import React, { useState, useEffect } from 'react';

import io from "socket.io-client";
import { decodeToken } from '../../../services/auth';
import api from '../../../services/api'
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Beneficios/header';
import ListBenefits from '../../../components/Administrativo/Beneficios/list-benefits';
import { toast } from 'react-toastify';
import './styles.scss';

export default function Benefits(props) {

    const { history } = props;
    const { user } = decodeToken();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageNotifications, setLoadPageNotifications ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notifications, setNotifications ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationsRead, setNotificationsRead ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadnotifications();
        loadnotificationsread();
        loadlimit();
        async function registerToSocketNotification() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            socket.on("notificationRead", () => {
                loadnotificationsread();
                // loadnotifications();

                if(localStorage.getItem('@peopleview/limit') > 4) {
                    showMore()
                }
                else {

                    loadnotifications();
                }

            });
        }
        registerToSocketNotification();
        loadbenefits();
    }, []);

    async function loadnotificationsread() {
        await api.get('/notification-read/')
        .then(response => {
            setNotificationsRead(response.data[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadnotifications() {
        setLoadPageNotifications(true)
        await api.get('/notification/')
        .then(response => {
            setLoadPageNotifications(false)
            if(response.data[0] !== undefined) {
                setNotifications(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function readTrue(event) {
        await api.put(`/notification/${event}`, { read: true })
        .then(response => {
            loadnotifications();
        }).catch(error => {});
    }

    async function loadlimit() {
        localStorage.setItem('@peopleview/limit', 4);
    }

    async function showMore() {
        let limite = 4;
        const limitStorage = localStorage.getItem('@peopleview/limit');
        let limit = (parseInt(limitStorage) + parseInt(limite))
        localStorage.setItem('@peopleview/limit', limit);
        await api.get(`notification/?showmore=true&limite=${limit}`)
        .then(response => {
            setLoadPageNotifications(false)
            if(response.data[0] !== undefined) {
                setNotifications(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function indexUpdate() {
        await api.get('/notification-update-all/')
        .then(response => {
            setLoadPageNotifications(false)
            if(response.data[0] !== undefined) {
                setNotifications(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    ////////////// FINAL NOTIFICATIONS ////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ beneftis, setBeneftis] = useState([]);
    async function loadbenefits() {
        setLoadPage(true)
        await api.get('/beneftis/?status=Ativo')
        .then(response => {
            setLoadPage(false)
            setBeneftis(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPage(false)
        });
    }
     // eslint-disable-next-line react-hooks/rules-of-hooks
     async function loadfilterbeneftis(event) {
        event.preventDefault()
        let name = event.target.value;
        setLoadPage(true)
        if(name.length >=1) {
            await api.get(`/beneftis/?name=${name}&status=Ativo`)
            .then(response => {
                setLoadPage(false);
                setBeneftis(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPage(false)
            });
        }
        else {
            await api.get('/beneftis/?status=Ativo')
            .then(response => {
                setLoadPage(false)
                setBeneftis(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPage(false)
            });
        }
    }
    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                loadfilterbeneftis={(event) => loadfilterbeneftis(event)}
                history={history}
                user={user}
                loadPageNotifications={loadPageNotifications}
                notifications={notifications}
                notificationsRead={notificationsRead}
                readTrue={readTrue}
                showMore={showMore}
                indexUpdate={indexUpdate}
                path={props.match.path}
                />
                <ListBenefits
                loadPage={loadPage}
                beneftis={beneftis}
                />
            </Layout>
        </>
    );
}
