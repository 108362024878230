import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import CurrencyInput from 'react-currency-input';
import { NavLink } from 'react-router-dom'
import Close from '../../../../../assets/images/close.svg';
import EditBlack from '../../../../../assets/images/edit-black.svg';
import Carregamento from '../../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../../assets/images/iconUserNone.svg';
import {  Modal } from "react-bootstrap";
import swal from 'sweetalert';
import api from '../../../../../services/api';
import FoodGroceries from '../../../../../assets/images/illustration-food-groceries.svg';
import { decodeToken } from '../../../../../services/auth';
import { toast } from 'react-toastify';

export default function ColProfileBenefits({ collaborator, id, path }) {

    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageBeneficios, setLoadPageBeneficios ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCompanyCosts, setLoadCompanyCosts ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ newBenefit, setNewBenefit ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnSave, setLoadBtnSave ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorCosts, setLoadCollaboratorCosts ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [benefitClicked, setBenefitClicked] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ beneftis, setBeneftis] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadbenefits();
        if (id && id) {
            loadbenefitsnew(id);
            loadbenefitsnewdiferent(id);
        }

    }, [id]);

    async function loadbenefits() {

        setLoadPage(true)
        await api.get('/beneftis/?status=Ativo')
        .then(response => {

            setBeneftis(response.data);
            setLoadPage(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ beneftisDiferentCollaborator, setBeneftisDiferentCollaborator] = useState([]);

    async function loadbenefitsnew(id) {

        setLoadPageBeneficios(true)
        await api.get(`/beneftis-new/?collaborator=${id}&status=Ativo`)
        .then(response => {

            setBeneftisDiferentCollaborator(response.data);
            setLoadPageBeneficios(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    ///////////////////////////// BENEFITS COMPANY NEW DIFERENT //////////////////////////////

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBeneftis, setCollaboratorBeneftis] = useState([]);


    async function loadbenefitsnewdiferent(id) {

        setLoadPageCollaboratorBeneftis(true)
        await api.get(`/beneftis-new-diferent/?collaborator=${id}&status=Ativo`)
        .then(response => {

            setCollaboratorBeneftis(response.data);
            setLoadPageCollaboratorBeneftis(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }



   //////////////////////////////

    function handleToggleVisibleAtivar(e) {

        setBenefitClicked(e)
        setVisible(!visible);

    }


    function handleToggleNoVisibleAtivar() {

        setNewBenefit([]);
        setVisible(!visible);
        setLoadCompanyCosts(false)
        setBenefitEdit([]);

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [benefitClickedEdit, setBenefitClickedEdit] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleEditar, setVisibleEditar] = useState(false);


    function handleToggleVisibleEditar(e) {
        setVisibleEditar(!visibleEditar);
        setBenefitClickedEdit(e)
        setBenefitEdit([]);
        setLoadCompanyCosts(false)

    }

    function handleToggleNoVisibleEditar(e) {

        setBenefitClickedEdit([]);
        setVisibleEditar(!visibleEditar);
        setLoadCompanyCosts(false)

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ benefitEdit, setBenefitEdit ] = useState([]);


    async function myChangeHandlerEdit(event) {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;
        let benefits     = benefitClickedEdit._id;


        if (nam === "collaboratorCosts") {

            if(val.length === 2) {
                setLoadCollaboratorCosts(false)
            }

            let collaboratorCosts = val.replace('R$ ', '');
            // let collaboratorCosts = mNumber(val)
            setBenefitEdit({ ...benefitEdit, [nam]: collaboratorCosts, collaborator, benefits })

        }
        else  if (nam === "companyCosts") {

            if(val.length === 2) {
                setLoadCompanyCosts(false)
            }

            let companyCosts = val.replace('R$ ', '');
            setBenefitEdit({ ...benefitEdit, [nam]: companyCosts })

        }
        else {
            setBenefitEdit({ ...benefitEdit, [nam]: val })

        }


    }

    async function update(event) {
        event.preventDefault();

        let idBenefits = benefitClickedEdit._id;

        const benefitUpdate = {
            collaboratorCosts : benefitEdit?.collaboratorCosts ? benefitEdit?.collaboratorCosts : benefitClickedEdit.collaboratorCosts,
            companyCosts      : benefitEdit?.companyCosts ? benefitEdit?.companyCosts : benefitClickedEdit.companyCosts,
            path              : path

        }

        if (benefitUpdate.companyCosts === "0.00") {
            setLoadCompanyCosts(true)
            return
        }

        async function loadupdatecollaboratorbenefit() {

            await api.put(`collaborator-beneftis/${idBenefits}`, benefitUpdate)
            .then(response => {

                loadbenefits();
                loadbenefitsnew(id);
                loadbenefitsnewdiferent(id);
                setBenefitEdit([]);
                setVisibleEditar(!visibleEditar);
                swal({ icon: "success", title: "Sucesso!", text: "Benefício editado com sucesso!" });

            }).catch(error => {

                setLoadPageBeneficios(false)
                setLoadBtnSave(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao ativar benefício, tente novamente mais tarde." });

            });

        }
        loadupdatecollaboratorbenefit();


    }



    ///////////////////////////////// FINAL BENEFITS COMPANY NEW DIFERENT//////////////////////////////////




    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;
        let benefits     = benefitClicked._id;


        if (nam === "collaboratorCosts") {

            if(val.length === 2) {
                setLoadCollaboratorCosts(false)
            }
            let collaboratorCosts = val.replace('R$ ', '');
            setNewBenefit({ ...newBenefit, [nam]: collaboratorCosts, collaborator, benefits, path: path })

        }
        else  if (nam === "companyCosts") {
            setLoadCompanyCosts(false)
            let companyCosts = val.replace('R$ ', '');
            setNewBenefit({ ...newBenefit, [nam]: companyCosts })

        }
        else {
            setNewBenefit({ ...newBenefit, [nam]: val })

        }


    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageBeneftis, setLoadPageBeneftis ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageCollaboratorBeneftis, setLoadPageCollaboratorBeneftis ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageBeneftisDiferentCollaborator, setLoadPageBeneftisDiferentCollaborator ] = useState(false);



    async function desativarBeneficio(benefit) {

        swal({
            title: "Atenção",
            text: "Deseja desativar este beneficio?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                setLoadPageBeneficios(true)
                setLoadPageBeneftis(true)
                setLoadPageCollaboratorBeneftis(true)
                setLoadPageBeneftisDiferentCollaborator(true)
                setLoadBtnSave(true)

                await api.put(`/collaborator-beneftis/${benefit}`, { status : "Desvinculado", path: path })
                .then(() => {

                    loadbenefits();
                    loadbenefitsnew(id);
                    loadbenefitsnewdiferent(id);
                    setNewBenefit([]);
                    swal({ icon: "success", title: "Sucesso!", text: "Benefício desvinculado com sucesso." });

                }).catch((error) => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });
            }
        });
    }


    async function save(event) {
        event.preventDefault();

        if(newBenefit?.length === 0) {


            swal({ icon: "warning", title: "Atenção!", text: "Por favor, informe o valor do benefício para poder ativá-lo." });
            setLoadCompanyCosts(true)
            return
        }

        if (newBenefit?.companyCosts !== undefined || newBenefit?.companyCosts !== "") {
            setLoadCompanyCosts(false)
        }

        async function loadbenefitssaved() {

            await api.get(`/collaborator-beneftis-saved/?collaborator=${id}&benefits=${newBenefit?.benefits}`)
            .then(response => {

                if(response.data == null) {

                    if (
                        newBenefit?.collaboratorCosts               !== undefined &&
                        newBenefit?.collaboratorCosts               !==     ""    &&
                        newBenefit?.companyCosts                    !== undefined &&
                        newBenefit?.companyCosts                    !==     ""
                    ) {

                        setLoadPageBeneficios(true)
                        setLoadBtnSave(true)

                        async function loadsavecollaboratorbenefit() {

                            await api.post('/collaborator-beneftis', newBenefit)
                            .then(response => {

                                loadbenefits();
                                loadbenefitsnew(id);
                                loadbenefitsnewdiferent(id);
                                setNewBenefit([]);
                                setVisible(!visible);
                                swal({ icon: "success", title: "Sucesso!", text: "Benefício ativado com sucesso!" });

                            }).catch(error => {

                                setLoadPageBeneficios(false)
                                setLoadBtnSave(false)
                                swal({ icon: "error", title: "Erro!", text: "Erro ao ativar benefício, tente novamente mais tarde." });

                            });

                        }
                        loadsavecollaboratorbenefit();

                    }

                }
                else {

                    let id_collaborator_benefit = response.data._id;

                    const benefitUpdate = {
                        status            : 'Ativo',
                        collaboratorCosts : newBenefit?.collaboratorCosts,
                        companyCosts      : newBenefit?.companyCosts,
                        path              : path

                    }



                    async function loadupdatecollaboratorbenefit() {


                        await api.put(`collaborator-beneftis/${id_collaborator_benefit}`, benefitUpdate)
                        .then(response => {

                            loadbenefits();
                            loadbenefitsnew(id);
                            loadbenefitsnewdiferent(id);
                            setNewBenefit([]);
                            setVisible(!visible);
                            swal({ icon: "success", title: "Sucesso!", text: "Benefício ativado com sucesso!" });

                        }).catch(error => {

                            setLoadPageBeneficios(false)
                            setLoadBtnSave(false)
                            swal({ icon: "error", title: "Erro!", text: "Erro ao ativar benefício, tente novamente mais tarde." });

                        });

                    }
                    loadupdatecollaboratorbenefit();


                }

            }).catch((error) => {});
        }
        loadbenefitssaved();


    }

    return (
        <>
            <SubMenu collaborator={collaborator} id={id} role={role} path={path}/>
                


                    <div className="box-infor-perfil profile-infor-beneficios">




                        {
                        loadPageBeneficios                   === true ||
                        loadPageBeneftis                     === true ||
                        loadPageCollaboratorBeneftis         === true ||
                        loadPageBeneftisDiferentCollaborator === true ?
                        <>
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>

                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>

                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                        </>
                        :
                        beneftis &&
                        beneftis.length !== 0 ?
                        beneftis.map((benefit, index) => (
                            collaboratorBeneftis && collaboratorBeneftis.length !== 0 ?
                            collaboratorBeneftis
                            .filter(filetrcollaboratorbenefit => filetrcollaboratorbenefit?.benefits?.name === benefit?.nameBenefit)
                            .map((collaboratorbenefit, index) => (
                                <>
                                    <div key={index} className="box">
                                    <h3>{benefit?.nameBenefit}

                                         {
                                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                                ""
                                                :
                                                <button  className="edit-Benefit" onClick={() => handleToggleVisibleEditar(collaboratorbenefit)}><img src={EditBlack} alt="Edite Informações" /></button>
                                        }

                                        </h3>
                                        <ul>
                                            <li>
                                                <div className="intemA">
                                                    <p>Colaborador</p>
                                                </div>
                                                <div className="intemB">
                                                <p>{collaboratorbenefit && collaboratorbenefit?.collaboratorCosts ? collaboratorbenefit.collaboratorCosts : "-"}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Empregador</p>
                                                </div>
                                                <div className="intemB">
                                                <p>{collaboratorbenefit && collaboratorbenefit?.companyCosts ? collaboratorbenefit.companyCosts : "-"}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Data de corte</p>
                                                </div>
                                                <div className="intemB">
                                                <p>{benefit && benefit?.cutoffDate ? benefit.cutoffDate : "-"}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Crédito</p>
                                                </div>
                                                <div className="intemB">
                                                <p>{benefit?.balanceCalculation ? benefit?.balanceCalculation : "-" }</p>
                                                </div>
                                            </li>
                                        </ul>

                                        {
                                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                                ""
                                                :
                                                <div className="ativar-desativar">
                                                    <button className="desativar"onClick={() => desativarBeneficio(collaboratorbenefit?._id)}>Desativar</button>
                                                </div>
                                        }



                                    </div>
                                </>

                            )):''
                        )):""
                        }



                        {
                            loadPageBeneficios                   === true ||
                            loadPageBeneftis                     === true ||
                            loadPageCollaboratorBeneftis         === true ||
                            loadPageBeneftisDiferentCollaborator === true ?
                            "":
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                            beneftisDiferentCollaborator &&
                            beneftisDiferentCollaborator.length !== 0 ?
                            beneftisDiferentCollaborator.map((benefitsdiferent, index) => (
                                <>
                                    <div className="box box-desativado">
                                    <h3>{benefitsdiferent.name} </h3>
                                    <ul>
                                            <li>
                                                <div className="intemA">
                                                    <p>Colaborador</p>
                                                </div>
                                                <div className="intemB">
                                                <p>-</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Empregador</p>
                                                </div>
                                                <div className="intemB">
                                                <p>-</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Data de corte</p>
                                                </div>
                                                <div className="intemB">
                                                <p>-</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Crédito</p>
                                                </div>
                                                <div className="intemB">
                                                <p>-</p>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="ativar-desativar">
                                            <button className="ativar" onClick={() => handleToggleVisibleAtivar(benefitsdiferent)}>Ativar</button>
                                        </div>
                                    </div>
                                </>
                            )):''
                        }

                </div>
                {
                        loadPageBeneficios                   === true ||
                        loadPageBeneftis                     === true ||
                        loadPageCollaboratorBeneftis         === true ||
                        loadPageBeneftisDiferentCollaborator === true  ?
                        ""
                        :
                        beneftisDiferentCollaborator.length === 0 &&
                        beneftis.length === 0 ?
                        <>
                            <div className="Pgnenhum-beneficio-cadastrado" >
                                <p>Nenhum benefícios cadastrado.</p>
                                    <NavLink className="btnGreen more" to="/beneficios/criar" >Cadastrar um benefício</NavLink>
                                    <img src={FoodGroceries} alt="Nenhum beneficio cadastrado" />

                            </div>
                        </>
                        :""
                    }

            <Modal show={visible} className="popup-beneficios" onHide={handleToggleNoVisibleAtivar}>
                <div>
                    <div className="titulo">

                    <h4>Configurar benefício
                    <p className="btn-fechar" onClick={handleToggleNoVisibleAtivar}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="colab-img-nome-cargo">
                    {
                                    collaborator.avatar && collaborator.avatar !== 'undefined' ?
                                    <img alt="" src={collaborator.avatar} className="foto-Usuario" />
                                    :
                                    <div className="semImgCol">
                                        <img src={IconUserNone} alt="Usuario sem imagem" />
                                    </div>
                                }
                        {/* <img src={collaborator.avatar} alt="Imagen Colaborador" /> */}
                        <div className="nome-cargo">
                            <h6>{collaborator.name}</h6>
                            <p>{collaborator.occupation}</p>
                        </div>
                    </div>
                    <div className="beneficio-infor">
                        <div className="infor">
                            <label>Benefício</label>
                            <p>{benefitClicked?.name ? benefitClicked.name : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Operador</label>
                            <p>{benefitClicked?.operator ? benefitClicked.operator : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Data de corte</label>
                            <p>{benefitClicked?.cutoffDate ? benefitClicked.cutoffDate : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Crédito</label>
                            <p>{benefitClicked?.balanceCalculation ? benefitClicked.balanceCalculation : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Descrição</label>
                            <p>{
                            benefitClicked && benefitClicked.description ? benefitClicked.description.split('\n').map(str => (<><br/>{str}</>)) : '-'}</p>
                        </div>
                    </div>
                    <div className="custos-beneficio">
                        <h6>Custos</h6>
                        <div className="calculo-beneficio">
                            <div className="infor-custo">
                                <label>Colaborador</label>
                                <CurrencyInput
                                    // prefix="$"
                                    prefix="R$ "
                                    onChangeEvent={myChangeHandler}
                                    name="collaboratorCosts"
                                    placeholder="R$ 0.00"
                                    value={newBenefit.collaboratorCosts !== undefined ? newBenefit.collaboratorCosts : null}
                                    />


                                {/* <input type="text" name="collaboratorCosts" value={newBenefit?.collaboratorCosts !== undefined ? newBenefit?.collaboratorCosts : ''}  onChange={myChangeHandler} placeholder="R$ 0,00" /> */}

                                {loadCollaboratorCosts ?
                                (<p className="textError">O campo é obrigatório.</p>) :
                                ('')
                                }

                            </div>
                            <div className="infor-custo">
                                <label>Empresa</label>
                                <CurrencyInput
                                    prefix="R$ "
                                    onChangeEvent={myChangeHandler}
                                    name="companyCosts"
                                    placeholder="R$ 0.00"
                                    className={loadCompanyCosts ? 'inputErro' : ''}
                                    value={newBenefit.companyCosts !== undefined ? newBenefit.companyCosts : null}
                                />
                                        {/* <input type="text" name="companyCosts" value={newBenefit?.companyCosts !== undefined ? newBenefit?.companyCosts : ''}  onChange={myChangeHandler} placeholder="R$ 0,00" /> */}

                                {loadCompanyCosts ?
                                (<p className="textError">O campo é obrigatório.</p>) :
                                ('')
                                }
                            </div>
                        </div>
                    </div>
                    <form onSubmit={save}>
                        <button className={loadBtnSave  ? 'btn-config btnGreen load' : 'btn-config btnGreen'}>Ativar benefício</button>
                    </form>
                </div>
            </Modal>

            <Modal show={visibleEditar} className="popup-beneficios" onHide={handleToggleNoVisibleEditar}>
                <div>
                    <div className="titulo">

                    <h4>Editar benefício
                    <p className="btn-fechar" onClick={handleToggleNoVisibleEditar}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="colab-img-nome-cargo">
                    {
                        collaborator.avatar && collaborator.avatar !== 'undefined' ?
                        <img alt="" src={collaborator.avatar} className="foto-Usuario" />
                        :
                        <div className="semImgCol">
                            <img src={IconUserNone} alt="Usuario sem imagem" />
                        </div>
                    }
                        {/* <img src={collaborator.avatar} alt="Imagen Colaborador" /> */}
                        <div className="nome-cargo">
                            <h6>{collaborator.name}</h6>
                            <p>{collaborator.occupation}</p>
                        </div>
                    </div>
                    <div className="beneficio-infor">
                        <div className="infor">
                            <label>Benefício</label>
                            <p>{benefitClickedEdit && benefitClickedEdit.benefits ? benefitClickedEdit.benefits.name : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Operador</label>
                            <p>{benefitClickedEdit && benefitClickedEdit.benefits && benefitClickedEdit.benefits.operator ? benefitClickedEdit.benefits.operator : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Data de corte</label>
                            <p>{benefitClickedEdit && benefitClickedEdit.benefits && benefitClickedEdit.benefits.cutoffDate ? benefitClickedEdit.benefits.cutoffDate : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Crédito</label>
                            <p>{benefitClickedEdit && benefitClickedEdit.benefits && benefitClickedEdit.benefits.balanceCalculation ? benefitClickedEdit.benefits.balanceCalculation : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Descrição</label>
                            <p>{benefitClickedEdit && benefitClickedEdit.benefits && benefitClickedEdit.benefits.description ? benefitClickedEdit.benefits.description.split('\n').map(str => (<><br/>{str}</>)) : '-'}</p>
                        </div>
                    </div>
                    <div className="custos-beneficio">
                        <h6>Custos</h6>
                        <div className="calculo-beneficio">
                            <div className="infor-custo">
                                <label>Colaborador</label>

                                <CurrencyInput

                                    prefix="R$ "
                                    onChangeEvent={myChangeHandlerEdit}
                                    name="collaboratorCosts"
                                    placeholder="R$ 0.00"
                                    value={benefitEdit.collaboratorCosts !== undefined ? benefitEdit.collaboratorCosts : benefitClickedEdit.collaboratorCosts !== undefined ? benefitClickedEdit.collaboratorCosts : null}
                                />
                                {loadCollaboratorCosts ?
                                (<p className="textError">O campo é obrigatório.</p>) :
                                ('')
                                }

                            </div>
                            <div className="infor-custo">
                                <label>Empresa</label>

                                <CurrencyInput

                                    prefix="R$ "
                                    onChangeEvent={myChangeHandlerEdit}
                                    name="companyCosts"
                                    placeholder="R$ 0.00"
                                    value={benefitEdit.companyCosts !== undefined ?
                                        benefitEdit.companyCosts
                                        : benefitClickedEdit.companyCosts !== undefined ?
                                            benefitClickedEdit.companyCosts : null
                                    }
                                    className={loadCompanyCosts ? 'inputErro' : ''}
                                />
                                {loadCompanyCosts ?
                                (<p className="textError">O campo é obrigatório.</p>) :
                                ('')
                                }
                            </div>
                        </div>
                    </div>
                    <form onSubmit={update}>
                        <button className={loadBtnSave  ? 'btn-config btnGreen load' : 'btn-config btnGreen'}>Editar benefício</button>
                    </form>
                </div>
            </Modal>

        </>
    );
}
