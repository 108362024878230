import React from 'react';
// import { NavLink } from 'react-router-dom'
import HeaderAdmin from '../../HeaderAdmin';

// import IconsInformation from '../../IconsInformation';

export default function headerSystemConfig({
    signatureTransactions,
}) {

    return (
        <>
        <HeaderAdmin>
                <div>
                    <h1>Configurações</h1>
                    <p>Informações e ajustes</p>
                </div>

        </HeaderAdmin>
        
            {/* <header className="header-geral header-systemConfig">
                <div className="cabeçalho">
                    <h1>Configurações</h1>
                    <p>Informações e ajustes</p>
                </div>
                <div className="infor">
                <IconsInformation
                signatureTransactions={signatureTransactions}
                />
                </div>

            </header> */}
        </>
    );
}
