import React, { useState, useEffect } from 'react';
import SubHeader from '../../../components/PerfilUser/subHeader';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import Documents from '../../../components/PerfilUser/Professional/PeriodicExam';

import Layout from '../../../components/Layouts/defaultUser';
import { decodeToken } from '../../../services/auth';
import { toast } from 'react-toastify';
import '../styles.scss';

import api from '../../../services/api';

const ColProfilePersonal = (props) => {

    const decoded = decodeToken();
    var id = decoded.user._id;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaborator(id);
    }, [id]);

    async function loadcollaborator(id) {
        await api.get(`/collaborator-access/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }


        return (
            <>
               <Layout
               path={props.match.path}
               >

                    <div className="profile-user">
                        <InforProfile
                            collaborator={collaborator}
                            id={id}
                            setCollaborator={setCollaborator}
                        />
                        <SubHeader
                            id={id}
                            path={props.match.path}
                            collaborator={collaborator}
                        />
                        <Documents
                            id={id}
                            path={props.match.path}
                            collaborator={collaborator}
                        />
                    </div>
                </Layout>
            </>
        );
}
export default ColProfilePersonal;
