import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import Close from '../../../assets/images/close.svg';
import iconV from '../../../assets/images/arrow.svg';
import { Modal, Dropdown } from "react-bootstrap";
import Carregando from '../../../assets/images/carregando.gif';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { dateFormatedToStripe } from '../../../services/utils';


export default function ResumoFerias() {

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [loadVacationSummary, setLoadVacationSummary] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorCurrentVacations, setCollaboratorCurrentVacations ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorVacationComing, setCollaboratorVacationComing ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorwillGoOnVacation, setCollaboratorwillGoOnVacation ] = useState([]);

    useEffect(() => {

        vacationSummary();

    }, []);



    async function vacationSummary() {

        setLoadVacationSummary(true)
        await api.get('/collaborator-vacation-new/?OnVacation=true&type=individual&status=aprovado')
        .then(response => {

            if(response.data[0] && response.data[0] !== undefined) {
                setCollaboratorCurrentVacations(response.data[0]);
                localStorage.setItem('@peopleview/totalInVacations', response.data[1][0].countOnVacation);
            }
            else {
                setCollaboratorCurrentVacations([]);
                localStorage.setItem('@peopleview/totalInVacations', 0);
            }
            setLoadVacationSummary(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadVacationSummary(false)
        });

        await api.get('/collaborator-vacation-new/?WithVacationsToExpire=true&type=individual')
        .then(response => {
            if(response.data[0] !== undefined) {
                setCollaboratorVacationComing(response.data[0]);
                localStorage.setItem('@peopleview/totalVacationsInComing', response.data[1][0].countWithVacationsToExpire);
            }
            else {
                setCollaboratorVacationComing([]);
                localStorage.setItem('@peopleview/totalVacationsInComing', 0);
            }
            setLoadVacationSummary(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadVacationSummary(false)
        });

        await api.get('/collaborator-vacation-new/?willEnjoyVacation=true&type=individual')
        .then(response => {
            if(response.data[0] !== undefined) {
                setCollaboratorwillGoOnVacation(response.data[0]);
                localStorage.setItem('@peopleview/totalwillGoOnVacation', response.data[1][0].countwillEnjoyVacation);
            }
            else {
                setCollaboratorwillGoOnVacation([]);
                localStorage.setItem('@peopleview/totalwillGoOnVacation', 0);
            }
            setLoadVacationSummary(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadVacationSummary(false)
        });
    }

    const totalInVacation        = localStorage.getItem('@peopleview/totalInVacations');
    const totalVacationsInComing = localStorage.getItem('@peopleview/totalVacationsInComing');
    const totalwillGoOnVacation  = localStorage.getItem('@peopleview/totalwillGoOnVacation');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleResumoFerias, setVisibleResumoFerias] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleCurrentVacations, setVisibleCurrentVacations] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleVacationsIncoming, setVisibleVacationsIncoming] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblewillGoOnVacation, setVisiblewillGoOnVacation] = useState(true);

    function VerResumoFerias() {
        setVisibleResumoFerias(!visibleResumoFerias);
    }

    async function myChangeHandlerCurrentVacation(event) {

        setVisibleCurrentVacations(!visibleCurrentVacations)

    }

    async function myChangeHandlerVacationsIncoming(event) {

        setVisibleVacationsIncoming(!visibleVacationsIncoming)

    }

    async function myChangeHandlerWillGoOnVacation(event) {

        setVisiblewillGoOnVacation(!visiblewillGoOnVacation)

    }

    return (
        <>

            <div className="resumo-ferias">
                <p className="em-ferias">
                    <span>{totalInVacation === null ? 0 : totalInVacation}</span>
                    Em férias
                </p>
                <p className="com-ferias-vencer">
                    <span>{totalVacationsInComing !== null ? totalVacationsInComing : '0'}</span>
                    Com férias a vencer
                </p>
                <p className="sairao-ferias">
                    <span>{totalwillGoOnVacation !== null ? totalwillGoOnVacation : '0'}</span>
                    {totalwillGoOnVacation <= 1 ? ' Sairá de férias' : 'Sairão de férias'}
                </p>
                <button className="ver-resumo" onClick={() => VerResumoFerias()}>
                    Ver resumo
                </button>
            </div>



            <Modal show={visibleResumoFerias} className="popup-geral popup-resumo-ferias" onHide={VerResumoFerias}>
            <div className="titulo">
                <h4>
                    <span>
                    Resumo de férias

                    </span>


                <Dropdown  className="dropdown-icon ">
                    <Dropdown.Toggle>
                    Filtrar <img alt="" src={iconV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <ul>
                            <li className="chekbox">
                                <div className="chekboxNovo">
                                    <input type="checkbox" name="emferias" onClick={myChangeHandlerCurrentVacation} defaultChecked="checked"/>
                                    <label htmlFor="nao"><span>Em férias</span></label>
                                </div>
                            </li>
                            <li className="chekbox">

                                <div className="chekboxNovo">
                                    <input type="checkbox" name="avencer" onClick={myChangeHandlerVacationsIncoming} defaultChecked="checked"/>
                                    <label htmlFor="nao"><span>A vencer</span></label>
                                </div>
                            </li>
                            <li className="chekbox">

                                <div className="chekboxNovo">
                                    <input type="checkbox" name="sairaodeferias" onClick={myChangeHandlerWillGoOnVacation} defaultChecked="checked"/>
                                    <label htmlFor="nao"><span>Sairão de férias</span></label>
                                </div>
                            </li>
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>



                <p className="btn-fechar" onClick={VerResumoFerias}><img src={Close} alt="Fechar" /></p></h4>
            </div>
            <div className="resumo-ferias">
                <div className="lista-resumo">
                    <div className="box-resumo" style={ visibleCurrentVacations === false ? {display:'none'} : {display:'block'}}>
                        <h4 className="titulo-box titulo-em-ferias"><span>{totalInVacation === null ? 0 : totalInVacation}</span> em férias</h4>
                        {
                            collaboratorCurrentVacations === undefined
                            ||
                            collaboratorCurrentVacations?.length === 0 ?
                            <>
                                <div className="lista-col-vazia">
                                    <p>
                                        Ninguém em férias no momento.
                                    </p>
                                </div>
                            </>
                            :
                            <>
                                <div className="lista-col">
                                    {
                                        collaboratorCurrentVacations ?
                                        collaboratorCurrentVacations
                                        .map((currentVacation, index) => (
                                            <>
                                                <div className="calaborador" key={index}>
                                                    <p className="nome">{currentVacation?.collaborator?.name ? currentVacation?.collaborator?.name : '-'}</p>
                                                    <div className="infor">
                                                        <p className="data">De {currentVacation?.startDate ? dateFormatedToStripe(currentVacation?.startDate) : '-'} à {currentVacation?.endDate ? dateFormatedToStripe(currentVacation?.endDate) : '-'} ({currentVacation?.vacationDays ? currentVacation?.vacationDays : '-'} dias)</p>
                                                        {/* idCollaborator */}
                                                        <NavLink to={`/colaboradores/${currentVacation?.collaborator?._id}/pessoal/`} className="verPerfil">
                                                            VER PERFIL
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                        :
                                        ''
                                    }
                                </div>
                            </>
                        }
                    </div>


                    <div className="box-resumo" style={ visibleVacationsIncoming === false ? {display:'none'} : {display:'block'}}>
                        <h4 className="titulo-box titulo-com-ferias-vencer"><span>{
                        totalVacationsInComing !== null && totalVacationsInComing !== undefined ? totalVacationsInComing : '0'
                        }</span> com férias a vencer</h4>
                        {
                            loadVacationSummary ? (
                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            collaboratorVacationComing === undefined
                            ||
                            collaboratorVacationComing.length === 0 ?
                            <>
                                <div className="lista-col-vazia">
                                    <p>
                                    Ninguém com férias a vencer no momento.
                                    </p>
                                </div>
                            </>
                            :
                            <>
                            <div className="lista-col">
                                {
                                    loadVacationSummary ? (
                                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                    ) :
                                    collaboratorVacationComing ?
                                    collaboratorVacationComing
                                    .map((vacationComin, index) => (
                                        <>
                                            <div className="calaborador" key={index}>
                                                <p className="nome">{
                                                vacationComin?.collaborator?.name ?
                                                vacationComin?.collaborator?.name
                                                :
                                                vacationComin?.nameCollaborator ?
                                                vacationComin?.nameCollaborator
                                                :
                                                '-'
                                                }</p>
                                                <div className="infor">
                                                    {/* <p className="data">Data Limite: {vacationComin.nextExpirationDate ? vacationComin.nextExpirationDate : '-'} <br/> ({vacationComin.daysLeft ? vacationComin.daysLeft : '-'} dias para o vencimento)</p> */}
                                                    <p className="data">Data Limite: {dateFormatedToStripe(vacationComin.limitConcessionPeriod) ? dateFormatedToStripe(vacationComin.limitConcessionPeriod) : '-'} <br/> ({vacationComin.balanceAvailable ? vacationComin.balanceAvailable : '-'} dias de saldo)</p>

                                                    <NavLink to={`/colaboradores/${vacationComin?.collaborator?._id}/pessoal/`} className="verPerfil">
                                                        VER PERFIL
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                    :
                                    ''
                                }
                            </div>
                            </>
                        }
                    </div>


                    <div className="box-resumo" style={ visiblewillGoOnVacation === false ? {display:'none'} : {display:'block'}}>
                        <h4 className="titulo-box titulo-sairao-ferias"><span>{totalwillGoOnVacation !== null ? totalwillGoOnVacation : '0'}</span>{totalwillGoOnVacation <= 1 ? ' sairá de férias' : ' sairão de férias'} </h4>

                        {
                            loadVacationSummary ? (
                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            collaboratorwillGoOnVacation === undefined
                            ||
                            collaboratorwillGoOnVacation.length === 0 ?
                            <>
                                <div className="lista-col-vazia">
                                    <p>
                                        Ninguém com férias programadas no momento.
                                    </p>
                                </div>
                            </>
                            :
                            <>
                            <div className="lista-col" >
                                {
                                    collaboratorwillGoOnVacation ?
                                    collaboratorwillGoOnVacation
                                    .map((collaboratorWillGoOnVacation, index) => (
                                        <>
                                            <div className="calaborador" key={index}>
                                                <p className="nome">{collaboratorWillGoOnVacation.collaborator?.name ? collaboratorWillGoOnVacation.collaborator?.name : '-'}</p>
                                                <div className="infor">
                                                    <p className="data">De {collaboratorWillGoOnVacation?.startDate ? dateFormatedToStripe(collaboratorWillGoOnVacation?.startDate) : '-'} à {collaboratorWillGoOnVacation?.endDate ? dateFormatedToStripe(collaboratorWillGoOnVacation?.endDate) : '-'} ({collaboratorWillGoOnVacation.vacationDays ? collaboratorWillGoOnVacation.vacationDays : '-'} dias)</p>

                                                    <NavLink to={`/colaboradores/${collaboratorWillGoOnVacation.collaborator._id}/pessoal/`} className="verPerfil">
                                                        VER PERFIL
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                    :
                                    ''
                                }
                            </div>
                            </>
                        }

                    </div>


                </div>
                <div className="btn">
                    <button className="btnGray" onClick={VerResumoFerias}>Fechar</button>
                </div>
            </div>
            </Modal>
        </>
    );
}
