import React, { useState, useEffect } from 'react';
import Dropzone from "react-dropzone";
import IconsProfile from '../../../../../assets/images/profile.svg';
import Carregando from '../../../../../assets/images/carregando.gif';
import SelectPersonalizado from '../../../../Reused/selectPersonalizado';
import {Countries} from '../../../../Reused/ArrayCountries';
import {removerSpecials} from '../../../../../services/utils';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import moment from 'moment'
import api from '../../../../../services/api';

export default function formPersonalData({
    id,
    path,
    collaborator,
    collaboratorUpdate,
    setCollaborator,
    LoadsetbornState,
    ufDistrito,
    LoadsetbornCity,
    onCollaboratorInputChange,
    setCollaboratorUpdate,
    ufEstados,
    ufCity,
    LoaddateOfBirth,
    Loadgender              ,
    LoadfatherName          ,
    LoadmotherName          ,
    LoadmaritalStatus       ,
    Loadnationality         ,
    LoadotherNationality    ,
    Loadethnicity           ,
    LoadbornState           ,
    LoadbornCity            ,
    focusDateOfBirth        ,
    focusgender             ,
    focusfatherName         ,
    focusmotherName         ,
    focusmaritalStatus      ,
    focusnationality        ,
    focusotherNationality   ,
    focusethnicity          ,
    focusbornState          ,
    focusbornCity,
    Loadsetnationality
}) {
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ ufEscolhido, setUEscolhido ] = useState('')
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ cidade, setCidade ] = useState('');

//////////////////
//////////////////
//////////////////

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [ load, setLoad ] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [filesSaved, setFilesSaved] = useState([]);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [, setFileNames] = useState([]);

 // eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(id && id !== undefined) {

        async function loadfilessaved() {
            setLoad(true)
            await api.get(`/collaborator-file/?id=${id}&category=identification`)
            .then(response => {
                setLoad(false)
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();

    }


}, [id]);

 async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setFileNames(acceptedFiles.map(file => file.name));
            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'identification');
            formData.append('collaborator', id);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file/?id=${id}&category=identification`)
                    .then(response => {
                        setLoad(false)
                        setFilesSaved(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

 }

 //const deleteFile = async (filesaved) => {
async function deleteFile(filesaved) {
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoad(true)

            await api.delete(`/collaborator-file/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file/?id=${id}&category=identification`)
                        .then(response => {
                            setFilesSaved(response.data);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch((error) => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
        }
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ citys, setCitys ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(ufDistrito !== undefined) {

        if(ufDistrito === '35') {
            async function loadcities() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
                .then(response => {

                    let itensCopyCitys = Array.from(response.data);

                    itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });

                    itensCopyCitys.sort(function (a, b) {
                        if (a.nome > b.nome) {
                          return 1;
                        }
                        if (a.nome < b.nome) {
                          return -1;
                        }
                        // a must be equal to b
                        return 0;
                      });

                    setCitys(itensCopyCitys);

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadcities();
        }
        else {

            async function loadcities() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
                .then(response => {
                    setCitys(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadcities();

        }

    }


}, [ufDistrito]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(ufDistrito !== undefined) {

        async function loaduf() {
            api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
            .then(response => {
                setUEscolhido(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loaduf();

    }


}, [ufDistrito]);



// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(ufCity !== undefined) {

        if(ufCity === '350000000') {

            let cidadeGravada = {
                id: "350000000",
                nome: "São Paulo"
            }
            setCidade(cidadeGravada);

        }
        else {

            async function loadcity() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
                .then(response => {
                    setCidade(response.data[0]);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadcity();

        }



    }


}, [ufCity]);


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);


// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(id && id !== undefined) {

        async function loadcollaboratorsaved() {
            await api.get(`/collaborator/${id}`)
            .then(response => {
                setCollaboratorSaved(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadcollaboratorsaved();

    }

}, [id]);



// eslint-disable-next-line react-hooks/rules-of-hooks
const [ dateOfbirthSaved, setDateOfbirthSaved ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    async function loaddateofbirth() {
        if(collaboratorSaved.dateOfBirth !== undefined) {
            let data = moment(collaboratorSaved.dateOfBirth, "YYYY-MM-DD");
            setDateOfbirthSaved(data.format("DD/MM/YYYY"));
        }
    }
    loaddateofbirth();
}, [collaboratorSaved.dateOfBirth]);



// eslint-disable-next-line react-hooks/rules-of-hooks
const [BuscaLoadOptionBornstate , setBuscaLoadOptionBornstate] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [BuscaLoadOptionCity , setBuscaLoadOptionCity] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [nationalityBusca , setNationalityBusca] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [nationalityBuscaString , setNationalityBuscaString] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const optionFiltreNacionalite = Countries.filter((valOption) => {
    return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.nationalityBusca !== undefined ? nationalityBusca.nationalityBusca : "" )  ;
});

// eslint-disable-next-line react-hooks/rules-of-hooks
const optionFiltreUfEstados = ufEstados.filter((valOption) => {
    return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornStateBusca !== undefined ? nationalityBusca.bornStateBusca : "" )  ;
});

const optionGener = [
    "Feminino",
    "Masculino",
    "Transgênero",
    "Não-binário",
    "Prefiro não responder"
]
const optionMaritalStatus = [
    "Solteiro(a)",
    "Casado(a)",
    "Divorciado(a)",
    "Viúvo(a)",
    "União estável"
]
const optionEthnicity = [
    "Nenhum", 
    "Branca",
    "Preta",
    "Indígena",
    "Amarela",
    "Parda"
]

// eslint-disable-next-line react-hooks/rules-of-hooks
const optionFiltreUCidade = citys.filter((valOption) => {
    return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornCityBusca !== undefined ? nationalityBusca.bornCityBusca : "" )  ;
});


const myChangeHandlerSlects = event => {
    let nam = event.target.name;
    let val = event.target.value;

    if(nam === "nationalityBusca") {


        let valOption = removerSpecials(val).toLowerCase()
        setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
        setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
        setCollaborator({ ...collaborator,   "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });


    }else if (nam === "nationality") {


        setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val  });
        setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val  });
        // setCollaborator({ ...collaborator,  [nam]: val  });
        Loadsetnationality(false)

        if(collaboratorUpdate.nationality !== "Brasil") {
            setNationalityBuscaString({ ...nationalityBuscaString, 
                "nationalityBusca": val,  
                "bornStateBusca": "", 
                "bornCityBusca": ""  
            });
            setCollaboratorUpdate({ ...collaboratorUpdate,  
                [nam]: val, 
                 "bornCityString": "", 
                 "bornCity": "",  
                 "bornState": "", 
                 "bornStateString": ""    
            });
            setCollaborator({ ...collaborator,  
                [nam]: "",  
                "bornCityString": "", 
                "bornCity": "",  
                "bornState": "", 
                "bornStateString": ""   
            });
            setNationalityBusca({ ...nationalityBusca,
                "bornStateBusca": "", 
                "bornCityBusca": "" 
            })

        }else {
            setNationalityBusca({ ...nationalityBusca,
                "bornStateBusca": "", 
                "bornCityBusca": "" 
            })
            setNationalityBuscaString({ ...nationalityBuscaString, 
                "nationalityBusca": val,  
                "bornStateBusca": "", 
                "bornCityBusca": "" 
                
             });
            setCollaboratorUpdate({ ...collaboratorUpdate,  
                [nam]: val,  
                "bornCityString": "", 
                "bornCity": "",  
                "bornState": "", 
                "bornStateString": ""   
             });
            setCollaborator({ ...collaborator,    
                [nam]: val,
                "bornCityString": "", 
                "bornCity": "",  
                "bornState": "", 
                "bornStateString": "" 
            });
        }
    }
    else
    if(nam === "bornStateBusca") {
        let valOption = removerSpecials(val).toLowerCase()
        setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
        setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });

    }
    else
    if(nam === "bornState") {
        setBuscaLoadOptionBornstate(true)
        let estado = val;

        async function estados() {

            if(estado !== undefined) {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
                .then(response => {
                    setBuscaLoadOptionBornstate(false)
                    LoadsetbornState(false)
                    let bornStateString = response.data.nome;
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": ""   });
                    setCollaborator({ ...collaborator, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": "" });

                    setNationalityBuscaString({ ...nationalityBuscaString,  "bornStateBusca": bornStateString,"bornCityBusca": "" });

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
        }
        estados();
    }
    else
    if(nam === "bornCityBusca") {
        let valOption = removerSpecials(val).toLowerCase()
        setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
        setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
    } else
    if(nam === "bornCity") {
        LoadsetbornCity(false)
        setBuscaLoadOptionCity(true)
        let cidade = val;
        if(cidade !== undefined) {

            if(cidade === '350000000') {

                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';
                setBuscaLoadOptionCity(false)

                setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });

            }
            else
            if(collaboratorUpdate.bornState === '35' && cidade === '350000000') {

                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';
                setBuscaLoadOptionCity(false)

                setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });

            }
            else {

                async function loadCity() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                    .then(response => {
                        let bornCityString = response.data[0].nome;
                        setBuscaLoadOptionCity(false)
                        setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, "bornCity": val })
                        setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                loadCity();

            }

        }
    }

}
return (
            <>
                <div className="personal-data">
                    <img alt="" src={IconsProfile} className="iconProfile"/>
                    <h3>Dados pessoais</h3>
                    <div className="box-grid">

                        <div className='linha'>
                            <div className='box'>
                                <p className="textCampo">Data de nascimento</p>
                                <div>
                                    {
                                        (collaboratorUpdate && collaboratorUpdate.dateOfBirth) || collaboratorUpdate.dateOfBirth === "" ?
                                        (
                                            <input type="text" ref={focusDateOfBirth} autocomplete="autocompleteoff" name="dateOfBirth" value={(collaboratorUpdate.dateOfBirth !== undefined) ? collaboratorUpdate.dateOfBirth : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className={LoaddateOfBirth ? 'inputUm inputErro' : 'inputUm'} />
                                        ) :
                                        dateOfbirthSaved[0] !== undefined ? (
                                            <input type="text" name="dateOfBirth" autocomplete="autocompleteoff" defaultValue={(dateOfbirthSaved !== undefined) ? dateOfbirthSaved : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" className="inputUm"/>
                                        ):
                                        (
                                            <input type="text" ref={focusDateOfBirth} autocomplete="autocompleteoff" name="dateOfBirth" defaultValue={(dateOfbirthSaved !== undefined) ? dateOfbirthSaved : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className={LoaddateOfBirth ? 'inputUm inputErro' : 'inputUm'} />
                                        )
                                    }
                                    {
                                        LoaddateOfBirth ? (
                                        <p className="textError inputUm">O campo é obrigatório.</p>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                            <div className='box'>
                                <p className="textCampo">Sexo</p>
                                <div>

                                    <SelectPersonalizado
                                        valorCampo={
                                            collaboratorUpdate && collaboratorUpdate.gender ?
                                            collaboratorUpdate.gender :
                                            collaboratorUpdate && collaboratorUpdate.gender === '' ?
                                            '' :
                                            collaborator && collaborator.gender ?
                                            collaborator.gender :
                                            ''
                                        }
                                        nameButton={"gender"}
                                        loadError={Loadgender}
                                        myChangeHandler={onCollaboratorInputChange}
                                        option={optionGener}
                                        focusRef={focusgender}
                                    />
                        
                                    {Loadgender ? (
                                        <p className="textError inputDois">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                </div>
                            </div>
                        </div>

                        <div className='linha'>
                            <div className='box'>
                                <p className="textCampo">Nome do pai</p>
                                <div>
                                    <input ref={focusfatherName} autocomplete="autocompleteoff" type="text" name="fatherName" defaultValue={(collaborator.fatherName !== undefined) ? collaborator.fatherName : ''} onChange={onCollaboratorInputChange} placeholder="Nome do pai" className={LoadfatherName ? 'inputTreis inputErro' : 'inputTreis'} />
                                    {LoadfatherName ? (
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                    ) : ('')
                                    }
                                </div>
                            </div>
                            <div className='box'>
                                <p className="textCampo">Nome da mãe</p>
                                <div>
                                    <input ref={focusmotherName} autocomplete="autocompleteoff" type="text" name="motherName" defaultValue={(collaborator.motherName !== undefined) ? collaborator.motherName : ''} onChange={onCollaboratorInputChange} placeholder="Nome da mãe" className={LoadmotherName ? 'inputQuatro inputErro' : 'inputQuatro'} />
                                    {LoadmotherName ? (
                                        <p className="textError inputQuatro">O campo é obrigatório.</p>
                                    ) : ('')
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='linha'>
                            <div className='box'>
                                <p className="textCampo">Estado civil</p>
                                <div>

                                <SelectPersonalizado
                                        valorCampo={
                                            collaboratorUpdate && collaboratorUpdate.maritalStatus ?
                                            collaboratorUpdate.maritalStatus :
                                            collaboratorUpdate && collaboratorUpdate.maritalStatus === '' ?
                                            '' :
                                            collaborator && collaborator.maritalStatus ?
                                            collaborator.maritalStatus :
                                            ''
                                        }
                                        nameButton={"maritalStatus"}
                                        loadError={LoadmaritalStatus}
                                        myChangeHandler={onCollaboratorInputChange}
                                        option={optionMaritalStatus}
                                        focusRef={focusmaritalStatus}
                                    />
                                    {LoadmaritalStatus ? (
                                    <p className="textError inputCinco">O campo é obrigatório.</p>
                                    ) : ('')
                                    }
                                </div>
                            </div>
                            <div className='box'>
                            </div>
                        </div>

                        <div className='linha'>
                            <div className='box'>
                                <h5>Naturalidade</h5>
                                <div></div>
                            </div>
                            <div className='box'></div>
                        </div>

                        
                        <div className='linha'>
                            <div className='box'>
                                <p className="textCampo">Nacionalidade</p>
                                <div>
                                    <SelectPersonalizado
                                        valorCampo={
                                            nationalityBuscaString && nationalityBuscaString.nationalityBusca ?
                                            nationalityBuscaString.nationalityBusca :
                                            nationalityBuscaString && nationalityBuscaString.nationalityBusca === '' ?
                                            '' :
                                            collaborator && collaborator.nationality ?
                                            collaborator.nationality :
                                            ''
                                        }
                                        nameCampoBusca={"nationalityBusca"}
                                        nameButton={"nationality"}
                                        loadError={Loadnationality}
                                        validacaoValue={"pais"}
                                        validacaoOptionIntem={"pais"}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreNacionalite}
                                        typeSelect={"busca"}
                                        focusRef={focusnationality}
                                    />
                                    {Loadnationality ? (
                                    <p className="textError inputSeis">O campo é obrigatório.</p>
                                    ) : ('')
                                    }

                                </div>
                            </div>
                            <div className='box'>
                                <p className="textCampo">Cor/Raça</p>
                                <div>


                                <SelectPersonalizado
                                        valorCampo={
                                            collaboratorUpdate && collaboratorUpdate.ethnicity ?
                                            collaboratorUpdate.ethnicity :
                                            collaboratorUpdate && collaboratorUpdate.ethnicity === '' ?
                                            '' :
                                            collaborator && collaborator.ethnicity ?
                                            collaborator.ethnicity :
                                            ''
                                        }
                                        nameButton={"ethnicity"}
                                        myChangeHandler={onCollaboratorInputChange}
                                        option={optionEthnicity}
                                        loadError={Loadethnicity}
                                        focusRef={focusethnicity}
                                    />
                                    {Loadethnicity ? (
                                        <p className="textError inputSete">O campo é obrigatório.</p>
                                    ) : ('')
                                    }

                                </div>
                            </div>
                        </div>
                        {
                        
                        
                        collaboratorUpdate?.nationality === 'Brasil' ||
                        collaborator?.nationality === 'Brasil' || 
                        collaborator?.nationality === undefined ||
                        collaboratorUpdate?.nationality === '' ?
                       


                        <div className='linha'>
                        <div className='box'>
                            <p className="textCampo">Natural do Estado</p>
                            <div>
                               
                                <SelectPersonalizado
                                    valorCampo={

                                        nationalityBuscaString && nationalityBuscaString.bornStateBusca ?
                                        nationalityBuscaString.bornStateBusca :
                                        nationalityBuscaString && nationalityBuscaString.bornStateBusca === '' ?
                                            '' :

                                            collaboratorUpdate && collaboratorUpdate.bornState ?
                                            ufEscolhido.nome 
                                            :
                                            collaborator && collaborator.bornState ?
                                                ufEscolhido.nome : ''
                                    }
                                    nameCampoBusca={"bornStateBusca"}
                                    nameButton={"bornState"}
                                    loadError={LoadbornState}
                                    myChangeHandler={myChangeHandlerSlects}
                                    option={optionFiltreUfEstados}

                                    loadOption={BuscaLoadOptionBornstate}
                                    validacaoValue={"estado"}
                                    validacaoOptionIntem={"estado"}
                                    typeSelect={"busca"}
                                    focusRef={focusbornState}
                                />
                                {LoadbornState ? (
                                <p className="textError inputNove">O campo é obrigatório.</p>
                                ) : ('')
                                }
                            </div>
                        </div>
                        <div className='box'>
                            <p className="textCampo">Natural da Cidade</p>
                            <div>
                               <SelectPersonalizado
                                   valorCampo={

                                       nationalityBuscaString && nationalityBuscaString.bornCityBusca ?
                                       nationalityBuscaString.bornCityBusca :
                                       nationalityBuscaString && nationalityBuscaString.bornCityBusca === '' ?
                                           '' :
                                            collaboratorUpdate && collaboratorUpdate.bornCity?
                                           cidade.nome 
                                           : collaborator?.bornCity   ?
                                           cidade.nome : ''
                                   }
                                   nameCampoBusca={"bornCityBusca"}
                                   nameButton={"bornCity"}
                                   loadError={LoadbornCity}
                                   myChangeHandler={myChangeHandlerSlects}
                                   option={optionFiltreUCidade}

                                   loadOption={BuscaLoadOptionCity}
                                   validacaoValue={"estado"}
                                   validacaoOptionIntem={"estado"}
                                   typeSelect={"busca"}
                                   focusRef={focusbornCity}
                               />
                                {LoadbornCity ? (
                                <p className="textError inputNove">O campo é obrigatório.</p>
                                ) : ('')
                                }
                            </div>
                        </div>
                    </div>




                        :<>
                             <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Natural do Estado</p>
                                    <div>
                                        <input ref={focusbornState} type="text" 
                                        name="bornStateString" 
                                        placeholder='Natural do Estado' 
                                        autocomplete="autocompleteoff"
                                        defaultValue={
                                            
                                            collaborator.bornStateString !== undefined ? 
                                            collaborator.bornStateString : ''
                                        
                                        } 
                                        onChange={onCollaboratorInputChange} className={LoadbornState ? 'inputOito inputErro' : 'inputOito'} />
                                       
                                        {LoadbornState ? (
                                        <p className="textError inputOito">O campo é obrigatório.</p>
                                             ) : ('')
                                   }
                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Natural da Cidade</p>
                                    <div>
                                        <input ref={focusbornCity} 
                                        type="text" name="bornCityString" 
                                        placeholder='Natural da Cidade' 
                                        autocomplete="autocompleteoff"
                                        defaultValue={
                                            collaborator.bornCityString !== undefined ? 
                                            collaborator.bornCityString : ''
                                        } onChange={onCollaboratorInputChange} 
                                        className={LoadbornCity ? 'inputNove inputErro' : 'inputNove'}
                                        />
                                        {LoadbornCity ? (
                                        <p className="textError inputNove">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        
                        }

                        {/* {collaborator.nationality === 'Outra' || collaboratorUpdate.nationality === 'Outra' ?
                        (
                            <>




                            </>
                        ) : (
                            <>
                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Natural do Estado</p>
                                    <div>
                                        <div className='cont-select inputOito'>
                                            <button className={LoadbornState ? 'inputErro select-' : 'select-'} ref={focusbornState} >
                                                <p>
                                                    {
                                                        collaboratorUpdate && collaboratorUpdate.bornState ?
                                                                ufEscolhido.nome 
                                                            :
                                                            collaboratorUpdate && collaboratorUpdate.bornState === '' ?
                                                                'Selecione' 
                                                            :
                                                            collaborator && collaborator.bornState ?
                                                                ufEscolhido.nome : 'Selecione'
                                                    }
                                                </p>
                                            </button>

                                            <div className="dropDown">
                                                <Scrollbars className='listDropDown' style={{  height: 120 }}>    
                                                    {
                                                        
                                                        ufEstados
                                                        .map((estado, index) => (
                                                            <button  key={index} name="bornState" value={estado.id}  onClick={onCollaboratorInputChange} className='item'>
                                                                {estado.nome}
                                                            </button>
                                                        ))
                                                    }
                                                </Scrollbars>
                                            </div>
                                        </div>


                                        {LoadbornState ? (
                                        <p className="textError inputOito">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
    
                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Natural da Cidade</p>
                                    <div>
                                        <div className='cont-select inputNove'>
                                            <button className={LoadbornCity ? 'inputErro select-' : 'select-'} ref={focusbornCity} >
                                                <p>
                                                    {
                                                        collaboratorUpdate && collaboratorUpdate.bornCity ?
                                                                cidade.nome 
                                                            :
                                                            collaboratorUpdate && collaboratorUpdate.bornCity === '' ?
                                                                'Selecione' 
                                                            :
                                                            collaborator && collaborator.bornCity ?
                                                                cidade.nome : 'Selecione'
                                                    }
                                                </p>
                                            </button>

                                            <div className="dropDown">
                                                <Scrollbars className='listDropDown' style={{  height: 120 }}>    
                                                    {
                                                        
                                                        citys
                                                        .map((city, index) => (
                                                            <button  key={index} name="bornCity" value={city.id}  onClick={onCollaboratorInputChange} className='item'>
                                                                {city.nome}
                                                            </button>
                                                        ))
                                                    }
                                                </Scrollbars>
                                            </div>
                                        </div>
                                        {LoadbornCity ? (
                                            <p className="textError inputNove">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                    </div>
                                </div>
                            </div>
                            </>
                        )
                        } */}
                        <div className='linha'>
                            <div className='box doc-dropzone'>
                                <p className="textCampo"></p>
                                <div>
                                    <Dropzone  onDrop={handleDrop} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                

                                </div>
                            </div>
                            <div className='arquivoAnexado'>


                                {filesSaved ?
                                filesSaved.map((filesaved, index) => (
                                    <div key={index} className="arquivo">

                                        <a className="nomeArquivo" href={filesaved.location} target="’_blank’">
                                            {filesaved.name}
                                        </a>
                                        <div>
                                            <button onClick={()=>deleteFile(filesaved)} className="remover">Remover</button>

                                        </div>
                                    </div>
                                    )) : ''}
                                {load ?
                                (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                                ('')}
                            </div>
                        </div>


                        <div className="inputOnze"></div>
                    </div>
                </div>


            </>
        );

}