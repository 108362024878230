import React from 'react';
// import { NavLink } from 'react-router-dom'
// import IconsInformation from '../../../../IconsInformation';
import HeaderAdmin from '../../../../HeaderAdmin';

const headerNovoDesligamento = () => {
    const nameprofile               = localStorage.getItem('@peopleview/nameProfile');
        return (
            <>
            <HeaderAdmin>
                    <div>
                        <h1>Novo desligamento</h1>
                        <p className="inforColaboraderes">
                            Desligando {nameprofile && nameprofile}
                        </p>
                    </div>

            </HeaderAdmin>
            </>
        );
}
export default headerNovoDesligamento;
