import React from 'react';
import { Dropdown } from "react-bootstrap";
import IconV from "../../../assets/images/v.svg";
import IconUp from "../../../assets/images/icon-arrow-up-green.svg";
import IconDown from "../../../assets/images/icon-arrow-dwon-red.svg";
import IconAliamento from '../../../assets/images/illustration-aliamento.svg';
import IconBemEstar from '../../../assets/images/illustration-bem-estar.svg';
import IconCarreira from '../../../assets/images/illustration-carreira.svg';
import IconCrescimentoPessoal from '../../../assets/images/illustration-crescimento-pessoal.svg';
import IconEmbaixadorismo from '../../../assets/images/illustration-embaixadorismo.svg';
import IconEstrutura from '../../../assets/images/illustration-estrutura.svg';
import IconFeedbackReconhecimento from '../../../assets/images/illustration-feedback-reconhecimento.svg';
import IconFelicidade from '../../../assets/images/illustration-felicidade.svg';
import IconInovacao from '../../../assets/images/illustration-inovacao.svg';
import IconJustica from '../../../assets/images/illustration-justica.svg';
import IconLideranca from '../../../assets/images/illustration-lideranca.svg';
import IconRelacionamentoInterpessoal from '../../../assets/images/illustration-relacionamento-interpessoal.svg';





export default function ManagementCategorias() {

    return (
        <>

            <div className='categorias'>
                <h2 className='titulo'>Categorias
                    <Dropdown  className="dropdown-icon ">
                        <Dropdown.Toggle>
                            <span>Nos últimos 7 dias</span>
                                <img src={IconV} alt="icone v" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <button>Nos últimos 7 dias</button>
                            <button>Nos últimos 15 dias</button>
                            <button>No último mês</button>
                        </Dropdown.Menu>
                    </Dropdown>
                </h2>
                <ul className='categorias-list'>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconAliamento} alt="Alinhamento com a empresa" className='alinhamento'/> 
                        </div>
                        <p>Alinhamento com a empresa</p>
                        <div className='up-down-temperatura'>
                            <span>10</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconBemEstar} alt="Bem-estar" className='bem-estar'/>  
                        </div>
                        <p>Bem-estar</p>
                        <div className='up-down-temperatura'>
                            <span>7</span>
                            <img src={IconDown} alt="Baixando"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconCarreira} alt="Carreira" className='carreira'/>  
                        </div>
                        <p>Carreira</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconCrescimentoPessoal} alt="Crescimento pessoal" className='crescimento-pessoal'/>  
                        </div>
                        <p>Crescimento pessoal</p>
                        <div className='up-down-temperatura'>
                            <span>10</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconEmbaixadorismo} alt="Embaixadorismo" className='embaixadorismo'/>  
                        </div>
                        <p>Embaixadorismo</p>
                        <div className='up-down-temperatura'>
                            <span>5</span>
                            <img src={IconDown} alt="Baixando"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconEstrutura} alt="Estrutura"  className='estrutura'/>  
                        </div>
                        <p>Estrutura</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconFeedbackReconhecimento} alt="Feedback e reconhecimento" className='feedback'/>  
                        </div>
                        <p>Feedback e reconhecimento</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconFelicidade} alt="Felicidade"  className='felicidade'/>  
                        </div>
                        <p>Felicidade</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconDown} alt="Baixando"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconInovacao} alt="Inovação"  className='inovacao'/>  
                        </div>
                        <p>Inovação</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconJustica} alt="Justiça" className='justica'/>  
                        </div>
                        <p>Justiça</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconLideranca} alt="Liderança" className='lideranca'/>  
                        </div>
                        <p>Liderança</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconDown} alt="Baixando"/>
                        </div>
                    </li>

                    <li className='box-leiaute '>
                        <div className='illustration'>
                            <img src={IconRelacionamentoInterpessoal} alt="Relacionamento interpessoal"  className='relacionamento-interpessoal'/>  
                        </div>
                        <p>Relacionamento interpessoal</p>
                        <div className='up-down-temperatura'>
                            <span>8</span>
                            <img src={IconUp} alt="Subindo"/>
                        </div>
                    </li>

                </ul>

            </div>
        </>
    );
}

