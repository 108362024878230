import React, { Component } from 'react';
// import api from '../../services/api';
// import { Link } from 'react-router-dom'
import './../Login/styles.scss';
import logo from '../../assets/images/logo.svg';

// import { decodeToken } from '../../services/auth';

export default class Recover extends Component {
    render() {
        return (
            <>
                <div className="pageLogin">
                    <img src={logo} alt="People View" className="logo"/>
                    <form className="formLogin recuperar-senha">
                        <h2 className="autera-senha-titulo">Recuperar Senha</h2>
                        <label className="autera-senha-subtitulo">Nova senha:</label>
                        <input type="text" name="Nova Senha" className="nova-senha" placeholder="Nova senha" required/>
                        <label className="autera-senha-subtitulo">Digite novamente:</label>
                        <input type="text" name="Nova Senha" className="nova-senha" placeholder="Digite novamente" required/>

                        <p className="senha-incopativel">Senhas diferentes</p>
                        <p className="carregando">Enviando...</p>
                        <button type="submit"  className="btnEntrar btnGreen" >Alterar senha</button>
                    </form>
                    <span className="copyright">© 2021 People View</span>
                </div>
            </>
        );
    }
}
