import React from 'react';
import Carregando from '../../../../../../assets/images/carregando.gif';
import { dateFormatedToStripe }  from '../../../../../../services/utils'

export default function ColProfileVacationSummary({
    id,
    loadHandleSubmit,
    loadInitialDate,
    loadFinalDate,
    vacationSummary,
    visible,
    myChangeHandler,
    handleSubmit,
    EditVacationSummaryVisible,
    loadVacation,
    vacationCompanyVestingPeriodSearchGroup,
    vacationCollaboratorVestingPeriodSearch,
    role,
}) {

    return (
        <>

            <div className="box">
                    <h3>Resumo de férias
                        {/* {
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                (role && role === 'admin') || (role && role === 'master') ? <button className="solicitar-ferias btnGreen" onClick={() => EditVacationSummaryVisible()}>Solicitar férias</button> : ''
                        } */}
                    </h3>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Saldo De Férias</p>
                            </div>
                            <div className="intemB">
                                <p>{vacationCompanyVestingPeriodSearchGroup?.balanceAvailable ? vacationCompanyVestingPeriodSearchGroup?.balanceAvailable : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Limite do benefício</p>
                            </div>
                            <div className="intemB">
                                <p>{vacationCompanyVestingPeriodSearchGroup?.limitConcessionPeriod ? dateFormatedToStripe(vacationCompanyVestingPeriodSearchGroup?.limitConcessionPeriod) : '-'}</p>
                            </div>
                        </li>
                        <li style={{border: '1px dashed #ccc'}}>
                        </li>

                        {
                            loadVacation ?
                                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            :
                            vacationCollaboratorVestingPeriodSearch &&
                            vacationCollaboratorVestingPeriodSearch.length !== 0 ?
                            vacationCollaboratorVestingPeriodSearch
                            .filter(vestingPeriod => vestingPeriod.status === "nao_utilizado")
                            .map((vestingPeriod, index) => (
                                <li key={index}>
                                    <div className="intemA">
                                        <p>Ano referência</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{vestingPeriod?.referenceYear ? vestingPeriod?.referenceYear +'-'+ (Number(vestingPeriod?.referenceYear +1)) : '-'}</p>
                                    </div>
                                    <div className="intemA">
                                        <p>Saldo disponível</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{vestingPeriod?.balanceAvailable ? vestingPeriod?.balanceAvailable : '-'}</p>
                                    </div>
                                </li>
                            ))
                            :
                            ''
                        }
                    </ul>
                </div>
        </>
    );
}

