import React, { useState, useEffect } from "react";
import api from "../../services/api";
// import { decodeToken } from "../../services/auth";

import './../Login/styles.scss';
import logo from '../../assets/images/logo.svg';

import swal from "sweetalert";

export default function FirstAccessCompany(props) {

    const { token } = props.match.params;
    const { path }  = props.match;

    const [ company, setCompany,  ] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        async function loadcompanyTrial() {

            await api.get(`/company-trial/${token}`)
            .then(response => {

                setCompany(response.data);

            })
            .catch(error => {

                console.log(error.response.data);

            });
        }
        loadcompanyTrial();



    }, [token]);




    const [ collaboratorCompany, setCollaboratorCompany,  ] = useState([]);

    const companyId   = company?._id;
    const currentDate = new Date()
    const year        = currentDate.getFullYear()
    const email       = collaboratorCompany?.email;
    const role        = collaboratorCompany?.role;
    const status      = collaboratorCompany?.status;

    useEffect(() => {

        if(companyId !== undefined) {

            async function loadcollaboratorFirstAcccesCompany() {

                await api.get(`/collaborator-trial/?companyId=${companyId}`)
                .then(response => {

                    setCollaboratorCompany(response.data);

                })
                .catch(error => {

                    console.log(error.response.data);

                });
            }
            loadcollaboratorFirstAcccesCompany();

        }

        if( email  !== undefined &&
            role   === 'master'  &&
            status === 'Ativo'
        ) {

            async function loadfirstlogincompany() {

                let access    = email;
                let password  = 'Sucesso@'+year;

                await api
                .post('/login', { access, password, companyId: company?._id })
                .then((res) => {


                    localStorage.setItem('@peopleview/token', res.data.token);

                }).catch(error => console.log(error))

            }
            loadfirstlogincompany();


        }


    }, [companyId, year, email, role, status, company._id]);







    const [load, setLoad] = useState(false);

    // const user = decodeToken();


    const [state, setState]           = useState([])

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;
        setState({...state, [nam]: val})
    }


    async function renewPassword(event) {
        event.preventDefault();
        const { password } = state;
        //alert(password)
        setLoad(true)
        await api.put('/first-access-company', { password, path: path, company, collaboratorCompany })
            .then(response => {

                setLoad(false)
                swal({ icon: "success", title: "Sucesso!", text: "Senha atualizada com sucesso." });

                let role = response.data.role;
                if(role === 'user') {
                    setTimeout(() => {
                        window.location.href = "/dashboard-user";
                    }, 2000);
                }
                else if(role === 'admin' || role === 'master') {
                    setTimeout(() => {
                        window.location.href = "/dashboard";
                    }, 2000);
                } else {
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 2000);
                }

            })
            .catch((error) => {
                setLoad(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao atualizar a senha, tente novamente mais tarde." });
            });
    }

    return (
        <>

            <div className="pageLogin">
                <img src={logo} alt="People View" className="logo"/>

                <form className="formLogin trial" onSubmit={renewPassword}>
                    <h2 className="titulo-primeiro-acesso">Olá, {collaboratorCompany.name}</h2>
                    <p className="subtitulo-primeiro-acesso">Para continuar, crie uma nova senha:</p>
                    <label className="autera-senha-subtitulo">Nova senha:</label>
                    <input type="password" name="password" onChange={myChangeHandler} className="nova-senha" placeholder="Nova senha" required/>
                    <label className="autera-senha-subtitulo">Digite novamente:</label>
                    <input type="password" name="repassword" onChange={myChangeHandler} className="nova-senha" placeholder="Digite novamente senha" required/>
                    {state.repassword && state.password !== state.repassword ? (<p className="senha-incopativel">Senhas diferentes</p>) : ('')}
                    {load ?
                    (<p className="carregando">Enviando...</p>) :
                    ('')
                    }
                    <button type="submit"  className="btnEntrar btnGreen" disabled={(state.password !== state.repassword || state.length === 0 || state.password === '' || state.repassword === '' || state.password.length <=7 ||state.repassword.length <=7 )}>Criar senha</button>
                </form>
                <span className="copyright">© {new Date().getFullYear()} People View</span>
            </div>
            {/*
            <Container className="h-100 bg-white" fluid>
                <Row className="h-100 justify-content-center">
                    <Col xs={12} sm={8} lg={6} className="d-flex align-items-self justify-content-between flex-column">

                        <div className="px-lg-5">
                            <h1 className="d-inline-flex bg-primary text-uppercase px-2 pb-0 pt-2">Olá, {user.name}</h1>
                            <p className="text-muted mb-4 mt-2">Para continuar, crie uma nova senha:</p>
                            <Form onSubmit={this.renewPassword}>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <Form.Label className="text-muted">Senha</Form.Label>
                                        <Form.Control className="rounded-0" type="password" name="password" onChange={this.myChangeHandler} required />
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <Form.Label className="text-muted">Repetir senha</Form.Label>
                                        <Form.Control className="rounded-0" type="password" name="repassword" onChange={this.myChangeHandler} required />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={12} className="d-flex align-items-center justify-content-end">
                                        <Button type="submit" className="rounded-0 text-white" disabled={(password !== repassword)}>Alterar senha</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="text-muted small py-3 text-center">
                            &copy; {new Date().getFullYear()} People View - All Rights Reserved
                        </div>
                    </Col>
                </Row>
            </Container>
            */}
        </>
    );
}
