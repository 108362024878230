import React from 'react';
import { NavLink } from 'react-router-dom'
import { decodeToken } from '../../services/auth';
import { Scrollbars } from 'react-custom-scrollbars';
import IconClose from '../../assets/images/close.svg';
import './styles.scss';
import logo from '../../assets/images/logo.svg';

export default function SideBar({ path, fecharSidBarAbrir }) {
    const decoded = decodeToken();
    return (
        <>
            <aside className="sidebarAdm">
                <div className='logo-btn'>
                    <button onClick={fecharSidBarAbrir} className='btn-close'><img src={IconClose} alt="fechar menu"  /></button>
                    <img src={logo} alt="People View" className='logo'/>
                </div>

                <Scrollbars className='listDropDown'
                >
                <nav>
                    <ul>
                        {
                            (decoded && decoded.user && decoded.user.role && decoded.user.role === 'admin')
                            ||
                            (decoded && decoded.user && decoded.user.role && decoded.user.role === 'master') ?
                            <li>
                            <NavLink to="/dashboard" exact activeClassName="active" className="link"><i></i>
                                Painel
                            </NavLink>
                            </li>
                            :
                            ''
                        }
                        <li>
                        <NavLink to="/colaboradores/ativos"
                        className={
                            path === '/colaboradores/ativos' ||
                            path === '/colaboradores/admissao' ||
                            path === '/colaboradores/processo-desligamento' ||
                            path === '/colaboradores/:id/pessoal' ||
                            path === '/colaboradores/:id/pessoal/enderecos-contatos' ||
                            path === '/colaboradores/:id/pessoal/documentos' ||
                            path === '/colaboradores/:id/pessoal/faltas-e-afastamentos' ||
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-documentos/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-beneficios/:id' ||
                            path === '/colaboradores/nova-admissao/finalizado/:id' ||
                            path === '/colaboradores/desligamento/:id/contabilidade' ||
                            path === '/colaboradores/desligamento/:id/rescisao-e-documentos' ||
                            path === '/colaboradores/desligamento/:id/finalizar' ||
                            path === '/colaboradores/desligamento/:id/finalizado' ||
                            path === '/colaboradores/lista-colaboradores-desligado' ||
                            path === '/colaboradores/:id/profissional' ||
                            path === '/colaboradores/:id/profissional/beneficios-do-colaborador' ||
                            path === '/colaboradores/:id/profissional/ferias' ||
                            path === '/colaboradores/:id/profissional/documentos'  ||
                            path === '/colaboradores/:id/profissional/folha-de-pagamento' ||
                            path === '/colaboradores/:id/profissional/jornada-de-trabalho' ||
                            path === '/colaboradores/:id/historico-salario-e-promocoes' ||
                            path === '/colaboradores/:id/historicos' ||
                            path === '/colaboradores/:id/anotacoes' ||
                            path === '/folha-de-pagamento/ano/mes/tipo/:id' ||
                            path === '/folha-de-pagamento/ano/mes/enviar/:payroll' ||
                            path === '/folha-de-pagamento' ||
                            path === '/colaboradores/:id/dados-de-acesso' ||
                            path === '/colaboradores/:id/historico-ultimas-alteracoes' ||

                            path === '/folha-de-ponto/:id' ||
                            path === '/ponto/configuracoes' ||
                            path === '/ponto/aprovacoes' ||
                            path === '/ponto/editar-jornada' ||
                            path === '/ponto/cadastrar-jornada' ||
                            path === '/ponto/jornadas' ||
                            path === '/ponto/alertas' ||
                            path === '/ponto' ||

                            path === '/beneficios' ||
                            path === '/beneficios/criar' ||
                            path === '/beneficios/:id/editar' ||
                            path === '/beneficio/informacoes/:id' ||

                            path === '/cargos' ||

                            path === '/departamentos' ||
                            
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/' ||
                            path === '/colaboradores/nova-admissao/enviar-contador/:id' ||

                            path === '/ferias' ||
                            path === '/ferias/:id' ||
                            path === '/feriascoletivas/cadastrar' ||
                            path === '/feriascoletivas/cadastrar/:id' ||
                            path === '/ferias/solicitacao-de-ferias/:id' ||
                            path === '/ferias/cadastrar-ferias-coletivas'  
                            ?
                            "active" :
                            ''}

                        ><i></i>Administrativo
                        </NavLink>
                {  
                    path === '/colaboradores/ativos' ||
                    path === '/colaboradores/admissao' ||
                    path === '/colaboradores/processo-desligamento' ||
                    path === '/colaboradores/:id/pessoal' ||
                    path === '/colaboradores/:id/pessoal/enderecos-contatos' ||
                    path === '/colaboradores/:id/pessoal/documentos' ||
                    path === '/colaboradores/:id/pessoal/faltas-e-afastamentos' ||
                    path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                    path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id' ||
                    path === '/colaboradores/nova-admissao/formulario-documentos/:id' ||
                    path === '/colaboradores/nova-admissao/formulario-beneficios/:id' ||
                    path === '/colaboradores/nova-admissao/finalizado/:id' ||
                    path === '/colaboradores/desligamento/:id/contabilidade' ||
                    path === '/colaboradores/desligamento/:id/rescisao-e-documentos' ||
                    path === '/colaboradores/desligamento/:id/finalizar' ||
                    path === '/colaboradores/desligamento/:id/finalizado' ||
                    path === '/colaboradores/lista-colaboradores-desligado' ||
                    path === '/colaboradores/:id/profissional' ||
                    path === '/colaboradores/:id/profissional/beneficios-do-colaborador' ||
                    path === '/colaboradores/:id/profissional/ferias' ||
                    path === '/colaboradores/:id/profissional/documentos'  ||
                    path === '/colaboradores/:id/profissional/folha-de-pagamento' ||
                    path === '/colaboradores/:id/profissional/jornada-de-trabalho' ||
                    path === '/colaboradores/:id/historico-salario-e-promocoes' ||
                    path === '/colaboradores/:id/dados-de-acesso' ||
                    path === '/colaboradores/:id/historicos' ||
                    path === '/colaboradores/:id/historico-ultimas-alteracoes' ||
                    path === '/habilidades' ||
                    path === '/beneficios' ||
                    path === '/beneficios/criar' ||
                    path === '/beneficios/:id/editar' ||
                    path === '/beneficio/informacoes/:id' ||
                    path === '/cargos' ||
                    path === '/departamentos' ||
                    path === '/ferias' ||
                    path === '/ferias/:id' ||
                    path === '/feriascoletivas/cadastrar' ||
                    path === '/feriascoletivas/cadastrar/:id' ||
                    path === '/ferias/solicitacao-de-ferias/:id' ||
                    path === '/ferias/cadastrar-ferias-coletivas'  ||
                    path === '/folha-de-ponto/:id' ||
                    path === '/ponto/configuracoes' ||
                    path === '/ponto/aprovacoes' ||
                    path === '/ponto/editar-jornada' ||
                    path === '/ponto/cadastrar-jornada' ||
                    path === '/ponto/jornadas' ||
                    path === '/ponto/alertas' ||
                    path === '/ponto' ||
                    path === '/folha-de-pagamento' ||
                    path === '/folha-de-pagamento/ano/mes/enviar/:payroll' ||
                    path === '/folha-de-pagamento/ano/mes/tipo/:id' ||
                    path === '/colaboradores/nova-admissao/enviar-contador/:id' ||

                    path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/' ||

                    path === '/colaboradores/:id/anotacoes' ?  <>
                        <ul >
                            <li>
                                <NavLink to="/colaboradores/ativos"
                                className={
                                    path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/' ||
                                    
                                    path === '/colaboradores/ativos' ||
                                    path === '/colaboradores/admissao' ||
                                    path === '/colaboradores/processo-desligamento' ||
                                    path === '/colaboradores/:id/pessoal' ||
                                    path === '/colaboradores/:id/pessoal/enderecos-contatos' ||
                                    path === '/colaboradores/:id/pessoal/documentos' ||
                                    path === '/colaboradores/:id/pessoal/faltas-e-afastamentos' ||
                                    path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                                    path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id' ||
                                    path === '/colaboradores/nova-admissao/formulario-documentos/:id' ||
                                    path === '/colaboradores/nova-admissao/formulario-beneficios/:id' ||
                                    path === '/colaboradores/nova-admissao/enviar-contador/:id' ||
                                    path === '/colaboradores/nova-admissao/finalizado/:id' ||
                                    path === '/colaboradores/desligamento/:id/contabilidade' ||
                                    path === '/colaboradores/desligamento/:id/rescisao-e-documentos' ||
                                    path === '/colaboradores/desligamento/:id/finalizar' ||
                                    path === '/colaboradores/desligamento/:id/finalizado' ||
                                    path === '/colaboradores/lista-colaboradores-desligado' ||
                                    path === '/colaboradores/:id/profissional' ||
                                    path === '/colaboradores/:id/profissional/beneficios-do-colaborador' ||
                                    path === '/colaboradores/:id/profissional/ferias' ||
                                    path === '/colaboradores/:id/profissional/documentos'  ||
                                    path === '/colaboradores/:id/profissional/folha-de-pagamento' ||
                                    path === '/colaboradores/:id/profissional/jornada-de-trabalho' ||
                                    path === '/colaboradores/:id/historico-salario-e-promocoes' ||
                                    path === '/colaboradores/:id/historico-ultimas-alteracoes' ||
                                    path === '/colaboradores/:id/dados-de-acesso' ||
                                    path === '/colaboradores/:id/historicos' ||
                                    path === '/colaboradores/:id/anotacoes' ?
                                    "active" :
                                    ''}
                                ><i></i>
                                    Colaboradores
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/ponto"
                                className={
                                    path === '/folha-de-ponto/:id' ||
                                    path === '/ponto/configuracoes' ||
                                    path === '/ponto/aprovacoes' ||
                                    path === '/ponto/editar-jornada' ||
                                    path === '/ponto/cadastrar-jornada' ||
                                    path === '/ponto/jornadas' ||
                                    path === '/ponto/alertas' ||
                                    path === '/ponto' ?
                                    "active" :
                                    ''}
                                ><i></i>
                                    Ponto
                                </NavLink>
                            </li>


                            {
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'admin')
                                ||
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'master') ?
                                <li>
                                <NavLink to="/habilidades"
                                className={
                                    path && path === '/habilidades'  ?
                                    "active" :
                                    ''}


                                >
                                <i></i>Habilidades

                                </NavLink>
                                </li>
                                :
                                ''
                            }
                            {
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'admin' )
                                ||
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'master') ?
                                <li>
                                <NavLink to="/beneficios"
                                className={
                                    (path && path === '/beneficio/informacoes/:id') ||
                                    (path && path === '/beneficios/criar') ||
                                    (path && path === '/beneficios/:id/editar') ?
                                    "active" :
                                    ''}
                                ><i></i>
                                    Benefícios
                                </NavLink>
                                </li>
                                :
                                ''
                            }
                            {
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'admin' )
                                ||
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'master') ?
                                <li>
                                <NavLink to="/folha-de-pagamento"
                                className={
                                    (path && path === '/folha-de-pagamento/ano/mes/tipo/:id') ||
                                    (path && path === '/folha-de-pagamento/ano/mes/enviar/:payroll') ||
                                    (path && path === '/folha-de-pagamento') ?
                                    "active" :
                                    ''}><i></i>
                                Folha de pagamento
                                </NavLink>
                                </li>
                                :
                                ''
                            }
                            {
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'admin' )
                                ||
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'master') ?
                                <li>
                                <NavLink to="/cargos" exact activeClassName="active"><i></i>
                                Cargos
                                </NavLink>
                                </li>
                                :
                                ''
                            }
                            {
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'admin' )
                                ||
                                (decoded && decoded.user && decoded.user.role && decoded.user.role === 'master') ?
                                <li>
                                <NavLink to="/departamentos" exact activeClassName="active"><i></i>
                                    Departamentos
                                </NavLink>
                                </li>
                                :
                                ''
                            }
                            <li>
                                <NavLink to="/ferias"
                                className={
                                    (path && path === '/ferias') ||
                                    (path && path === '/ferias/:id') ||
                                    (path && path === '/feriascoletivas/cadastrar') ||
                                    (path && path === '/feriascoletivas/cadastrar/:id') ||
                                    (path && path === '/ferias/solicitacao-de-ferias/:id') ||
                                    (path && path === '/ferias/cadastrar-ferias-coletivas') ?
                                    "active" :
                                    ''}><i></i>
                                    Férias
                                </NavLink>
                            </li>
                        </ul>

                    </> : ""}


                        </li>
                        {
                            decoded && decoded.user && decoded.user.sector && decoded.user.sector === 'Recursos Humanos' ?
                            <li>
                                <NavLink to="/contato-com-colaborador" exact className={
                                path === '/contato-com-colaborador' ||
                                path === '/mural-de-anuncios' ?
                                "active" :
                                ''}><i></i>Comunicação</NavLink>
                            </li>
                            :
                            ''
                        }
                        <li>
                            <NavLink to="/recrutamento/talentos"
                                className={
                                    path === '/recrutamento/talentos' ||
                                    path === '/recrutamento/talentos/pastas'||
                                    path === '/recrutamento/talentos/candidatos' ||
                                    path === '/recrutamento/perfil-candidato/:id'||
                                    path === '/recrutamento/cadastro-candidato'||
                                    path === '/recrutamento/editar-candidato/:id'||
                                    path === '/recrutamento/editar-vaga/:id' ||
                                    path === '/recrutamento/vagas'||
                                    path === '/recrutamento/cadastrar-vaga' ||
                                    path === '/recrutamento/talentos/pasta/:id'||
                                    path === '/recrutamento/configuracoes-da-pagina' ||
                                    path === '/recrutamento/politica-e-privacidade-da-pagina' ||
                                    path === '/recrutamento/personalizar-pagina'  ? "active" :
                                ''}
                            ><i></i>Recrutamento e seleção</NavLink>

                            {
                                path === '/recrutamento/talentos' ||
                                path === '/recrutamento/talentos/pastas'||
                                path === '/recrutamento/talentos/candidatos' ||
                                path === '/recrutamento/perfil-candidato/:id'||
                                path === '/recrutamento/cadastro-candidato'||
                                path === '/recrutamento/editar-candidato/:id'||
                                path === '/recrutamento/talentos/pasta/:id'  ||
                                path === '/recrutamento/editar-vaga/:id' ||
                                path === '/recrutamento/vagas'||
                                path === '/recrutamento/cadastrar-vaga' ||
                                path === '/recrutamento/configuracoes-da-pagina' ||
                                path === '/recrutamento/politica-e-privacidade-da-pagina' ||
                                path === '/recrutamento/personalizar-pagina' ?
                                    <>
                                        <ul>
                                            <li>
                                                <NavLink to="/recrutamento/talentos" className={
                                                    path === '/recrutamento/talentos' ||
                                                    path === '/recrutamento/talentos/pastas'||
                                                    path === '/recrutamento/talentos/candidatos' ||
                                                    path === '/recrutamento/perfil-candidato/:id'||
                                                    path === '/recrutamento/cadastro-candidato'||
                                                    path === '/recrutamento/editar-candidato/:id'||
                                                    path === '/recrutamento/talentos/pasta/:id' ? "active" :
                                                ''} ><i></i>Talentos</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/recrutamento/vagas" className={
                                                    path === '/recrutamento/editar-vaga/:id' ||
                                                    path === '/recrutamento/vagas'||
                                                    path === '/recrutamento/cadastrar-vaga' ? "active" :
                                                ''} ><i></i>Vagas</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/recrutamento/personalizar-pagina" className={
                                                    path === '/recrutamento/configuracoes-da-pagina' ||
                                                    path === '/recrutamento/politica-e-privacidade-da-pagina' ||
                                                    path === '/recrutamento/personalizar-pagina' ? "active" :
                                                ''} ><i></i>Personalizar página</NavLink>
                                            </li>
                                        </ul>
                                    </>
                                :""

                            }
                        </li>
                    </ul>
                </nav>

                </Scrollbars>

            </aside>
        </>
    );
}
