import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import SubHeaderTalents from '../../components/RecruitmentAndSelection/subHeaderTalents';
import { NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../assets/images/more.svg';
import iconOrdenation from '../../assets/images/ordenation.svg';
import Carregamento from '../../assets/images/carregando.gif';
import { formatDateString } from '../../services/utils';
import ModalAddFolder from '../../components/RecruitmentAndSelection/talents/modalAddFolder';
import Paginetion from '../../components/Reused/Pagination'
import api from '../../services/api';
import { toast } from 'react-toastify';
import { decodeToken } from '../../services/auth';
import { Scrollbars } from 'react-custom-scrollbars';


import swal from 'sweetalert';
// import { Scrollbars } from 'react-custom-scrollbars';
import './styles.scss';
// import { constrainPoint } from '@fullcalendar/core';


export default function Talents(props,{valueCLT}) {

    const { path } = props.match;
    const { pathname } = props.location;
    localStorage.setItem('@peopleview/voltar_recrutamento_talentos', pathname);
    const { user } = decodeToken();
    const role    = user.role;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages, setPages ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ limit,  ] = useState(20);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pagesTotalItem, setPagesTotalItem ] = useState(0);

    // status
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [
        status,
        // setStatus
    ] = useState('active');

    // sortBy
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sortBy, setSortBy ] = useState('name');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModalAddFolder, setVisibleModalAddCandite] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inforFolder, setInforFolder ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidates, setCandidates ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setBtnFilterAsFavoriteClicked] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [btnFilterMarkAsFavorite, setBtnFilterMarkAsFavorite] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nameSearch, setNameSearch] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadNameFolder, setLoadNameFolder ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidateFilterForThisFolder, setCandidateFilterForThisFolder ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allCandidateExclude, setAllCandidateExclude] = useState([]);

    async function modalAddFolderOpen() {
        setInforFolder("")
        setVisibleModalAddCandite(!visibleModalAddFolder);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadLastedCandidate, setLoadLastedCandidate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterPerCandidate, setFilterPerCandidate] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadLaborBond, setLoadLaborBond ] = useState("");

   const myChangeHandlerLoborBondFilter= event =>{
        let val = event.target.value;
        setLoadLaborBond(val)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadCandidates(status,loadLaborBond,limit, page, sortBy, nameFilter);
        loadAllFolderAtivos();
    }, [status,loadLaborBond,limit, page, sortBy, nameFilter]);

    // ROTA PRINCIPAL
    async function loadCandidates(
        status,loadLaborBond,limit, page, sortBy, nameFilter
    ) {
        setLoadLastedCandidate(true)
        if(status === '') {
            status = 'active'
        }
        if(sortBy === '') {
            sortBy = 'name'
        }
        await api.get(`/candidate/?name=${nameFilter}&laborBond=${loadLaborBond}&status=${status}&sortBy=${sortBy}&limit=${limit}&page=${page}`)
        .then(response => {
            setFilterPerCandidate(response.data.candidatesSearch);
            setPage(Number(response.data.page));
            setPages(response.data.pages);
            setLoadLastedCandidate(false)
            setPagesTotalItem(response.data.total);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // FUNÇÃO DO FILTRO BUSCAR
    async function loadCandidatesFilter(event) {
        if(role === 'admin' || role === 'master') {
            const name = event.target.value;
            setNameFilter(event.target.value);
            loadCandidates(status,loadLaborBond, page, sortBy, name)
        }
    }

    // => MODAL FOLDER
    async function modalAddFolderHide() {
        setVisibleModalAddCandite(false);
    }

    // => Count Folder
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ folderAtivos, setFolderAtivos] = useState([]);

    async function loadAllFolderAtivos() {

        // setLoadFolderAllCount(true)
        await api.get('/folder-all-count/?status=active')
        .then(response => {
            setFolderAtivos(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    async function moveAndEditCandidateToFolder(e) {
        let values        = e.target.value.split('&&');
        let idFolder      = values[0];
        let nameFolder    = values[1];
        let idCandidate   = values[2];
        let nameCandidate = values[3];
        swal({
            title: "Atenção",
            text: `Deseja realmente alterar o(a) candidato(a) ${nameCandidate} para à pasta ${nameFolder}?`,
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.put(`/candidate/${idCandidate}`, { folder: idFolder })
                .then(response => {
                    setCandidates([])
                    swal({ icon: "success", title: "Sucesso!", text: "Pasta de talentos mudadar com sucesso!" });

                }).catch(error => {

                    // console.log(error?.response?.data?.message);
                    if(error?.response?.data?.message) {
                        swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    }
                    else {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao mudar pasta de talentos, tente novamente mais tarde." });
                    }
                });

            }

        })



    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectLaborBond, setIsActiveSelectLaborBond] = useState(false);
    async function SelectLaborBond() { setIsActiveSelectLaborBond(!isActiveSelectLaborBond) }

    return (
        <>
            <Layout
            path={props.match.path}>
                <ModalAddFolder
                    visibleModalAddFolder={visibleModalAddFolder}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                    path={props.match.path}
                    inforFolder={inforFolder}
                    setInforFolder={setInforFolder}
                    modalAddFolderHide={modalAddFolderHide}
                    setCandidates={setCandidates}
                    candidates={candidates}
                    setBtnFilterAsFavoriteClicked={setBtnFilterAsFavoriteClicked}
                    setBtnFilterMarkAsFavorite={setBtnFilterMarkAsFavorite}
                    btnFilterMarkAsFavorite={btnFilterMarkAsFavorite}
                    setNameSearch={setNameSearch}
                    nameSearch={nameSearch}
                    setLoadNameFolder={setLoadNameFolder}
                    loadNameFolder={loadNameFolder}
                    setCandidateFilterForThisFolder={setCandidateFilterForThisFolder}
                    candidateFilterForThisFolder={candidateFilterForThisFolder}
                    setAllCandidateExclude={setAllCandidateExclude}
                    allCandidateExclude={allCandidateExclude}
                />
                <Header
                    path={path}
                    pathname={pathname}
                    modalAddFolderOpen={modalAddFolderOpen}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                />
                <div className='page-talents'>
                    <SubHeaderTalents />
                    <div className='list-all-candidates'>
                        <div className='title-filter'>
                            <h2 className='title-box'>Todos os candidatos</h2>

                            <div className="filter">
                                <div className='checks'>
                                    <p>Mostrando</p>
                                    <div className='list'>

                                        <div className="select-busca-btn">
                                                <button
                                                    className="menu-button"
                                                    onBlur={ () => (setIsActiveSelectLaborBond(false)) }
                                                    onClick={ SelectLaborBond }
                                                >
                                                    <span>
                                                    {
                                                        loadLaborBond && loadLaborBond !== "" ?
                                                            loadLaborBond  === "PJ" ? "Pessoa Jurídica"
                                                            : loadLaborBond
                                                        :"Todos os Regime"

                                                    }

                                                    </span>

                                                </button>
                                                <nav className={`menu ${isActiveSelectLaborBond ? "ativo": ""}`} >

                                                    <Scrollbars className='listDropDown'  style={{  height: 100
                                                    }}>
                                                        <ul>

                                                            {
                                                            [
                                                                "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "Pessoa Jurídica", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                                            ]
                                                                .map((LaborBond, index) => (
                                                                    <li key={index}>
                                                                        <button
                                                                        value={LaborBond === "Pessoa Jurídica" ? "PJ" : LaborBond }
                                                                        name="laborBond"
                                                                        onClick={myChangeHandlerLoborBondFilter}
                                                                        >{LaborBond}</button>
                                                                    </li>
                                                                ))}
                                                        </ul>

                                                    </Scrollbars>
                                                </nav>
                                            </div>




                                    </div>
                                </div>
                                <input
                                    type="text"
                                    className='busca'
                                    value={nameFilter && nameFilter !== undefined ? nameFilter : ''}
                                    onChange={loadCandidatesFilter}
                                    placeholder='Filtre por nome ou setor'
                                />
                            </div>
                        </div>
                        <div className='candidates-table'>
                            <div className='head-table grid-table'>
                                <div className='nome'>Nome</div>
                                <div className='data'>Recebido em</div>
                                <div className='setor'>Setor desejado</div>
                                <div className='pasta'>Pasta</div>
                                <div className='regime'>Regime</div>
                                <div className='curriculo'>Currículo</div>
                                <div className='view-more'>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconOrdenation} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button onClick={
                                                ()=> setSortBy('mais_recente')
                                                // ()=>loadCandidates('',page, 'mais_recente', nameFilter)
                                                } type="button" >
                                            Mais recentes primeiro
                                            </button>
                                            <button
                                            // mais_antigo
                                            onClick={
                                                ()=> setSortBy('mais_antigo')
                                            } type="button"
                                            >
                                            Mais antigos primeiro
                                            </button>
                                            <button onClick={
                                                ()=> setSortBy('por_nome')
                                            } type="button" >
                                            Nome em ordem alfabética
                                            </button>
                                            <button onClick={
                                                ()=> setSortBy('por_setor')
                                            } type="button" >
                                            Setor em ordem alfabética
                                            </button>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                </div>
                            </div>

                        <h4 className='infor-filtro'>{
                            sortBy === 'mais_recente'  ?
                            <> Mais recentes  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                            :
                            sortBy === 'mais_antigo' ?
                            <> Mais antigos  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                            :
                            sortBy === 'por_nome' ?
                            <> Ordem alfabética  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                            :
                            sortBy === 'por_setor' ?
                            <> Ordem alfabética por setor  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                            :
                            sortBy === 'mais_recente' ?
                            'Mais recentes'
                            :
                            sortBy === 'mais_antigo' ?
                            'Mais antigos'
                            :
                            sortBy === 'por_nome' ?
                            'Por Ordem alfabética'
                            :
                            sortBy === 'por_setor' ?
                            'Por setor em ordem alfabética'
                            :
                            loadLaborBond && loadLaborBond !== "" ?
                            loadLaborBond :""
                        }</h4>
                            <div className='body-table'>
                            {
                                loadLastedCandidate ?
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                    </>
                                    : filterPerCandidate.length === 0 ?

                                    <div className='nenhum-candidato'>
                                    <p>Nenhum candidato. <br/>Anuncie vagas ou cadastre manualmente.</p>
                                    <NavLink to="/recrutamento/cadastro-candidato"className='btnGreen more'> Cadastrar novo candidato </NavLink>
                                </div> :
                                filterPerCandidate !== undefined ? filterPerCandidate
                                .map((candidate, index) => (
                                <>
                                <div className='grid-table candidates' key={index}>
                                    <NavLink to={`/recrutamento/perfil-candidato/${candidate?._id}`} className="link-perfil">
                                        <div className='nome'>{candidate?.name}</div>
                                        <div className='data'> {formatDateString(candidate?.createdAt)}  </div>
                                        <div className='setor'>{candidate?.sector?.name ??  "-"}</div>
                                        <div className='pasta'>{candidate?.folder?.name ?? "-"}</div>
                                        <div className='regime'>{ candidate.laborBond === "PJ"  ?  "Pessoa Jurídica" : candidate.laborBond }</div>
                                        <div className='curriculo'>
                                            {
                                                candidate && candidate?.curriculum !== undefined ?
                                                <><a  target="’_blank’" href={candidate && candidate.curriculum}>Ver currículo</a></>:""
                                            }
                                        </div>
                                    </NavLink>
                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <NavLink to={`/recrutamento/perfil-candidato/${candidate?._id}`} >
                                                    Ver perfil
                                                </NavLink>
                                                <NavLink to={`/recrutamento/editar-candidato/${candidate?._id}`} >
                                                    Editar perfil
                                                </NavLink>
                                                <div className='mudar-pasta'>
                                                    <p>Mover para</p>
                                                        <select onChange={moveAndEditCandidateToFolder} name="folderSelect">
                                                            <option>Selecione a pasta</option>
                                                            {
                                                            folderAtivos &&
                                                            folderAtivos.length !== 0 ?
                                                            folderAtivos.map((folder, index) => (
                                                                <option key={index} value={folder._id+'&&'+folder.name+'&&'+candidate._id+'&&'+candidate.name}>{folder.name}</option>
                                                            ))
                                                            :
                                                            <option>Nenhuma pasta para selecionar</option>
                                                            }
                                                        </select>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </div>

                                </>
                                )) : ("")}
                            </div>
                        </div>

                {
                    loadLastedCandidate ? "":
                    <Paginetion
                        pages={pages}
                        setPage={setPage}
                        page={page}
                        limit={limit}
                        pagesTotalItem={pagesTotalItem}
                    /> 
                 }
            </div>
        </div>

        </Layout>
        </>
    );
}

