import React from 'react';
import { Tab, Tabs } from "react-bootstrap";
import IllustrationPraia from '../../../assets/images/illustration-praia.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import { dateFormatedToStripe } from '../../../services/utils'

export default function UserMyVacation({
    loadPageHistoryApproved,
    historyApproved,
    loadPageHistoryFailed,
    historyFailed,
    loadPageHistoryCollectiveVacation,
    historyCollectiveVacation,
}) {

    return (
        <>
            <div className="historico-ferias">
                <h2 className="titulo-box">Histórico</h2>
                <Tabs defaultActiveKey="Aprovadas" id="uncontrolled-tab-example " className="mb-3">
                    <Tab eventKey="Aprovadas" title="Aprovadas">
                        <div className="lista-historico">
                            <ul className="lista">
                                {
                                    loadPageHistoryApproved ?
                                    (
                                        <>
                                        <div className="carregamento">
                                            <img alt="" src={Carregamento} />
                                        </div>
                                        </>
                                    ):
                                    historyApproved.length !== 0 ? historyApproved
                                    .map((historyApprovedList, index) => (
                                    <>
                                        <li key={index}>
                                            <div className="infor-cal">

                                                {
                                                    historyApprovedList?.collaborator?.avatar !== undefined ?
                                                    <>
                                                    <div className="img-col">
                                                        <img src={historyApprovedList?.collaborator?.avatar} alt="nome" />
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="semImgCol">
                                                            <img src={IconUserNone} alt="coll" />
                                                        </div>
                                                    </>
                                                }
                                                <div className="nome-setor">
                                                    <h5 className="nome">{historyApprovedList?.collaborator?.name}</h5>
                                                    <div className="setor">
                                                        <p className="setor">{historyApprovedList?.collaborator?.occupation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="info-historico">
                                                <p>De <span>{dateFormatedToStripe(historyApprovedList?.startDate)}</span></p>
                                                <p>Até <span>{dateFormatedToStripe(historyApprovedList?.endDate)}</span></p>
                                                <p>Dias de férias <span>{historyApprovedList?.vacationDays}</span></p>
                                                <p>Dias de abono <span>{historyApprovedList?.vacationDaysSold}</span></p>
                                                <p>Total <span>{historyApprovedList?.vacationDays}</span></p>
                                            </div>
                                        </li>

                                    </>
                                    )) : (
                                    <>
                                            <div className="lista-vazia">
                                                <p>Essa praia está deserta. <br/>
                                                    Nenhum histórico de férias.</p>
                                                    <img src={IllustrationPraia} alt="illustração praia" />
                                            </div>
                                    </>
                                )}
                            </ul>
                        </div>
                    </Tab>
                    <Tab eventKey="Reprovadas" title="Reprovadas">

                        <div className="lista-historico">
                            <ul className="lista">
                                {
                                    loadPageHistoryFailed ?
                                    (
                                        <>
                                            <div className="carregamento">
                                                <img alt="" src={Carregamento} />
                                            </div>
                                        </>
                                    ):
                                    historyFailed[0] !== undefined ? historyFailed
                                    .map((historyFailedList, index) => (
                                    <>
                                        <li key={index}>
                                                <div className="infor-cal">
                                                {
                                                    historyFailedList?.collaborator?.avatar !== undefined ?
                                                    <>
                                                    <div className="img-col">
                                                        <img src={historyFailedList?.collaborator?.avatar} alt="nome" />
                                                    </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="semImgCol">
                                                            <img src={IconUserNone} alt="coll" />
                                                        </div>
                                                    </>
                                                }
                                                <div className="nome-setor">
                                                    {historyFailedList?.collaborator?.name}
                                                    <div className="setor">
                                                        <p className="setor">{historyFailedList?.collaborator?.occupation}</p>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="info-historico">
                                                    <p>De <span>{dateFormatedToStripe(historyFailedList?.startDate)}</span></p>
                                                    <p>Até <span>{dateFormatedToStripe(historyFailedList?.endDate)}</span></p>
                                                    <p>Dias de férias <span>{historyFailedList?.vacationDays}</span></p>
                                                    <p>Dias de abono <span>{historyFailedList?.vacationDaysSold}</span></p>
                                                    <p>Total <span>{historyFailedList?.vacationDays}</span></p>
                                                </div>
                                        </li>

                                    </>
                                    )) : (
                                    <>
                                        <div className="lista-vazia">
                                            <p>Essa praia está deserta. <br/>Nenhum histórico de férias.</p>
                                            <img src={IllustrationPraia} alt="illustração praia" />
                                        </div>
                                    </>
                                )}

                            </ul>
                        </div>


                    </Tab>
                    <Tab eventKey="Coletivas" title="Coletivas" >

                        {/*carregamento
                            <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div> */}
                        {/* Lista vazia
                        <div className="lista-vazia">
                            <p>Essa praia está deserta. <br/>
                                Nenhum histórico de férias.</p>
                                <img src={IllustrationPraia} alt="illustração praia" />
                        </div> */}


                        <div className="lista-historico">
                            <ul className="lista">
                                {
                                    loadPageHistoryCollectiveVacation ?
                                    <>
                                        <div className="carregamento">
                                            <img alt="" src={Carregamento} />
                                        </div>
                                    </>
                                    :
                                    historyCollectiveVacation.length !== 0 ? historyCollectiveVacation
                                    .map((historiccollectivelist, index) => (
                                        <li key={index}>
                                            <div className="box-ferias-coletiva">
                                                <h4 className="titulo-box">{historiccollectivelist.nameVacation}</h4>
                                                <div className="infor">
                                                    <p className="data">De {dateFormatedToStripe(historiccollectivelist.startDate)} à {dateFormatedToStripe(historiccollectivelist.endDate)} ({historiccollectivelist.balance} dias)</p>
                                                    <p className="grupo">{historiccollectivelist.sector ? <>Departamento: {historiccollectivelist.sector}</> : ''}</p>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                    :
                                    <>
                                        <div className="lista-vazia">
                                            <p>Essa praia está deserta. <br/>Nenhum histórico de férias.</p>
                                            <img src={IllustrationPraia} alt="illustração praia" />
                                        </div>
                                    </>
                                }
                            </ul>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

