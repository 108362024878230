import React, { useState, useEffect } from 'react';

import EditBlack from '../../../../../../assets/images/edit-black.svg';
import { Modal} from "react-bootstrap";
import Carregando from '../../../../../../assets/images/carregando.gif';
import Close from '../../../../../../assets/images/close.svg';
import Dropzone from "react-dropzone";
import moment from 'moment';
import { toast } from 'react-toastify';
import SelectPersonalizado from '../../../../../Reused/selectPersonalizado';
import {Countries} from '../../../../../Reused/ArrayCountries';
import {removerSpecials} from '../../../../../../services/utils';
import api from '../../../../../../services/api';


export default function ColProfilePersonalData ({
    id,
    loadPage,
    path,
    MakePersonalDataVisible,
    visiblePersonalData,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    ufEstados,
    ufEscolhido,
    ufDistrito,
    ufCity,
    OnSubmit,
    loadContinue,
    loadFileNamesPersonalData,
    filesSavedPersonalData,
    handleDropPersonalData,
    setCollaborator,
    setCollaboratorUpdate,
    deleteFile,
    user,

    setSelectEthnicity,
    setSelectNationality,
    setSelectGender,
    setSelectMaritalStatus,
    setSelectBornState,
    setSelectBornCity,

    selectEthnicity,
    selectNationality,
    selectGender,
    selectMaritalStatus,
    selectBornState,
    selectBornCity
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ citys, setCitys ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ cidade, setCidade ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfbirthSaved, setDateOfbirthSaved ] = useState([]);

    let dateOfBirth = collaborator.dateOfBirth;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(ufDistrito === '35') {
            if(ufDistrito) {
                loadcities(ufDistrito);
            }

        }
        else {

            if(ufDistrito) {
                loadcities_(ufDistrito);
            }
        }

        if(ufCity !== undefined) {

            if(ufCity === '350000000') {

                let cidadeGravada = {
                    id: "350000000",
                    nome: "São Paulo"
                }
                setCidade(cidadeGravada);

            }
            else {
                if(ufCity && ufCity !== "undefined") {
                    loadcity(ufCity);
                }

            }

        }
        if(dateOfBirth){
            loaddateofbirth(dateOfBirth);
        }


    }, [id, ufDistrito, ufCity, dateOfBirth])


    async function loadcities(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
        .then(response => {

            let itensCopyCitys = Array.from(response.data);
            itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });
            itensCopyCitys.sort(function (a, b) {

                if (a.nome > b.nome) {
                  return 1;
                }
                if (a.nome < b.nome) {
                  return -1;
                }

                return 0;
              });

            setCitys(itensCopyCitys);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Eror ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function loadcities_(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
        .then(response => {

            setCitys(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


    async function loadcity(ufCity) {
        console.log("ufCity", ufCity)
        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
        .then(response => {

            setCidade(response.data[0]);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loaddateofbirth(dateOfBirth) {

        if(dateOfBirth !== undefined) {
            let data = moment(dateOfBirth, "YYYY-MM-DD");
            setDateOfbirthSaved(data.format("DD/MM/YYYY"))
        }
    }

  

    async function OpenSelectEthnicity(event) {
        setSelectEthnicity(!selectEthnicity)
        setSelectNationality(false)
        setSelectGender(false)
        setSelectMaritalStatus(false)
    }


    async function OpenSelectGender(event) {
        setSelectGender(!selectGender)
        setSelectNationality(false)
        setSelectEthnicity(false)
        setSelectMaritalStatus(false)
    }


    async function OpenSelectMaritalStatus(event) {
        setSelectMaritalStatus(!selectMaritalStatus)
        setSelectNationality(false)
        setSelectEthnicity(false)
        setSelectGender(false)
    }


    async function closePessonalDataSelect(event) {
        if(
            event.target.className !== "btn-select selectNationality" &&
            event.target.className !== "btn-select selectEthnicity"  &&
            event.target.className !== "btn-select selectGender" &&
            event.target.className !== "btn-select selectMaritalStatus"  &&
            event.target.className !== "btn-select selectBornState"  &&
            event.target.className !== "btn-select selectBornCity"
        ) {
            setSelectNationality(false)
            setSelectEthnicity(false)
            setSelectGender(false)
            setSelectMaritalStatus(false)
            setSelectBornState(false)
            setSelectBornCity(false)

            if (event.target.className === "span-select-selectNationality") {
                setSelectNationality(!selectNationality)
                setSelectEthnicity(false)
                setSelectGender(false)
                setSelectMaritalStatus(false)
                setSelectBornState(false)
                setSelectBornCity(false)
            }
            else if (event.target.className === "span-select-selectEthnicity"){
                setSelectEthnicity(!selectEthnicity)
                setSelectNationality(false)
                setSelectGender(false)
                setSelectMaritalStatus(false)
                setSelectBornState(false)
                setSelectBornCity(false)
            }
            else if (event.target.className === "span-select-selectGender"){
                setSelectGender(!selectGender)
                setSelectMaritalStatus(false)
                setSelectNationality(false)
                setSelectEthnicity(false)
                setSelectBornState(false)
                setSelectBornCity(false)
            }
            else if (event.target.className === "span-select-selectMaritalStatus"){
                setSelectMaritalStatus(!selectMaritalStatus)
                setSelectNationality(false)
                setSelectEthnicity(false)
                setSelectGender(false)
                setSelectBornState(false)
                setSelectBornCity(false)
            }
            else if (event.target.className === "span-select-selectBornState"){
                setSelectBornState(!selectBornState)
                setSelectMaritalStatus(false)
                setSelectNationality(false)
                setSelectEthnicity(false)
                setSelectGender(false)
                setSelectBornCity(false)
            }
            else if (event.target.className === "span-select-selectBornCity"){
                setSelectBornCity(!selectBornCity)
                setSelectBornState(false)
                setSelectMaritalStatus(false)
                setSelectNationality(false)
                setSelectEthnicity(false)
                setSelectGender(false)
            }
        }
    }

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;
    
        if(nam === "nationalityBusca") {
    
    
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
            setCollaborator({ ...collaborator,   "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
    
    
        }else if (nam === "nationality") {
    
    
            setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val  });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val  });
    
            if(collaboratorUpdate.nationality !== "Brasil") {
                setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val,  "bornStateBusca": "", "bornCityBusca": ""  });
                setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
                setCollaborator({ ...collaborator,  [nam]: "",  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
                setNationalityBusca({ ...nationalityBusca, "bornStateBusca": "", "bornCityBusca": "" });
            }else {
                setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val  });
                setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
                setCollaborator({ ...collaborator,    [nam]: val});
                setNationalityBusca({ ...nationalityBusca, "bornStateBusca": "", "bornCityBusca": "" });
    
            }
        }
        else
        if(nam === "bornStateBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
    
        }
        else
        if(nam === "bornState") {
            setBuscaLoadOptionBornstate(true)
            let estado = val;
    
            async function estados() {
    
                if(estado !== undefined) {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
                    .then(response => {
                        setBuscaLoadOptionBornstate(false)
                        let bornStateString = response.data.nome;
                        setCollaboratorUpdate({ ...collaboratorUpdate, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": ""   });
                        setCollaborator({ ...collaborator, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": "" });
                        setNationalityBuscaString({ ...nationalityBuscaString,  "bornStateBusca": bornStateString,"bornCityBusca": "" });
    
                    }).catch(error => {});
    
                }
            }
            estados();
        }
        else
        if(nam === "bornCityBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        } else
        if(nam === "bornCity") {
            setBuscaLoadOptionCity(true)
            let cidade = val;
            if(cidade !== undefined) {
    
                if(cidade === '350000000') {
    
                    // alert('são paulo')
                    let bornCityString = 'São Paulo';
    
                    let nam = 'bornCity';
                    let val = '350000000';
                    setBuscaLoadOptionCity(false)
    
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                    setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
    
                }
                else
                if(collaboratorUpdate.bornState === '35' && cidade === '350000000') {
    
                    // alert('são paulo')
                    let bornCityString = 'São Paulo';
    
                    let nam = 'bornCity';
                    let val = '350000000';
                    setBuscaLoadOptionCity(false)
    
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                    setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
    
                }
                else {
    
                    async function loadCity() {
                        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                        .then(response => {
                            let bornCityString = response.data[0].nome;
                            setBuscaLoadOptionCity(false)
                            setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, "bornCity": val })
                            setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
                        }).catch(error => {});
    
                    }
                    loadCity();
    
                }
    
            }
        }
    
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [BuscaLoadOptionBornstate , setBuscaLoadOptionBornstate] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [BuscaLoadOptionCity , setBuscaLoadOptionCity] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBusca , setNationalityBusca] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBuscaString , setNationalityBuscaString] = useState([]);
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreNacionalite = Countries.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.nationalityBusca !== undefined ? nationalityBusca.nationalityBusca : "" )  ;
    });
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUfEstados = ufEstados.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornStateBusca !== undefined ? nationalityBusca.bornStateBusca : "" )  ;
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = citys.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornCityBusca !== undefined ? nationalityBusca.bornCityBusca : "" )  ;
    });



    return (
        <>

                <div className="box pessonal-data">

                    <h3>Informações pessoais

                    {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                       ""
                       :
                       (user && user.role === 'admin') || (user && user.role === 'master') ? <button className="edit-Benefit" onClick={() => MakePersonalDataVisible()} ><img src={EditBlack} alt="Edite Informações" /></button> : ''
                    }

                    </h3>
                    {
                            loadPage ?
                                (
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregando} />
                                    </div>
                                    </>
                                ):(

                                    <>
                        <ul>
                        <li>
                            <div className="intemA">
                                <p>Nome completo</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.name ? collaborator.name : '-'}</p>
                            </div>
                        </li>
                        {
                            (user && user.role === 'admin') || (user && user.role === 'master') ?
                            <>
                            <li>
                                <div className="intemA">
                                    <p>Nacionalidade</p>
                                </div>
                                <div className="intemB">
                                    {
                                        collaborator.nationality === 'Outra' ?
                                        ( <p>{collaborator.otherNationality ? collaborator.otherNationality : '-'}</p> )
                                        :
                                        ( <p>{collaborator.nationality ? collaborator.nationality : '-'}</p>)
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Natural do Estado</p>
                                </div>
                                <div className="intemB">

                                    {   
                                        collaborator.nationality === 'Outra' ?
                                            <p>{collaborator.bornStateOther ? collaborator.bornStateOther :collaborator.bornStateString ? collaborator.bornStateString  : '-'}</p>
                                        :
                                        collaborator.nationality === 'Brasil' ?
                                        <p>{ collaborator.bornState ? ufEscolhido.nome  : '-'}</p>
                                        :
                                        <p>{ collaborator.bornStateString ? collaborator.bornStateString  : '-'}</p>
                                    }

                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Natural da Cidade</p>
                                </div>
                                <div className="intemB">
                                    {   
                                        collaborator.nationality === 'Outra' ?
                                            <p>{collaborator.bornCityOther ? collaborator.bornCityOther :collaborator.bornCityOther ? collaborator.bornCityOther  : '-'}</p>
                                        :
                                        collaborator.nationality === 'Brasil' ?
                                        <p>{ collaborator.bornCity ? cidade.nome   : '-'}</p>
                                        :
                                        <p>{ collaborator.bornCityString ? collaborator.bornCityString  : '-'}</p>
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Cor/Raça</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.ethnicity ? collaborator.ethnicity : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Gênero</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.gender ? collaborator.gender : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Estado civil</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.maritalStatus ? collaborator.maritalStatus : '-'}</p>
                                </div>
                            </li>
                            </>
                            :
                            ''
                        }

                        <li>
                            <div className="intemA">
                                <p>Data de nascimento</p>
                            </div>
                            <div className="intemB">
                                <p>{dateOfbirthSaved && dateOfbirthSaved.length !== 0  ? dateOfbirthSaved : '-'}</p>
                            </div>
                        </li>
                        {
                            (user && user.role === 'admin') || (user && user.role === 'master') ?
                            <>
                            <li>
                                <div className="intemA">
                                    <p>Nome da mãe</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.motherName ? collaborator.motherName : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Nome do pai</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.fatherName ? collaborator.fatherName : '-'}</p>
                                </div>
                            </li>
                            <li  className="li-anexo">
                            <div className="intemA">
                            <p>Anexo certidão</p>
                            </div>
                            <div className="intemB arq-adicionado" >
                                {filesSavedPersonalData[0] !== undefined ?
                                filesSavedPersonalData.map((filesaved, index) => (
                                            <a href={filesaved.location} target="’_blank’" key={index}>
                                                {filesaved.name}
                                            </a>
                                )) : (
                                    <>
                                    <p>-</p>
                                    </>
                                )}


                            </div>
                            </li>
                            </>
                            :
                            ''
                        }


                    </ul>
                                    </>

                                )}

                </div>

                <Modal show={visiblePersonalData} className="popup-edit-perfil popup-editProfile-pessonalData" onHide={MakePersonalDataVisible}>
                    <div onClick={closePessonalDataSelect}>
                        <div className="titulo">
                            <h4>Informações pessoais  <p className="btn-fechar" onClick={MakePersonalDataVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Nome completo</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" name="name" autocomplete="autocompleteoff" defaultValue={collaborator.name ? collaborator.name : ''} onChange={onCollaboratorInputChange} placeholder="Nome Completo"/>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Nacionalidade</p>
                                </div>
                                <div className="intemB">


                                    <SelectPersonalizado
                                        valorCampo={
                                            nationalityBuscaString && nationalityBuscaString.nationalityBusca ?
                                            nationalityBuscaString.nationalityBusca :
                                            nationalityBuscaString && nationalityBuscaString.nationalityBusca === '' ?
                                            '' :
                                            collaborator && collaborator.nationality ?
                                            collaborator.nationality :
                                            ''
                                        }
                                        nameCampoBusca={"nationalityBusca"}
                                        nameButton={"nationality"}
                                        // loadError={Loadnationality}
                                        validacaoValue={"pais"}
                                        validacaoOptionIntem={"pais"}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreNacionalite}
                                        typeSelect={"busca"}
                                        // focusRef={focusnationality}
                                    />


                                </div>
                            </li>


                            <li>
                                <div className="intemA">
                                    <p>Natural do Estado </p>
                                </div>
                                <div className="intemB">

                                {
                                    collaboratorUpdate?.nationality === 'Brasil' ||
                                    collaborator?.nationality === 'Brasil' || 
                                    collaborator?.nationality === undefined ||
                                    collaboratorUpdate?.nationality === '' ?
                                    <SelectPersonalizado
                                        valorCampo={
    
                                            nationalityBuscaString && nationalityBuscaString.bornStateBusca ?
                                            nationalityBuscaString.bornStateBusca :
                                            nationalityBuscaString && nationalityBuscaString.bornStateBusca === '' ?
                                                '' :
    
                                                collaboratorUpdate && collaboratorUpdate.bornState ?
                                                ufEscolhido.nome 
                                                :
                                                collaborator && collaborator.bornState ?
                                                    ufEscolhido.nome : ''
                                        }
                                        nameCampoBusca={"bornStateBusca"}
                                        nameButton={"bornState"}
                                        // loadError={LoadbornState}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreUfEstados}
    
                                        loadOption={BuscaLoadOptionBornstate}
                                        validacaoValue={"estado"}
                                        validacaoOptionIntem={"estado"}
                                        typeSelect={"busca"}
                                        // focusRef={focusbornState}
                                    />
                                    :
                                    <input 
                                        // ref={focusbornState} 
                                        type="text" 
                                        name="bornStateString" 
                                        placeholder='Natural do Estado' 
                                        autocomplete="autocompleteoff"
                                        defaultValue={
                                            
                                            collaborator.bornStateString !== undefined ? 
                                            collaborator.bornStateString : ''
                                        
                                        } 
                                        onChange={onCollaboratorInputChange} 
                                        // className={LoadbornState ? 'inputOito inputErro' : 'inputOito'}
                                    />
                                }

                                </div>
                            </li>


                            <li>
                                <div className="intemA">
                                    <p>Natural do Cidade </p>
                                </div>
                                <div className="intemB">

                                {
                                    collaboratorUpdate?.nationality === 'Brasil' ||
                                    collaborator?.nationality === 'Brasil' || 
                                    collaborator?.nationality === undefined ||
                                    collaboratorUpdate?.nationality === '' ?
                                    <SelectPersonalizado
                                        valorCampo={
     
                                            nationalityBuscaString && nationalityBuscaString.bornCityBusca ?
                                            nationalityBuscaString.bornCityBusca :
                                            nationalityBuscaString && nationalityBuscaString.bornCityBusca === '' ?
                                                '' :
                                                 collaboratorUpdate && collaboratorUpdate.bornCity?
                                                cidade.nome 
                                                : collaborator?.bornCity   ?
                                                cidade.nome : ''
                                        }
                                        nameCampoBusca={"bornCityBusca"}
                                        nameButton={"bornCity"}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreUCidade}
     
                                        loadOption={BuscaLoadOptionCity}
                                        validacaoValue={"estado"}
                                        validacaoOptionIntem={"estado"}
                                        typeSelect={"busca"}
                                    />
                                    :
                                    <input 
                                    type="text" 
                                    name="bornCityString" 
                                    placeholder='Natural do cidade' 
                                    autocomplete="autocompleteoff"
                                    defaultValue={
                                        
                                        collaborator.bornCityString !== undefined ? 
                                        collaborator.bornCityString : ''
                                    
                                    } 
                                    onChange={onCollaboratorInputChange} 
                                    />
                                    
                                }

                                </div>
                            </li>

                       
                            <li>
                                <div className="intemA">
                                    <p>Cor/Raça</p>
                                </div>
                                <div className="intemB">


                                    <div className='select-x'>
                                        <button className='btn-select selectEthnicity'  id="selectEthnicity" onClick={OpenSelectEthnicity}>
                                            <span  className='span-select-selectEthnicity'>
                                                {
                                                    collaboratorUpdate && collaboratorUpdate.ethnicity !== undefined ?
                                                    collaboratorUpdate.ethnicity :
                                                    collaborator && collaborator.ethnicity !== undefined ?
                                                    collaborator.ethnicity : "Selecione"
                                                }
                                            </span>
                                        </button>
                                        {selectEthnicity ?
                                            <div className='resultado-busca'>
                                                {[
                                                    "Nenhum",
                                                    "Branca",
                                                    "Preta",
                                                    "Indígena",
                                                    "Amarela",
                                                    "Parda"
                                                ].map((item, index) => (
                                                    <button  key={index} onClick={() =>
                                                        setCollaboratorUpdate({ ...collaboratorUpdate, "ethnicity": item })
                                                    }><p>{item}</p></button>
                                                ))}
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Gênero</p>
                                </div>
                                <div className="intemB">


                                    <div className='select-x'>
                                        <button className='btn-select selectGender'  id="selectGender" onClick={OpenSelectGender}>
                                            <span  className='span-select-selectGender'>
                                                {
                                                    collaboratorUpdate && collaboratorUpdate.gender !== undefined ?
                                                    collaboratorUpdate.gender :
                                                    collaborator && collaborator.gender !== undefined ?
                                                    collaborator.gender : "Selecione"
                                                }
                                            </span>
                                        </button>
                                        {selectGender ?
                                            <div className='resultado-busca'>
                                                {[
                                                    "Feminino",
                                                    "Masculino",
                                                    "Transgênero",
                                                    "Não-binário",
                                                    "Prefiro não responder"
                                                ].map((item, index) => (
                                                    <button  key={index} onClick={() =>
                                                        setCollaboratorUpdate({ ...collaboratorUpdate, "gender": item })
                                                    }><p>{item}</p></button>
                                                ))}
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Estado civil</p>
                                </div>
                                <div className="intemB">


                                    <div className='select-x'>
                                        <button className='btn-select selectMaritalStatus'  id="selectMaritalStatus" onClick={OpenSelectMaritalStatus}>
                                            <span  className='span-select-selectMaritalStatus'>
                                                {
                                                    collaboratorUpdate && collaboratorUpdate.maritalStatus !== undefined ?
                                                    collaboratorUpdate.maritalStatus :
                                                    collaborator && collaborator.maritalStatus !== undefined ?
                                                    collaborator.maritalStatus : "Selecione"
                                                }
                                            </span>
                                        </button>
                                        {selectMaritalStatus ?
                                            <div className='resultado-busca'>
                                                {[
                                                    "Solteiro(a)",
                                                    "Casado(a)",
                                                    "Divorciado(a)",
                                                    "Viúvo(a)",
                                                    "União estável"
                                                ].map((item, index) => (
                                                    <button  key={index} onClick={() =>
                                                        setCollaboratorUpdate({ ...collaboratorUpdate, "maritalStatus": item })
                                                    }><p>{item}</p></button>
                                                ))}
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Data de nascimento</p>
                                </div>
                                <div className="intemB">


                                {(collaboratorUpdate && collaboratorUpdate.dateOfBirth !== undefined) || collaboratorUpdate.dateOfBirth === "" ?
                                        (
                                            <input type="text" name="dateOfBirth" autocomplete="autocompleteoff" maxlength="10"  value={(collaboratorUpdate.dateOfBirth !== undefined) ? collaboratorUpdate.dateOfBirth : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" />
                                        ) :
                                        collaborator.dateOfBirth && collaborator.dateOfBirth ? (
                                            <input type="text" name="dateOfBirth" autocomplete="autocompleteoff"  maxlength="10"  defaultValue={(dateOfbirthSaved !== undefined) ? dateOfbirthSaved : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" className="inputUm"/>
                                        ):
                                        (
                                            <input type="text" name="dateOfBirth" autocomplete="autocompleteoff"  maxlength="10"  value={(collaboratorUpdate.dateOfBirth !== undefined) ? collaboratorUpdate.dateOfBirth : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" />
                                        )
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Nome da mãe</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" name="motherName" autocomplete="autocompleteoff" defaultValue={(collaborator.motherName !== undefined) ? collaborator.motherName : ''} onChange={onCollaboratorInputChange} placeholder="Nome da mãe" className="inputQuatro"/>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Nome do pai</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" name="fatherName" autocomplete="autocompleteoff" defaultValue={(collaborator.fatherName !== undefined) ? collaborator.fatherName : ''} onChange={onCollaboratorInputChange} placeholder="Nome do pai" className="inputTreis"/>

                                </div>
                            </li>
                            <li>
                            <div className="intemA">
                                <p>Certidão de nascimento ou casamento</p>
                            </div>
                            <div className="intemB">
                                <Dropzone className="" onDrop={handleDropPersonalData}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                            </div>
                        </li>

                        {filesSavedPersonalData[0]  !== undefined ? (
                        <li>
                            <div className="arquivoAnexado">
                                <h5>Enviados</h5>

                                {filesSavedPersonalData[0] !== undefined ?
                                filesSavedPersonalData.map((filesaved, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                                        </p>
                                        <button onClick={()=>deleteFile(filesaved)} className="btnExclui">Remover anexo</button>
                                    </div>
                                )) : ''}

                            </div>
                        </li>
                                ) : ''}
                        {
                            loadFileNamesPersonalData ?
                            ( <li>
                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            </li>) : ('')
                        }
                            <li>
                                <div className="intemA btn">
                                </div>
                                <div className="intemB">
                                    <form onSubmit={OnSubmit} className="btn-salvar">
                                        <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                    </form>
                                </div>
                            </li>
                        </ul>


                    </div>
                </Modal>
        </>
    );
}
