import React from 'react';
import success from '../../assets/images/success.svg';
export default function pageFreeTrialSucesss(props) {



    return (
        <>
        <div className="page-sucesso">
            <img src={success} alt="Tudo certo" />
            <h2>Tudo certo!</h2>
            <p>Enviamos o link de acesso para o seu email. <br/> (Verifique sua caixa de SPAM)</p>
        </div>

        </>
    );
}
