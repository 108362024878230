import React, { useState, useEffect } from 'react';
// import Layout from '../../../components/Layouts/default';
import Layout from '../../../components/Layouts/default/index_plan_billing';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import FlatDetail from '../../../components/Administrativo/SystemsConfig/PlanBilling/flat-detail';
import ChangePlan from '../../../components/Administrativo/SystemsConfig/PlanBilling/changePlan';
import AddPaymentMethod from '../../../components/Administrativo/SystemsConfig/PlanBilling/AddPaymentMethod';
import EditPaymentMethod from '../../../components/Administrativo/SystemsConfig/PlanBilling/EditPaymentMethod';
import PaymentManage from '../../../components/Administrativo/SystemsConfig/PlanBilling/PaymentManage';
import AddMethodPayment from '../../../components/Administrativo/SystemsConfig/PlanBilling/AddMethodPayment';
import api from '../../../services/api';
import { decodeToken } from '../../../services/auth';
import moment from 'moment';
import {
    mNumber,
    mNumber3,
    expirationDateCard,
    mNumber11,
    mNumber14,
    creditCard
} from '../../../services/mask';
import {
    dataFormatadaParaProximaCobranca,
    dataFormatadaParaDataLimite,
    dateFormatedToSignature,
} from '../../../services/utils';
// // import PaymentMethod from '../../../components/Administrativo/SystemsConfig/'
// import BillingHistory from '../../../components/Administrativo/SystemsConfig/PlanBilling/BillingHistory';
// import IconVisa from '../../../assets/images/icon-visa.svg';
// import IconMastercard from '../../../assets/images/icon-mastercard.svg';
// import IconAmericanExpress from '../../../assets/images/icon-amex.svg';
// import IconJCB from '../../../assets/images/icon-jbc.svg';
// import IconDiscover from '../../../assets/images/icon-discover.svg';
// import IconDiners from '../../../assets/images/icon-diners.svg';
import swal from 'sweetalert';
import './styles.scss';

export default function pageSystemConfig(props, path) {

    let { user } = decodeToken();

    const startOfTrial = user.companyId?.startOfTrial;

    const statusCompany = user.companyId?.status;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadGeneralPlan();
        loadCompanyPlan();
        subscriptionFind();
        transactionsFind();
        loadCards();

        statusCompanyTrial(startOfTrial, statusCompany)

    }, [startOfTrial, statusCompany]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pageFlatDetail, setBtnPageFlatDetail] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pagePlan, setBtnPagePla] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pageAddPaymentMethod, setBtnPageAddPaymentMethod] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pageEditPaymentMethod, setBtnPageEditPaymentMethod] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ planGeneral, setPlanGeneral] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ cardsGeneral, setCardsGeneral] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ planCompany, setPlanCompany] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPlan, setLoadPlan] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadcards, setLoadcards] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ planSelected, setPlanSelect] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ signatureCompany, setSignatureCompany] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSignatureCompany, setLoadSignatureCompany] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ transactionsCompany, setTransactionsCompany] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTransactionsCompany, setLoadTransactionsCompany] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ signatureSelected, setSignatureSelected] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ paymentMethod, setPaymentMethod] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCardHolderName, setLoadCardHolderName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ document_number, setLoadDocument_number ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCardNumber, setLoadCardNumber ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCardExpirationDate, setLoadCardExpirationDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCardCVC, setLoadCardCVC ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadName, setLoadName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmail, setLoadEmail ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadZipcode, setLoadZipcode ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNeighborhood, setLoadNeighborhood ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadStreet, setLoadStreet ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadStreetNumber, setLoadStreetNumber ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTelefone, setLoadTelefone ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadComplementary, setloadComplementary ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonSavePlan, setLoadButtonSavePlan ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonSaveCard, setLoadButtonSaveCard ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ customerID, setCustomerID] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ card, setCard] = useState([]);

    async function ExchangePlan() {

        setBtnPageFlatDetail(false);
        setBtnPagePla(true);
        setBtnPageAddPaymentMethod(false);
        setBtnPageEditPaymentMethod(false);
        setPaymentMethod([]);

    }

    async function changePageFlatDetail() {

        setBtnPageFlatDetail(true);
        setBtnPagePla(false);
        setBtnPageAddPaymentMethod(false);
        setBtnPageEditPaymentMethod(false);
        setOpenPagePaymentManage(false);
        subscriptionFind();
        setSignatureSelected();


    }

    async function changePageAddPaymentMethod(planSelected) {

        setPlanSelect(planSelected);
        setBtnPageFlatDetail(false);
        setBtnPagePla(false);
        setBtnPageAddPaymentMethod(true);
        setBtnPageEditPaymentMethod(false);

    }

    async function changePageAddEditPaymentMethod(signatureSelectedToEdit) {

        let signatureData = {
            subscription_id      : signatureSelectedToEdit.id,
            namePlan             : signatureSelectedToEdit?.plan?.name,
            amountPlan           : signatureSelectedToEdit?.plan?.amount,
            card_holder_name     : signatureSelectedToEdit?.card?.holder_name,
            document_number      : signatureSelectedToEdit?.customer?.document_number,
            card_expiration_date : signatureSelectedToEdit?.card?.expiration_date,
            name                 : signatureSelectedToEdit?.customer?.name,
            email                : signatureSelectedToEdit?.customer?.email,
            ddd                  : signatureSelectedToEdit?.phone?.ddd,
            number               : signatureSelectedToEdit?.phone?.number,
            zipcode              : signatureSelectedToEdit?.address?.zipcode,
            neighborhood         : signatureSelectedToEdit?.address?.neighborhood,
            street               : signatureSelectedToEdit?.address?.street,
            street_number        : signatureSelectedToEdit?.address?.street_number,
        }

        if(signatureSelectedToEdit?.address?.complementary) {
            signatureData = {
                ...signatureData,
                complementary: signatureSelectedToEdit?.address?.complementary
            }
        }

        setSignatureSelected(signatureData)
        setBtnPageFlatDetail(false)
        setBtnPagePla(false)
        setBtnPageAddPaymentMethod(false)
        setBtnPageEditPaymentMethod(true)
    }

    async function loadGeneralPlan() {

        setLoadPlan(true)
        await api.get('/plan/')
        .then(response => {

            setLoadPlan(false)
            setPlanGeneral(response.data)

        }).catch(error => {
            setLoadPlan(false)
        })

    }

    async function loadCompanyPlan() {

        setLoadPlan(true)
        await api.get('/signed-plan-company/')
        .then(response => {

            setLoadPlan(false)
            setPlanCompany(response.data)

        }).catch(error => {setLoadPlan(false)})

    }

    async function companyPlanSuccess() {

        await api.get('/company-plan-success/')
        .then(response => {

            if(response.data.token !== undefined) {

                localStorage.setItem('@peopleview/token', response.data.token);

            }

        }).catch(error => {})

    }

    async function subscriptionFind() {

        setLoadSignatureCompany(true)
        await api.get('/subscription-find/')
        .then(response => {

            if(response.data !== null) {

                setLoadSignatureCompany(false)
                setCustomerID(response.data.customer.id)
                setSignatureCompany(response.data)

            }
            else {

                setLoadSignatureCompany(false)
                // setCustomerID(response.data.customer.id)
                // setSignatureCompany(response.data)

            }


        }).catch(error => {
            setLoadSignatureCompany(false)
        })

    }

    async function transactionsFind() {

        setLoadTransactionsCompany(true)
        await api.get('/transactions-find/')
        .then(response => {

            // console.log('response.data => /transactions-find/')
            // console.log(response.data)

            setLoadTransactionsCompany(false)
            // setCustomerID(response.data.customer.id)
            setTransactionsCompany(response.data)


        }).catch(error => {setLoadTransactionsCompany(false)})

    }

    async function loadCards() {

        setLoadcards(true)
        await api.get('/cards/')
        .then(response => {

            setLoadcards(false)
            setCardsGeneral(response.data)

        }).catch(error => {
            setLoadcards(false)
        })

    }

    async function myChangeHandlerPlan(event) {

        let nam     = event.target.name;
        let val     = event.target.value;
        let idPlan  = planSelected._id;
        let plan_id = planSelected.pagarme_plan_id;

        // card_holder_name
        if(nam === 'card_holder_name') {

            let name = val;

            if(val.length === 0) {
                setLoadCardHolderName(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else {
                setLoadCardHolderName(false)
                setPaymentMethod({ ...paymentMethod, [nam]: val, idPlan, plan_id, name })
            }

        }
        else
        if(nam === 'document_number') {

            if(val.length === 0) {
                setLoadDocument_number(true)
                let document_number = mNumber14(val)
                setPaymentMethod({ ...paymentMethod, [nam]: document_number })
            }
            else {
                setLoadDocument_number(false)
                let document_number = mNumber14(val)
                setPaymentMethod({ ...paymentMethod, [nam]: document_number })
            }

        }
        else
        if(nam === 'card_number') {

            if(val.length === 0) {
                setLoadCardNumber(true)
                let card_number = creditCard(val)
                setPaymentMethod({ ...paymentMethod, [nam]: card_number })
            }
            else {
                setLoadCardNumber(false)
                let card_number = creditCard(val)
                setPaymentMethod({ ...paymentMethod, [nam]: card_number })
            }

        }
        else
        if(nam === 'card_expiration_date') {

            if(val.length === 0) {
                setLoadCardExpirationDate(true)
                let card_expiration_date = expirationDateCard(val)
                setPaymentMethod({ ...paymentMethod, [nam]: card_expiration_date })
            }
            else {
                setLoadCardExpirationDate(false)
                let card_expiration_date = expirationDateCard(val)
                setPaymentMethod({ ...paymentMethod, [nam]: card_expiration_date })
            }

        }
        else
        if(nam === 'card_cvv') {

            if(val.length === 0) {
                setLoadCardCVC(true)
                let card_cvv = mNumber3(val)
                setPaymentMethod({ ...paymentMethod, [nam]: card_cvv })
            }
            else {
                setLoadCardCVC(false)
                let card_cvv = mNumber3(val)
                setPaymentMethod({ ...paymentMethod, [nam]: card_cvv })
            }

        }
        else
        if(nam === 'name') {

            if(val.length === 0) {
                setLoadName(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else {
                setLoadName(false)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }

        }
        else
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmail(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else {
                setLoadEmail(false)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }

        }
        else
        if(nam === 'phone') {

            if(val.length === 0) {
                setLoadTelefone(true)
                let phone = mNumber11(val)
                setPaymentMethod({ ...paymentMethod, [nam]: phone })
            }
            else {
                setLoadTelefone(false)
                let phone = mNumber11(val)
                setPaymentMethod({ ...paymentMethod, [nam]: phone })
            }

        }
        else
        if(nam === "zipcode") {


            if(val.length === 0) {
                setLoadZipcode(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else
            if(val.length <=7 && val.length >= 1) {
                setLoadZipcode(true)
                let zipcode = mNumber(val)
                setPaymentMethod({ ...paymentMethod, [nam]: zipcode })
            }
            else
            if(val.length === 8) {

                setLoadZipcode(false)
                let postCode = String(val).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
                const response = await api.get(`https://viacep.com.br/ws/${postCode}/json/`);

                if(response.data && response.data.logradouro !== undefined) {
                    setPaymentMethod({
                        ...paymentMethod,
                        zipcode: postCode,
                        street: response.data.logradouro,
                        neighborhood : response.data.bairro
                    });

                    setLoadNeighborhood(false)
                    setLoadStreet(false)

                }

            }


        }
        else
        if(nam === 'street_number') {

            if(val.length === 0) {
                setLoadStreetNumber(true)
                let street_number = mNumber(val)
                setPaymentMethod({ ...paymentMethod, [nam]: street_number })
            }
            else {
                setLoadStreetNumber(false)
                let street_number = mNumber(val)
                setPaymentMethod({ ...paymentMethod, [nam]: street_number })
            }

        }
        else
        if(nam === 'neighborhood') {

            if(val.length === 0) {
                setLoadNeighborhood(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else {
                setLoadNeighborhood(false)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }

        }
        else
        if(nam === 'street') {

            if(val.length === 0) {
                setLoadStreet(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else {
                setLoadStreet(false)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }

        }
        else
        if(nam === 'complementary') {

            if(val.length === 0) {
                setloadComplementary(true)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }
            else {
                setloadComplementary(false)
                setPaymentMethod({ ...paymentMethod, [nam]: val })
            }

        }
        else {

            setPaymentMethod({ ...paymentMethod, [nam]: val })

        }



    }

    async function myChangeHandlerEditPlan(event) {

        let nam = event.target.name;
        let val = event.target.value;

        // card_holder_name
        if(nam === 'card_holder_name') {

            if(val.length === 0) {
                setLoadCardHolderName(true)
                setSignatureSelected({ ...signatureSelected, [nam]: val })
            }
            else {
                setLoadCardHolderName(false)
                setSignatureSelected({ ...signatureSelected, [nam]: val })
            }

        }
        else
        if(nam === 'card_number') {

            if(val.length === 0) {
                setLoadCardNumber(true)
                let card_number = creditCard(val)
                setSignatureSelected({ ...signatureSelected, [nam]: card_number })
            }
            else {
                setLoadCardNumber(false)
                let card_number = creditCard(val)
                setSignatureSelected({ ...signatureSelected, [nam]: card_number })
            }

        }
        else
        if(nam === 'card_expiration_date') {

            if(val.length === 0) {
                setLoadCardExpirationDate(true)
                let card_expiration_date = expirationDateCard(val)
                setSignatureSelected({ ...signatureSelected, [nam]: card_expiration_date })
            }
            else {
                setLoadCardExpirationDate(false)
                let card_expiration_date = expirationDateCard(val)
                setSignatureSelected({ ...signatureSelected, [nam]: card_expiration_date })
            }

        }
        else
        if(nam === 'card_cvv') {

            if(val.length === 0) {
                setLoadCardCVC(true)
                let card_cvv = mNumber3(val)
                setSignatureSelected({ ...signatureSelected, [nam]: card_cvv })
            }
            else {
                setLoadCardCVC(false)
                let card_cvv = mNumber3(val)
                setSignatureSelected({ ...signatureSelected, [nam]: card_cvv })
            }

        }
        else {

            setSignatureSelected({ ...signatureSelected, [nam]: val })

        }

    }

    async function myChangeHandlerCard(event) {

        let nam         = event.target.name;
        let val         = event.target.value;
        let customer_id = customerID;

        // card_holder_name
        if(nam === 'card_holder_name') {

            if(val.length === 0) {
                setLoadCardHolderName(true)
                setCard({ ...card, [nam]: val })
            }
            else {
                setLoadCardHolderName(false)
                setCard({ ...card, [nam]: val, customer_id })
            }

        }
        else
        if(nam === 'card_number') {

            if(val.length === 0) {
                setLoadCardNumber(true)
                let card_number = creditCard(val)
                setCard({ ...card, [nam]: card_number })
            }
            else {
                setLoadCardNumber(false)
                let card_number = creditCard(val)
                setCard({ ...card, [nam]: card_number })
            }

        }
        else
        if(nam === 'card_expiration_date') {

            if(val.length === 0) {
                setLoadCardExpirationDate(true)
                let card_expiration_date = expirationDateCard(val)
                setCard({ ...card, [nam]: card_expiration_date })
            }
            else {
                setLoadCardExpirationDate(false)
                let card_expiration_date = expirationDateCard(val)
                setCard({ ...card, [nam]: card_expiration_date })
            }

        }
        else
        if(nam === 'card_cvv') {

            if(val.length === 0) {
                setLoadCardCVC(true)
                let card_cvv = mNumber3(val)
                setCard({ ...card, [nam]: card_cvv })
            }
            else {
                setLoadCardCVC(false)
                let card_cvv = mNumber3(val)
                setCard({ ...card, [nam]: card_cvv })
            }

        }
        else {

            setCard({ ...card, [nam]: val })

        }

    }

    async function savePlanCompany() {

        if(
            paymentMethod?.card_holder_name === undefined
            ||
            paymentMethod?.card_holder_name === ''
            ||
            paymentMethod?.document_number === undefined
            ||
            paymentMethod?.document_number === ''
            ||
            paymentMethod?.card_number === undefined
            ||
            paymentMethod?.card_number === ''
            ||
            paymentMethod?.card_expiration_date === undefined
            ||
            paymentMethod?.card_expiration_date === ''
            ||
            paymentMethod?.card_cvv === undefined
            ||
            paymentMethod?.card_cvv === ''
            ||
            paymentMethod?.name === undefined
            ||
            paymentMethod?.name === ''
            ||
            paymentMethod?.email === undefined
            ||
            paymentMethod?.email === ''
            ||
            paymentMethod?.street === undefined
            ||
            paymentMethod?.street === ''
            ||
            paymentMethod?.street_number === undefined
            ||
            paymentMethod?.street_number === ''
            ||
            paymentMethod?.neighborhood === undefined
            ||
            paymentMethod?.neighborhood === ''
            ||
            paymentMethod?.zipcode === undefined
            ||
            paymentMethod?.zipcode === ''
            ||
            paymentMethod?.phone === undefined
            ||
            paymentMethod?.phone === ''
        ) {

            if(paymentMethod?.card_holder_name === undefined || paymentMethod?.card_holder_name === '') {
                setLoadCardHolderName(true)
            }
            if(paymentMethod?.document_number === undefined || paymentMethod?.document_number === '') {
                setLoadDocument_number(true)
            }
            if(paymentMethod?.card_number === undefined || paymentMethod?.card_number === '') {
                setLoadCardNumber(true)
            }
            if(paymentMethod?.card_expiration_date === undefined || paymentMethod?.card_expiration_date === '') {
                setLoadCardExpirationDate(true)
            }
            if(paymentMethod?.card_cvv === undefined || paymentMethod?.card_cvv === '') {
                setLoadCardCVC(true)
            }

            if(paymentMethod?.name === undefined || paymentMethod?.name === '') {
                setLoadName(true)
            }
            if(paymentMethod?.email === undefined || paymentMethod?.email === '') {
                setLoadEmail(true)
            }
            if(paymentMethod?.street === undefined || paymentMethod?.street === '') {
                setLoadStreet(true)
            }
            if(paymentMethod?.street_number === undefined || paymentMethod?.street_number === '') {
                setLoadStreetNumber(true)
            }
            if(paymentMethod?.neighborhood === undefined || paymentMethod?.neighborhood === '') {
                setLoadNeighborhood(true)
            }
            if(paymentMethod?.zipcode === undefined || paymentMethod?.zipcode === '') {
                setLoadZipcode(true)
            }

            if(paymentMethod?.phone === undefined || paymentMethod?.phone === '') {
                setLoadTelefone(true)
            }
            return
        }
        else {

            let ddd_ = paymentMethod?.phone.substr(0, 2)

            let ddd = '0'+ddd_;
            let number = paymentMethod?.phone.substr(2)

            let card_number_splited = paymentMethod?.card_number.split(' ')

            // console.log(card_number_splited[0]+card_number_splited[1]+card_number_splited[2]+card_number_splited[3])

            let card_number = card_number_splited[0]+card_number_splited[1]+card_number_splited[2]+card_number_splited[3];

            let expiration_date_splited = paymentMethod.card_expiration_date.split('/');
            let card_expiration_date    = expiration_date_splited[0]+expiration_date_splited[1];

            // console.log(card_expiration_date)


            let PlanAndBilling = {
                "idPlan" : paymentMethod.idPlan,
                "plan_id": paymentMethod.plan_id,
                "card_number": card_number,
                "card_cvv": paymentMethod.card_cvv,
                "card_holder_name": paymentMethod.card_holder_name,
                "card_expiration_date": card_expiration_date,
                "email": paymentMethod.email,
                "name": paymentMethod.name,
                "document_number": paymentMethod.document_number,
                "street": paymentMethod.street,
                "street_number": paymentMethod.street_number,
                "neighborhood": paymentMethod.neighborhood,
                "zipcode": paymentMethod.zipcode,
                "ddd": ddd,
                "number": number,
                "path": props.match.path,
            }

            // console.log(PlanAndBilling)

            if(paymentMethod?.complementary) {

                PlanAndBilling = { ...PlanAndBilling, complementary: paymentMethod?.complementary }

            }

            setLoadButtonSavePlan(true)
            await api.post('/plan-and-billing/', PlanAndBilling)
            .then(response => {

                console.log('response.data => plan-and-billing')
                console.log(response.data)

                // setTimeout(function(){
                //     companyPlanSuccess();
                //     loadCompanyPlan();
                //     loadCards();
                //     subscriptionFind();
                //     setPaymentMethod();
                //     setLoadButtonSavePlan(false);
                //     setBtnPageFlatDetail(false)
                //     setBtnPagePla(true)
                //     setBtnPageAddPaymentMethod(false)
                //     transactionsFind();

                // }, 500);
                // swal({ icon: "success", title: "Sucesso!", text: "Plano contratado com sucesso!" });

            })
            .catch(error=>{
                console.log(error)
                console.log(error)
                setLoadButtonSavePlan(false);
                swal({ icon: "warning", title: "Atenção!", text: error?.response?.data?.message });
            })

        }


    }

    async function editPlanCompany() {

        if(
            (signatureSelected?.card_holder_name !== undefined
            &&
            signatureSelected?.card_holder_name !== '')
            &&
            (signatureSelected?.card_number !== undefined
            &&
            signatureSelected?.card_number !== '')
            &&
            (signatureSelected?.card_expiration_date !== undefined
            &&
            signatureSelected?.card_expiration_date !== '')
            &&
            (signatureSelected?.card_cvv !== undefined
            &&
            signatureSelected?.card_cvv !== '')
        ) {

            console.log('signatureSelected')
            console.log(signatureSelected)
            // alert('alterar cartão da assinatura')

            let PlanAndBillingUpdate = {
                subscription_id      : signatureSelected?.subscription_id,
                card_holder_name     : signatureSelected?.card_holder_name,
                card_number          : signatureSelected?.card_number,
                card_expiration_date : signatureSelected?.card_expiration_date,
                card_cvv             : signatureSelected?.card_cvv,
            }


            setLoadButtonSavePlan(true)
            await api.put('/plan-and-billing/', PlanAndBillingUpdate)
            .then(response => {

                setLoadButtonSavePlan(false);
                console.log('response.data')
                console.log(response.data)
                setTimeout(function(){

                    companyPlanSuccess();
                    loadCompanyPlan();
                    subscriptionFind()
                    setLoadButtonSavePlan(false);
                    setBtnPageFlatDetail(false)
                    setBtnPagePla(false)
                    setBtnPageAddPaymentMethod(false)
                    setBtnPageEditPaymentMethod(true)

                }, 500);
                swal({ icon: "success", title: "Sucesso!", text: "Dados do cartão atualizados com sucesso!" });

            })
            .catch(error=>{
                // console.log(error.response.data.message)
                // console.log(error.response.data.message)
                setLoadButtonSavePlan(false);
                swal({ icon: "warning", title: "Atenção!", text: error?.response?.data?.message });
            })

        }
        else {

            if(signatureSelected?.card_holder_name === undefined || signatureSelected?.card_holder_name === '') {
                setLoadCardHolderName(true)
            }
            if(signatureSelected?.document_number === undefined || signatureSelected?.document_number === '') {
                setLoadDocument_number(true)
            }
            if(signatureSelected?.card_number === undefined || signatureSelected?.card_number === '') {
                setLoadCardNumber(true)
            }
            if(signatureSelected?.card_expiration_date === undefined || signatureSelected?.card_expiration_date === '') {
                setLoadCardExpirationDate(true)
            }
            if(signatureSelected?.card_cvv === undefined || signatureSelected?.card_cvv === '') {
                setLoadCardCVC(true)
            }

        }


    }

    async function cancelPlanCompany(e, i) {

        console.log(e)
        console.log(i)

        let PlanAndBillingCancel ={
            "subscription_id": e,
            "path": props.match.path,
            "expirationDate": i
        }

        setLoadButtonSavePlan(true)
        await api.put('/plan-and-billing-cancel/', PlanAndBillingCancel)
        .then(response => {

            setLoadButtonSavePlan(false)

            // console.log('response.data.response.errors[0].message')
            // console.log(response.data.response.errors[0].message)

            if(response?.data?.response?.errors[0]?.message) {
                swal({ icon: "warning", title: "Atenção!", text: response?.data?.response?.errors[0]?.message ?
                'Não foi possível cancelar a assinatura' :
                'Algo deu errado, não foi possível cancelar a assinatura'
              });
            }
            else {

                console.log('response => Sucess cancel')
                console.log(response)

                setTimeout(function(){
                    // companyPlanSuccess();
                    loadGeneralPlan();
                    loadCompanyPlan();
                    loadCards();
                    subscriptionFind();
                    setPaymentMethod();
                    // setLoadButtonSavePlan(false);
                    setBtnPageFlatDetail(false)
                    setBtnPagePla(true)
                    setBtnPageAddPaymentMethod(false)
                    transactionsFind();

                }, 500);
                swal({ icon: "success", title: "Sucesso!", text: "Plano cancelado com sucesso!" });

            }



        })
        .catch(error=>{
            console.log(error)
            console.log(error)
            setLoadButtonSavePlan(false);
            swal({ icon: "warning", title: "Atenção!", text: error?.response?.data?.message });
        })

    }

    async function saveCard() {

        if(
            card?.card_holder_name === undefined
            ||
            card?.card_holder_name === ''
            ||
            card?.card_number === undefined
            ||
            card?.card_number === ''
            ||
            card?.card_expiration_date === undefined
            ||
            card?.card_expiration_date === ''
            ||
            card?.card_cvv === undefined
            ||
            card?.card_cvv === ''
        ) {

            if(card?.card_holder_name === undefined || card?.card_holder_name === '') {
                setLoadCardHolderName(true)
            }
            if(card?.card_number === undefined || card?.card_number === '') {
                setLoadCardNumber(true)
            }
            if(card?.card_expiration_date === undefined || card?.card_expiration_date === '') {
                setLoadCardExpirationDate(true)
            }
            if(card?.card_cvv === undefined || card?.card_cvv === '') {
                setLoadCardCVC(true)
            }

            return
        }
        else {

            let card_number_splited = card?.card_number.split(' ')

            // console.log(card_number_splited[0]+card_number_splited[1]+card_number_splited[2]+card_number_splited[3])

            let card_number = card_number_splited[0]+card_number_splited[1]+card_number_splited[2]+card_number_splited[3];

            let expiration_date_splited = card.card_expiration_date.split('/');
            let card_expiration_date    = expiration_date_splited[0]+expiration_date_splited[1];

            let CARD = {
                "customer_id" : card.customer_id,
                "card_number": card_number,
                "card_cvv": card.card_cvv,
                "card_holder_name": card.card_holder_name,
                "card_expiration_date": card_expiration_date,
                "path": props.match.pathth
            }

            setLoadButtonSaveCard(true)
            await api.post('/cards/', CARD)
            .then(response => {

                setTimeout(function(){
                    companyPlanSuccess();
                    loadCompanyPlan();
                    subscriptionFind();
                    setPaymentMethod();
                    loadCards();
                    setCard([]);

                    setLoadButtonSaveCard(false);

                    setOpenPagePaymentManage(true);
                    setOpenPageAddPayment(false);
                    setBtnPageFlatDetail(false)
                    setBtnPagePla(false)
                    setBtnPageAddPaymentMethod(false)

                }, 500);
                swal({ icon: "success", title: "Sucesso!", text: "Cartão cadastrado com sucesso!" });

            })
            .catch(error=>{
                console.log(error)
                console.log(error)
                // setCard([]);
                setLoadButtonSaveCard(false);
                swal({ icon: "warning", title: "Atenção!", text: error?.response?.data?.message });
            })

        }

    }

    async function destroyCard(e) {

        let pagarme_card_id     = e.pagarme_card_id;
        let pagarme_brand       = e.pagarme_brand;
        let pagarme_last_digits = e.pagarme_last_digits;

        setLoadcards(true)

        swal({
            title: "Atenção",
            text: `Você deseja excluir o cartão ${pagarme_brand} de final ${pagarme_last_digits}?`,
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            setLoadcards(false)

            if (res) {

                await api.put(`/cards/${pagarme_card_id}`,{ path: props.match.path })
                .then(response => {

                    setTimeout(function(){
                        companyPlanSuccess();
                        loadCompanyPlan();
                        subscriptionFind();
                        setPaymentMethod();
                        loadCards();

                        setLoadButtonSaveCard(false);

                        setOpenPagePaymentManage(true);
                        setOpenPageAddPayment(false);
                        setBtnPageFlatDetail(false)
                        setBtnPagePla(false)
                        setBtnPageAddPaymentMethod(false)

                    }, 500);
                    swal({ icon: "success", title: "Sucesso!", text: "Cartão removido com sucesso!" });

                })
                .catch(error=>{
                    console.log(error)
                    console.log(error)
                    setLoadButtonSaveCard(false);
                    swal({ icon: "warning", title: "Atenção!", text: error?.response?.data?.message });
                })

            }

        })

    }

    async function editCardPreferentialAndSubscription(e) {

        // console.log(e)
        // console.log(signatureCompany)

        let cardOldBrand      = signatureCompany?.card?.brand;
        let cardOldLastDigits = signatureCompany?.card?.last_digits;

        let subscription_id   = signatureCompany?.id;
        let card_id           = e.pagarme_card_id;

        let cardNewBrand      = e.pagarme_brand;
        let cardNewLastDigits = e.pagarme_last_digits;

        setLoadcards(true)

        swal({
            title: "Atenção",
            text: `Você está para modificar o seu cartão preferencial do cartão ${cardOldBrand} de final ${cardOldLastDigits} para o cartão ${cardNewBrand} de final ${cardNewLastDigits}, confirma essa modificação?`,
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            setLoadcards(false)

            if (res) {

                let editCardandSubscription = {
                    "subscription_id" : subscription_id,
                    "card_id": card_id,
                    "cardOldBrand": cardOldBrand,
                    "path": props.match.path
                }

                await api.put('/update-preferential-card-and-signature/', editCardandSubscription)
                .then(response => {

                    setTimeout(function(){
                        companyPlanSuccess();
                        loadCompanyPlan();
                        subscriptionFind();
                        setPaymentMethod();
                        loadCards();
                        setCard([]);

                        setLoadcards(false);

                        setOpenPagePaymentManage(true);
                        setOpenPageAddPayment(false);
                        setBtnPageFlatDetail(false)
                        setBtnPagePla(false)
                        setBtnPageAddPaymentMethod(false)

                    }, 500);
                    swal({ icon: "success", title: "Sucesso!", text: "Cartão alterado para preferencial com sucesso!" });

                })
                .catch(error=>{
                    console.log(error)
                    console.log(error)
                    setCard([]);
                    setLoadButtonSaveCard(false);
                    swal({ icon: "warning", title: "Atenção!", text: error?.response?.data?.message });
                })

            }

        })


    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ hideSidebar, setHideSidebar] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ statusDate, setStatusDate] = useState();


    async function statusCompanyTrial(startOfTrial, statusCompany) {

        if(statusCompany === 'Trial') {

            let createdAtCompanny_  = new Date(startOfTrial);
            let createdAtCompanny   = createdAtCompanny_.toISOString().split('T')[0]
            let createdAtCompanny_a = createdAtCompanny.split('-');
            let createdAtCompanny_b = new Date(createdAtCompanny_a[0] + "-" + createdAtCompanny_a[1] + "-" + createdAtCompanny_a[2]);

            let currentDate_ = new Date();

            let currentDate   = currentDate_.toISOString().split('T')[0]
            let currentDate_a = currentDate.split('-');
            let currentDate_b = new Date(currentDate_a[0] + "-" + currentDate_a[1] + "-" + currentDate_a[2]);

            let totalTrialDaysCompany = moment(currentDate_b).diff(moment(createdAtCompanny_b,"days"));
            let durationTotalTrialDaysCompany = moment.duration(totalTrialDaysCompany);

            if(durationTotalTrialDaysCompany._data.days !== undefined) {
                var days   = durationTotalTrialDaysCompany._data.days;
                setStatusDate(days)
            }
            if(durationTotalTrialDaysCompany._data.months !== undefined) {
                var months = durationTotalTrialDaysCompany._data.months;
            }
            if(durationTotalTrialDaysCompany._data.years !== undefined) {
                var years  = durationTotalTrialDaysCompany._data.years;
            }

            if(days > 10 || months > 0 || years > 0) {

                // console.log('acabou trial')
                setHideSidebar(true)
                setBtnPageFlatDetail(false)
                setBtnPagePla(true)
                setBtnPageAddPaymentMethod(false)


            }
            else{
                setHideSidebar(false)
                setBtnPageFlatDetail(false)
                setBtnPagePla(true)
                setBtnPageAddPaymentMethod(false)
            }

        }
        else {

            setHideSidebar(false)
            setBtnPageFlatDetail(true)
            setBtnPagePla(false)
            setBtnPageAddPaymentMethod(false)

        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ openPagePaymentManage, setOpenPagePaymentManage] = useState(false);
    async function openPaymentManage() {

        setOpenPagePaymentManage(true);
        setOpenPageAddPayment(false);
        setBtnPageFlatDetail(false);
        setCard([]);
        setLoadCardHolderName(false);
        setLoadCardNumber(false);
        setLoadCardExpirationDate(false);
        setLoadCardCVC(false);

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ openPageAddPayment, setOpenPageAddPayment] = useState(false);
    async function openAddPayment() {
        setOpenPageAddPayment(true)
        setOpenPagePaymentManage(false);
        setBtnPageFlatDetail(false);

    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                />
                <SubMenu
                path={props.match.path}
                hideSidebar={hideSidebar}
                />

                <div className="configSystem-plano-cobranca ">
                    <h2 className="titulo">Plano e cobrança</h2>
                        {
                            pageFlatDetail ?
                            <FlatDetail
                            planCompany={planCompany}

                            signatureCompany={signatureCompany }
                            loadSignatureCompany={loadSignatureCompany}

                            transactionsCompany={transactionsCompany}
                            loadTransactionsCompany={loadTransactionsCompany}

                            dataFormatadaParaProximaCobranca={dataFormatadaParaProximaCobranca}
                            dataFormatadaParaDataLimite={dataFormatadaParaDataLimite}
                            changePageAddEditPaymentMethod={changePageAddEditPaymentMethod}
                            dateFormatedToSignature={dateFormatedToSignature}
                            ExchangePlan={ExchangePlan}
                            openPaymentManage={openPaymentManage}

                            cancelPlanCompany={cancelPlanCompany}
                            loadButtonSavePlan={loadButtonSavePlan}

                            />
                            : ""
                        }
                        {
                            pagePlan ?
                            <ChangePlan
                                loadPlan={loadPlan}
                                planGeneral={planGeneral}
                                planCompany={planCompany}
                                changePageFlatDetail={changePageFlatDetail}
                                changePageAddPaymentMethod={changePageAddPaymentMethod}
                                signatureCompany={signatureCompany}
                                statusDate={statusDate}
                            />: ""
                        }
                        {
                            pageAddPaymentMethod?
                            <AddPaymentMethod
                            planSelected={planSelected}
                            signatureSelected={signatureSelected}
                            ExchangePlan={ExchangePlan}
                            myChangeHandlerPlan={myChangeHandlerPlan}
                            paymentMethod={paymentMethod}
                            loadCardHolderName={loadCardHolderName}
                            document_number={document_number}
                            loadCardNumber={loadCardNumber}
                            loadCardExpirationDate={loadCardExpirationDate}
                            loadCardCVC={loadCardCVC}
                            loadName={loadName}
                            loadEmail={loadEmail}
                            loadZipcode={loadZipcode}
                            loadNeighborhood={loadNeighborhood}
                            loadStreet={loadStreet}
                            loadStreetNumber={loadStreetNumber}
                            loadTelefone={loadTelefone}
                            loadComplementary={loadComplementary}

                            savePlanCompany={savePlanCompany}
                            loadButtonSavePlan={loadButtonSavePlan}
                            />
                            :""
                        }
                        {
                            pageEditPaymentMethod ?

                            <EditPaymentMethod
                            // planSelected={planSelected}
                            signatureSelected={signatureSelected}
                            changePageFlatDetail={changePageFlatDetail}
                            myChangeHandlerEditPlan={myChangeHandlerEditPlan}
                            loadCardHolderName={loadCardHolderName}
                            document_number={document_number}
                            loadCardNumber={loadCardNumber}
                            loadCardExpirationDate={loadCardExpirationDate}
                            loadCardCVC={loadCardCVC}
                            loadName={loadName}
                            loadEmail={loadEmail}
                            loadZipcode={loadZipcode}
                            loadNeighborhood={loadNeighborhood}
                            loadStreet={loadStreet}
                            loadStreetNumber={loadStreetNumber}
                            loadTelefone={loadTelefone}
                            loadComplementary={loadComplementary}

                            editPlanCompany={editPlanCompany}
                            loadButtonSavePlan={loadButtonSavePlan}
                            />
                            :
                            ''
                        }
                        {
                            openPagePaymentManage ?
                            <PaymentManage
                            openAddPayment={openAddPayment}
                            changePageFlatDetail={changePageFlatDetail}
                            loadcards={loadcards}
                            cardsGeneral={cardsGeneral}
                            destroyCard={destroyCard}
                            editCardPreferentialAndSubscription={editCardPreferentialAndSubscription}
                            />
                            :""
                        }
                        {
                            openPageAddPayment ?
                            <AddMethodPayment
                                loadCardHolderName={loadCardHolderName}
                                loadCardNumber={loadCardNumber}
                                loadCardExpirationDate={loadCardExpirationDate}
                                loadCardCVC={loadCardCVC}
                                openPaymentManage={openPaymentManage}
                                myChangeHandlerCard={myChangeHandlerCard}
                                card={card}
                                loadButtonSaveCard={loadButtonSaveCard}
                                saveCard={saveCard}
                             /> : ""
                        }
                </div>
            </Layout>
        </>
    );
}
