import React, { useState, useEffect } from 'react';
import Carregamento from '../../../../assets/images/carregando.gif';
import iconUpload from '../../../../assets/images/docs-upload.svg';
import api from '../../../../services/api';
import { toast } from 'react-toastify';


export default function ColProfiDcocTermContracts({ id }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredTermOrContract, setRegisteredTermOrContract ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadtermorcontract(id);
    }, [id]);


    async function loadtermorcontract(id) {
        setLoadPage(true)
        await api.get(`/collaborator-term-or-contract/${id}`)
        .then(response => {
            setLoadPage(false)
            if(response.data[0] !== undefined) {
                setRegisteredTermOrContract(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ termorContract, setFilterTermorContract ] = useState([]);

    async function loadfiltertermorcontract(event) {
        setLoadPage(true)
        const name = event.target.value;
        await api.get(`/collaborator-term-or-contract-access-id?id=${id}&name=${name}`)
        .then(response => {
            setFilterTermorContract(response.data);
            setLoadPage(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    return (
        <>
            <div className="box perfil-termo-contrato user">
                <div className="buscaAdd">
                    <form action="">
                        <input name="name" onChange={(event) => loadfiltertermorcontract(event)} type="text" placeholder="Procure pela data e tipo..." className="busca"/>
                    </form>

                </div>
                    <table>
                    <thead>
                        <tr className="tabelaTR">
                            <th className="inten-1">
                                Tipo
                            </th>
                            <th className="inten-2">Data</th>
                            <th className="inten-3"></th>
                            <th className="inten-4"></th>
                        </tr>
                    </thead>
                    <tbody>

                    {
                        loadPage ?
                        (
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            </>
                        ):
                        termorContract[0] !== undefined ? termorContract
                        .map((termorcontract, index) => (
                        <>
                        <tr className="tabelaTR"  key={index} >

                            <td className="inten-1">
                                <div className="motivo">
                                    <strong>{termorcontract.type}</strong>
                                </div>
                            </td>
                            <td className="inten-2">
                                {termorcontract.date}
                            </td>
                            <td className="inten-3">

                                    <a href={termorcontract.location} target="’_blank’">
                                        <img alt="icone de documentos" src={iconUpload} />
                                        {termorcontract.originalname}
                                    </a>
                            </td>
                        </tr>
                        </>
                        )) :
                        registeredTermOrContract.length === 0 ?
                        (<div className="nenhum-cadastrado-perfil"><p>Nenhum formação acadêmica cadastrada.</p></div>):
                        registeredTermOrContract !== undefined ? registeredTermOrContract
                        .map((termorcontract, index) => (
                        <>
                        <tr className="tabelaTR"  key={index} >

                            <td className="inten-1">
                                <div className="motivo">
                                    <strong>{termorcontract.type}</strong>
                                </div>
                            </td>
                            <td className="inten-2">
                                {termorcontract.date}
                            </td>
                            <td className="inten-3">

                                <a href={termorcontract.location} target="’_blank’">
                                    <img alt="icone de documentos" src={iconUpload} />
                                    {termorcontract.originalname}
                                </a>
                            </td>
                        </tr>
                        </>
                        )) : (
                            <>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}
