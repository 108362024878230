import React, {useEffect, useState} from 'react';
import apiV2 from '../../../../../../services/apiV2';
import { toast } from 'react-toastify';

import Carregamento from '../../../../../../assets/images/carregando.gif';

export default function ColProfileWorkDay ({ id, role, path, collaborator,
    addWorkdayToEmployee,
    loadBtnOnAndOff,
    disableWorkdayToEmployee,
    workdaySelect

}) {
    useEffect(() => {
        getWorkJourneyCompany();
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [workJourney, setWorkJourney] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadWorkJourneyCompany, setloadWorkJourneyCompany  ] = useState(false);

    async function getWorkJourneyCompany() {
        setloadWorkJourneyCompany(true)
        await apiV2.get('/weekly-journey')
        .then(response => {

            setloadWorkJourneyCompany(false)
            setWorkJourney(response.data.items);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setloadWorkJourneyCompany(false)
        })
    }

    return (
        <>
        <div className="profile-work-day">
            <h1 className="titulo">Jornada de trabalho</h1>
            <p className="sub-titulo">Selecione uma das jornadas abaixo para atrelar à este colaborador</p>

            <div className="journey-list">

            {

                loadWorkJourneyCompany ?
                    (
                        <>
                        <div className="carregamentoWork">
                            <img alt="" src={Carregamento} />
                        </div>
                        </>
                    ):
                    workJourney[0] !== undefined ? 
                        workJourney?.map((journey) => (
                            <>
                            <div key={journey._id} 
                            className={
                                collaborator?.weeklyJourney !== journey._id ? "jornada" : 'jornada active'
                            }>
                                <h6 className="name">
                                    {journey?.name}
                                    <button 
                                    disabled={loadBtnOnAndOff ? true : false}  
                                    className={`
                                        button-toggle 
                                        ${workdaySelect === journey._id ? "load" :"" + collaborator?.weeklyJourney !== journey._id ? "" : "active"}
                                    `}
                                    onClick={() => addWorkdayToEmployee(journey._id)}>
                                        <span></span>
                                    </button>

                                </h6>
                                <ul className="header-infor">
                                    <li>Dia</li>
                                    <li>Período 1</li>
                                    <li>Período 2</li>
                                    <li>Hora Total</li>
                                </ul>
                                <ul className='list-body-infor'>
                                    {
                                        journey?.days.map((dayWeek) => (
                                            <>
                                                <li key={dayWeek._id}>
                                                    <ul className="body-infor">
                                                        <li>
                                                            {
                                                                dayWeek.dayOfWeek === "1" ?
                                                                    "Segunda-feira" :
                                                                    dayWeek.dayOfWeek === "2" ?
                                                                        "Terça-feira" :
                                                                        dayWeek.dayOfWeek === "3" ?
                                                                            "Quarta-feira" :
                                                                            dayWeek.dayOfWeek === "4" ?
                                                                                "Quinta-feira" :
                                                                                dayWeek.dayOfWeek === "5" ?
                                                                                    "Sexta-feira" :
                                                                                    dayWeek.dayOfWeek === "6" ?
                                                                                        "Sábado" :
                                                                                        dayWeek.dayOfWeek === "7" ?
                                                                                            "domingo" : ""
                                                            }
                                                        </li>
                                                        <li>
                                                            {
                                                                dayWeek?.period1?.initTime ?
                                                                    dayWeek?.period1?.initTime : "-"
                                                            } - {
                                                                dayWeek?.period1?.endTime ?
                                                                    dayWeek?.period1?.endTime : "-"
                                                            }
                                                        </li>
                                                        <li>
                                                            {
                                                                dayWeek?.period2?.initTime ?
                                                                    dayWeek?.period2?.initTime : "-"
                                                            } - {
                                                                dayWeek?.period2?.endTime ?
                                                                    dayWeek?.period2?.endTime : "-"
                                                            }
                                                        </li>
                                                        <li>
                                                            {
                                                                dayWeek?.totalTimeWorking ?
                                                                    dayWeek?.totalTimeWorking : "-"
                                                            }
                                                        </li>
                                                    </ul>
                                                </li>


                                            </>
                                        ))
                                    }

                                </ul>
                                <p className='total-amount-of-hours'>Total: <span> {journey?.totalTimeWorking} horas</span></p>
                            </div>
                            </>
                        ))
                    :""
            }

            </div>
        </div>
        </>
    );
}
