import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/defaultUser';
import SubHeader from '../../../components/PerfilUser/subHeader';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import Playroll from '../../../components/PerfilUser/Professional/PlayrollNew';

import { decodeToken } from '../../../services/auth';
import '../styles.scss';
import api from '../../../services/api';
import { toast } from 'react-toastify';
const ColProfileVacation = (props) => {

    const decoded = decodeToken();
    var id = decoded.user._id;
    const [ collaborator, setCollaborator ] = useState([]);

    useEffect(() => {
        async function loadcollaborator() {
            await api.get(`/collaborator-access/${id}`)
            .then(response => {
                setCollaborator(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            })
        }
        loadcollaborator();
    }, [id]);

        return (
            <>
               <Layout
               path={props.match.path}
               >

                    <div className="profile-user">
                    <InforProfile
                        collaborator={collaborator}
                        id={id}
                        path={props.match.path}
                        setCollaborator={setCollaborator}
                    />
                        <SubHeader
                        id={id}
                        path={props.match.path}
                        collaborator={collaborator}/>
                        <Playroll
                        id={id}
                        path={props.match.path}
                        collaboratorState={collaborator}
                        collaborator={collaborator}
                        />
                    </div>
                </Layout>
            </>
        );
}
export default ColProfileVacation;
