import React,{useState} from 'react';
import EditBlack from '../../../../../../assets/images/edit-black.svg';

import Carregando from '../../../../../../assets/images/carregando.gif';
import Close from '../../../../../../assets/images/close.svg';
import Dropzone from "react-dropzone";
import { Modal} from "react-bootstrap";
import {removerSpecials} from '../../../../../../services/utils'
import SelectPersonalizado from '../../../../../../components/Reused/selectPersonalizado'

const ColProfileRG = ({
    id,
    collaborator,
    loadPage,
    onCollaboratorInputChange,
    OnSubmit,
    collaboratorUpdate,
    loadContinue,
    EditRGVisible,
    visibleRG,
    filesSavedRG,
    loadRG,
    deleteFileRG,
    fileNamesRG,
    setCollaboratorUpdate,

    setSelectRGufOrgan,
    selectRGufOrgan,
    estadosOrgam,
    handleDropRG
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ufOrganStringBusca , setufOrganStringBusca] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        
        if(nam === "ufOrganBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setufOrganStringBusca({ ...ufOrganStringBusca, [nam]: valOption });
        }else if(
            nam === "ufOrgan"
        ) {
            setCollaboratorUpdate({ ...collaboratorUpdate,  "ufOrgan": val })
            setufOrganStringBusca({ ...ufOrganStringBusca, "ufOrganBusca": val });

        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = estadosOrgam.filter((valOption) => {
        return removerSpecials(valOption).toLowerCase().includes(ufOrganStringBusca?.ufOrganBusca !== undefined ? ufOrganStringBusca.ufOrganBusca : "" )  ;
    });


        return (
            <>

                    <div className="box identidade">
                        <h3>RG                
                            {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? 
                                "" 
                                :  
                                <button className="edit-Benefit" onClick={() => EditRGVisible()}><img src={EditBlack} alt="Edite Informações" /></button>     
                            }
                        </h3>
                        {
                            loadPage ?
                                (
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregando} />
                                    </div>
                                    </>
                                ):(
                                    <>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Número do RG</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.rg ? collaborator.rg : '-' }</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Data de expedição</p>
                                </div>
                                <div className="intemB">
                                <p>{collaborator.rgExpeditionDate ? collaborator.rgExpeditionDate : '-'}</p>

                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Órgão emissor</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.rgOrgan ? collaborator.rgOrgan : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>UF emissor</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.ufOrgan ? collaborator.ufOrgan : '-'}</p>
                                </div>
                            </li>

                            {filesSavedRG[0] !== undefined ? (
                            <li className="li-anexo">
                                <div className="intemA">
                                    <p>Anexo</p>
                                </div>



                            <div className="intemB arq-adicionado" >
                                {filesSavedRG[0] !== undefined ?
                                filesSavedRG.map((filesaved, index) => (
                                            <a href={filesaved.location} target="’_blank’" key={index}>
                                                {filesaved.name}
                                            </a>
                                )) : (
                                    <>
                                    <p>-</p>
                                    </>
                                )}
                            </div>
                            </li>
                            ) : ('')}
                        </ul>
                        </>)}
                    </div>

                    <Modal show={visibleRG} className="popup-edit-perfil popup-edit-cpf" onHide={EditRGVisible}>
                    <div >
                        <div className="titulo">
                            <h4>RG <p className="btn-fechar" onClick={EditRGVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Número do RG</p>
                                </div>
                                <div className="intemB">
                                    {
                                        (collaboratorUpdate && collaboratorUpdate.rg !== undefined) || collaboratorUpdate.rg === '' ?
                                        (
                                            <input type="text"  name="rg" maxlength="12" value={(collaboratorUpdate.rg !== undefined) ? collaboratorUpdate.rg : ''}  onChange={onCollaboratorInputChange} placeholder="Número do RG"  />
                                        ) :
                                        collaborator && collaborator.rg ? (
                                            <input type="text"  name="rg" maxlength="12" defaultValue={(collaborator.rg !== undefined) ? collaborator.rg : ''}  onChange={onCollaboratorInputChange} placeholder="Número do RG"/>
                                        ):
                                        (
                                            <input type="text"  name="rg" maxlength="12" value={(collaboratorUpdate.rg !== undefined) ? collaboratorUpdate.rg : ''}  onChange={onCollaboratorInputChange} placeholder="Número do RG"  />
                                        )
                                    }

                                    {/* {collaborator.rg !== undefined ?
                                    (<InputMask type="text" name="rg" mask="9.999.999-9" defaultValue={collaborator.rg ? collaborator.rg : ''} onChange={onCollaboratorInputChange} className="inputUm"/>):
                                    (<InputMask mask="9.999.999-9" type="text" name="rg" onChange={onCollaboratorInputChange} placeholder="Número do RG" className="inputUm"/>)} */}

                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Data de expedição</p>
                                </div>
                                <div className="intemB">
                                    {
                                        (collaboratorUpdate && collaboratorUpdate.rgExpeditionDate !== undefined) || collaboratorUpdate.rgExpeditionDate === '' ?
                                        (
                                            <input type="text"  name="rgExpeditionDate" maxlength="10" value={(collaboratorUpdate.rgExpeditionDate !== undefined) ? collaboratorUpdate.rgExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  />
                                        ) :
                                        collaborator && collaborator.rgExpeditionDate ? (
                                            <input type="text"  name="rgExpeditionDate" maxlength="10" defaultValue={(collaborator.rgExpeditionDate !== undefined) ? collaborator.rgExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"/>
                                        ):
                                        (
                                            <input type="text"  name="rgExpeditionDate" maxlength="10" value={(collaboratorUpdate.rgExpeditionDate !== undefined) ? collaboratorUpdate.rgExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  />
                                        )
                                    }
                                    {/* {collaborator.rgExpeditionDate !== undefined ?
                                    (<InputMask type="text"  mask="99/99/9999" name="rgExpeditionDate" defaultValue={collaborator.rgExpeditionDate ? collaborator.rgExpeditionDate : ''} onChange={onCollaboratorInputChange} className="inputDois"/>):
                                    (<InputMask mask="99/99/9999" type="text" name="rgExpeditionDate" onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" className="inputDois"/>)} */}

                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Órgão emissor</p>
                                </div>
                                <div className="intemB">


                                {
                                        (collaboratorUpdate && collaboratorUpdate.rgOrgan !== undefined) || collaboratorUpdate.rgOrgan === '' ?
                                        (
                                            <input type="text"  name="rgOrgan" maxlength="10" value={(collaboratorUpdate.rgOrgan !== undefined) ? collaboratorUpdate.rgOrgan : ''}  onChange={onCollaboratorInputChange} placeholder="Órgão emissor"  />
                                        ) :
                                        collaborator && collaborator.rgOrgan ? (
                                            <input type="text"  name="rgOrgan" maxlength="10" defaultValue={(collaborator.rgOrgan !== undefined) ? collaborator.rgOrgan : ''}  onChange={onCollaboratorInputChange} placeholder="Órgão emissor"/>
                                        ):
                                        (
                                            <input type="text"  name="rgOrgan" maxlength="10" value={(collaboratorUpdate.rgOrgan !== undefined) ? collaboratorUpdate.rgOrgan : ''}  onChange={onCollaboratorInputChange} placeholder="Órgão emissor"  />
                                        )
                                    }

                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>UF emissor</p>
                                </div>
                                <div className="intemB">
                                    <SelectPersonalizado
                                        valorCampo={
                                            ufOrganStringBusca && ufOrganStringBusca.ufOrganBusca ?
                                            ufOrganStringBusca.ufOrganBusca :
                                            ufOrganStringBusca && ufOrganStringBusca.ufOrganBusca === '' ?
                                            '' :
                                            collaborator && collaborator.ufOrgan ?
                                                collaborator.ufOrgan
                                                :
                                                ''
                                        }
                                        nameCampoBusca={"ufOrganBusca"}
                                        nameButton={"ufOrgan"}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreUCidade}
                                        typeSelect={"busca"}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Anexar</p>
                                </div>
                                <div className="intemB">
                                        <Dropzone onDrop={handleDropRG} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                </div>
                            </li>

                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </li>
                            {filesSavedRG[0]  !== undefined ? (
                            <li>
                                <div className="arquivoAnexado">
                                    <h5>Enviados</h5>

                                    {filesSavedRG[0] !== undefined ?
                                    filesSavedRG.map((filesaved, index) => (
                                        <div key={index} className="arquivo">
                                            <p className="nomeArquivo">
                                                <a href={filesaved.location} target="’_blank’">
                                                    {filesaved.name}
                                                </a>
                                            </p>
                                            <button onClick={()=>deleteFileRG(filesaved)} className="btnExclui">Remover anexo</button>
                                        </div>
                                    )) : ''}

                                </div>
                            </li>
                                    ) : ''}
                            {loadRG ?
                            ( <li>
                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            </li>) : ('')}
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <form onSubmit={OnSubmit} className="btn-salvar">
                                        <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                    </form>
                                </div>
                            </li>


                        </ul>


                    </div>
                </Modal>
            </>
        );
}
export default ColProfileRG;
