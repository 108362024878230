import React  from 'react';
import HeaderAdmin from '../HeaderAdmin';
import { NavLink } from 'react-router-dom'
import { formatDateString, timeItWasRegistered } from '../../services/utils';


export default function headerRecruitmentAndSelection({
    path,
    pathname,
    loadCandidatesInfor,
    modalAddFolderOpen,
    candidates,
    nameFolder,
    folderCandidates

}) {


    const voltar_recrutamento_talentos = localStorage.getItem('@peopleview/voltar_recrutamento_talentos');

    function saveCandidate() {

        window.location.href = "/recrutamento/cadastro-candidato";

    }

    function goBackTalents() {
        if(voltar_recrutamento_talentos === null) {
            window.location.href = '/recrutamento/talentos/';
        }
        else {
            window.location.href = `${voltar_recrutamento_talentos}`;
        }
    }
    return (
        <>
        <HeaderAdmin>
            {
                path === '/recrutamento/cadastro-candidato' ?
                    <>

                    <div>
                        <h1><button onClick={goBackTalents} className="voltar">Voltar</button>Cadastrar novo candidato</h1>
                    </div>
                    </>

                    : ""
            }
             {
                path === '/recrutamento/editar-candidato/:id' ?
                    <>

                    <div>
                        <h1> <button onClick={goBackTalents} className="voltar">Voltar</button>Editar candidato</h1>
                    </div>
                    </>
                : ""
            }
            {
              path === '/recrutamento/perfil-candidato/:id' ?
                    <>
                    <div>
                        <h1><button onClick={goBackTalents} className="voltar">Voltar</button>{candidates && candidates?.name}</h1>
                       {loadCandidatesInfor ?"":
                       candidates ?
                        <p>Recebido em { formatDateString(candidates.createdAt)} às {candidates ? timeItWasRegistered(candidates?.createdAt) : ""}</p>
                        :"" }


                    </div>
                    </>
                : ""
            }

            {
              path === '/recrutamento/vagas' ?
                    <>
                    <div>
                        <h1>Vagas</h1>
                        <p>Cadastre e gerencie vagas abertas na sua empresa</p>
                    </div>
                    <div  className='vagas-button'>
                        <NavLink to="/recrutamento/cadastrar-vaga"className='btnGreen more'> Cadastrar nova vaga </NavLink>
                    </div>
                    </>
                : ""
            }

            {
              path === '/recrutamento/talentos/pasta/:id' ?
                    <>
                    <div>
                        <h1><button onClick={goBackTalents} className="voltar">Voltar</button>{nameFolder}</h1>
                        <p>{folderCandidates} candidatos nesta pasta</p>
                    </div>
                    <div>
                        <NavLink to="/recrutamento/cadastrar-vaga"className='btnGreen more'> Cadastrar nova vaga </NavLink>
                    </div>
                    </>
                : ""
            }

            {
              path === '/recrutamento/cadastrar-vaga' ?
                    <>
                    <div>
                        <h1><NavLink to="/recrutamento/vagas"className='voltar'>Voltar</NavLink>Cadastrar vaga</h1>
                        <p>Anuncie uma vaga</p>
                    </div>
                    </>
                : ""
            }

            {
              path === '/recrutamento/editar-vaga/:id' ?
                    <>
                    <div>
                        <h1><NavLink to="/recrutamento/vagas"className='voltar'>Voltar</NavLink>Editar vaga</h1>
                        <p>Anuncie uma vaga</p>
                    </div>
                    </>
                : ""
            }

        {
            path === '/recrutamento/talentos'           ||
            path === '/recrutamento/talentos/pastas'    ||
            path === '/recrutamento/talentos/candidatos'
            ?
            <>
                <div>
                    <h1>Talentos</h1>
                    <p>Encontre e gerencie perfis de candidatos, currículos e mais</p>
                </div>
                <div className='talents-button'>
                    <button className='btnGreen more' onClick={()=>modalAddFolderOpen('e')}>Criar nova pasta</button>

                    <button
                    // to="/recrutamento/cadastro-candidato"
                    onClick={()=>saveCandidate()}
                    className='btnGreen more'
                    >Cadastrar novo candidato</button>
                </div>
            </>
        : ""
        }
        {
            path === '/recrutamento/configuracoes-da-pagina'||
            path === '/recrutamento/politica-e-privacidade-da-pagina'||
            path === '/recrutamento/personalizar-pagina' ?
                <>
                <div>
                    <h1>Personalizar página</h1>
                    <p>Esta é a página personalizada de vagas da sua empresa</p>
                </div>
                </>
            : ""
        }
        </HeaderAdmin>
        {
            path === '/recrutamento/talentos' ||
            path === '/recrutamento/talentos/pastas' ||
            path === '/recrutamento/talentos/candidatos'
            ?
            <>


            <div className='talents-h-mobile'>
                <button className='btnGreen more' onClick={()=>modalAddFolderOpen('e')}>Criar nova pasta</button>
                <button className='btnGreen more'>Cadastrar novo candidato</button>
            </div>

            </>
            : ""
        }
        {
            path === '/recrutamento/vagas' ?
                <div  className='vagas-button-mobile'>
                    <NavLink to="/recrutamento/cadastrar-vaga"className='btnGreen more'> Cadastrar nova vaga </NavLink>
                </div>
            :""
        }
        </>
    );
}

