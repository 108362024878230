import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';


import GothamBlackWoof2 from '../assets/fonts/Gotham-Black.woff2';
import GothamBlackWoof from '../assets/fonts/Gotham-Black.woff';

import GothamMediumWoof2 from '../assets/fonts/Gotham-Medium.woff2';
import GothamMediumWoof from '../assets/fonts/Gotham-Medium.woff';

import GothamBoldWoof2 from '../assets/fonts/Gotham-Bold.woff2';
import GothamBoldWoof from '../assets/fonts/Gotham-Bold.woff';

import Brushaff from '../assets/fonts/Brushaff.otf';
// import Brushaff2 from '../assets/fonts/Brushaff.otf2';




export default createGlobalStyle`

@font-face {
    font-family: 'GothamBlack';
    src: local('GothamBlack'), local('Gotham-Black'),
    // url(${GothamBlackWoof2}) format('woff2'),
    url(${GothamBlackWoof}) format('woff');
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamMedium';
    src: local('GothamMedium'), local('Gotham-Medium'),
    url(${GothamMediumWoof2}) format('woff2'),
    url(${GothamMediumWoof}) format('woff');
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamBold';
    src: local('GothamBold'), local('Gotham-Bold'),
    url(${GothamBoldWoof2}) format('woff2'),
    url(${GothamBoldWoof}) format('woff');
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Brushaff';
    src: local('Brushaff'),
    url(${Brushaff}) format('otf');
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
}

:root {
    --background: #ffffff;
    --color-sidebar: #e6ecec;
    --color-sidebar-user: #c1e1de;
    --light-blue: #81d8d0;
    --green: #2bc78b;
    --blue: #327ee4;
    --orange: #ec6d1d;
    --text-color: #666666;
    --text-color-feed: #999999;
    --text-sidebar: #3f544e;
    --text-sidebar-user: #124e48;
    --text-black: #000000;
    --border: #d5dfdb;
    --red: #e95656;

}
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

*:focus {
    outline: 0;
}

html {
    @media (max-width: 1080px) {
        font-size: 92,85%
    }

    @media (max-width: 720px) {
        font-size: 85,72%
    }
}

    html, body {
        height: 100%;
    }

    body {
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }

    body, input, button {
        font: 12px 'GothamMedium', sans-serif;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style: none;
    }

    button {
        cursor: pointer;
    }
    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
