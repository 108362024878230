import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import Dropzone from "react-dropzone";
import IconCamera from '../../../assets/images/icone-camera.svg';
import CarregandoFoto from '../../../assets/images/carregando.gif';
import swal from 'sweetalert';
import api from '../../../services/api';
import axios from 'axios';
import {
    phones,
    mCNPJ,
    mCNPJ_ONLY_NUMBERS,
    phoneNumbersOnly
} from '../../../services/mask';

import './styles.scss';
export default function pageSystemConfig(props, path) {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRazaoSocialEdit, setLoadRazaoSocialEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTelefoneEdit, setLoadTelefoneEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmailEdit, setLoadEmailEdit] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCnpjEdit, setLoadCnpjEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCEPEdit, setLoadCEPEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNumeroEdit, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRuaAvenidaEdit, setLoadRuaAvenidaEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBairroEdit, setLoadBairroEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEstadoEdit, setLoadEstadoEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCidadeEdit, setLoadCidadeEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadComplementoEdit, ] = useState(false);


      ////////////////////////////////////////////////////////////////////////////
     ///////////////////////    DADOS DA EMPRESA   //////////////////////////////
    ////////////////////////////////////////////////////////////////////////////



    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ comapanyData, setComapanyData] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPageCompanyData] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonUpdate, setLoadButtonUpdate] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ comapanyDataUpdate, setComapanyDataUpdate] = useState([]);


    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ haveChange, setHaveChange] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        setLoadPageCompanyData(true)
        async function loadcompany() {

            await api.get('/company-admin/')
            .then(response => {

                setLoadPageCompanyData(false)
                // setComapanyData(response.data);
                setComapanyDataUpdate(response.data);
                // let cnpjLoad = response.data.cnpj

                // if(cnpjLoad !== undefined) {
                //     let cnpj = mCNPJ(cnpjLoad)
                //     setComapanyDataUpdate({ ...comapanyDataUpdate, 'cnpj': cnpj })

                // }

            }).catch(error => {})
        }
        loadcompany();
    }, []);





    async function myChangeHandlerCompany(event) {

        let nam = event.target.name;
        let val = event.target.value;
        let haveChange = true;

        //name
        if(nam === 'name') {

            if(val.length === 0) {
                setLoadRazaoSocialEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadRazaoSocialEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }


        }
        else
        //phone
        if (nam === "phone") {
                // let telefone = phones(val)

            if(val.length === 0) {
                setLoadTelefoneEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadTelefoneEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }


        }
        else
        if (nam === "cnpj") {

            if(val.length === 0) {
                setLoadCnpjEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                // var cnpj = mCNPJ(val)
                setLoadCnpjEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val, haveChange })
            }

        }
        else
        //email
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmailEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadEmailEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val, haveChange })
            }

        }
        else
        if(nam === "zipcode") {


            if(val.length === 0) {
                setLoadCEPEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else
            if(val.length <=7 && val.length >= 1) {
                setLoadCEPEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {

                let zipcode = String(val).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
                //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
                // let cepppp = cep(zipcode)
                const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

                setComapanyDataUpdate({
                    ...comapanyDataUpdate,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf
                })

                setLoadRuaAvenidaEdit(false)
                setLoadBairroEdit(false)
                setLoadEstadoEdit(false)
                setLoadCidadeEdit(false)
            }


        }
        else
        //address
        if(nam === 'address') {

            if(val.length === 0) {
                setLoadRuaAvenidaEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadRuaAvenidaEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }

        }
        else
        //addressNeighborhood
        if(nam === 'addressNeighborhood') {

            if(val.length === 0) {
                setLoadBairroEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadBairroEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }

        }
        else
        //addressState
        if(nam === 'addressState') {

            if(val.length === 0) {
                setLoadEstadoEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadEstadoEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }

        }
        else
        //addressCity
        if(nam === 'addressCity') {

            if(val.length === 0) {
                setLoadCidadeEdit(true)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }
            else {
                setLoadCidadeEdit(false)
                setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })
            }

        }
        else {

            setComapanyDataUpdate({ ...comapanyDataUpdate, [nam]: val })

        }

    }


    async function updateCompany() {
            let idCompany = comapanyDataUpdate._id;

            if(
                comapanyDataUpdate                                   &&
                comapanyDataUpdate.name                !== undefined &&
                comapanyDataUpdate.name                !== ""
            )  {

                setLoadRazaoSocialEdit(false)
            }else {
                setLoadRazaoSocialEdit(true)
                return
            }

            if(
                comapanyDataUpdate                                   &&
                comapanyDataUpdate.phone                !== undefined &&
                comapanyDataUpdate.phone                !== ""
            )  {
                setLoadTelefoneEdit(false)
            }else {
                setLoadTelefoneEdit(true)
                return
            }

            if(
                comapanyDataUpdate                                   &&
                comapanyDataUpdate.cnpj               !== undefined &&
                comapanyDataUpdate.cnpj                !== ""
            )  {

                setLoadCnpjEdit(false)
            }else {
                setLoadCnpjEdit(true)
                return
            }

            if(
                comapanyDataUpdate                                   &&
                comapanyDataUpdate.email               !== undefined &&
                comapanyDataUpdate.email               !== ""
            )  {
                setLoadEmailEdit(false)
            }else {
                setLoadEmailEdit(true)
                return
            }

            if(
                comapanyDataUpdate                                   &&
                comapanyDataUpdate.zipcode             !== undefined &&
                comapanyDataUpdate.zipcode             !== ""
            )  {
                setLoadCEPEdit(false)
            }else {
                setLoadCEPEdit(true)
                return
            }


            let valuephone         = phoneNumbersOnly(comapanyDataUpdate.phone);
            let valuecnpj          = mCNPJ_ONLY_NUMBERS(comapanyDataUpdate.cnpj);

            setLoadButtonUpdate(true)
            await api.put(`/company-admin/${idCompany}`, {
                'name': comapanyDataUpdate.name,
                 'cnpj': valuecnpj,
                 'phone': valuephone,
                 'email': comapanyDataUpdate.email,
                 'zipcode': comapanyDataUpdate.zipcode,
                 'nameDescription': comapanyDataUpdate.nameDescription,
                 'addressNumber': comapanyDataUpdate.addressNumber,
                 'address': comapanyDataUpdate.address,
                 'addressNeighborhood': comapanyDataUpdate.addressNeighborhood,
                 'addressState': comapanyDataUpdate.addressState,
                 'addressCity': comapanyDataUpdate.addressCity,
                 'addressComplement': comapanyDataUpdate.addressComplement,
                 'haveChange': comapanyDataUpdate.haveChange
                }
            )
            .then(response => {
                setLoadButtonUpdate(false)

                setLoadPageCompanyData(true)
                async function loadcompany() {

                    await api.get('/company-admin/')
                    .then(response => {

                        setLoadPageCompanyData(false)
                        setComapanyDataUpdate(response.data);
                    }).catch(error=> {})
                }
                loadcompany();

                swal({ icon: "success", title: "Sucesso!", text: "Empresa salva com sucesso." });
            }).catch(error => {
                setLoadButtonUpdate(false)
                console.log(error.response.data)
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });



    }



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadLogoCompany, setLoadLogoCompany ] = useState(false);

    async function handleDropAvatar(acceptedFiles) {

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        let file      = acceptedFiles[0];
        let idCompany = comapanyDataUpdate._id;
        formData.append('file', file);

        setLoadLogoCompany(true)
        await api.put(`/company-admin/${idCompany}`, formData, config)
        .then(response => {
            setLoadLogoCompany(false)

            setLoadPageCompanyData(true)
            async function loadcompany() {

                await api.get('/company-admin/')
                .then(response => {
                    // console.log(response.data)

                    setLoadPageCompanyData(false)
                    // setComapanyDataUpdate([]);
                    setComapanyDataUpdate(response.data);
                }).catch(error=> {})
            }
            loadcompany();

            // swal({ icon: "success", title: "Sucesso!", text: "Logo da empresa salva com sucesso." });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao editar a logo da empresa, tente novamente mais tarde." });
        });


    }

    async function deleteAvatar() {

        let idCompany = comapanyDataUpdate._id;
        let logo      = 'undefined';
        let logoKey   = 'undefined';

        setLoadLogoCompany(true)
        await api.put(`/company-admin/${idCompany}`, { logo, logoKey })
        .then(response => {
            setLoadLogoCompany(false)

            setLoadPageCompanyData(true)
            async function loadcompany() {

                await api.get('/company-admin/')
                .then(response => {

                    setLoadPageCompanyData(false)
                    // setComapanyDataUpdate([]);
                    setComapanyDataUpdate(response.data);
                }).catch(error=> {})
            }
            loadcompany();

            // swal({ icon: "success", title: "Sucesso!", text: "Logo da empresa removida com sucesso." });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao remover a logo da empresa da empresa, tente novamente mais tarde." });
        });

    }
    // console.log(comapanyDataUpdate)
    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-dados-empresa">
                    <h2 className="titulo">Dados da empresa</h2>
                    <ul>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Logo</p></div>
                                <div className="foto">
                                {
                                    loadLogoCompany ?
                                    <img src={CarregandoFoto} alt="carregando" className="carregamento-foto" />
                                    :
                                    comapanyDataUpdate &&
                                    comapanyDataUpdate.logo    !== undefined   &&
                                    comapanyDataUpdate.logo    !== 'undefined' &&
                                    comapanyDataUpdate.logoKey !== undefined   &&
                                    comapanyDataUpdate.logoKey !== 'undefined'    ?
                                    <>
                                    <Dropzone onDrop={handleDropAvatar} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "upload-foto" })}>
                                            {/* <img src={collaborator.avatar} alt={collaborator.name} className="img-adicionada"/> */}
                                            <label className="label-foto-adicionado" ><img alt="+" src={IconCamera} className="icon"/><img alt={comapanyDataUpdate.name}  src={comapanyDataUpdate.logo} className="foto"/></label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                        </div>
                                        )}
                                    </Dropzone>
                                    <div className="remover-foto">
                                        <button onClick={deleteAvatar}>Remover foto</button>
                                    </div>
                                    </>
                                    :
                                    <Dropzone onDrop={handleDropAvatar} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "upload-foto" })}>
                                                        <label className="labelIconPhoto"><div  className="iconPhoto"> <span></span> Enviar foto</div> </label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                        </div>
                                        )}
                                    </Dropzone>
                                }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Razão social *</p></div>
                                <div>
                                    <input type="text" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.name !== undefined ?
                                        comapanyDataUpdate.name
                                        :
                                        ''
                                    } name="name" onChange={myChangeHandlerCompany} placeholder="Razão social"  className={loadRazaoSocialEdit ? 'inputErro' : ''}/>
                                    {loadRazaoSocialEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Descrição</p></div>
                                <div>
                                    <input type="text" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.nameDescription !== undefined ?
                                        comapanyDataUpdate.nameDescription
                                        :
                                        ''
                                    } name="nameDescription" onChange={myChangeHandlerCompany} placeholder="Descrição" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Telefone</p></div>
                                <div>
                                    <input type="text" name="phone" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.phone !== undefined ?
                                        phones(comapanyDataUpdate.phone)
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Telefone"  className={loadTelefoneEdit ? 'inputErro' : ''}/>
                                    {loadTelefoneEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>CNPJ</p></div>
                                <div>
                                    <input type="text" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.cnpj !== undefined ?
                                        mCNPJ(comapanyDataUpdate.cnpj)
                                        :
                                        ''
                                    } name="cnpj" onChange={myChangeHandlerCompany} placeholder="CNPJ" className={loadCnpjEdit ? 'inputErro' : ''}/>
                                    {loadCnpjEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Email</p></div>
                                <div>
                                    <input type="text" name="email" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.email !== undefined ?
                                        comapanyDataUpdate.email
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Email"  className={loadEmailEdit ? 'inputErro' : ''}/>
                                    {loadEmailEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <h3 className="sub-titulo">Endereço</h3>
                    <ul>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>CEP</p></div>
                                <div>
                                <input type="text" name="zipcode" value={
                                    comapanyDataUpdate &&
                                    comapanyDataUpdate.zipcode !== undefined ?
                                    comapanyDataUpdate.zipcode
                                    :
                                    ''
                                } onChange={myChangeHandlerCompany} placeholder="CEP"  className={loadCEPEdit ? 'inputErro' : ''}/>
                                    {loadCEPEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className="texto"><p>Número</p></div>
                                <div>
                                    <input type="text" name="addressNumber" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.addressNumber !== undefined ?
                                        comapanyDataUpdate.addressNumber
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Número"  className={loadNumeroEdit ? 'inputErro' : ''}/>
                                    {loadNumeroEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Rua ou avenida</p></div>
                                <div>
                                    <input type="text" name="address" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.address !== undefined ?
                                        comapanyDataUpdate.address
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Rua ou avenida"  className={loadRuaAvenidaEdit ? 'inputErro' : ''}/>
                                    {loadRuaAvenidaEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>

                            <div className='bloco'>
                                <div className="texto"><p>Bairro</p></div>
                                <div>
                                    <input type="text" name="addressNeighborhood" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.addressNeighborhood !== undefined ?
                                        comapanyDataUpdate.addressNeighborhood
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Bairro"  className={loadBairroEdit ? 'inputErro' : ''}/>
                                    {loadBairroEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Estado</p></div>
                                <div>
                                    <input type="text" name="addressState" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.addressState !== undefined ?
                                        comapanyDataUpdate.addressState
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Estado"  className={loadEstadoEdit ? 'inputErro' : ''}/>
                                    {loadEstadoEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>

                            <div className='bloco'>
                                <div className="texto"><p>Cidade</p></div>
                                <div>
                                    <input type="text" name="addressCity" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.addressCity !== undefined ?
                                        comapanyDataUpdate.addressCity
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Cidade"  className={loadCidadeEdit ? 'inputErro' : ''}/>
                                    {loadCidadeEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Complemento</p></div>
                                <div>
                                    <input type="text" name="addressComplement" value={
                                        comapanyDataUpdate &&
                                        comapanyDataUpdate.addressComplement !== undefined ?
                                        comapanyDataUpdate.addressComplement
                                        :
                                        ''
                                    } onChange={myChangeHandlerCompany} placeholder="Complemento"  className={loadComplementoEdit ? 'inputErro' : ''}/>
                                    {loadComplementoEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="configSystem-btn-add-empresa">
                    <button onClick={updateCompany} className={loadButtonUpdate ? "btnGreen cadastrar load" : "btnGreen cadastrar"}>Salvar</button>
                </div>
            </Layout>
        </>
    );
}
