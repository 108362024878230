import React, { useState, useEffect } from 'react';

import Carregando from '../../../../assets/images/carregando.gif';
import Dropzone from "react-dropzone";
// import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import api from '../../../../services/api';



const tituloEleitor = ({
    id,
    token,
    onCollaboratorInputChange,
    collaborator,
    collaboratorUpdate,
    loadvoterRegistration ,
    loadelectoralZone     ,
    loadpollingStation    ,
    focusvoterRegistration,
    focuselectoralZone    ,
    focuspollingStation,
    path
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSaved, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id){async function loadfilessaved() {
            setLoad(true)
            //console.log(id)
            await api.get(`/collaborator-file-new/?id=${id}&category=docTE`)
            .then(response => {
                setLoad(false)
                //console.log(response.data[0])
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                setLoad(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();}
    }, [id]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {


            setFileNames(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docTE');
            formData.append('collaborator', id);
            formData.append('token', token);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file-token", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file-new/?id=${id}&category=docTE`)
                    .then(response => {
                        setLoad(false)
                        setFilesSaved(response.data);
                    }).catch(error=>{
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                //setTokenError(error.response.data);
                console.log(error.response.data)
                setLoad(false)
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    //const deleteFile = async (filesaved) => {
    async function deleteFile(filesaved) {
        //console.log(filesaved)
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoad(true)
                await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file-new/?id=${id}&category=docTE`)
                        .then(response => {
                            setFilesSaved(response.data);
                        }).catch(error=>{
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

    return (
            <>

                <div className="box-formulario titulo-eleitor">
                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <h5>Título de Eleitor</h5>
                            </div>
                        </div>
                    </div>
                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoUm">Número do título *</p>
                            </div>
                            <div className='intemB'>

                                <input
                                    ref={focusvoterRegistration}
                                    type="text"
                                    name="voterRegistration"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.voterRegistration)
                                        ||
                                        (collaboratorUpdate.voterRegistration === "")
                                        ?
                                        collaboratorUpdate.voterRegistration
                                        :
                                        (collaborator && collaborator.voterRegistration)
                                        ||
                                        (collaborator.voterRegistration === "")
                                        ?
                                        collaborator.voterRegistration
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="Número do título"
                                    className={loadvoterRegistration ? 'inputUm inputErro' : 'inputUm'}
                                />
                                {
                                loadvoterRegistration ?
                                <p className="textError inputUm">O campo é obrigatório.</p>
                                :
                                ('')
                                }

                            </div>
                        </div>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoDois">Zona eleitoral *</p>
                            </div>
                            <div className='intemB'>

                                <input
                                    ref={focuselectoralZone}
                                    type="text"
                                    name="electoralZone"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.electoralZone)
                                        ||
                                        (collaboratorUpdate.electoralZone === "")
                                        ?
                                        collaboratorUpdate.electoralZone
                                        :
                                        (collaborator && collaborator.electoralZone)
                                        ||
                                        (collaborator.electoralZone === "")
                                        ?
                                        collaborator.electoralZone
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="Zona eleitoral"
                                    className={loadelectoralZone ? 'inputDois inputErro' : 'inputDois'}
                                />
                                {
                                loadelectoralZone ?
                                <p className="textError inputDois">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                    </div>

                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoTreis">Seção eleitoral *</p>
                            </div>
                            <div className='intemB'>

                                    <input
                                    ref={focuspollingStation}
                                    type="text"
                                    name="pollingStation"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.pollingStation)
                                        ||
                                        (collaboratorUpdate.pollingStation === "")
                                        ?
                                        collaboratorUpdate.pollingStation
                                        :
                                        (collaborator && collaborator.pollingStation)
                                        ||
                                        (collaborator.pollingStation === "")
                                        ?
                                        collaborator.pollingStation
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="Seção eleitoral"
                                    className={loadpollingStation ? 'inputTreis inputErro' : 'inputTreis'}
                                />
                                {
                                loadpollingStation ?
                                <p className="textError inputTreis">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>

                    </div>

                       <div className='titulo-eleitor-dropzone'>


                            <div className="dropzone">
                                <Dropzone className="" onDrop={handleDrop}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="textoOnze">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </div>


                            <div className="arquivoAnexado textoDoze">
                            {filesSaved ?
                            filesSaved.map((filesaved, index) => (
                                <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        <a href={filesaved.location} target="’_blank’">
                                        {filesaved.originalname}
                                        </a>
                                    </p>
                                    <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                                </div>
                                )) : ''}
                            </div>
                            {load ?
                                (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                                ('')}
                       </div>

                </div>
            </>
        );

}
export default tituloEleitor;
