import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom'

// import iconChat from '../../../../assets/images/chat.svg';
// import iconNotifications from '../../../../assets/images/notifications.svg';
// import iconUser from '../../../../assets/images/user.svg';
import HeaderAdmin from '../../../../components/HeaderAdmin';
// import arrowLeft from '../../../../assets/images/arrow-left.svg';
import { formatDateString } from '../../../../services/utils';
// import IconsInformation from '../../../IconsInformation';
import api from '../../../../services/api';

export default function headerColProfile({
    id,
}) {

   // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {

       loadcollaborator(id);

   }, [id]);

   async function loadcollaborator(id) {

        focusTop.current.focus();
        await api.get(`/collaborator/${id}`)
        .then(response => {

            localStorage.setItem('@peopleview/nameProfile', response.data.name);
            localStorage.setItem('@peopleview/updatedAt', response.data.updatedAt);

        }).catch(error => {})

   }

    const nameprofile = localStorage.getItem('@peopleview/nameProfile');
    const updatedAt   = localStorage.getItem('@peopleview/updatedAt');


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusTop   = useRef(null);




    return (
        <>
        <HeaderAdmin>
            <div>
                <h1>
                    <NavLink ref={focusTop} to={localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? "/colaboradores/lista-colaboradores-desligado" : "/colaboradores/ativos" } className="voltar"> Voltar</NavLink>
                        {nameprofile}
                </h1>
                <p className="infor-atualizacao">Atualizado pela última vez em {formatDateString(updatedAt)}</p>

            </div>
        </HeaderAdmin>
            {/* <header className="header-geral header-perfil">
                <div className="cabeçalho">
                    <h1>
                        <NavLink ref={focusTop} to={localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? "/colaboradores/lista-colaboradores-desligado" : "/colaboradores/ativos" }><span><img src={arrowLeft} alt="Chat" className="chat"/></span> Voltar</NavLink>
                            {nameprofile}
                    </h1>
                    <p className="infor-atualizacao">Atualizado pela última vez em {formatDateString(updatedAt)}</p>
                </div>
                <div className="infor" >
                    <div>
                        {/* <form action="">
                            <input type="text" placeholder={`Pesquise no perfil de ${nameprofile}`} className="busca"/>
                        </form> */}
                    {/* </div>
                   <IconsInformation />
                </div>

            </header> */} 
        </>
    );
}

