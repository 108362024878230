import React from 'react';
export default function pageFreeTrialStapOne({
    etapaUmConcluida,
    SaveTrial,
    loadNome,
    loadEmail,
    loadPhone,
    loadNomeEmpresa,
    loadCnpj,
    loadCpf,
    myChangeHandler,
    freeTrialAdd,
    inforCompanyCol,
    nameTem,
    emailTem,
    phoneTem,
    cnpjTem,
    cpfTem,
    loadButton
}) {

    return (
        <>
        <ul className={etapaUmConcluida ? 'etapa-um' : ''}>
                <li>
                    <div className="intemA">
                        <p>Nome da empresa *</p>
                    </div>
                    <div className="intemB">
                        <input type="text" name="nameCompany"  placeholder="Nome da empresa"  className={loadNomeEmpresa ? 'inputErro' : ''} onChange={myChangeHandler}
                        value={
                            freeTrialAdd &&
                            freeTrialAdd.nameCompany !== undefined ?
                            freeTrialAdd.nameCompany
                            :
                            ''
                        }
                        />
                        {loadNomeEmpresa ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Nome completo *</p>
                    </div>
                    <div className="intemB">
                        <input type="text" name="nameCollaborator" placeholder="Nome"  className={loadNome ? 'inputErro' : ''} onChange={myChangeHandler}
                        value={
                            freeTrialAdd &&
                            freeTrialAdd.nameCollaborator !== undefined ?
                            freeTrialAdd.nameCollaborator
                            :
                            ''
                        }
                        />
                        {loadNome ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Email *</p>
                    </div>
                    <div className="intemB">
                        <input type="email" name="email" placeholder="Email"  className={loadEmail ? 'inputErro' : ''}onChange={myChangeHandler}
                        value={
                            freeTrialAdd &&
                            freeTrialAdd.email !== undefined ?
                            freeTrialAdd.email
                            :
                            ''
                        }
                        />
                        {loadEmail ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Telefone *</p>
                    </div>
                    <div className="intemB">
                        <input
                        type="text"
                        name="phone"
                        placeholder="Telefone"
                        className={loadPhone ? 'inputErro' : ''}
                        onChange={myChangeHandler}
                        value={
                            freeTrialAdd &&
                            freeTrialAdd.phone !== undefined ?
                            freeTrialAdd.phone
                            :
                            ''
                        }
                        />
                        {loadPhone ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                    </div>
                </li>
                <button onClick={SaveTrial} className={loadButton ? "btnGreen load" : "btnGreen"}>Continuar</button>
            </ul>
        </>
    );
}
