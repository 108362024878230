import React, { useState, useEffect } from 'react';
import "./styles.scss";
import Layout from '../../../../components/Layouts/default';
import HeaderNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/header';
import HeaderEtapasNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/headerAdmissionSteps';
import Success from '../../../../assets/images/success.svg';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

export default function Finishing(props) {
    const { id }      = props.match.params;
    const [stepOne]   = useState(true)
    const [stepTwo]   = useState(true)
    const [stepThree] = useState(true)
    const [stepFour]  = useState(true)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id !== undefined) {
            loadcollaborator(id)
        }
    }, [id]);

    async function loadcollaborator(id) {
        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    localStorage.removeItem('@peopleview/countCollaborators');
    localStorage.removeItem('@peopleview/coutAdmission');
    localStorage.removeItem('@peopleview/shutdowProcess');
    return (
        <>
            <Layout
            path={props.match.path}
            >
                <HeaderNovaAdmissao
                    collaborator={collaborator}
                    setCollaborator={setCollaborator}
                />
                <HeaderEtapasNovaAdmissao
                    id={id}
                    stepOne={stepOne}
                    stepTwo={stepTwo}
                    stepThree={stepThree}
                    stepFour={stepFour}
                    path={props.match.path}
                    collaboratorInfor={collaborator}
                />

                <div className="box-finalizado">
                    <img src={Success} alt="Success" className="icone" />
                    <h2>Colaborador concluído com sucesso!</h2>
                    <p>Foi enviado um email com os dados de acesso para o colaborador. Se não houver um email de acesso cadastrado para esse colaborador, você deve ir no cadastro do colaborador, na área “Dados de acesso” e informar ao colaborador que ele pode se conectar ao sistema utilizando o CPF e a senha temporária cadastrada.</p>
                </div>
            </Layout>

        </>
    );
}

