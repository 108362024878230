import React from 'react';
import './styles.scss';

import logo from '../../assets/images/logo.svg';

const ExpiredToken = () => {
    return (
        <>
            <div className="box-error token-expirado">

                <img src={logo} alt="People View" className="logo"/>
                <h1>Token Inválido.</h1>
                <span>© 2021 People View</span>
            </div>

        </>
    );
}
export default ExpiredToken;
