import React, { useState, useEffect } from 'react';

import Layout from '../../../../components/Layouts/default';
import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import Benefits from '../../../../components/Administrativo/Colaboradores/profile/Professional/Benefits';
import { decodeToken } from '../../../../services/auth';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import './styles.scss';
import swal from 'sweetalert';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

export default function ColProfilePersonal(props) {

    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;

    const { history } = props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBD, setCollaboratorBD ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
       if(id && id){ loadcollaborator(id);}
    }, [id]);

    async function loadcollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {

            setCollaboratorBD(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            //formData.append('collaborator', id);
            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {
                setLoadAvatar(false)
                loadcollaborator(id);
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                id={id}
                collaborator={collaboratorBD}
                history={history}
                path={props.match.path}
                />
                <InforProfile
                collaborator={collaboratorBD}
                id={id}
                loadAvatar={loadAvatar}
                handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                path={props.match.path}
                role={role}
                loadcollaborator={loadcollaborator}
                />
                <SubHeader
                id={id}
                path={props.match.path}
                collaborator={collaboratorBD}
                role={role}
                />
                <Benefits
                id={id}
                path={props.match.path}
                collaborator={collaboratorBD}
                />
            </Layout>
        </>
    );
}
