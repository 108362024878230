import React, { useState } from 'react';
// import IconsInformation from '../../IconsInformation';
import Close from '../../../assets/images/close.svg';
import { NavLink } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import Carregando from '../../../assets/images/carregando.gif';
import HeaderAdmin from '../../HeaderAdmin';
import { Scrollbars } from 'react-custom-scrollbars';
import IconADD from '../../../assets/images/add-branco.svg'
import { dateFormatedToStripe } from '../../../services/utils';

export default function headerFerias({
    role,
    loadCollectiveVacations,
    collectiveVacations
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    function listCollectiveVacation() {
        setVisible(!visible);
    }

    function closeListCollectiveVacation() {
        setVisible(!visible);
    }

    async function registrNew() {
        setTimeout(function(){window.location.href = '/feriascoletivas/cadastrar'}, 0);
    }
    return (
        <>

        <HeaderAdmin className="header-ferias">
            <div>
                <h1>Férias</h1>
                <p>Acompanhe e gerencie as férias dos colaboradores</p>
            </div>
            <div className='ferias-dash'>
                {
                    role && role === 'admin' ?
                        <>
                            <NavLink to="/feriascoletivas/cadastrar" exact activeClassName="active" className="btnGreen more cadastrar-ferias">
                            Cadastrar férias coletivas
                            </NavLink>
                        </>
                    :
                    ''
                }

                <button className="ver-ferias-coletivas"  onClick={() => listCollectiveVacation()} >
                    Ver férias coletivas
                </button>

            </div>

        <NavLink to="/feriascoletivas/cadastrar" exact activeClassName="active" className="cadastrar-ferias-mb">
            <img src={IconADD} alt="" />
        </NavLink>
        </HeaderAdmin>

            <Modal show={visible} className="popup-geral popup-ferias-coletiva" onHide={closeListCollectiveVacation}>
                <div className="titulo">
                    <h4>Férias coletivas  <p className="btn-fechar" onClick={closeListCollectiveVacation}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                {
                    collectiveVacations &&
                    collectiveVacations.length !== 0 ?
                    <>
                    </>
                    :
                    <>
                    <div className="ferias-coletiva-vazia"  >
                        <p>
                        Nenhuma férias<br/>
                        coletiva cadastrada.
                        </p>
                        {/* <div className="btns">
                            <button className="btnGray" onClick={closeListCollectiveVacation}>Fechar</button>
                            <button onClick={registrNew} className="btnGreen more">Cadastrar nova</button>
                        </div> */}
                    </div>

                    </>
                }

                        <div className="lista-ferias-coletiva">
                <Scrollbars   style={{  height:
                                    collectiveVacations.length === 0 ? 30 : "" ||
                                    collectiveVacations.length === 1 ? 70 : ""  ||
                                    collectiveVacations.length === 2 ? 155 : ""  ||
                                    collectiveVacations.length === 3 ? 235 : 235
                                    }}>

                            {
                                loadCollectiveVacations ? (
                                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                )
                                :
                                collectiveVacations &&
                                collectiveVacations.length !== 0 &&
                                collectiveVacations
                                .map((collective, index) => (

                                    <div className="box-ferias-coletiva" key={index}>
                                        {/* a a a a a */}
                                        <h4 className="titulo-box">{collective.nameVacation}</h4>
                                        <div className="infor">
                                            <p className="data">De {dateFormatedToStripe(collective.startDate)} à {dateFormatedToStripe(collective.endDate)} ({collective.balance} dias)</p>
                                            <div className='grupo-editar'>
                                                {
                                                    collective?.nameSector ?
                                                    <p className="grupo">Departamento: {collective.nameSector}</p>
                                                    :
                                                    ''
                                                }
                                                {/* {
                                                    role && role === 'admin' ?
                                                    <NavLink to={`/feriascoletivas/cadastrar/${collective._id}`} className="editar">
                                                        EDITAR
                                                    </NavLink>
                                                    :
                                                    ''
                                                } */}

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            </Scrollbars>
                        </div>
                    <div className="btns">
                        <button className="btnGray" onClick={closeListCollectiveVacation}>Fechar</button>
                        {
                            role && role === 'admin' ?
                            <button onClick={registrNew} className="btnGreen more">Cadastrar nova</button>
                            :
                            ''
                        }
                    </div>
            </Modal>
        </>
    );
}
