import React from 'react';

// import { NavLink } from 'react-router-dom'
import iconOrdenation from '../../../assets/images/ordenation.svg';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';
// import NenhumaHabilidade from '../../../assets/images/illustration-nenhum-habilidade.svg';
import Close from '../../../assets/images/close.svg';
// import ImgExemplo from '../../../assets/images/emer.jpg';
import Carregamento from '../../../assets/images/carregando.gif';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import IconArrowVazioVerde from '../../../assets/images/arrow-vazio-verde.svg';
import { Modal } from "react-bootstrap";

export default function headerHabilidadesAllSkill({
    loadPageAllSkill,
    allSkill,
    pages,
    pageAllSkill,
    totalOfTheAllSkill,
    CastrarHabilidade,
    prevPageAllSkill,
    nextPageAllSkill,
    paginationPageAllSkill,
    showCollaboratorsavedInTheSkill,
    VerHabilidade,
    EditarHabilidade,
    visibleEditarHabilidade,
    NaoEditarHabilidade,
    showSkill,
    loadNameEditHabilidade,
    myChangeHandlerExcludeCollaboratorAddedEditInTheSkill,
    visibleVerHabilidade,
    NaoVerHabilidade,
    showCollaboratorInTheSkill,
    loadFilterCollaboratorForThisSkill,
    name,
    collaboratorFilterForThisSkill,
    myChangeHandlerToAddCollaboratorEditSkill,
    myChangeHandlerToEditSkill,
    updateSkill,
    loadUpdateSkill,
    deleteSkill,
    filterSkills,
    handleKeyPressEditarHabilidade,
    loadShowCollaboratorInTheSkill
}) {
    let numberPaginate = []
    for(let i=1; i<=pages; i++){
        numberPaginate.push(
            <li className="number">
                <button className={i === pageAllSkill ? "secao" : ""} onClick={(event) => paginationPageAllSkill(i)}>{i}</button>
            </li>
        )
    }
    return (
        <>
            <div className="todas-habilidades">
                 {
                    loadPageAllSkill ?""
                    :
                     allSkill && allSkill.docs ?
                     ""
                     :
                     allSkill && allSkill.length === 0 ?


                     <div className="nenhuma-habilidade">
                            <p>Nenhuma habilidade cadastrado.</p>
                            <button className="btnGreen more btn-cadastrar-h"  onClick={() => CastrarHabilidade()} >Cadastrar nova habilidade</button>
                    </div>
                     :
                     <>
                        <thead>
                            <tr>
                                <th className="nome">
                                    <p>Habilidade</p>
                                </th>
                                <th  className='colaboraderes'>
                                    <p>Colaboradores com esta habilidade</p>
                                </th>
                                <th className="th-dopdown-vermais">
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconOrdenation} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button type="button" onClick={() => filterSkills('moreCommon')} name="moreCommon" value="moreCommon">
                                                Mais comum primeiro
                                            </button>
                                            <button type="button" onClick={() => filterSkills('lessCommon')}>
                                                Menos comum primeiro
                                            </button>
                                            <button type="button" onClick={() => filterSkills('alphabeticaOrder')}>
                                                Ordem alfabética
                                            </button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </th>
                            </tr>
                        </thead>
                     </>
                 }

                <tbody>
                    {
                        loadPageAllSkill ?
                        <div className="carregamento-habilidade">
                            <img alt="" src={Carregamento} />
                        </div>
                        :
                       allSkill && allSkill.docs && allSkill.docs.length === 0 ?
                        (

                               <div className="nenhuma-habilidade">
                                   <p>Nenhuma habilidade cadastrado.</p>
                                    <button className="btnGreen more btn-cadastrar-h"  onClick={() => CastrarHabilidade()} >Cadastrar nova habilidade</button>

                               </div>

                        )
                        :
                        allSkill[0] ? allSkill
                        .map((skillall, index) => (
                            <tr index={index}>
                                <td className="nome"  onClick={() => VerHabilidade(skillall)}>{skillall && skillall.nameSkill ? skillall.nameSkill : '-'}</td>
                                <td  className='colaboraderes'  onClick={() => VerHabilidade(skillall)}>{skillall && skillall.totalskill ? skillall.totalskill : '0'}</td>
                                <td className="td-dopdown-acoes">
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button type="button" name="Visualizer-habilidade"  onClick={() => VerHabilidade(skillall)}>
                                                Ver habilidade
                                            </button>
                                            <button type="button" name="editar-habilidade"  onClick={() => EditarHabilidade(skillall)}>
                                                Editar habilidade
                                            </button>
                                            <button onClick={() => deleteSkill(skillall.skill)} type="button" name="Exclui-habilidade" className="red">
                                                Excluir
                                            </button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                        :
                        ('')
                    }
                    <tr className="paginacaoG">
                            <ul>
                                {
                                    pages > 1 ?
                                    numberPaginate
                                    :
                                    ''
                                }
                                {
                                    pages > 1 ?
                                    <>
                                        <li className="page-anterior">
                                        <button style={
                                            (pageAllSkill === 1) ? { cursor: "not-allowed" } : {}}  disabled={pageAllSkill === 1} onClick={(event) => prevPageAllSkill(event)
                                        }>anterior</button>
                                        </li>
                                        <li className="page-proxima">
                                        <button style={(pageAllSkill === pages) ? { cursor: "not-allowed" } : {}}  disabled={pageAllSkill === pages} onClick={(event) => nextPageAllSkill(event)} >próxima</button>
                                        </li>
                                    </>
                                    :
                                    ''
                                }

                            </ul>
                    </tr>
                </tbody>
            </div>

            <Modal show={visibleEditarHabilidade} restoreFocus={false} className="popup-geral popup-criar-habilidade" onHide={NaoEditarHabilidade}>
                <div>
                    <div className="titulo">
                        <h4>Editar habilidade <p className="btn-fechar" onClick={NaoEditarHabilidade}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="form-nova-habilidade">
                        <input type="text" 
                        onChange={myChangeHandlerToEditSkill} 
                        name="name" 
                        defaultValue={showSkill && showSkill.nameSkill !== undefined ? showSkill.nameSkill : '-'} 
                        placeholder="Nome da habilidade" className={loadNameEditHabilidade ? 'inputErro' : ''}
                        onKeyPress={(event) => handleKeyPressEditarHabilidade(event)} 
                        id="nameEditarHabilidade"
                        />
                        {loadNameEditHabilidade ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        <input name="name" value={name} onChange={loadFilterCollaboratorForThisSkill} placeholder="Colaboradores com esta habilidade"  className="busca"/>

                        {/* //////// ESTADO VAZIO DE BUSCA DE COLABORADORES  ///////*/}
                        {
                            loadShowCollaboratorInTheSkill ?
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                         :
                            <div className="vazio-busca" style={
                                
                                    (showCollaboratorsavedInTheSkill &&
                                    showCollaboratorsavedInTheSkill.length === 0)
                                    &&
                                    (collaboratorFilterForThisSkill && collaboratorFilterForThisSkill.length === 0) ?
                                    {display:'block'}
                                    :
                                    {display:'none'}
                                }>
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira Calaboradores</span>
                            </div>
                        }

                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE COLABORADORES  ///////*/}
                        <div className="resultado-busca-atrelados-col" style={collaboratorFilterForThisSkill && collaboratorFilterForThisSkill.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                collaboratorFilterForThisSkill && collaboratorFilterForThisSkill.length !== 0 ?
                                collaboratorFilterForThisSkill.map((collaboratorFilter, index) => (
                                    <button onClick={myChangeHandlerToAddCollaboratorEditSkill} id={collaboratorFilter._id} key={index}>
                                        {/* {collaboratorFilter.avatar} */}
                                    {
                                        collaboratorFilter && collaboratorFilter.avatar !== undefined && collaboratorFilter.avatar !== "undefined" ?
                                        <>
                                        <div className="img-col">
                                            <img id={collaboratorFilter._id} src={collaboratorFilter.avatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img id={collaboratorFilter._id} src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <span id={collaboratorFilter._id}>{collaboratorFilter.name}</span>
                                    </button>
                                )):''
                            }
                        </div>

                        <div className="lista-atrelados-col"  >
                            {
                                loadShowCollaboratorInTheSkill ?""
                                :
                                showCollaboratorsavedInTheSkill && showCollaboratorsavedInTheSkill.length !== 0 ?
                                showCollaboratorsavedInTheSkill.map((collaboratorintheskill, index)=> (
                                    <div className="infor-col">
                                        {
                                            collaboratorintheskill && collaboratorintheskill.avatar !== undefined  && collaboratorintheskill.avatar !== "undefined" ?
                                            <>
                                            <div className="img-col">
                                                <img src={collaboratorintheskill.avatar} alt={collaboratorintheskill.name} />
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt={collaboratorintheskill.name} />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <div className="nome-setor">
                                            <p className="nome">
                                                {collaboratorintheskill && collaboratorintheskill.name !== undefined ? collaboratorintheskill.name : ''}
                                            </p>
                                            <div className="setor">
                                                <p>{collaboratorintheskill && collaboratorintheskill.occupation !== undefined ? collaboratorintheskill.occupation : ''}</p>
                                            </div>
                                        </div>
                                        <button onClick={myChangeHandlerExcludeCollaboratorAddedEditInTheSkill} value={collaboratorintheskill._id} className="remover">Remover</button>
                                    </div>
                                )):''
                            }


                        </div>
                        {/* //////// COLABORADORES ADICIONADOS ADICIONADOS  FIM ///////*/}
                    </div>
                    <button onClick={updateSkill} className={loadUpdateSkill ? "button-salve btnGreen load" : "button-salve btnGreen"}>Salvar</button>
                </div>
            </Modal>

            <Modal show={visibleVerHabilidade} className="popup-geral popup-ver-habilidade" onHide={NaoVerHabilidade}>
                <div>
                    <div className="titulo">
                        <h4>Habilidade {showSkill && showSkill.nameSkill !== undefined ? showSkill.nameSkill : '-'} <p className="btn-fechar" onClick={NaoVerHabilidade}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="infor-habilidade">
                        <div className="quantidade">
                            <p>{showSkill && showSkill.totalskill !== undefined ? showSkill.totalskill : '-'}</p>
                            Colaboradores
                        </div>
                        <span className="data">
                            Criado em {showSkill && showSkill.createdFormated !== undefined ? showSkill.createdFormated : '-'}
                        </span>
                    </div>
                    <div className="detalhes">
                        <div className="lista-atrelados-col"  >
                            {loadShowCollaboratorInTheSkill ?
                            
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            :
                                showCollaboratorInTheSkill && showCollaboratorInTheSkill.length !== 0 ?
                                showCollaboratorInTheSkill.map((collaboratorintheskill, index)=> (
                                    <div key={index} className="infor-col">
                                        {
                                            collaboratorintheskill                                  &&
                                            collaboratorintheskill.collaborator                     &&
                                            collaboratorintheskill.collaborator        !== null     &&
                                            collaboratorintheskill.collaborator.avatar !== undefined &&
                                            collaboratorintheskill.collaborator.avatar !== "undefined" ?
                                            <>
                                            <div className="img-col">
                                                <img 
                                                    src={
                                                        collaboratorintheskill                                  &&
                                                        collaboratorintheskill.collaborator                     &&
                                                        collaboratorintheskill.collaborator        !== null     &&
                                                        collaboratorintheskill.collaborator.avatar
                                                    } 
                                                    
                                                    alt={
                                                        collaboratorintheskill                                  &&
                                                        collaboratorintheskill.collaborator                     &&
                                                        collaboratorintheskill.collaborator        !== null     &&
                                                        collaboratorintheskill.collaborator.name
                                                    } 
                                                />
                                            </div>
                                            </>
                                            :
                                            <div className="colSemFoto" >
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt={
                                                        collaboratorintheskill                                  &&
                                                        collaboratorintheskill.collaborator                     &&
                                                        collaboratorintheskill.collaborator        !== null     &&
                                                        collaboratorintheskill.collaborator.name
                                                    } />
                                                </div>
                                            </div>
                                        }
                                        <div className="nome-setor">
                                            <p className="nome">
                                                <span>
                                                    {
                                                    collaboratorintheskill                                  &&
                                                    collaboratorintheskill.collaborator                     &&
                                                    collaboratorintheskill.collaborator        !== null     &&
                                                    collaboratorintheskill.collaborator.name
                                                    }
                                                </span>
                                            </p>
                                            <div className="setor">
                                                <p>{
                                                collaboratorintheskill                                  &&
                                                collaboratorintheskill.collaborator                     &&
                                                collaboratorintheskill.collaborator        !== null     &&
                                                collaboratorintheskill.collaborator.occupation
                                                }</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                showCollaboratorInTheSkill.length === 0  ?
                                    <div className="vazio-busca" >
                                        <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                        <span>Insira Calaboradores</span>
                                    </div>
                                :""
                            }
                        </div>
                    </div>
                    <button className="btnGray fechar" onClick={NaoVerHabilidade}>Fechar</button>
                </div>
            </Modal>
        </>
    );
}

