import React, { useState, useEffect } from 'react';
import iconNotifications from '../../assets/images/notifications.svg';
// import iconUser from '../../assets/images/user.svg'
import { Dropdown } from "react-bootstrap";
// import ImgExemplo2 from '../../assets/images/carolina_martini.jpg';
import { NavLink } from 'react-router-dom'
// import ImgExemplo from '../../assets/images/peterson.png';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import { momentDuration } from '../../services/utils';
import api from '../../services/api';
import io from 'socket.io-client';

import { decodeToken } from '../../services/auth';
import swal from 'sweetalert';

export default function headerColaboradores() {


    //////////////////////// NOTIFICATIONS USER //////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadnotificationuser();
        loadnotificationusertotal();
        loadnotificationscollaboratorread();
        loadlimit();

        async function registerToSocketNotificationUser() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            socket.on("notificationUserRead", () => {

                loadnotificationscollaboratorread();
                if(localStorage.getItem('@peopleview/limit') > 4) {
                    showMore()
                }
                else {
                    loadnotificationuser();
                }
            });
        }
        registerToSocketNotificationUser()

    }, []);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationUser, setNotificationUser] = useState([]);

    async function loadnotificationuser() {

        await api.get('/notification-user/')
        .then(response => {

            setNotificationUser(response.data);

        }).catch(error => {})

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationUserTotal, setNotificationUserTotal] = useState([]);

    async function loadnotificationusertotal() {

        await api.get('/notification-user-total/')
        .then(response => {

            setNotificationUserTotal(response.data);

        }).catch(error => {})

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationsRead, setNotificationsRead ] = useState([]);

    async function loadnotificationscollaboratorread() {

        await api.get('/notification-collaborator-read')
        .then(response => {

            setNotificationsRead(response.data);

        }).catch(error => {});
    }



    async function readTrue(event) {


        await api.put(`/notification/${event}`, { read: true })
        .then(response => {

            loadnotificationuser();

        }).catch(error => {});

    }

    // console.log('notificationUserTotal')
    // console.log(notificationUserTotal)

    async function loadlimit() {

        localStorage.setItem('@peopleview/limit', 4);

    }



    async function showMore() {

        let limite = 4;

        const limitStorage = localStorage.getItem('@peopleview/limit');

        let limit = (parseInt(limitStorage) + parseInt(limite))

        localStorage.setItem('@peopleview/limit', limit);

        //alert('showMore')
        await api.get(`notification-user/?showmore=true&limite=${limit}`)
        // await api.get('notification/?showmore=true&limite=8')
        .then(response => {


            setNotificationUser(response.data);

        }).catch(error => {});


    }

    async function indexUpdate() {
        //  alert('/notification-update-all/')

        try{
            const response = await api.get('/notification-user-update-all/')
            if(response.data[0] !== undefined) {
                setNotificationUser(response.data);
            }
            await loadnotificationuser();
        }catch (error){
            console.log(error)
        }
    }



    async function logout() {
        localStorage.clear();
        setTimeout(function(){window.location.href = '/';}, 1000);
        //setTimeout(function(){history.push('/');}, 1000);
    };

      async function userViewToAdminView(event) {
        event.preventDefault();

        await api.get('/user-view-to-admin-view/')
        .then(response => {

            if(response.data.token !== undefined) {

                localStorage.setItem('@peopleview/token', response.data.token);

                const decodedUserView = decodeToken();

                // console.log(decodedUserView)
                if (decodedUserView.user.role === 'admin' || decodedUserView.user.role   === 'manager') {
                    // const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                    window.location.href = '/colaboradores/ativos';

                }

            }

        }).catch(error => {
            swal({ icon: "warning", title: "Alerta!", text: "Você não é um administrador!" });
        })

      }

      const decoded = decodeToken();
      var nome = decoded.user.name;
      var nome_1 = nome.split(' ')[0];

        return (


            <>

                    <div className="dropdown-notificacao">
                    <Dropdown className="dropdown-icon">
                    <Dropdown.Toggle>
                        <span className={
                            notificationsRead && notificationsRead.length !== 0 ? "bolinha-notificacao" : ''
                        }>
                        </span>
                        <img alt="Notificações" src={iconNotifications} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="header-notif">
                            <p>Notificações</p>
                            <button onClick={indexUpdate}>Marcar tudo como lido</button>
                        </div>
                        {/* Aparece apena 4 notificacao caso ele clique em ver mais carrega mais, esta fazendo escrool ja */}

                            {/* <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div> */}


                        <div className="lista-notif">

                           {
                               notificationUser &&
                               notificationUser.length !== 0 ?
                               notificationUser.map((notification, index) => (
                                        notification.sector &&
                                        notification.sector !== undefined ?
                                        <>
                                        <NavLink

                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/meus-dados/profissional" exact >
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <p className="add-evento">Seu setor mudou para:</p>
                                                    <span className="nome-evento">{
                                                        notification.sector &&
                                                        notification.sector !== undefined ?
                                                        notification.sector
                                                        :
                                                        ''
                                                    }</span>
                                                </div>
                                            </div>
                                        </NavLink>
                                        </>
                                        :
                                        notification.salary &&
                                        notification.salary !== undefined ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/meus-dados/profissional" exact >
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <p className="add-evento">Seu salário mudou para:</p>
                                                    <span className="nome-evento">{
                                                        notification.salary &&
                                                        notification.salary !== undefined ?
                                                        notification.salary
                                                        :
                                                        ''
                                                    }</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.adverts &&
                                        notification.adverts !== undefined ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/dashboard-user/" exact >
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator                              &&
                                                    notification.collaborator.companyId                    &&
                                                    notification.collaborator.companyId.logo               &&
                                                    notification.collaborator.companyId.logo !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.companyId.logo} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator                              &&
                                                            notification.collaborator.companyId                    &&
                                                            notification.collaborator.companyId.name               &&
                                                            notification.collaborator.companyId.name !== undefined ?
                                                            notification.collaborator.companyId.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <p className="add-evento">Recado oficial:</p>
                                                    <span className="nome-evento">{
                                                        notification.adverts &&
                                                        notification.adverts.announcementTitle &&
                                                        notification.adverts.announcementTitle !== undefined ?
                                                        notification.adverts.announcementTitle
                                                        :
                                                        ''
                                                    }</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.vacations &&
                                        notification.vacations.category &&
                                        notification.vacations.category === 'Férias Coletivas' ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/minhas-ferias" exact>
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator                              &&
                                                    notification.collaborator.companyId                    &&
                                                    notification.collaborator.companyId.logo               &&
                                                    notification.collaborator.companyId.logo !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.companyId.logo} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator                              &&
                                                            notification.collaborator.companyId                    &&
                                                            notification.collaborator.companyId.name               &&
                                                            notification.collaborator.companyId.name !== undefined ?
                                                            notification.collaborator.companyId.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <p className="add-evento">Adicionou:</p>
                                                    <span className="nome-evento">{
                                                        notification.vacations &&
                                                        notification.vacations.category &&
                                                        notification.vacations.category === 'Férias Coletivas' ?
                                                        notification.vacations.category
                                                        :
                                                        ''
                                                    }</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.feedback &&
                                        notification.feedback !== undefined ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/" exact >
                                            <div className="adicionou-ao-evento nao-visualizado">
                                                {
                                                    notification.feedback                                  &&
                                                    notification.feedback.responsible                      &&
                                                    notification.feedback.responsible.avatar               &&
                                                    notification.feedback.responsible.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.feedback.responsible.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.feedback                                &&
                                                            notification.feedback.responsible                    &&
                                                            notification.feedback.responsible.name               &&
                                                            notification.feedback.responsible.name !== undefined ?
                                                            notification.feedback.responsible.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <span className="add-evento">Adicionou um feedback</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.vacations                    &&
                                        notification.statusVacation               &&
                                        notification.statusVacation === 'Aprovado' ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/minhas-ferias" exact >
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <span className="nome-evento">Férias aprovadas</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.vacations                              &&
                                        notification.statusVacation               &&
                                        notification.statusVacation === 'Reprovado' ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/minhas-ferias" exact>
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <span className="nome-evento">Férias reprovadas</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.payroll                     &&
                                        notification.payroll.type                ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/meus-dados/profissional/folha-de-pagamento" exact>
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <span className="nome-evento">{notification.payroll.type} disponível</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.messagecommunication               &&
                                        notification.messagecommunication               &&
                                        notification.messagecommunication.read !== undefined ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)}
                                        key={index}
                                        to="/contato-com-rh"
                                        exact
                                        >
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <span className="nome-evento">Nova mensagem em canal de comunicação</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.benefit               &&
                                        notification.benefit !== undefined  ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/meus-beneficios" exact>
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.collaborator &&
                                                    notification.collaborator.avatar &&
                                                    notification.collaborator.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.collaborator.avatar} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaborator.name &&
                                                            notification.collaborator.name !== undefined ?
                                                            notification.collaborator.name
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <span className="nome-evento">Novo benefício cadastrado</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        notification.categoryvacationsincommitng               &&
                                        notification.categoryvacationsincommitng !== undefined  ?
                                        <>
                                        <NavLink
                                        onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} key={index} to="/minhas-ferias" exact>
                                            <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"}>
                                                {
                                                    notification.avatarCompany &&
                                                    notification.avatarCompany !== undefined ?
                                                    <div className="img-col">
                                                        <img src={notification.avatarCompany} alt="nome" />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="infor-vento">
                                                    <div className="nome-data">
                                                        <p>{
                                                            notification.collaborator &&
                                                            notification.collaboratorName &&
                                                            notification.collaboratorName !== undefined ?
                                                            notification.collaboratorName
                                                            :
                                                            ''
                                                        }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                                    </div>
                                                    <p className="add-evento">{notification.collaboratorPersonalDataText}</p>
                                                    <span className="nome-evento">{
                                                        notification.textvacationsincommitng &&
                                                        notification.textvacationsincommitng !== undefined ?
                                                        notification.textvacationsincommitng
                                                        :
                                                        ''
                                                    }</span>
                                                </div>
                                            </div>
                                        </NavLink>

                                        </>
                                        :
                                        ''
                                ))
                               :
                                ''
                           }


                        </div>
                        <div className="btn-vermais">
                            {
                                notificationUser && notificationUser.length === notificationUserTotal ?
                                ''
                                :
                                <button onClick={showMore}>Mostrar mais</button>
                            }
                        </div>

                    </Dropdown.Menu>
                </Dropdown>                    </div>
                    <div  className="dropdown-user">
                        <Dropdown  className="dropdown-icon">
                            <Dropdown.Toggle>
                                {/* <img alt="Usuario" src={iconUser} /> */}
                                <div>

                                    {
                                        decoded.user.avatar && decoded.user.avatar !== 'undefined' ?
                                            <div className="img-col" >
                                                <img src={decoded.user.avatar} alt={decoded.user.name} />
                                            </div>
                                        : (
                                        <>
                                            <div className="colSemFoto" >
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt="" />
                                                </div>
                                            </div>
                                        </>
                                        )
                                    }
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className="infor-col">

                                        {
                                            decoded.user.avatar && decoded.user.avatar !== 'undefined' ?
                                                <div className="img-col" >
                                                    <img src={decoded.user.avatar} alt={decoded.user.name} />
                                                </div>
                                            : (
                                            <>
                                                <div className="colSemFoto" >
                                                    <div className="semImgCol">
                                                        <img  src={IconUserNone} alt="" />
                                                    </div>
                                                </div>
                                            </>
                                            )
                                        }

                                    <p className="nome">
                                        Olá, {nome_1 } :)
                                    </p>
                                </div>
                                {/* <button type="button" name="Ver como colaborador">
                                    Ver como Administrador
                                </button> */}
                                <NavLink to="/configuracao/alterar-senha"  exact>
                                Configurações
                                </NavLink>
                                {
                                    decoded.user.roleNumber &&
                                    decoded.user.roleNumber === 1 ?
                                    <button type="button" name="Exclui-lista" onClick={userViewToAdminView}>
                                        Acessar como admin
                                    </button>
                                    :
                                    decoded.user.roleNumber &&
                                    decoded.user.roleNumber === 2 ?
                                    <button type="button" name="Exclui-lista" onClick={userViewToAdminView}>
                                        Acessar como manager
                                    </button>
                                    :
                                    ''
                                }
                                <button type="button" name="Exclui-lista" onClick={logout}>
                                    sair
                                </button>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
            </>
        );
}
