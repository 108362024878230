import React, { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar';
import { NavLink } from 'react-router-dom'
import iconMenu from '../../../assets/images/icon-menu-mobile.svg'
import Logo from '../../../assets/images/logo.svg'
//import Header from '../../../components/Administrativo/Colaboradores/header';
import IconArrowSidbarOpen from '../../../assets/images/icon-arrow-sidebarOpen.svg';
import IconArrowSidbarClosed from '../../../assets/images/icon-arrow-sidebarClosed.svg'
import './layouts.scss';
import { decodeToken } from '../../../services/auth';
// import api from '../../../services/api';
import moment from 'moment';

export default function DefaultLayout(props) {
    if (props && props.children && props.children.props && props.children.props.className) {
        var path = props.children.props.className;
    }

    let { user, exp } = decodeToken();

    // console.log('decodeToken()')
    // console.log(decodeToken())

    //////// SHOW TRANSACTIONS //////////

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ loadTransactions, setLoadTransactions] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ hideSidebar, setHideSidebar] = useState(false);

    const startOfTrial  = user.companyId?.startOfTrial;
    const statusCompany = user.companyId?.status;

    var expiration       = exp * 1000;
    var dateExp          = moment(expiration)._d
    var currentDate      = moment()._d;
    var difDate          = moment(currentDate).diff(moment(dateExp,"days"));
    var difDatetransform = moment.duration(difDate);
    var secondsExpirade  = difDatetransform._data.seconds;
    // const minutesExpirade = difDatetransform._data.minutes;
    // const hoursExpirade   = difDatetransform._data.hours;




    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        statusCompanyTrial(startOfTrial, statusCompany, secondsExpirade)

    }, [startOfTrial, statusCompany, secondsExpirade]);


    async function statusCompanyTrial(startOfTrial, statusCompany, secondsExpirade) {

        if(statusCompany === 'Trial') {

            let createdAtCompanny_  = new Date(startOfTrial);
            let createdAtCompanny   = createdAtCompanny_.toISOString().split('T')[0]
            let createdAtCompanny_a = createdAtCompanny.split('-');
            let createdAtCompanny_b = new Date(createdAtCompanny_a[0] + "-" + createdAtCompanny_a[1] + "-" + createdAtCompanny_a[2]);

            let currentDate_ = new Date();

            let currentDate   = currentDate_.toISOString().split('T')[0]
            let currentDate_a = currentDate.split('-');
            let currentDate_b = new Date(currentDate_a[0] + "-" + currentDate_a[1] + "-" + currentDate_a[2]);

            let totalTrialDaysCompany = moment(currentDate_b).diff(moment(createdAtCompanny_b,"days"));
            let durationTotalTrialDaysCompany = moment.duration(totalTrialDaysCompany);

            // console.log('Trial')
            // console.log(durationTotalTrialDaysCompany._data);
            if(durationTotalTrialDaysCompany._data.days !== undefined) {
                var days   = durationTotalTrialDaysCompany._data.days;
            }
            if(durationTotalTrialDaysCompany._data.months !== undefined) {
                var months = durationTotalTrialDaysCompany._data.months;
            }
            if(durationTotalTrialDaysCompany._data.years !== undefined) {
                var years  = durationTotalTrialDaysCompany._data.years;
            }

            console.log('days')
            console.log(days)

            console.log('months')
            console.log(months)

            console.log('years')
            console.log(years)



            if(days > 9 || months > 0 || years > 0) {

                // console.log('secondsExpirade')
                // console.log(secondsExpirade)

                if(secondsExpirade > 0) {

                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }

                setHideSidebar(true)

            }
            else {

                // console.log('secondsExpirade')
                // console.log(secondsExpirade)

                if(secondsExpirade > 0) {

                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }


            }

        }
        else {

            // console.log('secondsExpirade')
            // console.log(secondsExpirade)

            if(secondsExpirade > 0) {

                localStorage.clear();
                setTimeout(function(){window.location.href = '/';}, 1000);

            }

            setHideSidebar(false);

        }




    }



    //////// CLOSE SIDBAR //////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sideBarShow, setSideBarShow ] = useState(false);

    // async function fecharSidBar() {
    //     setSideBarShow(false)
    // }
    // async function abrirSidBar() {
    //     setSideBarShow(true)
    // }

    async function fecharSidBarAbrir() {
        setSideBarShow(!sideBarShow)
    }

    return (
        <>
        {/* <div> */}
            <div className={
                // path && path === 'pagina-solicitar-ferias' ?
                //             "layaut-container bg-pag-ferias-admin" : 'layaut-container'

                // sideBarShow ?
                // path && path === 'pagina-solicitar-ferias' ?
                // "layaut-container bg-pag-ferias-admin" : 'layaut-container'
                // :
                // path && path === 'pagina-solicitar-ferias' ?
                // "layaut-container layaut-sem-sidbar bg-pag-ferias-admin" : 'layaut-sem-sidbar layaut-container'




                sideBarShow ?
                    path && path === 'pagina-solicitar-ferias' ?
                        "layaut-container bg-pag-ferias-admin"
                        :
                        'layaut-container'
                :
                    path && path === 'pagina-solicitar-ferias' ?
                    "layaut-sem-sidbar bg-pag-ferias-admin"
                    :
                    'layaut-sem-sidbar'




                            }>
                <div
                    className={
                        sideBarShow ? "sidebar ativo" : "sidebar "
                    }>
                {
                    user.companyId?.status === 'Inativo'?
                    ''
                    :
                    hideSidebar ?
                    ''
                    :
                    <Sidebar
                    path={props.path}
                    fecharSidBarAbrir={fecharSidBarAbrir}
                    />
                }

                </div>

                <div className='cont-interno'>
                    <div className="conteudo">
                        <div className='btn-menu-responsivo'>
                            <button className={  sideBarShow ? 'btn-closed-sidebar' : 'btn-open-sidebar'  }
                                onClick={  fecharSidBarAbrir } >
                                <img src={ sideBarShow ?  IconArrowSidbarClosed :  IconArrowSidbarOpen} alt="" />
                            </button>
                            {/* <button className={  sideBarShow ? 'btn-closed-sidebar bt-sidebar-desktop' : 'btn-open-sidebar bt-sidebar-desktop'  }
                                onClick={ fecharSidBarAbrir } >
                                <img src={ sideBarShow ?  IconArrowSidbarClosed : IconArrowSidbarOpen} alt="" />
                            </button> */}

                        </div>
                        <div className="btn-menu-mobile">
                            <button className='menu' onClick={fecharSidBarAbrir}>
                                <img src={iconMenu} alt="menu" />
                            </button>
                            <NavLink to="/dashboard" className="logo">
                                <img src={Logo} alt="peopleview" />
                            </NavLink>
                        </div>




                        {/* <button className={  sideBarShow ? 'btn-closed-sidebar bt-sidebar-mobile-1' : 'btn-open-sidebar bt-sidebar-mobile-1'  }
                            onClick={  sideBarShow ?  fecharSidBar : abrirSidBar } >
                            <img src={ sideBarShow ?  IconArrowSidbarOpen : IconArrowSidbarClosed} alt="" />
                        </button>
                        <button className={  sideBarShow ? 'btn-closed-sidebar bt-sidebar-desktop' : 'btn-open-sidebar bt-sidebar-desktop'  }
                            onClick={  sideBarShow ?  fecharSidBar : abrirSidBar } >
                            <img src={ sideBarShow ?  IconArrowSidbarClosed : IconArrowSidbarOpen} alt="" />
                        </button> */}




                        {
                        props.children
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
