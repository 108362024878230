import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, NavLink } from "react-bootstrap";
import iconV from '../../assets/images/v.svg';
import DropdownCalendar from './DropdownCalendar';
import imgCalendar from '../../assets/images/calendar.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import IconAddCol from '../../assets/images/icone-add-calendar.svg';
import moment from 'moment';
import swal from 'sweetalert';
import {
    diaMesFormatadaParaPontoCalendar,
    dataFormatadaParaTraco,
    dataFormatadaParaTracoCalendar,
    FormataCurrentMonthToCalendar
} from '../../services/utils';
import api from '../../services/api';
import { toast } from 'react-toastify';
import  './calendar.scss';

export default function pageCalendary ({ name }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [campoPesquicaColl, setCampoPesquicaColl] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filtroAno, setFiltroAno] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filtroMes, setFiltroMEs] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filtroSemana, setFiltroSemana] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentDate, setCurrentDate] = useState(new Date());

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentDate2, ] = useState(new Date());
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [currentDate, setCurrentDate] = useState(new Date('2022-05-18T23:05:00Z'));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    var largura = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // console.log('largura')
    // console.log(largura)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusEvent   = useRef(null);

    function AddColl() {
        setCampoPesquicaColl(!campoPesquicaColl);
    }

    function abrirFiltroAno() {
        setFiltroAno(true);
        setFiltroMEs(false);
        setFiltroSemana(false);
    }
    function abrirFiltroMes() {
        setFiltroAno(false);
        setFiltroMEs(true);
        setFiltroSemana(false);
        // alert(currentDate)
    }
    function abrirFiltroSemana() {
        setFiltroAno(false);
        setFiltroMEs(false);
        setFiltroSemana(true);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentHour, setCurrentHour] = useState(currentDate2.getHours());

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadeventscalendar(largura);


    }, [largura]);

    const dayOfTheWeek =  moment(currentDate).format('e');

    // const weekDayName  =  moment(currentDate).format('dddd');

      ////////////////////////////////////////////////////////////////////////////
     /////////           DEFININDO DIAS DE SEGUNDA A DOMINGO          ///////////
    ////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [domingo, setDomingo] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [segunda, setSegunda] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [terca, setTerca] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [quarta, setQuarta] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [quinta, setQuinta] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sexta, setSexta] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sabado, setSabado] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [cout35Days, setCout35Days] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [coutMonthInTheYear, setCoutMonthInTheYear] = useState([]);

    // eventsCalendarCount35DaysQuery
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [eventsCalendarCount35DaysQuery, setEventsCalendarCount35DaysQuery] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {


          /////////////////////////////////////////////////////////////
         ///////////     DEFININDO DE SEGUNDA À SEXTA     ////////////
        /////////////////////////////////////////////////////////////
        async function loadweekeventscalendar() {

            if(dayOfTheWeek === '1') {



                let monday             = moment(currentDate);
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)

                // console.log('mondayDateFormated')
                // console.log(mondayDateFormated)

                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate).add(1, 'days');
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate).add(2, 'days');
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate).add(3, 'days');
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate).add(4, 'days');
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate).add(5, 'days');
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)
                setSabado(saturdayDateFormated)

                let sunday             = moment(currentDate).add(6, 'days');
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }
            else
            if(dayOfTheWeek === '2') {

                let monday             = moment(currentDate).subtract(1, 'days');
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)
                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate)
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate).add(1, 'days');
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate).add(2, 'days');
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate).add(3, 'days');
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate).add(4, 'days');
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)
                setSabado(saturdayDateFormated)

                let sunday             = moment(currentDate).add(5, 'days');
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }
            else
            if(dayOfTheWeek === '3') {

                let monday             = moment(currentDate).subtract(2, 'days');
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)
                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate).subtract(1, 'days');
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate);
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate).add(1, 'days');
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate).add(2, 'days');
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate).add(3, 'days');
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)
                setSabado(saturdayDateFormated)

                let sunday             = moment(currentDate).add(4, 'days');
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }
            else
            if(dayOfTheWeek === '4') {

                // console.log('currentDate')
                // console.log(currentDate)

                let monday             = moment(currentDate).subtract(3, 'days');
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)
                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate).subtract(2, 'days');
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate).subtract(1, 'days');
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate);
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate).add(1, 'days');
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate).add(2, 'days');
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)
                setSabado(saturdayDateFormated)

                // let sunday             = moment(currentDate).subtract(4, 'days');
                let sunday             = moment(currentDate).add(3, 'days');
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }
            else
            if(dayOfTheWeek === '5') {

                let monday             = moment(currentDate).subtract(4, 'days');
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)
                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate).subtract(3, 'days');
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate).subtract(2, 'days');
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate).subtract(1, 'days');
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate)
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate).add(1, 'days');
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)
                setSabado(saturdayDateFormated)

                // let sunday             = moment(currentDate).subtract(4, 'days');
                let sunday             = moment(currentDate).add(2, 'days');
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }
            else
            if(dayOfTheWeek === '6') {

                let monday             = moment(currentDate).subtract(5, 'days');
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)
                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate).subtract(4, 'days');
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate).subtract(3, 'days');
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate).subtract(2, 'days');
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate).subtract(1, 'days');
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate)
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)

                // console.log('saturdayDateFormated')
                // console.log(saturdayDateFormated)

                setSabado(saturdayDateFormated)

                // let sunday             = moment(currentDate).subtract(4, 'days');
                let sunday             = moment(currentDate).add(1, 'days');
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }
            else
            if(dayOfTheWeek === '0') {

                let monday             = moment(currentDate).subtract(6, 'days');
                let mondayDate         = new Date(monday)
                let mondayDateFormated = diaMesFormatadaParaPontoCalendar(mondayDate)
                setSegunda(mondayDateFormated)

                let tuesday             = moment(currentDate).subtract(5, 'days');
                let tuesdayDate         = new Date(tuesday)
                let tuesdayDateFormated = diaMesFormatadaParaPontoCalendar(tuesdayDate)
                setTerca(tuesdayDateFormated)

                let wednesday             = moment(currentDate).subtract(4, 'days');
                let wednesdayDate         = new Date(wednesday)
                let wednesdayDateFormated = diaMesFormatadaParaPontoCalendar(wednesdayDate)
                setQuarta(wednesdayDateFormated)

                let thursday             = moment(currentDate).subtract(3, 'days');
                let thursdayDate         = new Date(thursday)
                let thursdayDateFormated = diaMesFormatadaParaPontoCalendar(thursdayDate)
                setQuinta(thursdayDateFormated)

                let friday             = moment(currentDate).subtract(2, 'days');
                let fridayDate         = new Date(friday)
                let fridayDateFormated = diaMesFormatadaParaPontoCalendar(fridayDate)
                setSexta(fridayDateFormated)

                let saturday             = moment(currentDate).subtract(1, 'days');
                let saturdayDate         = new Date(saturday)
                let saturdayDateFormated = diaMesFormatadaParaPontoCalendar(saturdayDate)
                setSabado(saturdayDateFormated)

                // let sunday             = moment(currentDate).subtract(4, 'days');
                let sunday             = moment(currentDate)
                let sundayDate         = new Date(sunday)
                let sundayDateFormated = diaMesFormatadaParaPontoCalendar(sundayDate)
                setDomingo(sundayDateFormated)

            }

        }
        loadweekeventscalendar();


          /////////////////////////////////////////////////////////////////////
         ///////////     ÚNICO GET DE MÊS - LÓGICA VEM DO BACK    ////////////
        /////////////////////////////////////////////////////////////////////
        async function loadcountevents35days() {
            // setCout35Days

            if(eventsCalendarCount35DaysQuery === false) {

                await api.get(`/events-calendar-count-35-days/`)
                .then(response => {

                    setCout35Days(response.data);

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }




        }
        loadcountevents35days();

        loadcounteventsmonthintheyear();

    }, [currentDate, dayOfTheWeek, eventsCalendarCount35DaysQuery]);


      ////////////////////////////////////////////////////////////////////////////
     /////////     FINAL DE DEFININDO DIAS DE SEGUNDA A DOMINGO        //////////
    ////////////////////////////////////////////////////////////////////////////

    async function loadCoutMonthInTheYearInitialDateToString(event) {

        setEventsCalendarCount35DaysQuery(true)

        let currentMonthSplItednitialDateToString = event.split('-')[1]

        let currentMonthClickedItednitialDateToString = parseInt(currentMonthSplItednitialDateToString -1)

        // alert(FormataCurrentMonthToCalendar(currentMonthClickedItednitialDateToString))

        setCurrentMonth(FormataCurrentMonthToCalendar(currentMonthClickedItednitialDateToString))

        let currentDate_ = event;
        setCurrentDate(currentDate_);

        let currentYear_ = event.split('-')[0];
        setCurrentYear(currentYear_);

        await api.get(`/events-calendar-count-35-days/?initialDateToString=${event}`)
        .then(response => {


            setFiltroAno(false);
            setFiltroMEs(true);
            setFiltroSemana(false);

            setCout35Days(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

      /////////////////////////////////////////////////////////////////////
     ///////////     ÚNICO GET DE ANO - LÓGICA VEM DO BACK    ////////////
    /////////////////////////////////////////////////////////////////////
    async function loadcounteventsmonthintheyear() {
        // setCout35Days

        await api.get('/events-calendar-count-per-month-in-the-year/')
        .then(response => {

            setCoutMonthInTheYear(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


     ////////////////////////////////////////////////////////////////////////////
    /////////     INICIO DE GET DE EVENTO DE SEGUNDA A DOMINGO        //////////
   ////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ eventCalendar, setEventCalendar] = useState([]);

    let events1 = [];
    let events2 = [];
    let events3 = [];
    let events4 = [];
    let events5 = [];
    let events6 = [];
    let events7 = [];



      /////////////////////////////////////////////////////////////////////
     /////////////////      GET EVENTS CALENDAR      /////////////////////
    /////////////////////////////////////////////////////////////////////


    async function loadeventscalendar(largura) {
        await api.get('/events-calendar/')
        .then(response => {

            setEventCalendar(response.data);
            if(largura > 1700) {

                focusEvent.current.focus();

            }


        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ comercial, setComercial] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ devs, setDevs] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ reuniao, setReuniao] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentMonth, setCurrentMonth] = useState(FormataCurrentMonthToCalendar(new Date().getMonth()));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());



    async function getDateWeekTheMonth(event, startTimeEvent) {

        let startTime = startTimeEvent.split(':')[0]

        setCurrentHour(startTime)

        let currentDate_ = dataFormatadaParaTraco(event)
        let currentMonthSplited = currentDate_.split('-')[1]
        let currentMonthClicked = parseInt(currentMonthSplited -1)

        let currentYear_      = event.split('/')[2]
        setCurrentYear(currentYear_)

        setCurrentMonth(FormataCurrentMonthToCalendar(currentMonthClicked))


        setCurrentDate(currentDate_)

        setFiltroAno(false);
        setFiltroMEs(false);
        setFiltroSemana(true);

        loadeventscalendar();


    }


    async function loadeventscalendarcategory(event) {


        if(event.target.name === 'comercial') {
            // alert('comercial')
            if(event.target.checked === true) {

                setComercial(true)
                // ==> COMERCIAL
                if(devs === false && reuniao === false) {

                    await api.get('/events-calendar/?eventCategory=comercial')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                // ==> COMERCIAL E DEVS
                if(devs === true && reuniao === false) {

                    await api.get('/events-calendar/?eventCategory=comercial&&eventCategory=devs')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                // ==> COMERCIAL E REUNIAO
                if(devs === false && reuniao === true) {

                    await api.get('/events-calendar/?eventCategory=comercial&&eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                // ==> COMERCIAL E DEVS E REUNIAO
                if(devs === true && reuniao === true) {

                    await api.get('/events-calendar/')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }
            else {

                setComercial(false)

                // ==> COMERCIAL E DEVS E REUNIAO
                if(devs === false && reuniao === false) {

                    await api.get('/events-calendar/')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                // ==> DEVS
                if(devs === true && reuniao === false) {

                    await api.get('/events-calendar/?eventCategory=devs')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                // ==> REUNIAO
                if(devs === false && reuniao === true) {

                    await api.get('/events-calendar/?eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                // ==> DEVS E REUNIAO
                if(devs === true && reuniao === true) {

                    await api.get('/events-calendar/?eventCategory=devs&&eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }

        }
        else
        if(event.target.name === 'devs') {
            // alert('devs')
            if(event.target.checked === true) {

                setDevs(true)

                // ==> DEVS
                if(comercial === false && reuniao === false) {

                    await api.get('/events-calendar/?eventCategory=devs')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                // ==> DEVS E COMERCIAL
                if(comercial === true && reuniao === false) {

                    await api.get('/events-calendar/?eventCategory=devs&&eventCategory=comercial')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> DEVS E REUNIAO
                if(comercial === false && reuniao === true) {

                    await api.get('/events-calendar/?eventCategory=devs&&eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                // ==> DEVS E COMERCIAL E REUNIAO
                if(comercial === true && reuniao === true) {

                    await api.get('/events-calendar/')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }


            }
            else {
                setDevs(false)

                // ==> DEVS E COMERCIL E REUNIAO
                if(comercial === false && reuniao === false) {

                    await api.get('/events-calendar/')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> COMERCIAL
                if(comercial === true && reuniao === false) {

                    await api.get('/events-calendar/?eventCategory=comercial')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> REUNIAO
                if(comercial === false && reuniao === true) {

                    await api.get('/events-calendar/?eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                // ==> COMERCIAL E REUNIAO
                if(comercial === true && reuniao === true) {

                    await api.get('/events-calendar/?eventCategory=comercial&&eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
            }

        }
        else
        if(event.target.name === 'reuniao') {
            // alert('reuniao')
            if(event.target.checked === true) {

                setReuniao(true)
                // ==> REUNIAO
                if(comercial === false && devs === false) {

                    await api.get('/events-calendar/?eventCategory=reuniao')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> REUNIAO E COMERCIAL
                if(comercial === true && devs === false) {

                    await api.get('/events-calendar/?eventCategory=reuniao&&eventCategory=comercial')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> REUNIAO E DEVS
                if(comercial === false && devs === true) {

                    await api.get('/events-calendar/?eventCategory=reuniao&&eventCategory=devs')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                // ==> REUNIAO E DEVS E COMERCIAL
                if(comercial === true && devs === true) {

                    await api.get('/events-calendar/')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }


            }
            else {

                setReuniao(false)

                // ==> REUNIAO E COMERCIAL E DEVS
                if(comercial === false && devs === false) {

                    await api.get('/events-calendar/')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> COMERCIAL
                if(comercial === true && devs === false) {

                    await api.get('/events-calendar/?eventCategory=comercial')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                else
                // ==> DEVS
                if(comercial === false && devs === true) {

                    await api.get('/events-calendar/?eventCategory=devs')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

                }
                // ==> DEVS E COMERCIAL
                if(comercial === true && devs === true) {

                    await api.get('/events-calendar/?eventCategory=devs&&eventCategory=comercial')
                    .then(response => {

                        setEventCalendar(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
            }

        }



    }


      ///////////////////////////////////////////////////////////////////////////////
     /////////////////      HANDLER EVENT CALENDAR UPDATE      /////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [eventCalendarUpdate, setEventCalendarUpdate] = useState([]);


    async function myChangeHanldlerEventcalendar(event) {

        // console.log('event.target')
        // console.log(event.target)
        let nam = event.target.name;
        let val = event.target.value;

        //eventDate_
        if(nam === 'eventDate_'){

            let eventDate = dataFormatadaParaTracoCalendar(val);
            setEventCalendarUpdate({ ...eventCalendarUpdate, [nam]: val, eventDate });

        }
        else {

            setEventCalendarUpdate({ ...eventCalendarUpdate, [nam]: val })

        }

    }

      ///////////////////////////////////////////////////////////////////////////////
     /////////////////       SETANDO CATEGORIA DO EVENTO       /////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    async function onClickeventCategory(event) {

        let nam = event.target.name;
        let val = event.target.id;

        if(nam === 'eventCategory_') {

            let eventCategory = val;
            setEventCalendarUpdate({ ...eventCalendarUpdate, [nam]: val, eventCategory })

        }
        else {

            setEventCalendarUpdate({ ...eventCalendarUpdate, [nam]: val })

        }



    }


      ///////////////////////////////////////////////////////////////////////////////
     ////////////////////         BUSCANDO COLABORADORES       /////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorFilterForThisEventCalendar, setCollaboratorFilterForThisEventCalendar ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ PesqCollaborator, setPesqCollaborator ] = useState([]);

    async function loadFilterCollaboratorForThisEventCalendar(event) {
        // console.log('event')
        // console.log(event)
        const name = event.target.value;
        setPesqCollaborator(name)
        if(name === '') {
            setCollaboratorFilterForThisEventCalendar([]);
        }
        else {
            //setLoadCollaboratorFilterForThisSkill(true)
            await api.get(`/collaborator-new?name=${name}&role=user&role=manager&role=admin`)
            .then(response => {

                const docs_a  = response.data[0];
                const arrInfo = response.data[1];

                let itensCopyCollaborator = []

                // => Se total de colaboradores forem menor que o limite
                if(arrInfo[0].total < arrInfo[0].limite) {

                    for(let dcs=0; dcs < arrInfo[0].total; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorFilterForThisEventCalendar(itensCopyCollaborator);
                    // setArrInfo(arrInfo[0]);

                }
                else {

                    for(let dcs=0; dcs < arrInfo[0].limite; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorFilterForThisEventCalendar(itensCopyCollaborator);
                    // setArrInfo(arrInfo[0]);

                }
                // => Final de => Se total de colaboradores forem menor que o limite
                // setCollaboratorFilterForThisEventCalendar(response.data);

            }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }

    }


      ////////////////////////////////////////////////////////////////////////////
     //////////////////      ADICONANDO COLABORADORES          //////////////////
    ////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborators, setCollaborators] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadToAddCollaborator ] = useState(false);

    async function myChangeHandlerToAddCollaborator(event) {

       let val = event.target.id;

       let arrCol = collaboratorFilterForThisEventCalendar;
       let indexCol = arrCol.findIndex(objeto => objeto._id === val);
       arrCol.splice(indexCol, 1);
       setCollaboratorFilterForThisEventCalendar(arrCol);
       setLoadToAddCollaborator(true)
       await api.get(`/collaborator/${val}`)
       .then(response => {

           const itensCopyCollaborators = Array.from(collaborators);

           itensCopyCollaborators.push({idcollaborator: collaborators.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });

           setCollaborators(itensCopyCollaborators);
           setCollaboratorBuscaPostEventCalendar([]);
           setCollaboratorFilterForThisEventCalendar([]);
           setPesqCollaborator([]);
           setLoadToAddCollaborator(false)

       }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


   




    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorsToUpdate, setCollaboratorsToUpdate] = useState([]);

    async function myChangeHandlerToAddCollaboratorToUpdate(event) {

        let val = event.target.id;

        let arrCol = collaboratorFilterForThisEventCalendar;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setCollaboratorFilterForThisEventCalendar(arrCol);
        setLoadToAddCollaborator(true)
        await api.get(`/collaborator/${val}`)
        .then(response => {

            const itensCopyCollaboratorsToUpdate = Array.from(collaboratorsToUpdate);

            itensCopyCollaboratorsToUpdate.push({idcollaborator: collaborators.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });

            setCollaboratorsToUpdate(itensCopyCollaboratorsToUpdate);
            setCollaboratorBuscaPostEventCalendar([]);
            setCollaboratorFilterForThisEventCalendar([]);
            setPesqCollaborator([]);
            setLoadToAddCollaborator(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

     }


    const hours = [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30"
    ]

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadUpdateeventCalendar ] = useState(false);


    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ eventCalendarEdit, setEventCalendarEdit ] = useState([]);

      ////////////////////////////////////////////////////////////////////////////
     ////////////////////        ATUALIZANDO EVENTOS.         ///////////////////
    ////////////////////////////////////////////////////////////////////////////
    async function updateEventcalendar(event) {

        let _id = event.target.id;

        if(
            (eventCalendarUpdate                        &&
            eventCalendarUpdate.eventName       !== undefined &&
            eventCalendarUpdate.eventName       !==     "")
            ||
            (eventCalendarUpdate.eventDate      !== undefined &&
            eventCalendarUpdate.eventDate       !==     "")
            ||
            (eventCalendarUpdate.startTimeEvent !== undefined &&
            eventCalendarUpdate.startTimeEvent  !==     "")
            ||
            (eventCalendarUpdate.endTimeEvent   !== undefined &&
            eventCalendarUpdate.endTimeEvent    !==     "")
            ||
            (eventCalendarUpdate.eventCategory_ !== undefined &&
            eventCalendarUpdate.eventCategory_  !==     "")
            ||
            collaboratorsToUpdate.length                !==     0
        ) {

            if(eventCalendarUpdate.length === 0) {

                setLoadUpdateeventCalendar(true)
                await api.put(`/events-calendar/${_id}`, { collaborator: collaboratorsToUpdate })
                .then(response => {

                    setEventCalendarUpdate([]);
                    setCollaboratorsToUpdate([]);
                    loadeventscalendar();


                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadUpdateeventCalendar(false);
                });

            }

            if(eventCalendarUpdate.length !== 0 && collaboratorsToUpdate.length === 0) {

                setLoadUpdateeventCalendar(true)
                await api.put(`/events-calendar/${_id}`, eventCalendarUpdate)
                .then(response => {

                    setEventCalendarUpdate([]);
                    setCollaboratorsToUpdate([]);
                    loadeventscalendar();


                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadUpdateeventCalendar(false);
                });

            }

            if(eventCalendarUpdate.length !== 0 && collaboratorsToUpdate.length !== 0) {

                let eventName      = null;
                if(eventCalendarUpdate.eventName !== undefined) {
                    eventName = eventCalendarUpdate.eventName;
                }

                let eventDate      = null;
                if(eventCalendarUpdate.eventDate !== undefined) {
                    eventDate = eventCalendarUpdate.eventDate;
                }

                let startTimeEvent = null;
                if(eventCalendarUpdate.startTimeEvent !== undefined) {
                    startTimeEvent = eventCalendarUpdate.startTimeEvent;
                }

                let endTimeEvent   = null;
                if(eventCalendarUpdate.endTimeEvent !== undefined) {
                    endTimeEvent = eventCalendarUpdate.endTimeEvent;
                }

                let eventCategory  = null;
                if(eventCalendarUpdate.eventCategory !== undefined) {
                    eventCategory = eventCalendarUpdate.eventCategory;
                }


                /// ==> TODOS DIFERENTE DE  UNDEFINED
                if(
                    eventName      !== undefined &&
                    eventDate      !== undefined &&
                    startTimeEvent !== undefined &&
                    endTimeEvent   !== undefined &&
                    eventCategory  !== undefined
                ){

                    let eventWithCollaborators = [{

                        eventName     ,
                        eventDate     ,
                        startTimeEvent,
                        endTimeEvent  ,
                        eventCategory ,
                        collaborator: collaboratorsToUpdate,


                    }];

                    setLoadUpdateeventCalendar(true)
                    await api.put(`/events-calendar/${_id}`, eventWithCollaborators[0])
                    .then(response => {

                        setEventCalendarUpdate([]);
                        setCollaboratorsToUpdate([]);
                        loadeventscalendar();


                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadUpdateeventCalendar(false);
                    });

                }

            }


        }
        else {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um item!" });
        }

    }



      ////////////////////////////////////////////////////////////////////////////
     /////////////         CARREGANDO EVENTOS DE SEG. A DOM.         ////////////
    ////////////////////////////////////////////////////////////////////////////
    async function loadevents() {

        if(eventCalendar && eventCalendar.length !== 0) {


            for(let ec=0; ec < eventCalendar.length; ec++) {

                let dayWeekFormated = eventCalendar[ec].dayWeekFormated;

                if(dayWeekFormated === segunda) {

                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }


                    events1.push(
                        <div style={{marginTop: resultBaseHour}} className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown
                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <DropdownCalendar
                                eventCalendar={eventCalendar[ec]}
                                myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                eventCalendarUpdate={eventCalendarUpdate}
                                dataFormatadaParaTraco={dataFormatadaParaTraco}
                                hours={hours}
                                onClickeventCategory={onClickeventCategory}
                                collaboratorEventCalendar={collaborator}
                                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                collaboratorsToUpdate={collaboratorsToUpdate}
                                collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                PesqCollaborator={PesqCollaborator}
                                collaborators={collaborators}
                                AddColl={AddColl}
                                campoPesquicaColl={campoPesquicaColl}
                                updateEventcalendar={updateEventcalendar}
                                />
                            </Dropdown>
                        </div>

                    )

                }

                if(dayWeekFormated === terca) {


                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }




                    events2.push(
                        <div
                        style={{marginTop: resultBaseHour}} className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown

                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <DropdownCalendar
                                eventCalendar={eventCalendar[ec]}
                                myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                eventCalendarUpdate={eventCalendarUpdate}
                                dataFormatadaParaTraco={dataFormatadaParaTraco}
                                hours={hours}
                                onClickeventCategory={onClickeventCategory}
                                collaboratorEventCalendar={collaborator}
                                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                collaboratorsToUpdate={collaboratorsToUpdate}
                                collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                PesqCollaborator={PesqCollaborator}
                                collaborators={collaborators}
                                AddColl={AddColl}
                                campoPesquicaColl={campoPesquicaColl}
                                updateEventcalendar={updateEventcalendar}
                                />
                            </Dropdown>
                        </div>

                    )


                }

                if(dayWeekFormated === quarta) {

                    // console.log('dayWeekFormated - quarta')
                    // console.log(dayWeekFormated)

                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }


                    events3.push(
                        <div style={{marginTop: resultBaseHour}} className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown
                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas position-23'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                {/* {eventCalendar[ec].baseHour} */}
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                    <DropdownCalendar
                                    eventCalendar={eventCalendar[ec]}
                                    myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                    eventCalendarUpdate={eventCalendarUpdate}
                                    dataFormatadaParaTraco={dataFormatadaParaTraco}
                                    hours={hours}
                                    onClickeventCategory={onClickeventCategory}
                                    collaboratorEventCalendar={collaborator}
                                    myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                    myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                    collaboratorsToUpdate={collaboratorsToUpdate}
                                    collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                    loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                    PesqCollaborator={PesqCollaborator}
                                    collaborators={collaborators}
                                    AddColl={AddColl}
                                    campoPesquicaColl={campoPesquicaColl}
                                    updateEventcalendar={updateEventcalendar}
                                    />

                            </Dropdown>
                        </div>

                    )

                }

                if(dayWeekFormated === quinta) {

                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }


                    events4.push(
                        <div style={{marginTop: resultBaseHour}}  className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown
                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <DropdownCalendar
                                eventCalendar={eventCalendar[ec]}
                                myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                eventCalendarUpdate={eventCalendarUpdate}
                                dataFormatadaParaTraco={dataFormatadaParaTraco}
                                hours={hours}
                                onClickeventCategory={onClickeventCategory}
                                collaboratorEventCalendar={collaborator}
                                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                collaboratorsToUpdate={collaboratorsToUpdate}
                                collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                PesqCollaborator={PesqCollaborator}
                                collaborators={collaborators}
                                AddColl={AddColl}
                                campoPesquicaColl={campoPesquicaColl}
                                updateEventcalendar={updateEventcalendar}
                                />
                            </Dropdown>
                        </div>

                    )

                }

                if(dayWeekFormated === sexta) {

                    // let _id            = eventCalendar[ec]._id;
                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }


                    events5.push(
                        <div style={{marginTop: resultBaseHour}}  className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown
                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <DropdownCalendar
                                eventCalendar={eventCalendar[ec]}
                                myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                eventCalendarUpdate={eventCalendarUpdate}
                                dataFormatadaParaTraco={dataFormatadaParaTraco}
                                hours={hours}
                                onClickeventCategory={onClickeventCategory}
                                collaboratorEventCalendar={collaborator}
                                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                collaboratorsToUpdate={collaboratorsToUpdate}
                                collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                PesqCollaborator={PesqCollaborator}
                                collaborators={collaborators}
                                AddColl={AddColl}
                                campoPesquicaColl={campoPesquicaColl}
                                updateEventcalendar={updateEventcalendar}
                                />
                            </Dropdown>
                        </div>

                    )

                }

                if(dayWeekFormated === sabado) {

                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }


                    events6.push(
                        <div style={{marginTop: resultBaseHour}} className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown
                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <DropdownCalendar
                                eventCalendar={eventCalendar[ec]}
                                myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                eventCalendarUpdate={eventCalendarUpdate}
                                dataFormatadaParaTraco={dataFormatadaParaTraco}
                                hours={hours}
                                onClickeventCategory={onClickeventCategory}
                                collaboratorEventCalendar={collaborator}
                                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                collaboratorsToUpdate={collaboratorsToUpdate}
                                collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                PesqCollaborator={PesqCollaborator}
                                collaborators={collaborators}
                                AddColl={AddColl}
                                campoPesquicaColl={campoPesquicaColl}
                                updateEventcalendar={updateEventcalendar}
                                />
                            </Dropdown>
                        </div>

                    )

                }

                if(dayWeekFormated === domingo) {

                    let baseHour       = parseInt(eventCalendar[ec].baseHour);
                    let eventName      = eventCalendar[ec].eventName;
                    let startTimeEvent = eventCalendar[ec].startTimeEvent;
                    let endTimeEvent   = eventCalendar[ec].endTimeEvent;
                    let baseMinutes    = eventCalendar[ec].baseMinutes;
                    let totalMinutes   = eventCalendar[ec].totalMinutes;
                    let eventCategory  = eventCalendar[ec].eventCategory_;
                    let collaborator   = eventCalendar[ec].collaborator;

                    let resultBaseHour = baseHour * 66;

                    if(baseMinutes === '30') {
                        resultBaseHour = resultBaseHour + 30;
                    }


                    events7.push(
                        <div style={{marginTop: resultBaseHour}}  className={
                            eventCalendar[ec].baseHour === "00" ? 'position-0 evento' :
                            eventCalendar[ec].baseHour === "01" ? 'position-1 evento' :
                            eventCalendar[ec].baseHour === "02" ? 'position-2 evento' :
                            eventCalendar[ec].baseHour === "03" ? 'position-3 evento' :
                            eventCalendar[ec].baseHour === "04" ? 'position-4 evento' :
                            eventCalendar[ec].baseHour === "05" ? 'position-5 evento' :
                            eventCalendar[ec].baseHour === "06" ? 'position-6 evento' :
                            eventCalendar[ec].baseHour === "07" ? 'position-7 evento' :
                            eventCalendar[ec].baseHour === "08" ? 'position-8 evento' :
                            eventCalendar[ec].baseHour === "09" ? 'position-9 evento' :
                            eventCalendar[ec].baseHour === "10" ? 'position-10 evento' :
                            eventCalendar[ec].baseHour === "11" ? 'position-11 evento' :
                            eventCalendar[ec].baseHour === "12" ? 'position-12 evento' :
                            eventCalendar[ec].baseHour === "13" ? 'position-13 evento' :
                            eventCalendar[ec].baseHour === "14" ? 'position-14 evento' :
                            eventCalendar[ec].baseHour === "15" ? 'position-15 evento' :
                            eventCalendar[ec].baseHour === "16" ? 'position-16 evento' :
                            eventCalendar[ec].baseHour === "17" ? 'position-17 evento' :
                            eventCalendar[ec].baseHour === "18" ? 'position-18 evento' :
                            eventCalendar[ec].baseHour === "19" ? 'position-19 evento' :
                            eventCalendar[ec].baseHour === "20" ? 'position-20 evento' :
                            eventCalendar[ec].baseHour === "21" ? 'position-21 evento' :
                            eventCalendar[ec].baseHour === "22" ? 'position-22 evento' :
                            eventCalendar[ec].baseHour === "23" ? 'position-23 evento' :
                            "evento"
                        }>
                            <Dropdown
                            className={
                            totalMinutes === 0.5 ? 'dropdown-icon criar-evento trinta'                  :
                            totalMinutes === 1   ? 'dropdown-icon criar-evento hora'                    :
                            totalMinutes === 1.5 ? 'dropdown-icon criar-evento hora-meia'               :
                            totalMinutes === 2   ? 'dropdown-icon criar-evento duas-horas'              :
                            totalMinutes === 2.5 ? 'dropdown-icon criar-evento duas-horas-meia'         :
                            totalMinutes === 3   ? 'dropdown-icon criar-evento treis-horas'             :
                            totalMinutes === 3.5 ? 'dropdown-icon criar-evento treis-horas-meia'        :
                            totalMinutes === 4   ? 'dropdown-icon criar-evento quatro-horas'            :
                            totalMinutes === 4.5 ? 'dropdown-icon criar-evento quatro-horas-meia'       :
                            totalMinutes === 5   ? 'dropdown-icon criar-evento cinco-horas'             :
                            totalMinutes === 5.5 ? 'dropdown-icon criar-evento cinco-horas-meia'        :
                            totalMinutes === 6   ? 'dropdown-icon criar-evento seis-horas'              :
                            totalMinutes === 6.5  ? 'dropdown-icon criar-evento seis-horas-meia'        :
                            totalMinutes === 7    ? 'dropdown-icon criar-evento sete-horas'             :
                            totalMinutes === 7.5  ? 'dropdown-icon criar-evento sete-horas-meia'        :
                            totalMinutes === 8    ? 'dropdown-icon criar-evento oito-horas'             :
                            totalMinutes === 8.5  ? 'dropdown-icon criar-evento oito-horas-meia'        :
                            totalMinutes === 9    ? 'dropdown-icon criar-evento nove-horas'             :
                            totalMinutes === 9.5  ? 'dropdown-icon criar-evento nove-horas-meia'        :
                            totalMinutes === 10   ? 'dropdown-icon criar-evento dez-horas'              :
                            totalMinutes === 10.5 ? 'dropdown-icon criar-evento dez-horas-meia'         :
                            totalMinutes === 11   ? 'dropdown-icon criar-evento onze-horas'             :
                            totalMinutes === 11.5 ? 'dropdown-icon criar-evento onze-horas-meia'        :
                            totalMinutes === 12   ? 'dropdown-icon criar-evento doze-horas'             :
                            totalMinutes === 12.5 ? 'dropdown-icon criar-evento doze-horas-meia'        :
                            totalMinutes === 13   ? 'dropdown-icon criar-evento treze-horas'            :
                            totalMinutes === 13.5 ? 'dropdown-icon criar-evento treze-horas-meia'       :
                            totalMinutes === 14   ? 'dropdown-icon criar-evento catorze-horas'          :
                            totalMinutes === 14.5 ? 'dropdown-icon criar-evento catorze-horas-meia'     :
                            totalMinutes === 15   ? 'dropdown-icon criar-evento quinze-horas'           :
                            totalMinutes === 15.5 ? 'dropdown-icon criar-evento quinze-horas-meia'      :
                            totalMinutes === 16   ? 'dropdown-icon criar-evento dezesseis-horas'        :
                            totalMinutes === 16.5 ? 'dropdown-icon criar-evento dezesseis-horas-meia'   :
                            totalMinutes === 17   ? 'dropdown-icon criar-evento dezessete-horas'        :
                            totalMinutes === 17.5 ? 'dropdown-icon criar-evento dezessete-horas-meia'   :
                            totalMinutes === 18   ? 'dropdown-icon criar-evento dezoito-horas'          :
                            totalMinutes === 18.5 ? 'dropdown-icon criar-evento dezoito-horas-meia'     :
                            totalMinutes === 19   ? 'dropdown-icon criar-evento dezenove-horas'         :
                            totalMinutes === 19.5 ? 'dropdown-icon criar-evento dezenove-horas-meia'    :
                            totalMinutes === 20   ? 'dropdown-icon criar-evento vinte-horas'            :
                            totalMinutes === 20.5 ? 'dropdown-icon criar-evento vinte-horas-meia'       :
                            totalMinutes === 21   ? 'dropdown-icon criar-evento vinteUm-horas'          :
                            totalMinutes === 21.5 ? 'dropdown-icon criar-evento vinteUm-horas-meia'     :
                            totalMinutes === 22   ? 'dropdown-icon criar-evento vinteDois-horas'        :
                            totalMinutes === 22.5 ? 'dropdown-icon criar-evento vinteDois-horas-meia'   :
                            totalMinutes === 23   ? 'dropdown-icon criar-evento vinteTreis-horas'       :
                            totalMinutes === 23.5 ? 'dropdown-icon criar-evento vinteTreis-horas-meia'  :
                            totalMinutes === 24   ? 'dropdown-icon criar-evento vinteQuatro-horas'      :
                            totalMinutes === 24.5 ? 'dropdown-icon criar-evento vinteQuatro-horas-meia' :
                            'dropdown-icon criar-evento trinta'}>
                                <Dropdown.Toggle className={
                                    eventCategory === 'reuniao'   ? 'notificacao-reuniao'    :
                                    eventCategory === 'devs'      ? 'notificacao-dev'        :
                                    eventCategory === 'comercial' ? 'notificacao-comercial'  :   'notificacao-reuniao' }>
                                    <div>
                                        <h3>{eventName}</h3>
                                        <span>{startTimeEvent} às {endTimeEvent}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <DropdownCalendar
                                eventCalendar={eventCalendar[ec]}
                                myChangeHanldlerEventcalendar={myChangeHanldlerEventcalendar}
                                eventCalendarUpdate={eventCalendarUpdate}
                                dataFormatadaParaTraco={dataFormatadaParaTraco}
                                hours={hours}
                                onClickeventCategory={onClickeventCategory}
                                collaboratorEventCalendar={collaborator}
                                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                                myChangeHandlerToAddCollaboratorToUpdate={myChangeHandlerToAddCollaboratorToUpdate}
                                collaboratorsToUpdate={collaboratorsToUpdate}
                                collaboratorFilterForThisEventCalendar={collaboratorFilterForThisEventCalendar}
                                loadFilterCollaboratorForThisEventCalendar={loadFilterCollaboratorForThisEventCalendar}
                                PesqCollaborator={PesqCollaborator}
                                collaborators={collaborators}
                                AddColl={AddColl}
                                campoPesquicaColl={campoPesquicaColl}
                                updateEventcalendar={updateEventcalendar}
                                />
                            </Dropdown>
                        </div>

                    )

                }

            }


        }


    }
    loadevents();

     ////////////////////////////////////////////////////////////////////////////
    /////////       FIM DE GET DE EVENTO DE SEGUNDA A DOMINGO         //////////
   ////////////////////////////////////////////////////////////////////////////



    ////////////////////////////////////////////////////////////////////////////
    /////////                  INICIO POST DE EVENTO                 //////////
   ////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ loadPostEventCalendar, setLoadPostEventCalendar ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [eventCalendarPost, setEventCalendarPost] = useState([]);

    // console.log(eventCalendarPost)

   // console.log(eventCalendarPost)

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ collaboratorBuscaPostEventCalendar, setCollaboratorBuscaPostEventCalendar ] = useState([]);



   async function loadBuscaCollaboratorPostEventCalendar(event) {

        const name = event.target.value;
        setPesqCollaborator(name)

        if(name === '') {
            setCollaboratorBuscaPostEventCalendar([]);
        }
        else {
            //setLoadCollaboratorFilterForThisSkill(true)
            await api.get(`/collaborator-new?name=${name}&role=user&role=manager&role=admin&status=Ativo`)
            .then(response => {

                const docs_a  = response.data[0];
                const arrInfo = response.data[1];

                let itensCopyCollaborator = []

                // => Se total de colaboradores forem menor que o limite
                if(arrInfo[0].total < arrInfo[0].limite) {

                    for(let dcs=0; dcs < arrInfo[0].total; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorBuscaPostEventCalendar(itensCopyCollaborator);
                    // setArrInfo(arrInfo[0]);

                }
                else {

                    for(let dcs=0; dcs < arrInfo[0].limite; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorBuscaPostEventCalendar(itensCopyCollaborator);
                    // setArrInfo(arrInfo[0]);

                }
                // => Final de => Se total de colaboradores forem menor que o limite

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }

    }




 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostHrIgual, setCalendarPostIgual] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostHrMenor, setCalendarPostMenor] = useState(false);

    async function myChangeHanldlerEvCalendarPost(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'eventDate_'){

            let eventDate = dataFormatadaParaTracoCalendar(val);
            setEventCalendarPost({ ...eventCalendarPost, [nam]: val, eventDate });

            setCalendarPostEventDate_(false)

        }
        else
        if(nam === 'eventCategory_') {

            let eventCategory = val;
            setEventCalendarPost({ ...eventCalendarPost, [nam]: val, eventCategory });
            setCalendarPostEventCategory(false)

        }
        else
        if(nam === 'startTimeEvent') {
            setEventCalendarPost({ ...eventCalendarPost, [nam]: val });
            let startTimeA = val.split(':');
            let endTimeA = eventCalendarPost.endTimeEvent !== undefined ? eventCalendarPost.endTimeEvent.split(':') : "";

            let HrA = startTimeA[0]
            let HrAInt = parseInt(HrA)
            let HrB = endTimeA[0]
            let HrBInt = parseInt(HrB)

            let MinutosA = startTimeA[1]
            let MinutosAInt = parseInt(MinutosA)
            let MinutosB = endTimeA[1]
            let MinutosBInt = parseInt(MinutosB)




            if (
                eventCalendarPost.endTimeEvent !== undefined
            ){

                if (HrAInt > HrBInt ) {
                    setCalendarPostIgual(true)
                    setCalendarPostMenor(true)
                    setCalendarPostEndTimeEvent(true)
                    setCalendarPostStartTimeEvent(true)
                } else
                if (HrAInt === HrBInt ){

                    if( MinutosAInt > MinutosBInt) {
                        setCalendarPostIgual(true)
                        setCalendarPostMenor(true)
                        setCalendarPostEndTimeEvent(true)
                        setCalendarPostStartTimeEvent(true)
                        console.log("=== >")
                    }else if( MinutosAInt === MinutosBInt){
                        setCalendarPostIgual(true)
                        setCalendarPostMenor(false)
                        setCalendarPostEndTimeEvent(true)
                        setCalendarPostStartTimeEvent(true)
                    }
                    else {
                        setCalendarPostIgual(false)
                        setCalendarPostMenor(false)
                        setCalendarPostEndTimeEvent(false)
                        setCalendarPostStartTimeEvent(false)
                    }
                }
                else {
                    setCalendarPostIgual(false)
                    setCalendarPostEndTimeEvent(false)
                    setCalendarPostStartTimeEvent(false)

                }

            }
        }
        else
        if(nam === 'endTimeEvent') {
            setEventCalendarPost({ ...eventCalendarPost, [nam]: val });
            let startTimeA = eventCalendarPost.startTimeEvent !== undefined ? eventCalendarPost.startTimeEvent.split(':') : "" ;
            let endTimeA = val.split(':');



            let HrA = startTimeA[0]
            let HrAInt = parseInt(HrA)
            let HrB = endTimeA[0]
            let HrBInt = parseInt(HrB)

            let MinutosA = startTimeA[1]
            let MinutosAInt = parseInt(MinutosA)
            let MinutosB = endTimeA[1]
            let MinutosBInt = parseInt(MinutosB)

            if (
                eventCalendarPost.startTimeEvent !== undefined
            ){
                if (HrAInt > HrBInt ) {
                    setCalendarPostIgual(true)
                    setCalendarPostMenor(true)
                    setCalendarPostEndTimeEvent(true)
                    setCalendarPostStartTimeEvent(true)
                } else
                if (HrAInt === HrBInt ){

                    if( MinutosAInt > MinutosBInt) {
                        setCalendarPostIgual(true)
                        setCalendarPostMenor(true)
                        setCalendarPostEndTimeEvent(true)
                        setCalendarPostStartTimeEvent(true)
                        console.log("=== >")
                    }else if( MinutosAInt === MinutosBInt){
                        setCalendarPostIgual(true)
                        setCalendarPostMenor(false)
                        setCalendarPostEndTimeEvent(true)
                        setCalendarPostStartTimeEvent(true)
                    }
                    else {
                        setCalendarPostIgual(false)
                        setCalendarPostMenor(false)
                        setCalendarPostEndTimeEvent(false)
                        setCalendarPostStartTimeEvent(false)
                    }
                }
                else {
                    setCalendarPostIgual(false)
                    setCalendarPostEndTimeEvent(false)
                    setCalendarPostStartTimeEvent(false)

                }
            }
        }
        else {

            setEventCalendarPost({ ...eventCalendarPost, [nam]: val });

        }


    }

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostEventName, setCalendarPostEventName] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostEventDate_, setCalendarPostEventDate_] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostStartTimeEvent, setCalendarPostStartTimeEvent] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostEndTimeEvent, setCalendarPostEndTimeEvent] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [calendarPostEventCategory, setCalendarPostEventCategory] = useState(false);





    async function saveEventcalendar() {

        if(eventCalendarPost.length !== 0) {


            if(
                (eventCalendarPost.eventName      !== undefined &&
                eventCalendarPost.eventName       !==    ''   )
                                                                &&
                (eventCalendarPost.startTimeEvent !== undefined &&
                eventCalendarPost.startTimeEvent  !==    ''   )
                                                                &&
                (eventCalendarPost.endTimeEvent   !== undefined &&
                eventCalendarPost.endTimeEvent    !==    ''   )
                                                                &&
                (eventCalendarPost.eventDate      !== undefined &&
                eventCalendarPost.eventDate       !==    ''   )
                                                                &&
                (eventCalendarPost.eventCategory  !== undefined &&
                eventCalendarPost.eventCategory   !==    ''   )
                                                                &&
                collaborators.length              !==     0
            ) {

                let arrayPostEventCollaborator = [
                    {
                        eventName      : eventCalendarPost.eventName,
                        startTimeEvent : eventCalendarPost.startTimeEvent,
                        endTimeEvent   : eventCalendarPost.endTimeEvent,
                        eventDate      : eventCalendarPost.eventDate,
                        eventCategory  : eventCalendarPost.eventCategory,
                        collaborator   : collaborators,
                    }
                ]

                await api.post('/events-calendar/', arrayPostEventCollaborator[0])
                .then(response => {



                    let eventDateCreated = response.data.eventDate.split('/')[0]
                    // console.log('eventDateCreated')
                    // console.log(eventDateCreated)

                    let segundaAtual = segunda.split('.')[0]
                    let tercaAtual   = terca.split('.')[0]
                    let quartaAtual  = quarta.split('.')[0]
                    let quintaAtual  = quinta.split('.')[0]
                    let sextaAtual   = sexta.split('.')[0]
                    let sabadoAtual  = sabado.split('.')[0]
                    let domingoAtual = domingo.split('.')[0]

                    let startTime = response.data.startTimeEvent.split(':')[0]

                    let eventDateSplit = response.data.eventDate.split('/');

                    let eventDate      = eventDateSplit[2]+"-"+eventDateSplit[1]+"-01";

                    // let dayWeekFormated = eventCalendar[ec].dayWeekFormated;


                    if(
                        eventDateCreated === segundaAtual ||
                        eventDateCreated === tercaAtual   ||
                        eventDateCreated === quartaAtual  ||
                        eventDateCreated === quintaAtual  ||
                        eventDateCreated === sextaAtual   ||
                        eventDateCreated === sabadoAtual  ||
                        eventDateCreated === domingoAtual
                    ) {

                        // console.log('startTime')
                        // console.log(startTime)
                        setCurrentHour(startTime)


                    }


                    setCalendarPostEventName(false)
                    loadcounteventsmonthintheyear()

                    async function loadcoun35days_a() {

                        await api.get(`/events-calendar-count-35-days/?initialDateToString=${eventDate}`)
                        .then(response => {

                            setCout35Days(response.data);

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    loadcoun35days_a()
                    setCollaborators([]);
                    setEventCalendarPost([]);
                    loadeventscalendar();


                }).catch(error => {
                    // console.log(error.response.data.message)
                    console.log(error)
                    if(error.response.data.message) {
                        swal({ icon: "warning", title: "Alerta!", text: error.response.data.message });
                    }

                })

            }
            else
            if(
                (eventCalendarPost.eventName      !== undefined &&
                eventCalendarPost.eventName       !==    ''   )
                                                                &&
                (eventCalendarPost.startTimeEvent !== undefined &&
                eventCalendarPost.startTimeEvent  !==    ''   )
                                                                &&
                (eventCalendarPost.endTimeEvent   !== undefined &&
                eventCalendarPost.endTimeEvent    !==    ''   )
                                                                &&
                (eventCalendarPost.eventDate      !== undefined &&
                eventCalendarPost.eventDate       !==    ''   )
                                                                &&
                (eventCalendarPost.eventCategory  !== undefined &&
                eventCalendarPost.eventCategory   !==    ''   )
            ) {

                let arrayPostEventCollaborator = [
                    {
                        eventName      : eventCalendarPost.eventName,
                        startTimeEvent : eventCalendarPost.startTimeEvent,
                        endTimeEvent   : eventCalendarPost.endTimeEvent,
                        eventDate      : eventCalendarPost.eventDate,
                        eventCategory  : eventCalendarPost.eventCategory
                    }
                ]

                // console.log(arrayPostEventCollaborator)

                await api.post('/events-calendar/', arrayPostEventCollaborator[0])
                .then(response => {

                    setCollaborators([]);
                    loadeventscalendar();
                    setEventCalendarPost([]);
                    setCalendarPostEventName(false)

                    async function loadsetevents() {

                        await api.get(`/events-calendar-count-35-days/?initialDateToString=${response.data.eventDate}`)
                        .then(response => {

                            setCout35Days(response.data);

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                        await api.get('/events-calendar-count-per-month-in-the-year/')
                        .then(response => {

                            setCoutMonthInTheYear(response.data);

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                        await api.get('/events-calendar/')
                        .then(response => {

                            setEventCalendar(response.data);


                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });


                    }
                    loadsetevents()



                }).catch(error => {})

            }
            else {
                if(eventCalendarPost.eventName === undefined ) {
                    setCalendarPostEventName(true)
                }


                if(eventCalendarPost.eventDate  === undefined ) {
                    setCalendarPostEventDate_(true)
                }


                if(eventCalendarPost.startTimeEvent  === undefined ) {
                    setCalendarPostStartTimeEvent(true)
                }


                if(eventCalendarPost.endTimeEvent  === undefined ) {
                    setCalendarPostEndTimeEvent(true)
                }


                if(eventCalendarPost.eventCategory_  === undefined ) {
                    setCalendarPostEventCategory(true)
                }

                // swal({ icon: "warning", title: "Alerta!", text: "Você deve preencher todos os dados necessários para criar um evento!" });

            }

        }
        else {
            swal({ icon: "warning", title: "Alerta!", text: "Necessário preencher todos os campos!" });

        }

    }


    ////////////////////////////////////////////////////////////////////////////
    /////////                    FIM POST DE EVENTO                  //////////
   ////////////////////////////////////////////////////////////////////////////

    // console.log(eventCalendarPost)
    return (
        <>
        <div className="calendario-eventos">
            <div className="header">
                <div className="infor">
                    <h4>Calendário</h4>
                    <div className="menu-ano">
                        <div className="menu">
                            <button type="button" className={filtroSemana ? "semana selecionado" : "semana"}  onClick={() => abrirFiltroSemana()}>Semana</button>
                            <button type="button" className={filtroMes ? "semana selecionado" : "semana"}  onClick={() => abrirFiltroMes()}>Mês</button>
                            <button type="button" className={filtroAno ? "semana selecionado" : "semana"}  onClick={() => abrirFiltroAno()}>Ano</button>

                        </div>
                    <p className="ano">{currentMonth} {currentYear}</p>
                    </div>
                </div>
                <div className="filtro">

                    <Dropdown  className="dropdown-icon ">
                        <Dropdown.Toggle>
                        Filtrar <img alt="" src={iconV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="chekboxText comercial">
                                <input onClick={loadeventscalendarcategory} type="checkbox" name="comercial" />
                                <label className="foreignTexto">Comercial</label>
                            </div>
                            <div className="chekboxText dev">
                                <input onClick={loadeventscalendarcategory} type="checkbox" name="devs" />
                                <label className="foreignTexto">Devs</label>
                            </div>
                            <div className="chekboxText reuniao">
                                <input onClick={loadeventscalendarcategory} type="checkbox" name="reuniao" />
                                <label className="foreignTexto">Reunião</label>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="btn-criar-evento">
                    {/* <button className="btnGreen more">Criar evento</button> */}
                    <Dropdown  className="dropdown-icon criar-evento">
                        <Dropdown.Toggle className="btnGreen more">
                        Criar evento
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="formulario">

                                {
                                    eventCalendarPost.eventName ?
                                    <input
                                        type="text"
                                        placeholder="Escreva titulo"
                                        name="eventName"
                                        onChange={myChangeHanldlerEvCalendarPost}
                                        className={calendarPostEventName && calendarPostEventName ? "inputErro titulo" : "titulo"}
                                    />

                                    :

                                    <input
                                        type="text"
                                        placeholder="Escreva titulo"
                                        name="eventName"
                                        value=""
                                        onChange={myChangeHanldlerEvCalendarPost}
                                        className={calendarPostEventName && calendarPostEventName ? "inputErro titulo" : " titulo"}
                                    />
                                }
                                {
                                    eventCalendarPost.eventDate_ ?
                                    <input
                                        type="date"
                                        name="eventDate_"
                                        placeholder="Sexta, 19 Março"
                                        required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                        onChange={myChangeHanldlerEvCalendarPost}
                                        className={calendarPostEventDate_ ?  "inputErro data" : " data"}
                                    />

                                    :
                                    <input
                                        type="date"
                                        name="eventDate_"
                                        placeholder="Sexta, 19 Março"
                                        className={calendarPostEventDate_ ?  "inputErro data" : " data"}
                                        value=""
                                        required pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                        onChange={myChangeHanldlerEvCalendarPost}
                                    />
                                }

                                <div className="horas">
                                    {
                                        eventCalendarPost.startTimeEvent ?
                                        <select
                                            name="startTimeEvent"
                                            onChange={myChangeHanldlerEvCalendarPost}
                                            className={
                                                calendarPostStartTimeEvent ?
                                                "error-hr" : ""
                                            }
                                        >
                                        {
                                            hours
                                            .map((item, index) => (
                                                <option value={item} key={index}>{item}</option>
                                            ))
                                        }
                                        </select>
                                        :
                                        <select
                                            name="startTimeEvent"
                                            value=""
                                            onChange={myChangeHanldlerEvCalendarPost}
                                            className={
                                                calendarPostStartTimeEvent ?
                                                "error-hr" : ""
                                            }
                                        >
                                        {
                                            hours
                                            .map((item, index) => (
                                                <option value={item} key={index}>{item}</option>
                                            ))
                                        }
                                        </select>
                                    }


                                    {
                                        eventCalendarPost.endTimeEvent ?
                                            <select
                                                name="endTimeEvent"
                                                onChange={myChangeHanldlerEvCalendarPost}
                                                className={
                                                    calendarPostEndTimeEvent ?
                                                    "error-hr" : ""
                                                }
                                            >
                                            {
                                                hours
                                                .map((item, index) => (
                                                    <option value={item} key={index}>{item}</option>
                                                ))
                                            }
                                            </select>
                                            :
                                            <select
                                                name="endTimeEvent"
                                                value=""
                                                onChange={myChangeHanldlerEvCalendarPost}
                                                className={
                                                    calendarPostEndTimeEvent ?
                                                    "error-hr" : ""
                                                }
                                            >
                                            {
                                                hours
                                                .map((item, index) => (
                                                    <option value={item} key={index}>{item}</option>
                                                ))
                                            }
                                            </select>
                                    }
                                </div>
                                {
                                    calendarPostHrIgual !== false ?

                                        <div className='hr-nao-pode-igual'>
                                            <p>
                                                {calendarPostHrMenor !== false ?
                                                "Hora inicial não pode ser maior que a final!"
                                                :
                                                "Hora inicial não pode ser igual a final!"
                                            }

                                            </p>
                                        </div>
                                    :
                                    ""
                                }


                                <div className="tipo-evento">
                                    <button
                                        name="eventCategory_"
                                        id="comercial"
                                        value="comercial"
                                        onClick={myChangeHanldlerEvCalendarPost}
                                        className={
                                            eventCalendarPost &&
                                            eventCalendarPost.eventCategory_ === "comercial" ?
                                            "comercial selecionado" : "comercial"
                                        }
                                    >Comercial</button>

                                    <button
                                        name="eventCategory_"
                                        id="devs"
                                        value="devs"
                                        onClick={myChangeHanldlerEvCalendarPost}
                                        className={
                                            eventCalendarPost &&
                                            eventCalendarPost.eventCategory_ === "devs" ?
                                            "devs selecionado" : "devs"
                                        }
                                    >Devs</button>
                                    <button
                                        name="eventCategory_"
                                        id="reuniao"
                                        value="reuniao"
                                        onClick={myChangeHanldlerEvCalendarPost}
                                        className={
                                            eventCalendarPost &&
                                            eventCalendarPost.eventCategory_ === "reuniao" ?
                                            "reuniao selecionado" : "reuniao"
                                        }
                                    >Reunião</button>
                                </div>
                                {
                                    calendarPostEventCategory ?

                                    <div className='hr-nao-pode-igual'>
                                        <p>Necessário escolher categoria. </p>
                                    </div>

                                    :
                                    ""
                                }
                                {
                                    campoPesquicaColl ?
                                    <div className="buasca-colaborador">
                                        <input type="text" name="name" value={PesqCollaborator} placeholder="Pesquisar colaborador" onChange={loadBuscaCollaboratorPostEventCalendar} />
                                        {
                                            collaboratorBuscaPostEventCalendar &&
                                            collaboratorBuscaPostEventCalendar.length !== 0 ?
                                            <div className="resultado-busca-atrelados-col">
                                                {
                                                    collaboratorBuscaPostEventCalendar.map((collaboratorforthisevent, index) => (
                                                        <button id={collaboratorforthisevent._id} key={index} onClick={myChangeHandlerToAddCollaborator}>
                                                            {
                                                                collaboratorforthisevent.avatar !== "undefined" ?
                                                                <div className="img-col">
                                                                    <img id={collaboratorforthisevent._id} src={collaboratorforthisevent.avatar} alt={collaboratorforthisevent.name} />
                                                                </div>
                                                                :
                                                                <div className="colSemFoto">
                                                                    <div className="semImgCol">
                                                                        <img id={collaboratorforthisevent._id} src={IconUserNone} alt="Usuario sem imagem" />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <span id={collaboratorforthisevent._id}>{collaboratorforthisevent.name}</span>
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                    :
                                    ''
                                }
                                <div className="col-add">
                                    <div className="adicionados">
                                    {
                                        collaborators &&
                                        collaborators.length !== 0 ?
                                        collaborators.map((collaboratoradded, index) => (
                                            collaboratoradded &&
                                            collaboratoradded.avatar && collaboratoradded.avatar !== "undefined" && collaboratoradded.avatar !== undefined ?
                                            <div key={index} className="img-col">
                                                <img src={collaboratoradded.avatar} alt="nome" />
                                            </div>
                                            :
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        ))
                                        :
                                        ''
                                    }
                                    </div>
                                    <div className="add-button">
                                        <button onClick={() => AddColl()}><img src={IconAddCol} alt="" /></button>
                                    </div>

                                </div>

                                {
                                    calendarPostHrIgual !== false  ?
                                        <button
                                            disabled
                                            className="salvar btnGreen"
                                        >
                                            Salvar evento
                                        </button>
                                    :
                                        <button
                                            onClick={saveEventcalendar}
                                            className="salvar btnGreen"
                                        >
                                            Salvar evento
                                        </button>
                                }
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            {
                filtroAno ? (
                <div className="box-calendar-mes">
                    {
                        coutMonthInTheYear && coutMonthInTheYear.length !== 0 ?
                        coutMonthInTheYear.map( (countMonyhYear, index3) => (
                            <button
                            key={index3}
                            onClick={() => loadCoutMonthInTheYearInitialDateToString(countMonyhYear.initialDateToString)}
                            >{countMonyhYear.monthToString}<div><span>{
                                countMonyhYear.countEvents === 0 ?
                                'nenhum'
                                :
                                countMonyhYear.countEvents === 1 ?
                                countMonyhYear.countEvents+' agendamento'
                                :
                                countMonyhYear.countEvents+' agendamentos'
                                }</span></div>
                            </button>
                        ))
                        :
                        ''

                    }
                </div>
            ) : ('')}

            {
                filtroMes ? (


                <div className="box-calendar-ano">
                    <div className="cabecalho">
                        <div className="dia">Segunda</div>
                        <div className="dia bloco-2">Terça</div>
                        <div className="dia bloco-2">Quarta</div>
                        <div className="dia bloco-2">Quinta</div>
                        <div className="dia bloco-2">Sexta</div>
                        <div className="dia bloco-2">Sabado</div>
                        <div className="dia bloco-2">domingo</div>

                    </div>

                    <div className="box-dia">


                        {
                            cout35Days && cout35Days ?
                            cout35Days.map(( countevents, index) => (

                                countevents.countEvents !== 0 ?
                                <>
                                <div
                                key={index}
                                className="dia"
                                >
                                <span
                                className={countevents.classDay === "monthCurrent" ? "dia-mes" : "dia-outro-mes"}>
                                {countevents.day}
                                </span>
                                <Dropdown  className="dropdown-icon notificacao-dopdown">
                                        <Dropdown.Toggle className="notificacao-reuniao">
                                        <span>{
                                        countevents.countEvents === 1 ?
                                        countevents.countEvents+ ' agendamento'
                                        :
                                        countevents.countEvents+ ' agendamentos'
                                        } </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="formulario">
                                                {
                                                    countevents.events.length > 0 ?
                                                    countevents.events.map((event, index2) => (
                                                        <>
                                                        <div key={index2} className={
                                                            event.eventCategory === 'comercial' ?
                                                            "notificacao laranjada"
                                                            :
                                                            event.eventCategory === 'devs' ?
                                                            "notificacao azul"
                                                            :
                                                            event.eventCategory === 'reuniao' ?
                                                            "notificacao verde"
                                                            :
                                                            ''
                                                            }>
                                                            <h3><span></span> {event.eventName}</h3>
                                                            <p><span>{event.startTimeEvent}</span>
                                                            <button
                                                            onClick={() => getDateWeekTheMonth(event.eventDate, event.startTimeEvent)}
                                                            >Ver detalhes</button></p>
                                                        </div>
                                                        </>
                                                    )):''
                                                }
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                                </>
                                :
                                <div className="dia "><span className={countevents.classDay === "monthCurrent" ? "dia-mes" : "dia-outro-mes"}>{countevents.day}</span></div>

                            )): ''
                        }
                    </div>
                </div>
            ) : ('')}


            {
                filtroSemana ? (
                <div className="box-calendar-seman">


                    <div className="header">
                        <div className="bloco-um"><img src={imgCalendar} alt="" /></div>

                        <div className="bloco-dois">

                            <h1>Segunda</h1>
                            <strong>{segunda}</strong>
                        </div>
                        <div className="bloco-dois">
                            <h1>Terça</h1>
                            <strong>{terca}</strong>
                        </div>
                        <div className="bloco-dois">
                            <h1>Quarta</h1>
                            <strong>{quarta}</strong>
                        </div>
                        <div className="bloco-dois">
                            <h1>Quinta</h1>
                            <strong>{quinta}</strong>
                        </div>
                        <div className="bloco-dois">
                            <h1>Sexta</h1>
                            <strong>{sexta}</strong>
                        </div>
                        <div className="bloco-dois">
                            <h1>Sábado</h1>
                            <strong>{sabado}</strong>
                        </div>
                        <div className="bloco-dois">
                            <h1>Domingo</h1>
                            <strong>{domingo}</strong>
                        </div>
                    </div>
                    <div className="corpo-novo-container">

                    <Scrollbars
                    // style={{  height: 335 }}
                    >
                    <div className="corpo-novo">
                        <div className="bloco-hora">
                            <div className="hora">
                                00:00
                            </div>
                            <div className="hora">
                                01:00
                                {/* {
                                   currentHour === 0
                                   ||
                                   currentHour === '01' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                } */}
                            </div>
                            <div className="hora">
                                02:00
                                {/* {
                                   currentHour === 0
                                   ||
                                   currentHour === '02' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                } */}
                            </div>
                            <div className="hora">
                                03:00
                                {/* {
                                   currentHour === 0
                                   ||
                                   currentHour === '03' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                } */}
                            </div>
                            <div className="hora">
                                04:00
                                {
                                   currentHour === 0
                                   ||
                                   currentHour === '00' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                05:00
                                {
                                   currentHour === 1
                                   ||
                                   currentHour === '01' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                06:00
                                {
                                   currentHour === 2
                                   ||
                                   currentHour === '02' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                07:00
                                {
                                   currentHour === 3
                                   ||
                                   currentHour === '03' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                08:00
                                {
                                   currentHour === 4
                                   ||
                                   currentHour === '04' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                09:00
                                {
                                   currentHour === 5
                                   ||
                                   currentHour === '05' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                10:00
                                {
                                   currentHour === 6
                                   ||
                                   currentHour === '06' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                11:00
                                {
                                   currentHour === 7
                                   ||
                                   currentHour === '07' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                12:00
                                {
                                   currentHour === 8
                                   ||
                                   currentHour === '08' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                13:00
                                {
                                   currentHour === 9
                                   ||
                                   currentHour === '09' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                14:00
                                {
                                   currentHour === 10
                                   ||
                                   currentHour === '10' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                15:00
                                {
                                    currentHour === 11
                                    ||
                                    currentHour === '11' ?
                                    <NavLink
                                    to="/"
                                    ref={focusEvent}
                                    >
                                    </NavLink>
                                    :
                                    ''

                                }
                            </div>
                            <div className="hora">
                                16:00
                                {
                                   currentHour === 12
                                   ||
                                   currentHour === '12' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                17:00
                                {
                                   currentHour === 13
                                   ||
                                   currentHour === '13' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                18:00
                                {

                                   currentHour === 14
                                   ||
                                   currentHour === '14' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                19:00
                                {
                                   currentHour === 15
                                   ||
                                   currentHour === '15' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                20:00
                                {
                                   currentHour === 16
                                   ||
                                   currentHour === '16' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                21:00
                                {
                                   currentHour === 17
                                   ||
                                   currentHour === '17' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                22:00
                                {
                                   currentHour === 18
                                   ||
                                   currentHour === '18' ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>
                            <div className="hora">
                                23:00
                                {
                                    currentHour === 19
                                    ||
                                    currentHour === '19'
                                    ||
                                    currentHour === 20
                                    ||
                                    currentHour === '20'
                                    ||
                                    currentHour === 21
                                    ||
                                    currentHour === '21'
                                    ||
                                    currentHour === 22
                                    ||
                                    currentHour === '22'
                                    ||
                                    currentHour === 23
                                    ||
                                    currentHour === '23'
                                   ?
                                   <NavLink
                                   to="/"
                                   ref={focusEvent}
                                   >
                                   </NavLink>
                                   :
                                   ''

                                }
                            </div>

                        </div>

                        {/* SEGUNDA */}
                        <div className="bloco-dia">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events1
                            }

                        </div>
                        {/* TERÇA */}
                        <div className="bloco-dia">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events2
                            }

                        </div>
                        {/* QUARTA */}
                        <div className="bloco-dia">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events3
                            }


                        </div>
                        {/* QUINTA */}
                        <div className="bloco-dia">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events4
                            }

                        </div>
                        {/* SEXTA */}
                        <div className="bloco-dia">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events5
                            }

                        </div>
                        {/* SÁBADO */}
                        <div className="bloco-dia bloco-dia-penutimo">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events6
                            }

                        </div>
                        {/* DOMINGO */}
                        <div className="bloco-dia-ultimo">

                            <div className="linha">
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div><div></div>
                                <div></div><div></div><div></div>
                            </div>
                            {
                                events7
                            }

                        </div>

                    </div>
                    </Scrollbars>


                    </div>

                </div>
                    ) : ('')}
            </div>
        </>
    );

}
