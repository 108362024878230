import React, {
    //  useState, useEffect 
} from 'react';
import { Dropdown } from "react-bootstrap";
import IconV from "../../../assets/images/v.svg";

export default function boxInfor(props) {




        return (
            <>
                    <div className='box-infs'>
                        <ul>
                            <li className='box box-leiaute'>
                                <strong>Realizados
                                    <Dropdown  className="dropdown-icon ">
                                        <Dropdown.Toggle>
                                            <span>Nos últimos 7 dias</span>
                                             <img src={IconV} alt="icone v" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button>Nos últimos 7 dias</button>
                                            <button>Nos últimos 15 dias</button>
                                            <button>No último mês</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </strong>
                                <p><strong>897</strong> <span className='accomplished'>+ 100%</span> </p>
                            </li>
                            <li className='box box-leiaute'>
                                <strong>Agendados
                                    <Dropdown  className="dropdown-icon ">
                                        <Dropdown.Toggle>
                                            <span>Nos últimos 7 dias</span>
                                             <img src={IconV} alt="icone v" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button>Nos últimos 7 dias</button>
                                            <button>Nos últimos 15 dias</button>
                                            <button>No último mês</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </strong>
                                <p><strong>897</strong> <span className='Scheduled'>+ 100%</span> </p>
                            </li>
                            <li className='box box-leiaute'>
                                <strong>Cancelados
                                    <Dropdown  className="dropdown-icon ">
                                        <Dropdown.Toggle>
                                            <span>Nos últimos 7 dias</span>
                                             <img src={IconV} alt="icone v" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button>Nos últimos 7 dias</button>
                                            <button>Nos últimos 15 dias</button>
                                            <button>No último mês</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </strong>
                                <p><strong>897</strong> <span className='canceled'>+ 100%</span> </p>
                            </li>
                        </ul>
                    </div>


                              
            </>
        );
}

