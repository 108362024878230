import axios from 'axios';
import { getToken } from './auth';

require('dotenv').config();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,
});

const errorHandler = (error) => {
    if (error.response) {
      if (error.response.status === 409) {
        return Promise.reject(error);
      }
      else if (error.response.status >= 400 && error.response.status <= 500) {
        return Promise.reject(error.response.data);
      }
    }
    return Promise.reject(error);
  };

api.interceptors.request.use(async (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => errorHandler(error)
  );

export default api;
