import React,{useState} from 'react';
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Carregando from '../../../../../../assets/images/carregando.gif';
import Close from '../../../../../../assets/images/close.svg';
import Dropzone from "react-dropzone";
import { Modal} from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import {removerSpecials} from '../../../../../../services/utils'
import SelectPersonalizado from '../../../../../../components/Reused/selectPersonalizado'

const ColProfileCNH = ({
    id,
    collaborator,
    loadPage,
    onCollaboratorInputChange,
    collaboratorUpdate ,
    OnSubmit,
    loadContinue,
    EditCNHVisible,
    visibleCNH,
    filesSavedCNH,
    loadCNH,
    deleteFileCNH,
    setCollaboratorUpdate,
    estadosOrgam,
    handleDropCNH
}) => {
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ufCNHStringBusca , setufCNHStringBusca] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        
        if(nam === "ufCNHBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setufCNHStringBusca({ ...ufCNHStringBusca, [nam]: valOption });
        }else if(
            nam === "ufCNH"
        ) {
            setCollaboratorUpdate({ ...collaboratorUpdate,  "ufCNH": val })
            setufCNHStringBusca({ ...ufCNHStringBusca, "ufCNHBusca": val });

        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = estadosOrgam.filter((valOption) => {
    return removerSpecials(valOption).toLowerCase().includes(ufCNHStringBusca?.ufCNHBusca !== undefined ? ufCNHStringBusca.ufCNHBusca : "" )  ;
    });



    return (
        <>

                <div className="box cnh">
                    <h3>CNH         
                        {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? 
                            "" 
                            :  
                            <button className="edit-Benefit" onClick={() => EditCNHVisible()}><img src={EditBlack} alt="Edite Informações" /></button>      
                        }
                    </h3>
                    {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregando} />
                                </div>
                                </>
                            ):(
                                <>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Número CNH</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.cnh ? collaborator.cnh : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Categoria</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.cnhCategory ? collaborator.cnhCategory : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data de expedição</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.cnhExpeditionDate ? collaborator.cnhExpeditionDate : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>UF da carteira</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.ufCNH ? collaborator.ufCNH : '-'}</p>
                            </div>
                        </li>


                        {filesSavedCNH[0] !== undefined ? (
                        <li className="li-anexo">
                            <div className="intemA">
                                <p>Anexo</p>
                            </div>



                        <div className="intemB arq-adicionado" >
                            {filesSavedCNH[0] !== undefined ?
                            filesSavedCNH.map((filesaved, index) => (
                                        <a href={filesaved.location} target="’_blank’" key={index}>
                                            {filesaved.name}
                                        </a>
                            )) : (
                                <>
                                <p>-</p>
                                </>
                            )}


                        </div>
                        </li>
                        ) : ('')}
                    </ul>
                    </>)}
                </div>
                <Modal show={visibleCNH} className="popup-edit-perfil popup-edit-cpf" onHide={EditCNHVisible}>
                <div>
                    <div className="titulo">
                        <h4>CNH <p className="btn-fechar" onClick={EditCNHVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Número CNH</p>
                            </div>
                            <div className="intemB">
                                {
                                (collaboratorUpdate && collaboratorUpdate.cnh !== undefined) || collaboratorUpdate.cnh === '' ?
                                (
                                    <input type="text"  name="cnh" value={(collaboratorUpdate.cnh !== undefined) ? collaboratorUpdate.cnh : ''}  onChange={onCollaboratorInputChange} placeholder="Número CNH"  />
                                ) :
                                collaborator && collaborator.cnh ? (
                                    <input type="text"  name="cnh" defaultValue={(collaborator.cnh !== undefined) ? collaborator.cnh : ''}  onChange={onCollaboratorInputChange} placeholder="Número CNH"/>
                                ):
                                (
                                    <input type="text"  name="cnh" value={(collaboratorUpdate.cnh !== undefined) ? collaboratorUpdate.cnh : ''}  onChange={onCollaboratorInputChange} placeholder="Número CNH"  />
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Categoria</p>
                            </div>
                            <div className="intemB">
                            <div className='cont-select'>
                                <button className='select-'>
                                    <p>
                                        {
                                            collaboratorUpdate && collaboratorUpdate.cnhCategory !== undefined ?
                                            collaboratorUpdate.cnhCategory :
                                            collaborator && collaborator.cnhCategory !== undefined ? 
                                            collaborator.cnhCategory : "Selecione"  
                                        }
                                    </p>
                                </button>
                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 130 }}>
                                        {[
                                            "A",
                                            "AB",
                                            "AC",
                                            "ACC",
                                            "AD",
                                            "AE",
                                            "B",
                                            "C",
                                            "D",
                                            "E"
                                        ].map((item, index) => (
                                            <button 
                                                key={index} 
                                                className='item' 
                                                name="name"
                                                onClick={() => setCollaboratorUpdate({ ...collaboratorUpdate, "cnhCategory": item })}
                                            >
                                                <p >{item}</p>
                                            </button>
                                        ))}
                                    </Scrollbars>
                                </div>
                            </div>



                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data de expedição</p>
                            </div>
                            <div className="intemB">


                                {
                                (collaboratorUpdate && collaboratorUpdate.cnhExpeditionDate !== undefined) || collaboratorUpdate.cnhExpeditionDate === '' ?
                                (
                                    <input type="text"  name="cnhExpeditionDate" value={(collaboratorUpdate.cnhExpeditionDate !== undefined) ? collaboratorUpdate.cnhExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  />
                                ) :
                                collaborator && collaborator.cnhExpeditionDate ? (
                                    <input type="text"  name="cnhExpeditionDate" defaultValue={(collaborator.cnhExpeditionDate !== undefined) ? collaborator.cnhExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"/>
                                ):
                                (
                                    <input type="text"  name="cnhExpeditionDate" value={(collaboratorUpdate.cnhExpeditionDate !== undefined) ? collaboratorUpdate.cnhExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  />
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>UF da carteira</p>
                            </div>
                            <div className="intemB">

                                <SelectPersonalizado
                                    valorCampo={
                                        ufCNHStringBusca && ufCNHStringBusca.ufCNHBusca ?
                                        ufCNHStringBusca.ufCNHBusca :
                                        ufCNHStringBusca && ufCNHStringBusca.ufCNHBusca === '' ?
                                        '' :
                                        collaborator && collaborator.ufCNH ?
                                            collaborator.ufCNH
                                            :
                                            ''
                                    }
                                    nameCampoBusca={"ufCNHBusca"}
                                    nameButton={"ufCNH"}
                                    myChangeHandler={myChangeHandlerSlects}
                                    option={optionFiltreUCidade}
                                    typeSelect={"busca"}
                                />
                            </div>
                        </li>

                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div className="intemB">
                                    <Dropzone onDrop={handleDropCNH} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                            </div>
                        </li>
                        {filesSavedCNH[0]  !== undefined ? (
                        <li>
                            <div className="arquivoAnexado">
                                <h5>Enviados</h5>

                                {filesSavedCNH[0] !== undefined ?
                                filesSavedCNH.map((filesaved, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                                        </p>
                                        <button onClick={()=>deleteFileCNH(filesaved)} className="btnExclui">Remover anexo</button>
                                    </div>
                                )) : ''}

                            </div>
                        </li>
                                ) : ''}
                        {loadCNH ?
                        ( <li>
                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                        </li>) : ('')}
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={OnSubmit} className="btn-salvar">
                                    <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>


                    </ul>


                </div>
            </Modal>

        </>
    );
}
export default ColProfileCNH;
