import React, {
    useState,
    useEffect
} from "react"
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import SubHeaderCustomizePage from '../../components/RecruitmentAndSelection/subHeaderCustomizePage';
import {removerSpecialsUrl} from '../../services/utils';
import api from '../../services/api';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

export default function SettingsCustomizePage(props) {
    const { path } = props.match;



    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadSettingsCustomizePageState()
    }, []);   
     // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ settingsCustomizeState , setSettingsCustomizeState] = useState({});

    async function loadSettingsCustomizePageState() {

        await api.get('/vacancie-page-appearance-config')
        .then(response => {
            if(response.data !== null){
                setSettingsCustomizeState(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadNameUrlPage, setLoadNameUrlPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ SettingsCustomize , setSettingsCustomize] = useState([]);

    const myChangeHandler = event =>{

        let nam = event.target.name;
        let val = event.target.value;

        if(nam === "subdomain"){

            setLoadNameUrlPage(false)
            let ValorAlterado = removerSpecialsUrl(val)
            setSettingsCustomize({ ...SettingsCustomize, [nam]: ValorAlterado})
    
        }else {

            setSettingsCustomize({ ...SettingsCustomize, [nam]: val})

        }
    }

    async function AtictivePage(){

        if(
            SettingsCustomize && SettingsCustomize.status  !== undefined 
        ) {
            if(
                SettingsCustomize?.status !== "active"
            ){
                setSettingsCustomize({ ...SettingsCustomize, "status": "active"})
            }else {
                setSettingsCustomize({ ...SettingsCustomize, "status": "inactive"})
            }
        }else
        if(
            settingsCustomizeState?.status !== "active"
        ){
            setSettingsCustomize({ ...SettingsCustomize, "status": "active"})
        }else {
            setSettingsCustomize({ ...SettingsCustomize, "status": "inactive"})
        }


       
    }




    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadBtnSave, setLoadBtnSave] = useState(false);

    async function savePage(){

        setLoadBtnSave(true)
        
        if(
            settingsCustomizeState?._id !== undefined
        ) {

            if(
                SettingsCustomize?.status === "active"
            ) {
                    if(
                        settingsCustomizeState.subdomain === undefined
                    ){
                        if(
                            SettingsCustomize.subdomain === "" ||
                            SettingsCustomize.subdomain === undefined
                        ) {
                            setLoadNameUrlPage(true)
                            setLoadBtnSave(false)
                            return
                        }
                         
                    }
            } else if (
                settingsCustomizeState?.status === "active"
                ) {
                    if(
                        SettingsCustomize.subdomain === ""
                    ){
                        setLoadNameUrlPage(true)
                        setLoadBtnSave(false)
                        return
                    }

            }

            await api.put(`/vacancie-page-appearance-config/${settingsCustomizeState?._id }`, SettingsCustomize)
             .then(response => {
                 setLoadBtnSave(false)
 
                 if(SettingsCustomize?.status === "active") {
                     swal({ icon: "success", title: "Sucesso!", text: "Pagina publicada com sucesso"});
                 }else {
                     swal({ icon: "success", title: "Sucesso!", text: "Informação salva com sucesso"});
                 }
                 setSettingsCustomize({})
                 loadSettingsCustomizePageState();
 
             }).catch(error => {
                 swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                 setLoadBtnSave(false)
             });

            


        } else {
         
            if(
                SettingsCustomize?.status === "active"
            ) {

                if(
                    SettingsCustomize.subdomain === undefined
                ){
                    setLoadNameUrlPage(true)
                    return
                }

            }

           await api.post("/vacancie-page-appearance-config", SettingsCustomize)
            .then(response => {
                setLoadBtnSave(false)

                if(SettingsCustomize?.status === "active") {
                    swal({ icon: "success", title: "Sucesso!", text: "Pagina publicada com sucesso"});
                }else {
                    swal({ icon: "success", title: "Sucesso!", text: "Informação salva com sucesso"});
                }
                setSettingsCustomize({})
                loadSettingsCustomizePageState();

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
            });
        }


        

    }


    return (
        <>
        <Layout path={path}> 
            <Header  path={path}/>

            <SubHeaderCustomizePage path={path}/>
            <div className="page-customizePage-setting">
                <div className="form-page">
                        <h5 className="sub-titulo">Defina o endereço</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Ativar página</p>
                                </div>
                                <div className="intemB">

                                <button 
                                    className={ 
                                        SettingsCustomize && SettingsCustomize.status  !== undefined ? 

                                            SettingsCustomize.status  !== "active" ?
                                            "button-toggle" : "button-toggle active"

                                        :settingsCustomizeState && settingsCustomizeState.status  !== undefined ?
                                            
                                                settingsCustomizeState.status  === "active" ?
                                                "button-toggle active" : "button-toggle"

                                            :
                                            "button-toggle"
                                    }
                                    onClick={AtictivePage} 
                                    ><span></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>URL da página</p>
                                </div>
                                <div className="intemB">
                                    <div>
                                        <input type="text" 
                                            placeholder='URL da página' 
                                            name='subdomain' 
                                            className={loadNameUrlPage ? 'inputErro' : ''} 
                                            onChange={myChangeHandler} 
                                            defaultValue={
                                                SettingsCustomize?.subdomain ?
                                                    SettingsCustomize?.subdomain 
                                                :
                                                    settingsCustomizeState && settingsCustomizeState.subdomain ?
                                                    settingsCustomizeState.subdomain :
                                                    ''
                                            }
                                        />
                                        { loadNameUrlPage ? <p className="textError">O campo é obrigatório.</p> : "" }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="url">
                            beta.peopleview.com.br/lp/{SettingsCustomize?.subdomain ? SettingsCustomize?.subdomain 
                                                    :    settingsCustomizeState && settingsCustomizeState.subdomain ?
                                                    settingsCustomizeState.subdomain :''}
                        </div>
                        <h5 className="sub-titulo dois">Links</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Site</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do site' 
                                        name='site' 
                                        defaultValue={
                                            SettingsCustomize?.site ?
                                                SettingsCustomize?.site 
                                            :
                                                settingsCustomizeState && settingsCustomizeState.site ?
                                                settingsCustomizeState.site :
                                                ''
                                        }
                                        onChange={myChangeHandler} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Facebook</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do facebook' 
                                        name='facebook' 
                                        defaultValue={
                                            SettingsCustomize?.facebook ?
                                                SettingsCustomize?.facebook 
                                            :
                                                settingsCustomizeState && settingsCustomizeState.facebook ?
                                                settingsCustomizeState.facebook :
                                                ''
                                        }
                                        onChange={myChangeHandler} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Instagram</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do instagram' 
                                        name='instagram' 
                                        defaultValue={
                                            SettingsCustomize?.instagram ?
                                                SettingsCustomize?.instagram 
                                            :
                                                settingsCustomizeState && settingsCustomizeState.instagram ?
                                                settingsCustomizeState.instagram :
                                                ''
                                        }
                                        onChange={myChangeHandler} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>LinkedIn</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do linkedIn' 
                                        name='linkedin' 
                                        defaultValue={
                                            SettingsCustomize?.linkedin ?
                                                SettingsCustomize?.linkedin 
                                            :
                                                settingsCustomizeState && settingsCustomizeState.linkedin ?
                                                settingsCustomizeState.linkedin :
                                                ''
                                        }
                                        onChange={myChangeHandler} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Twitter</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do twitter' 
                                        name='twitter' 
                                        defaultValue={
                                            SettingsCustomize?.twitter ?
                                                SettingsCustomize?.twitter 
                                            :
                                                settingsCustomizeState && settingsCustomizeState.twitter ?
                                                settingsCustomizeState.twitter :
                                                ''
                                        }
                                        onChange={myChangeHandler} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>YouTube</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do youtube' 
                                        name='youtube'
                                        defaultValue={
                                            SettingsCustomize?.youtube ?
                                                SettingsCustomize?.youtube 
                                            :
                                                settingsCustomizeState && settingsCustomizeState.youtube ?
                                                settingsCustomizeState.youtube :
                                                ''
                                        }
                                        onChange={myChangeHandler} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p></p>
                                </div>
                                <div className="intemB">
                                   <button 
                                        className={
                                            loadBtnSave ? 
                                            "btnGreen btn-salvar load" 
                                            : "btnGreen btn-salvar" 
                                        }  onClick={savePage}
                                    >Salvar</button>
                                </div>
                            </div>
                        </div>

                </div>
            </div>

        </Layout>
        </>
    )
}