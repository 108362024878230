import React, {
    useState,
    useEffect
} from 'react';
import Layout from '../../components/Layouts/defaultUser';
import IllustrationPonto from '../../assets/images/illustration-ponto.svg'
import iconArrowRed from '../../assets/images/arrow-icon-red.svg';
import iconArrowGreen from '../../assets/images/arrow-icon-green.svg';
import moment from "moment";
import { Modal } from "react-bootstrap";
import IconUserNone from '../../assets/images/iconUserNone.svg';
import Close from '../../assets/images/close.svg'
import NextIcon from "../../assets/images/arrow-right.svg"
import PrevIcon from "../../assets/images/arrow-left2.svg"
import Carregamento from '../../assets/images/carregando.gif';
import iconSetaV from '../../assets/images/v.svg';
import { decodeToken } from '../../services/auth';
import { select_string_time} from '../../services/utils';
import { dateMask } from '../../services/mask';
import { Pagination } from 'antd';
import { toast } from 'react-toastify';
import {
    getDayofTheWeek,
    diaMesFormatadaParaTraco,
    showDateMonthYearToString
} from '../../services/utils'
import { Dropdown } from "react-bootstrap";
import { useGeolocated } from "react-geolocated";
import apiV2 from '../../services/apiV2';
import swal from 'sweetalert';
import './styles.scss';

export default function UserMyClockPunch(props) {

    const decoded = decodeToken();

    const { path } = props.match;
    const { user } = decodeToken();
    const linkEnableLocation = "https://www.google.com/search?q=como+ativar+a+localiza%C3%A7%C3%A3o+no+seu+navegador&sxsrf=AJOqlzWdnxnQTyNMqj-GNZnxG-ldpJHpoA%3A1679404688591&ei=kK4ZZIHmI6jC5OUPvdqzmA8&ved=0ahUKEwiB7pb_je39AhUoIbkGHT3tDPMQ4dUDCA8&uact=5&oq=como+ativar+a+geocaliza%C3%A7%C3%A3o+no+seu+navegador&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQRzIECAAQR0oECEEYAFAAWABgggdoAHACeACAAQCIAQCSAQCYAQDIAQjAAQE&sclient=gws-wiz-serp";
    const [visibleRegisterPonto, setVisibleRegisterPonto] = useState(false);
    const [ dataHora, setDataHora ] = useState('');
    const [ listOfRegisteredPointsItems, setListOfRegisteredPointsItems ] = useState([]);
    const [ listOfRegisteredPointsMetadata, setListOfRegisteredPointsMetadata ] = useState([]);

    const [ loadBtnRegisterPoint, setBtnRegisterPoint] = useState(false);
    const [ loadListPage, setLoadListPage] = useState(false);

    const todaysDate = new Date();
    const [ selectedOptionFilder, setSelectedOptionFilder]  = useState("");
    const [ periodStartFilder, setPeriodStartFilder]  = useState(moment().startOf('week').format("YYYY-MM-DD"));
    const [ periodEndFilder, setPeriodEndFilder]  = useState(moment(todaysDate).format("YYYY-MM-DD"));
    const [ periodStartString, setPeriodStartString]  = useState("");
    const [ periodEndString, setPeriodEndString]  = useState("");
    
    const [ page, setPage] = useState(1);
    const [ limit, setLimit] = useState(25);
    const [ totalIntens, setTotalIntens] = useState(0);
    const pages = Math.ceil(parseInt(totalIntens)  /parseInt(limit));
    function myChangeHandlerPerPeriod(event) {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam === "startPeriod_") {
            const dateMaskString = dateMask(val)
            setPeriodStartString(dateMaskString)
            if(val.length === 10) {

                const data = moment(dateMaskString, 'DD/MM/YYYY').format('YYYY-MM-DD');
                setPeriodStartFilder(data)

            }
        } else
        if(nam === "endPeriod_") {
            const dateMaskString = dateMask(val)
            setPeriodEndString(dateMaskString)
            if(val.length === 10) {

                const data = moment(dateMaskString, 'DD/MM/YYYY').format('YYYY-MM-DD');
                setPeriodEndFilder(data)
                
            }
        }
    }

    useEffect(() => {
        returnHour(new Date());
    }, []);
    useEffect(() => {
        listOfRegisteredPoints(periodStartFilder,periodEndFilder);
    }, [periodStartFilder,periodEndFilder]);
    
  
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    setInterval(() => {
        returnHour(new Date());
    }, 300)

    function returnHour(datahora) {
        const zeroFill = n => {
            return ('0' + n).slice(-2);
        }
        const now = datahora;
        setDataHora(zeroFill(now.getHours()) + ':' + zeroFill(now.getMinutes()) + ':' + zeroFill(now.getSeconds()));
    }

    function abrirRegisterPonto() {
        setVisibleRegisterPonto(true);
    }
    
    function FecharRegisterPonto() {
        setVisibleRegisterPonto(false);
    }

    async function checkinCheckout() {
        setBtnRegisterPoint(true);
        setLoadListPage(true);
        const location = {
            "latitude": coords?.latitude,
            "longitude": coords?.longitude
        }
        await apiV2.post(`/checkin-checkout`, { location })
        .then(response => {

            setBtnRegisterPoint(false);
            setVisibleRegisterPonto(false);

            listOfRegisteredPoints(periodStartFilder,periodEndFilder, limit, page);
            swal({ icon: "success", title: "Sucesso!", text: "Ponto cadastrado com sucesso." });

        }).catch(error => {


            setBtnRegisterPoint(false);
            setVisibleRegisterPonto(false);
            swal(
                {
                    icon: "error", title: "Erro!",
                    text: error?.message? error?.message
                        : 'Não foi possível cadastrar ponto!'
                }
            );
            
        });
    }
    
    async function listOfRegisteredPoints(periodStartFilder,periodEndFilder) {
        setLoadListPage(true);
        await apiV2.get(`/punch-clock?punchClockDateRange[0]=${periodStartFilder}&punchClockDateRange[1]=${periodEndFilder}&order=desc&limit=25&page=1`)
        .then(response => {
            if(response?.data.paging.page) setPage(response?.data.paging.page )
            if(response?.data.paging.limit) setLimit(response?.data.paging.limit)
            setTotalIntens(response?.data.paging.total)
            if(response?.data?.items) setListOfRegisteredPointsItems(response?.data?.items);
            if(response?.data?.metadata) setListOfRegisteredPointsMetadata(response?.data?.metadata);
            setLoadListPage(false);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadListPage(false);
        })
    }
    async function listOfRegisteredPointsPaginetion(periodStartFilder,periodEndFilder,limit,page) {
        setLoadListPage(true);
        await apiV2.get(`/punch-clock?punchClockDateRange[0]=${periodStartFilder}&punchClockDateRange[1]=${periodEndFilder}&order=desc&limit=${limit}&page=${page}`)
        .then(response => {
            if(response?.data.paging.page) setPage(response?.data.paging.page)
            if(response?.data.paging.limit) setLimit(response?.data.paging.limit)
            if(response?.data?.items) setListOfRegisteredPointsItems(response?.data?.items);
            if(response?.data?.metadata) setListOfRegisteredPointsMetadata(response?.data?.metadata);
            setLoadListPage(false);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadListPage(false);
        })
    }

    var data = new Date();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();

    const totalExtra = select_string_time(listOfRegisteredPointsMetadata?.totalExtraTime ?? "00:00");
    const totalNegative = select_string_time(listOfRegisteredPointsMetadata?.totalNegativeTime ?? "00:00");
    const hoursBalance = select_string_time(listOfRegisteredPointsMetadata?.hoursBalance ?? "00:00");

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    async function journeyFilter(event) {
        setIsOpen(!isOpen);
        setSelectedOptionFilder(event)

        if(event === "showCurrentWeek") {

            const firstDay = moment().startOf('week').format("YYYY-MM-DD");
            const lastDay = moment().endOf('week').format("YYYY-MM-DD");
            setPeriodStartFilder(firstDay);
            setPeriodEndFilder(lastDay);
            const firstDayString = moment().startOf('week').format("DD/MM/YYYY");
            const lastDayString = moment().endOf('week').format("DD/MM/YYYY");
            setPeriodStartString(firstDayString);
            setPeriodEndString(lastDayString);

            
        } else if(event === "showOnlyYesterday") {

            const showOnlyYesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
            setPeriodStartFilder(showOnlyYesterday)
            setPeriodEndFilder(showOnlyYesterday);
            const momentData = moment(showOnlyYesterday, 'YYYY-MM-DD');
            const novaData = momentData.format('DD/MM/YYYY');
            setPeriodStartString(novaData);
            setPeriodEndString(novaData);

        } else if (event === "showLastWeek") {

            const primeiraData = moment().subtract(1, 'week').isoWeekday(0).format("YYYY-MM-DD");
            const ultimaData = moment().subtract(1, 'week').isoWeekday(6).format("YYYY-MM-DD");
            setPeriodStartFilder(primeiraData);
            setPeriodEndFilder(ultimaData);
            const primeiraDataString = moment().subtract(1, 'week').isoWeekday(0).format("DD/MM/YYYY");
            const ultimaDataString = moment().subtract(1, 'week').isoWeekday(6).format("DD/MM/YYYY");
            setPeriodStartString(primeiraDataString);
            setPeriodEndString(ultimaDataString);

        } else if (event === "showLastMonth") {
            const firstDayOfMonth = moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
            const lastDayOfMonth = moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
            setPeriodStartFilder(firstDayOfMonth);
            setPeriodEndFilder(lastDayOfMonth);
            const firstDayOfMonthString = moment().subtract(1, 'month').startOf('month').format("DD/MM/YYYY");
            const lastDayOfMonthString = moment().subtract(1, 'month').endOf('month').format("DD/MM/YYYY");
            setPeriodStartString(firstDayOfMonthString);
            setPeriodEndString(lastDayOfMonthString);

        }

    }

    async function myChangeHandlerPaginetion(pageSelect) {
        setPage(pageSelect);
        listOfRegisteredPointsPaginetion(periodStartFilder,periodEndFilder,limit,page)
        return
    }
    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <p> <img src={PrevIcon} alt="" /> anterior</p>;
        }
        if (type === 'next') {
            return <p>  próxima <img src={NextIcon} alt="" /></p>;
        }
        return originalElement;
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPontoResumo, setIsModalPontoResumo ] = useState(false);
    function abirModalPontoResumo() {
        setIsModalPontoResumo(true);
    }
    function FecharIsModalPontoResumo() {
        setIsModalPontoResumo(false);
    }
    return (
        <>
            <Layout path={path}>
                <div className="user-my-ponto">

                    {
                        isGeolocationEnabled === false ? 
                        <div className="btn-active-geolocation">
                                É necessário permitir a localização do navegador para registrar o ponto corretamente. <a href={linkEnableLocation} target="_blank" rel="noopener noreferrer">Veja como ativar a localização</a>.
                        </div>
                        : ""
                    }
                    <div className='box-information box-leiaute'>
                        <div className='box relogio'>
                            <div className='icon-reloligio'>
                                <img src={IllustrationPonto} alt="Relogio" />

                            </div>
                            <div className='infor'>
                                <p>Trabalhei hoje, até agora</p>
                                <strong>{listOfRegisteredPointsMetadata?.hoursWorkedSoFar ?listOfRegisteredPointsMetadata?.hoursWorkedSoFar : "00:00"}</strong>
                            </div>
                        </div>
                        <div className='box registro'>
                            <div className='infor'>
                                    <p>Horário atual</p>
                                    <strong>{dataHora ? dataHora.split(':')[0]+`:`+dataHora.split(':')[1] : '-'}</strong>
                            </div>
                             <button disabled={coords === undefined ? true : false}  onClick={abrirRegisterPonto}>Registrar ponto</button>

                        </div>
                    </div>

                    <div className='resumo-col box-leiaute'>
                        <div className='infor-col'>
                            {
                                user?.avatar ?
                                <img alt="" src={user?.avatar} className="foto-colaborador"/>
                                :
                                <div className="semImgCol">
                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                </div>
                            }
                            <div className="nome-cargo">
                                <strong className="nome">{user?.name}</strong>
                                <p className="cargo">{user?.occupation}</p>
                            </div>
                        </div>
                        <div className='infor'>
                            <div>
                                <p className='text'>Horas extras</p>
                                <div className='hr-minuto'>
                                    <img src={iconArrowGreen} alt="" />
                                    <p>
                                        {   
                                             parseInt(totalExtra[0]) === 0 ?
                                                <span className='minuto'>{parseInt(totalExtra[1])} minutos</span>:
                                            <>
                                                <span> {parseInt(totalExtra[0])}  hora{parseInt(totalExtra[0]) === 1 || parseInt(totalExtra[0]) === -1 ? "":"s"}</span> 
                                                <span className='minuto'> {parseInt(totalExtra[1])} minuto{parseInt(totalExtra[1]) === 1 ? "":"s"}</span>
                                            </>
                                        }
                                    </p>  
                                        
                                        
                                </div>
                            </div>
                            <div>
                                <p className='text'>Atrasos</p>
                                <div className='hr-minuto'>
                                    <img src={iconArrowRed} alt="" />
                                    <p>
                                        {   
                                             parseInt(totalNegative[0]) === 0 ?
                                                <span className='minuto'>{parseInt(totalNegative[1])} minutos</span>:
                                            <>
                                                <span> {parseInt(totalNegative[0])} hora{parseInt(totalNegative[0]) === 1 || parseInt(totalNegative[0]) === -1 ? "":"s"}</span> 
                                                <span className='minuto'> {parseInt(totalNegative[1])} minuto{parseInt(totalNegative[1]) === 1 ? "":"s"}</span>
                                            </>
                                        }
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p className='text'>Jornada</p>
                                <div className='hr-minuto'>
                                    <img src={iconArrowRed} alt="" />
                                    <p><span>{listOfRegisteredPointsMetadata?.weeklyJourney?.name ? listOfRegisteredPointsMetadata?.weeklyJourney?.name : "Nenhum"}</span></p>
                                </div>
                            </div>
                            <div className='btn-ver-detalher'>
                                <button  onClick={abirModalPontoResumo}>Ver detalhes</button>
                            </div>
                        </div>
                    </div>

                    <div className='header-menu'>
                        <div className='titulo-datas'>
                            <h3 className='titulo'>
                            {
                                mes  === '01'    ? 'Janeiro' :
                                mes  === '02'    ? 'Fevereiro' :
                                mes  === '03'    ? 'Março' :
                                mes  === '04'    ? 'Abril' :
                                mes  === '05'    ? 'Maio' :
                                mes  === '06'    ? 'Junho' :
                                mes  === '07'    ? 'Julho' :
                                mes  === '08'    ? 'Agosto' :
                                mes  === '09'    ? 'Setembro' :
                                mes  === '10'    ? 'Outubro' :
                                mes  === '11'   ? 'Novembro' :
                                mes  === '12'   ? 'Dezembro'  : ''
                            }  de {ano}
                            </h3>
                            <div className='date'>
                                <p>Período</p>
                                <div className='datas'>
                                    <input
                                        type="text"
                                        name="startPeriod_"
                                        value={periodStartString !== "" ? periodStartString : ''}
                                        onChange={myChangeHandlerPerPeriod}
                                        placeholder="00/00/0000"
                                        className="data"
                                    />
                                    <input
                                        type="text"
                                        name="endPeriod_"
                                        value={periodEndString !== "" ? periodEndString : ''}
                                        onChange={myChangeHandlerPerPeriod}
                                        placeholder="00/00/0000"
                                        className="data"
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='fucoes'>
                            <Dropdown   show={isOpen} onToggle={toggleDropdown} className="dropdown-icon">
                                <Dropdown.Toggle>

                                      {   selectedOptionFilder === "" ||
                                          selectedOptionFilder === "showCurrentWeek" ?
                                         "Mostrando semana atual"
                                         :
                                         selectedOptionFilder === "showOnlyYesterday" ?
                                         "Mostrar Apenas ontem"
                                         :
                                         selectedOptionFilder === "showLastWeek" ?
                                         "Mostrar na última semana"
                                         :
                                         selectedOptionFilder === "showLastMonth" ?
                                         "Mostrar último mês" : "Mostrando semana atual"
                                      }
                                    <img alt="" src={iconSetaV} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button  type="button" name="" onClick={() => journeyFilter("showCurrentWeek") }>
                                        Mostrar semana atual
                                    </button>
                                    <button  type="button" name="" onClick={() => journeyFilter("showOnlyYesterday")}>
                                        Mostrar Apenas ontem
                                    </button>
                                    <button  type="button" name="" onClick={() => journeyFilter("showLastWeek")}>
                                        Mostrar na última semana
                                    </button>
                                    <button  type="button" name="" onClick={() => journeyFilter("showLastMonth")}>
                                        Mostrar último mês
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className='tabela'>
                        <div className='header-tb'>
                            <div className='data'>
                                <p>Data</p>
                            </div>
                            <div className='entrada-saida'>
                                <p>Entrada</p>
                                <p>Saída Int.</p>
                            </div>
                            <div className='horas-trabalhadas'>
                                <p>Horas trabalhadas</p>
                            </div>
                            <div className='intervelo'>
                                <p>Intervalo</p>
                            </div>
                        </div>

                        <div className='corpo-tb'>
                        {
                            loadListPage ?
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            : 
                            listOfRegisteredPointsItems?.length !== 0 ?
                                listOfRegisteredPointsItems?.map((collaboratorClock) => (   
                                    
                                    <div className='linha-tb' key={collaboratorClock._id}>
                                        <div className='data bloco'>
                                            <p className='name-dia'>{getDayofTheWeek(collaboratorClock?.created_at)}</p>
                                            <p>{diaMesFormatadaParaTraco(collaboratorClock.punchClockDate)}</p>
                                        </div>

                                        <div className='entrada-saida bloco'>
                                            {collaboratorClock?.checkInCheckOut.map((checkInCheckOutItem) => (  
                                                <p>{checkInCheckOutItem?.checkTime ?? '-' }</p>
                                                ))
                                            }
                                        </div>
                                        <div className='horas-trabalhadas'>
                                            <p>{collaboratorClock?.totalTimeWorking ?? '-'}</p>
                                        </div>
                                        <div className='intervelo'>
                                            <p>{collaboratorClock?.totalInterval?? '-'}</p>
                                        
                                        </div>
                                    </div>
                                ))
                            : "Não foi encontrado nenhum registro de ponto batido."
                        }
                        </div>

                    </div>


                    <div className='paginacao-componente'>
                        {
                          pages <= 1 ? "":
                            <Pagination  
                                pageSize={limit}
                                // current={doNotAdd === false ? page : pageMAisUm }
                                size="default"
                                itemRender={itemRender} 
                                defaultCurrent={page}
                                onChange={myChangeHandlerPaginetion}
                                total={pages}  
                            />
                        }
                    </div> 
                </div>

                <Modal show={visibleRegisterPonto} restoreFocus={false} className="popup-geral popup-register-ponto" onHide={FecharRegisterPonto}>
                    <div className="titulo">
                        <h4>Registrar ponto<p className="btn-fechar" onClick={FecharRegisterPonto}><img src={Close} alt="Fechar" /></p></h4>
                        <p className='subTitulo'>{showDateMonthYearToString(new Date())}</p>
                    </div>
                    <div className='hora-atual'>
                        <img src={IllustrationPonto} alt="Relogio" />
                        <div className='infor'>
                            <p>Horário atual</p>
                            <strong>{dataHora.split(':')[0]+`:`+dataHora.split(':')[1]}</strong>
                        </div>
                    </div>
                    { 
                       
                        decoded && (decoded?.user.weeklyJourney === null || decoded?.user.weeklyJourney === "" ||  listOfRegisteredPointsMetadata.length === 0 ) ?
                            <div className='not-enabled'>
                                <h4>Você não possui jornada de trabalho</h4>
                            </div>
                        :
                        !isGeolocationAvailable ? 
                            <div className='not-enabled'>
                                <h4>Seu navegador não suporta geolocalização</h4>
                                <p>Por Favor troque o navegador ou atualize-o para uma versão mais recente.</p>
                            </div>
                        : !isGeolocationEnabled ? 
                            <div className='not-enabled'>
                                <h4>A geolocalização não está habilitada.</h4>
                                <p>
                                É necessário permitir a localização do navegador para registrar o ponto corretamente.
                                </p>
                                
                            </div>
                        : 
                        <>
                        <div className='btns'>
                            <button className='registrar' disabled={loadBtnRegisterPoint} onClick={checkinCheckout}>Registrar ponto</button>
                            <button className='cancelar'>Cancelar</button>
                        </div>
                        </>
                    }
                </Modal>
            </Layout>

            <Modal show={isModalPontoResumo}className="popup-geral "  restoreFocus={false} onHide={FecharIsModalPontoResumo}>
                <div className="modal-ponto-resumo">
                    <h4 className='titulo'>Resumo</h4>
                    <div className='infor-col'>
                       
                             {
                                user?.avatar ?
                                <img alt="" src={user?.avatar} className="foto-colaborador"/>
                                :
                                <div className="semImgCol">
                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                </div>
                            }
                        <div className="nome-cargo">
                            <strong className="nome">{user?.name}</strong>
                            <p className="cargo">{user?.occupation ?? "-"}</p>
                        </div>
                    </div>
                    <div className="infor-jornada">
                        <h6>Jornada</h6>
                        <p className='name-journey'>{listOfRegisteredPointsMetadata?.weeklyJourney?.name ?? "Nenhum"}</p>
                        <div className='list-periods'>

                            {
                                listOfRegisteredPointsMetadata?.weeklyJourney?.days.map((dayWeek) => (
                                    <>
                                    {
                                        dayWeek?.period1?.initTime || dayWeek?.period2?.initTime ?
                                        <p>
                                            <span>
                                                {
                                                    dayWeek.dayOfWeek === "1" ?
                                                    "Segunda-feira" :
                                                    dayWeek.dayOfWeek === "2" ?
                                                    "Terça-feira" :
                                                    dayWeek.dayOfWeek === "3" ?
                                                    "Quarta-feira" :
                                                    dayWeek.dayOfWeek === "4" ?
                                                    "Quinta-feira" :
                                                    dayWeek.dayOfWeek === "5" ?
                                                    "Sexta-feira" :
                                                    dayWeek.dayOfWeek === "6" ?
                                                    "Sábado" :
                                                    dayWeek.dayOfWeek === "7" ?
                                                    "domingo" : "Domingo"
                                                }
                                            </span>
                                            {

                                                dayWeek?.period1?.initTime ?
                                                <>
                                                    {
                                                        dayWeek?.period1?.initTime ?
                                                            dayWeek?.period1?.initTime : "-"
                                                    } - {
                                                        dayWeek?.period1?.endTime ?
                                                            dayWeek?.period1?.endTime : "-"
                                                    }
                                                </>: ""
                                            }
                                            {
                                                dayWeek?.period2?.initTime ?
                                                <>/
                                                    {
                                                        dayWeek?.period2?.initTime ?
                                                            dayWeek?.period2?.initTime : "-"
                                                    } - {
                                                        dayWeek?.period2?.endTime ?
                                                            dayWeek?.period2?.endTime : "-"
                                                    }
                                                </>: ""
                                            }
                                            
                                        </p>
                                        :""
                                    }
                                    </>
                                ))
                            }
                        </div>

                        <div className='infor-horas'>
                            <h6>Total de horas extras no período</h6>
                            <div className='hr-minuto'>
                                <img src={iconArrowGreen} alt="" />
                                <p>
                                
                                {   
                                    parseInt(totalExtra[0]) === 0 ?
                                        <span className='minuto'>{parseInt(totalExtra[1])} minutos</span>:
                                    <>
                                        <span> {parseInt(totalExtra[0])}  hora{parseInt(totalExtra[0]) === 1 || parseInt(totalExtra[0]) === -1 ? "":"s"}</span> 
                                        <span className='minuto'> {parseInt(totalExtra[1])} minuto{parseInt(totalExtra[1]) === 1 ? "":"s"}</span>
                                    </>
                                }
                                </p>
                            </div>
                            {/* <p>4 horas e 57 minutos extras além do limite mensal de 16 horas</p> */}
                        </div>

                        <div className='infor-horas'>
                            <h6>Total de atrasos no período</h6>
                            <div className='hr-minuto'>
                                <img src={iconArrowRed} alt="" />
                                <p>
                                
                                {   
                                    parseInt(totalNegative[0]) === 0 ?
                                        <span className='minuto'>{parseInt(totalNegative[1])} minutos</span>:
                                    <>
                                        <span> {parseInt(totalNegative[0])} hora{parseInt(totalNegative[0]) === 1 || parseInt(totalNegative[0]) === -1 ? "":"s"}</span> 
                                        <span className='minuto'> {parseInt(totalNegative[1])} minuto{parseInt(totalNegative[1]) === 1 ? "":"s"}</span>
                                    </>
                                }
                                </p>
                            </div>
                            {/* <p>Compensados pelas horas extras</p> */}
                        </div>

                        <div className='infor-horas'>
                            <h6 className='total'>Total</h6>
                            <div className='hr-minuto'>
                                {
                                parseInt(hoursBalance[0]) < 0 ?
                                <img src={iconArrowRed} alt="" />
                                :
                                <img src={iconArrowGreen} alt="" />
                                }
                                <p>
                                    {   
                                        parseInt(hoursBalance[0]) === 0 ?
                                            <span className='minuto'>{parseInt(hoursBalance[1])} minutos</span>:
                                        <>
                                            <span> {parseInt(hoursBalance[0])} hora{parseInt(hoursBalance[0]) === 1 || parseInt(hoursBalance[0]) === -1 ? "":"s"}</span> 
                                            <span className='minuto'> {parseInt(hoursBalance[1])} minuto{parseInt(hoursBalance[1]) === 1 ? "":"s"}</span>
                                        </>
                                    } 
                                    
                                </p>
                            </div>
                            {/* <p>3 horas e 45 minutos extras além do limite mensal de 16 horas</p> */}
                        </div>
                        <div className='btn-fechar'>
                            <button  onClick={()=> setIsModalPontoResumo(false)}>Fechar</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

