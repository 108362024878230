import React, { useState, useEffect } from 'react';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/v.svg';
import IconHeart from '../../../assets/images/heart.svg';
import IconHeartGray from '../../../assets/images/heart-gray.svg';
import { oneHundredCharacters } from '../../../services/utils';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import IllustracaoMural from '../../../assets/images/illustration-mural-noticia.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import api from '../../../services/api';
import { toast } from 'react-toastify';

export default function feedUser(props) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAnuncios, setLoadAnuncios ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ anuncioLista, setAnuncioLista] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadCollaboratorAdvertsAccess();
    }, []);


    async function loadCollaboratorAdvertsAccess() {
        setLoadAnuncios(true)
        await api.get('/collaborator-adverts-access/')
        .then(response => {
            setLoadAnuncios(false)
            setAnuncioLista(response.data);
        }).catch(error =>{
            setLoadAnuncios(false)
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }
    async function loadCollaboratorAvertsAccessRecado(){

        await api.get('/collaborator-adverts-access?category=Recado')
        .then(response => {
            setLoadAnuncios(false)
            setAnuncioLista(response.data);
        }).catch(error =>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadAnuncios(false)
        })

    }
    async function loadCollaboratorAvertsAccessAniv() {

        await api.get('/collaborator-adverts-access?category=Aniversário')
        .then(response => {
            setLoadAnuncios(false)
            setAnuncioLista(response.data);
        }).catch(error =>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadAnuncios(false)
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ tudoFeed, setTudoFeed ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ aniversariosFeed, setAniversariosFeed ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ recadosFeed, setRecadosFeed ] = useState(false);

    async function myChangeHandler(event) {

        let nam = event.target.name;
        let val = event.target.checked;

        if(nam === 'tudoFeed' && val === true) {
            setTudoFeed(true)
            setAniversariosFeed(true)
            setRecadosFeed(true)
            setLoadAnuncios(true)
            loadCollaboratorAdvertsAccess();

        }
        else
        if(nam === 'tudoFeed' && val === false) {
            setTudoFeed(false)
            setAniversariosFeed(false)
            setRecadosFeed(false)

        }
        else

        /*********************************************/
        ////////////////   aniversario    /////////////
        /*********************************************/

        if(nam === 'aniversariosFeed' && val === false) {

                if(recadosFeed === false && tudoFeed === false) {
                    setTudoFeed(false)
                    setAniversariosFeed(false)
                    setRecadosFeed(false)

                    setLoadAnuncios(true)
                    loadCollaboratorAdvertsAccess();

                }else {
                    //alert('niver false ')
                    setTudoFeed(false)
                    setRecadosFeed(true)
                    setAniversariosFeed(false)
                    setLoadAnuncios(true)
                    loadCollaboratorAvertsAccessRecado();

                }
        } else
        if(nam === 'aniversariosFeed' && val === true) {
                if(recadosFeed === true) {
                    setTudoFeed(true)
                    setRecadosFeed(true)
                    setAniversariosFeed(true)

                    setLoadAnuncios(true)
                    loadCollaboratorAdvertsAccess();
                } else  {
                    setTudoFeed(false)
                    setRecadosFeed(false)
                    setAniversariosFeed(true)

                    setLoadAnuncios(true)
                    loadCollaboratorAvertsAccessAniv();
                }

        } else

            /*********************************************/
        ////////////////      recado       ////////////
        /*********************************************/

        if(nam === 'recadosFeed' && val === false) {
            setTudoFeed(false)


                if(aniversariosFeed === false && tudoFeed === false) {
                    setTudoFeed(false)
                    setAniversariosFeed(false)
                    setRecadosFeed(false)
                    setLoadAnuncios(true)
                    loadCollaboratorAdvertsAccess();
                }else {
                    setRecadosFeed(false)
                    setAniversariosFeed(true)
                    setLoadAnuncios(true)
                    loadCollaboratorAvertsAccessAniv();
                }
        }else
        if(nam === 'recadosFeed' && val === true) {

                if(aniversariosFeed === true) {
                    setTudoFeed(true)
                    setRecadosFeed(true)
                    setAniversariosFeed(true)
                    setLoadAnuncios(true)
                    loadCollaboratorAdvertsAccess();
                } else  {
                    setTudoFeed(false)
                    setAniversariosFeed(false)
                    setRecadosFeed(true)
                    setLoadAnuncios(true)
                    loadCollaboratorAvertsAccessRecado();
                }
        }
    }
    async function saveLike(e) {
        let adverts    = e;
        let numberLike = 1;
        async function savecollaboratoradvertslike() {
            await api.post('/collaborator-adverts-like-access/', { adverts, numberLike })
            .then(() => {
                async function loadfeedsave() {
                    if(tudoFeed === true) {
                        setTudoFeed(true)
                        setAniversariosFeed(true)
                        setRecadosFeed(true)
                        loadCollaboratorAdvertsAccess();
                    }
                    else
                    if(recadosFeed === true) {
                        setTudoFeed(false)
                        setRecadosFeed(true)
                        setAniversariosFeed(false)
                        loadCollaboratorAvertsAccessRecado();
                    }
                    else
                    if(aniversariosFeed === true) {
                        setTudoFeed(false)
                        setRecadosFeed(false)
                        setAniversariosFeed(true)
                        loadCollaboratorAvertsAccessAniv();

                    } else {

                        setTudoFeed(true)
                        setRecadosFeed(true)
                        setAniversariosFeed(true)
                        loadCollaboratorAdvertsAccess();
                    }
                }
                loadfeedsave()
            }).catch((error) => {});
        }
        savecollaboratoradvertslike();
    }

    async function deleteLike(e) {
        await api.get(`/collaborator-adverts-like-access/?adverts=${e}`)
        .then(response => {
            if(response.data !== undefined && response.data._id !== undefined) {
                async function deletelikeOther() {
                    await api.delete(`/collaborator-adverts-like/${response.data._id}`)
                    .then(() => {
                        async function loadfeeddelete() {
                            if(tudoFeed === true) {
                                setTudoFeed(true)
                                setAniversariosFeed(true)
                                setRecadosFeed(true)
                                loadCollaboratorAdvertsAccess();
                            }
                            else
                            if(recadosFeed === true) {
                                setTudoFeed(false)
                                setRecadosFeed(true)
                                setAniversariosFeed(false)
                                loadCollaboratorAvertsAccessRecado();
                            }
                            else
                            if(aniversariosFeed === true) {
                                setTudoFeed(false)
                                setRecadosFeed(false)
                                setAniversariosFeed(true)
                                loadCollaboratorAvertsAccessAniv();
                            } else {
                                setTudoFeed(true)
                                setRecadosFeed(true)
                                setAniversariosFeed(true)
                                loadCollaboratorAdvertsAccess();
                            }
                        }
                        loadfeeddelete();
                    }).catch((error) => { });
                }
                deletelikeOther();
            }
        }).catch((error) => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ showMore, setShowmore ] = useState([]);

    async function showmore(e) {
        setShowmore(e)
    }
    return (
        <>
            <div className="feed-user">
                <h1 className="titulo">Mural de anúncios

                    <Dropdown  className="dropdown-icon filtro">
                        <Dropdown.Toggle>
                        Filtrar <img alt="" src={iconVerMais} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                                <div className="chekbox">
                                    <input type="checkbox" onChange={myChangeHandler} id="tudoFeed" name="tudoFeed" checked={tudoFeed}/>
                                    <label className="tudoFeed">Tudo</label>
                                </div>
                                <div className="chekbox">
                                    <input type="checkbox" onChange={myChangeHandler} id="aniversariosFeed" name="aniversariosFeed" checked={aniversariosFeed} />
                                    <label  className="aniversariosFeed">Aniversários</label>
                                </div>
                                <div className="chekbox">
                                    <input type="checkbox" onChange={myChangeHandler} id="recadosFeed" name="recadosFeed" checked={recadosFeed} />
                                    <label className="recadosFeed">Recados</label>
                                </div>
                        </Dropdown.Menu>
                    </Dropdown>

                </h1>

                <div className="feed">
                    <ul>
                        <div className="box">

                        {
                            loadAnuncios ?
                            (
                                <>

                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):
                            anuncioLista &&
                            anuncioLista.length !== 0 ?
                            anuncioLista
                            .map((anuncioList, index) => (

                                <li key={index}
                                    className={
                                        anuncioList.categoria === "Recado" ? "box-feed messages-rh" : "box-feed box-birthday"
                                    }
                                >
                                    {/* {anuncioList.categoria} */}
                                {
                                    anuncioList.categoria === "Recado" ?
                                       <>
                                            {
                                                anuncioList.avatar && anuncioList.avatar !== 'undefined' ?
                                                <img alt="" src={anuncioList.avatar} className="box-img" />
                                                :
                                                <div className="semImgCol">
                                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            }
                                            <div className="box-infor-like">
                                                <div className="box-infor">
                                                    <h1>{anuncioList.announcementTitle}</h1>
                                                    <div>
                                                    {
                                                        showMore && showMore === anuncioList._id ?
                                                        anuncioList.announcementDescription.split('\n').map(str => <p key={index}>{str}</p>)
                                                        :
                                                        <>
                                                            {
                                                                anuncioList.announcementDescription.split('\n').map(str => <p>{oneHundredCharacters(str)}</p>)
                                                            }
                                                            {
                                                                anuncioList.announcementDescription.length >= 300 ?
                                                                <p onClick={() => showmore(anuncioList._id)} >
                                                                        Ver mais
                                                                </p>
                                                                :
                                                                ''
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div></div>
                                        {
                                            anuncioList.totalLikes === 0 ?
                                            <div className="like">
                                                <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curti" /></button>
                                            </div>
                                            :
                                            anuncioList.totalLikes !== 0 && anuncioList.likeUser === false ?
                                            <div className="like like-false">
                                                <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curti" />{anuncioList.totalLikes}</button>
                                            </div>
                                            :
                                            anuncioList.totalLikes !== 0 && anuncioList.likeUser === true ?
                                            <div className="like like-true">
                                                <button onClick={() => deleteLike(anuncioList._id)}><img src={IconHeart} alt="Curti" /> {anuncioList.totalLikes}</button>
                                            </div>
                                            :
                                            ''
                                        }

                                       </>
                                    :

                                    <>
                                            {
                                            anuncioList.avatar && anuncioList.avatar !== 'undefined' ?
                                            <img alt="" src={anuncioList.avatar} className="box-img" />
                                            :
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        }
                                            {/* <img src={ImgExemplo} alt="img" className="box-img"/> */}
                                            <div className="box-infor-like">
                                                <div className="box-infor">
                                                    <h1>{anuncioList.announcementTitle}</h1>
                                                    <p>
                                                    {
                                                        showMore && showMore === anuncioList._id ?
                                                            anuncioList.announcementDescription
                                                        :
                                                        <>
                                                            {
                                                                oneHundredCharacters(anuncioList.announcementDescription)
                                                            }

                                                            {
                                                                anuncioList.announcementDescription.length >= 300 ?
                                                                <p onClick={() => showmore(anuncioList._id)} >
                                                                        Ver mais
                                                                </p>
                                                                :
                                                                ''
                                                            }

                                                        </>
                                                    }
                                                    </p>
                                                    {/* <img src={IllustrationBirthday} alt="Ilustração Aniversario" className="ilustracao"/> */}
                                                </div>
                                            </div>
                                            <div></div>
                                            {
                                                anuncioList.totalLikes === 0 ?
                                                <div className="like">
                                                    <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curti" /></button>
                                                </div>
                                                :
                                                anuncioList.totalLikes !== 0 && anuncioList.likeUser === false ?
                                                <div className="like like-false">
                                                    <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curti" />{anuncioList.totalLikes}</button>
                                                </div>
                                                :
                                                anuncioList.totalLikes !== 0 && anuncioList.likeUser === true ?
                                                <div className="like like-true">
                                                    <button onClick={() => deleteLike(anuncioList._id)}><img src={IconHeart} alt="Curti" /> {anuncioList.totalLikes}</button>
                                                </div>
                                                :
                                                ''
                                            }


                                    </>
                                }

                                </li>

                            ))
                            :
                            <>
                                <div className="feed-nenhum">
                                    <p>Nenhum anúncio no mural.</p>
                                    <img src={IllustracaoMural} alt="" />

                                </div>
                            </>
                            }
                        </div>
                    </ul>
                </div>

            </div>
        </>
    );
}

