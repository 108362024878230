import React, { Component } from 'react';
import { Container } from './styles';
import Layout from '../../components/Layouts/default';
import api from '../../services/api';
import { decodeToken } from '../../services/auth';

export default class Dashboard extends Component {
    state = {
        user: decodeToken(),
        countCollaborators: [],
    };

    componentDidMount() {
        this.getCountCollaborators();
    }

    getCountCollaborators = async () => {
        const response = await api.get('/number-of-collaborators');
        this.setState({ countCollaborators: response.data })
    }

    render() {
        const { countCollaborators } = this.state;

        return (
            <>
                <Layout>
                    <Container>
                       {countCollaborators} colaboradores ativos
                    </Container>
                </Layout>
            </>
        );
    }
}
