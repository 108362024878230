import React, {useState} from 'react';
import HeaderAdmin from '../HeaderAdmin';
import Close from '../../assets/images/close.svg';
import { Modal } from "react-bootstrap";

export default function headerManagement({
    path,
    visibleModalConfigPulsos,
    modalConfigPulsos
}) {

    let btnBuscas = []
    btnBuscas.push(
        <>
        <button className='btnGreen ' onClick={modalConfigPulsos}>Configurar pulsos</button>
        </>
    )

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [pulse_search, setPulse_search ] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [accept_late_reply, setAccept_late_reply ] = useState("");

    return (
        <>
        <HeaderAdmin>
                <>
                    <div>
                        <h1>Pulsos</h1>
                        <p>Acompanhe o clima da sua empresa de maneira transparente</p>
                    </div>
                    <div className='feedbeck'>
                        {btnBuscas}
                    </div>
                </>
        </HeaderAdmin>
        <div className='header-mb-feedbeks col'
        
        >
            {btnBuscas}
        </div>

            <Modal show={visibleModalConfigPulsos} className="popup-geral popup-config-pulsos" onHide={modalConfigPulsos}>
                <div>
                    <div className="titulo">
                        <h4>Configurar pulsos<p className="btn-fechar" onClick={modalConfigPulsos}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className='form'>
                        <div className='box'>
                            <h3>Enviar pesquisa de pulsos</h3>

                            <div className="radios">

                                <button className="btn-radio" onClick={() =>  setPulse_search("weekly")}>
                                    <label className={ pulse_search && pulse_search !== "" && pulse_search === "weekly" ?
                                        "chekboxLabel checado" : "chekboxLabel"
                                    }   
                                    ></label>
                                    <p className="texto"> Enviar semanalmente </p>
                                </button>

                                <button className="btn-radio" onClick={() =>  setPulse_search("monthly")}>
                                    <label className={ pulse_search && pulse_search !== "" && pulse_search === "monthly" ?
                                        "chekboxLabel checado" : "chekboxLabel"
                                    }   
                                    ></label>
                                    <p className="texto"> Enviar mensalmente </p>
                                </button>

                                <button className="btn-radio" onClick={() =>  setPulse_search("doNotSend")}>
                                    <label className={ pulse_search && pulse_search !== "" && pulse_search === "doNotSend" ?
                                        "chekboxLabel checado" : "chekboxLabel"
                                    }   
                                    ></label>
                                    <p className="texto"> Não enviar </p>
                                </button>



                            </div>

                        </div>
                        <div className='box'>
                            <h3>Aceitar resposta com atraso</h3>

                            <div className="radios">

                                <button className="btn-radio" onClick={() =>  setAccept_late_reply("two_days")}>
                                    <label className={ accept_late_reply && accept_late_reply !== "" && accept_late_reply === "two_days" ?
                                        "chekboxLabel checado" : "chekboxLabel"
                                    }   
                                    ></label>
                                    <p className="texto"> 2 dias </p>
                                </button>

                                <button className="btn-radio" onClick={() =>  setAccept_late_reply("one_days")}>
                                    <label className={ accept_late_reply && accept_late_reply !== "" && accept_late_reply === "one_days" ?
                                        "chekboxLabel checado" : "chekboxLabel"
                                    }   
                                    ></label>
                                    <p className="texto"> 1 dias </p>
                                </button>

                                <button className="btn-radio" onClick={() =>  setAccept_late_reply("not_accept_delay")}>
                                    <label className={ accept_late_reply && accept_late_reply !== "" && accept_late_reply === "not_accept_delay" ?
                                        "chekboxLabel checado" : "chekboxLabel"
                                    }   
                                    ></label>
                                    <p className="texto"> Não aceitar atraso </p>
                                </button>


                            </div>
                            <p>
                            Quando um atraso não é aceito, as respostas do colaborador que passarem da deadline serão registradas para a pesquisa do período seguinte.
                            </p>
                        </div>

                        <div className='box'>
                            <h3>Como funciona</h3>
                            <p>
                                Os pulsos são uma forma de acompanhar o clima e a saúde de diversas áreas da empresa. E tomar ações baseado nos resultados.
                            </p>
                            <p>
                                Os colaboradores receberão uma pesquisa por email e como uma notificação no perfil do People View sobre 12 áreas da empresa para darem uma nota. 
                            </p>
                            <p>
                                Não é necessário configurar quase nada e é possível acompanhar os resultados na seção Pulsos.
                            </p>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    );
}

