import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// Geral
import AlreadyIsLoged from '../pages/Login/index_';
import Login from '../pages/Login/index';
import RecoverPassword from '../pages/RecoverPassword';
import ForgotPassword from '../pages/ForgotPassword';
import FirstAccess from '../pages/FirstAccess';
import FirstAccessCompany from '../pages/FirstAccess/index_trial';
import ResetPassword from '../pages/ResetPassword';

import Dashboard from '../pages/Dashboard';
import Administrativo from '../pages/Administrativo';
import ColoboradoresAtivo from '../pages/Administrativo/Colaboradores';
import ColoboradoresAdmissao from '../pages/Administrativo/Colaboradores/admissao';
import DesligamentoColAccounting from '../pages/Administrativo/Colaboradores/desligamento/novo-desligamento';
import DesligamentoColTerminationDoc from '../pages/Administrativo/Colaboradores/desligamento/novo-desligamento/TerminationDoc';
import DesligamentoColFinish from '../pages/Administrativo/Colaboradores/desligamento/novo-desligamento/Finish';
import DesligamentoColBoxFinish from '../pages/Administrativo/Colaboradores/desligamento/novo-desligamento/BoxFinish';


import ProcessoDesligamentoCol from '../pages/Administrativo/Colaboradores/desligamento';
import listaColDesligado from '../pages/Administrativo/Colaboradores/desligamento/deliggingList';
import ColoboradoresHabilidades from '../pages/Administrativo/Habilidades';
import ColoboradoresBeneficios from '../pages/Administrativo/Beneficios';
import ColoboradoresBeneficiosRegister from '../pages/Administrativo/Beneficios/register-benefit';
import ColoboradoresBeneficiosEdite from '../pages/Administrativo/Beneficios/edite-benefit';
import ColoboradoresBeneficioInfor from '../pages/Administrativo/Beneficios/details-benefit';
import Cargos from '../pages/Administrativo/Cargos';
import Departamentos from '../pages/Administrativo/Departamentos';
import Ferias from '../pages/Administrativo/Ferias';
import FeriasCadastrarColetivas from '../pages/Administrativo/Ferias/Cadastrar-ferias-coletivas';
import FeriasSolicitar from '../pages/Administrativo/Ferias/Solicitacao-Ferias';
import NovoColBasicInfor from '../pages/Administrativo/Colaboradores/nova-admissao/formulario-Informacoes-basicas';
import NovoColPessonalData from '../pages/Administrativo/Colaboradores/nova-admissao/formulario-dados-pessoais';
import NovoColFinishing from '../pages/Administrativo/Colaboradores/nova-admissao/Finishing';
import NovoColPessonalDataRenewToken from '../pages/Administrativo/Colaboradores/nova-admissao/formulario-dados-pessoais-renovar-token';
//NovoColPessonalDataRenewToken
import NovoColDocuments from '../pages/Administrativo/Colaboradores/nova-admissao/formulario-documentos';
import NovoColBenefits from '../pages/Administrativo/Colaboradores/nova-admissao/formulario-beneficios';
import NovoColConclusao from '../pages/Administrativo/Colaboradores/nova-admissao/formulario-conclusao';
import ColPayroll from '../pages/Administrativo/Payroll';
import ColSubmitPayroll from '../pages/Administrativo/Payroll/SubmitPayroll';
import PayrollListCol from '../pages/Administrativo/Payroll/PayrollListCol';
import SystemsConfig from '../pages/Administrativo/SystemsConfig';
import SystConfRegistCompanies from '../pages/Administrativo/SystemsConfig/RegisteredCompanies';
import SystConfAddCompanies from '../pages/Administrativo/SystemsConfig/addCompanies';
import SystConfEditCompanies from '../pages/Administrativo/SystemsConfig/editCompanies';


/////////////////// RECRUTAMENTO ///////////////////////
import Talents from '../pages/RecruitmentAndSelection/Talents';
import AllFolder from '../pages/RecruitmentAndSelection/AllFolder';
import AllCandidates from '../pages/RecruitmentAndSelection/AllCandidates';
import FolderCandidates from '../pages/RecruitmentAndSelection/FolderCandidates';
import RegisterNewCandidate from '../pages/RecruitmentAndSelection/RegisterNewCandidate';
import CandidateProfile from '../pages/RecruitmentAndSelection/CandidateProfile';
import AllVacancies from '../pages/RecruitmentAndSelection/AllVacancies';
import RegisterVacancy from '../pages/RecruitmentAndSelection/RegisterVacancy';
import CustomizePage from '../pages/RecruitmentAndSelection/CustomizePage';
import PrivacyPolicyCustomizePage from '../pages/RecruitmentAndSelection/PrivacyPolicyCustomizePage';
import SettingsCustomizePage from '../pages/RecruitmentAndSelection/SettingsCustomizePage';

import WebsiteVacancies from '../pages/WebsiteVacancies';
import VacanciesPage from '../pages/WebsiteVacancies/VacanciesPage';



/////////////////// GESTAO ///////////////////////
import ManagementFeedbacksHome from '../pages/Management/Feedbacks';
import ManagementFeedbackCollaborator from '../pages/Management/Feedbacks/feedbacksCollaborator';
import ManagementOneToOne from '../pages/Management/oneToOne';
import ManagementOneToOneCollaborator from '../pages/Management/oneToOne/oneToOneCollaborator';
import ManagementPulsos from '../pages/Management/Pulses/';
import ManagementPerformanceEvaluation from '../pages/Management/PerformanceEvaluation/';
import ManagementPerformanceEvaluationCol from '../pages/Management/PerformanceEvaluation/PerformanceEvaluationCol';


// import SystConfPlanBilling from '../pages/Administrativo/SystemsConfig/PlanBilling';
import SystConfPlanBillingNew from '../pages/Administrativo/SystemsConfig/PlanBilling-new';


// import SystConfManagePayment from '../pages/Administrativo/SystemsConfig/ManagePayment';
// import SystConfAddPaymentMethodt from '../pages/Administrativo/SystemsConfig/AddPaymentMethod';
// import SystConfEditPaymentMethodt from '../pages/Administrativo/SystemsConfig/EditPaymentMethod';
// import SystConfChangePlan from '../pages/Administrativo/SystemsConfig/ChangePlan';
import SystConfChangePassword from '../pages/Administrativo/SystemsConfig/ChangePassword';
import SystConfAccountants from '../pages/Administrativo/SystemsConfig/Accountants';
import SystConfRegisterAccountant from '../pages/Administrativo/SystemsConfig/AddAccountant';
import SystConfEditAccountant from '../pages/Administrativo/SystemsConfig/EditAccountant';
import SystConfPrivacy from '../pages/Administrativo/SystemsConfig/Privacy';
import SystConfChecklist from '../pages/Administrativo/SystemsConfig/Checklist';

import ADMCommunicationChat from '../pages/ADMCommunication';
import ADMCommunicationAdWall from '../pages/ADMCommunication/AdWall';

//// ponto
import ScoreAdmin from '../pages/Administrativo/ScoreAdmin/index';
import ScoreAdminAlers from '../pages/Administrativo/ScoreAdmin/Alerts';
import ScoreAdminJourneys from '../pages/Administrativo/ScoreAdmin/Journeys';
import ScoreAdminJourneysAddEdit from '../pages/Administrativo/ScoreAdmin/JourneysAddEdite';
import ScoreAdminApprovals from '../pages/Administrativo/ScoreAdmin/Approvals';
import ScoreAdminSettings from '../pages/Administrativo/ScoreAdmin/Settings';
import ScoreAdminEmployeeTimeSheet from '../pages/Administrativo/ScoreAdmin/EmployeeTimeSheet';

import SuspendedAccountAdmin from '../pages/Error/SuspendedAccountAdmin';
import SuspendedAccountuser from '../pages/Error/SuspendedAccountUser';


/// Rotas do perfil
import ColProfilePersonal from '../pages/Administrativo/Colaboradores/Profile/Personal';
import ColProfileAddressesContacts from '../pages/Administrativo/Colaboradores/Profile/AddressesContacts';
import ColProfileDocPersonal from '../pages/Administrativo/Colaboradores/Profile/DocPersonal';
import ColProfileAbsencesAbsences from '../pages/Administrativo/Colaboradores/Profile/AbsencesAbsences';
import ColProfileProfessional from '../pages/Administrativo/Colaboradores/Profile/Professional';
import ColProfileDocProfessional from '../pages/Administrativo/Colaboradores/Profile/DocProfessional';
import ColProfileBenefits from '../pages/Administrativo/Colaboradores/Profile/Benefits';
import ColProfileVacation from '../pages/Administrativo/Colaboradores/Profile/Vacation';
import ColProfilePayroll from '../pages/Administrativo/Colaboradores/Profile/Payroll';
import ColProfileHistories from '../pages/Administrativo/Colaboradores/Profile/HistoryLastChanges';
import ColProfileHistorySalaryPromotions from '../pages/Administrativo/Colaboradores/Profile/HistorySalaryPromotions';
import ColProfileNotes from '../pages/Administrativo/Colaboradores/Profile/Notes';
import ColProfileWorkDay from '../pages/Administrativo/Colaboradores/Profile/workday';
import ColProfileAccessData from '../pages/Administrativo/Colaboradores/Profile/AccessData';
//// Fim rotas do Perfil

import ColRegisterDocOne from '../pages/User/registerDoc';
import ColRegisterDocTwo from '../pages/User/registerDoc/step-two';
import ColRegisterDocSucess from '../pages/User/registerDoc/success';
import ColRegisterDocProgressSave from '../pages/User/registerDoc/progress-saved';
import ExpiredToken from '../pages/Error/ExpiredToken';
import PageNotFound from '../pages/Error/PageNotFound';

//// User
import DashboardUser from '../pages/DashboardUser';
import DashboardUserPraiseWall from '../pages/DashboardUser/PraiseWall';
import DashboardUserPersonal from '../pages/DashboardUser/my-data/Personal';
import DashUserPersAddressesContact from '../pages/DashboardUser/my-data/AddressesContacts';
import DashUserPersDoc from '../pages/DashboardUser/my-data/DocPersonal';
import DashUserPersAbsencesAbsences from '../pages/DashboardUser/my-data/AbsencesAbsences';
import DashUserPersProfessional from '../pages/DashboardUser/my-data/Professional';
import DashUserPersProfessionalDoc from '../pages/DashboardUser/my-data/DocProfessional';
import DashUserPersProfessionalTermContract from '../pages/DashboardUser/my-data/TermContract';
import DashUserPersProfessionalPeriodicExam from '../pages/DashboardUser/my-data/PeriodicExam';
import DashUserPersProfessionalPayroll from '../pages/DashboardUser/my-data/Payroll';
import DashUserPersProfessionalWorkDay from '../pages/DashboardUser/my-data/workday';
import DashUserPersColProfileHistories from '../pages/DashboardUser/my-data/Histories';
import DashUserPersColProfileHistoriesSalaryPromotions from '../pages/DashboardUser/my-data/HistorySalaryPromotions';
import DashUserPersProfessionalNotes from '../pages/DashboardUser/my-data/Notes';
import DashUserMyBenefits from '../pages/DashboardUser/my-benefits';
import DashUserMySkills from '../pages/DashboardUser/my-skills';
import DashUserMyVacation from '../pages/DashboardUser/my-vacation';
import DashUserCommunication from '../pages/DashboardUser/communication';
import DashUserRedfinePassword from '../pages/DashboardUser/redefine-password';
import DashUserPonto from '../pages/DashboardUser/my-ponto';

// Free Trial
import FreeTrialFreeTrial from '../pages/FreeTrial';

import testando from '../pages/teste/index';


 /////////////////// SISTEM LOGS ///////////////////////
import SistemLogs from '../pages/LogsSistem';


import { isAuthenticated, isAdmin, isManager } from '../services/auth';
//const isAuthenticated= () => true;
//const isAdmin = () => true;
//const isManager = () => false;

const PrivateRoute = ({ component: Component, location, ...rest }) => (
    // eslint-disable-next-line no-sequences
    localStorage.setItem('@peopleview/urltokenstatususer', location.pathname),

    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ?
            (
                <Component {...props} />
            )
            :
            (
                <Redirect
                    to={{
                        pathname: '/validacao',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const AdminRoute = ({ component: Component, location, ...rest }) => (
    // eslint-disable-next-line no-sequences
    localStorage.setItem('@peopleview/urltokenstatusadmin', location.pathname),

    <Route
        {...rest}
        render={(props) =>

            isAdmin() ? (

                <Component {...props} />

            ) : (
                <Redirect
                    to={{
                        pathname: '/validacao',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const ManagerRoute = ({ component: Component, location, ...rest }) => (
    // eslint-disable-next-line no-sequences
    localStorage.setItem('@peopleview/urltokenstatusmanager', location.pathname),
    <Route
        {...rest}
        render={(props) =>
            isManager() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/validacao',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);
// go live
const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/cadastro" component={FreeTrialFreeTrial} />
            <Route exact path="/validacao" component={AlreadyIsLoged} />
            <Route exact path="/" component={Login} />
            <PrivateRoute exact path="/bem-vindo" component={FirstAccess} />
            <PrivateRoute exact path="/dashboard-user" component={DashboardUser} />
            <PrivateRoute exact path="/mural-de-elogios" component={DashboardUserPraiseWall} />
            <PrivateRoute exact path="/meus-dados/pessoal/" component={DashboardUserPersonal} />
            <PrivateRoute exact path="/meus-dados/pessoal/enderecos-contatos" component={DashUserPersAddressesContact} />
            <PrivateRoute exact path="/meus-dados/pessoal/documentos" component={DashUserPersDoc} />
            <PrivateRoute exact path="/meus-dados/pessoal/faltas-e-afastamentos" component={DashUserPersAbsencesAbsences} />
            <PrivateRoute exact path="/meus-dados/profissional" component={DashUserPersProfessional} />
            <PrivateRoute exact path="/meus-dados/profissional/documentos" component={DashUserPersProfessionalDoc} />
            <PrivateRoute exact path="/meus-dados/profissional/termos-e-contratos" component={DashUserPersProfessionalTermContract} />
            <PrivateRoute exact path="/meus-dados/profissional/exame-periotico" component={DashUserPersProfessionalPeriodicExam} />
            <PrivateRoute exact path="/meus-dados/profissional/folha-de-pagamento" component={DashUserPersProfessionalPayroll} />
            <PrivateRoute exact path="/meus-dados/profissional/jornada-de-trabalho" component={DashUserPersProfessionalWorkDay} />
            <PrivateRoute exact path="/meus-dados/historico-ultimas-alteracoes" component={DashUserPersColProfileHistories} />
            <PrivateRoute exact path="/meus-dados/historico-salario-e-promocoes" component={DashUserPersColProfileHistoriesSalaryPromotions} />
            <PrivateRoute exact path="/meus-dados/anotacoes" component={DashUserPersProfessionalNotes} />
            <PrivateRoute exact path="/meus-beneficios" component={DashUserMyBenefits} />
            <PrivateRoute exact path="/minhas-habilidades" component={DashUserMySkills} />
            <PrivateRoute exact path="/contato-com-rh" component={DashUserCommunication} />
            <PrivateRoute exact path="/configuracao/alterar-senha" component={DashUserRedfinePassword} />
            <PrivateRoute exact path="/minhas-ferias" component={DashUserMyVacation} />
            <PrivateRoute exact path="/meus-pontos" component={DashUserPonto} />

            <Route exact path="/recuperar-senha-colaborador/:token" component={ResetPassword} />
            <Route exact path="/esqueci-senha" component={ForgotPassword} />
            <Route exact path="/recuperar-senha" component={RecoverPassword} />
            <Route exact path="/cadastro-colaborador/:token" component={ColRegisterDocOne} />
            <Route exact path="/cadastro-colaborador/documentos/:token" component={ColRegisterDocTwo} />
            <Route exact path="/cadastro-colaborador/dados-enviados/:token" component={ColRegisterDocSucess} />
            <Route exact path="/cadastro-colaborador/progresso-salvo/:token" component={ColRegisterDocProgressSave} />

            <Route exact path="/token-expirado" component={ExpiredToken} />
            <Route exact path="/pagina-nao-encontrada" component={PageNotFound} />

            {/* <Route exact path="/lp/vagas-abertas" component={WebsiteVacancies} /> */}
            <Route exact path="/lp/:id" component={WebsiteVacancies} />
            <Route exact path="/lp/:id/:id_vacancie" component={VacanciesPage} />

            <Route exact path="/primeiro-acesso/:token" component={FirstAccessCompany} />
            <Route exact path="/testando" component={testando} />

            <AdminRoute exact path="/dashboard" component={Dashboard} />
            <AdminRoute exact path="/administrativo" component={Administrativo} />
            <AdminRoute exact path="/colaboradores/ativos" component={ColoboradoresAtivo} />
            <AdminRoute exact path="/colaboradores/admissao" component={ColoboradoresAdmissao} />

            <AdminRoute exact path="/colaboradores/desligamento/:id/contabilidade" component={DesligamentoColAccounting} />
            <AdminRoute exact path="/colaboradores/desligamento/:id/rescisao-e-documentos" component={DesligamentoColTerminationDoc} />
            <AdminRoute exact path="/colaboradores/desligamento/:id/finalizar" component={DesligamentoColFinish} />
            <AdminRoute exact path="/colaboradores/desligamento/:id/finalizado" component={DesligamentoColBoxFinish} />
            <AdminRoute exact path="/colaboradores/processo-desligamento" component={ProcessoDesligamentoCol} />
            <AdminRoute exact path="/colaboradores/lista-colaboradores-desligado" component={listaColDesligado} />

            <AdminRoute exact path="/habilidades" component={ColoboradoresHabilidades} />
            <AdminRoute exact path="/beneficios" component={ColoboradoresBeneficios} />
            <AdminRoute exact path="/beneficios/criar" component={ColoboradoresBeneficiosRegister} />
            <AdminRoute exact path="/beneficios/:id/editar" component={ColoboradoresBeneficiosEdite} />
            <AdminRoute exact path="/beneficio/informacoes/:id" component={ColoboradoresBeneficioInfor} />
            <AdminRoute exact path="/cargos" component={Cargos} />
            <AdminRoute exact path="/departamentos" component={Departamentos} />
            <AdminRoute exact path="/ferias" component={Ferias} />
            <AdminRoute exact path="/ferias/:vacationCollaborator" component={Ferias} />
            <AdminRoute exact path="/feriascoletivas/cadastrar" component={FeriasCadastrarColetivas} />
            <AdminRoute exact path="/feriascoletivas/cadastrar/:id" component={FeriasCadastrarColetivas} />
            <AdminRoute exact path="/ferias/solicitacao-de-ferias/:id" component={FeriasSolicitar} />

            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-Informacoes-basicas/" component={NovoColBasicInfor} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id" component={NovoColBasicInfor} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-dados-pessoais" component={NovoColPessonalData} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-dados-pessoais/:id" component={NovoColPessonalData} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-dados-pessoais-renovar-token/:token" component={NovoColPessonalDataRenewToken} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-documentos" component={NovoColDocuments} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-documentos/:id" component={NovoColDocuments} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-beneficios" component={NovoColBenefits} />
            <AdminRoute exact path="/colaboradores/nova-admissao/formulario-beneficios/:id" component={NovoColBenefits} />
            <AdminRoute exact path="/colaboradores/nova-admissao/enviar-contador/:id" component={NovoColConclusao} />
            <AdminRoute exact path="/colaboradores/nova-admissao/finalizado/:id" component={NovoColFinishing} />

            <AdminRoute exact path="/colaboradores/:id/pessoal" component={ColProfilePersonal} />
            <AdminRoute exact path="/colaboradores/:id/pessoal/enderecos-contatos" component={ColProfileAddressesContacts} />
            <AdminRoute exact path="/colaboradores/:id/pessoal/documentos" component={ColProfileDocPersonal} />
            <AdminRoute exact path="/colaboradores/:id/pessoal/faltas-e-afastamentos" component={ColProfileAbsencesAbsences} />
            <AdminRoute exact path="/colaboradores/:id/profissional" component={ColProfileProfessional} />
            <AdminRoute exact path="/colaboradores/:id/profissional/beneficios-do-colaborador" component={ColProfileBenefits} />
            <AdminRoute exact path="/colaboradores/:id/profissional/ferias" component={ColProfileVacation} />
            <AdminRoute exact path="/colaboradores/:id/profissional/folha-de-pagamento" component={ColProfilePayroll} />
            <AdminRoute exact path="/colaboradores/:id/profissional/documentos" component={ColProfileDocProfessional} />
            <AdminRoute exact path="/colaboradores/:id/profissional/jornada-de-trabalho" component={ColProfileWorkDay} />
            <AdminRoute exact path="/colaboradores/:id/dados-de-acesso" component={ColProfileAccessData} />
            <AdminRoute exact path="/colaboradores/:id/historico-ultimas-alteracoes" component={ColProfileHistories} />
            <AdminRoute exact path="/colaboradores/:id/historico-salario-e-promocoes" component={ColProfileHistorySalaryPromotions} />
            <AdminRoute exact path="/colaboradores/:id/anotacoes" component={ColProfileNotes} />




            <AdminRoute exact path="/folha-de-pagamento" component={ColPayroll} />
            <AdminRoute exact path="/folha-de-pagamento/ano/mes/enviar/:payroll" component={ColSubmitPayroll} />
            <AdminRoute exact path="/folha-de-pagamento/ano/mes/tipo/:id" component={PayrollListCol} />

            <AdminRoute exact path="/contato-com-colaborador" component={ADMCommunicationChat} />
            <AdminRoute exact path="/mural-de-anuncios" component={ADMCommunicationAdWall} />

            <AdminRoute exact path="/configuracao/dados-da-empresa/" component={SystemsConfig} />
            <AdminRoute exact path="/configuracao/empresas-cadastradas" component={SystConfRegistCompanies} />
            <AdminRoute exact path="/configuracao/adicionar-empresa" component={SystConfAddCompanies} />
            <AdminRoute exact path="/configuracao/editar-empresa/:id" component={SystConfEditCompanies} />




            <AdminRoute exact path="/configuracao/plano-e-cobranca" component={SystConfPlanBillingNew} />
            {/* <AdminRoute exact path="/configuracao/plano-e-cobranca-new" component={SystConfPlanBillingNew} /> */}
            {/* <AdminRoute exact path="/configuracao/plano-e-cobranca/gerenciar-pagamento" component={SystConfManagePayment} /> */}
            {/* <AdminRoute exact path="/configuracao/plano-e-cobranca/adicionar-metodo-pagamento" component={SystConfAddPaymentMethodt} />
            <AdminRoute exact path="/configuracao/plano-e-cobranca/editar-metodo-pagamento/:id" component={SystConfEditPaymentMethodt} />
            <AdminRoute exact path="/configuracao/plano-e-cobranca/trocar-plano" component={SystConfChangePlan} /> */}





            <AdminRoute exact path="/configuracao/alterar-senha-admin" component={SystConfChangePassword} />
            <AdminRoute exact path="/configuracao/contadores" component={SystConfAccountants} />
            <AdminRoute exact path="/configuracao/cadastrar-contador" component={SystConfRegisterAccountant} />
            <AdminRoute exact path="/configuracao/editar-contador/:id" component={SystConfEditAccountant} />
            <AdminRoute exact path="/configuracao/Privacidade" component={SystConfPrivacy} />
            <AdminRoute exact path="/configuracao/checklist" component={SystConfChecklist} />




            <AdminRoute exact path="/ponto" component={ScoreAdmin} />
            <AdminRoute exact path="/ponto/alertas" component={ScoreAdminAlers} />
            <AdminRoute exact path="/ponto/jornadas" component={ScoreAdminJourneys} />
            <AdminRoute exact path="/ponto/cadastrar-jornada" component={ScoreAdminJourneysAddEdit} />
            <AdminRoute exact path="/ponto/editar-jornada/:id" component={ScoreAdminJourneysAddEdit} />
            <AdminRoute exact path="/ponto/aprovacoes" component={ScoreAdminApprovals} />
            <AdminRoute exact path="/ponto/configuracoes" component={ScoreAdminSettings} />
            <AdminRoute exact path="/folha-de-ponto/:id" component={ScoreAdminEmployeeTimeSheet} />




            {/* /////////////////// RECRUTAMENTO ///////////////////////  */}
            <AdminRoute exact path="/recrutamento/talentos" component={Talents} />
            <AdminRoute exact path="/recrutamento/talentos/pastas" component={AllFolder} />
            <AdminRoute exact path="/recrutamento/talentos/candidatos" component={AllCandidates} />
            <AdminRoute exact path="/recrutamento/talentos/pasta/:id" component={FolderCandidates} />
            <AdminRoute exact path="/recrutamento/cadastro-candidato" component={RegisterNewCandidate} />
            <AdminRoute exact path="/recrutamento/editar-candidato/:id" component={RegisterNewCandidate} />
            <AdminRoute exact path="/recrutamento/perfil-candidato/:id" component={CandidateProfile} />
            <AdminRoute exact path="/recrutamento/vagas" component={AllVacancies} />
            <AdminRoute exact path="/recrutamento/cadastrar-vaga" component={RegisterVacancy} />
            <AdminRoute exact path="/recrutamento/editar-vaga/:id" component={RegisterVacancy} />
            <AdminRoute exact path="/recrutamento/personalizar-pagina" component={CustomizePage} />
            <AdminRoute exact path="/recrutamento/politica-e-privacidade-da-pagina" component={PrivacyPolicyCustomizePage} />
            <AdminRoute exact path="/recrutamento/configuracoes-da-pagina" component={SettingsCustomizePage} />



            {/* /////////////////// GESTAO ///////////////////////  */}
            <AdminRoute exact path="/gestao/feedbacks" component={ManagementFeedbacksHome} />
            <AdminRoute exact path="/gestao/feedback/:id" component={ManagementFeedbackCollaborator} />
            <AdminRoute exact path="/gestao/one-to-one" component={ManagementOneToOne} />
            <AdminRoute exact path="/gestao/one-to-one/:id" component={ManagementOneToOneCollaborator} />
            <AdminRoute exact path="/gestao/pulsos" component={ManagementPulsos} />
            <AdminRoute exact path="/gestao/avaliacao-de-desempenho" component={ManagementPerformanceEvaluation} />
            <AdminRoute exact path="/gestao/avaliacao-de-desempenho/:id" component={ManagementPerformanceEvaluationCol} />




            {/* /////////////////// SISTEM LOGS ///////////////////////  */}
            <AdminRoute exact path="/logs-sistema" component={SistemLogs} />

            <AdminRoute exact path="/conta-suspensa-administrador" component={SuspendedAccountAdmin} />
            <Route exact path="/conta-suspensa-colaborador" component={SuspendedAccountuser} />



            <PrivateRoute
                exact
                path="/dashboard-user"
                component={DashboardUser}
            />
            <ManagerRoute
                exact
                path="/colaboradores/"
                component={ColoboradoresAtivo}
            />
            <Route path="*" component={PageNotFound} />
            {/* <Route path="*" component={() => <h1>Page not found</h1>} /> */}

        </Switch>
    </BrowserRouter>
);

export default Routes;
