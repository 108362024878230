import React from 'react';

import { Pagination } from 'antd';
import NextIcon from "../../assets/images/arrow-right.svg"
import PrevIcon from "../../assets/images/arrow-left2.svg"

export default function paginationGeral({pages, page, setPage, loadPage,doNotAdd, pagesTotalItem, limit
}){

    let pageMAisUm = parseInt(page) + 1;

   const myChangeHandlerPaginetion =  (page)  =>{
        let pageVal = doNotAdd === undefined || doNotAdd === false ?
        parseInt(page)  - 1 : parseInt(page) ;
        setPage(pageVal);
        console.log(pageVal)
        return
    }
    const itemRender = (_, type, originalElement) => {

        if (type === 'prev') {
            return <p> <img src={PrevIcon} alt="" /> anterior</p>;
        }
        if (type === 'next') {
            return <p>  próxima <img src={NextIcon} alt="" /></p>;
        }
        return originalElement;

    };
    return (
        <>
        <div className='paginacao-componente'>
            {
                pages === 0 ? "":
                <Pagination  
                    pageSize={limit}
                    current={doNotAdd === undefined || doNotAdd === false ? parseInt(page) : pageMAisUm }
                    size="default"
                    itemRender={itemRender} 
                    // defaultCurrent={doNotAdd === undefined || doNotAdd === false ? parseInt(page) : pageMAisUm}
                    onChange={myChangeHandlerPaginetion}
                    total={pagesTotalItem}  
                />
            }
        </div>    
        
        
        </>
    )
}