import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Beneficios/header';
import Informacoes from '../../../components/Administrativo/Beneficios/details-benefit/informacoes';
import Relatorios from '../../../components/Administrativo/Beneficios/details-benefit/relatorios';
import QuantCol from '../../../components/Administrativo/Beneficios/details-benefit/quantity-col';
import TableCol from '../../../components/Administrativo/Beneficios/details-benefit/table-col';
import swal from 'sweetalert';
import './styles.scss';

export default function ColBeneficio(props) {
    const { id } = props.match.params;
    const { path } = props.match;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ arrInfo, setArrInfo ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [
        nameFilter,
        setNameFilter
    ] = useState();

    // alphabeticalOrder
    const [
        alphabeticalOrder,
        setAlphabeticalOrder
    ] = useState(false);

    // firstHighestValue
    const [ firstHighestValue, setFirstHighestValue ] = useState(false);

    // firstLowestValue
    const [
        firstLowestValue,
        setFirstLowestValue
    ] = useState(false);

    // statusActive
    const [
        statusActive,
        setStatusActive
    ] = useState(false);

    // statusUnbound
    const [
        statusUnbound,
        setStatusUnbound
    ] = useState(false);

      /////////////////////////////////////////////////////////////////////////
     ///////   Buscar Informações dos benefícios    ////////////////
    /////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loaddetailbenefit(id);
        loadcollaboratorBenefitsActives(id);
        loadcollaboratorBenefitsUnbound(id);
        loadcollaboratorBenefitsAll(id);
        loadcollaboratorbeneftiswithpagination(id);
    }, [id]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPageDetailBenefit ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ detailBenefit, setdetailBenefit] = useState([]);

    async function loaddetailbenefit(id) {

        setLoadPageDetailBenefit(true)
        await api.get(`/beneftis/${id}`)
        .then(response => {
            setdetailBenefit(response.data);
            setLoadPageDetailBenefit(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageDetailBenefit(false)
        })

    }


      /////////////////////////////////////////////////////////////////////////
     ///////   Buscar dos colaboradores neste benefícios   ////////////////
    /////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPagecollaboratorBenefitsActives ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBenefitsActives, setCollaboratorBenefitsActives] = useState([]);


    async function loadcollaboratorBenefitsActives(id) {

        setLoadPagecollaboratorBenefitsActives(true)
        await api.get(`collaborator-beneftis-count/?benefits=${id}&status=Ativo`)
        .then(response => {
            if(response.data.length === 0) {
                setLoadPagecollaboratorBenefitsActives(false)
                setCollaboratorBenefitsActives(0);
            }
            else {
                setLoadPagecollaboratorBenefitsActives(false)
                setCollaboratorBenefitsActives(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPagecollaboratorBenefitsActives(false)
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPagecollaboratorBenefitsUnbound ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBenefitsUnbound, setCollaboratorBenefitsUnbound] = useState([]);


    async function loadcollaboratorBenefitsUnbound(id) {

        setLoadPagecollaboratorBenefitsUnbound(true)
        await api.get(`collaborator-beneftis-count/?benefits=${id}&status=Desvinculado`)
        .then(response => {
            if(response.data.length === 0) {
                setLoadPagecollaboratorBenefitsUnbound(false)
                setCollaboratorBenefitsUnbound(0);
            }
            else {
                setLoadPagecollaboratorBenefitsUnbound(false)
                setCollaboratorBenefitsUnbound(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPagecollaboratorBenefitsUnbound(false)
        })
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPagecollaboratorBenefitsAll ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBenefitsAll, setCollaboratorBenefitsAll] = useState([]);
    async function loadcollaboratorBenefitsAll(id) {
        setLoadPagecollaboratorBenefitsAll(true)
        await api.get(`collaborator-beneftis-count/?benefits=${id}`)
        .then(response => {
            if(response.data.length === 0) {
                setLoadPagecollaboratorBenefitsAll(false)
                setCollaboratorBenefitsAll(0);
            }
            else {
                setLoadPagecollaboratorBenefitsAll(false)
                setCollaboratorBenefitsAll(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPagecollaboratorBenefitsAll(false)
        })
    }
      /////////////////////////////////////////////////////////////////////////
     ///////   Buscar colaboradores neste benefício    ////////////////
    /////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageCollaboratorBenefit, setLoadPageCollaboratorBenefit ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBenefit, setCollaboratorBenefit] = useState([]);
    async function loadCollaboratorBenefit(id) {
        setLoadPageCollaboratorBenefit(true)
        await api.get(`collaborator-beneftis/?benefits=${id}`)
        .then(response => {
            setPage(1);
            setCollaboratorBenefit(response.data);
            setLoadPageCollaboratorBenefit(false);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageCollaboratorBenefit(false);
        })

    }

      /////////////////////////////////////////////////////////////////////////
     ///////   Buscar Informações dos benefícios por nome e vínculo    ////////////////
    /////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    async function loadfilterCollaboratorBenefit(event) {
        event.preventDefault()

        setLoadPageCollaboratorBenefit(true)

        if(event.target.value.length === 0) {

            setNameFilter();

            if(alphabeticalOrder === true) {

                loadCollaboratorAlphabeticalOrder();

            }
            else
            if(firstHighestValue === true) {

                loadCollaboratorFirstHighestValue();

            }
            else
            if(firstLowestValue === true) {

                loadCollaboratorFirstLowestValue();

            }
            else
            if(statusActive === true) {

                loadCollaboratortStatusActive();

            }
            else
            if(statusUnbound === true) {

                loadCollaboratortStatusUnbound();

            }
            else {

                loadcollaboratorbeneftiswithpagination(id);

            }

        }
        else {

            const name = event.target.value;

            setNameFilter(event.target.value);

            if(alphabeticalOrder === true) {

                await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=1&name=${name}`)
                .then(response => {
                  
                    const arrInfo = response.data[1];
                    setCollaboratorBenefit(response.data[0]);
                    setArrInfo(arrInfo[0]);
                    setLoadPageCollaboratorBenefit(false);


                }).catch(error => {

                    setLoadPageCollaboratorBenefit(false);
                    setCollaboratorBenefit([]);
                    setArrInfo([])
                    setPage(1);

                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(firstHighestValue === true) {

                await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=collaboratorCosts&order=desc&name=${name}`)
                .then(response => {
                    
                    const arrInfo = response.data[1];
                    setCollaboratorBenefit(response.data[0]);
                    setArrInfo(arrInfo[0]);
                    setLoadPageCollaboratorBenefit(false);

                }).catch(error => {

                    setLoadPageCollaboratorBenefit(false);
                    setCollaboratorBenefit([]);
                    setArrInfo([])
                    setPage(1);

                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(firstLowestValue === true) {

                await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=collaboratorCosts&order=asc&name=${name}`)
                .then(response => {
                    
                    const arrInfo = response.data[1];
                    setCollaboratorBenefit(response.data[0]);
                    setArrInfo(arrInfo[0]);
                    setLoadPageCollaboratorBenefit(false);

                }).catch(error => {

                    setLoadPageCollaboratorBenefit(false);
                    setCollaboratorBenefit([]);
                    setArrInfo([])
                    setPage(1);

                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(statusActive === true) {

                await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=status&order=asc&name=${name}`)
                .then(response => {
                    
                    const arrInfo = response.data[1];
                    setCollaboratorBenefit(response.data[0]);
                    setArrInfo(arrInfo[0]);
                    setLoadPageCollaboratorBenefit(false);

                }).catch(error => {

                    setLoadPageCollaboratorBenefit(false);
                    setCollaboratorBenefit([]);
                    setArrInfo([])
                    setPage(1);

                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(statusUnbound === true) {

                // await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=status&order=desc&name=${name}`)
                .then(response => {
                    
                    const arrInfo = response.data[1];
                    setCollaboratorBenefit(response.data[0]);
                    setArrInfo(arrInfo[0]);
                    setLoadPageCollaboratorBenefit(false);

                }).catch(error => {

                    setLoadPageCollaboratorBenefit(false);
                    setCollaboratorBenefit([]);
                    setArrInfo([])
                    setPage(1);

                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else {

                await api.get(`/collaborator-beneftis/?benefits=${id}&name=${name}`)
                .then(response => {
                   
                    const arrInfo = response.data[1];
                    setCollaboratorBenefit(response.data[0]);
                    setArrInfo(arrInfo[0]);
                    setLoadPageCollaboratorBenefit(false);

                }).catch(error => {

                    setLoadPageCollaboratorBenefit(false);
                    setCollaboratorBenefit([]);
                    setArrInfo([])
                    setPage(1);

                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }

        }



    }

      /////////////////////////////////////////////////////////////////////////
     ///////   Filtros de colaboradores com este benefício    ////////////////
    /////////////////////////////////////////////////////////////////////////

    //collaborator-beneftis/?benefits=615356d77586e6470593ccba&sortBy=nameCollaborador&order=asc
    async function loadCollaboratorAlphabeticalOrder() {

        // setLoadPageCollaboratorBenefit(true);
        setAlphabeticalOrder(true);
        setFirstHighestValue(false);
        setFirstLowestValue(false);
        setStatusActive(false);
        setStatusUnbound(false);

        setLoadPageCollaboratorBenefit(true)

        if(nameFilter !== undefined) {

            await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=1&name=${nameFilter}`)
            .then(response => {

                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);

            }).catch(error => {
                setLoadPageCollaboratorBenefit(false);
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true`)
            .then(response => {
                setLoadPageCollaboratorBenefit(false);
                const docs_a  = response.data[0];
                const arrInfo = response.data[1];
                let itensCopyCollaborator = []
                // => Se total de colaboradores forem menor que o limite
                if(arrInfo[0].total < arrInfo[0].limite) {

                    for(let dcs=0; dcs < arrInfo[0].total; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorBenefit(itensCopyCollaborator);
                    setArrInfo(arrInfo[0]);

                }
                else {

                    for(let dcs=0; dcs < arrInfo[0].limite; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorBenefit(itensCopyCollaborator);
                    setArrInfo(arrInfo[0]);

                }
                // => Final de => Se total de colaboradores forem menor que o limite
                setPage(1);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }

    }

    //collaborator-beneftis/?benefits=615356d77586e6470593ccba&sortBy=collaboratorCosts&order=desc
    async function loadCollaboratorFirstHighestValue() {

        // setLoadPageCollaboratorBenefit(true);
        setAlphabeticalOrder(false);
        setFirstHighestValue(true);
        setFirstLowestValue(false);
        setStatusActive(false);
        setStatusUnbound(false);

        setLoadPageCollaboratorBenefit(true)

        if(nameFilter !== undefined) {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=collaboratorCosts&order=desc&name=${nameFilter}`)
            .then(response => {

                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPageCollaboratorBenefit(false);
            });

        }
        else {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=collaboratorCosts&order=desc`)
            .then(response => {

                setLoadPageCollaboratorBenefit(false);
                const arrInfo = response.data[1];

                let pagina = parseInt(arrInfo[0].pagina)

                let itensCopyCollaborator = [];

                const docs_a    = response.data[0];

                if(pagina === arrInfo[0].pages) {

                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;

                    for(let dcs=inicio; dcs < final; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorBenefit(itensCopyCollaborator);
                    setArrInfo(arrInfo[0]);

                }
                else {

                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                    for(let dcs=inicio; dcs < final; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setCollaboratorBenefit(itensCopyCollaborator);
                    setArrInfo(arrInfo[0]);
                }
                setPage(1);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }

    }

    //collaborator-beneftis/?benefits=615356d77586e6470593ccba&sortBy=collaboratorCosts&order=asc
    async function loadCollaboratorFirstLowestValue() {

        // setLoadPageCollaboratorBenefit(true);
        setAlphabeticalOrder(false);
        setFirstHighestValue(false);
        setFirstLowestValue(true);
        setStatusActive(false);
        setStatusUnbound(false);

        setLoadPageCollaboratorBenefit(true)

        if(nameFilter !== undefined) {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=collaboratorCosts&order=asc&name=${nameFilter}`)
            .then(response => {
                
                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);

            }).catch(error => {
                setLoadPageCollaboratorBenefit(false);
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });

            });

        }
        else {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=collaboratorCosts&order=asc`)
            .then(response => {
                
                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPageCollaboratorBenefit(false);
            });

        }

    }

    //collaborator-beneftis/?benefits=615356d77586e6470593ccba&sortBy=status&order=asc
    async function loadCollaboratortStatusActive() {

        // setLoadPageCollaboratorBenefit(true);
        setAlphabeticalOrder(false);
        setFirstHighestValue(false);
        setFirstLowestValue(false);
        setStatusActive(true);
        setStatusUnbound(false);

        setLoadPageCollaboratorBenefit(true)

        if(nameFilter !== undefined) {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=status&order=asc&name=${nameFilter}`)
            .then(response => {
                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);
            }).catch(error => {
                setLoadPageCollaboratorBenefit(false);
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=status&order=asc`)
            .then(response => {
                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPageCollaboratorBenefit(false);
            });

        }

    }

    //collaborator-beneftis/?benefits=615356d77586e6470593ccba&sortBy=status&order=asc
    async function loadCollaboratortStatusUnbound() {

        // setLoadPageCollaboratorBenefit(true);
        setAlphabeticalOrder(false);
        setFirstHighestValue(false);
        setFirstLowestValue(false);
        setStatusActive(false);
        setStatusUnbound(true);

        setLoadPageCollaboratorBenefit(true)

        if(nameFilter !== undefined) {


            // await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=status&order=desc&name=${nameFilter}`)
            .then(response => {
                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPageCollaboratorBenefit(false);
            });

        }
        else {

            await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=1&sortBy=status&order=desc`)
            .then(response => {
                const arrInfo = response.data[1];
                setCollaboratorBenefit(response.data[0]);
                setArrInfo(arrInfo[0]);
                setLoadPageCollaboratorBenefit(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPageCollaboratorBenefit(false);
            });
        }
    }

      /////////////////////////////////////////////////////////////////////////
     ///////   paginação de colaboradores com este benefício    //////////////
    /////////////////////////////////////////////////////////////////////////



    // eslint-disable-next-line react-hooks/rules-of-hooks
    async function loadcollaboratorbeneftiswithpagination(id) {

        setLoadPageCollaboratorBenefit(true)
        setAlphabeticalOrder(false);
        setFirstHighestValue(false);
        setFirstLowestValue(false);
        setStatusActive(false);
        setStatusUnbound(false);

        await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true`)
        .then(response => {
            const docs_a  = response.data[0];
            const arrInfo = response.data[1];
            let itensCopyCollaborator = []
            // => Se total de colaboradores forem menor que o limite
            if(arrInfo[0].total < arrInfo[0].limite) {
                for(let dcs=0; dcs < arrInfo[0].total; dcs++){
                    itensCopyCollaborator.push(docs_a[dcs]);
                }
                setCollaboratorBenefit(itensCopyCollaborator);
                setArrInfo(arrInfo[0]);
            }
            else {
                for(let dcs=0; dcs < arrInfo[0].limite; dcs++){
                    itensCopyCollaborator.push(docs_a[dcs]);
                }
                setCollaboratorBenefit(itensCopyCollaborator);
                setArrInfo(arrInfo[0]);
            }
            setLoadPageCollaboratorBenefit(false);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageCollaboratorBenefit(false);
        });
    }
    // loadcollaboratorbeneftiswithpagination(id);
    async function prevPage() {

        if (page === 1) return;

        const pageNumber = page - 1;
        setPage(pageNumber);
        async function loadcollaboratorvacationprevpage(page = pageNumber) {

            setLoadPageCollaboratorBenefit(true);

            if(nameFilter !== undefined) {

                if(alphabeticalOrder === true) {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&name=${nameFilter}`)
                    .then(response => {
                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {});

                }
                else
                if(firstHighestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=desc&name=${nameFilter}`)
                    .then(response => {
                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        setLoadPageCollaboratorBenefit(false);
                    });

                }
                else
                if(firstLowestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=asc&name=${nameFilter}`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusActive === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=asc&name=${nameFilter}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {});

                }
                else
                if(statusUnbound === true) {

                    // await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=desc&name=${nameFilter}`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }
            else {

                if(alphabeticalOrder === true) {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}`)
                    .then(response => {
                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);;

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstHighestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=desc`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstLowestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=asc`)
                    .then(response => {
                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusActive === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=asc`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusUnbound === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=desc`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}`)
                    .then(response => {

                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }

        }
        loadcollaboratorvacationprevpage();
    }

    async function nextPage() {

        if (page === arrInfo.pages) return;

        const pageNumber = page + 1;
        setPage(pageNumber);
        async function loadcollaboratorvacationsnextpage(page = pageNumber) {

            setLoadPageCollaboratorBenefit(true);

            if(nameFilter !== undefined) {

                if(alphabeticalOrder === true) {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&name=${nameFilter}`)
                    .then(response => {

                       
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstHighestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=desc&name=${nameFilter}`)
                    .then(response => {

                       
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstLowestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=asc&name=${nameFilter}`)
                    .then(response => {


                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusActive === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=asc&name=${nameFilter}`)
                    .then(response => {

                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusUnbound === true) {

                    // await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=desc&name=${nameFilter}`)
                    .then(response => {

                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                    .then(response => {

                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }
            else {

                if(alphabeticalOrder === true) {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}`)
                    .then(response => {

                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstHighestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=desc`)
                    .then(response => {

                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstLowestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=asc`)
                    .then(response => {

                        
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusActive === true) {
                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=asc`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                else
                if(statusUnbound === true) {
                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=desc`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                else {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }

        }
        loadcollaboratorvacationsnextpage();

    }

    async function paginationPage(page) {
        const pageNumber = page;
        setPage(pageNumber);
        async function loadcollaboratorvacationspagination(page = pageNumber) {

            setLoadPageCollaboratorBenefit(true);

            if(nameFilter !== undefined) {

                if(alphabeticalOrder === true) {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&name=${nameFilter}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {});

                }
                else
                if(firstHighestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=desc&name=${nameFilter}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstLowestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=asc&name=${nameFilter}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusActive === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=asc&name=${nameFilter}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusUnbound === true) {

                    // await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=desc&name=${nameFilter}`)
                    .then(response => {

                       
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&name=${nameFilter}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }
            else {

                if(alphabeticalOrder === true) {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstHighestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=desc`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(firstLowestValue === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=collaboratorCosts&order=asc`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusActive === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=asc`)
                    .then(response => {
                        const arrInfo = response.data[1];
                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(statusUnbound === true) {

                    await api.get(`collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}&sortBy=status&order=desc`)
                    .then(response => {

                        const arrInfo = response.data[1];

                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get(`/collaborator-beneftis/?benefits=${id}&paginate=true&page=${page}`)
                    .then(response => {

                        const arrInfo = response.data[1];

                        setCollaboratorBenefit(response.data[0]);
                        setArrInfo(arrInfo[0]);
                        setLoadPageCollaboratorBenefit(false);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }


        }
        loadcollaboratorvacationspagination();

    }


      ///////////////////////////////////////////////////////////////////////////////
     ////   atualizar benefício deste colaborador -  desvincular e ativar   ////////
    ///////////////////////////////////////////////////////////////////////////////

    async function benefitCollaboratorToUnlink(idBenefit) {

        let status = 'Desvinculado';

        setLoadPageCollaboratorBenefit(true)
        await api.put(`/collaborator-beneftis/${idBenefit}`, { status, path: path })
        .then(response => {

            if(alphabeticalOrder === true) {

                loadCollaboratorAlphabeticalOrder();

            }
            else
            if(firstHighestValue === true) {

                loadCollaboratorFirstHighestValue();

            }
            else
            if(firstLowestValue === true) {

                loadCollaboratorFirstLowestValue();

            }
            else
            if(statusActive === true) {

                loadCollaboratortStatusActive();

            }
            else
            if(statusUnbound === true) {

                loadCollaboratortStatusUnbound();

            }
            else {

                loadcollaboratorbeneftiswithpagination(id);

            }

            loadcollaboratorBenefitsActives(id);
            loadcollaboratorBenefitsUnbound(id)
            loadcollaboratorBenefitsAll(id)

            setLoadPageCollaboratorBenefit(false)
            swal({ icon: "success", title: "Sucesso!", text: "benefíicio desvinculado para este colaborador com sucesso!"});

        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
        });

    }


    //benefitToActiving
    async function benefitToActiving(idBenefit) {

        let status = 'Ativo';

        setLoadPageCollaboratorBenefit(true)
        await api.put(`/collaborator-beneftis/${idBenefit}`, { status, path: path })
        .then(response => {


            if(alphabeticalOrder === true) {

                loadCollaboratorAlphabeticalOrder();

            }
            else
            if(firstHighestValue === true) {

                loadCollaboratorFirstHighestValue();

            }
            else
            if(firstLowestValue === true) {

                loadCollaboratorFirstLowestValue();

            }
            else
            if(statusActive === true) {

                loadCollaboratortStatusActive();

            }
            else
            if(statusUnbound === true) {

                loadCollaboratortStatusUnbound();

            }
            else {

                loadcollaboratorbeneftiswithpagination(id);

            }

            loadcollaboratorBenefitsActives(id);
            loadcollaboratorBenefitsUnbound(id)
            loadcollaboratorBenefitsAll(id)

            setLoadPageCollaboratorBenefit(false)

            swal({ icon: "success", title: "Sucesso!", text: "benefíicio ativado para este colaborador com sucesso!"});

        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
        });

    }

    //unlinkBenefits
    async function unlinkBenefits(idBenefit) {

        let status = 'Inativo';

        setLoadPageCollaboratorBenefit(true)
        await api.put(`/beneftis/${idBenefit}`, { status, path: path })
        .then(response => {

            setLoadPageCollaboratorBenefit(false)

            swal({ icon: "success", title: "Sucesso!", text: "Benefício desativado com sucesso!"});
            // window.location.href = `/beneficios/`;

        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
        });

    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                detailBenefit={detailBenefit}
                path={props.match.path}
                />
                <div className="page-detalhe-beneficio">
                    <div className="page-left">
                        <Informacoes
                        detailBenefit={detailBenefit ? detailBenefit : ''}
                        unlinkBenefits={unlinkBenefits}
                        />
                        <Relatorios/>
                    </div>
                    <div className="page-right">
                        <QuantCol
                        collaboratorBenefitsActives={collaboratorBenefitsActives}
                        collaboratorBenefitsUnbound={collaboratorBenefitsUnbound}
                        collaboratorBenefitsAll={collaboratorBenefitsAll}
                        />
                        <TableCol
                        loadPageCollaboratorBenefit={loadPageCollaboratorBenefit}
                        nameFilter={nameFilter}
                        collaboratorBenefit={collaboratorBenefit}
                        loadfilterCollaboratorBenefit={(event) => loadfilterCollaboratorBenefit(event)}
                        loadCollaboratorAlphabeticalOrder={loadCollaboratorAlphabeticalOrder}
                        loadCollaboratorFirstHighestValue={loadCollaboratorFirstHighestValue}
                        loadCollaboratorFirstLowestValue={loadCollaboratorFirstLowestValue}
                        loadCollaboratortStatusActive={loadCollaboratortStatusActive}
                        loadCollaboratortStatusUnbound={loadCollaboratortStatusUnbound}
                        loadCollaboratorBenefit={loadCollaboratorBenefit}
                        page={page}
                        pages={arrInfo.pages}
                        total={arrInfo.total}
                        arrInfo={arrInfo}
                        paginationPage={paginationPage}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        benefitCollaboratorToUnlink={benefitCollaboratorToUnlink}
                        benefitToActiving={benefitToActiving}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}

