import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import SubHeaderTalents from '../../components/RecruitmentAndSelection/subHeaderTalents';
import { NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../assets/images/more.svg';
import IconFolder from '../../assets/images/icon-folder.svg';
import Carregamento from '../../assets/images/carregando.gif';
import IconStar from '../../assets/images/icon-star.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import illustrationFolder from '../../assets/images/illustration-folder.svg';
import ModalAddFolder from '../../components/RecruitmentAndSelection/talents/modalAddFolder';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import api from '../../services/api';
import apiV2 from '../../services/apiV2';
import './styles.scss';


export default function Talents(props) {

    const { path } = props.match;
    const { pathname } = props.location;
    localStorage.setItem('@peopleview/voltar_recrutamento_talentos', pathname);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ folder, setFolder] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFolder, setLoadFolder ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadsFolders();
    }, []);

    async function loadsFolders() {
        setLoadFolder(true)
        //await api.get('/folder')
        await apiV2.get('/folder')
        .then(response => {
            console.log("response.data", response.data)
            setFolder(response.data?.items);
            setLoadFolder(false)
        })
        .catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    async function turnFavorite(folderId, value) {

        if(value === 'favoritar') {

            const favorite = true
            setLoadFolder(true)
            await api.put(`/folder/${folderId}`, { favorite })
            .then(response => {
                setLoadFolder(false)
                loadsFolders();
                swal({ icon: "success", title: "Sucesso!", text: "Pasta favoritada com sucesso!" });
            }).catch(error => {
                setLoadFolder(false)
                swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message ? error?.response?.data?.message : "Erro ao favoritar a pasta, tente novamente mais tarde." });
            });

        }

        if(value === 'desfavoritar') {

            const favorite = false
            setLoadFolder(true)
            await api.put(`/folder/${folderId}`, { favorite })
            .then(response => {

                setLoadFolder(false)
                loadsFolders();
                swal({ icon: "success", title: "Sucesso!", text: "Pasta desfavoritada com sucesso!" });

            }).catch(error => {
                setLoadFolder(false)
                swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message ? error?.response?.data?.message : "Erro ao desfavoritar a pasta, tente novamente mais tarde." });
            });
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModalAddFolder, setVisibleModalAddCandite] = useState(false);
    async function modalAddFolderOpen() {
        setInforFolder("")
        setVisibleModalAddCandite(!visibleModalAddFolder);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [btnFilterMarkAsFavorite, setBtnFilterMarkAsFavorite] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidates, setCandidates ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setBtnFilterAsFavoriteClicked] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inforFolder, setInforFolder ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allCandidateExclude, setAllCandidateExclude] = useState([]);
    async function modalEditFolderOpen(e) {
        setInforFolder(e)
        setAllCandidateExclude("")
        setVisibleModalAddCandite(!visibleModalAddFolder);

        if(e !== 'e') {

            if(e?.favorite === true) {
                setBtnFilterAsFavoriteClicked(false)
            }
            if(e?.favorite === false) {
                setBtnFilterAsFavoriteClicked(true)
            }

            let _id             = e._id;
            let name            = e?.name;
            let favorite        = e?.favorite;
            let candidatesSAved = e?.candidate;


            const itensCopyCollaborators = Array.from(candidates);
            if(candidatesSAved.length !== 0) {

                for(let c=0; c < candidatesSAved.length; c++){

                    let _idCandidate    =  candidatesSAved[c]._id;
                    let nameCandidate   =  candidatesSAved[c].name;
                    let sectorCandidate =  candidatesSAved[c].sector.name;

                    itensCopyCollaborators.push({
                        idcollaborator: candidates.length,
                        name          : nameCandidate,
                        sector        : sectorCandidate,
                        _id           : _idCandidate
                    });
                    setCandidates(itensCopyCollaborators);

                }

            }

            setInforFolder({ ...inforFolder, _id, name, favorite, candidate: itensCopyCollaborators, edit: true });
            setVisibleModalAddCandite(!visibleModalAddFolder);

        }
        else {
            setInforFolder([])
            setVisibleModalAddCandite(!visibleModalAddFolder);
        }

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadNameFolder, setLoadNameFolder ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nameSearch, setNameSearch] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidateFilterForThisFolder, setCandidateFilterForThisFolder ] = useState([]);
    // => MODAL FOLDER
    async function modalAddFolderHide() {
        setVisibleModalAddCandite(false);
        setInforFolder([]);
        setCandidates("");
        setBtnFilterMarkAsFavorite(false);
        setNameSearch('');
        setCandidateFilterForThisFolder([]);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [btnFilterAtivo, setBtnFilterAtivo] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [btnFilterInativo, setBtnFilterInativo] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [btnFilterFavorito, setBtnFilterFavorito] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ inforSelect, setInforSelect ] = useState("");
    async function loadsFoldersDinamico(e) {
        setInforSelect(e)
        if(e === "Ativo") {
            let StringGEt ="/folder?status=active";
            loadsFoldersFiltro(StringGEt)
        } else if (e === "Inativo") {
            let StringGEt ="/folder?status=inactive";
            loadsFoldersFiltro(StringGEt)
        } else if (e === "Favorite") {
            let StringGEt ="/folder?favorite=true";
            loadsFoldersFiltro(StringGEt)
        }else if (e === "Todos") {
            let StringGEt ="/folder";
            loadsFoldersFiltro(StringGEt)
        }
    } 
    async function loadsFoldersFiltroSalve() {

        if(
            btnFilterAtivo === true &&
            btnFilterInativo === false &&
            btnFilterFavorito === false
        ) {
            setBtnFilterAtivo(true)
            let StringGEt ="/folder?status=active";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === true &&
            btnFilterInativo === true &&
            btnFilterFavorito === false
        ) {
            setBtnFilterAtivo(true)
            let StringGEt ="/folder?status=inactive&status=active&favorite=false";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === true &&
            btnFilterInativo === true &&
            btnFilterFavorito === true
        ) {
            setBtnFilterAtivo(true)
            let StringGEt ="/folder?status=inactive&status=active&favorite=true";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === false &&
            btnFilterInativo === true &&
            btnFilterFavorito === false
        ) {
            setBtnFilterAtivo(true)
            let StringGEt ="/folder?status=inactive";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === false &&
            btnFilterInativo === true &&
            btnFilterFavorito === true
        ) {
            let StringGEt ="/folder?status=inactive&favorite=true";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === false &&
            btnFilterInativo === false &&
            btnFilterFavorito === true
        ) {
            let StringGEt ="/folder?favorite=true";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === true &&
            btnFilterInativo === false &&
            btnFilterFavorito === true
        ) {
            let StringGEt ="/folder?status=active&favorite=true";
            loadsFoldersFiltro(StringGEt)
        }else if(
            btnFilterAtivo === false &&
            btnFilterInativo === false &&
            btnFilterFavorito === false
        ) {
            let StringGEt ="/folder";
            loadsFoldersFiltro(StringGEt)
        }
    }
    async function loadsFoldersFiltro(StringGEt) {

        setLoadFolder(true)
        await apiV2.get(`${StringGEt ? StringGEt :"/folder"}`)
        .then(response => {
            setLoadFolder(false)
            setFolder(response.data.items);
        })
        .catch(erro=>{})
    }
    // INACTIVE FOLDER
    async function inactiveFolder(_id, nameFolder) {
        swal({
            title: "Atenção",
            text: `Deseja realmente desativar a pasta ${nameFolder}?`,
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {

                await api.put(`/folder/${_id}`, { status: 'inactive' })
                .then(response => {
                    setFolder([]);
                    setCandidates([])
                    loadsFoldersFiltroSalve()
                    swal({ icon: "success", title: "Sucesso!", text: "Pasta de talentos desativada com sucesso!" });

                }).catch(error => {
                    if(error?.response?.data?.message) {
                        swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    }
                    else {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao desativar pasta de talentos, tente novamente mais tarde." });
                    }
                });

            }
        })

    }
    // ACTIVE FOLDER
    async function ativarFolder(_id, ) {
        await api.put(`/folder/${_id}`, { status: 'active' })
        .then(response => {
            loadsFoldersFiltroSalve()
            swal({ icon: "success", title: "Sucesso!", text: "Pasta de talentos ativada com sucesso!" });

        }).catch(error => {
            if(error?.response?.data?.message) {
                swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
            }
            else {
                swal({ icon: "error", title: "Erro!", text: "Erro ao ativar pasta de talentos, tente novamente mais tarde." });
            }
        });
    }
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [setIsActiveSelectFilter, setIsActiveSelectFiltersetIsActiveSelectFilter] = useState(false);
    async function SelectFiltersetIsActiveSelectFilter() { setIsActiveSelectFiltersetIsActiveSelectFilter(!setIsActiveSelectFilter) }
    return (
        <>
            <Layout
            path={props.match.path}>
                <ModalAddFolder 
                    visibleModalAddFolder={visibleModalAddFolder}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                    path={props.match.path}
                    inforFolder={inforFolder}
                    setInforFolder={setInforFolder}
                    modalAddFolderHide={modalAddFolderHide}

                    loadsFoldersFiltroSalve={loadsFoldersFiltroSalve}

                    setCandidates={setCandidates}
                    candidates={candidates}
                    setBtnFilterAsFavoriteClicked={setBtnFilterAsFavoriteClicked}
                    setBtnFilterMarkAsFavorite={setBtnFilterMarkAsFavorite}
                    btnFilterMarkAsFavorite={btnFilterMarkAsFavorite}
                    setNameSearch={setNameSearch}
                    nameSearch={nameSearch}
                    setLoadNameFolder={setLoadNameFolder}
                    loadNameFolder={loadNameFolder}
                    setCandidateFilterForThisFolder={setCandidateFilterForThisFolder}
                    candidateFilterForThisFolder={candidateFilterForThisFolder}
                    setAllCandidateExclude={setAllCandidateExclude}
                    allCandidateExclude={allCandidateExclude}
                />
                <Header
                    path={path}
                    pathname={pathname}
                    modalAddFolderOpen={modalAddFolderOpen}
                    
                    // strin={modalEditFolderOpen(folde)}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                 />
                <div className='page-talents'>
                    <SubHeaderTalents />
                    <div className='List-all-folders'>
                        <div className='title-filter'>
                            <h2 className='title-box'>Todas as pastas</h2>
                            <div className='filter'>
                               <p>Mostrando</p>
                               <div className="select-busca-btn">
                                    <input
                                        type="button" 
                                        className="menu-button"
                                        placeholder='Selecione'
                                        value={
                                            inforSelect !== "" ? inforSelect : "Todos"
                                        }
                                        onClick={ SelectFiltersetIsActiveSelectFilter }
                                        onBlur={ () => (setIsActiveSelectFiltersetIsActiveSelectFilter(false)) }
                                    />
                                    <nav className={`menu ${setIsActiveSelectFilter ? "ativo": ""}`} >
                                        <Scrollbars className='listDropDown'  style={{  height: 130}}>
                                            <ul>
                                                <li>
                                                    <button
                                                    onClick={()=> loadsFoldersDinamico('Todos')}
                                                    name="folder"
                                                    >Todos</button>
                                                </li>
                                                <li>
                                                    <button
                                                    onClick={()=> loadsFoldersDinamico('Ativo')}
                                                    name="folder"
                                                    >Ativo</button>
                                                </li>
                                                <li>
                                                    <button
                                                    onClick={()=> loadsFoldersDinamico('Inativo')}
                                                    name="folder"
                                                    >Inativo</button>
                                                </li>
                                                <li>
                                                    <button
                                                    onClick={()=> loadsFoldersDinamico('Favorite')}
                                                    name="folder"
                                                    >Favoritos</button>
                                                </li>
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {
                        loadFolder ?
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            </>
                            : folder.length === 0 ?
                                <div className='nenhum-folder'>
                                    <p>Nenhuma pasta cadastrada. <br /> Crie uma nova e comece a organizar candidatos.</p>
                                    <button className='btnGreen more' onClick={modalAddFolderOpen}>Criar nova pasta</button>
                                    <img src={illustrationFolder} alt="folder" />
                                </div> : ""
                        }
                        <ul>
                        {
                        folder[0] !== undefined ? folder
                        .map((folder, index) => (
                        <>
                            <li key={index} className={
                                folder.status === "active" ? "active" : "inactive"
                            }>
                                <NavLink to={`/recrutamento/talentos/pasta/${folder._id}`}>
                                    <img src={IconFolder} alt="Icone do Folder" className='icon-folder'/>
                                    <p>{folder.name}</p>
                                    <span>
                                    {
                                        folder.favorite ?
                                        <img src={IconStar} alt={folder.name} className="IconStar"/>
                                        :<div></div>
                                    }{folder.totalCandidates}</span>

                                </NavLink>
                                <div className='view-more'>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>

                                        {
                                            folder.status === "active" ?
                                            <>
                                                <button
                                                onClick={
                                                    folder?.favorite && folder?.favorite === true ?
                                                    ()=>turnFavorite(folder._id, 'desfavoritar')
                                                    :
                                                    ()=>turnFavorite(folder._id, 'favoritar')
                                                }
                                                type="button"
                                                >
                                                    {folder?.favorite && folder?.favorite === true ? 'Desfavoritar' : 'Favoritar'}
                                                </button>
                                                <button type="button" onClick={() => modalEditFolderOpen(folder)}>
                                                    Editar
                                                </button>
                                            </>
                                            :""
                                        }
                                            {
                                                  folder.status === "active" ?
                                                <>
                                                    <button type="button" className='red'  onClick={()=>inactiveFolder(folder._id, folder.name)} >
                                                        Desativar
                                                    </button>
                                                </>:

                                                    <button type="button" className='red'  onClick={()=>ativarFolder(folder._id, folder.name)} >
                                                    Ativar
                                                    </button>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                        </>
                        )) : ("")}
                        </ul>


                    </div>
                </div>




            </Layout>
        </>
    );
}

