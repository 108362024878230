import React, { useState, useEffect, useRef } from 'react';
import '../style.scss';
import Layout from '../../../../../components/Layouts/default';
import swal from 'sweetalert';
import api from '../../../../../services/api'
import { dateMask } from '../../../../../services/mask'
import Header from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/header';
import DesligamentoSteps from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/headerDesligamentoSteps';
import StepOne from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/StepOne';
import { decodeToken } from '../../../../../services/auth';
import { toast } from 'react-toastify';

export default function Desligamento(props) {
const { id } = props.match.params;
////////////// NOTIFICATIONS ////////////
const { history, } = props;
const { user } = decodeToken();
const [ stepOne ]   = useState(true);
const [ stepTwo ]   = useState(false);
const [ stepThree ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ , setDismissalLetterSent ] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 useEffect(() => {
    // loadfileloadcollaboratordismissal(id);
    if(id && id) {
        loadcollabotorshutdown(id);
        loadfileloadcollaboratordismissal(id);
        loadcollaboratordismissalinterviewfile(id);
        loadsendtoaccountingID(id)
    }
    loadsendtoaccounting();
}, [id]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorShutdown, setCollaboratorShutdown ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadCarregamentoColl, setLoadCarregamentoColl ] = useState(false);
async function loadcollabotorshutdown(id) {
    setLoadCarregamentoColl(true)
    await api.get(`/collaborator/${id}`)
    .then(response => {
        setCollaboratorShutdown(response.data)
        if(response.data.avatar !== undefined) {
            localStorage.setItem('@peopleview/avatar', response.data.avatar);
        } else {
            localStorage.setItem('@peopleview/avatar', '');
        }
        if(response.data.name !== undefined) {
            localStorage.setItem('@peopleview/nameProfile', response.data.name);
        } else {
            localStorage.setItem('@peopleview/nameProfile', '');
        }
        if(response.data.occupation !== undefined) {
            localStorage.setItem('@peopleview/collaboratorOccupation', response.data.occupation);
            localStorage.setItem('@peopleview/collaboratorSalary', response.data.salary);
        }
        if(response.data.sector !== undefined) {
            localStorage.setItem('@peopleview/collaboratorSector', response.data.sector);
        } else {
            localStorage.setItem('@peopleview/collaboratorSector', '');
        }
        if(response.data.admissionDate !== undefined && response.data.admissionDate !== 'Invalid Date') {
            localStorage.setItem('@peopleview/collaboratorAdmissionDate', response.data.admissionDate);
        } else {
            localStorage.setItem('@peopleview/collaboratorAdmissionDate','-');
        }
        if(response.data.companyId !== undefined) {
            localStorage.setItem('@peopleview/companyName', response.data.companyId.name);
            localStorage.setItem('@peopleview/logoComany', response.data.companyId.logo);
        } else {
            localStorage.setItem('@peopleview/companyName','-');
            localStorage.setItem('@peopleview/logoComany','-');
        }
        setLoadCarregamentoColl(false)
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
     })

 }


 const companyName               = localStorage.getItem('@peopleview/companyName');
 const logoComany                = localStorage.getItem('@peopleview/logoComany');
 const nameprofile               = localStorage.getItem('@peopleview/nameProfile');

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadTypeShutdown, setLoadTypeShutdown ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadEarlyWarning, setLoadEarlyWarning ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadPriorNoticeDate, setLoadPriorNoticeDate ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadResignationDate, setLoadResignationDate ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadDismissalExam, setLoadDismissalExam ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadDismissalExaminationDate, setLoadDismissalExaminationDate ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadContinue, setLoadContinue ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusTypeShutdown   = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusEarlyWarning = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusPriorNoticeDate = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusResignationDate = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusDismissalExam = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusDismissalExaminationDate = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const focusRHObservations = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaborator, setCollaborator ] = useState([]);


async function myChangeHandlerStepOneShutDown(event) {
    let nam = event.target.name;
    let val = event.target.value;

    if(nam === 'typeShutdown') {

        if(val === 'Selecione') {
            setLoadTypeShutdown(true);
        }
        else {
            setLoadTypeShutdown(false);
            setCollaborator({ ...collaborator, [nam]: val })
        }

    }
    else
    if(nam === 'earlyWarning') {

        if(val === 'Selecione') {
            setLoadEarlyWarning(true);
        }
        else {
            setLoadEarlyWarning(false);
            setCollaborator({ ...collaborator, [nam]: val })
        }

    }
    //priorNoticeDate
    else
    if(nam === 'priorNoticeDate') {

        if(val.length > 9) {
            let priorNoticeDate = dateMask(val)
            setLoadPriorNoticeDate(false);
            setCollaborator({ ...collaborator, [nam]: priorNoticeDate })
        } else {

            let priorNoticeDate = dateMask(val)
            setCollaborator({ ...collaborator, [nam]: priorNoticeDate })
        }


    }
    //resignationDate
    else
    if(nam === 'resignationDate') {

        if(val.length > 9) {
            let resignationDate = dateMask(val)
            setLoadResignationDate(false);
            setCollaborator({ ...collaborator, [nam]: resignationDate })
        }
        else {

            let resignationDate = dateMask(val)
            setCollaborator({ ...collaborator, [nam]: resignationDate })
        }


    }
    //dismissalExam
    else
    if(nam === 'dismissalExam') {

        setLoadDismissalExam(false);
        setCollaborator({ ...collaborator, [nam]: val })

    }
    //dismissalExaminationDate
    else
    if(nam === 'dismissalExaminationDate') {

        if(val.length > 9) {
            let dismissalExaminationDate = dateMask(val)
            setLoadDismissalExaminationDate(false);
            setCollaborator({ ...collaborator, [nam]: dismissalExaminationDate })
        }
        else {

            let dismissalExaminationDate = dateMask(val)
            setCollaborator({ ...collaborator, [nam]: dismissalExaminationDate })
        }


    }
    else {

        setCollaborator({ ...collaborator, [nam]: val })

    }


}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorShutdownSaved, setCollaboratorShutdownSaved ] = useState([]);

async function savestepOneShutDownProcess(event) {
    event.preventDefault();

    setLoadContinue(true);

    if (collaboratorShutdownSaved === undefined) {
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(collaborator.typeShutdown === undefined){
                setLoadTypeShutdown(true)
                focusTypeShutdown.current.focus();
                return
            } else {
                setLoadTypeShutdown(false)
                formData.append('typeShutdown', collaborator.typeShutdown);
            }

            if(collaborator.earlyWarning === undefined || collaborator.earlyWarning === ""){
                setLoadEarlyWarning(true)
                focusEarlyWarning.current.focus();
                return
            } else {
                setLoadEarlyWarning(false)
                formData.append('earlyWarning', collaborator.earlyWarning);
            }

            if(collaborator.earlyWarning === 'true' && (collaborator.priorNoticeDate === undefined || collaborator.priorNoticeDate === "")){
                setLoadPriorNoticeDate(true)
                focusPriorNoticeDate.current.focus();
                return
            } else {
                setLoadPriorNoticeDate(false)
                formData.append('priorNoticeDate', collaborator.priorNoticeDate);
            }

            if(collaborator.resignationDate === undefined || collaborator.resignationDate === ""){
                setLoadResignationDate(true)
                focusResignationDate.current.focus();
                return
            } else {
                setLoadResignationDate(false)
                formData.append('resignationDate', collaborator.resignationDate);
            }

            if(collaborator.dismissalExam === undefined || collaborator.dismissalExam === ""){
                setLoadDismissalExam(true)
                focusDismissalExam.current.focus();
                return
            } else {
                setLoadDismissalExam(false)
                formData.append('dismissalExam', collaborator.dismissalExam);
            }

            if(collaborator.dismissalExam === 'true' && (collaborator.dismissalExaminationDate === undefined || collaborator.priorNoticeDate === "")){
                setLoadDismissalExaminationDate(true)
                focusDismissalExaminationDate.current.focus();
                return
            } else {
                setLoadDismissalExaminationDate(false)
                formData.append('dismissalExaminationDate', collaborator.dismissalExaminationDate);
            }

            if(collaborator.unlinkBenefits !== undefined){
                formData.append('unlinkBenefits', collaborator.unlinkBenefits);
            }

            if(collaborator.rhObservations !== undefined){
                formData.append('rhObservations', collaborator.rhObservations);
                //focusRHObservations.current.focus();
            }

            formData.append('collaborator', id);
            await api.post('/collaborator-dismissal', formData, config)
            .then(response => {
                setLoadContinue(false)
                setCollaborator({})
                window.location.href = `/colaboradores/desligamento/${id}/rescisao-e-documentos`;
            }).catch(error => {
                //toast.info('Erro ao cadastrar no sistema, tente mais tarde!');
                swal({ icon: "error", title: "Erro!", text: "Erro ao criar novo desligamento, tente novamente mais tarde." });
                //console.log(error)
            });

    } else {

        if(collaborator && collaborator.length === 0) {
            setLoadContinue(false)
            window.location.href = `/colaboradores/desligamento/${id}/rescisao-e-documentos`;
        } else {
            if(
                (collaborator &&
                collaborator.priorNoticeDate          === "") ||
                collaborator.resignationDate          === "" ||
                collaborator.dismissalExaminationDate === "" ||
                collaborator.rhObservations           === ""
            ) {
                setLoadContinue(false)
                swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser atualizado em branco!" });
            }
            else {


                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if (collaborator.typeShutdown !== undefined) {
                    formData.append('typeShutdown', collaborator.typeShutdown);
                }
                if (collaborator.earlyWarning !== undefined) {
                    formData.append('earlyWarning', collaborator.earlyWarning);
                }
                if (collaborator.priorNoticeDate !== undefined) {
                    formData.append('priorNoticeDate', collaborator.priorNoticeDate);
                }
                if (collaborator.resignationDate !== undefined) {
                    formData.append('resignationDate', collaborator.resignationDate);
                }
                if (collaborator.dismissalExam !== undefined) {
                    formData.append('dismissalExam', collaborator.dismissalExam);
                }
                if (collaborator.dismissalExaminationDate !== undefined) {
                    formData.append('dismissalExaminationDate', collaborator.dismissalExaminationDate);
                }
                if (collaborator.unlinkBenefits !== undefined) {
                    formData.append('unlinkBenefits', collaborator.unlinkBenefits);
                }
                if (collaborator.rhObservations !== undefined) {
                    formData.append('rhObservations', collaborator.rhObservations);
                }
                await api.put(`/collaborator-dismissal/${collaboratorShutdownSaved._id}`, formData, config)
                .then(response => {

                    setLoadContinue(false)
                    setCollaborator({ ...collaborator })
                    swal({ icon: "success", title: "Sucesso!", text: "Desligamento editado com sucesso." })
                    window.location.href = `/colaboradores/desligamento/${id}/rescisao-e-documentos`;

                }).catch(error => {

                    setLoadContinue(false)
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar desligamento, tente novamente mais tarde." });

                });

            }

        }

    }

}

async function myChangeHandlerExcludeAdmssion(event) {
    let nam = event.target.name;
    let val = event.target.value;

    setCollaborator({
        ...collaboratorShutdownSaved,
        [nam]: val
    })
}

async function excludeAdmssion(event) {
    event.preventDefault();
      swal({
        title: "Atenção",
        text: "Deseja realmente excluir o processo de desligamento deste colaborador?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('status', 'Ativo');
            formData.append('shutdownProcess', false);
            if(collaboratorShutdownSaved !== undefined) {

                await api.delete(`/collaborator-dismissal/${collaboratorShutdownSaved._id}`)
                .then(response => {
                    window.location.href = `/colaboradores/ativos`;
                    swal({ icon: "success", title: "Sucesso!", text: "Desligamneto excluído com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                });

            }else {
                await api.put(`/collaborator/${id}`, formData, config)
                .then(response => {

                    window.location.href = `/colaboradores/ativos`;
                    swal({ icon: "success", title: "Sucesso!", text: "Desligamneto excluído com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde!" });
                });

            }
        }
    })

}

  /////////////////////////////////////////////////////////////////////////////////
 //////////////////////////////  DISMISSAL FORM //////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadDismissalForm, setLoadDismissalForm ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [, setFileNamesDismissalForm] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedDismissalForm, setFilesSavedDismissalForm] = useState([]);


async function loadfileloadcollaboratordismissal(id) {
    await api.get(`/collaborator-dismissal/${id}`)
    .then(response => {
        if(response.data !== undefined) {
            setCollaboratorShutdownSaved(response.data[0]);
        }
        else {
            setCollaboratorShutdownSaved([]);
        }
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}


async function loadcollaboratordismissalinterviewfile(id) {
    setLoadDismissalForm(true)
    await api.get(`/collaborator-dismissal-interview-file/?id=${id}&category=accounting-shutdown-file`)
    .then(response => {
        setLoadDismissalForm(false)
        setFilesSavedDismissalForm(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}



async function handleDropDismissalForm(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||  
        (typeI === 'image/png') ||  
        (typeI === 'image/gif') ||  
        (typeI === 'application/pdf') ||  
        (typeI === 'application/msword') ||  
        (typeI === 'application/vnd.ms-powerpoint') ||  
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||  
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||  
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||  
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||  
        (typeI === 'application/vnd.ms-excel') ||  
        (typeI === 'text/csv') ||  
        (typeI === 'application/csv' )
    ) {


        setFileNamesDismissalForm(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {

            return
        }
        formData.append('file', acceptedFiles[0]);
        //formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'accounting-shutdown-file');
        formData.append('collaborator', id);
        setLoadDismissalForm(true)
        await api.post("/collaborator-dismissal-interview-file", formData, config)
        .then(response => {
            setDismissalLetterSent(true)
            loadcollaboratordismissalinterviewfile(id)
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
        });
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" }); 
    }

}




async function deleteFileDismissalForm(filesaved) {
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoadDismissalForm(true)
            await api.delete(`/collaborator-dismissal-interview-file/${filesaved._id}`)
                .then(() => {
                    setDismissalLetterSent(true)
                    loadcollaboratordismissalinterviewfile(id)
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
        }
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [sendToAccounting, setSendToAccounting] = useState([]);


async function loadsendtoaccounting() {
    await api.get('/accounting/')
    .then(response => {
        setSendToAccounting(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [sendOneToAccounting, setSendOneToAccounting] = useState([]);


async function loadsendtoaccountingID(id) {
    await api.get(`/accounting/${id}`)
    .then(response => {
        setLoadErrorSendEmailToAccounting(false)
        setSendOneToAccounting(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ sendToAccountingSelect, setSendToAccountingSelect ] = useState([]);

async function myChangeHandlerSendToAccounting(event) {
    event.preventDefault()
    let id = event.target.value;
    let nam = event.target.name;
    let val = event.target.value;

    setSendToAccountingSelect({ ...sendToAccountingSelect, [nam]: val })

    loadsendtoaccountingID(id);

}

// eslint-disable-next-line react-hooks/rules-of-hooks
//setLoadErrorSendEmailToAccounting
const [loadErrorSendEmailToAccounting, setLoadErrorSendEmailToAccounting] = useState(false);
const [loadButtomSendEmailToAccounting, setLoadButtomSendEmailToAccounting] = useState(false);
const [emailSent, setEmailSent] = useState(false);

async function sendemailtoaccounting() {

    setEmailSent(false);
    if(sendToAccountingSelect && sendToAccountingSelect.length === 0) {
        setLoadErrorSendEmailToAccounting(true);
    }
    else {
        setLoadErrorSendEmailToAccounting(false)
        if(filesSavedDismissalForm[0] && filesSavedDismissalForm[0].location !== undefined) {
   
            if(collaboratorShutdownSaved && collaboratorShutdownSaved) {

                swal({
                    title: "Atenção",
                    text: "Deseja realmente enviar as informações para a contabilidade?",
                    icon: "warning",
                    buttons: ["Cancelar", "OK"],
                    dangerMode: false,
                })
                .then(async (res) => {
                    if (res) {

                        const sendEmailToAccounting = {
                            accountantName           : collaboratorShutdown.name,
                            accountantEmail          : collaboratorShutdown.email,
                            dismissalLetter          : filesSavedDismissalForm[0].location,
                            nameCollaborator         : nameprofile,
                            nameCompany              : companyName,
                            logo                     : logoComany,
                            dismissalExam            : collaboratorShutdownSaved.dismissalExam,
                            dismissalExaminationDate : collaboratorShutdownSaved.dismissalExaminationDate,
                            earlyWarning             : collaboratorShutdownSaved.earlyWarning,
                            priorNoticeDate          : collaboratorShutdownSaved.priorNoticeDate,
                            resignationDate          : collaboratorShutdownSaved.resignationDate,
                            rhObservations           : collaboratorShutdownSaved.rhObservations,
                            typeShutdown             : collaboratorShutdownSaved.typeShutdown,
                            unlinkBenefits           : collaboratorShutdownSaved.unlinkBenefits
                        }

                        setLoadButtomSendEmailToAccounting(true)
                        await api.post('/send-to-accounting/', sendEmailToAccounting)
                        .then(response => {

                            setLoadButtomSendEmailToAccounting(false);
                            setEmailSent(true);
                            setSendToAccountingSelect({});
                            loadfileloadcollaboratordismissal(id);
                            
                        }).catch(error => {

                            setLoadButtomSendEmailToAccounting(false);
                            setEmailSent(false);
                            swal({ icon: "error", title: "Erro!", text: "Erro ao enviar o arquivo para a contabilidade, tente novamente mais tarde." });

                        });

                    }
                })

            }
            else {

                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(collaborator.typeShutdown === undefined){
                    setLoadTypeShutdown(true)
                    focusTypeShutdown.current.focus();
                    return
                } else {
                    setLoadTypeShutdown(false)
                    formData.append('typeShutdown', collaborator.typeShutdown);
                }

                if(collaborator.earlyWarning === undefined){
                    setLoadEarlyWarning(true)
                    focusEarlyWarning.current.focus();
                    return
                } else {
                    setLoadEarlyWarning(false)
                    formData.append('earlyWarning', collaborator.earlyWarning);
                }

                if(collaborator.earlyWarning === 'true' && collaborator.priorNoticeDate === undefined){
                    setLoadPriorNoticeDate(true)
                    focusPriorNoticeDate.current.focus();
                    return
                } else {
                    setLoadPriorNoticeDate(false)
                    formData.append('priorNoticeDate', collaborator.priorNoticeDate);
                }

                if(collaborator.resignationDate === undefined){
                    setLoadResignationDate(true)
                    focusResignationDate.current.focus();
                    return
                } else {
                    setLoadResignationDate(false)
                    formData.append('resignationDate', collaborator.resignationDate);
                }

                if(collaborator.dismissalExam === undefined){
                    setLoadDismissalExam(true)
                    focusDismissalExam.current.focus();
                    return
                } else {
                    setLoadDismissalExam(false)
                    formData.append('dismissalExam', collaborator.dismissalExam);
                }

                if(collaborator.dismissalExam === 'true' && collaborator.dismissalExaminationDate === undefined){
                    setLoadDismissalExaminationDate(true)
                    focusDismissalExaminationDate.current.focus();
                    return
                } else {
                    setLoadDismissalExaminationDate(false)
                    formData.append('dismissalExaminationDate', collaborator.dismissalExaminationDate);
                }

                if(collaborator.unlinkBenefits !== undefined){
                    formData.append('unlinkBenefits', collaborator.unlinkBenefits);
                }

                if(collaborator.rhObservations !== undefined){
                    formData.append('rhObservations', collaborator.rhObservations);
                }

                formData.append('collaborator', id);

                swal({
                    title: "Atenção",
                    text: "Deseja realmente enviar as informações para a contabilidade?",
                    icon: "warning",
                    buttons: ["Cancelar", "OK"],
                    dangerMode: false,
                })
                .then(async (res) => {
                    if (res) {

                        const sendEmailToAccounting = {
                            accountantName           : sendOneToAccounting.name,
                            accountantEmail          : sendOneToAccounting.email,
                            dismissalLetter          : filesSavedDismissalForm[0].location,
                            nameCollaborator         : nameprofile,
                            nameCompany              : companyName,
                            logo                     : logoComany,
                            dismissalExam            : collaborator.dismissalExam,
                            dismissalExaminationDate : collaborator.dismissalExaminationDate,
                            earlyWarning             : collaborator.earlyWarning,
                            priorNoticeDate          : collaborator.priorNoticeDate,
                            resignationDate          : collaborator.resignationDate,
                            rhObservations           : collaborator.rhObservations,
                            typeShutdown             : collaborator.typeShutdown,
                            unlinkBenefits           : collaborator.unlinkBenefits
                        }


                        setLoadButtomSendEmailToAccounting(true)
                        await api.post('/send-to-accounting/', sendEmailToAccounting)
                        .then(response => {

                                async function postcollaboratordismissal() {

                                    await api.post('/collaborator-dismissal', formData, config)
                                    .then(response => {

                                        setLoadButtomSendEmailToAccounting(false);
                                        setEmailSent(true);
                                        setSendToAccountingSelect({});
                                        loadfileloadcollaboratordismissal(id);
                                        
                                    }).catch(error => {

                                        setLoadButtomSendEmailToAccounting(false);
                                        setEmailSent(false);
                                        swal({ icon: "error", title: "Erro!", text: "Erro ao criar novo desligamento, tente novamente mais tarde." });
                                        
                                    });

                                }
                                postcollaboratordismissal();

                        }).catch(error => {

                            setLoadButtomSendEmailToAccounting(false);
                            setEmailSent(false);
                            swal({ icon: "error", title: "Erro!", text: "Erro ao enviar o arquivo para a contabilidade, tente novamente mais tarde." });

                        });

                    }
                })

            }

        } else {

            if(collaboratorShutdownSaved && collaboratorShutdownSaved) {
                
                swal({
                    title: "Atenção",
                    text: "Deseja realmente enviar as informações para a contabilidade?",
                    icon: "warning",
                    buttons: ["Cancelar", "OK"],
                    dangerMode: false,
                })
                .then(async (res) => {
                    if (res) {

                        const sendEmailToAccounting = {
                            accountantName           : sendOneToAccounting.name,
                            accountantEmail          : sendOneToAccounting.email,
                            nameCollaborator         : nameprofile,
                            nameCompany              : companyName,
                            logo                     : logoComany,
                            dismissalExam            : collaboratorShutdownSaved.dismissalExam,
                            dismissalExaminationDate : collaboratorShutdownSaved.dismissalExaminationDate,
                            earlyWarning             : collaboratorShutdownSaved.earlyWarning,
                            priorNoticeDate          : collaboratorShutdownSaved.priorNoticeDate,
                            resignationDate          : collaboratorShutdownSaved.resignationDate,
                            rhObservations           : collaboratorShutdownSaved.rhObservations,
                            typeShutdown             : collaboratorShutdownSaved.typeShutdown,
                            unlinkBenefits           : collaboratorShutdownSaved.unlinkBenefits
                        }

                        setLoadButtomSendEmailToAccounting(true)
                        await api.post('/send-to-accounting/', sendEmailToAccounting)
                        .then(response => {

                            setLoadButtomSendEmailToAccounting(false);
                            setEmailSent(true);
                            setSendToAccountingSelect({});
                            loadfileloadcollaboratordismissal(id);

                        }).catch(error => {

                            setLoadButtomSendEmailToAccounting(false);
                            setEmailSent(false);
                            swal({ icon: "error", title: "Erro!", text: "Erro ao enviar o arquivo para a contabilidade, tente novamente mais tarde." });

                        });

                    }
                })

            } else {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(collaborator.typeShutdown === undefined){
                    setLoadTypeShutdown(true)
                    focusTypeShutdown.current.focus();
                    return
                } else {
                    setLoadTypeShutdown(false)
                    formData.append('typeShutdown', collaborator.typeShutdown);
                }

                if(collaborator.earlyWarning === undefined){
                    setLoadEarlyWarning(true)
                    focusEarlyWarning.current.focus();
                    return
                } else {
                    setLoadEarlyWarning(false)
                    formData.append('earlyWarning', collaborator.earlyWarning);
                }

                if(collaborator.earlyWarning === 'true' && collaborator.priorNoticeDate === undefined){
                    setLoadPriorNoticeDate(true)
                    focusPriorNoticeDate.current.focus();
                    return
                } else {
                    setLoadPriorNoticeDate(false)
                    formData.append('priorNoticeDate', collaborator.priorNoticeDate);
                }

                if(collaborator.resignationDate === undefined){
                    setLoadResignationDate(true)
                    focusResignationDate.current.focus();
                    return
                } else {
                    setLoadResignationDate(false)
                    formData.append('resignationDate', collaborator.resignationDate);
                }

                if(collaborator.dismissalExam === undefined){
                    setLoadDismissalExam(true)
                    focusDismissalExam.current.focus();
                    return
                } else {
                    setLoadDismissalExam(false)
                    formData.append('dismissalExam', collaborator.dismissalExam);
                }

                if(collaborator.dismissalExam === 'true' && collaborator.dismissalExaminationDate === undefined){
                    setLoadDismissalExaminationDate(true)
                    focusDismissalExaminationDate.current.focus();
                    return
                } else {
                    setLoadDismissalExaminationDate(false)
                    formData.append('dismissalExaminationDate', collaborator.dismissalExaminationDate);
                }

                if(collaborator.unlinkBenefits !== undefined){
                    formData.append('unlinkBenefits', collaborator.unlinkBenefits);
                }

                if(collaborator.rhObservations !== undefined){
                    formData.append('rhObservations', collaborator.rhObservations);
                }

                formData.append('collaborator', id);

                swal({
                    title: "Atenção",
                    text: "Deseja realmente enviar as informações para a contabilidade?",
                    icon: "warning",
                    buttons: ["Cancelar", "OK"],
                    dangerMode: false,
                })
                .then(async (res) => {
                    if (res) {

                        const sendEmailToAccounting = {
                            accountantName           : collaboratorShutdown.name,
                            accountantEmail          : collaboratorShutdown.email,
                            nameCollaborator         : nameprofile,
                            nameCompany              : companyName,
                            logo                     : logoComany,
                            dismissalExam            : collaborator.dismissalExam,
                            dismissalExaminationDate : collaborator.dismissalExaminationDate,
                            earlyWarning             : collaborator.earlyWarning,
                            priorNoticeDate          : collaborator.priorNoticeDate,
                            resignationDate          : collaborator.resignationDate,
                            rhObservations           : collaborator.rhObservations,
                            typeShutdown             : collaborator.typeShutdown,
                            unlinkBenefits           : collaborator.unlinkBenefits
                        }

                        setLoadButtomSendEmailToAccounting(true)
                        await api.post('/send-to-accounting/', sendEmailToAccounting)
                        .then(response => {

                            async function postcollaboratordismissal() {

                                await api.post('/collaborator-dismissal', formData, config)
                                .then(response => {

                                    setLoadButtomSendEmailToAccounting(false);
                                    setEmailSent(true);
                                    setSendToAccountingSelect({});
                                    loadfileloadcollaboratordismissal(id);

                                }).catch(error => {

                                    setLoadButtomSendEmailToAccounting(false)
                                    setEmailSent(false)
                                    swal({ icon: "error", title: "Erro!", text: "Erro ao criar novo desligamento, tente novamente mais tarde." });

                                });

                            }
                            postcollaboratordismissal();

                        }).catch(error => {

                            setLoadButtomSendEmailToAccounting(false)
                            setEmailSent(false)
                            swal({ icon: "error", title: "Erro!", text: "Erro ao enviar o informações de desligamento para a contabilidade, tente novamente mais tarde." });

                        });

                    }
                })

            }

        }

    }
}

return (
    <>
        <Layout
        path={props.match.path}
        >
            <Header
            history={history}
            user={user}
            />
            <DesligamentoSteps
            id={id}
            stepOne={stepOne}
            stepTwo={stepTwo}
            stepThree={stepThree}
            collaboratorShutdownSaved={collaboratorShutdownSaved}
            collaborator={collaborator}
            path={props.match.path}
            />
            <StepOne
                id={id}
                onCollaboratorInputChangeStepOneShutDown={myChangeHandlerStepOneShutDown}
                collaboratorShutdownSaved={collaboratorShutdownSaved}
                collaborator={collaborator}
                loadTypeShutdown={loadTypeShutdown}
                loadEarlyWarning={loadEarlyWarning}
                loadPriorNoticeDate={loadPriorNoticeDate}
                loadResignationDate={loadResignationDate}
                loadDismissalExam={loadDismissalExam}
                loadDismissalExaminationDate={loadDismissalExaminationDate}
                focusTypeShutdown={focusTypeShutdown}
                focusEarlyWarning={focusEarlyWarning}
                focusPriorNoticeDate={focusPriorNoticeDate}
                focusResignationDate={focusResignationDate}
                focusDismissalExam={focusDismissalExam}
                focusDismissalExaminationDate={focusDismissalExaminationDate}
                focusRHObservations={focusRHObservations}
                loadContinue={loadContinue}
                savestepOneShutDownProcess={savestepOneShutDownProcess}
                myChangeHandlerExcludeAdmssion={myChangeHandlerExcludeAdmssion}
                excludeAdmssion={excludeAdmssion}
                loadDismissalForm={loadDismissalForm}
                filesSavedDismissalForm={filesSavedDismissalForm}
                handleDropDismissalForm={handleDropDismissalForm}
                deleteFileDismissalForm={deleteFileDismissalForm}
                sendToAccounting={sendToAccounting}
                myChangeHandlerSendToAccounting={myChangeHandlerSendToAccounting}
                sendemailtoaccounting={sendemailtoaccounting}
                loadButtomSendEmailToAccounting={loadButtomSendEmailToAccounting}
                loadErrorSendEmailToAccounting={loadErrorSendEmailToAccounting}
                sendToAccountingSelect={sendToAccountingSelect}
                loadCarregamentoColl={loadCarregamentoColl}
                emailSent={emailSent}
            />
        </Layout>
    </>
);
}

