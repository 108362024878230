import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import IconADD from '../../../assets/images/add-branco.svg'
import { Scrollbars } from 'react-custom-scrollbars';

export default function HeaderNavigation({ path, nameFilter, onUserInputChange, user, role, idAcess, setOnlyDirectReports, onlyDirectReports,loadOnlyDirectReports }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(path === '/colaboradores/admissao' ) {
            focusAdmissao.current.focus();
        } else
        if( path === '/colaboradores/lista-colaboradores-desligado' ||
            path === '/colaboradores/processo-desligamento' ) {
            focusDesligamento.current.focus();
        }

    }, [idAcess, path, role]);
    const countCollaborators = localStorage.getItem('@peopleview/countCollaborators');
    const coutAdmission      = localStorage.getItem('@peopleview/coutAdmission');
    const shutdowProcess     = localStorage.getItem('@peopleview/shutdowProcess');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusAdmissao  = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusDesligamento = useRef(null);
    async function checkOnlyDirectReports(event) {
        setOnlyDirectReports(!onlyDirectReports);
        loadOnlyDirectReports(!onlyDirectReports)
    }
    let debounceTimer;
    async function onUserInputChangeTime(event) {
        event.preventDefault();
        clearTimeout(debounceTimer);
        const name = event.target.value;
        debounceTimer = setTimeout(() => {
            onUserInputChange(name);
        }, 500); 
    }
    
    return (
        <>
            <div
             className={
                path === '/colaboradores/lista-colaboradores-desligado' ||
                path === '/colaboradores/processo-desligamento'  ||
                path === '/colaboradores/admissao' ?
                "subHeaderColaboradores mobile-deseligamento" : "subHeaderColaboradores"
             }
            >
                <div className="navegacao">
                    <Scrollbars>
                        <ul className='scroll-padding'>
                            <li>
                                <NavLink to="/colaboradores/ativos" exact activeClassName="active"><span>Ativos ({countCollaborators !== null ? countCollaborators : '0'})</span></NavLink>
                            </li>
                            <button></button>
                            {
                                role === 'admin' || role === 'master' ?
                                <>
                                <li>
                                    <NavLink to="/colaboradores/admissao" exact activeClassName="active" ><span>Admissão ({coutAdmission !== null ? coutAdmission : '0'})</span> </NavLink>
                                </li>

                                <button ref={focusAdmissao}></button>
                                <li>
                                    <NavLink to="/colaboradores/processo-desligamento" className={
                                        path === '/colaboradores/lista-colaboradores-desligado' ||
                                        path === '/colaboradores/processo-desligamento' ?
                                        "active" : ""
                                    } ><span>Desligamentos ({shutdowProcess !== null ? shutdowProcess : '0'})</span></NavLink>
                                </li>
                                </>
                                :
                                ''
                            }
                            <button ref={focusDesligamento}></button>
                        </ul>
                    </Scrollbars>
                </div>
                <div className="admissaoBusca">
                    {
                        (user && user.role === 'admin')
                        ||
                        (user && user.role === 'master') ?
                        <>
                        <NavLink to="/colaboradores/nova-admissao/formulario-Informacoes-basicas" className="btnGreen more btn-nova-admissao">Nova admissão</NavLink>
                        <NavLink to="/colaboradores/nova-admissao/formulario-Informacoes-basicas" className="btn-nova-admissaoMB"><img src={IconADD} alt="" /></NavLink>
                        </>
                        :
                        <div></div>
                    }
                    <form className="buscaColaboradores">
                    <input
                    name="name"
                    defaultValue={nameFilter && nameFilter !== undefined ? nameFilter : ''}
                    onChange={onUserInputChangeTime}
                    autoComplete="off"
                    placeholder="Filtre por colaborador, cargo, departamento e mais"
                    />
                    {
                        role === 'manager' ?
                        <div className='only-direct-reports'>
                            <label className='checkbox' htmlFor="checkbox">
                                <input type="checkbox" onChange={checkOnlyDirectReports} checked={onlyDirectReports} id="checkbox" />
                                <label htmlFor="checkbox"></label>
                                <p>Somente subordinado diretos</p>
                            </label>
                        </div>
                        :""
                    }
                    </form>
                </div>

            </div>
        </>
    );
}

