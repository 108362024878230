import React from 'react';
const ColBeneficioRelatorio = (props) => {

        return (
            <>
                <div className="relatorio-beneficio">
                    <h4>Relatórios</h4>
                    <p className="em-breve">Em breve</p>
                </div>
            </>
        );
}
export default ColBeneficioRelatorio;
