import React, {
    useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Management/headerPerformanceEvaluation';
// import IconUserNone from '../../../assets/images/iconUserNone.svg';
import FotoExemploAlvaro from '../../../assets/images/alvarobraz.png';
import '../styles.scss';

export default function homePerformanceEvaluation(props) {

   const { path } = props.match;


   ///////// SETS E FUCTION DO MODAL DE CONFIGURAR PULSOS
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleModalPerformanceEvaluation, setVisibleModalPerformanceEvaluation] = useState(false);
   async function modalPerformanceEvaluation() {
       setVisibleModalPerformanceEvaluation(!visibleModalPerformanceEvaluation);
   }


   const overallAverage = 8.2;
       return (
           <>
              <Layout path={path}>
                   <Header 
                       path={path} 
                       modalPerformanceEvaluation={modalPerformanceEvaluation}
                       visibleModalPerformanceEvaluation={visibleModalPerformanceEvaluation}
                   />
                   <div className='pages-management'>

                        <div  className='performance-evaluation-col'>
                            <div className="overview-grade-avarage">

                                <div className='box-leiaute overview'>
                                    <h2 className='titulo'>Overview <span>Última avaliação em 12.01.2022</span></h2>
                                </div>


                                <div className='box-leiaute grade-avarage'>
                                    <h2 className='titulo'>Média de notas</h2>
                                    <div className='header-avarage'>
                                        <div className='collaborador'>
                                            <div className="foto">
                                                <img alt="" src={FotoExemploAlvaro}/>
                                            </div>
                                            {/* <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div> */}
                                            <div className='infor'>
                                                <strong>Alan Fellipe Pfaffenzeller Emmerich</strong>
                                                <p>Analista de Conteúdo Jr 2</p>
                                            </div>

                                        </div>
                                        <div className='avarage'>
                                            <p> Nota média <span>{overallAverage && overallAverage !== undefined ? overallAverage : "0.0"}</span></p>
                                            <div className='barrar'>
                                                <div 
                                                    className={
                                                        overallAverage && overallAverage <= 4 ? "progresso red" :
                                                        overallAverage <= 7 ? "progresso orange" :
                                                        overallAverage >= 8  ? "progresso green" :
                                                        "progresso"
                                                    }
                                                    style={
                                                        {width: overallAverage !== undefined && overallAverage !== 0 ? 
                                                    `${parseInt(overallAverage)}0%`: "0"}
                                                    }
                                                ></div>
                                            </div>
                                        </div>
                                        <button className='btnGreen'>Baixar relatório</button>
                                    </div>


                                </div>

                            </div>
                        </div>

                   </div>
               </Layout>
           </>
       );
}

