import React from 'react';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

const SubHeaderUserProfile = ({ id, path }) => {
    return (
        <>
            <ul className="menu-perfil">
                <Scrollbars>
                    <div className="scroll-padding">
                        <li>
                            <NavLink
                                to={`/meus-dados/pessoal/`}
                                className={
                                    path === '/meus-dados/pessoal/'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <span>Pessoal</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/meus-dados/profissional`}
                                className={
                                    path === '/meus-dados/profissional'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <span>Profissional</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/meus-dados/historico-ultimas-alteracoes`}
                                className={
                                    path ===
                                        '/meus-dados/historico-ultimas-alteracoes' ||
                                    path ===
                                        '/meus-dados/historico-salario-e-promocoes'
                                        ? 'active'
                                        : ''
                                }
                            >
                                <span>Históricos</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={`/meus-dados/anotacoes`}
                                exact
                                activeClassName="active"
                            >
                                <span>Anotações</span>
                            </NavLink>
                        </li>
                    </div>
                </Scrollbars>
            </ul>
        </>
    );
};
export default SubHeaderUserProfile;
