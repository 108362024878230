import React from 'react';
import HeaderAdmin from '../HeaderAdmin';

import { transformCurrentDayInStringDayMoreHours } from '../../services/utils'

export default function headerManagement({
    path,
}) {

    const currentDay = new Date();


    return (
        <>
        <HeaderAdmin>
               <div>
                   <h1>Logs do sistema</h1>
                   <p>{transformCurrentDayInStringDayMoreHours(currentDay)}</p>
               </div>
        </HeaderAdmin>
        </>
    );
}

