import React, { useState, useEffect } from 'react';
import "./styles.scss";
import Layout from '../../../../components/Layouts/default';
import HeaderNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/header';
import HeaderEtapasNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/headerAdmissionSteps';
import FormBasicInfor from '../../../../components/Administrativo/Colaboradores/novaAdmissao/formBasicInfor';
import Checklist from '../../../../components/Administrativo/Colaboradores/novaAdmissao/checklist';
import { decodeToken } from '../../../../services/auth';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

export default function InformacoesBasicas(props) {

    const [ stepOne ]   = useState(true);
    const [ stepTwo ]   = useState(false);
    const [ stepThree ] = useState(false);
    const { id }        = props.match.params;
    const { path }      = props.match;
    const { history }   = props;
    const { user }      = decodeToken();
    const [tasksDefault, setTasksDefault] = useState([]);
    const [tasksCompany, setTasksCompany] = useState([]);
    const [loadTasksChecklist, setLoadTasksChecklist] = useState(false);
    const [tasksCompanyReduce, setTasksCompanyReduce] = useState([]);
    const [, setIdCheckListCollaborator] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    useEffect(() => {

        if(id !== undefined) {
            loadtaskcheckeddefault(id);
            loadcollaborator(id);
        }

        loadTaskCompany();

    }, [id]);


    async function loadcollaborator(id) {
        if(id && id !== undefined) {
            await api.get(`/collaborator/${id}`)
            .then(response => {

                setCollaborator(response.data);

            }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
        } 
    }

    async function loadtaskcheckeddefault(id) {
        setLoadTasksChecklist(true)
        await api.get(`/checklist-admission/${id}`)
        .then(response => {

            setLoadTasksChecklist(false)
            setTasksDefault(response.data);
            setidItem('')

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadTaskCompany() {
        setLoadTasksChecklist(true)

        await api.get('/checklist-admission/')
        .then(response => {
            setLoadTasksChecklist(false)

            if(response.data[0]?._id) {
                setIdCheckListCollaborator(response.data[0]?._id)
            }

            setTasksCompany(response.data[0]?.item);


        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    const [idItem, setidItem] = useState('');
    async function addNewTaskSave(event) {
        setidItem(String(event.idItem))
        if(event.status === false) {

            const checklistCollaborator =
            await api.get(`/checklist-collaborator-admission/${id}`)

            if(checklistCollaborator?.data !== null) {

                let idCheckColab = checklistCollaborator.data._id;

                await api.put(`checklist-collaborator-admission/${idCheckColab}`, { status: true, item: event.idItem, path: path })
                .then(response => {

                    loadtaskcheckeddefault(id);

                }).catch(error => {})

            } else {

                let collaborator = id;
                let item         = [];

                item.push({
                    _id: event.idItem
                })

                await api.post('/checklist-collaborator-admission/', { collaborator, item, path: path })
                .then(response => {

                    loadtaskcheckeddefault(id);

                }).catch(error => {})

            }
        }
        else {

            await api.put(`checklist-collaborator-admission/${event.idChecklist}`, { status: false, item: event.idItem, path: path })
            .then(response => {

                loadtaskcheckeddefault(id);

            }).catch(error => {})

        }

    }


    function myChangeHandlerTemporaryChecklist(event) {
        setidItem(String(event._id))
        let itemCheck     = Array.from(tasksCompany);
        let itemCheckReduce = [];

        itemCheck.push({
            _id: event._id,
            name: event.name,
            companyId: event.companyId,
            status: true,
        })

        setTasksCompany(itemCheck)

        let checklist = itemCheck.reduce(function(checklistcount, currentChecklist){
            if(typeof checklistcount[currentChecklist._id+'&&'+currentChecklist.name] !== "undefined"){
                checklistcount[currentChecklist._id+'&&'+currentChecklist.name]++;
                return checklistcount;
            } else {
                checklistcount[currentChecklist._id+'&&'+currentChecklist.name]=1;
                return checklistcount;
            }
        }, {});


        var checkListArray = [];
        for(var x in checklist){

            let _id = x;
            let total = checklist[x];

            checkListArray.push({
                _id,
                total,
            });

        }

        for(let cla=0; cla < checkListArray.length; cla++){

            let _id       = checkListArray[cla]._id.split('&&')[0]
            let name      = checkListArray[cla]._id.split('&&')[1]
            let companyId = event.companyId;
            let total     = checkListArray[cla].total;

            let div = (total%2)

            if(div === 0) {

                let status = true;
                itemCheckReduce.push({
                    _id,
                    name,
                    companyId,
                    status,
                })

                setTasksCompanyReduce(itemCheckReduce)
            }
            else {

                let status = false;
                itemCheckReduce.push({
                    _id,
                    name,
                    companyId,
                    status,
                })

                setTasksCompanyReduce(itemCheckReduce)

            }

        }

    }


    return (
        <>
            <Layout
            path={props.match.path}
            >
                <HeaderNovaAdmissao
                history={history}
                user={user}
                collaborator={collaborator}
                setCollaborator={setCollaborator}
                />
                <HeaderEtapasNovaAdmissao id={id} stepOne={stepOne} stepTwo={stepTwo} stepThree={stepThree} path={props.match.path}
                collaboratorInfor={collaborator}/>
                <div className="structureNewAdmission">
                    <div className="structureContent">
                        <FormBasicInfor
                        id={id}
                        path={props.match.path}
                        tasksCompanyReduce={tasksCompanyReduce}
                        />
                    </div>
                    <div className="structureChecklist">
                        <Checklist
                        myChangeHandlerTemporaryChecklist={myChangeHandlerTemporaryChecklist}
                        collaboratorId={id}
                        tasksDefault={tasksDefault}
                        tasksCompany={tasksCompany}
                        addNewTaskSave={addNewTaskSave}
                        loadTasksChecklist={loadTasksChecklist}
                        tasksCompanyReduce={tasksCompanyReduce}
                        idItem={idItem}
                        />
                    </div>
                </div>

            </Layout>
        </>
    );

}

