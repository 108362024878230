import React, { useState, useEffect } from 'react';

import EditBlack from '../../../../../../assets/images/edit-black.svg';
import IllustrationDisability from '../../../../../../assets/images/illustration-disability.svg';
import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import { Modal} from "react-bootstrap";
import Carregamento from '../../../../../../assets/images/carregando.gif';
import SelectPersonalizado from '../../../../../../components/Reused/selectPersonalizado';
import api from '../../../../../../services/api';
import Close from '../../../../../../assets/images/close.svg';
import { toast } from 'react-toastify';

const ColProfileDeficiencia = ({ id, path } ) => {

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadPage, setLoadPage ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ registeredDisability, setRegisteredDisability ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    if(id){
        loadDisability(id);
    }

}, [id]);


async function loadDisability(id) {
    setLoadPage(true)
    await api.get(`/collaborator-disability/${id}`)
    .then(response => {
        setLoadPage(false)
        setLoadBtnDeficiencia(false)
        setVisible(false);
        if(response.data[0] !== undefined) {
            setRegisteredDisability(response.data);
        }
        else {
            setRegisteredDisability([]);
        }

    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    })
}

    ///////////////////////////////////////////////////////////////////////
    /// => Aqui você deleta uma formação acadêmica do colaborador
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    async function deleteDisability(disability) {
        //console.log(schooling)
        swal({
            title: "Atenção",
            text: "Deseja excluir esta Deficiência?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-disability/${disability}?path=${path}`)
                .then(() => {

                    loadDisability(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Deficiência removida com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover a deficiência, tente novamente mais tarde." });
                });
            }
        });
    }
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    /// => Aqui você atualiza ou adiciona uma formação acadêmica
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ disability, setDisability ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ disabilityUpdate, setDisabilityUpdate ] = useState([]);


    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = id;
        let physicalDisability = "true";

        if(nam === 'typeOfDisability') {
                setLoadDisabilityTypeOfDisability(false)
                setDisability({ ...disability, collaborator, physicalDisability, [nam]: val })

        }
        //disabilityObservations
        else
        if(nam === 'disabilityObservations') {
            setLoadDisabilityObservations(false)
            setDisability({ ...disability, collaborator, physicalDisability, [nam]: val })
        }
        else {

            setDisability({ ...disability, collaborator, physicalDisability, [nam]: val })
        }

    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTypeOfDisability, setLoadDisabilityTypeOfDisability ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDisabilityObservations, setLoadDisabilityObservations ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnDeficiencia, setLoadBtnDeficiencia ] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();

        if(disabilityUpdate._id !== undefined) {

            if(disability && disability.length === 0) {
                swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
            } else {

                if(disability.disabilityObservations === "") {
                    swal({ icon: "error", title: "Erro!", text: 'Nenhum campo pode ser salvo em branco!' });
                } else {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if (disability.typeOfDisability !== undefined) {
                        formData.append('typeOfDisability', disability.typeOfDisability);
                    }
                    if (disability.disabilityObservations !== undefined) {
                        formData.append('disabilityObservations', disability.disabilityObservations);
                    }
                    if (disability.file !== undefined) {
                        formData.append('file', disability.file);
                    }

                    formData.append('path', path);

                    setLoadBtnDeficiencia(true)
                    await api.put(`/collaborator-disability/${disabilityUpdate._id}`, formData, config)
                    .then(response => {

                        setFileNames();
                        setVisible(false);
                        setDisabilityUpdate();
                        setDisability([]);
                        loadDisability(id);

                        swal({ icon: "success", title: "Sucesso!", text: "Deficiência editada com sucesso!" });
                        //this.propsCollaboratorsInatives();
                    }).catch(error => {
                        //console.log(error.response.request.upload.onload)
                        if(error.response.request.upload.onload === null) {
                            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                            setLoad(false)
                        } else {
                            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                            setLoad(false)
                        }
                    });

                }

            }

        } else {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            // console.log(disability)

            if(disability && disability === undefined) {
                //swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
                if (disability.typeOfDisability !== undefined) {
                    formData.append('typeOfDisability', disability.typeOfDisability);
                    setLoadDisabilityTypeOfDisability(false)
                } else {
                    setLoadDisabilityTypeOfDisability(true)
                }
                if (disability.disabilityObservations !== undefined) {
                    formData.append('disabilityObservations', disability.disabilityObservations);
                    setLoadDisabilityObservations(false)
                } else {
                    setLoadDisabilityObservations(true)
                }

            } else if(disability && disability.length === 0) {
                //swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
                if (disability.typeOfDisability !== undefined) {
                    formData.append('typeOfDisability', disability.typeOfDisability);
                    setLoadDisabilityTypeOfDisability(false)
                } else {
                    setLoadDisabilityTypeOfDisability(true)
                }
                if (disability.disabilityObservations !== undefined) {
                    formData.append('disabilityObservations', disability.disabilityObservations);
                    setLoadDisabilityObservations(false)
                } else {
                    setLoadDisabilityObservations(true)
                }
            } else {
                if (disability.typeOfDisability !== undefined) {
                    formData.append('typeOfDisability', disability.typeOfDisability);
                    setLoadDisabilityTypeOfDisability(false)
                } else {
                    setLoadDisabilityTypeOfDisability(true)
                }
                if (disability.disabilityObservations !== undefined) {
                    formData.append('disabilityObservations', disability.disabilityObservations);
                    setLoadDisabilityObservations(false)
                } else {
                    setLoadDisabilityObservations(true)
                }

                if (disability.file !== undefined) {
                    formData.append('file', disability.file);
                }

                formData.append('collaborator', id);
                formData.append('physicalDisability', disability.physicalDisability);
                formData.append('path', path);
                // setLoadBtnDeficiencia(true)
                if (disability.typeOfDisability !== undefined && disability.disabilityObservations !== undefined ) {
                    setLoadBtnDeficiencia(true)
                    await api.post("/collaborator-disability", formData, config)
                    .then(response => {
                        setFileNames()
                        setVisible(false);
                        setDisabilityUpdate()
                        setDisability([])
                        loadDisability(id);
                        swal({ icon: "success", title: "Sucesso!", text: "Deficiência criada com sucesso!" });
                    }).catch(error => {
                        if(error.response.request.upload.onload === null) {
                            swal({ icon: "error", title: "Erro!", text: "Arquivo muito pesado, por favor, envie um aqruivo de no máximo até 2MB." });
                            setLoad(false)
                        } else {
                            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                            setLoad(false)
                        }
                    });
                }
            }



        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    function AddAndEditDisabilityVisible(e) {
        let disabilityUpdate = e;

        setDisabilityUpdate({
            ...disabilityUpdate
        })

        setVisible(!visible);
        setLoadDisabilityTypeOfDisability(false)
        setLoadDisabilityObservations(false)
        setLoadDisabilityObservations(false)
    }

    //

    function CloseAddAndEditDisabilityVisible() {

        setFileNames()
        setVisible(!visible);
        setDisability({});
        setLoadDisabilityTypeOfDisability(false)
        setLoadDisabilityObservations(false)
        setLoadDisabilityObservations(false)

    }


   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState([]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setFileNames(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0];
            setDisability({ ...disability, file })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFiles() {
        setFileNames();
        let file = fileNames
        setDisability({ ...disability, file })
    }


        return (
            <>

                    <div className="box deficiente">
                        <h3>Deficiência
                            {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                <button className="edit-adicionar btnGreen more" onClick={() => AddAndEditDisabilityVisible()}>Adicionar </button>
                            }
                        </h3>

                        {
                            loadPage ?
                                (
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                    </>
                                ):

                         registeredDisability[0] === undefined ?
                        (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationDisability} alt="Sem deficiencia" /><p>Não possui deficiência</p></div></div>):
                        registeredDisability !== undefined ? registeredDisability
                        .map((disability, index) => (
                        <>
                            <ul key={index} className="deficiente-box">
                                <li>
                                    <div className="intemA">
                                        <p>Tipo</p>
                                    </div>
                                    <div className="intemB nome-editar">
                                            <span className="nome">{disability.typeOfDisability}</span>
                                            <button className="editar"  onClick={() => AddAndEditDisabilityVisible(disability)} ><img src={EditBlack} alt="Edite Informações" /></button>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Observações</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{disability.disabilityObservations.split('\n').map(str => (<><br/>{str}</>))}</p>
                                    </div>
                                </li>
                                    {disability.originalname !== undefined ?
                                    (
                                        <li className="li-anexo">
                                            <div className="intemA">
                                                <p>Comprovante</p>
                                            </div>
                                            <div className="intemB arq-adicionado">

                                                <a href={disability.location} target="’_blank’">
                                                    {disability.originalname}
                                                </a>
                                            </div>
                                        </li>
                                    ) : ('')}
                            </ul>
                        </>
                        )) : (''
                        )}
                    </div>


                    <Modal show={visible} className="popup-edit-perfil popup-edit-deficiencia" onHide={CloseAddAndEditDisabilityVisible}>
                        <div>
                            <div className="titulo">
                                <h4>Deficiência <p className="btn-fechar" onClick={CloseAddAndEditDisabilityVisible}><img src={Close} alt="Fechar" /></p></h4>
                            </div>
                            <ul>
                                <li>
                                    <div className="intemA">
                                        <p>Tipo *</p>
                                    </div>
                                    <div className="intemB">
                                        <SelectPersonalizado 
                                            valorCampo={
                                                disability && disability.typeOfDisability !== undefined ?
                                                disability.typeOfDisability :
                                                disabilityUpdate && disabilityUpdate.typeOfDisability !== undefined ?
                                                disabilityUpdate.typeOfDisability : ""
                                            }
                                            nameButton={"typeOfDisability"}
                                            loadError={loadTypeOfDisability}
                                            myChangeHandler={myChangeHandler}
                                            optionFil={[
                                                "Física",
                                                "Visual",
                                                "Auditiva",
                                                "Mental",
                                                "Intelectual",
                                                "Reabilitado",
                                            ]}
                                            typeSelect="busca2"
                                        />
                                        {loadTypeOfDisability ? <> <p className="textError">O campo é obrigatório</p></> : ''}
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Observações *</p>
                                    </div>
                                    <div className="intemB">

                                    {disabilityUpdate && disabilityUpdate.disabilityObservations !== undefined ?
                                        (<textarea name="disabilityObservations"  defaultValue={disabilityUpdate.disabilityObservations}  className={loadDisabilityObservations ? 'inputErro' : 'inputUm'} cols="150" onChange={myChangeHandler} placeholder="Escreva sua observações..."></textarea>) :
                                        (<textarea name="disabilityObservations"  className={loadDisabilityObservations ? 'inputErro' : 'inputUm'} cols="150" onChange={myChangeHandler}  placeholder="Escreva sua observações..."></textarea>)
                                    }
                                        {loadDisabilityObservations ? <> <p className="textError textError-observacoes">O campo é obrigatório</p></> : ''}
                                    </div>
                                </li>

                                    {disabilityUpdate && disabilityUpdate.originalname !== undefined ?
                                    (
                                        <li>
                                            <div className="intemA">
                                                <p>Comprovante</p>
                                            </div>
                                            <div className="intemB arq-adicionado ">

                                                    <a href={disabilityUpdate && disabilityUpdate.location} target="’_blank’">
                                                        {disabilityUpdate && disabilityUpdate.originalname}
                                                    </a>
                                            </div>
                                        </li>
                                    ) : ('')}
                                <li>
                                    <div className="intemA">
                                    </div>
                                    <div className="intemB">{disabilityUpdate && disabilityUpdate.originalname !== undefined ?
                                    (
                                        <Dropzone  className="" onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel"><span></span>Atualizar arquivo arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                        ) :
                                        (
                                            <Dropzone  className="" onDrop={handleDrop} >
                                                {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input type="file" name="file" {...getInputProps()} />
                                                    <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                                </div>
                                                )}
                                            </Dropzone>

                                        )}


                                    </div>
                                </li>

                                <li>
                                    <div className="intemA">
                                    </div>
                                    <div className="intemB">
                                        <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                    </div>
                                </li>

                                {fileNames !== false && fileNames ?
                                fileNames.map((filename, index) => (
                                <li>
                                    <div>
                                        <div className="arquivoAnexado">

                                            {/* <h5>Enviado</h5> */}
                                            <div key={index} className="arquivo">
                                                <p className="nomeArquivo">
                                                    {filename}
                                                </p>
                                                <p onClick={deleteFiles} className="remover">Remover anexo</p>
                                            </div>

                                        </div>
                                    </div>
                                </li>

                                )) : load ?
                                (<div className="carregando textoDoze"><img src={Carregamento} alt="carregando" /></div>) :
                                ('')}
                                {disabilityUpdate && disabilityUpdate.disability !== false && disabilityUpdate._id  ?
                                        (<>
                                            <li className='comBtnExclui'>
                                                <div className="intemA">
                                                    <div className="intemB btnExclui">
                                                        <button  onClick={() => deleteDisability(disabilityUpdate && disabilityUpdate._id)}>Excluir deficiência</button>
                                                    </div>
                                                </div>

                                                <div className="intemB">
                                                    <form onSubmit={handleSubmit} className="btn-salvar">
                                                        <button type="submit" className={loadBtnDeficiencia  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                                    </form>
                                                </div>
                                            </li>
                                            <li className='comBtnExcluiMb'>
                                                <div className="intemA">
                                                    <form onSubmit={handleSubmit} className="btn-salvar">
                                                        <button type="submit" className={loadBtnDeficiencia  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                                    </form>
                                                </div>

                                                <div className="intemB">
                                                    <div className="intemB btnExclui">
                                                        <button  onClick={() => deleteDisability(disabilityUpdate && disabilityUpdate._id)}>Excluir deficiência</button>
                                                    </div>
                                                </div>
                                            </li>

                                        </>
                                        ) :
                                    (
                                        <li>
                                            <div className="intemA">
                                            </div>

                                            <div className="intemB">
                                                <form onSubmit={handleSubmit} className="btn-salvar">
                                                    <button type="submit" className={loadBtnDeficiencia  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                                </form>
                                            </div>
                                        </li>
                                    )}

                            </ul>

                        </div>
                    </Modal>


            </>
        );
}
export default ColProfileDeficiencia;
