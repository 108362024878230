import React from 'react';

const ColBeneficioInforQuant = ({
    collaboratorBenefitsActives,
    collaboratorBenefitsUnbound,
    collaboratorBenefitsAll,
}) => {

    return (
        <>
            <div className="quant-beneficio">
                <p className="color-verde"><span>{collaboratorBenefitsActives}</span>utilizando</p>
                <p className="color-laranja"><span>{collaboratorBenefitsUnbound}</span>desvinculados</p>
                <p className="color-azul"><span>{collaboratorBenefitsAll}</span>total</p>
            </div>
        </>
    );
}
export default ColBeneficioInforQuant;
