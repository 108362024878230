import React, { useState, useEffect } from 'react';
import './styles.scss';
import Close from '../../assets/images/close.svg';
import { Modal } from 'react-bootstrap';
import Layout from '../../components/Layouts/defaultUser';
import { last24Caracteres, dataFormatadaParaBarra } from '../../services/utils';
import swal from 'sweetalert';
import Carregamento from '../../assets/images/carregando.gif';
import api from '../../services/api';
import { toast } from 'react-toastify';

export default function UserMySkills(props, {
    name,
}) {

    const { path } = props.match;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setLoadPageSkillCollaboratorUser ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillCollaboratorUser, setSkillCollaboratorUser] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages, setPages] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ mySkills, setMySkills] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ numberSkills, setNumberSkills] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ numberGroups, setNumberGroups] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ numberCollaboratos, setNumberCollaboratos] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPageMyskills, setLoadPageMyskills ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nameSkillCollaborator, setNameSkillCollaborator ] = useState();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadnumberofcollaboratorsaccess();
        loadcountcollaboratorsskillgroup();
        loadnumberskills();
        loadmySkills();
        loadskillmorecommon();
        loadskillNew();
        loadskilllost();

    }, []);

    async function loadnumberofcollaboratorsaccess() {

        await api.get('/number-of-collaborators-access/')
        .then(response => {

           setNumberCollaboratos(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcountcollaboratorsskillgroup() {

        await api.get('/count-collaborators-skill-group/')
        .then(response => {

           setNumberGroups(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadnumberskills() {

        await api.get('/number-of-skills-user/')
        .then(response => {

           setNumberSkills(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function loadmySkills() {

        setLoadPageMyskills(true)
        await api.get('/skill-collaborator-user-show/')
        .then(response => {

           setMySkills(response.data);
           setLoadPageMyskills(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadskillCollaboratorUser();

    }, []);

    async function loadskillCollaboratorUser() {

        setLoadPageSkillCollaboratorUser(true)

        await api.get('/skill-collaborator-user/?paginate=true')
        .then(response => {

            setLoadPageSkillCollaboratorUser(false);

            const docs_a  = response.data[0];
            const arrInfo = response.data[1];

            let pagina = parseInt(arrInfo[0].pagina)

            let itensCopyCollaborator = [];

            if(pagina === arrInfo[0].pages) {

                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                let final  = arrInfo[0].total;

                for(let dcs=inicio; dcs < final; dcs++){

                    itensCopyCollaborator.push(docs_a[dcs]);

                }

                setSkillCollaboratorUser(itensCopyCollaborator);
                setPages(arrInfo[0].pages);


            }
            else {

                // => Se total menor que o limite
                if(arrInfo[0].total < arrInfo[0].limite) {

                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final =  arrInfo[0].total

                    for(let dcs=inicio; dcs < final; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setSkillCollaboratorUser(itensCopyCollaborator);
                    setPages(arrInfo[0].pages);


                }
                else {

                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                    for(let dcs=inicio; dcs < final; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setSkillCollaboratorUser(itensCopyCollaborator);
                    setPages(arrInfo[0].pages);


                }
                // => Final de => Se total menor que o limite

            }



        }).catch(error => {})
    }


    async function loadfilterperskill(event) {
        event.preventDefault();

        let name = event.target.value;
        setNameSkillCollaborator(event.target.value);
        setLoadPageSkillCollaboratorUser(true)

        if(name !== undefined) {

            async function loadskillCollaboratorUserPerName() {

                await api.get(`/skill-collaborator-user/?paginate=true&name=${name}`)
                .then(response => {

                    if(response.data.length === 0) {

                        setSkillCollaboratorUser([]);
                        setNameSkillCollaborator();
                        setPageAllSkill(1)
                        setPages(0);

                    }
                    else {

                        setLoadPageSkillCollaboratorUser(false);

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(arrInfo[0].pagina)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPageAllSkill(1)
                            setPages(arrInfo[0].pages);


                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setSkillCollaboratorUser(itensCopyCollaborator);
                                setPageAllSkill(1)
                                setPages(arrInfo[0].pages);


                            }
                            else {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setSkillCollaboratorUser(itensCopyCollaborator);
                                setPageAllSkill(1)
                                setPages(arrInfo[0].pages);


                            }
                            // => Final de => Se total menor que o limite

                        }

                    }

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadskillCollaboratorUserPerName();


        }
        else {

            loadskillCollaboratorUser();

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pageAllSkill, setPageAllSkill ] = useState(1);

    async function prevPageAllSkill(page) {

        if (pageAllSkill === 1) return;

        const pageNumber = pageAllSkill - 1;
        setPageAllSkill(pageNumber);

        if(nameSkillCollaborator !== undefined) {

            await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
            .then(response => {
                if(response.data.length === 0) {

                    setSkillCollaboratorUser([]);
                    setNameSkillCollaborator();
                    setPageAllSkill(1)
                    setPages(0);

                }
                else {

                    setLoadPageSkillCollaboratorUser(false);

                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(pageNumber)

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setSkillCollaboratorUser(itensCopyCollaborator);
                        setPages(arrInfo[0].pages);


                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        else {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        // => Final de => Se total menor que o limite

                    }

                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            async function loadallskillpagination(page = pageNumber) {

                await api.get(`/skill-collaborator-user/?paginate=true`)
                .then(response => {

                    setLoadPageSkillCollaboratorUser(false);

                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(page);

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setSkillCollaboratorUser(itensCopyCollaborator);
                        setPages(arrInfo[0].pages);


                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        else {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        // => Final de => Se total menor que o limite

                    }

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            loadallskillpagination();

        }

    }

    async function nextPageAllSkill(page) {


        const pageNumber = pageAllSkill + 1;
        setPageAllSkill(pageNumber);

        if(nameSkillCollaborator !== undefined) {

            await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
            .then(response => {
                if(response.data.length === 0) {

                    setSkillCollaboratorUser([]);
                    setNameSkillCollaborator();
                    setPageAllSkill(1)
                    setPages(0);

                }
                else {

                    setLoadPageSkillCollaboratorUser(false);

                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(pageNumber)

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setSkillCollaboratorUser(itensCopyCollaborator);
                        setPages(arrInfo[0].pages);


                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        else {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        // => Final de => Se total menor que o limite

                    }

                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            async function loadallskillpagination(page = pageNumber) {

                await api.get(`/skill-collaborator-user/?paginate=true`)
                .then(response => {

                    setLoadPageSkillCollaboratorUser(false);

                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(page);

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setSkillCollaboratorUser(itensCopyCollaborator);
                        setPages(arrInfo[0].pages);


                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        else {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        // => Final de => Se total menor que o limite

                    }

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            loadallskillpagination();

        }

    }

    async function paginationPageAllSkill(page) {

        const pageNumber = page;
        setPageAllSkill(pageNumber);

        if(nameSkillCollaborator !== undefined) {

            await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
            .then(response => {
                if(response.data.length === 0) {

                    setSkillCollaboratorUser([]);
                    setNameSkillCollaborator();
                    setPageAllSkill(1)
                    setPages(0);

                }
                else {

                    setLoadPageSkillCollaboratorUser(false);

                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(page)

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setSkillCollaboratorUser(itensCopyCollaborator);
                        setPages(arrInfo[0].pages);


                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        else {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        // => Final de => Se total menor que o limite

                    }

                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            async function loadallskillpagination(page = pageNumber) {

                await api.get(`/skill-collaborator-user/?paginate=true`)
                .then(response => {

                    setLoadPageSkillCollaboratorUser(false);

                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(page);

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setSkillCollaboratorUser(itensCopyCollaborator);
                        setPages(arrInfo[0].pages);


                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        else {


                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setSkillCollaboratorUser(itensCopyCollaborator);
                            setPages(arrInfo[0].pages);


                        }
                        // => Final de => Se total menor que o limite

                    }

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            loadallskillpagination();

        }

    }

    async function myChangehandlerSkillCollaborator(e) {
        let val   = e.target.checked;
        let skill = e.target.value;

        if(val === false) {

            await api.delete(`/skill-collaborator-user/${skill}?path=${path}`)
            .then(response => {

                setLoadPageSkillCollaboratorUser(true)

                if(nameSkillCollaborator !== undefined) {

                    async function getNameSkillCollaborator() {

                        await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
                        .then(response => {

                            if(response.data.length === 0) {

                                setSkillCollaboratorUser([]);
                                setNameSkillCollaborator();
                                setPageAllSkill(1)
                                setPages(0);

                            }
                            else {

                                loadskillCollaboratorUser()

                                setLoadPageSkillCollaboratorUser(false);

                                const docs_a  = response.data[0];
                                const arrInfo = response.data[1];

                                let pagina = parseInt(pageAllSkill)

                                let itensCopyCollaborator = [];


                                if(pagina === arrInfo[0].pages) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final  = arrInfo[0].total;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                else {

                                    // => Se total menor que o limite
                                    if(arrInfo[0].total < arrInfo[0].limite) {

                                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                        let final =  arrInfo[0].total

                                        for(let dcs=inicio; dcs < final; dcs++){

                                            itensCopyCollaborator.push(docs_a[dcs]);

                                        }

                                        setSkillCollaboratorUser(itensCopyCollaborator);
                                        loadmySkills()
                                        setPages(arrInfo[0].pages);


                                    }
                                    else {

                                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                        let final =  pagina * arrInfo[0].limite

                                        for(let dcs=inicio; dcs < final; dcs++){

                                            itensCopyCollaborator.push(docs_a[dcs]);

                                        }

                                        setSkillCollaboratorUser(itensCopyCollaborator);
                                        loadmySkills()
                                        setPages(arrInfo[0].pages);


                                    }
                                    // => Final de => Se total menor que o limite

                                }

                            }
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    getNameSkillCollaborator()

                }
                else {

                    async function loadallskillpagination() {


                        await api.get(`/skill-collaborator-user/?paginate=true`)
                        .then(response => {

                            setLoadPageSkillCollaboratorUser(false);

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(pageAllSkill);

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setSkillCollaboratorUser(itensCopyCollaborator);
                                loadmySkills()
                                setPages(arrInfo[0].pages);


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    loadallskillpagination();

                }


            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }
        else {
            setLoadPageSkillCollaboratorUser(true)
            await api.post('/skill-collaborator-user/', { skill, path: path })
            .then(response => {


                loadskillCollaboratorUser()
                loadnumberofcollaboratorsaccess();
                loadcountcollaboratorsskillgroup();
                loadnumberskills();
                loadmySkills();
                loadskillmorecommon();
                loadskillNew();
                loadskilllost();

                if(response.data.skill !== undefined) {
                    let skill            = response.data.skill;

                    async function updateSkillCollaboratorProeficiency() {

                        await api.post('/skill-collaborator-proeficiency-user/', { skill,  valueProficiency : 0, path: path })
                        .then(response => {

                            if(nameSkillCollaborator !== undefined) {

                                async function getNameSkillCollaborator() {

                                    await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
                                    .then(response => {
                                        if(response.data.length === 0) {


                                            setSkillCollaboratorUser([]);
                                            setNameSkillCollaborator();
                                            setPageAllSkill(1)
                                            setPages(0);

                                        }
                                        else {

                                            setLoadPageSkillCollaboratorUser(false);


                                            const docs_a  = response.data[0];
                                            const arrInfo = response.data[1];

                                            let pagina = parseInt(pageAllSkill)

                                            let itensCopyCollaborator = [];

                                            if(pagina === arrInfo[0].pages) {

                                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                                let final  = arrInfo[0].total;

                                                for(let dcs=inicio; dcs < final; dcs++){

                                                    itensCopyCollaborator.push(docs_a[dcs]);

                                                }

                                                setSkillCollaboratorUser(itensCopyCollaborator);
                                                loadmySkills()
                                                setPages(arrInfo[0].pages);


                                            }
                                            else {

                                                // => Se total menor que o limite
                                                if(arrInfo[0].total < arrInfo[0].limite) {

                                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                                    let final =  arrInfo[0].total

                                                    for(let dcs=inicio; dcs < final; dcs++){

                                                        itensCopyCollaborator.push(docs_a[dcs]);

                                                    }

                                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                                    loadmySkills()
                                                    setPages(arrInfo[0].pages);


                                                }
                                                else {

                                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                                    let final =  pagina * arrInfo[0].limite

                                                    for(let dcs=inicio; dcs < final; dcs++){

                                                        itensCopyCollaborator.push(docs_a[dcs]);

                                                    }

                                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                                    loadmySkills()
                                                    setPages(arrInfo[0].pages);


                                                }
                                                // => Final de => Se total menor que o limite

                                            }

                                        }
                                    }).catch(error => {
                                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                    });

                                }
                                getNameSkillCollaborator()

                            }
                            else {

                                async function loadallskillpagination() {

                                    await api.get(`/skill-collaborator-user/?paginate=true`)
                                    .then(response => {

                                        setLoadPageSkillCollaboratorUser(false);

                                        const docs_a  = response.data[0];
                                        const arrInfo = response.data[1];

                                        let pagina = parseInt(pageAllSkill);

                                        let itensCopyCollaborator = [];

                                        if(pagina === arrInfo[0].pages) {

                                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                            let final  = arrInfo[0].total;

                                            for(let dcs=inicio; dcs < final; dcs++){

                                                itensCopyCollaborator.push(docs_a[dcs]);

                                            }

                                            setSkillCollaboratorUser(itensCopyCollaborator);
                                            loadmySkills()
                                            setPages(arrInfo[0].pages);


                                        }
                                        else {

                                            // => Se total menor que o limite
                                            if(arrInfo[0].total < arrInfo[0].limite) {

                                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                                let final =  arrInfo[0].total;

                                                for(let dcs=inicio; dcs < final; dcs++){

                                                    itensCopyCollaborator.push(docs_a[dcs]);

                                                }

                                                setSkillCollaboratorUser(itensCopyCollaborator);
                                                loadmySkills()
                                                setPages(arrInfo[0].pages);


                                            }
                                            else {

                                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                                let final =  pagina * arrInfo[0].limite;

                                                for(let dcs=inicio; dcs < final; dcs++){

                                                    itensCopyCollaborator.push(docs_a[dcs]);

                                                }

                                                setSkillCollaboratorUser(itensCopyCollaborator);
                                                loadmySkills()
                                                setPages(arrInfo[0].pages);


                                            }
                                            // => Final de => Se total menor que o limite

                                        }

                                    }).catch(error => {
                                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                    });

                                }
                                loadallskillpagination();

                            }

                        }).catch(error => {})
                    }
                    updateSkillCollaboratorProeficiency();
                }
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }

    }

    async function myChangeHandlerCollaboratorProeficiency(e) {

        let idtospliting = e.target.id;
        let id = last24Caracteres(idtospliting)
        let check   = e.target.checked;
        let valueProficiency     = e.target.value;

        if(check === false) {
           await api.put(`/skill-collaborator-proeficiency-user/${id}`, { valueProficiency, path: path })
           .then(response => {

                if(nameSkillCollaborator !== undefined) {

                    async function getNameSkillCollaborator() {

                        await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
                        .then(response => {

                            if(response.data.length === 0) {

                                setSkillCollaboratorUser([]);
                                setNameSkillCollaborator();
                                setPageAllSkill(1)
                                setPages(0);

                            }
                            else {

                                setLoadPageSkillCollaboratorUser(false);

                                const docs_a  = response.data[0];
                                const arrInfo = response.data[1];

                                let pagina = parseInt(pageAllSkill)

                                let itensCopyCollaborator = [];

                                if(pagina === arrInfo[0].pages) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final  = arrInfo[0].total;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                else {

                                    // => Se total menor que o limite
                                    if(arrInfo[0].total < arrInfo[0].limite) {

                                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                        let final =  arrInfo[0].total

                                        for(let dcs=inicio; dcs < final; dcs++){

                                            itensCopyCollaborator.push(docs_a[dcs]);

                                        }

                                        setSkillCollaboratorUser(itensCopyCollaborator);
                                        loadmySkills()
                                        setPages(arrInfo[0].pages);


                                    }
                                    else {

                                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                        let final =  pagina * arrInfo[0].limite

                                        for(let dcs=inicio; dcs < final; dcs++){

                                            itensCopyCollaborator.push(docs_a[dcs]);

                                        }

                                        setSkillCollaboratorUser(itensCopyCollaborator);
                                        loadmySkills()
                                        setPages(arrInfo[0].pages);


                                    }
                                    // => Final de => Se total menor que o limite

                                }

                            }
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    getNameSkillCollaborator()

                }
                else {

                    async function loadallskillpagination() {

                        await api.get(`/skill-collaborator-user/?paginate=true`)
                        .then(response => {

                            setLoadPageSkillCollaboratorUser(false);

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(pageAllSkill);

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setSkillCollaboratorUser(itensCopyCollaborator);
                                loadmySkills()
                                setPages(arrInfo[0].pages);


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    loadallskillpagination();

                }

            }).catch(error => {})

        }
        else {
            await api.put(`/skill-collaborator-proeficiency-user/${id}`, { valueProficiency, path: path })
            .then(response => {

                if(nameSkillCollaborator !== undefined) {

                    async function getNameSkillCollaborator() {

                        await api.get(`/skill-collaborator-user/?paginate=true&name=${nameSkillCollaborator}`)
                        .then(response => {

                            if(response.data.length === 0) {

                                setSkillCollaboratorUser([]);
                                setNameSkillCollaborator();
                                setPageAllSkill(1)
                                setPages(0);

                            }
                            else {

                                setLoadPageSkillCollaboratorUser(false);

                                const docs_a  = response.data[0];
                                const arrInfo = response.data[1];

                                let pagina = parseInt(pageAllSkill)

                                let itensCopyCollaborator = [];

                                if(pagina === arrInfo[0].pages) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final  = arrInfo[0].total;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                else {

                                    // => Se total menor que o limite
                                    if(arrInfo[0].total < arrInfo[0].limite) {

                                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                        let final =  arrInfo[0].total

                                        for(let dcs=inicio; dcs < final; dcs++){

                                            itensCopyCollaborator.push(docs_a[dcs]);

                                        }

                                        setSkillCollaboratorUser(itensCopyCollaborator);
                                        loadmySkills()
                                        setPages(arrInfo[0].pages);


                                    }
                                    else {

                                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                        let final =  pagina * arrInfo[0].limite

                                        for(let dcs=inicio; dcs < final; dcs++){

                                            itensCopyCollaborator.push(docs_a[dcs]);

                                        }

                                        setSkillCollaboratorUser(itensCopyCollaborator);
                                        loadmySkills()
                                        setPages(arrInfo[0].pages);


                                    }
                                    // => Final de => Se total menor que o limite

                                }

                            }
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    getNameSkillCollaborator()

                }
                else {

                    async function loadallskillpagination() {

                        await api.get(`/skill-collaborator-user/?paginate=true`)
                        .then(response => {

                            setLoadPageSkillCollaboratorUser(false);

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(pageAllSkill);

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setSkillCollaboratorUser(itensCopyCollaborator);
                                loadmySkills()
                                setPages(arrInfo[0].pages);


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                else {


                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite;

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setSkillCollaboratorUser(itensCopyCollaborator);
                                    loadmySkills()
                                    setPages(arrInfo[0].pages);


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    loadallskillpagination();

                }

            }).catch(error => {})

        }

    }

    let numberPaginate = []

    for(let i=1; i<=pages; i++){
        numberPaginate.push(
            <li key={i} className="number">
                <button className={i === pageAllSkill ? "secao" : ""} onClick={(event) => paginationPageAllSkill(i)}>{i}</button>
            </li>
        )
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillMoreCommon, setSkillMoreCommon] = useState([]);


    async function loadskillmorecommon() {

        await api.get('/skill-more-common/')
        .then(response => {
            setSkillMoreCommon(response.data[0]);
        }).catch(error => {
            setSkillMoreCommon([]);
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ skillNew, setSkillNew] = useState([]);


    async function loadskillNew() {
        await api.get('/skill-new/')
        .then(response => {
            setSkillNew(response.data[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillLost, setSkillLost] = useState([]);

    async function loadskilllost() {
        await api.get('/skill-lost/')
        .then(response => {
            setSkillLost(response.data)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleResumoHabilidade, setVisibleResumoHabilidade] = useState(false);

    function VisualizarResumoHabilidade() {
        setVisibleResumoHabilidade(!visibleResumoHabilidade);
    }

    return (
        <>
            <Layout  path={path}>
                    <div className="user-my-skill">
                        <div className="meu-resumo">
                            <h3 className="titulo">Meu resumo</h3>
                            {
                                loadPageMyskills   ?
                                <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                </>
                                :
                                mySkills &&
                                mySkills.length !== 0 ? (
                                <ul className="lista-my-skills">
                                    {
                                        mySkills &&
                                        mySkills.length !== 0 ?
                                        mySkills.map((myskill, index) => (
                                            <li key={index}>{myskill.skill && myskill.skill.name ? myskill.skill.name : ''}</li>
                                        ))
                                        :
                                        ''
                                    }
                                </ul>
                                 )
                                 :
                                 (

                                    <p className="nenhuma-habilidade">Você ainda não definiu nenhuma habilidade. <br/>
                                    Marque as habilidades que você possui abaixo e elas aparecerão aqui.</p>
                                 )
                             }
                        </div>
                        <div className="resumo-geral">
                            <h3 className="titulo">Resumo geral</h3>
                            <div className="infor">
                                <ul>
                                    <li>
                                        <p>{numberSkills && numberSkills !== undefined && numberSkills.length !== 0 ? numberSkills : "0"}</p>
                                        <span>habilidades</span>
                                    </li>
                                    <li>
                                        <p>{numberGroups && numberGroups.length !== 0 ? numberGroups.length : "0"}</p>
                                        <span>grupos</span>
                                    </li>
                                    <li>
                                        <p>{numberCollaboratos && numberCollaboratos !== undefined && numberCollaboratos.length !== 0 ? numberCollaboratos : "0"}</p>
                                        <span>colaboradores</span>
                                    </li>
                                    <li>
                                        <p>{skillMoreCommon && skillMoreCommon.name !== undefined ? skillMoreCommon.name : "Nenhuma"}</p>
                                        <span>habilidade mais comum</span>
                                    </li>
                                </ul>
                                <div className="ver-resumo">
                                    <button onClick={() => VisualizarResumoHabilidade()} >Ver resumo</button>
                                </div>
                            </div>
                        </div>
                        <div className="menu-busca">
                            <h2 className="titulo">Editar habilidades</h2>
                            <form action="" className="busca">
                                <input onChange={loadfilterperskill} name="name" value={name} autocomplete="off"  placeholder="Filtre por habilidades"/>

                            </form>
                        </div>
                        <div className="lista-skill">
                            <div className="cabecalho">
                                <ul>
                                    <li className="nome">Habilidade</li>
                                    <li>Possuo a habilidade</li>
                                    <li className="potencia">Proficiência <span>(de 1 à 5)</span></li>
                                </ul>
                            </div>
                            <div className="lista">
                                <ul>
                                    {
                                        skillCollaboratorUser &&
                                        skillCollaboratorUser.length !== 0 ?
                                        skillCollaboratorUser.map((skilluser, index) => (
                                            <li key={index}>
                                                <div className="nome">
                                                {skilluser.nameSkill}
                                                </div>

                                                <div className="checa-habilidade">
                                                <input
                                                type="checkbox"
                                                name="skill"
                                                value={skilluser._id}
                                                onChange={myChangehandlerSkillCollaborator}
                                                id={skilluser._id}
                                                checked={skilluser.haveSkill}
                                                />
                                                <label htmlFor={skilluser._id}><div></div></label>
                                                </div>
                                                <div className="classificaHabilidade">
                                                    {
                                                        skilluser.proficiency === null ?
                                                        <>
                                                        <input disabled={skilluser.haveSkill === false ? true : false} type="checkbox" value={skilluser._id} id={`classificationOne-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input disabled={skilluser.haveSkill === false ? true : false} type="checkbox" value={skilluser._id} id={`classificationTwo-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input disabled={skilluser.haveSkill === false ? true : false} type="checkbox" value={skilluser._id} id={`classificationThree-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input disabled={skilluser.haveSkill === false ? true : false} type="checkbox" value={skilluser._id} id={`classificationFour-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input disabled={skilluser.haveSkill === false ? true : false} type="checkbox" value={skilluser._id} id={`classificationFive-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        skilluser.proficiency === 0 ?
                                                        <>
                                                        <input type="checkbox" value="1" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationOne-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="2" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationTwo-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="3" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationThree-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="4" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFour-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="5" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFive-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        skilluser.proficiency === 1 ?
                                                        <>
                                                        <input type="checkbox" value="1" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationOne-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="2" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationTwo-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="3" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationThree-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="4" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFour-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="5" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFive-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        skilluser.proficiency === 2 ?
                                                        <>
                                                        <input type="checkbox" value="1" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationOne-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="2" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationTwo-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="3" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationThree-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="4" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFour-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="5" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFive-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        skilluser.proficiency === 3 ?
                                                        <>
                                                        <input type="checkbox" value="1" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationOne-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="2" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationTwo-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="3" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationThree-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="4" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFour-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="5" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFive-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        skilluser.proficiency === 4 ?
                                                        <>
                                                        <input type="checkbox" value="1" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationOne-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="2" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationTwo-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="3" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationThree-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="4" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFour-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="5" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFive-${skilluser._id}`} checked={false} />
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        skilluser.proficiency === 5 ?
                                                        <>
                                                        <input type="checkbox" value="1" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationOne-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationOne-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="2" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationTwo-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationTwo-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="3" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationThree-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationThree-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="4" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFour-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationFour-${skilluser._id}`}><div></div></label>

                                                        <input type="checkbox" value="5" onChange={myChangeHandlerCollaboratorProeficiency} id={`classificationFive-${skilluser._id}`} checked={true}/>
                                                        <label htmlFor={`classificationFive-${skilluser._id}`}><div></div></label>
                                                        </>
                                                        :
                                                        ''
                                                    }


                                                </div>
                                            </li>
                                        ))
                                        :
                                        ''
                                    }
                                </ul>
                            </div>
                                {
                                    pages > 1 ?
                                    <>

                                        <div className="paginacaoG">
                                            <ul>
                                                {
                                                    numberPaginate
                                                }
                                                <li className="page-anterior">
                                                <button tyle={(pageAllSkill === 1) ? { cursor: "not-allowed" } : {}}  disabled={pageAllSkill === 1} onClick={(event) => prevPageAllSkill(event)} >anterior</button>
                                                </li>
                                                <li className="page-proxima">
                                                <button style={(pageAllSkill === pages) ? { cursor: "not-allowed" } : {}}  disabled={pageAllSkill === pages} onClick={(event) => nextPageAllSkill(event)} >próxima</button>
                                                </li>
                                            </ul>

                                        </div>
                                    </>
                                    :
                                    ''
                                }
                        </div>
                    </div>

                <Modal show={visibleResumoHabilidade} className="popup-geral popup-resumo-habilidade" onHide={VisualizarResumoHabilidade}>
                    <div>
                        <div className="titulo">
                            <h4>Resumo habilidades  <p className="btn-fechar" onClick={VisualizarResumoHabilidade}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <div className="infor-resumo">
                            <div className="quantidade">
                                <p>{numberSkills && numberSkills !== undefined ? numberSkills : "0"}</p>
                                habilidades
                            </div>
                            <div className="quantidade">
                                <p>{numberGroups && numberGroups.length !== 0 ? numberGroups.length : "0"}</p>
                                grupos
                            </div>
                            <div className="quantidade">
                                <p>{numberCollaboratos && numberCollaboratos !== undefined ? numberCollaboratos : "0"}</p>
                                Colaboradores
                            </div>
                        </div>
                        <div className="resumo">
                            <div className="box">
                                <div className="infor">
                                    <p className="green">{skillMoreCommon && skillMoreCommon.name !== undefined ? skillMoreCommon.name : "Nenhuma"}</p>
                                    <span>habilidade mais comum</span>
                                </div>
                                <span className="quantidade-data">{skillMoreCommon && skillMoreCommon.total !== undefined ? skillMoreCommon.total+ ' colaboradores' : ""} </span>
                            </div>
                            <div className="box">
                                <div className="infor">
                                    <p className="green">
                                        {skillNew === undefined ? 'Nenhuma': skillNew.nameSkill}
                                        </p>
                                    <span>habilidades novas</span>
                                </div>
                                <span className="quantidade-data">
                                    {skillNew === undefined ? '' : skillNew.totalskill <= 1 ? '1 colaborador' : skillNew.totalskill+ ' colaboradores'}
                                    </span>
                            </div>
                            <div className="box">
                                <div className="infor">
                                    <p className="red">{
                                        skillLost && skillLost.length === 1 ?
                                        skillLost[0].name
                                        :
                                        skillLost && skillLost.length > 1 ?

                                        skillLost.map((lostSkill, index) => (
                                            index > 1 ?
                                            ''
                                            :
                                            index === 1 ?
                                            lostSkill.name
                                            :
                                            lostSkill.name+', '
                                        ))
                                        :
                                        'Nenhuma'
                                    }</p>
                                    <span>habilidades perdidas</span>
                                </div>
                                <span className="quantidade-data"> {
                                    skillLost && skillLost.length !== 0 ?
                                    'Em '+dataFormatadaParaBarra(skillLost[0].createdAt)
                                    :
                                    ''
                                }</span>
                            </div>
                        </div>

                        <button className="btnGray fechar" onClick={VisualizarResumoHabilidade}>Fechar</button>
                    </div>
                </Modal>
            </Layout>
        </>
    );
}
