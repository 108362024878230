import React from 'react';
// import ButtonClose from '../../assets/images/close.svg';
// import SuccessIcon from '../../assets/images/success.svg';
import Close from '../../assets/images/close.svg';
import './modal.scss';
const ModalSucess = ({
    id = "modalGerla", 
    onClose= () => {}, 
    children, 
    isModalBoasVindas, 
    isModalCancelaAssinatura, 
    isModalExcluiConta, 
    isModalAddSalaryhistory, 
    isModalPutSalaryhistory,
    isModalAddVacation,
    isModalPontoEditHorarioAlert,
    isModalAddJourney,
    isModalEditJourney,
    isModalPontoResumo,
    isModalPontoJustificaAlert,
    isModalPostMuralAdd,
    isModalPostMuralEdit,
    isModalCheckList
}) => {
    const handloOusidClick = (e) => {
        if (e.target.id === id) onClose();
    }
    // console.log(isModalVisible)
        return (
            <>
                <div id={id} className={
                    isModalAddSalaryhistory ||
                    isModalExcluiConta ||
                    isModalCancelaAssinatura ||
                    isModalPutSalaryhistory ||
                    isModalAddVacation ||
                    isModalPontoEditHorarioAlert ||
                    isModalPontoJustificaAlert ||
                    isModalAddJourney ||
                    isModalEditJourney ||
                    isModalPontoResumo ||
                    isModalPostMuralAdd ||
                    isModalPostMuralEdit ||
                    isModalCheckList ||
                    isModalBoasVindas ? "modal-overlay " : "modal-overlay-none modal-overlay"
                }
                
                onClick={handloOusidClick}>
                    <div 
                    className={
                        isModalPostMuralEdit === true ||
                        isModalPostMuralAdd  === true ? 
                        "container modal-post-mural-adm": "container"
                    }
                    >
                        <button className="botao-close" onClick={onClose}><img src={Close} alt="fechar" /></button>
                        {children}
                    </div>
                </div>
            </>
        );
    }
export default ModalSucess;