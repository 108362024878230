import React from 'react';
// import IconsInformation from '../../IconsInformation';
import HeaderAdmin from '../../HeaderAdmin';
import { NavLink } from 'react-router-dom'
export default function headerCadastraFeriasColetiva ({
    id
}) {

        return (
            <>
            <HeaderAdmin>
                    <div>
                        <h1>
                            <NavLink to="/ferias/" className="voltar">
                                Voltar
                            </NavLink>
                            {id && id !== undefined ? "Editar" : "Cadastrar"} férias coletivas
                        </h1>
                    </div>

            </HeaderAdmin>
                {/* <header className="header-geral header-ferias">
                    <div className="cabeçalho">
                        <h1>

                        <NavLink to="/ferias/" className="voltar">
                            Voltar
                        </NavLink>
                        {id && id !== undefined ? "Editar" : "Cadastrar"} férias coletivas</h1>
                    </div>
                    <div className="infor">
                        <IconsInformation
                        history={history}
                        />
                    </div>

                </header> */}
            </>
        );
}
