import React, { useState, useEffect } from 'react';
import Carregando from '../../../../assets/images/carregando.gif';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import api from '../../../../services/api';

const Cpf = ({
    id,
    token,
    onCollaboratorInputChange,
    collaboratorUpdate,
    collaborator,
    loadcpf,
    focuscpf,
    path
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSaved, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id){
            async function loadfilessaved(id) {
                setLoad(true)
                await api.get(`/collaborator-file-new/?id=${id}&category=docCPF`)
                .then(response => {
                    setLoad(false)
                    //console.log(response.data[0])
                    if(response.data[0] !== undefined) {
                        setFilesSaved(response.data);
                    }
                }).catch(error => {
                    setLoad(false)
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadfilessaved(id);
        }
    }, [id]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNames(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCPF');
            formData.append('collaborator', id);
            formData.append('token', token);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file-token", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file-new/?id=${id}&category=docCPF`)
                    .then(response => {
                        setLoad(false)
                        setFilesSaved(response.data);
                    }).catch(error => {
                        setLoad(false)
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                //setTokenError(error.response.data);
                console.log(error.response.data)
                setLoad(false)
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    //const deleteFile = async (filesaved) => {
    async function deleteFile(filesaved) {
        //console.log(filesaved)

        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoad(true)
                await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file-new/?id=${id}&category=docCPF`)
                        .then(response => {
                            setFilesSaved(response.data);
                        }).catch(error => {
                            setLoad(false)
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }
return (
        <>
        <div className="box-formulario cpf">
                <h4 className="titulo-box">Documentos</h4>
                <div className='linha'>
                    <div className='bloco'>
                        <div className='intemA'>
                            <p className="textCampo">CPF *</p>
                        </div>
                        <div className='intemB'>
                            {
                                collaboratorUpdate && collaboratorUpdate.cpf !== undefined ?
                                <input ref={focuscpf} type="text" name="cpf" value={collaboratorUpdate.cpf ? collaboratorUpdate.cpf : ''} placeholder="000.000.000-00" onChange={onCollaboratorInputChange} className={loadcpf ? 'inputUm inputErro' : 'inputUm'} /> :
                                collaborator && collaborator.cpf !== undefined ?
                                <input ref={focuscpf} type="text" name="cpf" value={collaborator.cpf ? collaborator.cpf : ''} onChange={onCollaboratorInputChange} className={loadcpf ? 'inputUm inputErro' : 'inputUm'}/> :
                                <input ref={focuscpf} type="text" name="cpf" onChange={onCollaboratorInputChange} placeholder="000.000.000-00" className={loadcpf ? 'inputUm inputErro' : 'inputUm'}/>
                            }
                            {
                            loadcpf ?
                            <p className="textError">O campo é obrigatório.</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                </div>
                <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoDois">É estrangeiro?</p>

                            </div>
                            <div className='intemB'>
                                <div className="inputDois chekbox">
                                    {collaborator.foreign === undefined ?
                                        (

                                            <>
                                            <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" defaultChecked={false}/>
                                            <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                            <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                            <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" defaultChecked={true}/>
                                            <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                            <label htmlFor="foreignNao" className="foreignTexto">Não</label>
                                            </>
                                        ) :
                                        (
                                            ''
                                        )}

                                        {collaborator.foreign === "nao" ?
                                        (
                                            <>
                                            <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" defaultChecked={true}/>
                                            <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                            <label htmlFor="foreignNao" className="foreignTexto">Não</label>
                                            </>
                                        ) :
                                        (
                                            ''
                                        )}

                                        {collaborator.foreign === "sim" ?
                                        (
                                            <>
                                            <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" defaultChecked={false}/>
                                            <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                            <label htmlFor="foreignNao" className="foreignTexto">Não</label>
                                            </>
                                        ) :
                                        (
                                            ''
                                        )}

                                        {collaborator.foreign === "nao" ?
                                        (
                                            <>
                                            <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" defaultChecked={false}/>
                                            <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                            <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                            </>
                                        ) :
                                        ('')
                                        }

                                        {collaborator.foreign === "sim" ?
                                        (
                                            <>
                                            <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" defaultChecked={true}/>
                                            <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                            <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                            </>
                                        ) :
                                        ('')
                                        }
                                    </div>
                            </div>
                        </div>
                    </div>


                    <div className='cpf-dropzone'>
                            <div className="dropzone">
                                <Dropzone className="" onDrop={handleDrop}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="textoOnze">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </div>
                            <div className="arquivoAnexado">
                                {filesSaved ?
                                filesSaved.map((filesaved, index) => (
                                <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        <a href={filesaved.location} target="’_blank’">
                                        {filesaved.originalname}
                                        </a>
                                    </p>
                                    <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                                </div>
                                )) : ''}
                            </div>
                            {load ?
                            (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                            ('')}
                    </div>



        </div>
        </>
        );

}
export default Cpf;
