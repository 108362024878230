import React from 'react';

import { Dropdown } from "react-bootstrap";
import iconOrdenation from '../../../../assets/images/ordenation.svg';
import iconVerMais from '../../../../assets/images/more.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import { Scrollbars } from 'react-custom-scrollbars';

export default function ColBeneficioInfortableCol({
    loadPageCollaboratorBenefit,
    collaboratorBenefit,
    name,
    nameFilter,
    loadfilterCollaboratorBenefit,
    loadCollaboratorAlphabeticalOrder,
    loadCollaboratorFirstHighestValue,
    loadCollaboratorFirstLowestValue,
    loadCollaboratortStatusActive,
    loadCollaboratortStatusUnbound,
    loadCollaboratorBenefit,
    page,
    pages,
    total,
    arrInfo,
    paginationPage,
    nextPage,
    prevPage,
    paginateCollaboratorBenefit,
    benefitCollaboratorToUnlink,
    benefitToActiving,
}) {
    async function calledUrlEditBenefit(collaborator) {
        window.location.href = `/colaboradores/${collaborator}/profissional/beneficios-do-colaborador/`;
    }

    let numberPaginate = []

    for(let i=1; i<=pages; i++){
        numberPaginate.push(
            <li className="number">
                <button className={i === page ? "secao" : ""} onClick={(event) => paginationPage(i)}>{i}</button>
            </li>
        )
    }

    return (
        <>
            <div className="header-table-detalBenef">
                <h3 className="titulo">Colaboradores com este benefício</h3>
                <form action="" className="busca">
                    <input name="name" value={nameFilter !== undefined ? nameFilter : ''} onChange={loadfilterCollaboratorBenefit} placeholder="Filtrar por colaborador"/>
                </form>
            </div>
            <div className="table-detalBenef">
                <thead>
                    <tr>
                        <th  className='nome' >
                            <p>Nome</p>
                        </th>
                        <th className='vinculo'>
                            <p>Vínculo</p>
                        </th>
                        <th  className='valorDiario'>
                            <p>Valor diário</p>
                        </th>
                        <th>
                            <p>Status</p>
                        </th>
                        <th className="th-dopdown-vermais">
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconOrdenation} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button onClick={loadCollaboratorAlphabeticalOrder} type="button">
                                        Ordem alfabética
                                    </button>
                                    <button onClick={loadCollaboratorFirstHighestValue} type="button">
                                        Maior valor primeiro
                                    </button>
                                    <button onClick={loadCollaboratorFirstLowestValue} type="button">
                                        Menor valor primeiro
                                    </button>
                                    <button onClick={loadCollaboratortStatusActive} type="button">
                                        Ativos primeiro
                                    </button>
                                    <button onClick={loadCollaboratortStatusUnbound} type="button">
                                        Desvinculados primeiro
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        loadPageCollaboratorBenefit ?
                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                        :
                        collaboratorBenefit && collaboratorBenefit.length !== 0 ?
                        collaboratorBenefit.map((benefitcollaborator, index) => (
                            <tr key={index}>
                                <td className='nome' onClick={(event) => calledUrlEditBenefit(benefitcollaborator.collaborator._id)} ><p>{benefitcollaborator.collaboradorName}</p></td>
                                <td className='vinculo' onClick={(event) => calledUrlEditBenefit(benefitcollaborator.collaborator._id)} ><p>{benefitcollaborator.bond}</p></td>
                                <td className='valorDiario' onClick={(event) => calledUrlEditBenefit(benefitcollaborator.collaborator._id)} >R$ {benefitcollaborator.collaboratorCosts}</td>
                                <td className='statos'  onClick={(event) => calledUrlEditBenefit(benefitcollaborator.collaborator._id)} ><p className={benefitcollaborator.statusCollaborator === 'Ativo' ? "ativo-status" : "desvinculado-status"}>{benefitcollaborator.statusCollaborator}</p></td>
                                <td className="td-dopdown-acoes">
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button onClick={(event) => calledUrlEditBenefit(benefitcollaborator.collaborator._id)} type="button" name="verDetalher">
                                                Ver detalhes e editar
                                            </button>
                                            {
                                                benefitcollaborator.statusCollaborator === 'Ativo' ?
                                                <button onClick={(event) => benefitCollaboratorToUnlink(benefitcollaborator._id)} type="button" name="desvincularBeneficio" className="desvincular">
                                                    Desvincular benefício
                                                </button>
                                                :
                                                <button onClick={(event) => benefitToActiving(benefitcollaborator._id)} type="button" name="ativarBeneficio" className="ativo">
                                                    Ativar benefício
                                                </button>
                                            }

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                        :
                        <>
                        <div className='nenhum-colaborador'>
                            <p>Nenhum colaborador encontrado</p>
                        </div>
                        </>
                    }
                    {
                        pages > 1 ?
                        <>
                        <div className="paginacaoG">
                            <Scrollbars>
                                <ul>
                                    {
                                        numberPaginate
                                    }
                                    <li className="page-anterior">
                                        <button style={(page === 1) ? { cursor: "not-allowed" } : {}}  disabled={page === 1} onClick={(event) => prevPage(event)} >anterior</button>
                                    </li>
                                    <li className="page-proxima">
                                        <button style={(page === pages) ? { cursor: "not-allowed" } : {}}  disabled={page === pages} onClick={(event) => nextPage(event)} >próxima</button>
                                    </li>
                                </ul>
                            </Scrollbars>
                        </div>
                        </>
                        :
                        <>
                        
                        </>
                    }
                </tbody>
            </div>
        </>
    );
}

