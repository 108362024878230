import React, {useState} from 'react';
import { Modal } from "react-bootstrap";

import Close from '../../../assets/images/close.svg';


export default function generalInformation({
    countCandidate,
    countFolderFavorite,
    counLast24Hours,
    SectorCandidateMORE_COMMON,
    SectorCandidateLESS_COMMON,
    ageCandidateMostCandidate,
    loadGeneralInformation,
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleGeneralInfor, setVisibleGeneralInfor] = useState(false);
    function VisualizarGeneralInfor() {
        setVisibleGeneralInfor(!visibleGeneralInfor);
    }
    return (
        <>

            <div className='general-information'>
                <ul className='primeiro'>
                    <li className='candidatos'>
                        <>
                        <span>{loadGeneralInformation ? '0' : countCandidate}</span>
                        <p>{
                            loadGeneralInformation ? 'candidato'
                            :
                            countCandidate === "0" ? 'candidato' : 'candidatos'
                        }
                        </p>
                        </>
                    </li>
                    <li className='pastas'>
                    <span>{loadGeneralInformation ? '0' : countFolderFavorite}</span>
                    <p>{
                        loadGeneralInformation ? 'pasta'
                        :
                        countFolderFavorite === "0" ? 'pasta' : 'pastas'
                    }
                    </p>
                    </li>
                    <li className='ultimos-24h'>
                        <span>+ {loadGeneralInformation ? '0' : 
                            counLast24Hours === null ||counLast24Hours === "null" ?
                            "0":counLast24Hours
                        }</span>
                        <p>
                            {/* {
                            loadGeneralInformation ? ''
                            :
                             'nas 24 horas'
                        } */}
                        nas 24 horas
                        </p>
                    </li>
                </ul>
                <ul className='segundo'>
                    <li className='setor-comum'>
                        <span>
                            { 
                                loadGeneralInformation ? 
                                'Nenhum' 
                                : 
                                SectorCandidateMORE_COMMON === "" || SectorCandidateMORE_COMMON === null ? 
                                'Nenhum' : SectorCandidateMORE_COMMON
                            }
                        </span>
                        <p>setor mais comum</p>
                    </li>
                    <li className='setor-menos-comum'>
                        <span>
                            {
                                loadGeneralInformation ? 
                                'Nenhum' 
                                : 
                                SectorCandidateLESS_COMMON === ""|| SectorCandidateLESS_COMMON  === null ? 
                                'Nenhum' : SectorCandidateLESS_COMMON
                            }
                        </span>
                        <p>setor menos comum</p>
                    </li>
                    <li className='faixa-etaria'>
                        <span>
                            {
                                loadGeneralInformation ? 
                                'Nenhum' 
                                : 
                                ageCandidateMostCandidate === "" || 
                                ageCandidateMostCandidate === null || 
                                ageCandidateMostCandidate === "0" ? 
                                'Nenhum' : ageCandidateMostCandidate
                            }
                        </span>
                        <p>faixa etária mais comum</p>
                    </li>
                    <li className='ver-resumo'>
                        <button onClick={VisualizarGeneralInfor}>Ver resumo</button>
                    </li>
                </ul>
            </div>
            <Modal show={visibleGeneralInfor} className="popup-geral popup-talentos-resumo" onHide={VisualizarGeneralInfor}>
            <div>
                <div className="titulo">
                    <h4>Resumo  <p className="btn-fechar" onClick={VisualizarGeneralInfor}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className='general-information'>
                    <ul className='primeiro'>
                        <li className='candidatos'>
                            <span>472</span>
                            <p>candidatos</p>
                        </li>
                        <li className='pastas'>
                            <span>36</span>
                            <p>pastas</p>
                        </li>
                        <li className='ultimos-24h'>
                            <span>+ 22</span>
                            <p>nas últimas 24h</p>
                        </li>
                    </ul>
                    <ul className='segundo'>
                        <li className='setor-comum'>
                            <span>Social Media</span>
                            <p>setor mais comum</p>
                        </li>
                        <li className='setor-menos-comum'>
                            <span>Backend</span>
                            <p>setor menos comum</p>
                        </li>
                        <li className='faixa-etaria'>
                            <span>18-35 anos</span>
                            <p>faixa etária mais comum</p>
                        </li>
                    </ul>
                </div>
                <button className="btnGray fechar" onClick={VisualizarGeneralInfor}>Fechar</button>
            </div>
        </Modal>
        </>
    );
}

