import React,{
} from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./styles.scss";
export default function skeletonHomem(props) {
     
    return(
        <>
        
               <div className="Skeleton-website-vacancies vagas">
                 
                 
                    <Skeleton  
                        count={1}  
                        width={300}
                        height={20}
                        borderRadius={9}
                    />
                    <br/>
                    <br/>
                    <br/>
                    <div className="infor-aplicar">
                        <div className="infor">

                            <Skeleton  
                                count={1}  
                                height={20}
                                borderRadius={9}
                            />
                            <div  className="infor-vaga">

                                <Skeleton  
                                    count={1}  
                                    height={40}
                                    borderRadius={9}
                                />

                                <Skeleton  
                                    count={1}  
                                    height={40}
                                    borderRadius={9}
                                />
                                <Skeleton  
                                    count={1}  
                                    height={40}
                                    borderRadius={9}
                                />
                                <Skeleton  
                                    count={1}  
                                    height={40}
                                    borderRadius={9}
                                />
                            </div>

                        </div>
                        <div>
                            <Skeleton  
                                count={1}  
                                height={70}
                                borderRadius={9}
                            />

                        </div>

                    </div>
                    <br/> 
                    <br/> 
                    <div className="conteudo">
                        <Skeleton  
                            count={1}  
                            height={200}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            width={200}
                            height={30}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            height={60}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            height={60}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            width={200}
                            height={30}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            width={600}
                            height={20}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1} 
                            width={600} 
                            height={20}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            height={20}
                            width={600}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            width={200}
                            height={30}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            width={600}
                            height={20}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1} 
                            width={600} 
                            height={20}
                            borderRadius={9}
                        />
                        <br/> 
                        <Skeleton  
                            count={1}  
                            height={20}
                            width={600}
                            borderRadius={9}
                        />
                        <br/> 
                        <br/> 
                        <Skeleton  
                            count={1}  
                            height={400}
                            borderRadius={9}
                        />

                    </div>
                   
               </div>

        </>
    )
}
