import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import Dropzone from 'react-dropzone';
import Carregando from '../../../assets/images/carregando.gif';
import { formatDate } from "../../../services/utils"
import { Modal } from "react-bootstrap";
import Close from '../../../assets/images/close.svg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import api from '../../../services/api';
import { dateFormatedToShutDown } from '../../../services/utils';
import { toast } from 'react-toastify';

const AguardandoAprovacao = ({
    swal,
    collaboratorVacationInfo,
    visibleAccountingProcedures,
    showAccountingProcedures,
    noShowAccountingProcedures,
    vacationAwaitingManagerApproval,
    vacationAwaitingSubmissionToAccounting,
    loadVacationAwaitingManagerApproval,
    deleteRequestsInProgress,
    role,
    completeVacationRequest,
    loadBottomCompleteVacation,
}) => {

    const idCollaboratorVacationInfo = collaboratorVacationInfo.collaborator;
    const subsPercentMelon               = (15);
    const subsPercentMelonManager        = (35);
    const subsPercentMelonHumanResources = (55);
    const subsPercentGreen               = (80);

    //AccountingDocuments
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ accountingDocuments, setAccountingDocuments ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ fileForm, setFileForm ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(idCollaboratorVacationInfo !== undefined) {

            loadFileAccountingDocuments(idCollaboratorVacationInfo);

        }

    }, [idCollaboratorVacationInfo]);


    async function loadFileAccountingDocuments(idCollaboratorVacationInfo) {
        await api.get(`/accounting-documents/${idCollaboratorVacationInfo}`)
        .then(response => {
            setFileAccountingDocuments(response.data);
        }).catch((error) => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }




    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            let name = acceptedFiles[0].name;
            setFileNames(acceptedFiles.map(file => file.name));
            setFileForm({ ...fileForm,  file, name })
            setAccountingDocuments({ ...accountingDocuments, file })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    //setLoadErrorSendEmailToAccounting
    const [loadErrorSendEmailToAccounting2, setLoadErrorSendEmailToAccounting2] = useState(false);


    async function myChangeHandlerAccountingDocuments(e) {
        let nam          = e.target.name;
        let val          = e.target.value;
        let collaborator = collaboratorVacationInfo.collaborator;

        if(nam === 'accounting') {
            if(val === 'Selecione') {
                setLoadErrorSendEmailToAccounting2(true)
                setAccountingDocuments({ ...accountingDocuments, [nam]: val, collaborator })
            }
            else {
                setLoadErrorSendEmailToAccounting2(false)
                setAccountingDocuments({ ...accountingDocuments, [nam]: val, collaborator })
            }
        }
        else {
            setAccountingDocuments({ ...accountingDocuments, [nam]: val, collaborator })
        }


    }

    //loadBottomSend
    const [loadBottomSend, setLoadBottomSend] = useState(false);

    // loadingFiles
    const [loadingFiles, setloadingFiles] = useState(false);

    async function saveAccountingDocuments() {
        if(
            (accountingDocuments &&
            accountingDocuments.length === 0) ||
            accountingDocuments.accounting === "Selecione"
        ) {
            setLoadErrorSendEmailToAccounting2(true)
        }
        else {
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            formData.append('file', accountingDocuments.file);
            formData.append('accounting', accountingDocuments.accounting);
            formData.append('collaborator', accountingDocuments.collaborator);

            setLoadErrorSendEmailToAccounting2(false)
            setLoadBottomSend(true)
            async function loadaccoutingdocuments() {

                await api.post('/accounting-documents/', formData, config)
                .then(response => {
                    setLoadBottomSend(false)
                    setAccountingDocuments([])
                    setAccountingDocuments([])
                    setFileForm([])

                    async function loadFileAccountingDocuments() {
                        await api.get(`/accounting-documents/${collaboratorVacationInfo.collaborator}`)
                        .then(response => {
                            setFileAccountingDocuments(response.data);
                        }).catch((error) => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadFileAccountingDocuments();


                }).catch(error => {
                    setLoadBottomSend(false)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });
            }
            loadaccoutingdocuments();

        }
    }

    //RequestsSendToAccounting
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [requestsSendToAccounting, setRequestsSendToAccounting] = useState([]);
    const [loadButtomSendEmailToAccounting, setLoadButtomSendEmailToAccounting] = useState(false);

    //myChangeHandlerCollaboratorRequestsSendToAccounting
    async function myChangeHandlerCollaboratorRequestsSendToAccounting(e) {

        let nam = e.target.name;
        let val = e.target.value;

        let vacationInfo = collaboratorVacationInfo;

        if(nam === 'idAccounting') {

            if(val === 'Selecione') {
                setLoadErrorSendEmailToAccounting(true)
                setRequestsSendToAccounting({ ...requestsSendToAccounting, [nam]:val })
            }
            else {
                setLoadErrorSendEmailToAccounting(false)
                setRequestsSendToAccounting({ ...requestsSendToAccounting, [nam]:val, vacationInfo })
            }

        }
        else {
            setRequestsSendToAccounting({ ...requestsSendToAccounting, [nam]:val })
        }
    }

    // collaboratorVacationInfo && collaboratorVacationInfo.sentToAccounting === true
    useEffect(() => {
        async function loadcollaboratorvacations() {

            if(
                collaboratorVacationInfo &&
                collaboratorVacationInfo.sentToAccounting === true
            ) {
                setVisibleInformation(false);
                setVisibleDocuments(true)
            }


        }
        loadcollaboratorvacations();
    }, [collaboratorVacationInfo]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleInformation, setVisibleInformation] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleDocuments, setVisibleDocuments] = useState(false);

    //setLoadErrorSendEmailToAccounting
    const [loadErrorSendEmailToAccounting, setLoadErrorSendEmailToAccounting] = useState(false);

    //collaboratorRequestsSendToAccounting
    async function collaboratorRequestsSendToAccounting(e) {
        setLoadButtomSendEmailToAccounting(true)
        let collaborator = e.collaborator;

        if((requestsSendToAccounting && requestsSendToAccounting.idAccounting === undefined) || requestsSendToAccounting.idAccounting === 'Selecione') {

            setLoadErrorSendEmailToAccounting(true)
            setLoadButtomSendEmailToAccounting(false)
        }
        else {

            setLoadButtomSendEmailToAccounting(true)
            if(
                (requestsSendToAccounting &&
                requestsSendToAccounting.accountingComments === "")
                ||
                (requestsSendToAccounting &&
                requestsSendToAccounting.accountingComments === undefined)
            ) {
                async function loadcollaboratorrequestsendtoaccounting() {

                    await api.post(`/collaborator-requests-send-to-accounting/?id=${collaborator}`, requestsSendToAccounting)
                    .then(response => {
                        let sentToAccounting = response.data.sentToAccounting;
                        if(sentToAccounting === true) {
                            setLoadButtomSendEmailToAccounting(false)
                            setVisibleInformation(false)
                            setVisibleDocuments(true)
                        }

                    }).catch(error => {});
                }
                loadcollaboratorrequestsendtoaccounting();
            }
            else {
                async function loadcollaboratorrequestsendtoaccounting() {

                    await api.post(`/collaborator-requests-send-to-accounting/?id=${collaborator}`, requestsSendToAccounting)
                    .then(response => {
                        let sentToAccounting = response.data.sentToAccounting;
                        if(sentToAccounting === true) {
                            setLoadButtomSendEmailToAccounting(false)
                            setVisibleInformation(false)
                            setVisibleDocuments(true)
                        }


                    }).catch(error => {});
                }
                loadcollaboratorrequestsendtoaccounting();
            }

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sendToAccounting, setSendToAccounting] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadsendtoaccounting() {
            setloadingFiles(true)
            await api.get('/accounting/')
            .then(response => {
                setSendToAccounting(response.data);
                setloadingFiles(false)
            }).catch((error) => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setloadingFiles(false)
            })
        }
        loadsendtoaccounting();
    }, []);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [fileAccountingDocuments, setFileAccountingDocuments] = useState([]);

     async function deleteFileDismissalForm(filesaved) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {
                await api.delete(`/accounting-documents/${filesaved._id}`)
                    .then(() => {
                        async function loadFileAccountingDocuments() {
                            setloadingFiles(true)
                            await api.get(`/accounting-documents/${collaboratorVacationInfo.collaborator}`)
                            .then(response => {
                                setFileAccountingDocuments(response.data);
                                setFileForm([]);
                                setloadingFiles(false)
                            }).catch((error) => {
                                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                setloadingFiles(false)
                            })
                        }
                        loadFileAccountingDocuments();
                    }).catch(() => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                    });
            }
        });
    }

    return (
        <>
        <aside className="solicitacaes-andamento">
        <h2 className="titulo-box">Solicitações em andamento</h2>
            <ul>
                {
                    loadVacationAwaitingManagerApproval ?
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    :
                    vacationAwaitingManagerApproval                     &&
                    vacationAwaitingManagerApproval.length        === 0 &&
                    vacationAwaitingSubmissionToAccounting              &&
                    vacationAwaitingSubmissionToAccounting.length === 0 ?
                    <>
                    <div className="lista-vazia"  >
                        <p>Nenhuma solicitação de férias
                            em andamento no momento.</p>
                    </div>
                    </>
                    :
                    ''
                }
                    {
                    loadVacationAwaitingManagerApproval ?
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    :
                    vacationAwaitingManagerApproval &&
                    vacationAwaitingManagerApproval.length !== 0 ?
                    vacationAwaitingManagerApproval
                    .map((vacationManagerapproval, index) => (
                        <li key={index}>
                            <div className="infor-cal">
                                {
                                    vacationManagerapproval &&
                                    vacationManagerapproval.collaborator &&
                                    vacationManagerapproval.collaborator.avatar !== undefined ?
                                    <>
                                    <div className="img-col">
                                        <NavLink to={`/ferias/solicitacao-de-ferias/${vacationManagerapproval._id}`} >
                                            <img src={vacationManagerapproval.collaborator.avatar} alt="nome" />
                                        </NavLink>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <NavLink to={`/ferias/solicitacao-de-ferias/${vacationManagerapproval._id}`}>
                                        <div className="semImgCol">
                                            <img src={IconUserNone} alt="coll" />
                                        </div>
                                    </NavLink>
                                    </>
                                }
                                <div className="nome-setor-date">
                                    <NavLink to={`/ferias/solicitacao-de-ferias/${vacationManagerapproval._id}`} exact activeClassName="active" className="nome">
                                    {
                                        vacationManagerapproval.collaborator &&
                                        vacationManagerapproval.collaborator.name !== undefined ?
                                        vacationManagerapproval.collaborator.name :
                                        '-'
                                    }
                                    </NavLink>
                                    <div className="setor-date">
                                        <p className="setor"> {
                                            vacationManagerapproval.collaborator &&
                                            vacationManagerapproval.collaborator.occupation !== undefined ?
                                            vacationManagerapproval.collaborator.occupation :
                                            ''
                                        }</p>
                                        <p className="date">De <span>{dateFormatedToShutDown(vacationManagerapproval.startDate)}</span> Até <span>{dateFormatedToShutDown(vacationManagerapproval.endDate)}</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="barra-progresso-laranja">
                                    <span
                                    style={
                                        vacationManagerapproval &&
                                        vacationManagerapproval.humanResourcesStatus === 'pendente' && vacationManagerapproval.managerStatus === 'pendente' ?
                                        { width: `${subsPercentMelon}%`} :
                                        vacationManagerapproval.humanResourcesStatus === 'pendente' && vacationManagerapproval.managerStatus === 'aprovado' ?
                                        { width: `${subsPercentMelonManager}%`} :
                                        vacationManagerapproval.humanResourcesStatus === 'aprovado' && vacationManagerapproval.managerStatus === 'aprovado' ?
                                        { width: `${subsPercentMelonHumanResources}%`} :
                                        vacationManagerapproval.humanResourcesStatus === 'aprovado' && vacationManagerapproval.managerStatus === 'pendente' ?
                                        { width: `${subsPercentMelonHumanResources}%`} :
                                        { width: `${subsPercentMelonManager}%`}
                                    }
                                    >  </span>
                            </div>
                            <div className="status">
                                <p>
                                    Status:
                                    {
                                        vacationManagerapproval &&
                                        vacationManagerapproval.humanResourcesStatus === 'pendente' && vacationManagerapproval.managerStatus === 'pendente' ?
                                        ' Aguardando aprovação do gestor'
                                        :
                                        vacationManagerapproval.humanResourcesStatus === 'pendente' && vacationManagerapproval.managerStatus === 'aprovado' ?
                                        ' Aguardando aprovação do RH'
                                        :
                                        vacationManagerapproval.humanResourcesStatus === 'aprovado' && vacationManagerapproval.managerStatus === 'pendente' ?
                                        ' Aguardando concluir e ir à contabilidade'
                                        :
                                        vacationManagerapproval.humanResourcesStatus === 'aprovado' && vacationManagerapproval.managerStatus === 'aprovado' ?
                                        ' Aguardando concluir e ir à contabilidade'
                                        :
                                        ''
                                    }
                                    </p>

                                <NavLink to={`/ferias/solicitacao-de-ferias/${vacationManagerapproval._id}`} className="visualizar">
                                Visualizar
                                </NavLink>
                                {
                                    role && role === 'admin' ?
                                    <button onClick={() => deleteRequestsInProgress(vacationManagerapproval._id)}  className="remover">Remover</button>
                                    :
                                    ''
                                }
                            </div>

                        </li>
                    ))
                    :
                    ''
                }
                {
                    loadVacationAwaitingManagerApproval ?
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    :
                    vacationAwaitingSubmissionToAccounting &&
                    vacationAwaitingSubmissionToAccounting.length !== 0 ?
                    vacationAwaitingSubmissionToAccounting
                    .map((vacationSubmissionToAccounting, index) => (
                        <li key={index}>
                            <div className="infor-cal">
                            {
                                vacationSubmissionToAccounting.collaborator.avatar !== undefined ?
                                <>
                                <div className="img-col">
                                    <NavLink to={`/ferias/solicitacao-de-ferias/${vacationSubmissionToAccounting._id}`} >
                                        <img src={vacationSubmissionToAccounting.collaborator.avatar} alt="nome" />
                                    </NavLink>
                                </div>
                                </>
                                :
                                <>
                                <NavLink to={`/ferias/solicitacao-de-ferias/${vacationSubmissionToAccounting._id}`}>
                                    <div className="semImgCol">
                                        <img src={IconUserNone} alt="coll" />
                                    </div>
                                </NavLink>
                                </>
                            }
                            <div className="nome-setor-date">
                                <NavLink to={`/ferias/solicitacao-de-ferias/${vacationSubmissionToAccounting._id}`} exact activeClassName="active" className="nome">
                                {vacationSubmissionToAccounting.collaborator.name}
                                </NavLink>
                                <div className="setor-date">
                                    <p className="setor"> {vacationSubmissionToAccounting.collaborator.occupation}</p>
                                    <p className="date">De <span>{dateFormatedToShutDown(vacationSubmissionToAccounting.startDate)}</span> Até <span>{dateFormatedToShutDown(vacationSubmissionToAccounting.endDate)}</span></p>
                                </div>
                            </div>
                            </div>
                            <div className="barra-progresso-verde">
                                    <span style={{ width: `${subsPercentGreen}%`}} >  </span >
                            </div>
                            <div className="status">
                                {
                                    role && role === 'admin' ?
                                    <p>Status: Contabilidade
                                        <button className="contabilidade" onClick={(e) => showAccountingProcedures(vacationSubmissionToAccounting)}>Revisar e finalizar</button>
                                    </p>
                                    :
                                    ''
                                }
                                <NavLink to={`/ferias/solicitacao-de-ferias/${vacationSubmissionToAccounting._id}`} className="visualizar">
                                Visualizar
                                </NavLink>
                                {
                                    role && role === 'admin' ?
                                    <button onClick={() => deleteRequestsInProgress(vacationSubmissionToAccounting._id)} className="remover">Remover</button>
                                    :
                                    ''
                                }
                            </div>

                        </li>
                    ))
                    :
                    ''
                }

            </ul>
        </aside>

            <Modal show={visibleAccountingProcedures} className="popup-geral popup-tramites-contabilidade" onHide={noShowAccountingProcedures}>
                <div className="titulo">
                    <h4>Trâmites da contabilidade <p className="btn-fechar" onClick={noShowAccountingProcedures}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                    <div className="menu-processo">
                        <div className="menu menu-ativo menu-left">
                            <div  className="nome-processo">
                                <p>Informações</p>
                            </div>
                            <div className="numero-linha">
                                <p className="numero">
                                    <span>1</span>
                                </p>
                                <div className="linha"></div>

                            </div>
                        </div>
                        <div className={visibleDocuments === true  ? "menu menu-ativo menu-right" : "menu menu-right"}>
                            <div className="nome-processo">
                                <p>Documentos</p>
                            </div>
                            <div className="numero-linha">
                                <p className="numero">
                                    <span>2</span>
                                </p>
                                <div className="linha"></div>
                            </div>
                        </div>
                    </div>



                    <div
                    className="informacoes"
                    style={visibleInformation === true ? {display:'block'} : {display:'none'}}
                    >
                        <div className="infor">
                            <h6>Informações para a contabilidade</h6>
                            <p>O contador disponível irá analisar os dados do <br/>colaborador.</p>
                        </div>
                        <div className="infordois">
                            <h6>{collaboratorVacationInfo?.collaborator?.name ? collaboratorVacationInfo?.collaborator?.name : ''}</h6>
                            <div className="infor">
                                <p className="nome">Período</p>
                                <p className="data">De <span>{collaboratorVacationInfo?.startDate ? dateFormatedToShutDown(collaboratorVacationInfo?.startDate) : ''}</span> à <span>{collaboratorVacationInfo?.endDate ? dateFormatedToShutDown(collaboratorVacationInfo?.endDate) : ''} ({collaboratorVacationInfo && collaboratorVacationInfo.vacationDays ? collaboratorVacationInfo.vacationDays : ''} dias)</span></p>
                            </div>
                            <div className="infor">
                                <p className="nome">Abono</p>
                                <p className="detalhe">{collaboratorVacationInfo?.vacationDaysSold !== 0 ? collaboratorVacationInfo?.vacationDaysSold : 'Nenhum'}</p>
                            </div>
                            <div className="infor">
                                <p className="nome">Antecipar 13º</p>
                                <p className="detalhe">{collaboratorVacationInfo?.ThirteenthSalary === true ? 'Sim' : 'Não'}</p>
                            </div>
                            <div className="infor">
                                <p className="nome">Dias solicitados</p>
                                <p className="detalhe">{collaboratorVacationInfo?.balance ? collaboratorVacationInfo?.balance : ''} dias</p>
                            </div>
                        </div>
                        <div className="seleciona-contador">
                            <p className="textCampo textoUm">Contador</p>
                            <select name="idAccounting" onChange={myChangeHandlerCollaboratorRequestsSendToAccounting} className={loadErrorSendEmailToAccounting ? "inputUm inputErro" : "inputUm" }>
                                <option value="Selecione">Selecione</option>
                                {sendToAccounting && sendToAccounting.map((item, index) => (
                                    <option value={item._id} key={index}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="formulario">
                            <textarea onChange={myChangeHandlerCollaboratorRequestsSendToAccounting} name="accountingComments" placeholder="Comentário opcional ao contador…"></textarea>
                        </div>
                        <button onClick={() => collaboratorRequestsSendToAccounting(collaboratorVacationInfo)} className={loadButtomSendEmailToAccounting ? "enviar btnGreen load" : "enviar btnGreen"}>Enviar</button>

                    </div>



                    <div
                    className="documentos"
                    style={visibleDocuments === true ? {display:'block'} : {display:'none'}}
                    >
                        <div className="infor">
                            <h6>Documentos da contabilidade</h6>
                            <p>Revise e faça upload dos documentos enviados pelo <br/>
                            contador relativos aos valores do período de férias.</p>
                        </div>
                        <div className="seleciona-contador">
                            <p className="textCampo textoUm">Contador</p>
                            {
                                <select value={accountingDocuments && accountingDocuments.accounting ? accountingDocuments.accounting : ''} name="accounting" onChange={myChangeHandlerAccountingDocuments} className={loadErrorSendEmailToAccounting2 ? "inputUm inputErro" : "inputUm" }>
                                <option value="Selecione">Selecione</option>
                                {sendToAccounting && sendToAccounting.map((item, index) => (
                                    <option value={item._id} key={index}>{item.name}</option>
                                ))
                                }
                                </select>
                            }
                        </div>
                        <div className="anexarArquivo">

                            <Dropzone onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input type="file" name="file" {...getInputProps()} />
                                    <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                </div>
                                )}
                            </Dropzone>
                            <button onClick={saveAccountingDocuments} className={loadBottomSend ? "enviar btnGreen load" : "enviar btnGreen"}>Salvar</button>

                        </div>
                        <div className="arquivosEnviados">
                            {
                                fileForm &&
                                fileForm.name ?
                                <>
                                <h5>Anexado - {fileForm.name}</h5>
                                </>
                                :
                                ''
                            }
                            {
                                fileAccountingDocuments &&
                                fileAccountingDocuments.length !== 0 ?
                                <h5>Enviados</h5>
                                :
                                ''
                            }

                            {
                                loadingFiles ?
                                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                :
                                fileAccountingDocuments &&
                                fileAccountingDocuments.length !== 0 ?
                                fileAccountingDocuments
                                .map((fileaccountingDocs, index) => (
                                    <div className="arquivos" key={index}>
                                    <div className="nameArquivo">
                                        <a href={fileaccountingDocs.location} target="’_blank’">
                                            <span>
                                            {fileaccountingDocs.originalname}
                                            </span>
                                        </a>
                                        <span className="data">Enviado em {formatDate(fileaccountingDocs.createdAt)}</span>
                                    </div>
                                    <div className="btnExclui">
                                        <button onClick={()=>deleteFileDismissalForm(fileaccountingDocs)}>Excluir anexo</button >
                                    </div>
                                </div>
                                ))
                                :
                                <div className="nenhum-arq">
                                <p>Nenhum arquivo cadastrado.</p>
                                </div>

                            }
                        </div>
                        <button onClick={completeVacationRequest} className={loadBottomCompleteVacation ? "conluir btnGreen load" : "conluir btnGreen"}>Concluir solicitação</button>

                    </div>
            </Modal>
        </>
    );
}
export default AguardandoAprovacao;
