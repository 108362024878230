import React, { useState } from 'react';
import Close from '../../../assets/images/close.svg';
import { dataFormatadaParaPonto } from '../../../services/utils'
import { Modal } from "react-bootstrap";

export default function headerHabilidadesInforBox({
    totalSkill,
    totalGroup,
    skillNew,
    skillMoreCommon,
    skillLost,
}) {
    const habilidadesPerdidas = skillLost.length -1;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleResumoHabilidade, setVisibleResumoHabilidade] = useState(false);
    function VisualizarResumoHabilidade() {
        setVisibleResumoHabilidade(!visibleResumoHabilidade);
    }
    const countCollaborators = localStorage.getItem('@peopleview/countCollaborators');
    return (
        <>
        <div className="box-infor">
            <div>
                <ul>
                    <li>
                        <span className="verde">{totalSkill}</span>
                        <p>habilidades</p>
                    </li>
                    <li className='grupos'>
                        <span className="verde">
                            {
                            totalGroup &&
                            totalGroup.length ?
                            totalGroup.length
                            :
                            "0"
                            }
                        </span>
                        <p>
                            {
                                totalGroup &&
                                totalGroup.length ?
                                'grupos'
                                :
                                'grupo'
                            }
                        </p>
                    </li>
                    <li className='colaborador'>
                        <span className="verde">{countCollaborators}</span>
                        <p>colaboradores</p>
                    </li>
                    <li className='comum'>
                        <span className="verde">{
                            skillMoreCommon.length === 0 || skillMoreCommon === "Nenhuma" ? 'Nenhuma' : skillMoreCommon.name
                        }</span>
                        <p>habilidade mais comum</p>
                    </li>
                    <li className='novas'>
                        <span className="verde">{
                        skillNew && skillNew.length === 0 ?
                        'Nenhuma'
                        :
                        skillNew.nameSkill
                        }</span>
                        <p>habilidades novas</p>
                    </li>
                    <li className='perdidas'>
                        <span className="vermelho">{
                            skillLost && skillLost.length === 1 ?
                            skillLost[0].name
                            :
                            skillLost && skillLost.length > 1 ?
                            skillLost[0].name+ ' +'+habilidadesPerdidas
                            :
                            'Nenhuma'

                        }</span>
                        <p>habilidades perdidas</p>
                    </li>
                </ul>
            </div>

            <button className="ver-resumo" onClick={() => VisualizarResumoHabilidade()} >Ver resumo</button>
        </div>
        <Modal show={visibleResumoHabilidade} className="popup-geral popup-resumo-habilidade" onHide={VisualizarResumoHabilidade}>
            <div>
                <div className="titulo">
                    <h4>Resumo habilidades  <p className="btn-fechar" onClick={VisualizarResumoHabilidade}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className="infor-resumo">
                    <div className="quantidade">
                        <p>{totalSkill}</p>
                        habilidades
                    </div>
                    <div className="quantidade">
                        <p>
                            {
                                totalGroup &&
                                totalGroup.length ?
                                totalGroup.length
                                :
                                "0"
                            }
                        </p>
                        {
                            totalGroup &&
                            totalGroup.length ?
                            'grupos'
                            :
                            'grupo'
                        }
                    </div>
                    <div className="quantidade">
                        <p>{countCollaborators}</p>
                        Colaboradores
                    </div>
                </div>
                <div className="resumo">
                    <div className="box">
                        <div className="infor">
                            <p className="green">{skillMoreCommon === "Nenhuma" || skillMoreCommon?.length === 0 ? 'Nenhuma' : skillMoreCommon?.name}</p>
                            <span>habilidade mais comum</span>
                        </div>
                        <span className="quantidade-data">{
                            skillMoreCommon === "Nenhuma" ||
                            skillMoreCommon.length === 0 ?
                            '0 colaborador'
                            :
                            skillMoreCommon.total === 1 ?
                            skillMoreCommon.total+ ' colaborador'
                            :
                            skillMoreCommon.total+ ' colaboradores'
                        } </span>
                    </div>
                    <div className="box">
                        <div className="infor">
                            <p className="green">{
                                skillNew === undefined || skillNew === null ?
                                'Nenhuma'
                                :
                                skillNew.nameSkill
                            }</p>
                            <span>habilidades novas</span>
                        </div>
                        <span className="quantidade-data">{
                        skillNew.length === 0 ? ''
                        :
                        skillNew.totalskill === 0 ?
                        '0 colaborador'
                        :
                        skillNew.totalskill === 1 ?
                        '1 colaborador'
                        :
                        skillNew.totalskill+ ' colaboradores'
                        }</span>
                    </div>
                    <div className="box">
                        <div className="infor">
                            <p className="red">{
                                skillLost && skillLost.length === 1 ?
                                skillLost[0].name
                                :
                                skillLost && skillLost.length > 1 ?

                                skillLost.map((lostSkill, index) => (
                                    index === 0 ?
                                    lostSkill.name+', '
                                    :
                                    index > 1 ?
                                    ', '+ lostSkill.name
                                    :
                                    lostSkill.name
                                ))
                                :
                                'Nenhuma'
                            }</p>
                            <span>habilidades perdidas</span>
                        </div>
                        <span className="quantidade-data"> {
                            skillLost && skillLost.length !== 0 ?
                            'Em '+dataFormatadaParaPonto(skillLost[0].createdAt)
                            :
                            ''
                        }</span>
                    </div>
                </div>

                <button className="btnGray fechar" onClick={VisualizarResumoHabilidade}>Fechar</button>
            </div>
        </Modal>
        </>
    );
}

