import React from 'react';

// import { NavLink } from 'react-router-dom'
// import iconOrdenation from '../../../assets/images/ordenation.svg';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';
// import NenhumaHabilidade from '../../../assets/images/illustration-nenhum-habilidade.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import Close from '../../../assets/images/close.svg';
// import ImgExemplo from '../../../assets/images/emer.jpg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import IconArrowVazioVerde from '../../../assets/images/arrow-vazio-verde.svg';
import { Modal } from "react-bootstrap";

const headerHabilidadesByGroup = ({
    byGroup,
    loadPageByGroup,
    pages,
    CastrarGrupo,
    pageByGroup,
    prevPageByGroup,
    nextPageByGroup,
    paginationPageByGroup,
    VerGrupo,
    EditarGrupo,
    visibleVerGrupo,
    NaoVerGrupo,
    showGroup,
    showSkillInGroup,
    showCollaboratorInGroup,
    visibleEditrupo,
    loadNameEditGrupo,
    loadNameEditGrupoHab,
    NaoEditarGrupo,
    loadFilterCollaboratorForThisGroup,
    showCollaboratorSavedInGroup,
    collaboratorFilterForThisGroup,
    name,
    myChangeHandlerToAddCollaboratorEditGroup,
    myChangeHandlerExcludeCollaboratorAddedEditInTheGroup,
    filterSkillForThisGroup,
    skillFilterForThisGroup,
    myChangeHandlerToAddSkillToEditGroup,
    showSkillEditInGroup,
    myChangeHandlerExcludeSkillAddedEditInTheGroup,
    myChangeHandlerToEditGroup,
    updateGroup,
    loadUpdateGroup,
    deleteGroup,
    handleKeyPressEditarGrupo,

    loadShowCollaboratorSavedInGroup,
    loadShowSkillEditInGroup
}) => {
    let numberPaginate = []
    for(let i=1; i<=pages; i++){
        numberPaginate.push(
            <li className="number">
                <button className={i === pageByGroup ? "secao" : ""} onClick={(event) => paginationPageByGroup(i)}>{i}</button>
            </li>
        )
    }
    return (
        <>
        <div  className="grupos-habilidades">
                <ul className="lista-grupo">
                    {
                        loadPageByGroup ?
                        <div className="carregamento-grupo">
                            <img alt="" src={Carregamento} />
                        </div>
                        :
                        byGroup[0] ?
                        byGroup.map((group, index) => (
                            <li key={index}>
                                <div className="infor">
                                    <p>{group.nameGroup}</p>
                                    <span>{group.totalCollaborators} colaboradores</span>
                                    <span>{group.totalSkill} habilidades</span>
                                </div>
                                <div className="dopdown-acoes">
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button type="button" name="Visualizer-grupo" onClick={() => VerGrupo(group)}>
                                                Ver grupo
                                            </button>
                                            <button type="button" name="editar-grupo" onClick={() => EditarGrupo(group)}>
                                                Editar grupo
                                            </button>
                                            <button onClick={() => deleteGroup(group._id)} type="button" name="Exclui-grupo" className="red">
                                                Excluir
                                            </button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                        ))
                        :
                        <>
                        <div className="nenhuma-grupo">
                            <p>Nenhuma grupo cadastrado.</p>
                            <button className="btnGreen more btn-cadastrar-g" onClick={() => CastrarGrupo()}>Cadastrar novo grupo</button>
                        </div>
                        </>

                    }
                </ul>
                <div className="paginacaoG">
                    <ul>
                        {
                            pages > 1 ?
                            numberPaginate
                            :
                            ''
                        }
                        {
                            pages > 1 ?
                            <>
                                <li className="page-anterior">
                                    <button tyle={(pageByGroup === 1) ? { cursor: "not-allowed" } : {}}  disabled={pageByGroup === 1} onClick={(event) => prevPageByGroup(event)} >anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(pageByGroup === pages) ? { cursor: "not-allowed" } : {}}  disabled={pageByGroup === pages} onClick={(event) => nextPageByGroup(event)} >próxima</button>
                                </li>
                            </>
                            :
                            ''
                        }


                    </ul>
                </div>
            </div>

            <Modal show={visibleVerGrupo} restoreFocus={false} className="popup-geral popup-ver-grupo" onHide={NaoVerGrupo}>
                    <div>
                        <div className="titulo">
                            <h4>Grupo {showGroup && showGroup.nameGroup ? showGroup.nameGroup : ''} <p className="btn-fechar" onClick={NaoVerGrupo}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <div className="infor-grupo">
                            <div className="quantidade">
                                <p>{showGroup && showGroup.totalSkill ? showGroup.totalSkill : ''}</p>
                                habilidades
                            </div>
                            <div className="quantidade">
                                <p>{showGroup && showGroup.totalCollaborators ? showGroup.totalCollaborators : ''}</p>
                                Colaboradores
                            </div>
                            <span className="data">
                                Criado em {showGroup && showGroup.createdFormated ? showGroup.createdFormated : ''}
                            </span>
                        </div>
                        <h4>Habilidades neste grupo</h4>
                        {/* //////// HABILIDADES ADICIONADAS  ///////*/}
                        <div className="habilidades-adicionada">
                            {
                                showSkillInGroup && showSkillInGroup.length !== 0  ?
                                showSkillInGroup.map((skillingroup, index) => (

                                    skillingroup.skill && skillingroup.skill.name !== undefined ?
                                    <>
                                        <p>{skillingroup.skill.name}</p>
                                    </> : "Nenhuma habilidade"



                                ))
                                :
                                ''
                            }
                        </div>
                        {/* //////// FIM HABILIDADES ADICIONADAS  ///////*/}



                        <h4>Colaboradores neste grupo</h4>
                        <div className="detalhes">
                        {showCollaboratorInGroup && showCollaboratorInGroup.length !== 0 ? <>

                            <div className="lista-atrelados-col"  >
                                {
                                    showCollaboratorInGroup && showCollaboratorInGroup ?
                                    showCollaboratorInGroup.map((collaboratoringroup, index) => (
                                        <div key={index} className="infor-col">
                                            {
                                                collaboratoringroup.avatar !== undefined ?
                                                <div className="img-col">
                                                    <img src={collaboratoringroup.avatar} alt={collaboratoringroup.name} />
                                                </div>
                                                :
                                                <div className="colSemFoto">
                                                    <div className="semImgCol">
                                                        <img  src={IconUserNone} alt={collaboratoringroup.name} />
                                                    </div>
                                                </div>
                                            }
                                            <div className="nome-setor">
                                                <p className="nome">
                                                    <span>
                                                    {collaboratoringroup.name}
                                                    </span>
                                                </p>
                                                <div className="setor">
                                                    <p>{collaboratoringroup.occupation}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    'Nenhum colaborador'
                                }
                            </div>


                        </> : "Não tem colaborador"}

                        </div>
                        <button className="btnGray fechar" onClick={NaoVerGrupo}>Fechar</button>
                    </div>
                </Modal>

            <Modal show={visibleEditrupo} restoreFocus={false} className="popup-geral popup-criar-habilidade" onHide={NaoEditarGrupo}>
                <div>
                    <div className="titulo">
                        <h4>Editar grupo  <p className="btn-fechar" onClick={NaoEditarGrupo}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="form-nova-habilidade">
                        <input type="text" 
                        onChange={myChangeHandlerToEditGroup} 
                        name="nameGroup" 
                        onKeyPress={(event) => handleKeyPressEditarGrupo(event)} 
                        id="nameEditarGrupo"
                        defaultValue={showGroup && showGroup.nameGroup !== undefined ? showGroup.nameGroup : ''} 
                        placeholder="Nome do grupo" className={loadNameEditGrupo ? 'inputErro' : ''}/>
                        {loadNameEditGrupo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        <h4>Habilidades neste grupo</h4>

                        <input name="name" value={name} onChange={filterSkillForThisGroup}  placeholder="Habilidades para este grupo" className={loadNameEditGrupoHab ? 'inputErro busca' : 'busca'}/>
                        {loadNameEditGrupoHab ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        {/* //////// ESTADO VAZIO DE BUSCA DE HABILIDADE  ///////*/}
                        {
                            loadShowSkillEditInGroup === true ? "":
                            <div className="vazio-busca" style={
                                (showSkillEditInGroup &&
                                showSkillEditInGroup.length === 0)
                                &&
                                (skillFilterForThisGroup && skillFilterForThisGroup.length === 0) ?
                                {display:'block'}
                                :
                                {display:'none'}
                                }
                                >
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira Habilidades</span>
                            </div>
                        }
                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE HABILIDADE  ///////*/}


                        {/* //////// RESULTADO DE BUSCA HABILIDADE  ///////*/}
                        <div className="resultado-busca-habilidade" style={skillFilterForThisGroup && skillFilterForThisGroup.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                skillFilterForThisGroup && skillFilterForThisGroup.length !== 0 ?
                                skillFilterForThisGroup.map((skillfilterthisgroup, index) => (
                                    <p onClick={myChangeHandlerToAddSkillToEditGroup} id={skillfilterthisgroup._id} key={index}>{skillfilterthisgroup.name}</p>
                                )) : ''
                            }
                        </div>
                        {/* //////// FIM RESULTADO DE BUSCA HABILIDADE  ///////*/}


                        {/* //////// HABILIDADES ADICIONADAS  ///////*/}
                        <div className="habilidades-adicionada">
                            {loadShowSkillEditInGroup ?
                            "carregando"
                            :
                                showSkillEditInGroup && showSkillEditInGroup.length !== 0 ?
                                showSkillEditInGroup.map((skillInGroup, index)=> (
                                    <p key={index}>{skillInGroup.name} <button onClick={myChangeHandlerExcludeSkillAddedEditInTheGroup} value={skillInGroup._id} className="excluir"></button></p>
                                )):''
                            }

                        </div>
                        {/* //////// FIM HABILIDADES ADICIONADAS  ///////*/}

                        <h4>Colaboradores neste cargo</h4>

                        <input name='name' value={name} onChange={loadFilterCollaboratorForThisGroup} placeholder="Colaboradores para este grupo"  className="busca"/>


                        {/* //////// ESTADO VAZIO DE BUSCA DE CARGO  ///////*/}
                        <div className="vazio-busca" style={
                                (showCollaboratorSavedInGroup &&
                                showCollaboratorSavedInGroup.length === 0)
                                &&
                                (collaboratorFilterForThisGroup && collaboratorFilterForThisGroup.length === 0) ?
                                {display:'block'}
                                :
                                {display:'none'}
                            }>
                            <img src={IconArrowVazioVerde} alt="Seta Verda" />
                            <span>Insira Calaboradores</span>
                        </div>
                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE CARGO  ///////*/}

                        <div className="resultado-busca-atrelados-col" style={collaboratorFilterForThisGroup && collaboratorFilterForThisGroup.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                collaboratorFilterForThisGroup && collaboratorFilterForThisGroup.length !== 0 ?
                                collaboratorFilterForThisGroup.map((collaboratorFilterInGroup, index) => (
                                    <button onClick={myChangeHandlerToAddCollaboratorEditGroup} id={collaboratorFilterInGroup._id} key={index}>
                                        {
                                            collaboratorFilterInGroup && collaboratorFilterInGroup.avatar !== undefined ?
                                            <>
                                            <div className="img-col">
                                                <img id={collaboratorFilterInGroup._id} src={collaboratorFilterInGroup.avatar} alt={collaboratorFilterInGroup.name} />
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="colSemFoto" >
                                                <div className="semImgCol">
                                                    <img id={collaboratorFilterInGroup._id} src={IconUserNone} alt={collaboratorFilterInGroup.name} />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <span id={collaboratorFilterInGroup._id}>{collaboratorFilterInGroup.name}</span>
                                    </button>
                                )) : ''
                            }


                        </div>

                        {/* //////// COLABORADORES ADICIONADOS ADICIONADOS  ///////*/}
                        <div className="lista-atrelados-col"  style={showCollaboratorSavedInGroup && showCollaboratorSavedInGroup.length !== 0 ? {display: 'block'} : {display:'none'}}>
                                {
                                    showCollaboratorSavedInGroup && showCollaboratorSavedInGroup.length !== 0 ?
                                    showCollaboratorSavedInGroup.map((collaboratorInGroup, index) => (
                                        <div className="infor-col">
                                            {
                                                collaboratorInGroup && collaboratorInGroup.avatar !== undefined ?
                                                <>
                                                <div className="img-col">
                                                    <img src={collaboratorInGroup.avatar} alt={collaboratorInGroup.name} />
                                                </div>
                                                </>
                                                :
                                                <>
                                                <div className="colSemFoto" >
                                                    <div className="semImgCol">
                                                        <img  src={IconUserNone} alt={collaboratorInGroup.name} />
                                                    </div>
                                                </div>
                                                </>
                                            }
                                            <div className="nome-setor">
                                                <p className="nome">
                                                    {collaboratorInGroup.name}
                                                </p>
                                                <div className="setor">
                                                    <p>{collaboratorInGroup.occupation}</p>
                                                </div>
                                            </div>
                                            <button onClick={myChangeHandlerExcludeCollaboratorAddedEditInTheGroup} value={collaboratorInGroup._id} className="remover">Remover</button>
                                    </div>
                                    )):''
                                }


                        </div>
                        {/* //////// COLABORADORES ADICIONADOS ADICIONADOS  FIM ///////*/}


                    </div>
                    <div>
                        <div>

                        </div>
                    <button onClick={updateGroup} className={loadUpdateGroup ? "button-salve btnGreen load" : "button-salve btnGreen" }>Salvar</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default headerHabilidadesByGroup;

