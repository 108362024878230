import React,{useState} from "react";

import { Modal } from "react-bootstrap";
import Close from '../../../assets/images/close.svg';
import Carregando from '../../../assets/images/carregando.gif';
import IconArrowVazioVerde from '../../../assets/images/arrow-vazio-verde.svg';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

export default function ModalAddFolder({
    path,
    
    name,
    modalAddFolderHide,
    visibleModalAddFolder,
    setVisibleModalAddCandite,
    inforFolder,
    setInforFolder,

    loadsFoldersFiltroSalve,

    setCandidates,
    candidates,
    setBtnFilterAsFavoriteClicked,
    btnMarkAsFavoriteClicked,
    setBtnFilterMarkAsFavorite,
    btnFilterMarkAsFavorite,
    setNameSearch,
    nameSearch,
    setLoadNameFolder,
    loadNameFolder,
    setCandidateFilterForThisFolder,
    candidateFilterForThisFolder,
    setAllCandidateExclude,
    allCandidateExclude,
    loadsFavoriteFolders
}) {
  
     // => HANDLE FILTER FAVORITE

     async function filterMarkAsFavorite(e) {
        let nam = 'favorite';
        let val = e;
        if(val === true) {
            setBtnFilterAsFavoriteClicked(false)
            setInforFolder({ ...inforFolder, [nam]:false });
        }
        if(val === false || val === undefined) {
            setBtnFilterAsFavoriteClicked(true)
            setInforFolder({ ...inforFolder, [nam]:true });
        }
        setBtnFilterMarkAsFavorite(!btnFilterMarkAsFavorite);
    }
 
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaCandite, setIsActiveSelectBuscaCandite] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCandidateFilterForThisFolder, setLoadCandidateFilterForThisFolder ] = useState(true);

    // SEARCH CANDIDATES
    async function loadFilterCandidateForThisFolder(event) {
        const name = event.target.value;

        setNameSearch(name)

        if(name.length <= 1) {
            setCandidateFilterForThisFolder([]);
            setIsActiveSelectBuscaCandite(false)
        }
        else {
            setLoadCandidateFilterForThisFolder(true)
            setIsActiveSelectBuscaCandite(true)
            await api.get(`/candidate/?name=${name}&status=active`)
            .then(response => {

                setLoadCandidateFilterForThisFolder(false);
                setCandidateFilterForThisFolder(response.data[0]);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidateAddedThisFolder, setCandidateAddedThisFolder ] = useState([]);

   // => ADD CANDIDATE IN FOLDER

   async function myChangeHandlerToAddCollaborator(e) {

    let val = e

    let arrCol = candidateAddedThisFolder;
    let indexCol = arrCol.findIndex(objeto => objeto._id === val);
    arrCol.splice(indexCol, 1);
    setCandidateAddedThisFolder(arrCol);
    await api.get(`/candidate/${val}`)
    .then(response => {

        const itensCopyCollaborators = Array.from(candidates);
        const searchCandidateExists = itensCopyCollaborators.findIndex(objeto => objeto._id === val);

        if(searchCandidateExists === -1) {
            itensCopyCollaborators.push({idcollaborator: candidates.length, name: response.data.name, sector: response.data.sector.name, _id : response.data._id });
            setCandidates(itensCopyCollaborators);
            setIsActiveSelectBuscaCandite(false)
            setNameSearch('')
            setInforFolder({ ...inforFolder, candidate: itensCopyCollaborators });
        }
        else {
            itensCopyCollaborators.splice(searchCandidateExists, 0);
            setIsActiveSelectBuscaCandite(false)
            setNameSearch('')
            setInforFolder({ ...inforFolder, candidate: itensCopyCollaborators });
        }
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

  // => HANDLE FOLDER
  function handleAddFolder(e) {

    let nam = e.target.name;
    let val = e.target.value;

    if(val.length === 0) {
        setLoadNameFolder(true)
        setInforFolder({ ...inforFolder, [nam]:val });
    }
    else {
        setLoadNameFolder(false)
        setInforFolder({ ...inforFolder, [nam]:val });
    }


}

    // => EXCLUDE CANDIDATE IN FOLDER

    var id_candidate_excluded = null;
    async function myChangeHandlerExcludeCandidateAdded(event) {
        event.preventDefault();

        id_candidate_excluded = event.target.value;
        setAllCandidateExclude(id_candidate_excluded)

        if(candidates && candidates.length !== 0) {
            let arrayCandidate = candidates;
            let indexCandidates = arrayCandidate.findIndex(objeto => objeto._id === id_candidate_excluded);
            arrayCandidate.splice(indexCandidates, 1);
            setCandidates(arrayCandidate);

        } 
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadButtonSaveFolder, setLoadButtonSaveFolder] = useState(false);

    // => SAVE FOLDER
    async function saveAnEditFolder() {
        if(
            inforFolder?.name === undefined || inforFolder?.name === ''
        ) {
            setLoadNameFolder(true)
            return
        }
        else {

            if(inforFolder && inforFolder._id) {

                async function loadEditFolder() {

                    setLoadButtonSaveFolder(true)
                    await api.put(`/folder/${inforFolder._id}`, inforFolder)
                    .then(response => {

                        setLoadButtonSaveFolder(false);
                        setLoadNameFolder(false);
                        if(path === "/recrutamento/talentos/pastas") {
                            loadsFoldersFiltroSalve()
                        }else
                        if(path === "/recrutamento/talentos") {
                            loadsFavoriteFolders()
                        }
                        setVisibleModalAddCandite(false);
                        setInforFolder([]);
                        setCandidates([])
                        swal({ icon: "success", title: "Sucesso!", text: "Pasta de talentos editada com sucesso!" });

                    }).catch(error => {
                        setLoadButtonSaveFolder(false);
                        if(error?.response?.data?.message) {
                            swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                        }
                        else {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao editar pasta de talentos, tente novamente mais tarde." });
                        }
                    });


                }
                loadEditFolder()


            }
            else {
                setLoadNameFolder(false)

                async function loadsaveFolder() {

                    setLoadButtonSaveFolder(true)
                    await api.post('/folder/', inforFolder)
                    .then(response => {

                        setLoadButtonSaveFolder(false);

                        setLoadNameFolder(false);
                        if(path === "/recrutamento/talentos/pastas") {
                            loadsFoldersFiltroSalve()
                        }else
                        if(path === "/recrutamento/talentos") {
                            loadsFavoriteFolders()
                        }
                        

                        setVisibleModalAddCandite(false);
                        setInforFolder([]);
                        setCandidates([]);
                        swal({ icon: "success", title: "Sucesso!", text: "Pasta de talentos cadastrada com sucesso!" });

                    }).catch(error => {
                        setLoadButtonSaveFolder(false);
                        if(error?.response?.data?.message) {
                            swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                        }
                        else {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao cadastrar pasta de talentos, tente novamente mais tarde." });
                        }
                    });

                }
                loadsaveFolder();

            }

        }
    }
    return (
        <>
        <Modal show={visibleModalAddFolder} className="popup-geral popup-criar-pasta" onHide={modalAddFolderHide}>
            <div>
                <div className="titulo">
                    <h4>{inforFolder && inforFolder._id ? 'Editar pasta': 'Criar nova pasta'} <p className="btn-fechar" onClick={modalAddFolderHide}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className='form-pasta'>
                    <div className='box'>
                        <input
                            type="text"
                            name="name"
                            value={
                                inforFolder && inforFolder.edit ?
                                inforFolder.name :
                                inforFolder && inforFolder.name ?
                                inforFolder.name :
                                ''
                            }
                            onChange={handleAddFolder}
                            placeholder='Nome da pasta'
                            className={loadNameFolder ? 'inputErro' : ''}
                        />
                        { loadNameFolder ? <p className="textError">O campo é obrigatório.</p> : ""} 
                    </div> 

                    <div className='box input-check'>
                            <button
                                className={
                                    inforFolder && inforFolder.favorite === true ?
                                    "chek-button active"
                                    :
                                    inforFolder && inforFolder.favorite === false ?
                                    "chek-button"
                                    :
                                    btnFilterMarkAsFavorite ?
                                    "chek-button active"
                                    :
                                    "chek-button"
                                }
                                onClick={()=>filterMarkAsFavorite(inforFolder.favorite) }
                            >
                            <label ></label><span>Marcar pasta como favorita</span>
                            </button>
                    </div>
                    <div className='box busca-candidatos'>
                        <input
                            type="text"
                            name="name"
                            value={name ? name : nameSearch}
                            onChange={loadFilterCandidateForThisFolder}
                            placeholder='Buscar candidatos'
                            className='busca'
                        />
                        {
                            isActiveSelectBuscaCandite ?
                                loadCandidateFilterForThisFolder ?
                                    <>
                                    <div className='resultado-busca-candidato'>
                                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                    </div>
                                    </>
                                :
                                candidateFilterForThisFolder ?
                                    <>
                                        {
                                            candidateFilterForThisFolder.length === 0 ?
                                            ''
                                            :
                                            <div className='resultado-busca-candidato'>
                                            {

                                                candidateFilterForThisFolder.map((candidate, index)=>(
                                                    <>
                                                    <button
                                                        onClick={()=>myChangeHandlerToAddCollaborator(candidate._id)}
                                                        key={index}
                                                        onBlur={ () => (setIsActiveSelectBuscaCandite(false)) }
                                                    >
                                                        <div className='name-setor'>
                                                            <p>{candidate.name}</p>
                                                            <span>{candidate.sector.name}</span>
                                                        </div>
                                                    </button>
                                                    </>
                                                ))

                                            }
                                            </div>
                                        }

                                    </>
                                :
                                ''
                            :
                            candidates && candidates.length === 0 ?
                            <>
                            <div className="vazio-busca" >
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira candidatos</span>
                            </div>
                            </>
                            :
                            ''
                        }

                        {/* //////// CARGOS ADICIONADOS  ///////*/}
                        <div className="lista-atrelados-col">
                            {
                                candidates ?
                                candidates
                                .filter(filterCandidate =>
                                    filterCandidate._id !== allCandidateExclude
                                )
                                .map((candidateAdded, index)=>(
                                    <>
                                    <div key={index} className="infor-col">
                                        <div className="nome-setor">
                                            <p className="nome">
                                                {candidateAdded.name}
                                            </p>
                                            <div className="setor">
                                                <p>{candidateAdded?.sector}</p>
                                            </div>
                                        </div>
                                        <button 
                                        onClick={myChangeHandlerExcludeCandidateAdded} 
                                        value={candidateAdded._id} 
                                        className="remover">Remover</button>
                                    </div>
                                    </>
                                ))
                                :
                                ''
                            }

                        </div>
                        <div className='btns'>
                            <button 
                            onClick={saveAnEditFolder} 
                            disabled={loadButtonSaveFolder ? true : false}
                            className={loadButtonSaveFolder ? 'btn-salvar btnGreen load': 'btn-salvar btnGreen'}
                            >Salvar</button>
                            <button className="cancelar" 
                            onClick={modalAddFolderHide}
                            >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        </>
    )
}