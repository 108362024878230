import React from 'react';
// import { NavLink } from 'react-router-dom'

// import IconsInformation from '../IconsInformation';

import HeaderAdmin from '../HeaderAdmin';


export default function headerCommunication ({
    user,
}) {

    return (
        <>
<HeaderAdmin>
    <div>
        <h1>Comunicação</h1>
        <p>Mantenha uma comunicação direta com os colaboradores</p>
    </div>

</HeaderAdmin>
            {/* <header className="header-geral header-adm-communication">
                <div className="cabeçalho">
                    <h1>Comunicação</h1>
                    <p>Mantenha uma comunicação direta com os colaboradores</p>
                </div>
                <div className="infor">
                    <IconsInformation
                    user={user}
                    />
                </div>

            </header> */}
        </>
    );
}

