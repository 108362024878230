import moment from 'moment';
import Resizer from "react-image-file-resizer";

export const oneHundredCharacters = (string) => {
    var id = string;
    // var idString = id.toString();
    if(id.length >= 300) {
        var onehundredcharacters = id.substr(0, 300);
        return onehundredcharacters+' ...'
    }
    else {
        return id
    }
}

export const lastCaracteres = (string) => {
    var id = string;
    var lastFour = id.substr(id.length - 4);
    return lastFour
}

export const last24Caracteres = (string) => {
    var id = string;
    var last24 = id.substr(id.length - 24);
    return last24
}

//oneDecimalPlace
export const oneDecimalPlace = (string) => {
    var id = string;
    var idString = id.toString();
    var decimalPlace = idString.substr(0, 1);
    return decimalPlace
}

export const decimalPlace = (string) => {
    var id = string;
    var idString = id.toString();
    var decimalPlace = idString.substr(0, 2);
    return decimalPlace
}

export const  removerSpecialsUrl = (texto) => {
    // eliminando acentuação
        texto = texto.replace(" ", '-');
        texto = texto.replace(/[ÀÁÂÃÄÅ]/,"A");
        texto = texto.replace(/[àáâãäå]/,"a");
        texto = texto.replace(/[ÈÉÊË]/,"E");
        texto = texto.replace(/[úüùûū]/,"u");
        texto = texto.replace(/[ÚÜÙÛŪ]/,"U");
        texto = texto.replace(/[Ç]/,"C");
        texto = texto.replace(/[ç]/,"c");
        return texto.replace(/[^a-z0-9]/gi,'-');
}

export const  removerSpecials = (texto) => {
    // eliminando acentuação
    texto = texto.replace(/[ÀÁÂÃÄÅ]/,"A");
    texto = texto.replace(/[àáâãäå]/,"a");
    texto = texto.replace(/[ÈÉÊË]/,"E");
    texto = texto.replace(/[èéêë]/,"e");
    texto = texto.replace(/[úüùûū]/,"u");
    texto = texto.replace(/[ÚÜÙÛŪ]/,"U");
    texto = texto.replace(/[Ç]/,"C");
    texto = texto.replace(/[ç]/,"c");
    return texto.replace(/[^a-z0-9]/gi,'');
}

export const formatDateString = (date) => {
    var options = { day: 'numeric', year: 'numeric', month: 'long' };

    return new Date(new Date(date).setDate(new Date(date).getDate())).toLocaleDateString([],options);
}

export const dataFormatadaParaProximaCobranca = (date) => {
    var options = { day: 'numeric', year: 'numeric', month: 'long' };

    return new Date(new Date(date).setDate(new Date(date).getDate() +1)).toLocaleDateString([],options);
}

export const dataFormatadaParaDataLimite = (date) => {
    var options = { day: 'numeric', year: 'numeric', month: 'long' };

    return new Date(new Date(date).setDate(new Date(date).getDate())).toLocaleDateString([],options);
}

export const formatDate = (date) => new Date(new Date(date).setDate(new Date(date).getDate() +1)).toLocaleDateString();

const leftPad = (value, totalWidth, paddingChar) => {
    var length = totalWidth - value.toString().length + 1;
    return Array(length).join(paddingChar || '0') + value;
}

export const startDate = () => String(new Date().getFullYear() + "-" + leftPad(parseInt(new Date().getMonth() + 1), 2) + "-01");

export const endDate = () => {
    const d = new Date();
    const lastDay = new Date(d.getFullYear(), leftPad(parseInt(d.getMonth() + 1), 2), 0).getDate();
    return String(d.getFullYear() + "-" + leftPad(parseInt(d.getMonth() + 1), 2) + "-" + lastDay);
}

export const FormataStringData = (data) => {
    var dia  = data.split("/")[0];
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];

    return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const FormataStringDataToAdmission = (data) => {
    var dia  = data.split("/")[0];
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];

    if(mes === '01') {
        var mesString = 'Janeiro';
    }
    else
    if(mes === '02') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Fevereiro';
    }
    else
    if(mes === '03') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Março';
    }
    else
    if(mes === '04') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Abril';
    }
    else
    if(mes === '05') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Maio';
    }
    else
    if(mes === '06') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Junho';
    }
    else
    if(mes === '07') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Julho';
    }
    else
    if(mes === '08') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Agosto';
    }
    else
    if(mes === '09') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Setembro';
    }
    else
    if(mes === '10') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Outubro';
    }
    else
    if(mes === '11') {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Novembro';
    }
    else {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Dezembro';
    }


    return ("0"+dia).slice(-2) + ' de ' + mesString + ' ' + ano;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const FormataCurrentMonthToCalendar = (data) => {
    var mes  = data

    if(mes === 0) {
        var mesString = 'Janeiro';
    }
    else
    if(mes === 1) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Fevereiro';
    }
    else
    if(mes === 2) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Março';
    }
    else
    if(mes === 3) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Abril';
    }
    else
    if(mes === 4) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Maio';
    }
    else
    if(mes === 5) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Junho';
    }
    else
    if(mes === 6) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Julho';
    }
    else
    if(mes === 7) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Agosto';
    }
    else
    if(mes === 8) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Setembro';
    }
    else
    if(mes === 9) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Outubro';
    }
    else
    if(mes === 10) {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Novembro';
    }
    else {
        // eslint-disable-next-line no-redeclare
        var mesString = 'Dezembro';
    }

    return mesString;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const FormataStringData_revert = (data) => {
    var ano  = data.split("-")[0];
    var mes  = data.split("-")[1];
    var dia  = data.split("-")[2];
    return  (dia).slice(+0) + '/' + ("0"+mes).slice(-2) + '/' + ano;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const FormataStringData_revert_to_year = (data) => {
    var ano  = data.split("-")[0];
    return  ano;
}

export const dataFormatadaParaBarra = (data) => {


    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "/" + mes + "/" + ano;

    return dataFormatada


}

export const dataFormatadaParaPonto = (data) => {
    // let datebarra = new Date(data);
    // function pad(s) {
    //     return (s < 10) ? '0' + s : s;
    // }
    // let dataFormatada = ((pad(datebarra.getDate()) )) + "." + ((pad(datebarra.getMonth() + 1))) + "." + datebarra.getFullYear();
    // return dataFormatada;
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "." + mes + "." + ano;
    return dataFormatada
}

export const dataFormatadaParaTraco = (data) => {
    var dia  = data.split("/")[0];
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];
    return  ano + '-' + ("0"+mes).slice(-2) + '-' + (dia).slice(+0);
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const dataFormatadaParaTracoCalendar = (data) => {
    var ano = data.split("-")[0];
    var mes = data.split("-")[1];
    var dia = data.split("-")[2];
    return  (dia).slice(+0) + '/' + ("0"+mes).slice(-2) + '/' + ano;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const FormataStringDataParaPonto = (data) => {

    var dia  = data.split("/")[0];
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];

    return ("0"+dia).slice(-2) + '.' + ("0"+mes).slice(-2) + '.' + ano;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
}

export const diaMesFormatadaParaPonto = (data) => {
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "." + mes + "." + ano;
    return dataFormatada
}

export const diaMesFormatadaParaPontoCalendar = (data) => {
    let datebarra = new Date(data);
    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }
    let dataFormatada = ((pad(datebarra.getDate()) )) + "." + ((pad(datebarra.getMonth() + 1)));
    return dataFormatada;
    // let dateSplit = data.split('T')[0]

    // let dia = dateSplit.split('-')[2]
    // let mes = dateSplit.split('-')[1]
    // let ano = dateSplit.split('-')[0]

    // let dataFormatada = dia + "." + mes + "." + ano;
    // return dataFormatada
}

export const dateFormatedToSignature = (data) => {
    let datebarra = new Date(data);
    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }
    let dataFormatada = ((pad(datebarra.getDate()) )) + "." + ((pad(datebarra.getMonth() + 1))) + "." + ((pad(datebarra.getFullYear() + 1)));
    return dataFormatada;
}

export const dataFormatadaParaDia = (data) => {
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    return dia
}

export const somartempos = (tempo1, tempo2, tempo3, tempo4, tempo5, tempo6, tempo7) => {

    var array1 = tempo1.split(':');

    var tempo_seg1 = (parseInt(array1[0]) * 3600) + (parseInt(array1[1]) * 60);

    var array2 = tempo2.split(':');

    var tempo_seg2 = (parseInt(array2[0]) * 3600) + (parseInt(array2[1]) * 60);

    var array3 = tempo3.split(':');

    var tempo_seg3 = (parseInt(array3[0]) * 3600) + (parseInt(array3[1]) * 60);

    var array4 = tempo4.split(':');

    var tempo_seg4 = (parseInt(array4[0]) * 3600) + (parseInt(array4[1]) * 60);

    var array5 = tempo5.split(':');

    var tempo_seg5 = (parseInt(array5[0]) * 3600) + (parseInt(array5[1]) * 60);

    var array6 = tempo6.split(':');

    var tempo_seg6 = (parseInt(array6[0]) * 3600) + (parseInt(array6[1]) * 60);

    var array7 = tempo7.split(':');

    var tempo_seg7 = (parseInt(array7[0]) * 3600) + (parseInt(array7[1]) * 60);

    var tempofinal = parseInt(tempo_seg1) + parseInt(tempo_seg2) + parseInt(tempo_seg3) + parseInt(tempo_seg4) + parseInt(tempo_seg5) + parseInt(tempo_seg6) + parseInt(tempo_seg7);

    var hours = Math.floor(tempofinal / (60 * 60));

    var divisorMinutos = tempofinal % (60 * 60);

    var minutes = Math.floor(divisorMinutos / 60);

    //var divisorSeconds = divisorMinutos % 60;

    //var seconds = Math.ceil(divisorSeconds);

    var contador = "";

    if (hours < 10) { contador = "0" + hours + ":"; } else { contador = hours + ":"; }

    if (minutes < 10) { contador += "0" + minutes; } else { contador += minutes; }

    //if (seconds < 10) { contador += "0" + seconds; } else { contador += seconds; }

    return contador;

}

export const dateFormatedToHistory = (data) => {
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "." + mes + "." + ano;
    return dataFormatada
}

export const dateFormatedHours = (data) => {
    let datehistory = new Date(data);
    let dataFormatada = " às " + datehistory.getHours() + ":" + datehistory.getMinutes();
    return dataFormatada;
}

export const dateFormatedToDetailVacation= (data) => {
    var dia  = data.split("/")[0];
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];

    return  (dia).slice(+0) + '.' + ("0"+mes).slice(-2) + '.' + ano;
}

export const FormataTiraBarrar = (data) => {
    var dia  = data.split("/")[0];
    var mes  = data.split("/")[1];
    var ano  = data.split("/")[2];

    return ano + ("0"+mes).slice(-2) + ("0"+dia).slice(-2);
}

export const dateFormatedToShutDown= (data) => {
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "/" + mes + "/" + ano;
    return dataFormatada
}

export const dateFormatedPoint= (data) => {
    // let datehistory = new Date(data);
    // function pad(s) {
    //     return (s < 10) ? '0' + s : s;
    // }
    // let dataFormatada = ((pad(datehistory.getDate()) )) + "." + ((pad(datehistory.getMonth() + 1))) + "." + datehistory.getFullYear();
    // return dataFormatada;
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "." + mes + "." + ano;
    return dataFormatada
}

export const dateFormatedToStripe= (data) => {
    // let datehistory = new Date(data);
    // function pad(s) {
    //     return (s < 10) ? '0' + s : s;
    // }
    // let dataFormatada = ((pad(datehistory.getDate()) )) + "/" + ((pad(datehistory.getMonth() + 1))) + "/" + datehistory.getFullYear();
    // return dataFormatada;
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "/" + mes + "/" + ano;
    return dataFormatada
}

export const firstChargeNotice = (data) => {

    let tenDaysAfter_ = moment(data).add(10, 'days').format('YYYY-MM-DD');;

    let tuesdayDate   = new Date(tenDaysAfter_)

    var options = { day: 'numeric', year: 'numeric', month: 'long' };

    let tuesdayDate_b = new Date(new Date(tuesdayDate).setDate(new Date(tuesdayDate).getDate() +1)).toLocaleDateString([],options);


    return tuesdayDate_b

}

export const momentDuration = (data) => {


    var a = moment(new Date());//now
    var b = moment(data);//now
    // var b = moment(new Date());

    const minutes = a.diff(b, 'minutes');
    const hours = a.diff(b, 'hours');
    const days = a.diff(b, 'days');
    const year = a.diff(b, 'year');

    if(minutes <= 59) {
        return  minutes +' minutos'
    }
    else
    if(hours >= 1 && hours <=23) {
        return hours +' horas'
    }
    else
    if(days >= 1 && days <= 355) {
        return days +' dias'
    }
    else
    if(year >= 1) {
        return year +' anos'
    }

}

export const timeItWasRegistered = (data) => {
    // var a = new Date(data);//now
    // var b = moment(data);
    const hor = new Date(data).getHours()
    const minu = new Date(data).getMinutes()
    const pad = (minu < 10) ? '0' : '';
    const horario = hor + ":"+ pad + minu
    // const minutes = a.diff(b, 'minutes');
    // const hours = a.diff(b, 'hours');
    // // const days = a.diff(b, 'days');
    // // const year = a.diff(b, 'year');
    // const horas = abb

    return  horario
}

export const momentDurationToCommunication = (data) => {


    var a = moment(new Date());//now
    var b = moment(data);//now
    // var b = moment(new Date());

    const minutes = a.diff(b, 'minutes');
    const hours = a.diff(b, 'hours');
    const days = a.diff(b, 'days');
    const year = a.diff(b, 'year');

    if(minutes === 0) {
        return  'segundos atrás'
    }
    else
    if(minutes === 1) {
        return  minutes +' minuto'
    }
    else
    if(minutes > 1 && minutes <= 59) {
        return  minutes +' minutos'
    }
    else
    if(hours === 1) {
        return hours +' hora'
    }
    else
    if(hours > 1 && hours <=23) {
        return hours +' horas'
    }
    else
    if(days === 1) {
        return days +' dia'
    }
    else
    if(days > 1 && days <= 355) {
        return days +' dias'
    }
    else
    if(year === 1) {
        return year +' ano'
    }
    else
    if(year >= 1) {
        return year +' anos'
    }

}


export const momentDurationToMessageCommunication = (data) => {


    var a = moment(new Date());//now
    var b = moment(data);//now
    // var b = moment(new Date());

    const minutes = a.diff(b, 'minutes');
    const hours = a.diff(b, 'hours');
    const days = a.diff(b, 'days');
    const year = a.diff(b, 'year');

    if(minutes === 0) {
        return  'Agora mesmo'
    }
    else
    if(minutes === 1) {

        return  minutes +' minuto atrás'
    }
    else
    if(minutes > 1 && minutes <= 59) {

        let datehistory = new Date(data);
        let dataFormatada = " às " + datehistory.getHours() + ":" + datehistory.getMinutes();
        return dataFormatada;
        // return  minutes +' minutos'
    }
    else
    if(hours === 1) {
        return hours +' hora'
    }
    else
    if(hours > 1 && hours <=23) {
        // return hours +' horas'
        let datehistory = new Date(data);
        let dataFormatada = " às " + datehistory.getHours() + ":" + datehistory.getMinutes();
        return dataFormatada;
    }
    else
    if(days === 1) {
        return days +' dia atrás'
    }
    else
    if(days > 1 && days <= 355) {
        return days +' dias atrás'
    }
    else
    if(year === 1) {
        return year +' ano atrás'
    }
    else
    if(year >= 1) {
        return year +' anos atrás'
    }

}

export const momentDurationToFeed = (data) => {


    var a = moment(new Date());//now
    var b = moment(data);//now
    // var b = moment(new Date());

    const minutes = a.diff(b, 'minutes');
    const hours = a.diff(b, 'hours');
    const days = a.diff(b, 'days');
    const year = a.diff(b, 'year');

    if(minutes === 1) {
        return  minutes +' minuto atrás'
    }
    else
    if(minutes > 1 && minutes <= 59) {
        return  minutes +' minutos atrás'
    }
    else
    if(hours === 1) {
        return hours +' hora atrás'
    }
    else
    if(hours > 1 && hours <=23) {
        return hours +' horas atrás'
    }
    else
    if(days === 1) {
        return days +' dia atrás'
    }
    else
    if(days > 1 && days <= 355) {
        return days +' dias atrás'
    }
    else
    if(year === 1) {
        return year +' ano atrás'
    }
    else
    if(year >= 1) {
        return year +' anos atrás'
    }

}

export const momentDurationTotalDay = (initial, final) => {

    var diaInicial  = initial.split("/")[0];
    var mesInicial  = initial.split("/")[1];
    var anoInicial  = initial.split("/")[2];
    var initialDate =anoInicial + '-' + mesInicial + '-' + diaInicial;

    var diaFinal  = final.split("/")[0];
    var mesFinal  = final.split("/")[1];
    var anoFinal  = final.split("/")[2];
    var finalDate =anoFinal + '-' + mesFinal + '-' + diaFinal;

    var a = moment(new Date(initialDate));
    var b = moment(new Date(finalDate));

    const days = b.diff(a, 'days');

    const dia = days + 1;

    if(dia > 1) {
        return dia +' dias';
    }
    else {
        return dia +' dia';
    }

}

export const momentDurationTotalDayTrial = (initial, final) => {

    var a = moment(initial);
    var b = moment(final);

    const days = b.diff(a, 'days');

    const dia = days +1;

    if(dia > 1) {
        return ' '+dia +' dias';
    }
    else {
        return ' '+dia +' dia';
    }

}

export const amountPlan= (amount) => {

    if(amount.length > 0) {

        if(amount.length === 5) {

            let first = amount.substr(0, 3);
            let second = amount.substr(3, 5);

            return first+','+second;

        }


    }

}

export const StringToDate = (data) => {

    return new Date(data.split('/')[2]+'-'+data.split('/')[1]+'-'+data.split('/')[0])

}


export const diaMesFormatadaParaTraco = (data) => {
    // let datebarra = new Date(data);
    // function pad(s) {
    //     return (s < 10) ? '0' + s : s;
    // }
    // let dataFormatada = ((pad(datebarra.getDate()) )) + "." + ((pad(datebarra.getMonth() + 1)));
    // return dataFormatada;
    let dateSplit = data.split('T')[0]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    // let ano = dateSplit.split('-')[0]

    let dataFormatada = dia + "/" + mes;
    return dataFormatada
}

export const getDayofTheWeek = (date) => {

    let dayOfTheWeek =  moment(date).format('e');

    const x = parseInt(dayOfTheWeek);
    switch (true) {
        case (x === 0):
            return ("DOM");
        case (x === 1):
            return("SEG");
        case (x === 2):
            return ("TER");
        case (x === 3):
            return ("QUA");
        case (x === 4):
            return ("QUI");
        case (x === 5):
            return ("SEX");
        case (x === 6):
            return ("SAB");
        default:
            return ("-");
    }

}

export const dateFormatedToHour = (data) => {

    let dateWithoutUTC_ = new Date(data);
    let dateWithoutUTC  = dateWithoutUTC_.getTime();
    let dateZoneOffset  = (dateWithoutUTC_.getTimezoneOffset() * 60000);
    let date            = new Date(dateWithoutUTC + dateZoneOffset);

    let horas           = date.getHours() < 10 ? '0'+date.getHours() : date.getHours();
    let minutos         = date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes();
    let dataFormatada   = horas+ ":" + minutos;

    return dataFormatada;

}

export const totalWorkHoursFormatedToHour = (hour) => {

    let totalWorkHours          = hour.split(':');
    let totalWorkHoursFormated  = totalWorkHours[0];
    let totalWorkMinuesFormated = totalWorkHours[1].split(':')[0];
    let totalHours              = totalWorkHoursFormated+':'+totalWorkMinuesFormated;

    return totalHours;

}

export const firstDateMonth_trasformDateToDateFormatUTC = (date) => {

    const firstDate_      = String(date.getFullYear() + "-" + leftPad(parseInt(date.getMonth() + 1), 2) + "-01")
    const firstDate       = new Date(firstDate_)
    const year            = firstDate.getUTCFullYear()
    const month_          = firstDate.getUTCMonth() +1
    const month           = month_ < 10 ? '0'+month_ : month_
    const day_            = firstDate.getUTCDate()
    const day             = day_ < 10 ? '0'+day_ : day_
    const dateFormatedUTC = year+'-'+month+'-'+day+'T00:00:00.000Z'

    return dateFormatedUTC;

}

export const endDateMonth_trasformDateToDateFormatUTC = (date) => {

    const d = new Date(date);
    const lastDay_ = new Date(d.getFullYear(), leftPad(parseInt(d.getMonth() + 1), 2), 0).getDate();
    const lastDay = String(d.getFullYear() + "-" + leftPad(parseInt(d.getMonth() + 1), 2) + "-" + lastDay_);

    const year            = lastDay.split('-')[0]
    const month           = lastDay.split('-')[1]
    const day             = lastDay.split('-')[2]
    const dateFormatedUTC = year+'-'+month+'-'+day+'T23:59:59.000Z'

    return dateFormatedUTC;

}

export const showDateMonthYearToString = (date) => {

    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }

    let currentDate =  date

    const dateNow = currentDate.getDay()
    let dayString = ''

    const x = parseInt(dateNow);
    switch (true) {
        case (x === 0):
            dayString = ("Domingo, ");
            break
        case (x === 1):
            dayString = ("Segunda, ");
            break
        case (x === 2):
            dayString = ("Terça, ");
            break
        case (x === 3):
            dayString = ("Quarta, ");
            break
        case (x === 4):
            dayString = ("Quinta, ");
            break
        case (x === 5):
            dayString = ("Sexta, ");
            break
        case (x === 6):
            dayString = ("Sábado, ");
            break
        default:
            dayString = ("-");
            break
    }

    let day = ((pad(currentDate.getDate()) ));

    let month = ((pad(currentDate.getMonth() + 1) ));

    const y = parseInt(month);
    let monthString = ''

    switch (true) {
        case (y === 1):
            monthString = ("Janeiro");
            break
        case (y === 2):
            monthString = ("Fevereiro");
            break
        case (y === 3):
            monthString = ("Março");
            break
        case (y === 4):
            monthString = ("Abril");
            break
        case (y === 5):
            monthString = ("Maio");
            break
        case (y === 6):
            monthString = ("Junho");
            break
        case (y === 7):
            monthString = ("Julho");
            break
        case (y === 8):
            monthString = ("Agosto");
            break
        case (y === 9):
            monthString = ("Setembro");
            break
        case (y === 10):
            monthString = ("Outubro");
            break
        case (y === 11):
            monthString = ("Novembro");
            break
        case (y === 12):
            monthString = ("Dezembro");
            break
        default:
            monthString = ("-");
            break
    }

    const year = currentDate.getFullYear()

    return dayString+ day+ ' de '+monthString+ ' de '+year

}

export const showDateToShowLastActualization = (date) => {

    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }

    let currentDate =  new Date(date)

    let day = ((pad(currentDate.getDate()) ));

    let month = ((pad(currentDate.getMonth() + 1) ));

    const y = parseInt(month);
    let monthString = ''

    switch (true) {
        case (y === 1):
            monthString = ("Janeiro");
            break
        case (y === 2):
            monthString = ("Fevereiro");
            break
        case (y === 3):
            monthString = ("Março");
            break
        case (y === 4):
            monthString = ("Abril");
            break
        case (y === 5):
            monthString = ("Maio");
            break
        case (y === 6):
            monthString = ("Junho");
            break
        case (y === 7):
            monthString = ("Julho");
            break
        case (y === 8):
            monthString = ("Agosto");
            break
        case (y === 9):
            monthString = ("Setembro");
            break
        case (y === 10):
            monthString = ("Outubro");
            break
        case (y === 11):
            monthString = ("Novembro");
            break
        case (y === 12):
            monthString = ("Dezembro");
            break
        default:
            monthString = ("-");
            break
    }

    const year = currentDate.getFullYear()

    const hours = currentDate.getHours()

    const minutes = currentDate.getMinutes()

    return day+ ' de '+monthString+ ' de '+year+ " às " + hours + ":" + minutes;


}

export const trasformDateToDateFormatUTC = (hour) => {

    let currentDate = new Date()

    const date1_      = String(currentDate.getFullYear() + "-" + (currentDate.getMonth()+1) + "-" + currentDate.getDate())
    const date1       = new Date(date1_)

    const year            = date1.getUTCFullYear()
    const month_          = date1.getUTCMonth() +1
    const month           = month_ < 10 ? '0'+month_ : month_
    const day_            = date1.getUTCDate()
    const day             = day_ < 10 ? '0'+day_ : day_
    const dateFormatedUTC = year+'-'+month+'-'+day+'T'+hour+':00.000Z'

    return dateFormatedUTC;

}


export const transformDateUTCToHours = (dateUTC) => {

    if(dateUTC !== undefined) {
        let interval1Splited = dateUTC.split(':')
        let interal1Hour     = interval1Splited[0].split('T')[1]
        let interval1Min     = interval1Splited[1].split('.')[0]

        return interal1Hour+':'+interval1Min
    }
    else {
        return '-'
    }



}

export const transformDateUTCToHoursAndAftertransformDateUTCToHours = (date) => {

    if(date !== undefined) {

        let interval1Splited = date.split(':')
        let interal1Hour     = interval1Splited[0].split('T')[1]
        let interval1Min     = interval1Splited[1].split('.')[0]

        let hourTransformed =  interal1Hour+':'+interval1Min

        let currentDate = new Date()

        const date1_      = String(currentDate.getFullYear() + "-" + (currentDate.getMonth()+1) + "-" + currentDate.getDate())
        const date1       = new Date(date1_)

        const year            = date1.getUTCFullYear()
        const month_          = date1.getUTCMonth() +1
        const month           = month_ < 10 ? '0'+month_ : month_
        const day_            = date1.getUTCDate()
        const day             = day_ < 10 ? '0'+day_ : day_
        const dateFormatedUTC = year+'-'+month+'-'+day+'T'+hourTransformed+':00.000Z'

        return dateFormatedUTC;
    }
    else {
        return '-'
    }



}

export const sumClockPunch = (startTime_, endTime_) => {

    if(new Date(startTime_) > new Date(endTime_)) {

        let startTime              = new Date(startTime_);
        let endTimeToMoment        = new Date(endTime_);
        let endTime                = moment(endTimeToMoment).add(1, 'days');
        const timeDuration         = moment(endTime).diff(moment(startTime,"days"));
        const durationtimeDuration = moment.duration(timeDuration);
        let hour                   = Number(durationtimeDuration._data.hours);
        let minutes                = Number(durationtimeDuration._data.minutes);

        let json1                  = { hour : hour, minutes : minutes };

        function get_add_two_times(json1) {
            let hr        = parseInt(json1.hour);
            let mn        = parseInt(json1.minutes);
            let final_hr  = hr + Math.floor(mn/60);
            let final_mn  = mn%60;

            final_hr      = (Number(final_hr) < 10) ? '0' + final_hr : final_hr;
            final_mn      = (Number(final_mn) < 10) ? '0' + final_mn : final_mn;

            return final_hr + ':' + final_mn;
        }
        const result = get_add_two_times(json1);

        return result

    }
    else {
        let startTime              = new Date(startTime_)
        let endTime                = new Date(endTime_)
        const timeDuration         = moment(endTime).diff(moment(startTime,"days"));
        const durationtimeDuration = moment.duration(timeDuration);
        let hour                   = Number(durationtimeDuration._data.hours);
        let minutes                = Number(durationtimeDuration._data.minutes);

        let json1                  = { hour : hour, minutes : minutes };

        function get_add_two_times(json1) {
            let hr            = parseInt(json1.hour);
            let mn            = parseInt(json1.minutes);
            let final_hr      = hr + Math.floor(mn/60);
            let final_mn      = mn%60;

            final_hr          = (Number(final_hr) < 10) ? '0' + final_hr : final_hr;
            final_mn          = (Number(final_mn) < 10) ? '0' + final_mn : final_mn;

            return final_hr + ':' + final_mn;
        }
        const result = get_add_two_times(json1);

        return result
    }

}

export const sumTotalHours = (total_1, total_2, total_3, total_4, total_5, total_6, total_7) => {

    // console.log('total_1')
    // console.log(total_1)
    // console.log('total_2')
    // console.log(total_2)
    // console.log('total_3')
    // console.log(total_3)
    // console.log('total_4')
    // console.log(total_4)
    // console.log('total_5')
    // console.log(total_5)
    // console.log('total_6')
    // console.log(total_6)
    // console.log('total_7')
    // console.log(total_7)

    let total1 = '';
    let total2 = '';
    let total3 = '';
    let total4 = '';
    let total5 = '';
    let total6 = '';
    // let total7 = '';

    if(total_1 !== undefined) {
        total1 = moment(trasformDateToDateFormatUTC(total_1))

    }

    if(total_2 !== undefined) {
        total2 = moment(trasformDateToDateFormatUTC(total_2))
    }
    else {
        total2 = total1
    }

    if(total_3 !== undefined) {
        total3 = moment(trasformDateToDateFormatUTC(total_3))
    }
    else {
        total3 = total2
    }

    if(total_4 !== undefined) {
        total4 = moment(trasformDateToDateFormatUTC(total_4))
    }
    else {
        total4 = total3
    }

    if(total_5 !== undefined) {
        total5 = moment(trasformDateToDateFormatUTC(total_5))
    }
    else {
        total5 = total4
    }

    if(total_6 !== undefined) {
        total6 = moment(trasformDateToDateFormatUTC(total_6))
    }
    else {
        total6 = total5
    }


    let timeDuration         = moment(total2._d).add(moment(total1._d,"hours"));

    let durationtimeDuration = moment.duration(timeDuration);

    let hour                       = durationtimeDuration._data.hours;
    let minutes                    = durationtimeDuration._data.minutes;

    let json1 = { hour : hour, minutes : minutes };

    let timeDuration2         = moment(total4._d).add(moment(total3._d,"hours"));
    let durationtimeDuration2 = moment.duration(timeDuration2);
    let hour2                       = durationtimeDuration2._data.hours;
    let minutes2                    = durationtimeDuration2._data.minutes;

    let json2 = { hour : hour2, minutes : minutes2 };

    let timeDuration3         = moment(total6._d).add(moment(total5._d,"hours"));
    let durationtimeDuration3 = moment.duration(timeDuration3);
    let hour3                       = durationtimeDuration3._data.hours;
    let minutes3                    = durationtimeDuration3._data.minutes;

    let json3 = { hour : hour3, minutes : minutes3 };

    function get_add_times(json1, json2, json3) {
        let hr            = parseInt(json1.hour) + parseInt(json2.hour) + parseInt(json3.hour);
        let mn            = parseInt(json1.minutes) + parseInt(json2.minutes) + parseInt(json3.minutes);
        let final_hr      = hr + Math.floor(mn/60);
        let final_mn      = mn%60;
        final_mn          = (final_mn < 10) ? '0' + final_mn : final_mn;

        final_hr          = (Number(final_hr) < 10 && Number(final_hr) > 0) ? '0' + final_hr : final_hr;
        final_mn          = (Number(final_mn) < 10 && Number(final_mn) > 0) ? '0' + final_mn : final_mn;

        return final_hr + ':' + final_mn;
    }


    if(
        json1?.hour === 0 && json1?.minutes === 0
        &&
        json2?.hour === 0 && json2?.minutes === 0
        &&
        json3?.hour === 0 && json3?.minutes === 0
    ) {

        let result   = total_1
        return result
    }
    else
    if(
        json1?.hour === 0 && json1?.minutes === 0
        &&
        json2?.hour === 0 && json2?.minutes === 0
        &&
        (json3?.hour !== 0 || json3?.minutes !== 0)
    ) {

        if(total_7 !== '' && total_7 !== undefined) {


            let hour_total_1    = total_1.split(':')[0]
            let minutes_total_1 = total_1.split(':')[1]
            json1               = { hour : Number(hour_total_1), minutes : Number(minutes_total_1) };

            let hour_total_7    = total_7.split(':')[0]
            let minutes_total_7 = total_7.split(':')[1]
            json2               = { hour : Number(hour_total_7), minutes : Number(minutes_total_7) };


            let result          = get_add_times(json1, json2, json3);
            return result



        }
        else {

            let hour_total_1    = total_1.split(':')[0]
            let minutes_total_1 = total_1.split(':')[1]


            json1 = { hour : Number(hour_total_1), minutes : Number(minutes_total_1) };
            // let jsonVazio = { hour : 0, minutes : 0 };

            let result    = get_add_times(json1, json2, json3);
            return result

        }




    }
    else
    if(
        (json1?.hour !== 0 || json1?.minutes !== 0)
        &&
        json2?.hour === 0 && json2?.minutes === 0
        &&
        json3?.hour === 0 && json3?.minutes === 0
    ) {
        // console.log('total_3')
        // console.log(total_3)

        if(total_3 !== undefined && total_3.length === 5) {

            let hour_total_3    = total_3.split(':')[0]
            let minutes_total_3 = total_3.split(':')[1]

            console.log()

            let jsonTotal3 = { hour : hour_total_3, minutes : minutes_total_3 };
            let jsonVazio = { hour : 0, minutes : 0 };

            let result    = get_add_times(json1, jsonTotal3, jsonVazio);
            return result

            // console.log('tem')

        }



        let result    = get_add_times(json1, json2, json3);
        return result


    }
    else
    if(
        (json1?.hour !== 0 || json1?.minutes !== 0)
        &&
        (json2?.hour !== 0 || json2?.minutes !== 0)
        &&
        json3?.hour === 0 && json3?.minutes === 0
    ) {
        // console.log('total_5')
        // console.log(total_5)

        if(total_5 !== undefined && total_5.length === 5) {

            let hour_total_5    = total_5.split(':')[0]
            let minutes_total_5 = total_5.split(':')[1]

            console.log()

            let jsonTotal5 = { hour : hour_total_5, minutes : minutes_total_5 };
            // let jsonVazio = { hour : 0, minutes : 0 };

            let result    = get_add_times(json1, json2, jsonTotal5);
            return result

            // console.log('tem')

        }

        let result    = get_add_times(json1, json2, json3);
        return result


    }
    else {

        // console.log('ultimo loop')

        if(total_7 !== undefined && total_7 !== '') {

            if(total_7 !== undefined && total_7.length === 5) {

                let hour_total_7    = total_7.split(':')[0]
                let minutes_total_7 = total_7.split(':')[1]

                // console.log('total_7')
                // console.log(total_7)

                let json7 = { hour : Number(json3.hour)+Number(hour_total_7), minutes : Number(json3.minutes)+Number(minutes_total_7) };
                // let jsonVazio = { hour : 0, minutes : 0 };

                let result    = get_add_times(json1, json2, json7);
                return result

                // console.log('tem')

            }

        }
        else {

            // console.log('aquiiii')

            let result    = get_add_times(json1, json2, json3);

             return result

        }



    }

}


export const multiplyTotalWeekHours = (totalWorkHoursDay1, totalWorkHoursDay6, totalWorkHoursDay0) => {

    if(
        (totalWorkHoursDay1 !== undefined && totalWorkHoursDay1 !== '')
        ||
        (totalWorkHoursDay6 !== undefined && totalWorkHoursDay6 !== '')
        ||
        (totalWorkHoursDay0 !== undefined && totalWorkHoursDay0 !== '')
    ) {

        function sumHoursSegSex(totalHours) {

            let hourSplited = totalHours.split(':')[0]
            let hourMultiply = (Number(hourSplited)*5)
            let minutesSplited = totalHours.split(':')[1]
            let minutesMultiply = (Number(minutesSplited)*5)

            let hr            = parseInt(hourMultiply);
            let mn            = parseInt(minutesMultiply);
            let final_hr      = hr + Math.floor(mn/60);
            let final_mn      = mn%60;

            final_hr          = (Number(final_hr) < 10) ? '0' + final_hr : final_hr;
            final_mn          = (Number(final_mn) < 10) ? '0' + final_mn : final_mn;

            return final_hr + ':' + final_mn;


        }

        let totalSegSex = sumHoursSegSex(totalWorkHoursDay1)


        function sumHours(hora1, hora2) {

            let hora1Splited    = hora1.split(':')[0]
            let minutes1Splited = hora1.split(':')[1]

            let hora2Splited    = hora2.split(':')[0]
            let minutes2Splited = hora2.split(':')[1]


            let hr             = parseInt(hora1Splited)+parseInt(hora2Splited);
            let mn             = parseInt(minutes1Splited)+parseInt(minutes2Splited);
            let final_hr       = hr + Math.floor(mn/60);
            let final_mn       = mn%60;

            final_hr           = (Number(final_hr) < 10) ? '0' + final_hr : final_hr;
            final_mn           = (Number(final_mn) < 10) ? '0' + final_mn : final_mn;

            return final_hr + ':' + final_mn;


        }

        let totalHorasSemanais = totalSegSex;



        if(totalWorkHoursDay6 !== '' && totalWorkHoursDay0 === '') {

            totalHorasSemanais = sumHours(totalSegSex, totalWorkHoursDay6)

        }

        if(totalWorkHoursDay6 === '' && totalWorkHoursDay0 !== '') {

            totalHorasSemanais = sumHours(totalSegSex, totalWorkHoursDay0)

        }

        if(totalWorkHoursDay6 !== '' && totalWorkHoursDay0 !== '') {

            let finalDeSemana = sumHours(totalWorkHoursDay6, totalWorkHoursDay0)

            totalHorasSemanais = sumHours(finalDeSemana, totalSegSex)

        }

        return totalHorasSemanais;

    }

}

export const onlyHour = (hour) => {

    let hourSplited = hour.split(':')[0]

    return hourSplited

}

export const compareDateToAddVacation = (dateLimitConcession, endDateVacation) => {

    let dateLimitConcessionSplited = dateLimitConcession.split('T')[0];
    let dayConcession   = dateLimitConcessionSplited.split('-')[2]
    let monthConcession = dateLimitConcessionSplited.split('-')[1]
    let yearConcession  = dateLimitConcessionSplited.split('-')[0]
    let concessioPeriod = new Date(yearConcession+'-'+monthConcession+'-'+dayConcession)

    let endDateVacationSplited = endDateVacation.split('/')
    let dayVacation     = endDateVacationSplited[0]
    let monthVacation   = endDateVacationSplited[1]
    let yearVacation    = endDateVacationSplited[2]
    let vactionEndDate  = new Date(yearVacation+'-'+monthVacation+'-'+dayVacation)

    if(vactionEndDate >= concessioPeriod) {
        return 1
    }
    else {
        return 0
    }

}

export const resizeImage = (file) => {

    const fileResized = new Promise((resolve) => {
        Resizer.imageFileResizer(
        file,
        300,
        300,
        "PNG",
        100,
        0,
        (uri) => {
            resolve(uri);
        },
        "file"
        );
    });

    return fileResized

}

export const trasformDateStartDatePeriodOfLogToDateFormatUTC = (hour) => {

    if(hour !== null) {

        const year   = String(hour.getFullYear());
        const month_ = (hour.getMonth()+1);
        const day_   = hour.getDate();
        const month  = month_ < 10 ? '0'+month_ : month_;
        const day    = day_ < 10 ? '0'+day_ : day_;
        const dateFormatedUTC = year+'-'+month+'-'+day+'T00:00:00.123Z'

        return dateFormatedUTC;

    }

}

export const trasformDateEndDatePeriodOfLogToDateFormatUTC = (hour) => {

    if(hour !== null) {

        const year   = String(hour.getFullYear());
        const month_ = (hour.getMonth()+1);
        const day_   = hour.getDate();
        const month  = month_ < 10 ? '0'+month_ : month_;
        const day    = day_ < 10 ? '0'+day_ : day_;
        const dateFormatedUTC = year+'-'+month+'-'+day+'T23:59:59.123Z'

        return dateFormatedUTC;

    }

}

export const dateFormatedToLogOfTheSystem = (data) => {
    let dateSplit    = data.split('T')[0]
    let minutesSolit = data.split('T')[1]

    let dia = dateSplit.split('-')[2]
    let mes = dateSplit.split('-')[1]
    let ano = dateSplit.split('-')[0]

    let interal1Hour     = minutesSolit.split(':')[0]
    let interval1Min     = minutesSolit.split(':')[1]


    let dataFormatada = dia + "." + mes + "." + ano + ' '+interal1Hour+':'+interval1Min;
    return dataFormatada
}

export const transformCurrentDayInStringDayMoreHours = (data) => {

    var options       = { day: 'numeric', year: 'numeric', month: 'long' };
    let date          = new Date(data)
    let dateString    = new Date(new Date(date).setDate(new Date(date).getDate())).toLocaleDateString([],options);

    let dataFormatada = dateString+" às " + date.getHours() + ":" + date.getMinutes();
    return dataFormatada;

}

export const select_string_time = (str) => {
    const strings = [];
    let index = str.indexOf(":");
    while (index !== -1) {
      strings.push(str.substring(0, index));
      str = str.substring(index + 1);
      index = str.indexOf(":");
    }
    strings.push(str);
    return strings;
}
export const select_string_minute = (minute) => {
    const parts = minute.split(":")[1];
    const number = parseInt(parts);
    return number
}