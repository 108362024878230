import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Layout from '../../../components/Layouts/defaultUser';
import SubHeader from '../../../components/PerfilUser/subHeader';
import SalaryPromotions from '../../../components/PerfilUser/Historico/SalaryPromotions';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import '../styles.scss';
import api from '../../../services/api';

import { decodeToken } from '../../../services/auth';


export default function Histories(props) {

    const decoded = decodeToken();
    var id        = decoded.user._id;
    const role    = decoded.user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadcollaborator() {
            api.get(`/collaborator-access/${id}`)
            .then(response => {
                setCollaborator(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadcollaborator();
    }, [id]);

        return (
            <>
               <Layout
               path={props.match.path}
               >

                    <div className="profile-user">
                    <InforProfile collaborator={collaborator} id={id}  setCollaborator={setCollaborator}/>
                    <SubHeader collaborator={collaborator} id={id}
                    path={props.match.path}/>


                    <ul className="SubMenu-perfil">
                        <li>
                            <NavLink to={`/meus-dados/historico-ultimas-alteracoes`} exact activeClassName="active"><span>Últimas alterações</span></NavLink>
                        </li>
                        <li>
                            <NavLink to={`/meus-dados/historico-salario-e-promocoes`} exact activeClassName="active"><span>Salário e promoções</span></NavLink>
                        </li>
                    </ul>

                    <SalaryPromotions collaborator={collaborator} id={id} role={role} />
                    </div>
                </Layout>
            </>
        );
}

