import React, { useEffect } from 'react';
// import api from '../../services/api';
// import { Link } from 'react-router-dom'
import './styles.scss';
// import logo from '../../assets/images/logo.svg';

import { decodeToken } from '../../services/auth';

// import { mCPF} from '../../services/mask';



export default function Login() {

    // console.log('aqui')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        alreadyIsLogged();

    }, []);


    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ user, setUser] = useState([]);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ message, setMessage] = useState([]);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ error, setError] = useState(false);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ load, setLoad] = useState(false);


    async function alreadyIsLogged() {

        const alreadyToken = localStorage.getItem('@peopleview/token');

        if(alreadyToken !== null) {

            const decoded = decodeToken();

            if(
                decoded &&
                decoded.user &&
                decoded.user.companyId &&
                decoded.user.companyId.status === 'Inativo'
            ) {

                // console.log('Inativo')
                if (decoded.user.role   === 'admin' || decoded.user.role   === 'master') {
                    // window.location.href = '/conta-suspensa-administrador';
                    setTimeout(function(){window.location.href = '/conta-suspensa-administrador';}, 0)
                }
                else
                if (decoded.user.role   === 'user') {
                    window.location.href = '/conta-suspensa-colaborador';
                }
                else {

                }


            }
            else {

                if (decoded.user.firstAccess) {
                    window.location.href     = '/bem-vindo';
                } else {
                    if (decoded.user.role === 'admin' || decoded.user.role === 'master') {
                        const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                        if(urltokenstatusadmin !== null) {
                            window.location.href = `${urltokenstatusadmin}`;
                        } else {
                            window.location.href = '/dashboard';
                            // window.location.href = '/colaboradores/ativos';
                        }

                    }
                    else
                    if (decoded.user.role === 'manager') {


                        const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                        if(urltokenstatusadmin !== null) {
                            // alert('urltokenstatusadmin !== null')
                            window.location.href = `${urltokenstatusadmin}`;
                        } else {
                            // alert('/colaboradores/ativos')
                            window.location.href = '/colaboradores/ativos';
                        }

                    }
                    else {
                        const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                        if(urltokenstatususer !== null) {
                            window.location.href = `${urltokenstatususer}`;
                        } else {
                            window.location.href = '/dashboard-user';
                        }

                    }
                }

            }

        }
        else {


            window.location.href = '/';

        }

    }



    return (
        <></>
    );
}
