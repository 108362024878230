import React, { useState, useEffect } from 'react';
import "./styles.scss";
import Layout from '../../../../components/Layouts/default';
import HeaderNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/header';
import HeaderEtapasNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/headerAdmissionSteps';
import FormDocuments from '../../../../components/Administrativo/Colaboradores/novaAdmissao/formDocuments';
import Checklist from '../../../../components/Administrativo/Colaboradores/novaAdmissao/checklist';
import { decodeToken } from '../../../../services/auth';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const FormularioDocumentos = (props) => {

    const [stepOne]   = useState(false)
    const [stepTwo]   = useState(false)
    const [stepThree] = useState(true)
    const { id }      = props.match.params;
    const { path }    = props.match;
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [idItem, setidItem] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {
        // loademergencycontacts(id);
        if(id !== undefined) {
            loadtaskcheckeddefault(id);
            loadcollaborator(id);
        }

        loadTaskCompany()

    }, [id]);

    ////////////// NOTIFICATIONS ////////////
    const { history } = props;
    const { user } = decodeToken();
    const [loadTasksChecklist, setLoadTasksChecklist] = useState(false);
    const [tasksDefault, setTasksDefault] = useState([]);
    const [tasksCompany, setTasksCompany] = useState([]);
    async function loadcollaborator(id) {
        if(id && id !== undefined) {
            await api.get(`/collaborator/${id}`)
            .then(response => {

                setCollaborator(response.data);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        } else {}
    }

    async function loadtaskcheckeddefault(id) {
        setLoadTasksChecklist(true)
        await api.get(`/checklist-admission/${id}`)
        .then(response => {

            setLoadTasksChecklist(false)
            setTasksDefault(response.data);
            setidItem('');
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadTaskCompany() {

        setLoadTasksChecklist(true)
        await api.get('/checklist-admission/')
        .then(response => {

            setLoadTasksChecklist(false)
            setTasksCompany(response.data[0]?.item);
            setidItem('')

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function addNewTaskSave(event) {
        setidItem(String(event.idItem));
        if(event.status === false) {

            const checklistCollaborator =
            await api.get(`/checklist-collaborator-admission/${id}`)

            if(checklistCollaborator?.data !== null) {

                let idCheckColab = checklistCollaborator.data._id;

                await api.put(`checklist-collaborator-admission/${idCheckColab}`, { status: true, item: event.idItem, path: path })
                .then(response => {

                    loadtaskcheckeddefault(id);

                }).catch(error => {})

            } else {

                let collaborator = id;
                let item         = [];

                item.push({
                    _id: event.idItem
                })

                await api.post('/checklist-collaborator-admission/', { collaborator, item, path: path })
                .then(response => {

                    loadtaskcheckeddefault(id);

                }).catch(error => {})

            }
        }
        else {

            await api.put(`checklist-collaborator-admission/${event.idChecklist}`, { status: false, item: event.idItem, path: path })
            .then(response => {

                loadtaskcheckeddefault(id);

            }).catch(error => {})

        }

    }


    return (
        <>
            <Layout
            path={props.match.path}
            >
                <HeaderNovaAdmissao
                history={history}
                user={user}
                collaborator={collaborator}
                setCollaborator={setCollaborator}
                />
                <HeaderEtapasNovaAdmissao
                id={id} stepOne={stepOne} stepTwo={stepTwo} stepThree={stepThree}
                path={props.match.path}
                collaboratorInfor={collaborator}
                />
                <div className="structureNewAdmission">
                    <div className="structureContent">
                        <FormDocuments 
                        id={id} path={props.match.path}
                        collaborator={collaborator}
                        setCollaborator={setCollaborator}
                        />
                    </div>
                    <div className="structureChecklist">
                        <Checklist
                        collaboratorId={id}
                        path={props.match.path}
                        addNewTaskSave={addNewTaskSave}
                        tasksDefault={tasksDefault}
                        idItem={idItem}
                        tasksCompany={tasksCompany}
                        loadTasksChecklist={loadTasksChecklist}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}
export default FormularioDocumentos;
