import React from 'react';
import './styles.scss';

import logo from '../../assets/images/logo.svg';
import swal from 'sweetalert';
import api from '../../services/api';

export default function ExpiredToken({ token }) {

    async function tokenUpdateRequest() {

        async function loadcadastrocolaborador() {
            await api.get(`/collaborator-token-update-request/${token}`)
            .then(response => {
                swal({ icon: "success", title: "Sucesso!", text: "Solicitação enviada!" });
            })
            .catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Token inválido." });
            });
        }
        loadcadastrocolaborador();
        //window.location.href = `/colaboradores/nova-admissao/formulario-Informacoes-basicas/${id}`;
    }


    return (
        <>
        <div className="pagina-Token">
            <div className="box-error token-expirado">
                <img src={logo} alt="People View" className="logo"/>
                <h1>Token expirado.</h1>
                <p>Para a segurança das suas informações,
                   esta página não está mais disponível.</p>
                   <button onClick={()=>tokenUpdateRequest()}>Solicite ao RH um novo token</button>
                <span>© 2021 People View</span>
            </div>


        </div>
        </>
    );
}
