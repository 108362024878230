import React from 'react';
import { Tab, Tabs } from "react-bootstrap";
import Aprovados from "./historico/Aprovado";
import Reprovadas from "./historico/Reprovadas";
import Coletivas from "./historico/Coletivas";
export default function HistoricoFerias({
    loadHistoryVacations,
    vacationsCompanyApproved,
    vacationsCompanyRepproved,
    collectiveVacations,

    pageApproved,
    pages,
    prevPageApproved,
    nextPageApproved,
    paginationPageApproved,

    pageRepproved,
    prevPageRepproved,
    pagesRepproved,
    nextPageRepproved,
    paginationPageRepproved,

    nextPageCollective,
    prevPageCollective,
    pageCollective,
    pagesCollective,
    paginationPageCollective

}) {

    return (
        <>
            <div className="historico-ferias">
                <h2 className="titulo-box">Histórico</h2>
                <Tabs defaultActiveKey="Aprovadas" id="uncontrolled-tab-example " className="mb-3">
                    <Tab eventKey="Aprovadas" title="Aprovadas">
                        <Aprovados
                        loadHistoryVacations={loadHistoryVacations}
                        vacationsCompanyApproved={vacationsCompanyApproved}
                        pageApproved={pageApproved}
                        pages={pages}
                        prevPageApproved={prevPageApproved}
                        nextPageApproved={nextPageApproved}
                        paginationPageApproved={paginationPageApproved}
                        />
                    </Tab>
                    <Tab eventKey="Reprovadas" title="Reprovadas">
                        <Reprovadas
                        loadHistoryVacations={loadHistoryVacations}
                        vacationsCompanyRepproved={vacationsCompanyRepproved}
                        pageRepproved={pageRepproved}
                        prevPageRepproved={prevPageRepproved}
                        pagesRepproved={pagesRepproved}
                        nextPageRepproved={nextPageRepproved}
                        paginationPageRepproved={paginationPageRepproved}
                        />
                    </Tab>
                    <Tab eventKey="Coletivas" title="Coletivas" >
                        <Coletivas
                        loadHistoryVacations={loadHistoryVacations}
                        collectiveVacations={collectiveVacations}
                        nextPageCollective={nextPageCollective}
                        prevPageCollective={prevPageCollective}
                        pageCollective={pageCollective}
                        pagesCollective={pagesCollective}
                        paginationPageCollective={paginationPageCollective}
                        />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}
