import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './../Login/styles.scss';
import logo from '../../assets/images/logo.svg';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import swal from 'sweetalert';
///import { decodeToken } from '../../services/auth';
import api from '../../services/api';

export default function Recover() {

    const [load, setLoad] = useState(false);

    const [loadError, setLoadError] = useState(false);

    const [user, setUser] = useState([]);

    const myChangeHandler = event => {

        let nam = event.target.name;
        let val = event.target.value;

        if(val.length === 0) {
            setLoadError(true)
        }
        else {
            setLoadError(false)
        }

        setUser({ ...user, [nam]:val })

    };

    // const [, setRecuperarEmailCollaborator] = useState([]);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [loadCompanies, setLoadCompanies] = useState(false);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ companies, setCompanies] = useState([]);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [selectCompanies, setSelectCompanies] = useState(false);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [selectNext, setSelectNext] = useState(true);


     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [isActiveSelectCompanies, setIsActiveSelectCompanies] = useState(false);
     async function SelectCompanies() { setIsActiveSelectCompanies(!isActiveSelectCompanies) }

    async function save() {

        if(user?.email === undefined || user?.email === '') {
            setLoadError(true)
            return
        }

        async function loadrecuperaremailcollaborator() {
            setLoad(true)
            await api.post(`/recover-collaborator-password/`, user)
            .then(response => {

                setLoad(false)
                if(response?.data?.email) {
                    // setRecuperarEmailCollaborator(response.data);
                    //console.log(response.data)
                    swal({ icon: "success", title: "Sucesso!", text: "Vá até seu e-mail para ler as orientações." });
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                }

                if(response?.data?.companies) {
                    setCompanies(response.data.companies)
                    setSelectCompanies(true)
                    setLoadCompanies(true)
                    setSelectNext(false)
                }


            }).catch(error => {
                setLoad(false)
                swal({ icon: "error", title: "Erro!", text: "Email inválido." });
                //console.log(error)
            });
        }
        loadrecuperaremailcollaborator();
    }



    // const companies = [
    //     { _id: '5ddbdf2b442693001722fc8d', name: 'Jobs X' },
    //     {
    //       _id: '5ddbdb84442693001722fc8b',
    //       name: 'JobSpace Agência de Marketing Digital LTDA'
    //     }
    // ]

    function handlerSelectCompany(_idCompany, nameCompany) {

        let companyId   = _idCompany;
        let CompanyName = nameCompany;

        setUser({ ...user, companyId, CompanyName })
        setLoadCompanies(false)

    }

    console.log(user)


    return (
        <>
            <div className="pageLogin">
                <img src={logo} alt="People View" className="logo"/>
                <div className="formLogin esqueci-senha">
                    <h2 className="titulo-forgot-password">Esqueci a senha</h2>
                    {
                        selectNext ?
                        ''
                        :
                        <p className="titulo-select-company-in-forgot-password">{selectNext ? "" : "Selecione a empresa"}</p>
                    }
                    <input type="email" name="email" onChange={myChangeHandler} className={loadError ? "inputErro email" : "email"} placeholder="Digite seu email para recuperar" required/>
                    {load ?
                    (<p className="carregando">Enviando...</p>
                    ) :
                    ('')
                    }
                    {
                        selectCompanies ?
                        <>
                        <div className="select-busca-btn companias">
                            <button
                                className={loadCompanies ? 'inputErro menu-button' : 'menu-button'}
                                onBlur={ () => (setIsActiveSelectCompanies(false)) }
                                onClick={ SelectCompanies }
                            >
                            <span>
                                {user?.CompanyName ? user?.CompanyName : 'Selecione'}
                            </span>
                            </button>
                            <nav className={`menu ${isActiveSelectCompanies ? "ativo": ""}`} >
                                <Scrollbars className='listDropDown'  style={{  height:
                                companies?.length === 0 ? 39 : "" ||
                                companies?.length === 1 ? 39 : ""  ||
                                companies?.length === 2 ? 70 : ""  ||
                                companies?.length === 3 ? 120 : 120
                                }}>
                                    <ul>
                                        {
                                            companies && companies.length !== 0 ?
                                            companies
                                            .map((company, index) => (
                                                <li key={index}>
                                                    <button
                                                    onClick={()=>handlerSelectCompany(company._id, company.name)}
                                                    >
                                                        {company.name}
                                                    </button>
                                                </li>
                                            ))
                                            :
                                            ''
                                        }
                                    </ul>

                                </Scrollbars>
                            </nav>
                        </div>
                        </>
                        :
                        ''
                    }

                    <button onClick={()=>save()} type="submit" className="btnEntrar btnGreen">
                        {
                            selectNext ?
                            "Próximo"
                            :
                            "Enviar"
                        }
                    </button>
                    <Link to="/"  className="volta-login">
                        <img src={ArrowLeft} alt="Arrow Left" className="arrow-left"/>
                        Voltar para o login
                    </Link>
                </div>
                <span className="copyright">© 2021 People View</span>
            </div>
        </>
    );
}
