import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SelectPersonalizado from '../../../../Reused/selectPersonalizado';
import api from '../../../../../services/api';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

const formBasicInfor = ({
    id,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    save,
    loadbankCode     ,
    loadbankAgency   ,
    loadbankAccount  ,
    loadaccountDigit ,
    loadAccountType  ,
    loadpix          ,
    focusbankAgency  ,
    focusbankAccount ,
    mySearchBank,
    abrirResultado,
    abrirResultBank,
    myChangeHandlerToSaveBank,
    openSelAccountType,
    selectAccountTypeOpen,
    myChangeHandlerAccountType,
    closeBankData,
    focuspix,
    loadContinue
}) => {


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    if(id && id) {
        loadcadastrocolaborador(id);
    }
    loadapibank();

}, [id]);

async function loadcadastrocolaborador(id) {

    await api.get(`/collaborator/${id}`)
    .then(response => {
        setCollaboratorSaved(response.data);
    })
    .catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        //toast.info('Não autorizado! Token inválido ou expirado!');
        setCollaboratorSaved(error.response.data);
    });

}





const myChangeHandlerExcludeAdmssion = async (event) => {
    //const { collaborator } = this.state;
    let nam = event.target.name;
    let val = event.target.value;
    let admissionProcess = "false";
    let shutdownProcess = "true";
    let recentShutdown  = "false";
    let status = "Inativo";

    setCollaboratorSaved({ ...collaboratorSaved, admissionProcess, shutdownProcess, recentShutdown, status, [nam]: val })

}

async function excludeAdmssion(event) {
    event.preventDefault();

    swal({
        title: "Atenção",
        text: "Deseja realmente excluir o processo de admissão deste colaborador?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('_id', collaboratorSaved._id);
            formData.append('admissionProcess', collaboratorSaved.admissionProcess);
            formData.append('shutdownProcess', collaboratorSaved.shutdownProcess);
            formData.append('recentShutdown', collaboratorSaved.recentShutdown);
            formData.append('status', collaboratorSaved.status);

            await api.put(`/collaborator/${collaboratorSaved._id}`, formData, config)
            .then(response => {
                //setCollaborator({})
                swal({ icon: "success", title: "Sucesso!", text: "Admissão excluída com sucesso." });
                window.location.href = '/colaboradores/nova-admissao/formulario-Informacoes-basicas';
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
            });
        }
    })

}

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ apiBank, setApiBank] = useState([]);

    async function loadapibank() {
        await api.get(`https://brasilapi.com.br/api/banks/v1`)
        .then(response => {
            const itensCopyBanck = Array.from(response.data);
            itensCopyBanck.sort(function (a, b) {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            setApiBank(itensCopyBanck);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    const optionAccountType =[
        "Conta Poupança",
        "Conta Salário",
        "Conta Corrente"
    ]
    return (
        <>
        <div className="box bankData" onClick={closeBankData}>
            <div className="form">
                <div className="box">
                    <h3>Dados bancários</h3>
                    <div className="boxForm">
                    <p className="textCampo textoUm">Banco</p>


                    <div className='select-busca inputUm'>
                    <input type="text"
                        name="bankName"
                        value={
                            collaboratorUpdate && collaboratorUpdate.bankName !== undefined ?
                            collaboratorUpdate.bankName
                            :
                            collaborator.bankName !== undefined ?
                            collaborator.bankName
                            :
                            ''
                        }
                        placeholder='Banco'
                        onChange={mySearchBank}
                        onClick={abrirResultado}
                        autoComplete="off"
                        id="myBank"
                        className={loadbankCode ? 'inputErro busca' : 'busca'}
                    />


                            {abrirResultBank ?

                                    <div className='resultado-busca busca-bank' id='resultadoBusca'>
                                        {
                                            apiBank.length !== 0 ? apiBank
                                            .map((bank, index) => (
                                                <button onClick={() => myChangeHandlerToSaveBank(bank.name) } key={index} ><p  >{bank.code} {bank.code === null ? "" : "-"} {bank.name}</p></button>
                                            )): ('')
                                        }

                                    </div>

                                 :
                                ""
                            }

                    </div>

                {
                loadbankCode ?
                <p className="textError inputUm">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoDois">Agência</p>
                {
                (collaboratorUpdate && collaboratorUpdate.bankAgency !== undefined) || 
                collaboratorUpdate.bankAgency === '' ?
                <input ref={focusbankAgency} type="text" name="bankAgency" autocomplete="autocompleteoff"  value={collaboratorUpdate.bankAgency ? collaboratorUpdate.bankAgency : ''} placeholder="Agência" onChange={onCollaboratorInputChange} className={loadbankAgency ? 'inputErro inputDois' : 'inputDois'}/> :
                collaborator && collaborator.bankAgency ?
                <input ref={focusbankAgency} type="text" name="bankAgency" autocomplete="autocompleteoff"  value={collaborator.bankAgency ? collaborator.bankAgency : ''} onChange={onCollaboratorInputChange} className={loadbankAgency ? 'inputErro inputDois' : 'inputDois'}/> :
                <input ref={focusbankAgency} type="text" name="bankAgency" autocomplete="autocompleteoff" value={collaboratorUpdate.bankAgency ? collaboratorUpdate.bankAgency : ''} onChange={onCollaboratorInputChange} placeholder="Agência" className={loadbankAgency ? 'inputErro inputDois' : 'inputDois'}/>
                }
                {
                loadbankAgency ?
                <p className="textError inputDois">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoTreis">Conta</p>
                <div className="inputTreis">
                {
                (collaboratorUpdate && collaboratorUpdate.bankAccount !== undefined) || 
                collaboratorUpdate.bankAccount === '' ?
                <input ref={focusbankAccount} type="text" name="bankAccount" autocomplete="autocompleteoff"  value={collaboratorUpdate.bankAccount ? collaboratorUpdate.bankAccount : ''} onChange={onCollaboratorInputChange} placeholder="conta" className={loadbankAccount ? 'inputErro conta' : 'conta'}/> :
                collaborator && collaborator.bankAccount ?
                <input ref={focusbankAccount} type="text" name="bankAccount" autocomplete="autocompleteoff"  defaultValue={collaborator.bankAccount ? collaborator.bankAccount : ''} onChange={onCollaboratorInputChange} placeholder="conta" className={loadbankAccount ? 'inputErro conta' : 'conta'}/> :
                <input ref={focusbankAccount} type="text" name="bankAccount" autocomplete="autocompleteoff" value={collaboratorUpdate.bankAccount ? collaboratorUpdate.bankAccount : ''} onChange={onCollaboratorInputChange} placeholder="conta" className={loadbankAccount ? 'inputErro conta' : 'conta'}/>
                }
                {
                (collaboratorUpdate && collaboratorUpdate.accountDigit !== undefined) || 
                collaboratorUpdate.accountDigit === '' ?
                <input  type="text" name="accountDigit"  autocomplete="autocompleteoff" value={collaboratorUpdate.accountDigit ? collaboratorUpdate.accountDigit : ''} placeholder="Dígito" onChange={onCollaboratorInputChange} className={loadaccountDigit ? 'inputErro digito' : 'digito'}/> :
                collaborator && collaborator.accountDigit ?
                <input type="text" name="accountDigit"  autocomplete="autocompleteoff" defaultValue={collaborator.accountDigit ? collaborator.accountDigit : ''} onChange={onCollaboratorInputChange} placeholder="Dígito" className={loadaccountDigit ? 'inputErro digito' : 'digito'}/> :
                <input type="text" name="accountDigit"  autocomplete="autocompleteoff"value={collaboratorUpdate.accountDigit ? collaboratorUpdate.accountDigit : ''} onChange={onCollaboratorInputChange} placeholder="Dígito" className={loadaccountDigit ? 'inputErro digito' : 'digito'}/>
                }
                </div>
                {
                loadbankAccount ?
                <p className="textError inputTreis">O campo é obrigatório.</p>
                :
                ''
                }
                {
                loadaccountDigit ?
                <p className="textError inputTreis">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoQuatro">Tipo de conta</p>

                <div className='inputQuatro'>

                    <SelectPersonalizado
                        valorCampo={
                            collaboratorUpdate && collaboratorUpdate.AccountType !==  undefined ?
                            collaboratorUpdate.AccountType ? ( collaboratorUpdate.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                            collaboratorUpdate.AccountType &&
                            collaboratorUpdate.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                            collaboratorUpdate.AccountType &&
                            collaboratorUpdate.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                            ) : ""
                            :
                            collaboratorSaved.AccountType ? ( collaborator.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                            collaborator.AccountType &&
                            collaborator.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                            collaborator.AccountType &&
                            collaborator.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                            ) : ""
                        }
                        nameButton={"AccountType"}
                        myChangeHandler={myChangeHandlerAccountType}
                        option={optionAccountType}
                        loadError={loadAccountType}
                    />

                </div>
                {
                loadAccountType ?
                <p className="textError inputQuatro">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoCinco">Pix</p>
                <div className="inputCinco">
                {
                (collaboratorUpdate && collaboratorUpdate.pix !== undefined) || 
                collaboratorUpdate.pix === '' ?
                <input ref={focuspix} type="text" name="pix" autocomplete="autocompleteoff" value={collaboratorUpdate.pix ? collaboratorUpdate.pix : ''} placeholder="pix" onChange={onCollaboratorInputChange} className={loadpix ? 'inputErro pix' : 'pix'}/>:
                collaborator && collaborator.pix !== undefined ?
                <input ref={focuspix} type="text" name="pix" autocomplete="autocompleteoff" value={collaborator.pix ? collaborator.pix : ''} onChange={onCollaboratorInputChange} placeholder="pix" className={loadpix ? 'inputErro pix' : 'pix'}/>:
                <input ref={focuspix} type="text" name="pix" autocomplete="autocompleteoff" value={collaboratorUpdate.pix ? collaboratorUpdate.pix : ''} onChange={onCollaboratorInputChange} placeholder="pix" className={loadpix ? 'inputErro pix' : 'pix'}/>
                }
                </div>
                {
                loadpix ?
                <p className="textError inputCinco">O campo é obrigatório.</p>
                :
                ''
                }
                    </div>
                  

                </div>
            </div>
        </div>


            <div className="buttons">
                    <form onSubmit={excludeAdmssion} className="deleteStepOne">
                        <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaborator._id} type="submit" className="delete">
                            Excluir processo
                        </button>
                    </form>
                    <div className='salvedStep'>

                        <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${id}`} className="back btnGray">Voltar</NavLink>


                        <form className="savedandback" onSubmit={save}>
                            <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar e continuar</button>
                        </form>
                    </div>
                </div>
        </>
    );
}
export default formBasicInfor;
