import React from 'react';

import { dateFormatedToLogOfTheSystem } from '../../services/utils'

export default function tableLogs({ logOfTheSystem, loadlLogOfTheSystem }) {

    // const valorOld = {
    //     "_id": "63bee0366356f500168ede51",
    //     "companyId":" 63933a4e37ad650016af43df",
    //     "collaborator": "63987be7a8b71400161aa275",
    //     "name": 'Captura de Tela 2023-01-03 às 10.20.47.png',
    //     "category": 'identification',
    //     "originalname": 'Captura de Tela 2023-01-03 às 10.20.47.png',
    //     "mimetype": 'image/png',
    //     "size": 64775,
    //     "key": 'collaborators-file/09470ee396e2096caee9546f22313473-Captura de Tela 2023-01-03 às 10.20.47.png',
    //     "location": 'https://staging-peopleview.s3.amazonaws.com/collaborators-file/09470ee396e2096caee9546f22313473-Captura%20de%20Tela%202023-01-03%20a%CC%80s%2010.20.47.png',
    //     "createdAt": "2023-01-11T16:13:42.923Z",
    //     "updatedAt": "2023-01-11T16:13:42.923Z"
    // }
    // const valorNew = {
    //     "_id": "63bee0366356f500168ede51",
    //     "companyId":" 63933a4e37ad650016af43df",
    //     "collaborator": "63987be7a8b71400161aa275",
    //     "name": 'Captura de Tela 2023-01-03 às 10.20.47.png',
    //     "category": 'identification',
    //     "originalname": 'Captura de Tela 2023-01-03 às 10.20.47.png',
    //     "mimetype": 'image/png',
    //     "size": 64775,
    //     "key": 'collaborators-file/09470ee396e2096caee9546f22313473-Captura de Tela 2023-01-03 às 10.20.47.png',
    //     "location": 'https://staging-peopleview.s3.amazonaws.com/collaborators-file/09470ee396e2096caee9546f22313473-Captura%20de%20Tela%202023-01-03%20a%CC%80s%2010.20.47.png',
    //     "createdAt": "2023-01-11T16:13:42.923Z",
    //     "updatedAt": "2023-01-11T16:13:42.923Z"
    // }

    return (
        <>
        <div className='tabela-logs'>
            <div className='header-table'>
                <div> Ação </div>
                <div> Usuário </div>
                <div> Fucionalidade </div>
                <div> Data/Hora </div>
                <div> Antigo valor</div>
                <div> Novo valor</div>
            </div>
            <div className='body-table'>
                {
                    loadlLogOfTheSystem ?
                    'Carregando...'
                    :
                    logOfTheSystem && logOfTheSystem?.length !== 0 ?
                        logOfTheSystem.map((logs, index)=> (
                            <div key={index} className='linha'>
                                <div>{logs?.action}</div>
                                <div>{logs?.nameUser}</div>
                                <div>{logs?.functionality}</div>
                                <div>{dateFormatedToLogOfTheSystem(logs?.createdAt)}</div>
                                {
                                    logs?.oldValue ?
                                    <div>
                                        <pre>{logs?.oldValue}</pre>
                                    </div>
                                    :
                                    <div>
                                        <pre>-</pre>
                                    </div>
                                }
                                {
                                    logs?.NewValue ?
                                    <div>
                                        <pre>{
                                        // JSON.stringify(logs?.NewValue, null, 2)
                                        logs?.NewValue
                                        }</pre>
                                    </div>
                                    :
                                    <div>
                                        <pre>-</pre>
                                    </div>
                                }

                            </div>
                        ))
                    :
                    'Nada por aqui!'
                }
            </div>

        </div>
        </>
    );
}

