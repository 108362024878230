import React from 'react';
import Carregando from '../../../../assets/images/carregando.gif';

const Checklist = ({
    collaboratorId,
    myChangeHandlerTemporaryChecklist,
    tasksDefault,
    tasksCompany,
    addNewTaskSave,
    tasksCompanyReduce,
    loadTasksChecklist,
    idItem,
}) => {
    
    async function chackNewTaskSave(event) {
        addNewTaskSave(event)
    }
    return tasksDefault && tasksDefault?.length !== 0 ? (
        <>
            <div className="checkListAdm">
                <div className="checkList">
                    <h4>Checklist</h4>
                    <ul>
                        {tasksDefault.map((defaul, index) => (
                            <>
                                <li key={defaul._id}>
                                    <button
                                        className="btn-check"
                                        onClick={(event) =>
                                            idItem === "" ?
                                            chackNewTaskSave(defaul)
                                            : ""
                                        }
                                    >
                                        {String(defaul.idItem) ===
                                        String(idItem) ? (
                                            <img src={Carregando} alt="" />
                                        ) : (
                                            <label
                                                htmlFor={defaul.name}
                                                className={
                                                    defaul.status === true
                                                        ? 'chekboxLabel checado'
                                                        : 'chekboxLabel'
                                                }
                                            ></label>
                                        )}

                                        <div className="texto">
                                            <label htmlFor={defaul.name}>
                                                {defaul.name}
                                            </label>
                                        </div>
                                    </button>
                                    <input
                                        disabled={
                                            collaboratorId &&
                                            collaboratorId !== undefined
                                                ? false
                                                : true
                                        }
                                        name={defaul.name}
                                        type="checkbox"
                                        value={defaul.name}
                                        id={defaul.name}
                                        defaultChecked={defaul.status}
                                    />
                                </li>
                            </>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    ) : tasksCompanyReduce && tasksCompanyReduce?.length !== 0 ? (
        <>
            <div className="checkListAdm">
                <div className="checkList">
                    <h4>Checklist</h4>
                    <ul>
                        {tasksCompanyReduce.map((defaul, index) => (
                            <>
                                <li key={defaul._id}>
                                    <button
                                        onClick={(event) =>
                                            myChangeHandlerTemporaryChecklist(defaul)
                                        }
                                        className="btn-check"
                                    >
                                        {defaul.idItem === idItem ? (
                                            <img src={Carregando} alt="" />
                                        ) : (
                                            <label
                                                htmlFor={defaul.name}
                                                className={
                                                    defaul.status === true
                                                        ? 'chekboxLabel checado'
                                                        : 'chekboxLabel'
                                                }
                                            ></label>
                                        )}
                                        {/* <label htmlFor={defaul.name} className={defaul?.status === true ? "chekboxLabel checado" : "chekboxLabel"}> */}
                                        {/* </label> */}
                                        <div className="texto">
                                            <label htmlFor={defaul.name}>
                                                {defaul.name}
                                            </label>
                                        </div>
                                    </button>
                                    <input
                                        name={defaul.name}
                                        type="checkbox"
                                        value={defaul.name}
                                        id={defaul.name}
                                        defaultChecked={defaul?.status}
                                    />
                                </li>
                            </>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    ) : tasksCompany && tasksCompany?.length !== 0 ? (
        <>
            <div className="checkListAdm">
                <div className="checkList">
                    <h4>Checklist</h4>
                    <ul>
                        {tasksCompany.map((defaul, index) => (
                            <>
                                <li key={defaul._id}>
                                    <button
                                        onClick={(event) =>
                                            idItem === "" ?
                                            myChangeHandlerTemporaryChecklist(defaul) 
                                            : ""
                                        }
                                        className="btn-check"
                                    >
                                        {defaul.idItem === idItem ? (
                                            <img src={Carregando} alt="" />
                                        ) : (
                                            <label
                                                htmlFor={defaul.name}
                                                className={
                                                    defaul.status === true
                                                        ? 'chekboxLabel checado'
                                                        : 'chekboxLabel'
                                                }
                                            ></label>
                                        )}
                                        <div className="texto">
                                            <label htmlFor={defaul.name}>
                                                {defaul.name}
                                            </label>
                                        </div>
                                    </button>
                                    <input
                                        name={defaul.name}
                                        type="checkbox"
                                        value={defaul.name}
                                        id={defaul.name}
                                        defaultChecked={defaul?.status}
                                    />
                                </li>
                            </>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    ) : loadTasksChecklist ? (
        ''
    ) : tasksDefault?.length === 0 ||
      tasksCompanyReduce === undefined ||
      tasksCompany === undefined ? (
        <div className="checkListAdm">
            <div className="checkList">
                <h4>Checklist</h4>
                <ul>
                    <>
                        <li>
                            <p className="nao-tem-intem">
                                Não existem itens de checklist cadastrado. Para
                                utilizar o checklist vá em "Configurações", na
                                aba "Checklist" e cadastre os seus checklists.
                            </p>
                        </li>
                    </>
                </ul>
            </div>
        </div>
    ) : (
        ''
    );
};

export default Checklist;
