import React from 'react';
//import { NavLink } from 'react-router-dom'
import Carregando from '../../../../assets/images/carregando.gif';

import { formatDateString } from '../../../../services/utils'
import { Scrollbars } from 'react-custom-scrollbars';

import Dropzone from "react-dropzone";

//import InputMask from 'react-input-mask';

export default function formPersonalData({
    token,
    id,
    collaborator,
    comDependents,
    loadDependents,
    loadNameDependent,
    loadRelationship,
    loadDateOfBirth,
    loadSchoolingDependent,
    fileNamesDependents,
    dependents,
    registeredDependents,
    onCollaboratorInputChangeNoDependent,
    onCollaboratorInputChangeDependent,
    handleDropDependent,
    deleteFilesDependent,
    handleSubmitDependent,
    deleteDependent,
    focusNameDependent,
    focusRelationshipDependent,
    focusDateofBirthDependent,
    focusSchoolingDependente,
    focusBottomDependent,
    loadBottomDependent,
    dependentsSaved,
    noDependentsSaved
 }) {

return (
            <>

            <div className="box-formulario dependentes">
                    <h4 className="titulo-box">Dependentes</h4>
                    <div className="verificar-dependente">
                        <p className="textCampo">Possui dependentes?</p>
                        {
                            noDependentsSaved === true ?
                            <div className="inputUm">
                                <div className="radioNovo">
                                    <input disabled={true} type="radio" name="noDependents" value="true" onChange={onCollaboratorInputChangeNoDependent} id="nao" />
                                    <label htmlFor="nao"><span>Não</span></label>
                                </div>
                                <div className="radioNovo">
                                    <input type="radio" name="noDependents" value="false" onChange={onCollaboratorInputChangeNoDependent} id="sim" defaultChecked="checked"/>
                                    <label htmlFor="sim"><span>Sim</span></label>
                                </div>
                            </div>
                            :
                            noDependentsSaved === false ?
                            <>
                            <div className="inputUm">
                                <div className="radioNovo">
                                    <input type="radio" name="noDependents" value="true" onChange={onCollaboratorInputChangeNoDependent} id="nao" defaultChecked="checked"/>
                                    <label htmlFor="nao"><span>Não</span></label>
                                </div>
                                <div className="radioNovo">
                                    <input type="radio" name="noDependents" value="false" onChange={onCollaboratorInputChangeNoDependent} id="sim" />
                                    <label htmlFor="sim"><span>Sim</span></label>
                                </div>
                            </div>
                            </>
                            :
                            <div className="inputUm">
                                <div className="radioNovo">
                                    <input type="radio" name="noDependents" value="true" onChange={onCollaboratorInputChangeNoDependent} id="nao" defaultChecked="checked"/>
                                    <label htmlFor="nao"><span>Não</span></label>
                                </div>
                                <div className="radioNovo">
                                    <input type="radio" name="noDependents" value="false" onChange={onCollaboratorInputChangeNoDependent} id="sim" />
                                    <label htmlFor="sim"><span>Sim</span></label>
                                </div>
                            </div>


                        }
                    </div>
                    <div className=
                    {

                        dependents && dependents.noDependents === 'false' ?
                        "box-formulario" :
                        noDependentsSaved && noDependentsSaved === true ?
                        "box-formulario" :

                        "displayNone"
                    }

                    >
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo textoUm">Nome completo *</p>
                                    
                                </div>
                                <div className='intemB'>
                                    <input ref={focusNameDependent} type="text" name="name" value={dependents && dependents.name ? dependents.name : ''} onChange={onCollaboratorInputChangeDependent} placeholder="Nome completo" className={loadNameDependent ? 'inputUm inputErro' : 'inputUm'}/>
                                    <p className="inputUm textError">{loadNameDependent ? 'O campo é obrigatório.' : ''}</p>

                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo textoDois">Parentesco *</p>

                                </div>
                                <div className='intemB'>

                                    <div className='cont-select inputDois'>
                                        <button className={loadRelationship ? 'inputErro select-' : 'select-'}  ref={focusRelationshipDependent}>
                                            <p>
                                                {
                                                    dependents && dependents.relationship ?
                                                        dependents.relationship
                                                    :
                                                        'Selecione'
                                                }
                                            </p>
                                        </button>

                                        <div className="dropDown">
                                            <Scrollbars className='listDropDown'   
                                                style={{  height:  125 }}
                                            >
                                                {[
                                                    "Cônjuge", 
                                                    "Companheiro(a) ou união estável com filhos", 
                                                    "Companheiro(a) ou união estável sem filhos", 
                                                    "Filho ou enteado até 21 anos", 
                                                    "Filho ou enteado universitário ou em escola técnica",
                                                    "Irmão(ã), neto(a) ou bisneto(a) com guarda", 
                                                    "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica", 
                                                    "Pais, avós e bisavós", 
                                                    "Incapaz", 
                                                    "Agregado/Outros", 
                                                    "Ex cônjuge que recebe pensão de alimentos"
                                                    ].map((intem, index) => (
                                                        <button  key={index} name="relationship" value={intem}  onClick={onCollaboratorInputChangeDependent} className='item'>
                                                            {intem}
                                                        </button>
                                                    ))
                                                } 
                                                </Scrollbars>
                                        </div>
                                    </div>
                                     <p className="inputDois textError">{loadRelationship ? 'O campo é obrigatório.' : ''}</p>
                                </div>
                            </div>
                        </div>









                        {/* <select ref={focusRelationshipDependent} name="relationship" value={dependents && dependents.relationship ? dependents.relationship : ''} onChange={onCollaboratorInputChangeDependent} className={loadRelationship ? 'inputDois inputErro' : 'inputDois'}>
                            <option value="Selecione">Selecione</option>
                            {[
                                "Cônjuge", 
                                "Companheiro(a) ou união estável com filhos", 
                                "Companheiro(a) ou união estável sem filhos", 
                                "Filho ou enteado até 21 anos", 
                                "Filho ou enteado universitário ou em escola técnica",
                                 "Irmão(ã), neto(a) ou bisneto(a) com guarda", 
                                 "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica", 
                                 "Pais, avós e bisavós", 
                                 "Incapaz", 
                                 "Agregado/Outros", 
                                 "Ex cônjuge que recebe pensão de alimentos"
                            ].map((item, index) => (
                                <option value={item} key={index}>{item}</option>
                            ))}
                        </select> */}
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo textoTreis">Idade *</p>
                                    
                                </div>
                                <div className='intemB'>

                                    <input ref={focusDateofBirthDependent} mask="99" type="text" value={dependents && dependents.dateOfBirth ? dependents.dateOfBirth : ''} name="dateOfBirth" onChange={onCollaboratorInputChangeDependent} placeholder="Idade" className={loadDateOfBirth ? 'inputTreis inputErro' : 'inputTreis'}/>
                                    <p className="inputTreis textError">{loadDateOfBirth ? 'O campo é obrigatório.' : ''}</p>

                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo textoQuatro">Escolaridade *</p>
                                    
                                </div>
                                <div className='intemB'>
                                    <div className='cont-select'>
                                        <button className={loadSchoolingDependent ? 'inputErro select-' : 'select-'}  ref={focusSchoolingDependente}>
                                            <p>
                                                {
                                                    dependents && dependents.schooling ?
                                                        dependents.schooling
                                                    :
                                                        'Selecione'
                                                }
                                            </p>
                                        </button>

                                        <div className="dropDown">
                                            <Scrollbars className='listDropDown'   
                                                style={{  height:  125 }}
                                            >
                                                {[
                                                    "Analfabeto", "Até a 5ª série incompleta do Ensino Fundamental", "5º ano completo do Ensino Fundamental", "Do 6º ao 9º ano do Ensino Fundamental incompleto", "Ensino Fundamental completo", "Ensino Médio incompleto", "Ensino Médio completo", "Educação Superior incompleta", "Educação Superior completa", "Mestrado completo", "Mestrado incompleto", "Doutorado completo", "Doutorado incompleto", "Pós Graduação completa", "Pós Graduação incompleta", "Ensino Técnico completo", "Ensino Técnico incompleto"
                                                    ].map((intem, index) => (
                                                        <button  key={index} name="schooling" value={intem}  onClick={onCollaboratorInputChangeDependent} className='item'>
                                                            {intem}
                                                        </button>
                                                    ))
                                                } 
                                                </Scrollbars>
                                        </div>
                                    </div>
                                    <p className="textError">{loadSchoolingDependent ? 'O campo é obrigatório.' : ''}</p>

                                </div>
                            </div>
                        </div>

                        

                        {/* <select ref={focusSchoolingDependente} name="schooling" value={dependents && dependents.schooling ? dependents.schooling : ''} onChange={onCollaboratorInputChangeDependent} className={loadSchoolingDependent ? 'inputQuatro inputErro' : 'inputQuatro'}>
                            <option value="Selecione">Selecione</option>
                            {[
                                "Analfabeto", "Até a 5ª série incompleta do Ensino Fundamental", "5º ano completo do Ensino Fundamental", "Do 6º ao 9º ano do Ensino Fundamental incompleto", "Ensino Fundamental completo", "Ensino Médio incompleto", "Ensino Médio completo", "Educação Superior incompleta", "Educação Superior completa", "Mestrado completo", "Mestrado incompleto", "Doutorado completo", "Doutorado incompleto", "Pós Graduação completa", "Pós Graduação incompleta", "Ensino Técnico completo", "Ensino Técnico incompleto"
                            ].map((item, index) => (
                                <option value={item} key={index}>{item}</option>
                            ))}
                        </select> */}



                        <div className="depedente-dropzone">

                            <div  className="dropzone">

                                <Dropzone className="" onDrop={handleDropDependent}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>

                                <div className="textoOnze">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>

                            </div>


                            <div className="arquivoAnexado">
                                {fileNamesDependents ?
                                fileNamesDependents.map((filename, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            {filename}
                                        </p>
                                        <p onClick={deleteFilesDependent} className="remover">Remover anexo</p>
                                    </div>
                                )) : ''}
                            </div>
                        </div>




                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    
                                </div>
                                <div className='intemB'>
                                    <button ref={focusBottomDependent} type="submit" onClick={handleSubmitDependent}  className={loadDependents ? 'enviar btnGreen btnGreenLoad inputDoze' : 'enviar btnGreen inputDoze'}>{loadDependents ? 'Adicionando' : 'Adicionar dependente'}</button>
                                    {loadBottomDependent ? (
                                    <p className="textError inputDoze">É necessário adicionar um dependente</p>
                                    ) : ('')
                                    }

                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'></div>
                                <div className='intemB'></div>
                            </div>
                        </div> 
                    </div>
                {
                    registeredDependents && registeredDependents[0] !== undefined ?
                    <div className="dependente-adicionado">
        
        
                            {loadDependents ?
                                <div className="carregando  textoDoze"><img src={Carregando} alt="carregando" /></div> :
                                <>
                                    {
                                        registeredDependents && registeredDependents[0] !== undefined ?
                                        <h5>Cadastrados</h5> :
                                        ''
                                    }
                                    {
                                        registeredDependents && registeredDependents[0] !== undefined ?
                                        registeredDependents
                                        .map((dependentcollaborator, index) => (
                                            <div key={index} className="anexado">
                                                <div className="name">
                                                    <p>{dependentcollaborator.name}</p>
                                                </div>
                                                <div className="arquivo">
                                                <p>{dependentcollaborator.originalname !== undefined ? (
        
                                                    <a href={dependentcollaborator.location} target="’_blank’">
                                                    {dependentcollaborator.originalname}
                                                    </a>
                                                    ) : (<span>Nenhum anexo</span>)}
                                                    </p>
                                                </div>
                                                <div className="dataArquivo">
                                                    <span>Cadastrado em {formatDateString(dependentcollaborator.createdAt)}</span>
                                                </div>
                                                <div className="btnExclui">
                                                    <button onClick={() => deleteDependent(dependentcollaborator)}>Excluir</button>
                                                </div>
                                            </div>
                                        )) : (
                                            <div className="nenhum-dependente">
                                            <p></p>
                                            </div>
                                        )
                                    }
                                </>
                                }
                    </div>
                    :""
                
                }




            </div>

            </>
        );

}

