import React from 'react';
import logo from '../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom'
import './styles.scss';
// import { decodeToken } from '../../services/auth';

// const decoded = decodeToken();

// console.log('decoded')
// console.log(decoded)

export default function SuspendedAccount() {
    return (
        <>
            <div className="box-error conta-suspensa">
                <img src={logo} alt="People View" className="logo"/>
                <h1>Conta suspensa!</h1>
                {/* <p className="texto-um">Houve um problema com o pagamento e a assinatura não foi renovada.</p> */}
                <p className="texto-um">Parece que sua conta está inativa no momento.</p>
                <p className="texto-dois">Atualize as informações de pagamento nas <NavLink to={`/configuracao/plano-e-cobranca`}>configurações</NavLink> da sua conta.</p>


                <span>© 2021 People View</span>
            </div>
        </>
    );
}
