import React, {useState, useEffect} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import {removerSpecials} from '../../services/utils'
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment'
import swal from 'sweetalert';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { FormataStringData } from '../../services/utils';
import { dateMask, phones, cep } from '../../services/mask';
import './styles.scss';


export default function RegisterNewCandidate(props) {

    const { path } = props.match;
    const { pathname } = props.location;

    const { id } = props.match.params;
    
    useEffect(() => {

        loadsectors();
        if(id) {
            loadsCandidate(id);
        }
    }, [id]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfbirthSaved, setDateOfbirthSaved ] = useState([]);
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [canditateUp, setCanditateUp ] = useState([]);

    async function loadsCandidate(id) {
        // setLoadLatestCandidates(true)
        await api.get(`/candidate/${id}`)
        .then(response => {

            setCanditateUp(response.data);

            let dateOfBirth = response.data.dateOfBirth;
            if(dateOfBirth !== undefined) {
                let data = moment(dateOfBirth, "YYYY-MM-DD");
                setDateOfbirthSaved(data.format("DD/MM/YYYY"))
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadNameCandidate, setLoadNameCandidate] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadDateOfBirth, setLoadDateOfBirth] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSector, setLoadSector ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadLaborBond, setLoadLaborBond ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPhone, setLoadPhone ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadEmail, setLoadEmail ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadZipcode, setLoadZipcode ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadAddress, setLoadAddress ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadAddressNeighborhood, setLoadAddressNeighborhood ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadState, setLoadState ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadCity, setLoadCity ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [laodDropZone, setLoadDropZone ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadButtomSaveCandidate, setLoadButtomSaveCandidate ] = useState(false);

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA sector /////////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);
    async function loadsectors() {
        await api.get('/sector/?sortBy=name&order=asc')
        .then(response => {
            setSectors(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    const myChangeHandlerSelects = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "nameSector"){
            let valSector = removerSpecials(val).toLowerCase()
            setBuscaSector({ ...buscaSector, [nam]: val });
            setBuscaSectorState({ ...buscaSectorState, [nam]: valSector });
            setCandidate({ ...candidate, [nam]: val })
        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSector, setBuscaSector ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSectorState, setBuscaSectorState ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaSector, setIsActiveSelectBuscaSector] = useState(false);
    async function SelectBuscaSector() { setIsActiveSelectBuscaSector(!isActiveSelectBuscaSector) }
    // setLoadSector
    const sectorsFiltre = sectors.filter((sector) => {
        return removerSpecials(sector.name).toLowerCase().includes(buscaSectorState?.nameSector !== undefined ? buscaSectorState.nameSector : "" )  ;
    });


    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA sector /////////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectLaborBond, setIsActiveSelectLaborBond] = useState(false);
    async function SelectLaborBond() { setIsActiveSelectLaborBond(!isActiveSelectLaborBond) }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidate, setCandidate ] = useState([]);



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfBirthUpdate, setDateOfBirthUpdate ] = useState([]);

    async function mychangeHandlerCandidate(event) {

        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'name') {

            if(val.length === 0) {
                setLoadNameCandidate(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadNameCandidate(false)
                setCandidate({ ...candidate, [nam]: val })
            }



        }
        else
        if(nam === "dateOfBirth") {

            let date = dateMask(val)

            if(date.length === 0) {
                setLoadDateOfBirth(true)
                setCandidate({ ...candidate, [nam]: date })
            }
            else
            if(date.length >= 1 && date.length <= 10) {
                setCandidate({ ...candidate, [nam]: date })
                let valDate = FormataStringData(date);
                setLoadDateOfBirth(false)
                setDateOfBirthUpdate({ ...dateOfBirthUpdate, [nam]: valDate })
                setDateOfbirthSaved(date)
            }

        }
        // phone
        else
        if(nam === 'phone') {

            if(val.length === 0) {
                setLoadPhone(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                let phone = phones(val)
                setLoadPhone(false)
                setCandidate({ ...candidate, [nam]: phone })
            }

        }
        // email
        else
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmail(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadEmail(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if (nam === "zipcode") {

            if(val.length === 0) {
                setLoadZipcode(true)
            }
            else {
                setLoadZipcode(false)
            }


            getAddress(val)
            let zipcode = cep(val)
            setCandidate({ ...candidate, [nam]: zipcode })

        }
        else
        if(nam === 'address') {

            if(val.length === 0) {
                setLoadAddress(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadAddress(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if(nam === 'addressNeighborhood') {

            if(val.length === 0) {
                setLoadAddressNeighborhood(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadAddressNeighborhood(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if(nam === 'addressState') {

            if(val.length === 0) {
                setLoadState(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadState(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if(nam === 'addressCity') {

            if(val.length === 0) {
                setLoadCity(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadCity(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else {

            setCandidate({ ...candidate, [nam]: val })

        }





    }

    function sectorSelected(id_sector, name_sector) {
        setCandidate({ ...candidate, 'sector': id_sector, 'nameSector': name_sector })
        setLoadSector(false)
    }

    // sectorlaborBond
    function sectorlaborBond(name) {
        setCandidate({ ...candidate, 'laborBond': name})
        setLoadLaborBond(false)

    }

    async function getAddress(zipcode) {
        zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        if (zipcode.length >= 8) {
            //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
            // const response =
            await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
            .then(response => {
                setCandidate({
                    ...candidate,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })

                setLoadAddress(false)
                setLoadAddressNeighborhood(false)
                setLoadState(false)
                setLoadCity(false)
            })
            .catch(error=>{

                let val = ''
                setCandidate({ ...candidate, 'zipcode': val })

                setLoadZipcode(true)
                setLoadAddress(true)
                setLoadAddressNeighborhood(true)
                setLoadState(true)
                setLoadCity(true)

            })

        }
    }

    //////////////////////////////////////////////////////
    ///////////////// DROPZONE /////////////////
    ////////////////////////////////////////////////////

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            let file     = acceptedFiles[0];
            let nameFile = acceptedFiles[0].name;

            // setFileNames(acceptedFiles.map(file => file.name));
            setCandidate({ ...candidate, file, nameFile })
            setLoadDropZone(false)

        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
            setLoadDropZone(true)
        }

    }

    async function saveCandidate(){
        // alert(candidate.file )
        if(id !== undefined) {
            if(
                canditateUp?.originalname === null &&
                candidate.file === null
            ) {
                return
            }
            else {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
    
                if(candidate?.name) {
                    formData.append('name', candidate.name);
                }

                if(dateOfBirthUpdate?.dateOfBirth) {
                    formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
                }

                if(candidate?.sector) {
                    formData.append('sector', candidate.sector);
                }

                if(candidate?.laborBond) {
                    formData.append('laborBond', candidate.laborBond);
                }

                if(candidate?.phone) {
                    formData.append('phone', candidate.phone);
                }

                if(candidate?.email) {
                    formData.append('email', candidate.email);
                }

                if(candidate?.zipcode) {
                    formData.append('zipcode', candidate.zipcode);
                }

                if(candidate?.address) {
                    formData.append('address', candidate.address);
                }

                if(candidate?.addressNeighborhood) {
                    formData.append('addressNeighborhood', candidate.addressNeighborhood);
                }

                if(candidate?.addressCity) {
                    formData.append('addressCity', candidate.addressCity);
                }

                if(candidate?.addressState) {
                    formData.append('addressState', candidate.addressState);
                }

                if(candidate?.file) {
                    formData.append('file', candidate.file);
                }

                if(candidate?.addressNumber) {
                    formData.append('addressNumber', candidate.addressNumber);
                }
                if(candidate?.portfolio) {
                    formData.append('portfolio', candidate.portfolio);
                }
                if(candidate?.facebook) {
                    formData.append('facebook', candidate.facebook);
                }
                if(candidate?.linkedin) {
                    formData.append('linkedin', candidate.linkedin);
                }
                formData.append('path', path);

                // return
                setLoadButtomSaveCandidate(true)
                await api.put(`/candidate/${id}`, formData, config)
                .then(response => {
                    setLoadButtomSaveCandidate(false);
                    window.location.href =  `/recrutamento/perfil-candidato/${response.data._id}`;

                }).catch(error => {
                    setLoadButtomSaveCandidate(false);
                });
            }
        }
        else
        if(

            candidate?.name === '' ||
            candidate?.name === undefined
            ||
            candidate?.dateOfBirth === '' ||
            candidate?.dateOfBirth === undefined
            ||
            candidate?.sector === '' ||
            candidate?.sector === undefined
            ||
            candidate?.laborBond === '' ||
            candidate?.laborBond === undefined
            ||
            candidate?.phone === '' ||
            candidate?.phone === undefined
            ||
            candidate?.email === '' ||
            candidate?.email === undefined
            ||
            candidate?.zipcode === '' ||
            candidate?.zipcode === undefined
            ||
            candidate?.address === '' ||
            candidate?.address === undefined
            ||
            candidate?.addressNeighborhood === '' ||
            candidate?.addressNeighborhood === undefined
            ||
            candidate?.addressCity === '' ||
            candidate?.addressCity === undefined
            ||
            candidate?.addressState === '' ||
            candidate?.addressState === undefined
            ||
            candidate?.file === '' ||
            candidate?.file === undefined
        ) {

            if(candidate?.name === '' || candidate?.name === undefined){
                setLoadNameCandidate(true)
            }

            if(candidate?.dateOfBirth === '' || candidate?.dateOfBirth === undefined){
                setLoadDateOfBirth(true)
            }

            if(candidate?.sector === '' || candidate?.sector === undefined){
                setLoadSector(true)
            }

            if(candidate?.laborBond === '' || candidate?.laborBond === undefined){
                setLoadLaborBond(true)
            }

            if(candidate?.phone === '' || candidate?.phone === undefined){
                setLoadPhone(true)
            }

            if(candidate?.email === '' || candidate?.email === undefined){
                setLoadEmail(true)
            }

            if(candidate?.zipcode === '' || candidate?.zipcode === undefined){
                setLoadZipcode(true)
            }

            if(candidate?.address === '' || candidate?.address === undefined){
                setLoadAddress(true)
            }

            if(candidate?.addressNeighborhood === '' || candidate?.addressNeighborhood === undefined){
                setLoadAddressNeighborhood(true)
            }

            if(candidate?.addressCity === '' || candidate?.addressCity === undefined){
                setLoadCity(true)
            }

            if(candidate?.addressState === '' || candidate?.addressState === undefined){
                setLoadState(true)
            }

            if(candidate?.file === '' || candidate?.file === undefined){
                setLoadDropZone(true)
            }
            return

        }
        else {
            // alert('salvar')
            setLoadNameCandidate(false)
            setLoadDateOfBirth(false)
            setLoadSector(false)
            setLoadLaborBond(false)
            setLoadPhone(false)
            setLoadEmail(false)
            setLoadZipcode(false)
            setLoadAddress(false)
            setLoadAddressNeighborhood(false)
            setLoadCity(false)
            setLoadState(false)
            setLoadDropZone(false)

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('name', candidate.name);
            formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
            formData.append('sector', candidate.sector);
            formData.append('laborBond', candidate.laborBond);
            formData.append('phone', candidate.phone);
            formData.append('email', candidate.email);
            formData.append('zipcode', candidate.zipcode);
            formData.append('address', candidate.address);
            formData.append('addressNeighborhood', candidate.addressNeighborhood);
            formData.append('addressCity', candidate.addressCity);
            formData.append('addressState', candidate.addressState);
            formData.append('file', candidate.file);
            if(candidate?.addressNumber) {
                formData.append('addressNumber', candidate.addressNumber);
            }
            if(candidate?.portfolio) {
                formData.append('portfolio', candidate.portfolio);
            }
            if(candidate?.facebook) {
                formData.append('facebook', candidate.facebook);
            }
            if(candidate?.linkedin) {
                formData.append('linkedin', candidate.linkedin);
            }
            formData.append('path', path);



            setLoadButtomSaveCandidate(true)
            await api.post("/candidate/", formData, config)
            .then(response => {
                setLoadButtomSaveCandidate(false);
                window.location.href =  `/recrutamento/perfil-candidato/${response.data._id}`;
                // /recrutamento/perfil-candidato/${response.data._id}`

            }).catch(error => {
                setLoadButtomSaveCandidate(false);
            });



        }

    }

    function cancelCandidate(){

        const voltar_recrutamento_talentos = localStorage.getItem('@peopleview/voltar_recrutamento_talentos');

        if(voltar_recrutamento_talentos === null) {
            window.location.href = '/recrutamento/talentos/';
        }
        else {
            window.location.href = `${voltar_recrutamento_talentos}`;
        }

    }

    function removeAttachment(){

        let file     = null;
        let nameFile = null;
        setCandidate({ ...candidate, file, nameFile })
        setLoadDropZone(true)

        let originalname = null;

        setCanditateUp({ ...canditateUp, originalname });

    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                path={path}
                pathname={pathname}
                 />
                <div className='page-talents registre-candidate'>
                    <div className='formulario'>
                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Nome completo</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Nome completo'
                                        name='name'
                                        value={
                                            
                                                (candidate && candidate?.name)
                                                ||
                                                candidate?.name === '' ?
                                                candidate?.name
                                                : canditateUp && canditateUp?.name ?
                                                canditateUp.name:
                                                ''
                                        }
                                        className={loadNameCandidate ? 'inputErro' : ''}
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadNameCandidate ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>

                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Data de nascimento</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='DD/MM/AAAA'
                                        maxLength={10}
                                        name='dateOfBirth'
                                        value={
                                            
                                                (candidate && candidate?.dateOfBirth)
                                                ||
                                                candidate?.dateOfBirth === '' ?
                                                candidate?.dateOfBirth
                                                :
                                                canditateUp && canditateUp?.dateOfBirth ? 
                                                dateOfbirthSaved : ""
                                        }
                                        className={loadDateOfBirth ? 'inputErro' : ''}
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadDateOfBirth ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Setor</p>
                                </div>
                                <div className="intemB">
                                <div className="select-busca-btn">
                                    <input
                                        type="text"
                                        className={loadSector ? "menu-button inputErro" : "menu-button"}
                                        placeholder='Selecione'
                                        name="nameSector"
                                        value={
                                            candidate?.nameSector ?
                                            candidate?.nameSector :
                                            canditateUp && canditateUp.sector ?
                                                canditateUp.sector.name:
                                                ''
                                        }
                                        onClick={ SelectBuscaSector }
                                        onBlur={ () => (setIsActiveSelectBuscaSector(false)) }
                                        onChange={myChangeHandlerSelects}
                                    />
                                    <nav className={`menu ${isActiveSelectBuscaSector ? "ativo": ""}`} >
                                        <Scrollbars className='listDropDown'  style={{  height:
                                        sectorsFiltre.length === 0 ? 30 : "" ||
                                        sectorsFiltre.length === 1 ? 30 : ""  ||
                                        sectorsFiltre.length === 2 ? 70 : ""  ||
                                        sectorsFiltre.length === 3 ? 120 : 120
                                        }}>
                                            <ul>
                                                {
                                                    sectorsFiltre.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    sectorsFiltre
                                                    .map((sector, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={sector.name}
                                                            name="sector"
                                                            onClick={()=>sectorSelected(sector._id, sector.name)}
                                                            >{sector.name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                {loadSector ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                                </div>

                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Regime</p>
                                </div>
                                <div className="intemB">


                                    <div className="select-busca-btn">
                                        <button
                                            className={loadLaborBond ? "menu-button inputErro" : "menu-button"}
                                            onBlur={ () => (setIsActiveSelectLaborBond(false)) }
                                            onClick={ SelectLaborBond }
                                        >
                                        <span>
                                        {
                                            candidate?.laborBond ? 
                                                candidate?.laborBond === "PJ"  ? 
                                                "Pessoa Jurídica" : candidate?.laborBond 
                                             : 
                                            canditateUp && canditateUp.laborBond ?
                                                canditateUp.laborBond === "PJ"  ? 
                                                "Pessoa Jurídica" : canditateUp.laborBond 
                                            :
                                                'Selecione'
                                        }
                                        </span>
                                        </button>
                                        <nav className={`menu ${isActiveSelectLaborBond? "ativo": ""}`} >

                                            <Scrollbars className='listDropDown'  style={{  height: 100 }}>
                                                <ul>
                                                    {
                                                        [
                                                            "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "PJ", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                                        ].map((laborBondIntem, index) => (
                                                            <li key={index}>
                                                                <button
                                                                value={laborBondIntem}
                                                                name="laborBond"
                                                                onClick={()=>sectorlaborBond(laborBondIntem)}
                                                                >{laborBondIntem === "PJ"  ? "Pessoa Jurídica" : laborBondIntem}</button>
                                                            </li>
                                                        ))}
                                                </ul>

                                            </Scrollbars>
                                        </nav>
                                    </div>
                                    {loadLaborBond ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                    </div>

                            </div>
                        </div>
                        <h5>Contato</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Telefone</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    placeholder='Telefone'
                                    name='phone'
                                    className={loadPhone ? 'inputErro' : ''}
                                    value={
                                        (candidate && candidate?.phone)
                                        ||
                                        candidate?.phone === '' ?
                                        candidate?.phone
                                        :

                                        canditateUp && canditateUp.phone ?
                                        canditateUp.phone:
                                        ''
                                    }
                                    onChange={mychangeHandlerCandidate}
                                    />
                                    { loadPhone ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Email</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Email'
                                        name='email'
                                        className={loadEmail ? 'inputErro' : ''}
                                        value={
                                            (candidate && candidate?.email)
                                            ||
                                            candidate?.email === '' ?
                                            candidate?.email
                                            :
                                            canditateUp && canditateUp.email ?
                                            canditateUp.email:
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadEmail ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <h5>Endereço</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>CEP</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='CEP'
                                        name='zipcode'
                                        className={loadZipcode ? 'inputErro' : ''}
                                        value={
                                            (candidate && candidate?.zipcode)
                                            ||
                                            candidate?.zipcode === '' ?
                                            candidate?.zipcode
                                            :
                                            canditateUp && canditateUp.zipcode ?
                                            canditateUp.zipcode:
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadZipcode ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Número</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Número'
                                        name='addressNumber'
                                        value={
                                            (candidate && candidate?.addressNumber)
                                            ||
                                            candidate?.addressNumber === '' ?
                                            candidate?.addressNumber
                                            :
                                            canditateUp && canditateUp.addressNumber ?
                                            canditateUp.addressNumber:
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Rua ou avenida</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Rua ou avenida'
                                        name='address'
                                        className={loadAddress ? 'inputErro' : ''}
                                        value={
                                            (candidate && candidate?.address)
                                            ||
                                            candidate?.address === '' ?
                                            candidate?.address
                                            :
                                            canditateUp && canditateUp.address ?
                                            canditateUp.address:
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadAddress ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Bairro</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Bairro'
                                        name='addressNeighborhood'
                                        className={loadAddressNeighborhood ? 'inputErro' : ''}
                                        value={
                                            (candidate && candidate?.addressNeighborhood)
                                            ||
                                            candidate?.addressNeighborhood === '' ?
                                            candidate?.addressNeighborhood
                                            :
                                            canditateUp && canditateUp.addressNeighborhood ?
                                            canditateUp.addressNeighborhood:
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadAddressNeighborhood? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Estado</p>
                                </div>
                                <div className="intemB">
                                <input
                                    type="text"
                                    placeholder='Estado'
                                    name='addressState'
                                    className={loadState ? 'inputErro' : ''}
                                    value={
                                        (candidate && candidate?.addressState)
                                        ||
                                        candidate?.addressState === '' ?
                                        candidate?.addressState
                                        :
                                        canditateUp && canditateUp.addressState ?
                                        canditateUp.addressState:
                                        ''
                                    }
                                    onChange={mychangeHandlerCandidate}
                                />
                                    { loadState ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Cidade</p>
                                </div>
                                <div className="intemB">
                                <input
                                    type="text"
                                    placeholder='Cidade'
                                    name='addressCity'
                                    className={loadCity ? 'inputErro' : ''}
                                    value={
                                        (candidate && candidate?.addressCity)
                                        ||
                                        candidate?.addressCity === '' ?
                                        candidate?.addressCity
                                        :
                                        canditateUp && canditateUp.addressCity ?
                                        canditateUp.addressCity:
                                        ''
                                    }
                                    onChange={mychangeHandlerCandidate}
                                />
                                    { loadCity? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <h5>Links</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Portfolio</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Link do portfolio'
                                        name='portfolio'
                                        value={
                                            candidate && candidate?.portfolio ?
                                            candidate?.portfolio
                                            :
                                            canditateUp && canditateUp.portfolio ?
                                            canditateUp.portfolio:
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>LinkedIn</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    placeholder='Link do LinkedIn'
                                    name='linkedin'
                                    value={
                                        candidate && candidate?.linkedin ?
                                        candidate?.linkedin
                                        :
                                        canditateUp && canditateUp.linkedin ?
                                        canditateUp.linkedin:
                                        ''
                                    }
                                    onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Facebook</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    placeholder='Link do facebook'
                                    name='facebook'
                                    value={
                                        candidate && candidate?.facebook ?
                                        candidate?.facebook
                                        :
                                        canditateUp && canditateUp.facebook ?
                                        canditateUp.facebook:
                                        ''
                                    }
                                    onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeOne dropzone-campo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Anexar currículo</p>
                                </div>
                                <div className="intemB">
                                    <Dropzone onDrop={handleDrop} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className={laodDropZone ? "imgDocLabel inputErro" : "imgDocLabel"}><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                        {
                            candidate?.nameFile ?
<>
                            <div className="linha-typeOne">
                                <div className='bloco'>
                                    <div className="intemA">
                                    </div>
                                    <div className="intemB">

                                        <div className="ArquivosEnviados">
                                            <div  className="arquivos">
                                                <div className="nameArquivo">
                                                    <p>
                                                        {candidate?.nameFile}
                                                    </p>

                                                </div>
                                                <div className="btnExclui">
                                                    <button onClick={()=>removeAttachment()} >Excluir anexo</button >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </>
                            :
                            canditateUp && canditateUp?.originalname ?
                            
                            <div className="linha-typeOne">
                                <div className='bloco'>
                                    <div className="intemA">
                                    </div>
                                    <div className="intemB">

                                        <div className="ArquivosEnviados">
                                            <div  className="arquivos">
                                                <div className="nameArquivo">
                                                    <a href={canditateUp.curriculum} target="’_blank’">{canditateUp.originalname}</a>
                                                </div>
                                                <div className="btnExclui">
                                                    <button onClick={()=>removeAttachment()} >Excluir anexo</button >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            
                            : 
                            ''
                        }
                    </div>
                </div>
                <div className='page-talents-btns'>
                    <button onClick={cancelCandidate} className='cancelar btnGray'>Cancelar</button>
                    <button onClick={saveCandidate} className={loadButtomSaveCandidate ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                </div>
            </Layout>
        </>
    );
}

