import React from 'react';
//import EditBlack from '../../../../../assets/images/edit-black.svg';
import SubMenu from './subMenu';
import TermContract from './Documents/TermContract';
import PeriodicExam from './Documents/PeriodicExam';
import { decodeToken } from '../../../../../services/auth';

const ColProfileProficionalDoc = ({ id, path }) => {

    const { user } = decodeToken();
    const role     = user.role;

    return (
        <>
            <SubMenu id={id} role={role} path={path}/>
            <div className="box-infor-perfil box-infor-prof-doc">
                <TermContract id={id} path={path} />
                <PeriodicExam id={id} path={path} />

            </div>
        </>
    );
}
export default ColProfileProficionalDoc;
