import React from 'react';
// import IconsInformation from '../../IconsInformation';
import HeaderAdmin from '../../HeaderAdmin';
import { NavLink } from 'react-router-dom'
export default function headerCadastraFeriasColetiva ({
    history,
    notifications,
    showMore,
    notificationsRead,
    readTrue,
    indexUpdate,
}) {

        return (
            <>
            <HeaderAdmin>
                <div>

                <h1>
                    <NavLink to="/ferias/" className="voltar">
                        Voltar
                    </NavLink>
                    Solicitação de férias
                </h1>
                </div>
                
            </HeaderAdmin>

                {/* <header className="header-geral">
                    <div className="cabeçalho">
                        <h1>
                            <NavLink to="/ferias/" className="voltar">
                                Voltar
                            </NavLink>
                            Solicitação de férias</h1>
                    </div>
                    <div className="infor">
                        <IconsInformation
                        history={history}
                        notifications={notifications}
                        showMore={showMore}
                        notificationsRead={notificationsRead}
                        readTrue={readTrue}
                        indexUpdate={indexUpdate}
                        />
                    </div>

                </header> */}
            </>
        );
}
