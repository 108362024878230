import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../components/Layouts/defaultUser';
import Calendario from '../../assets/images/calendar-chat.svg';
import {  momentDurationToMessageCommunication } from '../../services/utils';
import io from 'socket.io-client';
import { decodeToken } from '../../services/auth';
import { NavLink } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';

export default function UserCommunication(props) {

    const decoded = decodeToken();
    const { path } = props.match;

    // console.log(decoded)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ conversa, setConversa] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ idComunication, setIdComunication] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ communicationUser, setCommunicationUser] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // setLoadPage(true)
        loadmessagecommunication();


        async function registerToSocket() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            socket.on("messagecommunication", () => {
                loadmessagecommunication();
            });
        }
        registerToSocket()



    }, []);


    async function loadmessagecommunication() {
        await api.get('/message-communication-user/')
        .then(response => {

            if(response.data.length !== 0) {

                setConversa(response.data);
                setIdComunication(response.data[0].communication)

                async function loadcommunicationuser() {

                    await api.get(`/collaborator-communication-show/${response.data[0].communication}`)
                    .then(response => {

                        setCommunicationUser(response.data);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadcommunicationuser();

            }



        }).catch(error => {
            setCommunicationUser([]);
        })
    }
    //  console.log(idComunication)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ communicationUserToRH, setCommunicationUserToRH] = useState([]);

    useEffect(() => {

        async function loadcommunicationuser() {

            await api.get('/collaborator-access-sector/?sector=Recursos%20Humanos')
            .then(response => {

                setCommunicationUserToRH(response.data[0]);

            }).catch(error => {
                setCommunicationUserToRH([])
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            })
        }
        loadcommunicationuser();

    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ mensagem, setMensagem] = useState([]);

    const myChangeHandlerMessage = event => {
        let nam = event.target.name;
        let val = event.target.value;
        setMensagem({ ...mensagem, [nam]: val })
    };

    async function handleSubmitMensagem(event) {
        event.preventDefault();

    if (mensagem.message === "" ||
        mensagem.message === undefined) {

            // swal({ icon: "error", title: "Erro!", text:  'Nenhum campo dode ser salvo em branco!'});
        } else {

            if(idComunication.length !== 0) {

                let communication = idComunication;
                let message       = mensagem.message;
                await api.post('/message-communication-user/', { communication, message })
                .then(response => {
                    setMensagem([])
                    async function loadmessagecommunication() {
                        await api.get('/message-communication-user/')
                        .then(response => {
                            setConversa(response.data);
                        }).catch(error => {})
                    }
                    loadmessagecommunication();
                }).catch(error => {
                    // swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });

            }
            else {
                let admin = communicationUserToRH._id;
                let name  = decoded.user.name;
                await api.post('/collaborator-communication-user/', { admin, name })
                .then(response => {

                    let communication = response.data._id;
                    let message       = mensagem.message;
                    async function loadmessagecommunication() {

                        await api.post('/message-communication-user/', { communication, message })
                        .then(response => {
                            setMensagem([])
                            async function loadmessagecommunication() {
                                await api.get('/message-communication-user/')
                                .then(response => {

                                    if(response.data.length !== 0) {

                                        setConversa(response.data);
                                        setIdComunication(response.data[0].communication)

                                        async function loadcommunicationuser() {

                                            await api.get(`/collaborator-communication-show/${response.data[0].communication}`)
                                            .then(response => {

                                                setCommunicationUser(response.data);

                                            }).catch(error => {
                                                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                            })
                                        }
                                        loadcommunicationuser();

                                    }

                                }).catch(error => {})
                            }
                            loadmessagecommunication();
                            // swal({ icon: "success", title: "Sucesso!", text: "mensagem enviada." });
                        }).catch(error => {
                            // swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        });

                    }
                    loadmessagecommunication();


                }).catch(error =>{})

            }

        }
    }
    return (
        <>
            <Layout path={path}>
                <div className="user-contato">
                    <div className="box-infor-gestor-rh">
                        {
                            communicationUser && communicationUser.admin ?
                            <>
                                <img src={
                                communicationUser && communicationUser.admin && communicationUser.admin.avatar ?
                                communicationUser.admin.avatar
                                :
                                communicationUserToRH && communicationUserToRH.avatar ?
                                communicationUserToRH.avatar
                                :
                                ''
                                } alt="" className="foto-gestor"/>
                            <div className="nome-setor-infor">
                                <h3>{
                                    communicationUser && communicationUser.admin && communicationUser.admin.name ?
                                    communicationUser.admin.name
                                    :
                                    communicationUserToRH && communicationUserToRH.name ?
                                    communicationUserToRH.name
                                    :
                                    ''
                                }</h3>
                                <span>{
                                    communicationUser && communicationUser.admin && communicationUser.admin.sector ?
                                    communicationUser.admin.sector
                                    :
                                    communicationUserToRH && communicationUserToRH.sector ?
                                    communicationUserToRH.sector
                                    :
                                    ''
                                }</span>
                                <p>Este é seu contato no RH. Fale direto com ele(a) :)</p>
                            </div>
                            </>
                            :
                            communicationUserToRH && communicationUserToRH.length !== 0 ?
                            <>
                                <img src={
                                communicationUserToRH && communicationUserToRH.avatar ?
                                communicationUserToRH.avatar
                                :
                                ''
                                } alt="" className="foto-gestor"/>
                            <div className="nome-setor-infor">
                                <h3>{
                                    communicationUserToRH && communicationUserToRH.name ?
                                    communicationUserToRH.name
                                    :
                                    ''
                                }</h3>
                                <span>{
                                    communicationUserToRH && communicationUserToRH.sector ?
                                    communicationUserToRH.sector
                                    :
                                    ''
                                }</span>
                                <p>Este é seu contato no RH. Fale direto com ele(a) :)</p>
                            </div>
                            </>
                            :
                            'Não existe ninguém com cargo de Recursos Humanos até o momento!'
                        }


                    </div>
                    <div className="chat-company">
                            <div  className="box-left">
                                <div className="header">
                                    {
                                        communicationUser && communicationUser.admin ?
                                        <h3>Conversar com {
                                            communicationUser && communicationUser.admin && communicationUser.admin.name ?
                                            communicationUser.admin.name
                                            :
                                            communicationUserToRH && communicationUserToRH.name ?
                                            communicationUserToRH.name
                                            :
                                            ''
                                        }</h3>
                                        :
                                        communicationUserToRH && communicationUserToRH.length !== 0 ?
                                        <h3>Conversar com {
                                            communicationUserToRH && communicationUserToRH.name ?
                                            communicationUserToRH.name
                                            :
                                            ''
                                        }</h3>
                                        :
                                        <h3>Ninguém para conversar aqui!</h3>
                                    }
                                </div>


                        {/* <div className="nenhuma-conversa">
                            <p>Nenhuma mensagem. <br/> Inicie uma conversa com o seu gestor abaixo.</p>
                            <img src={NenhumaConversa} alt="" />

                        </div> */}
                        <div className="conversation-overflow">
                            <div className="conversation">
                            {
                                conversa[0] !== undefined ? conversa
                                .map((conversaList, index) => (
                                <>
                                {
                                conversaList.title && conversaList.title ?
                                <>

                                    <div className="Sent">
                                    <NavLink to={
                                        decoded.user._id === conversaList.collaborator._id ?
                                        `/meus-dados/pessoal/faltas-e-afastamentos`
                                        :
                                        `/colaboradores/${conversaList.collaborator._id}/pessoal/faltas-e-afastamentos`

                                    } className="automatic-message">
                                            <div className="left">
                                                <h5>NOVA FALTA E AFASTAMENTO</h5>
                                                <div className="infor">
                                                    <span>“</span><p> {conversaList.message}</p>
                                                </div>

                                                <span className="hora not-seen">Mensagem automática enviada {momentDurationToMessageCommunication(conversaList.createdAt)}</span>
                                            </div>
                                            <div className="right">
                                                <img src={Calendario} alt="" />
                                            </div>
                                    </NavLink>
                                    </div>
                                </>
                                : decoded.user._id === conversaList.collaborator._id ? (
                                            <>
                                                <div className="Sent">
                                                    <p className="message">{conversaList.message}
                                                    <span className={conversaList.read === true ? "viewed" : "not-seen"}>{momentDurationToMessageCommunication(conversaList.createdAt)}</span>
                                                    </p>
                                                </div>
                                            </>
                                    ) : decoded.user._id !== conversaList.collaborator._id ? (
                                        <>
                                            <div className="Received" >
                                                <p className="message">{conversaList.message}
                                                <span className="not-seen">{momentDurationToMessageCommunication(conversaList.createdAt)}</span>
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    ) }

                                    </>
                                    )) : (
                                    <>
                                    </>
                                )
                            }
                            </div>
                        </div>

                        <div className="form-conversation">
                                <input type="text" name="message"   value={(mensagem.message !== undefined) ? mensagem.message : ''}  placeholder="Envie uma mensagem" onChange={myChangeHandlerMessage}/>
                            <form onSubmit={handleSubmitMensagem} >

                                    <button disabled={
                                        communicationUser            &&
                                        communicationUser.admin       ?
                                        false                         :
                                        communicationUserToRH        &&
                                        communicationUserToRH.length !== 0 ?
                                        false                         :
                                        true
                                        } className="enviar"></button>

                                </form>
                        </div>
                        </div>



                        <div className="company-data">
                            <h2>Dados da empresa</h2>
                            {
                                decoded.user.companyId?.logo ?
                                <img src={decoded.user.companyId.logo} alt={decoded.user.companyId.name}  className="img-company"/>
                                :"Nome da empresa:"
                            }
                            <h3>{decoded.user.companyId.name}</h3>
                            <span>{decoded.user.companyId.nameDescription}</span>
                            {
                                decoded.user.companyId?.cnpj ?
                                <span>CNPJ: {decoded.user.companyId.cnpj && decoded.user.companyId.cnpj ? decoded.user.companyId.cnpj : "-"}</span>
                                :""
                            }

                            {
                                decoded.user.companyId?.zipcode ||
                                decoded.user.companyId?.addressCity ||
                                decoded.user.companyId?.address ?
                                <h4>Endereço</h4>
                                :""

                            }
                            {
                                decoded.user.companyId?.address ?
                                <span>{decoded.user.companyId.address}, {decoded.user.companyId?.addressNumber}</span>
                                :""
                            }
                            {
                                decoded.user.companyId?.addressCity ?
                                <span>{decoded.user.companyId.addressCity} - {decoded.user.companyId.addressState}</span>
                                :""
                            }
                            {
                                decoded.user.companyId?.zipcode ?
                                <span>CEP: {decoded.user.companyId.zipcode}</span>
                                :""
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
