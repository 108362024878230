import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import JobInformation from './work/JobInformation';
import BondSalary from './work/BondSalary';
import InternInformation from './work/InternInformation';
import ExperiencePeriod from './work/ExperiencePeriod';
import api from '../../../../../services/api';
import { toast } from 'react-toastify';



export default function ColProfileProssional({
    id,
    path,
    collaborator,
    loadPage ,
    loadPageJobInformation,
    loadPage2Collaborator,
    loadAdmissionDate,
    loadJobInformation,
    workInfor,
    collaboratorInfor,
    registeredWorkInfor,
    registeredCollaborator,
    AddAndJobInformationVisible,
    myChangeHandlerJobInfor,
    handleSubmitJobInfor,
    managers,
    visibleJobInfor,
    role,
    JobInforFution,
    selectManager,
    selectRole,
    selectAdmissionType,
    selectFormOfPayment,
    selectStability,
    selectSyndicate,
    selectPositionOfTrust,
    selectFirstJob,
    selectUnemploymentInsurance,
    selectRetiree,
    selectRegistrationWithTheProfessionalBody,
    selectProfessionalAdvice,
    selectCIPA,
    selectHavePointsRecord,
    selectSalaryType,
    setWorkInfor
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredBondAndSalary, setRegisteredBondAndSalary ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

       if(id && id) {loadBondAndSalary(id);
}
    }, [id]);

    async function loadBondAndSalary(id) {

        await api.get(`collaborator-bond-and-salary/${id}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredBondAndSalary(response.data[0]);
            }
            else {
                setRegisteredBondAndSalary([]);
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

        return (
        <>
            <SubMenu id={id} collaborator={collaborator} role={role} path={path}/>
            <div className="box-infor-perfil profile-infor-profissional">
                <JobInformation
                    id={id}
                    collaborator={collaborator}
                    collaboratorState={collaborator}
                    loadPageJobInformation={loadPageJobInformation}
                    loadPage2Collaborator={loadPage2Collaborator}
                    loadAdmissionDate={loadAdmissionDate}
                    loadJobInformation={loadJobInformation}
                    workInfor={workInfor}
                    collaboratorInfor={collaboratorInfor}
                    registeredWorkInfor={registeredWorkInfor}
                    registeredCollaborator={registeredCollaborator}
                    AddAndJobInformationVisible={AddAndJobInformationVisible}
                    myChangeHandlerJobInfor={myChangeHandlerJobInfor}
                    handleSubmitJobInfor={handleSubmitJobInfor}
                    managers={managers}
                    visibleJobInfor={visibleJobInfor}
                    JobInforFution={JobInforFution}
                    selectManager={selectManager}
                    selectAdmissionType={selectAdmissionType}
                    selectRole={selectRole}
                    selectFormOfPayment={selectFormOfPayment}
                    selectSyndicate={selectSyndicate}
                    selectStability={selectStability}
                    selectPositionOfTrust={selectPositionOfTrust}
                    selectFirstJob={selectFirstJob}
                    selectUnemploymentInsurance={selectUnemploymentInsurance}
                    selectRetiree={selectRetiree}
                    selectRegistrationWithTheProfessionalBody={selectRegistrationWithTheProfessionalBody}
                    selectProfessionalAdvice={selectProfessionalAdvice}
                    selectCIPA={selectCIPA}
                    selectHavePointsRecord={selectHavePointsRecord}
                    selectSalaryType={selectSalaryType}
                    role={role}
                    setWorkInfor={setWorkInfor}
                />
                <BondSalary
                id={id}
                path={path}
                collaborator={collaborator}
                collaboratorState={collaborator}
                loadPageJobInformation={loadPageJobInformation}
                loadPage2Collaborator={loadPage2Collaborator}
                loadAdmissionDate={loadAdmissionDate}
                loadJobInformation={loadJobInformation}
                workInfor={workInfor}
                collaboratorInfor={collaboratorInfor}
                registeredWorkInfor={registeredWorkInfor}
                registeredCollaborator={registeredCollaborator}
                AddAndJobInformationVisible={AddAndJobInformationVisible}
                myChangeHandlerJobInfor={myChangeHandlerJobInfor}
                handleSubmitJobInfor={handleSubmitJobInfor}
                managers={managers}
                visibleJobInfor={visibleJobInfor}
                role={role}
                />
                {

                    (role && role === 'admin' && registeredBondAndSalary.length !== 0 && registeredBondAndSalary.bond === "Estágio")
                    ||
                    (role && role === 'master' && registeredBondAndSalary.length !== 0 && registeredBondAndSalary.bond === "Estágio") ?
                    <>
                        <InternInformation
                        id={id}
                        collaborator={collaborator}
                        path={path}
                        />
                    </>
                    :
                    ''
                }
                <ExperiencePeriod
                id={id}
                path={path}
                collaborator={collaborator}
                loadPage={loadPage}
                role={role}
                />
            </div>
        </>
    );
}

