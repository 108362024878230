import React, {useEffect, useState} from 'react';
import { Modal } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import Close from '../../assets/images/close.svg';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import Carregando from '../../assets/images/carregando.gif';
import api from '../../services/api';
import { dateMask, mNumber } from '../../services/mask';
import { compareDateToAddVacation, dataFormatadaParaBarra } from '../../services/utils'
import swal from 'sweetalert';
import { dateFormatedToShutDown } from '../../services/utils';
export default function AddModalVacation({
    onHide,
    show,
    idCol,
    startLoadSave,
}) {

    const [startVestingPeriodsShow, setStartVestingPeriodsShow] = useState(false);
    const [anoSelecionado, setAnoSelecionado] = useState("");
    const [loadcollaboratorVestingPeriod, setLoadcollaboratorVestingPeriod] = useState(false);
    const [loadCollaborator, setLoadCollaborator] = useState(false);
    const [ collaborator, setCollaborator ] = useState([]);
    const [collaboratorVestingPeriod, setCollaboratorVestingPeriod] = useState([]);
    useEffect(() => {
        if(idCol){
            loadCollaboratorVestingperiod(idCol)
            loadcollaborator(idCol) 
        }
    }, [idCol]);

    async function loadcollaborator(idCol) {
        try {
            setLoadCollaborator(true)
            const response = await api.get(`/collaborator/${idCol}`);
            setCollaborator(response.data);
        } catch (error) { console.log(error);}
        finally {setLoadCollaborator(false) }
    }

    async function loadCollaboratorVestingperiod(idCol) {
        try {
            setLoadcollaboratorVestingPeriod(true);
            const response = await api.get(`/company-vesting-period?page=0&collaborator=${idCol}`);
            setCollaboratorVestingPeriod(response.data.collaboratorVestingPeriodSearch);
        } catch (error) {console.log(error);} finally {
            setLoadcollaboratorVestingPeriod(false);
        }
    }
    function fecharDetalhesAno() {
        setAnoSelecionado("")
    }
    const [loadInforRequisition, setLoadInforRequisition] = useState(false);
    const [anoSelecionadoInfor, setAnoSelecionadoInfor] = useState([]);
    const [anoSelecionadoInforAprovaod, setAnoSelecionadoInforAprovaod] = useState([]);
    function VerDetalhesAno(e) {
        setAnoSelecionado(e.referenceYear)
        async function DetalheAnoSelecionado() {
            setLoadInforRequisition(true)
            await api.get(`collaborator-vacation-new?collaborator=${e.collaborator._id}&vestingPeriodReferenceYear=${e.referenceYear}&type=individual`)
            .then(response => {

                setAnoSelecionadoInfor(response.data.collaboratorVacationNewSearch)

            }).catch(error => {});

            await api.get(`collaborator-vacation-new?collaborator=${e.collaborator._id}&vestingPeriodReferenceYear=${e.referenceYear}&status=aprovado&type=individual`)
            .then(response => {

                setAnoSelecionadoInforAprovaod(response.data.collaboratorVacationNewSearch)

            }).catch(error => {});
            setLoadInforRequisition(false)

        }
        DetalheAnoSelecionado()

    }
    const [ loadBtnR, setLoadBtnR ] = useState(false);
    const [ loadErrorInitialDate, setLoadErrorInitialDate  ] = useState(false);
    const [ loadErrorFinalDate, setLoadErrorFinalDate ] = useState(false);
    const [ loadErrorVacationDaysSold,  setLoadErrorVacationDaysSold] = useState(false);
    const [ loadErrorVacationDays,  setLoadErrorVacationDays] = useState(false);
    const [ vacationSummaryR, setVacationSummaryR ] = useState([]);
    const [ vacationSummary, setVacationSummary ] = useState([]);
    function IniciaFeriasColl(e) {
        setVacationSummary(e)
        setVacationSummaryR({
            ...vacationSummaryR,
            collaborator: e.collaborator._id,
            companyId: e.companyId,
            vestingPeriodReferenceYear: e.referenceYear,
            status: 'aprovado'
        });
        setStartVestingPeriodsShow(true)
    }

    async function myChangeHandlerVaRetroactive(event) {
        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "startDate") {

           if(val.length === 10) {

               let startDate = dateMask(val)
               let startDateSplit = startDate.split('/')
               let startDateTransform = new Date(startDateSplit[2]+'-'+startDateSplit[1]+'-'+startDateSplit[0])
               setVacationSummaryR({ ...vacationSummaryR, [nam]: startDate, startDateTransform })
               setLoadErrorInitialDate(false)

           }
           else {

               let startDate = dateMask(val)
               setVacationSummaryR({ ...vacationSummaryR, [nam]: startDate })
               setLoadErrorInitialDate(false)

           }


       }
       else if (nam === "endDate") {

           if(val.length === 10) {

               let endDate = dateMask(val)
               let endDateSplit = endDate.split('/')
               let endDateTransform = new Date(endDateSplit[2]+'-'+endDateSplit[1]+'-'+endDateSplit[0])
               setVacationSummaryR({ ...vacationSummaryR, [nam]: endDate, endDateTransform })
               setLoadErrorInitialDate(false)

           }
           else {

               let endDate = dateMask(val)
               setVacationSummaryR({ ...vacationSummaryR, [nam]: endDate })
               setLoadErrorFinalDate(false)

           }

       }
       else if (nam === "vacationDaysSold") {

           let vacationDaysSold = mNumber(val)
           setVacationSummaryR({ ...vacationSummaryR, [nam]: vacationDaysSold })
           setLoadErrorVacationDaysSold(false)

       }
       else if (nam === "vacationDays") {

           let vacationDays = mNumber(val)
           setVacationSummaryR({ ...vacationSummaryR, [nam]: vacationDays })
           setLoadErrorVacationDays(false)

       }
       else {

           setVacationSummaryR({ ...vacationSummaryR, [nam]: val })

       }
    }
    async function saveAddVacation() {
        if(
            vacationSummaryR !== undefined && 
            vacationSummaryR.endDate !== undefined &&
            vacationSummaryR?.startDate !== undefined &&
            vacationSummaryR.vacationDaysSold !== undefined  &&
            vacationSummaryR.vacationDays !== undefined
        ) {

            let validator = compareDateToAddVacation(vacationSummary.limitConcessionPeriod, vacationSummaryR.endDate)
            let objectSaveVacation = {
                collaborator              : vacationSummaryR.collaborator,
                companyId                 : vacationSummaryR.companyId,
                category                  : 'vacation',
                vestingPeriodReferenceYear: vacationSummaryR.vestingPeriodReferenceYear,
                status                    : vacationSummaryR.status,
                startDate                 : vacationSummaryR.startDateTransform,
                endDate                   : vacationSummaryR.endDateTransform,
                vacationDaysSold          : vacationSummaryR.vacationDaysSold,
                vacationDays              : vacationSummaryR.vacationDays,
                comments                  : vacationSummaryR?.comments,
            }

            setLoadBtnR(true)
            if(
                vacationSummaryR.startDate !== undefined ||
                vacationSummaryR.endDate !== undefined ||
                vacationSummaryR.vacationDaysSold !== undefined ||
                vacationSummaryR.vacationDays !== undefined
            ) {
                if(validator === 0) {
                    await api.post('/collaborator-vacation-new/', objectSaveVacation)
                    .then(response => {
                        async function collaboratorVestingPeriodCalculateFisrtBalance() {
                            // => Atualiza todos os saldos dos períodos aquisitivos
                            await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                                companyId   : response.data.companyId,
                                collaborator: response.data.collaborator
                            })
                            .then(response => {

                                setVacationSummaryR()
                                setLoadBtnR(false)
                                startLoadSave()
                                swal({ icon: "success", title: "Sucesso!", text: "Adicionado histórico de férias com sucesso!" });

                            }).catch(error => {
                                swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar o histórico de férias." });

                            });

                        }
                        collaboratorVestingPeriodCalculateFisrtBalance()



                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadBtnR(false)
                    });

                }
                else {
                    swal({
                        title: "Atenção",
                        text: "Você está prestes a lançar um histórico de férias que ultrapassa a data do limite do benefício, você tem certeza que deseja continuar com está ação?",
                        icon: "warning",
                        buttons: ["Cancelar", "OK"],
                        dangerMode: false,
                    })
                    .then(async (res) => {

                        if (res) {
                            await api.post('/collaborator-vacation-new/', objectSaveVacation)
                            .then(response => {


                                async function collaboratorVestingPeriodCalculateFisrtBalance() {

                                    // => Atualiza todos os saldos dos períodos aquisitivos
                                    await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                                        companyId   : response.data.companyId,
                                        collaborator: response.data.collaborator
                                    })
                                    .then(response => {

                                        setVacationSummaryR()
                                        setLoadBtnR(false)
                                        loadCollaboratorVestingperiod(idCol)

                                        startLoadSave()
                                        swal({ icon: "success", title: "Sucesso!", text: "Adicionado histórico de férias com sucesso!" });

                                    }).catch(error => {
                                        
                                        swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });

                                    });

                                }
                                collaboratorVestingPeriodCalculateFisrtBalance()



                            }).catch(error => {
                                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                                setLoadBtnR(false)
                            });

                        }
                    });


                }



            } else {
                setLoadBtnR(false)
                return
            }
        }
        else {
            if (vacationSummaryR && vacationSummaryR?.startDate === undefined) {
                setLoadErrorInitialDate(true)
            }

            if (vacationSummaryR && vacationSummaryR.endDate === undefined) {
                setLoadErrorFinalDate(true)
            }

            if (vacationSummaryR && vacationSummaryR.vacationDaysSold === undefined) {
                setLoadErrorVacationDaysSold(true)
            }

            if (vacationSummaryR && vacationSummaryR.vacationDays === undefined) {
                setLoadErrorVacationDays(true)
            }
            return
        }



    }
    return (
        <>
         <Modal 
            show={show}
            className="popup-geral popup-vacation-add"
            onHide={onHide}>
                <div className="titulo">
                    <h4>
                        { startVestingPeriodsShow ? "Adicionar férias" : "Detalhes" }
                         <p className="btn-fechar" onClick={onHide}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className="infor-cal">
                    { 
                        loadCollaborator ? <img alt="carregando" src={Carregando} className="img-load"/> :
                        collaborator?.avatar ?
                            <div className="img-col"><div className="nome"><img src={collaborator?.avatar} alt="nome" /></div></div>
                            :
                            <div className="nome"><div className="semImgCol"><img src={IconUserNone} alt="coll" /></div></div>
                    }
                    <div className="nome-setor">
                        <div className="nome">
                            <span>
                                {   
                                    loadCollaborator ? <Skeleton count={1} height={15} width={100} borderRadius={4} />: 
                                    collaborator?.name ??   '-'
                                }
                            </span>
                        </div>
                        <div className="setor">
                            <p>
                                {  
                                    loadCollaborator ? <Skeleton count={1} height={10} width={100} borderRadius={4} />:
                                    collaborator?.occupation ?? '-'
                                }
                            </p>
                        </div>
                    </div>
                    <div className="data-admissao">
                        Data de admissão
                            { 
                                loadCollaborator ? <Skeleton count={1} height={15} width={50} borderRadius={4} />:
                                <span>{collaborator?.admissionDate ??'-'}</span>
                            }
                       
                    </div>
                </div>
                {
                    startVestingPeriodsShow ? 
                    <>
                    <div className='radio-verificacao-ano'>
                        <div className='list-ferias-naoAtualizada'>
                            <div className='item head'>
                                <p className='referencia'>Referência</p>
                                <p className='periodo'>Período aquisitivo</p>
                                <p className='ultilizado'>Utilizado</p>
                                <p className='saldo'>Saldo</p>
                            </div>

                            <div className='item body'>
                                <div className="referencia">
                                    <p className="texto"> {vacationSummary?.referenceYear} </p>
                                </div>
                                <p className='periodo'>{dateFormatedToShutDown(vacationSummary?.startDate)} à {dateFormatedToShutDown(vacationSummary?.endDate)} </p>
                                <p className='ultilizado'>{vacationSummary?.daysUsed}</p>
                                <p className='saldo'>{vacationSummary?.vacationDays}</p>
                            </div>
                        </div>
                    </div>

                    <ul className="ul-form">
                        <li>
                            <div className="intemA">
                                <p>De *</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="startDate" value={vacationSummaryR && vacationSummaryR.startDate !== undefined ? vacationSummaryR.startDate : ''}    placeholder="DD/MM/AAAA" onChange={myChangeHandlerVaRetroactive} className={loadErrorInitialDate ? 'inputErro' : ''}  />
                                {loadErrorInitialDate ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Até *</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="endDate" value={vacationSummaryR && vacationSummaryR.endDate !== undefined ? vacationSummaryR.endDate : ''} onChange={myChangeHandlerVaRetroactive}  placeholder="DD/MM/AAAA"  className={loadErrorFinalDate ? 'inputErro' : ''}  />
                                {loadErrorFinalDate ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Dias de abono *</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="vacationDaysSold" maxLength="2" value={vacationSummaryR && vacationSummaryR.vacationDaysSold !== undefined ? vacationSummaryR.vacationDaysSold : ''}   onChange={myChangeHandlerVaRetroactive} placeholder="Dias de abono" className={loadErrorVacationDaysSold ? 'inputErro' : ''} />
                                {loadErrorVacationDaysSold ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Dias de férias *</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="vacationDays" maxLength="2" value={vacationSummaryR && vacationSummaryR.vacationDays !== undefined ? vacationSummaryR.vacationDays : ''} onChange={myChangeHandlerVaRetroactive} placeholder="Dias de férias"className={loadErrorVacationDays ? 'inputErro' : ''}  />
                                {loadErrorVacationDays ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Justificativa</p>
                            </div>
                            <div className="intemB">
                                <textarea name="comments" value={vacationSummaryR && vacationSummaryR.comments !== undefined ? vacationSummaryR.comments : ''} cols="150" onChange={myChangeHandlerVaRetroactive} placeholder="Justificativa..."></textarea>
                            </div>
                        </li>
                    </ul>

                    <div className="btn-salva">
                            <button
                             onClick={() => saveAddVacation()} 
                             className={loadBtnR  ? 'btnGreen load save' : 'btnGreen save'}
                             >Salvar histórico</button>
                        <button className="cancelar" onClick={onHide}>Cancelar</button>
                    </div>
                    
                    </>:
                    <>
                        <div className='lista-anos'>
                            <div className='lista-anos'>
                                {
                                    loadcollaboratorVestingPeriod ?
                                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                    :
                                    collaboratorVestingPeriod && collaboratorVestingPeriod?.lenght !== 0 ?
                                    collaboratorVestingPeriod
                                    .filter(vestingPeriod => vestingPeriod.status === 'nao_utilizado')
                                    .map((vestingPeriod, index) => (
                                        <>
                                        <div className='box-details' key={index}>
                                            <h4 className='titulo'>
                                                Ano referência: {
                                                    vestingPeriod &&
                                                    vestingPeriod?.referenceYear ?
                                                    vestingPeriod?.referenceYear+'-'+Number(vestingPeriod?.referenceYear+1)
                                                    : '-'
                                                }
                                                {

                                                    anoSelecionado !== "" && anoSelecionado === vestingPeriod?.referenceYear ?
                                                    <button onClick={fecharDetalhesAno}>Ocultar detalhes</button> :
                                                    <button onClick={()=> VerDetalhesAno(vestingPeriod)}>Ver detalhes</button>
                                                }

                                            </h4>
                                            <ul className='infor-geral'>
                                                <li>
                                                    <p className='nome'>Início do aquisitivo</p>
                                                    <p className='infor'>
                                                        {
                                                            vestingPeriod &&
                                                            vestingPeriod?.startDate ?
                                                            dataFormatadaParaBarra(vestingPeriod?.startDate)
                                                            : '-'
                                                        }
                                                    </p>
                                                </li>
                                                <li>
                                                    <p className='nome'>Fim do aquisitivo</p>
                                                    <p className='infor'>
                                                        {
                                                            vestingPeriod &&
                                                            vestingPeriod?.endDate ?
                                                            dataFormatadaParaBarra(vestingPeriod?.endDate)
                                                            : '-'
                                                        }
                                                    </p>
                                                </li>
                                            <li>
                                                <p className="nome">Limite do benefício</p>
                                                <p className="infor">
                                                    {
                                                        vestingPeriod &&
                                                        vestingPeriod?.limitConcessionPeriod ?
                                                        dataFormatadaParaBarra(vestingPeriod?.limitConcessionPeriod)
                                                        : '-'
                                                    }
                                                </p>
                                            </li>
                                            <li>
                                                <p className="nome">Dias de férias</p>
                                                <p className="infor">
                                                    {
                                                        vestingPeriod &&
                                                        vestingPeriod?.vacationDays ?
                                                        vestingPeriod?.vacationDays
                                                        : '0'
                                                    }
                                                </p>
                                            </li>
                                            <li>
                                                <p className="nome">Dias utilizados</p>
                                                <p className="infor">
                                                    {
                                                        vestingPeriod &&
                                                        vestingPeriod?.daysUsed ?
                                                        vestingPeriod?.daysUsed
                                                        : '0'
                                                    }
                                                </p>
                                            </li>
                                            <li>
                                                <p className="nome">Saldo disponível</p>
                                                <p className="infor">
                                                    {
                                                        vestingPeriod &&
                                                        vestingPeriod?.balanceAvailable ?
                                                        vestingPeriod?.balanceAvailable
                                                        : '0'
                                                    }
                                                </p>
                                            </li>
                                            </ul>
                                            {
                                                anoSelecionado !== "" && anoSelecionado === vestingPeriod?.referenceYear ?
                                                <>

                                                <div className='list-requisicoes-ferias requisicoes'>
                                                    <h4 className='sub-titulo'>Requisições</h4>
                                                    <ul>


                                                    {
                                                    loadInforRequisition ?
                                                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                                    :
                                                    anoSelecionadoInfor &&
                                                            anoSelecionadoInfor !== "" &&
                                                            anoSelecionadoInfor !== undefined &&
                                                            anoSelecionadoInfor?.length !== 0  ?
                                                            anoSelecionadoInfor.map((intem, index) => (

                                                                <li className='infor-requisicoes'>
                                                                    <div className='inicio-fim'>
                                                                        <p>Início<span>{dateFormatedToShutDown(intem.startDate)}  </span></p>
                                                                        <p>Até<span>{dateFormatedToShutDown(intem.endDate)}</span></p>
                                                                    </div>
                                                                    <div className='dias-abono-total'>
                                                                        <p>Dias<span>{intem.vacationDays}</span></p>
                                                                        <p>Abono<span>{intem.vacationDaysSold}</span></p>
                                                                        <p>Total<span>{intem.balance}</span></p>
                                                                    </div>
                                                                    <div className='status'>
                                                                        <p>Status <span>{intem.status}</span></p>
                                                                    </div>
                                                                </li>

                                                            ))
                                                            :
                                                            <li className='infor-requisicoes-vazia' >
                                                                Nenhuma Requisição solicitada.
                                                            </li>
                                                        }
                                                    </ul>

                                                </div>
                                                <div className='list-requisicoes-ferias'>
                                                    <h4 className='sub-titulo'>Férias</h4>
                                                    <ul>

                                                        {
                                                            loadInforRequisition ?
                                                                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                                            :

                                                            anoSelecionadoInforAprovaod &&
                                                            anoSelecionadoInforAprovaod !== "" &&
                                                            anoSelecionadoInforAprovaod !== undefined &
                                                            anoSelecionadoInforAprovaod?.length !== 0 ?
                                                                anoSelecionadoInforAprovaod
                                                                .map((intem, index) => (
                                                                    <>
                                                                        <li className='infor-requisicoes' key={index}>
                                                                            <div className='inicio-fim'>
                                                                                <p>Início<span>{dateFormatedToShutDown(intem.startDate)}  </span></p>
                                                                                <p>Até<span>{dateFormatedToShutDown(intem.endDate)}</span></p>
                                                                            </div>
                                                                            <div className='dias-abono-total'>
                                                                                <p>Dias<span>{intem.vacationDays}</span></p>
                                                                                <p>Abono<span>{intem.vacationDaysSold}</span></p>
                                                                                <p>Total<span>{intem.balance}</span></p>
                                                                            </div>
                                                                            <div className='status'>
                                                                                <p>Status <span>{intem.status}</span></p>
                                                                            </div>
                                                                        </li>
                                                                    </>
                                                                ) )
                                                            :
                                                            <li className='infor-requisicoes-vazia' >
                                                                Nenhuma férias aprovada.
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                                </>
                                                :
                                                ""

                                            }
                                            <button className='btnGreen btn-Requisitar' onClick={()=>IniciaFeriasColl(vestingPeriod)}>Adicionar férias</button>

                                        </div>
                                        </>
                                    ))
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </>
                }
            </Modal>
    </>
    );
}
