import React, {
    useState, useEffect
} from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import api from '../../../../services/api';

export default function ColProfileBenefits({
    id,
    path,
    noChooseAccountig,
    Close,
    focusEmailAccouting,
    loadErrorSendEmailToAccounting,
    loadSaveAndSendFinaly,
    sendAccounting,
    myChangeHandlerCollaboratorRequestsSendToAccounting,
    saveandfinaly,
    loadSaveAndFinaly,
    collaborator
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadsendtoaccounting()

    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadContador, setLoadContador] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sendToAccounting, setSendToAccounting] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [accountingInformation, ] = useState([]);

    async function loadsendtoaccounting() {
        setLoadContador(true)
        await api.get('/accounting/')
        .then(response => {
            setLoadContador(false)
            setSendToAccounting(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function excludeAdmssion(event) {
        event.preventDefault();

        if(collaborator?._id) {

            swal({
                title: "Atenção",
                text: "Deseja realmente excluir o processo de admissão deste colaborador?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    let _id              = collaborator._id;
                    let admissionProcess = "false";
                    let shutdownProcess  = "true";
                    let recentShutdown   = "false";

                    formData.append('_id', _id);
                    formData.append('admissionProcess', admissionProcess);
                    formData.append('shutdownProcess', shutdownProcess);
                    formData.append('recentShutdown', recentShutdown);
                    formData.append('status', 'Inativo');
                    formData.append('path', path);

                    await api.put(`/collaborator/${collaborator._id}`, formData, config)
                    .then(response => {
                        swal({ icon: "success", title: "Sucesso!", text: "Colaborador removido com sucesso!" });
                        
                        window.location.href = '/colaboradores/nova-admissao/formulario-Informacoes-basicas';
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover a colaborador, tente novamente mais tarde." });
                    });

                }
            })

        }
        else {

            swal({ icon: "error", title: "Erro!", text: "Não existe nenhum colaborador para excluir!" });

        }

    }
    return (
        <>

        <div className="form-concluir">
            <div className='box'>
                <div className="titulo">
                    <h4>Envio das informações do processo admissional para a contabilidade</h4>
                </div>
                <div className="informacoes">
                    <div className="infor">
                        {loadContador ?""
                        :
                            sendToAccounting && sendToAccounting.length !== 0 ?
                            <p>Selecione o contador abaixo para enviar a documentação para a admissão do colaborador.</p>
                            :
                            <div>
                            <p>Não existe nenhum cadastro de contador no sistema, caso você deseje enviar automaticamente os dados do seu colaborador para o email do seu contador, por favor cadastre-o em <NavLink to="/configuracao/contadores">cadastro do contador</NavLink>.</p>
                            <p>Caso deseje seguir sem enviar os dados, clique em “Concluir”. <br/></p>

                            </div>
                        }
                    </div>
                    {
                        loadContador ?
                        ""
                        :
                        sendToAccounting && sendToAccounting.length !== 0 ?
                        <>
                            <div className="formulario">
                                <div className='display-grid'>

                                    <div className="intemA">
                                            <p>Contador</p>
                                        </div>
                                        <div className="intemB">
                                            <select
                                            ref={focusEmailAccouting}
                                            name="emailAccountig"
                                            className={loadErrorSendEmailToAccounting ? "inputUm inputErro" : "inputUm" }
                                            onChange={myChangeHandlerCollaboratorRequestsSendToAccounting}
                                            >

                                                {
                                                    accountingInformation.emailAccouting &&
                                                    accountingInformation.emailAccouting !== undefined &&
                                                    accountingInformation.emailAccouting !== '' ?
                                                    <>
                                                    <option value={accountingInformation.emailAccouting+'&'+accountingInformation.nameAccountig} >{accountingInformation.nameAccountig}</option>
                                                    </>
                                                    :
                                                    sendToAccounting ?
                                                    <>
                                                        <option value="Selecione">Selecione</option>
                                                        {
                                                            sendToAccounting.map((item, index) => (
                                                                <option value={item.email+'&'+item.name} key={index}>{item.name}</option>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </select>
                                        </div>
                                </div>
                                <textarea
                                onChange={myChangeHandlerCollaboratorRequestsSendToAccounting}
                                name="accountingComments"
                                placeholder="Comentário opcional ao contador…"
                                ></textarea>

                            </div>
                            <div className='btns'>
                                <button
                                onClick={sendAccounting}
                                className={ loadSaveAndSendFinaly ? "enviar btnGreen load" : "enviar btnGreen"}
                                >Enviar para à contabilidade</button>
                            </div>
                        </>
                        :""
                    }
                </div>



            </div>
            <div className="buttons">
                <form className="delete">
                        <button name="_id" type="submit" className="delete" onClick={excludeAdmssion}>
                            Excluir processo
                        </button>
                </form>

                <div className='salvedStep'>
                    <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${id}`} className="back btnGray">Voltar</NavLink>
                    <button
                    className={loadSaveAndFinaly ? "saved btnGreen load" : "saved btnGreen"}
                    onClick={saveandfinaly}
                    >Concluir processo de admissão e ativar o colaborador</button>
                </div>
            </div>


        </div>



        </>
    );
}
