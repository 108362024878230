import React from 'react';

import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import iconOrdenation from '../../../assets/images/ordenation.svg';

export default function ListCargo({
    occupation,
    EditarCargo,
    loadoccupationCrescente,
    loadoccupationDecrescente,
    loadPageList,
    deleteOccupation,
}) {

    return (
        <>
            <div className="list-cargo-page">
                <thead>
                        <tr>
                            <th className="nome">
                                <p>Cargo</p>
                            </th>
                            <th className='faixa-salarial'>
                                <p>Faixa salarial</p>
                            </th>
                            <th className='com-este-cargo'>
                                <p>Com este cargo </p>
                            </th>
                            <th className='habilidade-requeridas'>
                                <p>Habilidades requeridas</p>
                            </th>
                            <th className='deparamento' >
                                <p>Departamento</p>
                            </th>
                            <th className="th-dopdown-vermais">
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconOrdenation} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" name="name" value="" onClick={()=> loadoccupationCrescente()}>
                                        Ordem alfabética A
                                        </button>
                                        <button type="button" name="name" value="" onClick={()=> loadoccupationDecrescente()}>
                                        Ordem alfabética Z
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </th>
                        </tr>
                </thead>

                <tbody>


                {
                    loadPageList ?
                    (
                        <>

                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        </>
                    ):
                    occupation[0] !== undefined && occupation.length !== 0 ? occupation
                    .map((occupationList, index) => (
                    <>
                    <tr>
                        <td  className="nome"  onClick={() => EditarCargo(occupationList)} >
                            {occupationList.nameOccupatiomn}
                            <p>R$ {occupationList.minimumSalary ? occupationList.minimumSalary : '0,00'} - R$ {occupationList.maximumSalary ? occupationList.maximumSalary : '0,00'} </p>
                        </td>
                        <td  className='faixa-salarial' onClick={() => EditarCargo(occupationList)} >R$ {occupationList.minimumSalary ? occupationList.minimumSalary : '0,00'} - R$ {occupationList.maximumSalary ? occupationList.maximumSalary : '0,00'} </td>
                        <td  className='com-este-cargo' onClick={() => EditarCargo(occupationList)} >{occupationList.numberOfCollaboratorsOccupation} colaboradores</td>
                        <td  className='habilidade-requeridas' onClick={() => EditarCargo(occupationList)} >{occupationList.numberOfSkillsOccupation} habilidades</td>
                        <td className='deparamento' onClick={() => EditarCargo(occupationList)} >{occupationList.sector ? occupationList.sector : '-'}</td>
                        <td className="td-dopdown-acoes">
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button type="button" name="editar" onClick={() => EditarCargo(occupationList)} >
                                        Editar
                                    </button>
                                    <button type="button" name="excluir" onClick={() => deleteOccupation(occupationList)} className="red">
                                        Excluir
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>

                    </>
                    )) : (
                   ''
                    )}
                </tbody>

            </div>
        </>
    );
}

