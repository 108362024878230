import React from 'react';
// import { Tab, Tabs } from "react-bootstrap";
// import IllustrationPraia from '../../../assets/images/illustration-praia.svg';
import Carregamento from '../../../assets/images/carregando.gif';
// import ImgExemplo from '../../../assets/images/alvarobraz.png';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
// import api from '../../../services/api';
import { dateFormatedToStripe } from '../../../services/utils'



const UserMyVacationRequestInProgress = ({
    openRequest,
    loadOpenRequest,
    deleteRequestVacations,
}) => {
    const subsPercentMelon               = (15);
    const subsPercentMelonManager        = (35);
    const subsPercentMelonHumanResources = (55);
    const subsPercentGreen               = (80);
    return (
        <>
        {
            loadOpenRequest ?
            (
                <>
                <div className="carregamento">
                    <img alt="" src={Carregamento} />
                </div>
                </>
            ):
            openRequest.length !== 0 ? openRequest
            .map((openRequestSolicitar, index) => (
            <>
            <div className="solicitacao-em-andamento">
                <h3 className="titulo">Solicitações em aberto</h3>
                <div key={index} className="infor-cal">
                    {
                        openRequestSolicitar &&
                        openRequestSolicitar?.collaborator &&
                        openRequestSolicitar?.collaborator?.avatar !== undefined ?
                        <>
                        <div className="img-col">
                                <img src={openRequestSolicitar?.collaborator?.avatar} alt="nome" />
                        </div>
                        </>
                        :
                        <>
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="coll" />
                            </div>
                        </>
                    }

                    <div className="name-sector-data">
                        <div>
                            <h5 className="name">{openRequestSolicitar?.collaborator.name ? openRequestSolicitar?.collaborator.name : '-'}</h5>
                            <p className="sector">{openRequestSolicitar?.collaborator?.occupation ? openRequestSolicitar?.collaborator?.occupation : '-'} </p>
                        </div>
                        <div>
                            <p className="data">De <span>{openRequestSolicitar?.startDate ? dateFormatedToStripe(openRequestSolicitar?.startDate) : '-'}</span> Até <span>{openRequestSolicitar?.endDate ? dateFormatedToStripe(openRequestSolicitar?.endDate) : '-'}</span> </p>
                            <p className="total-days">Total de dias <span>{openRequestSolicitar?.vacationDays}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dias de abono <span>{openRequestSolicitar?.vacationDaysSold}</span> </p>
                        </div>
                    </div>
                </div>

                <div
                className={
                    openRequestSolicitar &&
                    openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                    openRequestSolicitar?.managerStatus             === 'pendente' ?
                    "barra-progresso-laranja"
                    :
                    openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                    openRequestSolicitar?.managerStatus             === 'aprovado' ?
                    "barra-progresso-laranja"
                    :
                    openRequestSolicitar?.humanResourcesStatus      === 'aprovado' &&
                    openRequestSolicitar?.managerStatus             === 'aprovado' &&
                    openRequestSolicitar?.completeAndGoToAccounting === false      ?
                    "barra-progresso-verde"
                    :
                    openRequestSolicitar?.humanResourcesStatus      === 'aprovado' &&
                    openRequestSolicitar?.managerStatus             === 'aprovado' &&
                    openRequestSolicitar?.completeAndGoToAccounting === true     ?
                    "barra-progresso-verde"
                    :
                    "barra-progresso-laranja"
                }
                >
                    <span
                    style={
                        openRequestSolicitar &&
                        openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                        openRequestSolicitar?.managerStatus             === 'pendente' ?
                        { width: `${subsPercentMelon}%`}
                        :
                        openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                        openRequestSolicitar?.managerStatus             === 'aprovado' ?
                        { width: `${subsPercentMelonManager}%`}
                        :
                        openRequestSolicitar?.humanResourcesStatus      === 'aprovado' &&
                        openRequestSolicitar?.managerStatus             === 'aprovado' &&
                        openRequestSolicitar?.completeAndGoToAccounting === false      ?
                        { width: `${subsPercentMelonHumanResources}%`}
                        :
                        openRequestSolicitar?.humanResourcesStatus      === 'aprovado' &&
                        openRequestSolicitar?.managerStatus             === 'aprovado' &&
                        openRequestSolicitar?.completeAndGoToAccounting === true     ?
                        { width: `${subsPercentGreen}%`}
                        :
                        { width: `${subsPercentMelon}%`}
                    }
                    >  </span>
                </div>
                <div className="status">
                    <p>Status:
                        {
                            openRequestSolicitar &&
                            openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                            openRequestSolicitar?.managerStatus             === 'pendente' ?
                            ' Aguardando aprovação do gestor'
                            :
                            openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                            openRequestSolicitar?.managerStatus             === 'aprovado' ?
                            ' Aguardando aprovação do RH'
                            :
                            openRequestSolicitar?.humanResourcesStatus      === 'aprovado' &&
                            openRequestSolicitar?.managerStatus             === 'aprovado' &&
                            openRequestSolicitar?.completeAndGoToAccounting === false    ?
                            ' Aguardando concluir e ir à contabilidade'
                            :
                            openRequestSolicitar?.humanResourcesStatus      === 'aprovado' &&
                            openRequestSolicitar?.managerStatus             === 'aprovado' &&
                            openRequestSolicitar?.completeAndGoToAccounting === true    ?
                            ' Concluída, aguardando aprovação'
                            :
                            ''
                        }
                    </p>
                    {
                        openRequestSolicitar &&
                        openRequestSolicitar?.humanResourcesStatus      === 'pendente' &&
                        openRequestSolicitar?.managerStatus             === 'pendente'  ?
                        <button onClick={() => deleteRequestVacations(openRequestSolicitar?._id)} className="remover">Remover</button>
                        :
                        ''
                    }
                </div>
            </div>

            </>
            )) : (
                <>
                </>
            )}
        </>
    );
}
export default UserMyVacationRequestInProgress;
