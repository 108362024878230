import React from 'react';

export default function generalInformation({
    vacancieRascunho,
    vacanciePublicado
}) {
    return (
        <>

            <div className='general-information'>
                <ul >
                    <li className='candidatos'>
                        <span>{vacanciePublicado && vacanciePublicado !== 0 ? vacanciePublicado : "0"}</span>
                        <p>vagas abertas</p>
                    </li>
                    <li className='pastas'>
                        <span>{vacancieRascunho && vacancieRascunho !== 0 ? vacancieRascunho : "0"}</span>
                        <p>rascunhos</p>
                    </li>
                </ul>
            </div>
        </>
    );
}

