import React, {
    useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Management/headerOneToOne';
import BoxInfor from '../../../components/Management/OneToOne/Box-infs';
import TableOneToOne from '../../../components/Management/OneToOne/tableOneToOne';
import illustrationOneToOne from '../../../assets/images/illustration-oneToOne.svg';
import '../styles.scss';

export default function homeFeedback(props) {

   const { path } = props.match;


   ///////// SETS E FUCTION DO MODAL DE AGENDAR FEEDBACK
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleModalscheduleOneToOne, setVisibleModalScheduleOneToOne] = useState(false);
   async function modalScheduleOneToOne() {
       setVisibleModalScheduleOneToOne(!visibleModalscheduleOneToOne);
   }
   ///////// SET PROVISORIO DE ESTADO VAZIO
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [oneToOneList, ] = useState(false);



       return (
           <>
              <Layout path={path}>
                   <Header 
                       path={path} 
                       modalScheduleOneToOne={modalScheduleOneToOne}
                       visibleModalscheduleOneToOne={visibleModalscheduleOneToOne}
                   />
                   <div className='pages-management'>
                       {
                           oneToOneList ?

                           <div className='nenhum-feedback'>
                            <p>Nenhum 1:1 agendado. <br/>Agende um 1:1..</p>
                            <button className='btnGreen more ' onClick={modalScheduleOneToOne}>Agendar feedback</button>

                            <img src={illustrationOneToOne} alt="" />
                            </div>
                        :
                           <div className="one-to-one">

                                <BoxInfor/>
                                <TableOneToOne/>
    
                           </div>
                           

                       }
                   </div>
               </Layout>
           </>
       );
}

