import React from "react";
import Parser from 'html-react-parser';


export default function VacanciesPageConteudo({inforVaga}) {
    return (
        <>
            <div className="conteiner">
                        {
                            inforVaga?.location ?
                            <img src={inforVaga?.location } alt="nome da vaga" className="banner-vaga"/>
                            :""
                        }
                        {
                            inforVaga?.description ?
                            <div className="box">
                                <h3>{inforVaga?.descriptionTitle }</h3>
                                <div className="infor-text">
                                    {inforVaga ? Parser(inforVaga?.description) : "" } 
                                </div>
                            </div> :""
                        }
                        {
                            inforVaga?.responsibilities ?
                            <div className="box">
                                <h3>Principais responsabilidades</h3>
                                <div className="infor-text">
                                    {inforVaga ? Parser(inforVaga?.responsibilities) : "" } 
                                </div>
            
                            </div>
                            :""
                        }
                        {
                            inforVaga?.skills ?
                                <div className="box">
                                    <h3>Habilidades necessárias</h3>
                                    <div className="infor-text">
                                        {inforVaga ? Parser(inforVaga?.skills) : "" } 
                                    </div>
                                </div>
                            :""
                        }
                    
                
            </div>
        </>
    )
}