import React, {useState}  from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/LogsSistem/header';
import Filters from '../../components/LogsSistem/filters';
import TableLogs from '../../components/LogsSistem/tableLogs';
import './styles.scss';

export default function homeFeedback(props) {
   const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [id_collaborator_search_log, setId_collaborator_search_log ] = useState("");
    // console.log("id_collaborator_search_log", id_collaborator_search_log)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [roles, setRoles] = useState("");
    // console.log("roles", roles)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ action, setAction] = useState("");
    // console.log("action", action)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ functionality, setFunctionality] = useState("");
    // console.log("functionality", functionality)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [logOfTheSystem, setLogOfTheSystem ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadlLogOfTheSystem, setLoadlLogOfTheSystem ] = useState([]);



       return (
           <>
              <Layout path={path}>
                   <Header   path={path}  />

                   <div className='page-logs-sistem'>
                        <Filters
                        setRoles={setRoles}
                        roles={roles}
                        setAction={setAction}
                        action={action}
                        id_collaborator_search_log={id_collaborator_search_log}
                        setId_collaborator_search_log={setId_collaborator_search_log}
                        functionality={functionality}
                        setFunctionality={setFunctionality}
                        setLogOfTheSystem={setLogOfTheSystem}
                        setLoadlLogOfTheSystem={setLoadlLogOfTheSystem}
                        />
                        <TableLogs
                        logOfTheSystem={logOfTheSystem}
                        loadlLogOfTheSystem={loadlLogOfTheSystem}
                        />
                   </div>

               </Layout>
           </>
       );
}

