import React, {useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import apiV2 from '../../../../services/apiV2';
import Carregamento from '../../../../assets/images/carregando.gif';
import { formatDateString } from '../../../../services/utils';

export default function ColProfilePersonalDataCourses ({id, token, setCoursesStatus, collaborator}) {
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState([]);
    const [loadCourses, setLoadCourses] = useState(false);
    const [loadBtnCourses, setLoadBtnCourses] = useState(false);
    const [filePreview, setFilePreview] = useState([]);
    const [loadErrorCourseName, setLoadErrorCourseName] = useState(false);
    const [loadInstituteName, setLoadInstituteName] = useState(false);
    const [loadStartAtDate, setLoadStartAtDate] = useState(false);
    const [loadConclusionAtDate, setLoadConclusionAtDate] = useState(false);
    useEffect(() => {
        if(id && token) { getCourses(id, token); }
    }, [id, token]);

    async function getCourses(id, token) {
        try {
            const config = {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              }; 
            setLoadBtnCourses(false)
            setLoadCourses(true)
            console.log(config)
            
            const response = await apiV2.get(`/course/external/search?collaborator._id=${id}&company=${collaborator.companyId}`, config)
            setCourses(response.data.items);
            setCoursesStatus(response.data.items);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        }
        finally{
            setLoadCourses(false)
        }
    }
    const applyMask = (input) => {
        const numericValue = input.replace(/\D/g, ''); // Remove caracteres não numéricos
        
        if (numericValue.length >= 2) {
            const firstDigit = parseInt(numericValue[0]);
            const secondDigit = parseInt(numericValue[1]);
    
            if (firstDigit > 1 || (firstDigit === 1 && secondDigit > 2)) {
                return;
            }
        }
    
        if (numericValue.length > 2) {
            const month = numericValue.slice(0, 2);
            const year = numericValue.slice(2, 6);
            return `${month}/${year}`;
        }
    
        return numericValue;
    };
    const transformDateFormatYYYYMM = (input) => {
        const parts = input.split('/');
        
        if (parts.length === 2) {
            const month = parts[0];
            const year = parts[1];
    
            if (month.length === 2 && year.length === 4) {
                return `${year}-${month}`;
            }
        }
    
        return 'Formato de data inválido';
    };
    function removeEmptyFields(obj) {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        if (Array.isArray(obj)) {
          return obj.map(item => removeEmptyFields(item)).filter(item => item !== null);
        }
      
        const newObj = {};
        for (const key in obj) {
          const value = removeEmptyFields(obj[key]);
          if (value !== null && value !== undefined && (!Array.isArray(value) || value.length > 0)) {
            newObj[key] = value;
          }
        }
        
        return Object.keys(newObj).length > 0 ? newObj : null;
    }
    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam === "startAtDate") {
            if (val.length <= 7) {
                const maskedInput = applyMask(val);
                if( val.length === 7 ||val.length === 0){
                    setLoadStartAtDate(false)
                }
                setCourse({...course, [nam]:maskedInput})
            }
        } else if(nam === "conclusionAtDate"){
            if (val.length <= 7) {
                const maskedInput = applyMask(val);
                if( val.length === 7 || val.length === 0){
                    setLoadConclusionAtDate(false)
                }
                setCourse({...course, [nam]:maskedInput})
            }
        } else if(nam === "courseName"){
            setCourse({...course, [nam]:val});
            setLoadErrorCourseName(false);
        } else if(nam === "instituteName"){
            setCourse({...course, [nam]:val});
            setLoadInstituteName(false);
        }  else {
            setCourse({...course, [nam]:val})
        }
    };
    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        if (file.size > maxSize) {
          swal({ icon: "error", title: "Erro!", text: "Arquivo é muito grande! Limite de 2MB." });
          return;
        }

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            acceptedFiles.map((file) => {
                // eslint-disable-next-line
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setCourse({...course, "file": {
                        format: file.type.split("/")[1],
                        name: file.name,
                        base64: reader.result,
                      }})
                      
                    setFilePreview({
                        preview: URL.createObjectURL(file),
                        name:  file.name,
                    });
                };
                return null;
             } )
            
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }
    async function deleteFiles() {
        setFilePreview([]);
        setCourse({...course, "file":""})
    } 
    async function handleSubmit(event) {
        event.preventDefault();
        
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        }; 
        setLoadBtnCourses(true);
        if(course && course.length === 0) {
            swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
            setLoadBtnCourses(false);
        }
        else
        if (!course?.courseName || course.courseName === "" || !course?.instituteName || course.instituteName === "") {
            if (!course?.courseName || course.courseName === "") {
              setLoadErrorCourseName(true);
            }
            if (!course?.instituteName || course.instituteName === "") {
              setLoadInstituteName(true);
            }
            setLoadBtnCourses(false)
            return;
        } else {
            if((course?.startAtDate && course?.startAtDate?.length !== 7) || (course?.conclusionAtDate && course?.conclusionAtDate?.length !== 7) ){
                if( course.startAtDate && course?.startAtDate?.length !== 7){
                    setLoadStartAtDate(true)
                }
                if( course?.conclusionAtDate && course?.conclusionAtDate?.length !== 7){
                    setLoadConclusionAtDate(true)
                }
                setLoadBtnCourses(false);
                return
           }
            let courseItem = {
                "collaborator": id,
                "courseName": course?.courseName,
                "instituteName": course?.instituteName,
                "startAtDate": course.startAtDate ? transformDateFormatYYYYMM(course?.startAtDate) : null,
                "conclusionAtDate": course?.conclusionAtDate ? transformDateFormatYYYYMM(course?.conclusionAtDate) : null,
                "file": course.file ?? {},
                "company": collaborator.companyId,
            } 
            await apiV2.post(`/course/external/create`, removeEmptyFields(courseItem), config)
            .then(response => {
                getCourses(id, token);
                setFilePreview([]);
                setCourse({})
                swal({ icon: "success", title: "Sucesso!", text: "Curso criada com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadBtnCourses(false);
            });
        }
    }
    async function deleteCourse(course) {
        
    const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }; 
        swal({
            title: "Atenção",
            text: "Deseja excluir este curso?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await apiV2.delete(`/course/external/delete/${course}` , config)
                .then(() => {
                    getCourses(id, token);
                    setFilePreview([]);
                    setCourse({...course, "file":""})
                    swal({ icon: "success", title: "Sucesso!", text: "Curso removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o curso, tente novamente mais tarde." });
                });
            }
        });
    }
    return (
        <>
        <div className="box-formulario courses">
            <h4 className="titulo-box">Cursos</h4>

                
            <div className='linha-academic'>
                <div className='intemA'>
                    <p className="textCampo">Instituição de ensino *</p>
                </div>
                <div className='intemB'>
                    <input 
                        type="text" 
                        name="instituteName" 
                        autoComplete="off" 
                        placeholder="Instituição de ensino" 
                        value={course?.instituteName ?? ""}
                        className={loadInstituteName ? 'inputErro' : ''}
                        onChange={myChangeHandler}
                    />
                    {loadInstituteName ? <p className="textError">O campo é obrigatório.</p>:""}
                </div>
            </div>
            <div className='linha-academic'>
                <div className='intemA'>
                    <p className="textCampo">Curso *</p>
                </div>
                <div className='intemB'>
                    <input 
                        type="text" 
                        name="courseName" 
                        autoComplete="off" 
                        placeholder="Curso" 
                        value={course?.courseName ?? ""}
                        className={loadErrorCourseName ? 'inputErro' : ''}
                        onChange={myChangeHandler}
                    />
                    {loadErrorCourseName ? <p className="textError">O campo é obrigatório.</p>:""}
                </div>
            </div>
            <div className='linha-academic'>
                <div className='intemA'>
                    <p className="textCampo">Inicio</p>
                </div>
                <div className='intemB'>
                    <input 
                        type="text" 
                        name="startAtDate" 
                        autoComplete="off" 
                        placeholder="MM/AAAA"  
                        className={loadStartAtDate ? 'inputErro' : ''}
                        value={course?.startAtDate ?? ""}
                        onChange={myChangeHandler}
                    />
                    {loadStartAtDate ? <p className="textError">Formato de data inválido.</p>:""}
                </div>
            </div>
            <div className='linha-academic'>
                <div className='intemA'>
                    <p className="textCampo">Conclusão</p>
                </div>
                <div className='intemB'>
                    <input 
                        type="text" 
                        name="conclusionAtDate" 
                        autoComplete="off" 
                        placeholder="MM/AAAA" 
                        className={loadConclusionAtDate ? 'inputErro' : ''}
                        value={course?.conclusionAtDate ?? ""}
                        onChange={myChangeHandler}
                    />
                    {loadConclusionAtDate ? <p className="textError">Formato de data inválido.</p>:""}
                </div>
            </div>
            <div className='linha-academic'>
                <div className='intemA'>
                    <p className="textCampo"></p>
                </div>
                <div className='intemB'>
                    <Dropzone onDrop={handleDrop} >
                        {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone inputOnze" })}>
                            <input type="file" name="file" {...getInputProps()} />
                            <label className="imgDocLabel inputOnze"><span></span>{
                                filePreview?.length !== 0 ? "Atualize o arquivo": "Anexe um arquivo"
                            }
                            
                            </label>
                        </div>
                        )}
                    </Dropzone>
                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p> 
                </div>
            </div>
            {  
                filePreview?.length !== 0 && filePreview !== undefined ?
                <>
                    <div className='linha-academic'>
                        <div className='intemA'>
                        </div>
                        <div className='intemB'>
                            <div className="arquivoAnexado textoDoze">
                                <div  className="arquivo">
                                    <p className="nomeArquivo">
                                        {filePreview.name}
                                    </p>
                                    <p onClick={() => deleteFiles()} className="remover">Remover anexo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            :""}
            <div className='linha-academic'>
                <div className='intemA'>
                </div>
                <div className='intemB'>
                    <form onSubmit={handleSubmit} className="btn-salvar">
                        <button type="submit" className={loadBtnCourses  ? 'enviar btnGreen load btn-adicionar' : 'enviar btnGreen btn-adicionar'}>{loadBtnCourses ? 'Adicionando' : 'Adicionar curso'}</button>
                    </form>
                </div>
            </div>
        </div>

        <div className="formacao-adicionado">
            <h5>Cadastrados</h5>
            {
                        loadCourses ?
                   
                        <div className="carregamento" >
                            <img alt="" src={Carregamento} />
                        </div>
                        : courses && courses.length !== 0 ?
                        courses
                        .map((course, index) => (
                            <>
                                <div key={index} className="anexado">
                                    <div className="name">
                                        <p>{course.courseName}</p>
                                    </div>
                                    <div className="arquivo">
                                        <p>
                                            {
                                                course?.file ?
                                                    course?.file?.url && (
                                                        <a href={course.file.url} target="_blank" rel="noopener noreferrer">
                                                            {course.file.name}
                                                        </a>
                                                    )
                                                : "Nenhum arquivo"
                                            }
                                        </p>
                                    </div>
                                    <div className="dataArquivo">
                                        <span>Cadastrado em {formatDateString(course.updatedAtDateTime)}</span>
                                    </div>
                                    <div className="btnExclui">
                                        <button onClick={() => deleteCourse(course._id)}>Excluir</button >
                                    </div>
                                </div>
                            </>
                        )): (
                            <div className="nenhum-dependente">
                                <p>Nenhuma curso cadastrado.</p>
                            </div>
                        )}
        </div>
        </>
    );
};