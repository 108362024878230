import React from "react";
import { oneHundredCharacters } from '../../../services/utils';
export default function customPage({
    colorBackground,

    topHeader,
    titleTopHeader,
    colorTextTopHeader,
    logoTopHeader,
    backgroundTopHeader,

    aboutCompany,
    titleAboutCompany,
    colorTitleAboutCompany,
    textAboutCompany,
    colorTextAboutCompany,

    videoCompany,
    titleVideoCompany,
    colorTitleVideoCompany,
    linkVideoCompany,
    logoFooter,
    footer,
    titleFooter,
    colorTitleFooter,
    colorTextFooter,
    colorBackgroundFooter
}) {

    return (
        <>
        <div>
        <div className="customPage" style={{ background: colorBackground }}>
            {
                topHeader ?
                <>
                    <div className="top"
                      style={{ background: `url(${backgroundTopHeader})` }}
                      >
                        <div className="center">
                            <div className="text">
                                <h2  style={{ color: colorTextTopHeader ? colorTextTopHeader : "#fff" }}>{titleTopHeader}</h2>
                            </div>
                            <div className="logo">
                                {logoTopHeader !== undefined ? <img src={logoTopHeader} alt="Nome da empresa" />:""}
                            </div>

                        </div>
                    </div>
                </>: ""
            }

            {
            aboutCompany ?
            <>
                <div className="about">
                    <h5 className="title-box" style={{ color: colorTitleAboutCompany ? colorTitleAboutCompany : "" }}>{titleAboutCompany} </h5>
                    <div className="bloco-text">
                        {
                            textAboutCompany && textAboutCompany.split('\n').map(str => <p  style={{ color: colorTextAboutCompany ? colorTextAboutCompany : "" }}>{oneHundredCharacters(str)}</p>)
                        }
                    </div>
                </div>
            </>: ""
            }
             {
            videoCompany ?
            <>

            <div className="video">
                {
                    titleVideoCompany ?
                    <h5 className="title-box" style={{ color: colorTitleVideoCompany }}>{titleVideoCompany}</h5>
                    :""
                }
                {
                   linkVideoCompany && linkVideoCompany !== undefined && linkVideoCompany !== "" && linkVideoCompany !== "https://www.youtube.com/embed" ? <>
                        <div className="video-container">
                            <iframe 
                            className="video-iframe"
                                src={linkVideoCompany}
                                title="YouTube video player" 
                                frameborder="0"  allowfullscreen
                            >
                            </iframe>
                        </div>
                    </>:""
                }
            </div>
            
            </>:""
            }
            {
                footer ? <>
                    <div className="Baseboard" style={{ background: colorBackgroundFooter ? colorBackgroundFooter : "" }}>
                        <div className="left">
                            <p className="titulo"  style={{ color: colorTitleFooter ? colorTitleFooter : "#000" }}>{titleFooter}</p>
                            <ul>
                                <li>
                                    <button  style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>Site</button>
                                </li>
                                <li>
                                    <button  style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>LinkedIn</button>
                                </li>
                                <li>
                                    <button  style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>Facebook</button>
                                </li>
                                <li>
                                    <button  style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>Twitter</button>
                                </li>
                                <li>
                                    <button  style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>Instagram</button>
                                </li>
                                <li>
                                    <button  style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>YouTube</button>
                                </li>
                            </ul>
                            <button  className="politica-privacidade" style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>Política de Privacidade</button>
                        </div>
                        <div className="right">
                            <div className="logo">
                                {
                                    logoFooter !== undefined ? <img src={logoFooter} alt="Nome da empresa" /> :""
                                }
                            </div>
                            <p   style={{ color: colorTextFooter ? colorTextFooter : "#000" }}>© 2022 People View</p>
        
                        </div>
                    </div>
                </>:""
            }
        </div>

        </div>
        </>
    )
}