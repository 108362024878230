import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../services/api';


export default function ColProfileJobInformation({ id, path }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredWorkInfor, setRegisteredWorkInfor ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredCollaborator, setRegisteredCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadRegisteredWorkInfor();
        loadcollaboratoraccess(id);
    }, [id]);

    async function loadRegisteredWorkInfor() {
        await api.get(`/collaborator-work-information-access`)
        .then(response => {
            if(response.data[0] !== undefined) {
                setRegisteredWorkInfor(response.data[0]);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    async function loadcollaboratoraccess(id) {
        await api.get(`/collaborator-access/${id}`)
        .then(response => {
            if(response.data !== undefined) {
                setRegisteredCollaborator(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    return (
        <>
            <div className="box JobInformation">
                <h3>Informações de Trabalho</h3>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Data de admissão</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator?.admissionDate !== undefined ? registeredCollaborator.admissionDate : "-"}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Gestor</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator?.nameManager !== undefined ? registeredCollaborator.nameManager : "-"}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>E-mail profissional</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator.email !== undefined ? (registeredCollaborator.email):('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Tipo de admissão</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator.admissionType !== undefined ? registeredCollaborator.admissionType : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Forma de pagamento</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredWorkInfor.formOfPayment !== undefined ? (registeredWorkInfor.formOfPayment):('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Matrícula</p>
                        </div>
                        <div className="intemB">
                                {registeredCollaborator && registeredWorkInfor.registration !== undefined ? (registeredWorkInfor.registration):('-')}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Sindicato</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredWorkInfor.syndicate === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Estabilidade</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredWorkInfor.stability !== undefined ? (registeredWorkInfor.stability):('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Cargo de Confiança</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredWorkInfor.positionOfTrust === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Primeiro emprego</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredWorkInfor.FirstJob === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Data do exame admissional</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator.dateAdmissionExamination !== undefined ? (registeredCollaborator.dateAdmissionExamination):('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Tipo de salário</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.SalaryType !== undefined ? (registeredWorkInfor.SalaryType):('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Tem seguro desemprego</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.unemploymentInsurance === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Aposentado</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.Retiree === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Inscrição no órgão de classe</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.RegistrationWithTheProfessionalBody === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Conselho Profissional</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.professionalAdvice === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>CIPA</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.CIPA === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Possui registro de ponto?</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredWorkInfor && registeredWorkInfor.havePointsRecord === true ? ('Sim'):('Não')}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}
