import React, { useState, useEffect } from 'react';
import iconJobVerde from '../../../assets/images/icon-job-verde.svg';
import iconMoneyVerde from '../../../assets/images/icon-money-verde.svg';
import api from '../../../services/api';
import { toast } from 'react-toastify';

import { dateFormatedToHistory } from '../../../services/utils';

export default function ColProfileHistories({ id, role }) {

    const [visible, setVisible] = useState(false);

    const [idHistory, setIdHistory] = useState([]);

    function handleToggleVisible(e) {

        if(e && e !== undefined) {
            setIdHistory(e)
            setVisible(true);
        }


    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadCollaboratorHistory ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorHistory, setCollaboratorHistory ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadcollaboratorvacationhistory() {
            setLoadCollaboratorHistory(true)
            await api.get(`/collaborator-history/${id}`)
            .then(response => {
                setLoadCollaboratorHistory(false)
                if(response.data !== undefined) {
                    //console.log(response.data)
                    setCollaboratorHistory(response.data);
                }
                else {
                    setCollaboratorHistory([]);
                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadcollaboratorvacationhistory();
    }, [id]);

    return (
        <>
        <div className="box-profile-historico">
            <div className="cabecalho">
                <h1>Histórico de salário e promoções</h1>

            </div>
            <div className="timeline-salary-promotions">
                {/* ////////////////////////
                Load de carregamento
                ///////////////////////////
                <div className="carregamento">
                    <img alt="" src={Carregando} />
                </div> */}
                <div className="box-timeline">
                    <ul className="ul-timeline">

                        {
                            collaboratorHistory && collaboratorHistory.length !== 0 ?
                            collaboratorHistory
                            .filter(filtercollaborator =>
                                filtercollaborator.reason === 'Mérito/Reajuste' ||
                                filtercollaborator.reason === 'Promoção'
                            )
                            .map((historycollaborator, index) => (
                                <>
                                <li key={index}>
                                    <div className="box boxAberto" onClick={() => handleToggleVisible(historycollaborator._id)}>
                                        <h3 className="promocao-titulo">{historycollaborator.name}</h3>
                                        <div className="infor">
                                            <div className="salario-cargo">
                                                <img src={iconMoneyVerde} alt="" />
                                                <div  className="salario">
                                                    <strong className="promocao-salario">R$ {historycollaborator.salary}</strong>
                                                    <span>Salário</span>
                                                </div>
                                            </div>
                                            <div className="salario-cargo">
                                                <img src={iconJobVerde} alt="" />
                                                <div className="cargo">
                                                    <strong className="promocao-cargo">{historycollaborator.occupation ? historycollaborator.occupation : historycollaborator.collaborator.occupation}</strong>
                                                    <span>Cargo</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="infor-lista" style={visible === true && idHistory === historycollaborator._id ? {display:'block'} : {display:'none'}}>
                                            <ul>
                                                <li>
                                                    <p>A parti r de</p>  <span>{historycollaborator.validOnTheDateOf}</span>
                                                </li>
                                                <li>
                                                    <p>Vínculo</p>  <span>{historycollaborator.bond}</span>
                                                </li>
                                                <li>
                                                    <p>Departamento</p> <span>{historycollaborator.collaborator.sector}</span>
                                                </li>
                                                <li>
                                                    <p>Centro de custo</p> <span>{historycollaborator.collaborator.costCenter}</span>
                                                </li>
                                                <li>
                                                    <p>Descrição</p> <span>Promoção por {historycollaborator.description}</span>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                    <p className="alterado-por">Por {historycollaborator.responsible.name} <span>{dateFormatedToHistory(historycollaborator.createdAt)}</span></p>
                                </li>
                                </>
                            ))
                            :
                            ''

                        }

                        {/* <li>
                            <div className="box boxAberto">
                                <h3>Reajuste</h3>
                                <div className="infor">
                                    <div className="salario-cargo">
                                        <img src={iconMoneyCinza} alt="" />
                                        <div  className="salario">
                                            <strong>R$ 3.500</strong>
                                            <span>Salário</span>
                                        </div>
                                    </div>
                                    <div className="salario-cargo">
                                        <img src={iconJobCinza} alt="" />
                                        <div className="cargo">
                                            <strong>Customer Success Pleno</strong>
                                            <span>Cargo</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="alterado-por">Por Carolina Martini <span>08.02.2020 às 9:30</span></p>
                        </li>
                        <li>
                            <div className="box boxAberto">
                                <h3>Dissídio</h3>
                                <div className="infor">
                                    <div className="salario-cargo">
                                        <img src={iconMoneyCinza} alt="" />
                                        <div  className="salario">
                                            <strong>R$ 3.000</strong>
                                            <span>Salário</span>
                                        </div>
                                    </div>
                                    <div className="salario-cargo">
                                        <img src={iconJobCinza} alt="" />
                                        <div className="cargo">
                                            <strong>Customer Success Pleno</strong>
                                            <span>Cargo</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="alterado-por">Por Carolina Martini <span>08.02.2020 às 9:30</span></p>
                        </li>
                        <li> */}
                            {/* <div className="box boxAberto">
                                <h3>Promoção</h3>
                                <div className="infor">
                                    <div className="salario-cargo">
                                        <img src={iconMoneyCinza} alt="" />
                                        <div  className="salario">
                                            <strong>R$ 2.900</strong>
                                            <span>Salário</span>
                                        </div>
                                    </div>
                                    <div className="salario-cargo">
                                        <img src={iconJobCinza} alt="" />
                                        <div className="cargo">
                                            <strong>Customer Success Pleno</strong>
                                            <span>Cargo</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="alterado-por">Por Carolina Martini <span>08.02.2020 às 9:30</span></p>
                        </li>
                        <li>
                            <div className="box boxAberto">
                                <h3>Mérito</h3>
                                <div className="infor">
                                    <div className="salario-cargo">
                                        <img src={iconMoneyCinza} alt="" />
                                        <div  className="salario">
                                            <strong>R$ 1.500</strong>
                                            <span>Salário</span>
                                        </div>
                                    </div>
                                    <div className="salario-cargo">
                                        <img src={iconJobCinza} alt="" />
                                        <div className="cargo">
                                            <strong>Customer Success Pleno</strong>
                                            <span>Cargo</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="alterado-por">Por Carolina Martini <span>08.02.2020 às 9:30</span></p>
                        </li> */}
                    </ul>
                </div>
            </div>

        </div>
        </>
    );
}

