import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import swal from 'sweetalert';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Cargos/header-list';
import ListCargos from '../../../components/Administrativo/Cargos/list-cargo';
import GridCargos from '../../../components/Administrativo/Cargos/grid-cargo';
import { decodeToken } from '../../../services/auth';
import Carregamento from '../../../assets/images/carregando.gif';
import EstadoVazio from '../../../assets/images/illustration-cargos-vazio.svg';
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import IconArrowVazioVerde from '../../../assets/images/arrow-vazio-verde.svg';
import Close from '../../../assets/images/close.svg';
import CurrencyInput from 'react-currency-input';
import './styles.scss';

export default function ColBeneficio(props) {

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [gridCargos, setGridCargos] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listCargos, setListCargos] = useState(false);

    function SelectGrid() {
        setGridCargos(true);
        setListCargos(false);
    }

    function SelectGridList() {
        setGridCargos(false);
        setListCargos(true);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageOccupationGrid, setLoadPageOccupationGrid ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupation, setOccupation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSkills, setLoadSkills ] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorOccupation, setLoadCollaboratorOccupation ] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadoccupation();
        loadsectors();
    }, []);

    async function loadoccupationUpdate() {
        await api.get('/occupation-store/?sortBy=name&order=asc')
        .then(response => {
            if(response.data.length === 0) {
                setOccupation([]);
                setLoadPageOccupationGrid(false)
                setLoadPage(false)
            }
            else {
                setOccupation(response.data);
                setLoadPageOccupationGrid(false)
                setLoadPage(false)
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageOccupationGrid(false)
            setLoadPage(false)
        })

    }



    async function loadoccupation() {
        setLoadPage(true)
        setLoadPageOccupationGrid(true)
        await api.get('/occupation-store/?sortBy=name&order=asc')
        .then(response => {

            if(response.data.length === 0) {
                setOccupation([]);
                setLoadPageOccupationGrid(false)
                setLoadPage(false)
            }
            else {
                setOccupation(response.data);
                setLoadPageOccupationGrid(false)
                setLoadPage(false)
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageOccupationGrid(false)
            setLoadPage(false)
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageList, setLoadPageList ] = useState(false);

    async function loadoccupationCrescente() {
        setLoadPageList(true)
        await api.get('/occupation-store/?sortBy=name&order=asc')
        .then(response => {
            setOccupation(response.data);
            setLoadPageList(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageList(false)
        })
    }

    async function loadoccupationDecrescente() {
        setLoadPageList(true)
        setLoadPageOccupationGrid(true)
        await api.get('/occupation-store/?sortBy=name&order=desc')
        .then(response => {
            setOccupation(response.data);
            setLoadPageList(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageList(false)
        })
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    async function loadfilteroccupation(event) {
        event.preventDefault()
        let name = event.target.value;
        setLoadPage(true)
        if(name.length > 1) {

            await api.get(`/occupation-store/?name=${name}`)
            .then(response => {

                if(response.data.length === 0) {
                    setOccupation([]);
                    setLoadPageOccupationGrid(false)
                    setLoadPage(false)
                }
                else {
                    setOccupation(response.data);
                    setLoadPageOccupationGrid(false)
                    setLoadPage(false)
                }

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            await api.get('/occupation-store/?sortBy=name&order=asc')
            .then(response => {

                if(response.data.length === 0) {
                    setOccupation([]);
                    setLoadPageOccupationGrid(false)
                    setLoadPage(false)
                }
                else {
                    setOccupation(response.data);
                    setLoadPageOccupationGrid(false)
                    setLoadPage(false)
                }

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameEdiCargo, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDepartamentoEdiCargo, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleEdiCargo, setVisibleEdiCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadHabilidadeEdiCargo, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadColEdiCargo, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupatioEdit, setOccupatioEdit] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showSkillInTheOccupation, setShowSkillInTheOccupation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showCollaboratorSavedInOccupation, setShowCollaboratorSavedInOccupation] = useState([]);

    //collaboratorFilterForThisSkill
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorFilterForThisOccupation, setCollaboratorFilterForThisOccupation ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countCollaboratorSavedInTheOccupation, setCountCollaboratorSavedInTheOccupation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countSkillSavedInTheOccupation, setCountSkillSavedInTheOccupation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countSkillDeleteInTheOccupation, setCountSkillDeleteInTheOccupation] = useState([]);

    async function EditarCargo(e) {

        setOccupatioEdit(e)
        setVisibleEdiCargo(!visibleEdiCargo);

        window.setTimeout(function () {
            document.getElementById('nameCargo').focus();
        }, 0);

        const id = e._id;
        setLoadSkills(true)
        await api.get(`/skill-occupation/${id}`)
        .then(response => {

            let itensCopyskill = Array.from(showSkillInTheOccupation);

            for(let i=0; i < response.data.length; i++) {

                let idSkill = i;
                let name    = response.data[i].skill.name;
                let skill   = response.data[i].skill._id;
                let _id     = response.data[i]._id;


                itensCopyskill.push({
                    idSkill,
                    name,
                    skill,
                    _id,
                });

            }

            setShowSkillInTheOccupation(itensCopyskill);
            setCountSkillSavedInTheOccupation(itensCopyskill);
            setLoadSkills(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadSkills(false)
        });

        setLoadCollaboratorOccupation(true)
        await api.get(`/collaborators-occupation-new/${id}`)
            .then(response => {

                let itensCopyCollaborators = Array.from(showCollaboratorSavedInOccupation);

                for(let i=0; i < response.data.length; i++) {

                    let idCollaborator = i;
                    let avatar         = response.data[i].collaborator.avatar;
                    let name           = response.data[i].collaborator.name;
                    let collaborator   = response.data[i].collaborator._id;
                    let occupation     = response.data[i].collaborator.occupation;
                    let _id            = response.data[i]._id;

                    itensCopyCollaborators.push({
                        idCollaborator,
                        avatar,
                        name,
                        collaborator,
                        occupation,
                        _id,
                    });


                }
                setShowCollaboratorSavedInOccupation(itensCopyCollaborators);
                setCountCollaboratorSavedInTheOccupation(itensCopyCollaborators)
                setLoadCollaboratorOccupation(false)

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadCollaboratorOccupation(false)
            });

    }

    //FecharEditarCargo
    function FecharEditarCargo() {

        setOccupatioEdit([]);
        setShowSkillInTheOccupation([]);
        setSkillFilterForThisOccupation([]);
        setCollaboratorFilterForThisOccupation([]);
        setShowCollaboratorSavedInOccupation([]);
        setLoadCollaboratorOccupation(true)
        setLoadSkills(true)

        setVisibleEdiCargo(!visibleEdiCargo);
    }



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameCadCargo, setLoadNameCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadMinSalaryCadCargo, setLoadMinSalaryCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadMaxSalaryCadCargo, setLoadMaxSalaryCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDepartamentoCadCargo, setLoadDepartamentoeCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleCadCargo, setVisibleCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadHabilidadeCadCargo, setLoadHabilidadeCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadColCadCargo, setLoadColCadCargo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    //const [ cargo, setOccupatioEdit] = useState([]);

    function CadastraCargo() {

        setVisibleCadCargo(!visibleCadCargo);
        setLoadNameCadCargo(false)


        window.setTimeout(function () {
            document.getElementById('nomeCargo').focus();
        }, 0);

    }
      /////////////////////////////////////////////////////////////////////////
     ///////   Buscar Habilidades para cadastrar no cargo     ////////////////
    /////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSkillFilterForThisOccupation, setLoadSkillFilterForThisOccupation ] = useState(false);

    //collaboratorFilterForThisSkill
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillFilterForThisOccupation, setSkillFilterForThisOccupation ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadNameCadOccupationHab] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameSkill, setNameSkill ] = useState([]);


    async function filterSkillForThisOccupation(event) {

        let nam = event.target.name;
        let val = event.target.value;

        if(event.target.value.length === 0) {

            setSkillFilterForThisOccupation([]);
            setNameSkill([])

        }
        else {

            setNameSkill({ ...nameSkill, [nam]: val })

            if(nameSkill.name && nameSkill.name === "") {

                setSkillFilterForThisOccupation([]);

            }
            else {

                setLoadSkillFilterForThisOccupation(true)
                setSkillFilterForThisOccupation(false)
                setSelectSectorAdd(false)

                await api.get(`/skill?name=${nameSkill.name}`)
                .then(response => {

                    setLoadHabilidadeCadCargo(false);
                    setLoadSkillFilterForThisOccupation(false);
                    setSkillFilterForThisOccupation(response.data);

                }).catch(error => {
                    setLoadHabilidadeCadCargo(false);
                    setLoadSkillFilterForThisOccupation(false);
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }

        }

    }

      ///////////////////////////////////////////////////////////////////////////////
     /////////////      Adicionar skills para cadastrar no cargo     ///////////////
    ///////////////////////////////////////////////////////////////////////////////



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillsOccupation, setSkillsOccupation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadToAddSkillToOccupation, setLoadToAddSkillToOccupation ] = useState(false);

    async function myChangeHandlerSkillToAddOccupation(event) {
        let val = event.target.id;

        let arrCol = skillFilterForThisOccupation;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setSkillFilterForThisOccupation(arrCol);
        setLoadToAddSkillToOccupation(true)
        await api.get(`/skill/${val}`)
        .then(response => {

            const itensCopyCollaborators = Array.from(skillsOccupation);

            const skillsOccupationExists =
            itensCopyCollaborators.findIndex(objeto => objeto.name === response.data.name);

            if(skillsOccupationExists === -1) {

                itensCopyCollaborators.push({idcollaborator: skillsOccupation.length, name: response.data.name, _id : response.data._id });
                setSkillsOccupation(itensCopyCollaborators);
                setNameSkill([]);

            }
            setLoadToAddSkillToOccupation(false);
            setLoadNameCadOccupationHab(false);
            setSkillFilterForThisOccupation([]);
            setNameSkill([]);


        }).catch(error => {
            setLoadToAddSkillToOccupation(false);
            setLoadNameCadOccupationHab(false);
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


      ///////////////////////////////////////////////////////////////////////////////
     ////////////////// Excluir skills para cadastrar novo grupo ///////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadExcludeSkillAddedOccupation, setLoadExcludeSkillAddedOccupation ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allSkillExcludeOccupation, setAllSkillExcludeOccupation] = useState([])

    var id_skill_excluded_occupation = null;
    async function myChangeHandlerExcludeSkillAddedOccupation(event) {
        event.preventDefault();

        id_skill_excluded_occupation = event.target.value;
        setAllSkillExcludeOccupation(id_skill_excluded_occupation)

        if(skillsOccupation && skillsOccupation.length !== 0) {

            setLoadExcludeSkillAddedOccupation(true)
            let arraySkills = skillsOccupation;
            let indexColSkills = arraySkills.findIndex(objeto => objeto._id === id_skill_excluded_occupation);
            arraySkills.splice(indexColSkills, 1);
            setSkillsOccupation(arraySkills);
            setLoadExcludeSkillAddedOccupation(false)

        }

    }



      /////////////////////////////////////////////////////////////////////////////////////////////////
     ///////////////     => FIM DE CADASTRAR HABILIDADES => OCCUPATION       /////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////

      /////////////////////////////////////////////////////////////////////////////////
     /////// Buscar colaboradores para cadastrar nova occupation ////////////////////
    ///////////////////////////////////////////////////////////////////////////////



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorFilterForThiOccupation, setLoadCollaboratorFilterForThiOccupation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameCollaborator, setNameCollaborator ] = useState([]);

    async function loadFilterCollaboratorForThisOccupation(event) {

        let nam = event.target.name;
        let val = event.target.value;
        if(event.target.value.length === 0) {

            setNameCollaborator([]);
            setCollaboratorFilterForThisOccupation([]);

        }
        else {

            setNameCollaborator({ ...nameCollaborator, [nam]: val })

            if(nameCollaborator.name && nameCollaborator.name === "") {

                setCollaboratorFilterForThisOccupation([]);

            }
            else {

                setLoadCollaboratorFilterForThiOccupation(true)
                await api.get(`/collaborator-new?name=${val}&role=user&role=manager&role=admin`)
                .then(response => {

                    setCollaboratorFilterForThisOccupation(response.data.collaboratorSearch);
                    setLoadColCadCargo(false)
                    setLoadCollaboratorFilterForThiOccupation(false);

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }

        }

    }


      ///////////////////////////////////////////////////////////////////////////////
     ///////////// Adicionar colaboradores para cadastrar novo grupo ///////////////
    ///////////////////////////////////////////////////////////////////////////////

    //loadNameCadOccupationCollaborator
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadNameCadOccupationCollaborator] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorsOccupation, setCollaboratorsOccupation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadToAddCollaboratorOccupation, setLoadToAddCollaboratorOccupation ] = useState(false);


    async function myChangeHandlerToAddCollaboratorOccupation(event) {

        let val = event.target.id;

        let arrCol = collaboratorFilterForThisOccupation;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setCollaboratorFilterForThisOccupation(arrCol);
        setLoadToAddCollaboratorOccupation(true)
        await api.get(`/collaborator/${val}`)
        .then(response => {

            const itensCopyCollaborators = Array.from(collaboratorsOccupation);

            const collaboratorsOccupationExists = collaboratorsOccupation.findIndex(objeto => objeto._id === val);

            if(collaboratorsOccupationExists === -1) {

                itensCopyCollaborators.push({idcollaborator: collaboratorsOccupation.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setCollaboratorsOccupation(itensCopyCollaborators);
                setNameCollaborator([])

            }

            setLoadToAddCollaboratorOccupation(false)
            setLoadNameCadOccupationCollaborator(false)
            setNameCollaborator([])

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }
      ///////////////////////////////////////////////////////////////////////////////
     //////////     Exclui colaboradores para cadastrar no cargo    ////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadExcludeCollaboratorAddedOccupation, setLoadExcludeCollaboratorAddedOccupation ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allCollaboratorsExcludeOccupation, setAllCollaboratorsExcludeOccupation] = useState([])

    var id_collaborator_excluded_group = null;
    async function myChangeHandlerExcludeCollaboratorAddedOccupation(event) {
        event.preventDefault();

        ////impeditivo
        id_collaborator_excluded_group = event.target.value;
        setAllCollaboratorsExcludeOccupation(id_collaborator_excluded_group)

        if(collaboratorsOccupation && collaboratorsOccupation.length !== 0) {

            setLoadExcludeCollaboratorAddedOccupation(true)
            let arrayColaboradores = collaboratorsOccupation;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excluded_group);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setCollaboratorsOccupation(arrayColaboradores);
            setLoadExcludeCollaboratorAddedOccupation(false)

        }

    }
      /////////////////////////////////////////////////////////////////////////////////////////////////
     ////////////////////////       => CADASTRAR OCCUPATION => CARGO      ////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors] = useState([])

    async function loadsectors() {

        await api.get('/sector/')
        .then(response => {

            setSectors(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupationToSave, setOccupationToSave] = useState([]);

    async function myChangeHandlerToSaveOccupation(event) {
        let nam = event.target.name;
        let val = event.target.value;


        if(nam === 'name') {

            if(val.length !== 0) {
                setLoadNameCadCargo(false)
                setOccupationToSave({ ...occupationToSave, [nam]: val, path: path })
            }
            else {
                setLoadNameCadCargo(true)
                setOccupationToSave({ ...occupationToSave, [nam]: val })
            }

        }
        if(nam === 'sector') {

            if(val.length !== 0) {
                setLoadDepartamentoeCadCargo(false)
                setOccupationToSave({ ...occupationToSave, [nam]: val })
            }
            else {
                setLoadDepartamentoeCadCargo(true)
            }

        }
        else {
            //setLoadNameCadGrupo(false)
            setOccupationToSave({ ...occupationToSave, [nam]: val })
        }

    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {

            saveOccupation(event)

        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRegisterNewOccupation, setLoadRegisterNewOccupation] = useState(false);

    async function saveOccupation(event) {
        event.preventDefault();

        if(
            occupationToSave                             &&
            occupationToSave.name          !== undefined &&
            occupationToSave.name          !== ""
        ) {
              ////////////////////////////////////////////////////////////////////
             //////    CADASTRA HABILIDADES E COLABORADORES NO CARGO       //////
            ////////////////////////////////////////////////////////////////////
            setLoadRegisterNewOccupation(true)
            await api.post("/occupation/", { occupationToSave, collaboratorsOccupation, skillsOccupation })
            .then(response => {

                setLoadNameCadCargo(false);
                setLoadMinSalaryCadCargo(false);
                setLoadMaxSalaryCadCargo(false);
                setLoadDepartamentoeCadCargo(false);

                loadoccupationUpdate();
                setLoadHabilidadeCadCargo(false);
                setLoadColCadCargo(false);
                setCollaboratorFilterForThisOccupation([]);
                setCollaboratorsOccupation([]);
                setSkillFilterForThisOccupation([]);
                setSkillsOccupation([]);
                setOccupationToSave([]);
                setLoadRegisterNewOccupation(false);
                setVisibleCadCargo(!visibleCadCargo);

            }).catch(error => {
                setLoadRegisterNewOccupation(false);
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });
        }
        else {

            setLoadNameCadCargo(true);

        }

    }


      /////////////////////////////////////////////////////////////////////////////////////////////////
     /////////////////////     ==> FIM DE CADASTRAR OCCUPATION <== CARGO   ///////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////


      /////////////////////////////////////////////////////////////////////////////////////////////////
     ////////////////////////       ==> EDITAR OCCUPATION <== CARGO        ///////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadToAddSkillToEditOccupation ] = useState(false);

    async function myChangeHandlerToAddSkillToEditOccupation(event) {

        let val = event.target.id;
        let arrCol = skillFilterForThisOccupation;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setSkillFilterForThisOccupation(arrCol);
        setLoadToAddSkillToEditOccupation(true)

        await api.get(`/skill/${val}`)
        .then(response => {

            const itensCopyCollaborators = Array.from(showSkillInTheOccupation);

            const showSkillInTheOccupationExists =
            itensCopyCollaborators.findIndex(objeto => objeto.name === response.data.name);

            if(showSkillInTheOccupationExists === -1) {

                itensCopyCollaborators.push({idSkill: showSkillInTheOccupation.length, name: response.data.name, _id : response.data._id });
                setShowSkillInTheOccupation(itensCopyCollaborators);

                const itensCountSkills = Array.from(countSkillSavedInTheOccupation);
                itensCountSkills.push({idSkill: showSkillInTheOccupation.length, name: response.data.name, _id : response.data._id });
                setCountSkillSavedInTheOccupation(itensCountSkills)

                setNameSkill([]);

            }

            setLoadToAddSkillToEditOccupation(false);
            setSkillFilterForThisOccupation([]);
            setNameSkill([]);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setSkillExcludedOccupation] = useState([])

    // eslint-disable-next-line no-redeclare
    var id_skill_excluded_occupation = null;
    async function myChangeHandlerExcludeSkillAddedEditInTheOccupation(event) {
        // event.preventDefault();

        id_skill_excluded_occupation = event._id;
        setSkillExcludedOccupation(id_skill_excluded_occupation)

        if(showSkillInTheOccupation && showSkillInTheOccupation.length !== 0) {

            //setLoadExcludeCollaboratorAddedGroup(true)
            let arrayColaboradores = showSkillInTheOccupation;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_skill_excluded_occupation);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setShowSkillInTheOccupation(arrayColaboradores);
            setCountSkillSavedInTheOccupation(arrayColaboradores);

            let arrayColaboradoresDelete = countSkillDeleteInTheOccupation;
            arrayColaboradoresDelete.push({
                _id: id_skill_excluded_occupation
            })

            setCountSkillDeleteInTheOccupation(arrayColaboradoresDelete);

        } else {

        }

    }

    async function myChangeHandlerToAddCollaboratorEditOccupation(event) {

       let val = event.target.id;

       let arrCol = collaboratorFilterForThisOccupation;
       let indexCol = arrCol.findIndex(objeto => objeto._id === val);
       arrCol.splice(indexCol, 1);
       setCollaboratorFilterForThisOccupation(arrCol);
       //setLoadToAddCollaborator(true)
       await api.get(`/collaborator/${val}`)
       .then(response => {
           
        const itensCopyCollaborators = Array.from(showCollaboratorSavedInOccupation);
        const showCollaboratorSavedInOccupationExists =
        itensCopyCollaborators.findIndex(objeto => objeto.collaborator === val);
        if(showCollaboratorSavedInOccupationExists === -1) {

            setNameCollaborator([])

            const itensCountCollaborators = Array.from(countCollaboratorSavedInTheOccupation);

            const itensCountCollaboratorsExists =
            itensCountCollaborators.findIndex(objeto => objeto._id === val);

            if(itensCountCollaboratorsExists === -1) {

                itensCopyCollaborators.push({idcollaborator: showCollaboratorSavedInOccupation.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setShowCollaboratorSavedInOccupation(itensCopyCollaborators);

                itensCountCollaborators.push({idcollaborator: showCollaboratorSavedInOccupation.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setCountCollaboratorSavedInTheOccupation(itensCountCollaborators)

                setNameCollaborator([])

            }

        }


            setCollaboratorFilterForThisOccupation([]);
            setNameCollaborator([]);

       }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
       });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setAllCollaboratorsExcludedOccupation] = useState([])

    var id_collaborator_excluded_occupation = null;
    async function myChangeHandlerExcludeCollaboratorAddedEditInTheOccupation(event) {

        event.preventDefault();

        id_collaborator_excluded_occupation = event.target.value;
        setAllCollaboratorsExcludedOccupation(id_collaborator_excluded_occupation)

        if(showCollaboratorSavedInOccupation && showCollaboratorSavedInOccupation.length !== 0) {

            //setLoadExcludeCollaboratorAddedOccupation(true)
            let arrayColaboradores = showCollaboratorSavedInOccupation;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excluded_occupation);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setShowCollaboratorSavedInOccupation(arrayColaboradores);
            //setLoadExcludeCollaboratorAddedOccupation(false)

            await api.delete(`/collaborators-occupation/${id_collaborator_excluded_occupation}`)
            .then(response => {

                setTimeout(() => {
                    loadoccupationUpdate()
                }, 500);

            }).catch(error => {
                //swal({ icon: "error", title: "Erro!", text: "Impossível deletar colaborador." });
            });


        } else {

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupationToEdit, setOccupationToEdit] = useState([])

    async function myChangeHandlerToEditOccupation(event) {
        let nam = event.target.name;
        let val = event.target.value;


        let id = occupatioEdit._id;
        setOccupationToEdit({ ...occupationToEdit, [nam]: val, id, path: path })

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadUpdateGroup, setLoadUpdateOccupation] = useState(false);

    async function updateOccupation(event) {

        if(
            (occupationToEdit &&
            occupationToEdit.name          !== undefined &&
            occupationToEdit.name          !== "")        ||
            (occupationToEdit.minimumSalary !== undefined &&
            occupationToEdit.minimumSalary !== "")        ||
            (occupationToEdit.maximumSalary !== undefined &&
            occupationToEdit.maximumSalary !== "")        ||
            (occupationToEdit.sector        !== undefined &&
            occupationToEdit.sector        !== "")

        ) {

            setLoadUpdateOccupation(true)
            await api.put(`/occupation/${occupationToEdit.id}`, { occupationToEdit, countCollaboratorSavedInTheOccupation, countSkillSavedInTheOccupation, countSkillDeleteInTheOccupation })
            .then(response => {

                loadoccupation();
                setLoadUpdateOccupation(false)
                setOccupationToEdit([]);
                setShowCollaboratorSavedInOccupation([]);
                setVisibleEdiCargo(!visibleEdiCargo);
                setShowSkillInTheOccupation([]);
                setCountCollaboratorSavedInTheOccupation([]);
                setCountSkillSavedInTheOccupation([]);
                setSkillFilterForThisOccupation([]);

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadUpdateOccupation(false);
            });

        }
        else {

            let occupationEdited = {
                name          : event.nameOccupatiomn,
                maximumSalary : event.maximumSalary,
                minimumSalary : event.minimumSalary,
                sector        : event.sector,
            }

            setLoadUpdateOccupation(true)
            await api.put(`/occupation/${event._id}`, { occupationEdited, countCollaboratorSavedInTheOccupation, countSkillSavedInTheOccupation, countSkillDeleteInTheOccupation })
            .then(response => {

                loadoccupation();
                setLoadUpdateOccupation(false)
                setOccupationToEdit([]);
                setShowCollaboratorSavedInOccupation([]);
                setVisibleEdiCargo(!visibleEdiCargo);
                setShowSkillInTheOccupation([]);
                setCountCollaboratorSavedInTheOccupation([]);
                setCountSkillSavedInTheOccupation([]);
                setSkillFilterForThisOccupation([]);

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadUpdateOccupation(false);
            });

        }

    }

    //// ==> deletar cargo
    async function deleteOccupation(e) {

        swal({
            title: "Atenção",
            text: "Deseja realmente excluir este cargo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.delete(`/occupation/${e._id}?path=${path}`)
                .then(response => {

                    loadoccupation();

                }).catch(error => {});

            }
        });

    }

    const { user } = decodeToken();

    async function closeSelect(e) {
        if(
            e.target.className !== "busca-col" &&
            e.target.className !== "busca-hab" &&
            e.target.className !== "btn-select selectSectorAdd" &&
            e.target.className !== "span-select-sector"

        ) {
            setSkillFilterForThisOccupation(false)
            setCollaboratorFilterForThisOccupation(false)
            setSelectSectorAdd(false)


        } else {
            if (e.target.className === "busca-hab") {
                // alert("aqui")
                setSkillFilterForThisOccupation(!skillFilterForThisOccupation)
                setCollaboratorFilterForThisOccupation(false)
                setSelectSectorAdd(false)
            }else
            if (e.target.className === "busca-col") {
                // alert("aqui")
                setNameCollaborator({});
                setSkillFilterForThisOccupation(false)
                setCollaboratorFilterForThisOccupation(!collaboratorFilterForThisOccupation)
                setSelectSectorAdd(false)
            }else
            if(
                e.target.className === "btn-select selectSectorAdd" &&
                e.target.className === "span-select-sector"
            ) {
                    setSelectSectorAdd(!selectSectorAdd)
                    setSkillFilterForThisOccupation(false)
                    setCollaboratorFilterForThisOccupation(false)
            }
        }
    }


    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    /// => Selects personalizado
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ selectSectorAdd, setSelectSectorAdd] = useState(false);

    async function OpenSelectSectorAdd(event) {
        setSelectSectorAdd(!selectSectorAdd)
        setSkillFilterForThisOccupation(false)
        setCollaboratorFilterForThisOccupation(false)
    }



    function handleKeyPressCadastrarCargo(event) {
        if (event.key === "Enter") {
            saveOccupation(event);
        }
    }
    function handleKeyPressAtualizarCargo(event) {
        if (event.key === "Enter") {
            updateOccupation(event);
        }
    }


    //FecharCadastraCargo
    function FecharCadastraCargo() {

        setNameCollaborator([]);
        setOccupationToSave([]);
        setSkillFilterForThisOccupation([]);
        setCollaboratorFilterForThisOccupation([]);
        setVisibleCadCargo(!visibleCadCargo);
        setCollaboratorsOccupation([])

        setSelectSectorAdd(false)
        setSkillFilterForThisOccupation(false)
        setCollaboratorFilterForThisOccupation(false)
    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                    loadPage={loadPage}
                    nameSkill={nameSkill}
                    nameCollaborator={nameCollaborator}
                    occupation={occupation}
                    loadfilteroccupation={(event) => loadfilteroccupation(event)}
                    CadastraCargo={CadastraCargo}
                    visibleCadCargo={visibleCadCargo}
                    loadNameCadCargo={loadNameCadCargo}
                    loadMinSalaryCadCargo={loadMinSalaryCadCargo}
                    loadMaxSalaryCadCargo={loadMaxSalaryCadCargo}
                    loadDepartamentoCadCargo={loadDepartamentoCadCargo}
                    loadHabilidadeCadCargo={loadHabilidadeCadCargo}
                    loadColCadCargo={loadColCadCargo}
                    loadFilterCollaboratorForThisOccupation={(event) => loadFilterCollaboratorForThisOccupation(event)}
                    collaboratorFilterForThisOccupation={collaboratorFilterForThisOccupation}
                    loadCollaboratorFilterForThiOccupation={loadCollaboratorFilterForThiOccupation}
                    myChangeHandlerToAddCollaboratorOccupation={myChangeHandlerToAddCollaboratorOccupation}
                    collaboratorsOccupation={collaboratorsOccupation}
                    loadToAddCollaboratorOccupation={loadToAddCollaboratorOccupation}
                    myChangeHandlerExcludeCollaboratorAddedOccupation={myChangeHandlerExcludeCollaboratorAddedOccupation}
                    loadExcludeCollaboratorAddedOccupation={loadExcludeCollaboratorAddedOccupation}
                    allCollaboratorsExcludeOccupation={allCollaboratorsExcludeOccupation}
                    filterSkillForThisOccupation={(event) => filterSkillForThisOccupation(event)}
                    skillFilterForThisOccupation={skillFilterForThisOccupation}
                    loadSkillFilterForThisOccupation={loadSkillFilterForThisOccupation}
                    myChangeHandlerSkillToAddOccupation={myChangeHandlerSkillToAddOccupation}
                    loadToAddSkillToOccupation={loadToAddSkillToOccupation}
                    skillsOccupation={skillsOccupation}
                    myChangeHandlerExcludeSkillAddedOccupation={myChangeHandlerExcludeSkillAddedOccupation}
                    loadExcludeSkillAddedOccupation={loadExcludeSkillAddedOccupation}
                    allSkillExcludeOccupation={allSkillExcludeOccupation}
                    myChangeHandlerToSaveOccupation={myChangeHandlerToSaveOccupation}
                    sectors={sectors}
                    saveOccupation={saveOccupation}
                    handleKeyPressCadastrarCargo={handleKeyPressCadastrarCargo}
                    loadRegisterNewOccupation={loadRegisterNewOccupation}
                    FecharCadastraCargo={FecharCadastraCargo}
                    occupationToSave={occupationToSave}
                    user={user}
                    closeSelect={closeSelect}
                    selectSectorAdd={selectSectorAdd}
                    OpenSelectSectorAdd={OpenSelectSectorAdd}
                    setOccupationToSave={setOccupationToSave}
                    handleKeyPress={handleKeyPress}
                />

                <div className="page-cargo-toogle">
                    <ul>
                        <li className={gridCargos ? "grid select" : "grid"} onClick={() => SelectGrid()} >Grid</li>
                        <li className={listCargos ? "lista select" : "lista"} onClick={() => SelectGridList()} >Lista</li>
                    </ul>
                </div>
                {
                loadPage ?
                (
                    <>

                    <div className="carregamento">
                        <img alt="" src={Carregamento} />
                    </div>
                    </>
                ):
                occupation && occupation[0] !== undefined && occupation.length !== 0  ? (
                <>
                {
                    gridCargos ? (
                    <GridCargos
                        loadPageOccupationGrid={loadPageOccupationGrid}
                        occupation={occupation}
                        EditarCargo={EditarCargo}
                        deleteOccupation={deleteOccupation}
                    />
                    ) : ('')
                }

                {
                    listCargos ? (
                    <ListCargos
                        occupation={occupation}
                        EditarCargo={EditarCargo}
                        loadoccupationCrescente={loadoccupationCrescente}
                        loadoccupationDecrescente={loadoccupationDecrescente}
                        loadPageList={loadPageList}
                        deleteOccupation={deleteOccupation}
                    />
                    ) : ('')
                }

                </>
                ) : (
                    <>
                    <div className="estado-vazio-pg-cargos">
                        <p>Nenhum cargo cadastrado.</p>
                        <button className="btnGreen more cadastrar-cargo" onClick={() => CadastraCargo()} >Cadastrar novo cargo</button>
                        <img src={EstadoVazio} alt="Sem cargos" />

                    </div>
                    </>
                )}






            <Modal show={visibleEdiCargo} restoreFocus={false} className="popup-geral popup-edit-cargo" onHide={FecharEditarCargo}>
                <div className='fechar-select' onClick={closeSelect}>
                    <div className="titulo">
                        <h4>Editar cargo <p className="btn-fechar" onClick={FecharEditarCargo}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="infor-edit-cargo">
                        <div className="faixa-salarial">
                            <p>De: R$ {occupatioEdit.minimumSalary ? occupatioEdit.minimumSalary : '0,00'} <br/>
                            Até: R$ {occupatioEdit.maximumSalary ?  occupatioEdit.maximumSalary : '0,00'}</p>
                            <span>faixa salarial</span>
                        </div>
                        <div className="col-comCargo">
                            <p>{occupatioEdit.numberOfCollaboratorsOccupation}</p>
                            <span>colaboradores com este cargo</span>
                        </div>
                        <div className="data-cadastro">
                            <span>cadastrado em <br/>
                                {occupatioEdit.createdFormated}</span>
                        </div>
                    </div>
                    <div className="form-novo-cargo">
                        <input
                        type="text"
                        name="name"
                        onChange={myChangeHandlerToEditOccupation}
                        onKeyPress={(event) => handleKeyPressAtualizarCargo(event)}
                        id='nameCargo'
                        value={
                            (occupationToEdit &&
                            occupationToEdit.name) ||
                            occupationToEdit.name === "" ?
                            occupationToEdit.name
                            :
                            occupatioEdit &&
                            occupatioEdit.nameOccupatiomn ?
                            occupatioEdit.nameOccupatiomn
                            :
                            ''
                        }
                        placeholder="Nome do cargo"
                        className={loadNameEdiCargo ? 'inputErro' : ''}
                        />
                        {loadNameEdiCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                        <h4>Faixa salarial</h4>
                        <div className="salar-minMax">
                            <div className="minMax">
                                <CurrencyInput
                                    className="salary-input"
                                    onChangeEvent={myChangeHandlerToEditOccupation}
                                    name="minimumSalary"
                                    value={
                                        (occupationToEdit &&
                                        occupationToEdit.minimumSalary) ||
                                        occupationToEdit.minimumSalary === "" ?
                                        occupationToEdit.minimumSalary
                                        :
                                        occupatioEdit &&
                                        occupatioEdit.minimumSalary ?
                                        occupatioEdit.minimumSalary
                                        :
                                        ''
                                    }
                                />
                            </div>
                            <div className="minMax">
                                <CurrencyInput
                                    className="salary-input"
                                    onChangeEvent={myChangeHandlerToEditOccupation}
                                    name="maximumSalary"
                                    value={
                                        (occupationToEdit &&
                                        occupationToEdit.maximumSalary) ||
                                        occupationToEdit.maximumSalary === "" ?
                                        occupationToEdit.maximumSalary
                                        :
                                        occupatioEdit &&
                                        occupatioEdit.maximumSalary ?
                                        occupatioEdit.maximumSalary
                                        :
                                        ''
                                    }
                                />
                            </div>
                        </div>
                        <h4>Departamento</h4>
                        <div className='select-x'>
                            <button className={loadDepartamentoEdiCargo ? 'inputErro btn-select selectSectorAdd' : 'btn-select selectSectorAdd'}  id="selectSectorAdd" onClick={OpenSelectSectorAdd}>
                                <span  className='span-select-sector'>


                                    {  occupationToEdit?.sector !== undefined  ?
                                            occupationToEdit.sector !== undefined && sectors ?
                                                sectors
                                                .filter(filtersector =>
                                                    filtersector._id === occupationToEdit.sector
                                                ).map((sector, index) => (
                                                    sector.name
                                                ))
                                             :
                                            "Selecione um departamento"
                                        :
                                            occupatioEdit.sector

                                        }


                                </span>
                            </button>

                            {
                                selectSectorAdd ?
                                    <div className='resultado-busca'>
                                     {
                                        sectors && sectors.length !== 0 ?
                                        sectors.map((sector, index) => (
                                            <button  key={index} onClick={() =>
                                                setOccupationToEdit({  ...occupationToEdit, "sector": sector._id, id: occupatioEdit._id})}><p>{sector.name}</p></button>
                                        ))
                                        :
                                        <button><p>Nenhum setor cadastrado até o momento!</p></button>
                                    }
                                    </div>
                                :
                                ""
                            }
                        </div>

                        {loadDepartamentoEdiCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        <h4>Habilidades requeridas</h4>
                        <input
                        name="name"
                        value={nameSkill && nameSkill.name !== undefined ? nameSkill.name : ''}
                        onChange={filterSkillForThisOccupation}
                        placeholder="Habilidades requeridas para este cargo"
                        className={loadHabilidadeEdiCargo ? 'inputErro busca busca-hab' : 'busca busca-hab'}
                        />
                        {loadHabilidadeEdiCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        {/* //////// RESULTADO DE BUSCA HABILIDADE  ///////*/}
                        <div className="resultado-busca-habilidade" style={skillFilterForThisOccupation && skillFilterForThisOccupation.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                skillFilterForThisOccupation &&
                                skillFilterForThisOccupation.length !== 0 ?
                                skillFilterForThisOccupation.map((filterthisoccupation, index) => (
                                    <p onClick={myChangeHandlerToAddSkillToEditOccupation} id={filterthisoccupation._id}  key={index}>{filterthisoccupation.name}</p>
                                )):''
                            }

                        </div>
                        {/* //////// FIM RESULTADO DE BUSCA HABILIDADE  ///////*/}

                        {/* //////// ESTADO VAZIO DE BUSCA HABILIDADE  ///////*/}
                        {
                            loadSkills?
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            :
                            showSkillInTheOccupation &&
                            showSkillInTheOccupation.length !== 0 ?
                                <div className="habilidades-adicionada" >
                                    {
                                        showSkillInTheOccupation &&
                                        showSkillInTheOccupation.length !== 0 ?
                                        showSkillInTheOccupation
                                        .map((skillintheoccupation, index) => (
                                            <p key={index}>{skillintheoccupation.name} <button onClick={() => myChangeHandlerExcludeSkillAddedEditInTheOccupation(skillintheoccupation)} className="excluir"></button></p>
                                        )):''
                                    }
                                </div>
                            :
                            <>
                            <div className="vazio-busca-habilidade" style={showSkillInTheOccupation && showSkillInTheOccupation.length === 0 ? {display:'flex'} : {display:'none'}}>
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira habilidades</span>
                            </div>
                            </>
                        }
                        {/* //////// FIM HABILIDADES ADICIONADAS  ///////*/}

                        <h4>Colaboradores com este cargo</h4>
                        <input
                        name="name"
                        value={nameCollaborator && nameCollaborator.name !== undefined ? nameCollaborator.name : ''}
                        onChange={loadFilterCollaboratorForThisOccupation}
                        placeholder="Atrelar colaboradores à este cargo"
                        className={loadColEdiCargo ? 'inputErro busca-col busca' : 'busca busca-col'}
                        />
                        {loadColEdiCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        {/* //////// RESULTADO DE BUSCA DE CARGO  ///////*/}
                        <div className="resultado-busca-atrelados-col" style={collaboratorFilterForThisOccupation && collaboratorFilterForThisOccupation.length !== 0 ? {display:'block'} : {display:'none'}}>
                        {
                                loadCollaboratorFilterForThiOccupation ?
                                <div className='carregamento-busca'>
                                    <img src={Carregamento} alt="" />
                                </div>
                                :
                                collaboratorFilterForThisOccupation &&
                                collaboratorFilterForThisOccupation.length !== 0 ?
                                collaboratorFilterForThisOccupation.map((filterforthisoccupation, index) => (
                                    <p onClick={myChangeHandlerToAddCollaboratorEditOccupation} id={filterforthisoccupation._id} key={index}>
                                    {
                                        filterforthisoccupation.avatar !== undefined ?
                                        <div className="img-col">
                                            <img id={filterforthisoccupation._id} src={filterforthisoccupation.avatar} alt={filterforthisoccupation.name} />
                                        </div>
                                        :
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img id={filterforthisoccupation._id} src={IconUserNone} alt={filterforthisoccupation.name} />
                                            </div>
                                        </div>
                                    }
                                <span id={filterforthisoccupation._id}>{filterforthisoccupation.name}</span>
                                </p>
                                )): ''
                            }
                        </div>
                        {/* //////// FIM RESULTADO DE BUSCA DE CARGO  ///////*/}

                        {/* //////// ESTADO VAZIO DE BUSCA DE CARGO  ///////*/}
                        {
                            loadCollaboratorOccupation ?
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            :
                            showCollaboratorSavedInOccupation &&
                            showCollaboratorSavedInOccupation.length !== 0 ?
                                <div className="lista-atrelados-col" >
                                    {
                                        showCollaboratorSavedInOccupation &&
                                        showCollaboratorSavedInOccupation.length !== 0 ?
                                        showCollaboratorSavedInOccupation.map((collaboratorsaved, index) => (
                                            <div key={index} className="infor-col">
                                                {
                                                    collaboratorsaved.avatar !== undefined ?
                                                    <div className="img-col">
                                                        <img src={collaboratorsaved.avatar} alt={collaboratorsaved.name} />
                                                    </div>
                                                    :
                                                    <div className="colSemFoto">
                                                        <div className="semImgCol">
                                                            <img  src={IconUserNone} alt={collaboratorsaved.name}/>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="nome-setor">
                                                    <p className="nome">
                                                        {collaboratorsaved.name}
                                                    </p>
                                                    <div className="setor">
                                                        <p>{collaboratorsaved.occupation}</p>
                                                    </div>
                                                </div>
                                                <button onClick={myChangeHandlerExcludeCollaboratorAddedEditInTheOccupation} value={collaboratorsaved._id} className="remover">Remover</button>
                                            </div>
                                        )):''
                                    }
                                </div>
                            :
                            <div className="vazio-busca-cargo" style={showCollaboratorSavedInOccupation && showCollaboratorSavedInOccupation.length === 0 ? {display:'flex'} : {display:'none'}}>
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira colaboradores</span>
                            </div>
                        }
                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE CARGO  ///////*/}

                        {/* //////// CARGOS ADICIONADOS  ///////*/}
                        {/* <div className="lista-atrelados-col" >
                            {
                            loadCollaboratorOccupation ?
                            ''
                            :
                                showCollaboratorSavedInOccupation &&
                                showCollaboratorSavedInOccupation.length !== 0 ?
                                showCollaboratorSavedInOccupation.map((collaboratorsaved, index) => (
                                    <div key={index} className="infor-col">
                                        {
                                            collaboratorsaved.avatar !== undefined ?
                                            <div className="img-col">
                                                <img src={collaboratorsaved.avatar} alt={collaboratorsaved.name} />
                                            </div>
                                            :
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt={collaboratorsaved.name}/>
                                                </div>
                                            </div>
                                        }
                                        <div className="nome-setor">
                                            <p className="nome">
                                                {collaboratorsaved.name}
                                            </p>
                                            <div className="setor">
                                                <p>{collaboratorsaved.occupation}</p>
                                            </div>
                                        </div>
                                        <button onClick={myChangeHandlerExcludeCollaboratorAddedEditInTheOccupation} value={collaboratorsaved._id} className="remover">Remover</button>
                                    </div>
                                )):''
                            }
                        </div> */}
                        {/* //////// FIM CARGOS ADICIONADOS  ///////*/}

                    </div>
                    <button
                    onClick={() => updateOccupation(occupatioEdit)}
                    disabled={loadUpdateGroup ? true : false}
                    // onChange={() => setOccupationToEdit({  ...occupationToEdit, id: occupatioEdit._id})}
                    className={loadUpdateGroup ? "button-salve btnGreen load" : "button-salve btnGreen"}
                    >
                    Salvar cargo
                    </button>
                </div>
            </Modal>


            </Layout>
        </>
    );
}

