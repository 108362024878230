import React, {  useState, useEffect } from 'react';
import api from '../../../../../services/api';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Carregando from '../../../../../assets/images/carregando.gif';

export default function formPersonalData({
    id,
    path,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    Loadphone               ,
    Loadcellphone           ,
    LoadaddressNumber       ,
    Loadaddress             ,
    Loadzipcode             ,
    LoadaddressNeighborhood ,
    LoadaddressCity         ,
    LoadaddressState        ,
    focusphone              ,
    focuscellphone          ,
    focusaddressNumber      ,
    focusaddress            ,
    focuszipcode            ,
    focusaddressNeighborhood,
    focusaddressCity        ,
    focusaddressState
}) {


//////////////////
//////////////////
//////////////////
 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [ load, setLoad ] = useState(false);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [filesSaved, setFilesSaved] = useState([]);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [, setFileNames] = useState([]);

 // eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(id && id !== undefined) {

        async function loadfilessaved() {

            setLoad(true)

            await api.get(`/collaborator-file/?id=${id}&category=location`)
            .then(response => {
                setLoad(false)
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();

    }


}, [id]);

 async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNames(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'location');
            //  formData.append('collaborator', collaborator._id);
            formData.append('collaborator', id);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file/?id=${id}&category=location`)
                    .then(response => {
                        if(response.data[0] !== undefined) {

                            setLoad(false)
                            setFilesSaved(response.data);

                        }

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                console.log(error.response.data)
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

 }

 //const deleteFile = async (filesaved) => {
async function deleteFile(filesaved) {
 swal({
     title: "Atenção",
     text: "Deseja excluir este arquivo?",
     icon: "warning",
     buttons: ["Cancelar", "OK"],
     dangerMode: false,
 })
     .then(async (res) => {
         if (res) {
             setLoad(true)
             await api.delete(`/collaborator-file/${filesaved._id}?path=${path}`)
                 .then(() => {
                     async function loadfilessaved() {
                         setLoad(false)
                         await api.get(`/collaborator-file/?id=${id}&category=location`)
                         .then(response => {
                                    setFilesSaved(response.data);

                         }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                     }
                     loadfilessaved();
                     swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                 }).catch(() => {
                     swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                 });
         }
     });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(id && id !== undefined) {

        async function loadcollaboratorsaved() {
            await api.get(`/collaborator/${id}`)
            .then(response => {
                setCollaboratorSaved(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadcollaboratorsaved();

    }


}, [id]);
        return (
            <>
                <div className="contact-address">
                        <h3>Contato e endereço</h3>



                        <div className="box-grid">

                            <div className='linha'>
                                <div className='box'>
                                    <h5>Contato</h5>
                                    <div> </div>
                                </div>
                                <div className='box'></div>
                            </div> 

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Telefone</p>
                                    <div>

                                        {
                                        collaboratorUpdate && collaboratorUpdate.phone !== undefined ?
                                        (
                                            <input ref={focusphone} type="text" autocomplete="autocompleteoff" name="phone" value={collaboratorUpdate.phone ? collaboratorUpdate.phone : ''} onChange={onCollaboratorInputChange} placeholder="Telefone" className={Loadphone ? 'inputUm inputErro' : 'inputUm'} />
                                        ) :
                                        collaboratorSaved.phone !== undefined ?
                                        (
                                            <input ref={focusphone} type="text" autocomplete="autocompleteoff" name="phone" defaultValue={collaboratorSaved.phone ? collaboratorSaved.phone : ''} onChange={onCollaboratorInputChange} placeholder="Telefone" className="inputUm"/>
                                        ):
                                        (
                                            <input ref={focusphone} type="text" autocomplete="autocompleteoff" name="phone" value={collaboratorUpdate.phone ? collaborator.phone : ''} onChange={onCollaboratorInputChange} placeholder="Telefone" className={Loadphone ? 'inputUm inputErro' : 'inputUm'} />
                                        )}
                                        {Loadphone ? (
                                            <p className="textError inputUm">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Celular</p>
                                    <div>
                                        {
                                        collaboratorUpdate && collaboratorUpdate.cellphone !== undefined ?
                                        (<input ref={focuscellphone} type="text" name="cellphone" autocomplete="autocompleteoff" value={collaboratorUpdate.cellphone ? collaboratorUpdate.cellphone : ''} onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputDois inputErro' : 'inputDois'} />) :
                                        collaboratorSaved.cellphone !== undefined ?
                                        (<input ref={focuscellphone} type="text" name="cellphone" autocomplete="autocompleteoff" defaultValue={collaboratorSaved.cellphone ? collaboratorSaved.cellphone : ''} onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputDois inputErro' : 'inputDois'}/>):
                                        (<input ref={focuscellphone} type="text" name="cellphone" autocomplete="autocompleteoff" value={collaborator && collaborator.cellphone ? collaborator.cellphone : ''} onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputDois inputErro' : 'inputDois'}/>)}
                                        {Loadcellphone ? (
                                        <p className="textError inputDois">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                    </div>
                                </div>
                            </div> 

                            <div className='linha'>
                                <div className='box'>
                                    <h5>Endereço</h5>
                                    <div> </div>
                                </div>
                                <div className='box'></div>
                            </div> 

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">CEP</p>
                                    <div>
                                        {
                                            collaboratorUpdate && collaboratorUpdate.zipcode ?
                                            (
                                                <input ref={focuszipcode}  type="text" name="zipcode" autoComplete="autocompleteoff" value={collaboratorUpdate.zipcode ? collaboratorUpdate.zipcode : ''} onChange={onCollaboratorInputChange} className={Loadzipcode ? 'inputTreis inputErro' : 'inputTreis'} />
                                            ) :
                                            collaboratorSaved && collaboratorSaved.zipcode ?
                                            (
                                                <input ref={focuszipcode}  type="text" name="zipcode" autocomplete="autocompleteoff" defaultValue={collaboratorSaved.zipcode ? collaboratorSaved.zipcode : ''} onChange={onCollaboratorInputChange} className={Loadzipcode ? 'inputTreis inputErro' : 'inputTreis'} />
                                            ) :
                                            (
                                                <input ref={focuszipcode}  type="text" name="zipcode"  autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="CEP" className={Loadzipcode ? 'inputTreis inputErro' : 'inputTreis'} />
                                            )
                                        }
                                        {Loadzipcode ? (
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Número</p>
                                    <div>
                                        <input ref={focusaddressNumber} type="text" name="addressNumber" autocomplete="autocompleteoff" defaultValue={
                                            collaboratorUpdate && collaboratorUpdate.addressNumber ?
                                            collaboratorUpdate.addressNumber :
                                            collaboratorSaved && collaboratorSaved.addressNumber ?
                                            collaboratorSaved.addressNumber :
                                            ''
                                        } onChange={onCollaboratorInputChange} placeholder="Número" className={LoadaddressNumber ? 'inputQuatro inputErro' : 'inputQuatro'} />
                                        {LoadaddressNumber ? (
                                        <p className="textError inputQuatro">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                    </div>
                                </div>
                            </div> 

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Rua ou avenida</p>
                                    <div>

                                        <input type="text" name="address" autocomplete="autocompleteoff" defaultValue={
                                            collaboratorUpdate && collaboratorUpdate.address ?
                                            collaboratorUpdate.address :
                                            collaboratorSaved && collaboratorSaved.address ?
                                            collaboratorSaved.address
                                            : ''
                                        } onChange={onCollaboratorInputChange} placeholder="Rua ou avenida" className="inputCinco"/>
                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Bairro</p>
                                    <div>
                                        <input type="text" name="addressNeighborhood" autocomplete="autocompleteoff" defaultValue={
                                            collaboratorUpdate && collaboratorUpdate.addressNeighborhood ?
                                            collaboratorUpdate.addressNeighborhood :
                                            collaboratorSaved && collaboratorSaved.addressNeighborhood ?
                                            collaboratorSaved.addressNeighborhood :
                                            ''
                                        } onChange={onCollaboratorInputChange} placeholder="Bairro" className="inputSeis"/>
                                    </div>
                                </div>
                            </div> 

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Estado</p>
                                    <div>
                                        <input type="text" name="addressState" defaultValue={
                                            collaboratorUpdate && collaboratorUpdate.addressState ?
                                            collaboratorUpdate.addressState :
                                            collaboratorSaved && collaboratorSaved.addressState ?
                                            collaboratorSaved.addressState :
                                            ''
                                        }autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="Estado" className="inputSete"/>

                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Cidade</p>
                                    <div>
                                        <input type="text" name="addressCity" defaultValue={
                                            collaboratorUpdate && collaboratorUpdate.addressCity ?
                                            collaboratorUpdate.addressCity :
                                            collaboratorSaved && collaboratorSaved.addressCity ?
                                            collaboratorSaved.addressCity :
                                            ''
                                        } autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="Cidade" className="inputOito"/>
                                    </div>
                                </div>
                            </div> 

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Complemento</p>
                                    <div>
                                        <input type="text" name="addressComplement" autocomplete="autocompleteoff" defaultValue={
                                            collaboratorUpdate && collaboratorUpdate.addressComplement ?
                                            collaboratorUpdate.addressComplement :
                                            collaboratorSaved && collaboratorSaved.addressComplement ?
                                            collaboratorSaved.addressComplement :
                                            ''
                                        } onChange={onCollaboratorInputChange} placeholder="Complemento" className="inputNove"/>

                                    </div>
                                </div>
                                <div className='box'>
                                </div>
                            </div> 

                            <div className='linha'>
                                <div className='box doc-dropzone'>
                                    <p className="textCampo"></p>
                                    <div>
                                        <Dropzone onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone " })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                        <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                    

                                    </div>
                                </div>
                                <div className='arquivoAnexado'>
                                    {filesSaved ?
                                    filesSaved.map((filesaved, index) => (
                                        <div key={index} className="arquivo">

                                            <a className="nomeArquivo" href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                                            <div>
                                                <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>

                                            </div>
                                        </div>
                                        )) : ''}
                                    {load ?
                                    (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                                    ('')}
                                </div>
                            </div>
                        </div>

                    </div>


            </>
        );
}
