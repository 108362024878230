import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../components/Layouts/defaultUser';
import AddCircle from '../../assets/images/add-circle.svg';
import IconExclui from '../../assets/images/close-branco.svg';
import { NavLink } from 'react-router-dom'
import SideBar from '../../components/DashboardUser/SideBar';
import iconV from '../../assets/images/v.svg';
import Carregando from '../../assets/images/carregando.gif';
import ArrowLeft from '../../assets/images/arrow-left2.svg';
import { Modal, Dropdown } from "react-bootstrap";
import Close from '../../assets/images/close.svg';
import { dateFormatedHours } from '../../services/utils';
import { decodeToken } from '../../services/auth';
import swal from 'sweetalert';
import api from '../../services/api';
import { toast } from 'react-toastify';
const DashboardUser = (props) => {

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleNewPraiseWall, setVisibleNewPraiseWall] = useState(false);
    function AddNewPraiseWall() {
        setVisibleNewPraiseWall(!visibleNewPraiseWall);
    }

    function NoAddNewPraiseWall() {
        setLoadPraiseWallContent(false);
        setLoadPraiseWallColor(false);
        setVisibleNewPraiseWall(!visibleNewPraiseWall);
    }

    /////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPagePraiseAcess, setLoadPagePraiseAcess ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorPraiseAcess, setcollaboratorPraiseAcess ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaborator();
    }, []);


    async function loadcollaborator() {
        setLoadPagePraiseAcess(true)
        await api.get("/collaborator-praise-access/")
        .then(response => {
            setLoadPagePraiseAcess(false)
            setcollaboratorPraiseAcess(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    //////////////////////////////////////////////////////////////////////
    /////////////////////       Loads         ///////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPraiseWallContent, setLoadPraiseWallContent] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPraiseWallColor, setLoadPraiseWallColor] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnPraiseWall, setLoadBtnPraiseWall] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ praiseRegistration, setPraiseRegistration ] = useState([]);

    const decoded = decodeToken();

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        setPraiseRegistration({ ...praiseRegistration, [nam]: val, path: path })

        if(nam === "praiseTitle") {
            if(val.length > 0) {
                setPraiseRegistration({ ...praiseRegistration, [nam]: val, path: path })
                setLoadPraiseWallContent(false)
            }
            else {
                setLoadPraiseWallContent(true)
            }
        } else {
            setLoadPraiseWallColor(false)
            setPraiseRegistration({ ...praiseRegistration, [nam]: val, path: path })
        }


    };
    async function handleSubmit(event) {
        event.preventDefault();

        if (praiseRegistration.praiseTitle === undefined || praiseRegistration.praiseTitle === "") {
            setLoadPraiseWallContent(true)
        } else {
            setLoadPraiseWallContent(false)
        }

        if (praiseRegistration.praiseColor === undefined || praiseRegistration.praiseColor === "") {
            setLoadPraiseWallColor(true)
        } else {
            setLoadPraiseWallColor(false)
        }

        let collaboratorId = decoded.user._id;
        setPraiseRegistration({ ...praiseRegistration, "collaborator": collaboratorId, path: path })

        if (
            praiseRegistration.praiseTitle              !== undefined &&
            praiseRegistration.praiseTitle              !==     ""    &&
            praiseRegistration.praiseColor              !== undefined &&
            praiseRegistration.praiseColor              !==     ""    &&
            praiseRegistration.collaborator             !== undefined &&
            praiseRegistration.collaborator             !==     ""
        ) {
            setLoadPagePraiseAcess(true)
            setLoadBtnPraiseWall(true)
            await api.post('/collaborator-praise-access', praiseRegistration)
            .then(response => {
                setPraiseRegistration([])
                async function loadPraise() {
                    await api.get(`/collaborator-praise-access/`)
                    .then(response => {
                        setVisibleNewPraiseWall(!visibleNewPraiseWall);
                        setLoadPagePraiseAcess(false)
                        setLoadBtnPraiseWall(false)
                        setcollaboratorPraiseAcess(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadPraise();
                swal({ icon: "success", title: "Sucesso!", text: "Elogio criado com sucesso!" });
            }).catch(error => {
                setLoadPagePraiseAcess(false)
                setLoadBtnPraiseWall(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao criar elogio, tente novamente mais tarde." });
            });
        }
    }
    ////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////
    /// => Aqui você deleta uma XXXXX
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    async function deletePraise(_id) {

        swal({
            title: "Atenção",
            text: "Deseja excluir este elorgio?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoadPagePraiseAcess(true)
                await api.delete(`/collaborator-praise-access/${_id}?path=${path}`)
                .then(() => {
                    setLoadPagePraiseAcess(false)
                    async function loadfilessaved() {
                        await api.get(`/collaborator-praise-access/`)
                        .then(response => {
                            setcollaboratorPraiseAcess(response.data);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Feito!", text: "Elogio removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o elogio, tente novamente mais tarde." });
                });
            }
        });
    }


    async function priseFilter(e) {

        let id = e.target.id;

        if(id === 'today') {

            async function loadPraiseToday() {
                await api.get('/collaborator-praise-access/?today=true')
                .then(response => {
                    setcollaboratorPraiseAcess(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadPraiseToday();

        }
        else
        if(id === 'myPrises') {

            async function loadPraiseMyPrises() {
                await api.get('/collaborator-praise-access/?myPrises=true')
                .then(response => {
                    setcollaboratorPraiseAcess(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadPraiseMyPrises();

        }
        else
        if(id === 'alphabeticalOrder') {

            async function loadPraiseAplphabeticaOlrder() {
                await api.get('/collaborator-praise-access/?alphabeticalOrder=true&sortBy=praiseTitle&order=asc')
                .then(response => {
                    setcollaboratorPraiseAcess(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadPraiseAplphabeticaOlrder();

        }
        else {}
    }
        return (
            <>
                <Layout path={path}>
                        <div className="dashbord-user">
                                <div className="page-mural-elogios">
                                    <div className="praise-wall">
                                    <div className="voltar-filtro">
                                            <NavLink to="/dashboard-user" className="voltar btnGray" ><img src={ArrowLeft} alt="ver mais" /> Voltar para o feed principal </NavLink>

                                            <Dropdown  className="dropdown-icon ">
                                                <Dropdown.Toggle>
                                                    Filtrar
                                                    <img alt="" src={iconV} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <button id="today" onClick={priseFilter}>Hoje</button>
                                                    <button id="myPrises" onClick={priseFilter}>Meus Elogios</button>
                                                    <button id="alphabeticalOrder" onClick={priseFilter}>Ordem alfabética</button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    </div>
                                    <h1 className="titulo">Mural de elogios <button className="btnGreen"  onClick={() => AddNewPraiseWall()}><img src={AddCircle} alt="Novo Elogio" /> Novo elogio</button></h1>
                                    <div className="wall post-it-styles ">
                                    {
                                        loadPagePraiseAcess ?
                                        (
                                            <>

                                                    <div className="carregamento">
                                                        <img alt="" src={Carregando} />
                                                    </div>
                                                    <div className="carregamento">
                                                        <img alt="" src={Carregando} />
                                                    </div>
                                                    <div className="carregamento">
                                                        <img alt="" src={Carregando} />
                                                    </div>
                                            </>
                                        ):
                                        collaboratorPraiseAcess[0] !== undefined ? collaboratorPraiseAcess
                                        .map((PraiseAcessList, index) => (
                                        <>
                                         <div
                                         className={
                                         PraiseAcessList.praiseColor                 === 'light-turquoise'    ? 'post-it post-it-turquoise' :
                                         PraiseAcessList.praiseColor                 === 'light-blue'    ? 'post-it post-it-blue ' :
                                         PraiseAcessList.praiseColor                 === 'light-orange'    ? 'post-it post-it-orange ' :
                                         PraiseAcessList.praiseColor                 === 'light-purple'    ? 'post-it post-it-purple ' :
                                         PraiseAcessList.praiseColor                 === 'light-red'    ? 'post-it post-it-red ' :
                                         PraiseAcessList.praiseColor                 === 'light-green'    ? 'post-it post-it-green ' :''
                                        }
                                        >
                                            <div className="post">
                                                <div className="titulo"><h4>{PraiseAcessList.praiseDateFormated}</h4>
                                                    {
                                                        PraiseAcessList.collaborator === decoded.user._id ?
                                                         <>
                                                         <button className="btn-editar"  onClick={() => deletePraise(PraiseAcessList && PraiseAcessList._id)}><img src={IconExclui} alt="Excluir" /></button>
                                                        </> : " "
                                                    }
                                                </div>
                                                <p>{PraiseAcessList.praiseTitle}</p>
                                                <span className="hora">{dateFormatedHours(PraiseAcessList.createdAt)}</span>
                                            </div>
                                        </div>
                                        </>
                                        )) : (
                                            <>
                                            <div></div>
                                             <div className="post-it post-it-blue" >
                                                    <div className="post">
                                                        <div className="titulo"><h4>Vazio</h4></div>
                                                        <p>Sem elogios ainda :(</p>
                                                        <span className="hora"></span>
                                                    </div>
                                                </div>
                                            <div></div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="sideBar">
                                <SideBar/>
                            </div>
                        </div>
                        <Modal show={visibleNewPraiseWall} className="popup-geral popup-user-PraiseWall" onHide={NoAddNewPraiseWall}>
                        <div>
                            <div className="titulo">
                                <h4>Novo elogio<p className="btn-fechar" onClick={NoAddNewPraiseWall}><img src={Close} alt="Fechar" /></p></h4>
                            </div>
                            <div className="post-PraiseWall">
                                <div className="post-it-styles">
                                    <div

                                     className={
                                        praiseRegistration.praiseColor                 === 'light-turquoise'    ? 'post-it post-it-turquoise' :
                                        praiseRegistration.praiseColor                 === 'light-blue'    ? 'post-it post-it-blue ' :
                                        praiseRegistration.praiseColor                 === 'light-orange'    ? 'post-it post-it-orange ' :
                                        praiseRegistration.praiseColor                 === 'light-purple'    ? 'post-it post-it-purple ' :
                                        praiseRegistration.praiseColor                 === 'light-red'    ? 'post-it post-it-red ' :
                                        praiseRegistration.praiseColor                 === 'light-green'    ? 'post-it post-it-green ' :'post-it post-it-orange'
                                       }
                                    >
                                        <div className="post">
                                            <div className="titulo"><h4>Hoje</h4></div>
                                            <p>{praiseRegistration.praiseTitle}</p>
                                            {/* <span className="hora">às 10:27</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-PraiseWall">
                                    <span className="contador-textarea">{praiseRegistration && praiseRegistration.praiseTitle === undefined ? "0" : praiseRegistration.praiseTitle.length }  / 50</span>
                                    <textarea name="praiseTitle" placeholder="Digite uma breve mensagem…" maxlength="50" onChange={myChangeHandler}  className={loadPraiseWallContent ? 'inputErro' : ''}></textarea>
                                    {loadPraiseWallContent ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                    <h5>Escolha uma cor para o post-it</h5>


                                    <ul className="alterar-cores">
                                        <li className="radio">
                                            <input type="radio" value="light-turquoise" name="praiseColor"  onChange={myChangeHandler}id="light-turquoise" />
                                            <label htmlFor="light-turquoise" className="corSelecionada light-turquoise"></label>
                                        </li>
                                        <li className="radio">
                                            <input type="radio" value="light-blue" name="praiseColor" onChange={myChangeHandler} id="light-blue"/>
                                            <label htmlFor="light-blue" className="corSelecionada light-blue"></label>
                                        </li>
                                        <li className="radio">
                                            <input type="radio" value="light-orange" name="praiseColor" onChange={myChangeHandler} id="light-orange"/>
                                            <label htmlFor="light-orange" className="corSelecionada light-orange"></label>
                                        </li>
                                        <li className="radio">
                                            <input type="radio" value="light-purple" name="praiseColor" onChange={myChangeHandler} id="light-purple" />
                                            <label htmlFor="light-purple" className="corSelecionada light-purple"></label>
                                        </li>
                                        <li className="radio">
                                            <input type="radio" value="light-red" name="praiseColor" onChange={myChangeHandler} id="light-red" />
                                            <label htmlFor="light-red" className="corSelecionada light-red"></label>
                                        </li>
                                        <li className="radio">
                                            <input type="radio" value="light-green" name="praiseColor" onChange={myChangeHandler} id="light-green" />
                                            <label htmlFor="light-green" className="corSelecionada light-green"></label>
                                        </li>
                                    </ul>
                                    {loadPraiseWallColor ? (
                                        <p className="textError">É necessário selecionar uma cor.</p>
                                    ) : ('')}
                                </div>
                            </div>

                            <div className="btn-salvar" onSubmit={handleSubmit} >
                                <button onClick={handleSubmit} type="submit" name="category" value="Férias" className={loadBtnPraiseWall  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                            </div>
                        </div>
                    </Modal>


                </Layout>
            </>
        );
    }
export default DashboardUser;
