import React, { useState, useEffect, useRef } from 'react';

import api from '../../../services/api';
import { mCNPJ, mNumber, dateMask } from '../../../services/mask';
import SelectPersonalizado from '../../../components/Reused/selectPersonalizado'
import swal from 'sweetalert';

export default function headerBenefitRegister({ history, path }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadName, setLoadName ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCategory, setLoadCategory] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCompanyCostIsPaid, setLoadCompanyCostIsPaid] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCnpjOperator, setLoadCnpjOperator] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setRegisteredBeneftis] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ benefit, setBenefit] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDiscountPercentage, setloadDiscountPercentage] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusName   = useRef(null);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const focusOperator   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCnpjOperator   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCategory   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusBalanceCalculation   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCompanyCostIsPaid   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCutoffDate   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusDiscountPercentage  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadbenefits();

    }, []);

    async function loadbenefits() {

        await api.get('/beneftis/')
        .then(response => {

            setRegisteredBeneftis(response.data);

        }).catch(error => {});
    }

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === "name"){
            setBenefit({ ...benefit, [nam]: val, path: path })
            setLoadName(false)
        }else

        if (nam === "category") {

            let category = val;
            let operator = undefined;


            setLoadCategory(false)
            setBenefit({ ...benefit, operator, category })

        } else
        if (nam === "cnpjOperator") {

            let cnpjOperator = mCNPJ(val)
            setBenefit({ ...benefit, [nam]: cnpjOperator })
            setLoadCnpjOperator(false)

        } else
        if (nam === "cutoffDate"){

            let cutoffDate = mNumber(val)
            setBenefit({ ...benefit, [nam]: cutoffDate })

        } else
        if (nam === "contractExpirationDate"){

            let contractExpirationDate = dateMask(val)
            setBenefit({ ...benefit, [nam]: contractExpirationDate })

        } else
        if (nam === "discountPercentage"){
            let discountPercentage = mNumber(val)
            setBenefit({ ...benefit, [nam]: discountPercentage })
            setloadDiscountPercentage(false)
        }
        else if(nam === "companyCostIsPaid"){
            if(val === "Sim") {
                let valSim = true;
                setBenefit({ ...benefit, [nam]: valSim })
            } else {
                let valNao = false;
                setBenefit({ ...benefit, [nam]: valNao })
            }
            setLoadCompanyCostIsPaid(false)
            
        }
        else
        if(nam === 'benefitDiscount') {
            if(event.target.checked === true) {
                let benefitDiscount = 'true';
                let salaryDiscount = 'false';
                setBenefit({ ...benefit, benefitDiscount, salaryDiscount  })
            }
            else {
                let benefitDiscount = 'false';
                let salaryDiscount = 'true';
                setBenefit({ ...benefit, benefitDiscount, salaryDiscount  })
            }
        } else
        if(nam === 'salaryDiscount') {
            if(event.target.checked === true) {
                let benefitDiscount = 'false';
                let salaryDiscount = 'true';
                setBenefit({ ...benefit, benefitDiscount, salaryDiscount  })
            }
            else {
                let benefitDiscount = 'true';
                let salaryDiscount = 'false';
                setBenefit({ ...benefit, benefitDiscount, salaryDiscount  })
            }
        }
        else {
            setBenefit({ ...benefit, [nam]: val })
        }


    };

    async function handleSubmit(event) {
        event.preventDefault();

        if (
            benefit.name === undefined ||
            benefit.name === ''
        ) {
            focusName.current.focus();
            setLoadName(true)
            // return
        }

        if (
            benefit.category === undefined ||
            benefit.category === ''
        ) {
            focusCategory.current.focus();
            setLoadCategory(true)
            // return
        }

        if (
            benefit.cnpjOperator === undefined ||
            benefit.cnpjOperator === ''
        ) {
            focusCnpjOperator.current.focus();
            setLoadCnpjOperator(true)
            // return
        }

        if (
            benefit.discountPercentage === undefined ||
            benefit.discountPercentage === ''
        ) {
            focusDiscountPercentage.current.focus();
            setloadDiscountPercentage(true)
            // return
        }

        if (
            benefit.companyCostIsPaid === undefined ||
            benefit.companyCostIsPaid === ''
        ) {
            focusCompanyCostIsPaid.current.focus();
            setLoadCompanyCostIsPaid(true)
            // return
        }
        if(
            benefit.name === undefined ||
            benefit.name === '' ||
            benefit.category === undefined ||
            benefit.category ===  '' ||
            benefit.cnpjOperator === undefined ||
            benefit.cnpjOperator ===  '' ||
            benefit.discountPercentage === undefined ||
            benefit.discountPercentage ===  '' ||
            benefit.companyCostIsPaid === undefined ||
            benefit.companyCostIsPaid ===  ''
        ) {
            return
        }
        setLoad(true)

        await api.post("/beneftis/", benefit)
        .then(response => {
            setTimeout(function(){history.goBack();}, 2000);
            swal({ icon: "success", title: "Sucesso!", text: "Benefício criado com sucesso!" });
        }).catch(error => {
            swal({
                icon: "error",
                title: "Erro!",
                text: error?.response?.data?.message ?
                error?.response?.data?.message
                :
                error?.response?.data?.error ?
                error?.response?.data?.error
                :
                'Não foi possível cadastrar um benefício, por favor tente mais tarde'

            });
        });

    }

    const categoryBenefit = [
        'Alimentação',
        'Alimentação e Refeição',
        'Assistência à Educação',
        'Auxílio home-office',
        'Bem-estar',
        'Combustível',
        'Convênio Farmácia',
        'Clube de Benefícios',
        'Cultura',
        'Day Off',
        'Estacionamento',
        'Licença maternidade',
        'Licença Paternidade estendida',
        'Mobilidade',
        'Outros',
        'Plano de Saúde',
        'Plano Odontológico',
        'Plano de Saúde Pet',
        'Previdência Privada',
        'Premiação',
        'Refeição',
        'Seguro de Vida',
        'Transporte'

    ]
    const operadorBenefit = benefit?.category === "Alimentação" ||
        benefit.category === "Refeição" ||
        benefit.category === "Alimentação e Refeição" ||
        benefit.category === "Transporte" ||
        benefit.category === "Mobilidade" ||
        benefit.category === "Combustível"  ? [
            "Sodexo",
            "Ticket",
            "Alelo",
            "Flash",
            "VR",
            "Swile"
            ]
        : benefit && benefit.category === "Plano de Saúde" ? [
            "Unimed",
            "Clinipam",
            "Bradesco Seguros",
            "Paraná Clínicas",
            "Sul América",
            "Amil",
            "Nossa Saúde",
            "MedSul"
            ]
        : benefit && benefit.category === "Plano Odontológico" ? [
            "Dental Uni",
            "Amil Dental",
            "Sul América Odonto",
            "Porto Seguro",
            "Bradesco Seguro Dental",
            "Dentalprev"
            ]
        : benefit && benefit.category === "Seguro de Vida" ? [
            "Prudential",
            "Porto Seguro",
            "Metlife",
            "Sul América",
            "Santander",
            "Bradesco Seguros"
            ]
            
        : benefit && benefit.category === "Bem-estar" ?  [
            "Zenklub",
            "Keiken",
            "Gympass",
            "Total Pass"
            ] : []
    ;


    return (
        <>
        <div className='page-cad-benefit'>

            <div className="register-benefit">

                <ul className="form-register">
                    <li>
                        <div className="intemA">
                                <p>Nome *</p>
                        </div>
                        <div className="intemB">
                            <input type="text" name="name" autoComplete="off" ref={focusName} placeholder="Exemplo: Vale refeição" className={loadName ? 'inputErro' : ''} onChange={myChangeHandler}/>
                            {loadName ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Categoria  *</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    
                                    benefit && benefit.category !== undefined ?
                                    benefit.category : ''
                                }
                                nameButton={"category"}
                                myChangeHandler={myChangeHandler}
                                optionFil={categoryBenefit}
                                loadError={loadCategory}
                                focusRef={focusCategory}
                                typeSelect="busca2"
                            />
                                
                            {loadCategory ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>CNPJ do operador  *</p>
                        </div>
                        <div className="intemB">
                            <input ref={focusCnpjOperator}  maxLength="18" type="text" autoComplete="off" onChange={myChangeHandler} value={benefit && benefit.cnpjOperator ? benefit.cnpjOperator : ''} name="cnpjOperator" placeholder="Digite o CNPJ" className={loadCnpjOperator ? 'inputErro' : ''}/>
                            {loadCnpjOperator ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>
                    </li>
                    {
                    (benefit && benefit.category === "Alimentação" )||
                    benefit.category === "Refeição" ||
                    benefit.category === "Alimentação e Refeição" ||
                    benefit.category === "Transporte" ||
                    benefit.category === "Mobilidade" ||
                    benefit.category === "Plano de Saúde"||
                    benefit.category === "Plano Odontológico" ||
                    benefit.category === "Seguro de Vida"  ||
                    benefit.category === "Bem-estar" ||
                    benefit.category === "Combustível"  ? (
                    <li>
                        <div className="intemA">
                            <p>Operador</p>
                        </div>
                        <div className="intemB">

                        <SelectPersonalizado
                                valorCampo={
                                    
                                    benefit && benefit.operator !== undefined ?
                                    benefit.operator : ''
                                }
                                nameButton={"operator"}
                                myChangeHandler={myChangeHandler}
                                option={ operadorBenefit }
                                loadError={loadCategory}
                                focusRef={focusCategory}
                            />
                            {/* {loadOperador ? <><p className="textError">É necessário escolher o operador</p></> : ''} */}
                        </div>
                    </li>
                    ):('')} 
                </ul>
                <ul className="form-register form-registerDois">
                    <li className="check-li">
                        <div className="intemA">
                            <p>Porcentagem de desconto  *</p>
                        </div>
                        <div className="intemB">
                            <input type="text" name="discountPercentage" autoComplete="off" ref={focusDiscountPercentage} maxLength="3"  onChange={myChangeHandler} value={benefit && benefit.discountPercentage ? benefit.discountPercentage : ''} className={loadDiscountPercentage ? 'inputErro' : ''} placeholder="Digite a porcentagem a ser descontada" />
                            {loadDiscountPercentage ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                        </div>
                        <div className="intemB">
                            <div className="radioNovo">
                                <input type="radio" name="benefitDiscount" onChange={myChangeHandler} id="DescontoBeneficios"
                                checked={benefit && benefit.benefitDiscount === "true" ? true : benefit.benefitDiscount === undefined ?
                                true : false
                                }/>
                                <label htmlFor="DescontoBeneficios" className="foreignTexto">Desconto do benefício</label>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                        </div>
                        <div className="intemB">
                            <div className="radioNovo">
                                <input type="radio" name="salaryDiscount" id="DescontoSalario" onChange={myChangeHandler}  checked={benefit && benefit.salaryDiscount === "true" ? true : false}/>
                                <label htmlFor="DescontoSalario" className="foreignTexto">Desconto do Salario</label>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className="form-register">
                    {/* <li className="check-li">
                        <div className="intemA">
                            <p>Descontar</p>
                        </div>
                        <div className="intemB ">
                            <div className="chekbox">
                                <input type="checkbox" value="true" name="absencesInThePreviousMonth" onChange={myChangeHandler} check={false} />
                                <label className="foreignTexto">Faltas no mês anterior</label>
                            </div>
                            <div className="chekbox">
                                <input type="checkbox" value="true" name="vacationNextMonth" onChange={myChangeHandler} check={false} />
                                <label >Férias no mês seguinte</label>
                            </div>
                            <div className="chekbox">
                                <input type="checkbox" value="true" name="absencesInThePreviousMonth2" onChange={myChangeHandler} check={false} />
                                <label className="foreignTexto">Afastamentos no mês anterior</label>
                            </div>
                            <div className="chekbox">
                                <input type="checkbox" value="true" name="licensesInTheFollowingMonth" onChange={myChangeHandler} check={false} />
                                <label className="foreignTexto">Licenças no mês seguinte</label>
                            </div>
                            <div className="chekbox">
                                <input type="checkbox" value="true" name="vacationInCurrentMonth" onChange={myChangeHandler} check={false} />
                                <label className="foreignTexto">Férias no mês corrente</label>
                            </div>
                        </div>
                    </li> */}
                    <li>
                        <div className="intemA">
                                <p>Tipo de cálculo</p>
                        </div>
                        <div className="intemB">


                        <SelectPersonalizado
                                valorCampo={
                                    
                                    benefit && benefit.balanceCalculation !== undefined ?
                                    benefit.balanceCalculation : ''
                                }
                                nameButton={"balanceCalculation"}
                                myChangeHandler={myChangeHandler}
                                option={ [
                                    "Fixo mensal",
                                    "Dias úteis"
                                ] }
                                // loadError={loadCategory}
                                focusRef={focusBalanceCalculation}
                            />

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>Custo pago na folha</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    
                                    benefit && benefit.companyCostIsPaid !== undefined ?
                                    benefit.companyCostIsPaid === true ? 'Sim' : 'Não'
                                    : ""
                                }
                                focusRef={focusCompanyCostIsPaid} 
                                nameButton={"companyCostIsPaid"}
                                loadError={loadCompanyCostIsPaid}
                                myChangeHandler={myChangeHandler}
                                option={ [
                                    "Sim",
                                    "Não"
                                ] }
                            />

                            {loadCompanyCostIsPaid ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>

                        <div className="intemC">
                            <div className="tooltips">
                                <div className="tooltips-icon">
                                    ?
                                </div>

                                <div className="tooltips-infor">
                                    <p>
                                    Alguns benefícios podem ser pagos na folha (auxílio educação ou auxílio creche, por exemplo). Nestes casos, ao invés de pagar um fornecedor, o colaborador receberá em dinheiro. Ao marcar este campo, o valor será lançado na folha de pagamento dos colaboradores inscritos automaticamente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>Data de corte</p>
                        </div>
                        <div className="intemB">
                            <input type="text"  ref={focusCutoffDate}  maxLength="2"  autoComplete="off" onChange={myChangeHandler} name="cutoffDate" value={benefit && benefit.cutoffDate ? benefit.cutoffDate : ''}   placeholder="Digite o dia do mês" />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>Data de vencimento</p>
                        </div>
                        <div className="intemB">
                            <input type="text" onChange={myChangeHandler}  autoComplete="off" value={benefit && benefit.contractExpirationDate ? benefit.contractExpirationDate : ''}  name="contractExpirationDate" placeholder="Data de vencimento" />
                        </div>
                    </li>
                    <li className="check-li">
                        <div className="intemA">
                                <p>Descrição</p>
                        </div>
                        <div className="intemB">
                            <textarea name="description" onChange={myChangeHandler} cols="150" placeholder="Justificativa"></textarea>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

        <div className="btns-cadastra-benefit">
            <form onSubmit={handleSubmit} className="cadastra">
                <button type="submit" className="btnGreen" >Salvar</button>
            </form>
            <a href="/beneficios" className="btnGray cancelar">Cancelar</a>

        </div>
        </>
    );
}

