import React from 'react';
import SubMenu from './subMenu';
import TermContract from './Documents/TermContract';

const ColProfileProficionalDoc = ({ id, collaborator }) => {

        return (
            <>
                <SubMenu id={id} collaborator={collaborator}/>
                <div className="box-infor-perfil">
                    <TermContract
                        id={id}
                        collaboratorState={collaborator}
                    />
                </div>
            </>
        );
}
export default ColProfileProficionalDoc;
