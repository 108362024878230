import React, { useState, useEffect } from 'react';
import { Dropdown } from "react-bootstrap";
import iconJobVerde from '../../../../../assets/images/icon-job-verde.svg';
import iconMoneyVerde from '../../../../../assets/images/icon-money-verde.svg';
import iconVerMais from '../../../../../assets/images/more.svg';
import api from '../../../../../services/api';
import swal from 'sweetalert';
import { nMoney, dateMask } from '../../../../../services/mask';
import ModalGeral from '../../../../modal/modal-geral';
import SubMenu from './subMenu';
import { dateFormatedToHistory } from '../../../../../services/utils';
import Carregamento from '../../../../../assets/images/carregando.gif';
import IconAddBrnaco from '../../../../../assets/images/add-branco.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import {removerSpecials} from '../../../../../services/utils'
import SelectPersonalizado from '../../../../../components/Reused/selectPersonalizado'
import { toast } from 'react-toastify';

export default function ColProfileHistoriesPromotions({ id, role, path }) {

    const [visible, setVisible] = useState(false);

    const [idHistory, setIdHistory] = useState([]);

    function handleToggleVisible(e) {

        if(e && e !== undefined) {
            setIdHistory(e)
            setVisible(true);
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorHistory, setLoadCollaboratorHistory ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorHistory, setCollaboratorHistory ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadcollaboratorvacationhistory(id);
            loadcollaborator(id);
            loadBondAndSalary(id);
        }

        loadoccupations();
        loadsectors();
        loadcompanies();

        if(
            (role && role === 'admin')
            ||
            (role && role === 'master')
        ) {

            loadcostcenter();

        }


    }, [id, role]);


    async function loadcollaboratorvacationhistory(id) {
        setLoadCollaboratorHistory(true)

        await api.get(`/collaborator-history/${id}`)
        .then(response => {

            setLoadCollaboratorHistory(false)

            let arrayCollaboratorHistory = [];

            for(let ch=0; ch < response.data.length; ch++) {

                let reason = response.data[ch].reason;

                if(
                    reason === 'Acordo coletivo'                ||
                    reason === 'Admissão'                       ||
                    reason === 'Ajuste de bolsa auxilio'        ||
                    reason === 'Ajuste de pró labore'           ||
                    reason === 'Alteração de função'            ||
                    reason === 'Dissídio'                       ||
                    reason === 'Efetivação de estagiário'       ||
                    reason === 'Enquadramento de função'        ||
                    reason === 'Enquadramento salarial'         ||
                    reason === 'Espontâneo'                     ||
                    reason === 'Mudança de jornada'             ||
                    reason === 'Mérito/Reajuste'                ||
                    reason === 'Promoção'                       ||
                    reason === 'Redução de jornada de trabalho'
                ) {

                    arrayCollaboratorHistory.push(response.data[ch]);

                }
            }

            setCollaboratorHistory(arrayCollaboratorHistory);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadBondAndSalary(id) {
        await api.get(`/collaborator-bond-and-salary/${id}`)
        .then(response => {

            setRegisteredBondAndSalary(response.data[0]);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    /////////////////////////////////////////////////
    ///////               Post               ///////
    ///////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setRegisteredBondAndSalary ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupations, setOccupations ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costcenter, setCostcenter ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ companies, setCompanies ] = useState([]);


    async function loadoccupations() {
        await api.get('/occupation/?sortBy=name&order=asc')
        .then(response => {
            setOccupations(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadsectors() {
        await api.get('/sector/?sortBy=name&order=asc')
        .then(response => {
            setSectors(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcostcenter() {
        await api.get('/costcenter/')
        .then(response => {
            setCostcenter(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcompanies() {
        await api.get('/company-admin/')
        .then(response => {
            setCompanies(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalAddSalaryhistory, setIsModalAddSalaryhistory ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ salaryhistoryAdd, setSalaryhistoryAdd ] = useState([]);

    async function myChangeHandlerAdd(event) {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam === 'name') {
            setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: val,  "reason": val,  "type": "update",  "collaborator": id })
            setLoadErrorName(false)
        }
        else
        if(nam === 'occupation') {
            // setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: val })
            // setLoadName(false)

                setBuscaOcupacao({ ...buscaOcupacao, "nomeOcupação": val });
                setLoadOccupation(false)

                await api.get(`/occupation/?name=${val}`)
                .then(response => {
                    setBuscaOcupacaoState({ ...buscaOcupacaoState, "nomeOcupação": val });
                    setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: val  })
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
        }
        else
        if(nam === 'sector') {

            setBuscaSector({ ...buscaSector, "nomeSector": val });
            setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: val })

        }
        else
        if(nam === 'salary') {
            let salary = nMoney(val)
            setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: salary })
        }
        else
        if(nam === 'validOnTheDateOf') {
            let validOnTheDateOf = dateMask(val)
            setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: validOnTheDateOf })
            setLoadErrorValidOnTheDateOf(false)
        }
        else {
            setSalaryhistoryAdd({ ...salaryhistoryAdd, [nam]: val })
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtn, setLoadBtn ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadErrorName, setLoadErrorName ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadErrorValidOnTheDateOf, setLoadErrorValidOnTheDateOf ] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();

        if (salaryhistoryAdd && salaryhistoryAdd._id === undefined) {
            if (salaryhistoryAdd.name !== undefined) {
                var name = salaryhistoryAdd.name;
                setLoadErrorName(false)
            } else {
                setLoadErrorName(true)
                // return
            }

            if (salaryhistoryAdd.occupation !== undefined) {
                var occupation = salaryhistoryAdd.occupation
            }

            if (salaryhistoryAdd.salary !== undefined) {
                var salary = salaryhistoryAdd.salary
            }

            if (salaryhistoryAdd.validOnTheDateOf !== undefined) {
                var validOnTheDateOf = salaryhistoryAdd.validOnTheDateOf
            }else {
                setLoadErrorValidOnTheDateOf(true)
                // return
            }
            
            if (salaryhistoryAdd.bond !== undefined) {
                var bond = salaryhistoryAdd.bond
            }
            if (salaryhistoryAdd.sector !== undefined) {
                var sector = salaryhistoryAdd.sector
            }
            if (salaryhistoryAdd.costCenter !== undefined) {
                var costCenter = salaryhistoryAdd.costCenter
            }
            if (salaryhistoryAdd.description !== undefined) {
                var description = salaryhistoryAdd.description
            }
            if (salaryhistoryAdd.reason !== undefined) {
                var reason = salaryhistoryAdd.reason
            }
            if (salaryhistoryAdd.type !== undefined) {
                var type = salaryhistoryAdd.type
            }
            if (salaryhistoryAdd.collaborator !== undefined) {
                var collaborator = salaryhistoryAdd.collaborator
            }

            setLoadBtn(true)
            if (
                name                                  !== undefined ||
                occupation                            !== undefined ||
                salary                                !== undefined ||
                bond                                  !== undefined ||
                validOnTheDateOf                      !== undefined ||
                sector                                !== undefined ||
                costCenter                            !== undefined ||
                reason                                !== undefined ||
                collaborator                          !== undefined ||
                description                           !== undefined
            ) {
                if(
                    name                                  === "" ||
                    occupation                            === "" ||
                    salary                                === "" ||
                    validOnTheDateOf                      === "" ||
                    bond                                  === "" ||
                    sector                                === "" ||
                    costCenter                            === "" ||
                    reason                                === "" ||
                    collaborator                          === "" ||
                    description                           === ""
                ) {
                    swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser salvo em branco" });
                    setLoadBtn(false)
                } else {

                    await api.post(`/collaborator-history`, {
                        type,
                        name,
                        occupation,
                        salary,
                        validOnTheDateOf,
                        bond,
                        sector,
                        costCenter,
                        reason,
                        collaborator,
                        description,
                        path: path
                     })
                    .then(response => {

                        setLoadCollaboratorHistory(true);
                        setSalaryhistoryAdd({});
                        loadcollaboratorvacationhistory(id);
                        setIsModalAddSalaryhistory(false);
                        setLoadBtn(false);
                        swal({ icon: "success", title: "Sucesso!", text: "Histórico adicionado!" });

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Histórico não pode ser adicionado!" });
                        console.log(error)
                    });
                }

            } else {
                setLoadBtn(false)
                // swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });
                return
            }
        }
    }
    /////////////////////////////////////////////////
    ///////               Put               ///////
    ///////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPutSalaryhistory, setIsModalPutSalaryhistory ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ salaryhistoryRegistered , setSalaryhistoryPutRegistered ] = useState([]);

    function abrirPut(e) {
        setLoadErrorName(false)
        setLoadErrorValidOnTheDateOf(false)

        
        if(e && e !== undefined) {
            setIsModalPutSalaryhistory(true)
            setSalaryhistoryPutRegistered(e)
        }
    }


    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ salaryhistoryPut , setSalaryhistoryPut ] = useState([]);

      async function myChangeHandlerPut(event) {
          let nam = event.target.name;
          let val = event.target.value;

          if(nam === 'name') {
              setSalaryhistoryPut({ ...salaryhistoryPut, [nam]: val,  "reason": val, "type": "update", "collaborator": id  })
              setLoadErrorName(false)
          }
          else
          if(nam === 'salary') {
              let salary = nMoney(val)
              setSalaryhistoryPut({ ...salaryhistoryPut, [nam]: salary })
          }
          else
          if(nam === 'validOnTheDateOf') {
              let validOnTheDateOf = dateMask(val)
              setSalaryhistoryPut({ ...salaryhistoryPut, [nam]: validOnTheDateOf })
              setLoadErrorValidOnTheDateOf(false)
          }
          else
          if(nam === 'occupation') {

              setBuscaOcupacao({ ...buscaOcupacao, "nomeOcupação": val });
              setSalaryhistoryPut({ ...salaryhistoryPut, [nam]: val })

          }
          else
          if(nam === 'sector') {

              setBuscaSector({ ...buscaSector, "nomeSector": val });
              setSalaryhistoryPut({ ...salaryhistoryPut, [nam]: val })
            //   setLoadSector(false)

          }
          else {
              setSalaryhistoryPut({ ...salaryhistoryPut, [nam]: val })
          }

      }


      async function handleSubmitDois(event) {
        event.preventDefault();

        var name = null
        var occupation = null
        var salary = null
        var validOnTheDateOf = null
        var costCenter = null
        var sector = null
        var description = null
        var bond = null
        var reason = null
        var collaborator = null
        var type = null

        if (salaryhistoryRegistered && salaryhistoryRegistered._id !== undefined) {

            if (salaryhistoryPut.name !== undefined) {
                name = salaryhistoryPut.name;
            } else {
                name = salaryhistoryRegistered.name;
            }

            if (salaryhistoryPut.occupation !== undefined) {
                 occupation = salaryhistoryPut.occupation
            }else {
                 occupation = salaryhistoryRegistered.occupation;
            }

            if (salaryhistoryPut.salary !== undefined) {
                 salary = salaryhistoryPut.salary
            }else {
                 salary = salaryhistoryRegistered.salary;
            }


            if (salaryhistoryPut.validOnTheDateOf !== undefined) {
                 validOnTheDateOf = salaryhistoryPut.validOnTheDateOf
            }else {
                 validOnTheDateOf = salaryhistoryRegistered.validOnTheDateOf;
            }

            if (salaryhistoryPut.bond !== undefined) {
                 bond = salaryhistoryPut.bond
            }else {
                 bond = salaryhistoryRegistered.bond;
            }

            if (salaryhistoryPut.sector !== undefined) {
                 sector = salaryhistoryPut.sector
            }else {
                 sector = salaryhistoryRegistered.sector;
            }

            if (salaryhistoryPut.costCenter !== undefined) {
                 costCenter = salaryhistoryPut.costCenter
            }else {
                 costCenter = salaryhistoryRegistered.costCenter;
            }

            if (salaryhistoryPut.description !== undefined) {
                 description = salaryhistoryPut.description
            }else {
                 description = salaryhistoryRegistered.description;
            }

            if (salaryhistoryPut.reason !== undefined) {
                 reason = salaryhistoryPut.reason
            }else {
                 reason = salaryhistoryRegistered.reason;
            }

            if (salaryhistoryPut.collaborator !== undefined) {
                 collaborator = salaryhistoryPut.collaborator
            }else {
                 collaborator = salaryhistoryRegistered.collaborator._id;
            }

            if (salaryhistoryPut.type !== undefined) {
                 type = salaryhistoryPut.type
            }else {
                 type = "update"
            }

            var idGeral = salaryhistoryRegistered._id

            setLoadBtn(true)

            if(
                name                                  === "" ||
                occupation                            === "" ||
                salary                                === "" ||
                validOnTheDateOf                      === "" ||
                bond                                  === "" ||
                sector                                === "" ||
                costCenter                            === "" ||
                reason                                === "" ||
                collaborator                          === "" ||
                description                           === ""
            ) {
                swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser salvo em branco" });
                setLoadBtn(false)
            } else {
                await api.put(`/collaborator-history/${idGeral}`, {
                    type,
                    name,
                    occupation,
                    salary,
                    validOnTheDateOf,
                    bond,
                    sector,
                    costCenter,
                    reason,
                    collaborator,
                    description,
                    path: path
                    })
                .then(response => {

                    setLoadCollaboratorHistory(true);
                    setSalaryhistoryPutRegistered([]);
                    setSalaryhistoryPut([]);
                    loadcollaboratorvacationhistory(id);
                    setIsModalPutSalaryhistory(false);
                    setLoadBtn(false);
                    swal({ icon: "success", title: "Sucesso!", text: "Histórico editado!" });

                }).catch(error => {

                    swal({ icon: "error", title: "Erro!", text: "Histórico não pode ser adicionado!" });

                });
            }

        }
    }

    /////////////////////////////////////////////////
    ///////               delete             ///////
    ///////////////////////////////////////////////

    function deleteSalaryhistory(e) {

        swal({
            title: "Atenção",
            text: "Deseja excluir este histórico?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {

                await api.delete(`/collaborator-history/${e}?path=${path}`)
                .then(() => {

                    setLoadCollaboratorHistory(true)
                    setSalaryhistoryAdd({});
                    loadcollaboratorvacationhistory(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Histórico removido com sucesso." });

                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o histórico, tente novamente mais tarde." });
                });

            }
        });
    }

    const nameTipy = [
        "Acordo coletivo",
        "Admissão",
        "Ajuste de bolsa auxilio",
        "Ajuste de pró labore",
        "Alteração de função",
        "Dissídio",
        "Efetivação de estagiário",
        "Enquadramento de função",
        "Enquadramento salarial",
        "Espontâneo",
        "Mudança de jornada",
        "Mérito/Reajuste",
        "Promoção",
        "Redução de jornada de trabalho"
    ]
    const nameVinculo = [
        "Aprendiz",
        "CLT",
        "Contrato Intermitente",
        "Contrato por Tempo Determinado",
        "Diretor Estatutário",
        "Estágio",
        "Pessoa Jurídica",
        "Sócio",
        "Trabalhador Autônomo",
        "Trabalhador Voluntário",
        "Trabalhador Temporário",
        "Trabalhador Rural",
        "Teletrabalho"
    ]

    function handleKeyPress(event) {
        // console.log(event.key);
        if (event.key === "Enter") {

            handleSubmit(event)

        }
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSector, setBuscaSector ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSectorState, setBuscaSectorState ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadOccupation, setLoadOccupation ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaOcupacao, setBuscaOcupacao ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaOcupacaoState, setBuscaOcupacaoState ] = useState([]);
     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [isActiveSelectBuscaOccupation, setIsActiveSelectBuscaOccupation] = useState(false);
     async function SelectBuscaOccupation() { setIsActiveSelectBuscaOccupation(!isActiveSelectBuscaOccupation) }
     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [isActiveSelectBuscaSector, setIsActiveSelectBuscaSector] = useState(false);
     async function SelectBuscaSector() { setIsActiveSelectBuscaSector(!isActiveSelectBuscaSector) }
 

     const myChangeHandlerSelects = event => {

        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "nomeOcupação"){
            let valOcupation = removerSpecials(val).toLowerCase()
            setBuscaOcupacao({ ...buscaOcupacao, [nam]: val });
            setBuscaOcupacaoState({ ...buscaOcupacaoState, [nam]: valOcupation });
        }else

        if (nam === "nomeSector"){
            let valSector = removerSpecials(val).toLowerCase()
            setBuscaSector({ ...buscaSector, [nam]: val });
            setBuscaSectorState({ ...buscaSectorState, [nam]: valSector });
        }
    }

    /////////////////////////////////////////////
    ///////////////// Filtros  /////////////////
    ///////////////////////////////////////////
    const occupationsFiltre = occupations.filter((ocupacao) => {
        return removerSpecials(ocupacao.name).toLowerCase().includes(buscaOcupacaoState?.nomeOcupação !== undefined ? buscaOcupacaoState.nomeOcupação : "" )  ;
    });
    const sectorsFiltre = sectors.filter((sector) => {
        return removerSpecials(sector.name).toLowerCase().includes(buscaSectorState?.nomeSector !== undefined ? buscaSectorState.nomeSector : "" )  ;
    });

    return (
        <>

        <ModalGeral onClose={()=> setIsModalPutSalaryhistory(false)} isModalPutSalaryhistory={isModalPutSalaryhistory}>
            <div className="modal-form">
                <div className="titulo"><h4>Editar dados</h4></div>

                <ul className="ul-form">
                    <li>
                        <div className="intemA">
                            <p>Tipo *</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    salaryhistoryPut && salaryhistoryPut.name !== undefined ?
                                    salaryhistoryPut.name
                                    :
                                    salaryhistoryRegistered && salaryhistoryRegistered.name !== undefined ?
                                        salaryhistoryRegistered.name : ""
                                }
                                nameButton={"name"}
                                myChangeHandler={myChangeHandlerPut}
                                loadError={loadErrorName}

                                optionFil={nameTipy}
                                typeSelect="busca2"
                            />
                            {loadErrorName ? (<p className="textError inputTreis">O campo é obrigatório.</p> ) : ('')}
                        </div>
                    </li>

                    <li>
                        <div className="intemA">
                            <p>Cargo</p>
                        </div>
                        <div className="intemB">
                            <div className="select-busca-btn">
                                <input
                                    type="text"
                                    className={loadOccupation ? "menu-button inputErro" : "menu-button"}
                                    placeholder='Selecione'
                                    name="nomeOcupação"
                                    autoComplete="off"
                                    value={
                                        buscaOcupacao && buscaOcupacao?.nomeOcupação !== undefined ?
                                        buscaOcupacao?.nomeOcupação
                                        :
                                        salaryhistoryRegistered && salaryhistoryRegistered.occupation !== undefined ?
                                            salaryhistoryRegistered.occupation : ""
                                    }
                                    onClick={ SelectBuscaOccupation }
                                    onBlur={ () => (setIsActiveSelectBuscaOccupation(false)) }
                                    onChange={myChangeHandlerSelects}
                                />
                                <nav className={`menu ${isActiveSelectBuscaOccupation ? "ativo": ""}`} >

                                    <Scrollbars className='listDropDown'  style={{  height:
                                    occupationsFiltre.length === 0 ? 30 : "" ||
                                    occupationsFiltre.length === 1 ? 30 : ""  ||
                                    occupationsFiltre.length === 2 ? 70 : ""  ||
                                    occupationsFiltre.length === 3 ? 120 : 120
                                    }}>
                                        <ul>
                                            {
                                                occupationsFiltre.length === 0 ?
                                                    <li>
                                                        <button>Nada encontrado</button>
                                                    </li>
                                                :
                                                occupationsFiltre
                                                .map((occupation, index) => (
                                                    <li key={index}>
                                                        <button
                                                        value={occupation.name}
                                                        name="occupation"
                                                        onClick={myChangeHandlerPut}
                                                        >{occupation.name}</button>
                                                    </li>
                                                ))}
                                        </ul>

                                    </Scrollbars>
                                </nav>
                            </div>

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Salário</p>
                        </div>
                        <div className="intemB">


                       {
                        salaryhistoryPut && salaryhistoryPut.salary !== undefined ?
                        (
                            <input type="text" name="salary" maxLength="9" value={salaryhistoryPut.salary !== undefined ? salaryhistoryPut.salary : ''}  onChange={myChangeHandlerPut} placeholder="R$ 0,000" />
                        ) :
                        salaryhistoryRegistered && salaryhistoryRegistered.salary !== undefined  && salaryhistoryRegistered.salary !== "-" ? (
                            <input type="text" name="salary" value={salaryhistoryRegistered.salary !== undefined ? salaryhistoryRegistered.salary : ''}  onChange={myChangeHandlerPut} placeholder="R$ 0,000"/>
                        ): (
                            <input type="text" name="salary" maxLength="9" value="" onChange={myChangeHandlerPut} placeholder="R$ 0,000" />
                        )}

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>A partir de *</p>
                        </div>
                        <div className="intemB">
                            {
                                (salaryhistoryPut && salaryhistoryPut.validOnTheDateOf !== undefined) || salaryhistoryPut.validOnTheDateOf === "" ?
                                (
                                    <input type="text" name="validOnTheDateOf" maxLength="11"  value={(salaryhistoryPut.validOnTheDateOf !== undefined) ? salaryhistoryPut.validOnTheDateOf : ''}  onChange={myChangeHandlerPut} placeholder="DD/MM/AAAA" className={loadErrorValidOnTheDateOf ? 'inputErro' : ''}/>
                                ) :
                                salaryhistoryRegistered && salaryhistoryRegistered.validOnTheDateOf ? (
                                    <input type="text" name="validOnTheDateOf"  maxLength="11"  value={(salaryhistoryRegistered.validOnTheDateOf !== undefined) ? salaryhistoryRegistered.validOnTheDateOf : ''}  onChange={myChangeHandlerPut} placeholder="DD/MM/AAAA"/>
                                ):
                                (
                                    <input type="text" name="validOnTheDateOf" value={(salaryhistoryPut.validOnTheDateOf !== undefined) ? salaryhistoryPut.validOnTheDateOf : ''}  onChange={myChangeHandlerPut} placeholder="DD/MM/AAAA" className={loadErrorValidOnTheDateOf ? 'inputErro' : ''}/>
                                )
                            }

                            {loadErrorValidOnTheDateOf ? (<p className="textError inputTreis">O campo é obrigatório.</p> ) : ('')}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Vínculo</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    salaryhistoryPut && salaryhistoryPut.bond !== undefined ?
                                    salaryhistoryPut.bond
                                    :
                                    salaryhistoryRegistered && salaryhistoryRegistered.bond !== undefined ?
                                        salaryhistoryRegistered.bond :
                                    ""
                                }
                                nameButton={"bond"}
                                myChangeHandler={myChangeHandlerPut}

                                optionFil={nameVinculo}
                                typeSelect="busca2"
                            />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Departamentos</p>
                        </div>
                        <div className="intemB">
                            <div className="select-busca-btn">
                                <input
                                    type="text"
                                    className="menu-button"
                                    placeholder='Selecione'
                                    name="nomeSector"
                                    autoComplete="off"
                                    value={
                                        buscaSector?.nomeSector !== undefined ?
                                        buscaSector.nomeSector :
                                        buscaSector && buscaSector?.sector === '' ?
                                        ''
                                        :
                                        salaryhistoryRegistered && salaryhistoryRegistered?.sector ?
                                        salaryhistoryRegistered.sector :
                                            ''
                                    }
                                    onClick={ SelectBuscaSector }
                                    onBlur={ () => (setIsActiveSelectBuscaSector(false)) }
                                    onChange={myChangeHandlerSelects}
                                />
                                <nav className={`menu ${isActiveSelectBuscaSector ? "ativo": ""}`} >
                                    <Scrollbars className='listDropDown'  style={{  height:
                                    sectorsFiltre.length === 0 ? 30 : "" ||
                                    sectorsFiltre.length === 1 ? 30 : ""  ||
                                    sectorsFiltre.length === 2 ? 70 : ""  ||
                                    sectorsFiltre.length === 3 ? 120 : 120
                                    }}>
                                        <ul>
                                            {
                                                sectorsFiltre.length === 0 ?
                                                    <li>
                                                        <button>Nada encontrado</button>
                                                    </li>
                                                :
                                                sectorsFiltre
                                                .map((sector, index) => (
                                                    <li key={index}>
                                                        <button
                                                        value={sector.name}
                                                        name="sector"
                                                        onClick={myChangeHandlerPut}
                                                        >{sector.name}</button>
                                                    </li>
                                                ))}
                                        </ul>

                                    </Scrollbars>
                                </nav>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Centro de custo</p>
                        </div>
                        <div className="intemB">
                            <div className='cont-select'>

                                <button className='select-' >
                                    <p>
                                        {
                                            salaryhistoryPut && salaryhistoryPut.costCenter !== undefined ?
                                            salaryhistoryPut.costCenter
                                            :
                                            salaryhistoryRegistered && salaryhistoryRegistered.costCenter !== undefined ?
                                                salaryhistoryRegistered.costCenter : "Selecione"
                                        }
                                    </p>
                                </button>

                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 120 }}>

                                    {       salaryhistoryRegistered &&
                                            salaryhistoryRegistered.costCenter &&
                                            salaryhistoryRegistered.costCenter === companies.name ?
                                             ""
                                             :
                                             <button className='item' name="costCenter"
                                                 onClick={() =>
                                                     setSalaryhistoryPut({ ...salaryhistoryPut, 'costCenter': companies.name})
                                                 }
                                             >
                                                 <p >{companies.name}</p>
                                             </button>
                                    }
                                    {
                                        costcenter
                                        .map((costcenter, index) => (

                                            salaryhistoryRegistered &&
                                            salaryhistoryRegistered.costCenter &&
                                            salaryhistoryRegistered.costCenter === costcenter.name ?
                                            ''
                                            :

                                            <button key={index} className='item' name="costCenter"
                                                onClick={() =>
                                                    setSalaryhistoryPut({ ...salaryhistoryPut, 'costCenter': costcenter.name})
                                                }
                                            >
                                                <p >{costcenter.name}</p>
                                            </button>


                                        ))
                                    }


                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA textarea-text">
                            <p>Descrição</p>
                        </div>
                        <div className="intemB ">

                        {
                                (salaryhistoryPut && salaryhistoryPut.description !== undefined) || salaryhistoryPut.description === "" ?
                                (
                                    <textarea name="description"maxLength="512" placeholder="Descrição..."  onChange={myChangeHandlerPut} value={(salaryhistoryPut.description !== undefined) ? salaryhistoryPut.description : ''}></textarea>
                                ) :(salaryhistoryRegistered && salaryhistoryRegistered.salary !== undefined) || salaryhistoryRegistered.salary === "" ? (
                                    <textarea name="description"maxLength="512" placeholder="Descrição..."  onChange={myChangeHandlerPut} value={(salaryhistoryRegistered.description !== undefined) ? salaryhistoryRegistered.description : ''} ></textarea>
                                ):(
                                    <textarea name="description"maxLength="512" placeholder="Descrição..."  onChange={myChangeHandlerPut} value={(salaryhistoryPut.description !== undefined) ? salaryhistoryPut.description : ''}></textarea>
                                )
                            }



                        </div>
                    </li>
                </ul>
                <div className="btn-salva">

                    <form  className="btn-salvar"  onSubmit={handleSubmitDois} >
                        <button type="submit" className={loadBtn  ? 'btnGreen load' : 'btnGreen'}>Salvar</button>
                    </form>
                    <button className="cancelar" onClick={()=> setIsModalPutSalaryhistory(false)}>Cancelar</button>
                </div>

            </div>
        </ModalGeral>
        <SubMenu id={id} role={role} path={path}/>
        <div className="box-profile-historico">
            <div className="cabecalho">
                <h1>Histórico de salário e promoções

                    {
                        localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                            ""
                            :
                            (role && role === 'admin') || (role && role === 'master') ?
                                <>
                                    <button className="btnGreen more addNovoHistorico" onClick={()=> setIsModalAddSalaryhistory(true)}>Adicionar novo</button>
                                    <button className="addNovoHistoricoMob" onClick={()=> setIsModalAddSalaryhistory(true)}><img alt="" src={IconAddBrnaco} /></button>
                                </>
                                : ''
                    }
                </h1>
            </div>
            <div className="timeline-salary-promotions">
                <div className="box-timeline">
                    <ul className="ul-timeline">
                        {
                        loadCollaboratorHistory ?
                            <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                            </>
                        :
                        collaboratorHistory && collaboratorHistory.length === 0 ?
                            <><li><div className="box nenhuma-ateracao">Nenhum histórico de salário ou promoções</div></li></>
                        :
                        collaboratorHistory && collaboratorHistory.length !== 0 ?
                        collaboratorHistory
                        .map((historycollaborator, index) => (
                            <>
                            <li key={index}>
                            <div className="box boxAberto" onClick={() => handleToggleVisible(historycollaborator._id)}>
                                <h3 className="promocao-titulo">{historycollaborator.name}


                                {
                                    localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                        ""
                                        :

                                        (role && role === 'admin') || (role && role === 'master') ?
                                        <Dropdown  className="dropdown-icon dropdown-hsitory">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button onClick={() => abrirPut(historycollaborator)} type="submit" >
                                                    Editar dados
                                                </button>

                                                <button name="_id" onClick={() => deleteSalaryhistory(historycollaborator._id)}  type="submit" className="red">
                                                    Excluir histórico
                                                </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        ''
                                }
                                </h3>
                                <div className="infor">
                                    <div className="salario-cargo">
                                        <img src={iconMoneyVerde} alt="" />
                                        <div  className="salario">
                                            <strong className="promocao-salario">R$ {(historycollaborator.salary !== "-") &&
                                            historycollaborator.salary !== undefined
                                            ? historycollaborator.salary : collaborator.salary !== undefined ? collaborator.salary : "0,00"}</strong>
                                            <span>Salário</span>
                                        </div>
                                    </div>
                                    <div className="salario-cargo">
                                        <img src={iconJobVerde} alt="" />
                                        <div className="cargo">
                                            <strong className="promocao-cargo">
                                                {(historycollaborator.occupation !== "-") &&
                                                historycollaborator.occupation !== undefined
                                                ? historycollaborator.occupation : collaborator.occupation !== undefined ? collaborator.occupation : "-"}
                                            </strong>
                                            <span>Cargo</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="infor-lista" style={visible === true && idHistory === historycollaborator._id ? {display:'block'} : {display:'none'}} >
                                    <ul>
                                        <li>
                                            <p>A parti r de</p>  <span>{historycollaborator.validOnTheDateOf}</span>
                                        </li>
                                        {(historycollaborator.bond !== "-") &&  historycollaborator.bond !== undefined  ?
                                            <li>
                                                <p>Vínculo</p>
                                                <span>
                                                    {(historycollaborator.bond !== "-") &&  historycollaborator.bond !== undefined  ? historycollaborator.bond : "-"}
                                                    {/* {historycollaborator.bond} */}
                                                </span>
                                            </li>
                                        :
                                        ""
                                        }
                                            {(historycollaborator.sector !== "-") &&  historycollaborator.sector !== undefined  ?

                                                <li>
                                                    <p>Departamento</p>
                                                    <span>
                                                    {(historycollaborator.sector !== "-") &&  historycollaborator.sector !== undefined  ? historycollaborator.sector : "-"}
                                                    </span>
                                                </li>
                                            :
                                            ""
                                        }

                                        {(historycollaborator.costCenter !== "-") &&  historycollaborator.costCenter !== undefined  ?

                                            <li>
                                                <p>Centro de custo</p>
                                                <span>
                                                {(historycollaborator.costCenter !== "-") &&  historycollaborator.costCenter !== undefined  ? historycollaborator.costCenter : "-"}
                                                </span>
                                            </li>
                                        :
                                        ""
                                        }
                                        {historycollaborator.description !== undefined ?
                                        <>
                                        <li>
                                            <p>Descrição</p> <span>{historycollaborator.description.split('\n').map(str => (<><br/>{str}</>))}</span>
                                        </li>
                                        </>
                                        :
                                        <></>
                                        }
                                    </ul>

                                </div>
                            </div>
                            <p className="alterado-por">Por {historycollaborator.responsible.name} <span>{dateFormatedToHistory(historycollaborator.createdAt)}</span></p>
                        </li>
                            </>
                        ))
                        :
                        ''
                        }
                    </ul>
                </div>
            </div>

        </div>

        <ModalGeral onClose={()=> setIsModalAddSalaryhistory(false)} isModalAddSalaryhistory={isModalAddSalaryhistory}>
            <div className="modal-form">
                <div className="titulo"><h4>Adicionar dados</h4></div>

                <ul className="ul-form">
                    <li>
                        <div className="intemA">
                            <p>Tipo *</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    salaryhistoryAdd && salaryhistoryAdd.name !== undefined ?
                                    salaryhistoryAdd.name
                                    :
                                    ""
                                }
                                nameButton={"name"}
                                myChangeHandler={myChangeHandlerAdd}
                                loadError={loadErrorName}

                                optionFil={nameTipy}
                                typeSelect="busca2"
                            />

                            {loadErrorName ? (<p className="textError inputTreis">O campo é obrigatório.</p> ) : ('')}
                        </div>
                    </li>



                    <li>
                        <div className="intemA">
                            <p>Cargo </p>
                        </div>
                        <div className="intemB">


                            <div className="select-busca-btn">
                                <input
                                    type="text"
                                    className={loadOccupation ? "menu-button inputErro" : "menu-button"}
                                    placeholder='Selecione'
                                    name="nomeOcupação"
                                    autoComplete="off"
                                    value={
                                        buscaOcupacaoState && buscaOcupacaoState?.nomeOcupação !== undefined ?
                                        buscaOcupacaoState?.nomeOcupação
                                        :""
                                    }
                                    onClick={ SelectBuscaOccupation }
                                    onBlur={ () => (setIsActiveSelectBuscaOccupation(false)) }
                                    onChange={myChangeHandlerSelects}
                                />
                                <nav className={`menu ${isActiveSelectBuscaOccupation ? "ativo": ""}`} >

                                    <Scrollbars className='listDropDown'  style={{  height:
                                    occupationsFiltre.length === 0 ? 30 : "" ||
                                    occupationsFiltre.length === 1 ? 30 : ""  ||
                                    occupationsFiltre.length === 2 ? 70 : ""  ||
                                    occupationsFiltre.length === 3 ? 120 : 120
                                    }}>
                                        <ul>
                                            {
                                                occupationsFiltre.length === 0 ?
                                                    <li>
                                                        <button>Nada encontrado</button>
                                                    </li>
                                                :
                                                occupationsFiltre
                                                .map((occupation, index) => (
                                                    <li key={index}>
                                                        <button
                                                        value={occupation.name}
                                                        name="occupation"
                                                        onClick={myChangeHandlerAdd}
                                                        >{occupation.name}</button>
                                                    </li>
                                                ))}
                                        </ul>

                                    </Scrollbars>
                                </nav>
                            </div>

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Salário </p>
                        </div>
                        <div className="intemB">
                            {
                                salaryhistoryAdd && salaryhistoryAdd.salary !== undefined ?
                                (
                                    <input type="text" name="salary" autocomplete="off" maxLength="9" value={salaryhistoryAdd.salary !== undefined ? salaryhistoryAdd.salary : ''}  onChange={myChangeHandlerAdd} placeholder="R$ 0,000" />
                                ) : (
                                    <input type="text" name="salary" autocomplete="off" maxLength="9" value="" onChange={myChangeHandlerAdd} placeholder="R$ 0,000" />
                            )}

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>A partir de *</p>
                        </div>
                        <div className="intemB">
                            {
                                salaryhistoryAdd && salaryhistoryAdd.validOnTheDateOf !== undefined ?
                                (
                                    <input type="text" name="validOnTheDateOf" autocomplete="off" onKeyPress={(event) => handleKeyPress(event)} maxLength="11"  value={(salaryhistoryAdd.validOnTheDateOf !== undefined) ? salaryhistoryAdd.validOnTheDateOf : ''}  onChange={myChangeHandlerAdd} placeholder="DD/MM/AAAA" className={loadErrorValidOnTheDateOf ? 'inputErro' : ''}/>
                                ) :
                                (
                                    <input type="text" name="validOnTheDateOf" autocomplete="off" onKeyPress={(event) => handleKeyPress(event)} maxLength="11"  value=""  onChange={myChangeHandlerAdd} placeholder="DD/MM/AAAA" className={loadErrorValidOnTheDateOf ? 'inputErro' : ''}/>
                                )
                            }

                            {loadErrorValidOnTheDateOf ? (<p className="textError inputTreis">O campo é obrigatório.</p> ) : ('')}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Vínculo </p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    salaryhistoryAdd && salaryhistoryAdd.bond !== undefined ?
                                    salaryhistoryAdd.bond
                                    :
                                    ""
                                }
                                nameButton={"bond"}
                                myChangeHandler={myChangeHandlerAdd}

                                optionFil={nameVinculo}
                                typeSelect="busca2"
                            />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Departamentos </p>
                        </div>
                        <div className="intemB">
                            <div className="select-busca-btn">
                                <input
                                    type="text"
                                    className="menu-button"
                                    placeholder='Selecione'
                                    name="nomeSector"
                                    autoComplete="off"
                                    value={
                                        buscaSector?.nomeSector !== undefined ?
                                        buscaSector.nomeSector :
                                        buscaSector && buscaSector?.sector === '' ?
                                        ''
                                        :
                                        salaryhistoryAdd && salaryhistoryAdd?.sector ?
                                        salaryhistoryAdd.sector :
                                            ''
                                    }
                                    onClick={ SelectBuscaSector }
                                    onBlur={ () => (setIsActiveSelectBuscaSector(false)) }
                                    onChange={myChangeHandlerSelects}
                                />
                                <nav className={`menu ${isActiveSelectBuscaSector ? "ativo": ""}`} >
                                    <Scrollbars className='listDropDown'  style={{  height:
                                    sectorsFiltre.length === 0 ? 30 : "" ||
                                    sectorsFiltre.length === 1 ? 30 : ""  ||
                                    sectorsFiltre.length === 2 ? 70 : ""  ||
                                    sectorsFiltre.length === 3 ? 120 : 120
                                    }}>
                                        <ul>
                                            {
                                                sectorsFiltre.length === 0 ?
                                                    <li>
                                                        <button>Nada encontrado</button>
                                                    </li>
                                                :
                                                sectorsFiltre
                                                .map((sector, index) => (
                                                    <li key={index}>
                                                        <button
                                                        value={sector.name}
                                                        name="sector"
                                                        onClick={myChangeHandlerAdd}
                                                        >{sector.name}</button>
                                                    </li>
                                                ))}
                                        </ul>

                                    </Scrollbars>
                                </nav>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Centro de custo </p>
                        </div>
                        <div className="intemB">
                            <div className='cont-select'>

                                <button className='select-' >
                                    <p>
                                        {
                                            salaryhistoryAdd && salaryhistoryAdd.costCenter !== undefined ?
                                            salaryhistoryAdd.costCenter
                                            : <p className='placeholder'>Selecione</p>
                                        }
                                    </p>
                                </button>

                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 80 }}>

                                    {       salaryhistoryAdd &&
                                            salaryhistoryAdd.costCenter &&
                                            salaryhistoryAdd.costCenter === companies.name ?
                                             ""
                                             :
                                             <button className='item' name="costCenter"
                                                 onClick={() =>
                                                    setSalaryhistoryAdd({ ...salaryhistoryAdd, 'costCenter': companies.name})
                                                 }
                                             >
                                                 <p >{companies.name}</p>
                                             </button>
                                    }
                                    {
                                        costcenter
                                        .map((costcenter, index) => (

                                            salaryhistoryAdd &&
                                            salaryhistoryAdd.costCenter &&
                                            salaryhistoryAdd.costCenter === costcenter.name ?
                                            ''
                                            :

                                            <button key={index} className='item' name="costCenter"
                                                onClick={() =>
                                                    setSalaryhistoryAdd({ ...salaryhistoryAdd, 'costCenter': costcenter.name})
                                                }
                                            >
                                                <p >{costcenter.name}</p>
                                            </button>


                                        ))
                                    }


                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA textarea-text">
                            <p>Descrição </p>
                        </div>
                        <div className="intemB ">
                            {
                                salaryhistoryAdd && salaryhistoryAdd.description !== undefined ?
                                (
                                    <textarea name="description"maxLength="512" value={salaryhistoryAdd.description !== undefined ? salaryhistoryAdd.description : ''}  placeholder="Descrição..."  onChange={myChangeHandlerAdd}></textarea>
                                ) : (
                                    <textarea name="description"maxLength="512" value="" placeholder="Descrição..."  onChange={myChangeHandlerAdd}></textarea>
                            )}

                        </div>
                    </li>
                </ul>
                <div className="btn-salva">

                    <form  className="btn-salvar"  onSubmit={handleSubmit} >
                        <button type="submit" className={loadBtn  ? 'btnGreen load' : 'btnGreen'}>Salvar</button>
                    </form>
                    <button className="cancelar" onClick={()=> setIsModalAddSalaryhistory(false)}>Cancelar</button>
                </div>

            </div>
        </ModalGeral>
        </>
    );
}

