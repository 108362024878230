import React, { useState, useEffect } from 'react';
import Layout from '../../../../../components/Layouts/default';
import { toast } from 'react-toastify';
import api from '../../../../../services/api'
import Success from '../../../../../assets/images/success.svg';
import Header from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/header';
import DesligamentoSteps from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/headerDesligamentoSteps';
import io from "socket.io-client";

import { NavLink } from 'react-router-dom'
import '../style.scss';

export default function BoxFinish(props) {
    const { id } = props.match.params;
    const [ stepOne ]   = useState(true);
    const [ stepTwo ]   = useState(true);
    const [ stepThree ] = useState(true);
    ////////////// NOTIFICATIONS ////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notifications, setNotifications ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationsRead, setNotificationsRead ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadnotifications();
        loadnotificationsread();
        loadlimit();

        async function registerToSocketNotification() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            socket.on("notificationRead", () => {
                loadnotificationsread();

                if(localStorage.getItem('@peopleview/limit') > 4) {
                    showMore()
                }
                else {

                    loadnotifications();
                }

            });
        }
        registerToSocketNotification();


    }, []);



    async function loadnotificationsread() {

        await api.get('/notification-read/')
        .then(response => {

            setNotificationsRead(response.data[0]);

        }).catch(error => {});
    }


    async function loadnotifications() {

        await api.get('/notification/')
        .then(response => {


            if(response.data[0] !== undefined) {

                setNotifications(response.data);

            }
        }).catch(error => {});
    }



    async function readTrue(event) {

        await api.put(`/notification/${event}`, { read: true })
        .then(response => {

            loadnotifications();


        }).catch(error => {});

    }


    async function loadlimit() {

        localStorage.setItem('@peopleview/limit', 4);

    }


    async function showMore() {

        let limite = 4;

        const limitStorage = localStorage.getItem('@peopleview/limit');

        let limit = (parseInt(limitStorage) + parseInt(limite))

        localStorage.setItem('@peopleview/limit', limit);
        await api.get(`notification/?showmore=true&limite=${limit}`)
        .then(response => {
            if(response.data[0] !== undefined) {
                setNotifications(response.data);
            }
        }).catch(error => {});


    }


    async function indexUpdate() {
        await api.get('/notification-update-all/')
        .then(response => {
            if(response.data[0] !== undefined) {
                setNotifications(response.data);
            }
        }).catch(error => {});

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorShutdownProcess, setCollaboratorShutdownProcess ] = useState([]);


     // eslint-disable-next-line react-hooks/rules-of-hooks
     useEffect(() => {
        if(id && id){
        async function loadcollabotorshutdown() {
            await api.get(`/collaborator/${id}`)
            .then(response => {
                setCollaboratorShutdownProcess(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            })
         }
         loadcollabotorshutdown()}
    }, [id]);


return (
    <>
        <Layout
        path={props.match.path}
        >
            <Header
            collaboratorShutdownProcess={collaboratorShutdownProcess}
            notifications={notifications}
            notificationsRead={notificationsRead}
            readTrue={readTrue}
            showMore={showMore}
            indexUpdate={indexUpdate}
            />
            <DesligamentoSteps
            id={id}
            stepOne={stepOne}
            stepTwo={stepTwo}
            stepThree={stepThree}
            />

            <div className="box-finalizado">
                    <img src={Success} alt="Success" className="icone" />
                <h2>Colaborador demitido com sucesso!</h2>
                <p>Você sempre pode visualizar e editar as informações do colaborador acessando a sua página de perfil, na lista de colaboradores.</p>
                <NavLink to="/colaboradores/ativos" exact activeClassName="active"  className="btnGray">Voltar à lista de colaboradores</NavLink>
            </div>

        </Layout>
    </>
);
}

