import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import './assets/scss/styles.scss';
import 'bootstrap/dist/css/bootstrap.css';

import Routes from './routes';
import history from './services/history';
//
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from './styles/global';
import './styles/global.scss';

export default function App() {
    return (
        <>
            <Router history={history}>
                <Routes />
                <GlobalStyle />
                <ToastContainer autoClose={3000} />
            </Router>
        </>
    )
}
