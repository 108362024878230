import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { Modal} from "react-bootstrap";
import EditBlack from '../../../../assets/images/edit-black.svg';
import Carregamento from '../../../../assets/images/carregando.gif';
import Close from '../../../../assets/images/close.svg';
import IllustrationEmergency from '../../../../assets/images/illustration-emergency.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import { phones } from '../../../../services/mask';


const ColProfileEmergencyContacts = ({ id, path, collaboratorSession, OnSubmit, loadContinue  }) => {

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [visible, setVisible] = useState(false);

 function PopupAddConEmertVisible() {
     setVisible(!visible);
     setEmergencyContact([])
     setContactEmergencySaved([])
     setLoademErgencyName(false);
     setLoadRelationship(false);
     setLoadEmergencyCellphone(false);
 }

 const [contactEmergencySaved, setContactEmergencySaved] = useState([]);

 function PopupEdiConEmertVisible(e) {

    if(e !== undefined) {
        setContactEmergencySaved(e)
    }
    else {
        setEmergencyContact([])
        setContactEmergencySaved([])
    }

    setVisible(!visible);
    setLoademErgencyName(false);
    setLoadRelationship(false);
    setLoadEmergencyCellphone(false);
 }

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ load, setLoad ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loademErgencyName, setLoademErgencyName ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadRelationship, setLoadRelationship ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadEmergencyCellphone, setLoadEmergencyCellphone ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ emergencyContact, setEmergencyContact ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ registeredEmergencyContact, setRegisteredEmergencyContact ] = useState([]);


// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    loademergencycontacts(id);

}, [id]);

async function loademergencycontacts(id) {
    setLoad(true)
     await api.get('/collaborator-emergency-contact-access')
     .then(response => {
        setLoad(false)
        if(response.data[0] !== undefined) {
            setRegisteredEmergencyContact(response.data);
        }
     }).catch(error => {});
 }

const collaborator = id;

console.log('emergencyContact')
console.log(emergencyContact)

const myChangeHandler = event => {

    let nam = event.target.name;
    let val = event.target.value;


    // setEmergencyContact({ ...emergencyContact, collaborator, [nam]: val })

    if (nam === "emergencyCellphone") {

        if(val.length >= 14) {
            setLoadEmergencyCellphone(false)
        }
        let emergencyCellphone = phones(val)
        setEmergencyContact({ ...emergencyContact, collaborator, [nam]: emergencyCellphone, path: path })

    }
    else
    if (nam === "emergencyName") {

        setLoademErgencyName(false)
        setEmergencyContact({ ...emergencyContact, collaborator, [nam]: val })
    }
    else
    if (nam === "relationship") {

        if(val === 'Selecione') {
            setLoadRelationship(true)
        } else {

            setLoadRelationship(false)
            setEmergencyContact({ ...emergencyContact, collaborator, [nam]: val })

        }

    }
    else
    if (nam === "emergencyEmail") {

        if(val === "") {
            let emergencyEmail = '-'
            setEmergencyContact({ ...emergencyContact, emergencyEmail })
        } else {
            setEmergencyContact({ ...emergencyContact, collaborator, [nam]: val })
        }

    }
    else {
        setEmergencyContact({ ...emergencyContact, collaborator, [nam]: val })
    }

};

async function handleSubmit(event) {
    event.preventDefault();
    if(
        emergencyContact === undefined ||
        emergencyContact.length === 0
    ) {
        if(contactEmergencySaved && contactEmergencySaved._id !== undefined) {

            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });

        } else {

            if (emergencyContact.emergencyName === undefined) {
                setLoademErgencyName(true)
            } else {
                setLoademErgencyName(false)
            }

            if (emergencyContact.relationship === undefined) {
                setLoadRelationship(true)
            } else {
                setLoadRelationship(false)
            }

            if (emergencyContact.emergencyCellphone === undefined) {
             setLoadEmergencyCellphone(true)
            } else {
             setLoadEmergencyCellphone(false)
            }

        }

    } else {

        if(contactEmergencySaved._id !== undefined) {

            if (
                emergencyContact.emergencyName      === "" ||
                emergencyContact.relationship       === "" ||
                emergencyContact.emergencyCellphone === ""
            ) {

                swal({ icon: "error", title: "Erro!", text: "Somente o campo e-mail pode ser salvo em branco!" });

            } else {

                setLoad(true)
                await api.put(`/collaborator-emergency-contact-access/${contactEmergencySaved._id}`, emergencyContact)
                .then(response => {
                    setEmergencyContact([]);
                    setContactEmergencySaved([])
                    setLoademErgencyName(false)
                    setLoadRelationship(false)
                    setLoadEmergencyCellphone(false)

                    loademergencycontacts(id);
                    setVisible(false);

                    swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência editado com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o contato de emergência, tente novamente mais tarde." });
                });

            }
        }
        else {
            if (
                emergencyContact.emergencyName !== undefined &&
                emergencyContact.relationship !== undefined &&
                emergencyContact.emergencyCellphone !== undefined
            ) {

                setLoad(true)
                await api.post('/collaborator-emergency-contact-access', emergencyContact)
                .then(response => {
                    setEmergencyContact([])
                    setContactEmergencySaved([])
                    setLoademErgencyName(false)
                    setLoadRelationship(false)
                    setLoadEmergencyCellphone(false)

                    loademergencycontacts(id);
                    setVisible(false);
                    swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência criado com sucesso!" });
                    //this.propsCollaboratorsInatives();
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao criar o contato de emergência, tente novamente mais tarde." });
                });

            }
        }
    }
}
////////////////////////////////////////////////////////////////////////////
///// FUNÇÂO RESPONSÀVEL POR DELETAR AS ISFOS DO CONATATO DE EMERGÊNCIA /////
///////////////////////////////////////////////////////////////////////////
async function handleDelete(_id) {
    setLoad(true);
    await api.delete(`/collaborator-emergency-contact-access/${_id}?path=${path}`)
    .then(response => {
        setLoad(false);
        setEmergencyContact([]);
        setContactEmergencySaved([])
        setLoademErgencyName(false)
        setLoadRelationship(false)
        setVisible(false);
        setLoadEmergencyCellphone(false)

        loademergencycontacts(id);

        swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência deletado com sucesso!" });
    }).catch(error => {
        swal({ icon: "error", title: "Erro!", text: "Erro ao deletadar o contato de emergência!" });
    });

}
        return (
            <>

                    <div className="box edereco-emergencia">
                        <h3 className="titulo-box "><span>Contatos de emergência</span>    <button className="edit-adicionar btnGreen more" onClick={() => PopupAddConEmertVisible()}>Adicionar</button></h3>
                        {
                        load ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):

                        registeredEmergencyContact[0] === undefined ?
                        (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationEmergency} alt="Sem deficiencia" /><p>Não possui contatos de emergência</p></div></div>):
                        registeredEmergencyContact !== undefined ? registeredEmergencyContact
                                    .map((emergencycontact, index) => (

                                            <ul>
                                                <li className="nome-contato">
                                                    <div className="intemA">
                                                        <p>Nome completo</p>
                                                    </div>
                                                    <div className="intemB nome-editar">

                                                        <span className="nome">{emergencycontact.emergencyName}</span>
                                                        <button onClick={() => PopupEdiConEmertVisible(emergencycontact)} className="editar"><img src={EditBlack} alt="Edite Informações" /> </button>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="intemA">
                                                        <p>Parentesco</p>
                                                    </div>
                                                    <div className="intemB">
                                                        <p>{emergencycontact.relationship}</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="intemA">
                                                        <p>Celular</p>
                                                    </div>
                                                    <div className="intemB">
                                                        <p>{emergencycontact.emergencyCellphone}</p>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="intemA">
                                                        <p>E-mail</p>
                                                    </div>
                                                    <div className="intemB">
                                                        <p>{emergencycontact.emergencyEmail ? emergencycontact.emergencyEmail : '-'}</p>
                                                    </div>
                                                </li>
                                            </ul>


                                    )): (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationEmergency} alt="Sem deficiencia" /><p>Não possui contatos de emergência</p></div></div>)}

                    </div>


                    <Modal show={visible} className="popup-edit-perfil" onHide={contactEmergencySaved && contactEmergencySaved._id !== undefined ? PopupEdiConEmertVisible : PopupAddConEmertVisible}>
                        <div>
                            <div className="titulo">
                                <h4>Contatos de emergência <p className="btn-fechar" onClick={contactEmergencySaved && contactEmergencySaved._id !== undefined ? PopupEdiConEmertVisible : PopupAddConEmertVisible}> <img src={Close} alt="Fechar" /></p></h4>
                            </div>
                            <ul>
                                <li>
                                    <div className="intemA">
                                        <p>Nome completo</p>
                                    </div>
                                    <div className="intemB">
                                        <input type="text" name="emergencyName" autocomplete="off" defaultValue={contactEmergencySaved && contactEmergencySaved._id !== undefined ? contactEmergencySaved.emergencyName : ''} onChange={myChangeHandler} placeholder="Nome completo" className={loademErgencyName ? 'inputUm inputErro' : 'inputUm'}/>
                                        <p className="inputUm textError">{loademErgencyName ? 'É necessário preencher um nome' : ''}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Parentesco</p>
                                    </div>
                                    <div className="intemB">
                                        <div className='cont-select'>
                                            <button className={loadRelationship ? 'inputErro select-' : 'select-'}>
                                                <p>
                                                    {
                                                        emergencyContact && emergencyContact.relationship !== undefined ?
                                                        emergencyContact.relationship :
                                                        contactEmergencySaved && contactEmergencySaved.relationship !== undefined ?
                                                        contactEmergencySaved.relationship : "Selecione"
                                                    }
                                                </p>
                                            </button>

                                            <div className="dropDown">
                                                <Scrollbars className='listDropDown'  style={{  height: 130 }}>
                                                        {[
                                                            "Cônjuge", "Companheiro(a) ou união estável com filhos", "Companheiro(a) ou união estável sem filhos",
                                                            "Filho ou enteado até 21 anos", "Filho ou enteado universitário ou em escola técnica",
                                                            "Irmão(ã), neto(a) ou bisneto(a) com guarda", "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica",
                                                            "Pais, avós e bisavós", "Incapaz", "Agregado/Outros", "Ex cônjuge que recebe pensão de alimentos"
                                                        ].map((item, index) => (
                                                        <button
                                                            key={index}
                                                            className='item'
                                                            name="name"
                                                            onClick={() => setEmergencyContact({ ...emergencyContact, collaborator, "relationship": item })
                                                            ||
                                                            setLoadRelationship(false)}
                                                        >
                                                            <p >{item}</p>
                                                        </button>
                                                    ))}
                                                </Scrollbars>
                                            </div>
                                        </div>
                                        <p className="inputDois textError">{loadRelationship ? 'É necessário escolher um parentesco' : ''}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Celular</p>
                                    </div>
                                    <div className="intemB">
                                    <input
                                    type="tel"
                                    name="emergencyCellphone"
                                    onChange={myChangeHandler}
                                    autocomplete="autocompleteoff"
                                    value={
                                        (emergencyContact && emergencyContact.emergencyCellphone) || emergencyContact.emergencyCellphone === "" ?
                                        emergencyContact.emergencyCellphone :
                                        contactEmergencySaved && contactEmergencySaved.emergencyCellphone ?
                                        contactEmergencySaved.emergencyCellphone :
                                        emergencyContact.emergencyCellphone
                                    }
                                    placeholder="Celular"
                                    className={loadEmergencyCellphone ? 'inputTreis inputErro' : 'inputTreis'}
                                    />
                                        <p className="inputTreis textError">{loadEmergencyCellphone ? 'É necessário preencher um celular' : ''}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Email</p>
                                    </div>
                                    <div className="intemB">
                                        {contactEmergencySaved && contactEmergencySaved._id !== undefined ?
                                        (<input type="email" name="emergencyEmail" autocomplete="autocompleteoff" defaultValue={contactEmergencySaved.emergencyEmail} onChange={myChangeHandler} placeholder="Email"/>) :
                                        (<input type="email" name="emergencyEmail" autocomplete="autocompleteoff" onChange={myChangeHandler} placeholder="Email"/>)}
                                    </div>

                                </li>
                                <li>
                                    <div className="intemA">
                                        {contactEmergencySaved && contactEmergencySaved._id !== undefined ?
                                        (
                                            <div className="intemB btnExclui">
                                                    <button onClick={() => handleDelete(contactEmergencySaved._id)}>Remover contato</button>
                                            </div>

                                        ):''}

                                    </div>
                                    <div className="intemB">
                                        <form onSubmit={handleSubmit} className="btn-salvar">
                                            <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                        </form>
                                    </div>

                                </li>


                            </ul>
                        </div>
                    </Modal>
            </>
        );
}
export default ColProfileEmergencyContacts;
