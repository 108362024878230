import React,{ useState, useEffect } from 'react';

import IconUserNone from '../../assets/images/iconUserNone.svg';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { format } from "date-fns";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";

import api from '../../services/api';
import { Scrollbars } from 'react-custom-scrollbars';

import {
    trasformDateStartDatePeriodOfLogToDateFormatUTC,
    trasformDateEndDatePeriodOfLogToDateFormatUTC
} from '../../services/utils'

export default function filterLogs({
    path,
    setRoles,
    roles,
    setAction,
    action,
    id_collaborator_search_log,
    setId_collaborator_search_log,
    module,
    functionality,
    setFunctionality,
    setLogOfTheSystem,
    setLoadlLogOfTheSystem
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBusca, setIsActiveSelectBusca] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, ] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSelect, setBuscaSelect ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listaOptionCol, setListaOptionCol ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listCollaborar, setListCollaborar ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadSelectCol, setLoadSelectCol] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [startDate, setStartDate] = useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [endDate, setEndDate] = useState(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [user, setUser ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listFunctionality, setListFunctionality ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        filterLogOfTheSystem(
            setLogOfTheSystem,
            setLoadlLogOfTheSystem,
            roles,
            functionality,
            action,
            trasformDateStartDatePeriodOfLogToDateFormatUTC(startDate),
            trasformDateEndDatePeriodOfLogToDateFormatUTC(endDate),
            user,
            page
        )

    }, [setLogOfTheSystem, setLoadlLogOfTheSystem, roles, functionality, action, startDate, endDate, user, page]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadcollaboratorSelect(nameFilter);

    }, [nameFilter]);

    async function loadcollaboratorSelect(nameFilter) {

        setLoadSelectCol(true)
        await api.get(`/collaborator-new/?name=${nameFilter}&status=Ativo&role=admin&role=manager&role=user&sortBy=name&page=0&limit=100`)
        .then(response => {

            setListaOptionCol(response.data.collaboratorSearch)
            setLoadSelectCol(false)

        }).catch(error => {

            console.log('error => loadcollaboratorSelect')
            console.log(error)

        });

    }

    async function filterLogOfTheSystem(setLogOfTheSystem, setLoadlLogOfTheSystem, roles, functionality, action, startDate, endDate, user, page) {

        setLoadlLogOfTheSystem(true)
        await api.get(`/log-of-the-system/?module=${roles}&functionality=${functionality}&action=${action}&startDate=${startDate}&endDate=${endDate}&user=${user}&page=${page}&limit=1000`)
        .then(response => {

            if(response?.data?.logOfTheSystemSearch?.length !== 0) {
                setLoadlLogOfTheSystem(false)
                setLogOfTheSystem(response.data.logOfTheSystemSearch)
            }
            else {
                setLoadlLogOfTheSystem(false)
                setLogOfTheSystem([])
            }



        }).catch(error => {

            setLoadlLogOfTheSystem(false)
            console.log('error => filterLogOfTheSystem')
            console.log(error)

        });

    }

    ///////// BUSCA "COM QUEM?" O COLABORADOR
    async function SelectBusca() { setIsActiveSelectBusca(!isActiveSelectBusca) }
    const myChangeHandlerSelects = event => {
        let val = event.target.value;
        setBuscaSelect(val)
        setNameFilter(val)
    }

    ///////// SELECIONE COLABORADOR
    async function myChangeHandler_array_Col(event)  {
        event.preventDefault();

        let collaborator = event.target.id;
        setUser(event.target.id)

        await api.get(`/collaborator-new/`)
        .then(response => {

            setListCollaborar(response.data.collaboratorSearch)
            setId_collaborator_search_log({...id_collaborator_search_log, collaborator})
            setBuscaSelect("")
            setNameFilter("")


        }).catch(error => {
            console.log('error => load_collaborator')
            console.log(error)
        });

    }

    ///////// REMOVE O COLABORADOR DA BUSCA

   async function remove_array_Col(event)  {
       event.preventDefault();
       let collaborator = "";
       setUser(collaborator)
       setId_collaborator_search_log({...id_collaborator_search_log, collaborator})
   }

   //////////////////////////////////////////////////////
   /////////////////  BUSCA DA TIPO DE FUNCIONALIDADE  ///////////
   ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectFunctionality, setIsActiveSelectFunctionality] = useState(false);
    async function SelectFunctionality() {

        await api.get('/log-of-the-system-functionality')
        .then(response => {

            setListFunctionality(response.data.LogOfTheSystemFunctionalitySearchGroup)
            setIsActiveSelectFunctionality(!isActiveSelectFunctionality)

        }).catch(error => {

            console.log('error => loadcollaboratorSelect')
            console.log(error)

        });

    }

    //////////////////////////////////////////////////////
    /////////////////  BUSCA DA TIPO DE ACAO  ///////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectAction, setIsActiveSelectAction] = useState(false);
    async function SelectAction() { setIsActiveSelectAction(!isActiveSelectAction) }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA TIPO DE USUARIO ///////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaRole, setIsActiveSelectBuscaRole] = useState(false);
    async function SelectBuscaRole() {

        setIsActiveSelectBuscaRole(!isActiveSelectBuscaRole)
    }

    registerLocale("ptBR", ptBR);

    const DatePickerCustomField = (({ value, onClick }, forwardRef) => (
        <button
            type="button"
            className="date-picker-custom-field"
            onClick={onClick}
            // ref={ref}
        >
            {value ? (
            <div className="value-style">{value}</div>
            ) : (
            <div className="placeholder-style">Filtrar por período</div>
            )}
            {/* <GrDown color="#393A3B" /> */}
        </button>
    ));


    const onChange = (dates) => {

        const [start, end] = dates;
        setStartDate(start);
        if(end !== null) {
            setEndDate(end);
        }


        filterLogOfTheSystem(
            setLogOfTheSystem,
            setLoadlLogOfTheSystem,
            roles,
            functionality,
            action,
            trasformDateStartDatePeriodOfLogToDateFormatUTC(start),
            trasformDateEndDatePeriodOfLogToDateFormatUTC(end),
            user,
            page
        )

    };

    return (
        <>
            <div className='filters'>
                <p className='titulo'>Mostrando</p>

                <div className="select-busca-btn type-user">

                    <button
                        className='menu-button'
                        onBlur={ () => (setIsActiveSelectBuscaRole(false)) }
                        onClick={ ()=> SelectBuscaRole() }
                    >
                        <span>
                            {
                                roles && roles ?
                                roles === "user" ?
                                'Usuário'
                                :
                                roles === "manager" ?
                                'Gestor'
                                :
                                roles === "admin" ?
                                'Administardor'
                                :
                                ""
                                :
                                <p className='placeholder'>Tipo usuário</p>
                            }
                        </span>

                    </button>

                    <nav className={`menu ${isActiveSelectBuscaRole ? "ativo": ""}`} >

                        <Scrollbars className='listDropDown'  style={{  height:  80
                        }}>
                            <ul>
                                {
                                [ "user",  "manager", "admin" ]
                                    .map((roles, index) => (
                                        <li key={index}>
                                            <button
                                            value={roles}
                                            name="role"
                                            onClick={()=> setRoles(roles)}
                                            >
                                            {
                                                roles === "user" ? "Usuário" :
                                                roles === "manager" ? "Gestor" :
                                                roles === "admin" ? "Administrador" : ""
                                            }
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>

                        </Scrollbars>
                    </nav>
                </div>

                <div className="select-busca-btn action">

                    <button
                        className='menu-button'
                        onBlur={ () => (setIsActiveSelectAction(false)) }
                        onClick={ SelectAction }
                    >
                        <span>
                            {
                                action && action ?
                                action === "login" ? "Login" :
                                action === "create" ? "Criação" :
                                action === "update" ? "Atualização" :
                                action === "delete" ? "Delete" : ""
                                :
                                <p className='placeholder'>Tipo ação</p>
                            }
                        </span>

                    </button>

                    <nav className={`menu ${isActiveSelectAction ? "ativo": ""}`} >

                        <Scrollbars className='listDropDown'  style={{  height:  80
                        }}>
                            <ul>
                                {
                                [ "login", "create", "update","delete" ]
                                    .map((actionItem, index) => (
                                        <li key={index}>
                                            <button
                                            value={action}
                                            name="role"
                                            onClick={()=> setAction(actionItem)}
                                            // onClick={myChangeHandler}
                                            >
                                            {
                                                actionItem === "login" ? "Login" :
                                                actionItem === "create" ? "Criação" :
                                                actionItem === "update" ? "Atualização" :
                                                actionItem === "delete" ? "Delete" : ""
                                            }
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>

                        </Scrollbars>
                    </nav>
                </div>

                <div className="select-busca-btn functionality">

                    <button
                        className='menu-button'
                        onBlur={ () => (setIsActiveSelectFunctionality(false)) }
                        onClick={ SelectFunctionality }
                    >
                        <span>
                        {
                                functionality ?
                                functionality
                                :
                                <p className='placeholder'>Tipo de funcionalidade</p>
                            }
                        </span>

                    </button>

                    <nav className={`menu ${isActiveSelectFunctionality ? "ativo": ""}`} >

                        <Scrollbars className='listDropDown'  style={{  height:  80
                        }}>
                            <ul>
                                {
                                listFunctionality
                                .map((functionalityItem, index) => (
                                    <li key={index}>
                                        <button
                                        value={functionalityItem?.functionality}
                                        name="functionality"
                                        onClick={()=> setFunctionality(functionalityItem?.functionality)}
                                        >
                                        {functionalityItem?.functionality}
                                        </button>
                                    </li>
                                ))
                                }
                            </ul>

                        </Scrollbars>
                    </nav>
                </div>
                <div className='data'>
                    <DatePicker
                        locale="ptBR"
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        customInput={<DatePickerCustomField />}
                    />

                </div>

                <div className="select-busca-btn col-busca">
                    <input
                        type="text"
                        className="campo-busca load-inputs"
                        placeholder='Selecione o colaborador'
                        name="buscaCol"
                        value={
                            buscaSelect &&
                            buscaSelect ? buscaSelect
                            : ""
                        }
                        autoComplete="autocompleteoff"
                        onClick={ SelectBusca }
                        onBlur={ () => (setIsActiveSelectBusca(false)) }
                        onKeyUp={ () => (setIsActiveSelectBusca(true))}
                        onChange={ myChangeHandlerSelects  }
                    />
                    <nav className={`menu ${isActiveSelectBusca ? "ativo": ""}`} >
                        <Scrollbars className='listDropDown'  style={{  height:
                        listaOptionCol?.length === 0 ? 60 : "" ||
                        listaOptionCol?.length === 1 ? 60 : ""  ||
                        listaOptionCol?.length === 2 ? 130 : ""  ||
                        listaOptionCol?.length >= 3 ? 180 : 120
                        }}>
                            <ul>
                                { loadSelectCol?

                                    <li>
                                        <button>carregando...</button>
                                    </li>:
                                    listaOptionCol?.length === 0 ||
                                    listaOptionCol?.length === "" ?
                                        <li>
                                            <button>Nada encontrado</button>
                                        </li>
                                    :
                                    listaOptionCol && listaOptionCol.length === 0 ?"":listaOptionCol
                                    .map((optionIntem, index) => (
                                        <li key={index}>
                                            <button
                                                id={optionIntem._id}
                                                name="Collaborador"
                                                onBlur={ () => (setBuscaSelect("")) }
                                                onClick={myChangeHandler_array_Col}
                                            >
                                                    <span className='collaborador'  id={optionIntem._id}>

                                                        {
                                                            optionIntem.avatar !== undefined && optionIntem.avatar !== "undefined" ?
                                                            <>
                                                            <span className="foto" id={optionIntem._id} >
                                                                <img id={optionIntem._id} src={optionIntem.avatar} alt="nome" />
                                                            </span>
                                                            </>
                                                            :
                                                            <>
                                                            <span className="colSemFoto">
                                                                <span className="semImgCol"    id={optionIntem._id}>
                                                                    <img   id={optionIntem._id} src={IconUserNone} alt="Usuario sem imagem" />
                                                                </span>
                                                            </span>
                                                            </>
                                                        }

                                                        <div className='infor'  id={optionIntem._id}>
                                                            <strong   id={optionIntem._id}>{optionIntem.name}</strong>
                                                            <p   id={optionIntem._id}>{optionIntem.occupation}</p>
                                                        </div>


                                                    </span>



                                            </button>
                                        </li>
                                    ))}
                            </ul>

                        </Scrollbars>
                    </nav>
                </div>


            </div>

            {
                id_collaborator_search_log && id_collaborator_search_log?.collaborator !== ""?
                    listCollaborar
                    .filter(optionIntem =>
                        optionIntem._id === id_collaborator_search_log?.collaborator
                    )
                    .map((optionIntem, index) => (

                        <div className='selected_contributor'  key={index}>
                            <p>Colaborador:</p>

                            <div className='collaborador'>
                                {
                                    optionIntem.avatar !== undefined && optionIntem.avatar !== "undefined" ?
                                    <>
                                    <span className="foto">
                                        <img src={optionIntem.avatar} alt="Imagem do collaborador" />
                                    </span>
                                    </>
                                    :
                                    <>
                                    <span className="colSemFoto">
                                        <span className="semImgCol"    >
                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                        </span>
                                    </span>
                                    </>
                                }

                                <div className='infor'  >
                                    <strong>{optionIntem?.name}</strong>
                                    <p>{optionIntem?.occupation}</p>
                                </div>
                            </div>
                            <button className="remover" onClick={remove_array_Col}>Remover</button>

                        </div>

                    ))
                :""
            }
        </>
    );
}

