import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../../../components/Layouts/default';
import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import Workday from '../../../../components/Administrativo/Colaboradores/profile/Professional/Workday';
import SubMenu from '../../../../components/Administrativo/Colaboradores/profile/Professional/subMenu';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import swal from 'sweetalert';
import api from '../../../../services/api';
import { decodeToken } from '../../../../services/auth';
import { toast } from 'react-toastify';

export default function ProfileWorkday(props) {
    
    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

       if(id && id) {loadcollaborator(id);}

    }, [id]);

    async function loadcollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('path', props.match.path);

            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {

                setLoadAvatar(false)
                loadcollaborator(id);

                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    const [ loadBtnOnAndOff, setLoadBtnOnAndOff ] = useState(false);
    const [ workdaySelect, setWorkdaySelect ] = useState('');

    async function addWorkdayToEmployee(e) {
        setWorkdaySelect(e)
        setLoadBtnOnAndOff(true)
        let weeklyJourney = collaborator?.weeklyJourney !== e ? e : null;
        if(e !== undefined) {
            await api.put(`/collaborator/${id}`, { weeklyJourney })
            .then(response => {
                loadcollaborator(id);
                setLoadBtnOnAndOff(false)
                
                setWorkdaySelect("");
                swal({ icon: "success", title: "Sucesso!", text: "Jornada de trabalho cadastrada com sucesso!" });

            }).catch(error => {
                setLoadBtnOnAndOff(false)
                swal({ icon: "error", title: "Erro!", text: error.response.data.message});
            });
        }
    }
    async function disableWorkdayToEmployee() {
        setLoadBtnOnAndOff(true)
        await api.put(`/collaborator/${id}`, { weeklyJourney: null })
        .then(response => {
            loadcollaborator(id);
            setLoadBtnOnAndOff(false)
            swal({ icon: "success", title: "Sucesso!", text: "Jornada de trabalho desabilitada com sucesso!" });

        }).catch(error => {
            setLoadBtnOnAndOff(false)
            swal({ icon: "error", title: "Erro!", text: error.response.data.message});
        });
    }
    return (
        <>
            <Layout
            path={props.match.path}
            >
            <Header
            collaborator={collaborator}
            id={id}
            />
            <InforProfile
                collaborator={collaborator}
                id={id}
                loadAvatar={loadAvatar}
                handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                role={role}
                path={props.match.path}
                loadcollaborator={loadcollaborator}
                />
                <SubHeader
                collaborator={collaborator}
                id={id}
                path={props.match.path}
                role={role}
                />
                <SubMenu
                collaborator={collaborator}
                id={id}
                role={role}
                path={props.match.path}
                />
                <Workday
                collaborator={collaborator}
                id={id}
                role={role}
                path={props.match.path}
                addWorkdayToEmployee={addWorkdayToEmployee}
                loadBtnOnAndOff={loadBtnOnAndOff}
                disableWorkdayToEmployee={disableWorkdayToEmployee}
                workdaySelect={workdaySelect}
                />
            </Layout>
        </>
    );
}

