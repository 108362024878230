import React, { useState, useEffect } from 'react';
import Carregamento from '../../../../assets/images/carregando.gif';
import iconUpload from '../../../../assets/images/docs-upload.svg';
import api from '../../../../services/api';
import { toast } from 'react-toastify';


const ColProfiDcocTermContracts = ({ id, collaboratorState }) => {

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ registeredPeriodicExam, setRegisteredPeriodicExam ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadPage, setLoadPage ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    loadtermorcontract();
}, []);

async function loadtermorcontract() {
    setLoadPage(true)
    await api.get(`/collaborator-periodic-exam-access/`)
    .then(response => {
            setLoadPage(false)
        if(response.data[0] !== undefined) {
            setRegisteredPeriodicExam(response.data);
        }
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ periodicExam, setFilterPeriodicExam ] = useState([]);

async function loadfilterperiodicexam(event) {
    setLoadPage(true)
    const name = event.target.value;
    await api.get(`/collaborator-periodic-exam-access-id/?id=${id}&name=${name}`)
    .then(response => {
        setLoadPage(false)
        setFilterPeriodicExam(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
    }


        return (
            <>

                <div className="box perfil-exame-periotica user">
                        <div className="buscaAdd">
                            <form action="">
                                <input type="text" onChange={(event) => loadfilterperiodicexam(event)} placeholder="Procure pela data e tipo..." className="busca"/>
                            </form>
                        </div>
                            <table>
                            <thead>
                                <tr className="tabelaTR">
                                    <th className="inten-1">
                                        Tipo
                                    </th>
                                    <th className="inten-2">Data</th>
                                    <th className="inten-3"></th>
                                    <th className="inten-4"></th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                            loadPage ?
                                (
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                    </>
                                ):
                                periodicExam[0] !== undefined ? periodicExam
                                .map((periodicexam, index) => (
                                <>
                                <tr className="tabelaTR">

                                    <td className="inten-1">
                                            <div className="motivo">
                                                <strong>{periodicexam.type}</strong>
                                            </div>
                                    </td>
                                    <td className="inten-2">
                                        {periodicexam.date}
                                    </td>
                                    <td className="inten-3">
                                        <a href={periodicexam.location} target="’_blank’">
                                            <img alt="icone de documentos" src={iconUpload} />
                                            {periodicexam.originalname}
                                        </a>
                                    </td>
                                </tr>
                                </>
                                )) :
                                registeredPeriodicExam.length === 0 ?
                                (<div className="nenhum-cadastrado-perfil"><p>Nenhum formação acadêmica cadastrada.</p></div>):
                                registeredPeriodicExam !== undefined ? registeredPeriodicExam
                                .map((periodicexam, index) => (
                                <>
                                <tr className="tabelaTR">

                                    <td className="inten-1">
                                            <div className="motivo">
                                                <strong>{periodicexam.type}</strong>
                                            </div>
                                    </td>
                                    <td className="inten-2">
                                        {periodicexam.date}
                                    </td>
                                    <td className="inten-3">
                                        <a href={periodicexam.location} target="’_blank’">
                                            <img alt="icone de documentos" src={iconUpload} />
                                            {periodicexam.originalname}
                                        </a>
                                    </td>
                                </tr>
                                </>
                                )) : (
                                    <>
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>

            </>
        );
}
export default ColProfiDcocTermContracts;
