/* eslint-disable use-isnan */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { differenceInDays, parse } from 'date-fns';
import Layout from '../../components/Layouts/defaultUser';
import Historico from '../../components/DashboardUser/vacation/Historic';
import RequestInProgress from '../../components/DashboardUser/vacation/Request-in-progress';
import { dateMask, mNumber } from '../../services/mask';
import api from '../../services/api';
import { dateFormatedToStripe } from '../../services/utils';
import { Modal } from "react-bootstrap";
import Close from '../../assets/images/close.svg';
import { decodeToken } from '../../services/auth';
import { StringToDate } from '../../services/utils'
import swal from 'sweetalert';
import { toast } from 'react-toastify';

export default function UserMyVacation(props) {

    const decoded  = decodeToken();
    const { path } = props.match;

    const collaborator = decoded.user._id;

    //////////////////////////////////////////////////////////////////////
    /////////////////       SET VISIBLE POPUP         ///////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    function AddVacationSummaryVisible(e) {

        let vestingPeriodReferenceYear = e;

        setRequestVacation({ ...requestVacation, vestingPeriodReferenceYear })

        setVisible(!visible);
    }

    function NoAddVacationSummaryVisible() {
        setRequestVacation([]);
        setVisible(!visible);
    }

    //////////////////////////////////////////////////////////////////////
    /////////////////////       Loads         ///////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnVacation, setLoadBtnVacation] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInitialDate, setLoadInitialDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFinalDate, setLoadFinalDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadVacationDaysSold, setLoadVacationDaysSold ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadComments, setLoadComments ] = useState(false);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ diasDisponivel, setDiasDisponivel] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // loadDiasDisponivel();
        loadOpenRequestPendente(collaborator);
        loadOpenRequestReprovado(collaborator);
        loadOpenRequestAprovado(collaborator);
        loadOpenRequestColetivas(collaborator);

        loadCollaboratorVestingperiod(collaborator)

    }, [collaborator]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadOpenRequest, setLoadPageOpenRequest] = useState(false);

   // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ openRequest, setOpenRequest] = useState([]);

    async function loadOpenRequestPendente(collaborator) {
        setLoadPageOpenRequest(true)
        await api.get(`/collaborator-vacation-new/?category=request&type=individual&collaborator=${collaborator}&status=pendente`)
        .then(response => {

            setLoadPageOpenRequest(false)
            setOpenRequest(response.data.collaboratorVacationNewSearch);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPageHistoryApproved, setLoadPageHistoryApproved] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ historyApproved, setHistoryApproved] = useState([]);

    async function loadOpenRequestAprovado(collaborator) {
        setLoadPageHistoryApproved(true)
        await api.get(`/collaborator-vacation-new/?category=vacation&type=individual&collaborator=${collaborator}&status=aprovado`)
        .then(response => {

            setLoadPageHistoryApproved(false)
            setHistoryApproved(response.data.collaboratorVacationNewSearch);
        }).catch(error=>{
            setLoadPageHistoryApproved(false)
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPageHistoryFailed, setLoadPageHistoryFailed] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ historyFailed, setHistoryFailed] = useState([]);

    async function loadOpenRequestReprovado(collaborator) {
        setLoadPageHistoryFailed(true)
        await api.get(`/collaborator-vacation-new/?category=request&type=individual&collaborator=${collaborator}&status=reprovado`)
        .then(response => {
            setLoadPageHistoryFailed(false)
            setHistoryFailed(response.data.collaboratorVacationNewSearch);
        }).catch(error => {
            setLoadPageHistoryFailed(false)
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [loadPageHistoryCollectiveVacation, setLoadPageHistoryCollectiveVacation] = useState(false);

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ historyCollectiveVacation, setHistoryCollectiveVacation] = useState([]);

    async function loadOpenRequestColetivas(collaborator) {
        setLoadPageHistoryCollectiveVacation(true)
        if(collaborator !== undefined) {
            await api.get(`/collaborator-vacation-new/?category=vacation&type=coletivas&collaborator=${collaborator}&status=aprovado`)
            .then(response => {
                setLoadPageHistoryCollectiveVacation(false)
                setHistoryCollectiveVacation(response.data.collaboratorVacationNewSearch);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ requestVacation, setRequestVacation] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ requestVacationDayCount, setRequestVacationDayCount] = useState('');

    function calculateDifferenceInDays(startDate, endDate) {
        const date1 = parse(startDate, 'dd/MM/yyyy', new Date());
        const date2 = parse(endDate, 'dd/MM/yyyy', new Date());
        if (date2 < date1) {
          return "Data final não pode ser menor que a data inicial.";
        }
        return differenceInDays(date2, date1);
    }
    function validateDate(dataString) {
    // Verifica se a string da data está no formato correto (dd/mm/aaaa)
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!dateRegex.test(dataString)) {
        return false;
    }
    
    // Converte a string em objeto Date para verificar se é uma data válida
    const [dia, mes, ano] = dataString.split('/').map(Number);
    const data = new Date(ano, mes - 1, dia);
    
    // Verifica se o objeto Date é válido e se os valores de dia, mês e ano correspondem
    return (
        data.getDate() === dia &&
        data.getMonth() === mes - 1 &&
        data.getFullYear() === ano
    );
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInitialDateString , setLoadInitialDateString ] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFinalDateString , setLoadFinalDateString  ] = useState("");

    async function myChangeHandleRequestVacation(event) {
        event.preventDefault();

        let nam              = event.target.name;
        let val              = event.target.value;
        let category         = "request";
        let ThirteenthSalary = false;
        let status           = 'pendente'

        if (nam === "startDate") {
            let startDate = dateMask(val)
            if(val.length >= 10) {

                let dateValidate = validateDate(startDate);
                if(dateValidate === false) {
                    setLoadInitialDate(true);
                    setLoadInitialDateString("Data inicial inválida.");
                    setRequestVacationDayCount(0);
                    return
                }else { 
                    setLoadInitialDateString("");
                    setLoadInitialDate(false) ;
                }

                let startDateStartVacationProcess = StringToDate(startDate);

                setRequestVacation({
                    ...requestVacation,
                    [nam]: startDate,
                    status,
                    collaborator,
                    startDateStartVacationProcess,
                    ThirteenthSalary,
                    category,
                });

                if(requestVacation?.endDate) {
                    const diferencaEmDias = calculateDifferenceInDays(val, requestVacation?.endDate);
                    console.log(diferencaEmDias)
                    setRequestVacationDayCount(diferencaEmDias)
                }

            }
            else { 
                setRequestVacation({ ...requestVacation, [nam]: startDate }) 
                setLoadInitialDateString("");
            }

        }
        else
        if (nam === "endDate") {
            let endDate = dateMask(val)
            if(val.length >= 10) {
                let dateValidate = validateDate(endDate);
                if(dateValidate === false) {
                    setLoadFinalDate(true)
                    setLoadFinalDateString("Data final inválida.")
                    setRequestVacationDayCount(0)
                    return
                }else {
                    setLoadFinalDate(false)
                    setLoadFinalDateString("")
                }
                let endDateStartVacationProcess = StringToDate(endDate)
                setRequestVacation({ ...requestVacation, [nam]: endDate, ThirteenthSalary, endDateStartVacationProcess })
                if(requestVacation?.startDate) {
                    const diferencaEmDias = calculateDifferenceInDays(requestVacation?.startDate, val);
                    if(isNaN(diferencaEmDias)) {setRequestVacationDayCount(0) } else {setRequestVacationDayCount(diferencaEmDias)}
                }

            }
            else {
                setRequestVacation({ ...requestVacation, [nam]: endDate, ThirteenthSalary })
                setLoadFinalDateString("")

            }



        }
        else
        if (nam === "vacationDaysSold") {

            if(val.length !== 0) {

                setLoadVacationDaysSold(false)
                let vacationDaysSold = mNumber(val)
                setRequestVacation({ ...requestVacation, [nam]: vacationDaysSold })

            }
            else {

                setLoadVacationDaysSold(true)
                setRequestVacation({ ...requestVacation, [nam]: val })

            }

        }
        //setLoadComments
        else
        if (nam === "comments") {

            if(val.length !== 0) {

                setLoadComments(false)
                setRequestVacation({ ...requestVacation,  [nam]: val })

            }
            else {

                setLoadComments(true)
                setRequestVacation({ ...requestVacation,  [nam]: val })

            }

        }
        else {
            setRequestVacation({ ...requestVacation, [nam]: val })
        }

    }

    async function saveRequestVacations() {
        if(requestVacation && requestVacation.length === 0) {
            setLoadInitialDate(true)
            setLoadFinalDate(true)
            setLoadVacationDaysSold(true)
            setLoadComments(true)
        }
        else {
            if(
                (requestVacation &&
                requestVacation.startDate === undefined) ||
                requestVacation.startDate === ''
            ) {

                setLoadInitialDate(true)

            }

            if(
                (requestVacation &&
                requestVacation.endDate === undefined) ||
                requestVacation.endDate === ''
            ) {

                setLoadFinalDate(true)

            }

            if(
                (requestVacation &&
                requestVacation.vacationDaysSold === undefined) ||
                requestVacation.vacationDaysSold === ''
            ) {

                setLoadVacationDaysSold(true)

            }

            if(
                requestVacation &&
                requestVacation.startDate      !== undefined &&
                requestVacation.startDate      !==     ''    &&
                requestVacation.endDate        !== undefined &&
                requestVacation.endDate        !==     ''    &&
                requestVacation.vacationDaysSold !== undefined &&
                requestVacation.vacationDaysSold !==     ''    
            ) {

                if(requestVacation.ThirteenthSalary === undefined) {
                    let ThirteenthSalary = false;
                    setRequestVacation({ ...requestVacation, ThirteenthSalary })
                }

                setLoadInitialDate(false)
                setLoadFinalDate(false)
                setLoadVacationDaysSold(false)
                setLoadBtnVacation(true)
                await api.post("/collaborator-vacation-new/", requestVacation)
                .then(response => {

                    console.log('response.data => post vacation new')
                    console.log(response.data)

                    async function collaboratorVestingPeriodUpdate() {

                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId: response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {

                            setLoadBtnVacation(false);
                            setVisible(!visible);
                            setLoadPageOpenRequest(true);
                            loadCollaboratorVestingperiod(collaborator);
                            loadOpenRequestReprovado(collaborator);
                            loadOpenRequestAprovado(collaborator);
                            loadOpenRequestPendente(collaborator);

                        })
                        .catch(error => {

                            swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                            setLoadBtnVacation(false)
                            setVisible(!visible);
                        });


                    }
                    collaboratorVestingPeriodUpdate()



                    swal({ icon: "success", title: "Sucesso!", text: "Férias solicitadas com sucesso!" });

                }).catch(error => {
                    setLoadBtnVacation(false)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });
            }
        }
    }

    async function deleteRequestVacations(e) {
        swal({
            title: "Atenção",
            text: "Deseja realmente excluir a solicitação de férias?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                // console.log(e)

                await api.delete(`/collaborator-vacation-new/${e}`)
                .then(response => {



                    async function loadUpdateVestingPeriodFirstBalance() {

                        // => Atualiza todos os saldos dos períodos aquisitivos
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {

                            loadOpenRequestPendente(collaborator);
                            loadOpenRequestReprovado(collaborator);
                            loadOpenRequestAprovado(collaborator);
                            loadOpenRequestColetivas(collaborator);

                            loadCollaboratorVestingperiod(collaborator)

                            swal({ icon: "success", title: "Sucesso!", text: "Solicitação de férias deletada com sucesso" });


                        }).catch(error => {

                            console.log(error)
                            swal({ icon: "error", title: "Erro!", text: "Férias deletada, porem os dias de férias dos períodos aquisitivos não foram atualizados, entre em contato com o administrador do sistema." });

                        });


                    }
                    loadUpdateVestingPeriodFirstBalance();

                }).catch(error => {});

            }
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadVacation, setLoadVacation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationCompanyVestingPeriodSearchGroup, setVacationCompanyVestingPeriodSearchGroup ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationCollaboratorVestingPeriodSearch, setVacationCollaboratorVestingPeriodSearch ] = useState([]);

    async function loadCollaboratorVestingperiod(collaborator) {

        setLoadVacation(true)
        await api.get(`/company-vesting-period?page=0&collaborator=${collaborator}`)
        .then(response => {
            setLoadVacation(false)
            setVacationCompanyVestingPeriodSearchGroup(response.data.companyVestingPeriodSearchGroup[0])
            setVacationCollaboratorVestingPeriodSearch(response.data.collaboratorVestingPeriodSearch)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    return (
        <>
            <Layout path={path}>
                <div className="page-vacation-user">
                    <div className="infor-solicitar-ferias">
                        <p className="infor">Você tem <span>{vacationCompanyVestingPeriodSearchGroup?.balanceAvailable !== undefined ? vacationCompanyVestingPeriodSearchGroup?.balanceAvailable : '0'} dias</span> de férias disponíveis
                            que devem ser tirados até <span>{vacationCompanyVestingPeriodSearchGroup?.limitConcessionPeriod !== undefined ? dateFormatedToStripe(vacationCompanyVestingPeriodSearchGroup?.limitConcessionPeriod) : '00/00/0000'}</span></p>
                        <p className="dashed"></p>

                        <ul>
                        {
                            loadVacation ?
                            'carregando...'
                            :
                            vacationCollaboratorVestingPeriodSearch &&
                            vacationCollaboratorVestingPeriodSearch.length !== 0 ?
                            vacationCollaboratorVestingPeriodSearch
                            .filter(vestingPeriod => vestingPeriod.status === "nao_utilizado")
                            .map((vestingPeriod, index) => (
                                <li key={index}>
                                    <div className="intemA">
                                        <p>Saldo disponível</p>
                                        <p>{vestingPeriod?.balanceAvailable ? vestingPeriod?.balanceAvailable : '-'}</p>
                                    </div>
                                    <div className="intemB">
                                        <p>Disponível em</p>
                                        <p>{vestingPeriod?.endDate ? dateFormatedToStripe(vestingPeriod?.endDate) : '-'}</p>
                                    </div>
                                    <div className="intemC">
                                        <p>Vencimento</p>
                                        <p>{vestingPeriod?.limitConcessionPeriod ? dateFormatedToStripe(vestingPeriod?.limitConcessionPeriod) : '-'}</p>
                                    </div>
                                    <div className="intemD">
                                    <button className="btnGreen more" onClick={() => AddVacationSummaryVisible(vestingPeriod?.referenceYear)}>Solicitar férias</button>
                                    </div>
                                </li>
                            ))
                            :""

                        }
                        </ul>
                    </div>

                    <RequestInProgress
                    openRequest={openRequest}
                    loadOpenRequest={loadOpenRequest}
                    deleteRequestVacations={deleteRequestVacations}
                    />
                    <Historico
                    loadPageHistoryApproved={loadPageHistoryApproved}
                    historyApproved={historyApproved}
                    loadPageHistoryFailed={loadPageHistoryFailed}
                    historyFailed={historyFailed}
                    loadPageHistoryCollectiveVacation={loadPageHistoryCollectiveVacation}
                    historyCollectiveVacation={historyCollectiveVacation}
                    />
                </div>

                <Modal show={visible} className="popup-edit-perfil popup-vation-user " onHide={NoAddVacationSummaryVisible}>
                    <div>
                        <div className="titulo">
                            <h4>Solicitar Férias <p className="btn-fechar" onClick={NoAddVacationSummaryVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>De *</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    name="startDate"
                                    onChange={myChangeHandleRequestVacation}
                                    value={requestVacation && requestVacation.startDate ? requestVacation.startDate : ''}
                                    placeholder="DD/MM/AAAA"
                                    className={loadInitialDate ? 'inputUm inputErro' : 'inputUm'}
                                    />
                                    <p className="inputUm textError">{loadInitialDate ? loadInitialDateString? loadInitialDateString : 'É necessário adicionar uma data' : ''}</p>
                                </div>
                                </li>
                            <li>
                                <div className="intemA">
                                    <p>Até *</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    name="endDate"
                                    onChange={myChangeHandleRequestVacation}
                                    value={requestVacation && requestVacation.endDate ? requestVacation.endDate : ''}
                                    placeholder="DD/MM/AAAA"
                                    className={loadFinalDate ? 'inputUm inputErro' : 'inputUm'}
                                    />
                                    <p className="inputUm textError">{loadFinalDate ? loadFinalDateString ? loadFinalDateString :'É necessário adicionar uma data' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Total de dias</p>
                                </div>
                                <div className="intemB">
                                    <p>{requestVacationDayCount !== "" ?
                                        requestVacationDayCount !== "Data final não pode ser menor que a data inicial." ?
                                        isNaN(requestVacationDayCount) ? 
                                            "0 dias" :
                                            `${requestVacationDayCount} ${requestVacationDayCount === 1 ?"dia": "dias"}`
                                            : requestVacationDayCount
                                        : "0 dias"}</p>
                                </div>
                            
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Dias de abono</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    name="vacationDaysSold"
                                    onChange={myChangeHandleRequestVacation}
                                    value={requestVacation && requestVacation.vacationDaysSold ? requestVacation.vacationDaysSold : ''}
                                    placeholder="Dias de abono"
                                    className={loadVacationDaysSold ? 'inputUm inputErro' : 'inputUm'}
                                    />
                                    <p className="inputUm textError">{loadVacationDaysSold ? 'É necessário adicionar um valor' : ''}</p>
                                </div>
                                </li>
                            <li>
                                <div className="intemA">
                                    <p>Antecipar 1ª Parcela do 13º salário</p>
                                </div>
                                <div className="intemB">
                                    <div className="radioNovo">
                                        <input
                                            type="radio"
                                            value="false"
                                            onChange={myChangeHandleRequestVacation}
                                            name="ThirteenthSalary"
                                            id="workingDayTreis"
                                            checked={
                                                requestVacation.ThirteenthSalary === "false" ?
                                                true
                                                :
                                                requestVacation.ThirteenthSalary === "true" ?
                                                false
                                                :
                                                true
                                            }
                                            />
                                        <label htmlFor="workingDayTreis" className="foreignTexto">Não</label>
                                    </div>
                                        <div className="radioNovo">
                                            <input
                                            type="radio"
                                            value="true"
                                            onChange={myChangeHandleRequestVacation}
                                            name="ThirteenthSalary"
                                            id="workingDayDois"
                                            checked={
                                                requestVacation.ThirteenthSalary === "true" ?
                                                true
                                                :
                                                requestVacation.ThirteenthSalary === "false" ?
                                                false
                                                :
                                                false
                                            }
                                            />
                                            <label htmlFor="workingDayDois" className="foreignTexto">Sim</label>
                                        </div>
                                </div>
                                </li>
                            <li>
                                <div className="intemA">
                                    <p>Justificativa</p>
                                </div>
                                <div className="intemB">
                                    <textarea name="comments" onChange={myChangeHandleRequestVacation} cols="150" placeholder="Descrição"
                                    className={loadComments ? 'inputErro' : ''}

                                    ></textarea>
                                    <p className="textError">{loadComments ? 'É necessário adicionar um comentário' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="btn-salvar">
                                    <button onClick={saveRequestVacations} type="submit" className={loadBtnVacation  ? 'saved btnGreen load' : 'saved btnGreen'}>Solicitar</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal>
            </Layout>
        </>
    );
}
