import React, { useState, useEffect } from 'react';
import Layout from '../../../../components/Layouts/default';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

import Header from '../../../../components/Administrativo/Colaboradores/header';
import HeaderNavigation from '../../../../components/Administrativo/Colaboradores/headerNavigation';
import Desligamento from '../../../../components/Administrativo/Colaboradores/Desligamento';
import './style.scss';

import { decodeToken } from '../../../../services/auth';

export default function ShutDown(props) {

    const { history } = props;
    const { user }    = decodeToken();
    const role        = user.role;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ shutdowProcessSearch, setShutdowProcessSearch ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ recentShutdown, setRecentShutdown ] = useState([]);

    useEffect(() => {
        loadshutdowcollaborator(nameFilter);
    }, [nameFilter]);

    async function loadfilterpercollaborator(event) {
        event.preventDefault();
        setLoad(true);
        const name = event.target.value;
        setNameFilter(event.target.value);
        loadshutdowcollaborator(name);
    }

    async function loadshutdowcollaborator(nameFilter) {
        setLoad(true)
        await api.get(`/collaborator-new/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&shutdownProcess=true&page=0&limit=20`)
        .then(response => {
            setLoad(false)
            setShutdowProcessSearch(response.data.collaboratorSearch);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
        await api.get(`/collaborator-new/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&recentShutdown=true&page=0&limit=20`)
        .then(response => {
            setLoad(false)
            setRecentShutdown(response.data.collaboratorSearch);
        }).catch(error =>  {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                history={history}
                user={user}
                role={role}
                />
                <HeaderNavigation
                nameFilter={nameFilter}
                path={props.match.path}
                onUserInputChange={(event) => loadfilterpercollaborator(event)}
                user={user}
                role={role}

                />
                <Desligamento
                // shutdowProcess={shutdowProcess}
                shutdowProcessSearch={shutdowProcessSearch}
                recentShutdown={recentShutdown}
                load={load}
                loadshutdown={load}
                />

            </Layout>
        </>
    );
}
