import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/header';
import SubMenu from '../../../components/Administrativo/ScoreAdmin/subMenu';
import iconVerMais from '../../../assets/images/more.svg';
import { Dropdown } from "react-bootstrap";
import Carregamento from '../../../assets/images/carregando.gif';
import IllustrationPonto from '../../../assets/images/illustration-ponto-setting.svg';
import apiV2 from '../../../services/apiV2';
import { toast } from 'react-toastify';
import './styles.scss';

export default function ScoreAdmin(props) {
    useEffect(() => {
        loadWorkJourneyCompany();
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [workJourney, setWorkJourney] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadWorkJourney, setloadWorkJourney] = useState(false);
    async function loadWorkJourneyCompany() {
        setloadWorkJourney(true)
        await apiV2.get('/weekly-journey?')
        .then(response => {
            if(response?.data?.items) setWorkJourney(response?.data?.items);
            setloadWorkJourney(false)
        }).catch(error => {
            setloadWorkJourney(false);
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    function accessJourneyForEditing(id) {  window.location.href = `/ponto/editar-jornada/${id}`; }
    return (
        <>
            <Layout path={props.match.path}>
                <Header path={props.match.path} />
                <div className="page-ponto">
                    <SubMenu props={props} />
                    <div className="page-journeys">
                        <section className='header'>
                            <div>
                                <h2 className="titulo">Jornadas</h2>
                                <p className='subTitulo'>Crie ou edite jornadas de trabalho personalizadas</p>
                            </div>
                            <NavLink to="/ponto/cadastrar-jornada" className="btnGreen more btn-add-jornada">
                                Adicionar jornada
                            </NavLink>
                        </section>
                        <div className='journey-list'>
                            {
                                loadWorkJourney === true ?
                                    <div className="carregamentoWork">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                    :
                                    workJourney?.length === 0 ?
                                        <>

                                            <div className="nenhuma-jornada-cadastrada" >
                                                <p>Nenhuma jornada cadastrado.</p>
                                                <NavLink to="/ponto/cadastrar-jornada" className="btnGreen more btn-add-jornada">
                                                    Adicionar jornada
                                                </NavLink>
                                                <img src={IllustrationPonto} alt="Nenhuma jornada cadastrado." />
                                            </div>
                                        </>
                                        :
                                        workJourney?.length !== 0 ?
                                            workJourney.map((journey) => (
                                                <>
                                                    <div key={journey._id} className='jornada'>
                                                        <h6 className="name">
                                                            {journey?.name}
                                                            <Dropdown className="dropdown-icon">
                                                                <Dropdown.Toggle>
                                                                    <img alt="" src={iconVerMais} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <button onClick={() => accessJourneyForEditing(journey?._id)} >Editar jornada</button>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </h6>
                                                        <ul className="header-infor">
                                                            <li>Dia</li>
                                                            <li>Período 1</li>
                                                            <li>Período 2</li>
                                                            <li>Hora Total</li>
                                                        </ul>
                                                        <ul className='list-body-infor'>
                                                            {
                                                                journey?.days.map((dayWeek) => (
                                                                    <>
                                                                        <li key={dayWeek._id}>
                                                                            <ul className="body-infor">
                                                                                <li>
                                                                                    {
                                                                                        dayWeek.dayOfWeek === "1" ?
                                                                                            "Segunda-feira" :
                                                                                            dayWeek.dayOfWeek === "2" ?
                                                                                                "Terça-feira" :
                                                                                                dayWeek.dayOfWeek === "3" ?
                                                                                                    "Quarta-feira" :
                                                                                                    dayWeek.dayOfWeek === "4" ?
                                                                                                        "Quinta-feira" :
                                                                                                        dayWeek.dayOfWeek === "5" ?
                                                                                                            "Sexta-feira" :
                                                                                                            dayWeek.dayOfWeek === "6" ?
                                                                                                                "Sábado" :
                                                                                                                dayWeek.dayOfWeek === "0" ?
                                                                                                                    "domingo" : ""
                                                                                    }
                                                                                </li>
                                                                                <li>
                                                                                    {
                                                                                        dayWeek?.period1?.initTime ?
                                                                                            dayWeek?.period1?.initTime : ""
                                                                                    } - {
                                                                                        dayWeek?.period1?.endTime ?
                                                                                            dayWeek?.period1?.endTime : ""
                                                                                    }
                                                                                </li>
                                                                                <li>
                                                                                    {
                                                                                        dayWeek?.period2?.initTime ?
                                                                                            dayWeek?.period2?.initTime : ""
                                                                                    } - {
                                                                                        dayWeek?.period2?.endTime ?
                                                                                            dayWeek?.period2?.endTime : ""
                                                                                    }
                                                                                </li>
                                                                                <li>
                                                                                    {
                                                                                        dayWeek?.totalTimeWorking ?
                                                                                            dayWeek?.totalTimeWorking : "-"
                                                                                    }
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </>
                                                                ))
                                                            }

                                                        </ul>
                                                        <p className='total-amount-of-hours'>Total: <span> {journey?.totalTimeWorking} horas</span></p>
                                                    </div>
                                                </>
                                            ))
                                            :
                                            ''
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
