import React from 'react';
import { NavLink } from 'react-router-dom'
import Carregando from '../../../../assets/images/carregando.gif';
// import ImgExemplo from '../../../../assets/images/alvarobraz.png';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import IllustrationPraia from '../../../../assets/images/illustration-praia.svg';
import { dateFormatedToStripe } from '../../../../services/utils'
export default function HistoricoFerias({
    loadHistoryVacations,
    vacationsCompanyRepproved,
    pageRepproved,
    prevPageRepproved,
    pagesRepproved,
    nextPageRepproved,
    paginationPageRepproved
}) {

    return (
        <>
            <div className="lista-historico">


            {
                loadHistoryVacations ?
                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                :
                vacationsCompanyRepproved                &&
                vacationsCompanyRepproved  !== undefined &&
                vacationsCompanyRepproved.length !== 0 ?
                <>
                <ul className="lista">
                    {
                        vacationsCompanyRepproved &&
                        vacationsCompanyRepproved.length !== 0 &&
                        vacationsCompanyRepproved !== undefined ?
                        vacationsCompanyRepproved
                        .map((vacationsCompany, index) => (
                            <li key={index}>
                                <div className="infor-cal">
                                {
                                    vacationsCompany.collaborator.avatar !== undefined ?
                                    <>
                                    <div className="img-col">
                                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                            <img src={vacationsCompany.collaborator.avatar} alt="nome" />
                                        </NavLink>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <NavLink to="/ferias/" >
                                        <div className="semImgCol">
                                            <img src={IconUserNone} alt="coll" />
                                        </div>
                                    </NavLink>
                                    </>
                                }
                                <div className="nome-setor">
                                    <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                    {vacationsCompany.collaborator.name}
                                    </NavLink>
                                    <div className="setor">
                                        <p className="setor">{vacationsCompany.collaborator.occupation}</p>
                                    </div>
                                </div>
                                </div>
                                <div className="info-historico">
                                <p>De <span>{dateFormatedToStripe(vacationsCompany.startDate)}</span></p>
                                    <p>Até <span>{dateFormatedToStripe(vacationsCompany.endDate)}</span></p>
                                    <p>Dias de férias <span>{vacationsCompany.vacationDays}</span></p>
                                    <p>Dias de abono <span>{vacationsCompany.vacationDaysSold}</span></p>
                                    <p>Total <span>{vacationsCompany.balance}</span></p>
                                </div>
                        </li>
                        )) :
                        ''
                    }
                </ul>
                </>
                :
                <>
                <div className="lista-vazia">
                    <p>Essa praia está deserta. <br/>
                        Nenhum histórico de férias.</p>
                        <img src={IllustrationPraia} alt="illustração praia" />
                </div>
                </>
            }
            </div>
        </>
    );
}
