import React from 'react';
import HeaderAdmin from '../../HeaderAdmin';

export default function headerScore({
    filterBuscaCol,
    path
}) {
    let debounceTimer;
    async function onUserInputChangeTime(event) {
        event.preventDefault();
        clearTimeout(debounceTimer);
        const name = event.target.value;
        debounceTimer = setTimeout(() => {
            filterBuscaCol(name);
        }, 500); 
    }
    return (
        <>
        <HeaderAdmin>
            <div className='header-score-geral'>
                <div>
                    <h1>Ponto</h1>
                    <p>Acompanhe o ponto dos colaboradores em tempo real</p>
                </div>
                {
                    path === "/ponto" ?
                        <input name="name"  type="text" placeholder="Pesquisar um colaborador" className="busca" onChange={onUserInputChangeTime}/>
                
                        : ""
                    }
                </div>
        </HeaderAdmin>
        {
            path === "/ponto" ?
            
                <div className='header-score-geral-mb'>
                    <input name="name"  type="text" placeholder="Pesquisar um colaborador" className="busca" onChange={onUserInputChangeTime}/>
                </div>
            : ""
          }
        </>
    );
}

