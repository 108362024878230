import React, { useState } from 'react';
import Logo from '../../assets/images/logo.svg';
import IllustrationTrial from '../../assets/images/illustration-free-trial.svg';
import StepOne from '../../components/FreeTrial/StepOne';
// import StepTwo from '../../components/FreeTrial/StepTwo';
import Sucesso from '../../components/FreeTrial/Success';
import {
    // mCNPJ,
    // mCNPJ_ONLY_NUMBERS,
    // mCPF_ONLY_NUMBERS,
    // mCPF,
    // mNumber,
    // creditCard,
    // expirationDateCard,
    // cvcCard,
    phoneNumbersOnly,
    phones,
} from '../../services/mask';
import swal from 'sweetalert';
import api from '../../services/api';
// import axios from 'axios';
import './styles.scss';
export default function  pageFreeTrial(props) {

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [etapasSucesso, setEtapasSucesso] = useState(false);

    ////////////////////////////////////////////////////////////
    /////////////////    Load Etapa 1    ///////////////////////
    ////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNome, setLoadNome] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmail, setLoadEmail] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPhone, setLoadPhone] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNomeEmpresa, seteLoadNomeEmpresa] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ loadCnpj, setLoadCnpj] = useState(false);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ loadCpf, setLoadCpf] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButton, setLoadButton] = useState(false);

    ////////////////////////////////////////////////////////////
    /////////////////    Load Etapa 2    ///////////////////////
    ////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////
    /////////////////       myChangeHandler      ///////////////
    ////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ freeTrialAdd, setfreeTrialAdd] = useState([]);

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'nameCompany') {

            if(val.length === 0) {
                seteLoadNomeEmpresa(true)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
            }
            else {
                seteLoadNomeEmpresa(false)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
            }

        }
        else
        if(nam === 'nameCollaborator') {

            if(val.length === 0) {
                setLoadNome(true)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
            }
            else {
                setLoadNome(false)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
            }

        }
        else
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmail(true)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
            }
            else {
                setLoadEmail(false)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
            }

        }
        else
        if(nam === 'phone') {

            if(val.length === 0) {
                setLoadPhone(true)
                let phone = phones(val)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: phone })
            }
            else {
                setLoadPhone(false)
                let phone = phones(val)
                setfreeTrialAdd({ ...freeTrialAdd, [nam]: phone })
            }

        }
        else {
            setfreeTrialAdd({ ...freeTrialAdd, [nam]: val })
        }
    }

    ////////////////////////////////////////////////////////////
    /////////////////            save           ///////////////
    ////////////////////////////////////////////////////////////

    async function SaveTrial() {
        if(freeTrialAdd && freeTrialAdd.length === 0) {
            swal({ icon: "error", title: "Erro!", text: "Necessário adicionar todos os campos!" });
        }
        else {


            // const formData = new FormData();
            if(
                freeTrialAdd                                   &&
                freeTrialAdd.nameCompany                !== undefined &&
                freeTrialAdd.nameCompany                !== ''
            ) {

                seteLoadNomeEmpresa(false)
            }
            else {
                seteLoadNomeEmpresa(true)
            }

            if(
                freeTrialAdd                                   &&
                freeTrialAdd.nameCollaborator                !== undefined &&
                freeTrialAdd.nameCollaborator                !== ''
            ) {

                setLoadNome(false)
            }
            else {
                setLoadNome(true)
            }

            if(
                freeTrialAdd                                   &&
                freeTrialAdd.email                !== undefined &&
                freeTrialAdd.email                !== ''
            ) {

                // console.log('freeTrialAdd.email')
                // console.log(freeTrialAdd.email)

                // console.log('freeTrialAdd.email.indexOf(" ")')
                // console.log(freeTrialAdd.email.indexOf(" "))

                if(freeTrialAdd.email.indexOf(" ") > 0) {

                    swal({ icon: "error", title: "Erro!", text: 'Seu e-mail não pode conter espaços.' });
                    setLoadEmail(false)
                    return

                }


                if(freeTrialAdd.email.search("@") === -1) {

                    swal({ icon: "error", title: "Erro!", text: 'Seu e-mail deve conter ( @ ).' });
                    setLoadEmail(false)
                    return

                }


                let usuario = freeTrialAdd.email.substring(0, freeTrialAdd.email.indexOf("@"));
                let dominio = freeTrialAdd.email.substring(freeTrialAdd.email.indexOf("@")+ 1, freeTrialAdd.email.length);

                console.log('dominio.indexOf(".")')
                console.log(dominio.indexOf("."))
                if(dominio.indexOf(".") <= 0) {

                    swal({ icon: "error", title: "Erro!", text: 'Seu e-mail deve conter um domínio com ( . ), ex: \n\n nome@dominio.com' });
                    setLoadEmail(false)
                    return

                }

                if (
                    (usuario.length >=1)  &&
                    (dominio.length >=3)  &&
                    (usuario.search("@")  === -1) &&
                    (dominio.search("@")  === -1) &&
                    (usuario.search(" ")  === -1) &&
                    (dominio.search(" ")  === -1) &&
                    (dominio.search(".")  !== -1) &&
                    (dominio.indexOf(".") >=1)&&
                    (dominio.lastIndexOf(".") < dominio.length - 1)
                ) {
                    setLoadEmail(false)
                }
                else {

                    swal({ icon: "error", title: "Erro!", text: 'Formato de e-mail infomado inválido' });
                    setLoadEmail(false)
                    return

                }


            }
            else {
                setLoadEmail(true)
            }

            if(
                freeTrialAdd                                    &&
                freeTrialAdd.phone                !== undefined &&
                freeTrialAdd.phone                !== ''
            ) {

                setLoadPhone(false)
            }
            else {
                setLoadPhone(true)
            }


            if(
                freeTrialAdd                                     &&
                (freeTrialAdd.nameCompany        !== undefined   &&
                freeTrialAdd.nameCompany         !==       '')   &&
                (freeTrialAdd.nameCollaborator   !== undefined   &&
                freeTrialAdd.nameCollaborator    !==       '')   &&
                (freeTrialAdd.email              !== undefined   &&
                freeTrialAdd.email               !==       '')   &&
                (freeTrialAdd.phone              !== undefined   &&
                freeTrialAdd.phone               !==       '')
            ) {
                let nameCompany      = freeTrialAdd.nameCompany;
                let nameCollaborator = freeTrialAdd.nameCollaborator;
                let email            = freeTrialAdd.email;
                let phone            = phoneNumbersOnly(freeTrialAdd.phone);

                const storeTrial = {
                    nameCollaborator,
                    email,
                    phone,
                    nameCompany,
                    path: path,
                }


                // console.log('freeTrialAdd')
                // console.log(freeTrialAdd)

                setLoadButton(true)
                await api.post('/collaborator-company-trial/', storeTrial)
                .then(response => {
                    setLoadButton(false)
                    // let companyId     = response.data.companyId
                    // let nameCompany   = freeTrialAdd.nameCompany
                    // let name          = response.data.name
                    // let email         = response.data.email
                    // let phoneNumber   = freeTrialAdd.phone
                    // let cpf           = freeTrialAdd.cpf
                    // let cnpj          = freeTrialAdd.cnpj

                    // localStorage.setItem('@peopleview/nameCompany', nameCompany);
                    // localStorage.setItem('@peopleview/name', name);
                    // localStorage.setItem('@peopleview/email', email);
                    // localStorage.setItem('@peopleview/phone', phoneNumber);
                    // localStorage.setItem('@peopleview/companyId', companyId);
                    // localStorage.setItem('@peopleview/cpf', cpf);
                    // localStorage.setItem('@peopleview/cnpj', cnpj);


                    // swal({ icon: "success", title: "Sucesso!", text: "Contador adicionado com sucesso." });

                    setEtapasSucesso(true);

                }).catch(error => {
                    setLoadButton(false)
                    // console.log("error.response.data.error")
                    // console.log(error.response.data.message)
                    swal({
                        icon: "error",
                        title: "Erro!",
                        text:
                        error.response.data.message ?
                        error.response.data.message
                        :
                        ''
                    });
                });
            }
        }
    }

    return (
        <>
           <div className={
               etapasSucesso ? "page-free-treal sucesso" : "page-free-treal"
           }>
               <img src={Logo} alt="People View" className="logo"/>
               <h1 className="titulo">Estamos animados com seu interesse em conhecer o People View</h1>

               {
                    etapasSucesso ?
                    <>
                        <Sucesso/>
                    </>
                    :
                    <>
                        <div className="box-page">
                            {/* <span className="name-stap">Passo {etapaUmConcluida ? "2": "1"} de 2</span> */}
                            {
                                // etapaUmConcluida ?
                                // <>
                                //     <h2 className="sub-titulo">Dados do cartão <span>(não será cobrado agora)</span></h2>
                                // </>
                                // :
                                // <>
                                //     <h2 className="sub-titulo">Dados pessoais</h2>
                                // </>
                            }
                            <h2 className="sub-titulo">Dados cadastrais</h2>
                            <div className="box-form">
                                {/* <div className={etapaUmConcluida ? 'form' : 'form etap-n-concluida '}> */}
                                <div className="form etap-n-concluida">
                                        <StepOne
                                            loadNome={loadNome}
                                            loadEmail={loadEmail}
                                            loadPhone={loadPhone}
                                            loadNomeEmpresa={loadNomeEmpresa}
                                            // loadCnpj={loadCnpj}
                                            // loadPostCode={loadPostCode}
                                            loadButton={loadButton}
                                            // etapaUmConcluida={etapaUmConcluida}
                                            // loadCpf={loadCpf}
                                            myChangeHandler={myChangeHandler}
                                            freeTrialAdd={freeTrialAdd}
                                            SaveTrial={SaveTrial}
                                            // inforCompanyCol={inforCompanyCol}
                                            // nameTem={nameTem}
                                            // emailTem={emailTem}
                                            // phoneTem={phoneTem}
                                            // cnpjTem={cnpjTem}
                                            // cpfTem={cpfTem}
                                            // nameCompanyTem={nameCompanyTem}
                                        />
                                        {/* <StepTwo
                                            loadNameHolder={loadNameHolder}
                                            loadNumberCard={loadNumberCard}
                                            loadExpirationDate={loadExpirationDate}
                                            loadCvc={loadCvc}
                                            loadButton={loadButton}
                                            etapaUmConcluida={etapaUmConcluida}
                                            evSucesso={evSucesso}
                                            paymentMethod={paymentMethod}
                                            loadPostCode={loadPostCode}
                                            loadNumero={loadNumero}
                                            loadRuaAvenida={loadRuaAvenida}
                                            loadState={loadState}
                                            loadCyty={loadCyty}
                                            loadNeighborhood={loadNeighborhood}
                                            myChangeHandlerTwo={myChangeHandlerTwo}
                                        /> */}
                                </div>
                                <div className="ilustracao">
                                    <img src={IllustrationTrial} alt="" />
                                </div>
                            </div>
                        </div>
                    </>
                 }
               <span className="copyright">© 2022 People View</span>
           </div>
        </>
    );
}
