import React, { useState, useEffect } from 'react';

import { Modal } from "react-bootstrap";
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Close from '../../../../../../assets/images/close.svg';
import api from '../../../../../../services/api';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
import Carregando from '../../../../../../assets/images/carregando.gif';
import { dataFormatadaParaBarra } from '../../../../../../services/utils';
import { toast } from 'react-toastify';

export default function ColProfileExperiencePeriod({ id, path, collaborator, loadPage, role }) {
    //////////////////////////////////////////////////////////////////////
    /////////////////////       Loads         ///////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTimeCourse, setLoadTimeCourse ] = useState(false);
    const [ loadTermination, setLoadTermination ] = useState(false);
    const [ loadsecondEnd, setLoadsecondEnd ] = useState(false);
    const [ loadTimeCourseChose, setLoadTimeCourseChose ] = useState(false);

    const [ loadTimeCourseSaved, setLoadTimeCourseSaved ] = useState(false);
    const [ loadTimeCourseUpdate, setLoadTimeCourseUpdate ] = useState(false);

    const [ loadTimeCourseCustomized, setLoadTimeCourseCustomized ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

       if(id && id) {loadExperiencePeriod(id);}

    }, [id]);


    async function loadExperiencePeriod(id) {
        await api.get(`/collaborator-experience-period/${id}`)
        .then(response => {
            setLoadTimeCourseSaved(response.data[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    //////////////////////////////////////////////////////////////////////
    /////////////////       SET VISIBLE POPUP         ///////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);
    function EditExperiencePeriodVisible(e) {


        if(e !== undefined){
            setLoadTimeCourseUpdate(e)
        } else {
            setLoadTimeCourseUpdate([])
        }

        setVisible(!visible);
    }
    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;
        setLoadTimeCourseCustomized(false)
        setLoadTimeCourse(false)


        let collaborator = id;

        if(val === 'Nenhum'){
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: val, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
            return
        } else
        if(val === '1 x 15 dias'){
            let value = "1x15";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '1 x 30 dias'){
            let value = "1x30";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '1 x 45 dias'){
            let value = "1x45";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '1 x 60 dias'){
            let value = "1x60";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '1 x 90 dias'){
            let value = "1x90";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '2 x 15 dias'){
            let value = "2x15";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '2 x 30 dias'){
            let value = "2x30";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '2 x 45 dias'){
            let value = "2x45";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '30 + 60 dias'){
            let value = "30x60";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else
        if(val === '44 + 46 dias'){
            let value = "44x46";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, [nam]: value, path: path })
            setLoadTimeCourseCustomized(false)
            setLoadTimeCourse(false)
        } else {
            let timeCourse = "Customizado";
            let collaborator = id;
            setLoadTimeCourseChose({ ...loadTimeCourseChose, collaborator, timeCourse, [nam]: val, path: path })
            setLoadTimeCourseCustomized(true)
            setLoadTimeCourse(false)
        }

    }

    async function handleSubmit(event) {
        event.preventDefault();

        if(loadTimeCourseUpdate._id !== undefined) {

            if (loadTimeCourseChose && loadTimeCourseChose.timeCourse !== undefined) {

                if(loadTimeCourseChose.timeCourse === 'Customizado') {
                    if(loadTimeCourseChose.termination === undefined) {
                        setLoadTermination(true)
                        return
                    } else {
                        setLoadTermination(false)
                    }
                    if(loadTimeCourseChose.secondEnd === undefined) {
                        setLoadsecondEnd(true)
                        return
                    } else {
                        setLoadsecondEnd(false)
                    }

                }
                setLoad(true)
                await api.put(`/collaborator-experience-period/${loadTimeCourseUpdate._id}`, loadTimeCourseChose)
                .then(response => {

                    setLoad(false);
                    setLoadTimeCourseChose([]);
                    setLoadTimeCourseUpdate([]);
                    setLoadTimeCourseSaved([]);
                    setVisible(!visible);
                    loadExperiencePeriod(id);


                    swal({ icon: "success", title: "Sucesso!", text: "Período de experiência alterado com sucesso!" });
                }).catch(error => {
                    setLoad(false)
                    swal({ icon: "error", title: "Erro!", text:  `ERRO: ${error.response.data.message}` });
                });
            } else {
                setLoadTimeCourse(true)
                return
            }
        } else {

            if (loadTimeCourseChose && loadTimeCourseChose.timeCourse !== undefined) {

                setLoadTimeCourse(false)
                if(loadTimeCourseChose.timeCourse === 'Customizado') {
                    if(loadTimeCourseChose.termination === undefined) {
                        setLoadTermination(true)
                        return
                    } else {
                        setLoadTermination(false)
                    }
                    if(loadTimeCourseChose.secondEnd === undefined) {
                        setLoadsecondEnd(true)
                        return
                    } else {
                        setLoadsecondEnd(false)
                    }
                }

                setLoad(true)
                await api.post('/collaborator-experience-period/', loadTimeCourseChose)
                .then(response => {

                    setLoad(false)
                    setLoadTimeCourseChose([]);
                    setLoadTimeCourseSaved([]);
                    setLoadTimeCourseUpdate([]);
                    setVisible(!visible);
                    loadExperiencePeriod(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Período de experiência criado com sucesso!" });

                }).catch(error => {
                    setLoad(false)
                    //console.log(error.response.data.message)
                    swal({ icon: "error", title: "Erro!", text:  `ERRO: ${error.response.data.message}` });
                });
            } else {
                setLoadTimeCourse(true)
                return
            }
        }
    }


    return (
        <>

            <div className="box ExperiencePeriod">
                <h3>Período de Experiência { (role && role === 'admin') || (role && role === 'master') ? <button className="edit-Benefit" onClick={() => EditExperiencePeriodVisible(loadTimeCourseSaved)}><img src={EditBlack} alt="Edite Informações" /></button> : '' } </h3>
                {
                loadPage ?
                    <>
                        <div className="carregamento">
                            <img alt="" src={Carregando} />
                        </div>
                    </>
                    :
                    <>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Período</p>
                                </div>
                                <div className="intemB">
                                    <p>{loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '1x15'  ? '1 x 15' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '1x30'  ? '1 x 30' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '1x45'  ? '1 x 45' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '1x60'  ? '1 x 60' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '1x90'  ? '1 x 90' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '2x15'  ? '2 x 15' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '2x30'  ? '2 x 30' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '2x45'  ? '2 x 45' :
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '30x60' ? '30 + 60':
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === '44x46' ? '44 + 46':
                                    loadTimeCourseSaved &&
                                    loadTimeCourseSaved.timeCourse === 'Customizado' ? 'Customizado':
                                    '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Data de admissão</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.admissionDate ? collaborator.admissionDate : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Término</p>
                                </div>
                                <div className="intemB">
                                    <p>{loadTimeCourseSaved && loadTimeCourseSaved.termination !== undefined ? dataFormatadaParaBarra(loadTimeCourseSaved.termination) : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Segundo Término</p>
                                </div>
                                <div className="intemB">
                                <p>{loadTimeCourseSaved && loadTimeCourseSaved.secondEnd !== undefined ? dataFormatadaParaBarra(loadTimeCourseSaved.secondEnd) : '-'}</p>
                                </div>
                            </li>
                        </ul>
                    </>
                }
            </div>

            <Modal show={visible} className="popup-edit-perfil" onHide={EditExperiencePeriodVisible}>
                <div>
                    <div className="titulo">
                        <h4>Período de Experiência <p className="btn-fechar" onClick={EditExperiencePeriodVisible}> <img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <strong><p>Período</p></strong>
                            </div>
                            <div className="intemB">




                                <select onChange={myChangeHandler} name="timeCourse" className={loadTimeCourse ? 'inputErro' : ''}>
                                    {loadTimeCourseUpdate && loadTimeCourseUpdate.timeCourse !== undefined ?
                                    (<option value={
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x15'  ? '1 x 15' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x30'  ? '1 x 30' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x45'  ? '1 x 45' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x60'  ? '1 x 60' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x90'  ? '1 x 90' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '2x15'  ? '2 x 15' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '2x30'  ? '2 x 30' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '2x45'  ? '2 x 45' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '30x60' ? '30 + 60':
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '44x46' ? '44 + 46':
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === 'Customizado' ? 'Customizado': ''
                                    }>{
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x15'  ? '1 x 15' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x30'  ? '1 x 30' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x45'  ? '1 x 45' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x60'  ? '1 x 60' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '1x90'  ? '1 x 90' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '2x15'  ? '2 x 15' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '2x30'  ? '2 x 30' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '2x45'  ? '2 x 45' :
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '30x60' ? '30 + 60':
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === '44x46' ? '44 + 46':
                                        loadTimeCourseUpdate &&
                                        loadTimeCourseUpdate.timeCourse === 'Customizado' ? 'Customizado': ''
                                        }</option>) :
                                    ('')
                                    }
                                    {[
                                        "Nenhum",
                                        "1 x 15 dias",
                                        "1 x 30 dias",
                                        "1 x 45 dias",
                                        "1 x 60 dias",
                                        "1 x 90 dias",
                                        "2 x 15 dias",
                                        "2 x 30 dias",
                                        "2 x 45 dias",
                                        "30 + 60 dias",
                                        "44 + 46 dias",
                                        "Customizado"
                                    ].map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                    ))}

                                </select>
                                <p className="textError">{loadTimeCourse ? 'É necessário escolher um período' : ''}</p>
                            </div>
                        </li>
                        <li hidden={loadTimeCourseCustomized ? false : true}>
                            <div className="intemA">
                                <strong><p>Término</p></strong>
                            </div>
                            <div className="intemB">
                            <InputMask mask="99/99/9999" type="text" name="termination" defaultValue={loadTimeCourseUpdate && loadTimeCourseUpdate.termination !== undefined ? dataFormatadaParaBarra(loadTimeCourseUpdate.termination) : '' } onChange={myChangeHandler} placeholder="DD/MM/AAAA" className={loadTermination ? ' inputErro' : ''}/>
                                <p className="textError">{loadTermination ? 'É necessário escolher uma data de término' : ''}</p>
                            </div>
                        </li>
                        <li hidden={loadTimeCourseCustomized ? false : true}>
                            <div className="intemA">
                                <strong><p>Segundo Término</p></strong>
                            </div>
                            <div className="intemB">
                            <InputMask mask="99/99/9999" type="text" name="secondEnd" defaultValue={loadTimeCourseUpdate && loadTimeCourseUpdate.secondEnd !== undefined ? dataFormatadaParaBarra(loadTimeCourseUpdate.secondEnd) : '' } onChange={myChangeHandler} placeholder="DD/MM/AAAA" className={loadsecondEnd ? ' inputErro' : ''}/>
                                <p className="textError">{loadsecondEnd? 'É necessário escolher uma data de segundo término' : ''}</p>
                            </div>
                        </li>
                        <li >
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={handleSubmit} className="btn-salvar">
                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>{loadTimeCourseSaved && loadTimeCourseSaved !== undefined ? 'Editar' : 'Salvar'}</button>
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    );
}

