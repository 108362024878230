import React, { useState, useEffect, useRef } from 'react';
import IconReloadPassword from '../../../../assets/images/cached_black.svg';
import IconRemove from '../../../../assets/images/delete.svg';
import CurrencyInput from 'react-currency-input';
import swal from 'sweetalert';
import api from '../../../../services/api';
import { mCPF, dateMask } from '../../../../services/mask';
import {removerSpecials} from '../../../../services/utils'
import { Scrollbars } from 'react-custom-scrollbars';
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado';
import { toast } from 'react-toastify';

export default function formBasicInfor({ id, path, tasksCompanyReduce }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ photoAvatar, setPhotoAvatar ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupations, setOccupations ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costcenter, setCostcenter ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ companies, setCompanies ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ managers, setManagers ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        // loadcollaboratoredition(id);
        if(id && id) {
            loadcollaborator(id);
            loadcollaboratorsaved(id);
        }
        loadoccupations();
        loadsectors();
        loadcostcenter();
        loadcompanies();
        loadmanagers();

    }, [id]);

    async function loadcollaborator(id) {
        if(id && id !== undefined) {
            await api.get(`/collaborator/${id}`)
            .then(response => {

                setCollaborator(response.data);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        } else {}
    }

    async function loadoccupations() {
        await api.get('/occupation/?sortBy=name&order=asc')
        .then(response => {
            setOccupations(response.data);
        }).catch(error => {})
    }

    async function loadsectors() {
        await api.get('/sector/?sortBy=name&order=asc')
        .then(response => {
            setSectors(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcostcenter() {
        await api.get('/costcenter/')
        .then(response => {
            setCostcenter(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcompanies() {
        await api.get('/company-admin/')
        .then(response => {
            setCompanies(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function loadmanagers() {
        await api.get('/collaborator-new?role=manager&status=Ativo')
        .then(response => {
            setManagers(response.data.collaboratorSearch);
        }).catch(error => {});
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusName   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCpf     = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusOccupation   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusSalary   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusSector   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCostCenter   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusRole   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusAdmissionType   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusAdmissionDate   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusTemporaryPassword   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadName, setLoadName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCpf, setLoadCpf ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTemporaryPassword, setLoadTemporaryPassword ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadOccupation, setLoadOccupation ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSalary, setLoadSalary ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSector, setLoadSector ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAdmissionType, setLoadAdmissionType ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAdmissionDate, setLoadAdmissionDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCostCenter, setLoadCostCenter ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRole, setLoadRole ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupationsSalary, setOccupationsSalary ] = useState(false);

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if (collaborator && collaborator?._id === undefined) {
            if (nam === "file") {
                let file = event.target.files[0]
                const typeI = file.type ;
                if ((typeI === 'image/jpg') ||
                    (typeI === 'image/jpeg') ||
                    (typeI === 'image/pjpeg') ||
                    (typeI === 'image/png')  ||
                    (typeI === "image/webp")
                ) {
                    setPhotoAvatar({
                        ...photoAvatar,
                        [nam]: event.target.files[0],
                        fileName: event.target.files[0].name,
                        fileURL: URL.createObjectURL(event.target.files[0])
                    })
                } else {
                    swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
                }
            }
            else
            if(nam === 'name') {

                if(val.length === 0) {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadName(true)
                }
                else {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadName(false)
                }

            }
            else
            if(nam === 'email') {

                setCollaborator({ ...collaborator, [nam]: val })

            }
            else
            if(nam === 'personalEmail') {

                // setLoadPersonalEmail(false)
                setCollaborator({ ...collaborator, [nam]: val })

            }
            if(nam === 'cpf') {
                let cpf = mCPF(val)
                setCollaborator({ ...collaborator, [nam]: cpf })
                setLoadCpf(false)
            }
            else
            if(nam === 'occupation') {

                setBuscaOcupacao({ ...buscaOcupacao, "nomeOcupação": val });
                setLoadOccupation(false)

                await api.get(`/occupation/?name=${val}`)
                .then(response => {
                    setOccupationsSalary(response.data[0])
                    setCollaborator({ ...collaborator, [nam]: val  })
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(nam === 'salary') {
                let valSalry = val.replace('R$ ', '');

                if(valSalry === "0.00") {
                    // let salary = nMoney(val)
                    setCollaborator({ ...collaborator, [nam]: valSalry })
                    setLoadSalary(true)
                }
                else {
                    // let salary = nMoney(val)
                    setCollaborator({ ...collaborator, [nam]: valSalry })
                    setLoadSalary(false)


                }
            }
            else
            if(nam === 'sector') {

                setBuscaSector({ ...buscaSector, "nomeSector": val });
                setCollaborator({ ...collaborator, [nam]: val })
                setLoadSector(false)

            }
            else
            if(nam === 'costCenter') {

                if(val.length === 0) {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadCostCenter(true)
                }
                else {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadCostCenter(false)
                }

            }
            else
            if(nam === 'admissionType') {

                if(val.length === 0) {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadAdmissionType(true)
                }
                else {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadAdmissionType(false)
                }

            }
            else
            if(nam === 'role') {

                if(val.length === 0) {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadRole(true)
                }
                else {
                    setCollaborator({ ...collaborator, [nam]: val })
                    setLoadRole(false)
                }

            }
            else
            if(nam === 'admissionDate') {

                if(val.length === 0) {
                    let admissionDate = dateMask(val)
                    setCollaborator({ ...collaborator, [nam]: admissionDate })
                    setLoadAdmissionDate(true)
                }
                else {
                    let admissionDate = dateMask(val)
                    setCollaborator({ ...collaborator, [nam]: admissionDate })
                    setLoadAdmissionDate(false)
                }

            }
            else
            if(nam === 'dateAdmissionExamination') {
                let dateAdmissionExamination = dateMask(val)
                setCollaborator({ ...collaborator, [nam]: dateAdmissionExamination })

            }
            else
            if(nam === 'temporaryPassword') {

                setLoadTemporaryPassword(false)
                setCollaborator({ ...collaborator, [nam]: val })

            }
            else  {
                setCollaborator({ ...collaborator, [nam]: val })
            }

        } else {
            if(nam === 'name') {

                if(val.length === 0) {
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
                    setLoadName(true)
                }
                else {
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
                    setLoadName(false)
                }

            }
            else
            if (nam === "file") {

                let file = event.target.files[0]
                const typeI = file.type ;

                if ((typeI === 'image/jpg') ||
                    (typeI === 'image/jpeg') ||
                    (typeI === 'image/pjpeg') ||
                    (typeI === 'image/png')  ||
                    (typeI === "image/webp")
                ) {
                    setPhotoAvatar({
                        ...photoAvatar,
                        [nam]: event.target.files[0],
                        fileName: event.target.files[0].name,
                        fileURL: URL.createObjectURL(event.target.files[0])
                    })
                } else {
                    swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
                }


            }
            else
            if(nam === 'cpf') {
                if(val.length === 14) {

                    let cpf = mCPF(val)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cpf })
                    setLoadCpf(false)

                }
                else {

                    let cpf = mCPF(val)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cpf })
                    setLoadCpf(true)

                }

            }
            else
            if(nam === 'occupation') {
                setLoadOccupation(false)
                setBuscaOcupacao({ ...buscaOcupacao, "nomeOcupação": val });
                await api.get(`/occupation/?name=${val}`)
                .then(response => {
                    setOccupationsSalary(response.data[0]);
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val  })
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });


            }
            else
            if(nam === 'sector') {

                setBuscaSector({ ...buscaSector, "nomeSector": val });
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
                setLoadSector(false)

            }
            else
            if(nam === 'salary') {
                let valSalry = val.replace('R$ ', '');

                if(valSalry === "0.00") {
                    // let salary = nMoney(val)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valSalry })
                    setLoadSalary(true)
                }
                else {
                    // let salary = nMoney(val)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valSalry })
                    setLoadSalary(false)
                }
            }
            else
            if(nam === 'admissionDate') {

                if(val.length === 10) {
                    let admissionDate = dateMask(val)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: admissionDate })
                    setLoadAdmissionDate(false)
                }
                else {
                    let admissionDate = dateMask(val)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: admissionDate })
                    setLoadAdmissionDate(true)
                }

            }
            else
            if(nam === 'dateAdmissionExamination') {
                let dateAdmissionExamination = dateMask(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: dateAdmissionExamination })

            }
            else
            if(nam === 'personalEmail') {

                // setLoadPersonalEmail(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })

            }
            else
            if(nam === 'email') {

                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })

            }
            else
            if(nam === 'temporaryPassword') {

                if(val.length === 0) {
                    setLoadTemporaryPassword(true)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
                }
                else {
                    setLoadTemporaryPassword(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
                }

            }
            else {

                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })

            }
        }
    }




    async function save(event) {
        event.preventDefault();

        if(collaborator && collaborator?._id === undefined) {

                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(photoAvatar.file !== undefined){
                    formData.append('file', photoAvatar.file);
                }
                if(collaborator.name === undefined || collaborator.name === "") {
                    setLoadName(true)
                    focusName.current.focus();
                } else {
                    setLoadName(false)
                    formData.append('name', collaborator.name);
                }

                if(collaborator.personalEmail !== undefined && collaborator.personalEmail !== "") {
                    let usuario = collaborator.personalEmail.substring(0, collaborator.personalEmail.indexOf("@"));
                    let dominio = collaborator.personalEmail.substring(collaborator.personalEmail.indexOf("@")+ 1, collaborator.personalEmail.length);
                    if (
                        (usuario.length >=1)  &&
                        (dominio.length >=3)  &&
                        (usuario.search("@")  === -1) &&
                        (dominio.search("@")  === -1) &&
                        (usuario.search(" ")  === -1) &&
                        (dominio.search(" ")  === -1) &&
                        (dominio.search(".")  !== -1) &&
                        (dominio.indexOf(".") >=1)&&
                        (dominio.lastIndexOf(".") < dominio.length - 1)
                    ) {
                        formData.append('personalEmail', collaborator.personalEmail);
                    }
                    else {
                        swal({ icon: "error", title: "Erro!", text: 'Formato de e-mail pessoal inválido' });
                        return
                    }


                }

                if(collaborator.cpf === undefined || collaborator.cpf === "") {
                    setLoadCpf(true)
                    focusCpf.current.focus();
                    // return
                } else {
                    setLoadCpf(false)
                    formData.append('cpf', collaborator.cpf);
                }


                if(collaborator.email !== undefined && collaborator.email !== "") {
                    let usuario = collaborator.email.substring(0, collaborator.email.indexOf("@"));
                    let dominio = collaborator.email.substring(collaborator.email.indexOf("@")+ 1, collaborator.email.length);
                    if (
                        (usuario.length >=1)  &&
                        (dominio.length >=3)  &&
                        (usuario.search("@")  === -1) &&
                        (dominio.search("@")  === -1) &&
                        (usuario.search(" ")  === -1) &&
                        (dominio.search(" ")  === -1) &&
                        (dominio.search(".")  !== -1) &&
                        (dominio.indexOf(".") >=1)&&
                        (dominio.lastIndexOf(".") < dominio.length - 1)
                    ) {
                        formData.append('email', collaborator.email);
                    }
                    else {
                        swal({ icon: "error", title: "Erro!", text: 'Formato de e-mail de acesso inválido' });
                        return
                    }


                }

                if(collaborator.occupation === undefined || collaborator.occupation === "") {
                    setLoadOccupation(false)
                    formData.append('occupation', '-');
                } else {
                    setLoadOccupation(false)
                    formData.append('occupation', collaborator.occupation);
                }

                if(collaborator?.salary === undefined || collaborator.salary === "0.00"|| collaborator.salary === "") {
                    setLoadSalary(true)
                    focusSalary.current.focus()
                    // return
                } else {
                    setLoadSector(false)
                    formData.append('salary', collaborator.salary);
                }

                if(collaborator.sector === undefined || collaborator.sector === "") {
                    setLoadSector(false)
                    formData.append('sector', '-');
                } else {
                    setLoadSector(false)
                    formData.append('sector', collaborator.sector);
                }

                if(collaborator.costCenter === undefined || collaborator.costCenter === "") {
                    setLoadCostCenter(true)
                    focusCostCenter.current.focus()
                    // return
                } else {
                    setLoadCostCenter(false)
                    formData.append('costCenter', collaborator.costCenter);
                }

                if (collaborator.manager !== undefined && collaborator.manager !== 'Gestor' && collaborator.manager !== '-') {
                    formData.append('manager', collaborator.manager);
                }

                if(collaborator.admissionType === undefined || collaborator.admissionType === "") {
                    setLoadAdmissionType(true)
                    focusAdmissionType.current.focus();
                    // return
                } else {
                    setLoadAdmissionType(false)
                    formData.append('admissionType', collaborator.admissionType);
                }

                if(collaborator.role === undefined || collaborator.role === "") {
                    setLoadRole(true)
                    focusRole.current.focus()
                    // return
                } else {
                    setLoadRole(false)
                    formData.append('role', collaborator.role);
                }

                if(collaborator.temporaryPassword === undefined || collaborator.temporaryPassword === "") {
                    setLoadTemporaryPassword(true)
                    focusTemporaryPassword.current.focus()
                    // return
                } else {
                    setLoadTemporaryPassword(false)
                    formData.append('temporaryPassword', collaborator.temporaryPassword);
                }
                if(collaborator.admissionDate === undefined || collaborator.admissionDate === "") {
                    setLoadAdmissionDate(true)
                    focusAdmissionDate.current.focus();
                    // return
                } else {
                    setLoadAdmissionDate(false)
                    formData.append('admissionDate', collaborator.admissionDate);
                }

                if (collaborator.dateAdmissionExamination !== undefined && collaborator.dateAdmissionExamination !== '') {
                    formData.append('dateAdmissionExamination', collaborator.dateAdmissionExamination);
                }

                let collaboratorEdition = "false";
                let stepOne = "true";
                let stepTwo = "false";
                let stepThree = "false";
                let stepFour = "false";
                let admissionProcess = "true";

                formData.append('collaboratorEdition', collaboratorEdition);
                formData.append('stepOne', stepOne);
                formData.append('stepTwo', stepTwo);
                formData.append('stepThree', stepThree);
                formData.append('stepFour', stepFour);
                formData.append('admissionProcess', admissionProcess);
                formData.append('path', path);

                if(
                    collaborator.name          !== undefined &&
                    collaborator.cpf           !== undefined &&
                    (
                        collaborator.salary        !== undefined ||
                        collaborator.salary        !== "0.00"
                    ) &&
                    collaborator.admissionType     !== undefined &&
                    collaborator.costCenter        !== undefined &&
                    collaborator.admissionDate     !== undefined &&
                    collaborator.admissionDate     !== ''        &&
                    collaborator.temporaryPassword !== undefined &&
                    collaborator.temporaryPassword !== ''        &&
                    collaborator.role              !== undefined
                ) {

                    let item = [];

                    if(tasksCompanyReduce && tasksCompanyReduce.length !== 0) {

                        for(let tcr=0; tcr < tasksCompanyReduce.length; tcr++){

                            let status = tasksCompanyReduce[tcr].status;

                            if(status === true) {
                                item.push(tasksCompanyReduce[tcr])
                            }

                        }

                    }
                    setLoadContinue(true)
                    await api.post('/collaborator', formData, config)
                    .then(response => {

                        let idCollaborator = response.data._id;

                        async function addCheckListColaborattor(){

                            await api.post('/checklist-collaborator-admission/', { collaborator: idCollaborator, item, path: path })
                            .then(response => {

                            }).catch(error => {})

                        }
                        addCheckListColaborattor()
                        setLoadContinue(false)
                        setCollaborator({})

                        window.location.href = `/colaboradores/nova-admissao/formulario-dados-pessoais/${response.data._id}`;

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!a", text: error.response.data.message });
                        setLoadContinue(false)
                    });
                }

        } else {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (photoAvatar.file !== undefined && photoAvatar.file !== '') {
                formData.append('file', photoAvatar.file);
            }
            if (collaboratorUpdate._id !== undefined && collaboratorUpdate._id !== '') {
                formData.append('_id', collaboratorUpdate._id);
            }

            if(
                collaboratorUpdate?.name === ""
            ) {
                setLoadName(true)
                focusName.current.focus();
                return
            }
            if(collaboratorUpdate?.name !== undefined) {
                setLoadName(false)
                formData.append('name', collaboratorUpdate.name);
            }

            if (collaboratorUpdate.personalEmail !== undefined && collaboratorUpdate.personalEmail !== '') {

                let usuario = collaboratorUpdate.personalEmail.substring(0, collaboratorUpdate.personalEmail.indexOf("@"));
                let dominio = collaboratorUpdate.personalEmail.substring(collaboratorUpdate.personalEmail.indexOf("@")+ 1, collaboratorUpdate.personalEmail.length);

                if (
                    (usuario.length >=1)  &&
                    (dominio.length >=3)  &&
                    (usuario.search("@")  === -1) &&
                    (dominio.search("@")  === -1) &&
                    (usuario.search(" ")  === -1) &&
                    (dominio.search(" ")  === -1) &&
                    (dominio.search(".")  !== -1) &&
                    (dominio.indexOf(".") >=1)&&
                    (dominio.lastIndexOf(".") < dominio.length - 1)
                ) {
                    formData.append('personalEmail', collaboratorUpdate.personalEmail);
                }
                else {

                    swal({ icon: "error", title: "Erro!", text: 'Formato de e-mail inválido' });
                    return
                }

            }

            if(
                collaboratorUpdate?.cpf === "" || collaboratorUpdate?.cpf?.length < 14
            ) {
                setLoadCpf(true)
                focusCpf.current.focus();
                return
            }
            if(collaboratorUpdate?.cpf !== undefined) {
                setLoadCpf(false)
                formData.append('cpf', collaboratorUpdate.cpf);
            }

            if(collaboratorUpdate.email !== undefined && collaboratorUpdate.email !== "") {
                let usuario = collaboratorUpdate.email.substring(0, collaboratorUpdate.email.indexOf("@"));
                let dominio = collaboratorUpdate.email.substring(collaboratorUpdate.email.indexOf("@")+ 1, collaboratorUpdate.email.length);
                if (
                    (usuario.length >=1)  &&
                    (dominio.length >=3)  &&
                    (usuario.search("@")  === -1) &&
                    (dominio.search("@")  === -1) &&
                    (usuario.search(" ")  === -1) &&
                    (dominio.search(" ")  === -1) &&
                    (dominio.search(".")  !== -1) &&
                    (dominio.indexOf(".") >=1)&&
                    (dominio.lastIndexOf(".") < dominio.length - 1)
                ) {
                    formData.append('email', collaboratorUpdate.email);
                }
                else {
                    swal({ icon: "error", title: "Erro!", text: 'Formato de e-mail de acesso inválido' });
                    return
                }

            }

            if (collaboratorUpdate.sector !== undefined && collaboratorUpdate.sector !== '') {
                formData.append('sector', collaboratorUpdate.sector);
            }
            if (collaboratorUpdate.admissionType !== undefined && collaboratorUpdate.admissionType !== '') {
                formData.append('admissionType', collaboratorUpdate.admissionType);
            }
            if (collaboratorUpdate.occupation !== undefined && collaboratorUpdate.occupation !== '') {
                formData.append('occupation', collaboratorUpdate.occupation);
            }

            if(
                collaboratorUpdate?.salary === "0.00"
            ) {
                setLoadSalary(true)
                focusSalary.current.focus();
                // focusSalary.current.focus();
                // return
            }
            if(collaboratorUpdate?.salary !== undefined) {
                setLoadSalary(false)
                formData.append('salary', collaboratorUpdate.salary);
            }


            if (collaboratorUpdate.role !== undefined && collaboratorUpdate.role !== '') {
                formData.append('role', collaboratorUpdate.role);
            }
            if (collaboratorUpdate.costCenter !== undefined && collaboratorUpdate.costCenter !== '') {
                formData.append('costCenter', collaboratorUpdate.costCenter);
            }

            if (collaboratorUpdate.manager !== undefined && collaboratorUpdate.manager !== 'Gestor' && collaboratorUpdate.manager !== '-'){
                formData.append('manager', collaboratorUpdate.manager);
            }

            if(
                collaboratorUpdate?.admissionDate === "" || collaboratorUpdate?.admissionDate?.length < 10
            ) {
                setLoadAdmissionDate(true)
                focusAdmissionDate.current.focus();
                return
            }
            if(collaboratorUpdate?.admissionDate !== undefined) {
                setLoadAdmissionDate(false)
                formData.append('admissionDate', collaboratorUpdate.admissionDate);
            }

            if (collaboratorUpdate.dateAdmissionExamination !== undefined && collaboratorUpdate.dateAdmissionExamination !== '') {
                formData.append('dateAdmissionExamination', collaboratorUpdate.dateAdmissionExamination);
            }

            if(
                collaboratorUpdate?.temporaryPassword === ""
            ) {
                setLoadTemporaryPassword(true)
                focusTemporaryPassword.current.focus();
                return
            }
            if(collaboratorUpdate?.temporaryPassword !== undefined) {
                setLoadTemporaryPassword(false)
                formData.append('temporaryPassword', collaboratorUpdate.temporaryPassword);
            }

            let stepOne             = "true";
            formData.append('stepOne', stepOne);
            formData.append('path', path);


            setLoadContinue(true)
            await api.put(`/collaborator/${collaborator._id}`, formData, config)
            .then(response => {

                setLoadContinue(false)

                if(response.data.stepsNewAdmission === 2 ){
                    window.location.href = `/colaboradores/nova-admissao/formulario-dados-pessoais/${id}`;
                }else if(response.data.stepsNewAdmission === 3 ){
                    window.location.href = `/colaboradores/nova-admissao/formulario-documentos/${id}`;
                }else if( response.data.stepsNewAdmission === 4 ){
                    window.location.href = `/colaboradores/nova-admissao/formulario-beneficios/${id}`;
                }else if( response.data.stepsNewAdmission === 5 ){
                    window.location.href = `/colaboradores/nova-admissao/enviar-contador/${id}`;
                }

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadContinue(false)
            });


        }

    }


    async function excludeAdmssion(event) {
        event.preventDefault();

        if(collaborator?._id) {

            swal({
                title: "Atenção",
                text: "Deseja realmente excluir o processo de admissão deste colaborador?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    let _id              = collaborator._id;
                    let admissionProcess = "false";
                    let shutdownProcess  = "true";
                    let recentShutdown   = "false";

                    formData.append('_id', _id);
                    formData.append('admissionProcess', admissionProcess);
                    formData.append('shutdownProcess', shutdownProcess);
                    formData.append('recentShutdown', recentShutdown);
                    formData.append('status', 'Inativo');
                    formData.append('path', path);

                    await api.put(`/collaborator/${collaborator._id}`, formData, config)
                    .then(response => {
                        swal({ icon: "success", title: "Sucesso!", text: "Colaborador removido com sucesso!" });

                        window.location.href = '/colaboradores/nova-admissao/formulario-Informacoes-basicas';
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover a colaborador, tente novamente mais tarde." });
                    });

                }
            })

        }
        else {

            swal({ icon: "error", title: "Erro!", text: "Não existe nenhum colaborador para excluir!" });

        }

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);
    async function loadcollaboratorsaved(id) {

        if(id && id !== undefined) {

            await api.get(`/collaborator/${id}`)
            .then(response => {
                setCollaboratorSaved(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }

    }

    //////////////////////////////////////////////////////
    //////////////////////////////////////////////////////

    async function removeAvatar(event) {
        event.preventDefault();
        //alert('entrou')

        swal({
            title: "Atenção",
            text: "Deseja realmente remover a foto deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                let avatar             = 'undefined';
                let avatarKey          = 'undefined';
                let originalnameAvatar = 'undefined';

                await api.put(`/collaborator/${id}`, { avatar, avatarKey, originalnameAvatar, path: path })
                .then(response => {
                    loadcollaborator(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Foto removida com sucesso!" });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover a foto, tente novamente mais tarde." });
                });

            }
        })

    }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA OCUPACAO /////////////////
    ////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaOcupacao, setBuscaOcupacao ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaOcupacaoState, setBuscaOcupacaoState ] = useState([]);
     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [isActiveSelectBuscaOccupation, setIsActiveSelectBuscaOccupation] = useState(false);
     async function SelectBuscaOccupation() { setIsActiveSelectBuscaOccupation(!isActiveSelectBuscaOccupation) }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA DEPARTAMENTO /////////////////
    ////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSector, setBuscaSector ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSectorState, setBuscaSectorState ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaSector, setIsActiveSelectBuscaSector] = useState(false);
    async function SelectBuscaSector() { setIsActiveSelectBuscaSector(!isActiveSelectBuscaSector) }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA DEPARTAMENTO //////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectCostcenter, setIsActiveSelectCostcenter] = useState(false);
    async function SelectCostcenter() { setIsActiveSelectCostcenter(!isActiveSelectCostcenter) }

    //////////////////////////////////////////////////////
    /////////////////  BUSCA DA GESTOR  /////////////////
    ////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaManager, setIsActiveSelectBuscaManager] = useState(false);
    async function SelectBuscaManager() { setIsActiveSelectBuscaManager(!isActiveSelectBuscaManager) }


    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA TIPO DE USUARIO ///////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaRole, setIsActiveSelectBuscaRole] = useState(false);
    async function SelectBuscaRole() { setIsActiveSelectBuscaRole(!isActiveSelectBuscaRole) }


    const myChangeHandlerSelects = event => {

        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "nomeOcupação"){
            let valOcupation = removerSpecials(val).toLowerCase()
            setBuscaOcupacao({ ...buscaOcupacao, [nam]: val });
            setBuscaOcupacaoState({ ...buscaOcupacaoState, [nam]: valOcupation });
        }else

        if (nam === "nomeSector"){
            let valSector = removerSpecials(val).toLowerCase()
            setBuscaSector({ ...buscaSector, [nam]: val });
            setBuscaSectorState({ ...buscaSectorState, [nam]: valSector });
        }
    }

    /////////////////////////////////////////////
    ///////////////// Filtros  /////////////////
    ///////////////////////////////////////////
    const occupationsFiltre = occupations.filter((ocupacao) => {
        return removerSpecials(ocupacao.name).toLowerCase().includes(buscaOcupacaoState?.nomeOcupação !== undefined ? buscaOcupacaoState.nomeOcupação : "" )  ;
    });
    const sectorsFiltre = sectors.filter((sector) => {
        return removerSpecials(sector.name).toLowerCase().includes(buscaSectorState?.nomeSector !== undefined ? buscaSectorState.nomeSector : "" )  ;
    });

    // async function loadcollaboratoredition(id) {
    //     if(id && id !== undefined) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    async function loadgetPassword() {

        var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJLMNOPQRSTUVWXYZ";
        var passwordLength = 8;
        var password = "";

        for (var i = 0; i < passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
        }
        if(id && id !== undefined) {
            setCollaboratorUpdate({ ...collaboratorUpdate, "temporaryPassword": password })
        }
        else {

            setCollaborator({ ...collaborator, "temporaryPassword": password })

        }

        setLoadTemporaryPassword(false)

    }
    return (
        <>
            <div className="formBasicInfor">
                <div className="formInfor">
                    <h3 className='titulo'>Informações básicas</h3>
                    <div className='form-basic'>
                        {/* <img alt="" src={IconsProfile} className="iconProfile"/> */}
                        <div className="inforInput foto-inpt">
                            <label>Foto</label>
                            <div className='foto'>
                            {
                            collaborator && collaborator.avatar !== undefined && collaborator.avatar !== 'undefined' ?
                                photoAvatar && photoAvatar.fileURL !== undefined ?
                                <label htmlFor="file" className="label-foto-adicionado" style={{display:'block'}}>
                                <img alt="+" src={photoAvatar.fileURL}/></label> :
                                <label htmlFor="file" className="label-foto-adicionado" style={{display:'block'}}>
                                <img alt="+" src={collaborator.avatar}/></label>
                            :
                            photoAvatar && photoAvatar.fileURL !== undefined ?
                                <label htmlFor="file" className="label-foto-adicionado" style={{display:'block'}}>
                                <img alt="+" src={photoAvatar.fileURL}/></label>
                            :
                                <label htmlFor="file" className="labelIconPhoto"><div className="iconPhoto"><span></span>Enviar foto</div> </label>
                            }
                            {/* <label htmlFor="file" className="labelIconPhoto"><div className="iconPhoto"><span></span>Enviar foto</div> </label> */}
                            <input type="file" name="file" id="file" onChange={(e) => myChangeHandler(e)} className="inputFile"/>
                            {
                                collaborator && collaborator.avatar !== undefined && collaborator.avatar !== 'undefined' ?
                                    <div className="remover-foto">
                                        <button onClick={removeAvatar}><img src={IconRemove} alt="" /> Remover foto</button>
                                    </div>
                                :
                                ''
                            }
                            </div>
                        </div>
                        <div className="inforInput">

                            <label  htmlFor="name">Nome completo *</label>
                            <div>
                                <input ref={focusName} autoComplete="off" type="text" onChange={myChangeHandler} name="name" value={
                                    collaboratorUpdate.name !== undefined ?
                                    collaboratorUpdate.name
                                    :
                                    collaborator.name       !== undefined ?
                                    collaborator.name
                                    :
                                    ''
                                } placeholder="Nome Completo" className={loadName ? 'inputErro' : ''} />
                                {loadName ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}

                            </div>
                        </div>
                        <div className="inforInput">
                            <label  htmlFor="personalEmail">Email</label>
                            <div>
                                <input type="personalEmail" name="personalEmail" autoComplete="off" onChange={myChangeHandler} value={
                                    collaboratorUpdate.personalEmail !== undefined ?
                                    collaboratorUpdate.personalEmail
                                    :
                                    collaborator.personalEmail       !== undefined ?
                                    collaborator.personalEmail
                                    :
                                    ''
                                } placeholder="E-mail" />


                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="occupation" className='occupationLabel'>Cargo</label>
                            <div>
                                <div className="select-busca-btn">
                                    <input
                                        type="text"
                                        className={loadOccupation ? "menu-button inputErro" : "menu-button"}
                                        placeholder='Selecione'
                                        name="nomeOcupação"
                                        autoComplete="off"
                                        value={
                                            buscaOcupacao?.nomeOcupação !== undefined ?
                                                buscaOcupacao.nomeOcupação :
                                            collaboratorUpdate && collaboratorUpdate?.occupation === '' ?
                                            ''
                                            :
                                            collaborator && collaborator?.occupation ?
                                                collaborator.occupation :
                                                ''
                                        }
                                        ref={focusOccupation}
                                        onClick={ SelectBuscaOccupation }
                                        onBlur={ () => (setIsActiveSelectBuscaOccupation(false)) }
                                        onChange={myChangeHandlerSelects}
                                    />
                                    <nav className={`menu ${isActiveSelectBuscaOccupation ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height:
                                        occupationsFiltre.length === 0 ? 30 : "" ||
                                        occupationsFiltre.length === 1 ? 30 : ""  ||
                                        occupationsFiltre.length === 2 ? 70 : ""  ||
                                        occupationsFiltre.length === 3 ? 120 : 120
                                        }}>
                                            <ul>
                                                {
                                                    occupationsFiltre.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    occupationsFiltre
                                                    .map((occupation, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={occupation.name}
                                                            name="occupation"
                                                            onClick={myChangeHandler}
                                                            >{occupation.name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                {occupationsSalary && occupationsSalary !== undefined ? (
                                        occupationsSalary?.maximumSalary !== undefined &&
                                        occupationsSalary.minimumSalary !== undefined ?
                                            <p className="faixa-salarial">A faixa salarial desse cargo é de <br></br>
                                            R$ {occupationsSalary?.minimumSalary === undefined ? "0,00" : occupationsSalary.minimumSalary} - R$ {occupationsSalary?.maximumSalary === undefined ? "0,00" :  occupationsSalary.maximumSalary}</p>
                                        : ""
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="sector">Salário *</label>
                            <div
                                    ref={focusSalary} >

                            <CurrencyInput
                                    prefix="R$ "
                                    className={loadSalary ? 'inputErro' : ''}
                                    onChangeEvent={myChangeHandler}
                                    name="salary"
                                    placeholder="R$ 0.00"
                                    value={
                                        collaboratorUpdate.salary !== undefined ?
                                        collaboratorUpdate.salary
                                        :
                                        collaborator.salary       !== undefined ?
                                        collaborator.salary
                                        :
                                        null}
                                    />

                                {
                                loadSalary ?
                                    <p className="textError">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="sector">Departamentos</label>
                            <div>
                                <div className="select-busca-btn">
                                    <input
                                        type="text"
                                        className={loadSector ? "menu-button inputErro" : "menu-button"}
                                        placeholder='Selecione'
                                        name="nomeSector"
                                        autoComplete="off"
                                        value={
                                            buscaSector?.nomeSector !== undefined ?
                                            buscaSector.nomeSector :
                                            collaboratorUpdate && collaboratorUpdate?.sector === '' ?
                                            ''
                                            :
                                            collaborator && collaborator?.sector ?
                                                collaborator.sector :
                                                ''
                                        }
                                        ref={focusSector}
                                        onClick={ SelectBuscaSector }
                                        onBlur={ () => (setIsActiveSelectBuscaSector(false)) }
                                        onChange={myChangeHandlerSelects}
                                    />
                                    <nav className={`menu ${isActiveSelectBuscaSector ? "ativo": ""}`} >
                                        <Scrollbars className='listDropDown'  style={{  height:
                                        sectorsFiltre.length === 0 ? 30 : "" ||
                                        sectorsFiltre.length === 1 ? 30 : ""  ||
                                        sectorsFiltre.length === 2 ? 70 : ""  ||
                                        sectorsFiltre.length === 3 ? 120 : 120
                                        }}>
                                            <ul>
                                                {
                                                    sectorsFiltre.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    sectorsFiltre
                                                    .map((sector, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={sector.name}
                                                            name="sector"
                                                            onClick={myChangeHandler}
                                                            >{sector.name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                {loadSector ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="costCenter">Centro de custo *</label>
                            <div>

                            <div className="select-busca-btn">
                                    <button
                                        className={loadCostCenter ? "menu-button inputErro" : "menu-button"}
                                        onBlur={ () => (setIsActiveSelectCostcenter(false)) }
                                        onClick={ SelectCostcenter }
                                        ref={focusCostCenter}
                                    >
                                        <span>
                                    {

                                        collaboratorUpdate && collaboratorUpdate.costCenter ?
                                        collaboratorUpdate.costCenter :
                                        collaboratorUpdate && collaboratorUpdate.costCenter === '' ?
                                        <p className='placeholder'>Selecione</p> :
                                        collaborator && collaborator.costCenter ?
                                        collaborator.costCenter :
                                        <p className='placeholder'>Selecione</p>

                                        }

                                        </span>

                                    </button>
                                    <nav className={`menu ${isActiveSelectCostcenter ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height:
                                        costcenter.length === 0 ? 30 : "" ||
                                        costcenter.length === 1 ? 70 : ""  ||
                                        costcenter.length === 2 ? 100 : ""  ||
                                        costcenter.length === 3 ? 100 : 120
                                        }}>
                                            <ul>

                                                <li>
                                                    <button
                                                    value={companies.name}
                                                    name="costCenter"
                                                    onClick={myChangeHandler}
                                                    >{companies.name}</button>
                                                </li>
                                                {
                                                    costcenter
                                                    .map((costcent, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={costcent.name}
                                                            name="costCenter"
                                                            onClick={myChangeHandler}
                                                            >{costcent.name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                {loadCostCenter ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}

                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="manager" className='manger-label'>Gestor</label>
                            <div>

                                <div className="select-busca-btn">

                                    <button
                                        className="menu-button"
                                        onBlur={ () => (setIsActiveSelectBuscaManager(false)) }
                                        onClick={ SelectBuscaManager }
                                    >
                                        <span>
                                        {

                                            collaboratorUpdate && collaboratorUpdate.manager ?
                                            managers
                                            .map((manag, index) => (
                                                collaboratorUpdate.manager ===  manag._id   ?
                                                manag.name
                                                : ""
                                            ))
                                            :
                                            collaborator && collaborator.manager ?

                                            managers
                                            .map((manag, index) => (
                                                collaborator.manager ===  manag._id  ||
                                                collaborator.manager._id  ===  manag._id ?
                                                manag.name
                                                : ""
                                            ))
                                            :
                                            <p className='placeholder'>Selecione</p>
                                        }

                                        </span>

                                    </button>




                                    <nav className={`menu ${isActiveSelectBuscaManager ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height:
                                        managers.length === 0 ? 30 : "" ||
                                        managers.length === 1 ? 70 : ""  ||
                                        managers.length === 2 ? 100 : ""  ||
                                        managers.length === 3 ? 100 : 120
                                        }}>
                                            <ul>
                                                {
                                                managers && managers.length > 0 ?
                                                    managers
                                                    .filter(manager => manager.role === "manager")
                                                    .map((manager, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={manager._id}
                                                            name="manager"
                                                            onClick={myChangeHandler}
                                                            >{manager.name}</button>
                                                        </li>
                                                    ))
                                                :
                                                <>
                                                <li>
                                                    <button> Nenhum gerente cadastrado até o momento!</button>
                                                </li>
                                                </>
                                                }
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                <p className='text-managers'>Defina para quem este colaborador reportará.
                                Este dado será utilizado, por exemplo, para
                                aprovação de férias.</p>
                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="admissionType">Tipo de admissão *</label>
                            <div>



                                <SelectPersonalizado
                                    valorCampo={
                                        collaboratorUpdate && collaboratorUpdate.admissionType ?
                                        collaboratorUpdate.admissionType :
                                        collaboratorUpdate && collaboratorUpdate.admissionType === '' ?
                                        ""  :
                                        collaborator && collaborator.admissionType ?
                                        collaborator.admissionType :
                                        ""
                                    }
                                    loadError={loadAdmissionType}
                                    focusRef={focusAdmissionType}
                                    nameButton={"admissionType"}
                                    myChangeHandler={myChangeHandler}
                                    // option={todosTipos}

                                    optionFil={[
                                        "Admissão",
                                        "Admissão por sucessão",
                                        "Efetivação",
                                        "Transferência com ônus",
                                        "Transferência sem ônus",
                                        "Reintegração"
                                    ]}
                                    typeSelect="busca2"


                                />
                                {loadAdmissionType ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}

                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="role">Tipo de usuário *</label>
                            <div>
                                <div className="select-busca-btn">

                                    <button
                                        className={loadRole ? 'inputErro menu-button' : 'menu-button'}
                                        ref={focusRole}
                                        onBlur={ () => (setIsActiveSelectBuscaRole(false)) }
                                        onClick={ SelectBuscaRole }
                                    >
                                        <span>
                                        {
                                            collaboratorUpdate && collaboratorUpdate.role ?
                                                collaboratorUpdate.role === "user" ? "Usuário" :
                                                collaboratorUpdate.role === "manager" ? "Gestor" :
                                                collaboratorUpdate.role === "admin" ? "Administrador" : ""
                                            :
                                            collaboratorUpdate && collaboratorUpdate.role === '' ?
                                            <p className='placeholder'>Selecione</p>  :
                                            collaborator && collaborator.role ?
                                                collaborator.role === "user" ? "Usuário" :
                                                collaborator.role === "manager" ? "Gestor" :
                                                collaborator.role === "admin" ? "Administrador" : ""
                                            :
                                            <p className='placeholder'>Selecione</p>
                                        }

                                        </span>

                                    </button>

                                    <nav className={`menu ${isActiveSelectBuscaRole ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height:  80
                                        }}>
                                            <ul>
                                                {
                                                [ "user",  "manager", "admin" ]
                                                    .map((roles, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={roles}
                                                            name="role"
                                                            onClick={myChangeHandler}
                                                            >
                                                            {
                                                                    roles === "user" ? "Usuário" :
                                                                    roles === "manager" ? "Gestor" :
                                                                    roles === "admin" ? "Administrador" : ""
                                                                }
                                                            </button>
                                                        </li>
                                                    ))
                                                }
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                    </div>
                                {loadRole ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}

                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="admissionDate">Data de admissão *</label>
                            <div>
                                <input
                                    type="text"
                                    name="admissionDate"
                                    maxLength="10"
                                    autoComplete="off"
                                    ref={focusAdmissionDate}
                                    value={
                                        (collaboratorUpdate &&
                                        collaboratorUpdate.admissionDate !== undefined) ||
                                        collaboratorUpdate.admissionDate === "" ?
                                        collaboratorUpdate.admissionDate        :
                                        (collaborator &&
                                        collaborator.admissionDate !== undefined) ||
                                        collaborator.admissionDate === "" ?
                                        collaborator.admissionDate        :
                                        collaboratorSaved && collaboratorSaved.admissionDate ?
                                        collaboratorSaved.admissionDate         :
                                        collaboratorUpdate.admissionDate
                                    }
                                    onChange={myChangeHandler}
                                    placeholder="DD/MM/AAAA"
                                    className={loadAdmissionDate ? 'inputErro' : ''}
                                />
                                {loadAdmissionDate ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </div>
                        <div className="inforInput">
                            <label htmlFor="dateAdmissionExamination">Data do exame admissional</label>
                            <div>
                                <input
                                    type="text"
                                    name="dateAdmissionExamination"
                                    maxLength="10"
                                    autoComplete="off"
                                    value={
                                        (collaboratorUpdate &&
                                        collaboratorUpdate.dateAdmissionExamination !== undefined) ||
                                        collaboratorUpdate.dateAdmissionExamination === "" ?
                                        collaboratorUpdate.dateAdmissionExamination        :
                                        (collaborator &&
                                        collaborator.dateAdmissionExamination !== undefined) ||
                                        collaborator.dateAdmissionExamination === "" ?
                                        collaborator.dateAdmissionExamination        :
                                        collaboratorSaved && collaboratorSaved.dateAdmissionExamination ?
                                        collaboratorSaved.dateAdmissionExamination         :
                                        collaboratorUpdate.dateAdmissionExamination
                                    }
                                    onChange={myChangeHandler}
                                    placeholder="DD/MM/AAAA"
                                />
                            </div>

                        </div>
                    </div>
                    <div className='form-login'>
                        <div className='infor'>
                            <h5>Informações de acesso ao sistema</h5>
                            <p>O colaborador poderá se conectar ao sistema usando o CPF ou o email de acesso cadastrado abaixo.</p>
                            <p>
                                Ao final do processo de admissão seu colaborador irá receber um email com as instruções para login, caso seu colaborador
                                não possua um email, informe ao mesmo que ele deverá se conectar ao sistema utilizando o CPF e a senha gerada abaixo.
                            </p>
                        </div>
                        <div className="inforInput">
                            <label>Email de acesso</label>
                            <div>
                                <input type="email" name="email" autoComplete="off" onChange={myChangeHandler} value={
                                    collaboratorUpdate.email !== undefined ?
                                    collaboratorUpdate.email
                                    :
                                    collaborator.email       !== undefined ?
                                    collaborator.email
                                    :
                                    ''
                                } placeholder="E-mail" />
                            </div>
                        </div>
                        <div className="inforInput">
                            <label>Cpf *</label>
                            <div>
                                <input ref={focusCpf}  autoComplete="off" type="text" name="cpf" onChange={myChangeHandler} value={
                                    collaboratorUpdate.cpf !== undefined ?
                                    collaboratorUpdate.cpf
                                    :
                                    collaborator.cpf       !== undefined ?
                                    collaborator.cpf
                                    :
                                    ''
                                } placeholder="Cpf" className={loadCpf ? 'inputErro' : ''}/>
                                {loadCpf ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}

                            </div>
                        </div>
                        <div className="inforInput">
                            <label>Senha temporaria *</label>
                            <div>
                                <input ref={focusTemporaryPassword} type="text" name="temporaryPassword"  onChange={myChangeHandler} value={
                                    collaboratorUpdate.temporaryPassword !== undefined ?
                                    collaboratorUpdate.temporaryPassword
                                    :
                                    collaborator.temporaryPassword       !== undefined ?
                                    collaborator.temporaryPassword
                                    :
                                    ''
                                } placeholder="Senha temporaria" className={loadTemporaryPassword ? 'inputErro' : ''}/>
                                <button className='btn-reload-password' onClick={loadgetPassword}><img src={IconReloadPassword} alt="Reload" /></button>
                                {loadTemporaryPassword ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="formBasicInfor">
                <div className="buttons">
                    <div>
                        <button onClick={excludeAdmssion} type="button" className="deleteStepOne">
                            Excluir processo
                        </button>
                    </div>

                    <button type="button" onClick={save}
                    className={
                        loadContinue  ?
                        'saved btnGreen load'
                        :
                        'saved btnGreen'
                        }
                    >Salvar e continuar</button>

                </div>
            </div>

        </>
    );
}
