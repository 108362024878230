import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';





const SubMenuColProfile = ({ id, role, path }) => {


 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusPessoalInforPessoal  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusPessoalEnderecoContato  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusPessolDocumentos = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusPessolFaltaAfastamentos = useRef(null);


 // eslint-disable-next-line react-hooks/rules-of-hooks
 useEffect(() => {
     if( path === '/colaboradores/:id/pessoal') {
        focusPessoalInforPessoal.current.focus();
     } else
     if(path === '/colaboradores/:id/pessoal/enderecos-contatos'){
        focusPessoalEnderecoContato.current.focus();
     }else
     if( path === '/colaboradores/:id/pessoal/documentos'){
        focusPessolDocumentos.current.focus();
     }else
     if( path === '/colaboradores/:id/pessoal/faltas-e-afastamentos'){
        focusPessolFaltaAfastamentos.current.focus();
     }

 }, [path]);




        return (
            <>
                <ul className="SubMenu-perfil">
                    <Scrollbars>
                        <li>
                            <NavLink to={`/colaboradores/${id}/pessoal`} exact activeClassName="active" ref={focusPessoalInforPessoal}><span>Informações pessoais</span></NavLink>
                        </li>
                        <li  >
                            <NavLink to={`/colaboradores/${id}/pessoal/enderecos-contatos`} exact activeClassName="active"><span>Endereços e contatos</span><button ref={focusPessoalEnderecoContato}></button></ NavLink >

                        </li>
                        {
                            (role && role === 'admin') || (role && role === 'master') ?
                            <li>
                                <NavLink to={`/colaboradores/${id}/pessoal/documentos`} exact activeClassName="active"><span>Documentos</span><button ref={focusPessolDocumentos}></button></NavLink>
                            </li>
                            :
                            ''
                        }
                        <li>
                            <NavLink to={`/colaboradores/${id}/pessoal/faltas-e-afastamentos`} exact activeClassName="active" ref={focusPessolFaltaAfastamentos}><span>Faltas e Afastamentos</span></NavLink>
                        </li>

                    </Scrollbars>
                </ul>
            </>
        );
}
export default SubMenuColProfile;
