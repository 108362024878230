import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';




const SubMenuColProfile = ({ id, role, path }) => {
    // console.log(path)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusHitoricoUltimas  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusHitoricoPromo  = useRef(null);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(path === '/colaboradores/:id/historico-ultimas-alteracoes' ) {
            focusHitoricoUltimas.current.focus();
        } else
        if(
            path === '/colaboradores/:id/historico-salario-e-promocoes'
        ){
            focusHitoricoPromo.current.focus();
        }


    }, [path]);
        return (
            <>
                <ul className="SubMenu-perfil">
                    <Scrollbars>
                        {
                            (role && role === 'admin') || (role && role === 'master') ?
                            <>
                            <li>
                                <NavLink to={`/colaboradores/${id}/historico-ultimas-alteracoes`} exact ref={focusHitoricoUltimas}><span>Últimas alterações</span></NavLink>
                            </li>
                            <li>
                                <NavLink to={`/colaboradores/${id}/historico-salario-e-promocoes`} exact ><span>Salário e promoções</span></NavLink>
                            </li>
                            </>
                            :
                            <li>
                                <NavLink to={`/colaboradores/${id}/historico-salario-e-promocoes`} exact className="active"><span>Salário e promoções</span></NavLink>
                            </li>
                        }

                        <button ref={focusHitoricoPromo}></button>
                    </Scrollbars>
                </ul>
            </>
        );
}
export default SubMenuColProfile;
