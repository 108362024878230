import React, {useState, useEffect} from 'react';
import SelectPersonalizado from '../../../components/Reused/selectPersonalizado'
import { Dropdown } from "react-bootstrap";
import iconOrdenation from '../../../assets/images/ordenation.svg';
import iconVerMais from '../../../assets/images/more.svg';
import iconNadaEncontrado from '../../../assets/images/icon-vacancies.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import { formatDateString } from '../../../services/utils';
import api from '../../../services/api'
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

export default function VacancyListTable() {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadTable, setLoadTable] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacancieTable , setVacancieTable] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacancieConfig , setVacancieConfig] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages, setPages ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ statusFilter , setStatusFilter] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter , setNameFilter] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sortByFilter , setSortByFilter] = useState("title");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadLastedVacancy, setLoadLastedVacancy ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadVacancieTable(nameFilter,statusFilter,sortByFilter, page);
        loadSettingsCustomizePageState()
    }, [nameFilter,statusFilter,sortByFilter, page]);

    async function loadSettingsCustomizePageState() {

        await api.get('/vacancie-page-appearance-config')
        .then(response => {      
             setVacancieConfig(response.data)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    async function loadVacancieTable(nameFilter,statusFilter,sortByFilter, page) {
        setLoadTable(true)
        setLoadLastedVacancy(true)
        let limit     = 20;
        await api.get(`/vacancie/?name=${nameFilter}&status=${statusFilter}&sortBy=${sortByFilter}&page=${page}&limit=${limit}`)
        .then(response => {


            setVacancieTable(response.data.vacancieSearch);
            setPage(parseInt(response.data.page));
            setPages(parseInt(response.data.pages));
            setLoadTable(false)
            setLoadLastedVacancy(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filter, setFilter] = useState(false);
    
   
    const listaFilterVagas = [
        "Todas as vagas",
        "Publicadas",
        "Rascunhos"
    ]

    async function paginationPage(page) {

            setPage(parseInt(page));
         loadVacancieTable(nameFilter,statusFilter,sortByFilter, page);
    }

    async function prevPage() {

            if (page === 0) return;
            const pageNumber = page - 1;
            setPage(pageNumber);
            // setLoadLastedCandidate(true);
            loadVacancieTable(nameFilter,statusFilter,sortByFilter, pageNumber);

    }

    async function nextPage() {

            if (page === pages) return;
            const pageNumber = page + 1;
            setPage(pageNumber);
            loadVacancieTable(nameFilter,statusFilter, sortByFilter, pageNumber);
        

    }
      // PAGINAÇÃO

      let numberPaginate = []
      

      if(pages > 20 && pages <= 40) {

        let pagesDivisor = (pages/3)

        let paginationFirts = Math.trunc(pagesDivisor);
        let paginationDot   = Math.trunc(pagesDivisor*2);
        let paginationLast  = Math.trunc(pagesDivisor*3);

        for(let i=0; i<=paginationFirts; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button
                    className={i === page ? "secao" : ""}
                    onClick={
                        (event) => paginationPage(i)
                    }>
                    {i+1}
                    </button>
                </li>
            )

        }
        for(let i=0; i<=3; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button>
                    .
                    </button>
                </li>
            )

        }
        for(let i=paginationDot; i<=paginationLast; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button
                    className={i === page ? "secao" : ""}
                    onClick={
                        (event) => paginationPage(i)
                    }>
                    {i+1}
                    </button>
                </li>
            )

        }

    }
    else
    if(pages > 40) {

        let pagesDivisor = (pages/4)

        let paginationFirts = Math.trunc(pagesDivisor);

        paginationFirts  = Math.trunc(paginationFirts/2);
        let paginationLast2   = Math.trunc(pages-paginationFirts);

        for(let i=0; i<=paginationFirts; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button
                    className={i === page ? "secao" : ""}
                    onClick={
                        (event) => paginationPage(i)
                    }>
                    {i+1}
                    </button>
                </li>
            )

        }
        for(let i=0; i<=3; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button>
                    .
                    </button>
                </li>
            )

        }
        for(let i=paginationLast2; i<=pages; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button
                    className={i === page ? "secao" : ""}
                    onClick={
                        (event) => paginationPage(i)
                    }>
                    {i+1}
                    </button>
                </li>
            )

        }

    }
    else {
        if(pages === 0) {

        }
        else {
            for(let i=0; i<=pages; i++){
                numberPaginate.push(
                    <li className="number" key={i}>
                        <button
                        className={i === page ? "secao" : ""}
                        onClick={
                            (event) => paginationPage(i)
                        }>
                        {i+1}
                        </button>
                    </li>
                )
            }
        }
    }

    const myChangeHandlerFilter = event =>{
        let val = event.target.value;
        let nam = event.target.name;
        setFilter(true)
        if(nam === "tipoVagas"){
            if(val === "Todas as vagas"){
                setStatusFilter("")
                setPage(0)
            }else if(val === "Publicadas"){
                setStatusFilter("Publicado")
                setPage(0)
            }else if(val === "Rascunhos"){
                setStatusFilter("Rascunho")
                setPage(0)
            }
        } else if (nam === "busca"){
            setNameFilter(val)
            setPage(0)
        }
    }
    return (
        <>

            <div className='vacancy-list-table'>
                <div className='title-filter'>
                    <h2 className='title-box'>Todas as vagas</h2>
                    <div className="filter">
                        <div className='checks'>
                            <p>Mostrando</p> 
                            <div className='list'>
                            <SelectPersonalizado 
                                valorCampo={statusFilter !== "" ? 
                                    statusFilter === "Publicado" ? "Publicadas" :""||
                                    statusFilter === "Rascunho" ? "Rascunhos" :""
                                : "Todas as vagas"}
                                typeSelect={"btn"}
                                nameButton={"tipoVagas"}
                                myChangeHandler={myChangeHandlerFilter}
                                option={listaFilterVagas}
                            />
                            </div>
                        </div>
                        <input type="text" className='busca' placeholder='Filtrar vagas' name="busca" onChange={myChangeHandlerFilter}/>
                    </div>
                </div>
                
                {/* icon-vacancies.svg */}

                <div className='vacancy-table'>

                    <div className='head-table grid-table '>
                        <div className='name'>Vaga</div>
                        <div className='data'>Cadastrada em</div>
                        <div className='quantidade'>Candidaturas recebidas</div>
                        <div className='status'>Status</div>
                        <div className='view-more'>
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconOrdenation} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button type="button" onClick={ ()=> setSortByFilter('mais_recente')}>
                                    Cadastradas por último
                                    </button>
                                    <button type="button" onClick={ ()=> setSortByFilter('mais_antigo')}>
                                    Cadastradas primeiro
                                    </button>
                                    <button type="button" onClick={ ()=> setSortByFilter('first_more_candidates')}>
                                    Mais candidaturas primeiro
                                    </button>
                                    <button type="button"  onClick={ ()=> setSortByFilter('title')}>
                                    Ordem alfabética
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                    <h4 className='infor-filtro'>
                    {
                        sortByFilter === 'mais_recente'  ?
                        <> Mais recentes { statusFilter && statusFilter !== "" ? `de ${ statusFilter === "Publicado" ? "publicadas" :""|| statusFilter === "Rascunho" ? "rascunhos" :""  }` :""}</>
                        :
                        sortByFilter === 'mais_antigo'  ?
                        <> Mais antigos { statusFilter && statusFilter !== "" ? `de ${ statusFilter === "Publicado" ? "publicadas" :""|| statusFilter === "Rascunho" ? "rascunhos" :""  }` :""}</>
                        :
                        sortByFilter === 'first_more_candidates'  ?
                        <>  Mais candidaturas primeiro { statusFilter && statusFilter !== "" ? `de ${ statusFilter === "Publicado" ? "publicadas" :""|| statusFilter === "Rascunho" ? "rascunhos" :""  }` :""}</>
                        :""
                    }
                    </h4>


                    <div className='body-table'>
                    {loadTable ?
                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                    :
                    vacancieTable?.length !== 0 ?

                        vacancieTable
                        .map((vacancieItem, index) => (
                            <>
                                <div className='grid-table vaga'>
                                    <NavLink to={
                                        vacancieConfig?.status === "active" && vacancieItem?.status === "Publicado"?
                                            `/lp/${vacancieConfig?.subdomain}/${vacancieItem._id}`
                                            :
                                            `/recrutamento/editar-vaga/${vacancieItem._id}`
                                        } className="link-infor">
                                        <div className='name'>
                                            {vacancieItem && vacancieItem.title}
                                        </div>
                                        <div className='data'>
                                        {formatDateString(vacancieItem && vacancieItem?.createdAt)}
                                        </div>
                                        <div className='quantidade'>
                                        {vacancieItem && vacancieItem.candidate.length}
                                        </div>
                                        <div className='status'>
                                        {
                                        vacancieItem && vacancieItem.status === "Publicado"?                           
                                            <span className='publicada'>Publicada</span>:
                                            <span className='rascunho'>Rascunho</span>
                                        }
                                            
                                        </div>
                                    </NavLink>
                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    vacancieConfig?.status === "active" && vacancieItem?.status === "Publicado"?
                                                    <NavLink to={`/lp/${vacancieConfig?.subdomain}/${vacancieItem._id}`}>
                                                        Visualizar
                                                    </NavLink>:""

                                                }
                                                <NavLink to={`/recrutamento/editar-vaga/${vacancieItem._id}`}>
                                                Editar
                                                </NavLink>
                                                <button type="button"  className='red'>Excluir</button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </div>
                            </>
                        )) 

                        :
                        <div className='nenhuma-vaga'>
                            <img src={iconNadaEncontrado} alt="nada encontrado" />
                            <p>Nenhuma vaga {filter === true?"encontrada.":"cadastrada." }<br/>Cadastre e anuncie vagas.</p>
                            <NavLink to="/recrutamento/cadastrar-vaga"className='btnGreen more'> Cadastrar nova vaga </NavLink>
                        </div>
                    }





                        {/* <div className='grid-table vaga'>
                            <NavLink to="/" className="link-infor">
                                <div className='name'>
                                    Frontend Pleno
                                </div>
                                <div className='data'>
                                    23 Agosto 2022
                                </div>
                                <div className='quantidade'>
                                    42
                                </div>
                                <div className='status'>
                                       <span className='publicada'>Publicada</span>
                                </div>
                            </NavLink>
                            <div className='view-more'>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NavLink to="/">
                                            Visualizar
                                        </NavLink>
                                        <button type="button" >
                                        Editar
                                        </button>
                                        <button type="button"  className='red'>Excluir</button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div> */}
                        {/* <div className='grid-table vaga'>
                         
                                <div className='name'>
                                    <NavLink to="/">Backend Sênior - 100% remoto</NavLink> 
                                </div>
                                <div className='data'>
                                    <NavLink to="/">23 Agosto 2022</NavLink>
                                </div>
                                <div className='quantidade'>
                                    <NavLink to="/">42</NavLink>
                                </div>
                                <div className='status'>
                                       <NavLink to="/"><span className='rascunho'>rascunho</span></NavLink>
                                </div>
                                <div className='visualizar'>
                                    <a  target="_blank" href="/">Visualizar vaga</a>
                                </div>
                            <div className='view-more'>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" >
                                        Editar
                                        </button>
                                        <button type="button"  className='red'>Excluir</button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div> */}
                    </div>


                    

                {
                    loadLastedVacancy ? "":
                    <div className='paginacaoG'>
                        <ul>
                            {numberPaginate}
                            {
                                pages && pages > 0 ?
                                <>
                                <li className="page-anterior">
                                    <button style={(page === 0) ? { cursor: "not-allowed" } : {}}  disabled={page === 0} onClick={(event) => prevPage(event)}>anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(page === pages) ? { cursor: "not-allowed" } : {}}  disabled={page === pages} onClick={(event) => nextPage(event)}>próxima</button>
                                </li>
                                </>
                                :
                                ''
                            }
                        </ul>
                    </div>
                }

                </div>

            </div>
        </>
    );
}

