import React, {
    useState,
    useEffect
} from "react"
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import SubHeaderCustomizePage from '../../components/RecruitmentAndSelection/subHeaderCustomizePage';
import TextEditor from '../../components/Reused/TextEditor'
import { toast } from 'react-toastify';
import api from '../../services/api'
import swal from 'sweetalert';

export default function PrivacyPolicyCustomizePage(props) {
    const { path } = props.match;


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ PrivacyPolicy , setPrivacyPolicy] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ PrivacyPolicyState , setPrivacyPolicyState] = useState("");


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadPrivacyPolicyState()
    }, []);
    async function loadPrivacyPolicyState() {

        await api.get('/vacancie-page-appearance-privacy-policy')
        .then(response => {
            if(response.data !== null){
                setPrivacyPolicyState(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }


    const myChangeHandler = event =>{

        let nam = event.target.name;
        let val = event.target.value;
        setPrivacyPolicy({ ...PrivacyPolicy, [nam]: val})
    }

    /////////////////////////////////////////////
    ////////////// Editor de testo //////////////
    ///////////////////////////////////////////

    const myChangeHandlerDescription = (e, editor ) => {
        let description = editor.getData();
        setPrivacyPolicy({ ...PrivacyPolicy,  "text": description})
    }
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadBtnSave, setLoadBtnSave] = useState(false);
    async function save() {
        setLoadBtnSave(true)
        if(PrivacyPolicyState === "") {

            await api.post("/vacancie-page-appearance-privacy-policy", PrivacyPolicy)
            .then(response => {
                setLoadBtnSave(false)
                window.location.href =  `/recrutamento/configuracoes-da-pagina`;
    
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        } else {

            await api.put(`/vacancie-page-appearance-privacy-policy/${PrivacyPolicyState._id}`, PrivacyPolicy)
            .then(response => {
                setLoadBtnSave(false)
                window.location.href =  `/recrutamento/configuracoes-da-pagina`;
    
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }

    }
    return (
        <>
        <Layout path={path}> 
            <Header  path={path}/>

            <SubHeaderCustomizePage path={path}/>
            <div className="page-customizePage-privacyCustomize">
                    <h2 className="titulo-pg">Política de privacidade</h2>
                    <p className="text-infor-pg">
                        Os candidatos terão que aceitar essas políticas ao aplicarem para uma vaga.
                        A política é de responsabilidade da empresa contratante.
                    </p>
                    <div className="editor-texto">
                        <TextEditor 
                            myChangeHandlerTitulo={myChangeHandler}
                            myChangeHandlerEditor={myChangeHandlerDescription} 

                            nameTitle={"title"}
                            placeholderTitle={"Título"}
                            placeholderEditor={"Digite a descrição da vaga…"}


                            titleValor={
                                PrivacyPolicy && PrivacyPolicy.title ?
                                PrivacyPolicy.title 
                                :
                                PrivacyPolicyState && PrivacyPolicyState.title ?
                                PrivacyPolicyState.title
                                 :
                                ''
                            }
                            descritionValor={
                                PrivacyPolicy && PrivacyPolicy.text ?
                                PrivacyPolicy.text 
                                :
                                PrivacyPolicyState && PrivacyPolicyState.text ?
                                PrivacyPolicyState.text
                                 :
                                ''

                            }
                        />

                    </div>
                    <button className={loadBtnSave ?  "btnGreen btn-salvar load" :  "btnGreen btn-salvar" }  onClick={save}>Salvar</button>
                    
            </div>

        </Layout>
        </>
    )
}