import React, { useState, useEffect, useRef } from 'react';
import "./styles.scss";
import Layout from '../../../../components/Layouts/default';
import HeaderNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/header';
import HeaderEtapasNovaAdmissao from '../../../../components/Administrativo/Colaboradores/novaAdmissao/headerAdmissionSteps';
import EnviarContabilidade from '../../../../components/Administrativo/Colaboradores/novaAdmissao/EnviarContabilidade';
import Checklist from '../../../../components/Administrativo/Colaboradores/novaAdmissao/checklist';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import { decodeToken } from '../../../../services/auth'

export default function Dashboard(props) {

    const [stepOne]   = useState(true)
    const [stepTwo]   = useState(true)
    const [stepThree] = useState(true)
    const [stepFour]  = useState(true)
    const { id }      = props.match.params;
    const { path }    = props.match;


   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [idItem, setidItem] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ collaborator, setCollaborator ] = useState([]);

    let ufReservationCard_   = collaborator.ufReservationCard;
    let cityReservationCard_ = collaborator.cityReservationCard;

    ////////////// NOTIFICATIONS ////////////
    const { history } = props;
    const { user } = decodeToken();

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ registeredEmergencyContact, setRegisteredEmergencyContact ] = useState([]);

   // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {

        if(id && id !== undefined && id !== "undefined") {

            loademergencycontacts(id);
            loadtaskcheckeddefault(id);
            loadcollaborator(id)
            loadbenefitsnewdiferent(id)
            loadfilessavedIdentification(id)
            loadfilessavedLocation(id)
            loadschooling(id)
            loaddependents(id)
            loadfilescollaborator(id)

        }
        if (ufReservationCard_ && ufReservationCard_ !== undefined && ufReservationCard_ !== "undefined") {
            loadEstado(ufReservationCard_)
        }
        if(cityReservationCard_ && cityReservationCard_ !== undefined && cityReservationCard_ !== "undefined") {
            loadCity_(cityReservationCard_)
        }

        loadCity()
        loadTaskCompany()
   }, [id, ufReservationCard_,cityReservationCard_]);


   const focusEmailAccouting   = useRef(null);

   async function loadcollaborator(id) {

       await api.get(`/collaborator/${id}`)
       .then(response => {

       setCollaborator(response.data);

       }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

   }

   //eslint-disable-next-line react-hooks/rules-of-hooks
   const [ collaboratorBeneftis, setCollaboratorBeneftis] = useState([]);

   async function loadbenefitsnewdiferent(id) {
        await api.get(`/beneftis-new-diferent/?collaborator=${id}&status=Ativo`)
        .then(response => {
            
            setCollaboratorBeneftis(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

   async function loademergencycontacts(id) {

        await api.get(`/collaborator-emergency-contact/${id}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredEmergencyContact(response.data);
            }
            else {
                setRegisteredEmergencyContact([]);
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
   }

   const [tasksDefault, setTasksDefault] = useState([]);

    const [tasksCompany, setTasksCompany] = useState([]);
    const [loadTasksChecklist, setLoadTasksChecklist] = useState(false); 

    async function loadtaskcheckeddefault(id) {
        setLoadTasksChecklist(true)
        await api.get(`/checklist-admission/${id}`)
        .then(response => {
            setLoadTasksChecklist(false)
            setTasksDefault(response.data);
            setidItem('')
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadTaskCompany() {
        setLoadTasksChecklist(true)

        await api.get('/checklist-admission/')
        .then(response => {
            setLoadTasksChecklist(false)
            setTasksCompany(response.data[0]?.item);
            setidItem('')
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function addNewTaskSave(event) {
        setidItem(String(event.idItem));
        if(event.status === false) {

            const checklistCollaborator =
            await api.get(`/checklist-collaborator-admission/${id}`)

            if(checklistCollaborator?.data !== null) {

                let idCheckColab = checklistCollaborator.data._id;

                await api.put(`checklist-collaborator-admission/${idCheckColab}`, { status: true, item: event.idItem, path: path })
                .then(response => {

                    loadtaskcheckeddefault(id);

                }).catch(error => {})

            } else {

                let collaborator = id;
                let item         = [];

                item.push({
                    _id: event.idItem
                })

                await api.post('/checklist-collaborator-admission/', { collaborator, item, path: path })
                .then(response => {

                    loadtaskcheckeddefault(id);

                }).catch(error => {})

            }
        }
        else {

            await api.put(`checklist-collaborator-admission/${event.idChecklist}`, { status: false, item: event.idItem, path: path })
            .then(response => {

                loadtaskcheckeddefault(id);

            }).catch(error => {})

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedIdentification, setFilesSavedIdentification] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedLocation, setFilesSavedLocation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredSchooling, setRegisteredSchooling ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredDependents, setRegisteredDependents ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filesCollaborator, setFilesCollaborator ] = useState([]);

    //  ufReservationCard
    const [ufReservationCard, setUfReservationCard] = useState([]);

    //  cityReservationCardString
    const [cityReservationCardString, setCityReservationCardString] = useState([]);

    //setLoadErrorSendEmailToAccounting
    const [loadErrorSendEmailToAccounting, setLoadErrorSendEmailToAccounting] = useState(false);

    async function loadfilessavedIdentification(id) {

        await api.get(`/collaborator-file/?id=${id}&category=identification`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setFilesSavedIdentification(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadfilessavedLocation(id) {

        await api.get(`/collaborator-file/?id=${id}&category=location`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setFilesSavedLocation(response.data);
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadschooling(id) {

        await api.get(`/collaborator-schooling/${id}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredSchooling(response.data);

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loaddependents(id) {

        await api.get(`/collaborator-dependent/${id}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredDependents(response.data);

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }



    async function loadfilescollaborator(id) {

        await api.get(`/collaborator-file/?id=${id}&category=docCPF&category=docRG&category=docCNH&category=docCT&category=docTE&category=docCR`)
        .then(response => {
            setFilesCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadEstado(ufReservationCard_){

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufReservationCard_}`)
        .then(response => {

            setUfReservationCard(response.data.nome)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadCity() {

        setCityReservationCardString('São paulo')

    }

    async function loadCity_(cityReservationCard_) {
        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cityReservationCard_}`)
        .then(response => {

            setCityReservationCardString(response.data[0].nome)

        }).catch(error => { });

    }

     //accountingInformation
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [accountingInformation, setAccountingInformation] = useState([]);

    //myChangeHandlerCollaboratorRequestsSendToAccounting
    async function myChangeHandlerCollaboratorRequestsSendToAccounting(e) {


        let nam = e.target.name;
        let val = e.target.value;

        if(nam === 'emailAccountig') {

            let nameAccountig  = e.target.value.split('&')[1]
            let emailAccouting = e.target.value.split('&')[0]

            if(val === 'Selecione') {
                setLoadErrorSendEmailToAccounting(true)
            }
            else {
                setLoadErrorSendEmailToAccounting(false)
                setAccountingInformation({ ...accountingInformation, nameAccountig, emailAccouting })
            }

        }
        else {

            setLoadErrorSendEmailToAccounting(false)
            setAccountingInformation({ ...accountingInformation, [nam]: val})

        }



    }

    const [loadSaveAndSendFinaly, setLoadSaveAndSendFinaly] = useState(false);

    const [loadSaveAndFinaly, setLoadSaveAndFinaly] = useState(false);

    async function sendAccounting(event) {

        setLoadSaveAndSendFinaly(true)

        event.preventDefault();

        let nameAccountig                 = accountingInformation.nameAccountig;
        let emailAccouting                = accountingInformation.emailAccouting;
        let accountingComments            = accountingInformation.accountingComments;

        let nameCompany                   = collaborator.companyId.name;
        let name                          = collaborator.name;

        let email                         = collaborator?.email;

        let sector                        = collaborator.sector;
        let occupation                    = collaborator.occupation;
        let salary                        = collaborator.salary;
        let costCenter                    = collaborator.costCenter;
        let admissionDate                 = collaborator.admissionDate;

        let dateOfBirth                   = collaborator.dateOfBirth;
        let gender                        = collaborator.gender;
        let fatherName                    = collaborator.fatherName;
        let motherName                    = collaborator.motherName;
        let maritalStatus                 = collaborator.maritalStatus;
        let nationality                   = collaborator.nationality;
        let otherNationality              = collaborator.otherNationality;
        let ethnicity                     = collaborator.ethnicity;
        let bornStateString               = collaborator.bornStateString;
        let bornCityString                = collaborator.bornCityString;

        let phone                         = collaborator.phone;
        let cellphone                     = collaborator.cellphone;
        let zipcode                       = collaborator.zipcode;
        let address                       = collaborator.address;
        let addressNeighborhood           = collaborator.addressNeighborhood;
        let addressCity                   = collaborator.addressCity;
        let addressState                  = collaborator.addressState;
        let addressNumber                 = collaborator.addressNumber;
        let addressComplement             = collaborator.addressComplement;

        let cpf                           = collaborator.cpf;
        let foreign                       = collaborator.foreign;

        let rg                            = collaborator.rg;
        let rgExpeditionDate              = collaborator.rgExpeditionDate;
        let rgOrgan                       = collaborator.rgOrgan;
        let ufOrgan                       = collaborator.ufOrgan;

        let cnh                           = collaborator.cnh;
        let cnhCategory                   = collaborator.cnhCategory;
        let cnhExpeditionDate             = collaborator.cnhExpeditionDate;
        let cnhExpirationDate             = collaborator.cnhExpirationDate;
        let ufCNH                         = collaborator.ufCNH;

        let pisSeries                     = collaborator.pisSeries;
        let walletNumber                  = collaborator.walletNumber;
        let serialNumber                  = collaborator.serialNumber;
        let walletUF                      = collaborator.walletUF;
        let issueDate                     = collaborator.issueDate;
        let pisNumber                     = collaborator.pisNumber;

        let reservationCardNumber         = collaborator.reservationCardNumber;
        let reservationCardSeries         = collaborator.reservationCardSeries;
        let reservationCardCategory       = collaborator.reservationCardCategory;
        let reservationCardExpeditionDate = collaborator.reservationCardExpeditionDate;

        let voterRegistration             = collaborator.voterRegistration;
        let electoralZone                 = collaborator.electoralZone;
        let pollingStation                = collaborator.pollingStation;

        let InformationProccessAdmissionAccounting = [{

            nameAccountig                ,
            emailAccouting               ,
            accountingComments           ,
            nameCompany                  ,
            name                         ,
            email                        ,
            sector                       ,
            occupation                   ,
            salary                       ,
            costCenter                   ,
            admissionDate                ,
            dateOfBirth                  ,

            gender                       ,
            fatherName                   ,
            motherName                   ,
            maritalStatus                ,
            nationality                  ,
            otherNationality             ,
            ethnicity                    ,
            bornStateString              ,
            bornCityString               ,
            filesSavedIdentification     ,

            phone                        ,
            cellphone                    ,
            zipcode                      ,
            address                      ,
            addressNeighborhood          ,
            addressCity                  ,
            addressState                 ,
            addressNumber                ,
            addressComplement            ,
            filesSavedLocation           ,

            registeredSchooling          ,
            registeredDependents         ,
            registeredEmergencyContact   ,

            cpf                          ,
            foreign                      ,

            rg                           ,
            rgExpeditionDate             ,
            rgOrgan                      ,
            ufOrgan                      ,

            cnh                          ,
            cnhCategory                  ,
            cnhExpeditionDate            ,
            cnhExpirationDate            ,
            ufCNH                        ,

            pisSeries                    ,
            walletNumber                 ,
            serialNumber                 ,
            walletUF                     ,
            issueDate                    ,
            pisNumber                    ,

            reservationCardNumber        ,
            reservationCardSeries        ,
            reservationCardCategory      ,
            reservationCardExpeditionDate,
            ufReservationCard            ,

            cityReservationCardString    ,

            voterRegistration            ,
            electoralZone                ,
            pollingStation               ,
            filesCollaborator            ,
            collaboratorBeneftis         ,


        }]

        if(
            accountingInformation?.emailAccouting               &&
            accountingInformation?.emailAccouting !== undefined
        ) {

            async function informationAdmissionProcessToAccounting() {

                if(
                    accountingInformation.emailAccouting               &&
                    accountingInformation.emailAccouting !== undefined
                ) {

                    setLoadErrorSendEmailToAccounting(false);

                    await api.post('/information-admission-process-to-accounting/', InformationProccessAdmissionAccounting)
                    .then(response => {

                        // noChooseAccountig()
                        setLoadSaveAndSendFinaly(false)
                        swal({
                            icon: "success", title: "Sucesso!",
                            text: "Informações enviadas para a contabilidade com sucesso!"
                        });

                    }).catch(error => {

                        // noChooseAccountig()
                        setLoadSaveAndSendFinaly(false);
                        swal({
                            icon: "error", title: "Erro!", text: error?.response?.data?.message ?
                            error?.response?.data?.message
                            :
                            'Não foi possível enviar as informações para a contabilidade, por favor, tente mais tarde!'
                        });

                    });

                }
                else {
                    setLoadErrorSendEmailToAccounting(true)
                    setLoadSaveAndSendFinaly(false)
                }



            }
            informationAdmissionProcessToAccounting()


        }
        else {

            // setLoadSaveAndSendFinaly(false)
            if(
                accountingInformation.emailAccouting === undefined
            ) {
                setLoadErrorSendEmailToAccounting(true);
                focusEmailAccouting.current.focus();
                setLoadSaveAndSendFinaly(false)
            }

        }



    }

    async function saveandfinaly(event) {
        event.preventDefault();

        let countStatusCheckList = 0

        if(tasksDefault.length !== 0) {

            for(let td=0; td < tasksDefault.length; td++){

                // tasksDefault.length[td]

                let statusCheckList = tasksDefault[td].status

                if(statusCheckList === false) {
                    countStatusCheckList = countStatusCheckList +1
                }

            }

        }


        if(countStatusCheckList > 0) {

            swal({
                title: "Atenção",
                text: "Alguns itens do checklist deste processo de admissão não foram verificados, gostaria de concluir o processo mesmo assim?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    formData.append('stepOne', 'true');
                    formData.append('stepTwo', 'true');
                    formData.append('stepThree', 'true');
                    formData.append('stepFour', 'true');
                    formData.append('status', 'Ativo');
                    formData.append('path', path);

                    setLoadSaveAndFinaly(true);
                    await api.put(`/collaborator/${id}`, formData, config)
                    .then(async (response)=> {

                        if(
                            response.data.status === 'Ativo'
                        ) {

                            async function loadStoreCollaboratorVestingPeriod() {

                                let nameCollaborator  = response.data.name;
                                let sectorCollaborator = '';

                                if(response?.data?.sector !== undefined) {
                                    sectorCollaborator = response.data.sector;
                                }
                                else {
                                    sectorCollaborator = '-';
                                }

                                let admissionDate     = response.data.admissionDate;

                                try {
                                    // Cria todos os períodos aquisitivos => storeCollaboratorVestingPeriod
                                    await api.post('/collaborator-vesting-period/', {
                                        companyId: response.data.companyId,
                                        collaborator: response.data._id,
                                        nameCollaborator,
                                        sectorCollaborator,
                                        admissionDate,
                                    })
                                    setLoadSaveAndFinaly(false);
                                }catch(error) {

                                    swal({
                                        icon: "error", title: "Erro!",
                                        text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema."
                                    });
                                }
                            }
                            await loadStoreCollaboratorVestingPeriod()

                            setTimeout(async function(){

                                async function loadUpdateVestingPeriodFirstBalance() {

                                    try {
                                        // => Atualiza todos os saldos dos períodos aquisitivos
                                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                                            companyId   : collaborator.companyId._id,
                                            collaborator: collaborator._id
                                        })

                                    }catch(error) {
                                        swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });
                                    }

                                    let personalEmail = '';
                                    let email         = '';

                                    if(
                                        collaborator?.email
                                        &&
                                        collaborator?.personalEmail === undefined
                                    ) {

                                        email         = collaborator?.email
                                        personalEmail = collaborator?.email;

                                    }
                                    else
                                    if(
                                        collaborator?.email === undefined
                                        &&
                                        collaborator?.personalEmail
                                    ) {

                                        email         = collaborator?.personalEmail
                                        personalEmail = collaborator?.personalEmail;

                                    }
                                    else {
                                        email         = collaborator?.email
                                        personalEmail = collaborator?.personalEmail;
                                    }

                                    if(
                                        email         !== undefined &&
                                        personalEmail !== undefined
                                    ) {

                                        // let email         = collaborator?.email;

                                        async function releaseSystemAccessToTheCollaborator() {

                                            const formData = new FormData();
                                            const config = {
                                                headers: {
                                                    'content-type': 'multipart/form-data'
                                                }
                                            }

                                            formData.append('freeAccess', true);
                                            // formData.append('email', email);
                                            formData.append('path', path);
                                            // setLoadButtomFreeAccess(true)
                                            await api.put(`/collaborator/${id}`, formData, config)
                                            .then(response => {

                                                window.location.href = `/colaboradores/nova-admissao/finalizado/${id}`;
                                                swal({ icon: "success", title: "Sucesso!", text: "Acesso liberado com sucesso! Vá até a página de ativos para visualizar este colaborador!" });

                                            }).catch(error => {

                                                setLoadSaveAndFinaly(false)
                                                // setVisibleAccountingProcedures(false);

                                                swal({
                                                    icon: "error", title: "Erro!", text: error?.response?.data?.message ?
                                                    error?.response?.data?.message
                                                    :
                                                    'Colaborador concluído com sucesso! Porem suas informações não foram enviadas em seu e-mail pessoal, você deve ir no cadastro do colaborador, na área “Dados de acesso” e informar ao colaborador que ele pode se conectar ao sistema utilizando o seu e-mail ou CPF e a senha temporária cadastrada!'
                                                });

                                            });

                                        }
                                        releaseSystemAccessToTheCollaborator()

                                    } else {

                                        setTimeout(function(){

                                            setLoadSaveAndFinaly(false)
                                            // setVisible(false);
                                            window.location.href = `/colaboradores/nova-admissao/finalizado/${id}`;
                                            swal({ icon: "success", title: "Sucesso!", text: "Acesso liberado com sucesso! Vá até a página de ativos para visualizar este colaborador!" });

                                        }, 3000)

                                    }

                                }
                                await loadUpdateVestingPeriodFirstBalance()

                            }, 500);


                        }




                    }).catch(error => {
                        swal({
                            icon: "error", title: "Erro!",
                            text: error?.response?.data?.message ?
                            error?.response?.data?.message
                            :
                            'Não foi possível concluir o colaborador, por favor, entre em contato com o administrador do sistema.'
                        });
                    });


                }
            })

        }
        else {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            formData.append('stepOne', 'true');
            formData.append('stepTwo', 'true');
            formData.append('stepThree', 'true');
            formData.append('stepFour', 'true');
            formData.append('status', 'Ativo');
            formData.append('path', path);

            setLoadSaveAndFinaly(true);
            await api.put(`/collaborator/${id}`, formData, config)
            .then(async (response) => {

                if(
                    response.data.status === 'Ativo'
                ) {

                    async function loadStoreCollaboratorVestingPeriod() {

                        let nameCollaborator  = response.data.name;
                        let sectorCollaborator = '';

                        if(response?.data?.sector !== undefined) {
                            sectorCollaborator = response.data.sector;
                        }
                        else {
                            sectorCollaborator = '-';
                        }

                        let admissionDate     = response.data.admissionDate;

                        try{
                            // Cria todos os períodos aquisitivos => storeCollaboratorVestingPeriod
                            await api.post('/collaborator-vesting-period/', {
                                companyId: response.data.companyId,
                                collaborator: response.data._id,
                                nameCollaborator,
                                sectorCollaborator,
                                admissionDate,
                            })

                            setLoadSaveAndFinaly(false);
                        }catch(error){

                            swal({
                                icon: "error", title: "Erro!",
                                text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema."
                            });
                        }
                    }
                    await loadStoreCollaboratorVestingPeriod()

                    await setTimeout(async function(){

                        async function loadUpdateVestingPeriodFirstBalance() {

                            try {
                                // => Atualiza todos os saldos dos períodos aquisitivos
                                await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                                    companyId   : collaborator.companyId._id,
                                    collaborator: collaborator._id
                                })
                            }catch(error){

                                swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });
                            }

                            let personalEmail = '';
                            let email         = '';

                            if(
                                collaborator?.email
                                &&
                                collaborator?.personalEmail === undefined
                            ) {

                                email         = collaborator?.email
                                personalEmail = collaborator?.email;

                            }
                            else
                            if(
                                collaborator?.email === undefined
                                &&
                                collaborator?.personalEmail
                            ) {

                                email         = collaborator?.personalEmail
                                personalEmail = collaborator?.personalEmail;

                            }
                            else {
                                email         = collaborator?.email
                                personalEmail = collaborator?.personalEmail;
                            }

                            if(
                                email         !== undefined &&
                                personalEmail !== undefined
                            ) {

                                // let email         = collaborator?.email;

                                async function releaseSystemAccessToTheCollaborator() {

                                    const formData = new FormData();
                                    const config = {
                                        headers: {
                                            'content-type': 'multipart/form-data'
                                        }
                                    }

                                    formData.append('freeAccess', true);
                                    // formData.append('email', email);
                                    formData.append('path', path);
                                    // setLoadButtomFreeAccess(true)
                                    await api.put(`/collaborator/${id}`, formData, config)
                                    .then(response => {

                                        window.location.href = `/colaboradores/nova-admissao/finalizado/${id}`;
                                        swal({ icon: "success", title: "Sucesso!", text: "Acesso liberado com sucesso! Vá até a página de ativos para visualizar este colaborador!" });

                                    }).catch(error => {

                                        setLoadSaveAndFinaly(false)
                                        // setVisibleAccountingProcedures(false);

                                        swal({
                                            icon: "error", title: "Erro!", text: error?.response?.data?.message ?
                                            error?.response?.data?.message
                                            :
                                            'Colaborador concluído com sucesso! Porem suas informações não foram enviadas em seu e-mail pessoal, você deve ir no cadastro do colaborador, na área “Dados de acesso” e informar ao colaborador que ele pode se conectar ao sistema utilizando o seu e-mail ou CPF e a senha temporária cadastrada!'
                                        });

                                    });

                                }
                                releaseSystemAccessToTheCollaborator()

                            } else {

                                setTimeout(function(){

                                    setLoadSaveAndFinaly(false)
                                    // setVisible(false);
                                    window.location.href = `/colaboradores/nova-admissao/finalizado/${id}`;
                                    swal({ icon: "success", title: "Sucesso!", text: "Acesso liberado com sucesso! Vá até a página de ativos para visualizar este colaborador!" });

                                }, 3000)

                            }

                        }
                        await loadUpdateVestingPeriodFirstBalance()

                    }, 500);


                }




            }).catch(error => {
                swal({
                    icon: "error", title: "Erro!",
                    text: error?.response?.data?.message ?
                    error?.response?.data?.message
                    :
                    'Não foi possível concluir o colaborador, por favor, entre em contato com o administrador do sistema.'
                });
            });

        }



    }



    return (
        <>
            <Layout
            path={props.match.path}
            >
                <HeaderNovaAdmissao
                    history={history}
                    user={user}
                    collaborator={collaborator}
                    setCollaborator={setCollaborator}
                />
                <HeaderEtapasNovaAdmissao
                    id={id}
                    stepOne={stepOne}
                    stepTwo={stepTwo}
                    stepThree={stepThree}
                    stepFour={stepFour}
                    registeredEmergencyContact={registeredEmergencyContact}
                    path={props.match.path}
                    collaboratorInfor={collaborator}
                />

                <div className="structureNewAdmission">
                    <div className="structureContent">


                        <EnviarContabilidade
                        history={props.history}
                        id={id}
                        path={props.match.path}
                        focusEmailAccouting={focusEmailAccouting}
                        loadSaveAndSendFinaly={loadSaveAndSendFinaly}
                        loadErrorSendEmailToAccounting={loadErrorSendEmailToAccounting}
                        sendAccounting={sendAccounting}
                        myChangeHandlerCollaboratorRequestsSendToAccounting={myChangeHandlerCollaboratorRequestsSendToAccounting}
                        saveandfinaly={saveandfinaly}
                        loadSaveAndFinaly={loadSaveAndFinaly}
                        collaborator={collaborator}
                        />

                    </div>
                <div className="structureChecklist">
                        <Checklist
                            collaboratorId={id}
                            path={props.match.path}
                            addNewTaskSave={addNewTaskSave}
                            tasksDefault={tasksDefault}
                            tasksCompany={tasksCompany}
                            loadTasksChecklist={loadTasksChecklist}
                            idItem={idItem}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}

