import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import swal from 'sweetalert';
import api from '../../../../../services/api';

export default function StepTwoTerminationDoc({ id }) {

const collaboratorId = id;




useEffect(()=>{

    loadterminationchecklistEnvioContabilidade(collaboratorId);
    loadterminationchecklistRemoverBeneficio(collaboratorId);
    loadterminationchecklistNotificacaoDemissao(collaboratorId);
    loadterminationchecklistRevisarCalcRecisao(collaboratorId);
    loadterminationchecklistEncerramentoContrato(collaboratorId);
    loadterminationchecklistSeguroDesemprego(collaboratorId);
    loadterminationchecklistAgendarHomologacao(collaboratorId);
    loadterminationchecklistImprimirComunicado(collaboratorId);
    loadterminationchecklistImprimirExtratoFGTS(collaboratorId);
    loadterminationchecklistImpriComproPagFGTS(collaboratorId);
    loadterminationchecklistImprirTRCT(collaboratorId);
    loadterminationchecklistPagarColEmitirProva(collaboratorId);
    loadterminationchecklistEscanearArqPerfilCol(collaboratorId);
    loadterminationchecklistAtulCarteiraTra(collaboratorId);
    loadterminationchecklistChavConectLiberarFGTS(collaboratorId);
    loadterminationchecklistHomologacaoSind(collaboratorId);

    loadcollaboratordismissal(collaboratorId);

},[collaboratorId])


const [checklistEnvioContabilidade, setChecklistEnvioContabilidade] = useState([])

async function loadterminationchecklistEnvioContabilidade(collaboratorId) {
    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=EnvioContabilidade`)
    .then(response => {
        setChecklistEnvioContabilidade(response.data);
    }).catch(error => {});
}


//RemoverBeneficio
const [checklistRemoverBeneficio, setChecklistRemoverBeneficio] = useState([])

async function loadterminationchecklistRemoverBeneficio(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=RemoverBeneficio`)
    .then(response => {
        setChecklistRemoverBeneficio(response.data);
    }).catch(error => {});

}


//NotificacaoDemissao
const [checklistNotificacaoDemissao, setChecklistNotificacaoDemissao] = useState([])

async function loadterminationchecklistNotificacaoDemissao(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=NotificacaoDemissao`)
    .then(response => {

        setChecklistNotificacaoDemissao(response.data);

    }).catch(error => {});

}


//RevisarCalcRecisao
const [checklistRevisarCalcRecisao, setChecklistRevisarCalcRecisao] = useState([])

async function loadterminationchecklistRevisarCalcRecisao(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=RevisarCalcRecisao`)
    .then(response => {
        setChecklistRevisarCalcRecisao(response.data);
    }).catch(error => {});

}


//EncerramentoContrato
const [checklistEncerramentoContrato, setChecklistEncerramentoContrato] = useState([])

async function loadterminationchecklistEncerramentoContrato(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=EncerramentoContrato`)
    .then(response => {
        setChecklistEncerramentoContrato(response.data);
    }).catch(error => {});

}


//SeguroDesemprego
const [checklistSeguroDesemprego, setChecklistSeguroDesemprego] = useState([])

async function loadterminationchecklistSeguroDesemprego(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=SeguroDesemprego`)
    .then(response => {
        setChecklistSeguroDesemprego(response.data);
    }).catch(error => {});

}


//AgendarHomologacao
const [checklistAgendarHomologacao, setChecklistAgendarHomologacao] = useState([])

async function loadterminationchecklistAgendarHomologacao(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=AgendarHomologacao`)
    .then(response => {
        setChecklistAgendarHomologacao(response.data);
    }).catch(error => {});

}


//ImprimirComunicado
const [checklistImprimirComunicado, setChecklistImprimirComunicado] = useState([])

async function loadterminationchecklistImprimirComunicado(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=ImprimirComunicado`)
    .then(response => {
        setChecklistImprimirComunicado(response.data);
    }).catch(error => {});

}


//ImprimirExtratoFGTS
const [checklistImprimirExtratoFGTS, setChecklistImprimirExtratoFGTS] = useState([])

async function loadterminationchecklistImprimirExtratoFGTS(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=ImprimirExtratoFGTS`)
    .then(response => {
        setChecklistImprimirExtratoFGTS(response.data);
    }).catch(error => {});

}


//ImpriComproPagFGTS
const [checklistImpriComproPagFGTS, setChecklistImpriComproPagFGTS] = useState([])

async function loadterminationchecklistImpriComproPagFGTS(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=ImpriComproPagFGTS`)
    .then(response => {
        setChecklistImpriComproPagFGTS(response.data);
    }).catch(error => {});

}


//ImprirTRCT
const [checklistImprirTRCT, setChecklistImprirTRCT] = useState([])

async function loadterminationchecklistImprirTRCT(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=ImprirTRCT`)
    .then(response => {
        setChecklistImprirTRCT(response.data);
    }).catch(error => {});

}


//PagarColEmitirProva
const [checklistPagarColEmitirProva, setChecklistPagarColEmitirProva] = useState([])

async function loadterminationchecklistPagarColEmitirProva(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=PagarColEmitirProva`)
    .then(response => {
        setChecklistPagarColEmitirProva(response.data);
    }).catch(error => {});

}


//EscanearArqPerfilCol
const [checklistEscanearArqPerfilCol, setChecklistEscanearArqPerfilCol] = useState([])

async function loadterminationchecklistEscanearArqPerfilCol(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=EscanearArqPerfilCol`)
    .then(response => {
        setChecklistEscanearArqPerfilCol(response.data);
    }).catch(error => {});

}


//AtulCarteiraTra
const [checklistAtulCarteiraTra, setChecklistAtulCarteiraTra] = useState([])

async function loadterminationchecklistAtulCarteiraTra(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=AtulCarteiraTra`)
    .then(response => {
        setChecklistAtulCarteiraTra(response.data);
    }).catch(error => {});

}

//ChavConectLiberarFGTS
const [checklistChavConectLiberarFGTS, setChecklistChavConectLiberarFGTS] = useState([])

async function loadterminationchecklistChavConectLiberarFGTS(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=ChavConectLiberarFGTS`)
    .then(response => {
        setChecklistChavConectLiberarFGTS(response.data);
    }).catch(error => {});

}


//HomologacaoSind
const [checklistHomologacaoSind, setChecklistHomologacaoSind] = useState([])

async function loadterminationchecklistHomologacaoSind(collaboratorId) {

    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=HomologacaoSind`)
    .then(response => {
        setChecklistHomologacaoSind(response.data);
    }).catch(error => {});

}


async function addTerminationCheckList(event) {

    if(event.target.checked === true) {

        const name      = event.target.name;
        const nameCheck = event.target.name;
        await api.post('/collaborator-termination-checklist/', { collaboratorId, name })
        .then(response => {

            async function loadterminationchecklistatualizado() {
                await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=${nameCheck}`)
                .then(response => {
                //const nameCheck = response.data[0].name;
                    if (nameCheck === 'EnvioContabilidade') {
                        setChecklistEnvioContabilidade(response.data);
                    }
                    if (nameCheck === 'NotificacaoDemissao') {
                        setChecklistNotificacaoDemissao(response.data);
                    }
                    if (nameCheck === 'RevisarCalcRecisao') {
                        setChecklistRevisarCalcRecisao(response.data);
                    }
                    if (nameCheck === 'RemoverBeneficio') {
                        setChecklistRemoverBeneficio(response.data);
                    }
                    if (nameCheck === 'EncerramentoContrato') {
                        setChecklistEncerramentoContrato(response.data);
                    }
                    if (nameCheck === 'SeguroDesemprego') {
                        setChecklistSeguroDesemprego(response.data);
                    }
                    if (nameCheck === 'AgendarHomologacao') {
                        setChecklistAgendarHomologacao(response.data);
                    }
                    if (nameCheck === 'ImprimirComunicado') {
                        setChecklistImprimirComunicado(response.data);
                    }
                    if (nameCheck === 'ImprimirExtratoFGTS') {
                        setChecklistImprimirExtratoFGTS(response.data);
                    }
                    if (nameCheck === 'ImpriComproPagFGTS') {
                        setChecklistImpriComproPagFGTS(response.data);
                    }
                    if (nameCheck === 'ImprirTRCT') {
                        setChecklistImprirTRCT(response.data);
                    }
                    if (nameCheck === 'PagarColEmitirProva') {
                        setChecklistPagarColEmitirProva(response.data);
                    }
                    if (nameCheck === 'EscanearArqPerfilCol') {
                        setChecklistEscanearArqPerfilCol(response.data);
                    }
                    if (nameCheck === 'AtulCarteiraTra') {
                        setChecklistAtulCarteiraTra(response.data);
                    }
                    if (nameCheck === 'ChavConectLiberarFGTS') {
                        setChecklistChavConectLiberarFGTS(response.data);
                    }
                    if (nameCheck === 'HomologacaoSind') {
                        setChecklistHomologacaoSind(response.data);
                    }
                })
            }
            loadterminationchecklistatualizado();

        }).catch(error => {});


    } else {
        const name = event.target.name;

        async function loadterminationchecklist() {

            await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=${name}`)
            .then(response => {

                const idCheckTerminatorCollaborator = response.data[0]._id;
                const nameCheck = response.data[0].name;

                async function deletecolaboratortermintationchecklist() {

                    await api.delete(`/collaborator-termination-checklist/${idCheckTerminatorCollaborator}`)
                    .then(response => {}).catch(error => {});


                }
                deletecolaboratortermintationchecklist()


                async function loadterminationchecklistatualizado() {

                    await api.get(`/collaborator-termination-checklist/?id=${collaboratorId}&name=${nameCheck}`)
                    .then(response => {

                        if (nameCheck === 'EnvioContabilidade') {
                            setChecklistEnvioContabilidade({});
                        }
                        if (nameCheck === 'NotificacaoDemissao') {
                            setChecklistNotificacaoDemissao({});
                        }
                        if (nameCheck === 'RevisarCalcRecisao') {
                            //alert('RevisarCalcRecisao')
                            setChecklistRevisarCalcRecisao({});
                        }
                        if (nameCheck === 'RemoverBeneficio') {
                            ///alert('RemoverBeneficio')
                            setChecklistRemoverBeneficio({});
                        }
                        if (nameCheck === 'EncerramentoContrato') {
                            setChecklistEncerramentoContrato({});
                        }
                        if (nameCheck === 'SeguroDesemprego') {
                            setChecklistSeguroDesemprego({});
                        }
                        if (nameCheck === 'AgendarHomologacao') {
                            setChecklistAgendarHomologacao({});
                        }
                        if (nameCheck === 'ImprimirComunicado') {
                            setChecklistImprimirComunicado({});
                        }
                        if (nameCheck === 'ImprimirExtratoFGTS') {
                            setChecklistImprimirExtratoFGTS({});
                        }
                        if (nameCheck === 'ImpriComproPagFGTS') {
                            setChecklistImpriComproPagFGTS({});
                        }
                        if (nameCheck === 'ImprirTRCT') {
                            setChecklistImprirTRCT({});
                        }
                        if (nameCheck === 'PagarColEmitirProva') {
                            setChecklistPagarColEmitirProva({});
                        }
                        if (nameCheck === 'PagarColEmitirProva') {
                            setChecklistPagarColEmitirProva({});
                        }
                        if (nameCheck === 'EscanearArqPerfilCol') {
                            setChecklistEscanearArqPerfilCol({});
                        }
                        if (nameCheck === 'AtulCarteiraTra') {
                            setChecklistAtulCarteiraTra({});
                        }
                        if (nameCheck === 'ChavConectLiberarFGTS') {
                            setChecklistChavConectLiberarFGTS({});
                        }
                        if (nameCheck === 'HomologacaoSind') {
                            setChecklistHomologacaoSind({});
                        }
                    }).catch(error => {});
                }
                loadterminationchecklistatualizado();

            }).catch(error => {});
        }
        loadterminationchecklist();
        //alert('saiu' +event.target.name)
    }
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorShutdownSaved, setCollaboratorShutdownSaved ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ , setLoadShutdownSaved ] = useState(false);


async function loadcollaboratordismissal(collaboratorId) {
    setLoadShutdownSaved(true)
    await api.get(`/collaborator-dismissal/${collaboratorId}`)
    .then(response => {
        setLoadShutdownSaved(false)
        setCollaboratorShutdownSaved(response.data[0]);
        //console.log(response.data[0])
    }).catch(error => {});
}

async function excludeAdmssion(event) {
    // console.log(collaboratorShutdownSaved._id)
    event.preventDefault();
      swal({
        title: "Atenção",
        text: "Deseja realmente excluir o processo de admissão deste colaborador?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('_id', id);

            if(collaboratorShutdownSaved !== undefined) {

                await api.delete(`/collaborator-dismissal/${collaboratorShutdownSaved._id}`, formData, config)
                .then(response => {

                    window.location.href = `/colaboradores/ativos`;
                    swal({ icon: "success", title: "Sucesso!", text: "Desligamneto excluído com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                });

            }
            else {

                await api.put(`/collaborator/${collaboratorId}`, { status: 'Ativo', shutdownProcess: false })
                .then(response => {

                    window.location.href = `/colaboradores/ativos`;
                    swal({ icon: "success", title: "Sucesso!", text: "Desligamneto excluído com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                });

            }



        }
    }).catch(error => {});

}


return (
    <>
       <div className="desligamento-etapa-dois">
            <h2 className="titulo">Lista de itens e documentos</h2>
            <p className="subtitulo">Revise a lista de documentos enviados e pendentes e outros itens</p>
            <div className="lista-um">

            <ul>
                <li className="chekbox">
                    {
                    checklistEnvioContabilidade[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="EnvioContabilidade" name="EnvioContabilidade" id="EnvioContabilidade" checked/>
                        <label htmlFor="EnvioContabilidade" className="chackboxLabel"></label>
                        <label htmlFor="EnvioContabilidade" className="foreignTexto">Envio das informações para a contabilidade</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="EnvioContabilidade" name="EnvioContabilidade" id="EnvioContabilidade"/>
                        <label htmlFor="EnvioContabilidade" className="chackboxLabel"></label>
                        <label htmlFor="EnvioContabilidade" className="foreignTexto">Envio das informações para a contabilidade</label>
                        </>
                    )
                    }
                </li>
                <li className="chekbox">
                    {
                    checklistNotificacaoDemissao[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="NotificacaoDemissao" name="NotificacaoDemissao" id="NotificacaoDemissao" checked/>
                        <label htmlFor="NotificacaoDemissao" className="chackboxLabel"></label>
                        <label htmlFor="NotificacaoDemissao" className="foreignTexto">Notificação de demissão</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="NotificacaoDemissao" name="NotificacaoDemissao" id="NotificacaoDemissao" />
                        <label htmlFor="NotificacaoDemissao" className="chackboxLabel"></label>
                        <label htmlFor="NotificacaoDemissao" className="foreignTexto">Notificação de demissão</label>
                        </>
                    )
                    }
                </li>
                <li className="chekbox">
                    {checklistRevisarCalcRecisao[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="RevisarCalcRecisao" name="RevisarCalcRecisao" id="RevisarCalcRecisao" checked/>
                        <label htmlFor="RevisarCalcRecisao" className="chackboxLabel"></label>
                        <label htmlFor="RevisarCalcRecisao" className="foreignTexto">Revisar cálculo de rescisão </label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="RevisarCalcRecisao" name="RevisarCalcRecisao" id="RevisarCalcRecisao" />
                        <label htmlFor="RevisarCalcRecisao" className="chackboxLabel"></label>
                        <label htmlFor="RevisarCalcRecisao" className="foreignTexto">Revisar cálculo de rescisão </label>
                        </>
                    )
                    }
                </li>
                <li className="chekbox">
                {
                checklistRemoverBeneficio[0] ?
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="RemoverBeneficio" name="RemoverBeneficio" id="RemoverBeneficio" checked/>
                    <label htmlFor="RemoverBeneficio" className="chackboxLabel"></label>
                    <label htmlFor="RemoverBeneficio" className="foreignTexto">Remover benefícios com os operadores</label>
                    </>
                ) :
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="RemoverBeneficio" name="RemoverBeneficio" id="RemoverBeneficio" />
                    <label htmlFor="RemoverBeneficio" className="chackboxLabel"></label>
                    <label htmlFor="RemoverBeneficio" className="foreignTexto">Remover benefícios com os operadores</label>
                    </>
                )
                }
                </li>
                <li className="chekbox">
                {
                checklistEncerramentoContrato[0] ?
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="EncerramentoContrato" name="EncerramentoContrato" id="EncerramentoContrato" checked/>
                    <label htmlFor="EncerramentoContrato" className="chackboxLabel"></label>
                    <label htmlFor="EncerramentoContrato" className="foreignTexto">Encerramento de contrato de experiência ou estágio</label>
                    </>
                ) :
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="EncerramentoContrato" name="EncerramentoContrato" id="EncerramentoContrato" />
                    <label htmlFor="EncerramentoContrato" className="chackboxLabel"></label>
                    <label htmlFor="EncerramentoContrato" className="foreignTexto">Encerramento de contrato de experiência ou estágio</label>
                    </>
                )
                }
                </li>
                <li className="chekbox">
                {checklistSeguroDesemprego[0] ?
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="SeguroDesemprego" name="SeguroDesemprego" id="SeguroDesemprego" checked/>
                    <label htmlFor="SeguroDesemprego" className="chackboxLabel"></label>
                    <label htmlFor="SeguroDesemprego" className="foreignTexto">Seguro desemprego</label>
                    </>
                ) :
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="SeguroDesemprego" name="SeguroDesemprego" id="SeguroDesemprego" />
                    <label htmlFor="SeguroDesemprego" className="chackboxLabel"></label>
                    <label htmlFor="SeguroDesemprego" className="foreignTexto">Seguro desemprego</label>
                    </>
                )
                }
                </li>
                <li className="chekbox">
                {checklistAgendarHomologacao[0] ?
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="AgendarHomologacao" name="AgendarHomologacao" id="AgendarHomologacao" checked/>
                    <label htmlFor="AgendarHomologacao" className="chackboxLabel"></label>
                    <label htmlFor="AgendarHomologacao" className="foreignTexto">Agendar homologação (caso tenha mais de 1 ano de empresa)</label>
                    </>
                ) :
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="AgendarHomologacao" name="AgendarHomologacao" id="AgendarHomologacao" />
                    <label htmlFor="AgendarHomologacao" className="chackboxLabel"></label>
                    <label htmlFor="AgendarHomologacao" className="foreignTexto">Agendar homologação (caso tenha mais de 1 ano de empresa)</label>
                    </>
                )
                }
                </li>
                <li className="chekbox">
                {checklistImprimirComunicado[0] ?
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImprimirComunicado" name="ImprimirComunicado" id="ImprimirComunicado" checked/>
                    <label htmlFor="ImprimirComunicado" className="chackboxLabel"></label>
                    <label htmlFor="ImprimirComunicado" className="foreignTexto">Imprimir comunicado de dispensa</label>
                    </>
                ) :
                (
                    <>
                    <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImprimirComunicado" name="ImprimirComunicado" id="ImprimirComunicado" />
                    <label htmlFor="ImprimirComunicado" className="chackboxLabel"></label>
                    <label htmlFor="ImprimirComunicado" className="foreignTexto">Imprimir comunicado de dispensa</label>
                    </>
                )
                }
                </li>
            </ul>

            </div>
            <div className="lista-dois">
                <ul>
                    <li className="chekbox">
                    {checklistImprimirExtratoFGTS[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImprimirExtratoFGTS" name="ImprimirExtratoFGTS" id="ImprimirExtratoFGTS" checked/>
                        <label htmlFor="ImprimirExtratoFGTS" className="chackboxLabel"></label>
                        <label htmlFor="ImprimirExtratoFGTS" className="foreignTexto">Imprimir extrato do FGTS do período trabalhado</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImprimirExtratoFGTS" name="ImprimirExtratoFGTS" id="ImprimirExtratoFGTS" />
                        <label htmlFor="ImprimirExtratoFGTS" className="chackboxLabel"></label>
                        <label htmlFor="ImprimirExtratoFGTS" className="foreignTexto">Imprimir extrato do FGTS do período trabalhado</label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistImpriComproPagFGTS[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImpriComproPagFGTS" name="ImpriComproPagFGTS" id="ImpriComproPagFGTS" checked/>
                        <label htmlFor="ImpriComproPagFGTS" className="chackboxLabel"></label>
                        <label htmlFor="ImpriComproPagFGTS" className="foreignTexto">Imprimir comprovante do pagamento de multa do FGTS e GRRF </label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImpriComproPagFGTS" name="ImpriComproPagFGTS" id="ImpriComproPagFGTS" />
                        <label htmlFor="ImpriComproPagFGTS" className="chackboxLabel"></label>
                        <label htmlFor="ImpriComproPagFGTS" className="foreignTexto">Imprimir comprovante do pagamento de multa do FGTS e GRRF </label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistImprirTRCT[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImprirTRCT" name="ImprirTRCT" id="ImprirTRCT" checked/>
                        <label htmlFor="ImprirTRCT" className="chackboxLabel"></label>
                        <label htmlFor="ImprirTRCT" className="foreignTexto">Imprimir 5 vias do TRCT (termo de rescisão)</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ImprirTRCT" name="ImprirTRCT" id="ImprirTRCT" />
                        <label htmlFor="ImprirTRCT" className="chackboxLabel"></label>
                        <label htmlFor="ImprirTRCT" className="foreignTexto">Imprimir 5 vias do TRCT (termo de rescisão)</label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistPagarColEmitirProva[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="PagarColEmitirProva" name="PagarColEmitirProva" id="PagarColEmitirProva" checked/>
                        <label htmlFor="PagarColEmitirProva" className="chackboxLabel"></label>
                        <label htmlFor="PagarColEmitirProva" className="foreignTexto">Pagar colaborador e emitir prova bancária de quitação</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="PagarColEmitirProva" name="PagarColEmitirProva" id="PagarColEmitirProva" />
                        <label htmlFor="PagarColEmitirProva" className="chackboxLabel"></label>
                        <label htmlFor="PagarColEmitirProva" className="foreignTexto">Pagar colaborador e emitir prova bancária de quitação</label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistEscanearArqPerfilCol[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="EscanearArqPerfilCol" name="EscanearArqPerfilCol" id="EscanearArqPerfilCol" checked/>
                        <label htmlFor="EscanearArqPerfilCol" className="chackboxLabel"></label>
                        <label htmlFor="EscanearArqPerfilCol" className="foreignTexto">Escanear e arquivar no perfil do colaborador os comprovantes de pagamento</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="EscanearArqPerfilCol" name="EscanearArqPerfilCol" id="EscanearArqPerfilCol" />
                        <label htmlFor="EscanearArqPerfilCol" className="chackboxLabel"></label>
                        <label htmlFor="EscanearArqPerfilCol" className="foreignTexto">Escanear e arquivar no perfil do colaborador os comprovantes de pagamento</label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistAtulCarteiraTra[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="AtulCarteiraTra" name="AtulCarteiraTra" id="AtulCarteiraTra" checked/>
                        <label htmlFor="AtulCarteiraTra" className="chackboxLabel"></label>
                        <label htmlFor="AtulCarteiraTra" className="foreignTexto">Atualizar e dar baixa na Carteira de Trabalho e livro de registro</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="AtulCarteiraTra" name="AtulCarteiraTra" id="AtulCarteiraTra" />
                        <label htmlFor="AtulCarteiraTra" className="chackboxLabel"></label>
                        <label htmlFor="AtulCarteiraTra" className="foreignTexto">Atualizar e dar baixa na Carteira de Trabalho e livro de registro</label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistChavConectLiberarFGTS[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ChavConectLiberarFGTS" name="ChavConectLiberarFGTS" id="ChavConectLiberarFGTS" checked/>
                        <label htmlFor="ChavConectLiberarFGTS" className="chackboxLabel"></label>
                        <label htmlFor="ChavConectLiberarFGTS" className="foreignTexto">Chave de conectividade para liberar FGTS</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="ChavConectLiberarFGTS" name="ChavConectLiberarFGTS" id="ChavConectLiberarFGTS" />
                        <label htmlFor="ChavConectLiberarFGTS" className="chackboxLabel"></label>
                        <label htmlFor="ChavConectLiberarFGTS" className="foreignTexto">Chave de conectividade para liberar FGTS</label>
                        </>
                    )
                    }
                    </li>
                    <li className="chekbox">
                    {checklistHomologacaoSind[0] ?
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="HomologacaoSind" name="HomologacaoSind" id="HomologacaoSind" checked/>
                        <label htmlFor="HomologacaoSind" className="chackboxLabel"></label>
                        <label htmlFor="HomologacaoSind" className="foreignTexto">Homologação no sindicato ou MTE</label>
                        </>
                    ) :
                    (
                        <>
                        <input type="checkbox" onChange={(event) => addTerminationCheckList(event)} value="HomologacaoSind" name="HomologacaoSind" id="HomologacaoSind" />
                        <label htmlFor="HomologacaoSind" className="chackboxLabel"></label>
                        <label htmlFor="HomologacaoSind" className="foreignTexto">Homologação no sindicato ou MTE</label>
                        </>
                    )
                    }
                    </li>
                </ul>

            </div>
       </div>
       <div className="desligamento-buttons">
        <form onSubmit={excludeAdmssion} className="delete">
                <button type="submit" className="delete">
                    Excluir processo
                </button>
        </form>
        <div className='saved-back'>
            <NavLink to={`/colaboradores/desligamento/${collaboratorId}/contabilidade`} className="back btnGray">Voltar</NavLink>
            <NavLink to={`/colaboradores/desligamento/${collaboratorId}/finalizar`} className="saved btnGreen">Continuar</NavLink>


        </div>

        </div>

    </>
);
}

