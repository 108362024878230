import React from 'react';

import { Dropdown } from "react-bootstrap";
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import iconVerMais from '../../../../assets/images/more.svg';
import NenhumColAtivo from '../../../../assets/images/illustration-pageAtivo-vazio.svg';
import { NavLink } from 'react-router-dom'
import Carregamento from '../../../../assets/images/carregando.gif';
import { decodeToken } from '../../../../services/auth';

export default function ScoreAdmin({itemRenderPagination, page, checkOnlyDirectReports, onlyDirectReports, loadPage, collaborators, pages, total, onChangePagination, numberPaginate, nextPage, prevPage}) {
    const { user } = decodeToken();
    const role    = user.role;

    return (
        <>
           <div className='real-time-presence'>
               
               <div className='header-menu'>
                    <div className='titulo-datas'>
                        <h3 className='titulo'>
                            Lista de colaboradores
                        </h3>


                        {
                            role === 'manager' ?
                            <div className='only-direct-reports'>
                                <label className='checkbox' htmlFor="checkbox">
                                    <input type="checkbox" onChange={checkOnlyDirectReports} checked={onlyDirectReports} id="checkbox" />
                                    <label htmlFor="checkbox"></label>
                                    <p>Somente subordinado diretos</p>
                                </label>
                            </div>
                            :""
                        }
                    </div>
                </div>
               <div className='tabela-header'>
                   <div>Colaborador</div>
                   <div className='department'>Departamento</div>
                   <div className='managing'>Gestor</div>
                   <div>
                   </div>
               </div>
               <div className='tabela-corpo'>
                {
                    loadPage ?
                    <div className="carregamento">
                           <img alt="" src={Carregamento} />
                       </div>
                       :
                       collaborators?.length !== 0 ?
                            collaborators?.collaboratorSearch.map((collaborator, index) => (
                                <div className='tr' key={index}>
                                    <NavLink to={`/folha-de-ponto/${collaborator._id}`} className="colaborador">
                                        {
                                            collaborator.avatar && collaborator.avatar !== 'undefined' ?
                                            <img alt="" src={collaborator.avatar} className="foto-colaborador"/>
                                            :
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        }
                                        <div className="nome-cargo">
                                            <strong className="nome">{collaborator?.name}</strong>
                                            <p className="cargo">{collaborator?.occupation}</p>
                                        </div>
                                    </NavLink>
                                    <NavLink  to={`/folha-de-ponto/${collaborator._id}`}  className='department' >
                                    {collaborator.sector ? collaborator.sector : '-'}
                                    </NavLink>
                                    <NavLink  to={`/folha-de-ponto/${collaborator._id}`}  className='managing' >
                                    {collaborator.nameManager ? collaborator.nameManager : '-'}
                                    </NavLink>
                                    <div>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            <NavLink  to={`/folha-de-ponto/${collaborator._id}`} >Visualizar folha</NavLink>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            ))
                       :
                       
                    <div className="nenhum-colaborador-ativo">
                        {
                            user && user.role === 'manager' ?
                            <p>Você não possui nenhum colaborador.</p>:
                            <>
                            <p>Nenhum colaborador cadastrado. Comece uma nova admissão.</p>
                            <NavLink className="btnGreen more" to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/`}>
                                    Nova admissão
                            </NavLink>
                            </>

                        }
                        <img src={NenhumColAtivo} alt="Nenhum colaborador ativo" />
                    </div>

                }

               </div>
                {/* {
                    pages === 0 ? "":
                    <div className='paginacao-componente'>
                    <Pagination
                        itemRender={itemRenderPagination}
                        total={total}
                        current={page + 1} 
                        onChange={onChangePagination}
                    />
                    </div>
                } */}

{
                pages === 0 ? '' :
                    <>
                    <div className="paginacaoG">
                        <ul >
                            {
                                numberPaginate
                            }
                            {
                                pages && pages > 0 ?
                                <>
                                <li className="page-anterior">
                                    <button style={(page === 0) ? { cursor: "not-allowed" } : {}}  disabled={page === 0} onClick={() => prevPage(page)} >anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(page === pages) ? { cursor: "not-allowed" } : {}}  disabled={page === pages} onClick={() => nextPage(page)} >próxima</button>
                                </li>
                                </>
                                :
                                ''
                            }
                        </ul>
                    </div>
                    </>
                }
           </div>
        </>
    );
}

