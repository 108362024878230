import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../../../components/Layouts/default';
import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import Professional from '../../../../components/Administrativo/Colaboradores/profile/Professional';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import api from '../../../../services/api';
import { decodeToken } from '../../../../services/auth';
import swal from 'sweetalert';
import { dateMask } from '../../../../services/mask';
import { toast } from 'react-toastify';

export default function PageAdmissao(props) {

    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) {

            loadcollaborator(id);
            loadworkinfor(id);
            loadmanagers();

        }

    }, [id]);

    async function loadcollaborator(id) {

        setLoadPage(true)
        setLoadPage2Collaborator(true)
        await api.get(`/collaborator/${id}`)
        .then(response => {

            setLoadPage(false)
            setLoadPage2Collaborator(false)
            setCollaborator(response.data);
            setWorkInfor({})
            setRegisteredCollaborator(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadworkinfor(id) {

        setLoadPageWorkInfor(true)
        await api.get(`/collaborator-work-information/${id}`)
        .then(response => {

            setLoadPageWorkInfor(false)
            if(response.data[0] !== undefined) {

                setRegisteredWorkInfor(response.data[0]);

            }
            else {

                setRegisteredWorkInfor([]);

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    ////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageJobInformation, setLoadPageWorkInfor ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage2Collaborator, setLoadPage2Collaborator ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAdmissionDate,  ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadJobInformation, setLoadJobInformation ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ workInfor, setWorkInfor ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorInfor,  ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredWorkInfor, setRegisteredWorkInfor ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredCollaborator, setRegisteredCollaborator ] = useState([]);


    async function loadmanagers() {
        await api.get('/collaborator-new?role=manager&status=Ativo')
        .then(response => {
            setManagers(response.data.collaboratorSearch);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleJobInfor, setVisibleJobInfor] = useState(false);
    function AddAndJobInformationVisible(e) {

        const _id = e;
        setWorkInfor({
            _id
        })

        setVisibleJobInfor(!visibleJobInfor);
        setSelectRole(false)
        setSelectManager(false)
        setSelectAdmissionType(false)
        setSelectFormOfPayment(false)
        setSelectSyndicate(false)
        setSelectStability(false)
        setSelectPositionOfTrust(false)
        setSelectFirstJob(false)
        setSelectUnemploymentInsurance(false)
        setSelectRetiree(false)
        setSelectRegistrationWithTheProfessionalBody(false)
        setSelectProfessionalAdvice(false)
        setSelectCIPA(false)
        setSelectHavePointsRecord(false)
        setSelectSalaryType(false)
    }


    const myChangeHandlerJobInfor = event => {

        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;
        //admissionDate
        if (nam === "admissionDate") {

            let admissionDate = dateMask(val)
            //setCollaboratorInfor({ ...collaboratorInfor, collaborator, [nam]: admissionDate })
            setWorkInfor({ ...workInfor, collaborator, [nam]: admissionDate, path: props.match.path })
        }
        //manager
        else
        if (nam === "manager") {

            //setCollaboratorInfor({ ...collaboratorInfor, collaborator, [nam]: val })
            setWorkInfor({ ...workInfor, collaborator, [nam]: val })

        }
        //dateAdmissionExamination
        else
        if (nam === "dateAdmissionExamination") {

            let dateAdmissionExamination = dateMask(val)
            //setCollaboratorInfor({ ...collaboratorInfor, collaborator, [nam]: dateAdmissionExamination })
            setWorkInfor({ ...workInfor, collaborator, [nam]: dateAdmissionExamination })


        }
        else {

            setWorkInfor({ ...workInfor, collaborator, [nam]: val })

        }

    };

    async function handleSubmitJobInfor(event) {
        event.preventDefault();


        if(workInfor._id !== undefined) {
            setLoadJobInformation(true)

            if (workInfor.admissionDate !== undefined) {
                var admissionDate = workInfor.admissionDate
            }

            if (workInfor.registration !== undefined ) {
                var registration = workInfor.registration
            }

            if (workInfor.manager !== undefined) {
            var manager = workInfor.manager
            }
            if (workInfor.email !== undefined) {
                var email = workInfor.email
            }
            if (workInfor.role !== undefined) {
                var role = workInfor.role
                // alert("entrou")
            }
            if (workInfor.admissionType !== undefined) {
                var admissionType = workInfor.admissionType
            }

            if (workInfor.formOfPayment !== undefined) {
                var formOfPayment = workInfor.formOfPayment
            }

            if (workInfor.syndicate !== undefined) {
                var syndicate = workInfor.syndicate
            }

            if (workInfor.stability !== undefined) {
                var stability = workInfor.stability
            }

            if (workInfor.positionOfTrust !== undefined) {
                var positionOfTrust = workInfor.positionOfTrust
            }

            if (workInfor.FirstJob !== undefined) {
                var FirstJob = workInfor.FirstJob
            }

            if (workInfor.dateAdmissionExamination !== undefined) {
                var dateAdmissionExamination = workInfor.dateAdmissionExamination
            }

            if (workInfor.SalaryType !== undefined) {
                var SalaryType = workInfor.SalaryType
            }

            if (workInfor.unemploymentInsurance !== undefined) {
                var unemploymentInsurance = workInfor.unemploymentInsurance
            }

            if (workInfor.Retiree !== undefined) {
                var Retiree = workInfor.Retiree
            }

            if (workInfor.RegistrationWithTheProfessionalBody !== undefined) {
                var RegistrationWithTheProfessionalBody = workInfor.RegistrationWithTheProfessionalBody
            }

            if (workInfor.professionalAdvice !== undefined ) {
                var professionalAdvice = workInfor.professionalAdvice
            }

            if (workInfor.CIPA !== undefined) {
                var CIPA = workInfor.CIPA
            }

            if (workInfor.havePointsRecord !== undefined) {
                var havePointsRecord = workInfor.havePointsRecord
            }

            setLoadJobInformation(true)
            if(
                workInfor.admissionDate            === "" ||
                workInfor.SalaryType               === "" ||
                workInfor.admissionType            === "" ||
                workInfor.formOfPayment            === "" ||

                workInfor.stability                === "" ||
                workInfor.registration             === "" ||
                workInfor.role                     === "" ||
                workInfor.dateAdmissionExamination === ""
            ) {
                swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser salvo em branco" });
                setLoadJobInformation(false)
            } else {
                if (
                    admissionDate                       !== undefined ||
                    manager                             !== undefined ||
                    dateAdmissionExamination            !== undefined ||
                    registration                        !== undefined ||
                    email                               !== undefined ||
                    admissionType                       !== undefined ||
                    formOfPayment                       !== undefined ||
                    syndicate                           !== undefined ||
                    stability                           !== undefined ||
                    positionOfTrust                     !== undefined ||
                    FirstJob                            !== undefined ||
                    SalaryType                          !== undefined ||
                    unemploymentInsurance               !== undefined ||
                    Retiree                             !== undefined ||
                    RegistrationWithTheProfessionalBody !== undefined ||
                    professionalAdvice                  !== undefined ||
                    CIPA                                !== undefined ||
                    role                                !== undefined ||
                    havePointsRecord                    !== undefined
                ) {
                    //////////////////////////////////////////////////////////
                    setLoadJobInformation(true)
                    if (
                        admissionDate                       !== undefined ||
                        manager                             !== undefined ||
                        email                               !== undefined ||
                        role                                !== undefined ||
                        admissionType                       !== undefined ||
                        dateAdmissionExamination            !== undefined
                    ) {
                        await api.put(`/collaborator/${workInfor.collaborator}`, { admissionDate, manager, email, dateAdmissionExamination, role, admissionType, path: props.match.path })
                        .then(response => {

                            setVisibleJobInfor(!visibleJobInfor);
                            setLoadJobInformation(false);
                            loadcollaborator(id);


                            if (
                                registration                        !== undefined ||
                                admissionType                       !== undefined ||
                                formOfPayment                       !== undefined ||
                                syndicate                           !== undefined ||
                                stability                           !== undefined ||
                                positionOfTrust                     !== undefined ||
                                FirstJob                            !== undefined ||
                                SalaryType                          !== undefined ||
                                unemploymentInsurance               !== undefined ||
                                Retiree                             !== undefined ||
                                RegistrationWithTheProfessionalBody !== undefined ||
                                professionalAdvice                  !== undefined ||
                                CIPA                                !== undefined ||
                                role                                !== undefined ||
                                havePointsRecord                    !== undefined
                            ) {
                                ///////////////////////////////////////////////////////////////////////////

                                setLoadJobInformation(true)
                                async function collaboratorworkinforput() {

                                    await api.put(`/collaborator-work-information/${workInfor._id}`, {
                                        registration,
                                        admissionType,
                                        formOfPayment,
                                        syndicate,
                                        stability,
                                        positionOfTrust,
                                        FirstJob,
                                        SalaryType,
                                        unemploymentInsurance,
                                        Retiree,
                                        RegistrationWithTheProfessionalBody,
                                        professionalAdvice,
                                        CIPA,
                                        role,
                                        havePointsRecord,
                                        path: props.match.path
                                    })
                                    .then(response => {

                                        setVisibleJobInfor(!visibleJobInfor);
                                        setLoadJobInformation(false);
                                        loadworkinfor(id);
                                        swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                                    }).catch(error => {
                                        swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                        setLoadJobInformation(false)
                                    });

                                }
                                collaboratorworkinforput()

                                ///////////////////////////////////////////////////////////////////////////
                            } else {
                                swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                                setLoadJobInformation(false);
                            }



                        }).catch(error => {
                            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                            setLoadJobInformation(false);
                            //swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                        });

                        //////////////////////////////////////////////////////////////////////////////
                    } else {
                        if (
                            registration                        !== undefined ||
                            admissionType                       !== undefined ||
                            formOfPayment                       !== undefined ||
                            syndicate                           !== undefined ||
                            stability                           !== undefined ||
                            positionOfTrust                     !== undefined ||
                            FirstJob                            !== undefined ||
                            SalaryType                          !== undefined ||
                            unemploymentInsurance               !== undefined ||
                            Retiree                             !== undefined ||
                            RegistrationWithTheProfessionalBody !== undefined ||
                            professionalAdvice                  !== undefined ||
                            CIPA                                !== undefined ||
                            role                                !== undefined ||
                            havePointsRecord                    !== undefined
                        ) {
                            ///////////////////////////////////////////////////////////////////////////
                            setLoadJobInformation(true)
                            async function collaboratorworkinforput() {

                                await api.put(`/collaborator-work-information/${workInfor._id}`, {
                                    registration,
                                    admissionType,
                                    formOfPayment,
                                    syndicate,
                                    stability,
                                    positionOfTrust,
                                    FirstJob,
                                    SalaryType,
                                    unemploymentInsurance,
                                    Retiree,
                                    RegistrationWithTheProfessionalBody,
                                    professionalAdvice,
                                    CIPA,
                                    role,
                                    havePointsRecord,
                                    path: props.match.path
                                })
                                .then(response => {

                                    setLoadJobInformation(false)
                                    setVisibleJobInfor(!visibleJobInfor);
                                    loadworkinfor(id);
                                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                                }).catch(error => {

                                    swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                    setLoadJobInformation(false);

                                });

                            }
                            collaboratorworkinforput()


                            ///////////////////////////////////////////////////////////////////////////
                        } else {
                            swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });
                            setLoadJobInformation(false);
                        }
                    }





                } else {
                    swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });

                    setLoadJobInformation(false);
                }
            }


        } else {

            if (workInfor.admissionDate !== undefined && workInfor.admissionDate !== '') {
                // eslint-disable-next-line no-redeclare
                var admissionDate = workInfor.admissionDate
            }

            if (workInfor.registration !== undefined  && workInfor.registration !== '') {
                // eslint-disable-next-line no-redeclare
                var registration = workInfor.registration
            }

            if (workInfor.manager !== undefined && workInfor.manager !== '') {
                // eslint-disable-next-line no-redeclare
                var manager = workInfor.manager
            }

            if (workInfor.role !== undefined && workInfor.role !== '') {
                // eslint-disable-next-line no-redeclare
                var role = workInfor.role
            }

            if (workInfor.email !== undefined && workInfor.email !== '') {
                // eslint-disable-next-line no-redeclare
                var email = workInfor.email
            }

            if (workInfor.admissionType !== undefined && workInfor.admissionType !== '') {
                // eslint-disable-next-line no-redeclare
                var admissionType = workInfor.admissionType
            }

            if (workInfor.formOfPayment !== undefined && workInfor.formOfPayment !== '') {
                // eslint-disable-next-line no-redeclare
                var formOfPayment = workInfor.formOfPayment
            }

            if (workInfor.syndicate !== undefined && workInfor.syndicate !== '') {
                // eslint-disable-next-line no-redeclare
                var syndicate = workInfor.syndicate
            }

            if (workInfor.stability !== undefined && workInfor.stability !== '') {
                // eslint-disable-next-line no-redeclare
                var stability = workInfor.stability
            }

            if (workInfor.positionOfTrust !== undefined && workInfor.positionOfTrust !== '') {
                // eslint-disable-next-line no-redeclare
                var positionOfTrust = workInfor.positionOfTrust
            }

            if (workInfor.FirstJob !== undefined && workInfor.FirstJob !== '') {
                // eslint-disable-next-line no-redeclare
                var FirstJob = workInfor.FirstJob
            }

            if (workInfor.dateAdmissionExamination !== undefined && workInfor.dateAdmissionExamination !== '') {
                // eslint-disable-next-line no-redeclare
                var dateAdmissionExamination = workInfor.dateAdmissionExamination
            }

            if (workInfor.SalaryType !== undefined && workInfor.SalaryType !== '') {
                // eslint-disable-next-line no-redeclare
                var SalaryType = workInfor.SalaryType
            }

            if (workInfor.unemploymentInsurance !== undefined && workInfor.unemploymentInsurance !== '') {
                // eslint-disable-next-line no-redeclare
                var unemploymentInsurance = workInfor.unemploymentInsurance
            }

            if (workInfor.Retiree !== undefined && workInfor.Retiree !== '') {
                // eslint-disable-next-line no-redeclare
                var Retiree = workInfor.Retiree
            }

            if (workInfor.RegistrationWithTheProfessionalBody !== undefined && workInfor.RegistrationWithTheProfessionalBody !== '') {
                // eslint-disable-next-line no-redeclare
                var RegistrationWithTheProfessionalBody = workInfor.RegistrationWithTheProfessionalBody
            }

            if (workInfor.professionalAdvice !== undefined && workInfor.professionalAdvice !== '') {
                // eslint-disable-next-line no-redeclare
                var professionalAdvice = workInfor.professionalAdvice
            }

            if (workInfor.CIPA !== undefined && workInfor.CIPA !== '') {
                // eslint-disable-next-line no-redeclare
                var CIPA = workInfor.CIPA
            }

            if (workInfor.havePointsRecord !== undefined && workInfor.havePointsRecord !== '') {
                // eslint-disable-next-line no-redeclare
                var havePointsRecord = workInfor.havePointsRecord
            }
            if (
                admissionDate                       !== undefined ||
                manager                             !== undefined ||
                dateAdmissionExamination            !== undefined ||
                registration                        !== undefined ||
                email                               !== undefined ||
                admissionType                       !== undefined ||
                formOfPayment                       !== undefined ||
                syndicate                           !== undefined ||
                stability                           !== undefined ||
                positionOfTrust                     !== undefined ||
                FirstJob                            !== undefined ||
                SalaryType                          !== undefined ||
                unemploymentInsurance               !== undefined ||
                Retiree                             !== undefined ||
                RegistrationWithTheProfessionalBody !== undefined ||
                professionalAdvice                  !== undefined ||
                CIPA                                !== undefined ||
                role                                !== undefined ||
                havePointsRecord                    !== undefined
            ) {
                setLoadJobInformation(true)
                if (
                    admissionDate                       !== undefined ||
                    manager                             !== undefined ||
                    email                               !== undefined ||
                    role                                !== undefined ||
                    admissionType                       !== undefined ||
                    dateAdmissionExamination            !== undefined
                ) {
                    await api.put(`/collaborator/${workInfor.collaborator}`, { admissionDate, manager, email, role, dateAdmissionExamination, admissionType, path: props.match.path })
                    .then(response => {

                        setVisibleJobInfor(!visibleJobInfor);
                        setLoadJobInformation(false)
                        loadcollaborator(id);

                        if (
                            registration                        !== undefined ||
                            admissionType                       !== undefined ||
                            formOfPayment                       !== undefined ||
                            syndicate                           !== undefined ||
                            stability                           !== undefined ||
                            positionOfTrust                     !== undefined ||
                            FirstJob                            !== undefined ||
                            SalaryType                          !== undefined ||
                            unemploymentInsurance               !== undefined ||
                            Retiree                             !== undefined ||
                            RegistrationWithTheProfessionalBody !== undefined ||
                            professionalAdvice                  !== undefined ||
                            CIPA                                !== undefined ||
                            role                                !== undefined ||
                            havePointsRecord                    !== undefined
                        ) {
                            ///////////////////////////////////////////////////////////////////////////
                            setLoadJobInformation(true)
                            async function collaboratorworkinforpost() {

                                await api.post('/collaborator-work-information/', workInfor)
                                .then(response => {

                                    setVisibleJobInfor(!visibleJobInfor);
                                    setLoadJobInformation(false)
                                    loadworkinfor(id);
                                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                                }).catch(error => {
                                    swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                    // console.log(error.response.data)
                                    setLoadJobInformation(false);
                                });

                            }
                            collaboratorworkinforpost()

                            ///////////////////////////////////////////////////////////////////////////
                        } else {
                            swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                            setLoadJobInformation(false);
                        }



                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadJobInformation(false);
                        //swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                    });
                } else {
                    if (
                        registration                        !== undefined ||
                        admissionType                       !== undefined ||
                        formOfPayment                       !== undefined ||
                        syndicate                           !== undefined ||
                        stability                           !== undefined ||
                        positionOfTrust                     !== undefined ||
                        FirstJob                            !== undefined ||
                        SalaryType                          !== undefined ||
                        unemploymentInsurance               !== undefined ||
                        Retiree                             !== undefined ||
                        RegistrationWithTheProfessionalBody !== undefined ||
                        professionalAdvice                  !== undefined ||
                        CIPA                                !== undefined ||
                        role                                !== undefined ||
                        havePointsRecord                    !== undefined
                    ) {
                        ///////////////////////////////////////////////////////////////////////////
                        setLoadJobInformation(true)
                        async function collaboratorworkinforpost() {

                            await api.post('/collaborator-work-information/', workInfor)
                            .then(response => {

                                setLoadJobInformation(false)
                                setVisibleJobInfor(!visibleJobInfor);
                                loadworkinfor(id);
                                swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                            }).catch(error => {

                                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                                setLoadJobInformation(false);

                            });

                        }
                        collaboratorworkinforpost();
                        ///////////////////////////////////////////////////////////////////////////
                    } else {
                        swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });
                        setLoadJobInformation(false);
                    }
                }

            } else {
                swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });
                setLoadJobInformation(false)
            }
        }


    }

    const [ managers, setManagers ] = useState([]);

    ///////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (acceptedFiles === undefined) {

                return
            }

            formData.append('file', acceptedFiles[0]);

            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {

                setLoadAvatar(false)
                loadcollaborator(id);
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }



////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/// => Selects personalizado Informações de Trabalho
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////// role

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectManager, setSelectManager] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectRole, setSelectRole] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectAdmissionType, setSelectAdmissionType] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectFormOfPayment, setSelectFormOfPayment] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectSyndicate, setSelectSyndicate] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectStability, setSelectStability] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectPositionOfTrust, setSelectPositionOfTrust] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectFirstJob, setSelectFirstJob] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectUnemploymentInsurance, setSelectUnemploymentInsurance] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectRetiree, setSelectRetiree] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectRegistrationWithTheProfessionalBody, setSelectRegistrationWithTheProfessionalBody] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectProfessionalAdvice, setSelectProfessionalAdvice] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectCIPA, setSelectCIPA] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectHavePointsRecord, setSelectHavePointsRecord] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectSalaryType, setSelectSalaryType] = useState(false);


    const JobInforFution = {

        OpenSelectManager() {
            setSelectManager(!selectManager)
            setSelectRole(false)
            setSelectAdmissionType(false)
            setSelectFormOfPayment(false)
            setSelectSyndicate(false)
            setSelectStability(false)
            setSelectPositionOfTrust(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectSalaryType(false)
        },
        OpenSelectRole() {
            setSelectRole(!selectRole)
            setSelectManager(false)
            setSelectAdmissionType(false)
            setSelectFormOfPayment(false)
            setSelectSyndicate(false)
            setSelectStability(false)
            setSelectPositionOfTrust(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectAdmissionType(){
            setSelectAdmissionType(!selectAdmissionType)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectSyndicate(false)
            setSelectPositionOfTrust(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectFormOfPayment(){
            setSelectFormOfPayment(!selectFormOfPayment)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectSyndicate(false)
            setSelectStability(false)
            setSelectPositionOfTrust(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectSyndicate(){
            setSelectSyndicate(!selectSyndicate)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectStability(false)
            setSelectPositionOfTrust(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectStability(){
            setSelectStability(!selectStability)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectPositionOfTrust(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectPositionOfTrust(){
            setSelectPositionOfTrust(!selectPositionOfTrust)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectFirstJob(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectFirstJob(){
            setSelectFirstJob(!selectFirstJob)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectUnemploymentInsurance(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectUnemploymentInsurance(){
            setSelectUnemploymentInsurance(!selectUnemploymentInsurance)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectRetiree(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectRetiree(){
            setSelectRetiree(!selectRetiree)
            setSelectUnemploymentInsurance(false)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectRegistrationWithTheProfessionalBody(){
            setSelectRegistrationWithTheProfessionalBody(!selectRegistrationWithTheProfessionalBody)
            setSelectRetiree(false)
            setSelectUnemploymentInsurance(false)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectProfessionalAdvice(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectProfessionalAdvice(){
            setSelectProfessionalAdvice(!selectProfessionalAdvice)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectRetiree(false)
            setSelectUnemploymentInsurance(false)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectCIPA(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectCIPA(){
            setSelectCIPA(!selectCIPA)
            setSelectProfessionalAdvice(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectRetiree(false)
            setSelectUnemploymentInsurance(false)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectHavePointsRecord(false)
            setSelectSalaryType(false)
        },
        OpenSelectHavePointsRecord(){
            setSelectHavePointsRecord(!selectHavePointsRecord)
            setSelectCIPA(false)
            setSelectProfessionalAdvice(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectRetiree(false)
            setSelectUnemploymentInsurance(false)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
            setSelectSalaryType(false)
        },
        OpenSelectSalaryType(){
            setSelectSalaryType(!selectSalaryType)
            setSelectHavePointsRecord(false)
            setSelectCIPA(false)
            setSelectProfessionalAdvice(false)
            setSelectRegistrationWithTheProfessionalBody(false)
            setSelectRetiree(false)
            setSelectUnemploymentInsurance(false)
            setSelectFirstJob(false)
            setSelectPositionOfTrust(false)
            setSelectStability(false)
            setSelectSyndicate(false)
            setSelectAdmissionType(false)
            setSelectManager(false)
            setSelectRole(false)
            setSelectFormOfPayment(false)
        },
        closeDependentSelect(event) {
            if(
                event.target.className !== "btn-select selectManager"                               &&
                event.target.className !== "btn-select selectRole"                                  &&
                event.target.className !== "btn-select selectAdmissionType"                         &&
                event.target.className !== "btn-select selectFormOfPayment"                         &&
                event.target.className !== "btn-select selectSyndicate"                             &&
                event.target.className !== "btn-select selectStability"                             &&
                event.target.className !== "btn-select selectPositionOfTrust"                       &&
                event.target.className !== "btn-select selectFirstJob"                              &&
                event.target.className !== "btn-select selectUnemploymentInsurance"                 &&
                event.target.className !== "btn-select selectRetiree"                               &&
                event.target.className !== "btn-select selectRegistrationWithTheProfessionalBody"   &&
                event.target.className !== "btn-select selectProfessionalAdvice"                    &&
                event.target.className !== "btn-select selectCIPA"                                  &&
                event.target.className !== "btn-select selectHavePointsRecord"                      &&
                event.target.className !== "btn-select selectSalaryType"
            ) {
                setSelectManager(false)
                setSelectRole(false)
                setSelectAdmissionType(false)
                setSelectFormOfPayment(false)
                setSelectSyndicate(false)
                setSelectStability(false)
                setSelectPositionOfTrust(false)
                setSelectFirstJob(false)
                setSelectUnemploymentInsurance(false)
                setSelectRetiree(false)
                setSelectRegistrationWithTheProfessionalBody(false)
                setSelectProfessionalAdvice(false)
                setSelectCIPA(false)
                setSelectHavePointsRecord(false)
                setSelectSalaryType(false)
                if (event.target.className === "span-select-selectManager") {
                    setSelectManager(!selectManager)
                    setSelectRole(false)
                    setSelectAdmissionType(false)
                    setSelectFormOfPayment(false)
                    setSelectSyndicate(false)
                    setSelectStability(false)
                    setSelectPositionOfTrust(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
               } else if (event.target.className === "span-select-selectRole"){
                    setSelectRole(!selectRole)
                    setSelectManager(false)
                    setSelectAdmissionType(false)
                    setSelectFormOfPayment(false)
                    setSelectSyndicate(false)
                    setSelectStability(false)
                    setSelectPositionOfTrust(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
               } else if (event.target.className === "span-select-selectAdmissionType"){
                    setSelectAdmissionType(!selectAdmissionType)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectFormOfPayment(false)
                    setSelectSyndicate(false)
                    setSelectStability(false)
                    setSelectPositionOfTrust(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
               } else if (event.target.className === "span-select-selectFormOfPayment"){
                    setSelectFormOfPayment(!selectFormOfPayment)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectSyndicate(false)
                    setSelectStability(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectSyndicate"){
                    setSelectSyndicate(!selectSyndicate)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectStability(false)
                    setSelectPositionOfTrust(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                } else if (event.target.className === "span-select-selectStability"){
                    setSelectStability(!selectStability)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectPositionOfTrust(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectPositionOfTrust"){
                    setSelectPositionOfTrust(!selectPositionOfTrust)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectFirstJob(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                } else if (event.target.className === "span-select-selectFirstJob"){
                    setSelectFirstJob(!selectFirstJob)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                } else if (event.target.className === "span-select-selectUnemploymentInsurance"){
                    setSelectUnemploymentInsurance(!selectUnemploymentInsurance)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectRetiree(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectRetiree"){
                    setSelectRetiree(!selectRetiree)
                    setSelectUnemploymentInsurance(false)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectRegistrationWithTheProfessionalBody"){
                    setSelectRegistrationWithTheProfessionalBody(!selectRegistrationWithTheProfessionalBody)
                    setSelectRetiree(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectProfessionalAdvice(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectProfessionalAdvice"){
                    setSelectProfessionalAdvice(!selectProfessionalAdvice)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectRetiree(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectCIPA(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectCIPA"){
                    setSelectCIPA(!selectCIPA)
                    setSelectProfessionalAdvice(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectRetiree(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectHavePointsRecord(false)
                    setSelectSalaryType(false)
                }  else if (event.target.className === "span-select-selectHavePointsRecord"){
                    setSelectHavePointsRecord(!selectHavePointsRecord)
                    setSelectCIPA(false)
                    setSelectProfessionalAdvice(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectRetiree(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                    setSelectSalaryType(false)
                } else if (event.target.className === "span-select-selectSalaryType"){
                    setSelectSalaryType(!selectSalaryType)
                    setSelectHavePointsRecord(false)
                    setSelectCIPA(false)
                    setSelectProfessionalAdvice(false)
                    setSelectRegistrationWithTheProfessionalBody(false)
                    setSelectRetiree(false)
                    setSelectUnemploymentInsurance(false)
                    setSelectFirstJob(false)
                    setSelectPositionOfTrust(false)
                    setSelectStability(false)
                    setSelectSyndicate(false)
                    setSelectFormOfPayment(false)
                    setSelectAdmissionType(false)
                    setSelectManager(false)
                    setSelectRole(false)
                }
            }
        }
    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                id={id}
                collaborator={collaborator}
                />
                <InforProfile
                collaborator={collaborator}
                id={id}
                path={props.match.path}
                registeredCollaborator={registeredCollaborator}
                loadAvatar={loadAvatar}
                handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                role={role}
                loadcollaborator={loadcollaborator}
                />
                <SubHeader
                id={id}
                collaborator={collaborator}
                role={role}
                />
                <Professional
                id={id}
                path={props.match.path}
                loadPage={loadPage}
                collaborator={collaborator}
                loadPageJobInformation={loadPageJobInformation}
                loadPage2Collaborator={loadPage2Collaborator}
                loadAdmissionDate={loadAdmissionDate}
                loadJobInformation={loadJobInformation}
                workInfor={workInfor}
                collaboratorInfor={collaboratorInfor}
                registeredWorkInfor={registeredWorkInfor}
                registeredCollaborator={registeredCollaborator}
                AddAndJobInformationVisible={AddAndJobInformationVisible}
                myChangeHandlerJobInfor={myChangeHandlerJobInfor}
                handleSubmitJobInfor={handleSubmitJobInfor}
                managers={managers}
                visibleJobInfor={visibleJobInfor}
                role={role}
                JobInforFution={JobInforFution}

                selectManager={selectManager}
                selectRole={selectRole}
                selectAdmissionType={selectAdmissionType}
                selectFormOfPayment={selectFormOfPayment}
                selectSyndicate={selectSyndicate}
                selectStability={selectStability}
                selectPositionOfTrust={selectPositionOfTrust}
                selectFirstJob={selectFirstJob}
                selectUnemploymentInsurance={selectUnemploymentInsurance}
                selectRetiree={selectRetiree}
                selectRegistrationWithTheProfessionalBody={selectRegistrationWithTheProfessionalBody}
                selectProfessionalAdvice={selectProfessionalAdvice}
                selectCIPA={selectCIPA}
                selectHavePointsRecord={selectHavePointsRecord}
                selectSalaryType={selectSalaryType}


                setWorkInfor={setWorkInfor}
                />


            </Layout>
        </>
    );
}
