import React from 'react';
// import IconsInformation from '../../IconsInformation';
import HeaderAdmin from '../../HeaderAdmin';
import IconADD from '../../../assets/images/add-branco.svg';

export default function headerDepartamentos({
    history,
    departamentos,
    CadastraDepartamento,
    name,
    loadfiltersectors,
    user,
    loadPageDepartamento
}) {
        return (
            <>
            <HeaderAdmin>
                <div>
                    <h1>Departamentos</h1>
                    <p>
                        Cadastre e gerencie os departamentos da sua empresa. {
                            loadPageDepartamento ? "":
                            departamentos && departamentos.length === 0 ? 'Nenhum departamento cadastrado' :
                            departamentos.length+ ' departamentos cadastrados'
                        }
                    </p>
                </div>
                <div className='departamentos'>
                    <button  type="button" className="btnGreen more cadastrar-departamento" onClick={() => CadastraDepartamento()}>Cadastrar novo departamento</button>
                    <form action="">
                        <input name="name" value={name} onChange={loadfiltersectors} type="text" autocomplete="off" placeholder="Filtre por departamento" className="campo-busca"/>
                    </form>
                </div>
            </HeaderAdmin>

           

        <div className='PgDepartamentoHeader'>
            <button className="btnGreen more cadastrar-departamento" onClick={() => CadastraDepartamento()}>Cadastrar novo departamento</button>
            <button className="cadastrar-departamento-mb" onClick={() => CadastraDepartamento()}><img src={IconADD} alt="add" /></button>
            <form action="">
                <input name="name" value={name} onChange={loadfiltersectors} type="text" autocomplete="off" placeholder="Filtre por departamento" className="campo-busca"/>
            </form>
        </div>   
            </>
        );
}
