import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import swal from 'sweetalert';
import api from '../../../services/api';
import { NavLink } from 'react-router-dom'
// import axios from 'axios';
import { mCPFmCNPJ,phones } from '../../../services/mask';
import './styles.scss';
export default function pageSystemConfigAccoutants(props, path) {

    const { id } = props.match.params

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ accountants, setAccountants] = useState([]);

   // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {

    // setLoadPagePaymentMethod(true)
    async function loadaccountants() {
        await api.get(`/accounting/${id}`)
        .then(response => {
            setAccountants(response.data);
        }).catch(error => {})
        }
        loadaccountants();
    }, [id]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButton, setLoadButton] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameSave, setLoadNameSave] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPhoneSave, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmailSave, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ editAccounting, setEditAccounting] = useState([]);

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "CNPJ") {
            let CNPJAqui = mCPFmCNPJ(val)

            if(val.length === 0) {

            }
            setEditAccounting({ ...editAccounting, [nam]: CNPJAqui })
            // if(val.length >= 14) {
            //     // setLoadcpf(false)
            // }
        } else if (nam === "name") {
            if(val.length === 0) {
                setLoadNameSave(true)
                setEditAccounting({ ...editAccounting, [nam]: val })
            } else {
                setEditAccounting({ ...editAccounting, [nam]: val })
                setLoadNameSave(false)

            }
        }
        else if (nam === "phone") {
            let phonesAqui = phones(val)
            setEditAccounting({ ...editAccounting, [nam]: phonesAqui })
        }
        else {
            setEditAccounting({ ...editAccounting, [nam]: val })
        }
    }

    async function EditAccounttant() {

        if((editAccounting && editAccounting.length === 0)
        ) {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });
        }
        else {
            // const formData = new FormData();
            // const config = {
            //     headers: {
            //         'content-type': 'multipart/form-data'
            //     }
            // }

            if(
                (editAccounting                                    &&
                editAccounting.name                !== undefined   &&
                editAccounting.name                !== '')          ||
                (editAccounting.email                !== undefined  &&
                editAccounting.email                !== '')         ||
                (editAccounting.CNPJ                !== undefined  &&
                editAccounting.CNPJ                !== '')         ||
                (editAccounting.phone                !== undefined  &&
                editAccounting.phone                !== '')
            ) {
                setLoadButton(true)
                await api.put(`/accounting/${id}`, editAccounting)
                .then(response => {
                    setLoadButton(false)
                    swal({ icon: "success", title: "Sucesso!", text: "Contador editado com sucesso." });
                    window.location.href = '/configuracao/contadores';

                }).catch(error => {
                    setLoadButton(false)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });

                });
            }
        }
    }
    console.log(editAccounting)

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-cadastrar-contador">
                    <h5 className="titulo">Editar contador</h5>
                    <NavLink to={`/configuracao/contadores`} className="voltar">
                        Voltar para lista
                    </NavLink>
                    <div className="formulario">
                        <ul>
                            <li>
                                <p className="texto">Nome completo*</p>
                                <div>

                                {
                                    <input type="text" name="name" placeholder="Nome completo "  value={
                                        (editAccounting &&
                                        editAccounting.name) ||
                                        editAccounting.name === '' ?
                                        editAccounting.name
                                        :
                                        accountants &&
                                        accountants.name !== undefined ?
                                        accountants.name
                                        :
                                        ''
                                        } onChange={myChangeHandler} className={loadNameSave ? 'inputErro' : ''}/>
                                }
                                    {loadNameSave? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">Email*</p>
                                <div>
                                    {
                                        <input type="text" name="email" placeholder="Email" value={
                                            (editAccounting &&
                                            editAccounting.email) ||
                                            editAccounting.email === '' ?
                                            editAccounting.email
                                            :
                                            accountants &&
                                            accountants.email !== undefined ?
                                            accountants.email
                                            :
                                            ''
                                        } onChange={myChangeHandler} className={loadEmailSave ? 'inputErro' : ''}/>
                                    }
                                    {loadEmailSave? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">Telefone*</p>
                                <div>
                                    {
                                        <input type="text" name="phone" value={
                                            (editAccounting &&
                                            editAccounting.phone) ||
                                            editAccounting.phone === '' ?
                                            editAccounting.phone
                                            :
                                            accountants &&
                                            accountants.phone !== undefined ?
                                            accountants.phone
                                            :
                                            ''
                                        } placeholder="Telefone" onChange={myChangeHandler} className={loadPhoneSave ? 'inputErro' : ''}  />
                                    }
                                    {loadPhoneSave? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">CPF/CNPJ</p>
                                <div>
                                    {
                                        <input
                                        type="text"
                                        name="CNPJ" maxlength="18"
                                        value={
                                            (
                                            editAccounting             &&
                                            editAccounting.CNPJ
                                            )                          ||
                                            editAccounting.CNPJ === '' ?
                                            editAccounting.CNPJ
                                            :
                                            accountants               &&
                                            accountants.CNPJ !== undefined ?
                                            accountants.CNPJ
                                            :
                                            ""
                                        }
                                    placeholder="CPF/CNPJ (Opcional)"
                                    onChange={myChangeHandler}
                                    />

                                    }
                                </div>
                            </li>
                            <li>
                                <p></p>
                                <button onClick={EditAccounttant} className={loadButton ? "btnGreen load" : "btnGreen"} >Salvar</button>
                            </li>
                        </ul>

                    </div>


                </div>
            </Layout>
        </>
    );
}
