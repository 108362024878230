import React  from 'react';
import { NavLink } from 'react-router-dom'
import HeaderAdmin from '../../HeaderAdmin';

export default function HeaderFolhaDePagamentoSubmit({
    payroll,
}) 
{
    return (
        <HeaderAdmin>
            <div>
                <h1>
                    <NavLink to={`/folha-de-pagamento/ano/mes/tipo/${payroll._id}`} className="voltar">Voltar</NavLink>
                    {payroll && payroll.month !== undefined ? payroll.month : ""} {payroll && payroll.year !== undefined ? payroll.year : ""} - {payroll && payroll.type !== undefined ? payroll.type : ""}
                </h1>
                <p>Adicione os holerites aos colaboradores. Formato aceito: pdf.</p>
            </div>
        </HeaderAdmin>
    );
}
