import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import Carregamento from '../../../assets/images/carregando.gif';
import apiV2 from '../../../services/apiV2';
import { toast } from 'react-toastify';

const TabReceipts = ({ id})  => {

const [ loadPage, setLoadPage ] = useState(false);
const [ documents, setDocuments ] = useState([]);

async function loadDocuments(event) {

    //RSM Todo: Implemetar filtros por mes e ano
    console.log('colaboratorID -> ', id);
    setLoadPage(true)
    await apiV2.get(`/payroll-document?pageable=false&collaborator=${id}&select[0]=-files.base64&populate[0]=payroll&select[1]=-payroll.allPayrollDocumentBase64`)
    .then(response => {

            setLoadPage(false)
            console.log('response -> ', response.data.items);
            setDocuments(response.data.items);
            
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    })        
}

useEffect(() => {
    loadDocuments();
}, []);

async function downloadPaycheck(e) {
    setLoadPage(true);
    try{
        console.log('payload====', `/payroll-document/${e.target.id}?select[0]=files`)
        await apiV2.get(`/payroll-document/${e.target.id}?select[0]=files`)
        .then(response => {
            if (response.data.files !== undefined){
                const downloadLink = document.createElement("a");
                downloadLink.href = response.data.files[0].base64;
                downloadLink.download = response.data.files[0].name;
                downloadLink.click();
                toast.success("Sucesso! Arquivo solicitado está iniciando o seu download.", { position: toast.POSITION.BOTTOM_RIGHT  });
            }else{
                toast.error("O arquivo está corrompido, favor solicitar ao responsável para substituir o mesmo.", { position: toast.POSITION.BOTTOM_RIGHT  });
            }
        }).catch(error => {
            toast.error("Não foi possível baixar o arquivo solicitado!", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    finally{
        setLoadPage(false);
    }
}


return (
            <>
                <SubMenu/>
                <div className="box-infor-perfil box-infor-playroll user">
                    <div className="box">
                    <div className="buscaAdd">
                            <form action="">
                                <input type="text" onChange={(event) => loadDocuments(event)} placeholder="Procure pelo mês e ano" className="busca"/>
                            </form>
                        </div>
                        <table>
                            <thead>
                                <tr className="tabelaTR">
                                    <th className="inten-1">Período</th>
                                    <th className="inten-2">Tipo</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                loadPage ?
                                    (
                                    <>
                                        <div className="carregamento">
                                            <img alt="" src={Carregamento} />
                                        </div>
                                    </>
                                    )
                                :documents.length === 0 ?
                                    (<div className="nenhum-cadastrado-perfil"><p>Nenhum documento cadastrado.</p></div>)
                                :documents !== undefined ? 
                                    documents.map((documents, index) => (
                                    <>
                                    <tr className="tabelaTR">
                                        <td className="inten-1">
                                            <div className="motivo">
                                                <strong> { documents.payroll.year } </strong>
                                                <span>{documents.payroll.month}</span>
                                            </div>
                                        </td>
                                        <td className="inten-2">
                                            {documents.payroll.type}
                                        </td>
                                        <td className="inten-3">
                                                <button className="download-file" onClick={downloadPaycheck} id={documents._id}>Download</button>
                                        </td>
                                    </tr>
                                </>
                                )) : (
                                    <>
                                    </>
                                )}
                            </tbody>
                        </table>
                        </div>
                </div>
            </>
        );
}
export default TabReceipts;
