import React from 'react';
import api from '../../../services/api';
import { decodeToken } from '../../../services/auth';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Ferias/Header-S-Ferias';
import BoxInfor from '../../../components/Administrativo/Ferias/solicitcao-ferias/box-infor';
import Solicitacao from '../../../components/Administrativo/Ferias/solicitcao-ferias/solicitacao';
import './styles.scss';


export default function ColBeneficio(props) {

    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;

    return (
        <>
            <Layout path={props.match.path} className="bg-pagina-solicitar-ferias">
                <div className="pagina-solicitar-ferias">
                    <Header
                    />
                    <div className="box-solitacao">
                        <BoxInfor
                        api={api}
                        // collaboratorVacationInfo={collaboratorVacationInfo}
                        id={id}
                        />
                        <Solicitacao
                        id={id}
                        role={role}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}

