import React from 'react';
import iconVerMais from '../../../../assets/images/more.svg';
// import FotoEmpresa from '../../../../assets/images/jobs.jpeg';
import { NavLink } from 'react-router-dom'
import { Dropdown } from "react-bootstrap";


export default function ListCompanies({
    comapanyData,
    costCenterData,
    updateCostCenter,
}) {

    function editCompany() {
        window.location.href = `/configuracao/dados-da-empresa/`;
    }

    return (
        <>
        <ul className="lista-empresas">
            {
                comapanyData &&
                comapanyData !== undefined ?
                <>
                <li className="ativado">
                    <Dropdown  className="dropdown-icon">
                        <Dropdown.Toggle>
                        <img alt="" src={iconVerMais} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <button onClick={editCompany} type="button" name="Editar-empresa" >
                                Editar
                            </button>
                        </Dropdown.Menu>
                    </Dropdown>
                    <img src={comapanyData.logo} alt={comapanyData.name} className="foto-empresa"/>
                    <h3 className="nome">{comapanyData.name}</h3>
                    <p className="sub-nome">{comapanyData.nameDescription}</p>
                    <p className="cnpj">CNPJ: {comapanyData.cnpj}</p>
                    <h4 className="sub-titulo">Endereço</h4>
                    <p className="rua">{comapanyData.address}, {comapanyData.addressNumber}</p>
                    <p className="estado">{comapanyData.addressCity} - {comapanyData.addressState}</p>
                    <p className="cep">CEP: {comapanyData.zipcode}</p>
                </li>
                </>
                :
                ''
            }
            {
                costCenterData &&
                costCenterData.length !== 0 ?
                costCenterData.map((costcenter, index) => (
                    <li key={index}>
                        <Dropdown  className="dropdown-icon">
                            <Dropdown.Toggle>
                            <img alt="" src={iconVerMais} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                            <NavLink to={`/configuracao/editar-empresa/${costcenter._id}`} className="add-empresa">
                            Editar
                            </NavLink>
                                {/* <button type="button" name="Exclui-empresa" className="red">
                                    Remover
                                </button> */}
                            </Dropdown.Menu>
                        </Dropdown>
                        <img src={costcenter.location && costcenter.location ? costcenter.location : 'https://res-5.cloudinary.com/hireclub/image/upload/default_avatar.png'} alt={costcenter.name && costcenter.name ? costcenter.name : ''} className="foto-empresa"/>
                        <h3 className="nome">{costcenter.name && costcenter.name ? costcenter.name : ''}</h3>
                        <p className="sub-nome">{costcenter.nameDescription && costcenter.nameDescription ? costcenter.nameDescription : ''}</p>
                        <p className="cnpj">CNPJ: {costcenter.cnpj && costcenter.cnpj ? costcenter.cnpj : ''}</p>
                        <h4 className="sub-titulo">Endereço</h4>
                        <p className="rua">{costcenter.address && costcenter.address ? costcenter.address : ''}, {costcenter.addressNumber && costcenter.addressNumber ? costcenter.addressNumber : ''}</p>
                        <p className="estado">{costcenter.addressCity && costcenter.addressCity ? costcenter.addressCity : ''} - {costcenter.addressState && costcenter.addressState ? costcenter.addressState : ''}</p>
                        <p className="cep">CEP: {costcenter.zipcode && costcenter.zipcode ? costcenter.zipcode : ''}</p>
                    </li>
                )):''
            }
        </ul>
        </>
    );
}

