import React, { useState, useEffect } from 'react';

import Layout from '../../../../components/Layouts/default';

import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import AddressesContacts from '../../../../components/Administrativo/Colaboradores/profile/Personal/AddressesContacts';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import './styles.scss';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { FormataStringData } from "../../../../services/utils";
import { phones, cep, mNumber} from '../../../../services/mask';
import { decodeToken } from '../../../../services/auth';
import { toast } from 'react-toastify';
import axios from 'axios';

export default function ColProfilePersonal(props) {

    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) {

            loadcollaborator(id);
            loadfilessavedAddress(id);

        }

    }, [id]);


    async function loadcollaborator(id) {

        setLoadPage(true)
        await api.get(`/collaborator/${id}`)
        .then(response => {

            setLoadPage(false)
            setCollaboratorUpdate([]);
            setCollaborator(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    const  myChangeHandler = event => {
         let nam = event.target.name;
         let val = event.target.value;
         console.log(val)

         if (nam === "zipcode") {
            getAddress(val)
            let zipcode = cep(val)
            if(val.length >= 8) {
                Loadsetzipcode(false)
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: zipcode })
         } else if (nam === "dateOfBirth") {
             let valDate = FormataStringData(val)
             setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valDate })
         }else if (nam === "addressNumber") {

            let addressNumber = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: addressNumber })

        }  else if (nam === "phone") {

            // Loadsetphone(false)
            let phone = phones(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: phone })

        }   else if (nam === "cellphone") {
            // Loadsetcellphone(false)
            if(val.length >= 14) {
                Loadsetcellphone(false)
            }
            let cellphone = phones(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cellphone })

        }  else if (nam === "personalEmail") {
            // Loadsetcellphone(false)
            let personalEmail = val
            console.log(val)
            if(val.length >= 10) {
                Loadsetemail(false)
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: personalEmail })
        }
         else {
             setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
         }

    };

    async function getAddress(zipcode) {
        zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        if (zipcode.length >= 8) {
            //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
            const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

            if (response.data !== null) {
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })
            }
        }
    }


      //////////////////////////////////////////////////////////////////////////////////
     /////////////////////////////   ENDEREÇOS E CONTATOS   ///////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAddress, setLoadAddress ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesAddress] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedAddress, setFilesSavedAddress] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileLocationSend, setFileLocationSend] = useState(false);

    async function loadfilessavedAddress(id) {

        await api.get(`/collaborator-file/?id=${id}&category=location`)
        .then(response => {

            setLoadAddress(false)
            setFilesSavedAddress(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function handleDropAddress(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNamesAddress(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'location');
            formData.append('collaborator', collaborator._id);
            formData.append('path', props.match.path);

            setLoadAddress(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {

                setFileLocationSend(true)
                loadfilessavedAddress(id);

            }).catch(error => {});
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    async function deleteFileAddress(filesaved) {

        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoadAddress(true)
                await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
                .then(() => {

                    setFileLocationSend(true);
                    loadfilessavedAddress(id);

                }).catch(() => {});
            }
        });

    }
      //////////////////////////////////////////////////////////////////////////////////
     /////////////////////////////   FINAL ENDEREÇOS E CONTATOS   /////////////////////
    //////////////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleAddress, setVisibleAddress] = useState(false);
    function PopupAddressVisible() {
        setVisibleAddress(!visibleAddress);
        setCollaboratorUpdate([])
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleContact, setVisibleContact] = useState(false);
    function PopupContactVisible() {
      setVisibleContact(!visibleContact);
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ Loadzipcode                , Loadsetzipcode                    ] = useState(false);
    const [ LoadaddressNeighborhood            ] = useState(false);
    const [ LoadaddressCity                            ] = useState(false);
    const [ LoadaddressState                          ] = useState(false);
    const [ LoadaddressNumber          , LoadsetaddressNumber              ] = useState(false);
    // const [ Loadphone                  , Loadsetphone                      ] = useState(false);
    const [ Loadcellphone              , Loadsetcellphone                  ] = useState(false);
    const [ Loademail              , Loadsetemail                  ] = useState(false);
    // const [ Loademail                                        ] = useState(false);



    // console.log(collaboratorUpdate)
    async function saveContact(event) {
        event.preventDefault();

            if(collaboratorUpdate === undefined || collaboratorUpdate.length === 0) {
                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });
            } else if(
                collaboratorUpdate.cellphone === "" ||
                collaboratorUpdate.personalEmail === ""
            ){
                // alert("entrou 2")
                swal({ icon: "error", title: "Erro!", text: "Somente o campo telefone pode ser salvo em branco" });
            }
            else {
                const formData     = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if(collaboratorUpdate.phone && collaboratorUpdate.phone !== "") {
                    formData.append('phone', collaboratorUpdate.phone);
                }

                if ( collaboratorUpdate.cellphone && collaboratorUpdate.cellphone !== undefined) {
                    formData.append('cellphone', collaboratorUpdate.cellphone);
                    Loadsetcellphone(false)
                } else if (collaborator.cellphone && collaborator.cellphone !== undefined) {
                    formData.append('cellphone', collaborator.cellphone);
                    Loadsetcellphone(false)
                } else {
                    Loadsetcellphone(true)
                    return
                }
                if ( collaboratorUpdate.personalEmail && collaboratorUpdate.personalEmail !== undefined) {
                    formData.append('personalEmail', collaboratorUpdate.personalEmail);
                    Loadsetemail(false)
                } else if (collaborator.personalEmail && collaborator.personalEmail !== undefined) {
                    formData.append('personalEmail', collaborator.personalEmail);
                    Loadsetemail(false)
                } else {
                    Loadsetemail(true)
                    return
                }
                formData.append('path', props.match.path);
                setLoadContinue(true)
                await api.put(`/collaborator/${id}`, formData, config)
                .then(response => {

                    setLoadContinue(false)
                    setCollaboratorUpdate([]);
                    setVisibleContact(!visibleContact);
                    loadcollaborator(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });

                }).catch(error => {

                    swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });

                });
            }
    }

    async function saveAddress(event) {
        event.preventDefault();
            if(collaboratorUpdate === undefined || collaboratorUpdate.length === 0) {
                if(fileLocationSend && fileLocationSend === true) {
                    swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
                    setFileLocationSend(false)
                    setVisibleAddress(!visibleAddress);
                } else {
                    swal({ icon: "error", title: "Erro!", text: 'Necessário atualizar pelo menos um campo!' });
                }
            }
            else
            if(
                collaboratorUpdate.zipcode               ===     "" ||
                collaboratorUpdate.address               ===     "" ||
                collaboratorUpdate.addressNeighborhood   ===     "" ||
                collaboratorUpdate.addressCity           ===     "" ||
                collaboratorUpdate.addressState          ===     ""
            ) {

                swal({ icon: "error", title: "Erro!", text: 'Apenas o campo número pode ser salvo em branco!' });
            }
            else {

                const formData     = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(collaboratorUpdate.zipcode && collaboratorUpdate.zipcode !== undefined) {
                    formData.append('zipcode', collaboratorUpdate.zipcode);
                    Loadsetzipcode(false)
                }

                if(collaboratorUpdate.address !== undefined) {
                    formData.append('address', collaboratorUpdate.address);
                }

                if(collaboratorUpdate.addressNumber !== undefined || collaboratorUpdate.addressNumber === "") {

                    if(collaboratorUpdate.addressNumber === "") {
                        formData.append('addressNumber', '-');
                        LoadsetaddressNumber(false)
                    } else {
                        formData.append('addressNumber', collaboratorUpdate.addressNumber);
                        LoadsetaddressNumber(false)
                    }

                }

                if(collaboratorUpdate.addressNeighborhood !== undefined) {
                    formData.append('addressNeighborhood', collaboratorUpdate.addressNeighborhood);
                }

                if(collaboratorUpdate.addressCity !== undefined) {
                    formData.append('addressCity', collaboratorUpdate.addressCity);
                }

                if(collaboratorUpdate.addressState !== undefined) {
                    formData.append('addressState', collaboratorUpdate.addressState);
                }

                if(collaboratorUpdate.addressComplement !== undefined) {
                    formData.append('addressComplement', collaboratorUpdate.addressComplement);
                }

                formData.append('path', props.match.path);

                await api.put(`/collaborator/${id}`, formData, config)
                .then(response => {

                    setLoadContinue(false)
                    loadcollaborator(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
                    setVisibleAddress(!visibleAddress);

                }).catch(error => {

                    swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });

                });
            }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (acceptedFiles === undefined) {

                return
            }

            formData.append('file', acceptedFiles[0]);
            formData.append('path', props.match.path);

            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {

                setLoadAvatar(false)
                loadcollaborator(id);
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }


    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                id={id}
                />
                <InforProfile
                collaborator={collaborator}
                id={id}
                path={props.match.path}
                loadAvatar={loadAvatar}
                handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                role={role}
                />
                <SubHeader
                id={id}
                path={props.match.path}
                collaborator={collaborator}
                role={role}
                />
                <AddressesContacts
                path={props.match.path}
                loadPage={loadPage}
                id={id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                saveContact={saveContact}
                saveAddress={saveAddress}
                loadContinue={loadContinue}
                PopupAddressVisible      ={PopupAddressVisible}
                visibleAddress           ={visibleAddress}
                PopupContactVisible       ={PopupContactVisible}
                visibleContact           ={visibleContact}
                Loadzipcode              ={Loadzipcode            }
                LoadaddressNeighborhood  ={LoadaddressNeighborhood}
                LoadaddressCity          ={LoadaddressCity        }
                LoadaddressState         ={LoadaddressState       }
                LoadaddressNumber        ={LoadaddressNumber      }
                // Loadphone                ={Loadphone              }
                Loadcellphone            ={Loadcellphone          }
                Loademail                ={Loademail              }
                loadAddress={loadAddress}
                filesSavedAddress={filesSavedAddress}
                handleDropAddress={handleDropAddress}
                deleteFileAddress={deleteFileAddress}
                role={role}
                />
            </Layout>
        </>
    );
}

