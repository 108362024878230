import React, {useEffect, useState} from 'react';

import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import SubHeaderTalents from '../../components/RecruitmentAndSelection/subHeaderTalents';
import GeneralInformation from '../../components/RecruitmentAndSelection/talents/generalInformation';
import FavoriteFolders from '../../components/RecruitmentAndSelection/talents/favoriteFolders';
import LatestCandidates from '../../components/RecruitmentAndSelection/talents/latestCandidates';
import ModalAddFolder from '../../components/RecruitmentAndSelection/talents/modalAddFolder';
import { toast } from 'react-toastify';

import api from '../../services/api';

import swal from 'sweetalert';

import './styles.scss';


export default function Talents(props) {

    const { pathname } = props.location;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadLaborBond, setLoadLaborBond ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sortByCandidate, setSortBy ] = useState('name');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadNumberOfTheCandidate();
        loadNumberOfTheFolderFavorite();
        loadCounLast24Hours();
        loadSectorCandidateMORE_COMMON();
        loadSectorCandidateLESS_COMMON();
        loadAgeCandidateMostCandidate();
        loadAllFolderAtivos();
        loadsFavoriteFolders();

        loadsLatestCandidates(loadLaborBond, sortByCandidate);
        loadAllFolder();

    }, [loadLaborBond, sortByCandidate]);

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModalAddFolder, setVisibleModalAddCandite] = useState(false);

    // => STATES // => STATES // => STATES // => STATES // => STATES // => STATES

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ latestCandidates, setLatestCandidates] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadLatestCandidates, setLoadLatestCandidates ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ folderFavorite, setFolderFavorite] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFolderFavorite, setLoadFolderFavorite ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadGeneralInformation, setLoadGeneralInformation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadNameFolder, setLoadNameFolder ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [btnFilterMarkAsFavorite, setBtnFilterMarkAsFavorite] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setBtnFilterAsFavoriteClicked] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidateFilterForThisFolder, setCandidateFilterForThisFolder ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nameSearch, setNameSearch] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidates, setCandidates ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setFolderAllCount ] = useState([]);


    // => GENERATOR INFORMATION // => GENERATOR INFORMATION// => GENERATOR INFORMATION

    // => Count Candidate
    async function loadNumberOfTheCandidate() {
        setLoadGeneralInformation(true)
        await api.get('/count-candidate')
        .then(response => {
            setLoadGeneralInformation(false)
            localStorage.setItem('@peopleview/countCandidatesActives', response.data);
            // setCountCandidate(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const countCandidate = localStorage.getItem('@peopleview/countCandidatesActives');

    // => Count Folder
    async function loadNumberOfTheFolderFavorite() {
        setLoadGeneralInformation(true)
        await api.get('/folder-all-count/?sortBy=COUNT&status=active')
        .then(response => {
            setLoadGeneralInformation(false)
            localStorage.setItem('@peopleview/countFolderFavoriteActives', response.data);
            // setCountCandidate(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const countFolderFavorite = localStorage.getItem('@peopleview/countFolderFavoriteActives');

    // => Count Folder
    async function loadCounLast24Hours() {
        setLoadGeneralInformation(true)
        await api.get('/count-candidate-last-24-hours/')
        .then(response => {
            setLoadGeneralInformation(false)
            localStorage.setItem('@peopleview/counLast24Hours', response.data);
            // setCountCandidate(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const counLast24Hours = localStorage.getItem('@peopleview/counLast24Hours');

    // => SectorCandidateMORE_COMMON
    async function loadSectorCandidateMORE_COMMON() {
        setLoadGeneralInformation(true)
        await api.get('/sector-candidate-common?sortBy=MORE_COMMON')
        .then(response => {
            setLoadGeneralInformation(false)
            localStorage.setItem('@peopleview/SectorCandidateMORE_COMMON', response.data);
            // setCountCandidate(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const SectorCandidateMORE_COMMON = localStorage.getItem('@peopleview/SectorCandidateMORE_COMMON');

    // => SectorCandidateLESS_COMMON
    async function loadSectorCandidateLESS_COMMON() {
        setLoadGeneralInformation(true)
        await api.get('/sector-candidate-common?sortBy=LESS_COMMON')
        .then(response => {
            setLoadGeneralInformation(false)
            localStorage.setItem('@peopleview/SectorCandidateLESS_COMMON', response.data);
            // setCountCandidate(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const SectorCandidateLESS_COMMON = localStorage.getItem('@peopleview/SectorCandidateLESS_COMMON');

    //
    // => ageCandidateMostCandidate
    async function loadAgeCandidateMostCandidate() {
        setLoadGeneralInformation(true)
        await api.get('/age-candidate-most-common')
        .then(response => {
            setLoadGeneralInformation(false)
            localStorage.setItem('@peopleview/ageCandidateMostCandidate', response.data);
            // setCountCandidate(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const ageCandidateMostCandidate = localStorage.getItem('@peopleview/ageCandidateMostCandidate');


    // MODAL OPEN FOLDER

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inforFolder, setInforFolder ] = useState('');

    async function modalAddFolderOpen() {
        setInforFolder("")
        setVisibleModalAddCandite(!visibleModalAddFolder);
    }
    async function modalEditFolderOpen(e) {
        setInforFolder(e)
        setAllCandidateExclude("")
        setVisibleModalAddCandite(!visibleModalAddFolder);

        if(e !== 'e') {

            if(e?.favorite === true) {
                setBtnFilterAsFavoriteClicked(false)
            }
            if(e?.favorite === false) {
                setBtnFilterAsFavoriteClicked(true)
            }

            let _id             = e._id;
            let name            = e?.name;
            let favorite        = e?.favorite;
            let candidatesSAved = e?.candidate;


            const itensCopyCollaborators = Array.from(candidates);
            if(candidatesSAved.length !== 0) {

                for(let c=0; c < candidatesSAved.length; c++){

                    let _idCandidate    =  candidatesSAved[c]._id;
                    let nameCandidate   =  candidatesSAved[c].name;
                    let sectorCandidate =  candidatesSAved[c].sector.name;

                    itensCopyCollaborators.push({
                        idcollaborator: candidates.length,
                        name          : nameCandidate,
                        sector        : sectorCandidate,
                        _id           : _idCandidate
                    });
                    setCandidates(itensCopyCollaborators);

                }

            }

            setInforFolder({ ...inforFolder, _id, name, favorite, candidate: itensCopyCollaborators, edit: true });
            setVisibleModalAddCandite(!visibleModalAddFolder);

        }
        else {
            setInforFolder([])
            setVisibleModalAddCandite(!visibleModalAddFolder);
        }

    }

    // => MODAL FOLDER
    async function modalAddFolderHide() {

        setVisibleModalAddCandite(false);
        setBtnFilterMarkAsFavorite(false);
        setNameSearch('');
        setCandidateFilterForThisFolder([]);
        setCandidates([]);
        setLoadNameFolder(false);

    }

    // => EXCLUDE CANDIDATE IN FOLDER

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allCandidateExclude, setAllCandidateExclude] = useState([])



    // INACTIVE FOLDER
    async function inactiveFolder(_id, nameFolder) {

        setLoadFolderFavorite(true)
        swal({
            title: "Atenção",
            text: `Deseja realmente excluir a pasta ${nameFolder}?`,
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {

                await api.put(`/folder/${_id}`, { status: 'inactive' })
                .then(response => {

                    setLoadFolderFavorite(false);
                    loadsFavoriteFolders();
                    loadNumberOfTheFolderFavorite();
                    setCandidates([])
                    swal({ icon: "success", title: "Sucesso!", text: "Pasta de talentos excluída com sucesso!" });

                }).catch(error => {
                    setLoadFolderFavorite(false);
                    if(error?.response?.data?.message) {
                        swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    }
                    else {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao excluir pasta de talentos, tente novamente mais tarde." });
                    }
                });

            }
            setLoadFolderFavorite(false)
        })

    }

    // => Count Folder
    async function loadAllFolder() {
        await api.get('/folder-all-count/?status=active&favorite=true')
        .then(response => {
            setFolderAllCount(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    // => Count Folder
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ folderAtivos, setFolderAtivos] = useState([]);

    async function loadAllFolderAtivos() {
        await api.get('/folder-all-count/?status=active')
        .then(response => {
            setFolderAtivos(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////

    async function loadsFavoriteFolders() {
        setLoadFolderFavorite(true)
        await api.get('/folder-favorite-all-count/?status=active&favorite=true')
        .then(response => {
            setLoadFolderFavorite(false)
            setFolderFavorite(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // => LAST CANDIDATES // => LAST CANDIDATES // => LAST CANDIDATES

    async function loadsLatestCandidates(loadLaborBond, sortByCandidate) {

        setLoadLatestCandidates(true)
        await api.get(`/candidate/?status=active&laborBond=${loadLaborBond}&sortBy=${sortByCandidate}&limit=10&page=0`)
        .then(response => {
            setLoadLatestCandidates(false)
            setLatestCandidates(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }


    async function loadsLatestCandidatesOLDER() {
        setLoadLatestCandidates(true)
        await api.get('/candidate-last/?sortBy=OLDER&status=active')
        .then(response => {
            setLoadLatestCandidates(false)
            setLatestCandidates(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    async function loadPerName() {
        setLoadLatestCandidates(true)
        await api.get('/candidate-last/?sortBy=name&status=active')
        .then(response => {
            setLoadLatestCandidates(false)
            setLatestCandidates(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    async function loadPerSector() {
        setLoadLatestCandidates(true)
        await api.get('/candidate-last/?sortBy=sector&status=active')
        .then(response => {
            setLoadLatestCandidates(false)
            setLatestCandidates(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    async function MORE_RECENT() {
        loadsLatestCandidates()
    }
    async function OLDER() {
        loadsLatestCandidatesOLDER()
    }

    async function sortByNAME() {
        loadPerName()
    }

    async function sortBySector() {
        loadPerSector()
    }

    async function moveAndEditCandidateToFolder(e) {
        let values        = e.target.value.split('&&');
        let idFolder      = values[0];
        let nameFolder    = values[1];
        let idCandidate   = values[2];
        let nameCandidate = values[3];
        swal({
            title: "Atenção",
            text: `Deseja realmente alterar o(a) candidato(a) ${nameCandidate} para à pasta ${nameFolder}?`,
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            setLoadLatestCandidates(true);
            if (res) {

                await api.put(`/candidate/${idCandidate}`, { "folder": idFolder })
                .then(response => {

                    setLoadLatestCandidates(false);
                    loadsFavoriteFolders();
                    loadNumberOfTheFolderFavorite();
                    loadsLatestCandidates(loadLaborBond, sortByCandidate);
                    setCandidates([])
                    swal({ icon: "success", title: "Sucesso!", text: "Candidato(a) movido com sucesso!" });

                }).catch(error => {
                    setLoadLatestCandidates(false);
                    if(error?.response?.data?.message) {
                        swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    }
                    else {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao mover candidato(a) de pasta de talentos, tente novamente mais tarde." });
                    }
                });

            }
            setLoadLatestCandidates(false);
        })
    }

    localStorage.setItem('@peopleview/voltar_recrutamento_talentos', pathname);
    //////////////////////////////////////////////////
    ///////////////// BUSCA DA BorBond //////////////
    ////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectLaborBond, setIsActiveSelectLaborBond] = useState(false);
    async function SelectLaborBond() { setIsActiveSelectLaborBond(!isActiveSelectLaborBond) }

    return (
        <>
            <Layout
                path={props.match.path}>

                <ModalAddFolder
                    visibleModalAddFolder={visibleModalAddFolder}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                    path={props.match.path}
                    inforFolder={inforFolder}
                    setInforFolder={setInforFolder}
                    modalAddFolderHide={modalAddFolderHide}
                    loadsFavoriteFolders={loadsFavoriteFolders}
                    setCandidates={setCandidates}
                    candidates={candidates}
                    setBtnFilterAsFavoriteClicked={setBtnFilterAsFavoriteClicked}
                    setBtnFilterMarkAsFavorite={setBtnFilterMarkAsFavorite}
                    btnFilterMarkAsFavorite={btnFilterMarkAsFavorite}
                    setNameSearch={setNameSearch}
                    nameSearch={nameSearch}
                    setLoadNameFolder={setLoadNameFolder}
                    loadNameFolder={loadNameFolder}
                    setCandidateFilterForThisFolder={setCandidateFilterForThisFolder}
                    candidateFilterForThisFolder={candidateFilterForThisFolder}
                    setAllCandidateExclude={setAllCandidateExclude}
                    allCandidateExclude={allCandidateExclude}
                />
                <Header
                    path={path}
                    pathname={pathname}
                    modalAddFolderOpen={modalAddFolderOpen}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                 />
                <div className='page-talents'>
                    <SubHeaderTalents/>
                    <GeneralInformation
                        countCandidate={countCandidate}
                        countFolderFavorite={countFolderFavorite}
                        counLast24Hours={counLast24Hours}
                        SectorCandidateMORE_COMMON={SectorCandidateMORE_COMMON}
                        SectorCandidateLESS_COMMON={SectorCandidateLESS_COMMON}
                        ageCandidateMostCandidate={ageCandidateMostCandidate}
                        loadGeneralInformation={loadGeneralInformation}
                    />
                    <FavoriteFolders
                        modalAddFolderOpen={modalAddFolderOpen}
                        folderFavorite={folderFavorite}
                        loadFolderFavorite={loadFolderFavorite}
                        inactiveFolder={inactiveFolder}
                        modalEditFolderOpen={modalEditFolderOpen}
                    />
                    <LatestCandidates
                        latestCandidates={latestCandidates}
                        loadLatestCandidates={loadLatestCandidates}
                        MORE_RECENT={MORE_RECENT}
                        OLDER={OLDER}
                        sortByNAME={sortByNAME}
                        sortBySector={sortBySector}
                        moveAndEditCandidateToFolder={moveAndEditCandidateToFolder}
                        folderAtivos={folderAtivos}
                        isActiveSelectLaborBond={isActiveSelectLaborBond}
                        SelectLaborBond={SelectLaborBond}
                        setIsActiveSelectLaborBond={setIsActiveSelectLaborBond}
                        loadLaborBond={loadLaborBond}
                        setLoadLaborBond={setLoadLaborBond}
                        setSortBy={setSortBy}
                        sortByCandidate={sortByCandidate}
                    />
                </div>
            </Layout>
        </>
    );
}

