import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../../../components/Layouts/default';
import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import Documents from '../../../../components/Administrativo/Colaboradores/profile/Personal/Documents';
import { mCPF, mRGNovo, mNumber, dateMask, mCNH, mPIS, mReservationCardNumber, mVoterRegistration } from '../../../../services/mask';
import swal from 'sweetalert';
import axios from 'axios';
import { decodeToken } from '../../../../services/auth';
import {FormataStringData } from "../../../../services/utils";
import api from '../../../../services/api';
import { toast } from 'react-toastify';

export default function ColProfilePersonal(props) {

  const { id }   = props.match.params;
  const { user } = decodeToken();
  const role   = user.role;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ loadPage, setLoadPage ] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ collaborator, setCollaborator ] = useState([]);
  
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {

        if(id && id) {
            async function loadcollaborator() {
    
                setLoadPage(true)
                await api.get(`/collaborator/${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaborator(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
    
            }
            loadcollaborator();

        }


  }, [id]);



    /////////////////////////////////////////////////////////////////////////////
   /////////////////////////////    CPF    /////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedHandleDropCPF, setFilesSavedHandleDropCPF] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileCPFSend, setfileCPFSend] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id) {
            loadfilessavedcategorydocCPF(id);
        }

    }, [id]);

    async function loadfilessavedcategorydocCPF(id) {

        await api.get(`/collaborator-file/?id=${id}&category=docCPF`)
        .then(response => {
            setFilesSavedHandleDropCPF(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadHandleDropCPF, setLoadHandleDropCPF ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileNamesCPF, setFileNamesCPF] = useState([]);


async function handleDropCPF(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {
        setFileNamesCPF(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docCPF');
        formData.append('collaborator', id);
        formData.append('path', props.match.path);

        setLoadHandleDropCPF(true)
        await api.post("/collaborator-file", formData, config)
        .then(response => {
            async function loadfilessaved() {
                await api.get(`/collaborator-file/?id=${id}&category=docCPF`)
                .then(response => {
                    setLoadHandleDropCPF(false)
                    setFilesSavedHandleDropCPF(response.data);
                    setfileCPFSend(true)
                })
            }
            loadfilessaved();
        }).catch(error => {
        });
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }

}

async function deleteFileCPF(filesaved) {

    setLoadHandleDropCPF(true)
    await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
    .then(response => {
        setfileCPFSend(true)
        async function loadfilessaved() {
            setLoadHandleDropCPF(false)
            await api.get(`/collaborator-file/?id=${id}&category=docCPF`)
            .then(response => {
                setFilesSavedHandleDropCPF(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();
    }).catch(() => {
    });

}

    /////////////////////////////////////////////////////////////////////////////
   /////////////////////////   FINAL DE CPF    /////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////
   ///////////////////////    CARTEIRA DE TRABALHO    //////////////////////////
  /////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedHandleDropWorkCard, setFilesSavedHandleDropWorkCard] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadfilessavedcategorydocCT(id);
        }
    }, [id]);

    async function loadfilessavedcategorydocCT(id) {
        await api.get(`/collaborator-file/?id=${id}&category=docCT`)
        .then(response => {
            setFilesSavedHandleDropWorkCard(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileWorkCardSend, setfileWorkCardSend] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadWorkCard, setLoadWorkCard ] = useState(false);

    async function deleteFileWorkCard(filesaved) {

        setLoadWorkCard(true)
        await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
        .then(response => {
            setfileWorkCardSend(true)
            async function loadfilessaved() {
                setLoadWorkCard(false)
                await api.get(`/collaborator-file/?id=${id}&category=docCT`)
                .then(response => {
                    setFilesSavedHandleDropWorkCard(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadfilessaved();

        }).catch(() => {
            //swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
        });
    }



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNamesWorkCard, setFileNamesWorkCard] = useState([]);

    async function handleDropWorkCard(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNamesWorkCard(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {
                //toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCT');
            formData.append('collaborator', id);
            formData.append('path', props.match.path);

            setLoadWorkCard(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {
                setfileWorkCardSend(true)
                async function loadfilessaved() {
                    await api.get(`/collaborator-file/?id=${id}&category=docCT`)
                    .then(response => {
                        setLoadWorkCard(false)
                        setFilesSavedHandleDropWorkCard(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadfilessaved();

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }


    /////////////////////////////////////////////////////////////////////////////
   ///////////////////    FINAL DE CARTEIRA DE TRABALHO    /////////////////////
  /////////////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////
   ///////////////////////////////////   RG  ///////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileRGSend, setfileRGSend] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedRG, setFilesSavedRG] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id){
            loadfilessavedcategorydocRG(id);
        }

    }, [id]);

    async function loadfilessavedcategorydocRG(id) {

        await api.get(`/collaborator-file/?id=${id}&category=docRG`)
        .then(response => {
            setFilesSavedRG(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRG, setLoadRG ] = useState(false);

    async function deleteFileRG(filesaved) {
        setLoadRG(true)
        await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
        .then(response => {
            setfileRGSend(true)
            async function loadfilessaved() {
                setLoadRG(false)
                await api.get(`/collaborator-file/?id=${id}&category=docRG`)
                .then(response => {
                    setFilesSavedRG(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                })
            }
            loadfilessaved();
            //swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
        }).catch(() => {
            //swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
        });

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNamesRG, setFileNamesRG] = useState([]);

    async function handleDropRG(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNamesRG(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                //toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docRG');
            formData.append('collaborator', id);
            formData.append('path', props.match.path);

            setLoadRG(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {
                setfileRGSend(true)
                async function loadfilessaved() {
                    await api.get(`/collaborator-file/?id=${id}&category=docRG`)
                    .then(response => {
                        setLoadRG(false)
                        setFilesSavedRG(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadfilessaved();
                // swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                // swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }


    /////////////////////////////////////////////////////////////////////////////
   //////////////////////////    TÍTULO DE ELEITOR  ////////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [filesSavedVoterTitle, setFilesSavedVoterTitle] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if(id && id){
        loadfilessavedcategorydocTE(id);
    }
  }, [id]);

  async function loadfilessavedcategorydocTE(id) {
    //setLoadVoterTitle(true)
    await api.get(`/collaborator-file/?id=${id}&category=docTE`)
    .then(response => {
        //setLoadVoterTitle(false)
        setFilesSavedVoterTitle(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}


 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [ loadVoterTitle, setLoadVoterTitle ] = useState(false);

 async function deleteFileVoterTitle(filesaved) {
    setLoadVoterTitle(true)
     await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
    .then(response => {
        setfileVoterTitleSend(true)
        async function loadfilessaved() {
            setLoadVoterTitle(false)
            await api.get(`/collaborator-file/?id=${id}&category=docTE`)
            .then(response => {
                setFilesSavedVoterTitle(response.data);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();
    }).catch(() => {});

  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fileVoterTitleSend, setfileVoterTitleSend] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fileNamesVoterTitle, setFileNamesVoterTitle] = useState([]);

  async function handleDropVoterTitle(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {

        setFileNamesVoterTitle(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {
            //toast.info('É necessário selecionar uma arquivo para o envio!');
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docTE');
        formData.append('collaborator', id);
        formData.append('path', props.match.path);

        setLoadVoterTitle(true)
        await api.post("/collaborator-file", formData, config)
        .then(response => {
            setfileVoterTitleSend(true)
            async function loadfilessaved() {
                await api.get(`/collaborator-file/?id=${id}&category=docTE`)
                .then(response => {
                    setLoadVoterTitle(false)
                    setFilesSavedVoterTitle(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadfilessaved();
        }).catch(error => {});
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }

  }


    /////////////////////////////////////////////////////////////////////////////
   //////////////////////    FINAL TÍTULO DE ELEITOR  //////////////////////////
  /////////////////////////////////////////////////////////////////////////////


    /////////////////////////////////////////////////////////////////////////////
   //////////////////////    CARTEIRA DE RESERVISTA/  //////////////////////////
  /////////////////////////////////////////////////////////////////////////////

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [fileReservistSend, setfileReservistSend] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [filesSavedReservist, setFilesSavedReservist] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if(id && id){
        loadfilessavedcategorydocCR(id);
    }
  }, [id]);

  async function loadfilessavedcategorydocCR(id) {
    await api.get(`/collaborator-file/?id=${id}&category=docCR`)
    .then(response => {
        setFilesSavedReservist(response.data);

    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });

  }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadReservist, setLoadReservist ] = useState(false);

    async function deleteFileReservist(filesaved) {

        setLoadReservist(true)
        await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
        .then(response => {
            setLoadReservist(false)
            setfileReservistSend(true)
            async function loadfilessaved() {
                setLoadVoterTitle(false)
                await api.get(`/collaborator-file/?id=${id}&category=docCR`)
                .then(response => {
                    setFilesSavedReservist(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadfilessaved();
            //swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
        }).catch(() => {});
    }


     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [, setFileNamesReservist] = useState([]);

     async function handleDropReservist(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNamesReservist(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCR');
            formData.append('collaborator', id);
            formData.append('path', props.match.path);

            setLoadReservist(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {
                setfileReservistSend(true)
                async function loadfilessaved() {
                    await api.get(`/collaborator-file/?id=${id}&category=docCR`)
                    .then(response => {
                        setLoadReservist(false)
                        setFilesSavedReservist(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
            }).catch(error => {});
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

        /////////////////////////////////////////////////////////////////////////////
    ////////////////////   FINAL DE CARTEIRA DE RESERVISTA/  ////////////////////
    /////////////////////////////////////////////////////////////////////////////


        /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////   CNH    //////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileCNHSend, setfileCNHSend] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedCNH, setFilesSavedCNH] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadfilessavedcategorydocCNH(id);
        }
    }, [id]);

    async function loadfilessavedcategorydocCNH(id) {

        await api.get(`/collaborator-file/?id=${id}&category=docCNH`)
        .then(response => {

            setFilesSavedCNH(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCNH, setLoadCNH ] = useState(false);

    async function deleteFileCNH(filesaved) {

        setLoadCNH(true)
        await api.delete(`/collaborator-file/${filesaved._id}?path=${props.match.path}`)
        .then(response => {
            setLoadCNH(false)
            setfileCNHSend(true)
            async function loadfilessaved() {
                setLoadCNH(false)
                await api.get(`/collaborator-file/?id=${id}&category=docCNH`)
                .then(response => {
                    setFilesSavedCNH(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadfilessaved();

        }).catch(() => {});

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesCNH] = useState([]);

    async function handleDropCNH(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setFileNamesCNH(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                //toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCNH');
            formData.append('collaborator', id);
            setLoadCNH(true)
            await api.post("/collaborator-file", formData, config)
            .then(response => {
                setfileCNHSend(true)
                async function loadfilessaved() {
                    await api.get(`/collaborator-file/?id=${id}&category=docCNH`)
                    .then(response => {
                        setLoadCNH(false)
                        setFilesSavedCNH(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                // swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                // swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    /////////////////////////////////////////////////////////////////////////////
   ////////////////////////////   FINAL DE CNH    //////////////////////////////
  /////////////////////////////////////////////////////////////////////////////



   const  myChangeHandler = event => {
       let nam = event.target.name;
       let val = event.target.value;


       if (nam === "cpf") {

        let cpf = mCPF(val)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cpf })

        }
        else if (nam === "zipcode") {
                getAddress(val)
        } else if (nam === "dateOfBirth") {
            let valDate = FormataStringData(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valDate })
        } else if (nam === "walletNumber") {
            let walletNumber = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: walletNumber })
        } else if (nam === "serialNumber") {
            let serialNumber = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: serialNumber })
        }else if (nam === "issueDate") {
            let issueDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: issueDate })
        }else if (nam === "pisNumber") {
            let pisNumber = mPIS(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pisNumber })
        }else if (nam === "voterRegistration") {
            let voterRegistration = mVoterRegistration(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: voterRegistration })
        }else if (nam === "electoralZone") {
            let electoralZone = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: electoralZone })
        }else if (nam === "pollingStation") {
            let pollingStation = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pollingStation })
        }else if (nam === "rg") {
            let rg = mRGNovo(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rg })
        }else if (nam === "rgExpeditionDate") {
            let rgExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rgExpeditionDate })
        }else if (nam === "reservationCardNumber") {
            let reservationCardNumber = mReservationCardNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardNumber })
        } else if (nam === "reservationCardCategory") {
            let reservationCardCategory = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardCategory })
        }else if (nam === "reservationCardExpeditionDate") {
            let reservationCardExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardExpeditionDate })
        }else if (nam === "cnh") {
            let cnh = mCNH(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnh })
        }else if (nam === "cnhExpeditionDate") {
            let cnhExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnhExpeditionDate })
        }
        else {
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

    };

   // eslint-disable-next-line no-undef
   async function getAddress(zipcode) {
       zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
       if (zipcode.length >= 8) {
           //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
           const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

           if (response.data !== null) {
            setCollaboratorUpdate({
                   ...collaboratorUpdate,
                   zipcode: zipcode,
                   address: response.data.logradouro,
                   addressNeighborhood : response.data.bairro,
                   addressCity: response.data.localidade,
                   addressState: response.data.uf,
               })
           }
       }
   }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      
       if(id && id){ loadcollaborator(id);}

    }, [id]);


    async function loadcollaborator(id) {
        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error =>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
   // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ ufEstados, setUfEstados ] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {

      loadufestados();

  }, []);

  async function loadufestados() {
    await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
    .then(response => {
        setUfEstados(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ loadContinue, setLoadContinue ] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleCPF, setVisibleCPF] = useState(false);
  function EditCpfVisible() {
      setCollaboratorUpdate([]);
      setVisibleCPF(!visibleCPF);
  } 
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleWorkCard, setVisibleWorkCard] = useState(false);

  function EditWorkCardVisible() {
      setCollaboratorUpdate([]);
      setVisibleWorkCard(!visibleWorkCard);
      setSelectWalletUF(false)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleRG, setVisibleRG] = useState(false);
  function EditRGVisible() {
    setCollaboratorUpdate([]);
      setVisibleRG(!visibleRG);
      setSelectRGufOrgan(false)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleVoterTitle, setVisibleVoterTitle] = useState(false);
  function EditVoterTitleVisible() {
    setCollaboratorUpdate([]);
      setVisibleVoterTitle(!visibleVoterTitle);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleReservist, setVisibleReservist] = useState(false);
  function EditReservistVisible() {
      setCollaboratorUpdate([]);
      setVisibleReservist(!visibleReservist);
      setSelectUfReservationCard(false)
      setSelectCityReservationCard(false)
  }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleCNH, setVisibleCNH] = useState(false);
    function EditCNHVisible() {
        setCollaboratorUpdate([]);
        setVisibleCNH(!visibleCNH);
        setSelectCnhCategory(false)
        setSelectUfCNH(false)
    }


  async function save(event) {
      event.preventDefault();
      //alert(collaborator.dateOfBirth)
      if(
            (collaboratorUpdate === undefined || collaboratorUpdate.length === 0) &&
            (
                (fileCPFSend && fileCPFSend === true)  ||
                (fileWorkCardSend && fileWorkCardSend === true)  ||
                (fileRGSend && fileRGSend === true)  ||
                (fileVoterTitleSend && fileVoterTitleSend === true)  ||
                (fileReservistSend && fileReservistSend === true)||
                (fileCNHSend && fileCNHSend === true)
            )
      ){
            swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
            setfileCPFSend(false)
            setVisibleCPF(false);
            setfileWorkCardSend(false)
            setVisibleWorkCard(false);
            setfileRGSend(false)
            setVisibleRG(false);
            setfileVoterTitleSend(false)
            setVisibleVoterTitle(false);
            setfileReservistSend(false)
            setVisibleReservist(false);
            setfileCNHSend(false)
            setVisibleCNH(false);
      } else {
            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            // alert("entrou")
            if(collaboratorUpdate.cpf !== undefined) {
                formData.append('cpf', collaboratorUpdate.cpf);
            }
            if(collaboratorUpdate.foreign !== undefined) {
                formData.append('foreign', collaboratorUpdate.foreign);
            }
            if(collaboratorUpdate.walletNumber !== undefined) {
                formData.append('walletNumber', collaboratorUpdate.walletNumber);
            }
            if(collaboratorUpdate.serialNumber !== undefined) {
                formData.append('serialNumber', collaboratorUpdate.serialNumber);
            }
            if(collaboratorUpdate.issueDate !== undefined) {
                formData.append('issueDate', collaboratorUpdate.issueDate);
            }
            if(collaboratorUpdate.walletUF !== undefined) {
                formData.append('walletUF', collaboratorUpdate.walletUF);
            }
            if(collaboratorUpdate.pisNumber !== undefined) {
                formData.append('pisNumber', collaboratorUpdate.pisNumber);
            }
            if(collaboratorUpdate.rg !== undefined) {
                formData.append('rg', collaboratorUpdate.rg);
            }
            if(collaboratorUpdate.rgExpeditionDate !== undefined) {
                formData.append('rgExpeditionDate', collaboratorUpdate.rgExpeditionDate);
            }
            if(collaboratorUpdate.rgOrgan !== undefined) {
                formData.append('rgOrgan', collaboratorUpdate.rgOrgan);
            }
            if(collaboratorUpdate.ufOrgan !== undefined) {
                formData.append('ufOrgan', collaboratorUpdate.ufOrgan);
            }
            if(collaboratorUpdate.voterRegistration !== undefined) {
                formData.append('voterRegistration', collaboratorUpdate.voterRegistration);
            }
            if(collaboratorUpdate.electoralZone !== undefined) {
                formData.append('electoralZone', collaboratorUpdate.electoralZone);
            }
            if(collaboratorUpdate.pollingStation !== undefined) {
                formData.append('pollingStation', collaboratorUpdate.pollingStation);
            }
            if(collaboratorUpdate.reservationCardNumber !== undefined) {
                formData.append('reservationCardNumber', collaboratorUpdate.reservationCardNumber);
            }
            if(collaboratorUpdate.reservationCardSeries !== undefined) {
                formData.append('reservationCardSeries', collaboratorUpdate.reservationCardSeries);
            }
            if(collaboratorUpdate.reservationCardCategory !== undefined) {
                formData.append('reservationCardCategory', collaboratorUpdate.reservationCardCategory);
            }
            if(collaboratorUpdate.reservationCardExpeditionDate !== undefined) {
                formData.append('reservationCardExpeditionDate', collaboratorUpdate.reservationCardExpeditionDate);
            }
            if(collaboratorUpdate.ufReservationCard !== undefined) {
                formData.append('ufReservationCard', collaboratorUpdate.ufReservationCard);
            }
            if(collaboratorUpdate.cityReservationCard !== undefined) {
                formData.append('cityReservationCard', collaboratorUpdate.cityReservationCard);
            }
            if(collaboratorUpdate.cnh !== undefined) {
                formData.append('cnh', collaboratorUpdate.cnh);
            }
            if(collaboratorUpdate.cnhCategory !== undefined) {
                formData.append('cnhCategory', collaboratorUpdate.cnhCategory);
            }
            if(collaboratorUpdate.cnhExpeditionDate !== undefined) {
                formData.append('cnhExpeditionDate', collaboratorUpdate.cnhExpeditionDate);
            }
            if(collaboratorUpdate.ufCNH !== undefined) {
                formData.append('ufCNH', collaboratorUpdate.ufCNH);
            }
            formData.append('path', props.match.path);

            setLoadContinue(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {
                setLoadContinue(false)
                setVisibleCPF(false);
                setVisibleWorkCard(false);
                setVisibleRG(false);
                setVisibleVoterTitle(false);
                setVisibleReservist(false);
                setVisibleCNH(false);

                async function loadcollaborator() {
                    await api.get(`/collaborator/${id}`)
                    .then(response => {
                        setCollaborator(response.data);
                        setTimeout(function(){ setCollaboratorUpdate({});}, 1000);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadcollaborator();

                swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadContinue(false)
            });
      }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('path', props.match.path);
            //formData.append('collaborator', id);
            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {
                setLoadAvatar(false)
                async function loadcadastrocolaborador() {
                    await api.get(`/collaborator/${id}`)
                    .then(response => {
                        setCollaborator(response.data);
                    })
                    .catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message  });
                    });
                }
                loadcadastrocolaborador();
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }


    //////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectWalletUF, setSelectWalletUF] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectRGufOrgan, setSelectRGufOrgan] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectUfReservationCard, setSelectUfReservationCard] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectCityReservationCard, setSelectCityReservationCard] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectCnhCategory, setSelectCnhCategory] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectUfCNH, setSelectUfCNH] = useState(false);

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                    id={id}
                    collaborator={collaborator}
                />
                <InforProfile
                    collaborator={collaborator}
                    id={id}
                    role={role}
                    path={props.match.path}
                    loadAvatar={loadAvatar}
                    handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                    loadcollaborator={loadcollaborator}
                />
                <SubHeader
                    id={id}
                    path={props.match.path}
                    collaborator={collaborator}
                    role={role}
                />
                <Documents
                    id={id}
                    path={props.match.path}
                    loadPage={loadPage}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={myChangeHandler}
                    ufEstados={ufEstados}

                    ufReservationCard={
                        collaboratorUpdate &&
                        collaboratorUpdate.ufReservationCard ?
                        collaboratorUpdate.ufReservationCard :
                        ''
                    }

                    ufDistrito={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={save}
                    loadContinue={loadContinue}
                    EditCpfVisible={EditCpfVisible}
                    visibleCPF={visibleCPF}
                    EditWorkCardVisible={EditWorkCardVisible}
                    visibleWorkCard={visibleWorkCard}
                    EditRGVisible={EditRGVisible}
                    visibleRG={visibleRG}
                    EditVoterTitleVisible={EditVoterTitleVisible}
                    visibleVoterTitle={visibleVoterTitle}
                    EditReservistVisible={EditReservistVisible}
                    visibleReservist={visibleReservist}
                    EditCNHVisible={EditCNHVisible}
                    visibleCNH={visibleCNH}
                    filesSavedHandleDropCPF={filesSavedHandleDropCPF}
                    loadHandleDropCPF={loadHandleDropCPF}
                    fileNamesCPF={fileNamesCPF}
                    handleDropCPF={handleDropCPF}
                    deleteFileCPF={deleteFileCPF}
                    filesSavedHandleDropWorkCard={filesSavedHandleDropWorkCard}
                    loadWorkCard={loadWorkCard}
                    fileNamesWorkCard={fileNamesWorkCard}
                    handleDropWorkCard={handleDropWorkCard}
                    deleteFileWorkCard={deleteFileWorkCard}
                    filesSavedRG={filesSavedRG}
                    loadRG={loadRG}
                    deleteFileRG={deleteFileRG}
                    fileNamesRG={fileNamesRG}
                    handleDropRG={handleDropRG}
                    filesSavedVoterTitle={filesSavedVoterTitle}
                    loadVoterTitle={loadVoterTitle}
                    deleteFileVoterTitle={deleteFileVoterTitle}
                    fileNamesVoterTitle={fileNamesVoterTitle}
                    handleDropVoterTitle={handleDropVoterTitle}
                    filesSavedReservist={filesSavedReservist}
                    loadReservist={loadReservist}
                    deleteFileReservist={deleteFileReservist}
                    handleDropReservist={handleDropReservist}
                    filesSavedCNH={filesSavedCNH}
                    loadCNH={loadCNH}
                    deleteFileCNH={deleteFileCNH}
                    handleDropCNH={handleDropCNH}
                    setCollaboratorUpdate={setCollaboratorUpdate}

                    setSelectWalletUF={setSelectWalletUF}
                    selectWalletUF={selectWalletUF}

                    setSelectRGufOrgan={setSelectRGufOrgan}
                    selectRGufOrgan={selectRGufOrgan}

                    selectUfReservationCard={selectUfReservationCard}
                    setSelectUfReservationCard={setSelectUfReservationCard}
                    selectCityReservationCard={selectCityReservationCard}
                    setSelectCityReservationCard={setSelectCityReservationCard}

                    selectCnhCategory={selectCnhCategory}
                    setSelectCnhCategory={setSelectCnhCategory}
                    selectUfCNH={selectUfCNH}
                    setSelectUfCNH={setSelectUfCNH}
                    role={role}
                />

            </Layout>
        </>
    );
}

