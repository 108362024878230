import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import WaitingFill from './waitingFill';
import PersonalData from './formPersonalData/personalData';
import ContactAddress from './formPersonalData/contactAddress';
import AcademicEducation from './formPersonalData/AcademicEducation';
import Dependents from './formPersonalData/dependents';
import EmergencyContact from './formPersonalData/emergencyContact';
import Courses from './formPersonalData/Courses'
import { FormataStringData } from '../../../../services/utils';
import { dateMask, phones, cep, conclusionAE, dateofbirthDependet } from '../../../../services/mask';
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';

import api from '../../../../services/api';

export default function formPersonalData ({ id, path, collaborator,setCollaborator }) {

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ load, setLoad ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ editCollaborator, setEditCollaborator ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ ufEstados, setUfEstados ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadContinue, setLoadContinue ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ dateOfBirthUpdate, setDateOfBirthUpdate ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoaddateOfBirth            , LoadsetdateOfBirth                ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadgender                 , Loadsetgender                     ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadfatherName             , LoadsetfatherName                 ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadmotherName             , LoadsetmotherName                 ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadmaritalStatus          , LoadsetmaritalStatus              ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadnationality            , Loadsetnationality                ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadethnicity              , Loadsetethnicity                  ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadbornState              , LoadsetbornState                  ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadbornCity               , LoadsetbornCity                   ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadaddressNumber          ,               ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadaddress                ,                     ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadphone                  , Loadsetphone                      ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadcellphone              , Loadsetcellphone                  ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ Loadzipcode                , Loadsetzipcode                    ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadaddressNeighborhood    , LoadsetaddressNeighborhood        ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadaddressCity            , LoadsetaddressCity                ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ LoadaddressState           , LoadsetaddressState               ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadBottomAcademic         , setloadBottomAcademic             ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadBottomDependent        , setloadBottomDependent            ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadBottomEmergencyContact , setloadBottomEmergencyContact     ] = useState(false);


// eslint-disable-next-line react-hooks/rules-of-hooks
const focusDateOfBirth            = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusgender                 = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusfatherName             = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusmotherName             = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusmaritalStatus          = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusnationality            = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusotherNationality       = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusethnicity              = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusbornState              = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusbornCity               = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusaddressNumber          = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusaddress                = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusphone                  = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focuscellphone              = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focuszipcode                = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusaddressNeighborhood    = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusaddressCity            = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusaddressState           = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusSchooling              = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusCourse                 = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusInstitution            = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusBottomAcademic         = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusNameDependent          = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusRelationshipDependent  = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusDateofBirthDependent   = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusSchoolingDependente    = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusBottomDependent        = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusEmergencyName          = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusRelationshipEmergency  = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusEmergencyCellphone     = useRef(null);
// eslint-disable-next-line react-hooks/rules-of-hooks
const focusBottomEmergencyContact = useRef(null);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    loadufestados();

    if(id && id !== undefined) {

        loadeditcollaborator(id);
        loadschooling(id);
        loaddependents(id);
        loademergencycontacts(id);

    }

}, [id]);

async function loadufestados() {
    await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
    .then(response => {
        setUfEstados(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

async function loadeditcollaborator(id) {

    setLoad(true)
    await api.get(`/collaborator/${id}`)
    .then(response => {
        setLoad(false)
        setEditCollaborator(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });

}

async function loadschooling(id) {

    await api.get(`/collaborator-schooling/${id}`)
    .then(response => {

        setFileNamesSchooling([])
        setSchooling([])
        setLoadButtonSchooling(false)
        setLoadSchooling(false)
        setLoadCourse(false)
        setLoadInstitution(false)
        setloadBottomAcademic(false)
        setRegisteredSchooling(response.data);


    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}


const myChangeHandlerAdminEdition = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    setEditCollaborator({ ...editCollaborator, [nam]: val })

}


const  myChangeHandler = event => {
    let nam = event.target.name;
    let val = event.target.value;
    //parou aqui - setar collaboratorupdate

    if (nam === "zipcode") {
        if(val.length >= 8) {
            Loadsetzipcode(false)
        }
        getAddress(val)
    }
    else if (nam === "dateOfBirth") {

        let date = dateMask(val)
        if(date.length <= 10) {

            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: date })
            if(date.length === 10) {
                let valDate = FormataStringData(date);
                LoadsetdateOfBirth(false)
                setDateOfBirthUpdate({ ...dateOfBirthUpdate, [nam]: valDate })
            }
        }

    }
    else
    if (nam === "gender") {

        if(val === 'Selecione') {
            let valgender = undefined;
            Loadsetgender(true)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valgender })
        } else {
            Loadsetgender(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

    }
    //fatherName
    else
    if (nam === "fatherName") {
        LoadsetfatherName(false)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
    }
    //motherName
    else
    if (nam === "motherName") {
        LoadsetmotherName(false)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
    }
    //maritalStatus
    else
    if (nam === "maritalStatus") {

        if(val === 'Selecione') {
            let valmaritalStatus = undefined;
            LoadsetmaritalStatus(true)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valmaritalStatus })
        } else {
            LoadsetmaritalStatus(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

    }
    else
    if (nam === "ethnicity") {

        if(val === 'Selecione') {
            let valethnicity = undefined;
            Loadsetethnicity(true)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valethnicity })
        } else {
            Loadsetethnicity(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

    }
    //otherNationality
    else
    if (nam === "bornStateString") {
        LoadsetbornState(false)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
    }
    else
    if (nam === "bornCityString") {
        LoadsetbornCity(false)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
    }


    //phone
    else if (nam === "phone") {
        if(val.length >= 14) {
            Loadsetphone(false)
        }
        let telefone = phones(val)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: telefone })

    }
    //cellphone
    else if (nam === "cellphone") {
        if(val.length >= 14) {
            Loadsetcellphone(false)
        }
        let celular = phones(val)
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: celular })

    }
    else {
        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
    }

    };

    // eslint-disable-next-line no-undef
    async function getAddress(zipcode) {

        if (zipcode.length === 8) {
            zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
            let cepppp = cep(zipcode)
            const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

            if (response.data !== null) {
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    zipcode: cepppp,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })
            }
        } else {
            let cepppp = cep(zipcode)
            setCollaboratorUpdate({
                ...collaboratorUpdate,
                zipcode: cepppp,
            })
        }
    }


    async function save(event) {
        event.preventDefault();

            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(
                collaboratorUpdate                                     &&
                collaboratorUpdate.dateOfBirth         !== undefined   &&
                collaboratorUpdate.dateOfBirth.length  === 10          &&
                dateOfBirthUpdate.dateOfBirth          !== undefined
            ) {
                LoadsetdateOfBirth(false)
                formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
            }
            else if(
                collaborator                     &&
                collaborator.dateOfBirth         !== undefined
            ) {
                LoadsetdateOfBirth(false);
            }
            else {
                LoadsetdateOfBirth(true);
            }

            if(collaboratorUpdate.gender !== undefined) {
                Loadsetgender(false)
                formData.append('gender', collaboratorUpdate.gender);
            }
            else if(
                collaborator                     &&
                collaborator.gender         !== undefined
            ) {
                Loadsetgender(false)
            }
            else {
                Loadsetgender(true)
            }

           if(collaboratorUpdate.fatherName !== undefined && collaboratorUpdate.fatherName !== "") {
                LoadsetfatherName(false)
                formData.append('fatherName', collaboratorUpdate.fatherName);
            }
            else  if(
                collaborator                     &&
                collaborator.fatherName         !== undefined
            ) {
                LoadsetfatherName(false)
            } else {
                LoadsetfatherName(true)
            }

            if(collaboratorUpdate.motherName !== undefined && collaboratorUpdate.motherName !== "") {
                LoadsetmotherName(false)
                formData.append('motherName', collaboratorUpdate.motherName);
            }
            else if(
                collaborator                     &&
                collaborator.motherName         !== undefined
            ) {
                LoadsetmotherName(false)
            } else {
                LoadsetmotherName(true)
            }

            if(collaboratorUpdate.maritalStatus !== undefined) {
                LoadsetmaritalStatus(false)
                formData.append('maritalStatus', collaboratorUpdate.maritalStatus);
            }
            else if(
                collaborator                     &&
                collaborator.maritalStatus       !== undefined
            ) {
                LoadsetmaritalStatus(false)
            }
            else {
                LoadsetmaritalStatus(true)
            }

            if(collaboratorUpdate.nationality !== undefined) {
                Loadsetnationality(false)
                formData.append('nationality', collaboratorUpdate.nationality);

            }
            else if(
                collaborator                     &&
                collaborator.nationality         !== undefined
            ) {
                Loadsetnationality(false)
            }
            else {
                Loadsetnationality(true)
            }

            if(collaboratorUpdate.ethnicity !== undefined) {
                Loadsetethnicity(false)
                formData.append('ethnicity', collaboratorUpdate.ethnicity);
            }
            else if(
                collaborator                     &&
                collaborator.ethnicity         !== undefined
            ) {
                Loadsetethnicity(false)
            }
            else{
                Loadsetethnicity(true)
            }

            if(collaboratorUpdate.nationality === 'Brasil' || collaborator.nationality === 'Brasil') {

                if(
                    collaboratorUpdate?.bornState !== undefined &&
                    collaboratorUpdate?.bornState !== ""
                ) {
                    LoadsetbornState(false)
                    formData.append('bornState', collaboratorUpdate.bornState);
                    formData.append('bornStateString', collaboratorUpdate.bornStateString);
                }
                else if(
                    collaborator                     &&
                    collaborator?.bornState         !== undefined &&
                    collaborator?.bornState         !== ""
                ) {
                    LoadsetbornState(false)
                } else {
                    LoadsetbornState(true)
                }

                if(
                    collaboratorUpdate.bornCity !== undefined &&
                    collaboratorUpdate.bornCity !== ""
                ) {
                    LoadsetbornCity(false)
                    formData.append('bornCity', collaboratorUpdate.bornCity);
                    formData.append('bornCityString', collaboratorUpdate.bornCityString);
                }
                else if(
                    collaborator                     &&
                    collaborator?.bornCity         !== undefined &&
                    collaborator?.bornState         !== ""
                ) {
                    LoadsetbornCity(false)
                } else {
                    LoadsetbornCity(true)
                }

            }else {

                if(
                    collaboratorUpdate?.bornStateString !== undefined &&
                    collaboratorUpdate?.bornStateString !== ""
                ) {
                    LoadsetbornState(false)
                    formData.append('bornState', undefined);
                    formData.append('bornStateString', collaboratorUpdate.bornStateString);
                }
                else if(
                    collaborator                     &&
                    collaborator?.bornStateString         !== undefined &&
                    collaborator?.bornStateString         !== ""
                ) {
                    LoadsetbornState(false)
                } else {
                    LoadsetbornState(true)
                }
                if(
                    collaboratorUpdate.bornCityString !== undefined  &&
                    collaboratorUpdate.bornCityString !== ""
                ) {
                    LoadsetbornCity(false)
                    formData.append('bornCity', undefined);
                    formData.append('bornCityString', collaboratorUpdate.bornCityString);
                }
                else if(
                    collaborator                     &&
                    collaborator?.bornCityString         !== undefined &&
                    collaborator?.bornCityString         !== ""
                ) {
                    LoadsetbornCity(false)
                } else {
                    LoadsetbornCity(true)
                }

            }

            if(collaboratorUpdate.phone !== undefined && collaboratorUpdate.phone !== "") {
                Loadsetphone(false)
                formData.append('phone', collaboratorUpdate.phone);
            }

           if(collaboratorUpdate.cellphone !== undefined && collaboratorUpdate.cellphone !== "") {
                Loadsetcellphone(false)
                formData.append('cellphone', collaboratorUpdate.cellphone);
            }
            else if(
                collaborator                     &&
                collaborator.cellphone         !== undefined
            ) {
                Loadsetcellphone(false)
            } else  {
                Loadsetcellphone(true)
            }

            if(collaboratorUpdate.address !== undefined) {
                formData.append('address', collaboratorUpdate.address);
            }

            if(collaboratorUpdate.zipcode !== undefined && collaboratorUpdate.zipcode !== "") {
                Loadsetzipcode(false)
                formData.append('zipcode', collaboratorUpdate.zipcode);
            }
            else if(
                collaborator                     &&
                collaborator.zipcode         !== undefined
            ) {
                Loadsetzipcode(false)
            } else {
                Loadsetzipcode(true)
            }

            if(collaboratorUpdate.addressNumber !== undefined && collaboratorUpdate.addressNumber !== "") {
                formData.append('addressNumber', collaboratorUpdate.addressNumber);
            }

            if(collaboratorUpdate.addressNeighborhood !== undefined) {
                LoadsetaddressNeighborhood(false)
                formData.append('addressNeighborhood', collaboratorUpdate.addressNeighborhood);
            }

            if(collaboratorUpdate.addressCity !== undefined) {
                LoadsetaddressCity(false)
                formData.append('addressCity', collaboratorUpdate.addressCity);
            }

            if(collaboratorUpdate.addressState !== undefined) {
                LoadsetaddressState(false)
                formData.append('addressState', collaboratorUpdate.addressState);
            }

            if(collaboratorUpdate.addressComplement !== undefined) {
                formData.append('addressComplement', collaboratorUpdate.addressComplement);
            }

            if(registeredEmergencyContact && registeredEmergencyContact[0] !== undefined) {

                setLoademErgencyName(false)
                setLoadRelationshipEmergencyContact(false)
                setLoadEmergencyCellphone(false)
                setloadBottomEmergencyContact(false)
                setLoadContinue(false)
                setLoad(false)

            }
            else {

                if (emergencyContact.emergencyName === undefined || emergencyContact.emergencyName === "") {
                    focusEmergencyName.current.focus();
                    setLoademErgencyName(true)
                } else {
                    setLoademErgencyName(false)
                }

                if(emergencyContact.relationship === 'Selecione') {

                    focusRelationshipEmergency.current.focus();
                    setLoadRelationshipEmergencyContact(true)

                } else {

                    if (emergencyContact.relationship === undefined || emergencyContact.relationship === "") {

                        focusRelationshipEmergency.current.focus();
                        setLoadRelationshipEmergencyContact(true)

                    } else {
                        setLoadRelationshipEmergencyContact(false)
                    }

                }

                if (emergencyContact.emergencyCellphone === undefined || emergencyContact.emergencyCellphone === "") {
                    focusEmergencyCellphone.current.focus();
                    setLoadEmergencyCellphone(true)
                } else {
                    setLoadEmergencyCellphone(false)
                }

                if(registeredEmergencyContact && registeredEmergencyContact[0] === undefined) {
                    setloadBottomEmergencyContact(true)
                    setLoadContinue(false)
                    setLoad(false)
                    focusBottomEmergencyContact.current.focus();
                }

            }

            if(registeredDependents && registeredDependents[0] !== undefined) {

                setLoadNameDependent(false);
                setLoadRelationship(false);
                setLoadDateOfBirth(false);
                setLoadSchoolingDependent(false);
                setloadBottomDependent(false)
                setLoadContinue(false)
                setLoad(false)

            }
            else {

                if(dependents && dependents.noDependents === 'false') {
                    if (dependents.name !== undefined && dependents.name !== "") {
                        setLoadNameDependent(false)
                    } else {
                        setLoadNameDependent(true)
                        focusNameDependent.current.focus();
                    }

                    if(dependents.relationship === 'Selecione') {

                        setLoadRelationship(true)
                        focusRelationshipDependent.current.focus();

                    } else {

                        if (dependents.relationship !== undefined && dependents.relationship !== "") {
                            setLoadRelationship(false)
                        } else {
                            setLoadRelationship(true)
                            focusRelationshipDependent.current.focus();
                        }
                    }

                    if (dependents.dateOfBirth !== undefined && dependents.dateOfBirth !== "") {
                        setLoadDateOfBirth(false)
                    } else {
                        setLoadDateOfBirth(true)
                        focusDateofBirthDependent.current.focus();
                    }


                    if(dependents.schooling === 'Selecione') {

                        setLoadSchoolingDependent(true)
                        focusSchoolingDependente.current.focus();

                    } else {

                        if (dependents.schooling !== undefined && dependents.schooling !== "") {
                            setLoadSchoolingDependent(false)
                        } else {
                            setLoadSchoolingDependent(true)
                            focusSchoolingDependente.current.focus();
                        }

                    }

                    if(registeredDependents && registeredDependents[0] === undefined) {
                        focusBottomDependent.current.focus();
                        setloadBottomDependent(true)
                        setLoadContinue(false)
                        setLoad(false)
                        // return
                    }
                }

            }


            if(registeredSchooling && registeredSchooling[0] !== undefined) {

                setLoadSchooling(false);
                setLoadInstitution(false);
                setLoadCourse(false);
                setloadBottomAcademic(false)
                setLoadContinue(false)
                setLoad(false)

            }
            else {

                if (schooling.schooling !== undefined && schooling.schooling !== "") {
                    setLoadSchooling(false)
                } else {
                    setLoadSchooling(true)
                    focusSchooling.current.focus();
                }

                if (schooling.course !== undefined && schooling.course !== "") {
                    setLoadCourse(false)
                } else {
                    setLoadCourse(true)
                    focusCourse.current.focus();
                }

                if (schooling.institution !== undefined && schooling.institution !== "") {
                    setLoadInstitution(false)
                } else {
                    setLoadInstitution(true)
                    focusInstitution.current.focus();
                }

                if(registeredSchooling && registeredSchooling[0] === undefined) {
                    focusBottomAcademic.current.focus();
                    setloadBottomAcademic(true)
                    setLoadContinue(false)
                    setLoad(false)
                }
            }

            if (
                collaboratorUpdate.dateOfBirth   === undefined && collaborator.dateOfBirth   === undefined
            ) { focusDateOfBirth.current.focus(); return }
            else
            if (
                collaboratorUpdate.gender   === undefined && collaborator.gender   === undefined
            ) { focusgender.current.focus(); return }
            else
            if (
                collaboratorUpdate.fatherName   === undefined && collaborator.fatherName   === undefined
            ) { focusfatherName.current.focus(); return }
            else
            if (
                collaboratorUpdate.motherName   === undefined && collaborator.motherName   === undefined
            ) { focusmotherName.current.focus(); return }
            else
            if (
                collaboratorUpdate.maritalStatus   === undefined && collaborator.maritalStatus   === undefined
            ) { focusmaritalStatus.current.focus(); return }
            else
            if (
                collaboratorUpdate.nationality   === undefined && collaborator.nationality   === undefined
            ) { focusnationality.current.focus(); return }
            else
            if (
                collaboratorUpdate.ethnicity   === undefined && collaborator.ethnicity   === undefined
            ) { focusethnicity.current.focus(); return }
            else
            if(
                collaboratorUpdate.bornCity === undefined &&
                collaborator.bornCity === undefined
            ) {
                    focusbornCity.current.focus();
                    LoadsetbornCity(true)
                    return

            }else
            if(
                collaboratorUpdate.bornCityString === "" &&
                collaborator.bornCityString === ""
            ) {
                    focusbornCity.current.focus();
                    LoadsetbornCity(true)
                     return

            }else
            if(
                collaboratorUpdate.nationality === "Brsil" &&
                collaboratorUpdate.bornCity === "" &&
                collaboratorUpdate.bornState === ""
            ) {

                if (
                    collaboratorUpdate.bornCity   === "" && collaborator.bornCity   === ""
                ) { focusbornState.current.focus(); return }
                else

                if (
                    collaboratorUpdate.bornCity   === "" && collaborator.bornCity   === ""
                ) { focusbornCity.current.focus(); return }

            }else if (
                collaboratorUpdate.nationality !== "Brsil" &&
                collaboratorUpdate.bornCityString === "" &&
                collaboratorUpdate.bornStateString === ""
            ) {

                if (
                    collaboratorUpdate.bornStateString   === "" && collaborator.bornStateString   === ""
                ) { focusbornState.current.focus(); return }
                else
                if (
                    collaboratorUpdate.bornCityString   === "" && collaborator.bornCityString   === ""
                ) { focusbornCity.current.focus(); return }

            } else
            if (
                collaboratorUpdate.cellphone   === undefined && collaborator.cellphone   === undefined
            ) { focuscellphone.current.focus(); return }
            else
            if (
                collaboratorUpdate.zipcode   === undefined && collaborator.zipcode   === undefined
            ) { focuszipcode.current.focus(); return }
            else
            if (
                registeredSchooling && registeredSchooling[0]  === undefined
            ) { focusSchooling.current.focus(); return }
            else
            if (
                registeredEmergencyContact && registeredEmergencyContact[0] === undefined
            ) { focusEmergencyName.current.focus(); return }
            else{

                formData.append('stepOne', 'true');


                let stepsNewAdmission = collaborator.stepsNewAdmission;
                // collaborator
                if(stepsNewAdmission === 2) {
                    formData.append('stepsNewAdmission', 3);
                }


                formData.append('path', path);
                setLoadContinue(true)
                await api.put(`/collaborator/${id}`, formData, config)
                .then(response => {

                    if(response.data.stepsNewAdmission === 3 ){
                        window.location.href = `/colaboradores/nova-admissao/formulario-documentos/${id}`;
                    }else if( response.data.stepsNewAdmission === 4 ){
                        window.location.href = `/colaboradores/nova-admissao/formulario-beneficios/${id}`;
                    }else if( response.data.stepsNewAdmission === 5 ){
                        window.location.href = `/colaboradores/nova-admissao/enviar-contador/${id}`;
                    }

                }).catch(error => {
                    setLoadContinue(false)
                    //setLoad(true)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });

                    if(error.response.data.message === 'Data de aniversário em formato inválido, por favor informe um formato existente!') {
                        LoadsetdateOfBirth(true)
                        focusDateOfBirth.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Você não pode cadastrar uma data de aniversário menor que 1920.') {
                        LoadsetdateOfBirth(true)
                        focusDateOfBirth.current.focus();
                        return
                    }

                });

            }
    }

    const myChangeHandlerExcludeAdmssion = async (event) => {
        //const { collaborator } = this.state;
        let nam = event.target.name;
        let val = event.target.value;
        let admissionProcess = "false";
        let shutdownProcess = "true";
        let recentShutdown  = "false";
        let status = "Inativo";

        setCollaboratorUpdate({ ...collaboratorUpdate, admissionProcess, shutdownProcess, recentShutdown, status, [nam]: val })

    }

    async function excludeAdmssion(event) {
        event.preventDefault();

        if(editCollaborator?._id) {

            swal({
                title: "Atenção",
                text: "Deseja realmente excluir o processo de admissão deste colaborador?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    let _id              = editCollaborator._id;
                    let admissionProcess = "false";
                    let shutdownProcess  = "true";
                    let recentShutdown   = "false";
                    // let status           = "Inativo";

                    formData.append('_id', _id);
                    formData.append('admissionProcess', admissionProcess);
                    formData.append('shutdownProcess', shutdownProcess);
                    formData.append('recentShutdown', recentShutdown);
                    formData.append('status', 'Inativo');
                    formData.append('path', path);

                    await api.put(`/collaborator/${editCollaborator._id}`, formData, config)
                    .then(response => {
                        swal({ icon: "success", title: "Sucesso!", text: "Colaborador removido com sucesso!" });
                        window.location.href = '/colaboradores/nova-admissao/formulario-Informacoes-basicas';
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover a colaborador, tente novamente mais tarde." });
                    });

                }
            })

        }
        else {

            swal({ icon: "error", title: "Erro!", text: "Não existe nenhum colaborador para excluir!" });

        }

    }



    //////////////////////////////////////////////////////////////////////////////////////////////
    ////////   SCHOOLING     /////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ schooling, setSchooling ] = useState([]);

    async function myChangeHandlerSchooling(event) {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;

        if(nam === 'conclusion') {
            let yearconclusion = conclusionAE(val)
            //conclusionAE
            setSchooling({ ...schooling, collaborator, [nam]: yearconclusion })
        } else if (nam === "schooling") {

            if(val === 'Selecione') {
                //alert('aqui')
                let valschooling = undefined;
                setLoadSchooling(true);
                setSchooling({ ...schooling, collaborator, [nam]: valschooling })
            } else {
                setLoadSchooling(false);
                setSchooling({ ...schooling, collaborator, [nam]: val })
            }

        } else if (nam === "institution") {

            setLoadInstitution(false);
            setSchooling({ ...schooling, collaborator, [nam]: val })

        } else if (nam === "course") {

            setLoadCourse(false);
            setSchooling({ ...schooling, collaborator, [nam]: val })

        }  else {
            setSchooling({ ...schooling, collaborator, [nam]: val })
        }

    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNamesSchooling, setFileNamesSchooling] = useState([]);

    async function handleDropSchooling(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setFileNamesSchooling(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setSchooling({ ...schooling, file })
            return
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFilesSchooling() {
        setFileNamesSchooling();
        let file = fileNamesSchooling
        setSchooling({ ...schooling, file })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonSchooling, setLoadButtonSchooling ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSchooling, setLoadSchooling ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInstitution, setLoadInstitution ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCourse, setLoadCourse ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredSchooling, setRegisteredSchooling ] = useState([]);
    //registeredSchooling


    async function handleSubmitSchooling(event) {
        event.preventDefault();

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if (schooling.file !== undefined) {
            formData.append('file', schooling.file);
        }
        //formData.append('file', schooling.file);
        if (schooling.schooling !== undefined && schooling.schooling !== "") {
            formData.append('schooling', schooling.schooling);
            setLoadSchooling(false)
        } else {
            setLoadSchooling(true)
        }

        if (schooling.course !== undefined && schooling.course !== "") {
            formData.append('course', schooling.course);
            setLoadCourse(false)
        } else {
            setLoadCourse(true)
        }

        if (schooling.institution !== undefined && schooling.institution !== "") {
            formData.append('institution', schooling.institution);
            setLoadInstitution(false)
        } else {
            setLoadInstitution(true)
        }
        if (schooling.conclusion !== undefined && schooling.conclusion !== "") {
            formData.append('conclusion', schooling.conclusion);
        }
        formData.append('collaborator', id);
        formData.append('path', path);

        if (
            schooling.schooling !== undefined && schooling.schooling !== "" &&
            schooling.course !== undefined && schooling.course !== "" &&
            schooling.institution !== undefined && schooling.institution !== ""
        ) {

            setLoadButtonSchooling(true)
            await api.post("/collaborator-schooling", formData, config)
            .then(response => {
                let schooling = '';
                let course = '';
                let institution = '';
                let conclusion = '';
                let file  = '';

                setSchooling({ ...schooling, schooling, course, institution, conclusion, file});
                setloadBottomAcademic(false);
                loadschooling(id);
    
                swal({ icon: "success", title: "Sucesso!", text: "Formação acadêmica criada com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível criar a formação acadêmica!" });
            });
        }
    }

    async function deleteSchooling(schooling) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/collaborator-schooling/${schooling}?path=${path}`)
                        .then(() => {

                            loadschooling(id);
                           
                            swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                        }).catch(() => {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                        });
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    ////////   FINAL SCHOOLING     ///////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////
    ////////   DEPENDENTS     ////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDependents, setLoadDependents ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameDependent, setLoadNameDependent ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRelationship, setLoadRelationship ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDateOfBirth, setLoadDateOfBirth ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSchoolingDependent, setLoadSchoolingDependent ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNamesDependents, setFileNamesDependents] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dependents, setDependents ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredDependents, setRegisteredDependents ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ noDependentsSaved, setNoDependentsSaved ] = useState(false);


    async function loaddependents(id) {
        setLoadDependents(true)
        await api.get(`/collaborator-dependent/${id}`)
        .then(response => {

            setLoadDependents(false);
            if(response.data.length !== 0) {

                setNoDependentsSaved(true);
                setRegisteredDependents(response.data);

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function myChangeHandlerNoDependent(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = id;
        let dependent = val

        setDependents({ ...dependents, collaborator, [nam]: val })
        setCollaboratorUpdate({ ...collaboratorUpdate, dependent })

    }


    async function myChangeHandlerDependents(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = id;

        if(nam === 'dateOfBirth') {
            setLoadDateOfBirth(false)
            let birthDependent = dateofbirthDependet(val)
            setDependents({ ...dependents, collaborator, [nam]: birthDependent })

        }
        else  if(nam === 'name') {
            setLoadNameDependent(false)
            setDependents({ ...dependents, collaborator, [nam]: val })

        }
        else if(nam === 'relationship') {

            if(val === 'Selecione') {
                let valrelationship = undefined;
                setLoadRelationship(true)
                setDependents({ ...dependents, collaborator, [nam]: valrelationship })
            }
            else if(val === 'Nenhum') {
                setLoadRelationship(false)
                setDependents({ ...dependents, collaborator, name: val, [nam]: val, dateOfBirth: '0', schooling: val })
            }
            else {
                setLoadRelationship(false)
                setDependents({ ...dependents, collaborator, [nam]: val })
            }

        }
        else if(nam === 'schooling') {

            if(val === 'Selecione') {
                let valschooling = undefined;
                setLoadSchoolingDependent(true)
                setDependents({ ...dependents, collaborator, [nam]: valschooling })
            } else {
                setLoadSchoolingDependent(false)
                setDependents({ ...dependents, collaborator, [nam]: val })
            }

        }
        else {
            setDependents({ ...dependents, collaborator, [nam]: val })
        }

    };



    async function handleDropDependent(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNamesDependents(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setDependents({ ...dependents, file })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFilesDependent() {
        setFileNamesDependents();
        let file = fileNamesDependents
        setDependents({ ...dependents, file })
    }

    async function handleSubmitDependent(event) {
        event.preventDefault();
        if(
            (dependents && dependents.noDependents === 'false') ||
            noDependentsSaved                     ===  true
            ) {

                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if (dependents.name !== undefined && dependents.name !== "") {
                    setLoadNameDependent(false)
                    formData.append('name', dependents.name);
                } else {
                    setLoadNameDependent(true)
                }

                if (dependents.relationship !== undefined && dependents.relationship !== "") {
                    setLoadRelationship(false)
                    formData.append('relationship', dependents.relationship);
                } else {
                    setLoadRelationship(true)
                }

                if (dependents.dateOfBirth !== undefined && dependents.dateOfBirth !== "") {
                    setLoadDateOfBirth(false)
                    formData.append('dateOfBirth', dependents.dateOfBirth);
                } else {
                    setLoadDateOfBirth(true)
                }

                if (dependents.schooling !== undefined && dependents.schooling !== "") {
                    setLoadSchoolingDependent(false)
                    formData.append('schooling', dependents.schooling);
                } else {
                    setLoadSchoolingDependent(true)
                }

                if (dependents.file !== undefined) {
                    formData.append('file', dependents.file);
                }
                formData.append('collaborator', id);
                formData.append('noDependents', false);
                formData.append('path', path);
                if (
                    dependents.name         !== undefined &&
                    dependents.name         !== ""        &&
                    dependents.relationship !== undefined &&
                    dependents.relationship !== ""        &&
                    dependents.dateOfBirth  !== undefined &&
                    dependents.dateOfBirth  !== ""        &&
                    dependents.schooling    !== undefined &&
                    dependents.schooling    !== ""
                ) {
                    setLoadDependents(true)
                    await api.post("/collaborator-dependent", formData, config)
                    .then(response => {
                        let name         = undefined;
                        let relationship = undefined;
                        let dateOfBirth  = undefined
                        let schooling    = undefined;

                        setDependents({ ...dependents, name, relationship, dateOfBirth, schooling })
                        setloadBottomDependent(false)
                        setLoadContinue(false)
                        setLoad(false)
                        setFileNamesDependents([])
                        setNoDependentsSaved(true)
                        loaddependents(id)
                        swal({ icon: "success", title: "Sucesso!", text: "Dependente criado com sucesso!" });

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar um dependente!" });
                    });
                }

        } else {
            setLoadNameDependent(false)
            setLoadRelationship(false)
            setLoadDateOfBirth(false)
            setLoadSchoolingDependent(false)
            setDependents({ ...dependents, noDependents: 'false' })

        }



    }


    async function deleteDependent(schooling) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/collaborator-dependent/${schooling}?path=${path}`)
                    .then(() => {

                        let noDependents = 'true';
                        let name         = undefined;
                        let relationship = undefined;
                        let dateOfBirth  = undefined
                        let schooling    = undefined;

                        setDependents({ ...dependents, noDependents, name, relationship, dateOfBirth, schooling })

                        const dependentcollaboratorupdate = {

                            "dependent": 'false',
                        }

                        async function loadcollaboratorupdate() {
                            const collaboratorupdated = await api.put(`/collaborator/${id}`, dependentcollaboratorupdate)

                            if(collaboratorupdated.status === 200) {

                                await api.get(`/collaborator-dependent/${id}`)
                                .then(response => {
                                    setRegisteredDependents(response.data);
                                    setNoDependentsSaved(false)
                                }).catch(error => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });
                                // loaddependents(id)

                            }
                        }
                        loadcollaboratorupdate();

                        swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                    }).catch(() => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                    });
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////
    ////////  FINAL DEPENDENTS     ///////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////
    ////////  EMERGENCY CONTACT   ////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmergencyContact, setLoadEmergencyContact ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmergencyCellphone, setLoadEmergencyCellphone ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loademErgencyName, setLoademErgencyName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRelationshipEmergencyContact, setLoadRelationshipEmergencyContact ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ emergencyContact, setEmergencyContact ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredEmergencyContact, setRegisteredEmergencyContact ] = useState([]);

    async function loademergencycontacts(id) {

        setLoadEmergencyContact(true)
        await api.get(`/collaborator-emergency-contact/${id}`)
        .then(response => {

            setLoadEmergencyContact(false)

            if(response.data[0] !== undefined) {

                setRegisteredEmergencyContact(response.data);

            }
            else {

                setRegisteredEmergencyContact([])

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function myChangeHandlerEmergencyContact(event) {
        let nam = event.target.name;
        let val = event.target.value;

        const collaboratorId = collaborator._id;



        if(nam === 'emergencyName') {
            setLoademErgencyName(false)
            setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: val, path: path })
        }
        else
        if(nam === 'emergencyCellphone') {
            let cellphoneemergency = phones(val)
            if(val.length >= 14) {
                setLoadEmergencyCellphone(false)
            }
            setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: cellphoneemergency })
        }
        else
        if(nam === 'relationship') {

            if(val === 'Selecione') {
                let valrelationship = undefined;
                setLoadRelationshipEmergencyContact(true)
                setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: valrelationship })
            } else {
                setLoadRelationshipEmergencyContact(false)
                setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: val })
            }

        }
        else {
            setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: val })
        }

    };

    async function handleSubmitEmergencyContact(event) {
        event.preventDefault();

        if (emergencyContact.emergencyName === undefined) {
            setLoademErgencyName(true)
        } else {
            setLoademErgencyName(false)
        }

        if (emergencyContact.relationship === undefined) {
            setLoadRelationshipEmergencyContact(true)
        } else {
            setLoadRelationshipEmergencyContact(false)
        }

        if (emergencyContact.emergencyCellphone === undefined) {
            setLoadEmergencyCellphone(true)
        } else {
            setLoadEmergencyCellphone(false)
        }

        if (
            emergencyContact.emergencyName      !== undefined &&
            emergencyContact.emergencyName      !== ""        &&
            emergencyContact.relationship       !== undefined &&
            emergencyContact.relationship       !== ""        &&
            emergencyContact.emergencyCellphone !== undefined &&
            emergencyContact.emergencyCellphone !== ""
        ) {
            setLoadEmergencyContact(true)
            await api.post('/collaborator-emergency-contact/', emergencyContact)
            .then(response => {

                let emergencyName      = undefined;
                let relationship       = undefined;
                let emergencyCellphone = undefined;
                let emergencyEmail     = undefined;
                setEmergencyContact({ ...emergencyContact, emergencyName, relationship, emergencyCellphone, emergencyEmail })
                setloadBottomEmergencyContact(false);
                setLoadContinue(false);
                setLoad(false);
                loademergencycontacts(id);
                swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência criado com sucesso!" });

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao criar o contato de emergência, tente novamente mais tarde." });
            });
        }
    }

    async function handleDeleteEmergencyContact(_id) {
        try {

            await api.delete(`/collaborator-emergency-contact/${_id}?path=${path}`)
            .then(response => {

                loademergencycontacts(id)
                swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência deletado com sucesso!" });

            }).catch(error => {});


        } catch (error) {

        swal({ icon: "error", title: "Erro!", text: "Erro ao deletadar o contato de emergência!" });

        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleInforEmail, setVisibleInforEmail] = useState(false);

    function FecharInforEmail() {

        setVisibleInforEmail(false)
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPreencherManualmente, setLoadPreencherManualmente ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSolicitarPreenchimento, setLoadSolicitarPreenchimento ] = useState(false);

    async function EditStepProcessTwo(e){


        if(e === 'preencher_manualmente') {
            setLoadPreencherManualmente(true)
            await api.put(`/collaborator/${editCollaborator._id}`, { collaboratorEdition: false, stepProcessTwo: 'preencher_manualmente' })
            .then(response => {

                setLoadPreencherManualmente(false)
                loadeditcollaborator(id)

            }).catch(error => {
                setLoadPreencherManualmente(false)
                swal({ icon: "error", title: "Erro!", text: "Ocorreu no momento, por favor, tente mais tarde." });
            });

        }

        if(e === 'solicitar_preenchimento') {

            setLoadSolicitarPreenchimento(true)
            if(
                editCollaborator?.personalEmail
                ||
                editCollaborator?.email
            ) {

                await api.put(`/collaborator/${editCollaborator._id}`, {collaboratorEdition: true, stepProcessTwo: 'solicitar_preenchimento' })
                .then(response => {

                    async function sendReminder(){

                        let idCollaborator = editCollaborator._id;

                        await api.get(`/collaborator-send-reminder?_id=${idCollaborator}`)
                        .then(response => {

                            let personalEmail = ''

                            if(editCollaborator?.email) {
                                personalEmail  = editCollaborator.email;
                            }
                            else {
                                personalEmail  = editCollaborator.personalEmail;
                            }

                            loadeditcollaborator(id)
                            swal({ icon: "success", title: "Sucesso!", text: `E-mail enviado com sucesso para ${personalEmail}.` });

                        }).catch(error => {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao enviar o e-mail, tente novamente mais tarde." });
                        });

                    }
                    sendReminder()

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Ocorreu um erro no momento, por favor, tente mais tarde." });
                });

            }
            else  {
                setLoadSolicitarPreenchimento(false)
                setVisibleInforEmail(true)
            }

        }

    }

    return (
        <>
                <div className="formPersonalDAta">
                {load ?
                ''
                :
                (editCollaborator &&
                editCollaborator.stepProcessTwo === 'escolher')
                ||
                editCollaborator.stepProcessTwo === 'solicitar_preenchimento'
                ?
                <WaitingFill
                    id={id}
                    editCollaborator={editCollaborator && editCollaborator !== null ? editCollaborator : ''}
                    onCollaboratorInputChange={myChangeHandlerAdminEdition}
                    onCollaboratorInputDeleteChange={myChangeHandlerExcludeAdmssion}
                    deleteAdmission={excludeAdmssion}
                    visibleInforEmail={visibleInforEmail}
                    FecharInforEmail={FecharInforEmail}
                    EditStepProcessTwo={EditStepProcessTwo}
                    loadPreencherManualmente={loadPreencherManualmente}
                    loadSolicitarPreenchimento={loadSolicitarPreenchimento}
                />
                :
                <>
                <PersonalData
                    id={id}
                    path={path}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    setCollaborator={setCollaborator}
                    setCollaboratorUpdate={setCollaboratorUpdate}
                    ufDistrito={collaboratorUpdate.bornState ? collaboratorUpdate.bornState : collaborator.bornState}
                    onCollaboratorInputChange={myChangeHandler}
                    ufEstados={ufEstados}
                    ufEstadoEscolhido={collaboratorUpdate.bornState ? collaboratorUpdate.bornState : collaborator.bornState}
                    ufCity={collaboratorUpdate.bornCity ? collaboratorUpdate.bornCity : collaborator.bornCity}
                    LoaddateOfBirth={LoaddateOfBirth}
                    Loadgender               ={Loadgender             }
                    LoadfatherName           ={LoadfatherName         }
                    LoadmotherName           ={LoadmotherName         }
                    LoadmaritalStatus        ={LoadmaritalStatus      }
                    Loadnationality          ={Loadnationality        }
                    Loadethnicity            ={Loadethnicity          }
                    LoadbornState            ={LoadbornState          }
                    LoadbornCity             ={LoadbornCity           }
                    focusDateOfBirth         ={focusDateOfBirth        }
                    focusgender              ={focusgender             }
                    focusfatherName          ={focusfatherName         }
                    focusmotherName          ={focusmotherName         }
                    focusmaritalStatus       ={focusmaritalStatus      }
                    focusnationality         ={focusnationality        }
                    focusotherNationality    ={focusotherNationality   }
                    focusethnicity           ={focusethnicity          }
                    focusbornState           ={focusbornState          }
                    focusbornCity            ={focusbornCity           }
                    Loadsetnationality       ={Loadsetnationality      }
                    LoadsetbornCity ={LoadsetbornCity}
                    LoadsetbornState={LoadsetbornState}
                />
                <ContactAddress
                    collaboratorUpdate       ={collaboratorUpdate}
                    id                       ={id}
                    path                     ={path}
                    onCollaboratorInputChange={myChangeHandler}
                    LoadaddressNumber        ={LoadaddressNumber      }
                    Loadaddress              ={Loadaddress            }
                    Loadphone                ={Loadphone              }
                    Loadcellphone            ={Loadcellphone          }
                    Loadzipcode              ={Loadzipcode            }
                    LoadaddressNeighborhood  ={LoadaddressNeighborhood}
                    LoadaddressCity          ={LoadaddressCity        }
                    LoadaddressState         ={LoadaddressState       }
                    focusaddressNumber       ={focusaddressNumber      }
                    focusaddress             ={focusaddress            }
                    focusphone               ={focusphone              }
                    focuscellphone           ={focuscellphone          }
                    focuszipcode             ={focuszipcode            }
                    focusaddressNeighborhood ={focusaddressNeighborhood}
                    focusaddressCity         ={focusaddressCity        }
                    focusaddressState        ={focusaddressState       }
                />
                <AcademicEducation
                    id={id}
                    collaboratorSession={collaborator}
                    onCollaboratorInputChange={myChangeHandler}
                    onCollaboratorInputChangeSchooling={myChangeHandlerSchooling}
                    schooling={schooling}
                    handleDropSchooling={handleDropSchooling}
                    deleteFilesSchooling={deleteFilesSchooling}
                    fileNamesSchooling={fileNamesSchooling}
                    handleSubmitSchooling={handleSubmitSchooling}
                    loadButtonSchooling={loadButtonSchooling}
                    loadSchooling={loadSchooling}
                    loadInstitution={loadInstitution}
                    loadCourse={loadCourse}
                    registeredSchooling={registeredSchooling}
                    deleteSchooling={deleteSchooling}
                    focusSchooling={focusSchooling}
                    focusCourse={focusCourse}
                    focusInstitution={focusInstitution}
                    focusBottomAcademic={focusBottomAcademic}
                    loadBottomAcademic={loadBottomAcademic}
                />
                <Courses id={id}/>
                <Dependents
                    id={id}
                    collaboratorSession={collaborator}
                    onCollaboratorInputChangeNoDependent={myChangeHandlerNoDependent}
                    onCollaboratorInputChangeDependent={myChangeHandlerDependents}
                    loadDependents                    ={loadDependents}
                    loadNameDependent                 ={loadNameDependent}
                    loadRelationship                  ={loadRelationship}
                    loadDateOfBirth                   ={loadDateOfBirth}
                    loadSchoolingDependent            ={loadSchoolingDependent}
                    fileNamesDependents               ={fileNamesDependents}
                    dependents                        ={dependents}
                    registeredDependents              ={registeredDependents}
                    handleDropDependent               ={handleDropDependent}
                    deleteFilesDependent              ={deleteFilesDependent}
                    handleSubmitDependent             ={handleSubmitDependent}
                    deleteDependent                   ={deleteDependent}
                    focusNameDependent                ={focusNameDependent}
                    focusRelationshipDependent        ={focusRelationshipDependent}
                    focusDateofBirthDependent         ={focusDateofBirthDependent}
                    focusSchoolingDependente          ={focusSchoolingDependente}
                    focusBottomDependent              ={focusBottomDependent}
                    loadBottomDependent               ={loadBottomDependent}
                    noDependentsSaved                 ={noDependentsSaved}
                />
                    <EmergencyContact
                        id={id}
                        loadEmergencyContact={loadEmergencyContact}
                        registeredEmergencyContact={registeredEmergencyContact}
                        loadEmergencyCellphone={loadEmergencyCellphone}
                        loademErgencyName={loademErgencyName}
                        loadRelationshipEmergencyContact={loadRelationshipEmergencyContact}
                        onCollaboratorInputChangeEmergencyContact={myChangeHandlerEmergencyContact}
                        handleSubmitEmergencyContact={handleSubmitEmergencyContact}
                        handleDeleteEmergencyContact={handleDeleteEmergencyContact}
                        emergencyContact                          ={emergencyContact}
                        focusEmergencyName                        ={focusEmergencyName}
                        focusRelationshipEmergency                ={focusRelationshipEmergency}
                        focusEmergencyCellphone                   ={focusEmergencyCellphone}
                        focusBottomEmergencyContact               ={focusBottomEmergencyContact}
                        loadBottomEmergencyContact                ={loadBottomEmergencyContact}
                    />
                </>
                 }

                {
                 editCollaborator && editCollaborator.stepProcessTwo === 'preencher_manualmente' ?
                 <div className="buttons">
                    <div className="delete-btn">
                        <button onClick={excludeAdmssion} type="button" className="delete">
                            Excluir processo
                        </button>
                    </div>
                    <div className="saved-btn">
                        <button onClick={save} type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar e continuar</button>
                    </div>
                    <NavLink to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/${id}`} className="back">Voltar</NavLink>
                </div>
                 :
                 ''
                }
            </div>
        </>
    );
}
