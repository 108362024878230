import React from 'react';
import { NavLink } from 'react-router-dom'
import Checklist from './checklist';

export default function headerEtapaNovoColaborador({ 
    id, 
    stepOne, 
    stepTwo, 
    stepThree, 
    stepFour,
    stepConclusion, 
    registeredEmergencyContact,
    collaboratorInfor,
    path }) {

    // const StepsNewAdmission               = parseInt(localStorage.getItem('@peopleview/StepsNewAdmission'));

    return (
        <>
            <div className="progressBarAdmission">
                <div className='titulo-page'>
                    <h2>
                        {
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas' ? 
                            "Informações básicas" : 
                            path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id' ?
                            "Dados pessoais" :
                            path === '/colaboradores/nova-admissao/formulario-documentos/:id' ?
                            "Documentos":
                            path === '/colaboradores/nova-admissao/formulario-beneficios/:id' ?
                            "Benefícios" :
                            path === '/colaboradores/nova-admissao/formulario-beneficios/:id' ?
                            "Conclusão":
                            path === '/colaboradores/nova-admissao/finalizado/:id' ?
                            "Finalizado":  ""

                        }
                    </h2>

                </div>
                <div className='infor'>
                        {/* classname="previousSession" quando tiver em outra seção */}
                    <div className={
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/' ?
                            "session basicInformation" : "previousSession basicInformation"
                        }>
                        { id ?
                        <>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/${id}`} className="nameSession"><p>Informações básicas</p></NavLink>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/${id}`} className="number"><div><p>1</p></div></NavLink>
                        </>
                        :
                        <>
                        <NavLink to="/colaboradores/nova-admissao/formulario-Informacoes-basicas" className="nameSession"><p>Informações básicas</p></NavLink>
                        <NavLink to="/colaboradores/nova-admissao/formulario-Informacoes-basicas" className="number"><div><p>1</p></div></NavLink>
                        </>
                        }
                    </div>

                    <div className={
                        path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id'? 
                        "session personalData" : 
                        path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                        path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas' ||
                        path === '/colaboradores/nova-admissao/finalizado/:id' ?
                        collaboratorInfor.stepsNewAdmission >= 2 ? "previousSession personalData " : "personalData"
                        :
                        path === '/colaboradores/nova-admissao/formulario-documentos/:id' ||
                        path === '/colaboradores/nova-admissao/formulario-beneficios/:id'  ||
                        path === '/colaboradores/nova-admissao/enviar-contador/:id'?

                        "previousSession personalData" : "personalData"
                    }>
                        { collaboratorInfor.stepsNewAdmission  >= 2  ?
                        <>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${id}`} className="nameSession"><p>Dados pessoais</p></NavLink>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${id}`} className="number"><div><p>2</p></div></NavLink>
                        </>
                        :
                        <>
                        <button className="nameSession" ><p>Dados pessoais</p></button>
                        <button className="number"><div><p>2</p></div></button>
                        {/* <NavLink to="#" className="nameSession"><p>Dados pessoais</p></NavLink>
                        <NavLink to="#" className="number"><div><p>2</p></div></NavLink> */}
                        </>
                        }
                        {/* <div className="Linha"></div> */}
                    </div>
                    <div className={

                        path === '/colaboradores/nova-admissao/formulario-documentos/:id' ?
                        "session documents" 
                        :
                        path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id'||
                        path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                        path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas' ||
                        path === '/colaboradores/nova-admissao/formulario-beneficios/:id'  ||
                        path === '/colaboradores/nova-admissao/enviar-contador/:id' ||
                        path === '/colaboradores/nova-admissao/finalizado/:id' ?
                        collaboratorInfor.stepsNewAdmission >= 3 ? "previousSession documents"  : "documents"
                        : "documents"
                        
                    }>
                        {
                         collaboratorInfor.stepsNewAdmission  >= 3 ?
                        <>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-documentos/${id}`} className="nameSession"><p>Documentos</p></NavLink>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-documentos/${id}`} className="number"><div><p>3</p></div></NavLink>
                        </>
                        :
                        <>
                        <button className="nameSession" ><p>Documentos</p></button>
                        <button className="number" ><div><p>3</p></div></button>
                        </>
                        }
                        {/* <div className="Linha"></div> */}
                    </div>
                    <div className={
                            path === '/colaboradores/nova-admissao/formulario-beneficios/:id'  ?
                            "session benefits"
                            :
                            path === '/colaboradores/nova-admissao/formulario-documentos/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id'||
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                            path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas' ||
                            path === '/colaboradores/nova-admissao/enviar-contador/:id' ||
                            path === '/colaboradores/nova-admissao/finalizado/:id' ?
                            collaboratorInfor.stepsNewAdmission  >= 4 ? "previousSession benefits"  : "benefits"
                            : "benefits"
                        }>
                        {
                         collaboratorInfor.stepsNewAdmission  >= 4 ?
                        <>
                            <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${id}`} className="nameSession"><p>Benefícios</p></NavLink>
                            <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${id}`} className="number"><div><p>4</p></div></NavLink>
                        </>
                        :
                        <>
                            <button className="nameSession" ><p>Benefícios</p></button>
                            <button className="number"><div><p>4</p></div></button>
                        </>
                        }

                        {/* <div className="Linha linha-right"></div> */}
                    </div>

                    {/* classname="previousSession" quando tiver em outra seção */}
                    <div className={
                        path === '/colaboradores/nova-admissao/finalizado/:id' ||
                        path === '/colaboradores/nova-admissao/enviar-contador/:id'  ?
                        "session conclusion"
                        :
                        path === '/colaboradores/nova-admissao/formulario-documentos/:id' ||
                        path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id'||
                        path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas/:id' ||
                        path === '/colaboradores/nova-admissao/formulario-Informacoes-basicas' ||
                        path === '/colaboradores/nova-admissao/formulario-beneficios/:id'  ?
                        collaboratorInfor.stepsNewAdmission  === 5 ? 
                        "previousSession conclusion"  :
                        
                        "conclusion"
                        : "conclusion"
                     }>
                       
                    {
                        collaboratorInfor.stepsNewAdmission  === 5 ?
                            <>
                                <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${id}`} className="nameSession"><p>Conclusão</p></NavLink>
                                <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${id}`} className="number"><div><p>5</p></div></NavLink>
                            </>
                            :
                            <>
                                <button className="nameSession" ><p>Conclusão</p></button>
                                <button className="number"><div><p>5</p></div></button>
                            </>
                        }
                    </div>
                </div>
                </div>

                <div className='barra-progress-novaAdmissao'>
                    <div className='barra-progress'>
                        <div className='etapas'
                        // className={stepOne &&
                        //     stepOne === true ? "stepOne" : "" ||
                        //     stepTwo === true ? "stepTwo" : "" ||
                        //     stepThree === true ? "stepThree" : "" ||
                        //     stepFour === true ? "stepFour" : "stepOne"
                        // }

                        // className={stepOne &&
                        //     stepOne === true && stepTwo === false && stepThree === false && stepFour === false ?
                        //     "stepOne" :

                        //     (stepOne === true && stepTwo === true && stepThree === false && (stepFour === false || stepFour === "undefined")) ||
                        //     path === '/colaboradores/nova-admissao/formulario-dados-pessoais/:id'
                        //     ?
                        //     "stepTwo" :

                        //     (stepOne === true && stepTwo === true && stepThree === true &&  (stepFour === false || stepFour === "undefined")) ||
                        //     path === '/colaboradores/nova-admissao/formulario-documentos/:id'
                        //     ?
                        //     "stepThree" :

                        //     stepOne === true && stepTwo === true && stepThree === true && stepFour === true ?
                        //     "stepFour" : "stepOne"
                        // }


                        >
                            <span 
                            className="ativo"
                            ></span>
                            <span className={
                                collaboratorInfor.stepsNewAdmission >= 2 ?
                                "ativo" :""
                            }></span>
                            <span className={
                                collaboratorInfor.stepsNewAdmission >= 3 ?
                                "ativo" :""
                            }
                            ></span>
                            <span className={
                                collaboratorInfor.stepsNewAdmission >= 4 ?
                                "ativo" : ""
                            }></span>
                            <span className={
                                collaboratorInfor.stepsNewAdmission === 5 ?
                                "ativo" : ""

                            }></span>
                        </div>
                    </div>


                </div>


                {

                    path && path === '/colaboradores/nova-admissao/finalizado/:id'  ? "" :
                    <>
                        <div className='check-list-nv'>
                            <Checklist collaboratorId={id} path={path}/>
                        </div>
                    </>


                }
        </>
    );
}
