import React from 'react';
import './styles.scss';
import Header from '../../../components/User/registerDoc/header';
import CheckMelon from '../../../assets/images/check-melon.svg';

export default function RegisterDoc(props) {
    const { token } = props.match.params;
    return (

            <>
            <div className="pagina-Token">
                <Header/>
                <div className="dados-salvo">
                    <img src={CheckMelon} alt="Icone de sucesso" />
                    <h2>Progresso salvo, mas não esqueça de voltar</h2>
                    <p>Você pode terminar de preencher depois, mas lembre-se
                        que este link só ficará ativo por 48 horas.</p>
                    <p>Caso o perca, acesse-o pelo seu email ou fale com o RH.</p>
                <a href={`/cadastro-colaborador/${token}`}className="btnGreen">Preencher agora</a>
                </div>
                <div style={{height: "38px"}}></div>

            </div>
            </>
    );
}

