import React, { Component } from 'react';
export default class HeaderRegistreDoc extends Component {
    render() {
        return (
            <>
                <header className="header-request-registration">
                    <div className="cabeçalho">
                        <h1>Meus dados</h1>
                    </div>
                </header>
            </>
        );
    }
}
