import React from 'react';
import SubMenu from './subMenu';
import CPF from './Documentos/Cpf';
import WorkCard from './Documentos/WorkCard';
import VoterTitle from './Documentos/VoterTitle';
import Rg from './Documentos/RG';
import Cnh from './Documentos/CNH';
import Reservist from './Documentos/Reservist';


export default function ColProfilePersonalDoc({
    id,
    collaborator,
    loadPage,
    onCollaboratorInputChange,
    collaboratorUpdate,
    ufEstados,
    ufDistrito,
    OnSubmit,
    loadContinue,
    EditCpfVisible,
    visibleCPF,
    EditWorkCardVisible,
    visibleWorkCard,
    EditRGVisible,
    visibleRG,
    EditVoterTitleVisible,
    visibleVoterTitle,
    EditReservistVisible,
    visibleReservist,
    EditCNHVisible,
    visibleCNH,
    filesSavedCPF,
    loadCPF,
    handleDropCPF,
    deleteFileCPF,
    filesSavedWorkCard,
    loadWorkCard,
    handleDropWorkCard,
    deleteFileWorkCard,
    filesSavedVoterTitle,
    loadVoterTitle,
    deleteFileVoterTitle,
    handleDropVoterTitle,
    filesSavedRG,
    loadRG,
    deleteFileRG,
    handleDropRG,
    filesSavedCNH,
    loadCNH,
    deleteFileCNH,
    handleDropCNH,
    filesSavedReservist,
    loadReservist,
    deleteFileReservist,
    handleDropReservist,
    setSelectWalletUF,
    selectWalletUF,
    setCollaboratorUpdate,
    setSelectRGufOrgan,
    selectRGufOrgan,
    ufReservationCard,
    selectUfReservationCard,
    setSelectUfReservationCard,
    selectCityReservationCard,
    setSelectCityReservationCard, 
    selectCnhCategory,
    setSelectCnhCategory,
    selectUfCNH,
    setSelectUfCNH
 }) {
    const estadosOrgam = [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins"
    ]
        return (
            <>
                <SubMenu id={id} collaborator={collaborator}/>
                <div className="box-infor-perfil profile-personal-doc">
                    <CPF
                        id={id}
                        loadPage={loadPage}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstados={ufEstados}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        EditCpfVisible={EditCpfVisible}
                        visibleCPF={visibleCPF}
                        filesSavedCPF={filesSavedCPF}
                        loadCPF={loadCPF}
                        handleDropCPF={handleDropCPF}
                        deleteFileCPF={deleteFileCPF}
                    />
                    <WorkCard
                        id={id}
                        loadPage={loadPage}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstados={ufEstados}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        EditWorkCardVisible={EditWorkCardVisible}
                        visibleWorkCard={visibleWorkCard}
                        filesSavedWorkCard={filesSavedWorkCard}
                        loadWorkCard={loadWorkCard}
                        handleDropWorkCard={handleDropWorkCard}
                        deleteFileWorkCard={deleteFileWorkCard}
                        setCollaboratorUpdate={setCollaboratorUpdate}
                        setSelectWalletUF={setSelectWalletUF}
                        selectWalletUF={selectWalletUF}
                        estadosOrgam={estadosOrgam}
                    />
                    <VoterTitle
                        id={id}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        loadPage={loadPage}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstados={ufEstados}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        EditVoterTitleVisible={EditVoterTitleVisible}
                        visibleVoterTitle={visibleVoterTitle}
                        filesSavedVoterTitle={filesSavedVoterTitle}
                        loadVoterTitle={loadVoterTitle}
                        deleteFileVoterTitle={deleteFileVoterTitle}
                        handleDropVoterTitle={handleDropVoterTitle}
                    />
                    <Rg
                        id={id}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        loadPage={loadPage}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstados={ufEstados}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        EditRGVisible={EditRGVisible}
                        visibleRG={visibleRG}
                        filesSavedRG={filesSavedRG}
                        loadRG={loadRG}
                        deleteFileRG={deleteFileRG}
                        handleDropRG={handleDropRG}
                        setCollaboratorUpdate={setCollaboratorUpdate}
                        setSelectRGufOrgan={setSelectRGufOrgan}
                        selectRGufOrgan={selectRGufOrgan}
                        estadosOrgam={estadosOrgam}
                    />
                    <Cnh
                        id={id}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        loadPage={loadPage}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstados={ufEstados}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        EditCNHVisible={EditCNHVisible}
                        visibleCNH={visibleCNH}
                        filesSavedCNH={filesSavedCNH}
                        loadCNH={loadCNH}
                        deleteFileCNH={deleteFileCNH}
                        handleDropCNH={handleDropCNH}
                        setCollaboratorUpdate={setCollaboratorUpdate}
                        selectCnhCategory={selectCnhCategory}
                        setSelectCnhCategory={setSelectCnhCategory}
                        selectUfCNH={selectUfCNH}
                        setSelectUfCNH={setSelectUfCNH}
                        estadosOrgam={estadosOrgam}
                    />
                    <Reservist
                        id={id}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        loadPage={loadPage}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstadoEscolhido={collaborator.bornState}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        collaboratorState={collaborator}
                        ufDistrito={collaborator.ufReservationCard}
                        ufReservationCard={ufReservationCard}
                        ufCity={collaborator.cityReservationCard}
                        EditReservistVisible={EditReservistVisible}
                        visibleReservist={visibleReservist}
                        filesSavedReservist={filesSavedReservist}
                        loadReservist={loadReservist}
                        deleteFileReservist={deleteFileReservist}
                        handleDropReservist={handleDropReservist}
                        setCollaboratorUpdate={setCollaboratorUpdate}
                        selectUfReservationCard={selectUfReservationCard}
                        setSelectUfReservationCard={setSelectUfReservationCard}
                        selectCityReservationCard={selectCityReservationCard}
                        setSelectCityReservationCard={setSelectCityReservationCard}
                    />
                </div>
            </>
        );
}

