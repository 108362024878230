import React from 'react';
// import { NavLink } from 'react-router-dom'
import Close from '../../../assets/images/close.svg';
// import IconsInformation from '../../IconsInformation';
// import ImgExemplo from '../../../assets/images/emer.jpg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import IconArrowVazioVerde from '../../../assets/images/arrow-vazio-verde.svg';
import HeaderAdmin from '../../../components/HeaderAdmin';
import { Modal } from "react-bootstrap";

export default function headerHabilidades ({
    history,
    name,
    load,
    loadFilterCollaboratorForThisSkill,
    collaboratorFilterForThisSkill,
    myChangeHandlerToAddCollaborator,
    collaborators,
    myChangeHandlerExcludeCollaboratorAdded,
    loadCollaboratorFilterForThisSkill,
    loadToAddCollaborator,
    loadExcludeCollaboratorAdded,
    allCollaboratorsExclude,
    myChangeHandlerToSaveSkill,
    loadNameCadHabilidade,
    loadRegisterNewSkill,
    saveSkill,
    CastrarHabilidade,
    visibleCastrarHabilidade,
    loadFilterCollaboratorForThisGroup,
    loadCollaboratorFilterForThiGroup,
    collaboratorFilterForThisGroup,
    myChangeHandlerToAddCollaboratorGroup,
    loadToAddCollaboratorGroup,
    collaboratorsGroup,
    loadExcludeCollaboratorAddedGroup,
    myChangeHandlerExcludeCollaboratorAddedGroup,
    allCollaboratorsExcludeGroup,
    filterSkillForThisGroup,
    skillFilterForThisGroup,
    loadSkillFilterForThisGroup,
    myChangeHandlerSkillToAddGroup,
    skillsGroup,
    loadToAddSkillTorGroup,
    loadExcludeSkillAddedGroup,
    allSkillExcludeGroup,
    myChangeHandlerExcludeSkillAddedGroup,
    myChangeHandlerToSaveGroup,
    loadRegisterNewGroup,
    saveGroup,
    CastrarGrupo,
    loadNameCadGrupo,
    visibleCastrarGrupo,
    loadNameCadGrupoHab,
    loadNameCadGrupoCollaborator,
    user,
    loadPageNotifications,
    notifications,
    notificationsRead,
    readTrue,
    showMore,
    indexUpdate,
    closeSelect,
    handleKeyPressCriarHabilidade,
    handleKeyPressCriarGrupo
}) {
    console.log("collaboratorFilterForThisSkill header", collaboratorFilterForThisSkill)

    return (
        <>

            <HeaderAdmin className="header-habilidades">
                <div>
                    <h1>Habilidades</h1>
                    <p>Gerencie as habilidades dos colaboradores e grupos</p>
                </div>
                <div className='habilidade-h'>
                    <button className="btnGreen more btn-cadastrar-h"  onClick={() => CastrarHabilidade()} >Cadastrar nova habilidade</button>
                    <button className="btnGreen more btn-cadastrar-g" onClick={() => CastrarGrupo()}>Cadastrar novo grupo</button>
                </div>

            </HeaderAdmin>

            <div className='habilidade-h-mobile'>
                <button className="btnGreen more btn-cadastrar-h"  onClick={() => CastrarHabilidade()} >Cadastrar nova habilidade</button>
                <button className="btnGreen more btn-cadastrar-g" onClick={() => CastrarGrupo()}>Cadastrar novo grupo</button>
            </div>

                {/* <header className="header-geral header-habilidades">
                <div className="cabeçalho">
                    <h1>Habilidades</h1>
                    <p>Gerencie as habilidades dos colaboradores e grupos</p>
                </div>
                <div className="infor">
                    <IconsInformation
                    history={history}
                    showMore={showMore}
                    indexUpdate={indexUpdate}
                    loadPageNotifications={loadPageNotifications}
                    notifications={notifications}
                    notificationsRead={notificationsRead}
                    readTrue={readTrue}
                    />
                </div>

            </header> */}

            <Modal show={visibleCastrarHabilidade} restoreFocus={false} className="popup-geral popup-criar-habilidade" onHide={CastrarHabilidade}>
                <div  onClick={closeSelect}>
                    <div className="titulo">
                        <h4>Cadastrar nova habilidade  <p className="btn-fechar" onClick={CastrarHabilidade}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="form-nova-habilidade">
                        <input 
                            type="text" 
                            onChange={myChangeHandlerToSaveSkill} 
                            name="name" 
                            placeholder="Nome da habilidade" 
                            className={loadNameCadHabilidade ? 'inputErro' : ''}
                            onKeyPress={(event) => handleKeyPressCriarHabilidade(event)} 
                            id="nameHabilidadeCadastrar"
                            autocomplete="autocompleteoff"
                        />
                        {loadNameCadHabilidade ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}



                        <input name="name" value={name} autocomplete="autocompleteoff" onChange={loadFilterCollaboratorForThisSkill} placeholder="Colaboradores com esta habilidade" className="busca"/>
                        <div className="resultado-busca-atrelados-col" style={collaboratorFilterForThisSkill && collaboratorFilterForThisSkill.length !== 0 ? {display:'block'} : {display:'none'}}>
                        {
                            loadCollaboratorFilterForThisSkill ?
                            'Carregando ...'
                            :
                            collaboratorFilterForThisSkill && collaboratorFilterForThisSkill.length !== 0 ?
                            collaboratorFilterForThisSkill.map((collaboratorFilter, index) => (
                                <button onClick={myChangeHandlerToAddCollaborator} id={collaboratorFilter._id} key={index}>
                                    {
                                        collaboratorFilter.avatar !== undefined && collaboratorFilter.avatar !== "undefined" ?
                                        <>
                                        <div className="img-col">
                                            <img id={collaboratorFilter._id} src={collaboratorFilter.avatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img id={collaboratorFilter._id} src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <span id={collaboratorFilter._id}>{collaboratorFilter.name}</span>
                                </button>
                            ))
                            :
                            ('')
                        }
                        </div>

                        <div className="vazio-busca" style={collaborators && collaborators.length === 0 ? {display:'block'} : {display:'none'}}>
                            <img src={IconArrowVazioVerde} alt="Seta Verda" />
                            <span>Insira Calaboradores</span>
                        </div>

                        {/* //////// COLABORADORES ADICIONADOS  ///////*/}

                        <div className="lista-atrelados-col" style={collaborators && collaborators.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                loadToAddCollaborator ?
                                'Carregando ...'
                                :
                                loadExcludeCollaboratorAdded ?
                                'carregando ...'
                                :
                                collaborators && collaborators.length !== 0 ?
                                collaborators
                                .filter(filtercollaborator =>
                                    filtercollaborator._id !== allCollaboratorsExclude
                                )
                                .map((addedCollaborator, index) => (
                                    <div key={index} className="infor-col">
                                        {/* {addedCollaborator.avatar} */}
                                        {
                                            addedCollaborator && addedCollaborator.avatar !== undefined && addedCollaborator.avatar !== "undefined" ?
                                            <>
                                            <div className="img-col">
                                                <img src={addedCollaborator.avatar} alt="nome" />
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="colSemFoto" >
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <div className="nome-setor">
                                            <p className="nome">
                                                {addedCollaborator.name}
                                            </p>
                                            <div className="setor">
                                                <p>{addedCollaborator.occupation}</p>
                                            </div>
                                        </div>
                                        <button onClick={myChangeHandlerExcludeCollaboratorAdded} value={addedCollaborator._id} className="remover">Remover</button>
                                    </div>
                                ))
                                :
                                ''
                            }
                        </div>
                        {/* //////// COLABORADORES ADICIONADOS ADICIONADOS  FIM ///////*/}


                    </div>
                    <button onClick={saveSkill} className={loadRegisterNewSkill ? "button-salve btnGreen load" : "button-salve btnGreen"}>Salvar nova habilidade</button>
                </div>
            </Modal>

            <Modal show={visibleCastrarGrupo} className="popup-geral popup-criar-habilidade" onHide={CastrarGrupo}>
                <div onClick={closeSelect}>
                    <div className="titulo">
                        <h4>Cadastrar novo grupo  <p className="btn-fechar" onClick={CastrarGrupo}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="form-nova-habilidade">
                        <input 
                            onChange={myChangeHandlerToSaveGroup} 
                            name="nameGroup" 
                            autocomplete="autocompleteoff"
                            placeholder="Nome do grupo" 
                            className={loadNameCadGrupo ? 'inputErro' : ''}
                            onKeyPress={(event) => handleKeyPressCriarGrupo(event)} 
                            id="nameGrupoCadastrar"
                        />
                        {loadNameCadGrupo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        <h4>Habilidades para este grupo</h4>

                        <input name="name" value={name} onChange={filterSkillForThisGroup} autocomplete="autocompleteoff" placeholder="Habilidades para este grupo" className={loadNameCadGrupoHab ? 'inputErro busca' : 'busca'}/>
                        {loadNameCadGrupoHab ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}




                        {/* //////// RESULTADO DE BUSCA HABILIDADE  ///////*/}
                        <div className="resultado-busca-habilidade" style={skillFilterForThisGroup && skillFilterForThisGroup.length !== 0 ? {display: 'block'} : {display:'none'}}>
                            {
                                loadSkillFilterForThisGroup ?
                                'Carregando ...'
                                :
                                skillFilterForThisGroup && skillFilterForThisGroup.length !== 0 ?
                                skillFilterForThisGroup.map((skillFilterGroup, index) => (
                                    <p onClick={myChangeHandlerSkillToAddGroup} id={skillFilterGroup._id} key={index}>{skillFilterGroup.name}</p>
                                )):
                                ''
                            }

                        </div>
                        {/* //////// FIM RESULTADO DE BUSCA HABILIDADE  ///////*/}

                        {/* //////// ESTADO VAZIO DE BUSCA DE HABILIDADE  ///////*/}
                        {skillsGroup && skillsGroup && skillsGroup.length === 0 ?
                            <div className="vazio-busca" >
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira Habilidades</span>
                            </div>
                        :
                        ""
                        }

                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE HABILIDADE  ///////*/}

                        {/* //////// HABILIDADES ADICIONADAS  ///////*/}
                        {skillsGroup && skillsGroup.length !== 0 ?
                            <div className="habilidades-adicionada" >
                            {
                                loadToAddSkillTorGroup ?
                                'Carregando ...'
                                :
                                skillsGroup && skillsGroup.length !== 0 ?
                                skillsGroup
                                .filter(filterSkillGroup =>
                                    filterSkillGroup._id !== allSkillExcludeGroup
                                )
                                .map((skillGroup, index) => (
                                    <p key={index}>{skillGroup.name} <button onClick={myChangeHandlerExcludeSkillAddedGroup} value={skillGroup._id} className="excluir"></button></p>
                                )):
                                ''
                            }
                            </div>
                        :
                        ""
                        }

                        {/* //////// FIM HABILIDADES ADICIONADAS  ///////*/}

                        <h4>Colaboradores para este grupo</h4>

                        <input name="name" value={name} onChange={loadFilterCollaboratorForThisGroup} autocomplete="autocompleteoff" placeholder="Colaboradores para este grupo"  className={loadNameCadGrupoCollaborator ? 'inputErro busca' : 'busca'}/>
                        {loadNameCadGrupoCollaborator ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE COLABORADOR  ///////*/}

                        <div className="resultado-busca-atrelados-col" style={collaboratorFilterForThisGroup && collaboratorFilterForThisGroup.length !== 0 ? {display:'block'} : {display:'none'}}>
                        {

                            loadCollaboratorFilterForThiGroup ?
                            'Carregando ...'
                            :
                            collaboratorFilterForThisGroup && collaboratorFilterForThisGroup.length !== 0 ?
                            collaboratorFilterForThisGroup.map((collaboratorForThisGroup, index) => (
                                <button onClick={myChangeHandlerToAddCollaboratorGroup} id={collaboratorForThisGroup._id} key={index}>
                                    {
                                        collaboratorForThisGroup && collaboratorForThisGroup.avatar !== undefined && collaboratorForThisGroup.avatar !== "undefined" ?
                                        <div className="img-col">
                                            <img id={collaboratorForThisGroup._id} src={collaboratorForThisGroup.avatar} alt={collaboratorForThisGroup.name} />
                                        </div>
                                        :
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img id={collaboratorForThisGroup._id} src={IconUserNone} alt={collaboratorForThisGroup.name} />
                                            </div>
                                        </div>
                                    }
                                <span id={collaboratorForThisGroup._id}>{collaboratorForThisGroup.name}</span>
                                </button>
                            ))
                            :
                            ''
                        }
                        </div>

                        <div className="vazio-busca" style={collaboratorsGroup && collaboratorsGroup.length === 0 ? {display:'block'} : {display:'none'}}>
                            <img src={IconArrowVazioVerde} alt="Seta Verda" />
                            <span>Insira Calaboradores</span>
                        </div>

                        {/* //////// COLABORADORES ADICIONADOS ADICIONADOS  ///////*/}
                        <div className="lista-atrelados-col" style={collaboratorsGroup && collaboratorsGroup.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                loadExcludeCollaboratorAddedGroup ?
                                'Carregando ...'
                                :
                                collaboratorsGroup && collaboratorsGroup.length !== 0 ?
                                collaboratorsGroup
                                .filter(filtercollaborator =>
                                    filtercollaborator._id !== allCollaboratorsExcludeGroup
                                )
                                .map((collaboratorGroup, index) => (
                                    <div className="infor-col" key={index}>
                                        {
                                           collaboratorGroup && collaboratorGroup.avatar !== undefined && collaboratorGroup.avatar !== "undefined" ?
                                            <div className="img-col">
                                                <img src={collaboratorGroup.avatar} alt={collaboratorGroup.name} />
                                            </div>
                                            :
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt={collaboratorGroup.name} />
                                                </div>
                                            </div>
                                        }
                                        <div className="nome-setor">
                                            <p className="nome">
                                                {collaboratorGroup.name}
                                            </p>
                                            <div className="setor">
                                                <p>{collaboratorGroup.occupation}</p>
                                            </div>
                                        </div>
                                        <button onClick={myChangeHandlerExcludeCollaboratorAddedGroup} value={collaboratorGroup._id} className="remover">Remover</button>
                                    </div>
                                ))
                                :
                                ''
                            }
                        </div>
                        {/* //////// COLABORADORES ADICIONADOS ADICIONADOS  FIM ///////*/}


                    </div>
                    <button onClick={saveGroup} className={loadRegisterNewGroup ? "button-salve btnGreen load" : "button-salve btnGreen" }>Salvar novo grupo</button>
                </div>
            </Modal>

        </>
    );
}

