import React from 'react';
// import IconsInformation from '../../IconsInformation';
// import ImgExemplo from '../../../assets/images/emer.jpg';
import HeaderAdmin from '../../HeaderAdmin';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import IconArrowVazioVerde from '../../../assets/images/arrow-vazio-verde.svg';
import Close from '../../../assets/images/close.svg';
import IconADD from '../../../assets/images/add-branco.svg'
import Carregando from '../../../assets/images/carregando.gif';
// import api from '../../../services/api';
import CurrencyInput from 'react-currency-input';

import { Modal } from "react-bootstrap";


export default function headerCargosListHeader({
    history,
    loadPage,
    occupation,
    loadfilteroccupation,
    name,
    nameSkill,
    nameCollaborator,
    CadastraCargo,
    visibleCadCargo,
    loadNameCadCargo,
    loadMinSalaryCadCargo,
    loadMaxSalaryCadCargo,
    loadDepartamentoCadCargo,
    loadHabilidadeCadCargo,
    loadColCadCargo,
    loadFilterCollaboratorForThisOccupation,
    collaboratorFilterForThisOccupation,
    loadCollaboratorFilterForThiOccupation,
    myChangeHandlerToAddCollaboratorOccupation,
    collaboratorsOccupation,
    loadToAddCollaboratorOccupation,
    myChangeHandlerExcludeCollaboratorAddedOccupation,
    loadExcludeCollaboratorAddedOccupatio,
    allCollaboratorsExcludeOccupation,
    filterSkillForThisOccupation,
    skillFilterForThisOccupation,
    loadSkillFilterForThisOccupation,
    myChangeHandlerSkillToAddOccupation,
    loadToAddSkillToOccupation,
    skillsOccupation,
    myChangeHandlerExcludeSkillAddedOccupation,
    loadExcludeSkillAddedOccupation,
    allSkillExcludeOccupation,
    myChangeHandlerToSaveOccupation,
    sectors,
    saveOccupation,
    loadRegisterNewOccupation,
    FecharCadastraCargo,
    occupationToSave,
    user,
    closeSelect,
    selectSectorAdd,
    OpenSelectSectorAdd,
    setOccupationToSave,
    handleKeyPressCadastrarCargo
}) {

    return (
        <>
        <HeaderAdmin>
            <div>

                <h1>Cargos</h1>
                <p>Cadastre e gerencie os cargos da sua empresa. {
                loadPage? "":
                occupation && occupation.length === 0  ?
                'Nenhum cargo cadastrado até o momento!'
                :
                occupation.length+' cargos cadastrados'
                } </p>
            </div>
            <div className='cargos'>
                <button className="btnGreen more cadastrar-cargo" onClick={() => CadastraCargo()} >Cadastrar novo cargo</button>
                <form action="">
                    <input
                        name="name"
                        value={name}
                        onChange={loadfilteroccupation}
                        type="text"
                        autoComplete="off"
                        placeholder="Filtrar por cargo"
                        className="campo-busca"
                    />
                </form>
            </div>
        </HeaderAdmin>

        <div className='PgCargosheader'>
                <button className="btnGreen more cadastrar-cargo" onClick={() => CadastraCargo()} >Cadastrar novo cargo</button>
                <button className="cadastrar-cargo-mobile" onClick={() => CadastraCargo()} ><img src={IconADD} alt="add" /></button>
                <form action="">
                    <input name="name" value={name} onChange={loadfilteroccupation} type="text" placeholder="Filtrar por cargo" autoComplete="off" className="campo-busca"/>
                </form>

        </div>


            <Modal show={visibleCadCargo} restoreFocus={false}  className="popup-geral popup-add-cargo" onHide={FecharCadastraCargo}>
                <div className='fechar-select' onClick={closeSelect}>
                    <div className="titulo">
                        <h4>Cadastrar Cargo  <p className="btn-fechar" onClick={FecharCadastraCargo}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="form-novo-cargo">
                        <input type="text" name="name"
                            value={occupationToSave && occupationToSave.name ? occupationToSave.name : ''}
                            onChange={myChangeHandlerToSaveOccupation}
                            placeholder="Nome do cargo"
                            className={loadNameCadCargo ? 'inputErro' : ''}
                            onKeyPress={(event) => handleKeyPressCadastrarCargo(event)}
                            autofocus="true"
                            autocomplete="autocompleteoff"
                            id='nomeCargo'
                        />
                        {loadNameCadCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                        <h4>Faixa salarial</h4>
                        <div className="salar-minMax">
                            <div className="minMax">
                            <CurrencyInput
                                className="salary-input"
                                onChangeEvent={myChangeHandlerToSaveOccupation}
                                name="minimumSalary"
                                autocomplete="autocompleteoff"
                                value={
                                    occupationToSave && occupationToSave.minimumSalary  ?
                                    occupationToSave.minimumSalary
                                    :
                                    ''
                                }
                            />
                            </div>
                            <div className="minMax">

                                <CurrencyInput
                                    className="salary-input"
                                    onChangeEvent={myChangeHandlerToSaveOccupation}
                                    name="maximumSalary"
                                    autocomplete="autocompleteoff"
                                    // placeholder="R$ 0,000" 
                                    value={
                                        occupationToSave && occupationToSave.maximumSalary  ?
                                        occupationToSave.maximumSalary
                                        :
                                        ''
                                    }
                                />

                            </div>
                        </div>
                        <h4>Departamento</h4>

                        <div className='select-x'>
                            <button className={loadDepartamentoCadCargo ? 'inputErro btn-select selectSectorAdd' : 'btn-select selectSectorAdd'}  id="selectSectorAdd" onClick={OpenSelectSectorAdd}>
                                <span  className='span-select-sector'>
                                    {
                                        // occupationToSave && occupationToSave.sector !== undefined ?
                                        // occupationToSave.sector : "Selecione um departamento"


                                            occupationToSave.sector !== undefined && sectors ?
                                            sectors
                                            .filter(filtersector =>
                                                filtersector._id === occupationToSave.sector
                                            ).map((sector, index) => (
                                                sector.name
                                            ))
                                            :
                                            "Selecione um departamento"
                                        }


                                </span>
                            </button>

                            {
                                selectSectorAdd ?
                                    <div className='resultado-busca'>
                                     {
                                        sectors && sectors.length !== 0 ?
                                        sectors.map((sector, index) => (
                                            <button  key={index} onClick={() =>
                                                setOccupationToSave({ ...occupationToSave, "sector": sector._id })}><p>{sector.name}</p></button>
                                        ))
                                        :
                                        <button><p>Nenhum setor cadastrado até o momento!</p></button>
                                    }
                                    </div>
                                :
                                ""
                            }
                        </div>


                        {/* <select onChange={myChangeHandlerToSaveOccupation} name="sector" className={loadDepartamentoCadCargo ? 'inputErro' : ''}>
                            <option>Selecione um departamento</option>
                            {
                                sectors && sectors.length !== 0 ?
                                sectors.map((sector, index) => (
                                    <option name="sector" value={sector._id} key={index}>{sector.name}</option>
                                ))
                                :
                                <option name="sector" value='"-'>Nenhum setor cadastrado até o momento!</option>
                            }

                        </select> */}
                        {loadDepartamentoCadCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                        <h4>Habilidades requeridas</h4>
                        <input
                        name="name"
                        autocomplete="off"
                        value={nameSkill && nameSkill.name !== undefined ? nameSkill.name : ''}
                        onChange={filterSkillForThisOccupation}
                        placeholder="Habilidades requeridas para este cargo"
                        className={loadHabilidadeCadCargo ? 'inputErro busca busca-hab' : 'busca busca-hab'}
                        />
                        {loadHabilidadeCadCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}


                        <div className="resultado-busca-habilidade" style={skillFilterForThisOccupation && skillFilterForThisOccupation.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                skillFilterForThisOccupation &&
                                skillFilterForThisOccupation.length !== 0 ?
                                skillFilterForThisOccupation.map((filterforthisccupation, index) => (
                                    <p onClick={myChangeHandlerSkillToAddOccupation} id={filterforthisccupation._id} key={index}>{filterforthisccupation.name}</p>
                                )):''

                            }
                        </div>
                        {loadToAddSkillToOccupation || loadExcludeSkillAddedOccupation ?""
                            :
                            skillsOccupation && skillsOccupation.length === 0 ?
                            <div className="vazio-busca-habilidade">
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira habilidades</span>
                            </div>
                            :
                            ''
                        }
                        {loadToAddSkillToOccupation || loadExcludeSkillAddedOccupation ?
                            <>
                            <div className='carregamento'>
                                <img src={Carregando} alt="" />

                            </div>
                            </>
                            :
                            skillsOccupation && skillsOccupation.length !== 0 ?
                                <>
                                    <div className="habilidades-adicionada">
                                    {
                                        skillsOccupation &&
                                        skillsOccupation.length !== 0 ?
                                        skillsOccupation
                                        .filter(filterSkillOccupation =>
                                            filterSkillOccupation._id !== allSkillExcludeOccupation
                                        )
                                        .map((occupationskill, index) => (
                                            <p key={index}>{occupationskill.name} <button onClick={myChangeHandlerExcludeSkillAddedOccupation} value={occupationskill._id} className="excluir"></button></p>
                                        )):''
                                    }
                                    </div>
                                    {/* //////// FIM HABILIDADES ADICIONADAS  ///////*/}
                                </>
                            :""
                        }


                        <h4>Colaboradores para este cargo</h4>
                        <input
                        name="name"
                        autocomplete="off"
                        value={nameCollaborator && nameCollaborator.name !== undefined ? nameCollaborator.name : ''}
                        onChange={loadFilterCollaboratorForThisOccupation}
                        placeholder="Atrelar colaboradores à este cargo"
                        className={loadColCadCargo ? 'inputErro busca busca-col' : 'busca busca-col'}
                        />
                        {loadColCadCargo ? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}

                        {/* { BUSCA DE COLABORADORES } */}

                        <div className="resultado-busca-atrelados-col" style={collaboratorFilterForThisOccupation && collaboratorFilterForThisOccupation.length !== 0 ? {display:'block'} : {display:'none'}}>
                            {
                                loadCollaboratorFilterForThiOccupation ?
                                <div className='carregamento-busca'>
                                    <img src={Carregando} alt="" />
                                </div>
                                :
                                collaboratorFilterForThisOccupation &&
                                collaboratorFilterForThisOccupation.length !== 0 ?
                                collaboratorFilterForThisOccupation.map((filterforthisoccupayion, index) => (

                                    <p onClick={myChangeHandlerToAddCollaboratorOccupation} id={filterforthisoccupayion._id} key={index}>
                                        {
                                            filterforthisoccupayion?.avatar !== undefined && filterforthisoccupayion?.avatar !== "undefined" ?
                                            <div className="img-col">
                                                <img id={filterforthisoccupayion._id} src={filterforthisoccupayion.avatar} alt={filterforthisoccupayion.name} />
                                            </div>
                                            :
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img id={filterforthisoccupayion._id} src={IconUserNone} alt={filterforthisoccupayion.name} />
                                                </div>
                                            </div>
                                        }
                                        <span id={filterforthisoccupayion._id}>{filterforthisoccupayion.name}</span>
                                    </p>
                                )): ''
                            }


                        </div>
                        {/* //////// FIM RESULTADO DE BUSCA DE COLABORADORES  ///////*/}

                        {/* //////// ESTADO VAZIO DE BUSCA DE COLABORADORES  ///////*/}
                        {loadToAddCollaboratorOccupation || loadExcludeCollaboratorAddedOccupatio ?
                            ""
                        :
                            collaboratorsOccupation && collaboratorsOccupation.length === 0 ?
                            <div className="vazio-busca-cargo">
                                <img src={IconArrowVazioVerde} alt="Seta Verda" />
                                <span>Insira colaboradores</span>
                            </div>
                            :
                            ''
                        }

                        {/* //////// FIM ESTADO VAQZIO DE BUSCA DE CARGO  ///////*/}
                        {loadToAddCollaboratorOccupation || loadExcludeCollaboratorAddedOccupatio ?
                        <>
                        <div className='carregamento'>
                            <img src={Carregando} alt="" />
                        </div>

                        </>
                        :

                        collaboratorsOccupation && collaboratorsOccupation.length !== 0 ?
                        <>

                        {/* //////// CARGOS ADICIONADOS  ///////*/}
                        <div className="lista-atrelados-col">
                            {
                                collaboratorsOccupation &&
                                collaboratorsOccupation.length !== 0 ?
                                collaboratorsOccupation
                                .filter(filtercollaborator =>
                                    filtercollaborator._id !== allCollaboratorsExcludeOccupation
                                )
                                .map((occupationcolaborator, index) => (
                                    <div key={index} className="infor-col">
                                        {
                                        occupationcolaborator?.avatar !== undefined && occupationcolaborator?.avatar !== "undefined"  ?
                                            <div className="img-col">
                                                <img src={occupationcolaborator.avatar} alt={occupationcolaborator.name} />
                                            </div>
                                            :
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt={occupationcolaborator.name} />
                                                </div>
                                            </div>
                                        }
                                        <div className="nome-setor">
                                            <p className="nome">
                                                {occupationcolaborator.name}
                                            </p>
                                            <div className="setor">
                                                <p>{occupationcolaborator.sector}</p>
                                            </div>
                                        </div>
                                        <button onClick={myChangeHandlerExcludeCollaboratorAddedOccupation} value={occupationcolaborator._id} className="remover">Remover</button>
                                    </div>
                                )): ''
                            }
                        </div>
                        {/* //////// FIM CARGOS ADICIONADOS  ///////*/}
                        </>
                        :""
                        }

                    </div>
                    <button 
                        onClick={saveOccupation} 
                        disabled={loadRegisterNewOccupation ? true : false}
                        className={loadRegisterNewOccupation ? "button-salve btnGreen load" : "button-salve btnGreen"}
                    >Salvar novo cargo</button>
                </div>
            </Modal>
        </>
    );
}

