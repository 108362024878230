import React from 'react';
import EditBlack from '../../../assets/images/edit-black.svg';
import { NavLink } from 'react-router-dom'
import Carregamento from '../../../assets/images/carregando.gif';
import OperadorSodexo from '../../../assets/images/operador-logo-sodexo.svg';
import OperadorTicket from '../../../assets/images/operador-logo-ticket.svg';
import OperadorAlelo from '../../../assets/images/operador-logo-alelo.svg';
import OperadorVR from '../../../assets/images/operador-logo-vr.svg';
import OperadorFlash from '../../../assets/images/operador-logo-flash.svg';
import OperadorSwile from '../../../assets/images/operador-logo-swile.svg';
import OperadorUnimed from '../../../assets/images/operador-logo-unimed.svg';
import OperadorClinipam from '../../../assets/images/operador-logo-clinipam.svg';
import OperadorBradesco from '../../../assets/images/operador-logo-bradesco.svg';
import OperadorParanaClinicas from '../../../assets/images/operador-logo-parana-clinicas.png';
import OperadorSulAmerica from '../../../assets/images/operador-logo-sulamerica.svg';
import OperadorAmil from '../../../assets/images/operador-logo-amil.svg';
import OperadorNossaSaude from '../../../assets/images/operador-logo-nossa-saude.svg';
import OperadorMedSul from '../../../assets/images/operador-logo-medsul.png';
import OperadorDentalUni from '../../../assets/images/operador-logo-dental-uni.png';
import OperadorAmilDental from '../../../assets/images/operador-logo-amil-dental.svg';
import OperadorSulAmericaOdonto from '../../../assets/images/operador-logo-sulamerica-dental.svg';
import OperadorPortoSeguro from '../../../assets/images/operador-logo-porto-seguro.svg';
import OperadorBradescoSeguroDental from '../../../assets/images/operador-logo-bradesco-dental.svg';
import OperadorDentalprev from '../../../assets/images/operador-logo-dentalprev.svg';
import OperadorMetlife from '../../../assets/images/operador-logo-metlife.svg';
import OperadorPrudential from '../../../assets/images/operador-logo-prudential.svg';
import OperadorSantander from '../../../assets/images/operador-logo-santander.svg';
import OperadorZenklub from '../../../assets/images/operador-logo-zenklub.svg';
import OperadorKeiken from '../../../assets/images/operador-logo-keiken.png';
import OperadorGympass from '../../../assets/images/operador-logo-gympass.svg';
import OperadorTotalPass from '../../../assets/images/operador-logo-totalpass.svg';
import FoodGroceries from '../../../assets/images/illustration-food-groceries.svg';

export default function headerBenefitList({
    loadPage,
    beneftis,
}) {

    return (
        <>
        <div className="list-benefits-page">

        {
        loadPage ?
        (
            <>

            <div className="carregamento">
                <img alt="" src={Carregamento} />
            </div>

            <div className="carregamento">
                <img alt="" src={Carregamento} />
            </div>

            <div className="carregamento3">
                <img alt="" src={Carregamento} />
            </div>
            </>
        ):
        beneftis[0] !== undefined ? beneftis
        .map((beneftisList, index) => (
        <>

            <NavLink  to={`/beneficio/informacoes/${beneftisList._id}`}>
            <div
            className={
                beneftisList.category                === 'Refeição'    ? 'box-benefits vl-refeicao' :
                beneftisList.category                === 'Transporte'    ? 'box-benefits vl-transporte' :
                beneftisList.category                === 'Combustível'    ? 'box-benefits vl-combustivel' :
                beneftisList.category                === 'Alimentação'    ? 'box-benefits vl-alimentacao' :
                beneftisList.category                === 'Mobilidade'    ? 'box-benefits vl-mobilidade' :
                beneftisList.category                === 'Estacionamento'    ? 'box-benefits vl-estacionamento' :
                beneftisList.category                === 'Plano de Saúde'    ? 'box-benefits vl-plano-saude' :
                beneftisList.category                === 'Plano Odontológico'    ? 'box-benefits vl-plano-odonto' :
                beneftisList.category                === 'Seguro de Vida'    ? 'box-benefits vl-seguro-vida' :
                beneftisList.category                === 'Plano de Saúde Pet'    ? 'box-benefits vl-plano-saude-pet' :
                beneftisList.category                === 'Convênio Farmácia'    ? 'box-benefits vl-convenio-farmacia' :
                beneftisList.category                === 'Assistência à Educação'    ? 'box-benefits vl-assistencia-educacao' :
                beneftisList.category                === 'Cultura'    ? 'box-benefits vl-cultura' :
                beneftisList.category                === 'Auxílio home-office'    ? 'box-benefits vl-auxilio-home-office' :
                beneftisList.category                === 'Bem-estar'    ? 'box-benefits vl-bem-estar' :
                beneftisList.category                === 'Clube de Benefícios'    ? 'box-benefits vl-clube-beneficios' :
                beneftisList.category                === 'Premiação'    ? 'box-benefits vl-premiacao' :
                beneftisList.category                === 'Day Off'    ? 'box-benefits vl-day-off' :
                beneftisList.category                === 'Previdência Privada'    ? 'box-benefits vl-previdencia-privada' :
                beneftisList.category                === 'Licença maternidade'    ? 'box-benefits vl-licenca-mater-paternidade' :
                beneftisList.category                === 'Licença Paternidade estendida'    ? 'box-benefits vl-licenca-mater-paternidade' :
                beneftisList.category                === 'Alimentação e Refeição'    ? 'box-benefits vl-alimentacao-refeicao' :
                beneftisList.category                === 'Outros'    ? 'box-benefits vl-outros' :'box-benefits vl-outros'
            }
            >
                <h3 className="nome">{beneftisList.nameBenefit}</h3>
                <p className="infor">
                    {
                        beneftisList.numberOfBenefitscollaborator === 0 ?
                        'Nenhum colaborador com este benefício'
                        :
                        beneftisList.numberOfBenefitscollaborator === 1 ?
                        '1 colaborador utiliza este benefício'
                        :
                        beneftisList.numberOfBenefitscollaborator+' colaboradores utilizam este benefício'

                    }

                </p>
                <div className="operador">

                    <img src={
                    beneftisList.operator                === 'Zenklub'    ? OperadorZenklub :
                    beneftisList.operator                === 'Keiken'    ? OperadorKeiken :
                    beneftisList.operator                === 'Gympass'    ? OperadorGympass :
                    beneftisList.operator                === 'Total Pass'    ? OperadorTotalPass :

                    beneftisList.operator                === 'Prudential'    ? OperadorPrudential :
                    beneftisList.operator                === 'Metlife'    ? OperadorMetlife :
                    beneftisList.operator                === 'Santander'    ? OperadorSantander :

                    beneftisList.operator                === 'Dental Uni'    ? OperadorDentalUni :
                    beneftisList.operator                === 'Amil Dental'    ? OperadorAmilDental :
                    beneftisList.operator                === 'Sul América Odonto'    ? OperadorSulAmericaOdonto :
                    beneftisList.operator                === 'Porto Seguro'    ? OperadorPortoSeguro :
                    beneftisList.operator                === 'Bradesco Seguro Dental'    ? OperadorBradescoSeguroDental :
                    beneftisList.operator                === 'Dentalprev'    ? OperadorDentalprev :


                    beneftisList.operator                === 'Unimed'    ? OperadorUnimed :
                    beneftisList.operator                === 'Clinipam'    ? OperadorClinipam :
                    beneftisList.operator                === 'Bradesco Seguros'    ? OperadorBradesco :
                    beneftisList.operator                === 'Paraná Clínicas'    ? OperadorParanaClinicas :
                    beneftisList.operator                === 'Sul América'    ? OperadorSulAmerica :
                    beneftisList.operator                === 'Amil'    ? OperadorAmil :
                    beneftisList.operator                === 'Nossa Saúde'    ? OperadorNossaSaude :
                    beneftisList.operator                === 'MedSul'    ? OperadorMedSul :

                    beneftisList.operator                === 'Sodexo'    ? OperadorSodexo :
                    beneftisList.operator                === 'Alelo'    ? OperadorAlelo :
                    beneftisList.operator                === 'VR'       ? OperadorVR :
                    beneftisList.operator                === 'Flash'    ? OperadorFlash :
                    beneftisList.operator                === 'Swile'    ? OperadorSwile :
                    beneftisList.operator                === 'Ticket'    ? OperadorTicket : ""
                    } alt={beneftisList.operator } />
                </div>

                <NavLink  to={`/beneficios/${beneftisList._id}/editar/`} className="btn-editar">
                    <img src={EditBlack} alt="Editar" /> Editar
                </NavLink>
            </div>

            </NavLink>
        </>
        )) : (
            <>
            </>
        )}


        </div>

                {
                    loadPage ?
                    (
                        <>
                        </>
                    ):
                beneftis[0] === undefined ? (
                    <>
                    <div className="nenhum-beneficio-cadastrado" >
                        <p>Nenhum benefícios cadastrado.</p>
                            <NavLink className="btnGreen more" to="/beneficios/criar" >Cadastrar um benefício</NavLink>
                            <img src={FoodGroceries} alt="Nenhum beneficio cadastrado" />

                    </div>
                    </>
                ):""}
        </>
    );
}

