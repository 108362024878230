import React, { useState } from 'react';
import { Dropdown } from "react-bootstrap";
import { NavLink } from 'react-router-dom'

import iconVerMais from '../../../../assets/images/more.svg';
// import api from '../../../../services/api';
import Carregando from '../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import { formatDateString } from '../../../../services/utils';
import { Scrollbars } from 'react-custom-scrollbars';


const ProcessoDesligamneto = ({ shutdowProcessSearch, recentShutdown, load, loadshutdown }) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mbShutdowProcessSelect, setMbbShutdowProcessSelec] = useState(false);

    return (

        <>
        <div className="page-desligamento mobile">
                <div className="titulo">
                    <h2>Em processo de desligamento</h2>
                    <div className="btn-back">
                        <NavLink to="/colaboradores/lista-colaboradores-desligado">Ver todos os desligamentos</NavLink>
                    </div>
                </div>


            <div className='select-shutdowProcess'>
                <p className='tituloMostrando'>Mostrando</p>
                <div className='cont-select'>
                    <button className='select-'>
                            {
                                mbShutdowProcessSelect.shutdowProcess === "Pendente" ||  mbShutdowProcessSelect.shutdowProcess === false || mbShutdowProcessSelect.shutdowProcess === undefined ?
                                <>
                                <p className='pendente'>
                                    <span></span> Pendente
                                </p>
                                </>
                                : mbShutdowProcessSelect.shutdowProcess === "Desligamentos recentes" ?
                                <>
                                <p className='desligamento-recente'>
                                    <span></span> Desligamentos recentes
                                </p>
                                </>
                                :
                                ""


                            }
                    </button>

                    <div className="dropDown">
                        <Scrollbars className='listDropDown'  style={{  height: 70 }}>
                            {
                            ['Pendente', 'Desligamentos recentes']
                                .map((item, index) => (
                                        <button key={index} className='item'
                                                onClick={() => setMbbShutdowProcessSelec({ ...mbShutdowProcessSelect, "shutdowProcess": item  })}
                                        ><p>{item}</p></button>
                                ))
                            }
                        </Scrollbars>
                    </div>
                </div>
            </div>



            <div className="listas-desligamento">

                {
                    mbShutdowProcessSelect.shutdowProcess === "Pendente" ||  mbShutdowProcessSelect.shutdowProcess === false || mbShutdowProcessSelect.shutdowProcess === undefined ?
                    <>
                    <div className="pentente">
                        <ul>
                           {

                            shutdowProcessSearch && shutdowProcessSearch.length === 0 ?
                            (<div className="nenhum-colaborador"><p>Nenhum colaborador nesta etapa.</p></div>)
                            :
                            shutdowProcessSearch.lenght !== 0 ? shutdowProcessSearch
                        //    .filter(filtercollaborator => filtercollaborator.recentShutdown === false)
                           .map((shutdown, index) => (
                            <li key={index}>
                                <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="foto" >
                                    {
                                    shutdown?.avatar !== undefined &&
                                    shutdown?.avatar !== "undefined" ?
                                        <img alt={shutdown.name} src={shutdown.avatar} className="com-foto" />
                                        :
                                        <div className="semFoto">
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                    }

                                    {/*  */}
                                </NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="nome" >{shutdown.name}</NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="cargo" >{shutdown.occupation}</NavLink>
                                <div className="verMais">
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                        <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="cargo" >Acessar</NavLink>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="tooltipData">
                                    <div className="tooltipInfor">
                                        <i>
                                            <span className="tooltiptext">Aguardando preenchimento</span>
                                        </i>
                                    </div>
                                    <span className="date">{shutdown.updatedAt ? formatDateString(shutdown.updatedAt) : ''}</span>
                                </div>
                            </li>
                           ))
                           :
                           ''
                           }
                        </ul>
                    </div>
                    </>
                    : mbShutdowProcessSelect.shutdowProcess === "Desligamentos recentes" ?
                    <>
                    <div className="recentes">
                        <ul>
                            {
                            recentShutdown && recentShutdown.length === 0 ?
                            (<div className="nenhum-colaborador"><p>Nenhum desligamento nos últimos 15 dias</p></div>)
                            :
                            recentShutdown.lenght !== 0 ? recentShutdown
                            // .filter(shutrecent => shutrecent.recentShutdown === true)
                            .map((shutrecent, index) => (
                                <li key={index}>
                                <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`} className="foto" >
                                    {
                                    shutrecent?.avatar !== undefined &&
                                    shutrecent?.avatar !== "undefined" ?
                                        <img alt={shutrecent.name} src={shutrecent.avatar} className="com-foto" />
                                        :
                                        <div className="semFoto">
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                    }

                                    {/* <img alt={shutrecent.name} src={shutrecent.avatar} /> */}
                                </NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`} className="nome" >{shutrecent.name}</NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`} className="cargo" >{shutrecent.occupation}</NavLink>
                                <div className="verMais">
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <NavLink to={`/colaboradores/${shutrecent._id}/pessoal/`}>
                                                Ver perfil
                                            </NavLink>
                                            <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`}>
                                                Ver Informações do desligamento
                                            </NavLink>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="tooltipData">
                                    <div className="tooltipInfor">
                                        <i>
                                            <span className="tooltiptext">Aguardando preenchimento</span>
                                        </i>
                                    </div>
                                    <span className="date">{shutrecent.updatedAt ? formatDateString(shutrecent.updatedAt) : ''}</span>
                                </div>
                            </li>
                            )) : (
                                <div className="nenhum-col-etapa">
                                Nenhum desligamento
                                nos últimos 15 dias
                                </div>
                            )}


                        </ul>
                    </div>
                    </>
                    :
                    ""

                }
            </div>




        </div>



            <div className="page-desligamento desktop">
                <div className="titulo">
                    <h2>Em processo de desligamento</h2>
                    <div className="btn-back">
                        <NavLink to="/colaboradores/lista-colaboradores-desligado">Ver todos os desligamentos</NavLink>
                    </div>
                </div>
                <div className="listas-desligamento">
                    <div className="pentente">
                        <h3><i></i> Pendente</h3>
                        <ul>
                           {

                            shutdowProcessSearch && shutdowProcessSearch.length === 0 ?
                            (<div className="nenhum-colaborador"><p>Nenhum colaborador nesta etapa.</p></div>)
                            :
                            shutdowProcessSearch.lenght !== 0 ? shutdowProcessSearch
                        //    .filter(filtercollaborator => filtercollaborator.recentShutdown === false)
                           .map((shutdown, index) => (
                            <li key={index}>
                                <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="foto" >
                                    {
                                    shutdown?.avatar !== undefined &&
                                    shutdown?.avatar !== "undefined"  ?
                                        <img alt={shutdown.name} src={shutdown.avatar} className="com-foto" />
                                        :
                                        <div className="semFoto">
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                    }

                                    {/*  */}
                                </NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="nome" >{shutdown.name}</NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="cargo" >{shutdown.occupation}</NavLink>
                                <div className="verMais">
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>

                                        <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`} className="cargo" >Acessar</NavLink>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="tooltipData">
                                    <div className="tooltipInfor">
                                        <i>
                                            <span className="tooltiptext">Aguardando preenchimento</span>
                                        </i>
                                    </div>
                                    <span className="date">{shutdown.updatedAt ? formatDateString(shutdown.updatedAt) : ''}</span>
                                </div>
                            </li>
                           ))
                           :
                           ''
                           }
                        </ul>

                    </div>
                    <div className="recentes">
                        <h3><i></i> Desligamentos recentes</h3>
                        <ul>
                            {
                            recentShutdown && recentShutdown.length === 0 ?
                            (<div className="nenhum-colaborador"><p>Nenhum desligamento nos últimos 15 dias</p></div>)
                            :
                            recentShutdown.lenght !== 0 ? recentShutdown
                            // .filter(shutrecent => shutrecent.recentShutdown === true)
                            .map((shutrecent, index) => (
                                <li key={index}>
                                <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`} className="foto" >
                                    {
                                        shutrecent.avatar !== undefined ?
                                        <img alt={shutrecent.name} src={shutrecent.avatar} className="com-foto" />
                                        :
                                        <div className="semFoto">
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                    }

                                    {/* <img alt={shutrecent.name} src={shutrecent.avatar} /> */}
                                </NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`} className="nome" >{shutrecent.name}</NavLink>
                                <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`} className="cargo" >{shutrecent.occupation}</NavLink>
                                <div className="verMais">
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <NavLink to={`/colaboradores/${shutrecent._id}/pessoal/`}>
                                                Ver perfil
                                            </NavLink>
                                            <NavLink to={`/colaboradores/desligamento/${shutrecent._id}/contabilidade`}>
                                                Ver Informações do desligamento
                                            </NavLink>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="tooltipData">
                                    <div className="tooltipInfor">
                                        <i>
                                            <span className="tooltiptext">Aguardando preenchimento</span>
                                        </i>
                                    </div>
                                    <span className="date">{shutrecent.updatedAt ? formatDateString(shutrecent.updatedAt) : ''}</span>
                                </div>
                            </li>
                            )) : (
                                <div className="nenhum-col-etapa">
                                Nenhum desligamento
                                nos últimos 15 dias
                                </div>
                            )}


                        </ul>

                    </div>
                </div>
                <div className="loading">
                {loadshutdown ?
                    (<div className="carregando"><img src={Carregando} alt="carregando" /></div>) :
                    load ?
                    (<div className="carregando"><img src={Carregando} alt="carregando" /></div>) :
                    ('')}
                </div>
            </div>
        </>
    );
}
export default ProcessoDesligamneto;
