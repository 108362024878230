import React, { useState } from 'react';
import Layout from '../../../components/Layouts/default';
import { NavLink, useLocation } from 'react-router-dom'
import Dropzone from "react-dropzone";
import apiV2 from '../../../services/apiV2';
import Header from '../../../components/Administrativo/FolhaDePagamento/headerSubmit';
import IconLightning from '../../../assets/images/icon-lightning.svg';
import IconTurtle from '../../../assets/images/icon-turtle.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import swal from 'sweetalert';
import './styles.scss';
import Utils from '../../../shared/Utils';

export default function PageFolhaDePagamentoSubmit(props) {

    const [ loading, setLoading ] = useState(false);
    const location = useLocation();

    const sendFileToProcess =  function (file, forceDropDocuments) {
        return new Promise((resolve, reject) => {
            Utils.getBase64(file)
            .then(result => {
                 apiV2.post('/payroll/reader-pdf', 
                        {"forceDropDocuments": forceDropDocuments,
                        "payroll": location.state.payroll._id,
                        "name": location.state.payroll.type,
                        "format": "pdf",
                        "base64": result})
                    .then(response => {
                        setLoading(false);
                        swal({ icon: "success", title: "Sucesso!", text: "A folha foi processada com sucesso." })
                        .then(value => {resolve("processed");});
                        })
                    .catch(
                        error => {
                            if (error.response.status === 409) {
                                resolve("alreadyExists");
                            }
                            else {
                                console.log('===>>>Error Dump:');
                                console.log(error);
                                reject(error);
                            }
                        });
            })
            .catch(error => {
                console.log('===>>>Error Dump:');
                console.log(error);
                reject(error);
            });
        });
        
    }

    async function processFile(acceptedFiles, fileRejections) {
        try {
            setLoading(true);
            if ((acceptedFiles.length === 1) & (fileRejections.length === 0)) {
                await sendFileToProcess(acceptedFiles[0], false)
                .then(response => {
                    switch(response) {
                        case "processed":
                            window.location.href = `/folha-de-pagamento/ano/mes/tipo/${location.state.payroll._id}`;
                            break;
                        case "alreadyExists":
                            swal({ 
                                icon: "warning", 
                                title: "Folha já Processada!", 
                                text: "Existem documentos deste tipo `Holerites` já lançados para esta folha de pagamento. Ao prosseguir os documentos atuais serão substituídos por este novo documento.",
                                buttons: ["Cancelar", "Processar"],
                                dangerMode: false,
                                })
                                .then((value) => {
                                    if (value) {
                                        setLoading(true);
                                        sendFileToProcess(acceptedFiles[0], true)
                                        .then(response2 => {
                                            switch(response2) {
                                                case "processed":
                                                    window.location.href = `/folha-de-pagamento/ano/mes/tipo/${location.state.payroll._id}`;
                                                    break;
                                                default:
                                                    throw new Error('Houve uma falha ao processar a folha de pagamento.');
                                            }
                                        });
                                    }
                                }
                             );
                            break;
                        default:
                            break;                    
                    }
                });
            }
            else
            {
                swal({ icon: "error", title: "Erro!", text: "Só é possível selecionar um arquivo PDF por vez." });
            }
        } catch (error) {
            swal({ icon: "error", title: "Erro!", text: "Ocorreu um erro. Código: " + error.message }); 
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <Layout path={props.match.path}>
            <Header payroll={location.state.payroll}/>
            <div className="page-playrollSubmit">
                <div className="box box-left">
                    {/* icon-lightning */}
                    <div className="icone">
                    <img src={IconLightning} alt="Envio rápido e inteligente"  className="IconLightning"/>
                    </div>
                    <h3>Envio rápido e inteligente</h3>
                    <p className="IconLightning-infor">Envie um único arquivo com todos os holerites e o
                    People View preenche automaticamente.</p>
                    {
                        loading ?
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                        :
                        <>
                            <Dropzone 
                                accept={'application/pdf'}
                                multiple={false}
                                maxFiles={1}
                                onDrop={processFile}>
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input type="file" name="file" {...getInputProps()} />
                                    <label className="imgDocLabel"><span></span>Selecione ou arraste o arquivo</label>
                                </div>
                                )}
                            </Dropzone>
                            <p className="infor-doc-dropzone">Formatos aceitos: pdf</p>
                        </>
                    }
                </div>
                <div className="box">
                    {   /* icon-turtle */}
                    <div className="icone">
                        <img src={IconTurtle} alt="Manual"  className="IconTurtle"/>
                    </div>
                    <h3>Manual</h3>
                    <p className="IconTurtle-infor">Envie um arquivo individual para cada colaborador, um por vez.</p>
                        <NavLink to={`/folha-de-pagamento/ano/mes/tipo/${location.state.payroll._id}`} exact className="btnGray btn-manual">Enviar individualmente</NavLink>
                </div>
            </div>
        </Layout>
    );
}

