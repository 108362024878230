import React, {useState,useEffect} from 'react';
import HeaderAdmin from '../HeaderAdmin';
import { Scrollbars } from 'react-custom-scrollbars';
import SelectPersonalizado from '../Reused/selectPersonalizado'
import { TimePicker } from 'antd';
import Close from '../../assets/images/close.svg';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import { Modal } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import api from '../../services/api';

export default function headerManagement({
    path,
    visibleModalPerformanceEvaluation,
    modalPerformanceEvaluation
}) {

    let btnBuscas = []
    btnBuscas.push(
        <>
        <button className='btnGreen more' onClick={modalPerformanceEvaluation}>Agendar avaliação</button>
        </>
    )


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, ] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sortBy, ] = useState('name');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listaOptionCol, setListaOptionCol ] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listCollaborar, setListCollaborar ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadSelectCol, setLoadSelectCol] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaboratorSelect(page, nameFilter, sortBy) 
        load_collaborator()
    }, [ page, nameFilter, sortBy]);

    async function loadcollaboratorSelect(page, nameFilter, sortBy) {
        setLoadSelectCol(true)
        await api.get(`/collaborator-new/?name=${nameFilter}&status=Ativo&role=admin&role=manager&role=user&sortBy=${sortBy}&page=${page}&limit=100`)
        .then(response => {
            setListaOptionCol(response.data.collaboratorSearch)
            setLoadSelectCol(false)
        }).catch(error => {});
    }

    async function load_collaborator() {
        await api.get(`/collaborator-new/`)
        .then(response => {
            console.log("response.data")
            setListCollaborar(response.data.collaboratorSearch)
        }).catch(error => {});
    }

    


    //////////////////////////////////////////////////////
    ///////////   MODAL AGENDAR FEEDBACK     ////////////
    ////////////////////////////////////////////////////

    ///////// SETs DO FORM DE AGENDAR

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBusca, setIsActiveSelectBusca] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSelect, setBuscaSelect ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [id_collaborator_schedule, setId_collaborator_schedule ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [radio_frequency, setRadio_frequency ] = useState("");

    

    ///////// BUSCA "COM QUEM?" O COLABORADOR

    async function SelectBusca() { setIsActiveSelectBusca(!isActiveSelectBusca) }
    const myChangeHandlerSelects = event => {
        let val = event.target.value;
        setBuscaSelect(val)
        setNameFilter(val)
    }

    ///////// ADICIONA O COLABORADOR DA BUSCA "COM QUEM?"

    async function myChangeHandler_array_Col(event)  {
        event.preventDefault();
        let collaborator = event.target.id;
        setId_collaborator_schedule({...id_collaborator_schedule, collaborator})
        setBuscaSelect("")
        setNameFilter("")

    }  

     ///////// REMOVE O COLABORADOR DA BUSCA "COM QUEM?"

    async function remove_array_Col(event)  {
        event.preventDefault();
        let collaborator = "";
        setId_collaborator_schedule({...id_collaborator_schedule, collaborator})
    
    }
    
    ///////// ONCHENG DOS CAMPOS

    async function myChangeHandler(event)  {
        let nam = event.target.name;
        let val = event.target.value;

        console.log("nam, val");
        console.log(nam, val);
    }

    const format = 'HH:mm';
    const myChangeHandler_end_time = ( time, timeString) => {
        console.log("time");
        console.log(time);
        console.log("timeString");
        console.log(timeString);
    };

    const myChangeHandler_start_time = ( time, timeString) => {
        console.log("time");
        console.log(time);
        console.log("timeString");
        console.log(timeString);
    };



    return (
        <>
        <HeaderAdmin>
                <>

                    {
                        path === '/gestao/avaliacao-de-desempenho' ?
                        <>
                            <div>
                                <h1>Avaliação de desempenho</h1>
                                <p>Acompanhe o desempenho dos colaboradores da empresa</p>
                            </div>
                            <div className='feedbeck'>
                                {btnBuscas}
                            </div>
                        </>
                        :path === '/gestao/avaliacao-de-desempenho/:id' ?
                            <>
                            
                        <div>
                            <h1>
                                <NavLink to="/gestao/avaliacao-de-desempenho" className="voltar">Voltar</NavLink>Desempenho Bárbara
                            </h1>
                        </div>
                        <div className='feedbeckCol'>
                        {btnBuscas}
                        </div>
                            </>
                        :""
                        }
                </>
        </HeaderAdmin>
        <div className='header-mb-feedbeks col'
        
        >
            {btnBuscas}
        </div>

            <Modal show={visibleModalPerformanceEvaluation} className="popup-geral popup-schedule-feedback" onHide={modalPerformanceEvaluation}>
                <div>
                    <div className="titulo">
                        <h4>Agendar Avaliação<p className="btn-fechar" onClick={modalPerformanceEvaluation}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className='form-shedule'>
                        <h3 className='titulo'>Com quem?</h3>
                        <div className='with-whom'>
                            <div className="select-busca-btn col">
                                <input 
                                    type="text" 
                                    className="campo-busca"
                                    placeholder='Selecione o colaborador'
                                    name="buscaCol"
                                    value={
                                        buscaSelect &&
                                        buscaSelect ? buscaSelect
                                        : ""
                                    }
                                    autoComplete="autocompleteoff"
                                    onClick={ SelectBusca }
                                    onBlur={ () => (setIsActiveSelectBusca(false)) }
                                    onKeyUp={ () => (setIsActiveSelectBusca(true))}
                                    onChange={ myChangeHandlerSelects  }
                                />
                                <nav className={`menu ${isActiveSelectBusca ? "ativo": ""}`} >
                                    <Scrollbars className='listDropDown'  style={{  height: 
                                    listaOptionCol?.length === 0 ? 60 : "" ||
                                    listaOptionCol?.length === 1 ? 60 : ""  ||
                                    listaOptionCol?.length === 2 ? 130 : ""  ||
                                    listaOptionCol?.length >= 3 ? 180 : 120
                                    }}>
                                        <ul>
                                            { loadSelectCol?
                                                
                                                <li>
                                                    <button>carregando...</button>
                                                </li>:
                                                listaOptionCol?.length === 0 ||
                                                listaOptionCol?.length === "" ?
                                                    <li>
                                                        <button>Nada encontrado</button>
                                                    </li>
                                                :
                                                listaOptionCol && listaOptionCol.length === 0 ?"":listaOptionCol
                                                .map((optionIntem, index) => (
                                                    <li key={index}>
                                                        <button 
                                                            id={optionIntem._id}
                                                            name="Collaborador"
                                                            onBlur={ () => (setBuscaSelect("")) }
                                                            onClick={myChangeHandler_array_Col}
                                                        >
                                                                <span className='collaborador'  id={optionIntem._id}>

                                                                    {
                                                                        optionIntem.avatar !== undefined && optionIntem.avatar !== "undefined" ?
                                                                        <>
                                                                        <span className="foto" id={optionIntem._id} >
                                                                            <img id={optionIntem._id} src={optionIntem.avatar} alt="nome" />
                                                                        </span>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        <span className="colSemFoto">
                                                                            <span className="semImgCol"    id={optionIntem._id}>
                                                                                <img   id={optionIntem._id} src={IconUserNone} alt="Usuario sem imagem" />
                                                                            </span>
                                                                        </span>
                                                                        </>
                                                                    }

                                                                    <div className='infor'  id={optionIntem._id}>
                                                                        <strong   id={optionIntem._id}>{optionIntem.name}</strong>
                                                                        <p   id={optionIntem._id}>{optionIntem.occupation}</p>
                                                                    </div>


                                                                </span>


                                                            
                                                        </button>
                                                    </li>
                                                ))}
                                        </ul>
                                        
                                    </Scrollbars>
                                </nav>
                            </div>
                            {
                                id_collaborator_schedule && id_collaborator_schedule?.collaborator !== ""?
                                    listCollaborar
                                    .filter(optionIntem =>
                                        optionIntem._id === id_collaborator_schedule?.collaborator
                                    )
                                    .map((optionIntem, index) => (

                                        <div className='selected_contributor'  key={index}>

                                            <div className='collaborador'>
                                                {
                                                    optionIntem.avatar !== undefined && optionIntem.avatar !== "undefined" ?
                                                    <>
                                                    <span className="foto">
                                                        <img src={optionIntem.avatar} alt="Imagem do collaborador" />
                                                    </span>
                                                    </>
                                                    :
                                                    <>
                                                    <span className="colSemFoto">
                                                        <span className="semImgCol"    >
                                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                        </span>
                                                    </span>
                                                    </>
                                                }

                                                <div className='infor'  >
                                                    <strong>{optionIntem?.name}</strong>
                                                    <p>{optionIntem?.occupation}</p>
                                                </div>
                                            </div>
                                         <button className="remover" onClick={remove_array_Col}>Remover</button>

                                        </div>
                                                    
                                    ))
                                :""
                            }
                    
                        </div>
                        <h3 className='titulo'>Quando?</h3>
                        <div className='when'>
                            <div className="form_data">
                                <input type="text" name="data" placeholder='Data' className='data'onChange={myChangeHandler}/>
                                <TimePicker format={format} placeholder="De" className="start_time" onChange={myChangeHandler_start_time} showNow={false} /> 
                                <TimePicker format={format} placeholder="Até" className="end_time" onChange={myChangeHandler_end_time} showNow={false}/>
                            </div>
                            <div className='form_Frequency'>
                                <h4 className='subTitulo'>Frequência</h4>
                                <div className="radios">

                                    <button className="btn-radio" onClick={() =>  setRadio_frequency("Nenhuma")}>
                                        <label className={ radio_frequency && radio_frequency !== "" && radio_frequency === "Nenhuma" ?
                                            "chekboxLabel checado" : "chekboxLabel"
                                        }   
                                        ></label>
                                        <p className="texto"> Nenhuma </p>
                                    </button>

                                    <button className="btn-radio" onClick={() =>  setRadio_frequency("Semanal")}>
                                        <label className={ radio_frequency && radio_frequency !== "" && radio_frequency === "Semanal" ?
                                            "chekboxLabel checado" : "chekboxLabel"
                                        }   
                                        ></label>
                                        <p className="texto"> Semanal </p>
                                    </button>

                                    <button className="btn-radio" onClick={() =>  setRadio_frequency("Mensal")}>
                                        <label className={ radio_frequency && radio_frequency !== "" && radio_frequency === "Mensal" ?
                                            "chekboxLabel checado" : "chekboxLabel"
                                        }   
                                        ></label>
                                        <p className="texto"> Mensal </p>
                                    </button>

                                    <button className="btn-radio" onClick={() =>  setRadio_frequency("Personalizada")}>
                                        <label className={ radio_frequency && radio_frequency !== "" && radio_frequency === "Personalizada" ?
                                            "chekboxLabel checado" : "chekboxLabel"
                                        }   
                                        ></label>
                                        <p className="texto"> Personalizada </p>
                                    </button>

                                

                                </div>
                                <div className="filtro_setRadio_frequency">
                                    <SelectPersonalizado
                                        valorCampo={
                                            ''
                                        }
                                        placeholder="Quantidade"
                                        nameButton={"category"}
                                        myChangeHandler={myChangeHandler}
                                    />
                                    <SelectPersonalizado
                                    valorCampo={
                                        ''
                                    }
                                    placeholder="Frequência"
                                    nameButton={"category"}
                                    myChangeHandler={myChangeHandler}
                                />
                                <SelectPersonalizado
                                    valorCampo={
                                        ''
                                    }
                                    placeholder="Dia sem."
                                    nameButton={"category"}
                                    myChangeHandler={myChangeHandler}
                                />
                                    
                                </div>
                            </div>
                        </div>
                        <h3 className='titulo'>Onde?</h3>
                        <div className="form-where">
                            <button className="btn google-meet">Google Meet</button>
                            <button className="btn teams">Teams</button>
                            <button className="btn zoom">Zoom</button>
                            <button className="btn skype">Skype</button>
                            <button className="btn">Outro serviço</button>
                            <button className="btn">Presencial</button>
                        </div>
                        <input type="text" name="link" className='link-where' placeholder='Cole o link da reunião ou endereço do local' />
                        <div className="btns">
                            <button className='save btnGreen load'>Agendar avaliação</button>
                            <button className='cancelar' onClick={modalPerformanceEvaluation}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

