// eslint-disable-next-line
import React, { useEffect, useState} from 'react';
import IllustrationEducation from '../../../../assets/images/illustration-education.svg';
import Carregamento from '../../../../assets/images/carregando.gif';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Close from '../../../../assets/images/close.svg';
import moment from 'moment';
import swal from 'sweetalert';
import {Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import apiV2 from '../../../../services/apiV2';
import { toast } from 'react-toastify';

export default function ColProfilePersonalDataCourses ({ id}) {
    const [courses, setCourses] = useState([]);
    const [course, setCourse] = useState([]);
    const [loadCourses, setLoadCourses] = useState(false);
    const [modelCourses, setModelCourses] = useState(false);
    const [loadBtnCourses, setLoadBtnCourses] = useState(false);
    const [filePreview, setFilePreview] = useState([]);
    const [loadErrorCourseName, setLoadErrorCourseName] = useState(false);
    const [loadInstituteName, setLoadInstituteName] = useState(false);
    const [loadStartAtDate, setLoadStartAtDate] = useState(false);
    const [loadConclusionAtDate, setLoadConclusionAtDate] = useState(false);
    
    useEffect(() => {
        if(id && id) { getCourses(id); }
    }, [id]);
    async function getCourses(id) {
        try {
            setLoadBtnCourses(false)
            setLoadCourses(true)
            const response = await apiV2.get(`/course?collaborator._id=${id}`)
            setCourses(response.data.items);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        }
        finally{
            setLoadCourses(false)
        }
    }
    async function AddCourses() {
        setModelCourses(true)
    }
    async function AddAndEditCoursesOnHide() {
        setCourse({});
        setFilePreview([]);
        setModelCourses(false);
        setLoadErrorCourseName(false);
        setLoadInstituteName(false);
        setLoadBtnCourses(false);
    }
    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        if (file.size > maxSize) {
          swal({ icon: "error", title: "Erro!", text: "Arquivo é muito grande! Limite de 2MB." });
          return;
        }

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            acceptedFiles.map((file) => {
                // eslint-disable-next-line
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setCourse({...course, "file": {
                        format: file.type.split("/")[1],
                        name: file.name,
                        base64: reader.result,
                      }})
                      
                    setFilePreview({
                        preview: URL.createObjectURL(file),
                        name:  file.name,
                    });
                };
                return null;
             } )
            
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }
    async function deleteFiles() {
        setFilePreview([]);
        setCourse({...course, "file":""})
    }
    
    const applyMask = (input) => {
        const numericValue = input.replace(/\D/g, ''); // Remove caracteres não numéricos
        
        if (numericValue.length >= 2) {
            const firstDigit = parseInt(numericValue[0]);
            const secondDigit = parseInt(numericValue[1]);
    
            if (firstDigit > 1 || (firstDigit === 1 && secondDigit > 2)) {
                return;
            }
        }
    
        if (numericValue.length > 2) {
            const month = numericValue.slice(0, 2);
            const year = numericValue.slice(2, 6);
            return `${month}/${year}`;
        }
    
        return numericValue;
    };

    const transformDateFormatYYYYMM = (input) => {
        const parts = input.split('/');
        
        if (parts.length === 2) {
            const month = parts[0];
            const year = parts[1];
    
            if (month.length === 2 && year.length === 4) {
                return `${year}-${month}`;
            }
        }
    
        return 'Formato de data inválido';
    };

    const transformToMMYYYY = (isoDate) => {
        const date = new Date(isoDate);
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        
        return `${month}/${year}`;
    };
    
    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam === "startAtDate") {
            if (val.length <= 7) {
                const maskedInput = applyMask(val);
                if( val.length === 7 ||val.length === 0){
                    setLoadStartAtDate(false)
                }
                setCourse({...course, [nam]:maskedInput})
            }
        } else if(nam === "conclusionAtDate"){
            if (val.length <= 7) {
                const maskedInput = applyMask(val);
                if( val.length === 7 || val.length === 0){
                    setLoadConclusionAtDate(false)
                }
                setCourse({...course, [nam]:maskedInput})
            }
        } else if(nam === "courseName"){
            setCourse({...course, [nam]:val});
            setLoadErrorCourseName(false);
        } else if(nam === "instituteName"){
            setCourse({...course, [nam]:val});
            setLoadInstituteName(false);
        }  else {
            setCourse({...course, [nam]:val})
        }
    };

    function removeEmptyFields(obj) {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        if (Array.isArray(obj)) {
          return obj.map(item => removeEmptyFields(item)).filter(item => item !== null);
        }
      
        const newObj = {};
        for (const key in obj) {
          const value = removeEmptyFields(obj[key]);
          if (value !== null && value !== undefined && (!Array.isArray(value) || value.length > 0)) {
            newObj[key] = value;
          }
        }
        
        return Object.keys(newObj).length > 0 ? newObj : null;
    }
      
    async function handleSubmit(event) {
        event.preventDefault();
        setLoadBtnCourses(true);
        if(course && course.length === 0) {
            swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
            setLoadBtnCourses(false);
        }
        else
        if (!course?.courseName || course.courseName === "" || !course?.instituteName || course.instituteName === "") {
            if (!course?.courseName || course.courseName === "") {
              setLoadErrorCourseName(true);
            }
            if (!course?.instituteName || course.instituteName === "") {
              setLoadInstituteName(true);
            }
            setLoadBtnCourses(false)
            return;
        } else {
            if((course?.startAtDate && course?.startAtDate?.length !== 7) || (course?.conclusionAtDate && course?.conclusionAtDate?.length !== 7) ){
                if( course.startAtDate && course?.startAtDate?.length !== 7){
                    setLoadStartAtDate(true)
                }
                if( course?.conclusionAtDate && course?.conclusionAtDate?.length !== 7){
                    setLoadConclusionAtDate(true)
                }
                setLoadBtnCourses(false);
                return
           }
           if(!course?.idCourse) {
                let courseItem = {
                    "collaborator": id,
                    "courseName": course?.courseName,
                    "instituteName": course?.instituteName,
                    "startAtDate": course.startAtDate ? transformDateFormatYYYYMM(course?.startAtDate) : null,
                    "conclusionAtDate": course?.conclusionAtDate ? transformDateFormatYYYYMM(course?.conclusionAtDate) : null,
                    "file": course.file ?? {},
                } 
                await apiV2.post(`/course`, removeEmptyFields(courseItem))
                .then(response => {
                    getCourses(id);
                    setFilePreview([]);
                    setCourse({})
                    setModelCourses(false)
                    swal({ icon: "success", title: "Sucesso!", text: "Curso criada com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadBtnCourses(false);
                });
           }else {
                let courseItemEdit = {
                    "courseName": course?.courseName,
                    "instituteName": course?.instituteName,
                    "startAtDate": course.startAtDate ? transformDateFormatYYYYMM(course?.startAtDate) : null,
                    "conclusionAtDate": course?.conclusionAtDate ? transformDateFormatYYYYMM(course?.conclusionAtDate) : null,
                    "file": course?.file &&  course?.file !== ""? course?.file : {},
                }
                await apiV2.patch(`/course/${course.idCourse}`, courseItemEdit)
                .then(response => {
                    getCourses(id);
                    setFilePreview([]);
                    setCourse({})
                    setModelCourses(false)
                    swal({ icon: "success", title: "Sucesso!", text: "Curso criada com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadBtnCourses(false);
                });
                
           }
        }
    }

    async function EditCourse(e) {
        setModelCourses(true)
        setCourse({
            "idCourse": e._id,
            "courseName": e.courseName,
            "instituteName": e.instituteName,
            "startAtDate": e.startAtDate ? transformToMMYYYY(e.startAtDate) : "",
            "conclusionAtDate": e.conclusionAtDate ? transformToMMYYYY(e.conclusionAtDate) : "",
            "file": e?.file ? e.file : {},
        })
        if(e?.file?.url) {
            setFilePreview({
                format: e?.file.format,
                name: e.file.name,
                url: e.file.url,
            });
        }else {
            setFilePreview([]);
        }
    }
    
    async function deleteCourse(course) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este curso?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await apiV2.delete(`/course/${course}`)
                .then(() => {
                    getCourses(id);
                    setFilePreview([]);
                    setCourse({...course, "file":""})
                    setModelCourses(false)
                    swal({ icon: "success", title: "Sucesso!", text: "Curso removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o curso, tente novamente mais tarde." });
                });
            }
        });
    }
    
    return (
        <>
            <div className="box courses">
                <h3>Cursos 
                    {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? "" :
                    <button className="btnGreen more edit-adicionar" onClick={() => AddCourses()}>Adicionar</button>}
                </h3>
                <div className='list-courses'>
                    
                {
                    loadCourses ?
               
                    <div className="carregamento" >
                        <img alt="" src={Carregamento} />
                    </div>
                    : courses && courses.length !== 0 ?
                    courses
                    .map((course, index) => (
                        <>
                        <ul className="courses-box" >
                            <li >
                                <div className="intemA">
                                    <p>Instituição de ensino</p>
                                </div>
                                
                                <div className="intemB nome-editar">
                                    <span className="nome">{course.instituteName}</span>
                                    <button  className="editar" onClick={() => EditCourse(course)}><img src={EditBlack} alt="Edite Informações" /></button>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Curso</p>
                                </div>
                                <div className="intemB">
                                    <p>{course.courseName}</p>
                                </div>
                            </li>
                            {
                                course.startAtDate ? 
                                <li>
                                    <div className="intemA">
                                        <p>Inicio</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{moment(course.startAtDate).format("MM/YYYY")}</p>
                                    </div>
                                </li>
                                :""
                            }
                            {
                                course.conclusionAtDate ?
                                <li>
                                    <div className="intemA">
                                        <p>Conclusão</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{moment(course.conclusionAtDate).format("MM/YYYY")}</p>
                                    </div>
                                </li>
                                :""
                            }
                            {
                                course?.file?.url ?
                                <li>
                                    <div className="intemA">
                                        <p>Comprovante</p>
                                    </div>
                                    <div className="intemB arq-adicionado">
                                    {/*// eslint-disable-next-line */}
                                    {course?.file?.url && (
                                        <a href={course.file.url} target="_blank" rel="noopener noreferrer">
                                            {course.file.name}
                                        </a>
                                    )}
                                    </div>
                                </li>:""

                            }
                        </ul>
                        </>
                    )):
                    <div className="nenhum-cad-perfil" ><div className="box-img"><img src={IllustrationEducation} alt="Sem deficiencia" /><p>Não possui curso</p></div></div>
                 }
                 </div>
               
                

            </div>
            <Modal show={modelCourses} className="popup-edit-perfil popup-edit-educacao-academica" onHide={AddAndEditCoursesOnHide}>
                <div>
                    <div className="titulo">
                        <h4>Cursos  <p className="btn-fechar" onClick={AddAndEditCoursesOnHide}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Instituição de<br/> ensino *</p>
                            </div>
                            <div className="intemB">
                                <input 
                                    type="text" 
                                    name="instituteName" 
                                    autoComplete="off" 
                                    placeholder="Instituição de ensino" 
                                    value={course?.instituteName ?? ""}
                                    className={loadInstituteName ? 'inputErro' : ''}
                                    onChange={myChangeHandler}
                                />
                                {loadInstituteName ? <p className="textError">O campo é obrigatório.</p>:""}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Curso *</p>
                            </div>
                            <div className="intemB">
                                <input 
                                    type="text" 
                                    name="courseName" 
                                    autoComplete="off" 
                                    placeholder="Curso" 
                                    value={course?.courseName ?? ""}
                                    className={loadErrorCourseName ? 'inputErro' : ''}
                                    onChange={myChangeHandler}
                                />
                                {loadErrorCourseName ? <p className="textError">O campo é obrigatório.</p>:""}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Inicio</p>
                            </div>
                            <div className="intemB">
                                <input 
                                    type="text" 
                                    name="startAtDate" 
                                    autoComplete="off" 
                                    placeholder="MM/AAAA"  
                                    className={loadStartAtDate ? 'inputErro' : ''}
                                    value={course?.startAtDate ?? ""}
                                    onChange={myChangeHandler}
                                />
                                {loadStartAtDate ? <p className="textError">Formato de data inválido.</p>:""}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Conclusão</p>
                            </div>
                            <div className="intemB">
                                <input 
                                    type="text" 
                                    name="conclusionAtDate" 
                                    autoComplete="off" 
                                    placeholder="MM/AAAA" 
                                    className={loadConclusionAtDate ? 'inputErro' : ''}
                                    value={course?.conclusionAtDate ?? ""}
                                    onChange={myChangeHandler}
                                />
                                {loadConclusionAtDate ? <p className="textError">Formato de data inválido.</p>:""}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div className="intemB">
                                <Dropzone onDrop={handleDrop} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel inputOnze"><span></span>{
                                            filePreview?.length !== 0 ? "Atualize o arquivo": "Anexe um arquivo"
                                        }
                                        
                                        </label>
                                    </div>
                                    )}
                                </Dropzone>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                            </div>
                        </li>
                        
                        {
                            filePreview?.length !== 0 && filePreview !== undefined ?
                            <li>
                                <div>
                                    <div className="arquivoAnexado">
                                        <div className="arquivo">
                                            <p className="nomeArquivo">
                                                <a href={filePreview.preview} target="’_blank’">
                                                {filePreview.name}
                                                </a>
                                            </p>
                                            <p onClick={() => deleteFiles()} className="remover">Remover anexo</p>
                                        </div>

                                    </div>
                                </div>
                            </li>
                            : ""
                        }
                        
                        <li>
                            <div className="intemA">
                                {
                                     course?.idCourse ?
                                     <div className="intem btnExclui">
                                         <button 
                                         onClick={() => deleteCourse(course?.idCourse)}
                                         >Excluir Curso
                                         </button >
                                     </div>
                                     :""

                                }
                            </div>

                            <div className="intemB">
                                <form onSubmit={handleSubmit} className="btn-salvar">
                                    <button type="submit" className={loadBtnCourses  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    );
}