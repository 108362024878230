import React from 'react';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';

// import IconsInformation from '../IconsInformation';



const subMenuCommunication = ({ path }) => {

    return (
        <>
        <div className="menu-page-comunicacao">
        <Scrollbars>

            <ul className="menu">
                    <li>
                        <NavLink to={`/contato-com-colaborador`} exact className={path === '/contato-com-colaborador' ? "active" : ''}><span>Contato com colaborador</span></NavLink>
                    </li>
                    <li>
                        <NavLink to={`/mural-de-anuncios`} exact className={path === '/mural-de-anuncios' ? "active" : ''}><span>Mural de anúncios</span></NavLink>
                    </li>
                </ul>
        </Scrollbars>
        </div>
        </>
    );
}
export default subMenuCommunication;
