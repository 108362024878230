import React from 'react';
import TimeInput from 'react-advanced-time-input';
export default function periodFilds(props) {

    return (
        <>
            <div className='period'>
                <p>Período {props.period} </p>
                <div>

                    <TimeInput
                        placeholder='Inicial'
                        className={
                            props?.loadErrorInitTime ?  'inputErro' : ''
                        }
                        colon=":"  
                        disabled={props?.disabledOne}
                        name={props?.nameInitTime}
                        onChange={props?.myChangeHandler}
                        value={props?.valuePeriod?.initTime}
                    />

                    <TimeInput
                        placeholder='Final'
                        className={
                            props?.loadErrorEndTime ?  'inputErro' : ''
                        }
                        disabled={props?.disabledTwo}
                        name={props?.nameEndTime}
                        value={props?.valuePeriod?.endTime}
                        onChange={props?.myChangeHandler}
                    />

                </div>
            </div>
        </>
    );
}
