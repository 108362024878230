import React from 'react';
// import iconChat from '../../assets/images/chat.svg';
// import iconNotifications from '../../assets/images/notifications.svg';
// import IconsInformation from '../IconsInformation';
import HeaderAdmin from '../HeaderAdmin';
// import iconUser from '../../assets/images/user.svg';
import { formatDateString } from '../../services/utils';


export default function Header({
    user,
}) {

    //const currentDate = new Date().toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"});
    const currentDate = new Date();


    return (
        <>
        <HeaderAdmin>
            <div>
                <h1>
                {user.name}
                </h1>
                <p>Seu resumo de {formatDateString(currentDate)}</p>

            </div>
            
        </HeaderAdmin>



        {/* {/* <header className="header-geral headerDeshbord">

            <div className="cabeçalho">
                <h1>
                Bom dia, {user.name}
                </h1>
                <p>Seu resumo de {formatDateString(currentDate)}</p>
            </div>
            <div className="infor">
                    {/* <form className="busca">
                        <input placeholder="Busque por documentos, curriculos e mais"/>
                    </form> */}
                {/* <IconsInformation /> */} 
            {/* </div> */}

        {/* </header> */} 

            {/* <header className="header-geral headerDeshbord">
                <div className="cabeçalho">
                    <h1>Bom dia, Valleir</h1>
                    <p className="data">Seu resumo de 24 de março de 2021</p>
                </div>
                <div className="infor">
                    <form className="busca">
                        <input placeholder="Busque por documentos, curriculos e mais"/>
                    </form>
                    <IconsInformation />
                </div>

            </header> */}
        </>
    );

}
