import React  from 'react';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../../assets/images/more.svg';
import { Scrollbars } from 'react-custom-scrollbars';

export default function homeFeedbackColQuestions() {
    return (
        <>
                
            <div className='box-leiaute questions'>
                <h2 className='titulo'>Perguntas</h2>
                <p className='subTitulo'>Personalize as perguntas para este feedback</p>
                <div className='box-list'>
                    <Scrollbars>
                        <ul>
                            <li>
                                <p className='nome-pergunta'>
                                    O que tem achado das nossas sprints de 2 semanas?
                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle><img alt="" src={iconVerMais} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button type="button" className='red' > Excluir pergunta </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </p>

                                <div className='ckeck-list'>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Péssimo</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Ruim</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Médio</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Bom</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                        <span>Ótimo</span>
                                    </div>
                                    
                                </div>
                            </li>
                            <li>
                                <p className='nome-pergunta'>
                                    O que tem achado das nossas sprints de 2 semanas?
                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle><img alt="" src={iconVerMais} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button type="button" className='red' > Excluir pergunta </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </p>

                                <div className='ckeck-list'>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Péssimo</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Ruim</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Médio</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Bom</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                        <span>Ótimo</span>
                                    </div>
                                    
                                </div>
                            </li>
                            <li>
                                <p className='nome-pergunta'>
                                    O que tem achado das nossas sprints de 2 semanas?
                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle><img alt="" src={iconVerMais} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button type="button" className='red' > Excluir pergunta </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </p>

                                <div className='ckeck-list'>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Péssimo</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Ruim</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Médio</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Bom</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                        <span>Ótimo</span>
                                    </div>
                                    
                                </div>
                            </li>
                            <li>
                                <p className='nome-pergunta'>
                                    O que tem achado das nossas sprints de 2 semanas?
                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle><img alt="" src={iconVerMais} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button type="button" className='red' > Excluir pergunta </button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </p>

                                <div className='ckeck-list'>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Péssimo</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Ruim</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Médio</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <span>Bom</span>
                                    </div>
                                    <div>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                        <span>Ótimo</span>
                                    </div>
                                    
                                </div>
                            </li>
                        </ul>
                    </Scrollbars>
                </div>
                <div className='box-form'>
                    <input type="text"  placeholder='Digite uma nova pergunta…'/>
                    <div className='btns'>
                        <button className='btnGreen load'>Adicionar</button>
                        <button className='cancela'>Cancelar</button>

                    </div>

                </div>
                    
            </div>


                            
        </>
    );
}

