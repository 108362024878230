import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/ADMCommunication/header';
import SubMenu from '../../components/ADMCommunication/subMenu';
import api from '../../services/api';
import { oneHundredCharacters } from '../../services/utils';
import IllustracaoMural from '../../assets/images/illustration-mural-noticia.svg';
import swal from 'sweetalert';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import Carregamento from '../../assets/images/carregando.gif';
import IllustrationBirthday from '../../assets/images/illustration-birthday.svg';
import EditarBlack from '../../assets/images/edit-black.svg';
import IconHeart from '../../assets/images/heart.svg';
import IconHeartGray from '../../assets/images/heart-gray.svg';
import ModalGeral from '../../components/modal/modal-geral';
import { toast } from 'react-toastify';
import './styles.scss';
export default function  pageSystemConfig(props) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ anuncioLista, setAnuncioLista] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadAdvertsOrderDesc();

    }, []);

    async function loadAdvertsOrderDesc() {
        setLoadAnuncios(true)
        await api.get('/collaborator-adverts/?order=desc')
        .then(response => {

            setLoadAnuncios(false)
            setLoadBtnPostAnuncio(false)
            setEditarAnuncio(false)
            setVerAnuncios(true)

            setAnuncioLista(response.data);
        }).catch(error=> {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAnuncios, setLoadAnuncios ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnPostAnuncio, setLoadBtnPostAnuncio ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPostDescription, setLoadDescription ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPostTitle, setLoadPostTitle ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ anuncioRegistration, setAnuncioRegistration ] = useState([]);

    const myChangeHandlerPost = event => {
        let nam = event.target.name;
        let val = event.target.value;
        let category  = "Recado";
        setAnuncioRegistration({ ...anuncioRegistration, [nam]: val, category })
    };

    async function handlePost(event) {
        event.preventDefault();

        if (anuncioRegistration.announcementTitle === undefined || anuncioRegistration.announcementTitle === "") {
            setLoadPostTitle(true)
        } else {
            setLoadPostTitle(false)
        }
        if (anuncioRegistration.announcementDescription === undefined || anuncioRegistration.announcementDescription === "") {
            setLoadDescription(true)
        } else {
            setLoadDescription(false)
        }

        if (
            anuncioRegistration.announcementTitle              !== undefined &&
            anuncioRegistration.announcementTitle               !==     ""    &&
            anuncioRegistration.announcementDescription       !== undefined &&
            anuncioRegistration.announcementDescription       !==     ""
        ) {
            setLoadAnuncios(true)
            setLoadBtnPostAnuncio(true)

            await api.post('/collaborator-adverts', anuncioRegistration)
            .then(response => {

                setAnuncioRegistration([])

                loadAdvertsOrderDesc();
                setIsModalPostMuralAdd(false)

                swal({ icon: "success", title: "Sucesso!", text: "Anúncio criado com sucesso!" });
                //this.propsCollaboratorsInatives();
            }).catch(error => {
                setLoadAnuncios(false)
                setLoadBtnPostAnuncio(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao criar Anúncio, tente novamente mais tarde." });
            });


        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ editarAnuncio, setEditarAnuncio] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ verAnuncios, setVerAnuncios ] = useState(true);


    function CancelaEditPos() {
        setEditarAnuncio(false)
        setVerAnuncios(true)
        setAnuncioRegistration([])
        setAnuncioUpdate([])
        setLoadBtnPostAnuncio(false)

        setIsModalPostMuralEdit(false)
        setIsModalPostMuralAdd(false)

    }


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ anuncioUpdate, setAnuncioUpdate ] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ anuncioCheck, setAnuncioCheck ] = useState([]);

    function UpdateAnuncio(e) {

        let anuncioCheck = e;
        setAnuncioCheck(anuncioCheck)
        setEditarAnuncio(true)
        setVerAnuncios(false)
        setIsModalPostMuralEdit(true)

    }

    const myChangeHandlerUpdate = event => {

        let nam = event.target.name;
        let val = event.target.value;
        let _id = anuncioCheck._id;
        setAnuncioUpdate({ ...anuncioUpdate, [nam]: val, _id})

    };

    async function handleEdite(event) {
        event.preventDefault();

        if( anuncioUpdate === undefined || anuncioUpdate.length === 0) {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });
        } else if(
            anuncioUpdate.announcementTitle              === "" ||
            anuncioUpdate.announcementDescription        === ""
        ) {
            swal({ icon: "error", title: "Erro!", text:  'Nenhum campo dode ser salvo em branco!'});
        } else {
            setLoadAnuncios(true)
            setLoadBtnPostAnuncio(true)


            await api.put(`/collaborator-adverts/${anuncioUpdate._id}`, anuncioUpdate)
            .then(response => {

                setAnuncioUpdate([])

                loadAdvertsOrderDesc();
                setIsModalPostMuralEdit(false)

                swal({ icon: "success", title: "Sucesso!", text: "Anúncio editado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao editar o anúncio, tente novamente mais tarde." });
                setLoadAnuncios(false)
                setLoadBtnPostAnuncio(false)
            });
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ anuncioListaRecente, setAnuncioListaRecente] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ anuncioListaAntigos, setAnuncioListaAntigos] = useState(false);

    async function myChangeHandlerFiltro(event) {

        let val = event.target.value;

        if (val === "Maisantigoprimeiro") {

            setLoadAnuncios(true)
            await api.get(`/collaborator-adverts/?order=asc`)
            .then(response => {

                setName();
                setLoadAnuncios(false);
                setAnuncioListaAntigos(true);
                setFilterAnuncio(false);
                setEditarAnuncio(false);
                setVerAnuncios(true);
                setLoadBtnPostAnuncio(false);
                setAnuncioRegistration([]);
                setAnuncioUpdate([]);
                setAnuncioLista(response.data);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
           ////ASC

        } else {

            setLoadAnuncios(true)
            await api.get(`/collaborator-adverts/?order=desc`)
            .then(response => {

                setName();
                setAnuncioListaRecente(true);
                setFilterAnuncio(false);
                setLoadAnuncios(false);
                setEditarAnuncio(false);
                setVerAnuncios(true);
                setLoadBtnPostAnuncio(false);
                setAnuncioRegistration([]);
                setAnuncioUpdate([]);
                setAnuncioLista(response.data);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }

    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ buscaAnuncio, setFilterAnuncio ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ name, setName ] = useState();

    async function loadfilterAnuncio(event) {

        if(event.target.value.length === 0) {

            loadAdvertsOrderDesc();
            setName();

        }
        else {

            const nameserach = event.target.value;

            setName(nameserach);
            setLoadAnuncios(true);

            await api.get(`/collaborator-adverts/?name=${nameserach}`)
            .then(response => {

                setFilterAnuncio(true);
                setAnuncioListaRecente(false);
                setAnuncioListaAntigos(false);
                setLoadAnuncios(false);
                setEditarAnuncio(false);
                setVerAnuncios(true);
                setLoadBtnPostAnuncio(false);
                setAnuncioRegistration([]);
                setAnuncioUpdate([]);
                setAnuncioLista(response.data);

            }).catch(error => {

                setFilterAnuncio(true);
                setAnuncioListaRecente(false);
                setAnuncioListaAntigos(false);
                setLoadAnuncios(false);
                setEditarAnuncio(false);
                setVerAnuncios(true);
                setLoadBtnPostAnuncio(false);
                setAnuncioRegistration([]);
                setAnuncioUpdate([]);
                setAnuncioLista([]);
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });

            });

        }

    }

    //saveLike
    async function saveLike(e) {

    let adverts    = e;
    let numberLike = 1;

    async function savecollaboratoradvertslike() {

        await api.post('/collaborator-adverts-like/', { adverts, numberLike })
        .then(() => {

            async function loadAdverts() {

                if(buscaAnuncio === true) {

                    await api.get(`/collaborator-adverts/?name=${name}`)
                    .then(response => {

                        setFilterAnuncio(true)
                        setAnuncioListaRecente(false)
                        setAnuncioListaAntigos(false)


                        setLoadAnuncios(false)
                        setAnuncioLista(response.data);
                        setEditarAnuncio(false)
                        setVerAnuncios(true)
                        setAnuncioRegistration([])
                        setAnuncioUpdate([])
                        setLoadBtnPostAnuncio(false)
                    }).catch((error) => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else
                if(anuncioListaRecente === true) {

                    await api.get(`/collaborator-adverts/?order=desc`)
                    .then(response => {

                        setAnuncioListaRecente(true)
                        setAnuncioListaAntigos(false)
                        setFilterAnuncio(false)


                        setAnuncioLista(response.data);
                        setLoadAnuncios(false)
                        setEditarAnuncio(false)
                        setVerAnuncios(true)
                        setAnuncioRegistration([])
                        setAnuncioUpdate([])
                        setLoadBtnPostAnuncio(false)
                    }).catch((error) => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });


                }
                else
                if(anuncioListaAntigos === true) {

                    await api.get(`/collaborator-adverts/?order=asc`)
                    .then(response => {
                        setLoadAnuncios(false)

                        setAnuncioListaAntigos(true)
                        setAnuncioListaRecente(false)
                        setFilterAnuncio(false)

                        setAnuncioLista(response.data);
                        setEditarAnuncio(false)
                        setVerAnuncios(true)
                        setAnuncioRegistration([])
                        setAnuncioUpdate([])
                        setLoadBtnPostAnuncio(false)
                        //console.log(response.data)
                    }).catch((error) => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get('/collaborator-adverts/?order=desc')
                    .then(response => {
                        setLoadAnuncios(false)
                        setAnuncioLista(response.data);
                    }).catch((error) => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }

            }
            loadAdverts();

        }).catch((error) => {
            console.log(error.response.data.message)
        });

    }
    savecollaboratoradvertslike();

    }

    async function deleteLike(e) {

        await api.get(`/collaborator-adverts-like/?adverts=${e}`)
        .then(response => {

            if(response.data !== undefined && response.data._id !== undefined) {

                async function deletelikeOther() {

                    await api.delete(`/collaborator-admin-adverts-like/${response.data._id}`)
                    .then(() => {

                        async function loadAdverts() {

                            if(buscaAnuncio === true) {

                                await api.get(`/collaborator-adverts/?name=${name}`)
                                .then(response => {

                                    setFilterAnuncio(true)
                                    setAnuncioListaRecente(false)
                                    setAnuncioListaAntigos(false)


                                    setLoadAnuncios(false)
                                    setAnuncioLista(response.data);
                                    setEditarAnuncio(false)
                                    setVerAnuncios(true)
                                    setAnuncioRegistration([])
                                    setAnuncioUpdate([])
                                    setLoadBtnPostAnuncio(false)
                                    //console.log(response.data)
                                }).catch((error) => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });

                            }
                            else
                            if(anuncioListaRecente === true) {

                                await api.get(`/collaborator-adverts/?order=desc`)
                                .then(response => {

                                    setAnuncioListaRecente(true)
                                    setAnuncioListaAntigos(false)
                                    setFilterAnuncio(false)
                                    setAnuncioLista(response.data);
                                    setLoadAnuncios(false)
                                    setEditarAnuncio(false)
                                    setVerAnuncios(true)
                                    setAnuncioRegistration([])
                                    setAnuncioUpdate([])
                                    setLoadBtnPostAnuncio(false)
                                }).catch((error) => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });


                            }
                            else
                            if(anuncioListaAntigos === true) {

                                await api.get(`/collaborator-adverts/?order=asc`)
                                .then(response => {
                                    setLoadAnuncios(false)

                                    setAnuncioListaAntigos(true)
                                    setAnuncioListaRecente(false)
                                    setFilterAnuncio(false)

                                    setAnuncioLista(response.data);
                                    setEditarAnuncio(false)
                                    setVerAnuncios(true)
                                    setAnuncioRegistration([])
                                    setAnuncioUpdate([])
                                    setLoadBtnPostAnuncio(false)
                                }).catch((error) => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });

                            }
                            else {

                                await api.get('/collaborator-adverts/?order=desc')
                                .then(response => {
                                    setLoadAnuncios(false)
                                    setAnuncioLista(response.data);
                                }).catch((error) => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });
                            }

                        }
                        loadAdverts();

                    }).catch((error) => {
                        // console.log(error.response.data.message)
                    });

                }
                deletelikeOther();

            }


        }).catch((error) => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });


    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ showMoreAnuncioList, setShowmoreAnuncioList ] = useState([]);

    async function showMoreAnuncio(e) {
        // alert(e)

        setShowmoreAnuncioList(e)
    }

    ////////////////////////////////////////////////////////////////////////////
    /////     FUNÇÂO RESPONSÀVEL POR DELETAR AS ISFOS DO Anuncio     /////
    ///////////////////////////////////////////////////////////////////////////
    async function handleDelete(_id) {


        setLoadAnuncios(true)
        setLoadBtnPostAnuncio(true)


        // setLoadPageDepartamento(true)
        await api.delete(`/collaborator-adverts/${_id}`)
        .then(response => {

            setAnuncioUpdate([])

            loadAdvertsOrderDesc();


            setIsModalPostMuralEdit(false)
            swal({ icon: "success", title: "Sucesso!", text: "Anúncios deletado com sucesso!" });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao deletadar o anúncios!" });
            setLoadAnuncios(false)
            setLoadBtnPostAnuncio(false)
        });

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPostMuralAdd, setIsModalPostMuralAdd ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPostMuralEdit, setIsModalPostMuralEdit ] = useState(false);

    return (
            <>

            <ModalGeral onClose={()=> setIsModalPostMuralEdit(false)} isModalPostMuralEdit={isModalPostMuralEdit}>
                <h2 className='titulo'>Editar o post</h2>
               
                <div className="form-anuncio" >
                    <div className="form">
                        <div className="titulo-input">

                            { (anuncioUpdate && anuncioUpdate.announcementTitle !== undefined) || anuncioUpdate.announcementTitle === '' ?
                                (
                                    <input type="text" name="announcementTitle" placeholder="Titulo"  defaultValue={(anuncioUpdate.announcementTitle !== undefined) ? anuncioUpdate.announcementTitle : ''} onChange={myChangeHandlerUpdate}  />
                                ) : (
                                    <input type="text" name="announcementTitle" placeholder="Titulo"  value={(anuncioCheck.announcementTitle !== undefined) ? anuncioCheck.announcementTitle : ''} onChange={myChangeHandlerUpdate}  />
                                )}

                        </div>

                        { (anuncioUpdate && anuncioUpdate.announcementDescription !== undefined) || anuncioUpdate.announcementDescription === '' ?
                            (
                                <textarea name="announcementDescription" placeholder="Digite um novo anúncio…" defaultValue={(anuncioUpdate.announcementDescription !== undefined) ? anuncioUpdate.announcementDescription : ''}  onChange={myChangeHandlerUpdate}></textarea>
                            ) : (
                                <textarea name="announcementDescription" placeholder="Digite um novo anúncio…" value={(anuncioCheck.announcementDescription !== undefined) ? anuncioCheck.announcementDescription : ''}  onChange={myChangeHandlerUpdate}></textarea>
                            )}
                    </div>
                    {loadPostTitle? (
                        <p className="textError textErrorTextarea">O campo do titulo e obrigatório.</p>
                    ) : ('')}
                    {loadPostDescription? (
                        <p className="textError textErrorTextarea">O campo do descrição e obrigatório.</p>
                    ) : ('')}

                    <div className="botoes">
                        <form onSubmit={handleEdite}>
                            <button className={loadBtnPostAnuncio  ? 'enviar btnGreen load' : 'enviar btnGreen '}>Editar no mural</button>
                        </form>
                        <div className='btn-2'>
                            <button className="excluir"  onClick={() => handleDelete(anuncioCheck && anuncioCheck._id)}>Excluir</button>
                            <button className="cancelar" onClick={() => CancelaEditPos()}>Cancelar</button>
                        </div>
                    </div>

                </div>
            </ModalGeral>




            <ModalGeral onClose={()=> setIsModalPostMuralAdd(false)} isModalPostMuralAdd={isModalPostMuralAdd}>
                <h2 className='titulo'>Postar no mural</h2>
                <div className="form-anuncio" >
                    <div className="form">
                        <div className="titulo-input">
                            <input type="text" name="announcementTitle" placeholder="Titulo"  value={(anuncioRegistration.announcementTitle !== undefined) ? anuncioRegistration.announcementTitle : ''} onChange={myChangeHandlerPost}  />
                        </div>
                        <textarea name="announcementDescription" placeholder="Digite um novo anúncio…" value={(anuncioRegistration.announcementDescription !== undefined) ? anuncioRegistration.announcementDescription : ''}  onChange={myChangeHandlerPost}></textarea>
                    </div>
                    {loadPostTitle? (
                        <p className="textError textErrorTextarea">O campo do titulo e obrigatório.</p>
                    ) : ('')}
                    {loadPostDescription? (
                        <p className="textError textErrorTextarea">O campo do descrição e obrigatório.</p>
                    ) : ('')}

                    <div className="botoes">
                        <form onSubmit={handlePost}>
                            <button className={loadBtnPostAnuncio  ? 'enviar btnGreen load' : 'enviar btnGreen '}>Postar no mural</button>
                        </form>
                        <button className="cancelar" onClick={() => CancelaEditPos()}>Cancelar</button>
                    </div>
                </div>
            </ModalGeral>

                <Layout
                path={props.match.path}
                >
                    <Header />
                    <SubMenu/>
                    <div className="adm-page-cominicacao mural-anuncio">
                        <h3 className="titulo">Mural de anúncios
                            <select name="filtro" className="filtro"  onChange={myChangeHandlerFiltro}  >
                                <option value="Maisrecentesprimeiro">Mais recentes primeiro</option>
                                <option value="Maisantigoprimeiro">Mais antigo primeiro</option>
                            </select>
                        </h3>
                        <input
                        type="text"
                        value={name !== undefined ? name : ''}
                        placeholder="Buscar nos anúncios"
                        className="busca"
                        onChange={(event) => loadfilterAnuncio(event)}
                        />
                        <button className='btnGreen postaMural-mb' onClick={() => setIsModalPostMuralAdd(true)}>Postar no mural</button>
                        <div className="box-anucios">
                            {editarAnuncio ?
                                <>
                                <div className="form-anuncio" >
                                    <div className="form">
                                        <div className="titulo">

                                            { (anuncioUpdate && anuncioUpdate.announcementTitle !== undefined) || anuncioUpdate.announcementTitle === '' ?
                                                (
                                                    <input type="text" name="announcementTitle" placeholder="Titulo"  defaultValue={(anuncioUpdate.announcementTitle !== undefined) ? anuncioUpdate.announcementTitle : ''} onChange={myChangeHandlerUpdate}  />
                                                ) : (
                                                    <input type="text" name="announcementTitle" placeholder="Titulo"  value={(anuncioCheck.announcementTitle !== undefined) ? anuncioCheck.announcementTitle : ''} onChange={myChangeHandlerUpdate}  />
                                                )}

                                        </div>

                                        { (anuncioUpdate && anuncioUpdate.announcementDescription !== undefined) || anuncioUpdate.announcementDescription === '' ?
                                            (
                                                <textarea name="announcementDescription" placeholder="Digite um novo anúncio…" defaultValue={(anuncioUpdate.announcementDescription !== undefined) ? anuncioUpdate.announcementDescription : ''}  onChange={myChangeHandlerUpdate}></textarea>
                                            ) : (
                                                <textarea name="announcementDescription" placeholder="Digite um novo anúncio…" value={(anuncioCheck.announcementDescription !== undefined) ? anuncioCheck.announcementDescription : ''}  onChange={myChangeHandlerUpdate}></textarea>
                                            )}
                                    </div>
                                    {loadPostTitle? (
                                        <p className="textError textErrorTextarea">O campo do titulo e obrigatório.</p>
                                    ) : ('')}
                                    {loadPostDescription? (
                                        <p className="textError textErrorTextarea">O campo do descrição e obrigatório.</p>
                                    ) : ('')}

                                    <div className="botoes">
                                        <button className="excluir"  onClick={() => handleDelete(anuncioCheck && anuncioCheck._id)}>Excluir</button>
                                        <form onSubmit={handleEdite}>
                                            <button className={loadBtnPostAnuncio  ? 'enviar btnGreen load' : 'enviar btnGreen '}>Editar no mural</button>
                                        </form>
                                        <button className="cancelar" onClick={() => CancelaEditPos()}>Cancelar</button>
                                    </div>

                                </div>

                                </>:("")
                            }
                        {verAnuncios ?
                            <>
                            <div className="form-anuncio" >
                                <div className="form">
                                    <div className="titulo">
                                        <input type="text" name="announcementTitle" placeholder="Titulo"  value={(anuncioRegistration.announcementTitle !== undefined) ? anuncioRegistration.announcementTitle : ''} onChange={myChangeHandlerPost}  />
                                    </div>
                                    <textarea name="announcementDescription" placeholder="Digite um novo anúncio…" value={(anuncioRegistration.announcementDescription !== undefined) ? anuncioRegistration.announcementDescription : ''}  onChange={myChangeHandlerPost}></textarea>
                                </div>
                                {loadPostTitle? (
                                    <p className="textError textErrorTextarea">O campo do titulo e obrigatório.</p>
                                ) : ('')}
                                {loadPostDescription? (
                                    <p className="textError textErrorTextarea">O campo do descrição e obrigatório.</p>
                                ) : ('')}

                                    <div className="botoes">
                                        <form onSubmit={handlePost}>
                                            <button className={loadBtnPostAnuncio  ? 'enviar btnGreen load' : 'enviar btnGreen '}>Postar no mural</button>
                                        </form>
                                        <button className="cancelar" onClick={() => CancelaEditPos()}>Cancelar</button>
                                    </div>

                            </div>



                            <div className="feed">
                                <ul>
                                {
                                    loadAnuncios ?
                                    (
                                        <>

                                        <div className="carregamento">
                                            <img alt="" src={Carregamento} />
                                        </div>
                                        </>
                                    ):
                                    anuncioLista && anuncioLista.length !== 0 ? anuncioLista
                                    .map((anuncioList, index) => (
                                    <>

                                    {anuncioList.category === "Recado" ?
                                        <li className="box-feed messages-rh" key={index}>
                                            {/* aqui */}

                                            {
                                                anuncioList.avatar && anuncioList.avatar !== 'undefined' ?
                                                <img alt="" src={anuncioList.avatar} className="box-img" />
                                                :
                                                <div className="semImgCol">
                                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            }
                                            {/* <img src={anuncioList.avatar} alt="img" className="box-img"/> */}
                                            <div className="box-infor-like">
                                                <div className="box-infor">
                                                    <h1>{anuncioList.announcementTitle}</h1>
                                                    <div>
                                                        {
                                                            showMoreAnuncioList && showMoreAnuncioList === anuncioList._id ?
                                                            anuncioList.announcementDescription.split('\n').map(str => <p>{str}</p>)
                                                            :
                                                            <>
                                                            {
                                                                anuncioList.announcementDescription.split('\n').map(str => <p>{oneHundredCharacters(str)}</p>)
                                                            }
                                                            {
                                                                anuncioList.announcementDescription.length >= 300 ?
                                                                <p onClick={() => showMoreAnuncio(anuncioList._id)} >
                                                                        Ver mais
                                                                </p>
                                                                :
                                                                ''
                                                            }
                                                            </>
                                                        }


                                                    </div>
                                                </div>
                                                <div className="btn-editar"><button onClick={() => UpdateAnuncio(anuncioList)}><img src={EditarBlack} alt="editar" /></button></div>
                                            </div>
                                            <div></div>


                                                {
                                                anuncioList.totalLikes === 0 ? (
                                                    <div className="like">
                                                        <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curtir" /></button>
                                                    </div>
                                                )
                                                :
                                                anuncioList.totalLikes !== 0 && anuncioList.likeUser === false ? (
                                                    <div className="like like-false">
                                                        <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curtir" />{anuncioList.totalLikes}</button>
                                                    </div>
                                                )
                                                :
                                                anuncioList.totalLikes !== 0 && anuncioList.likeUser === true ? (
                                                    <div className="like like-true">
                                                    <button onClick={() => deleteLike(anuncioList._id)}><img src={IconHeart} alt="Curtir" /> {anuncioList.totalLikes}</button>
                                                </div>
                                                )
                                                :
                                                ''
                                                }


                                        </li>


                                    :

                                    <li className="box-feed box-birthday" key={index}>
                                        {/* aqui */}

                                        {
                                            anuncioList.avatar && anuncioList.avatar !== 'undefined' ?
                                            <img alt="" src={anuncioList.avatar} className="box-img" />
                                            :
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        }
                                        {/* <img src={anuncioList.avatar} alt="img" className="box-img"/> */}
                                        <div className="box-infor-like">
                                            <div className="box-infor">
                                                <h1>{anuncioList.announcementTitle}</h1>
                                                <div>
                                                    {
                                                        showMoreAnuncioList && showMoreAnuncioList === anuncioList._id ?
                                                        <p>
                                                            {
                                                                anuncioList.announcementDescription.replace('\n', "     ")
                                                            }
                                                        </p>
                                                        :
                                                        <>
                                                        <p>
                                                            {
                                                                oneHundredCharacters(anuncioList.announcementDescription.replace('\n', '    '))
                                                            }
                                                        </p>
                                                        {
                                                            anuncioList.announcementDescription.length >= 300 ?
                                                            <p onClick={() => showMoreAnuncio(anuncioList._id)} >
                                                                    Ver mais
                                                            </p>
                                                            :
                                                            ''
                                                        }
                                                        </>
                                                    }

                                                    <img src={IllustrationBirthday} alt="Ilustração Aniversario" className="ilustracao"/>

                                                </div>
                                            </div>
                                        </div>
                                        <div></div>


                                            {
                                            anuncioList.totalLikes === 0 ? (
                                                <div className="like">
                                                    <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curtir" /></button>
                                                </div>
                                            )
                                            :
                                            anuncioList.totalLikes !== 0 && anuncioList.likeUser === false ? (
                                                <div className="like like-false">
                                                    <button onClick={() => saveLike(anuncioList._id)}><img src={IconHeartGray} alt="Curtir" />{anuncioList.totalLikes}</button>
                                                </div>
                                            )
                                            :
                                            anuncioList.totalLikes !== 0 && anuncioList.likeUser === true ? (
                                                <div className="like like-true">
                                                <button onClick={() => deleteLike(anuncioList._id)}><img src={IconHeart} alt="Curtir" /> {anuncioList.totalLikes}</button>
                                            </div>
                                            )
                                            :
                                            ''
                                            }


                                    </li>


                                    }

                                    </>
                                    )) : (
                                        <>
                                            <div className="feed-nenhum">
                                                <p>Nenhum anúncio no mural.</p>
                                                <img src={IllustracaoMural} alt="" />

                                            </div>
                                        </>
                                    )}
                                </ul>
                            </div>


                            </>
                            : ('')
                            }

                        </div>
                    </div>
                </Layout>





















            </>
    );
}
