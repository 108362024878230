import React  from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../../assets/images/more.svg';

export default function homeFeedbackColComment(props) {
    return (
        <>
            <div className='box-leiaute comment'>
                <h2 className='titulo'>Anotações</h2>
                <div className='box-list-comment'>
                    <Scrollbars>
                        <ul>
                            <li>
                                <div className='collaborador-view-more'>

                                    <div className='collaborador'>

                                        <div className='infor'>
                                            <strong>Anitra Vasconcelos</strong>
                                            <p>Diretora de atendimento <span>9 Dez 2021 às 9:42</span></p>
                                        </div>

                                    </div>

                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle><img alt="" src={iconVerMais} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button type="button" > Editar anotação</button>
                                                <button type="button" className='red' > Excluir anotação</button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className='comment-infor'>
                                    <p>
                                    A conversa foi muito produtiva, o colaborador muito está satisfeito com o trabalho que vem sendo desempenhado e com o ambiente e flexibidade do trabalho.
                                    </p>
                                    <p>
                                    Apenas solicitou algumas ferramentas extras para realizar o trabalho. A conversa foi muito produtiva, o colaborador muito está satisfeito com o trabalho que vem sendo desempenhado e com o ambiente e flexibidade do trabalho. Apenas solicitou algumas ferramentas extras para realizar o trabalho.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className='collaborador-view-more'>

                                    <div className='collaborador'>
                                        <div className='infor'>
                                            <strong>Anitra Vasconcelos</strong>
                                            <p>Diretora de atendimento <span>9 Dez 2021 às 9:42</span></p>
                                        </div>

                                    </div>

                                    <div className='view-more'>
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle><img alt="" src={iconVerMais} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button type="button" > Editar anotação</button>
                                                <button type="button" className='red' > Excluir anotação</button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className='comment-infor'>
                                    <p>
                                    A conversa foi muito produtiva, o colaborador muito está satisfeito com o trabalho que vem sendo desempenhado e com o ambiente e flexibidade do trabalho.
                                    </p>
                                    <p>
                                    Apenas solicitou algumas ferramentas extras para realizar o trabalho. A conversa foi muito produtiva, o colaborador muito está satisfeito com o trabalho que vem sendo desempenhado e com o ambiente e flexibidade do trabalho. Apenas solicitou algumas ferramentas extras para realizar o trabalho.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </Scrollbars>

                </div>
                <div className='box-form'>
                    <input type="text"  placeholder='Seus comentários e notas…'/>
                    <div className='btns'>
                        <button className='cancela'>Cancelar</button>
                        <button className='btnGreen load'>Publicar</button>
                    </div>

                </div>
            </div>         
        </>
    );
}
