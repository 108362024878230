import React, { useState, useEffect } from 'react';

import Layout from '../../../../components/Layouts/default';

import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import Personal from '../../../../components/Administrativo/Colaboradores/profile/Personal';
import {FormataStringData } from "../../../../services/utils";
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import './styles.scss';
import swal from 'sweetalert';
import api from '../../../../services/api';
import { decodeToken } from '../../../../services/auth';
import axios from 'axios';
import { dateMask, mNumber } from '../../../../services/mask';
import { resizeImage } from '../../../../services/utils';
import { toast } from 'react-toastify';

export default function ColProfilePersonal(props) {

    const { id } = props.match.params
    const path = props.match.path;
    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) { loadcollaborator(id); }

        if(
            (id && id !== undefined && role === 'admin')
            ||
            (id && id !== undefined && role === 'master')
        ) {  loadfilessaved(id); }

        loadufestados();


    }, [id, role]);

    async function loadcollaborator(id) {

        setLoadPage(true)
        await api.get(`/collaborator/${id}`)
        .then(response => {
            setLoadPage(false)
            setCollaborator(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadfilessaved(id) {

        await api.get(`/collaborator-file/?id=${id}&category=identification`)
        .then(response => {

            setLoadFileNamesPersonalData(false)
            setFilesSavedPersonalData(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


      //////////////////////////////////////////////////////////////////////////////////
     ////////////////// INFORMAÇÕES PESSOAIS //////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileCertificateSend, setFileCertificateSend] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedPersonalData, setFilesSavedPersonalData] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFileNamesPersonalData, setLoadFileNamesPersonalData ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesPersonalData] = useState([]);


    async function handleDropPersonalData(acceptedFilesPersonalData) {


    let file = acceptedFilesPersonalData[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {

        setFileNamesPersonalData(acceptedFilesPersonalData.map(file => file.name));

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if (acceptedFilesPersonalData === undefined) {
            return
        }

        formData.append('file', acceptedFilesPersonalData[0]);
        formData.append('name', acceptedFilesPersonalData[0].name);
        formData.append('category', 'identification');
        formData.append('collaborator', id);
        formData.append('path', path);
        setLoadFileNamesPersonalData(true)

        await api.post("/collaborator-file", formData, config)
        .then(response => {

            setFileCertificateSend(true)
            loadfilessaved(id);

        }).catch(error => {

            setFileNamesPersonalData(false)

        });
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }

    }


    async function deleteFile(filesaved) {

        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                setFileNamesPersonalData(true)
                await api.delete(`/collaborator-file/${filesaved._id}?path=${path}`)
                .then(() => {

                    setFileCertificateSend(true)
                    loadfilessaved(id);

                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

      //////////////////////////////////////////////////////////////////////////////////
     ////////////////// FINAL INFORMAÇÕES PESSOAIS ////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfBirthUpdate, setDateOfBirthUpdate ] = useState([]);

     async function myChangeHandler(event) {
         let nam = event.target.name;
         let val = event.target.value;

         if (nam === "zipcode") {
              getAddress(val)
         } else if (nam === "bankAgency") {

             let value = event.target.value.replace(/[^\d]/g, "")
            //  console.log(value.length)
             if(value.length > 10) {
                 //alert('entrou')
                setCollaboratorUpdate({ ...collaboratorUpdate })
             } else {
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: value })

             }
         } else if (nam === "bankAccount") {
            let bankAccount = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: bankAccount })
        }
        else if (nam === "bornState") {
            let estado = val;

            await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
            .then(response => {
                let bornStateString = response.data.nome;
                setCollaboratorUpdate({ ...collaboratorUpdate, bornStateString, [nam]: val })
            }).catch(error => {});


        } else if (nam === "bornCity") {

            let cidade = val;

            if(cidade === '350000000') {

                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';

                setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })

            }
            else
            if(collaboratorUpdate.bornState === '35' && cidade === '350000000') {

                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';

                setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })

            }
            else {

                async function loadCity() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                    .then(response => {
                        let bornCityString = response.data[0].nome;
                        setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                    }).catch(error => {});

                }
                loadCity();

            }


        }
        else
        if (nam === "dateOfBirth") {

            let date = dateMask(val)
            if(date.length <= 10) {


                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: date })
                if(date.length === 10) {
                    let valDate = FormataStringData(date);
                    //LoadsetdateOfBirth(false)
                    setDateOfBirthUpdate({ ...dateOfBirthUpdate, [nam]: valDate })
                }
            }

        }
        else {
             setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

     };
//    eslint-disable-next-line react-hooks/rules-of-hooks
//    const [ bankName, setBankName] = useState([]);

   async function myChangeHandlerToSaveBank(event) {
        setCollaboratorUpdate({ ...collaboratorUpdate, "bankName": event})

    //    setBankName({ ...bankName, "nameBank": event })
       setAbrirResultBank(false)
   }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ abrirResultBank, setAbrirResultBank ] = useState(false);

    function abrirResultado() {
        setAbrirResultBank(!abrirResultBank)
        setOpenSelAccountType(false)
    }

    function fecharBusca() {
            setAbrirResultBank(false)
    }

    function mySearchBank(event) {
         // Declare variables
         event.preventDefault();

         let nam = event.target.name;
         let val = event.target.value;

         setAbrirResultBank(true)
         var input, filter, ul, li, a, i, txtValue;
         input = document.getElementById('myBank');
         filter = input.value.toUpperCase();
         ul = document.getElementById("resultadoBusca");

         if(ul !== null) {
             li = ul.getElementsByTagName('button');

             for (i = 0; i < li.length; i++) {

                a = li[i].getElementsByTagName("p")[0];

                if(a && a !== null ) {

                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                    } else {
                        li[i].style.display = "none";
                    }

                }

             }
             setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
             return
         }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ openSelAccountType, setOpenSelAccountType ] = useState(false);

    async function openAccountType(e) {
        if(
            e.target.className !== "btn-select selectAccountType"
            && e.target.id !== "myBank"
        ) {
            setOpenSelAccountType(false)
            setAbrirResultBank(false)
        }
    }
    async function selectAccountTypeOpen(e){
        setOpenSelAccountType(!openSelAccountType)
    }
    async function myChangeHandlerAccountType(event) {

        let nam = event;
        setCollaboratorUpdate({ ...collaboratorUpdate, "AccountType": nam})
        setOpenSelAccountType(!openSelAccountType)
   }

    // eslint-disable-next-line no-undef
    async function getAddress(zipcode) {
        zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        if (zipcode.length >= 8) {
            //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
            const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

            if (response.data !== null) {
                setCollaborator({
                    ...collaborator,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEstados, setUfEstados ] = useState([]);

    async function loadufestados() {

        await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
        .then(response => {
            setUfEstados(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePersonalData, setVisiblePersonalData] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectEthnicity, setSelectEthnicity] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectNationality, setSelectNationality] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectGender, setSelectGender] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectMaritalStatus, setSelectMaritalStatus] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectBornState, setSelectBornState] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectBornCity, setSelectBornCity] = useState(false);

    function MakePersonalDataVisible() {
        setVisiblePersonalData(!visiblePersonalData);
        setCollaboratorUpdate([])
        setSelectEthnicity(false)
        setSelectNationality(false)
        setSelectGender(false)
        setSelectMaritalStatus(false)
        setSelectBornState(false)
        setSelectBornCity(false)
    }

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [visibleBank, setVisibleBank] = useState(false);
     function EditBankDataVisibleBank() {
        setVisibleBank(!visibleBank);
        setCollaboratorUpdate([])
        setAbrirResultBank(false)
        setOpenSelAccountType(false)
     }

      //////////////////////////////////////////////////////////////////////////////////
     ////////////////// FIM SELECTS INFORMACOES PESSOAIS ///////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);

    async function save(event) {
        event.preventDefault();

        const formData     = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        //visiblePersonalData
        if(
            collaboratorUpdate                     === undefined ||
            collaboratorUpdate.length              ===     0

        ) {

            if(fileCertificateSend && fileCertificateSend === true) {
                swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
                setFileCertificateSend(false)
                setVisiblePersonalData(!visiblePersonalData);
            } else {
                swal({ icon: "error", title: "Erro!", text: 'Necessário atualizar pelo menos um campo!' });
            }

        } else {

            if(
                collaboratorUpdate.name                === "" ||
                collaboratorUpdate.dateOfBirth         === "" ||
                collaboratorUpdate.gender              === "" ||
                collaboratorUpdate.fatherName          === "" ||
                collaboratorUpdate.motherName          === "" ||
                collaboratorUpdate.maritalStatus       === "" ||
                collaboratorUpdate.nationality         === "" ||
                collaboratorUpdate.otherNationality    === "" ||
                collaboratorUpdate.ethnicity           === "" ||
                collaboratorUpdate.addressNumber       === "" ||
                collaboratorUpdate.address             === "" ||
                collaboratorUpdate.phone               === "" ||
                collaboratorUpdate.cellphone           === "" ||
                collaboratorUpdate.zipcode             === "" ||
                collaboratorUpdate.addressNeighborhood === "" ||
                collaboratorUpdate.addressCity         === "" ||
                collaboratorUpdate.addressState        === "" ||
                collaboratorUpdate.addressComplement   === "" ||
                collaboratorUpdate.bankCode            === "" ||
                collaboratorUpdate.bankName            === "" ||
                collaboratorUpdate.bankAgency          === "" ||
                collaboratorUpdate.bankAccount         === "" ||
                collaboratorUpdate.AccountType         === "" ||
                collaboratorUpdate.pix                 === ""
            ) {
                swal({ icon: "error", title: "Erro!", text: 'Nenhum campo pode ser salvo em branco!' });
            } else {

                if(collaboratorUpdate.name !== undefined) {
                    formData.append('name', collaboratorUpdate.name);
                }
                if(collaboratorUpdate.dateOfBirth !== undefined) {
                    //formData.append('dateOfBirth', collaboratorUpdate.dateOfBirth);
                    formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
                }
                if(collaboratorUpdate.gender !== undefined) {
                    formData.append('gender', collaboratorUpdate.gender);
                }
                if(collaboratorUpdate.fatherName !== undefined) {
                    formData.append('fatherName', collaboratorUpdate.fatherName);
                }
                if(collaboratorUpdate.motherName !== undefined) {
                    formData.append('motherName', collaboratorUpdate.motherName);
                }
                if(collaboratorUpdate.maritalStatus !== undefined) {
                    formData.append('maritalStatus', collaboratorUpdate.maritalStatus);
                }
                if(collaboratorUpdate.nationality !== undefined) {
                    formData.append('nationality', collaboratorUpdate.nationality);
                }
                if(collaboratorUpdate.ethnicity !== undefined) {
                    formData.append('ethnicity', collaboratorUpdate.ethnicity);
                }
                if(collaboratorUpdate.bornState !== undefined && collaboratorUpdate.bornState !== '') {
                    formData.append('bornState', collaboratorUpdate.bornState);
                }
                if(collaboratorUpdate.bornStateString !== undefined && collaboratorUpdate.bornStateString !== '') {
                    formData.append('bornStateString', collaboratorUpdate.bornStateString);
                    //
                }

                if(collaboratorUpdate.bornCity !== undefined && collaboratorUpdate.bornCity !== '') {
                    formData.append('bornCity', collaboratorUpdate.bornCity);
                }

                if(collaboratorUpdate.bornCityString !== undefined && collaboratorUpdate.bornCityString !== '') {
                    formData.append('bornCityString', collaboratorUpdate.bornCityString);
                    //
                }
                if(collaboratorUpdate.addressNumber !== undefined) {
                    formData.append('addressNumber', collaboratorUpdate.addressNumber);
                }
                if(collaboratorUpdate.address !== undefined) {
                    formData.append('address', collaboratorUpdate.address);
                }
                if(collaboratorUpdate.phone !== undefined) {
                    formData.append('phone', collaboratorUpdate.phone);
                }
                if(collaboratorUpdate.cellphone !== undefined) {
                    formData.append('cellphone', collaboratorUpdate.cellphone);
                }
                if(collaboratorUpdate.zipcode !== undefined) {
                    formData.append('zipcode', collaboratorUpdate.zipcode);
                }
                if(collaboratorUpdate.addressNeighborhood !== undefined) {
                    formData.append('addressNeighborhood', collaboratorUpdate.addressNeighborhood);
                }
                if(collaboratorUpdate.addressCity !== undefined) {
                    formData.append('addressCity', collaboratorUpdate.addressCity);
                }
                if(collaboratorUpdate.addressState !== undefined) {
                    formData.append('addressState', collaboratorUpdate.addressState);
                }
                if(collaboratorUpdate.addressComplement !== undefined) {
                    formData.append('addressComplement', collaboratorUpdate.addressComplement);
                }
                if(collaboratorUpdate.bankCode !== undefined) {
                    formData.append('bankCode', collaboratorUpdate.bankCode);
                }
                if(collaboratorUpdate.bankName !== undefined) {
                    formData.append('bankName', collaboratorUpdate.bankName);
                }
                if(collaboratorUpdate.bankAgency !== undefined) {
                    formData.append('bankAgency', collaboratorUpdate.bankAgency);
                }
                if(collaboratorUpdate.bankAccount !== undefined) {
                    formData.append('bankAccount', collaboratorUpdate.bankAccount);
                }
                if(collaboratorUpdate.accountDigit !== undefined) {
                    formData.append('accountDigit', collaboratorUpdate.accountDigit);
                }
                if(collaboratorUpdate.AccountType !== undefined) {
                    formData.append('AccountType', collaboratorUpdate.AccountType);
                }
                if(collaboratorUpdate.pix !== undefined) {
                    formData.append('pix', collaboratorUpdate.pix);
                }
                formData.append('path', path);

                setLoadContinue(true)

                await api.put(`/collaborator/${id}`, formData, config)
                .then(response => {
                    setLoadContinue(false)
                    if(visiblePersonalData === true) {
                        setVisiblePersonalData(!visiblePersonalData);
                    }
                    if(visibleBank === true) {
                        setVisibleBank(!visibleBank);
                    }

                    setCollaboratorUpdate([])
                    loadcollaborator(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Dados do colaborador alterado com sucesso!" });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadContinue(false)
                });
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        console.log(acceptedFiles[0])

        let file = acceptedFiles[0]

        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (acceptedFiles === undefined) {

                return
            }

            let fileResized = await resizeImage(acceptedFiles[0]);

            formData.append('file', fileResized);
            formData.append('path', path);

            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {

                setLoadAvatar(false)
                loadcollaborator(id);
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }


    return (
        <>
            <Layout
            path={props.match.path}
            >
            <Header
            id={id}
            />
            <InforProfile
            collaborator={collaborator}
            id={id}
            loadAvatar={loadAvatar}
            path={path}
            handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
            user={user}
            role={role}
            loadcollaborator={loadcollaborator}
            />
            <SubHeader
                id={id}
                path={props.match.path}
                collaborator={collaborator}
                role={role}
            />
            <Personal
                id={id}
                loadPage={loadPage}
                path={props.match.path}
                myChangeHandlerToSaveBank={myChangeHandlerToSaveBank}
                MakePersonalDataVisible={MakePersonalDataVisible}
                visiblePersonalData={visiblePersonalData}
                EditBankDataVisibleBank={EditBankDataVisibleBank}
                visibleBank={visibleBank}
                collaborator={collaborator}
                setCollaborator={setCollaborator}
                collaboratorUpdate={collaboratorUpdate}
                setCollaboratorUpdate={setCollaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                ufEstados={ufEstados}
                ufDistrito={
                    collaboratorUpdate && collaboratorUpdate.bornState ?
                    collaboratorUpdate.bornState :
                    collaborator && collaborator.bornState ?
                    collaborator.bornState :
                    ''
                }
                ufCity={
                    collaborator && collaborator.bornCity ?
                    collaborator.bornCity :
                    collaboratorUpdate && collaboratorUpdate.bornCity ?
                    collaboratorUpdate.bornCity : ''
                }
                OnSubmit={save}
                loadContinue={loadContinue}
                loadFileNamesPersonalData={loadFileNamesPersonalData}
                filesSavedPersonalData={filesSavedPersonalData}
                handleDropPersonalData={handleDropPersonalData}
                deleteFile={deleteFile}
                //collaboratorUpdate
                abrirResultado={abrirResultado}
                abrirResultBank={abrirResultBank}
                mySearchBank={mySearchBank}
                fecharBusca={fecharBusca}

                openSelAccountType={openSelAccountType}
                openAccountType={openAccountType}
                selectAccountTypeOpen={selectAccountTypeOpen}
                myChangeHandlerAccountType={myChangeHandlerAccountType}
                user={user}
                role={role}

                setSelectEthnicity={setSelectEthnicity}
                setSelectNationality={setSelectNationality}
                setSelectGender={setSelectGender}
                setSelectMaritalStatus={setSelectMaritalStatus}
                setSelectBornState={setSelectBornState}
                setSelectBornCity={setSelectBornCity}

                selectEthnicity={selectEthnicity}
                selectNationality={selectNationality}
                selectGender={selectGender}
                selectMaritalStatus={selectMaritalStatus}
                selectBornState={selectBornState}
                selectBornCity={selectBornCity}
            />
            </Layout>
        </>
    );
}
