import React, { useState, useEffect } from 'react';
import SubHeader from '../../../components/PerfilUser/subHeader';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import Documents from '../../../components/PerfilUser/Personal/Documents';
import { mCPF, mNumber, dateMask, mCNH, mPIS, mReservationCardNumber, mVoterRegistration } from '../../../services/mask';
import { toast } from 'react-toastify';

import Layout from '../../../components/Layouts/defaultUser';
import '../styles.scss';

import swal from 'sweetalert';
import axios from 'axios';
import { decodeToken } from '../../../services/auth';

import {FormataStringData } from "../../../services/utils";
import api from '../../../services/api';

export default function ColProfilePersonal(props) {

const decoded = decodeToken();
var id = decoded.user._id;

const { path } = props.match;

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaborator, setCollaborator ] = useState([]);


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadPage, setLoadPage ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    if(id && id) {
        loadcollaborator(id);
        loadfilessavedCPF(id);
        loadfilessavedworkcard(id);
        loadfilessavedvotertitle(id);
        loadfilessavedrg(id);
        loadfilessavedcnh(id);
        loadfilessavedreservist(id);
    }
}, [id]);


async function loadcollaborator(id) {
    setLoadPage(true)
    await api.get(`/collaborator-access/${id}`)
    .then(response => {
        setCollaborator(response.data);
        setCollaboratorUpdate({});
        setLoadPage(false)
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });

}

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////    CPF    /////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileCPFSend, setfileCPFSend] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedCPF, setFilesSavedCPF] = useState([]);


async function loadfilessavedCPF(id) {
    setLoadCPF(false)
    await api.get(`/collaborator-file-access/?id=${id}&category=docCPF`)
    .then(response => {
        // setfileCPFSend(true)
        setFilesSavedCPF(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}


 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [ loadCPF, setLoadCPF ] = useState(false);

async function deleteFileCPF(filesaved) {
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoadCPF(true)
            await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
            .then(() => {
                loadfilessavedCPF(id);
                setfileCPFSend(true)
            }).catch(error => {});
        }
    });
}


// eslint-disable-next-line react-hooks/rules-of-hooks
const [, setFileNamesCPF] = useState([]);

async function handleDropCPF(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {
        setFileNamesCPF(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if (acceptedFiles === undefined) {
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docCPF');
        formData.append('collaborator', id);
        formData.append('path', path);
        setLoadCPF(true)
        await api.post("/collaborator-file-access", formData, config)
        .then(() => {
            loadfilessavedCPF(id);
            setfileCPFSend(true)
        }).catch(error => {});
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }
}

/////////////////////////////////////////////////////////////////////////////
////////////////////////    FINAL DE CPF    /////////////////////////////////
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
///////////////////////    CARTEIRA DE TRABALHO    //////////////////////////
/////////////////////////////////////////////////////////////////////////////


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ selectWalletUF, setSelectWalletUF] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileWorkCardSend, setfileWorkCardSend] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedWorkCard, setFilesSavedWorkCard] = useState([]);

async function loadfilessavedworkcard(id) {
    await api.get(`/collaborator-file-access/?id=${id}&category=docCT`)
    .then(response => {
        setFilesSavedWorkCard(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadWorkCard, setLoadWorkCard ] = useState(false);

async function deleteFileWorkCard(filesaved) {
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoadWorkCard(true)
            await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
            .then(() => {
                setLoadWorkCard(false)
                loadfilessavedworkcard(id)
                setfileWorkCardSend(true);
            }).catch(error => {});
        }
    });
}


// eslint-disable-next-line react-hooks/rules-of-hooks
const [, setFileNamesWorkCard] = useState([]);

async function handleDropWorkCard(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {
        setFileNamesWorkCard(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docCT');
        formData.append('collaborator', id);
        formData.append('path', path);
        setLoadWorkCard(true)
        await api.post("/collaborator-file-access", formData, config)
        .then(() => {
            setLoadWorkCard(false)
            loadfilessavedworkcard(id)
            setfileWorkCardSend(true);
        }).catch(error => {});
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }
}

/////////////////////////////////////////////////////////////////////////////
////////////////////    FINAL CARTEIRA DE TRABALHO    ///////////////////////
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
//////////////////////////    TÍTULO DE ELEITOR  ////////////////////////////
/////////////////////////////////////////////////////////////////////////////

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileVoterTitleSend, setfileVoterTitleSend] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedVoterTitle, setFilesSavedVoterTitle] = useState([]);

async function loadfilessavedvotertitle(id) {
    await api.get(`/collaborator-file-access/?id=${id}&category=docTE`)
    .then(response => {
        setFilesSavedVoterTitle(response.data);
        setLoadVoterTitle(false)
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadVoterTitle, setLoadVoterTitle ] = useState(false);

async function deleteFileVoterTitle(filesaved) {

swal({
    title: "Atenção",
    text: "Deseja excluir este arquivo?",
    icon: "warning",
    buttons: ["Cancelar", "OK"],
    dangerMode: false,
})
    .then(async (res) => {
        if (res) {
            setLoadVoterTitle(true)
            await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
            .then(() => {
                setfileVoterTitleSend(true);
                loadfilessavedvotertitle(id);
            }).catch(error => {});
        }
    });
}


// eslint-disable-next-line react-hooks/rules-of-hooks
const [, setFileNamesVoterTitle] = useState([]);

async function handleDropVoterTitle(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {
        setFileNamesVoterTitle(acceptedFiles.map(file => file.name));
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docTE');
        formData.append('collaborator', id);
        formData.append('path', path);
        setLoadVoterTitle(true)
        await api.post("/collaborator-file-access", formData, config)
        .then(() => {
            setfileVoterTitleSend(true);
            loadfilessavedvotertitle(id);
        }).catch(error => {});
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }
}

/////////////////////////////////////////////////////////////////////////////
//////////////////////    FINAL DE TÍTULO DE ELEITOR  ///////////////////////
/////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////   RG  ///////////////////////////////////
/////////////////////////////////////////////////////////////////////////////


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ selectRGufOrgan, setSelectRGufOrgan] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileRGSend, setfileRGSend] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedRG, setFilesSavedRG] = useState([]);

async function loadfilessavedrg(id) {
    await api.get(`/collaborator-file-access/?id=${id}&category=docRG`)
    .then(response => {
        setFilesSavedRG(response.data);
        setLoadRG(false)
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadRG, setLoadRG ] = useState(false);

async function deleteFileRG(filesaved) {
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoadRG(true)
            await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
            .then(() => {
                setfileRGSend(true)
                loadfilessavedrg(id);
            }).catch(error => {});
        }
    });
}

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesRG] = useState([]);

    async function handleDropRG(acceptedFiles) {

    setFileNamesRG(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docRG');
        formData.append('collaborator', id);
        formData.append('path', path);
        setLoadRG(true)
        await api.post("/collaborator-file-access", formData, config)
        .then(() => {
            setfileRGSend(true)
            loadfilessavedrg(id);
        }).catch(() => {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
        });

    }
/////////////////////////////////////////////////////////////////////////////
/////////////////////////// FINAL DE RG  ////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////////////////////////////////////////////
///////////////////////////////   CNH      //////////////////////////////////
/////////////////////////////////////////////////////////////////////////////


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ selectCnhCategory, setSelectCnhCategory] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ selectUfCNH, setSelectUfCNH] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileCNHSend, setfileCNHSend] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedCNH, setFilesSavedCNH] = useState([]);

async function loadfilessavedcnh(id) {
    setLoadCNH(true)
    await api.get(`/collaborator-file-access/?id=${id}&category=docCNH`)
    .then(response => {
        setLoadCNH(false)
        setFilesSavedCNH(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadCNH, setLoadCNH ] = useState(false);

async function deleteFileCNH(filesaved) {
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoadCNH(true)
            await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
            .then(() => {
                setfileCNHSend(true)
                loadfilessavedcnh(id);
            }).catch(error => {});
        }
    });
}


// eslint-disable-next-line react-hooks/rules-of-hooks
const [, setFileNamesCNH] = useState([]);

async function handleDropCNH(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {
        setFileNamesCNH(acceptedFiles.map(file => file.name));

        const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCNH');
            formData.append('collaborator', id);
            formData.append('path', path);
            setLoadCNH(true)
            await api.post("/collaborator-file-access", formData, config)
            .then(() => {
                setfileCNHSend(true)
                loadfilessavedcnh(id);
            }).catch(() => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            });
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }

}

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////    FINAL DE CNH  //////////////////////////////
/////////////////////////////////////////////////////////////////////////////


/////////////////////////////////////////////////////////////////////////////
/////////////////////////   CARTEIRA DE RESERVISTA   ////////////////////////
/////////////////////////////////////////////////////////////////////////////


// eslint-disable-next-line react-hooks/rules-of-hooks
const [ selectUfReservationCard, setSelectUfReservationCard] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ selectCityReservationCard, setSelectCityReservationCard] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [fileReservistSend, setfileReservistSend] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSavedReservist, setFilesSavedReservist] = useState([]);

async function loadfilessavedreservist(id) {
    setLoadReservist(true)
    await api.get(`/collaborator-file-access/?id=${id}&category=docCR`)
    .then(response => {
        setFilesSavedReservist(response.data);
        setLoadReservist(false)
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadReservist, setLoadReservist ] = useState(false);

async function deleteFileReservist(filesaved) {
   swal({
       title: "Atenção",
       text: "Deseja excluir este arquivo?",
       icon: "warning",
       buttons: ["Cancelar", "OK"],
       dangerMode: false,
   })
    .then(async (res) => {
        if (res) {
            setLoadReservist(true)
            await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
            .then(() => {
                setfileReservistSend(true)
                loadfilessavedreservist(id);
            }).catch(() => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
            });
        }
    });
  }

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [, setFileNamesReservist] = useState([]);

   async function handleDropReservist(acceptedFiles) {

    let file = acceptedFiles[0]
    const typeI = file.type ;
    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {
        setFileNamesReservist(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if (acceptedFiles === undefined) {
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docCR');
        formData.append('collaborator', id);
        formData.append('path', path);
        setLoadReservist(true)
        await api.post("/collaborator-file-access", formData, config)
        .then(() => {
            setfileReservistSend(true)
            loadfilessavedreservist(id);
        }).catch(() => {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
        });
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }

}

    /////////////////////////////////////////////////////////////////////////////
   ////////////////////    FINAL DE CARTEIRA DE RESERVISTA  ////////////////////
  /////////////////////////////////////////////////////////////////////////////

   const  myChangeHandler = event => {
       let nam = event.target.name;
       let val = event.target.value;


        if (nam === "cpf") {
            let cpf = mCPF(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cpf })
        }
        else
        if (nam === "zipcode") {
            getAddress(val)
        }
        else
        if (nam === "dateOfBirth") {
            let valDate = FormataStringData(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valDate })
        }
        else
        if (nam === "walletNumber") {
            let walletNumber = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: walletNumber })
        }
        else
        if (nam === "serialNumber") {
            let serialNumber = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: serialNumber })
        }
        else
        if (nam === "issueDate") {
            let issueDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: issueDate })
        }
        else
        if (nam === "pisNumber") {
            let pisNumber = mPIS(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pisNumber })
        }
        else
        if (nam === "voterRegistration") {
            let voterRegistration = mVoterRegistration(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: voterRegistration })
        }
        else
        if (nam === "electoralZone") {
            let electoralZone = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: electoralZone })
        }
        else
        if (nam === "pollingStation") {
            let pollingStation = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pollingStation })
        }
        else
        if (nam === "rg") {
            let rg = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rg })
        }
        else
        if (nam === "rgExpeditionDate") {
            let rgExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rgExpeditionDate })
        }
        else
        if (nam === "reservationCardNumber") {
            let reservationCardNumber = mReservationCardNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardNumber })
        }
        else
        if (nam === "reservationCardCategory") {
            let reservationCardCategory = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardCategory })
        }
        else
        if (nam === "reservationCardExpeditionDate") {
            let reservationCardExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardExpeditionDate })
        }
        else
        if (nam === "cnh") {
            let cnh = mCNH(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnh })
        }
        else
        if (nam === "cnhExpeditionDate") {
            let cnhExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnhExpeditionDate })
        }
        else {
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

   };

   // eslint-disable-next-line no-undef
   async function getAddress(zipcode) {
       zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
       if (zipcode.length >= 8) {
           //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
           const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

           if (response.data !== null) {
            setCollaboratorUpdate({
                   ...collaboratorUpdate,
                   zipcode: zipcode,
                   address: response.data.logradouro,
                   addressNeighborhood : response.data.bairro,
                   addressCity: response.data.localidade,
                   addressState: response.data.uf,
               })
           }
       }
   }

   // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ ufEstados, setUfEstados ] = useState([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
      async function loadufestados() {
          await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
          .then(response => {
              setUfEstados(response.data);
          }).catch(() => {});
      }
      loadufestados();
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [ loadContinue, setLoadContinue ] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleCPF, setVisibleCPF] = useState(false);
  function EditCpfVisible() {
      setCollaboratorUpdate([]);
      setVisibleCPF(!visibleCPF);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleWorkCard, setVisibleWorkCard] = useState(false);
  function EditWorkCardVisible() {
      setCollaboratorUpdate([]);
      setVisibleWorkCard(!visibleWorkCard);
      setSelectWalletUF(false)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleRG, setVisibleRG] = useState(false);
  function EditRGVisible() {
      setCollaboratorUpdate([]);
      setVisibleRG(!visibleRG);
      setSelectRGufOrgan(false)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleVoterTitle, setVisibleVoterTitle] = useState(false);
  function EditVoterTitleVisible() {
      setCollaboratorUpdate([]);
      setVisibleVoterTitle(!visibleVoterTitle);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleReservist, setVisibleReservist] = useState(false);
  function EditReservistVisible() {
      setCollaboratorUpdate([]);
      setVisibleReservist(!visibleReservist);
      setSelectUfReservationCard(false)
      setSelectCityReservationCard(false)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [visibleCNH, setVisibleCNH] = useState(false);
  function EditCNHVisible() {
      setCollaboratorUpdate([]);
      setVisibleCNH(!visibleCNH);
      setSelectCnhCategory(false)
      setSelectUfCNH(false)
  }

  async function save(event) {
      event.preventDefault();
      //alert(collaborator.dateOfBirth)
        if(
            (collaboratorUpdate === undefined || collaboratorUpdate.length === 0) &&
            (
                (fileCPFSend && fileCPFSend === true)  ||
                (fileWorkCardSend && fileWorkCardSend === true)  ||
                (fileRGSend && fileRGSend === true)  ||
                (fileVoterTitleSend && fileVoterTitleSend === true)  ||
                (fileReservistSend && fileReservistSend === true)||
                (fileCNHSend && fileCNHSend === true)
            )
        ){
                swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
                setfileCPFSend(false)
                setVisibleCPF(false);
                setfileWorkCardSend(false)
                setVisibleWorkCard(false);
                setfileRGSend(false)
                setVisibleRG(false);
                setfileVoterTitleSend(false)
                setVisibleVoterTitle(false);
                setfileReservistSend(false)
                setVisibleReservist(false);
                setfileCNHSend(false)
                setVisibleCNH(false);
        }
      else {

        if(
            collaboratorUpdate.cpf                           === "" ||
            collaboratorUpdate.issueDate                     === "" ||
            collaboratorUpdate.pisNumber                     === "" ||
            collaboratorUpdate.serialNumber                  === "" ||
            collaboratorUpdate.walletNumber                  === "" ||
            collaboratorUpdate.electoralZone                 === "" ||
            collaboratorUpdate.pollingStation                === "" ||
            collaboratorUpdate.voterRegistration             === "" ||
            collaboratorUpdate.rg                            === "" ||
            collaboratorUpdate.rgExpeditionDate              === "" ||
            collaboratorUpdate.reservationCardCategory       === "" ||
            collaboratorUpdate.reservationCardExpeditionDate === "" ||
            collaboratorUpdate.reservationCardNumber         === "" ||
            collaboratorUpdate.reservationCardSeries         === "" ||
            collaboratorUpdate.cnh                           === "" ||
            collaboratorUpdate.cnhExpeditionDate             === ""
        ) {
            swal({ icon: "error", title: "Erro!", text: 'Nenhum campo pode ser salvo em branco!' });
        } else {
            // alert("entrou")
            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if(collaboratorUpdate.cpf !== undefined) {
                formData.append('cpf', collaboratorUpdate.cpf);
            }
            if(collaboratorUpdate.foreign !== undefined) {
                formData.append('foreign', collaboratorUpdate.foreign);
            }
            if(collaboratorUpdate.walletNumber !== undefined) {
                formData.append('walletNumber', collaboratorUpdate.walletNumber);
            }
            if(collaboratorUpdate.serialNumber !== undefined) {
                formData.append('serialNumber', collaboratorUpdate.serialNumber);
            }
            if(collaboratorUpdate.issueDate !== undefined) {
                formData.append('issueDate', collaboratorUpdate.issueDate);
            }
            if(collaboratorUpdate.walletUF !== undefined) {
                formData.append('walletUF', collaboratorUpdate.walletUF);
            }
            if(collaboratorUpdate.pisNumber !== undefined) {
                formData.append('pisNumber', collaboratorUpdate.pisNumber);
            }
            if(collaboratorUpdate.rg !== undefined) {
                formData.append('rg', collaboratorUpdate.rg);
            }
            if(collaboratorUpdate.rgExpeditionDate !== undefined) {
                formData.append('rgExpeditionDate', collaboratorUpdate.rgExpeditionDate);
            }
            if(collaboratorUpdate.rgOrgan !== undefined) {
                formData.append('rgOrgan', collaboratorUpdate.rgOrgan);
            }
            if(collaboratorUpdate.ufOrgan !== undefined) {
                formData.append('ufOrgan', collaboratorUpdate.ufOrgan);
            }
            if(collaboratorUpdate.voterRegistration !== undefined) {
                formData.append('voterRegistration', collaboratorUpdate.voterRegistration);
            }
            if(collaboratorUpdate.electoralZone !== undefined) {
                formData.append('electoralZone', collaboratorUpdate.electoralZone);
            }
            if(collaboratorUpdate.pollingStation !== undefined) {
                formData.append('pollingStation', collaboratorUpdate.pollingStation);
            }
            if(collaboratorUpdate.reservationCardNumber !== undefined) {
                formData.append('reservationCardNumber', collaboratorUpdate.reservationCardNumber);
            }
            if(collaboratorUpdate.reservationCardSeries !== undefined) {
                formData.append('reservationCardSeries', collaboratorUpdate.reservationCardSeries);
            }
            if(collaboratorUpdate.reservationCardCategory !== undefined) {
                formData.append('reservationCardCategory', collaboratorUpdate.reservationCardCategory);
            }
            if(collaboratorUpdate.reservationCardExpeditionDate !== undefined) {
                formData.append('reservationCardExpeditionDate', collaboratorUpdate.reservationCardExpeditionDate);
            }
            if(collaboratorUpdate.ufReservationCard !== undefined) {
                formData.append('ufReservationCard', collaboratorUpdate.ufReservationCard);
            }
            if(collaboratorUpdate.cityReservationCard !== undefined) {
                formData.append('cityReservationCard', collaboratorUpdate.cityReservationCard);
            }
            if(collaboratorUpdate.cnh !== undefined) {
                formData.append('cnh', collaboratorUpdate.cnh);
            }
            if(collaboratorUpdate.cnhCategory !== undefined) {
                formData.append('cnhCategory', collaboratorUpdate.cnhCategory);
            }
            if(collaboratorUpdate.cnhExpeditionDate !== undefined) {
                formData.append('cnhExpeditionDate', collaboratorUpdate.cnhExpeditionDate);
            }
            if(collaboratorUpdate.ufCNH !== undefined) {
                formData.append('ufCNH', collaboratorUpdate.ufCNH);
            }
            formData.append('path', path);
            setLoadContinue(true)
            await api.put(`/collaborator-access/${id}`, formData, config)
            .then(() => {
                setLoadContinue(false)


                setVisibleCPF(false);
                setVisibleWorkCard(false);
                setVisibleRG(false);
                setVisibleVoterTitle(false);
                setVisibleReservist(false);
                setVisibleCNH(false);

                loadcollaborator(id);
                swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadContinue(false)
            });
        }

      }

  }
        return (
            <>
               <Layout
               path={props.match.path}
               >

                <div className="profile-user">
                    <InforProfile collaborator={collaborator} id={id}  setCollaborator={setCollaborator}/>
                    <SubHeader
                    id={id}
                    path={props.match.path}
                    collaborator={collaborator}/>
                    <Documents
                        id={id}
                        loadPage={loadPage}
                        collaboratorUpdate={collaboratorUpdate}
                        collaborator={collaborator}
                        onCollaboratorInputChange={myChangeHandler}
                        ufEstados={ufEstados}
                        ufDistrito={collaborator.bornState}
                        ufCity={collaborator.bornCity}

                        ufReservationCard={collaboratorUpdate && collaboratorUpdate.ufReservationCard ? collaboratorUpdate.ufReservationCard : ''}

                        OnSubmit={save}
                        loadContinue={loadContinue}
                        EditCpfVisible={EditCpfVisible}
                        visibleCPF={visibleCPF}
                        EditWorkCardVisible={EditWorkCardVisible}
                        visibleWorkCard={visibleWorkCard}
                        EditRGVisible={EditRGVisible}
                        visibleRG={visibleRG}
                        EditVoterTitleVisible={EditVoterTitleVisible}
                        visibleVoterTitle={visibleVoterTitle}
                        EditReservistVisible={EditReservistVisible}
                        visibleReservist={visibleReservist}
                        EditCNHVisible={EditCNHVisible}
                        visibleCNH={visibleCNH}
                        filesSavedCPF={filesSavedCPF}
                        loadCPF={loadCPF}
                        handleDropCPF={handleDropCPF}
                        deleteFileCPF={deleteFileCPF}
                        filesSavedWorkCard={filesSavedWorkCard}
                        loadWorkCard={loadWorkCard}
                        handleDropWorkCard={handleDropWorkCard}
                        deleteFileWorkCard={deleteFileWorkCard}
                        filesSavedVoterTitle={filesSavedVoterTitle}
                        loadVoterTitle={loadVoterTitle}
                        deleteFileVoterTitle={deleteFileVoterTitle}
                        handleDropVoterTitle={handleDropVoterTitle}
                        filesSavedRG={filesSavedRG}
                        loadRG={loadRG}
                        deleteFileRG={deleteFileRG}
                        handleDropRG={handleDropRG}
                        filesSavedCNH={filesSavedCNH}
                        loadCNH={loadCNH}
                        deleteFileCNH={deleteFileCNH}
                        handleDropCNH={handleDropCNH}
                        filesSavedReservist={filesSavedReservist}
                        loadReservist={loadReservist}
                        deleteFileReservist={deleteFileReservist}
                        handleDropReservist={handleDropReservist}

                        setCollaboratorUpdate={setCollaboratorUpdate}

                        setSelectWalletUF={setSelectWalletUF}
                        selectWalletUF={selectWalletUF}

                        setSelectRGufOrgan={setSelectRGufOrgan}
                        selectRGufOrgan={selectRGufOrgan}

                        selectCnhCategory={selectCnhCategory}
                        setSelectCnhCategory={setSelectCnhCategory}
                        selectUfCNH={selectUfCNH}
                        setSelectUfCNH={setSelectUfCNH}


                        selectUfReservationCard={selectUfReservationCard}
                        setSelectUfReservationCard={setSelectUfReservationCard}
                        selectCityReservationCard={selectCityReservationCard}
                        setSelectCityReservationCard={setSelectCityReservationCard}
                    />
                </div>
                </Layout>
            </>
        );
}

