import React from 'react';
import './styles.scss';
import Header from '../../../components/User/registerDoc/header';
import Sucess from '../../../assets/images/success.svg';

export default function RegisterDoc(props) {

    return (
            <>
            <div className='pagina-Token'>
                <Header/>
                <div className="dados-enviados">
                    <img src={Sucess} alt="Icone de sucesso" />
                    <h2>Dados enviados com sucesso!</h2>
                    <p>O seu gestor de RH recebeu seus dados e irá prosseguir com o processo de admissão.</p>
                    <p>Se tiver alguma dúvida, contate o seu gestor.</p>
                </div>
                <div style={{height: "38px"}}></div>
            </div>
            </>
    );
}

