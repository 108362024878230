import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
import Carregamento from '../../../../assets/images/carregando.gif';
import IconVisa from '../../../../assets/images/icon-visa.svg';
import IconError from '../../../../assets/images/error.svg';
import { amountPlan, momentDurationTotalDayTrial } from '../../../../services/utils';

// import IconVisa from '../../../assets/images/icon-visa.svg';
import IconMastercard from '../../../../assets/images/icon-mastercard.svg';
// import IconAmericanExpress from '../../../assets/images/icon-amex.svg';
// import IconJCB from '../../../assets/images/icon-jbc.svg';
// import IconDiscover from '../../../assets/images/icon-discover.svg';
// import IconDiners from '../../../assets/images/icon-diners.svg';
import ModalGeral from '../../../../components/modal/modal-geral';

export default function pageSystemConfigFlatDetail({
    props,
    path,
    planCompany,
    signatureCompany,
    loadSignatureCompany,

    transactionsCompany,
    loadTransactionsCompany,

    dataFormatadaParaProximaCobranca,
    dataFormatadaParaDataLimite,
    changePageAddEditPaymentMethod,
    dateFormatedToSignature,
    // statusDate,
    openPaymentManage,
    ExchangePlan,

    cancelPlanCompany,
    loadButtonSavePlan
}) {

    const currentDate    = new Date();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalCancelaAssinatura, setIsModalCancelaAssinatura ] = useState(false);


    return (
        <>

            <ModalGeral onClose={()=> setIsModalCancelaAssinatura(false)} isModalCancelaAssinatura={isModalCancelaAssinatura}>
                <div className="conteudo-cancelar-assinatura">
                    <img src={IconError} alt="Cancelar assinatura" className="ilustration"/>
                    <h2  className="titulo">Tem certeza que deseja <br/> cancelar sua assinatura?</h2>
                    <p  className="infor">Seu plano atual continuará vigente<br/>até o dia <span>
                        {dataFormatadaParaDataLimite(signatureCompany.current_period_end)}
                    </span></p>
                    <button onClick={()=>cancelPlanCompany(signatureCompany.id, signatureCompany.current_period_end)} className={loadButtonSavePlan ? "btnRed load" : "btnRed"}>Cancelar assinatura</button>
                   
                </div>
            </ModalGeral>

            <div className='detalhe-plano'>

                {
                    loadSignatureCompany ?
                    <>
                    <div className="carregamento">
                        <img alt="" src={Carregamento} />
                    </div>
                    <br />
                    <br />
                    </>
                    :
                    signatureCompany && signatureCompany?.status === 'canceled' ?
                    <>
                    <h5 className="sub-titulo">Detalhes do plano</h5>
                    <div
                    className={planCompany?.planId?.type === 'free' || signatureCompany?.status === 'canceled' ? "infor-plano free" : "infor-plano" }
                    >
                        <div className='nome-valor'>
                        <h4 className="nome-plano">
                            {
                                planCompany?.planId?.type === 'trial' ?
                                'TRIAL'
                                :
                                planCompany?.planId?.name === 'STARTER' ?
                                'STARTER'
                                :
                                planCompany?.planId?.name === 'BASIC' ?
                                'BASIC'
                                :
                                planCompany?.planId?.name === 'PROFESSIONAL' ?
                                'PROFESSIONAL'
                                :
                                planCompany?.planId?.name === 'ENTERPRISE' ?
                                'ENTERPRISE'
                                :
                                planCompany?.planId?.name === '199.90' ?
                                '199.90'
                                :
                                planCompany?.planId?.name === '299.90' ?
                                '299.90'
                                :
                                planCompany?.planId?.name === 'People View 399' ?
                                '399.90'
                                :
                                planCompany?.planId?.type === 'free' ?
                                'FREE'
                                :
                                'Básico'
                            }
                        </h4>

                        <p className="valor-plano">
                        Sua empresa está com sua assintura cancelada e poderá usar o sistema até dia  {dataFormatadaParaDataLimite(signatureCompany.current_period_end)}
                        </p>

                        </div>
                        {
                            planCompany?.planId?.type === 'trial' ||
                            planCompany?.planId?.type === 'free'  ||
                            signatureCompany?.status  === 'canceled' ?
                            ''
                            :
                            <>
                            <div className="tipo-cartao-data">
                            <p className="cartao">
                            <img src={
                                signatureCompany?.card_brand === 'mastercard'?
                                IconMastercard
                                :
                                signatureCompany?.card_brand === 'visa'?
                                IconVisa
                                :
                                ''
                                } alt="Numero cartao" />•••• •••• •••• {signatureCompany?.card_last_digits}
                                </p>
                            <p className="data">Sua próxima cobrança será {dataFormatadaParaProximaCobranca(signatureCompany?.current_period_end)}</p>
                            </div>
                            </>
                        }

                        <div className="links">
                            {
                                planCompany?.planId?.type === 'free' || signatureCompany?.status === 'canceled' ?
                                ''
                                :
                                <>
                                <button className="btn-gerenciar" onClick={openPaymentManage}>Gerenciar pagamento</button>
                                <button  className="btn-gerenciar" onClick={ExchangePlan}>{planCompany?.planId?.type === 'trial' ? 'Contratar plano' : 'Trocar plano'}</button>
                                </>
                            }
                        </div>
                    </div>
                    </>
                    :
                    planCompany && planCompany?.status === 'active' ?
                    <>
                    <h5 className="sub-titulo">Detalhes do plano</h5>
                    <div
                    className={planCompany?.planId?.type === 'free' ? "infor-plano free" : "infor-plano" }
                    >
                        <div className='nome-valor'>
                        <h4 className="nome-plano">
                            {
                                planCompany?.planId?.type === 'trial' ?
                                'TRIAL'
                                :
                                planCompany?.planId?.name === 'STARTER' ?
                                'STARTER'
                                :
                                planCompany?.planId?.name === 'BASIC' ?
                                'BASIC'
                                :
                                planCompany?.planId?.name === 'PROFESSIONAL' ?
                                'PROFESSIONAL'
                                :
                                planCompany?.planId?.name === 'ENTERPRISE' ?
                                'ENTERPRISE'
                                :
                                planCompany?.planId?.name === '199.90' ?
                                '199.90'
                                :
                                planCompany?.planId?.name === '299.90' ?
                                '299.90'
                                :
                                planCompany?.planId?.name === 'People View 399' ?
                                '399.90'
                                :
                                planCompany?.planId?.type === 'free' ?
                                'FREE'
                                :
                                'Básico'
                            }
                        </h4>

                        <p className="valor-plano">
                            {
                                planCompany?.planId?.type === 'trial' ?
                                <>
                                Seu plano trial expira em
                                {momentDurationTotalDayTrial(currentDate,planCompany?.expirationDate)}
                                {/* {statusDate} */}
                                </>
                                :
                                planCompany?.planId?.type === 'free' ?
                                'Sua empresa é contemplada com a versão FREE do People View!'
                                :
                                <>
                                R$ {amountPlan(String(signatureCompany?.plan?.amount))}
                                <span>
                                    mensal
                                </span>
                                </>
                            }
                        </p>

                        </div>
                        {
                            planCompany?.planId?.type === 'trial' || planCompany?.planId?.type === 'free' ?
                            ''
                            :
                            <>
                            <div className="tipo-cartao-data">
                            <p className="cartao">
                            <img src={
                                signatureCompany?.card_brand === 'mastercard'?
                                IconMastercard
                                :
                                signatureCompany?.card_brand === 'visa'?
                                IconVisa
                                :
                                ''
                                } alt="Numero cartao" />•••• •••• •••• {signatureCompany?.card_last_digits}
                                </p>
                            <p className="data">Sua próxima cobrança será {dataFormatadaParaProximaCobranca(signatureCompany?.current_period_end)}</p>
                            </div>
                            </>
                        }

                        <div className="links">
                            {
                                planCompany?.planId?.type === 'free' ?
                                ''
                                :
                                <>

                                <button className="btn-gerenciar" onClick={openPaymentManage}>Gerenciar pagamento</button>
                                <button  className="btn-gerenciar" onClick={ExchangePlan}>{planCompany?.planId?.type === 'trial' ? 'Contratar plano' : 'Trocar plano'}</button>
                                </>
                            }
                        </div>
                    </div>
                    </>
                    :""
                 }

                    <div
                    className={planCompany?.planId?.type === 'free' ? "historico-cobranca free" : "historico-cobranca" }>
                        <h5 className="titulo-historico">{loadSignatureCompany ? '' : 'Histórico de cobrança' } {loadSignatureCompany ? '' : signatureCompany?.status === 'canceled' ? '' : planCompany?.planId?.type === 'free' ? '' : <button onClick={() => setIsModalCancelaAssinatura(true)}>Cancelar assinatura</button>}</h5>

                        {
                            // loadSignatureCompany ?
                            // <>
                            // <div className="carregamento">
                            //     <img alt="" src={Carregamento} />
                            // </div>

                            // <br />
                            // <br />
                            // </>
                            // :
                            // ''
                        }

                        {
                            loadSignatureCompany ?
                            ''
                            :
                            transactionsCompany === null || transactionsCompany.length === 0 ?
                            <div className="nenhum-historico-cobranca">
                                <p>Nenhum histórico de cobrança até o momento.</p>
                            </div>
                            :
                            <>
                            <br />
                                <br />
                                <div className="tabela-historico">
                                    <ul className="cabeca">
                                        <li>Cobrança</li>
                                        <li className='vencimento'>Vencimento</li>
                                        <li className='valor'>Valor</li>
                                        <li className='status'>Status</li>
                                        <li>Pagamento</li>
                                    </ul>
                                    <ul className="corpo">
                                        {
                                            loadTransactionsCompany ?
                                            <div className="carregamento">
                                                <img alt="" src={Carregamento} />
                                            </div>
                                            :
                                            transactionsCompany.map((transaction, index)=>(
                                                <>
                                                <li key={index}>
                                                    <div>#{transaction?.id}</div>
                                                    <div  className='vencimento'>{dateFormatedToSignature(transaction?.date_created)}</div>
                                                    <div className='valor'>R$ {amountPlan(String(transaction?.amount))}</div>
                                                    <div className={transaction?.status === 'paid' ? 'pago status' : 'aberto status'}>
                                                        {transaction?.status === 'paid' ? 'Pago' : 'Pagamento pendente'}

                                                    {/* {
                                                        'Pago' 'Pagamento pendente' 'Não paga' 'Cancelado' 'Recusado'  'Em aberto'
                                                    } */}

                                                    </div>

                                                    <div className="comprovante"><a href='/'>Comprovante</a></div>
                                                </li>
                                                </>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className='cancelar-assinatura'>
                                </div>
                            </>
                        }
                    </div>
                       
            </div>
        </>
    );
}
