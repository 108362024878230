import React, { useState, useEffect } from 'react';
import Dropzone from "react-dropzone";
import swal from 'sweetalert';
import Carregando from '../../../../../assets/images/carregando.gif'
import api from '../../../../../services/api';
import { dateFormatedToShutDown } from '../../../../../services/utils'
import { decodeToken } from '../../../../../services/auth';
import { NavLink } from 'react-router-dom'

export default function StepThree({ id }) {

    const decoded = decodeToken();

    const collaborator = id;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDismisslaInterviewFile, setLoadDismisslaInterviewFile ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesDismisslaInterviewFile] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedDismisslaInterviewFile, setFilesSavedDismisslaInterviewFile] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [layoffInterviewFileSend, setLayoffInterviewFileSend] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadcollaboratordismissalinterviewfile(id);
        loadterminationchecklistquestion(id);
        loadterminationchecklistquestionremuneration(id);
        loadterminationchecklistquestiondemotivation(id);
        loadterminationchecklistquestionlossOfPurpose(id);
        loadterminationchecklistquestionlackOfChallenges(id);
        loadterminationchecklistquestionmissingOfFutureVision(id);
        loadterminationchecklistquestionmisManagementOrLeadership(id);
        loadterminationchecklistquestionrelationshipWithManager(id);
        loadterminationchecklistquestionrelationshipWithColleagues(id);
        loadterminationchecklistquestionculturalMisalignment(id);
        loadterminationchecklistquestionbestBenefits(id);
        loadterminationchecklistquestioncareerChange(id);
        loadterminationchecklistquestionchangOfPosition(id);
        loadterminationchecklistquestionpersonalReasons(id);
        loadterminationchecklistquestionothers(id);

        loadterminationchecklistquestionsalaryIncrease(id);
        loadterminationchecklistquestionjobPromotion(id);
        loadterminationchecklistquestionstrengtheningPurpose(id);
        loadterminationchecklistquestionnewChallenges(id);
        loadterminationchecklistquestionhaveAClearFutureVision(id);
        loadterminationchecklistquestionmanagerChange(id);
        loadterminationchecklistquestionmanagementImprovements(id);
        loadterminationchecklistquestioncultureAlignment(id);
        loadterminationchecklistquestionmoreBenefits(id);
        loadterminationchecklistquestionfunctionChange(id);
        loadterminationchecklistquestionfeedbackCulture(id);
        loadterminationchecklistquestionotherImprovements(id);
        loadterminationchecklistquestionanything(id);

        loadterminationchecklistquestiondirection(id);
        loadterminationchecklistquestionrh(id);
        loadterminationchecklistquestionmanagers(id);
        loadterminationchecklistquestionleaders(id);
        loadterminationchecklistquestionclimate(id);
        loadterminationchecklistquestionculture_(id);
        loadterminationchecklistquestionsalaryNeedsToBeImproved(id);
        loadterminationchecklistquestionbenefits(id);
        loadterminationchecklistquestionstructure(id);
        loadterminationchecklistquestionprocess(id);
        loadterminationchecklistquestionopportunities(id);
        loadterminationchecklistquestionoperation(id);
        loadterminationchecklistquestioninternalCommunication(id);
        loadterminationchecklistquestionjourneyModel(id);
        loadterminationchecklistquestionworkModel(id);
        loadterminationchecklistquestionfeedbacks(id);

        loadterminationchecklistquestionWorkClimateAssessment1(id);
        loadterminationchecklistquestionWorkClimateAssessment2(id);
        loadterminationchecklistquestionWorkClimateAssessment3(id);
        loadterminationchecklistquestionWorkClimateAssessment4(id);
        loadterminationchecklistquestionWorkClimateAssessment5(id);

        loadterminationchecklistquestionStructureClimateAssessment1(id);
        loadterminationchecklistquestionStructureClimateAssessment2(id);
        loadterminationchecklistquestionStructureClimateAssessment3(id);
        loadterminationchecklistquestionStructureClimateAssessment4(id);
        loadterminationchecklistquestionStructureClimateAssessment5(id);

        loadterminationchecklistquestionOpportunitiesForGrowth1(id);
        loadterminationchecklistquestionOpportunitiesForGrowth2(id);
        loadterminationchecklistquestionOpportunitiesForGrowth3(id);
        loadterminationchecklistquestionOpportunitiesForGrowth4(id);
        loadterminationchecklistquestionOpportunitiesForGrowth5(id);

        loadterminationchecklistquestionHowWasYourRelationship1(id);
        loadterminationchecklistquestionHowWasYourRelationship2(id);
        loadterminationchecklistquestionHowWasYourRelationship3(id);
        loadterminationchecklistquestionHowWasYourRelationship4(id);
        loadterminationchecklistquestionHowWasYourRelationship5(id);

        loadterminationchecklistquestionRelationshipWithTheTeam1(id);
        loadterminationchecklistquestionRelationshipWithTheTeam2(id);
        loadterminationchecklistquestionRelationshipWithTheTeam3(id);
        loadterminationchecklistquestionRelationshipWithTheTeam4(id);
        loadterminationchecklistquestionRelationshipWithTheTeam5(id);

        loadterminationchecklistquestionYourNoteExperienceInJobs1(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs2(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs3(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs4(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs5(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs6(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs7(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs8(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs9(id);
        loadterminationchecklistquestionYourNoteExperienceInJobs10(id);

        loadcollaboratordismissalinterviewid(id);
        loadcollaboratordismissal(id);



    }, [id]);

    async function loadcollaboratordismissalinterviewfile(id) {

        setLoadDismisslaInterviewFile(true)
        await api.get(`/collaborator-dismissal-interview-file/?id=${id}&category=dismissal-interview-file`)
        .then(response => {

            setLoadDismisslaInterviewFile(false)
            setFilesSavedDismisslaInterviewFile(response.data);

        }).catch(error => {})

    }

    async function handleDropDismisslaInterviewFile(acceptedFiles) {

        setFileNamesDismisslaInterviewFile(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {

            return
        }
        formData.append('file', acceptedFiles[0]);
        //formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'dismissal-interview-file');
        formData.append('collaborator', id);
        setLoadDismisslaInterviewFile(true)
        await api.post("/collaborator-dismissal-interview-file", formData, config)
        .then(response => {
            setLayoffInterviewFileSend(true)
            // async function loadfilessaved() {
            //     //await api.get(`/collaborator-dismissal-interview-file/?id=${id}&dismissal-interview-file`)
            //     await api.get(`/collaborator-dismissal-interview-file/?id=${id}&category=dismissal-interview-file`)
            //     .then(response => {
            //         setLoadDismisslaInterviewFile(false)
            //         setFilesSavedDismisslaInterviewFile(response.data);
            //     })
            // }
            // loadfilessaved();
            loadcollaboratordismissalinterviewfile(id);
            //swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            //console.log(error.response.data)
        });

    }

    async function deleteFileDismisslaInterviewFile(filesaved) {
        //console.log(filesaved)
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoadDismisslaInterviewFile(true)
                await api.delete(`/collaborator-dismissal-interview-file/${filesaved._id}`)
                    .then(() => {
                        setLayoffInterviewFileSend(true)
                        // async function loadfilessaved() {
                        //     setLoadDismisslaInterviewFile(false)
                        //     await api.get(`/collaborator-dismissal-interview-file/?id=${id}&category=dismissal-interview-file`)
                        //     .then(response => {
                        //         //console.log(response.data)
                        //         setFilesSavedDismisslaInterviewFile(response.data);
                        //     })
                        // }
                        // loadfilessaved();
                        loadcollaboratordismissalinterviewfile(id);
                        //swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                    }).catch(() => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                    });
            }
        });
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //addReasonsForTheDismissalRequest

    //receivedBetterProposal
    const [receivedBetterProposal, setChecklistReceivedBetterProposal] = useState([])

    async function loadterminationchecklistquestion(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=receivedBetterProposal&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistReceivedBetterProposal(response.data);

        }).catch(error => {})

    }

     //Remuneration
     const [remuneration, setChecklistRemuneration] = useState([])

    async function loadterminationchecklistquestionremuneration(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=remuneration&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistRemuneration(response.data);

        }).catch(error => {})
    }


    //Demotivation
    const [demotivation, setChecklistDemotivation] = useState([])

    async function loadterminationchecklistquestiondemotivation(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=demotivation&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistDemotivation(response.data);

        }).catch(error => {})

    }


    //LossOfPurpose
    const [lossOfPurpose, setChecklistLossOfPurpose] = useState([])

    async function loadterminationchecklistquestionlossOfPurpose(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=lossOfPurpose&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistLossOfPurpose(response.data);

        }).catch(error => {})

    }

    //lackOfChallenges
    const [lackOfChallenges, setChecklistlackOfChallenges] = useState([])

    async function loadterminationchecklistquestionlackOfChallenges(id) {

    await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=lackOfChallenges&category=ReasonsForTheDismissalRequest`)
    .then(response => {

        setChecklistlackOfChallenges(response.data);

    }).catch(error => {})

    }


    //missingOfFutureVision
    const [missingOfFutureVision, setChecklistMissingOfFutureVision] = useState([])

    async function loadterminationchecklistquestionmissingOfFutureVision(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=missingOfFutureVision&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistMissingOfFutureVision(response.data);

        }).catch(error => {})

    }

    //MisManagementOrLeadership
    const [misManagementOrLeadership, setChecklistMisManagementOrLeadership] = useState([])

    async function loadterminationchecklistquestionmisManagementOrLeadership(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=misManagementOrLeadership&category=ReasonsForTheDismissalRequest`)
        .then(response => {
            setChecklistMisManagementOrLeadership(response.data);
            //console.log(response.data)
        }).catch(error => {})


    }

    //RelationshipWithManager
    const [relationshipWithManager, setChecklistRelationshipWithManager] = useState([])

    async function loadterminationchecklistquestionrelationshipWithManager(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=relationshipWithManager&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistRelationshipWithManager(response.data);

        }).catch(error => {})
    }

    //RelationshipWithManager
    const [relationshipWithColleagues, setChecklistRelationshipWithColleagues] = useState([])

    async function loadterminationchecklistquestionrelationshipWithColleagues(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=relationshipWithColleagues&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistRelationshipWithColleagues(response.data);

        }).catch(error => {})
    }

    //RelationshipWithManager
    const [culturalMisalignment, setChecklistCulturalMisalignment] = useState([])

    async function loadterminationchecklistquestionculturalMisalignment(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=culturalMisalignment&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistCulturalMisalignment(response.data);

        }).catch(error => {})
    }


    //bestBenefits
    const [bestBenefits, setChecklistBestBenefits] = useState([])

    async function loadterminationchecklistquestionbestBenefits(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=bestBenefits&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistBestBenefits(response.data);

        }).catch(error => {})

    }

    //bestBenefits
    const [careerChange, setChecklistCareerChange] = useState([])

    async function loadterminationchecklistquestioncareerChange(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=careerChange&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistCareerChange(response.data);

        }).catch(error => {})

    }


    //changOfPosition
    const [changOfPosition, setChecklistChangOfPosition] = useState([])

    async function loadterminationchecklistquestionchangOfPosition(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=changOfPosition&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistChangOfPosition(response.data);

        }).catch(error => {})

    }

    //personalReasons
    const [personalReasons, setChecklistPersonalReasons] = useState([])

    async function loadterminationchecklistquestionpersonalReasons(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=personalReasons&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistPersonalReasons(response.data);

        }).catch(error => {})

    }

    //others
    const [others, setChecklistOthers] = useState([])

    async function loadterminationchecklistquestionothers(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=others&category=ReasonsForTheDismissalRequest`)
        .then(response => {

            setChecklistOthers(response.data);

        }).catch(error => {})

    }

    //addReasonsForTheDismissalRequest
    async function addReasonsForTheDismissalRequest(event) {
        if(event.target.checked === true) {
            ////alert(event.target.name)
            const name      = event.target.name;
            const category  = "ReasonsForTheDismissalRequest";
            const nameCheck = event.target.name;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, name, category })
            async function loadterminationchecklistatualizado() {

                await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${nameCheck}&category=${category}`)
                .then(response => {
                    if (nameCheck === 'receivedBetterProposal') {
                        setChecklistReceivedBetterProposal(response.data);
                    }
                    if (nameCheck === 'remuneration') {
                        setChecklistRemuneration(response.data);
                    }
                    if (nameCheck === 'demotivation') {
                        setChecklistDemotivation(response.data);
                    }
                    if (nameCheck === 'lossOfPurpose') {
                        setChecklistLossOfPurpose(response.data);
                    }
                    if (nameCheck === 'lackOfChallenges') {
                        setChecklistlackOfChallenges(response.data);
                    }
                    if (nameCheck === 'missingOfFutureVision') {
                        setChecklistMissingOfFutureVision(response.data);
                    }
                    if (nameCheck === 'misManagementOrLeadership') {
                        setChecklistMisManagementOrLeadership(response.data);
                    }
                    if (nameCheck === 'relationshipWithManager') {
                        setChecklistRelationshipWithManager(response.data);
                    }
                    if (nameCheck === 'relationshipWithColleagues') {
                        setChecklistRelationshipWithColleagues(response.data);
                    }
                    if (nameCheck === 'culturalMisalignment') {
                        setChecklistCulturalMisalignment(response.data);
                    }
                    if (nameCheck === 'bestBenefits') {
                        setChecklistBestBenefits(response.data);
                    }
                    if (nameCheck === 'careerChange') {
                        setChecklistCareerChange(response.data);
                    }
                    if (nameCheck === 'changOfPosition') {
                        setChecklistChangOfPosition(response.data);
                    }
                    if (nameCheck === 'personalReasons') {
                        setChecklistPersonalReasons(response.data);
                    }
                    if (nameCheck === 'others') {
                        setChecklistOthers(response.data);
                    }
                }).catch(error => {})
            }
            loadterminationchecklistatualizado();

        } else {
            async function loadterminationchecklist() {
                const name      = event.target.name;
                const category  = "ReasonsForTheDismissalRequest";

                await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const nameCheck = response.data[0].name;

                    async function deletecollaboratordismissalinterviewchecklstquestion() {

                        await api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        .then(response => {})
                        .catch(error => {})

                    }
                    deletecollaboratordismissalinterviewchecklstquestion()


                    async function loadterminationchecklistatualizado() {
                        if (nameCheck === 'receivedBetterProposal') {
                            setChecklistReceivedBetterProposal({});
                        }
                        if (nameCheck === 'remuneration') {
                            setChecklistRemuneration({});
                        }
                        if (nameCheck === 'demotivation') {
                            setChecklistDemotivation({});
                        }
                        if (nameCheck === 'lossOfPurpose') {
                            setChecklistLossOfPurpose({});
                        }
                        if (nameCheck === 'lackOfChallenges') {
                            setChecklistlackOfChallenges({});
                        }
                        if (nameCheck === 'missingOfFutureVision') {
                            setChecklistMissingOfFutureVision({});
                        }
                        if (nameCheck === 'misManagementOrLeadership') {
                            setChecklistMisManagementOrLeadership({});
                        }
                        if (nameCheck === 'relationshipWithManager') {
                            setChecklistRelationshipWithManager({});
                        }
                        if (nameCheck === 'relationshipWithColleagues') {
                            setChecklistRelationshipWithColleagues({});
                        }
                        if (nameCheck === 'culturalMisalignment') {
                            setChecklistCulturalMisalignment({});
                        }
                        if (nameCheck === 'bestBenefits') {
                            setChecklistBestBenefits({});
                        }
                        if (nameCheck === 'careerChange') {
                            setChecklistCareerChange({});
                        }
                        if (nameCheck === 'changOfPosition') {
                            setChecklistChangOfPosition({});
                        }
                        if (nameCheck === 'personalReasons') {
                            setChecklistPersonalReasons({});
                        }
                        if (nameCheck === 'others') {
                            setChecklistOthers({});
                        }
                    }
                    loadterminationchecklistatualizado();

                }).catch(error => {})
            }
            loadterminationchecklist();
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //whatWouldYouDoToImprove

    //salaryIncrease
    const [salaryIncrease, setChecklistSalaryIncrease] = useState([])

    async function loadterminationchecklistquestionsalaryIncrease(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=salaryIncrease&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistSalaryIncrease(response.data);

        }).catch(error => {})

    }

    //jobPromotion
    const [jobPromotion, setChecklistJobPromotion] = useState([])

    async function loadterminationchecklistquestionjobPromotion(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=jobPromotion&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistJobPromotion(response.data);

        }).catch(error => {})
    }

    //strengtheningPurpose
    const [strengtheningPurpose, setChecklistStrengtheningPurpose] = useState([])

    async function loadterminationchecklistquestionstrengtheningPurpose(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=strengtheningPurpose&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistStrengtheningPurpose(response.data);

        }).catch(error => {})
    }

    //newChallenges
    const [newChallenges, setChecklistNewChallenges] = useState([])

    async function loadterminationchecklistquestionnewChallenges(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=newChallenges&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistNewChallenges(response.data);

        }).catch(error => {})

    }

    //haveAClearFutureVision
    const [haveAClearFutureVision, setChecklistHaveAClearFutureVision] = useState([])

    async function loadterminationchecklistquestionhaveAClearFutureVision(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=haveAClearFutureVision&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistHaveAClearFutureVision(response.data);

        }).catch(error => {})

    }

    //managerChange
    const [managerChange, setChecklistManagerChange] = useState([])

    async function loadterminationchecklistquestionmanagerChange(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=managerChange&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistManagerChange(response.data);

        }).catch(error => {})

    }

    //managementImprovements
    const [managementImprovements, setChecklistManagementImprovements] = useState([])

    async function loadterminationchecklistquestionmanagementImprovements(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=managementImprovements&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistManagementImprovements(response.data);

        }).catch(error => {})

    }

    //cultureAlignment
    const [cultureAlignment, setChecklistCultureAlignment] = useState([])

    async function loadterminationchecklistquestioncultureAlignment(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=cultureAlignment&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistCultureAlignment(response.data);

        }).catch(error => {})

    }

    //moreBenefits
    const [moreBenefits, setChecklistMoreBenefits] = useState([])

    async function loadterminationchecklistquestionmoreBenefits(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=moreBenefits&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistMoreBenefits(response.data);

        }).catch(error => {})

    }

    //functionChange
    const [functionChange, setChecklistFunctionChange] = useState([])

    async function loadterminationchecklistquestionfunctionChange(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=functionChange&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistFunctionChange(response.data);

        }).catch(error => {})

    }

    //feedbackCulture
    const [feedbackCulture, setChecklistFeedbackCulture] = useState([])

    async function loadterminationchecklistquestionfeedbackCulture(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=feedbackCulture&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistFeedbackCulture(response.data);

        }).catch(error => {})

    }

    //otherImprovements
    const [otherImprovements, setChecklistOtherImprovements] = useState([])

    async function loadterminationchecklistquestionotherImprovements(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=otherImprovements&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistOtherImprovements(response.data);

        }).catch(error => {})


    }


    //anything
    const [anything, setChecklistAnything] = useState([])

    async function loadterminationchecklistquestionanything(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=anything&category=WhatWouldYouDoToImprove`)
        .then(response => {

            setChecklistAnything(response.data);

        }).catch(error => {})

    }


    //WorkplaceClimateAssessment
    async function whatWouldYouDoToImprove(event) {
        if(event.target.checked === true) {
            ////alert(event.target.name)
            const name      = event.target.name;
            const category  = "WhatWouldYouDoToImprove";
            const nameCheck = event.target.name;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, name, category })
            async function loadterminationchecklistatualizado() {

                await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${nameCheck}&category=${category}`)
                .then(response => {
                    if (nameCheck === 'salaryIncrease') {
                        setChecklistSalaryIncrease(response.data);
                    }
                    if (nameCheck === 'jobPromotion') {
                        setChecklistJobPromotion(response.data);
                    }
                    if (nameCheck === 'strengtheningPurpose') {
                        setChecklistStrengtheningPurpose(response.data);
                    }
                    if (nameCheck === 'newChallenges') {
                        setChecklistNewChallenges(response.data);
                    }
                    if (nameCheck === 'haveAClearFutureVision') {
                        setChecklistHaveAClearFutureVision(response.data);
                    }
                    if (nameCheck === 'managerChange') {
                        setChecklistManagerChange(response.data);
                    }
                    if (nameCheck === 'managementImprovements') {
                        setChecklistManagementImprovements(response.data);
                    }
                    if (nameCheck === 'moreBenefits') {
                        setChecklistMoreBenefits(response.data);
                    }
                    if (nameCheck === 'functionChange') {
                        setChecklistFunctionChange(response.data);
                    }
                    if (nameCheck === 'feedbackCulture') {
                        setChecklistFeedbackCulture(response.data);
                    }
                    if (nameCheck === 'otherImprovements') {
                        setChecklistOtherImprovements(response.data);
                    }
                    if (nameCheck === 'anything') {
                        setChecklistAnything(response.data);
                    }
                    //anything


                }).catch(error => {})
            }
            loadterminationchecklistatualizado();

        } else {
            async function loadterminationchecklist() {
                const name      = event.target.name;
                const category  = "WhatWouldYouDoToImprove";

                await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const nameCheck = response.data[0].name;

                    async function deletecollaboratordismissalinterviewcheckListquestions() {

                        await api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        .then(response => {})
                        .catch(error => {})

                    }
                    deletecollaboratordismissalinterviewcheckListquestions()


                    async function loadterminationchecklistatualizado() {
                        if (nameCheck === 'salaryIncrease') {
                            setChecklistSalaryIncrease({});
                        }
                        if (nameCheck === 'jobPromotion') {
                            setChecklistJobPromotion({});
                        }
                        if (nameCheck === 'strengtheningPurpose') {
                            setChecklistStrengtheningPurpose({});
                        }
                        if (nameCheck === 'newChallenges') {
                            setChecklistNewChallenges({});
                        }
                        if (nameCheck === 'haveAClearFutureVision') {
                            setChecklistHaveAClearFutureVision({});
                        }
                        if (nameCheck === 'managerChange') {
                            setChecklistManagerChange({});
                        }
                        if (nameCheck === 'managementImprovements') {
                            setChecklistManagementImprovements({});
                        }
                        if (nameCheck === 'cultureAlignment') {
                            setChecklistCultureAlignment({});
                        }
                        if (nameCheck === 'moreBenefits') {
                            setChecklistMoreBenefits({});
                        }
                        if (nameCheck === 'functionChange') {
                            setChecklistFunctionChange({});
                        }
                        if (nameCheck === 'feedbackCulture') {
                            setChecklistFeedbackCulture({});
                        }
                        if (nameCheck === 'otherImprovements') {
                            setChecklistOtherImprovements({});
                        }

                        if (nameCheck === 'anything') {
                            setChecklistAnything({});
                        }

                    }
                    loadterminationchecklistatualizado();

                }).catch(error => {})
            }
            loadterminationchecklist();
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //direction
    const [direction, setChecklistDirection] = useState([])

    async function loadterminationchecklistquestiondirection(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=direction&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistDirection(response.data);

        }).catch(error => {})

    }

    //hr
    const [rh, setChecklistRH] = useState([])

    async function loadterminationchecklistquestionrh(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=rh&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistRH(response.data);

        }).catch(error => {})
    }

    //managers
    const [managers, setChecklistManagers] = useState([])

    async function loadterminationchecklistquestionmanagers(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=managers&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistManagers(response.data);

        }).catch(error => {})

    }

    //leaders
    const [leaders, setChecklistLeaders] = useState([])

    async function loadterminationchecklistquestionleaders(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=leaders&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistLeaders(response.data);

        }).catch(error => {})

    }

    //climate
    const [climate, setChecklistClimate] = useState([])

    async function loadterminationchecklistquestionclimate(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=climate&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistClimate(response.data);

        }).catch(error => {})


    }

    //culture
    const [culture, setChecklistCulture] = useState([])



    async function loadterminationchecklistquestionculture_(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=culture&category=WorkplaceClimateAssessment`)
        .then(response => {
            setChecklistCulture(response.data);
            //console.log(response.data)
        }).catch(error => {})
    }

    //salaryNeedsToBeImproved
    const [salaryNeedsToBeImproved, setChecklistSalaryNeedsToBeImproved] = useState([])

    async function loadterminationchecklistquestionsalaryNeedsToBeImproved(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=salaryNeedsToBeImproved&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistSalaryNeedsToBeImproved(response.data);

        }).catch(error => {})

    }


    //benefits
    const [benefits, setChecklistBenefits] = useState([])

    async function loadterminationchecklistquestionbenefits(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=benefits&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistBenefits(response.data);

        }).catch(error => {})

    }

    //structure
    const [structure, setChecklistStructure] = useState([])

    async function loadterminationchecklistquestionstructure(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=structure&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistStructure(response.data);

        }).catch(error => {})

    }

    //process
    const [process, setChecklistProcess] = useState([])

    async function loadterminationchecklistquestionprocess(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=process&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistProcess(response.data);

        }).catch(error => {})


    }

    //opportunities
    const [opportunities, setChecklistOpportunities] = useState([])

    async function loadterminationchecklistquestionopportunities(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=opportunities&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistOpportunities(response.data);

        }).catch(error => {})

    }

    //operation
    const [operation, setChecklistOperation] = useState([])

    async function loadterminationchecklistquestionoperation(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=operation&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistOperation(response.data);

        }).catch(error => {})

    }

    //internalCommunication
    const [internalCommunication, setChecklistInternalCommunication] = useState([])

    async function loadterminationchecklistquestioninternalCommunication(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=internalCommunication&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistInternalCommunication(response.data);

        }).catch(error => {})

    }

    //journeyModel
    const [journeyModel, setChecklistJourneyModel] = useState([])

    async function loadterminationchecklistquestionjourneyModel(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=journeyModel&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistJourneyModel(response.data);

        }).catch(error => {})

    }

    //workModel
    const [workModel, setChecklistWorkModel] = useState([])

    async function loadterminationchecklistquestionworkModel(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=workModel&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistWorkModel(response.data);

        }).catch(error => {})
    }

    //feedbacks
    const [feedbacks, setChecklistFeedbacks] = useState([])

    async function loadterminationchecklistquestionfeedbacks(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=feedbacks&category=WorkplaceClimateAssessment`)
        .then(response => {

            setChecklistFeedbacks(response.data);

        }).catch(error => {})

    }


    //WorkplaceClimateAssessment
    async function workplaceClimateAssessment(event) {
        if(event.target.checked === true) {
            ////alert(event.target.name)
            const name      = event.target.name;
            const category  = "WorkplaceClimateAssessment";
            const nameCheck = event.target.name;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, name, category })
            async function loadterminationchecklistatualizado() {

                await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${nameCheck}&category=${category}`)
                .then(response => {
                    if (nameCheck === 'direction') {
                        setChecklistDirection(response.data);
                    }
                    if (nameCheck === 'rh') {
                        setChecklistRH(response.data);
                    }
                    if (nameCheck === 'managers') {
                        setChecklistManagers(response.data);
                    }
                    if (nameCheck === 'leaders') {
                        setChecklistLeaders(response.data);
                    }
                    if (nameCheck === 'climate') {
                        setChecklistClimate(response.data);
                    }
                    if (nameCheck === 'culture') {
                        setChecklistCulture(response.data);
                    }
                    if (nameCheck === 'salaryNeedsToBeImproved') {
                        setChecklistSalaryNeedsToBeImproved(response.data);
                    }
                    if (nameCheck === 'benefits') {
                        setChecklistBenefits(response.data);
                    }
                    if (nameCheck === 'structure') {
                        setChecklistStructure(response.data);
                    }
                    if (nameCheck === 'process') {
                        setChecklistProcess(response.data);
                    }
                    if (nameCheck === 'opportunities') {
                        setChecklistOpportunities(response.data);
                    }
                    if (nameCheck === 'operation') {
                        setChecklistOperation(response.data);
                    }
                    if (nameCheck === 'internalCommunication') {
                        setChecklistInternalCommunication(response.data);
                    }
                    if (nameCheck === 'journeyModel') {
                        setChecklistJourneyModel(response.data);
                    }
                    if (nameCheck === 'workModel') {
                        setChecklistWorkModel(response.data);
                    }
                    if (nameCheck === 'feedbacks') {
                        setChecklistFeedbacks(response.data);
                    }

                    //feedbacks, setChecklistFeedbacks


                }).catch(error => {})
            }
            loadterminationchecklistatualizado();

        } else {
            async function loadterminationchecklist() {
                const name      = event.target.name;
                const category  = "WorkplaceClimateAssessment";

                await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    console.log(response.data)
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const nameCheck = response.data[0].name;

                    async function deletecollaboratordismissalinterviewcheckListquestions() {

                        await api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        .then(response => {})
                        .catch(error => {})
                    }

                    deletecollaboratordismissalinterviewcheckListquestions()



                    async function loadterminationchecklistatualizado() {
                        if (nameCheck === 'direction') {
                            setChecklistDirection({});
                        }
                        if (nameCheck === 'rh') {
                            setChecklistRH({});
                        }
                        if (nameCheck === 'managers') {
                            setChecklistManagers({});
                        }
                        if (nameCheck === 'leaders') {
                            setChecklistLeaders({});
                        }
                        if (nameCheck === 'climate') {
                            setChecklistClimate({});
                        }
                        if (nameCheck === 'culture') {
                            setChecklistCulture({});
                        }
                        if (nameCheck === 'salaryNeedsToBeImproved') {
                            setChecklistSalaryNeedsToBeImproved({});
                        }
                        if (nameCheck === 'benefits') {
                            setChecklistBenefits({});
                        }
                        if (nameCheck === 'structure') {
                            setChecklistStructure({});
                        }
                        if (nameCheck === 'process') {
                            setChecklistProcess({});
                        }
                        if (nameCheck === 'opportunities') {
                            setChecklistOpportunities({});
                        }
                        if (nameCheck === 'operation') {
                            setChecklistOperation({});
                        }
                        if (nameCheck === 'internalCommunication') {
                            setChecklistInternalCommunication({});
                        }
                        if (nameCheck === 'journeyModel') {
                            setChecklistJourneyModel({});
                        }
                        if (nameCheck === 'workModel') {
                            setChecklistWorkModel({});
                        }
                        if (nameCheck === 'feedbacks') {
                            setChecklistFeedbacks({});
                        }
                    }
                    loadterminationchecklistatualizado();

                }).catch(error => {})
            }
            loadterminationchecklist();
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //WorkClimateAssessment

    // ROTA GET DAS EWSTRELINHAS ==> (WorkClimateAssessment)

    //oneStarclimate
    const [oneStarclimate, setChecklistOneStarclimate] = useState([])

    async function loadterminationchecklistquestionWorkClimateAssessment1(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=WorkClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                console.log('collaborator-dismissal-interview-checkList-questions/')
                console.log(response.data)
                setChecklistOneStarclimate(response.data);

            }



        }).catch(error => {})
    }

    //twoStarclimate
    const [twoStarclimate, setChecklistTwoStarclimate] = useState([])

    async function loadterminationchecklistquestionWorkClimateAssessment2(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=WorkClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistOneStarclimate([{ value: 1 }]);
                setChecklistTwoStarclimate(response.data);

            }


            //console.log(response.data.length)
        }).catch(error => {})
    }

    //threeStarclimate
    const [threeStarclimate, setChecklistThreeStarclimate] = useState([])

    async function loadterminationchecklistquestionWorkClimateAssessment3(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=WorkClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistOneStarclimate([{ value: 1 }]);
                setChecklistTwoStarclimate([{ value: 1 }]);
                setChecklistThreeStarclimate(response.data);

            }

        }).catch(error => {})
    }

    //fourStarclimate
    const [fourStarclimate, setChecklistfourStarclimate] = useState([])

    async function loadterminationchecklistquestionWorkClimateAssessment4(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=WorkClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistOneStarclimate([{ value: 1 }]);
                setChecklistTwoStarclimate([{ value: 1 }]);
                setChecklistThreeStarclimate([{ value: 1 }]);
                setChecklistfourStarclimate(response.data);

            }

        }).catch(error => {})
    }

    //fiveStarclimate
    const [fiveStarclimate, setChecklistFiveStarclimate] = useState([])

    async function loadterminationchecklistquestionWorkClimateAssessment5(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=WorkClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistOneStarclimate([{ value: 1 }]);
                setChecklistTwoStarclimate([{ value: 1 }]);
                setChecklistThreeStarclimate([{ value: 1 }]);
                setChecklistfourStarclimate([{ value: 1 }]);
                setChecklistFiveStarclimate(response.data);

            }



        }).catch(error => {})
    }


    //workClimateAssessment

    async function workClimateAssessment(event) {
        //SAVE
        if(event.target.checked === true) {

            const value      = event.target.value;
            const category  = "WorkClimateAssessment";
            const valueCheck = event.target.value;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
            async function loadterminationchecklistatualizado() {
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
                .then(response => {
                    if (valueCheck === '1') {
                        setChecklistOneStarclimate(response.data);
                    }
                    if (valueCheck === '2') {
                        setChecklistTwoStarclimate(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistOneStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistOneStarclimate([{ value: 1 }]);
                        });


                    }
                    if (valueCheck === '3') {
                        setChecklistThreeStarclimate(response.data);


                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistOneStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistOneStarclimate([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistTwoStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistTwoStarclimate([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '4') {
                        setChecklistfourStarclimate(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistOneStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistOneStarclimate([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistTwoStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistTwoStarclimate([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistThreeStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistThreeStarclimate([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '5') {
                        setChecklistFiveStarclimate(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistOneStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistOneStarclimate([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistTwoStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistTwoStarclimate([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistThreeStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistThreeStarclimate([{ value: 1 }]);
                        });

                        /////////---4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=WorkClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistfourStarclimate([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistfourStarclimate([{ value: 1 }]);
                        });
                    }

                    //setChecklistFiveStarclimate


                })
            }
            loadterminationchecklistatualizado();

        //DELETE
        } else {
            async function loadterminationchecklist() {
                const value      = event.target.value;

                const category  = "WorkClimateAssessment";
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const valueCheck = response.data[0].value;
                    api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                    async function loadterminationchecklistatualizado() {
                        if (valueCheck === 1) {
                            setChecklistOneStarclimate({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistTwoStarclimate({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistThreeStarclimate({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarclimate({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistFiveStarclimate({});
                        }
                        if (valueCheck === 2) {
                            ////alert(valueCheck)
                            setChecklistTwoStarclimate({});

                            /////////----1----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 2 post 1')
                                    const value      = 1;
                                    const category  = "WorkClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    ///////////----1----////////////
                                    setChecklistOneStarclimate([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });


                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistThreeStarclimate({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarclimate({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistFiveStarclimate({});

                        }
                        if (valueCheck === 3) {
                            setChecklistThreeStarclimate({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 3 post 2')
                                    const value      = 2;
                                    const category  = "WorkClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistOneStarclimate([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklistTwoStarclimate([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarclimate({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistFiveStarclimate({});

                        }
                        if (valueCheck === 4) {
                            setChecklistfourStarclimate({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    ////alert('delete 4 post 3')
                                    const value      = 3;
                                    const category  = "WorkClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistOneStarclimate([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklistTwoStarclimate([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistThreeStarclimate([{ value: 1 }]);

                                } else {

                                }
                            })
                            .catch(error => {

                            });

                             /////////----5----////////////
                             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                             .then(response => {
                                 const idCheckTerminatorCollaborator = response.data[0]._id;
                                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                             })
                             .catch(error => {
                                 //console.log(error)
                             });
                             setChecklistFiveStarclimate({});
                        }
                        if (valueCheck === 5) {
                            setChecklistFiveStarclimate({});


                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 5 post 4')
                                    const value      = 4;
                                    const category  = "WorkClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistOneStarclimate([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklistTwoStarclimate([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistThreeStarclimate([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistfourStarclimate([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });
                        }

                        //setChecklistFiveStarclimate

                    }
                    loadterminationchecklistatualizado();

                })
                .catch(error => {
                    ////alert(value)
                    if (value === '1') {
                        setChecklistOneStarclimate({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistTwoStarclimate({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistThreeStarclimate({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarclimate({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistFiveStarclimate({});
                    }
                    if (value === '2') {
                        ////alert(value)
                        setChecklistTwoStarclimate({});

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 2 post 1')
                                const value      = 1;
                                const category  = "WorkClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                ///////////----1----////////////
                                setChecklistOneStarclimate([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistThreeStarclimate({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarclimate({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistFiveStarclimate({});

                    }
                    if (value === '3') {
                        setChecklistThreeStarclimate({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 3 post 2')
                                const value      = 2;
                                const category  = "WorkClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistOneStarclimate([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklistTwoStarclimate([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarclimate({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistFiveStarclimate({});

                    }
                    if (value === '4') {
                        setChecklistfourStarclimate({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                ////alert('delete 4 post 3')
                                const value      = 3;
                                const category  = "WorkClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistOneStarclimate([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklistTwoStarclimate([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistThreeStarclimate([{ value: 1 }]);

                            } else {

                            }
                        })
                        .catch(error => {

                        });

                         /////////----5----////////////
                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                         .then(response => {
                             const idCheckTerminatorCollaborator = response.data[0]._id;
                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                         })
                         .catch(error => {
                             //console.log(error)
                         });
                         setChecklistFiveStarclimate({});
                    }
                    if (value === '5') {
                        setChecklistFiveStarclimate({});


                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 5 post 4')
                                const value      = 4;
                                const category  = "WorkClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistOneStarclimate([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklistTwoStarclimate([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistThreeStarclimate([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarclimate([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });
                    }

                });
            }
            loadterminationchecklist();
        }
    }


    // ROTA GET DAS ESTRELINHAS ==> (StructureClimateAssessment)

    //oneStarStructure
    const [oneStarStructure, setChecklistoneStarStructure] = useState([])

    useEffect(()=>{


    },[id])

    async function loadterminationchecklistquestionStructureClimateAssessment1(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=StructureClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarStructure(response.data);

            }

        }).catch(error => {})
    }

    //twoStarStructure
    const [twoStarStructure, setChecklisttwoStarStructure] = useState([])

    async function loadterminationchecklistquestionStructureClimateAssessment2(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=StructureClimateAssessment`)
         .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarStructure([{ value: 1 }]);
                setChecklisttwoStarStructure(response.data);

            }


         }).catch(error => {})
     }

    //threeStarStructure
    const [threeStarStructure, setChecklistthreeStarStructure] = useState([])

    async function loadterminationchecklistquestionStructureClimateAssessment3(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=StructureClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarStructure([{ value: 1 }]);
                setChecklisttwoStarStructure([{ value: 1 }]);
                setChecklistthreeStarStructure(response.data)

            }

        }).catch(error => {})
    }

    //fourStarStructure
    const [fourStarStructure, setChecklistfourStarStructure] = useState([])

    async function loadterminationchecklistquestionStructureClimateAssessment4(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=StructureClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarStructure([{ value: 1 }]);
                setChecklisttwoStarStructure([{ value: 1 }]);
                setChecklistthreeStarStructure([{ value: 1 }]);
                setChecklistfourStarStructure(response.data);

            }

        }).catch(error => {})
    }

    //fiveStarStructure
    const [fiveStarStructure, setChecklistfiveStarStructure] = useState([])

    async function loadterminationchecklistquestionStructureClimateAssessment5(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=StructureClimateAssessment`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarStructure([{ value: 1 }]);
                setChecklisttwoStarStructure([{ value: 1 }]);
                setChecklistthreeStarStructure([{ value: 1 }]);
                setChecklistfourStarStructure([{ value: 1 }]);
                setChecklistfiveStarStructure(response.data);

            }


        }).catch(error => {})
    }


    //structureClimateAssessment

    async function structureClimateAssessment(event) {
        //SAVE
        if(event.target.checked === true) {
            const value      = event.target.value;
            const category  = "StructureClimateAssessment";
            const valueCheck = event.target.value;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
            async function loadterminationchecklistatualizado() {
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
                .then(response => {
                    if (valueCheck === '1') {
                        setChecklistoneStarStructure(response.data);
                    }
                    if (valueCheck === '2') {
                        setChecklisttwoStarStructure(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarStructure([{ value: 1 }]);
                        });


                    }
                    if (valueCheck === '3') {
                        setChecklistthreeStarStructure(response.data);


                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarStructure([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarStructure([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '4') {
                        setChecklistfourStarStructure(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarStructure([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarStructure([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarStructure([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '5') {
                        setChecklistfiveStarStructure(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarStructure([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarStructure([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarStructure([{ value: 1 }]);
                        });

                        /////////---4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=StructureClimateAssessment`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistfourStarStructure([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistfourStarStructure([{ value: 1 }]);
                        });
                    }

                    //setChecklistfiveStarStructure


                })
            }
            loadterminationchecklistatualizado();
        //DELETE
        } else {
            async function loadterminationchecklist() {
                const value      = event.target.value;

                const category  = "StructureClimateAssessment";
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const valueCheck = response.data[0].value;
                    api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                    async function loadterminationchecklistatualizado() {
                        if (valueCheck === 1) {
                            setChecklistoneStarStructure({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisttwoStarStructure({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarStructure({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarStructure({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarStructure({});
                        }
                        if (valueCheck === 2) {
                            ////alert(valueCheck)
                            setChecklisttwoStarStructure({});

                            /////////----1----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 2 post 1')
                                    const value      = 1;
                                    const category  = "StructureClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    ///////////----1----////////////
                                    setChecklistoneStarStructure([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });


                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarStructure({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarStructure({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarStructure({});

                        }
                        if (valueCheck === 3) {
                            setChecklistthreeStarStructure({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 3 post 2')
                                    const value      = 2;
                                    const category  = "StructureClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarStructure([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarStructure([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarStructure({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarStructure({});

                        }
                        if (valueCheck === 4) {
                            setChecklistfourStarStructure({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    ////alert('delete 4 post 3')
                                    const value      = 3;
                                    const category  = "StructureClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarStructure([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarStructure([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarStructure([{ value: 1 }]);

                                } else {

                                }
                            })
                            .catch(error => {

                            });

                             /////////----5----////////////
                             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                             .then(response => {
                                 const idCheckTerminatorCollaborator = response.data[0]._id;
                                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                             })
                             .catch(error => {
                                 //console.log(error)
                             });
                             setChecklistfiveStarStructure({});
                        }
                        if (valueCheck === 5) {
                            setChecklistfiveStarStructure({});


                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 5 post 4')
                                    const value      = 4;
                                    const category  = "StructureClimateAssessment";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarStructure([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarStructure([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarStructure([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistfourStarStructure([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });
                        }

                        //setChecklistfiveStarStructure

                    }
                    loadterminationchecklistatualizado();

                })
                .catch(error => {
                    ////alert(value)
                    if (value === '1') {
                        setChecklistoneStarStructure({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttwoStarStructure({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarStructure({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarStructure({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarStructure({});
                    }
                    if (value === '2') {
                        ////alert(value)
                        setChecklisttwoStarStructure({});

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 2 post 1')
                                const value      = 1;
                                const category  = "StructureClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                ///////////----1----////////////
                                setChecklistoneStarStructure([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarStructure({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarStructure({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarStructure({});

                    }
                    if (value === '3') {
                        setChecklistthreeStarStructure({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 3 post 2')
                                const value      = 2;
                                const category  = "StructureClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarStructure([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarStructure([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarStructure({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarStructure({});

                    }
                    if (value === '4') {
                        setChecklistfourStarStructure({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                ////alert('delete 4 post 3')
                                const value      = 3;
                                const category  = "StructureClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarStructure([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarStructure([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarStructure([{ value: 1 }]);

                            } else {

                            }
                        })
                        .catch(error => {

                        });

                         /////////----5----////////////
                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                         .then(response => {
                             const idCheckTerminatorCollaborator = response.data[0]._id;
                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                         })
                         .catch(error => {
                             //console.log(error)
                         });
                         setChecklistfiveStarStructure({});
                    }
                    if (value === '5') {
                        setChecklistfiveStarStructure({});


                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 5 post 4')
                                const value      = 4;
                                const category  = "StructureClimateAssessment";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarStructure([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarStructure([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarStructure([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarStructure([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });
                    }

                });
            }
            loadterminationchecklist();
        }
    }


    // ROTA GET DAS ESTRELINHAS ==> (OpportunitiesForGrowth)

    //oneStarOpportunity
    const [oneStarOpportunity, setChecklistoneStarOpportunity] = useState([])

    async function loadterminationchecklistquestionOpportunitiesForGrowth1(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=OpportunitiesForGrowth`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarOpportunity(response.data);

            }

        }).catch(error => {})
    }

    //twoStarOpportunity
    const [twoStarOpportunity, setChecklisttwoStarOpportunity] = useState([])

    async function loadterminationchecklistquestionOpportunitiesForGrowth2(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=OpportunitiesForGrowth`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarOpportunity([{ value: 1 }]);
                setChecklisttwoStarOpportunity(response.data);

            }

        }).catch(error => {})
    }

    //threeStarOpportunity
    const [threeStarOpportunity, setChecklistthreeStarOpportunity] = useState([])

    async function loadterminationchecklistquestionOpportunitiesForGrowth3(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=OpportunitiesForGrowth`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarOpportunity([{ value: 1 }]);
                setChecklisttwoStarOpportunity([{ value: 1 }]);
                setChecklistthreeStarOpportunity(response.data);

            }

        }).catch(error => {})
    }

    //fourStarOpportunity
    const [fourStarOpportunity, setChecklistfourStarOpportunity] = useState([])

    async function loadterminationchecklistquestionOpportunitiesForGrowth4(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=OpportunitiesForGrowth`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarOpportunity([{ value: 1 }]);
                setChecklisttwoStarOpportunity([{ value: 1 }]);
                setChecklistthreeStarOpportunity([{ value: 1 }]);
                setChecklistfourStarOpportunity(response.data);

            }

        }).catch(error => {})
    }

    //fiveStarOpportunity
    const [fiveStarOpportunity, setChecklistfiveStarOpportunity] = useState([])

    async function loadterminationchecklistquestionOpportunitiesForGrowth5(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=OpportunitiesForGrowth`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarOpportunity([{ value: 1 }]);
                setChecklisttwoStarOpportunity([{ value: 1 }]);
                setChecklistthreeStarOpportunity([{ value: 1 }]);
                setChecklistfourStarOpportunity([{ value: 1 }]);
                setChecklistfiveStarOpportunity(response.data);

            }

        }).catch(error => {})
    }


    //OpportunitiesForGrowth

    async function opportunitiesForGrowth(event) {
        //SAVE
        if(event.target.checked === true) {
            const value      = event.target.value;
            const category  = "OpportunitiesForGrowth";
            const valueCheck = event.target.value;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
            async function loadterminationchecklistatualizado() {
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
                .then(response => {
                    if (valueCheck === '1') {
                        setChecklistoneStarOpportunity(response.data);
                    }
                    if (valueCheck === '2') {
                        setChecklisttwoStarOpportunity(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        });


                    }
                    if (valueCheck === '3') {
                        setChecklistthreeStarOpportunity(response.data);


                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarOpportunity([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '4') {
                        setChecklistfourStarOpportunity(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarOpportunity([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarOpportunity([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '5') {
                        setChecklistfiveStarOpportunity(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarOpportunity([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarOpportunity([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarOpportunity([{ value: 1 }]);
                        });

                        /////////---4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=OpportunitiesForGrowth`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistfourStarOpportunity([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistfourStarOpportunity([{ value: 1 }]);
                        });
                    }

                    //setChecklistfiveStarOpportunity


                })
            }
            loadterminationchecklistatualizado();
        //DELETE
        } else {
            async function loadterminationchecklist() {
                const value      = event.target.value;

                const category  = "OpportunitiesForGrowth";
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const valueCheck = response.data[0].value;
                    api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                    async function loadterminationchecklistatualizado() {
                        if (valueCheck === 1) {
                            setChecklistoneStarOpportunity({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisttwoStarOpportunity({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarOpportunity({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarOpportunity({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarOpportunity({});
                        }
                        if (valueCheck === 2) {
                            ////alert(valueCheck)
                            setChecklisttwoStarOpportunity({});

                            /////////----1----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 2 post 1')
                                    const value      = 1;
                                    const category  = "OpportunitiesForGrowth";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    ///////////----1----////////////
                                    setChecklistoneStarOpportunity([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });


                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarOpportunity({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarOpportunity({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarOpportunity({});

                        }
                        if (valueCheck === 3) {
                            setChecklistthreeStarOpportunity({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 3 post 2')
                                    const value      = 2;
                                    const category  = "OpportunitiesForGrowth";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarOpportunity([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarOpportunity([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarOpportunity({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarOpportunity({});

                        }
                        if (valueCheck === 4) {
                            setChecklistfourStarOpportunity({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    ////alert('delete 4 post 3')
                                    const value      = 3;
                                    const category  = "OpportunitiesForGrowth";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarOpportunity([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarOpportunity([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarOpportunity([{ value: 1 }]);

                                } else {

                                }
                            })
                            .catch(error => {

                            });

                             /////////----5----////////////
                             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                             .then(response => {
                                 const idCheckTerminatorCollaborator = response.data[0]._id;
                                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                             })
                             .catch(error => {
                                 //console.log(error)
                             });
                             setChecklistfiveStarOpportunity({});
                        }
                        if (valueCheck === 5) {
                            setChecklistfiveStarOpportunity({});


                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 5 post 4')
                                    const value      = 4;
                                    const category  = "OpportunitiesForGrowth";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarOpportunity([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarOpportunity([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarOpportunity([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistfourStarOpportunity([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });
                        }

                        //setChecklistfiveStarOpportunity

                    }
                    loadterminationchecklistatualizado();

                })
                .catch(error => {
                    ////alert(value)
                    if (value === '1') {
                        setChecklistoneStarOpportunity({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttwoStarOpportunity({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarOpportunity({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarOpportunity({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarOpportunity({});
                    }
                    if (value === '2') {
                        ////alert(value)
                        setChecklisttwoStarOpportunity({});

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 2 post 1')
                                const value      = 1;
                                const category  = "OpportunitiesForGrowth";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                ///////////----1----////////////
                                setChecklistoneStarOpportunity([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarOpportunity({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarOpportunity({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarOpportunity({});

                    }
                    if (value === '3') {
                        setChecklistthreeStarOpportunity({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 3 post 2')
                                const value      = 2;
                                const category  = "OpportunitiesForGrowth";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarOpportunity([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarOpportunity([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarOpportunity({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarOpportunity({});

                    }
                    if (value === '4') {
                        setChecklistfourStarOpportunity({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                ////alert('delete 4 post 3')
                                const value      = 3;
                                const category  = "OpportunitiesForGrowth";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarOpportunity([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarOpportunity([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarOpportunity([{ value: 1 }]);

                            } else {

                            }
                        })
                        .catch(error => {

                        });

                         /////////----5----////////////
                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                         .then(response => {
                             const idCheckTerminatorCollaborator = response.data[0]._id;
                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                         })
                         .catch(error => {
                             //console.log(error)
                         });
                         setChecklistfiveStarOpportunity({});
                    }
                    if (value === '5') {
                        setChecklistfiveStarOpportunity({});


                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 5 post 4')
                                const value      = 4;
                                const category  = "OpportunitiesForGrowth";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarOpportunity([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarOpportunity([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarOpportunity([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarOpportunity([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });
                    }

                });
            }
            loadterminationchecklist();
        }
    }

    // ROTA GET DAS ESTRELINHAS ==> (HowWasYourRelationship)

    //oneStarManagersRelationship
    const [oneStarManagersRelationship, setChecklistoneStarManagersRelationship] = useState([])

    async function loadterminationchecklistquestionHowWasYourRelationship1(id) {

        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=HowWasYourRelationship`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarManagersRelationship(response.data);

            }

        }).catch(error => {})

    }

    //twoStarManagersRelationship
    const [twoStarManagersRelationship, setChecklisttwoStarManagersRelationship] = useState([])

    async function loadterminationchecklistquestionHowWasYourRelationship2(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=HowWasYourRelationship`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                setChecklisttwoStarManagersRelationship(response.data);

            }


        }).catch(error => {})
    }

    //threeStarManagersRelationship
    const [threeStarManagersRelationship, setChecklistthreeStarManagersRelationship] = useState([])

    async function loadterminationchecklistquestionHowWasYourRelationship3(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=HowWasYourRelationship`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                setChecklistthreeStarManagersRelationship(response.data);

            }


        }).catch(error => {})
    }

    //fourStarManagersRelationship
    const [fourStarManagersRelationship, setChecklistfourStarManagersRelationship] = useState([])

    async function loadterminationchecklistquestionHowWasYourRelationship4(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=HowWasYourRelationship`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                setChecklistfourStarManagersRelationship(response.data);

            }


        }).catch(error => {})
    }

    //fiveStarManagersRelationship
    const [fiveStarManagersRelationship, setChecklistfiveStarManagersRelationship] = useState([])

    async function loadterminationchecklistquestionHowWasYourRelationship5(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=HowWasYourRelationship`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                setChecklistfourStarManagersRelationship([{ value: 1 }]);
                setChecklistfiveStarManagersRelationship(response.data);

            }


        }).catch(error => {})
    }


    //HowWasYourRelationship

    async function howWasYourRelationship(event) {
        //SAVE
        if(event.target.checked === true) {
            const value      = event.target.value;
            const category  = "HowWasYourRelationship";
            const valueCheck = event.target.value;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
            async function loadterminationchecklistatualizado() {
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
                .then(response => {
                    if (valueCheck === '1') {
                        setChecklistoneStarManagersRelationship(response.data);
                    }
                    if (valueCheck === '2') {
                        setChecklisttwoStarManagersRelationship(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        });


                    }
                    if (valueCheck === '3') {
                        setChecklistthreeStarManagersRelationship(response.data);


                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '4') {
                        setChecklistfourStarManagersRelationship(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '5') {
                        setChecklistfiveStarManagersRelationship(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarManagersRelationship([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                        });

                        /////////---4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=HowWasYourRelationship`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistfourStarManagersRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistfourStarManagersRelationship([{ value: 1 }]);
                        });
                    }

                    //setChecklistfiveStarManagersRelationship


                })
            }
            loadterminationchecklistatualizado();
        //DELETE
        } else {
            async function loadterminationchecklist() {
                const value      = event.target.value;

                const category  = "HowWasYourRelationship";
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const valueCheck = response.data[0].value;
                    api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                    async function loadterminationchecklistatualizado() {
                        if (valueCheck === 1) {
                            setChecklistoneStarManagersRelationship({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisttwoStarManagersRelationship({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarManagersRelationship({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarManagersRelationship({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarManagersRelationship({});
                        }
                        if (valueCheck === 2) {
                            ////alert(valueCheck)
                            setChecklisttwoStarManagersRelationship({});

                            /////////----1----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 2 post 1')
                                    const value      = 1;
                                    const category  = "HowWasYourRelationship";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    ///////////----1----////////////
                                    setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });


                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarManagersRelationship({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarManagersRelationship({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarManagersRelationship({});

                        }
                        if (valueCheck === 3) {
                            setChecklistthreeStarManagersRelationship({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 3 post 2')
                                    const value      = 2;
                                    const category  = "HowWasYourRelationship";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarManagersRelationship({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarManagersRelationship({});

                        }
                        if (valueCheck === 4) {
                            setChecklistfourStarManagersRelationship({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    ////alert('delete 4 post 3')
                                    const value      = 3;
                                    const category  = "HowWasYourRelationship";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarManagersRelationship([{ value: 1 }]);

                                } else {

                                }
                            })
                            .catch(error => {

                            });

                             /////////----5----////////////
                             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                             .then(response => {
                                 const idCheckTerminatorCollaborator = response.data[0]._id;
                                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                             })
                             .catch(error => {
                                 //console.log(error)
                             });
                             setChecklistfiveStarManagersRelationship({});
                        }
                        if (valueCheck === 5) {
                            setChecklistfiveStarManagersRelationship({});


                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 5 post 4')
                                    const value      = 4;
                                    const category  = "HowWasYourRelationship";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistfourStarManagersRelationship([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });
                        }

                        //setChecklistfiveStarManagersRelationship

                    }
                    loadterminationchecklistatualizado();

                })
                .catch(error => {
                    ////alert(value)
                    if (value === '1') {
                        setChecklistoneStarManagersRelationship({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttwoStarManagersRelationship({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarManagersRelationship({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarManagersRelationship({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarManagersRelationship({});
                    }
                    if (value === '2') {
                        ////alert(value)
                        setChecklisttwoStarManagersRelationship({});

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 2 post 1')
                                const value      = 1;
                                const category  = "HowWasYourRelationship";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                ///////////----1----////////////
                                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarManagersRelationship({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarManagersRelationship({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarManagersRelationship({});

                    }
                    if (value === '3') {
                        setChecklistthreeStarManagersRelationship({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 3 post 2')
                                const value      = 2;
                                const category  = "HowWasYourRelationship";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarManagersRelationship({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarManagersRelationship({});

                    }
                    if (value === '4') {
                        setChecklistfourStarManagersRelationship({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                ////alert('delete 4 post 3')
                                const value      = 3;
                                const category  = "HowWasYourRelationship";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarManagersRelationship([{ value: 1 }]);

                            } else {

                            }
                        })
                        .catch(error => {

                        });

                         /////////----5----////////////
                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                         .then(response => {
                             const idCheckTerminatorCollaborator = response.data[0]._id;
                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                         })
                         .catch(error => {
                             //console.log(error)
                         });
                         setChecklistfiveStarManagersRelationship({});
                    }
                    if (value === '5') {
                        setChecklistfiveStarManagersRelationship({});


                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 5 post 4')
                                const value      = 4;
                                const category  = "HowWasYourRelationship";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarManagersRelationship([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarManagersRelationship([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarManagersRelationship([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarManagersRelationship([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });
                    }

                });
            }
            loadterminationchecklist();
        }
    }


    // ROTA GET DAS ESTRELINHAS ==> (RelationshipWithTheTeam)

    //oneStarTeamRelationship
    const [oneStarTeamRelationship, setChecklistoneStarTeamRelationship] = useState([])

    async function loadterminationchecklistquestionRelationshipWithTheTeam1(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=RelationshipWithTheTeam`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarTeamRelationship(response.data);

            }

        }).catch(error => {})
    }

    //twoStarTeamRatio
    const [twoStarTeamRatio, setChecklisttwoStarTeamRatio] = useState([])

    async function loadterminationchecklistquestionRelationshipWithTheTeam2(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=RelationshipWithTheTeam`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                setChecklisttwoStarTeamRatio(response.data);

            }

        }).catch(error => {})
    }

    //threeStarTeamRelationship
    const [threeStarTeamRelationship, setChecklistthreeStarTeamRelationship] = useState([])

    async function loadterminationchecklistquestionRelationshipWithTheTeam3(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=RelationshipWithTheTeam`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                setChecklisttwoStarTeamRatio([{ value: 1 }]);
                setChecklistthreeStarTeamRelationship(response.data);

            }


        }).catch(error => {})
    }

    //fourStarTeamRelationship
    const [fourStarTeamRelationship, setChecklistfourStarTeamRelationship] = useState([])

    async function loadterminationchecklistquestionRelationshipWithTheTeam4(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=RelationshipWithTheTeam`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                setChecklisttwoStarTeamRatio([{ value: 1 }]);
                setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                setChecklistfourStarTeamRelationship(response.data);

            }


        }).catch(error => {})
    }


    //fiveStarTeamRelationship
    const [fiveStarTeamRelationship, setChecklistfiveStarTeamRelationship] = useState([])

    async function loadterminationchecklistquestionRelationshipWithTheTeam5(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=RelationshipWithTheTeam`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                setChecklisttwoStarTeamRatio([{ value: 1 }]);
                setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                setChecklistfourStarTeamRelationship([{ value: 1 }]);
                setChecklistfiveStarTeamRelationship(response.data);

            }


        }).catch(error => {})
    }


    //RelationshipWithTheTeam

    async function relationshipWithTheTeam(event) {
        //SAVE
        if(event.target.checked === true) {
            const value      = event.target.value;
            const category  = "RelationshipWithTheTeam";
            const valueCheck = event.target.value;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
            async function loadterminationchecklistatualizado() {
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
                .then(response => {
                    if (valueCheck === '1') {
                        setChecklistoneStarTeamRelationship(response.data);
                    }
                    if (valueCheck === '2') {
                        setChecklisttwoStarTeamRatio(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        });


                    }
                    if (valueCheck === '3') {
                        setChecklistthreeStarTeamRelationship(response.data);


                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarTeamRatio([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarTeamRatio([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '4') {
                        setChecklistfourStarTeamRelationship(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarTeamRatio([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarTeamRatio([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '5') {
                        setChecklistfiveStarTeamRelationship(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarTeamRelationship([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarTeamRatio([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarTeamRatio([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                        });

                        /////////---4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=RelationshipWithTheTeam`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistfourStarTeamRelationship([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistfourStarTeamRelationship([{ value: 1 }]);
                        });
                    }

                    //setChecklistfiveStarTeamRelationship


                })
            }
            loadterminationchecklistatualizado();
        //DELETE
        } else {
            async function loadterminationchecklist() {
                const value      = event.target.value;

                const category  = "RelationshipWithTheTeam";
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const valueCheck = response.data[0].value;
                    api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                    async function loadterminationchecklistatualizado() {
                        if (valueCheck === 1) {
                            setChecklistoneStarTeamRelationship({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisttwoStarTeamRatio({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarTeamRelationship({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarTeamRelationship({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarTeamRelationship({});
                        }
                        if (valueCheck === 2) {
                            ////alert(valueCheck)
                            setChecklisttwoStarTeamRatio({});

                            /////////----1----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 2 post 1')
                                    const value      = 1;
                                    const category  = "RelationshipWithTheTeam";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    ///////////----1----////////////
                                    setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });


                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarTeamRelationship({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarTeamRelationship({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarTeamRelationship({});

                        }
                        if (valueCheck === 3) {
                            setChecklistthreeStarTeamRelationship({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 3 post 2')
                                    const value      = 2;
                                    const category  = "RelationshipWithTheTeam";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarTeamRatio([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarTeamRelationship({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarTeamRelationship({});

                        }
                        if (valueCheck === 4) {
                            setChecklistfourStarTeamRelationship({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    ////alert('delete 4 post 3')
                                    const value      = 3;
                                    const category  = "RelationshipWithTheTeam";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarTeamRatio([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarTeamRelationship([{ value: 1 }]);

                                } else {

                                }
                            })
                            .catch(error => {

                            });

                             /////////----5----////////////
                             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                             .then(response => {
                                 const idCheckTerminatorCollaborator = response.data[0]._id;
                                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                             })
                             .catch(error => {
                                 //console.log(error)
                             });
                             setChecklistfiveStarTeamRelationship({});
                        }
                        if (valueCheck === 5) {
                            setChecklistfiveStarTeamRelationship({});


                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 5 post 4')
                                    const value      = 4;
                                    const category  = "RelationshipWithTheTeam";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarTeamRatio([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistfourStarTeamRelationship([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });
                        }

                        //setChecklistfiveStarTeamRelationship

                    }
                    loadterminationchecklistatualizado();

                })
                .catch(error => {
                    ////alert(value)
                    if (value === '1') {
                        setChecklistoneStarTeamRelationship({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttwoStarTeamRatio({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarTeamRelationship({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarTeamRelationship({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarTeamRelationship({});
                    }
                    if (value === '2') {
                        ////alert(value)
                        setChecklisttwoStarTeamRatio({});

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 2 post 1')
                                const value      = 1;
                                const category  = "RelationshipWithTheTeam";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                ///////////----1----////////////
                                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarTeamRelationship({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarTeamRelationship({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarTeamRelationship({});

                    }
                    if (value === '3') {
                        setChecklistthreeStarTeamRelationship({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 3 post 2')
                                const value      = 2;
                                const category  = "RelationshipWithTheTeam";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarTeamRatio([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarTeamRelationship({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarTeamRelationship({});

                    }
                    if (value === '4') {
                        setChecklistfourStarTeamRelationship({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                ////alert('delete 4 post 3')
                                const value      = 3;
                                const category  = "RelationshipWithTheTeam";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarTeamRatio([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarTeamRelationship([{ value: 1 }]);

                            } else {

                            }
                        })
                        .catch(error => {

                        });

                         /////////----5----////////////
                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                         .then(response => {
                             const idCheckTerminatorCollaborator = response.data[0]._id;
                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                         })
                         .catch(error => {
                             //console.log(error)
                         });
                         setChecklistfiveStarTeamRelationship({});
                    }
                    if (value === '5') {
                        setChecklistfiveStarTeamRelationship({});


                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 5 post 4')
                                const value      = 4;
                                const category  = "RelationshipWithTheTeam";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarTeamRelationship([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarTeamRatio([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarTeamRelationship([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarTeamRelationship([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });
                    }

                });
            }
            loadterminationchecklist();
        }
    }


    // ROTA GET DAS ESTRELINHAS ==> (opinionRegardingSalary)

    //oneStarSalaryOpinion
    // const [oneStarSalaryOpinion, setChecklistoneStarSalaryOpinion] = useState([])

    // useEffect(()=>{
    //     async function loadterminationchecklistquestion() {
    //         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=opinionRegardingSalary`)
    //         .then(response => {
    //             setChecklistoneStarSalaryOpinion(response.data);
    //             //console.log(response.data)
    //         })
    //     }
    //     loadterminationchecklistquestion();
    // },[id])

    //twoStarSalaryOpinion
    // const [twoStarSalaryOpinion, setChecklisttwoStarSalaryOpinion] = useState([])

    // useEffect(()=>{
    //     async function loadterminationchecklistquestion() {
    //         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=opinionRegardingSalary`)
    //         .then(response => {
    //             if(response.data.length === 0) {

    //             } else {
    //                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklisttwoStarSalaryOpinion(response.data);
    //             }

    //             //console.log(response.data.length)
    //         })
    //     }
    //     loadterminationchecklistquestion();
    // },[id])

    // //threeStarSalaryOpinion
    // const [threeStarSalaryOpinion, setChecklistthreeStarSalaryOpinion] = useState([])

    // useEffect(()=>{
    //     async function loadterminationchecklistquestion() {
    //         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=opinionRegardingSalary`)
    //         .then(response => {
    //             if(response.data.length === 0) {

    //             } else {
    //                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklistthreeStarSalaryOpinion(response.data);
    //             }

    //             //console.log(response.data.length)

    //             //console.log(response.data)
    //         })
    //     }
    //     loadterminationchecklistquestion();
    // },[id])

    // //fourStarSalaryOpinion
    // const [fourStarSalaryOpinion, setChecklistfourStarSalaryOpinion] = useState([])

    // useEffect(()=>{
    //     async function loadterminationchecklistquestion() {
    //         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=opinionRegardingSalary`)
    //         .then(response => {
    //             if(response.data.length === 0) {

    //             } else {
    //                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklistfourStarSalaryOpinion(response.data);
    //             }

    //             //console.log(response.data.length)

    //             //console.log(response.data)
    //         })
    //     }
    //     loadterminationchecklistquestion();
    // },[id])

    //fiveStarSalaryOpinion
    // const [fiveStarSalaryOpinion, setChecklistfiveStarSalaryOpinion] = useState([])

    // useEffect(()=>{
    //     async function loadterminationchecklistquestion() {
    //         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=opinionRegardingSalary`)
    //         .then(response => {
    //             if(response.data.length === 0) {

    //             } else {
    //                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklistfourStarSalaryOpinion([{ value: 1 }]);
    //                 setChecklistfiveStarSalaryOpinion(response.data);
    //             }

    //             //console.log(response.data.length)

    //         })
    //     }
    //     loadterminationchecklistquestion();
    // },[id])


    //opinionRegardingSalary

    // async function opinionRegardingSalary(event) {
    //     //SAVE
    //     if(event.target.checked === true) {
    //         const value      = event.target.value;
    //         const category  = "opinionRegardingSalary";
    //         const valueCheck = event.target.value;
    //         await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //         async function loadterminationchecklistatualizado() {
    //             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
    //             .then(response => {
    //                 if (valueCheck === '1') {
    //                     setChecklistoneStarSalaryOpinion(response.data);
    //                 }
    //                 if (valueCheck === '2') {
    //                     setChecklisttwoStarSalaryOpinion(response.data);

    //                     /////////----1----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     });


    //                 }
    //                 if (valueCheck === '3') {
    //                     setChecklistthreeStarSalaryOpinion(response.data);


    //                     /////////----1----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     });

    //                     /////////----2----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                     });
    //                 }
    //                 if (valueCheck === '4') {
    //                     setChecklistfourStarSalaryOpinion(response.data);

    //                     /////////----1----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     });

    //                     /////////----2----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                     });

    //                     /////////---3----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                     });
    //                 }
    //                 if (valueCheck === '5') {
    //                     setChecklistfiveStarSalaryOpinion(response.data);

    //                     /////////----1----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                     });

    //                     /////////----2----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                     });

    //                     /////////---3----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                     });

    //                     /////////---4----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=opinionRegardingSalary`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         setChecklistfourStarSalaryOpinion([{ value: 1 }]);
    //                     })
    //                     .catch(error => {
    //                         setChecklistfourStarSalaryOpinion([{ value: 1 }]);
    //                     });
    //                 }

    //                 //setChecklistfiveStarSalaryOpinion


    //             })
    //         }
    //         loadterminationchecklistatualizado();
    //     //DELETE
    //     } else {
    //         async function loadterminationchecklist() {
    //             const value      = event.target.value;

    //             const category  = "opinionRegardingSalary";
    //             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
    //             //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
    //             .then(response => {
    //                 const idCheckTerminatorCollaborator = response.data[0]._id;
    //                 const valueCheck = response.data[0].value;
    //                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                 async function loadterminationchecklistatualizado() {
    //                     if (valueCheck === 1) {
    //                         setChecklistoneStarSalaryOpinion({});

    //                         /////////----2----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklisttwoStarSalaryOpinion({});

    //                         /////////----3----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistthreeStarSalaryOpinion({});

    //                         /////////----4----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistfourStarSalaryOpinion({});

    //                         /////////----5----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistfiveStarSalaryOpinion({});
    //                     }
    //                     if (valueCheck === 2) {
    //                         ////alert(valueCheck)
    //                         setChecklisttwoStarSalaryOpinion({});

    //                         /////////----1----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
    //                         .then(response => {
    //                             if(response.data.length === 0) {
    //                                 //alert('delete 2 post 1')
    //                                 const value      = 1;
    //                                 const category  = "opinionRegardingSalary";
    //                                 api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                                 ///////////----1----////////////
    //                                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                             } else {

    //                             }
    //                         })
    //                         .catch(error => {

    //                         });


    //                         /////////----3----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistthreeStarSalaryOpinion({});

    //                         /////////----4----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistfourStarSalaryOpinion({});

    //                         /////////----5----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistfiveStarSalaryOpinion({});

    //                     }
    //                     if (valueCheck === 3) {
    //                         setChecklistthreeStarSalaryOpinion({});

    //                         /////////----2----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
    //                         .then(response => {
    //                             if(response.data.length === 0) {
    //                                 //alert('delete 3 post 2')
    //                                 const value      = 2;
    //                                 const category  = "opinionRegardingSalary";
    //                                 api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                                 /////////----1----////////////
    //                                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                                 /////////----2----////////////
    //                                 setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                             } else {

    //                             }
    //                         })
    //                         .catch(error => {

    //                         });

    //                         /////////----4----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistfourStarSalaryOpinion({});

    //                         /////////----5----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                         .then(response => {
    //                             const idCheckTerminatorCollaborator = response.data[0]._id;
    //                             api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                         })
    //                         .catch(error => {
    //                             //console.log(error)
    //                         });
    //                         setChecklistfiveStarSalaryOpinion({});

    //                     }
    //                     if (valueCheck === 4) {
    //                         setChecklistfourStarSalaryOpinion({});

    //                         /////////----3----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
    //                         .then(response => {
    //                             if(response.data.length === 0) {
    //                                 ////alert('delete 4 post 3')
    //                                 const value      = 3;
    //                                 const category  = "opinionRegardingSalary";
    //                                 api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                                 /////////----1----////////////
    //                                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                                 /////////----2----////////////
    //                                 setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                                 /////////----3----////////////
    //                                 setChecklistthreeStarSalaryOpinion([{ value: 1 }]);

    //                             } else {

    //                             }
    //                         })
    //                         .catch(error => {

    //                         });

    //                          /////////----5----////////////
    //                          api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                          .then(response => {
    //                              const idCheckTerminatorCollaborator = response.data[0]._id;
    //                              api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                          })
    //                          .catch(error => {
    //                              //console.log(error)
    //                          });
    //                          setChecklistfiveStarSalaryOpinion({});
    //                     }
    //                     if (valueCheck === 5) {
    //                         setChecklistfiveStarSalaryOpinion({});


    //                         /////////----4----////////////
    //                         api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                         .then(response => {
    //                             if(response.data.length === 0) {
    //                                 //alert('delete 5 post 4')
    //                                 const value      = 4;
    //                                 const category  = "opinionRegardingSalary";
    //                                 api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                                 /////////----1----////////////
    //                                 setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                                 /////////----2----////////////
    //                                 setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                                 /////////----3----////////////
    //                                 setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                                 /////////----3----////////////
    //                                 setChecklistfourStarSalaryOpinion([{ value: 1 }]);
    //                             } else {

    //                             }
    //                         })
    //                         .catch(error => {

    //                         });
    //                     }

    //                     //setChecklistfiveStarSalaryOpinion

    //                 }
    //                 loadterminationchecklistatualizado();

    //             })
    //             .catch(error => {
    //                 ////alert(value)
    //                 if (value === '1') {
    //                     setChecklistoneStarSalaryOpinion({});

    //                     /////////----2----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklisttwoStarSalaryOpinion({});

    //                     /////////----3----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistthreeStarSalaryOpinion({});

    //                     /////////----4----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistfourStarSalaryOpinion({});

    //                     /////////----5----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistfiveStarSalaryOpinion({});
    //                 }
    //                 if (value === '2') {
    //                     ////alert(value)
    //                     setChecklisttwoStarSalaryOpinion({});

    //                     /////////----1----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
    //                     .then(response => {
    //                         if(response.data.length === 0) {
    //                             //alert('delete 2 post 1')
    //                             const value      = 1;
    //                             const category  = "opinionRegardingSalary";
    //                             api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                             ///////////----1----////////////
    //                             setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                         } else {

    //                         }
    //                     })
    //                     .catch(error => {

    //                     });


    //                     /////////----3----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistthreeStarSalaryOpinion({});

    //                     /////////----4----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistfourStarSalaryOpinion({});

    //                     /////////----5----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistfiveStarSalaryOpinion({});

    //                 }
    //                 if (value === '3') {
    //                     setChecklistthreeStarSalaryOpinion({});

    //                     /////////----2----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
    //                     .then(response => {
    //                         if(response.data.length === 0) {
    //                             //alert('delete 3 post 2')
    //                             const value      = 2;
    //                             const category  = "opinionRegardingSalary";
    //                             api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                             /////////----1----////////////
    //                             setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                             /////////----2----////////////
    //                             setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                         } else {

    //                         }
    //                     })
    //                     .catch(error => {

    //                     });

    //                     /////////----4----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistfourStarSalaryOpinion({});

    //                     /////////----5----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                     .then(response => {
    //                         const idCheckTerminatorCollaborator = response.data[0]._id;
    //                         api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                     })
    //                     .catch(error => {
    //                         //console.log(error)
    //                     });
    //                     setChecklistfiveStarSalaryOpinion({});

    //                 }
    //                 if (value === '4') {
    //                     setChecklistfourStarSalaryOpinion({});

    //                     /////////----3----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
    //                     .then(response => {
    //                         if(response.data.length === 0) {
    //                             ////alert('delete 4 post 3')
    //                             const value      = 3;
    //                             const category  = "opinionRegardingSalary";
    //                             api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                             /////////----1----////////////
    //                             setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                             /////////----2----////////////
    //                             setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                             /////////----3----////////////
    //                             setChecklistthreeStarSalaryOpinion([{ value: 1 }]);

    //                         } else {

    //                         }
    //                     })
    //                     .catch(error => {

    //                     });

    //                      /////////----5----////////////
    //                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
    //                      .then(response => {
    //                          const idCheckTerminatorCollaborator = response.data[0]._id;
    //                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
    //                      })
    //                      .catch(error => {
    //                          //console.log(error)
    //                      });
    //                      setChecklistfiveStarSalaryOpinion({});
    //                 }
    //                 if (value === '5') {
    //                     setChecklistfiveStarSalaryOpinion({});


    //                     /////////----4----////////////
    //                     api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
    //                     .then(response => {
    //                         if(response.data.length === 0) {
    //                             //alert('delete 5 post 4')
    //                             const value      = 4;
    //                             const category  = "opinionRegardingSalary";
    //                             api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
    //                             /////////----1----////////////
    //                             setChecklistoneStarSalaryOpinion([{ value: 1 }]);
    //                             /////////----2----////////////
    //                             setChecklisttwoStarSalaryOpinion([{ value: 1 }]);
    //                             /////////----3----////////////
    //                             setChecklistthreeStarSalaryOpinion([{ value: 1 }]);
    //                             /////////----3----////////////
    //                             setChecklistfourStarSalaryOpinion([{ value: 1 }]);
    //                         } else {

    //                         }
    //                     })
    //                     .catch(error => {

    //                     });
    //                 }

    //             });
    //         }
    //         loadterminationchecklist();
    //     }
    // }

    // ROTA GET DAS ESTRELINHAS ==> (YourNoteExperienceInJobs)

    //oneStarExperienceJobs
    const [oneStarExperienceJobs, setChecklistoneStarExperienceJobs] = useState([])

    async function loadterminationchecklistquestionYourNoteExperienceInJobs1(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs(response.data);

            }

        }).catch(error => {})
    }

    //twoStarExperienceJobs
    const [twoStarExperienceJobs, setChecklisttwoStarExperienceJobs] = useState([])

    async function loadterminationchecklistquestionYourNoteExperienceInJobs2(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

    //threeStarExperienceJobs
    const [threeStarExperienceJobs, setChecklistthreeStarExperienceJobs] = useState([])

    async function loadterminationchecklistquestionYourNoteExperienceInJobs3(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

    //fourStarExperienceJobs
    const [fourStarExperienceJobs, setChecklistfourStarExperienceJobs] = useState([])

    async function loadterminationchecklistquestionYourNoteExperienceInJobs4(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

    //fiveStarExperienceJobs
    const [fiveStarExperienceJobs, setChecklistfiveStarExperienceJobs] = useState([])

    async function loadterminationchecklistquestionYourNoteExperienceInJobs5(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                setChecklistfiveStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

     //sixStarExperienceJobs
     const [sixStarExperienceJobs, setChecklistsixStarExperienceJobs] = useState([])

     async function loadterminationchecklistquestionYourNoteExperienceInJobs6(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                setChecklistsixStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

     //sevenStarExperienceJobs
     const [sevenStarExperienceJobs, setChecklistsevenStarExperienceJobs] = useState([])

     async function loadterminationchecklistquestionYourNoteExperienceInJobs7(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                setChecklistsevenStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

     //eightStarExperienceJobs
     const [eightStarExperienceJobs, setChecklisteightStarExperienceJobs] = useState([])

     async function loadterminationchecklistquestionYourNoteExperienceInJobs8(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                setChecklisteightStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

     //nineStarExperienceJobs
     const [nineStarExperienceJobs, setChecklistnineStarExperienceJobs] = useState([])

     async function loadterminationchecklistquestionYourNoteExperienceInJobs9(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=YourNoteExperienceInJobs`)
        .then(response => {


            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                setChecklisteightStarExperienceJobs([{ value: 1 }]);
                setChecklistnineStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }

     //tenStarExperienceJobs
     const [tenStarExperienceJobs, setChecklisttenStarExperienceJobs] = useState([])

     async function loadterminationchecklistquestionYourNoteExperienceInJobs10(id) {
        await api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=YourNoteExperienceInJobs`)
        .then(response => {

            if(response.data.length !== 0) {

                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                setChecklisteightStarExperienceJobs([{ value: 1 }]);
                setChecklistnineStarExperienceJobs([{ value: 1 }]);
                setChecklisttenStarExperienceJobs(response.data);

            }


        }).catch(error => {})
    }


    //YourNoteExperienceInJobs

    async function yourNoteExperienceInJobs(event) {
        //SAVE
        if(event.target.checked === true) {
            const value      = event.target.value;
            const category  = "YourNoteExperienceInJobs";
            const valueCheck = event.target.value;
            await api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
            async function loadterminationchecklistatualizado() {
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${valueCheck}&category=${category}`)
                .then(response => {
                    if (valueCheck === '1') {
                        setChecklistoneStarExperienceJobs(response.data);
                    }
                    if (valueCheck === '2') {
                        setChecklisttwoStarExperienceJobs(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        });


                    }
                    if (valueCheck === '3') {
                        setChecklistthreeStarExperienceJobs(response.data);


                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '4') {
                        setChecklistfourStarExperienceJobs(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                        });
                    }
                    if (valueCheck === '5') {
                        setChecklistfiveStarExperienceJobs(response.data);

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistoneStarExperienceJobs([{ value: 1 }]);
                        });

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                        });

                        /////////---3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                        });

                        /////////---4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            setChecklistfourStarExperienceJobs([{ value: 1 }]);
                        })
                        .catch(error => {
                            setChecklistfourStarExperienceJobs([{ value: 1 }]);
                        });


                        /////////----6----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsixStarExperienceJobs({});
                      })
                      .catch(error => {
                          setChecklistsixStarExperienceJobs({});
                      });

                      /////////----7----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsevenStarExperienceJobs({});
                      })
                      .catch(error => {
                          setChecklistsevenStarExperienceJobs({});
                      });

                      /////////---8----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisteightStarExperienceJobs({});
                      })
                      .catch(error => {
                          setChecklisteightStarExperienceJobs({});
                      });

                      /////////---9----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistnineStarExperienceJobs({});
                      })
                      .catch(error => {
                          setChecklistnineStarExperienceJobs({});
                      });

                      /////////---10----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisttenStarExperienceJobs({});
                      })
                      .catch(error => {
                          setChecklisttenStarExperienceJobs({});
                      });

                    }

                    if (valueCheck === '6') {
                      setChecklistsixStarExperienceJobs(response.data);

                      /////////----1----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////----2----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---3----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---4----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---5----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      });


                    }

                    if (valueCheck === '7') {
                      setChecklistsevenStarExperienceJobs(response.data);

                      /////////----1----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////----2----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---3----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---4----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---5----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---6----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      });


                    }

                    if (valueCheck === '8') {
                      setChecklisteightStarExperienceJobs(response.data);

                      /////////----1----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////----2----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---3----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---4----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---5----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---6----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---7----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                      });


                    }

                    if (valueCheck === '9') {
                      setChecklistnineStarExperienceJobs(response.data);

                      /////////----1----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////----2----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---3----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---4----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---5----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---6----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---7----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---8----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisteightStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisteightStarExperienceJobs([{ value: 1 }]);
                      });


                    }

                    if (valueCheck === '10') {
                      setChecklisttenStarExperienceJobs(response.data);

                      /////////----1----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistoneStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////----2----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---3----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---4----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfourStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---5----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---6----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsixStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---7----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                      });

                      /////////---8----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklisteightStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklisteightStarExperienceJobs([{ value: 1 }]);
                      });


                      /////////---9----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=YourNoteExperienceInJobs`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          setChecklistnineStarExperienceJobs([{ value: 1 }]);
                      })
                      .catch(error => {
                          setChecklistnineStarExperienceJobs([{ value: 1 }]);
                      });


                    }

                    //tenStarExperienceJobs


                })
            }
            loadterminationchecklistatualizado();
        //DELETE
        } else {
            async function loadterminationchecklist() {
                const value      = event.target.value;

                const category  = "YourNoteExperienceInJobs";
                api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=${value}&category=${category}`)
                //api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&name=${name}&category=${category}`)
                .then(response => {
                    const idCheckTerminatorCollaborator = response.data[0]._id;
                    const valueCheck = response.data[0].value;
                    api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                    async function loadterminationchecklistatualizado() {
                        if (valueCheck === 1) {
                            setChecklistoneStarExperienceJobs({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisttwoStarExperienceJobs({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarExperienceJobs({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarExperienceJobs({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarExperienceJobs({});
                        }
                        if (valueCheck === 2) {
                            ////alert(valueCheck)
                            setChecklisttwoStarExperienceJobs({});

                            /////////----1----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 2 post 1')
                                    const value      = 1;
                                    const category  = "YourNoteExperienceInJobs";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    ///////////----1----////////////
                                    setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });


                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistthreeStarExperienceJobs({});

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarExperienceJobs({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarExperienceJobs({});

                        }
                        if (valueCheck === 3) {
                            setChecklistthreeStarExperienceJobs({});

                            /////////----2----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 3 post 2')
                                    const value      = 2;
                                    const category  = "YourNoteExperienceInJobs";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfourStarExperienceJobs({});

                            /////////----5----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistfiveStarExperienceJobs({});

                        }
                        if (valueCheck === 4) {
                            setChecklistfourStarExperienceJobs({});

                            /////////----3----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    ////alert('delete 4 post 3')
                                    const value      = 3;
                                    const category  = "YourNoteExperienceInJobs";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarExperienceJobs([{ value: 1 }]);

                                } else {

                                }
                            })
                            .catch(error => {

                            });

                             /////////----5----////////////
                             api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                             .then(response => {
                                 const idCheckTerminatorCollaborator = response.data[0]._id;
                                 api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                             })
                             .catch(error => {
                                 //console.log(error)
                             });
                             setChecklistfiveStarExperienceJobs({});
                        }
                        if (valueCheck === 5) {
                            setChecklistfiveStarExperienceJobs({});


                            /////////----4----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                            .then(response => {
                                if(response.data.length === 0) {
                                    //alert('delete 5 post 4')
                                    const value      = 4;
                                    const category  = "YourNoteExperienceInJobs";
                                    api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                    /////////----1----////////////
                                    setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                    /////////----2----////////////
                                    setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                    /////////----3----////////////
                                    setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                } else {

                                }
                            })
                            .catch(error => {

                            });

                            /////////----6----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistsixStarExperienceJobs({});


                            /////////----7----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistsevenStarExperienceJobs({});

                            /////////----8----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisteightStarExperienceJobs({});

                            /////////----9----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklistnineStarExperienceJobs({});

                            /////////----10----////////////
                            api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                            .then(response => {
                                const idCheckTerminatorCollaborator = response.data[0]._id;
                                api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                            })
                            .catch(error => {
                                //console.log(error)
                            });
                            setChecklisttenStarExperienceJobs({});
                        }

                        if (valueCheck === 6) {
                          setChecklistsixStarExperienceJobs({});


                          /////////----5----////////////
                          api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                          .then(response => {
                              if(response.data.length === 0) {
                                  //alert('delete 6 post 5')
                                  const value      = 5;
                                  const category  = "YourNoteExperienceInJobs";
                                  api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                  /////////----1----////////////
                                  setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                  /////////----2----////////////
                                  setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                  /////////----3----////////////
                                  setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                  /////////----4----////////////
                                  setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                  /////////----5----////////////
                                  setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                              } else {

                              }
                          })
                          .catch(error => {

                          });

                          /////////----7----////////////
                          api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                          .then(response => {
                              const idCheckTerminatorCollaborator = response.data[0]._id;
                              api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          })
                          .catch(error => {
                              //console.log(error)
                          });
                          setChecklistsevenStarExperienceJobs({});

                          /////////----8----////////////
                          api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                          .then(response => {
                              const idCheckTerminatorCollaborator = response.data[0]._id;
                              api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          })
                          .catch(error => {
                              //console.log(error)
                          });
                          setChecklisteightStarExperienceJobs({});

                          /////////----9----////////////
                          api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                          .then(response => {
                              const idCheckTerminatorCollaborator = response.data[0]._id;
                              api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          })
                          .catch(error => {
                              //console.log(error)
                          });
                          setChecklistnineStarExperienceJobs({});

                          /////////----10----////////////
                          api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                          .then(response => {
                              const idCheckTerminatorCollaborator = response.data[0]._id;
                              api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                          })
                          .catch(error => {
                              //console.log(error)
                          });
                          setChecklisttenStarExperienceJobs({});
                      }

                      if (valueCheck === 7) {
                        setChecklistsevenStarExperienceJobs({});


                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 7 post 6')
                                const value      = 6;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----4----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                /////////----5----////////////
                                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                                /////////----6----////////////
                                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });



                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});
                    }

                    if (valueCheck === 8) {
                      setChecklisteightStarExperienceJobs({});


                      /////////----7----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                      .then(response => {
                          if(response.data.length === 0) {
                              //alert('delete 8 post 7')
                              const value      = 7;
                              const category  = "YourNoteExperienceInJobs";
                              api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                              /////////----1----////////////
                              setChecklistoneStarExperienceJobs([{ value: 1 }]);
                              /////////----2----////////////
                              setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                              /////////----3----////////////
                              setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                              /////////----4----////////////
                              setChecklistfourStarExperienceJobs([{ value: 1 }]);
                              /////////----5----////////////
                              setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                              /////////----6----////////////
                              setChecklistsixStarExperienceJobs([{ value: 1 }]);
                              /////////----7----////////////
                              setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                          } else {

                          }
                      })
                      .catch(error => {

                      });


                      /////////----9----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                      })
                      .catch(error => {
                          //console.log(error)
                      });
                      setChecklistnineStarExperienceJobs({});

                      /////////----10----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                      })
                      .catch(error => {
                          //console.log(error)
                      });
                      setChecklisttenStarExperienceJobs({});
                    }

                    if (valueCheck === 9) {
                      setChecklistnineStarExperienceJobs({});


                      /////////----8----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                      .then(response => {
                          if(response.data.length === 0) {
                              //alert('delete 9 post 8')
                              const value      = 8;
                              const category  = "YourNoteExperienceInJobs";
                              api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                              /////////----1----////////////
                              setChecklistoneStarExperienceJobs([{ value: 1 }]);
                              /////////----2----////////////
                              setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                              /////////----3----////////////
                              setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                              /////////----4----////////////
                              setChecklistfourStarExperienceJobs([{ value: 1 }]);
                              /////////----5----////////////
                              setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                              /////////----6----////////////
                              setChecklistsixStarExperienceJobs([{ value: 1 }]);
                              /////////----7----////////////
                              setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                              /////////----8----////////////
                              setChecklisteightStarExperienceJobs([{ value: 1 }]);
                          } else {

                          }
                      })
                      .catch(error => {

                      });


                      /////////----10----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                      .then(response => {
                          const idCheckTerminatorCollaborator = response.data[0]._id;
                          api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                      })
                      .catch(error => {
                          //console.log(error)
                      });
                      setChecklisttenStarExperienceJobs({});
                    }

                    if (valueCheck === 10) {
                      setChecklistnineStarExperienceJobs({});


                      /////////----9----////////////
                      api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                      .then(response => {
                          if(response.data.length === 0) {
                              //alert('delete 10 post 9')
                              const value      = 9;
                              const category  = "YourNoteExperienceInJobs";
                              api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                              /////////----1----////////////
                              setChecklistoneStarExperienceJobs([{ value: 1 }]);
                              /////////----2----////////////
                              setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                              /////////----3----////////////
                              setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                              /////////----4----////////////
                              setChecklistfourStarExperienceJobs([{ value: 1 }]);
                              /////////----5----////////////
                              setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                              /////////----6----////////////
                              setChecklistsixStarExperienceJobs([{ value: 1 }]);
                              /////////----7----////////////
                              setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                              /////////----8----////////////
                              setChecklisteightStarExperienceJobs([{ value: 1 }]);
                              /////////----9----////////////
                              setChecklistnineStarExperienceJobs([{ value: 1 }]);
                          } else {

                          }
                      })
                      .catch(error => {

                      });

                    }

                        //tenStarExperienceJobs

                    }
                    loadterminationchecklistatualizado();

                })
                .catch(error => {
                    ////alert(value)
                    ////alert(value)
                    if (value === '1') {
                        setChecklistoneStarExperienceJobs({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttwoStarExperienceJobs({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarExperienceJobs({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarExperienceJobs({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarExperienceJobs({});

                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsixStarExperienceJobs({});

                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsevenStarExperienceJobs({});

                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }
                    if (value === '2') {
                        ////alert(value)
                        setChecklisttwoStarExperienceJobs({});

                        /////////----1----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=1&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 2 post 1')
                                const value      = 1;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                ///////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistthreeStarExperienceJobs({});

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarExperienceJobs({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarExperienceJobs({});

                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsixStarExperienceJobs({});

                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsevenStarExperienceJobs({});

                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }
                    if (value === '3') {
                        setChecklistthreeStarExperienceJobs({});

                        /////////----2----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=2&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 3 post 2')
                                const value      = 2;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfourStarExperienceJobs({});

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarExperienceJobs({});

                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsixStarExperienceJobs({});

                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsevenStarExperienceJobs({});

                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }
                    if (value === '4') {
                        setChecklistfourStarExperienceJobs({});

                        /////////----3----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=3&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                ////alert('delete 4 post 3')
                                const value      = 3;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);

                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistfiveStarExperienceJobs({});

                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsixStarExperienceJobs({});

                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsevenStarExperienceJobs({});

                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});
                    }
                    if (value === '5') {
                        setChecklistfiveStarExperienceJobs({});


                        /////////----4----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=4&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 5 post 4')
                                const value      = 4;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----4----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsixStarExperienceJobs({});

                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsevenStarExperienceJobs({});

                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }

                    if (value === '6') {
                        setChecklistfiveStarExperienceJobs({});


                        /////////----5----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=5&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 6 post 5')
                                const value      = 5;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----4----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                /////////----5----////////////
                                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistsevenStarExperienceJobs({});

                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }

                    if (value === '7') {
                        setChecklistfiveStarExperienceJobs({});


                        /////////----6----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=6&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 7 post 6')
                                const value      = 6;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----4----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                /////////----5----////////////
                                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                                /////////----6----////////////
                                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----8----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisteightStarExperienceJobs({});

                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }

                    if (value === '8') {
                        setChecklistfiveStarExperienceJobs({});


                        /////////----7----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=7&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 8 post 7')
                                const value      = 7;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----4----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                /////////----5----////////////
                                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                                /////////----6----////////////
                                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                                /////////----7----////////////
                                setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });


                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklistnineStarExperienceJobs({});

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }

                    if (value === '9') {
                        setChecklistfiveStarExperienceJobs({});


                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=8&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 9 post 8')
                                const value      = 8;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                /////////----5----////////////
                                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                                /////////----6----////////////
                                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                                /////////----7----////////////
                                setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                                /////////----8----////////////
                                setChecklisteightStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                        /////////----10----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=10&category=${category}`)
                        .then(response => {
                            const idCheckTerminatorCollaborator = response.data[0]._id;
                            api.delete(`/collaborator-dismissal-interview-checkList-questions/${idCheckTerminatorCollaborator}`)
                        })
                        .catch(error => {
                            //console.log(error)
                        });
                        setChecklisttenStarExperienceJobs({});

                    }

                    if (value === '10') {
                        setChecklistfiveStarExperienceJobs({});


                        /////////----9----////////////
                        api.get(`/collaborator-dismissal-interview-checkList-questions/?id=${id}&value=9&category=${category}`)
                        .then(response => {
                            if(response.data.length === 0) {
                                //alert('delete 10 post 9')
                                const value      = 9;
                                const category  = "YourNoteExperienceInJobs";
                                api.post('/collaborator-dismissal-interview-checkList-questions', { collaborator, value, category })
                                /////////----1----////////////
                                setChecklistoneStarExperienceJobs([{ value: 1 }]);
                                /////////----2----////////////
                                setChecklisttwoStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistthreeStarExperienceJobs([{ value: 1 }]);
                                /////////----3----////////////
                                setChecklistfourStarExperienceJobs([{ value: 1 }]);
                                /////////----5----////////////
                                setChecklistfiveStarExperienceJobs([{ value: 1 }]);
                                /////////----6----////////////
                                setChecklistsixStarExperienceJobs([{ value: 1 }]);
                                /////////----7----////////////
                                setChecklistsevenStarExperienceJobs([{ value: 1 }]);
                                /////////----8----////////////
                                setChecklisteightStarExperienceJobs([{ value: 1 }]);
                                /////////----9---////////////
                                setChecklistnineStarExperienceJobs([{ value: 1 }]);
                            } else {

                            }
                        })
                        .catch(error => {

                        });

                    }

                });
            }
            loadterminationchecklist();
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorDismisalInterview, setCollaboratorDismisalInterview ] = useState([]);

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;


        setCollaboratorDismisalInterview({ ...collaboratorDismisalInterview, [nam]: val })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorDismisalInterviewSaved, setCollaboratorDismisalInterviewSaved ] = useState([]);

    async function loadcollaboratordismissalinterviewid(id) {

        await api.get(`/collaborator-dismissal-interview/${id}`)
        .then(response => {

            setCollaboratorDismisalInterviewSaved(response.data[0]);

        }).catch(error => {})
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);

    async function continuar(e) {
        e.preventDefault();

        const shutdownProcess = 'false';
        const recentShutdown  = 'true';
        const status          = 'Inativo'

        const updateCollaborator = {
            shutdownProcess,
            recentShutdown,
            status
        }
        setLoadContinue(true);
        await api.put(`/collaborator/${id}`, updateCollaborator)
        .then(response => {

            async function deleteVacation() {

                await api.delete(`/collaborator-vacation/${id}`)
                .then(response => {

                    setLoadContinue(false)
                    setCollaboratorDismisalInterview({});
                    // swal({ icon: "success", title: "Sucesso!", text: "Nova entrevista de desligamento criada com sucesso!" });
                    window.location.href = `/colaboradores/desligamento/${id}/finalizado`;

                }).catch(error => {
                    setLoadContinue(true);
                    // swal({ icon: "error", title: "Erro!", text: "Erro ao desligar colaborador, tente novamente mais tarde." });
                    console.log(error)
                });


            }
            deleteVacation();


        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao desligar colaborador, tente novamente mais tarde." });
            console.log(error)
        });

    }


    async function save(event) {
        event.preventDefault();


        if (collaboratorDismisalInterviewSaved === undefined) {
            // alert("entrou1")
            // if(collaboratorDismisalInterview.opinionRegardingSalary === undefined){
            //     setLoadopinionRegardingSalary(true)
            //     return
            // } else {
            //     setLoadopinionRegardingSalary(false)
            // }

            // if(collaboratorDismisalInterview.companyImageAndFeeling === undefined){
            //     setLoadcompanyImageAndFeeling(true)
            //     return
            // } else {
            //     setLoadcompanyImageAndFeeling(false)
            // }

            // if(collaboratorDismisalInterview.nameThreePositivePeople === undefined){
            //     setLoadNameThreePositivePeople(true)
            //     return
            // } else {
            //     setLoadNameThreePositivePeople(false)
            // }

            // if(collaboratorDismisalInterview.whatIsYourAdvice === undefined){
            //     setLoadWhatIsYourAdvice(true)
            //     return
            // } else {
            //     setLoadWhatIsYourAdvice(false)
            // }

            // if(collaboratorDismisalInterview.criticismCommentOrSuggestion === undefined){
            //     setLoadCriticismCommentOrSuggestion(true)
            //     return
            // } else {
            //     setLoadCriticismCommentOrSuggestion(false)
            // }

            if(collaboratorDismisalInterview.opinionRegardingSalary !== undefined &&
                collaboratorDismisalInterview.companyImageAndFeeling !== undefined &&
                collaboratorDismisalInterview.nameThreePositivePeople !== undefined &&
                collaboratorDismisalInterview.whatIsYourAdvice !== undefined &&
                collaboratorDismisalInterview.criticismCommentOrSuggestion !== undefined
            ){

                setLoadContinue(true)

                if(collaboratorDismisalInterview.extraRemarks !== undefined){
                    const opinionRegardingSalary = collaboratorDismisalInterview.opinionRegardingSalary;
                    const companyImageAndFeeling = collaboratorDismisalInterview.companyImageAndFeeling;
                    const nameThreePositivePeople = collaboratorDismisalInterview.nameThreePositivePeople;
                    const whatIsYourAdvice = collaboratorDismisalInterview.whatIsYourAdvice;
                    const criticismCommentOrSuggestion = collaboratorDismisalInterview.criticismCommentOrSuggestion;
                    const collaborator = id;
                    const extraRemarks = collaboratorDismisalInterview.extraRemarks;

                    const formInterview =
                        {
                            opinionRegardingSalary,
                            companyImageAndFeeling,
                            nameThreePositivePeople,
                            whatIsYourAdvice,
                            criticismCommentOrSuggestion,
                            collaborator,
                            extraRemarks
                        }

                    const shutdownProcess = 'false';
                    const recentShutdown  = 'true';
                    const status          = 'Inativo'

                    const updateCollaborator = {
                        shutdownProcess,
                        recentShutdown,
                        status
                    }



                    await api.post('/collaborator-dismissal-interview/', formInterview)
                    .then(response => {
                        setLoadContinue(false)
                        setCollaboratorDismisalInterview({});
                        api.put(`/collaborator/${id}`, updateCollaborator)

                        swal({ icon: "success", title: "Sucesso!", text: "Nova entrevista de desligamento criada com sucesso!" });
                        window.location.href = `/colaboradores/desligamento/${id}/finalizado`;
                    }).catch(error => {
                        setLoadContinue(false);
                        swal({ icon: "error", title: "Erro!", text: "Erro ao criar nova entrevista de desligamento, tente novamente mais tarde." });
                        console.log(error)
                    });

                } else {
                    const opinionRegardingSalary = collaboratorDismisalInterview.opinionRegardingSalary;
                    const companyImageAndFeeling = collaboratorDismisalInterview.companyImageAndFeeling;
                    const nameThreePositivePeople = collaboratorDismisalInterview.nameThreePositivePeople;
                    const whatIsYourAdvice = collaboratorDismisalInterview.whatIsYourAdvice;
                    const criticismCommentOrSuggestion = collaboratorDismisalInterview.criticismCommentOrSuggestion;
                    const collaborator = id;

                    const formInterview =
                        {
                            opinionRegardingSalary,
                            companyImageAndFeeling,
                            nameThreePositivePeople,
                            whatIsYourAdvice,
                            criticismCommentOrSuggestion,
                            collaborator
                        }

                        const shutdownProcess = 'false';
                        const recentShutdown  = 'true';
                        const status          = 'Inativo'

                        const updateCollaborator = {
                            shutdownProcess,
                            recentShutdown,
                            status
                        }

                    await api.post('/collaborator-dismissal-interview/', formInterview)
                    .then(response => {
                        setLoadContinue(false)
                        setCollaboratorDismisalInterview({})
                        api.put(`/collaborator/${id}`, updateCollaborator)
                        swal({ icon: "success", title: "Sucesso!", text: "Nova entrevista de desligamento criada com sucesso!" });
                        window.location.href = `/colaboradores/desligamento/${id}/finalizado`;
                    }).catch(error => {
                        setLoadContinue(false);
                        swal({ icon: "error", title: "Erro!", text: "Erro ao criar nova entrevista de desligamento, tente novamente mais tarde." });
                        // console.log(error)
                    });

                }

            }
            else {
                swal({ icon: "warning", title: "Alerta!", text: "Caso queira realizar a entrevista de desligamento, você deve preencher as questões 9, 10, 11, 12 e 13." });
            }

        } else {


        //    console.log(collaboratorDismisalInterview)

            if(collaboratorDismisalInterview && collaboratorDismisalInterview.length === 0) {

                if(layoffInterviewFileSend && layoffInterviewFileSend === true) {

                    swal({ icon: "success", title: "Sucesso!", text: "Entrevista de desligamento editada com sucesso." })
                    setLayoffInterviewFileSend(false)
                    window.location.href = `/colaboradores/desligamento/${id}/finalizado`;

                } else {

                    swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });

                }

            }
            else {
                if(
                    collaboratorDismisalInterview.opinionRegardingSalary       === "" ||
                    collaboratorDismisalInterview.companyImageAndFeeling       === "" ||
                    collaboratorDismisalInterview.nameThreePositivePeople      === "" ||
                    collaboratorDismisalInterview.whatIsYourAdvice             === "" ||
                    collaboratorDismisalInterview.criticismCommentOrSuggestion === "" ||
                    collaboratorDismisalInterview.extraRemarks                 === ""
                ) {

                    swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser salvo em branco!" });

                }
                else {

        //             // console.log('collaboratorDismisalInterview')
        //             // console.log(collaboratorDismisalInterview)

                    if(collaboratorDismisalInterview.extraRemarks !== undefined){
                        const opinionRegardingSalary       = collaboratorDismisalInterview.opinionRegardingSalary;
                        const companyImageAndFeeling       = collaboratorDismisalInterview.companyImageAndFeeling;
                        const nameThreePositivePeople      = collaboratorDismisalInterview.nameThreePositivePeople;
                        const whatIsYourAdvice             = collaboratorDismisalInterview.whatIsYourAdvice;
                        const criticismCommentOrSuggestion = collaboratorDismisalInterview.criticismCommentOrSuggestion;
                        const collaborator                 = id;
                        const extraRemarks                 = collaboratorDismisalInterview.extraRemarks;

                        const formInterview =
                            {
                                opinionRegardingSalary,
                                companyImageAndFeeling,
                                nameThreePositivePeople,
                                whatIsYourAdvice,
                                criticismCommentOrSuggestion,
                                collaborator,
                                extraRemarks
                            }

                            const shutdownProcess = 'false';
                            const recentShutdown  = 'true';
                            const status          = 'Inativo'

                            const updateCollaborator = {
                                shutdownProcess,
                                recentShutdown,
                                status
                            }

                        setLoadContinue(true)
                        await api.put(`/collaborator-dismissal-interview/${collaboratorDismisalInterviewSaved._id}`, formInterview)
                                .then(response => {
                                    setLoadContinue(false)
                                    setCollaboratorDismisalInterview({ ...collaboratorDismisalInterview })
                                    api.put(`/collaborator/${id}`, updateCollaborator)
                                    swal({ icon: "success", title: "Sucesso!", text: "Entrevista de desligamento editada com sucesso." })
                                    window.location.href = `/colaboradores/desligamento/${id}/finalizado`;
                                }).catch(error => {
                                    swal({ icon: "error", title: "Erro!", text: "Entrevista de desligamento não pode ser alterada!" });
                                    console.log(error)
                                });

                    } else {
                        const opinionRegardingSalary       = collaboratorDismisalInterview.opinionRegardingSalary;
                        const companyImageAndFeeling       = collaboratorDismisalInterview.companyImageAndFeeling;
                        const nameThreePositivePeople      = collaboratorDismisalInterview.nameThreePositivePeople;
                        const whatIsYourAdvice             = collaboratorDismisalInterview.whatIsYourAdvice;
                        const criticismCommentOrSuggestion = collaboratorDismisalInterview.criticismCommentOrSuggestion;
                        const collaborator                 = id;
                        //const extraRemarks = collaboratorDismisalInterview.extraRemarks;

                        const formInterview =
                            {
                                opinionRegardingSalary,
                                companyImageAndFeeling,
                                nameThreePositivePeople,
                                whatIsYourAdvice,
                                criticismCommentOrSuggestion,
                                collaborator
                            }

                            const shutdownProcess = 'false';
                            const recentShutdown  = 'true';
                            const status          = 'Inativo'

                            const updateCollaborator = {
                                shutdownProcess,
                                recentShutdown,
                                status
                            }

                        setLoadContinue(true)
                        await api.put(`/collaborator-dismissal-interview/${collaboratorDismisalInterviewSaved._id}`, formInterview)
                                .then(response => {
                                    setLoadContinue(false)
                                    setCollaboratorDismisalInterview({ ...collaboratorDismisalInterview })
                                    api.put(`/collaborator/${id}`, updateCollaborator)
                                    swal({ icon: "success", title: "Sucesso!", text: "Entrevista de desligamento editada com sucesso." })
                                    window.location.href = `/colaboradores/desligamento/${id}/finalizado`;
                                }).catch(error => {
                                    swal({ icon: "error", title: "Erro!", text: "Entrevista de desligamento não pode ser alterada!" });
                                    // console.log(error)
                                });
                    }

                }

            }

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorShutdownSaved, setCollaboratorShutdownSaved ] = useState([]);

    async function loadcollaboratordismissal(id) {

        await api.get(`/collaborator-dismissal/${id}`)
        .then(response => {

            setCollaboratorShutdownSaved(response.data[0]);

        }).catch(error => {})
    }

    async function excludeAdmssion(event) {
        // console.log(collaboratorShutdownSaved._id)
        event.preventDefault();
          swal({
            title: "Atenção",
            text: "Deseja realmente excluir o processo de admissão deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                formData.append('_id', id);

                if(collaboratorShutdownSaved !== undefined) {

                    await api.delete(`/collaborator-dismissal/${collaboratorShutdownSaved._id}`, formData, config)
                    .then(response => {

                        window.location.href = `/colaboradores/ativos`;
                        swal({ icon: "success", title: "Sucesso!", text: "Desligamneto excluído com sucesso." });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                    });

                }
                else {

                    await api.put(`/collaborator/${id}`, { status: 'Ativo', shutdownProcess: false })
                    .then(response => {

                        window.location.href = `/colaboradores/ativos`;
                        swal({ icon: "success", title: "Sucesso!", text: "Desligamneto excluído com sucesso." });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                    });

                }



            }
        })

    }


    // console.log('oneStarclimate')
    // console.log(oneStarclimate.length)
    // console.log('twoStarclimate')
    // console.log(twoStarclimate)
    // console.log('threeStarclimate')
    // console.log(threeStarclimate)
    // console.log('fourStarclimate')
    // console.log(fourStarclimate)
    // console.log('fiveStarclimate')
    // console.log(fiveStarclimate)

    return (
        <>
        <div className="desligamento-etapa-treis">
            <div className="formulario-fim">
                    <h2 className="titulo">Entrevista com o colaborador</h2>


                    <h4 className="subTitulo">1. Qual(is) o(s) motivo(s) do pedido de desligamento?</h4>


                    <div className="chekbox-perguntas">
                        <ul>
                            <li>
                                {receivedBetterProposal[0] ?
                                (
                                    <>
                                    <input type="checkbox" name="receivedBetterProposal"  onChange={(event) => addReasonsForTheDismissalRequest(event)} id="receivedBetterProposal" checked={true}/>
                                    <label htmlFor="receivedBetterProposal" className="chackboxLabel"></label>
                                    <label htmlFor="receivedBetterProposal" className="foreignTexto">Recebeu proposta melhor</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" name="receivedBetterProposal"  onChange={(event) => addReasonsForTheDismissalRequest(event)} id="receivedBetterProposal" checked={false}/>
                                    <label htmlFor="receivedBetterProposal" className="chackboxLabel"></label>
                                    <label htmlFor="receivedBetterProposal" className="foreignTexto">Recebeu proposta melhor</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {remuneration[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="remuneration" name="remuneration" onChange={(event) => addReasonsForTheDismissalRequest(event)} id="remuneration" checked={true}/>
                                    <label htmlFor="remuneration" className="chackboxLabel"></label>
                                    <label htmlFor="remuneration" className="foreignTexto">Salário</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="remuneration" name="remuneration" onChange={(event) => addReasonsForTheDismissalRequest(event)} id="remuneration" checked={false}/>
                                    <label htmlFor="remuneration" className="chackboxLabel"></label>
                                    <label htmlFor="remuneration" className="foreignTexto">Salário</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {demotivation[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="demotivation" name="demotivation" onChange={(event) => addReasonsForTheDismissalRequest(event)} id="demotivation" checked={true}/>
                                    <label htmlFor="demotivation" className="chackboxLabel"></label>
                                    <label htmlFor="demotivation" className="foreignTexto">Desmotivação</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="demotivation" name="demotivation" onChange={(event) => addReasonsForTheDismissalRequest(event)} id="demotivation" checked={false} />
                                    <label htmlFor="demotivation" className="chackboxLabel"></label>
                                    <label htmlFor="demotivation" className="foreignTexto">Desmotivação</label>
                                    </>
                                )
                                }

                            </li>
                            <li>
                                {lossOfPurpose[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="lossOfPurpose" name="lossOfPurpose" onChange={(event) => addReasonsForTheDismissalRequest(event)} id="lossOfPurpose" checked={true}/>
                                    <label htmlFor="lossOfPurpose" className="chackboxLabel"></label>
                                    <label htmlFor="lossOfPurpose" className="foreignTexto">Perda de propósito</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="lossOfPurpose" name="lossOfPurpose" onChange={(event) => addReasonsForTheDismissalRequest(event)} id="lossOfPurpose" checked={false} />
                                    <label htmlFor="lossOfPurpose" className="chackboxLabel"></label>
                                    <label htmlFor="lossOfPurpose" className="foreignTexto">Perda de propósito</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {lackOfChallenges[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="lackOfChallenges" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="lackOfChallenges" id="lackOfChallenges" checked={true}/>
                                    <label htmlFor="lackOfChallenges" className="chackboxLabel"></label>
                                    <label htmlFor="lackOfChallenges" className="foreignTexto">Falta de desafios</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="lackOfChallenges" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="lackOfChallenges" id="lackOfChallenges" checked={false}/>
                                    <label htmlFor="lackOfChallenges" className="chackboxLabel"></label>
                                    <label htmlFor="lackOfChallenges" className="foreignTexto">Falta de desafios</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {missingOfFutureVision[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="missingOfFutureVision" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="missingOfFutureVision" id="missingOfFutureVision"  checked={true}/>
                                    <label htmlFor="missingOfFutureVision" className="chackboxLabel"></label>
                                    <label htmlFor="missingOfFutureVision" className="foreignTexto">Falta de visão de futuro</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="missingOfFutureVision" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="missingOfFutureVision" id="missingOfFutureVision" checked={false} />
                                    <label htmlFor="missingOfFutureVision" className="chackboxLabel"></label>
                                    <label htmlFor="missingOfFutureVision" className="foreignTexto">Falta de visão de futuro</label>
                                    </>
                                )
                                }

                            </li>
                            <li>
                                {misManagementOrLeadership[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="misManagementOrLeadership" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="misManagementOrLeadership" id="misManagementOrLeadership" checked={true}/>
                                    <label htmlFor="misManagementOrLeadership" className="chackboxLabel"></label>
                                    <label htmlFor="misManagementOrLeadership" className="foreignTexto">Má gestão/liderança</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="misManagementOrLeadership" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="misManagementOrLeadership" id="misManagementOrLeadership" checked={false}/>
                                    <label htmlFor="misManagementOrLeadership" className="chackboxLabel"></label>
                                    <label htmlFor="misManagementOrLeadership" className="foreignTexto">Má gestão/liderança</label>
                                    </>
                                )
                                }

                            </li>
                            <li>
                                {relationshipWithManager[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="relationshipWithManager" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="relationshipWithManager" id="relationshipWithManager" checked={true}/>
                                    <label htmlFor="relationshipWithManager" className="chackboxLabel"></label>
                                    <label htmlFor="relationshipWithManager" className="foreignTexto">Relacionamento com gestor</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="relationshipWithManager" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="relationshipWithManager" id="relationshipWithManager" checked={false}/>
                                    <label htmlFor="relationshipWithManager" className="chackboxLabel"></label>
                                    <label htmlFor="relationshipWithManager" className="foreignTexto">Relacionamento com gestor</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {relationshipWithColleagues[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="relationshipWithColleagues" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="relationshipWithColleagues" id="relationshipWithColleagues" checked={true}/>
                                    <label htmlFor="relationshipWithColleagues" className="chackboxLabel"></label>
                                    <label htmlFor="relationshipWithColleagues" className="foreignTexto">Relacionamento com colegas</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="relationshipWithColleagues" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="relationshipWithColleagues" id="relationshipWithColleagues" checked={false} />
                                    <label htmlFor="relationshipWithColleagues" className="chackboxLabel"></label>
                                    <label htmlFor="relationshipWithColleagues" className="foreignTexto">Relacionamento com colegas</label>
                                    </>
                                )
                                }

                            </li>
                            <li>
                                {culturalMisalignment[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="culturalMisalignment" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="culturalMisalignment" id="culturalMisalignment" checked={true}/>
                                    <label htmlFor="culturalMisalignment" className="chackboxLabel"></label>
                                    <label htmlFor="culturalMisalignment" className="foreignTexto">Desalinhamento de cultura</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="culturalMisalignment" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="culturalMisalignment" id="culturalMisalignment" checked={false}/>
                                    <label htmlFor="culturalMisalignment" className="chackboxLabel"></label>
                                    <label htmlFor="culturalMisalignment" className="foreignTexto">Desalinhamento de cultura</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {bestBenefits[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="bestBenefits" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="bestBenefits" id="bestBenefits" checked={true}/>
                                    <label htmlFor="bestBenefits" className="chackboxLabel"></label>
                                    <label htmlFor="bestBenefits" className="foreignTexto">Busca por benefícios melhores</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="bestBenefits" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="bestBenefits" id="bestBenefits" checked={false} />
                                    <label htmlFor="bestBenefits" className="chackboxLabel"></label>
                                    <label htmlFor="bestBenefits" className="foreignTexto">Busca por benefícios melhores</label>
                                    </>
                                )
                                }

                            </li>
                            <li>
                                {careerChange[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="careerChange" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="careerChange" id="careerChange" checked={true}/>
                                    <label htmlFor="careerChange" className="chackboxLabel"></label>
                                    <label htmlFor="careerChange" className="foreignTexto">Mudança de carreira</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="careerChange" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="careerChange" id="careerChange" checked={false} />
                                    <label htmlFor="careerChange" className="chackboxLabel"></label>
                                    <label htmlFor="careerChange" className="foreignTexto">Mudança de carreira</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {changOfPosition[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="changOfPosition" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="changOfPosition" id="changOfPosition" checked={true}/>
                                    <label htmlFor="changOfPosition" className="chackboxLabel"></label>
                                    <label htmlFor="changOfPosition" className="foreignTexto">Busca por outra função de atuação</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="changOfPosition" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="changOfPosition" id="changOfPosition" checked={false} />
                                    <label htmlFor="changOfPosition" className="chackboxLabel"></label>
                                    <label htmlFor="changOfPosition" className="foreignTexto">Busca por outra função de atuação</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {personalReasons[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="personalReasons" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="personalReasons" id="personalReasons" checked={true}/>
                                    <label htmlFor="personalReasons" className="chackboxLabel"></label>
                                    <label htmlFor="personalReasons" className="foreignTexto">Motivos pessoais</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="personalReasons" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="personalReasons" id="personalReasons" checked={false} />
                                    <label htmlFor="personalReasons" className="chackboxLabel"></label>
                                    <label htmlFor="personalReasons" className="foreignTexto">Motivos pessoais</label>
                                    </>
                                )
                                }
                            </li>
                            <li>
                                {others[0] ?
                                (
                                    <>
                                    <input type="checkbox" value="others" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="others" id="others" checked={true}/>
                                    <label htmlFor="others" className="chackboxLabel"></label>
                                    <label htmlFor="others" className="foreignTexto">Outros</label>
                                    </>
                                ) :
                                (
                                    <>
                                    <input type="checkbox" value="others" onChange={(event) => addReasonsForTheDismissalRequest(event)} name="others" id="others" checked={false} />
                                    <label htmlFor="others" className="chackboxLabel"></label>
                                    <label htmlFor="others" className="foreignTexto">Outros</label>
                                    </>
                                )
                                }
                            </li>
                        </ul>

                        <h4 className="subTitulo">2. O que você faria para melhorar as questões que causaram sua saída?</h4>
                        <div className="chekbox-perguntas">
                            <ul>
                                <li>
                                    {salaryIncrease[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="salaryIncrease" onChange={(event) => whatWouldYouDoToImprove(event)} name="salaryIncrease" id="salaryIncrease" checked={true}/>
                                        <label htmlFor="salaryIncrease" className="chackboxLabel"></label>
                                        <label htmlFor="salaryIncrease" className="foreignTexto">Aumento salarial</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="salaryIncrease" onChange={(event) => whatWouldYouDoToImprove(event)} name="salaryIncrease" id="salaryIncrease" checked={false} />
                                        <label htmlFor="salaryIncrease" className="chackboxLabel"></label>
                                        <label htmlFor="salaryIncrease" className="foreignTexto">Aumento salarial</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {jobPromotion[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="jobPromotion" onChange={(event) => whatWouldYouDoToImprove(event)} name="jobPromotion" id="jobPromotion" checked={true}/>
                                        <label htmlFor="jobPromotion" className="chackboxLabel"></label>
                                        <label htmlFor="jobPromotion" className="foreignTexto">Promoção de cargo</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="jobPromotion" onChange={(event) => whatWouldYouDoToImprove(event)} name="jobPromotion" id="jobPromotion" checked={false}/>
                                        <label htmlFor="jobPromotion" className="chackboxLabel"></label>
                                        <label htmlFor="jobPromotion" className="foreignTexto">Promoção de cargo</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {strengtheningPurpose[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="strengtheningPurpose" onChange={(event) => whatWouldYouDoToImprove(event)} name="strengtheningPurpose" id="strengtheningPurpose" checked={true}/>
                                        <label htmlFor="strengtheningPurpose" className="chackboxLabel"></label>
                                        <label htmlFor="strengtheningPurpose" className="foreignTexto">Fortalecimento de propósito</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="strengtheningPurpose" onChange={(event) => whatWouldYouDoToImprove(event)} name="strengtheningPurpose" id="strengtheningPurpose" checked={false}/>
                                        <label htmlFor="strengtheningPurpose" className="chackboxLabel"></label>
                                        <label htmlFor="strengtheningPurpose" className="foreignTexto">Fortalecimento de propósito</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {newChallenges[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="newChallenges" onChange={(event) => whatWouldYouDoToImprove(event)} name="newChallenges" id="newChallenges" checked={true}/>
                                        <label htmlFor="newChallenges" className="chackboxLabel"></label>
                                        <label htmlFor="newChallenges" className="foreignTexto">Novos desafios</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="newChallenges" onChange={(event) => whatWouldYouDoToImprove(event)} name="newChallenges" id="newChallenges" checked={false}/>
                                        <label htmlFor="newChallenges" className="chackboxLabel"></label>
                                        <label htmlFor="newChallenges" className="foreignTexto">Novos desafios</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {haveAClearFutureVision[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="haveAClearFutureVision" onChange={(event) => whatWouldYouDoToImprove(event)} name="haveAClearFutureVision" id="haveAClearFutureVision" checked={true}/>
                                        <label htmlFor="haveAClearFutureVision" className="chackboxLabel"></label>
                                        <label htmlFor="haveAClearFutureVision" className="foreignTexto">Ter visão de futuro mais clara</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="haveAClearFutureVision" onChange={(event) => whatWouldYouDoToImprove(event)} name="haveAClearFutureVision" id="haveAClearFutureVision" checked={false}/>
                                        <label htmlFor="haveAClearFutureVision" className="chackboxLabel"></label>
                                        <label htmlFor="haveAClearFutureVision" className="foreignTexto">Ter visão de futuro mais clara</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {managerChange[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="managerChange" onChange={(event) => whatWouldYouDoToImprove(event)} name="managerChange" id="managerChange" checked={true}/>
                                        <label htmlFor="managerChange" className="chackboxLabel"></label>
                                        <label htmlFor="managerChange" className="foreignTexto">Mudança de gestor</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="managerChange" onChange={(event) => whatWouldYouDoToImprove(event)} name="managerChange" id="managerChange" checked={false} />
                                        <label htmlFor="managerChange" className="chackboxLabel"></label>
                                        <label htmlFor="managerChange" className="foreignTexto">Mudança de gestor</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {managementImprovements[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="managementImprovements" onChange={(event) => whatWouldYouDoToImprove(event)} name="managementImprovements" id="managementImprovements" checked={true}/>
                                        <label htmlFor="managementImprovements" className="chackboxLabel"></label>
                                        <label htmlFor="managementImprovements" className="foreignTexto">Melhorias na gestão</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="managementImprovements" onChange={(event) => whatWouldYouDoToImprove(event)} name="managementImprovements" id="managementImprovements" checked={false} />
                                        <label htmlFor="managementImprovements" className="chackboxLabel"></label>
                                        <label htmlFor="managementImprovements" className="foreignTexto">Melhorias na gestão</label>
                                        </>
                                    )}

                                </li>
                                <li>
                                    {cultureAlignment[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="cultureAlignment" onChange={(event) => whatWouldYouDoToImprove(event)} name="cultureAlignment" id="cultureAlignment" checked={true}/>
                                        <label htmlFor="cultureAlignment" className="chackboxLabel"></label>
                                        <label htmlFor="cultureAlignment" className="foreignTexto">Alinhamento de cultura</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="cultureAlignment" onChange={(event) => whatWouldYouDoToImprove(event)} name="cultureAlignment" id="cultureAlignment" checked={false} />
                                        <label htmlFor="cultureAlignment" className="chackboxLabel"></label>
                                        <label htmlFor="cultureAlignment" className="foreignTexto">Alinhamento de cultura</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {moreBenefits[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="moreBenefits" onChange={(event) => whatWouldYouDoToImprove(event)} name="moreBenefits" id="moreBenefits" checked={true}/>
                                        <label htmlFor="moreBenefits" className="chackboxLabel"></label>
                                        <label htmlFor="moreBenefits" className="foreignTexto">Mais benefícios</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="moreBenefits" onChange={(event) => whatWouldYouDoToImprove(event)} name="moreBenefits" id="moreBenefits" checked={false} />
                                        <label htmlFor="moreBenefits" className="chackboxLabel"></label>
                                        <label htmlFor="moreBenefits" className="foreignTexto">Mais benefícios</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {functionChange[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="functionChange" onChange={(event) => whatWouldYouDoToImprove(event)} name="functionChange" id="functionChange" checked={true}/>
                                        <label htmlFor="functionChange" className="chackboxLabel"></label>
                                        <label htmlFor="functionChange" className="foreignTexto">Mudança de função</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="functionChange" onChange={(event) => whatWouldYouDoToImprove(event)} name="functionChange" id="functionChange" checked={false} />
                                        <label htmlFor="functionChange" className="chackboxLabel"></label>
                                        <label htmlFor="functionChange" className="foreignTexto">Mudança de função</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {feedbackCulture[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="feedbackCulture" onChange={(event) => whatWouldYouDoToImprove(event)} name="feedbackCulture" id="feedbackCulture" checked={true}/>
                                        <label htmlFor="feedbackCulture" className="chackboxLabel"></label>
                                        <label htmlFor="feedbackCulture" className="foreignTexto">Cultura de feedback</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="feedbackCulture" onChange={(event) => whatWouldYouDoToImprove(event)} name="feedbackCulture" id="feedbackCulture" checked={false} />
                                        <label htmlFor="feedbackCulture" className="chackboxLabel"></label>
                                        <label htmlFor="feedbackCulture" className="foreignTexto">Cultura de feedback</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {otherImprovements[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="otherImprovements" onChange={(event) => whatWouldYouDoToImprove(event)} name="otherImprovements" id="otherImprovements" checked={true} />
                                        <label htmlFor="otherImprovements" className="chackboxLabel"></label>
                                        <label htmlFor="otherImprovements" className="foreignTexto">Outros</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="otherImprovements" onChange={(event) => whatWouldYouDoToImprove(event)} name="otherImprovements" id="otherImprovements" checked={false} />
                                        <label htmlFor="otherImprovements" className="chackboxLabel"></label>
                                        <label htmlFor="otherImprovements" className="foreignTexto">Outros</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {anything[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="anything" onChange={(event) => whatWouldYouDoToImprove(event)} name="anything" id="anything" checked={true} />
                                        <label htmlFor="anything" className="chackboxLabel"></label>
                                        <label htmlFor="anything" className="foreignTexto">Nada</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="anything" onChange={(event) => whatWouldYouDoToImprove(event)} name="anything" id="anything" checked={false} />
                                        <label htmlFor="anything" className="chackboxLabel"></label>
                                        <label htmlFor="anything" className="foreignTexto">Nada</label>
                                        </>
                                    )
                                    }

                                </li>
                            </ul>
                        </div>

                        <h4 className="subTitulo">3. Em sua opinião, quais pontos a empresa precisa melhorar? </h4>
                        <div className="chekbox-perguntas">
                            <ul>
                                <li>
                                    {direction[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="direction" onChange={(event) => workplaceClimateAssessment(event)} name="direction" id="direction" checked={true} />
                                        <label htmlFor="direction" className="chackboxLabel"></label>
                                        <label htmlFor="direction" className="foreignTexto">Diretoria</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="direction" onChange={(event) => workplaceClimateAssessment(event)} name="direction" id="direction" checked={false} />
                                        <label htmlFor="direction" className="chackboxLabel"></label>
                                        <label htmlFor="direction" className="foreignTexto">Diretoria</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {rh[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="rh" onChange={(event) => workplaceClimateAssessment(event)} name="rh" id="rh" checked={true}/>
                                        <label htmlFor="rh" className="chackboxLabel"></label>
                                        <label htmlFor="rh" className="foreignTexto">RH</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="rh" onChange={(event) => workplaceClimateAssessment(event)} name="rh" id="rh" checked={false} />
                                        <label htmlFor="rh" className="chackboxLabel"></label>
                                        <label htmlFor="rh" className="foreignTexto">RH</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {managers[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="managers" onChange={(event) => workplaceClimateAssessment(event)} name="managers" id="managers" checked={true}/>
                                        <label htmlFor="managers" className="chackboxLabel"></label>
                                        <label htmlFor="managers" className="foreignTexto">Gestores</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="managers" onChange={(event) => workplaceClimateAssessment(event)} name="managers" id="managers" checked={false} />
                                        <label htmlFor="managers" className="chackboxLabel"></label>
                                        <label htmlFor="managers" className="foreignTexto">Gestores</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {leaders[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="leaders" onChange={(event) => workplaceClimateAssessment(event)} name="leaders" id="leaders" checked={true}/>
                                        <label htmlFor="leaders" className="chackboxLabel"></label>
                                        <label htmlFor="leaders" className="foreignTexto">Liderança</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="leaders" onChange={(event) => workplaceClimateAssessment(event)} name="leaders" id="leaders" checked={false} />
                                        <label htmlFor="leaders" className="chackboxLabel"></label>
                                        <label htmlFor="leaders" className="foreignTexto">Liderança</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {climate[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="climate" onChange={(event) => workplaceClimateAssessment(event)} name="climate" id="climate" checked={true}/>
                                        <label htmlFor="climate" className="chackboxLabel"></label>
                                        <label htmlFor="climate" className="foreignTexto">Clima</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="climate" onChange={(event) => workplaceClimateAssessment(event)} name="climate" id="climate"  checked={false}/>
                                        <label htmlFor="climate" className="chackboxLabel"></label>
                                        <label htmlFor="climate" className="foreignTexto">Clima</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {culture[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="culture" onChange={(event) => workplaceClimateAssessment(event)} name="culture" id="culture" checked={true}/>
                                        <label htmlFor="culture" className="chackboxLabel"></label>
                                        <label htmlFor="culture" className="foreignTexto">Cultura</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="culture" onChange={(event) => workplaceClimateAssessment(event)} name="culture" id="culture" checked={false} />
                                        <label htmlFor="culture" className="chackboxLabel"></label>
                                        <label htmlFor="culture" className="foreignTexto">Cultura</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {salaryNeedsToBeImproved[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="salaryNeedsToBeImproved" onChange={(event) => workplaceClimateAssessment(event)} name="salaryNeedsToBeImproved" id="salaryNeedsToBeImproved" checked={true}/>
                                        <label htmlFor="salaryNeedsToBeImproved" className="chackboxLabel"></label>
                                        <label htmlFor="salaryNeedsToBeImproved" className="foreignTexto">Salário</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="salaryNeedsToBeImproved" onChange={(event) => workplaceClimateAssessment(event)} name="salaryNeedsToBeImproved" id="salaryNeedsToBeImproved" checked={false}/>
                                        <label htmlFor="salaryNeedsToBeImproved" className="chackboxLabel"></label>
                                        <label htmlFor="salaryNeedsToBeImproved" className="foreignTexto">Salário</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {benefits[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="benefits" onChange={(event) => workplaceClimateAssessment(event)} name="benefits" id="benefits" checked={true}/>
                                        <label htmlFor="benefits" className="chackboxLabel"></label>
                                        <label htmlFor="benefits" className="foreignTexto">Benefícios</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="benefits" onChange={(event) => workplaceClimateAssessment(event)} name="benefits" id="benefits" checked={false}/>
                                        <label htmlFor="benefits" className="chackboxLabel"></label>
                                        <label htmlFor="benefits" className="foreignTexto">Benefícios</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {structure[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="structure" onChange={(event) => workplaceClimateAssessment(event)} name="structure" id="structure" checked={true}/>
                                        <label htmlFor="structure" className="chackboxLabel"></label>
                                        <label htmlFor="structure" className="foreignTexto">Estrutura</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="structure" onChange={(event) => workplaceClimateAssessment(event)} name="structure" id="structure" checked={false}/>
                                        <label htmlFor="structure" className="chackboxLabel"></label>
                                        <label htmlFor="structure" className="foreignTexto">Estrutura</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {process[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="process" onChange={(event) => workplaceClimateAssessment(event)} name="process" id="process" checked={true}/>
                                        <label htmlFor="process" className="chackboxLabel"></label>
                                        <label htmlFor="process" className="foreignTexto">Processos</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="process" onChange={(event) => workplaceClimateAssessment(event)} name="process" id="process" checked={false}/>
                                        <label htmlFor="process" className="chackboxLabel"></label>
                                        <label htmlFor="process" className="foreignTexto">Processos</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {opportunities[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="opportunities" onChange={(event) => workplaceClimateAssessment(event)} name="opportunities" id="opportunities" checked={true}/>
                                        <label htmlFor="opportunities" className="chackboxLabel"></label>
                                        <label htmlFor="opportunities" className="foreignTexto">Oportunidades</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="opportunities" onChange={(event) => workplaceClimateAssessment(event)} name="opportunities" id="opportunities" checked={false}/>
                                        <label htmlFor="opportunities" className="chackboxLabel"></label>
                                        <label htmlFor="opportunities" className="foreignTexto">Oportunidades</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {operation[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="operation" onChange={(event) => workplaceClimateAssessment(event)} name="operation" id="operation" checked={true}/>
                                        <label htmlFor="operation" className="chackboxLabel"></label>
                                        <label htmlFor="operation" className="foreignTexto">Operação</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="operation" onChange={(event) => workplaceClimateAssessment(event)} name="operation" id="operation" checked={false}/>
                                        <label htmlFor="operation" className="chackboxLabel"></label>
                                        <label htmlFor="operation" className="foreignTexto">Operação</label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {internalCommunication[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="internalCommunication" onChange={(event) => workplaceClimateAssessment(event)} name="internalCommunication" id="internalCommunication" checked={true}/>
                                        <label htmlFor="internalCommunication" className="chackboxLabel"></label>
                                        <label htmlFor="internalCommunication" className="foreignTexto">Comunicação Interna</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="internalCommunication" onChange={(event) => workplaceClimateAssessment(event)} name="internalCommunication" id="internalCommunication" checked={false}/>
                                        <label htmlFor="internalCommunication" className="chackboxLabel"></label>
                                        <label htmlFor="internalCommunication" className="foreignTexto">Comunicação Interna</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {journeyModel[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="journeyModel" onChange={(event) => workplaceClimateAssessment(event)} name="journeyModel" id="journeyModel" checked={true}/>
                                        <label htmlFor="journeyModel" className="chackboxLabel"></label>
                                        <label htmlFor="journeyModel" className="foreignTexto">Modelo de jornada</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="journeyModel" onChange={(event) => workplaceClimateAssessment(event)} name="journeyModel" id="journeyModel" checked={false}/>
                                        <label htmlFor="journeyModel" className="chackboxLabel"></label>
                                        <label htmlFor="journeyModel" className="foreignTexto">Modelo de jornada</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {workModel[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="workModel" onChange={(event) => workplaceClimateAssessment(event)} name="workModel" id="workModel" checked={true}/>
                                        <label htmlFor="workModel" className="chackboxLabel"></label>
                                        <label htmlFor="workModel" className="foreignTexto">Modelo de trabalho</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="workModel" onChange={(event) => workplaceClimateAssessment(event)} name="workModel" id="workModel" checked={false}/>
                                        <label htmlFor="workModel" className="chackboxLabel"></label>
                                        <label htmlFor="workModel" className="foreignTexto">Modelo de trabalho</label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {feedbacks[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="feedbacks" onChange={(event) => workplaceClimateAssessment(event)} name="feedbacks" id="feedbacks" checked={true} />
                                        <label htmlFor="feedbacks" className="chackboxLabel"></label>
                                        <label htmlFor="feedbacks" className="foreignTexto">Feedbacks</label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="feedbacks" onChange={(event) => workplaceClimateAssessment(event)} name="feedbacks" id="feedbacks" checked={false}/>
                                        <label htmlFor="feedbacks" className="chackboxLabel"></label>
                                        <label htmlFor="feedbacks" className="foreignTexto">Feedbacks</label>
                                        </>
                                    )
                                    }
                                </li>
                            </ul>
                        </div>

                        <h4 className="subTitulo">4. Como avalia o ambiente de trabalho? Entre clima e estrutura. </h4>
                        <div className="avaliacao-estrelha avalia-ambiente-trabalho">
                            <div className="clima">
                                <p>Clima</p>
                                <ul>
                                    <li>
                                        {oneStarclimate[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="1" onChange={(event) => workClimateAssessment(event)} name="oneStarclimate" id="oneStarclimate" checked={true}/>
                                            <label htmlFor="oneStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="1" onChange={(event) => workClimateAssessment(event)} name="oneStarclimate" id="oneStarclimate" checked={false}/>
                                            <label htmlFor="oneStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }
                                    </li>
                                    <li>
                                        {twoStarclimate[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="2" onChange={(event) => workClimateAssessment(event)} name="twoStarclimate" id="twoStarclimate" checked={true}/>
                                            <label htmlFor="twoStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="2" onChange={(event) => workClimateAssessment(event)} name="twoStarclimate" id="twoStarclimate" checked={false}/>
                                            <label htmlFor="twoStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }
                                    </li>
                                    <li>
                                        {threeStarclimate[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="3" onChange={(event) => workClimateAssessment(event)} name="threeStarclimate" id="threeStarclimate" checked={true}/>
                                            <label htmlFor="threeStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="3" onChange={(event) => workClimateAssessment(event)} name="threeStarclimate" id="threeStarclimate" checked={false}/>
                                            <label htmlFor="threeStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }

                                    </li>
                                    <li>
                                        {fourStarclimate[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="4" onChange={(event) => workClimateAssessment(event)} name="fourStarclimate" id="fourStarclimate" checked={true}/>
                                            <label htmlFor="fourStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="4" onChange={(event) => workClimateAssessment(event)} name="fourStarclimate" id="fourStarclimate" checked={false}/>
                                            <label htmlFor="fourStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }
                                    </li>
                                    <li>
                                        {fiveStarclimate[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="5" onChange={(event) => workClimateAssessment(event)} name="fiveStarclimate" id="fiveStarclimate" checked={true}/>
                                            <label htmlFor="fiveStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="5" onChange={(event) => workClimateAssessment(event)} name="fiveStarclimate" id="fiveStarclimate" checked={false}/>
                                            <label htmlFor="fiveStarclimate" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }

                                    </li>
                                </ul>
                            </div>
                            <div className="estrutura">
                                <p>Estrutura</p>
                                <ul>
                                    <li>
                                        {oneStarStructure[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="1" onChange={(event) => structureClimateAssessment(event)} name="oneStarStructure" id="oneStarStructure" checked={true}/>
                                            <label htmlFor="oneStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="1" onChange={(event) => structureClimateAssessment(event)} name="oneStarStructure" id="oneStarStructure" checked={false}/>
                                            <label htmlFor="oneStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }

                                    </li>
                                    <li>
                                        {twoStarStructure[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="2" onChange={(event) => structureClimateAssessment(event)} name="twoStarStructure" id="twoStarStructure" checked={true}/>
                                            <label htmlFor="twoStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="2" onChange={(event) => structureClimateAssessment(event)} name="twoStarStructure" id="twoStarStructure" checked={false}/>
                                            <label htmlFor="twoStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }

                                    </li>
                                    <li>
                                        {threeStarStructure[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="3" onChange={(event) => structureClimateAssessment(event)} name="threeStarStructure" id="threeStarStructure" checked={true}/>
                                            <label htmlFor="threeStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="3" onChange={(event) => structureClimateAssessment(event)} name="threeStarStructure" id="threeStarStructure" checked={false}/>
                                            <label htmlFor="threeStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }

                                    </li>
                                    <li>
                                        {fourStarStructure[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="4" onChange={(event) => structureClimateAssessment(event)} name="fourStarStructure" id="fourStarStructure" checked={true}/>
                                            <label htmlFor="fourStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="4" onChange={(event) => structureClimateAssessment(event)} name="fourStarStructure" id="fourStarStructure" checked={false}/>
                                            <label htmlFor="fourStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }

                                    </li>
                                    <li>
                                        {fiveStarStructure[0] ?
                                        (
                                            <>
                                            <input type="checkbox" value="5" onChange={(event) => structureClimateAssessment(event)} name="fiveStarStructure" id="fiveStarStructure" checked={true}/>
                                            <label htmlFor="fiveStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        ) :
                                        (
                                            <>
                                            <input type="checkbox" value="5" onChange={(event) => structureClimateAssessment(event)} name="fiveStarStructure" id="fiveStarStructure" checked={false}/>
                                            <label htmlFor="fiveStarStructure" className="chackboxEstrelha"></label>
                                            </>
                                        )
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <h4 className="subTitulo">5. A empresa ofereceu oportunidades para o seu crescimento e desenvolvimento?</h4>
                        <div className="avaliacao-estrelha">
                            <ul>
                                <li>
                                    {oneStarOpportunity[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => opportunitiesForGrowth(event)} name="oneStarOpportunity" id="oneStarOpportunity" checked={true}/>
                                        <label htmlFor="oneStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => opportunitiesForGrowth(event)} name="oneStarOpportunity" id="oneStarOpportunity" checked={false}/>
                                        <label htmlFor="oneStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {twoStarOpportunity[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => opportunitiesForGrowth(event)} name="twoStarOpportunity" id="twoStarOpportunity" checked={true}/>
                                        <label htmlFor="twoStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => opportunitiesForGrowth(event)} name="twoStarOpportunity" id="twoStarOpportunity" checked={false}/>
                                        <label htmlFor="twoStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {threeStarOpportunity[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => opportunitiesForGrowth(event)} name="threeStarOpportunity" id="threeStarOpportunity" checked={true}/>
                                        <label htmlFor="threeStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => opportunitiesForGrowth(event)} name="threeStarOpportunity" id="threeStarOpportunity" checked={false}/>
                                        <label htmlFor="threeStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {fourStarOpportunity[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => opportunitiesForGrowth(event)} name="fourStarOpportunity" id="fourStarOpportunity" checked={true}/>
                                        <label htmlFor="fourStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => opportunitiesForGrowth(event)} name="fourStarOpportunity" id="fourStarOpportunity" checked={false}/>
                                        <label htmlFor="fourStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {fiveStarOpportunity[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => opportunitiesForGrowth(event)} name="fiveStarOpportunity" id="fiveStarOpportunity" checked={true}/>
                                        <label htmlFor="fiveStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => opportunitiesForGrowth(event)} name="fiveStarOpportunity" id="fiveStarOpportunity" checked={false}/>
                                        <label htmlFor="fiveStarOpportunity" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }

                                </li>
                            </ul>
                        </div>

                        <h4 className="subTitulo">6. Como foi o seu relacionamento com os gestores e líderes? </h4>
                        <div className="avaliacao-estrelha">
                            <ul>
                                <li>
                                    {oneStarManagersRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => howWasYourRelationship(event)} name="oneStarManagersRelationship" id="oneStarManagersRelationship" checked={true}/>
                                        <label htmlFor="oneStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => howWasYourRelationship(event)} name="oneStarManagersRelationship" id="oneStarManagersRelationship" checked={false}/>
                                        <label htmlFor="oneStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {twoStarManagersRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => howWasYourRelationship(event)} name="twoStarManagersRelationship" id="twoStarManagersRelationship" checked={true}/>
                                        <label htmlFor="twoStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => howWasYourRelationship(event)} name="twoStarManagersRelationship" id="twoStarManagersRelationship" checked={false}/>
                                        <label htmlFor="twoStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {threeStarManagersRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => howWasYourRelationship(event)} name="threeStarManagersRelationship" id="threeStarManagersRelationship" checked={true}/>
                                        <label htmlFor="threeStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => howWasYourRelationship(event)} name="threeStarManagersRelationship" id="threeStarManagersRelationship" checked={false}/>
                                        <label htmlFor="threeStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {fourStarManagersRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => howWasYourRelationship(event)} name="fourStarManagersRelationship" id="fourStarManagersRelationship" checked={true}/>
                                        <label htmlFor="fourStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => howWasYourRelationship(event)} name="fourStarManagersRelationship" id="fourStarManagersRelationship" checked={false}/>
                                        <label htmlFor="fourStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {fiveStarManagersRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => howWasYourRelationship(event)} name="fiveStarManagersRelationship" id="fiveStarManagersRelationship" checked={true}/>
                                        <label htmlFor="fiveStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => howWasYourRelationship(event)} name="fiveStarManagersRelationship" id="fiveStarManagersRelationship" checked={false}/>
                                        <label htmlFor="fiveStarManagersRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                            </ul>
                        </div>

                        <h4 className="subTitulo">7. Como foi seu relacionamento com os colegas de equipe?</h4>
                        <div className="avaliacao-estrelha">
                            <ul>
                                <li>
                                    {oneStarTeamRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => relationshipWithTheTeam(event)} name="oneStarTeamRelationship" id="oneStarTeamRelationship" checked={true}/>
                                        <label htmlFor="oneStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => relationshipWithTheTeam(event)} name="oneStarTeamRelationship" id="oneStarTeamRelationship" checked={false}/>
                                        <label htmlFor="oneStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {twoStarTeamRatio[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => relationshipWithTheTeam(event)} name="twoStarTeamRatio" id="twoStarTeamRatio" checked={true}/>
                                        <label htmlFor="twoStarTeamRatio" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => relationshipWithTheTeam(event)} name="twoStarTeamRatio" id="twoStarTeamRatio" checked={false}/>
                                        <label htmlFor="twoStarTeamRatio" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {threeStarTeamRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => relationshipWithTheTeam(event)} name="threeStarTeamRelationship" id="threeStarTeamRelationship" checked={true}/>
                                        <label htmlFor="threeStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => relationshipWithTheTeam(event)} name="threeStarTeamRelationship" id="threeStarTeamRelationship" checked={false}/>
                                        <label htmlFor="threeStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }

                                </li>
                                <li>
                                    {fourStarTeamRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => relationshipWithTheTeam(event)} name="fourStarTeamRelationship" id="fourStarTeamRelationship" checked={true}/>
                                        <label htmlFor="fourStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => relationshipWithTheTeam(event)} name="fourStarTeamRelationship" id="fourStarTeamRelationship" checked={false}/>
                                        <label htmlFor="fourStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {fiveStarTeamRelationship[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => relationshipWithTheTeam(event)} name="fiveStarTeamRelationship" id="fiveStarTeamRelationship" checked={true}/>
                                        <label htmlFor="fiveStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => relationshipWithTheTeam(event)} name="fiveStarTeamRelationship" id="fiveStarTeamRelationship" checked={false}/>
                                        <label htmlFor="fiveStarTeamRelationship" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                            </ul>
                        </div>


                        <h4 className="subTitulo">8. De 0 a 10, qual nota você dá para sua experiência na {decoded.user.companyId.name}?</h4>
                        <div className="avaliacao-estrelha ava-star-experi-job">
                            <ul>
                                <li>
                                    {oneStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => yourNoteExperienceInJobs(event)} name="oneStarExperienceJobs" id="oneStarExperienceJobs" checked={true}/>
                                        <label htmlFor="oneStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="1" onChange={(event) => yourNoteExperienceInJobs(event)} name="oneStarExperienceJobs" id="oneStarExperienceJobs" checked={false}/>
                                        <label htmlFor="oneStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {twoStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => yourNoteExperienceInJobs(event)} name="twoStarExperienceJobs" id="twoStarExperienceJobs" checked={true}/>
                                        <label htmlFor="twoStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="2" onChange={(event) => yourNoteExperienceInJobs(event)} name="twoStarExperienceJobs" id="twoStarExperienceJobs" checked={false}/>
                                        <label htmlFor="twoStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {threeStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => yourNoteExperienceInJobs(event)} name="threeStarExperienceJobs" id="threeStarExperienceJobs" checked={true}/>
                                        <label htmlFor="threeStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="3" onChange={(event) => yourNoteExperienceInJobs(event)} name="threeStarExperienceJobs" id="threeStarExperienceJobs" checked={false}/>
                                        <label htmlFor="threeStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {fourStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => yourNoteExperienceInJobs(event)} name="fourStarExperienceJobs" id="fourStarExperienceJobs" checked={true}/>
                                        <label htmlFor="fourStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="4" onChange={(event) => yourNoteExperienceInJobs(event)} name="fourStarExperienceJobs" id="fourStarExperienceJobs" checked={false}/>
                                        <label htmlFor="fourStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {fiveStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => yourNoteExperienceInJobs(event)} name="fiveStarExperienceJobs" id="fiveStarExperienceJobs" checked={true}/>
                                        <label htmlFor="fiveStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="5" onChange={(event) => yourNoteExperienceInJobs(event)} name="fiveStarExperienceJobs" id="fiveStarExperienceJobs" checked={false}/>
                                        <label htmlFor="fiveStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {sixStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="6" onChange={(event) => yourNoteExperienceInJobs(event)} name="sixStarExperienceJobs" id="sixStarExperienceJobs" checked={true}/>
                                        <label htmlFor="sixStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="6" onChange={(event) => yourNoteExperienceInJobs(event)} name="sixStarExperienceJobs" id="sixStarExperienceJobs" checked={false}/>
                                        <label htmlFor="sixStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {sevenStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="7" onChange={(event) => yourNoteExperienceInJobs(event)} name="sevenStarExperienceJobs" id="sevenStarExperienceJobs" checked={true}/>
                                        <label htmlFor="sevenStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="7" onChange={(event) => yourNoteExperienceInJobs(event)} name="sevenStarExperienceJobs" id="sevenStarExperienceJobs" checked={false}/>
                                        <label htmlFor="sevenStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {eightStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="8" onChange={(event) => yourNoteExperienceInJobs(event)} name="eightStarExperienceJobs" id="eightStarExperienceJobs" checked={true}/>
                                        <label htmlFor="eightStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="8" onChange={(event) => yourNoteExperienceInJobs(event)} name="eightStarExperienceJobs" id="eightStarExperienceJobs" checked={false}/>
                                        <label htmlFor="eightStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {nineStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="9" onChange={(event) => yourNoteExperienceInJobs(event)} name="nineStarExperienceJobs" id="nineStarExperienceJobs" checked={true}/>
                                        <label htmlFor="nineStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="9" onChange={(event) => yourNoteExperienceInJobs(event)} name="nineStarExperienceJobs" id="nineStarExperienceJobs" checked={false}/>
                                        <label htmlFor="nineStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                                <li>
                                    {tenStarExperienceJobs[0] ?
                                    (
                                        <>
                                        <input type="checkbox" value="10" onChange={(event) => yourNoteExperienceInJobs(event)} name="tenStarExperienceJobs" id="tenStarExperienceJobs" checked={true}/>
                                        <label htmlFor="tenStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    ) :
                                    (
                                        <>
                                        <input type="checkbox" value="10" onChange={(event) => yourNoteExperienceInJobs(event)} name="tenStarExperienceJobs" id="tenStarExperienceJobs" checked={false}/>
                                        <label htmlFor="tenStarExperienceJobs" className="chackboxEstrelha"></label>
                                        </>
                                    )
                                    }
                                </li>
                            </ul>
                        </div>
                        <h4 className="subTitulo">9. Qual sua opinião em relação a Salário? </h4>
                        <div className="avaliacao-estrelha">
                            <textarea name="opinionRegardingSalary" defaultValue={(collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved.opinionRegardingSalary !== undefined) ? collaboratorDismisalInterviewSaved.opinionRegardingSalary : ''} onChange={myChangeHandler} cols="150" placeholder="Digite as observações do colaborador..." >
                            </textarea>
                        </div>

                        <h4 className="subTitulo">10. Imagem/sentimento levará da empresa?</h4>
                            <textarea name="companyImageAndFeeling" defaultValue={(collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved.companyImageAndFeeling !== undefined) ? collaboratorDismisalInterviewSaved.companyImageAndFeeling : ''} onChange={myChangeHandler} cols="150" placeholder="Digite as observações do colaborador..." >
                            </textarea>
                        <h4 className="subTitulo">11. Cite 3 pessoas da empresa que mais tiveram impactos positivos na sua carreira.</h4>
                            <textarea name="nameThreePositivePeople" defaultValue={(collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved.nameThreePositivePeople !== undefined) ? collaboratorDismisalInterviewSaved.nameThreePositivePeople : ''} onChange={myChangeHandler} cols="150" placeholder="Digite as observações do colaborador..." >
                            </textarea>
                        <h4 className="subTitulo">12. O que você aconselharia para a próxima pessoa que irá ocupar a sua função na empresa?</h4>
                            <textarea name="whatIsYourAdvice" defaultValue={(collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved.whatIsYourAdvice !== undefined) ? collaboratorDismisalInterviewSaved.whatIsYourAdvice : ''} onChange={myChangeHandler} cols="150" placeholder="Digite as observações do colaborador..." >
                            </textarea>
                        <h4 className="subTitulo">13. Existe alguma crítica, comentário ou sugestão sobre algum ponto que não foi abordado nas perguntas anteriores?</h4>
                            <textarea name="criticismCommentOrSuggestion" defaultValue={(collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved.criticismCommentOrSuggestion !== undefined) ? collaboratorDismisalInterviewSaved.criticismCommentOrSuggestion : ''} onChange={myChangeHandler} cols="150" placeholder="Digite as observações do colaborador..." >
                            </textarea>
                    </div>
                    <h5 className="titulo-obsevacoe-extra">Observações extras</h5>
                        <textarea className="input-obsevacoe-extra" defaultValue={(collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved.extraRemarks !== undefined) ? collaboratorDismisalInterviewSaved.extraRemarks : ''} onChange={myChangeHandler} name="extraRemarks" cols="150" placeholder="Digite as observações do colaborador..">
                        </textarea>

                    <h2 className="titulo-anex-doc">Anexar documentos</h2>

                    <div className="adicionar-doc">
                    <Dropzone onDrop={handleDropDismisslaInterviewFile} >
                        {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input type="file" name="file" {...getInputProps()} />
                            <label className="imgDocLabel"><span></span>Selecione ou arraste o arquivo</label>
                        </div>
                        )}
                    </Dropzone>

                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>


                        {loadDismisslaInterviewFile ?
                        (<div className="carregando textoDez"><img src={Carregando} alt="carregando" /></div>) :
                        ('')}
                    </div>


            </div>
                    <div className="ArquivosEnviados">
                        <h5>Enviados</h5>
                        {filesSavedDismisslaInterviewFile[0] === undefined ?
                        (
                            <div className="nenhum-arq">
                                    <p>Nenhum documento cadastrado.</p>
                                </div>
                        ):
                        filesSavedDismisslaInterviewFile ?
                        filesSavedDismisslaInterviewFile.map((filesaved, index) => (
                            <>
                                        <div key={index} className="arquivos">
                                        <div className="nameArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.originalname}
                                            </a>
                                        </div>
                                        <div className="dataArquivo">
                                            <span>Enviado em {dateFormatedToShutDown(filesaved.createdAt)}</span>
                                        </div>
                                        <div className="btnExclui">
                                            <button onClick={()=>deleteFileDismisslaInterviewFile(filesaved)}>Excluir anexo</button>
                                        </div>
                                    </div>
                            </>
                        )) :
                        ('')}

                    </div>
                </div>









        <div className="desligamento-buttons">
                <form onSubmit={excludeAdmssion} className="delete">
                        <button type="submit" className="delete">
                            Excluir processo
                        </button>
                </form>

                    <div className='saved-back'>
                            <NavLink to={`/colaboradores/desligamento/${id}/rescisao-e-documentos`}  className="back btnGray">Voltar</NavLink>
                            {
                                collaboratorDismisalInterview.length === 0 ?
                                <form onSubmit={continuar}>
                                    <button type="submit" disabled={loadContinue ? true : false} className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>{collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved._id ? 'Editar e continuar' : 'Continuar'}</button>
                                </form>
                                :
                                <form onSubmit={save}>
                                    <button type="submit" disabled={loadContinue ? true : false}  className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>{collaboratorDismisalInterviewSaved && collaboratorDismisalInterviewSaved._id ? 'Editar e continuar' : 'Salvar e continuar'}</button>
                                </form>
                            }
                    </div>
            </div>

        </>
    );
}

