import React from 'react';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

const SubMenuUserProfile = ({ id }) => {
    return (
        <>
            <ul className="SubMenu-perfil">
                <Scrollbars>
                    <li>
                        <NavLink
                            to={`/meus-dados/pessoal/`}
                            exact
                            activeClassName="active"
                        >
                            <span>Informações pessoais</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/meus-dados/pessoal/enderecos-contatos`}
                            exact
                            activeClassName="active"
                        >
                            <span>Endereços e contatos</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/meus-dados/pessoal/documentos`}
                            exact
                            activeClassName="active"
                        >
                            <span>Documentos</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/meus-dados/pessoal/faltas-e-afastamentos`}
                            exact
                            activeClassName="active"
                        >
                            <span>Falta e Afastamentos</span>
                        </NavLink>
                    </li>
                </Scrollbars>
            </ul>
        </>
    );
};
export default SubMenuUserProfile;
