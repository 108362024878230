import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink } from 'react-router-dom';


export default function SubHeaderTalents(path) {


    return (
        <>
            <div className='subMenu'>
                <Scrollbars>
                    <div className='scroll-padding'>
                        <ul>
                            <li>
                                <NavLink to={"/recrutamento/talentos"} exact activeClassName="active" className="link">
                                    <span>Visão geral</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/recrutamento/talentos/pastas"} exact activeClassName="active" className="link">
                                    <span>Todas as pastas</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/recrutamento/talentos/candidatos"} exact activeClassName="active" className="link">
                                    <span>Todos os candidatos</span>    
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </Scrollbars>
            </div>
        </>
    );
}

