import React, { useState, useRef } from 'react';
import Documents from './formDocuments/documents';
import CopiesDocuments from './formDocuments/copiesDocuments';
import BankData from './formDocuments/bankData';
import api from '../../../../services/api';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { mCPF, mRGNovo, dateMask, mCNH, mSerialNumber, mPIS, mVoterRegistration, mElectoralZone, mReservationCardNumber, mReservationCardCategory } from '../../../../services/mask';

const formDocumentos = ({ id, path,collaborator }) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadcpf                          , setLoadcpf                           ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadrg                           , setLoadrg                            ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadrgExpeditionDate             , setLoadrgExpeditionDate              ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadrgOrgan                      , setLoadrgOrgan                       ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadufOrgan                      , setLoadufOrgan                       ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadcnh                          , setLoadcnh                           ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadcnhCategory                  , setLoadcnhCategory                   ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadcnhExpeditionDate            , setLoadcnhExpeditionDate             ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadufCNH                        , setLoadufCNH                         ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadwalletNumber                 , setLoadwalletNumber                  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadserialNumber                 , setLoadserialNumber                  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadissueDate                    , setLoadissueDate                     ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadwalletUF                     , setLoadwalletUF                      ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadpisNumber                    , setLoadpisNumber                     ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadvoterRegistration            , setLoadvoterRegistration             ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadelectoralZone                , setLoadelectoralZone                 ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadpollingStation               , setLoadpollingStation                ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadreservationCardNumber        , setLoadreservationCardNumber         ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadreservationCardSeries        , setLoadreservationCardSeries         ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadreservationCardCategory      , setLoadreservationCardCategory       ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadreservationCardExpeditionDate, setLoadreservationCardExpeditionDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadufReservationCard            , setLoadufReservationCard             ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadcityReservationCard          , setLoadcityReservationCard           ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadbankCode                     , setLoadbankCode                      ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadbankAgency                   , setLoadbankAgency                    ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadbankAccount                  , setLoadbankAccount                   ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadaccountDigit                 , setLoadaccountDigit                  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAccountType                  , setLoadAccountType                   ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadpix                          , setLoadpix                           ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuscpf                                            = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusrg                                             = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusrgExpeditionDate                               = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusrgOrgan                                        = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusufOrgan                                        = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuscnh                                            = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuscnhCategory                                    = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuscnhExpeditionDate                              = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusufCNH                                          = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuswalletNumber                                   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusserialNumber                                   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusissueDate                                      = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuswalletUF                                       = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuspisNumber                                      = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusvoterRegistration                              = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuselectoralZone                                  = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuspollingStation                                 = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusreservationCardNumber                          = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusreservationCardSeries                          = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusreservationCardCategory                        = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusreservationCardExpeditionDate                  = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusufReservationCard                              = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuscityReservationCard                            = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const focusbankCode                                       = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusbankAgency                                     = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusbankAccount                                    = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const focusaccountDigit                                   = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusAccountType                                    = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focuspix                                            = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "cpf") {

            let cpf = mCPF(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cpf })
            if(val.length >= 14) {
                setLoadcpf(false)
            }

        }
        else if (nam === "rg") {

            let rg = mRGNovo(val)
            if(val.length >= 10) {
                setLoadrg(false)
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rg })

        }
        else if (nam === "rgExpeditionDate") {

            let rgExpeditionDate = dateMask(val)
            if(val.length >= 10) {
                setLoadrgExpeditionDate(false)
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rgExpeditionDate })

        }
        //rgOrgan
        else if(nam === 'rgOrgan') {
            if(val === 'Selecione') {
                setLoadrgOrgan(true)
                let valrgOrgan = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valrgOrgan })
            } else {
                setLoadrgOrgan(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        //ufOrgan
        else if(nam === 'ufOrgan') {
            if(val === 'Selecione') {
                setLoadufOrgan(true)
                let valufOrgan = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valufOrgan })
            } else {
                setLoadufOrgan(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        //cnh
        else if (nam === "cnh") {
            if(val.length >= 11) {
                setLoadcnh(false)
            }
            let cnh = mCNH(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnh })

        }
        //cnhCategory
        else if(nam === 'cnhCategory') {
            if(val === 'Selecione') {
                setLoadcnhCategory(true)
                let valcnhCategory = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valcnhCategory })
            } else {
                setLoadcnhCategory(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        //cnhExpeditionDate
        else if (nam === "cnhExpeditionDate") {

            if(val.length >= 10) {
                setLoadcnhExpeditionDate(false)
            }
            let cnhExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnhExpeditionDate })

        }
        //ufCNH
        else if(nam === 'ufCNH') {
            if(val === 'Selecione') {
                setLoadufCNH(true)
                let valufCNH = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valufCNH })
            } else {
                setLoadufCNH(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }


        //walletNumber
        else if (nam === "walletNumber") {

            if(val.length >= 11) {
                setLoadwalletNumber(false)
            }
            let walletNumber = mCNH(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: walletNumber })

        }
        //serialNumber
        else if (nam === "serialNumber") {

            if(val.length >= 4) {
                setLoadserialNumber(false)
            }
            let serialNumber = mSerialNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: serialNumber })

        }
        //issueDate
        else if (nam === "issueDate") {

            if(val.length >= 10) {
                setLoadissueDate(false)
            }
            let issueDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: issueDate })

        }
        //walletUF
        else if(nam === 'walletUF') {
            if(val === 'Selecione') {
                setLoadwalletUF(true)
                let valwalletUF = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valwalletUF })
            } else {
                setLoadwalletUF(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        //pisNumber
        else if (nam === "pisNumber") {

            if(val.length >= 13) {
                setLoadpisNumber(false)
            }
            let pisNumber = mPIS(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pisNumber })

        }
        //voterRegistration
        else if (nam === "voterRegistration") {

            if(val.length >= 14) {
                setLoadvoterRegistration(false)
            }
            let voterRegistration = mVoterRegistration(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: voterRegistration })

        }
        //electoralZone
        else if (nam === "electoralZone") {

            if(val.length >= 3) {
                setLoadelectoralZone(false)
            }
            let electoralZone = mElectoralZone(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: electoralZone })

        }
        //pollingStation
        else if (nam === "pollingStation") {

            if(val.length >= 4) {
                setLoadpollingStation(false)
            }
            let pollingStation = mSerialNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pollingStation })

        }
        //reservationCardNumber
        else if (nam === "reservationCardNumber") {

            if(val.length >= 12) {
                setLoadreservationCardNumber(false)
            }
            let reservationCardNumber = mReservationCardNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardNumber })

        }
        //reservationCardSeries
        else if (nam === "reservationCardSeries") {

            if(val.length >= 2) {
                setLoadreservationCardSeries(false)
            }
            // let reservationCardSeries = mReservationCardCategory(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })


        }
        //reservationCardCategory
        else if (nam === "reservationCardCategory") {

            if(val.length >= 2) {
                setLoadreservationCardCategory(false)
            }
            let reservationCardCategory = mReservationCardCategory(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardCategory })

        }
        //reservationCardExpeditionDate
        else if (nam === "reservationCardExpeditionDate") {

            if(val.length >= 10) {
                setLoadreservationCardExpeditionDate(false)
            }
            let reservationCardExpeditionDate = dateMask(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardExpeditionDate })

        }
        //ufReservationCard
        else if(nam === 'ufReservationCard') {
            if(val === 'Selecione') {
                setLoadufReservationCard(true)
                let valufReservationCard = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valufReservationCard })
            } else {
                setLoadufReservationCard(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        //cityReservationCard
        else if(nam === 'cityReservationCard') {
            if(val === 'Selecione') {
                setLoadcityReservationCard(true)
                let valcityReservationCard = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valcityReservationCard })
            } else {

                let cidade = val;

                if(cidade === '350000000') {

                    // alert('são paulo')
                    let cityReservationCardString = 'São Paulo';

                    let nam = 'cityReservationCard';
                    let val = '350000000';
                    setLoadcityReservationCard(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, cityReservationCardString, [nam]: val })

                }
                else
                if(collaboratorUpdate.ufReservationCard === '35' && cidade === '350000000') {

                    // alert('são paulo')
                    let cityReservationCardString = 'São Paulo';

                    let nam = 'cityReservationCard';
                    let val = '350000000';

                    setCollaboratorUpdate({ ...collaboratorUpdate, cityReservationCardString, [nam]: val })

                }
                else {
                    if(cidade & cidade) {
                        async function loadCity() {
                            await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                            .then(response => {
                                let cityReservationCardString = response.data[0].nome;
                                setCollaboratorUpdate({ ...collaboratorUpdate, cityReservationCardString, [nam]: val })
                            }).catch(error => {
                                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                            });
    
                        }
                        loadCity();
                    }

                }

                setLoadcityReservationCard(false)

            }
        }
        //bankCode
        else if(nam === 'bankName') {
            // focusbankCode.current.focus();
            if(val === 'Selecione o banco') {
                setLoadbankCode(true)
                let valbankCode = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valbankCode })
            } else {
                setLoadbankCode(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        else if (nam === "bankAgency") {

            setLoadbankAgency(false)
            let value = event.target.value.replace(/[^\d]/g, "")
            if(value.length > 10) {
                setCollaboratorUpdate({ ...collaboratorUpdate })
            } else {
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: value })
            }

        }
        else if (nam === "bankAccount") {

            setLoadbankAccount(false)
            let value = event.target.value.replace(/[^\d]/g, "")
            if(value.length > 20) {
                //alert('entrou')
                setCollaboratorUpdate({ ...collaboratorUpdate })
            } else {
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: value })
            }

        }
        //AccountType
        else if(nam === 'AccountType') {
            if(val === 'Selecione') {
                setLoadAccountType(true)
                let valAccountType = undefined;
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valAccountType })
            } else {
                setLoadAccountType(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }
        }
        //pix
        else if(nam === 'pix') {

            setLoadpix(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })

        }
        else {
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

    };

    async function save(event) {
        event.preventDefault();

            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            setLoadContinue(true)

            if(collaboratorUpdate.cpf !== undefined && collaboratorUpdate.cpf !== "") {
                setLoadcpf(false)
                formData.append('cpf', collaboratorUpdate.cpf);
            }
            else if(
                collaborator             &&
                collaborator.cpf         !== undefined
            ) {
                setLoadcpf(false)
            }
            else {
                setLoadcpf(true)
                focuscpf.current.focus();
                // return
            }


            if(collaboratorUpdate.foreign !== undefined) {
                formData.append('foreign', collaboratorUpdate.foreign);
            } else {
                formData.append('foreign', 'nao');
            }

            if(collaboratorUpdate.rg !== undefined && collaboratorUpdate.rg !== "") {
                setLoadrg(false)
                formData.append('rg', collaboratorUpdate.rg);
            }
            else if(
                collaborator             &&
                collaborator.rg         !== undefined
            ) {
                setLoadrg(false)
            }
            else {
                setLoadrg(true)
                focusrg.current.focus();
                // return
            }

            if(collaboratorUpdate.rgExpeditionDate !== undefined && collaboratorUpdate.rgExpeditionDate !== "") {
                setLoadrgExpeditionDate(false)
                formData.append('rgExpeditionDate', collaboratorUpdate.rgExpeditionDate);
            }
            else if(
                collaborator             &&
                collaborator.rgExpeditionDate         !== undefined
            ) {
                setLoadrgExpeditionDate(false)
            }
            else {
                setLoadrgExpeditionDate(true)
                focusrgExpeditionDate.current.focus();
                // return
            }

            if(collaboratorUpdate.rgOrgan !== undefined && collaboratorUpdate.rgOrgan !== "") {
                setLoadrgOrgan(false)
                formData.append('rgOrgan', collaboratorUpdate.rgOrgan);
            }
            else if(
                collaborator             &&
                collaborator.rgOrgan         !== undefined
            ) {
                setLoadrgOrgan(false)
            }
            else {
                setLoadrgOrgan(true)
                focusrgOrgan.current.focus();
                // return
            }

            if(collaboratorUpdate.ufOrgan !== undefined && collaboratorUpdate.ufOrgan !== "") {
                setLoadufOrgan(false)
                formData.append('ufOrgan', collaboratorUpdate.ufOrgan);
            }
            else if(
                collaborator             &&
                collaborator.ufOrgan         !== undefined
            ) {
                setLoadufOrgan(false)
            }
            else {
                setLoadufOrgan(true)
                focusufOrgan.current.focus();
                // return
            }

            if(collaboratorUpdate.cnh !== undefined && collaboratorUpdate.cnh !== "") {
                setLoadcnh(false)
                formData.append('cnh', collaboratorUpdate.cnh);
            }
            else if(
                collaborator             &&
                collaborator.cnh         !== undefined
            ) {
                setLoadcnh(false)
            }

            if(collaboratorUpdate.cnhCategory !== undefined && collaboratorUpdate.cnhCategory !== "") {
                setLoadcnhCategory(false)
                formData.append('cnhCategory', collaboratorUpdate.cnhCategory);
            }
            else if(
                collaborator             &&
                collaborator.cnhCategory         !== undefined
            ) {
                setLoadcnhCategory(false)
            }


            if(collaboratorUpdate.cnhExpeditionDate !== undefined && collaboratorUpdate.cnhExpeditionDate !== "") {
                setLoadcnhExpeditionDate(false)
                formData.append('cnhExpeditionDate', collaboratorUpdate.cnhExpeditionDate);
            }
            else if(
                collaborator             &&
                collaborator.cnhExpeditionDate         !== undefined
            ) {
                setLoadcnhExpeditionDate(false)
            }
            // else {
            //     setLoadcnhExpeditionDate(true)
            //     focuscnhExpeditionDate.current.focus();
            //     return
            // }

            if(collaboratorUpdate.ufCNH !== undefined && collaboratorUpdate.ufCNH !== "") {
                setLoadufCNH(false)
                formData.append('ufCNH', collaboratorUpdate.ufCNH);
            }
            else if(
                collaborator             &&
                collaborator.ufCNH         !== undefined
            ) {
                setLoadufCNH(false)
            }


            if(collaboratorUpdate.walletNumber !== undefined && collaboratorUpdate.walletNumber !== "") {
                setLoadwalletNumber(false)
                formData.append('walletNumber', collaboratorUpdate.walletNumber);
            }

            if(collaboratorUpdate.serialNumber !== undefined && collaboratorUpdate.serialNumber !== "") {
                setLoadserialNumber(false)
                formData.append('serialNumber', collaboratorUpdate.serialNumber);
            }

            if(collaboratorUpdate.issueDate !== undefined && collaboratorUpdate.issueDate !== "") {
                setLoadissueDate(false)
                formData.append('issueDate', collaboratorUpdate.issueDate);
            }

            if(collaboratorUpdate.walletUF !== undefined && collaboratorUpdate.walletUF !== "") {
                setLoadwalletUF(false)
                formData.append('walletUF', collaboratorUpdate.walletUF);
            }

            if(collaboratorUpdate.pisNumber !== undefined && collaboratorUpdate.pisNumber !== "") {
                setLoadpisNumber(false)
                formData.append('pisNumber', collaboratorUpdate.pisNumber);
            }


            if(collaboratorUpdate.voterRegistration !== undefined && collaboratorUpdate.voterRegistration !== "") {
                setLoadvoterRegistration(false)
                formData.append('voterRegistration', collaboratorUpdate.voterRegistration);
            }
            else if(
                collaborator             &&
                collaborator.voterRegistration         !== undefined
            ) {
                setLoadvoterRegistration(false)
            }
            else {
                setLoadvoterRegistration(true)
                focusvoterRegistration.current.focus();
                // return
            }

            if(collaboratorUpdate.electoralZone !== undefined && collaboratorUpdate.electoralZone !== "") {
                setLoadelectoralZone(false)
                formData.append('electoralZone', collaboratorUpdate.electoralZone);
            }
            else if(
                collaborator             &&
                collaborator.electoralZone         !== undefined
            ) {
                setLoadelectoralZone(false)
            }
            else {
                setLoadelectoralZone(true)
                focuselectoralZone.current.focus();
                // return
            }

            if(collaboratorUpdate.pollingStation !== undefined && collaboratorUpdate.pollingStation !== "") {
                setLoadpollingStation(false)
                formData.append('pollingStation', collaboratorUpdate.pollingStation);
            }
            else if(
                collaborator             &&
                collaborator.pollingStation         !== undefined
            ) {
                setLoadpollingStation(false)
            }
            else {
                setLoadpollingStation(true)
                focuspollingStation.current.focus();
                // return
            }

            if(collaborator && collaborator.gender === 'Masculino') {

                if(collaboratorUpdate.reservationCardNumber !== undefined && collaboratorUpdate.reservationCardNumber !== "") {
                    setLoadreservationCardNumber(false)
                    formData.append('reservationCardNumber', collaboratorUpdate.reservationCardNumber);
                }
                else if(
                    collaborator             &&
                    collaborator.reservationCardNumber         !== undefined
                ) {
                    setLoadreservationCardNumber(false)
                }
                else {
                    setLoadreservationCardNumber(true)
                    focusreservationCardNumber.current.focus();
                    // return
                }

                if(collaboratorUpdate.reservationCardSeries !== undefined && collaboratorUpdate.reservationCardSeries !== "") {
                    setLoadreservationCardSeries(false)
                    formData.append('reservationCardSeries', collaboratorUpdate.reservationCardSeries);
                }
                else if(
                    collaborator             &&
                    collaborator.reservationCardSeries         !== undefined
                ) {
                    setLoadreservationCardSeries(false)
                }
                else {
                    setLoadreservationCardSeries(true)
                    focusreservationCardSeries.current.focus();
                    // return
                }

                if(collaboratorUpdate.reservationCardCategory !== undefined && collaboratorUpdate.reservationCardCategory !== "") {
                    setLoadreservationCardCategory(false)
                    formData.append('reservationCardCategory', collaboratorUpdate.reservationCardCategory);
                }
                else if(
                    collaborator             &&
                    collaborator.reservationCardCategory         !== undefined
                ) {
                    setLoadreservationCardCategory(false)
                }
                else {
                    setLoadreservationCardCategory(true)
                    focusreservationCardCategory.current.focus();
                    // return
                }

                if(collaboratorUpdate.reservationCardExpeditionDate !== undefined && collaboratorUpdate.reservationCardExpeditionDate !== "") {
                    setLoadreservationCardExpeditionDate(false)
                    formData.append('reservationCardExpeditionDate', collaboratorUpdate.reservationCardExpeditionDate);
                }
                else if(
                    collaborator             &&
                    collaborator.reservationCardExpeditionDate         !== undefined
                ) {
                    setLoadreservationCardExpeditionDate(false)
                }
                else {
                    setLoadreservationCardExpeditionDate(true)
                    focusreservationCardExpeditionDate.current.focus();
                    // return
                }

                if(collaboratorUpdate.ufReservationCard !== undefined && collaboratorUpdate.ufReservationCard !== "") {
                    setLoadufReservationCard(false)
                    formData.append('ufReservationCard', collaboratorUpdate.ufReservationCard);
                }
                else if(
                    collaborator             &&
                    collaborator.ufReservationCard         !== undefined
                ) {
                    setLoadufReservationCard(false)
                }
                else {
                    setLoadufReservationCard(true)
                    focusufReservationCard.current.focus();
                    // return
                }

                if(collaboratorUpdate.cityReservationCard !== undefined && collaboratorUpdate.cityReservationCard !== "") {
                    setLoadcityReservationCard(false)
                    formData.append('cityReservationCard', collaboratorUpdate.cityReservationCard);
                }
                else if(
                    collaborator             &&
                    collaborator.cityReservationCard         !== undefined
                ) {
                    setLoadcityReservationCard(false)
                }
                else {
                    setLoadcityReservationCard(true)
                    focuscityReservationCard.current.focus();
                    // return
                }

            }

            if(collaboratorUpdate.bankName !== undefined && collaboratorUpdate.bankName !== "") {
                setLoadbankCode(false)
                formData.append('bankName', collaboratorUpdate.bankName);
            }
            else if(
                collaborator             &&
                collaborator.bankName         !== undefined
            ) {
                setLoadbankCode(false)
            }
            else {
                setLoadbankCode(true)
                // focusbankCode.current.focus();
                // return
            }

            if(collaboratorUpdate.bankAgency !== undefined && collaboratorUpdate.bankAgency !== "") {
                setLoadbankAgency(false)
                formData.append('bankAgency', collaboratorUpdate.bankAgency);
            }
            else if(
                collaborator             &&
                collaborator.bankAgency         !== undefined
            ) {
                setLoadbankAgency(false)
            }
            else {
                setLoadbankAgency(true)
                focusbankAgency.current.focus();
                // return
            }

            if(collaboratorUpdate.bankAccount !== undefined && collaboratorUpdate.bankAccount !== "") {
                setLoadbankAccount(false)
                formData.append('bankAccount', collaboratorUpdate.bankAccount);
            }
            else if(
                collaborator             &&
                collaborator.bankAccount         !== undefined
            ) {
                setLoadbankAccount(false)
            }
            else {
                setLoadbankAccount(true)
                focusbankAccount.current.focus();
                // return
            }

            if(collaboratorUpdate.accountDigit !== undefined && collaboratorUpdate.accountDigit !== "") {
                setLoadaccountDigit(false)
                formData.append('accountDigit', collaboratorUpdate.accountDigit);
            }
            else if(
                collaborator             &&
                collaborator.accountDigit         !== undefined
            ) {
                setLoadaccountDigit(false)
            }

            if(collaboratorUpdate.AccountType !== undefined && collaboratorUpdate.AccountType !== "") {
                setLoadAccountType(false)
                formData.append('AccountType', collaboratorUpdate.AccountType);
            }
            else if(
                collaborator             &&
                collaborator.AccountType         !== undefined
            ) {
                setLoadAccountType(false)
            }
            else {
                setLoadAccountType(true)
            }

            if(collaboratorUpdate.pix !== undefined && collaboratorUpdate.pix !== "") {
                setLoadpix(false)
                formData.append('pix', collaboratorUpdate.pix);
            }
            else if(
                collaborator             &&
                collaborator.pix         !== undefined
            ) {
                setLoadpix(false)
            }
            else {
                setLoadpix(true)
                focuspix.current.focus();
            }
            formData.append('stepOne', 'true');
            formData.append('stepTwo', 'true');
            formData.append('stepThree', 'true');
            formData.append('stepFour', 'false')
            formData.append('path', path)
            if (
                (
                    collaboratorUpdate?.cpf                           !== undefined ||
                    collaborator?.cpf                                 !== undefined
                )
                &&(
                    collaboratorUpdate?.rg                            !== undefined ||
                    collaborator?.rg                            !== undefined
                ) &&(
                    collaboratorUpdate?.rgExpeditionDate              !== undefined ||
                    collaborator?.rgExpeditionDate              !== undefined
                ) &&(
                    collaboratorUpdate?.rgOrgan                       !== undefined ||
                    collaborator?.rgOrgan                       !== undefined
                ) &&(
                    collaboratorUpdate?.ufOrgan                       !== undefined ||
                    collaborator?.ufOrgan                       !== undefined

                )&& (
                    collaboratorUpdate?.voterRegistration             !== undefined ||
                    collaborator?.voterRegistration             !== undefined
                )&&(
                    collaboratorUpdate?.electoralZone                 !== undefined ||
                    collaborator?.electoralZone                 !== undefined

                )&&(
                    collaboratorUpdate?.pollingStation                !== undefined ||
                    collaborator?.pollingStation                !== undefined
                )&&(
                    collaboratorUpdate.bankName                      !== undefined ||
                    collaborator?.bankName                      !== undefined
                )&&(
                    collaboratorUpdate?.bankAgency                    !== undefined ||
                    collaborator?.bankAgency                    !== undefined
                ) &&(
                    collaboratorUpdate?.bankAccount                   !== undefined ||
                    collaborator?.bankAccount                   !== undefined
                ) &&(
                    collaboratorUpdate?.AccountType                   !== undefined ||
                    collaborator?.AccountType                   !== undefined
                ) && (
                    collaboratorUpdate?.pix                           !== undefined ||
                    collaborator?.pix                           !== undefined
                )
        ) {

            let stepsNewAdmission = collaborator.stepsNewAdmission;

            if(stepsNewAdmission === 3) {
                formData.append('stepsNewAdmission', 4);
            }

            if(collaborator.gender === "Masculino") {
                if(
                    (
                        collaborator?.reservationCardNumber !== undefined || collaboratorUpdate?.reservationCardNumber !== undefined
                    ) &&
                    (
                        collaborator?.reservationCardSeries !== undefined || collaboratorUpdate?.reservationCardSeries !== undefined
                    ) && (
                        collaborator?.reservationCardCategory !== undefined || collaboratorUpdate?.reservationCardCategory !== undefined
                    ) && (
                        collaborator?.reservationCardExpeditionDate !== undefined || collaboratorUpdate?.reservationCardExpeditionDate !== undefined
                    ) && (
                        collaborator?.ufReservationCard !== undefined || collaboratorUpdate?.ufReservationCard !== undefined
                    ) && (
                        collaborator?.cityReservationCard !== undefined || collaboratorUpdate?.cityReservationCard !== undefined
                    )
                ) {

                    setLoadContinue(false)
                    await api.put(`/collaborator/${id}`, formData, config)
                    .then(response => {

                        if(response.data.stepsNewAdmission === 3 ){
                            window.location.href = `/colaboradores/nova-admissao/formulario-documentos/${id}`;
                        }else if( response.data.stepsNewAdmission === 4 ){
                            window.location.href = `/colaboradores/nova-admissao/formulario-beneficios/${id}`;
                        }else if( response.data.stepsNewAdmission === 5 ){
                            window.location.href = `/colaboradores/nova-admissao/enviar-contador/${id}`;
                        }

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        if(error.response.data.message === 'Você não pode cadastrar uma data de expedição do RG menor que 1920.') {
                            setLoadrgExpeditionDate(true)
                            focusrgExpeditionDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Data de expedição do RG em formato inválido, por favor informe um formato existente!') {
                            setLoadrgExpeditionDate(true)
                            focusrgExpeditionDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Você não pode cadastrar uma data de expedição da CNH menor que 1920.') {
                            setLoadcnhExpeditionDate(true)
                            focuscnhExpeditionDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Data de expedição da CNH em formato inválido, por favor informe um formato existente!') {
                            setLoadcnhExpeditionDate(true)
                            focuscnhExpeditionDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Você não pode cadastrar uma data de emissão da Carteira de Trabalho menor que 1920.') {
                            setLoadissueDate(true)
                            focusissueDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Data de emissão da Carteira de Trabalho em formato inválido, por favor informe um formato existente!') {
                            setLoadissueDate(true)
                            focusissueDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Você não pode cadastrar uma data de expedição da Carteira de Reservista menor que 1920.') {
                            setLoadreservationCardExpeditionDate(true)
                            focusreservationCardExpeditionDate.current.focus();
                            return
                        }
                        else
                        if(error.response.data.message === 'Data de expedição da Carteira de Reservista em formato inválido, por favor informe um formato existente!') {
                            setLoadreservationCardExpeditionDate(true)
                            focusreservationCardExpeditionDate.current.focus();
                            return
                        }

                    });

                }else{
                    setLoadContinue(false)
                    return
                }

            }else {

                setLoadContinue(true)
                await api.put(`/collaborator/${id}`, formData, config)
                .then(response => {


                    setLoadContinue(false)

                    if(response.data.stepsNewAdmission === 3 ){
                        window.location.href = `/colaboradores/nova-admissao/formulario-documentos/${id}`;
                    }else if( response.data.stepsNewAdmission === 4 ){
                        window.location.href = `/colaboradores/nova-admissao/formulario-beneficios/${id}`;
                    }else if( response.data.stepsNewAdmission === 5 ){
                        window.location.href = `/colaboradores/nova-admissao/enviar-contador/${id}`;
                    }

                }).catch(error => {

                    setLoadContinue(false)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    if(error.response.data.message === 'Você não pode cadastrar uma data de expedição do RG menor que 1920.') {
                        setLoadrgExpeditionDate(true)
                        focusrgExpeditionDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Data de expedição do RG em formato inválido, por favor informe um formato existente!') {
                        setLoadrgExpeditionDate(true)
                        focusrgExpeditionDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Você não pode cadastrar uma data de expedição da CNH menor que 1920.') {
                        setLoadcnhExpeditionDate(true)
                        focuscnhExpeditionDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Data de expedição da CNH em formato inválido, por favor informe um formato existente!') {
                        setLoadcnhExpeditionDate(true)
                        focuscnhExpeditionDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Você não pode cadastrar uma data de emissão da Carteira de Trabalho menor que 1920.') {
                        setLoadissueDate(true)
                        focusissueDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Data de emissão da Carteira de Trabalho em formato inválido, por favor informe um formato existente!') {
                        setLoadissueDate(true)
                        focusissueDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Você não pode cadastrar uma data de expedição da Carteira de Reservista menor que 1920.') {
                        setLoadreservationCardExpeditionDate(true)
                        focusreservationCardExpeditionDate.current.focus();
                        return
                    }
                    else
                    if(error.response.data.message === 'Data de expedição da Carteira de Reservista em formato inválido, por favor informe um formato existente!') {
                        setLoadreservationCardExpeditionDate(true)
                        focusreservationCardExpeditionDate.current.focus();
                        return
                    }

                });


            }

        } else {
            setLoadContinue(false)
            return
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ abrirResultBank, setAbrirResultBank ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ openSelAccountType, setOpenSelAccountType ] = useState(false);

    function mySearchBank(event) {
        // Declare variables
        event.preventDefault();

        let nam = event.target.name;
        let val = event.target.value;

        setAbrirResultBank(true)
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('myBank');
        filter = input.value.toUpperCase();
        ul = document.getElementById("resultadoBusca");
        if(ul !== null) {

            li = ul.getElementsByTagName('button');

            // Loop through all list items, and hide those who don't match the search query
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("p")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
                } else {
                li[i].style.display = "none";
                }
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            return
        }
    }

    function abrirResultado() {
        setAbrirResultBank(!abrirResultBank)
        setOpenSelAccountType(false)
    }
    async function myChangeHandlerToSaveBank(event) {
        setLoadbankCode(false)
        setCollaboratorUpdate({ ...collaboratorUpdate, "bankName": event})
        setAbrirResultBank(false)
    }

    async function selectAccountTypeOpen(e){
        setOpenSelAccountType(!openSelAccountType)
    }
    async function myChangeHandlerAccountType(event) {

        // let nam = event.target.name;
        let val = event.target.value;
        if(val ===  "Conta Poupança" ) {
            setCollaboratorUpdate({ ...collaboratorUpdate, "AccountType": 'conta-poupanca'})
        }else if(val ===  "Conta Salário" ) {
            setCollaboratorUpdate({ ...collaboratorUpdate, "AccountType": 'conta-salario'})
        }else if(val ===  "Conta Corrente" ) {
            setCollaboratorUpdate({ ...collaboratorUpdate, "AccountType": 'conta-Corrente'})
        }
        setLoadAccountType(false)
   }
   async function closeBankData(e) {
        if(e.target.className !== "btn-select selectAccountType" && e.target.id !== "myBank" ) {
            setOpenSelAccountType(false)
            setAbrirResultBank(false)
        }
    }


    return (
        <>
            <div className="formDocuments">
                <Documents id={id}
                collaboratorState={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                setCollaboratorUpdate={setCollaboratorUpdate}
                ufDistrito={collaborator.ufReservationCard}
                ufReservationCard={collaboratorUpdate && collaboratorUpdate.ufReservationCard ? collaboratorUpdate.ufReservationCard : ''}
                ufCity={collaborator.cityReservationCard}
                onCollaboratorInputChange={myChangeHandler}
                loadcpf={loadcpf}
                focuscpf={focuscpf}
                focusrg={focusrg}
                loadrg={loadrg}
                loadrgExpeditionDate={loadrgExpeditionDate}
                loadrgOrgan={loadrgOrgan}
                loadufOrgan={loadufOrgan}
                focusrgExpeditionDate={focusrgExpeditionDate}
                focusrgOrgan={focusrgOrgan}
                focusufOrgan={focusufOrgan}
                loadcnh               ={loadcnh              }
                loadcnhCategory       ={loadcnhCategory      }
                loadcnhExpeditionDate ={loadcnhExpeditionDate}
                loadufCNH             ={loadufCNH            }
                focuscnh              ={focuscnh              }
                focuscnhCategory      ={focuscnhCategory      }
                focuscnhExpeditionDate={focuscnhExpeditionDate}
                focusufCNH            ={focusufCNH            }
                loadwalletNumber ={loadwalletNumber}
                loadserialNumber ={loadserialNumber}
                loadissueDate    ={loadissueDate   }
                loadwalletUF     ={loadwalletUF    }
                loadpisNumber    ={loadpisNumber   }
                focuswalletNumber={focuswalletNumber}
                focusserialNumber={focusserialNumber}
                focusissueDate   ={focusissueDate   }
                focuswalletUF    ={focuswalletUF    }
                focuspisNumber   ={focuspisNumber   }
                loadvoterRegistration ={loadvoterRegistration}
                loadelectoralZone     ={loadelectoralZone    }
                loadpollingStation    ={loadpollingStation   }
                focusvoterRegistration={focusvoterRegistration}
                focuselectoralZone    ={focuselectoralZone    }
                focuspollingStation   ={focuspollingStation   }
                loadreservationCardNumber         ={loadreservationCardNumber        }
                loadreservationCardSeries         ={loadreservationCardSeries        }
                loadreservationCardCategory       ={loadreservationCardCategory      }
                loadreservationCardExpeditionDate ={loadreservationCardExpeditionDate}
                loadufReservationCard             ={loadufReservationCard            }
                loadcityReservationCard           ={loadcityReservationCard          }
                focusreservationCardNumber        ={focusreservationCardNumber        }
                focusreservationCardSeries        ={focusreservationCardSeries        }
                focusreservationCardCategory      ={focusreservationCardCategory      }
                focusreservationCardExpeditionDate={focusreservationCardExpeditionDate}
                focusufReservationCard            ={focusufReservationCard            }
                focuscityReservationCard          ={focuscityReservationCard          }
                setLoadufOrgan ={setLoadufOrgan}
                setLoadufReservationCard={setLoadufReservationCard}
                setLoadcityReservationCard={setLoadcityReservationCard}
                />
                <CopiesDocuments id={id} path={path} />
                <BankData
                id={id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                loadbankCode     ={loadbankCode    }
                loadbankAgency   ={loadbankAgency  }
                loadbankAccount  ={loadbankAccount }
                loadaccountDigit ={loadaccountDigit}
                loadAccountType  ={loadAccountType }
                loadpix          ={loadpix         }
                // focusbankCode    ={focusbankCode    }
                focusbankAgency  ={focusbankAgency  }
                focusbankAccount ={focusbankAccount }
                // focusaccountDigit={focusaccountDigit}
                focusAccountType ={focusAccountType }
                focuspix         ={focuspix         }

                mySearchBank    ={mySearchBank}
                abrirResultado ={abrirResultado}
                abrirResultBank ={abrirResultBank}
                myChangeHandlerToSaveBank ={myChangeHandlerToSaveBank}
                openSelAccountType={openSelAccountType}
                selectAccountTypeOpen={selectAccountTypeOpen}
                myChangeHandlerAccountType={myChangeHandlerAccountType}
                closeBankData={closeBankData}
                setCollaboratorUpdate={setCollaboratorUpdate}
                loadContinue={loadContinue}
                save={save}
                />
            </div>
        </>
    );

}
export default formDocumentos;
