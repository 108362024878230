import React, { useState, useEffect } from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Carregando from '../../../../assets/images/carregando.gif';
// import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import { Modal} from "react-bootstrap";
import api from '../../../../services/api';
import Close from '../../../../assets/images/close.svg';
import {removerSpecials} from '../../../../services/utils'
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado'

export default function ColProfileReservist ({
    id,
    collaborator,
    loadPage,
    onCollaboratorInputChange,
    collaboratorUpdate,
    collaboratorState,
    ufDistrito ,
    ufCity,
    OnSubmit,
    loadContinue,
    EditReservistVisible,
    visibleReservist,
    filesSavedReservist,
    loadReservist,
    deleteFileReservist,
    handleDropReservist,
    ufReservationCard,
    setCollaboratorUpdate,
    selectUfReservationCard,
    setSelectUfReservationCard,
    selectCityReservationCard,
    setSelectCityReservationCard
}) {

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhido, setUEscolhido ] = useState('')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEstados, setUfEstados ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCidade ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ citys, setCitys ] = useState([]);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     useEffect(() => {

        loadufestados()

        async function loaduf() {
            
            await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
            .then(response => {
                setUEscolhido(response.data);
                // alert("Alert")
            }).catch(error => {});
        }
        loaduf();

        if(ufCity !== undefined) {
            if(ufCity === '350000000') {
                let cidadeGravada = {
                    id: "350000000",
                    nome: "São Paulo"
                }
                setCidade(cidadeGravada);
            }
            else {
                async function loadcity() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
                    .then(response => {
                        setCidade(response.data[0]);
                    }).catch(error => {});
                }
                loadcity();
            }
        }

        if(ufReservationCard === '35') {
            // console.log('estado de são paulo')
            async function loadcities() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufReservationCard}/distritos?orderBy=nome`)
                .then(response => {

                    let itensCopyCitys = Array.from(response.data);

                    itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });
                    // console.log('itensCopyCitys')
                    // console.log(itensCopyCitys)

                    itensCopyCitys.sort(function (a, b) {
                        if (a.nome > b.nome) {
                          return 1;
                        }
                        if (a.nome < b.nome) {
                          return -1;
                        }
                        // a must be equal to b
                        return 0;
                      });

                    setCitys(itensCopyCitys);

                }).catch(error => {});
            }
            loadcities();
        }
        else {

            if(collaboratorUpdate && collaboratorUpdate.ufReservationCard === undefined) {
                const numeroCity = collaboratorState.ufReservationCard;

                api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${numeroCity}/distritos?orderBy=nome`)
                .then(response => {
                    setCitys(response.data);
                }).catch(error => {});

            } else { 
                async function loadcities() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufReservationCard}/distritos?orderBy=nome`)
                .then(response => {
                    setCitys(response.data);
                }).catch(error => {});
            }
            loadcities();

            }

           
        }
    }, [ufDistrito, ufCity, ufReservationCard, collaboratorUpdate, collaboratorState]);

    async function loadufestados() {
        await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
        .then(response => {
            setUfEstados(response.data);
        }).catch(error => {});
    }
    

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhidoUpdateC, setUUfEscolhidoUpdateC ] = useState('')


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [nationalityBusca , setNationalityBusca] = useState([]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [nationalityBuscaString , setNationalityBuscaString] = useState([]);

  const myChangeHandlerSlects = event => {
      let nam = event.target.name;
      let val = event.target.value;

      if(nam === "ufReservationCardBusca") {
          let valOption = removerSpecials(val).toLowerCase()
          setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
          setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
  
      }
      else
      if(nam === "ufReservationCard") {

          setCollaboratorUpdate({ ...collaboratorUpdate, "ufReservationCard": val })
          
          api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val}`)
          .then(response => {
              setUUfEscolhidoUpdateC(response.data);
              setNationalityBuscaString({ ...nationalityBuscaString,  "ufReservationCardBusca": response.data.nome  });
  
              if(response.data.nome === "São Paulo") {
  
                  async function loadufestados() {
                      await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${response.data.id}/distritos?orderBy=nome`)
                      .then(response => {
  
                          let itensCopyCitys = Array.from(response.data);
  
                          itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });
  
                          itensCopyCitys.sort(function (a, b) {
                              if (a.nome > b.nome) {
                              return 1;
                              }
                              if (a.nome < b.nome) {
                              return -1;
                              }
                              // a must be equal to b
                              return 0;
                          });
  
                          setCitys(itensCopyCitys);
  
                      }).catch(error => {});
                  }
                  loadufestados()
  
  
              }
  
          }).catch(error => {});
      }
      else
      if(nam === "cityReservationCardBusca") {
          let valOption = removerSpecials(val).toLowerCase()
          setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
          setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
      } 
      else
      if(nam === "cityReservationCard") {
          setNationalityBuscaString({ ...nationalityBuscaString, "cityReservationCardBusca": val });
          setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val });
      }
  }

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const optionFiltreUfEstados = ufEstados.filter((valOption) => {
      return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.ufReservationCardBusca !== undefined ? nationalityBusca.ufReservationCardBusca : "" )  ;
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const optionFiltreUfCidade = citys.filter((valOption) => {
       return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.cityReservationCardBusca !== undefined ? nationalityBusca.cityReservationCardBusca : "" )  ;
   });







        return (
            <>

            <div className="box reservist">
                <h3>Carteira de Reservista <button className="edit-Benefit" onClick={() => EditReservistVisible()}><img src={EditBlack} alt="Edite Informações" /></button></h3>
                {
                    loadPage ?
                        (
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregando} />
                            </div>
                            </>
                        ):(
                            <>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Número</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.reservationCardNumber ? collaborator.reservationCardNumber : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Série</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.reservationCardSeries ? collaborator.reservationCardSeries : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Categoria</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.reservationCardCategory ? collaborator.reservationCardCategory : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Data de expedição</p>
                        </div>
                        <div className="intemB">
                        <p>{collaborator.reservationCardExpeditionDate ? collaborator.reservationCardExpeditionDate : '-'}</p>

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Estado</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.ufReservationCard ? ufEscolhido.nome : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Cidade</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.cityReservationCard ? collaborator.cityReservationCard : '-'}</p>
                        </div>
                    </li>

                    {filesSavedReservist[0] !== undefined ? (
                    <li className="li-anexo">
                        <div className="intemA">
                            <p>Anexo</p>
                        </div>



                    <div className="intemB arq-adicionado" >
                        {filesSavedReservist[0] !== undefined ?
                        filesSavedReservist.map((filesaved, index) => (
                                    <a href={filesaved.location} target="’_blank’" key={index}>
                                        {filesaved.name}
                                    </a>
                        )) : (
                            <>
                            <p>-</p>
                            </>
                        )}


                    </div>
                    </li>
                    ) : ('')}
                </ul>
                </>)}
            </div>
            <Modal show={visibleReservist} className="popup-edit-perfil popup-edit-cpf" onHide={EditReservistVisible}>
            <div>
                <div className="titulo">
                    <h4>Carteira de Reservista <p className="btn-fechar" onClick={EditReservistVisible}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Número</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.reservationCardNumber !== undefined) || collaboratorUpdate.reservationCardNumber === '' ?
                            (
                                <input type="text"  name="reservationCardNumber" value={(collaboratorUpdate.reservationCardNumber !== undefined) ? collaboratorUpdate.reservationCardNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número"  />
                            ) :
                            collaborator && collaborator.reservationCardNumber ? (
                                <input type="text"  name="reservationCardNumber" defaultValue={(collaborator.reservationCardNumber !== undefined) ? collaborator.reservationCardNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número"/>
                            ):
                            (
                                <input type="text"  name="reservationCardNumber" value={(collaboratorUpdate.reservationCardNumber !== undefined) ? collaboratorUpdate.reservationCardNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Série</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.reservationCardSeries !== undefined) || collaboratorUpdate.reservationCardSeries === '' ?
                            (
                                <input type="text"  name="reservationCardSeries" autocomplete="autocompleteoff" maxlength="4" value={(collaboratorUpdate.reservationCardSeries !== undefined) ? collaboratorUpdate.reservationCardSeries : ''}  onChange={onCollaboratorInputChange} placeholder="Série"  />
                            ) :
                            collaborator && collaborator.reservationCardSeries ? (
                                <input type="text"  name="reservationCardSeries" autocomplete="autocompleteoff" maxlength="4" defaultValue={(collaborator.reservationCardSeries !== undefined) ? collaborator.reservationCardSeries : ''}  onChange={onCollaboratorInputChange} placeholder="Série"/>
                            ):
                            (
                                <input type="text"  name="reservationCardSeries" autocomplete="autocompleteoff" maxlength="4" value={(collaboratorUpdate.reservationCardSeries !== undefined) ? collaboratorUpdate.reservationCardSeries : ''}  onChange={onCollaboratorInputChange} placeholder="Série"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Categoria</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.reservationCardCategory !== undefined) || collaboratorUpdate.reservationCardCategory === '' ?
                            (
                                <input type="text"  name="reservationCardCategory" autocomplete="autocompleteoff" maxlength="2" value={(collaboratorUpdate.reservationCardCategory !== undefined) ? collaboratorUpdate.reservationCardCategory : ''}  onChange={onCollaboratorInputChange} placeholder="Categoria"  />
                            ) :
                            collaborator && collaborator.reservationCardCategory ? (
                                <input type="text"  name="reservationCardCategory" autocomplete="autocompleteoff" maxlength="2" defaultValue={(collaborator.reservationCardCategory !== undefined) ? collaborator.reservationCardCategory : ''}  onChange={onCollaboratorInputChange} placeholder="Categoria"/>
                            ):
                            (
                                <input type="text"  name="reservationCardCategory" autocomplete="autocompleteoff" maxlength="2" value={(collaboratorUpdate.reservationCardCategory !== undefined) ? collaboratorUpdate.reservationCardCategory : ''}  onChange={onCollaboratorInputChange} placeholder="Categoria"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Data Expedição</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.reservationCardExpeditionDate !== undefined )|| collaboratorUpdate.reservationCardExpeditionDate === '' ?
                            (
                                <input type="text"  name="reservationCardExpeditionDate" autocomplete="autocompleteoff" maxlength="10" value={(collaboratorUpdate.reservationCardExpeditionDate !== undefined) ? collaboratorUpdate.reservationCardExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="Data de expedição"  />
                            ) :
                            collaborator && collaborator.reservationCardExpeditionDate ? (
                                <input type="text"  name="reservationCardExpeditionDate" autocomplete="autocompleteoff" maxlength="10" defaultValue={(collaborator.reservationCardExpeditionDate !== undefined) ? collaborator.reservationCardExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="Data de expedição"/>
                            ):
                            (
                                <input type="text"  name="reservationCardExpeditionDate" autocomplete="autocompleteoff" maxlength="10" value={(collaboratorUpdate.reservationCardExpeditionDate !== undefined) ? collaboratorUpdate.reservationCardExpeditionDate : ''}  onChange={onCollaboratorInputChange} placeholder="Data de expedição"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Estado</p>
                        </div>
                        <div className="intemB">



                        <SelectPersonalizado
                            valorCampo={

                                nationalityBuscaString && nationalityBuscaString.ufReservationCardBusca ?
                                nationalityBuscaString.ufReservationCardBusca :
                                nationalityBuscaString && nationalityBuscaString.ufReservationCardBusca === '' ?
                                    '' :

                                    collaboratorUpdate && collaboratorUpdate.ufReservationCard ?
                                    ufEscolhidoUpdateC.nome 
                                    :
                                    collaboratorState && collaboratorState.ufReservationCard ?
                                    ufEscolhido.nome : ''
                            }
                            nameCampoBusca={"ufReservationCardBusca"}
                            nameButton={"ufReservationCard"}
                            
                            myChangeHandler={myChangeHandlerSlects}
                            option={optionFiltreUfEstados}
                            validacaoValue={"estado"}
                            validacaoOptionIntem={"estado"}
                            typeSelect={"busca"}
                        />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Cidade</p>
                        </div>
                        <div className="intemB">
                                <SelectPersonalizado
                                    valorCampo={
        
                                        nationalityBuscaString && nationalityBuscaString.cityReservationCardBusca ?
                                        nationalityBuscaString.cityReservationCardBusca  :
                                        nationalityBuscaString && nationalityBuscaString.cityReservationCardBusca === '' ?
                                            '' :
                                            collaboratorUpdate && collaboratorUpdate.cityReservationCard ?
                                            collaboratorUpdate.cityReservationCard :
                                            collaboratorState && collaboratorState.cityReservationCard  ?
                                            collaboratorState.cityReservationCard  : ''
                                    }
                                    nameCampoBusca={"cityReservationCardBusca"}
                                    nameButton={"cityReservationCard"}
                                    
                                    myChangeHandler={myChangeHandlerSlects}
                                    option={optionFiltreUfCidade}
                                    validacaoValue={"pais"}
                                    validacaoOptionIntem={"pais"}
                                    typeSelect={"busca"}
                                />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Anexar</p>
                        </div>
                        <div className="intemB">
                                <Dropzone onDrop={handleDropReservist} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                        </div>
                    </li>

                    <li>
                        <div className="intemA">
                        </div>
                        <div className="intemB">
                            <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                        </div>
                    </li>
                    {filesSavedReservist[0]  !== undefined ? (
                    <li>
                        <div className="arquivoAnexado">
                            <h5>Enviados</h5>

                            {filesSavedReservist[0] !== undefined ?
                            filesSavedReservist.map((filesaved, index) => (
                                <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        <a href={filesaved.location} target="’_blank’">
                                            {filesaved.name}
                                        </a>
                                    </p>
                                    <button onClick={()=>deleteFileReservist(filesaved)} className="btnExclui">Remover anexo</button>
                                </div>
                            )) : ''}

                        </div>
                    </li>
                            ) : ''}
                    {loadReservist ?
                    ( <li>
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    </li>) : ('')}
                    <li>
                        <div className="intemA">
                        </div>
                        <div className="intemB">
                            <form onSubmit={OnSubmit} className="btn-salvar">
                                <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                            </form>
                        </div>
                    </li>

                </ul>


            </div>
        </Modal>
        </>
    );
}

