import React, {useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom'
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/header';
import SubMenu from '../../../components/Administrativo/ScoreAdmin/subMenu';
import PeriodFields from '../../../components/Administrativo/ScoreAdmin/periodFields';
import apiV2 from '../../../services/apiV2';
import moment from 'moment'
import swal from 'sweetalert';


import './styles.scss';

export default function ScoreAdmin(props) {

    const { id } = props.match.params;
    
    //////////////////////////////////////////////////////
    /////////////////// LOAD DE ERROR ///////////////////
    ////////////////////////////////////////////////////

    const [ loadErrorName, setLoadErrorName ] = useState(false);
    const [ loadSaveButton, setLoadSaveButton  ] = useState(false);
    const [ loadSameTimeInWeekDays, setLoadSameTimeInWeekDays ] = useState(false);
    const [ loadIncludeWeekend, setLoadIncludeWeekend ] = useState(false);

    const [ name, setName ] = useState("");
    const [ totalTimeWorking, setTotalTimeWorking ] = useState("");
    
    const [ secondPeriod1, setSecondPeriod1 ] = useState({});
    const [ secondPeriod2, setSecondPeriod2 ] = useState({});

    const [ tuesdayPeriod1, setTuesdayPeriod1 ] = useState({});
    const [ tuesdayPeriod2, setTuesdayPeriod2 ] = useState({});

    const [ wednesdayPeriod1, setWednesdayPeriod1 ] = useState({});
    const [ wednesdayPeriod2, setWednesdayPeriod2 ] = useState({});

    const [ thursdayPeriod1, setThursdayPeriod1 ] = useState({});
    const [ thursdayPeriod2, setThursdayPeriod2 ] = useState({});

    const [ fridayPeriod1, setFridayPeriod1 ] = useState({});
    const [ fridayPeriod2, setFridayPeriod2 ] = useState({});

    const [ saturdayPeriod1, setSaturdayPeriod1 ] = useState({});
    const [ saturdayPeriod2, setSaturdayPeriod2 ] = useState({});

    const [ sundayPeriod1, setSundayPeriod1 ] = useState({});
    const [ sundayPeriod2, setSundayPeriod2 ] = useState({});

    useEffect(() => {
        if(id !== undefined) { loadSavedWorday(id) }
    },[id]);

    useEffect(() => {
        calculateDifferenceHours( 
            secondPeriod1, 
            secondPeriod2, 
            tuesdayPeriod1, 
            tuesdayPeriod2, 
            wednesdayPeriod1, 
            wednesdayPeriod2, 
            thursdayPeriod1, 
            thursdayPeriod2,
            fridayPeriod1, 
            fridayPeriod2,
            saturdayPeriod1, 
            saturdayPeriod2,
            sundayPeriod1,
            sundayPeriod2,
        )
    },[ 
        secondPeriod1, 
        secondPeriod2, 
        tuesdayPeriod1, 
        tuesdayPeriod2, 
        wednesdayPeriod1, 
        wednesdayPeriod2, 
        thursdayPeriod1, 
        thursdayPeriod2,
        fridayPeriod1, 
        fridayPeriod2,
        saturdayPeriod1, 
        saturdayPeriod2,
        sundayPeriod1,
        sundayPeriod2,
    ])
    //////////////////////////////////////////////////////
    /////////////////// LOAD JOURNEY ///////////////////
    ////////////////////////////////////////////////////

    async function loadSavedWorday(id) {
       
        await apiV2.get(`/weekly-journey/${id}`)
        .then(response => {

            setTotalTimeWorking(response?.data?.totalTimeWorking)
            setName(response?.data?.name);
            setLoadIncludeWeekend(response?.data?.includeWeekend);
            setLoadSameTimeInWeekDays(response?.data?.sameTimeInWeekDays);

            const days = response.data.days;
            const dayOfWeek1 =   days.filter(periods => periods.dayOfWeek === "1");
            const dayOfWeek2 =   days.filter(periods => periods.dayOfWeek === "2");
            const dayOfWeek3 =   days.filter(periods => periods.dayOfWeek === "3");
            const dayOfWeek4 =   days.filter(periods => periods.dayOfWeek === "4");
            const dayOfWeek5 =   days.filter(periods => periods.dayOfWeek === "5");
            const dayOfWeek6 =   days.filter(periods => periods.dayOfWeek === "6");
            const dayOfWeek7 =   days.filter(periods => periods.dayOfWeek === "7");

            setSecondPeriod1(dayOfWeek1[0]?.period1 !== undefined ? dayOfWeek1[0]?.period1 : "")
            setSecondPeriod2(dayOfWeek1[0]?.period2 !== undefined ? dayOfWeek1[0]?.period2 : "")

            setTuesdayPeriod1(dayOfWeek2[0]?.period1 !== undefined ? dayOfWeek2[0]?.period1 : "")
            setTuesdayPeriod2(dayOfWeek2[0]?.period2 !== undefined ? dayOfWeek2[0]?.period2 : "")

            setWednesdayPeriod1(dayOfWeek3[0]?.period1 !== undefined ? dayOfWeek3[0]?.period1 : "")
            setWednesdayPeriod2(dayOfWeek3[0]?.period2 !== undefined ? dayOfWeek3[0]?.period2 : "")

            setThursdayPeriod1(dayOfWeek4[0]?.period1 !== undefined ? dayOfWeek4[0]?.period1 : "")
            setThursdayPeriod2(dayOfWeek4[0]?.period2 !== undefined ? dayOfWeek4[0]?.period2 : "")

            setFridayPeriod1(dayOfWeek5[0]?.period1 !== undefined ? dayOfWeek5[0]?.period1 : "")
            setFridayPeriod2(dayOfWeek5[0]?.period2 !== undefined ? dayOfWeek5[0]?.period2 : "")

            setSaturdayPeriod1(dayOfWeek6[0]?.period1 !== undefined ? dayOfWeek6[0]?.period1 : "")
            setSaturdayPeriod2(dayOfWeek6[0]?.period2 !== undefined ? dayOfWeek6[0]?.period2 : "")

            setSundayPeriod1(dayOfWeek7[0]?.period1 !== undefined ? dayOfWeek7[0]?.period1 : "")
            setSundayPeriod2(dayOfWeek7[0]?.period2 !== undefined ? dayOfWeek7[0]?.period2 : "")

        }).catch(error => {
            toast.error( error?.response?.data?.message ? error?.response?.data?.message :
                'Não foi possível carregar a jornada de trabalhho!', { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    
    function myChangeHandler(event, time) {
        let nam          = event.target.name;
        let val          = event.target.value;
        if (nam === "name") {
            setName(val)
            if(val === "") {
                setLoadErrorName(true)
            }else {
                setLoadErrorName(false)
            } 
        } else 
        
        if(nam === "second_period1_initTime") {
            setSecondPeriod1({...secondPeriod1, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "second_period1_endTime") {
            setSecondPeriod1({...secondPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "second_period2_initTime") {
            setSecondPeriod2({...secondPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "second_period2_endTime") {
            setSecondPeriod2({...secondPeriod2, endTime: time});
            calculateDifferenceHours();
        }else


        if(nam === "tuesday_period1_initTime") {
            setTuesdayPeriod1({...tuesdayPeriod1, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "tuesday_period1_endTime") {
            setTuesdayPeriod1({...tuesdayPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "tuesday_period2_initTime") {
            setTuesdayPeriod2({...tuesdayPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "tuesday_period2_endTime") {
            setTuesdayPeriod2({...tuesdayPeriod2, endTime: time});
            calculateDifferenceHours();
        }else

        if(nam === "wednesday_period1_initTime") {
            setWednesdayPeriod1({...wednesdayPeriod1, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "wednesday_period1_endTime") {
            setWednesdayPeriod1({...wednesdayPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "wednesday_period2_initTime") {
            setWednesdayPeriod2({...wednesdayPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "wednesday_period2_endTime") {
            setWednesdayPeriod2({...wednesdayPeriod2, endTime: time});
            calculateDifferenceHours();
        }else

        if(nam === "thursday_period1_initTime") {
            setThursdayPeriod1({...thursdayPeriod1, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "thursday_period1_endTime") {
            setThursdayPeriod1({...thursdayPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "thursday_period2_initTime") {
            setThursdayPeriod2({...thursdayPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "thursday_period2_endTime") {
            setThursdayPeriod2({...thursdayPeriod2, endTime: time});
            calculateDifferenceHours();
        }else

        if(nam === "friday_period1_initTime") {
            setFridayPeriod1({...fridayPeriod1, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "friday_period1_endTime") {
            setFridayPeriod1({...fridayPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "friday_period2_initTime") {
            setFridayPeriod2({...fridayPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "friday_period2_endTime") {
            setFridayPeriod2({...fridayPeriod2, endTime: time});
            calculateDifferenceHours();
        }else

        if(nam === "saturday_period1_initTime") {
            setSaturdayPeriod1({...saturdayPeriod1, initTime: time});
            calculateDifferenceHours();
        }
        else
        if(nam === "saturday_period1_endTime") {
            setSaturdayPeriod1({...saturdayPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "saturday_period2_initTime") {
            setSaturdayPeriod2({...saturdayPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "saturday_period2_endTime") {
            setSaturdayPeriod2({...saturdayPeriod2, endTime: time});
            calculateDifferenceHours();
        }else

        if(nam === "sunday_period1_initTime") {
            setSundayPeriod1({...sundayPeriod1, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "sunday_period1_endTime") {
            setSundayPeriod1({...sundayPeriod1, endTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "sunday_period2_initTime") {
            setSundayPeriod2({...sundayPeriod2, initTime: time});
            calculateDifferenceHours();
        }else
        if(nam === "sunday_period2_endTime") {
            setSundayPeriod2({...sundayPeriod2, endTime: time});
            calculateDifferenceHours();
        }
    }

    async function saveWorkJourney() {
        setLoadSaveButton(true)
        if(name === "") {
            setLoadErrorName(true)
             return
        } else
        if(
            (secondPeriod1?.initTime === "" || secondPeriod1?.initTime === undefined ) &&
            (secondPeriod1?.endTime === "" || secondPeriod1?.endTime === undefined) &&
            (secondPeriod2?.initTime === "" || secondPeriod2?.initTime === undefined ) &&
            (secondPeriod2?.endTime === "" || secondPeriod2?.endTime === undefined) &&

            (tuesdayPeriod1?.initTime === "" || tuesdayPeriod1?.initTime === undefined ) &&
            (tuesdayPeriod1?.endTime === "" || tuesdayPeriod1?.endTime === undefined)&&
            (tuesdayPeriod2?.initTime === "" || tuesdayPeriod2?.initTime === undefined ) &&
            (tuesdayPeriod2?.endTime === "" || tuesdayPeriod2?.endTime === undefined)&&

            (wednesdayPeriod1?.initTime === "" || wednesdayPeriod1?.initTime === undefined ) &&
            (wednesdayPeriod1?.endTime === "" || wednesdayPeriod1?.endTime === undefined)&&
            (wednesdayPeriod2?.initTime === "" || wednesdayPeriod2?.initTime === undefined ) &&
            (wednesdayPeriod2?.endTime === "" || wednesdayPeriod2?.endTime === undefined) &&

            (thursdayPeriod1?.initTime === "" || thursdayPeriod1?.initTime === undefined ) &&
            (thursdayPeriod1?.endTime === "" || thursdayPeriod1?.endTime === undefined)  &&
            (thursdayPeriod2?.initTime === "" || thursdayPeriod2?.initTime === undefined ) &&
            (thursdayPeriod2?.endTime === "" || thursdayPeriod2?.endTime === undefined)  &&

            (fridayPeriod1?.initTime === "" || fridayPeriod1?.initTime === undefined ) &&
            (fridayPeriod1?.endTime === "" || fridayPeriod1?.endTime === undefined)  &&
            (fridayPeriod2?.initTime === "" || fridayPeriod2?.initTime === undefined ) &&
            (fridayPeriod2?.endTime === "" || fridayPeriod2?.endTime === undefined)   &&

            (saturdayPeriod1?.initTime === "" || saturdayPeriod1?.initTime === undefined ) &&
            (saturdayPeriod1?.endTime === "" || saturdayPeriod1?.endTime === undefined)  &&
            (saturdayPeriod2?.initTime === "" || saturdayPeriod2?.initTime === undefined ) &&
            (saturdayPeriod2?.endTime === "" || saturdayPeriod2?.endTime === undefined)   &&

            (sundayPeriod1?.initTime === "" || sundayPeriod1?.initTime === undefined ) &&
            (sundayPeriod1?.endTime === "" || sundayPeriod1?.endTime === undefined)  &&
            (sundayPeriod2?.initTime === "" || sundayPeriod2?.initTime === undefined ) &&
            (sundayPeriod2?.endTime === "" || sundayPeriod2?.endTime === undefined) 
        ){
            swal({ icon: "error", title: "Erro!", text: 'Necessário adicionar pelo menos um periodo!' });
            return
        }
        if(id !== undefined ) {
            await apiV2.patch(`/weekly-journey/${id}`, {
                "name": name,
                "sameTimeInWeekDays": loadSameTimeInWeekDays,
                "includeWeekend": loadIncludeWeekend,
                "days": [
                    {
                        "dayOfWeek": 1,
                        "period1": secondPeriod1 == null || Object.keys(secondPeriod1).length === 0  ? undefined : secondPeriod1,
                        "period2": secondPeriod2 == null || Object.keys(secondPeriod2).length === 0  ? undefined : secondPeriod2,
                    },
                    {
                        "dayOfWeek": 2,
                        "period1": tuesdayPeriod1 == null || Object.keys(tuesdayPeriod1).length === 0  ? undefined : tuesdayPeriod1,
                        "period2": tuesdayPeriod2 == null || Object.keys(tuesdayPeriod2).length === 0  ? undefined : tuesdayPeriod2,
                    },
                    {
                        "dayOfWeek": 3,
                        "period1": wednesdayPeriod1 == null || Object.keys(wednesdayPeriod1).length === 0  ? undefined : wednesdayPeriod1,
                        "period2": wednesdayPeriod2 == null || Object.keys(wednesdayPeriod2).length === 0  ? undefined : wednesdayPeriod2,
                    },
                    {
                        "dayOfWeek": 4,
                        "period1": thursdayPeriod1 == null || Object.keys(thursdayPeriod1).length === 0  ? undefined : thursdayPeriod1,
                        "period2": thursdayPeriod2 == null || Object.keys(thursdayPeriod2).length === 0  ? undefined : thursdayPeriod2,
                    },
                    {
                        "dayOfWeek": 5,
                        "period1": fridayPeriod1 == null || Object.keys(fridayPeriod1).length === 0  ? undefined : fridayPeriod1,
                        "period2": fridayPeriod2 == null || Object.keys(fridayPeriod2).length === 0  ? undefined : fridayPeriod2,
                    },
                    {
                        "dayOfWeek": 6,
                        "period1": saturdayPeriod1 == null || Object.keys(saturdayPeriod1).length === 0  ? undefined : saturdayPeriod1,
                        "period2": saturdayPeriod2 == null || Object.keys(saturdayPeriod2).length === 0  ? undefined : saturdayPeriod2,
                    },
                    {
                        "dayOfWeek": "0",
                        "period1": sundayPeriod1 == null || Object.keys(sundayPeriod1).length === 0  ? undefined : sundayPeriod1,
                        "period2": sundayPeriod2 == null || Object.keys(sundayPeriod2).length === 0  ? undefined : sundayPeriod2
                    }
                ]
            })
            .then(response => { 
                setLoadSaveButton(false);
                swal({ icon: "success", title: "Sucesso!", text: "Jornada de trabalho atualizada com sucesso!"});
                window.location.href = `/ponto/jornadas`;
            }).catch(error => {
                toast.error( error?.response?.data?.message ? error?.response?.data?.message :
                    'Não foi possível atualizar a jornada de trabalhho!', { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadSaveButton(false);
            });

        }else {

            await apiV2.post(`/weekly-journey`, {
                "name": name,
                "sameTimeInWeekDays": loadSameTimeInWeekDays,
                "includeWeekend": loadIncludeWeekend,
                "days": [
                    {
                        "dayOfWeek": 1,
                        "period1": secondPeriod1 == null || Object.keys(secondPeriod1).length === 0  ? undefined : secondPeriod1,
                        "period2": secondPeriod2 == null || Object.keys(secondPeriod2).length === 0  ? undefined : secondPeriod2,
                    },
                    {
                        "dayOfWeek": 2,
                        "period1": tuesdayPeriod1 == null || Object.keys(tuesdayPeriod1).length === 0  ? undefined : tuesdayPeriod1,
                        "period2": tuesdayPeriod2 == null || Object.keys(tuesdayPeriod2).length === 0  ? undefined : tuesdayPeriod2,
                    },
                    {
                        "dayOfWeek": 3,
                        "period1": wednesdayPeriod1 == null || Object.keys(wednesdayPeriod1).length === 0  ? undefined : wednesdayPeriod1,
                        "period2": wednesdayPeriod2 == null || Object.keys(wednesdayPeriod2).length === 0  ? undefined : wednesdayPeriod2,
                    },
                    {
                        "dayOfWeek": 4,
                        "period1": thursdayPeriod1 == null || Object.keys(thursdayPeriod1).length === 0  ? undefined : thursdayPeriod1,
                        "period2": thursdayPeriod2 == null || Object.keys(thursdayPeriod2).length === 0  ? undefined : thursdayPeriod2,
                    },
                    {
                        "dayOfWeek": 5,
                        "period1": fridayPeriod1 == null || Object.keys(fridayPeriod1).length === 0  ? undefined : fridayPeriod1,
                        "period2": fridayPeriod2 == null || Object.keys(fridayPeriod2).length === 0  ? undefined : fridayPeriod2,
                    },
                    {
                        "dayOfWeek": 6,
                        "period1": saturdayPeriod1 == null || Object.keys(saturdayPeriod1).length === 0  ? undefined : saturdayPeriod1,
                        "period2": saturdayPeriod2 == null || Object.keys(saturdayPeriod2).length === 0  ? undefined : saturdayPeriod2,
                    },
                    {
                        "dayOfWeek": "0",
                        "period1": sundayPeriod1 == null || Object.keys(sundayPeriod1).length === 0  ? undefined : sundayPeriod1,
                        "period2": sundayPeriod2 == null || Object.keys(sundayPeriod2).length === 0  ? undefined : sundayPeriod2
                    }
                ]
            })
            .then(response => { 
                setLoadSaveButton(false);
                swal({ icon: "success", title: "Sucesso!", text: "Jornada de trabalho salva com sucesso!"});
                window.location.href = `/ponto/jornadas`;
            }).catch(error => {
                toast.error( error?.response?.data?.message ? error?.response?.data?.message :
                    'Não foi possível salvar a jornada de trabalhho!', { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadSaveButton(false);
            });

        }

    }
    async function calculateDifferenceHours(
        secondPeriod1, 
        secondPeriod2, 
        tuesdayPeriod1, 
        tuesdayPeriod2, 
        wednesdayPeriod1, 
        wednesdayPeriod2, 
        thursdayPeriod1, 
        thursdayPeriod2,
        fridayPeriod1, 
        fridayPeriod2,
        saturdayPeriod1, 
        saturdayPeriod2,
        sundayPeriod1,
        sundayPeriod2,
        ) {
        const secondPeriod1_initTime = moment(secondPeriod1?.initTime ?secondPeriod1?.initTime : "", 'HH:mm');
        const secondPeriod1_endTime = moment(secondPeriod1?.endTime ?secondPeriod1?.endTime : "", 'HH:mm');

        const secondPeriod2_initTime = moment(secondPeriod2?.initTime ?secondPeriod2?.initTime : "", 'HH:mm');
        const secondPeriod2_endTime = moment(secondPeriod2?.endTime ?secondPeriod2?.endTime : "", 'HH:mm');

        const tuesdayPeriod1_initTime = moment(tuesdayPeriod1?.initTime ?tuesdayPeriod1?.initTime : "", 'HH:mm');
        const tuesdayPeriod1_endTime = moment(tuesdayPeriod1?.endTime ?tuesdayPeriod1?.endTime : "", 'HH:mm');

        const tuesdayPeriod2_initTime = moment(tuesdayPeriod2?.initTime ?tuesdayPeriod2?.initTime : "", 'HH:mm');
        const tuesdayPeriod2_endTime = moment(tuesdayPeriod2?.endTime ?tuesdayPeriod2?.endTime : "", 'HH:mm');

        const wednesdayPeriod1_initTime = moment(wednesdayPeriod1?.initTime ?wednesdayPeriod1?.initTime : "", 'HH:mm');
        const wednesdayPeriod1_endTime = moment(wednesdayPeriod1?.endTime ?wednesdayPeriod1?.endTime : "", 'HH:mm');

        const wednesdayPeriod2_initTime = moment(wednesdayPeriod2?.initTime ?wednesdayPeriod2?.initTime : "", 'HH:mm');
        const wednesdayPeriod2_endTime = moment(wednesdayPeriod2?.endTime ?wednesdayPeriod2?.endTime : "", 'HH:mm');

        const thursdayPeriod1_initTime = moment(thursdayPeriod1?.initTime ?thursdayPeriod1?.initTime : "", 'HH:mm');
        const thursdayPeriod1_endTime = moment(thursdayPeriod1?.endTime ?thursdayPeriod1?.endTime : "", 'HH:mm');

        const thursdayPeriod2_initTime = moment(thursdayPeriod2?.initTime ?thursdayPeriod2?.initTime : "", 'HH:mm');
        const thursdayPeriod2_endTime = moment(thursdayPeriod2?.endTime ?thursdayPeriod2?.endTime : "", 'HH:mm');

        const fridayPeriod1_initTime = moment(fridayPeriod1?.initTime ?fridayPeriod1?.initTime : "", 'HH:mm');
        const fridayPeriod1_endTime = moment(fridayPeriod1?.endTime ?fridayPeriod1?.endTime : "", 'HH:mm');

        const fridayPeriod2_initTime = moment(fridayPeriod2?.initTime ?fridayPeriod2?.initTime : "", 'HH:mm');
        const fridayPeriod2_endTime = moment(fridayPeriod2?.endTime ?fridayPeriod2?.endTime : "", 'HH:mm');

        const saturdayPeriod1_initTime = moment(saturdayPeriod1?.initTime ?saturdayPeriod1?.initTime : "", 'HH:mm');
        const saturdayPeriod1_endTime = moment(saturdayPeriod1?.endTime ?saturdayPeriod1?.endTime : "", 'HH:mm');

        const saturdayPeriod2_initTime = moment(saturdayPeriod2?.initTime ?saturdayPeriod2?.initTime : "", 'HH:mm');
        const saturdayPeriod2_endTime = moment(saturdayPeriod2?.endTime ?saturdayPeriod2?.endTime : "", 'HH:mm');

        const sundayPeriod1_initTime = moment(sundayPeriod1?.initTime ?sundayPeriod1?.initTime : "", 'HH:mm');
        const sundayPeriod1_endTime = moment(sundayPeriod1?.endTime ?sundayPeriod1?.endTime : "", 'HH:mm');

        const sundayPeriod2_initTime = moment(sundayPeriod2?.initTime ?sundayPeriod2?.initTime : "", 'HH:mm');
        const sundayPeriod2_endTime = moment(sundayPeriod2?.endTime ?sundayPeriod2?.endTime : "", 'HH:mm');

        const diferenca1 = moment.utc(moment(secondPeriod1_endTime, 'HH:mm').diff(moment(secondPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca2 = moment.utc(moment(secondPeriod2_endTime, 'HH:mm').diff(moment(secondPeriod2_initTime, 'HH:mm'))).format('HH:mm');

        const diferenca3 = moment.utc(moment(tuesdayPeriod1_endTime, 'HH:mm').diff(moment(tuesdayPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca4 = moment.utc(moment(tuesdayPeriod2_endTime, 'HH:mm').diff(moment(tuesdayPeriod2_initTime, 'HH:mm'))).format('HH:mm');

        const diferenca5 = moment.utc(moment(wednesdayPeriod1_endTime, 'HH:mm').diff(moment(wednesdayPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca6 = moment.utc(moment(wednesdayPeriod2_endTime, 'HH:mm').diff(moment(wednesdayPeriod2_initTime, 'HH:mm'))).format('HH:mm');

        const diferenca7 = moment.utc(moment(thursdayPeriod1_endTime, 'HH:mm').diff(moment(thursdayPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca8 = moment.utc(moment(thursdayPeriod2_endTime, 'HH:mm').diff(moment(thursdayPeriod2_initTime, 'HH:mm'))).format('HH:mm');

        const diferenca9 = moment.utc(moment(fridayPeriod1_endTime, 'HH:mm').diff(moment(fridayPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca10 = moment.utc(moment(fridayPeriod2_endTime, 'HH:mm').diff(moment(fridayPeriod2_initTime, 'HH:mm'))).format('HH:mm');

        const diferenca11 = moment.utc(moment(saturdayPeriod1_endTime, 'HH:mm').diff(moment(saturdayPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca12 = moment.utc(moment(saturdayPeriod2_endTime, 'HH:mm').diff(moment(saturdayPeriod2_initTime, 'HH:mm'))).format('HH:mm');

        const diferenca13 = moment.utc(moment(sundayPeriod1_endTime, 'HH:mm').diff(moment(sundayPeriod1_initTime, 'HH:mm'))).format('HH:mm');
        const diferenca14 = moment.utc(moment(sundayPeriod2_endTime, 'HH:mm').diff(moment(sundayPeriod2_initTime, 'HH:mm'))).format('HH:mm');
        
        const array = [];
       
        if(diferenca1 !== "Invalid date") {
            array.push(diferenca1)
        }
        if(diferenca2 !== "Invalid date") {
            array.push(diferenca2)
        }
        if(diferenca3 !== "Invalid date") {
            array.push(diferenca3)
        }
        if(diferenca4 !== "Invalid date") {
            array.push(diferenca4)
        }
        if(diferenca5 !== "Invalid date") {
            array.push(diferenca5)
        }   
        if(diferenca6 !== "Invalid date") {
            array.push(diferenca6)
        } 
        if(diferenca7 !== "Invalid date") {
            array.push(diferenca7)
        } 
        if(diferenca8 !== "Invalid date") {
            array.push(diferenca8)
        } 
        if(diferenca9 !== "Invalid date") {
            array.push(diferenca9)
        } 
        if(diferenca10 !== "Invalid date") {
            array.push(diferenca10)
        } 
        if(diferenca11 !== "Invalid date") {
            array.push(diferenca11)
        } 
        if(diferenca12 !== "Invalid date") {
            array.push(diferenca12)
        }  
        if(diferenca13 !== "Invalid date") {
            array.push(diferenca13)
        } 
        if(diferenca14 !== "Invalid date") {
            array.push(diferenca14)
        } 

        let totalMinutos = 0;
        array.forEach(hora => {
            const [hh, mm] = hora.split(':');
            totalMinutos += parseInt(hh) * 60 + parseInt(mm);
        });
        const horasFinais = Math.floor(totalMinutos / 60);
        const minutosFinais = totalMinutos % 60;
        const horasFormatadas = horasFinais.toString().padStart(2, '0');
        const minutosFormatados = minutosFinais.toString().padStart(2, '0');
        setTotalTimeWorking(`${horasFormatadas}:${minutosFormatados}`)
    }

    return (
        <>


            <Layout path={props.match.path}>
                <Header  path={props.match.path}/>
                <div className="page-ponto">
                    <SubMenu props={props}/>

                    <div className="page-journeys">
                        <section className='header'>
                            <h2 className="titulo"><NavLink to="/ponto/jornadas" className="voltar">Voltar</NavLink>
                            {
                                props.match.path === '/ponto/cadastrar-jornada' ? "Adicionar jornada" : "" ||
                                props.match.path === '/ponto/editar-jornada' ? "Editar jornada" : ""
                            }
                            </h2>
                        </section>
                        <div className='form-jornada'>
                            <div className='name-jornada'>
                                <p>Nome da jornada</p>
                                <div>
                                    <input
                                        type="text"
                                        placeholder='44h seg à sáb...'
                                        name='name'
                                        value={name}
                                        onChange={myChangeHandler}
                                        className={loadErrorName ? 'inputErro' : ''}
                                    />
                                    {
                                    loadErrorName ?
                                        <p className="textError">O campo é obrigatório.</p>
                                    : ('')
                                }
                                </div>
                            </div>
                            <h2 className="titulo">Horários</h2>
                            <div className="radios-same-time">
                                <p>Mesmo horário seg à sex</p>
                                <div className='radios'>
                                    <button className="btn-radio" onClick={() => setLoadSameTimeInWeekDays(true)}>
                                        <label className={
                                            loadSameTimeInWeekDays && loadSameTimeInWeekDays !== "" && loadSameTimeInWeekDays === true ?
                                           "chekboxLabel checado" : "chekboxLabel"
                                        }
                                        ></label>
                                        <p className="texto"> Sim </p>
                                    </button>

                                    <button className="btn-radio" onClick={() => setLoadSameTimeInWeekDays(false)}>
                                        <label className={
                                        loadSameTimeInWeekDays === "" || loadSameTimeInWeekDays === false ?
                                           "chekboxLabel checado" : "chekboxLabel"
                                        }   ></label>
                                        <p className="texto"> Não </p>
                                    </button>

                                </div>
                            </div>
                            <ul className="block-form-days">
                                <li>
                                    <div className='dia'>
                                        <p>
                                        {
                                            loadSameTimeInWeekDays === true ? 
                                            "SEGUNDA À SEXTA" : "SEGUNDA"
                                        }
                                            
                                            </p>
                                    </div>
                                    <div className='field-periods'>
                                        <PeriodFields
                                            period="1"
                                            valuePeriod={secondPeriod1}
                                            disabledTwo={
                                                secondPeriod1.initTime === "" ||
                                                secondPeriod1.initTime === undefined ?
                                                true : false
                                            }
                                            nameInitTime="second_period1_initTime"
                                            nameEndTime="second_period1_endTime"
                                            myChangeHandler={myChangeHandler}
                                        />
                                        <PeriodFields
                                            period="2"
                                            valuePeriod={secondPeriod2}

                                            disabledOne={
                                                secondPeriod1.endTime === "" ||
                                                secondPeriod1.endTime === undefined ?
                                                true : false
                                            }
                                            disabledTwo={
                                                secondPeriod2.initTime === "" ||
                                                secondPeriod2.initTime === undefined ?
                                                true : false
                                            }
                                            nameInitTime="second_period2_initTime"
                                            nameEndTime="second_period2_endTime"
                                            myChangeHandler={myChangeHandler}
                                        />
                                    </div>
                                </li>
                                {
                                    loadSameTimeInWeekDays === false ? 
                                    <>
                                        <li>
                                            <div className='dia'>
                                                <p>TERÇA</p>
                                            </div>
                                            <div className='field-periods'>
                                                <PeriodFields
                                                    period="1"
                                                    valuePeriod={tuesdayPeriod1}
                                                    disabledTwo={
                                                        tuesdayPeriod1.initTime === "" ||
                                                        tuesdayPeriod1.initTime === undefined ?
                                                        true : false
                                                    }

                                                    nameInitTime="tuesday_period1_initTime"
                                                    nameEndTime="tuesday_period1_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                    
                                                />
                                                <PeriodFields
                                                    period="2"
                                                    valuePeriod={tuesdayPeriod2}

                                                    disabledOne={
                                                        tuesdayPeriod1.endTime === "" ||
                                                        tuesdayPeriod1.endTime === undefined ?
                                                        true : false
                                                    }
                                                    disabledTwo={
                                                        tuesdayPeriod2.initTime === "" ||
                                                        tuesdayPeriod2.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="tuesday_period2_initTime"
                                                    nameEndTime="tuesday_period2_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <div className='dia'>
                                                <p>QUARTA</p>
                                            </div>
                                            <div className='field-periods'>
                                                <PeriodFields
                                                    period="1"
                                                    valuePeriod={wednesdayPeriod1}
                                                    disabledTwo={
                                                        wednesdayPeriod1.initTime === "" ||
                                                        wednesdayPeriod1.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="wednesday_period1_initTime"
                                                    nameEndTime="wednesday_period1_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                    
                                                />
                                                <PeriodFields
                                                    period="2"
                                                    valuePeriod={wednesdayPeriod2}
                                                    disabledOne={
                                                        wednesdayPeriod1.endTime === "" ||
                                                        wednesdayPeriod1.endTime === undefined ?
                                                        true : false
                                                    }
                                                    disabledTwo={
                                                        wednesdayPeriod2.initTime === "" ||
                                                        wednesdayPeriod2.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="wednesday_period2_initTime"
                                                    nameEndTime="wednesday_period2_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <div className='dia'>
                                                <p>QUINTA</p>
                                            </div>
                                            <div className='field-periods'>
                                                <PeriodFields
                                                    period="1"
                                                    valuePeriod={thursdayPeriod1}
                                                    disabledTwo={
                                                        thursdayPeriod1.initTime === "" ||
                                                        thursdayPeriod1.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="thursday_period1_initTime"
                                                    nameEndTime="thursday_period1_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                    
                                                />
                                                <PeriodFields
                                                    period="2"
                                                    valuePeriod={thursdayPeriod2}

                                                    disabledOne={
                                                        thursdayPeriod1.endTime === "" ||
                                                        thursdayPeriod1.endTime === undefined ?
                                                        true : false
                                                    }
                                                    disabledTwo={
                                                        thursdayPeriod2.initTime === "" ||
                                                        thursdayPeriod2.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="thursday_period2_initTime"
                                                    nameEndTime="thursday_period2_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <div className='dia'>
                                                <p>SEXTA</p>
                                            </div>
                                            <div className='field-periods'>
                                                <PeriodFields
                                                    period="1"
                                                    valuePeriod={fridayPeriod1}
                                                    disabledTwo={
                                                        fridayPeriod1.initTime === "" ||
                                                        fridayPeriod1.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="friday_period1_initTime"
                                                    nameEndTime="friday_period1_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                />
                                                <PeriodFields
                                                    period="2"
                                                    valuePeriod={fridayPeriod2}
                                                    disabledOne={
                                                        fridayPeriod1.endTime === "" ||
                                                        fridayPeriod1.endTime === undefined ?
                                                        true : false
                                                    }
                                                    disabledTwo={
                                                        fridayPeriod2.initTime === "" ||
                                                        fridayPeriod2.initTime === undefined ?
                                                        true : false
                                                    }
                                                    nameInitTime="friday_period2_initTime"
                                                    nameEndTime="friday_period2_endTime"
                                                    myChangeHandler={myChangeHandler}
                                                />
                                            </div>
                                        </li>
                                    </>:""
                                }
                            </ul>
                            <div className="radios-same-time bloco-final-semana">
                                <p>Incluir final de semana</p>

                                <div className='radios'>

                                    <button className="btn-radio" onClick={() => setLoadIncludeWeekend(true)}>
                                        <label className={
                                            loadIncludeWeekend &&
                                            loadIncludeWeekend !== "" &&
                                            loadIncludeWeekend === true ?
                                           "chekboxLabel checado" : "chekboxLabel"
                                        }
                                        ></label>
                                        <p className="texto"> Sim </p>
                                    </button>

                                    <button className="btn-radio" onClick={() => setLoadIncludeWeekend(false)}>
                                        <label className={
                                        loadIncludeWeekend === "" ||
                                        loadIncludeWeekend === false ?
                                           "chekboxLabel checado" : "chekboxLabel"
                                        }   ></label>
                                        <p className="texto"> Não </p>
                                    </button>

                                </div>
                            </div>
                            {
                                loadIncludeWeekend === true ?
                                <>
                                 <ul className="block-form-days">
                                    <li>
                                        <div className='dia'>
                                            <p>SÁBADO</p>
                                        </div>
                                        <div className='field-periods'>
                                            <PeriodFields
                                                period="1"
                                                valuePeriod={saturdayPeriod1}
                                                disabledTwo={
                                                    saturdayPeriod1.initTime === "" ||
                                                    saturdayPeriod1.initTime === undefined ?
                                                    true : false
                                                }
                                                nameInitTime="saturday_period1_initTime"
                                                nameEndTime="saturday_period1_endTime"
                                                myChangeHandler={myChangeHandler}
                                            />

                                            <PeriodFields
                                                period="2"
                                                valuePeriod={saturdayPeriod2}
                                                disabledOne={
                                                    saturdayPeriod1.endTime === "" ||
                                                    saturdayPeriod1.endTime === undefined ?
                                                    true : false
                                                }
                                                disabledTwo={
                                                    saturdayPeriod2.initTime === "" ||
                                                    saturdayPeriod2.initTime === undefined ?
                                                    true : false
                                                }
                                                nameInitTime="saturday_period2_initTime"
                                                nameEndTime="saturday_period2_endTime"
                                                myChangeHandler={myChangeHandler}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className='dia'>
                                            <p>DOMINGO</p>
                                        </div>
                                        <div className='field-periods'>
                                            <PeriodFields
                                                period="1"
                                                valuePeriod={sundayPeriod1}
                                                disabledTwo={
                                                    sundayPeriod1.initTime === "" ||
                                                    sundayPeriod1.initTime === undefined ?
                                                    true : false
                                                }
                                                nameInitTime="sunday_period1_initTime"
                                                nameEndTime="sunday_period1_endTime"
                                                myChangeHandler={myChangeHandler}
                                            />
                                            <PeriodFields
                                                period="2"
                                                valuePeriod={sundayPeriod2}
                                                disabledOne={
                                                    sundayPeriod1.endTime === "" ||
                                                    sundayPeriod1.endTime === undefined ?
                                                    true : false
                                                }
                                                disabledTwo={
                                                    sundayPeriod2.initTime === "" ||
                                                    sundayPeriod2.initTime === undefined ?
                                                    true : false
                                                }
                                                nameInitTime="sunday_period2_initTime"
                                                nameEndTime="sunday_period2_endTime"
                                                myChangeHandler={myChangeHandler}
                                            />
                                        </div>
                                    </li>
                                 </ul>
                                </>
                                :""
                            }
                            <div className='total-horas'>
                                <p>Total: {totalTimeWorking !== "" ? totalTimeWorking : "00:00"} horas</p>
                            </div>
                        </div>
                    </div>
                    <div className='btn-page-journeys'>
                        <button 
                        onClick={()=>saveWorkJourney()} 
                        type="submit" className={loadSaveButton ? "btnGreen load" : "btnGreen"}>
                        Salvar
                        </button>
                    </div>
                </div>
            </Layout>
        </>
    );
}
