import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import SubMenu from './subMenu';
import iconVerMais from '../../../../../assets/images/more.svg';
import Carregamento from '../../../../../assets/images/carregando.gif';
import iconUpload from '../../../../../assets/images/docs-upload.svg';
import Close from '../../../../../assets/images/close.svg';
import api from '../../../../../services/api';
import apiV2 from '../../../../../services/apiV2';
import { dateMask } from '../../../../../services/mask';
import { momentDurationTotalDay } from '../../../../../services/utils';
import SelectPersonalizado from '../../../../Reused/selectPersonalizado'

export default function ColProfilePersonal({ id, collaboratorState, role, path }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInitialDate, setLoadInitialDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFinalDate, setLoadFinalDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadReason, setLoadReason ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadJustificationName, setLoadJustificationName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ absences, setAbsences ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ absencesUpdate, setAbsencesUpdate ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredAbsences, setRegisteredAbsences ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) {

            loadabsences(id);

        }

    }, [id]);

    async function loadabsences(id) {
        setLoadPage(true)
        await api.get(`/collaborator-absences/${id}`)
        .then(response => {

            setLoadPage(false)
            setLoad(false)
            setAbsencesUpdate([]);
            setFileNames("");
            setAbsences([]);
            if(response.data[0] !== undefined) {
                setAbsences([]);
                setRegisteredAbsences(response.data);
            }
            else {setRegisteredAbsences([]);}
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filtrerAbsences, setFilterAbsences ] = useState([]);

    async function loadfilterabsences(event) {
        const name = event.target.value;
        setLoad(true)
        await api.get(`/collaborator-absences?id=${id}&name=${name}`)
        .then(response => {

            setLoad(false)
            setFilterAbsences(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    async function deleteAbsences(absences) {
        swal({
        title: "Atenção",
        text: "Deseja realmente excluir?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-absences/${absences}?path=${path}`)
                .then(() => {

                    loadabsences(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Falta ou afastamento removido com sucesso." });

                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover, tente novamente mais tarde." });
                });
            }
        });
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visualizarvisible, setVisualizarVisible] = useState(false);

    //CloseAbsencesVisible
    function CloseAbsencesVisible() {
        setAbsencesUpdate([])
        setFileNames("");
        setVisible(false);
        setVisualizarVisible(false);
        setAbsences([]);
        setLoadReason(false)
        setLoadJustificationName(false)
        setLoadInitialDate(false)
        setLoadFinalDate(false)
        setSelectAbsencesReason(false)
        setSelectAbsencesJustName(false)
    }

    function AddAndEditAbsencesVisible(e) {
        setAbsencesUpdate([]);
        if(e !== undefined) {
            let file = {};
            if(e?.file !== undefined && e?.file !== null ){
                file = e.file;
            }  else if(e?.originalname !== undefined && e?.originalname !== null){
                file = {
                    "url": e.location,
                    "name": e.originalname,
                    "format": e.mimetype,
                }
            } else {
                file = null;
            }
            
            let absencesItem = {
                "_id": e._id,
                "collaborator": e.collaborator,
                "description": e?.description,
                "finalDate": e?.finalDate,
                "initialDate": e?.initialDate ?? null,
                "CID": e?.CID ?? null,
                "justificationName": e?.justificationName  ?? null,
                "reason": e?.reason ?? null,
                "file": file,
            };
            setAbsencesUpdate({
                ...absencesItem
            })
        }
        setFileNames("");
        setVisible(!visible);
        setAbsences([]);
        setLoadReason(false)
        setLoadJustificationName(false)
        setLoadInitialDate(false)
        setLoadFinalDate(false)
    }

    function VisualizarAbsencesVisible(e) {
        let file = {};

        if(e?.file !== undefined && e?.file !== null  ){
            file = e.file;
        }  else if(e?.originalname !== undefined && e?.originalname !== null){
            file = {
                "url": e.location,
                "name": e.originalname,
                "format": e.mimetype,
            }
        } else {
            file = null;
        }
        
        let absencesItem = {
            "description": e?.description ?? null,
            "finalDate": e?.finalDate ?? null,
            "initialDate": e?.initialDate ?? null,
            "justificationName": e?.justificationName  ?? null,
            "CID": e?.CID  ?? null,
            "reason": e?.reason ?? null,
            "file": file ,
        }
        setAbsences({
            ...absencesItem
        })
        setFileNames("");
        setVisualizarVisible(!visualizarvisible);
        setLoadReason(false)
        setLoadJustificationName(false)
        setLoadInitialDate(false)
        setLoadFinalDate(false)
        setAbsencesUpdate([])
    }

    let collaborator = collaboratorState._id;

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        //reason
        if (nam === "reason") {
            setLoadReason(false)
            setAbsences({ ...absences, collaborator, [nam]: val })
        }
        //justificationName
        else
        if (nam === "justificationName") {
            setLoadJustificationName(false)
            setAbsences({ ...absences, collaborator, [nam]: val })
        }
        //initialDate
        else
        if (nam === "initialDate") {

            setLoadInitialDate(false)
            let initialDate = dateMask(val)
            setAbsences({ ...absences, collaborator, [nam]: initialDate })

        }
        //finalDate
        else
        if (nam === "finalDate") {

            setLoadFinalDate(false)
            let finalDate = dateMask(val)
            setAbsences({ ...absences, collaborator, [nam]: finalDate })

        }

        else {

            setAbsences({ ...absences, collaborator, [nam]: val })
        }

    };
    function removeEmptyFields(obj) {
        if (typeof obj !== 'object' || obj === null) {
          return obj;
        }
      
        if (Array.isArray(obj)) {
          return obj.map(item => removeEmptyFields(item)).filter(item => item !== null);
        }
      
        const newObj = {};
        for (const key in obj) {
          const value = removeEmptyFields(obj[key]);
          if (value !== null && value !== undefined && (!Array.isArray(value) || value.length > 0)) {
            newObj[key] = value;
          }
        }
        
        return Object.keys(newObj).length > 0 ? newObj : null;
    }
    async function handleSubmit(event) {
        event.preventDefault();
        if(absencesUpdate._id !== undefined) {
            if(absences && absences.length === 0) {
                swal({ icon: "error", title: "Erro!", text: 'Necessário atualizar pelo menos um campo!' });
            } else {
                if(
                    absences.finalDate    === "" ||
                    absences.initialDate  === ""
                ) {
                    swal({ icon: "error", title: "Erro!", text: 'Apenas os campos Descrição e CID podem ser salvos em branco' });
                    if (absences?.initialDate === "" || absences?.initialDate?.length < 10) {
                        setLoadInitialDate(true)
                    }
                    if (absences?.finalDate === "" || absences?.finalDate?.length < 10) {
                        setLoadFinalDate(true)
                    }
                    return

                } else {
                    let absencesItem = {} 

                    if (absences.reason !== undefined) {
                        absencesItem = {...absencesItem, reason: absences.reason}
                    }
                    if (absences.justificationName !== undefined) {
                        absencesItem = {...absencesItem, justificationName: absences.justificationName}
                    }
                    if (absences.initialDate !== undefined) {
                        absencesItem = {...absencesItem, initialDate: absences.initialDate}
                    }
                    if (absences.finalDate !== undefined) {
                        absencesItem = {...absencesItem, finalDate: absences.finalDate}
                    }
                    if (absences.description !== undefined) {
                        absencesItem = {...absencesItem, description: absences.description}
                    }
                    if (absences.CID !== undefined) {
                        absencesItem = {...absencesItem, CID: absences.CID}
                    }
                    if (absences.file !== undefined) {
                        if(absences.file === ""){
                            absencesItem = {...absencesItem, 
                                file: null,
                                size: null,
                                originalname: null,
                                mimetype: null,
                                location: null,
                                key: null,
                            }
                        } else {
                            absencesItem = {...absencesItem, file: absences.file}
                        }
                    }
                    setLoad(true)
                    try {
                        await apiV2.patch(`/absence/${absencesUpdate._id}`, absencesItem)
                        setLoad(false)
                        setFileNames("")
                        loadabsences(id);
                        swal({ icon: "success", title: "Sucesso!", text: "Falta ou afastamento atualizado com sucesso!"});
                        setVisible(!visible);
                    } catch (error) {
                        swal({ icon: "error", title: "Erro!", text: error.message ?? "Error ao editar falta ou afastamento"  });
                        setLoad(false)
                    }
                }
            }
        } else {
            if (absences.reason !== undefined) {
                setLoadReason(false)
            } else {
                setLoadReason(true)
            }
            if (absences.justificationName !== undefined) {
                setLoadJustificationName(false)
            } else {
                setLoadJustificationName(true)
            }
            if (absences.initialDate !== undefined && absences.initialDate.length >= 10) {
                setLoadInitialDate(false)
            } else {
                setLoadInitialDate(true)
            }
            if (absences.finalDate !== undefined && absences.finalDate.length >= 10) {
                setLoadFinalDate(false)
            } else {
                setLoadFinalDate(true)
            }
            if (
                absences.reason            !== undefined &&
                absences.justificationName !== undefined &&
                absences.initialDate       !== undefined &&
                absences.initialDate.length >=    10     &&
                absences.finalDate         !== undefined &&
                absences.finalDate.length   >=    10     &&
                absences.finalDate         !== undefined
            ) {
                setLoad(true)
                let absencesItem = {
                    "collaborator": absences.collaborator,
                    "description": absences?.description,
                    "finalDate": absences?.finalDate,
                    "initialDate": absences?.initialDate ?? null,
                    "justificationName": absences?.justificationName  ?? null,
                    "reason": absences?.reason ?? null,
                    "file": absences.file ?? {},
                } 
                try {
                    await apiV2.post(`/absence`, removeEmptyFields(absencesItem))
                    setLoad(false)
                    setFileNames("")
                    loadabsences(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Falta ou afastamento criado com sucesso!" });
                    setVisible(!visible);
                } catch (error) {
                    swal({ icon: "error", title: "Erro!", text:  error.message ?? "Error ao salvar falta ou afastamento" });
                    setLoad(false)
                }
            }
        }
    }
    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        if (file.size > maxSize) {
          swal({ icon: "error", title: "Erro!", text: "Arquivo é muito grande! Limite de 2MB." });
          return;
        }

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            acceptedFiles.map((file) => {
                // eslint-disable-next-line
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setAbsences({...absences, "file": {
                        format: file.type.split("/")[1],
                        name: file.name,
                        base64: reader.result,
                    }})
                    setFileNames(file.name);
                };
                return null;
             })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    /// => Selects personalizado
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectAbsencesReason, setSelectAbsencesReason] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectAbsencesJustName, setSelectAbsencesJustName] = useState(false);


    async function closeAbsencesSelect(event) {
        if(
            event.target.className !== "btn-select selectAbsencesReason" &&
            event.target.className !== "btn-select selectAbsencesJustName"
        ) {
            setSelectAbsencesReason(false)
            setSelectAbsencesJustName(false)
            if (event.target.className === "span-select-selectAbsencesReason") {
                setSelectAbsencesReason(!selectAbsencesReason)

            } else if (event.target.className === "span-select-selectAbsencesJustName"){
                setSelectAbsencesJustName(!selectAbsencesJustName)

            }
        }
    }

    async function deleteFile(e){
        setFileNames("");
        setAbsencesUpdate({ ...absencesUpdate, file: undefined });
        setAbsences({ ...absences, file: "" });
    }

    return (
        <>
        <SubMenu id={id} collaborator={collaborator} role={role} path={path} />
            <div className="box-infor-perfil box-infor-faltas">
                <div className="box">
                <div className="buscaAdd">
                    <form action="">
                        <input type="text" onChange={(event) => loadfilterabsences(event)} placeholder="Procure pelo mês e ano" className="busca"/>
                    </form>

                    {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                        ""
                        :
                        (role && role === 'admin') || (role && role === 'master') ?
                        <button className="btnGreen more" onClick={() => AddAndEditAbsencesVisible()}>Adicionar</button>
                        :
                        ''
                    }

                </div>
                <table>
                    <thead>
                        <tr className="tabelaTR">
                            <th className="inten-1">
                                    Motivo 
                            </th>
                            <th className="inten-2">Período</th>
                            <th className="inten-3">Total de dias</th>
                            <th className="inten-4"></th>
                            <th className="inten-5"></th>
                        </tr>
                    </thead>
                    <tbody>


                    {
                    loadPage ?
                    (
                        <>
                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        </>
                    ):
                    filtrerAbsences[0] !== undefined ? filtrerAbsences
                    .map((filtrerabsences, index) => (
                    <>
                    <tr className="tabelaTR">
                        <td className="inten-1" onClick={() => VisualizarAbsencesVisible(filtrerAbsences[0])} >
                                <div className="motivo">
                                    <strong>{filtrerabsences.reason}</strong>
                                    <p>
                                    {`${filtrerabsences.justificationName} `}

                                    {filtrerabsences.CID !== undefined ?
                                    (
                                        <>
                                        -
                                        {` ${filtrerabsences.CID}`}
                                        </>
                                    ) :
                                    ('')}
                                    </p>
                                </div>
                        </td>
                        <td className="inten-2" onClick={() => VisualizarAbsencesVisible(filtrerAbsences[0])} >
                                    {`${filtrerabsences.initialDate} `}

                                    {filtrerabsences.initialDate !== filtrerabsences.finalDate ?
                                    (
                                        <>
                                        até
                                        {` ${filtrerabsences.finalDate}`}
                                        </>
                                    ) :
                                    ('')}
                        </td>
                        <td className="inten-3" onClick={() => VisualizarAbsencesVisible(filtrerAbsences[0])} >
                        <p>
                        {
                            momentDurationTotalDay(filtrerabsences.initialDate,filtrerabsences.finalDate)
                        }
                        </p>
                        </td>
                        <td className="inten-4">
                            {
                                   ( filtrerabsences?.originalname !== undefined && filtrerabsences.originalname !== null) ||
                                    filtrerabsences?.file?.name !== undefined  ?
                                (
                                    <>
                                        <a href={filtrerabsences?.file?.url ?? filtrerabsences?.location} target="’_blank’">
                                            <img alt="icone de documentos" src={iconUpload} />
                                            <span>{filtrerabsences?.file?.name ?? filtrerabsences?.originalname}</span>
                                        </a>
                                    </>
                                ) :
                                ('----')
                            }  
                        </td>
                        <td className="inten-5">


                            {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                    <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                            <button  onClick={() => VisualizarAbsencesVisible(filtrerAbsences[0])} name="_id" value="" type="submit">
                                                Visualizar justificativa
                                            </button>

                                            {
                                                (role && role === 'admin') || (role && role === 'master') ?
                                                <>
                                                <button  onClick={() => AddAndEditAbsencesVisible(filtrerAbsences[0])} name="_id" value="" type="submit">
                                                    Editar
                                                </button>

                                                <div>
                                                    <button onClick={() => deleteAbsences(filtrerAbsences[0]._id)} name="_id" value="" type="submit" className="red">
                                                    Excluir
                                                    </button>
                                                </div>
                                                </>
                                                :
                                                ''
                                            }
                                    </Dropdown.Menu>
                                </Dropdown>
                            }

                        </td>
                    </tr>
                    </>
                    )) :
                    registeredAbsences.length === 0 ?
                    (<div className="nenhum-cadastrado-perfil"><p>Nenhuma falta ou afastamento até o momento!</p></div>):
                    registeredAbsences !== undefined ? registeredAbsences
                    .map((absences, index) => (
                        <>
                        <tr className="tabelaTR">
                            <td className="inten-1" onClick={() => VisualizarAbsencesVisible(absences)} >
                                    <div className="motivo">
                                        <strong>{absences.reason}</strong>
                                        <p>
                                        {`${absences.justificationName} `}

                                        {absences.CID !== undefined ?
                                        (
                                            <>
                                            -
                                            {` ${absences.CID}`}
                                            </>
                                        ) :
                                        ('')}
                                        </p>
                                    </div>
                            </td>
                            <td className="inten-2" onClick={() => VisualizarAbsencesVisible(absences)} >
                                        {`${absences.initialDate} `}

                                        {absences.initialDate !== absences.finalDate ?
                                        (
                                            <>
                                            até
                                            {` ${absences.finalDate}`}
                                            </>
                                        ) :
                                        ('')}
                            </td>
                            <td className="inten-3"  onClick={() => VisualizarAbsencesVisible(absences)} >
                            <p>
                            {
                                momentDurationTotalDay(absences.initialDate,absences.finalDate)
                            }
                            </p>
                            </td>
                            <td className="inten-4">
                                {
                                   ( absences?.originalname !== undefined && absences.originalname !== null) ||
                                    absences?.file?.name !== undefined ?
                                    (
                                        <>
                                            <a href={absences?.file?.url ?? absences?.location !== undefined} target="’_blank’">
                                                <img alt="icone de documentos" src={iconUpload} />
                                                <span>{absences?.file?.name ?? absences?.originalname}</span>
                                            </a>
                                        </>
                                    ) :
                                    ('----')
                                }
                            </td>
                            <td className="inten-5">
                                {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                    ""
                                    :
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                                <button  onClick={() => VisualizarAbsencesVisible(absences)} name="_id" value="" type="submit">
                                                    Visualizar justificativa
                                                </button>
                                                {
                                                    (role && role === 'admin') || (role && role === 'master') ?
                                                    <>
                                                    <button  onClick={() => AddAndEditAbsencesVisible(absences)} name="_id" value="" type="submit">
                                                        Editar
                                                    </button>
                                                    <div>
                                                        <button onClick={() => deleteAbsences(absences._id)} name="_id" value="" type="submit" className="red">
                                                        Excluir
                                                        </button>
                                                    </div>
                                                    </>
                                                    :
                                                    ''
                                                }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                            </td>
                        </tr>
                        </>
                        )) : (
                        <>
                        </>
                    )}
                    </tbody>
                </table>
                </div>
            </div>
            <Modal show={visualizarvisible} className="popup-edit-perfil popup-edit-perfil-faltas" onHide={CloseAbsencesVisible}>
                <div>
                    <div className="titulo">
                        <h4>Falta e Afastamentos <p className="btn-fechar" onClick={CloseAbsencesVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul className="visualizar">
                            <li>
                                <div className="intemA">
                                    <p>Motivo</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                        {absences.reason}
                                    </strong>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Tipo</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                        {absences.justificationName}
                                    </strong>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>De</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                        {absences.initialDate}
                                    </strong>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Até</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                        {absences.finalDate}
                                    </strong>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Descrição</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                        {absences?.description ?? '-'}
                                    </strong>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>CID</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                        {absences?.CID !== null ? (absences.CID):('-')}
                                    </strong>
                                </div>
                            </li>
                            {
                            absences?.file !== undefined &&
                            absences?.file !== null  ?
                            <li>
                                <div className="intemA">
                                    <p>Comprovante</p>
                                </div>
                                <div className="intemB">
                                    <strong>
                                    <a
                                        href={absences.file.url} target="’_blank’" >
                                            <span>
                                                {absences.file.name }
                                            </span>
                                    </a>



                                    </strong>

                                </div>
                            </li>
                            :""}
                    </ul>
                </div>
            </Modal>
            <Modal show={visible} className="popup-edit-perfil popup-edit-perfil-faltas" onHide={CloseAbsencesVisible}>
                <div onClick={closeAbsencesSelect}>
                    <div className="titulo">
                        <h4>Falta e Afastamentos <p className="btn-fechar" onClick={CloseAbsencesVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Motivo *</p>
                            </div>
                            <div className="intemB">
                                <SelectPersonalizado
                                    valorCampo={
                                        absences && absences.reason !== undefined ?
                                        absences.reason :
                                        absencesUpdate && absencesUpdate.reason !== undefined ?
                                        absencesUpdate.reason : ""
                                    }
                                    nameButton={"reason"}
                                    myChangeHandler={myChangeHandler}
                                    // option={optionRelationshipEmergencyContact}
                                    loadError={loadReason}
                                
                                    optionFil={[
                                        "Falta", "Afastamento por doença", "Afastamento por acidente",
                                        "Licença maternidade", "Licença paternidade", "Outros",
                                        "Afastamento por suspensão", "Licença Casamento", "Falecimento familiar",
                                        "Suspensão Contrato de Trabalho"
                                    ]}
                                    typeSelect="busca2"
                                />
                                <p className="inputDois textError">{loadReason ? 'É necessário escolher um motivo' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo *</p>
                            </div>
                            <div className="intemB">
                                <SelectPersonalizado
                                    valorCampo={
                                        absences && absences.justificationName !== undefined ?
                                        absences.justificationName :
                                        absencesUpdate && absencesUpdate.justificationName !== undefined ?
                                        absencesUpdate.justificationName : ""
                                    }
                                    nameButton={"justificationName"}
                                    myChangeHandler={myChangeHandler}
                                    // option={optionRelationshipEmergencyContact}
                                    loadError={loadJustificationName}
                                
                                    optionFil={[
                                        "Justificada", "Não justificada", "Atestado médico",
                                        "Superior a 15 dias", "Novo afastamento - Mesmo acidente de trabalho",
                                        "Igual ou inferior a 15 dias", "Novo afastamento - Mesma doença 60 dias",
                                        "Igual ou inferior a 15 dias", "Prorrogação da licença maternidade",
                                        "Aborto não criminoso", "Adoção (até 1 ano de idade);", "Adoção (1 a 4 anos de idade)",
                                        "Adoção (a partir de 4 anos de idade)", "Serviço militar", "Mandato judicial",
                                        "Licença sem vencimento", "Outros motivos de vencimento temporário",
                                        "5 dias de licença-paternidade", "Nascimento natural - 180 dias",
                                        "Licença remunerada", "Licença não remunerada", "Aposentadoria por invalidez",
                                        "Licença maternidade", "Covid 19 MP936/2020"
                                        ]}
                                    typeSelect="busca2"
                                />
                                <p className="inputDois textError">{loadJustificationName ? 'É necessário escolher um tipo' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>De *</p>
                            </div>
                            <div className="intemB">
                                {
                                    (absences && absences.initialDate !== undefined) || absences.initialDate === "" || (absencesUpdate && absencesUpdate.initialDate !== undefined)  ?
                                    <input type="text"  maxlength="10" name="initialDate" autocomplete="off" value={(absences && absences.initialDate) || absences.initialDate === "" ? absences.initialDate : absencesUpdate && absencesUpdate.initialDate ? absencesUpdate.initialDate : ''} className={loadInitialDate ? ' inputErro' : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  />
                                    :
                                    registeredAbsences && registeredAbsences.initialDate ?
                                    <input type="text"  name="initialDate" autocomplete="off" maxlength="10" defaultValue={registeredAbsences && registeredAbsences.initialDate !== undefined ? registeredAbsences.initialDate : ''}  className={loadInitialDate ? ' inputErro' : ''} onChange={myChangeHandler} placeholder="DD/MM/AAAA"/>
                                    :
                                    <input type="text"  maxlength="10" name="initialDate" autocomplete="off" value={absences && absences.initialDate !== undefined ? absences.initialDate : ''} className={loadInitialDate ? ' inputErro' : ''} onChange={myChangeHandler} placeholder="DD/MM/AAAA"  />
                                }
                                <p className="inputUm textError">{loadInitialDate ? 'É necessário adicionar uma data' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Até *</p>
                            </div>
                            <div className="intemB">
                                {
                                    (absences && absences.finalDate !== undefined) || absences.finalDate === "" || (absencesUpdate && absencesUpdate.finalDate !== undefined) ?
                                    <input type="text"  maxlength="10" name="finalDate" autocomplete="off" value={(absences && absences.finalDate) || absences.finalDate === "" ? absences.finalDate : absencesUpdate && absencesUpdate.finalDate ? absencesUpdate.finalDate : ''} className={loadFinalDate ? ' inputErro' : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  />
                                    :
                                    registeredAbsences && registeredAbsences.finalDate ?
                                    <input type="text"  name="finalDate" autocomplete="off" maxlength="10" defaultValue={registeredAbsences && registeredAbsences.finalDate !== undefined ? registeredAbsences.finalDate : ''}  className={loadFinalDate ? ' inputErro' : ''} onChange={myChangeHandler} placeholder="DD/MM/AAAA"/>
                                    :
                                    <input type="text"  maxlength="10" name="finalDate" autocomplete="off" value={absences && absences.finalDate ? absences.finalDate : ''} className={loadFinalDate ? ' inputErro' : ''} onChange={myChangeHandler} placeholder="DD/MM/AAAA"  />
                                }
                                <p className="inputUm textError">{loadFinalDate ? 'É necessário adicionar uma data' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Descrição</p>
                            </div>
                            <div className="intemB">
                                {
                                (absencesUpdate && absencesUpdate.description !== undefined) || (absences && absences.description !== undefined) ?
                                <textarea name="description" cols="150" onChange={myChangeHandler}  className="campo-infor" defaultValue={absencesUpdate && absencesUpdate.description ? absencesUpdate.description : absences && absences.description ? absences.description : '' }></textarea>
                                :
                                <textarea name="description" cols="150" onChange={myChangeHandler}  className="campo-infor" placeholder="Justificativa"></textarea>
                                }
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>CID</p>
                            </div>
                            <div className="intemB">
                                {
                                    (absencesUpdate && absencesUpdate.CID !== undefined) ||
                                    (absences && absences.CID !== undefined) ?
                                    <input type="text" name="CID" autocomplete="off"   onChange={myChangeHandler} defaultValue={absencesUpdate && absencesUpdate.CID ? absencesUpdate.CID : absences && absences.CID ? absences.CID : ''}/>
                                    :
                                    <input type="text" name="CID" autocomplete="off"  placeholder="CID" onChange={myChangeHandler} />
                                }
                            </div>
                        </li>
                        
                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div className="intemB">
                                <Dropzone onDrop={handleDrop} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>
                                        {
                                           absencesUpdate?.file === undefined && fileNames === "" ? "Anexe um arquivo" : "Alterar o arquivo"
                                        }
                                       </label>
                                    </div>
                                    )}
                                </Dropzone>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                            </div>
                        </li>

                        {
                            fileNames !== "" ?
                                <li>
                                    <div className="arquivoAnexado">
                                        <h5>Enviado</h5>

                                            <div className="arquivo">
                                                <p className="nomeArquivo">
                                                    <span>
                                                    {fileNames ?? ""}
                                                    </span>
                                                </p>
                                                <button onClick={() => deleteFile("fileNames")} className="btnExclui">Remover anexo</button>
                                            </div>

                                    </div>
                                </li>
                            :  
                            absencesUpdate?.file !== undefined &&
                            absencesUpdate?.file !== null ?
                            <>
                                <li>
                                    <div className="arquivoAnexado">
                                        <h5>Enviado</h5>

                                            <div className="arquivo">
                                                <p className="nomeArquivo">

                                                <a
                                                href={absencesUpdate?.file.url} target="’_blank’" >
                                                    <span>
                                                        {absencesUpdate?.file.name }
                                                    </span>
                                                    </a>
                                                </p>
                                                <button onClick={() => deleteFile(absencesUpdate)} className="btnExclui">Remover anexo</button>
                                            </div>

                                    </div>

                                </li>
                            </> :""
                        }


                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={handleSubmit}  className="btn-salvar">
                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>
                                            Salvar
                                    </button>
                                </form>
                            </div>
                        </li>



                    </ul>


                </div>
            </Modal>
        </>
    );
}
