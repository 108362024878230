import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../components/Layouts/defaultUser';
import OperadorSodexo from '../../assets/images/operador-logo-sodexo.svg';
import OperadorTicket from '../../assets/images/operador-logo-ticket.svg';
import OperadorUnimed from '../../assets/images/operador-logo-unimed.svg';
import Carregamento from '../../assets/images/carregando.gif';
import OperadorAlelo from '../../assets/images/operador-logo-alelo.svg';
import OperadorVR from '../../assets/images/operador-logo-vr.svg';
import OperadorFlash from '../../assets/images/operador-logo-flash.svg';
import OperadorSwile from '../../assets/images/operador-logo-swile.svg';
import OperadorClinipam from '../../assets/images/operador-logo-clinipam.svg';
import OperadorBradesco from '../../assets/images/operador-logo-bradesco.svg';
import OperadorParanaClinicas from '../../assets/images/operador-logo-parana-clinicas.png';
import OperadorSulAmerica from '../../assets/images/operador-logo-sulamerica.svg';
import OperadorAmil from '../../assets/images/operador-logo-amil.svg';
import OperadorNossaSaude from '../../assets/images/operador-logo-nossa-saude.svg';
import OperadorMedSul from '../../assets/images/operador-logo-medsul.png';
import OperadorDentalUni from '../../assets/images/operador-logo-dental-uni.png';
import OperadorAmilDental from '../../assets/images/operador-logo-amil-dental.svg';
import OperadorSulAmericaOdonto from '../../assets/images/operador-logo-sulamerica-dental.svg';
import OperadorPortoSeguro from '../../assets/images/operador-logo-porto-seguro.svg';
import OperadorBradescoSeguroDental from '../../assets/images/operador-logo-bradesco-dental.svg';
import OperadorDentalprev from '../../assets/images/operador-logo-dentalprev.svg';
import OperadorMetlife from '../../assets/images/operador-logo-metlife.svg';
import OperadorPrudential from '../../assets/images/operador-logo-prudential.svg';
import OperadorSantander from '../../assets/images/operador-logo-santander.svg';
import OperadorZenklub from '../../assets/images/operador-logo-zenklub.svg';
import OperadorKeiken from '../../assets/images/operador-logo-keiken.png';
import OperadorGympass from '../../assets/images/operador-logo-gympass.svg';
import OperadorTotalPass from '../../assets/images/operador-logo-totalpass.svg';
import FoodGroceries from '../../assets/images/illustration-food-groceries.svg';
import api from '../../services/api';
import { toast } from 'react-toastify';

const UserMyBenefits = (props) => {
    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBD, setCollaboratorBD ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCollaboratorBDOutra ] = useState([]);

    // const itensCopyCollaborator = [];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadcollaboratorbeneftisaccess();
        loadcollaboratorbeneftisdatauser();

    }, []);


    async function loadcollaboratorbeneftisaccess() {
        setLoadPage(true)
        await api.get("/collaborator-beneftis-access/?status=Ativo")
        .then(response => {

            if(response.data.length > 0) {

                const itensCopyCollaborator = []

                for(let cba=0; cba < response.data.length; cba++){

                    if(
                        response.data[cba] &&
                        response.data[cba]?.status === 'Ativo'
                    ) {

                        itensCopyCollaborator.push(response.data[cba]);
                    }


                }

                setCollaboratorBD(itensCopyCollaborator)

            }

            setLoadPage(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }



    async function loadcollaboratorbeneftisdatauser() {
        setLoadPage(true)
        await api.get("/collaborator-beneftis-data-user/")
        .then(response => {

            setCollaboratorBDOutra(response.data);
            setLoadPage(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    var data = new Date();
    var Mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    var ProximoAno = parseInt(ano) + parseInt(1);

    return (
        <>
            <Layout path={path}>
                    <div className="user-my-benefits">
                    {
                        loadPage ?
                        (
                            <>

                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>

                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            </>
                        ):
                        collaboratorBD.length !== 0 ? collaboratorBD
                        .map((beneftisList) => (
                        <>

                        <div
                            className={
                                beneftisList.benefits?.category                 === 'Refeição'    ? 'my-benef vl-refeicao' :
                                beneftisList.benefits?.category                 === 'Transporte'    ? 'my-benef vl-transporte' :
                                beneftisList.benefits?.category                 === 'Combustível'    ? 'my-benef vl-combustivel' :
                                beneftisList.benefits?.category                 === 'Alimentação'    ? 'my-benef vl-alimentacao' :
                                beneftisList.benefits?.category                 === 'Mobilidade'    ? 'my-benef vl-mobilidade' :
                                beneftisList.benefits?.category                 === 'Estacionamento'    ? 'my-benef vl-estacionamento' :
                                beneftisList.benefits?.category                 === 'Plano de Saúde'    ? 'my-benef vl-plano-saude' :
                                beneftisList.benefits?.category                 === 'Plano Odontológico'    ? 'my-benef vl-plano-odonto' :
                                beneftisList.benefits?.category                 === 'Seguro de Vida'    ? 'my-benef vl-seguro-vida' :
                                beneftisList.benefits?.category                 === 'Plano de Saúde Pet'    ? 'my-benef vl-plano-saude-pet' :
                                beneftisList.benefits?.category                 === 'Convênio Farmácia'    ? 'my-benef vl-convenio-farmacia' :
                                beneftisList.benefits?.category                 === 'Assistência à Educação'    ? 'my-benef vl-assistencia-educacao' :
                                beneftisList.benefits?.category                 === 'Cultura'    ? 'my-benef vl-cultura' :
                                beneftisList.benefits?.category                 === 'Auxílio home-office'    ? 'my-benef vl-auxilio-home-office' :
                                beneftisList.benefits?.category                 === 'Bem-estar'    ? 'my-benef vl-bem-estar' :
                                beneftisList.benefits?.category                 === 'Clube de Benefícios'    ? 'my-benef vl-clube-beneficios' :
                                beneftisList.benefits?.category                 === 'Premiação'    ? 'my-benef vl-premiacao' :
                                beneftisList.benefits?.category                 === 'Day Off'    ? 'my-benef vl-day-off' :
                                beneftisList.benefits?.category                 === 'Previdência Privada'    ? 'my-benef vl-previdencia-privada' :
                                beneftisList.benefits?.category                 === 'Licença maternidade'    ? 'my-benef vl-licenca-mater-paternidade' :
                                beneftisList.benefits?.category                 === 'Licença Paternidade estendida'    ? 'my-benef vl-licenca-mater-paternidade' :
                                beneftisList.benefits?.category                 === 'Alimentação e Refeição'    ? 'my-benef vl-alimentacao-refeicao' :
                                beneftisList.benefits?.category                 === 'Outros'    ? 'my-benef vl-outros' :'my-benef vl-outros'
                            }
                            key={beneftisList.benefits?.name}
                            >
                            <h3>{beneftisList.benefits?.name }</h3>
                            <ul>
                            {beneftisList.benefitsbenefits?.benefitDiscount === true ?
                            <>
                                <li> <p>Data do crédito</p> <span>01

                                {
                                    Mes  === '12'    ? ' Janeiro ' :
                                    Mes  === '01'    ? ' Fevereiro ' :
                                    Mes  === '02'    ? ' Março ' :
                                    Mes  === '03'    ? ' Abril ' :
                                    Mes  === '04'    ? ' Maio ' :
                                    Mes  === '05'    ? ' Junho ' :
                                    Mes  === '06'    ? ' Julho ' :
                                    Mes  === '07'    ? ' Agosto ' :
                                    Mes  === '08'    ? ' Setembro ' :
                                    Mes  === '09'    ? ' Outubro ' :
                                    Mes  === '10'   ? ' Novembro ' :
                                    Mes  === '11'   ? ' Dezembro'  : ' '
                                }
                                {
                                    Mes !== 11 ? ano : ProximoAno
                                }
                                    </span></li>
                            </>:
                            <>
                                <li> <p>Desconto no salário</p> <span>{beneftisList.benefits?.discountPercentage }%</span></li>

                            </> }
                            </ul>
                            <div className="operador">

                                {
                                    beneftisList.benefits &&
                                    beneftisList.benefits.operator &&
                                    beneftisList.benefits.operator !== undefined ?
                                    <>
                                        <img src={
                                        beneftisList.benefits.operator                === 'Zenklub'    ? OperadorZenklub :
                                        beneftisList.benefits.operator                === 'Keiken'    ? OperadorKeiken :
                                        beneftisList.benefits.operator                === 'Gympass'    ? OperadorGympass :
                                        beneftisList.benefits.operator                === 'Total Pass'    ? OperadorTotalPass :

                                        beneftisList.benefits.operator                === 'Prudential'    ? OperadorPrudential :
                                        beneftisList.benefits.operator                === 'Metlife'    ? OperadorMetlife :
                                        beneftisList.benefits.operator                === 'Santander'    ? OperadorSantander :

                                        beneftisList.benefits.operator                === 'Dental Uni'    ? OperadorDentalUni :
                                        beneftisList.benefits.operator                === 'Amil Dental'    ? OperadorAmilDental :
                                        beneftisList.benefits.operator                === 'Sul América Odonto'    ? OperadorSulAmericaOdonto :
                                        beneftisList.benefits.operator                === 'Porto Seguro'    ? OperadorPortoSeguro :
                                        beneftisList.benefits.operator                === 'Bradesco Seguro Dental'    ? OperadorBradescoSeguroDental :
                                        beneftisList.benefits.operator                === 'Dentalprev'    ? OperadorDentalprev :


                                        beneftisList.benefits.operator                === 'Unimed'    ? OperadorUnimed :
                                        beneftisList.benefits.operator                === 'Clinipam'    ? OperadorClinipam :
                                        beneftisList.benefits.operator                === 'Bradesco Seguros'    ? OperadorBradesco :
                                        beneftisList.benefits.operator                === 'Paraná Clínicas'    ? OperadorParanaClinicas :
                                        beneftisList.benefits.operator                === 'Sul América'    ? OperadorSulAmerica :
                                        beneftisList.benefits.operator                === 'Amil'    ? OperadorAmil :
                                        beneftisList.benefits.operator                === 'Nossa Saúde'    ? OperadorNossaSaude :
                                        beneftisList.benefits.operator                === 'MedSul'    ? OperadorMedSul :

                                        beneftisList.benefits.operator                === 'Sodexo'    ? OperadorSodexo :
                                        beneftisList.benefits.operator                === 'Alelo'    ? OperadorAlelo :
                                        beneftisList.benefits.operator                === 'VR'       ? OperadorVR :
                                        beneftisList.benefits.operator                === 'Flash'    ? OperadorFlash :
                                        beneftisList.benefits.operator                === 'Swile'    ? OperadorSwile :
                                        beneftisList.benefits.operator                === 'Ticket'    ? OperadorTicket : ""
                                        } alt={beneftisList.benefits.operator } />
                                    </>
                                    :
                                    ''
                                }

                            </div>
                        </div>
                        </>
                        )) : (
                            <>
                            </>
                        )}

                    </div>
                    {

                    loadPage ?"":
                    collaboratorBD.length === 0 ?
                        <div className="nenhum-beneficio-cadastrado" >
                            <p>Nenhum benefício vinculado a você.</p>
                            <img src={FoodGroceries} alt="Nenhum beneficio cadastrado" />
                        </div>
                    :""
                    }
            </Layout>
        </>
    );
    }
export default UserMyBenefits;
