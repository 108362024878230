import React, { useState, useEffect } from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import IllustrationDependente from '../../../../assets/images/illustration-dependente.svg';
import Carregamento from '../../../../assets/images/carregando.gif';
import { Scrollbars } from 'react-custom-scrollbars';
import swal from 'sweetalert';
import { Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import Close from '../../../../assets/images/close.svg';
import { dateofbirthDependet } from '../../../../services/mask'
import api from '../../../../services/api';

export default function ColProfileDependents({ id, path, collaboratorState  }) {



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadName, setLoadName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRelationship, setLoadRelationship ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDateOfBirth, setLoadDateOfBirth ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSchooling, setLoadSchooling ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dependent, setDependent ] = useState([]);

    const [ dependentUpdate, setDependentUpdate ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredDependents, setRegisteredDependents ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loaddependents(id);

    }, [id]);

    async function loaddependents(id) {
        setLoadPage(true)
        await api.get(`/collaborator-dependent-access`)
        .then(response => {

         setLoadPage(false)
            if(response.data[0] !== undefined) {
                setRegisteredDependents(response.data);
            }
        }).catch(error => {});
    }

    async function deleteDependent(dependent) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este dependente?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-dependent-access/${dependent}?path=${path}`)
                    .then(() => {

                        setDependent([])
                        setDependentUpdate([])
                        // setVisible(false);
                        setVisible(!visible);
                        async function loadfilessaved() {
                            await api.get('/collaborator-dependent-access/')
                            .then(response => {
                                setLoad(false)
                                setVisible(false);
                                setRegisteredDependents(response.data);
                            }).catch(error => {});
                        }
                        loadfilessaved();
                        swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                    }).catch(() => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                    });
            }
        });
    }




    function AddAndEditDependentsVisible(e) {
        //console.log(e)

        let dependentmodal = e;

        setDependentUpdate({
            ...dependentmodal
        })

        setLoadName(false)
        setLoadRelationship(false)
        setLoadDateOfBirth(false)
        setLoadDateOfBirth(false)
        setLoadSchooling(false)
        setFileNames(false)
        setVisible(!visible);
    }

    function CloseAddAndEditDependentsVisible() {

        setDependent({
            collaborator: undefined,
            name: undefined,
            relationship: undefined,
            dateOfBirth: undefined,
            schooling: undefined,
            file: undefined
        })

        setDependentUpdate({
            collaborator: undefined,
            name: undefined,
            relationship: undefined,
            dateOfBirth: undefined,
            schooling: undefined,
            file: undefined
        })

        setVisible(!visible);

    }


    let collaborator = id;

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        setDependent({ ...dependent, collaborator })

        if(nam === 'name') {

            setLoadName(false)
            let collaborator = id;
            setDependent({ ...dependent, collaborator, [nam]: val })

        }
        // relationship
        else
        if(nam === 'relationship') {

            setLoadRelationship(false)
            let collaborator = id;
            setDependent({ ...dependent, collaborator, [nam]: val })

        }
        // dateOfBirth
        else
        if(nam === 'dateOfBirth') {

            setLoadDateOfBirth(false)
            let collaborator = id;
            let dateofbirth = dateofbirthDependet(val)
            setDependent({ ...dependent, collaborator, [nam]: dateofbirth })

        }
        // schooling
        else
        if(nam === 'schooling') {

            setLoadSchooling(false)
            let collaborator = id;
            setDependent({ ...dependent, collaborator, [nam]: val })

        }
        else {
            let collaborator = id;
            setDependent({ ...dependent, collaborator, [nam]: val })
        }
    };

async function handleSubmit(event) {
    event.preventDefault();

    if(dependentUpdate._id !== undefined) {

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if(dependent && dependent.length === 0) {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo" });
        }
        else
        if(
            dependent.name         === "" ||
            dependent.relationship === "" ||
            dependent.dateOfBirth  === "" ||
            dependent.schooling    === ""
        ) {
            swal({ icon: "error", title: "Erro!", text: 'Nenhum campo pode ser salvo em branco!' });
        }
        else {

            if(
                dependent.collaborator === undefined &&
                dependent.dateOfBirth  === undefined &&
                dependent.file         === undefined &&
                dependent.name         === undefined &&
                dependent.relationship === undefined &&
                dependent.schooling    === undefined
            ) {
                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo" });
            } else {

                if (dependent.name !== undefined && dependent.name !== "") {
                    setLoadName(false)
                    formData.append('name', dependent.name);
                }

                if (dependent.relationship !== undefined && dependent.relationship !== "") {
                    setLoadRelationship(false)
                    formData.append('relationship', dependent.relationship);
                }

                if (dependent.dateOfBirth !== undefined && dependent.dateOfBirth !== "") {
                    setLoadDateOfBirth(false)
                    formData.append('dateOfBirth', dependent.dateOfBirth);
                }

                if (dependent.schooling !== undefined && dependent.schooling !== "") {
                    setLoadSchooling(false)
                    formData.append('schooling', dependent.schooling);
                }

                if (dependent.file !== undefined && dependent.file !== "") {
                    formData.append('file', dependent.file);
                }
                formData.append('path', path);

                setLoad(true)
                await api.put(`/collaborator-dependent-access/${dependentUpdate._id}`, formData, config)
                .then(response => {

                    setDependent({
                        collaborator: undefined,
                        name: undefined,
                        relationship: undefined,
                        dateOfBirth: undefined,
                        schooling: undefined,
                        file: undefined
                    })

                    setDependentUpdate({
                        collaborator: undefined,
                        name: undefined,
                        relationship: undefined,
                        dateOfBirth: undefined,
                        schooling: undefined,
                        file: undefined
                    })

                    async function loadsdependet() {
                        await api.get('/collaborator-dependent-access/')
                        .then(response => {
                            setLoad(false)
                            setVisible(false);
                            setRegisteredDependents(response.data);
                        }).catch(error => {});
                    }
                    loadsdependet();

                    swal({ icon: "success", title: "Sucesso!", text: "Dependente editada com sucesso!" });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message  });
                });

            }

        }

    } else {

        if (
            dependent.name         === undefined ||
            dependent.name         ===    ""     ||
            dependent.relationship === undefined ||
            dependent.relationship ===    ""     ||
            dependent.dateOfBirth  === undefined ||
            dependent.dateOfBirth  ===    ""     ||
            dependent.schooling    === undefined ||
            dependent.schooling    ===    ""
        ) {
            if (dependent.name === undefined || dependent.name === "") {
                setLoadName(true)
            } else {
                setLoadName(false)
            }

            if (dependent.relationship === undefined || dependent.relationship === "") {
                setLoadRelationship(true)
            } else {
                setLoadRelationship(false)
            }

            if (dependent.dateOfBirth === undefined || dependent.dateOfBirth === "") {
                setLoadDateOfBirth(true)
            } else {
                setLoadDateOfBirth(false)
            }

            if (dependent.schooling === undefined || dependent.schooling === "") {
                setLoadSchooling(true)
            } else {
                setLoadSchooling(false)
            }

            return
        } else {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (dependent.collaborator !== undefined && dependent.collaborator !== "") {
                formData.append('collaborator', dependent.collaborator);
            }

            if (dependent.name !== undefined && dependent.name !== "") {
                setLoadName(false)
                formData.append('name', dependent.name);
            }

            if (dependent.relationship !== undefined && dependent.relationship !== "") {
                setLoadRelationship(false)
                formData.append('relationship', dependent.relationship);
            }

            if (dependent.dateOfBirth !== undefined && dependent.dateOfBirth !== "") {
                setLoadDateOfBirth(false)
                formData.append('dateOfBirth', dependent.dateOfBirth);
            }

            if (dependent.schooling !== undefined && dependent.schooling !== "") {
                setLoadSchooling(false)
                formData.append('schooling', dependent.schooling);
            }

            if (dependent.file !== undefined && dependent.file !== "") {
                formData.append('file', dependent.file);
            }

            formData.append('noDependents', false);
            formData.append('path', path);

            setLoad(true)
            await api.post("/collaborator-dependent-access", formData, config)
            .then(response => {

                setDependent({
                    collaborator: undefined,
                    name: undefined,
                    relationship: undefined,
                    dateOfBirth: undefined,
                    schooling: undefined,
                    file: undefined
                })

                setDependentUpdate({
                    collaborator: undefined,
                    name: undefined,
                    relationship: undefined,
                    dateOfBirth: undefined,
                    schooling: undefined,
                    file: undefined
                })

                async function loadfilessaved() {
                    await api.get('/collaborator-dependent-access/')
                    .then(response => {
                        setLoad(false)
                        setFileNames(false)
                        setRegisteredDependents(response.data);
                        setVisible(!visible);
                    })
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Dependente criado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }

    }

}


async function handleDrop(acceptedFiles) {
     let file = acceptedFiles[0]

     const typeI = file.type ;

     if ((typeI === 'image/jpg') ||
         (typeI === 'image/jpeg') ||
         (typeI === 'image/pjpeg') ||
         (typeI === 'image/png') ||
         (typeI === 'image/gif') ||
         (typeI === 'application/pdf') ||
         (typeI === 'application/msword') ||
         (typeI === 'application/vnd.ms-powerpoint') ||
         (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
         (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
         (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
         (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
         (typeI === 'application/vnd.ms-excel') ||
         (typeI === 'text/csv') ||
         (typeI === 'application/csv' )
     ) {
        setDependent({ ...dependent, file })
         setFileNames(acceptedFiles.map(file => file.name));
     } else {
         swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
     }
 }

 async function deleteFiles() {
    setFileNames();
    let file = fileNames
    setDependent({ ...dependent, file })
}


    return (
        <>

                <div className="box dependentes">
                    <h3>Dependentes <button className="btnGreen more edit-adicionar" onClick={() => AddAndEditDependentsVisible()}>Adicionar</button></h3>
                    {
                            loadPage ?
                                (
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                    </>
                                ):
                        registeredDependents[0] === undefined ?
                        (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationDependente} alt="Sem deficiencia" /><p>Não possui dependentes</p></div></div>):
                        registeredDependents[0] !== undefined ? registeredDependents
                        .map((dependent, index) => (
                            <>
                            <ul key={index} className="dependentes-box">
                                <li >
                                <div className="intemA">
                                    <p>Nome</p>
                                </div>
                                <div className="intemB nome-editar">
                                        <span className="nome">{dependent.name}</span>
                                        <button onClick={() => AddAndEditDependentsVisible(dependent)} className="editar"><img src={EditBlack} alt="Edite Informações" /></button>
                                </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Parentesco</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{dependent.relationship}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Idade</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{dependent.dateOfBirth}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Escolaridade</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{dependent.schooling}</p>
                                    </div>
                                </li>

                                {dependent.originalname !== undefined ?
                                    (
                                        <li className="li-anexo">
                                        <div className="intemA">
                                            <p>Comprovante</p>
                                        </div>
                                        <div className="intemB arq-adicionado">

                                            <a href={dependent.location} target="’_blank’">
                                            {dependent.originalname }
                                            </a>
                                        </div>
                                    </li>
                                    ) : ''
                                }
                            </ul>
                            </>
                        )) : ('')}
                </div>
                <Modal show={visible} className="popup-edit-perfil popup-edit-deficiencia" onHide={CloseAddAndEditDependentsVisible}>
                    <div>
                        <div className="titulo">
                            <h4>Dependentes <p className="btn-fechar" onClick={CloseAddAndEditDependentsVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Nome completo</p>
                                </div>
                                <div className="intemB">
                                     <input type="text" name="name"  autocomplete="off" onChange={myChangeHandler} defaultValue={dependentUpdate && dependentUpdate.name ? dependentUpdate.name : dependent && dependent.name ? dependent.name : ''} placeholder="Nome completo" className={loadName ? 'inputUm inputErro' : 'inputUm'}/>
                                     <p className="inputUm textError">{loadName ? 'É necessário preencher um nome' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Parentesco</p>
                                </div>
                                <div className="intemB">
                                    <div className='cont-select'>
                                        <button className={loadRelationship ? 'inputErro select-' : 'select-'}>
                                            <p>
                                                {
                                                    dependent && dependent.relationship !== undefined ?
                                                    dependent.relationship :
                                                    dependentUpdate && dependentUpdate.relationship !== undefined ?
                                                    dependentUpdate.relationship : "Selecione"
                                                }
                                            </p>
                                        </button>

                                        <div className="dropDown">
                                            <Scrollbars className='listDropDown'  style={{  height: 130 }}>
                                                {[
                                                    "Cônjuge", "Companheiro(a) ou união estável com filhos",
                                                    "Companheiro(a) ou união estável sem filhos",
                                                    "Filho ou enteado até 21 anos", "Filho ou enteado universitário ou em escola técnica",
                                                    "Irmão(ã), neto(a) ou bisneto(a) com guarda",
                                                    "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica",
                                                        "Pais, avós e bisavós", "Incapaz", "Agregado/Outros",
                                                        "Ex cônjuge que recebe pensão de alimentos"
                                                ].map((item, index) => (
                                                    <button
                                                        key={index}
                                                        className='item'
                                                        name="name"
                                                        onClick={() => setDependent({ ...dependent, collaborator, "relationship": item })
                                                        ||
                                                        setLoadRelationship(false)}
                                                    >
                                                        <p >{item}</p>
                                                    </button>
                                                ))}
                                            </Scrollbars>
                                        </div>
                                    </div>
                                    <p className="inputDois textError">{loadRelationship ? 'É necessário escolher um parentesco' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Idade</p>
                                </div>
                                <div className="intemB">
                                <input
                                type="text"
                                name="dateOfBirth"
                                autocomplete="off"
                                value={
                                    dependent && dependent.dateOfBirth !== undefined ?
                                    dependent.dateOfBirth :
                                    dependentUpdate && dependentUpdate.dateOfBirth !== undefined ?
                                    dependentUpdate.dateOfBirth :
                                    dependent.dateOfBirth
                                }
                                onChange={myChangeHandler}
                                placeholder="Idade"
                                className={loadDateOfBirth ? 'inputTreis inputErro' : 'inputTreis'}
                                />
                                <p className="inputTreis textError">{loadDateOfBirth ? 'É necessário preencher uma idade' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Escolaridade</p>
                                </div>
                                <div className="intemB">
                                    <div className='cont-select'>
                                        <button className={loadSchooling ? 'inputErro select-' : 'select-'}>
                                            <p>
                                                {
                                                    dependent && dependent.schooling !== undefined ?
                                                    dependent.schooling :
                                                    dependentUpdate && dependentUpdate.schooling !== undefined ?
                                                    dependentUpdate.schooling : "Selecione"
                                                }
                                            </p>
                                        </button>
                                        <div className="dropDown">
                                            <Scrollbars className='listDropDown'  style={{  height: 130 }}>
                                                {[
                                                    "Analfabeto", "Até a 5ª série incompleta do Ensino Fundamental",
                                                    "5º ano completo do Ensino Fundamental",
                                                    "Do 6º ao 9º ano do Ensino Fundamental incompleto",
                                                    "Ensino Fundamental completo", "Ensino Médio incompleto",
                                                    "Ensino Médio completo", "Educação Superior incompleta",
                                                    "Educação Superior completa", "Mestrado completo", "Mestrado incompleto",
                                                    "Doutorado completo", "Doutorado incompleto", "Pós Graduação completa",
                                                    "Pós Graduação incompleta", "Ensino Técnico completo",
                                                    "Ensino Técnico incompleto"
                                                ].map((item, index) => (

                                                    <button
                                                        key={index}
                                                        className='item'
                                                        name="name"
                                                        onClick={() => setDependent({ ...dependent, collaborator, "schooling": item })
                                                        ||
                                                        setLoadSchooling(false)}
                                                    >
                                                        <p >{item}</p>
                                                    </button>
                                                ))}
                                            </Scrollbars>
                                        </div>
                                    </div>
                                    <p className="inputQuatro textError">{loadSchooling ? 'É necessário escolher uma escolaridade' : ''}</p>
                                </div>
                            </li>


                            {dependent.originalname !== undefined ?
                                    (
                                        <li>
                                        <div className="intemA">
                                            <p>Comprovante</p>
                                        </div>
                                        <div className="intemB">

                                            <a href={dependent.location} target="’_blank’">
                                            {dependent.originalname }
                                            </a>
                                        </div>
                                    </li>
                                    ) : ''
                                }
                            <li>
                                <div className="intemA">
                                    <p>Anexar</p>
                                </div>
                                <div className="intemB">

                                        <Dropzone  className="" onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel inputOnze"><span></span>Anexe um arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                </div>
                            </li>

                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </li>
                            {fileNames ?
                            fileNames.map((filename, index) => (
                                <li>
                                    <div className="arquivoAnexado textoDoze">
                                        <div key={index} className="arquivo">
                                            <p className="nomeArquivo">
                                                {filename}
                                            </p>
                                            <p onClick={deleteFiles} className="remover">Remover anexo</p>
                                        </div>
                                    </div>
                                </li>
                            )) : ''}


                            {dependentUpdate && dependentUpdate.dependent !== false && dependentUpdate && dependentUpdate._id  ?
                                (
                                    <li>
                                        <div className="intemA">
                                            <div className="btnExclui">
                                                <button onClick={() => deleteDependent(dependentUpdate._id)}>Excluir dependente</button >
                                            </div>
                                        </div>
                                        <div className="intemB">
                                            <form  onSubmit={handleSubmit} className="btn-salvar">
                                                <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                            </form>
                                        </div>
                                    </li>
                                ) :
                            (
                                <li>
                                    <div className="intemA">
                                    </div>

                                    <div className="intemB">
                                        <form  onSubmit={handleSubmit} className="btn-salvar">
                                            <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                        </form>
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </Modal>
        </>
    );
}
