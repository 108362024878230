import React, { useState, useEffect } from 'react';

import { Modal } from "react-bootstrap";
import CurrencyInput from 'react-currency-input';
import swal from 'sweetalert';
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Close from '../../../../../../assets/images/close.svg';
import { dateMask } from '../../../../../../services/mask';
import Carregando from '../../../../../../assets/images/carregando.gif';
import api from '../../../../../../services/api';
import SelectPersonalizado from '../../../../../../components/Reused/selectPersonalizado';
import { toast } from 'react-toastify';

export default function ColProfileBondSalary ({ id, collaborator, role, path }) {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageSalary, setLoadPageSalary ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBond  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCharge ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDepartment ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCostCenter, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadWage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupationsList, setOccupationsList ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadValidFrom ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadReason ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredBondAndSalary, setRegisteredBondAndSalary ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadBondAndSalary(id);
            loadCollaborator(id);
            loadCollaboratorSalary(id);
        }
        loadoccupations();
        loadsectors();
        loadcompanies();

        if(
            (role && role === 'admin')
            ||
            (role && role === 'master')
        ) {

            loadcostcenter();

        }

    }, [id, role]);

    async function loadBondAndSalary(id) {
        setLoadPageSalary(true)
        await api.get(`/collaborator-bond-and-salary/${id}`)
        .then(response => {

            setLoadPageSalary(false)
            if(response.data[0] !== undefined) {

                setRegisteredBondAndSalary(response.data[0]);

            }
            else {

                setRegisteredBondAndSalary([]);

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadoccupations() {

        await api.get('/occupation/?sortBy=name&order=asc')
        .then(response => {

            setOccupationsList(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadCollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {

            if(response.data !== undefined) {

                setRegisteredCollaborator(response.data);

            }
            else {
                setRegisteredCollaborator([]);
            }


        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadCollaboratorSalary(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {

            if (response.data !== undefined) {

                localStorage.setItem('@peopleview/collaboratorSalary', response.data.salary);

            } else {

                return

            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function loadsectors() {

        await api.get('/sector/?sortBy=name&order=asc')
        .then(response => {

            setSectors(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredCollaborator, setRegisteredCollaborator ] = useState([]);

    // const occupationsalary  = localStorage.getItem('@peopleview/collaboratorSalary');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costCenter, setCostCenter ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ companies, setCompanies ] = useState([]);


    async function loadcostcenter() {

        await api.get('/costcenter/')
        .then(response => {
            setCostCenter(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


    async function loadcompanies() {

        await api.get('/company-admin/')
        .then(response => {

            setCompanies(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    //////////////////////////////////////////////////////////////////////
    /////////////////       SET VISIBLE POPUP         ///////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);
    function EditBondSalaryVisible(e) {
        if(e !== undefined) {
            const _id = e;

            setBondSalary({
                ...bondSalary, _id
            })

            setVisible(!visible);
        } else {

            setVisible(!visible);
        }

        setSelectBond(false)
        setSelectOccupation(false)
        setSelectSector(false)
        setSelectCostCenter(false)

        setSelectBondAndSalaryReason(false)

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ bondSalary, setBondSalary ] = useState([]);

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "validOnTheDateOf") {

            let validOnTheDateOf = dateMask(val)
            setBondSalary({ ...bondSalary, collaborator, [nam]: validOnTheDateOf })
            // console.log(bondSalary)

        } else if (nam === "salary") {

            setBondSalary({ ...bondSalary, collaborator, [nam]: val })

        }else {

            setBondSalary({ ...bondSalary, collaborator, [nam]: val })

        }

    };



    async function handleSubmit(event) {
        event.preventDefault();

        if (bondSalary._id !== undefined) {

            if (bondSalary.bond !== undefined) {
                var bond = bondSalary.bond
            }

            if (bondSalary.occupation !== undefined) {
                var occupation = bondSalary.occupation
                //console.log(bondSalary.occupation)
            }

            if (bondSalary.sector !== undefined) {
                var sector = bondSalary.sector
                //console.log(bondSalary.sector)
            }

            if (bondSalary.costCenter !== undefined) {
                var costCenter = bondSalary.costCenter
                //console.log(bondSalary.costCenter)
            }

            if (bondSalary.salary !== undefined) {
                var salary = bondSalary.salary
            }

            if (bondSalary.validOnTheDateOf !== undefined) {
                var validOnTheDateOf = bondSalary.validOnTheDateOf
            }

            if (bondSalary.reason !== undefined) {
                var reason = bondSalary.reason
            }

            if (bondSalary.description !== undefined) {
                var description = bondSalary.description
            }
            if (bondSalary.collaborator !== undefined) {
                var collaborator = bondSalary.collaborator
            }
            //console.log(bondSalary)

            if (
                occupation                        !== undefined ||
                sector                            !== undefined ||
                costCenter                        !== undefined ||
                salary                            !== undefined ||
                bond                              !== undefined ||
                validOnTheDateOf                  !== undefined ||
                reason                            !== undefined ||
                description                       !== undefined
            ) {
                setLoad(true)
                if(
                    occupation                        === "" ||
                    sector                            === "" ||
                    costCenter                        === "" ||
                    salary                            === "" ||
                    bond                              === "" ||
                    validOnTheDateOf                  === "" ||
                    reason                            === "" ||
                    description                       === ""
                ) {
                    swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser salvo em branco" });
                    setLoad(false)
                } else {

                    if (
                        occupation                        !== undefined ||
                        sector                            !== undefined ||
                        costCenter                        !== undefined ||
                        salary                            !== undefined
                    ) {
                        await api.put(`/collaborator/${id}`, { occupation, sector, costCenter, salary, path: path })
                        .then(response => {

                            setBondSalary([]);
                            localStorage.setItem('@peopleview/collaboratorSalary', salary);
                            loadCollaborator(id);

                            ///////////////////////////////////////////////////////////////
                            if (
                                bond                              !== undefined ||
                                validOnTheDateOf                  !== undefined ||
                                reason                            !== undefined ||
                                description                       !== undefined
                            ) {
                                async function putcollaboratorbondandsalary() {

                                    await api.put(`/collaborator-bond-and-salary/${bondSalary._id}`, {
                                        bond,
                                        validOnTheDateOf,
                                        reason,
                                        description,
                                        path: path
                                    })
                                    .then(response => {

                                        setLoad(false)
                                        setVisible(!visible);
                                        setBondSalary([]);
                                        loadBondAndSalary(id);
                                        swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                                    }).catch(error => {
                                        swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                        // console.log(error)
                                        setVisible(!visible);
                                    });

                                }
                                putcollaboratorbondandsalary();

                            } else {
                                swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                                setVisible(!visible);
                            }
                            ///////////////////////////////////////////////////////////////

                        }).catch(error => {
                            swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                            // console.log(error)
                        });
                    } else {
                        ///////////////////////////////////////////////////////////////
                        if (
                            bond                              !== undefined ||
                            validOnTheDateOf                  !== undefined ||
                            reason                            !== undefined ||
                            description                       !== undefined
                        ) {

                            async function putcollaboratorbondandsalary() {

                                await api.put(`/collaborator-bond-and-salary/${bondSalary._id}`, {
                                    bond,
                                    validOnTheDateOf,
                                    reason,
                                    description,
                                    path: path
                                })
                                .then(response => {

                                    setLoad(false)
                                    setVisible(!visible);
                                    setBondSalary([]);
                                    loadBondAndSalary(id);
                                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                                }).catch(error => {
                                    swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                    setLoad(false)
                                    setVisible(!visible);
                                });

                            }
                            putcollaboratorbondandsalary();

                        } else {
                            swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                            setLoad(false)
                            setVisible(!visible);
                            setBondSalary([]);
                        }
                        ///////////////////////////////////////////////////////////////
                    }

                }




            } else {
                swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });
            }
        }  else {
            if (bondSalary.bond !== undefined) {
                bond = bondSalary.bond
            }

            if (bondSalary.occupation !== undefined) {
                occupation = bondSalary.occupation
                // console.log(bondSalary.occupation)
            }

            if (bondSalary.sector !== undefined) {
                sector = bondSalary.sector
                // console.log(bondSalary.sector)
            }

            if (bondSalary.costCenter !== undefined) {
                costCenter = bondSalary.costCenter
                // console.log(bondSalary.costCenter)
            }

            if (bondSalary.salary !== undefined) {
                salary = bondSalary.salary
            }

            if (bondSalary.validOnTheDateOf !== undefined) {
                validOnTheDateOf = bondSalary.validOnTheDateOf
            }

            if (bondSalary.reason !== undefined) {
                reason = bondSalary.reason
            }

            if (bondSalary.description !== undefined) {
                description = bondSalary.description
            }
            if (bondSalary.collaborator !== undefined) {
                collaborator = bondSalary.collaborator
            }

            if (
                occupation                        !== undefined ||
                sector                            !== undefined ||
                costCenter                        !== undefined ||
                salary                            !== undefined ||
                bond                              !== undefined ||
                validOnTheDateOf                  !== undefined ||
                reason                            !== undefined ||
                description                       !== undefined
            ) {
                setLoad(true)

                if (
                    occupation                        !== undefined ||
                    sector                            !== undefined ||
                    costCenter                        !== undefined ||
                    salary                            !== undefined
                ) {
                    await api.put(`/collaborator/${id}`, { occupation, sector, costCenter, salary, path: path })
                    .then(response => {

                        setBondSalary([]);
                        localStorage.setItem('@peopleview/collaboratorSalary', salary);
                        loadCollaborator(id);
                        //////////////////////////////////////////////////////////////////////
                        if (
                            bond                              !== undefined ||
                            validOnTheDateOf                  !== undefined ||
                            reason                            !== undefined ||
                            description                       !== undefined
                        ) {

                            async function postCollaboratorBondAndSalary() {

                                await api.post('/collaborator-bond-and-salary/', {
                                    collaborator,
                                    bond,
                                    validOnTheDateOf,
                                    reason,
                                    description,
                                    path: path
                                })
                                .then(response => {

                                    setLoad(false)
                                    setVisible(!visible);
                                    setBondSalary([]);
                                    loadBondAndSalary(id);
                                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })

                                }).catch(error => {
                                    swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                    setLoad(false)
                                    setVisible(!visible);
                                });

                            }
                            postCollaboratorBondAndSalary()



                        } else {
                            swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                            setLoad(false)
                            setVisible(!visible);
                            setBondSalary({})
                        }
                        //////////////////////////////////////////////////////////////////////
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado!" });
                                setLoad(false)
                                setVisible(!visible);
                    });
                } else {
                    //////////////////////////////////////////////////////////////////////
                    if (
                        bond                              !== undefined ||
                        validOnTheDateOf                  !== undefined ||
                        reason                            !== undefined ||
                        description                       !== undefined
                    ) {
                        async function postCollaboratorBondAndSalary() {

                            await api.post('/collaborator-bond-and-salary/', {
                                collaborator,
                                bond,
                                validOnTheDateOf,
                                reason,
                                description,
                                path: path
                            })
                            .then(response => {
                                // console.log(response.data)
                                setLoad(false)
                                setVisible(!visible);
                                setBondSalary([]);
                                loadBondAndSalary(id);

                                swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                            }).catch(error => {
                                swal({ icon: "error", title: "Erro!", text: "Colaborador não pode ser alterado post!" });
                                setLoad(false)
                                setVisible(!visible);
                            });

                        }
                        postCollaboratorBondAndSalary()


                    } else {
                        swal({ icon: "success", title: "Sucesso!", text: "Colaborador editado com sucesso." })
                        setLoad(false)
                        setVisible(!visible);
                        setBondSalary([]);
                    }
                    //////////////////////////////////////////////////////////////////////
                }

            } else {
                swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um dado!" });
            }
        }


    }





////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
/// => Selects personalizado Vínculo e Salário
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectBond, setSelectBond ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectOccupation, setSelectOccupation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectSector, setSelectSector ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectCostCenter, setSelectCostCenter ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ selectBondAndSalaryReason, setSelectBondAndSalaryReason ] = useState(false);



    // console.log(costCenter)

    const BondSalaryFution = {

        OpenSelectBond() {
            setSelectBond(!selectBond)
            setSelectOccupation(false)
            setSelectSector(false)
            setSelectCostCenter(false)
            setSelectBondAndSalaryReason(false)
        },
        OpenSelectOccupation() {
            setSelectOccupation(!selectOccupation)
            setSelectBond(false)
            setSelectSector(false)
            setSelectCostCenter(false)
            setSelectBondAndSalaryReason(false)
        },
        OpenSelectSector() {
            setSelectSector(!selectSector)
            setSelectOccupation(false)
            setSelectBond(false)
            setSelectCostCenter(false)
            setSelectBondAndSalaryReason(false)
        },
        OpenSelectCostCenter() {
            setSelectSector(false)
            setSelectOccupation(false)
            setSelectBond(false)
            setSelectCostCenter(!selectCostCenter)
            setSelectBondAndSalaryReason(false)
        },
        OpenSelectBondAndSalaryReason() {
            setSelectSector(false)
            setSelectOccupation(false)
            setSelectBond(false)
            setSelectCostCenter(false)
            setSelectBondAndSalaryReason(!selectBondAndSalaryReason)
        },
        closeBondSalarySelect(event) {
            if(
                event.target.className !== "btn-select selectBond"                  &&
                event.target.className !== "btn-select selectOccupation"            &&
                event.target.className !== "btn-select selectSector"                &&
                event.target.className !== "btn-select selectCostCenter"            &&
                event.target.className !== "btn-select selectBondAndSalaryReason"
            ) {
                setSelectBond(false)
                setSelectOccupation(false)
                setSelectSector(false)
                setSelectCostCenter(false)
                setSelectBondAndSalaryReason(false)
                if (event.target.className === "span-select-selectBond") {
                    BondSalaryFution.OpenSelectBond()
                }else
                if (event.target.className === "span-select-selectOccupation") {
                    BondSalaryFution.OpenSelectOccupation()
                }else
                if (event.target.className === "span-select-selectSector") {
                    BondSalaryFution.OpenSelectSector()
                }else
                if (event.target.className === "span-select-selectCostCenter") {
                    BondSalaryFution.OpenSelectCostCenter()
                }else
                if (event.target.className === "span-select-selectBondAndSalaryReason") {
                    BondSalaryFution.OpenSelectBondAndSalaryReason()
                }
            }
        }
    }

    return (
        <>

                <div className="box BondSalary">
                    <h3>Vínculo e Salário
                        {
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                (role && role === 'admin') || (role && role === 'master') ? <button className="edit-Benefit" onClick={() => EditBondSalaryVisible(registeredBondAndSalary._id)}><img src={EditBlack} alt="Edite Informações" /></button> : ''
                        }
                    </h3>
                    {
                        loadPageSalary ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregando} />
                                </div>
                                </>
                            ):(
                                <>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Vínculo</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredBondAndSalary.bond !== undefined ?(registeredBondAndSalary.bond) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Cargo</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredCollaborator.occupation !== undefined ? (registeredCollaborator.occupation) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Departamento</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredCollaborator.sector !== undefined ? (registeredCollaborator.sector) : ('-')}</p>
                            </div>
                        </li>
                        {
                            role && role === 'admin' ?
                            <li>
                                <div className="intemA">
                                    <p>Centro de custo</p>
                                </div>
                                <div className="intemB">
                                    {
                                        registeredCollaborator && registeredCollaborator.costCenter && registeredCollaborator.costCenter !== undefined ?
                                        registeredCollaborator.costCenter : '-'
                                    }

                                        {/* <p>{companies[0] !== undefined ? (companies[0].name) : ('-')}</p> */}
                                </div>
                            </li>
                            :
                            ''
                        }
                        <li>
                            <div className="intemA">
                                <p>Salário</p>
                            </div>
                            <div className="intemB nome">

                                <p>
                                    {registeredCollaborator.salary !== undefined ? (registeredCollaborator.salary) : ('-')}
                                {/* { occupationsalary !== undefined ? (
                                {occupationsalary}
                                ) : ('-')
                                } */}

                                </p>
                            </div>
                        </li>
                        {
                            (role && role === 'admin') || (role && role === 'master') ?
                            <>
                            <li>
                                <div className="intemA">
                                    <p>Válido a partir de</p>
                                </div>
                                <div className="intemB">
                                    <p>{registeredBondAndSalary.validOnTheDateOf !== undefined ?(registeredBondAndSalary.validOnTheDateOf) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Motivo</p>
                                </div>
                                <div className="intemB">
                                    <p>{registeredBondAndSalary.reason !== undefined ?(registeredBondAndSalary.reason) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Descrição</p>
                                </div>
                                <div className="intemB">
                                    <p>{registeredBondAndSalary.description !== undefined ?(registeredBondAndSalary.description.split('\n').map(str => (<><br/>{str}</>))) : ('-')}</p>
                                </div>
                            </li>
                            </>
                            :
                            ''
                        }
                    </ul>
                    </>)}
                </div>

                <Modal show={visible} className="popup-edit-perfil popup-edit-perfil-VSalario" onHide={EditBondSalaryVisible}>
                    <div onClick={BondSalaryFution.closeBondSalarySelect}>
                        <div className="titulo">
                            <h4>Vínculo e Salário <p className="btn-fechar" onClick={EditBondSalaryVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <strong><p>Vínculo</p></strong>
                                </div>
                                <div className="intemB">
                                    <SelectPersonalizado
                                        valorCampo={
                                            
                                                bondSalary && bondSalary.bond !== undefined ?
                                                [
                                                    "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "Pessoa Jurídica", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                                ]
                                                    .filter(item => item === bondSalary.bond)
                                                    .map((item, index) => (item))
                                                :
                                                    registeredBondAndSalary && registeredBondAndSalary.bond ?
                                                        registeredBondAndSalary.bond
                                                    :
                                                    ''
                                            
                                        }
                                        nameButton={"bond"}
                                        myChangeHandler={myChangeHandler}
                                        loadError={loadBond}

                                        optionFil={ [
                                            "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "Pessoa Jurídica", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                        ]}
                                        typeSelect="busca2"
                                    />
                                    <p className="textError">{loadBond ? 'É necessário escolhe um vínculo' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Cargo</p>
                                    {/* selectOccupation */}
                                </div>
                                <div className="intemB">
                                    <div className='select-x'>
                                        <button className={loadCharge ? 'inputErro btn-select selectOccupation' : 'btn-select selectOccupation'} id='selectOccupation'  onClick={BondSalaryFution.OpenSelectOccupation}>
                                            <span  className='span-select-selectOccupation'>
                                                {
                                                    bondSalary && bondSalary.occupation !== undefined ?
                                                        bondSalary.occupation
                                                    :
                                                        registeredCollaborator && registeredCollaborator.occupation ?
                                                            registeredCollaborator.occupation
                                                        :
                                                        'Selecione'
                                                }
                                            </span>
                                        </button>
                                        {selectOccupation ?
                                            <div className='resultado-busca'>

                                                {
                                                    occupationsList
                                                    .map((occupation, index) => (
                                                        registeredCollaborator &&
                                                        registeredCollaborator.occupation &&
                                                        registeredCollaborator.occupation === occupation.name ?
                                                        '' :
                                                        <button  key={index}
                                                            onClick={() => setBondSalary(
                                                                { ...bondSalary, collaborator, "occupation": occupation.name }
                                                            )}
                                                            >
                                                            <p>{occupation.name}</p>
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                    <p className="textError">{loadCharge ? 'É necessário escolhe um cargo' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Departamento</p>
                                    {/* selectSector */}
                                </div>
                                <div className="intemB">
                                    <div className='select-x'>
                                        <button className={loadDepartment ? 'inputErro btn-select selectSector' : 'btn-select selectSector'} id='selectSector'  onClick={BondSalaryFution.OpenSelectSector}>
                                            <span  className='span-select-selectSector'>
                                                {
                                                    bondSalary && bondSalary.sector !== undefined ?
                                                        bondSalary.sector
                                                    :
                                                        registeredCollaborator && registeredCollaborator.sector ?
                                                            registeredCollaborator.sector
                                                        :
                                                        'Selecione'
                                                }
                                            </span>
                                        </button>
                                        {selectSector ?
                                            <div className='resultado-busca'>

                                                {
                                                    sectors
                                                    .map((sector, index) => (
                                                        registeredCollaborator &&
                                                        registeredCollaborator.sector &&
                                                        registeredCollaborator.sector === sector.name ?
                                                        '' :
                                                        <button  key={index}
                                                            onClick={() => setBondSalary(
                                                                { ...bondSalary, collaborator, "sector": sector.name }
                                                            )}
                                                            >
                                                            <p>{sector.name}</p>
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                    <p className="textError">{loadDepartment ? 'É necessário escolhe um departamento' : ''}</p>
                                </div>
                            </li>

                            <li>
                                <div className="intemA">
                                    <p>Centro de custo</p>
                                </div>
                                <div className="intemB">
                                    <div className='select-x'>
                                        <button className={loadDepartment ? 'inputErro btn-select selectCostCenter' : 'btn-select selectCostCenter'} id='selectCostCenter'  onClick={BondSalaryFution.OpenSelectCostCenter}>
                                            <span  className='span-select-selectCostCenter'>
                                                {
                                                    bondSalary && bondSalary.costCenter !== undefined ?
                                                        bondSalary.costCenter
                                                    :
                                                        registeredCollaborator && registeredCollaborator.costCenter ?
                                                            registeredCollaborator.costCenter
                                                        :
                                                        'Selecione'
                                                }
                                            </span>
                                        </button>
                                        {selectCostCenter ?
                                            <div className='resultado-busca'>


                                                {       bondSalary && bondSalary.costCenter === undefined ?
                                                            registeredCollaborator &&
                                                            registeredCollaborator.costCenter &&
                                                            registeredCollaborator.costCenter === companies.name ?
                                                            ""
                                                            :
                                                            <button
                                                                onClick={() => setBondSalary(
                                                                    { ...bondSalary, collaborator, "costCenter": companies.name }
                                                                )}
                                                                >
                                                                <p>{companies.name}</p>
                                                            </button>
                                                        :
                                                            bondSalary &&
                                                            bondSalary.costCenter &&
                                                            bondSalary.costCenter === companies.name ?
                                                            ""
                                                            :
                                                            <button
                                                                onClick={() => setBondSalary(
                                                                    { ...bondSalary, collaborator, "costCenter": companies.name }
                                                                )}
                                                                >
                                                                <p>{companies.name}</p>
                                                            </button>
                                                }


                                                {
                                                    bondSalary && bondSalary.costCenter === undefined ?
                                                        costCenter
                                                        .map((costcenter, index) => (

                                                            registeredCollaborator &&
                                                            registeredCollaborator.costCenter &&
                                                            registeredCollaborator.costCenter === costcenter.name ?
                                                            ''
                                                            :
                                                            <button
                                                                onClick={() => setBondSalary(
                                                                    { ...bondSalary, collaborator, "costCenter": costcenter.name }
                                                                )}
                                                                >
                                                                <p>{costcenter.name}</p>
                                                            </button>
                                                        ))
                                                    :
                                                        costCenter
                                                        .map((costcenter, index) => (

                                                            bondSalary &&
                                                            bondSalary.costCenter &&
                                                            bondSalary.costCenter === costcenter.name ?
                                                            ''
                                                            :
                                                            <button
                                                                onClick={() => setBondSalary(
                                                                    { ...bondSalary, collaborator, "costCenter": costcenter.name }
                                                                )}
                                                                >
                                                                <p>{costcenter.name}</p>
                                                            </button>
                                                        ))
                                                }
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>

                                    <p className="textError">{loadCostCenter ? 'É necessário escolhe um centro de custo' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <strong><p>Salário</p></strong>
                                </div>
                                <div className="intemB">

                                <CurrencyInput
                                    // prefix="$"
                                    // ref={focusSalary} 
                                    className={loadWage ? 'inputErro salary-input' : 'salary-input'}
                                    onChangeEvent={myChangeHandler}
                                    name="salary"
                                    // placeholder="R$ 0,000" 
                                    value={
                                        bondSalary.salary !== undefined ?
                                        bondSalary.salary
                                        :
                                        registeredCollaborator.salary       !== undefined ?
                                        registeredCollaborator.salary
                                        :
                                        ''}
                                    />

                                    <p className="textError">{loadWage ? 'É necessário detertmina um salário' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Válido a partir de</p>
                                </div>
                                <div className="intemB">

                                    {
                                        (bondSalary && bondSalary.validOnTheDateOf !== undefined) || bondSalary.validOnTheDateOf === "" ?
                                        (
                                            <input type="text" name="validOnTheDateOf" autocomplete="off" maxlength="11"  value={(bondSalary.validOnTheDateOf !== undefined) ? bondSalary.validOnTheDateOf : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadValidFrom ? 'inputUm inputErro' : 'inputUm'} />
                                        ) :
                                        registeredBondAndSalary && registeredBondAndSalary.validOnTheDateOf ? (
                                            <input type="text" name="validOnTheDateOf" autocomplete="off"  maxlength="11"  defaultValue={(registeredBondAndSalary.validOnTheDateOf !== undefined) ? registeredBondAndSalary.validOnTheDateOf : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className="inputUm"/>
                                        ):
                                        (
                                            <input type="text" name="validOnTheDateOf" autocomplete="off"  maxlength="11"  value={(bondSalary.validOnTheDateOf !== undefined) ? bondSalary.validOnTheDateOf : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadValidFrom ? 'inputUm inputErro' : 'inputUm'} />
                                        )
                                    }
                                    <p className="textError">{loadValidFrom ? 'É necessário escolhe uma data' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Motivo</p>
                                    {/* selectBondAndSalaryReason */}
                                </div>
                                <div className="intemB">
                                    <SelectPersonalizado
                                        valorCampo={
                                            bondSalary && bondSalary.reason !== undefined ?
                                            [
                                                "Acordo coletivo","Admissão","Ajuste de bolsa auxilio","Ajuste de pró labore","Alteração de função",
                                                "Dissídio","Efetivação de estagiário","Enquadramento de função","Enquadramento salarial","Espontâneo","Mudança de jornada","Mérito/Reajuste","Promoção","Redução de jornada de trabalho"
                                                    ]
                                                .filter(item => item === bondSalary.reason)
                                                .map((item, index) => (item))
                                            :
                                                registeredBondAndSalary && registeredBondAndSalary.reason ?
                                                    registeredBondAndSalary.reason
                                                :
                                                ''
                                            
                                        }
                                        nameButton={"reason"}
                                        myChangeHandler={myChangeHandler}
                                        loadError={loadReason}

                                        optionFil={ [
                                            "Acordo coletivo","Admissão","Ajuste de bolsa auxilio","Ajuste de pró labore","Alteração de função",
                                            "Dissídio","Efetivação de estagiário","Enquadramento de função","Enquadramento salarial","Espontâneo","Mudança de jornada","Mérito/Reajuste","Promoção","Redução de jornada de trabalho"
                                        ]}
                                        typeSelect="busca2"
                                    />
                                    <p className="textError">{loadReason ? 'É necessário escolhe um motivo' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Descrição</p>
                                </div>
                                <div className="intemB">
                                    {registeredBondAndSalary.description     !== undefined ?
                                    (<textarea name="description" cols="150"  onChange={myChangeHandler} defaultValue={registeredBondAndSalary.description  } ></textarea>) :
                                    (<textarea name="description" cols="150"  onChange={myChangeHandler} placeholder="Descrição"></textarea>) }

                                </div>
                            </li>
                            {/* <li>
                                <div className="chekbox intemB">
                                    <input type="checkbox" value="sim" name="foreign" id="foreignSim" />
                                    <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                    <label htmlFor="foreignSim" className="foreignTexto">Notificar contador sobre as alterações realizadas.</label>
                                </div>
                            </li> */}
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                <form  onSubmit={handleSubmit}  className="btn-salvar">
                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                                </div>
                            </li>

                        </ul>

                    </div>
                </Modal>
        </>
    );
}

