import React,{
    useEffect,
    useState
} from "react";
import SelectPersonalizado from '../../components/Reused/selectPersonalizado'
import { NavLink } from 'react-router-dom'
import api from '../../services/api'
import Skeleton from 'react-loading-skeleton'
export default function WebsiteVacancies({id, companyId}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages, setPages ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterPages, setFilterPages ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterlaType, setFilterlaType ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterCity, setFilterCity ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterNameSector, setFilterNameSector ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterName, setFilterName ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ cidadesCAds, setCidadesCAds] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadVacancies(id, filterPages, filterlaType, filterCity, filterNameSector, filterName);
        loadSectore(id);
        loadCidade(id);
        
    }, [id, filterPages, filterlaType, filterCity, filterNameSector, filterName]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [laodVagas, setLoadVagas] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [vagasAbertas, setVagasAbertas] = useState([]);

    async function loadVacancies(id, filterPages, filterlaType, filterCity, filterNameSector, filterName) {

        setLoadVagas(true)
        await api.get(`/vacancie-page-appearance-vacancie/${id}?name=${filterName}&type=${filterlaType}&city=${filterCity}&nameSector=${filterNameSector}&page=${filterPages}&status=Publicado&limit=2`)
        .then(response => {
        
            setVagasAbertas(response.data.vacancieSearch)
            setLoadVagas(false)
            setPages(response.data.pages);

        }).catch(error => {
            setLoadVagas(false)
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectoresList, setSectoresList] = useState([]);

    async function loadCidade(id) {
        await api.get(`/vacancie-page-appearance-vacancie/${id}?page=0&status=Publicado`)
        .then(response => {
            let Cits = ["Todas as cidades"];

            for(let c=0; c < response.data?.vacancieSearch.length; c++){
                let bornCityString =  response.data?.vacancieSearch[c].bornCityString;
                Cits.push(bornCityString)
            }
            setCidadesCAds(Cits)

        }).catch(error => {
            setLoadVagas(false)
        })

    }

    async function loadSectore(id) {
        await api.get(`/vacancie-page-appearance-vacancie/${id}?page=0&status=Publicado`)
        .then(response => {

            let Sectores = ["Todos os departamentos"];
            for(let c=0; c < response.data?.vacancieSearch.length; c++){
                let sectoresString =  response.data?.vacancieSearch[c]?.nameSector;
                Sectores.push(sectoresString)
            }
            setSectoresList(Sectores)

        }).catch(error => {
            setLoadVagas(false)
        })
    }

    let resultCidades =[]

    if(cidadesCAds.length !== 0){
        resultCidades = cidadesCAds.sort().reduce((init, current) => {
            if (init.length === 0 || init[init.length - 1] !== current) {
                init.push(current);
            }
            return init;
        }, []);

    }

    let resultSector =[]

    if(sectoresList.length !== 0){
        resultSector = sectoresList.sort().reduce((init, current) => {
            if (init.length === 0 || init[init.length - 1] !== current) {
                init.push(current);
            }
            return init;
        }, []);
    }

    const listaTipo = [
        "Todos os tipos",
        "Presencial",
        "Híbrido", 
        "Remoto"
    ]

    const myChangeHandlerFilterTipe = event =>{
        let val = event.target.value;
        if(val === "Todos os tipos"){
            setFilterlaType("")
        }else {
            setFilterlaType(val)
        }

        setFilterPages(0)
    }
    const myChangeHandlerFilterCidades = event =>{
        let val = event.target.value;
        if(val === "Todas as cidades"){
            setFilterCity("")
        }else {
            setFilterCity(val)
        }
        setFilterPages(0)
    }
    const myChangeHandlerFilterSector = event =>{
        let val = event.target.value;
        if(val === "Todos os departamentos"){
            setFilterNameSector("")
        }else {
            setFilterNameSector(val)
        }
        setFilterPages(0)
    }

    const myChangeHandlerFilterName = event =>{
        let val = event.target.value;
        setFilterName(val)
        setFilterPages(0)
    }


    let numberPaginate = []

    if(pages > 0) {
        for(let i=0; i <= pages; i++){

            numberPaginate.push(
                <li className="number" key={i}>
                    <button
                    className={i === filterPages ? "secao" : ""}
                    onClick={
                        (event) => setFilterPages(i)
                    }
                    >
                    {i+1}
                    </button>
                </li>
            )
        }
    }
    
    async function prevPage() {


            if (filterPages === 0) return;
            const pageNumber = filterPages - 1;

            setFilterPages(pageNumber);

        

    }

    async function nextPage() {

            if (filterPages === pages) return;
            const pageNumber = filterPages + 1;

            setFilterPages(pageNumber);

    }
console.log(vagasAbertas)
    return(
        <>
            <div className="vacancies">
                <div className="center">

                    <p className="quantidade-vagas">Vagas abertas {vagasAbertas?.length}</p>

                    <div className="menu-filtro">
                        <div className="text">
                            <p>Mostrando</p>
                        </div>
                        <div className="departamentos">
                            <SelectPersonalizado 
                                valorCampo={filterNameSector !== "" ? filterNameSector : "Todos os departamentos"}
                                typeSelect={"btn"}
                                nameButton={"cargos"}
                                myChangeHandler={myChangeHandlerFilterSector}
                                option={resultSector}
                            />
                        </div>
                        <div className="cidades">
                            <SelectPersonalizado 
                                valorCampo={filterCity !== "" ? filterCity :"Todas as cidades"}
                                typeSelect={"btn"}
                                myChangeHandler={myChangeHandlerFilterCidades}
                                nameButton={"filtroCidades"}
                                option={resultCidades}
                            />

                        </div>
                        <div className="tipos">
                            <SelectPersonalizado 
                                valorCampo={filterlaType !== "" ? filterlaType : "Todos os tipos"}
                                typeSelect={"btn"}
                                myChangeHandler={myChangeHandlerFilterTipe}
                                nameButton={"filtroTipos"}
                                option={listaTipo}
                            />
                        </div>
                        <div className="filtrar-busca" >
                            <input type="text" placeholder="Filtre por vagas" onChange={myChangeHandlerFilterName}  className="campo-busca"/>
                        </div>
                    </div>

                    <div className="tabela-vagas">

                        {
                            
                            laodVagas ?
                            <>
                               <Skeleton  
                                count={1}  
                                height={40}
                                borderRadius={9}
                                />
                                <Skeleton  
                                 count={1}  
                                 height={40}
                                 borderRadius={9}
                                 />
                                 <Skeleton  
                                  count={1}  
                                  height={40}
                                  borderRadius={9}
                                  />
                                {/* <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div> */}
                            </>
                            :
                             vagasAbertas.length === 0 ?

                                <div className='nenhum-vaga'>
                                    <p>Nenhuma vaga encontrada.</p>
                                </div> 
                            :
                            vagasAbertas
                            .map((vagas, index) => (
                            <>
                                <NavLink to={`/lp/${id}/${vagas._id}`} >
                                        <div className="nome">
                                            <p>{vagas.title}</p>
                                        </div>
                                        <div className="cidade">
                                            <p>{vagas.bornCityString}</p>
                                        </div>
                                        <div className="tipo">
                                            <p>{vagas.type}</p>
                                        </div>
                                    </NavLink>

                                </>
                            ))}
                        





                        {/* <NavLink to={`/lp/vaga/nome-da-vaga`} >
                            <div className="nome">
                                <p>Analista de Recursos Humanos</p>
                            </div>
                            <div className="cidade">
                                <p>Curitiba</p>
                            </div>
                            <div className="tipo">
                                <p>CLT</p>
                            </div>
                        </NavLink>
                        <NavLink to={`/lp/vaga/nome-da-vaga`} >
                            <div className="nome">
                                <p>Analista de Recursos Humanos</p>
                            </div>
                            <div className="cidade">
                                <p>Curitiba</p>
                            </div>
                            <div className="tipo">
                                <p>ESTAGIO</p>
                            </div>
                        </NavLink>
                        <NavLink to={`/lp/vaga/nome-da-vaga`} >
                            <div className="nome">
                                <p>Analista de Recursos Humanos</p>
                            </div>
                            <div className="cidade">
                                <p>Curitiba</p>
                            </div>
                            <div className="tipo">
                                <p>CLT</p>
                            </div>
                        </NavLink>
                        <NavLink to={`/lp/vaga/nome-da-vaga`} >
                            <div className="nome">
                                <p>Analista de Recursos Humanos</p>
                            </div>
                            <div className="cidade">
                                <p>Curitiba</p>
                            </div>
                            <div className="tipo">
                                <p>CLT</p>
                            </div>
                        </NavLink> */}

                        {
                            pages ?
                            <div className='paginacaoG'>
                                <ul>
                                   
                                    {numberPaginate}

                            {
                                pages && pages > 0 ?
                                <>
                                <li className="page-anterior">
                                    <button style={(filterPages === 0) ? { cursor: "not-allowed" } : {}}  disabled={filterPages === 0} onClick={(event) => prevPage(event)}>anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(filterPages === pages) ? { cursor: "not-allowed" } : {}}  disabled={filterPages === pages} onClick={(event) => nextPage(event)}>próxima</button>
                                </li>
                                </>
                                :
                                ''
                            }
                                    {/* <li className="page-anterior">
                                        <button >anterior</button>
                                    </li>
                                    <li className="page-proxima">
                                        <button >próxima</button>
                                    </li> */}
                                </ul>
                            </div>

                            :""
                        }

                   




                    </div>



                </div>


            </div>
        
        
        
        
        </>
    )
}