import React from 'react';
// import Add from '../../../assets/images/add.svg';

import Dropzone from "react-dropzone";

import IconCamera from '../../../assets/images/icone-camera.svg';
import CarregandoFoto from '../../../assets/images/carregando.gif';

export default function PreInformation({ collaborator, handleDropAvatar, loadAvatar, loadAvatarVazio }) {

        return (
            <>
                <div className="mensagem-adm">O administrador da sua conta solicitou que termine de preencher seus dados
                pessoais para finalizar o processo de contratação. Preencha seus dados abaixo.</div>
                <div className="infor-col" >
                    <div>
                        {
                        loadAvatar ?
                        (
                            <img src={CarregandoFoto} alt="carregando" className="carregamento-foto" />
                        ) :
                        collaborator.avatar && collaborator.avatar ? (

                            <Dropzone onDrop={handleDropAvatar} >
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "img-col"  })}>
                                    {/* <img src={collaborator.avatar} alt={collaborator.name} className="img-adicionada"/> */}
                                    <label className="label-foto-adicionado" ><img alt="+" src={IconCamera} className="icon"/><img alt={collaborator.name}  src={collaborator.avatar}   className="foto"/></label>
                                    <input type="file" name="file" className="inputFile" {...getInputProps()}  />
                                </div>
                                )}
                            </Dropzone>

                        ) : (
                            <Dropzone onDrop={handleDropAvatar} >
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "img-col"  })}>
                                    <label className="labelIconPhoto"><div  className={loadAvatarVazio ? "iconPhoto inputErro" : "iconPhoto"}> <span></span> Enviar foto</div> </label>
                                    <input  type="file" name="file" className={loadAvatarVazio ? 'inputFile inputErro' : 'inputFile'} {...getInputProps()}/>
                                </div>
                                )}
                            </Dropzone>
                        )}
                        {loadAvatarVazio ? (
                        <p className="textError inputFile">É necessário adicionar uma foto pessoal</p>
                        ) : ('')
                        }
                    </div>

                   
                    <div className="infor">
                        <h3 className="nome">{collaborator.name}</h3>
                        <p className="cargo">{collaborator.occupation}</p>
                        <p className="departamento">Departamento<br/>
                            <span>{collaborator.sector}</span>
                        </p>
                    </div>
                </div>


            </>
        );
}

