import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Layout from '../../components/Layouts/default';
import Header from '../../components/ADMCommunication/header';
import SubMenu from '../../components/ADMCommunication/subMenu';
import Calendario from '../../assets/images/calendar-chat.svg';
import NenhumaConversa from '../../assets/images/illustration-nenhum-chat.svg';
import ArrowBrown from '../../assets/images/arrow-brown.svg';
import ArrowLeft from '../../assets/images/arrow-left.svg';
import { decodeToken } from '../../services/auth';
import { momentDurationToCommunication, momentDurationToMessageCommunication } from '../../services/utils';
import Carregamento from '../../assets/images/carregando.gif';
import api from '../../services/api';
import io from 'socket.io-client';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import './styles.scss';

export default function pageSystemConfig(props, path, name) {

    const { user } = decodeToken();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ listaColaboradorConv, setListaColaboradorConv] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ idMessageCommunication, setidMessageCommunication] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setIdCollaboratroMessageCommunication] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ abriConversa, setAbriConversa] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ conversaSelecionada, setConversaSelecionada] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcommunication();
        async function registerToSocket() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            socket.on("comunication", () => {
                loadcommunication();
            });
        }
        registerToSocket()


        async function registerToSocket2() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            socket.on("messagecommunication", () => {
                loadcommunication();
            });
        }
        registerToSocket2()

        if(idMessageCommunication !== null) {

            async function loadmessagecommunication() {

                await api.get(`/message-communication/?communication=${idMessageCommunication}`)
                 .then(response => {
                     setConversaSelecionada(response.data);
                 }).catch(error => { });

             }
             loadmessagecommunication();

             async function registerToSocket3() {
                // const socket = io(`${process.env.REACT_APP_API_URL}`);
                const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
                socket.on("messagecommunication", () => {
                    loadmessagecommunication();
                });
            }
            registerToSocket3()

        }



    }, [idMessageCommunication]);


    async function loadcommunication() {

        setLoadPage(true)
        await api.get('/collaborator-communication-index/')
        .then(response => {
        setLoadPage(false)
            setListaColaboradorConv(response.data);
        }).catch(error => {})
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter] = useState([]);

    async function loadfiltercommunication(event) {

        if(event.target.value.length === 0) {

            loadcommunication();

        }
        else {

            const name = event.target.value;

            setNameFilter(name)
            setLoadPage(true)
            await api.get(`/collaborator-communication-index/?name=${name}&sortBy=name&order=desc`)
            .then(response => {


                setLoadPage(false);
                const docs_a    = response.data[0];
                    const arrInfo = response.data[1];

                    let pagina = parseInt(arrInfo[0].pagina)

                    let itensCopyCollaborator = [];

                    if(pagina === arrInfo[0].pages) {

                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setListaColaboradorConv(itensCopyCollaborator);
                        setNameFilter(name);

                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {

                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setListaColaboradorConv(itensCopyCollaborator);
                            setNameFilter(name)

                        }
                        else {

                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].pagina * arrInfo[0].limite

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setListaColaboradorConv(itensCopyCollaborator);
                            setNameFilter(name)



                        }
                        // => Final de => Se total menor que o limite

                    }
                // setListaColaboradorConv(response.data);

            }).catch(error => {
                setLoadPage(false);
                setListaColaboradorConv([])
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ colaboradorName, setColaboradorName] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ colaboradoSelected, setColaboradoSelected] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ colaboradorID, setColaboradorID] = useState([]);

    //console.log(colaboradorName._id)

    async function myChangeHandlerTwo(event) {

        setColaboradorID(event.collaborator)

        setColaboradorName(event.nameCollaborator)

        setidMessageCommunication(event._id)
        setIdCollaboratroMessageCommunication(event.collaborator)

        if(event._id === null) {

            let collaborator = event.collaborator;

            async function loadmessagecommunication() {

                await api.get(`/message-communication/?communication=${event._id}&collaborator=${collaborator}`)
                .then(response => {

                    setColaboradoSelected(response.data.collaborator)

                    setConversaSelecionada([]);
                    setAbriConversa(true)

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            loadmessagecommunication();

            async function loadcomunication() {

                if(nameFilter && nameFilter.length > 1) {

                    await api.get(`/collaborator-communication-index/?name=${nameFilter}&sortBy=name&order=desc`)
                    .then(response => {
                    setLoadPage(false)

                        setListaColaboradorConv(response.data);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }
                else {

                    await api.get('/collaborator-communication-index/')
                    .then(response => {

                        setListaColaboradorConv(response.data);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });

                }

            }
            loadcomunication();


        }
        else {


            async function loadmessagecommunication() {

                await api.get(`/message-communication/?communication=${event._id}`)
                .then(response => {

                        setConversaSelecionada(response.data);
                        setAbriConversa(true)

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            loadmessagecommunication();

        }

    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ mensagem, setMensagem] = useState([]);

    const myChangeHandlerMessage = event => {

        let nam = event.target.name;
        let val = event.target.value;

        setMensagem({ ...mensagem, [nam]: val })

    };

    async function handleSubmitMensagem(event) {
        event.preventDefault();

        if (mensagem.message === "" ||
            mensagem.message === undefined
        ) {

        } else {

            if(colaboradoSelected.length === 0) {

                setidMessageCommunication(conversaSelecionada[0].communication)
                setIdCollaboratroMessageCommunication(event.collaborator)


                let communication = conversaSelecionada[0].communication;
                let message = mensagem.message;

                await api.post('/message-communication/', { communication, message })
                .then(response => {
                    setMensagem([])
                    async function loadmessagecommunication() {

                       await api.get(`/message-communication/?communication=${conversaSelecionada[0].communication}`)
                        .then(response => {

                            setConversaSelecionada(response.data);

                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });

                    }
                    loadmessagecommunication();

                }).catch(error => {

                });

            }
            else {

                let collaborator = colaboradoSelected;
                let message = mensagem.message;

                await api.post('/message-communication/', { collaborator, message })
                .then(response => {
                    setMensagem([])
                    setColaboradoSelected([])

                    setidMessageCommunication(response.data.communication)

                    async function loadmessagecommunication() {

                       await api.get(`/message-communication/?communication=${response.data.communication}`)
                        .then(response => {

                            setConversaSelecionada(response.data);


                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });


                        async function loadcomunication() {

                            if(nameFilter && nameFilter.length > 1) {

                                await api.get(`/collaborator-communication-index/?name=${nameFilter}&sortBy=name&order=desc`)
                                .then(response => {
                                setLoadPage(false)

                                    setListaColaboradorConv(response.data);

                                }).catch(error => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });

                            }
                            else {

                                await api.get('/collaborator-communication-index/')
                                .then(response => {

                                    setListaColaboradorConv(response.data);

                                }).catch(error => {
                                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                });

                            }

                        }
                        loadcomunication();

                    }
                    loadmessagecommunication();

                }).catch(error => {

                });

            }


        }
    }

    async function fecharConversa() {
        setConversaSelecionada([]);
        setColaboradorID([]);
    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                user={user}
                />
                <SubMenu/>
                <div className="adm-page-cominicacao sala-batepapo">
                    <h3 className="titulo">Converse direto com um colaborador</h3>
                    <div className="bate-papo">
                        <div
                        className={
                            conversaSelecionada[0]?._id ?
                            "box-left inativo"
                            : "box-left ativo"
                        }
                        >
                            <input name="name" value={name} onChange={loadfiltercommunication} placeholder="Encontrar ou começar conversa…" className="busca"/>




                            <ul className="lista-bate-papo">

                        {
                            loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):
                            listaColaboradorConv !== null && listaColaboradorConv.length !== 0 ? listaColaboradorConv
                            .map((listaColaborador, index) => (
                            <>

                            {
                                    listaColaborador.read === false &&
                                    listaColaborador.author &&
                                    listaColaborador.author !== user._id ?

                                    <li onClick={() => myChangeHandlerTwo(listaColaborador)}  className="mensagem-nova">

                                        <h4 className="name">{listaColaborador.nameCollaborator}</h4>
                                        <p className="setor">{listaColaborador.occupation}</p>
                                        <span className="hora">{
                                            listaColaborador.createdAt !== null ?
                                            `Há `+
                                            momentDurationToCommunication(listaColaborador.createdAt)
                                            :
                                            'Não iniciada'
                                        }</span>


                                    </li>
                                    :
                                    <>
                                     <li onClick={() => myChangeHandlerTwo(listaColaborador)} className={colaboradorID === listaColaborador.collaborator ? "conversa-selecionada" : ""}>
                                        <h4 className="name">{listaColaborador.nameCollaborator}</h4>
                                        <p className="setor">{listaColaborador.occupation}</p>
                                        <span className="hora">{
                                            listaColaborador.createdAt !== null ?
                                            `Há `+
                                            momentDurationToCommunication(listaColaborador.createdAt)
                                            :
                                            'Não iniciada'
                                        }</span>
                                    </li>

                                    </>
                                }


                            </>
                            )) : (
                                <>
                               <div className="nenhuma-colaborador" >
                                   <img src={ArrowBrown} alt="Nenhuma conversa" />
                                <p>Inicie uma conversa com um colaborador.</p>
                            </div>
                                </>
                        )}
                            </ul>
                        </div>
                        {
                            abriConversa ? (
                                <>


                                    <div

                                    className={
                                        conversaSelecionada[0]?._id ?
                                        "box-right ativo"
                                        : "box-right inativo"
                                    }

                                    >
                                        <div className="header">
                                            <h3><button onClick={fecharConversa}><img src={ArrowLeft} alt="fechar" /></button><span>{colaboradorName}</span> </h3>
                                        </div>
                                        <div className="conversation-overflow">
                                            <Scrollbars>
                                                <div className="conversation">
                                                    {
                                                    conversaSelecionada !== null ? conversaSelecionada
                                                    .map((conversa, index) => (
                                                    <>
                                                    { conversa.title && conversa.title ?
                                                        <>
                                                        <div className="Received">
                                                        <NavLink to={`/colaboradores/${conversa.collaborator._id}/pessoal/faltas-e-afastamentos`} className="automatic-message">
                                                                <div className="left">
                                                                    <h5>NOVA FALTA E AFASTAMENTO</h5>
                                                                    <div className="infor">
                                                                        <span>“</span><p> {conversa.message}</p>
                                                                    </div>

                                                                    <span className={conversa.read === true ? "hora viewed" : "hora not-seen"}>Mensagem automática enviada {momentDurationToMessageCommunication(conversa.createdAt)}</span>
                                                                </div>
                                                                <div className="right">
                                                                    <img src={Calendario} alt="" />
                                                                </div>
                                                        </NavLink>
                                                                </div>
                                                        </>
                                                        :
                                                        user._id === conversa.collaborator._id ?
                                                        <>
                                                            <div className="Sent">
                                                                <p className="message">{conversa.message}
                                                                <span className={conversa.read === true ? "viewed" : "not-seen"}>{momentDurationToMessageCommunication(conversa.createdAt)}</span>
                                                                </p>
                                                            </div>
                                                        </>
                                                        :
                                                        user._id !== conversa.collaborator._id ?
                                                        <>
                                                            <div className="Received" >
                                                                <p className="message">{conversa.message}
                                                                <span className="not-seen">{momentDurationToMessageCommunication(conversa.createdAt)}</span>
                                                                </p>
                                                            </div>
                                                        </>

                                                        :
                                                        ''
                                                        }

                                                        </>
                                                        ))
                                                        :
                                                        ''
                                                    }


                                            {
                                            /* <div className="conversation-overflow">

                                                <div className="conversation">
                                                    <div className="date">
                                                        <p>Quarta, 18 Ago 2021</p>
                                                    </div>
                                                    <div className="Sent">
                                                        <p className="message">Oie, Tudo bem, e voce? pode tirar
                                                        <span className="viewed">10:21</span>
                                                        </p>
                                                    </div>
                                                    <div className="date">
                                                        <p>Domingo, 31 Ago 2021</p>
                                                    </div>
                                                    <div className="Received">
                                                        <p className="message">Valleir seu lindo
                                                        <span className="not-seen">10:21</span>
                                                        </p>
                                                    </div>

                                                    <div className="Sent">
                                                        <p className="message">Eu sei obrigado
                                                        <span className="viewed">10:21</span>
                                                        </p>
                                                    </div>
                                                    <NavLink to="/" className="automatic-message">
                                                            <div className="left">
                                                                <h5>NOVA FALTA E AFASTAMENTO</h5>
                                                                <div className="infor">
                                                                    <span>“</span><p> Vou ao médico, retorno à tarde Vou ao médico, retorno à tarde. Vou ao médico, retorno à tarde..</p>
                                                                </div>

                                                                <span className="hora viewed">Mensagem automática enviada às 10:21</span>
                                                            </div>
                                                            <div className="right">
                                                                <img src={Calendario} alt="" />
                                                            </div>
                                                    </NavLink>  */
                                                    }

                                                </div>
                                            </Scrollbars>



                                        </div>

                                        <div className="form-conversation">
                                            <input type="text" name="message" value={(mensagem.message !== undefined) ? mensagem.message : ''}  placeholder="Envie uma mensagem" onChange={myChangeHandlerMessage}/>

                                            <form onSubmit={handleSubmitMensagem} >
                                                <button className="enviar"></button>
                                            </form>
                                        </div>
                                    </div>


                                </>
                            ):(
                                <>
                                    <div
                                        className={
                                            conversaSelecionada[0]?._id ?
                                            "nenhuma-conversa ativo"
                                            : "nenhuma-conversa inativo"
                                        }
                                    >
                                        <p>Nenhuma conversa.</p>
                                        <img src={NenhumaConversa} alt="" />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </Layout>


        </>
    );
}
