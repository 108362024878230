import React from 'react';
import { NavLink } from 'react-router-dom'
// import ImgExemplo from '../../../../assets/images/alvarobraz.png';
import Carregando from '../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import IllustrationPraia from '../../../../assets/images/illustration-praia.svg';
import { dateFormatedToStripe } from '../../../../services/utils'

export default function HistoricoFerias({
    loadHistoryVacations,
    vacationsCompanyApproved,
    pageApproved,
    pages,
    prevPageApproved,
    nextPageApproved,
    paginationPageApproved
}) {

    return (
        <>
            <div className="lista-historico">
        {
            vacationsCompanyApproved &&
            vacationsCompanyApproved.length === 0 ?
            ("")
            : (<>
                <ul className="lista">
                {
                    vacationsCompanyApproved ?
                    vacationsCompanyApproved
                    .map((vacationsCompany, index) => (
                        <li key={index}>
                            <div className="infor-cal">
                                {
                                    vacationsCompany.collaborator.avatar !== undefined ?
                                    <>
                                    <div className="img-col">
                                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                            <img src={vacationsCompany.collaborator.avatar} alt="nome" />
                                        </NavLink>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <NavLink to="/ferias/" >
                                        <div className="semImgCol">
                                            <img src={IconUserNone} alt="coll" />
                                        </div>
                                    </NavLink>
                                    </>
                                }
                                <div className="nome-setor">
                                    <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                    {vacationsCompany.collaborator.name}
                                    </NavLink>
                                    <div className="setor">
                                        <p className="setor">{vacationsCompany.collaborator.occupation}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="info-historico">
                                <p>De <span>{dateFormatedToStripe(vacationsCompany.startDate)}</span></p>
                                <p>Até <span>{dateFormatedToStripe(vacationsCompany.endDate)}</span></p>
                                <p>Dias de férias <span>{vacationsCompany.vacationDays}</span></p>
                                <p>Dias de abono <span>{vacationsCompany.vacationDaysSold}</span></p>
                                <p>Total <span>{vacationsCompany.balance}</span></p>
                            </div>
                    </li>
                    )) :
                    ''
                }


            </ul>

            </>)}
        {
            loadHistoryVacations ? (
            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
            ) :
            vacationsCompanyApproved &&
            vacationsCompanyApproved.length === 0 ?
            <>
            <div className="lista-vazia">
                <p>Essa praia está deserta. <br/>
                    Nenhum histórico de férias.</p>
                    <img src={IllustrationPraia} alt="illustração praia" />
            </div>
            </>
            :
            <>
            {/* <div className="paginacaoG">
                <ul>
                    {
                        pages && pages !== undefined && pages > 1 ?
                        numberPaginate
                        :
                        ''
                    }
                    {
                        pages && pages !== undefined && pages > 1 ?
                        <>
                        <li className="page-anterior">
                            <button tyle={(pageApproved === 1) ? { cursor: "not-allowed" } : {}}  disabled={pageApproved === 1} onClick={(event) => prevPageApproved(event)} >anterior</button>
                        </li>
                        <li className="page-proxima">
                            <button style={(pageApproved === pages) ? { cursor: "not-allowed" } : {}}  disabled={pageApproved === pages} onClick={(event) => nextPageApproved(event)} >próxima</button>
                        </li>
                        </>
                        :
                        ''
                    }

                </ul>
            </div> */}
            </>
        }



            </div>
        </>
    );
}
