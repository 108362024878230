import React from 'react';
import { Dropdown } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import iconVerMais from '../../../../assets/images/more.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import { formatDateString } from '../../../../services/utils';
import IconADD from '../../../../assets/images/add-branco.svg'
import Paginacao from '../../../../components/Administrativo/Colaboradores/Ativos/paginacao'

// import api from '../../../../services/api';

const ColaboradoresDesligado = ({
    allShutdown,
    pages,
    prevPage,
    nextPage,
    paginationPage,
    load,
    page,
    nameFilter,
    onUserInputChange
}) => {

    return (
        <>

            <div className="page-todos-desligamento">
                <div className="titulo">
                    <h2 >Colaboradores desligados</h2>
                    <div className="btn-back">
                        <NavLink to="/colaboradores/processo-desligamento" >Voltar à lista em andamento</NavLink>
                    </div>
                </div>
                <div className='busca-button'>

                <form className="buscaColaboradores">
                    <input
                    name="name"
                    value={nameFilter && nameFilter !== undefined ? nameFilter : ''}
                    onChange={onUserInputChange}
                    className="campo-busca"
                    autocomplete="off"
                    placeholder="Filtre por colaborador, cargo, departamento e mais"
                    />
                    </form>
                    <NavLink to="/colaboradores/nova-admissao/formulario-Informacoes-basicas" className="btn-nova-admissaoMB"><img src={IconADD} alt="" /></NavLink>
                </div>

                <table className="tabela">
                {
                load ?
                (<div className="carregando"><img src={Carregando} alt="carregando" /></div>) :

                    allShutdown && allShutdown.length === 0 ?
                    (<div className="nenhum-colaborador"><p>Nenhum colaborador desligado</p></div>):
                    allShutdown.lenght !== 0 ? allShutdown
                    // .filter(shutdown => shutdown.recentShutdown === true)
                    .map((shutdown, index) => (
                    <tr key={index} className="tabelaTR">
                    <td className="inten-1">
                        <NavLink to={`/colaboradores/${shutdown._id}/pessoal/`}>
                            <div className="colaborador">
                                {/* <img alt={shutdown.name} src={shutdown.avatar} /> */}
                                {
                                    shutdown?.avatar !== undefined &&
                                    shutdown?.avatar !== "undefined" ?
                                    <img alt={shutdown.name} src={shutdown.avatar} className="com-foto" />
                                    :
                                        <div className="semImgCol">
                                            <img src={IconUserNone} alt="Usuario sem imagem" />
                                        </div>
                                }
                                <strong>{shutdown.name}</strong>
                                <p>{shutdown.occupation}</p>
                            </div>
                        </NavLink>
                    </td>
                    <td className="inten-2">
                        <NavLink to={`/colaboradores/${shutdown._id}/pessoal/`}>
                            {shutdown.sector}
                        </NavLink>
                    </td>
                    <td className="inten-3">
                        <NavLink to={`/colaboradores/${shutdown._id}/pessoal/`}>
                            Em {shutdown.updatedAt ? formatDateString(shutdown.updatedAt) : ''}
                        </NavLink>
                    </td>
                    <td className="inten-4">
                        <Dropdown  className="dropdown-icon">
                            <Dropdown.Toggle>
                            <img alt="" src={iconVerMais} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                    <NavLink to={`/colaboradores/${shutdown._id}/pessoal/`}>
                                        Ver perfil
                                    </NavLink>
                                    <NavLink to={`/colaboradores/desligamento/${shutdown._id}/contabilidade`}>
                                        Ver Informações do desligamento
                                    </NavLink>



                                    {/* <button type="button">
                                        Ver perfil
                                    </button> */}
                                    {/* <button type="button">
                                        Migrar colaborador
                                    </button> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    </tr>
                    )) : ('')}
                </table>

                {/* {loadshutdown ?
                (<div className="carregando"><img src={Carregando} alt="carregando" /></div>) :
                load ?
                (<div className="carregando"><img src={Carregando} alt="carregando" /></div>) :
                ('')} */}
                {
                    load ?
                    ''
                    :
                    allShutdown && allShutdown.length === 0 ?
                    ''
                    :
                    <>

                        {pages === 0 ? '' : (
                           <Paginacao
                           page={page}
                           pages={pages}
                           prevPage={(event) => prevPage(event)}
                           nextPage={(event) => nextPage(event)}
                           paginationPage={paginationPage}
                           />
                       )}


                    </>
                }
            </div>

        </>
    );
}
export default ColaboradoresDesligado;
