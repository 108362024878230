import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import swal from 'sweetalert';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Ferias/Header-C-F-Coletiva';
import { dateMask } from '../../../services/mask';
import { StringToDate } from '../../../services/utils';
import { Scrollbars } from 'react-custom-scrollbars';
// import ListBenefits from '../../../components/Administrativo/Beneficios/list-benefits' loadRegisterCollectiveVacation
import './styles.scss';


export default function CadastrarFeriasColetiva(props) {

    const { id } = props.match.params;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameVacation,  setLoadNameVacation] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSector, setLoadSector] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInitialDate, setLoadInitialDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFinalDate, setLoadFinalDate ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAccounting,  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRegisterCollectiveVacation, setLoadRegisterCollectiveVacation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [accounting, setAccounting] = useState([]);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [collectiveVacation, setCollectiveVacation] = useState([]);

    useEffect(() => {

        loadsectors();
        loadaccounting();

    }, [id]);

    async function loadsectors() {
        await api.get('/sector/')
        .then(response => {
            setSectors(response.data);
        }).catch(error => {})
    }

    async function loadaccounting() {

        await api.get('/accounting/')
        .then(response => {

            setAccounting(response.data);

        }).catch(() => {})
    }


    //collectiveVacation
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collectiveVacation, setCollectiveVacation ] = useState([]);

    async function myChangeHandlerRegisterCollectiveVacation(event) {

        let nam             = event.target.name;
        let val             = event.target.value;
        // let discountVcation = false;
        let category        = 'vacation';
        let type            = 'coletivas';



        if(nam === "nameVacation") {

            let status           = 'aprovado'

            if(val.length === 0) {
                setLoadNameVacation(true)
                setCollectiveVacation({ ...collectiveVacation, [nam]:val, category, type })
            }
            else {
                setLoadNameVacation(false)
                setCollectiveVacation({ ...collectiveVacation, [nam]:val, category, type, status })
            }

        }else
        if(nam === "sector") {

           setLoadSector(false)
           if(val === "Todos") {

                setCollectiveVacation({ ...collectiveVacation, sector: '', nameSector: 'Todos'  })

           }else {

            if(val.split('&&')[1] !== undefined) {

                let sector     = val.split('&&')[0]
                let nameSector = val.split('&&')[1]

                setCollectiveVacation({ ...collectiveVacation, sector, nameSector  })

            }

           }
        }
        else
        if(nam === 'startDate') {

            if(val.length === 0) {

                setLoadInitialDate(true)

            }

            if(val.length >= 10) {

                setLoadInitialDate(false)

            }
            let startDate = dateMask(val)
            let startDateStartVacationProcess = StringToDate(startDate)
            setCollectiveVacation({ ...collectiveVacation, [nam]:startDate, startDateStartVacationProcess })


        }
        else
        if(nam === 'endDate') {

            if(val.length === 0) {

                setLoadFinalDate(true)

            }

            if(val.length >= 10) {

                setLoadFinalDate(false)

            }

            let endDate = dateMask(val)
            let endDateStartVacationProcess = StringToDate(endDate)
            setCollectiveVacation({ ...collectiveVacation, [nam]:endDate, endDateStartVacationProcess })

        }
        else
        if(nam === 'accounting') {
            setCollectiveVacation({ ...collectiveVacation, "accounting": val })
        }
        else {
            setCollectiveVacation({ ...collectiveVacation, [nam]:val })
        }



    }


    async function saveRegisterCollectiveVacation() {

        if(
            collectiveVacation                     === undefined ||
            collectiveVacation.length              ===     0

        ) {
            setLoadSector(true)
            setLoadNameVacation(true)
            setLoadInitialDate(true)
            setLoadFinalDate(true)
            // swal({ icon: "error", title: "Erro!", text: 'Necessário preencher pelo menos um campo!' });
        }
        else {

            if(id !== undefined) {
                // await api.put(`/collaborator/${id}`, formData, config)
                setLoadRegisterCollectiveVacation(true)
                await api.put(`/collaborator-request/${id}`, collectiveVacation)
                .then(response => {

                    setCollectiveVacation([]);
                    async function loadcollectivevacation() {
                        await api.get(`/collaborators-request-collective-vacations/?category=F%C3%A9rias%20Coletivas&id=${id}`)
                        .then(response => {
                            setCollectiveVacation(response.data);
                        }).catch(error => {})
                    }
                    loadcollectivevacation();
                    setLoadRegisterCollectiveVacation(false);
                    swal({ icon: "success", title: "Sucesso!", text: 'Férias coletivas editadas com sucesso!' });


                }).catch(error => {

                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadRegisterCollectiveVacation(false)

                });
            }
            else {

                if(collectiveVacation.nameVacation === undefined) {
                    setLoadNameVacation(true)
                }
                if(collectiveVacation.sector === undefined) {
                    setLoadSector(true)
                }
                if(collectiveVacation.startDate === undefined) {
                    setLoadInitialDate(true)
                }
                if(collectiveVacation.endDate === undefined) {
                    setLoadFinalDate(true)
                }

                if(
                    collectiveVacation.nameVacation === undefined ||
                    collectiveVacation.sector       === undefined ||
                    collectiveVacation.startDate    === undefined ||
                    collectiveVacation.endDate      === undefined
                ) {
                    return
                }

                setLoadRegisterCollectiveVacation(true)
                await api.post('/collaborator-vacation-new/', collectiveVacation)
                .then(response => {

                    setCollectiveVacation([]);
                    setLoadRegisterCollectiveVacation(false);
                    swal({ icon: "success", title: "Sucesso!", text: 'Férias coletivas cadastradas com sucesso!' });


                }).catch(error => {

                    swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    setLoadRegisterCollectiveVacation(false)

                });


            }


        }

    }

    async function cancelRegisterCollectiveVacation() {

        setTimeout(function(){window.location.href = '/ferias'}, 0);

    }

    // async function deleteCollectiveVacations(event) {
    //     event.preventDefault();

    //     const idCollectiveVaction = event.target.value;

    //     swal({
    //         title: "Atenção",
    //         text: "Deseja realmente excluir as férias coletivas!",
    //         icon: "warning",
    //         buttons: ["Cancelar", "OK"],
    //         dangerMode: false,
    //     })
    //     .then(async (res) => {

    //         if (res) {

    //             await api.delete(`/collaborator-request-collective-vacation/${idCollectiveVaction}`)
    //             .then(response => {

    //                 swal({ icon: "success", title: "Sucesso!", text: "Férias coletivas deletadas com sucesso." });
    //                 window.location.href = '/ferias';

    //             }).catch(error => {
    //                 swal({ icon: "error", title: "Erro!", text: "Erro ao excluir as férias coletivas, tente novamente mais tarde." });
    //             });

    //         }

    //     })


    // }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA TIPO DE sector ///////////
    ////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectSecto, setIsActiveSelectSecto] = useState(false);
    async function SelectSecto() { setIsActiveSelectSecto(!isActiveSelectSecto) }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA TIPO DE sector ///////////
    ////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectAccounting, setIsActiveSelectAccounting] = useState(false);
    async function SelectAccounting() { setIsActiveSelectAccounting(!isActiveSelectAccounting) }
    // console.log(collectiveVacation && collectiveVacation[0]?.nameVacation ? collectiveVacation[0].nameVacation : collectiveVacation)

    return (
        <>
            <Layout
            path={props.match.path}>
                <div className="pagina-C-F-coletiva">
                <Header
                id={id}
                />
                    <div className="form-C-F-coletiva">
                        <ul className="box-form">
                            <li>
                                <p className="texto">Nome</p>
                                <div className="inputs">
                                    <input
                                    type="text"
                                    value={
                                        collectiveVacation?.nameVacation === '' ||
                                        collectiveVacation?.nameVacation ?
                                        collectiveVacation?.nameVacation :
                                        ''
                                    }
                                    onChange={myChangeHandlerRegisterCollectiveVacation}
                                    name="nameVacation"
                                    placeholder="Ex: Ano novo e natal"
                                    className={loadNameVacation ? 'inputErro' : ''}
                                    />
                                    {loadNameVacation ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">Departamento</p>
                                <div className="inputs">


                                    <div className="select-busca-btn">

                                        <button
                                            className={loadSector ? 'inputErro menu-button' : 'menu-button'}
                                            // ref={focusAdmissionType}
                                            onBlur={ () => (setIsActiveSelectSecto(false)) }
                                            onClick={ SelectSecto }
                                        >
                                            <span>
                                                {

                                                        collectiveVacation.nameSector ?
                                                        collectiveVacation.nameSector :
                                                        'Selecione'
                                                }

                                            </span>

                                        </button>




                                        <nav className={`menu ${isActiveSelectSecto ? "ativo": ""}`} >

                                            <Scrollbars className='listDropDown'  style={{ height:
                                                    sectors.length === 0 ? 35 : "" ||
                                                    sectors.length === 1 ? 70 : ""  ||
                                                    sectors.length === 2 ? 120 : 120
                                            }}>
                                                <ul>

                                                <li >
                                                    <button
                                                    value="Todos"
                                                    name="sector"
                                                    onClick={myChangeHandlerRegisterCollectiveVacation}
                                                    >Todos</button>
                                                </li>
                                                    {
                                                        sectors
                                                        .map((item, index) => (
                                                            <li key={index}>
                                                                <button
                                                                value={item._id+'&&'+item.name}
                                                                name="sector"
                                                                onClick={
                                                                myChangeHandlerRegisterCollectiveVacation

                                                            }
                                                                >{item.name}</button>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>

                                            </Scrollbars>
                                        </nav>
                                    </div>

                                {loadSector ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}

                                 </div>
                            </li>
                            <li>
                                <p className="texto">De</p>
                                <div className="inputs datas">
                                    <div className="dataUm">
                                        <input
                                        value={
                                            (collectiveVacation &&
                                            collectiveVacation.startDate === '') ||
                                            collectiveVacation.startDate ?
                                            collectiveVacation.startDate :
                                            ''
                                        }
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="text"
                                        name="startDate"
                                        placeholder="DD/MM/AAAA"
                                        className={loadInitialDate ? 'inputErro' : ''}
                                        />
                                        {loadInitialDate ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}

                                    </div>
                                    <span className="spanAte">Até</span>
                                    <div className="dataDois">
                                        <input
                                        value={
                                            collectiveVacation?.endDate === '' ||
                                            collectiveVacation?.endDate ?
                                            collectiveVacation?.endDate :
                                            ''
                                        }
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="text" name="endDate" placeholder="DD/MM/AAAA"
                                        className={loadFinalDate ? 'inputErro' : ''}
                                        />
                                        {loadFinalDate ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}

                                    </div>
                                </div>
                            </li>
                            <li className="checkboxC">
                                <p className="texto">Descontar feriados</p>
                                <div className="inputs ">
                                {
                                    collectiveVacation                             &&
                                    collectiveVacation.discountVcation === false ?
                                    <>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="false"
                                        id="nao"
                                        defaultChecked={true}
                                        />
                                        <label htmlFor="nao"><span>Não</span></label>
                                    </div>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="true"
                                        id="sim"
                                        />
                                        <label htmlFor="sim"><span>Sim</span></label>
                                    </div>
                                    </>
                                    :
                                    collectiveVacation                             &&
                                    collectiveVacation.discountVcation === true  ?
                                    <>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="false"
                                        id="nao"
                                        />
                                        <label htmlFor="nao"><span>Não</span></label>
                                    </div>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="true"
                                        id="sim"
                                        defaultChecked={true}
                                        />
                                        <label htmlFor="sim"><span>Sim</span></label>
                                    </div>
                                    </>
                                    :
                                    collectiveVacation[0]                                  &&
                                    collectiveVacation[0].discountVcation === false      ?
                                    <>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="false"
                                        id="nao"
                                        defaultChecked={true}
                                        />
                                        <label htmlFor="nao"><span>Não</span></label>
                                    </div>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="true"
                                        id="sim"
                                        />
                                        <label htmlFor="sim"><span>Sim</span></label>
                                    </div>
                                    </>
                                    :
                                    collectiveVacation[0]                                  &&
                                    collectiveVacation[0].discountVcation === true      ?
                                    <>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="false"
                                        id="nao"
                                        />
                                        <label htmlFor="nao"><span>Não</span></label>
                                    </div>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="true"
                                        id="sim"
                                        defaultChecked={true}
                                        />
                                        <label htmlFor="sim"><span>Sim</span></label>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="false"
                                        id="nao"
                                        defaultChecked={true}
                                        />
                                        <label htmlFor="nao"><span>Não</span></label>
                                    </div>
                                    <div className="chekboxNovo">
                                        <input
                                        onChange={myChangeHandlerRegisterCollectiveVacation}
                                        type="radio"
                                        name="discountVcation"
                                        value="true"
                                        id="sim"
                                        />
                                        <label htmlFor="sim"><span>Sim</span></label>
                                    </div>
                                    </>
                                }
                                </div>
                            </li>
                        </ul>
                        <div className="linha"></div>
                        <ul className="box-form">
                            <li>
                                {/* valleir */}
                                <p className="texto">Contador</p>
                                <div className="inputs">





                                <div className="select-busca-btn">

                                    <button
                                        className={loadAccounting ? 'inputErro menu-button' : 'menu-button'}
                                        // ref={focusAdmissionType}
                                        onBlur={ () => (setIsActiveSelectAccounting(false)) }
                                        onClick={ SelectAccounting }
                                    >
                                        <span>

                                            {
                                                collectiveVacation &&
                                                collectiveVacation.accounting ?
                                                    accounting && accounting.map((item, index) => (
                                                        collectiveVacation.accounting  === item._id ?
                                                            item.name
                                                        : "Selecione"
                                                    ))
                                                :
                                                collectiveVacation[0]         &&
                                                collectiveVacation[0].accountingID         ?

                                                    accounting && accounting.map((item, index) => (
                                                        collectiveVacation[0].accountingID  === item._id ?
                                                                item.name
                                                            :   "Selecione"
                                                        ))
                                                :
                                                'Selecione'
                                            }

                                        </span>

                                    </button>

                                    <nav className={`menu ${isActiveSelectAccounting ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{
                                            height:
                                            accounting.length === 0 ? 35 : "" ||
                                            accounting.length === 1 ? 40 : ""  ||
                                            accounting.length === 2 ? 70 : ""  ||
                                            accounting.length === 3 ? 120 : 120
                                        }}>
                                            <ul>

                                                {
                                                    accounting.length !== 0 ?
                                                    accounting
                                                    .map((item, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={item._id }
                                                            name="accounting"
                                                            onClick={myChangeHandlerRegisterCollectiveVacation}
                                                            >{item.name }</button>
                                                        </li>
                                                    ))
                                                    :
                                                    <li>
                                                        <button >Nenhum contador cadastrado</button>
                                                    </li>
                                                }
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>








{/*
                                    <div className='cont-select'>
                                        <button className={loadSector ? 'inputErro select-' : 'select-'} >
                                            <p>
                                                {
                                        collectiveVacation &&
                                        collectiveVacation.accounting ?
                                            accounting && accounting.map((item, index) => (
                                                collectiveVacation.accounting  === item._id ?
                                                    item.name
                                                : "Selecione"
                                            ))
                                        :
                                        collectiveVacation[0]         &&
                                        collectiveVacation[0].accountingID         ?

                                            accounting && accounting.map((item, index) => (
                                                collectiveVacation[0].accountingID  === item._id ?
                                                        item.name
                                                    :   "Selecione"
                                                ))
                                        :
                                        'Selecione'
                                                }
                                            </p>
                                        </button>

                                            <div className="dropDown">
                                                <Scrollbars className='listDropDown'  style={{  height:
                                                   accounting.length === 0 ? 35 : "" ||
                                                   accounting.length === 1 ? 35 : ""  ||
                                                   accounting.length === 2 ? 70 : ""  ||
                                                   accounting.length === 3 ? 120 : 120

                                                    }}>
                                                    {
                                                    accounting.length !== 0 ?
                                                        accounting && accounting
                                                        .map((item, index) => (

                                                            collectiveVacation &&
                                                            collectiveVacation.accounting !== undefined ?
                                                                collectiveVacation.accounting === item._id ?
                                                                ""
                                                                :
                                                                    <button key={index} className='item'
                                                                        onClick={() => setCollectiveVacation({ ...collectiveVacation, "accounting": item._id  })
                                                                    }
                                                                ><p >{item.name}</p></button>
                                                            :
                                                                <button key={index} className='item'
                                                                    onClick={() =>  setCollectiveVacation({ ...collectiveVacation, "accounting": item._id  })
                                                                }
                                                                ><p >{item.name}</p></button>

                                                        ))
                                                    :
                                                    <button className='item'><p >Nenhum contador</p></button>
                                                }
                                                </Scrollbars>
                                            </div>
                                    </div>
 */}






                                    {/* <select
                                    onChange={myChangeHandlerRegisterCollectiveVacation}
                                    name="accounting"
                                    className={loadAccounting ? 'inputErro' : ''}
                                    value={
                                        collectiveVacation &&
                                        collectiveVacation.accounting ?
                                        collectiveVacation.accounting :
                                        collectiveVacation[0]         &&
                                        collectiveVacation[0].accountingID         ?
                                        collectiveVacation[0].accountingID         :
                                        ''
                                    }
                                    >
                                        <option value="">Selecione</option>
                                        {accounting && accounting.map((item, index) => (
                                            <option value={item._id} key={index}>{item.name}</option>
                                        ))}
                                    </select> */}
                                    {loadAccounting ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}
                                </div>
                            </li>
                            <li className="Justificativa">
                                <p className="texto">Justificativa</p>
                                <div className="inputs">
                                    <textarea
                                    value={
                                        (collectiveVacation &&
                                        collectiveVacation.comments === '') ||
                                        collectiveVacation.comments ?
                                        collectiveVacation.comments :
                                        collectiveVacation[0]         &&
                                        collectiveVacation[0].comments         ?
                                        collectiveVacation[0].comments         :
                                        ''
                                    }
                                    onChange={myChangeHandlerRegisterCollectiveVacation}
                                    name="comments"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    placeholder="Digite uma mensagem…"
                                    >

                                    </textarea>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="btns">

                        <button onClick={saveRegisterCollectiveVacation} className={loadRegisterCollectiveVacation  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                        <button onClick={cancelRegisterCollectiveVacation} className="btnGray cancelar">Cancelar</button>
                       {/* {
                            id !== undefined ?
                                <button onClick={deleteCollectiveVacations} value={id} className="delete">
                                    Excluir férias coletivas
                                </button>
                                : ""
                        } */}

                    </div>
                </div>

            </Layout>
        </>
    );
}

