import React, { useState } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import swal from 'sweetalert';
import api from '../../../services/api';
import { NavLink } from 'react-router-dom'
// import axios from 'axios';
import { mCPFmCNPJ,phones } from '../../../services/mask';
import './styles.scss';
export default function pageSystemConfigAccoutants(props, path) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButton, setLoadButton] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ accountingAdd, setAccountingAdd ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameSave, setLoadNameSave] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPhoneSave, setLoadPhoneSave] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmailSave, setLoadEmailSave] = useState(false);

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "CNPJ") {
            let CNPJAqui = mCPFmCNPJ(val)
            setAccountingAdd({ ...accountingAdd, [nam]: CNPJAqui })
            if(val.length >= 14) {
                // setLoadcpf(false)
            }
        } else if (nam === "phone") {
            let phonesAqui = phones(val)
            setAccountingAdd({ ...accountingAdd, [nam]: phonesAqui })
        }
        else {
            setAccountingAdd({ ...accountingAdd, [nam]: val })
        }
    }

    async function addAccounttant() {
        // alert("aqui")
        if(accountingAdd && accountingAdd.length === 0) {
            swal({ icon: "error", title: "Erro!", text: "Necessário adicionar pelo menos um campo!" });
        }
        else {
            const formData = new FormData();
            // const config = {
            //     headers: {
            //         'content-type': 'multipart/form-data'
            //     }
            // }

            if(
                accountingAdd                                   &&
                accountingAdd.name                !== undefined &&
                accountingAdd.name                !== ''
            ) {
                formData.append('name', accountingAdd.name);
                setLoadNameSave(false)
            }
            else {
                setLoadNameSave(true)
            }

            if(
                accountingAdd                                   &&
                accountingAdd.email                !== undefined &&
                accountingAdd.email                !== ''
            ) {
                formData.append('email', accountingAdd.email);
                setLoadEmailSave(false)
            }
            else {
                setLoadEmailSave(true)
            }

            if(
                accountingAdd                                   &&
                accountingAdd.phone                !== undefined &&
                accountingAdd.phone                !== ''
            ) {
                formData.append('phone', accountingAdd.phone);
                setLoadPhoneSave(false)
            }
            else {
                setLoadPhoneSave(true)
            }

            if(
                accountingAdd                                   &&
                accountingAdd.CNPJ                !== undefined &&
                accountingAdd.CNPJ                !== ''
            ) {
                // alert("cpj")
                formData.append('CNPJ', accountingAdd.CNPJ);
                setLoadPhoneSave(false)
            }

            if(
                accountingAdd                                    ||
                (accountingAdd.name                !== undefined   &&
                accountingAdd.name                !== '')          ||
                (accountingAdd.email                !== undefined  &&
                accountingAdd.email                !== '')         ||
                (accountingAdd.phone                !== undefined  &&
                accountingAdd.phone                !== '')
            ) {
                setLoadButton(true)
                await api.post('/accounting', accountingAdd)
                .then(response => {
                    setLoadButton(false)
                    swal({ icon: "success", title: "Sucesso!", text: "Contador adicionado com sucesso." });
                    window.location.href = '/configuracao/contadores';

                }).catch(error => {
                    setLoadButton(false)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });
            }



        }

    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-cadastrar-contador">
                    <h5 className="titulo">Cadastrar contador</h5>
                    <NavLink to={`/configuracao/contadores`} className="voltar">
                        Voltar para lista
                    </NavLink>
                    <div className="formulario">
                        <ul>
                            <li>
                                <p className="texto">Nome completo*</p>
                                <div>
                                    <input type="text" name="name" value={
                                    accountingAdd &&
                                    accountingAdd.name !== undefined ?
                                    accountingAdd.name
                                    :
                                    ''
                                } placeholder="Nome completo" onChange={myChangeHandler} className={loadNameSave ? 'inputErro' : ''}/>
                                    {loadNameSave? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">Email*</p>
                                <div>
                                    <input type="text" name="email" placeholder="Email" onChange={myChangeHandler} className={loadEmailSave ? 'inputErro' : ''}/>
                                    {loadEmailSave? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">Telefone*</p>
                                <div>
                                    <input type="text" name="phone" value={
                                    accountingAdd &&
                                    accountingAdd.phone !== undefined ?
                                    accountingAdd.phone
                                    :
                                    ''
                                } placeholder="Telefone" onChange={myChangeHandler} className={loadPhoneSave ? 'inputErro' : ''}  />
                                    {loadPhoneSave? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <p className="texto">CPF/CNPJ</p>
                                <div>
                                    <input type="text" name="CNPJ" value={
                                    accountingAdd &&
                                    accountingAdd.CNPJ !== undefined ?
                                    accountingAdd.CNPJ
                                    :
                                    ''
                                }  placeholder="CPF/CNPJ (Opcional)" onChange={myChangeHandler} />
                                </div>
                            </li>
                            <li>
                                <p></p>
                                <button onClick={addAccounttant} className={loadButton ? "btnGreen load" : "btnGreen"} >Salvar contador</button>
                            </li>
                        </ul>

                    </div>


                </div>
            </Layout>
        </>
    );
}
