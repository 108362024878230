import React from 'react';
import { Dropdown } from "react-bootstrap";
// import ImgExemplo from '../../assets/images/alvarobraz.png';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import IconAddCol from '../../assets/images/icone-add-calendar.svg';


export default function DropdownCalendar({
    eventCalendar,
    myChangeHanldlerEventcalendar,
    eventCalendarUpdate,
    dataFormatadaParaTraco,
    hours,
    onClickeventCategory,
    collaboratorEventCalendar,
    myChangeHandlerToAddCollaborator,
    collaboratorsToUpdate,
    myChangeHandlerToAddCollaboratorToUpdate,
    collaboratorFilterForThisEventCalendar,
    loadFilterCollaboratorForThisEventCalendar,
    PesqCollaborator,
    name,
    collaborators,
    AddColl,
    campoPesquicaColl,
    updateEventcalendar
}) {

    // console.log('eventCalendarUpdate.startTimeEvent')
    // console.log(eventCalendarUpdate.startTimeEvent.split(':'))
    // console.log(parseInt(eventCalendarUpdate.startTimeEvent))


    return (
        <>
        <Dropdown.Menu>
            <div className="formulario">
            <input
            type="text"
            name="eventName"
            value={
                (eventCalendarUpdate                  &&
                eventCalendarUpdate.eventName === '') ||
                eventCalendarUpdate.eventName ?
                eventCalendarUpdate.eventName :
                eventCalendar.eventName ?
                eventCalendar.eventName :
                ''
            }
            onChange={myChangeHanldlerEventcalendar}
            placeholder="Escreva titulo"
            className="titulo"
            />
            <input
            type="date"
            name="eventDate_"
            value={
                (eventCalendarUpdate                           &&
                eventCalendarUpdate.eventDate_ === '')         ||
                eventCalendarUpdate.eventDate_                  ?
                eventCalendarUpdate.eventDate_                  :
                eventCalendar.eventDate                         ?
                dataFormatadaParaTraco(eventCalendar.eventDate) :
                ''
            }
            onChange={myChangeHanldlerEventcalendar}
            className="data"
            required
            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
            />
                <div className="horas">
                        <select
                        name="startTimeEvent"
                        onChange={myChangeHanldlerEventcalendar}
                        className={
                            eventCalendarUpdate.startTimeEvent !== undefined || eventCalendarUpdate.endTimeEvent !== undefined ? 
                                eventCalendarUpdate.startTimeEvent ===  eventCalendarUpdate.endTimeEvent  ||
                                eventCalendar.startTimeEvent === eventCalendarUpdate.endTimeEvent ||
                                eventCalendarUpdate.startTimeEvent === eventCalendar.endTimeEvent  ? 
                                "error-hr"
                                :
                                ""
                            :
                            ""
                        }

                        >
                            {
                                eventCalendar.startTimeEvent ?
                                <>
                                <option value={eventCalendar.startTimeEvent} >{eventCalendar.startTimeEvent}</option>
                                {
                                    hours
                                    .map((item, index) => (
                                        item === eventCalendar.startTimeEvent ?
                                        ''
                                        :
                                        <option value={item} key={index}>{item}</option>
                                    ))
                                }
                                </>
                                :
                                hours
                                .map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))
                            }
                        </select>
                        <select
                        name="endTimeEvent"
                        onChange={myChangeHanldlerEventcalendar}
                        className={
                            eventCalendarUpdate.startTimeEvent !== undefined || eventCalendarUpdate.endTimeEvent !== undefined ? 
                                eventCalendarUpdate.startTimeEvent ===  eventCalendarUpdate.endTimeEvent  ||
                                eventCalendar.startTimeEvent === eventCalendarUpdate.endTimeEvent ||
                                eventCalendarUpdate.startTimeEvent === eventCalendar.endTimeEvent  ? 
                                "error-hr"
                                :
                                ""
                            :
                            ""
                        }
                        >
                            {
                                eventCalendar.endTimeEvent ?
                                <>
                                <option value={eventCalendar.endTimeEvent} >{eventCalendar.endTimeEvent}</option>
                                {
                                    hours
                                    .map((item, index) => (
                                        item === eventCalendar.endTimeEvent ?
                                        ''
                                        :
                                        <option value={item} key={index}>{item}</option>
                                    ))
                                }
                                </>
                                :
                                hours
                                .map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div>
                    {       eventCalendarUpdate.startTimeEvent !== undefined || eventCalendarUpdate.endTimeEvent !== undefined ? 
                                
                                eventCalendarUpdate.startTimeEvent ===  eventCalendarUpdate.endTimeEvent  ||
                                eventCalendar.startTimeEvent === eventCalendarUpdate.endTimeEvent ||
                                eventCalendarUpdate.startTimeEvent === eventCalendar.endTimeEvent  ? 
                                    <div className='hr-nao-pode-igual'>
                                        <p>
                                            Hora final não pode ser igual a inicial!
                                        </p>
                                    </div>
                                : 
                                ""

                            :
                            ""
                        }
                    </div>
                    <div className="tipo-evento">
                        <button
                        name="eventCategory_"
                        id="comercial"
                        onClick={onClickeventCategory}
                        className={
                            eventCalendarUpdate &&
                            eventCalendarUpdate.eventCategory_ === 'comercial' ?
                            "comercial selecionado"
                            :
                            eventCalendarUpdate &&
                            eventCalendarUpdate.eventCategory_ !== undefined &&
                            eventCalendarUpdate.eventCategory_ !== 'comercial'?
                            "comercial"
                            :
                            eventCalendar.eventCategory_  === 'comercial'
                            ? "comercial selecionado"
                            : "comercial"
                        }>
                            Comercial
                        </button>
                        <button
                        name="eventCategory_"
                        id="devs"
                        onClick={onClickeventCategory}
                        className={
                            eventCalendarUpdate &&
                            eventCalendarUpdate.eventCategory_ === 'devs'?
                            "devs selecionado"
                            :
                            eventCalendarUpdate &&
                            eventCalendarUpdate.eventCategory_ !== undefined &&
                            eventCalendarUpdate.eventCategory_ !== 'devs'?
                            "devs"
                            :
                            eventCalendar.eventCategory_  === 'devs' ?
                            "devs selecionado"
                            : "devs"
                        }>
                            Devs
                        </button>
                        <button
                        name="eventCategory_"
                        id="reuniao"
                        onClick={onClickeventCategory}
                        className={
                            eventCalendarUpdate &&
                            eventCalendarUpdate.eventCategory_ === 'reuniao' ?
                            "reuniao selecionado"
                            :
                            eventCalendarUpdate &&
                            eventCalendarUpdate.eventCategory_ !== undefined &&
                            eventCalendarUpdate.eventCategory_ !== 'reuniao'?
                            "reuniao"
                            :
                            eventCalendar.eventCategory_  === 'reuniao' ?
                            "reuniao selecionado"
                            :
                            "reuniao"
                        }>
                            Reunião
                        </button>

                    </div>

                {
                    campoPesquicaColl ?
                    <>
                    <div className="buasca-colaborador">
                        <input name="name" value={PesqCollaborator} onChange={loadFilterCollaboratorForThisEventCalendar} placeholder="Pesquisar colaborador" />

                        {
                            collaboratorFilterForThisEventCalendar &&
                            collaboratorFilterForThisEventCalendar.length !== 0 ?
                            <div className="resultado-busca-atrelados-col">
                                {
                                    collaboratorFilterForThisEventCalendar.map((collaboratorforthisevent, index) => (
                                        <button onClick={myChangeHandlerToAddCollaboratorToUpdate} id={collaboratorforthisevent._id} key={index}>
                                            {

                                            collaboratorforthisevent.avatar && 
                                            collaboratorforthisevent.avatar !== "undefined" && 
                                            collaboratorforthisevent.avatar !== undefined ?
                                                <div className="img-col">
                                                    <img id={collaboratorforthisevent._id} src={collaboratorforthisevent.avatar} alt={collaboratorforthisevent.name} />
                                                </div>
                                                :
                                                <div className="colSemFoto">
                                                    <div className="semImgCol">
                                                        <img id={collaboratorforthisevent._id} src={IconUserNone} alt="Usuario sem imagem" />
                                                    </div>
                                                </div>
                                            }
                                            <span id={collaboratorforthisevent._id}>{collaboratorforthisevent.name}</span>
                                        </button>
                                    ))
                                }
                            </div>
                            :
                            ''
                        }

                    </div>
                    </>
                    :
                    ''
                }


                <div className="col-add">
                    <div className="adicionados">
                    {
                        collaboratorEventCalendar &&
                        collaboratorEventCalendar.length !== 0 ?
                        collaboratorEventCalendar.map((collaboratorEvent, index) => (
                            collaboratorEvent.avatar && 
                            collaboratorEvent.avatar !== "undefined" && 
                            collaboratorEvent.avatar !== undefined ?
                            <div key={index} className="img-col">
                                <img src={collaboratorEvent.avatar} alt="nome" />
                            </div>
                            :
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="coll" />
                            </div>
                        ))
                        :
                        ''
                    }
                    {
                        collaboratorsToUpdate &&
                        collaboratorsToUpdate.length !== 0 ?
                        collaboratorsToUpdate.map((collaboratoradded, index) => (
                            collaboratoradded &&
                            collaboratoradded.avatar && 
                            collaboratoradded.avatar !== "undefined" && 
                            collaboratoradded.avatar !== undefined ?
                            <div key={index} className="img-col">
                                <img src={collaboratoradded.avatar} alt="nome" />
                            </div>
                            :
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="coll" />
                            </div>
                        ))
                        :
                        ''
                    }
                    </div>
                    <div className="add-button">
                        <button onClick={() => AddColl()}><img src={IconAddCol} alt="" /></button>
                    </div>
                </div>



                {       eventCalendarUpdate.startTimeEvent !== undefined || eventCalendarUpdate.endTimeEvent !== undefined ? 
                                
                                eventCalendarUpdate.startTimeEvent ===  eventCalendarUpdate.endTimeEvent  ||
                                eventCalendar.startTimeEvent === eventCalendarUpdate.endTimeEvent ||
                                eventCalendarUpdate.startTimeEvent === eventCalendar.endTimeEvent  ? 
                                <button disabled className="salvar btnGreen">Salvar evento</button>
                                : 
                                <button id={eventCalendar._id} onClick={updateEventcalendar} className="salvar btnGreen">Salvar evento</button>

                            :
                            <button id={eventCalendar._id} onClick={updateEventcalendar} className="salvar btnGreen">Salvar evento</button>
                        }
                

            </div>
        </Dropdown.Menu>
        </>
    );

}
