import React, { useState } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';

import swal from 'sweetalert';
import api from '../../../services/api';
import './styles.scss';
// import { check } from 'prettier';
export default function pageSystemConfig(props, path) {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPasswordCurrent, setLoadPasswordCurrent] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNewPassword, setLoadNewPassword] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadConfirmNewPassword, setLoadConfirmNewPassword] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonUpdatePassword, setLoadButtonUpdatePassword] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ changePassword, setChangePassword] = useState([]);


    async function myChangeHandlerChangePassword(event) {
        //event.preventDefault();
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'password') {

            if(val.length === 0) {

                setLoadPasswordCurrent(true);
                setChangePassword({ ...changePassword, [nam] : val });

            }
            else {

                setLoadPasswordCurrent(false);
                setChangePassword({ ...changePassword, [nam] : val });

            }

        }
        else
        if(nam === 'newPassword') {

            if(val.length === 0) {

                setLoadNewPassword(true);
                setChangePassword({ ...changePassword, [nam] : val });

            }
            else {

                setLoadNewPassword(false);
                setChangePassword({ ...changePassword, [nam] : val });

            }

        }
        else
        if(nam === 'confirnNewPassword') {

            if(val.length === 0) {

                setLoadConfirmNewPassword(true);
                setChangePassword({ ...changePassword, [nam] : val });

            }
            else {

                setLoadConfirmNewPassword(false);
                setChangePassword({ ...changePassword, [nam] : val });

            }

        }
        else {

        }

    }

    async function updateChangePassword() {

        if(changePassword && changePassword.length !== 0) {

            setLoadButtonUpdatePassword(true)
            await api.put('/change-password/', changePassword)
            .then(response => {
                setLoadButtonUpdatePassword(false)
                setChangePassword({});
                swal({ icon: "success", title: "Sucesso!", text: "Senha alterada com sucesso." });

            }).catch(error => {
                setLoadButtonUpdatePassword(false)
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }

    }


    // console.log('changePassword')
    // console.log(changePassword                               &&
    //     changePassword.newPassword                   &&
    //     changePassword.confirnNewPassword            &&
    //     changePassword.newPassword.length > 7        &&
    //     changePassword.confirnNewPassword.length > 7 &&
    //     changePassword.newPassword                  ===
    //     changePassword.confirnNewPassword             ?
    //     'false'
    //     :
    //     'true')


    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-alterar-senha">
                    <h2 className="titulo">Alterar senha</h2>
                    <div className="form-addEdit">
                        <h3 className="sub-titulo">Digite sua senha atual</h3>
                        <ul>
                            <li>
                                <div className="texto"><p>Senha atual</p></div>
                                <div>
                                    <input type="password" name="password" value={changePassword?.password ? changePassword?.password  : ""} onChange={myChangeHandlerChangePassword} placeholder="Senha atual"  className={loadPasswordCurrent ? 'inputErro' : ''}/>
                                    {loadPasswordCurrent ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                        </ul>
                        <h3 className="sub-titulo">Nova senha</h3>
                        <ul>
                            <li>
                                <div className="texto"><p>Nova senha</p></div>
                                <div>
                                    <input type="password" name="newPassword" value={changePassword?.newPassword ? changePassword?.newPassword  : ""}  onChange={myChangeHandlerChangePassword} placeholder="Nova senha"  className={loadNewPassword ? 'inputErro' : ''}/>
                                    {loadNewPassword ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <div className="texto"><p>Confirme a nova senha</p></div>
                                <div>
                                    <input type="password" name="confirnNewPassword"  value={changePassword?.confirnNewPassword ? changePassword?.confirnNewPassword  : ""} onChange={myChangeHandlerChangePassword} placeholder="Confirme a nova senha"  className={loadConfirmNewPassword ? 'inputErro' : ''}/>
                                    {loadConfirmNewPassword ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                        </ul>
                        <div className="btn">
                            <button disabled={
                                changePassword                               &&
                                changePassword.newPassword                   &&
                                changePassword.confirnNewPassword            &&
                                changePassword.newPassword.length > 7        &&
                                changePassword.confirnNewPassword.length > 7 &&
                                changePassword.newPassword                  ===
                                changePassword.confirnNewPassword             ?
                                false
                                :
                                true
                            }
                            onClick={updateChangePassword}
                            className={loadButtonUpdatePassword ? "btnGreen load" : "btnGreen"}>Salvar senha</button>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}
