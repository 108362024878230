import React, {useEffect, useState} from 'react';
import { Modal} from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import Close from '../../assets/images/close.svg';
import IconUserNone from '../../assets/images/iconUserNone.svg';
import Carregando from '../../assets/images/carregando.gif';
import Skeleton from 'react-loading-skeleton'
import swal from 'sweetalert';
import api from '../../services/api';

export default function ShippingToAccountant(props) {

    const [collaborator, setCollaborator] = useState([]);
    const [loadCollaborator, setLoadCollaborator] = useState(false);
    const [loadContador, setLoadContador] = useState(false);
    const [sendToAccounting, setSendToAccounting] = useState([]);
    const [loadErrorSendEmailToAccounting, setLoadErrorSendEmailToAccounting] = useState(false);
    const [accountingInformation, setAccountingInformation] = useState([]);
    const [ registeredEmergencyContact, setRegisteredEmergencyContact ] = useState([]);
    const [ registeredSchooling, setRegisteredSchooling ] = useState([]);
    const [ registeredDependents, setRegisteredDependents ] = useState([]);
    const [filesSavedLocation, setFilesSavedLocation] = useState([]);
    const [filesSavedIdentification, setFilesSavedIdentification] = useState([]);
    const [cityReservationCardString, setCityReservationCardString] = useState([]);
    const [ufReservationCard, setUfReservationCard] = useState([]);
    const [ filesCollaborator, setFilesCollaborator ] = useState([]);
   const [ collaboratorBeneftis, setCollaboratorBeneftis] = useState([]);
   const [loadSaveAndSendFinaly, setLoadSaveAndSendFinaly] = useState(false);
   let ufReservationCard_   = collaborator.ufReservationCard;
   let cityReservationCard_ = collaborator.cityReservationCard;
   const id = props.idCol;
   useEffect(() => {
        const cleanUp = () => {
            setCollaborator('');
            setLoadCollaborator(false);
            setLoadContador(false);
            setSendToAccounting([]);
            setLoadErrorSendEmailToAccounting(false);
            setAccountingInformation([]);
            setRegisteredEmergencyContact([]);
            setRegisteredSchooling([]);
            setRegisteredDependents([]);
            setFilesSavedLocation([]);
            setFilesSavedIdentification([]);
            setCityReservationCardString([]);
            setUfReservationCard([]);
            setFilesCollaborator([]);
            setCollaboratorBeneftis([]);
            setLoadSaveAndSendFinaly(false);
        };
        return cleanUp;
    }, []);

    useEffect(() => {
        if (id) {
            loadResponseCollaborator(id);
            loadFilesSavedLocation(id);
            loadFilesSavedIdentification(id);
            loadEstado(ufReservationCard_);
            loadSchooling(id);
            loadDependents(id);
            loadFilesCollaborator(id)
            loadEmergencyContacts(id);
            loadBenefitsNewDiferent(id);
            loadCity_(cityReservationCard_);
        }
    }, [id, ufReservationCard_, cityReservationCard_]);


    useEffect(() => {
        loadCity();
        loadSendToAccounting();
    }, []);

    async function loadResponseCollaborator(id) {
        if (!id) {
            return; 
        }
        try {
            setLoadCollaborator(true);
            const response = await api.get(`/collaborator/${id}`);
            setCollaborator(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadCollaborator(false);
        }
    }
    async function loadFilesSavedIdentification(id) {

        await api.get(`/collaborator-file/?id=${id}&category=identification`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setFilesSavedIdentification(response.data);
            }
        }).catch(error => {});
    }
    async function loadSchooling(id) {

        await api.get(`/collaborator-schooling/${id}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredSchooling(response.data);

            }

        }).catch(error => {})
    }
    async function loadDependents(id) {

        await api.get(`/collaborator-dependent/${id}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredDependents(response.data);

            }

        }).catch(error => {})
    }
    async function loadFilesSavedLocation(id) {

        await api.get(`/collaborator-file/?id=${id}&category=location`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setFilesSavedLocation(response.data);
            }

        }).catch(error => {});
    }
    async function loadCity() {
        setCityReservationCardString('São paulo')
    }
    async function loadCity_(cityReservationCard_) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cityReservationCard_}`)
        .then(response => {

            setCityReservationCardString(response.data[0].nome)

        }).catch(error => {});

    }
    async function loadEstado(ufReservationCard_){

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufReservationCard_}`)
        .then(response => {

            setUfReservationCard(response.data.nome)

        }).catch(error => {});

    }
    async function loadEmergencyContacts(id) {
 
         await api.get(`/collaborator-emergency-contact/${id}`)
         .then(response => {
 
             if(response.data[0] !== undefined) {
                 setRegisteredEmergencyContact(response.data);
             }
             else {
                 setRegisteredEmergencyContact([]);
             }
 
         }).catch(error => {})
 
 
    }
    async function myChangeHandlerCollaboratorRequestsSendToAccounting(e) {

        let nam = e.target.name;
        let val = e.target.value;

        if(nam === 'emailAccountig') {

            let nameAccountig  = e.target.value.split('&')[1]
            let emailAccouting = e.target.value.split('&')[0]

            if(val === 'Selecione') {
                setLoadErrorSendEmailToAccounting(true)
            }
            else {
                setLoadErrorSendEmailToAccounting(false)
                setAccountingInformation({ ...accountingInformation, nameAccountig, emailAccouting })
            }

        }
        else {

            setLoadErrorSendEmailToAccounting(false)
            setAccountingInformation({ ...accountingInformation, [nam]: val})

        }



    }
    async function loadFilesCollaborator(id) {

        await api.get(`/collaborator-file/?id=${id}&category=docCPF&category=docRG&category=docCNH&category=docCT&category=docTE&category=docCR`)
        .then(response => {
            setFilesCollaborator(response.data);
        }).catch(error => {});

    }
    async function loadBenefitsNewDiferent(id) {
        await api.get(`/beneftis-new-diferent/?collaborator=${id}&status=Ativo`)
        .then(response => {
            setCollaboratorBeneftis(response.data);
        }).catch(error => {});
    }
    async function sendAccounting(event) {

        setLoadSaveAndSendFinaly(true)

        event.preventDefault();

        let nameAccountig                 = accountingInformation.nameAccountig;
        let emailAccouting                = accountingInformation.emailAccouting;
        let accountingComments            = accountingInformation.accountingComments;

        let nameCompany                   = collaborator.companyId.name;
        let name                          = collaborator.name;

        let email                         = collaborator?.email;

        let sector                        = collaborator.sector;
        let occupation                    = collaborator.occupation;
        let salary                        = collaborator.salary;
        let costCenter                    = collaborator.costCenter;
        let admissionDate                 = collaborator.admissionDate;

        let dateOfBirth                   = collaborator.dateOfBirth;
        let gender                        = collaborator.gender;
        let fatherName                    = collaborator.fatherName;
        let motherName                    = collaborator.motherName;
        let maritalStatus                 = collaborator.maritalStatus;
        let nationality                   = collaborator.nationality;
        let otherNationality              = collaborator.otherNationality;
        let ethnicity                     = collaborator.ethnicity;
        let bornStateString               = collaborator.bornStateString;
        let bornCityString                = collaborator.bornCityString;

        let phone                         = collaborator.phone;
        let cellphone                     = collaborator.cellphone;
        let zipcode                       = collaborator.zipcode;
        let address                       = collaborator.address;
        let addressNeighborhood           = collaborator.addressNeighborhood;
        let addressCity                   = collaborator.addressCity;
        let addressState                  = collaborator.addressState;
        let addressNumber                 = collaborator.addressNumber;
        let addressComplement             = collaborator.addressComplement;

        let cpf                           = collaborator.cpf;
        let foreign                       = collaborator.foreign;

        let rg                            = collaborator.rg;
        let rgExpeditionDate              = collaborator.rgExpeditionDate;
        let rgOrgan                       = collaborator.rgOrgan;
        let ufOrgan                       = collaborator.ufOrgan;

        let cnh                           = collaborator.cnh;
        let cnhCategory                   = collaborator.cnhCategory;
        let cnhExpeditionDate             = collaborator.cnhExpeditionDate;
        let cnhExpirationDate             = collaborator.cnhExpirationDate;
        let ufCNH                         = collaborator.ufCNH;

        let pisSeries                     = collaborator.pisSeries;
        let walletNumber                  = collaborator.walletNumber;
        let serialNumber                  = collaborator.serialNumber;
        let walletUF                      = collaborator.walletUF;
        let issueDate                     = collaborator.issueDate;
        let pisNumber                     = collaborator.pisNumber;

        let reservationCardNumber         = collaborator.reservationCardNumber;
        let reservationCardSeries         = collaborator.reservationCardSeries;
        let reservationCardCategory       = collaborator.reservationCardCategory;
        let reservationCardExpeditionDate = collaborator.reservationCardExpeditionDate;

        let voterRegistration             = collaborator.voterRegistration;
        let electoralZone                 = collaborator.electoralZone;
        let pollingStation                = collaborator.pollingStation;

        let InformationProccessAdmissionAccounting = [{

            nameAccountig                ,
            emailAccouting               ,
            accountingComments           ,
            nameCompany                  ,
            name                         ,
            email                        ,
            sector                       ,
            occupation                   ,
            salary                       ,
            costCenter                   ,
            admissionDate                ,
            dateOfBirth                  ,

            gender                       ,
            fatherName                   ,
            motherName                   ,
            maritalStatus                ,
            nationality                  ,
            otherNationality             ,
            ethnicity                    ,
            bornStateString              ,
            bornCityString               ,
            filesSavedIdentification     ,

            phone                        ,
            cellphone                    ,
            zipcode                      ,
            address                      ,
            addressNeighborhood          ,
            addressCity                  ,
            addressState                 ,
            addressNumber                ,
            addressComplement            ,
            filesSavedLocation           ,

            registeredSchooling          ,
            registeredDependents         ,
            registeredEmergencyContact   ,

            cpf                          ,
            foreign                      ,

            rg                           ,
            rgExpeditionDate             ,
            rgOrgan                      ,
            ufOrgan                      ,

            cnh                          ,
            cnhCategory                  ,
            cnhExpeditionDate            ,
            cnhExpirationDate            ,
            ufCNH                        ,

            pisSeries                    ,
            walletNumber                 ,
            serialNumber                 ,
            walletUF                     ,
            issueDate                    ,
            pisNumber                    ,

            reservationCardNumber        ,
            reservationCardSeries        ,
            reservationCardCategory      ,
            reservationCardExpeditionDate,
            ufReservationCard            ,

            cityReservationCardString    ,

            voterRegistration            ,
            electoralZone                ,
            pollingStation               ,
            filesCollaborator            ,
            collaboratorBeneftis         ,


        }]

        if(
            accountingInformation?.emailAccouting               &&
            accountingInformation?.emailAccouting !== undefined
        ) {

            async function informationAdmissionProcessToAccounting() {

                if(
                    accountingInformation.emailAccouting               &&
                    accountingInformation.emailAccouting !== undefined
                ) {

                    setLoadErrorSendEmailToAccounting(false);

                    await api.post('/information-admission-process-to-accounting/', InformationProccessAdmissionAccounting)
                    .then(response => {

                        // noChooseAccountig()
                        setLoadSaveAndSendFinaly(false)
                        swal({
                            icon: "success", title: "Sucesso!",
                            text: "Informações enviadas para a contabilidade com sucesso!"
                        });
                        props.onHide();

                    }).catch(error => {

                        // noChooseAccountig()
                        setLoadSaveAndSendFinaly(false);
                        swal({
                            icon: "error", title: "Erro!", text: error?.response?.data?.message ?
                            error?.response?.data?.message
                            :
                            'Não foi possível enviar as informações para a contabilidade, por favor, tente mais tarde!'
                        });

                    });

                }
                else {
                    setLoadErrorSendEmailToAccounting(true)
                    setLoadSaveAndSendFinaly(false)
                }



            }
            informationAdmissionProcessToAccounting()


        }
        else {

            // setLoadSaveAndSendFinaly(false)
            if(
                accountingInformation.emailAccouting === undefined
            ) {
                setLoadErrorSendEmailToAccounting(true);
                setLoadSaveAndSendFinaly(false)
            }

        }



    }
    async function loadSendToAccounting() {
        try {

          setLoadContador(true);
          const response = await api.get('/accounting/');
          setSendToAccounting(response.data);

        } catch (error) {
          console.log(error)
        } finally {
          setLoadContador(false);
        }
    }
      
    return (
        <>
            <Modal show={props.show} className="popup-geral modal-accountant" onHide={props.onHide}>

                <div className="titulo">
                    <h4>Envio das informações para a contabilidade <p className="btn-fechar" onClick={props.onHide}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                
                <div className="infor-cal">

                    {   loadCollaborator ?
                            <img alt="carregando" src={Carregando} className="img-load"/>
                        :
                        collaborator?.avatar !== undefined ?
                        <>
                        <div className="img-col">
                            <img src={collaborator?.avatar} alt="nome" />
                        </div>
                        </>
                        :
                        <>
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="coll" />
                            </div>
                        </>
                    }
                    <div className="nome-setor">
                        <h5 className="nome">
                        {   
                            loadCollaborator ?
                                <Skeleton 
                                    count={1}  
                                    height={15}
                                    width={100}
                                    borderRadius={4}
                                />  
                            : collaborator?.name
                        }
                            
                        </h5>
                        <div className="setor">
                            <p className="setor">
                                {   
                                    loadCollaborator ?
                                        <Skeleton 
                                            count={1}  
                                            height={10}
                                            width={100}
                                            borderRadius={4}
                                        />  
                                    : collaborator?.occupation
                                }
                            </p>
                        </div>
                    </div>
                </div>
                
                <div className="informacoes">
                    <div className="infor">
                        {loadContador ?""
                        :
                            sendToAccounting && sendToAccounting.length !== 0 ?
                            <p>Escolha o contador abaixo para enviar a documentação a ele.</p>
                            :
                            <div>
                                <p>Não existe nenhum cadastro de contador no sistema, caso você deseje enviar automaticamente os dados do seu colaborador para o email do seu contador, por favor cadastre-o em <NavLink to="/configuracao/contadores">cadastro do contador</NavLink>.</p>
                            </div>
                        }
                    </div>
                    {
                        loadContador ?
                        ""
                        :
                        sendToAccounting && sendToAccounting.length !== 0 ?
                        <>
                            <div className="formulario">
                                <div className='display-grid'>

                                    <div className="intemA">
                                            <p>Contador</p>
                                        </div>
                                        <div className="intemB">
                                            <select
                                            name="emailAccountig"
                                            className={loadErrorSendEmailToAccounting ? "inputErro" : "" }
                                            onChange={myChangeHandlerCollaboratorRequestsSendToAccounting}
                                            >

                                                {
                                                    accountingInformation.emailAccouting &&
                                                    accountingInformation.emailAccouting !== undefined &&
                                                    accountingInformation.emailAccouting !== '' ?
                                                    <>
                                                    <option value={accountingInformation.emailAccouting+'&'+accountingInformation.nameAccountig} >{accountingInformation.nameAccountig}</option>
                                                    </>
                                                    :
                                                    sendToAccounting ?
                                                    <>
                                                        <option value="Selecione">Selecione</option>
                                                        {
                                                            sendToAccounting.map((item, index) => (
                                                                <option value={item.email+'&'+item.name} key={index}>{item.name}</option>
                                                            ))
                                                        }
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </select>
                                        </div>
                                </div>
                                <textarea
                                onChange={myChangeHandlerCollaboratorRequestsSendToAccounting}
                                name="accountingComments"
                                placeholder="Comentário opcional ao contador…"
                                ></textarea>

                            </div>
                            <div className='btns'>
                                <button
                                onClick={sendAccounting}
                                className={ loadSaveAndSendFinaly ? "enviar btnGreen load" : "enviar btnGreen"}
                                >Enviar para à contabilidade</button>
                            </div>
                        </>
                        :""
                    }
                </div>

            </Modal>
        </>
    );
}