import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import { NavLink } from 'react-router-dom'
import iconOrdenation from '../../../../assets/images/ordenation.svg';
import iconEdit from '../../../../assets/images/edit.svg';
import iconVerMais from '../../../../assets/images/more.svg';
import Close from '../../../../assets/images/close.svg';
import Logo from '../../../../assets/images/logo.svg';
import IlustracaoPopup from '../../../../assets/images/illustration-award.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import NenhumColAtivo from '../../../../assets/images/illustration-pageAtivo-vazio.svg';
import Excluir from '../../../../assets/images/delete.svg';
import { FormataStringDataToAdmission } from '../../../../services/utils';
import ModalGeral from '../../../../components/modal/modal-geral';
import ShippingToAccountant from '../../../../components/modal/ShippingToAccountant'
import api from '../../../../services/api';
// import swal from '@sweetalert/with-react'

export default function AtivosColaboradores({
    //getCollaborator,
    filterPerCollaborator,
    user,
    nextPage,
    prevPage,
    page,
    pages,
    numberPaginate,
    paginationPage,
    setSortBy,

    load,
    loadPerAlphabeticalOrder,
    loadPerMostRecent ,
    loadPerOlder,
    loadPerSkills,
    loadPerManager,
    name,
    myChangeHandlerStatusEdition,
    desligarColaborador,
    myChangeHandlerSetAll,
    myChangeHandlerToCheck,
    myChangeHandlerUpdated,
    myChangeHandlerskill,
    skillUpdate,
    allCollaborators,
    collaborators,
    checkhedAll,
    checkeds,
    checkedsaAll,
    someOneChecked,
    handleToggleVisible,
    handleToggleNoVisible,
    visible,
    saveToCheck,
    saveSkill,
    loaderErrorSkill,
    checkedSelected,
    loadButtomEditSelected,
    loadButtomSkillAtributeSelected,
    myChangeHandlerExcludeCollaborator,
    allCollaboratorsExclude,
    id_collaborator_excludeded,
    loadCheckedsaAll,
    loadCheckeds,
    checkhedNoVisible,
    chooseOrdering,
    totalCollaborators,
    role
    // idToChecked
}) {

    const [ showShippingToAccountant, setShowShippingToAccountant ] = useState(false);
    const [ idCollaboratorAccountant, setidCollaboratorAccountant ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ occupations, setOccupations ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ managers, setManagers ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ companyId, setCompanyId ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ startWelcome, setStartWelcome ] = useState(false);
    const [ isModalBoasVindas, setIsModalBoasVindas ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadoccupation();
        loadsectors();
        loadmanagers();
        loadcompany();

        async function readModal() {

            if(startWelcome && startWelcome === true) {

                setIsModalBoasVindas(true)
                // eslint-disable-next-line react-hooks/rules-of-hooks

                    async function updatemodal() {

                        let idCompany = companyId

                        await api.put(`/company-admin/${idCompany}`, { startWelcome: false })
                        .then(response => {

                        }).catch(error => {});

                    }
                    updatemodal();

            }

        }
        readModal();


    }, [companyId, startWelcome]);


    async function loadcompany() {

        await api.get('/company-admin/')
        .then(response => {

            setCompanyId(response.data._id);
            setStartWelcome(response.data.startWelcome)

        }).catch(error => {})
    }

    async function loadoccupation() {

        await api.get('/occupation')
        .then(response => {
            setOccupations(response.data);
        }).catch(error => {})
    }

    async function loadsectors() {
        await api.get('/sector/')
        .then(response => {
            setSectors(response.data);
        }).catch(error => {})
    }

    async function loadmanagers() {
        await api.get('/collaborator/?role=manager&status=Ativo')
        .then(response => {
            setManagers(response.data[0]);
        }).catch(error => {})
    }

    async function onHideShippingToAccountant() {
        setShowShippingToAccountant(false)
    }

    async function openShippingToAccountant(event) {
        setidCollaboratorAccountant(event)
        setShowShippingToAccountant(true)
    }
    return (
        <>
        <ModalGeral onClose={()=> setIsModalBoasVindas(false)} isModalBoasVindas={isModalBoasVindas}>
            <div className="conteudo-boas-vindas">
                <img src={Logo} alt="Peopleview" className="logo"/>
                <h2  className="titulo">Bem-vindo(a) ao People View!</h2>
                <p  className="infor">Uma maneira mais fácil e simples de gerenciar o RH da sua empresa.</p>
                <img src={IlustracaoPopup} alt="Peopleview"  className="ilustracao"/>
                <NavLink className="btnGreen" to={`/configuracao/dados-da-empresa/`}>
                    Preencher restante dos dados da empresa
                </NavLink>
                <button  className="fechar" onClick={()=> setIsModalBoasVindas(false)}>Preencher depois e começar a usar</button>
            </div>
        </ModalGeral>
        <div className="ativosColaboradores">
            <table>
            <thead>
                <tr className="tabelaTR">
                    {user && user.role === 'admin' && (
                        <th className="inten-1 checkbox">
                        {checkhedAll === true ? (
                            <>
                            <input type="checkbox" checked={true} onChange={myChangeHandlerSetAll} value="all" id="checkbox" />
                            <label htmlFor="checkbox"></label>
                            </>
                        ) : checkhedAll === false ? (
                            <>
                            <input type="checkbox" checked={false} onChange={myChangeHandlerSetAll} value="all" id="checkbox" />
                            <label htmlFor="checkbox"></label>
                            </>
                        ) : (
                            <>
                            <input type="checkbox" onChange={myChangeHandlerSetAll} value="all" id="checkbox" />
                            <label htmlFor="checkbox"></label>
                            </>
                        )}
                        </th>
                    )}

                    <th className="inten-2">
                        <strong>Colaborador </strong>
                        {checkhedAll === true ? (
                        <>
                            <button onClick={() => handleToggleVisible()} className="editarSelecionados">
                            <img alt="" src={iconEdit} /> Editar selecionados
                            </button>
                        </>
                        ) : someOneChecked && someOneChecked === true ? (
                        <>
                            <button onClick={() => {}} className="editarSelecionados"></button>
                        </>
                        ) : checkeds && checkeds !== undefined && checkeds.length > 1 ? (
                        <>
                            <button onClick={() => handleToggleVisible()} className="editarSelecionados">
                            <img alt="" src={iconEdit} /> Editar selecionados
                            </button>
                        </>
                        ) : (
                        <>
                            <button onClick={() => {}} className="editarSelecionados"></button>
                        </>
                        )}
                    </th>

                    <th className="inten-3">Departamento</th>
                    <th className="inten-4">Gestor</th>
                    <th className="inten-6">Data de admissão</th>
                    <th className="inten-7 popup">
                        <Dropdown className="dropdown-icon">
                        <Dropdown.Toggle>
                            <img alt="" src={iconOrdenation} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <button type="button" name="name" value={name} onClick={() => chooseOrdering('ordem_alfabetica')}>
                                Ordem alfabética
                                </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <button type="button" name="name" value={name} onClick={() => chooseOrdering('mais_recente')}>
                                Mais recentes primeiro
                                </button>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <button type="button" name="name" value={name} onClick={() => chooseOrdering('mais_antigo')}>
                                Mais antigos primeiro
                                </button>
                            </Dropdown.Item>
                            {user && user.role === 'admin' && (
                                <Dropdown.Item>
                                    <button type="button" name="name" value={name} className="porGestor" onClick={() => chooseOrdering('por_gestor')}>
                                        Por gestor
                                    </button>
                                </Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                        </Dropdown>
                    </th>
                </tr>
            </thead>
                {loadPerAlphabeticalOrder === true ? (
                    <div className="filtros-resultado"><p><span className="arrow-baixo"></span>   Ordem alfabética</p></div>
                ) : loadPerMostRecent === true ? (
                    <div className="filtros-resultado"><p><span className="arrow-baixo"></span>   Mais recentes primeiro</p></div>
                ) : loadPerOlder === true? (
                    <div className="filtros-resultado"><p><span className="arrow-baixo"></span>   Mais antigo primeiro</p></div>
                ) : loadPerSkills === true ? (
                    <div className="filtros-resultado"><p><span className="arrow-baixo"></span>   Por número de habilidades</p></div>
                ) : loadPerManager === true ? (
                    <div className="filtros-resultado"><p><span className="arrow-baixo"></span>   Por gestor</p></div>
                ) : ('')}
               <tbody>
                    {load ? (
                        <div className="carregando">
                        <img src={Carregando} alt="carregando" />
                        </div>
                    ) : filterPerCollaborator[0] === undefined ? (
                        <>
                        <div className="nenhum-colaborador-ativo">
                            {user && user.role === 'manager' ? (
                            <p>Você não possui nenhum colaborador.</p>
                            ) : (
                            <>
                                <p>Nenhum colaborador cadastrado. Comece uma nova admissão.</p>
                                <NavLink className="btnGreen more" to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/`}>
                                Nova admissão
                                </NavLink>
                            </>
                            )}
                            <img src={NenhumColAtivo} alt="Nenhum colaborador ativo" />
                        </div>
                        </>
                    ) : (
                        filterPerCollaborator.length !== 0 &&
                        filterPerCollaborator.map((filtercollaborator, index) => (
                        <tr className="tabelaTR" key={index}>
                            {user && user.role === 'admin' && (
                            <td className="inten-1 checkbox2">
                                {checkhedAll === true ? (
                                <>
                                    <input
                                    type="checkbox"
                                    name="_id"
                                    checked={true}
                                    onChange={myChangeHandlerToCheck}
                                    value={filtercollaborator._id}
                                    id={filtercollaborator._id}
                                    />
                                    <label htmlFor={filtercollaborator._id}></label>
                                </>
                                ) : checkhedAll === false ? (
                                <>
                                    <input
                                    type="checkbox"
                                    name="_id"
                                    onChange={myChangeHandlerToCheck}
                                    value={filtercollaborator._id}
                                    id={filtercollaborator._id}
                                    />
                                    <label htmlFor={filtercollaborator._id}></label>
                                </>
                                ) : (
                                <>
                                    <input
                                    type="checkbox"
                                    name="_id"
                                    onChange={myChangeHandlerToCheck}
                                    value={filtercollaborator._id}
                                    id={filtercollaborator._id}
                                    />
                                    <label htmlFor={filtercollaborator._id}></label>
                                </>
                                )}
                            </td>
                            )}
                            <td className="inten-2">
                            <NavLink to={`/colaboradores/${filtercollaborator._id}/pessoal/`}>
                                <div className="colaborador">
                                {filtercollaborator.avatar && filtercollaborator.avatar !== 'undefined' ? (
                                    <img alt="" src={filtercollaborator.avatar} className="foto-Usuario" />
                                ) : (
                                    <div className="semImgCol">
                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                    </div>
                                )}
                                <strong>{filtercollaborator.name}</strong>
                                <p>{filtercollaborator.occupation}</p>
                                </div>
                            </NavLink>
                            </td>
                            <td className="inten-3">
                            <NavLink to={`/colaboradores/${filtercollaborator._id}/pessoal/`}>{filtercollaborator.sector}</NavLink>
                            </td>
                            <td className="inten-4">
                            <NavLink to={`/colaboradores/${filtercollaborator._id}/pessoal/`}>
                                {filtercollaborator.nameManager ? filtercollaborator.nameManager : '-'}
                            </NavLink>
                            </td>
                            <td className="inten-6">
                            <NavLink to={`/colaboradores/${filtercollaborator._id}/pessoal/`}>
                                {filtercollaborator.admissionDate ? FormataStringDataToAdmission(filtercollaborator.admissionDate) : '-'}
                            </NavLink>
                            </td>
                            <td className="inten-7">
                            <Dropdown className="dropdown-icon dropdown-ver-mais">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                <NavLink to={`/colaboradores/${filtercollaborator._id}/pessoal/`}>Ver perfil</NavLink>
                                <button onClick={() => openShippingToAccountant(filtercollaborator._id)}>Enviar para contabilidade</button>
                                {user && user.role === 'admin' && (
                                    <form onSubmit={desligarColaborador}>
                                    <button onClick={myChangeHandlerStatusEdition} name="_id" value={filtercollaborator._id} type="submit" className="red">
                                        Desligar colaborador
                                    </button>
                                    </form>
                                )}
                                </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        ))
                    )}
                    </tbody>
                {
                pages === 0 ? '' :
                    <>
                    <div className="paginacaoG">
                        <ul >
                            {
                                numberPaginate
                            }
                            {
                                pages && pages > 0 ?
                                <>
                                <li className="page-anterior">
                                    <button style={(page === 0) ? { cursor: "not-allowed" } : {}}  disabled={page === 0} onClick={() => prevPage(page)} >anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(page === pages) ? { cursor: "not-allowed" } : {}}  disabled={page === pages} onClick={() => nextPage(page)} >próxima</button>
                                </li>
                                </>
                                :
                                ''
                            }
                        </ul>
                    </div>
                    </>
                }
            </table>
        </div>
        <Modal show={visible} className="popup-editar-selecionados" onHide={handleToggleNoVisible}>
            <div>
                <div className="titulo">
                    <h4>Editar selecionados<p className="btn-fechar" onClick={handleToggleNoVisible}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className="form-troca">
                    <div className="infor">
                        <label>Trocar de cargo</label>
                        <select onChange={myChangeHandlerUpdated} name="occupation" className="">
                        <option >Selecionar</option>
                        {
                        occupations.map((occupation, index) => (
                            <option key={index} value={occupation.name}>{occupation.name}</option>
                        ))
                        }
                        </select>

                    </div>
                    <div className="infor">
                        <label>Mover de setor</label>
                        <select onChange={myChangeHandlerUpdated} name="sector" className="">
                        <option>Selecionar</option>
                        {
                        sectors.map((sector, index) => (
                            <option key={index} value={sector.name}>{sector.name}</option>
                        ))
                        }
                        </select>
                    </div>
                    <div className="infor">
                        <label>Trocar de gestor</label>
                        <select onChange={myChangeHandlerUpdated} name="manager" className="">
                        <option>Selecionar</option>
                        {
                        managers.map((manager, index) => (
                            <option key={index} value={manager._id}>{manager.name}</option>
                        ))
                        }
                        </select>
                    </div>
                    <div className="infor">
                        <label>Atribuir habilidade</label>
                        <div className='habilidade'>
                            <div>

                                <input
                                    type="text"
                                    onChange={myChangeHandlerskill}
                                    name="name"
                                    value={skillUpdate && skillUpdate.name !== undefined ? skillUpdate.name : ''}
                                    placeholder="Nome da skill"
                                    className={loaderErrorSkill ? 'inputErro' : ''}
                                />
                                {loaderErrorSkill ?
                                <p className="textError">O campo é obrigatório. </p>
                                : ''}

                            </div>
                        <button
                        onClick={saveSkill}
                        className={loadButtomSkillAtributeSelected ? "btnGreen load" : "btnGreen"}
                        disabled={
                            allCollaborators && allCollaborators.length !== undefined && allCollaborators.length !== 0 ?
                            false
                            :
                            collaborators && collaborators.length !== undefined && collaborators.length !== 0 ?
                            false
                            :
                            true
                        }
                        >
                            Atribuir
                            </button>
                        </div>
                    </div>
                </div>
                <div className="selecionados">
                    <p className="quantidade">

                    </p>
                    <p className="quantidade">
                        {
                            loadCheckedsaAll ?
                            <><span><img src={Carregando} alt="carregando" /></span>  colaboradores</>
                            :
                            allCollaborators && allCollaborators.length !== undefined && allCollaborators.length !== 0 ?
                            <>{allCollaborators.length} colaboradores</>
                            :
                            loadCheckeds ?
                            <><span><img src={Carregando} alt="carregando" /></span>  colaboradores</>
                            :
                            collaborators && collaborators.length !== undefined && collaborators.length === 1 ?
                            <>{collaborators.length} colaborador</>
                            :
                            loadCheckeds ?
                            <><span><img src={Carregando} alt="carregando" /></span>  colaboradores</>
                            :
                            collaborators && collaborators.length !== undefined && collaborators.length > 1 ?
                            <>{collaborators.length} colaboradores</>
                            :
                            'nenhum colaborador selecionado'
                        }
                    </p>
                    <ul>


                    {
                        loadCheckedsaAll ?
                        <div className="carregamento">
                            <img src={Carregando} alt="carregando" />
                        </div>
                        :
                        allCollaborators.length !== 0 ?
                        allCollaborators
                        .filter(filtercollaborator =>
                            filtercollaborator._id !== allCollaboratorsExclude
                            )
                        .map((collaborator, index) => (
                            <li key={index}>
                                <div className="colaborador">
                                {
                                    collaborator.avatar && collaborator.avatar !== 'undefined' ?
                                    <div className="foto">
                                        <img alt="" src={collaborator.avatar}/>
                                    </div>
                                    :
                                    <div className="semImgCol">
                                        <img  src={IconUserNone} alt="Usuario sem imagem" />
                                    </div>
                                }

                                    <strong>{collaborator.name}</strong>
                                    <p>{collaborator.occupation}</p>
                                </div>
                                <button onClick={myChangeHandlerExcludeCollaborator} value={collaborator._id} className="btn-excluir"><img src={Excluir} alt="Excluir" />Remover</button>
                            </li>
                        ))
                        :
                        loadCheckeds ?
                        <div className="carregamento">
                            <img src={Carregando} alt="carregando" />
                        </div>
                        :
                        collaborators && collaborators.length !== 0 ?
                        collaborators
                        .filter(filtercollaborator =>
                            filtercollaborator._id !== allCollaboratorsExclude
                            )
                        .map((collaborator, index) => (
                            <li key={index}>
                                <div className="colaborador">
                                    {/* <div className="foto">
                                        <img alt="" src={collaborator.avatar ? collaborator.avatar : collaborator.companyId.logo}/>
                                    </div> */}
                                    {
                                    collaborator.avatar && collaborator.avatar !== 'undefined' ?
                                    <div className="foto">
                                        <img alt="" src={collaborator.avatar}/>
                                    </div>
                                    :
                                    <div className="selecSemFoto">
                                        <div className="semImgCol">
                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                        </div>
                                    </div>
                                }

                                    <strong>{collaborator.name}</strong>
                                    <p>{collaborator.occupation}</p>
                                </div>
                                <button onClick={myChangeHandlerExcludeCollaborator} value={collaborator._id} className="btn-excluir"><img src={Excluir} alt="Excluir" />Remover</button>
                            </li>
                        ))
                        : ''
                    }
                    </ul>
                </div>
                <button
                onClick={saveToCheck}
                className={loadButtomEditSelected ? "btnGreen load btn-config" : "btnGreen btn-config"}
                disabled={
                    allCollaborators && allCollaborators.length !== undefined && allCollaborators.length !== 0 ?
                    false
                    :
                    collaborators && collaborators.length !== undefined && collaborators.length !== 0 ?
                    false
                    :
                    true
                }
                >
                    Salvar
                </button>
            </div>
        </Modal>

        <ShippingToAccountant 
            show={showShippingToAccountant}
            onHide={onHideShippingToAccountant}
            idCol={idCollaboratorAccountant}
        />
        </>
    );
}
