import React, {useState, useEffect} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import { Scrollbars } from 'react-custom-scrollbars';
import IconEditar from '../../assets/images/edit-black.svg';
import api from '../../services/api';
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify';
import { formatDateString } from '../../services/utils';
import {cep} from '../../services/mask';
import './styles.scss';
import swal from 'sweetalert';


export default function CandidateProfile(props) {
    const { path } = props.match;
    const { pathname } = props.location;
    const { id } = props.match.params;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCandidatesInfor, setLoadCandidatesInfor ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidates, setCandidates ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFolder, setLoadFolder ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadsCandidates(id);
        loadAllFolderAtivos();
    }, [id]);

    async function loadsCandidates(id) {
        setLoadCandidatesInfor(true)
        await api.get(`/candidate/${id}`)
        .then(response => {
            setLoadCandidatesInfor(false)
            setCandidates(response.data);
        }).catch(error=>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const [idFolder, setIdFolder ] = useState("");
    const [nameFolder, setNameFolder ] = useState("");
    const [idCandidate, setIdCandidate ] = useState("");
    const [nameCandidate, setNameCandidate ] = useState("");


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaFolder, setIsActiveSelectBuscaFolder] = useState(false);
    async function SelectBuscaFolder() { setIsActiveSelectBuscaFolder(!isActiveSelectBuscaFolder) }

    const myChangeHandlerSelects = event => {
        let values        = event.split('&&')
        setIdFolder(values[0]);
        setNameFolder(values[1]);
        setIdCandidate(values[2]);
        setNameCandidate(values[3]);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ folderAtivos, setFolderAtivos] = useState([]);

    async function loadAllFolderAtivos() {
        try {
            setLoadFolder(true)
            const response =  await api.get('/folder-all-count/?status=active');
            setFolderAtivos(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        } finally { setLoadFolder(false) }
    }
    async function moveAndEditCandidateToFolder() {

        if(idFolder && nameFolder && idCandidate && nameCandidate) {
            swal({
                title: "Atenção",
                text: `Deseja realmente alterar o(a) candidato(a) ${nameCandidate} para à pasta ${nameFolder}?`,
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {
    
                    await api.put(`/candidate/${idCandidate}`, { "folder": idFolder })
                    .then(response => {
                        loadsCandidates(id)

                        setIdFolder("");
                        setTimeout(function(){setNameFolder("");}, 1000);
                        setIdCandidate("");
                        setNameCandidate("");
                        swal({ icon: "success", title: "Sucesso!", text: "Candidato(a) movido com sucesso!" });
    
                    }).catch(error => {
                        if(error?.response?.data?.message) {
                            swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                        }
                        else {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao mover candidato(a) de pasta de talentos, tente novamente mais tarde." });
                        }
                    });
    
                }
    
            })
        } else (
        swal({ icon: "error", title: "Erro!", text: "Pasta não foi selecionada!\nPor favor, faça uma nova escolha!" })
        )
    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                    path={path}
                    pathname={pathname}
                    candidates={candidates}
                    loadCandidatesInfor={loadCandidatesInfor}
                />
                <div className='page-talents candidate-profile'>

                    <div className='box-infor'>
                        <h2><span>{
                        loadCandidatesInfor ?
                        <Skeleton  
                        count={1}  
                        height={20}
                        width={100}
                        borderRadius={9}
                        /> :
                        candidates && candidates?.name}</span>
                        
                            <a href={`/recrutamento/editar-candidato/${id}`}><img src={IconEditar} alt="editar" /> Editar</a>
                        </h2>
                        <p className='sub-titulo'>{
                        loadCandidatesInfor ?
                        <Skeleton  
                        count={1}  
                        height={15}
                        width={60}
                        borderRadius={9}
                        /> :
                        
                        candidates && candidates.sector !== undefined ? candidates.sector.name : ""}</p>
                        <ul>
                            <li>
                                <p>Data de nascimento</p>
                                <span>{
                                loadCandidatesInfor ?
                                <Skeleton  
                                count={1}  
                                height={15}
                                width={60}
                                borderRadius={9}
                                /> :
                                candidates &&  candidates !== undefined ? formatDateString(candidates?.dateOfBirth) : ""}</span>
                            </li>
                            <li>
                                <p>Setor desejado</p>
                                <span>{
                                loadCandidatesInfor ?
                                <Skeleton  
                                count={1}  
                                height={15}
                                width={60}
                                borderRadius={9}
                                /> :candidates && candidates.sector !== undefined ? candidates.sector.name : ""}</span>
                            </li>
                            <li>
                                <p>Telefone</p>
                                <span>{
                                loadCandidatesInfor ?
                                <Skeleton  
                                count={1}  
                                height={15}
                                width={60}
                                borderRadius={9}
                                /> :candidates && candidates.phone !== undefined ? candidates.phone : ""}</span>
                            </li>
                            <li>
                                <p>Regime de contratação</p>
                                <span>
                                    {
                                    loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                    /> :
                                    candidates && candidates.laborBond !== undefined ? 
                                    candidates.laborBond === "PJ"  ? 
                                    "Pessoa Jurídica" : candidates.laborBond 
                                    : ""
                                    }
                                </span>
                            </li>
                            <li>
                                <p>Email</p>
                                <span>{
                                loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                /> :candidates && candidates.email !== undefined ? candidates.email : ""}</span>
                            </li>
                            <li>
                                <p>Currículo</p>
                                {
                                    loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                    /> :
                                    candidates && candidates.curriculum !== undefined ?
                                    <><a  target="’_blank’" href={candidates && candidates.curriculum}>Ver currículo</a></>
                                    : <span>Não cadastrado</span>
                                }
                            </li>
                            <li>
                                <p>Endereço</p>
                                <span>
                                    {
                                loadCandidatesInfor ?
                                <Skeleton  
                                count={1}  
                                height={15}
                                width={60}
                                borderRadius={9}
                                /> :candidates && candidates.address !== undefined ? candidates.address : "" }{loadCandidatesInfor ?"":","} {candidates && candidates.addressNumber !== undefined ? candidates.addressNumber : "" } {loadCandidatesInfor ?"":"-"} {candidates && candidates.addressNeighborhood !== undefined ? candidates.addressNeighborhood : "" }
                                </span>
                            </li>
                            <li>
                                <p>Portfolio</p>
                                {
                                    loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                    /> :
                                    candidates && candidates.portfolio !== undefined ?
                                    <><a  target="’_blank’" href={candidates && candidates.portfolio}>Ver portfolio</a></>: <span>Não cadastrado</span>
                                }
                            </li>
                            <li>
                                <p>Cidade/Estado</p>
                                <span>
                                    {
                                loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                /> :candidates && candidates.addressCity !== undefined ? candidates.addressCity : "" }{loadCandidatesInfor ?"":"/"}
                                    {
                                loadCandidatesInfor ?"" : candidates && candidates.addressState !== undefined ? candidates.addressState : "" }
                                </span>
                            </li>
                            <li>
                                <p>LinkedIn</p>
                                {
                                    loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                    /> :
                                    candidates && candidates.linkedin !== undefined ?
                                    <><a  target="’_blank’" href={candidates && candidates.linkedin}>Ver Linkedin</a></>: <span>Não cadastrado</span>
                                }
                            </li>
                            <li>
                                <p>CEP</p>
                                <span>
                                    {
                                    loadCandidatesInfor ?
                                    <Skeleton  
                                    count={1}  
                                    height={15}
                                    width={60}
                                    borderRadius={9}
                                    /> :
                                    candidates && candidates.zipcode !== undefined ? cep(candidates.zipcode) : "Não cadastrado" }
                                </span>
                            </li>
                            <li>
                                <p>Facebook</p>
                                {
                                loadCandidatesInfor ?
                                <Skeleton  
                                count={1}  
                                height={15}
                                width={60}
                                borderRadius={9}
                                /> :
                                    candidates && candidates.facebook !== undefined ?
                                    <><a  target="’_blank’" href={candidates && candidates.facebook}>Ver Linkedin</a></>: <span>Não cadastrado</span>
                                }
                            </li>
                        </ul>
                        <div className='mover-para-folder'>
                            <p className='titulo-box'>Mover para uma pasta</p>
                            <div className='form-folde'>

                                <div className="select-busca-btn">
                                    <input
                                        type="button" 
                                        className="menu-button"
                                        placeholder='Selecione'
                                        name="nomeFolder"
                                        value={
                                            nameFolder ? nameFolder :
                                            candidates?.folder?.name ?
                                            candidates?.folder?.name
                                            : "Selecione uma pasta"
                                        }
                                        onClick={ SelectBuscaFolder }
                                        onBlur={ () => (setIsActiveSelectBuscaFolder(false)) }
                                    />
                                    <nav className={`menu ${isActiveSelectBuscaFolder ? "ativo": ""}`} >
                                        <Scrollbars className='listDropDown'  style={{  height:
                                        folderAtivos.length === 0 ? 30 : "" ||
                                        folderAtivos.length === 1 ? 30 : ""  ||
                                        folderAtivos.length === 2 ? 70 : ""  ||
                                        folderAtivos.length === 3 ? 120 : 120
                                        }}>
                                            <ul>
                                                {
                                                    loadFolder ?
                                                    <Skeleton  
                                                        count={1}  
                                                        height={15}
                                                        width={70}
                                                        borderRadius={9}
                                                    />  
                                                    :
                                                    folderAtivos.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    folderAtivos
                                                    .map((folder, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={folder._id+'&&'+
                                                            folder.name+
                                                            '&&'+candidates._id+
                                                            '&&'+candidates.name}
                                                            onClick={()=>myChangeHandlerSelects(folder._id+'&&'+
                                                            folder.name+
                                                            '&&'+candidates._id+
                                                            '&&'+candidates.name)}
                                                            name="folder"
                                                            >{folder.name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                <button className='btnGreen btn-mover' onClick={() => moveAndEditCandidateToFolder()}>Mover para pasta</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

