import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../../../components/Layouts/default';
import { Modal} from "react-bootstrap";
import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Close from '../../../../assets/images/close.svg';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import swal from 'sweetalert';
import api from '../../../../services/api';
import { decodeToken } from '../../../../services/auth';
import { mCPF } from '../../../../services/mask';
import { toast } from 'react-toastify';

export default function ColProfilePersonal(props) {

    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ editableData, setEditableData ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

       if(id && id) {loadcollaborator(id);}

    }, [id]);

    async function loadcollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {

            setCollaborator(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('path', props.match.path);
            //formData.append('collaborator', id);
            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {

                setLoadAvatar(false);
                loadcollaborator(id)

                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleAcessData, setVisibleAcessData] = useState(false);
    // console.log(visibleAcessData)

    function openVisibleAcessData(e) {

        if(e?.email && e?.cpf) {
            setEditableData({ email: e?.email, cpf: e?.cpf })
        }
        else
        if(e?.email) {
            setEditableData({ email: e?.email })
        }
        else
        if(e?.cpf) {
            setEditableData({ cpf: e?.cpf })
        }

        setVisibleAcessData(true);
    }

    function hideVisibleAcessData() {

        setEditableData([])
        loadcollaborator(id);
        setVisibleAcessData(false);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCpf, setLoadCpf ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmail, setLoadEmail ] = useState(false);


    async function myChangeHandler(event) {

        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'email'){

            if(val.length === 0) {
                setLoadEmail(true)
            }
            else {
                setLoadEmail(false)
            }

            setCollaborator({ ...collaborator, [nam]: val })
            setEditableData({ ...editableData, [nam]: val })

        }
        else
        if(nam === 'cpf'){

            if(val.length <= 13) {
                setLoadCpf(true)
                let cpf = mCPF(val)
                setCollaborator({ ...collaborator, [nam]: cpf })
                setEditableData({ ...editableData, [nam]: cpf })
            }
            else {

                let cpf = mCPF(val)
                setCollaborator({ ...collaborator, [nam]: cpf })
                setEditableData({ ...editableData, [nam]: cpf })
                setLoadCpf(false)

            }


        }
        else {

            setCollaborator({ ...collaborator, [nam]: val })
        }

    }




    async function saveAccessData() {

        if(
            collaborator?.email?.length === 0
            ||
            collaborator?.cpf?.length   === 0
        ) {

            if(
                collaborator?.email?.length === 0
            ) {
                setLoadEmail(true)
            }

            if(
               collaborator?.cpf?.length   < 13
            ) {
                setLoadCpf(true)
            }

            return

        }
        else {

            setLoadContinue(true)
            await api.put(`/collaborator/${collaborator._id}`, editableData)
            .then(response => {

                setLoadContinue(false)
                setVisibleAcessData(false);
                swal({ icon: "success", title: "Sucesso!", text: "Informações de acesso ao sistema salvas com sucesso!" });

            }).catch(error => {
                setLoadContinue(false)
                console.log(error?.response?.data?.message)
                swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message ? error?.response?.data?.message : "Erro ao salvar as informações de acesso ao sistema, tente novamente mais tarde." });
            });

        }

    }


    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                id={id}
                collaborator={collaborator}
                />
                <InforProfile
                    collaborator={collaborator}
                    path={props.match.path}
                    id={id}
                    loadAvatar={loadAvatar}
                    handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                    role={role}
                    loadcollaborator={loadcollaborator}
                />
                <SubHeader
                id={id}
                path={props.match.path}
                collaborator={collaborator}
                role={role}
                />
                <div className="box-infor-perfil box-acess-data">

                    <div className="box">
                        <h3>Informações de acesso ao sistema <button className="edit-Benefit" onClick={()=>openVisibleAcessData(collaborator)} ><img src={EditBlack} alt="Edite Informações" /></button>  </h3>

                        <ul>

                            <li>
                                <div className="intemA">
                                    <p>Email de acesso</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator?.email ? collaborator?.email : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Cpf *</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator?.cpf ? collaborator?.cpf : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Senha temporaria *</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator?.temporaryPassword ? collaborator?.temporaryPassword : '-'}</p>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>

                <Modal show={visibleAcessData} className="popup-edit-perfil popup-editProfile-pessonalData" onHide={()=>hideVisibleAcessData()}>
                    <div>
                        <div className="titulo">
                            <h4>Informações de acesso ao sistema  <p className="btn-fechar" onClick={()=>hideVisibleAcessData()}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Email de acesso</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="email"
                                        name="email"
                                        autoComplete="off"
                                        placeholder="E-mail"
                                        value={
                                            collaborator?.email || collaborator?.email === '' ?
                                            collaborator?.email
                                            :
                                            ''
                                        }
                                        className={loadEmail ? 'inputErro' : ''}
                                        onChange={myChangeHandler}
                                    />
                                    {loadEmail ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Cpf *</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="cpf"
                                        placeholder="Cpf"
                                        className={loadCpf ? 'inputErro' : ''}
                                        onChange={myChangeHandler}
                                        value={
                                            collaborator?.cpf || collaborator?.cpf === '' ?
                                            collaborator?.cpf
                                            :
                                            ''
                                        }
                                    />
                                    {loadCpf ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </li>


                            <li>
                                <div className="intemA btn">
                                </div>
                                <div className="intemB">
                                    <div className="btn-salvar">
                                        <button onClick={()=>saveAccessData()} type="button" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                    </div>
                                </div>
                            </li>
                        </ul>


                    </div>
                </Modal>

            </Layout>
        </>
    );
}
