import React, { useEffect, useState } from 'react';
import SidebarUser from '../../SidebarUser';
import HeaderG from '../../HeaderUser/headerG';
import logo from '../../../assets/images/logo-user.svg';
import iconMenu from '../../../assets/images/icon-menu-mobile.svg';
// import api from '../../../services/api';
import './styles.scss';
import { decodeToken } from '../../../services/auth';
import IconArrowSidbarOpen from '../../../assets/images/icon-arrow-sidebarOpen.svg';
import IconArrowSidbarClosed from '../../../assets/images/icon-arrow-sidebarClosed.svg';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

export default function DefaultLayout(props) {

    const { user, exp } = decodeToken();

    const path = props.path;
    const role = user.role;

    const startOfTrial  = user.companyId?.startOfTrial;
    const statusCompany = user.companyId?.status;

    var expiration       = exp * 1000;
    var dateExp          = moment(expiration)._d
    var currentDate      = moment()._d;
    var difDate          = moment(currentDate).diff(moment(dateExp,"days"));
    var difDatetransform = moment.duration(difDate);
    var secondsExpirade  = difDatetransform._data.seconds;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        // loadSignatureTransactions();
        statusCompanyTrial(startOfTrial, statusCompany, secondsExpirade)

        if(role === 'admin' || role === 'master') {

            window.location.href = '/dashboard';

        }
        else
        if(role === 'manager') {

            window.location.href = '/colaboradores/ativos';

        }
        else {}

    }, [role, user, startOfTrial, statusCompany, secondsExpirade]);


    async function statusCompanyTrial(startOfTrial, statusCompany, secondsExpirade) {

        if(statusCompany === 'Trial') {

            let createdAtCompanny_  = new Date(startOfTrial);
            let createdAtCompanny   = createdAtCompanny_.toISOString().split('T')[0]
            let createdAtCompanny_a = createdAtCompanny.split('-');
            let createdAtCompanny_b = new Date(createdAtCompanny_a[0] + "-" + createdAtCompanny_a[1] + "-" + createdAtCompanny_a[2]);

            let currentDate_ = new Date();

            let currentDate   = currentDate_.toISOString().split('T')[0]
            let currentDate_a = currentDate.split('-');
            let currentDate_b = new Date(currentDate_a[0] + "-" + currentDate_a[1] + "-" + currentDate_a[2]);

            let totalTrialDaysCompany = moment(currentDate_b).diff(moment(createdAtCompanny_b,"days"));
            let durationTotalTrialDaysCompany = moment.duration(totalTrialDaysCompany);

            // console.log('Trial')
            // console.log(durationTotalTrialDaysCompany._data);
            if(durationTotalTrialDaysCompany._data.days !== undefined) {
                var days   = durationTotalTrialDaysCompany._data.days;
            }
            if(durationTotalTrialDaysCompany._data.months !== undefined) {
                var months = durationTotalTrialDaysCompany._data.months;
            }
            if(durationTotalTrialDaysCompany._data.years !== undefined) {
                var years  = durationTotalTrialDaysCompany._data.years;
            }

       



            if(days > 9 || months > 0 || years > 0) {

                // console.log('secondsExpirade')
                // console.log(secondsExpirade)

                if(secondsExpirade > 0) {

                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }

                console.log('redireciona para plano e cobrança!')
                window.location.href = '/configuracao/plano-e-cobranca';

                // setHideSidebar(true)

            }
            else {

                // console.log('secondsExpirade')
                // console.log(secondsExpirade)

                if(secondsExpirade > 0) {

                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }


            }

        }
        else {

            // console.log('secondsExpirade')
            // console.log(secondsExpirade)

            if(secondsExpirade > 0) {

                localStorage.clear();
                setTimeout(function(){window.location.href = '/';}, 1000);

            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sideBarShow, setSideBarShow ] = useState(false);

    async function fecharSidBarAbrir() {
        setSideBarShow(!sideBarShow)
    }

    return (
        <>
        {
            user           &&
            user.companyId &&
            user.companyId.status === 'Inativo' ?
            window.location.href = '/conta-suspensa-colaborador'
            :
            <div className="layaut-container-user mobile">
                <aside
                className={  sideBarShow ? 'sidebar ativo' : 'sidebar'  }
                >
                    <SidebarUser fecharSidBarAbrir={fecharSidBarAbrir} path={path}/>
                </aside>
                <div className="conteudo">

                <div className='btn-menu-responsivo'>
                    <button className={  sideBarShow ? 'btn-closed-sidebar' : 'btn-open-sidebar'  }
                        onClick={  fecharSidBarAbrir } >
                        <img src={ sideBarShow ?  IconArrowSidbarClosed :  IconArrowSidbarOpen} alt="" />
                    </button>

                </div>

                    <div className="btn-menu-mobile">
                        <button className='menu' onClick={fecharSidBarAbrir}>
                            <img src={iconMenu} alt="menu" />
                        </button>
                        <NavLink to="/dashboard" className="logo">
                            <img src={logo} alt="peopleview" />
                        </NavLink>
                    </div>
                    <div className="conteudo-center">
                        <HeaderG  path={path}/>
                        {props.children}
                    </div>
                </div>
            </div>
        }

        </>
    );

}
