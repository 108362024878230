import React, { useState, useEffect } from 'react';
import Layout from '../../../../../components/Layouts/default';
import { toast } from 'react-toastify';
import api from '../../../../../services/api'
import Header from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/header';
import DesligamentoSteps from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/headerDesligamentoSteps';
import StepThre from '../../../../../components/Administrativo/Colaboradores/Desligamento/novo-desligamento/StepThree';
import '../style.scss';

import { decodeToken } from '../../../../../services/auth';

export default function Finish(props) {

    const { id } = props.match.params;
    ////////////// NOTIFICATIONS ////////////
    const { history } = props;
    const { user } = decodeToken();
    const [ stepOne ]   = useState(false);
    const [ stepTwo ]   = useState(false);
    const [ stepThree ] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorShutdownProcess, setCollaboratorShutdownProcess ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadcollabotorshutdown(id);
            loadcollaboratordismissal(id);
        }
    }, [id]);
    async function loadcollabotorshutdown(id) {
        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaboratorShutdownProcess(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorShutdownSaved, setCollaboratorShutdownSaved ] = useState([]);
    async function loadcollaboratordismissal(id) {
        await api.get(`/collaborator-dismissal/${id}`)
        .then(response => {
            setCollaboratorShutdownSaved(response.data[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }
    const nameprofile               = localStorage.getItem('@peopleview/nameProfile');

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                collaboratorShutdownProcess={collaboratorShutdownProcess}
                nameprofile={nameprofile}
                history={history}
                user={user}
                />
                <DesligamentoSteps
                path={props.match.path}
                id={id}
                stepOne={stepOne}
                stepTwo={stepTwo}
                stepThree={stepThree}
                collaboratorShutdownSaved={collaboratorShutdownSaved}
                />
                <StepThre id={id}/>
            </Layout>
        </>
    );
}

