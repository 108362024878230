import React,{useState, useEffect, useRef} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import { Scrollbars } from 'react-custom-scrollbars';
import Dropzone from 'react-dropzone';
import SelectPersonalizado from '../../components/Reused/selectPersonalizado'
import {removerSpecials} from '../../services/utils'
import { NavLink } from 'react-router-dom'
import api from '../../services/api'
import swal from 'sweetalert';
import { toast } from 'react-toastify';

import TextEditor from '../../components/Reused/TextEditor'

import './styles.scss';

export default function RegisterVacancy(props) {

    const { path } = props.match;
    const { id } = props.match.params;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacancieUpdate , setVacancieUpdate] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacancie , setVacancie] = useState([]);
    const ufDistrito =  vacancieUpdate && vacancieUpdate.bornState ? vacancieUpdate.bornState : vacancie && vacancie.bornState ? vacancie.bornState :'';
    const ufCity = vacancie && vacancie.bornCity ? vacancie.bornCity : vacancieUpdate && vacancieUpdate.bornCity ? vacancieUpdate.bornCity : '';
  

  // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(ufDistrito !== undefined) {
            loaduf(ufDistrito);
            if(ufDistrito === '35') {
                loadcities_(ufDistrito);
            }
            else {
                loadcities(ufDistrito);
            }
        }

        if(ufCity !== undefined) {
            loadcity(ufCity);
        }

        loadufestados();
        loadsectors();
        if(id){
            loadVacancieRegisterid(id);
        }

    }, [ufDistrito, ufCity, id]);

    async function loadVacancieRegisterid(id) {

        await api.get(`/vacancie/${id}`)
        .then(response => {

            setVacancie(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhido, setUEscolhido ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ citys, setCitys ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ cidade, setCidade ] = useState('');

    async function loadcities(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
        .then(response => {

            setCitys(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loaduf(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
        .then(response => {

            setUEscolhido(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcities_(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
        .then(response => {

            let itensCopyCitys = Array.from(response.data);

            itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });

            itensCopyCitys.sort(function (a, b) {

                if (a.nome > b.nome) {
                  return 1;
                }
                if (a.nome < b.nome) {
                  return -1;
                }

                return 0;
            });

            setCitys(itensCopyCitys);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcity(ufCity) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
        .then(response => {

            setCidade(response.data[0]);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEstados, setUfEstados ] = useState([]);

    async function loadufestados() {

        await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
        .then(response => {
            setUfEstados(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }


    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA BorBond //////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectLaborBond, setIsActiveSelectLaborBond] = useState(false);
    async function SelectLaborBond() { setIsActiveSelectLaborBond(!isActiveSelectLaborBond) }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA TYPE //////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectType, setIsActiveSelectType] = useState(false);

    async function SelectType() { setIsActiveSelectType(!isActiveSelectType) }


    /////////////////////////////////////////////
    ///////////////// DROPZONE /////////////////
    ///////////////////////////////////////////

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            let file     = acceptedFiles[0];
            let nameFile = acceptedFiles[0].name;

            // setFileNames(acceptedFiles.map(file => file.name));
            setVacancieUpdate({ ...vacancieUpdate, file, nameFile })

        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    function removeAttachment(){

        let file     = null;
        let nameFile = null;
        setVacancieUpdate({ ...vacancieUpdate, file, nameFile })

        let originalname = null;

        setVacancie({ ...vacancie, originalname });

    }

    const myChangeHandler = event =>{

        let nam = event.target.name;
        let val = event.target.value;

        if(nam === "title") {
            if(val.length >= 1) {

               
            setLoadTitle(false)

            }
            else {
                
            setLoadTitle(true)
            }
        }
        setVacancieUpdate({ ...vacancieUpdate, [nam]: val})
    }

    /////////////////////////////////////////////
    ////////////// Editor de testo //////////////
    ///////////////////////////////////////////

    const myChangeHandlerDescription = (e, editor ) => {
            let description = editor.getData();
            setVacancieUpdate({ ...vacancieUpdate, "description": description})
    }
    const myChangeHandlerResponsibilities = (e, editor ) => {

        let responsibilities = editor.getData()
        setVacancieUpdate({ ...vacancieUpdate, "responsibilities": responsibilities})
    }
    const myChangeHandlerskills = (e, editor ) => {

        let skills = editor.getData()
        setVacancieUpdate({ ...vacancieUpdate, "skills": skills})
    }

   /////////////////////////////////////////////
    ////////////// Estado e cidades //////////////
    ///////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [BuscaLoadOptionBornstate , setBuscaLoadOptionBornstate] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [BuscaLoadOptionCity , setBuscaLoadOptionCity] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBusca , setNationalityBusca] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBuscaString , setNationalityBuscaString] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === "bornStateBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
    
        }
        else
        if(nam === "bornState") {
            setBuscaLoadOptionBornstate(true)
            let estado = val;
    
            async function estados() {
    
                if(estado !== undefined) {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
                    .then(response => {
                        setBuscaLoadOptionBornstate(false)
                        let bornStateString = response.data.nome;
                        setVacancieUpdate({ ...vacancieUpdate, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": ""   });
                        setVacancie({ ...vacancie, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": "" });
                        setNationalityBuscaString({ ...nationalityBuscaString,  "bornStateBusca": bornStateString,"bornCityBusca": "" });
    
                    }).catch(error => {});
    
                }
            }
            estados();
        }
        else
        if(nam === "bornCityBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        } else
        if(nam === "bornCity") {
            setBuscaLoadOptionCity(true)
            let cidade = val;
            if(cidade !== undefined) {
    
                if(cidade === '350000000') {
    
                    // alert('são paulo')
                    let bornCityString = 'São Paulo';
    
                    let nam = 'bornCity';
                    let val = '350000000';
                    setBuscaLoadOptionCity(false)
    
                    setVacancieUpdate({ ...vacancieUpdate, bornCityString, [nam]: val })
                    setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
    
                }
                else
                if(vacancieUpdate.bornState === '35' && cidade === '350000000') {
                    let bornCityString = 'São Paulo';
    
                    let nam = 'bornCity';
                    let val = '350000000';
                    setBuscaLoadOptionCity(false)
    
                    setVacancieUpdate({ ...vacancieUpdate, bornCityString, [nam]: val })
                    setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
    
                }
                else {
    
                    async function loadCity() {
                        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                        .then(response => {
                            let bornCityString = response.data[0].nome;
                            setBuscaLoadOptionCity(false)
                            setVacancieUpdate({ ...vacancieUpdate, bornCityString, "bornCity": val })
                            setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
    
                    }
                    loadCity();
    
                }
    
            }

        } else 
        if (nam === "nameSector"){
            let valSector = removerSpecials(val).toLowerCase()
            setBuscaSector({ ...buscaSector, [nam]: val });
            setBuscaSectorState({ ...buscaSectorState, [nam]: valSector });
            setVacancieUpdate({ ...vacancieUpdate, [nam]: val })
        }
        
    
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUfEstados = ufEstados.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornStateBusca !== undefined ? nationalityBusca.bornStateBusca : "" )  ;
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = citys.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornCityBusca !== undefined ? nationalityBusca.bornCityBusca : "" )  ;
    });


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);
    async function loadsectors() {
        await api.get('/sector/?sortBy=name&order=asc')
        .then(response => {
            setSectors(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSector, setBuscaSector ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSectorState, setBuscaSectorState ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaSector, setIsActiveSelectBuscaSector] = useState(false);
    async function SelectBuscaSector() { setIsActiveSelectBuscaSector(!isActiveSelectBuscaSector) }
    // setLoadSector
    const sectorsFiltre = sectors.filter((sector) => {
        return removerSpecials(sector.name).toLowerCase().includes(buscaSectorState?.nameSector !== undefined ? buscaSectorState.nameSector : "" )  ;
    });

    function sectorSelected(id_sector, name_sector) {
        setVacancieUpdate({ ...vacancieUpdate, 'sector': id_sector, 'nameSector': name_sector })
    }
  
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadTitle, setLoadTitle] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [LoadbornState, setLoadbornState ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadBtnSave, setLoadBtnSave] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusTitle            = useRef(null);

    async function save(e, event) {
        const status = e;
        if(id !== undefined) {

            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            setLoadBtnSave(true)
        

            if(vacancieUpdate.title !== "" && vacancieUpdate.title !== undefined ) {
                formData.append('title', vacancieUpdate.title);
            }

            if(
                vacancieUpdate?.bornState !== undefined &&
                vacancieUpdate?.bornState !== ""
            ) {
                setLoadbornState(false)
                formData.append('bornState', vacancieUpdate.bornState);
                formData.append('bornStateString', vacancieUpdate.bornStateString);
            }

            if(
                vacancieUpdate.bornCity !== undefined && 
                vacancieUpdate.bornCity !== ""
            ) {
                formData.append('bornCity', vacancieUpdate.bornCity);
                formData.append('bornCityString', vacancieUpdate.bornCityString);
            }
        

            if(vacancieUpdate.laborBond !== undefined) {
                formData.append('laborBond', vacancieUpdate.laborBond);
            }
        

            if(vacancieUpdate.type !== undefined) {
                formData.append('type', vacancieUpdate.type);
            } 
        

            if(vacancieUpdate.sector !== undefined) {
                formData.append('sector', vacancieUpdate.sector);
            } 

            if(vacancieUpdate.descriptionTitle !== undefined) {
                formData.append('descriptionTitle', vacancieUpdate.descriptionTitle);
            }

            if(vacancieUpdate.description !== undefined) {
                formData.append('description', vacancieUpdate.description);
            }

            if(vacancieUpdate.responsibilities !== undefined) {
                formData.append('responsibilities', vacancieUpdate.responsibilities);
            }

            if(vacancieUpdate.skills !== undefined) {
                formData.append('skills', vacancieUpdate.skills);
            }

            if(vacancieUpdate?.file) {
                formData.append('file', vacancieUpdate.file);
            }
            formData.append('status', status);
            formData.append('path', path);

            setLoadBtnSave(true)
            await api.put(`/vacancie/${id}`, formData, config)
            .then(response => {
                setLoadBtnSave(false)
                swal({ icon: "success", title: "Sucesso!", text: "Vaga atualizada com sucesso!" });
                window.location.href =  `/recrutamento/vagas`;

            }).catch(error => {
                console.log("error.response.data.message")
                console.log(error.response.data.message)
                console.log("error.response.data")
                console.log(error.response.data)
                console.log("error.response")
                console.log(error.response)
            });



        } else {
            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            setLoadBtnSave(true)
           
    
            if(vacancieUpdate.title !== undefined) {
                setLoadTitle(false)
                formData.append('title', vacancieUpdate.title);
            }
            else if(
                vacancie                     &&
                vacancie.title         !== undefined
            ) {
                setLoadTitle(false)
            }
            else {
                setLoadTitle(true)
                focusTitle.current.focus();
                setLoadBtnSave(false)
                return
            }
    
            if(
                vacancieUpdate?.bornState !== undefined &&
                vacancieUpdate?.bornState !== ""
            ) {
                setLoadbornState(false)
                formData.append('bornState', vacancieUpdate.bornState);
                formData.append('bornStateString', vacancieUpdate.bornStateString);
            }
    
            if(
                vacancieUpdate.bornCity !== undefined && 
                vacancieUpdate.bornCity !== ""
            ) {
                formData.append('bornCity', vacancieUpdate.bornCity);
                formData.append('bornCityString', vacancieUpdate.bornCityString);
            }
           
    
            if(vacancieUpdate.laborBond !== undefined) {
                formData.append('laborBond', vacancieUpdate.laborBond);
            }
           
    
            if(vacancieUpdate.type !== undefined) {
                formData.append('type', vacancieUpdate.type);
            } 
           
    
            if(vacancieUpdate.sector !== undefined) {
                formData.append('sector', vacancieUpdate.sector);
            } 
    
            if(vacancieUpdate.descriptionTitle !== undefined) {
                formData.append('descriptionTitle', vacancieUpdate.descriptionTitle);
            }
    
            if(vacancieUpdate.description !== undefined) {
                formData.append('description', vacancieUpdate.description);
            }
    
            if(vacancieUpdate.responsibilities !== undefined) {
                formData.append('responsibilities', vacancieUpdate.responsibilities);
            }
    
            if(vacancieUpdate.skills !== undefined) {
                formData.append('skills', vacancieUpdate.skills);
            }
    
            if(vacancieUpdate?.file) {
                formData.append('file', vacancieUpdate.file);
            }
    
            formData.append('status', status);
            formData.append('path', path);




            setLoadBtnSave(true)
            await api.post("/vacancie/", formData, config)
            .then(response => {
                setLoadBtnSave(false)
                swal({ icon: "success", title: "Sucesso!", text: "Vaga criada com sucesso!" });
                window.location.href =  `/recrutamento/vagas`;

            }).catch(error => {
                console.log("error.response.data.message")
                console.log(error.response.data.message)
                console.log("error.response.data")
                console.log(error.response.data)
                console.log("error.response")
                console.log(error.response)
        });

        }

        


    }
    return (
        <>
            <Layout path={props.match.path}>
                <Header path={path}
                 />

                <div className="page-vacancies registre-vacancies">
                    <div className='formulario'>
                        <h5>Resumo</h5>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Título</p>
                                </div>
                                <div className="intemB">
                                    <input 
                                        type="text" 
                                        className={loadTitle ? 'inputErro' : ''} 
                                        placeholder='Título' 
                                        name='title' 
                                        defaultValue={vacancie.title ? vacancie.title : ''} 
                                        ref={focusTitle}
                                        onChange={myChangeHandler}
                                    />
                                    { loadTitle ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Estado da vaga</p>
                                </div>
                                <div className="intemB">
                                   

                                <SelectPersonalizado
                                        valorCampo={
    
                                            nationalityBuscaString && nationalityBuscaString.bornStateBusca ?
                                            nationalityBuscaString.bornStateBusca :
                                            nationalityBuscaString && nationalityBuscaString.bornStateBusca === '' ?
                                                '' :
    
                                                vacancieUpdate && vacancieUpdate.bornState ?
                                                ufEscolhido.nome 
                                                :
                                                vacancie && vacancie.bornState ?
                                                    ufEscolhido.nome : ''
                                        }
                                        nameCampoBusca={"bornStateBusca"}
                                        nameButton={"bornState"}
                                        loadError={LoadbornState}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreUfEstados}
    
                                        loadOption={BuscaLoadOptionBornstate}
                                        validacaoValue={"estado"}
                                        validacaoOptionIntem={"estado"}
                                        typeSelect={"busca"}
                                        // focusRef={focusbornState}
                                    />
                                    

                                    { LoadbornState ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Cidade da vaga</p>
                                </div>
                                <div className="intemB">

                                <SelectPersonalizado
                                        valorCampo={
     
                                            nationalityBuscaString && nationalityBuscaString.bornCityBusca ?
                                            nationalityBuscaString.bornCityBusca :
                                            nationalityBuscaString && nationalityBuscaString.bornCityBusca === '' ?
                                                '' :
                                                vacancieUpdate && vacancieUpdate.bornCity?
                                                cidade.nome 
                                                : vacancie?.bornCity   ?
                                                cidade.nome : ''
                                        }
                                        nameCampoBusca={"bornCityBusca"}
                                        nameButton={"bornCity"}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreUCidade}
                                        loadOption={BuscaLoadOptionCity}
                                        validacaoValue={"estado"}
                                        validacaoOptionIntem={"estado"}
                                        typeSelect={"busca"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Setor</p>
                                </div>
                                <div className="intemB">
                                        <div className="select-busca-btn">
                                            <input
                                                type="text"
                                                className="menu-button"
                                                placeholder='Selecione'
                                                name="nameSector"
                                                value={
                                                   
                                                    vacancieUpdate?.nameSector ?
                                                    vacancieUpdate?.nameSector :
                                                    vacancie && vacancie.sector ?
                                                        vacancie.sector.name:
                                                        ''
                                                }
                                                onClick={ SelectBuscaSector }
                                                onBlur={ () => (setIsActiveSelectBuscaSector(false)) }
                                                onChange={myChangeHandlerSlects}
                                            />
                                            <nav className={`menu ${isActiveSelectBuscaSector ? "ativo": ""}`} >
                                                <Scrollbars className='listDropDown'  style={{  height:
                                                sectorsFiltre.length === 0 ? 30 : "" ||
                                                sectorsFiltre.length === 1 ? 30 : ""  ||
                                                sectorsFiltre.length === 2 ? 70 : ""  ||
                                                sectorsFiltre.length === 3 ? 120 : 120
                                                }}>
                                                    <ul>
                                                        {
                                                            sectorsFiltre.length === 0 ?
                                                                <li>
                                                                    <button>Nada encontrado</button>
                                                                </li>
                                                            :
                                                            sectorsFiltre
                                                            .map((sector, index) => (
                                                                <li key={index}>
                                                                    <button
                                                                    value={sector.name}
                                                                    name="sector"
                                                                    onClick={()=>sectorSelected(sector._id, sector.name)}
                                                                    >{sector.name}</button>
                                                                </li>
                                                            ))}
                                                    </ul>

                                                </Scrollbars>
                                            </nav>
                                        </div>


                                </div>
                            </div>
                        </div>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Regime</p>
                                </div>
                                <div className="intemB">
                                     <div className="select-busca-btn">
                                        <button 
                                            className="menu-button"
                                            onBlur={ () => (setIsActiveSelectLaborBond(false)) }
                                            onClick={ SelectLaborBond }
                                        >
                                            <span>
                                            {
                                                vacancieUpdate && vacancieUpdate.laborBond ?
                                                vacancieUpdate.laborBond  === "PJ" ? "Pessoa Jurídica" : vacancieUpdate.laborBond
                                                :
                                                vacancie && vacancie.laborBond ?
                                                vacancie.laborBond  === "PJ" ? "Pessoa Jurídica" : vacancie.laborBond
                                                 :
                                                'Selecione'
                                            }

                                            </span>
                                        
                                        </button>
                                        <nav className={`menu ${isActiveSelectLaborBond ? "ativo": ""}`} >

                                            <Scrollbars className='listDropDown'  style={{  height: 100
                                            }}>
                                                <ul>

                                                    {   
                                                    [
                                                        "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "Pessoa Jurídica", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                                    ]
                                                        .map((LaborBond, index) => (
                                                            <li key={index}>
                                                                <button 
                                                                value={LaborBond === "Pessoa Jurídica" ? "PJ" : LaborBond }
                                                                name="laborBond"
                                                                onClick={myChangeHandler}
                                                                >{LaborBond}</button>
                                                            </li>
                                                        ))}
                                                </ul>
                                                
                                            </Scrollbars>
                                        </nav>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Tipo</p>
                                </div>
                                <div className="intemB">
                                     <div className="select-busca-btn">
                                        <button 
                                            className="menu-button"
                                            onBlur={ () => (setIsActiveSelectType(false)) }
                                            onClick={ SelectType }
                                        >
                                            <span>
                                                {

                                                vacancieUpdate && vacancieUpdate.type ?
                                                vacancieUpdate.type 
                                                :
                                                vacancie && vacancie.type ?
                                                vacancie.type
                                                 :
                                                'Selecione'
                                                }

                                            </span>
                                        
                                        </button>
                                        <nav className={`menu ${isActiveSelectType ? "ativo": ""}`} >

                                            <Scrollbars className='listDropDown'  style={{  height: 100
                                            }}>
                                                <ul>
                                                    {   
                                                    [
                                                    "Presencial", "Híbrido", "Remoto"
                                                    ]
                                                    .map((TypeIntem, index) => (
                                                        <li>
                                                            <button 
                                                            value={TypeIntem}
                                                            name="type"
                                                            onClick={myChangeHandler}
                                                            >{TypeIntem}</button>
                                                        </li>
                                                    ))}
                                                </ul>
                                                
                                            </Scrollbars>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Imagem de capa</p>
                                </div>
                                <div className="intemB">
                                    <Dropzone onDrop={handleDrop} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                          



                        {
                            vacancieUpdate?.nameFile ?
<>
                            <div className="linha-typeOne">
                                <div className='bloco'>
                                    <div className="intemA">
                                    </div>
                                    <div className="intemB">

                                        <div className="ArquivosEnviados">
                                            <div  className="arquivos">
                                                <div className="nameArquivo">
                                                    <p>
                                                        {vacancieUpdate?.nameFile}
                                                    </p>

                                                </div>
                                                <div className="btnExclui">
                                                    <button onClick={()=>removeAttachment()} >Excluir anexo</button >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </>
                            :
                            vacancie && vacancie?.originalname ?
                            
                            <div className="linha-typeOne">
                                <div className='bloco'>
                                    <div className="intemA">
                                    </div>
                                    <div className="intemB">

                                        <div className="ArquivosEnviados">
                                            <div  className="arquivos">
                                                <div className="nameArquivo">
                                                    <a href="{/}" target="’_blank’">{vacancie.originalname}</a>
                                                </div>
                                                <div className="btnExclui">
                                                    <button onClick={()=>removeAttachment()} >Excluir anexo</button >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            : 
                            ''
                        }

                        <div className="linha-typeTwo editor editor-um">
                            <div className='bloco'>
                                <div className="intemA">

                                    <h5>Descrição da vaga</h5>
                                </div>
                                <div className="intemB">
                                    <TextEditor 
                                        myChangeHandlerTitulo={myChangeHandler}
                                        myChangeHandlerEditor={myChangeHandlerDescription} 
                                        titleValor={
                                            vacancieUpdate && vacancieUpdate.descriptionTitle ?
                                            vacancieUpdate.descriptionTitle 
                                            :
                                            vacancie && vacancie.descriptionTitle ?
                                            vacancie.descriptionTitle
                                             :
                                            ''
                                        }
                                        descritionValor={
                                            vacancie && vacancie.description ?
                                            vacancie.description
                                             :
                                            ''

                                        }
                                        nameTitle={"descriptionTitle"}
                                        placeholderTitle={"Titulo"}
                                        placeholderEditor={"Digite a descrição da vaga…"}

                                    />
                                </div>
                            </div>
                        </div>

                        <div className="linha-typeTwo editor">
                            <div className='bloco'>
                                <div className="intemA">

                                    <h5>Principais responsabilidades</h5>
                                </div>
                                <div className="intemB">
                                    <TextEditor 
                                        myChangeHandlerEditor={myChangeHandlerResponsibilities}  
                                        title={"noTitle"}
                                        placeholderEditor={"Digite a as responsabilidades da vaga…"}

                                        descritionValor={
                                            vacancie && vacancie.responsibilities ?
                                            vacancie.responsibilities
                                             :
                                            ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="linha-typeTwo editor">
                            <div className='bloco'>
                                <div className="intemA">

                                    <h5>Habilidades necessárias</h5>
                                </div>
                                <div className="intemB">
                                    <TextEditor 
                                        myChangeHandlerEditor={myChangeHandlerskills}  
                                        title={"noTitle"}
                                        placeholderEditor={"Digite a as habilidades necessárias da vaga…"}

                                        descritionValor={
                                            vacancie && vacancie.responsibilities ?
                                            vacancie.responsibilities
                                             :
                                            ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='page-vacancies-btns'>
                    <NavLink to="/recrutamento/vagas"className='cancelar'>Voltar</NavLink>
                    <div>
                        <button className='rascunho btnGray'
                        
                            onClick={() => save("Rascunho")}>Rascunho</button>
                        <button
                            className={loadBtnSave ?'saved btnGreen load' : 'saved btnGreen' }
                            onClick={() => save("Publicado")}
                        >Publicar</button>
                    </div>
                </div>
            </Layout>
        </>
    );
}

