import React from 'react';
// import React, { useState, useEffect } from 'react';
import Carregamento from '../../../../assets/images/carregando.gif';

import { momentDurationTotalDayTrial } from '../../../../services/utils';

export default function pageSystemConfigChangePlan({
    props,
    path,
    loadPlan,
    changePageFlatDetail,
    changePageAddPaymentMethod,
    planGeneral,
    planCompany,
    signatureCompany,
    statusDate,
}) {

    const currentDate    = new Date();

    console.log('planCompany')
    console.log(planCompany)

    return (
        <>
        <div className='troca-plano'>

            {
                loadPlan ?
                <div className="carregamento">
                    <img alt="" src={Carregamento} />
                </div>
                :
                <>
                {
                    planCompany !== null && planCompany?.status === 'active' && planCompany?.planId?.type !== 'trial' ?
                    ''
                    :
                    (planCompany !== null && planCompany?.planId?.type !== 'trial')
                    ||
                    (statusDate <= 10) ?
                    <>
                    <p className='teste-periodo'>Seu plano trial expira em
                    {momentDurationTotalDayTrial(currentDate,planCompany?.expirationDate)}</p>
                    </>
                    :
                    <p className='teste-acabou'>Seu período de teste expirou, por favor selecione um dos planos para continuar utilizando o PeopleView.</p>
                }

                <h3 className="sub-titulo">{planCompany !== null && planCompany?.planId?.type !== 'trial' ? 'Trocar plano' : 'Selecionar plano'}</h3>
                {
                    (statusDate > 10 && planCompany !== null) || (planCompany?.planId?.type === 'trial') ?
                    ''
                    :
                    <button className='voltar' onClick={changePageFlatDetail}>Voltar</button>
                }

                <br />
                <br />

                <div className="list-planos">

                    {
                        planGeneral ?
                        planGeneral.map((generalPlan, index) => (
                            <>
                            <div key={index} className={
                                planCompany?.planId?.pagarme_plan_id === generalPlan?.pagarme_plan_id ?
                                "plano selecionado"
                                :
                                "plano"
                            } onClick={
                                planCompany?.planId?.pagarme_plan_id === generalPlan?.pagarme_plan_id ?
                                ''
                                :
                                ()=>changePageAddPaymentMethod(generalPlan)
                                }>
                                <h4>{generalPlan.name}</h4>
                                <p className="valor">R$ {generalPlan.amount} <span> mês</span></p>
                                <ul>
                                    {
                                        generalPlan.featurePlan.map((feature, index) => (
                                            <>
                                            <li>{feature.description}</li>
                                            </>
                                        ))
                                    }
                                </ul>
                                {
                                    planCompany?.planId?.pagarme_plan_id === generalPlan?.pagarme_plan_id ?
                                    ''
                                    :
                                    <button className='btnGreen'>Contratar</button>
                                }
                            </div>
                            </>
                        ))
                        :
                        ''
                    }

                </div>
                </>
            }




        </div>

        </>
    );
}
