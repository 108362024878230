import React from 'react';
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Carregando from '../../../../../../assets/images/carregando.gif';
import Close from '../../../../../../assets/images/close.svg';
// import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import { Modal} from "react-bootstrap";
// import api from '../../../../../../services/api';

const ColProfileVoterTitle = ({
    id,
    collaborator,
    loadPage,
    onCollaboratorInputChange,
    collaboratorUpdate,
    OnSubmit,
    loadContinue,
    EditVoterTitleVisible,
    visibleVoterTitle,
    filesSavedVoterTitle,
    loadVoterTitle,
    deleteFileVoterTitle,
    fileNamesVoterTitle,
    handleDropVoterTitle
}) => {

    return (
        <>

                <div className="box titulo-eleitor">
                    <h3>Título de Eleitor                    
                        {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? 
                            "" 
                            :  
                            <button className="edit-Benefit" onClick={() => EditVoterTitleVisible()}><img src={EditBlack} alt="Edite Informações" /></button>   
                        }
                    </h3>
                    {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregando} />
                                </div>
                                </>
                            ):(
                                <>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Número do título</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.voterRegistration ? collaborator.voterRegistration : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Zona eleitoral</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.electoralZone ? collaborator.electoralZone : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Seção eleitoral</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.pollingStation ? collaborator.pollingStation : '-'}</p>
                            </div>
                        </li>

                        {filesSavedVoterTitle[0] !== undefined ? (
                        <li className="li-anexo">
                            <div className="intemA">
                                <p>Anexo</p>
                            </div>



                        <div className="intemB arq-adicionado" >
                            {filesSavedVoterTitle[0] !== undefined ?
                            filesSavedVoterTitle.map((filesaved, index) => (
                                        <a href={filesaved.location} target="’_blank’" key={index}>
                                            {filesaved.name}
                                        </a>
                            )) : (
                                <>
                                <p>-</p>
                                </>
                            )}


                        </div>
                        </li>
                        ) : ('')}
                    </ul>
                    </>)}
                </div>
                <Modal show={visibleVoterTitle} className="popup-edit-perfil popup-edit-cpf" onHide={EditVoterTitleVisible}>
                <div>
                    <div className="titulo">
                        <h4>Título de Eleitor  <p className="btn-fechar" onClick={EditVoterTitleVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Número do título</p>
                            </div>
                            <div className="intemB">
                                {
                                (collaboratorUpdate && collaboratorUpdate.voterRegistration !== undefined) || collaboratorUpdate.voterRegistration === '' ?
                                (
                                    <input type="text"  name="voterRegistration" value={(collaboratorUpdate.voterRegistration !== undefined) ? collaboratorUpdate.voterRegistration : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"  />
                                ) :
                                collaborator && collaborator.voterRegistration ? (
                                    <input type="text"  name="voterRegistration" defaultValue={(collaborator.voterRegistration !== undefined) ? collaborator.voterRegistration : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"/>
                                ):
                                (
                                    <input type="text"  name="voterRegistration" value={(collaboratorUpdate.voterRegistration !== undefined) ? collaboratorUpdate.voterRegistration : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"  />
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Zona eleitoral</p>
                            </div>
                            <div className="intemB">
                                { (collaboratorUpdate && collaboratorUpdate.electoralZone !== undefined) || collaboratorUpdate.electoralZone === '' ?
                                (
                                    <input type="text" maxlLength="3" name="electoralZone" value={(collaboratorUpdate.electoralZone !== undefined) ? collaboratorUpdate.electoralZone : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"  />
                                ) :
                                collaborator && collaborator.electoralZone ? (
                                    <input type="text"  maxlLength="3" name="electoralZone" defaultValue={(collaborator.electoralZone !== undefined) ? collaborator.electoralZone : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"/>
                                ):
                                (
                                    <input type="text"  maxlLength="3" name="electoralZone" value={(collaboratorUpdate.electoralZone !== undefined) ? collaboratorUpdate.electoralZone : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"  />
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Seção eleitoral</p>
                            </div>
                            <div className="intemB">
                                { (collaboratorUpdate && collaboratorUpdate.pollingStation !== undefined) || collaboratorUpdate.pollingStation === '' ?
                                (
                                    <input type="text" maxlLength="4" name="pollingStation" value={(collaboratorUpdate.pollingStation !== undefined) ? collaboratorUpdate.pollingStation : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"  />
                                ) :
                                collaborator && collaborator.pollingStation ? (
                                    <input type="text"  maxlLength="4" name="pollingStation" defaultValue={(collaborator.pollingStation !== undefined) ? collaborator.pollingStation : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"/>
                                ):
                                (
                                    <input type="text"  maxlLength="4" name="pollingStation" value={(collaboratorUpdate.pollingStation !== undefined) ? collaboratorUpdate.pollingStation : ''}  onChange={onCollaboratorInputChange} placeholder="Número do título"  />
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div className="intemB">
                                    <Dropzone onDrop={handleDropVoterTitle} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                            </div>
                        </li>

                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                            </div>
                        </li>
                        {filesSavedVoterTitle[0]  !== undefined ? (
                        <li>
                            <div className="arquivoAnexado">
                                <h5>Enviados</h5>

                                {filesSavedVoterTitle[0] !== undefined ?
                                filesSavedVoterTitle.map((filesaved, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                                        </p>
                                        <button onClick={()=>deleteFileVoterTitle(filesaved)} className="btnExclui">Remover anexo</button>
                                    </div>
                                )) : ''}

                            </div>
                        </li>
                                ) : ''}
                        {loadVoterTitle ?
                        ( <li>
                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                        </li>) : ('')}
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={OnSubmit} className="btn-salvar">
                                    <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>

                    </ul>


                </div>
            </Modal>
        </>
    );
}
export default ColProfileVoterTitle;
