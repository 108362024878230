import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { dataFormatadaParaBarra } from '../../../../services/utils';
import { toast } from 'react-toastify';
export default function ColProfileExperiencePeriod({ collaborator }) {

const [ loadTimeCourseSaved, setLoadTimeCourseSaved ] = useState(false);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    loadExperiencePeriod();
}, []);

async function loadExperiencePeriod() {
    await api.get('/collaborator-experience-period-access')
    .then(response => {
        setLoadTimeCourseSaved(response.data);
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}


    return (
        <>
            <div className="box ExperiencePeriod">
                <h3>Período de Experiência</h3>

                <ul>
                    <li>
                        <div className="intemA">
                            <p>Período</p>
                        </div>
                        <div className="intemB">
                            <p>{loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '1x15'  ? '1 x 15' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '1x30'  ? '1 x 30' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '1x45'  ? '1 x 45' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '1x60'  ? '1 x 60' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '1x90'  ? '1 x 90' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '2x15'  ? '2 x 15' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '2x30'  ? '2 x 30' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '2x45'  ? '2 x 45' :
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '30x60' ? '30 + 60':
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === '44x46' ? '44 + 46':
                            loadTimeCourseSaved &&
                            loadTimeCourseSaved.timeCourse === 'Customizado' ? 'Customizado':
                            '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Data de admissão</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.admissionDate ? collaborator.admissionDate : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Término</p>
                        </div>
                        <div className="intemB">
                            <p>{loadTimeCourseSaved && loadTimeCourseSaved.termination !== undefined ? dataFormatadaParaBarra(loadTimeCourseSaved.termination) : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Segundo Término</p>
                        </div>
                        <div className="intemB">
                        <p>{loadTimeCourseSaved && loadTimeCourseSaved.secondEnd !== undefined ? dataFormatadaParaBarra(loadTimeCourseSaved.secondEnd) : '-'}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}

