import React, { useState, useEffect } from 'react';

import Layout from '../../../components/Layouts/defaultUser';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import AddressesContacts from '../../../components/PerfilUser/Personal/AddressesContacts';
import SubHeader from '../../../components/PerfilUser/subHeader';
import { dateMask, cep, mNumber, phones } from '../../../services/mask';
import '../styles.scss';
import api from '../../../services/api';
import swal from 'sweetalert';
import { FormataStringData } from "../../../services/utils";
import axios from 'axios';
import { decodeToken } from '../../../services/auth';
import { toast } from 'react-toastify';

const ColProfilePersonal = (props) => {
    const decoded = decodeToken();
    var id = decoded.user._id;

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfBirthUpdate, setDateOfBirthUpdate ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaborator(id);
        loadfilessaved(id);
    }, [id]);

    async function loadcollaborator(id) {
        setLoadPage(true)
        await api.get(`/collaborator-access/${id}`)
        .then(response => {
            setLoadPage(false)
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

      //////////////////////////////////////////////////////////////////////////////////
     ////////////////// INFORMAÇÕES PESSOAIS //////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileCertificateSend, setFileCertificateSend] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAddress, setLoadAddress ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesAddress] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedAddress, setFilesSavedAddress] = useState([]);

    async function loadfilessaved(id) {
        await api.get(`/collaborator-file-access/?id=${id}&category=location`)
        .then(response => {
            // setLoadAddress(false)
            setFilesSavedAddress(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function handleDropAddress(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;


        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setFileNamesAddress(acceptedFiles.map(file => file.name));
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'location');
            formData.append('collaborator', collaborator._id);
            setLoadAddress(true)
            await api.post("/collaborator-file-access", formData, config)
            .then(response => {
                setFileCertificateSend(true)
                loadfilessaved(id);
                setLoadAddress(false)
                // swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!"});
            }).catch(error => { });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    //const deleteFile = async (filesaved) => {
    async function deleteFileAddress(filesaved) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                setLoadAddress(true)
                await api.delete(`/collaborator-file-access/${filesaved._id}?path=${path}`)
                .then(() => {
                    setFileCertificateSend(true)
                    setVisibleAddress(false);
                    loadfilessaved(id);
                    setLoadAddress(false)
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

    ////////////////////////////////////////////////////////////////////

    const  myChangeHandler = event => {

        let nam = event.target.name;
         let val = event.target.value;

         if (nam === "zipcode") {
            getAddress(val)
         }
         else
         if (nam === "bankAgency") {

             let value = event.target.value.replace(/[^\d]/g, "")

             if(value.length > 10) {

                setCollaboratorUpdate({ ...collaboratorUpdate })

             } else {

                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: value })

             }
         }
         else
         if (nam === "bankAccount") {

            let bankAccount = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: bankAccount })

        }
        else
        if (nam === "accountDigit") {

            let accountDigit = mNumber(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: accountDigit })

        }
        else
        if (nam === "bornState") {

            let estado = val;
            async function estados() {

                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
                .then(response => {
                    let bornStateString = response.data.nome;
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornStateString, [nam]: val })
                }).catch(error => {});

            }
            estados()


        }
        else
        if (nam === "bornCity") {

            let cidade = val;
            async function cidades() {

                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                .then(response => {
                    let bornCityString = response.data[0].nome;
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                }).catch(error => {});

            }
            cidades()

        }
        else
        if (nam === "dateOfBirth") {

            let date = dateMask(val)
            if(date.length <= 10) {

                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: date })
                if(date.length === 10) {
                    let valDate = FormataStringData(date);
                    setDateOfBirthUpdate({ ...dateOfBirthUpdate, [nam]: valDate })
                }
            }

        }
        else
        if (nam === "phone") {

            let phone = phones(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: phone })

        }
        else
        if (nam === "cellphone") {
            if(val.length >= 14) {
                Loadsetcellphone(false)
            }

            let phone = phones(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: phone })

        }
        else if (nam === "personalEmail") {
            // Loadsetcellphone(false)
            if(val.length >= 10) {
                Loadsetemail(false)
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }
        else {
             setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }

     };
     async function getAddress(zipcode) {

        if (zipcode.length === 8) {
            zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
            //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
            let cepppp = cep(zipcode)
            const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

            if (response.data !== null) {
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    zipcode: cepppp,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })
            }
        } else {
            let cepppp = cep(zipcode)
            setCollaboratorUpdate({
                ...collaboratorUpdate,
                zipcode: cepppp,
            })
        }
    }
    //// para salva no butao


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleAddress, setVisibleAddress] = useState(false);
    function PopupAddressVisible() {
        setVisibleAddress(!visibleAddress);
        setCollaboratorUpdate([])
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleContact, setVisibleContact] = useState(false);
    function PopupContactVisible() {
      setVisibleContact(!visibleContact);
      setCollaboratorUpdate([])
    }
    const [ Loadcellphone              , Loadsetcellphone                  ] = useState(false);
    const [ Loademail              , Loadsetemail                  ] = useState(false);

    async function saveContact(event) {
        event.preventDefault();


            if(collaboratorUpdate === undefined || collaboratorUpdate.length === 0) {
                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });
            }else if(
                collaboratorUpdate.cellphone === "" ||
                collaboratorUpdate.personalEmail === ""
            ){
                // alert("entrou 2")
                swal({ icon: "error", title: "Erro!", text: "Somente o campo telefone pode ser salvo em branco" });
            }
            else {
                const formData     = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if(collaboratorUpdate.phone && collaboratorUpdate.phone !== "") {
                    formData.append('phone', collaboratorUpdate.phone);
                }

                if ( collaboratorUpdate.cellphone && collaboratorUpdate.cellphone !== undefined) {
                    formData.append('cellphone', collaboratorUpdate.cellphone);
                    Loadsetcellphone(false)
                } else if (collaborator.cellphone && collaborator.cellphone !== undefined) {
                    formData.append('cellphone', collaborator.cellphone);
                    Loadsetcellphone(false)
                } else {
                    Loadsetcellphone(true)
                    return
                }
                if ( collaboratorUpdate.personalEmail && collaboratorUpdate.personalEmail !== undefined) {
                    formData.append('personalEmail', collaboratorUpdate.personalEmail);
                    Loadsetemail(false)
                } else if (collaborator.personalEmail && collaborator.personalEmail !== undefined) {
                    formData.append('personalEmail', collaborator.personalEmail);
                    Loadsetemail(false)
                } else {
                    Loadsetemail(true)
                    return
                }
                formData.append('path', path);
                setLoadContinue(true)
                await api.put(`/collaborator-access/${id}`, formData, config)
                .then(response => {
                    setLoadContinue(false)
                    setCollaboratorUpdate([]);
                    setVisibleContact(!visibleContact);
                    loadcollaborator(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
                });
            }
    }

    async function save(event) {
        event.preventDefault();

        if(
            collaboratorUpdate === undefined ||
            collaboratorUpdate.length === 0
        ) {

            if(fileCertificateSend && fileCertificateSend === true) {

                swal({ icon: "success", title: "Sucesso!", text: "Dados do Colaborador alterado com sucesso!" });
                setFileCertificateSend(false)
                setVisibleAddress(false);

            } else {

                swal({ icon: "error", title: "Erro!", text: 'Necessário atualizar pelo menos um campo!' });

            }

        }
        else {


            if(
                collaboratorUpdate.address             === "" ||
                collaboratorUpdate.zipcode             === "" ||
                collaboratorUpdate.addressNeighborhood === "" ||
                collaboratorUpdate.addressCity         === "" ||
                collaboratorUpdate.addressState        === "" ||
                collaboratorUpdate.addressComplement   === ""
            ) {
                swal({ icon: "error", title: "Erro!", text: 'Apenas o campo número pode ser salvo em branco!' });
            }
            else
            if(
                collaboratorUpdate.personalEmail               === "" ||
                collaboratorUpdate.cellphone                   === ""
            ) {
                swal({ icon: "error", title: "Erro!", text: 'Apenas o campo telefone pode ser salvo em branco!' });
            }
            else {

                const formData     = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }

                if(collaboratorUpdate.addressNumber !== undefined) {

                    if(collaboratorUpdate.addressNumber === "") {
                        formData.append('addressNumber', '-');
                    } else {
                        formData.append('addressNumber', collaboratorUpdate.addressNumber);
                    }

                }
                if(collaboratorUpdate.address !== undefined) {
                    formData.append('address', collaboratorUpdate.address);
                }
                if(collaboratorUpdate.zipcode !== undefined) {
                    formData.append('zipcode', collaboratorUpdate.zipcode);
                }
                if(collaboratorUpdate.addressNeighborhood !== undefined) {
                    formData.append('addressNeighborhood', collaboratorUpdate.addressNeighborhood);
                }
                if(collaboratorUpdate.addressCity !== undefined) {
                    formData.append('addressCity', collaboratorUpdate.addressCity);
                }
                if(collaboratorUpdate.addressState !== undefined) {
                    formData.append('addressState', collaboratorUpdate.addressState);
                }
                if(collaboratorUpdate.addressComplement !== undefined) {
                    formData.append('addressComplement', collaboratorUpdate.addressComplement);
                }
                if(collaboratorUpdate.phone !== undefined) {
                    formData.append('phone', collaboratorUpdate.phone);
                }
                if(collaboratorUpdate.cellphone !== undefined) {

                    if (
                        (collaboratorUpdate.cellphone  === undefined && collaborator.cellphone === undefined)
                        ||
                        (collaboratorUpdate.cellphone  === "")
                    ) {
                        Loadsetcellphone(true)
                        return
                    } else {
                        formData.append('phone', collaboratorUpdate.phone);
                    }

                }

                if(collaboratorUpdate.personalEmail !== undefined) {

                    if (
                        (collaboratorUpdate.personalEmail  === undefined && collaborator.personalEmail === undefined)
                        ||
                        (collaboratorUpdate.personalEmail  === "")
                    ) {
                        Loadsetemail(true)
                        return
                    } else {
                        formData.append('personalEmail', collaboratorUpdate.personalEmail);

                    }

                }
                formData.append('path', path);
                setLoadContinue(true)
                await api.put(`/collaborator-access/${id}`, formData, config)
                .then(response => {

                    setLoadContinue(false)
                    setVisibleAddress(false);
                    setVisibleContact(false);
                    setCollaboratorUpdate([])
                    loadcollaborator(id);
                    loadcollaborator();
                    swal({ icon: "success", title: "Sucesso!", text: "Dados do colaborador alterado com sucesso!" });

                }).catch(error => {
                    // console.log(error)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadContinue(false)
                });

            }
        }
    }
        return (
            <>
               <Layout
               path={props.match.path}
               >

                    <div className="profile-user">
                        <InforProfile
                            collaborator={collaborator}
                            id={id}
                            setCollaborator={setCollaborator}
                            path={path}
                        />
                        <SubHeader
                            id={id}
                            path={props.match.path}
                            collaborator={collaborator}
                        />
                        <AddressesContacts
                            id={id}
                            path={path}
                            loadPage={loadPage}
                            collaborator={collaborator}
                            collaboratorUpdate={collaboratorUpdate}
                            onCollaboratorInputChange={myChangeHandler}
                            OnSubmit={save}
                            loadContinue={loadContinue}
                            PopupAddressVisible={PopupAddressVisible}
                            visibleAddress={visibleAddress}
                            PopupContactVisible={PopupContactVisible}
                            visibleContact={visibleContact}
                            loadAddress={loadAddress}
                            filesSavedAddress={filesSavedAddress}
                            handleDropAddress={handleDropAddress}
                            deleteFileAddress={deleteFileAddress}
                            Loadcellphone={Loadcellphone}
                            Loademail={Loademail}
                            saveContact={saveContact}
                        />
                    </div>
                </Layout>
            </>
        );
}
export default ColProfilePersonal;
