import React from 'react';

import Dropzone from "react-dropzone";
// import InputMask from 'react-input-mask';
import { formatDateString } from '../../../../../services/utils'
import SelectPersonalizado from '../../../../Reused/selectPersonalizado'

export default function formPersonalData({
    id,
    onCollaboratorInputChangeSchooling,
    schooling,
    handleDropSchooling,
    deleteFilesSchooling,
    fileNamesSchooling,
    handleSubmitSchooling,
    loadButtonSchooling,
    loadSchooling,
    loadInstitution,
    loadCourse,
    registeredSchooling,
    deleteSchooling,
    focusSchooling,
    focusCourse,
    focusInstitution,
    focusBottomAcademic,
    loadBottomAcademic
 }) {
     const optionSchooling = [
         "Analfabeto",
         "Até a 5ª série cursando do Ensino Fundamental", "Até a 5ª série incompleta do Ensino Fundamental", "5º ano completo do Ensino Fundamental",
         "Do 6º ao 9º ano do Ensino Fundamental incompleto", "Do 6º ao 9º ano do Ensino Fundamental cursando", "Ensino Fundamental completo",
         "Ensino Médio incompleto", "Ensino Médio cursando", "Ensino Médio completo",
         "Educação Superior incompleta", "Educação Superior cursando", "Educação Superior completa",
         "Mestrado incompleto", "Mestrado cursando", "Mestrado completo",
         "Doutorado incompleto", "Doutorado cursando", "Doutorado completo",
         "Pós Graduação incompleta", "Pós Graduação cursando", "Pós Graduação completa",
         "Ensino Técnico cursando", "Ensino Técnico incompleto", "Ensino Técnico completo"
     ]

    return (
        <>
                <div className="academic-education">

                    <h3>Formação acadêmica</h3>

                    <div className='formulario'>
                        <div className='linha'>
                            <p className="textCampo">Escolaridade</p>
                            <div>
                                <SelectPersonalizado
                                    valorCampo={
                                        
                                        schooling && schooling.schooling ?
                                        schooling.schooling 
                                        :
                                        ''
                                    }
                                    nameButton={"schooling"}
                                    myChangeHandler={onCollaboratorInputChangeSchooling}
                                    // option={optionSchooling}
                                    optionFil={optionSchooling}
                                    typeSelect="busca2"
                                    loadError={loadSchooling}
                                    focusRef={focusSchooling}
                                />
                                <p className="inputUm textError">{loadSchooling ? 'É necessário escolher uma escolaridade' : ''}</p>
                            </div>
                        </div> 

                        <div className='linha'>
                                <p className="textCampo">Curso</p>
                                <div>
                                    <input ref={focusCourse} type="text" name="course" autocomplete="autocompleteoff" value={schooling && schooling.course ? schooling.course : ''} onChange={onCollaboratorInputChangeSchooling} placeholder="Curso" className={loadCourse ? 'inputDois inputErro' : 'inputDois'}/>
                                    <p className="inputDois textError">{loadCourse ? 'É necessário preencher um curso' : ''}</p>
                                </div>
                        </div> 

                        <div className='linha'>
                                <p className="textCampo">Instituição</p>
                                <div>
                                    <input ref={focusInstitution} type="text" name="institution"  autocomplete="autocompleteoff" value={schooling && schooling.institution ? schooling.institution : ''} onChange={onCollaboratorInputChangeSchooling} placeholder="Instituição" className={loadInstitution ? 'inputTreis inputErro' : 'inputTreis'}/>
                                    <p className="inputTreis textError">{loadInstitution ? 'É necessário preencher uma instituição' : ''}</p>
                                </div>
                        </div> 




                        {
                        schooling.schooling === '5º ano completo do Ensino Fundamental' ||
                        schooling.schooling === 'Ensino Fundamental completo' ||
                        schooling.schooling === 'Ensino Médio completo' ||
                        schooling.schooling === 'Educação Superior completa' ||
                        schooling.schooling === 'Mestrado completo' ||
                        schooling.schooling === 'Doutorado completo' ||
                        schooling.schooling === 'Pós Graduação completa' ||
                        schooling.schooling === 'Ensino Técnico completo' ?
                        (
                            <>
                                <div className='linha'>
                                    <p className="textCampo">Ano de conclusão</p>
                                    <div>
                                        <input type="text" name="conclusion"  autocomplete="autocompleteoff" value={schooling && schooling.conclusion ? schooling.conclusion : ''} onChange={onCollaboratorInputChangeSchooling} placeholder="Ano de conclusão" className="inputQuatro"/>
                                    </div>
                                </div> 
                            </>
                        ) :
                        (
                            ''
                        )
                        }



                        <div className='linha anexar'>
                            <p className="textCampo"></p>
                            <div>
                                <Dropzone onDrop={handleDropSchooling} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                
                                
                                    {fileNamesSchooling.length !== 0  ?
                                    <>
                                        <div className="arquivoAnexado">

                                            {fileNamesSchooling ?
                                            fileNamesSchooling.map((filename, index) => (
                                                <div key={index} className="arquivo">
                                                    <p className="nomeArquivo">
                                                        {filename}
                                                    </p>
                                                    <p onClick={deleteFilesSchooling} className="remover">Remover</p>
                                                </div>
                                            )) : ''}
                                        </div>

                                    </>
                                    :""}
                                </div>
                            </div>

                            <div className='linha anexar'>
                                <p className="textCampo"></p>
                                <div>
                                    <button ref={focusBottomAcademic}  onClick={handleSubmitSchooling} type="submit" className={loadButtonSchooling ? 'btnGreen load btn-adicionar' : 'btnGreen btn-adicionar'}>{loadButtonSchooling ? 'Adicionando' : 'Adicionar formação acadêmica'}</button>
                                    {loadBottomAcademic ? (
                                    <p className="textError inputCinco">É necessário adicionar uma formação acadêmica</p>
                                    ) : ('')
                                    }
                                </div>
                            </div> 





                        </div> 
                   

                    <div className="educacao-enviados">
                        <h5>Cadastrado</h5>

                        {registeredSchooling[0] !== undefined ? registeredSchooling
                        .map((schooling, index) => (
                            <div key={index} className="educacao">
                                <div className="nome-educacao">
                                    <p>{schooling.schooling}</p>
                                </div>
                                <div className="arquivo">
                                    <p>{schooling.originalname !== undefined ? (

                                    <a href={schooling.location} target="’_blank’">
                                    {schooling.originalname}
                                    </a>
                                    ) : (<span>Nenhum anexo</span>)}
                                    </p>
                                                                        </div>
                                <div className="data-educacao">
                                <span>cadastrado em {formatDateString(schooling.createdAt)}</span>
                                </div>
                                <div className="btnExclui">
                                    <button onClick={() => deleteSchooling(schooling._id)}>Excluir</button >
                                </div>
                            </div>
                        )): (
                            <div className="nenhum-educacao">
                                <p>Nenhuma formação acadêmica cadastrada.</p>
                            </div>
                        )}
                    </div>
                </div>


        </>
    );

}
