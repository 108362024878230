import React,{useEffect,useState} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import GeneralInformation from '../../components/RecruitmentAndSelection/vacancies/GeneralInformation';
import VacancyListTable from '../../components/RecruitmentAndSelection/vacancies/VacancyListTable';
import api from '../../services/api';
import { toast } from 'react-toastify';
import './styles.scss';


export default function CandidateProfile(props) {

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacanciePublicado , setVacanciePublicado] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [  , setVacancieInativo] = useState([]);
    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacancieRascunho , setVacancieRascunho] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadVacanciePublicado();
        loadVacancieInativo();
        loadVacancieRascunho();
    }, []);

    async function loadVacanciePublicado() {

        await api.get(`/vacancie/?status=Publicado`)
        .then(response => {

            setVacanciePublicado(response.data.total);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }
    async function loadVacancieInativo() {

        await api.get(`/vacancie/?paginate=true&status=INATIVOS`)
        .then(response => {

            setVacancieInativo(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }
    async function loadVacancieRascunho() {

        await api.get(`/vacancie/?status=Rascunho`)
        .then(response => {

            setVacancieRascunho(response.data.total);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }


    return (
        <>
            <Layout
            path={props.match.path}>
                <Header path={path}
                />
                <div className="page-vacancies ">
                    <GeneralInformation
                        vacanciePublicado={vacanciePublicado}
                        vacancieRascunho={vacancieRascunho}
                    />
                    <VacancyListTable
                    />

                </div>
            </Layout>
        </>
    );
}

