import React,{
} from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./styles.scss";
export default function skeletonHomem(props) {
     
    return(
        <>
        
               <div className="Skeleton-website-vacancies">
                 
                 
                <Skeleton  
                    count={1}  
                    height={240}
                    borderRadius={9}
                    />
                    <br/>   
                    <Skeleton count={1}  
                    x="27" y="139"
                        height={20} 
                        width={300}
                        borderRadius={9}
                    />
                    <br/> 
                    <Skeleton 
                        count={1}  
                        height={150}
                        borderRadius={9}
                    />  
                    <br/>   
                    <br/>   
                    <Skeleton count={1}  
                    x="27" y="139"
                        height={20} 
                        width={300}
                        borderRadius={9}
                    />
                    <div className="container-menu">
                        <Skeleton count={1} 
                            height={20} 
                            width={100}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={20} 
                            width={150}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={20} 
                            width={150}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={20} 
                            width={150}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={20} 
                            width={300}
                            borderRadius={9}
                        />   

                    </div> 

                    <div className="container-vagas">
                        <Skeleton count={1} 
                            height={40}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={40}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={40}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={40}
                            borderRadius={9}
                        />   
                        <Skeleton count={1} 
                            height={40}
                            borderRadius={9}
                        />   

                    </div>
                    <Skeleton  
                    count={1}  
                    height={240}
                    borderRadius={9}
                    />
               </div>

        </>
    )
}
