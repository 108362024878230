import React from 'react';
import { Dropdown } from "react-bootstrap";
import IconV from "../../../assets/images/v.svg";
import Illustration from "../../../assets/images/illustration-performance-evaluation.svg";


const overallAverage = 8.2;
  
const  overal_Average_list = [
    {
        "name" : "Comunicação oral",
        "count" : 6.5
    },
    {
        "name" : "Comunicação escrita",
        "count" : 5.5
    },
    {
        "name" : "Embaixadorismo",
        "count" : 4.5
    },
    {
        "name" : "Proatividade",
        "count" : 7.7
    },
    {
        "name" : "Comprometimento",
        "count" : 7.2
    }
];



export default function performanceEvaluationOverview() {

    return (
        <>

                <div className='box-leiaute overview'>
                    <h2 className='titulo'>Overview
                        <Dropdown  className="dropdown-icon ">
                            <Dropdown.Toggle>
                                <span>Nos últimos 7 dias</span>
                                    <img src={IconV} alt="icone v" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <button>Nos últimos 7 dias</button>
                                <button>Nos últimos 15 dias</button>
                                <button>No último mês</button>
                            </Dropdown.Menu>
                        </Dropdown>
                    </h2>
                    <div className='box-grades-illustration'>
                        <div className='box-grades'>
                            <div className='grades'>
                                <div className='box'>
                                    <p> Nota média geral da empresa <span>{overallAverage && overallAverage !== undefined ? overallAverage : "0.0"}</span></p>
                                    <div className='barrar'>
                                        <div 
                                            className={
                                                overallAverage && overallAverage <= 4 ? "progresso red" :
                                                overallAverage <= 7 ? "progresso orange" :
                                                overallAverage >= 8  ? "progresso green" :
                                                "progresso"
                                            }
                                            style={
                                                {width: overallAverage !== undefined && overallAverage !== 0 ? 
                                            `${parseInt(overallAverage)}0%`: "0"}
                                            }
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div className='grades'>

                                {
                                    overal_Average_list.map((barra, index) => (
                                        <>
                                            <div className='box'>
                                                <p> {barra?.name} <span>{barra?.count ? barra.count : "0.0"} </span></p>
                                                <div className='barrar'>
                                                    <div 
                                                        className="progresso blue"
                                                        style={ {width: barra?.count ?  `${parseInt(barra.count)}0%`: "0"} }
                                                    ></div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                 }
                            </div>

                        </div>
                        <img src={Illustration} alt="Avaliação de desempenho" className='illustration'/>
                    </div>
                </div>
        </>
    );
}

