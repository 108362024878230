import React from 'react';
import IconVisa from '../../../../assets/images/icon-visa.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import IconMastercard from '../../../../assets/images/icon-mastercard.svg';
import IconAmex from '../../../../assets/images/icon-amex.svg';
import IconJCB from '../../../../assets/images/icon-jbc.svg';
// import IconElo from '../../../../assets/images/icon-elo.svg';
// import IconError from '../../../../assets/images/error.svg';
import IconDiners from '../../../../assets/images/icon-diners.svg';
import IconDiscover from '../../../../assets/images/icon-discover.svg';
// import ModalGeral from '../../../../components/modal/modal-geral';
// import { NavLink } from 'react-router-dom'
export default function pagePaymentManage({
    openAddPayment,
    changePageFlatDetail,
    loadcards,
    cardsGeneral,
    destroyCard,
    editCardPreferentialAndSubscription
}) {

    return (
        <>
        <div className='gerenciar-pagamento'>
            <h3 class="sub-titulo">Gerenciar pagamento</h3>
            <span class="infor-titulo">Edite seus detalhes de pagamento, adicione um backup ou mude seu método de pagamento preferencial.</span>
            <button className='voltar' onClick={changePageFlatDetail}>Voltar</button>
            {
                loadcards ?
                <div className="carregamento">
                <img alt="" src={Carregando} />
                </div>
                :
                <>
                <ul className="lista-catoes">
                    {
                        cardsGeneral && cardsGeneral.length !== 0 ?
                        cardsGeneral.map((card, index) => (
                            <li key={index} className="infor-carta">
                                <div className="cartao">
                                    {
                                        card.pagarme_brand === 'visa'       ||
                                        card.pagarme_brand === 'mastercard' ||
                                        card.pagarme_brand === 'amex'       ||
                                        card.pagarme_brand === 'discover'   ||
                                        card.pagarme_brand === 'diners'     ||
                                        card.pagarme_brand === 'jcb'         ?
                                        <img src={
                                            card.pagarme_brand === 'visa' ?
                                            IconVisa
                                            :
                                            card.pagarme_brand === 'mastercard' ?
                                            IconMastercard
                                            :
                                            card.pagarme_brand === 'amex' ?
                                            IconAmex
                                            :
                                            card.pagarme_brand === 'discover' ?
                                            IconDiscover
                                            :
                                            card.pagarme_brand === 'diners' ?
                                            IconDiners
                                            :
                                            card.pagarme_brand === 'jcb' ?
                                            IconJCB
                                            :
                                            ""
                                        }
                                        alt="
                                            Número do cartão
                                        " />
                                        :
                                        ''
                                    }
                                    •••• •••• •••• {card.pagarme_last_digits}
                                </div>
                                {
                                    card?.mainCard === true ?
                                    <div className="status"><span>PREFERENCIAL</span></div>
                                    :
                                    <div className="status">
                                        <button onClick={() => editCardPreferentialAndSubscription(card)} >Tornar preferencial</button>
                                    </div>
                                }


                                <div className="edita-remover">
                                    <button onClick={()=>destroyCard(card)} className='remover'>
                                        Remover
                                    </button>
                                </div>
                            </li>
                        ))
                        :
                        ''
                    }
                </ul>
                </>
            }
            <div className="btn-add-metodo-pagamento">
                <button onClick={openAddPayment}>
                + ADICIONAR MÉTODO DE PAGAMENTO
                </button>
            </div>
        </div>
        </>
    )
}
