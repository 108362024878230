import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import iconAdd from '../../../assets/images/add-green.svg';
import iconPostit from '../../../assets/images/postit.svg';
import iconMore from '../../../assets/images/more.svg';
import Close from '../../../assets/images/close.svg';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import api from '../../../services/api';
import { dateFormatedToHistory } from '../../../services/utils';

export default function ColProfileNote ({ id, path, collaborator }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTitle, setLoadTitle ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadColor, setLoadColor ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAnnotatione, setLoadAnnotatione ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredNote, setRegisteredNote ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [noteUpdate, setNoteUpdate] = useState(false);


    function AddAndEditNoteVisible(e) {
        if(e !== undefined) {
            let noteUpdate = e;

            setNoteUpdate({
                ...noteUpdate
            })
        }

        setVisible(!visible);
    }


    function CloaseAddAndEditNoteVisible() {

        setNoteUpdate()
        setRegisteredNoteState();
        setLoadTitle(false)
        setLoadColor(false)
        setLoadAnnotatione(false)
        setVisible(!visible);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadNote(id);
    }, [id]);

    async function loadNote(id) {
        // setLoadPage(true)
        await api.get(`/collaborator-notes-access/`)
        .then(response => {
            if(response.data !== undefined) {
                setRegisteredNote(response.data);
            }

            setLoad(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredNoteState, setRegisteredNoteState ] = useState([]);

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;
        setRegisteredNoteState({ ...registeredNoteState, collaborator, [nam]: val, path: path })
        // setNoteUpdate({
        //     ...noteUpdate, [nam]: val, path: path
        // })
    

        if (nam === "title") {
            if(val.length === 0) {
                setLoadTitle(true)
            }else {
                setLoadTitle(false)
            }
        }
        if (nam === "color") {
            setLoadColor(false)
        }
        if (nam === "annotation") {

            if(val.length === 0) {
                setLoadAnnotatione(true)
            }else {
                setLoadAnnotatione(false)
            }
        } 



    };

    async function myChangeHandlerTwo(event) {
        var color = event.target.value;

        const notePut = {
                color,
                path: path
        }
        setLoad(true)
        await api.put(`/collaborator-notes-access/${event.target.id}`, notePut)
        .then(response => {
            setRegisteredNoteState();
            setNoteUpdate()
            loadNote(id);
            setVisible(false)
            swal({ icon: "success", title: "Feito!", text: "Anotação atualizada com sucesso!" });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!",  text: "error"});
            setLoad(true)
        });
    };
    //////////////////////////////////////////////////////////////////////
    async function handleSubmit(event) {
        event.preventDefault();
        if(noteUpdate && noteUpdate._id !== undefined) {
            if(
                registeredNoteState?.title           === "" ||
                registeredNoteState?.annotation      === ""   
            ) {
                return
            }else {
                setLoad(true)
                await api.put(`/collaborator-notes-access/${noteUpdate._id}`, registeredNoteState)
                .then(response => {
                    setRegisteredNoteState();
                    setNoteUpdate()
                    loadNote(id);
                    setVisible(false)
                    swal({ icon: "success", title: "Feito!", text: "Anotação atualizada com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!",  text: "error"});
                    setLoad(true)
                });
            }
        } else {
            if (registeredNoteState && registeredNoteState.title !== undefined) {
                setLoadTitle(false)
            } else {
                setLoadTitle(true)
                // return
            }

            if (registeredNoteState && registeredNoteState.color !== undefined) {
                setLoadColor(false)
            } else {
                setLoadColor(true)
                // return
            }

            if (registeredNoteState && registeredNoteState.annotation !== undefined) {
                setLoadAnnotatione(false)
            } else {
                setLoadAnnotatione(true)
                // return
            }
            if(
                registeredNoteState?.annotation === undefined ||
                registeredNoteState?.color      === undefined ||
                registeredNoteState?.title      === undefined ||
                registeredNoteState?.title      === ""        ||
                registeredNoteState?.annotation      === ""   
            ){
                return
            } else {

                setLoad(true)
                await api.post(`/collaborator-notes-access`, registeredNoteState)
                .then(response => {
                    setRegisteredNoteState();
                    setNoteUpdate();
                    loadNote(id);
                    swal({ icon: "success", title: "Feito!", text: "Anotação feita com sucesso!" });
                    setVisible(false)
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "error"});
                });
            }

        }
    }

    ////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////
    /// => Aqui você deleta uma XXXXX
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////
    async function deleteNote(note) {
    swal({
     title: "Atenção",
     text: "Deseja excluir este anotação?",
     icon: "warning",
     buttons: ["Cancelar", "OK"],
     dangerMode: false,
    })
     .then(async (res) => {
         if (res) {
            setLoad(true)
            await api.delete(`/collaborator-notes-access/${note}?path=${path}`)
            .then(() => {
            loadNote(id);
                swal({ icon: "success", title: "Feito!", text: "Anotação removida com sucesso." });
            }).catch(() => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao remover o exame, tente novamente mais tarde." });
            });
         }
     });
    }
    return (
        <>
            <div className="box-profile-anotacoes">
                <button className="adicionar-nota" onClick={() => AddAndEditNoteVisible()}>
                    <img alt="iconAdd" src={iconAdd} />
                    Adicionar anotação
                </button>
                {registeredNote !== undefined ? registeredNote
                .map((note, index) => (
                <>
                <div key={index}

                className={
                    note.color                === 'light-purple'    ? 'anotacao box-light-purple' :
                    note.color                === 'light-blue'      ? 'anotacao box-light-blue' :
                    note.color                === 'light-red'       ? 'anotacao box-light-red' :
                    note.color                === 'light-orange'    ? 'anotacao box-light-orange' :
                    note.color                === 'light-green'     ? 'anotacao box-light-green' :
                    note.color                === 'light-turquoise' ? 'anotacao box-light-turquoise' : ''
                }
                >
                        <div className="cabecalho ">
                            <img alt="iconPostit" src={iconPostit} className="icon-postit"/>
                            <div className="infor">
                                <h5>{note.title}



                            <div className="dropdown-icon">
                                <Dropdown  className="dropdown-icon ">
                                    <Dropdown.Toggle>
                                        <img alt="" src={iconMore} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button onClick={() => AddAndEditNoteVisible(note)}>Editar anotação</button>

                                        {note.color !== undefined ? (
                                            <>

                                            <ul className="alterar-cores">
                                                <li className="radio">

                                                <label className={
                                                        note.color === 'light-turquoise' ? 'corSelecionada light-turquoise' : 'cor light-turquoise'
                                                    }>
                                                    <input type="radio" onChange={myChangeHandlerTwo} value="light-turquoise" name="color" id={note._id} checked={
                                                        note.color === 'light-turquoise' ? true : false
                                                    }
                                                    />
                                                    </label>
                                                </li>
                                                <li className="radio">
                                                    <label className={
                                                        note.color === 'light-blue' ? 'corSelecionada light-blue' : 'cor light-blue'
                                                    }>
                                                    <input type="radio" onChange={myChangeHandlerTwo}  value="light-blue" name="color" id={note._id} checked={
                                                        note.color === 'light-blue' ? true : false
                                                    }/>
                                                    </label>
                                                </li>
                                                <li className="radio">
                                                    <label className={
                                                        note.color === 'light-orange' ? 'corSelecionada light-orange' : 'cor light-orange'
                                                    }>
                                                    <input type="radio" onChange={myChangeHandlerTwo}  value="light-orange" name="color" id={note._id} checked={
                                                        note.color === 'light-orange' ? true : false}
                                                        />
                                                    </label>
                                                </li>
                                                <li className="radio">
                                                    <label className={
                                                        note.color === 'light-purple' ? 'corSelecionada light-purple' : 'cor light-purple'
                                                    }>
                                                    <input type="radio" onChange={myChangeHandlerTwo}  value="light-purple" name="color" id={note._id} checked={
                                                        note.color === 'light-purple' ? true : false}
                                                    /></label>
                                                </li>
                                                <li className="radio">
                                                        <label className={
                                                        note.color === 'light-red' ? 'corSelecionada light-red' : 'cor light-red'
                                                    }>
                                                    <input type="radio" onChange={myChangeHandlerTwo}  value="light-red" name="color" id={note._id} checked={
                                                        note.color === 'light-red' ? true : false}
                                                        />
                                                    </label>
                                                </li>
                                                <li className="radio">
                                                    <label className={
                                                        note.color === 'light-green' ? 'corSelecionada light-green' : 'cor light-green'
                                                    }>
                                                    <input type="radio" onChange={myChangeHandlerTwo}  value="light-green" name="color" id={note._id} checked={
                                                        note.color === 'light-green' ? true : false}
                                                        /></label>
                                                </li>
                                            </ul>
                                            <p className="inputUm textError">{loadColor ? 'É necessário selecionar uma cor' : ''}</p>
                                            </>

                                            ) : ''}
                                        <button className="red" onClick={() => deleteNote(note._id)} >Excluir anotação</button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                                </h5>
                                <p>
                                    <span className="nome">Por {note.responsible.name}</span>
                                    <span className="data"> {dateFormatedToHistory(note.createdAt)}</span>
                                </p>
                            </div>
                        </div>
                        <p className="infor-texto">{note.annotation} </p>
                    </div>
                </>
                    )) : (''
                )}
            </div>
            <Modal show={visible} className="popup-edit-perfil popup-edit-perfil-note" onHide={CloaseAddAndEditNoteVisible}>
                <div>
                    <div className="titulo">
                        <h4>{noteUpdate && noteUpdate !== undefined ? "Editar anotação" : "Adicionar anotação"} <p className="btn-fechar" onClick={CloaseAddAndEditNoteVisible}> <img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Título</p>
                            </div>
                            <div className="intemB">
                                {noteUpdate && noteUpdate.title !== undefined ?
                                (<input type="text" name="title"  onChange={myChangeHandler} defaultValue={noteUpdate.title ? noteUpdate.title : registeredNoteState.title ? registeredNoteState.title : ''}   className={loadTitle ? 'inputErro' : ''}/>):
                                (<input type="text" name="title"  onChange={myChangeHandler}  placeholder="Título da anotação"   className={loadTitle ? 'inputErro' : ''}/>)
                                }
                                <p className="inputUm textError">{loadTitle ? 'É necessário escrecever título da anotação' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>cor</p>
                            </div>
                            <div className="intemB">
                            {noteUpdate && noteUpdate.color !== undefined ? (

                                    <>
                                    <ul className="alterar-cores">
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-turquoise' ?
                                                    "corSelecionada light-turquoise" :
                                                    noteUpdate && noteUpdate.color === 'light-turquoise' ?
                                                    "corSelecionada light-turquoise" :
                                                    "cor light-turquoise"
                                                }>
                                                    <input type="radio" value="light-turquoise" name="color" onChange={myChangeHandler}/>
                                                </label>
                                            </li>

                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-blue' ?
                                                    "corSelecionada light-blue" :
                                                    noteUpdate && noteUpdate.color === 'light-blue' ?
                                                    "corSelecionada light-blue" :
                                                    "cor light-blue"
                                                }>
                                                    <input type="radio" value="light-blue" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-orange' ?
                                                    "corSelecionada light-orange" :
                                                    noteUpdate && noteUpdate.color === 'light-orange' ?
                                                    "corSelecionada light-orange" :
                                                    "cor light-orange"
                                                }>
                                                    <input type="radio" value="light-orange" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-purple' ?
                                                    "corSelecionada light-purple" :
                                                    noteUpdate && noteUpdate.color === 'light-purple' ?
                                                    "corSelecionada light-purple" :
                                                    "cor light-purple"
                                                }>
                                                    <input type="radio" value="light-purple" name="color"  onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-red' ?
                                                    "corSelecionada light-red" :
                                                    noteUpdate && noteUpdate.color === 'light-red' ?
                                                    "corSelecionada light-red" :
                                                    "cor light-red"
                                                }>
                                                    <input type="radio" value="light-red" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-green' ?
                                                    "corSelecionada light-green" :
                                                    noteUpdate && noteUpdate.color === 'light-green' ?
                                                    "corSelecionada light-green" :
                                                    "cor light-green"
                                                }>
                                                    <input type="radio" value="light-green" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                        </ul>
                                    <p className="inputUm textError">{loadColor ? 'É necessário selecionar uma cor' : ''}</p>
                                    </>

                                    ) : (
                                        <>
                                        <ul className="alterar-cores">
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-turquoise' ?
                                                    "corSelecionada light-turquoise" :
                                                    "cor light-turquoise"
                                                }>
                                                    <input type="radio" value="light-turquoise" name="color" onChange={myChangeHandler}/>
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-blue' ?
                                                    "corSelecionada light-blue" :
                                                    "cor light-blue"
                                                }>
                                                    <input type="radio" value="light-blue" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-orange' ?
                                                    "corSelecionada light-orange" :
                                                    "cor light-orange"
                                                }>
                                                    <input type="radio" value="light-orange" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-purple' ?
                                                    "corSelecionada light-purple" :
                                                    "cor light-purple"
                                                }>
                                                    <input type="radio" value="light-purple" name="color"  onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-red' ?
                                                    "corSelecionada light-red" :
                                                    "cor light-red"
                                                }>
                                                    <input type="radio" value="light-red" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                            <li className="radio">
                                                <label className={
                                                    registeredNoteState && registeredNoteState.color === 'light-green' ?
                                                    "corSelecionada light-green" :
                                                    "cor light-green"
                                                }>
                                                    <input type="radio" value="light-green" name="color" onChange={myChangeHandler} />
                                                </label>
                                            </li>
                                        </ul>
                                        <p className="inputUm textError">{loadColor ? 'É necessário selecionar uma cor' : ''}</p>
                                        </>
                                    )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA textarea-text">
                                <p>Anotação</p>
                            </div>
                            <div className="intemB">
                                {noteUpdate && noteUpdate.annotation !== undefined ?
                                (<textarea cols="150" defaultValue={noteUpdate.annotation}   name="annotation" onChange={myChangeHandler}   className={loadAnnotatione ? 'inputErro' : ''}>
                                </textarea>) :
                                (<textarea cols="150" placeholder="Digite sua anotação…" name="annotation" onChange={myChangeHandler}   className={loadAnnotatione ? 'inputErro' : ''}>
                                </textarea>)}
                                <p className="inputUm textError">{loadAnnotatione ? 'É necessário digita uma anotação…' : ''}</p>

                            </div>
                        </li>
                    </ul>
                    <div className="btns">
                        <button type="submit" className="btnGray" onClick={CloaseAddAndEditNoteVisible}>Cancelar</button>
                        <form className="adicionar" onSubmit={handleSubmit}   >
                            <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>
                                    Salvar
                            </button>
                        </form>

                    </div>


                </div>
            </Modal>
        </>
    );
}
