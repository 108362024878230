import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';
import IconFolder from '../../../assets/images/icon-folder.svg';
import Carregamento from '../../../assets/images/carregando.gif';




export default function favoriteFolders({
    modalAddFolderOpen,
    folderFavorite,
    loadFolderFavorite,
    inactiveFolder,
    modalEditFolderOpen
}) {

    return (
        <>
        <div className='favorite-folders'>
            <h2 className='title-box'>
                Pastas favoritas
                <NavLink to={"/recrutamento/talentos/pastas"}>
                    Ver todas as pastas
                </NavLink>
            </h2>
            <div className='list-folder'>
                    {
                    loadFolderFavorite ?
                        <>
                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        </>
                        : folderFavorite.length === 0 ?

                        <div className='nenhum-folder'>
                           <p>Nenhuma pasta favorita. <br /> Favorite uma pasta ou crie uma nova.</p>
                           <button onClick={()=>modalAddFolderOpen('e')} className="btnGreen more">Criar nova pasta</button>
                       </div> :""
                    }
                    <ul>
                    {
                    loadFolderFavorite ?
                    ''
                    :
                    folderFavorite[0] !== undefined ? folderFavorite
                    .map((folder, index) => (
                    <>
                        <li key={index}>

                            <NavLink to={`/recrutamento/talentos/pasta/${folder?._id}`}>
                            <img src={IconFolder} alt="Icone do Folder" className='icon-folder'/>
                            <p>{folder?.name}</p>
                            <span>{folder?.candidate?.length}</span>

                            </NavLink>
                            <div className='view-more'>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button onClick={()=>modalEditFolderOpen(folder)} type="button" >
                                            Editar
                                        </button>
                                        <button onClick={()=>inactiveFolder(folder._id, folder.name)} type="button" className='red'>
                                            Excluir
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </li>
                    </>
                    )) : ("")}
                    </ul>




            </div>
        </div>
        </>
    );
}

