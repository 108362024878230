import React, {
    useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Management/headerPulses';
import Overview from '../../../components/Management/Pulso/Overview';
import Categorias from '../../../components/Management/Pulso/categories';
import IllustrationFeed from '../../../assets/images/illustration-page-pulsos.svg'
import '../styles.scss';

export default function homePulsos(props) {

   const { path } = props.match;


   ///////// SETS E FUCTION DO MODAL DE CONFIGURAR PULSOS
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleModalConfigPulsos, setVisibleModalConfigPulsos] = useState(false);
   async function modalConfigPulsos() {
       setVisibleModalConfigPulsos(!visibleModalConfigPulsos);
   }

   ///////// SET PROVISORIO DE ESTADO VAZIO
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [pulsos, ] = useState(false);



       return (
           <>
              <Layout path={path}>
                   <Header 
                       path={path} 
                       modalConfigPulsos={modalConfigPulsos}
                       visibleModalConfigPulsos={visibleModalConfigPulsos}
                   />
                   <div className='pages-management'>

                   {
                            pulsos ?
                            <div className='nenhum-feedback'>
                               <p>Nenhum pulso registrado. <br/>Ative a pesquisa de pulsos.</p>
                               <button className='btnGreen more ' onClick={modalConfigPulsos}>Agendar feedback</button>

                               <img src={IllustrationFeed} alt="" />
                            </div>
                            :
                       <div className='pulses'>

                           <Overview/>
                           <Categorias/>
                           <div className="box-leiaute box-log">
                               
                                <h2 className='titulo'>Log de respostas</h2>
                               <div className='infors'>
                                   <div className='box'>
                                       <strong>122</strong>
                                       <p className='call'>colaboradores</p>
                                   </div>
                                   <div className='box'>
                                       <strong>82</strong>
                                       <p>responderam até o momento</p>
                                   </div>
                                   <div className='box'>
                                       <strong>67.21%</strong>
                                       <p>de engajamento</p>
                                   </div>

                               </div>
                           </div>

                       </div>
}
                   </div>
               </Layout>
           </>
       );
}

