import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import swal from 'sweetalert';
import Header from '../../../components/Administrativo/Colaboradores/header';
import HeaderNavigation from '../../../components/Administrativo/Colaboradores/headerNavigation';
import Admissao from '../../../components/Administrativo/Colaboradores/Admissao';
import '../styles';
import { decodeToken } from '../../../services/auth';

export default function PageAdmissao(props) {

    const { history } = props;
    const { user }    = decodeToken();
    const role        = user.role;
    const { path }    = props.match;

    localStorage.removeItem('@peopleview/StepsNewAdmission');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ stepOne, setStepOne ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ stepTwo, setStepTwo ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ stepThree, setStepThree ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ stepFour, setStepFour ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtomGerenateNew, setLoadButtomGerenateNew] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadIDButtom, setLoadIDButtom ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadstepone(nameFilter)
    }, [nameFilter]);
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////   STEPS   /////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function loadstepone(nameFilter) {
        setLoad(true)
        await api.get(`/collaborator-new-kanban/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&admissionProcess=true&stepOne=true&stepTwo=false&stepThree=false&stepFour=false&collaboratorEdition=false&page=0&limit=20`)
        .then(response => {
            setStepOne(response.data.collaboratorSearch);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

        await api.get(`/collaborator-new-kanban/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&admissionProcess=true&stepOne=true&stepTwo=false&stepThree=false&stepFour=false&collaboratorEdition=true&page=0&limit=20`)
        .then(response => {
            setStepTwo(response.data.collaboratorSearch);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

        await api.get(`/collaborator-new-kanban/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&admissionProcess=true&stepOne=true&stepTwo=true&stepThree=true&stepFour=false&collaboratorEdition=false&page=0&limit=20`)
        .then(response => {
            setStepThree(response.data.collaboratorSearch);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

        await api.get(`/collaborator-new-kanban/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&admissionProcess=true&stepOne=true&stepTwo=true&stepThree=true&stepFour=true&collaboratorEdition=false&page=0&limit=20`)
        .then(response => {
            setStepFour(response.data.collaboratorSearch);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
        setLoad(false)
    }

    async function loadfilterpercollaborator(event) {

        event.preventDefault();

        setLoad(true);
        const name = event.target.value;
        setNameFilter(event.target.value);

        loadstepone(name)

    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////   UPDATE TOKEN   ///////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    async function myChangeHandlerUpdateToken(event) {
        event.preventDefault();
        const id = event.target.value
        setLoadIDButtom(id)
        setLoadButtomGerenateNew(true)
        await api.put(`/collaborator-update-token/${id}`)
        .then(response => {

            setLoadButtomGerenateNew(false)
            loadstepone(nameFilter)

            swal({ icon: "success", title: "Sucesso!", text: "Token Atualizado e enviado e-mail para o colaborador!" });

        }).catch(error => {
            console.log(error.response.data)
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////  ENVIAR LEMBRETE   //////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //const sendReminder = async (filtercollaborator) => {
    async function sendReminder(collaboratorStepOne) {
        setLoad(true)
        await api.get(`collaborator-send-reminder?_id=${collaboratorStepOne._id}`)
        .then(response => {
            setLoad(false)
            swal({ icon: "success", title: "Sucesso!", text: `e-mail enviado com sucesso para ${collaboratorStepOne.email}.` });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao enviar o e-mail, tente novamente mais tarde." });
        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////   EXCLUIR ADMISSÃO   /////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const myChangeHandlerExcludeAdmssion = async (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let admissionProcess = "false";
        let shutdownProcess = "true";
        let recentShutdown  = "false";
        let status = "Inativo";
        setCollaborator({ ...collaborator, admissionProcess, shutdownProcess, recentShutdown, status, [nam]: val })
    }

    async function excludeAdmssion(event) {
        event.preventDefault();
        swal({
            title: "Atenção",
            text: "Deseja realmente excluir o processo de admissão deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                formData.append('_id', collaborator._id);
                formData.append('admissionProcess', collaborator.admissionProcess);
                formData.append('shutdownProcess', collaborator.shutdownProcess);
                formData.append('recentShutdown', collaborator.recentShutdown);
                formData.append('status', collaborator.status);
                await api.put(`/collaborator/${collaborator._id}`, formData, config)
                .then(response => {
                    loadstepone(nameFilter)
                    swal({ icon: "success", title: "Sucesso!", text: "Admissão excluída com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                });
            }
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmailProfissional,  ] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleReleaseAccess, setVisibleReleaseAccess] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [idEmailReleased, setIdEmailReleased] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [onlyProfessionalEmail, setOnlyProfessionalEmail] = useState(false);
    // onlyCpf
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [onlyCpf, setOnlyCpf] = useState(false);
    function ReleaseAccess(e, i) {
        if(i === 'email') {
            setOnlyProfessionalEmail(true)
            setOnlyCpf(false)
        }
        if(i === 'cpf') {
            setOnlyCpf(true)
            setOnlyProfessionalEmail(false)
        }
        setIdEmailReleased(e._id)
        setVisibleReleaseAccess(!visibleReleaseAccess);
    }

    function NoReleaseAccess() {
        setVisibleReleaseAccess(!visibleReleaseAccess);
    }
    
    return (
        <>
            <Layout
            path={props.match.path}
            >
            <Header
            history={history}
            user={user}
            />
                <HeaderNavigation
                nameFilter={nameFilter}
                role={role}
                onUserInputChange={(event) => loadfilterpercollaborator(event)}
                path={props.match.path}
                user={user}
                />
                <Admissao
                path={path}
                sendReminder={sendReminder}
                excludeAdmssion={excludeAdmssion}
                myChangeHandlerExcludeAdmssion={myChangeHandlerExcludeAdmssion}
                myChangeHandlerUpdateToken={myChangeHandlerUpdateToken}
                load={load}
                loadButtomGerenateNew={loadButtomGerenateNew}
                loadIDButtom={loadIDButtom}
                stepOne={stepOne}
                stepTwo={stepTwo}
                stepThree={stepThree}
                stepFour={stepFour}
                ReleaseAccess={ReleaseAccess}
                NoReleaseAccess={NoReleaseAccess}
                visibleReleaseAccess={visibleReleaseAccess}
                idEmailReleased={idEmailReleased}
                loadEmailProfissional={loadEmailProfissional}
                onlyCpf={onlyCpf}
                onlyProfessionalEmail={onlyProfessionalEmail}
                loadstepone={()=>loadstepone(nameFilter)}
                />
            </Layout>
        </>
    );
}

