import React, { useState, useEffect } from 'react';

import Carregando from '../../../../assets/images/carregando.gif';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import {removerSpecials} from '../../../../services/utils'
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado'

import api from '../../../../services/api';


const carteiraTrabalho = ({
    id,
    token,
    onCollaboratorInputChange,
    collaborator,
    collaboratorUpdate,
    loadwalletNumber ,
    loadserialNumber ,
    loadissueDate    ,
    loadwalletUF     ,
    loadpisNumber    ,
    focuswalletNumber,
    focusserialNumber,
    focusissueDate   ,
    focuswalletUF    ,
    focuspisNumber,
    estadosOrgam,
    setCollaboratorUpdate,
    path
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSaved, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
       if(id && id){ async function loadfilessaved() {
            setLoad(true)
            //console.log(id)
            await api.get(`/collaborator-file-new/?id=${id}&category=docCT`)
            .then(response => {
                setLoad(false)
                //console.log(response.data[0])
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                setLoad(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();}
    }, [id]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNames(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCT');
            formData.append('collaborator', id);
            formData.append('token', token);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file-token", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file-new/?id=${id}&category=docCT`)
                    .then(response => {
                        setLoad(false)
                        setFilesSaved(response.data);
                    }).catch(error=>{
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                //setTokenError(error.response.data);
                console.log(error.response.data)
                setLoad(false)
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    //const deleteFile = async (filesaved) => {
    async function deleteFile(filesaved) {
        //console.log(filesaved)
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoad(true)
                await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file-new/?id=${id}&category=docCT`)
                        .then(response => {
                            setFilesSaved(response.data);
                        }).catch(error=>{
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCollaboratorSaved ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadcadastrocolaborador() {
            await api.get(`/collaborator-list/${token}`)
            .then(response => {
                setCollaboratorSaved(response.data);
            })
            .catch(error => {
                setCollaboratorSaved(error.response.data);
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadcadastrocolaborador();
    }, [token]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [walletUFStringBusca , setwalletUFStringBusca] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        
        if(nam === "walletUFBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setwalletUFStringBusca({ ...walletUFStringBusca, [nam]: valOption });
        }else if(
            nam === "walletUF"
        ) {
            setCollaboratorUpdate({ ...collaboratorUpdate,  "walletUF": val })
            setwalletUFStringBusca({ ...walletUFStringBusca, "walletUFBusca": val });

        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = estadosOrgam.filter((valOption) => {
    return removerSpecials(valOption).toLowerCase().includes(walletUFStringBusca?.walletUFBusca !== undefined ? walletUFStringBusca.walletUFBusca : "" )  ;
    });

    return (
            <>

                <div className="box-formulario carteira-trabalho">
                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <h5>Carteira de Trabalho</h5>
                            </div>
                        </div>
                    </div>
                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoUm">Número da carteira </p>
                            </div>
                            <div className='intemB'>
                                <input
                                    ref={focuswalletNumber}
                                    type="text"
                                    name="walletNumber"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.walletNumber)
                                        ||
                                        (collaboratorUpdate.walletNumber === "")
                                        ?
                                        collaboratorUpdate.walletNumber
                                        :
                                        (collaborator && collaborator.walletNumber)
                                        ||
                                        (collaborator.walletNumber === "")
                                        ?
                                        collaborator.walletNumber
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="Número da carteira"
                                    className={loadwalletNumber ? 'inputUm inputErro' : 'inputUm'}
                                />
                                {
                                loadwalletNumber ?
                                <p className="textError inputUm">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoDois">Nº de série </p>
                            </div>
                            <div className='intemB'>

                                <input
                                    ref={focusserialNumber}
                                    type="text"
                                    name="serialNumber"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.serialNumber)
                                        ||
                                        (collaboratorUpdate.serialNumber === "")
                                        ?
                                        collaboratorUpdate.serialNumber
                                        :
                                        (collaborator && collaborator.serialNumber)
                                        ||
                                        (collaborator.serialNumber === "")
                                        ?
                                        collaborator.serialNumber
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="Nº de série"
                                    className={loadserialNumber ? 'inputDois inputErro' : 'inputDois'}
                                />
                                {
                                loadserialNumber ?
                                <p className="textError inputDois">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                    </div>
                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo">Data de emissão </p>
                            </div>
                            <div className='intemB'>

                                    <input
                                    ref={focusissueDate}
                                    type="text"
                                    name="issueDate"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.issueDate)
                                        ||
                                        (collaboratorUpdate.issueDate === "")
                                        ?
                                        collaboratorUpdate.issueDate
                                        :
                                        (collaborator && collaborator.issueDate)
                                        ||
                                        (collaborator.issueDate === "")
                                        ?
                                        collaborator.issueDate
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="DD/MM/AAAA"
                                    className={loadissueDate ? 'inputTreis inputErro' : 'inputTreis'}
                                />
                                {
                                loadissueDate ?
                                <p className="textError inputTreis">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo">UF da carteira </p>
                            </div>
                            <div className='intemB'>
                                <SelectPersonalizado
                                    valorCampo={
                                        walletUFStringBusca && walletUFStringBusca.walletUFBusca ?
                                        walletUFStringBusca.walletUFBusca :
                                        walletUFStringBusca && walletUFStringBusca.walletUFBusca === '' ?
                                        '' :
                                        collaborator && collaborator.walletUF ?
                                            collaborator.walletUF
                                            :
                                            ''
                                    }
                                    nameCampoBusca={"walletUFBusca"}
                                    nameButton={"walletUF"}
                                    loadError={loadwalletUF}
                                    myChangeHandler={myChangeHandlerSlects}
                                    option={optionFiltreUCidade}
                                    typeSelect={"busca"}
                                    focusRef={focuswalletUF}
                                />
                                {
                                loadwalletUF ?
                                <p className="textError inputQuatro">É necessário selecionar a UF da carteira</p>
                                :
                                ('')
                                }

                            </div>
                        </div>
                    </div>
                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoCinco">Número PIS </p>
                            </div>
                            <div className='intemB'>
                                <input
                                    ref={focuspisNumber}
                                    type="text"
                                    name="pisNumber"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.pisNumber)
                                        ||
                                        (collaboratorUpdate.pisNumber === "")
                                        ?
                                        collaboratorUpdate.pisNumber
                                        :
                                        (collaborator && collaborator.pisNumber)
                                        ||
                                        (collaborator.pisNumber === "")
                                        ?
                                        collaborator.pisNumber
                                        :
                                        ''
                                    }
                                    placeholder="Nº PIS"
                                    onChange={onCollaboratorInputChange}
                                    className={loadpisNumber ? 'inputCinco inputErro' : 'inputCinco'}
                                />
                                {
                                loadpisNumber ?
                                <p className="textError inputCinco">O campo é obrigatório.</p>
                                :
                                ('')
                                }

                            </div>
                        </div>
                    </div>

                        <div className='carteira-trabalho-dropzone'>

                            <div className="dropzone">
                                <Dropzone className="" onDrop={handleDrop}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="textoOnze">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </div>


                            <div className="arquivoAnexado textoDoze">
                            {filesSaved ?
                            filesSaved.map((filesaved, index) => (
                                <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        <a href={filesaved.location} target="’_blank’">
                                        {filesaved.originalname}
                                        </a>
                                    </p>
                                    <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                                </div>
                                )) : ''}
                            </div>
                            {load ?
                                (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                                ('')}

                        </div>
                </div>
            </>
        );

}
export default carteiraTrabalho;
