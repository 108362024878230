import React, {
    useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Overview from '../../../components/Management/PerformanceEvaluation/Overview';
import Header from '../../../components/Management/headerPerformanceEvaluation';
import Table from '../../../components/Management/PerformanceEvaluation/table';
import '../styles.scss';

export default function homePerformanceEvaluation(props) {

   const { path } = props.match;


   ///////// SETS E FUCTION DO MODAL DE CONFIGURAR PULSOS
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleModalPerformanceEvaluation, setVisibleModalPerformanceEvaluation] = useState(false);
   async function modalPerformanceEvaluation() {
       setVisibleModalPerformanceEvaluation(!visibleModalPerformanceEvaluation);
   }


       return (
           <>
              <Layout path={path}>
                   <Header 
                       path={path} 
                       modalPerformanceEvaluation={modalPerformanceEvaluation}
                       visibleModalPerformanceEvaluation={visibleModalPerformanceEvaluation}
                   />
                   <div className='pages-management'>

                        <div  className='performance-evaluation'>
                            <Overview/>
                            <Table/>
                        </div>

                   </div>
               </Layout>
           </>
       );
}

