import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import VacationSummary from './Vacation/VacationSummary';
import VacationHistory from './Vacation/VacationHistory';
import swal from 'sweetalert';
import api from '../../../../../services/api';
import { dateMask, mNumber } from '../../../../../services/mask';
import ModalAddFerias from '../../../../modal/ModalAddVacation';
import { toast } from 'react-toastify';

export default function ColProfileVacation({ id, collaborator, role, path }) {

    //////////////////////////////////////////////////////////////////////
    /////////////////////       Loads         ///////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadHandleSubmit, setLoadHandleSubmit ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInitialDate, setLoadInitialDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFinalDate, setLoadFinalDate ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationSummary, setVacationSummary ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadCollaboratorVestingperiod(id);
            loadcollaboratorvacationhistoryid(id);
        }
    }, [id]);


    //////////////////////////////////////////////////////////////////////
    /////////////////       SET VISIBLE POPUP         ///////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);
    function EditVacationSummaryVisible() {

        setVisible(!visible);
    }

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        let category = "Férias";

        // setVacationSummary({ ...vacationSummary, category, [nam]: val })
        if (nam === "startDate") {

            let startDate = dateMask(val)
            setVacationSummary({ ...vacationSummary, category, [nam]: startDate })

        } else if (nam === "endDate") {

            let endDate = dateMask(val)
            setVacationSummary({ ...vacationSummary, category, [nam]: endDate })

        } else if (nam === "vacationDaysSold") {

            let vacationDaysSold = mNumber(val)
            setVacationSummary({ ...vacationSummary, category, [nam]: vacationDaysSold })

        } else {

            setVacationSummary({ ...vacationSummary, category, [nam]: val })

        }

    }

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    /// => Aqui você atualiza ou adiciona uma xxxxxxxxxxxx
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    async function handleSubmit(event) {
        event.preventDefault();


        if (vacationSummary.startDate !== undefined) {
            var startDate = vacationSummary.startDate;
            setLoadInitialDate(false)
        } else {
            setLoadInitialDate(true)
        }

        if (vacationSummary.endDate !== undefined) {
            var endDate =  vacationSummary.endDate;

            setLoadFinalDate(false)
        } else {
            setLoadFinalDate(true)
        }

        if (vacationSummary.vacationDaysSold !== undefined) {
            var vacationDaysSold = vacationSummary.vacationDaysSold;

        }

        if (vacationSummary.ThirteenthSalary !== undefined) {
            var ThirteenthSalary = vacationSummary.ThirteenthSalary;

        } else {
            // eslint-disable-next-line no-redeclare
            var ThirteenthSalary = false;
        }

        if (vacationSummary.comments !== undefined) {
            var comments = vacationSummary.comments;

        }

        var collaborator = id;

        var category = vacationSummary.category;

        if (vacationSummary.startDate !== undefined &&  vacationSummary.endDate !== undefined ) {
            setLoadHandleSubmit(true)

            await api.post("/collaborator-request-admin/", {
                collaborator,
                category,
                startDate,
                endDate,
                vacationDaysSold,
                ThirteenthSalary,
                comments
            })
            .then(response => {
                loadcollaboratorvacationhistoryid(id)

                swal({ icon: "success", title: "Sucesso!", text: "Férias solicitadas com sucesso para este colaborador!" });
                setVisible(!visible);
            }).catch(error => {
                setLoadHandleSubmit(false)
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });

            });
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadVacation, setLoadVacation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationCompanyVestingPeriodSearchGroup, setVacationCompanyVestingPeriodSearchGroup ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationCollaboratorVestingPeriodSearch, setVacationCollaboratorVestingPeriodSearch ] = useState([]);

    async function loadCollaboratorVestingperiod(id) {

        setLoadVacation(true)
        await api.get(`/company-vesting-period?page=0&collaborator=${id}`)
        .then(response => {

            setLoadVacation(false)
            setVacationCompanyVestingPeriodSearchGroup(response.data.companyVestingPeriodSearchGroup[0])
            setVacationCollaboratorVestingPeriodSearch(response.data.collaboratorVestingPeriodSearch)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadVacationHistory, setLoadVacationHistory ] = useState(false);

      //////////////////////////////////////////////////////////////////////////////
     //////////////////      COLLABORATOR VACATION HISTORY      ///////////////////
    //////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorVacationHistory, setLoadCollaboratorVacationHistory ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorvacationHistory, setCollaboratorvacationHistory ] = useState([]);


    async function loadcollaboratorvacationhistoryid(id) {
        setLoadVacationHistory(true)
        setLoadCollaboratorVacationHistory(true)
        await api.get(`/collaborator-vacation-new/${id}`)
        .then(response => {
            setLoadCollaboratorVacationHistory(false)
            setLoadVacationHistory(false)
            setLoadVacationHistory(false)
            setCollaboratorvacationHistory(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    const [ isModalAddVacationRetroactive, setIsModalAddVacationRetroactive ] = useState(false);

    function openModalAddVacation() {
        setIsModalAddVacationRetroactive(true)
    }  

    function closeModalAddVacation() {
        setIsModalAddVacationRetroactive(false)
    }

    async function deleteVacationHistory(e) {
        swal({
            title: "Atenção",
            text: "Deseja realmente excluir as férias do histórico de férias?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            setLoadVacationHistory(true)
            setLoadVacation(true);
            setLoadCollaboratorVacationHistory(true);
            if (res) {
                await api.delete(`/collaborator-vacation-new/${e._id}`)
                .then(response => {
                    async function loadUpdateVestingPeriodFirstBalance() {
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {

                            loadCollaboratorVestingperiod(id);
                            loadcollaboratorvacationhistoryid(id);

                        }).catch(error => {

                            console.log(error)
                            swal({ icon: "error", title: "Erro!", text: "Férias deletada, porem os dias de férias dos períodos aquisitivos não foram atualizados, entre em contato com o administrador do sistema." });

                        });

                        setLoadCollaboratorVacationHistory(true);
                        setLoadVacationHistory(true);
                    }
                    loadUpdateVestingPeriodFirstBalance();

                }).catch(error => {});

            }
        });
    }
    function startLoadSave() {
        closeModalAddVacation()
        loadcollaboratorvacationhistoryid(id);
        loadCollaboratorVestingperiod(id)
    }

    return (
        <>  
            {
                isModalAddVacationRetroactive ?
                <ModalAddFerias 
                    show={isModalAddVacationRetroactive}
                    onHide={closeModalAddVacation}
                    idCol={id}
                    startLoadSave={() => startLoadSave()}
                />:""
            }
            <SubMenu id={id} collaborator={collaborator} role={role} path={path}/>
            <div className="box-infor-perfil box-infor-ferias">
                <VacationSummary
                    id={id}
                    loadHandleSubmit={loadHandleSubmit}
                    loadInitialDate={loadInitialDate}
                    loadFinalDate={loadFinalDate}
                    vacationSummary={vacationSummary}
                    visible={visible}
                    myChangeHandler={myChangeHandler}
                    handleSubmit={handleSubmit}
                    EditVacationSummaryVisible={EditVacationSummaryVisible}
                    loadVacation={loadVacation}
                    vacationCompanyVestingPeriodSearchGroup={vacationCompanyVestingPeriodSearchGroup}
                    vacationCollaboratorVestingPeriodSearch={vacationCollaboratorVestingPeriodSearch}
                    role={role}
                />
                <VacationHistory
                    collaborator={collaborator}
                    id={id}
                    loadVacationHistory={loadVacationHistory}
                    loadCollaboratorVacationHistory={loadCollaboratorVacationHistory}
                    collaboratorvacationHistory={collaboratorvacationHistory}
                    deleteVacationHistory={deleteVacationHistory}
                    role={role}

                    openModalAddVacation={openModalAddVacation}
                />
            </div>
        </>
    );
}

