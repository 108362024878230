import React from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Dropzone from "react-dropzone";
import Carregando from '../../../../assets/images/carregando.gif';
import Close from '../../../../assets/images/close.svg';
import { Modal} from "react-bootstrap";

const ColProfileAddress = ({
    id,
    loadPage,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    OnSubmit,
    loadContinue,
    PopupAddressVisible,
    visibleAddress,
    loadAddress,
    filesSavedAddress,
    handleDropAddress,
    deleteFileAddress
}) => {
    return (
        <>

            <div className="box endereco">
                    <h3>Endereço <button className="edit-Benefit" onClick={() => PopupAddressVisible()}><img src={EditBlack} alt="Edite Informações" /></button></h3>
                    {
                        loadPage ?
                        (
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregando} />
                            </div>
                            </>
                        ):(
                        <>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>CEP</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator && collaborator.zipcode ? collaborator.zipcode : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Rua ou avenida</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.address ? collaborator.address : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Número</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.addressNumber ? collaborator.addressNumber : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Bairro</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.addressNeighborhood ? collaborator.addressNeighborhood : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Estado</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.addressState ? collaborator.addressState : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Cidade</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.addressCity ? collaborator.addressCity : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Complemento</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.addressComplement ? collaborator.addressComplement : '-'}</p>
                                </div>
                            </li>

                            {filesSavedAddress[0] !== undefined ? (
                            <li className="li-anexo">
                                <div className="intemA">
                                    <p>Comprovante</p>
                                </div>

                                <div className="intemB  arq-adicionado">
                                {filesSavedAddress[0] !== undefined ?
                                filesSavedAddress.map((filesaved, index) => (
                                            <a href={filesaved.location} target="’_blank’" key={index}>
                                                {filesaved.name}
                                            </a>
                                )) : (
                                    <>
                                    <p>-</p>
                                    </>
                                )}

                                </div>
                            </li>
                            ) :('')}
                        </ul>

                    </>)}
                </div>
                <Modal show={visibleAddress} className="popup-edit-perfil" onHide={PopupAddressVisible} >
                    <div className="titulo">
                        <h4>Endereço <p className="btn-fechar" onClick={PopupAddressVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                            <p>CEP</p>
                            </div>
                            <div className="intemB">
                            <input
                            type="text"
                            maxlength="11"
                            name="zipcode"
                            defaultValue={
                                collaboratorUpdate && collaboratorUpdate.zipcode ?
                                collaboratorUpdate.zipcode :
                                collaborator.zipcode ?
                                collaborator.zipcode : ''

                            }
                            onChange={onCollaboratorInputChange}
                            placeholder="CEP"  />

                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Rua ou avenida</p>
                            </div>
                            <div className="intemB">
                                {
                                collaboratorUpdate && collaboratorUpdate.address !== undefined ?
                                (<input type="text" name="address" Value={collaboratorUpdate.address ? collaboratorUpdate.address : ''} onChange={onCollaboratorInputChange} autocomplete="autocompleteoff" className="inputCinco"/>) :
                                collaborator.address ?
                                (<input type="text" name="address" Value={collaborator.address ? collaborator.address : ''} onChange={onCollaboratorInputChange} autocomplete="autocompleteoff" placeholder="Rua ou avenida" className="inputCinco"/>) :
                                (<input type="text" name="address" autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="Rua ou avenida" className="inputCinco"/>)
                                }


                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Número</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="addressNumber" autocomplete="autocompleteoff" defaultValue={
                                    collaboratorUpdate && collaboratorUpdate.addressNumber ?
                                    collaboratorUpdate.addressNumber :
                                    collaborator.addressNumber ?
                                    collaborator.addressNumber : ''
                                    } onChange={onCollaboratorInputChange} placeholder="Número" className="inputQuatro"/>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Bairro</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="addressNeighborhood" autocomplete="autocompleteoff" Value={
                                    collaboratorUpdate && collaboratorUpdate.addressNeighborhood ?
                                    collaboratorUpdate.addressNeighborhood :
                                    collaborator.addressNeighborhood ?
                                    collaborator.addressNeighborhood : ''
                                    } onChange={onCollaboratorInputChange} placeholder="Bairro" className="inputSeis"/>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Estado</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="addressState" autocomplete="autocompleteoff" Value={
                                    collaboratorUpdate && collaboratorUpdate.addressState ?
                                    collaboratorUpdate.addressState :
                                    collaborator.addressState ?
                                    collaborator.addressState : ''
                                    } onChange={onCollaboratorInputChange} placeholder="Estado" className="inputSete"/>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Cidade</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="addressCity" autocomplete="autocompleteoff" Value={
                                    collaboratorUpdate && collaboratorUpdate.addressCity ?
                                    collaboratorUpdate.addressCity :
                                    collaborator.addressCity ?
                                    collaborator.addressCity : ''
                                    } onChange={onCollaboratorInputChange} placeholder="Cidade" className="inputOito"/>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Complemento</p>
                            </div>
                            <div className="intemB">
                                <input type="text" name="addressComplement" autocomplete="autocompleteoff" Value={
                                    collaboratorUpdate && collaboratorUpdate.addressComplement ?
                                    collaboratorUpdate.addressComplement :
                                    collaborator.addressComplement ?
                                    collaborator.addressComplement : ''
                                    } onChange={onCollaboratorInputChange} placeholder="Complemento" className="inputSeis"/>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <Dropzone onDrop={handleDropAddress} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>

                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </li>

                            {filesSavedAddress[0]  !== undefined ? (
                            <li>
                                <div className="arquivoAnexado">
                                    <h5>Enviados</h5>

                                    {filesSavedAddress[0] !== undefined ?
                                    filesSavedAddress.map((filesaved, index) => (
                                        <div key={index} className="arquivo">
                                            <p className="nomeArquivo">
                                                <a href={filesaved.location} target="’_blank’">
                                                    {filesaved.name}
                                                </a>
                                            </p>
                                            <button onClick={()=>deleteFileAddress(filesaved)} className="btnExclui">Remover anexo</button>
                                        </div>
                                    )) : ''}

                                </div>
                            </li>
                                    ) : ''}
                            {loadAddress ?
                            (<li><div className="carregando"><img src={Carregando} alt="carregando" /></div></li>) :
                            ('')}

                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={OnSubmit} className="btn-salvar">
                                    <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>
                    </ul>
                </Modal>

        </>
    );
}
export default ColProfileAddress;
