import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Header from '../../../components/User/registerDoc/header';
import HeaderSteps from '../../../components/User/registerDoc/headerAdmissionSteps';
import PreInformation from '../../../components/User/registerDoc/PreInformation';
import { toast } from 'react-toastify';
import Cpf from '../../../components/User/registerDoc/documents/cpf';
import Rg from '../../../components/User/registerDoc/documents/rg';
import Cnh from '../../../components/User/registerDoc/documents/cnh';
import CarteiraTrabalho from '../../../components/User/registerDoc/documents/carteiraTrabalho';
import TituloEleitor from '../../../components/User/registerDoc/documents/tituloEleitor';
import Reservista from '../../../components/User/registerDoc/documents/reservista';
import Banco from '../../../components/User/registerDoc/documents/banco';
import ExpiredToken from '../../Error/ExpiredToken'
import InvaliToken from '../../Error/InvaliToken'
import { mCPF, mNumber, mPisNumber, dateMask, mCNH, mSerialNumber, mVoterRegistration, mElectoralZone, mReservationCardNumber, mReservationCardCategory } from '../../../services/mask';
import swal from 'sweetalert';
import api from '../../../services/api';

export default function RegisterDoc(props) {

    const { history } = props;
    const { path }    = props.match;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtn, setLoadBtn ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);
    const [ tokenError, setTokenError ] = useState([]);

    const [ stepOne ]   = useState(false);
    const [ stepTwo ]   = useState(true);

    const { token } = props.match.params;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(token && token){
        async function loadcadastrocolaborador() {

            await api.get(`/collaborator-list/${token}`)
            .then(response => {
                setCollaborator(response.data);

            focusTop.current.focus();
            })
            .catch(error => {
                setTokenError(error.response.data);
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        loadcadastrocolaborador();}
    }, [token]);

    const focusTop                                            = useRef(null);

    const [ loadcpf                          , setLoadcpf                           ] = useState(false);
    const [ loadrg                           , setLoadrg                            ] = useState(false);
    const [ loadrgExpeditionDate             , setLoadrgExpeditionDate              ] = useState(false);
    const [ loadrgOrgan                      , setLoadrgOrgan                       ] = useState(false);
    const [ loadufOrgan                      , setLoadufOrgan                       ] = useState(false);
    const [ loadcnh                          , setLoadcnh                           ] = useState(false);
    const [ loadcnhCategory                  , setLoadcnhCategory                   ] = useState(false);
    const [ loadcnhExpeditionDate            , setLoadcnhExpeditionDate             ] = useState(false);
    const [ loadufCNH                        , setLoadufCNH                         ] = useState(false);
    const [ loadwalletNumber                 , setLoadwalletNumber                  ] = useState(false);
    const [ loadserialNumber                 , setLoadserialNumber                  ] = useState(false);
    const [ loadissueDate                    , setLoadissueDate                     ] = useState(false);
    const [ loadwalletUF                     , setLoadwalletUF                      ] = useState(false);
    const [ loadpisNumber                    , setLoadpisNumber                     ] = useState(false);
    const [ loadvoterRegistration            , setLoadvoterRegistration             ] = useState(false);
    const [ loadelectoralZone                , setLoadelectoralZone                 ] = useState(false);
    const [ loadpollingStation               , setLoadpollingStation                ] = useState(false);
    const [ loadreservationCardNumber        , setLoadreservationCardNumber         ] = useState(false);
    const [ loadreservationCardSeries        , setLoadreservationCardSeries         ] = useState(false);
    const [ loadreservationCardCategory      , setLoadreservationCardCategory       ] = useState(false);
    const [ loadreservationCardExpeditionDate, setLoadreservationCardExpeditionDate ] = useState(false);
    const [ loadufReservationCard            , setLoadufReservationCard             ] = useState(false);
    const [ loadcityReservationCard          , setLoadcityReservationCard           ] = useState(false);
    const [ loadbankCode                     , setLoadbankCode                      ] = useState(false);
    const [ loadbankAgency                   , setLoadbankAgency                    ] = useState(false);
    const [ loadbankAccount                  , setLoadbankAccount                   ] = useState(false);
    const [                 , setLoadaccountDigit                  ] = useState(false);
    const [ loadAccountType                  , setLoadAccountType                   ] = useState(false);
    const [ loadpix                          , setLoadpix                           ] = useState(false);



    const focuscpf                                            = useRef(null);
    const focusrg                                             = useRef(null);
    const focusrgExpeditionDate                               = useRef(null);
    const focusrgOrgan                                        = useRef(null);
    const focusufOrgan                                        = useRef(null);
    const focuscnh                                            = useRef(null);
    const focuscnhCategory                                    = useRef(null);
    const focuscnhExpeditionDate                              = useRef(null);
    const focusufCNH                                          = useRef(null);
    const focuswalletNumber                                   = useRef(null);
    const focusserialNumber                                   = useRef(null);
    const focusissueDate                                      = useRef(null);
    const focuswalletUF                                       = useRef(null);
    const focuspisNumber                                      = useRef(null);
    const focusvoterRegistration                              = useRef(null);
    const focuselectoralZone                                  = useRef(null);
    const focuspollingStation                                 = useRef(null);
    const focusreservationCardNumber                          = useRef(null);
    const focusreservationCardSeries                          = useRef(null);
    const focusreservationCardCategory                        = useRef(null);
    const focusreservationCardExpeditionDate                  = useRef(null);
    const focusufReservationCard                              = useRef(null);
    const focuscityReservationCard                            = useRef(null);
    const focusbankCode                                       = useRef(null);
    const focusbankAgency                                     = useRef(null);
    const focusbankAccount                                    = useRef(null);
    // const focusaccountDigit                                   = useRef(null);
    const focusAccountType                                    = useRef(null);
    const focuspix                                            = useRef(null);



     async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        // setCollaborator({ ...collaborator, [nam]: val })

            if (nam === "cpf") {

                let cpf = mCPF(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cpf, path: path })
                if(val.length >= 14) {
                    setLoadcpf(false)
                }

            }
            else if (nam === "rg") {

                let rg = mNumber(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rg, path: path })
                if(val.length >= 10) {
                    setLoadrg(false)
                }

            }
            else if (nam === "rgExpeditionDate") {
            // alert("entrou")
                let rgExpeditionDate = dateMask(val)
                if(val.length >= 10) {
                    setLoadrgExpeditionDate(false)
                }
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: rgExpeditionDate, path: path })

            }
            //rgOrgan
            else if(nam === 'rgOrgan') {
                if(val === 'Selecione') {
                    setLoadrgOrgan(true)
                    let valrgOrgan = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valrgOrgan })
                } else {
                    setLoadrgOrgan(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            //ufOrgan
            else if(nam === 'ufOrgan') {
                if(val === 'Selecione') {
                    setLoadufOrgan(true)
                    let valufOrgan = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valufOrgan })
                } else {
                    setLoadufOrgan(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            // cnh
            else if (nam === "cnh") {
                let cnh = mCNH(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnh, path: path })

            }
            // cnhExpeditionDate
            else if (nam === "cnhExpeditionDate") {
                let cnhExpeditionDate = dateMask(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: cnhExpeditionDate, path: path })

            }
            //walletNumber
            else if (nam === "walletNumber") {
                let walletNumber = mNumber(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: walletNumber, path: path })

                if(val.length >= 11) {
                    setLoadwalletNumber(false)
                }

            }
            //serialNumber
            else if (nam === "serialNumber") {

                if(val.length >= 4) {
                    setLoadserialNumber(false)
                }
                let serialNumber = mSerialNumber(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: serialNumber, path: path })

            }
            //issueDate
            else if (nam === "issueDate") {

                if(val.length >= 10) {
                    setLoadissueDate(false)
                }
                let issueDate = dateMask(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: issueDate, path: path })

            }
            //walletUF
            else if(nam === 'walletUF') {
                if(val === 'Selecione') {
                    setLoadwalletUF(true)
                    let valwalletUF = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valwalletUF })
                } else {
                    setLoadwalletUF(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            //pisNumber
            else if (nam === "pisNumber") {
                let pisNumber = mPisNumber(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pisNumber, path: path })
                if(val.length >= 13) {
                    setLoadpisNumber(false)
                }

            }
            //voterRegistration
            else if (nam === "voterRegistration") {

                if(val.length >= 14) {
                    setLoadvoterRegistration(false)
                }
                let voterRegistration = mVoterRegistration(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: voterRegistration, path: path })

            }
            //electoralZone
            else if (nam === "electoralZone") {
                let electoralZone = mElectoralZone(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: electoralZone, path: path })

                if(val.length >= 3) {
                    setLoadelectoralZone(false)
                }

            }
            //pollingStation
            else if (nam === "pollingStation") {

                if(val.length >= 4) {
                    setLoadpollingStation(false)
                }
                let pollingStation = mSerialNumber(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: pollingStation, path: path })

            }
            //reservationCardNumber
            else if (nam === "reservationCardNumber") {
                let reservationCardNumber = mReservationCardNumber(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardNumber, path: path })

                if(val.length >= 12) {
                    setLoadreservationCardNumber(false)
                }

            }
            //reservationCardSeries
            else if (nam === "reservationCardSeries") {

                if(val.length >= 2) {
                    setLoadreservationCardSeries(false)
                }
                let reservationCardSeries = mReservationCardCategory(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardSeries, path: path })


            }
            //reservationCardCategory
            else if (nam === "reservationCardCategory") {

                if(val.length >= 2) {
                    setLoadreservationCardCategory(false)
                }
                let reservationCardCategory = mReservationCardCategory(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardCategory, path: path })

            }
            //reservationCardExpeditionDate
            else if (nam === "reservationCardExpeditionDate") {

                if(val.length >= 10) {
                    setLoadreservationCardExpeditionDate(false)
                }
                let reservationCardExpeditionDate = dateMask(val)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: reservationCardExpeditionDate, path: path })

            }
            //ufReservationCard
            else if(nam === 'ufReservationCard') {
                if(val === 'Selecione') {
                    setLoadufReservationCard(true)
                    let valufReservationCard = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valufReservationCard })
                } else {
                    setLoadufReservationCard(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            //cityReservationCard
            else if(nam === 'cityReservationCard') {
                if(val === 'Selecione') {
                    setLoadcityReservationCard(true)
                    let valcityReservationCard = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valcityReservationCard })
                } else {
                    setLoadcityReservationCard(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            //bankCode
            else if(nam === 'bankCode') {
                if(val === 'Selecione o banco') {
                    setLoadbankCode(true)
                    let valbankCode = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valbankCode })
                } else {
                    setLoadbankCode(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            else if (nam === "bankAgency") {

                setLoadbankAgency(false)
                let value = event.target.value.replace(/[^\d]/g, "")
                //console.log(value.length)
                if(value.length > 10) {
                    setCollaboratorUpdate({ ...collaboratorUpdate })
                } else {
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: value, path: path })
                }

            }
            else if (nam === "bankAccount") {

                setLoadbankAccount(false)
                let value = event.target.value.replace(/[^\d]/g, "")
                if(value.length > 20) {
                    //alert('entrou')
                    setCollaboratorUpdate({ ...collaboratorUpdate })
                } else {
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: value, path: path })
                }

            }
            //AccountType
            else if(nam === 'AccountType') {
                if(val === 'Selecione') {
                    setLoadAccountType(true)
                    let valAccountType = undefined;
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valAccountType })
                } else {
                    setLoadAccountType(false)
                    setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
                }
            }
            //pix
            else if(nam === 'pix') {

                setLoadpix(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })

            }
            else {
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val, path: path })
            }
    };

    async function saveFillInLater(event) {
        event.preventDefault();

        if(
            collaboratorUpdate.cpf                           !== undefined ||
            collaboratorUpdate.foreign                       !== undefined ||
            collaboratorUpdate.rg                            !== undefined ||
            collaboratorUpdate.rgExpeditionDate              !== undefined ||
            collaboratorUpdate.rgOrgan                       !== undefined ||
            collaboratorUpdate.ufOrgan                       !== undefined ||
            collaboratorUpdate.cnh                           !== undefined ||
            collaboratorUpdate.cnhCategory                   !== undefined ||
            collaboratorUpdate.cnhExpeditionDate             !== undefined ||
            collaboratorUpdate.ufCNH                         !== undefined ||
            collaboratorUpdate.walletNumber                  !== undefined ||
            collaboratorUpdate.serialNumber                  !== undefined ||
            collaboratorUpdate.issueDate                     !== undefined ||
            collaboratorUpdate.walletUF                      !== undefined ||
            collaboratorUpdate.pisNumber                     !== undefined ||
            collaboratorUpdate.voterRegistration             !== undefined ||
            collaboratorUpdate.electoralZone                 !== undefined ||
            collaboratorUpdate.pollingStation                !== undefined ||
            collaboratorUpdate.reservationCardNumber         !== undefined ||
            collaboratorUpdate.reservationCardSeries         !== undefined ||
            collaboratorUpdate.reservationCardCategory       !== undefined ||
            collaboratorUpdate.reservationCardExpeditionDate !== undefined ||
            collaboratorUpdate.ufReservationCard             !== undefined ||
            collaboratorUpdate.cityReservationCard           !== undefined ||
            collaboratorUpdate.bankName                      !== undefined ||
            collaboratorUpdate.bankCode                      !== undefined ||
            collaboratorUpdate.bankAgency                    !== undefined ||
            collaboratorUpdate.bankAccount                   !== undefined ||
            // collaboratorUpdate.accountDigit                  !== undefined ||
            collaboratorUpdate.AccountType                   !== undefined ||
            collaboratorUpdate.pix                           !== undefined
        ) {
            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(collaboratorUpdate.cpf !== undefined && collaboratorUpdate.cpf !== "") {
                setLoadcpf(false)
                formData.append('cpf', collaboratorUpdate.cpf);
            }

            if(collaboratorUpdate.foreign !== undefined) {
                formData.append('foreign', collaboratorUpdate.foreign);
            } else {
                formData.append('foreign', 'nao');
            }

            if(collaboratorUpdate.rg !== undefined && collaboratorUpdate.rg !== "") {
                setLoadrg(false)
                formData.append('rg', collaboratorUpdate.rg);
            }

            if(collaboratorUpdate.rgExpeditionDate !== undefined && collaboratorUpdate.rgExpeditionDate !== "") {
                setLoadrgExpeditionDate(false)
                formData.append('rgExpeditionDate', collaboratorUpdate.rgExpeditionDate);
            }

            if(collaboratorUpdate.rgOrgan !== undefined && collaboratorUpdate.rgOrgan !== "") {
                setLoadrgOrgan(false)
                formData.append('rgOrgan', collaboratorUpdate.rgOrgan);
            }

            if(collaboratorUpdate.ufOrgan !== undefined && collaboratorUpdate.ufOrgan !== "") {
                setLoadufOrgan(false)
                formData.append('ufOrgan', collaboratorUpdate.ufOrgan);
            }

            if(collaboratorUpdate.cnh !== undefined && collaboratorUpdate.cnh !== "") {
                setLoadcnh(false)
                formData.append('cnh', collaboratorUpdate.cnh);
            }

            if(collaboratorUpdate.cnhCategory !== undefined && collaboratorUpdate.cnhCategory !== "") {
                setLoadcnhCategory(false)
                formData.append('cnhCategory', collaboratorUpdate.cnhCategory);
            }

            if(collaboratorUpdate.cnhExpeditionDate !== undefined && collaboratorUpdate.cnhExpeditionDate !== "") {
                setLoadcnhExpeditionDate(false)
                formData.append('cnhExpeditionDate', collaboratorUpdate.cnhExpeditionDate);
            }

            if(collaboratorUpdate.ufCNH !== undefined && collaboratorUpdate.ufCNH !== "") {
                setLoadufCNH(false)
                formData.append('ufCNH', collaboratorUpdate.ufCNH);
            }


            if(collaboratorUpdate.walletNumber !== undefined && collaboratorUpdate.walletNumber !== "") {
                setLoadwalletNumber(false)
                formData.append('walletNumber', collaboratorUpdate.walletNumber);
            }

            if(collaboratorUpdate.serialNumber !== undefined && collaboratorUpdate.serialNumber !== "") {
                setLoadserialNumber(false)
                formData.append('serialNumber', collaboratorUpdate.serialNumber);
            }

            if(collaboratorUpdate.issueDate !== undefined && collaboratorUpdate.issueDate !== "") {
                setLoadissueDate(false)
                formData.append('issueDate', collaboratorUpdate.issueDate);
            }

            if(collaboratorUpdate.walletUF !== undefined && collaboratorUpdate.walletUF !== "") {
                setLoadwalletUF(false)
                formData.append('walletUF', collaboratorUpdate.walletUF);
            }

            if(collaboratorUpdate.pisNumber !== undefined && collaboratorUpdate.pisNumber !== "") {
                setLoadpisNumber(false)
                formData.append('pisNumber', collaboratorUpdate.pisNumber);
            }


            if(collaboratorUpdate.voterRegistration !== undefined && collaboratorUpdate.voterRegistration !== "") {
                setLoadvoterRegistration(false)
                formData.append('voterRegistration', collaboratorUpdate.voterRegistration);
            }

            if(collaboratorUpdate.electoralZone !== undefined && collaboratorUpdate.electoralZone !== "") {
                setLoadelectoralZone(false)
                formData.append('electoralZone', collaboratorUpdate.electoralZone);
            }

            if(collaboratorUpdate.pollingStation !== undefined && collaboratorUpdate.pollingStation !== "") {
                setLoadpollingStation(false)
                formData.append('pollingStation', collaboratorUpdate.pollingStation);
            }

            if(collaborator && collaborator.gender === 'Masculino') {

                if(collaboratorUpdate.reservationCardNumber !== undefined && collaboratorUpdate.reservationCardNumber !== "") {
                    setLoadreservationCardNumber(false)
                    formData.append('reservationCardNumber', collaboratorUpdate.reservationCardNumber);
                }

                if(collaboratorUpdate.reservationCardSeries !== undefined && collaboratorUpdate.reservationCardSeries !== "") {
                    setLoadreservationCardSeries(false)
                    formData.append('reservationCardSeries', collaboratorUpdate.reservationCardSeries);
                }

                if(collaboratorUpdate.reservationCardCategory !== undefined && collaboratorUpdate.reservationCardCategory !== "") {
                    setLoadreservationCardCategory(false)
                    formData.append('reservationCardCategory', collaboratorUpdate.reservationCardCategory);
                }

                if(collaboratorUpdate.reservationCardExpeditionDate !== undefined && collaboratorUpdate.reservationCardExpeditionDate !== "") {
                    setLoadreservationCardExpeditionDate(false)
                    formData.append('reservationCardExpeditionDate', collaboratorUpdate.reservationCardExpeditionDate);
                }

                if(collaboratorUpdate.ufReservationCard !== undefined && collaboratorUpdate.ufReservationCard !== "") {
                    setLoadufReservationCard(false)
                    formData.append('ufReservationCard', collaboratorUpdate.ufReservationCard);
                }

                if(collaboratorUpdate.cityReservationCard !== undefined && collaboratorUpdate.cityReservationCard !== "") {
                    setLoadcityReservationCard(false)
                    formData.append('cityReservationCard', collaboratorUpdate.cityReservationCard);
                }

            }

            if(collaboratorUpdate.bankName !== undefined && collaboratorUpdate.bankName !== "") {
                setLoadbankCode(false)
                formData.append('bankName', collaboratorUpdate.bankName);
            }

            if(collaboratorUpdate.bankCode !== undefined && collaboratorUpdate.bankCode !== "") {
                setLoadbankCode(false)
                formData.append('bankCode', collaboratorUpdate.bankCode);
            }

            if(collaboratorUpdate.bankAgency !== undefined && collaboratorUpdate.bankAgency !== "") {
                setLoadbankAgency(false)
                formData.append('bankAgency', collaboratorUpdate.bankAgency);
            }

            if(collaboratorUpdate.bankAccount !== undefined && collaboratorUpdate.bankAccount !== "") {
                setLoadbankAccount(false)
                formData.append('bankAccount', collaboratorUpdate.bankAccount);
            }

            if(collaboratorUpdate.accountDigit !== undefined && collaboratorUpdate.accountDigit !== "") {
                // setLoadaccountDigit(false)
                formData.append('accountDigit', collaboratorUpdate.accountDigit);
            }

            if(collaboratorUpdate.AccountType !== undefined && collaboratorUpdate.AccountType !== "") {
                setLoadAccountType(false)
                formData.append('AccountType', collaboratorUpdate.AccountType);
            }

            if(collaboratorUpdate.pix !== undefined && collaboratorUpdate.pix !== "") {
                setLoadpix(false)
                formData.append('pix', collaboratorUpdate.pix);
            }
            formData.append('path', path);

            setLoadBtn(true)

            await api.put(`/collaborator-update/${token}`, formData, config)
            .then(response => {
                async function loadcollaborator() {
                    await api.get(`/collaborator-list/${token}`)
                    .then(response => {
                        setLoadBtn(false)
                        setCollaborator(response.data);
                        setTimeout(function(){history.push(`/cadastro-colaborador/progresso-salvo/${token}`);}, 0);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadcollaborator();
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadBtn(false)
            });


        } else {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo" });
        }
    }





    async function save(event) {
        event.preventDefault();

            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(collaboratorUpdate.cpf !== undefined && collaboratorUpdate.cpf !== "") {
                setLoadcpf(false)
                formData.append('cpf', collaboratorUpdate.cpf);
            }
            else if(
                collaborator             &&
                collaborator.cpf         !== undefined
            ) {
                setLoadcpf(false)
            }
            else {
                setLoadcpf(true)
                focuscpf.current.focus();

            }


            if(collaboratorUpdate.foreign !== undefined) {
                formData.append('foreign', collaboratorUpdate.foreign);
            } else {
                formData.append('foreign', 'nao');
            }

            if(collaboratorUpdate.rg !== undefined && collaboratorUpdate.rg !== "") {
                setLoadrg(false)
                formData.append('rg', collaboratorUpdate.rg);
            }
            else if(
                collaborator             &&
                collaborator.rg         !== undefined
            ) {
                setLoadrg(false)
            }
            else {
                setLoadrg(true)
                focusrg.current.focus();

            }

            if(collaboratorUpdate.rgExpeditionDate !== undefined && collaboratorUpdate.rgExpeditionDate !== "") {
                setLoadrgExpeditionDate(false)
                formData.append('rgExpeditionDate', collaboratorUpdate.rgExpeditionDate);
            }
            else if(
                collaborator             &&
                collaborator.rgExpeditionDate         !== undefined
            ) {
                setLoadrgExpeditionDate(false)
            }
            else {
                setLoadrgExpeditionDate(true)
                focusrgExpeditionDate.current.focus();

            }

            if(collaboratorUpdate.rgOrgan !== undefined && collaboratorUpdate.rgOrgan !== "") {
                setLoadrgOrgan(false)
                formData.append('rgOrgan', collaboratorUpdate.rgOrgan);
            }
            else if(
                collaborator             &&
                collaborator.rgOrgan         !== undefined
            ) {
                setLoadrgOrgan(false)
            }
            else {
                setLoadrgOrgan(true)
                focusrgOrgan.current.focus();

            }

            if(collaboratorUpdate.ufOrgan !== undefined && collaboratorUpdate.ufOrgan !== "") {
                setLoadufOrgan(false)
                formData.append('ufOrgan', collaboratorUpdate.ufOrgan);
            }
            else if(
                collaborator             &&
                collaborator.ufOrgan         !== undefined
            ) {
                setLoadufOrgan(false)
            }
            else {
                setLoadufOrgan(true)
                focusufOrgan.current.focus();

            }

            if(collaboratorUpdate.cnh !== undefined && collaboratorUpdate.cnh !== "") {
                setLoadcnh(false)
                formData.append('cnh', collaboratorUpdate.cnh);
            }

            if(collaboratorUpdate.cnhCategory !== undefined && collaboratorUpdate.cnhCategory !== "") {
                setLoadcnhCategory(false)
                formData.append('cnhCategory', collaboratorUpdate.cnhCategory);
            }

            if(collaboratorUpdate.cnhExpeditionDate !== undefined && collaboratorUpdate.cnhExpeditionDate !== "") {
                setLoadcnhExpeditionDate(false)
                formData.append('cnhExpeditionDate', collaboratorUpdate.cnhExpeditionDate);
            }

            if(collaboratorUpdate.ufCNH !== undefined && collaboratorUpdate.ufCNH !== "") {
                setLoadufCNH(false)
                formData.append('ufCNH', collaboratorUpdate.ufCNH);
            }


            if(collaboratorUpdate.walletNumber !== undefined && collaboratorUpdate.walletNumber !== "") {
                setLoadwalletNumber(false)
                formData.append('walletNumber', collaboratorUpdate.walletNumber);
            }

            if(collaboratorUpdate.serialNumber !== undefined && collaboratorUpdate.serialNumber !== "") {
                setLoadserialNumber(false)
                formData.append('serialNumber', collaboratorUpdate.serialNumber);
            }

            if(collaboratorUpdate.issueDate !== undefined && collaboratorUpdate.issueDate !== "") {
                setLoadissueDate(false)
                formData.append('issueDate', collaboratorUpdate.issueDate);
            }

            if(collaboratorUpdate.walletUF !== undefined && collaboratorUpdate.walletUF !== "") {
                setLoadwalletUF(false)
                formData.append('walletUF', collaboratorUpdate.walletUF);
            }

            if(collaboratorUpdate.pisNumber !== undefined && collaboratorUpdate.pisNumber !== "") {
                setLoadpisNumber(false)
                formData.append('pisNumber', collaboratorUpdate.pisNumber);
            }

            if(collaboratorUpdate.voterRegistration !== undefined && collaboratorUpdate.voterRegistration !== "") {
                setLoadvoterRegistration(false)
                formData.append('voterRegistration', collaboratorUpdate.voterRegistration);
            }
            else if(
                collaborator             &&
                collaborator.voterRegistration         !== undefined
            ) {
                setLoadvoterRegistration(false)
            }
            else {
                setLoadvoterRegistration(true)
                focusvoterRegistration.current.focus();

            }

            if(collaboratorUpdate.electoralZone !== undefined && collaboratorUpdate.electoralZone !== "") {
                setLoadelectoralZone(false)
                formData.append('electoralZone', collaboratorUpdate.electoralZone);
            }
            else if(
                collaborator             &&
                collaborator.electoralZone         !== undefined
            ) {
                setLoadelectoralZone(false)
            }
            else {
                setLoadelectoralZone(true)
                focuselectoralZone.current.focus();

            }

            if(collaboratorUpdate.pollingStation !== undefined && collaboratorUpdate.pollingStation !== "") {
                setLoadpollingStation(false)
                formData.append('pollingStation', collaboratorUpdate.pollingStation);
            }
            else if(
                collaborator             &&
                collaborator.pollingStation         !== undefined
            ) {
                setLoadpollingStation(false)
            }
            else {
                setLoadpollingStation(true)
                focuspollingStation.current.focus();

            }

            if(collaborator && collaborator.gender === 'Masculino') {

                if(collaboratorUpdate.reservationCardNumber !== undefined && collaboratorUpdate.reservationCardNumber !== "") {
                    setLoadreservationCardNumber(false)
                    formData.append('reservationCardNumber', collaboratorUpdate.reservationCardNumber);
                }

                if(collaboratorUpdate.reservationCardSeries !== undefined && collaboratorUpdate.reservationCardSeries !== "") {
                    setLoadreservationCardSeries(false)
                    formData.append('reservationCardSeries', collaboratorUpdate.reservationCardSeries);
                }

                if(collaboratorUpdate.reservationCardCategory !== undefined && collaboratorUpdate.reservationCardCategory !== "") {
                    setLoadreservationCardCategory(false)
                    formData.append('reservationCardCategory', collaboratorUpdate.reservationCardCategory);
                }

                if(collaboratorUpdate.reservationCardExpeditionDate !== undefined && collaboratorUpdate.reservationCardExpeditionDate !== "") {
                    setLoadreservationCardExpeditionDate(false)
                    formData.append('reservationCardExpeditionDate', collaboratorUpdate.reservationCardExpeditionDate);
                }

                if(collaboratorUpdate.ufReservationCard !== undefined && collaboratorUpdate.ufReservationCard !== "") {
                    setLoadufReservationCard(false)
                    formData.append('ufReservationCard', collaboratorUpdate.ufReservationCard);
                }

                if(collaboratorUpdate.cityReservationCard !== undefined && collaboratorUpdate.cityReservationCard !== "") {
                    setLoadcityReservationCard(false)
                    formData.append('cityReservationCard', collaboratorUpdate.cityReservationCard);
                }

            }

            if(collaboratorUpdate.bankName !== undefined && collaboratorUpdate.bankName !== "") {
                setLoadbankCode(false)
                formData.append('bankName', collaboratorUpdate.bankName);
            }
            else if(
                collaborator             &&
                collaborator.bankName         !== undefined && collaboratorUpdate.bankName !== ""
            ) {
                setLoadbankCode(false)
            }
            else {
                setLoadbankCode(true)
                focusbankCode.current.focus();

            }

            if(collaboratorUpdate.bankAgency !== undefined && collaboratorUpdate.bankAgency !== "") {
                setLoadbankAgency(false)
                formData.append('bankAgency', collaboratorUpdate.bankAgency);
            }
            else if(
                collaborator             &&
                collaborator.bankAgency         !== undefined
            ) {
                setLoadbankAgency(false)
            }
            else {
                setLoadbankAgency(true)
                focusbankAgency.current.focus();

            }

            if(collaboratorUpdate.bankAccount !== undefined && collaboratorUpdate.bankAccount !== "") {
                setLoadbankAccount(false)
                formData.append('bankAccount', collaboratorUpdate.bankAccount);
            }
            else if(
                collaborator             &&
                collaborator.bankAccount         !== undefined
            ) {
                setLoadbankAccount(false)
            }
            else {
                setLoadbankAccount(true)
                focusbankAccount.current.focus();

            }

            if(collaboratorUpdate.accountDigit !== undefined && collaboratorUpdate.accountDigit !== "") {
                setLoadaccountDigit(false)
                formData.append('accountDigit', collaboratorUpdate.accountDigit);
            }
            else if(
                collaborator             &&
                collaborator.accountDigit         !== undefined
            ) {
                setLoadaccountDigit(false)
            }

            if(collaboratorUpdate.AccountType !== undefined && collaboratorUpdate.AccountType !== "") {
                setLoadAccountType(false)
                formData.append('AccountType', collaboratorUpdate.AccountType);
            }
            else if(
                collaborator             &&
                collaborator.AccountType         !== undefined
            ) {
                setLoadAccountType(false)
            }
            else {
                setLoadAccountType(true)
                setOpenSelAccountType(true)
                focusAccountType.current.focus();

            }

            if(collaboratorUpdate.pix !== undefined && collaboratorUpdate.pix !== "") {
                setLoadpix(false)
                formData.append('pix', collaboratorUpdate.pix);
            }
            else if(
                collaborator             &&
                collaborator.pix         !== undefined
            ) {
                setLoadpix(false)
            }
            else {
                setLoadpix(true)
                focuspix.current.focus();

            }

            if(collaboratorUpdate.stepContinue !== undefined && collaboratorUpdate.stepContinue === "true") {
                setLoadContinue(true)
                formData.append('stepOne', true)
                formData.append('stepTwo', true)
                formData.append('stepThree', true)
                formData.append('stepFour', false)
                formData.append('collaboratorEdition', false);
                formData.append('stepProcessTwo', 'preencher_manualmente')
            } else {
                setLoad(true)
            }
            if(
                (
                    collaboratorUpdate.cpf                           !== undefined ||
                    collaborator.cpf                                 !== undefined
                )&&
                (
                    collaboratorUpdate.rg                           !== undefined ||
                    collaborator.rg                                 !== undefined
                )&&
                (
                    collaboratorUpdate.rgExpeditionDate                           !== undefined ||
                    collaborator.rgExpeditionDate                                 !== undefined
                )&&
                (
                    collaboratorUpdate.rgOrgan                           !== undefined ||
                    collaborator.rgOrgan                                 !== undefined
                )&&
                (
                    collaboratorUpdate.ufOrgan                           !== undefined ||
                    collaborator.ufOrgan                                 !== undefined
                )&&
                (
                    collaboratorUpdate.voterRegistration                           !== undefined ||
                    collaborator.voterRegistration                                 !== undefined
                )&&
                (
                    collaboratorUpdate.electoralZone                           !== undefined ||
                    collaborator.electoralZone                                 !== undefined
                )&&
                (
                    collaboratorUpdate.pollingStation                           !== undefined ||
                    collaborator.pollingStation                                 !== undefined
                )&&
                (
                    collaboratorUpdate.bankName                           !== undefined ||
                    collaborator.bankName                                 !== undefined
                )&&
                (
                    collaboratorUpdate.bankAgency                           !== undefined ||
                    collaborator.bankAgency                                 !== undefined
                )&&
                (
                    collaboratorUpdate.bankAccount                           !== undefined ||
                    collaborator.bankAccount                                 !== undefined
                )&&
                (
                    collaboratorUpdate.AccountType                           !== undefined ||
                    collaborator.AccountType                                 !== undefined
                )&&
                (
                    collaboratorUpdate.pix                           !== undefined ||
                    collaborator.pix                                 !== undefined
                )
            ) {

                formData.append('stepsNewAdmission', 4);
                formData.append('path', path);
                await api.put(`/collaborator-update/${token}`, formData, config)
                .then(response => {
                    async function loadcollaborator() {
                        await api.get(`/collaborator-list/${token}`)
                        .then(response => {
                            if(collaboratorUpdate.stepContinue !== undefined && collaboratorUpdate.stepContinue === "true") {
                                setLoadContinue(false)
                                setTimeout(function(){history.push(`/cadastro-colaborador/dados-enviados/${token}`);}, 1000);
                            } else {
                                setLoad(false)
                            }
                            setCollaborator(response.data);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadcollaborator();
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadContinue(false)
                    setLoad(false)
                });
            } else {
                // swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo" });
                setLoadContinue(false)
                setLoad(false)
                return
            }

    }

    async function backFile() {
        //window.location.href = `/cadastro-colaborador/${token}`;
        setTimeout(function(){history.push(`/cadastro-colaborador/${token}`);}, 1000);
    }


      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// AVATAR /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    //setLoadAvatar
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatar(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {
            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('path', path);
            setLoadAvatar(true)
            await api.put(`/collaborator-update/${token}`, formData, config)
            .then(response => {
                setLoadAvatar(false)
                async function loadcadastrocolaborador() {
                    await api.get(`/collaborator-list/${token}`)
                    .then(response => {
                        setCollaborator(response.data);
                    })
                    .catch(error => {
                        setTokenError(error.response.data);
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadcadastrocolaborador();
                swal({ icon: "success", title: "Sucesso!", text: "Colaborador alterado com sucesso!" });
                // }

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }
    //console.log(collaboratorUpdate && collaboratorUpdate ? collaboratorUpdate : '-')

    ///////////////////////////////////////////////////////
    /////////////////// BANCO SELECT /////////////////////
    /////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ openSelAccountType, setOpenSelAccountType ] = useState(false);
    async function selectAccountTypeOpen(){
            setOpenSelAccountType(true)
    }
    async function myChangeHandlerAccountType(event) {
        let nam = event;
        setCollaboratorUpdate({ ...collaboratorUpdate, "AccountType": nam})
        setOpenSelAccountType(!openSelAccountType)
        setLoadAccountType(false)
   }

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ abrirResultBank, setAbrirResultBank ] = useState(false);

   async function openAccountType(e) {
        if(e.target.className !== "btn-select selectAccountType" && e.target.id !== "myBank" ) {
            setOpenSelAccountType(false)
            setAbrirResultBank(false)
        }
    }

    function mySearchBank(event) {
        // Declare variables
        event.preventDefault();

        let nam = event.target.name;
        let val = event.target.value;

        setAbrirResultBank(true)
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('myBank');
        filter = input.value.toUpperCase();
        ul = document.getElementById("resultadoBusca");
        // console.log('ul')
        // console.log(ul)
        if(ul !== null) {
            li = ul.getElementsByTagName('button');
            // Loop through all list items, and hide those who don't match the search query
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("p")[0];
                // console.log(a)
                 if(a && a !== null ) {
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                    } else {
                    li[i].style.display = "none";
                    }
                }
            }
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            return
        }

    }


    function abrirResultado() {
        setAbrirResultBank(!abrirResultBank)
        setOpenSelAccountType(false)
    }

   async function myChangeHandlerToSaveBank(event) {
        setCollaboratorUpdate({ ...collaboratorUpdate, "bankName": event})
        setAbrirResultBank(false)
        setLoadbankCode(false)
    }


    const estadosOrgam = [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins"
    ]

    return (
        <>
        <div className="pagina-Token">

            <div  id="select-todas" onClick={openAccountType}>
            {tokenError.message !== undefined ? (
                    <InvaliToken />
                ) : tokenError.error !== undefined ? (
                    <ExpiredToken />
                ) : (
                <>
                <Header/>
                <div className="solicitacao-cadastro">
                <HeaderSteps token={token} stepOne={stepOne} stepTwo={stepTwo} focusTop={focusTop} />
                <PreInformation
                collaborator={collaborator}
                handleDropAvatar={handleDropAvatar}
                loadAvatar={loadAvatar}
                />
                <Cpf
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                loadcpf={loadcpf}
                focuscpf={focuscpf}
                path={path}
                />
                <Rg
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                setCollaboratorUpdate={setCollaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                focusrg={focusrg}
                loadrg={loadrg}
                loadrgExpeditionDate={loadrgExpeditionDate}
                loadrgOrgan={loadrgOrgan}
                loadufOrgan={loadufOrgan}
                setLoadufOrgan={setLoadufOrgan}
                focusrgExpeditionDate={focusrgExpeditionDate}
                focusrgOrgan={focusrgOrgan}
                focusufOrgan={focusufOrgan}
                estadosOrgam={estadosOrgam}
                path={path}
                />
                <Cnh
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                loadcnh               ={loadcnh              }
                loadcnhCategory       ={loadcnhCategory      }
                loadcnhExpeditionDate ={loadcnhExpeditionDate}
                loadufCNH             ={loadufCNH            }
                focuscnh              ={focuscnh              }
                focuscnhCategory      ={focuscnhCategory      }
                focuscnhExpeditionDate={focuscnhExpeditionDate}
                focusufCNH            ={focusufCNH            }
                estadosOrgam={estadosOrgam}
                path={path}
                setCollaboratorUpdate={setCollaboratorUpdate}
                />
                <CarteiraTrabalho
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                loadwalletNumber ={loadwalletNumber}
                loadserialNumber ={loadserialNumber}
                loadissueDate    ={loadissueDate   }
                loadwalletUF     ={loadwalletUF    }
                loadpisNumber    ={loadpisNumber   }
                focuswalletNumber={focuswalletNumber}
                focusserialNumber={focusserialNumber}
                focusissueDate   ={focusissueDate   }
                focuswalletUF    ={focuswalletUF    }
                focuspisNumber   ={focuspisNumber   }
                estadosOrgam={estadosOrgam}
                setCollaboratorUpdate={setCollaboratorUpdate}
                path={path}
                />
                <TituloEleitor
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                loadvoterRegistration ={loadvoterRegistration}
                loadelectoralZone     ={loadelectoralZone    }
                loadpollingStation    ={loadpollingStation   }
                focusvoterRegistration={focusvoterRegistration}
                focuselectoralZone    ={focuselectoralZone    }
                focuspollingStation   ={focuspollingStation   }
                path={path}
                />
                {
                (collaboratorUpdate && collaboratorUpdate.gender === "Masculino") ||
                (collaborator && collaborator.gender === "Masculino") ? (
                <>
                <Reservista
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                ufDistrito={
                    collaboratorUpdate && collaboratorUpdate.ufReservationCard ?
                    collaboratorUpdate.ufReservationCard :
                    collaborator && collaborator.ufReservationCard ?
                    collaborator.ufReservationCard :
                    ''
                }
                ufCity={
                    collaboratorUpdate && collaboratorUpdate.cityReservationCard ?
                    collaboratorUpdate.cityReservationCard :
                    collaborator && collaborator.cityReservationCard ?
                    collaborator.cityReservationCard :
                    ''
                }
                loadreservationCardNumber         ={loadreservationCardNumber        }
                loadreservationCardSeries         ={loadreservationCardSeries        }
                loadreservationCardCategory       ={loadreservationCardCategory      }
                loadreservationCardExpeditionDate ={loadreservationCardExpeditionDate}
                loadufReservationCard             ={loadufReservationCard            }
                loadcityReservationCard           ={loadcityReservationCard          }
                focusreservationCardNumber        ={focusreservationCardNumber        }
                focusreservationCardSeries        ={focusreservationCardSeries        }
                focusreservationCardCategory      ={focusreservationCardCategory      }
                focusreservationCardExpeditionDate={focusreservationCardExpeditionDate}
                focusufReservationCard            ={focusufReservationCard            }
                focuscityReservationCard          ={focuscityReservationCard          }
                path={path}
                setCollaboratorUpdate={setCollaboratorUpdate}
                />
                </>
                ): ''}
                <Banco
                token={token}
                id={collaborator._id}
                collaborator={collaborator}
                collaboratorUpdate={collaboratorUpdate}
                onCollaboratorInputChange={myChangeHandler}
                loadbankCode     ={loadbankCode    }
                loadbankAgency   ={loadbankAgency  }
                loadbankAccount  ={loadbankAccount }
                // loadaccountDigit ={loadaccountDigit}
                loadAccountType  ={loadAccountType }
                loadpix          ={loadpix         }
                focusbankCode    ={focusbankCode    }
                focusbankAgency  ={focusbankAgency  }
                focusbankAccount ={focusbankAccount }
                // focusaccountDigit={focusaccountDigit}
                focusAccountType ={focusAccountType }
                focuspix         ={focuspix         }
                selectAccountTypeOpen ={selectAccountTypeOpen}
                myChangeHandlerAccountType ={myChangeHandlerAccountType}
                abrirResultBank={abrirResultBank}
                openSelAccountType={openSelAccountType}
                mySearchBank={mySearchBank}
                abrirResultado={abrirResultado}
                myChangeHandlerToSaveBank={myChangeHandlerToSaveBank}
                setOpenSelAccountType={setOpenSelAccountType}
                setLoadAccountType={setLoadAccountType}
                path={path}
                />
                </div>
                <div style={{height: "25px"}}></div>
                <div className="buttons-solicitacao-2">
                    <button name="stepOne" value="true" onClick={()=>backFile()} className="voltar">Voltar</button>

                    <form onSubmit={save} >
                    <button type="submit" name="stepContinue" value={"true"} onClick={myChangeHandler} className={loadContinue ? 'saved btnGreen btnGreenLoad' : 'saved btnGreen'}>Salvar e prosseguir</button>
                    {/* <button type="submit" className={load ? 'continuar-depois btnGray btnGrayLoad' : 'continuar-depois btnGray'}>Salvar e continuar depois</button> */}
                    </form>
                    <form onSubmit={saveFillInLater} >
                        <button type="submit" className={loadBtn ? 'continuar-depois btnGray btnGrayLoad' : 'continuar-depois btnGray'}>Salvar e continuar depois</button>
                    </form>
                </div>
                <div style={{height: "38px"}}></div>


                </>

            )}


            </div>
        </div>

        </>
    );
}

