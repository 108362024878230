import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import { NavLink } from 'react-router-dom'
import ListCompanie from '../../../components/Administrativo/SystemsConfig/RegisteredCompanies/list-companies';
import api from '../../../services/api';
import './styles.scss';
export default function pageSystemConfig(props, path) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ comapanyData, setComapanyData] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setLoadPageCompanyData] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        setLoadPageCompanyData(true)
        async function loadcompany() {

            await api.get('/company-admin/')
            .then(response => {

                setLoadPageCompanyData(false)
                setComapanyData(response.data);
            }).catch(error => {})
        }
        loadcompany();
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costCenterData, setCostCenterData] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        setLoadPageCompanyData(true)
        async function loadcostcenter() {

            await api.get('/costcenter/')
            .then(response => {

                setLoadPageCompanyData(false)
                setCostCenterData(response.data);
            }).catch(error => {})
        }
        loadcostcenter();
    }, []);

    async function updateCostCenter(e) {

    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-empresas-cadastradas">
                    <h2 className="titulo">Empresas cadastradas</h2>
                    <ListCompanie
                    comapanyData={comapanyData}
                    costCenterData={costCenterData}
                    updateCostCenter={updateCostCenter}
                    />
                    <div className="butao-adicionar-empresa">
                        <NavLink to={`/configuracao/adicionar-empresa`} className="add-empresa">
                        + ADICIONAR EMPRESA
                        </NavLink>
                    </div>
                </div>
            </Layout>
        </>
    );

}
