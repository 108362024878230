import React, {useState, useEffect, useRef} from "react"
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import SubHeaderCustomizePage from '../../components/RecruitmentAndSelection/subHeaderCustomizePage';
import Dropzone from 'react-dropzone';
import CustomPage from '../../components/RecruitmentAndSelection/CustomizePage/customPage'
import api from '../../services/api'
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import InputColor from 'react-input-color';

export default function CustomizePage(props) {
    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadAparenciaSalva();
        loadAparenciaSalvaFileLogoTopHeader();
        loadAparenciaSalvaFileBackgroundTopHeader();
        loadAparenciaSalvaFileLogoFooter();
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [customizePageState, setCustomizePageState] = useState("");

    async function loadAparenciaSalva() {

        await api.get('/vacancie-page-appearance')
        .then(response => {
            if(response.data !== null){
                setCustomizePageState(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [customizePageStateFileLogoTopHeader, setCustomizePageStateFileLogoTopHeader] = useState("");
    async function loadAparenciaSalvaFileLogoTopHeader() {

        await api.get('/vacancie-page-appearance-file?category=logoTopHeader')
        .then(response => {
            if(response.data !== null){
                setCustomizePageStateFileLogoTopHeader(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [customizePageStateFileBackgroundTopHeader, setCustomizePageStateFileBackgroundTopHeader] = useState("");

    async function loadAparenciaSalvaFileBackgroundTopHeader() {

        await api.get('/vacancie-page-appearance-file?category=backgroundTopHeader')
        .then(response => {
            if(response.data !== null){
                setCustomizePageStateFileBackgroundTopHeader(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [customizePageStateFileLogoFooter, setCustomizePageStateFileLogoFooter] = useState("");

    async function loadAparenciaSalvaFileLogoFooter() {

        await api.get('/vacancie-page-appearance-file?category=logoFooter')
        .then(response => {
            if(response.data !== null){
                setCustomizePageStateFileLogoFooter(response.data);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusTitle            = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [customizePage, setCustomizePage] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadColorBacground, setLoadColorBacground] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////
    ///////////////////////////         TOPO LOAD        //////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadTitleTopHeader, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadColorTextTopHeader, setLoadColorTextTopHeader] = useState(false);

    ///////////////////////////////////////////////////////////////////////////////
    ///////////////////////////       SOBRE LOAD        //////////////////////////
    /////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadTitleAboutCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [colorTitleAboutCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [textAboutCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [colorTextAboutCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [colorTitleVideoCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadTitleVideoCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadLinkVideoCompany, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadColorBackgroundFooter, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadColorTextFooter, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadTitleFooter, ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [colorTitleFooter, ] = useState(false);
    
    const myChangeHandler = event =>{
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'colorBackground') {
            setCustomizePage({ ...customizePage, [nam]: val})
            if(val.length <= [0]) {
                setLoadColorBacground(true)
                return
            } else {
                setLoadColorBacground(false)
            }
        } else
        if(nam === 'colorTextTopHeader') {
            setCustomizePage({ ...customizePage, [nam]: val})
            if(val.length <= [0]) {
                setLoadColorTextTopHeader(true)
                return
            } else {
                setLoadColorTextTopHeader(false)
            }
        } else
        if(nam === 'linkVideoCompany') {
            const url = val;
            const resultado = url.substring(url.indexOf("youtube.com") + 11);
            const LinkVideo = "https://www.youtube.com/embed"+ resultado;
            setCustomizePage({ ...customizePage, 'linkVideoCompany': LinkVideo})
        }else {
            setCustomizePage({ ...customizePage, [nam]: val})

        }

    }
    
    const myChangeHandlerColorBackground = event =>{
        if( event.hex !==  "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorBackground': val})
        }
    }

    const myChangeHandlerColorTextTopHeader = event =>{
        if( event.hex !==  "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorTextTopHeader': val})
        }
    }
    const myChangeHandlerColorTitleAboutCompany = event =>{
        if( event.hex !==  "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorTitleAboutCompany': val})
        }
    }

    const myChangeHandlerColorTextAboutCompany = event =>{
        if( event.hex !==  "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorTextAboutCompany': val})
        }
    }
    
    const myChangeHandlercolorTitleVideoCompany = event =>{
        if( event && event.hex !== "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorTitleVideoCompany': val})
        }
    }
    const myChangeHandlercolorTitleFooter = event =>{
        if( event && event.hex !== "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorTitleFooter': val})
        }
    }
    const myChangeHandlerColorTextFooter = event =>{
        if( event && event.hex !== "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorTextFooter': val})
        }
    }

    const myChangeHandlerColorBackgroundFooter = event =>{
        if( event && event.hex !== "" ) {
            let val = event.hex;
            setCustomizePage({ ...customizePage, 'colorBackgroundFooter': val})
        }
    }
    
    async function AtictivetopHeader(){

            if(customizePage.topHeader === true) {
                setCustomizePage({ ...customizePage, 'topHeader': false})
                setCustomizePageState({...customizePageState, 'topHeader': false})
            }else {

                if(customizePageState.topHeader === true) {
                    setCustomizePage({ ...customizePage, 'topHeader': false})
                    setCustomizePageState({...customizePageState, 'topHeader': false})

                }else{
                    setCustomizePage({ ...customizePage, 'topHeader': true})
                    setCustomizePageState({...customizePageState, 'topHeader': true})
                }

            }
    }
       
    async function AtictiveAboutCompany(){

        if(customizePage.aboutCompany === true) {
            setCustomizePage({ ...customizePage, 'aboutCompany': false})
            setCustomizePageState({...customizePageState, 'aboutCompany': false})
        }else {

            if(customizePageState.aboutCompany === true) {
                setCustomizePage({ ...customizePage, 'aboutCompany': false})
                setCustomizePageState({...customizePageState, 'aboutCompany': false})

            }else{
                setCustomizePage({ ...customizePage, 'aboutCompany': true})
                setCustomizePageState({...customizePageState, 'aboutCompany': true})
            }

        }
    } 
    async function AtictiveVideoCompany(){

        if(customizePage.videoCompany === true) {
            setCustomizePage({ ...customizePage, 'videoCompany': false})
            setCustomizePageState({...customizePageState, 'videoCompany': false})
        }else {

            if(customizePageState.videoCompany === true) {
                setCustomizePage({ ...customizePage, 'videoCompany': false})
                setCustomizePageState({...customizePageState, 'videoCompany': false})

            }else{
                setCustomizePage({ ...customizePage, 'videoCompany': true})
                setCustomizePageState({...customizePageState, 'videoCompany': true})
            }

        }
    }
    async function AtictiveFooter(){

        if(customizePage.footer === true) {
            setCustomizePage({ ...customizePage, 'footer': false})
            setCustomizePageState({...customizePageState, 'footer': false})
        }else {

            if(customizePageState.footer === true) {
                setCustomizePage({ ...customizePage, 'footer': false})
                setCustomizePageState({...customizePageState, 'footer': false})

            }else{
                setCustomizePage({ ...customizePage, 'footer': true})
                setCustomizePageState({...customizePageState, 'footer': true})
            }

        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadBtnSave, setLoadBtnSave] = useState(false);

    async function savePage(){

        setLoadBtnSave(true)

        if(customizePageState?._id !== undefined) {
            await api.put(`/vacancie-page-appearance/${customizePageState._id}`, customizePage)
            .then(response => {
                setLoadBtnSave(false);
                loadAparenciaSalva();
                window.location.href =  `/recrutamento/politica-e-privacidade-da-pagina`;

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }else { 
            await api.post("/vacancie-page-appearance/", customizePage)
            .then(response => {
                setLoadBtnSave(false);
                loadAparenciaSalva();
                window.location.href =  `/recrutamento/politica-e-privacidade-da-pagina`;

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });
        }
    }
    async function discardInformation(){

        if(customizePageState?._id !== undefined) {
            swal({
                title: "Atenção",
                text: "Deseja descartar essas Informações?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/vacancie-page-appearance/${customizePageState?._id}`)
                    .then(() => {
                        setCustomizePage({});
                        setCustomizePageState({});
                        swal({ icon: "success", title: "Sucesso!", text: "Informações descartada com sucesso." });
                        focusTitle.current.focus();
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        focusTitle.current.focus();
                    });
                }
            });
        }else {
            swal({
                title: "Atenção",
                text: "Deseja descartar essas Informações?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                setCustomizePage({});
                setCustomizePageState({});
                focusTitle.current.focus();
                
            });
        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesBackgroundTopHeader] = useState([]);

    // customizePageStateFileBackgroundTopHeader
    async function handleDropLogoTopHeader(acceptedFilesLogoTopHeader) {

        let file = acceptedFilesLogoTopHeader[0]
        const typeI = file.type ;
    
        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
    
            setFileNamesBackgroundTopHeader(acceptedFilesLogoTopHeader.map(file => file.name));
    
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFilesLogoTopHeader === undefined) {
                return
            }
    
            formData.append('file', acceptedFilesLogoTopHeader[0]);
            formData.append('category', 'logoTopHeader');
            formData.append('path', path);
    
            if(customizePageStateFileLogoTopHeader?._id !== undefined){
                
                await api.put(`/vacancie-page-appearance-file/${customizePageStateFileLogoTopHeader._id}`, formData, config)
                .then(response => { 
                    
                    loadAparenciaSalvaFileLogoTopHeader();

                }).catch(error => {
                    
                    setFileNamesBackgroundTopHeader(false)
        
                });

            }else { 
                
                await api.post("/vacancie-page-appearance-file/", formData, config)
                .then(response => { 

                    loadAparenciaSalvaFileLogoTopHeader();

                }).catch(error => {
        
                    setFileNamesBackgroundTopHeader(false)
        
                });

            }

        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    
    }
    async function handleDropBackgroundTopHeader(acceptedFilesLogoTopHeader) {

        let file = acceptedFilesLogoTopHeader[0]
        const typeI = file.type ;
    
        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
    
            setFileNamesBackgroundTopHeader(acceptedFilesLogoTopHeader.map(file => file.name));
    
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFilesLogoTopHeader === undefined) {
                return
            }
    
            formData.append('file', acceptedFilesLogoTopHeader[0]);
            formData.append('category', 'backgroundTopHeader');
            formData.append('path', path);
    
            if(customizePageStateFileBackgroundTopHeader?._id !== undefined){
                await api.put(`/vacancie-page-appearance-file/${customizePageStateFileBackgroundTopHeader._id}`, formData, config)
                .then(response => { 

                    loadAparenciaSalvaFileBackgroundTopHeader();

                }).catch(error => {

                    setFileNamesBackgroundTopHeader(false)
        
                });

            }else { 
                await api.post("/vacancie-page-appearance-file/", formData, config)
                .then(response => { 

                    loadAparenciaSalvaFileBackgroundTopHeader();

                }).catch(error => {
        
                    setFileNamesBackgroundTopHeader(false)
        
                });

            }
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    
    }
    async function handleDropLogoFooter(acceptedFilesLogoTopHeader) {

        let file = acceptedFilesLogoTopHeader[0]
        const typeI = file.type ;
    
        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
    
            setFileNamesBackgroundTopHeader(acceptedFilesLogoTopHeader.map(file => file.name));
    
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFilesLogoTopHeader === undefined) {
                return
            }
    
            formData.append('file', acceptedFilesLogoTopHeader[0]);
            formData.append('category', 'logoFooter');
            formData.append('path', path);
    
            if(customizePageStateFileLogoFooter?._id !== undefined){
                await api.put(`/vacancie-page-appearance-file/${customizePageStateFileLogoFooter._id}`, formData, config)
                .then(response => { 
                    
                    loadAparenciaSalvaFileLogoFooter()

                }).catch(error => {
                    setFileNamesBackgroundTopHeader(false)
        
                });

            }else { 
                await api.post("/vacancie-page-appearance-file/", formData, config)
                .then(response => { 
                    
                    loadAparenciaSalvaFileLogoFooter()

                }).catch(error => {
        
                    setFileNamesBackgroundTopHeader(false)
        
                });

            }
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    
    }


    return (
        <>
        <Layout path={path}> 
            <Header  path={path}/>

            <SubHeaderCustomizePage path={path}/>
            <div className="page-customizePage">
                <div className="form-customize">
                    <h5>Geral</h5>

                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do background </p>
                            </div>
                            <div className="intemB">
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorBackground !== undefined ? 
                                            customizePage.colorBackground :
                                            customizePageState && customizePageState?.colorBackground !== undefined ?
                                            customizePageState.colorBackground : ""
                                        }
                                        onChange={myChangeHandlerColorBackground}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={loadColorBacground ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorBackground" 
                                        placeholder="Cor do background"
                                        ref={focusTitle}
                                        value={
                                            customizePage?.colorBackground !== undefined ? 
                                            customizePage.colorBackground : 
                                            customizePageState && customizePageState?.colorBackground !== undefined ?
                                            customizePageState.colorBackground : ""
                                        }
                                    />
                                { loadColorBacground ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <h5>Topo</h5>
                            </div>
                            <div className="intemB">
                                <div className="esconder-secao">
                                    <button 
                                    className={ 
                                        customizePage && customizePage.topHeader  !== undefined ? 
                                        customizePage.topHeader  === true ?
                                            "button-toggle active" : "button-toggle"
                                        :customizePageState && customizePageState.topHeader  !== undefined ?
                                            customizePageState.topHeader  === true ?
                                            "button-toggle active" : "button-toggle"
                                        :"button-toggle"
                                    }
                                        onClick={AtictivetopHeader} 
                                    ><span></span>
                                    </button>
                                    <p>Esconder seção</p>
                                </div>
                                {/* <button className="button-toggle active"><span></span></button> */}
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.topHeader  !== undefined ? 
                                    customizePage.topHeader  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.topHeader  !== undefined ?
                                        customizePageState.topHeader  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <input type="text" 
                                    className={loadTitleTopHeader ? "inputErro" : ""}
                                    onChange={myChangeHandler} 
                                    name="titleTopHeader" 
                                    placeholder="Título"
                                    maxLength={80}

                                    defaultValue={
                                        customizePage && customizePage?.titleTopHeader !== undefined ? 
                                        customizePage.titleTopHeader :
                                        customizePageState && customizePageState?.titleTopHeader !== undefined ?
                                        customizePageState.titleTopHeader : ""
                                    }
                                />
                                { loadTitleTopHeader ? <p className="textError">O campo é obrigatório.</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.topHeader  !== undefined ? 
                                    customizePage.topHeader  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.topHeader  !== undefined ?
                                        customizePageState.topHeader  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorTextTopHeader !== undefined ? 
                                            customizePage.colorTextTopHeader :
                                            customizePageState && customizePageState?.colorTextTopHeader !== undefined ?
                                            customizePageState.colorTextTopHeader :   ""
                                        }
                                        onChange={myChangeHandlerColorTextTopHeader}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={loadColorTextTopHeader ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorTextTopHeader" 
                                        placeholder="Cor do titulo"

                                        value={
                                            customizePage && customizePage?.colorTextTopHeader !== undefined ? 
                                            customizePage.colorTextTopHeader :
                                            customizePageState && customizePageState?.colorTextTopHeader !== undefined ?
                                            customizePageState.colorTextTopHeader : ""
                                        }
                                    />
                                { loadColorTextTopHeader ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Logo</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.topHeader  !== undefined ? 
                                    customizePage.topHeader  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.topHeader  !== undefined ?
                                        customizePageState.topHeader  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                } 
                            >
                                    <Dropzone onDrop={handleDropLogoTopHeader} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        { customizePageStateFileLogoTopHeader !== "" ?
                                            <label className="imgDocLabel"><span></span><p>{customizePageStateFileLogoTopHeader?.originalname}</p></label>
                                            :<label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        }
                                        </div>
                                        )}
                                    </Dropzone>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Imagem de fundo </p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.topHeader  !== undefined ? 
                                    customizePage.topHeader  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.topHeader  !== undefined ?
                                        customizePageState.topHeader  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                    <Dropzone onDrop={handleDropBackgroundTopHeader} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />

                                        { customizePageStateFileBackgroundTopHeader !== "" ?
                                            <label className="imgDocLabel"><span></span><p>{customizePageStateFileBackgroundTopHeader?.originalname}</p></label>
                                            :<label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        }
                                        </div>
                                        )}
                                    </Dropzone>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <h5>Sobre</h5>
                            </div>
                            <div className="intemB">
                                <div className="esconder-secao">
                                    <button 
                                        className={ 
                                            customizePage && customizePage?.aboutCompany  !== undefined ? 
                                            customizePage.aboutCompany  === true ?
                                                "button-toggle active" : "button-toggle"
                                            :customizePageState && customizePageState?.aboutCompany  !== undefined ?
                                                customizePageState.aboutCompany  === true ?
                                                "button-toggle active" : "button-toggle"
                                            :"button-toggle"
                                        }
                                        onClick={AtictiveAboutCompany} 
                                    ><span></span></button>
                                    <p>Esconder seção</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage.aboutCompany  !== undefined ? 
                                    customizePage.aboutCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState.aboutCompany  !== undefined ?
                                        customizePageState.aboutCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                                >
                                <input type="text" 
                                    className={loadTitleAboutCompany ? "inputErro" : ""}
                                    onChange={myChangeHandler} 
                                    name="titleAboutCompany" 
                                    placeholder="Título sobre"
                                    defaultValue={
                                        customizePage && customizePage.titleAboutCompany !== undefined ? 
                                        customizePage.titleAboutCompany :
                                        customizePageState && customizePageState.titleAboutCompany !== undefined ?
                                        customizePageState.titleAboutCompany : ""
                                    }
                                />
                                { loadTitleAboutCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.aboutCompany  !== undefined ? 
                                    customizePage.aboutCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.aboutCompany  !== undefined ?
                                        customizePageState.aboutCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorTitleAboutCompany !== undefined ? 
                                            customizePage.colorTitleAboutCompany :
                                            customizePageState && customizePageState?.colorTitleAboutCompany !== undefined ?
                                            customizePageState.colorTitleAboutCompany : ""
                                        }
                                        onChange={myChangeHandlerColorTitleAboutCompany}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={colorTitleAboutCompany ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorTextTopHeader" 
                                        placeholder="Cor do titulo"

                                        value={
                                            customizePage && customizePage?.colorTitleAboutCompany !== undefined ? 
                                            customizePage.colorTitleAboutCompany :
                                            customizePageState && customizePageState?.colorTitleAboutCompany !== undefined ?
                                            customizePageState.colorTitleAboutCompany : ""
                                        }
                                    />
                                { colorTitleAboutCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Texto</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage.aboutCompany  !== undefined ? 
                                    customizePage.aboutCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.aboutCompany  !== undefined ?
                                        customizePageState.aboutCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <textarea 
                                    name="textAboutCompany" 
                                    onChange={myChangeHandler} 
                                    placeholder="Texto..."
                                    className={textAboutCompany ? "inputErro" : ""}
                                    value={
                                        customizePage && customizePage.textAboutCompany !== undefined ? 
                                        customizePage.textAboutCompany :
                                        customizePageState && customizePageState?.textAboutCompany !== undefined ?
                                        customizePageState.textAboutCompany : ""
                                    }
                                >
                                </textarea>
                                { textAboutCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do texto</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.aboutCompany  !== undefined ? 
                                    customizePage.aboutCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.aboutCompany  !== undefined ?
                                        customizePageState.aboutCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorTextAboutCompany !== undefined ? 
                                            customizePage.colorTextAboutCompany :
                                            customizePageState && customizePageState?.colorTextAboutCompany !== undefined ?
                                            customizePageState.colorTextAboutCompany : ""
                                        }
                                        onChange={myChangeHandlerColorTextAboutCompany}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={colorTextAboutCompany ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorTextAboutCompany" 
                                        placeholder="Cor do texto"
                                        value={
                                            customizePage && customizePage?.colorTextAboutCompany !== undefined ? 
                                            customizePage.colorTextAboutCompany :
                                            customizePageState && customizePageState?.colorTextAboutCompany !== undefined ?
                                            customizePageState.colorTextAboutCompany : ""
                                        }
                                    />
                                { colorTextAboutCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <h5>Vídeo</h5>
                            </div>
                            <div className="intemB">
                                <div className="esconder-secao">
                                    <button 
                                        className={ 
                                            customizePage && customizePage?.videoCompany  !== undefined ? 
                                            customizePage.videoCompany  === true ?
                                                "button-toggle active" : "button-toggle"
                                            :customizePageState && customizePageState?.videoCompany  !== undefined ?
                                                customizePageState.videoCompany  === true ?
                                                "button-toggle active" : "button-toggle"
                                            :"button-toggle"
                                        }
                                    // className={ customizePage && customizePage.videoCompany  === true ? "button-toggle active" : "button-toggle"}
                                        onClick={AtictiveVideoCompany} 
                                    ><span></span>
                                    </button>
                                    <p>Esconder seção</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.videoCompany  !== undefined ? 
                                    customizePage.videoCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.videoCompany  !== undefined ?
                                        customizePageState.videoCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <input type="text" 
                                    className={loadTitleVideoCompany ? "inputErro" : ""}
                                    onChange={myChangeHandler} 
                                    name="titleVideoCompany" 
                                    placeholder="Título"
                                    defaultValue={
                                        customizePage && customizePage?.titleVideoCompany !== undefined ? 
                                        customizePage.titleVideoCompany :
                                        customizePageState && customizePageState?.titleVideoCompany !== undefined ?
                                        customizePageState.titleVideoCompany : ""
                                    }
                                />
                                { loadTitleVideoCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.videoCompany  !== undefined ? 
                                    customizePage.videoCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.videoCompany  !== undefined ?
                                        customizePageState.videoCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorTitleVideoCompany !== undefined ? 
                                            customizePage.colorTitleVideoCompany :
                                            customizePageState && customizePageState?.colorTitleVideoCompany !== undefined ?
                                            customizePageState.colorTitleVideoCompany : ""
                                        }
                                        onChange={myChangeHandlercolorTitleVideoCompany}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={colorTitleVideoCompany ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorTextTopHeader" 
                                        placeholder="Cor do titulo"
                                        value={
                                            customizePage && customizePage?.colorTitleVideoCompany !== undefined ? 
                                            customizePage.colorTitleVideoCompany :
                                            customizePageState && customizePageState?.colorTitleVideoCompany !== undefined ?
                                            customizePageState.colorTitleVideoCompany : ""
                                        }
                                    />
                                { colorTitleVideoCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Link do YouTube </p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.videoCompany  !== undefined ? 
                                    customizePage.videoCompany  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.videoCompany  !== undefined ?
                                        customizePageState.videoCompany  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <input type="text" 
                                    className={loadLinkVideoCompany ? "inputErro" : ""}
                                    onChange={myChangeHandler} 
                                    name="linkVideoCompany" 
                                    placeholder="Link do YouTube "
                                    defaultValue={
                                        customizePage && customizePage?.linkVideoCompany !== undefined ? 
                                        customizePage.linkVideoCompany :
                                        customizePageState && customizePageState?.linkVideoCompany !== undefined ?
                                        customizePageState.linkVideoCompany : ""
                                    }
                                />
                                { loadLinkVideoCompany ? <p className="textError">O campo é obrigatório.</p> : "" }
                            </div>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <h5>Rodapé</h5>
                            </div>
                            <div className="intemB">
                                <div className="esconder-secao">
                                    <button 
                                        className={
                                            customizePage && customizePage?.footer  !== undefined ? 
                                            customizePage.footer  === true ?
                                                "button-toggle active" : "button-toggle"
                                            :customizePageState && customizePageState?.footer  !== undefined ?
                                                customizePageState.footer  === true ?
                                                "button-toggle active" : "button-toggle"
                                            :"button-toggle"
                                        }
                                        onClick={AtictiveFooter} 
                                    ><span></span>
                                    </button>
                                    <p>Esconder seção</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.footer  !== undefined ? 
                                    customizePage.footer  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.footer  !== undefined ?
                                        customizePageState.footer  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <input type="text" 
                                    className={loadTitleFooter ? "inputErro" : ""}
                                    onChange={myChangeHandler} 
                                    name="titleFooter" 
                                    placeholder="Título"
                                    maxLength={80}
                                    defaultValue={
                                        customizePage && customizePage?.titleFooter !== undefined ? 
                                        customizePage.titleFooter :
                                        customizePageState && customizePageState?.titleFooter !== undefined ?
                                        customizePageState.titleFooter : ""
                                    }
                                />
                                { loadTitleFooter ? <p className="textError">O campo é obrigatório.</p> : "" }
                            </div>
                        </div>
                    </div>  
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do título</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.footer  !== undefined ? 
                                    customizePage.footer  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.footer  !== undefined ?
                                        customizePageState.footer  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorTitleFooter !== undefined ? 
                                            customizePage.colorTitleFooter :
                                            customizePageState && customizePageState?.colorTitleFooter !== undefined ?
                                            customizePageState.colorTitleFooter : ""
                                        }
                                        onChange={myChangeHandlercolorTitleFooter}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={colorTitleFooter ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorTitleFooter" 
                                        placeholder="Cor do background "
                                        value={
                                            customizePage && customizePage?.colorTitleFooter !== undefined ? 
                                            customizePage.colorTitleFooter :
                                            customizePageState && customizePageState?.colorTitleFooter !== undefined ?
                                            customizePageState.colorTitleFooter : ""
                                        }
                                    />
                                    { colorTitleFooter ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Logo</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.footer  !== undefined ? 
                                    customizePage.footer  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.footer  !== undefined ?
                                        customizePageState.footer  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <Dropzone onDrop={handleDropLogoFooter} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        { customizePageStateFileLogoFooter !== "" ?
                                            <label className="imgDocLabel"><span></span><p>{customizePageStateFileLogoFooter?.originalname}</p></label>
                                            :<label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        }
                                    </div>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                    </div>
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do texto </p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.footer  !== undefined ? 
                                    customizePage.footer  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.footer  !== undefined ?
                                        customizePageState.footer  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorTextFooter !== undefined ? 
                                            customizePage.colorTextFooter :
                                            customizePageState && customizePageState?.colorTextFooter !== undefined ?
                                            customizePageState.colorTextFooter : ""
                                        }
                                        onChange={myChangeHandlerColorTextFooter}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={loadColorTextFooter ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorTextFooter" 
                                        placeholder="Cor do texto "
                                        value={
                                            customizePage && customizePage?.colorTextFooter !== undefined ? 
                                            customizePage.colorTextFooter :
                                            customizePageState && customizePageState?.colorTextFooter !== undefined ?
                                            customizePageState.colorTextFooter : ""
                                        }
                                    />
                                    { loadColorTextFooter ? <p className="textError">O campo é obrigatório.</p> : "" }

                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="linha-typeOne">
                        <div className='bloco'>
                            <div className="intemA">
                                <p>Cor do backgroundo</p>
                            </div>
                            <div
                                className={ 
                                    customizePage && customizePage?.footer  !== undefined ? 
                                    customizePage.footer  === true ?
                                    "intemB" : "intemB block"
                                    :customizePageState && customizePageState?.footer  !== undefined ?
                                        customizePageState.footer  === true ?
                                        "intemB" : "intemB block"
                                    :"intemB block"
                                }
                            >
                                <div className="inputsColor">
                                    <InputColor
                                        initialValue={
                                            customizePage && customizePage?.colorBackgroundFooter !== undefined ? 
                                            customizePage.colorBackgroundFooter :
                                            customizePageState && customizePageState?.colorBackgroundFooter !== undefined ?
                                            customizePageState.colorBackgroundFooter : ""
                                        }
                                        onChange={myChangeHandlerColorBackgroundFooter}
                                        placement="right"
                                    />
                                    <input type="text" 
                                        className={loadColorBackgroundFooter ? "inputErro inputColor" : "inputColor"}
                                        onChange={myChangeHandler} 
                                        name="colorBackgroundFooter" 
                                        placeholder="Cor do background "
                                        value={
                                            customizePage && customizePage?.colorBackgroundFooter !== undefined ? 
                                            customizePage.colorBackgroundFooter :
                                            customizePageState && customizePageState?.colorBackgroundFooter !== undefined ?
                                            customizePageState.colorBackgroundFooter : ""
                                        }
                                    />
                                    { loadColorBackgroundFooter ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                    </div>  

                </div>
                <CustomPage 
                    colorBackground={ 
                        customizePage?.colorBackground !== undefined ?
                        customizePage.colorBackground :
                        customizePageState?.colorBackground !== undefined ?
                        customizePageState.colorBackground : ""
                    }
                    topHeader={ 
                        customizePage?.topHeader !== undefined ?
                        customizePage.topHeader :
                        customizePageState?.topHeader !== undefined ?
                        customizePageState.topHeader : ""
                    }
                    titleTopHeader={ 
                        customizePage?.titleTopHeader !== undefined ?
                        customizePage.titleTopHeader :
                        customizePageState?.titleTopHeader !== undefined ?
                        customizePageState.titleTopHeader : ""
                    }
                    colorTextTopHeader={ 
                        customizePage?.colorTextTopHeader !== undefined ?
                        customizePage.colorTextTopHeader :
                        customizePageState?.colorTextTopHeader !== undefined ?
                        customizePageState.colorTextTopHeader : ""
                    }
                    logoTopHeader={
                        customizePageStateFileLogoTopHeader !== undefined ?

                        customizePageStateFileLogoTopHeader.location : ""
                    }
                    
                    backgroundTopHeader={
                        customizePageStateFileBackgroundTopHeader !== undefined ?

                        customizePageStateFileBackgroundTopHeader.location : ""
                    }
                    
                    logoFooter={
                        customizePageStateFileLogoFooter !== undefined ?

                        customizePageStateFileLogoFooter.location : ""
                    }
                    // loadAparenciaSalvaFileLogoFooter

                    aboutCompany={ 
                        customizePage?.aboutCompany !== undefined ?
                        customizePage.aboutCompany :
                        customizePageState?.aboutCompany !== undefined ?
                        customizePageState.aboutCompany : ""
                    }
                    titleAboutCompany={ 
                        customizePage?.titleAboutCompany !== undefined ?
                        customizePage.titleAboutCompany :
                        customizePageState?.titleAboutCompany !== undefined ?
                        customizePageState.titleAboutCompany : ""
                    }
                    colorTitleAboutCompany={ 
                        customizePage?.colorTitleAboutCompany !== undefined ?
                        customizePage.colorTitleAboutCompany :
                        customizePageState?.colorTitleAboutCompany !== undefined ?
                        customizePageState.colorTitleAboutCompany : ""
                    }
                    textAboutCompany={ 
                        customizePage?.textAboutCompany !== undefined ?
                        customizePage.textAboutCompany :
                        customizePageState?.textAboutCompany !== undefined ?
                        customizePageState.textAboutCompany : ""
                    }
                    colorTextAboutCompany={ 
                        customizePage?.colorTextAboutCompany !== undefined ?
                        customizePage.colorTextAboutCompany :
                        customizePageState?.colorTextAboutCompany !== undefined ?
                        customizePageState.colorTextAboutCompany : ""
                    }
                    
                    videoCompany={ 
                        customizePage?.videoCompany !== undefined ?
                        customizePage.videoCompany :
                        customizePageState?.videoCompany !== undefined ?
                        customizePageState.videoCompany : ""
                    }
                    titleVideoCompany={ 
                        customizePage?.titleVideoCompany !== undefined ?
                        customizePage.titleVideoCompany :
                        customizePageState?.titleVideoCompany !== undefined ?
                        customizePageState.titleVideoCompany : ""
                     }
                    colorTitleVideoCompany={ 
                        customizePage?.colorTitleVideoCompany !== undefined ?
                        customizePage.colorTitleVideoCompany :
                        customizePageState?.colorTitleVideoCompany !== undefined ?
                        customizePageState.colorTitleVideoCompany : ""
                    }
                    linkVideoCompany={ 
                        customizePage?.linkVideoCompany !== undefined ?
                        customizePage.linkVideoCompany :
                        customizePageState?.linkVideoCompany !== undefined ?
                        customizePageState.linkVideoCompany : ""
                    }
                    
                    footer={ 
                        customizePage?.footer !== undefined ?
                        customizePage.footer :
                        customizePageState?.footer !== undefined ?
                        customizePageState.footer : ""
                    }
                    titleFooter={ 
                        customizePage?.titleFooter !== undefined ?
                        customizePage.titleFooter :
                        customizePageState?.titleFooter !== undefined ?
                        customizePageState.titleFooter : ""
                    }
                    colorTitleFooter={ 
                        customizePage?.colorTitleFooter !== undefined ?
                        customizePage.colorTitleFooter :
                        customizePageState?.colorTitleFooter !== undefined ?
                        customizePageState.colorTitleFooter : ""
                    }
                    colorTextFooter={ 
                        customizePage?.colorTextFooter !== undefined ?
                        customizePage.colorTextFooter :
                        customizePageState?.colorTextFooter !== undefined ?
                        customizePageState.colorTextFooter : ""
                    }
                    colorBackgroundFooter={ 
                        customizePage?.colorBackgroundFooter !== undefined ?
                        customizePage.colorBackgroundFooter :
                        customizePageState?.colorBackgroundFooter !== undefined ?
                        customizePageState.colorBackgroundFooter : ""
                    }
                />

                
            </div>
            <div className="page-customizePage-buttons">
                <button className="btnGray cancelar" onClick={discardInformation}>Descarta informações </button>
                <button 
                    className={loadBtnSave ? "btnGreen publicar load" : "btnGreen publicar"}
                onClick={savePage}>Salvar</button>
            </div>

        </Layout>
        </>
    )
}