import React, { useState, useEffect } from 'react';
import api from '../../../../../../services/api';
import swal from 'sweetalert';
import { Modal} from "react-bootstrap";
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Close from '../../../../../../assets/images/close.svg';
import Carregamento from '../../../../../../assets/images/carregando.gif';
import IllustrationEmergency from '../../../../../../assets/images/illustration-emergency.svg';
import { phones } from '../../../../../../services/mask';
import SelectPersonalizado from '../../../../../Reused/selectPersonalizado';
import { toast } from 'react-toastify';


const ColProfileEmergencyContacts = ({ id, loadContinue, loadPage, path  }) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    //////////////////////////////////////
    /////POP UP de ADD
    /////////////////////////////////////
    function PopupAddConEmertVisible() {
        setVisible(!visible);
        setLoademErgencyName(false);
        setLoadRelationship(false);
        setLoadEmergencyCellphone(false);
        setEmergencyContact([])
    }

    const [contactEmergencySaved, setContactEmergencySaved] = useState([]);
    ////////////////////////////////////////////////////////////////////////////
    /////     POP UP de EDIT RECEBENDO O CLICK DO FORM A SER EDITADO      /////
    ///////////////////////////////////////////////////////////////////////////
    function PopupEdiConEmertVisible(e) {
        setContactEmergencySaved(e)
        setVisible(!visible);
        setLoademErgencyName(false);
        setLoadRelationship(false);
        setLoadEmergencyCellphone(false);
        setEmergencyContact([])
    }
    ////////////////////////////////////////////////////////////////////////////
    /////     TODOS OS LOADERS DE INPUT E DE SALVAR FORM TBM     /////
    ///////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loademErgencyName, setLoademErgencyName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRelationship, setLoadRelationship ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmergencyCellphone, setLoadEmergencyCellphone ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ emergencyContact, setEmergencyContact ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredEmergencyContact, setRegisteredEmergencyContact ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id !== undefined) {
            loademergencycontacts(id);
        }
    }, [id]);

    async function loademergencycontacts(id) {

        setLoad(true)
        await api.get(`/collaborator-emergency-contact/${id}`)
        .then(response => {
            setLoad(false)
            if(response.data[0] !== undefined) {
                setEmergencyContact([]);
                setContactEmergencySaved([]);
                setVisible(false);
                setRegisteredEmergencyContact(response.data);
            }
            else {
                setEmergencyContact([]);
                setContactEmergencySaved([]);
                setVisible(false);
                setRegisteredEmergencyContact([]);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    ////////////////////////////////////////////////////////////////////////////
    /////     FUNÇÂO RESPONSÀVEL POR RECEBER OS EVENTOS DOS IMPUTS     /////
    ///////////////////////////////////////////////////////////////////////////
    const collaboratorId = id;
    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: val, path: path })

        if (nam === "emergencyCellphone") {

            if(val.length >= 14) {
                setLoadEmergencyCellphone(false)
            }
            let emergencyCellphone = phones(val)
            setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: emergencyCellphone })

        }
        else
        if (nam === "emergencyName") {
            setLoademErgencyName(false)

        }
        else
        if (nam === "relationship") {
            setLoadRelationship(false)

        }
        else
        if (nam === "emergencyEmail") {

            if(val === "") {
                let emergencyEmail = '-'
                setEmergencyContact({ ...emergencyContact, emergencyEmail })
            } else {
                setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: val })
            }

        }
        else {
            setEmergencyContact({ ...emergencyContact, collaboratorId, [nam]: val })
        }

    };



    ////////////////////////////////////////////////////////////////////////////
    /////     FUNÇÂO RESPONSÀVEL POR EDITAR E ADICIONAR AS INFOS DOS INPUTS     /////
    ///////////////////////////////////////////////////////////////////////////
    async function handleSubmit(event) {
        event.preventDefault();

        if(contactEmergencySaved._id !== undefined) {

            // if(emergencyContact === undefined || emergencyContact.length === 0) {
            //     swal({ icon: "sucess", title: "Sucesso!!", text: "Atualizado com sucesso!" });
            // }
            // else
            if(
                emergencyContact.emergencyName      === "" ||
                emergencyContact.relationship       === "" ||
                emergencyContact.emergencyCellphone === ""
            ) {
                swal({ icon: "error", title: "Erro!", text: 'Apenas o campo email pode ser salvo em branco!' });
            }
            else {

                setLoad(true)
                await api.put(`/collaborator-emergency-contact/${contactEmergencySaved._id}`, emergencyContact)
                .then(response => {

                    loademergencycontacts(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência editado com sucesso!" });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar o contato de emergência, tente novamente mais tarde." });
                });
            }

        } else {

            if (emergencyContact.emergencyName === undefined || emergencyContact.emergencyName === "") {
                setLoademErgencyName(true)
            } else {
                setLoademErgencyName(false)
            }

            if (emergencyContact.relationship === undefined || emergencyContact.relationship === "") {
                setLoadRelationship(true)
            } else {
                setLoadRelationship(false)
            }

            if (emergencyContact.emergencyCellphone === undefined || emergencyContact.emergencyCellphone === "") {
                setLoadEmergencyCellphone(true)
            } else {
                setLoadEmergencyCellphone(false)
            }

            if (
                emergencyContact.emergencyName      !== undefined &&
                emergencyContact.emergencyName      !==     ""    &&
                emergencyContact.relationship       !== undefined &&
                emergencyContact.relationship       !==     ""    &&
                emergencyContact.emergencyCellphone !== undefined &&
                emergencyContact.emergencyCellphone !==     ""
            ) {

                setLoad(true)
                await api.post('/collaborator-emergency-contact/', emergencyContact)
                .then(response => {

                    loademergencycontacts(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência criado com sucesso!" });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao criar o contato de emergência, tente novamente mais tarde." });
                });
            }
        }
    }

    ////////////////////////////////////////////////////////////////////////////
    ///// FUNÇÂO RESPONSÀVEL POR DELETAR AS ISFOS DO CONATATO DE EMERGÊNCIA /////
    ///////////////////////////////////////////////////////////////////////////
    async function handleDelete(_id) {

    //setLoad(true)
    await api.delete(`/collaborator-emergency-contact/${_id}?path=${path}`)
    .then(response => {

        loademergencycontacts(id)

        swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência deletado com sucesso!" });
    }).catch(error => {
        swal({ icon: "error", title: "Erro!", text: "Erro ao deletadar o contato de emergência!" });
    });

}


const  optionRelationshipEmergencyContact = [
    "Cônjuge", "Companheiro(a) ou união estável com filhos", "Companheiro(a) ou união estável sem filhos", "Filho ou enteado até 21 anos", "Filho ou enteado universitário ou em escola técnica", "Irmão(ã), neto(a) ou bisneto(a) com guarda", "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica", "Pais, avós e bisavós", "Incapaz", "Agregado/Outros", "Ex cônjuge que recebe pensão de alimentos"
]


    return (
        <>

                <div className="box edereco-emergencia">
                    <h3 className="titulo-box "><span>Contatos de emergência</span>
                        {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                            ""
                            :
                            <button className="edit-adicionar btnGreen more" onClick={() => PopupAddConEmertVisible()}>Adicionar</button>
                        }
                    </h3>
                    {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):(
                                <>

                    {
                        load ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):

                    registeredEmergencyContact[0] === undefined ?
                    (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationEmergency} alt="Sem deficiencia" /><p>Não possui contatos de emergência</p></div></div>):
                    registeredEmergencyContact !== undefined ? registeredEmergencyContact
                                .map((emergencycontact, index) => (

                                        <ul>
                                            <li className="nome-contato">
                                                <div className="intemA">
                                                    <p>Nome completo</p>
                                                </div>
                                                <div className="intemB nome-editar">
                                                    <span className="nome">{emergencycontact.emergencyName}</span>

                                                    {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                                        ""
                                                        :
                                                        <button onClick={() => PopupEdiConEmertVisible(emergencycontact)} className="editar"><img src={EditBlack} alt="Edite Informações"/></button>
                                                    }
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Parentesco</p>
                                                </div>
                                                <div className="intemB">
                                                    <p>{emergencycontact.relationship}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="intemA">
                                                    <p>Celular</p>
                                                </div>
                                                <div className="intemB">
                                                    <p>{emergencycontact.emergencyCellphone}</p>
                                                </div>
                                            </li>
                                            {emergencycontact.emergencyEmail !== undefined ?(

                                                <li>
                                                    <div className="intemA">
                                                        <p>E-mail</p>
                                                    </div>
                                                    <div className="intemB">
                                                        <p>{emergencycontact.emergencyEmail}</p>
                                                    </div>
                                                </li>
                                                ) : ('')}
                                        </ul>


                                )): (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationEmergency} alt="Não possui contatos de emergência" /><p>Não possui contatos de emergência</p></div></div>)}

                    </> )}
                </div>


                <Modal show={visible} className="popup-edit-perfil" onHide={contactEmergencySaved && contactEmergencySaved._id !== undefined ? PopupEdiConEmertVisible : PopupAddConEmertVisible}>
                    <div >
                        <div className="titulo">
                            <h4>Contatos de emergência <p className="btn-fechar" onClick={contactEmergencySaved && contactEmergencySaved._id  !== undefined ? PopupEdiConEmertVisible : PopupAddConEmertVisible}> <img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Nome completo</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" name="emergencyName" autocomplete="autocompleteoff" defaultValue={contactEmergencySaved && contactEmergencySaved._id !== undefined ? contactEmergencySaved.emergencyName : ''} onChange={myChangeHandler} placeholder="Nome completo" className={loademErgencyName ? 'inputUm inputErro' : 'inputUm'}/>
                                    <p className="inputUm textError">{loademErgencyName ? 'É necessário preencher um nome' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Parentesco</p>
                                </div>
                                <div className="intemB">


                                <SelectPersonalizado
                                        valorCampo={
                                            emergencyContact && emergencyContact.relationship ?
                                                emergencyContact.relationship 
                                                :
                                                contactEmergencySaved && contactEmergencySaved?.relationship ?
                                                contactEmergencySaved.relationship :
                                                ''
                                        }
                                        nameButton={"relationship"}
                                        myChangeHandler={myChangeHandler}
                                        // option={optionRelationshipEmergencyContact}
                                        loadError={loadRelationship}
                                    
                                        optionFil={optionRelationshipEmergencyContact}
                                        typeSelect="busca2"
                                    />

                                    <p className="inputDois textError">{loadRelationship ? 'É necessário escolher um parentesco' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Celular</p>
                                </div>
                                <div className="intemB">
                                            {
                                                (emergencyContact && emergencyContact.emergencyCellphone !== undefined) || emergencyContact.emergencyCellphone === '' ?
                                                (
                                                    <input type="text" name="emergencyCellphone" autocomplete="autocompleteoff"  value={(emergencyContact.emergencyCellphone !== undefined) ? emergencyContact.emergencyCellphone : ''}  onChange={myChangeHandler} placeholder="Celular"  className={loadEmergencyCellphone ? 'inputErro' : ''}/>
                                                ) :
                                                contactEmergencySaved && contactEmergencySaved.emergencyCellphone && contactEmergencySaved.emergencyCellphone !== ''? (
                                                    <input type="text" name="emergencyCellphone" autocomplete="autocompleteoff" defaultValue={(contactEmergencySaved.emergencyCellphone !== undefined) ? contactEmergencySaved.emergencyCellphone : ''}  onChange={myChangeHandler} placeholder="Celular" className={loadEmergencyCellphone ? 'inputErro' : ''}/>
                                                ):
                                                (
                                                    <input type="text" name="emergencyCellphone" autocomplete="autocompleteoff" value={(emergencyContact.emergencyCellphone !== undefined) ? emergencyContact.emergencyCellphone : ''}  onChange={myChangeHandler} placeholder="Celular" className={loadEmergencyCellphone ? 'inputErro' : ''} />
                                                )}
                                                <p className="inputTreis textError">{loadEmergencyCellphone ? 'É necessário preencher um celular' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Email</p>
                                </div>
                                <div className="intemB">
                                    {contactEmergencySaved && contactEmergencySaved._id !== undefined ?
                                    (<input type="email" name="emergencyEmail" autocomplete="off" defaultValue={contactEmergencySaved && contactEmergencySaved.emergencyEmail} onChange={myChangeHandler} placeholder="Email"/>) :
                                    (<input type="email" name="emergencyEmail" autocomplete="off" onChange={myChangeHandler} placeholder="Email"/>)}
                                </div>

                            </li>

                            <li className='comBtnExcluiMb'>
                                <div className="intemA">
                                    <form onSubmit={handleSubmit} className="btn-salvar">
                                        <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>{contactEmergencySaved !== undefined && contactEmergencySaved.length !== 0  ? 'Editar' : 'Adicionar' }</button>
                                    </form>

                                </div>
                                <div className="intemB">
                                    {contactEmergencySaved && contactEmergencySaved._id !== undefined ?
                                    (
                                        <div className="intemB btnExclui">
                                                <button onClick={() => handleDelete(contactEmergencySaved && contactEmergencySaved._id)}>Remover contato</button>
                                        </div>

                                    ):''}
                                </div>

                            </li>

                            <li className='comBtnExclui'>
                                <div className="intemA">
                                    {contactEmergencySaved && contactEmergencySaved._id !== undefined ?
                                    (
                                        <div className="intemB btnExclui">
                                                <button onClick={() => handleDelete(contactEmergencySaved && contactEmergencySaved._id)}>Remover contato</button>
                                        </div>

                                    ):''}

                                </div>
                                <div className="intemB">
                                    <form onSubmit={handleSubmit} className="btn-salvar">
                                        <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                    </form>
                                </div>

                            </li>


                        </ul>
                    </div>
                </Modal>
        </>
    );
}
export default ColProfileEmergencyContacts;
