import React from 'react';
import { NavLink } from 'react-router-dom'


export default function subMenuScore({props}) {
    const path = props.match.path;

    return (
        <>
            <ul className="sub-menu-geral">
                <li>
                    <NavLink to="/ponto" exact activeClassName="active" ><span>Visão geral</span> </NavLink>
                </li>
                {/* <li>
                    <span className="bolinha-notificacao"></span>
                    <NavLink to="/ponto/alertas" exact activeClassName="active" ><span>Alertas</span> </NavLink>
                </li> */}
                <li>
                    <NavLink to="/ponto/jornadas" className={
                             path === "/ponto/jornadas" ||
                             path === '/ponto/editar-jornada/:id' ||
                             path === "/ponto/cadastrar-jornada" ? "active" : ""
                    } ><span>Jornadas</span> </NavLink>
                </li>
                {/* <li>
                    <span className="bolinha-notificacao"></span>
                    <NavLink to="/ponto/aprovacoes" exact activeClassName="active" className="notif-nova"><span>Aprovações</span> </NavLink>
                </li> */}
                {/* <li>
                    <NavLink to="/ponto/configuracoes" exact activeClassName="active" ><span>Configurar</span> </NavLink>
                </li> */}
            </ul>

        </>
    );
}

