import React from 'react';
// import { NavLink } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import IconClose from '../../assets/images/close.svg';
import logo from '../../assets/images/logo-user.svg';

export default function SideBar({path, fecharSidBarAbrir}) {
    return (
        <>
        <div className='logo-btn'>
            {/* dalsdlsa */}
            <button onClick={fecharSidBarAbrir} className='btn-close'><img src={IconClose} alt="fechar menu"  /></button>

            <NavLink to="/dashboard-user" className="logo">
                    <img src={logo} alt="People View"/>
            </NavLink>
        </div>


            <nav>
                <ul>
                    <li>
                        <NavLink to="/dashboard-user" exact activeClassName="active" className="link"><i></i>
                        Resumo
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/meus-dados/pessoal"

                        className={
                            path?.path === '/meus-dados/anotacoes' ||
                            path?.path === '/meus-dados/historico-salario-e-promocoes' ||
                            path?.path === '/meus-dados/historico-ultimas-alteracoes' ||
                            path?.path === '/meus-dados/profissional/jornada-de-trabalho' ||
                            path?.path === '/meus-dados/profissional/folha-de-pagamento' ||
                            path?.path === '/meus-dados/profissional/exame-periotico' ||
                            path?.path === '/meus-dados/profissional/termos-e-contratos' ||
                            path?.path === '/meus-dados/profissional' ||
                            path?.path === '/meus-dados/pessoal/faltas-e-afastamentos' ||
                            path?.path === '/meus-dados/pessoal/documentos' ||
                            path?.path === '/meus-dados/pessoal/enderecos-contatos' ||
                            path?.path === '/configuracao/alterar-senha' ||
                            path?.path === '/meus-dados/pessoal/'?
                        "active link" :"link"
                        }><i></i>Meus dados</NavLink>
                    </li>

                    <li>
                        <NavLink to="/minhas-habilidades"  exact activeClassName="active" className="link"><i></i>Minhas Habilidades</NavLink>
                    </li>
                    <li>
                        <NavLink to="/minhas-ferias"  exact activeClassName="active" className="link"><i></i>Minhas férias</NavLink>
                    </li>
                    <li>
                        <NavLink to="/meus-beneficios"  exact activeClassName="active" className="link"><i></i>Meus benefícios</NavLink>
                    </li>

                    <li>
                        <NavLink to="/meus-pontos"  exact activeClassName="active" className="link"><i></i>Meus pontos</NavLink>
                    </li>

                    <li>
                        <NavLink to="/contato-com-rh"  exact activeClassName="active" className="link"><i></i>Contato</NavLink>
                    </li>

                </ul>
            </nav>
        </>
    );
}
