import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/defaultUser';
import '../styles.scss';
import { toast } from 'react-toastify';
import { decodeToken } from '../../../services/auth';
import SubHeader from '../../../components/PerfilUser/subHeader';
import Notas from '../../../components/PerfilUser/Notes';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import api from '../../../services/api';

export default function PageAdmissao(props) {

    const decoded = decodeToken();
    var id = decoded.user._id;
    const { path } =props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaborator(id);
    }, [id]);

    async function loadcollaborator(id) {
        await api.get(`/collaborator-access/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    return (
        <>
            <Layout
            path={props.match.path}
            >

                <div className="profile-user">
                <InforProfile collaborator={collaborator} id={id} path={path} setCollaborator={setCollaborator}/>
                <SubHeader collaborator={collaborator} id={id}/>
                <Notas collaborator={collaborator} id={id} path={path} />
                </div>
            </Layout>
        </>
    );
}
