import React, { useEffect, useState } from 'react';
import iconNotifications from '../../assets/images/notifications.svg';
import { Dropdown } from "react-bootstrap";
import IconUserNone from '../../assets/images/iconUserNone.svg';
import { NavLink } from 'react-router-dom'
import { momentDuration } from '../../services/utils';
import { decodeToken } from '../../services/auth';
import api from '../../services/api';
import io from "socket.io-client";

export default function iconsInformation({ signatureTransactions }) {

    const decoded = decodeToken();
    var nome = decoded.user.name;
    var nome_1 = nome.split(' ')[0];

    async function logout() {
        localStorage.clear();
        setTimeout(function(){window.location.href = '/';}, 1000);
        //setTimeout(function(){history.push('/');}, 1000);
    };

    async function userView(event) {
        event.preventDefault();

        await api.get('/user-view/')
        .then(response => {

            if(response.data.token !== undefined) {

                localStorage.setItem('@peopleview/token', response.data.token);

                const decodedUserView = decodeToken();

                if (decodedUserView.user.role   === 'user') {

                    const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                    if(urltokenstatususer !== null) {
                        window.location.href = `${urltokenstatususer}`;
                    } else {
                        window.location.href = '/dashboard-user';
                    }

                }

            }

        }).catch(error => {})

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notifications, setNotifications ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationsTotal, setNotificationsTotal ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ notificationsRead, setNotificationsRead ] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadnotifications();
        loadnotificationstotal();
        loadnotificationsread();
        loadlimit();


        async function registerToSocketNotification() {
            // const socket = io(`${process.env.REACT_APP_API_URL}`);
            const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket", "polling"] });
            // const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket"] });
            socket.on("notificationRead", () => {
                loadnotificationsread();
                // loadnotifications();

                if(localStorage.getItem('@peopleview/limit') > 4) {
                    showMore()
                }
                else {

                    loadnotifications();
                }

            });
        }
        registerToSocketNotification();


    }, []);



    async function loadnotificationsread() {

        await api.get('/notification-read/')
        .then(response => {

            setNotificationsRead(response.data[0]);

        }).catch(error => {});
    }


    async function loadnotifications() {
        // setLoadPageNotifications(true)
        await api.get('/notification/')
        .then(response => {

            // setLoadPageNotifications(false)
            if(response.data[0] !== undefined) {

                setNotifications(response.data);

            }
        }).catch(error => {});
    }

    async function loadnotificationstotal() {
        // setLoadPageNotifications(true)
        await api.get('/notification-total/')
        .then(response => {

            setNotificationsTotal(response.data);

        }).catch(error => {});
    }

    async function readTrue(event) {


        await api.put(`/notification/${event}`, { read: true })
        .then(response => {

            loadnotifications();


        }).catch(error => {});

    }


    async function loadlimit() {

        localStorage.setItem('@peopleview/limit', 4);

    }


    async function showMore() {

        let limite = 4;

        const limitStorage = localStorage.getItem('@peopleview/limit');

        let limit = (parseInt(limitStorage) + parseInt(limite))

        localStorage.setItem('@peopleview/limit', limit);
        await api.get(`notification/?showmore=true&limite=${limit}`)
        .then(response => {


            setNotifications(response.data);


        }).catch(error => {});


    }


    async function indexUpdate() {
        try{
            const response = await api.get('/notification-update-all/')
            if(response.data[0] !== undefined) {
                setNotifications(response.data);
            }
            await loadnotifications();

        }catch(error){
            console.log("error", error)
        }

    }



    return (
        <>
        {
            (decoded &&
            decoded.user &&
            decoded.user.companyId &&
            decoded.user.companyId.status === 'Ativo' &&
            decoded && decoded.user && decoded.user.role === 'admin')
            ||
            (decoded &&
            decoded.user &&
            decoded.user.companyId &&
            decoded.user.companyId.status === 'Ativo' &&
            decoded && decoded.user && decoded.user.role === 'master')
            ||
            (decoded &&
            decoded.user &&
            decoded.user.companyId &&
            decoded.user.companyId.status === 'Trial' &&
            decoded && decoded.user && decoded.user.role === 'admin')
            ||
            (decoded &&
            decoded.user &&
            decoded.user.companyId &&
            decoded.user.companyId.status === 'Trial' &&
            decoded && decoded.user && decoded.user.role === 'master')
            ?
            <>
            <div className="dropdown-notificacao">
            <Dropdown  className="dropdown-icon">
                <Dropdown.Toggle>
                    <span className={
                        notificationsRead && notificationsRead.length !== 0 ? "bolinha-notificacao" : ''
                    }
                    ></span>
                    <img alt="Notificações" src={iconNotifications} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="header-notif">
                        <p>Notificações</p>
                        <button onClick={indexUpdate}>Marcar tudo como lido</button>
                    </div>
                    <div className="lista-notif">
                        {
                            notifications && notifications.length !== 0 ?
                            notifications
                            .filter(filternotofocation => filternotofocation.categorycandidate !== 'candidate')
                            .map((notification, index) => (
                                // messageCommunicationWithRH
                                notification.messageCommunicationWithRH && notification.messageCommunicationWithRH !== undefined ?
                                    decoded && decoded.user && decoded.user.sector && decoded.user.sector === 'Recursos Humanos' ?
                                    <>
                                    <NavLink  key={notification?._id}  onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to="/contato-com-colaborador" exact>

                                        <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                            {
                                                notification.messageCommunicationWithRH                        &&
                                                notification.messageCommunicationWithRH.collaborator           &&
                                                notification.messageCommunicationWithRH.collaborator.avatar    &&
                                                notification.messageCommunicationWithRH.collaborator.avatar !== undefined ?
                                                <>
                                                <div className="img-col">
                                                    <img src={notification.messageCommunicationWithRH.collaborator.avatar} alt="nome" />
                                                </div>
                                                </>
                                                :
                                                <>
                                                <div className="colSemFoto">
                                                    <div className="semImgCol">
                                                        <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                    </div>
                                                </div>
                                                </>
                                            }
                                            <div className="infor-vento">
                                                <div className="nome-data">
                                                    <p>
                                                        {
                                                            notification.messageCommunicationWithRH                   &&
                                                            notification.messageCommunicationWithRH.collaborator      &&
                                                            notification.messageCommunicationWithRH.collaborator.name ?
                                                            notification.messageCommunicationWithRH.collaborator.name :
                                                            ''
                                                        }
                                                        </p><span>Há {momentDuration(notification.createdAt)}</span>
                                                </div>
                                                <span className="nome-evento">enviou mensagem</span>
                                            </div>
                                        </div>
                                    </NavLink>

                                    </>
                                    :
                                    ''
                                :
                                //collaboratorAdmissionProcess
                                notification.collaboratorAdmissionProcess && notification.collaboratorAdmissionProcess !== undefined ?
                                <>
                                <NavLink  key={notification?._id}  onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to={`/colaboradores/nova-admissao/formulario-beneficios/${notification.collaboratorAdmissionProcess._id}`} exact>

                                    <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                        {
                                            notification.collaboratorAdmissionProcess        &&
                                            notification.collaboratorAdmissionProcess.avatar &&
                                            notification.collaboratorAdmissionProcess.avatar !== undefined ?
                                            <>
                                            <div className="img-col">
                                                <img src={notification.collaboratorAdmissionProcess.avatar} alt="nome" />
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <div className="infor-vento">
                                            <div className="nome-data">
                                                <p>
                                                    {
                                                        notification.collaboratorAdmissionProcess &&
                                                        notification.collaboratorAdmissionProcess.name ?
                                                        notification.collaboratorAdmissionProcess.name :
                                                        ''
                                                    }
                                                    </p><span>Há {momentDuration(notification.createdAt)}</span>
                                            </div>
                                            <span className="nome-evento">Completou formulário de admissão</span>
                                        </div>
                                    </div>
                                </NavLink>
                                </>
                                :
                                notification.categoryvacations && notification.categoryvacations !== undefined ?
                                <>
                                <NavLink  key={notification?._id}  onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to="/ferias" exact>

                                    <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                        {
                                            notification.vacations &&
                                            notification.vacations.collaborator &&
                                            notification.vacations.collaborator.avatar !== undefined ?
                                            <>
                                            <div className="img-col">
                                                <img src={notification.vacations.collaborator.avatar} alt="nome" />
                                            </div>
                                            </>
                                            :
                                            <>
                                            <div className="colSemFoto">
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <div className="infor-vento">
                                            <div className="nome-data">
                                                <p>
                                                    {
                                                        notification.vacations &&
                                                        notification.vacations.collaborator &&
                                                        notification.vacations.collaborator.name ?
                                                        notification.vacations.collaborator.name :
                                                        ''
                                                    }
                                                    </p><span>Há {momentDuration(notification.createdAt)}</span>
                                            </div>
                                            <span className="nome-evento">Solicitou férias</span>
                                        </div>
                                    </div>
                                </NavLink>
                                </>
                                :
                                notification.categoryfeedback && notification.categoryfeedback !== undefined ?
                                <>
                                <NavLink key={notification?._id} onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to="/colaboradores/ativos" exact>
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.feedback.responsible.avatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.feedback.responsible.avatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{notification.feedback.responsible.name}</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">Adicionou um feedback</span>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                notification.categoryadverts && notification.categoryadverts === 'birthdaysOfTheDay' ?
                                <>
                                <NavLink  key={notification?._id}
                                onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to="/mural-de-anuncios"

                                exact
                                >
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.adverts &&
                                        notification.adverts.collaborator &&
                                        notification.adverts.collaborator.avatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.adverts.collaborator.avatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                            notification.adverts &&
                                            notification.adverts.collaborator &&
                                            notification.adverts.collaborator.name}</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">Fez aniversário</span>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                notification.categoryeventscalendar && notification.categoryeventscalendar !== undefined ?
                                <>
                                <NavLink key={notification?._id} onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to="/dashboard" exact>
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.collaboratoreventscalendar &&
                                        notification.collaboratoreventscalendar.collaborator &&
                                        notification.collaboratoreventscalendar.collaborator.avatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={
                                                notification.collaboratoreventscalendar.collaborator.avatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                                notification.collaboratoreventscalendar &&
                                                notification.collaboratoreventscalendar.collaborator &&
                                                notification.collaboratoreventscalendar.collaborator.name}</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <p className="add-evento">Adicionou o evento</p>
                                    <span className="nome-evento">{
                                        notification.collaboratoreventscalendar &&
                                        notification.collaboratoreventscalendar.eventscalendar ?
                                        notification.collaboratoreventscalendar.eventscalendar.eventName : ''}</span>
                                    </div>
                                </div>
                                </NavLink>

                                </>
                                :
                                // categorycollaboratorabsences
                                notification.categorycollaboratorabsences &&
                                notification.categorycollaboratorabsences !== undefined &&
                                notification.collaboratorabsences         !== undefined &&
                                notification.collaboratorabsences         !== null  ?
                                <>
                                <NavLink  key={notification?._id}
                                onClick={notification.read === true ? () => {} : () => readTrue(notification._id)}
                                to={`/colaboradores/${notification.collaboratorabsences.collaborator._id}/pessoal/faltas-e-afastamentos`}
                                // ref={focusNotification}
                                exact
                                >
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.collaboratorabsences &&
                                        notification.collaboratorabsences.collaborator &&
                                        notification.collaboratorabsences.collaborator.avatar &&
                                        notification.collaboratorabsences.collaborator.avatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.collaboratorabsences.collaborator.avatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                                notification.collaboratorabsences &&
                                                notification.collaboratorabsences.collaborator &&
                                                notification.collaboratorabsences.collaborator.name ?
                                                notification.collaboratorabsences.collaborator.name :
                                                ''
                                            }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">Notificou</span>
                                        <p className="add-evento">{
                                            notification.collaboratorabsences &&
                                            notification.collaboratorabsences.reason ?
                                            notification.collaboratorabsences.reason :
                                            ''
                                        }</p>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                // collaboratorPersonalDataNew
                                notification.collaboratorPersonalDataNew &&
                                notification.collaboratorPersonalDataNew !== undefined ?
                                <>
                                <NavLink  key={notification?._id}
                                onClick={notification.read === true ? () => {} : () => readTrue(notification._id)}
                                to={
                                    notification.collaboratorPersonalDataNew === 'Vence em 10 dias.' ?
                                    `/colaboradores/${notification.collaboratorId}/profissional/`
                                    :
                                    `/colaboradores/${notification.collaboratorId}/pessoal/`
                                }
                                // ref={focusNotification}
                                exact
                                >
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.collaboratorPersonalDataNew &&
                                        notification.collaboratorAvatar &&
                                        notification.collaboratorAvatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.collaboratorAvatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                                notification.collaboratorPersonalDataNew &&
                                                notification.collaboratorPersonalDataOld ?
                                                notification.collaboratorPersonalDataOld :
                                                ''
                                            }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">{notification.collaboratorPersonalDataText}</span>
                                        <p className="add-evento">{
                                            notification.collaboratorPersonalDataNew ?
                                            notification.collaboratorPersonalDataNew :
                                            ''
                                        }</p>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                // categoryvacationsincommitng
                                notification.categoryvacationsincommitng &&
                                notification.categoryvacationsincommitng !== undefined ?
                                <>
                                <NavLink  key={notification?._id}
                                onClick={notification.read === true ? () => {} : () => readTrue(notification._id)}
                                to={`/ferias`}
                                exact
                                >
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.collaboratorAvatar &&
                                        notification.collaboratorAvatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.collaboratorAvatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                                notification.collaboratorName ?
                                                notification.collaboratorName :
                                                ''
                                            }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">{notification.collaboratorPersonalDataText}</span>
                                        <p className="add-evento">{
                                            notification.textvacationsincommitng ?
                                            notification.textvacationsincommitng :
                                            ''
                                        }</p>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                // originalnameIdentification
                                notification.originalnameIdentification &&
                                notification.originalnameIdentification !== undefined ?
                                <>
                                <NavLink  key={notification?._id}
                                onClick={notification.read === true ? () => {} : () => readTrue(notification._id)}
                                to={`/colaboradores/${notification.collaboratorId}/pessoal/`}
                                // ref={focusNotification}
                                exact
                                >
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.originalnameIdentification &&
                                        notification.collaboratorAvatar &&
                                        notification.collaboratorAvatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.collaboratorAvatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                                notification.originalnameIdentification &&
                                                notification.collaboratorName ?
                                                notification.collaboratorName :
                                                ''
                                            }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">{notification.collaboratorPersonalDataText}</span>
                                        <p className="add-evento">{
                                            notification.originalnameIdentification ?
                                            notification.originalnameIdentification :
                                            ''
                                        }</p>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                // originalnameIdentification
                                notification.originalnameDisability &&
                                notification.originalnameDisability !== undefined ?
                                <>
                                <NavLink  key={notification?._id}
                                onClick={notification.read === true ? () => {} : () => readTrue(notification._id)}
                                to={`/colaboradores/${notification.collaboratorId}/pessoal/`}
                                // ref={focusNotification}
                                exact
                                >
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    {
                                        notification.originalnameDisability &&
                                        notification.collaboratorAvatar &&
                                        notification.collaboratorAvatar !== undefined ?
                                        <>
                                        <div className="img-col">
                                            <img src={notification.collaboratorAvatar} alt="nome" />
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                        </>
                                    }
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>{
                                                notification.originalnameDisability &&
                                                notification.collaboratorPersonalDataOld ?
                                                notification.collaboratorPersonalDataOld :
                                                ''
                                            }</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">{notification.collaboratorPersonalDataText}</span>
                                        <p className="add-evento">{
                                            notification.originalnameDisability ?
                                            notification.originalnameDisability :
                                            ''
                                        }</p>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                // planTrialStatus
                                notification.planTrialStatus && notification.planTrialStatus !== undefined ?
                                <>
                                <NavLink  key={notification?._id} onClick={notification.read === true ? () => {} : () => readTrue(notification._id)} to="/configuracao/plano-e-cobranca" exact>
                                <div className={notification.read === true ? "notificacao-normal visualizado" : "notificacao-normal nao-visualizado"} >
                                    <>
                                    <div className="colSemFoto">
                                        <div className="semImgCol">
                                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                                        </div>
                                    </div>
                                    </>
                                    <div className="infor-vento">
                                        <div className="nome-data">
                                            <p>People View!</p><span>Há {momentDuration(notification.createdAt)}</span>
                                        </div>
                                        <span className="nome-evento">Notificou</span>
                                        <p className="add-evento">{notification.planTrialStatus}</p>
                                    </div>
                                </div>
                                </NavLink>
                                </>
                                :
                                ''
                            ))

                            :
                            ''
                        }

                        {
                        //     <NavLink to="/"  exact >
                        //     <div className="adicionou-ao-evento nao-visualizado">
                        //         <div className="img-col">
                        //             <img src={ImgExemplo2} alt="nome" />
                        //         </div>
                        //         {/* esse e a imgem do gesto sem foto */}
                        //         <div className="colSemFoto"  style={{display:'none'}}>
                        //             <div className="semImgCol">
                        //                 <img  src={IconUserNone} alt="Usuario sem imagem" />
                        //             </div>
                        //         </div>
                        //         <div className="infor-vento">
                        //             <div className="nome-data">
                        //                 <p>Carolina Martini</p><span>Há 2 min</span>
                        //             </div>
                        //             <p className="add-evento">Adicionou você ao evento</p>
                        //             <span className="nome-evento">Entrevista Helena</span>
                        //         </div>
                        //     </div>
                        // </NavLink>
                        // <NavLink to="/"  exact>
                        //     <div className="notificacao-normal nao-visualizado">
                        //         <div className="img-col">
                        //             <img src={ImgExemplo} alt="nome" />
                        //         </div>
                        //         {/* esse e a imgem do gesto sem foto */}
                        //         <div className="colSemFoto"  style={{display:'none'}}>
                        //             <div className="semImgCol">
                        //                 <img  src={IconUserNone} alt="Usuario sem imagem" />
                        //             </div>
                        //         </div>
                        //         <div className="infor-vento">
                        //             <div className="nome-data">
                        //                 <p>Carolina Martini</p><span>Há 2 min</span>
                        //             </div>
                        //             <span className="nome-evento">Adicionou um feedback</span>
                        //         </div>
                        //     </div>
                        // </NavLink>

                        // <NavLink to="/"  exact>
                        //     <div className="notificacao-normal visualizado">
                        //         <div className="img-col">
                        //             <img src={ImgExemplo2} alt="nome" />
                        //         </div>
                        //         {/* esse e a imgem do gesto sem foto */}
                        //         <div className="colSemFoto"  style={{display:'none'}}>
                        //             <div className="semImgCol">
                        //                 <img  src={IconUserNone} alt="Usuario sem imagem" />
                        //             </div>
                        //         </div>
                        //         <div className="infor-vento">
                        //             <div className="nome-data">
                        //                 <p>Carolina Martini</p><span>Há 2 min</span>
                        //             </div>
                        //             <span className="nome-evento">Adicionou um feedback </span>
                        //         </div>
                        //     </div>
                        // </NavLink>
                        }
                    </div>
                    <div className="btn-vermais">
                        {
                            notifications && notifications.length === notificationsTotal ?
                            ''
                            :
                            <button onClick={showMore} >Mostrar mais</button>
                        }
                    </div>

                </Dropdown.Menu>
            </Dropdown>
            </div>
            </>
            :
            ''
        }

            <div  className="dropdown-user">
                <Dropdown  className="dropdown-icon">
                    <Dropdown.Toggle>
                        {/* <img alt="Usuario" src={iconUser} />
                         */}


                        {
                            decoded.user.role === "master" ?

                                decoded.user.companyId.logo && decoded.user.companyId.logo !== 'undefined' ?
                                    <div className="img-col" >
                                    <img src={decoded.user.companyId.logo} alt={decoded.user.name} />
                                    </div>
                                : (
                                    <>
                                    <div className="colSemFoto" >
                                        <div className="semImgCol">
                                            <img  src={IconUserNone} alt={decoded.user.name} />
                                        </div>
                                    </div>
                                    </>
                                )
                            :
                            decoded.user.avatar && decoded.user.avatar !== 'undefined' ?
                                <div className="img-col" >
                                <img src={decoded.user.avatar} alt={decoded.user.name} />
                                </div>
                            : (
                                <>
                                <div className="colSemFoto" >
                                    <div className="semImgCol">
                                        <img  src={IconUserNone} alt={decoded.user.name} />
                                    </div>
                                </div>
                                </>
                            )
                        }
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="infor-col">

                        {
                            decoded.user.role === "master" ?

                                decoded.user.companyId.logo && decoded.user.companyId.logo !== 'undefined' ?
                                    <div className="img-col" >
                                    <img src={decoded.user.companyId.logo} alt={decoded.user.name} />
                                    </div>
                                : (
                                    <>
                                    <div className="colSemFoto" >
                                        <div className="semImgCol">
                                            <img  src={IconUserNone} alt={decoded.user.name} />
                                        </div>
                                    </div>
                                    </>
                                )
                            :
                            decoded.user.avatar && decoded.user.avatar !== 'undefined' ?
                                <div className="img-col" >
                                <img src={decoded.user.avatar} alt={decoded.user.name} />
                                </div>
                            : (
                                <>
                                <div className="colSemFoto" >
                                    <div className="semImgCol">
                                        <img  src={IconUserNone} alt={decoded.user.name} />
                                    </div>
                                </div>
                                </>
                            )
                        }
                            {/* esse e a imgem do gesto sem foto */}
                            <p className="nome">
                                Olá, {nome_1 } :)
                            </p>
                        </div>
                        {/* <button type="button" name="Ver como colaborador">
                            Ver como colaborador
                        </button> */}
                        {
                            decoded &&
                            decoded.user &&
                            decoded.user.companyId &&
                            decoded.user.companyId.status === 'Ativo' &&
                            decoded && decoded.user && decoded.user.role === 'admin' ?
                            <>
                                <NavLink to="/configuracao/empresas-cadastradas"  exact>
                                    Empresas cadastradas
                                </NavLink>
                                <NavLink to="/configuracao/plano-e-cobranca"  exact>
                                    Plano e cobrança
                                </NavLink>
                                <NavLink to="/configuracao/dados-da-empresa/"  exact>
                                    Configurações
                                </NavLink>
                                <button type="button" name="Exclui-lista" onClick={userView}>
                                    Acessar como colaborador
                                </button>
                                <button type="button" name="Exclui-lista" onClick={logout}>
                                    Sair
                                </button>
                            </>
                            :
                            decoded &&
                            decoded.user &&
                            decoded.user.companyId &&
                            decoded.user.companyId.status === 'Ativo' &&
                            decoded && decoded.user && decoded.user.role === 'master' ?
                            <>
                                <NavLink to="/configuracao/empresas-cadastradas"  exact>
                                    Empresas cadastradas
                                </NavLink>
                                <NavLink to="/configuracao/plano-e-cobranca"  exact>
                                    Plano e cobrança
                                </NavLink>
                                <NavLink to="/configuracao/dados-da-empresa/"  exact>
                                    Configurações
                                </NavLink>
                                <button type="button" name="Exclui-lista" onClick={logout}>
                                    Sair
                                </button>
                            </>
                            :
                            decoded &&
                            decoded.user &&
                            decoded.user.companyId &&
                            decoded.user.companyId.status === 'Trial' &&
                            decoded && decoded.user && decoded.user.role === 'master' ?
                            <>
                                <NavLink to="/configuracao/empresas-cadastradas"  exact>
                                    Empresas cadastradas
                                </NavLink>
                                <NavLink to="/configuracao/plano-e-cobranca"  exact>
                                    Plano e cobrança
                                </NavLink>
                                <NavLink to="/configuracao/dados-da-empresa/"  exact>
                                    Configurações
                                </NavLink>
                                <button type="button" name="Exclui-lista" onClick={logout}>
                                    Sair
                                </button>
                            </>
                            :
                            // decoded &&
                            // decoded.user &&
                            // decoded.user.companyId &&
                            // // decoded.user.companyId.status === 'Ativo' &&
                            decoded && decoded.user && decoded.user.role === 'manager' ?
                            <>
                                <button type="button" name="Exclui-lista" onClick={userView}>
                                Acessar como colaborador
                                </button>
                                <button type="button" name="Exclui-lista" onClick={logout}>
                                    Sair
                                </button>
                            </>
                            :
                            <>
                                <NavLink to="/configuracao/plano-e-cobranca"  exact>
                                    Plano e cobrança
                                </NavLink>
                                <button type="button" name="Exclui-lista" onClick={logout}>
                                    Sair
                                </button>
                            </>
                        }
                        {/* <NavLink to="/"  exact>
                            Central de ajuda
                        </NavLink> */}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );

}
