import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import { Dropdown } from "react-bootstrap";
import Carregando from '../../../../assets/images/carregando.gif';
import iconVerMais from '../../../../assets/images/more.svg';
import { FormataStringDataToAdmission } from '../../../../services/utils';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import swal from 'sweetalert';
import { Scrollbars } from 'react-custom-scrollbars';

import api from '../../../../services/api';

export default function AdmissaoColaboradores ({
    path,
    loadFilter,
    sendReminder,
    excludeAdmssion,
    myChangeHandlerExcludeAdmssion,
    myChangeHandlerUpdateToken,
    load,
    loadButtomGerenateNew,
    loadIDButtom,
    stepOne,
    stepTwo,
    stepThree,
    stepFour,
    loadstepone
}) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mbAdmissaoSelect, setMbAdmissaoSelect] = useState(false);
    const [loadSaveAndFinaly, setLoadSaveAndFinaly] = useState(false);
    async function saveandfinaly(collaboratorstepFour) {
        setLoadSaveAndFinaly(true)
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        formData.append('stepOne', 'true');
        formData.append('stepTwo', 'true');
        formData.append('stepThree', 'true');
        formData.append('stepFour', 'true');
        formData.append('status', 'Ativo');
        formData.append('path', path);

        setLoadSaveAndFinaly(true);
        await api.put(`/collaborator/${collaboratorstepFour._id}`, formData, config)
        .then(response => {
            if(
                response.data.status === 'Ativo'
            ) {
                localStorage.removeItem('@peopleview/countCollaborators');
                localStorage.removeItem('@peopleview/coutAdmission');
                localStorage.removeItem('@peopleview/shutdowProcess');
                async function loadStoreCollaboratorVestingPeriod() {
                    let nameCollaborator  = response.data.name;
                    let sectorCollaborator = '';
                    if(response?.data?.sector !== undefined) {
                        sectorCollaborator = response.data.sector;
                    }
                    else {
                        sectorCollaborator = '-';
                    }
                    let admissionDate     = response.data.admissionDate;
                    // Cria todos os períodos aquisitivos => storeCollaboratorVestingPeriod
                    await api.post('/collaborator-vesting-period/', {
                        companyId: response.data.companyId,
                        collaborator: response.data._id,
                        nameCollaborator,
                        sectorCollaborator,
                        admissionDate,
                    })
                    .then(response => {
                        loadstepone();
                        setLoadSaveAndFinaly(false);
                    })
                    .catch(error => {
                        swal({
                            icon: "error", title: "Erro!",
                            text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema."
                        });

                    });
                }
                loadStoreCollaboratorVestingPeriod()

                setTimeout(function(){
                    async function loadUpdateVestingPeriodFirstBalance() {
                        // => Atualiza todos os saldos dos períodos aquisitivos
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : collaboratorstepFour.companyId._id,
                            collaborator: collaboratorstepFour._id
                        })
                        .then(response => {}).catch(error => {
                            loadstepone();
                            swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });

                        });

                        let personalEmail = '';
                        let email         = '';

                        if(
                            collaboratorstepFour?.email
                            &&
                            collaboratorstepFour?.personalEmail === undefined
                        ) {

                            email         = collaboratorstepFour?.email
                            personalEmail = collaboratorstepFour?.email;

                        }
                        else
                        if(
                            collaboratorstepFour?.email === undefined
                            &&
                            collaboratorstepFour?.personalEmail
                        ) {

                            email         = collaboratorstepFour?.personalEmail
                            personalEmail = collaboratorstepFour?.personalEmail;

                        }
                        else {
                            email         = collaboratorstepFour?.email
                            personalEmail = collaboratorstepFour?.personalEmail;
                        }

                        if(
                            email         !== undefined &&
                            personalEmail !== undefined
                        ) {
                            async function releaseSystemAccessToTheCollaborator() {
                                const formData = new FormData();
                                const config = {
                                    headers: {
                                        'content-type': 'multipart/form-data'
                                    }
                                }
                                formData.append('freeAccess', true);
                                formData.append('path', path);
                                await api.put(`/collaborator/${collaboratorstepFour._id}`, formData, config)
                                .then(response => {
                                    loadstepone();
                                    window.location.href = `/colaboradores/nova-admissao/finalizado/${collaboratorstepFour._id}`;
                                    swal({ icon: "success", title: "Sucesso!", text: "Acesso liberado com sucesso! Vá até a página de ativos para visualizar este colaborador!" });

                                }).catch(error => {


                                    loadstepone();
                                    setLoadSaveAndFinaly(false)

                                    swal({
                                        icon: "error", title: "Erro!", text: error?.response?.data?.message ?
                                        error?.response?.data?.message
                                        :
                                        'Colaborador concluído com sucesso! Porem suas informações não foram enviadas em seu e-mail pessoal, você deve ir no cadastro do colaborador, na área “Dados de acesso” e informar ao colaborador que ele pode se conectar ao sistema utilizando o seu e-mail ou CPF e a senha temporária cadastrada!'
                                    });

                                });

                            }
                            releaseSystemAccessToTheCollaborator()

                        } else {

                            setTimeout(function(){

                                setLoadSaveAndFinaly(false)
                                // setVisible(false);
                                window.location.href = `/colaboradores/nova-admissao/finalizado/${collaboratorstepFour._id}`;
                                swal({ icon: "success", title: "Sucesso!", text: "Acesso liberado com sucesso! Vá até a página de ativos para visualizar este colaborador!" });

                            }, 3000)

                        }

                    }
                    loadUpdateVestingPeriodFirstBalance()

                }, 500);
            }
        }).catch(error => {
            swal({
                icon: "error", title: "Erro!",
                text: error?.response?.data?.message ?
                error?.response?.data?.message
                :
                'Não foi possível concluir o colaborador, por favor, entre em contato com o administrador do sistema.'
            });
        });
}


    return (
        <>
        <div className='admissionProcess-mobile'>
            <h2>Em processo de admissão</h2>

            <div className='select-admissionProcess'>
                <p className='titulo'>Mostrando</p>
                <div className='cont-select'>
                    <button className='select-'>
                            {
                                mbAdmissaoSelect.admmisaoSelect === "Pendente" ||  mbAdmissaoSelect.admmisaoSelect === false || mbAdmissaoSelect.admmisaoSelect === undefined ?
                                <>
                                <p className='pending'>
                                    <span></span> Pendente
                                </p>
                                </>
                                : mbAdmissaoSelect.admmisaoSelect === "Aguardando preenchimento" ?
                                <>
                                <p className='waitingFill'>
                                    <span></span> Aguardando preenchimento
                                </p>
                                </>
                                : mbAdmissaoSelect.admmisaoSelect === "Configurar benefícios" ?
                                <>
                                <p className='configBenefits'>
                                    <span></span> Configurar benefícios
                                </p>
                                </>
                                :
                                mbAdmissaoSelect.admmisaoSelect === "Concluído" ?
                                <>
                                <p className='Concluded'>
                                    <span></span> Concluído
                                </p>
                                </>
                                :
                                ""


                            }
                    </button>

                    <div className="dropDown">
                        <Scrollbars className='listDropDown'  style={{  height: 140 }}>
                            {
                            ['Pendente', 'Aguardando preenchimento', 'Configurar benefícios', 'Concluído']
                                .map((item, index) => (
                                        <button key={index} className='item'
                                                onClick={() => setMbAdmissaoSelect({ ...mbAdmissaoSelect, "admmisaoSelect": item  })}
                                        ><p>{item}</p></button>
                                ))
                            }
                        </Scrollbars>
                    </div>
                </div>
            </div>
            {
               mbAdmissaoSelect === false || mbAdmissaoSelect.admmisaoSelect === "Pendente" ?
               <>
                    <div className='list-selecionada  pending'>

                        <ul>


                            {load ? (
                                <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            loadFilter ? (
                            <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) : ("")}
                            {
                                load ? '' : stepOne.length !== 0 ? stepOne
                                .map((collaboratorStepOne, index) => (
                                    <li key={index}>
                                        <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepOne._id}`} className="foto" >
                                        {
                                            collaboratorStepOne.avatar && collaboratorStepOne.avatar !== 'undefined' ?
                                            <img alt="" src={collaboratorStepOne.avatar}  className="colComFoto"/>
                                            :
                                            <div className="semFoto">
                                                <div className="semImgCol">
                                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            </div>
                                        }
                                        </NavLink>
                                        <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepOne._id}`}  className="nome">{collaboratorStepOne.name}
                                        </NavLink>
                                        <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepOne._id}`} className="cargo">
                                            {collaboratorStepOne.occupation}
                                        </NavLink>

                                        <div className="verMais">
                                            <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                                <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                        <form onSubmit={excludeAdmssion}>
                                                            <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepOne._id} type="submit" className="red">
                                                                Excluir admissão
                                                            </button>
                                                        </form>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                        <div className="tooltipData">
                                            <div className="tooltipInfor">
                                                <i>
                                                <span className="tooltiptext">
                                                    Aguardando preenchimento
                                                </span>
                                                </i>
                                            </div>
                                            <span className="date">{collaboratorStepOne.admissionDate ? FormataStringDataToAdmission(collaboratorStepOne.admissionDate) : ''}</span>
                                        </div>
                                    </li>
                                )) :
                                    (
                                    <div className="nenhum-colaborador">
                                    <p>Nenhum colaborador nesta etapa.</p>
                                    </div>
                                )
                            }

                        </ul>
                    </div>
                    </>
               : ""
            }
            {
               mbAdmissaoSelect.admmisaoSelect === "Aguardando preenchimento" ?
                    <div className='list-selecionada  waitingFill'>
                        <ul>
                            {load ? (
                                <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            loadFilter ? (
                            <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) : ("")}
                            {
                                load ? '' : stepTwo.length !== 0 ? stepTwo
                                        .map((collaboratorStepTwo, index) => (
                                            <>
                                            <li className={collaboratorStepTwo.tokenExpired && collaboratorStepTwo.tokenExpired === true  ? "card-token-expirado" : ""} key={index}>
                                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepTwo._id}`} className={collaboratorStepTwo.avatar && collaboratorStepTwo.avatar !== 'undefined' ? "foto" : "foto"}>
                                                    {
                                                        collaboratorStepTwo.avatar && collaboratorStepTwo.avatar !== 'undefined' ?
                                                        <img alt="" src={collaboratorStepTwo.avatar}  className="colComFoto"/>
                                                        :
                                                        <div className="semFoto">
                                                            <div className="semImgCol">
                                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                                            </div>
                                                        </div>
                                                    }
                                                </NavLink>
                                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepTwo._id}`}  className="nome">{collaboratorStepTwo.name}
                                                </NavLink>
                                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepTwo._id}`} className="cargo">
                                                    {collaboratorStepTwo.occupation}
                                                </NavLink>

                                                <div className="verMais">
                                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                                        <Dropdown.Toggle>
                                                        <img alt="" src={iconVerMais} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                                <button onClick={() => sendReminder(collaboratorStepTwo)} type="button">
                                                                    Enviar lembrete
                                                                </button>
                                                                <form onSubmit={excludeAdmssion}>
                                                                    <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepTwo._id} type="submit" className="red">
                                                                        Excluir admissão
                                                                    </button>
                                                                </form>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div className="tooltipData">
                                                    <div className="tooltipInfor">
                                                        <i>
                                                        <span className="tooltiptext">
                                                            Aguardando preenchimento
                                                        </span>
                                                        </i>
                                                    </div>
                                                    <span className="date">{collaboratorStepTwo.admissionDate ? FormataStringDataToAdmission(collaboratorStepTwo.admissionDate) : ''}</span>
                                                </div>
                                                {
                                                    collaboratorStepTwo.tokenExpired && collaboratorStepTwo.tokenExpired === true  ?
                                                    <div className="renovar-token">
                                                        <p>Token expirado</p>
                                                        <button onClick={myChangeHandlerUpdateToken} value={collaboratorStepTwo._id} className={loadIDButtom === collaboratorStepTwo._id && loadButtomGerenateNew ? "btnGreen load gerarToken" : "btnGreen gerarToken"}>Gerar um novo</button>
                                                    </div>
                                                    :
                                                    ''
                                                }

                                            </li>

                                            </>
                                        )) : (
                                            <div className="nenhum-colaborador">
                                            <p>Nenhum colaborador nesta etapa.</p>
                                            </div>
                                        )}

                                        </ul>
                                    </div>
                                : ""
            }
            {
               mbAdmissaoSelect.admmisaoSelect === "Configurar benefícios" ?
                    <div className='list-selecionada  configBenefits'>
                       <ul>



                            {load ? (
                                <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            loadFilter ? (
                            <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) : ("")}

                                {
                                load ? '' : stepThree.length !== 0 ? stepThree
                                            .map((collaboratorStepThree, index) => (
                                                <li key={index}>
                                                    <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${collaboratorStepThree._id}`} className="foto">
                                                    {
                                                        collaboratorStepThree.avatar && collaboratorStepThree.avatar !== 'undefined' ?
                                                        <img alt="" src={collaboratorStepThree.avatar}  className="colComFoto"/>
                                                        :
                                                        <div className="semFoto">
                                                            <div className="semImgCol">
                                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                                            </div>
                                                        </div>
                                                    }
                                                    </NavLink>
                                                    <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${collaboratorStepThree._id}`}  className="nome">{collaboratorStepThree.name}
                                                    </NavLink>
                                                    <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${collaboratorStepThree._id}`} className="cargo">
                                                        {collaboratorStepThree.occupation}
                                                    </NavLink>

                                                    <div className="verMais">
                                                        <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                                            <Dropdown.Toggle>
                                                            <img alt="" src={iconVerMais} />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                    <form onSubmit={excludeAdmssion}>
                                                                        <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepThree._id} type="submit" className="red">
                                                                            Excluir admissão
                                                                        </button>
                                                                    </form>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="tooltipData">
                                                        <div className="tooltipInfor">
                                                            <i>
                                                            <span className="tooltiptext">
                                                                Aguardando preenchimento
                                                            </span>
                                                            </i>
                                                        </div>
                                                    <span className="date">{collaboratorStepThree.admissionDate ? FormataStringDataToAdmission(collaboratorStepThree.admissionDate) : ''}</span>
                                                    </div>
                                                </li>
                                            )) : (
                                                <div className="nenhum-colaborador">
                                                <p>Nenhum colaborador nesta etapa.</p>
                                                </div>
                                            )}
                                        </ul>
                                    </div>
                    : ""
                    }
                    {
                    mbAdmissaoSelect.admmisaoSelect === "Concluído" ?
                        <div className='list-selecionada  Concluded'>
                            {load ? (
                                <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            loadFilter ? (
                            <div className="carregando loading"><img src={Carregando} alt="carregando" /></div>
                            ) : ("")}
                            <ul>
                            {
                            load ? '' : stepFour.length !== 0 ? stepFour
                            .map((collaboratorStepThree, index) => (
                                <li key={index}>
                                    <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${collaboratorStepThree._id}`} className="foto">
                                    {
                                        collaboratorStepThree.avatar && collaboratorStepThree.avatar !== 'undefined' ?
                                        <img alt="" src={collaboratorStepThree.avatar}  className="colComFoto"/>
                                        :
                                        <div className="semFoto">
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                    }
                                    </NavLink>
                                    <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${collaboratorStepThree._id}`}  className="nome">{collaboratorStepThree.name}
                                    </NavLink>
                                    <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${collaboratorStepThree._id}`} className="cargo">
                                        {collaboratorStepThree.occupation}
                                    </NavLink>
                                    <div className="verMais">
                                        <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                    <button type="button">
                                                        Ativar Colaborador
                                                    </button>
                                                    <form onSubmit={excludeAdmssion}>
                                                        <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepThree._id} type="submit" className="red">
                                                            Excluir admissão
                                                        </button>
                                                    </form>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="tooltipData">
                                        <div className="tooltipInfor">
                                            <i>
                                            <span className="tooltiptext">
                                                Aguardando preenchimento
                                            </span>
                                            </i>
                                        </div>
                                        <span className="date">{collaboratorStepThree.admissionDate ? FormataStringDataToAdmission(collaboratorStepThree.admissionDate) : ''}</span>
                                    </div>
                                </li>
                            )) : (
                                <div className="nenhum-colaborador">
                                <p>Nenhum colaborador nesta etapa.</p>
                                </div>
                            )}

                            </ul>
                        </div>
                    : ""
                    }

        </div>
        <div className="admissionProcess">
            <h2>Em processo de admissão</h2>

            <div className="pending">
                <h3><i></i> Pendente</h3>

                {
                    load ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) :
                    loadFilter ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) : ("")
                }
                <ul>
                {
                    load ? '' : stepOne.length !== 0 ? stepOne
                        .map((collaboratorStepOne, index) => (
                            <li key={index}>
                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepOne._id}`} className="foto" >
                                {
                                    collaboratorStepOne.avatar && collaboratorStepOne.avatar !== 'undefined' ?
                                    <img alt="" src={collaboratorStepOne.avatar}  className="colComFoto"/>
                                    :
                                    <div className="semFoto">
                                        <div className="semImgCol">
                                            <img src={IconUserNone} alt="Usuario sem imagem" />
                                        </div>
                                    </div>
                                }
                                </NavLink>
                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepOne._id}`}  className="nome">{collaboratorStepOne.name}
                                </NavLink>
                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepOne._id}`} className="cargo">
                                    {collaboratorStepOne.occupation}
                                </NavLink>

                                <div className="verMais">
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                                <form onSubmit={excludeAdmssion}>
                                                    <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepOne._id} type="submit" className="red">
                                                        Excluir admissão
                                                    </button>
                                                </form>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <div className="tooltipData">
                                    <div className="tooltipInfor">
                                        <i>
                                        <span className="tooltiptext">
                                            Aguardando preenchimento
                                        </span>
                                        </i>
                                    </div>
                                    <span className="date">{collaboratorStepOne.admissionDate ? FormataStringDataToAdmission(collaboratorStepOne.admissionDate) : ''}</span>
                                </div>
                            </li>
                        )) :
                            (
                            <div className="nenhum-colaborador">
                            <p>Nenhum colaborador nesta etapa.</p>
                            </div>
                        )}
                </ul>


            </div>
            <div className="waitingFill">
                <h3><i></i> Aguardando preenchimento</h3>

                {
                    load ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) :
                    loadFilter ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) : ("")
                }
                <ul>
                {
                load ? '' : stepTwo.length !== 0 ? stepTwo
                        .map((collaboratorStepTwo, index) => (

                            <li className={collaboratorStepTwo.tokenExpired && collaboratorStepTwo.tokenExpired === true  ? "card-token-expirado" : ""} key={index}>
                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepTwo._id}`} className={collaboratorStepTwo.avatar && collaboratorStepTwo.avatar !== 'undefined' ? "foto" : "foto"}>
                                    {
                                        collaboratorStepTwo.avatar && collaboratorStepTwo.avatar !== 'undefined' ?
                                        <img alt="" src={collaboratorStepTwo.avatar}  className="colComFoto"/>
                                        :
                                        <div className="semFoto">
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                        </div>
                                    }
                                </NavLink>
                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepTwo._id}`}  className="nome">{collaboratorStepTwo.name}
                                </NavLink>
                                <NavLink to={`/colaboradores/nova-admissao/formulario-dados-pessoais/${collaboratorStepTwo._id}`} className="cargo">
                                    {collaboratorStepTwo.occupation}
                                </NavLink>

                                <div className="verMais">
                                    <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                                <button onClick={() => sendReminder(collaboratorStepTwo)} type="button">
                                                    Enviar lembrete
                                                </button>
                                                <form onSubmit={excludeAdmssion}>
                                                    <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepTwo._id} type="submit" className="red">
                                                        Excluir admissão
                                                    </button>
                                                </form>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="tooltipData">
                                    <div className="tooltipInfor">
                                        <i>
                                        <span className="tooltiptext">
                                            Aguardando preenchimento
                                        </span>
                                        </i>
                                    </div>
                                    <span className="date">{collaboratorStepTwo.admissionDate ? FormataStringDataToAdmission(collaboratorStepTwo.admissionDate) : ''}</span>
                                </div>
                                {
                                    collaboratorStepTwo.tokenExpired && collaboratorStepTwo.tokenExpired === true  ?
                                    <div className="renovar-token">
                                        <p>Token expirado</p>
                                        <button onClick={myChangeHandlerUpdateToken} value={collaboratorStepTwo._id} className={loadIDButtom === collaboratorStepTwo._id && loadButtomGerenateNew ? "btnGreen load gerarToken" : "btnGreen gerarToken"}>Gerar um novo</button>
                                    </div>
                                    :
                                    ''
                                }

                            </li>
                        )) : (
                            <div className="nenhum-colaborador">
                            <p>Nenhum colaborador nesta etapa.</p>
                            </div>
                        )}

                </ul>

            </div>
            <div className="configBenefits">
                <h3><i></i> Configurar benefícios</h3>
                {
                    load ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) :
                    loadFilter ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) : ("")
                }
                <ul>
                {
                load ? '' : stepThree.length !== 0 ? stepThree
                    .map((collaboratorStepThree, index) => (
                        <li key={index}>
                            <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${collaboratorStepThree._id}`} className="foto">
                            {
                                collaboratorStepThree.avatar && collaboratorStepThree.avatar !== 'undefined' ?
                                <img alt="" src={collaboratorStepThree.avatar}  className="colComFoto"/>
                                :
                                <div className="semFoto">
                                    <div className="semImgCol">
                                        <img src={IconUserNone} alt="Usuario sem imagem" />
                                    </div>
                                </div>
                            }
                            </NavLink>
                            <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${collaboratorStepThree._id}`}  className="nome">{collaboratorStepThree.name}
                            </NavLink>
                            <NavLink to={`/colaboradores/nova-admissao/formulario-beneficios/${collaboratorStepThree._id}`} className="cargo">
                                {collaboratorStepThree.occupation}
                            </NavLink>

                            <div className="verMais">
                                <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                            {/* <button onClick={() => sendReminder(collaboratorStepThree)} type="button">
                                                Enviar lembrete
                                            </button> */}
                                            <form onSubmit={excludeAdmssion}>
                                                <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorStepThree._id} type="submit" className="red">
                                                    Excluir admissão
                                                </button>
                                            </form>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="tooltipData">
                                <div className="tooltipInfor">
                                    <i>
                                    <span className="tooltiptext">
                                        Aguardando preenchimento
                                    </span>
                                    </i>
                                </div>
                            <span className="date">{collaboratorStepThree.admissionDate ? FormataStringDataToAdmission(collaboratorStepThree.admissionDate) : ''}</span>
                                {/* <span className="date">{collaboratorStepThree.admissionDate ? collaboratorStepThree.admissionDate : ''}</span> */}
                            </div>
                        </li>
                    )) : (
                        <div className="nenhum-colaborador">
                        <p>Nenhum colaborador nesta etapa.</p>
                        </div>
                    )}

                </ul>

            </div>
            <div className="Concluded">
                <h3><i></i> Concluído</h3>

                {
                    load ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) :
                    loadFilter ? ( <div className="carregando loading"><img src={Carregando} alt="carregando" /></div> ) : ("")
                }
                <ul>


                {
                load ? '' : stepFour.length !== 0 ? stepFour
                    .map((collaboratorstepFour, index) => (
                    <li key={index}>
                        <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${collaboratorstepFour._id}`} className="foto">
                        {
                            collaboratorstepFour.avatar && collaboratorstepFour.avatar !== 'undefined' ?
                            <img alt="" src={collaboratorstepFour.avatar}  className="colComFoto"/>
                            :
                            <div className="semFoto">
                                <div className="semImgCol">
                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                </div>
                            </div>
                        }
                        </NavLink>
                        <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${collaboratorstepFour._id}`}  className="nome">{collaboratorstepFour.name}
                        </NavLink>
                        <NavLink to={`/colaboradores/nova-admissao/enviar-contador/${collaboratorstepFour._id}`} className="cargo">
                            {collaboratorstepFour.occupation}
                        </NavLink>

                        <div className="verMais">
                            <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                    <button disabled={loadSaveAndFinaly ? true : false} onClick={()=>saveandfinaly(collaboratorstepFour)} type="button">
                                        {
                                            loadSaveAndFinaly ?
                                            'Ativando...'
                                            :
                                            'Ativar Colaborador'
                                        }
                                    </button>
                                <form onSubmit={excludeAdmssion}>
                                    <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaboratorstepFour._id} type="submit" className="red">
                                        Excluir admissão
                                    </button>
                                </form>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="tooltipData">
                            <div className="tooltipInfor">
                                <i>
                                <span className="tooltiptext">
                                    preenchimento concluído
                                </span>
                                </i>
                            </div>
                                    <span className="date">{collaboratorstepFour.admissionDate ? FormataStringDataToAdmission(collaboratorstepFour.admissionDate) : ''}</span>
                        </div>
                    </li>
                    )) : (
                        <div className="nenhum-colaborador">
                        <p>Nenhum colaborador nesta etapa.</p>
                        </div>
                    )}
                </ul>

            </div>
        </div>

        </>
    );
}

