import React, { useState, useEffect } from 'react';
import Carregando from '../../../../../../assets/images/carregando.gif';
import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import {  Modal} from "react-bootstrap";
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Academic from '../../../../../../assets/images/academic.svg';
import Carregamento from '../../../../../../assets/images/carregando.gif';
import IllustrationEducation from '../../../../../../assets/images/illustration-education.svg';
import Close from '../../../../../../assets/images/close.svg';
import { conclusionAE } from '../../../../../../services/mask';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';
import api from '../../../../../../services/api';


export default function ColProfileAcademicEducation({ id, path }) {

        //////////////////////////////////////////////////////////////////////////////
       //////////////////////////////////////////////////////////////////////////////
      /// => Aqui você faz um get nas informações acadêmicas deste colaborador  ////
     //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ schoolingUpdate, setSchoolingUpdate ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ schooling, setSchooling ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredSchooling, setRegisteredSchooling ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id) {
            loadschooling(id);
        }

    }, [id]);

    async function loadschooling(id) {

        setLoadPage(true)
        await api.get(`/collaborator-schooling/${id}`)
        .then(response => {

            setLoadPage(false)
            if(response.data[0] !== undefined) {
                setLoadCourse(false)
                setLoadBtnAcademic(false)
                setSchooling([])
                setSchoolingUpdate([])
                setVisible(false);
                setFileNames(false)
                setLoadSchooling(false)
                setLoadInstitution(false)
                setRegisteredSchooling(response.data);
            }
            else {
                setLoadCourse(false)
                setLoadBtnAcademic(false)
                setSchooling([])
                setSchoolingUpdate([])
                setVisible(false);
                setFileNames(false)
                setLoadSchooling(false)
                setLoadInstitution(false)
                setRegisteredSchooling([]);
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }



    async function deleteSchooling(schooling) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este formação acadêmica?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-schooling/${schooling}?path=${path}`)
                .then(() => {

                    loadschooling(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Formação acadêmica removida com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover a formação acadêmica, tente novamente mais tarde." });
                });
            }
        });
    }

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = id;
        if(nam === 'schooling') {
            if(val === 'Selecione') {
                setLoadSchooling(true)
            } else {
                setLoadSchooling(false)
                setSchooling({ ...schooling, collaborator, [nam]: val })
            }
        }
        else
        if(nam === 'course') {
            setLoadCourse(false)
            setSchooling({ ...schooling, collaborator, [nam]: val })
        }
        else
        if(nam === 'institution') {
            setLoadInstitution(false)
            setSchooling({ ...schooling, collaborator, [nam]: val })
        }
        else
        if(nam === 'conclusion') {
            let conclusion = conclusionAE(val)
            setSchooling({ ...schooling, collaborator, [nam]: conclusion })
        }
        else
        {
            setSchooling({ ...schooling, collaborator, [nam]: val })
        }
    };



    async function handleSubmit(event) {
        event.preventDefault();

        if(schoolingUpdate._id !== undefined && schoolingUpdate._id !== "") {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(schooling && schooling.length === 0) {
                swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
            }
            else
            if(
                schooling.schooling   ===     "" ||
                schooling.course      ===     "" ||
                schooling.institution ===     ""
            ) {
                //swal({ icon: "error", title: "Erro!", text: "Necessário alterar pelo menos um campo." });
                swal({ icon: "error", title: "Erro!", text: 'Nenhum campo pode ser salvo em branco!' });
            }
            else {
                if (schooling.schooling !== undefined && schooling.schooling !== "") {
                    formData.append('schooling', schooling.schooling);
                    setLoadSchooling(false)
                }
                if (schooling.course !== undefined && schooling.course !== "") {
                    formData.append('course', schooling.course);
                    setLoadCourse(false)
                }
                if (schooling.institution !== undefined && schooling.institution !== "") {
                    formData.append('institution', schooling.institution);
                    setLoadInstitution(false)
                }
                if (schooling.file !== undefined && schooling.file !== "") {
                    formData.append('file', schooling.file);
                }
                if (schooling.conclusion !== undefined && schooling.conclusion !== "") {
                    formData.append('conclusion', schooling.conclusion);
                }
                if (
                    (schooling.schooling   !== undefined && schooling.schooling   !== "")    ||
                    (schooling.course      !== undefined && schooling.course      !== "")    ||
                    (schooling.institution !== undefined && schooling.institution !== "")    ||
                    (schooling.file        !== undefined && schooling.file        !== "")
                ) {

                    formData.append('path', path);

                    setLoadBtnAcademic(true)
                    await api.put(`/collaborator-schooling/${schoolingUpdate._id}`, formData, config)
                    .then(response => {

                        loadschooling(id);

                        swal({ icon: "success", title: "Sucesso!", text: "Formação Acadêmica editada com sucesso!" });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadBtnAcademic(false);
                    });
                }
            }
        } else {
            // alert('schooling')
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (schooling.schooling !== undefined && schooling.schooling !== "") {
                formData.append('schooling', schooling.schooling);
                setLoadSchooling(false)
            } else {
                setLoadSchooling(true)
            }

            if (schooling.course !== undefined && schooling.course !== "") {
                formData.append('course', schooling.course);
                setLoadCourse(false)
            } else {
                setLoadCourse(true)
            }

            if (schooling.institution !== undefined && schooling.institution !== "") {
                formData.append('institution', schooling.institution);
                setLoadInstitution(false)
            } else {
                setLoadInstitution(true)
            }

            if (schooling.file !== undefined && schooling.file !== "") {
                formData.append('file', schooling.file);
            }

            if (schooling.conclusion !== undefined && schooling.conclusion !== "") {
                formData.append('conclusion', schooling.conclusion);
            }
            formData.append('collaborator', id);
            if (
                schooling.schooling   !== undefined &&
                schooling.schooling   !==     ""    &&
                schooling.course      !== undefined &&
                schooling.course      !==     ""    &&
                schooling.institution !== undefined &&
                schooling.institution !==     ""
                ) {

                    formData.append('path', path);
                    setLoadBtnAcademic(true)
                    await api.post("/collaborator-schooling", formData, config)
                    .then(response => {

                        loadschooling(id);

                        swal({ icon: "success", title: "Sucesso!", text: "Formação acadêmica criada com sucesso!" });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadBtnAcademic(false)
                    });
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSchooling, setLoadSchooling ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInstitution, setLoadInstitution ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCourse, setLoadCourse ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);


    function AddAndEditAcademicVisible(e) {

        if(e !== undefined) {
            let SchoolingUpdate = e;
            setSchoolingUpdate({
                ...SchoolingUpdate
            })
        }
        setLoadSchooling(false)
        setLoadInstitution(false)
        setLoadCourse(false)
        setFileNames(false)
        setLoadInstitution(false)
        setVisible(!visible);
    }


   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ load, ] = useState(false);

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ loadBtnAcademic, setLoadBtnAcademic ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState([]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            //console.log(acceptedFiles[0])
            //console.log(acceptedFiles[0].name)
            setFileNames(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setSchooling({ ...schooling, file })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFiles() {
        setFileNames();
        let file = fileNames
        setSchooling({ ...schooling, file })
    }


    ////////////////////
    ///////////////////

    let collaborator = id;




    return (
        <>

                <div className="box educacao-academica">
                    <h3>Formação acadêmica
                            {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                <button className="btnGreen more edit-adicionar" onClick={() => AddAndEditAcademicVisible()}>Adicionar</button>
                            }
                    </h3>
                        {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):
                        registeredSchooling[0] === undefined ?
                        (<div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationEducation} alt="Sem deficiencia" /><p>Não possui formação acadêmica</p></div></div>):

                        registeredSchooling !== undefined ? registeredSchooling
                        .map((schooling, index) => (
                           <>
                            <ul key={index} className="schooling-box">
                                <li >
                                <div className="intemA">
                                    <img src={Academic} alt="Icone de Educação" />
                                </div>
                                <div className="intemB nome-editar">
                                        <span className="nome">{schooling.schooling}</span>
                                        <button onClick={() => AddAndEditAcademicVisible(schooling)} className="editar"><img src={EditBlack} alt="Edite Informações" /></button>
                                </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Instituição de ensino</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{schooling.institution}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Curso</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{schooling.course}</p>
                                    </div>
                                </li>
                                {
                                schooling.schooling === '5º ano completo do Ensino Fundamental' ||
                                schooling.schooling === 'Ensino Fundamental completo' ||
                                schooling.schooling === 'Ensino Médio completo' ||
                                schooling.schooling === 'Educação Superior completa' ||
                                schooling.schooling === 'Mestrado completo' ||
                                schooling.schooling === 'Doutorado completo' ||
                                schooling.schooling === 'Pós Graduação completa' ||
                                schooling.schooling === 'Ensino Técnico completo' ?
                                ( <>
                                    <li>
                                        <div className="intemA">
                                            <p>Ano de conclusão</p>
                                        </div>
                                        <div className="intemB">
                                            <p>{schooling.conclusion ? schooling.conclusion : '-'}</p>
                                        </div>
                                    </li>
                                </>) : ''}

                                {schooling.originalname !== undefined ?
                                        (
                                            <li className="li-anexo">
                                                <div className="intemA">
                                                    <p>Comprovante</p>
                                                </div>
                                                <div className="intemB arq-adicionado">
                                                    <a href={schooling.location} target="’_blank’">
                                                    {schooling.originalname }
                                                    </a>
                                                </div>
                                            </li>
                                        ) : ''
                                    }
                            </ul>
                           </>
                        )) : ''}


                </div>
                <Modal show={visible} className="popup-edit-perfil popup-edit-educacao-academica" onHide={AddAndEditAcademicVisible}>
                    <div>
                        <div className="titulo">
                            <h4>Formação acadêmica  <p className="btn-fechar" onClick={AddAndEditAcademicVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Escolaridade *</p>
                                </div>
                                <div className="intemB">

                                    <div className='cont-select'>
                                        <button className={loadSchooling ? 'inputErro select-' : 'select-'}>
                                            <p>
                                                {
                                                    schooling && schooling.schooling !== undefined ?
                                                    schooling.schooling :
                                                    schoolingUpdate && schoolingUpdate.schooling !== undefined ?
                                                    schoolingUpdate.schooling : "Selecione"
                                                }
                                            </p>
                                        </button>

                                        <div className="dropDown">
                                            <Scrollbars className='listDropDown'  style={{  height: 130 }}>
                                                {[
                                                    "Analfabeto",
                                                    "Até a 5ª série cursando do Ensino Fundamental", "Até a 5ª série incompleta do Ensino Fundamental", "5º ano completo do Ensino Fundamental",
                                                    "Do 6º ao 9º ano do Ensino Fundamental incompleto", "Do 6º ao 9º ano do Ensino Fundamental cursando", "Ensino Fundamental completo",
                                                    "Ensino Médio incompleto", "Ensino Médio cursando", "Ensino Médio completo",
                                                    "Educação Superior incompleta", "Educação Superior cursando", "Educação Superior completa",
                                                    "Mestrado incompleto", "Mestrado cursando", "Mestrado completo",
                                                    "Doutorado incompleto", "Doutorado cursando", "Doutorado completo",
                                                    "Pós Graduação incompleta", "Pós Graduação cursando", "Pós Graduação completa",
                                                    "Ensino Técnico cursando", "Ensino Técnico incompleto", "Ensino Técnico completo"
                                                ].map((item, index) => (
                                                    <button
                                                        key={index}
                                                        className='item'
                                                        name="name"
                                                        onClick={() =>
                                                            setSchooling({ ...schooling, collaborator, 'schooling': item })
                                                        ||
                                                        setLoadSchooling(false)}
                                                    >
                                                        <p >{item}</p>
                                                    </button>
                                                ))}
                                            </Scrollbars>
                                        </div>
                                    </div>

                                    <p className="inputUm textError">{loadSchooling ? 'É necessário escolher uma escolaridade' : ''}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Curso *</p>
                                </div>
                                <div className="intemB">
                                    {schoolingUpdate &&  schoolingUpdate.course !== undefined && schoolingUpdate.course !== "" ?
                                    (<input type="text" name="course" autocomplete="off" onChange={myChangeHandler} defaultValue={schoolingUpdate && schoolingUpdate.course} className={loadCourse ? 'inputDois inputErro' : 'inputDois'}/>) :
                                    (<input type="text" name="course" autocomplete="off" onChange={myChangeHandler} placeholder="Curso" className={loadCourse ? 'inputDois inputErro' : 'inputDois'}/>)
                                    }
                                    <p className="inputDois textError">{loadCourse ? 'É necessário preencher um curso' : ''}</p>

                                </div>
                            </li>

                            <li>
                                <div className="intemA">
                                    <p>Instituição *</p>

                                </div>
                                <div className="intemB">
                                    {schoolingUpdate &&  schoolingUpdate.institution !== undefined && schoolingUpdate.institution !== ""?
                                    (<input type="text" name="institution" autocomplete="off" onChange={myChangeHandler} defaultValue={schoolingUpdate && schoolingUpdate.institution} className={loadInstitution ? 'inputTreis inputErro' : 'inputTreis'}/>) :
                                    (<input type="text" name="institution" autocomplete="off" onChange={myChangeHandler} placeholder="Instituição" className={loadInstitution ? 'inputTreis inputErro' : 'inputTreis'}/>)
                                    }

                                    <p className="inputTreis textError">{loadInstitution ? 'É necessário preencher uma instituição' : ''}</p>
                                </div>
                            </li>
                                {
                                (schoolingUpdate && schoolingUpdate.schooling === '5º ano completo do Ensino Fundamental') ||
                                (schooling       && schooling.schooling       === '5º ano completo do Ensino Fundamental') ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Ensino Fundamental completo')           ||
                                (schooling       && schooling.schooling       === 'Ensino Fundamental completo')           ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Ensino Médio completo'     )            ||
                                (schooling       && schooling.schooling       === 'Ensino Médio completo'     )            ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Educação Superior completa')            ||
                                (schooling       && schooling.schooling       === 'Educação Superior completa')            ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Mestrado completo'         )            ||
                                (schooling       && schooling.schooling       === 'Mestrado completo'         )            ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Doutorado completo'        )            ||
                                (schooling       && schooling.schooling       === 'Doutorado completo'        )            ||
                                (schooling       && schooling.schooling       === 'Pós Graduação completa'    )            ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Pós Graduação completa'    )            ||
                                (schoolingUpdate && schoolingUpdate.schooling === 'Ensino Técnico completo'   )            ||
                                (schooling       && schooling.schooling       === 'Ensino Técnico completo'  )             ?
                                (
                                    <>
                                    <li>
                                        <div className="intemA">
                                            <p>Ano de conclusão</p>
                                        </div>
                                        <div className="intemB">
                                        {
                                        schooling && schooling.conclusion !== undefined && schooling.conclusion !== "" ?
                                        (<input type="text" name="conclusion" autocomplete="off" onChange={myChangeHandler} value={schooling && schooling.conclusion} placeholder="Ano de conclusão"/>) :
                                        schoolingUpdate && schoolingUpdate.conclusion !== undefined && schoolingUpdate.conclusion !== "" ?
                                        (<input type="text" name="conclusion" autocomplete="off" onChange={myChangeHandler} defaultValue={schoolingUpdate && schoolingUpdate.conclusion} placeholder="Ano de conclusão"/>) :
                                        (<input type="text" name="conclusion" autocomplete="off" onChange={myChangeHandler} placeholder="Ano de conclusão"/>)
                                        }

                                        </div>
                                    </li>
                                    </>
                                ) :
                                (
                                    ''
                                )
                                }


                                {schoolingUpdate && schoolingUpdate.originalname !== undefined ?
                                (
                                    <li>
                                        <div className="intemA">
                                            <p>Comprovante</p>
                                        </div>
                                        <div className="intemB arq-adicionado ">

                                            <a href={schoolingUpdate.location} target="’_blank’">
                                            {schoolingUpdate.originalname }
                                            </a>
                                        </div>
                                </li>) :
                                ('')}
                            <li>
                                <div className="intemA">
                                    <p>Anexar</p>
                                </div>
                                <div className="intemB">{schoolingUpdate && schoolingUpdate.originalname !== undefined ?
                                (
                                    <Dropzone  className="" onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel inputOnze"><span></span>Atualize o arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                    ) :
                                    (
                                        <Dropzone  className="" onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel inputOnze"><span></span>Anexe um arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>

                                    )}
                                </div>
                            </li>

                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </li>

                            {fileNames !== false && fileNames ?
                            fileNames.map((filename, index) => (
                            <li>
                                <div>
                                    <div className="arquivoAnexado">

                                        {/* <h5>Enviados</h5> */}
                                        <div key={index} className="arquivo">
                                            <p className="nomeArquivo">
                                                <a href={fileNames.location} target="’_blank’">
                                                {filename}
                                                </a>
                                            </p>
                                            <p onClick={deleteFiles} className="remover">Remover anexo</p>
                                        </div>

                                    </div>
                                </div>
                            </li>

                            )) : load ?
                            (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                            ('')}


                            {schoolingUpdate.schooling !== false && schoolingUpdate._id  ?
                                    (
                                        <>

                                        <li className='comBtnExclui'>
                                            <div className="intemA">
                                                <div className="intem btnExclui">
                                                    <button onClick={() => deleteSchooling(schoolingUpdate._id)}>Excluir escolaridade</button >
                                                </div>
                                            </div>
                                            <div className="intemB">
                                                <form onSubmit={handleSubmit} className="btn-salvar">
                                                    <button type="submit" className={loadBtnAcademic  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                                </form>
                                            </div>
                                        </li>
                                        <li className='comBtnExcluiMb'>
                                            <div className="intemA">
                                                <form onSubmit={handleSubmit} className="btn-salvar">
                                                    <button type="submit" className={loadBtnAcademic  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                                </form>
                                            </div>
                                            <div className="intemB">
                                                <div className="intem btnExclui">
                                                    <button onClick={() => deleteSchooling(schoolingUpdate._id)}>Excluir escolaridade</button >
                                                </div>
                                            </div>
                                        </li>
                                        </>
                                    ) :
                                (
                                    <li>
                                        <div className="intemA">
                                        </div>

                                        <div className="intemB">
                                            <form onSubmit={handleSubmit} className="btn-salvar">
                                                <button type="submit" className={loadBtnAcademic  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                            </form>
                                        </div>
                                    </li>
                                )}
                        </ul>


                    </div>
                </Modal>
        </>
    );
}

