import React, {
    //  useState, useEffect 
} from 'react';

export default function boxEvaluationGeneral(props) {




        return (
            <>
                  
                <div className='box-avaliacao-geral box-leiaute'>
                    <h3>Avaliação geral</h3>
                    <div className='box-infor'>
                        <p>Gestão</p>
                        <ul>
                            <li>
                                <p>5% </p>
                                <span>Péssimo</span>
                            </li>
                            <li>
                                    <p>5%</p>
                                <span>Ruim</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Médio</span>
                            </li>
                            <li>
                                <p>70% </p>
                                <span>Bom</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Ótimo</span>
                            </li>
                        </ul>
                        <p>Clima</p>
                        <ul>
                            <li>
                                    <p>5%</p>
                                <span>Péssimo</span>
                            </li>
                            <li>
                                    <p>5%</p>
                                <span>Ruim</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Médio</span>
                            </li>
                            <li>
                                <p>70% </p>
                                <span>Bom</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Ótimo</span>
                            </li>
                        </ul>
                        <p>Demandas</p>
                        <ul>
                            <li>
                                <p>5%</p>
                                <span>Péssimo</span>
                            </li>
                            <li>
                                    <p>5%</p>
                                <span>Ruim</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Médio</span>
                            </li>
                            <li>
                                <p>70% </p>
                                <span>Bom</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Ótimo</span>
                            </li>
                        </ul>
                        <p>Motivação</p>
                        <ul>
                            <li>
                                <p>5%</p>
                                <span>Péssimo</span>
                            </li>
                            <li>
                                    <p>5%</p>
                                <span>Ruim</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Médio</span>
                            </li>
                            <li>
                                <p>70% </p>
                                <span>Bom</span>
                            </li>
                            <li>
                                <p>10% </p>
                                <span>Ótimo</span>
                            </li>
                        </ul>

                    </div>
                </div>            
            </>
        );
}

