/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { Dropdown } from "react-bootstrap";
import iconV from '../../../assets/images/v.svg';
import Carregando from '../../../assets/images/carregando.gif';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { dateFormatedToHistory } from '../../../services/utils';

export default function ColProfileHistories({ id }) {

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorHistories, setCollaboratorHistories ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterEverything, setfilterEverything ] = useState(false);
    const [ filterPersonalData, setfilterPersonalData ] = useState(false);
    const [ filterProfessionalInformation, setfilterProfessionalInformation ] = useState(false);
    const [ filterDocuments, setfilterDocuments ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaboratorhistories(id);
    }, [id]);

    async function loadcollaboratorhistories(id) {
        setLoadPage(true)
        await api.get(`/collaborator-history/${id}`)
        .then(response => {
            setCollaboratorHistories(response.data);
            setLoadPage(false)
        }).catch(error =>{
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }


    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.checked;

          /*********************************************/
         /////////////////    TUDO   ///////////////////
        /*********************************************/
        if(nam === 'filterEverything' && val === true) {
            setfilterEverything(true)
            setfilterPersonalData(true)
            setfilterProfessionalInformation(true)
            setfilterDocuments(true)

            await api.get(`/collaborator-history/${id}`)
            .then(response => {
                setLoadPage(false)
                setCollaboratorHistories(response.data);
            }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

        }
        else
        if(nam === 'filterEverything' && val === false) {
            setfilterEverything(false)
            setfilterPersonalData(false)
            setfilterProfessionalInformation(false)
            setfilterDocuments(false)

        }
          /*********************************************/
         ////////////////  DADOS PESSOAIS  /////////////
        /*********************************************/
        else
        if(nam === 'filterPersonalData' && val === false) {
            setfilterEverything(false)

            if(filterProfessionalInformation === true && filterDocuments === true) {

                setfilterPersonalData(false)
                setfilterProfessionalInformation(true)
                setfilterDocuments(true)
                setLoadPage(true)
                await api.get(`/collaborator-history-professional-information-and-documents/?id=${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            } else {

                if(filterEverything === true) {
                    setfilterEverything(false)

                    setfilterPersonalData(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                    setfilterProfessionalInformation(false)
                    setfilterDocuments(false)
                }
                else
                if(filterProfessionalInformation === true) {

                    setfilterPersonalData(false)
                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-professional-information/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterDocuments === true) {

                    setfilterDocuments(true)
                    setfilterPersonalData(false)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else {

                    setfilterPersonalData(false)
                    setLoadPage(true)
                    await api.get(`/collaborator-history/${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });


                }

            }

        }
        else
        if(nam === 'filterPersonalData' && val === true) {

            if(filterProfessionalInformation === true && filterDocuments === true) {

                setfilterPersonalData(true)
                setfilterProfessionalInformation(true)
                setfilterDocuments(true)
                setLoadPage(true)
                await api.get(`/collaborator-history/${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            } else {

                if(filterProfessionalInformation === true) {

                    setfilterPersonalData(true)
                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data-and-professional-information/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterDocuments === true) {

                    setfilterPersonalData(true)
                    setfilterDocuments(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data-and-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else {

                    setfilterPersonalData(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }

            }

        }
          /*********************************************/
         ///////////  DADOS PROFISSIONAIS  /////////////
        /*********************************************/
        else
        if(nam === 'filterProfessionalInformation' && val === false) {
            setfilterEverything(false)

            if(filterPersonalData === true && filterDocuments === true) {

                setfilterPersonalData(true)
                setfilterProfessionalInformation(false)
                setfilterDocuments(true)
                setLoadPage(true)
                await api.get(`/collaborator-history-personal-data-and-documents/?id=${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            }
            else
            if(filterPersonalData === false && filterDocuments === true) {

                setfilterEverything(false)
                setfilterPersonalData(false)
                setfilterProfessionalInformation(false)
                setfilterDocuments(true)
                setLoadPage(true)
                await api.get(`/collaborator-history-documents/?id=${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            }
            else
            if(filterPersonalData === true && filterDocuments === false) {

                setfilterEverything(false)
                setfilterPersonalData(true)
                setfilterProfessionalInformation(false)
                setfilterDocuments(false)
                setLoadPage(true)
                await api.get(`/collaborator-history-personal-data/?id=${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            }
            else {

                if(filterEverything === true) {
                    setfilterEverything(false)
                    setfilterPersonalData(false)

                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-professional-information/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                    setfilterDocuments(false)
                }
                else
                if(filterPersonalData === true) {

                    setfilterProfessionalInformation(false)
                    setfilterPersonalData(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterDocuments === true) {

                    setfilterProfessionalInformation(false)
                    setfilterDocuments(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else {

                    setfilterProfessionalInformation(false)
                    setLoadPage(true)
                    await api.get(`/collaborator-history/${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
                }

            }

        }
        else
        if(nam === 'filterProfessionalInformation' && val === true) {

            if(filterPersonalData === true && filterDocuments === true) {

                setfilterPersonalData(true)
                setfilterProfessionalInformation(true)
                setfilterDocuments(true)
                setLoadPage(true)
                await api.get(`/collaborator-history/${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            } else {

                if(filterPersonalData === true) {

                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data-and-professional-information/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterDocuments === true) {

                    setfilterProfessionalInformation(true)
                    setfilterDocuments(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-professional-information-and-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else {

                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-professional-information/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }

            }

        }
          /*********************************************/
         ////////////////  DOCUMENTOS  /////////////////
        /*********************************************/
        else
        if(nam === 'filterDocuments' && val === false) {
            setfilterEverything(false)

            if(filterPersonalData === true && filterProfessionalInformation === true) {

                setfilterPersonalData(true)
                setfilterProfessionalInformation(true)
                setfilterDocuments(false)
                setLoadPage(true)
                await api.get(`/collaborator-history-personal-data-and-professional-information/?id=${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

            } else {

                if(filterEverything === true) {
                    setfilterEverything(false)
                    setfilterPersonalData(false)
                    setfilterProfessionalInformation(false)

                    setfilterDocuments(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterPersonalData === true) {

                    setfilterDocuments(false)
                    setfilterPersonalData(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterProfessionalInformation === true) {

                    setfilterDocuments(false)
                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-professional-information/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else {

                    setfilterDocuments(false)
                    setLoadPage(true)
                    await api.get(`/collaborator-history/${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }

            }



        }
        else
        if(nam === 'filterDocuments' && val === true) {

            if(filterPersonalData === true && filterProfessionalInformation === true) {

                setfilterPersonalData(true)
                setfilterProfessionalInformation(true)
                setfilterDocuments(true)
                setLoadPage(true)
                await api.get(`/collaborator-history/${id}`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorHistories(response.data);
                }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });


            } else {

                if(filterPersonalData === true) {

                    setfilterDocuments(true)
                    setfilterPersonalData(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-personal-data-and-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else
                if(filterProfessionalInformation === true) {

                    setfilterDocuments(true)
                    setfilterProfessionalInformation(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-professional-information-and-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }
                else {

                    setfilterDocuments(true)
                    setLoadPage(true)
                    await api.get(`/collaborator-history-documents/?id=${id}`)
                    .then(response => {
                        setLoadPage(false)
                        setCollaboratorHistories(response.data);
                    }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

                }

            }

        }


    }


    return (
        <>
        <div className="box-profile-historico">
            <div className="cabecalho">
                <h1>Histórico</h1>
                <Dropdown  className="dropdown-icon ">
                    <Dropdown.Toggle>
                        <img alt="" src={iconV} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <ul>
                            <li className="chekbox">
                                <input type="checkbox" onChange={myChangeHandler} name="filterEverything" id="filterEverything" checked={filterEverything} />
                                <label htmlFor="filterEverything" className="chackboxLabel"></label>
                                <label htmlFor="filterEverything" className="foreignTexto">Tudo</label>
                            </li>
                            <li className="chekbox">
                                <input type="checkbox" onChange={myChangeHandler} name="filterPersonalData" id="filterPersonalData" checked={filterPersonalData}/>
                                <label htmlFor="filterPersonalData" className="chackboxLabel"></label>
                                <label htmlFor="filterPersonalData" className="foreignTexto">Dados pessoais</label>
                            </li>
                            <li className="chekbox">
                                <input type="checkbox" onChange={myChangeHandler} name="filterProfessionalInformation" id="filterProfessionalInformation" checked={filterProfessionalInformation} />
                                <label htmlFor="filterProfessionalInformation" className="chackboxLabel"></label>
                                <label htmlFor="filterProfessionalInformation" className="foreignTexto">Dados profissionais</label>
                            </li>
                            <li className="chekbox">
                                <input type="checkbox" onChange={myChangeHandler} name="filterDocuments" id="filterDocuments" checked={filterDocuments} />
                                <label htmlFor="filterDocuments" className="chackboxLabel"></label>
                                <label htmlFor="filterDocuments" className="foreignTexto">Arquivos</label>
                            </li>
                        </ul>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="timeline">
                {
                loadPage ?
                    (
                        <>
                        <div className="carregamento">
                            <img alt="" src={Carregando} />
                        </div>
                        </>
                    ):(
                        <>
                        <div className="box">
                            <ul>

                            {collaboratorHistories && collaboratorHistories[0] !== undefined ?
                                collaboratorHistories.map((histories, index) =>
                                (
                                    <>
                                    <li key={index} className="alteracao-adicionar">
                                        <h5 className="nome">{
                                            histories.name                       === 'Estado Civil'       ?
                                            histories.name                                                :
                                            histories.name                       === 'Dados bancários'    &&
                                            histories.type                       === 'create'             ?
                                            'Dados bancários - Adição de conta'                           :
                                            histories.name                       === 'Dados bancários'    &&
                                            histories.type                       === 'update'             ?
                                            'Dados bancários - Edição de conta'                           :
                                            histories.name                       === 'Endereço'           &&
                                            histories.type                       === 'update'             ?
                                            'Endereço - Edição'                                           :
                                            histories.name                       === 'Formação acadêmica' &&
                                            histories.type                       === 'create'             ?
                                            'Formação acadêmica - Adição'                                 :
                                            histories.name                       === 'Dependente'         &&
                                            histories.type                       === 'create'             ?
                                            'Dependente - Adição'                                         :
                                            histories.name                       === 'Dependentes'        &&
                                            histories.type                       === 'delete'             ?
                                            'Dependente - Exclusão'                                       :
                                            histories.name                       === 'Mudança de gestor'  &&
                                            histories.type                       === 'update'             ?
                                            'Mudança de gestor'                                           :
                                            histories.name                       === 'Mudança de cargo'   &&
                                            histories.type                       === 'update'             ?
                                            'Mudança de cargo'                                            :
                                            histories.name                       === 'Mudança de setor'   &&
                                            histories.type                       === 'update'             ?
                                            'Mudança de setor'                                            :
                                            histories.name                       === 'Mudança de centro de custo'  &&
                                            histories.type                       === 'update'             ?
                                            'Mudança de centro de custo'                                  :
                                            histories.name                       === 'Benefícios'         &&
                                            histories.type                       === 'delete'             ?
                                            'Benefício desativado'                                          :
                                            histories.name                       === 'Adição de benefício'&&
                                            histories.type                       === 'create'             ?
                                            'Adição de benefício'                                         :
                                            histories.name                       === 'Mudança de benefício'&&
                                            histories.type                       === 'update'             ?
                                            'Mudança de benefício'                                        :
                                            histories.field                      === 'arquivo'            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição'                                          :
                                            histories.originalnameDocCPF         !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - CPF'                                    :
                                            histories.originalnameDocRG          !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - RG'                                     :
                                            histories.originalnameDocCNH         !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - CNH'                                    :
                                            histories.originalnameDocCT          !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - Carteira de Trabalho'                   :
                                            histories.originalnameDocTE          !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - Título de Eleitor'                      :
                                            histories.originalnameDocCR          !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - carteira de Reservista'                 :
                                            histories.originalnameIdentification !== undefined            &&
                                            histories.type                       === 'create'             &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição -  Certidão'                         :
                                            histories.originalnameLocation       !== undefined            &&
                                            histories.type                       === 'create'             ?
                                            'Documeto -  Adição - Localização'                            :
                                            histories.name                       === 'Cópia de documento - Carteira de reservista' &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - Carteira de reservista'               :
                                            histories.name                       === 'Cópia de documento - Título de eleitor'      &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - Título de eleitor'                    :
                                            histories.name                       === 'Cópia de documento - Carteira de Trabalho'   &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - Carteira de Trabalho'                 :
                                            histories.name                       === 'Cópia de documento - CNH'                    &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - CNH'                                  :
                                            histories.name                       === 'Cópia de documento - RG'                     &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - RG'                                   :
                                            histories.name                       === 'Cópia de documento - CPF'                    &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - CPF'                                  :
                                            histories.name                       === 'Informações de identificação'                    &&
                                            histories.type                       === 'delete'             ?
                                            'Documeto -  Exclusão - Certidão'                             :

                                            ''
                                        }</h5>
                                        {/*****************************ESTADO CIVIL***********************************/}
                                        {
                                            histories.maritalStatusOld && histories.maritalStatusOld !== undefined ?
                                            <>
                                                <p className="endetificacao">
                                                    <span>{histories.maritalStatusOld}</span>
                                                    <strong>{histories.maritalStatus}</strong>
                                                </p>
                                            </>
                                            :
                                            ''
                                        }
                                        {/************************DADOS BANCÁRIOS - ADIÇÃO****************************/}
                                        {
                                            histories.name                    === 'Dados bancários' &&
                                            histories.type                    === 'create'           ?
                                            <>
                                                <p className="endetificacao"><strong>{histories.bankCode}</strong></p>
                                                <p className="endetificacao"><strong>Tipo de {histories.AccountType}</strong></p>
                                                <p className="endetificacao"><strong>Agência {histories.bankAgency}</strong></p>
                                                <p className="endetificacao"><strong>Conta {histories.bankAccount}-{histories.accountDigit} </strong></p>
                                                <p className="endetificacao"><strong>Chave PIX {histories.pix}</strong></p>
                                            </>
                                            :
                                            ''
                                        }
                                        {/************************DADOS BANCÁRIOS - EDIÇÃO****************************/}
                                        {
                                            histories.name                    === 'Dados bancários' &&
                                            histories.type                    === 'update'           ?
                                            <>
                                                <p className="endetificacao"><span>{histories.bankCodeOld}</span><strong>{histories.bankCode}</strong></p>
                                                <p className="endetificacao"><strong>Tipo de conta {histories.AccountType}</strong></p>
                                                <p className="endetificacao"><span>Agência {histories.bankAgencyOld}</span><strong>Agência {histories.bankAgency}</strong></p>
                                                <p className="endetificacao"><span>Conta {histories.bankAccountOld}-{histories.accountDigitOld}</span><strong>Conta  {histories.bankAccount}-{histories.accountDigit}</strong></p>
                                                <p className="endetificacao"><span>Chave PIX {histories.pixOld}</span><strong>Chave PIX {histories.pix}</strong></p>
                                            </>
                                            :
                                            ''
                                        }
                                        {/************************FORMAÇÂO ACADEMICA - ADIÇÃO****************************/}
                                        {histories.name === 'Formação acadêmica' && histories.type === 'create' ?
                                        <>
                                            <p className="endetificacao"><strong>Instituição {histories.institution}</strong></p>
                                            <p className="endetificacao"><strong>Curso {histories.course}</strong></p>
                                            <p className="endetificacao"><strong>Ano de conclusão {histories.conclusion}</strong></p>
                                            <p className="endetificacao"><strong>Comprovante: <a target="_blank" href={histories.locationschooling}>{histories.originalnameSchooling}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************ENDEREÇO - EDIÇÃO********************************/}
                                        {histories.name === 'Endereço' && histories.type === 'update' ?
                                        <>
                                            {
                                                histories.addressOld !== histories.address && histories.addressNumberOld !== histories.addressNumber ?
                                                (<> <p className="endetificacao"><span>{histories.addressOld}, {histories.addressNumberOld}</span><strong> {histories.address}, {histories.addressNumber}</strong></p> </>) :

                                                histories.addressOld !== histories.address && histories.addressNumberOld === histories.addressNumber ?
                                                (<> <p className="endetificacao"><span>{histories.addressOld}</span><strong> {histories.address}</strong></p> </>) :

                                                histories.addressOld === histories.address && histories.addressNumberOld !== histories.addressNumber ?
                                                (<> <p className="endetificacao"><span>Número {histories.addressNumberOld}</span><strong>Número {histories.addressNumber}</strong></p> </>) : ''
                                            }

                                            {
                                                histories.addressNeighborhoodOld === histories.addressNeighborhood ? ('') :
                                                (<><p className="endetificacao"><span>{histories.addressNeighborhoodOld}</span><strong> {histories.addressNeighborhood}</strong></p></>)
                                            }

                                            {
                                                histories.addressCityOld !== histories.addressCity && histories.addressStateOld !== histories.addressState ?
                                                (<><p className="endetificacao"><span>{histories.addressCityOld} - {histories.addressStateOld}</span><strong>{histories.addressCity} - {histories.addressState}</strong></p></>) :

                                                histories.addressCityOld !== histories.addressCity && histories.addressStateOld === histories.addressState ?
                                                (<><p className="endetificacao"><span>Cidade {histories.addressCityOld}</span><strong>Cidade {histories.addressCity}</strong></p></>) :

                                                histories.addressCityOld === histories.addressCity && histories.addressStateOld !== histories.addressState ?
                                                (<><p className="endetificacao"><span>Estado {histories.addressStateOld}</span><strong>Estado {histories.addressState}</strong></p></>) :''

                                            }
                                            {
                                                histories.zipcodeOld === histories.zipcode ? ('') :
                                                (<><p className="endetificacao"><span>CEP {histories.zipcodeOld}</span><strong>CEP {histories.zipcode}</strong></p></>)
                                            }
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Dependente - EDIÇÃO********************************/}
                                        {histories.name === 'Dependente' && histories.type === 'create' ?
                                        <>
                                            <p className="endetificacao"><strong>Nome {histories.nameDepentend}</strong></p>
                                            <p className="endetificacao"><strong>Parentesco {histories.relationshipDependent}</strong></p>
                                            <p className="endetificacao"><strong>Idade {histories.dateOfBirthDependent}</strong></p>
                                            <p className="endetificacao"><strong>Escolaridade {histories.schoolingDependent}</strong></p>
                                            {
                                                histories.originalnameDependent && histories.originalnameDependent ?
                                                (<><p className="endetificacao"><strong>Comprovante: <a href={histories.locationDependent} target="_blank">{histories.originalnameDependent}</a></strong></p></>) : ''
                                            }
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Dependente - Exclusão********************************/}
                                        {histories.name === 'Dependentes' && histories.type === 'delete' ?
                                        <>
                                            <p className="endetificacao"><span>Nome {histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Mudança de gestor**************/}
                                        {histories.name === 'Mudança de gestor' && histories.type === 'update' ?
                                        <>
                                        <p className="endetificacao"><span>Gestor {histories.nameManagerOld} </span><strong>Gestor {histories.nameManager}</strong></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Mudança de cargo**************/}
                                        {histories.name === 'Mudança de cargo' && histories.type === 'update' ?
                                        <>
                                        <p className="endetificacao"><span>Cargo {histories.occupationOld} </span><strong>Cargo {histories.occupation}</strong></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Mudança de setor**************/}
                                        {histories.name === 'Mudança de setor' && histories.type === 'update' ?
                                        <>
                                        <p className="endetificacao"><span>Setor {histories.sectorOld} </span><strong>Setor {histories.sector}</strong></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Mudança de centro de custo**************/}
                                        {histories.name === 'Mudança de centro de custo' && histories.type === 'update' ?
                                        <>
                                        <p className="endetificacao"><span>Centro de custo {histories.costCenterOld} </span><strong>Centro de custo {histories.costCenter}</strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Adição de benefício**************/}
                                        {histories.name === 'Adição de benefício' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong>Benefício {histories.nameBenefit}</strong></p>
                                        <p className="endetificacao"><strong>Colaborador R$ {histories.collaboratorCosts}</strong></p>
                                        <p className="endetificacao"><strong>Empregador R$ {histories.companyCosts}</strong></p>
                                        <p className="endetificacao"><strong>Data de corte {histories.cutoffDate}</strong></p>
                                        <p className="endetificacao"><strong>Crédito {histories.balanceCalculation}</strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Mudança de benefício**************/}
                                        {histories.name === 'Mudança de benefício' && histories.type === 'update' ?
                                        <>
                                        <p className="endetificacao"><strong>Benefício {histories.nameBenefit}</strong></p>
                                        <p className="endetificacao"><span>Colaborador R$ {histories.collaboratorCostsOld}</span><strong>Colaborador R$ {histories.collaboratorCosts}</strong></p>
                                        <p className="endetificacao"><span>Empregador R$ {histories.companyCostsOld}</span><strong>Empregador R$ {histories.companyCosts}</strong></p>
                                        <p className="endetificacao"><strong>Data de corte {histories.cutoffDate}</strong></p>
                                        <p className="endetificacao"><strong>Crédito {histories.balanceCalculation}</strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Localização**************/}
                                        {histories.name === 'Benefícios' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Identificação**************/}
                                        {histories.name === 'Informações de identificação' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationIdentification} target="_blank">{histories.originalnameIdentification}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Localização**************/}
                                        {histories.name === 'Informações de Localização' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationLocation} target="_blank">{histories.originalnameLocation}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - CPF**************/}
                                        {histories.name === 'Cópia de documento - CPF' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationDocCPF} target="_blank">{histories.originalnameDocCPF}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - RG**************/}
                                        {histories.name === 'Cópia de documento - RG' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationDocRG} target="_blank">{histories.originalnameDocRG}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - CNH**************/}
                                        {histories.name === 'Cópia de documento - CNH' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationDocCNH} target="_blank">{histories.originalnameDocCNH}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Carteira de trabalho**************/}
                                        {histories.name === 'Cópia de documento - Carteira de trabalho' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationDocCT} target="_blank">{histories.originalnameDocCT}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Título de eleitor**************/}
                                        {histories.name === 'Cópia de documento - Título de eleitor' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationDocTE} target="_blank">{histories.originalnameDocTE}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Carteira de reservista**************/}
                                        {histories.name === 'Cópia de documento - Carteira de reservista' && histories.type === 'create' ?
                                        <>
                                        <p className="endetificacao"><strong><a href={histories.locationDocCR} target="_blank">{histories.originalnameDocCR}</a></strong></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Carteira de reservista**************/}
                                        {histories.name === 'Cópia de documento - Carteira de reservista' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Título de eleitor**************/}
                                        {histories.name === 'Cópia de documento - Título de eleitor' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }

                                        {/*******************************Documento - Carteira de Trabalho**************/}
                                        {histories.name === 'Cópia de documento - Carteira de Trabalho' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Documento - CNH**************/}
                                        {histories.name === 'Cópia de documento - CNH' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Documento - RG**************/}
                                        {histories.name === 'Cópia de documento - RG' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Documento - CPF**************/}
                                        {histories.name === 'Cópia de documento - CPF' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }
                                        {/*******************************Documento - Certidão**************/}
                                        {histories.name === 'Informações de identificação' && histories.type === 'delete' ?
                                        <>
                                        <p className="endetificacao"><span>{histories.value}</span></p>
                                        </>
                                        :
                                        ''
                                        }

                                        <div className="data"><span className="alterador">Por {histories ? histories.responsible.name : ''}</span> <span>{dateFormatedToHistory(histories.createdAt)}</span></div>
                                    </li>
                                    </>
                                )) :
                                (
                                    <><li><div className="nenhuma-ateracao">Nenhuma alteração</div></li></>
                                )
                                }








                                {
                                    // <li className="alteracao-adicionar">
                                    //     <h5 className="nome">Documento - Adição</h5>
                                    //     <p className="endetificacao"><strong>cnh-renovada.pdf</strong></p>
                                    //     <div className="data"><span className="alterador">Por Emerson Nunes</span> <span>20.07.2021 às 10:12</span></div>
                                    // </li>
                                    // <li className="alteracao-adicionar">
                                    //     <h5 className="nome">Documento - Exclusão</h5>
                                    //     <p className="endetificacao"><span>cnh-2017.pdf</span></p>
                                    //     <div className="data"><span className="alterador">Por Emerson Nunes</span> <span>20.07.2021 às 10:12</span></div>
                                    // </li>
                                    // <li className="alteracao-adicionar">
                                    //     <h5 className="nome">Habilidades - Adição</h5>
                                    //     <p className="endetificacao"><strong>Vue.js</strong></p>
                                    //     <p className="endetificacao"><strong>Nuxt.js</strong></p>
                                    //     <p className="endetificacao"><strong>Firebase</strong></p>
                                    //     <div className="data"><span className="alterador">Por Emerson Nunes</span> <span>20.07.2021 às 10:12</span></div>
                                    // </li>
                                }


                            </ul>
                        </div>
                        </>)}

            </div>

        </div>
        </>
    );
}
