import React,{useState, useEffect} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {removerSpecials} from '../../services/utils'

export default function selectPersonalidado({
    nameButton, 
    nameCampoBusca, 
    typeSelect,
    validacaoValue,
    validacaoOptionIntem, 
    option, 
    myChangeHandler, 
    nameCampo, 
    valorCampo, 
    loadError,
    focusRef,
    optionFil,
    loadOption,
    placeholder
}){
//     console.log("option")
// console.log(option)


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        pegaValor(valorCampo);
        // loadValorcamor(valorCampo);

    }, [valorCampo]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectLaborBond, setIsActiveSelectLaborBond] = useState(false);
    async function SelectLaborBond() { setIsActiveSelectLaborBond(!isActiveSelectLaborBond) }

    //////////////////////////////////////////////////////
    ///////////////// BUSCA /////////////////
    ////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBusca, setIsActiveSelectBusca] = useState(false);
    async function SelectBusca() { setIsActiveSelectBusca(!isActiveSelectBusca) }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaState, setBuscaState ] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [busca, setBusca ] = useState("");

    const myChangeHandlerSelects = event => {
        let val = event.target.value;
        let ValorCampo = removerSpecials(val).toLowerCase();
        setBuscaState(ValorCampo)
        setBusca(val)
    }

    function pegaValor(valorCampo) {
        setBusca(valorCampo)
        setBuscaState("")

    }
    const optionFilter = optionFil && optionFil.filter((options) => {
        return removerSpecials(options).toLowerCase().includes(buscaState !== "" ? buscaState : "" )  ;
    });
    return (
        <>
        {
            typeSelect === "busca" ?
            <div className="select-busca-btn">
                <input 
                    type="text" 
                    className={loadError && loadError === true ? "menu-button inputErro" : "menu-button"}
                    placeholder='Selecione'
                    name={nameCampoBusca}
                    value={
                        loadOption ? "Carregando..." :
                        valorCampo && valorCampo ? valorCampo : ""
                    }
                    autoComplete="autocompleteoff"
                    onClick={ SelectBusca }
                    onBlur={ () => (setIsActiveSelectBusca(false)) }
                    onKeyUp={ () => (setIsActiveSelectBusca(true))}
                    onChange={ myChangeHandler  }
                    ref={focusRef} 
                />
                <nav className={`menu ${isActiveSelectBusca ? "ativo": ""}`} >
                    <Scrollbars className='listDropDown'  style={{  height: 
                    option.length === 0 ? 30 : "" ||
                    option.length === 1 ? 30 : ""  ||
                    option.length === 2 ? 70 : ""  ||
                    option.length >= 3 ? 120 : 120
                    }}>
                        <ul>
                            { 
                                option.length === undefined ||
                                option.length === 0 ?
                                    <li>
                                        <button>Nada encontrado</button>
                                    </li>
                                :
                                option
                                .map((optionIntem, index) => (
                                    <li key={index}>
                                        <button 
                                        value={ 
                                            validacaoValue === "pais" ? optionIntem.nome : "" ||
                                            validacaoValue === "estado" ? optionIntem.id : optionIntem
                                        }
                                        name={nameButton !== undefined ? nameButton : ""}
                                        onClick={myChangeHandler !== undefined ? myChangeHandler : ""}
                                        >{
                                            validacaoOptionIntem === "pais" ? optionIntem.nome : ""||
                                            validacaoOptionIntem === "estado" ? optionIntem.nome
                                            : optionIntem
                                        }</button>
                                    </li>
                                ))}
                        </ul>
                        
                    </Scrollbars>
                </nav>
            </div>

            :  typeSelect === "busca2" ?
                <div className="select-busca-btn">
                    <input 
                        type="text" 
                        className={loadError && loadError === true ? "menu-button inputErro" : "menu-button"}
                        placeholder='Selecione'
                        name={nameCampoBusca}
                        value={
                            loadOption ? "Carregando..." :
                            busca && busca !== ""  ? busca
                            : ""
                        }
                        autoComplete="autocompleteoff"
                        onClick={ SelectBusca }
                        onBlur={ () => (setIsActiveSelectBusca(false)) }
                        onKeyUp={ () => (setIsActiveSelectBusca(true))}
                        onChange={ myChangeHandlerSelects  }
                        ref={focusRef} 
                    />
                    <nav className={`menu ${isActiveSelectBusca ? "ativo": ""}`} >
                        <Scrollbars className='listDropDown'  style={{  height: 
                        optionFilter?.length === 0 ? 40 : "" ||
                        optionFilter?.length === 1 ? 40 : ""  ||
                        optionFilter?.length === 2 ? 70 : ""  ||
                        optionFilter?.length >= 3 ? 120 : 120
                        }}>
                            <ul>
                                { 
                                    optionFilter.length === undefined ||
                                    optionFilter?.length === 0 ?
                                        <li>
                                            <button>Nada encontrado</button>
                                        </li>
                                    :
                                    optionFilter && optionFilter.length === 0 ?"":optionFilter
                                    .map((optionIntem, index) => (
                                        <li key={index}>
                                            <button 
                                            value={ 
                                                validacaoValue === "pais" ? optionIntem.nome : "" ||
                                                validacaoValue === "estado" ? optionIntem.id : optionIntem
                                            }
                                            name={nameButton !== undefined ? nameButton : ""}
                                            onClick={myChangeHandler ? myChangeHandler  :""}
                                            >{
                                                validacaoOptionIntem === "pais" ? optionIntem.nome : ""||
                                                validacaoOptionIntem === "estado" ? optionIntem.nome
                                                : optionIntem
                                            }</button>
                                        </li>
                                    ))}
                            </ul>
                            
                        </Scrollbars>
                    </nav>
                </div>
            :
            <div className="select-busca-btn">
                <button 
                    className={loadError && loadError === true ? "menu-button inputErro" : "menu-button"}
                    onBlur={ () => (setIsActiveSelectLaborBond(false)) }
                    onClick={ SelectLaborBond }
                    ref={focusRef} 
                >
                    <span>
                    {valorCampo && valorCampo !== undefined ? valorCampo : <p className='placeholder'>{placeholder && placeholder ? placeholder : "Selecione"}</p> }
                    </span>

                </button>
                <nav className={`menu ${isActiveSelectLaborBond ? "ativo": ""}`} >
                    <Scrollbars className='listDropDown'  style={{  height: 
                    option?.length === 0 ? 30 : "" ||
                    option?.length === 1 ? 30 : ""  ||
                    option?.length === 2 ? 70 : ""  ||
                    option?.length >= 3 ? 120 : 30
                    }}>
                    <ul>
                        {
                            option && option.map((optionIntem, index) => (
                                <>
                                    <li key={index}>
                                        <button 
                                        // validacaoValue
                                        // validacaoOptionIntem
                                        value={ 
                                            validacaoValue === "pais" ? optionIntem.nome : "" ||
                                            validacaoValue === "estado" ? optionIntem.id : optionIntem
                                        }
                                        name={nameButton !== undefined ? nameButton : ""}
                                        onClick={myChangeHandler !== undefined ? myChangeHandler : ""}
                                        >{ 
                                            validacaoOptionIntem === "pais" ? optionIntem.nome : ""||
                                            validacaoOptionIntem === "estado" ? optionIntem.nome
                                            : optionIntem
                                            }</button>
                                    </li>
                                </>
                            ))}
                    </ul>
                        
                        </Scrollbars>
                </nav>
            </div>

        }
        
        
        </>
    )
}