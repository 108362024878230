import React, {useEffect, useRef} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink } from 'react-router-dom';


export default function SubHeaderTalents(path) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusConfig  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusAparencia  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPoliticaPrivacidade  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(path.path === '/recrutamento/configuracoes-da-pagina' ) {
            focusConfig.current.focus();
        } else if(path.path === '/recrutamento/politica-e-privacidade-da-pagina' ) {
            focusPoliticaPrivacidade.current.focus();
        } else if(path.path === '/recrutamento/personalizar-pagina' ) {
            focusAparencia.current.focus();
        }


    }, [path.path]);




    return (
        <>
            <div className='page-customizePage-subMenu'>
                <Scrollbars>
                    <div className='scroll-padding'>
                        <ul>
                            <li>
                                <NavLink ref={focusAparencia} to={"/recrutamento/personalizar-pagina"} exact activeClassName="active" className="link">
                                    <span>Aparência</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink ref={focusPoliticaPrivacidade} to={"/recrutamento/politica-e-privacidade-da-pagina"} exact activeClassName="active" className="link">
                                    <span>Política de privacidade</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink  ref={focusConfig}  to={"/recrutamento/configuracoes-da-pagina"} exact activeClassName="active" className="link">
                                    <span>Configurações</span>    
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </Scrollbars>
            </div>
        </>
    );
}

