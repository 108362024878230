import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import Carregando from '../../../assets/images/carregando.gif';
import api from '../../../services/api';
// import Dropzone from "react-dropzone";
// import IconCamera from '../../../assets/images/icone-camera.svg';
// import CarregandoFoto from '../../../assets/images/carregando.gif';
import swal from 'sweetalert';
// import axios from 'axios';
// import {
//     phones,
//     mCNPJ,
//     mCNPJ_ONLY_NUMBERS,
//     phoneNumbersOnly
// } from '../../../services/mask';

import './styles.scss';
export default function pageChecklists(props, path) {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setCompania] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [checkList, setCheckList] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadCheckList, setLoadCheckList] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcompany();
        loadChecks();
    }, []);



    async function loadChecks() {
        setLoadCheckList(true)
        await api.get('/checklist-admission-item/')
        .then(response => {
            setCheckList(response.data)
            setLoadCheckList(false)
            setLoadNameError(false)
            setChecklistName({})
        }).catch(error => {})
    }


    async function loadcompany() {
        await api.get('/company-admin/')
        .then(response => {
            setCompania(response.data)
        }).catch(error => {})
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameError, setLoadNameError] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtn, setLoadBtn] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [checklistName, setChecklistName] = useState([]);


    async function myChangeHandlerChecklist(event) {

        let nam = event.target.name;
        let val = event.target.value;
        if(val.length === 0) {
            setLoadNameError(true)
            setChecklistName({ ...checklistName,[nam]: val })
        }
        else {
            setLoadNameError(false)
            setChecklistName({ ...checklistName,[nam]: val })
        }

    }

    async function updateCheckListName(event) {
        event.preventDefault();

        if(checklistName._id !== undefined){
            await api.put(`/checklist-admission-item/${checklistName._id}`, checklistName)
            .then(response => {

                loadChecks();


                setLoadBtn(false)
                setChecklistName({})
                swal({ icon: "success", title: "Sucesso!", text: "Item atualizado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoadBtn(false)
            });


        } else {

            if(
                checklistName                                   &&
                checklistName.name                !== undefined &&
                checklistName.name                !== ""
            )  {

                setLoadNameError(false)
                setLoadBtn(false)
            }else {
                setLoadNameError(true)
                setLoadBtn(false)
                return
            }


            setLoadBtn(true)

            await api.post("/checklist-admission-item/", checklistName)
            .then(response => {
                loadChecks();
                setLoadBtn(false)
                setChecklistName({})
                swal({ icon: "success", title: "Sucesso!", text: "Item criado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                if( error.response.data.message === "Item já cadastrado com este nome!") {
                    setLoadNameError(true)
                }
                setLoadBtn(false)
            });
        }

    }

    //// ==> deletar item da lista
    async function deleteItem(e) {

        swal({
            title: "Atenção",
            text: "Deseja realmente excluir este item?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/checklist-admission-item/${e._id}`)
                .then(response => {

                    loadChecks();

                }).catch(error => {});

            }
        });
    }

    //// ==> editar item da lista
    async function editarItem(e) {
        let checklistName = e ;

        setChecklistName({ ...checklistName})

    }
    return (
       <>

       <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className='configSystem-checklist'>
                    <h1 className='titulo'>Checklist de nova admissão</h1>
                    <div className='form-list'>
                        <div className='form'>
                            <input type="text" name="name" 
                                maxLength='50' placeholder='Texto do item de checklist.'
                                value={checklistName && checklistName?.name !== undefined ? checklistName.name : ""}
                                onChange={myChangeHandlerChecklist}
                                className={loadNameError ? 'inputErro' : ''}
                            />
                            {/* <textarea
                                name="name" cols="30" rows="10"
                                maxLength='50' placeholder='Escreva'
                                value={checklistName && checklistName?.name !== undefined ? checklistName.name : ""}
                                onChange={myChangeHandlerChecklist}
                                className={loadNameError ? 'inputErro' : ''}
                            ></textarea> */}
                            { loadNameError ? <p className="textError">O campo é obrigatório.</p> : "" }
                            <button
                                onClick={updateCheckListName}
                                className={loadBtn ? 'btnGreen load' : 'btnGreen'}
                            >Salvar</button>
                        </div>
                        <div className='list'>
                            <div className='tabela'>

                                {  loadCheckList ?
                                    <>
                                    <div className='carregando'><img src={Carregando} alt="carregando" /></div>
                                    </>
                                    :
                                    checkList.length !== 0 ?
                                    <>

                                        <div className='cabeca'>Itens do checklist</div>

                                        <ul className='corpo'>
                                        {
                                            checkList
                                            .map((item, index) => (
                                                <li>
                                                    <span className='nome'>{item.name}</span>
                                                    <button className='editar'  onClick={() => editarItem(item)}>Editar</button>
                                                    <button className='excluir'  onClick={() => deleteItem(item)}>Excluir</button>
                                                </li>
                                            ))
                                            }
                                        </ul>

                                    </>
                                    :
                                    <>
                                        <div className='vazio'>Você ainda não possui nenhum item em seu checklist</div>
                                    </>
                                }


                            </div>

                        </div>
                    </div>
                </div>
        </Layout>
       </>
    );
}
