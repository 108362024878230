import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { Dropdown } from "react-bootstrap";
import Dropzone from "react-dropzone";
import iconVerMais from '../../../../assets/images/more.svg';
import CarregandoFoto from '../../../../assets/images/carregando.gif';
import IconCamera from '../../../../assets/images/icone-camera.svg';
import Close from '../../../../assets/images/close.svg';
import IconEnviadoSucess from '../../../../assets/images/success.svg';
import { Modal } from "react-bootstrap";
import iconEyeSee from '../../../../assets/images/iconEyeSee.svg';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import { toast } from 'react-toastify';

import api from '../../../../services/api';



export default function inforProfile({
    id,
    path,
    collaborator,
    loadAvatar,
    handleDropAvatarAdminProfile,
    role,
    loadcollaborator,
})  {

    if(collaborator && collaborator.avatar !== undefined && collaborator.avatar !== 'undefined') {
        localStorage.setItem('@peopleview/avatar', collaborator.avatar);

    }

    const salaryStorage = collaborator.salary;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {

            async function loadcollaborator() {
                await api.get(`/collaborator/${id}`)
                .then(response => {
    
                    if(response.data.name !== undefined) {
                        localStorage.setItem('@peopleview/nameProfile', response.data.name);
                    } else {
                        localStorage.setItem('@peopleview/nameProfile', '');
                    }
    
                    if(response.data.occupation !== undefined) {
                        localStorage.setItem('@peopleview/collaboratorOccupation', response.data.occupation);
                    } else {
                        localStorage.setItem('@peopleview/collaboratorOccupation', '');
                    }
    
                    if(
                        salaryStorage && salaryStorage !== undefined
                    ) {
                        localStorage.setItem('@peopleview/collaboratorSalary', salaryStorage);
                    }
                    else
                    //response.data.salary !== undefined
                    if(
                        response.data.salary !== undefined
                    ) {
                        localStorage.setItem('@peopleview/collaboratorSalary', response.data.salary);
                    }
                    else {
                        localStorage.setItem('@peopleview/collaboratorSalary', '');
                    }
    
                    if(response.data.sector !== undefined) {
                        localStorage.setItem('@peopleview/collaboratorSector', response.data.sector);
                    } else {
                        localStorage.setItem('@peopleview/collaboratorSector', '');
                    }
    
                    if(response.data.admissionDate !== undefined) {
                        localStorage.setItem('@peopleview/collaboratorAdmissionDate', response.data.admissionDate);
                    } else {
                        localStorage.setItem('@peopleview/collaboratorAdmissionDate','');
                    }
    
                    if(response.data.nameManager !== undefined) {
                        localStorage.setItem('@peopleview/nameManager', response.data.nameManager);
                    } else {
                        localStorage.setItem('@peopleview/nameManager', '');
                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                })
            }
            loadcollaborator();
        }

    }, [id, salaryStorage]);




    var   avatar                    = localStorage.getItem('@peopleview/avatar');
    const nameprofile               = localStorage.getItem('@peopleview/nameProfile');
    const collaboratoroccupation    = localStorage.getItem('@peopleview/collaboratorOccupation');
    const collaboratorsalary        = localStorage.getItem('@peopleview/collaboratorSalary');
    const collaboratorsector        = localStorage.getItem('@peopleview/collaboratorSector');
    const collaboratoradmissionDate = localStorage.getItem('@peopleview/collaboratorAdmissionDate');
    const namemanager               = localStorage.getItem('@peopleview/nameManager');


    //////////////////////////////
    //////////////////////////////
    //    Desliga colaborador
    //////////////////////////////
    //////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorDesliga, setCollaboratorDesliga ]  = useState([]);

    const myChangeHandlerStatusEdition = async (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        // console.log(val)
        let admissionProcess = "false";
        let shutdownProcess = "true";
        let recentShutdown  = "false";
        let status = "Inativo";

        setCollaboratorDesliga({ status, [nam]: val, admissionProcess, recentShutdown, shutdownProcess })
    }

    async function desligarColaborador(event) {
        event.preventDefault();
        swal({
            title: "Atenção",
            text: "Deseja realmente desligar este colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                formData.append('_id', collaboratorDesliga._id);
                formData.append('admissionProcess', collaboratorDesliga.admissionProcess);
                formData.append('shutdownProcess', collaboratorDesliga.shutdownProcess);
                formData.append('recentShutdown', collaboratorDesliga.recentShutdown);
                formData.append('status', collaboratorDesliga.status);
                formData.append('path', path);
                // setLoad(true)
                await api.put(`/collaborator/${collaboratorDesliga._id}`, formData, config)
                .then(response => {
                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador desligado com sucesso." });
                    window.location.href = `/colaboradores/processo-desligamento`;
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao desligar o colaborador, tente novamente mais tarde." });
                });
            }
        }).catch(error => {});
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePopupRedefinirSenha, setVisiblePopupRedefinirSenha] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleMensagemSucessoRS, setVisibleMensagemSucessoRS] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleMensagemSucessoRS_a, setVisibleMensagemSucessoRS_a] = useState(false);



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonUpdatePassword, setLoadButtonUpdatePassword] = useState(false);

    async function AbrirRedefinirSenha(e) {
        setVisiblePopupRedefinirSenha(!visiblePopupRedefinirSenha);
        setVisibleMensagemSucessoRS(false)
    }

    // const currentDate = new Date()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ temporaryPassword, setTemporaryPassword ]  = useState('');

    async function RedefinirSenha(event) {
        event.preventDefault();
        setLoadButtonUpdatePassword(true)
        // formData.append('path', path);
        await api.put(`/reset-collaborator-password/${id}?path=${path}`)
        .then(response => {
            // console.log('response.data')
            // console.log(response.data)
            if(response.data?.email) {
                setVisibleMensagemSucessoRS(true)
                setLoadButtonUpdatePassword(false)
            }
            else
            if(response.data?.temporaryPassword) {
                setTemporaryPassword(response.data?.temporaryPassword)
                setVisibleMensagemSucessoRS_a(true)
                setLoadButtonUpdatePassword(false)
            }
            else {}
            loadcollaborator(id);

        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao redefinar senha, tente novamente mais tarde." });
        });

    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [verSalario, setVerSalario] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [esconderSalario, setEsconderVerSalario] = useState(true);

    function VerSalario() {
    setVerSalario(false);
    setEsconderVerSalario(true);
    }
    function EsconderSalario() {
    setVerSalario(true);
    setEsconderVerSalario(false);
    }

        return (
            <>
                <section className="infor-perfil">
                    {
                    loadAvatar ?
                    (
                        <img src={CarregandoFoto} alt="carregando" className="carregamento-foto" />
                    )
                    :
                    role && role === 'manager' ?
                        avatar && avatar ?
                        <img alt={collaborator.name}  src={avatar}   className="foto-role"/>
                        :
                        <div className="semImgCol">
                            <img  src={IconUserNone} alt="Usuario sem imagem" />
                        </div>
                    :
                    avatar && avatar ? (

                        <Dropzone onDrop={handleDropAvatarAdminProfile} >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "upload-foto2" })}>
                                {/* <img src={collaborator.avatar} alt={collaborator.name} className="img-adicionada"/> */}
                                <label className="label-foto-adicionado" ><img alt="+" src={IconCamera} className="icon"/><img alt={collaborator.name}  src={avatar}   className="foto"/></label>
                                <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                            </div>
                            )}
                        </Dropzone>

                    ) : (
                        <Dropzone onDrop={handleDropAvatarAdminProfile} >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "upload-foto2" })}>
                                <label className="labelIconPhoto"><div  className="iconPhoto"> <span></span> Enviar foto</div> </label>
                                <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                            </div>
                            )}
                        </Dropzone>
                    )}
                    <div className='infor-name-cargo'>
                        <h2 className="none">{nameprofile}</h2>
                        <p className="cargo">{collaboratoroccupation && collaboratoroccupation !== undefined ? collaboratoroccupation : '-'}</p>
                    </div>
                    <div className="infor">
                        <h2 className="none">{nameprofile}</h2>
                        <p className="cargo">{collaboratoroccupation && collaboratoroccupation !== undefined ? collaboratoroccupation : '-'}</p>
                        <div className="bloco-section">
                            <div className="bloco">
                                <h4>Departamento</h4>
                                <p>{collaboratorsector && collaboratorsector !== undefined ? collaboratorsector : '-'}</p>
                            </div>
                            <div className="bloco salario">
                                <h4>Salário</h4>

                                {verSalario ? <>
                                    <button onClick={() => VerSalario()} >
                                        {
                                            collaboratorsalary && collaboratorsalary !== undefined && collaboratorsalary !== '' ?
                                        (
                                            <>
                                            R$ {collaboratorsalary}
                                            </>
                                            ) : ('R$ 0,00')
                                        }

                                    </button>
                                </> : ""}
                                {esconderSalario ? <>
                                    <button onClick={() => EsconderSalario()} >
                                       <img src={iconEyeSee} alt="Olho" />

                                    </button>
                                </> : ""}

                                {/* <p>
                                    { collaboratorsalary && collaboratorsalary  !== undefined ? (collaboratorsalary)  : 'R$ 0,00'}
                                    </p> */}
                                {/* {
                                collaboratorsalary && collaboratorsalary !== undefined && collaboratorsalary !== '' ?
                                (
                                    <p>
                                    R$ {collaboratorsalary}
                                    </p>
                                ) : ('R$ 0,00')
                                } */}
                            </div>
                            <div className="bloco">
                                <h4>Admissão</h4>
                                <p>{collaboratoradmissionDate && collaboratoradmissionDate !== undefined ? collaboratoradmissionDate : '-'}</p>
                            </div>
                            <div className="bloco">
                                <h4>Gestor</h4>
                                <p>{namemanager && namemanager !== undefined ? namemanager : '-'}</p>
                            </div>
                        </div>

                    </div>
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <>
                        <div className="dropdow">
                            <Dropdown  className="dropdown-icon dropdown-perfil">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                        <Dropzone onDrop={handleDropAvatarAdminProfile} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "Dropdown-dropzone" })}>
                                                <label className="label-foto" >{avatar && avatar ? ("Alterar foto"):("Adicionar foto")}</label>
                                                <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                            </div>
                                            )}
                                        </Dropzone>
                                        <button type="button"  onClick={() => AbrirRedefinirSenha(collaborator._id)} >
                                            Redefinir senha
                                        </button>

                                        <form onSubmit={desligarColaborador}>
                                            <button onClick={myChangeHandlerStatusEdition} name="_id" value={collaborator._id}  type="submit" className="red">
                                                Desligar colaborador
                                            </button>
                                        </form>
                                        {/* <button type="button">
                                            Exportar perfil
                                        </button> */}

                                        {/* <button type="button">
                                            Enviar para contabilidade
                                        </button> */}

                                        {/* <button type="button">
                                            Migrar colaborador
                                        </button> */}

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        </>
                        :
                        ''
                    }
                </section>


        <Modal show={visiblePopupRedefinirSenha} className="popup-geral popup-edit-cargo" onHide={AbrirRedefinirSenha}>
            <div>
                <div className="titulo">
                    <h4>Redefinir senha <p className="btn-fechar" onClick={AbrirRedefinirSenha}><img src={Close} alt="Fechar" /></p></h4>
                </div>

                <div className="modal-redefinir-senha">
                    <div className="form-inf">
                        <p>Se o colaborador possuir e-mail cadastrado ele receberá um email com o processo para redefinir a senha, caso não, você terá que informar a nova senha para ele que será gerada após você clicar em (Redefinir senha).</p>
                        <button className={loadButtonUpdatePassword ? 'btnGreen load btn' : 'btnGreen btn'} onClick={RedefinirSenha} >Redefinir senha</button>
                    </div>
                    {
                        visibleMensagemSucessoRS ?
                        <>
                            <div className="enviado-com-sucesso">
                                <p><img src={IconEnviadoSucess} alt="Enviado" /> Email enviado ao colaborador</p>
                            </div>
                        </>
                        :
                        ""
                    }
                    {
                        // visibleMensagemSucessoRS_a
                        visibleMensagemSucessoRS_a ?
                        <>
                            <div className="enviado-com-sucesso">
                                <p><img src={IconEnviadoSucess} alt="Enviado" /> Senha temporária redefinida para {temporaryPassword}</p>
                            </div>
                        </>
                        :
                        ""
                    }
                </div>
            </div>
        </Modal>

    </>
    );
}
