import React, {
    useState,
    useEffect
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/headerEmployeeTimeSheet';
import iconArrowRed from '../../../assets/images/arrow-icon-red.svg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import iconSetaV from '../../../assets/images/v.svg';
import iconArrowGreen from '../../../assets/images/arrow-icon-green.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import { Pagination } from 'antd';
import { Modal } from "react-bootstrap";
import apiV2 from '../../../services/apiV2';
import api from '../../../services/api';
import { dateMask } from '../../../services/mask';
import { 
    select_string_time,
    getDayofTheWeek,
    diaMesFormatadaParaTraco,
} from '../../../services/utils';
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import NextIcon from "../../../assets/images/arrow-right.svg"
import PrevIcon from "../../../assets/images/arrow-left2.svg"


import './styles.scss';

export default function ScoreAdmin(props) {

    const { id }   = props.match.params;

    var data = new Date();
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();

    const [ collaborator, setCollaborator ] = useState({});
    const [ , setLoadCollaborator ] = useState(false);
    const [ loadListPage, setLoadListPage] = useState(false);
    const todaysDate = new Date();
    const [ periodStartFilder, setPeriodStartFilder]  = useState(moment().startOf('week').format("YYYY-MM-DD"));
    const [ periodEndFilder, setPeriodEndFilder]  = useState(moment(todaysDate).format("YYYY-MM-DD"));

    const [ listOfRegisteredPointsItems, setListOfRegisteredPointsItems ] = useState([]);
    const [ listOfRegisteredPointsMetadata, setListOfRegisteredPointsMetadata ] = useState([]);
    
    const [ page, setPage] = useState(1);
    const [ limit, setLimit] = useState(25);
    const [ totalIntens, setTotalIntens] = useState(0);
    const pages = Math.ceil(parseInt(totalIntens)  /parseInt(limit));

    const [ selectedOptionFilder, setSelectedOptionFilder]  = useState("");
    const [ periodStartString, setPeriodStartString]  = useState("");
    const [ periodEndString, setPeriodEndString]  = useState("");


    useEffect(() => {
        loadcollaborator(id);
    }, [id]);

    useEffect(() => {
        listOfRegisteredPoints(id, periodStartFilder,periodEndFilder);
    }, [id, periodStartFilder, periodEndFilder]);

    async function loadcollaborator(id) {
        setLoadCollaborator(true);
        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
            setLoadCollaborator(false);
        }).catch(error => {setLoadCollaborator(false);});
    }

    async function listOfRegisteredPoints(id,periodStartFilder,periodEndFilder) {
        setLoadListPage(true);
        await apiV2.get(`/punch-clock?collaborator._id=${id}&punchClockDateRange[0]=${periodStartFilder}&punchClockDateRange[1]=${periodEndFilder}&order=desc&limit=25&page=1`)
        .then(response => {  
            if(response?.data.paging.page) setPage(response?.data.paging.page )
            if(response?.data.paging.limit) setLimit(response?.data.paging.limit)
            setTotalIntens(response?.data.paging.total)
            if(response?.data?.items) setListOfRegisteredPointsItems(response?.data?.items);
            if(response?.data?.metadata) setListOfRegisteredPointsMetadata(response?.data?.metadata);
            setLoadListPage(false);
        }).catch(error => {
            setLoadListPage(false);
        })
    }

    const totalExtra = select_string_time(listOfRegisteredPointsMetadata?.totalExtraTime ?? "00:00");
    const totalNegative = select_string_time(listOfRegisteredPointsMetadata?.totalNegativeTime ?? "00:00");
    const hoursBalance = select_string_time(listOfRegisteredPointsMetadata?.hoursBalance ?? "00:00");

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    
    async function journeyFilter(event) {
        setIsOpen(!isOpen);
        setSelectedOptionFilder(event)

        if(event === "showCurrentWeek") {

            const firstDay = moment().startOf('week').format("YYYY-MM-DD");
            const lastDay = moment().endOf('week').format("YYYY-MM-DD");
            setPeriodStartFilder(firstDay);
            setPeriodEndFilder(lastDay);
            const firstDayString = moment().startOf('week').format("DD/MM/YYYY");
            const lastDayString = moment().endOf('week').format("DD/MM/YYYY");
            setPeriodStartString(firstDayString);
            setPeriodEndString(lastDayString);

            
        } else if(event === "showOnlyYesterday") {

            const showOnlyYesterday = moment().subtract(1, "days").format("YYYY-MM-DD");
            setPeriodStartFilder(showOnlyYesterday)
            setPeriodEndFilder(showOnlyYesterday);
            const momentData = moment(showOnlyYesterday, 'YYYY-MM-DD');
            const novaData = momentData.format('DD/MM/YYYY');
            setPeriodStartString(novaData);
            setPeriodEndString(novaData);

        } else if (event === "showLastWeek") {

            const primeiraData = moment().subtract(1, 'week').isoWeekday(0).format("YYYY-MM-DD");
            const ultimaData = moment().subtract(1, 'week').isoWeekday(6).format("YYYY-MM-DD");
            setPeriodStartFilder(primeiraData);
            setPeriodEndFilder(ultimaData);
            const primeiraDataString = moment().subtract(1, 'week').isoWeekday(0).format("DD/MM/YYYY");
            const ultimaDataString = moment().subtract(1, 'week').isoWeekday(6).format("DD/MM/YYYY");
            setPeriodStartString(primeiraDataString);
            setPeriodEndString(ultimaDataString);

        } else if (event === "showLastMonth") {
            const firstDayOfMonth = moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
            const lastDayOfMonth = moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
            setPeriodStartFilder(firstDayOfMonth);
            setPeriodEndFilder(lastDayOfMonth);
            const firstDayOfMonthString = moment().subtract(1, 'month').startOf('month').format("DD/MM/YYYY");
            const lastDayOfMonthString = moment().subtract(1, 'month').endOf('month').format("DD/MM/YYYY");
            setPeriodStartString(firstDayOfMonthString);
            setPeriodEndString(lastDayOfMonthString);

        }

    }

    function myChangeHandlerPerPeriod(event) {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam === "startPeriod_") {
            const dateMaskString = dateMask(val)
            setPeriodStartString(dateMaskString)
            if(val.length === 10) {

                const data = moment(dateMaskString, 'DD/MM/YYYY').format('YYYY-MM-DD');
                setPeriodStartFilder(data)

            }
        } else
        if(nam === "endPeriod_") {
            const dateMaskString = dateMask(val)
            setPeriodEndString(dateMaskString)
            if(val.length === 10) {

                const data = moment(dateMaskString, 'DD/MM/YYYY').format('YYYY-MM-DD');
                setPeriodEndFilder(data)
                
            }
        }
    }

    async function listOfRegisteredPointsPaginetion(periodStartFilder,periodEndFilder,limit,page) {
        setLoadListPage(true);
        await apiV2.get(`/punch-clock?punchClockDateRange[0]=${periodStartFilder}&punchClockDateRange[1]=${periodEndFilder}&order=desc&limit=${limit}&page=${page}`)
        .then(response => {
            if(response?.data.paging.page) setPage(response?.data.paging.page)
            if(response?.data.paging.limit) setLimit(response?.data.paging.limit)
            if(response?.data?.items) setListOfRegisteredPointsItems(response?.data?.items);
            if(response?.data?.metadata) setListOfRegisteredPointsMetadata(response?.data?.metadata);
            setLoadListPage(false);
        }).catch(error => {
            setLoadListPage(false);
        })
    }

    async function myChangeHandlerPaginetion(pageSelect) {
        setPage(pageSelect);
        listOfRegisteredPointsPaginetion(periodStartFilder,periodEndFilder,limit,page)
        return
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <p> <img src={PrevIcon} alt="" /> anterior</p>;
        }
        if (type === 'next') {
            return <p>  próxima <img src={NextIcon} alt="" /></p>;
        }
        return originalElement;
    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPontoResumo, setIsModalPontoResumo ] = useState(false);
    function abirModalPontoResumo() {
        setIsModalPontoResumo(true);
    }
    function FecharIsModalPontoResumo() {
        setIsModalPontoResumo(false);
    }
    return (
        <>
            <Layout path={props.match.path}>
                <Header collaborator={collaborator}/>
                <div className="page-ponto">
                    <div className="page-folha-ponto">
                        <div className='resumo-col'>
                            <div className='infor-col'>
                                {
                                    collaborator?.avatar ?
                                    <img alt="" src={collaborator?.avatar} className="foto-colaborador"/>
                                    :
                                    <div className="semImgCol">
                                        <img src={IconUserNone} alt="Usuario sem imagem" />
                                    </div>
                                }
                                <div className="nome-cargo">
                                    <strong className="nome">{collaborator?.name ?? "-"}</strong>
                                    <p className="cargo">{collaborator?.occupation ?? "-"}</p>
                                </div>
                            </div>
                            <div className='infor'>
                                <div>
                                    <p className='text'>Horas extras</p>
                                    <div className='hr-minuto'>
                                        <img src={iconArrowGreen} alt="" />
                                        <p>
                                            {   
                                                parseInt(totalExtra[0]) === 0 ?
                                                    <span className='minuto'>{parseInt(totalExtra[1])} minutos</span>:
                                                <>
                                                    <span> {parseInt(totalExtra[0])}  hora{parseInt(totalExtra[0]) === 1 || parseInt(totalExtra[0]) === -1 ? "":"s"}</span> 
                                                    <span className='minuto'> {parseInt(totalExtra[1])} minuto{parseInt(totalExtra[1]) === 1 ? "":"s"}</span>
                                                </>
                                            }
                                        </p>  
                                            
                                            
                                    </div>
                                </div>
                                <div>
                                    <p className='text'>Atrasos</p>
                                    <div className='hr-minuto'>
                                        <img src={iconArrowRed} alt="" />
                                        <p>
                                            {   
                                                parseInt(totalNegative[0]) === 0 ?
                                                    <span className='minuto'>{parseInt(totalNegative[1])} minutos</span>:
                                                <>
                                                    <span> {parseInt(totalNegative[0])} hora{parseInt(totalNegative[0]) === 1 || parseInt(totalNegative[0]) === -1 ? "":"s"}</span> 
                                                    <span className='minuto'> {parseInt(totalNegative[1])} minuto{parseInt(totalNegative[1]) === 1 ? "":"s"}</span>
                                                </>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <p className='text'>Jornada</p>
                                    <div className='hr-minuto'>
                                        <img src={iconArrowRed} alt="" />
                                        <p><span>{listOfRegisteredPointsMetadata?.weeklyJourney?.name ? listOfRegisteredPointsMetadata?.weeklyJourney?.name : "Nenhum"}</span></p>
                                    </div>
                                </div>
                                <div className='btn-ver-detalher'>
                                    <p className='text'>{" "}</p>
                                    <button onClick={abirModalPontoResumo}>Ver detalhes</button>
                                </div>
                            </div>
                        </div>
                        <div className='header-menu'>
                            <div className='titulo-datas'> 
                                <h3 className='titulo'>
                                {
                                    mes  === '01'    ? 'Janeiro' :
                                    mes  === '02'    ? 'Fevereiro' :
                                    mes  === '03'    ? 'Março' :
                                    mes  === '04'    ? 'Abril' :
                                    mes  === '05'    ? 'Maio' :
                                    mes  === '06'    ? 'Junho' :
                                    mes  === '07'    ? 'Julho' :
                                    mes  === '08'    ? 'Agosto' :
                                    mes  === '09'    ? 'Setembro' :
                                    mes  === '10'    ? 'Outubro' :
                                    mes  === '11'   ? 'Novembro' :
                                    mes  === '12'   ? 'Dezembro'  : ''
                                }  de {ano}
                                </h3>
                                <div className='date'>
                                    <p>Período</p>
                                    <div className='datas'>
                                        <input
                                            type="text"
                                            name="startPeriod_"
                                            value={periodStartString !== "" ? periodStartString : ''}
                                            onChange={myChangeHandlerPerPeriod}
                                            placeholder="00/00/0000"
                                            className="data"
                                        />
                                        <input
                                            type="text"
                                            name="endPeriod_"
                                            value={periodEndString !== "" ? periodEndString : ''}
                                            onChange={myChangeHandlerPerPeriod}
                                            placeholder="00/00/0000"
                                            className="data"
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className='fucoes'>
                                <Dropdown   show={isOpen} onToggle={toggleDropdown} className="dropdown-icon">
                                    <Dropdown.Toggle>

                                        {   selectedOptionFilder === "" ||
                                            selectedOptionFilder === "showCurrentWeek" ?
                                            "Mostrando semana atual"
                                            :
                                            selectedOptionFilder === "showOnlyYesterday" ?
                                            "Mostrar Apenas ontem"
                                            :
                                            selectedOptionFilder === "showLastWeek" ?
                                            "Mostrar na última semana"
                                            :
                                            selectedOptionFilder === "showLastMonth" ?
                                            "Mostrar último mês" : "Mostrando semana atual"
                                        }
                                        <img alt="" src={iconSetaV} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button  type="button" name="" onClick={() => journeyFilter("showCurrentWeek") }>
                                            Mostrar semana atual
                                        </button>
                                        <button  type="button" name="" onClick={() => journeyFilter("showOnlyYesterday")}>
                                            Mostrar Apenas ontem
                                        </button>
                                        <button  type="button" name="" onClick={() => journeyFilter("showLastWeek")}>
                                            Mostrar na última semana
                                        </button>
                                        <button  type="button" name="" onClick={() => journeyFilter("showLastMonth")}>
                                            Mostrar último mês
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            
                        </div>


                        <div className='tabela'>
                            <div className='header-tb'>
                                <div className='data'>
                                    <p>Data</p>
                                </div>
                                <div className='entrada-saida'>
                                    <p>Entrada</p>
                                    <p>Saída Int.</p>
                                </div>
                                <div className='horas-trabalhadas'>
                                    <p>Horas trabalhadas</p>
                                </div>
                                <div className='intervelo'>
                                    <p>Intervalo</p>
                                </div>
                                {/* <div className='add-noturno'>
                                    <p>Adic. noturno</p>
                                </div> */}
                                {/* <div className='justificativa'>
                                    <p>Justificativas</p>
                                </div> */}
                                {/* <div className='drop'></div> */}
                            </div>

                            <div className='corpo-tb'>
                            {
                                loadListPage ?
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                : 
                                listOfRegisteredPointsItems?.length !== 0 ?
                                    listOfRegisteredPointsItems?.map((collaboratorClock) => (   
                                        
                                        <div className='linha-tb' key={collaboratorClock._id}>
                                            <div className='data bloco'>
                                                <p className='name-dia'>{getDayofTheWeek(collaboratorClock?.created_at)}</p>
                                                <p>{diaMesFormatadaParaTraco(collaboratorClock.punchClockDate)}</p>
                                            </div>

                                            <div className='entrada-saida bloco'>
                                                {collaboratorClock?.checkInCheckOut.map((checkInCheckOutItem) => (  
                                                    <p>{checkInCheckOutItem?.checkTime ?? '-' }</p>
                                                    ))
                                                }
                                            </div>
                                            <div className='horas-trabalhadas'>
                                                <p>{collaboratorClock?.totalTimeWorking ?? '-'}</p>
                                            </div>
                                            <div className='intervelo'>
                                                <p>{collaboratorClock?.totalInterval?? '-'}</p>
                                            
                                            </div>
                                        </div>
                                    ))
                                : "Não foi encontrado nenhum registro de ponto batido."
                            }
                            </div>

                        </div>



                        <div className='paginacao-componente'>
                            {
                            pages <= 1 ? "":
                            <Pagination  
                                pageSize={limit}
                                // current={doNotAdd === false ? page : pageMAisUm }
                                size="default"
                                itemRender={itemRender} 
                                defaultCurrent={page}
                                onChange={myChangeHandlerPaginetion}
                                total={pages}  
                            />
                            }
                        </div> 
                    </div> 
                </div>
            </Layout>


            <Modal show={isModalPontoResumo}className="popup-geral "  restoreFocus={false} onHide={FecharIsModalPontoResumo}>
                <div className="modal-ponto-resumo">
                    <h4 className='titulo'>Resumo</h4>
                    <div className='infor-col'>
                        {
                            collaborator?.avatar ?
                            <img alt="" src={collaborator?.avatar} className="foto-colaborador"/>
                            :
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="Usuario sem imagem" />
                            </div>
                        }
                        <div className="nome-cargo">
                            <strong className="nome">{collaborator?.name ?? "-"}</strong>
                            <p className="cargo">{collaborator?.occupation ?? "-"}</p>
                        </div>
                    </div>
                    <div className="infor-jornada">
                        <h6>Jornada</h6>
                        <p className='name-journey'>{listOfRegisteredPointsMetadata?.weeklyJourney?.name ?? "Nenhum"}</p>
                        <div className='list-periods'>

                            {
                                listOfRegisteredPointsMetadata?.weeklyJourney?.days.map((dayWeek) => (
                                    <>
                                    {
                                        dayWeek?.period1?.initTime || dayWeek?.period2?.initTime ?
                                        <p>
                                            <span>
                                                {
                                                    dayWeek.dayOfWeek === "1" ?
                                                    "Segunda-feira" :
                                                    dayWeek.dayOfWeek === "2" ?
                                                    "Terça-feira" :
                                                    dayWeek.dayOfWeek === "3" ?
                                                    "Quarta-feira" :
                                                    dayWeek.dayOfWeek === "4" ?
                                                    "Quinta-feira" :
                                                    dayWeek.dayOfWeek === "5" ?
                                                    "Sexta-feira" :
                                                    dayWeek.dayOfWeek === "6" ?
                                                    "Sábado" :
                                                    dayWeek.dayOfWeek === "7" ?
                                                    "domingo" : "Domingo"
                                                }
                                            </span>
                                            {

                                                dayWeek?.period1?.initTime ?
                                                <>
                                                    {
                                                        dayWeek?.period1?.initTime ?
                                                            dayWeek?.period1?.initTime : "-"
                                                    } - {
                                                        dayWeek?.period1?.endTime ?
                                                            dayWeek?.period1?.endTime : "-"
                                                    }
                                                </>: ""
                                            }
                                            {
                                                dayWeek?.period2?.initTime ?
                                                <>/
                                                    {
                                                        dayWeek?.period2?.initTime ?
                                                            dayWeek?.period2?.initTime : "-"
                                                    } - {
                                                        dayWeek?.period2?.endTime ?
                                                            dayWeek?.period2?.endTime : "-"
                                                    }
                                                </>: ""
                                            }
                                            
                                        </p>
                                        :""
                                    }
                                    </>
                                ))
                            }
                        </div>

                        <div className='infor-horas'>
                            <h6>Total de horas extras no período</h6>
                            <div className='hr-minuto'>
                                <img src={iconArrowGreen} alt="" />
                                <p>
                                
                                {   
                                    parseInt(totalExtra[0]) === 0 ?
                                        <span className='minuto'>{parseInt(totalExtra[1])} minutos</span>:
                                    <>
                                        <span> {parseInt(totalExtra[0])}  hora{parseInt(totalExtra[0]) === 1 || parseInt(totalExtra[0]) === -1 ? "":"s"}</span> 
                                        <span className='minuto'> {parseInt(totalExtra[1])} minuto{parseInt(totalExtra[1]) === 1 ? "":"s"}</span>
                                    </>
                                }
                                </p>
                            </div>
                            {/* <p>4 horas e 57 minutos extras além do limite mensal de 16 horas</p> */}
                        </div>

                        <div className='infor-horas'>
                            <h6>Total de atrasos no período</h6>
                            <div className='hr-minuto'>
                                <img src={iconArrowRed} alt="" />
                                <p>
                                
                                {   
                                    parseInt(totalNegative[0]) === 0 ?
                                        <span className='minuto'>{parseInt(totalNegative[1])} minutos</span>:
                                    <>
                                        <span> {parseInt(totalNegative[0])} hora{parseInt(totalNegative[0]) === 1 || parseInt(totalNegative[0]) === -1 ? "":"s"}</span> 
                                        <span className='minuto'> {parseInt(totalNegative[1])} minuto{parseInt(totalNegative[1]) === 1 ? "":"s"}</span>
                                    </>
                                }
                                </p>
                            </div>
                            {/* <p>Compensados pelas horas extras</p> */}
                        </div>

                        <div className='infor-horas'>
                            <h6 className='total'>Total</h6>
                            <div className='hr-minuto'>
                                {
                                parseInt(hoursBalance[0]) < 0 ?
                                <img src={iconArrowRed} alt="" />
                                :
                                <img src={iconArrowGreen} alt="" />
                                }
                                <p>
                                    {   
                                        parseInt(hoursBalance[0]) === 0 ?
                                            <span className='minuto'>{parseInt(hoursBalance[1])} minutos</span>:
                                        <>
                                            <span> {parseInt(hoursBalance[0])} hora{parseInt(hoursBalance[0]) === 1 || parseInt(hoursBalance[0]) === -1 ? "":"s"}</span> 
                                            <span className='minuto'> {parseInt(hoursBalance[1])} minuto{parseInt(hoursBalance[1]) === 1 ? "":"s"}</span>
                                        </>
                                    } 
                                    
                                </p>
                            </div>
                            {/* <p>3 horas e 45 minutos extras além do limite mensal de 16 horas</p> */}
                        </div>
                        <div className='btn-fechar'>
                            <button  onClick={()=> setIsModalPontoResumo(false)}>Fechar</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

