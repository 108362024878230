import React, { useState, useEffect } from 'react';

import api from '../../../services/api';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Departamentos/header';
import iconOrdenation from '../../../assets/images/ordenation.svg';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import Close from '../../../assets/images/close.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import { Dropdown } from "react-bootstrap";
import EstadoVazio from '../../../assets/images/illustration-departamento-vazio.svg';
import iconVerMais from '../../../assets/images/more.svg';
import { decodeToken } from '../../../services/auth';
import './styles.scss';

import { Modal } from "react-bootstrap";

export default function ColBeneficio(props) {

    const { path } = props.match;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageDepartamento, setLoadPageDepartamento ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ departamentos, setDepartamentos] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadDepartamentos();
    }, []);

    async function loadDepartamentos() {

        setLoadPageDepartamento(true)
        await api.get('/sector/?sortBy=name&order=asc')
        .then(response => {

            if(response.data.length === 0) {
                setDepartamentos([]);
            }
            else {
                setDepartamentos(response.data);
            }
            setLoadPageDepartamento(false)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageDepartamento(false)
        })
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    async function loadfiltersectors(event) {
        event.preventDefault()
        let name = event.target.value;
        setLoadPageDepartamento(true)
        if(name.length > 1) {

            await api.get(`/sector/?name=${name}`)
            .then(response => {

                if(response.data.length === 0) {
                    setDepartamentos([]);
                }
                else {
                    setDepartamentos(response.data);
                }
                setLoadPageDepartamento(false)

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadPageDepartamento(false)
            });

        }
        else {
            loadDepartamentos();
        }

    }

    ////////////////////////////////////////////////////////
    /////////// funcao selecioanr lista ou grid ///////////
    //////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [gridDepartamento, setGridDepartamento] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [listDepartamento, setListDepartamento] = useState(false);

    function SelectGrid() {
        setGridDepartamento(true);
        setListDepartamento(false);
    }

    function SelectGridList() {
        setListDepartamento(true);
        setGridDepartamento(false);
    }


    ////////////////////////////////////////////////////////
    ///////////     funcao ver popup       ////////////////
    //////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePopupDepart, setVisiblePopupDepart] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ departamento, setDepartamento] = useState([]);
    function CadastraDepartamento(e) {
        //console.log(e)
        let departamento = e;

        setDepartamento({
            ...departamento
        })
        setLoadNameCadDepartamento(false)
        setLoaddescriptionCadDepartamento(false)
        setVisiblePopupDepart(!visiblePopupDepart);
        window.setTimeout(function () { 
            document.getElementById('nameDepartamento').focus(); 
        }, 0); 
        
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePopupDepartV, setVisiblePopupDepartV] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ departamentoV, setDepartamentoV] = useState([]);

    function VerDepartamento(e) {
        //console.log(e)
        let departamentoV = e;
        setDepartamentoV({
            ...departamentoV
        })
        setVisiblePopupDepartV(!visiblePopupDepartV);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePopupDepartUpdate, setVisiblePopupDepartUpdate] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    function UpdateDepartamento(e) {
        let departamento = e;
        setDepartamento({
            ...departamento
        })
        setVisiblePopupDepartUpdate(!visiblePopupDepartUpdate);
        window.setTimeout(function () { 
            document.getElementById('nameDepartamento').focus(); 
        }, 0); 
    }

    ////////////////////////////////////////////////////////
    ///////////     funcao ver popup       ////////////////
    //////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ departmentRegistration, setDepartmentRegistration ] = useState([]);

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        setDepartmentRegistration({ ...departmentRegistration, [nam]: val, path: path })
        if(nam === "name") {
            setLoadNameCadDepartamento(false)
        }
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ departmentUpdate, setDepartmentUpdate ] = useState([]);

    const myChangeHandlerUpdate = event => {

        let nam = event.target.name;
        let val = event.target.value;

        setDepartmentUpdate({ ...departmentUpdate, [nam]: val, path: path })

    };

    ///////////////////////////////////////////////////
    /////     FUNÇÂO RESPONSÀVEL Por adicionar   /////
    //////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameCadDepartamento, setLoadNameCadDepartamento] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnCadDepartamento, setLoadBtnCadDepartamento] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loaddescriptionCadDepartamento, setLoaddescriptionCadDepartamento] = useState(false);

    async function handleSubmit(event) {
        event.preventDefault();

        if (departmentRegistration.name === undefined || departmentRegistration.name === "") {
            setLoadNameCadDepartamento(true)
        } else {
            setLoadNameCadDepartamento(false)
        }

        if (
            departmentRegistration.name              !== undefined &&
            departmentRegistration.name               !==     ""   
        ) {

            setLoadPageDepartamento(true)
            setLoadBtnCadDepartamento(true)
            await api.post('/sector/', departmentRegistration)
            .then(response => {
                setDepartmentRegistration([])
                setLoadPageDepartamento(false)
                setLoadBtnCadDepartamento(false)
                setDepartamentoV(false);
                setVisiblePopupDepart(!visiblePopupDepart);
                loadDepartamentos();

                swal({ icon: "success", title: "Sucesso!", text: "Departamento criado com sucesso!" });
                //this.propsCollaboratorsInatives();
            }).catch(error => {
                setLoadBtnCadDepartamento(false)
                setLoadPageDepartamento(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao criar departamento, tente novamente mais tarde." });
            });
        }
    }

    async function handleSubmitUpdate(event) {
        event.preventDefault();
         if(
            departmentUpdate.name              === "" ||
            departmentUpdate.description       === ""
        ) {
            swal({ icon: "error", title: "Erro!", text:  'Nenhum campo dode ser salvo em branco!'});
        } else {
            setLoadPageDepartamento(true)
            setLoadBtnCadDepartamento(true)
            api.put(`/sector/${departamento._id}`, departmentUpdate)
            .then(response => {
                setDepartmentUpdate([])
                setLoadPageDepartamento(false)
                setLoadBtnCadDepartamento(false)
                setVisiblePopupDepartUpdate(!visiblePopupDepartUpdate);
                loadDepartamentos();

                swal({ icon: "success", title: "Sucesso!", text: "Departamento editado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao editar o departamento, tente novamente mais tarde." });
                setLoadPageDepartamento(false)
                setLoadBtnCadDepartamento(false)
            });
        }
    }
    ////////////////////////////////////////////////////////////////////////////
    /////     FUNÇÂO RESPONSÀVEL POR DELETAR AS ISFOS DO DEPARTAMENTO     /////
    ///////////////////////////////////////////////////////////////////////////
    async function handleDelete(_id) {
        setLoadBtnCadDepartamento(true)
        setLoadPageDepartamento(true)
        await api.put(`/sector/${_id}?path=${path}`,{ hiringStatus: false })
        .then(response => {
            setDepartmentRegistration([]);
            setLoadBtnCadDepartamento(false)
            setVisiblePopupDepartV(false);
            setLoadPageDepartamento(false)
            loadDepartamentos();
            swal({ icon: "success", title: "Sucesso!", text: "Departamento deletado com sucesso!" });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao deletadar o Departamento!" });
        });

    }

    const { user } = decodeToken();

    async function loadDepartamentosCrescente() {

        setLoadPageDepartamento(true)
        loadDepartamentos();

    }

    async function loadDepartamentosDecrescente() {

        setLoadPageDepartamento(true)
        await api.get('/sector/?sortBy=name&order=desc')
        .then(response => {
            setDepartamentos(response.data);
            setLoadPageDepartamento(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageDepartamento(false)
        })

    }
      function handleKeyPressCadastraDpartamento(event) {
        if (event.key === "Enter") {
            handleSubmit(event);
        }
    }
    function handleKeyPressAtualizarDpartamento(event) {
        if (event.key === "Enter") {
            handleSubmitUpdate(event);
        }
    }
    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                    departamentos={departamentos}
                    CadastraDepartamento={CadastraDepartamento}
                    loadfiltersectors={(event) => loadfiltersectors(event)}
                    user={user}
                    loadPageDepartamento={loadPageDepartamento}
                />
                {
                    loadPageDepartamento ?
                    (
                    <>
                        <div className="carregamento-PGdepartamento"><img alt="" src={Carregamento} /></div>
                    </>)
                    :
                    departamentos[0] !== undefined ? (
                        <>
                        <div className="page-departamento-toogle">
                            <ul>
                                <li className={gridDepartamento ? "grid select" : "grid"} onClick={() => SelectGrid()} >Grid</li>
                                <li className={listDepartamento ? "lista select" : "lista"} onClick={() => SelectGridList()} >Lista</li>
                            </ul>
                        </div>
                {
                    gridDepartamento ? (
                    <div className="grid-departamento-page">
                        {
                            loadPageDepartamento ?
                            (
                                <>
                                    <div className="carregamento"><img alt="" src={Carregamento} /></div>
                                    <div className="carregamento"><img alt="" src={Carregamento} /></div>
                                    <div className="carregamento"><img alt="" src={Carregamento} /></div>
                                    <div className="carregamento4"><img alt="" src={Carregamento} /></div>
                                </>
                            ):
                            departamentos[0] !== undefined ? departamentos
                            .map((departamentosList, index) => (
                            <>
                            <div className='departamento-card' key={index}>

                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" name="editar Departamento" onClick={() => UpdateDepartamento(departamentosList)} >
                                            Editar departamento
                                        </button>
                                        <button type="button" name="Ver departamento" onClick={() => VerDepartamento(departamentosList)} >
                                            Ver mais
                                        </button>

                                        <button type="button" name="excluir" className="red" onClick={() => handleDelete(departamentosList && departamentosList._id)}>
                                            Excluir
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="departamento" onClick={() => UpdateDepartamento(departamentosList)}>
                                    <h3 className="nome">
                                        <span>{departamentosList?.name}</span>
                                    </h3>
                                    <p className="descricao">
                                        {departamentosList?.description}
                                    </p>
                                </div>

                            </div> 
                                    </>
                            )) : (
                                <>
                                </>
                            )}
                    </div>
                    ) : ('')
                    }

                    {
                    listDepartamento ? (
                    <div className="list-departamento-page">
                    <thead>
                    <tr>
                        <th className="nome">
                            <p>Departamento</p>
                        </th>
                        <th>
                            <p>Descrição</p>
                        </th>
                        <th className="th-dopdown-vermais">
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconOrdenation} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button type="button" onClick={() => loadDepartamentosCrescente()} >
                                        Ordem alfabética A
                                    </button>
                                    <button type="button" onClick={() => loadDepartamentosDecrescente()} >
                                        Ordem alfabética Z
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                    loadPageDepartamento ?
                    (
                        <>

                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        </>
                    ):
                    departamentos[0] !== undefined ? departamentos
                    .map((departamentosList, index) => (
                    <>
                            <tr>
                                <td  className="nome" onClick={() => UpdateDepartamento(departamentosList)}>
                                    {departamentosList?.name}
                                    <p>{departamentosList?.description}</p>
                                </td>
                                <td className="descricao" onClick={() => UpdateDepartamento(departamentosList)}><span>{departamentosList?.description?.split('\n').map(str => <p>{str}</p>)}</span></td>
                                <td className="td-dopdown-acoes">
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button type="button" name="editar Departamento"  onClick={() => UpdateDepartamento(departamentosList)}>
                                                Editar departamento
                                            </button>
                                            <button type="button" name="editar"  onClick={() => VerDepartamento(departamentosList)}>
                                                Ver mais
                                            </button>
                                            <button type="button" name="excluir" className="red" onClick={() => handleDelete(departamentosList && departamentosList._id)}>
                                                Excluir
                                            </button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                    </>
                    )) : (
                        <>
                        </>
                    )}


                    </tbody>
                    </div>
                    ) : ('')
                }

                </>
                    ) : (
                        <>
                        <div className="departamento-vazio">
                            <p>Nenhum departamento cadastrado.</p>
                            <button className="btnGreen more" onClick={() => CadastraDepartamento()}>Cadastrar novo departamento</button>
                            <img src={EstadoVazio} alt="Nenhum departamento" />
                        </div>
                        </>
                    )
                }
            </Layout>


            <Modal show={visiblePopupDepart}  restoreFocus={false}  className="popup-geral popup-add-departamento" onHide={CadastraDepartamento}>
                <div>
                    <div className="titulo">
                        <h4>Cadastrar novo departamento  <p className="btn-fechar" onClick={CadastraDepartamento}><img src={Close} alt="Fechar" /></p></h4>
                    </div>


                    <div className="form-novo-departamento">
                        <input 
                            type="text" 
                            name="name"  
                            onKeyPress={(event) => handleKeyPressCadastraDpartamento(event)} 
                            placeholder="Nome do setor" 
                            onChange={myChangeHandler} 
                            className={loadNameCadDepartamento ? 'inputErro' : ''} 
                            autoFocus="true"
                            autocomplete="off"
                            id="nameDepartamento"
                        />
                        {loadNameCadDepartamento? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}
                        <textarea name="description" placeholder="Descrição do setor" onChange={myChangeHandler}autoFocus></textarea>
                    </div>
                    <form onClick={handleSubmit} className="btn-salvar">
                        <button 
                            type="button" 
                            disabled={loadBtnCadDepartamento ? true : false}
                            className={loadBtnCadDepartamento  ? 'button-salve btnGreen load' : 'button-salve btnGreen '}
                        >Salvar novo departamento</button>
                    </form>
                </div>
            </Modal>


            <Modal show={visiblePopupDepartUpdate}  restoreFocus={false} className="popup-geral popup-add-departamento" onHide={UpdateDepartamento}>
                <div>
                    <div className="titulo">
                        <h4>{departamento.name}  <p className="btn-fechar" onClick={UpdateDepartamento}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="form-novo-departamento">

                    {
                        (departmentUpdate && departmentUpdate.name !== undefined) || departmentUpdate.name === '' ?
                        (
                            <input type="text" name="name"
                            onKeyPress={(event) => handleKeyPressAtualizarDpartamento(event)} 
                            id="nameDepartamento"
                             value={(departmentUpdate.name !== undefined) ? departmentUpdate.name : ''}  onChange={myChangeHandlerUpdate} placeholder="Nome do setor"  className={loadNameCadDepartamento ? 'inputErro' : ''}/>
                        ) :
                        departamento.name && departamento.name  && departamento.name !== ''? (
                            <input type="text" name="name"
                            onKeyPress={(event) => handleKeyPressAtualizarDpartamento(event)} 
                            id="nameDepartamento"
                             defaultValue={(departamento.name !== undefined) ? departamento.name  : ''}  onChange={myChangeHandlerUpdate} placeholder="Nome do setor 2" className={loadNameCadDepartamento ? 'inputErro' : ''}/>
                        ):
                        (
                            <input type="text" name="name"
                            onKeyPress={(event) => handleKeyPressAtualizarDpartamento(event)} 
                            id="nameDepartamento"
                             value={(departamento.name !== undefined) ? departamento.name : ''}  onChange={myChangeHandlerUpdate} placeholder="Nome do setor" className={loadNameCadDepartamento ? 'inputErro' : ''} />
                        )}

                        {loadNameCadDepartamento? (
                            <p className="textError">O campo é obrigatório.</p>
                        ) : ('')}


                        {
                            (departmentUpdate && departmentUpdate.description !== undefined) || departmentUpdate.description === '' ?
                        (
                            <textarea name="description" placeholder="Descrição do setor" value={(departmentUpdate.description !== undefined) ? departmentUpdate.description : ''} onChange={myChangeHandlerUpdate} className={loaddescriptionCadDepartamento ? 'inputErro' : ''}></textarea>
                        ) :
                            departamento.description && departamento.description  && departamento.description !== ''? (
                                <textarea name="description" placeholder="Descrição do setor 2" defaultValue={(departamento.description !== undefined) ? departamento.description : ''} onChange={myChangeHandlerUpdate} className={loaddescriptionCadDepartamento ? 'inputErro' : ''}></textarea>
                        ):
                        (
                            <textarea name="description" placeholder="Descrição do setor" value={(departmentUpdate.description !== undefined) ? departmentUpdate.description : ''} onChange={myChangeHandlerUpdate} className={loaddescriptionCadDepartamento ? 'inputErro' : ''}></textarea>
                        )}

                        {loaddescriptionCadDepartamento? (
                            <p className="textError textErrorTextarea">O campo é obrigatório.</p>
                        ) : ('')}
                    </div>

                    <form onSubmit={handleSubmitUpdate} className="btn-salvar">
                        <button 
                            type="submit" 
                            disabled={loadBtnCadDepartamento ? true : false}
                            className={loadBtnCadDepartamento  ? 'button-salve btnGreen load' : 'button-salve btnGreen '}
                        >Salvar departamento</button>
                    </form>
                </div>
            </Modal>


            <Modal show={visiblePopupDepartV} className="popup-geral popup-add-departamento" onHide={VerDepartamento}>
                <div>
                    <div className="titulo">
                        <h4>{departamentoV.name} <p className="btn-fechar" onClick={VerDepartamento}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="infor-departamento">
                    {departamentoV && departamentoV.description && departamentoV.description.split('\n').map(str => <p>{str}</p>)}
                    </div>
                    <div className="btn-salvar">
                        <button 
                            onClick={() => handleDelete(departamentoV && departamentoV._id)} 
                            disabled={loadBtnCadDepartamento ? true : false}
                            className={loadBtnCadDepartamento  ? 'button-salve btnRed load' : 'button-salve btnRed '}
                        >Deletar departamento</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}

