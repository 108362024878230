import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import { mCNPJ, mNumber, dateMask } from '../../../services/mask';
import swal from 'sweetalert';
import SelectPersonalizado from '../../../components/Reused/selectPersonalizado';
import { toast } from 'react-toastify';

export default function headerBenefitEdite({ id, history, path }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDiscountPercentage, setloadDiscountPercentage] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadName, setLoadName ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCompanyCostIsPaid, setLoadCompanyCostIsPaid] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCnpjOperator, setLoadCnpjOperator] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredBeneftis, setRegisteredBeneftis] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCategorySavedId] = useState([]);
    // categorySavedId

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadbenefits(id);

    }, [id]);

    async function loadbenefits(id) {

        await api.get(`/beneftis/${id}`)
        .then(response => {

            setRegisteredBeneftis(response.data);
            setCategorySavedId(response.data.category)

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredBeneftisUpdate, setRegisteredBeneftisUpdate] = useState([]);

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;
        if(nam === 'absencesInThePreviousMonth') {
            if(event.target.checked === true) {
                let absencesInThePreviousMonth = true;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, absencesInThePreviousMonth, path: path })
            } else {
                let absencesInThePreviousMonth = false;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, absencesInThePreviousMonth, path: path })
            }
        }
        else
        if(nam === 'vacationNextMonth') {
            if(event.target.checked === true) {
                let vacationNextMonth = true;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, vacationNextMonth, path: path })
            } else {
                let vacationNextMonth = false;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, vacationNextMonth, path: path })
            }
        }
        else
        if(nam === 'absencesInThePreviousMonth2') {
            if(event.target.checked === true) {
                let absencesInThePreviousMonth2 = true;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, absencesInThePreviousMonth2, path: path })
            } else {
                let absencesInThePreviousMonth2 = false;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, absencesInThePreviousMonth2, path: path })
            }
        }
        else
        if(nam === 'licensesInTheFollowingMonth') {
            if(event.target.checked === true) {
                let licensesInTheFollowingMonth = true;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, licensesInTheFollowingMonth, path: path })
            } else {
                let licensesInTheFollowingMonth = false;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, licensesInTheFollowingMonth, path: path })
            }
        }
        else if(nam === "companyCostIsPaid"){
            if(val === "Sim") {
                let valSim = true;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: valSim, path: path  })
            } else {
                let valNao = false;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: valNao, path: path  })
            }
            
        }
        else
        if(nam === 'vacationInCurrentMonth') {
            if(event.target.checked === true) {
                let vacationInCurrentMonth = true;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, vacationInCurrentMonth, path: path })
            } else {
                let vacationInCurrentMonth = false;
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, vacationInCurrentMonth, path: path })
            }
        }
        else
        if (nam === "cnpjOperator") {
            let cnpjOperator = mCNPJ(val)
            setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: cnpjOperator, path: path })
        }
        else
        if (nam === "contractExpirationDate"){

            let contractExpirationDate = dateMask(val)
            setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: contractExpirationDate, path: path })

        }
        else

        if (nam === "category") {
            let category = val;
            let operator = undefined;
            setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate,  operator, category, path: path })

        }
        else
        if (nam === "cutoffDate"){

            let cutoffDate = mNumber(val)
            setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: cutoffDate, path: path })

        }
        else
        //benefitDiscount
        if (nam === "benefitDiscount"){

            if(val === 'true') {

                let salaryDiscount = 'false';

                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: val, salaryDiscount, path: path });

            }
            else {

                let salaryDiscount = 'true';
                setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: val, salaryDiscount, path: path });

            }

        }
        else {
            setRegisteredBeneftisUpdate({ ...registeredBeneftisUpdate, [nam]: val, path: path })
        }



    };

    async function handleSubmit(event) {

        event.preventDefault();

        if (
            registeredBeneftis.name === undefined ||
            registeredBeneftisUpdate.name === ''
        ) {
            setLoadName(true)
        }

        if (
            registeredBeneftis.cnpjOperator === undefined ||
            registeredBeneftisUpdate.cnpjOperator === ''
        ) {
            setLoadCnpjOperator(true)
        }

        if (
            registeredBeneftis.discountPercentage === undefined ||
            registeredBeneftisUpdate.discountPercentage === ''
        ) {
            setloadDiscountPercentage(true)
        }

        if (
            registeredBeneftis.companyCostIsPaid === undefined ||
            registeredBeneftisUpdate.companyCostIsPaid === ''
        ) {
            setLoadCompanyCostIsPaid(true)
        }
        if(
            registeredBeneftis.name === undefined ||
            registeredBeneftisUpdate.name === '' ||
            registeredBeneftis.cnpjOperator === undefined ||
            registeredBeneftisUpdate.cnpjOperator ===  '' ||
            registeredBeneftis.discountPercentage === undefined ||
            registeredBeneftisUpdate.discountPercentage ===  '' ||
            registeredBeneftis.companyCostIsPaid === undefined ||
            registeredBeneftisUpdate.companyCostIsPaid ===  ''
        ) {
            return
        }

        setLoad(true)
        await api.put(`/beneftis/${registeredBeneftis._id}`, registeredBeneftisUpdate)
        .then(response => {
            async function loadabsences() {
                await api.get(`/beneftis/${registeredBeneftis._id}`)
                .then(response => {
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                })
            }
            loadabsences();
            setLoad(false)
            setTimeout(function(){history.goBack();}, 2000);
            swal({ icon: "success", title: "Sucesso!", text: "Benefício atualizado com sucesso!"});
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            setLoad(false)
        });

    }

    const categoryBenefit = [
        'Alimentação',
        'Alimentação e Refeição',
        'Assistência à Educação',
        'Auxílio home-office',
        'Bem-estar',
        'Combustível',
        'Convênio Farmácia',
        'Clube de Benefícios',
        'Cultura',
        'Day Off',
        'Estacionamento',
        'Licença maternidade',
        'Licença Paternidade estendida',
        'Mobilidade',
        'Outros',
        'Plano de Saúde',
        'Plano Odontológico',
        'Plano de Saúde Pet',
        'Previdência Privada',
        'Premiação',
        'Refeição',
        'Seguro de Vida',
        'Transporte'

    ]
    // registeredBeneftis.category


    const operadorBenefit =  registeredBeneftisUpdate && registeredBeneftisUpdate.category ?
        registeredBeneftisUpdate?.category === "Alimentação" ||
        registeredBeneftisUpdate.category === "Refeição" ||
        registeredBeneftisUpdate.category === "Alimentação e Refeição" ||
        registeredBeneftisUpdate.category === "Transporte" ||
        registeredBeneftisUpdate.category === "Mobilidade" ||
        registeredBeneftisUpdate.category === "Combustível"  ? [
            "Sodexo",
            "Ticket",
            "Alelo",
            "Flash",
            "VR",
            "Swile"
            ]
        : registeredBeneftisUpdate && registeredBeneftisUpdate.category === "Plano de Saúde" ? [
            "Unimed",
            "Clinipam",
            "Bradesco Seguros",
            "Paraná Clínicas",
            "Sul América",
            "Amil",
            "Nossa Saúde",
            "MedSul"
            ]
        : registeredBeneftisUpdate && registeredBeneftisUpdate.category === "Plano Odontológico" ? [
            "Dental Uni",
            "Amil Dental",
            "Sul América Odonto",
            "Porto Seguro",
            "Bradesco Seguro Dental",
            "Dentalprev"
            ]
        : registeredBeneftisUpdate && registeredBeneftisUpdate.category === "Seguro de Vida" ? [
            "Prudential",
            "Porto Seguro",
            "Metlife",
            "Sul América",
            "Santander",
            "Bradesco Seguros"
            ]
            
        : registeredBeneftisUpdate && registeredBeneftisUpdate.category === "Bem-estar" ?  [
            "Zenklub",
            "Keiken",
            "Gympass",
            "Total Pass"
        ] : []
    :
        registeredBeneftis?.category === "Alimentação" ||
        registeredBeneftis.category === "Refeição" ||
        registeredBeneftis.category === "Alimentação e Refeição" ||
        registeredBeneftis.category === "Transporte" ||
        registeredBeneftis.category === "Mobilidade" ||
        registeredBeneftis.category === "Combustível"  ? [
            "Sodexo",
            "Ticket",
            "Alelo",
            "Flash",
            "VR",
            "Swile"
            ]
        : registeredBeneftis && registeredBeneftis.category === "Plano de Saúde" ? [
            "Unimed",
            "Clinipam",
            "Bradesco Seguros",
            "Paraná Clínicas",
            "Sul América",
            "Amil",
            "Nossa Saúde",
            "MedSul"
            ]
        : registeredBeneftis && registeredBeneftis.category === "Plano Odontológico" ? [
            "Dental Uni",
            "Amil Dental",
            "Sul América Odonto",
            "Porto Seguro",
            "Bradesco Seguro Dental",
            "Dentalprev"
            ]
        : registeredBeneftis && registeredBeneftis.category === "Seguro de Vida" ? [
            "Prudential",
            "Porto Seguro",
            "Metlife",
            "Sul América",
            "Santander",
            "Bradesco Seguros"
            ]
            
        : registeredBeneftis && registeredBeneftis.category === "Bem-estar" ?  [
            "Zenklub",
            "Keiken",
            "Gympass",
            "Total Pass"
        ] : []  
    ;

    return (
        <>
        <div className='page-cad-benefit'>
            <div className="register-benefit">

                <ul className="form-register">
                    <li>
                        <div className="intemA">
                                <p>Nome *</p>
                        </div>
                        <div className="intemB">


                            <input type="text" name="name"
                            className={loadName ? 'inputErro' : ''}
                            autoComplete="autocompleteoff"
                            value={
                                registeredBeneftisUpdate && registeredBeneftisUpdate.name ?
                                registeredBeneftisUpdate.name :
                                registeredBeneftisUpdate && registeredBeneftisUpdate.name === '' ?
                                '' :
                                registeredBeneftis && registeredBeneftis.name ?
                                registeredBeneftis.name :
                                ''
                            } placeholder="Exemplo: Vale refeição" onChange={myChangeHandler}/>
                            {loadName ? <><p className="textError">O campo é obrigatório.</p></> : ''}

                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Categoria *</p>
                        </div>
                        <div className="intemB">
                                 <SelectPersonalizado
                                    valorCampo={
                                        registeredBeneftisUpdate && registeredBeneftisUpdate.category ?
                                        registeredBeneftisUpdate.category :
                                        registeredBeneftisUpdate && registeredBeneftisUpdate.category === '' ?
                                        '' :
                                        registeredBeneftis && registeredBeneftis.category ?
                                        registeredBeneftis.category :
                                        ''

                                    }
                                    nameButton={"category"}
                                    myChangeHandler={myChangeHandler}
                                    optionFil={categoryBenefit}
                                    typeSelect="busca2"
                                />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>CNPJ do operador *</p>
                        </div>
                        <div className=" intemB">

                        {
                            registeredBeneftisUpdate &&
                            registeredBeneftisUpdate.cnpjOperator !== undefined ?
                            (<input type="text" name="cnpjOperator" autoComplete="autocompleteoff"maxLength="18"  value={registeredBeneftisUpdate.cnpjOperator} onChange={myChangeHandler} placeholder="CNPJ do operador"  className={loadCnpjOperator ? 'inputErro' : ''}/>) :
                            registeredBeneftis && registeredBeneftis.cnpjOperator !== undefined ?
                            (<input type="text" name="cnpjOperator" autoComplete="autocompleteoff"maxLength="18"  value={registeredBeneftis.cnpjOperator} onChange={myChangeHandler} placeholder="CNPJ do operador"  className={loadCnpjOperator ? 'inputErro' : ''} />) :
                            (<input type="text" name="cnpjOperator" autoComplete="autocompleteoff"maxLength="18"  onChange={myChangeHandler} placeholder="CNPJ do operador" className={loadCnpjOperator ? 'inputErro' : ''}/>)
                        }
                        {loadCnpjOperator ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>
                    </li>

                    {           (registeredBeneftisUpdate && registeredBeneftis.category === "Alimentação") ||
                                registeredBeneftis.category === "Refeição" ||
                                registeredBeneftis.category === "Alimentação e Refeição" ||
                                registeredBeneftis.category === "Transporte" ||
                                registeredBeneftis.category === "Mobilidade" ||
                                registeredBeneftis.category === "Plano de Saúde"||
                                registeredBeneftis.category === "Plano Odontológico" ||
                                registeredBeneftis.category === "Seguro de Vida"  ||
                                registeredBeneftis.category === "Bem-estar" ||
                                registeredBeneftis.category === "Combustível"||
                                registeredBeneftisUpdate.category === "Alimentação" ||
                                registeredBeneftisUpdate.category === "Refeição" ||
                                registeredBeneftisUpdate.category === "Alimentação e Refeição" ||
                                registeredBeneftisUpdate.category === "Transporte" ||
                                registeredBeneftisUpdate.category === "Mobilidade" ||
                                registeredBeneftisUpdate.category === "Plano de Saúde"||
                                registeredBeneftisUpdate.category === "Plano Odontológico" ||
                                registeredBeneftisUpdate.category === "Seguro de Vida"  ||
                                registeredBeneftisUpdate.category === "Bem-estar" ||
                                registeredBeneftisUpdate.category === "Combustível"  ? (
                    <li>
                        <div className="intemA">
                                <p>Operador</p>
                        </div>
                        <div className="intemB">


                        <SelectPersonalizado
                                valorCampo={
                                    (registeredBeneftisUpdate                           &&
                                    registeredBeneftisUpdate.operator === '') ||
                                    registeredBeneftisUpdate.operator         ?
                                    registeredBeneftisUpdate.operator         :
                                    registeredBeneftis &&
                                    registeredBeneftis.operator ?
                                    registeredBeneftis.operator :
                                    ''
                                }
                                nameButton={"operator"}
                                myChangeHandler={myChangeHandler}
                                option={ operadorBenefit }
                            />
                        </div>
                    </li>) :('')}

                </ul>
                <ul className="form-register form-registerDois">
                    <li className="check-li">
                        <div className="intemA">
                            <p>Porcentagem de desconto *</p>
                        </div>
                        <div className="intemB">
                            <input
                            type="text"
                            name="discountPercentage"
                            autoComplete="autocompleteoff"
                            value={
                                (registeredBeneftisUpdate                           &&
                                registeredBeneftisUpdate.discountPercentage === '') ||
                                registeredBeneftisUpdate.discountPercentage         ?
                                registeredBeneftisUpdate.discountPercentage         :
                                registeredBeneftis &&
                                registeredBeneftis.discountPercentage ?
                                registeredBeneftis.discountPercentage :
                                ''
                            }
                            onChange={myChangeHandler}
                            className={loadDiscountPercentage ? 'inputErro' : ''}
                            placeholder="Digite a porcentagem a ser descontada"
                            />
                            {loadDiscountPercentage ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>
                    </li>
                    {
                        registeredBeneftis                               &&
                        registeredBeneftis.benefitDiscount !== undefined &&
                        registeredBeneftis.benefitDiscount === true     ?
                        <>
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <div className="radioNovo">
                                        <input type="radio" name="benefitDiscount" value="true" onChange={myChangeHandler} id="DescontoBeneficios"  defaultChecked="checked"/>
                                        <label htmlFor="DescontoBeneficios" className="foreignTexto">Desconto do benefício</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <div className="radioNovo">
                                        <input type="radio" name="benefitDiscount" value="false" id="DescontoSalario" onChange={myChangeHandler}  />
                                        <label htmlFor="DescontoSalario" className="foreignTexto">Desconto do Salario</label>
                                    </div>
                                </div>
                            </li>
                        </>
                        :
                        registeredBeneftis                               &&
                        registeredBeneftis.benefitDiscount !== undefined &&
                        registeredBeneftis.benefitDiscount === false     ?
                        <>
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <div className="radioNovo">
                                        <input type="radio" name="benefitDiscount" value="true" onChange={myChangeHandler} id="DescontoBeneficios" />
                                        <label htmlFor="DescontoBeneficios" className="foreignTexto">Desconto do benefício</label>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <div className="radioNovo">
                                        <input type="radio" name="benefitDiscount" value="false" id="DescontoSalario" onChange={myChangeHandler} defaultChecked="checked" />
                                        <label htmlFor="DescontoSalario" className="foreignTexto">Desconto do Salario</label>
                                    </div>
                                </div>
                            </li>
                        </>
                        :
                        <>

                        </>
                    }


                </ul>
                <ul className="form-register form-registerDois">
                    {/* <li className="check-li">
                        <div className="intemA">
                                <p>Descontar</p>
                        </div>
                        <div className="intemB ">
                            <div className="chekbox">
                            {
                            registeredBeneftisUpdate &&
                            registeredBeneftisUpdate.absencesInThePreviousMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="absencesInThePreviousMonth" onChange={myChangeHandler} checked={registeredBeneftisUpdate && registeredBeneftisUpdate.absencesInThePreviousMonth === true ? true : false}/>
                                <label className="foreignTexto">Faltas no mês anterior</label>
                                </>
                            ) :
                            registeredBeneftis && registeredBeneftis.absencesInThePreviousMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="absencesInThePreviousMonth" onChange={myChangeHandler} checked={registeredBeneftis && registeredBeneftis.absencesInThePreviousMonth === true ? true : false}/>
                                <label className="foreignTexto">Faltas no mês anterior</label>
                                </>
                            ) :
                            (
                                <>
                                <input type="checkbox" name="absencesInThePreviousMonth" onChange={myChangeHandler} checked={false}/>
                                <label className="foreignTexto">Faltas no mês anterior</label>
                                </>
                            )
                            }
                            </div>
                            <div className="chekbox">
                            {
                            registeredBeneftisUpdate &&
                            registeredBeneftisUpdate.vacationNextMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="vacationNextMonth" onChange={myChangeHandler} checked={registeredBeneftisUpdate && registeredBeneftisUpdate.vacationNextMonth === true ? true : false}/>
                                <label className="foreignTexto">Férias no mês seguinte</label>
                                </>
                            ) :
                            registeredBeneftis && registeredBeneftis.vacationNextMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="vacationNextMonth" onChange={myChangeHandler} checked={registeredBeneftis && registeredBeneftis.vacationNextMonth === true ? true : false}/>
                                <label className="foreignTexto">Férias no mês seguinte</label>
                                </>
                            ) :
                            (
                                <>
                                <input type="checkbox" name="vacationNextMonth" onChange={myChangeHandler} checked={false}/>
                                <label className="foreignTexto">Férias no mês seguinte</label>
                                </>
                            )
                            }
                            </div>
                            <div className="chekbox">
                            {
                            registeredBeneftisUpdate &&
                            registeredBeneftisUpdate.absencesInThePreviousMonth2 !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="absencesInThePreviousMonth2" onChange={myChangeHandler} checked={registeredBeneftisUpdate && registeredBeneftisUpdate.absencesInThePreviousMonth2 === true ? true : false}/>
                                <label className="foreignTexto">Afastamentos no mês anterior</label>
                                </>
                            ) :
                            registeredBeneftis && registeredBeneftis.absencesInThePreviousMonth2 !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="absencesInThePreviousMonth2" onChange={myChangeHandler} checked={registeredBeneftis && registeredBeneftis.absencesInThePreviousMonth2 === true ? true : false}/>
                                <label className="foreignTexto">Afastamentos no mês anterior</label>
                                </>
                            ) :
                            (
                                <>
                                <input type="checkbox" name="absencesInThePreviousMonth2" onChange={myChangeHandler} checked={false}/>
                                <label className="foreignTexto">Afastamentos no mês anterior</label>
                                </>
                            )
                            }
                            </div>
                            <div className="chekbox">
                            {
                            registeredBeneftisUpdate &&
                            registeredBeneftisUpdate.licensesInTheFollowingMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="licensesInTheFollowingMonth" onChange={myChangeHandler} checked={registeredBeneftisUpdate && registeredBeneftisUpdate.licensesInTheFollowingMonth === true ? true : false}/>
                                <label className="foreignTexto">Licenças no mês seguinte</label>
                                </>
                            ) :
                            registeredBeneftis && registeredBeneftis.licensesInTheFollowingMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="licensesInTheFollowingMonth" onChange={myChangeHandler} checked={registeredBeneftis && registeredBeneftis.licensesInTheFollowingMonth === true ? true : false}/>
                                <label className="foreignTexto">Licenças no mês seguinte</label>
                                </>
                            ) :
                            (
                                <>
                                <input type="checkbox" name="licensesInTheFollowingMonth" onChange={myChangeHandler} checked={false}/>
                                <label className="foreignTexto">Licenças no mês seguinte</label>
                                </>
                            )
                            }
                            </div>
                            <div className="chekbox">
                            {
                            registeredBeneftisUpdate &&
                            registeredBeneftisUpdate.vacationInCurrentMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="vacationInCurrentMonth" onChange={myChangeHandler} checked={registeredBeneftisUpdate && registeredBeneftisUpdate.vacationInCurrentMonth === true ? true : false}/>
                                <label className="foreignTexto">Férias no mês corrente</label>
                                </>
                            ) :
                            registeredBeneftis && registeredBeneftis.vacationInCurrentMonth !== undefined ?
                            (
                                <>
                                <input type="checkbox" name="vacationInCurrentMonth" onChange={myChangeHandler} checked={registeredBeneftis && registeredBeneftis.vacationInCurrentMonth === true ? true : false}/>
                                <label className="foreignTexto">Férias no mês corrente</label>
                                </>
                            ) :
                            (
                                <>
                                <input type="checkbox" name="vacationInCurrentMonth" onChange={myChangeHandler} checked={false}/>
                                <label className="foreignTexto">Férias no mês corrente</label>
                                </>
                            )
                            }
                            </div>
                        </div>
                    </li> */}
                    <li>
                        <div className="intemA">
                                <p>Tipo de cálculo</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    registeredBeneftisUpdate && registeredBeneftisUpdate.balanceCalculation ?
                                    registeredBeneftisUpdate.balanceCalculation :
                                    registeredBeneftisUpdate && registeredBeneftisUpdate.balanceCalculation === '' ?
                                    '' :
                                    registeredBeneftis && registeredBeneftis.balanceCalculation ?
                                    registeredBeneftis.balanceCalculation :
                                    ''
                                }
                                nameButton={"balanceCalculation"}
                                myChangeHandler={myChangeHandler}
                                option={ [
                                    "Fixo mensal",
                                    "Dias úteis"
                                ] }
                            />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>Custo pago na folha</p>
                        </div>
                        <div className="intemB">
                            <SelectPersonalizado
                                valorCampo={
                                    registeredBeneftisUpdate && registeredBeneftisUpdate.companyCostIsPaid !== undefined ?
                                            registeredBeneftisUpdate.companyCostIsPaid === true ? 'Sim' : 'Não'
                                        :
                                            registeredBeneftis && registeredBeneftis.companyCostIsPaid === true ? 'Sim' : 'Não'
                                }
                                nameButton={"companyCostIsPaid"}
                                myChangeHandler={myChangeHandler}
                                option={ [
                                    "Sim",
                                    "Não"
                                ] }
                                loadError={loadCompanyCostIsPaid}
                            />



                            {loadCompanyCostIsPaid ? <><p className="textError">O campo é obrigatório.</p></> : ''}
                        </div>

                        <div className="intemC">
                            <div className="tooltips">
                                <div className="tooltips-icon">
                                    ?
                                </div>

                                <div className="tooltips-infor">
                                    <p>
                                    Alguns benefícios podem ser pagos na folha (auxílio educação ou auxílio creche, por exemplo). Nestes casos, ao invés de pagar um fornecedor, o colaborador receberá em dinheiro. Ao marcar este campo, o valor será lançado na folha de pagamento dos colaboradores inscritos automaticamente.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>Data de corte</p>
                        </div>
                        <div className="intemB">
                            <input
                            type="text"
                            name="cutoffDate"
                            autoComplete="off"
                            value={
                                (registeredBeneftisUpdate                   &&
                                registeredBeneftisUpdate.cutoffDate === '') ||
                                registeredBeneftisUpdate.cutoffDate         ?
                                registeredBeneftisUpdate.cutoffDate         :
                                registeredBeneftis                         &&
                                registeredBeneftis.cutoffDate               ?
                                registeredBeneftis.cutoffDate               :
                                ''
                            }
                            onChange={myChangeHandler}
                            placeholder='Data de corte'
                            />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                                <p>Data de vencimento</p>
                        </div>
                        <div className="intemB">
                            {
                                registeredBeneftisUpdate &&
                                registeredBeneftisUpdate.contractExpirationDate !== undefined ?
                                (
                                    <input type="text" maxLength="10" name="contractExpirationDate" autoComplete="off" value={registeredBeneftisUpdate.contractExpirationDate} onChange={myChangeHandler} />
                                ) :
                                registeredBeneftis && registeredBeneftis.contractExpirationDate !== undefined ?
                                (
                                    <input type="text" name="contractExpirationDate" autoComplete="off" value={registeredBeneftis.contractExpirationDate} onChange={myChangeHandler} />
                                ) :
                                (
                                    <input type="text" maxLength="10" name="contractExpirationDate" autoComplete="off" onChange={myChangeHandler} placeholder="Digite o dia do mês"/>
                                )
                            }
                        </div>
                    </li>
                    <li className="check-li">
                        <div className="intemA">
                                <p>Descrição</p>
                        </div>
                        <div className="intemB">
                            <textarea name="description" defaultValue={registeredBeneftis.description } onChange={myChangeHandler} cols="150" placeholder="Justificativa"></textarea>
                        </div>
                    </li>

                </ul>
            </div>

        </div>


        <div className="btns-cadastra-benefit">
            <form onSubmit={handleSubmit} className="cadastra">
                <button type="submit" className={load ? "btnGreen load" : "btnGreen"}>Salvar</button>
            </form>
            <a href="/beneficios" className="btnGray cancelar">Cancelar</a>

        </div>



        </>
    );
}

