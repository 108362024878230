import React from 'react';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../../../../assets/images/more.svg';
import Carregamento from '../../../../../../assets/images/carregando.gif';
import IllustrationVacations from '../../../../../../assets/images/illustration-vacations.svg';
import { dateFormatedToStripe  } from '../../../../../../services/utils';
export default function ColProfileVacationHistory({
    loadVacationHistory,
    collaboratorvacationHistory,
    deleteVacationHistory,
    role,
    openModalAddVacation,
}) {

    return (
        <>

        <div className="box VacationHistory">
            <h3>Histórico de férias
                {
                    localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                    ""
                    :
                    (role && role === 'admin') || (role && role === 'master') ? 
                    <button className="btnGreen more adicionar-retroativa"  onClick={openModalAddVacation}>Adicionar férias</button> 
                    : ''
            }
            </h3>
            {
                loadVacationHistory ?
                <div className="carregamento">
                    <img alt="" src={Carregamento} />
                </div>
                :
                collaboratorvacationHistory.length === 0 ?
                <div className="nenhum-cad-perfil"><div className="box-img"><img src={IllustrationVacations} alt="Não possui férias" /><p>Não possui férias</p></div></div>
                :
                collaboratorvacationHistory && collaboratorvacationHistory.length !== 0 ?
                collaboratorvacationHistory.map((vacationhistory, index) => (
                    <>
                    <div key={index} className="ferias">
                        <h4>


                        {
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                            ""
                            :
                            (role && role === 'admin') || (role && role === 'master') ?
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button type="button" name="excluir" className="red" onClick={() => deleteVacationHistory(vacationhistory?.vacationHistory)}>
                                        Excluir
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            ''
                        }
                        Férias de ano referência {vacationhistory?.vacationHistory?.vestingPeriodReferenceYear} - {(Number(vacationhistory?.vacationHistory?.vestingPeriodReferenceYear +1))} <span>Período aquisitivo {dateFormatedToStripe(vacationhistory?.startDateVestingPeriod)} até {dateFormatedToStripe(vacationhistory?.endDateVestingPeriod)}</span>

                        </h4>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Data de início</p>
                                </div>
                                <div className="intemB">
                                    <p>{dateFormatedToStripe(vacationhistory?.vacationHistory?.startDate)}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Data de término</p>
                                </div>
                                <div className="intemB">
                                    <p>{dateFormatedToStripe(vacationhistory?.vacationHistory?.endDate)}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Dias de férias</p>
                                </div>
                                <div className="intemB">
                                    <p>{vacationhistory?.vacationHistory?.vacationDays}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Dias de abono</p>
                                </div>
                                <div className="intemB">
                                    <p>{vacationhistory?.vacationHistory?.vacationDaysSold}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Total</p>
                                </div>
                                <div className="intemB">
                                    <p>{(vacationhistory?.vacationHistory?.vacationDays + vacationhistory?.vacationHistory?.vacationDaysSold)}</p>
                                </div>
                            </li>
                            {
                                vacationhistory?.vacationHistory?.comments ?
                                <li>
                                    <div className="intemA">
                                        <p>Observações</p>
                                    </div>
                                    <div className="intemB">
                                        {
                                            vacationhistory?.vacationHistory?.comments ?
                                            vacationhistory?.vacationHistory?.comments.split('\n').map(str => <p>{str}</p>)
                                            :
                                            '-'
                                        }
                                    </div>
                                </li>
                                :
                                ''
                            }
                            <li>
                                <div className="intemA">
                                    <p>Status</p>
                                </div>
                                <div className="intemB">
                                    <p>{vacationhistory?.vacationHistory?.status}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    </>
                ))
                :
                ''
            }
        </div>
    </>
    );
}
