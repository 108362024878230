import React, {
    useState, 
   //  useEffect 
} from 'react';
import { Dropdown } from "react-bootstrap";
import iconOrdenation from '../../../assets/images/ordenation.svg';
import iconVerMais from '../../../assets/images/more.svg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import FotoExemploAlvaro from '../../../assets/images/alvarobraz.png';
import FotoExemploCarol from '../../../assets/images/carolina_martini.jpg';
import Close from '../../../assets/images/close.svg';
import { Modal } from "react-bootstrap";
import Pagination from '../../Reused/Pagination'

import { NavLink } from 'react-router-dom'

export default function tableOneToOne(props) {

    ///////// SETS paginacao

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages,  ] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ limit,  ] = useState(1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pagesTotalItem,  ] = useState(0);

   
   ///////// SETS E FUCTION DO HISTORICO DE FEEDBACK
    
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleModalHistoryOneToOne, setVisibleModalHistoryOneToOne] = useState(false);
   async function modalHistoryOneToOne() {
       setVisibleModalHistoryOneToOne(!visibleModalHistoryOneToOne);
   }



       return (
           <>
           <div className='general-table oneToOne-table'>
               <div className='header-table '>
                   <div className='status'>
                       <p>Status</p>
                   </div>
                   <div className='data'>
                       <p>Data e horário</p>
                   </div>
                   <div>
                       <p>Colaborador</p>
                   </div>
                   <div className='view-more'>
                               <Dropdown  className="dropdown-icon">
                                   <Dropdown.Toggle>
                                   <img alt="" src={iconOrdenation} />
                                   </Dropdown.Toggle>
                                   <Dropdown.Menu>
                                       <button type="button" >
                                       Ordem alfabética
                                       </button>
                                       <button type="button"
                                       >
                                       Mais recentes primeiro
                                       </button>
                                       <button type="button" >
                                       Mais antigos primeiro
                                       </button>
                                       <button  type="button" >
                                       Por número de habilidades
                                       </button>
                                       <button  type="button" >
                                       Por gestor
                                       </button>
                                   </Dropdown.Menu>
                               </Dropdown>

                   </div>

               </div>
               <div className='body-table'> 
                   <div className="item">
                       <NavLink to="/gestao/one-to-one/:id" className='status cancelado'>
                           <p >Cancelado</p>
                       </NavLink>  
                       <NavLink to="/gestao/one-to-one/:id"  className='data'>
                           <p>07 Dez 2021 às 9:20 <span className='status cancelado'>Cancelado</span></p>
                       </NavLink>  
                       <NavLink to="/gestao/one-to-one/:id" className="collaborador">
                           <div className="semImgCol">
                               <img  src={IconUserNone} alt="Usuario sem imagem" />
                           </div>
                           <div className='infor'>
                               <strong>Alan Fellipe Pfaffenzeller Emmerich</strong>
                               <p>Analista de Conteúdo Jr 2</p>
                           </div>
                       </NavLink>  
                       
                       <div className='view-more'>
                           <Dropdown  className="dropdown-icon">
                               <Dropdown.Toggle>
                               <img alt="" src={iconVerMais} />
                               </Dropdown.Toggle>
                               <Dropdown.Menu>
                                   <button type="button" >
                                   Editar agendamento
                                   </button>
                                   <button type="button" onClick={modalHistoryOneToOne} >
                                   Histórico deste colaborador
                                   </button>
                                   <button type="button" className='red' >
                                   Excluir agendamento
                                   </button>
                               </Dropdown.Menu>
                           </Dropdown>

                       </div>
                       
                   </div>
                   <div className="item">
                       <NavLink to="/gestao/one-to-one/:id" className='status agendado'>
                           <p >Agendado</p>
                       </NavLink>  
                       <NavLink to="/gestao/one-to-one/:id" className='data'>
                           <p >07 Dez 2021 às 9:20 <span className='status agendado'>Agendado</span></p>
                       </NavLink>  
                       <NavLink to="/gestao/one-to-one/:id" className="collaborador">
                           <div className="foto">
                               <img alt="" src={FotoExemploAlvaro}/>
                           </div>
                           <div className='infor'>
                               
                               <strong>Alvaros braz do nordeste</strong>
                               <p>Analista de Conteúdo Jr 2</p>
                           </div>
                       </NavLink>  
                       
                       <div className='view-more'>
                           <Dropdown  className="dropdown-icon">
                               <Dropdown.Toggle>
                               <img alt="" src={iconVerMais} />
                               </Dropdown.Toggle>
                               <Dropdown.Menu>
                                   <button type="button" >
                                   Editar agendamento
                                   </button>
                                   <button type="button" onClick={modalHistoryOneToOne} >
                                   Histórico deste colaborador
                                   </button>
                                   <button type="button" className='red' >
                                   Excluir agendamento
                                   </button>
                               </Dropdown.Menu>
                           </Dropdown>

                       </div>
                       
                   </div>
                   <div className="item">
                       <NavLink to="/gestao/one-to-one/:id"  className='status realizado'>
                           <p>Realizado </p>
                       </NavLink>  
                       <NavLink to="/gestao/one-to-one/:id" className='data' >
                           <p >07 Dez 2021 às 9:20 <span className='status realizado'>Realizado</span></p>
                       </NavLink>  
                       <NavLink to="/gestao/one-to-one/:id" className="collaborador">
                           <div className="foto">
                               <img alt="" src={FotoExemploCarol}/>
                           </div>
                           <div className='infor'>
                               
                               <strong>Bárbara Tebaldi Franco</strong>
                               <p>Atendimento Pleno 1</p>
                           </div>
                       </NavLink>  
                       
                       <div className='view-more'>
                           <Dropdown  className="dropdown-icon">
                               <Dropdown.Toggle>
                               <img alt="" src={iconVerMais} />
                               </Dropdown.Toggle>
                               <Dropdown.Menu>
                                   <button type="button" >
                                   Editar agendamento
                                   </button>
                                   <button type="button" onClick={modalHistoryOneToOne} >
                                   Histórico deste colaborador
                                   </button>
                                   <button type="button" className='red' >
                                   Excluir agendamento
                                   </button>
                               </Dropdown.Menu>
                           </Dropdown>

                       </div>
                       
                   </div>

               </div>
                    <Pagination
                        pages={pages}
                        setPage={setPage}
                        page={page}
                        limit={limit}
                        pagesTotalItem={pagesTotalItem}
                    /> 
           </div>   




           <Modal show={visibleModalHistoryOneToOne} className="popup-geral popup-history-feedback" onHide={modalHistoryOneToOne}>
               <div>
                   <div className="titulo">
                       <h4>Histórico de 1:1s<p className="btn-fechar" onClick={modalHistoryOneToOne}><img src={Close} alt="Fechar" /></p></h4>
                   </div>
                   <div className='infor-list'>
                       <div className='collaborador' onClick={modalHistoryOneToOne}>

                           {/*
                               ESSE E O HTML PARA COLABORADOR SEM FOTO 
                               ↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘

                               <span className="colSemFoto">
                                   <span className="semImgCol"    >
                                       <img    src={IconUserNone} alt="Usuario sem imagem" />
                                   </span>
                               </span> 

                           */}

                           <span className="foto"  >
                               <img  src={FotoExemploCarol} alt="nome" />
                           </span>
                           

                           <div className='infor'  >
                               <strong>Amanda Carolina Peres Mancio</strong>
                               <p>Amanda Carolina Peres Mancio</p>
                           </div>
                       </div>
                       <ul className='list'>
                           <li>
                               <div className='collaborador'>

                                       {/*
                                           ESSE E O HTML PARA COLABORADOR SEM FOTO 
                                           ↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘

                                           <span className="colSemFoto">
                                               <span className="semImgCol"    >
                                                   <img    src={IconUserNone} alt="Usuario sem imagem" />
                                               </span>
                                           </span> 

                                       */}

                                       <span className="foto"  >
                                           <img  src={FotoExemploCarol} alt="nome" />
                                       </span>


                                       <div className='infor'  >
                                           <strong>Carolina Martini</strong>
                                           <p>Gestor</p>
                                       </div>

                                       <NavLink to="/gestao/one-to-one/:id" className='visualizar'>Visualizar</NavLink>  
                               </div>
                               <div className='infor-status'>
                                       <p>Status <span className='realizado'>Realizado</span></p>
                                       <p>Data e horário<span className='data'>20 Dez 2021 às 10:30</span></p>
                                       <p>Onde<span className='onde'>Google Meet</span></p>
                               </div>
                           </li>
                           <li>
                               <div className='collaborador'>

                                       {/*
                                           ESSE E O HTML PARA COLABORADOR SEM FOTO 
                                           ↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘

                                           <span className="colSemFoto">
                                               <span className="semImgCol"    >
                                                   <img    src={IconUserNone} alt="Usuario sem imagem" />
                                               </span>
                                           </span> 

                                       */}

                                       <span className="foto"  >
                                           <img  src={FotoExemploCarol} alt="nome" />
                                       </span>


                                       <div className='infor'  >
                                           <strong>Carolina Martini</strong>
                                           <p>Gestor</p>
                                       </div>
                                       <NavLink to="/gestao/one-to-one/:id" className='visualizar'>Visualizar</NavLink>  
                               </div>
                               <div className='infor-status'>
                                       <p>Status <span className='cancelado'>Cancelado</span></p>
                                       <p>Data e horário<span className='data'>20 Dez 2021 às 10:30</span></p>
                                       <p>Onde<span className='onde'>Google Meet</span></p>
                               </div>
                           </li>

                           <li>
                               <div className='collaborador'>

                                       {/*
                                           ESSE E O HTML PARA COLABORADOR SEM FOTO 
                                           ↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘↘

                                           <span className="colSemFoto">
                                               <span className="semImgCol"    >
                                                   <img    src={IconUserNone} alt="Usuario sem imagem" />
                                               </span>
                                           </span> 

                                       */}

                                       <span className="foto"  >
                                           <img  src={FotoExemploCarol} alt="nome" />
                                       </span>


                                       <div className='infor'  >
                                           <strong>Carolina Martini</strong>
                                           <p>Gestor</p>
                                       </div>
                                       <NavLink to="/gestao/one-to-one/:id" className='visualizar'>Visualizar</NavLink>  
                               </div>
                               <div className='infor-status'>
                                       <p>Status <span className='agendado'>Agendado</span></p>
                                       <p>Data e horário<span className='data'>20 Dez 2021 às 10:30</span></p>
                                       <p>Onde<span className='onde'>Google Meet</span></p>
                               </div>
                           </li>


                       </ul>
                       <button className='cancelar' onClick={modalHistoryOneToOne}>
                           Cancelar
                       </button>
                   </div>
                  
               </div>
           </Modal>
           </>
       );
}

