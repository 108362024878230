import React, { useState, useEffect } from 'react';
//import { NavLink } from 'react-router-dom'
import Carregando from '../../../../assets/images/carregando.gif';
import api from '../../../../services/api';
import { Scrollbars } from 'react-custom-scrollbars';

import moment from 'moment'
import Dropzone from "react-dropzone";
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado';
import {Countries} from '../../../../components/Reused/ArrayCountries';
import {removerSpecials} from '../../../../services/utils';

const formPersonalData = ({
    id,
    token,
    ufDistrito,
    onCollaboratorInputChange,
    ufCity,
    collaborator,
    collaboratorUpdate,
    LoaddateOfBirth,
    Loadgender              ,
    LoadfatherName          ,
    LoadmotherName          ,
    LoadmaritalStatus       ,
    Loadnationality         ,
    LoadotherNationality    ,
    Loadethnicity           ,
    LoadbornState           ,
    LoadbornCity            ,
    focusDateOfBirth        ,
    focusgender             ,
    focusfatherName         ,
    focusmotherName         ,
    focusmaritalStatus      ,
    focusnationality        ,
    focusotherNationality   ,
    focusethnicity          ,
    focusbornState          ,
    focusbornCity,
    setCollaboratorUpdate,
    setCollaborator,
    LoadsetbornState,
    LoadsetbornCity,
    path,
    Loadsetnationality
}) => {

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ load, setLoad ] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ citys, setCitys ] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ dateOfbirthSaved, setDateOfbirthSaved ] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ cidade, setCidade ] = useState('');
// eslint-disable-next-line react-hooks/rules-of-hooks
const [, setFileNames] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [filesSaved, setFilesSaved] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    async function loadfilessaved() {
        setLoad(true)
        //console.log(id)
        if(id && id !== undefined) {
            await api.get(`/collaborator-file-new/?id=${id}&category=identification`)
            .then(response => {
                setLoad(false)
                //console.log(response.data[0])
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                setLoad(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
    }
    loadfilessaved();
}, [id]);


//const deleteFile = async (filesaved) => {
async function deleteFile(filesaved) {
    //console.log(filesaved)
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoad(true)
            await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
            .then(() => {
                async function loadfilessaved() {
                    setLoad(false)
                    await api.get(`/collaborator-file-new/?id=${id}&category=identification`)
                    .then(response => {

                        setFilesSaved(response.data);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
            }).catch(() => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
            });
        }
    });
}


async function handleDrop(acceptedFiles) {
    //(acceptedFiles[0])
    //console.log(acceptedFiles[0].name)

    let file = acceptedFiles[0]
    const typeI = file.type ;

    if ((typeI === 'image/jpg') ||
        (typeI === 'image/jpeg') ||
        (typeI === 'image/pjpeg') ||
        (typeI === 'image/png') ||
        (typeI === 'image/gif') ||
        (typeI === 'application/pdf') ||
        (typeI === 'application/msword') ||
        (typeI === 'application/vnd.ms-powerpoint') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
        (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
        (typeI === 'application/vnd.ms-excel') ||
        (typeI === 'text/csv') ||
        (typeI === 'application/csv' )
    ) {

        setFileNames(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
        if (acceptedFiles === undefined) {
            toast.info('É necessário selecionar uma arquivo para o envio!');
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'identification');
        formData.append('collaborator', id);
        formData.append('token', token);
        formData.append('path', path);
        setLoad(true)
        await api.post("/collaborator-file-token", formData, config)
        .then(response => {
            async function loadfilessaved() {
                await api.get(`/collaborator-file-new/?id=${id}&category=identification`)
                .then(response => {
                    setLoad(false)
                    setFilesSaved(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadfilessaved();
            swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
        });
    } else {
        swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
    }

}


// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(ufDistrito !== undefined) {

        if(ufDistrito === '35') {
            // console.log('estado de são paulo')
            async function loadcities() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
                .then(response => {

                    let itensCopyCitys = Array.from(response.data);

                    itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });

                    itensCopyCitys.sort(function (a, b) {
                        if (a.nome > b.nome) {
                          return 1;
                        }
                        if (a.nome < b.nome) {
                          return -1;
                        }
                        // a must be equal to b
                        return 0;
                      });

                    setCitys(itensCopyCitys);

                }).catch(error => {});
            }
            loadcities();




        }
        else {

            async function loadcities() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
                .then(response => {
                    setCitys(response.data);
                }).catch(error => {});
            }
            loadcities();

        }

    }

}, [ufDistrito]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {

    if(ufCity !== undefined) {
        if(ufCity === '350000000') {

            let cidadeGravada = {
                id: "350000000",
                nome: "São Paulo"
            }
            setCidade(cidadeGravada);

        }
        else {

            async function loadcity() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
                .then(response => {
                    setCidade(response.data[0]);
                }).catch(error => {});
            }
            loadcity();

        }

    }



}, [ufCity]);

//https://servicodados.ibge.gov.br/api/v1/localidades/distritos/
//console.log(collaborator.dateOfBirth)


// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    async function loadcadastrocolaborador() {
        await api.get(`/collaborator-list/${token}`)
        .then(response => {
            setCollaboratorSaved(response.data);
        })
        .catch(error => {
            setCollaboratorSaved(error.response.data);
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    loadcadastrocolaborador();
}, [token]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    async function loaddateofbirth() {
        if(collaboratorSaved.dateOfBirth !== undefined) {
            let data = moment(collaboratorSaved.dateOfBirth, "YYYY-MM-DD");
            setDateOfbirthSaved(data.format("DD/MM/YYYY"))
        }
    }
    loaddateofbirth();
}, [collaboratorSaved.dateOfBirth, dateOfbirthSaved]);

const genderList = [
    "Feminino",
    "Masculino",
    "Transgênero",
    "Não-binário",
    "Prefiro não responder"
]
const maritalStatusList = [
    "Solteiro(a)",
    "Casado(a)",
    "Divorciado(a)",
    "Viúvo(a)",
    "União estável"
]


    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhido, setUEscolhido ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loaduf() {
            await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
            .then(response => {
                setUEscolhido(response.data);
                //console.log(response.data)
            }).catch(error => {});
        }
        loaduf();
    }, [ufDistrito]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEstados, setUfEstados ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadufestados() {
            await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
            .then(response => {
                setUfEstados(response.data);
            }).catch(error => {});
        }
        loadufestados();
    }, []);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectGender, setIsActiveSelectGender] = useState(false);
    async function SelectGender() { setIsActiveSelectGender(!isActiveSelectGender) }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectMaritalStatus, setIsActiveSelectMaritalStatus] = useState(false);
    async function SelectMaritalStatus() { setIsActiveSelectMaritalStatus(!isActiveSelectMaritalStatus) }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectEthnicity, setIsActiveSelectEthnicity] = useState(false);
    async function SelectEthnicity() { setIsActiveSelectEthnicity(!isActiveSelectEthnicity) }

    
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////

const myChangeHandlerSlects = event => {
    let nam = event.target.name;
    let val = event.target.value;

    if(nam === "nationalityBusca") {


        let valOption = removerSpecials(val).toLowerCase()
        setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
        setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
        setCollaborator({ ...collaborator,   "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });


    }else if (nam === "nationality") {


        setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val  });
        setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val  });
        // setCollaborator({ ...collaborator,  [nam]: val  });
        Loadsetnationality(false)

        if(collaboratorUpdate.nationality !== "Brasil") {
            setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val,  "bornStateBusca": "", "bornCityBusca": ""  });
            setNationalityBusca({ ...nationalityBusca, "bornStateBusca": "", "bornCityBusca": "" });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
            setCollaborator({ ...collaborator,  [nam]: "",  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });

        }else {
            setNationalityBuscaString({ ...nationalityBuscaString, "nationalityBusca": val, "bornStateBusca": "", "bornCityBusca": ""   });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val,  "bornCityString": "", "bornCity": "",  "bornState": "", "bornStateString": ""    });
            setCollaborator({ ...collaborator,    [nam]: val});

        }
    }
    else
    if(nam === "bornStateBusca") {
        let valOption = removerSpecials(val).toLowerCase()
        setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
        setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });

    }
    else
    if(nam === "bornState") {
        setBuscaLoadOptionBornstate(true)
        let estado = val;

        async function estados() {

            if(estado !== undefined) {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
                .then(response => {
                    setBuscaLoadOptionBornstate(false)
                    LoadsetbornState(false)
                    let bornStateString = response.data.nome;
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": ""   });
                    setCollaborator({ ...collaborator, bornStateString, [nam]: val,  "bornCityString": "", "bornCity": "" });

                    setNationalityBuscaString({ ...nationalityBuscaString,  "bornStateBusca": bornStateString,"bornCityBusca": "" });

                }).catch(error => {});

            }
        }
        estados();
    }
    else
    if(nam === "bornCityBusca") {
        let valOption = removerSpecials(val).toLowerCase()
        setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
        setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
    } else
    if(nam === "bornCity") {
        LoadsetbornCity(false)
        setBuscaLoadOptionCity(true)
        let cidade = val;
        if(cidade !== undefined) {

            if(cidade === '350000000') {

                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';
                setBuscaLoadOptionCity(false)

                setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });

            }
            else
            if(collaboratorUpdate.bornState === '35' && cidade === '350000000') {

                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';
                setBuscaLoadOptionCity(false)

                setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });

            }
            else {

                async function loadCity() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}`)
                    .then(response => {
                        let bornCityString = response.data[0].nome;
                        setBuscaLoadOptionCity(false)
                        setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, "bornCity": val })
                        setNationalityBuscaString({ ...nationalityBuscaString,  "bornCityBusca": bornCityString  });
                    }).catch(error => {});

                }
                loadCity();

            }

        }
    }

}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [BuscaLoadOptionBornstate , setBuscaLoadOptionBornstate] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [BuscaLoadOptionCity , setBuscaLoadOptionCity] = useState(false);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [nationalityBusca , setNationalityBusca] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [nationalityBuscaString , setNationalityBuscaString] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const optionFiltreNacionalite = Countries.filter((valOption) => {
    return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.nationalityBusca !== undefined ? nationalityBusca.nationalityBusca : "" )  ;
});

// eslint-disable-next-line react-hooks/rules-of-hooks
const optionFiltreUfEstados = ufEstados.filter((valOption) => {
    return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornStateBusca !== undefined ? nationalityBusca.bornStateBusca : "" )  ;
});
// eslint-disable-next-line react-hooks/rules-of-hooks
const optionFiltreUCidade = citys.filter((valOption) => {
    return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.bornCityBusca !== undefined ? nationalityBusca.bornCityBusca : "" )  ;
});


    return (
                <>

                    <div className="box-formulario">
                        <h4 className="titulo-box">Dados pessoais</h4>
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Data de nascimento *</p>
                                </div>
                                <div className='intemb'>
                                    {
                                        collaboratorUpdate && collaboratorUpdate.dateOfBirth ?
                                        (
                                            <input type="text" ref={focusDateOfBirth} name="dateOfBirth" value={(collaboratorUpdate.dateOfBirth !== undefined) ? collaboratorUpdate.dateOfBirth : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className={LoaddateOfBirth ? 'inputErro' : ''} />
                                        ) :
                                        dateOfbirthSaved[0] !== undefined ? (
                                            <input type="text" name="dateOfBirth" defaultValue={(dateOfbirthSaved !== undefined) ? dateOfbirthSaved : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"/>
                                        ):
                                        (
                                            <input type="text" ref={focusDateOfBirth} name="dateOfBirth" defaultValue={(dateOfbirthSaved !== undefined) ? dateOfbirthSaved : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className={LoaddateOfBirth ? 'inputErro' : ''} />
                                        )
                                    }
                                    {
                                        LoaddateOfBirth ? (
                                        <p className="textError inputUm">O campo é obrigatório.</p>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo textoDois">Sexo *</p>
                                </div>
                                <div className='intemB'>


                                <div className="select-busca-btn">
                                    <button
                                        className={Loadgender ? "menu-button inputErro" : "menu-button"}
                                        onBlur={ () => (setIsActiveSelectGender(false)) }
                                        onClick={ SelectGender }
                                        ref={focusgender}
                                    >
                                    {
                                        collaboratorUpdate && collaboratorUpdate.gender ?
                                        collaboratorUpdate.gender
                                        :
                                            collaborator && collaborator.gender ?
                                            collaborator.gender :
                                            'Selecione'

                                    }
                                    </button>
                                    <nav className={`menu ${isActiveSelectGender ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height:
                                        genderList.length === 0 ? 30 : "" ||
                                        genderList.length === 1 ? 30 : ""  ||
                                        genderList.length === 2 ? 70 : ""  ||
                                        genderList.length === 3 ? 100 : 120
                                        }}>
                                            <ul>
                                                {
                                                    genderList.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    genderList
                                                    .map((name, index) => (
                                                        <li>
                                                            <button
                                                            value={name}
                                                            name="gender"
                                                            onClick={onCollaboratorInputChange}
                                                            >{name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>

                                </div>



                                    {Loadgender ? (
                                        <p className="textError inputDois">O campo é obrigatório.</p>
                                        ) : ('')
                                    }

                                </div>
                            </div>
                        </div>
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Nome do pai *</p>
                                </div>
                                <div className='intemB'>
                                    <input ref={focusfatherName} type="text" autoComplete="off" name="fatherName" defaultValue={(collaborator.fatherName !== undefined) ? collaborator.fatherName : ''} onChange={onCollaboratorInputChange} placeholder="Nome do pai" className={LoadfatherName ? 'inputTreis inputErro' : 'inputTreis'} />
                                    {LoadfatherName ? (
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Nome da mãe *</p>
                                </div>
                                <div className='intemB'>
                                    <input ref={focusmotherName} type="text" name="motherName" autoComplete="off" defaultValue={(collaborator.motherName !== undefined) ? collaborator.motherName : ''} onChange={onCollaboratorInputChange} placeholder="Nome da mãe" className={LoadmotherName ? 'inputQuatro inputErro' : 'inputQuatro'} />
                                    {LoadmotherName ? (
                                        <p className="textError inputQuatro">O campo é obrigatório.</p>
                                        ) : ('')
                                    }

                                </div>
                            </div>
                        </div>

                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Estado civil *</p>
                                </div>
                                <div className='intemB'>

                                <div className="select-busca-btn">
                                    <button
                                        className={LoadmaritalStatus ? "menu-button inputErro" : "menu-button"}
                                        // onFocus={() => (setIsActiveSelectMaritalStatus(true))}
                                        ref={focusmaritalStatus}
                                        onBlur={ () => (setIsActiveSelectMaritalStatus(false)) }
                                        onClick={ SelectMaritalStatus }
                                    >
                                    {
                                        collaboratorUpdate && collaboratorUpdate.maritalStatus ?
                                        collaboratorUpdate.maritalStatus
                                        :
                                            collaborator && collaborator.maritalStatus ?
                                            collaborator.maritalStatus :
                                            'Selecione'

                                    }
                                    </button>
                                    <nav className={`menu ${isActiveSelectMaritalStatus ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height:
                                        maritalStatusList.length === 0 ? 30 : "" ||
                                        maritalStatusList.length === 1 ? 30 : ""  ||
                                        maritalStatusList.length === 2 ? 70 : ""  ||
                                        maritalStatusList.length === 3 ? 100 : 120
                                        }}>
                                            <ul>
                                                {
                                                    maritalStatusList.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    maritalStatusList
                                                    .map((name, index) => (
                                                        <li>
                                                            <button
                                                            value={name}
                                                            name="maritalStatus"
                                                            onClick={onCollaboratorInputChange}
                                                            >{name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>

                                </div>


                                    {LoadmaritalStatus ? (
                                        <p className="textError inputCinco">O campo é obrigatório.</p>
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                        <h5 className="SubtituloUm">Naturalidade</h5>
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Nacionalidade *</p>
                                </div>
                                <div className='intemB'>
                                    <SelectPersonalizado
                                        valorCampo={
                                            nationalityBuscaString && nationalityBuscaString.nationalityBusca ?
                                            nationalityBuscaString.nationalityBusca :
                                            nationalityBuscaString && nationalityBuscaString.nationalityBusca === '' ?
                                            '' :
                                            collaborator && collaborator.nationality ?
                                            collaborator.nationality :
                                            ''
                                        }
                                        nameCampoBusca={"nationalityBusca"}
                                        nameButton={"nationality"}
                                        loadError={Loadnationality}
                                        validacaoValue={"pais"}
                                        validacaoOptionIntem={"pais"}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreNacionalite}
                                        typeSelect={"busca"}
                                        focusRef={focusnationality}
                                    />
                                    {Loadnationality ? (
                                        <p className="textError inputSeis">O campo é obrigatório.</p>
                                        ) : ('')
                                        }
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Cor/Raça *</p>
                                </div>
                                <div className='intemB'>

                                <div className="select-busca-btn">
                                    <button
                                        className={Loadethnicity ? "menu-button inputErro" : "menu-button"}
                                        ref={focusethnicity}
                                        onBlur={ () => (setIsActiveSelectEthnicity(false)) }
                                        onClick={ SelectEthnicity }
                                    >
                                    {
                                        collaboratorUpdate && collaboratorUpdate.ethnicity ?
                                            collaboratorUpdate.ethnicity
                                        :
                                            collaborator && collaborator.ethnicity ?
                                                collaborator.ethnicity
                                                :
                                                'Selecione'
                                    }
                                    </button>
                                    <nav className={`menu ${isActiveSelectEthnicity ? "ativo": ""}`} >

                                        <Scrollbars className='listDropDown'  style={{  height: 120
                                        }}>
                                            <ul>
                                                {

                                                    [
                                                        "Nenhum",
                                                        "Branca",
                                                        "Preta",
                                                        "Indígena",
                                                        "Amarela",
                                                        "Parda"
                                                    ]
                                                    .map((name, index) => (
                                                        <li>
                                                            <button
                                                            value={name}
                                                            name="ethnicity"
                                                            onClick={onCollaboratorInputChange}
                                                            >{name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>

                                </div>





                                    {Loadethnicity ?
                                        <>
                                            <p className="textError inputSete">O campo é obrigatório.</p>
                                        </>
                                        : ('')
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Natural do Estado * </p>
                                </div>
                                <div className='intemB'>
                                {
                                    collaboratorUpdate?.nationality === 'Brasil' ||
                                    collaborator?.nationality === 'Brasil' || 
                                    collaborator?.nationality === undefined ||
                                    collaboratorUpdate?.nationality === '' ?
                                        <SelectPersonalizado
                                            valorCampo={

                                                nationalityBuscaString && nationalityBuscaString.bornStateBusca ?
                                                nationalityBuscaString.bornStateBusca :
                                                nationalityBuscaString && nationalityBuscaString.bornStateBusca === '' ?
                                                    '' :

                                                    collaboratorUpdate && collaboratorUpdate.bornState ?
                                                    ufEscolhido.nome 
                                                    :
                                                    collaborator && collaborator.bornState ?
                                                        ufEscolhido.nome : ''
                                            }
                                            nameCampoBusca={"bornStateBusca"}
                                            nameButton={"bornState"}
                                            loadError={LoadbornState}
                                            myChangeHandler={myChangeHandlerSlects}
                                            option={optionFiltreUfEstados}

                                            loadOption={BuscaLoadOptionBornstate}
                                            validacaoValue={"estado"}
                                            validacaoOptionIntem={"estado"}
                                            typeSelect={"busca"}
                                            focusRef={focusbornState}
                                        />
                                    : 
                                        <input ref={focusbornState} type="text" 
                                            name="bornStateString" 
                                            placeholder='Natural do Estado' 
                                            defaultValue={
                                                
                                                collaborator.bornStateString !== undefined ? 
                                                collaborator.bornStateString : ''
                                            
                                            } 
                                            onChange={onCollaboratorInputChange} className={LoadbornState ? 'inputOito inputErro' : 'inputOito'} 
                                        />
                                    }
                                    
                                    
                                    
                                    
                                    
                                    {LoadbornState ? (
                                    <p className="textError inputOito">O campo é obrigatório.</p>
                                    ) : ('')
                                    }
                                </div>
                            </div>

                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo textoNove">Natural da Cidade *</p>
                                </div>
                                <div className='intemB'>
                                {
                                    collaboratorUpdate?.nationality === 'Brasil' ||
                                    collaborator?.nationality === 'Brasil' || 
                                    collaborator?.nationality === undefined ||
                                    collaboratorUpdate?.nationality === '' ?
                                        <SelectPersonalizado
                                            valorCampo={
        
                                                nationalityBuscaString && nationalityBuscaString.bornCityBusca ?
                                                nationalityBuscaString.bornCityBusca :
                                                nationalityBuscaString && nationalityBuscaString.bornCityBusca === '' ?
                                                    '' :
                                                    collaboratorUpdate && collaboratorUpdate.bornCity?
                                                    cidade.nome 
                                                    : collaborator?.bornCity   ?
                                                    cidade.nome : ''
                                            }
                                            nameCampoBusca={"bornCityBusca"}
                                            nameButton={"bornCity"}
                                            loadError={LoadbornCity}
                                            myChangeHandler={myChangeHandlerSlects}
                                            option={optionFiltreUCidade}
        
                                            loadOption={BuscaLoadOptionCity}
                                            validacaoValue={"estado"}
                                            validacaoOptionIntem={"estado"}
                                            typeSelect={"busca"}
                                            focusRef={focusbornCity}
                                        />
                                    :
                                    <input ref={focusbornCity} 
                                        type="text" name="bornCityString" 
                                        placeholder='Natural da Cidade' 
                                        defaultValue={
                                            collaborator.bornCityString !== undefined ? 
                                            collaborator.bornCityString : ''
                                        } onChange={onCollaboratorInputChange} 
                                        className={LoadbornCity ? 'inputNove inputErro' : 'inputNove'}
                                    />
                                }
                                    
                                    
                                    
                                    
                                    
                                    
                                    
                                    {LoadbornCity ? (
                                    <p className="textError inputNove">O campo é obrigatório.</p>
                                    ) : ('')
                                    }
                                </div>
                            </div>
                        </div>


                  </div>
                  <div className="dados-pessoais-dropzone">
                        <div className="dropzone">
                            <Dropzone className="" onDrop={handleDrop}  >
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input type="file" name="file"  {...getInputProps()} />
                                    <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                </div>
                                )}
                            </Dropzone>
                            <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>

                        </div>

                    <div className="arquivoAnexado">
                        {filesSaved ?
                        filesSaved.map((filesaved, index) => (
                            <div key={index} className="arquivo">
                                <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                                </p>
                                <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                            </div>
                            )) : ''}
                    </div>
                    {load ?
                    (<div className="carregando"><img src={Carregando} alt="carregando" /></div>) :
                    ('')}
                  </div>



            </>
        );

}
export default formPersonalData;
