import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import Carregando from '../../../../assets/images/carregando.gif';
import Dropzone from "react-dropzone";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado';
import {removerSpecials} from '../../../../services/utils';

export default function Rg({
    id,
    token,
    onCollaboratorInputChange,
    collaboratorUpdate,
    setCollaboratorUpdate,
    collaborator,
    loadrg,
    loadrgExpeditionDate,
    loadrgOrgan,
    loadufOrgan,
    setLoadufOrgan,
    focusrg,
    focusrgExpeditionDate,
    focusrgOrgan,
    focusufOrgan,
    estadosOrgam,
    path,
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSaved, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
       if(id && id){ async function loadfilessaved() {
            setLoad(true)
            await api.get(`/collaborator-file-new/?id=${id}&category=docRG`)
            .then(response => {
                setLoad(false)
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                setLoad(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();}
    }, [id]);

    async function handleDrop(acceptedFiles) {

            let file = acceptedFiles[0]
            const typeI = file.type ;

            if ((typeI === 'image/jpg') ||
                (typeI === 'image/jpeg') ||
                (typeI === 'image/pjpeg') ||
                (typeI === 'image/png') ||
                (typeI === 'image/gif') ||
                (typeI === 'application/pdf') ||
                (typeI === 'application/msword') ||
                (typeI === 'application/vnd.ms-powerpoint') ||
                (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
                (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
                (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
                (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
                (typeI === 'application/vnd.ms-excel') ||
                (typeI === 'text/csv') ||
                (typeI === 'application/csv' )
            ) {

                setFileNames(acceptedFiles.map(file => file.name));

                const formData = new FormData();
                const config = {
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        }
                if (acceptedFiles === undefined) {
                    toast.info('É necessário selecionar uma arquivo para o envio!');
                    return
                }
                formData.append('file', acceptedFiles[0]);
                formData.append('name', acceptedFiles[0].name);
                formData.append('category', 'docRG');
                formData.append('collaborator', id);
                formData.append('token', token);
                formData.append('path', path);
                setLoad(true)
                await api.post("/collaborator-file-token", formData, config)
                .then(response => {
                    async function loadfilessaved() {
                        await api.get(`/collaborator-file-new/?id=${id}&category=docRG`)
                        .then(response => {
                            setLoad(false)
                            setFilesSaved(response.data);
                        }).catch(error=>{
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                    //setTokenError(error.response.data);
                    console.log(error.response.data)
                    setLoad(false)
                });
            } else {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
            }

    }

    //const deleteFile = async (filesaved) => {
    async function deleteFile(filesaved) {
        //console.log(filesaved)

        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoad(true)
                await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file-new/?id=${id}&category=docRG`)
                        .then(response => {
                            setFilesSaved(response.data);
                        }).catch(error=>{
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ufOrganStringBusca , setufOrganStringBusca] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        
        if(nam === "ufOrganBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setufOrganStringBusca({ ...ufOrganStringBusca, [nam]: valOption });
        }else if(
            nam === "ufOrgan"
        ) {
            setLoadufOrgan(false)
            setCollaboratorUpdate({ ...collaboratorUpdate,  "ufOrgan": val })
            setufOrganStringBusca({ ...ufOrganStringBusca, "ufOrganBusca": val });

        }
    }

    
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = estadosOrgam.filter((valOption) => {
        return removerSpecials(valOption).toLowerCase().includes(ufOrganStringBusca?.ufOrganBusca !== undefined ? ufOrganStringBusca.ufOrganBusca : "" )  ;
    });

    return (
            <>

                <div className="box-formulario rg">
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <h5 >RG</h5>
                                </div>
                            </div>
                        </div>
                        <div className='linha'>
                                <div className='bloco'>
                                    <div className='intemA'>
                                        <p className="textCampo textoUm">Número do RG *</p>

                                    </div>
                                    <div className='intemB'>
                                        <input
                                            ref={focusrg}
                                            type="text"
                                            name="rg"
                                            maxLength="16"
                                            value={
                                                (collaboratorUpdate && collaboratorUpdate.rg)
                                                ||
                                                (collaboratorUpdate.rg === "")
                                                ?
                                                collaboratorUpdate.rg
                                                :
                                                (collaborator && collaborator.rg)
                                                ||
                                                (collaborator.rg === "")
                                                ?
                                                collaborator.rg
                                                :
                                                ''

                                            }
                                            onChange={onCollaboratorInputChange}
                                            placeholder="Número do RG"
                                            className={loadrg ? 'inputUm inputErro' : 'inputUm'}
                                        />

                                        {
                                        loadrg ?
                                        <p className="textError inputUm">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }
                                    </div>
                                </div>
                                <div className='bloco'>
                                    <div className='intemA'>
                                        <p className="textCampo textoDois">Data de expedição *</p>
                                    </div>
                                    <div className='intemB'>
                                        <input
                                            ref={focusrgExpeditionDate}
                                            type="text"
                                            name="rgExpeditionDate"
                                            value={
                                                (collaboratorUpdate && collaboratorUpdate.rgExpeditionDate)
                                                ||
                                                (collaboratorUpdate.rgExpeditionDate === "")
                                                ?
                                                collaboratorUpdate.rgExpeditionDate
                                                :
                                                (collaborator && collaborator.rgExpeditionDate)
                                                ||
                                                (collaborator.rgExpeditionDate === "")
                                                ?
                                                collaborator.rgExpeditionDate
                                                :
                                                ''
                                            }
                                            placeholder="DD/MM/AAAA"
                                            onChange={onCollaboratorInputChange}
                                            className={loadrgExpeditionDate ? 'inputDois inputErro' : 'inputDois'}
                                        />
                                        {
                                        loadrgExpeditionDate ?
                                        <p className="textError inputDois">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='linha'>
                                <div className='bloco'>
                                    <div className='intemA'>
                                        <p className="textCampo">Órgão emissor *</p>
                                    </div>
                                    <div className='intemB'>
                                         <input
                                            ref={focusrgOrgan}
                                            type="text"
                                            name="rgOrgan"
                                            value={
                                                (collaboratorUpdate && collaboratorUpdate.rgOrgan)
                                                ||
                                                (collaboratorUpdate.rgOrgan === "")
                                                ?
                                                collaboratorUpdate.rgOrgan
                                                :
                                                (collaborator && collaborator.rgOrgan)
                                                ||
                                                (collaborator.rgOrgan === "")
                                                ?
                                                collaborator.rgOrgan
                                                :
                                                ''
                                            }
                                            onChange={onCollaboratorInputChange}
                                            placeholder="Órgão emissor"
                                            className={loadrgOrgan ? 'inputTreis inputErro' : 'inputTreis'}
                                        />

                                        {
                                        loadrgOrgan ?
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }

                                    </div>
                                </div>
                                <div className='bloco'>
                                    <div className='intemA'>
                                        <p className="textCampo textoQuatro">UF emissor *</p>
                                    </div>
                                    <div className='intemB'>


                                    <SelectPersonalizado
                                            valorCampo={
                                                ufOrganStringBusca && ufOrganStringBusca.ufOrganBusca ?
                                                ufOrganStringBusca.ufOrganBusca :
                                                ufOrganStringBusca && ufOrganStringBusca.ufOrganBusca === '' ?
                                                '' :
                                                collaborator && collaborator.ufOrgan ?
                                                    collaborator.ufOrgan
                                                    :
                                                    ''
                                            }
                                            nameCampoBusca={"ufOrganBusca"}
                                            nameButton={"ufOrgan"}
                                            loadError={loadufOrgan}
                                            myChangeHandler={myChangeHandlerSlects}
                                            option={optionFiltreUCidade}
                                            typeSelect={"busca"}
                                            focusRef={focusufOrgan}
                                        />

                                        {
                                        loadufOrgan ?
                                        <p className="textError inputQuatro">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }

                                    </div>
                                </div>
                            </div>

                    <div className="rg-dropzone">
                            <div className="dropzone">
                                <Dropzone className="" onDrop={handleDrop}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="textoOnze">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </div>


                            <div className="arquivoAnexado">
                                {filesSaved ?
                                filesSaved.map((filesaved, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                            {filesaved.originalname}
                                            </a>
                                        </p>
                                        <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                                    </div>
                                    )) : ''}
                            </div>
                            {load ?
                            (<div className="carregando  textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                            ('')}

                     </div>
                </div>
            </>
        );

}

