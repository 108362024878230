import React, { useState, useEffect } from 'react';

import Carregando from '../../../../assets/images/carregando.gif';
import Dropzone from "react-dropzone";
// import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
// import { Scrollbars } from 'react-custom-scrollbars';
import {removerSpecials} from '../../../../services/utils'
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado'

import api from '../../../../services/api';


const Reservista = ({
    id,
    token,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    ufDistrito,
    ufCity,
    loadreservationCardNumber         ,
    loadreservationCardSeries         ,
    loadreservationCardCategory       ,
    loadreservationCardExpeditionDate ,
    loadufReservationCard             ,
    loadcityReservationCard           ,
    focusreservationCardNumber        ,
    focusreservationCardSeries        ,
    focusreservationCardCategory      ,
    focusreservationCardExpeditionDate,
    focusufReservationCard            ,
    focuscityReservationCard,
    path,
    setCollaboratorUpdate
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSaved, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEstados, setUfEstados ] = useState([]);
    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhido, setUEscolhido ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCidade ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ citys, setCitys ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id){
        async function loadfilessaved() {
            setLoad(true)
            await api.get(`/collaborator-file-new/?id=${id}&category=docCR`)
            .then(response => {
                setLoad(false)
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                setLoad(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();}
    }, [id]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNames(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'docCR');
            formData.append('collaborator', id);
            formData.append('token', token);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file-token", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file-new/?id=${id}&category=docCR`)
                    .then(response => {
                        setLoad(false)
                        setFilesSaved(response.data);
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                //setTokenError(error.response.data);
                console.log(error.response.data)
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    //const deleteFile = async (filesaved) => {
    async function deleteFile(filesaved) {
        //console.log(filesaved)
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoad(true)
                await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file-new/?id=${id}&category=docCR`)
                        .then(response => {
                            setFilesSaved(response.data);
                        }).catch(error=>{
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadufestados() {
            await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
            .then(response => {
                setUfEstados(response.data);
            }).catch(error => {});
        }
        loadufestados();
    }, []);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(ufDistrito !== undefined) {

            if(ufDistrito === '35') {
                // console.log('estado de são paulo')
                async function loadcities() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
                    .then(response => {

                        let itensCopyCitys = Array.from(response.data);

                        itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });
                        // console.log('itensCopyCitys')
                        // console.log(itensCopyCitys)

                        itensCopyCitys.sort(function (a, b) {
                            if (a.nome > b.nome) {
                            return 1;
                            }
                            if (a.nome < b.nome) {
                            return -1;
                            }
                            // a must be equal to b
                            return 0;
                        });

                        setCitys(itensCopyCitys);

                    }).catch(error => {});
                }
                loadcities();




            }
            else {

                async function loadcities() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
                    .then(response => {
                        setCitys(response.data);
                    }).catch(error => {});
                }
                loadcities();

            }



        }




    }, [ufDistrito]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(ufDistrito !== undefined) {

            async function loaduf() {
                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
                .then(response => {
                    setUEscolhido(response.data);
                }).catch(error => {});
            }
            loaduf();

        }


    }, [ufDistrito]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(ufCity !== undefined) {

            if(ufCity === '350000000') {

                let cidadeGravada = {
                    id: "350000000",
                    nome: "São Paulo"
                }
                setCidade(cidadeGravada);

            }
            else {

                async function loadcity() {
                    await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
                    .then(response => {
                        setCidade(response.data[0]);
                    }).catch(error => {});
                }
                loadcity();

            }

        }

    }, [ufCity]);

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhidoUpdateC, setUUfEscolhidoUpdateC ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBusca , setNationalityBusca] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBuscaString , setNationalityBuscaString] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === "ufReservationCardBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
    
        }
        else
        if(nam === "ufReservationCard") {

            setCollaboratorUpdate({ ...collaboratorUpdate, "ufReservationCard": val })
            
            api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val}`)
            .then(response => {
                setUUfEscolhidoUpdateC(response.data);
                setNationalityBuscaString({ ...nationalityBuscaString,  "ufReservationCardBusca": response.data.nome  });
    
                if(response.data.nome === "São Paulo") {
    
                    async function loadufestados() {
                        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${response.data.id}/distritos?orderBy=nome`)
                        .then(response => {
    
                            let itensCopyCitys = Array.from(response.data);
    
                            itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });
    
                            itensCopyCitys.sort(function (a, b) {
                                if (a.nome > b.nome) {
                                return 1;
                                }
                                if (a.nome < b.nome) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });
    
                            setCitys(itensCopyCitys);
    
                        }).catch(error => {});
                    }
                    loadufestados()
    
    
                }
    
            }).catch(error => {});
        }
        else
        if(nam === "cityReservationCardBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        } 
        else
        if(nam === "cityReservationCard") {
            setNationalityBuscaString({ ...nationalityBuscaString, "cityReservationCardBusca": val });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val });
        }
    }

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const optionFiltreUfEstados = ufEstados.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.ufReservationCardBusca !== undefined ? nationalityBusca.ufReservationCardBusca : "" )  ;
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUfCidade = citys.filter((valOption) => {
         return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.cityReservationCardBusca !== undefined ? nationalityBusca.cityReservationCardBusca : "" )  ;
     });

    return (
            <>

                <div className="box-formulario reservista">
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <h5>Carteira de Reservista</h5>
                                </div>
                            </div>
                        </div>
                        <div className='linha'>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Número</p>
                                </div>
                                <div className='intemB'>

                                    <input
                                        ref={focusreservationCardNumber}
                                        type="text"
                                        name="reservationCardNumber"
                                        value={
                                            (collaboratorUpdate && collaboratorUpdate.reservationCardNumber)
                                            ||
                                            (collaboratorUpdate.reservationCardNumber === "")
                                            ?
                                            collaboratorUpdate.reservationCardNumber
                                            :
                                            (collaborator && collaborator.reservationCardNumber)
                                            ||
                                            (collaborator.reservationCardNumber === "")
                                            ?
                                            collaborator.reservationCardNumber
                                            :
                                            ''
                                        }
                                        placeholder="Número"
                                        onChange={onCollaboratorInputChange}
                                        className={loadreservationCardNumber ? 'inputUm inputErro' : 'inputUm'}
                                    />
                                    {
                                    loadreservationCardNumber ?
                                    <p className="textError inputUm">O campo é obrigatório.</p>
                                    :
                                    ('')
                                    }
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className='intemA'>
                                    <p className="textCampo">Série</p>
                                </div>
                                <div className='intemB'>
                                    <input
                                        ref={focusreservationCardSeries}
                                        type="text"
                                        name="reservationCardSeries"
                                        value={
                                            (collaboratorUpdate && collaboratorUpdate.reservationCardSeries)
                                            ||
                                            (collaboratorUpdate.reservationCardSeries === "")
                                            ?
                                            collaboratorUpdate.reservationCardSeries
                                            :
                                            (collaborator && collaborator.reservationCardSeries)
                                            ||
                                            (collaborator.reservationCardSeries === "")
                                            ?
                                            collaborator.reservationCardSeries
                                            :
                                            ''
                                        }
                                        placeholder="Série"
                                        maxLength={4}
                                        onChange={onCollaboratorInputChange}
                                        className={loadreservationCardSeries ? 'inputDois inputErro' : 'inputDois'}
                                    />
                                    {
                                    loadreservationCardSeries ?
                                    <p className="textError inputDois">O campo é obrigatório.</p>
                                    :
                                    ('')
                                    }

                                </div>
                            </div>
                        </div>


                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoTreis">Categoria </p>
                            </div>
                            <div className='intemB'>

                                <input
                                    ref={focusreservationCardCategory}
                                    type="text"
                                    name="reservationCardCategory"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.reservationCardCategory)
                                        ||
                                        (collaboratorUpdate.reservationCardCategory === "")
                                        ?
                                        collaboratorUpdate.reservationCardCategory
                                        :
                                        (collaborator && collaborator.reservationCardCategory)
                                        ||
                                        (collaborator.reservationCardCategory === "")
                                        ?
                                        collaborator.reservationCardCategory
                                        :
                                        ''
                                    }
                                    onChange={onCollaboratorInputChange}
                                    placeholder="Categoria"
                                    className={loadreservationCardCategory ? 'inputTreis inputErro' : 'inputTreis'}
                                />
                                {
                                loadreservationCardCategory ?
                                <p className="textError inputTreis">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoQuatro">Data Expedição </p>
                            </div>
                            <div className='intemB'>

                                <input
                                    ref={focusreservationCardExpeditionDate}
                                    type="text"
                                    name="reservationCardExpeditionDate"
                                    value={
                                        (collaboratorUpdate && collaboratorUpdate.reservationCardExpeditionDate)
                                        ||
                                        (collaboratorUpdate.reservationCardExpeditionDate === "")
                                        ?
                                        collaboratorUpdate.reservationCardExpeditionDate
                                        :
                                        (collaborator && collaborator.reservationCardExpeditionDate)
                                        ||
                                        (collaborator.reservationCardExpeditionDate === "")
                                        ?
                                        collaborator.reservationCardExpeditionDate
                                        :
                                        ''
                                    }
                                    placeholder="DD/MM/AAAA"
                                    onChange={onCollaboratorInputChange}
                                    className={loadreservationCardExpeditionDate ? 'inputQuatro inputErro' : 'inputQuatro'}
                                    />
                                    {
                                    loadreservationCardExpeditionDate ?
                                    <p className="textError inputQuatro">O campo é obrigatório.</p>
                                    :
                                    ('')
                                    }
                            </div>
                        </div>
                    </div>

                    <div className='linha'>
                        <div className='bloco'>
                            <div className='intemA'>
                                 <p className="textCampo textoCinco">Estado </p>
                            </div>
                            <div className='intemB'>
                                <SelectPersonalizado
                                    valorCampo={

                                        nationalityBuscaString && nationalityBuscaString.ufReservationCardBusca ?
                                        nationalityBuscaString.ufReservationCardBusca :
                                        nationalityBuscaString && nationalityBuscaString.ufReservationCardBusca === '' ?
                                            '' :

                                            collaboratorUpdate && collaboratorUpdate.ufReservationCard ?
                                            ufEscolhidoUpdateC.nome 
                                            :
                                            collaborator && collaborator.ufReservationCard ?
                                            ufEscolhido.nome : ''
                                    }
                                    nameCampoBusca={"ufReservationCardBusca"}
                                    nameButton={"ufReservationCard"}
                                    loadError={loadufReservationCard}
                                    myChangeHandler={myChangeHandlerSlects}
                                    option={optionFiltreUfEstados}
                                    validacaoValue={"estado"}
                                    validacaoOptionIntem={"estado"}
                                    typeSelect={"busca"}
                                />


                                {
                                loadufReservationCard ?
                                <p className="textError inputCinco">O campo é obrigatório.</p>
                                :
                                ('')
                                }
                            </div>
                        </div>
                        <div className='bloco'>
                            <div className='intemA'>
                                <p className="textCampo textoSeis">Cidade </p>
                            </div>
                            <div className='intemB'>

                            <SelectPersonalizado
                                    valorCampo={
        
                                        nationalityBuscaString && nationalityBuscaString.cityReservationCardBusca ?
                                        nationalityBuscaString.cityReservationCardBusca  :
                                        nationalityBuscaString && nationalityBuscaString.cityReservationCardBusca === '' ?
                                            '' :
                                            collaboratorUpdate && collaboratorUpdate.cityReservationCard ?
                                            collaboratorUpdate.cityReservationCard :
                                            collaborator && collaborator.cityReservationCard  ?
                                            collaborator.cityReservationCard  : ''
                                    }
                                    nameCampoBusca={"cityReservationCardBusca"}
                                    nameButton={"cityReservationCard"}
                                    loadError={loadcityReservationCard}
                                    myChangeHandler={myChangeHandlerSlects}
                                    option={optionFiltreUfCidade}
                                    validacaoValue={"pais"}
                                    validacaoOptionIntem={"pais"}
                                    typeSelect={"busca"}
                                />

                                
                                    {
                                    loadcityReservationCard ?
                                    <p className="textError inputSeis">O campo é obrigatório.</p>
                                    :
                                    ('')
                                    }

                            </div>
                        </div>
                    </div>


                        <div className='reservista-dropzone'>
                            <div className="dropzone">
                                <Dropzone className="" onDrop={handleDrop}  >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file"  {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="textoOnze">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                </div>
                            </div>
                            <div className="arquivoAnexado textoDoze">
                                {filesSaved ?
                                filesSaved.map((filesaved, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                            {filesaved.originalname}
                                            </a>
                                        </p>
                                        <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                                    </div>
                                    )) : ''}
                                </div>
                                {load ?
                                (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                                ('')}
                        </div>




                </div>
            </>
        );

}
export default Reservista;
