import React from 'react';
import SubMenu from './subMenu';
import CPF from './Documentos/Cpf';
import WorkCard from './Documentos/WorkCard';
import VoterTitle from './Documentos/VoterTitle';
import Rg from './Documentos/RG';
import Cnh from './Documentos/CNH';
import Reservist from './Documentos/Reservist';


export default function ColProfilePersonalDoc({
    id,
    path,
    collaborator,
    loadPage,
    collaboratorUpdate,
    onCollaboratorInputChange,
    collaboratorState,
    ufEstados,
    ufDistrito,
    OnSubmit,
    loadContinue,
    EditCpfVisible,
    visibleCPF,
    EditWorkCardVisible,
    visibleWorkCard,
    EditRGVisible,
    visibleRG,
    EditVoterTitleVisible,
    visibleVoterTitle,
    EditReservistVisible,
    visibleReservist,
    EditCNHVisible,
    visibleCNH,
    filesSavedHandleDropCPF,
    loadHandleDropCPF,
    fileNamesCPF,
    handleDropCPF,
    deleteFileCPF,
    filesSavedHandleDropWorkCard,
    loadWorkCard,
    fileNamesWorkCard,
    handleDropWorkCard,
    deleteFileWorkCard,
    filesSavedRG,
    loadRG,
    deleteFileRG,
    fileNamesRG,
    handleDropRG,
    filesSavedVoterTitle,
    loadVoterTitle,
    deleteFileVoterTitle,
    fileNamesVoterTitle,
    handleDropVoterTitle,
    filesSavedReservist,
    loadReservist,
    deleteFileReservist,
    handleDropReservist,
    filesSavedCNH,
    loadCNH,
    deleteFileCNH,
    handleDropCNH,
    setCollaboratorUpdate,
    ufReservationCard,

    setSelectRGufOrgan,
    selectRGufOrgan,

    selectUfReservationCard,
    setSelectUfReservationCard,
    selectCityReservationCard,
    setSelectCityReservationCard,

    selectCnhCategory,
    setSelectCnhCategory,
    selectUfCNH,
    setSelectUfCNH,


    setSelectWalletUF,
    selectWalletUF,
    role,
 }) {

    const estadosOrgam = [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins"
    ]

    return (
        <>
            <SubMenu id={id} collaborator={collaborator} role={role} path={path}/>
            <div className="box-infor-perfil profile-personal-docs">
                <CPF
                    id={id}
                    loadPage={loadPage}
                    collaboratorUpdate={collaboratorUpdate}
                    collaborator={collaborator}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    ufEstados={ufEstados}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    EditCpfVisible={EditCpfVisible}
                    visibleCPF={visibleCPF}
                    filesSavedHandleDropCPF={filesSavedHandleDropCPF}
                    loadHandleDropCPF={loadHandleDropCPF}
                    fileNamesCPF={fileNamesCPF}
                    handleDropCPF={handleDropCPF}
                    deleteFileCPF={deleteFileCPF}
                />
                <WorkCard
                    id={id}
                    loadPage={loadPage}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    ufEstados={ufEstados}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    EditWorkCardVisible={EditWorkCardVisible}
                    visibleWorkCard={visibleWorkCard}
                    filesSavedHandleDropWorkCard={filesSavedHandleDropWorkCard}
                    loadWorkCard={loadWorkCard}
                    fileNamesWorkCard={fileNamesWorkCard}
                    handleDropWorkCard={handleDropWorkCard}
                    deleteFileWorkCard={deleteFileWorkCard}
                    setCollaboratorUpdate={setCollaboratorUpdate}

                    setSelectWalletUF={setSelectWalletUF}
                    selectWalletUF={selectWalletUF}
                    estadosOrgam={estadosOrgam}
                />
                <VoterTitle
                    id={id}
                    loadPage={loadPage}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    ufEstados={ufEstados}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    EditVoterTitleVisible={EditVoterTitleVisible}
                    visibleVoterTitle={visibleVoterTitle}
                    filesSavedVoterTitle={filesSavedVoterTitle}
                    loadVoterTitle={loadVoterTitle}
                    deleteFileVoterTitle={deleteFileVoterTitle}
                    fileNamesVoterTitle={fileNamesVoterTitle}
                    handleDropVoterTitle={handleDropVoterTitle}
                />
                <Rg
                    id={id}
                    loadPage={loadPage}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    ufEstados={ufEstados}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    EditRGVisible={EditRGVisible}
                    visibleRG={visibleRG}
                    filesSavedRG={filesSavedRG}
                    loadRG={loadRG}
                    deleteFileRG={deleteFileRG}
                    fileNamesRG={fileNamesRG}
                    handleDropRG={handleDropRG}
                    setCollaboratorUpdate={setCollaboratorUpdate}
                    estadosOrgam={estadosOrgam}
                    setSelectRGufOrgan={setSelectRGufOrgan}
                    selectRGufOrgan={selectRGufOrgan}
                />
                <Cnh
                    id={id}
                    loadPage={loadPage}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    ufEstados={ufEstados}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    EditCNHVisible={EditCNHVisible}
                    visibleCNH={visibleCNH}
                    filesSavedCNH={filesSavedCNH}
                    loadCNH={loadCNH}
                    deleteFileCNH={deleteFileCNH}
                    handleDropCNH={handleDropCNH}
                    setCollaboratorUpdate={setCollaboratorUpdate}

                    selectCnhCategory={selectCnhCategory}
                    setSelectCnhCategory={setSelectCnhCategory}
                    selectUfCNH={selectUfCNH}
                    setSelectUfCNH={setSelectUfCNH}
                    
                    estadosOrgam={estadosOrgam}
                />
                {
                    collaborator.gender && collaborator.gender === 'Masculino' ?
                    <Reservist
                        id={id}
                        loadPage={loadPage}
                        collaborator={collaborator}
                        collaboratorUpdate={collaboratorUpdate}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstadoEscolhido={collaborator.bornState}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        collaboratorState={collaborator}
                        ufDistrito={collaborator.ufReservationCard}
                        ufReservationCard={ufReservationCard}
                        ufCity={collaborator.cityReservationCard}
                        EditReservistVisible={EditReservistVisible}
                        visibleReservist={visibleReservist}
                        filesSavedReservist={filesSavedReservist}
                        loadReservist={loadReservist}
                        deleteFileReservist={deleteFileReservist}
                        handleDropReservist={handleDropReservist}
                        setCollaboratorUpdate={setCollaboratorUpdate}

                        selectUfReservationCard={selectUfReservationCard}
                        setSelectUfReservationCard={setSelectUfReservationCard}
                        selectCityReservationCard={selectCityReservationCard}
                        setSelectCityReservationCard={setSelectCityReservationCard}
                    />
                    :
                    ''
                }
            </div>
        </>
    );
}

