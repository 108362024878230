import React from 'react';
import IconsInformation from '../../components/IconsInformation';
// import iconMenu from '../../assets/images/icon-toogle-list-selected.svg'
// import Logo from '../../assets/images/logo.svg'
import  './styles.scss';

export default function headerGeral({
    children, 
}) {


    return (
        <>
            <header className="header-component">
                <div className='btn-menu-responsivo'>
                    {/* <button className='menu' onClick={abrirSidBar}>
                        <img src={iconMenu} alt="menu" />
                    </button>
                    <button className='logo'>
                        <img src={Logo} alt="peopleview" />
                    </button> */}

                </div>
                <div className="cabeçalho">
                    {children}
                </div>
                <div className="infor">
                   <IconsInformation />
                </div>
            </header>
        </>
    );
}

