import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import Dropzone from "react-dropzone";
import swal from 'sweetalert';
import api from '../../../services/api';
import apiV2 from '../../../services/apiV2';
import Skeleton from 'react-loading-skeleton';
import HeaderG from '../../../components/Administrativo/FolhaDePagamento/header';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';
import Utils from '../../../shared/Utils';
import './styles.scss';

export default function PageFolhaDePagamentoSubmit(props) {

    const { id } = props.match.params

      ////////////////////////////////////////////////////////////////////////////
     ////////////////////       PAYCHECK LIST       /////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    const [ loadPaycheck, setLoadPaycheck ] = useState(false);
    const [ allPaycheck, setAllPaycheck] = useState([]);
    useEffect(() => {

        loadallPaycheck(id);

    }, [id]);

    async function loadallPaycheck(id) {
        try{
            setLoadPaycheck(true);
            setLoadPayroll(true);
            await apiV2.get(`/payroll/${id}?select[0]=-allPayrollDocumentBase64&select[1]=-documents.files.base64`)
            .then(response => {
                setAllPaycheck(response.data);
                console.log('allPaycheck===>', response.data)
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao carregar lista de documentos. Tente novamente mais tarde." });
            });
        } finally {
            setLoadPaycheck(false);
            setLoadPayroll(false);
        }
    }

     const [ collaboratorID, setCollaboratorID] = useState([]);

     async function collaboratorIdAddPaycheck(e) {
        e.preventDefault();

        let collaborator = e.target.id;

        setCollaboratorID({ ...collaboratorID, collaborator })

    }

     async function handleDropPaycheck(acceptedFiles) {
        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            Utils.getBase64(acceptedFiles[0])
            .then(result => {
                setLoadPaycheck(true)
                try{
                    const payload = {
                        collaborator: `${collaboratorID.collaborator}`,
                        payroll: `${id}`,
                        files: [
                            {
                                name: `${acceptedFiles[0].name}`,
                                format: `${acceptedFiles[0].type.split('/')[1].trim()}`,
                                base64: `${result}`
                            }
                        ]
                    }
                    apiV2.post('/payroll-document', payload)
                    .then(response => {
                        loadallPaycheck(id);
                        swal({ icon: "success", title: "Sucesso!", text: "Arquivo adicionado com sucesso!" });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar arquivo!" });
                    }); 
                }
                finally{
                    setLoadPaycheck(false);
                }
            })
            .catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar arquivo! Erro: " + error });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    async function updatePaycheck(e) {
        let file = e.target.files[0];
        let paycheckId = e.target.dataset.id;

        Utils.getBase64(e.target.files[0])
        .then(result => {
            setLoadPaycheck(true)
            try{
                const payload = {
                    files: [
                        {
                            name: `${file.name}`,
                            format: `${file.type.split('/')[1].trim()}`,
                            base64: `${result}`
                        }
                    ]
                }
                apiV2.patch(`/payroll-document/${paycheckId}`, payload)
                .then(() => {
                    loadallPaycheck(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo adicionado com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar arquivo!" });
                }); 
            }
            finally{
                setLoadPaycheck(false);
            }
        })
        .catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar arquivo! Erro: " + error });
        });        
    }

    async function downloadPaycheck(e) {
        setLoadPaycheck(true);
        try{
            console.log('paycheckID---->>>', e.target.id)
            await apiV2.get(`/payroll-document/${e.target.id}?select[0]=files`)
            .then(response => {
                if (response.data.files !== undefined){
                    const downloadLink = document.createElement("a");
                    downloadLink.href = response.data.files[0].base64;
                    downloadLink.download = response.data.files[0].name;
                    downloadLink.click();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo solicitado está iniciando o seu download." });
                }else{
                    swal({ icon: "error", title: "Erro!", text: "O arquivo está corrompido, favor solicitar ao responsável para substituir o mesmo." });
                }
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível baixar o arquivo solicitado!" });
            });
        }
        finally{
            setLoadPaycheck(false);
        }
    }

    // ==> DELETAR PAYCHECK
    const [ , setLoadExcludePaycheck ] = useState(false);

    async function deletePaycheck(paycheckId) {
        swal({
         title: "Atenção",
         text: "Deseja realmente excluir este holerite?",
         icon: "warning",
         buttons: ["Cancelar", "OK"],
         dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoadExcludePaycheck(true);
                try{
                    await apiV2.delete(`/payroll-document/${paycheckId}`)
                    .then(() => {                    
                        loadallPaycheck(id);
                        swal({ icon: "success", title: "Sucesso!", text: "Documento removido com sucesso." });
                    }).catch(() => {
                        swal({ icon: "error", title: "Erro!", text: "Erro ao remover o documento, tente novamente mais tarde." });
                    });
                }finally{
                    setLoadExcludePaycheck(false);
                }
            }
        });
    }

     const [ loadPayroll, setLoadPayroll] = useState([]);

    const [ loadSendAllPaycheck, setLoadSendAllPaycheck ] = useState(false);

    async function sendAllPaycheck() {
        let year  = allPaycheck.year;
        let month = allPaycheck.month;
        let type  = allPaycheck.type;
        let id  = allPaycheck._id;

        console.log('allPaycheck->', allPaycheck._id)

        setLoadSendAllPaycheck(true)
        try{
            api.post('/paycheck-send-email-to-all-collaborator', { id, year, month, type })
            .then(response => {
                swal({ icon: "success", title: "Sucesso!", text: "Holerites enviados com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível enviar os holerites!" });
            });
        }finally{
            setLoadSendAllPaycheck(false);
        }
    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <HeaderG
                    sendAllPaycheck={sendAllPaycheck}
                    loadSendAllPaycheck={loadSendAllPaycheck}
                    id={id}
                    payroll={allPaycheck}
                    loadPayroll={loadPayroll}
                    path={props.match.path}
                />
                <div className="page-payrollCol">
                    <ul>
                        {
                            loadPaycheck ?
                            <div>
                                <Skeleton 
                                    count={1}  
                                    height={60}
                                    borderRadius={4}
                                    style={{marginBottom: "15px"}}
                                />    
                                <Skeleton 
                                    count={1}  
                                    height={60}
                                    borderRadius={4}
                                    style={{marginBottom: "15px"}}
                                />   
                                <Skeleton 
                                    count={1}  
                                    height={60}
                                    borderRadius={4}
                                    style={{marginBottom: "15px"}}
                                />     
                            </div>
                            :
                            allPaycheck.documents && allPaycheck.documents.length !== 0 ?
                            allPaycheck.documents.map((paycheckall, index) => (
                                <li key={index}>
                                <div className="infor-col">
                                    {
                                        paycheckall.collaborator.avatar !== undefined ?
                                        <div className="img-col">
                                            <img src={paycheckall.collaborator.avatar} alt={paycheckall.collaborator.name} />
                                        </div>
                                        :
                                        <div className="colSemFoto">
                                            <div className="semImgCol">
                                                <img  src={IconUserNone} alt={paycheckall.collaborator.name} />
                                            </div>
                                        </div>
                                    }


                                    <div className="nome-setor">
                                        <p className="nome">
                                            {paycheckall.collaborator.name}
                                        </p>
                                        <div className="setor">
                                            <p>{paycheckall.collaborator.occupation}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    (paycheckall.files !== undefined) ?
                                    <div className="eventos" background="blue">
                                        <button className="download-file" onClick={downloadPaycheck} id={paycheckall._id}>Download</button>
                                        <label className="subistitui-holerite">
                                            Editar
                                            <input onChange={updatePaycheck} type="file" name="id"  data-id={paycheckall._id}/>
                                        </label>

                                        <div className="icon-dropdown">
                                            <Dropdown  className="dropdown-icon">
                                                <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <button className="download-file" onClick={downloadPaycheck}id={paycheckall._id}>Download</button>
                                                    <label className="subistitui-holerite-mobile">
                                                        Editar
                                                        <input onChange={updatePaycheck} type="file" name="id" data-id={paycheckall._id}/>
                                                    </label>
                                                    <button onClick={() => deletePaycheck(paycheckall._id)} type="button" name="Excluir" className="red">
                                                        Excluir
                                                    </button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    :
                                    <div onClick={collaboratorIdAddPaycheck} className="evento-enviar-arquivo">
                                        <Dropzone  onDrop={handleDropPaycheck}>
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone-add-holerite" })} >
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label id={paycheckall.collaborator._id} className="imgDocLabel-mobile"></label>

                                                <label id={paycheckall.collaborator._id} className="imgDocLabel"><span></span>Adicionar holerite</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                }
                            </li>
                            )):''
                        }
                    </ul>

                </div>
            </Layout>
        </>
    );
}
