import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/defaultUser';
import { FormataStringData } from '../../../services/utils';
import SubHeader from '../../../components/PerfilUser/subHeader';
import Personal from '../../../components/PerfilUser/Personal';
import InforProfile from '../../../components/PerfilUser/InforProfile';
import { decodeToken } from '../../../services/auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import swal from 'sweetalert';
import '../styles.scss';
import { dateMask, mNumber } from '../../../services/mask';

import api from '../../../services/api';

const ProfileUserPersonal = (props) => {
    const { path } = props.match;

    const decoded = decodeToken();

    var id = decoded.user._id;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPage, setLoadPage] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [collaborator, setCollaborator] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [collaboratorUpdate, setCollaboratorUpdate] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [dateOfBirthUpdate, setDateOfBirthUpdate] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileCertificateSend, setFileCertificateSend] = useState(false);

    //////////////////////////////////////////////////////////////////////////////////
    ////////////////// INFORMAÇÕES PESSOAIS //////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSavedPersonalData, setFilesSavedPersonalData] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadfilessavedPersonalData();
        loadcollaboratoraccess(id);
        loadufestados();
    }, [id]);

    async function loadfilessavedPersonalData() {
        setLoadPersonalData(true);
        await api
            .get(`/collaborator-file-access/?category=identification`)
            .then((response) => {
                setLoadPersonalData(false);
                setFilesSavedPersonalData(response.data);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
    }

    async function loadcollaboratoraccess(id) {
        setLoadPage(true);
        await api
            .get(`/collaborator-access/${id}`)
            .then((response) => {
                setCollaborator(response.data);
                setLoadPage(false);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ufEstados, setUfEstados] = useState([]);

    async function loadufestados() {
        await api
            .get(
                'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
            )
            .then((response) => {
                setUfEstados(response.data);
            })
            .catch((error) => {});
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPersonalData, setLoadPersonalData] = useState(false);

    async function deleteFilePersonaldata(filesaved) {
        swal({
            title: 'Atenção',
            text: 'Deseja excluir este arquivo?',
            icon: 'warning',
            buttons: ['Cancelar', 'OK'],
            dangerMode: false,
        }).then(async (res) => {
            if (res) {
                setFileCertificateSend(true);
                setLoadPersonalData(true);
                await api
                    .delete(
                        `/collaborator-file-access/${filesaved._id}?path=${path}`
                    )
                    .then(() => {
                        async function loadfilessaved() {
                            setLoadPersonalData(false);
                            loadfilessavedPersonalData();
                        }
                        loadfilessaved();
                    })
                    .catch((error) => {});
            }
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNamesPersonalData] = useState([]);

    async function handleDropPersonalData(acceptedFilesPersonalData) {
        setFileNamesPersonalData(
            acceptedFilesPersonalData.map((file) => file.name)
        );
        const typeI = acceptedFilesPersonalData[0].type;
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        if (acceptedFilesPersonalData === undefined) {
            return;
        } else if (
            typeI === 'image/jpg' ||
            typeI === 'image/jpeg' ||
            typeI === 'image/pjpeg' ||
            typeI === 'image/png' ||
            typeI === 'image/gif' ||
            typeI === 'application/pdf' ||
            typeI === 'application/msword' ||
            typeI === 'application/vnd.ms-powerpoint' ||
            typeI ===
                'application/vnd.openxmlformats-officedocument.presentationml.slideshow' ||
            typeI ===
                'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            typeI ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            typeI ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            typeI === 'application/vnd.ms-excel' ||
            typeI === 'text/csv' ||
            typeI === 'application/csv'
        ) {
            formData.append('file', acceptedFilesPersonalData[0]);
            formData.append('name', acceptedFilesPersonalData[0].name);
            formData.append('category', 'identification');
            formData.append('collaborator', id);
            formData.append('path', path);
            setLoadPersonalData(true);
            await api
                .post('/collaborator-file-access/', formData, config)
                .then((response) => {
                    setFileCertificateSend(true);
                    async function loadfilessaved() {
                        loadfilessavedPersonalData();
                    }
                    loadfilessaved();
                })
                .catch((error) => {
                    setLoadPersonalData(false);
                });
        } else {
            swal({
                icon: 'error',
                title: 'Erro!',
                text: 'Arquivo não suportado!',
            });
        }
    }

    //////////////////////////////////////////////////////////////////////////////////
    /////////////////////// FINAL DE INFORMAÇÕES PESSOAIS ////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if (nam === 'zipcode') {
            getAddress(val);
        } else if (nam === 'bankAgency') {
            let value = event.target.value.replace(/[^\d]/g, '');

            if (value.length > 10) {
                setCollaboratorUpdate({ ...collaboratorUpdate, path: path });
            } else {
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    [nam]: value,
                    path: path,
                });
            }
        } else if (nam === 'bankAccount') {
            let bankAccount = mNumber(val);
            setCollaboratorUpdate({
                ...collaboratorUpdate,
                [nam]: bankAccount,
                path: path,
            });
        } else if (nam === 'accountDigit') {
            if (val !== '') {
                let accountDigit = mNumber(val);
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    [nam]: accountDigit,
                    deleteAccountDigit: 0,
                    path: path,
                });
            } else {
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    [nam]: val,
                    deleteAccountDigit: 1,
                    path: path,
                });
            }
        } else if (nam === 'bornState') {
            let estado = val;
            api.get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`
            )
                .then((response) => {
                    let bornStateString = response.data.nome;
                    setCollaboratorUpdate({
                        ...collaboratorUpdate,
                        bornStateString,
                        [nam]: val,
                        path: path,
                    });
                })
                .catch((error) => {});
        } else if (nam === 'bornCity') {
            let cidade = val;

            if (cidade === '350000000') {
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';

                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    bornCityString,
                    [nam]: val,
                    path: path,
                });
            } else if (
                collaboratorUpdate.bornState === '35' &&
                cidade === '350000000'
            ) {
                // alert('são paulo')
                let bornCityString = 'São Paulo';

                let nam = 'bornCity';
                let val = '350000000';

                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    bornCityString,
                    [nam]: val,
                    path: path,
                });
            } else {
                async function loadCity() {
                    await api
                        .get(
                            `https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}?orderBy=nome`
                        )
                        .then((response) => {
                            let bornCityString = response.data[0].nome;
                            setCollaboratorUpdate({
                                ...collaboratorUpdate,
                                bornCityString,
                                [nam]: val,
                                path: path,
                            });
                        })
                        .catch((error) => {});
                }
                loadCity();
            }
        } else if (nam === 'dateOfBirth') {
            let date = dateMask(val);
            if (date.length <= 10) {
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    [nam]: date,
                    path: path,
                });
                if (date.length === 10) {
                    let valDate = FormataStringData(date);
                    //LoadsetdateOfBirth(false)
                    setDateOfBirthUpdate({
                        ...dateOfBirthUpdate,
                        [nam]: valDate,
                        path: path,
                    });
                }
            }
        } else {
            setCollaboratorUpdate({
                ...collaboratorUpdate,
                [nam]: val,
                path: path,
            });
        }
    }
    // console.log(collaboratorUpdate)
    // eslint-disable-next-line no-undef
    async function getAddress(zipcode) {
        zipcode = String(zipcode)
            .normalize('NFD')
            .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        if (zipcode.length >= 8) {
            //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
            const response = await axios.get(
                `https://viacep.com.br/ws/${zipcode}/json/`
            );

            if (response.data !== null) {
                setCollaborator({
                    ...collaborator,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood: response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                    path: path,
                });
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePersonalData, setVisiblePersonalData] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectEthnicity, setSelectEthnicity] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectNationality, setSelectNationality] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectGender, setSelectGender] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectMaritalStatus, setSelectMaritalStatus] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectBornState, setSelectBornState] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectBornCity, setSelectBornCity] = useState(false);

    function MakePersonalDataVisible() {
        setVisiblePersonalData(!visiblePersonalData);
        setCollaboratorUpdate([]);
        setSelectEthnicity(false);
        setSelectNationality(false);
        setSelectGender(false);
        setSelectMaritalStatus(false);
        setSelectBornState(false);
        setSelectBornCity(false);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleBank, setVisibleBank] = useState(false);
    function EditBankDataVisibleBank() {
        setVisibleBank(!visibleBank);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadContinue, setLoadContinue] = useState(false);

    async function save(event) {
        event.preventDefault();
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        if (
            collaboratorUpdate === undefined ||
            collaboratorUpdate.length === 0
        ) {
            if (fileCertificateSend && fileCertificateSend === true) {
                swal({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Dados do Colaborador alterado com sucesso!',
                });
                setFileCertificateSend(false);
                setVisiblePersonalData(!visiblePersonalData);
            } else {
                swal({
                    icon: 'error',
                    title: 'Erro!',
                    text: 'Necessário atualizar pelo menos um campo!',
                });
            }
        } else {
            if (
                collaboratorUpdate.name === '' ||
                collaboratorUpdate.dateOfBirth === '' ||
                collaboratorUpdate.gender === '' ||
                collaboratorUpdate.fatherName === '' ||
                collaboratorUpdate.motherName === '' ||
                collaboratorUpdate.maritalStatus === '' ||
                collaboratorUpdate.nationality === '' ||
                collaboratorUpdate.otherNationality === '' ||
                collaboratorUpdate.ethnicity === '' ||
                collaboratorUpdate.addressNumber === '' ||
                collaboratorUpdate.address === '' ||
                collaboratorUpdate.phone === '' ||
                collaboratorUpdate.cellphone === '' ||
                collaboratorUpdate.zipcode === '' ||
                collaboratorUpdate.addressNeighborhood === '' ||
                collaboratorUpdate.addressCity === '' ||
                collaboratorUpdate.addressState === '' ||
                collaboratorUpdate.addressComplement === ''
            ) {
                swal({
                    icon: 'error',
                    title: 'Erro!',
                    text: 'Nenhum campo pode ser salvo em branco!',
                });
            } else {
                // alert(collaboratorUpdate)

                if (collaboratorUpdate.name !== undefined) {
                    formData.append('name', collaboratorUpdate.name);
                }
                if (collaboratorUpdate.dateOfBirth !== undefined) {
                    //formData.append('dateOfBirth', collaboratorUpdate.dateOfBirth);
                    formData.append(
                        'dateOfBirth',
                        dateOfBirthUpdate.dateOfBirth
                    );
                }
                if (collaboratorUpdate.gender !== undefined) {
                    formData.append('gender', collaboratorUpdate.gender);
                }
                if (collaboratorUpdate.fatherName !== undefined) {
                    formData.append(
                        'fatherName',
                        collaboratorUpdate.fatherName
                    );
                }
                if (collaboratorUpdate.motherName !== undefined) {
                    formData.append(
                        'motherName',
                        collaboratorUpdate.motherName
                    );
                }
                if (collaboratorUpdate.maritalStatus !== undefined) {
                    formData.append(
                        'maritalStatus',
                        collaboratorUpdate.maritalStatus
                    );
                }
                if (collaboratorUpdate.nationality !== undefined) {
                    formData.append(
                        'nationality',
                        collaboratorUpdate.nationality
                    );
                }
                if (collaboratorUpdate.ethnicity !== undefined) {
                    formData.append('ethnicity', collaboratorUpdate.ethnicity);
                }
                if (
                    collaboratorUpdate.bornState !== undefined &&
                    collaboratorUpdate.bornState !== ''
                ) {
                    formData.append('bornState', collaboratorUpdate.bornState);
                    // formData.append('bornStateString', collaboratorUpdate.bornStateString);
                    //
                }
                if (
                    collaboratorUpdate.bornStateString !== undefined &&
                    collaboratorUpdate.bornStateString !== ''
                ) {
                    // formData.append('bornState', collaboratorUpdate.bornState);
                    formData.append(
                        'bornStateString',
                        collaboratorUpdate.bornStateString
                    );
                    //
                }

                if (
                    collaboratorUpdate.bornCity !== undefined &&
                    collaboratorUpdate.bornCity !== ''
                ) {
                    formData.append('bornCity', collaboratorUpdate.bornCity);
                    // formData.append('bornCityString', collaboratorUpdate.bornCityString);
                    //
                }

                if (
                    collaboratorUpdate.bornCityString !== undefined &&
                    collaboratorUpdate.bornCityString !== ''
                ) {
                    // formData.append('bornCity', collaboratorUpdate.bornCity);
                    formData.append(
                        'bornCityString',
                        collaboratorUpdate.bornCityString
                    );
                    //
                }
                if (collaboratorUpdate.addressNumber !== undefined) {
                    formData.append(
                        'addressNumber',
                        collaboratorUpdate.addressNumber
                    );
                }
                if (collaboratorUpdate.address !== undefined) {
                    formData.append('address', collaboratorUpdate.address);
                }
                if (collaboratorUpdate.phone !== undefined) {
                    formData.append('phone', collaboratorUpdate.phone);
                }
                if (collaboratorUpdate.cellphone !== undefined) {
                    formData.append('cellphone', collaboratorUpdate.cellphone);
                }
                if (collaboratorUpdate.zipcode !== undefined) {
                    formData.append('zipcode', collaboratorUpdate.zipcode);
                }
                if (collaboratorUpdate.addressNeighborhood !== undefined) {
                    formData.append(
                        'addressNeighborhood',
                        collaboratorUpdate.addressNeighborhood
                    );
                }
                if (collaboratorUpdate.addressCity !== undefined) {
                    formData.append(
                        'addressCity',
                        collaboratorUpdate.addressCity
                    );
                }
                if (collaboratorUpdate.addressState !== undefined) {
                    formData.append(
                        'addressState',
                        collaboratorUpdate.addressState
                    );
                }
                if (collaboratorUpdate.addressComplement !== undefined) {
                    formData.append(
                        'addressComplement',
                        collaboratorUpdate.addressComplement
                    );
                }
                if (collaboratorUpdate.bankCode !== undefined) {
                    formData.append('bankCode', collaboratorUpdate.bankCode);
                }
                if (collaboratorUpdate.bankName !== undefined) {
                    formData.append('bankName', collaboratorUpdate.bankName);
                }
                if (collaboratorUpdate.bankAgency !== undefined) {
                    formData.append(
                        'bankAgency',
                        collaboratorUpdate.bankAgency
                    );
                }
                if (collaboratorUpdate.bankAccount !== undefined) {
                    formData.append(
                        'bankAccount',
                        collaboratorUpdate.bankAccount
                    );
                }
                if (
                    collaboratorUpdate.accountDigit !== undefined &&
                    collaboratorUpdate.accountDigit !== ''
                ) {
                    formData.append(
                        'accountDigit',
                        collaboratorUpdate.accountDigit
                    );
                }
                if (
                    collaboratorUpdate.deleteAccountDigit !== undefined &&
                    collaboratorUpdate.deleteAccountDigit === 1
                ) {
                    formData.append(
                        'deleteAccountDigit',
                        collaboratorUpdate.deleteAccountDigit
                    );
                }
                if (collaboratorUpdate.AccountType !== undefined) {
                    formData.append(
                        'AccountType',
                        collaboratorUpdate.AccountType
                    );
                }
                if (collaboratorUpdate.pix !== undefined) {
                    formData.append('pix', collaboratorUpdate.pix);
                }
                formData.append('path', path);
                // console.log(collaboratorUpdate)
                setLoadContinue(true);
                await api
                    .put(`/collaborator-access/${id}`, formData, config)
                    .then((response) => {
                        setLoadContinue(false);
                        if (visiblePersonalData === true) {
                            setVisiblePersonalData(!visiblePersonalData);
                        }
                        if (visibleBank === true) {
                            setVisibleBank(!visibleBank);
                        }

                        setCollaboratorUpdate([]);
                        async function loadcollaborator() {
                            await api
                                .get(`/collaborator-access/${id}`)
                                .then((response) => {
                                    setCollaborator(response.data);
                                })
                                .catch((error) => {});
                        }
                        loadcollaborator();

                        swal({
                            icon: 'success',
                            title: 'Sucesso!',
                            text: 'Dados do Colaborador alterado com sucesso!',
                        });
                        //window.location.href = `/colaboradores/nova-admissao/formulario-documentos/${id}`;
                    })
                    .catch((error) => {
                        swal({
                            icon: 'error',
                            title: 'Erro!',
                            text: error.response.data.message,
                        });
                        setLoadContinue(false);
                        // console.log("error.response")
                        // console.log(error.response)
                    });
            }
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [openSelAccountType, setOpenSelAccountType] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [abrirResultBank, setAbrirResultBank] = useState(false);

    function mySearchBank(event) {
        // Declare variables
        event.preventDefault();

        let nam = event.target.name;
        let val = event.target.value;

        setAbrirResultBank(true);
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('myBank');
        filter = input.value.toUpperCase();
        ul = document.getElementById('resultadoBusca');
        if (ul !== null) {
            li = ul.getElementsByTagName('button');
            // Loop through all list items, and hide those who don't match the search query
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName('p')[0];
                if (a && a !== null) {
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = '';
                    } else {
                        li[i].style.display = 'none';
                    }
                }
            }
            setCollaboratorUpdate({
                ...collaboratorUpdate,
                [nam]: val,
                path: path,
            });
            return;
        }
    }

    function abrirResultado() {
        setAbrirResultBank(!abrirResultBank);
        setOpenSelAccountType(false);
    }

    async function myChangeHandlerToSaveBank(event) {
        setCollaboratorUpdate({
            ...collaboratorUpdate,
            bankName: event,
            path: path,
        });
        setAbrirResultBank(false);
    }

    async function openAccountType(e) {
        if (
            e.target.className !== 'btn-select selectAccountType' &&
            e.target.id !== 'myBank'
        ) {
            setOpenSelAccountType(false);
            setAbrirResultBank(false);
        }
    }

    async function selectAccountTypeOpen(e) {
        setOpenSelAccountType(!openSelAccountType);
    }
    async function myChangeHandlerAccountType(event) {
        let nam = event;
        setCollaboratorUpdate({
            ...collaboratorUpdate,
            AccountType: nam,
            path: path,
        });
        setOpenSelAccountType(!openSelAccountType);
    }
    return (
        <>
            <Layout path={path}>
                <div className="profile-user">
                    <InforProfile
                        collaborator={collaborator}
                        id={id}
                        setCollaborator={setCollaborator}
                    />
                    <SubHeader id={id} collaborator={collaborator} />
                    <Personal
                        id={id}
                        path={path}
                        loadPage={loadPage}
                        MakePersonalDataVisible={MakePersonalDataVisible}
                        visiblePersonalData={visiblePersonalData}
                        EditBankDataVisibleBank={EditBankDataVisibleBank}
                        visibleBank={visibleBank}
                        collaborator={collaborator}
                        setCollaborator={setCollaborator}
                        collaboratorUpdate={collaboratorUpdate}
                        setCollaboratorUpdate={setCollaboratorUpdate}
                        onCollaboratorInputChange={myChangeHandler}
                        ufEstados={ufEstados}
                        ufDistrito={
                            collaboratorUpdate && collaboratorUpdate.bornState
                                ? collaboratorUpdate.bornState
                                : collaborator && collaborator.bornState
                                ? collaborator.bornState
                                : ''
                        }
                        ufCity={
                            collaborator && collaborator.bornCity
                                ? collaborator.bornCity
                                : collaboratorUpdate &&
                                  collaboratorUpdate.bornCity
                                ? collaboratorUpdate.bornCity
                                : ''
                        }
                        OnSubmit={save}
                        loadContinue={loadContinue}
                        filesSavedPersonalData={filesSavedPersonalData}
                        loadPersonalData={loadPersonalData}
                        deleteFilePersonaldata={deleteFilePersonaldata}
                        handleDropPersonalData={handleDropPersonalData}
                        mySearchBank={mySearchBank}
                        abrirResultado={abrirResultado}
                        abrirResultBank={abrirResultBank}
                        myChangeHandlerToSaveBank={myChangeHandlerToSaveBank}
                        openAccountType={openAccountType}
                        selectAccountTypeOpen={selectAccountTypeOpen}
                        openSelAccountType={openSelAccountType}
                        myChangeHandlerAccountType={myChangeHandlerAccountType}
                        setSelectEthnicity={setSelectEthnicity}
                        setSelectNationality={setSelectNationality}
                        setSelectGender={setSelectGender}
                        setSelectMaritalStatus={setSelectMaritalStatus}
                        setSelectBornState={setSelectBornState}
                        setSelectBornCity={setSelectBornCity}
                        selectEthnicity={selectEthnicity}
                        selectNationality={selectNationality}
                        selectGender={selectGender}
                        selectMaritalStatus={selectMaritalStatus}
                        selectBornState={selectBornState}
                        selectBornCity={selectBornCity}
                    />
                </div>
            </Layout>
        </>
    );
};
export default ProfileUserPersonal;
