import React from 'react';
// import { NavLink } from 'react-router-dom'
// import ImgExemplo from '../../../../assets/images/alvarobraz.png';
import Carregando from '../../../../assets/images/carregando.gif';
import IllustrationPraia from '../../../../assets/images/illustration-praia.svg';
import { dateFormatedToStripe } from '../../../../services/utils'

export default function HistoricoFerias({
    loadHistoryVacations,
    collectiveVacations,
    nextPageCollective,
    prevPageCollective,
    pageCollective,
    pagesCollective,
    paginationPageCollective
}) {

    return (
        <>

            <div className="lista-historico">
            <ul className="lista">
                {
                    loadHistoryVacations ? (
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    ) :
                    collectiveVacations.length === 0 ?
                    <>
                        <div className="lista-vazia">
                            <p>Essa praia está deserta. <br/>
                                Nenhum histórico de férias.</p>
                                <img src={IllustrationPraia} alt="illustração praia" />
                        </div>
                    </>
                    :
                    collectiveVacations ?
                    collectiveVacations
                    .map((vacationCollective, index) => (
                        <li key={index}>
                        <div className="box-ferias-coletiva">
                            <h4 className="titulo-box">{vacationCollective.nameVacation}</h4>
                            <div className="infor">
                                <p className="data">De {dateFormatedToStripe(vacationCollective.startDate)} à {dateFormatedToStripe(vacationCollective.endDate)} ({vacationCollective.balance} dias)</p>
                                {
                                    vacationCollective?.nameSector ?
                                    <p className="grupo">Departamento: {vacationCollective.nameSector}</p>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                    </li>
                    )) :
                    ''
                }
            </ul>

                    {/* {
                    collectiveVacations ? (
                        <div className="paginacaoG">
                            <ul>
                            {
                                pagesCollective && pagesCollective !== undefined && pagesCollective > 1 ?
                                numberPaginate
                                :
                                ''
                            }
                            {
                                pagesCollective && pagesCollective !== undefined && pagesCollective > 1 ?
                                <>
                                <li className="page-anterior">
                                    <button tyle={(pageCollective === 1) ? { cursor: "not-allowed" } : {}}  disabled={pageCollective === 1} onClick={(event) => prevPageCollective(event)} >anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(pageCollective === pagesCollective) ? { cursor: "not-allowed" } : {}}  disabled={pageCollective === pagesCollective} onClick={(event) => nextPageCollective(event)} >próxima</button>
                                </li>
                                </>
                                :
                                ''
                            }
                            </ul>
                        </div>
                    ) :
                    ("")
                    } */}


            </div>
        </>
    );
}
