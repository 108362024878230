import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const SendAccounting = ({
    id ,
    sendToAccounting,
    collaborator,
    myChangeHandlerSendToAccounting,
    sendemailtoaccounting,
    loadButtomSendEmailToAccounting,
    loadErrorSendEmailToAccounting,
    sendToAccountingSelect,
    emailSent
}) => {

return (
    <>
       <div className="enviar-contabilidade">
            <h2 className="titulo">Enviar para contabilidade</h2>
            <p  className="subTitulo">
            Você pode enviar os dados do colaborador diretamente para a contabilidade.
            </p>
            <div   className="seleciona-contador">

                <p className="textCampo textoUm">Contador</p>
                    <div className='cont-select inputUm'>
                        <button className={loadErrorSendEmailToAccounting ? 'select- inputErro' : 'select-'}>
                            <p>
                                {
                                    sendToAccountingSelect && sendToAccountingSelect.contador ?
                                        
                                        sendToAccounting && sendToAccounting
                                        .map((intem, index) => (
                                            sendToAccountingSelect.contador === intem._id ?
                                                intem.name
                                            :
                                            ""
                                        ))
                                    :
                                        'Selecione'
                                }
                            </p>
                        </button>

                        <div className="dropDown">
                            <Scrollbars className='listDropDown'   
                                style={{  height:  
                                    sendToAccounting.length === 0 ? 35 : "" ||
                                    sendToAccounting.length === 1 ? 35 : ""  ||
                                    sendToAccounting.length === 2 ? 70 : ""  ||
                                    sendToAccounting.length === 3 ? 120 : 120 }
                                }
                            >
                                {   
                                   sendToAccounting.length !== 0 ?
                                        sendToAccounting && sendToAccounting
                                        .map((intem, index) => (
                                            <button  key={index} name="contador" value={intem._id}  onClick={myChangeHandlerSendToAccounting} className='item'>
                                                {intem.name}
                                            </button>
                                        ))
                                    :
                                    <button className='item'><p >Nenhum contador</p></button>
                                } 
                                </Scrollbars>
                        </div>
                    </div>


                <select onChange={myChangeHandlerSendToAccounting} className={loadErrorSendEmailToAccounting ? "inputUm inputErro" : "inputUm" }>
                    {
                        sendToAccountingSelect.contador !== undefined ?
                        <>
                            <option value="Selecione">Selecione</option>
                            {
                                sendToAccounting && sendToAccounting.map((item, index) => (
                                    <option value={item._id} key={index}>{item.name}</option>
                                ))
                            }
                        
                        </>:<>
                            <option value="Selecione">Selecione</option>
                            {
                            sendToAccounting && sendToAccounting.map((item, index) => (
                                <option value={item._id} key={index}>{item.name}</option>
                            ))
                            }

                        </>


                    }
                </select>

                {
                    loadErrorSendEmailToAccounting ?
                    <p className="inputUm textError">'É necessário selecionar o contador responsavel'</p>
                    :
                    ''
                }
                <button onClick={sendemailtoaccounting} disabled={loadButtomSendEmailToAccounting ? true : false} className={loadButtomSendEmailToAccounting ? "btnGreen load inputDois" : "btnGreen inputDois"}>Enviar para contabilidade</button>
            </div>

            {
                emailSent ? <><p className='enviado'>E-mail enviado para a contabilidade e informações para desligamento salvas com sucesso!</p></>:""
            }
       </div>

    </>
);
}
export default SendAccounting;
