import React, { useState, useEffect } from 'react';
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import api from '../../../../../../services/api';
import Close from '../../../../../../assets/images/close.svg';
import Carregando from '../../../../../../assets/images/carregando.gif';
import { Modal} from "react-bootstrap";
import { toast } from 'react-toastify';


const ColProfileContact = ({
    id,
    loadPage,
    collaborator,
    onCollaboratorInputChange,
    saveContact,
    loadContinue,
    PopupContactVisible,
    collaboratorUpdate,
    visibleContact,
    Loadphone               ,
    Loadcellphone           ,
    Loademail,
    role,
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) {
            loadfilessaved(id);
        }

    }, [id]);

    async function loadfilessaved(id) {

        await api.get(`/collaborator-file/?id=${id}&category=location`)
        .then(response => {

            setFilesSaved(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    return (
        <>

            <div className="box contato">
                <h3>
                    Contatos
                    {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                        ""
                        :
                        role && (role === 'admin' || role === 'master' )  ? <button className="edit-Benefit" onClick={() => PopupContactVisible()}><img src={EditBlack} alt="Edite Informações" /></button> : ''
                    }
                </h3>
                {
                    loadPage ?
                        (
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregando} />
                            </div>
                            </>
                        ):(
                            <>

                <ul>
                    {
                        role && (role === 'admin' || role === 'master' ) ?
                        <li>
                            <div className="intemA">
                                <p>Telefone</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator && collaborator.phone ? collaborator.phone : '-'}</p>
                            </div>
                        </li>
                        :
                        ''
                    }
                    <li>
                        <div className="intemA">
                            <p>Celular</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator && collaborator.cellphone ? collaborator.cellphone : '-'}</p>
                        </div>
                    </li>
                    {
                        role && (role === 'admin' || role === 'master' )  ?
                        <li>
                            <div className="intemA">
                                <p>E-mail pessoal</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator && collaborator.personalEmail ? collaborator.personalEmail : '-'}</p>
                            </div>
                        </li>
                        :
                        ''
                    }
                </ul>
                </> )}
            </div>

                <Modal show={visibleContact} className="popup-edit-perfil" onHide={PopupContactVisible} >
                <div>
                    <div className="titulo">
                        <h4>Contatos <p className="btn-fechar" onClick={PopupContactVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                            <p>Telefone</p>
                            </div>
                            <div className="intemB">
                            {
                                (collaboratorUpdate && collaboratorUpdate.phone !== undefined) || collaboratorUpdate.phone === '' ?
                                (
                                    <input type="text" name="phone" autocomplete="off" value={(collaboratorUpdate.phone !== undefined) ? collaboratorUpdate.phone : ''}  onChange={onCollaboratorInputChange} placeholder="Telefone"  />
                                ) :
                                collaborator && collaborator.phone ?(
                                    <input type="text" name="phone" autocomplete="off" defaultValue={(collaborator.phone !== undefined) ? collaborator.phone : ''}  onChange={onCollaboratorInputChange} placeholder="Telefone" />
                                ):
                                (
                                    <input type="text" name="phone" autocomplete="off" value={(collaboratorUpdate.phone !== undefined) ? collaboratorUpdate.phone : ''}  onChange={onCollaboratorInputChange} placeholder="Telefone" />
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            <p>Celular *</p>
                            </div>
                            <div className="intemB">
                                {
                                    (collaboratorUpdate && collaboratorUpdate.cellphone !== undefined) || collaboratorUpdate.cellphone === '' ?
                                    (
                                        <input type="text" name="cellphone" autocomplete="off" value={(collaboratorUpdate.cellphone !== undefined) ? collaboratorUpdate.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular"  className={Loadcellphone ? 'inputErro' : ''}/>
                                    ) :
                                    collaborator && collaborator.cellphone && collaborator.cellphone !== ''? (
                                        <input type="text" name="cellphone" autocomplete="off" defaultValue={(collaborator.cellphone !== undefined) ? collaborator.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputErro' : ''}/>
                                    ):
                                    (
                                        <input type="text" name="cellphone" autocomplete="off" value={(collaboratorUpdate.cellphone !== undefined) ? collaboratorUpdate.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular"  className={Loadcellphone ? 'inputErro' : ''}/>
                                    )}


                                    {Loadcellphone ? (
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                        ) : ('')
                                    }
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            <p>E-mail pessoal*</p>
                            </div>
                            <div className="intemB">
                            <input
                                type="personalEmail"
                                name="personalEmail"
                                autocomplete="off"
                                onChange={onCollaboratorInputChange}
                                value={
                                    collaboratorUpdate?.personalEmail ||
                                    collaboratorUpdate?.personalEmail === '' ?
                                    collaboratorUpdate?.personalEmail
                                    :
                                    collaborator?.personalEmail ?
                                    collaborator?.personalEmail
                                    :
                                    ''
                                    }
                                placeholder="E-mail"
                                className={Loademail ? 'inputErro' : ''}
                            />
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={saveContact} className="btn-salvar">
                                    <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>
                    </ul>

                </div>
            </Modal>
        </>
    );
}
export default ColProfileContact;
