import React from 'react';
import IconsInformation from './IconsInformation.js';
import { decodeToken } from '../../services/auth';

export default function headerColaboradores(path) {

    const decoded = decodeToken();
    var nome = decoded.user.name;
    var nome_1 = nome.split(' ')[0];

    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();



    return (
        <>
            <header className="header-user">

                <div className="cabeçalho">

                    {
                        path?.path === "/dashboard-user" ?
                        <>
                            <h1>Boa tarde, {nome_1 }</h1>
                            <p className="data">Hoje é {dia} de {
                                mes  === '01'    ? 'Janeiro' :
                                mes  === '02'    ? 'Fevereiro' :
                                mes  === '03'    ? 'Março' :
                                mes  === '04'    ? 'Abril' :
                                mes  === '05'    ? 'Maio' :
                                mes  === '06'    ? 'Junho' :
                                mes  === '07'    ? 'Julho' :
                                mes  === '08'    ? 'Agosto' :
                                mes  === '09'    ? 'Setembro' :
                                mes  === '10'    ? 'Outubro' :
                                mes  === '11'   ? 'Novembro' :
                                mes  === '12'   ? 'Dezembro'  : ''
                            }  de {ano}
                            </p>
                        </> :""
                    }
                    {
                        path?.path === "/minhas-habilidades" ?
                        <>
                        <h1>Minhas habilidades</h1>
                        </>:""
                    }
                    {
                        path?.path === "/minhas-ferias" ?
                        <>
                        <h1>Minhas férias</h1>
                        </>:""
                    }
                    {
                        path?.path === '/meus-beneficios'?
                        <>
                        <h1>Meus benefícios</h1>
                        </>:""
                    }
                    {
                        path?.path === '/contato-com-rh'?
                        <>
                        <h1>Contato</h1>
                        </>:""
                    }
                    {
                        path?.path === '/configuracao/alterar-senha'?
                        <>
                        <h1>Configurações</h1>
                        </>:""
                    }
                    {
                        path?.path === '/mural-de-elogios'?
                        <>
                        <h1>Mural de elogios</h1>
                        </>:""
                    }
                    {
                        path?.path === '/meus-dados/anotacoes' ||
                        path?.path === '/meus-dados/historico-salario-e-promocoes' ||
                        path?.path === '/meus-dados/historico-ultimas-alteracoes' ||
                        path?.path === '/meus-dados/profissional/jornada-de-trabalho' ||
                        path?.path === '/meus-dados/profissional/folha-de-pagamento' ||
                        path?.path === '/meus-dados/profissional/exame-periotico' ||
                        path?.path === '/meus-dados/profissional/termos-e-contratos' ||
                        path?.path === '/meus-dados/profissional' ||
                        path?.path === '/meus-dados/pessoal/faltas-e-afastamentos' ||
                        path?.path === '/meus-dados/pessoal/documentos' ||
                        path?.path === '/meus-dados/pessoal/enderecos-contatos' ||
                        path?.path === '/meus-dados/pessoal/'?
                        <>
                        <h1>Meus Dados</h1>
                        </>:""
                    }

                    {
                        path?.path === "/meus-pontos" ?
                        <>
                        <div className='ponto'>
                            <h1>Ponto</h1>
                            {/* <p>Atualizado pela última vez em {showDateToShowLastActualization(lastActualizaction)}</p> */}
                        </div>
                        </>
                        :""
                    }
                </div>
                <div className="infor">
                    <IconsInformation />
                </div>
            </header>
        </>
    );
}
