import React from 'react';
// import Carregamento from '../../../../assets/images/carregando.gif';

export default function pageSystemConfigAddPaymentMethod({
    props,
    path,

    ExchangePlan,
    planSelected,
    signatureSelected,
    myChangeHandlerPlan,
    paymentMethod,

    loadCardHolderName,
    document_number,
    loadCardNumber,
    loadCardExpirationDate,
    loadCardCVC,
    loadName,
    loadEmail,
    loadZipcode,
    loadNeighborhood,
    loadStreet,
    loadStreetNumber,
    loadTelefone,
    loadComplementary,

    savePlanCompany,
    loadButtonSavePlan,
}) {

    // console.log('paymentMethod')
    // console.log(paymentMethod)

    return (
        <>
        <div className='addEdit-metodo-pagamento'>
            <h3  className="sub-titulo">Insira os detalhes do seu cartão de crédito</h3>
            <button className='voltar' onClick={ExchangePlan}>Voltar para lista</button>
            <div className='form-infor'>
            <div className='infor-plano-seleionado'>
                    <div>
                        <h3>Plano selecionado</h3>
                        <p className='nome-plano'>{planSelected?.name}</p>
                    </div>
                    <div>
                        <h3>Valor</h3>
                        <p className='valor'>
                            R$ {planSelected?.amount}
                            <span className='porMes'>Por mês</span>
                        </p>
                    </div>
                </div>
                <div className="form-addEdit one">
                    <ul>
                        <li>
                            <div className="texto"><p>Nome do titular *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.card_holder_name ? paymentMethod?.card_holder_name : ''}
                                type="text"
                                name="card_holder_name"
                                placeholder='Nome do titular'
                                className={loadCardHolderName ? 'inputErro' : ''}
                                />
                                {loadCardHolderName ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Email *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.email ? paymentMethod?.email : ''}
                                type="text" name="email"
                                placeholder='E-mail'
                                className={loadEmail ? 'inputErro' : ''}
                                />
                                {loadEmail ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>CPF ou CNPJ *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.document_number ? paymentMethod?.document_number : ''}
                                type="text" name="document_number"
                                placeholder='CPF ou CNPJ'
                                className={document_number ? 'inputErro' : ''}
                                />
                                {document_number ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Telefone *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.phone ? paymentMethod?.phone : ''}
                                type="text" name="phone"
                                placeholder='Telefone'
                                className={loadTelefone ? 'inputErro' : ''}
                                />
                                {loadTelefone ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Número do cartão *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.card_number ? paymentMethod?.card_number : ''}
                                type="text" name="card_number"
                                placeholder='Número do cartão'
                                className={loadCardNumber ? 'inputErro' : ''}
                                />
                                {loadCardNumber ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Data de validade *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.card_expiration_date ? paymentMethod?.card_expiration_date : ''}
                                type="text" name="card_expiration_date"
                                placeholder='Data de validade'
                                className={loadCardExpirationDate ? 'inputErro' : ''}
                                />
                                {loadCardExpirationDate ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Código de segurança *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.card_cvv ? paymentMethod?.card_cvv : ''}
                                type="text" name="card_cvv"
                                placeholder='CVV'
                                className={loadCardCVC ? 'inputErro' : ''}
                                />
                                {loadCardCVC ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="form-addEdit twon">
                    <h5>Dados de endereço de cobrança do cartão:</h5>
                    <ul>
                        <li>
                            <div className="texto"><p>CEP *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.zipcode ? paymentMethod?.zipcode : ''}
                                type="text" name="zipcode"
                                placeholder='CEP'
                                className={loadZipcode ? 'inputErro' : ''}
                                />
                                {loadZipcode ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Bairro *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.neighborhood ? paymentMethod?.neighborhood : ''}
                                type="text" name="neighborhood"
                                placeholder='Bairro'
                                className={loadNeighborhood ? 'inputErro' : ''}
                                />
                                {loadNeighborhood ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Rua *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.street ? paymentMethod?.street : ''}
                                type="text" name="street"
                                placeholder='Rua'
                                className={loadStreet ? 'inputErro' : ''}
                                />
                                {loadStreet ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Numero *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.street_number ? paymentMethod?.street_number : ''}
                                type="text" name="street_number"
                                placeholder='Numero'
                                className={loadStreetNumber ? 'inputErro' : ''}
                                />
                                {loadStreetNumber ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Complemento</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerPlan}
                                value={paymentMethod?.complementary ? paymentMethod?.complementary : ''}
                                type="text" name="complementary"
                                placeholder='Complemento'
                                className={loadComplementary ? 'inputErro' : ''}
                                />
                                {loadComplementary ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"></div>
                            <div className="intemB">
                                <button onClick={() => savePlanCompany()} className={loadButtonSavePlan ? "btnGreen load" : "btnGreen"}>Assinar People View</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    );
}
