import React from 'react';

import SelectPersonalizado from '../../../../Reused/selectPersonalizado'

export default function formPersonalData({
    id,
    loadEmergencyContact,
    registeredEmergencyContact,
    loadEmergencyCellphone,
    loademErgencyName,
    loadRelationshipEmergencyContact,
    onCollaboratorInputChangeEmergencyContact,
    handleSubmitEmergencyContact,
    handleDeleteEmergencyContact,
    emergencyContact,
    focusBottomEmergencyContact,
    loadBottomEmergencyContact,
    focusEmergencyName,
    focusRelationshipEmergency,
    focusEmergencyCellphone
}) {

const  optionRelationshipEmergencyContact = [
    "Cônjuge", "Companheiro(a) ou união estável com filhos", "Companheiro(a) ou união estável sem filhos", "Filho ou enteado até 21 anos", "Filho ou enteado universitário ou em escola técnica", "Irmão(ã), neto(a) ou bisneto(a) com guarda", "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica", "Pais, avós e bisavós", "Incapaz", "Agregado/Outros", "Ex cônjuge que recebe pensão de alimentos"
]

    return (
        <>
            <div className="emergency-contact">
                    <h3>Contato de emergência</h3>
                    <div>
                    <div className="box-grid">
                        <div className='linha'>
                            <div className='box'>
                                <h5>Contato</h5>
                                <div></div>
                            </div>
                            <div className='box'></div>
                        </div>

                        <div className='linha'>
                            <div className='box'>
                                <p className="textCampo">Nome completo</p>
                                <div>
                                    <input ref={focusEmergencyName} type="text" name="emergencyName"  autocomplete="autocompleteoff" value={emergencyContact && emergencyContact.emergencyName ? emergencyContact.emergencyName : ''} onChange={onCollaboratorInputChangeEmergencyContact} placeholder="Nome completo" className={loademErgencyName ? 'inputUm inputErro' : 'inputUm'}/>
                                    <p className="inputUm textError">{loademErgencyName ? 'O campo é obrigatório.' : ''}</p>
                                </div>
                            </div>
                            <div className='box'>
                                <p className="textCampo">Parentesco</p>
                                <div>

                                    <SelectPersonalizado
                                        valorCampo={
                                            emergencyContact && emergencyContact.relationship ?
                                                emergencyContact.relationship 
                                                :
                                                ''
                                        }
                                        nameButton={"relationship"}
                                        myChangeHandler={onCollaboratorInputChangeEmergencyContact}
                                        // option={optionRelationshipEmergencyContact}
                                        loadError={loadRelationshipEmergencyContact}
                                        focusRef={focusRelationshipEmergency}
                                    
                                        optionFil={optionRelationshipEmergencyContact}
                                        typeSelect="busca2"
                                    />

                                    <p className="inputDois textError">{loadRelationshipEmergencyContact ? 'O campo é obrigatório.' : ''}</p>

                                </div>
                            </div>
                        </div>


                        <div className='linha'>
                            <div className='box'>
                                <p className="textCampo">Celular</p>
                                <div>
                                    <input ref={focusEmergencyCellphone}  autocomplete="autocompleteoff" value={emergencyContact && emergencyContact.emergencyCellphone ? emergencyContact.emergencyCellphone : ''} type="tel" name="emergencyCellphone" onChange={onCollaboratorInputChangeEmergencyContact} placeholder="Celular" className={loadEmergencyCellphone ? 'inputTreis inputErro' : 'inputTreis'}/>
                                    <p className="inputTreis textError">{loadEmergencyCellphone ? 'O campo é obrigatório.' : ''}</p>
                                </div>
                            </div>
                            <div className='box'>
                                <p className="textCampo">Email</p>
                                <div>
                                    <input type="email" name="emergencyEmail" autocomplete="autocompleteoff" value={emergencyContact && emergencyContact.emergencyEmail ? emergencyContact.emergencyEmail : ''} onChange={onCollaboratorInputChangeEmergencyContact} placeholder="Email" className="inputQuatro"/>
                                </div>
                            </div>
                        </div>

                        <div className='linha'>
                            <div className='box doc-dropzone'>
                                <p className="textCampo"></p>
                                <div>

                                        <button ref={focusBottomEmergencyContact} type="submit" onClick={handleSubmitEmergencyContact} className={loadEmergencyContact ? 'inputCinco btnGreen btnGreenLoad btn-adicionar' : 'inputCinco btnGreen btn-adicionar'}>{loadEmergencyContact ? 'Adicionando' : 'Adicionar contato'}</button>
                                        {loadBottomEmergencyContact ? (
                                        <p className="textError inputCinco">O campo é obrigatório.</p>
                                        ) : ('')
                                        }

                                </div>
                            </div>
                            <div className='box'>
                            </div>
                        </div>



                    </div>
                    </div>
                    <div className="contato-enviados">
                        <h5>Cadastrados</h5>
                        {registeredEmergencyContact[0] !== undefined ? registeredEmergencyContact
                            .map((emergencycontact, index) => (
                        <div className="contato">
                            <div className="nome-contato">
                                <p>{emergencycontact.emergencyName}</p>
                            </div>
                            <div className="numero-contato">
                                <span>{emergencycontact.emergencyCellphone}</span>
                            </div>
                            <div className="btnExclui">
                            <button onClick={() => handleDeleteEmergencyContact(emergencycontact._id)}>Remover</button>
                            </div>
                        </div>
                        )): (
                        <div className="nenhum-depentende">
                            <p>Nenhum contato de emergência cadastrado.</p>
                        </div>
                        )}
                    </div>

                </div>


        </>
    );
}
