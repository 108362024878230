import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import iconVerMais from '../../../../../assets/images/more.svg';
import Carregamento from '../../../../../assets/images/carregando.gif';
import iconUpload from '../../../../../assets/images/docs-upload.svg';
import Close from '../../../../../assets/images/close.svg';
import { Modal, Dropdown } from "react-bootstrap";
import Dropzone from "react-dropzone";
import api from '../../../../../services/api';
import swal from 'sweetalert';
import { mNumber } from '../../../../../services/mask';
import { decodeToken } from '../../../../../services/auth';
import { Scrollbars } from 'react-custom-scrollbars';
import SelectPersonalizado from '../../../../../components/Reused/selectPersonalizado'
import { toast } from 'react-toastify';

const ColProfileProssional = ({ id, collaboratorState, path })  => {

    const { user } = decodeToken();
    const role     = user.role;

    const [ loadMonth, setLoadMonth ] = useState(false);

    const [ loadYear, setLoadYear ] = useState(false);

    const [ loadType, setLoadType ] = useState(false);

    const [ load, setLoad ] = useState(false);

    const [ loadFileForm, setLoadFileForm ] = useState(false);

    const [ loadArchive, setLoadArchive ] = useState(false);

    const [ loadPage, setLoadPage ] = useState(false);

    const [ registeredReceiptsAndPayments, setRegisteredReceiptsAndPayments ] = useState([]);

    const [ receiptsAndPayments, setReceiptsAndPayments ] = useState([]);

    const [ receiptsAndPaymentsUpdated, setReceiptsAndPaymentsUpdated ] = useState([]);

    useEffect(() => {

       if (id && id) {loadreceiptsandpayments(id);}

    }, [id]);

    async function loadreceiptsandpayments(id) {

        setLoadPage(true)
        await api.get(`/collaborator-receipts-and-payments/?id=${id}`)
        .then(response => {

            setLoadPage(false);
            setLoad(false);
            setFileNames(false);
            setFilterReceiptsAndPayments([]);

            if(response.data[0] !== undefined) {
                setRegisteredReceiptsAndPayments(response.data);
            }
            else {
                setRegisteredReceiptsAndPayments([]);
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    const [ filterReceiptsAndPayments, setFilterReceiptsAndPayments ] = useState([]);

    async function loadfilterreceiptsandpayments(event) {
        const name = event.target.value;

        setLoad(true)
        await api.get(`/collaborator-receipts-and-payments?id=${id}&name=${name}`)
        .then(response => {

            setLoad(false)
            setFilterReceiptsAndPayments(response.data);

        }).catch(error => {
            setLoad(false)
        });

    }


    //////////////////////////////////////////////////////////////////////
    ///////////////////////      Popup      /////////////////////////////
    const [visible, setVisible] = useState(false);
    function AddAndEditPlayrollVisible(e) {

        if(e !== undefined) {

            let receiptsAndPayments = e;
            setReceiptsAndPaymentsUpdated({
                ...receiptsAndPayments
            })

        } else {

            setReceiptsAndPaymentsUpdated([])
        }

        setVisible(!visible);
        setLoadMonth(false)
        setLoadYear(false)
        setLoadType(false)
        setLoadFileForm(false)
        setFileNames(false)
    }

    function AddPlayrollVisible() {
        setReceiptsAndPayments([])
        setReceiptsAndPaymentsUpdated([])
        setVisible(!visible);
        setLoadMonth(false)
        setLoadYear(false)
        setLoadType(false)
        setLoadFileForm(false)
        setFileNames(false)
    }
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    const [fileNames, setFileNames] = useState([]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setLoadArchive(true)
            setFileNames(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setLoadFileForm(false)
            setReceiptsAndPayments({ ...receiptsAndPayments, file })
            setLoadArchive(false)
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    async function deleteFiles() {
        setFileNames();
        let file = undefined
        setLoadFileForm(true)
        setReceiptsAndPayments({ ...receiptsAndPayments, file })
    }



    async function deleteReceiptsAndPayments(receiptsAndPayments) {

        swal({
            title: "Atenção",
            text: "Deseja excluir este item?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-receipts-and-payments/${receiptsAndPayments}?path=${path}`)
                .then(() => {

                    loadreceiptsandpayments(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Recibos de pagamentos removido com sucesso." });

                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover recibos de pagamentos, tente novamente mais tarde." });
                });
            }
        });
    }


    let collaborator = collaboratorState._id;

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        // month
        if (nam === "month") {
            setReceiptsAndPayments({ ...receiptsAndPayments, collaborator, [nam]: val, path: path })
            setLoadMonth(false)

        }
        // year
        else
        if (nam === "year") {
            setLoadYear(false)
            let year = mNumber(val)
            setReceiptsAndPayments({ ...receiptsAndPayments, collaborator, [nam]: year })

        }
        // type
        else
        if (nam === "type") {

            setLoadType(false)
            setReceiptsAndPayments({ ...receiptsAndPayments, collaborator, [nam]: val })

        }
        else {
            setReceiptsAndPayments({ ...receiptsAndPayments, collaborator, [nam]: val })
        }

    };


    async function handleSubmit(event) {
        event.preventDefault();

        if(receiptsAndPaymentsUpdated._id !== undefined) {

            if(receiptsAndPayments.length === 0) {

                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });

            } else {

                if(
                    receiptsAndPayments.month === "" ||
                    receiptsAndPayments.year  === "" ||
                    receiptsAndPayments.type  === "" ||
                    receiptsAndPayments.file  === ""
                ) {

                    swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser alterado em branco!" });

                } else {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if (receiptsAndPayments.month !== undefined) {
                        formData.append('month', receiptsAndPayments.month);
                    }

                    if (receiptsAndPayments.year !== undefined) {
                        formData.append('year', receiptsAndPayments.year);
                    }

                    if (receiptsAndPayments.type !== undefined) {
                        formData.append('type', receiptsAndPayments.type);
                    }

                    if (receiptsAndPayments.file !== undefined) {
                        formData.append('file', receiptsAndPayments.file);
                    }
                    formData.append('collaborator', id);
                    formData.append('path', path);

                    setLoad(true)
                    await api.put(`/collaborator-receipts-and-payments/${receiptsAndPaymentsUpdated._id}`, formData, config)
                    .then(response => {

                        setReceiptsAndPayments([]);
                        setReceiptsAndPaymentsUpdated([]);
                        setVisible(!visible);
                        loadreceiptsandpayments(id);
                        setLoad(false)
                        swal({ icon: "success", title: "Sucesso!", text: "Recibos de pagamentos atualizado com sucesso!" });

                    }).catch(error => {
                        setLoad(false)
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    });

                }

            }

        } else {

            if (
                receiptsAndPayments.month === undefined ||
                receiptsAndPayments.month ===     ""    ||
                receiptsAndPayments.year  === undefined ||
                receiptsAndPayments.year  ===     ""    ||
                receiptsAndPayments.type  === undefined ||
                receiptsAndPayments.type  ===     ""    ||
                receiptsAndPayments.file  === undefined
            ) {

                if (receiptsAndPayments.month !== undefined) {
                    setLoadMonth(false)
                } else {
                    setLoadMonth(true)
                }

                if (receiptsAndPayments.year !== undefined && receiptsAndPayments.year !== "") {
                    setLoadYear(false)
                } else {
                    setLoadYear(true)
                }

                if (receiptsAndPayments.type !== undefined) {
                    setLoadType(false)
                } else {
                    setLoadType(true)
                }

                if (receiptsAndPayments.file !== undefined) {
                    setLoadFileForm(false)
                } else {
                    setLoadFileForm(true)
                }
                return

            } else {



                if (
                    receiptsAndPayments.month !== undefined &&
                    receiptsAndPayments.year  !== undefined &&
                    receiptsAndPayments.type  !== undefined &&
                    receiptsAndPayments.file  !== undefined
                ) {
                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }

                    formData.append('collaborator', id);
                    formData.append('month', receiptsAndPayments.month);
                    formData.append('year',  receiptsAndPayments.year);
                    formData.append('type',  receiptsAndPayments.type);
                    formData.append('file',  receiptsAndPayments.file);
                    formData.append('path', path);
                    setLoad(true)
                    await api.post("/collaborator-receipts-and-payments", formData, config)
                    .then(response => {

                        setReceiptsAndPayments([]);
                        setReceiptsAndPaymentsUpdated([]);
                        setVisible(!visible);
                        loadreceiptsandpayments(id);
                        setLoad(false)

                        swal({ icon: "success", title: "Sucesso!", text: "Recibos de pagamentos criado com sucesso!" });

                    }).catch(error => {
                        setLoad(false)
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    });
                }

            }

        }

    }

const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
]

    return (
        <>
            <SubMenu id={id} role={role} path={path}/>
            <div className="box-infor-perfil box-infor-playroll">
                <div className="box">
                <div className="buscaAdd">
                        <form action="">
                            <input type="text" onChange={(event) => loadfilterreceiptsandpayments(event)} placeholder="Procure pelo mês e ano" className="busca"/>
                        </form>


                        {
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                <button className="btnGreen more" onClick={() => AddPlayrollVisible()}>Adicionar</button>
                        }

                    </div>
                    <table>
                        <thead>
                            <tr className="tabelaTR">
                                <th className="inten-1">
                                    Período
                                </th>
                                <th className="inten-2">Tipo</th>
                                <th className="inten-3"></th>
                                <th className="inten-4"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        loadPage ?
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            </>
                        :
                            filterReceiptsAndPayments[0] !== undefined ? filterReceiptsAndPayments
                            .map((receiptsandpayments, index) => (
                            <>

                            <tr className="tabelaTR">

                                <td className="inten-1"  onClick={() => AddAndEditPlayrollVisible(receiptsandpayments)}>
                                    <div className="motivo">
                                        <strong> { receiptsandpayments.month } </strong>
                                        <span>{receiptsandpayments.year}</span>

                                    </div>
                                </td>
                                <td className="inten-2" onClick={() => AddAndEditPlayrollVisible(receiptsandpayments)}>
                                    {receiptsandpayments.type}
                                </td>
                                <td className="inten-3">
                                    <a href={receiptsandpayments.location} target="’_blank’">
                                        <img alt="icone de documentos" src={iconUpload} />
                                        <span>{receiptsandpayments.originalname}</span>
                                    </a>
                                        {/* <a href={receiptsandpayments.location} target="’_blank’">
                                            <img alt="icone de documentos" src={iconUpload} />
                                            {receiptsandpayments.originalname}
                                        </a> */}
                                </td>
                                <td className="inten-4">

                                {
                                    localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                        ""
                                        :
                                        <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>

                                                    <button   onClick={() => AddAndEditPlayrollVisible(receiptsandpayments)} name="_id" value="" type="submit">
                                                        Editar
                                                    </button>

                                                    <div>
                                                        <button   onClick={() => deleteReceiptsAndPayments(receiptsandpayments._id)} name="_id" value="" type="submit" className="red">
                                                        Excluir
                                                        </button>
                                                    </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                }
                                </td>
                            </tr>


                            </>
                            ))
                            :
                            registeredReceiptsAndPayments.length === 0 ?
                            <div className="nenhum-cadastrado-perfil"><p>Nenhum recibos de pagamentos cadastrada.</p></div>
                            :
                            registeredReceiptsAndPayments !== undefined ? registeredReceiptsAndPayments
                            .map((receiptsAndPayments, index) => (
                            <>
                            <tr className="tabelaTR">

                                <td className="inten-1"  onClick={() => AddAndEditPlayrollVisible(receiptsAndPayments)}>
                                    <div className="motivo">
                                        <strong> { receiptsAndPayments.month } </strong>
                                        <span>{receiptsAndPayments.year}</span>

                                    </div>
                                </td>
                                <td className="inten-2"  onClick={() => AddAndEditPlayrollVisible(receiptsAndPayments)}>
                                    {receiptsAndPayments.type}
                                </td>
                                <td className="inten-3">
                                    <a href={receiptsAndPayments.location} target="’_blank’">
                                        <img alt="icone de documentos" src={iconUpload} />
                                        <span>{receiptsAndPayments.originalname}</span>
                                    </a>
                                        {/* <a href={receiptsAndPayments.location} target="’_blank’">
                                            <img alt="icone de documentos" src={iconUpload} />
                                            {receiptsAndPayments.originalname}
                                        </a> */}
                                </td>
                                <td className="inten-4">
                                    {
                                        localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                            ""
                                            :
                                            <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                                <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>

                                                        <button   onClick={() => AddAndEditPlayrollVisible(receiptsAndPayments)} name="_id" value="" type="submit">
                                                            Editar
                                                        </button>

                                                        <div>
                                                            <button   onClick={() => deleteReceiptsAndPayments(receiptsAndPayments._id)} name="_id" value="" type="submit" className="red">
                                                            Excluir
                                                            </button>
                                                        </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    }
                                </td>
                            </tr>
                            </>
                            )) : (
                                <>
                                </>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
            <Modal show={visible} className="popup-edit-perfil" onHide={AddAndEditPlayrollVisible}>
                <div>
                    <div className="titulo">
                        <h4>Recibos de pagamentos <p className="btn-fechar" onClick={AddAndEditPlayrollVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Mês</p>
                            </div>
                            <div className="intemB">
                                    <SelectPersonalizado
                                        valorCampo={
                                            receiptsAndPayments && receiptsAndPayments.month !== undefined ?
                                            receiptsAndPayments.month
                                            :
                                            receiptsAndPaymentsUpdated && receiptsAndPaymentsUpdated.month !== undefined ?
                                                receiptsAndPaymentsUpdated.month : ""
                                        }
                                        nameButton={"month"}
                                        myChangeHandler={myChangeHandler}
                                        loadError={loadMonth}

                                        optionFil={meses}
                                        typeSelect="busca2"
                                    />
                                <p className="inputUm textError">{loadMonth ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Ano</p>
                            </div>
                            <div className="intemB">
                            {
                                (receiptsAndPaymentsUpdated && receiptsAndPaymentsUpdated.year !== undefined) || receiptsAndPaymentsUpdated.year === "" ?
                                (
                                    <input type="text" name="year" maxlength="4"  defaultValue={(receiptsAndPaymentsUpdated.year !== undefined) ? receiptsAndPaymentsUpdated.year : ''}  onChange={myChangeHandler} placeholder="AAAA"  className={loadYear ? 'inputUm inputErro' : 'inputUm'} />
                                )
                                :
                                (receiptsAndPayments && receiptsAndPayments.year !== undefined) || receiptsAndPayments.year === "" ?
                                (
                                    <input type="text" name="year" maxlength="4"  defaultValue={(receiptsAndPayments.year !== undefined) ? receiptsAndPayments.year : ''}  onChange={myChangeHandler} placeholder="AAAA"  className={loadYear ? 'inputUm inputErro' : 'inputUm'} />
                                )
                                :
                                registeredReceiptsAndPayments && registeredReceiptsAndPayments.year ? (
                                    <input type="text" name="year"  maxlength="4"  defaultValue={(registeredReceiptsAndPayments.year !== undefined) ? registeredReceiptsAndPayments.year : ''}  onChange={myChangeHandler} placeholder="AAAA" className="inputUm"/>
                                )
                                :
                                (
                                    <input type="text" name="year"  maxlength="4"  value={(receiptsAndPayments.year !== undefined) ? receiptsAndPayments.year : ''}  onChange={myChangeHandler} placeholder="AAAA"  className={loadYear ? 'inputUm inputErro' : 'inputUm'} />
                                )
                            }
                                <p className="textError">{loadYear ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo</p>
                            </div>
                            <div className="intemB">
                                <div className='cont-select'>

                                    <button className={loadType ? 'inputErro select-' : 'select-'}>
                                        {
                                            receiptsAndPayments && receiptsAndPayments.type !== undefined ?
                                            receiptsAndPayments.type
                                            :
                                            receiptsAndPaymentsUpdated && receiptsAndPaymentsUpdated.type !== undefined ?
                                                receiptsAndPaymentsUpdated.type : "Selecione"
                                        }
                                    </button>
                                    <div className='dropDown'>
                                        <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                            {[
                                                "Adiantamento",
                                                "Holerites",
                                                "Bônus",
                                                "Comissão",
                                                "Informe de Rendimentos",
                                                "13º Salário",
                                                "Outros",
                                                "Recibo de Férias",
                                                "13º Salário - Primeira parcela",
                                                "13º Salário - Segunda parcela",
                                                "Adiantamento",
                                                "PLR",
                                                "Folha Complementar",
                                                "Recibos",
                                                "Pró-labore",
                                                "Espelho de Ponto"

                                            ]
                                            .map((typ, index) => (
                                                <button
                                                    key={index}
                                                    className='item'
                                                    onClick={() =>
                                                        setReceiptsAndPayments({ ...receiptsAndPayments, collaborator, "type" : typ }) ||
                                                        setLoadType(false)
                                                    }
                                                >
                                                    <p >{typ}</p>
                                                </button>
                                            ))}
                                            </Scrollbars>
                                    </div>
                                </div>
                                <p className="inputUm textError">{loadType ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>

                        {
                            receiptsAndPaymentsUpdated.originalname !== undefined ?
                            (
                                <li>
                                    <div className="intemA">
                                        <p>Anexo</p>
                                    </div>
                                    <div className="intemB">
                                        <p className="documento-anexado">
                                            <a href={receiptsAndPaymentsUpdated.location} target="’_blank’">
                                                {receiptsAndPaymentsUpdated.originalname}
                                            </a>
                                        </p>

                                    </div>
                                </li>
                            )
                            :
                            receiptsAndPayments.originalname !== undefined ?
                            (
                                <li>
                                    <div className="intemA">
                                        <p>Anexo</p>
                                    </div>
                                    <div className="intemB">
                                        <p className="documento-anexado">
                                            <a href={receiptsAndPayments.location} target="’_blank’">
                                                {receiptsAndPayments.originalname}
                                            </a>
                                        </p>

                                    </div>
                                </li>
                            )
                            :
                            ''
                        }
                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div  className={loadFileForm ? 'imgDocLabel-error intemB' : 'intemB'}>
                                    <Dropzone onDrop={handleDrop} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className="imgDocLabel"><span></span>
                                            {
                                                receiptsAndPaymentsUpdated.originalname !== undefined ?
                                                'Atualizar o arquivo'
                                                :
                                                receiptsAndPayments.originalname !== undefined ?
                                                'Atualizar o arquivo'
                                                :
                                                'Anexe um documento'
                                            }
                                            </label>
                                        </div>
                                        )}
                                    </Dropzone>
                            </div>
                        </li>

                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                <p className="textError">{loadFileForm ? 'É necessário escolher anexar arquivo' : ''}</p>
                            </div>
                        </li>

                        {fileNames !== false && fileNames ?
                        fileNames.map((filename, index) => (
                        <li>
                            <div className="arquivoAnexado">

                                <h5>Enviados</h5>
                                <div key={index} className="arquivo">
                                <p className="nomeArquivo">
                                    {filename}
                                </p>
                                    <p onClick={deleteFiles} className="remover">Remover anexo</p>
                                </div>

                            </div>
                        </li>

                        )) : loadArchive ?
                        (<div className="carregando textoDoze"><img src={Carregamento} alt="carregando" /></div>) :
                        ('')}
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">

                                <form onSubmit={handleSubmit} className="btn-salvar">
                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>
                                       Salvar
                                    </button>
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    );
}
export default ColProfileProssional;
