import React from 'react';
import { Dropdown } from "react-bootstrap";
import IconV from "../../../assets/images/v.svg";
import Grafic from "./grafic"



export default function ManagementOverview() {

    return (
        <>

            <div className='box-leiaute overview'>
                <h2 className='titulo'>Overview
                    <Dropdown  className="dropdown-icon ">
                        <Dropdown.Toggle>
                            <span>Nos últimos 7 dias</span>
                                <img src={IconV} alt="icone v" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <button>Nos últimos 7 dias</button>
                            <button>Nos últimos 15 dias</button>
                            <button>No último mês</button>
                        </Dropdown.Menu>
                    </Dropdown>
                </h2>

                <div className="average-general-graph">

                    <div className="average-total">
                    {/*
                         ------   down up   ----------- 
                         class down e quando tiver baixando o numero
                         class up e quando tiver subindo o numero
                    */}
                        <div className="circle up">
                            <p>Média geral</p>
                            <strong>7.83</strong>
                            <span>7%</span>
                        </div>
                        <p>Atualizado 03.01.2022 às 10:22</p>
                    </div>

                    <Grafic/>
                    
                </div>

            </div>
        </>
    );
}

