import React from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import { Modal} from "react-bootstrap";
import Dropzone from "react-dropzone";
import Carregando from '../../../../assets/images/carregando.gif';
import Close from '../../../../assets/images/close.svg';

export default function ColProfileCPF({ id,
     collaborator,
     loadPage,
     onCollaboratorInputChange,
     collaboratorUpdate,
     ufCity,
     OnSubmit,
     loadContinue,
     EditCpfVisible,
     visibleCPF,
     filesSavedCPF,
    loadCPF,
    handleDropCPF,
    deleteFileCPF
}) {


    return (
        <>
        <div className="box cpf">
                    <h3>CPF <button className="edit-Benefit" onClick={() => EditCpfVisible()} ><img src={EditBlack} alt="Edite Informações" /></button></h3>
                    {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregando} />
                                </div>
                                </>
                            ):(
                                <>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Numero</p>
                            </div>
                            <div className="intemB">
                                <p>{collaborator.cpf ? collaborator.cpf : '-'}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>É estrangeiro?</p>
                            </div>
                            <div className="intemB">
                                <p>
                                    {collaborator.foreign === 'nao' ? 'Não' :
                                collaborator.foreign === 'sim' ? 'Sim' : 'Não' }</p>
                            </div>
                        </li>

                        {filesSavedCPF[0] !== undefined ? (
                        <li className="li-anexo">
                            <div className="intemA">
                                <p>Anexo</p>
                            </div>



                        <div className="intemB arq-adicionado" >
                            {filesSavedCPF[0] !== undefined ?
                            filesSavedCPF.map((filesaved, index) => (
                                        <a href={filesaved.location} target="’_blank’" key={index}>
                                            {filesaved.name}
                                        </a>
                            )) : (
                                <>
                                <p>-</p>
                                </>
                            )}


                        </div>
                        </li>
                        ) : ('')}



                    </ul>

                    </>)}
                </div>

            <Modal show={visibleCPF} className="popup-edit-perfil popup-edit-cpf" onHide={EditCpfVisible}>
                <div>
                    <div className="titulo">
                        <h4>CPF  <p className="btn-fechar" onClick={EditCpfVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Numero</p>
                            </div>
                            <div className="intemB">

                            {
                                collaboratorUpdate && collaboratorUpdate.cpf !== undefined ?
                                <input type="text" name="cpf" value={collaboratorUpdate.cpf ? collaboratorUpdate.cpf : ''} placeholder="000.000.000-00" onChange={onCollaboratorInputChange} className="inputUm" /> :
                                collaborator && collaborator.cpf !== undefined ?
                                <input type="text" name="cpf" value={collaborator.cpf ? collaborator.cpf : ''} onChange={onCollaboratorInputChange} className="inputUm"/> :
                                <input type="text" name="cpf" onChange={onCollaboratorInputChange} placeholder="000.000.000-00" className="inputUm"/>

                            }
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>É estrangeiro?</p>
                            </div>
                            <div className="intemB chekbox">
                                {

                                collaboratorUpdate && collaboratorUpdate?.foreign ?
                                <div className="inputDois chekbox">
                                    <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" checked={collaboratorUpdate.foreign === "nao"? true : false} />
                                    <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                    <label htmlFor="foreignNao" className="foreignTexto">Não</label>

                                    <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" checked={collaboratorUpdate.foreign === "sim"? true : false} />
                                    <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                    <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                </div>

                                :
                                <div className="inputDois chekbox">
                                    <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" checked={collaborator.foreign === "nao"? true : false} />
                                    <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                    <label htmlFor="foreignNao" className="foreignTexto">Não</label>

                                    <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" checked={collaborator.foreign === "sim"? true : false} />
                                    <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                    <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                </div>

                            }
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div className="intemB">
                                    <Dropzone onDrop={handleDropCPF} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()} />
                                            <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                            </div>
                        </li>

                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                            </div>
                        </li>
                        {filesSavedCPF[0]  !== undefined ? (
                        <li>
                            <div className="arquivoAnexado">
                                <h5>Enviados</h5>

                                {filesSavedCPF[0] !== undefined ?
                                filesSavedCPF.map((filesaved, index) => (
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                                        </p>
                                        <button onClick={()=>deleteFileCPF(filesaved)} className="btnExclui">Remover anexo</button>
                                    </div>
                                )) : ''}

                            </div>
                        </li>
                                ) : ''}
                        {loadCPF ?
                        ( <li>
                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                        </li>) : ('')}


                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={OnSubmit} className="btn-salvar">
                                    <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>

                    </ul>


                </div>
            </Modal>

        </>
    );
}
