import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';




const SubMenuColProfile = ({ id, role, path })  => {
    // console.log(path)


 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusProfInforTrabalho  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusProfBeneficios  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusProfFerias  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusProfDocumentos  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusProfRecibosPamentos  = useRef(null);

 // eslint-disable-next-line react-hooks/rules-of-hooks
 const focusProfJornadaTrabalho  = useRef(null);



 // eslint-disable-next-line react-hooks/rules-of-hooks
 useEffect(() => {
    if( path === '/colaboradores/:id/profissional') {
        focusProfInforTrabalho.current.focus();
    } else
    if(path === '/colaboradores/:id/profissional/beneficios-do-colaborador'){
        focusProfBeneficios.current.focus();
    }else
    if( path === '/colaboradores/:id/profissional/ferias'){
        focusProfFerias.current.focus();
    }else
    if( path === '/colaboradores/:id/profissional/documentos'){
        focusProfDocumentos.current.focus();
    }else
    if( path === '/colaboradores/:id/profissional/folha-de-pagamento'){
        focusProfRecibosPamentos.current.focus();
    }else
    if( path === '/colaboradores/:id/profissional/jornada-de-trabalho'){
        focusProfJornadaTrabalho.current.focus();
    }


}, [path]);
    return (
        <>
            <ul className="SubMenu-perfil">
                <Scrollbars>
                    <li>
                        <NavLink to={`/colaboradores/${id}/profissional`} exact activeClassName="active" ref={focusProfInforTrabalho}><span>Informações de trabalho</span></NavLink>
                    </li>
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <>
                        <li>
                            <NavLink to={`/colaboradores/${id}/profissional/beneficios-do-colaborador`} exact activeClassName="active"><span>Benefícios</span></NavLink>
                        </li>
                        <button ref={focusProfBeneficios}></button>
                        </>
                        :
                        ''
                    }
                    <li>
                        <NavLink to={`/colaboradores/${id}/profissional/ferias`} exact activeClassName="active"><span>Férias</span></NavLink>
                    </li>
                        <button ref={focusProfFerias}></button>
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <>
                        <li>
                            <NavLink to={`/colaboradores/${id}/profissional/documentos`} exact activeClassName="active" ><span>Documentos</span></NavLink>
                        </li>
                        <button ref={focusProfDocumentos}></button>
                        <li>
                            <NavLink to={`/colaboradores/${id}/profissional/folha-de-pagamento`} exact activeClassName="active" ref={focusProfRecibosPamentos}><span>Recibos de pagamentos</span></NavLink>
                        </li>
                        </>
                        :
                        ''
                    }

                    <li>
                        <NavLink to={`/colaboradores/${id}/profissional/jornada-de-trabalho`} exact activeClassName="active" ><span>Jornada de trabalho</span></NavLink>
                    </li>
                    <button ref={focusProfJornadaTrabalho}></button>
                </Scrollbars>
            </ul>

        </>
    );

}
export default SubMenuColProfile;
