import React, { useState, useEffect } from 'react';
// import ImgExemplo from '../../../../assets/images/emer.jpg';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import IconAddCircleGreen from '../../../../assets/images/add-circle-green.svg';
import Carregando from '../../../../assets/images/carregando.gif';
// import { NavLink } from 'react-router-dom';
import api from '../../../../services/api';
import swal from 'sweetalert';

export default function HistoricoFeriasBoxInforSolicitacao({
    id,
    role,
 }) {

    //vacationAwaitingManagerApproval
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorVacationInfo, setCollaboratorVacationInfo ] = useState([]);

    //collaboratorCheklistVacation
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorCheklistVacation, setCollaboratorCheklistVacation ] = useState([]);

    //HumanResourcesCheklistVacation
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ humanResourcesCheklistVacation, setHumanResourcesCheklistVacation ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaboratorvacationinfo(id);
        loadcollaboratorchecklistvacation(id);
        loadhumanresourceschecklistvacation(id);
    }, [id]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadSolicitaçao, setLoadSolicitaçao] = useState(false);
    async function loadcollaboratorvacationinfo(id) {
        setLoadSolicitaçao(true)
        await api.get(`collaborator-vacation-new/?id=${id}&type=individual`)
        .then(response => {
            setCollaboratorVacationInfo(response.data.collaboratorVacationNewSearch[0]);
            setLoadSolicitaçao(false)
        }).catch(error => {});
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadChecklistvacation, setLoadChecklistvacation] = useState(false);
    async function loadcollaboratorchecklistvacation(id) {
        setLoadChecklistvacation(true)
        await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=collaborator`)
        .then(response => {
            setCollaboratorCheklistVacation(response.data);
            setLoadChecklistvacation(false)
        }).catch(error => {});
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadHumanResourcesChecklistVacation, setLoadHumanResourcesChecklistVacation] = useState(false);
    async function loadhumanresourceschecklistvacation(id) {
        setLoadHumanResourcesChecklistVacation(true)
        await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=humanresources`)
        .then(response => {
            setHumanResourcesCheklistVacation(response.data);
            setLoadHumanResourcesChecklistVacation(false)
        }).catch(error => {});
    }

    //bottomLiManagerApproval
    async function bottomLiManagerApproval(e) {
        e.preventDefault();
        setManagerApprovalstatus(true)
        setHumanResourcesApprovalstatus(false)
        setConclusionStatus(false)
    }

    //managerApprovalstatus
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ managerApprovalstatus, setManagerApprovalstatus ] = useState(false);

    //humanResourcesApprovalstatus
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ humanResourcesApprovalstatus, setHumanResourcesApprovalstatus ] = useState(false);

    // conclusionStatus
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ conclusionStatus, setConclusionStatus ] = useState(false);


    //bottomLiHumanResourcesApproval
    async function bottomLiHumanResourcesApproval(e) {
        e.preventDefault();
        setManagerApprovalstatus(false)
        setHumanResourcesApprovalstatus(true)
        setConclusionStatus(false)
    }

    //bottomLiConclusionstatus
    async function bottomLiConclusionstatus(e) {
        e.preventDefault();
        setManagerApprovalstatus(true)
        setHumanResourcesApprovalstatus(true)
        setConclusionStatus(true)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadJustificativa, setLoadJustificativa ] = useState(false);

    //approveVacations
    async function approveVacations(event) {
        event.preventDefault();
        let managerStatus = "aprovado"
        setJustificationOfFailure(false)

        swal({
            title: "Atenção",
            text: "Deseja realmente aprovar as férias deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, { managerStatus })
                .then(response => {
                    setManagerApprovalstatus(false)
                    setJustificationOfFailure(false)
                    setHumanResourcesApprovalstatus(true)
                    setLoadSolicitaçao(true)

                    async function collaboratorVestingPeriodCalculateFisrtBalance() {
                        // => Atualiza todos os saldos dos períodos aquisitivos
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {
                            loadcollaboratorvacationinfo(id);
                        }).catch(error => {
                            console.log(error)
                            swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });

                        });

                    }
                    collaboratorVestingPeriodCalculateFisrtBalance()
                    loadcollaboratorvacationinfo(id);

                    swal({ icon: "success", title: "Sucesso!", text: 'Férias aprovadas pelo gestor com sucesso!' });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadJustificativa(false)
                });

            }
        })

    }

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ failVacation, setFailVacation ] = useState([]);

    //justificationOfFailure
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ justificationOfFailure, setJustificationOfFailure ] = useState(false);

    //myChangeHandlerFailVacation
    async function myChangeHandlerFailVacation(event) {
        event.preventDefault();
        let managerStatus = "reprovado"
        let status        = "reprovado"

        setFailVacation({ ...failVacation, managerStatus, status })
        setJustificationOfFailure(true)
    }

    //myChangeHandlerManagerComments
    async function myChangeHandlerManagerComments(event) {
        event.preventDefault();

        let nam = event.target.name;
        let val = event.target.value;

        if(val.length > 10) {
            setLoadJustificativa(false)
        }

        setFailVacation({ ...failVacation, [nam]: val })
        // setJustificationOfFailure(true)
    }

    //cancelAndJustify
    async function cancelAndJustify(event) {
        event.preventDefault();
        //setLoadJustificativa
        if(
            failVacation                                 &&
            failVacation.managerStatus   === "reprovado" &&
            failVacation.managerComments !== undefined   &&
            failVacation.managerComments !== ""
        ) {
            setLoadJustificativa(false)
            swal({
                title: "Atenção",
                text: "Deseja realmente reprovar as férias deste colaborador?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {

                    await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, failVacation)
                    .then(response => {

                        setJustificationOfFailure(false)

                        async function collaboratorVestingPeriodCalculateFisrtBalance() {
                            // => Atualiza todos os saldos dos períodos aquisitivos
                            await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                                companyId   : response.data.companyId,
                                collaborator: response.data.collaborator
                            })
                            .then(response => {
                                loadcollaboratorvacationinfo(id);
                            }).catch(error => {
                                console.log(error)
                                swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });
                            });
                        }
                        collaboratorVestingPeriodCalculateFisrtBalance()
                        loadcollaboratorvacationinfo(id);
                        swal({ icon: "success", title: "Sucesso!", text: 'Férias reprovadas com sucesso!' });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadJustificativa(false)
                    });

                }
            })

        }
        else {
            setLoadJustificativa(true)
        }

    }

    //backToRequest
    async function backToRequest(event) {
        event.preventDefault();
        let managerStatus = "aprovado"

        setFailVacation({ ...failVacation, managerStatus })
        setLoadJustificativa(false)
        setJustificationOfFailure(false)

    }

    //leavePending
    async function leavePending(event) {
        event.preventDefault();
        let managerStatus = "pendente"
        let status        = "pendente"
        swal({
            title: "Atenção",
            text: "Deseja realmente deixar as férias pendente deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, { managerStatus, status })
                .then(response => {
                    setHumanResourcesApprovalstatus(false)
                    setConclusionStatus(false)

                    async function collaboratorVestingPeriodCalculateFisrtBalance() {
                        // => Atualiza todos os saldos dos períodos aquisitivos
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {
                            loadcollaboratorvacationinfo(id);
                        }).catch(error => {
                            console.log(error)
                            swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });
                        });

                    }
                    collaboratorVestingPeriodCalculateFisrtBalance()
                    loadcollaboratorvacationinfo(id);

                    swal({ icon: "success", title: "Sucesso!", text: 'Férias deixada como pendente pelo gestor com sucesso!' });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadJustificativa(false)
                });

            }
        })

    }

    // => HUMAN RESOURCES
    //////////////////////////////////////////////////////////////////////////////////////////

    //approveVacationsHumanResources
    async function approveVacationsHumanResources(event) {
        event.preventDefault();
        let humanResourcesStatus = "aprovado"
        setJustificationOfFailureHumanResources(false)

        swal({
            title: "Atenção",
            text: "Deseja realmente aprovar as férias deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, { humanResourcesStatus })
                .then(response => {
                    setConclusionStatus(true)
                    setJustificationOfFailureHumanResources(false)
                    loadcollaboratorvacationinfo(id);
                    swal({ icon: "success", title: "Sucesso!", text: 'Férias aprovadas pelo RH com sucesso!' });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadJustificativaHumanResources(false)
                });

            }
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ failVacationHumanResources, setFailVacationHumanResources ] = useState([]);

    //justificationOfFailure
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ justificationOfFailureHumanResources, setJustificationOfFailureHumanResources ] = useState(false);

     // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadJustificativaHumanResources, setLoadJustificativaHumanResources ] = useState(false);

    //myChangeHandlerFailVacation
    async function myChangeHandlerFailVacationToHumanResources(event) {
        event.preventDefault();
        let humanResourcesStatus = "reprovado";
        let status               = "reprovado";
        setFailVacationHumanResources({ ...failVacationHumanResources, humanResourcesStatus, status })
        setJustificationOfFailureHumanResources(true)
    }

    //myChangeHandlerCommentsHumanResources
    async function myChangeHandlerCommentsHumanResources(event) {
        event.preventDefault();
        let nam = event.target.name;
        let val = event.target.value;
        if(val.length > 10) {
            setLoadJustificativaHumanResources(false)
        }
        setFailVacationHumanResources({ ...failVacationHumanResources, [nam]: val })
    }

    //cancelAndJustifyHumanResources
    async function cancelAndJustifyHumanResources(event) {
        event.preventDefault();
        //setLoadJustificativaHumanResources
        if(
            failVacationHumanResources                                        &&
            failVacationHumanResources.humanResourcesStatus   === "reprovado" &&
            failVacationHumanResources.humanResourcesComments !== undefined   &&
            failVacationHumanResources.humanResourcesComments !== ""
        ) {
            setLoadJustificativaHumanResources(false)
            swal({
                title: "Atenção",
                text: "Deseja realmente reprovar as férias deste colaborador?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {

                    await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, failVacationHumanResources)
                    .then(response => {
                        setLoadSolicitaçao(true)
                        setJustificationOfFailureHumanResources(false)

                        async function collaboratorVestingPeriodCalculateFisrtBalance() {
                            // => Atualiza todos os saldos dos períodos aquisitivos
                            await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                                companyId   : response.data.companyId,
                                collaborator: response.data.collaborator
                            })
                            .then(response => {
                                loadcollaboratorvacationinfo(id);
                            }).catch(error => {
                                console.log(error)
                                swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });
                            });

                        }
                        collaboratorVestingPeriodCalculateFisrtBalance()
                        swal({ icon: "success", title: "Sucesso!", text: 'Férias reprovadas pelo RH com sucesso!' });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoadJustificativaHumanResources(false)
                    });

                }
            })

        }
        else {
            setLoadJustificativaHumanResources(true)
        }

    }

    //backToRequestHumanResources
    async function backToRequestHumanResources(event) {
        event.preventDefault();
        let humanResourcesStatus = "aprovado"
        setFailVacationHumanResources({ ...failVacationHumanResources, humanResourcesStatus })
        setLoadJustificativaHumanResources(false)
        setJustificationOfFailureHumanResources(false)
    }


    //leavePendingHumanResources
    async function leavePendingHumanResources(event) {
        event.preventDefault();
        let humanResourcesStatus = "pendente"
        let status               = "pendente"
        swal({
            title: "Atenção",
            text: "Deseja realmente deixar as férias pendente deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, { humanResourcesStatus, status })
                .then(response => {

                    async function collaboratorVestingPeriodCalculateFisrtBalance() {
                        // => Atualiza todos os saldos dos períodos aquisitivos
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {
                            loadcollaboratorvacationinfo(id);
                        }).catch(error => {
                            console.log(error)
                            swal({ icon: "error", title: "Erro!", text: "Colaborador deixado como pendente, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });

                        });

                    }
                    collaboratorVestingPeriodCalculateFisrtBalance()

                    loadcollaboratorvacationinfo(id);

                    swal({ icon: "success", title: "Sucesso!", text: 'Férias deixada como pendente pelo RH com sucesso!' });

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadJustificativaHumanResources(false)
                });

            }
        })

    }

    // eslint-disable-next-line no-unused-vars
    async function backVacations() {
        setTimeout(function(){window.location.href = '/ferias'}, 500);
    }

    async function backVacationsManagerApproval() {
        setManagerApprovalstatus(true)
        setHumanResourcesApprovalstatus(false)
        setConclusionStatus(false)
    }

    //backVacationsHumanResourcesApproval
    async function backVacationsHumanResourcesApproval() {

        setManagerApprovalstatus(false)
        setHumanResourcesApprovalstatus(true)
        setConclusionStatus(false)

    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// CHECK LIST COLLABORATOR VACATION ////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ visibleInputCheckCollaboratorVacation, setVisibleInputCheckCollaboratorVacation ] = useState(false);

    //addCheckCollaboratorVacation
    async function addCheckCollaboratorVacation() {
        setVisibleAddItem(!visibleAddItem)
        setVisibleInputCheckCollaboratorVacation(!visibleInputCheckCollaboratorVacation)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ visibleAddItem, setVisibleAddItem ] = useState(true);

    //cancelCheckCollaboratorVacation
    async function cancelCheckCollaboratorVacation() {
        setVisibleInputCheckCollaboratorVacation(!visibleInputCheckCollaboratorVacation)
        setVisibleAddItem(!visibleAddItem)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ newChecklistCollaboratorVacation, setNewChecklistCollaboratorVacation ] = useState([]);

    async function checklistCollaboratorVacation(e) {
        e.preventDefault();
        let collaborator = id;
        let category     = 'collaborator';
        let nam          = e.target.name;
        let val          = e.target.value;
        let status       = false;
         setNewChecklistCollaboratorVacation({ ...newChecklistCollaboratorVacation, collaborator, category, [nam] : val, status })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadChecklistCollaboratorVacation, setLoadChecklistCollaboratorVacation ] = useState(false);

    //saveCheckCollaboratorVacation
    async function saveCheckCollaboratorVacation(e) {
        e.preventDefault();
        if(
            newChecklistCollaboratorVacation        &&
            newChecklistCollaboratorVacation.length === 0
        ) {
            setLoadChecklistCollaboratorVacation(true)
        }
        else {

            await api.post('/collaborator-checklist-vacation/', newChecklistCollaboratorVacation)
            .then(response => {
                setLoadChecklistCollaboratorVacation(false)
                async function loadcollaboratorchecklistvacation() {
                    await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=collaborator`)
                    .then(response => {
                        setCollaboratorCheklistVacation(response.data);
                        setNewChecklistCollaboratorVacation({});
                    })
                }
                loadcollaboratorchecklistvacation();
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "error"});
            });

        }
    }

    //saveNewCheckCollaboratorVacation
    async function saveNewCheckCollaboratorVacation(e) {
        e.preventDefault();

        if(e.target.checked === true) {
            let status = true;
            let _id = e.target.id
            await api.put(`/collaborator-checklist-vacation/${_id}`, { status })
            .then(response => {
                async function loadcollaboratorchecklistvacation() {
                    await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=collaborator`)
                    .then(response => {
                        setCollaboratorCheklistVacation(response.data);
                    }).catch(error => {});
                }
                loadcollaboratorchecklistvacation();
            }).catch(error => {});

        }
        else {
            let status = false;
            let _id = e.target.id
            //alert('false')

            await api.put(`/collaborator-checklist-vacation/${_id}`, { status })
            .then(response => {

                async function loadcollaboratorchecklistvacation() {

                    await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=collaborator`)
                    .then(response => {
                        setCollaboratorCheklistVacation(response.data);
                    }).catch(error => {});
                }
                loadcollaboratorchecklistvacation();
            }).catch(error => {});
        }
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////// CHECK LIST HUMAN RESOURCES VACATION ////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ visibleInputCheckhumanResourcesVacation, setVisibleInputCheckhumanResourcesVacation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ visibleAddItemHumanResources, setVisibleAddItemHumanResources ] = useState(true);

    //addCheckHumanResourcesVacation
    async function addCheckHumanResourcesVacation() {
        setVisibleAddItemHumanResources(!visibleAddItemHumanResources)
        setVisibleInputCheckhumanResourcesVacation(!visibleInputCheckhumanResourcesVacation)
    }

    //cancelCheckCollaboratorVacation
    async function cancelCheckHumanResourcesVacation() {
        setVisibleInputCheckhumanResourcesVacation(!visibleInputCheckhumanResourcesVacation)
        setVisibleAddItemHumanResources(!visibleAddItemHumanResources)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ newChecklistHumanResourcesVacation, setNewChecklistHumanResourcesVacation ] = useState([]);

    async function checklistHumanResourcesVacation(e) {
        e.preventDefault();
        let collaborator = id;
        let category     = 'humanresources';
        let nam          = e.target.name;
        let val          = e.target.value;
        let status       = false;
         setNewChecklistHumanResourcesVacation({ ...newChecklistHumanResourcesVacation, collaborator, category, [nam] : val, status })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadChecklistHumanResourcesVacation, setLoadChecklistHumanResourcesVacation ] = useState(false);

    //saveCheckCollaboratorVacation
    async function saveCheckHumanResourcesVacation(e) {
        e.preventDefault();
        if(
            newChecklistHumanResourcesVacation        &&
            newChecklistHumanResourcesVacation.length === 0
        ) {
            setLoadChecklistHumanResourcesVacation(true)
        }
        else {

            await api.post('/collaborator-checklist-vacation/', newChecklistHumanResourcesVacation)
            .then(response => {
                setLoadChecklistHumanResourcesVacation(false)
                async function loadcollaboratorchecklistvacation() {
                    await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=humanresources`)
                    .then(response => {
                        setHumanResourcesCheklistVacation(response.data);
                        setNewChecklistHumanResourcesVacation({});
                    })
                }
                loadcollaboratorchecklistvacation();
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "error"});
            });

        }
    }

    //saveNewCheckHumanResourcesVacation
    async function saveNewCheckHumanResourcesVacation(e) {
        e.preventDefault();
        if(e.target.checked === true) {
            let status = true;
            let _id = e.target.id;
            await api.put(`/collaborator-checklist-vacation/${_id}`, { status })
            .then(response => {
                async function loadhumanchecklistvacation() {
                    setLoadSolicitaçao(true)
                    await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=humanresources`)
                    .then(response => {
                        setLoadSolicitaçao(false)
                        setHumanResourcesCheklistVacation(response.data);
                    }).catch(error => {});
                }
                loadhumanchecklistvacation();
            }).catch(error => { });

        }
        else {
            let status = false;
            let _id = e.target.id;
            await api.put(`/collaborator-checklist-vacation/${_id}`, { status })
            .then(response => {
                async function loadhumanchecklistvacation() {
                    setLoadSolicitaçao(true)
                    await api.get(`/collaborator-checklist-vacation/?collaborator=${id}&category=humanresources`)
                    .then(response => {
                        setLoadSolicitaçao(false)
                        setHumanResourcesCheklistVacation(response.data);
                    }).catch(error => {});
                }
                loadhumanchecklistvacation();
            }).catch(error => {});
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////
    //updateCompleteAndGoToAccounting
    async function updateCompleteAndGoToAccounting(e) {
        e.preventDefault();
        let completeAndGoToAccounting = true;
        swal({
            title: "Atenção",
            text: "Deseja realmente concluir e ir à contabilidade?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {

                await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, { completeAndGoToAccounting })
                .then(response => {

                    let vacationCollaborator = collaboratorVacationInfo._id;

                    setTimeout(function(){window.location.href = `/ferias/${vacationCollaborator}`;}, 500);

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    setLoadJustificativa(false)
                });

            }
        })

    }
    return (
        <>
            <div className={loadHumanResourcesChecklistVacation === true ||
                    loadChecklistvacation  === true ||
                    loadSolicitaçao  === true ?"solicitacao load": "solicitacao"}>
                <h3 className="titulo-solicatacao">Status</h3>
                {
                    loadHumanResourcesChecklistVacation === true ||
                    loadChecklistvacation  === true ||
                    loadSolicitaçao  === true ? (
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    ) :
                    (<>
                    <div  className="menu">
                        <ul>
                            <li
                            onClick={bottomLiManagerApproval}
                            className="concluido"
                            >Aprovação do gestor
                            </li>
                            <li
                            onClick={
                                // role && role !== 'admin' ?
                                // () => {} :
                                bottomLiHumanResourcesApproval
                            }
                            className={
                                conclusionStatus                              &&
                                conclusionStatus                              === true       ?
                                "concluido" :
                                managerApprovalstatus                         &&
                                managerApprovalstatus                         === true       ?
                                '' :
                                humanResourcesApprovalstatus                  &&
                                humanResourcesApprovalstatus                  === true       ?
                                "concluido" :
                                collaboratorVacationInfo                      &&
                                collaboratorVacationInfo.managerStatus &&
                                collaboratorVacationInfo.managerStatus === "aprovado" ?
                                "concluido" :
                                ""
                            }
                            >
                                Aprovação do RH
                            </li>
                            <li
                            onClick={
                                (
                                    collaboratorVacationInfo?.humanResourcesStatus &&
                                    collaboratorVacationInfo?.humanResourcesStatus === "pendente"
                                )
                                ||
                                (
                                    collaboratorVacationInfo?.status &&
                                    collaboratorVacationInfo?.status === "reprovado"
                                ) ?
                                () => {}
                                :
                                // role && role !== 'admin' ? () => {} : 
                                bottomLiConclusionstatus
                            }
                            className={

                                conclusionStatus                       &&
                                conclusionStatus                       === true       ?
                                "concluidoUltimo" :
                                managerApprovalstatus                  &&
                                managerApprovalstatus                  === true       ?
                                '' :
                                humanResourcesApprovalstatus           &&
                                humanResourcesApprovalstatus           === true             &&
                                conclusionStatus                       === true              ?
                                "concluido" :
                                humanResourcesApprovalstatus           &&
                                humanResourcesApprovalstatus           === true             &&
                                conclusionStatus                       === false              ?
                                '' :
                                collaboratorVacationInfo               &&
                                collaboratorVacationInfo.humanResourcesStatus &&
                                collaboratorVacationInfo.humanResourcesStatus === "aprovado" ?
                                "concluidoUltimo" :
                                ''
                            }
                            >
                                Conclusão
                            </li>
                            {/* <li  className="concluidoUltimo">Conclusão</li> */}
                        </ul>
                    </div>

               
                    <div className="form-passos">
                        {/*
                            /////////////////////////////////////////////
                            Esse e o passo 1
                            /////////////////////////////////////////////
                            */}
                        <div
                        className="gestor"
                        style={
                            conclusionStatus                       &&
                            conclusionStatus                       === true        ?
                            { display:'none' }                                     :
                            managerApprovalstatus                  &&
                            managerApprovalstatus                  === true        ?
                            { display:'block' }                                    :
                            humanResourcesApprovalstatus           &&
                            humanResourcesApprovalstatus           === true        ?
                            { display:'none' }                                     :
                            collaboratorVacationInfo               &&
                            collaboratorVacationInfo.managerStatus &&
                            collaboratorVacationInfo.managerStatus === "reprovado" ?
                            { display:'block' }                                    :
                            collaboratorVacationInfo               &&
                            collaboratorVacationInfo.managerStatus &&
                            collaboratorVacationInfo.managerStatus === "aprovado"  ?
                            { display:'none' }                                     :
                            { display:'block' }
                        }
                        >
                            <h5 className="titulo-passos">Aprovação do gestor</h5>
                            <p className="infor-passos">Aqui o gestor pode analisar e aprovar ou
                                reprovar o pedido de férias do colaborador.</p>
                            <h6 className="sub-titulo-gestor">Gestor</h6>
                                    <div className="infor-gestor">
                                        {
                                            collaboratorVacationInfo?.collaborator?.manager?.avatar ?
                                            <>
                                            <div className="img-gestor">
                                                <img src={collaboratorVacationInfo?.collaborator?.manager?.avatar} alt="nome" />
                                            </div>
                                            </>
                                            :
                                            <>
                                                <div className="gestorSemFoto" style={{display:'none'}}>
                                                <div className="semImgCol">
                                                    <img  src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            </div>
                                            </>
                                        }
                                        <div className="nome-email">
                                            <p className="nome">
                                                {
                                                    collaboratorVacationInfo?.collaborator?.manager?.name !== null ?
                                                    collaboratorVacationInfo?.collaborator?.manager?.name :
                                                    ''
                                                }
                                            </p>
                                            <div className="email">
                                                <p>
                                                {
                                                    collaboratorVacationInfo?.collaborator?.manager?.email?
                                                    collaboratorVacationInfo?.collaborator?.manager?.email :
                                                    ''
                                                }
                                                </p>
                                            </div>
                                        </div>
                                    </div>


                            {/* o estatus tem em todos, e existe 3 tipos pendente, reprovado e aprovado */}
                            <div className="status-passos">
                                <p>Status: <span className={
                                    collaboratorVacationInfo &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === 'pendente' ?
                                    'pendente'
                                    :
                                    collaboratorVacationInfo &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === 'reprovado' ?
                                    'reprovado'
                                    :
                                    collaboratorVacationInfo &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === 'aprovado' ?
                                    'aprovado'
                                    :
                                    ''
                                }>{
                                    collaboratorVacationInfo &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === 'pendente' ?
                                    'pendente'
                                    :
                                    collaboratorVacationInfo &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === 'reprovado' ?
                                    'reprovado'
                                    :
                                    collaboratorVacationInfo &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === 'aprovado' ?
                                    'aprovado'
                                    :
                                    ''
                                }</span></p>
                            </div>
                            {
                                justificationOfFailure === false ?
                                <>
                                {
                                    role === 'manager' ?
                                    <div className="buttons-passos">
                                        {
                                            (collaboratorVacationInfo &&
                                            collaboratorVacationInfo.managerStatus &&
                                            collaboratorVacationInfo.managerStatus === 'reprovado') ||
                                            collaboratorVacationInfo.managerStatus === 'aprovado'  ?
                                            <>
                                            <button onClick={leavePending} className="btnPendente">Deixar pendente</button>
                                            </>
                                            :
                                            <>
                                            <button onClick={approveVacations} className="btnGreen">Aprovar</button>
                                            <button onClick={myChangeHandlerFailVacation} className="btnGray">Reprovar</button>
                                            </>
                                        }
                                    </div>
                                    :
                                    ''
                                }
                                </>
                                :
                                justificationOfFailure === true ?
                                <>
                                {/* So vai aparece se clicar em reprovar */}
                                <div className="justificativa-reprovacao">
                                    <textarea name="managerComments" onChange={myChangeHandlerManagerComments} placeholder="Digite uma justificativa…" className={loadJustificativa ? 'inputErro' : ''}  ></textarea>
                                    {loadJustificativa ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                    <button onClick={cancelAndJustify} className="btnConfirma">Justificar e cancelar solicitação de férias</button>
                                    <button onClick={backToRequest} className="btnCancela">Mudei de ideia, voltar à tela de aprovação</button>
                                </div>
                                </>
                                :
                                ''
                            }


                        </div>

                        {/*
                            /////////////////////////////////////////////
                            Esse e o passo 1 ---- fimm
                            /////////////////////////////////////////////
                        */}

                        {/*
                            /////////////////////////////////////////////
                            Esse e o passo 2
                            /////////////////////////////////////////////
                        */}
                        <div
                        className="aprovacao-RH"
                        style={
                            conclusionStatus                       &&
                            conclusionStatus                       === true        ?
                            { display:'none' }                                     :
                            humanResourcesApprovalstatus           &&
                            humanResourcesApprovalstatus           === true ?
                            { display:'block' }
                            :
                            managerApprovalstatus                  &&
                            managerApprovalstatus                  === true       ?
                            { display:'none' }                                    :
                            collaboratorVacationInfo               &&
                            collaboratorVacationInfo.humanResourcesStatus &&
                            collaboratorVacationInfo.humanResourcesStatus === "aprovado" ?
                            { display:'none' }                                                           :
                            collaboratorVacationInfo               &&
                            collaboratorVacationInfo.managerStatus &&
                            collaboratorVacationInfo.managerStatus === "aprovado" ?
                            { display:'block' }
                            :
                            { display:'none' }
                        }
                        >
                            <h5 className="titulo-passos">Aprovação do RH</h5>
                           
                                    <p className="infor-passos">Aqui o RH pode analisar e aprovar ou
                                    reprovar o pedido de férias do colaborador.</p>

                                    {/* o estatus tem em todos, e existe 3 tipos pendente, reprovado e aprovado */}
                                    <div className="status-passos">
                                        <p>Status: <span className={
                                            collaboratorVacationInfo &&
                                            collaboratorVacationInfo.humanResourcesStatus &&
                                            collaboratorVacationInfo.humanResourcesStatus === 'pendente' ?
                                            'pendente'
                                            :
                                            collaboratorVacationInfo &&
                                            collaboratorVacationInfo.humanResourcesStatus &&
                                            collaboratorVacationInfo.humanResourcesStatus === 'reprovado' ?
                                            'reprovado'
                                            :
                                            collaboratorVacationInfo &&
                                            collaboratorVacationInfo.humanResourcesStatus &&
                                            collaboratorVacationInfo.humanResourcesStatus === 'aprovado' ?
                                            'aprovado'
                                            :
                                            ''
                                        }>{
                                            collaboratorVacationInfo &&
                                            collaboratorVacationInfo.humanResourcesStatus &&
                                            collaboratorVacationInfo.humanResourcesStatus === 'pendente' ?
                                            'pendente'
                                            :
                                            collaboratorVacationInfo &&
                                            collaboratorVacationInfo.humanResourcesStatus &&
                                            collaboratorVacationInfo.humanResourcesStatus === 'reprovado' ?
                                            'reprovado'
                                            :
                                            collaboratorVacationInfo &&
                                            collaboratorVacationInfo.humanResourcesStatus &&
                                            collaboratorVacationInfo.humanResourcesStatus === 'aprovado' ?
                                            'aprovado'
                                            :
                                            ''
                                        }</span></p>
                                    </div>
                                    {
                                        role === "manager"? "":
                                        <>
                                    {
                                        justificationOfFailureHumanResources === false ?
                                        <>
                                        <div className="buttons-passos">
                                            {
                                                (collaboratorVacationInfo &&
                                                collaboratorVacationInfo.humanResourcesStatus &&
                                                collaboratorVacationInfo.humanResourcesStatus === 'reprovado') ||
                                                collaboratorVacationInfo.humanResourcesStatus === 'aprovado'  ?
                                                <>
                                                <button onClick={leavePendingHumanResources} disabled={role && role !== 'admin' ? true : false} className="btnPendente">Deixar pendente</button>
                                                </>
                                                :
                                                <>
                                                <button onClick={approveVacationsHumanResources} disabled={role && role !== 'admin' ? true : false} className="btnGreen">Aprovar</button>
                                                <button onClick={myChangeHandlerFailVacationToHumanResources} disabled={role && role !== 'admin' ? true : false} className="btnGray">Reprovar</button>
                                                </>
                                            }
                                        </div>
                                        </>
                                        :
                                        justificationOfFailureHumanResources === true ?
                                        <>
                                        {/* So vai aparece se clicar em reprovar */}
                                        <div className="justificativa-reprovacao">
                                            <textarea name="humanResourcesComments" onChange={myChangeHandlerCommentsHumanResources} placeholder="Digite uma justificativa…" className={loadJustificativaHumanResources ? 'inputErro' : ''}  ></textarea>
                                            {loadJustificativaHumanResources ? (
                                                <p className="textError">O campo é obrigatório.</p>
                                            ) : ('')}
                                            <button onClick={cancelAndJustifyHumanResources} className="btnConfirma">Justificar e cancelar solicitação de férias</button>
                                            <button onClick={backToRequestHumanResources} className="btnCancela">Mudei de ideia, voltar à tela de aprovação</button>
                                        </div>
                                        </>
                                        :
                                        ''
                                    }
                                </>

                            }
                        </div>
                        {/*
                            /////////////////////////////////////////////
                            Esse e o passo 2
                            fimmmm
                            /////////////////////////////////////////////
                            */}

                        {/*
                            /////////////////////////////////////////////
                            Esse e o passo 3
                            /////////////////////////////////////////////
                            */}

                        <div
                        className="conclusao"
                        style={
                            conclusionStatus                       &&
                            conclusionStatus                       === true        ?
                            { display:'block' }                                     :
                            humanResourcesApprovalstatus           &&
                            humanResourcesApprovalstatus           === true              ?
                            { display:'none' }
                            :
                            managerApprovalstatus                  &&
                            managerApprovalstatus                  === true              ?
                            { display:'none' }                                           :
                            collaboratorVacationInfo                      &&
                            collaboratorVacationInfo.humanResourcesStatus &&
                            collaboratorVacationInfo.humanResourcesStatus === "aprovado" ?
                            { display:'block' }                                          :
                            { display:'none' }
                        }>
                            <h5 className="titulo-passos">Conclusão</h5>
                            {
                            role === "manager"? <p className="infor-passos">Solicitação de férias está sendo concluída pelo RH.</p>:<>
                            <p className="infor-passos">Opcionalmente, você pode cadastrar itens restantes
                            que o <span>colaborador</span> deve realizar.</p>

                            {
                                collaboratorCheklistVacation &&
                                collaboratorCheklistVacation.length === 0 ?
                                'Nenhum checklist até o momemto' :
                                ''
                            }
                            <div className="checkListConclusao">
                            {
                                collaboratorCheklistVacation &&
                                collaboratorCheklistVacation[0] !== undefined ?
                                collaboratorCheklistVacation
                                .map((checlistCollaborator, index) => (
                                    <div className="chekboxNovo" key={index}>
                                        <input
                                        onClick={saveNewCheckCollaboratorVacation}
                                        type="checkbox"
                                        name="name"
                                        value={checlistCollaborator.name}
                                        id={checlistCollaborator._id}
                                        checked={
                                            checlistCollaborator.status === true ?
                                            true :
                                            checlistCollaborator.status === false ?
                                            false :
                                            ''
                                        }
                                        />
                                        <label htmlFor={checlistCollaborator._id}><span>{checlistCollaborator.name}</span></label>
                                    </div>
                                )) : ''
                            }



                            </div>






                            {
                                visibleAddItem ?
                                <div className="form-add-checkList" >
                                    <div className="btn-add-checkList">
                                        <button onClick={addCheckCollaboratorVacation} disabled={role && role !== 'admin' ? true : false} className="adicionar2"><img src={IconAddCircleGreen} alt="icone de mais" /> <span>Adicionar item </span></button>
                                    </div>
                                </div>
                                :
                                ''
                            }

                            {
                                visibleInputCheckCollaboratorVacation ?
                                <div className="form-add-checkList">  {/* apos clica em adicionar intem abre esse motal */}
                                    <input onChange={checklistCollaboratorVacation} type="text" name="name" value={newChecklistCollaboratorVacation && newChecklistCollaboratorVacation.name !== undefined ? newChecklistCollaboratorVacation.name : "" } placeholder="Digite o item do checklist…" className={loadChecklistCollaboratorVacation ? 'inputErro' : ''} />
                                    {loadChecklistCollaboratorVacation ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                    <div className="btn-add-checkList">
                                            <button onClick={saveCheckCollaboratorVacation} className="btnGreen adicionar">Adicionar</button>
                                            <button onClick={cancelCheckCollaboratorVacation}  className="cancelar">Cancelar</button>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            <p className="infor-passos">Opcionalmente, você pode cadastrar itens restantes
                            que o <span>RH</span> deve realizar.</p>

                            {
                                humanResourcesCheklistVacation &&
                                humanResourcesCheklistVacation.length === 0 ?
                                'Nenhum checklist até o momemto' :
                                ''
                            }

                            <div className="checkListConclusao">
                                {
                                    humanResourcesCheklistVacation &&
                                    humanResourcesCheklistVacation[0] !== undefined ?
                                    humanResourcesCheklistVacation
                                    .map((checlistHumanResources, index) => (
                                        <div className="chekboxNovo" key={index}>
                                            <input
                                            onClick={saveNewCheckHumanResourcesVacation}
                                            type="checkbox"
                                            name="name"
                                            value={checlistHumanResources.name}
                                            id={checlistHumanResources._id}
                                            checked={
                                                checlistHumanResources.status === true ?
                                                true :
                                                checlistHumanResources.status === false ?
                                                false :
                                                ''
                                            }
                                            />
                                            <label htmlFor={checlistHumanResources._id}><span>{checlistHumanResources.name}</span></label>
                                        </div>
                                    )) : ''
                                }


                            </div>
                            {
                            visibleAddItemHumanResources ?
                                <div className="form-add-checkList" >
                                    <div className="btn-add-checkList">
                                        <button onClick={addCheckHumanResourcesVacation} disabled={role && role !== 'admin' ? true : false} className="adicionar2"><img src={IconAddCircleGreen} alt="icone de mais" /> <span>Adicionar item </span></button>
                                    </div>
                                </div>
                                :
                                ''
                            }


                            {
                                visibleInputCheckhumanResourcesVacation ?
                                <div className="form-add-checkList">  {/* apos clica em adicionar intem abre esse motal */}
                                    <input onChange={checklistHumanResourcesVacation} type="text" name="name" placeholder="Digite o item do checklist…" value={newChecklistHumanResourcesVacation && newChecklistHumanResourcesVacation.name !== undefined ? newChecklistHumanResourcesVacation.name : "" } className={loadChecklistHumanResourcesVacation ? 'inputErro' : ''} />
                                    {loadChecklistHumanResourcesVacation ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                    <div className="btn-add-checkList">
                                            <button onClick={saveCheckHumanResourcesVacation} className="btnGreen adicionar">Adicionar</button>
                                            <button onClick={cancelCheckHumanResourcesVacation}  className="cancelar">Cancelar</button>
                                    </div>
                                </div>
                                :
                                ''
                            }


                            <button onClick={updateCompleteAndGoToAccounting} disabled={role && role !== 'admin' ? true : false} className="btnFinalizarProcesso btnGreen">Concluir solicitação (irá para contabilidade)</button>

                            </>}
                        </div>
                        {/*
                            /////////////////////////////////////////////
                            Esse e o passo 3
                            fimmmm
                            /////////////////////////////////////////////
                        */}

                        <div className="anteriorproximo">

                                {
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    humanResourcesApprovalstatus           === false  &&
                                    collaboratorVacationInfo                          &&
                                    collaboratorVacationInfo.managerStatus            &&
                                    collaboratorVacationInfo.managerStatus === "pendente"    ?
                                    // <button disabled className="anterior">
                                    //     anterior 1
                                    // </button>
                                    ''
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    humanResourcesApprovalstatus           === true   ?
                                    <button onClick={backVacationsManagerApproval} className="anterior">
                                        anterior
                                    </button>
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "aprovado" ?
                                    <button onClick={backVacationsHumanResourcesApproval} className="anterior">
                                        anterior
                                    </button>
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "Pendente" ?
                                    <button onClick={backVacationsManagerApproval} className="anterior">
                                        anterior
                                    </button>
                                    :
                                    conclusionStatus                       &&
                                    conclusionStatus                       === true        ?
                                    <button onClick={backVacationsHumanResourcesApproval} className="anterior">
                                        anterior
                                    </button>
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "reprovado"  ?
                                    <button onClick={backVacationsHumanResourcesApproval} className="anterior">
                                        anterior
                                    </button>
                                    :
                                    ''
                                }
                                {
                                    conclusionStatus                       === false      &&
                                    managerApprovalstatus                  === false      &&
                                    humanResourcesApprovalstatus           === false      &&
                                    role                                   === 'manager'  &&
                                    collaboratorVacationInfo                              &&
                                    collaboratorVacationInfo.managerStatus                &&
                                    collaboratorVacationInfo.managerStatus === "pendente" ?
                                    <button disabled className="proxima">próxima</button>
                                    :
                                    conclusionStatus                              === false     &&
                                    managerApprovalstatus                         === false     &&
                                    humanResourcesApprovalstatus                  === false     &&
                                    role                                          !== 'manager' &&
                                    collaboratorVacationInfo                                    &&
                                    collaboratorVacationInfo.humanResourcesStatus               &&
                                    collaboratorVacationInfo.humanResourcesStatus === "pendente" ?
                                    <button onClick={bottomLiHumanResourcesApproval} disabled={role === "admin" ? false : true} className="proxima">próxima</button>
                                    :
                                    collaboratorVacationInfo.managerStatus === "reprovado" ?
                                    ''
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === true   &&
                                    humanResourcesApprovalstatus           === false   ?
                                    <button onClick={bottomLiHumanResourcesApproval} className="proxima">próxima</button>
                                    :
                                    conclusionStatus                       === true  &&
                                    managerApprovalstatus                  === true  &&
                                    humanResourcesApprovalstatus           === true   ?
                                    // <button disabled className="proxima">próxima conc</button>
                                    ''
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo?.humanResourcesStatus === "aprovado" &&
                                    humanResourcesApprovalstatus           === true   ?
                                    <button onClick={bottomLiConclusionstatus} disabled={role && role !== 'admin' ? true : false} className="proxima">próxima</button>
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "aprovado" ?
                                    // <button disabled className="proxima">próxima</button>
                                    ''
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.managerStatus &&
                                    collaboratorVacationInfo.managerStatus === "reprovado"  ?
                                    <button onClick={bottomLiHumanResourcesApproval} className="proxima">próxima</button>
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "pendente"  ?
                                    // <button disabled className="proxima">próxima6</button>
                                    ''
                                    :
                                    conclusionStatus                       === true  &&
                                    managerApprovalstatus                  === false  &&
                                    humanResourcesApprovalstatus           === true   ?
                                    <button disabled className="proxima">próxima</button>
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "reprovado"  ?
                                    ''
                                    :
                                    conclusionStatus                       === false  &&
                                    managerApprovalstatus                  === false  &&
                                    collaboratorVacationInfo                      &&
                                    collaboratorVacationInfo.humanResourcesStatus &&
                                    collaboratorVacationInfo.humanResourcesStatus === "aprovado" ?
                                    <button onClick={bottomLiConclusionstatus} className="proxima">próxima</button>
                                    :
                                    ''
                                }

                        </div>
                    </div>
                    </>
                    )
                }
            </div>
        </>
    );
}
