import React,{
    useEffect,
    useState,
    useRef
} from "react";
import Baseboard from '../../components/WebsiteVacancies/Baseboard'
import VacnciesPageConteudo from '../../components/WebsiteVacancies/vacnciesPageConteudo'
import VacnciesPageForm from '../../components/WebsiteVacancies/vacnciesPageForm'
import { NavLink } from 'react-router-dom'
import api from '../../services/api'
import SkeletonVaga from '../../components/WebsiteVacancies/skeleton/skeletonVaga'
import "./styles.scss";
export default function VacanciesPage(props) {

    const id = props.match.params.id;
    const id_vacancie = props.match.params.id_vacancie;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusForm            = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPagenNaoEncontrada, setLoadPagenNaoEncontrada] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadVaga(id_vacancie);
        loadPageVacancie(id);
    }, [id_vacancie, id]);



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ inforVacancyPage, setInforVacancyPage] = useState([]);

    async function loadPageVacancie(id) {

        await api.get(`/vacancie-page-appearance/${id}`)
        .then(response => {


            if(response.data === null){
                setLoadPagenNaoEncontrada(true)
            }else {
                setInforVacancyPage(response.data[0]);
            }
        }).catch(error => {
        })

    }


    const filesPage =  inforVacancyPage.vacancyPageAppearanceFileSearch;
    const aparencePage =  inforVacancyPage.vacancyPageAppearanceSearch;
    const PrivacyPolicyPage =  inforVacancyPage.vacancyPageAppearancePrivacyPolicySearch;
    const statusPageConfig =  aparencePage?.vacancyPageAppearanceConfig[0].status;


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadVagaC, setLoadVaga] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [inforVaga, setInforVaga] = useState("");

    async function loadVaga(id_vacancie) {
        setLoadVaga(true)

        await api.get(`/vacancie-public/${id_vacancie}`)
        .then(response => {
            setInforVaga(response.data);
            setLoadVaga(false);
        }).catch(error => {
            setLoadVaga(false);
        })
    }
    async function focarForm(){
        focusForm.current.focus();
    }
    return(
        <>
            {
                loadVagaC ?
                    <div className="page-website-vacancies vacancies-page" >
                        <SkeletonVaga/>
                    </div>
                :
                loadPagenNaoEncontrada ?
                <div className="box-error pagina-nao-encontrada">
                    <h1>404</h1>
                    <p>Não encontramos esta página.</p>
                    <span>© 2021 People View</span>
                </div>
                :
                statusPageConfig && statusPageConfig === "inactive" ?
                <div className="box-error pagina-nao-encontrada">
                    <h1>Página em contrução</h1>
                    {/* <p>Não encontramos esta página.</p> */}
                    <span>© 2021 People View</span>
                </div>

            :
            <div className="page-website-vacancies vacancies-page" >
            
                <div className="header">
                    <div className="voltar">
                        <NavLink to={`/lp/${id}`} >Ver todas as vagas</NavLink>
                    </div>
                    <div className="infor-aplicar">
                        <div className="infor">
                            <h2>{inforVaga?.title}</h2>
                            <ul>
                                <li>{inforVaga?.bornCityString} - {inforVaga?.bornStateString}</li>
                                <li>{inforVaga?.laborBond}</li>
                                <li>{inforVaga?.type}</li>
                                <li>{inforVaga?.companyId?.name}</li>
                            </ul>
                        </div>
                        <button className="btn-aplicar-vaga"onClick={focarForm}>Aplicar para vaga</button>
                    </div>

                </div>


                <div className="conteudo">
                    <VacnciesPageConteudo
                        inforVaga={inforVaga}
                    />
                    <VacnciesPageForm
                    companyId={aparencePage?.companyId}
                    id_vacancie={id_vacancie}
                    focusForm={focusForm}
                    urlLp={props.match.params.id}
                    />
                </div>
            



                {
                aparencePage?.footer === true ?
                        <Baseboard
                            aparencePage={aparencePage}
                            filesPage={filesPage?filesPage : ""}
                            PrivacyPolicyPage={PrivacyPolicyPage?PrivacyPolicyPage[0] : ""}
                        />
                    :""
                }
            </div>
        }


        </>
    )
}
