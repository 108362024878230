import React, { useState, useEffect } from 'react';
import Layout from '../../../../components/Layouts/default';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

import Header from '../../../../components/Administrativo/Colaboradores/header';
import HeaderNavigation from '../../../../components/Administrativo/Colaboradores/headerNavigation';
import ListDeligging from '../../../../components/Administrativo/Colaboradores/Desligamento/deliggingList';
import './style.scss';
import { decodeToken } from '../../../../services/auth';


export default function(props) {
    const { history } = props;
    const { user } = decodeToken();
    const role        = user.role;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allShutdown, setAllShutdown ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(0);
    const [ pages, setPages ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ total, setTotal ] = useState(0);
    useEffect(() => {
        loadallshutdown(page, nameFilter);
    }, [page, nameFilter]);

    async function loadallshutdown(page, nameFilter) {
        setLoad(true)
        await api.get(`/collaborator-new/?status=Inativo&role=admin&role=manager&role=user&name=${nameFilter}&sortBy=name&page=${page}&limit=20&shutdownProcess=false&admissionProcess=false`)
        .then(response => {
            setLoad(false)
            setAllShutdown(response.data.collaboratorSearch);
            setPages(response.data.pages)
            setTotal(response.data.total)
        }).catch(error =>  {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoad(false)
        });
    }
    async function loadfilterpercollaborator(event) {
        setLoad(true);
        const name = event;
        setNameFilter(name);
        loadallshutdown(page, name)
    }
    async function prevPage(page) {
        if (page === 0) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        loadallshutdown(pageNumber, nameFilter)
    }

    async function nextPage(page) {
        if (page === pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        loadallshutdown(pageNumber, nameFilter)
    }

    async function paginationPage(page) {
        const pageNumber = page;
        setPage(pageNumber);
        loadallshutdown(pageNumber, nameFilter)
    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                history={history}
                user={user}
                />
                <HeaderNavigation
                    nameFilter={nameFilter}
                    path={props.match.path} 
                    onUserInputChange={(event) => loadfilterpercollaborator(event)}
                    role={role}
                    user={user}
                 />
                <ListDeligging
                nameFilter={nameFilter}
                path={props.match.path}
                onUserInputChange={(event) => loadfilterpercollaborator(event)}
                loadshutdown={load}
                allShutdown={allShutdown}
                page={page}
                pages={pages}
                total={total}
                prevPage={prevPage}
                nextPage={nextPage}
                paginationPage={paginationPage}
                load={load}
                />

            </Layout>
        </>
    );
}
