import React from 'react';
import IconUserNone from '../../../../../../assets/images/iconUserNone.svg';

const InforCol = () => {

    const avatar                    = localStorage.getItem('@peopleview/avatar');
    const nameprofile               = localStorage.getItem('@peopleview/nameProfile');
    const collaboratorsalary        = localStorage.getItem('@peopleview/collaboratorSalary');
    const collaboratorsector        = localStorage.getItem('@peopleview/collaboratorSector');
    const collaboratoradmissionDate = localStorage.getItem('@peopleview/collaboratorAdmissionDate');
    const collaboratoroccupation    = localStorage.getItem('@peopleview/collaboratorOccupation');

return (
    <>
       <div className="infor-colaborador">
            <div className="box">
                    {
                    avatar && avatar !== undefined ? 
                        <img src={avatar} alt={nameprofile} className='fotoColl'/>
                    : 
                        <div className="semImgCol">
                            <img src={IconUserNone} alt={nameprofile} />
                        </div>
                    }

                    

                    <h4 className="col-nome">{
                        nameprofile && nameprofile !== undefined ?
                        nameprofile : "..."
                    }</h4>
                    <p className="col-cargo">{
                        collaboratoroccupation && collaboratoroccupation !== undefined ?
                        collaboratoroccupation : "..."
                    }</p>
                    <div  className="col-infor">
                        <div>
                            <p className="col-titulo">Departamento</p>
                            <span className="col-text">{
                                collaboratorsector && collaboratorsector !== undefined ?
                                collaboratorsector : "..."
                            }</span>
                        </div>

                        <div>
                            <p className="col-titulo">Salário</p>
                            {
                            collaboratorsalary && collaboratorsalary !== undefined ?
                            (<span className="col-text">R${collaboratorsalary}</span>) :
                            ('...')
                            }
                        </div>

                        <div>
                            <p className="col-titulo">Admissão</p>
                            <span className="col-text">{collaboratoradmissionDate}</span>
                        </div>

                        <div>

                            <p className="col-titulo">Desligamento</p>
                            <span className="col-text">Em andamento</span>
                            
                        </div>

                    </div>
            </div>
       </div>

    </>
);
}
export default InforCol;
