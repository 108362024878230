import React from 'react';
import './TextEditor.scss';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default function EditorText({
    myChangeHandlerTitulo, 
    titleValor, 
    nameTitle, 
    myChangeHandlerEditor,
    title, 
    placeholderTitle, 
    placeholderEditor,
    descritionValor
}) {
    // CKEditor.setData( '<p>Some text.</p>' );

    return (
        <>
            <div className='text-editor-general'>
                {
                    title && title === "noTitle" ?""
                    :
                    <input type="text"
                        className='title-input'
                        name={nameTitle}
                        defaultValue={titleValor && titleValor ? titleValor : ""} 
                        onChange={myChangeHandlerTitulo}
                        placeholder={placeholderTitle}
                    />
                }
                <div className={title && title === "noTitle" ? "editorNoTitle": ""}>
                    <CKEditor
                    editor={ ClassicEditor}
                    onChange={myChangeHandlerEditor }
                    data={descritionValor}
                    config ={
                        {
                        toolbar: [ 'bold', 'italic', 'bulletedList' ],
                        placeholder: placeholderEditor,
                        velue: "Acertei"
                        }
                    }
                    />

                </div>
            </div>
        </>
    )
}