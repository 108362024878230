import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask'
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Close from '../../../../../../assets/images/close.svg';
import swal from 'sweetalert';
import axios from 'axios';
import Carregando from '../../../../../../assets/images/carregando.gif';
import { dateMask, mCNPJ, cep, mNumber } from '../../../../../../services/mask';
import api from '../../../../../../services/api';
import { toast } from 'react-toastify';

export default function ColProfileInternInformation({ id, path }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredInternInformation, setRegisteredInternInformation ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ internInformationUpdate, setInternInformationUpdate ] = useState([]);

    const [ loadErrorinitialDate, setloadErrorinitialDate ] = useState(false);

    const [ loadErrorfinalDate, setloadErrorfinalDate ] = useState(false);

    const [ loadErroreducationalInstitution, setloadErroreducationalInstitution ] = useState(false);

    //natureOfTheInternship

    const focusInitialDate   = useRef(null);
    const focusFinalDate   = useRef(null);
    const focusEducationalInstitution   = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

       if(id && id) {loadInternInformation(id);}

    }, [id]);

    async function loadInternInformation(id) {
        setLoadPage(true)
        await api.get(`/collaborator-intern-information/${id}`)
        .then(response => {
            setLoadPage(false)
            if(response.data[0] !== undefined) {

                setRegisteredInternInformation(response.data[0]);

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    /////////////////////////////////////////////////////////////////////  CNPJEducationalInstitution


    const  myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;

        if (nam === "zipcode") {

            getAddress(val)
            let zipcode = cep(val)
            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: zipcode })

        }
        else
        if (nam === "zipcode") {
            getAddress(val)
        }
        else
        if (nam === "initialDate") {

            setloadErrorinitialDate(false)
            let initialDate = dateMask(val)
            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: initialDate, path: path })

        }
        else
        if (nam === "finalDate") {

            setloadErrorfinalDate(false)
            let finalDate = dateMask(val)
            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: finalDate })

        }
        else
        if (nam === "educationalInstitution") {

            setloadErroreducationalInstitution(false)
            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: val })

        }
        else
        if (nam === "CNPJEducationalInstitution") {

            let CNPJEducationalInstitution = mCNPJ(val)
            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: CNPJEducationalInstitution })

        } else if (nam === "addressNumber") {

            let addressNumber = mNumber(val)
            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: addressNumber })

        }   else {

            setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: val })
        }

    };

    async function getAddress(zipcode) {
    zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
    if (zipcode.length >= 8) {
        //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
        const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
        //    console.log(response.data)
        if (response.data !== null) {
                setInternInformationUpdate({
                ...internInformationUpdate,
                zipcode: zipcode,
                address: response.data.logradouro,
                addressNeighborhood : response.data.bairro,
                addressCity: response.data.localidade,
                addressState: response.data.uf,
            })
        }
    }
    }


    //////////////////////////////////////////////////////////////////////
    /////////////////////       Loads         ///////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);




    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModal, setVisibleModal] = useState(false);
    const fecharModalProfileInterInfor = (e) => {
    if (e.target.id === 'idModal') setVisibleModal(false);
    }

    function AbrirModalProfileInterInfor(e) {
        setVisibleModal(true)
        let registeredInternInformation = e;

        setRegisteredInternInformation({
            ...registeredInternInformation
        });

        setInternInformationUpdate([]);
        setloadErrorinitialDate(false);
        setloadErrorfinalDate(false);
        setloadErroreducationalInstitution(false);

    }


    async function handleSubmit(event) {

        event.preventDefault();

        if(registeredInternInformation && registeredInternInformation._id !== undefined) {
            if(internInformationUpdate.length === 0){
                swal({ icon: "error", title: "Erro!", text:'Necessário atualizar pelo menos um campo.' });
            } else {

                if(
                    internInformationUpdate.initialDate                === "" ||
                    internInformationUpdate.finalDate                  === "" ||
                    internInformationUpdate.educationalInstitution     === "" ||
                    internInformationUpdate.CNPJEducationalInstitution === "" ||
                    internInformationUpdate.zipcode                    === "" ||
                    internInformationUpdate.address                    === "" ||
                    internInformationUpdate.addressCity                === "" ||
                    internInformationUpdate.addressNeighborhood        === "" ||
                    internInformationUpdate.addressState               === ""
                    ) {
                    swal({ icon: "error", title: "Erro!", text:'Apenas os campos Número e Complemento podem ser salvos em branco!' });
                } else {

                    setLoad(true)
                    await api.put(`/collaborator-intern-information/${registeredInternInformation._id}`, internInformationUpdate)
                    .then(response => {
                        async function loadfilessaved() {
                            await api.get(`/collaborator-intern-information/${id}`)
                            .then(response => {
                                setLoad(false)
                                setRegisteredInternInformation(response.data[0]);
                                setInternInformationUpdate([])
                            }).catch(error => {
                                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                            });
                        }
                        loadfilessaved();
                        swal({ icon: "success", title: "Sucesso!", text: "Informações para Estagiário atualizada com sucesso!" });
                        setVisibleModal(false)
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!",  text: error.response.data.message });
                        setLoad(true)
                    });

                }

            }

        } else {

            if(internInformationUpdate.length === 0){
                setloadErrorinitialDate(true)
                setloadErrorfinalDate(true)
                setloadErroreducationalInstitution(true)
                focusInitialDate.current.focus()
            } else {


                if(internInformationUpdate.initialDate === undefined || internInformationUpdate.initialDate === "") {
                    setloadErrorinitialDate(true)
                    focusInitialDate.current.focus()
                    return
                }

                if(internInformationUpdate.finalDate === undefined || internInformationUpdate.finalDate === "") {
                    setloadErrorfinalDate(true)
                    focusFinalDate.current.focus()
                    return
                }

                if(internInformationUpdate.educationalInstitution === undefined || internInformationUpdate.educationalInstitution === "") {
                    setloadErroreducationalInstitution(true)
                    focusEducationalInstitution.current.focus()
                    return
                }

                setLoad(true)
                await api.post(`/collaborator-intern-information/`, internInformationUpdate)
                .then(response => {
                    async function loadfilessaved() {
                        await api.get(`/collaborator-intern-information/${id}`)
                        .then(response => {
                            setLoad(false)
                            setRegisteredInternInformation(response.data[0]);
                            setInternInformationUpdate([])
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Exame períotico atualizada com sucesso!" });
                    setVisibleModal(false)
                }).catch(error => {
                    console.log(error)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoad(false)
                });
            }
        }
    }

    return (
        <>

                <div className="box InternInformation">
                    <h3>Informações para Estagiário
                    {
                        localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                        ""
                        :
                        <button className="edit-Benefit" onClick={() => AbrirModalProfileInterInfor(registeredInternInformation)}><img src={EditBlack} alt="Edite Informações" /></button>
                    }
                    </h3>
                    {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregando} />
                                </div>
                                </>
                            ):(
                                <>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Data de início</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.initialDate !== undefined ?(registeredInternInformation.initialDate) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data de término</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.finalDate !== undefined ?(registeredInternInformation.finalDate) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Natureza do estágio</p>
                            </div>
                            <div className="intemB nome">
                                <p>{
                                registeredInternInformation.natureOfTheInternship !== undefined &&
                                registeredInternInformation.natureOfTheInternship === 'true' ? 'Obrigatório' :
                                registeredInternInformation.natureOfTheInternship === 'false' ? 'Não obrigatório' :
                                'Não obrigatório'
                                }
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Instituição de ensino</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.educationalInstitution !== undefined ?(registeredInternInformation.educationalInstitution) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>CNPJ da instituição de ensino</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.CNPJEducationalInstitution !== undefined ?(registeredInternInformation.CNPJEducationalInstitution) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>CEP</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.zipcode !== undefined ?(registeredInternInformation.zipcode) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Endereço</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.address !== undefined ?(registeredInternInformation.address) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Número</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.addressNumber !== undefined ?(registeredInternInformation.addressNumber) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Complemento</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.addressComplement !== undefined ?(registeredInternInformation.addressComplement) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Bairro</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.addressNeighborhood !== undefined ?(registeredInternInformation.addressNeighborhood) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>UF</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.addressState !== undefined ?(registeredInternInformation.addressState) : ('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Cidade</p>
                            </div>
                            <div className="intemB nome">
                                <p>{registeredInternInformation.addressCity !== undefined ?(registeredInternInformation.addressCity) : ('-')}</p>
                            </div>
                        </li>

                    </ul>
                    </>)}
                </div>




                {visibleModal ?
                    <div className="modalGeral" id="idModal" onClick={fecharModalProfileInterInfor}>

                        <div className="boxModal">
                                <div className="titulo">
                                    <h4>Informações para Estagiário  <p className="btn-fechar" onClick={() => setVisibleModal(false)}> <img src={Close} alt="Fechar" /></p></h4>
                                </div>
                                <ul>
                                    <li>
                                        <div className="intemA">
                                            <p>Data de início</p>
                                        </div>

                                        <div className="intemB">
                                            {
                                                (internInformationUpdate && internInformationUpdate.initialDate !== undefined) || internInformationUpdate.initialDate === '' ?
                                                (
                                                    <input ref={focusInitialDate} type="text" name="initialDate" value={(internInformationUpdate.initialDate !== undefined) ? internInformationUpdate.initialDate : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className={loadErrorinitialDate ? 'inputErro' : ''}/>
                                                ) :
                                                registeredInternInformation && registeredInternInformation.initialDate ? (
                                                    <input ref={focusInitialDate} type="text" name="initialDate" defaultValue={(registeredInternInformation.initialDate !== undefined) ? registeredInternInformation.initialDate : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  />
                                                )
                                                :
                                                    <input ref={focusInitialDate} type="text" name="initialDate" value={(internInformationUpdate.initialDate !== undefined) ? internInformationUpdate.initialDate : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className={loadErrorinitialDate ? 'inputErro' : ''} />
                                            }
                                            <p className="textError">{loadErrorinitialDate ? 'É necessário xxxxxxx' : ''}</p>
                                        </div>

                                    </li>

                                    <li>
                                        <div className="intemA">
                                            <p>Data de término</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                                (internInformationUpdate && internInformationUpdate.finalDate !== undefined) || internInformationUpdate.finalDate === '' ?
                                                (
                                                    <input ref={focusFinalDate} type="text" name="finalDate" value={(internInformationUpdate.finalDate !== undefined) ? internInformationUpdate.finalDate : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className={loadErrorfinalDate ? 'inputErro' : ''}/>
                                                ) :
                                                registeredInternInformation && registeredInternInformation.finalDate && internInformationUpdate.finalDate !== ''? (
                                                    <input ref={focusFinalDate} type="text" name="finalDate" defaultValue={(registeredInternInformation.finalDate !== undefined) ? registeredInternInformation.finalDate : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"/>
                                                ):

                                                    <input ref={focusFinalDate} type="text" name="finalDate" value={(internInformationUpdate.finalDate !== undefined) ? internInformationUpdate.finalDate : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className={loadErrorfinalDate ? 'inputErro' : ''}/>
                                            }
                                            <p className="textError">{loadErrorfinalDate ? 'É necessário xxxxxxx' : ''}</p>
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Natureza do estágio</p>
                                        </div>
                                        <div className="intemB">
                                                {registeredInternInformation.natureOfTheInternship === "true" ? (
                                            <div className="radio">
                                                <input type="radio" value="true" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignSim" checked/>
                                                <label htmlFor="foreignSim" className="chackboxLabel"><span></span></label>
                                                <label htmlFor="foreignSim" className="foreignTexto">Obrigatório</label> <br/>

                                                <input type="radio" value="false" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignNao"/>
                                                <label htmlFor="foreignNao" className="chackboxLabel"><span></span></label>
                                                <label htmlFor="foreignNao" className="foreignTexto">Não obrigatório</label>
                                                </div>

                                                ) : registeredInternInformation.natureOfTheInternship === "false" ? (
                                                <div className="radio">
                                                <input type="radio" value="true" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignSim"/>
                                                <label htmlFor="foreignSim" className="chackboxLabel"><span></span></label>
                                                <label htmlFor="foreignSim" className="foreignTexto">Obrigatório</label> <br/>

                                                <input type="radio" value="false" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignNao" checked/>
                                                <label htmlFor="foreignNao" className="chackboxLabel"><span></span></label>
                                                <label htmlFor="foreignNao" className="foreignTexto">Não obrigatório</label>
                                                </div>

                                                ) : (
                                                    <div className="radio">
                                                    <input type="radio" value="true" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignSim"/>
                                                    <label htmlFor="foreignSim" className="chackboxLabel"><span></span></label>
                                                    <label htmlFor="foreignSim" className="foreignTexto">Obrigatório</label> <br/>

                                                    <input type="radio" value="false" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignNao" checked/>
                                                    <label htmlFor="foreignNao" className="chackboxLabel"><span></span></label>
                                                    <label htmlFor="foreignNao" className="foreignTexto">Não obrigatório</label>
                                                </div>
                                                )}
                                        </div>

                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Instituição de ensino</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                            registeredInternInformation.educationalInstitution      !== undefined ?
                                            <input ref={focusEducationalInstitution} type="text" name="educationalInstitution"  onChange={myChangeHandler} defaultValue={registeredInternInformation.educationalInstitution}  />
                                            :
                                            <input ref={focusEducationalInstitution} type="text" name="educationalInstitution"  onChange={myChangeHandler} placeholder="Instituição de ensino" className={loadErroreducationalInstitution ? 'inputErro' : ''} />
                                            }
                                            <p className="textError">{loadErroreducationalInstitution ? 'É necessário xxxxxxx' : ''}</p>
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>CNPJ da instituição de ensino</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                                (internInformationUpdate && internInformationUpdate.CNPJEducationalInstitution !== undefined) || internInformationUpdate.CNPJEducationalInstitution === '' ?
                                                (
                                                    <input type="text" maxlength="18" name="CNPJEducationalInstitution" value={(internInformationUpdate.CNPJEducationalInstitution !== undefined) ? internInformationUpdate.CNPJEducationalInstitution : ''}  onChange={myChangeHandler} placeholder="CNPJ"  />
                                                ) :
                                                registeredInternInformation && registeredInternInformation.CNPJEducationalInstitution ? (
                                                    <input type="text" maxlength="18" name="CNPJEducationalInstitution" defaultValue={(registeredInternInformation.CNPJEducationalInstitution !== undefined) ? registeredInternInformation.CNPJEducationalInstitution : ''}  onChange={myChangeHandler} placeholder="CNPJ" className="inputUm"/>
                                                ):
                                                (
                                                    <input type="text" maxlength="18" name="CNPJEducationalInstitution" value={(internInformationUpdate.CNPJEducationalInstitution !== undefined) ? internInformationUpdate.CNPJEducationalInstitution : ''}  onChange={myChangeHandler} placeholder="CNPJ"  />
                                                )}
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>CEP</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                                (internInformationUpdate && internInformationUpdate.zipcode !== undefined) || internInformationUpdate.zipcode === '' ?
                                                (
                                                    <input type="text"  maxlength="8" name="zipcode" value={(internInformationUpdate.zipcode !== undefined) ? internInformationUpdate.zipcode : ''}  onChange={myChangeHandler} placeholder="CEP"  />
                                                ) :
                                                registeredInternInformation && registeredInternInformation.zipcode ? (
                                                    <input type="text"  name="zipcode" defaultValue={(registeredInternInformation.zipcode !== undefined) ? registeredInternInformation.zipcode : ''}  onChange={myChangeHandler} placeholder="CEP" className="inputUm"/>
                                                ):
                                                (
                                                    <input type="text"  maxlength="8" name="zipcode" value={(internInformationUpdate.zipcode !== undefined) ? internInformationUpdate.zipcode : ''}  onChange={myChangeHandler} placeholder="CEP"  />
                                                )}
                                            {/* {registeredInternInformation.zipcode      !== undefined ?
                                            (<InputMask type="text" name="zipcode" onChange={myChangeHandler}  defaultValue={registeredInternInformation.zipcode}/>) :
                                            (<InputMask type="text" name="zipcode" onChange={myChangeHandler}  placeholder="CEP" />) } */}
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Endereço</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                            (internInformationUpdate && internInformationUpdate.address) || internInformationUpdate.address === "" ?
                                            (<input type="text" name="address" onChange={myChangeHandler} defaultValue={internInformationUpdate.address} placeholder="Endereço"/>):
                                            registeredInternInformation.address    !== undefined ?
                                            (<input type="text" name="address" onChange={myChangeHandler} defaultValue={registeredInternInformation.address} placeholder="Endereço"/>) :
                                            (<input type="text" name="address" onChange={myChangeHandler} placeholder="Endereço" />) }
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Número</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                                (internInformationUpdate && internInformationUpdate.addressNumber !== undefined) || internInformationUpdate.addressNumber === '' ?
                                                (
                                                    <input type="text"  maxlength="8" name="addressNumber" defaultValue={(internInformationUpdate.addressNumber !== undefined) ? internInformationUpdate.addressNumber : ''}  onChange={myChangeHandler} placeholder="Número"  />
                                                ) :
                                                registeredInternInformation && registeredInternInformation.addressNumber ? (
                                                    <input type="text"  name="addressNumber" defaultValue={(registeredInternInformation.addressNumber !== undefined) ? registeredInternInformation.addressNumber : ''}  onChange={myChangeHandler} placeholder="Número" className="inputUm"/>
                                                ):
                                                (
                                                    <input type="text"  maxlength="8" name="addressNumber" value={(internInformationUpdate.addressNumber !== undefined) ? internInformationUpdate.addressNumber : ''}  onChange={myChangeHandler} placeholder="Número"  />
                                                )}
                                            {/* {registeredInternInformation.addressNumber    !== undefined ?
                                            (<InputMask type="text" name="addressNumber" onChange={myChangeHandler}  defaultValue={registeredInternInformation.addressNumber}/>) :
                                            (<InputMask type="text" name="addressNumber"  onChange={myChangeHandler} placeholder="Número" />) } */}
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Complemento</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                            (internInformationUpdate && internInformationUpdate.addressComplement) || internInformationUpdate.addressComplement === "" ?
                                            (<input type="text" name="addressComplement"  onChange={myChangeHandler} defaultValue={internInformationUpdate.addressComplement}/>):
                                            registeredInternInformation.addressComplement     !== undefined ?
                                            (<input type="text" name="addressComplement"  onChange={myChangeHandler} defaultValue={registeredInternInformation.addressComplement}/>) :
                                            (<input type="text" name="addressComplement"  onChange={myChangeHandler} placeholder="Complemento" />) }
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Bairro</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                            (internInformationUpdate && internInformationUpdate.addressNeighborhood) || internInformationUpdate.addressNeighborhood === "" ?
                                            (<InputMask type="text" name="addressNeighborhood"  onChange={myChangeHandler} defaultValue={internInformationUpdate.addressNeighborhood} />):
                                            registeredInternInformation.addressNeighborhood     !== undefined ?
                                            (<InputMask type="text" name="addressNeighborhood"  onChange={myChangeHandler} value={registeredInternInformation.addressNeighborhood} />) :
                                            (<InputMask type="text" name="addressNeighborhood"  onChange={myChangeHandler} placeholder="Bairro" />) }
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>UF</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                            (internInformationUpdate && internInformationUpdate.addressState) || internInformationUpdate.addressState === "" ?
                                            (<input type="text" name="addressState"  onChange={myChangeHandler} defaultValue={internInformationUpdate.addressState}/>):
                                            registeredInternInformation.addressState     !== undefined ?
                                            (<input type="text" name="addressState"  onChange={myChangeHandler} value={registeredInternInformation.addressState}/>) :
                                            (<input type="text" name="addressState"  onChange={myChangeHandler} placeholder="Estado"/>) }
                                    </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                            <p>Cidade</p>
                                        </div>
                                        <div className="intemB">
                                            {
                                            (internInformationUpdate && internInformationUpdate.addressCity) || internInformationUpdate.addressCity === "" ?
                                            (<input type="text" name="addressCity"  onChange={myChangeHandler} defaultValue={internInformationUpdate.addressCity}/>):
                                            registeredInternInformation.addressCity     !== undefined ?
                                            (<input type="text" name="addressCity"  onChange={myChangeHandler} value={registeredInternInformation.addressCity}/>) :
                                            (<input type="text" name="addressCity"  onChange={myChangeHandler} placeholder="Cidade"/>) }
                                        </div>
                                    </li>
                                    <li>
                                        <div className="intemA">
                                        </div>
                                        <div className="intemB">
                                            <form className="btn-salvar" onSubmit={handleSubmit}   >
                                                <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>{registeredInternInformation && registeredInternInformation._id !== undefined ? 'Atualizar' : 'Adicionar'}</button>
                                            </form>
                                        </div>
                                    </li>
                                </ul>
                        </div>

                    </div>
                :
                    null
                    }


        </>
    );
}
