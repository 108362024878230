import React from 'react';
import Form from './StepOne/DismissalForm';
import InforCol from './StepOne/inforCol';
import SendAccounting from './StepOne/SendAccounting';


const Dashboard = ({
    id,
    onCollaboratorInputChangeStepOneShutDown,
    collaboratorShutdownSaved,
    collaborator,
    loadTypeShutdown,
    loadEarlyWarning,
    loadPriorNoticeDate,
    loadResignationDate,
    loadDismissalExam,
    loadDismissalExaminationDate,
    focusTypeShutdown,
    focusEarlyWarning,
    focusPriorNoticeDate,
    focusResignationDate,
    focusDismissalExam,
    focusDismissalExaminationDate,
    focusRHObservations,
    loadContinue,
    savestepOneShutDownProcess,
    myChangeHandlerExcludeAdmssion,
    excludeAdmssion,
    loadDismissalForm,
    filesSavedDismissalForm,
    handleDropDismissalForm,
    deleteFileDismissalForm,
    sendToAccounting,
    myChangeHandlerSendToAccounting,
    sendemailtoaccounting,
    loadButtomSendEmailToAccounting,
    loadErrorSendEmailToAccounting,
    sendToAccountingSelect,
    emailSent
}) => {

return (
    <>
       <div className="desligamento-etapa-um">
            <h2 className="titulo">Informações para o desligamento</h2>
            <Form
            id={id}
            onCollaboratorInputChangeStepOneShutDown={onCollaboratorInputChangeStepOneShutDown}
            collaborator={collaborator}
            loadTypeShutdown={loadTypeShutdown}
            loadEarlyWarning={loadEarlyWarning}
            focusPriorNoticeDate={focusPriorNoticeDate}
            focusTypeShutdown={focusTypeShutdown}
            focusEarlyWarning={focusEarlyWarning}
            focusResignationDate={focusResignationDate}
            focusDismissalExam={focusDismissalExam}
            focusDismissalExaminationDate={focusDismissalExaminationDate}
            focusRHObservations={focusRHObservations}
            loadPriorNoticeDate={loadPriorNoticeDate}
            loadResignationDate={loadResignationDate}
            loadDismissalExam={loadDismissalExam}
            loadDismissalExaminationDate={loadDismissalExaminationDate}
            loadDismissalForm={loadDismissalForm}
            filesSavedDismissalForm={filesSavedDismissalForm}
            collaboratorShutdownSaved={collaboratorShutdownSaved}
            handleDropDismissalForm={handleDropDismissalForm}
            deleteFileDismissalForm={deleteFileDismissalForm}
            />
            <InforCol />
       </div>
       <SendAccounting
            id={id}
            collaborator={collaborator}
            sendToAccounting={sendToAccounting}
            myChangeHandlerSendToAccounting={myChangeHandlerSendToAccounting}
            sendemailtoaccounting={sendemailtoaccounting}
            loadButtomSendEmailToAccounting={loadButtomSendEmailToAccounting}
            loadErrorSendEmailToAccounting={loadErrorSendEmailToAccounting}
            sendToAccountingSelect={sendToAccountingSelect}
            emailSent={emailSent}
       />

       <div className="desligamento-buttons">
       <form onSubmit={excludeAdmssion} className="delete">
                <button type="submit" className="delete">
                    Excluir processo
                </button>
        </form>


        <div className='saved-back'>
            <form onSubmit={savestepOneShutDownProcess} >
                <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>{collaboratorShutdownSaved && collaboratorShutdownSaved._id ? 'Editar e continuar' : 'Salvar e continuar'}</button>
            </form>
        </div>

        </div>

    </>
);
}
export default Dashboard;
