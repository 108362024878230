import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import { NavLink, useHistory } from 'react-router-dom'
import apiV2 from '../../../services/apiV2';
import Header from '../../../components/Administrativo/FolhaDePagamento/header';
import PayrollList from '../../../components/Administrativo/FolhaDePagamento/PayrollList';
import IconFolder from '../../../assets/images/icon-folder.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import IconFilter from '../../../assets/images/icon-filter.svg';
import IconADD from '../../../assets/images/add-branco.svg';
import Close from '../../../assets/images/close.svg';
import { Modal } from "react-bootstrap";
import swal from 'sweetalert';
import { decodeToken } from '../../../services/auth';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

import './styles.scss';

export default function PageFolhaDePagamento (props, name)  {

    const { id } = props.match.params;
    const { path } = props.match;
    const history = useHistory();

      ////////////////////////////////////////////////////////////////////////////
     ////////////////////       PAYROLL LIST       //////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    
    const [ loadPagePayroll, setLoadPagePayroll ] = useState(false);

    const [ allPayroll, setAllPayroll] = useState([]);

    useEffect(() => {

        loadallPayroll();

    }, []);

    const anoCorrent = new Date().getFullYear();

    useEffect(()=>{
        anosCorrentes(anoCorrent);
    },[anoCorrent])

    const [ anosF, setAnosF ]           = useState([])

    function anosCorrentes(anoCorrent) {
        let anoInicial = 2000;
        const todosAnos = [];
        for(let a=anoInicial; a <= anoCorrent; a++){
             let ano = a;
             todosAnos.push(ano);
        }
        setAnosF(todosAnos)
    }
    const anosFP = anosF.sort((a, b) => b-a)

    const todosMesesAnoF = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ]

    async function loadallPayroll() {
        try {
            setLoadPagePayroll(true)
            await apiV2.get(`/payroll?select[0]=-allPayrollDocumentBase64&pageable=false`)
            .then(response => {
                setAllPayroll(response.data.items);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? error.message, { position: toast.POSITION.BOTTOM_RIGHT  });
            })
        } finally {
            setLoadPagePayroll(false)
        }
    }


    async function loadfilterperPayroll(event) {
        event.preventDefault()
        let name = event.target.value;
        if(name.length > 1) {
            console.log('texto=>', name);
            try{
                setLoadPagePayroll(true)
                await apiV2.get(`/payroll?select[0]=-allPayrollDocumentBase64&pageable=false&searchText=${name}`)
                .then(response => {
                    console.log('response.data===----->>>>>', response.data)
                    setAllPayroll(response.data.items);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? error.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            finally{
                setLoadPagePayroll(false);
            }
        }
        else {
            setLoadPagePayroll(false)
            loadallPayroll();
        }
        console.log('allPayroll===>>>', allPayroll)

    }

    async function getPayrollFiltered(year, month, type){

        let yearFilter = (year === undefined) ? '' : (year === 'all' ? '' : year);
        let monthFilter = (month === undefined) ? '' : (month === 'allMonth' ? '' : month);
        let typeFilter = (type === undefined) ? '' : (type === 'allType' ? '' : type);  

        try{
            setLoadPagePayroll(true)
            await apiV2.get(`/payroll?select[0]=-allPayrollDocumentBase64&pageable=false&year=${yearFilter}&month=${monthFilter}&type=${typeFilter}`)
            .then(response => {
                    setAllPayroll(response.data.items);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? error.message, { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        finally{
            setLoadPagePayroll(false);
        }
    }

    const [ selectedDate, setSelectedDate] = useState([]);

    const [ selectedAno, setSelectedAno] = useState([]);

    const YearPayroll = "YearPayroll"

    async function myChangeHandlerYear(event, eventb) {
        setSelectedAno({ ...selectedAno, YearPayroll: eventb });

        setSelectedDate({ ...selectedDate, year: eventb });

        getPayrollFiltered(eventb, selectedDate.month, selectedDate.type);
    }

    const [ selectedMes, setSelectedMes] = useState([]);

    async function myChangeHandlerMonth(event, eventb) {
        setSelectedMes({ ...selectedMes, PayrollMonth: eventb });

        setSelectedDate({ ...selectedDate, month: eventb });

        getPayrollFiltered(selectedDate.year, eventb, selectedDate.type);
    }

    const [ selecteType, setSelectedType] = useState([]);

    async function myChangehandlerType(event, eventb) {
        setSelectedType({ ...selecteType, "PayrollType": eventb });

        setSelectedDate({ ...selectedDate, type: eventb });

        getPayrollFiltered(selectedDate.year, selectedDate.month, eventb);
    }

    const [visibleFilterMeses, setVisibleFilterMeses] = useState(false);

    function CastrarFilterMeses(e) {
        setVisibleFilterMeses(!visibleFilterMeses);
    }

    function FiltrarAnoMesTipo(e) {
        setVisibleFilterMeses(!visibleFilterMeses);

    }

    function NaoVerFilterMeses() {
        setRegisterNewMonth([]);
        setVisibleFilterMeses(!visibleFilterMeses);
    }


      ////////////////////////////////////////////////////////////////////////////
     ////////////////////       PAYROLL STORE       /////////////////////////////
    ////////////////////////////////////////////////////////////////////////////


    ////////////  ======>  COMEÇO CADASTRAR NOVO MÊS

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCadYearPayroll, setLoadCadYearPayroll] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCadPayrollMonth, setLoadCadPayrollMonth] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCadPayrollType, setLoadCadPayrollType] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleCastrarMes, setVisibleCastrarMes] = useState(false);

    function CastrarNovoMes(e) {
        setVisibleCastrarMes(!visibleCastrarMes);
    }

    //
    function NaoVerCastrarNovoMes() {
        setRegisterNewMonth([]);
        setVisibleCastrarMes(!visibleCastrarMes);
    }

    ///////////////////////////////////////////////////////////////////////////


    ////////////  ======>  COMEÇO DE myChangeHandlerPayrollStore

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registerNewMonth, setRegisterNewMonth] = useState([]);

    async function myChangeHandlerPayrollStore(event) {
        event.preventDefault();

        let nam = event.target.name;
        let val = event.target.value;
        

        if(nam === 'year') {

            if(val === 'Selecione o ano') {

                let val = '';
                setRegisterNewMonth({ ...registerNewMonth, [nam]: val })
                setLoadCadYearPayroll(true)

            }
            else {

                setLoadCadYearPayroll(false)
                setRegisterNewMonth({ ...registerNewMonth, [nam]: val, path: path })

            }

        }
        else
        if(nam === 'month') {

            if(val === 'Selecione o mês') {

                let val = '';
                setRegisterNewMonth({ ...registerNewMonth, [nam]: val })
                setLoadCadPayrollMonth(true)

            }
            else {

                setLoadCadPayrollMonth(false)
                setRegisterNewMonth({ ...registerNewMonth, [nam]: val, path: path })

            }

        }
        else
        if(nam === 'type') {

            if(val === 'Selecione o tipo') {

                let val = '';
                setRegisterNewMonth({ ...registerNewMonth, [nam]: val })
                setLoadCadPayrollType(true)

            }
            else {

                setLoadCadPayrollType(false)
                setRegisterNewMonth({ ...registerNewMonth, [nam]: val, path: path })

            }

        }
        else {
            setRegisterNewMonth({ ...registerNewMonth, [nam]: val, path: path })
        }

    }

    async function cancelStatePayrollStore() {
        setRegisterNewMonth([]);
        setVisibleCastrarMes(false)
    }


    ////////////  ======>  COMEÇO DE savePayrollStore


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadButtonPayrollStore, setLoadButtonPayrollStore] = useState(false);




    async function savePayrollStore(event) {
        event.preventDefault();

        if(registerNewMonth && registerNewMonth.length !== 0) {

            if(registerNewMonth.year === '' || registerNewMonth.year === undefined) {
                setLoadCadYearPayroll(true)

            }
            else {
                setLoadCadYearPayroll(false)
            }

            if(registerNewMonth.month === '' || registerNewMonth.month === undefined) {
                setLoadCadPayrollMonth(true)
            }
            else {
                setLoadCadPayrollMonth(false)
            }

            if(registerNewMonth.type === '' || registerNewMonth.type === undefined) {
                setLoadCadPayrollType(true)
            }
            else {
                setLoadCadPayrollType(false)
            }

            if(
                registerNewMonth.month === ''        ||
                registerNewMonth.month === undefined ||
                registerNewMonth.type  === ''        ||
                registerNewMonth.type  === undefined ||
                registerNewMonth.type  === ''        ||
                registerNewMonth.type  === undefined
            ) {
                return
            }


            async function savepayrollstore() {

                setLoadButtonPayrollStore(true)
                await apiV2.post('/payroll/', registerNewMonth)
                .then(response => {
                    let payroll = {...registerNewMonth, _id: response.data._id}
                    setLoadButtonPayrollStore(false)
                    setRegisterNewMonth([]);
                    setVisibleCastrarMes(!visibleCastrarMes);
                    swal({ icon: "success", title: "Sucesso!", text: "Novo mês cadastrado com sucesso!" });
                    history.push({
                        pathname: `/folha-de-pagamento/ano/mes/enviar/${payroll._id}`,
                        state: {payroll}
                      }); 
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response?.data?.message ?? error.message });
                    setLoadButtonPayrollStore(false)
                });                
            }
            savepayrollstore();

        }
        else {
            setLoadCadYearPayroll(true)
            setLoadCadPayrollMonth(true)
            setLoadCadPayrollType(true)
            // swal({ icon: "error", title: "Erro!", text: 'Nenhum campo selecionado!' });

        }

    }


      ////////////////////////////////////////////////////////////////////////////
     ////////////////////       PAYROLL UPDATE       ////////////////////////////
    ////////////////////////////////////////////////////////////////////////////


    ////////////  ======>  COMEÇO EDITAR NOVO MÊS

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [registeredNewMonth, setRegisteredNewMonth] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleEditarMes, setVisibleEditarMes] = useState(false);

    function EditarNovoMes(e) {

        setRegisteredNewMonth(e)

        setVisibleEditarMes(!visibleEditarMes);
    }

    //
    function NaoVerEditarNovoMes() {
        setVisibleEditarMes(!visibleEditarMes);
    }

    /////////// loads de popup Cadastra mes do holerite //////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEditYearPayroll, setLoadEditYearPayroll] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEditPayrollMonth, setLoadEditPayrollMonth] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEditPayrollType, setLoadEditPayrollType] = useState(false);


    ////////////  ======>  COMEÇO DE myChangeHandlerUpdateNewMonth

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredUpdateNewMonth, setRegisteredUpdateNewMonth] = useState([]);

    async function myChangeHandlerUpdateNewMonth(event) {
        event.preventDefault();


        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'year') {

            if(val === 'Selecione o ano') {

                let val = '';
                setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })
                setLoadEditYearPayroll(true)

            }
            else {

                setLoadEditYearPayroll(false)
                setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })

            }

        }
        else
        if(nam === 'month') {

            if(val === 'Selecione o mês') {

                let val = '';
                setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })
                setLoadEditPayrollMonth(true)

            }
            else {

                setLoadEditPayrollMonth(false)
                setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })

            }

        }
        else
        if(nam === 'type') {

            if(val === 'Selecione o tipo') {

                let val = '';
                setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })
                setLoadEditPayrollType(true)

            }
            else {

                setLoadEditPayrollType(false)
                setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })

            }

        }
        else {
            setRegisteredUpdateNewMonth({ ...registeredUpdateNewMonth, [nam]: val, path: path })
        }


    }


    ////////////  ======>  COMEÇO DE updatePayrollStore


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadButtonPayrollUpdate, setLoadButtonPayrollUpdate] = useState(false);


    async function updatePayrollStore(event) {
        event.preventDefault();

        if(registeredUpdateNewMonth && registeredUpdateNewMonth.length !== 0) {


            if(registerNewMonth && registerNewMonth.year && registerNewMonth.year === '' ) {
                setLoadCadYearPayroll(true)
                return
            }
            else {
                setLoadCadYearPayroll(false)
            }


            if(registerNewMonth && registerNewMonth.month && registerNewMonth.month === '' ) {
                setLoadCadPayrollMonth(true)
                return
            }
            else {
                setLoadCadPayrollMonth(false)
            }

            if(registerNewMonth && registerNewMonth.type && registerNewMonth.type === '') {
                setLoadCadPayrollType(true)
                return
            }
            else {
                setLoadCadPayrollType(false)
            }


            async function updatepayrollstore() {
                setLoadButtonPayrollUpdate(true)
                await apiV2.patch(`/payroll/${registeredNewMonth._id}`, registeredUpdateNewMonth)
                .then(response => {

                    async function loadallPayroll() {
                        try {
                            setLoadPagePayroll(true)
                            await apiV2.get(`/payroll?select[0]=-allPayrollDocumentBase64&pageable=false`)
                            .then(response => {
                                setAllPayroll(response.data.items);
                            }).catch(error => {
                                toast.error(error?.response?.data?.message ?? error.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                            })
                        } finally {
                            setLoadPagePayroll(false)
                        }
                    }

                    loadallPayroll();

                    setLoadButtonPayrollUpdate(false)
                    setRegisteredUpdateNewMonth([]);
                    setVisibleEditarMes(!visibleEditarMes);
                    swal({ icon: "success", title: "Sucesso!", text: "Mês editado com sucesso!" });

                }).catch(error => {
                    setLoadButtonPayrollUpdate(false)
                    swal({ icon: "error", title: "Erro!", text: error.response?.data?.message ?? error.message });
                });
            }
            updatepayrollstore();

        }
        else {

            swal({ icon: "error", title: "Erro!", text: 'Necessário atualizar pelo menos um campo!' });

        }

    }

    async function deletePayroll(payrollId) {
        swal({
            title: "Atenção",
            text: "Excluir esse mês irá apagar todos os holerites cadastrados nele, deseja prosseguir com a operação?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoadPagePayroll(true)
                await apiV2.delete(`/payroll/${payrollId}`)
                .then(() => {

                    setLoadPagePayroll(false)
                    loadallPayroll();

                    swal({ icon: "success", title: "Sucesso!", text: "Mês removido com sucesso da folha de pagamento." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response?.data?.message ?? error.message });
                });
            }
        });
    }

    const { user } = decodeToken();
    
    return (
        <>
        <Modal show={visibleFilterMeses} className="popup-geral popup-payroll-filter" onHide={visibleFilterMeses}>

            <div>
                <div className="titulo">
                    <h4>Filtrar holerites  <p className="btn-fechar" onClick={NaoVerFilterMeses}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <ul className="form">
                    <li>

                        <div className="intemA">
                            <p>Ano</p>
                        </div>
                        <div className='intemB'>

                            <div className='cont-select year' >

                                <button className='select-' >
                                    <p>
                                        {
                                            selectedAno && selectedAno.YearPayroll ?
                                            selectedAno.YearPayroll :
                                            'Todos'
                                        }
                                    </p>
                                </button>


                                <div className="dropDown">
                                        <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                                <button className='item' name="YearPayroll"
                                                onClick={() =>  myChangeHandlerYear( YearPayroll, "all")}
                                                >
                                                    <p >all</p>
                                                </button>
                                            {anosFP.map((ano, index) => (
                                                selectedAno && selectedAno.YearPayroll !==  anosFP ?
                                                    <button key={index} className='item' name="YearPayroll"
                                                    onClick={() =>  myChangeHandlerYear( YearPayroll, ano)}
                                                    >
                                                        <p >{ano}</p>
                                                    </button>
                                                :
                                                ''
                                            ))}
                                        </Scrollbars>
                                    </div>
                            </div>

                        </div>

                    </li>
                    <li>

                        <div className="intemA">
                            <p>Mês</p>
                        </div>
                        <div className='intemB'>
                            <div className='cont-select month' >

                                <button className='select-' >
                                    <p>
                                        {
                                            selectedMes && selectedMes.PayrollMonth ?
                                                selectedMes.PayrollMonth  === "allMonth" ?
                                                    "Todos os meses" : selectedMes.PayrollMonth
                                            :
                                            'Todos os meses'
                                        }
                                    </p>
                                </button>


                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                            <button className='item' name="PayrollMonth"
                                            onClick={() =>  myChangeHandlerMonth( "PayrollMonth", "allMonth")}
                                            >
                                                <p >Todos os meses</p>
                                            </button>
                                        {todosMesesAnoF.map((mes, index) => (
                                            selectedMes && selectedMes.PayrollMonth !==  anosFP ?
                                                <button key={index} className='item' name="PayrollMonth"
                                                onClick={() =>  myChangeHandlerMonth( "PayrollMonth", mes)}
                                                >
                                                    <p >{mes}</p>
                                                </button>
                                            :
                                            ''
                                        ))}
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>

                    </li>
                    <li>
                        <div className="intemA">
                            <p>Tipo</p>
                        </div>
                        <div className='intemB'>
                            <div className='cont-select type' >

                                <button className='select-' >
                                    <p>
                                        {
                                            selecteType && selecteType.PayrollType ?
                                                selecteType.PayrollType  === "allType" ?
                                                    "Todos os tipos" : selecteType.PayrollType
                                            :
                                            'Todos os tipos'
                                        }
                                    </p>
                                </button>


                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                            <button className='item' name="PayrollType"
                                            onClick={() =>  myChangehandlerType( "PayrollType", "allType")}
                                            >
                                                <p >Todos os tipos</p>
                                            </button>
                                        {[
                                            'Holerites',
                                            'Bônus',
                                            'Comissão',
                                            'Informe de Rendimentos',
                                            '13º Salário',
                                            'Outros',
                                            'Recibo de Férias',
                                            '13º Salário - Primeira parcela',
                                            '13º Salário - Segunda parcela',
                                            'Adiantamento',
                                            'PLR',
                                            'Folha Complementar',
                                            'Recibos',
                                            'Pró-labore',
                                            'Espelho de Ponto',
                                        ].map((type, index) => (
                                            <button key={index} className='item' name="PayrollType"
                                            onClick={() =>  myChangehandlerType( "PayrollType", type)}
                                            >
                                                <p >{type}</p>
                                            </button>
                                        ))}
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <button className='btnGreen filtrar-btn' onClick={FiltrarAnoMesTipo}>Filtrar holerites</button>
            </div>
        </Modal>
            <Layout
            path={props.match.path}>
                <Header
                    visibleCastrarMes={visibleCastrarMes}
                    CastrarNovoMes={CastrarNovoMes}
                    NaoVerCastrarNovoMes={NaoVerCastrarNovoMes}
                    loadCadYearPayroll={loadCadYearPayroll}
                    loadCadPayrollMonth={loadCadPayrollMonth}
                    loadCadPayrollType={loadCadPayrollType}
                    registerNewMonth={registerNewMonth}
                    setRegisterNewMonth={setRegisterNewMonth}
                    myChangeHandlerPayrollStore={myChangeHandlerPayrollStore}
                    cancelStatePayrollStore={cancelStatePayrollStore}
                    savePayrollStore={savePayrollStore}
                    loadButtonPayrollStore={loadButtonPayrollStore}
                    id={id}
                    user={user}
                    path={props.match.path}
                    setLoadCadYearPayroll={setLoadCadYearPayroll}
                />
                <div className="page-folha-pagamento">
                    <ul className="sub-menu">
                        <li>
                            <NavLink to="/folha-de-pagamento" exact className={
                            props.match.path === '/folha-de-pagamento' ?
                            "active" :
                            'not-active'
                            }><span>Holerites</span></NavLink>
                        </li>

                        <button className="cadastrar-mes-mb" onClick={() => CastrarNovoMes()}><img src={IconADD} alt="" /></button>


                        <button className="btnGreen more cadastrar-mes" onClick={() => CastrarNovoMes()}>Cadastrar novo mês</button>
                    </ul>
                    <div className='busca-mobile'>
                        <input name="name" value={name} onChange={loadfilterperPayroll}  autoComplete="off" placeholder="Filtre por mês, ano ou tipo" className="campo-busca " />
                    </div>
                   <div className="filtros">
                        <p className='titulo'>
                            Mostrando <span className=''>
                                    {
                                         selectedAno.length === undefined &&
                                         selecteType.length === undefined &&
                                         selectedMes.length === undefined ?
                                         'todos'
                                         :
                                            (selectedAno.YearPayroll === 'all' ||
                                            selectedAno.YearPayroll === undefined)
                                            &&
                                            (selecteType.PayrollType === 'allType'||
                                            selecteType.PayrollType === undefined )
                                            &&
                                            (selectedMes.PayrollMonth === undefined ||
                                            selectedMes.PayrollMonth === 'allMonth') ?
                                                "todos"
                                            :
                                                "personalizadoa"
                                    }
                                </span>
                        </p>


                        <div className='form-mobile'>
                            <button onClick={() => CastrarFilterMeses()}>Filtrar <img src={IconFilter} alt="Icone do Filter" /></button>
                        </div>
                        <div className="form">

                            <div className='cont-select year' >

                                <button className='select-' >
                                    <p>
                                        {
                                            selectedAno && selectedAno.YearPayroll ?
                                                selectedAno.YearPayroll === "all" ? 'Todos' : 
                                                selectedAno.YearPayroll
                                            :
                                            'Todos'
                                        }
                                    </p>
                                </button>


                                <div className="dropDown">
                                        <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                                <button className='item' name="YearPayroll"
                                                onClick={() =>  myChangeHandlerYear( YearPayroll, "all")}
                                                >
                                                    <p >Todos</p>
                                                </button>
                                            {anosFP.map((ano, index) => (
                                                selectedAno && selectedAno.YearPayroll !==  anosFP ?
                                                    <button key={index} className='item' name="YearPayroll"
                                                    onClick={() =>  myChangeHandlerYear( YearPayroll, ano)}
                                                    >
                                                        <p >{ano}</p>
                                                    </button>
                                                :
                                                ''
                                            ))}
                                        </Scrollbars>
                                    </div>
                            </div>

                            {/* <select onChange={myChangeHandlerYear} name="YearPayroll" className="year">
                                <option value="all">Todos</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2020">2019</option>
                            </select> */}

                            <div className='cont-select month' >

                                <button className='select-' >
                                    <p>
                                        {
                                            selectedMes && selectedMes.PayrollMonth ?
                                                selectedMes.PayrollMonth  === "allMonth" ?
                                                    "Todos os meses" : selectedMes.PayrollMonth
                                            :
                                            'Todos os meses'
                                        }
                                    </p>
                                </button>


                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                            <button className='item' name="PayrollMonth"
                                            onClick={() =>  myChangeHandlerMonth( "PayrollMonth", "allMonth")}
                                            >
                                                <p >Todos os meses</p>
                                            </button>
                                        {todosMesesAnoF.map((mes, index) => (
                                            selectedMes && selectedMes.PayrollMonth !==  anosFP ?
                                                <button key={index} className='item' name="PayrollMonth"
                                                onClick={() =>  myChangeHandlerMonth( "PayrollMonth", mes)}
                                                >
                                                    <p >{mes}</p>
                                                </button>
                                            :
                                            ''
                                        ))}
                                    </Scrollbars>
                                </div>
                            </div>


                            {/* <select onChange={myChangeHandlerMonth} name="PayrollMonth" className="month">
                                <option value="allMonth">Todos os meses</option>
                                <option value="Janeiro">Janeiro</option>
                                <option value="Fevereiro">Fevereiro</option>
                                <option value="Março">Março</option>
                                <option value="Abril">Abril</option>
                                <option value="Maio">Maio</option>
                                <option value="Junho">Junho</option>
                                <option value="Julho">Julho</option>
                                <option value="Agosto">Agosto</option>
                                <option value="Setembro">Setembro</option>
                                <option value="Outubro">Outubro</option>
                                <option value="Novembro">Novembro</option>
                                <option value="Dezembro">Dezembro</option>
                            </select> */}


                            <div className='cont-select type' >

                                <button className='select-' >
                                    <p>
                                        {
                                            selecteType && selecteType.PayrollType ?
                                                selecteType.PayrollType  === "allType" ?
                                                    "Todos os tipos" : selecteType.PayrollType
                                            :
                                            'Todos os tipos'
                                        }
                                    </p>
                                </button>


                                <div className="dropDown">
                                    <Scrollbars className='listDropDown'  style={{  height: 120 }}>
                                            <button className='item' name="PayrollType"
                                            onClick={() =>  myChangehandlerType( "PayrollType", "allType")}
                                            >
                                                <p >Todos os tipos</p>
                                            </button>
                                        {[
                                            'Holerites',
                                            'Bônus',
                                            'Comissão',
                                            'Informe de Rendimentos',
                                            '13º Salário',
                                            'Outros',
                                            'Recibo de Férias',
                                            '13º Salário - Primeira parcela',
                                            '13º Salário - Segunda parcela',
                                            'Adiantamento',
                                            'PLR',
                                            'Folha Complementar',
                                            'Recibos',
                                            'Pró-labore',
                                            'Espelho de Ponto',
                                        ].map((type, index) => (
                                            <button key={index} className='item' name="PayrollType"
                                            onClick={() =>  myChangehandlerType( "PayrollType", type)}
                                            >
                                                <p >{type}</p>
                                            </button>
                                        ))}
                                    </Scrollbars>
                                </div>
                            </div>


{/*


                            <select onChange={myChangehandlerType} name="PayrollType" className="type">
                                <option value="allType">Todos os tipos</option>
                                <option value="Holerites">Holerites</option>
                                <option value="Bônus">Bônus</option>
                                <option value="Comissão">Comissão</option>
                                <option value="Informe de Rendimentos">Informe de Rendimentos</option>
                                <option value="13º Salário">13º Salário</option>
                                <option value="Outros">Outros</option>
                                <option value="Recibo de Férias">Recibo de Férias</option>
                                <option value="13º Salário - Primeira parcela">13º Salário - Primeira parcela</option>
                                <option value="13º Salário - Segunda parcela">13º Salário - Segunda parcela</option>
                                <option value="Adiantamento">Adiantamento</option>
                                <option value="PLR">PLR</option>
                                <option value="Folha Complementar">Folha Complementar</option>
                                <option value="Recibos">Recibos</option>
                                <option value="Pró-labore">Pró-labore</option>
                                <option value="Espelho de Ponto">Espelho de Ponto</option>
                            </select> */}
                            <input name="name" onChange={loadfilterperPayroll} placeholder="Filtre por mês, ano ou tipo"  autocomplete="off" className="busca" />
                        </div>
                    </div>
                {
                    loadPagePayroll ?
                    <>
                    <div className="carregamento carregamento-F-P">
                        <img alt="" src={Carregamento} />
                    </div>
                    </>
                    :
                    allPayroll && allPayroll.length !== 0 && allPayroll !== undefined ?(
                    <>
                    <PayrollList
                    allPayroll={allPayroll}
                    loadPagePayroll={loadPagePayroll}
                    visibleEditarMes={visibleEditarMes}
                    EditarNovoMes={EditarNovoMes}
                    NaoVerEditarNovoMes={NaoVerEditarNovoMes}
                    loadEditYearPayroll={loadEditYearPayroll}
                    loadEditPayrollMonth={loadEditPayrollMonth}
                    loadEditPayrollType={loadEditPayrollType}
                    registeredNewMonth={registeredNewMonth}
                    myChangeHandlerUpdateNewMonth={myChangeHandlerUpdateNewMonth}
                    registeredUpdateNewMonth={registeredUpdateNewMonth}
                    loadButtonPayrollUpdate={loadButtonPayrollUpdate}
                    updatePayrollStore={updatePayrollStore}
                    deletePayroll={deletePayroll}
                    />

                    </>
                ):(
                    <>

                    <div className="payroll-nenhum" >
                        <div className="box">
                            <p>Nenhum mês <span>{selecteType.PayrollType !== undefined ? selecteType.PayrollType  === "allType" ? "de holerites " : "de " + selecteType.PayrollType : "" }</span> cadastrado.</p>
                            <button className="btnGreen more" onClick={() => CastrarNovoMes()}>Cadastrar novo mês</button>
                            <img src={IconFolder} alt="Icone Folder" className="icone"/>
                        </div>
                    </div>

                    </>
                ) }
                </div>
            </Layout>
        </>
    );
}
