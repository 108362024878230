import React from 'react';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';
import Carregamento from '../../../assets/images/carregando.gif';


export default function GridCargo({
    loadPageOccupationGrid,
    occupation,
    EditarCargo,
    deleteOccupation,
 }) {

    return (
        <>
        <div className="cargo-page">

            <div className="grid-cargo-page">

            {
                loadPageOccupationGrid ?
                (
                    <>

                    <div className="carregamento">
                        <img alt="" src={Carregamento} />
                    </div>

                    <div className="carregamento">
                        <img alt="" src={Carregamento} />
                    </div>

                    <div className="carregamento">
                        <img alt="" src={Carregamento} />
                    </div>

                    <div className="carregamento4">
                        <img alt="" src={Carregamento} />
                    </div>
                    </>
                ):
                occupation[0] !== undefined && occupation.length !== 0  ? occupation
                .map((occupationList, index) => (
                <>

                <div className='card-cargo'>
                        {/* setVisibleEdiCargo */}
                        <Dropdown  className="dropdown-icon">
                            <Dropdown.Toggle >
                            <img alt="" src={iconVerMais}  />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <button type="button" name="editar" onClick={() => EditarCargo(occupationList)}>
                                    Editar
                                </button>
                                <button onClick={() => deleteOccupation(occupationList)} type="button" name="excluir" className="red">
                                    Excluir
                                </button>
                            </Dropdown.Menu>
                        </Dropdown>
                <div className="cargo"  id="pai"onClick={() => EditarCargo(occupationList)}>
                    <h3 className="nome">
                        <span>{occupationList.nameOccupatiomn}</span>
                    </h3>
                    <p className="faixa-salarial">Faixa salarial</p>
                    <div className="valor-quantidade">
                        <div className='valor'>
                            De: R$ {occupationList.minimumSalary ? occupationList.minimumSalary : '0,00'} <br/>
                            Até: R$ {occupationList.maximumSalary ? occupationList.maximumSalary : '0,00'}
                        </div>
                        <span className="quantidade">{occupationList.numberOfCollaboratorsOccupation} com este cargo</span>
                    </div>
                </div>

            </div>


                </>
        )) : (
            <>
            </>
        )}


            </div>

            </div>


        </>
    );
}

