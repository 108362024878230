import React, { useState, useEffect } from 'react';
import Docs from '../../../../../assets/images/docs.svg';
import api from '../../../../../services/api';
import {removerSpecials} from '../../../../../services/utils';
import SelectPersonalizado from '../../../../../components/Reused/selectPersonalizado';
import { toast } from 'react-toastify';

const formBasicInfor = ({
    id,
    onCollaboratorInputChange,
    ufReservationCard,
    collaboratorUpdate,
    ufDistrito,
    ufCity,
    loadcpf,
    focuscpf,
    loadrg,
    loadrgExpeditionDate,
    loadrgOrgan,
    loadufOrgan,
    focusrg,
    focusrgExpeditionDate,
    focusrgOrgan,
    focusufOrgan,
    loadcnh               ,
    loadcnhCategory       ,
    loadcnhExpeditionDate ,
    loadufCNH             ,
    focuscnh              ,
    focuscnhCategory      ,
    focuscnhExpeditionDate,
    focusufCNH,
    loadwalletNumber ,
    loadserialNumber ,
    loadissueDate    ,
    loadwalletUF     ,
    loadpisNumber    ,
    focuswalletNumber,
    focusserialNumber,
    focusissueDate   ,
    focuswalletUF    ,
    focuspisNumber,
    loadvoterRegistration ,
    loadelectoralZone     ,
    loadpollingStation    ,
    focusvoterRegistration,
    focuselectoralZone    ,
    focuspollingStation,
    loadreservationCardNumber         ,
    loadreservationCardSeries         ,
    loadreservationCardCategory       ,
    loadreservationCardExpeditionDate ,
    loadufReservationCard             ,
    loadcityReservationCard           ,
    focusreservationCardNumber        ,
    focusreservationCardSeries        ,
    focusreservationCardCategory      ,
    focusreservationCardExpeditionDate,
    focusufReservationCard            ,
    focuscityReservationCard,
    setCollaboratorUpdate,
    setLoadufOrgan,
    setLoadufReservationCard,
    setLoadcityReservationCard
    }) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEstados, setUfEstados ] = useState([]);
    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhido, setUEscolhido ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCidade ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ citys, setCitys ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (id && id) {
            loadcollaborator(id);
        }
        loadufestados();

        if(ufDistrito !== undefined) {
            loaduf(ufDistrito);
        }

        if(ufCity !== undefined) {

            if(ufCity === '350000000') {

                let cidadeGravada = {
                    id: "350000000",
                    nome: "São Paulo"
                }
                setCidade(cidadeGravada);

            }
            else {

                loadcity(ufCity);

            }

        }

        if(ufReservationCard !== undefined) {

            if(ufReservationCard === '35') {

                loadcities(ufReservationCard);

            }
            else {

                loadcities_(ufReservationCard);

            }

        }


    }, [id, ufDistrito, ufCity, ufReservationCard]);

    async function loadcollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadufestados() {

        await api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
        .then(response => {
            setUfEstados(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loaduf(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
        .then(response => {
            setUEscolhido(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadcity(ufCity) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
        .then(response => {
            setCidade(response.data[0]);
        }).catch(error => {});

    }

    async function loadcities(ufReservationCard) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufReservationCard}/distritos?orderBy=nome`)
        .then(response => {

            let itensCopyCitys = Array.from(response.data);

            itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });

            itensCopyCitys.sort(function (a, b) {
                if (a.nome > b.nome) {
                    return 1;
                }
                if (a.nome < b.nome) {
                    return -1;
                }
                // a must be equal to b
                return 0;
                });

            setCitys(itensCopyCitys);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadcities_(ufReservationCard) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufReservationCard}/distritos?orderBy=nome`)
        .then(response => {
            setCitys(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    const estadosOrgam = [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins"
    ]
    const categoriaCnh = [
        "ACC","A","B","C","D","E"
    ]

    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhidoUpdateC, setUUfEscolhidoUpdateC ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBusca , setNationalityBusca] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [nationalityBuscaString , setNationalityBuscaString] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === "ufReservationCardBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
    
        }
        else
        if(nam === "ufReservationCard") {
            let ufReservationCard = val;
            setLoadufReservationCard(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, ufReservationCard })
            
            api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${val}`)
            .then(response => {
                setUUfEscolhidoUpdateC(response.data);
                setNationalityBuscaString({ ...nationalityBuscaString,  "ufReservationCardBusca": response.data.nome  });

                if(response.data.nome === "São Paulo") {
    
                    async function loadufestados() {
                        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${response.data.id}/distritos?orderBy=nome`)
                        .then(response => {
    
                            let itensCopyCitys = Array.from(response.data);
    
                            itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });
    
                            itensCopyCitys.sort(function (a, b) {
                                if (a.nome > b.nome) {
                                return 1;
                                }
                                if (a.nome < b.nome) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });
    
                            setCitys(itensCopyCitys);
    
                        }).catch(error => {});
                    }
                    loadufestados()
    
    
                }
    
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        else
        if(nam === "cityReservationCardBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        } 
        else
        if(nam === "cityReservationCard") {
            setLoadcityReservationCard(false)
            setNationalityBuscaString({ ...nationalityBuscaString, "cityReservationCardBusca": val });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val });
        }
        else
        if(nam === "ufOrganBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        }
        else
        if(nam === "ufOrgan") {
            setNationalityBuscaString({ ...nationalityBuscaString, "ufOrganBusca": val });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val });
            setLoadufOrgan(false)
        }
        else
        if(nam === "ufCNHBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        }
        else
        if(nam === "ufCNH") {
            setNationalityBuscaString({ ...nationalityBuscaString, "ufCNHBusca": val });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val });
            setLoadufOrgan(false)
        }
        else
        if(nam === "walletUFBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setNationalityBusca({ ...nationalityBusca, [nam]: valOption });
            setNationalityBuscaString({ ...nationalityBuscaString,  [nam]: val  });
        }
        else
        if(nam === "walletUF") {
            setNationalityBuscaString({ ...nationalityBuscaString, "walletUFBusca": val });
            setCollaboratorUpdate({ ...collaboratorUpdate,  [nam]: val });
            setLoadufOrgan(false)
        }
    }

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const optionFiltreUfEstados = ufEstados.filter((valOption) => {
        return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.ufReservationCardBusca !== undefined ? nationalityBusca.ufReservationCardBusca : "" )  ;
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUfCidade = citys.filter((valOption) => {
         return removerSpecials(valOption.nome).toLowerCase().includes(nationalityBusca?.cityReservationCardBusca !== undefined ? nationalityBusca.cityReservationCardBusca : "" )  ;
     });

     // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUfCidadeRg = estadosOrgam.filter((valOption) => {
        return removerSpecials(valOption).toLowerCase().includes(nationalityBusca?.ufOrganBusca !== undefined ? nationalityBusca.ufOrganBusca : "" )  ;
    });

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const optionFiltreUfCidadeCNH = estadosOrgam.filter((valOption) => {
        return removerSpecials(valOption).toLowerCase().includes(nationalityBusca?.ufCNHBusca !== undefined ? nationalityBusca.ufCNHBusca : "" )  ;
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUfCidadeWalletUF = estadosOrgam.filter((valOption) => {
       return removerSpecials(valOption).toLowerCase().includes(nationalityBusca?.walletUFBusca !== undefined ? nationalityBusca.walletUFBusca : "" )  ;
   });


    return (
        <>
        <div className="box documents">
            <h3>Documentos</h3>
            <img src={Docs} alt="Documentos" className="imgDocuments"/>

            <div className="box-grid">
                <div className='linha um'>
                    <div className='box'>
                        <p className="textCampo">CPF</p>
                        <div>

                        {
                            collaboratorUpdate && collaboratorUpdate.cpf !== undefined ?
                            <input ref={focuscpf} type="text" name="cpf" autocomplete="autocompleteoff" value={collaboratorUpdate.cpf ? collaboratorUpdate.cpf : ''} placeholder="000.000.000-00" onChange={onCollaboratorInputChange} className="inputUm" /> :
                            collaborator && collaborator.cpf !== undefined ?
                            <input ref={focuscpf} type="text" name="cpf" autocomplete="autocompleteoff" value={collaborator.cpf ? collaborator.cpf : ''} onChange={onCollaboratorInputChange} className="inputUm"/> :
                            <input ref={focuscpf} type="text" name="cpf" autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="000.000.000-00" className="inputUm"/>

                        }
                        {
                        loadcpf ?
                        <p className="textError inputUm">É necessário preencher o CPF</p>
                        :
                        ('')
                        }
                        </div>
                    </div>
                </div>

                <div className='linha um'>
                    <div className='box'>
                        <p className="textCampo">É estrangeiro?</p>
                        <div>

                        <div className="chekbox">
                            {collaborator.foreign === undefined ?
                            (

                                <>
                                <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" defaultChecked={false}/>
                                <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" defaultChecked={true}/>
                                <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                <label htmlFor="foreignNao" className="foreignTexto">Não</label>
                                </>
                            ) :
                            (
                                ''
                            )}

                            {collaborator.foreign === "nao" ?
                            (
                                <>
                                <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" defaultChecked={true}/>
                                <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                <label htmlFor="foreignNao" className="foreignTexto">Não</label>
                                </>
                            ) :
                            (
                                ''
                            )}

                            {collaborator.foreign === "sim" ?
                            (
                                <>
                                <input type="radio" value="nao" onChange={onCollaboratorInputChange} name="foreign" id="foreignNao" defaultChecked={false}/>
                                <label htmlFor="foreignNao" className="chackboxLabel"></label>
                                <label htmlFor="foreignNao" className="foreignTexto">Não</label>
                                </>
                            ) :
                            (
                                ''
                            )}

                            {collaborator.foreign === "sim" ?
                            (
                                <>
                                <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" defaultChecked={true}/>
                                <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                </>
                            ) :
                            ('')
                            }

                            {collaborator.foreign === "nao" ?
                            (
                                <>
                                <input type="radio" value="sim" onChange={onCollaboratorInputChange} name="foreign" id="foreignSim" defaultChecked={false}/>
                                <label htmlFor="foreignSim" className="chackboxLabel"></label>
                                <label htmlFor="foreignSim" className="foreignTexto">Sim</label>
                                </>
                            ) :
                            ('')
                            }
                            </div>

                        </div>
                    </div>
                </div>


                <div className='linha'>
                    <div className='box'>
                        <h5>RG</h5>
                        <div></div>
                    </div>
                    <div className='box'></div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Número do RG</p>
                        <div>



                            {
                                        (collaboratorUpdate && collaboratorUpdate.rg !== undefined) || collaboratorUpdate.rg === '' ?
                                <input ref={focusrg} type="text" name="rg" autocomplete="autocompleteoff" maxLength="12" value={(collaboratorUpdate.rg !== undefined) ? collaboratorUpdate.rg : ''} onChange={onCollaboratorInputChange} placeholder="Número do RG" className={loadrg ? 'inputErro inputUm' : 'inputUm'}/> :
                                collaborator && collaborator.rg ?
                                <input ref={focusrg} type="text" name="rg" autocomplete="autocompleteoff" maxLength="12" value={(collaborator.rg !== undefined)  ? collaborator.rg : ''} onChange={onCollaboratorInputChange} placeholder="Número do RG" className={loadrg ? 'inputErro inputUm' : 'inputUm'}/> :
                                <input ref={focusrg} type="text" name="rg" autocomplete="autocompleteoff" maxLength="12" value={(collaboratorUpdate.rg !== undefined)  ? collaborator.rg : ''} onChange={onCollaboratorInputChange} placeholder="Número do RG" className={loadrg ? 'inputErro inputUm' : 'inputUm'}/>
                            }
                            {
                            loadrg ?
                            <p className="textError inputUm">O campo é obrigatório.</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">Data de expedição</p>
                        <div>

                            {
                                (collaboratorUpdate && collaboratorUpdate.rgExpeditionDate !== undefined) || collaboratorUpdate.rgExpeditionDate === '' ?
                                <input ref={focusrgExpeditionDate} type="text" name="rgExpeditionDate" autocomplete="autocompleteoff" value={collaboratorUpdate.rgExpeditionDate ? collaboratorUpdate.rgExpeditionDate : ''} onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" className={loadrgExpeditionDate ? 'inputErro inputDois' : 'inputDois'}/> :
                                collaborator && collaborator.rgExpeditionDate ?
                                <input ref={focusrgExpeditionDate} type="text" name="rgExpeditionDate" autocomplete="autocompleteoff" value={collaborator.rgExpeditionDate ? collaborator.rgExpeditionDate : ''} onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA" className={loadrgExpeditionDate ? 'inputErro inputDois' : 'inputDois'}/> :
                                <input ref={focusrgExpeditionDate} type="text" name="rgExpeditionDate" autocomplete="autocompleteoff" value={collaborator.rgExpeditionDate ? collaborator.rgExpeditionDate : ''} onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className={loadrgExpeditionDate ? 'inputErro inputDois' : 'inputDois'}/>
                            }
                            {
                            loadrgExpeditionDate ?
                            <p className="textError inputDois">O campo é obrigatório.</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                </div>


                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Órgão emissor</p>
                        <div>
                            {
                                (collaboratorUpdate && collaboratorUpdate.rgOrgan !== undefined) || collaboratorUpdate.rgOrgan === '' ?
                                    // collaboratorUpdate && collaboratorUpdate.rgOrgan ?
                                    <input ref={focusrgOrgan} type="text" name="rgOrgan" autocomplete="autocompleteoff" value={collaboratorUpdate.rgOrgan ? collaboratorUpdate.rgOrgan : ''} placeholder="Órgão emissor"  onChange={onCollaboratorInputChange} className={loadrgOrgan ? 'inputErro inputTreis' : 'inputTreis'}/> :
                                    collaborator && collaborator.rgOrgan ?
                                    <input ref={focusrgOrgan} type="text" name="rgOrgan" autocomplete="autocompleteoff" value={collaborator.rgOrgan ? collaborator.rgOrgan : ''} placeholder="Órgão emissor"  onChange={onCollaboratorInputChange} className={loadrgOrgan ? 'inputErro inputTreis' : 'inputTreis'}/> :
                                    <input ref={focusrgOrgan} type="text" name="rgOrgan" autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="Órgão emissor"  className={loadrgOrgan ? 'inputErro inputTreis' : 'inputTreis'}/>
                                }


                            {
                            loadrgOrgan ?
                            <p className="textError inputTreis">O campo é obrigatório.</p>
                            :
                            ('')
                            }
                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">UF emissor</p>
                        <div>
                        <SelectPersonalizado
                            valorCampo={

                                nationalityBuscaString && nationalityBuscaString.ufOrganBusca ?
                                nationalityBuscaString.ufOrganBusca :
                                nationalityBuscaString && nationalityBuscaString.ufOrganBusca === '' ?
                                    '' :

                                    collaboratorUpdate && collaboratorUpdate.ufOrgan ?
                                    collaboratorUpdate.ufOrgan
                                    :
                                    collaborator && collaborator.ufOrgan ?
                                    collaborator.ufOrgan : ''
                            }
                            nameCampoBusca={"ufOrganBusca"}
                            nameButton={"ufOrgan"}
                            loadError={loadufOrgan}
                            myChangeHandler={myChangeHandlerSlects}
                            option={optionFiltreUfCidadeRg}
                            typeSelect={"busca"}
                            focusRef={focusufOrgan}
                        />

                            

                            {
                            loadufOrgan ?
                            <p className="textError inputQuatro">O campo é obrigatório.</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                </div>


                <div className='linha'>
                    <div className='box'>
                        <h5>CNH</h5>
                        <div></div>
                    </div>
                    <div className='box'></div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Número CNH</p>
                        <div>
                            {
                                collaboratorUpdate && collaboratorUpdate.cnh ?
                                <input ref={focuscnh} type="text" name="cnh" autocomplete="autocompleteoff" value={collaboratorUpdate.cnh ? collaboratorUpdate.cnh : ''} placeholder="Número CNH" onChange={onCollaboratorInputChange} className="inputUm"/> :
                                collaborator && collaborator.cnh ?
                                <input ref={focuscnh} type="text" name="cnh" autocomplete="autocompleteoff" defaultValue={collaborator.cnh ? collaborator.cnh : ''} placeholder="Número CNH" onChange={onCollaboratorInputChange} className="inputUm"/> :
                                <input ref={focuscnh} type="text" name="cnh" autocomplete="autocompleteoff" value={collaboratorUpdate.cnh ? collaboratorUpdate.cnh : ''} onChange={onCollaboratorInputChange} placeholder="Número CNH"  className="inputUm"/>
                            }
                            {
                            loadcnh ?
                            <p className="textError inputUm">É necessário preencher o Número da CNH</p>
                            :
                            ('')
                            }
                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">Categoria</p>
                        <div>



                  
                        <SelectPersonalizado
                            valorCampo={
                                    collaboratorUpdate && collaboratorUpdate.cnhCategory ?
                                    collaboratorUpdate.cnhCategory
                                    :
                                    collaborator && collaborator.cnhCategory ?
                                    collaborator.cnhCategory : ''
                            }
                            nameButton={"cnhCategory"}
                            myChangeHandler={onCollaboratorInputChange}
                            option={categoriaCnh}
                        />

                            {/* <select ref={focuscnhCategory} name="cnhCategory" onChange={onCollaboratorInputChange} className="inputDois">
                            {
                            collaborator.cnhCategory !== undefined ?
                            (
                                <>
                                <option value={collaborator.cnhCategory}>{collaborator.cnhCategory}</option>
                                <option value="ACC">ACC</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                </>
                            )
                            :
                            (
                                <>
                                <option value="Selecione">Selecione</option>
                                <option value="ACC">ACC</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                </>
                            )
                            }
                            </select> */}

                        </div>
                    </div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Data de expedição</p>
                        <div>
                            {
                                (collaboratorUpdate && collaboratorUpdate.cnhExpeditionDate !== undefined) || collaboratorUpdate.cnhExpeditionDate === '' ?
                                <input ref={focuscnhExpeditionDate} type="text" name="cnhExpeditionDate" autocomplete="autocompleteoff" value={collaboratorUpdate.cnhExpeditionDate ? collaboratorUpdate.cnhExpeditionDate : ''} onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className="inputTreis"/> :
                                collaborator && collaborator.cnhExpeditionDate ?
                                <input ref={focuscnhExpeditionDate} type="text" name="cnhExpeditionDate" autocomplete="autocompleteoff" value={collaborator.cnhExpeditionDate ? collaborator.cnhExpeditionDate : ''} onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className="inputTreis"/> :
                                <input ref={focuscnhExpeditionDate} type="text" name="cnhExpeditionDate" autocomplete="autocompleteoff" value={collaboratorUpdate.cnhExpeditionDate ? collaboratorUpdate.cnhExpeditionDate : ''} onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  className="inputTreis"/>
                            }
                            {
                            loadcnhExpeditionDate ?
                            <p className="textError inputTreis">É necessário preencher a Data de expedição</p>
                            :
                            ('')
                            }
                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">UF da carteira</p>
                        <div>
                            <SelectPersonalizado
                                valorCampo={

                                    nationalityBuscaString && nationalityBuscaString.ufCNHBusca ?
                                    nationalityBuscaString.ufCNHBusca :
                                    nationalityBuscaString && nationalityBuscaString.ufCNHBusca === '' ?
                                        '' :

                                        collaboratorUpdate && collaboratorUpdate.ufCNH ?
                                        collaboratorUpdate.ufCNH
                                        :
                                        collaborator && collaborator.ufCNH ?
                                        collaborator.ufCNH : ''
                                }
                                nameCampoBusca={"ufCNHBusca"}
                                nameButton={"ufCNH"}
                                myChangeHandler={myChangeHandlerSlects}
                                option={optionFiltreUfCidadeCNH}
                                typeSelect={"busca"}
                            />
                        </div>
                    </div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <h5>Carteira de Trabalho</h5>
                        <div></div>
                    </div>
                    <div className='box'></div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Número da carteira</p>
                        <div>

                            {
                                (collaboratorUpdate && collaboratorUpdate.walletNumber !== undefined) || collaboratorUpdate.walletNumber === '' ?
                                <input ref={focuswalletNumber} type="text" name="walletNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.walletNumber ? collaboratorUpdate.walletNumber : ''} placeholder="Número da carteira" onChange={onCollaboratorInputChange} className="inputUm"/> :
                                collaborator && collaborator.walletNumber ?
                                <input ref={focuswalletNumber} type="text" name="walletNumber" autocomplete="autocompleteoff" value={collaborator.walletNumber ? collaborator.walletNumber : ''} placeholder="Número da carteira" onChange={onCollaboratorInputChange} className="inputUm"/> :
                                <input ref={focuswalletNumber} type="text" name="walletNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.walletNumber ? collaboratorUpdate.walletNumber : ''} placeholder="Número da carteira" onChange={onCollaboratorInputChange} className="inputUm"/>
                            }
                            {
                            loadwalletNumber ?
                            <p className="textError inputUm">É necessário preencher o Número da carteira</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">Nº de série</p>
                        <div>

                            {
                                (collaboratorUpdate && collaboratorUpdate.serialNumber !== undefined) || collaboratorUpdate.serialNumber === '' ?
                                <input ref={focusserialNumber} type="text" name="serialNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.serialNumber ? collaboratorUpdate.serialNumber : ''} placeholder="Nº de série" onChange={onCollaboratorInputChange} className="inputDois"/> :
                                collaborator && collaborator.serialNumber ?
                                <input ref={focusserialNumber} type="text" name="serialNumber" autocomplete="autocompleteoff" value={collaborator.serialNumber ? collaborator.serialNumber : ''} placeholder="Nº de série" onChange={onCollaboratorInputChange} className="inputDois"/> :
                                <input ref={focusserialNumber} type="text" name="serialNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.serialNumber ? collaboratorUpdate.serialNumber : ''} placeholder="Nº de série" onChange={onCollaboratorInputChange} className="inputDois"/>
                            }
                            {
                            loadserialNumber ?
                            <p className="textError inputDois">É necessário preencher o Nº de série</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Data de emissão</p>
                        <div>
                            {
                                (collaboratorUpdate && collaboratorUpdate.issueDate !== undefined) || collaboratorUpdate.issueDate === '' ?
                                <input ref={focusissueDate} type="text" name="issueDate" autocomplete="autocompleteoff" value={collaboratorUpdate.issueDate ? collaboratorUpdate.issueDate : ''} placeholder="DD/MM/AAA" onChange={onCollaboratorInputChange} className="inputTreis"/> :
                                collaborator && collaborator.issueDate ?
                                <input ref={focusissueDate} type="text" name="issueDate" autocomplete="autocompleteoff" value={collaborator.issueDate ? collaborator.issueDate : ''} placeholder="DD/MM/AAA" onChange={onCollaboratorInputChange} className="inputTreis"/> :
                                <input ref={focusissueDate} type="text" name="issueDate" autocomplete="autocompleteoff" value={collaboratorUpdate.issueDate ? collaboratorUpdate.issueDate : ''} placeholder="DD/MM/AAA" onChange={onCollaboratorInputChange} className="inputTreis"/>
                            }
                            {
                            loadissueDate ?
                            <p className="textError inputTreis">É necessário preencher a Data de emissão</p>
                            :
                            ('')
                            }
                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">UF da carteira</p>
                        <div>

                        <SelectPersonalizado
                                valorCampo={

                                    nationalityBuscaString && nationalityBuscaString.walletUFBusca ?
                                    nationalityBuscaString.walletUFBusca :
                                    nationalityBuscaString && nationalityBuscaString.walletUFBusca === '' ?
                                        '' :

                                        collaboratorUpdate && collaboratorUpdate.walletUF ?
                                        collaboratorUpdate.walletUF
                                        :
                                        collaborator && collaborator.walletUF ?
                                        collaborator.walletUF : ''
                                }
                                nameCampoBusca={"walletUFBusca"}
                                nameButton={"walletUF"}
                                myChangeHandler={myChangeHandlerSlects}
                                option={optionFiltreUfCidadeWalletUF}
                                typeSelect={"busca"}
                            />
                        </div>
                    </div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Número PIS</p>
                        <div>
                            {
                                (collaboratorUpdate && collaboratorUpdate.pisNumber !== undefined) || collaboratorUpdate.pisNumber === '' ?
                                <input ref={focuspisNumber} type="text" name="pisNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.pisNumber ? collaboratorUpdate.pisNumber : ''} placeholder="Nº de série" onChange={onCollaboratorInputChange} className="inputCinco"/> :
                                collaborator && collaborator.pisNumber ?
                                <input ref={focuspisNumber} type="text" name="pisNumber" autocomplete="autocompleteoff" value={collaborator.pisNumber ? collaborator.pisNumber : ''} placeholder="Nº de série" onChange={onCollaboratorInputChange} className="inputCinco"/> :
                                <input ref={focuspisNumber} type="text" name="pisNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.pisNumber ? collaboratorUpdate.pisNumber : ''} placeholder="Nº de série" onChange={onCollaboratorInputChange} className="inputCinco"/>
                            }
                            {
                            loadpisNumber ?
                            <p className="textError inputCinco">É necessário preencher o Número PIS</p>
                            :
                            ('')
                            }
                        </div>
                    </div>
                    <div className='box'>
                    </div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <h5>Título de Eleitor</h5>
                        <div></div>
                    </div>
                    <div className='box'></div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Número do título</p>
                        <div>
                            {
                                (collaboratorUpdate && collaboratorUpdate.voterRegistration !== undefined) || 
                                collaboratorUpdate.voterRegistration === '' ?
                                <input ref={focusvoterRegistration} type="text" name="voterRegistration" autocomplete="autocompleteoff" value={collaboratorUpdate.voterRegistration ? collaboratorUpdate.voterRegistration : ''} onChange={onCollaboratorInputChange} className={loadvoterRegistration ? 'inputErro inputUm' : 'inputUm'} placeholder="Número do título"/> :
                                collaborator && collaborator.voterRegistration ?
                                <input ref={focusvoterRegistration} type="text" name="voterRegistration" autocomplete="autocompleteoff" value={collaborator.voterRegistration ? collaborator.voterRegistration : ''} onChange={onCollaboratorInputChange} className={loadvoterRegistration ? 'inputErro inputUm' : 'inputUm'} placeholder="Número do título"/> :
                                <input ref={focusvoterRegistration} type="text" name="voterRegistration" autocomplete="autocompleteoff" value={collaboratorUpdate.voterRegistration ? collaboratorUpdate.voterRegistration : ''} onChange={onCollaboratorInputChange} className={loadvoterRegistration ? 'inputErro inputUm' : 'inputUm'} placeholder="Número do título"/>
                            }
                            {
                            loadvoterRegistration ?
                            <p className="textError inputUm">O campo é obrigatório.</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                    <div className='box'>
                        <p className="textCampo">Zona eleitoral</p>
                        <div>

                            {
                                (collaboratorUpdate && collaboratorUpdate.electoralZone !== undefined) || 
                                collaboratorUpdate.electoralZone === '' ?
                                <input ref={focuselectoralZone} type="text" name="electoralZone" autocomplete="autocompleteoff" value={collaboratorUpdate.electoralZone ? collaboratorUpdate.electoralZone : ''} onChange={onCollaboratorInputChange} placeholder="Zona eleitoral" className={loadelectoralZone ? 'inputErro inputDois' : 'inputDois'}/> :
                                collaborator && collaborator.electoralZone ?
                                <input ref={focuselectoralZone} type="text" name="electoralZone" autocomplete="autocompleteoff" value={collaborator.electoralZone ? collaborator.electoralZone : ''} onChange={onCollaboratorInputChange} placeholder="Zona eleitoral" className={loadelectoralZone ? 'inputErro inputDois' : 'inputDois'}/> :
                                <input ref={focuselectoralZone} type="text" name="electoralZone" autocomplete="autocompleteoff" value={collaboratorUpdate.electoralZone ? collaboratorUpdate.electoralZone : ''} placeholder="Zona eleitoral" onChange={onCollaboratorInputChange} className={loadelectoralZone ? 'inputErro inputDois' : 'inputDois'}/>
                            }
                            {
                            loadelectoralZone ?
                            <p className="textError inputDois">O campo é obrigatório.</p>
                            :
                            ('')
                            }
                        </div>
                    </div>
                </div>

                <div className='linha'>
                    <div className='box'>
                        <p className="textCampo">Seção eleitoral</p>
                        <div>
                            {
                                (collaboratorUpdate && collaboratorUpdate.pollingStation !== undefined) || 
                                collaboratorUpdate.pollingStation === '' ?
                                <input ref={focuspollingStation} type="text" name="pollingStation" autocomplete="autocompleteoff" value={collaboratorUpdate.pollingStation ? collaboratorUpdate.pollingStation : ''} placeholder="Seção eleitoral" onChange={onCollaboratorInputChange} className={loadpollingStation ? 'inputErro inputTreis' : 'inputTreis'}/> :
                                collaborator && collaborator.pollingStation ?
                                <input ref={focuspollingStation} type="text" name="pollingStation" autocomplete="autocompleteoff" value={collaborator.pollingStation ? collaborator.pollingStation : ''} placeholder="Seção eleitoral" onChange={onCollaboratorInputChange} className={loadpollingStation ? 'inputErro inputTreis' : 'inputTreis'}/> :
                                <input ref={focuspollingStation} type="text" name="pollingStation" autocomplete="autocompleteoff" value={collaboratorUpdate.pollingStation ? collaboratorUpdate.pollingStation : ''} placeholder="Seção eleitoral" onChange={onCollaboratorInputChange} className={loadpollingStation ? 'inputErro inputTreis' : 'inputTreis'}/>
                            }
                            {
                            loadpollingStation ?
                            <p className="textError inputTreis">O campo é obrigatório.</p>
                            :
                            ('')
                            }

                        </div>
                    </div>
                    <div className='box'>
                    </div>
                </div>


                {
                    (collaboratorUpdate && collaboratorUpdate.gender === "Masculino") ||
                    (collaborator && collaborator.gender === "Masculino") ?
                    (
                        <>

                            <div className='linha'>
                                <div className='box'>
                                    <h5>Carteira de Reservista</h5>
                                    <div></div>
                                </div>
                                <div className='box'></div>
                            </div>

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Número</p>
                                    <div>
                                        {
                                            (collaboratorUpdate && collaboratorUpdate.reservationCardNumber !== undefined) || 
                                            collaboratorUpdate.reservationCardNumber === '' ?
                                            <input ref={focusreservationCardNumber} type="text" name="reservationCardNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardNumber ? collaboratorUpdate.reservationCardNumber : ''} placeholder="Número" onChange={onCollaboratorInputChange} className={loadreservationCardNumber ? 'inputErro inputUm' : 'inputUm'}/> :
                                            collaborator && collaborator.reservationCardNumber ?
                                            <input ref={focusreservationCardNumber} type="text" name="reservationCardNumber" autocomplete="autocompleteoff" value={collaborator.reservationCardNumber ? collaborator.reservationCardNumber : ''} onChange={onCollaboratorInputChange} className={loadreservationCardNumber ? 'inputErro inputUm' : 'inputUm'}/> :
                                            <input ref={focusreservationCardNumber} type="text" name="reservationCardNumber" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardNumber ? collaboratorUpdate.reservationCardNumber : ''} placeholder="Número" onChange={onCollaboratorInputChange} className={loadreservationCardNumber ? 'inputErro inputUm' : 'inputUm'}/>
                                        }
                                        {
                                        loadreservationCardNumber ?
                                        <p className="textError inputUm">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }

                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Série</p>
                                    <div>
                                        {
                                            (collaboratorUpdate && collaboratorUpdate.reservationCardSeries !== undefined) || 
                                            collaboratorUpdate.reservationCardSeries === '' ?
                                            <input ref={focusreservationCardSeries} type="text" maxLength="4" name="reservationCardSeries" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardSeries ? collaboratorUpdate.reservationCardSeries : ''} placeholder="Série" onChange={onCollaboratorInputChange}  className={loadreservationCardSeries ? 'inputErro inputDois' : 'inputDois'}/> :
                                            collaborator && collaborator.reservationCardSeries ?
                                            <input ref={focusreservationCardSeries} type="text" maxLength="4" name="reservationCardSeries" autocomplete="autocompleteoff" value={collaborator.reservationCardSeries ? collaborator.reservationCardSeries : ''} onChange={onCollaboratorInputChange}  className={loadreservationCardSeries ? 'inputErro inputDois' : 'inputDois'}/> :
                                            <input ref={focusreservationCardSeries} type="text" maxLength="4" name="reservationCardSeries" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardSeries ? collaboratorUpdate.reservationCardSeries : ''} placeholder="Série" onChange={onCollaboratorInputChange}  className={loadreservationCardSeries ? 'inputErro inputDois' : 'inputDois'}/>
                                        }
                                        {
                                        loadreservationCardSeries ?
                                        <p className="textError inputDois">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Categoria</p>
                                    <div>
                                        {
                                            (collaboratorUpdate && collaboratorUpdate.reservationCardCategory !== undefined) || 
                                            collaboratorUpdate.reservationCardCategory === '' ?
                                            <input ref={focusreservationCardCategory} type="text" name="reservationCardCategory" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardCategory ? collaboratorUpdate.reservationCardCategory : ''} onChange={onCollaboratorInputChange} placeholder="Categoria" className={loadreservationCardCategory ? 'inputErro inputTreis' : 'inputTreis'}/> :
                                            collaborator && collaborator.reservationCardCategory ?
                                            <input ref={focusreservationCardCategory} type="text" name="reservationCardCategory" autocomplete="autocompleteoff" value={collaborator.reservationCardCategory ? collaborator.reservationCardCategory : ''} onChange={onCollaboratorInputChange} className={loadreservationCardCategory ? 'inputErro inputTreis' : 'inputTreis'}/> :
                                            <input ref={focusreservationCardCategory} type="text" name="reservationCardCategory" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardCategory ? collaboratorUpdate.reservationCardCategory : ''} onChange={onCollaboratorInputChange} placeholder="Categoria" className={loadreservationCardCategory ? 'inputErro inputTreis' : 'inputTreis'}/>
                                        }
                                        {
                                        loadreservationCardCategory ?
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }
                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Data Expedição</p>
                                    <div>
                                        {
                                            (collaboratorUpdate && collaboratorUpdate.reservationCardExpeditionDate !== undefined) || 
                                            collaboratorUpdate.reservationCardExpeditionDate === '' ?
                                            <input ref={focusreservationCardExpeditionDate} type="text" name="reservationCardExpeditionDate" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardExpeditionDate ? collaboratorUpdate.reservationCardExpeditionDate : ''} placeholder="DD/MM/AAAA" onChange={onCollaboratorInputChange} className={loadreservationCardExpeditionDate ? 'inputErro inputQuatro' : 'inputQuatro'}/> :
                                            collaborator && collaborator.reservationCardExpeditionDate ?
                                            <input ref={focusreservationCardExpeditionDate} type="text" name="reservationCardExpeditionDate" autocomplete="autocompleteoff" value={collaborator.reservationCardExpeditionDate ? collaborator.reservationCardExpeditionDate : ''} onChange={onCollaboratorInputChange} className={loadreservationCardExpeditionDate ? 'inputErro inputQuatro' : 'inputQuatro'}/> :
                                            <input ref={focusreservationCardExpeditionDate} type="text" name="reservationCardExpeditionDate" autocomplete="autocompleteoff" value={collaboratorUpdate.reservationCardExpeditionDate ? collaboratorUpdate.reservationCardExpeditionDate : ''} placeholder="DD/MM/AAAA" onChange={onCollaboratorInputChange} className={loadreservationCardExpeditionDate ? 'inputErro inputQuatro' : 'inputQuatro'}/>
                                        }
                                        {
                                        loadreservationCardExpeditionDate ?
                                        <p className="textError inputQuatro">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='linha'>
                                <div className='box'>
                                    <p className="textCampo">Estado</p>
                                    <div>
                                    <SelectPersonalizado
                                        valorCampo={

                                            nationalityBuscaString && nationalityBuscaString.ufReservationCardBusca ?
                                            nationalityBuscaString.ufReservationCardBusca :
                                            nationalityBuscaString && nationalityBuscaString.ufReservationCardBusca === '' ?
                                                '' :

                                                collaboratorUpdate && collaboratorUpdate.ufReservationCard ?
                                                ufEscolhidoUpdateC.nome 
                                                :
                                                collaborator && collaborator.ufReservationCard ?
                                                ufEscolhido.nome : ''
                                        }
                                        nameCampoBusca={"ufReservationCardBusca"}
                                        nameButton={"ufReservationCard"}
                                        loadError={loadufReservationCard}
                                        myChangeHandler={myChangeHandlerSlects}
                                        option={optionFiltreUfEstados}
                                        validacaoValue={"estado"}
                                        validacaoOptionIntem={"estado"}
                                        typeSelect={"busca"}
                                        focusRef={focusufReservationCard}
                                    />
                                    {
                                        loadufReservationCard ?
                                        <p className="textError inputCinco">O campo é obrigatório.</p>
                                        :
                                        ('')
                                    }

                                    </div>
                                </div>
                                <div className='box'>
                                    <p className="textCampo">Cidade</p>
                                    <div>
                                        
                                        <SelectPersonalizado
                                            valorCampo={
                
                                                nationalityBuscaString && nationalityBuscaString.cityReservationCardBusca ?
                                                nationalityBuscaString.cityReservationCardBusca  :
                                                nationalityBuscaString && nationalityBuscaString.cityReservationCardBusca === '' ?
                                                    '' :
                                                    collaboratorUpdate && collaboratorUpdate.cityReservationCard ?
                                                    collaboratorUpdate.cityReservationCard :
                                                    collaborator && collaborator.cityReservationCard  ?
                                                    collaborator.cityReservationCard  : ''
                                            }
                                            nameCampoBusca={"cityReservationCardBusca"}
                                            nameButton={"cityReservationCard"}
                                            loadError={loadcityReservationCard}
                                            myChangeHandler={myChangeHandlerSlects}
                                            option={optionFiltreUfCidade}
                                            validacaoValue={"pais"}
                                            validacaoOptionIntem={"pais"}
                                            typeSelect={"busca"}
                                            focusRef={focuscityReservationCard}
                                        />
                                        {
                                        loadcityReservationCard ?
                                        <p className="textError inputSeis">O campo é obrigatório.</p>
                                        :
                                        ('')
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                    ) :
                    ''
                }

            </div>
        </div>
        </>
    );
}
export default formBasicInfor;
