import React from 'react';
import { NavLink } from 'react-router-dom'
import IconsWait from '../../../../assets/images/wait.svg';
import Close from '../../../../assets/images/close.svg';
// import swal from 'sweetalert';
// import api from '../../../../services/api';

import { Modal } from "react-bootstrap";

export default function formBasicInfor({
    editCollaborator,
    onCollaboratorInputChange,
    onCollaboratorInputDeleteChange,
    deleteAdmission,
    visibleInforEmail,
    FecharInforEmail,
    EditStepProcessTwo,
    loadPreencherManualmente,
    loadSolicitarPreenchimento
}) {


    return (
        <>
            <div  className="fillingEmployee">

                {
                    editCollaborator && editCollaborator?.stepProcessTwo === 'escolher' ?
                    <div className='boxFillDecision'>
                        <h3>Dados Pessoais do colaborador</h3>
                        <p>
                            Você pode preencher manualmente os dados pessoais do colaborador ou enviar um email com o link da página de cadastro
                            onde ele mesmo poderá preencher seus dados pessoais, para isso,
                            é necessário que o campo “e-mail” na etapa anterior tenha sido informado. O que você deseja fazer?
                        </p>
                        <div className='buttons'>
                            <button
                            className={loadSolicitarPreenchimento ? "btnGreen load" : "btnGreen"}
                            // onClick={() => setVisibleInforEmail(true)}
                            onClick={()=>EditStepProcessTwo('solicitar_preenchimento')}
                            >
                                Solicitar preenchimento ao colaborador
                            </button>
                            <button
                            name="collaboratorEdition"
                            onClick={()=>EditStepProcessTwo('preencher_manualmente')}
                            className={loadPreencherManualmente ? "btnGreen load" : "btnGreen"}>
                                Preencher manualmente
                            </button>
                        </div>
                    </div>
                    :
                    ''
                }
                {
                    editCollaborator && editCollaborator?.stepProcessTwo === 'solicitar_preenchimento' ?
                    <div className="boxFill">
                        <img alt="" src={IconsWait } className="iconsWait"/>
                        <h3>Aguardando o preenchimento do colaborador</h3>
                        <p>
                            Um e-mail foi enviado para <strong>{
                            editCollaborator.email !== undefined  && editCollaborator.personalEmail !== undefined ?
                            editCollaborator?.email :
                            editCollaborator?.personalEmail === undefined && editCollaborator?.email !== undefined ?
                            editCollaborator?.email :
                            editCollaborator?.email === undefined && editCollaborator?.personalEmail !== undefined ?
                            editCollaborator?.personalEmail:  ""


                            // editCollaborator && editCollaborator.email !== undefined ? editCollaborator.email : editCollaborator.personalEmail
                            
                            }</strong> com um link para o preenchimento do resto das informações.
                            Você será notificado via e-mail assim que o colaborador concluir o preenchimento dos seus dados.
                        </p>
                        <button
                        onClick={()=>EditStepProcessTwo('preencher_manualmente')}
                        className={loadPreencherManualmente ? "btnGreen fillInManually load" : "btnGreen fillInManually"}>
                            Preencher manualmente
                        </button>
                        <NavLink to="/colaboradores/admissao" className="backCollaboratorsList btnGray">Voltar à lista de colaboradores</NavLink>
                    </div>
                    :
                    ''
                }

                <div className="buttons">
                    <div>
                        <button onClick={deleteAdmission} type="button" className="deleteStepTwo">
                            Excluir processo
                        </button>
                    </div>
                    {/*<button className="saved">Salvar e continuar</button>*/}
                    {editCollaborator ?
                    (
                        <NavLink className="btnGray back" to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/${editCollaborator._id}`}>
                        Voltar
                        </NavLink>
                    ) :
                    ('')
                    }
                </div>
            </div>


            <Modal show={visibleInforEmail} restoreFocus={false}  className="popup-geral popup-infor-novaAdmissao" onHide={FecharInforEmail}>
                <div className="titulo">
                    <h4>Colaborador não possui email cadastrado.  <p className="btn-fechar" onClick={FecharInforEmail}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className='infor'>
                    <p>
                    Para solicitar o preenchimento dos dados pelo colaborador é necessário informar ao menos um email no cadastro da etapa1. Por favor informe um email no campo "Email" ou no campo "Email de acesso".
                    </p>

                    <NavLink className="btnGray" to={`/colaboradores/nova-admissao/formulario-Informacoes-basicas/${editCollaborator._id}`}>
                         Ir para Informações basicas
                    </NavLink>

                </div>
            </Modal>

        </>
    );
}
