import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import swal from 'sweetalert';
import api from '../../../../../services/api';
import { toast } from 'react-toastify';
import { formatDateString } from '../../../../../services/utils';

const formBasicInfor = ({ id, path }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ fileForm, setFileForm ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filesCollaborator, setFilesCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCategory, setLoadCategory ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadFileForm ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id) {
            loadfilescollaborator(id);
        }

    }, [id]);

    async function loadfilescollaborator(id) {
        setLoad(true)
        await api.get(`/collaborator-file/?id=${id}&category=docCPF&category=docRG&category=docCNH&category=docCT&category=docTE&category=docCR`)
        .then(response => {
            setLoad(false)
            setFileNames();
            setFilesCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoad(false)
        });
    }



    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;

        setFileForm({ ...fileForm, [nam]: val })
        //this.setState({ fileForm: { ...this.state.fileForm, [nam]: val } });

    }

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            let file = acceptedFiles[0];
            let name = acceptedFiles[0].name;

            setFileNames(acceptedFiles.map(file => file.name));
            setFileForm({ ...fileForm,  file, name })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }



    async function saveCopyDocuments(event) {
        event.preventDefault();

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if(fileForm.category === undefined) {
            setLoadCategory(true)
            return
        } else {
            setLoadCategory(false)
            formData.append('category', fileForm.category);
        }

        if (fileForm.file === undefined) {
            setLoadFileForm(true)
            return
        } else {
            setLoadFileForm(false)
            formData.append('file', fileForm.file);
            formData.append('name', fileForm.name);
        }

        formData.append('collaborator', id);
        formData.append('path', path);

        setLoad(true)
        await api.post("/collaborator-file", formData, config)
        .then(response => {
            loadfilescollaborator(id);
            swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
        }).catch(error => {
            setLoad(false)
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
        });



    }


    async function deleteDoc(collaborator) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/collaborator-file/${collaborator._id}?path=${path}`)
                        .then(() => {
                            loadfilescollaborator(id);
                            swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                        }).catch(() => {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                        });
                }
            });
    }



   async function deleteFiles() {
    setFileNames();
   }

    return (
        <>
            <div className="box CopiesOfDocuments">
                <h3>Cópias de documentos</h3>
                <form onSubmit={saveCopyDocuments}>
                <div className="formImgDocs">
                    <p>Tipo</p>
                    <select name="category" onChange={myChangeHandler} className={loadCategory ? 'tipoDoc inputErro' : 'tipoDoc'} >
                        <option>Selecione</option>
                        <option value="docCPF">CPF</option>
                        <option value="docRG">RG</option>
                        <option value="docCNH">CNH</option>
                        <option value="docCT">Carteira de Trabalho</option>
                        <option value="docTE">Título de Eleitor</option>
                        <option value="docCR">Carteira de Reservista</option>
                    </select>
                    <span className="textError">{loadCategory ? 'É necessário selecionar um nome de arquivo' : ''}</span>
                    <div className="anexarArquivo ">
                        <Dropzone onDrop={handleDrop} >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <input type="file" name="file" {...getInputProps()} />
                                <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                            </div>
                            )}
                        </Dropzone>
                            <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                        <div className="arquivoAnexado">
                        {fileNames ?
                        fileNames.map((filename, index) => (
                            <div key={index} className="arquivo">
                                <p className="nomeArquivo">
                                    {filename}
                                </p>
                                <p onClick={deleteFiles} className="remover">Remover anexo</p>
                            </div>
                        )) : ''}
                        </div>
                    </div>
                    <button type= "submit" className={load ? 'enviar btnGreen btnGreenLoad' : 'enviar btnGreen'}>{load ? 'enviando...' : 'Enviar documentos'}</button>
                </div>
                </form>
                <div className="ArquivosEnviados">
                    <h6>Enviados</h6>

                    {filesCollaborator[0] !== undefined ? filesCollaborator
                    .map((collaborator, index) => (
                        <div key={index} className="arquivos">
                            <div className="nameArquivo">
                                <span>
                                    {collaborator.category === 'docCPF' ?
                                    'CPF' :
                                    collaborator.category === 'docRG' ?
                                    'RG' :
                                    collaborator.category === 'docCNH' ?
                                    'CNH' :
                                    collaborator.category === 'docCT' ?
                                    'Carteira de Trabalho' :
                                    collaborator.category === 'docTE' ?
                                    'Título de Eleitor' :
                                    collaborator.category === 'docCR' ?
                                    'Carteira de Reservista' :
                                        ''

                                    }
                                </span>

                                <a href={collaborator.location} target="’_blank’">
                                    {collaborator.originalname}
                                </a>
                            {/* {collaborator.originalname} */}
                            </div>
                            <div className="dataArquivo">
                                <span>Enviado em {formatDateString(collaborator.createdAt)}</span>
                            </div>
                            <div className="btnExclui">
                                <button onClick={() => deleteDoc(collaborator)}>Excluir anexo</button >
                            </div>
                        </div>
                    )): (
                        <div className="nenhum-arq">
                        <p>Nenhum documento cadastrado.</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default formBasicInfor;
