import React from 'react';

import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Beneficios/header';
import RegisterBenefits from '../../../components/Administrativo/Beneficios/register-benefit.js'
import './styles.scss';
import { decodeToken } from '../../../services/auth';

export default function ColRegisterBenefit(props) {

    const { history } = props;
    const { user } = decodeToken();

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                history={history}
                user={user}
                path={props.match.path}
                />
                <RegisterBenefits
                history={props.history}
                path={props.match.path}
                />
            </Layout>
        </>
    );
}

