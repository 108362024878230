import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import iconOrdenation from '../../../assets/images/ordenation.svg';
import iconVerMais from '../../../assets/images/more.svg';
import Carregamento from '../../../assets/images/carregando.gif';
import { formatDateString } from '../../../services/utils';
import { Scrollbars } from 'react-custom-scrollbars';


export default function latestCandidates({
    latestCandidates,
    loadLatestCandidates,
    moveAndEditCandidateToFolder,
    folderAtivos,
    isActiveSelectLaborBond,
    SelectLaborBond,
    setIsActiveSelectLaborBond,
    loadLaborBond,
    setLoadLaborBond,
    setSortBy,
    sortByCandidate

}) {

    const myChangeHandlerLoborBondFilter= event =>{
        let val = event.target.value;
        setLoadLaborBond(val)
    }

    const candidatesSearchA = latestCandidates?.candidatesSearch ? latestCandidates.candidatesSearch :"";
    return (
        <>
        <div className='latest-candidates'>
            <div className='title-filter'>
                <h2 className='title-box'>
                    Últimos candidatos
                    <NavLink to={"/recrutamento/talentos/candidatos"}>Ver todos os candidatos</NavLink>
                </h2>
                <div className="filter">
                    <div className='checks'>
                        <p>Mostrando</p>
                        <div className='list'>
                           
                            <div className="select-busca-btn">
                                <button 
                                    className="menu-button"
                                    onBlur={ () => (setIsActiveSelectLaborBond(false)) }
                                    onClick={ SelectLaborBond }
                                >
                                    <span>
                                    {
                                        loadLaborBond && loadLaborBond !== "" ?
                                            loadLaborBond  === "PJ" ? "Pessoa Jurídica" 
                                            : loadLaborBond
                                        :"Todos os Regime"
                                        
                                    }

                                    </span>
                                
                                </button>
                                <nav className={`menu ${isActiveSelectLaborBond ? "ativo": ""}`} >

                                    <Scrollbars className='listDropDown'  style={{  height: 100
                                    }}>
                                        <ul>

                                            {   
                                            [
                                                "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "Pessoa Jurídica", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                            ]
                                                .map((LaborBond, index) => (
                                                    <li key={index}>
                                                        <button 
                                                        value={LaborBond === "Pessoa Jurídica" ? "PJ" : LaborBond }
                                                        name="laborBond"
                                                        onClick={myChangeHandlerLoborBondFilter}
                                                        >{LaborBond}</button>
                                                    </li>
                                                ))}
                                        </ul>
                                        
                                    </Scrollbars>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='candidates-table'>
                <div className='head-table grid-table'>
                    <div className='nome'>Nome</div>
                    <div className='data'>Recebido em</div>
                    <div className='setor'>Setor desejado</div>
                    <div className='pasta'>Pasta</div>
                    <div className='regime'>Regime</div>
                    <div className='curriculo'>Currículo</div>
                    <div className='view-more'>
                        <Dropdown  className="dropdown-icon">
                            <Dropdown.Toggle>
                            <img alt="" src={iconOrdenation} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                    <button onClick={
                                        ()=> setSortBy('mais_recente')
                                        } type="button" >
                                    Mais recentes primeiro
                                    </button>
                                    <button
                                    // mais_antigo
                                    onClick={
                                        ()=> setSortBy('mais_antigo')
                                    } type="button"
                                    >
                                    Mais antigos primeiro
                                    </button>
                                    <button onClick={
                                        ()=> setSortBy('por_nome')
                                    } type="button" >
                                    Nome em ordem alfabética
                                    </button>
                                    <button onClick={
                                         ()=> setSortBy('por_setor')
                                    } type="button" >
                                    Setor em ordem alfabética
                                    </button>
                            </Dropdown.Menu>
                        </Dropdown>


                    </div>
                </div>
                <h4 className='infor-filtro'>{
                    sortByCandidate === 'mais_recente'  ?
                    <> Mais recentes  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                    :
                    sortByCandidate === 'mais_antigo' ?
                    <> Mais antigos  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                    :
                    sortByCandidate === 'por_nome' ?
                    <> Ordem alfabética  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                    :
                    sortByCandidate === 'por_setor' ?
                    <> Ordem alfabética por setor  { loadLaborBond && loadLaborBond !== "" ? `de ${loadLaborBond}` :""} </>
                    :
                    sortByCandidate === 'mais_recente' ?
                    'Mais recentes'
                    :
                    sortByCandidate === 'mais_antigo' ?
                    'Mais antigos'
                    :
                    sortByCandidate === 'por_nome' ?
                    'Por Ordem alfabética'
                    :
                    sortByCandidate === 'por_setor' ?
                    'Por setor em ordem alfabética'
                    :
                    loadLaborBond && loadLaborBond !== "" ?
                    loadLaborBond :""
                }</h4>
                <div className='body-table'>
                {
                    loadLatestCandidates ?
                    <>
                    <div className="carregamento">
                        <img alt="" src={Carregamento} />
                    </div>
                    </>
                    :candidatesSearchA && 
                    candidatesSearchA?.length === 0 ?

                    <div className='nenhum-candidato'>
                        <p>Nenhum candidato. <br/>Anuncie vagas ou cadastre manualmente.</p>
                        <NavLink to="/recrutamento/cadastro-candidato"className='btnGreen more'> Cadastrar novo candidato </NavLink>
                    </div> 
                    :
                
                    candidatesSearchA !== "" ? 
                    candidatesSearchA   
                    .map((candidate, index) => (
                    <>
                    <div className='grid-table candidates'key={index}>

                        <NavLink to={`/recrutamento/perfil-candidato/${candidate?._id}`} className="link-perfil">
                            <div className='nome'>{candidate?.name}</div>
                            <div className='data'> {formatDateString(candidate?.updatedAt)}  </div>
                            <div className='setor'>{candidate?.sector?.name ?? "-"}</div>
                            <div className='pasta'>{candidate?.folder?.name ?? "-"}</div>
                            <div className='regime'>{candidate?.laborBond === "PJ" ? "Pessoa Jurídica" : candidate.laborBond  }</div>
                            <div className='curriculo'>
                                    {
                                        candidate && candidate?.curriculum !== undefined ?
                                        <><a  target="’_blank’" href={candidate && candidate?.curriculum}>Ver currículo</a></>:""
                                    }
                            </div>
                        </NavLink>
                        <div className='view-more'>
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                        {folderAtivos.length !== 0 ? 
                                        <>
                                        <NavLink to={`/recrutamento/perfil-candidato/${candidate?._id}`} >
                                            Ver perfil
                                        </NavLink>
                                        <NavLink to={`/recrutamento/editar-candidato/${candidate?._id}`} >
                                            Editar perfil
                                        </NavLink>
                                            <div className='mudar-pasta'>
                                                <p>Mover para</p>
                                                <select onChange={moveAndEditCandidateToFolder} name="folderSelect">
                                                    <option>Selecione a pasta</option>
                                                    {
                                                    folderAtivos &&
                                                    folderAtivos.length !== 0 ?
                                                    folderAtivos.map((folder, index) => (
                                                        <option key={index} value={folder._id+'&&'+folder.name+'&&'+candidate._id+'&&'+candidate.name}>{folder.name}</option>
                                                    ))
                                                    :
                                                    <option>Nenhuma pasta para selecionar</option>
                                                    }
                                                </select>
                                            </div>
                                        </>
                                        :""}
                                    {/* <button onClick={()=>inactiveCandidate(candidate._id,candidate.name)} type="button" className='red'>Excluir candidato</button> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>

                    </>
                    )) : ("")}
                </div>

            </div>

        </div>

        </>
    );
}

