import React from 'react';
import SubMenu from './subMenu';
import PeriodicExam from './Documents/PeriodicExam';





const ColProfileProficionalDoc = ({ id, collaborator }) => {

        return (
            <>
                <SubMenu id={id} collaborator={collaborator}/>
                <div className="box-infor-perfil ">
                    <PeriodicExam id={id} collaboratorState={collaborator}/>

                </div>
            </>
        );
}
export default ColProfileProficionalDoc;
