import React, { useState } from "react";
import api from "../../services/api";
import { decodeToken } from "../../services/auth";

import './../Login/styles.scss';
import logo from '../../assets/images/logo.svg';
import iconEyeSee from '../../assets/images/ico-see.svg';
import iconEyeSeeOff from '../../assets/images/ico-see-off.svg';

import swal from "sweetalert";

export default function FirstAccess() {

    const [load, setLoad] = useState(false);

    const user = decodeToken();
    console.log("User", user)

    const [state, setState]           = useState([])

    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;
        setState({...state, [nam]: val})
    }

    async function renewPassword(event) {
        event.preventDefault();
        const { password } = state;
        //alert(password)
        setLoad(true)
        await api.put(`/first-access`, { password })
            .then(response => {
                setLoad(false)
                //console.log(response.data.role)
                swal({ icon: "success", title: "Sucesso!", text: "Senha atualizada com sucesso." });
                let role = response.data.role;
                if(role === 'user') {
                    setTimeout(() => {
                        window.location.href = "/dashboard-user";
                    }, 3000);
                }
                else if(role === 'admin' || role === 'manager' || role === 'master') {
                    setTimeout(() => {
                        window.location.href = "/colaboradores/ativos";
                    }, 3000);
                } else {
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 3000);
                }

            })
            .catch((error) => {
                // console.log('error')
                // console.log(error)
                setLoad(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao atualizar a senha, tente novamente mais tarde." });
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            });
    }

    async function logout() {
        localStorage.clear();
        setTimeout(function(){window.location.href = '/';}, 1000);
        //setTimeout(function(){history.push('/');}, 1000);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [passwordTypeOne, setPasswordTypeOne] = useState(true);
    function HideOrShowPasswordOne(e) {
        e.preventDefault()
        setPasswordTypeOne(!passwordTypeOne)
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [passwordTypeTwo, setPasswordTypeTwo] = useState(true);
    function HideOrShowPasswordTwo(e) {
        e.preventDefault()
        setPasswordTypeTwo(!passwordTypeTwo)
    }

    return (
        <>

            <div className="pageLogin">
                <img src={logo} alt="People View" className="logo"/>

                <form className="formLogin primeiro-acessso" onSubmit={renewPassword}>
                    <h2 className="titulo-primeiro-acesso">Olá, {user.user.name}</h2>
                    <p className="subtitulo-primeiro-acesso">Para continuar, crie uma nova senha:</p>
                    <label className="autera-senha-subtitulo">Nova senha:</label>

                    <div className="password-input">
                        <button
                        onClick={HideOrShowPasswordOne}
                            className={passwordTypeOne ?"":"active"}
                        >
                            {
                                passwordTypeOne ?
                                <img src={iconEyeSeeOff} alt="Olho" />
                                :
                                <img src={iconEyeSee} alt="Olho" />
                            }
                        </button>
                        <input type={passwordTypeOne ? "password" : "text"} name="password" onChange={myChangeHandler} className="nova-senha" placeholder="Nova senha" required/>
                    </div>
                    <label className="autera-senha-subtitulo">Digite novamente:</label>

                    <div className="password-input">
                        <button
                        onClick={HideOrShowPasswordTwo}
                            className={passwordTypeTwo ?"":"active"}
                        >
                            {
                                passwordTypeTwo ?
                                <img src={iconEyeSeeOff} alt="Olho" />
                                :
                                <img src={iconEyeSee} alt="Olho" />
                            }
                        </button>
                        <input type={passwordTypeTwo ? "password" : "text"}  name="repassword" onChange={myChangeHandler} className="nova-senha" placeholder="Digite novamente senha" required/>
                    </div>
                    {state.repassword && state.password !== state.repassword ? (<p className="senha-incopativel">Senhas diferentes</p>) : ('')}
                    {load ?
                    (<p className="carregando">Enviando...</p>) :
                    ('')
                    }
                    <button type="submit"  className="btnEntrar btnGreen" disabled={(state.password !== state.repassword || state.length === 0 || state.password === '' || state.repassword === '' || state.password.length <=7 ||state.repassword.length <=7 )}>Alterar senha</button>
                    <div  className="criar-conta" >
                        <button type="button" onClick={logout} className="voltar-login"> Voltar para o login</button>
                    </div>
                </form>
                <span className="copyright">© {new Date().getFullYear()} People View</span>
            </div>
        </>
    );
}
