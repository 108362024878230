import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from "react-bootstrap";
import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import Carregando from '../../../../../../assets/images/carregando.gif';
import iconVerMais from '../../../../../../assets/images/more.svg';
import Carregamento from '../../../../../../assets/images/carregando.gif';
import iconUpload from '../../../../../../assets/images/docs-upload.svg';
import Close from '../../../../../../assets/images/close.svg';
import api from '../../../../../../services/api';
import { dateMask } from '../../../../../../services/mask';
import { toast } from 'react-toastify';


const ColProfiDcocTermContracts = ({ id, path }) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFileForm, setLoadFileForm ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadType, setLoadType ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDate, setLoadDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadArchive, setLoadArchive ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ periodicexam, setPeriodicExam ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ periodicExamtUpdated, setPeriodicExamtUpdated ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredPeriodicExam, setRegisteredPeriodicExam ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

      if(id && id)  {loadperiodicexam(id);}

    }, [id]);


    async function loadperiodicexam(id) {

        setLoadPage(true)
        await api.get(`/collaborator-periodic-exam/${id}`)
        .then(response => {

            setLoadPage(false);
            setLoad(false);
            setFileNames(false);
            setFilterPeriodicExam([]);

            if(response.data[0] !== undefined) {

                setRegisteredPeriodicExam(response.data);

            }
            else {

                setRegisteredPeriodicExam([])

            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPage(false)
        })
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ periodicExam, setFilterPeriodicExam ] = useState([]);



    async function loadfilterperiodicexam(event) {
        const name = event.target.value;

        setLoad(true)
        await api.get(`/collaborator-periodic-exam?id=${id}&name=${name}`)
        .then(response => {

            setLoad(false)
            setFilterPeriodicExam(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

     }


        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [visible, setVisible] = useState(false);


        function AddAndEditPeriodicExamVisible(e) {

            if(e !== undefined) {
                let periodicexam = e;
                setPeriodicExamtUpdated({
                    ...periodicexam
                })
            } else {
                setPeriodicExam([]);
                setPeriodicExamtUpdated([]);
            }

            setFileNames(false);
            setLoadFileForm(false);
            setLoadType(false);
            setLoadDate(false);
            setVisible(!visible);
        }

        function AddTermOrContractVisible() {
            setPeriodicExam([])
            setPeriodicExamtUpdated([])
            setFileNames(false);
            setLoadFileForm(false);
            setLoadType(false);
            setLoadDate(false);
            setVisible(!visible);
        }

    //////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////
    /// => Aqui você seta as informações via MyChangeHandle para dentro de schooling
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;

        //type
        if (nam === "type") {

            setLoadType(false)
            setPeriodicExam({ ...periodicexam, collaborator, [nam]: val, path: path })

        }
        //date
        else
        if (nam === "date") {

            setLoadDate(false)
            let date = dateMask(val)
            setPeriodicExam({ ...periodicexam, collaborator, [nam]: date })

        }
        else {
            setPeriodicExam({ ...periodicexam, collaborator, [nam]: val })

        }

    };


    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState([]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setLoadArchive(true)
            setFileNames(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setLoadFileForm(false)
            setPeriodicExam({ ...periodicexam, file })
            setLoadArchive(false)
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFiles() {
        setFileNames();
        let file = undefined
        setLoadFileForm(true)
        setPeriodicExam({ ...periodicexam, file })
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if(periodicExamtUpdated._id !== undefined) {

            if(periodicexam.length === 0) {

                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });

            } else {

                if(
                    periodicexam.type === "" ||
                    periodicexam.date === ""
                ) {

                    swal({ icon: "error", title: "Erro!", text: "Nenhum campo pode ser alterado em branco!" });

                } else {

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if (periodicexam.type !== undefined) {
                        formData.append('type', periodicexam.type);
                    }
                    if (periodicexam.file !== undefined) {
                        formData.append('file', periodicexam.file);
                    }
                    if (periodicexam.date !== undefined) {
                        formData.append('date', periodicexam.date);
                    }
                    formData.append('path', path);

                    setLoad(true)
                    await api.put(`/collaborator-periodic-exam/${periodicExamtUpdated._id}`, formData, config)
                    .then(response => {

                        setPeriodicExam([]);
                        setPeriodicExamtUpdated([]);
                        setVisible(!visible);
                        loadperiodicexam(id);
                        swal({ icon: "success", title: "Sucesso!", text: "Exame períodico atualizado com sucesso!" });

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoad(false)
                        // swal({ icon: "error", title: "Erro!", text: "Não foi possível atualizar o exame períodico!" });

                    });

                }

            }

        } else {

            if (
                periodicexam.type === undefined ||
                periodicexam.date === undefined ||
                periodicexam.file === undefined
            ) {

                if (periodicexam.type !== undefined) {
                    setLoadType(false)
                } else {
                    setLoadType(true)
                }

                if (periodicexam.date !== undefined) {
                    setLoadDate(false)
                } else {
                    setLoadDate(true)
                }

                if (periodicexam.file !== undefined) {
                    setLoadFileForm(false)
                } else {
                    setLoadFileForm(true)
                }
                return

            } else {

                if (
                    periodicexam.type !== undefined &&
                    periodicexam.file !== undefined &&
                    periodicexam.date !== undefined
                ) {
                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    formData.append('collaborator', id);
                    formData.append('type', periodicexam.type);
                    formData.append('date', periodicexam.date);
                    formData.append('file', periodicexam.file);
                    formData.append('path', path);

                    setLoad(true)
                    await api.post("/collaborator-periodic-exam/", formData, config)
                    .then(response => {

                        setPeriodicExam([]);
                        setPeriodicExamtUpdated([]);
                        setVisible(!visible);
                        loadperiodicexam(id);

                        swal({ icon: "success", title: "Sucesso!", text: "Exame períodico criado com sucesso!" });
                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoad(false)
                    });
                }

            }

        }


    }

    async function deletePeriodicExam(periodicexam) {

        swal({
            title: "Atenção",
            text: "Deseja excluir este exame?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-periodic-exam/${periodicexam}?path=${path}`)
                .then(() => {

                    loadperiodicexam(id);
                    swal({ icon: "success", title: "Sucesso!", text: "Exame removido com sucesso." });

                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o exame, tente novamente mais tarde." });
                });
            }
        });
    }

    return (
        <>

            <div className="box perfil-exame-periotica">
                    <h3>Exame períodico</h3>
                    <div className="buscaAdd">
                        <form action="">
                            <input type="text" onChange={(event) => loadfilterperiodicexam(event)} placeholder="Procure pela data e tipo..." className="busca"/>
                        </form>


                        {
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                <button className="btnGreen more" onClick={() => AddTermOrContractVisible()}>Adicionar</button>
                        }

                    </div>
                        <table>
                        <thead>
                            <tr className="tabelaTR">
                                <th className="inten-1">
                                    Tipo
                                </th>
                                <th className="inten-2">Data</th>
                                <th className="inten-3"></th>
                                <th className="inten-4"></th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        loadPage ?
                            (
                                <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                                </>
                            ):
                            periodicExam[0] !== undefined ? periodicExam
                            .map((periodicexam, index) => (
                            <>
                            <tr className="tabelaTR">

                                <td className="inten-1"   onClick={() => AddAndEditPeriodicExamVisible(periodicexam)} >
                                        <div className="motivo">
                                            <strong>{periodicexam.type}</strong>
                                        </div>
                                </td>
                                <td className="inten-2"  onClick={() => AddAndEditPeriodicExamVisible(periodicexam)} >
                                    {periodicexam.date}
                                </td>
                                <td className="inten-3">

                                    <a href={periodicexam.location} target="’_blank’">
                                        <img alt="icone de documentos" src={iconUpload} />
                                        <span>{periodicexam.originalname}</span>

                                    </a>
                                </td>
                                <td className="inten-5">



                                {
                                    localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                        ""
                                        :
                                        <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                            <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                    <button   onClick={() => AddAndEditPeriodicExamVisible(periodicexam)} name="_id" value="" type="submit">
                                                        Editar
                                                    </button>

                                                    <div>
                                                        <button  onClick={() => deletePeriodicExam(periodicexam._id)} name="_id" value="" type="submit" className="red">
                                                        Excluir
                                                        </button>
                                                    </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                }
                                </td>
                            </tr>




                            </>
                            )) :
                            registeredPeriodicExam.length === 0 ?
                            (<div className="nenhum-cadastrado-perfil"><p>Nenhum exame períodico cadastrado.</p></div>):
                            registeredPeriodicExam !== undefined ? registeredPeriodicExam
                            .map((periodicexam, index) => (
                            <>
                            <tr className="tabelaTR">

                                <td className="inten-1" onClick={() => AddAndEditPeriodicExamVisible(periodicexam)}>
                                        <div className="motivo">
                                            <strong>{periodicexam.type}</strong>
                                        </div>
                                </td>
                                <td className="inten-2" onClick={() => AddAndEditPeriodicExamVisible(periodicexam)}>
                                    {periodicexam.date}
                                </td>
                                <td className="inten-3">
                                    <a href={periodicexam.location} target="’_blank’">
                                        <img alt="icone de documentos" src={iconUpload} />
                                        <span>{periodicexam.originalname}</span>
                                    </a>
                                </td>
                                <td className="inten-5">
                                {
                                    localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                        ""
                                        :
                                        <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                            <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                    <button   onClick={() => AddAndEditPeriodicExamVisible(periodicexam)} name="_id" value="" type="submit">
                                                        Editar
                                                    </button>

                                                    <div>
                                                        <button  onClick={() => deletePeriodicExam(periodicexam._id)} name="_id" value="" type="submit" className="red">
                                                        Excluir
                                                        </button>
                                                    </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </td>
                            </tr>
                            </>
                            )) : (
                                <>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>

                <Modal show={visible} className="popup-edit-perfil" onHide={AddAndEditPeriodicExamVisible}>
                <div>
                    <div className="titulo">
                        <h4>Exame períodico <p className="btn-fechar" onClick={AddAndEditPeriodicExamVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Tipo</p>
                            </div>
                            <div className="intemB">
                                {
                                    periodicexam.type !== undefined ?
                                    <input type="text" name="type" onChange={myChangeHandler} defaultValue={periodicexam && periodicexam.type ? periodicexam.type : ''}  className={loadType ? 'inputDois inputErro' : 'inputDois'}/>
                                    :
                                    periodicExamtUpdated && periodicExamtUpdated.type !== undefined ?
                                    <input type="text" name="type" onChange={myChangeHandler} defaultValue={periodicExamtUpdated && periodicExamtUpdated.type ? periodicExamtUpdated.type : ''}  className={loadType ? 'inputDois inputErro' : 'inputDois'}/>
                                    :
                                    <input type="text" name="type" onChange={myChangeHandler}  placeholder="Tipo de exame períodico" className={loadType ? 'inputDois inputErro' : 'inputDois'}/>
                                    }
                                <p className="inputUm textError">{loadType ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data</p>
                            </div>
                            <div className="intemB">

                            {
                                (periodicexam && periodicexam.date !== undefined) || periodicexam.date === "" ?
                                <input type="text" name="date" autocomplete="off" maxLength="11"  value={(periodicexam.date !== undefined) ? periodicexam.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadDate ? 'inputUm inputErro' : 'inputUm'} />
                                :
                                (periodicExamtUpdated && periodicExamtUpdated.date !== undefined) || periodicExamtUpdated.date === "" ?
                                <input type="text" name="date" autocomplete="off" maxLength="11"  value={(periodicExamtUpdated.date !== undefined) ? periodicExamtUpdated.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadDate ? 'inputUm inputErro' : 'inputUm'} />
                                :
                                registeredPeriodicExam && registeredPeriodicExam.date ?
                                <input type="text" name="date" autocomplete="off"  maxLength="11"  defaultValue={(registeredPeriodicExam.date !== undefined) ? registeredPeriodicExam.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className="inputUm"/>
                                :
                                <input type="text" name="date" autocomplete="off"  maxLength="11"  value={(periodicexam.date !== undefined) ? periodicexam.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadDate ? 'inputUm inputErro' : 'inputUm'} />
                                }
                                <p className="inputUm textError">{loadDate ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>

                            {
                            periodicExamtUpdated.originalname !== undefined ?
                            (
                                <li>
                                    <div className="intemA">
                                        <p>Documento</p>
                                    </div>
                                    <div className="intemB">
                                        <p className="documento-anexado">
                                            <a href={periodicExamtUpdated.location} target="’_blank’">
                                                {periodicExamtUpdated.originalname}
                                            </a>
                                        </p>

                                    </div>
                                </li>
                            )
                            :
                            periodicexam.originalname !== undefined ?
                            (
                                <li>
                                    <div className="intemA">
                                        <p>Documento</p>
                                    </div>
                                    <div className="intemB">
                                        <p className="documento-anexado">
                                            <a href={periodicexam.location} target="’_blank’">
                                                {periodicexam.originalname}
                                            </a>
                                        </p>

                                    </div>
                                </li>
                            )
                            :
                            ''
                            }
                            <li>
                                <div className="intemA">
                                    <p>Anexar</p>
                                </div>
                                <div  className={loadFileForm ? 'imgDocLabel-error intemB' : 'intemB'}>
                                    {
                                    periodicExamtUpdated.originalname !== undefined ?
                                    (
                                        <Dropzone  className="" onDrop={handleDrop} >
                                                {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                                    <input type="file" name="file" {...getInputProps()} />
                                                    <label className="imgDocLabel inputOnze"><span></span>Atualize o arquivo</label>
                                                </div>
                                                )}
                                            </Dropzone>
                                    )
                                    :
                                    periodicexam.originalname !== undefined ?
                                    (
                                        <Dropzone  className="" onDrop={handleDrop} >
                                                {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                                    <input type="file" name="file" {...getInputProps()} />
                                                    <label className="imgDocLabel inputOnze"><span></span>Atualize o arquivo</label>
                                                </div>
                                                )}
                                            </Dropzone>
                                    )
                                    :
                                    (
                                        <Dropzone  className="" onDrop={handleDrop} >
                                                {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone inputOnze" })}>
                                                    <input type="file" name="file" {...getInputProps()} />
                                                    <label className="imgDocLabel inputOnze"><span></span>Anexe um arquivo</label>
                                                </div>
                                                )}
                                            </Dropzone>
                                    )}
                                </div>
                            </li>

                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                    <p className="textError">{loadFileForm ? 'É necessário escolher anexar arquivo' : ''}</p></div>
                            </li>

                            {fileNames !== false && fileNames ?
                            fileNames.map((filename, index) => (
                            <li key={index}>
                                <div className="arquivoAnexado">

                                    {/* <h5>Enviados</h5> */}
                                    <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        <a href={fileNames.location} target="’_blank’">
                                        {filename}
                                        </a>
                                    </p>
                                        <p onClick={deleteFiles} className="remover">Remover anexo</p>
                                    </div>

                                </div>
                            </li>

                            )) : loadArchive ?
                            (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                            ('')}


                                {
                                    periodicExamtUpdated && periodicExamtUpdated !== undefined && periodicExamtUpdated._id  ?

                                    (
                                        <li>
                                            <div className="intemA">
                                            </div>
                                            <div className="intemB">
                                                <form onSubmit={handleSubmit}   className="btn-salvar">
                                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar exame períodico</button>
                                                </form>
                                            </div>
                                        </li>
                                    ) :
                                    (
                                        <li>
                                            <div className="intemA">
                                            </div>

                                            <div className="intemB">
                                                <form onSubmit={handleSubmit}   className="btn-salvar">
                                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar exame períodico</button>
                                                </form>
                                            </div>
                                        </li>
                                    )
                                }
                    </ul>




                </div>
            </Modal>
        </>
    );
}
export default ColProfiDcocTermContracts;
