import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layouts/default';
import { decodeToken } from '../../services/auth';
import Header from '../../components/Dashboard/header';
import HeaderSection from '../../components/Dashboard/HeaderSection';
import CalendarNovo from '../../components/Dashboard/calendar-new';
import FeedNovo from '../../components/Dashboard/feed';
import { toast } from 'react-toastify';

import api from '../../services/api';

import './styles.scss';

export default function Dashboard(props) {


    const { user } = decodeToken();


      ///////////////////////////////////////////////////////////////////////////
     ////////////////////////          FEED           //////////////////////////
    ///////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPageFeed ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ feed, setFeed ] = useState([]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadfeed();

    }, []);

    async function loadfeed() {
        setLoadPageFeed(true)
        await api.get('/feed/')
        .then(response => {

            setLoadPageFeed(false)
            setFeed(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function showMoreFeed() {

        let limite = 4;

        const limitStorage = localStorage.getItem('@peopleview/limitFeed');

        let limit = (parseInt(limitStorage) + parseInt(limite))

        localStorage.setItem('@peopleview/limitFeed', limit);
        await api.get(`feed/?showmore=true&limite=${limit}`)
        .then(response => {
                setLoadPageFeed(false)
            if(response.data[0] !== undefined) {

                setFeed(response.data);

            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    //tudo
    const [ all, setAll ] = useState(false);
    // candidate
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidate, setCandidate ] = useState(false);
    //feedback
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ feedback, setFeedback ] = useState(false);
    // collaboratorabsences
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorabsences, setCollaboratorabsences ] = useState(false);
    //adverts
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ adverts, setAdverts ] = useState(false);
    async function filterFeed(event) {
        let value = event.target.value;
        let check = event.target.checked;
         /////////////////////////////////////////////////
        ////////////   ==> TUDO
        if(value === 'all' && check === true) {
            //alert('true TUDO')

            setAll(true)
            setCandidate(true)
            setFeedback(true)
            setCollaboratorabsences(true)
            setAdverts(true)

            setLoadPageFeed(true)
            await api.get('/feed/')
            .then(response => {

                setLoadPageFeed(false)
                if(response.data[0] !== undefined) {

                    setFeed(response.data);

                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });


        }
        else
        if(value === 'all' && check === false) {
            //alert('false TUDO')

            setAll(false)
            setCandidate(false)
            setFeedback(false)
            setCollaboratorabsences(false)
            setAdverts(false)

        }
         ////////////   ==> FINAL TUDO
        /////////////////////////////////////////////////


         /////////////////////////////////////////////////
        ////////////   ==> CANDIDATE
        if(value === 'candidate' && check === true) {
            //alert('true candidate')

            setAll(false)

            setCandidate(true)

            if(feedback === false && collaboratorabsences === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === true && collaboratorabsences === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === true && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === false && collaboratorabsences === false) {

                setFeedback(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(feedback === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === false && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }



        }
        else
        if(value === 'candidate' && check === false) {

            setAll(false)

            setCandidate(false)

            if(feedback === false && collaboratorabsences === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === true && collaboratorabsences === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === true && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === false && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(feedback === false && collaboratorabsences === true) {

                setFeedback(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }



        }
         ////////////   => FINAL CANDIDATE
        /////////////////////////////////////////////////


         /////////////////////////////////////////////////
        ////////////   ==> FEEDBACK
        if(value === 'feedback' && check === true) {

            setAll(false)

            setFeedback(true)

            if(candidate === true && collaboratorabsences === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && adverts === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && collaboratorabsences === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && collaboratorabsences === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && collaboratorabsences === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && collaboratorabsences === false) {


                setCandidate(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === true && collaboratorabsences === true) {


                setFeedback(true)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === false && collaboratorabsences === true) {


                setFeedback(true)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }


        }
        else
        if(value === 'feedback' && check === false) {

            setFeedback(false)
            setAll(false)

            if(candidate === true && collaboratorabsences === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && adverts === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && collaboratorabsences === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && collaboratorabsences === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryeventscalendar=collaboratoreventscalendar')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && collaboratorabsences === true) {

                setFeedback(false)

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && collaboratorabsences === true) {

                setCandidate(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === true && collaboratorabsences === false) {

                setFeedback(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }


        }
         ////////////   => FINAL FEEDBACK
        /////////////////////////////////////////////////



         /////////////////////////////////////////////////
        ////////////   ==> COLLABORATOR ABSENCES
        if(value === 'collaboratorabsences' && check === true) {

            setAll(false)
            setCollaboratorabsences(true)

            if(candidate === true && feedback === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === false) {


                setCandidate(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === true && feedback === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === false && feedback === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }


        }
        else
        if(value === 'collaboratorabsences' && check === false) {

            setAll(false)
            setCollaboratorabsences(false)

            if(candidate === true && feedback === false && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true && adverts === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback&categoryeventscalendar=collaboratoreventscalendar')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === true) {

                setCandidate(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === true && feedback === false) {

                setCandidate(true)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }


        }
         ////////////   => FINAL COLLABORATOR ABSENCES
        /////////////////////////////////////////////////


        //setAdverts
         /////////////////////////////////////////////////
        ////////////   ==> ADVERTS
        if(value === 'adverts' && check === true) {

            setAll(false)
            setAdverts(true)

            if(candidate === true && feedback === false && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback&categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === false && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categoryadverts=adverts')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback&categoryeventscalendar=collaboratoreventscalendar')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === false && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryeventscalendar=collaboratoreventscalendar')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === false) {


                setCandidate(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === true && feedback === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === false && feedback === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }


        }
        else
        if(value === 'adverts' && check === false) {

            setAll(false)
            setAdverts(false)


            if(candidate === true && feedback === false && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === false && collaboratorabsences === false) {

                setLoadPageFeed(true)
                await api.get('/feed/')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true && collaboratorabsences === true) {

                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categorycollaboratorabsences=collaboratorabsences&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === true && feedback === true) {



                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate&categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            else
            if(candidate === false && feedback === true) {

                setCandidate(false)
                setLoadPageFeed(true)
                await api.get('/feed/?categoryfeedback=feedback')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            else
            if(candidate === true && feedback === false) {

                setCandidate(true)
                setLoadPageFeed(true)
                await api.get('/feed/?categorycandidate=candidate')
                .then(response => {

                    setLoadPageFeed(false)
                    if(response.data[0] !== undefined) {

                        setFeed(response.data);

                    }
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }


        }
         ////////////   => FINAL ADVERTS
        /////////////////////////////////////////////////

    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                    user={user}
                />
                <HeaderSection />
                <div className="page-dashboard">
                    <div className="contFeedCalendar">
                        <div className="feed">
                            <FeedNovo
                                feed={feed}
                                filterFeed={filterFeed}
                                all={all}
                                candidate={candidate}
                                feedback={feedback}
                                collaboratorabsences={collaboratorabsences}
                                adverts={adverts}
                                showMoreFeed={showMoreFeed}
                            />
                        </div>
                        <div className="calendario">
                            <CalendarNovo/>
                        </div>
                    </div>

                    {/* <div className="contGrafico">
                        <GafricoNovo/>
                        {/* <Graphics /> */}
                    {/* </div> */}
                </div>
            </Layout>
        </>
    );
}
