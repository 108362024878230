import React, { useState, useEffect } from 'react';

// import Carregando from '../../../../assets/images/carregando.gif';
// import Dropzone from "react-dropzone";
// import InputMask from 'react-input-mask';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import api from '../../../../services/api';
// import bankData from '../../../../services/bankData.json'

const Banco = ({
    id,
    token,
    onCollaboratorInputChange,
    collaborator,
    collaboratorUpdate,
    loadbankCode     ,
    loadbankAgency   ,
    loadbankAccount  ,
    loadaccountDigit ,
    loadAccountType  ,
    loadpix          ,
    focusbankCode    ,
    focusbankAgency  ,
    focusbankAccount ,
    focusaccountDigit,
    focusAccountType ,
    openSelAccountType,
    selectAccountTypeOpen,
    myChangeHandlerAccountType,
    abrirResultBank,
    mySearchBank,
    abrirResultado,
    myChangeHandlerToSaveBank,
    focuspix,
    path
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id && id){
        async function loadfilessaved() {
            setLoad(true)
            //console.log(id)
            await api.get(`/collaborator-file-new/?id=${id}&category=docDB`)
            .then(response => {
                setLoad(false)
                //console.log(response.data[0])
                if(response.data[0] !== undefined) {
                    setFilesSaved(response.data);
                }
            }).catch(error => {
                setLoad(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();}
    }, [id]);

    // eslint-disable-next-line no-unused-vars
    async function handleDrop(acceptedFiles) {

        setFileNames(acceptedFiles.map(file => file.name));

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if (acceptedFiles === undefined) {
            toast.info('É necessário selecionar uma arquivo para o envio!');
            return
        }
        formData.append('file', acceptedFiles[0]);
        formData.append('name', acceptedFiles[0].name);
        formData.append('category', 'docDB');
        formData.append('collaborator', id);
        formData.append('token', token);
        formData.append('path', path);
        setLoad(true)
        await api.post("/collaborator-file-token", formData, config)
        .then(response => {
            async function loadfilessaved() {
                await api.get(`/collaborator-file-new/?id=${id}&category=docDB`)
                .then(response => {
                    setLoad(false)
                    setFilesSaved(response.data);
                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                })
            }
            loadfilessaved();
            swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
            //setTokenError(error.response.data);
            console.log(error.response.data)
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ apiBank, setApiBank] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadapibank() {
            await api.get(`https://brasilapi.com.br/api/banks/v1`)
            .then(response => {
                const itensCopyBanck = Array.from(response.data);
                itensCopyBanck.sort(function (a, b) {
                    if (a.name > b.name) {
                    return 1;
                    }
                    if (a.name < b.name) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                setApiBank(itensCopyBanck);
            }).catch(error=>{})
        }
        loadapibank();
    }, []);

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA DEPARTAMENTO //////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBank, setIsActiveSelectBank] = useState(false);
    async function SelectBank() { setIsActiveSelectBank(!isActiveSelectBank) }
    // console.log(apiBank.length)

    return (
        <>
            <div className="box-form banco">
                <h4 className="titulo-box">Dados bancários</h4>
                <p className="textCampo textoUm">Banco *</p>
                <div className='inputUm select-busca'>
                <input type="text"
                    autocomplete="autocompleteoff"
                    ref={focusbankCode}
                    name="bankName"
                    className={loadbankCode ? 'inputUm inputErro busca' : 'inputUm busca'}
                    value={
                        collaboratorUpdate && collaboratorUpdate.bankName !== undefined ?
                        collaboratorUpdate.bankName
                        :
                        collaborator.bankName !== undefined ?
                        collaborator.bankName
                        :
                        ''
                    }
                        placeholder='Banco'
                        onChange={mySearchBank}
                        onClick={abrirResultado}
                        id="myBank"
                    />
                     {abrirResultBank ?

                                    <div className='resultado-busca busca-bank' id='resultadoBusca'>
                                        {
                                            apiBank.length !== 0 ? apiBank
                                            .map((bank, index) => (
                                                <button
                                                onClick={() => myChangeHandlerToSaveBank(bank.name) }
                                                key={index} >
                                                    <p>
                                                        {
                                                            bank.code === null ? ""
                                                            :
                                                            bank.code
                                                        }
                                                        {
                                                            bank.code === null ? ""
                                                            :
                                                            "-"
                                                        }
                                                        {
                                                            bank.name === null ?
                                                            ""
                                                            :
                                                            bank.name
                                                        }
                                                    </p>
                                                </button>
                                            )): ('')
                                        }

                                    </div>

                                 :
                                ""
                            }

                </div>

                {
                loadbankCode ?
                <p className="textError inputUm">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoDois">Agência *</p>
                <input
                    ref={focusbankAgency}
                    type="text"
                    name="bankAgency"
                    value={
                        (collaboratorUpdate && collaboratorUpdate.bankAgency)
                        ||
                        (collaboratorUpdate.bankAgency === "")
                        ?
                        collaboratorUpdate.bankAgency
                        :
                        (collaborator && collaborator.bankAgency)
                        ||
                        (collaborator.bankAgency === "")
                        ?
                        collaborator.bankAgency
                        :
                        ''
                    }
                    placeholder="Agência"
                    onChange={onCollaboratorInputChange}
                    className={loadbankAgency ? 'inputDois inputErro' : 'inputDois'}
                />
                {
                loadbankAgency ?
                <p className="textError inputDois">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoTreis">Conta *</p>
                <div className="inputTreis">
                    <input
                        ref={focusbankAccount}
                        type="text"
                        name="bankAccount"
                        value={
                            (collaboratorUpdate && collaboratorUpdate.bankAccount)
                            ||
                            (collaboratorUpdate.bankAccount === "")
                            ?
                            collaboratorUpdate.bankAccount
                            :
                            (collaborator && collaborator.bankAccount)
                            ||
                            (collaborator.bankAccount === "")
                            ?
                            collaborator.bankAccount
                            :
                            ''
                        }
                        onChange={onCollaboratorInputChange}
                        placeholder="Conta"
                        className={loadbankAccount ? 'conta inputErro' : 'conta'}
                    />
                    <input
                        ref={focusaccountDigit}
                        type="text"
                        name="accountDigit"
                        value={
                            (collaboratorUpdate && collaboratorUpdate.accountDigit)
                            ||
                            (collaboratorUpdate.accountDigit === "")
                            ?
                            collaboratorUpdate.accountDigit
                            :
                            (collaborator && collaborator.accountDigit)
                            ||
                            (collaborator.accountDigit === "")
                            ?
                            collaborator.accountDigit
                            :
                            ''
                        }
                        placeholder="Dígito"
                        onChange={onCollaboratorInputChange}
                        className={loadaccountDigit ? 'digito inputErro' : 'digito'}
                    />
                </div>


                {
                loadbankAccount ?
                <p className="textError inputTreis">O campo é obrigatório.</p>
                :
                ''
                }
                {
                loadaccountDigit ?
                <p className="textError inputTreis">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoQuatro">Tipo de conta *</p>
                <div className="inputQuatro">

                <div className="select-busca-btn">
                    <button 
                        className={loadAccountType ? "menu-button inputErro" : "menu-button"}
                        onBlur={ () => (setIsActiveSelectBank(false)) }
                        onClick={ SelectBank }
                        ref={focusAccountType}
                    >
                        <span>
                            {

                                collaboratorUpdate && collaboratorUpdate.AccountType !==  undefined ?
                                collaboratorUpdate.AccountType ? ( collaboratorUpdate.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                collaboratorUpdate.AccountType &&
                                collaboratorUpdate.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                collaboratorUpdate.AccountType &&
                                collaboratorUpdate.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                ) : "selecione"
                                :
                                collaborator.AccountType ? ( collaborator.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                collaborator.AccountType &&
                                collaborator.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                collaborator.AccountType &&
                                collaborator.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                ) : "selecione"

                            }

                        </span>
                    
                    </button>
                                <nav className={`menu ${isActiveSelectBank ? "ativo": ""}`} >

                                    <Scrollbars className='listDropDown'  style={{  height: 95 }}>
                                        <ul>
                                            {   
                                                [
                                                    'conta-Corrente',
                                                    'conta-poupanca',
                                                    'conta-salario'
                                                ]
                                                .map((backs, index) => (
                                                    <li>
                                                        <button 
                                                        value={backs}
                                                        name="AccountType"
                                                        onClick={onCollaboratorInputChange}
                                                        >{
                                                            backs === 'conta-Corrente' ? 'Conta Corrente' :"" ||
                                                            backs === 'conta-poupanca' ? 'Conta Poupança' :"" ||
                                                            backs === 'conta-salario' ? 'Conta Salário' : ''
                                                        
                                                        }</button>
                                                    </li>
                                                ))}
                                        </ul>
                                        
                                    </Scrollbars>
                                </nav>
                            </div>


                  
                </div>





                {
                loadAccountType ?
                <p className="textError inputQuatro">O campo é obrigatório.</p>
                :
                ''
                }
                <p className="textCampo textoCinco">Pix *</p>
                <div className="inputCinco">
                {
                collaboratorUpdate && collaboratorUpdate.pix !== undefined ?
                <input ref={focuspix} type="text" name="pix" value={collaboratorUpdate.pix ? collaboratorUpdate.pix : ''} placeholder="pix" onChange={onCollaboratorInputChange} className={loadpix ? 'pix inputErro' : 'pix'}/>:
                collaborator && collaborator.pix !== undefined ?
                <input ref={focuspix} type="text" name="pix" value={collaborator.pix ? collaborator.pix : ''} onChange={onCollaboratorInputChange} className={loadpix ? 'pix inputErro' : 'pix'}/>:
                <input ref={focuspix} type="text" name="pix" value={collaboratorUpdate.pix ? collaboratorUpdate.pix : ''} onChange={onCollaboratorInputChange} placeholder="pix" className={loadpix ? 'pix inputErro' : 'pix'}/>
                }
                </div>
                {
                loadpix ?
                <p className="textError inputCinco">O campo é obrigatório.</p>
                :
                ''
                }

            </div>
        </>
        );

}
export default Banco;
