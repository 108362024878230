import React,{
    useEffect,
    useState
} from "react";
// import Logo from '../../assets/images/logo-jobs.svg'
import Vacancies from '../../components/WebsiteVacancies/vacancies'
import Baseboard from '../../components/WebsiteVacancies/Baseboard'
// import Carregamento from '../../assets/images/carregando.gif';
import api from '../../services/api'
import SkeltonHomem from '../../components/WebsiteVacancies/skeleton/skeletonHome'
import "./styles.scss";
// import logo from '../../assets/images/logo.svg';
export default function WebsiteVacancies(props) {

    const id = props.match.params.id;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadPage(id)
    }, [id]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPageC, setLoadPageC] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPagenNaoEncontrada, setLoadPagenNaoEncontrada] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ inforVacancyPage, setInforVacancyPage] = useState([]);

    async function loadPage(id) {
        setLoadPageC(true)

        await api.get(`/vacancie-page-appearance/${id}`)
        .then(response => {
            if(response.data === null){
                setLoadPagenNaoEncontrada(true)
            }else {
                setInforVacancyPage(response.data[0]);
            }
            setLoadPageC(false)
        }).catch(error => {
            setLoadPageC(false)
            setLoadPagenNaoEncontrada(true)
        })

    }

    const filesPage =  inforVacancyPage.vacancyPageAppearanceFileSearch;
    const aparencePage =  inforVacancyPage.vacancyPageAppearanceSearch;
    const PrivacyPolicyPage =  inforVacancyPage.vacancyPageAppearancePrivacyPolicySearch;
    const statusPageConfig =  aparencePage?.vacancyPageAppearanceConfig[0].status;


    return(
        <>
        {
            loadPageC ?
            
              <SkeltonHomem/>

            :
            loadPagenNaoEncontrada ?
            <div className="box-error pagina-nao-encontrada">
                <h1>404</h1>
                <p>Não encontramos esta página.</p>
                <span>© 2021 People View</span>
            </div>
            :
            statusPageConfig && statusPageConfig === "inactive" ?
            <div className="box-error pagina-nao-encontrada">
                <h1>Página em contrução</h1>
                {/* <p>Não encontramos esta página.</p> */}
                <span>© 2021 People View</span>
            </div>

        :
        <div className="page-website-vacancies"
        style={{ background: aparencePage?.colorBackground ? aparencePage.colorBackground : "#ff" }}
        >

            {
               aparencePage?.topHeader === true ?
               

                    aparencePage.titleTopHeader !== undefined ||
                    filesPage.category === "backgroundTopHeader"  ||
                    filesPage.category === "logoTopHeader" ?


                        <div className="banner-top"
                            style={{ background: `url(${

                                    filesPage
                                    .filter(filter =>
                                        filter.category === "backgroundTopHeader"
                                    ).map((filesPagef) => (
                                        filesPagef.location
                                    ))
                            })` }}
                    >
                        <div className="center">
                            <div className="text">
                            {
                                    aparencePage && aparencePage.titleTopHeader !== undefined ?
                                        <h2
                                            style={{ color: aparencePage?.colorTextTopHeader ? aparencePage.colorTextTopHeader : "#000" }}
                                        >
                                        {aparencePage && aparencePage?.titleTopHeader !== undefined ? aparencePage.titleTopHeader : ""}
                                        </h2>
                                    :""
                                }
                                </div>

                            <div className="logo">
                                {
                                        filesPage
                                        .filter(filter =>
                                            filter.category === "logoTopHeader"
                                        ).map((filesPagef, index) => (
                                            <img src={filesPagef.location} alt="Nome da empresa" />
                                        ))
                                }

                            </div>
                        </div>
                    </div>
                    
                    
                    :""

               
               
               :""
            }

            {
               aparencePage?.aboutCompany === true ?
                    aparencePage.titleAboutCompany !== undefined ||
                    aparencePage.textAboutCompany !== undefined ?
                        <div className="about">

                            {
                                aparencePage.titleAboutCompany ?
                                <h5 className="title-box"
                                    style={{ color: aparencePage?.colorTitleAboutCompany ? aparencePage.colorTitleAboutCompany : "#000" }}
                                >
                                    {aparencePage && aparencePage?.titleAboutCompany !== undefined ? aparencePage.titleAboutCompany : ""}
                                </h5>
                                :""
                            }
                            {
                                aparencePage.textAboutCompany ?
                                    <div className="bloco-text">
                                        {
                                            aparencePage.textAboutCompany.split('\n').map(str =>
                                                <p style={{ color: aparencePage?.colorTextAboutCompany ? aparencePage.colorTextAboutCompany : "#000" }} >
                                                    {str}
                                                </p>
                                            )
                                        }
                                    </div>
                                :""
                            }
                        </div>
                    :""

                :""
            }


            <Vacancies
                id={id !== undefined ? id : ""}
                companyId={aparencePage?.companyId}
            />



            {
               aparencePage?.videoCompany === true ?
                <div className="video">
                    <div className="center">
                        {
                            aparencePage?.titleVideoCompany  ?
                            <h5 className="title-box"
                            style={{ color: aparencePage?.colorTitleVideoCompany ? aparencePage.colorTitleVideoCompany : "#000" }}
                            >{aparencePage?.titleVideoCompany }</h5>
                            :""
                        }

                        {
                            aparencePage?.linkVideoCompany ?

                                <div className="video-container">
                                    <iframe
                                        width="100%"
                                        height="450"
                                        className="video-iframe"
                                        src={aparencePage?.linkVideoCompany}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allowFullScreen
                                    >
                                    </iframe>
                                </div>
                            :""
                        }


                    </div>
                </div>
                :""
            }



            {
               aparencePage?.footer === true ?
                    <Baseboard
                        aparencePage={aparencePage}
                        filesPage={filesPage?filesPage : ""}
                        PrivacyPolicyPage={PrivacyPolicyPage?PrivacyPolicyPage[0] : ""}
                    />
                :<div className="baseboard-none"></div>
            }
        </div>

    }

        </>
    )
}
