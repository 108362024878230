import React, { useState, useEffect } from 'react';
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Carregando from '../../../../../../assets/images/carregando.gif';
import Close from '../../../../../../assets/images/close.svg';
import { Modal} from "react-bootstrap";
import api from '../../../../../../services/api';
import { toast } from 'react-toastify';


export default function ColProfileBankData({
    id,
    loadPage,
    collaborator,
    collaboratorUpdate,
    EditBankDataVisibleBank,
    visibleBank,
    onCollaboratorInputChange,
    myChangeHandlerToSaveBank,
    OnSubmit,
    abrirResultado,
    abrirResultBank,
    mySearchBank,
    loadContinue,
    openSelAccountType,
    openAccountType,
    selectAccountTypeOpen,
    myChangeHandlerAccountType
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(id){
            loadcadastrocolaborador(id);
        }
        loadapibank();

    }, [id]);

    async function loadcadastrocolaborador(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaboratorSaved(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ apiBank, setApiBank] = useState([]);


    async function loadapibank() {

        await api.get(`https://brasilapi.com.br/api/banks/v1`)
        .then(response => {

            const itensCopyBanck = Array.from(response.data);

            itensCopyBanck.sort(function (a, b) {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }

                return 0;
            });
            setApiBank(itensCopyBanck);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }


    return (
        <>

        <div className="box banco">
            <h3>Dados bancários 
                
                

            {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ? 
                "" 
                : 
                <button className="edit-Benefit" onClick={() => EditBankDataVisibleBank(collaborator)}><img src={EditBlack} alt="Edite Informações" /></button>
            }
                
            </h3>
            {
                loadPage ?
                    (
                        <>
                        <div className="carregamento">
                            <img alt="" src={Carregando} />
                        </div>
                        </>
                    ):(
                        <>

            <ul>
                <li>
                    <div className="intemA">
                        <p>Banco</p>
                    </div>
                    <div className="intemB">
                        <p>{collaborator.bankName !== undefined ? collaborator.bankName : '-'}</p>
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Agência</p>
                    </div>
                    <div className="intemB">
                        <p>{collaborator.bankAgency !== undefined ? collaborator.bankAgency : '-'}</p>
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Conta</p>
                    </div>
                    <div className="intemB">
                        <p>{collaborator.bankAccount ? collaborator.bankAccount : ''}
                        {collaborator.accountDigit ? " - " : ''}
                        
                        {collaborator.accountDigit ? collaborator.accountDigit : ''}
                        </p>
                    </div>
                </li>

                <li>
                    <div className="intemA">
                        <p>Tipo de conta</p>
                    </div>
                    <div className="intemB">
                        <p>

                        {collaborator.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                        collaborator.AccountType &&
                        collaborator.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                        collaborator.AccountType &&
                        collaborator.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                        }
                        </p>
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Pix</p>
                    </div>
                    <div className="intemB">
                        <p>{collaborator.pix !== undefined ? collaborator.pix : '-'}</p>
                    </div>
                </li>
            </ul>

            </>

            )}
        </div>
        <Modal show={visibleBank} className="popup-edit-perfil popup-edit-cartTrabalho"  onHide={EditBankDataVisibleBank}>
        <div id="select-todas" onClick={openAccountType}>
            <div className="titulo">
                <h4>Dados bancários <p className="btn-fechar" onClick={EditBankDataVisibleBank}><img src={Close} alt="Fechar" /></p></h4>
            </div>
            <ul>
                <li>
                    <div className="intemA">
                        <p>Banco</p>
                    </div>
                    <div className="intemB">
                        <div className='select-busca'>


                            <input type="text"
                            name="bankName"
                            value={
                                collaboratorUpdate && collaboratorUpdate.bankName !== undefined ?
                                collaboratorUpdate.bankName
                                :
                                collaborator.bankName !== undefined ?
                                collaborator.bankName
                                :
                                ''
                            }
                                placeholder='Banco'
                                onChange={mySearchBank}
                                onClick={abrirResultado}
                                autocomplete="autocompleteoff"
                                id="myBank" className='busca'
                            />

                            {abrirResultBank ?

                                    <div className='resultado-busca busca-bank' id='resultadoBusca'>
                                        {
                                            apiBank.length !== 0 ? apiBank
                                            .map((bank, index) => (
                                                <button onClick={() => myChangeHandlerToSaveBank(bank.name) } key={index} ><p  >{bank.code} {bank.code === null ? "" : "-"} {bank.name}</p></button>
                                            )): ('')
                                        }

                                    </div>

                                    :
                                ""
                            }
                        </div>

                    </div>

                </li>
                <li>
                    <div className="intemA">
                        <p>Agência</p>
                    </div>
                    <div className="intemB">
                        {collaboratorSaved.bankAgency !== undefined ?
                        (
                            collaboratorUpdate && collaboratorUpdate.bankAgency ?
                            (<input type="text" name="bankAgency" autocomplete="autocompleteoff"  value={collaboratorUpdate.bankAgency ? collaboratorUpdate.bankAgency : ''} onChange={onCollaboratorInputChange} className="inputDois"/>) :
                            (<input type="text" name="bankAgency" autocomplete="autocompleteoff"  defaultValue={collaboratorSaved.bankAgency ? collaboratorSaved.bankAgency : ''} onChange={onCollaboratorInputChange} className="inputDois"/>)
                        ) :
                        (
                            <input type="text" name="bankAgency" autocomplete="autocompleteoff" value={collaboratorUpdate.bankAgency ? collaboratorUpdate.bankAgency : ''} onChange={onCollaboratorInputChange} placeholder="Agência" className="inputDois"/>
                        )}
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Conta</p>
                    </div>
                    <div className="intemB digito-conta">
                    {
                        (collaboratorUpdate && collaboratorUpdate.bankAccount !== undefined) || collaboratorUpdate.bankAccount === "" ?
                            (
                                <input type="text" name="bankAccount" autocomplete="autocompleteoff" maxlength="20"  value={(collaboratorUpdate.bankAccount !== undefined) ? collaboratorUpdate.bankAccount : ''}  onChange={onCollaboratorInputChange} placeholder="Conta"   className="conta"/>
                            ) :
                            collaborator && collaborator.bankAccount ? (
                                <input type="text" name="bankAccount" autocomplete="autocompleteoff"  maxlength="20"  defaultValue={(collaboratorSaved.bankAccount !== undefined) ? collaboratorSaved.bankAccount : ''}  onChange={onCollaboratorInputChange} placeholder="Conta" className="conta"/>
                            ):
                            (
                                <input type="text" name="bankAccount" autocomplete="autocompleteoff"  maxlength="20"  value={(collaboratorUpdate.bankAccount !== undefined) ? collaboratorUpdate.bankAccount : ''}  onChange={onCollaboratorInputChange} placeholder="Conta"    className="conta"/>
                            )
                        }

                        {
                        (collaboratorUpdate && collaboratorUpdate.accountDigit !== undefined) || collaboratorUpdate.accountDigit === "" ?
                            (
                                <input type="text" name="accountDigit" autocomplete="autocompleteoff" maxlength="4"  value={(collaboratorUpdate.accountDigit !== undefined) ? collaboratorUpdate.accountDigit : ''}  onChange={onCollaboratorInputChange} placeholder="Dígito"   className="digito"/>
                            ) :
                            collaborator && collaborator.accountDigit ? (
                                <input type="text" name="accountDigit" autocomplete="autocompleteoff"  maxlength="4"  defaultValue={(collaboratorSaved.accountDigit !== undefined) ? collaboratorSaved.accountDigit : ''}  onChange={onCollaboratorInputChange} placeholder="Dígito" className="digito"/>
                            ):
                            (
                                <input type="text" name="accountDigit" autocomplete="autocompleteoff"  maxlength="4"  value={(collaboratorUpdate.accountDigit !== undefined) ? collaboratorUpdate.accountDigit : ''}  onChange={onCollaboratorInputChange} placeholder="Dígito"    className="digito"/>
                            )
                        }
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Tipo de conta</p>
                    </div>
                    <div className="intemB">

                        <div className='select-x'>
                            <button className='btn-select selectAccountType' id="selectAccountType" onClick={selectAccountTypeOpen} >
                                {
                                collaboratorUpdate && collaboratorUpdate.AccountType !==  undefined ?
                                collaboratorUpdate.AccountType ? ( collaboratorUpdate.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                collaboratorUpdate.AccountType &&
                                collaboratorUpdate.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                collaboratorUpdate.AccountType &&
                                collaboratorUpdate.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                ) : "selecione"
                                :
                                collaboratorSaved.AccountType ? ( collaborator.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                collaborator.AccountType &&
                                collaborator.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                collaborator.AccountType &&
                                collaborator.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                ) : "selecione"
                            }
                            </button>
                            {openSelAccountType ?
                            <div className='resultado-busca'>
                                <button onClick={() => myChangeHandlerAccountType('conta-Corrente')}><p>Conta Corrente</p></button>
                                <button onClick={() => myChangeHandlerAccountType('conta-poupanca')} ><p>Conta Poupança</p></button>
                                <button onClick={() => myChangeHandlerAccountType('conta-salario')}><p>Conta Salário</p></button>
                            </div>
                            :""}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="intemA">
                        <p>Pix</p>
                    </div>
                    <div className="intemB">
                        {collaboratorSaved.pix !== undefined ?
                        (<input type="text" name="pix" autocomplete="autocompleteoff" defaultValue={collaboratorSaved.pix ? collaboratorSaved.pix : ''} onChange={onCollaboratorInputChange}/>):
                        (<input  type="text" name="pix" autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="pix"/>)}

                    </div>
                </li>
                <li>
                    <div className="intemA">
                    </div>
                    <div className="intemB">
                        <form onSubmit={OnSubmit} className="btn-salvar">
                            <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>{collaboratorSaved && collaboratorSaved.bankAccount ? 'Atualizar' :'Salvar'}</button>
                        </form>
                    </div>
                </li>
            </ul>


        </div>
        </Modal>
        </>
    );
}
