import React, {useState} from 'react';
import './styles.scss';
import Layout from '../../components/Layouts/defaultUser';
import FeedUser from '../../components/DashboardUser/FeedUser';
import PraiseWall from '../../components/DashboardUser/PraiseWall';
import SideBar from '../../components/DashboardUser/SideBar';

export default function DashboardUser(props) {

    const { path } = props.match;
    const [ praise, setPraise] = useState(true);
    const [ feed, setFeed] = useState(false)

    function feedOpen() {
        setFeed(true);
        setPraise(false);
    }
    function praiseOpen() {
        setFeed(false);
        setPraise(true);
    }

    return (
        <>
            <Layout path={path}>
                <div className="dashbord-user">
                    <div className="conteudo-deshbord">
                        <div className='menu-praise-and-news'>
                            <ul>
                                <li><button onClick={praiseOpen} className={praise ? "active" : ""}><span>Mural de elogios</span></button></li>
                                <li><button onClick={feedOpen} className={feed ? "active" : ""}><span>Mural de anúncios</span></button></li>
                            </ul>
                        </div>
                        <div className='desktop-paiseWall-Feed'>
                            <PraiseWall path={path} />
                            <FeedUser/>
                        </div>
                        <div className='mobile-paiseWall-Feed'>
                            { praise ? <PraiseWall path={path}/> : "" }
                            { feed ? <FeedUser/> : "" }
                        </div>
                    </div>
                    <div className="sideBar">
                        <SideBar/>
                    </div>

                </div>
            </Layout>
        </>
    );

}
