import React from 'react';

import OperadorSodexo from '../../../../assets/images/operador-logo-sodexo.svg';
import OperadorTicket from '../../../../assets/images/operador-logo-ticket.svg';
import OperadorAlelo from '../../../../assets/images/operador-logo-alelo.svg';
import OperadorVR from '../../../../assets/images/operador-logo-vr.svg';
import OperadorFlash from '../../../../assets/images/operador-logo-flash.svg';
import OperadorSwile from '../../../../assets/images/operador-logo-swile.svg';
import OperadorUnimed from '../../../../assets/images/operador-logo-unimed.svg';
import OperadorClinipam from '../../../../assets/images/operador-logo-clinipam.svg';
import OperadorBradesco from '../../../../assets/images/operador-logo-bradesco.svg';
import OperadorParanaClinicas from '../../../../assets/images/operador-logo-parana-clinicas.png';
import OperadorSulAmerica from '../../../../assets/images/operador-logo-sulamerica.svg';
import OperadorAmil from '../../../../assets/images/operador-logo-amil.svg';
import OperadorNossaSaude from '../../../../assets/images/operador-logo-nossa-saude.svg';
import OperadorMedSul from '../../../../assets/images/operador-logo-medsul.png';
import OperadorDentalUni from '../../../../assets/images/operador-logo-dental-uni.png';
import OperadorAmilDental from '../../../../assets/images/operador-logo-amil-dental.svg';
import OperadorSulAmericaOdonto from '../../../../assets/images/operador-logo-sulamerica-dental.svg';
import OperadorPortoSeguro from '../../../../assets/images/operador-logo-porto-seguro.svg';
import OperadorBradescoSeguroDental from '../../../../assets/images/operador-logo-bradesco-dental.svg';
import OperadorDentalprev from '../../../../assets/images/operador-logo-dentalprev.svg';
import OperadorMetlife from '../../../../assets/images/operador-logo-metlife.svg';
import OperadorPrudential from '../../../../assets/images/operador-logo-prudential.svg';
import OperadorSantander from '../../../../assets/images/operador-logo-santander.svg';
import OperadorZenklub from '../../../../assets/images/operador-logo-zenklub.svg';
import OperadorKeiken from '../../../../assets/images/operador-logo-keiken.png';
import OperadorGympass from '../../../../assets/images/operador-logo-gympass.svg';
import OperadorTotalPass from '../../../../assets/images/operador-logo-totalpass.svg';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../../assets/images/more.svg';


export default function ColBeneficioInfor({
    detailBenefit,
    unlinkBenefits,
}) {

    async function calledUrlEditBenefit(id) {
        window.location.href = `/beneficios/${id}/editar/`;
    }

    return (
        <>
            <div 
                className={
                    detailBenefit.category                === 'Refeição'    ? 'informacao-beneficio vl-refeicao' :
                    detailBenefit.category                === 'Transporte'    ? 'informacao-beneficio vl-transporte' :
                    detailBenefit.category                === 'Combustível'    ? 'informacao-beneficio vl-combustivel' :
                    detailBenefit.category                === 'Alimentação'    ? 'informacao-beneficio vl-alimentacao' :
                    detailBenefit.category                === 'Mobilidade'    ? 'informacao-beneficio vl-mobilidade' :
                    detailBenefit.category                === 'Estacionamento'    ? 'informacao-beneficio vl-estacionamento' :
                    detailBenefit.category                === 'Plano de Saúde'    ? 'informacao-beneficio vl-plano-saude' :
                    detailBenefit.category                === 'Plano Odontológico'    ? 'informacao-beneficio vl-plano-odonto' :
                    detailBenefit.category                === 'Seguro de Vida'    ? 'informacao-beneficio vl-seguro-vida' :
                    detailBenefit.category                === 'Plano de Saúde Pet'    ? 'informacao-beneficio vl-plano-saude-pet' :
                    detailBenefit.category                === 'Convênio Farmácia'    ? 'informacao-beneficio vl-convenio-farmacia' :
                    detailBenefit.category                === 'Assistência à Educação'    ? 'informacao-beneficio vl-assistencia-educacao' :
                    detailBenefit.category                === 'Cultura'    ? 'informacao-beneficio vl-cultura' :
                    detailBenefit.category                === 'Auxílio home-office'    ? 'informacao-beneficio vl-auxilio-home-office' :
                    detailBenefit.category                === 'Bem-estar'    ? 'informacao-beneficio vl-bem-estar' :
                    detailBenefit.category                === 'Clube de Benefícios'    ? 'informacao-beneficio vl-clube-beneficios' :
                    detailBenefit.category                === 'Premiação'    ? 'informacao-beneficio vl-premiacao' :
                    detailBenefit.category                === 'Day Off'    ? 'informacao-beneficio vl-day-off' :
                    detailBenefit.category                === 'Previdência Privada'    ? 'informacao-beneficio vl-previdencia-privada' :
                    detailBenefit.category                === 'Licença maternidade'    ? 'informacao-beneficio vl-licenca-mater-paternidade' :
                    detailBenefit.category                === 'Licença Paternidade estendida'    ? 'informacao-beneficio vl-licenca-mater-paternidade' :
                    detailBenefit.category                === 'Alimentação e Refeição'    ? 'informacao-beneficio vl-alimentacao-refeicao' :
                    detailBenefit.category                === 'Outros'    ? 'informacao-beneficio vl-outros' :'informacao-beneficio vl-outros'
                }
            
            >
                <h4>Informações
                    <Dropdown  className="dropdown-icon">
                        <Dropdown.Toggle>
                        <img alt="" src={iconVerMais} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <button onClick={(event) => calledUrlEditBenefit(detailBenefit._id)} type="button" name="EditarBeneficio">
                            Editar benefício
                            </button>
                            <button onClick={(event) => unlinkBenefits(detailBenefit._id)} type="button" name="iniciarFerias" className="red">
                            Excluir benefício
                            </button>
                        </Dropdown.Menu>
                    </Dropdown>
                </h4>
                <div className='infor'>
                    <div>
                        <p>Nome do benefício</p>
                        <span>{detailBenefit.name}</span>
                    </div>
                    <div>
                        <p>Categoria</p>
                        <span>{detailBenefit.category}</span>
                    </div>
                    <div>
                        <p>Data de corte</p>
                        <span>{detailBenefit.cutoffDate}</span>
                    </div>
                    <div>
                        <p>Operador</p>
                        <div className="operador-img">
                            <img src={
                                detailBenefit.operator                === 'Zenklub'    ? OperadorZenklub :
                                detailBenefit.operator                === 'Keiken'    ? OperadorKeiken :
                                detailBenefit.operator                === 'Gympass'    ? OperadorGympass :
                                detailBenefit.operator                === 'Total Pass'    ? OperadorTotalPass :

                                detailBenefit.operator                === 'Prudential'    ? OperadorPrudential :
                                detailBenefit.operator                === 'Metlife'    ? OperadorMetlife :
                                detailBenefit.operator                === 'Santander'    ? OperadorSantander :

                                detailBenefit.operator                === 'Dental Uni'    ? OperadorDentalUni :
                                detailBenefit.operator                === 'Amil Dental'    ? OperadorAmilDental :
                                detailBenefit.operator                === 'Sul América Odonto'    ? OperadorSulAmericaOdonto :
                                detailBenefit.operator                === 'Porto Seguro'    ? OperadorPortoSeguro :
                                detailBenefit.operator                === 'Bradesco Seguro Dental'    ? OperadorBradescoSeguroDental :
                                detailBenefit.operator                === 'Dentalprev'    ? OperadorDentalprev :


                                detailBenefit.operator                === 'Unimed'    ? OperadorUnimed :
                                detailBenefit.operator                === 'Clinipam'    ? OperadorClinipam :
                                detailBenefit.operator                === 'Bradesco Seguros'    ? OperadorBradesco :
                                detailBenefit.operator                === 'Paraná Clínicas'    ? OperadorParanaClinicas :
                                detailBenefit.operator                === 'Sul América'    ? OperadorSulAmerica :
                                detailBenefit.operator                === 'Amil'    ? OperadorAmil :
                                detailBenefit.operator                === 'Nossa Saúde'    ? OperadorNossaSaude :
                                detailBenefit.operator                === 'MedSul'    ? OperadorMedSul :

                                detailBenefit.operator                === 'Sodexo'    ? OperadorSodexo :
                                detailBenefit.operator                === 'Alelo'    ? OperadorAlelo :
                                detailBenefit.operator                === 'VR'       ? OperadorVR :
                                detailBenefit.operator                === 'Flash'    ? OperadorFlash :
                                detailBenefit.operator                === 'Swile'    ? OperadorSwile :
                                detailBenefit.operator                === 'Ticket'    ? OperadorTicket : ""
                            } alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

