/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import Close from '../../../../assets/images/close.svg';
import { NavLink } from 'react-router-dom'
import Carregamento from '../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import {  Modal } from "react-bootstrap";
import swal from 'sweetalert';
import FoodGroceries from '../../../../assets/images/illustration-food-groceries.svg';
import CurrencyInput from 'react-currency-input';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

export default function ColProfileBenefits({ id, path }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCompanyCosts, setLoadCompanyCosts ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ newBenefit, setNewBenefit ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnSave, setLoadBtnSave ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorCosts, setLoadCollaboratorCosts ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [benefitClicked, setBenefitClicked] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ beneftis, setBeneftis] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ beneftisDiferentCollaborator, setBeneftisDiferentCollaborator] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPageCollaboratorBeneftis, setLoadPageCollaboratorBeneftis ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) {

            loadcollaborator(id);
            loadbenefits();
            loadbenefitsID(id);
            loadbenefitsnewdiferent(id);

        }

    }, [id]);

    async function loadcollaborator(id) {
        await api.get(`/collaborator/${id}`)
        .then(response => {
            setCollaborator(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    async function loadbenefits() {
        setLoadPage(true)
        await api.get('/beneftis/?status=Ativo')
        .then(response => {
            setBeneftis(response.data);
            setLoadPage(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    async function loadbenefitsID(id) {
        setLoadPageCollaboratorBeneftis(true)
        await api.get(`/beneftis-new/?collaborator=${id}&status=Ativo`)
        .then(response => {
            setBeneftisDiferentCollaborator(response.data);
            setLoadPageCollaboratorBeneftis(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    ///////////////////////////// BENEFITS COMPANY NEW DIFERENT //////////////////////////////
    //eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorBeneftis, setCollaboratorBeneftis] = useState([]);
    async function loadbenefitsnewdiferent(id) {
        await api.get(`/beneftis-new-diferent/?collaborator=${id}&status=Ativo`)
        .then(response => {
            setCollaboratorBeneftis(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    function handleToggleVisibleAtivar(e) {
        setLoadBtnSave(false)
        setBenefitClicked(e)
        setVisible(!visible);
    }
    function handleToggleNoVisibleAtivar() {
        setLoadBtnSave(false)
        setNewBenefit([]);
        setVisible(!visible);
        setLoadCompanyCosts(false)
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [benefitClickedEdit, setBenefitClickedEdit] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleEditar, setVisibleEditar] = useState(false);
    function handleToggleVisibleEditar(e) {
        setLoadBtnSave(false)
        setVisibleEditar(!visibleEditar);
        setBenefitClickedEdit(e)
        setLoadCompanyCosts(false)
    }
    function handleToggleNoVisibleEditar(e) {
        setLoadBtnSave(false)
        setBenefitClickedEdit([]);
        setVisibleEditar(!visibleEditar);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ benefitEdit, setBenefitEdit ] = useState([]);
    async function myChangeHandlerEdit(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = id;
        let benefits     = benefitClickedEdit._id;
        if (nam === "collaboratorCosts") {
            if(val.length === 2) {
                setLoadCollaboratorCosts(false)
            }
            let collaboratorCosts = val.replace('R$ ', '');
            setBenefitEdit({ ...benefitEdit, [nam]: collaboratorCosts, collaborator, benefits })
        }
        else  if (nam === "companyCosts") {
            setLoadCompanyCosts(false)
            let companyCosts = val.replace('R$ ', '');
            setBenefitEdit({ ...benefitEdit, [nam]: companyCosts })
        }
        else {
            setBenefitEdit({ ...benefitEdit, [nam]: val })
        }
    }
    async function update(event) {
        event.preventDefault();
        let idBenefits = benefitClickedEdit._id;
        const benefitUpdate = {
            collaboratorCosts : benefitEdit?.collaboratorCosts ? benefitEdit?.collaboratorCosts : benefitClickedEdit.collaboratorCosts,
            companyCosts      : benefitEdit?.companyCosts ? benefitEdit?.companyCosts : benefitClickedEdit.companyCosts,
            path              : path
        }
        if (benefitUpdate.companyCosts === "0.00") {
            setLoadCompanyCosts(true)
            return
        }
        async function loadupdatecollaboratorbenefit() {
            setLoadBtnSave(true)
            await api.put(`collaborator-beneftis/${idBenefits}`, benefitUpdate)
            .then(response => {
                loadbenefits()
                loadbenefitsnewdiferent(id)
                loadbenefitsID(id)
                setBenefitEdit([]);
                setVisibleEditar(!visibleEditar);
                swal({ icon: "success", title: "Sucesso!", text: "Benefício editado com sucesso!" });
            }).catch(error => {
                setLoadBtnSave(false)
                swal({
                    icon: "warning",
                    title: "Atenção!",
                    text:
                    error.response.data.message ?
                    error.response.data.message
                    :
                    "Erro ao ativar benefício, tente novamente mais tarde."
                });
            });
        }
        loadupdatecollaboratorbenefit();
    }
    ///////////////////////////////// FINAL BENEFITS COMPANY NEW DIFERENT//////////////////////////////////
    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = id;
        let benefits     = benefitClicked._id;

        if (nam === "collaboratorCosts") {
            if(val.length === 2) {
                setLoadCollaboratorCosts(false)
            }
            let collaboratorCosts = val.replace('R$ ', '');
            setNewBenefit({ ...newBenefit, [nam]: collaboratorCosts, collaborator, benefits, path: path })
        }
        else  if (nam === "companyCosts") {
            setLoadCompanyCosts(false)
            let companyCosts = val.replace('R$ ', '');
            setNewBenefit({ ...newBenefit, [nam]: companyCosts, collaborator, benefits, path: path  })
        }
        else {
            setNewBenefit({ ...newBenefit, [nam]: val })
        }
    }
    async function desativarBeneficio(benefit) {
        swal({
            title: "Atenção",
            text: "Deseja desativar este beneficio?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                setLoadPage(true)
                setLoadBtnSave(true)
                await api.put(`/collaborator-beneftis/${benefit}`, { status : "Desvinculado", path: path })
                .then(() => {
                    loadbenefits()
                    loadbenefitsnewdiferent(id)
                    loadbenefitsID(id)
                    setNewBenefit([]);
                    swal({ icon: "success", title: "Sucesso!", text: "Benefício desvinculado com sucesso." });
                }).catch((error) => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });
            }
        });
    }
    function removeUndefinedAndEmptyFields(obj) {
        const result = {};
      
        for (const key in obj) {
          if (obj[key] !== undefined && obj[key] !== null) {
            if (typeof obj[key] === 'string') {
              if (obj[key].trim() !== '') {
                result[key] = obj[key];
              }
            } else {
              result[key] = obj[key];
            }
          }
        }
      
        return result;
      }
    async function save(event) {
        event.preventDefault();
        let benefitArray = {};
        if(newBenefit?.length !== 0) {
            benefitArray = newBenefit;
        }else {
            setLoadCompanyCosts(true)
            swal({ icon: "warning", title: "Atenção!", text: "Por favor, informe o valor do benefício para poder ativá-lo." });
            return
        }
        setLoadPage(true)
        async function loadallbenefits() {
            await api.get(`/collaborator-beneftis-saved/?collaborator=${id}&benefits=${benefitArray.benefits}`)
            .then(response => {
                if(response.data == null) {
                    if (
                        (benefitArray.collaboratorCosts              !== undefined &&
                        benefitArray.collaboratorCosts               !==     "")
                        ||
                        (benefitArray.companyCosts                   !== undefined &&
                        benefitArray.companyCosts                    !==     "")
                    ) {
                        setLoadBtnSave(true)
                        async function loadsavecollaboratorbenefit() {
                            await api.post('/collaborator-beneftis', benefitArray)
                            .then(response => {
                                setLoadBtnSave(false)
                                loadbenefits()
                                loadbenefitsnewdiferent(id)
                                loadbenefitsID(id)
                                setNewBenefit([]);
                                setVisible(!visible);
                                swal({ icon: "success", title: "Sucesso!", text: "Benefício ativado com sucesso!" });
                            }).catch(error => {
                                setLoadBtnSave(false)
                                swal({
                                    icon: "warning",
                                    title: "Atenção!",
                                    text:
                                    error.response.data.message ?
                                    error.response.data.message
                                    :
                                    "Erro ao ativar benefício, tente novamente mais tarde."
                                });
                            });
                        }
                        loadsavecollaboratorbenefit();
                    }
                }
                else {
                    let id_collaborator_benefit = response.data._id;
                    const benefitUpdate = {
                        status            : 'Ativo',
                        collaboratorCosts : newBenefit?.collaboratorCosts,
                        companyCosts      : newBenefit?.companyCosts,
                    }
                    const cleanedBenefitUpdate = removeUndefinedAndEmptyFields(benefitUpdate);
                    async function loadupdatecollaboratorbenefit() {
                        setLoadBtnSave(true)
                        await api.put(`collaborator-beneftis/${id_collaborator_benefit}`, cleanedBenefitUpdate)
                        .then(response => {
                            setLoadBtnSave(false)
                            loadbenefits()
                            loadbenefitsnewdiferent(id)
                            loadbenefitsID(id)
                            setNewBenefit([]);
                            setVisible(!visible);
                            swal({ icon: "success", title: "Sucesso!", text: "Benefício ativado com sucesso!" });
                        }).catch(error => {
                            setLoadBtnSave(false)
                            swal({
                                icon: "warning",
                                title: "Atenção!",
                                text:
                                error.response.data.message ?
                                error.response.data.message
                                :
                                "Erro ao ativar benefício, tente novamente mais tarde."
                            });
                        });
                    }
                    loadupdatecollaboratorbenefit();
                }
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadallbenefits();
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);
    const myChangeHandlerExcludeAdmssion = async (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        let admissionProcess = "false";
        let shutdownProcess = "true";
        let recentShutdown  = "false";
        let status = "Inativo";
        setCollaboratorUpdate({ ...collaboratorUpdate, admissionProcess, shutdownProcess, recentShutdown, status, [nam]: val })
    }
    async function excludeAdmssion(event) {
        event.preventDefault();
        swal({
            title: "Atenção",
            text: "Deseja realmente excluir o processo de admissão deste colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                formData.append('_id', collaborator._id);
                formData.append('admissionProcess', collaboratorUpdate.admissionProcess);
                formData.append('shutdownProcess', collaboratorUpdate.shutdownProcess);
                formData.append('recentShutdown', collaboratorUpdate.recentShutdown);
                formData.append('status', collaboratorUpdate.status);
                await api.put(`/collaborator/${collaborator._id}`, formData, config)
                .then(response => {
                    swal({ icon: "success", title: "Sucesso!", text: "Admissão excluída com sucesso." });
                    window.location.href = '/colaboradores/nova-admissao/formulario-Informacoes-basicas';
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao excluir o colaborador, tente novamente mais tarde." });
                });
            }
        })
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadStepThree, setLoadStepThree] = useState(false);
    async function saveStepThree() {
        if(collaboratorBeneftis?.length === 0) {
            swal({
                title: "Atenção",
                text: "Não foi ativiado nenhum benefício para este colaborador, deseja continuar mesmo assim?",
                icon: "warning",
                buttons: ["Cancelar", "OK"],
                dangerMode: false,
            })
            .then(async (res) => {
                if (res) {
                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    formData.append('stepOne', 'true');
                    formData.append('stepTwo', 'true');
                    formData.append('stepThree', 'true');
                    formData.append('stepFour', 'true');
                    formData.append('path', path);
                    let stepsNewAdmission = collaborator.stepsNewAdmission;
                    if(stepsNewAdmission === 4) {
                        formData.append('stepsNewAdmission', 5);
                    }
                    setLoadStepThree(true);
                    await api.put(`/collaborator/${id}`, formData, config)
                    .then(response => {
                        setLoadStepThree(false)
                        window.location.href = `/colaboradores/nova-admissao/enviar-contador/${id}`;
                    })
                    .catch(error => {
                        swal({
                            icon: "error", title: "Erro!",
                            text: error?.response?.data?.message ?
                            error?.response?.data?.message
                            :
                            'Não foi possível salvar o colaborador, por favor, entre em contato com o administrador do sistema.'
                        });
                    });
                }
            })
        }else {
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('stepOne', 'true');
            formData.append('stepTwo', 'true');
            formData.append('stepThree', 'true');
            formData.append('stepFour', 'true');
            formData.append('path', path);
            let stepsNewAdmission = collaborator.stepsNewAdmission;
            if(stepsNewAdmission === 4) {
                formData.append('stepsNewAdmission', 5);
            }
            setLoadStepThree(true);
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {
                setLoadStepThree(false)
                window.location.href = `/colaboradores/nova-admissao/enviar-contador/${id}`;
            })
            .catch(error => {
                swal({
                    icon: "error", title: "Erro!",
                    text: error?.response?.data?.message ?
                    error?.response?.data?.message
                    :
                    'Não foi possível salvar o colaborador, por favor, entre em contato com o administrador do sistema.'
                });
            });
        }
    }
    return (
        <>
        <div className="form-beneficios">   
            <div className="box">
                {
                    loadPage === true ||
                    loadPageCollaboratorBeneftis === true ?
                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                    : 
                    beneftis &&
                    beneftis.length !== 0 ? 
                    ( <>
                        <div className="beficios-grid">
                        {
                        beneftis &&
                        beneftis.length !== 0 ?
                        beneftis.map((benefit) => (
                            collaboratorBeneftis && collaboratorBeneftis.length !== 0 ?
                            collaboratorBeneftis
                            .filter(
                                    filetrcollaboratorbenefit =>
                                    filetrcollaboratorbenefit.benefits._id === benefit._id
                                )
                            .map((collaboratorbenefit, index) => (
                                <>
                                    <div key={index}
                                        className={
                                            benefit.category                === 'Refeição'    ? 'beneficio-Ativo beneficio vl-refeicao' :
                                            benefit.category                === 'Transporte'    ? 'beneficio-Ativo beneficio vl-transporte' :
                                            benefit.category                === 'Combustível'    ? 'beneficio-Ativo beneficio vl-combustivel' :
                                            benefit.category                === 'Alimentação'    ? 'beneficio-Ativo beneficio vl-alimentacao' :
                                            benefit.category                === 'Mobilidade'    ? 'beneficio-Ativo beneficio vl-mobilidade' :
                                            benefit.category                === 'Estacionamento'    ? 'beneficio-Ativo beneficio vl-estacionamento' :
                                            benefit.category                === 'Plano de Saúde'    ? 'beneficio-Ativo beneficio vl-plano-saude' :
                                            benefit.category                === 'Plano Odontológico'    ? 'beneficio-Ativo beneficio vl-plano-odonto' :
                                            benefit.category                === 'Seguro de Vida'    ? 'beneficio-Ativo beneficio vl-seguro-vida' :
                                            benefit.category                === 'Plano de Saúde Pet'    ? 'beneficio-Ativo beneficio vl-plano-saude-pet' :
                                            benefit.category                === 'Convênio Farmácia'    ? 'beneficio-Ativo beneficio vl-convenio-farmacia' :
                                            benefit.category                === 'Assistência à Educação'    ? 'beneficio-Ativo beneficio vl-assistencia-educacao' :
                                            benefit.category                === 'Cultura'    ? 'beneficio-Ativo beneficio vl-cultura' :
                                            benefit.category                === 'Auxílio home-office'    ? 'beneficio-Ativo beneficio vl-auxilio-home-office' :
                                            benefit.category                === 'Bem-estar'    ? 'beneficio-Ativo beneficio vl-bem-estar' :
                                            benefit.category                === 'Clube de Benefícios'    ? 'beneficio-Ativo beneficio vl-clube-beneficio-Ativo beneficios' :
                                            benefit.category                === 'Premiação'    ? 'beneficio-Ativo beneficio vl-premiacao' :
                                            benefit.category                === 'Day Off'    ? 'beneficio-Ativo beneficio vl-day-off' :
                                            benefit.category                === 'Previdência Privada'    ? 'beneficio-Ativo beneficio vl-previdencia-privada' :
                                            benefit.category                === 'Licença maternidade'    ? 'beneficio-Ativo beneficio vl-licenca-mater-paternidade' :
                                            benefit.category                === 'Licença Paternidade estendida'    ? 'beneficio-Ativo beneficio vl-licenca-mater-paternidade' :
                                            benefit.category                === 'Alimentação e Refeição'    ? 'beneficio-Ativo beneficio vl-alimentacao-refeicao' :
                                            benefit.category                === 'Outros'    ? 'beneficio-Ativo beneficio vl-outros' :'beneficio-Ativo beneficio vl-outros'
                                        }
                                    >
                                        <div className="titulo">
                                            <h4>{collaboratorbenefit.benefits.name}</h4>
                                        </div>
                                        <div className="buttoes">
                                            <button onClick={() => handleToggleVisibleEditar(collaboratorbenefit)} className="configure-Benefit">
                                            Editar benefício
                                            </button>
                                            <button onClick={() => desativarBeneficio(collaboratorbenefit._id)} className="desativar">
                                            Desativar
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )):''
                        )):''
                        }
                        {
                            beneftisDiferentCollaborator &&
                            beneftisDiferentCollaborator.length !== 0 ?
                            beneftisDiferentCollaborator.map((benefitsdiferent, index) => (
                                <>
                                    <div key={benefitsdiferent._id}
                                        className={
                                            benefitsdiferent.category                === 'Refeição'    ? 'beneficio vl-refeicao' :
                                            benefitsdiferent.category                === 'Transporte'    ? 'beneficio vl-transporte' :
                                            benefitsdiferent.category                === 'Combustível'    ? 'beneficio vl-combustivel' :
                                            benefitsdiferent.category                === 'Alimentação'    ? 'beneficio vl-alimentacao' :
                                            benefitsdiferent.category                === 'Mobilidade'    ? 'beneficio vl-mobilidade' :
                                            benefitsdiferent.category                === 'Estacionamento'    ? 'beneficio vl-estacionamento' :
                                            benefitsdiferent.category                === 'Plano de Saúde'    ? 'beneficio vl-plano-saude' :
                                            benefitsdiferent.category                === 'Plano Odontológico'    ? 'beneficio vl-plano-odonto' :
                                            benefitsdiferent.category                === 'Seguro de Vida'    ? 'beneficio vl-seguro-vida' :
                                            benefitsdiferent.category                === 'Plano de Saúde Pet'    ? 'beneficio vl-plano-saude-pet' :
                                            benefitsdiferent.category                === 'Convênio Farmácia'    ? 'beneficio vl-convenio-farmacia' :
                                            benefitsdiferent.category                === 'Assistência à Educação'    ? 'beneficio vl-assistencia-educacao' :
                                            benefitsdiferent.category                === 'Cultura'    ? 'beneficio vl-cultura' :
                                            benefitsdiferent.category                === 'Auxílio home-office'    ? 'beneficio vl-auxilio-home-office' :
                                            benefitsdiferent.category                === 'Bem-estar'    ? 'beneficio vl-bem-estar' :
                                            benefitsdiferent.category                === 'Clube de Benefícios'    ? 'beneficio vl-clube-beneficios' :
                                            benefitsdiferent.category                === 'Premiação'    ? 'beneficio vl-premiacao' :
                                            benefitsdiferent.category                === 'Day Off'    ? 'beneficio vl-day-off' :
                                            benefitsdiferent.category                === 'Previdência Privada'    ? 'beneficio vl-previdencia-privada' :
                                            benefitsdiferent.category                === 'Licença maternidade'    ? 'beneficio vl-licenca-mater-paternidade' :
                                            benefitsdiferent.category                === 'Licença Paternidade estendida'    ? 'beneficio vl-licenca-mater-paternidade' :
                                            benefitsdiferent.category                === 'Alimentação e Refeição'    ? 'beneficio vl-alimentacao-refeicao' :
                                            benefitsdiferent.category                === 'Outros'    ? 'beneficio vl-outros' :'beneficio vl-outros'
                                        }
                                    >
                                        <div className="titulo">
                                            <h4>{benefitsdiferent.name}</h4>
                                        </div>
                                        <div className="buttoes">
                                            <button onClick={() => handleToggleVisibleAtivar(benefitsdiferent)} className="configure-Benefit">
                                            Ativar Benefício
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )):''
                        }
                    </div>
                    </> )
                    :
                    <div className="nenhum-beneficio-cadastrado" >
                        <p>Nenhum benefícios cadastrado. <br/>
                        Para prosseguir e necessário criar um beneficios.
                        </p>
                        <NavLink className="btnGreen more" to="/beneficios/criar" >Cadastrar um benefício</NavLink>
                        <img src={FoodGroceries} alt="Nenhum beneficio cadastrado" />
                    </div>
                }
            </div>
            <div className="buttons">
                <form onSubmit={excludeAdmssion} className="deleteStepOne">
                        <button onClick={myChangeHandlerExcludeAdmssion} name="_id" value={collaborator._id} type="submit" className="delete">
                            Excluir processo
                        </button>
                </form>

                    <div className='salvedStep'>
                        <NavLink to={`/colaboradores/nova-admissao/formulario-documentos/${id}`} className="back btnGray">Voltar</NavLink>
                        <button onClick={saveStepThree} className={loadStepThree ? "saved btnGreen load" : "saved btnGreen"}>salvar e continuar</button>
                    </div>
                </div>
            </div>
            <Modal show={visible} className="popup-beneficios" onHide={handleToggleNoVisibleAtivar}>
                <div>
                    <div className="titulo">
                        <h4>Configurar benefício
                        <p className="btn-fechar" onClick={handleToggleNoVisibleAtivar}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="colab-img-nome-cargo">
                        {
                            collaborator.avatar && collaborator.avatar !== 'undefined' ?
                            <img alt="" src={collaborator.avatar} className="foto-Usuario" />
                            :
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="Usuario sem imagem" />
                            </div>
                        }
                        <div className="nome-cargo">
                            <h6>{collaborator.name}</h6>
                            <p>{collaborator.occupation}</p>
                        </div>
                    </div>
                    <div className="beneficio-infor">
                        <div className="infor">
                            <label>Benefício</label>
                            <p>{benefitClicked && benefitClicked?.name  ? benefitClicked.name : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Operador</label>
                            <p>{benefitClicked && benefitClicked?.operator  ? benefitClicked.operator : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Data de corte</label>
                            <p>{benefitClicked && benefitClicked?.cutoffDate ? benefitClicked.cutoffDate : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Crédito</label>
                            <p>{benefitClicked && benefitClicked?.balanceCalculation  ? benefitClicked.balanceCalculation : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Descrição</label>
                            <p>{benefitClicked && benefitClicked?.description ? benefitClicked.description : '-'}</p>
                        </div>
                    </div>
                    <div className="custos-beneficio">
                        <h6>Custos</h6>
                        <div className="calculo-beneficio">
                            <div className="infor-custo">
                                <label>Colaborador</label>
                                <CurrencyInput
                                    prefix="R$ "
                                    onChangeEvent={myChangeHandler}
                                    name="collaboratorCosts"
                                    placeholder="R$ 0.00"
                                    value={newBenefit.collaboratorCosts !== undefined ? newBenefit.collaboratorCosts : null}
                                />
                                {loadCollaboratorCosts ?
                                (<p className="textError">'É necessário preencher o custo do collaborador'</p>) :
                                ('')
                                }
                            </div>
                            <div className="infor-custo">
                                <label>Empresa</label>
                                <CurrencyInput
                                    prefix="R$ "
                                    onChangeEvent={myChangeHandler}
                                    name="companyCosts"
                                    placeholder="R$ 0.00"
                                    className={loadCompanyCosts ? 'inputErro' : ''}
                                    value={newBenefit.companyCosts !== undefined ? newBenefit.companyCosts : null}
                                />
                                {loadCompanyCosts ?
                                (<p className="textError">O campo é obrigatório.</p>) :
                                ('')
                                }
                            </div>
                        </div>
                    </div>
                    <form onSubmit={save}>
                        <button className={loadBtnSave  ? 'btn-config btnGreen load' : 'btn-config btnGreen'}>Ativar benefício</button>
                    </form>
                </div>
            </Modal>
            <Modal show={visibleEditar} className="popup-beneficios" onHide={handleToggleNoVisibleEditar}>
                <div>
                    <div className="titulo">
                    <h4>Editar benefício
                    <p className="btn-fechar" onClick={handleToggleNoVisibleEditar}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="colab-img-nome-cargo">
                        {
                            collaborator.avatar && collaborator.avatar !== 'undefined' ?
                            <img alt="" src={collaborator.avatar} className="foto-Usuario" />
                            :
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="Usuario sem imagem" />
                            </div>
                        }
                        <div className="nome-cargo">
                            <h6>{collaborator.name}</h6>
                            <p>{collaborator.occupation}</p>
                        </div>
                    </div>
                    <div className="beneficio-infor">
                        <div className="infor">
                            <label>Benefício</label>
                            <p>{benefitClickedEdit && benefitClickedEdit?.benefits?.name  ? benefitClickedEdit.benefits.name : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Operador</label>
                            <p>{benefitClickedEdit && benefitClickedEdit?.benefits?.operator  ? benefitClickedEdit.benefits.operator : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Data de corte</label>
                            <p>{benefitClickedEdit && benefitClickedEdit?.benefits?.cutoffDate  ? benefitClickedEdit.benefits.cutoffDate : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Crédito</label>
                            <p>{benefitClickedEdit && benefitClickedEdit?.benefits?.balanceCalculation ? benefitClickedEdit.benefits.balanceCalculation : '-'}</p>
                        </div>
                        <div className="infor">
                            <label>Descrição</label>
                            <p>{benefitClickedEdit && benefitClickedEdit.benefits?.description  ? benefitClickedEdit.benefits.description : '-'}</p>
                        </div>
                    </div>
                    <div className="custos-beneficio">
                        <h6>Custos</h6>
                        <div className="calculo-beneficio">
                            <div className="infor-custo">
                                <label>Colaborador</label>
                                <CurrencyInput
                                    prefix="R$ "
                                    onChangeEvent={myChangeHandlerEdit}
                                    name="collaboratorCosts"
                                    placeholder="R$ 0.00"
                                    value={benefitEdit.collaboratorCosts !== undefined ? benefitEdit.collaboratorCosts : benefitClickedEdit.collaboratorCosts !== undefined ? benefitClickedEdit.collaboratorCosts : null}
                                />
                                {loadCollaboratorCosts ?
                                (<p className="textError">'É necessário preencher o custo do collaborador'</p>) :
                                ('')
                                }
                            </div>
                            <div className="infor-custo">
                                <label>Empresa</label>
                                <CurrencyInput
                                    prefix="R$ "
                                    onChangeEvent={myChangeHandlerEdit}
                                    name="companyCosts"
                                    placeholder="R$ 0.00"
                                    value={benefitEdit.companyCosts !== undefined ?
                                        benefitEdit.companyCosts
                                        : benefitClickedEdit.companyCosts !== undefined ?
                                            benefitClickedEdit.companyCosts : null
                                    }
                                    className={loadCompanyCosts ? 'inputErro' : ''}
                                />
                                {loadCompanyCosts ?
                                (<p className="textError">O campo é obrigatório.</p>) :
                                ('')
                                }
                            </div>
                        </div>
                    </div>
                    <form onSubmit={update}>
                        <button className={loadBtnSave  ? 'btn-config btnGreen load' : 'btn-config btnGreen'}>Salvar benefício</button>
                    </form>
                </div>
            </Modal>
        </>
    );
}
