import React, {
     useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Management/headerFeedBack';
import BoxInfor from '../../../components/Management/Feedbacks/Box-infs';
import BoxEvaluationGeneral from '../../../components/Management/Feedbacks/BoxEvaluationGeneral';
import TableFeedbacks from '../../../components/Management/Feedbacks/tableFeedbacks';
import IllustrationFeed from '../../../assets/images/illustration-feedback.svg'
import '../styles.scss';
// import { decodeToken } from '../../../services/auth';

// import api from '../../../services/api';

export default function homeFeedback(props) {

    const { path } = props.match;


    ///////// SETS E FUCTION DO MODAL DE AGENDAR FEEDBACK
     
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModalscheduleFeedback, setVisibleModalScheduleFeedback] = useState(false);
    async function modalScheduleFeedback() {
        setVisibleModalScheduleFeedback(!visibleModalscheduleFeedback);
    }
    ///////// SET PROVISORIO DE ESTADO VAZIO
     
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [feedBackList, ] = useState(false);



        return (
            <>
               <Layout path={path}>
                    <Header 
                        path={path} 
                        modalScheduleFeedback={modalScheduleFeedback}
                        visibleModalscheduleFeedback={visibleModalscheduleFeedback}
                    />
                    <div className='pages-management'>
                        {
                            feedBackList ?
                            <div className='nenhum-feedback'>
                               <p>Nenhum feedback agendado. <br/>Agende um feedback.</p>
                               <button className='btnGreen more ' onClick={modalScheduleFeedback}>Agendar feedback</button>

                               <img src={IllustrationFeed} alt="" />
                            </div>
                            :
                            <div className='feedback'>
                                <div className='left'>
    
                                    <BoxInfor />
                                    <TableFeedbacks/>
    
                                </div>
                                <div className='rigth'>
                                    <BoxEvaluationGeneral/>
                                </div>
    
                            </div>

                        }
                    </div>
                </Layout>
            </>
        );
}

