import React, { useEffect } from 'react';
import './styles.scss';

import logo from '../../assets/images/logo.svg';


export default function SuspendedAccountUser() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadredirectlogin();

    }, []);

    async function loadredirectlogin() {

        localStorage.clear();
        setTimeout(function(){window.location.href = '/';}, 5000);

    }


    return (
        <>
            <div className="box-error conta-suspensa">
                <img src={logo} alt="People View" className="logo"/>
                <h1>Conta suspensa!</h1>
                <p className="texto-um">Ocorreu um problema.</p>
                <p className="texto-dois">Entre em contato com um administrador da conta para que ele acesse as configurações e atualize as informações necessárias.</p>
                <span>© 2021 People View</span>
            </div>

        </>
    );
}
