import React, {useState, useEffect} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/header';
import RealTimePresence from '../../../components/Administrativo/ScoreAdmin/Overview/Real-time-presence';
import NextIcon from "../../../assets/images/arrow-right.svg";
import PrevIcon from "../../../assets/images/arrow-left2.svg";
import { toast } from 'react-toastify';
import SubMenu from '../../../components/Administrativo/ScoreAdmin/subMenu';
import api from '../../../services/api';
import './styles.scss';

export default function ScoreAdmin(props) {

    const [ page, setPage ] = useState(0);
    const [ pages, setPages ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ LIMITPAGE ] = useState(10);
    const [ loadPage, setLoadPage ] = useState(0);
    const [ collaborators, setCollaborators ] = useState('');
    const [ nameFilter, setNameFilter ] = useState('');
    const [ onlyDirectReports, setOnlyDirectReports ] = useState(false);

    const itemRenderPagination = (_, type, originalElement) => {
        if (type === 'prev') {
            return <p> <img src={PrevIcon} alt="" /> anterior</p>;
        }
        if (type === 'next') {
            return <p>  próxima <img src={NextIcon} alt="" /></p>;
        }
        return originalElement;
    };

    useEffect(() => {
        loadcollaborator(LIMITPAGE);
    }, [LIMITPAGE]);

    async function loadcollaborator(LIMITPAGE) {
        try {
            setLoadPage(true);
            const response = await api.get(`/collaborator-new/?status=Ativo&role=admin&role=manager&role=user&sortBy=name&&page=0&limit=${LIMITPAGE}`)
            setCollaborators(response.data);
            setPages(response.data?.pages);
            setPage(response.data?.page);
            setTotal(response.data?.total);
        }catch(error) {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        } finally {
            setLoadPage(false);
        }
    }

    async function loadOnlyDirectReports(event) {
        const name = nameFilter;
        const onlyDirectReports = event;
        const pageNumber = 0;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, name, LIMITPAGE, onlyDirectReports);
    }

    async function checkOnlyDirectReports(event) {
        setOnlyDirectReports(!onlyDirectReports);
        loadOnlyDirectReports(!onlyDirectReports)
    }

    async function loadcollaboratorPage(page, nameFilter, LIMITPAGE, onlyDirectReports) {
        try {
            setLoadPage(true);
            let response = await api.get(`/collaborator-new/?name=${nameFilter}&status=Ativo&role=admin&role=manager&role=user&sortBy=name&page=${page}&limit=${LIMITPAGE}&onlyDirect=${onlyDirectReports}`)
            setCollaborators(response.data);
            setPages(response.data?.pages);
            setPage(response.data?.page);
            setTotal(response.data?.total);
        }catch(error) {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        } finally {
            setLoadPage(false);
        }
    }
    
    async function filterBuscaCol(event) {
        setNameFilter(event);
        let name = event;
        const pageNumber = 0;
        loadcollaboratorPage(pageNumber, name, LIMITPAGE, onlyDirectReports);
    }
    const onChangePagination = (pageValue) => {
        setPage(pageValue - 1);
        const page = pageValue - 1;
        loadcollaboratorPage(page, nameFilter, LIMITPAGE, onlyDirectReports);
    };

    async function paginationPage(page) {
        const pageNumber = page;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, nameFilter, LIMITPAGE, onlyDirectReports);
    }

    let numberPaginate = []
    for(let i=0; i<=pages; i++){
        numberPaginate.push(
            <li className="number"  key={i}>
                <button className={i === page ? "secao" : ""} onClick={() => paginationPage(i)}>{i+1}</button>
            </li>
        )
    }

    async function prevPage(page) {
        if (page === 0) return;
        const pageNumber = page - 1;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, nameFilter, LIMITPAGE, onlyDirectReports);
    }

    async function nextPage(page) {
        if (page === pages) return;
        const pageNumber = page + 1;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, nameFilter, LIMITPAGE, onlyDirectReports);
    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header filterBuscaCol={(event) => filterBuscaCol(event)}  path={props.match.path}/>
                <div className="page-ponto">
                    <SubMenu props={props}/>
                    <div className="visao-geral">
                        <RealTimePresence
                            nameFilter={nameFilter}
                            itemRenderPagination={itemRenderPagination}
                            page={page}
                            pages={pages}
                            total={total}
                            checkOnlyDirectReports={checkOnlyDirectReports}
                            onlyDirectReports={onlyDirectReports}
                            loadPage={loadPage}
                            collaborators={collaborators}
                            onChangePagination={onChangePagination}
                            numberPaginate={numberPaginate}
                            nextPage={nextPage}
                            prevPage={prevPage}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}

