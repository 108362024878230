import React, { useState, useEffect, useRef } from 'react';
import api from '../../../services/api';
import swal from 'sweetalert';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/Ferias/Header';
import ResumoFerias from '../../../components/Administrativo/Ferias/Resumo-ferias';
import SaldoFerias from '../../../components/Administrativo/Ferias/Saldo-ferias';
import AguardandoAprovacao from '../../../components/Administrativo/Ferias/Solicitacaes-andamento';
import HistoricoFerias from '../../../components/Administrativo/Ferias/historico-ferias';
import { dateMask, mVacationDaysSold } from '../../../services/mask'
import { StringToDate } from '../../../services/utils'
import { Scrollbars } from 'react-custom-scrollbars';
import { decodeToken } from '../../../services/auth';
import { toast } from 'react-toastify';
import './styles.scss';



export default function ColVacation(props) {

    const { user } = decodeToken();
    const role     = user.role;

    const roleToManager     = user.role;


    const id_collaborator = user._id;

    const { vacationCollaborator } = props.match.params;
    const [visibleAccountingProcedures, setAccountingProcedures] = useState(false);
    const [accountingInformation, setAccountingInformation] = useState([]);
    const [ nameFilter, setNameFilter ] = useState('');
    const [
        arrInfo,
    ] = useState([]);

    const [ page, setPage ] = useState(0);
    const [ pages, setPages ] = useState(0);
    const [ total, setTotal ] = useState(0);
    const [ sortBy, setSortBy ] = useState('ordem_alfabetica');
    const [ onlyDirectReports, setOnlyDirectReports ] = useState(false);

    function showAccountingProcedures(e) {
        setAccountingInformation(e._id)
        setAccountingProcedures(true);
    }

    function noShowAccountingProcedures() {
        if(
            vacationCollaborator &&
            vacationCollaborator !== undefined
        ) {
            setTimeout(function(){window.location.href = '/ferias/';}, 0);
        }
        else {
            setAccountingProcedures(false);
        }
    }

    const [ collaboratorVacationInfo, setCollaboratorVacationInfo ] = useState([]);
    useEffect(() => {
        if(
            vacationCollaborator &&
            vacationCollaborator !== undefined
        ) {
            loadcollaboratorvacationinfo(vacationCollaborator);
        }
        else
        if(
            accountingInformation &&
            accountingInformation.length !== 0
        ) {
            loadcollaboratoraccountingInformation(accountingInformation);
        }

        // loadcollaboratorvacations();
        loadcollaboratorvacationswithpagination(page, nameFilter, sortBy, role, onlyDirectReports);
        const id_collaborator = user._id;

        loadcollaboratorvacationsapprovedwithpagination(id_collaborator, role);
        loadcollaboratorvacationsrepprovedwithpagination(role);
        loadcollectivevacations(id_collaborator, role);
    }, [page, nameFilter, sortBy, role, roleToManager, vacationCollaborator, accountingInformation, id_collaborator, user._id, onlyDirectReports]);
    useEffect(() => {
        loadvacationawaitingmnanagerapproval(roleToManager)
    }, [roleToManager]);

    async function loadcollaboratorvacationinfo(vacationCollaborator) {
        setAccountingProcedures(true);
        await api.get(`/collaborator-vacation-new/?id=${vacationCollaborator}&type=individual`)
        .then(response => {
            setCollaboratorVacationInfo(response.data.collaboratorVacationNewSearch[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function loadcollaboratoraccountingInformation(accountingInformation) {
        setAccountingProcedures(true);
        await api.get(`/collaborator-vacation-new/?id=${accountingInformation}&type=individual`)
        .then(response => {
            setCollaboratorVacationInfo(response.data.collaboratorVacationNewSearch[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    async function loadvacationawaitingmnanagerapproval(roleToManager) {

        if(roleToManager === 'manager') {

            setLoadVacationAwaitingManagerApproval(true)
            await api.get(`/collaborator-vacation-new-manager/?category=request&status=pendente&completeAndGoToAccounting=false&type=individual`)
            .then(response => {
                setVacationAwaitingManagerApproval(response.data.collaboratorVacationNewSearch);
                setLoadVacationAwaitingManagerApproval(false)
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadVacationAwaitingManagerApproval(false)
            });

            // setLoadVacationAwaitingManagerApproval(true)
            await api.get('/collaborator-vacation-new-manager/?category=request&status=pendente&completeAndGoToAccounting=true&type=individual')
            .then(response => {
                setVacationAwaitingSubmissionToAccounting(response.data.collaboratorVacationNewSearch);
                setLoadVacationAwaitingManagerApproval(false)

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadVacationAwaitingManagerApproval(false)
            });


        }
        else {

            setLoadVacationAwaitingManagerApproval(true)
            await api.get('/collaborator-vacation-new/?category=request&status=pendente&completeAndGoToAccounting=false&type=individual')
            .then(response => {
                setVacationAwaitingManagerApproval(response.data.collaboratorVacationNewSearch);
                setLoadVacationAwaitingManagerApproval(false)
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadVacationAwaitingManagerApproval(false)
            });

            // setLoadVacationAwaitingManagerApproval(true)
            await api.get('/collaborator-vacation-new/?category=request&status=pendente&completeAndGoToAccounting=true&type=individual')
            .then(response => {
                setVacationAwaitingSubmissionToAccounting(response.data.collaboratorVacationNewSearch);
                setLoadVacationAwaitingManagerApproval(false)

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadVacationAwaitingManagerApproval(false)
            });

        }



    }

    const [
        pagesCollective,
        // setPagesCollective
    ] = useState(1);

    const [ load, setLoad ] = useState(false);
    const [ filterCollaboratorVacations, setFilterCollaboratorVacations ] = useState([]);

    async function loadcollaboratorvacationswithpagination(page, nameFilter, sortBy, role, onlyDirectReports) {

        if(role === 'manager') {

            await api.get(`/company-vesting-period-manager/?page=${page}&name=${nameFilter}&sortBy=${sortBy}&onlyDirect=${onlyDirectReports}`)
            .then(response => {
                setFilterCollaboratorVacations(response.data.collaboratorSearch);
                setPages(response.data.pages)
                setTotal(response.data.total)
                setLoad(false)
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoad(false)
            });

        }
        else {

            await api.get(`/company-vesting-period/?page=${page}&name=${nameFilter}&sortBy=${sortBy}`)
            .then(response => {
                setFilterCollaboratorVacations(response.data.companyVestingPeriodSearchGroup);
                setPages(response.data.pages)
                setTotal(response.data.total)
                setLoad(false)
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoad(false)
            });


        }


    }

    async function loadfilterpernameandsector(event) {
        event.preventDefault();
        setLoad(true);
        const name = event.target.value;
        setNameFilter(event.target.value);
        loadcollaboratorvacationswithpagination(page, name, sortBy, onlyDirectReports)
    }

    // => Paginação

    async function prevPage(page) {

        if (page === 0) return;

        const pageNumber = page - 1;
        setPage(pageNumber);

        loadcollaboratorvacationswithpagination(pageNumber, nameFilter, sortBy, onlyDirectReports);

    }

    async function nextPage(page) {

        if (page === pages) return;

        const pageNumber = page + 1;
        setPage(pageNumber);

        loadcollaboratorvacationswithpagination(pageNumber, nameFilter, sortBy, onlyDirectReports);

    }

    async function paginationPage(page) {

        const pageNumber = page;
        setPage(pageNumber);

        loadcollaboratorvacationswithpagination(pageNumber, nameFilter, sortBy, onlyDirectReports);

    }

    // => Histórico
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadHistoryVacations, setLoadHistoryVacations ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationsCompanyApproved, setVacationsCompanyApproved ] = useState([]);

    async function loadcollaboratorvacationsapprovedwithpagination(id_collaborator, roleToManager) {
        setLoadHistoryVacations(true)

        if(roleToManager === 'manager') {

            await api.get(`/collaborator-vacation-new-manager/?category=vacation&status=aprovado&manager=${id_collaborator}&type=individual`)
            .then(response => {
                setVacationsCompanyApproved(response.data.collaboratorVacationNewSearch);
                setLoadHistoryVacations(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadHistoryVacations(false)
            });

        }
        else {

            await api.get('/collaborator-vacation-new/?category=vacation&status=aprovado&type=individual')
            .then(response => {
                setVacationsCompanyApproved(response.data.collaboratorVacationNewSearch);
                setLoadHistoryVacations(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadHistoryVacations(false);
            });

        }
    }
    ///////////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationsCompanyRepproved, setVacationsCompanyRepproved ] = useState([]);

    async function loadcollaboratorvacationsrepprovedwithpagination(roleToManager) {
        setLoadHistoryVacations(true)

        if(roleToManager === 'manager') {

            await api.get(`/collaborator-vacation-new-manager/?category=request&status=reprovado&type=individual`)
            .then(response => {
                setVacationsCompanyRepproved(response.data.collaboratorVacationNewSearch);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }
        else {

            await api.get('/collaborator-vacation-new/?category=request&status=reprovado&type=individual')
            .then(response => {
                setVacationsCompanyRepproved(response.data.collaboratorVacationNewSearch);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

        }


    }

    ///////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collectiveVacations, setCollectiveVacations ] = useState([]);

    // loadCollectiveVacations
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollectiveVacations, setLoadCollectiveVacations ] = useState(false);
    async function loadcollectivevacations(id_collaborator, roleToManager) {

        setLoadCollectiveVacations(true)

        if(roleToManager === 'manager') {


            await api.get(`/collaborator-vacation-new/?category=vacation&status=aprovado&manager=${id_collaborator}&type=coletivas`)
            .then(response => {
                setCollectiveVacations(response.data.collaboratorVacationNewSearch);
                setLoadCollectiveVacations(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadCollectiveVacations(false);
            });

        }
        else {

            await api.get('/collaborator-vacation-new/?category=vacation&status=aprovado&type=coletivas')
            .then(response => {
                setCollectiveVacations(response.data.collaboratorVacationNewSearch);
                setLoadCollectiveVacations(false);
            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                setLoadCollectiveVacations(false);
            });
        }
    }

    //////////////////////////////////////////////////////////////////////////////
    //collaboratorVacationDetails
    const [collaboratorVacationDetailID, setCollaboratorVacationDetailID] = useState([]);

    //collaboratorVacationDetails
    const [collaboratorVacationDetails, setCollaboratorVacationDetails] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleVerDetalhesCol, setVisibleVerDetalhesCol] = useState(false);


    // CollaboratorVestingPeriod
    const [collaboratorVestingPeriod, setCollaboratorVestingPeriod] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadcollaboratorVestingPeriod, setLoadcollaboratorVestingPeriod] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [anoSelecionado, setAnoSelecionado] = useState("");

    function VerDetalhesCol(e) {
        setAnoSelecionado("")

        if(e && e !== undefined) {

            async function CollaboratorVestingPeriodShow() {
                setLoadcollaboratorVestingPeriod(true)
                await api.get(`/collaborator-vesting-period/?collaborator=${e._id}`)
                .then(response => {

                    setLoadcollaboratorVestingPeriod(false);
                    setCollaboratorVestingPeriod(response.data);

                }).catch(error => {
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });

            }
            CollaboratorVestingPeriodShow();
            setCollaboratorVacationDetailID(e.collaborator);

        }
        setVisibleVerDetalhesCol(!visibleVerDetalhesCol);

    }

    //fecharVerDetalhesCol
    function fecharVerDetalhesCol() {
        setVisibleVerDetalhesCol(!visibleVerDetalhesCol);
    }



    /////////////////////////////////////////////////////////////////////////////
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleIniciaFeriasCol, setVisibleIniciaFeriasCol] = useState(false);

   function IniciaFeriasColl(e) {
        if(e && e !== undefined) {
            setCollaboratorVacationDetails(e)
            setCollaboratorVacationDetailID(e.collaborator._id)
            setStartVacationProcess({
                ...startVacationProcess,
                'collaborator': e.collaborator._id,
                'category': 'request',
                'status': 'pendente',
                'vestingPeriodReferenceYear': e.referenceYear,
            })

        }
        setVisibleIniciaFeriasCol(!visibleIniciaFeriasCol);
        setVisibleVerDetalhesCol(false)

   }
   
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [continuarPedidoFeria, setContinuarPedidoFeria] = useState(true);

    //fecharIniciaFeriasColl
    function fecharIniciaFeriasColl() {

        setVisibleIniciaFeriasCol(!visibleIniciaFeriasCol);
        setStartVacationProcess({});
        setLoadInitialDate(false)
        setLoadFinalDate(false)
        setLoadvacationDaysSold(false)
        setLoadComments(false)
        setContinuarPedidoFeria(true)

    }


    //startVacationProcess
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ startVacationProcess, setStartVacationProcess ] = useState([]);


    async function myChangeHandler(event) {
        let nam              = event.target.name;
        let val              = event.target.value;

        let ThirteenthSalary = false;

        if(nam === 'startDate') {

            if(val.length >= 10) {

                setLoadInitialDate(false)
                let startDate = dateMask(val)

                let startDateStartVacationProcess = StringToDate(startDate)
                setStartVacationProcess({ ...startVacationProcess, [nam]: startDate, startDateStartVacationProcess, ThirteenthSalary })

            }
            else {

                let startDate = dateMask(val)
                setStartVacationProcess({ ...startVacationProcess, [nam]: startDate, ThirteenthSalary })

            }

        }
        else
        if(nam === 'endDate') {


            if(val.length >= 10) {

                setLoadFinalDate(false)
                let endDate = dateMask(val)

                let endDateStartVacationProcess = StringToDate(endDate)
                setStartVacationProcess({ ...startVacationProcess, [nam]: endDate, endDateStartVacationProcess })

            }
            else {

                let endDate = dateMask(val)
                setStartVacationProcess({ ...startVacationProcess, [nam]: endDate, ThirteenthSalary })

            }



        }
        //mVacationDaysSold
        else
        if(nam === 'vacationDaysSold') {

            if(val.length >= 1) {

                setLoadvacationDaysSold(false)

            }

            let vacationDaysSold = mVacationDaysSold(val)
            setStartVacationProcess({ ...startVacationProcess, [nam]: vacationDaysSold })

        }
        //mVacationDaysSold
        else
        if(nam === 'comments') {

            setLoadComments(false)
            setStartVacationProcess({ ...startVacationProcess, [nam]: val })

        }
        else {
            setStartVacationProcess({ ...startVacationProcess, [nam]: val })
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSalveInicialFerias, setLoadSalveInicialFerias ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInitialDate, setLoadInitialDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFinalDate, setLoadFinalDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadvacationDaysSold, setLoadvacationDaysSold ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadComments, setLoadComments ] = useState(false);

    async function saveStartVacationProcess(e) {
        e.preventDefault();

        if(
            startVacationProcess?.startDate        === undefined        ||
            startVacationProcess?.startDate        === ""               ||
            startVacationProcess?.startDate        === undefined        ||
            startVacationProcess?.startDate        === ""               ||
            startVacationProcess?.vacationDaysSold === undefined        ||
            startVacationProcess?.vacationDaysSold === ""               ||
            startVacationProcess?.comments         === undefined        ||
            startVacationProcess?.comments         === ""
        ) {

            if(startVacationProcess.startDate === undefined || startVacationProcess.startDate === "") {
                setLoadInitialDate(true)
            }

            if(startVacationProcess.endDate === undefined || startVacationProcess.endDate === "") {
                setLoadFinalDate(true)

            }

            if(startVacationProcess.vacationDaysSold === undefined || startVacationProcess.vacationDaysSold === "") {
                setLoadvacationDaysSold(true)

            }

            if(startVacationProcess.comments === undefined || startVacationProcess.comments === "") {
                setLoadComments(true)

            }
            return
        }

        setLoadSalveInicialFerias(true)
        await api.post('/collaborator-vacation-new/', startVacationProcess)
        .then(response => {

            async function collaboratorVestingPeriodUpdate() {

                await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                    companyId: response.data.companyId,
                    collaborator: response.data.collaborator
                })
                .then(response => {

                    loadcollaboratorvacationswithpagination(page, nameFilter, sortBy, onlyDirectReports)
                    loadvacationawaitingmnanagerapproval();

                    setLoadSalveInicialFerias(false);
                    setStartVacationProcess({});
                    swal({ icon: "success", title: "Sucesso!", text: 'Férias solicitadas com sucesso!' });
                    setVisibleIniciaFeriasCol(!visibleIniciaFeriasCol)

                })
                .catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
                    setLoadSalveInicialFerias(false)
                });


            }
            collaboratorVestingPeriodUpdate()

        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: error?.response?.data?.message });
            setLoadSalveInicialFerias(false)
        });


    }

    /////////////////////////////////////////////////////////////////////////////////////////
    //vacationAwaitingManagerApproval
    //// eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadVacationAwaitingManagerApproval, setLoadVacationAwaitingManagerApproval ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationAwaitingManagerApproval, setVacationAwaitingManagerApproval ] = useState([]);
    //vacationAwaitingSubmissionToAccounting
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ vacationAwaitingSubmissionToAccounting, setVacationAwaitingSubmissionToAccounting ] = useState([]);
    async function deleteRequestsInProgress(e) {

        swal({
            title: "Atenção",
            text: "Você tem certeza que quer remover esta solicitação de férias?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {
                await api.put(`/collaborator-vacation-new/${e}`, { status: 'reprovado' })
                .then((response) => {


                    async function collaboratorVestingPeriodCalculateFisrtBalance() {

                        // => Atualiza todos os saldos dos períodos aquisitivos
                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId   : response.data.companyId,
                            collaborator: response.data.collaborator
                        })
                        .then(response => {

                            loadvacationawaitingmnanagerapproval();
                            // loadvacationawaitingmnanagerapproval2(role);
                            loadcollaboratorvacationswithpagination(page, nameFilter, sortBy, onlyDirectReports)

                            swal({ icon: "success", title: "Sucesso!", text: "Solicitação de férias removida com sucesso." });

                        }).catch(error => {
                            swal({ icon: "error", title: "Erro!", text: "Colaborador ativo, porem os dias de férias dos períodos aquisitivos não foram salvos, entre em contato com o administrador do sistema." });

                        });

                    }
                    collaboratorVestingPeriodCalculateFisrtBalance();
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover esta solicitação de férias, tente novamente mais tarde." });
                });

            }
        });

    }
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////



    //// eslint-disable-next-line react-hooks/rules-of-hooks
    const [ saldoFeriasNB, setSaldoFErias ] = useState(true);

    //// eslint-disable-next-line react-hooks/rules-of-hooks
    const [ solicitacaoEmAdamentoMB, setSolicitacaoEmAdamentoMB ] = useState(false);

    //// eslint-disable-next-line react-hooks/rules-of-hooks
    const [ historicoMB, setHistoricoMB ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const solicitacaoEmAdamentoRef  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const historicoRef  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const saldoFeriasRef  = useRef(null);

    async function solicitacaoAdamentoMB() {
        setSaldoFErias(false);
        setSolicitacaoEmAdamentoMB(true);
        setHistoricoMB(false);
        solicitacaoEmAdamentoRef.current.focus();

    }

    async function saldoFeriaMB() {
        setSaldoFErias(true);
        setSolicitacaoEmAdamentoMB(false);
        setHistoricoMB(false);
        saldoFeriasRef.current.focus();
    }

    async function feriasMB() {
        setSaldoFErias(false);
        setSolicitacaoEmAdamentoMB(false);
        setHistoricoMB(true);
        historicoRef.current.focus();
    }

    //loadBottomSend
    const [loadBottomCompleteVacation, setLoadBottomCompleteVacation] = useState(false);

    //completeVacationRequest
    async function completeVacationRequest() {
        swal({
            title: "Atenção",
            text: "Você tem certeza que quer concluir o processo de férias?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {

                setLoadBottomCompleteVacation(true)
                let status   = 'aprovado';
                let category = 'vacation';

                await api.put(`/collaborator-vacation-new/${collaboratorVacationInfo._id}`, { status, category })
                .then(response => {
                    let companyId    = response.data.companyId;
                    let collaborator = response.data.collaborator;

                    async function collaboratorVestingPeriodCalculateUtilizedBalance() {

                        await api.put('/collaborator-vesting-period-calculate-fisrt-balance/', {
                            companyId,
                            collaborator
                        })
                        .then(response => {
                            loadcollaboratorvacationsapprovedwithpagination(id_collaborator, role)
                            setLoadBottomCompleteVacation(false)
                            setAccountingProcedures(true);
                            noShowAccountingProcedures();
                            swal({ icon: "success", title: "Sucesso!", text: 'Férias aprovadas com sucesso!' });

                        })
                        .catch(error => {
                            swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                            setLoadBottomCompleteVacation(false)
                        });

                    }
                    collaboratorVestingPeriodCalculateUtilizedBalance()


                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadBottomCompleteVacation(false)

                });

            }
        });
    }

    // onlyDirectReports, setOnlyDirectReports
    async function loadOnlyDirectReports(event) {
        setOnlyDirectReports(event)
        const name = nameFilter;

        const onlyDirectReports = event;
        loadcollaboratorvacationswithpagination(page, name, sortBy, role, onlyDirectReports)
    }
    async function checkOnlyDirectReports(event) {
        setOnlyDirectReports(!onlyDirectReports)
        loadOnlyDirectReports(!onlyDirectReports)
    }


    return (
        <>
            <Layout
            path={props.match.path}>
                <div className="pagina-ferias">
                    <Header
                    user={user}
                    role={role}
                    loadCollectiveVacations={loadCollectiveVacations}
                    collectiveVacations={collectiveVacations}
                    />  
                    <div className='resumo-ferias-mobile'>
                        <ResumoFerias
                        role={role}
                        />
                    </div>
                    <div className='menu-mobile'>

                            <Scrollbars>
                                <ul className='scroll-padding'>
                                    <li>
                                        <button ref={saldoFeriasRef}></button>
                                        <button className={saldoFeriasNB === true ? 'ativo' : ''} onClick={saldoFeriaMB}> <span>Saldo de férias</span> </button>
                                    </li>
                                    <li>
                                        <button  className={solicitacaoEmAdamentoMB === true ? 'ativo' : ''} onClick={solicitacaoAdamentoMB}>
                                            <span>Solicitações em andamento</span>
                                        </button>

                                        <button ref={solicitacaoEmAdamentoRef}></button>
                                    </li>
                                    <li>
                                        <button className={historicoMB === true ? 'ativo' : ''}  onClick={feriasMB}> <span>Histórico</span> </button>
                                    </li>

                                    <button ref={historicoRef}></button>

                                </ul>
                            </Scrollbars>
                    </div>
                    <div className='ferias-mobile'>    
                        {
                            role === 'manager' ?
                            <div className='only-direct-reports'>
                                <label className='checkbox' htmlFor="checkbox">
                                    <input type="checkbox" checked={onlyDirectReports} id="checkbox" onChange={checkOnlyDirectReports}/>
                                    <label htmlFor="checkbox"></label>
                                    <p>Somente subordinado diretos</p>
                                </label>
                            </div>
                            :""
                        }
                        {
                            saldoFeriasNB === true ?
                            <>   
                            
                                <SaldoFerias
                            anoSelecionado={anoSelecionado} 
                            setAnoSelecionado={setAnoSelecionado}
                            collaboratorVestingPeriod={collaboratorVestingPeriod}
                            loadcollaboratorVestingPeriod={loadcollaboratorVestingPeriod}

                            nameFilter={nameFilter}
                            onUserInputChange={(event) => loadfilterpernameandsector(event)}
                            filterCollaboratorVacations={filterCollaboratorVacations}

                            nextPage={nextPage}
                            prevPage={prevPage}
                            page={page}
                            pages={pages}
                            total={total}
                            paginationPage={paginationPage}
                            arrInfo={arrInfo}
                            modalMobile={"modalMobile"}

                            api={api}
                            myChangeHandler={myChangeHandler}
                            startVacationProcess={startVacationProcess}
                            saveStartVacationProcess={saveStartVacationProcess}
                            loadSalveInicialFerias={loadSalveInicialFerias}
                            loadInitialDate={loadInitialDate}
                            loadFinalDate={loadFinalDate}
                            loadvacationDaysSold={loadvacationDaysSold}
                            loadComments={loadComments}

                            collaboratorVacationDetails={collaboratorVacationDetails}
                            collaboratorVacationDetailID={collaboratorVacationDetailID}
                            VerDetalhesCol={VerDetalhesCol}
                            fecharVerDetalhesCol={fecharVerDetalhesCol}
                            visibleVerDetalhesCol={visibleVerDetalhesCol}
                            visibleIniciaFeriasCol={visibleIniciaFeriasCol}
                            IniciaFeriasColl={IniciaFeriasColl}
                            fecharIniciaFeriasColl={fecharIniciaFeriasColl}
                            load={load}
                            role={role}
                            user={user}
                            setSortBy={setSortBy}
                            setStartVacationProcess={setStartVacationProcess}
                            continuarPedidoFeria={continuarPedidoFeria}
                            setContinuarPedidoFeria={setContinuarPedidoFeria}
                                />
                            </>
                            :""
                        }
                        {
                            solicitacaoEmAdamentoMB === true ?
                            <>
                                <AguardandoAprovacao
                                loadVacationAwaitingManagerApproval={loadVacationAwaitingManagerApproval}
                                swal={swal}
                                collaboratorVacationInfo={collaboratorVacationInfo}
                                visibleAccountingProcedures={visibleAccountingProcedures}
                                showAccountingProcedures={showAccountingProcedures}
                                noShowAccountingProcedures={noShowAccountingProcedures}
                                vacationAwaitingManagerApproval={vacationAwaitingManagerApproval}
                                vacationAwaitingSubmissionToAccounting={vacationAwaitingSubmissionToAccounting}
                                deleteRequestsInProgress={deleteRequestsInProgress}
                                role={role}
                                loadcollaboratorvacationswithpagination={loadcollaboratorvacationswithpagination}
                                completeVacationRequest={completeVacationRequest}
                                loadBottomCompleteVacation={loadBottomCompleteVacation}
                                />
                            </>
                            :""

                    }
                    {
                        historicoMB === true ?
                        <>
                            <HistoricoFerias
                                loadHistoryVacations={loadHistoryVacations}
                                vacationsCompanyApproved={vacationsCompanyApproved}
                                vacationsCompanyRepproved={vacationsCompanyRepproved}
                                collectiveVacations={collectiveVacations}

                            />
                        </>
                        :""
                    }





                    </div>
                    <div className="page-left">
                        <ResumoFerias
                        api={api}
                        />
                        {
                            role === 'manager' ?
                            <div className='only-direct-reports'>
                                <label className='checkbox' htmlFor="checkbox">
                                    <input type="checkbox" checked={onlyDirectReports} id="checkbox" onChange={checkOnlyDirectReports}/>
                                    <label htmlFor="checkbox"></label>
                                    <p>Somente subordinado diretos</p>
                                </label>
                            </div>
                            :""
                        }
                        <SaldoFerias
                            anoSelecionado={anoSelecionado} 
                            setAnoSelecionado={setAnoSelecionado}

                            collaboratorVestingPeriod={collaboratorVestingPeriod}
                            loadcollaboratorVestingPeriod={loadcollaboratorVestingPeriod}

                            nameFilter={nameFilter}
                            onUserInputChange={(event) => loadfilterpernameandsector(event)}
                            filterCollaboratorVacations={filterCollaboratorVacations}

                            nextPage={nextPage}
                            prevPage={prevPage}
                            page={page}
                            pages={pages}
                            total={total}
                            paginationPage={paginationPage}
                            arrInfo={arrInfo}

                            api={api}
                            myChangeHandler={myChangeHandler}
                            startVacationProcess={startVacationProcess}
                            saveStartVacationProcess={saveStartVacationProcess}
                            loadSalveInicialFerias={loadSalveInicialFerias}
                            loadInitialDate={loadInitialDate}
                            loadFinalDate={loadFinalDate}
                            loadvacationDaysSold={loadvacationDaysSold}
                            loadComments={loadComments}

                            collaboratorVacationDetails={collaboratorVacationDetails}
                            collaboratorVacationDetailID={collaboratorVacationDetailID}
                            VerDetalhesCol={VerDetalhesCol}
                            fecharVerDetalhesCol={fecharVerDetalhesCol}
                            visibleVerDetalhesCol={visibleVerDetalhesCol}
                            visibleIniciaFeriasCol={visibleIniciaFeriasCol}
                            IniciaFeriasColl={IniciaFeriasColl}
                            fecharIniciaFeriasColl={fecharIniciaFeriasColl}
                            load={load}
                            role={role}
                            user={user}
                            setSortBy={setSortBy}
                            setStartVacationProcess={setStartVacationProcess}
                            continuarPedidoFeria={continuarPedidoFeria}
                            setContinuarPedidoFeria={setContinuarPedidoFeria}
                        />
                    </div>

                    <div className="page-right">
                        <AguardandoAprovacao
                        loadVacationAwaitingManagerApproval={loadVacationAwaitingManagerApproval}
                        swal={swal}
                        collaboratorVacationInfo={collaboratorVacationInfo}
                        visibleAccountingProcedures={visibleAccountingProcedures}
                        showAccountingProcedures={showAccountingProcedures}
                        noShowAccountingProcedures={noShowAccountingProcedures}
                        vacationAwaitingManagerApproval={vacationAwaitingManagerApproval}
                        vacationAwaitingSubmissionToAccounting={vacationAwaitingSubmissionToAccounting}
                        deleteRequestsInProgress={deleteRequestsInProgress}
                        role={role}
                        completeVacationRequest={completeVacationRequest}
                        loadBottomCompleteVacation={loadBottomCompleteVacation}
                        />
                        <HistoricoFerias
                        loadHistoryVacations={loadHistoryVacations}
                        vacationsCompanyApproved={vacationsCompanyApproved}
                        vacationsCompanyRepproved={vacationsCompanyRepproved}
                        collectiveVacations={collectiveVacations}
                        pagesCollective={pagesCollective}

                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}

