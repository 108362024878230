import React, { useState, useEffect } from 'react';
import Arrow from '../../../assets/images/arrow.svg';
import EditBlack from '../../../assets/images/edit-black.svg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import { decodeToken } from '../../../services/auth';
import { toast } from 'react-toastify';

import api from '../../../services/api';
const sidBar = () => {

    const decoded = decodeToken();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ verSkill, setVerSkill ] = useState(false);

    function verMinhasSkill() {
        setVerSkill(!verSkill);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ listSkill, setListSkill ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcollaborator();
    }, []);

    async function loadcollaborator() {
        await api.get(`/skill-collaborator-user-show/`)
        .then(response => {
            setListSkill(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    return (
        <>
            <div className="my-data">
                <h2>Meus dados</h2>
                {
                    decoded.user.avatar && decoded.user.avatar !== 'undefined' ?
                    <img alt={decoded.user.name } src={decoded.user.avatar} className="foto-Usuario my-img" />
                    :
                    <div className="semImgCol">
                        <img src={IconUserNone} alt="Usuario sem imagem" />
                    </div>
                }
                <div className="my-infor">
                    <h4>{decoded.user.name }</h4>
                    <span>{decoded.user.sector }</span>

                    <span>Na empresa desde {decoded.user && decoded.user.admissionDate !== undefined ? decoded.user.admissionDate : "00/00/000"  }</span>
                </div>

                {decoded.user.manager && decoded.user.manager!== 'undefined' ?
                <div className="my-Manager">
                    <h4>Gestor</h4>
                    <div className="infor">
                    {
                            decoded.user.manager.avatar && decoded.user.manager.avatar !== 'undefined' ?
                            <img alt={decoded.user.nameManager} src={decoded.user.manager.avatar} className="foto-Usuario img-manager" />
                            :
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="Usuario sem imagem" />
                            </div>
                        }
                        <p>{decoded.user.nameManager}</p>
                    </div>
                </div>
                : ""
                 }


                    <div className="my-skills">
                        <h4>Minhas habilidades</h4>

                        {listSkill && listSkill.length !== 0 ? <>
                            <button className="btnGray btn-mostra-mais" onClick={() => verMinhasSkill()} >Mostrar <img src={Arrow} alt="ver mais" /></button>
                        {
                                    verSkill ?
                                (
                                    <>
                                    <div className="lista">
                                            <ul>
                                                {
                                                listSkill[0] !== undefined ? listSkill
                                                .map((skillLista, index) => (
                                                <>
                                                            <li>{skillLista.skill.name}</li>
                                                </>
                                                )) : (
                                                    <>
                                                    </>
                                                )}

                                        </ul>
                                    </div>
                                    </>
                                )
                                    :
                                    ''
                                }
                                </>:
                                <><p className="nao-tem-habilidade">Você ainda não definiu nenhuma habilidade.</p></>}
                    </div>
                <a href="/meus-dados/pessoal" className="update-my-data">Atualizar meus dados <img src={EditBlack} alt="" /></a>
            </div>

            <div className="company-data">
                <h2>Dados da empresa</h2>
                {
                    decoded.user.companyId?.logo ?
                    <img src={decoded.user.companyId.logo} alt={decoded.user.companyId.name}  className="img-company"/>
                    :""
                }
                <h3>{decoded.user.companyId.name}</h3>
                <span>{decoded.user.companyId.nameDescription}</span>
                {
                    decoded.user.companyId?.cnpj ?
                    <span>CNPJ: {decoded.user.companyId.cnpj && decoded.user.companyId.cnpj ? decoded.user.companyId.cnpj : "-"}</span>   
                    :""
                }
                
                {   
                    decoded.user.companyId?.zipcode ||
                    decoded.user.companyId?.addressCity ||
                    decoded.user.companyId?.address ?
                    <h4>Endereço</h4>
                    :""

                }
                {
                    decoded.user.companyId?.address ?
                    <span>{decoded.user.companyId.address}, {decoded.user.companyId?.addressNumber}</span>
                    :""
                }
                {
                    decoded.user.companyId?.addressCity ?
                    <span>{decoded.user.companyId.addressCity} - {decoded.user.companyId.addressState}</span>
                    :""
                }
                {
                    decoded.user.companyId?.zipcode ?
                    <span>CEP: {decoded.user.companyId.zipcode}</span>
                    :""
                }
                
                
               
                <a href="/contato-com-rh" className="talk-hr">Fale com o RH <img src={EditBlack} alt="Editar" /></a>
            </div>
        </>
    );
}
export default sidBar;
