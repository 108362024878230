import React from 'react';
import SubMenu from './subMenu';
import Address from './AddressesContacts/Address';
import Contact from './AddressesContacts/Contact';
import EmergencyContacts from './AddressesContacts/EmergencyContacts';

// import api from '../../../services/api';


const ColProfilePersonal = ({

    id,
    path,
    collaborator,
    loadPage,
    collaboratorUpdate,
    onCollaboratorInputChange,
    OnSubmit,
    loadContinue,
    PopupAddressVisible,
    visibleAddress,
    PopupContactVisible,
    visibleContact,
    loadAddress,
    filesSavedAddress,
    handleDropAddress,
    deleteFileAddress,
    Loadcellphone,
    Loademail,
    saveContact

}) => {

        return (
            <>
            <SubMenu id={id} collaborator={collaborator}/>
                <div className="boxs-profile-user profile-infor-endereco">
                    <Address
                        id={id}
                        loadPage={loadPage}
                        collaborator={collaborator}
                        collaboratorUpdate={collaboratorUpdate}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        PopupAddressVisible={PopupAddressVisible}
                        visibleAddress={visibleAddress}
                        loadAddress={loadAddress}
                        filesSavedAddress={filesSavedAddress}
                        handleDropAddress={handleDropAddress}
                        deleteFileAddress={deleteFileAddress}

                    />
                    <Contact
                        id={id}
                        loadPage={loadPage}
                        collaborator={collaborator}
                        collaboratorUpdate={collaboratorUpdate}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        PopupContactVisible={PopupContactVisible}
                        visibleContact={visibleContact}
                        Loadcellphone={Loadcellphone}
                        Loademail={Loademail}
                        saveContact={saveContact}
                    />
                    <EmergencyContacts
                        id={id}
                        path={path}
                        loadPage={loadPage}
                        collaboratorSession={collaborator}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                    />
                </div>
            </>
        );
}
export default ColProfilePersonal;
