import React, {
     useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Management/headerFeedBack';
import Questions from '../../../components/Management/Feedbacks/feedbacksCollaborator/questions';
import Comment from '../../../components/Management/Feedbacks/feedbacksCollaborator/comment';

import { NavLink } from 'react-router-dom'
// import IconUserNone from '../../../assets/images/iconUserNone.svg';
import FotoExemploCarol from '../../../assets/images/carolina_martini.jpg';
import '../styles.scss';

export default function homeFeedbackCol(props) {

    const { path } = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModalscheduleFeedback, setVisibleModalScheduleFeedback] = useState(false);

    async function modalScheduleFeedback() {
        setVisibleModalScheduleFeedback(!visibleModalscheduleFeedback);
    }

        return (
            <>
               <Layout path={path}>
                    <Header 
                        path={path} 
                        modalScheduleFeedback={modalScheduleFeedback}
                        visibleModalscheduleFeedback={visibleModalscheduleFeedback}
                    />
                    <div className='pages-management'>
                        <div className='feedback-col'>
                            <div className='infor-geral-coll-box box-leiaute'>
                                <div className='collaborador'>
                                    <div className="foto">
                                        <img alt="" src={FotoExemploCarol}/>
                                    </div>
                                {/* <div className="semImgCol">
                                    <img  src={IconUserNone} alt="Usuario sem imagem" />
                                </div> */}

                                <div className='infor'>
                                    <strong>Anitra Vasconcelos</strong>
                                    <p>Diretora de atendimento</p>
                                </div>

                                </div>
                                <div className='status'>
                                    <span>Status</span>
                                    <p className='realizado'>Realizado</p>
                                    {/* <p className='agendado'>Agendado</p>
                                    <p className='cancelado'>Cancelado</p> */}
                                </div>
                                <div className='data'>
                                    <span>Data e horário</span>
                                    <p>8 Dez 2021 às 10:22</p>
                                </div>
                                <div className='link-reuniao'>
                                    {/*
                                        google-meet
                                        teams
                                        zoom
                                        skype
                                        */}
                                    <span className='skype'>Google Meet</span>

                                    <NavLink to="/gestao/feedback/:id">
                                            https://meet.google.com/otv-ueqf-ruz
                                    </NavLink>  
                                    
                                </div>

                            </div>
                            <div className='check-clim-manag-deman-mot'>
                                <div className="box-leiaute box">
                                    <p>Gestão</p>
                                    <div className='ckeck-list'>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                    </div>
                                </div>
                                <div className="box-leiaute box">
                                    <p>Clima</p>
                                    <div className='ckeck-list'>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                    </div>
                                </div>
                                <div className="box-leiaute box">
                                    <p>Demandas</p>
                                    <div className='ckeck-list'>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                    </div>
                                </div>
                                <div className="box-leiaute box">
                                    <p>Motivação</p>
                                    <div className='ckeck-list'>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel checado"></label></button>
                                        <button class="btn-radio"><label class="chekboxLabel"></label></button>
                                    </div>
                                </div>
                            </div>
                            <div className='box-questions-comment'>
                                <Questions/>
                                <Comment/>

                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        );
}
