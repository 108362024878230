import React from 'react';
// import IconsInformation from '../../../IconsInformation';

import HeaderAdmin from '../../../HeaderAdmin';

export default function headerNovoColaborador({
    history,
    user,
    collaborator
}) {

    return (
        <>


            <HeaderAdmin>
                <div>
                    <h1>Nova admissão {collaborator && collaborator.name? `- ${collaborator.name}` : ""}</h1>
                    <p className="inforColaboraderes">Acompanhe e gerencie o processo de admissão de um colaborador</p>
                </div>
            </HeaderAdmin>
        </>
    );
}
