import React from 'react';
// import Carregamento from '../../../../assets/images/carregando.gif';

export default function pageSystemConfigAddPaymentMethod({
    props,
    path,

    changePageFlatDetail,
    planSelected,
    signatureSelected,
    myChangeHandlerEditPlan,
    paymentMethodEdit,

    loadCardHolderName,
    document_number,
    loadCardNumber,
    loadCardExpirationDate,
    loadCardCVC,
    loadName,
    loadEmail,
    loadZipcode,
    loadNeighborhood,
    loadStreet,
    loadStreetNumber,
    loadTelefone,
    loadComplementary,

    editPlanCompany,
    loadButtonSavePlan,
}) {

    // console.log('signatureSelected')
    // console.log(signatureSelected)

    return (
        <>
        <div className='addEdit-metodo-pagamento'>
            <h3  className="sub-titulo">Altere os detalhes do seu cartão de crédito caso queira mudar a  forma de pagamento</h3>
            <button className='voltar' onClick={changePageFlatDetail}>Voltar para lista</button>

            <div className='form-infor'>


            <div className='infor-plano-seleionado'>
                    <div>
                        <h3>Plano selecionado</h3>
                        <p className='nome-plano'>{
                        signatureSelected?.namePlan === 'BASIC_149900' ?
                        'BASIC'
                        :
                        signatureSelected?.namePlan === 'STARTER_999' ?
                        'STARTER'
                        :
                        signatureSelected?.namePlan === 'PROFESSIONAL' ?
                        'PROFESSIONAL'
                        :
                        signatureSelected?.namePlan === 'ENTERPRISE' ?
                        'ENTERPRISE'
                        :
                        'Outro'
                        }</p>
                    </div>
                    <div>
                        <h3>Valor</h3>
                        <p className='valor'>
                            R$ {signatureSelected?.amountPlan}
                            <span className='porMes'>Por mês</span>
                        </p>
                    </div>
                </div>

                <div className="form-addEdit one">
                    <ul>
                        <li>
                            <div className="texto"><p>Nome do titular *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.card_holder_name ?
                                    signatureSelected?.card_holder_name
                                    :
                                    ''
                                }
                                type="text"
                                name="card_holder_name"
                                placeholder='Nome do titular'
                                className={loadCardHolderName ? 'inputErro' : ''}
                                />
                                {loadCardHolderName ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>CPF ou CNPJ *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                value={
                                    signatureSelected?.document_number ?
                                    signatureSelected?.document_number
                                    :
                                    ''
                                }
                                type="text" name="document_number"
                                placeholder='CPF ou CNPJ'
                                />
                                {document_number ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Número do cartão *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.card_number ?
                                    signatureSelected?.card_number
                                    :
                                    ''
                                }
                                type="text" name="card_number"
                                placeholder='Número do cartão'
                                className={loadCardNumber ? 'inputErro' : ''}
                                />
                                {loadCardNumber ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Data de validade *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.card_expiration_date ?
                                    signatureSelected?.card_expiration_date
                                    :
                                    ''
                                }
                                type="text" name="card_expiration_date"
                                placeholder='Data de validade'
                                className={loadCardExpirationDate ? 'inputErro' : ''}
                                />
                                {loadCardExpirationDate ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Código de segurança *</p></div>
                            <div className="intemB">
                                <input
                                onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected.card_cvv ?
                                    signatureSelected.card_cvv
                                    :
                                    ''
                                }
                                type="text" name="card_cvv"
                                placeholder='CVC'
                                className={loadCardCVC ? 'inputErro' : ''}
                                />
                                {loadCardCVC ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="form-addEdit twon">
                    <h5>Dados de endereço de cobrança do cartão:</h5>
                    <ul>
                        <li>
                            <div className="texto"><p>Nome *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.name ?
                                    signatureSelected?.name
                                    :
                                    ''
                                }
                                type="text" name="name"
                                placeholder='Nome'
                                className={loadName ? 'inputErro' : ''}
                                />
                                {loadName ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>E-mail *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.email ?
                                    signatureSelected?.email
                                    :
                                    ''
                                }
                                type="text" name="email"
                                placeholder='E-mail'
                                className={loadEmail ? 'inputErro' : ''}
                                />
                                {loadEmail ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Telefone *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.ddd ?
                                    signatureSelected?.ddd+signatureSelected?.number
                                    :
                                    ''
                                }
                                type="text" name="phone"
                                placeholder='Telefone'
                                className={loadTelefone ? 'inputErro' : ''}
                                />
                                {loadTelefone ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>CEP *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.zipcode ?
                                    signatureSelected?.zipcode
                                    :
                                    ''
                                }
                                type="text" name="zipcode"
                                placeholder='CEP'
                                className={loadZipcode ? 'inputErro' : ''}
                                />
                                {loadZipcode ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Bairro *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.neighborhood ?
                                    signatureSelected?.neighborhood
                                    :
                                    ''
                                }
                                type="text" name="neighborhood"
                                placeholder='Bairro'
                                className={loadNeighborhood ? 'inputErro' : ''}
                                />
                                {loadNeighborhood ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Rua *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.street ?
                                    signatureSelected?.street
                                    :
                                    ''
                                }
                                type="text" name="street"
                                placeholder='Rua'
                                className={loadStreet ? 'inputErro' : ''}
                                />
                                {loadStreet ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="texto"><p>Numero *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.street_number ?
                                    signatureSelected?.street_number
                                    :
                                    ''}
                                type="text" name="street_number"
                                placeholder='Numero'
                                className={loadStreetNumber ? 'inputErro' : ''}
                                />
                                {loadStreetNumber ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                            </div>
                        </li>
                        {/* <li>
                            <div className="texto"><p>Complemento *</p></div>
                            <div className="intemB">
                                <input
                                disabled={true}
                                // onChange={myChangeHandlerEditPlan}
                                value={
                                    signatureSelected?.complementary ?
                                    signatureSelected?.complementary
                                    :
                                    ''}
                                type="text" name="complementary"
                                placeholder='Complemento'
                                />

                            </div>
                        </li> */}
                        <li>
                            <div className="texto"></div>
                            <div className="intemB">
                                <button onClick={() => editPlanCompany()} className={loadButtonSavePlan ? "btnGreen load" : "btnGreen"}>Assinar People View</button>
                            </div>
                        </li>

                    </ul>
                </div>



            </div>


        </div>

        </>
    );
}
