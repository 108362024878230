import React from 'react';
// import api from '../../../services/api';
// import swal from 'sweetalert';
import { NavLink } from 'react-router-dom'
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/header';
import SubMenu from '../../../components/Administrativo/ScoreAdmin/subMenu';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import FotoCarol from '../../../assets/images/carolina_martini.jpg';
import Carregamento from '../../../assets/images/carregando.gif';
// import IconAddGreen from '../../assets/images/add-green.svg';
// import iconVerMais from '../../assets/images/more.svg';
// import { Dropdown } from "react-bootstrap";
// import ModalGeral from '../../components/modal/modal-geral';
// import iconOrdenation from '../../assets/images/ordenation.svg';
// import { mHora } from '../../services/mask';
// import Dropzone from "react-dropzone";

import './styles.scss';

export default function ScoreAdmin(props) {
    ////////////////////////////////////////////////////////////////////////
    ///////////////////             MODAL Adicionar            ////////////
    //////////////////////////////////////////////////////////////////////

   
    return (
        <>
        


            <Layout path={props.match.path}>
                <Header/>
                <div className="page-ponto">
                    <SubMenu props={props}/>
                    <div className="page-aprovacoes">
                        <h2 className="titulo">Aprovações</h2>
                        <h3 className="subTitulo">Pendências de solicitações feitas por colaboradores</h3>



                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        <ul className='lista-aprovacoes'>
                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>JUSTIFICATIVA</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='justificativa-atestado'>
                                        <p className='infor'>Fui ao médico a partir das 15h</p>
                                        <NavLink to={`/`} className='arquivo'>atestado-medico.pdf</NavLink>
                                        <span className='data-arquivo'>Enviado em 04/03/2022</span>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            
                                            <img alt="" src={FotoCarol} className="foto-colaborador"/>
                                            <div className="nome-data">
                                                <strong className="nome">Carolina Martini Guerini</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>MUDANÇA DE HORÁRIO</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='alteracao-hora'>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Entrada <span>9:40</span></p>

                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Entrada <span>8:02</span></p>
                                            </div>
                                        </div>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Saída <span>18:02</span></p>
                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Saída <span>19:40</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                            <div className="nome-data">
                                                <strong className="nome">Valleir Malta Rocha Ramos</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>JUSTIFICATIVA</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='justificativa-atestado'>
                                        <p className='infor'>Fui ao médico a partir das 15h</p>
                                        <NavLink to={`/`} className='arquivo'>atestado-medico.pdf</NavLink>
                                        <span className='data-arquivo'>Enviado em 04/03/2022</span>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            
                                            <img alt="" src={FotoCarol} className="foto-colaborador"/>
                                            <div className="nome-data">
                                                <strong className="nome">Carolina Martini Guerini</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>MUDANÇA DE HORÁRIO</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='alteracao-hora'>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Entrada <span>9:40</span></p>

                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Entrada <span>8:02</span></p>
                                            </div>
                                        </div>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Saída <span>18:02</span></p>
                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Saída <span>19:40</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                            <div className="nome-data">
                                                <strong className="nome">Valleir Malta Rocha Ramos</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>JUSTIFICATIVA</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='justificativa-atestado'>
                                        <p className='infor'>Fui ao médico a partir das 15h</p>
                                        <NavLink to={`/`} className='arquivo'>atestado-medico.pdf</NavLink>
                                        <span className='data-arquivo'>Enviado em 04/03/2022</span>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            
                                            <img alt="" src={FotoCarol} className="foto-colaborador"/>
                                            <div className="nome-data">
                                                <strong className="nome">Carolina Martini Guerini</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>MUDANÇA DE HORÁRIO</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='alteracao-hora'>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Entrada <span>9:40</span></p>

                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Entrada <span>8:02</span></p>
                                            </div>
                                        </div>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Saída <span>18:02</span></p>
                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Saída <span>19:40</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                            <div className="nome-data">
                                                <strong className="nome">Valleir Malta Rocha Ramos</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>JUSTIFICATIVA</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='justificativa-atestado'>
                                        <p className='infor'>Fui ao médico a partir das 15h</p>
                                        <NavLink to={`/`} className='arquivo'>atestado-medico.pdf</NavLink>
                                        <span className='data-arquivo'>Enviado em 04/03/2022</span>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            
                                            <img alt="" src={FotoCarol} className="foto-colaborador"/>
                                            <div className="nome-data">
                                                <strong className="nome">Carolina Martini Guerini</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className='infor-justificativa'>
                                    <div className='nome-pedencia'>
                                        <h5>MUDANÇA DE HORÁRIO</h5>
                                        <p>Relativo ao dia 22 de Março 2022</p>
                                    </div>
                                    <div className='alteracao-hora'>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Entrada <span>9:40</span></p>

                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Entrada <span>8:02</span></p>
                                            </div>
                                        </div>
                                        <div className='horas'>
                                            <div className='anterior'>
                                                <p className='titulo'>ANTERIOR</p>
                                                <p className='hora'>Saída <span>18:02</span></p>
                                            </div>
                                            <div className='novo'>
                                                <span className='titulo'>NOVO</span>
                                                <p className='hora'>Saída <span>19:40</span></p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='infor-status'>
                                    <div className='infor-col'>
                                            <div className="semImgCol">
                                                <img src={IconUserNone} alt="Usuario sem imagem" />
                                            </div>
                                            <div className="nome-data">
                                                <strong className="nome">Valleir Malta Rocha Ramos</strong>
                                                <p className="data">Solicitado 05.04.2022 às 13:42</p>
                                            </div>
                                    </div>
                                    <div className='status'>
                                        <p>Status: <span>pendente</span></p>
                                    </div>
                                    <div className='btn-aprovacoes'>
                                        <button className='btnGreen load'>Aprovar</button>
                                        <button className='btnGray'>Reprovar</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </Layout>





        </>
    );
}

