import React, { useState, useEffect } from 'react';
//import { NavLink } from 'react-router-dom'
import Dropzone from "react-dropzone";
import api from '../../../../services/api';
//import { formatDate } from '../../../../services/utils';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Carregando from '../../../../assets/images/carregando.gif';
// import InputMask from 'react-input-mask';

const formPersonalData = ({
    id,
    token,
    onCollaboratorInputChange,
    collaborator,
    collaboratorUpdate,
    Loadphone               ,
    Loadcellphone           ,
    LoadaddressNumber       ,
    Loadaddress             ,
    Loadzipcode             ,
    LoadaddressNeighborhood ,
    LoadaddressCity         ,
    LoadaddressState        ,
    focusphone              ,
    focuscellphone          ,
    focusaddressNumber      ,
    focusaddress            ,
    focuszipcode            ,
    focusaddressNeighborhood,
    focusaddressCity        ,
    focusaddressState,
    path
}) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filesSaved, setFilesSaved] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(token && token) {
            async function loadcadastrocolaborador() {
                await api.get(`/collaborator-list/${token}`)
                .then(response => {
                    setCollaboratorSaved(response.data);
                })
                .catch(error => {
                    setCollaboratorSaved(error.response.data);
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                });
            }
            loadcadastrocolaborador();
        }
    }, [token]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    if(id && id !== undefined) {
        async function loadfilessaved() {
            setLoad(true)
            await api.get(`/collaborator-file-new/?id=${id}&category=location`)
            .then(response => {
                setLoad(false)
                setFilesSaved(response.data);
            })
            .catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadfilessaved();
    }
}, [id]);

    async function handleDrop(acceptedFiles) {
        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNames(acceptedFiles.map(file => file.name));

            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {
                toast.info('É necessário selecionar uma arquivo para o envio!');
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('name', acceptedFiles[0].name);
            formData.append('category', 'location');
            formData.append('collaborator', id);
            formData.append('token', token);
            formData.append('path', path);
            setLoad(true)
            await api.post("/collaborator-file-token", formData, config)
            .then(response => {
                async function loadfilessaved() {
                    await api.get(`/collaborator-file-new/?id=${id}&category=location`)
                    .then(response => {
                        setLoad(false)
                        setFilesSaved(response.data);
                    })
                    .catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Arquivo anexado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Arquivo não enviado!" });
                // console.log(error.response.data)
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    //const deleteFile = async (filesaved) => {
async function deleteFile(filesaved) {
    //console.log(filesaved)
    swal({
        title: "Atenção",
        text: "Deseja excluir este arquivo?",
        icon: "warning",
        buttons: ["Cancelar", "OK"],
        dangerMode: false,
    })
    .then(async (res) => {
        if (res) {
            setLoad(true)
            await api.delete(`/collaborator-file-token/${filesaved._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        setLoad(false)
                        await api.get(`/collaborator-file-new/?id=${id}&category=location`)
                        .then(response => {

                            setFilesSaved(response.data);

                        })
                        .catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
        }
    });
}

return (
        <>
        <form className="box-formulario contato-endereco">
        <h4 className="titulo-box">Contato e endereço</h4>

        <h5 className="SubtituloUm">Contato</h5>
        <div className='linha'>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Telefone </p>
                </div>
                <div className='intemB'>

                        {
                        collaboratorUpdate && collaboratorUpdate.phone !== undefined ?
                        (
                            <input ref={focusphone} type="text" name="phone" value={collaboratorUpdate.phone ? collaboratorUpdate.phone : ''} onChange={onCollaboratorInputChange} placeholder="Telefone" className={Loadphone ? 'inputErro' : ''} />
                        ) :
                        collaboratorSaved.phone !== undefined ?
                        (
                            <input ref={focusphone} type="text" name="phone" defaultValue={collaboratorSaved.phone ? collaboratorSaved.phone : ''} onChange={onCollaboratorInputChange} placeholder="Telefone" />
                        ):
                        (
                            <input ref={focusphone} type="text" name="phone" value={collaboratorUpdate.phone ? collaborator.phone : ''} onChange={onCollaboratorInputChange} placeholder="Telefone" className={Loadphone ? 'inputErro' : ''} />
                        )}
                        {Loadphone ? (
                        <p className="textError">O campo é obrigatório.</p>
                        ) : ('')
                        }

                </div>
            </div>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Celular *</p>
                </div>
                <div className='intemB'>
                        {
                        collaboratorUpdate && collaboratorUpdate.cellphone !== undefined ?
                        (<input ref={focuscellphone} type="text" name="cellphone" value={collaboratorUpdate.cellphone ? collaboratorUpdate.cellphone : ''} onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputErro' : ''} />) :
                        collaboratorSaved.cellphone !== undefined ?
                        (<input ref={focuscellphone} type="text" name="cellphone" defaultValue={collaboratorSaved.cellphone ? collaboratorSaved.cellphone : ''} onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputErro' : ''}/>):
                        (<input ref={focuscellphone} type="text" name="cellphone" value={collaborator.cellphone ? collaborator.cellphone : ''} onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputErro' : ''}/>)}
                        {Loadcellphone ? (
                        <p className="textError">O campo é obrigatório.</p>
                        ) : ('')
                        }

                </div>
            </div>
        </div>
        <h5 className="SubtituloDois">Endereço</h5>

        <div className='linha'>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">CEP *</p>
                </div>
                <div className='intemB'>

                    {
                        collaboratorUpdate && collaboratorUpdate.zipcode ?
                        (
                            <input ref={focuszipcode} type="text" autocomplete="autocompleteoff" name="zipcode" value={collaboratorUpdate.zipcode ? collaboratorUpdate.zipcode : ''} onChange={onCollaboratorInputChange} className={Loadzipcode ? 'inputTreis inputErro' : 'inputTreis'} placeholder="CEP"/>
                        ) :
                        collaborator && collaborator.zipcode ?
                        (
                            <input ref={focuszipcode} type="text" autocomplete="autocompleteoff" name="zipcode" defaultValue={collaborator.zipcode ? collaborator.zipcode : ''} onChange={onCollaboratorInputChange} className={Loadzipcode ? 'inputTreis inputErro' : 'inputTreis'} placeholder="CEP"/>
                        ) :
                        (
                            <input ref={focuszipcode} type="text" autocomplete="autocompleteoff" name="zipcode"  onChange={onCollaboratorInputChange} placeholder="CEP" className={Loadzipcode ? 'inputTreis inputErro' : 'inputTreis'} />
                        )
                    }
                    {Loadzipcode ? (
                    <p className="textError inputTreis">O campo é obrigatório.</p>
                    ) : ('')
                    }

                </div>
            </div>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Número</p>
                </div>
                <div className='intemB'>

                    <input ref={focusaddressNumber} type="text" name="addressNumber"  defaultValue={
                        collaboratorUpdate && collaboratorUpdate.addressNumber ?
                        collaboratorUpdate.addressNumber :
                        collaborator && collaborator.addressNumber ?
                        collaborator.addressNumber :
                        ''
                    } onChange={onCollaboratorInputChange} placeholder="Número" className={LoadaddressNumber ? 'inputQuatro inputErro' : 'inputQuatro'} />
                    {LoadaddressNumber ? (
                    <p className="textError inputQuatro">O campo é obrigatório.</p>
                    ) : ('')
                    }

                </div>
            </div>
        </div>
        <div className='linha'>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Rua ou avenida</p>
                </div>
                <div className='intemB'>
                    <input type="text" name="address" defaultValue={
                        collaboratorUpdate && collaboratorUpdate.address ?
                        collaboratorUpdate.address :
                        collaborator && collaborator.address ?
                        collaborator.address
                        : ''
                    } onChange={onCollaboratorInputChange} autocomplete="autocompleteoff" placeholder="Rua ou avenida" className="inputCinco"/>
                </div>
            </div>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Bairro</p>
                </div>
                <div className='intemB'>
                    <input type="text" name="addressNeighborhood" 
                        autocomplete="autocompleteoff"
                        defaultValue={
                        collaboratorUpdate && collaboratorUpdate.addressNeighborhood ?
                        collaboratorUpdate.addressNeighborhood :
                        collaborator && collaborator.addressNeighborhood ?
                        collaborator.addressNeighborhood :
                        ''
                    } onChange={onCollaboratorInputChange} placeholder="Bairro" className="inputSeis"/>
                </div>
            </div>
        </div>
        <div className='linha'>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo textoSete">Estado</p>
                </div>
                <div className='intemB'>
                    <input type="text" name="addressState" autocomplete="autocompleteoff" defaultValue={
                        collaboratorUpdate && collaboratorUpdate.addressState ?
                        collaboratorUpdate.addressState :
                        collaborator && collaborator.addressState ?
                        collaborator.addressState :
                        ''
                    } onChange={onCollaboratorInputChange} placeholder="Estado" className="inputSete"/>
                </div>
            </div>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Cidade</p>
                </div>
                <div className='intemB'>
                    <input type="text" name="addressCity" autocomplete="autocompleteoff" defaultValue={
                        collaboratorUpdate && collaboratorUpdate.addressCity ?
                        collaboratorUpdate.addressCity :
                        collaborator && collaborator.addressCity ?
                        collaborator.addressCity :
                        ''
                    } onChange={onCollaboratorInputChange} placeholder="Cidade" className="inputOito"/>
                </div>
            </div>
        </div>
        <div className='linha'>
            <div className='bloco'>
                <div className='intemA'>
                    <p className="textCampo">Complemento</p>
                </div>
                <div className='intemB'>
                    <input type="text" name="addressComplement" defaultValue={
                        collaboratorUpdate && collaboratorUpdate.addressComplement ?
                        collaboratorUpdate.addressComplement :
                        collaborator && collaborator.addressComplement ?
                        collaborator.addressComplement :
                        ''
                    }autocomplete="autocompleteoff" onChange={onCollaboratorInputChange} placeholder="Complemento" className="inputNove"/>
                </div>
            </div>
        </div>

        </form>
        <div className="dados-pessoais-dropzone">
            <div className="dropzone">
                <Dropzone className="" onDrop={handleDrop}  >
                    {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input type="file" name="file"  {...getInputProps()} />
                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                    </div>
                    )}
                </Dropzone>
            <div className="textoOnze">
                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
            </div>
            </div>
            <div className="arquivoAnexado textoDoze">
                {filesSaved ?
                filesSaved.map((filesaved, index) => (
                    <div key={index} className="arquivo">
                        <p className="nomeArquivo">
                                            <a href={filesaved.location} target="’_blank’">
                                                {filesaved.name}
                                            </a>
                        </p>
                        <button onClick={()=>deleteFile(filesaved)} className="remover">Remover anexo</button>
                    </div>
                    )) : ''}
            </div>
            {load ?
            (<div className="carregando  textoDoze"><img src={Carregando} alt="carregando" /></div>) :
            ('')}
        </div>
        </>
        );
}
export default formPersonalData;
