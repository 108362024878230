import React from 'react';
//import { NavLink } from 'react-router-dom'
import Carregando from '../../../../assets/images/carregando.gif';

//import api from '../../../../services/api';
import { formatDateString } from '../../../../services/utils'
import Dropzone from "react-dropzone";
//import swal from 'sweetalert';
// import InputMask from 'react-input-mask';
//import Carregando from '../../../../assets/images/carregando.gif';
import { Scrollbars } from 'react-custom-scrollbars';

const formPersonalData = ({
    token,
    id,
    collaborator,
    loadAcademicEducation,
    focusSchooling,
    loadSchooling,
    focusCourse,
    loadInstitution,
    focusInstitution,
    loadCourse,
    focusBottomAcademic,
    loadBottomAcademic,
    schooling,
    registeredSchooling,
    fileNamesSchooling,
    onCollaboratorInputChangeSchooling,
    handleDropSchooling,
    deleteFilesSchooling,
    handleSubmitSchooling,
    deleteSchooling,
    conclusionAE
 }) => {
return (
            <>

            <div className="box-formulario academic-education">
                <h4 className="titulo-box">Formação acadêmica</h4>
                
                <div className='linha-academic'>
                    <div className='intemA'>
                        <p className="textCampo">Escolaridade *</p>
                    </div>
                    <div className='intemB'>
                        <div className='cont-select inputUm'>
                            <button className={loadSchooling ? 'inputErro select-' : 'select-'}  ref={focusSchooling}>
                                <p>
                                    {
                                        schooling && schooling.schooling ?
                                            schooling.schooling
                                        :
                                            'Selecione'
                                    }
                                </p>
                            </button>

                            <div className="dropDown">
                                <Scrollbars className='listDropDown'   
                                    style={{  height:  125 }}
                                >
                                    {[
                                            "Analfabeto",
                                            "Até a 5ª série cursando do Ensino Fundamental", "Até a 5ª série incompleta do Ensino Fundamental", "5º ano completo do Ensino Fundamental",
                                            "Do 6º ao 9º ano do Ensino Fundamental incompleto", "Do 6º ao 9º ano do Ensino Fundamental cursando", "Ensino Fundamental completo",
                                            "Ensino Médio incompleto", "Ensino Médio cursando", "Ensino Médio completo",
                                            "Educação Superior incompleta", "Educação Superior cursando", "Educação Superior completa",
                                            "Mestrado incompleto", "Mestrado cursando", "Mestrado completo",
                                            "Doutorado incompleto", "Doutorado cursando", "Doutorado completo",
                                            "Pós Graduação incompleta", "Pós Graduação cursando", "Pós Graduação completa",
                                            "Ensino Técnico cursando", "Ensino Técnico incompleto", "Ensino Técnico completo"
                                        ].map((intem, index) => (
                                            <button  key={index} name="schooling" value={intem}  onClick={onCollaboratorInputChangeSchooling} className='item'>
                                                {intem}
                                            </button>
                                        ))
                                    } 
                                    </Scrollbars>
                            </div>
                        </div>

                        <p className="textError">{loadSchooling ? 'O campo é obrigatório.' : ''} </p>
                    </div>
                </div>
                <div className='linha-academic'>
                    <div className='intemA'>
                        <p className="textCampo textoDois">Curso *</p>
                    </div>
                    <div className='intemB'>
                        <input ref={focusCourse} type="text" name="course" autocomplete="autocompleteoff" value={schooling && schooling.course ? schooling.course : ''} onChange={onCollaboratorInputChangeSchooling} placeholder="Curso" className={loadCourse ? 'inputDois inputErro' : 'inputDois'}/>
                        <p className="inputDois textError">{loadCourse ? 'O campo é obrigatório.' : ''}</p>
                    </div>
                </div>
                <div className='linha-academic'>
                    <div className='intemA'>
                        <p className="textCampo textoTreis">Instituição *</p>
                    </div>
                    <div className='intemB'>
                        <input ref={focusInstitution} type="text" autocomplete="autocompleteoff" name="institution" value={schooling && schooling.institution ? schooling.institution : ''} onChange={onCollaboratorInputChangeSchooling} placeholder="Instituição" className={loadInstitution ? 'inputTreis inputErro' : 'inputTreis'}/>
                        <p className="inputTreis textError">{loadInstitution ? 'O campo é obrigatório.' : ''}</p>
                    </div>
                </div>
                {
                    schooling.schooling === '5º ano completo do Ensino Fundamental' ||
                    schooling.schooling === 'Ensino Fundamental completo' ||
                    schooling.schooling === 'Ensino Médio completo' ||
                    schooling.schooling === 'Educação Superior completa' ||
                    schooling.schooling === 'Mestrado completo' ||
                    schooling.schooling === 'Doutorado completo' ||
                    schooling.schooling === 'Pós Graduação completa' ||
                    schooling.schooling === 'Ensino Técnico completo' ?
                    (
                        <>
                            <div className='linha-academic'>
                                <div className='intemA'>
                                    <p className="textCampo textoQuatro">Ano de conclusão *</p>
                                </div>
                                <div className='intemB'>
                                    <input type="text" name="conclusion" autocomplete="autocompleteoff" value={schooling && schooling.conclusion ? schooling.conclusion : ''} onChange={onCollaboratorInputChangeSchooling} placeholder="Ano de conclusão" className="inputQuatro"/>
                                </div>
                            </div>
                    </>
                    ) :
                    (
                        ''
                    )
                }
                <div className='linha-academic'>
                    <div className='intemA'>
                    </div>
                    <div className='intemB'>
                        <Dropzone className="" onDrop={handleDropSchooling}  >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <input type="file" name="file"  {...getInputProps()} />
                                <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                            </div>
                            )}
                        </Dropzone>
                        <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                    </div>
                </div>

                {fileNamesSchooling[0] !== undefined ?<>
                    <div className='linha-academic'>
                        <div className='intemA'>
                        </div>
                        <div className='intemB'>
                            <div className="arquivoAnexado textoDoze">
                            {fileNamesSchooling ?
                                fileNamesSchooling.map((filename, index) => (
                                <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        {filename}
                                    </p>
                                    <p onClick={deleteFilesSchooling} className="remover">Remover anexo</p>
                                </div>
                                
                            )) : ''}
                            </div>
                        </div>
                    </div>
                    </> :"" 
                }
                <div className='linha-academic'>
                    <div className='intemA'>
                    </div>
                    <div className='intemB'>
                        <button ref={focusBottomAcademic} onClick={handleSubmitSchooling}  type="submit" className="enviar btnGreen">{loadAcademicEducation ? 'Carregando...' : 'Adicionar formação acadêmica'}</button>
                        {loadBottomAcademic ? (
                        <p className="textError inputNove">O campo é obrigatório.</p>
                        ) : ('')
                        }
                    </div>
                </div>
            </div>
            <div className="formacao-adicionado">
                    <h5>Cadastrados</h5>
                    {loadAcademicEducation ?
            (<div className="carregando  textoDoze"><img src={Carregando} alt="carregando" /></div>) :
            registeredSchooling[0] !== undefined ? registeredSchooling
                            .map((schooling, index) => (
                                <div key={index} className="anexado">
                                    <div className="name">
                                        <p>{schooling.schooling}</p>
                                    </div>
                                    <div className="arquivo">
                                    {/* {registeredInternInformation.address !== undefined ?(registeredInternInformation.address) : ('-')} */}
                                        <p>{schooling.originalname !== undefined ? (

                                        <a href={schooling.location} target="’_blank’">
                                        {schooling.originalname}
                                        </a>
                                        ) : (<span>Nenhum anexo</span>)}
                                    </p>
                                    </div>
                                    <div className="dataArquivo">
                                        <span>Cadastrado em {formatDateString(schooling.createdAt)}</span>
                                    </div>
                                    <div className="btnExclui">
                                        <button onClick={() => deleteSchooling(schooling)}>Excluir</button>
                                    </div>
                                </div>
                            )) : (
                                <div className="nenhum-dependente">
                                <p>Nenhuma formação acadêmica cadastrada.</p>
                                </div>
                            )}


                </div>


            </>
        );

}
export default formPersonalData;

