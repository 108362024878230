import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import Header from '../../../components/User/registerDoc/header';
import HeaderSteps from '../../../components/User/registerDoc/headerAdmissionSteps';
import PreInformation from '../../../components/User/registerDoc/PreInformation';
import { FormataStringData } from '../../../services/utils';
import PersonalData from '../../../components/User/registerDoc/personalData/personalData';
import ContactAddress from '../../../components/User/registerDoc/personalData/contactAddress';
import AcademicEducation from '../../../components/User/registerDoc/personalData/AcademicEducation';
import Dependents from '../../../components/User/registerDoc/personalData/dependents';
import EmergencyContact from '../../../components/User/registerDoc/personalData/emergencyContact';
import Courses from '../../../components/User/registerDoc/personalData/Courses';
import ExpiredToken from '../../Error/ExpiredToken'
import InvaliToken from '../../Error/InvaliToken'
import axios from 'axios';
import api from '../../../services/api';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { dateMask, phones, cep, conclusionAE, dateofbirthDependet } from '../../../services/mask';

export default function RegisterDoc(props) {

    const { history } = props;
    const { path }    = props.match;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadContinue, setLoadContinue ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorUpdate, setCollaboratorUpdate ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [coursesStatus, setCoursesStatus] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfBirthUpdate, setDateOfBirthUpdate ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorId, setCollaboratorId ] = useState([]);
    const [ tokenError, setTokenError ] = useState([]);
    const [ stepOne ]   = useState(true);
    const [ stepTwo ]   = useState(false);
    const { token } = props.match.params;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(token && token){
            loadcadastrocolaborador(token);
        }
    }, [token]);

    async function loadcadastrocolaborador(token) {

        await api.get(`/collaborator-list/${token}`)
        .then(response => {

            setCollaborator(response.data);
            setCollaboratorId(response.data._id);
        })
        .catch(error => {
            setTokenError(error.response.data);
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });

        });
    }

    async function myChangeHandler(event) {
        let nam = event.target.name;
        var val = event.target.value;

        if (nam === "zipcode") {
            if(val.length >= 8) {
                Loadsetzipcode(false)
            }
            getAddress(val)
        }
        else if (nam === "dateOfBirth") {

            let date = dateMask(val)
            if(date.length <= 10) {

                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: date })
                if(date.length === 10) {
                    let valDate = FormataStringData(date);
                    LoadsetdateOfBirth(false)
                    setDateOfBirthUpdate({ ...dateOfBirthUpdate, [nam]: valDate })
                }
            }

        }
        else if (nam === "gender") {

            if(val === 'Selecione') {
                let valgender = undefined;
                Loadsetgender(true)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valgender })
            } else {
                Loadsetgender(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }

        }
        //fatherName
        else if (nam === "fatherName") {
            LoadsetfatherName(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }
        //motherName
        else if (nam === "motherName") {
            LoadsetmotherName(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }
        //maritalStatus
        else if (nam === "maritalStatus") {

            if(val === 'Selecione') {
                let valmaritalStatus = undefined;
                LoadsetmaritalStatus(true)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valmaritalStatus })
            } else {
                LoadsetmaritalStatus(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }

        }
        //nationality
        else if (nam === "nationality") {

            if(val === 'Selecione') {
                let valnationality = undefined;
                Loadsetnationality(true)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valnationality })
            } else {
                Loadsetnationality(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }

        }
        //ethnicity
        else if (nam === "ethnicity") {

            if(val === 'Selecione') {
                let valethnicity = undefined;
                Loadsetethnicity(true)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valethnicity })
            } else {
                Loadsetethnicity(false)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
            }

        }
        //otherNationality
        else if (nam === "otherNationality") {
            LoadsetotherNationality(false)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })

        }
        //bornState
        else if (nam === "bornState") {

            if(val === 'Selecione') {
                let valbornState = undefined;
                LoadsetbornState(true)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valbornState })
            } else {

                LoadsetbornState(false)
                let estado = val;

                await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}`)
                .then(response => {
                    let bornStateString = response.data.nome;
                    setCollaboratorUpdate({ ...collaboratorUpdate, bornStateString, [nam]: val })
                }).catch(error => {});

            }

        }
        //bornCity
        else if (nam === "bornCity") {

            if(val === 'Selecione') {
                let valbornCity = undefined;
                LoadsetbornCity(true)
                setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: valbornCity })
            } else {

               LoadsetbornCity(false)
                let cidade = val;

                if(cidade === '350000000') {

                    // alert('são paulo')
                    let bornCityString = 'São Paulo';

                    let nam = 'bornCity';
                    let val = '350000000';

                    setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })

                }
                else
                if(collaboratorUpdate.bornState === '35' && cidade === '350000000') {

                    // alert('são paulo')
                    let bornCityString = 'São Paulo';

                    let nam = 'bornCity';
                    let val = '350000000';

                    setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })

                }
                else {

                    async function loadCity() {
                        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${cidade}?orderBy=nome`)
                        .then(response => {
                            let bornCityString = response.data[0].nome;
                            setCollaboratorUpdate({ ...collaboratorUpdate, bornCityString, [nam]: val })
                        }).catch(error => {});

                    }
                    loadCity();

                }

            }

        }
        //phone
        else if (nam === "phone") {
            if(val.length >= 14) {
                Loadsetphone(false)
            }
            let telefone = phones(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: telefone })

        }
        //cellphone
        else if (nam === "cellphone") {
            if(val.length >= 14) {
                Loadsetcellphone(false)
            }
            let celular = phones(val)
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: celular })

        }
        else if (nam === "stepTwoToken") {
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }
        else {
            setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val })
        }
    };


     // eslint-disable-next-line no-undef
     async function getAddress(zipcode) {

        if (zipcode.length === 8 || zipcode.length === 9) {
            zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
            let cepppp = cep(zipcode)

            if(zipcode.length === 8 || zipcode.length === 9) {
                const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

                if (response.data !== null) {
                    setCollaboratorUpdate({
                        ...collaboratorUpdate,
                        zipcode: cepppp,
                        address: response.data.logradouro,
                        addressNeighborhood : response.data.bairro,
                        addressCity: response.data.localidade,
                        addressState: response.data.uf,
                    })
                }

            }
            else {
                let cepppp = cep(zipcode)
                setCollaboratorUpdate({
                    ...collaboratorUpdate,
                    zipcode: cepppp,
                })
            }

        } else {

            let cepppp = cep(zipcode)
            setCollaboratorUpdate({
                ...collaboratorUpdate,
                zipcode: cepppp,
            })
        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ LoaddateOfBirth            , LoadsetdateOfBirth                ] = useState(false);
    const [ Loadgender                 , Loadsetgender                     ] = useState(false);
    const [ LoadfatherName             , LoadsetfatherName                 ] = useState(false);
    const [ LoadmotherName             , LoadsetmotherName                 ] = useState(false);
    const [ LoadmaritalStatus          , LoadsetmaritalStatus              ] = useState(false);
    const [ Loadnationality            , Loadsetnationality                ] = useState(false);
    const [ LoadotherNationality       , LoadsetotherNationality           ] = useState(false);
    const [ Loadethnicity              , Loadsetethnicity                  ] = useState(false);
    const [ LoadbornState              , LoadsetbornState                  ] = useState(false);
    const [ LoadbornCity               , LoadsetbornCity                   ] = useState(false);
    const [ LoadaddressNumber          ,               ] = useState(false);
    const [ Loadaddress                ,                    ] = useState(false);
    const [ Loadphone                  , Loadsetphone                      ] = useState(false);
    const [ Loadcellphone              , Loadsetcellphone                  ] = useState(false);
    const [ Loadzipcode                , Loadsetzipcode                    ] = useState(false);
    const [ LoadaddressNeighborhood    , LoadsetaddressNeighborhood        ] = useState(false);
    const [ LoadaddressCity            , LoadsetaddressCity                ] = useState(false);
    const [ LoadaddressState           , LoadsetaddressState               ] = useState(false);
    const [ loadBottomAcademic         , setloadBottomAcademic             ] = useState(false);
    const [ loadBottomDependent        , setloadBottomDependent            ] = useState(false);
    const [ loadBottomEmergencyContact , setloadBottomEmergencyContact     ] = useState(false);
    // const [ loadHaveDependent          , setloadHaveDependent              ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusDateOfBirth            = useRef(null);
    const focusgender                 = useRef(null);
    const focusfatherName             = useRef(null);
    const focusmotherName             = useRef(null);
    const focusmaritalStatus          = useRef(null);
    const focusnationality            = useRef(null);
    const focusotherNationality       = useRef(null);
    const focusethnicity              = useRef(null);
    const focusbornState              = useRef(null);
    const focusbornCity               = useRef(null);
    const focusaddressNumber          = useRef(null);
    const focusaddress                = useRef(null);
    const focusphone                  = useRef(null);
    const focuscellphone              = useRef(null);
    const focuszipcode                = useRef(null);
    const focusaddressNeighborhood    = useRef(null);
    const focusaddressCity            = useRef(null);
    const focusaddressState           = useRef(null);
    const focusSchooling              = useRef(null);
    const focusCourse                 = useRef(null);
    const focusInstitution            = useRef(null);
    const focusBottomAcademic         = useRef(null);
    const focusNameDependent          = useRef(null);
    const focusRelationshipDependent  = useRef(null);
    const focusDateofBirthDependent   = useRef(null);
    const focusSchoolingDependente    = useRef(null);
    const focusBottomDependent        = useRef(null);
    const focusEmergencyName          = useRef(null);
    const focusRelationshipEmergency  = useRef(null);
    const focusEmergencyCellphone     = useRef(null);
    const focusBottomEmergencyContact = useRef(null);

    //setLoadAvatar
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ]           = useState(false);
    const [ loadAvatarVazio , setLoadAvatarVazio ] = useState(false);
    const focusAvatar                              = useRef(null);

    async function save(event) {
        event.preventDefault();
    
        const formData     = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if(
            collaboratorUpdate                                     &&
            collaboratorUpdate.dateOfBirth         !== undefined   &&
            collaboratorUpdate.dateOfBirth.length  === 10          &&
            dateOfBirthUpdate.dateOfBirth          !== undefined
        ) {
            LoadsetdateOfBirth(false)
            formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
        }
        else if(
            collaborator                     &&
            collaborator.dateOfBirth         !== undefined
        ) {
            LoadsetdateOfBirth(false);
        }
        else {
            LoadsetdateOfBirth(true);
        }

        if(collaboratorUpdate.gender !== undefined) {
            Loadsetgender(false)
            formData.append('gender', collaboratorUpdate.gender);
        }
        else if(
            collaborator                     &&
            collaborator.gender         !== undefined
        ) {
            Loadsetgender(false)
        }
        else {
            Loadsetgender(true)
        }

       if(collaboratorUpdate.fatherName !== undefined && collaboratorUpdate.fatherName !== "") {
            LoadsetfatherName(false)
            formData.append('fatherName', collaboratorUpdate.fatherName);
        }
        else  if(
            collaborator                     &&
            collaborator.fatherName         !== undefined
        ) {
            LoadsetfatherName(false)
        } else {
            LoadsetfatherName(true)
        }

        if(collaboratorUpdate.motherName !== undefined && collaboratorUpdate.motherName !== "") {
            LoadsetmotherName(false)
            formData.append('motherName', collaboratorUpdate.motherName);
        }
        else if(
            collaborator                     &&
            collaborator.motherName         !== undefined
        ) {
            LoadsetmotherName(false)
        } else {
            LoadsetmotherName(true)
        }

        if(collaboratorUpdate.maritalStatus !== undefined) {
            LoadsetmaritalStatus(false)
            formData.append('maritalStatus', collaboratorUpdate.maritalStatus);
        }
        else if(
            collaborator                     &&
            collaborator.maritalStatus       !== undefined
        ) {
            LoadsetmaritalStatus(false)
        }
        else {
            LoadsetmaritalStatus(true)
        }
        
        if(collaboratorUpdate.nationality !== undefined) {
            Loadsetnationality(false)
            formData.append('nationality', collaboratorUpdate.nationality);

        }
        else if(
            collaborator                     &&
            collaborator.nationality         !== undefined
        ) {
            Loadsetnationality(false)
        }
        else {
            Loadsetnationality(true)
        }

        if(collaboratorUpdate.ethnicity !== undefined) {
            Loadsetethnicity(false)
            formData.append('ethnicity', collaboratorUpdate.ethnicity);
        }
        else if(
            collaborator                     &&
            collaborator.ethnicity         !== undefined
        ) {
            Loadsetethnicity(false)
        }
        else{
            Loadsetethnicity(true)
        }

        if(collaboratorUpdate.nationality === 'Brasil' || collaborator.nationality === 'Brasil') {

            if(
                collaboratorUpdate?.bornState !== undefined &&
                collaboratorUpdate?.bornState !== ""
            ) {
                LoadsetbornState(false)
                formData.append('bornState', collaboratorUpdate.bornState);
                formData.append('bornStateString', collaboratorUpdate.bornStateString);
            }
            else if(
                collaborator                     &&
                collaborator?.bornState         !== undefined &&
                collaborator?.bornState         !== ""
            ) {
                LoadsetbornState(false)
            } else {
                LoadsetbornState(true)
            }

            if(
                collaboratorUpdate.bornCity !== undefined && 
                collaboratorUpdate.bornCity !== ""
            ) {
                LoadsetbornCity(false)
                formData.append('bornCity', collaboratorUpdate.bornCity);
                formData.append('bornCityString', collaboratorUpdate.bornCityString);
            }
            else if(
                collaborator                     &&
                collaborator?.bornCity         !== undefined &&
                collaborator?.bornState         !== ""
            ) {
                LoadsetbornCity(false)
            } else {
                LoadsetbornCity(true)
            }

        }else {

            if(
                collaboratorUpdate?.bornStateString !== undefined &&
                collaboratorUpdate?.bornStateString !== ""
            ) {
                LoadsetbornState(false)
                formData.append('bornState', undefined);
                formData.append('bornStateString', collaboratorUpdate.bornStateString);
            }
            else if(
                collaborator                     &&
                collaborator?.bornStateString         !== undefined &&
                collaborator?.bornStateString         !== ""
            ) {
                LoadsetbornState(false)
            } else {
                LoadsetbornState(true)
            }
            if(
                collaboratorUpdate.bornCityString !== undefined  && 
                collaboratorUpdate.bornCityString !== ""
            ) {
                LoadsetbornCity(false)
                formData.append('bornCity', undefined);
                formData.append('bornCityString', collaboratorUpdate.bornCityString);
            }
            else if(
                collaborator                     &&
                collaborator?.bornCityString         !== undefined &&
                collaborator?.bornCityString         !== ""
            ) {
                LoadsetbornCity(false)
            } else {
                LoadsetbornCity(true)
            }

        }

        if(collaboratorUpdate.phone !== undefined && collaboratorUpdate.phone !== "") {
            Loadsetphone(false)
            formData.append('phone', collaboratorUpdate.phone);
        }
        
       if(collaboratorUpdate.cellphone !== undefined && collaboratorUpdate.cellphone !== "") {
            Loadsetcellphone(false)
            formData.append('cellphone', collaboratorUpdate.cellphone);
        }
        else if(
            collaborator                     &&
            collaborator.cellphone         !== undefined
        ) {
            Loadsetcellphone(false)
        } else  {
            Loadsetcellphone(true)
        }

        if(collaboratorUpdate.address !== undefined) {
            formData.append('address', collaboratorUpdate.address);
        }

        if(collaboratorUpdate.zipcode !== undefined && collaboratorUpdate.zipcode !== "") {
            Loadsetzipcode(false)
            formData.append('zipcode', collaboratorUpdate.zipcode);
        }
        else if(
            collaborator                     &&
            collaborator.zipcode         !== undefined
        ) {
            Loadsetzipcode(false)
        } else {
            Loadsetzipcode(true)
        }

        if(collaboratorUpdate.addressNumber !== undefined && collaboratorUpdate.addressNumber !== "") {
            formData.append('addressNumber', collaboratorUpdate.addressNumber);
        }

        if(collaboratorUpdate.addressNeighborhood !== undefined) {
            LoadsetaddressNeighborhood(false)
            formData.append('addressNeighborhood', collaboratorUpdate.addressNeighborhood);
        }

        if(collaboratorUpdate.addressCity !== undefined) {
            LoadsetaddressCity(false)
            formData.append('addressCity', collaboratorUpdate.addressCity);
        }

        if(collaboratorUpdate.addressState !== undefined) {
            LoadsetaddressState(false)
            formData.append('addressState', collaboratorUpdate.addressState);
        }

        if(collaboratorUpdate.addressComplement !== undefined) {
            formData.append('addressComplement', collaboratorUpdate.addressComplement);
        }
        
        if(registeredEmergencyContact && registeredEmergencyContact[0] !== undefined) {

            setLoademErgencyName(false)
            setLoadRelationshipEmergencyContact(false)
            setLoadEmergencyCellphone(false)
            setloadBottomEmergencyContact(false)
            setLoadContinue(false)
            setLoad(false)
            
        }
        else {

            if (emergencyContact.emergencyName === undefined || emergencyContact.emergencyName === "") {
                focusEmergencyName.current.focus();
                setLoademErgencyName(true)
            } else {
                setLoademErgencyName(false)
            }

            if(emergencyContact.relationship === 'Selecione') {

                focusRelationshipEmergency.current.focus();
                setLoadRelationshipEmergencyContact(true)
                
            } else {

                if (emergencyContact.relationship === undefined || emergencyContact.relationship === "") {

                    focusRelationshipEmergency.current.focus();
                    setLoadRelationshipEmergencyContact(true)
                    
                } else {
                    setLoadRelationshipEmergencyContact(false)
                }

            }

            if (emergencyContact.emergencyCellphone === undefined || emergencyContact.emergencyCellphone === "") {
                focusEmergencyCellphone.current.focus();
                setLoadEmergencyCellphone(true)
            } else {
                setLoadEmergencyCellphone(false)
            }

            if(registeredEmergencyContact && registeredEmergencyContact[0] === undefined) {
                setloadBottomEmergencyContact(true)
                setLoadContinue(false)
                setLoad(false)
                focusBottomEmergencyContact.current.focus();
            }

        }

        if(registeredDependents && registeredDependents[0] !== undefined) {

            setLoadNameDependent(false);
            setLoadRelationship(false);
            setLoadDateOfBirth(false);
            setLoadSchoolingDependent(false);
            setloadBottomDependent(false)
            setLoadContinue(false)
            setLoad(false)

        }
        else {

            if(dependents && dependents.noDependents === 'false') {
                if (dependents.name !== undefined && dependents.name !== "") {
                    setLoadNameDependent(false)
                } else {
                    setLoadNameDependent(true)
                    focusNameDependent.current.focus();
                }

                if(dependents.relationship === 'Selecione') {

                    setLoadRelationship(true)
                    focusRelationshipDependent.current.focus();

                } else {

                    if (dependents.relationship !== undefined && dependents.relationship !== "") {
                        setLoadRelationship(false)
                    } else {
                        setLoadRelationship(true)
                        focusRelationshipDependent.current.focus();
                    }
                }

                if (dependents.dateOfBirth !== undefined && dependents.dateOfBirth !== "") {
                    setLoadDateOfBirth(false)
                } else {
                    setLoadDateOfBirth(true)
                    focusDateofBirthDependent.current.focus();
                }


                if(dependents.schooling === 'Selecione') {

                    setLoadSchoolingDependent(true)
                    focusSchoolingDependente.current.focus();

                } else {

                    if (dependents.schooling !== undefined && dependents.schooling !== "") {
                        setLoadSchoolingDependent(false)
                    } else {
                        setLoadSchoolingDependent(true)
                        focusSchoolingDependente.current.focus();
                    }

                }

                if(registeredDependents && registeredDependents[0] === undefined) {
                    focusBottomDependent.current.focus();
                    setloadBottomDependent(true)
                    setLoadContinue(false)
                    setLoad(false)
                    // return
                }
            }

        }


        if(registeredSchooling && registeredSchooling[0] !== undefined) {

            setLoadSchooling(false);
            setLoadInstitution(false);
            setLoadCourse(false);
            setloadBottomAcademic(false)
            setLoadContinue(false)
            setLoad(false)

        }
        else {

            if (schooling.schooling !== undefined && schooling.schooling !== "") {
                setLoadSchooling(false)
            } else {
                setLoadSchooling(true)
                focusSchooling.current.focus();
            }

            if (schooling.course !== undefined && schooling.course !== "") {
                setLoadCourse(false)
            } else {
                setLoadCourse(true)
                focusCourse.current.focus();
            }

            if (schooling.institution !== undefined && schooling.institution !== "") {
                setLoadInstitution(false)
            } else {
                setLoadInstitution(true)
                focusInstitution.current.focus();
            }

            if(registeredSchooling && registeredSchooling[0] === undefined) {
                focusBottomAcademic.current.focus();
                setloadBottomAcademic(true)
                setLoadContinue(false)
                setLoad(false)
            }
        }

        if (
            collaboratorUpdate.dateOfBirth   === undefined && collaborator.dateOfBirth   === undefined 
        ) { focusDateOfBirth.current.focus(); return }
        else
        if (
            collaboratorUpdate.gender   === undefined && collaborator.gender   === undefined 
        ) { focusgender.current.focus(); return }
        else
        if (
            collaboratorUpdate.fatherName   === undefined && collaborator.fatherName   === undefined 
        ) { focusfatherName.current.focus(); return }
        else
        if (
            collaboratorUpdate.motherName   === undefined && collaborator.motherName   === undefined 
        ) { focusmotherName.current.focus(); return }
        else
        if (
            collaboratorUpdate.maritalStatus   === undefined && collaborator.maritalStatus   === undefined 
        ) { focusmaritalStatus.current.focus(); return }
        else
        if (
            collaboratorUpdate.nationality   === undefined && collaborator.nationality   === undefined 
        ) { focusnationality.current.focus(); return }
        else
        if (
            collaboratorUpdate.ethnicity   === undefined && collaborator.ethnicity   === undefined 
        ) { focusethnicity.current.focus(); return }
        else
        if( 
            collaboratorUpdate.bornCity === undefined &&
            collaborator.bornCity === undefined
        ) {
                focusbornCity.current.focus();
                LoadsetbornCity(true)
                return

        }else
        if( 
            collaboratorUpdate.bornCityString === "" &&
            collaborator.bornCityString === ""
        ) {
                focusbornCity.current.focus();
                LoadsetbornCity(true)
                 return

        }else
        if( 
            collaboratorUpdate.nationality === "Brsil" &&
            collaboratorUpdate.bornCity === "" &&
            collaboratorUpdate.bornState === "" 
        ) {

            if (
                collaboratorUpdate.bornCity   === "" && collaborator.bornCity   === ""
            ) { focusbornState.current.focus(); return }
            else

            if (
                collaboratorUpdate.bornCity   === "" && collaborator.bornCity   === ""
            ) { focusbornCity.current.focus(); return }

        }else if (
            collaboratorUpdate.nationality !== "Brsil" &&
            collaboratorUpdate.bornCityString === "" &&
            collaboratorUpdate.bornStateString === "" 
        ) {

            if (
                collaboratorUpdate.bornStateString   === "" && collaborator.bornStateString   === "" 
            ) { focusbornState.current.focus(); return }
            else
            if (
                collaboratorUpdate.bornCityString   === "" && collaborator.bornCityString   === "" 
            ) { focusbornCity.current.focus(); return }
            
        } else
        if (
            collaboratorUpdate.cellphone   === undefined && collaborator.cellphone   === undefined 
        ) { focuscellphone.current.focus(); return }
        else
        if (
            collaboratorUpdate.zipcode   === undefined && collaborator.zipcode   === undefined 
        ) { focuszipcode.current.focus(); return }
        else
        if (
            registeredSchooling && registeredSchooling[0]  === undefined
        ) { focusSchooling.current.focus(); return}
        else
        if (
            registeredEmergencyContact && registeredEmergencyContact[0] === undefined
        ) { 
            focusEmergencyName.current.focus(); return 
        }
         else {
                formData.append('path', path);

                await api.put(`/collaborator-update/${token}`, formData, config)
                .then(response => {
                    async function loadcollaborator() {
                        await api.get(`/collaborator-list/${token}`)
                        .then(response => {

                            if(collaboratorUpdate.stepTwoToken !== undefined && collaboratorUpdate.stepTwoToken === "true") {
                                setLoadContinue(false)
                            } else {
                                setLoad(false)
                            }
                            setCollaborator(response.data);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadcollaborator();
                    // swal({ icon: "success", title: "Sucesso!", text: "Colaborador alterado com sucesso!" });
                    if(collaboratorUpdate.stepTwoToken !== undefined && collaboratorUpdate.stepTwoToken === "true") {
                        //window.location.href = `/cadastro-colaborador/documentos/${token}`;
                        setTimeout(function(){history.push(`/cadastro-colaborador/documentos/${token}`);}, 0);
                    }

                }).catch(error => {
                    setLoadContinue(true)
                    setLoad(true)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoad(false)
                });

                    
            } 
    
    }




    async function saveFillInLater(event) {
        event.preventDefault();
        if (
            dateOfBirthUpdate.dateOfBirth          !== undefined ||
            collaboratorUpdate.gender              !== undefined ||
            collaboratorUpdate.fatherName          !== undefined ||
            collaboratorUpdate.motherName          !== undefined ||
            collaboratorUpdate.maritalStatus       !== undefined ||
            collaboratorUpdate.nationality         !== undefined ||
            collaboratorUpdate.otherNationality    !== undefined ||
            collaboratorUpdate.ethnicity           !== undefined ||
            collaboratorUpdate.bornState           !== undefined ||
            collaboratorUpdate.bornStateString     !== undefined ||
            collaboratorUpdate.bornCity            !== undefined ||
            collaboratorUpdate.bornCityString      !== undefined ||
            collaboratorUpdate.addressNumber       !== undefined ||
            collaboratorUpdate.address             !== undefined ||
            collaboratorUpdate.phone               !== undefined ||
            collaboratorUpdate.cellphone           !== undefined ||
            collaboratorUpdate.zipcode             !== undefined ||
            collaboratorUpdate.addressNeighborhood !== undefined ||
            collaboratorUpdate.addressCity         !== undefined ||
            collaboratorUpdate.addressState        !== undefined ||
            collaboratorUpdate.addressComplement   !== undefined ||
            coursesStatus.length             !==      0    ||
            registeredSchooling.length             !==      0    ||
            registeredEmergencyContact.length      !==      0
        ) {
            const formData     = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            setLoad(true)

            // formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
            if(
                collaboratorUpdate                                     &&
                collaboratorUpdate.dateOfBirth         !== undefined   &&
                collaboratorUpdate.dateOfBirth.length  === 10          &&
                dateOfBirthUpdate.dateOfBirth          !== undefined
            ) {
                LoadsetdateOfBirth(false)
                formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
            }

            if(collaboratorUpdate.gender !== undefined) {
                Loadsetgender(false)
                formData.append('gender', collaboratorUpdate.gender);
            }


            if(collaboratorUpdate.fatherName !== undefined && collaboratorUpdate.fatherName !== "") {
                LoadsetfatherName(false)
                formData.append('fatherName', collaboratorUpdate.fatherName);
            }


            if(collaboratorUpdate.motherName !== undefined && collaboratorUpdate.motherName !== "") {
                LoadsetmotherName(false)
                formData.append('motherName', collaboratorUpdate.motherName);
            }

            if(collaboratorUpdate.maritalStatus !== undefined) {
                LoadsetmaritalStatus(false)
                formData.append('maritalStatus', collaboratorUpdate.maritalStatus);
            }

            if(collaboratorUpdate.nationality !== undefined) {
                Loadsetnationality(false)
                formData.append('nationality', collaboratorUpdate.nationality);
            }

            if(collaboratorUpdate.bornStateString !== undefined) {
                LoadsetbornState(false)
                formData.append('bornStateString', collaboratorUpdate.bornStateString);
            }

            if(collaboratorUpdate.otherNationality !== undefined) {
                LoadsetotherNationality(false)
                formData.append('otherNationality', collaboratorUpdate.otherNationality);
            }

            if(collaboratorUpdate.bornCityString !== undefined) {
                LoadsetbornCity(false)
                // formData.append('bornCity', undefined);
                formData.append('bornCityString', collaboratorUpdate.bornCityString);
            }

            if(collaboratorUpdate.bornState !== undefined) {
                LoadsetbornState(false)
                formData.append('bornState', collaboratorUpdate.bornState);
                // formData.append('bornStateString', collaboratorUpdate.bornStateString);
            }

            if(collaboratorUpdate.bornCity !== undefined) {
                LoadsetbornCity(false)
                formData.append('bornCity', collaboratorUpdate.bornCity);
                // formData.append('bornCityString', collaboratorUpdate.bornCityString);
            }

            if(collaboratorUpdate.ethnicity !== undefined) {
                Loadsetethnicity(false)
                formData.append('ethnicity', collaboratorUpdate.ethnicity);
            }

            if(collaboratorUpdate.phone !== undefined && collaboratorUpdate.phone !== "") {
                Loadsetphone(false)
                formData.append('phone', collaboratorUpdate.phone);
            }

           if(collaboratorUpdate.cellphone !== undefined && collaboratorUpdate.cellphone !== "") {
                Loadsetcellphone(false)
                formData.append('cellphone', collaboratorUpdate.cellphone);
            }

            if(collaboratorUpdate.address !== undefined) {
                //Loadsetaddress(false)
                formData.append('address', collaboratorUpdate.address);
            }

            if(collaboratorUpdate.zipcode !== undefined && collaboratorUpdate.zipcode !== "") {
                Loadsetzipcode(false)
                formData.append('zipcode', collaboratorUpdate.zipcode);
            }

            if(collaboratorUpdate.addressNumber !== undefined && collaboratorUpdate.addressNumber !== "") {
                formData.append('addressNumber', collaboratorUpdate.addressNumber);
            }

            if(collaboratorUpdate.addressNeighborhood !== undefined) {
                LoadsetaddressNeighborhood(false)
                formData.append('addressNeighborhood', collaboratorUpdate.addressNeighborhood);
            }

            if(collaboratorUpdate.addressCity !== undefined) {
                LoadsetaddressCity(false)
                formData.append('addressCity', collaboratorUpdate.addressCity);
            }

            if(collaboratorUpdate.addressState !== undefined) {
                LoadsetaddressState(false)
                formData.append('addressState', collaboratorUpdate.addressState);
            }

            if(collaboratorUpdate.addressComplement !== undefined) {
                formData.append('addressComplement', collaboratorUpdate.addressComplement);
            }


                formData.append('path', path);
                await api.put(`/collaborator-update/${token}`, formData, config)
                .then(response => {
                    async function loadcollaborator() {
                        await api.get(`/collaborator-list/${token}`)
                        .then(response => {
                            setLoadContinue(false)
                            setLoad(false)
                            setCollaborator(response.data);
                            setTimeout(function(){history.push(`/cadastro-colaborador/progresso-salvo/${token}`);}, 0);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        });
                    }
                    loadcollaborator();
                    // swal({ icon: "success", title: "Sucesso!", text: "Colaborador salvo com sucesso!" });
                }).catch(error => {
                    setLoadContinue(true)
                    setLoad(true)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                });
        }

        else {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo" });
        }
    }

      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// ACADEMIC EDUCATION  ////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAcademicEducation, setLoadAcademicEducation ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSchooling, setLoadSchooling ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadInstitution, setLoadInstitution ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCourse, setLoadCourse ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ schooling, setSchooling ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredSchooling, setRegisteredSchooling ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNamesSchooling, setFileNamesSchooling] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(collaboratorId && collaboratorId.length !== 0) {
            async function loadschooling() {
                setLoadAcademicEducation(true)

                await api.get(`/collaborator-schooling-token/?id=${collaboratorId}`)
                .then(response => {

                    setLoadAcademicEducation(false)
                    if(response.data.length !== 0) {
                        setRegisteredSchooling(response.data);
                    } else {
                        setRegisteredSchooling([]);
                    }

                }).catch(error => {
                    setRegisteredSchooling([]);
                    setLoadAcademicEducation(false)
                    toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                })
            }
            loadschooling();
        }

    }, [collaboratorId]);

    async function myChangeHandlerSchooling(event) {
        let nam          = event.target.name;
        let val          = event.target.value;
        let collaborator = collaboratorId;

        if(nam === 'conclusion') {
            let yearconclusion = conclusionAE(val)
            //conclusionAE
            setSchooling({ ...schooling, collaborator, [nam]: yearconclusion })
        } else if (nam === "schooling") {

            if(val === 'Selecione') {
                //alert('aqui')
                let valschooling = undefined;
                setLoadSchooling(true);
                setSchooling({ ...schooling, collaborator, [nam]: valschooling })
            } else {
                setLoadSchooling(false);
                setSchooling({ ...schooling, collaborator, [nam]: val })
            }

        } else if (nam === "institution") {

            setLoadInstitution(false);
            setSchooling({ ...schooling, collaborator, [nam]: val })

        } else if (nam === "course") {

            setLoadCourse(false);
            setSchooling({ ...schooling, collaborator, [nam]: val })

        }  else {
            setSchooling({ ...schooling, collaborator, [nam]: val })
        }




    };

    async function handleDropSchooling(acceptedFiles) {
        //(acceptedFiles[0])

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {

            setFileNamesSchooling(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setSchooling({ ...schooling, file })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFilesSchooling() {
        setFileNamesSchooling();
        let file = fileNamesSchooling
        setSchooling({ ...schooling, file })
    }


    async function handleSubmitSchooling(event) {
        event.preventDefault();

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if (schooling.file !== undefined) {
            formData.append('file', schooling.file);
        }
        //formData.append('file', schooling.file);
        if (schooling.schooling !== undefined && schooling.schooling !== "") {
            formData.append('schooling', schooling.schooling);
            setLoadSchooling(false)
        } else {
            setLoadSchooling(true)
        }

        if (schooling.course !== undefined && schooling.course !== "") {
            formData.append('course', schooling.course);
            setLoadCourse(false)
        } else {
            setLoadCourse(true)
        }

        if (schooling.institution !== undefined && schooling.institution !== "") {
            formData.append('institution', schooling.institution);
            setLoadInstitution(false)
        } else {
            setLoadInstitution(true)
        }
        if (schooling.conclusion !== undefined && schooling.conclusion !== "") {
            formData.append('conclusion', schooling.conclusion);
        }
        formData.append('collaborator', collaboratorId);
        formData.append('token', token);
        formData.append('path', path);
        if (schooling.schooling !== undefined && schooling.schooling !== "" &&
        schooling.course !== undefined && schooling.course !== "" &&
        schooling.institution !== undefined && schooling.institution !== "") {

            setLoadAcademicEducation(true)
            await api.post("/collaborator-schooling-token", formData, config)
            .then(response => {
                let schooling = '';
                let course = '';
                let institution = '';
                let conclusion = '';

                setSchooling({ ...schooling, schooling, course, institution, conclusion})
                setloadBottomAcademic(false)
                setLoadContinue(false)
                setLoad(false)

                async function loadfilessaved() {
                    await api.get(`/collaborator-schooling-token/?id=${collaboratorId}`)
                    .then(response => {
                        setLoadAcademicEducation(false)
                        setRegisteredSchooling(response.data);


                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Formação acadêmica criada com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível criar a formação acadêmica!" });
            });
        }

    }

    async function deleteSchooling(schooling) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-schooling-token/${schooling._id}?path=${path}`)
                .then(() => {
                    async function loadfilessaved() {
                        await api.get(`/collaborator-schooling-token/?id=${collaboratorId}`)
                        .then(response => {
                            //setLoadAcademicEducation(false)
                            setRegisteredSchooling(response.data);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }

      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// FINAL ACADEMIC EDUCATION  //////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// DEPENDENT  /////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDependents, setLoadDependents ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameDependent, setLoadNameDependent ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRelationship, setLoadRelationship ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDateOfBirth, setLoadDateOfBirth ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSchoolingDependent, setLoadSchoolingDependent ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNamesDependents, setFileNamesDependents] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dependents, setDependents ] = useState([]);
     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ noDependentsSaved, setNoDependentsSaved ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredDependents, setRegisteredDependents ] = useState([]);



    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(collaboratorId && collaboratorId.length !== 0) {
        async function loaddependents() {

            await api.get(`/collaborator-dependent-token/?id=${collaboratorId}`)
            .then(response => {
                setLoadDependents(false)

                if(response.data.length !== 0) {
                    setRegisteredDependents(response.data);
                    setNoDependentsSaved(true)
                }
                else {
                    setRegisteredDependents([]);
                }
            }).catch(error => {
                setLoadDependents(false)
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            })

        }
        loaddependents();
    }

    }, [collaboratorId]);

    async function myChangeHandlerNoDependent(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = collaboratorId;
        let dependent = val

        setDependents({ ...dependents, collaborator, [nam]: val })
        setCollaboratorUpdate({ ...collaboratorUpdate, dependent })

    }



    async function myChangeHandlerDependent(event) {
        let nam = event.target.name;
        let val = event.target.value;
        let collaborator = collaboratorId;

        if(nam === 'dateOfBirth') {
            setLoadDateOfBirth(false)
            let birthDependent = dateofbirthDependet(val)
            setDependents({ ...dependents, collaborator, [nam]: birthDependent })

        }
        else  if(nam === 'name') {
            setLoadNameDependent(false)
            setDependents({ ...dependents, collaborator, [nam]: val })

        }
        else if(nam === 'relationship') {

            if(val === 'Selecione') {
                let valrelationship = undefined;
                setLoadRelationship(true)
                setDependents({ ...dependents, collaborator, [nam]: valrelationship })
            }
            else if(val === 'Nenhum') {
                setLoadRelationship(false)
                setDependents({ ...dependents, collaborator, name: val, [nam]: val, dateOfBirth: '0', schooling: val })
            }
            else {
                setLoadRelationship(false)
                setDependents({ ...dependents, collaborator, [nam]: val })
            }

        }
        else if(nam === 'schooling') {

            if(val === 'Selecione') {
                let valschooling = undefined;
                setLoadSchoolingDependent(true)
                setDependents({ ...dependents, collaborator, [nam]: valschooling })
            } else {
                setLoadSchoolingDependent(false)
                setDependents({ ...dependents, collaborator, [nam]: val })
            }

        }
        else {
            setDependents({ ...dependents, collaborator, [nam]: val })
        }



    };

    async function handleDropDependent(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setFileNamesDependents(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setDependents({ ...dependents, file })
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFilesDependent() {
        setFileNamesDependents();
        let file = fileNamesDependents
        setDependents({ ...dependents, file })
    }

    async function handleSubmitDependent(event) {
        event.preventDefault();

        if(
            (dependents && dependents.noDependents === 'false') ||
            noDependentsSaved                     ===  true
            ) {


            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if (dependents.name !== undefined && dependents.name !== "") {
                setLoadNameDependent(false)
                formData.append('name', dependents.name);
            } else {
                setLoadNameDependent(true)
            }

            if (dependents.relationship !== undefined && dependents.relationship !== "") {
                setLoadRelationship(false)
                formData.append('relationship', dependents.relationship);
            } else {
                setLoadRelationship(true)
            }

            if (dependents.dateOfBirth !== undefined && dependents.dateOfBirth !== "") {
                setLoadDateOfBirth(false)
                formData.append('dateOfBirth', dependents.dateOfBirth);
            } else {
                setLoadDateOfBirth(true)
            }

            if (dependents.schooling !== undefined && dependents.schooling !== "") {
                setLoadSchoolingDependent(false)
                formData.append('schooling', dependents.schooling);
            } else {
                setLoadSchoolingDependent(true)
            }

            if (dependents.file !== undefined) {
                formData.append('file', dependents.file);
            }
            formData.append('collaborator', collaboratorId);
            formData.append('token', token);
            formData.append('path', path);
            formData.append('noDependents', false);
            if (
                dependents.name         !== undefined &&
                dependents.name         !== ""        &&
                dependents.relationship !== undefined &&
                dependents.relationship !== ""        &&
                dependents.dateOfBirth  !== undefined &&
                dependents.dateOfBirth  !== ""        &&
                dependents.schooling    !== undefined &&
                dependents.schooling    !== ""
            ) {
                // formData.append('path', path);
                setLoadDependents(true)
                await api.post("/collaborator-dependent-token", formData, config)
                .then(response => {
                    let name         = undefined;
                    let relationship = undefined;
                    let dateOfBirth  = undefined
                    let schooling    = undefined;

                    setDependents({ ...dependents, name, relationship, dateOfBirth, schooling })
                    setloadBottomDependent(false)
                    setLoadContinue(false)
                    setLoad(false)


                    const dependentcollaboratorupdate = {

                        "dependent": 'true',
                        path: path
                    }

                    async function loadcollaboratorupdate() {
                        await api.put(`/collaborator-update/${token}`, dependentcollaboratorupdate)
                        .then(response => {}).catch(error => {})
                    }
                    loadcollaboratorupdate();

                    async function loadfilessaved() {
                        await api.get(`/collaborator-dependent-token/?id=${collaboratorId}`)
                        .then(response => {
                            setLoadDependents(false)
                            setRegisteredDependents(response.data);
                        }).catch(error => {
                            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                        })
                    }
                    loadfilessaved();
                    swal({ icon: "success", title: "Sucesso!", text: "Dependente criado com sucesso!" });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Não foi possível adicionar um dependente!" });

                });
            }

        } else {
            setLoadNameDependent(false)
            setLoadRelationship(false)
            setLoadDateOfBirth(false)
            setLoadSchoolingDependent(false)
            setDependents({ ...dependents, noDependents: 'false' })

        }


    }

    async function deleteDependent(schooling) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
            .then(async (res) => {
                if (res) {
                    await api.delete(`/collaborator-dependent-token/${schooling._id}?path=${path}`)
                        .then(() => {

                            let noDependents = 'true';
                            let name         = undefined;
                            let relationship = undefined;
                            let dateOfBirth  = undefined
                            let schooling    = undefined;

                            setDependents({ ...dependents, noDependents, name, relationship, dateOfBirth, schooling })

                            const dependentcollaboratorupdate = {

                                "dependent": 'false',
                                path: path
                            }

                            async function loadcollaboratorupdate() {
                                const collaboratorupdated = await api.put(`/collaborator-update/${token}`, dependentcollaboratorupdate)

                                if(collaboratorupdated.status === 200) {

                                    await api.get(`/collaborator-dependent-token/?id=${collaboratorId}`)
                                    .then(response => {
                                        setRegisteredDependents(response.data);
                                        setNoDependentsSaved(false)
                                    }).catch(error => {
                                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                                    })

                                }
                            }
                            loadcollaboratorupdate();
                            swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                        }).catch(() => {
                            swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                        });
                }
            });
    }

      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// FINAL DEPENDENT  ///////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// EMERGENCY CONTACT///////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmergencyContact, setLoadEmergencyContact ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loademErgencyName, setLoademErgencyName ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRelationshipEmergencyContact, setLoadRelationshipEmergencyContact ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmergencyCellphone, setLoadEmergencyCellphone ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ emergencyContact, setEmergencyContact ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredEmergencyContact, setRegisteredEmergencyContact ] = useState([]);



    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(collaboratorId && collaboratorId.length !== 0) {
            loademergencycontacts(collaboratorId);
        }

    }, [collaboratorId]);
    

    async function loademergencycontacts(collaboratorId) {
        //const { id } = collaboratorSession._id
        await api.get(`/collaborator-emergency-contact-token/?id=${collaboratorId}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setRegisteredEmergencyContact(response.data);
            } else {
                setRegisteredEmergencyContact([])
            }
        }).catch(error => {
            setRegisteredEmergencyContact([])
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    const myChangeHandlerEmergencyContact = event => {
        let nam = event.target.name;
        let val = event.target.value;

        const collaboratorId = collaborator._id;

        if(nam === 'emergencyName') {
            setLoademErgencyName(false)
            setEmergencyContact({ ...emergencyContact, token, collaboratorId, [nam]: val, path: path })
        }
        else
        if(nam === 'emergencyCellphone') {
            let cellphoneemergency = phones(val)
            if(val.length >= 14) {
                setLoadEmergencyCellphone(false)
            }
            setEmergencyContact({ ...emergencyContact, token, collaboratorId, [nam]: cellphoneemergency })
        }
        else
        if(nam === 'relationship') {

            if(val === 'Selecione') {
                let valrelationship = undefined;
                setLoadRelationshipEmergencyContact(true)
                setEmergencyContact({ ...emergencyContact, token, collaboratorId, [nam]: valrelationship })
            } else {
                setLoadRelationshipEmergencyContact(false)
                setEmergencyContact({ ...emergencyContact, token, collaboratorId, [nam]: val })
            }

        }
        else {
            setEmergencyContact({ ...emergencyContact, token, collaboratorId, [nam]: val })
        }



    };

    async function handleSubmitEmergencyContact(event) {
        event.preventDefault();

        if (emergencyContact.emergencyName === undefined || emergencyContact.emergencyName === "") {
            setLoademErgencyName(true)
        } else {
            setLoademErgencyName(false)
        }

        if (emergencyContact.relationship === undefined || emergencyContact.relationship === "") {
            setLoadRelationshipEmergencyContact(true)
        } else {
            setLoadRelationshipEmergencyContact(false)
        }

        if (emergencyContact.emergencyCellphone === undefined || emergencyContact.emergencyCellphone === "") {
            setLoadEmergencyCellphone(true)
        } else {
            setLoadEmergencyCellphone(false)
        }

        if (
            emergencyContact.emergencyName      !== undefined &&
            emergencyContact.emergencyName      !== ""        &&
            emergencyContact.relationship       !== undefined &&
            emergencyContact.relationship       !== ""        &&
            emergencyContact.emergencyCellphone !== undefined &&
            emergencyContact.emergencyCellphone !== ""
        ) {
            setLoadEmergencyContact(true)
            await api.post('/collaborator-emergency-contact-token/', emergencyContact)
            .then(response => {
                let emergencyName      = undefined;
                let relationship       = undefined;
                let emergencyCellphone = undefined;
                let emergencyEmail     = undefined;
                setEmergencyContact({ ...emergencyContact, emergencyName, relationship, emergencyCellphone, emergencyEmail })
                setloadBottomEmergencyContact(false)
                setLoadContinue(false)
                setLoad(false)
                async function loademergencycontacts() {
                    //const { id } = collaboratorSession._id
                    await api.get(`/collaborator-emergency-contact-token/?id=${collaboratorId}`)
                    .then(response => {
                        setLoadEmergencyContact(false)
                        setRegisteredEmergencyContact(response.data);

                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    })
                }
                loademergencycontacts();

                swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência criado com sucesso!" });
                //this.propsCollaboratorsInatives();
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Erro ao criar o contato de emergência, tente novamente mais tarde." });
            });
        }

    }

    async function handleDeleteEmergencyContact(_id) {
        try {
        await api.delete(`/collaborator-emergency-contact-token/${_id}?path=${path}`);

        async function loademergencycontacts() {
            //const { id } = collaboratorSession._id
            await api.get(`/collaborator-emergency-contact-token/?id=${collaboratorId}`)
            .then(response => {
                setRegisteredEmergencyContact(response.data);

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            })
        }
        loademergencycontacts();

        swal({ icon: "success", title: "Sucesso!", text: "Contato de emergência deletado com sucesso!" });
        } catch (error) {
        swal({ icon: "error", title: "Erro!", text: "Erro ao deletadar o contato de emergência!" });
        }
    }
    async function handleDropAvatar(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {
            const formData = new FormData();
            const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('path', path);
            setLoadAvatar(true)
            await api.put(`/collaborator-update/${token}`, formData, config)
            .then(response => {
                setLoadAvatar(false)
                setLoadAvatarVazio(false);
                async function loadcadastrocolaborador() {
                    await api.get(`/collaborator-list/${token}`)
                    .then(response => {
                        setCollaborator(response.data);
                    })
                    .catch(error => {
                        //toast.info('Não autorizado! Token inválido ou expirado!');
                        setTokenError(error);
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadcadastrocolaborador();
                swal({ icon: "success", title: "Sucesso!", text: "Foto adicionada com sucesso!" });

            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possíovel fazer as alterações" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }
    return (
        <>
            {tokenError.message !== undefined ? (
                <InvaliToken />
            ) : tokenError.error !== undefined ? (
                <ExpiredToken token={token} />
            ) : (
                <>
                <div className='pagina-Token'>
                    <Header/>
                    <div className="solicitacao-cadastro">
                        <HeaderSteps
                            token={token}
                            stepOne={stepOne}
                            stepTwo={stepTwo}
                            collaborator={collaborator}
                            registeredSchooling={registeredSchooling}
                            registeredDependents={registeredDependents}
                            registeredEmergencyContact={registeredEmergencyContact}
                            collaboratorUpdate={collaboratorUpdate}
                            focusAvatar={focusAvatar}
                        />
                        <PreInformation
                            collaborator={collaborator}
                            handleDropAvatar={handleDropAvatar}
                            loadAvatar={loadAvatar}
                            loadAvatarVazio={loadAvatarVazio}
                        />
                        <PersonalData
                            token={token}
                            Loadsetnationality={Loadsetnationality}
                            id={collaborator && collaborator._id ? collaborator._id : ''}
                            ufDistrito={
                                collaboratorUpdate && collaboratorUpdate.bornState !== undefined ?
                                collaboratorUpdate.bornState :
                                collaborator && collaborator.bornState !== undefined ?
                                collaborator.bornState :
                                ''
                            }
                            setCollaborator={setCollaborator}
                            setCollaboratorUpdate={setCollaboratorUpdate}
                            onCollaboratorInputChange={myChangeHandler}
                            ufEstadoEscolhido={collaborator.bornState}
                            ufCity={collaborator.bornCity}
                            collaborator={collaborator}
                            collaboratorUpdate={collaboratorUpdate}
                            LoaddateOfBirth={LoaddateOfBirth}
                            Loadgender               ={Loadgender             }
                            LoadfatherName           ={LoadfatherName         }
                            LoadmotherName           ={LoadmotherName         }
                            LoadmaritalStatus        ={LoadmaritalStatus      }
                            Loadnationality          ={Loadnationality        }
                            LoadotherNationality     ={LoadotherNationality   }
                            Loadethnicity            ={Loadethnicity          }
                            LoadbornState            ={LoadbornState          }
                            LoadbornCity             ={LoadbornCity           }
                            focusDateOfBirth         ={focusDateOfBirth        }
                            focusgender              ={focusgender             }
                            focusfatherName          ={focusfatherName         }
                            focusmotherName          ={focusmotherName         }
                            focusmaritalStatus       ={focusmaritalStatus      }
                            focusnationality         ={focusnationality        }
                            focusotherNationality    ={focusotherNationality   }
                            focusethnicity           ={focusethnicity          }
                            focusbornState           ={focusbornState          }
                            focusbornCity            ={focusbornCity           }
                            LoadsetbornState={LoadsetbornState}
                            LoadsetbornCity={LoadsetbornCity}
                            path={path}
                        />
                        <ContactAddress
                            token={token}
                            id={collaborator._id}
                            onCollaboratorInputChange={myChangeHandler}
                            collaborator={collaborator}
                            collaboratorUpdate={collaboratorUpdate}
                            LoadaddressNumber        ={LoadaddressNumber      }
                            Loadaddress              ={Loadaddress            }
                            Loadphone                ={Loadphone              }
                            Loadcellphone            ={Loadcellphone          }
                            Loadzipcode              ={Loadzipcode            }
                            LoadaddressNeighborhood  ={LoadaddressNeighborhood}
                            LoadaddressCity          ={LoadaddressCity        }
                            LoadaddressState         ={LoadaddressState       }
                            focusaddressNumber       ={focusaddressNumber      }
                            focusaddress             ={focusaddress            }
                            focusphone               ={focusphone              }
                            focuscellphone           ={focuscellphone          }
                            focuszipcode             ={focuszipcode            }
                            focusaddressNeighborhood ={focusaddressNeighborhood}
                            focusaddressCity         ={focusaddressCity        }
                            focusaddressState        ={focusaddressState       }
                            path={path}
                        />
                        <AcademicEducation
                            token={token}
                            id={collaborator._id}
                            collaborator={collaborator}
                            loadAcademicEducation={loadAcademicEducation}
                            focusSchooling={focusSchooling}
                            focusCourse={focusCourse}
                            focusInstitution={focusInstitution}
                            focusBottomAcademic={focusBottomAcademic}
                            loadSchooling={loadSchooling}
                            loadInstitution={loadInstitution}
                            loadCourse={loadCourse}
                            loadBottomAcademic={loadBottomAcademic}
                            schooling={schooling}
                            registeredSchooling={registeredSchooling}
                            fileNamesSchooling={fileNamesSchooling}
                            onCollaboratorInputChangeSchooling={myChangeHandlerSchooling}
                            handleDropSchooling={handleDropSchooling}
                            deleteFilesSchooling={deleteFilesSchooling}
                            handleSubmitSchooling={handleSubmitSchooling}
                            deleteSchooling={deleteSchooling}
                            conclusionAE={conclusionAE}
                        />
                        <Courses 
                            id={collaborator._id}
                            token={token}
                            setCoursesStatus={setCoursesStatus}
                            collaborator                      ={collaborator}
                        />
                        <Dependents
                            token                             ={token}
                            id                                ={collaborator._id}
                            collaborator                      ={collaborator}
                            loadDependents                    ={loadDependents}
                            loadNameDependent                 ={loadNameDependent}
                            loadRelationship                  ={loadRelationship}
                            loadDateOfBirth                   ={loadDateOfBirth}
                            loadSchoolingDependent            ={loadSchoolingDependent}
                            fileNamesDependents               ={fileNamesDependents}
                            dependents                        ={dependents}
                            registeredDependents              ={registeredDependents}
                            onCollaboratorInputChangeNoDependent={myChangeHandlerNoDependent}
                            onCollaboratorInputChangeDependent={myChangeHandlerDependent}
                            handleDropDependent               ={handleDropDependent}
                            deleteFilesDependent              ={deleteFilesDependent}
                            handleSubmitDependent             ={handleSubmitDependent}
                            deleteDependent                   ={deleteDependent}
                            focusNameDependent                ={focusNameDependent}
                            focusRelationshipDependent        ={focusRelationshipDependent}
                            focusDateofBirthDependent         ={focusDateofBirthDependent}
                            focusSchoolingDependente          ={focusSchoolingDependente}
                            focusBottomDependent              ={focusBottomDependent}
                            loadBottomDependent               ={loadBottomDependent}
                            noDependentsSaved                 ={noDependentsSaved}
                        // loadHaveDependent                 ={loadHaveDependent}
                        />
                        <EmergencyContact
                            tokencollaborator                         ={token}
                            id                                        ={collaborator._id}
                            onCollaboratorInputChange                 ={myChangeHandler}
                            collaborator                              ={collaborator}
                            loadEmergencyContact                      ={loadEmergencyContact}
                            loademErgencyName                         ={loademErgencyName}
                            loadRelationshipEmergencyContact          ={loadRelationshipEmergencyContact}
                            loadEmergencyCellphone                    ={loadEmergencyCellphone}
                            emergencyContact                          ={emergencyContact}
                            registeredEmergencyContact                ={registeredEmergencyContact}
                            onCollaboratorInputChangeEmergencyContact ={myChangeHandlerEmergencyContact}
                            handleSubmitEmergencyContact              ={handleSubmitEmergencyContact}
                            handleDeleteEmergencyContact              ={handleDeleteEmergencyContact}
                            focusEmergencyName                        ={focusEmergencyName}
                            focusRelationshipEmergency                ={focusRelationshipEmergency}
                            focusEmergencyCellphone                   ={focusEmergencyCellphone}
                            focusBottomEmergencyContact               ={focusBottomEmergencyContact}
                            loadBottomEmergencyContact                ={loadBottomEmergencyContact}
                        />
                    </div>
                    <div style={{height: "25px"}}></div>
                    <div className="buttons-solicitacao">
                        <form onSubmit={saveFillInLater} >
                        <button type="submit" className={load ? 'continuar-depois btnGray btnGrayLoad' : 'continuar-depois btnGray'}>Salvar e continuar depois</button>
                        </form>
                        <form onSubmit={save}>
                        <button name="stepTwoToken" value={"true"} onClick={myChangeHandler} className={loadContinue ? 'saved btnGreen btnGreenLoad' : 'saved btnGreen'}>Salvar e prosseguir</button>
                        </form>
                    </div>
                    <div style={{height: "38px"}}></div>
                </div>

                </>
            )}
        </>
    );
}

