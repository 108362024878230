import React from 'react';
import { NavLink } from 'react-router-dom';

export default function headerEtapaNovaDemissao({path, id, stepOne, stepTwo, stepThree, collaboratorShutdownSaved, collaborator }) {
    return (
        <>
            <div className="progressBarDesligamento">
                <div className='nameSession-mb'>
                    <h1>
                    {
                        path && path === '/colaboradores/desligamento/:id/contabilidade' ?"Contabilidade":
                        path && path === '/colaboradores/desligamento/:id/rescisao-e-documentos' ? "Rescisão e documentos":
                        path && path === '/colaboradores/desligamento/:id/finalizar' ? "Finalizar": ""
                    }
                    </h1>
                </div>
                    <div className={stepOne ? "session contabilidade" : "previousSession contabilidade"} >
                        {
                        collaborator && collaborator !== undefined  ?
                        (
                            <>
                            <NavLink to={`/colaboradores/desligamento/${id}/contabilidade`} className="nameSession"><p>Contabilidade</p></NavLink>
                            <NavLink to={`/colaboradores/desligamento/${id}/contabilidade`} className="number"><div><p>1</p></div></NavLink>
                            </>
                        ) :
                        collaboratorShutdownSaved && collaboratorShutdownSaved !== undefined  ?
                        (
                            <>
                            <NavLink to={`/colaboradores/desligamento/${id}/contabilidade`} className="nameSession"><p>Contabilidade</p></NavLink>
                            <NavLink to={`/colaboradores/desligamento/${id}/contabilidade`} className="number"><div><p>1</p></div></NavLink>
                            </>
                        ) :
                        (
                            <>
                            <NavLink to="#" className="nameSession"><p>Contabilidade</p></NavLink>
                            <NavLink to="#" className="number"><div><p>1</p></div></NavLink>
                            </>
                        )
                        }

                        <div className="Linha linha-left"></div>
                    </div>

                    <div className={stepTwo ? "session revisao-documentos" : stepThree ? "previousSession revisao-documentos" : "revisao-documentos"}>
                        {
                        collaborator && collaborator !== undefined  ?
                        (
                            <>
                            <NavLink to={`/colaboradores/desligamento/${id}/rescisao-e-documentos`} className="nameSession"><p>Rescisão e documentos</p></NavLink>
                            <NavLink to={`/colaboradores/desligamento/${id}/rescisao-e-documentos`} className="number"><div><p>2</p></div></NavLink>
                            </>
                        ) :
                        collaboratorShutdownSaved && collaboratorShutdownSaved !== undefined  ?
                        (
                            <>
                            <NavLink to={`/colaboradores/desligamento/${id}/rescisao-e-documentos`} className="nameSession"><p>Rescisão e documentos</p></NavLink>
                            <NavLink to={`/colaboradores/desligamento/${id}/rescisao-e-documentos`} className="number"><div><p>2</p></div></NavLink>
                            </>
                        ) :
                        (
                            <>
                            <NavLink to="#" className="nameSession"><p>Rescisão e documentos</p></NavLink>
                            <NavLink to="#" className="number"><div><p>2</p></div></NavLink>
                            </>
                        )
                        }
                        <div className="Linha"></div>
                    </div>
                    <div className={stepThree ? "session finalizar" : "finalizar"}>
                        {id ?
                        (
                            <>
                            <NavLink to={`/colaboradores/desligamento/${id}/finalizar`} className="nameSession"><p>Finalizar</p></NavLink>
                            <NavLink to={`/colaboradores/desligamento/${id}/finalizar`} className="number"><div><p>3</p></div></NavLink>
                            </>
                        ) :
                        (
                            <>
                            <NavLink to="#" className="nameSession"><p>Finalizar</p></NavLink>
                            <NavLink to="#" className="number"><div><p>3</p></div></NavLink>
                            </>
                        )
                        }


                        <div className="Linha linha-right"></div>
                    </div>
                </div>
        </>
    );
}
