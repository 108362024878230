import React from 'react';
import SubMenu from './subMenu';
import Address from './AddressesContacts/Address';
import Contact from './AddressesContacts/Contact';
import EmergencyContacts from './AddressesContacts/EmergencyContacts';


const ColProfilePersonal = ({
    id,
    path,
    loadPage,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    saveContact,
    saveAddress,
    loadContinue,
    PopupAddressVisible,
    visibleAddress,
    PopupContactVisible,
    visibleContact,
    LoadaddressNumber       ,
    Loadzipcode             ,
    LoadaddressNeighborhood ,
    LoadaddressCity         ,
    LoadaddressState        ,
    Loadcellphone           ,
    Loademail,
    loadAddress,
    filesSavedAddress,
    handleDropAddress,
    deleteFileAddress,
    role,
}) => {

        return (
            <>
            <SubMenu id={id} collaborator={collaborator} role={role} path={path}/>
                <div className="box-infor-perfil profile-infor-pessoal">
                {
                    (role && role === 'admin') || (role && role === 'master') ?
                    <Address
                        id={id}
                        path={path}
                        loadPage={loadPage}
                        collaborator={collaborator}
                        collaboratorUpdate={collaboratorUpdate}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        loadContinue={loadContinue}
                        PopupAddressVisible={PopupAddressVisible}
                        visibleAddress={visibleAddress}
                        Loadzipcode              ={Loadzipcode            }
                        LoadaddressNeighborhood  ={LoadaddressNeighborhood}
                        LoadaddressCity          ={LoadaddressCity        }
                        LoadaddressState         ={LoadaddressState       }
                        LoadaddressNumber        ={LoadaddressNumber      }
                        saveAddress={saveAddress}
                        loadAddress={loadAddress}
                        filesSavedAddress={filesSavedAddress}
                        handleDropAddress={handleDropAddress}
                        deleteFileAddress={deleteFileAddress}
                    />
                    :
                    ''
                }

                    <Contact
                        id={id}
                        loadPage={loadPage}
                        collaborator={collaborator}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        saveContact={saveContact}
                        loadContinue={loadContinue}
                        PopupContactVisible={PopupContactVisible}
                        visibleContact={visibleContact}
                        collaboratorUpdate={collaboratorUpdate}
                        Loadcellphone            ={Loadcellphone          }
                        Loademail                ={Loademail              }
                        role={role}
                    />
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <EmergencyContacts
                            id={id}
                            path={path}
                            loadPage={loadPage}
                            collaboratorSession={collaborator}
                            onCollaboratorInputChange={onCollaboratorInputChange}
                            loadContinue={loadContinue}
                        />
                        :
                        ''
                    }

                </div>
            </>
        );
}
export default ColProfilePersonal;
