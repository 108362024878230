import React from 'react';
//import { NavLink } from 'react-router-dom'
import { formatDateString } from '../../../../services/utils'

// import InputMask from 'react-input-mask';
import { Scrollbars } from 'react-custom-scrollbars';

export default function emergencyContact({
    id,
    tokencollaborator,
    collaborator,
    loadEmergencyContact,
    loademErgencyName,
    loadRelationshipEmergencyContact,
    loadEmergencyCellphone,
    emergencyContact,
    registeredEmergencyContact,
    onCollaboratorInputChangeEmergencyContact,
    handleSubmitEmergencyContact,
    handleDeleteEmergencyContact,
    focusBottomEmergencyContact,
    loadBottomEmergencyContact,
    focusEmergencyName,
    focusRelationshipEmergency,
    focusEmergencyCellphone
 }) {

    return (
        <>

        <div onSubmit={handleSubmitEmergencyContact} className="box-formulario contato-emergencia">
            <h4 className="titulo-box">Contato de emergência</h4>
            <div className='linha'>
                <div className='bloco'>
                    <div className='intemA'>
                        <p className="textCampo textoUm">Nome completo</p>
                    </div>
                    <div className='intemB'>
                        <input ref={focusEmergencyName} type="text" name="emergencyName" autocomplete="autocompleteoff" value={emergencyContact && emergencyContact.emergencyName ? emergencyContact.emergencyName : ''} onChange={onCollaboratorInputChangeEmergencyContact} placeholder="Nome completo" className={loademErgencyName ? 'inputUm inputErro' : 'inputUm'}/>
                        <p className="inputUm textError">{loademErgencyName ? 'O campo é obrigatório.' : ''}</p>
                    </div>
                </div>
                <div className='bloco'>
                    <div className='intemA'>
                        <p className="textCampo textoDois">Parentesco</p>

                    </div>
                    <div className='intemB'>

                        <div className='cont-select inputDois'>
                            <button className={loadRelationshipEmergencyContact ? 'inputErro select-' : 'select-'}  ref={focusRelationshipEmergency}>
                                <p>
                                    {
                                        emergencyContact && emergencyContact.relationship ?
                                            emergencyContact.relationship
                                        :
                                            'Selecione'
                                    }
                                </p>
                            </button>

                            <div className="dropDown">
                                <Scrollbars className='listDropDown'
                                    style={{  height:  125 }}
                                >
                                    {[
                                        "Cônjuge",
                                        "Companheiro(a) ou união estável com filhos",
                                        "Companheiro(a) ou união estável sem filhos",
                                        "Filho ou enteado até 21 anos",
                                        "Filho ou enteado universitário ou em escola técnica",
                                        "Irmão(ã), neto(a) ou bisneto(a) com guarda",
                                        "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica",
                                        "Pais, avós e bisavós",
                                        "Incapaz",
                                        "Agregado/Outros",
                                        "Ex cônjuge que recebe pensão de alimentos"
                                        ].map((intem, index) => (
                                            <button  key={index} name="relationship" value={intem}  onClick={onCollaboratorInputChangeEmergencyContact} className='item'>
                                                {intem}
                                            </button>
                                        ))
                                    }
                                    </Scrollbars>
                            </div>
                        </div>
                        <p className="inputDois textError">{loadRelationshipEmergencyContact ? 'O campo é obrigatório.' : ''}</p>

                    </div>
                </div>
            </div>
            <div className='linha'>
                <div className='bloco'>
                    <div className='intemA'>
                        <p className="textCampo textoTreis">Celular</p>
                    </div>
                    <div className='intemB'>

                        <input ref={focusEmergencyCellphone} autocomplete="autocompleteoff" value={emergencyContact && emergencyContact.emergencyCellphone ? emergencyContact.emergencyCellphone : ''} type="tel" name="emergencyCellphone" onChange={onCollaboratorInputChangeEmergencyContact} placeholder="Celular" className={loadEmergencyCellphone ? 'inputTreis inputErro' : 'inputTreis'}/>
                        <p className="inputTreis textError">{loadEmergencyCellphone ? 'O campo é obrigatório.' : ''}</p>


                    </div>
                </div>
                <div className='bloco'>
                    <div className='intemA'>
                        <p className="textCampo textoQuatro">Email</p>

                    </div>
                    <div className='intemB'>
                        <input type="email" name="emergencyEmail" autocomplete="autocompleteoff" value={emergencyContact && emergencyContact.emergencyEmail ? emergencyContact.emergencyEmail : ''} onChange={onCollaboratorInputChangeEmergencyContact} placeholder="Email" className="inputQuatro"/>


                    </div>
                </div>
            </div>
            <div className='linha'>
                <div className='bloco'>
                    <div className='intemA'>

                    </div>
                    <div className='intemB'>

                        <button ref={focusBottomEmergencyContact}  onClick={handleSubmitEmergencyContact}  type="submit" className={loadEmergencyContact ? 'inputCinco btnGreen btnGreenLoad btn-adicionar' : 'inputCinco btnGreen btn-adicionar'}>{loadEmergencyContact ? 'Adicionando' : 'Adicionar contato'}</button>
                        {loadBottomEmergencyContact ? (
                        <p className="textError inputCinco">É necessário adicionar um contato de emergência</p>
                        ) : ('')
                        }

                    </div>
                </div>
            </div>



            {/* <select ref={focusRelationshipEmergency} name="relationship" value={emergencyContact && emergencyContact.relationship ? emergencyContact.relationship : ''} onChange={onCollaboratorInputChangeEmergencyContact} className={loadRelationshipEmergencyContact ? 'inputDois inputErro' : 'inputDois'}>
                <option value="Selecione">Selecione</option>
                {["Cônjuge", "Companheiro(a) ou união estável com filhos", "Companheiro(a) ou união estável sem filhos", "Filho ou enteado até 21 anos", "Filho ou enteado universitário ou em escola técnica", "Irmão(ã), neto(a) ou bisneto(a) com guarda", "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica", "Pais, avós e bisavós", "Incapaz", "Agregado/Outros", "Ex cônjuge que recebe pensão de alimentos"
                ].map((item, index) => (
                    <option value={item} key={index}>{item}</option>
                ))}
            </select> */}
        </div>
        <div className="cont-emergencia-adicionado">
            <h5>Cadastrados</h5>
            {registeredEmergencyContact[0] !== undefined ? registeredEmergencyContact
            .map((emergencycontact, index) => (
            <div key={index} className="anexado">
                <div className="name">
                    <p>{emergencycontact.emergencyName}</p>
                </div>
                <div className="contato">
                    <p>{emergencycontact.emergencyCellphone}</p>
                </div>
                <div className="dataArquivo">
                    <span>cadastrado {formatDateString(emergencycontact.createdAt)}</span>
                </div>
                <div className="btnExclui">
                <button onClick={() => handleDeleteEmergencyContact(emergencycontact._id)}>Excluir</button>
                </div>
            </div>
            )) : (
                <div className="nenhum-cont-emergencia">
                <p>Nenhum contato de emergência cadastrado.</p>
                </div>
            )}
        </div>

        </>
    );

}

