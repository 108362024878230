import React, {useState}  from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default function homeFeedbackColQuestions() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [adicionarItem, setAdicionarItem] = useState(false);
    return (
        <>
                
            <div className='box-leiaute questions'>
                <h2 className='titulo'>Assuntos</h2>
                <div className='box-list'>
                    <Scrollbars style={{  height: 140
                    // option?.length === 0 ? 30 : "" ||
                    // option?.length === 1 ? 30 : ""  ||
                    // option?.length === 2 ? 70 : ""  ||
                    // option?.length >= 3 ? 120 : 30
                    
                    }}>
                        <ul>
                            <li>
                                <button className='chek-button active'>
                                    <label></label>
                                    <span>Opção pra fechar lateral para telas pequenas</span>
                                </button>
                                <button className='excluir'>Excluir</button>
                            </li>
                            <li>
                                <button className='chek-button '>
                                    <label></label>
                                    <span>Opção pra fechar lateral para telas pequenas</span>
                                </button>
                                <button className='excluir'>Excluir</button>
                            </li>
                            <li>
                                <button className='chek-button '>
                                    <label></label>
                                    <span>Opção pra fechar lateral para telas pequenas</span>
                                </button>
                                <button className='excluir'>Excluir</button>
                            </li>
                        </ul>
                    </Scrollbars>
                </div>

                <div className='box-form'>
                    {
                        adicionarItem ?
                        <>
                            <input type="text"  placeholder='Digite uma nova pergunta…'/>
                            <div className='btns'>
                                <button className='btnGreen load'>Adicionar</button>
                                <button className='cancela'>Cancelar</button>

                            </div>
                        </>
                        :
                            <button className='adicionar-item' onClick={() => setAdicionarItem(true)}>Adicionar item</button>
                        
                    }


                </div>
                    
            </div>


                            
        </>
    );
}

