import React from 'react';
import IconAliamento from '../../../../assets/images/illustration-aliamento.svg';
import IconBemEstar from '../../../../assets/images/illustration-bem-estar.svg';
import IconCarreira from '../../../../assets/images/illustration-carreira.svg';
import IconCrescimentoPessoal from '../../../../assets/images/illustration-crescimento-pessoal.svg';
import IconEmbaixadorismo from '../../../../assets/images/illustration-embaixadorismo.svg';
import IconEstrutura from '../../../../assets/images/illustration-estrutura.svg';
import IconFeedbackReconhecimento from '../../../../assets/images/illustration-feedback-reconhecimento.svg';
import IconFelicidade from '../../../../assets/images/illustration-felicidade.svg';
import IconInovacao from '../../../../assets/images/illustration-inovacao.svg';
import IconJustica from '../../../../assets/images/illustration-justica.svg';
import IconLideranca from '../../../../assets/images/illustration-lideranca.svg';
import IconRelacionamentoInterpessoal from '../../../../assets/images/illustration-relacionamento-interpessoal.svg';



export default function ManagementOverview() {
    // 
    // 
    // 
    
    const grafic_oveview = [
        {
            "name" : "Alinhamento com a empresa",
            "status" : "down",
            "count" : 10.00
        },
        {
            "name" : "Bem-estar",
            "status" : "up",
            "count" : 8.78
        },
        {
            "name" : "Carreira",
            "status" : "down",
            "count" : 5.00
        },
        {
            "name" : "Crescimento pessoal",
            "status" : "down",
            "count" : 2.00
        },
        {
            "name" : "Embaixadorismo",
            "status" : "down",
            "count" : 6.00
        },
        {
            "name" : "Estrutura",
            "status" : "updown",
            "count" : 6.00
        },
        {
            "name" : "Feedback e reconhecimento",
            "status" : "up",
            "count" : 8.00
        },
        {
            "name" : "Felicidade",
            "status" : "down",
            "count" : 1.00
        },
        {
            "name" : "Inovação",
            "status" : "down",
            "count" : 1.00
        },
        {
            "name" : "Justiça",
            "status" : "up",
            "count" : 1.00
        },
        {
            "name" : "Liderança",
            "status" : "down",
            "count" : 1.00
        },
        {
            "name" : "Relacionamento interpessoal",
            "status" : "up",
            "count" : 7.00
        }
    ];


    const subsPercentEnpt = (0)



    return (
        <>
        <div className="graph-overview">
            <div className='numbers'>
                <p>10</p>
                <p>8</p>
                <p>6</p>
                <p>4</p>
                <p>2</p>
                <p>0</p>
            </div>
            <div className='progress-bars'>
                 {
                     grafic_oveview.map((barra, index) => (
                        <>
                            <div className={barra.status === "up" ? "barra up":"down barra"} key={index}> 
                                <span style={barra.count !== undefined ? 
                                    { height: `${parseInt(barra.count)}0%`} : 
                                    { height: `${subsPercentEnpt}%`}} 
                                    className="barra-int"
                                >
                                    <div className="balao">
                                        <div className='infor'>
                                        {/* "name" : "Alinhamento com a empresa",
                                        
                                        Relacionamento interpessoal
                                        
                                        */}
                                        {
                                            barra.name === "Alinhamento com a empresa" ?
                                                <img src={IconAliamento} alt="Alinhamento com a empresa" className='icon-alinhamento'/>
                                            :barra.name === "Bem-estar" ?
                                                <img src={IconBemEstar} alt="Bem-estar" className='icon-bemestar'/>
                                            :barra.name === "Carreira" ?
                                             <img src={IconCarreira} alt="Carreira" className='icon-carreira'/>
                                             :barra.name === "Crescimento pessoal" ?
                                              <img src={IconCrescimentoPessoal} alt="Crescimento pessoal" className='icon-carreira'/>
                                              :barra.name === "Embaixadorismo" ?
                                               <img src={IconEmbaixadorismo} alt="Embaixadorismo" className='icon-embaixadorismo'/>
                                               :barra.name === "Estrutura" ?
                                                <img src={IconEstrutura} alt="Estrutura" className='icon-estrutura'/>
                                                :barra.name === "Feedback e reconhecimento" ?
                                                <img src={IconFeedbackReconhecimento} alt="Feedback e reconhecimento" className='icon-feedback'/>
                                                :barra.name === "Felicidade" ?
                                                <img src={IconFelicidade} alt="Felicidade" className='icon-feedback'/>
                                                :barra.name === "Inovação" ?
                                                <img src={IconInovacao} alt="Inovação" className='icon-bemestar'/>
                                                :barra.name === "Justiça" ?
                                                <img src={IconJustica} alt="Justiça" className='icon-feedback'/>
                                                :barra.name === "Liderança" ?
                                                <img src={IconLideranca} alt="Liderança" className='icon-feedback'/>
                                                :barra.name === "Relacionamento interpessoal" ?
                                                <img src={IconRelacionamentoInterpessoal} alt="Relacionamento interpessoal" className='icon-feedback'/>
                                            
                                            :""
                                        
                                        }
                                            
                                            {barra.name}
                                            <span className={barra.status === "up" ? "icon-up":"icon-down"}></span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </>
                    ))
                 }
            </div>

            <p className='infor-cate'>Categorias</p>
        </div>
        </>
    );
}

