import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import PessonalData from './PessonalData/pessonalData';
import BankData from './PessonalData/bankData';
import AcademicEducation from './PessonalData/academicEducation';
import Courses from './PessonalData/Courses';
import Deficiencia from './PessonalData/deficiencia';
import Dependents from './PessonalData/Dependents';
import api from '../../../../../services/api';
import { toast } from 'react-toastify';

export default function ColProfilePersonal ({
    id,
    path,
    MakePersonalDataVisible,
    myChangeHandlerToSaveBank,
    visiblePersonalData,
    EditBankDataVisibleBank,
    visibleBank,
    collaborator,
    setCollaborator,
    collaboratorUpdate,
    setCollaboratorUpdate,
    onCollaboratorInputChange,
    ufEstados,
    ufDistrito ,
    ufCity,
    OnSubmit,
    loadContinue,
    loadPage,
    loadFileNamesPersonalData,
    filesSavedPersonalData,
    handleDropPersonalData,
    mySearchBank,
    abrirResultado,
    abrirResultBank,
    fecharBusca,
    deleteFile,
    openSelAccountType,
    openAccountType,
    selectAccountTypeOpen,
    myChangeHandlerAccountType,
    user,
    role,

    setSelectEthnicity,
    setSelectNationality,
    setSelectGender,
    setSelectMaritalStatus,
    setSelectBornState,
    setSelectBornCity,

    selectEthnicity,
    selectNationality,
    selectGender,
    selectMaritalStatus,
    selectBornState,
    selectBornCity
}) {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCitys ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ ufEscolhido, setUEscolhido ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCidade ] = useState('');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(ufDistrito !== undefined && ufDistrito !== "undefined") {

            loaduf(ufDistrito);

            if(ufDistrito === '35') {

                loadcities_(ufDistrito);

            }
            else {

                loadcities(ufDistrito);

            }

        }

        if(ufCity && ufCity !== "undefined" && ufCity !== undefined) {

            loadcity(ufCity);

        }

    }, [ufDistrito, ufCity]);


    async function loaduf(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
        .then(response => {

            setUEscolhido(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcities_(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
        .then(response => {

            let itensCopyCitys = Array.from(response.data);

            itensCopyCitys.push({id: '350000000', nome: 'São Paulo' });

            itensCopyCitys.sort(function (a, b) {

                if (a.nome > b.nome) {
                  return 1;
                }
                if (a.nome < b.nome) {
                  return -1;
                }

                return 0;
            });

            setCitys(itensCopyCitys);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadcities(ufDistrito) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}/distritos?orderBy=nome`)
        .then(response => {

            setCitys(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }

    async function loadcity(ufCity) {

        await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${ufCity}`)
        .then(response => {

            setCidade(response.data[0]);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });

    }
    return (
        <>
            <SubMenu
            id={id}
            role={role}
            path={path}
            />
            <div className="box-infor-perfil profile-infor-pessoal">
                <div className='pessonal-data-courses'>
                    <PessonalData
                        id={id}
                        loadPage={loadPage}
                        path={path}
                        MakePersonalDataVisible={MakePersonalDataVisible}
                        visiblePersonalData={visiblePersonalData}
                        collaborator={collaborator}
                        collaboratorUpdate={collaboratorUpdate}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        ufEstados={ufEstados}
                        ufEscolhido={ufEscolhido}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        loadFileNamesPersonalData={loadFileNamesPersonalData}
                        filesSavedPersonalData={filesSavedPersonalData}
                        handleDropPersonalData={handleDropPersonalData}
                        deleteFile={deleteFile}
                        setCollaboratorUpdate={setCollaboratorUpdate}
                        setCollaborator={setCollaborator}
                        user={user}

                        setSelectEthnicity={setSelectEthnicity}
                        setSelectNationality={setSelectNationality}
                        setSelectGender={setSelectGender}
                        setSelectMaritalStatus={setSelectMaritalStatus}
                        setSelectBornState={setSelectBornState}
                        setSelectBornCity={setSelectBornCity}

                        selectEthnicity={selectEthnicity}
                        selectNationality={selectNationality}
                        selectGender={selectGender}
                        selectMaritalStatus={selectMaritalStatus}
                        selectBornState={selectBornState}
                        selectBornCity={selectBornCity}
                    />
                    
                {
                    (user && user.role === 'admin') || (user && user.role === 'master') ?
                    <Courses
                        user={user} 
                        id={id}
                    />: ""}


                </div>
                {
                    (user && user.role === 'admin') || (user && user.role === 'master') ?
                    <>
                    <BankData
                        id={id}
                        loadPage={loadPage}
                        collaborator={collaborator}
                        myChangeHandlerToSaveBank={myChangeHandlerToSaveBank}
                        collaboratorUpdate={collaboratorUpdate}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        EditBankDataVisibleBank={EditBankDataVisibleBank}
                        visibleBank={visibleBank}
                        ufEstados={ufEstados}
                        ufEscolhido={ufEscolhido}
                        ufDistrito={ufDistrito}
                        ufEstadoEscolhido={collaborator.bornState}
                        ufCity={collaborator.bornCity}
                        OnSubmit={OnSubmit}
                        loadContinue={loadContinue}
                        abrirResultado={abrirResultado}
                        abrirResultBank={abrirResultBank}
                        fecharBusca={fecharBusca}
                        mySearchBank={mySearchBank}
                        openSelAccountType={openSelAccountType}
                        openAccountType={openAccountType}
                        selectAccountTypeOpen={selectAccountTypeOpen}
                        myChangeHandlerAccountType={myChangeHandlerAccountType


                        }
                    />
                    <Deficiencia
                    id={id}
                    path={path}
                    collaboratorState={collaborator}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    loadContinue={loadContinue}
                    />
                    <AcademicEducation
                        id={id}
                        path={path}
                        collaboratorState={collaborator}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        loadContinue={loadContinue}
                        />
                    <Dependents
                        id={id}
                        path={path}
                        collaboratorState={collaborator}
                        onCollaboratorInputChange={onCollaboratorInputChange}
                        loadContinue={loadContinue}
                        />
                    </>
                    :
                    ''
                }

            </div>
        </>
    );
}