import React from 'react';
///import IconsInformation from '../../../IconsInformation';
import { NavLink } from 'react-router-dom'

const headerEtapaNovoColaborador = ({
    token,
    stepOne,
    stepTwo,
    collaborator,
    registeredSchooling,
    registeredDependents,
    registeredEmergencyContact,
    focusTop,
    collaboratorUpdate,
    focusAvatar
}) => {

        return (
            <>
                <div className="progress-Bar">
                            {/* classname="previousSession" quando tiver em outra seção */}
                        <div className={stepOne ? "session basicInformation" : "previousSession basicInformation"}>
                            <NavLink to={`/cadastro-colaborador/${token}`} className="nameSession" ref={focusTop}><p>Dados pessoais</p></NavLink>
                            <NavLink to={`/cadastro-colaborador/${token}`} className="number" ref={focusAvatar} ><div><p>1</p></div></NavLink>

                            <div className="Linha linha-left"></div>
                        </div>

                        <div className={stepTwo ? "session documents" : "documents"}>
                        {
                        collaborator                                    &&
                        collaborator.dateOfBirth          !== undefined &&
                        collaborator.gender               !== undefined &&
                        collaborator.fatherName           !== undefined &&
                        collaborator.motherName           !== undefined &&
                        collaborator.maritalStatus        !== undefined &&
                        collaborator.nationality          !== undefined &&
                        collaborator.ethnicity            !== undefined &&
                        collaborator.bornState            !== undefined &&
                        collaborator.bornCity             !== undefined &&
                        // collaborator.addressNumber        !== undefined &&
                        collaborator.address              !== undefined &&
                        collaborator.phone                !== undefined &&
                        collaborator.cellphone            !== undefined &&
                        collaborator.zipcode              !== undefined &&
                        collaborator.addressNeighborhood  !== undefined &&
                        collaborator.addressCity          !== undefined &&
                        collaborator.addressState         !== undefined &&
                        registeredSchooling                             &&
                        registeredSchooling.length        !==     0     &&
                        registeredSchooling               !== undefined &&
                        // registeredDependents                            &&
                        // registeredDependents.length       !==     0     &&
                        // registeredDependents              !== undefined &&
                        registeredEmergencyContact                      &&
                        registeredEmergencyContact.length !==     0     &&
                        registeredEmergencyContact        !== undefined ?
                        <>
                        <NavLink to={`/cadastro-colaborador/documentos/${token}`}className="nameSession"><p>Documentos</p></NavLink>
                        <NavLink to={`/cadastro-colaborador/documentos/${token}`} className="number"><div><p>2</p></div></NavLink>
                        </>
                        :
                        <>
                        <NavLink to="#"className="nameSession"><p>Documentos</p></NavLink>
                        <NavLink to="#" className="number"><div><p>2</p></div></NavLink>
                        </>
                        }


                            <div className="Linha linha-right"></div>
                        </div>

                    </div>
            </>
        );
}
export default headerEtapaNovoColaborador;
