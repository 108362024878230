import React from 'react';
import { NavLink } from 'react-router-dom'
import './styles.scss';

import logo from '../../assets/images/logo.svg';
import { decodeToken } from '../../services/auth';

const PageNotFound = () => {
    const decoded = decodeToken();
    return (
        <>
            <div className="box-error pagina-nao-encontrada">

                <img src={logo} alt="People View" className="logo"/>
                <h1>404</h1>
                <p>Não encontramos esta página.</p>
                    {
                        decoded.user.role === "user" ? 
                        <NavLink to={`/dashboard-user`}>
                            Voltar ao início
                        </NavLink>
                        :

                        <NavLink to={`/colaboradores/ativos`}>
                            Voltar ao início
                        </NavLink>
                    }
                   {/* <button>Voltar ao início</button> */}
                <span>© 2021 People View</span>
            </div>

        </>
    );
}
export default PageNotFound;
