import React from 'react';
import { Dropdown } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import iconV from '../../assets/images/v.svg';
import iconNews from '../../assets/images/icon-news.svg';
import { momentDurationToFeed } from '../../services/utils';

import  './calendar.scss';

export default function pageCFeed ({
    feed,
    filterFeed,
    all,
    candidate,
    feedback,
    collaboratorabsences,
    adverts,
    showMoreFeed,
 }) {

    return (
        <>

        <div className="feed-novo">
            <div className="header-feed">

                <h1>
                    <span>Feed</span>

                <Dropdown  className="dropdown-icon ">
                        <Dropdown.Toggle>
                        Filtrar <img alt="" src={iconV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="chekboxText">
                                <input value="all" onChange={filterFeed} type="checkbox" checked={all} />
                                <label className="foreignTexto">Tudo</label>
                            </div>
                            {/* <div className="chekboxText">
                                <input value="candidate" onChange={filterFeed} type="checkbox" checked={candidate} />
                                <label className="foreignTexto">Currículos</label>
                            </div>
                            <div className="chekboxText">
                                <input value="feedback" onChange={filterFeed} type="checkbox" checked={feedback} />
                                <label className="foreignTexto">Feedbacks</label>
                            </div> */}
                            <div className="chekboxText">
                                <input value="collaboratorabsences" onChange={filterFeed} type="checkbox" checked={collaboratorabsences} />
                                <label className="foreignTexto">Ausências</label>
                            </div>
                            <div className="chekboxText">
                                <input value="adverts" onChange={filterFeed} type="checkbox" checked={adverts} />
                                <label className="foreignTexto">Comunicação</label>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </h1>

            </div>


            {
            feed.length !== 0 ?
            <Scrollbars
                    className='scrollbar'
                    // style={{  height: 400 }}
                    >
                    <div className="lista-Feed" >
                        <div className="box">
                            <ul>
                                {


                                    feed &&
                                    feed.length !== 0 ?
                                    feed.map((feedname, index) => (
                                        feedname.categoryadverts               &&
                                        feedname.categoryadverts === 'adverts' &&
                                        feedname.adverts         !==  null      ?
                                        <>
                                        <li key={index}>
                                            <p>
                                                <strong>{feedname.adverts && feedname.adverts.collaborator ? feedname.adverts.collaborator.name : '' } </strong>
                                                criou um recado no mural de aníncios
                                                <br />
                                                <span>{momentDurationToFeed(feedname.createdAt)}</span>
                                            </p>
                                        </li>
                                        </>
                                        :
                                        feedname.categoryeventscalendar                                  &&
                                        feedname.categoryeventscalendar === 'collaboratoreventscalendar' &&
                                        feedname.eventscalendar         !==                              null ?
                                        <>
                                        <li key={index}>
                                            <p>
                                                <strong>{feedname.collaboratoreventscalendar && feedname.collaboratoreventscalendar.collaborator ? feedname.collaboratoreventscalendar.collaborator.name : '' } </strong>
                                                criou o evento {feedname.collaboratoreventscalendar && feedname.collaboratoreventscalendar.eventscalendar ? feedname.collaboratoreventscalendar.eventscalendar.eventName : '' } no calendário
                                                <br />
                                                <span>{momentDurationToFeed(feedname.createdAt)}</span>
                                            </p>
                                        </li>
                                        </>
                                        :
                                        feedname.categoryfeedback                &&
                                        feedname.categoryfeedback === 'feedback' &&
                                        feedname.feedback         !== null       ?
                                        <>
                                        <li key={index}>
                                            <p>
                                                <strong>{feedname.feedback && feedname.feedback.responsible ? feedname.feedback.responsible.name : '' } </strong>
                                                adicionou um feedback
                                                <br />
                                                <span>{momentDurationToFeed(feedname.createdAt)}</span>
                                            </p>
                                        </li>
                                        </>
                                        :
                                        feedname.categorycollaboratorabsences                            &&
                                        feedname.categorycollaboratorabsences === 'collaboratorabsences' &&
                                        feedname.collaboratorabsences         !==  null                   ?
                                        <>
                                        <li key={index}>
                                            <p>
                                                <strong>{feedname.collaboratorabsences && feedname.collaboratorabsences.collaborator ? feedname.collaboratorabsences.collaborator.name : '' } </strong>
                                                criou um aviso de ausência
                                                <br />
                                                <span>{momentDurationToFeed(feedname.createdAt)}</span>
                                            </p>
                                        </li>
                                        </>
                                        :
                                        feedname.categorycandidate &&
                                        feedname.categorycandidate === 'candidate' ?
                                        <>
                                        <li key={index}>
                                            <p>
                                                <strong>{feedname.candidate && feedname.candidate.name ? feedname.candidate.name : '' } </strong>
                                                se candidatou na vaga de
                                                <strong> {feedname.candidate && feedname.candidate.sector ? feedname.candidate.sector : ''}</strong>
                                                <br />
                                                <span>{momentDurationToFeed(feedname.createdAt)}</span>
                                            </p>
                                        </li>
                                        </>
                                        :
                                        <>
                                        </>

                                    ))
                                    :""
                                }
                            </ul>
                            <button className="ver-mais" onClick={showMoreFeed} >Mostrar mais</button>

                        </div>
                    </div>


            </Scrollbars>
                        :
                            <>
                        <div className='feed-vazio'>
                            <div>
                                <p>Nenhuma novidade por aqui.</p>
                                <img src={iconNews} alt="Nenhuma novidade por aqui." />
                            </div>
                        </div>
                            </>
                    }


        </div>


        </>
    );

}
