import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import Dropzone from "react-dropzone";
import IconCamera from '../../../assets/images/icone-camera.svg';
import CarregandoFoto from '../../../assets/images/carregando.gif';
import { NavLink } from 'react-router-dom'
import { mCNPJ, phones } from '../../../services/mask';
import swal from 'sweetalert';
import axios from 'axios';
import api from '../../../services/api';

import './styles.scss';

export default function pageSystemConfig(props, path) {

    const { id } = props.match.params

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRazaoSocialEdit, setLoadRazaoSocialEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTelefoneEdit, setLoadTelefoneEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCnpj, setLoadCnpj] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmailEdit, setLoadEmailEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCEPEdit, setLoadCEPEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNumeroEdit, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRuaAvenidaEdit, setLoadRuaAvenidaEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBairroEdit, setLoadBairroEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEstadoEdit, setLoadEstadoEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCidadeEdit, setLoadCidadeEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadComplementoEdit, ] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costCenterDataId, setCostCenterDataId] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonUpdate, setLoadButtonUpdate] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPageCostCenterDataId] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        setLoadPageCostCenterDataId(true)
        async function loadcompany() {

            await api.get(`/costcenter/${id}`)
            .then(response => {

                setLoadPageCostCenterDataId(false)
                setCostCenterDataId(response.data);
            }).catch(error => {})
        }
        loadcompany();
    }, [id]);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costCenterDataUpdate, setCostCenterDataUpdate] = useState([]);


    async function myChangeHandlerCostCenter(event) {

        let nam = event.target.name;
        let val = event.target.value;

        //name
        if(nam === 'name') {

            if(val.length === 0) {
                setLoadRazaoSocialEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                setLoadRazaoSocialEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }


        }
        else
        //phone
        if (nam === "phone") {

            if(val.length === 0) {
                setLoadTelefoneEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                let telefone = phones(val)
                setLoadTelefoneEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: telefone })
            }


        }
        else
        if (nam === "cnpj") {

            if(val.length === 0) {
                setLoadCnpj(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                var cnpj = mCNPJ(val)
                setLoadCnpj(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: cnpj })
            }

        }
        else
        //email
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmailEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                setLoadEmailEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }

        }
        else
        if(nam === "zipcode") {


            if(val.length === 0) {
                setLoadCEPEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else
            if(val.length <=7 && val.length >= 1) {
                setLoadCEPEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {

                let zipcode = String(val).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
                //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
                // let cepppp = cep(zipcode)
                const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

                setCostCenterDataUpdate({
                    ...costCenterDataUpdate,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })

                setLoadRuaAvenidaEdit(false)
                setLoadBairroEdit(false)
                setLoadEstadoEdit(false)
                setLoadCidadeEdit(false)
            }


        }
        else
        //address
        if(nam === 'address') {

            if(val.length === 0) {
                setLoadRuaAvenidaEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                setLoadRuaAvenidaEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }

        }
        else
        //addressNeighborhood
        if(nam === 'addressNeighborhood') {

            if(val.length === 0) {
                setLoadBairroEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                setLoadBairroEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }

        }
        else
        //addressState
        if(nam === 'addressState') {

            if(val.length === 0) {
                setLoadEstadoEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                setLoadEstadoEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }

        }
        else
        //addressCity
        if(nam === 'addressCity') {

            if(val.length === 0) {
                setLoadCidadeEdit(true)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }
            else {
                setLoadCidadeEdit(false)
                setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })
            }

        }
        else {

            setCostCenterDataUpdate({ ...costCenterDataUpdate, [nam]: val })

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPageCompanyData] = useState(false);



    async function updateCostCenter() {


        if(costCenterDataUpdate && costCenterDataUpdate.length === 0) {

            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });

        }
        else {


            if(
                (costCenterDataUpdate                                   &&
                costCenterDataUpdate.name                !== undefined &&
                costCenterDataUpdate.name                !== '')        ||
                (costCenterDataUpdate.nameDescription     !== undefined &&
                costCenterDataUpdate.nameDescription     !== '')        ||
                (costCenterDataUpdate.phone               !== undefined &&
                costCenterDataUpdate.phone               !== '')        ||
                (costCenterDataUpdate.cnpj                !== undefined &&
                costCenterDataUpdate.cnpj                !== '')        ||
                (costCenterDataUpdate.email               !== undefined &&
                costCenterDataUpdate.email               !== '')        ||
                (costCenterDataUpdate.zipcode             !== undefined &&
                costCenterDataUpdate.zipcode             !== '')        ||
                (costCenterDataUpdate.addressNumber       !== undefined &&
                costCenterDataUpdate.addressNumber       !== '')        ||
                (costCenterDataUpdate.address             !== undefined &&
                costCenterDataUpdate.address             !== '')        ||
                (costCenterDataUpdate.addressNeighborhood !== undefined &&
                costCenterDataUpdate.addressNeighborhood !== '')        ||
                (costCenterDataUpdate.addressState        !== undefined &&
                costCenterDataUpdate.addressState        !== '')        ||
                (costCenterDataUpdate.addressCity         !== undefined &&
                costCenterDataUpdate.addressCity         !== '')        ||
                (costCenterDataUpdate.addressComplement   !== undefined &&
                costCenterDataUpdate.addressComplement   !== '')
            ) {

                let idCostCenter = costCenterDataId._id;

                setLoadButtonUpdate(true)
                await api.put(`/costcenter/${idCostCenter}`, costCenterDataUpdate)
                .then(response => {
                    setLoadButtonUpdate(false)

                    setLoadPageCompanyData(true)
                    async function loadcompany() {

                        await api.get(`/costcenter/${id}`)
                        .then(response => {

                            setLoadPageCompanyData(false)
                            setCostCenterDataUpdate([]);
                            setCostCenterDataId(response.data);
                        }).catch(error=> {})
                    }
                    loadcompany();

                    swal({ icon: "success", title: "Sucesso!", text: "Empresa salva com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao editar a empresa, tente novamente mais tarde." });
                });

            }

        }

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadLogoCompany, setLoadLogoCompany ] = useState(false);

    async function handleDropAvatar(acceptedFiles) {

        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        let file      = acceptedFiles[0];
        let idCostCenter = costCenterDataId._id;
        formData.append('file', file);

        setLoadLogoCompany(true)
        await api.put(`/costcenter/${idCostCenter}`, formData, config)
        .then(response => {
            setLoadLogoCompany(false)

            setLoadPageCompanyData(true)
            async function loadcompany() {

                await api.get(`/costcenter/${id}`)
                .then(response => {

                    setLoadPageCompanyData(false)
                    setCostCenterDataUpdate([]);
                    setCostCenterDataId(response.data);
                })
            }
            loadcompany();

            // swal({ icon: "success", title: "Sucesso!", text: "Logo da empresa salva com sucesso." });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao editar a logo da empresa, tente novamente mais tarde." });
        });


    }


    async function deleteAvatar() {

        let key          = 'undefined';
        let location     = 'undefined';
        let mimetype     = 'undefined';
        let originalname = 'undefined';
        let size         = 0;




        setLoadLogoCompany(true)
        await api.put(`/costcenter/${id}`, {
            key         ,
            location    ,
            mimetype    ,
            originalname,
            size        ,
         })
        .then(response => {
            setLoadLogoCompany(false)

            setLoadPageCompanyData(true)
            async function loadcompany() {

                await api.get(`/costcenter/${id}`)
                .then(response => {

                    setLoadPageCompanyData(false)
                    setCostCenterDataUpdate([]);
                    setCostCenterDataId(response.data);
                })
            }
            loadcompany();

            // swal({ icon: "success", title: "Sucesso!", text: "Logo da empresa removida com sucesso." });
        }).catch(error => {
            swal({ icon: "error", title: "Erro!", text: "Erro ao remover a logo da empresa da empresa, tente novamente mais tarde." });
        });

    }

    // console.log('costCenterDataUpdate')
    // console.log(costCenterDataUpdate)


    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-add-empresa">
                    <h2 className="titulo">Editar empresa</h2>
                    <ul>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Logo</p></div>
                                <div className="foto">
                                {
                                    loadLogoCompany ?
                                    <img src={CarregandoFoto} alt="carregando" className="carregamento-foto" />
                                    :
                                    // comapanyData &&
                                    // comapanyData.logo    !== undefined   &&
                                    // comapanyData.logo    !== 'undefined' &&
                                    costCenterDataId.location !== undefined   &&
                                    costCenterDataId.location !== 'undefined'    ?
                                    <>
                                    <Dropzone onDrop={handleDropAvatar} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "upload-foto" })}>
                                            {/* <img src={collaborator.avatar} alt={collaborator.name} className="img-adicionada"/> */}
                                            <label className="label-foto-adicionado" ><img alt="+" src={IconCamera} className="icon"/><img alt={costCenterDataId.name}  src={costCenterDataId.location} className="foto"/></label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                        </div>
                                        )}
                                    </Dropzone>
                                    <div className="remover-foto">
                                        <button onClick={deleteAvatar}>Remover foto</button>
                                    </div>
                                    </>
                                    :
                                    <Dropzone onDrop={handleDropAvatar}>
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "upload-foto" })}>
                                                        <label className="labelIconPhoto"><div  className="iconPhoto"> <span></span> Enviar foto</div> </label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                        </div>
                                        )}
                                    </Dropzone>
                                }
                                </div>
                            </div>
                        </li>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Razão social</p></div>
                                <div>
                                    <input type="text" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.name !== undefined ?
                                        costCenterDataUpdate.name
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.name !== undefined ?
                                        costCenterDataId.name
                                        :
                                        ''
                                    } name="name" onChange={myChangeHandlerCostCenter} placeholder="Razão social"  className={loadRazaoSocialEdit ? 'inputErro' : ''}/>
                                    {loadRazaoSocialEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Descrição</p></div>
                                <div>
                                    <input type="text" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.nameDescription !== undefined ?
                                        costCenterDataUpdate.nameDescription
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.nameDescription !== undefined ?
                                        costCenterDataId.nameDescription
                                        :
                                        ''
                                    } name="nameDescription" onChange={myChangeHandlerCostCenter} placeholder="Descrição" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Telefone</p></div>
                                <div>
                                    <input type="text" name="phone" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.phone !== undefined ?
                                        costCenterDataUpdate.phone
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.phone !== undefined ?
                                        costCenterDataId.phone
                                        :
                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Telefone"  className={loadTelefoneEdit ? 'inputErro' : ''}/>
                                    {loadTelefoneEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>CNPJ</p></div>
                                <div>
                                    <input type="text" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.cnpj !== undefined ?
                                        costCenterDataUpdate.cnpj
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.cnpj !== undefined ?
                                        costCenterDataId.cnpj
                                        :
                                        ''
                                    } name="cnpj" onChange={myChangeHandlerCostCenter} placeholder="CNPJ" className={loadCnpj ? 'inputErro' : ''}/>
                                    {loadCnpj? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Email</p></div>
                                <div>
                                    <input type="text" name="email" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.email !== undefined ?
                                        costCenterDataUpdate.email
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.email !== undefined ?
                                        costCenterDataId.email
                                        :
                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Email"  className={loadEmailEdit ? 'inputErro' : ''}/>
                                    {loadEmailEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <h3 className="sub-titulo">Endereço</h3>
                    <ul>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>CEP</p></div>
                                <div>
                                    <input type="text" name="zipcode" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.zipcode !== undefined ?
                                        costCenterDataUpdate.zipcode
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.zipcode !== undefined ?
                                        costCenterDataId.zipcode
                                        :
                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="CEP"  className={loadCEPEdit ? 'inputErro' : ''}/>
                                        {loadCEPEdit? (
                                            <p className="textError">O campo é obrigatório.</p>
                                        ) : ('')}
                                </div>
                            </div>
                            
                            <div className='bloco'>
                                <div className="texto"><p>Número</p></div>
                                <div>
                                    <input type="text" name="addressNumber" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.addressNumber !== undefined ?
                                        costCenterDataUpdate.addressNumber
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.addressNumber !== undefined ?
                                        costCenterDataId.addressNumber
                                        :
                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Número"  className={loadNumeroEdit ? 'inputErro' : ''}/>
                                    {loadNumeroEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Rua ou avenida</p></div>
                                <div>
                                    <input type="text" name="address" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.address !== undefined ?
                                        costCenterDataUpdate.address
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.address !== undefined ?
                                        costCenterDataId.address : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Rua ou avenida"  className={loadRuaAvenidaEdit ? 'inputErro' : ''}/>
                                    {loadRuaAvenidaEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className="texto"><p>Bairro</p></div>
                                <div>
                                    <input type="text" name="addressNeighborhood" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.addressNeighborhood !== undefined ?
                                        costCenterDataUpdate.addressNeighborhood
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.addressNeighborhood !== undefined ?
                                        costCenterDataId.addressNeighborhood : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Bairro"  className={loadBairroEdit ? 'inputErro' : ''}/>
                                    {loadBairroEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Estado</p></div>
                                <div>
                                    <input type="text" name="addressState" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.addressState !== undefined ?
                                        costCenterDataUpdate.addressState
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.addressState !== undefined ?
                                        costCenterDataId.addressState : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Estado"  className={loadEstadoEdit ? 'inputErro' : ''}/>
                                    {loadEstadoEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className="texto"><p>Cidade</p></div>
                                <div>
                                    <input type="text" name="addressCity" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.addressCity !== undefined ?
                                        costCenterDataUpdate.addressCity
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.addressCity !== undefined ?
                                        costCenterDataId.addressCity : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Cidade"  className={loadCidadeEdit ? 'inputErro' : ''}/>
                                    {loadCidadeEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Complemento</p></div>
                                <div>
                                    <input type="text" name="addressComplement" value={
                                        costCenterDataUpdate &&
                                        costCenterDataUpdate.addressComplement !== undefined ?
                                        costCenterDataUpdate.addressComplement
                                        :
                                        costCenterDataId &&
                                        costCenterDataId.addressComplement !== undefined ?
                                        costCenterDataId.addressComplement : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Complemento"  className={loadComplementoEdit ? 'inputErro' : ''}/>
                                    {loadComplementoEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="configSystem-btn-add-empresa">


                    <NavLink to={`/configuracao/empresas-cadastradas`} className="btnGray">Voltar</NavLink>
                    <button onClick={updateCostCenter} className={loadButtonUpdate ? "btnGreen cadastrar load" : "btnGreen cadastrar"}>Editar Empresa</button>

                </div>
            </Layout>
        </>
    );
}
