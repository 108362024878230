import React from 'react';
// import React, { useReducer } from 'react';
import { Modal } from "react-bootstrap";

import Carregando from '../../../../../../assets/images/carregando.gif';
import EditBlack from '../../../../../../assets/images/edit-black.svg';
import Close from '../../../../../../assets/images/close.svg';



export default function ColProfileJobInformation({
    id,
    loadPageJobInformation,
    loadAdmissionDate,
    loadJobInformation,
    workInfor,
    registeredWorkInfor,
    registeredCollaborator,
    AddAndJobInformationVisible,
    myChangeHandlerJobInfor,
    handleSubmitJobInfor,
    managers,
    visibleJobInfor,
    role,
    JobInforFution,
    selectManager,
    selectRole,
    selectAdmissionType,
    selectFormOfPayment,
    selectSyndicate,
    selectStability,
    selectPositionOfTrust,
    selectFirstJob,
    selectUnemploymentInsurance,
    selectRetiree,
    selectRegistrationWithTheProfessionalBody,
    selectProfessionalAdvice,
    selectCIPA,
    selectHavePointsRecord,
    selectSalaryType,
    setWorkInfor
}){

    return (
        <>
            <div className="box JobInformation">
                <h3>Informações de Trabalho
                    {
                        localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                            ""
                            :
                            (role && role === 'admin') || (role && role === 'master') ? <button className="edit-Benefit" onClick={() => AddAndJobInformationVisible(registeredWorkInfor._id)}><img src={EditBlack} alt="Edite Informações" /></button> : ''
                    }
                </h3>
                {
                loadPageJobInformation  ?
                <>
                <div className="carregamento">
                    <img alt="" src={Carregando} />
                </div>
                </>
                :
                <>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Data de admissão</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator.admissionDate ? registeredCollaborator.admissionDate : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Gestor</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator.nameManager ? registeredCollaborator.nameManager : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>E-mail profissional</p>
                        </div>
                        <div className="intemB">
                            <p>
                                {
                                  registeredCollaborator       &&
                                  registeredCollaborator.email !== undefined ?
                                  registeredCollaborator.email
                                  :
                                  '-'
                                }
                            </p>
                        </div>
                    </li>
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <li>
                            <div className="intemA">
                                <p>Tipo de usuário</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredCollaborator && registeredCollaborator.role !== undefined ? (
                                    registeredCollaborator.role === "user" ? "Usuário" :
                                    registeredCollaborator.role === "manager" ? "Gestor" :
                                    registeredCollaborator.role === "admin" ? "Administrador" : ""
                                    ):('-')}</p>
                            </div>
                        </li>
                        :
                        ''
                    }

                    <li>
                        <div className="intemA">
                            <p>Tipo de admissão</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator && registeredCollaborator.admissionType !== undefined ? registeredCollaborator.admissionType :'-'}</p>
                        </div>
                    </li>
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <>
                         <li>
                            <div className="intemA">
                                <p>Forma de pagamento</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.formOfPayment !== undefined ? (registeredWorkInfor.formOfPayment):('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Matrícula</p>
                            </div>
                            <div className="intemB">
                                    {registeredWorkInfor && registeredWorkInfor.registration !== undefined ? (registeredWorkInfor.registration):('-')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Sindicato</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.syndicate === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Estabilidade</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.stability !== undefined ? (registeredWorkInfor.stability):('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Cargo de Confiança</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.positionOfTrust === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Primeiro emprego</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.FirstJob === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data do exame admissional</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredCollaborator && registeredCollaborator.dateAdmissionExamination !== undefined ? (registeredCollaborator.dateAdmissionExamination):('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo de salário</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.SalaryType !== undefined ? (registeredWorkInfor.SalaryType):('-')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tem seguro desemprego</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.unemploymentInsurance === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Aposentado</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.Retiree === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Inscrição no órgão de classe</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.RegistrationWithTheProfessionalBody === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Conselho Profissional</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor &&registeredWorkInfor.professionalAdvice === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>CIPA</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.CIPA === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Possui registro de ponto?</p>
                            </div>
                            <div className="intemB">
                                <p>{registeredWorkInfor && registeredWorkInfor.havePointsRecord === true ? ('Sim'):('Não')}</p>
                            </div>
                        </li>
                        </>
                        :
                        ''
                    }
                </ul>
                </>
                }
            </div>


            <Modal show={visibleJobInfor} className="popup-edit-perfil" onHide={AddAndJobInformationVisible}>
                <div onClick={JobInforFution.closeDependentSelect}>
                    <div className="titulo">
                        <h4>Informações de Trabalho<p className="btn-fechar" onClick={AddAndJobInformationVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Data de admissão</p>
                            </div>
                            <div className="intemB">

                                    {
                                        workInfor && workInfor.admissionDate ?
                                        (
                                            <input type="text" name="admissionDate" autocomplete="autocompleteoff" value={(workInfor && workInfor.admissionDate !== undefined) ? workInfor.admissionDate : ''}  onChange={myChangeHandlerJobInfor} placeholder="DD/MM/AAAA"  className={loadAdmissionDate ? 'inputUm inputErro' : 'inputUm'} />
                                        ) :
                                        registeredCollaborator && registeredCollaborator.admissionDate ? (
                                            <input type="text" name="admissionDate" autocomplete="autocompleteoff" defaultValue={(registeredCollaborator && registeredCollaborator.admissionDate !== undefined) ? registeredCollaborator.admissionDate : ''}  onChange={myChangeHandlerJobInfor} placeholder="DD/MM/AAAA" className="inputUm"/>
                                        ):
                                        (
                                            <input type="text" name="admissionDate" autocomplete="autocompleteoff" value={(workInfor && workInfor.admissionDate !== undefined) ? workInfor.admissionDate : ''}  onChange={myChangeHandlerJobInfor} placeholder="DD/MM/AAAA"  className={loadAdmissionDate ? 'inputUm inputErro' : 'inputUm'} />
                                        )
                                    }
                                <p className="inputUm textError">{loadAdmissionDate ? 'É necessário adicionar uma data' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Matrícula</p>
                            </div>
                            <div className="intemB">
                                {registeredWorkInfor && registeredWorkInfor.registration !== undefined ?
                                (<input type="text" onChange={myChangeHandlerJobInfor} autocomplete="autocompleteoff"  defaultValue={registeredWorkInfor.registration} name="registration" />) :
                                (<input type="text" onChange={myChangeHandlerJobInfor} autocomplete="autocompleteoff" name="registration" placeholder="Matrícula"/>)}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Gestor</p>
                            </div>
                            <div className="intemB">




                                <div className='select-x'>






                                    <button className='btn-select selectManager' id='selectManager' onClick={JobInforFution.OpenSelectManager}>
                                        <span  className='span-select-selectManager'>

                                                {
                                                    workInfor && workInfor.manager !== undefined ?
                                                        managers
                                                        .filter(manager => manager._id === workInfor.manager)
                                                        .map((manager, index) => (
                                                            manager.name
                                                        ))
                                                    :
                                                        registeredCollaborator && registeredCollaborator.manager ?
                                                        registeredCollaborator.manager.name :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectManager ?
                                        <div className='resultado-busca'>
                                                {
                                                    managers && managers !== undefined && managers.length !== 0 ?
                                                        managers
                                                        .filter(manager => manager.role === "manager")
                                                        .map((manager, index) => (
                                                            <button  key={index}
                                                                onClick={() => setWorkInfor({ ...workInfor, "collaborator": id, "manager": manager._id })}
                                                            ><p>{manager.name}</p></button>
                                                        ))
                                                :
                                                <button ><p>Não possui gestor.</p></button>
                                            }
                                            {/* {managers && managers !== undefined ? "" : "Não possue gestor."} */}
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>E-mail profissional</p>
                            </div>
                            <div className="intemB">

                                {
                                    registeredWorkInfor &&
                                    registeredWorkInfor.email !== undefined  && registeredWorkInfor.email !== ''?
                                    <input type="text" onChange={myChangeHandlerJobInfor} autocomplete="off" defaultValue={registeredWorkInfor.email} name="email" placeholder="E-mail profissional" /> :
                                    registeredCollaborator       &&
                                    registeredCollaborator.email !== undefined && registeredCollaborator.email !== '' ?
                                    <input type="text" onChange={myChangeHandlerJobInfor} autocomplete="off" defaultValue={registeredCollaborator.email} name="email" placeholder="E-mail profissional"/>
                                    :
                                    <input type="text" onChange={myChangeHandlerJobInfor} autocomplete="off" name="email" placeholder="E-mail profissional"/>
                                }
                            </div>
                        </li>


                        <li>
                            <div className="intemA">
                                <p>Tipo de usuário</p>
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectRole' id='selectRole' onClick={JobInforFution.OpenSelectRole}>
                                        <span  className='span-select-selectRole'>

                                                {
                                                    workInfor && workInfor.role !== undefined ?
                                                        [  "admin", "manager", "user" ]
                                                        .filter(item => item === workInfor.role)
                                                        .map((item, index) => (
                                                            item === "user" ? "Usuário" :
                                                            item === "manager" ? "Gestor" :
                                                            item === "admin" ? "Administrador" : ""
                                                        ))
                                                    :
                                                        registeredCollaborator && registeredCollaborator.role ?
                                                            registeredCollaborator.role === "user" ? "Usuário" :
                                                            registeredCollaborator.role === "manager" ? "Gestor" :
                                                            registeredCollaborator.role === "admin" ? "Administrador" : ""
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectRole ?
                                        <div className='resultado-busca'>
                                            {


                                                [  "admin", "manager", "user" ]
                                                .filter(item =>
                                                    workInfor.role !== undefined ?
                                                    item !== workInfor.role
                                                    :
                                                    item !== registeredCollaborator.role
                                                )
                                                .map((item, index) => (

                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "role": item }
                                                        )}
                                                    >
                                                        <p>{
                                                        item === "user" ? "Usuário" :
                                                        item === "manager" ? "Gestor" :
                                                        item === "admin" ? "Administrador" : ""
                                                        }</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>

                                    {/* <select name="role" onChange={myChangeHandlerJobInfor} >
                                        {registeredCollaborator.role ?
                                        (<option value={registeredCollaborator.role}>{
                                            registeredCollaborator.role === "user" ? "User" :
                                            registeredCollaborator.role === "manager" ? "Manager" :
                                            registeredCollaborator.role === "admin" ? "Admin" : ""
                                        }</option>) :
                                        (<option value="Selecione">Selecione</option>) }
                                        {
                                        registeredCollaborator.role === "user" ? "" : <><option value="user">User</option></>
                                        }
                                        {
                                        registeredCollaborator.role === "manager" ? "" : <><option value="manager">Manager</option></>
                                        }
                                        {
                                        registeredCollaborator.role === "admin" ? "" : <><option value="admin">Admin</option></>
                                        }
                                    </select> */}
                            </div>
                        </li>


                        <li>
                            <div className="intemA">
                                <p>Tipo de admissão</p>
                                {/* selectAdmissionType */}
                            </div>
                            <div className="intemB">
                                {/* <select name="admissionType" onChange={myChangeHandlerJobInfor}>
                                    {registeredCollaborator.admissionType ?
                                    (<option value={registeredCollaborator.admissionType}>{
                                        registeredCollaborator.admissionType
                                    }</option>) :
                                    (<option value="Selecione">Selecione</option>) }
                                        {
                                        registeredCollaborator.admissionType === "Admissão" ? "" : <><option value="Admissão">Admissão</option></>
                                        }
                                        {
                                        registeredCollaborator.admissionType === "Admissão por sucessão" ? "" : <><option value="Admissão por sucessão">Admissão por sucessão</option></>
                                        }
                                        {
                                        registeredCollaborator.admissionType === "Efetivação" ? "" : <><option value="Efetivação">Efetivação</option></>
                                        }
                                        {
                                        registeredCollaborator.admissionType === "Transferência com ônus" ? "" : <><option value="Transferência com ônus">Transferência com ônus</option></>
                                        }
                                        {
                                        registeredCollaborator.admissionType === "Transferência sem ônus" ? "" : <><option value="Transferência sem ônus">Transferência sem ônus</option></>
                                        }
                                        {
                                        registeredCollaborator.admissionType === "Reintegração" ? "" : <><option value="Reintegração">Reintegração</option></>
                                        }
                                </select> */}
                                <div className='select-x'>
                                    <button className='btn-select selectAdmissionType' id='selectAdmissionType' onClick={JobInforFution.OpenSelectAdmissionType}>
                                        <span  className='span-select-selectAdmissionType'>

                                                {
                                                    workInfor && workInfor.admissionType !== undefined ?
                                                    [
                                                        "Admissão",
                                                        "Admissão por sucessão",
                                                        "Efetivação",
                                                        "Transferência com ônus",
                                                        "Transferência sem ônus",
                                                        "Reintegração"
                                                    ]
                                                        .filter(item => item === workInfor.admissionType)
                                                        .map((item, index) => (item))
                                                    :
                                                        registeredCollaborator && registeredCollaborator.admissionType ?
                                                            registeredCollaborator.admissionType
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectAdmissionType ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    "Admissão",
                                                    "Admissão por sucessão",
                                                    "Efetivação",
                                                    "Transferência com ônus",
                                                    "Transferência sem ônus",
                                                    "Reintegração"
                                                ]
                                                .filter(item =>
                                                    workInfor.admissionType !== undefined ?
                                                    item !== workInfor.admissionType
                                                    :
                                                    item !== registeredCollaborator.admissionType
                                                )
                                                .map((item, index) => (

                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "admissionType": item }
                                                        )}
                                                    >
                                                        <p>{item}</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Forma de pagamento</p>
                                {/* selectFormOfPayment */}
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectFormOfPayment' id='selectFormOfPayment' onClick={JobInforFution.OpenSelectFormOfPayment}>
                                        <span  className='span-select-selectFormOfPayment'>

                                                {
                                                    workInfor && workInfor.formOfPayment !== undefined ?
                                                    [
                                                        'Salário',
                                                        'Bolsa',
                                                        'Pró-labore',
                                                        'Por serviço prestado'
                                                    ]
                                                        .filter(item => item === workInfor.formOfPayment)
                                                        .map((item, index) => (item))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.formOfPayment ?
                                                            registeredWorkInfor.formOfPayment
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectFormOfPayment ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    'Salário',
                                                    'Bolsa',
                                                    'Pró-labore',
                                                    'Por serviço prestado'
                                                ]
                                                .filter(item =>
                                                    workInfor.formOfPayment !== undefined ?
                                                    item !== workInfor.formOfPayment
                                                    :
                                                    item !== registeredCollaborator.formOfPayment
                                                )
                                                .map((item, index) => (

                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "formOfPayment": item }
                                                        )}
                                                    >
                                                        <p>{item}</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Sindicato</p>
                                {/* selectSyndicate */}
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectSyndicate' id='selectSyndicate' onClick={JobInforFution.OpenSelectSyndicate}>
                                        <span  className='span-select-selectSyndicate'>

                                                {
                                                    workInfor && workInfor.syndicate !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.syndicate)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.syndicate !== undefined ?
                                                            registeredWorkInfor.syndicate  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectSyndicate ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.syndicate !== undefined ?
                                                        item !== workInfor.syndicate
                                                    :
                                                        item !== registeredWorkInfor.syndicate
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "syndicate": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Estabilidade</p>
                                {/* selectStability */}
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectStability' id='selectStability' onClick={JobInforFution.OpenSelectStability}>
                                        <span  className='span-select-selectStability'>

                                                {
                                                    workInfor && workInfor.stability !== undefined ?
                                                        [
                                                            'Nenhum',
                                                            'Acidente de Trabalho',
                                                            'Período de gestação',
                                                            'Acordo coletivo',
                                                            'Eleito(a) para CIPA'
                                                        ]
                                                        .filter(item => item === workInfor.stability)
                                                        .map((item, index) => ( item ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.stability !== undefined ?
                                                            registeredWorkInfor.stability
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectStability ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    'Nenhum',
                                                    'Acidente de Trabalho',
                                                    'Período de gestação',
                                                    'Acordo coletivo',
                                                    'Eleito(a) para CIPA'
                                                ]
                                                .filter(item =>
                                                    workInfor.stability !== undefined ?
                                                        item !== workInfor.stability
                                                    :
                                                        item !== registeredWorkInfor.stability
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "stability": item }
                                                        )}
                                                    >
                                                        <p>{item}</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Cargo de confiança</p>
                                {/* selectPositionOfTrust */}
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectPositionOfTrust' id='selectPositionOfTrust' onClick={JobInforFution.OpenSelectPositionOfTrust}>
                                        <span  className='span-select-selectPositionOfTrust'>

                                                {
                                                    workInfor && workInfor.positionOfTrust !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.positionOfTrust)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.positionOfTrust !== undefined ?
                                                            registeredWorkInfor.positionOfTrust  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectPositionOfTrust ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.positionOfTrust !== undefined ?
                                                        item !== workInfor.positionOfTrust
                                                    :
                                                        item !== registeredWorkInfor.positionOfTrust
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "positionOfTrust": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>



                                {/* <select name="positionOfTrust" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.positionOfTrust === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.positionOfTrust === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Primeiro emprego</p>
                                {/* selectFirstJob */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectFirstJob' id='selectFirstJob' onClick={JobInforFution.OpenSelectFirstJob}>
                                        <span  className='span-select-selectFirstJob'>

                                                {
                                                    workInfor && workInfor.FirstJob !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.FirstJob)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.FirstJob !== undefined ?
                                                            registeredWorkInfor.FirstJob  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectFirstJob ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.FirstJob !== undefined ?
                                                        item !== workInfor.FirstJob
                                                    :
                                                        item !== registeredWorkInfor.FirstJob
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "FirstJob": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>



                                {/* <select name="FirstJob" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.FirstJob === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.FirstJob === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data do exame admissional</p>
                            </div>
                            <div className="intemB">


                            {
                                        workInfor && workInfor.dateAdmissionExamination ?
                                        (
                                            <input type="text" name="dateAdmissionExamination" autocomplete="autocompleteoff" value={(workInfor.dateAdmissionExamination !== undefined) ? workInfor.dateAdmissionExamination : ''}  onChange={myChangeHandlerJobInfor} placeholder="DD/MM/AAAA"  className={loadAdmissionDate ? 'inputUm inputErro' : 'inputUm'} />
                                        ) :
                                        registeredCollaborator && registeredCollaborator.dateAdmissionExamination ? (
                                            <input type="text" name="dateAdmissionExamination" autocomplete="autocompleteoff" defaultValue={(registeredCollaborator.dateAdmissionExamination !== undefined) ? registeredCollaborator.dateAdmissionExamination : ''}  onChange={myChangeHandlerJobInfor} placeholder="DD/MM/AAAA" className="inputUm"/>
                                        ):
                                        (
                                            <input type="text" name="dateAdmissionExamination" autocomplete="autocompleteoff" value={(workInfor.dateAdmissionExamination !== undefined) ? workInfor.dateAdmissionExamination : ''}  onChange={myChangeHandlerJobInfor} placeholder="DD/MM/AAAA"  className={loadAdmissionDate ? 'inputUm inputErro' : 'inputUm'} />
                                        )
                                    }
                                <p className="inputUm textError">{loadAdmissionDate ? 'É necessário adicionar uma data' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo de salário</p>
                                {/* selectSalaryType */}
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectSalaryType' id='selectSalaryType' onClick={JobInforFution.OpenSelectSalaryType}>
                                        <span  className='span-select-selectSalaryType'>

                                                {
                                                    workInfor && workInfor.SalaryType !== undefined ?
                                                        [
                                                            'Mensalista',
                                                            'Semanalista',
                                                            'Diarista',
                                                            'Horista',
                                                            'Comissionado'
                                                        ]
                                                        .filter(item => item === workInfor.SalaryType)
                                                        .map((item, index) => ( item ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.SalaryType !== undefined ?
                                                            registeredWorkInfor.SalaryType
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectSalaryType ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    'Mensalista',
                                                    'Semanalista',
                                                    'Diarista',
                                                    'Horista',
                                                    'Comissionado'
                                                ]
                                                .filter(item =>
                                                    workInfor.SalaryType !== undefined ?
                                                        item !== workInfor.SalaryType
                                                    :
                                                        item !== registeredWorkInfor.SalaryType
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "SalaryType": item }
                                                        )}
                                                    >
                                                        <p>{item}</p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>


                            {/* <select name="SalaryType" onChange={myChangeHandlerJobInfor}>
                                {
                                    registeredWorkInfor && registeredWorkInfor.SalaryType ?
                                    (
                                        <option value={registeredWorkInfor.SalaryType}>{registeredWorkInfor.SalaryType}</option>
                                    ) :
                                    <>
                                    <option>Selecione</option>
                                    </>
                                }
                                {[
                                    'Mensalista',
                                    'Semanalista',
                                    'Diarista',
                                    'Horista',
                                    'Comissionado',
                                    ].map((item, index) => (
                                        <option value={item} key={index}>{item}</option>
                                ))}
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tem seguro desemprego</p>
                                {/* selectUnemploymentInsurance */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectUnemploymentInsurance' id='selectUnemploymentInsurance' onClick={JobInforFution.OpenSelectUnemploymentInsurance}>
                                        <span  className='span-select-selectUnemploymentInsurance'>

                                                {
                                                    workInfor && workInfor.unemploymentInsurance !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.unemploymentInsurance)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.unemploymentInsurance !== undefined ?
                                                            registeredWorkInfor.FirstJob  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectUnemploymentInsurance ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.unemploymentInsurance !== undefined ?
                                                        item !== workInfor.unemploymentInsurance
                                                    :
                                                        item !== registeredWorkInfor.unemploymentInsurance
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "unemploymentInsurance": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>


                                {/* <select name="unemploymentInsurance" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.unemploymentInsurance === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.unemploymentInsurance === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Aposentado</p>
                                {/* selectRetiree */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectRetiree' id='selectRetiree' onClick={JobInforFution.OpenSelectRetiree}>
                                        <span  className='span-select-selectRetiree'>

                                                {
                                                    workInfor && workInfor.Retiree !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.Retiree)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.Retiree !== undefined ?
                                                            registeredWorkInfor.Retiree  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectRetiree ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.Retiree !== undefined ?
                                                        item !== workInfor.Retiree
                                                    :
                                                        item !== registeredWorkInfor.Retiree
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "Retiree": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>



                                {/* <select name="Retiree" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.Retiree === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.Retiree === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Inscrição no órgão de classe</p>
                                {/* selectRegistrationWithTheProfessionalBody */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectRegistrationWithTheProfessionalBody' id='selectRegistrationWithTheProfessionalBody' onClick={JobInforFution.OpenSelectRegistrationWithTheProfessionalBody}>
                                        <span  className='span-select-selectRegistrationWithTheProfessionalBody'>

                                                {
                                                    workInfor && workInfor.RegistrationWithTheProfessionalBody !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.RegistrationWithTheProfessionalBody)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.RegistrationWithTheProfessionalBody !== undefined ?
                                                            registeredWorkInfor.RegistrationWithTheProfessionalBody  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectRegistrationWithTheProfessionalBody ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.RegistrationWithTheProfessionalBody !== undefined ?
                                                        item !== workInfor.RegistrationWithTheProfessionalBody
                                                    :
                                                        item !== registeredWorkInfor.RegistrationWithTheProfessionalBody
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "RegistrationWithTheProfessionalBody": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>




                                {/* <select name="RegistrationWithTheProfessionalBody" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.RegistrationWithTheProfessionalBody === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.RegistrationWithTheProfessionalBody === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Conselho Profissional</p>
                                {/* selectProfessionalAdvice */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectProfessionalAdvice' id='selectProfessionalAdvice' onClick={JobInforFution.OpenSelectProfessionalAdvice}>
                                        <span  className='span-select-selectProfessionalAdvice'>

                                                {
                                                    workInfor && workInfor.professionalAdvice !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.professionalAdvice)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.professionalAdvice !== undefined ?
                                                            registeredWorkInfor.professionalAdvice  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectProfessionalAdvice ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.professionalAdvice !== undefined ?
                                                        item !== workInfor.professionalAdvice
                                                    :
                                                        item !== registeredWorkInfor.professionalAdvice
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "professionalAdvice": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>


                                {/* <select name="professionalAdvice" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.professionalAdvice === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.professionalAdvice === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>CIPA</p>
                                {/* selectCIPA */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectCIPA' id='selectCIPA' onClick={JobInforFution.OpenSelectCIPA}>
                                        <span  className='span-select-selectCIPA'>

                                                {
                                                    workInfor && workInfor.CIPA !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.CIPA)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.CIPA !== undefined ?
                                                            registeredWorkInfor.CIPA  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectCIPA ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.CIPA !== undefined ?
                                                        item !== workInfor.CIPA
                                                    :
                                                        item !== registeredWorkInfor.CIPA
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "CIPA": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>




                                {/* <select name="CIPA" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.CIPA === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.CIPA === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) : (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Possui registro de ponto?</p>
                                {/* selectHavePointsRecord */}
                            </div>
                            <div className="intemB">

                                <div className='select-x'>
                                    <button className='btn-select selectHavePointsRecord' id='selectHavePointsRecord' onClick={JobInforFution.OpenSelectHavePointsRecord}>
                                        <span  className='span-select-selectHavePointsRecord'>

                                                {
                                                    workInfor && workInfor.havePointsRecord !== undefined ?
                                                        [
                                                            true,
                                                            false
                                                        ]
                                                        .filter(item => item === workInfor.havePointsRecord)
                                                        .map((item, index) => (
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                        ))
                                                    :
                                                        registeredWorkInfor && registeredWorkInfor.havePointsRecord !== undefined ?
                                                            registeredWorkInfor.havePointsRecord  === true ? "Sim" : "Não"
                                                        :
                                                        'Selecione'
                                                }

                                        </span>
                                    </button>
                                    {selectHavePointsRecord ?
                                        <div className='resultado-busca'>
                                            {
                                                [
                                                    true,
                                                    false
                                                ]
                                                .filter(item =>
                                                    workInfor.havePointsRecord !== undefined ?
                                                        item !== workInfor.havePointsRecord
                                                    :
                                                        item !== registeredWorkInfor.havePointsRecord
                                                )
                                                .map((item, index) => (
                                                    <button  key={index}
                                                        onClick={() => setWorkInfor(
                                                            { ...workInfor, "collaborator": id, "havePointsRecord": item }
                                                        )}
                                                    >
                                                        <p>
                                                            {
                                                            item  === true ? "Sim" :
                                                            item  === false ? "Não" : ""
                                                            }
                                                        </p>
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                </div>

                                {/* <select name="havePointsRecord" onChange={myChangeHandlerJobInfor} >
                                    {
                                    (registeredWorkInfor.havePointsRecord === true) ?
                                    (
                                    <>
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </>
                                    ) :
                                    registeredWorkInfor.havePointsRecord === false ?
                                    (
                                    <>
                                        <option value="false">Não</option>
                                        <option value="true">Sim</option>
                                    </>
                                    ) :
                                    (
                                        <>
                                            <option value="false">Não</option>
                                            <option value="true">Sim</option>
                                        </>
                                    )
                                    }
                                </select> */}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={handleSubmitJobInfor} className="btn-salvar">
                                    <button type="submit" className={loadJobInformation  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>

                            </div>
                        </li>
                    </ul>

                </div>
            </Modal>
        </>
    );
}
