import React from 'react';
import { NavLink } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';

const SubMenuColProfile = () => {
    return (
        <>
            <ul className="SubMenu-perfil">
                <Scrollbars>
                    <li>
                        <NavLink
                            to={`/meus-dados/profissional`}
                            exact
                            activeClassName="active"
                        >
                            <span>Informações de trabalho</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/meus-dados/profissional/termos-e-contratos`}
                            exact
                            activeClassName="active"
                        >
                            <span>Termo ou contrato</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/meus-dados/profissional/exame-periotico`}
                            exact
                            activeClassName="active"
                        >
                            <span>Exame períotico</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/meus-dados/profissional/folha-de-pagamento`}
                            exact
                            activeClassName="active"
                        >
                            <span>Recibos de pagamentos</span>
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink
                            to={`/meus-dados/profissional/jornada-de-trabalho`}
                            exact
                            activeClassName="active"
                        >
                            <span>Jornada de Trabalho</span>
                        </NavLink>
                    </li> */}
                </Scrollbars>
            </ul>
        </>
    );
};
export default SubMenuColProfile;
