import React, {useRef, useEffect} from 'react';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';



const subMenuSystemConfig = ( { path, hideSidebar } ) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPgCheckLists  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPgPrivacidade  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPgAlterarSenha  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPgContador  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPgPlanoCobranca  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPgEmpresasCadastradas  = useRef(null);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(path === '/configuracao/checklist' ) {
            focusPgCheckLists.current.focus();
        }
        else
        if(path === '/configuracao/Privacidade' ) {
            focusPgPrivacidade.current.focus();
        }
        else
        if(path === '/configuracao/alterar-senha-admin' ) {
            focusPgAlterarSenha.current.focus();
        }
        else
        if( path === '/configuracao/contadores'  ||
            path === '/configuracao/cadastrar-contador'   ||
            path ===  '/configuracao/editar-contador/:id') {
                focusPgContador.current.focus();
        }
        else
        if( path === '/configuracao/plano-e-cobranca'  ||
            path === '/configuracao/plano-e-cobranca/trocar-plano' ||
            path === '/configuracao/plano-e-cobranca/gerenciar-pagamento') {
                focusPgPlanoCobranca.current.focus();
        }
        else
        if( path === '/configuracao/empresas-cadastradas' ||
            path === '/configuracao/editar-empresa/:id' ) {
                focusPgEmpresasCadastradas.current.focus();
        }


    }, [path]);

    // console.log(path.path)/configuracao/contadores

    return (
        <>
            <ul className="menu-page-configSistem">
                <Scrollbars>

                    {
                        hideSidebar ?
                        <>
                        <li>

                        </li>
                        <li>


                        </li>
                        <li>
                            <NavLink ref={focusPgPlanoCobranca} to={`/configuracao/plano-e-cobranca`}  className={
                                path === '/configuracao/plano-e-cobranca' ||
                                path === '/configuracao/plano-e-cobranca/trocar-plano' ||
                                path.path === '/configuracao/plano-e-cobranca/gerenciar-pagamento' ? "active" : ''}><span>Plano e cobrança</span></NavLink>
                        </li>
                        <li>

                        </li>
                        <li>

                        </li>
                        <li>

                        </li>
                        <li>

                        </li>
                        </>
                        :
                        <>
                        <li>
                            <NavLink to={`/configuracao/dados-da-empresa/`}  className={
                                path.path === '/configuracao/dados-da-empresa/'  ? "active" : ''}><span>Dados da empresa</span></NavLink>
                        </li>
                        <li>
                            <NavLink ref={focusPgEmpresasCadastradas} to={`/configuracao/empresas-cadastradas`} className={
                                path === '/configuracao/empresas-cadastradas' ||
                                path.path === '/configuracao/editar-empresa/:id' ? "active" : ''}><span>Empresas cadastradas</span></NavLink>
                        </li>
                        <li>
                            <NavLink ref={focusPgPlanoCobranca} to={`/configuracao/plano-e-cobranca`}  className={
                                path === '/configuracao/plano-e-cobranca' ||
                                path === '/configuracao/plano-e-cobranca/trocar-plano' ||
                                path.path === '/configuracao/plano-e-cobranca/gerenciar-pagamento' ? "active" : ''}><span>Plano e cobrança</span></NavLink>
                        </li>
                        <li>
                            <NavLink ref={focusPgContador} to={`/configuracao/contadores`}   className={
                                path.path === '/configuracao/contadores'  ||
                                path.path === '/configuracao/cadastrar-contador'   ||
                                path.path ===  '/configuracao/editar-contador/:id' ? "active" : ''} ><span>Contadores</span></NavLink>
                        </li>
                        <li>
                            <NavLink ref={focusPgAlterarSenha} to={`/configuracao/alterar-senha-admin`} exact className={path === '/configuracao/alterar-senha' ? "active" : ''}><span>Alterar senha</span></NavLink>
                        </li>
                        <li>
                            <NavLink ref={focusPgPrivacidade} to={`/configuracao/Privacidade`} exact className={path === '/configuracao/Privacidade' ? "active" : ''}><span>Privacidade</span></NavLink>
                        </li>
                        <li>
                            <NavLink ref={focusPgCheckLists} to={`/configuracao/checklist`} exact className={path === '/configuracao/checklist' ? "active" : ''}><span>Checklist</span></NavLink>
                        </li>
                        </>
                    }

                </Scrollbars>
           </ul>

        </>
    );
}
export default subMenuSystemConfig;
