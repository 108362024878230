import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom'
import './styles.scss';
import logo from '../../assets/images/logo.svg';
import iconEyeSee from '../../assets/images/ico-see.svg';
import iconEyeSeeOff from '../../assets/images/ico-see-off.svg';
import { Scrollbars } from 'react-custom-scrollbars';

import { decodeToken } from '../../services/auth';

import { mCPF} from '../../services/mask';
import moment from 'moment';




export default function Login_() {


    const year = new Date().getFullYear()


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        alreadyIsLogged();

    }, []);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ user, setUser] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ message, setMessage] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ error, setError] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ companies, setCompanies] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectEmail, setSelectEmail] = useState(true);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectCompanies, setSelectCompanies] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectPassword, setSelectPassword] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectCompanies, setIsActiveSelectCompanies] = useState(false);
    async function SelectCompanies() { setIsActiveSelectCompanies(!isActiveSelectCompanies) }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadAccess, setLoadAccess] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadCompanies, setLoadCompanies] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPassword, setLoadPassword] = useState(false);


    async function alreadyIsLogged() {

        const alreadyToken = localStorage.getItem('@peopleview/token');

        if(alreadyToken !== null) {

            const decoded = decodeToken();

            if(
                decoded &&
                decoded.user &&
                decoded.user.companyId &&
                decoded.user.companyId.status === 'Inativo'
            ) {
                if (decoded.user.role === 'admin' || decoded.user.role === 'master') {
                    // window.location.href = '/conta-suspensa-administrador';
                    setTimeout(function(){window.location.href = '/conta-suspensa-administrador';}, 0)
                }
                else
                if (decoded.user.role   === 'user') {
                    window.location.href = '/conta-suspensa-colaborador';
                }
                else {

                }


            }
            else
            if(decoded.user.companyId?.status === 'Trial') {

                let createdAtCompanny_  = new Date(decoded.user.companyId.startOfTrial);
                let createdAtCompanny   = createdAtCompanny_.toISOString().split('T')[0]
                let createdAtCompanny_a = createdAtCompanny.split('-');
                let createdAtCompanny_b = new Date(createdAtCompanny_a[0] + "-" + createdAtCompanny_a[1] + "-" + createdAtCompanny_a[2]);

                let currentDate_ = new Date();

                let currentDate   = currentDate_.toISOString().split('T')[0]
                let currentDate_a = currentDate.split('-');
                let currentDate_b = new Date(currentDate_a[0] + "-" + currentDate_a[1] + "-" + currentDate_a[2]);



                let totalTrialDaysCompany = moment(currentDate_b).diff(moment(createdAtCompanny_b,"days"));
                let durationTotalTrialDaysCompany = moment.duration(totalTrialDaysCompany);

                if(durationTotalTrialDaysCompany._data.days !== undefined) {
                    var days   = durationTotalTrialDaysCompany._data.days;
                }
                if(durationTotalTrialDaysCompany._data.months !== undefined) {
                    var months = durationTotalTrialDaysCompany._data.months;
                }
                if(durationTotalTrialDaysCompany._data.years !== undefined) {
                    var years  = durationTotalTrialDaysCompany._data.years;
                }

                if(days <= 9 && months === 0 && years === 0) {

                    /////////////////////////////////////////////////////
                    if (decoded.user.role   === 'admin' || decoded.user.role   === 'master') {
                        const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                        if(urltokenstatusadmin !== null) {
                            window.location.href = `${urltokenstatusadmin}`;
                        } else {
                            window.location.href = '/dashboard';
                            // window.location.href = '/colaboradores/ativos';
                        }

                    }
                    else
                    if (decoded.user.role === 'manager') {


                        const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                        if(urltokenstatusadmin !== null) {
                            // alert('urltokenstatusadmin !== null')
                            window.location.href = `${urltokenstatusadmin}`;
                        } else {
                            // alert('/colaboradores/ativos')
                            window.location.href = '/colaboradores/ativos';
                        }

                    }
                    else {
                        const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                        if(urltokenstatususer !== null) {
                            window.location.href = `${urltokenstatususer}`;
                        } else {
                            window.location.href = '/dashboard-user';
                        }

                    }

                }
                else {

                    // localStorage.setItem('@peopleview/token', res.data.token);
                    window.location.href = '/configuracao/plano-e-cobranca';

                }



            }
            else {

                if (decoded.user.firstAccess) {
                    window.location.href     = '/bem-vindo';
                } else {
                    if (decoded.user.role === 'admin' || decoded.user.role === 'master') {
                        const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                        if(urltokenstatusadmin !== null) {

                            var exp = decoded.exp * 1000;

                            const dateExp      = moment(exp)._d
                            const currentDate = moment()._d;

                            const difDate = moment(currentDate).diff(moment(dateExp,"days"));
                            const difDatetransform = moment.duration(difDate);

                            const seconds = difDatetransform._data.seconds

                            if(seconds > 0) {
                                localStorage.clear();
                                // setTimeout(function(){window.location.href = '/';}, 1000);
                                return
                            }
                            else {
                                window.location.href = `${urltokenstatusadmin}`;
                            }

                        } else {
                            // window.location.href = '/dashboard';
                            window.location.href = '/colaboradores/ativos';
                        }

                    }
                    else
                    if (decoded.user.role === 'manager') {


                        const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                        if(urltokenstatusadmin !== null) {

                            var expManager = decoded.exp * 1000;

                            const dateExp      = moment(expManager)._d
                            const currentDate = moment()._d;

                            const difDate = moment(currentDate).diff(moment(dateExp,"days"));
                            const difDatetransform = moment.duration(difDate);

                            const seconds = difDatetransform._data.seconds

                            if(seconds > 0) {
                                localStorage.clear();
                                // setTimeout(function(){window.location.href = '/';}, 1000);
                                return
                            }
                            else {
                                window.location.href = `${urltokenstatusadmin}`;
                            }

                        } else {
                            window.location.href = '/colaboradores/ativos';
                        }

                    }
                    else {
                        const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                        if(urltokenstatususer !== null) {

                            var expUser = decoded.exp * 1000;

                            const dateExp      = moment(expUser)._d
                            const currentDate = moment()._d;

                            const difDate = moment(currentDate).diff(moment(dateExp,"days"));
                            const difDatetransform = moment.duration(difDate);

                            const seconds = difDatetransform._data.seconds

                            if(seconds > 0) {
                                localStorage.clear();
                                return
                            }
                            else {
                                window.location.href = `${urltokenstatususer}`;
                            }

                        } else {
                            window.location.href = '/dashboard-user';
                        }

                    }
                }

            }

        }

    }



    function myChangeHandler(event) {
        const nam = event.target.name;
        const val = event.target.value;

        if(val.length >= 4) {

            let loginAccess = val.split('.')[0]
            loginAccess = isNaN(loginAccess)

            if(nam === 'access' && val.length > 3) {
                setLoadAccess(false)
            }

            if(nam === 'password' && val.length > 7) {
                setLoadPassword(false)
            }

            if(nam === 'access' && loginAccess === false) {
                // alert('cpf')
                let cpf = mCPF(val)
                setUser({ ...user, [nam]: cpf })
            }
            else {
                // alert('email')
                setUser({ ...user, [nam]: val })
            }

        }
        else {
            setUser({ ...user, [nam]: val })

            if(nam === 'access' && val.length === 0) {
                setLoadAccess(true)
            }

            if(nam === 'password' && val.length === 0) {
                setLoadPassword(true)
            }

        }

    };

    function handlerSelectCompany(_idCompany, nameCompany) {

        let companyId   = _idCompany;
        let CompanyName = nameCompany;

        setUser({ ...user, companyId, CompanyName })
        setLoadCompanies(false)

    }

    function companyCurrent(companyActuality) {

        if(companyActuality.length === 1) {

            let companyId   = companyActuality[0]._id;
            let CompanyName = companyActuality[0].name;

            setUser({ ...user, companyId, CompanyName })
            setLoadCompanies(false)

        }

    }

    function esqueceuSenhaOuVoltaremail(e){

        if(e === 'senha') {

            window.location.href = '/esqueci-senha';

        }

        if(e === 'email') {

            setUser([])
            setSelectEmail(true)
            setSelectCompanies(false)
            setSelectPassword(false)
            setLoadCompanies(false)

        }



    }



    async function login() {

        if(
            user?.access   === undefined ||
            user?.access   ===     ''
        ) {
            setLoadAccess(true)
            return
        }

        if(
            user?.companyId   !== undefined 
        ) {
            if(user?.password === undefined || user?.password === '') {
                setLoadAccess(true)
                setLoadPassword(true)
                return
            }
        }

        setLoad(true)
        await api
        .post('/login', user)
        .then((res) => {
            setMessage("")
            setError(false)
            setLoad(false)

            if(res.data.token !== undefined) {

                localStorage.setItem('@peopleview/token', res.data.token);

                const decoded = decodeToken();

                if(

                    decoded.user.companyId?.status === 'Inativo'
                ) {

                    if (decoded.user.role   === 'admin') {
                        // window.location.href = '/conta-suspensa-administrador';
                        setTimeout(function(){window.location.href = '/conta-suspensa-administrador';}, 0)
                    }
                    else
                    if (decoded.user.role   === 'user') {
                        window.location.href = '/conta-suspensa-colaborador';
                    }
                    else {

                    }

                }
                else
                if(decoded.user.companyId?.status === 'Trial') {

                    let createdAtCompanny_  = new Date(decoded.user.companyId.startOfTrial);
                    let createdAtCompanny   = createdAtCompanny_.toISOString().split('T')[0]
                    let createdAtCompanny_a = createdAtCompanny.split('-');
                    let createdAtCompanny_b = new Date(createdAtCompanny_a[0] + "-" + createdAtCompanny_a[1] + "-" + createdAtCompanny_a[2]);

                    let currentDate_ = new Date();

                    let currentDate   = currentDate_.toISOString().split('T')[0]
                    let currentDate_a = currentDate.split('-');
                    let currentDate_b = new Date(currentDate_a[0] + "-" + currentDate_a[1] + "-" + currentDate_a[2]);


                    let totalTrialDaysCompany = moment(currentDate_b).diff(moment(createdAtCompanny_b,"days"));
                    let durationTotalTrialDaysCompany = moment.duration(totalTrialDaysCompany);

                    if(durationTotalTrialDaysCompany._data.days !== undefined) {
                        var days   = durationTotalTrialDaysCompany._data.days;
                    }
                    if(durationTotalTrialDaysCompany._data.months !== undefined) {
                        var months = durationTotalTrialDaysCompany._data.months;
                    }
                    if(durationTotalTrialDaysCompany._data.years !== undefined) {
                        var years  = durationTotalTrialDaysCompany._data.years;
                    }
                    if(days <= 9 && months === 0 && years === 0) {

                        if (decoded.user.firstAccess) {
                            window.location.href     = '/bem-vindo';
                        } else {

                            if(
                                (decoded.user.status !== 'Ativo')
                            ) {

                                setError(true)
                                setMessage('Colaborador Inativo no sistema!')

                            }
                            else {

                                /////////////////////////////////////////////////////
                                if (decoded.user.role   === 'admin' || decoded.user.role   === 'master') {
                                    const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                                    if(urltokenstatusadmin !== null) {
                                        window.location.href = `${urltokenstatusadmin}`;
                                    } else {
                                        // window.location.href = '/dashboard';
                                        window.location.href = '/colaboradores/ativos';
                                    }

                                }
                                else
                                if (decoded.user.role === 'manager') {


                                    const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                                    if(urltokenstatusadmin !== null) {
                                        // alert('urltokenstatusadmin !== null')
                                        window.location.href = `${urltokenstatusadmin}`;
                                    } else {
                                        // alert('/colaboradores/ativos')
                                        window.location.href = '/colaboradores/ativos';
                                    }

                                }
                                else {
                                    const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                                    if(urltokenstatususer !== null) {
                                        window.location.href = `${urltokenstatususer}`;
                                    } else {
                                        window.location.href = '/dashboard-user';
                                    }

                                }

                            }

                        }

                    }
                    else {

                        // localStorage.setItem('@peopleview/token', res.data.token);

                        window.location.href = '/configuracao/plano-e-cobranca';

                    }



                }
                else {

                    if (decoded.user.firstAccess) {
                        window.location.href     = '/bem-vindo';
                    } else {

                        if(
                            (decoded.user.status !== 'Ativo')
                        ) {

                            setError(true)
                            setMessage('Colaborador Inativo no sistema!')

                        }
                        else {

                            /////////////////////////////////////////////////////
                            if (decoded.user.role   === 'admin' || decoded.user.role   === 'master') {
                                const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                                if(urltokenstatusadmin !== null) {
                                    window.location.href = `${urltokenstatusadmin}`;
                                } else {
                                    // window.location.href = '/dashboard';
                                    window.location.href = '/colaboradores/ativos';
                                }

                            }
                            else
                            if (decoded.user.role === 'manager') {


                                const urltokenstatusadmin = localStorage.getItem('@peopleview/urltokenstatusadmin');

                                if(urltokenstatusadmin !== null) {
                                    // alert('urltokenstatusadmin !== null')
                                    window.location.href = `${urltokenstatusadmin}`;
                                } else {
                                    // alert('/colaboradores/ativos')
                                    window.location.href = '/colaboradores/ativos';
                                }

                            }
                            else {
                                const urltokenstatususer = localStorage.getItem('@peopleview/urltokenstatususer');

                                if(urltokenstatususer !== null) {
                                    window.location.href = `${urltokenstatususer}`;
                                } else {
                                    window.location.href = '/dashboard-user';
                                }

                            }
                            /////////////////////////////////////////////////////

                        }

                    }

                }



            }
            else
            if(res.data.companies !== undefined) {

                setCompanies(res.data.companies)
                setSelectEmail(false)
                setSelectCompanies(true)
                setSelectPassword(true)
                setLoadCompanies(true)
                companyCurrent(res.data.companies)

            }
        })
        .catch((err) => {

            setError(true)
            setLoad(false)
            setMessage(err?.response?.data?.error)

        });
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [passwordType, setPasswordType] = useState(true);
    function HideOrShowPassword(e) {
        e.preventDefault()
        setPasswordType(!passwordType)
    }

    function handleKeyPressEmail(event) {
        if (event.key === "Enter") {
            login(event);
        }
    }

    return (
        <>
            <div className="pageLogin">
                <img src={logo} alt="People View" className="logo"/>
                <div  className="formLogin login">
                    <h2>Entrar</h2>
                    {
                        selectEmail ?
                        <input
                            type="text"
                            name="access"
                            className={loadAccess ? "inputErro email" : "email"}
                            value={user.access && user.access !== '' ? user.access : ''}
                            placeholder="Email ou CPF"
                            onChange={myChangeHandler}

                            onKeyPress={(event) => handleKeyPressEmail(event)}
                            required
                        />
                        :
                        ''
                    }
                    {
                        selectCompanies ?
                        <>
                        <div className="select-busca-btn companias">
                            <button
                                className={loadCompanies ? 'inputErro menu-button' : 'menu-button'}
                                onBlur={ () => (setIsActiveSelectCompanies(false)) }
                                onClick={ SelectCompanies }
                            >
                            <span>
                                {user?.CompanyName ? user?.CompanyName : 'Selecione'}
                            </span>
                            </button>
                            <nav className={`menu ${isActiveSelectCompanies ? "ativo": ""}`} >
                                <Scrollbars className='listDropDown'  style={{  height:
                                companies?.length === 0 ? 39 : "" ||
                                companies?.length === 1 ? 39 : ""  ||
                                companies?.length === 2 ? 70 : ""  ||
                                companies?.length === 3 ? 120 : 120
                                }}>
                                    <ul>
                                        {
                                            companies && companies.length !== 0 ?
                                            companies
                                            .map((company, index) => (
                                                <li key={index}>
                                                    <button
                                                    onClick={()=>handlerSelectCompany(company._id, company.name)}
                                                    >
                                                        {company.name}
                                                    </button>
                                                </li>
                                            ))
                                            :
                                            ''
                                        }
                                    </ul>

                                </Scrollbars>
                            </nav>
                        </div>
                        </>
                        :
                        ''
                    }
                    {
                        selectPassword ?
                        <>
                        <div className="password-input">
                            <button onClick={HideOrShowPassword}
                                className={passwordType ?"":"active"}
                            >

                                {
                                    passwordType ?
                                    <img src={iconEyeSeeOff} alt="Olho" />
                                    :
                                    <img src={iconEyeSee} alt="Olho" />
                                }
                            </button>
                            <input type={passwordType ? "password": "text"} name="password" className={loadPassword ? "inputErro senha": "senha"} placeholder="Senha"
                            onKeyPress={(event) => handleKeyPressEmail(event)}
                            onChange={myChangeHandler} required/>
                        </div>
                        </>
                        :
                        ''
                    }
                    {
                    message && message ===
                    'Opss! A versão de teste terminou, Acesse seu e-mail e entre em contato com um de nossos revendedores!'
                    ?
                    <p className="emailSenhaInvalido">
                        {window.location.href = '/conta-suspensa-administrador'}
                    </p>
                    :
                    message && message !== '' ?
                    (
                        <p className="emailSenhaInvalido">
                            {message}
                        </p>
                    ) :
                    error ? (
                        <p className="emailSenhaInvalido">
                            E-mail/CPF e/ou senha inválidos!
                        </p>
                    ) : (
                        ""
                    )
                    }
                    {/* {load ? (
                    <p className="carregando">Carregando...</p>
                    ) : ("")} */}


                    <button
                    //  className="btnEntrar btnGreen"
                     className= {load ? "btnEntrar btnGreen load" : "btnEntrar btnGreen" }
                     onClick={()=>login()}

                     >Entrar</button>
                    <button
                    onClick={
                        selectEmail ?
                        ()=>esqueceuSenhaOuVoltaremail('senha')
                        :
                        ()=>esqueceuSenhaOuVoltaremail('email')
                    }
                    className="esqueceuSenha"
                    >
                        {selectEmail ? "Esqueceu sua senha?" : "Voltar para e-mail"}
                    </button>
                    <div  className="criar-conta" >
                        <Link to="/cadastro">Criar conta</Link>
                    </div>
                </div>
                <span className="copyright">© {year} People View</span>
            </div>
        </>
    );

}
