import React, {
    useState
} from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Management/headerOneToOne';
import Questions from '../../../components/Management/OneToOne/oneToOneCollaborator/questions';
import Comment from '../../../components/Management/OneToOne/oneToOneCollaborator/comment';

import { NavLink } from 'react-router-dom'
// import IconUserNone from '../../../assets/images/iconUserNone.svg';
import FotoExemploCarol from '../../../assets/images/carolina_martini.jpg';
import '../styles.scss';

export default function homeFeedbackCol(props) {

   const { path } = props.match;

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [visibleModalscheduleOneToOne, setVisibleModalScheduleOneToOne] = useState(false);

   async function modalScheduleOneToOne() {
       setVisibleModalScheduleOneToOne(!visibleModalscheduleOneToOne);
   }

       return (
           <>
              <Layout path={path}>
                   <Header 
                       path={path} 
                       modalScheduleOneToOne={modalScheduleOneToOne}
                       visibleModalscheduleOneToOne={visibleModalscheduleOneToOne}
                   />
                   <div className='pages-management'>
                       <div className='one-to-one-col'>
                           <div className='infor-geral-coll-box box-leiaute'>
                               <div className='collaborador'>
                                   <div className="foto">
                                       <img alt="" src={FotoExemploCarol}/>
                                   </div>
                               {/* <div className="semImgCol">
                                   <img  src={IconUserNone} alt="Usuario sem imagem" />
                               </div> */}

                               <div className='infor'>
                                   <strong>Anitra Vasconcelos</strong>
                                   <p>Diretora de atendimento</p>
                               </div>

                               </div>
                               <div className='status'>
                                   <span>Status</span>
                                   <p className='realizado'>Realizado</p>
                                   {/* <p className='agendado'>Agendado</p>
                                   <p className='cancelado'>Cancelado</p> */}
                               </div>
                               <div className='data'>
                                   <span>Data e horário</span>
                                   <p>8 Dez 2021 às 10:22</p>
                               </div>
                               <div className='link-reuniao'>
                                   {/*
                                       google-meet
                                       teams
                                       zoom
                                       skype
                                       */}
                                   <span className='skype'>Google Meet</span>

                                   <NavLink to="/gestao/feedback/:id">
                                           https://meet.google.com/otv-ueqf-ruz
                                   </NavLink>  
                                   
                               </div>

                           </div>

                           <div className='box-questions-comment'>
                               <Questions/>
                               <Comment/>
                           </div>
                          
                       </div>
                   </div>
               </Layout>
           </>
       );
}
