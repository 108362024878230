import React, { useState, useEffect } from 'react';

import { Modal, Dropdown } from "react-bootstrap";
import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import Carregando from '../../../../../../assets/images/carregando.gif';
import iconVerMais from '../../../../../../assets/images/more.svg';
import Carregamento from '../../../../../../assets/images/carregando.gif';
import iconUpload from '../../../../../../assets/images/docs-upload.svg';
import Close from '../../../../../../assets/images/close.svg';
import { dateMask } from '../../../../../../services/mask';
import api from '../../../../../../services/api';
import { toast } from 'react-toastify';


const ColProfiDcocTermContracts = ({ id, path }) => {
    ////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////
    /// => Aqui você faz um get nas informações acadêmicas deste colaborador
    //////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ termorcontract, setTermOrContract ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ termorcontractUpdated, setTermOrContractUpdated ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadArchive, setLoadArchive ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredTermOrContract, setRegisteredTermOrContract ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFileForm, setLoadFileForm ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadType, setLoadType ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadDate, setLoadDate ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

       if(id && id) {loadtermorcontract(id);}

    }, [id]);

    async function loadtermorcontract(id) {

        setLoadPage(true)
        await  api.get(`/collaborator-term-or-contract/${id}`)
        .then(response => {

            setLoadPage(false);
            setLoad(false);
            setFileNames(false);
            setFilterTermorContract([]);
            if(response.data[0] !== undefined) {
                setRegisteredTermOrContract(response.data);
            }
            else {
                setRegisteredTermOrContract([]);
                setLoadPage(false)
            }

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPage(false)
        })
    }

    async function deleteTermOrContract(termorcontract) {
        swal({
            title: "Atenção",
            text: "Deseja excluir este arquivo?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                await api.delete(`/collaborator-term-or-contract/${termorcontract}?path=${path}`)
                .then(() => {

                    loadtermorcontract(id);

                    swal({ icon: "success", title: "Sucesso!", text: "Arquivo removido com sucesso." });
                }).catch(() => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao remover o arquivo, tente novamente mais tarde." });
                });
            }
        });
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);



    function AddAndEditTermOrContractVisible(e) {
        //console.log(e)

        if(e !== undefined) {
            let termorcontract = e;
            setTermOrContractUpdated({
                ...termorcontract
            })
        } else {
            setTermOrContract([])
            setTermOrContractUpdated([])
        }

        setFileNames(false);
        setLoadFileForm(false);
        setLoadType(false);
        setLoadDate(false);
        setVisible(!visible);
    }

    function AddTermOrContractVisible() {
        setTermOrContract([])
        setTermOrContractUpdated([])
        setFileNames(false);
        setLoadFileForm(false);
        setLoadType(false);
        setLoadDate(false);
        setVisible(!visible);
    }



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [fileNames, setFileNames] = useState([]);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            setLoadArchive(true)
            setFileNames(acceptedFiles.map(file => file.name));
            let file = acceptedFiles[0]
            setLoadFileForm(false)
            setTermOrContract({ ...termorcontract, file })
            setLoadArchive(false)
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }
    }

    async function deleteFiles() {
        setFileNames();
        let file = undefined
        setLoadFileForm(true)
        setTermOrContract({ ...termorcontract, file })
    }



    const myChangeHandler = event => {
        let nam = event.target.name;
        let val = event.target.value;

        let collaborator = id;

        //type
        if (nam === "type") {

            setLoadType(false)
            setTermOrContract({ ...termorcontract, collaborator, [nam]: val, path: path })

        }
        else
        if (nam === "date") {

            setLoadDate(false)
            let date = dateMask(val)
            setTermOrContract({ ...termorcontract, collaborator, [nam]: date })

        }
        else {
            setTermOrContract({ ...termorcontract, collaborator, [nam]: val })

        }

    };


    async function handleSubmit(event) {
        event.preventDefault();

        if(termorcontractUpdated._id !== undefined) {

            if(termorcontract.length === 0) {

                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo!" });

            } else {

                if(
                    termorcontract.type === "" ||
                    termorcontract.date === ""
                ) {

                    swal({ icon: "error", title: "Erro!", text: "Nenhum Termo ou contrato cadastrado!" });

                } else {
                    // alert("eentrou")

                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    if (termorcontract.type !== undefined) {
                        formData.append('type', termorcontract.type);
                    }
                    if (termorcontract.file !== undefined) {
                        formData.append('file', termorcontract.file);
                    }
                    if (termorcontract.date !== undefined) {
                        formData.append('date', termorcontract.date);
                    }
                    formData.append('path', path);

                    setLoad(true)
                    await api.put(`/collaborator-term-or-contract/${termorcontractUpdated._id}`, formData, config)
                    .then(response => {

                        setTermOrContract([])
                        setTermOrContractUpdated([])
                        setVisible(!visible);
                        loadtermorcontract(id)

                        swal({ icon: "success", title: "Sucesso!", text: "Termo ou contrato atualizado com sucesso!" });

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoad(false)
                    });

                }

            }


        } else {

            if (
                termorcontract.type === undefined ||
                termorcontract.date === undefined ||
                termorcontract.file === undefined
            ) {

                if (termorcontract.type !== undefined) {
                    setLoadType(false)
                } else {
                    setLoadType(true)
                }

                if (termorcontract.date !== undefined) {
                    setLoadDate(false)
                } else {
                    setLoadDate(true)
                }

                if (termorcontract.file !== undefined) {
                    setLoadFileForm(false)
                } else {
                    setLoadFileForm(true)
                }
                return

            } else {



                if (
                    termorcontract.type !== undefined &&
                    termorcontract.file !== undefined &
                    termorcontract.date !== undefined
                ) {
                    const formData = new FormData();
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }
                    formData.append('collaborator', id);
                    formData.append('type', termorcontract.type);
                    formData.append('date', termorcontract.date);
                    formData.append('file', termorcontract.file);
                    formData.append('path', path);

                    setLoad(true)
                    await api.post("/collaborator-term-or-contract/", formData, config)
                    .then(response => {

                        setTermOrContract([])
                        setTermOrContractUpdated([])
                        setVisible(!visible);
                        loadtermorcontract(id);

                        swal({ icon: "success", title: "Sucesso!", text: "Termo ou contrato criado com sucesso!" });

                    }).catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                        setLoad(false)
                    });
                }

            }

        }


    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterTermOrContract, setFilterTermorContract ] = useState([]);



    async function loadfiltertermorcontract(event) {
        const name = event.target.value;
        setLoad(true)
        await api.get(`/collaborator-term-or-contract?id=${id}&name=${name}`)
        .then(response => {

            setLoad(false)
            setFilterTermorContract(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }



    return (
        <>

                <div className="box perfil-termo-contrato">
                    <h3>Termo contrato</h3>
                    <div className="buscaAdd">
                        <form action="">
                            <input name="name" onChange={(event) => loadfiltertermorcontract(event)} type="text" placeholder="Procure pela data e tipo..." className="busca"/>
                        </form>

                        {
                            localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                ""
                                :
                                <button className="btnGreen more" onClick={() => AddTermOrContractVisible()}>Adicionar</button>
                        }

                    </div>
                        <table>
                        <thead>
                            <tr className="tabelaTR">
                                <th className="inten-1">
                                    Tipo
                                </th>
                                <th className="inten-2">Data</th>
                                <th className="inten-3"></th>
                                <th className="inten-4"></th>
                            </tr>
                        </thead>
                        <tbody>

                        {
                            loadPage ?
                            <>
                                <div className="carregamento">
                                    <img alt="" src={Carregamento} />
                                </div>
                            </>
                            :
                            filterTermOrContract[0] !== undefined ? filterTermOrContract
                            .map((termorcontract, index) => (
                            <>
                            <tr className="tabelaTR"  key={index} >

                                <td className="inten-1"  onClick={() => AddAndEditTermOrContractVisible(termorcontract)} >
                                    <div className="motivo">
                                        <strong>{termorcontract.type}</strong>
                                    </div>
                                </td>
                                <td className="inten-2"  onClick={() => AddAndEditTermOrContractVisible(termorcontract)} >
                                    {termorcontract.date}
                                </td>
                                <td className="inten-3">

                                        <a href={termorcontract.location} target="’_blank’">
                                            <img alt="icone de documentos" src={iconUpload} />
                                            <span>{termorcontract.originalname}</span>

                                        </a>
                                </td>
                                <td className="inten-5">
                                    {
                                        localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                        ""
                                        :
                                        <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                            <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                    <button  onClick={() => AddAndEditTermOrContractVisible(termorcontract)}  name="_id" value="" type="submit">
                                                        Editar
                                                    </button>

                                                    <div>
                                                        <button onClick={() => deleteTermOrContract(termorcontract._id)} name="_id" value="" type="submit" className="red">
                                                        Excluir
                                                        </button>
                                                    </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </td>
                            </tr>
                            </>
                            )) :
                            registeredTermOrContract.length === 0 ?
                            (<div className="nenhum-cadastrado-perfil"><p>Nenhum termo ou contrato cadastrado.</p></div>):
                            registeredTermOrContract !== undefined ? registeredTermOrContract
                            .map((termorcontract, index) => (
                            <>
                            <tr className="tabelaTR"  key={index} >

                                <td className="inten-1" onClick={() => AddAndEditTermOrContractVisible(termorcontract)}>
                                    <div className="motivo">
                                        <strong>{termorcontract.type}</strong>
                                    </div>
                                </td>
                                <td className="inten-2" onClick={() => AddAndEditTermOrContractVisible(termorcontract)}>
                                    {termorcontract.date}
                                </td>
                                <td className="inten-3">

                                    <a href={termorcontract.location} target="’_blank’">
                                        <img alt="icone de documentos" src={iconUpload} />
                                        <span>{termorcontract.originalname}</span>

                                    </a>
                                </td>
                                <td className="inten-5">
                                    {
                                        localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                                            ""
                                            :
                                            <Dropdown  className="dropdown-icon dropdown-ver-mais">
                                                <Dropdown.Toggle>
                                                    <img alt="" src={iconVerMais} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                        <button  onClick={() => AddAndEditTermOrContractVisible(termorcontract)}  name="_id" value="" type="submit">
                                                            Editar
                                                        </button>

                                                        <div>
                                                            <button onClick={() => deleteTermOrContract(termorcontract._id)} name="_id" value="" type="submit" className="red">
                                                            Excluir
                                                            </button>
                                                        </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                    }
                                </td>
                            </tr>
                            </>
                            )) : (
                                <>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>

                <Modal show={visible} className="popup-edit-perfil" onHide={AddAndEditTermOrContractVisible}>
                <div>
                    <div className="titulo">
                        <h4>Termo ou contrato <p className="btn-fechar" onClick={AddAndEditTermOrContractVisible}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Tipo</p>
                            </div>
                            <div className="intemB">
                                {
                                    termorcontract.type !== undefined ?
                                    <input type="text" name="type" onChange={myChangeHandler} defaultValue={termorcontract && termorcontract.type ? termorcontract.type  : ''}  className={loadType ? 'inputDois inputErro' : 'inputDois'}/>
                                    :
                                    termorcontractUpdated.type !== undefined ?
                                    <input type="text" name="type" onChange={myChangeHandler} defaultValue={termorcontractUpdated && termorcontractUpdated.type ? termorcontractUpdated.type : ''}  className={loadType ? 'inputDois inputErro' : 'inputDois'}/>
                                    :
                                    <input type="text" name="type" onChange={myChangeHandler}  placeholder="Tipo termo ou contrato" className={loadType ? 'inputDois inputErro' : 'inputDois'}/>
                                }
                                <p className="inputUm textError">{loadType ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Data</p>
                            </div>
                            <div className="intemB">

                                {
                                    (termorcontract && termorcontract.date !== undefined) || termorcontract.date === "" ?
                                    (
                                        <input type="text" name="date" autocomplete="off" maxLength="11"  value={(termorcontract.date !== undefined) ? termorcontract.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadDate ? 'inputUm inputErro' : 'inputUm'} />
                                    ) :
                                    (termorcontractUpdated && termorcontractUpdated.date !== undefined) || termorcontractUpdated.date === "" ?
                                    (
                                        <input type="text" name="date" autocomplete="off" maxLength="11"  value={(termorcontractUpdated.date !== undefined) ? termorcontractUpdated.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadDate ? 'inputUm inputErro' : 'inputUm'} />
                                    ) :
                                    registeredTermOrContract && registeredTermOrContract.date ? (
                                        <input type="text" name="date" autocomplete="off"  maxLength="11"  defaultValue={(registeredTermOrContract.date !== undefined) ? registeredTermOrContract.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA" className="inputUm"/>
                                    ):
                                    (
                                        <input type="text" name="date" autocomplete="off"  maxLength="11"  value={(termorcontract.date !== undefined) ? termorcontract.date : ''}  onChange={myChangeHandler} placeholder="DD/MM/AAAA"  className={loadDate ? 'inputUm inputErro' : 'inputUm'} />
                                    )
                                }
                                <p className="inputUm textError">{loadDate ? 'O campo é obrigatório.' : ''}</p>
                            </div>
                        </li>

                        {

                            termorcontractUpdated.originalname !== undefined ?
                            (
                                <li>
                                    <div className="intemA">
                                        <p>Documento</p>
                                    </div>
                                    <div className="intemB">
                                        <p className="documento-anexado">
                                            <a href={termorcontractUpdated.location} target="’_blank’">
                                                {termorcontractUpdated.originalname}
                                            </a>
                                        </p>

                                    </div>
                                </li>
                            )
                            :
                            termorcontract.originalname !== undefined ?
                            (
                                <li>
                                    <div className="intemA">
                                        <p>Documento</p>
                                    </div>
                                    <div className="intemB">
                                        <p className="documento-anexado">
                                            <a href={termorcontract.location} target="’_blank’">
                                                {termorcontract.originalname}
                                            </a>
                                        </p>

                                    </div>
                                </li>
                            )
                            :
                            ('')
                        }
                        <li>
                            <div className="intemA">
                                <p>Anexar</p>
                            </div>
                            <div className={loadFileForm ? 'imgDocLabel-error intemB' : 'intemB'}>
                                {
                                    termorcontractUpdated.originalname !== undefined ?
                                    (
                                        <Dropzone className=""  onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel inputOnze"><span></span>Atualize o arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                    )
                                    :
                                    termorcontract.originalname !== undefined ?
                                    (
                                        <Dropzone className=""  onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel inputOnze"><span></span>Atualize o arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                    )
                                    :
                                    (
                                        <Dropzone  className=""  onDrop={handleDrop} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input type="file" name="file" {...getInputProps()} />
                                                <label className="imgDocLabel inputOnze"><span></span>Anexe um arquivo</label>
                                            </div>
                                            )}
                                        </Dropzone>
                                    )
                                }
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>

                                <p className="textError">{loadFileForm ? 'É necessário anexar um arquivo' : ''}</p>
                                </div>
                        </li>
                        {fileNames !== false && fileNames ?
                            fileNames.map((filename, index) => (
                            <li>
                                <div className="arquivoAnexado">

                                    {/* <h5>Enviados</h5> */}
                                    <div key={index} className="arquivo">
                                        <p className="nomeArquivo">
                                            <a href={fileNames.location} target="’_blank’">
                                            {filename}
                                            </a>
                                        </p>
                                        <p onClick={deleteFiles} className="remover">Remover anexo</p>
                                    </div>

                                </div>
                            </li>

                            )) : loadArchive ?
                            (<div className="carregando textoDoze"><img src={Carregando} alt="carregando" /></div>) :
                            ('')}


                            {
                                termorcontractUpdated && termorcontractUpdated !== undefined && termorcontractUpdated._id  ?
                                (
                                    <li>
                                        <div className="intemA">
                                        </div>
                                        <div className="intemB">
                                            <form onSubmit={handleSubmit}   className="btn-salvar">
                                                <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                            </form>
                                        </div>
                                    </li>
                                )
                                :
                                (
                                    <li>
                                        <div className="intemA">
                                        </div>

                                        <div className="intemB">
                                            <form onSubmit={handleSubmit}   className="btn-salvar">
                                                <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                            </form>
                                        </div>
                                    </li>
                                )
                            }



                    </ul>


                </div>
            </Modal>
        </>
    );
}
export default ColProfiDcocTermContracts;
