import React from 'react';

export default function pageSystemConfigAddMethodPayment({
    loadCardHolderName,
    loadCardNumber,
    loadCardExpirationDate,
    loadCardCVC,
    openPaymentManage,
    myChangeHandlerCard,
    card,
    loadButtonSaveCard,
    saveCard,
}) {


    return (
        <>
        <div className='addEdit-metodo-pagamento'>
            <h3  className="sub-titulo">Insira os detalhes do seu método de pagamento</h3>
            <button className='voltar' onClick={openPaymentManage}>Voltar para lista</button>

            <div className="form-addEdit">

            <ul>
                <li>
                    <div className="texto"><p>Nome do titular *</p></div>
                    <div className="intemB">
                        <input
                            type="text"
                            name="card_holder_name"
                            value={card?.card_holder_name}
                            placeholder='Nome do titular'
                            className={loadCardHolderName ? 'inputErro' : ''}
                            onChange={myChangeHandlerCard}
                        />

                        {loadCardHolderName ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                    </div>
                </li>
                <li>
                    <div className="texto"><p>Número do cartão *</p></div>
                    <div className="intemB">

                        <input
                            type="text" name="card_number"
                            value={card?.card_number}
                            placeholder='Número do cartão'
                            className={loadCardNumber ? 'inputErro' : ''}
                            onChange={myChangeHandlerCard}
                        />
                        {loadCardNumber ? (<p className="textError">O campo é obrigatório.</p>) : ('')}
                    </div>
                </li>
                <li>
                    <div className="texto"><p>Data de validade *</p></div>
                    <div className="intemB">
                        <input
                        type="text" name="card_expiration_date"
                        value={card?.card_expiration_date}
                        placeholder='Data de validade'
                        className={loadCardExpirationDate ? 'inputErro' : ''}
                        onChange={myChangeHandlerCard}
                        />
                        {loadCardExpirationDate ? (<p className="textError">O campo é obrigatório.</p>) : ('')}

                    </div>
                </li>
                <li>
                    <div className="texto"><p>Código de segurança *</p></div>
                    <div className="intemB">
                        <input
                        type="text" name="card_cvv"
                        value={card?.card_cvv}
                        placeholder='CVC'
                        className={loadCardCVC ? 'inputErro' : ''}
                        onChange={myChangeHandlerCard}
                        />
                        {loadCardCVC ? (<p className="textError">O campo é obrigatório.</p>) : ('')}

                    </div>
                </li>
            </ul>

            <div className="btn">
                <button onClick={() => saveCard()} className={loadButtonSaveCard ? "btnGreen load" : "btnGreen"}>Adicionar método de pagamento</button>
            </div>
            </div>
        </div>
        </>
    )
}
