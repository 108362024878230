import React from 'react';
import { formatDateString } from '../../../../../services/utils';
import Carregando from '../../../../../assets/images/carregando.gif';
import SelectPersonalizado from '../../../../Reused/selectPersonalizado'
import Dropzone from "react-dropzone";

export default function formPersonalData({
    id,
    loadDependents,
    loadNameDependent,
    loadRelationship,
    loadDateOfBirth,
    loadSchoolingDependent,
    fileNamesDependents,
    dependents,
    registeredDependents,
    onCollaboratorInputChangeNoDependent,
    onCollaboratorInputChangeDependent,
    handleDropDependent,
    deleteFilesDependent,
    handleSubmitDependent,
    deleteDependent,
    focusNameDependent,
    focusRelationshipDependent,
    focusDateofBirthDependent,
    focusSchoolingDependente,
    focusBottomDependent,
    loadBottomDependent,
    noDependentsSaved
}) {
    const optionParentesco =  [
        "Nenhum", "Cônjuge", "Companheiro(a) ou união estável com filhos", "Companheiro(a) ou união estável sem filhos", "Filho ou enteado até 21 anos", "Filho ou enteado universitário ou em escola técnica", "Irmão(ã), neto(a) ou bisneto(a) com guarda", "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola técnica", "Pais, avós e bisavós", "Incapaz", "Agregado/Outros", "Ex cônjuge que recebe pensão de alimentos"
    ]
    const optionEscolaridade = [
        "Analfabeto", "Até a 5ª série incompleta do Ensino Fundamental", "5º ano completo do Ensino Fundamental", "Do 6º ao 9º ano do Ensino Fundamental incompleto", "Ensino Fundamental completo", "Ensino Médio incompleto", "Ensino Médio completo", "Educação Superior incompleta", "Educação Superior completa", "Mestrado completo", "Mestrado incompleto", "Doutorado completo", "Doutorado incompleto", "Pós Graduação completa", "Pós Graduação incompleta", "Ensino Técnico completo", "Ensino Técnico incompleto"
    ]
    return (

        <>
            <div className="dependents">
                    <h3>Dependentes</h3>
                    <div >
                    <div className="box-dependents">
                        <div className="verificar-dependente">
                            <p className="textCampo">Possui dependentes?</p>
                            {
                            noDependentsSaved === true ?
                            <div className="inputUm">
                                <div className="radioNovo">
                                    <input disabled={true} type="radio"  name="noDependents" value="true" onChange={onCollaboratorInputChangeNoDependent} id="nao" onClick={handleSubmitDependent}/>
                                    <label htmlFor="nao"><span>Não</span></label>
                                </div>
                                <div className="radioNovo">
                                    <input type="radio" name="noDependents" value="false" onChange={onCollaboratorInputChangeNoDependent} id="sim" defaultChecked="checked"/>
                                    <label htmlFor="sim" className='checado'><span>Sim</span></label>
                                </div>
                            </div>
                            :
                                dependents.noDependents === "false" ?
                                    <div className="inputUm">
                                        <div className="radioNovo">
                                            <input type="radio" name="noDependents" value="true" onChange={onCollaboratorInputChangeNoDependent} id="nao" checked={false} onClick={handleSubmitDependent}/>
                                            <label htmlFor="nao"><span>Não</span></label>
                                        </div>
                                        <div className="radioNovo">
                                            <input type="radio" name="noDependents" value="false" onChange={onCollaboratorInputChangeNoDependent} id="sim" checked={true}/>
                                            <label htmlFor="sim" className='checado'><span>Sim</span></label>
                                        </div>
                                    </div>
                                    :

                                    <div className="inputUm">
                                        <div className="radioNovo">
                                            <input type="radio" name="noDependents" value="true" onChange={onCollaboratorInputChangeNoDependent} id="nao" checked={true} />
                                            <label htmlFor="nao"  className='checado' ><span>Não</span></label>
                                        </div>
                                        <div className="radioNovo">
                                            <input type="radio" name="noDependents" value="false" onChange={onCollaboratorInputChangeNoDependent} id="sim" checked={false} onClick={handleSubmitDependent}/>
                                            <label htmlFor="sim"><span>Sim</span></label>
                                        </div>
                                    </div>
                        }
                        </div>
                        <div className={
                        dependents && dependents.noDependents === 'true'  ?
                        "displayNone" :
                        dependents && dependents.noDependents === 'false' ?
                        "com-dependente box-grid" :
                        noDependentsSaved && noDependentsSaved === true ?
                        "com-dependente box-grid" :
                        "displayNone"
                        }>
                                <div className='linha'>
                                    <div className='box'>
                                        <p className="textCampo">Nome completo</p>
                                        <div>
                                            <input ref={focusNameDependent} type="text" name="name"  autoComplete="off" value={dependents && dependents.name ? dependents.name : ''} onChange={onCollaboratorInputChangeDependent} placeholder="Nome completo" className={loadNameDependent ? 'inputUm inputErro' : 'inputUm'}/>
                                            <p className="inputUm textError">{loadNameDependent ? 'É necessário preencher um nome' : ''}</p>
                                        </div>
                                    </div>
                                    <div className='box'>
                                        <p className="textCampo">Parentesco</p>
                                        <div>
                                            <SelectPersonalizado
                                                valorCampo={
                                                    dependents && dependents.relationship ?
                                                        dependents.relationship 
                                                        :
                                                        ''
                                                }
                                                nameButton={"relationship"}
                                                myChangeHandler={onCollaboratorInputChangeDependent}
                                                option={optionParentesco}
                                                loadError={loadRelationship}
                                                focusRef={focusRelationshipDependent}
                                            />
                                            <p className="inputDois textError">{loadRelationship ? 'O campo é obrigatório.' : ''}</p>
                                        </div>
                                    </div>
                                </div>



                                <div className='linha'>
                                    <div className='box'>
                                        <p className="textCampo">Idade</p>
                                        <div>
                                            <input ref={focusDateofBirthDependent} mask="99" type="text"  autoComplete="off" value={dependents && dependents.dateOfBirth ? dependents.dateOfBirth : ''} name="dateOfBirth" onChange={onCollaboratorInputChangeDependent} placeholder="Idade" className={loadDateOfBirth ? 'inputErro' : ''}/>
                                            <p className="textError">{loadDateOfBirth ? 'O campo é obrigatório.' : ''}</p>
                                        </div>
                                    </div>
                                    <div className='box'>
                                        <p className="textCampo">Escolaridade</p>
                                        <div>
                                        {/* optionEscolaridade */}



                                            <SelectPersonalizado
                                                valorCampo={
                                                    dependents && dependents.schooling ?
                                                        dependents.schooling 
                                                        :
                                                        ''
                                                }
                                                nameButton={"schooling"}
                                                myChangeHandler={onCollaboratorInputChangeDependent}
                                                option={optionEscolaridade}
                                                loadError={loadSchoolingDependent}
                                                focusRef={focusSchoolingDependente}
                                            />



                                            <p className="textError">{loadSchoolingDependent ? 'O campo é obrigatório.' : ''}</p>
                                        </div>
                                    </div>
                                </div>
                                    


                                <div className='linha'>
                                    <div className='box doc-dropzone'>
                                        <p className="textCampo"></p>
                                        <div>
                                            <Dropzone onDrop={handleDropDependent} >
                                                {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input type="file" name="file" {...getInputProps()} />
                                                    <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                                </div>
                                                )}
                                            </Dropzone>
                                            <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                                        </div>
                                    </div>
                                    {fileNamesDependents ?
                                        <div className='arquivoAnexado'>


                                                {fileNamesDependents ?
                                                    fileNamesDependents.map((filename, index) => (
                                                        <div key={index} className="arquivo">
                                                            <p className="nomeArquivo">
                                                                {filename}
                                                            </p>
                                                            <div>
                                                                <p onClick={deleteFilesDependent} className="remover">Remover</p>
                                                            </div>
                                                        </div>
                                                    )) : ''}
                                        </div>
                                    : 
                                    <div className='box'>
                                    </div>
                                    }
                                </div>



                                <div className='linha'>
                                    <div className='box doc-dropzone'>
                                        <p className="textCampo"></p>
                                        <div>

                                            <button ref={focusBottomDependent} onClick={handleSubmitDependent} type="submit" className={loadDependents ? 'inputCinco btnGreen btnGreenLoad btn-adicionar' : 'inputCinco btnGreen btn-adicionar'}>{loadDependents ? 'Adicionando' : 'Adicionar dependente'}</button>
                                            {loadBottomDependent ? (
                                            <p className="textError inputCinco">É necessário adicionar um dependente</p>
                                            ) : ('')
                                            }

                                        </div>
                                    </div>
                                    <div className='box'>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="dependenteEnviados">

                        {loadDependents ?
                        <div className="carregando  textoSeis"><img src={Carregando} alt="carregando" /></div> :
                        <>
                            {
                                registeredDependents && registeredDependents[0] !== undefined ?
                                <h5>Cadastrados</h5> :
                                ''
                            }
                            {
                                registeredDependents[0] !== undefined ? registeredDependents
                            .map((dependent, index) => (
                            <div className="depentende">
                                <div className="nomeDependemte">
                                    <p>{dependent.name}</p>
                                </div>

                                <div className="arquivo">
                                        <p>{dependent.originalname !== undefined ? (

                                            <a href={dependent.location} target="’_blank’">
                                            {dependent.originalname}
                                            </a>
                                            ) : (<span>Nenhum anexo</span>)}
                                            </p>
                                        </div>
                                <div className="dataArquivo">
                                        <span>Cadastrado em {formatDateString(dependent.createdAt)}</span>
                                </div>
                                <div className="btnExclui">
                                <button onClick={() => deleteDependent(dependent._id)}>Remover</button >
                                </div>
                            </div>
                            )): (
                            <div className="nenhum-depentende">
                                <p></p>
                            </div>
                            )
                            }
                        </>
                        }
                    </div>

                </div>


        </>
    );
}

