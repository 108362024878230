import React, { useState } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import IconError from '../../../assets/images/error.svg';
import ModalGeral from '../../../components/modal/modal-geral';
import swal from 'sweetalert';
import api from '../../../services/api';
import { decodeToken } from '../../../services/auth';

import './styles.scss';
export default function pageSystemConfig(props, path) {

    const decoded = decodeToken();
    var id = decoded.user.companyId._id;

    async function cancelComapany() {
        setIsExcluiConta(false)
        swal({
            title: "Atenção",
            text: "Tem certeza que deseja excluir a conta definitivamente? Não poderá ser desfeito.",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {

            if (res) {

                await api.put(`/company-admin/${id}`, { status: 'Canceled' })
                .then(response => {

                    swal({ icon: "success", title: "Sucesso!", text: "Conta cancelada com sucesso" });
                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }).catch(error => {
                    console.log(error.response.data)
                });

            }

        })

    }
    // function openTab() {
    //     window.open('https://play.google.com/store/apps/details?id=com.drishya');
    //   }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalExcluiConta, setIsExcluiConta ] = useState(false);

    return (
        <>


        <ModalGeral onClose={()=> setIsExcluiConta(false)} isModalExcluiConta={isModalExcluiConta}>
            <div className="conteudo-exclui-conta">
                <img src={IconError} alt="Cancelar assinatura" className="ilustration"/>
                    <h2  className="titulo">Tem certeza que deseja <br/> excluir a conta definitivamente?</h2>
                    <p  className="infor">Não poderá ser desfeito.</p>
                    <p  className="infor">Saiba mais sobre a <br/>
                    <a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm" target="_blank" rel="noopener noreferrer">Lei Geral de Proteção de Dados (LGPD</a>
                    </p>
                    <button onClick={cancelComapany} className="btnRed">Excluir conta</button>

                    {/* {
                        signatureCurrent &&
                        signatureCurrent.id &&
                        signatureCurrent.statusSignature === 'paid' ?
                        <button  className="btnRed" onClick={() => cancelSiganture(signatureCurrent.id)}>Cancelar assinatura</button>
                        :
                        company &&
                        company.status === 'Trial' ?
                        <button className="btnRed" onClick={cancelTrial}>Cancelar assinatura trial</button>
                        :
                        ''
                    } */}
            </div>
        </ModalGeral>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-plano-cobranca privacy">
                    <h2 className="titulo">Privacidade</h2>
                    <p>
                    Caso queira excluir sua conta e remover todos os dados, utilize o excluir abaixo.
                    Se quiser apenas cancelar a assinatura e não perder os dados, vá até a seção  <span>Gerenciar pagamento</span> e <span>selecione Cancelar assinatura</span>.
                    </p>
                    <button onClick={()=> setIsExcluiConta(true)} className="btnRed">Excluir conta</button>
                </div>

            </Layout>
        </>
    );
}
