import React, { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar';
import { NavLink } from 'react-router-dom'
//import Header from '../../../components/Administrativo/Colaboradores/header';
import IconArrowSidbarOpen from '../../../assets/images/icon-arrow-sidebarOpen.svg';
import IconArrowSidbarClosed from '../../../assets/images/icon-arrow-sidebarClosed.svg'
import iconMenu from '../../../assets/images/icon-menu-mobile.svg'
import Logo from '../../../assets/images/logo.svg'
// import api from '../../../services/api';
import './layouts.scss';
import { decodeToken } from '../../../services/auth';
// import api from '../../../services/api';
import moment from 'moment';



export default function DefaultLayout(props, {collaborator}) {
    if (props && props.children && props.children.props && props.children.props.className) {
        var path = props.children.props.className;
    }
    const { user, exp } = decodeToken();
    const startOfTrial  = user.companyId?.startOfTrial;
    const statusCompany = user.companyId?.status;

    var expiration       = exp * 1000;
    var dateExp          = moment(expiration)._d
    var currentDate      = moment()._d;
    var difDate          = moment(currentDate).diff(moment(dateExp,"days"));
    var difDatetransform = moment.duration(difDate);
    var secondsExpirade  = difDatetransform._data.seconds;


    const role = user.role;

    if(
        props.children &&
        props.children[1] &&
        props.children[1].props &&
        props.children[1].props.collaborator
    ) {

        const statusColaborador = props.children[1].props.collaborator;
        if(
            statusColaborador && statusColaborador.status === "Inativo"
        ) {
            if (localStorage.getItem('@peopleview/statusCollaborador') !== "Inativo") {
                    localStorage.setItem('@peopleview/statusCollaborador', statusColaborador.status);
            }

        } else if (
            statusColaborador === undefined ||
            statusColaborador.status === "Ativo"

        ) {
            localStorage.removeItem('@peopleview/statusCollaborador');
        }

    } else  {
        localStorage.removeItem('@peopleview/statusCollaborador');
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        statusCompanyTrial(startOfTrial, statusCompany, secondsExpirade)

        if(role === 'user') {

            window.location.href = '/dashboard-user';

        }
        else {}

    }, [role, user, startOfTrial, statusCompany, secondsExpirade]);
    
    async function statusCompanyTrial(startOfTrial, statusCompany, secondsExpirade) {

        if(statusCompany === 'Trial') {

            let createdAtCompanny_  = new Date(startOfTrial);
            let createdAtCompanny   = createdAtCompanny_.toISOString().split('T')[0]
            let createdAtCompanny_a = createdAtCompanny.split('-');
            let createdAtCompanny_b = new Date(createdAtCompanny_a[0] + "-" + createdAtCompanny_a[1] + "-" + createdAtCompanny_a[2]);

            let currentDate_ = new Date();

            let currentDate   = currentDate_.toISOString().split('T')[0]
            let currentDate_a = currentDate.split('-');
            let currentDate_b = new Date(currentDate_a[0] + "-" + currentDate_a[1] + "-" + currentDate_a[2]);

            let totalTrialDaysCompany = moment(currentDate_b).diff(moment(createdAtCompanny_b,"days"));
            let durationTotalTrialDaysCompany = moment.duration(totalTrialDaysCompany);

            if(durationTotalTrialDaysCompany._data.days !== undefined) {
                var days   = durationTotalTrialDaysCompany._data.days;
            }
            if(durationTotalTrialDaysCompany._data.months !== undefined) {
                var months = durationTotalTrialDaysCompany._data.months;
            }
            if(durationTotalTrialDaysCompany._data.years !== undefined) {
                var years  = durationTotalTrialDaysCompany._data.years;
            }




            if(days >= 9 || months > 0 || years > 0) {

                if(secondsExpirade > 0) {

                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }

                window.location.href = '/configuracao/plano-e-cobranca';
            }
            else {


                if(secondsExpirade > 0) {

                    localStorage.clear();
                    setTimeout(function(){window.location.href = '/';}, 1000);

                }


            }

        }
        else {


            if(secondsExpirade > 0) {

                localStorage.clear();
                setTimeout(function(){window.location.href = '/';}, 1000);

            }
        }
    }
    //////// SHOW SIDBAR //////////


    //////// CLOSE SIDBAR //////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sideBarShow, setSideBarShow ] = useState(false);

    async function fecharSidBarAbrir() {
        setSideBarShow(!sideBarShow)
    }


    function contaSuspensaAdministrador() {

        setTimeout(function(){window.location.href = '/conta-suspensa-administrador';}, 0)

    }

    return (

        <>
        {
            user           &&
            user.companyId &&
            user.companyId?.status === 'Inativo' ?
            contaSuspensaAdministrador()
            :
            <div className={
                sideBarShow ?
                    path && path === 'pagina-solicitar-ferias' ?
                        "layaut-container bg-pag-ferias-admin"
                        :
                        'layaut-container'
                :
                    path && path === 'pagina-solicitar-ferias' ?
                    "layaut-sem-sidbar bg-pag-ferias-admin"
                    :
                    'layaut-sem-sidbar'
            }
            >
                    <div
                    className={
                        sideBarShow ? "sidebar ativo" : "sidebar "
                    }
                    >
                        <Sidebar path={props.path} fecharSidBarAbrir={fecharSidBarAbrir}/>
                    </div>

                <div className='cont-interno'>
                    {localStorage.getItem('@peopleview/statusCollaborador') === "Inativo" ?
                        <div className='barrar-perfil-desligado'>
                            <p>Perfil desligado</p>
                        </div>

                    : ""}

                    <div className="conteudo">
                        <div className='btn-menu-responsivo'>
                            <button className={  sideBarShow ? 'btn-closed-sidebar' : 'btn-open-sidebar'  }
                                onClick={  fecharSidBarAbrir } >
                                <img src={ sideBarShow ?  IconArrowSidbarClosed :  IconArrowSidbarOpen} alt="" />
                            </button>

                        </div>
                        <div className="btn-menu-mobile">
                            <button className='menu' onClick={fecharSidBarAbrir}>
                                <img src={iconMenu} alt="menu" />
                            </button>
                            <NavLink to="/dashboard" className="logo">
                                <img src={Logo} alt="peopleview" />
                            </NavLink>
                        </div>


                        {props.children }
                    </div>

                </div>
            </div>






        }

        </>
    );
}
