import React from 'react';
import Dropzone from "react-dropzone";
//import swal from 'sweetalert';
import Carregando from '../../../../../../assets/images/carregando.gif'
//import api from '../../../../../../services/api';
import { dateFormatedToShutDown } from '../../../../../../services/utils'
import { Scrollbars } from 'react-custom-scrollbars';


const DismissalForm = ({
    id,
    onCollaboratorInputChangeStepOneShutDown,
    collaborator,
    loadTypeShutdown,
    focusTypeShutdown,
    loadEarlyWarning,
    focusEarlyWarning,
    focusPriorNoticeDate,
    focusResignationDate,
    focusDismissalExam,
    focusDismissalExaminationDate,
    focusRHObservations,
    loadPriorNoticeDate,
    loadResignationDate,
    loadDismissalExam,
    loadDismissalExaminationDate,
    loadDismissalForm,
    filesSavedDismissalForm,
    collaboratorShutdownSaved,
    handleDropDismissalForm,
    deleteFileDismissalForm,
}) => {



return (
    <>
       <div className="form-desligamentp">
           <div className='box'>
               <div className='text'>
                    <p className="textCampo">Tipo de desligamento *</p>

               </div>
               <div className='inputs'> 
                    <div className='cont-select'>
                        <button className={loadTypeShutdown ? 'select- inputErro' : 'select-'} ref={focusTypeShutdown} >
                            <p>
                                {
                                    collaborator && collaborator.typeShutdown ?
                                        collaborator.typeShutdown
                                    :
                                        collaboratorShutdownSaved && collaboratorShutdownSaved.typeShutdown ?
                                            collaboratorShutdownSaved.typeShutdown
                                            :
                                            'Selecione'
                                }
                            </p>
                        </button>

                        <div className="dropDown">
                            <Scrollbars className='listDropDown'   
                                style={{  height:  120 }}
                            >
                                {[
                                    "Demissão sem justa causa",
                                    "Demissão por justa causa",
                                    "Pedido de demissão pelo colaborador",
                                    "Acordo entre as partes",
                                    "Demissão consensual",
                                    "Término de contrato de experiência",
                                    "Quebra de contrato de experiência"
                                ]
                                    .map((intem, index) => (
                                        <button  key={index} name="typeShutdown" value={intem}  onClick={onCollaboratorInputChangeStepOneShutDown} className='item'>
                                            {intem}
                                        </button>
                                    ))
                                } 
                                </Scrollbars>
                        </div>
                    </div>

                    {loadTypeShutdown ? (
                        <p className="textError">'É necessário selecionar o Tipo de desligamento'</p>
                    ) : ('')}

               </div>
           </div>

           <div className='box'>

               <div className='text'>
                    <p className="textCampo">Aviso prévio *</p>
               </div>

               <div className='inputs'>

                    <div className='cont-select'>
                        <button className={loadEarlyWarning ? 'select- inputErro' : 'select-'}ref={focusEarlyWarning}>
                            <p>
                                {
                                    collaborator && collaborator?.earlyWarning ?
                                            collaborator.earlyWarning === "true" || 
                                            collaborator.earlyWarning === true ? 'Sim' : 'Não'
                                    :
                                        collaboratorShutdownSaved && 
                                        (
                                            collaboratorShutdownSaved?.earlyWarning  === false ||
                                            collaboratorShutdownSaved?.earlyWarning  === true
                                        )?
                                            collaboratorShutdownSaved.earlyWarning === "true" || 
                                            collaboratorShutdownSaved.earlyWarning === true ? 'Sim' : 'Não'
                                        :
                                        'Selecione'
                                }
                            </p>
                        </button>

                        <div className="dropDown">
                            <Scrollbars className='listDropDown'   
                                style={{  height:  65 }}
                            >
                                {[
                                    true,
                                    false
                                ]
                                    .map((intem, index) => (
                                        <button  key={index} name="earlyWarning" value={intem}  onClick={onCollaboratorInputChangeStepOneShutDown} className='item'>
                                            {
                                                intem === true ? "Sim" : "Não"
                                            }
                                        </button>
                                    ))
                                } 
                                </Scrollbars>
                        </div>
                    </div>


                    {/* <select ref={focusEarlyWarning} name="earlyWarning" onChange={onCollaboratorInputChangeStepOneShutDown} className={loadEarlyWarning ? 'inputDois inputErro' : 'inputDois'}>
                    {collaboratorShutdownSaved !== undefined && collaboratorShutdownSaved.earlyWarning !== undefined ?
                    (
                        <>
                        <option value={collaboratorShutdownSaved.earlyWarning}>{collaboratorShutdownSaved.earlyWarning === true ? 'Sim' : 'Não'}</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                        </>
                    )             :
                    (
                        <>
                        <option>Selecione</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                        </>
                    )
                    }
                    </select> */}


                    {loadEarlyWarning ? (
                        <p className="textError">'É necessário preencher um nome'</p>
                    ) : ('')}

               </div>
           </div>
            
            {
                (collaborator &&
                collaborator.earlyWarning &&
                collaborator.earlyWarning === 'true')
                ||
                (collaboratorShutdownSaved &&
                collaboratorShutdownSaved.earlyWarning &&
                collaboratorShutdownSaved.earlyWarning === true) ?
                <>

                <div className='box'>
    
                   <div className='text'>
                        <p className="textCampo avisoPrevioTexto">Data do aviso prévio *</p>
                   </div>
                   <div className='inputs'>
                        <input
                            ref={focusPriorNoticeDate}
                            value={
                                (collaborator && collaborator.length !== 0 && collaborator.priorNoticeDate !== undefined) ||
                                (collaborator && collaborator.length !== 0 && collaborator.priorNoticeDate && collaborator.priorNoticeDate === "") ?
                                collaborator.priorNoticeDate                             :
                                collaboratorShutdownSaved                                &&
                                collaboratorShutdownSaved.priorNoticeDate !== undefined  &&
                                collaboratorShutdownSaved.priorNoticeDate === 'undefined' ?
                                ""                                                       :
                                collaboratorShutdownSaved                                &&
                                collaboratorShutdownSaved.priorNoticeDate !== undefined  ?
                                collaboratorShutdownSaved.priorNoticeDate                :
                                ''
                            }
                            type="text"
                            name="priorNoticeDate"
                            onChange={onCollaboratorInputChangeStepOneShutDown}
                            placeholder="DD/MM/AAAA"
                            className={loadPriorNoticeDate ? 'avisoPrevioInput inputErro' : 'avisoPrevioInput'}
                        />
    
                   </div>
                   
               </div>
                </>
                :
                ''
            }
            <div className='box'>
               <div className='text'>
                    <p className="textCampo">Data do desligamento *</p>
               </div>
               <div className='inputs'>
                    <input
                        ref={focusResignationDate}
                        type="text"
                        value={
                            (collaborator && collaborator.length !== 0 && collaborator.resignationDate !== undefined) ||
                            (collaborator && collaborator.length !== 0 && collaborator.resignationDate && collaborator.resignationDate === "") ?
                            collaborator.resignationDate                             :
                            collaboratorShutdownSaved                                &&
                            collaboratorShutdownSaved.resignationDate !== undefined  ?
                            collaboratorShutdownSaved.resignationDate                :
                            ''
                        }
                        name="resignationDate"
                        onChange={onCollaboratorInputChangeStepOneShutDown}
                        placeholder="DD/MM/AAAA"
                        className={loadResignationDate ? 'inputTreis inputErro' : 'inputTreis'}
                    />
                    {loadResignationDate ? (
                        <p className="inputTreis textError">'É necessário digitar uma data'</p>
                    ) : ('')}
               </div>
           </div>

           <div className='box'>
               <div className='text'>
                    <p className="textCampo textoQuatro">Exame demissional *</p>
               </div>
               <div className='inputs'>
                    <div className='cont-select' >
                        <button className={loadDismissalExam ? 'select- inputErro' : 'select-'}ref={focusDismissalExam} >
                            <p>
                                {
                                    collaborator && collaborator?.dismissalExam ?
                                            collaborator.dismissalExam === "true" || 
                                            collaborator.dismissalExam === true ? 'Sim' : 'Não'
                                    :
                                        collaboratorShutdownSaved && 
                                        (
                                            collaboratorShutdownSaved?.dismissalExam  === false ||
                                            collaboratorShutdownSaved?.dismissalExam  === true
                                        )?
                                            collaboratorShutdownSaved.dismissalExam === "true" || 
                                            collaboratorShutdownSaved.dismissalExam === true ? 'Sim' : 'Não'
                                        :
                                        'Selecione'
                                }
                            </p>
                        </button>

                        <div className="dropDown">
                            <Scrollbars className='listDropDown'   
                                style={{  height:  65 }}
                            >
                                {[
                                    true,
                                    false
                                ]
                                    .map((intem, index) => (
                                        <button  key={index} name="dismissalExam" value={intem}  onClick={onCollaboratorInputChangeStepOneShutDown} className='item'>
                                            {
                                                intem === true ? "Sim" : "Não"
                                            }
                                        </button>
                                    ))
                                } 
                                </Scrollbars>
                        </div>
                    </div>


                    {/* <select ref={focusDismissalExam} name="dismissalExam" onChange={onCollaboratorInputChangeStepOneShutDown} className={loadDismissalExam ? 'inputQuatro inputErro' : 'inputQuatro'}>
                    {collaboratorShutdownSaved !== undefined && collaboratorShutdownSaved.dismissalExam !== undefined ?
                    (
                        <>
                        <option value={collaboratorShutdownSaved.dismissalExam}>{collaboratorShutdownSaved.dismissalExam === true ? 'Sim' : 'Não'}</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                        </>
                    )             :
                    (
                        <>
                        <option>Selecione</option>
                        <option value={true}>Sim</option>
                        <option value={false}>Não</option>
                        </>
                    )
                    }
                    </select> */}



                    {loadDismissalExam ? (
                        <p className="textError">'É necessário preencher um nome'</p>
                    ) : ('')}


               </div>
           </div>
           
            {collaboratorShutdownSaved !== undefined && collaboratorShutdownSaved.dismissalExam === true ?
                <>
                <div className='box'>
                    <div className='text'>
                        <p className="textCampo">Data do exame demissional *</p>
                    </div>
                    <div className='inputs'>
                        <input
                            type="text"
                            name="dismissalExaminationDate"
                            ref={focusDismissalExaminationDate}
                            value={
                                (collaborator &&
                                collaborator.length                       !==     0      &&
                                collaborator.dismissalExaminationDate !== undefined)               ||
                                (collaborator &&
                                collaborator.length                       !==     0      &&
                                collaborator.dismissalExaminationDate                             &&
                                collaborator.dismissalExaminationDate              ===     "")     ?
                                collaborator.dismissalExaminationDate                             :
                                collaboratorShutdownSaved                                &&
                                collaboratorShutdownSaved.dismissalExaminationDate !== undefined  ?
                                collaboratorShutdownSaved.dismissalExaminationDate                :
                                ''
                            }
                            onChange={onCollaboratorInputChangeStepOneShutDown}
                            placeholder="DD/MM/AAAA"
                            className={loadDismissalExaminationDate ? 'inputCinco inputErro' : 'inputCinco'}
                        />
                        {loadDismissalExaminationDate ? (
                            <p className="inputCinco textError">'É necessário digitar uma data'</p>
                        ) : ('')}

                    </div>
                </div>
                </>
            :
            collaborator.dismissalExam === 'true' ?
            <>
                <div className='box'>
                    <div className='text'>
                        <p className="textCampo textoCinco">Data do exame demissional *</p>
                    </div>
                    <div className='inputs'>
                        <input
                            type="text"
                            name="dismissalExaminationDate"
                            ref={focusDismissalExaminationDate}
                            value={
                                (collaborator &&
                                collaborator.length                       !==     0      &&
                                collaborator.dismissalExaminationDate !== undefined)               ||
                                (collaborator &&
                                collaborator.length                       !==     0      &&
                                collaborator.dismissalExaminationDate                             &&
                                collaborator.dismissalExaminationDate              ===     "")     ?
                                collaborator.dismissalExaminationDate                             :
                                collaboratorShutdownSaved                                &&
                                collaboratorShutdownSaved.dismissalExaminationDate !== undefined  ?
                                collaboratorShutdownSaved.dismissalExaminationDate                :
                                ''
                            }
                            onChange={onCollaboratorInputChangeStepOneShutDown}
                            placeholder="DD/MM/AAAA"
                            className={loadDismissalExaminationDate ? 'inputCinco inputErro' : 'inputCinco'}
                        />
                        {loadDismissalExaminationDate ? (
                            <p className="inputCinco textError">'É necessário digitar uma data'</p>
                        ) : ('')}

                    </div>
                </div>
                </>
            :
            (
               ''
            )
            }
            <div className='box'>
                <div className='text'>
                    <p className="textCampo">Desvincular benefícios</p>
                </div>
                <div className='inputs'>
                    <div className="inputSeis chekbox">
                        {
                        collaborator.unlinkBenefits === 'true' ?
                        (
                            <>
                            <input type="radio" value={true} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsSim" checked={true}/>
                            <label htmlFor="unlinkBenefitsSim" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsSim" className="foreignTexto">Sim</label>

                            <input type="radio" value={false} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsNao" checked={false}/>
                            <label htmlFor="unlinkBenefitsNao" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsNao" className="foreignTexto">Não</label>
                            </>
                        ) :
                        collaborator.unlinkBenefits === 'false' ?
                        (
                            <>
                            <input type="radio" value={true} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsSim" checked={false}/>
                            <label htmlFor="unlinkBenefitsSim" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsSim" className="foreignTexto">Sim</label>

                            <input type="radio" value={false} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsNao" checked={true}/>
                            <label htmlFor="unlinkBenefitsNao" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsNao" className="foreignTexto">Não</label>
                            </>
                        ) :
                        collaboratorShutdownSaved !== undefined && collaboratorShutdownSaved.unlinkBenefits !== undefined && collaboratorShutdownSaved.unlinkBenefits === true ?
                        (
                            <>
                            <input type="radio" value={true} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsSim" checked={true}/>
                            <label htmlFor="unlinkBenefitsSim" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsSim" className="foreignTexto">Sim</label>

                            <input type="radio" value={false} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsNao" checked={false}/>
                            <label htmlFor="unlinkBenefitsNao" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsNao" className="foreignTexto">Não</label>
                            </>
                        )  :
                        collaboratorShutdownSaved !== undefined && collaboratorShutdownSaved.collaborator !== undefined && collaboratorShutdownSaved.unlinkBenefits === false ?
                        (
                            <>
                            <input type="radio" value={true} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsSim" checked={false}/>
                            <label htmlFor="unlinkBenefitsSim" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsSim" className="foreignTexto">Sim</label>

                            <input type="radio" value={false} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsNao" checked={true}/>
                            <label htmlFor="unlinkBenefitsNao" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsNao" className="foreignTexto">Não</label>
                            </>
                        ) :
                        (
                            <>
                            <input type="radio" value={true} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsSim" checked={false}/>
                            <label htmlFor="unlinkBenefitsSim" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsSim" className="foreignTexto">Sim</label>

                            <input type="radio" value={false} name="unlinkBenefits" onChange={onCollaboratorInputChangeStepOneShutDown} id="unlinkBenefitsNao" checked={true}/>
                            <label htmlFor="unlinkBenefitsNao" className="chackboxLabel"></label>
                            <label htmlFor="unlinkBenefitsNao" className="foreignTexto">Não</label>
                            </>
                        )
                        }


                    </div>

                </div>
            </div>


            <div className='box'>
                <div className='text'>
                    <p className="textCampo">Observações do RH</p>
                </div>
                <div className='inputs'>
                    {
                        collaboratorShutdownSaved !== undefined && collaboratorShutdownSaved.rhObservations !== undefined ?
                        (
                            <>
                            <textarea ref={focusRHObservations} className="inputSete" name="rhObservations" onChange={onCollaboratorInputChangeStepOneShutDown} cols="150" defaultValue={collaboratorShutdownSaved.rhObservations}></textarea>
                            </>
                        ) :
                        (
                            <>
                            <textarea ref={focusRHObservations} className="inputSete" name="rhObservations" onChange={onCollaboratorInputChangeStepOneShutDown} cols="150" placeholder="Digite uma observação (opcional)"></textarea>
                            </>
                        )
                    }

                    {/* <p className="inputSete textError msgTextarea"></p> */}
                </div>
            </div>



            <div className='box'>
                <div className='text'>
                    <p className="textCampo">Carta de desligamento</p>

                </div>
                <div className='inputs'>

                    <div>
                        <Dropzone onDrop={handleDropDismissalForm} >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <input type="file" name="file" {...getInputProps()} />
                                <label className="imgDocLabel"><span></span>Selecione ou arraste o arquivo</label>
                            </div>
                            )}
                        </Dropzone>

                        <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                        {loadDismissalForm ?
                        (<div className="carregando textoDez"><img src={Carregando} alt="carregando" /></div>) :
                        ('')}
                    </div>

                </div>
            </div>

            <div className="ArquivosEnviados">
            {filesSavedDismissalForm ?
                filesSavedDismissalForm.map((filesaved, index) => (
                    <div  key={index}  className="arquivos">
                        <div className="nameArquivo">


                            <a href={filesaved.location} target="’_blank’">
                                {filesaved.originalname}
                            </a>
                        </div>
                        <div className="dataArquivo">
                            <span>Enviado em {dateFormatedToShutDown(filesaved.createdAt)}</span>
                        </div>
                        <div className="btnExclui">
                            <button onClick={()=>deleteFileDismissalForm(filesaved)}>Excluir anexo</button>
                        </div>
                    </div>
                )) : ''}

</div>
       </div>

    </>
);
}
export default DismissalForm;
