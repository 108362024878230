import React,{useState} from 'react';
import { NavLink } from 'react-router-dom'
import Close from '../../../assets/images/close.svg';
import iconOrdenation from '../../../assets/images/ordenation.svg';
import iconVerMais from '../../../assets/images/more.svg';
import Carregando from '../../../assets/images/carregando.gif';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import { Modal, Dropdown } from "react-bootstrap";
import { dateFormatedToDetailVacation, dataFormatadaParaBarra } from '../../../services/utils'
import api from '../../../services/api';
import { dateFormatedToShutDown } from '../../../services/utils';
import { toast } from 'react-toastify';

export default function SaldoFerias({
    collaboratorVestingPeriod,
    loadcollaboratorVestingPeriod,

    nameFilter,
    filterCollaboratorVacations,
    onUserInputChange,
    pages,
    page,
    paginationPage,
    nextPage,
    prevPage,
    myChangeHandler,
    startVacationProcess,
    setStartVacationProcess,
    saveStartVacationProcess,
    loadSalveInicialFerias,
    loadInitialDate,
    loadFinalDate,
    loadvacationDaysSold,
    loadComments,

    collaboratorVacationDetails,
    collaboratorVacationDetailID,
    VerDetalhesCol,
    fecharVerDetalhesCol,
    visibleVerDetalhesCol,
    visibleIniciaFeriasCol,
    IniciaFeriasColl,
    fecharIniciaFeriasColl,
    load,
    role,
    setSortBy,
    modalMobile,
    anoSelecionado,
    setAnoSelecionado,
    continuarPedidoFeria,
    setContinuarPedidoFeria,
}) {

    let numberPaginate = []

    for(let i=0; i<=pages; i++){
        numberPaginate.push(
            <li key={i} className="number">
                <button className={i === page ? "secao" : ""} onClick={() => paginationPage(i)}>{i+1}</button>
            </li>
        )
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [anoSelecionadoInfor, setAnoSelecionadoInfor] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [anoSelecionadoInforAprovaod, setAnoSelecionadoInforAprovaod] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadInforRequisition, setLoadInforRequisition] = useState(false);

    function VerDetalhesAno(e) {
        setAnoSelecionado(e.referenceYear)
        async function DetalheAnoSelecionado() {
            setLoadInforRequisition(true)
            await api.get(`collaborator-vacation-new?collaborator=${e.collaborator._id}&vestingPeriodReferenceYear=${e.referenceYear}&type=individual`)
            .then(response => {

                setAnoSelecionadoInfor(response.data.collaboratorVacationNewSearch)

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });

            await api.get(`collaborator-vacation-new?collaborator=${e.collaborator._id}&vestingPeriodReferenceYear=${e.referenceYear}&status=aprovado&type=individual`)
            .then(response => {

                setAnoSelecionadoInforAprovaod(response.data.collaboratorVacationNewSearch)

            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
            setLoadInforRequisition(false)

        }
        DetalheAnoSelecionado()

    }

    function fecharDetalhesAno() {
        setAnoSelecionado("")
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filterDisponivelAno, setFilterDisponivelAno] = useState("nao_utilizado");

    function filtrarFeriasUltilizada(e) {
        setFilterDisponivelAno(e)
    }
    function selecionarAno(e) {
       let val = e ;
        setStartVacationProcess({...startVacationProcess, "vestingPeriodReferenceYear" :val});
    }


    function anoSelecionadoContinua() {
        setContinuarPedidoFeria(false)
    }
    function anoSelecionadoVoltar() {
        setContinuarPedidoFeria(true)
    }   

    return (
        <>
            <div className="saldo-ferias">
                <div className="sub-head">
                    <h2 className="titulo">
                        Saldo de férias
                    </h2>
                    <input name="name" autocomplete="off" value={nameFilter && nameFilter !== undefined ? nameFilter : ''} onChange={onUserInputChange} placeholder="Filtrar por colaborador ou setor" className="campo-busca"/>
                  
                </div>
                <div className="tabela-saldo-ferias">
                    <thead>
                        <tr>
                            <th className="nome">
                                <p>Nome</p>
                            </th>
                            <th className="setor">
                                <p>Setor</p>
                            </th>
                            <th className="proximo-vencimento">
                                <p>Próximo <br />vencimento</p>
                            </th>
                            <th className="saldo-disponivel">
                                <p>Saldo <br />disponível</p>
                            </th>
                            <th className="th-dopdown-vermais">
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconOrdenation} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" onClick={()=>setSortBy('ordem_alfabetica')}>
                                            Ordem alfabética
                                        </button>
                                        <button type="button" name="name" onClick={()=>setSortBy('por_setor')}>
                                            Por setor
                                        </button>
                                        <button type="button" name="name" onClick={()=>setSortBy('proximos_a_vencer_primeiro')}>
                                            Próximos a vencer primeiro
                                        </button>
                                        <button type="button" name="name" onClick={()=>setSortBy('por_saldo_disponivel')}>
                                            Por saldo disponível
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        load ? (
                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            ) :
                            filterCollaboratorVacations &&
                            filterCollaboratorVacations.lenght !== 0 ?
                            filterCollaboratorVacations
                            .map((filtercollaborator, index) => (
                                <>
                                <tr key={index}>
                                    <td  className="nome" onClick={() => VerDetalhesCol(filtercollaborator)}><p>{filtercollaborator?.nameCollaborator ? filtercollaborator?.nameCollaborator : '-'}</p></td>
                                    <td className="setor" onClick={() => VerDetalhesCol(filtercollaborator)}><p>
                                        {
                                        filtercollaborator?.collaborator &&
                                        filtercollaborator?.sectorCollaborator ?
                                        filtercollaborator?.sectorCollaborator
                                        :
                                        '-'
                                        }
                                        </p></td>
                                    <td className="proximo-vencimento"  onClick={() => VerDetalhesCol(filtercollaborator)}><p>{filtercollaborator?.limitConcessionPeriod ? dataFormatadaParaBarra(filtercollaborator?.limitConcessionPeriod) : '-'}</p></td>
                                    <td className="saldo-disponivel"  onClick={() => VerDetalhesCol(filtercollaborator)}><p>{filtercollaborator?.balanceAvailable ? filtercollaborator?.balanceAvailable : '0'}</p></td>
                                    <td className="td-dopdown-acoes">
                                        <Dropdown  className="dropdown-icon">
                                            <Dropdown.Toggle>
                                            <img alt="" src={iconVerMais} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                   role === 'master' || role === 'admin' ?
                                                   <button type="button" name="verDetalher" onClick={() => VerDetalhesCol(filtercollaborator)}>
                                                        Ver detalhes
                                                    </button>
                                                   :
                                                   ''
                                                }
                                                {
                                                    filtercollaborator?.collaborator !== null ?
                                                    <NavLink to={`/colaboradores/${
                                                        filtercollaborator?.collaborator !== null ?
                                                        filtercollaborator?.collaborator
                                                        :
                                                        ""
                                                        }/pessoal`}>Ver perfil
                                                    </NavLink>
                                                    :
                                                    ""
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                                </>
                            )) :
                            ''
                    }

                    {
                        pages && pages !== 0 ?
                        <>
                        <div className="paginacaoG">
                            <ul>
                                <div className='numberPaginate-div'>
                                    {
                                        numberPaginate
                                    }
                                </div>
                                <li className="page-anterior">
                                    <button style={(page === 0) ? { cursor: "not-allowed" } : {}}  disabled={page === 0} onClick={() => prevPage(page)} >anterior</button>
                                </li>
                                <li className="page-proxima">
                                    <button style={(page === pages) ? { cursor: "not-allowed" } : {}}  disabled={page === pages} onClick={() => nextPage(page)} >próxima</button>
                                </li>
                            </ul>
                        </div>
                        </>
                        :
                        ''
                    }


                    </tbody>
                </div>
            </div>

            <Modal show={visibleVerDetalhesCol}
            className={modalMobile ? "popup-geral popup-ferias-verDetalhesCol modalMobile"  : "popup-geral popup-ferias-verDetalhesCol" }
            onHide={fecharVerDetalhesCol}>
                <div className="titulo">
                    <h4>Detalhes <p className="btn-fechar" onClick={fecharVerDetalhesCol}><img src={Close} alt="Fechar" /></p></h4>
                </div>

                <div className="infor-cal">
                    {
                        collaboratorVestingPeriod                 &&
                        collaboratorVestingPeriod[0]              &&
                        collaboratorVestingPeriod[0]?.collaborator?.avatar ?
                        <div className="img-col">
                            <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                <img src={collaboratorVestingPeriod[0]?.collaborator?.avatar} alt="nome" />
                            </NavLink>
                        </div>
                        :
                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="coll" />
                            </div>
                        </NavLink>
                    }
                    <div className="nome-setor">
                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                            <span>
                                {
                                    collaboratorVestingPeriod                 &&
                                    collaboratorVestingPeriod[0]              &&
                                    collaboratorVestingPeriod[0]?.collaborator?.name ?
                                    collaboratorVestingPeriod[0]?.collaborator?.name :
                                    '-'
                                }
                            </span>
                        </NavLink>
                        <div className="setor">
                            <p>
                                {
                                    collaboratorVestingPeriod                 &&
                                    collaboratorVestingPeriod[0]              &&
                                    collaboratorVestingPeriod[0]?.collaborator?.occupation ?
                                    collaboratorVestingPeriod[0]?.collaborator?.occupation :
                                    '-'
                                }
                            </p>
                        </div>
                    </div>
                    <div className="data-admissao">
                        Data de admissão
                        <span>
                            {
                                collaboratorVestingPeriod                 &&
                                collaboratorVestingPeriod[0]              &&
                                collaboratorVestingPeriod[0]?.collaborator?.admissionDate ?
                                collaboratorVestingPeriod[0]?.collaborator?.admissionDate :
                                '-'
                            }
                        </span>
                    </div>
                </div>
                <div className='filter-list'>
                    <div className='buttons'>
                        <button className={filterDisponivelAno === "nao_utilizado" ? 'active' :""} onClick={()=> filtrarFeriasUltilizada("nao_utilizado")}>Disponíveis</button>
                        <button className={filterDisponivelAno === "utilizado" ? 'active' :""} onClick={()=> filtrarFeriasUltilizada("utilizado")}>Utilizadas</button>

                    </div>

                </div>
                {
                    filterDisponivelAno === "nao_utilizado" ?
                        <div className='lista-anos'>
                            {
                                loadcollaboratorVestingPeriod ?
                                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                :
                                collaboratorVestingPeriod && collaboratorVestingPeriod?.lenght !== 0 ?
                                collaboratorVestingPeriod
                                .filter(vestingPeriod => vestingPeriod.status === 'nao_utilizado')
                                .map((vestingPeriod, index) => (
                                    <>
                                    <div className='box-details' key={index}>
                                        <h4 className='titulo'>
                                            Ano referência: {
                                                vestingPeriod &&
                                                vestingPeriod?.referenceYear ?
                                                vestingPeriod?.referenceYear+'-'+Number(vestingPeriod?.referenceYear+1)
                                                : '-'
                                            }
                                            {

                                                anoSelecionado !== "" && anoSelecionado === vestingPeriod?.referenceYear ?
                                                <button onClick={fecharDetalhesAno}>Ocultar detalhes</button> :
                                                <button onClick={()=> VerDetalhesAno(vestingPeriod)}>Ver detalhes</button>
                                            }

                                        </h4>
                                        <ul className='infor-geral'>
                                            <li>
                                                <p className='nome'>Início do aquisitivo</p>
                                                <p className='infor'>
                                                    {
                                                        vestingPeriod &&
                                                        vestingPeriod?.startDate ?
                                                        dataFormatadaParaBarra(vestingPeriod?.startDate)
                                                        : '-'
                                                    }
                                                </p>
                                            </li>
                                            <li>
                                                <p className='nome'>Fim do aquisitivo</p>
                                                <p className='infor'>
                                                    {
                                                        vestingPeriod &&
                                                        vestingPeriod?.endDate ?
                                                        dataFormatadaParaBarra(vestingPeriod?.endDate)
                                                        : '-'
                                                    }
                                                </p>
                                            </li>
                                        <li>
                                            <p className="nome">Limite do benefício</p>
                                            <p className="infor">
                                                {
                                                    vestingPeriod &&
                                                    vestingPeriod?.limitConcessionPeriod ?
                                                    dataFormatadaParaBarra(vestingPeriod?.limitConcessionPeriod)
                                                    : '-'
                                                }
                                            </p>
                                        </li>
                                        <li>
                                            <p className="nome">Dias de férias</p>
                                            <p className="infor">
                                                {
                                                    vestingPeriod &&
                                                    vestingPeriod?.vacationDays ?
                                                    vestingPeriod?.vacationDays
                                                    : '0'
                                                }
                                            </p>
                                        </li>
                                        <li>
                                            <p className="nome">Dias utilizados</p>
                                            <p className="infor">
                                                {
                                                    vestingPeriod &&
                                                    vestingPeriod?.daysUsed ?
                                                    vestingPeriod?.daysUsed
                                                    : '0'
                                                }
                                            </p>
                                        </li>
                                        <li>
                                            <p className="nome">Saldo disponível</p>
                                            <p className="infor">
                                                {
                                                    vestingPeriod &&
                                                    vestingPeriod?.balanceAvailable ?
                                                    vestingPeriod?.balanceAvailable
                                                    : '0'
                                                }
                                            </p>
                                        </li>
                                        </ul>
                                        {
                                            anoSelecionado !== "" && anoSelecionado === vestingPeriod?.referenceYear ?
                                            <>

                                            <div className='list-requisicoes-ferias requisicoes'>
                                                <h4 className='sub-titulo'>Requisições</h4>
                                                <ul>


                                                {
                                                loadInforRequisition ?
                                                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                                :
                                                anoSelecionadoInfor &&
                                                        anoSelecionadoInfor !== "" &&
                                                        anoSelecionadoInfor !== undefined &&
                                                        anoSelecionadoInfor?.length !== 0  ?
                                                        anoSelecionadoInfor.map((intem, index) => (

                                                            <li className='infor-requisicoes'>
                                                                <div className='inicio-fim'>
                                                                    <p>Início<span>{dateFormatedToShutDown(intem.startDate)}  </span></p>
                                                                    <p>Até<span>{dateFormatedToShutDown(intem.endDate)}</span></p>
                                                                </div>
                                                                <div className='dias-abono-total'>
                                                                    <p>Dias<span>{intem.vacationDays}</span></p>
                                                                    <p>Abono<span>{intem.vacationDaysSold}</span></p>
                                                                    <p>Total<span>{intem.balance}</span></p>
                                                                </div>
                                                                <div className='status'>
                                                                    <p>Status <span>{intem.status}</span></p>
                                                                </div>
                                                            </li>

                                                        ))
                                                        :
                                                        <li className='infor-requisicoes-vazia' >
                                                            Nenhuma Requisição solicitada.
                                                        </li>
                                                    }
                                                </ul>

                                            </div>
                                            <div className='list-requisicoes-ferias'>
                                                <h4 className='sub-titulo'>Férias</h4>
                                                <ul>

                                                    {
                                                        loadInforRequisition ?
                                                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                                        :

                                                        anoSelecionadoInforAprovaod &&
                                                        anoSelecionadoInforAprovaod !== "" &&
                                                        anoSelecionadoInforAprovaod !== undefined &
                                                        anoSelecionadoInforAprovaod?.length !== 0 ?
                                                            anoSelecionadoInforAprovaod
                                                            .map((intem, index) => (
                                                                <>
                                                                    <li className='infor-requisicoes' key={index}>
                                                                        <div className='inicio-fim'>
                                                                            <p>Início<span>{dateFormatedToShutDown(intem.startDate)}  </span></p>
                                                                            <p>Até<span>{dateFormatedToShutDown(intem.endDate)}</span></p>
                                                                        </div>
                                                                        <div className='dias-abono-total'>
                                                                            <p>Dias<span>{intem.vacationDays}</span></p>
                                                                            <p>Abono<span>{intem.vacationDaysSold}</span></p>
                                                                            <p>Total<span>{intem.balance}</span></p>
                                                                        </div>
                                                                        <div className='status'>
                                                                            <p>Status <span>{intem.status}</span></p>
                                                                        </div>
                                                                    </li>
                                                                </>
                                                            ) )
                                                        :
                                                        <li className='infor-requisicoes-vazia' >
                                                            Nenhuma férias aprovada.
                                                        </li>
                                                    }
                                                    {/* <li className='infor-requisicoes'>
                                                        <div className='inicio-fim'>
                                                            <p>Início<span>01/03/2017  </span></p>
                                                            <p>Até<span>31/03/2017</span></p>
                                                        </div>
                                                        <div className='dias-abono-total'>
                                                            <p>Dias<span>30</span></p>
                                                            <p>Abono<span>0</span></p>
                                                            <p>Total<span>30</span></p>
                                                        </div>
                                                        <div className='status'>
                                                            <p>Status <span>Aguardando aprovação</span></p>
                                                        </div>
                                                    </li>
                                                    <li className='infor-requisicoes'>
                                                        <div className='inicio-fim'>
                                                            <p>Início<span>01/03/2017  </span></p>
                                                            <p>Até<span>31/03/2017</span></p>
                                                        </div>
                                                        <div className='dias-abono-total'>
                                                            <p>Dias<span>30</span></p>
                                                            <p>Abono<span>0</span></p>
                                                            <p>Total<span>30</span></p>
                                                        </div>
                                                        <div className='status'>
                                                            <p>Status <span>Aguardando aprovação</span></p>
                                                        </div>
                                                    </li> */}
                                                </ul>
                                            </div>
                                            </>
                                            :
                                            ""

                                        }
                                        {
                                            filterDisponivelAno === "nao_utilizado" ?
                                            <button className='btnGreen btn-Requisitar' onClick={()=>IniciaFeriasColl(vestingPeriod)}>Requisitar férias</button>
                                            :""
                                        }

                                    </div>
                                    </>
                                ))
                                :
                                ''
                            }
                        </div>
                    :
                    <div className='lista-anos'>
                        {
                            loadcollaboratorVestingPeriod ?
                            <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                            :
                            collaboratorVestingPeriod && collaboratorVestingPeriod?.lenght !== 0 ?
                            collaboratorVestingPeriod
                            .filter(vestingPeriod => vestingPeriod.status === 'utilizado')
                            .map((vestingPeriod, index) => (
                                <>
                                <div className='box-details' key={index}>
                                    <h4 className='titulo'>
                                        Ano referência: {
                                            vestingPeriod &&
                                            vestingPeriod?.referenceYear ?
                                            vestingPeriod?.referenceYear+'-'+Number(vestingPeriod?.referenceYear+1)
                                            : '-'
                                        }
                                        {

                                            anoSelecionado !== "" && anoSelecionado === vestingPeriod?.referenceYear ?
                                            <button onClick={fecharDetalhesAno}>Ocultar detalhes</button> :
                                            <button onClick={()=> VerDetalhesAno(vestingPeriod)}>Ver detalhes</button>
                                        }

                                    </h4>
                                    <ul className='infor-geral'>
                                        <li>
                                            <p className='nome'>Início do aquisitivo</p>
                                            <p className='infor'>
                                                {
                                                    vestingPeriod &&
                                                    vestingPeriod?.startDate ?
                                                    dataFormatadaParaBarra(vestingPeriod?.startDate)
                                                    : '-'
                                                }
                                            </p>
                                        </li>
                                        <li>
                                            <p className='nome'>Fim do aquisitivo</p>
                                            <p className='infor'>
                                                {
                                                    vestingPeriod &&
                                                    vestingPeriod?.endDate ?
                                                    dataFormatadaParaBarra(vestingPeriod?.endDate)
                                                    : '-'
                                                }
                                            </p>
                                        </li>
                                    <li>
                                        <p className="nome">Limite do benefício</p>
                                        <p className="infor">
                                            {
                                                vestingPeriod &&
                                                vestingPeriod?.limitConcessionPeriod ?
                                                dataFormatadaParaBarra(vestingPeriod?.limitConcessionPeriod)
                                                : '-'
                                            }
                                        </p>
                                    </li>
                                    <li>
                                        <p className="nome">Dias de férias</p>
                                        <p className="infor">
                                            {
                                                vestingPeriod &&
                                                vestingPeriod?.vacationDays ?
                                                vestingPeriod?.vacationDays
                                                : '0'
                                            }
                                        </p>
                                    </li>
                                    <li>
                                        <p className="nome">Dias utilizados</p>
                                        <p className="infor">
                                            {
                                                vestingPeriod &&
                                                vestingPeriod?.daysUsed ?
                                                vestingPeriod?.daysUsed
                                                : '0'
                                            }
                                        </p>
                                    </li>
                                    <li>
                                        <p className="nome">Saldo disponível</p>
                                        <p className="infor">
                                            {
                                                vestingPeriod &&
                                                vestingPeriod?.balanceAvailable ?
                                                vestingPeriod?.balanceAvailable
                                                : '0'
                                            }
                                        </p>
                                    </li>
                                    </ul>
                                    {
                                        anoSelecionado !== "" && anoSelecionado === vestingPeriod?.referenceYear ?
                                        <>

                                        <div className='list-requisicoes-ferias requisicoes'>
                                            <h4 className='sub-titulo'>Requisições</h4>
                                            <ul>


                                            {
                                            loadInforRequisition ?
                                                <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                            :
                                            anoSelecionadoInfor &&
                                                    anoSelecionadoInfor !== "" &&
                                                    anoSelecionadoInfor !== undefined &&
                                                    anoSelecionadoInfor?.length !== 0  ?
                                                    anoSelecionadoInfor.map((intem, index) => (

                                                        <li className='infor-requisicoes'>
                                                            <div className='inicio-fim'>
                                                                <p>Início<span>{dateFormatedToShutDown(intem.startDate)}  </span></p>
                                                                <p>Até<span>{dateFormatedToShutDown(intem.endDate)}</span></p>
                                                            </div>
                                                            <div className='dias-abono-total'>
                                                                <p>Dias<span>{intem.vacationDays}</span></p>
                                                                <p>Abono<span>{intem.vacationDaysSold}</span></p>
                                                                <p>Total<span>{intem.balance}</span></p>
                                                            </div>
                                                            <div className='status'>
                                                                <p>Status <span>{intem.status}</span></p>
                                                            </div>
                                                        </li>

                                                    ))
                                                    :
                                                    <li className='infor-requisicoes-vazia' >
                                                        Nenhuma Requisição solicitada.
                                                    </li>
                                                }
                                            </ul>

                                        </div>
                                        <div className='list-requisicoes-ferias'>
                                            <h4 className='sub-titulo'>Férias</h4>
                                            <ul>

                                                {
                                                    loadInforRequisition ?
                                                        <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                                                    :

                                                    anoSelecionadoInforAprovaod &&
                                                    anoSelecionadoInforAprovaod !== "" &&
                                                    anoSelecionadoInforAprovaod !== undefined &
                                                    anoSelecionadoInforAprovaod?.length !== 0 ?
                                                        anoSelecionadoInforAprovaod
                                                        .map((intem, index) => (
                                                            <>
                                                                <li className='infor-requisicoes' key={index}>
                                                                    <div className='inicio-fim'>
                                                                        <p>Início<span>{dateFormatedToShutDown(intem.startDate)}  </span></p>
                                                                        <p>Até<span>{dateFormatedToShutDown(intem.endDate)}</span></p>
                                                                    </div>
                                                                    <div className='dias-abono-total'>
                                                                        <p>Dias<span>{intem.vacationDays}</span></p>
                                                                        <p>Abono<span>{intem.vacationDaysSold}</span></p>
                                                                        <p>Total<span>{intem.balance}</span></p>
                                                                    </div>
                                                                    <div className='status'>
                                                                        <p>Status <span>{intem.status}</span></p>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ) )
                                                    :
                                                    <li className='infor-requisicoes-vazia' >
                                                        Nenhuma férias aprovada.
                                                    </li>
                                                }
                                                {/* <li className='infor-requisicoes'>
                                                    <div className='inicio-fim'>
                                                        <p>Início<span>01/03/2017  </span></p>
                                                        <p>Até<span>31/03/2017</span></p>
                                                    </div>
                                                    <div className='dias-abono-total'>
                                                        <p>Dias<span>30</span></p>
                                                        <p>Abono<span>0</span></p>
                                                        <p>Total<span>30</span></p>
                                                    </div>
                                                    <div className='status'>
                                                        <p>Status <span>Aguardando aprovação</span></p>
                                                    </div>
                                                </li>
                                                <li className='infor-requisicoes'>
                                                    <div className='inicio-fim'>
                                                        <p>Início<span>01/03/2017  </span></p>
                                                        <p>Até<span>31/03/2017</span></p>
                                                    </div>
                                                    <div className='dias-abono-total'>
                                                        <p>Dias<span>30</span></p>
                                                        <p>Abono<span>0</span></p>
                                                        <p>Total<span>30</span></p>
                                                    </div>
                                                    <div className='status'>
                                                        <p>Status <span>Aguardando aprovação</span></p>
                                                    </div>
                                                </li> */}
                                            </ul>
                                        </div>
                                        </>
                                        :
                                        ""

                                    }
                                    {
                                        filterDisponivelAno === "nao_utilizado" ?
                                        <button className='btnGreen btn-Requisitar' onClick={()=>IniciaFeriasColl(vestingPeriod)}>Requisitar férias</button>
                                        :""
                                    }

                                </div>
                                </>
                            ))
                            :
                            ''
                        }
                        </div>
                }


                <button className="btnGray btn-fechar" onClick={fecharVerDetalhesCol}>Fechar</button>
            </Modal>



            <Modal show={visibleIniciaFeriasCol}
              className={modalMobile ? "popup-geral popup-iniciar-feriasCol modalMobile"  : "popup-geral popup-iniciar-feriasCol" } onHide={fecharIniciaFeriasColl}>
                <div className="titulo">
                    <h4>Iniciar processo de férias <p className="btn-fechar" onClick={fecharIniciaFeriasColl}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <div className="infor-cal">
                {
                        collaboratorVacationDetails &&
                        collaboratorVacationDetails.collaborator &&
                        collaboratorVacationDetails.collaborator.avatar ?
                        <div className="img-col">
                            <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                <img src={collaboratorVacationDetails.collaborator.avatar} alt="nome" />
                            </NavLink>
                        </div>
                        :
                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                            <div className="semImgCol">
                                <img src={IconUserNone} alt="coll" />
                            </div>
                        </NavLink>
                    }
                    <div className="nome-setor">
                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                            <span>
                                {
                                    collaboratorVacationDetails &&
                                    collaboratorVacationDetails.nameCollaborator ?
                                    collaboratorVacationDetails.nameCollaborator :
                                    ''
                                }
                            </span>
                        </NavLink>
                        <div className="setor">
                            <p>
                                {
                                    collaboratorVacationDetails.collaborator &&
                                    collaboratorVacationDetails.collaborator.occupation ?
                                    collaboratorVacationDetails.collaborator.occupation
                                    :
                                    '-'
                                }
                            </p>
                        </div>
                    </div>
                    <div className="data-admissao">
                        Data de admissão
                        <span>
                            {
                                collaboratorVacationDetails &&
                                collaboratorVacationDetails.collaborator &&
                                collaboratorVacationDetails.collaborator.admissionDate ?
                                dateFormatedToDetailVacation(collaboratorVacationDetails.collaborator.admissionDate)
                                :
                                ''
                            }
                        </span>
                    </div>
                </div>
                    {continuarPedidoFeria ?
                        <div className='radio-verificacao-ano'>
                            <p className='infor'>Selecione o ano de referência das férias a serem solicitadas:</p>
                            <div className='list-ferias-naoAtualizada'>
                                <div className='item head'>
                                    <p className='referencia'>Referência</p>
                                    <p className='periodo'>Período aquisitivo</p>
                                    <p className='ultilizado'>Utilizado</p>
                                    <p className='saldo'>Saldo</p>
                                </div>
                                {   startVacationProcess !== undefined ?
                                        collaboratorVestingPeriod && collaboratorVestingPeriod?.lenght !== 0 ?
                                        collaboratorVestingPeriod
                                        .filter(vestingPeriod => vestingPeriod.status === "nao_utilizado" && vestingPeriod.balanceAvailable > 0)
                                        .map((vestingPeriod, index) => (
                                            <>
                                                <div className='item body'>


                                                    <button className="btn-radio referencia"onClick={()=> selecionarAno(vestingPeriod?.referenceYear)}>
                                                        <label
                                                        className={parseInt(startVacationProcess?.vestingPeriodReferenceYear) === parseInt(vestingPeriod?.referenceYear) ?
                                                        "chekboxLabel checado" : "chekboxLabel"
                                                        }
                                                        ></label>
                                                        <p className="texto"> {vestingPeriod?.referenceYear} </p>
                                                    </button>
                                                    {/* <p className='referencia'> {vestingPeriod?.referenceYear}</p> */}
                                                    <p className='periodo'>{dateFormatedToShutDown(vestingPeriod?.startDate)} à {dateFormatedToShutDown(vestingPeriod?.endDate)} </p>
                                                    <p className='ultilizado'>{vestingPeriod?.daysUsed}</p>
                                                    <p className='saldo'>{vestingPeriod?.vacationDays}</p>
                                                </div>

                                            </>))
                                        :
                                        ''
                                    :
                                    ''
                                }


                            </div>
                            <button onClick={anoSelecionadoContinua} className="btnGreen btn-continua">Continuar</button>

                        </div>
                        :
                        <>
                        <div className='radio-verificacao-ano'>
                            <div className='list-ferias-naoAtualizada'>
                                <div className='item head'>
                                    <p className='referencia'>Referência</p>
                                    <p className='periodo'>Período aquisitivo</p>
                                    <p className='ultilizado'>Utilizado</p>
                                    <p className='saldo'>Saldo</p>
                                </div>
                                {   startVacationProcess !== undefined ?
                                        collaboratorVestingPeriod && collaboratorVestingPeriod?.lenght !== 0 ?
                                        collaboratorVestingPeriod
                                        .filter(vestingPeriod=>vestingPeriod?.referenceYear === startVacationProcess?.vestingPeriodReferenceYear)
                                        .map((vestingPeriod, index) => (
                                            <>
                                                <div key={index} className='item body'>


                                                    <button className="btn-radio referencia">
                                                        <label
                                                        className={parseInt(startVacationProcess?.vestingPeriodReferenceYear) === parseInt(vestingPeriod?.referenceYear) ?
                                                        "chekboxLabel checado" : "chekboxLabel"
                                                        }
                                                        ></label>
                                                        <p className="texto"> {vestingPeriod?.referenceYear} </p>
                                                    </button>
                                                    {/* <p className='referencia'> {vestingPeriod?.referenceYear}</p> */}
                                                    <p className='periodo'>{dateFormatedToShutDown(vestingPeriod?.startDate)} à {dateFormatedToShutDown(vestingPeriod?.endDate)} </p>
                                                    <p className='ultilizado'>{vestingPeriod?.daysUsed}</p>
                                                    <p className='saldo'>{vestingPeriod?.vacationDays}</p>
                                                </div>

                                            </>))
                                        :
                                        ''
                                    :
                                    ''
                                }


                            </div>

                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>De *</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    name="startDate"
                                    id={collaboratorVacationDetails &&
                                        collaboratorVacationDetails.collaborator ?
                                        collaboratorVacationDetails.collaborator._id :
                                    ''}
                                    onChange={myChangeHandler}
                                    value={startVacationProcess && startVacationProcess.startDate !== undefined ? startVacationProcess.startDate : '' }
                                    placeholder="DD/MM/AAAA"
                                    className={loadInitialDate ? 'inputErro' : ''}
                                    />

                                    {loadInitialDate ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}
                                </div>
                                </li>
                            <li>
                                <div className="intemA">
                                    <p>Até *</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    name="endDate"
                                    onChange={myChangeHandler}
                                    value={startVacationProcess && startVacationProcess.endDate !== undefined ? startVacationProcess.endDate : '' }
                                    placeholder="DD/MM/AAAA"
                                    className={loadFinalDate ? 'inputErro' : ''}
                                    />
                                    {loadFinalDate ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Dias de abono *</p>
                                </div>
                                <div className="intemB">
                                    <input
                                    type="text"
                                    name="vacationDaysSold"
                                    onChange={myChangeHandler}
                                    className={loadvacationDaysSold ? 'inputErro' : ''}
                                    value={startVacationProcess && startVacationProcess.vacationDaysSold !== undefined ? startVacationProcess.vacationDaysSold : '' }
                                    placeholder="Dias de abono"
                                    />
                                    {loadvacationDaysSold ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}
                                </div>
                                </li>
                            <li>
                                <div className="intemA">
                                    <p>Antecipar 1ª Parcela do 13º salário</p>
                                </div>
                                <div className="intemB radio">
                                <div className="radioNovo">
                                    <input type="radio" name="ThirteenthSalary" onChange={myChangeHandler} value="true"  id="workingDayDois" />
                                    <label htmlFor="workingDayDois" className="foreignTexto">Sim</label>
                                </div>
                                <div className="radioNovo">
                                    <input type="radio" name="ThirteenthSalary" onChange={myChangeHandler} value="false"  id="workingDayTreis" defaultChecked={true} />
                                    <label htmlFor="workingDayTreis" className="foreignTexto">Não</label>
                                </div>
                                </div>
                                </li>
                            <li>
                                <div className="intemA">
                                    <p>Justificativa *</p>
                                </div>
                                <div className="intemB">
                                    <textarea
                                    name="comments"
                                    onChange={myChangeHandler}
                                    value={startVacationProcess && startVacationProcess.comments !== undefined ? startVacationProcess.comments : '' }
                                    cols="150"
                                    className={loadComments ? 'inputErro' : ''}
                                    placeholder="Descrição"></textarea>
                                    {loadComments ? ( <p className="textError">O campo é obrigatório.</p>) : ('')}
                                </div>
                            </li>
                            <li>
                                <div className="btn-iniciar-ferias">
                                    <button className='btnGray' onClick={anoSelecionadoVoltar}>Voltar</button>
                                    <button
                                    onClick={saveStartVacationProcess}
                                    name="collaborator"
                                    value={collaboratorVacationDetails &&
                                        collaboratorVacationDetails.collaborator &&
                                        collaboratorVacationDetails.collaborator._id ?
                                        collaboratorVacationDetails.collaborator._id
                                        :
                                        ''
                                    }
                                    className={loadSalveInicialFerias  ? 'saved btnGreen load' : 'saved btnGreen'}
                                    >Iniciar processo</button>
                                </div>
                            </li>


                        </ul>

                        </>
                    }

            </Modal>


        </>
    );
}
