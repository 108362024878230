import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask'
import {FormataStringData } from "../../../../services/utils";
import swal from 'sweetalert';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
export default function ColProfileInternInformation({ id, collaborator }) {

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ registeredInternInformation, setRegisteredInternInformation ] = useState([]);
// eslint-disable-next-line react-hooks/rules-of-hooks
const [ internInformationUpdate, setInternInformationUpdate ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    async function loadInternInformation() {
        api.get(`/collaborator-intern-information/${id}`)
        .then(response => {
            if(response.data[0] !== undefined) {
                setRegisteredInternInformation(response.data[0]);

            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
    loadInternInformation();
}, [id]);


/////////////////////////////////////////////////////////////////////


const  myChangeHandler = event => {
    let nam = event.target.name;
    let val = event.target.value;

    if (nam === "zipcode") {
         getAddress(val)
    } else if (nam === "dateOfBirth") {
        let valDate = FormataStringData(val)
        setInternInformationUpdate({ ...internInformationUpdate, [nam]: valDate })
    }
    else {
        let collaborator = id;
        setInternInformationUpdate({ ...internInformationUpdate, collaborator, [nam]: val })
    }

};
async function getAddress(zipcode) {
   zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
   if (zipcode.length >= 8) {
       //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
       const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
    //    console.log(response.data)
       if (response.data !== null) {
            setInternInformationUpdate({
               ...internInformationUpdate,
               zipcode: zipcode,
               address: response.data.logradouro,
               addressNeighborhood : response.data.bairro,
               addressCity: response.data.localidade,
               addressState: response.data.uf,
           })
       }
   }
}


//////////////////////////////////////////////////////////////////////
/////////////////////       Loads         ///////////////////////////

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ load, setLoad ] = useState(false);
//////////////////////////////////////////////////////////////////////
/////////////////       SET VISIBLE POPUP         ///////////////////

// eslint-disable-next-line react-hooks/rules-of-hooks
const [visibleModal, setVisibleModal] = useState(false);
const fecharModalProfileInterInfor = (e) => {
   if (e.target.id === 'idModal') setVisibleModal(false);
}

//////////////////////////////////////////////////////////////////////
/////////////////         Hora da magica          ///////////////////
async function handleSubmit(event) {

    event.preventDefault();

    if(registeredInternInformation && registeredInternInformation._id !== undefined) {
        if(internInformationUpdate.length === 0){
            swal({ icon: "error", title: "Erro!", text:'Necessário atualizar pelo menos um campo.' });
        } else {
            setLoad(true)
            await api.put(`/collaborator-intern-information/${registeredInternInformation._id}`, internInformationUpdate)
            .then(response => {
                async function loadfilessaved() {
                    api.get(`/collaborator-intern-information/${id}`)
                    .then(response => {
                        setLoad(false)
                        setRegisteredInternInformation(response.data[0]);
                        setInternInformationUpdate([])
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Informações para Estagiário atualizada com sucesso!" });
                setVisibleModal(false)
            }).catch(error => {
                swal({ icon: "error", title: "Erro!",  text: error.response.data.message });
                setLoad(true)
            });
        }

    } else {

        if(internInformationUpdate.length === 0){
            swal({ icon: "error", title: "Erro!", text:'Necessário atualizar pelo menos um campo.' });
        } else {
            setLoad(true)
            await api.post(`/collaborator-intern-information/`, internInformationUpdate)
            .then(response => {
                async function loadfilessaved() {
                    api.get(`/collaborator-intern-information/${id}`)
                    .then(response => {
                        setLoad(false)
                        setRegisteredInternInformation(response.data[0]);
                        setInternInformationUpdate([])
                    }).catch(error => {
                        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
                    });
                }
                loadfilessaved();
                swal({ icon: "success", title: "Sucesso!", text: "Exame períotico atualizada com sucesso!" });
                setVisibleModal(false)
            }).catch(error => {
                console.log(error)
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                setLoad(false)
            });
        }
    }
}

        return (
            <>

                    <div className="box InternInformation">
                        <h3>Informações para Estagiário
                            {/* <button className="edit-Benefit" onClick={() => AbrirModalProfileInterInfor(registeredInternInformation)}><img src={EditBlack} alt="Edite Informações" /></button> */}
                            </h3>
                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Data de início</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.initialDate !== undefined ?(registeredInternInformation.initialDate) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Data de término</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.finalDate !== undefined ?(registeredInternInformation.finalDate) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Natureza do estágio</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.natureOfTheInternship !== undefined &&
                                    registeredInternInformation.natureOfTheInternship === 'true' ? ('Obrigatório') :
                                    registeredInternInformation.natureOfTheInternship === 'false' ? ('Não obrigatório') :
                                    ('-')}
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Instituição de ensino</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.educationalInstitution !== undefined ?(registeredInternInformation.educationalInstitution) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>CNPJ da instituição de ensino</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.CNPJEducationalInstitution !== undefined ?(registeredInternInformation.CNPJEducationalInstitution) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>CEP</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.zipcode !== undefined ?(registeredInternInformation.zipcode) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Endereço</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.address !== undefined ?(registeredInternInformation.address) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Número</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.addressNumber !== undefined ?(registeredInternInformation.addressNumber) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Complemento</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.addressComplement !== undefined ?(registeredInternInformation.addressComplement) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Bairro</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.addressNeighborhood !== undefined ?(registeredInternInformation.addressNeighborhood) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>UF</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.addressState !== undefined ?(registeredInternInformation.addressState) : ('-')}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Cidade</p>
                                </div>
                                <div className="intemB nome">
                                    <p>{registeredInternInformation.addressCity !== undefined ?(registeredInternInformation.addressCity) : ('-')}</p>
                                </div>
                            </li>

                        </ul>
                    </div>




                    {visibleModal ?
                        <div className="modalGeral" id="idModal" onClick={fecharModalProfileInterInfor}>

                            <div className="boxModal">
                                    <div className="titulo">
                                        <h4>Informações para Estagiário  <p className="btn-fechar" onClick={() => setVisibleModal(false)}>X</p></h4>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="intemA">
                                                <p>Data de início</p>
                                            </div>
                                            {registeredInternInformation.initialDate    !== undefined ?
                                                (
                                                <div className="intemB">
                                                    <InputMask mask="99/99/9999" type="text" onChange={myChangeHandler}  defaultValue={registeredInternInformation.initialDate} name="initialDate" />
                                                </div>
                                                ) :(
                                                <div className="intemB">
                                                    <InputMask mask="99/99/9999" type="text" onChange={myChangeHandler}  name="initialDate" placeholder="DD/MM/AAAA"/>
                                                </div>
                                                ) }

                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Data de término</p>
                                            </div>
                                            <div className="intemB">
                                                {registeredInternInformation.finalDate      !== undefined ?
                                                (<InputMask mask="99/99/9999" type="text" onChange={myChangeHandler}  defaultValue={registeredInternInformation.finalDate} name="finalDate" />) :
                                                (<InputMask mask="99/99/9999" type="text" onChange={myChangeHandler}  name="finalDate" placeholder="DD/MM/AAAA" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Natureza do estágio</p>
                                            </div>
                                            <div className="intemB">
                                                    {registeredInternInformation.natureOfTheInternship === "true" ? (
                                                <div className="radio">
                                                    <input type="radio" value="true" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignSim" checked/>
                                                    <label htmlFor="foreignSim" className="chackboxLabel"><span></span></label>
                                                    <label htmlFor="foreignSim" className="foreignTexto">Obrigatório</label> <br/>

                                                    <input type="radio" value="false" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignNao"/>
                                                    <label htmlFor="foreignNao" className="chackboxLabel"><span></span></label>
                                                    <label htmlFor="foreignNao" className="foreignTexto">Não obrigatório</label>
                                                    </div>

                                                    ) : registeredInternInformation.natureOfTheInternship === "false" ? (
                                                    <div className="radio">
                                                    <input type="radio" value="true" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignSim"/>
                                                    <label htmlFor="foreignSim" className="chackboxLabel"><span></span></label>
                                                    <label htmlFor="foreignSim" className="foreignTexto">Obrigatório</label> <br/>

                                                    <input type="radio" value="false" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignNao" checked/>
                                                    <label htmlFor="foreignNao" className="chackboxLabel"><span></span></label>
                                                    <label htmlFor="foreignNao" className="foreignTexto">Não obrigatório</label>
                                                    </div>

                                                    ) : (
                                                        <div className="radio">
                                                        <input type="radio" value="true" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignSim"/>
                                                        <label htmlFor="foreignSim" className="chackboxLabel"><span></span></label>
                                                        <label htmlFor="foreignSim" className="foreignTexto">Obrigatório</label> <br/>

                                                        <input type="radio" value="false" onChange={myChangeHandler} name="natureOfTheInternship" id="foreignNao"/>
                                                        <label htmlFor="foreignNao" className="chackboxLabel"><span></span></label>
                                                        <label htmlFor="foreignNao" className="foreignTexto">Não obrigatório</label>
                                                    </div>
                                                    )}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Instituição de ensino</p>
                                            </div>
                                            <div className="intemB">
                                                {registeredInternInformation.educationalInstitution      !== undefined ?
                                                (<InputMask type="text" name="educationalInstitution"  onChange={myChangeHandler} defaultValue={registeredInternInformation.educationalInstitution}  />) :
                                                (<InputMask type="text" name="educationalInstitution"  onChange={myChangeHandler} placeholder="Instituição de ensino" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>CNPJ da instituição de ensino</p>
                                            </div>
                                            <div className="intemB">
                                                {registeredInternInformation.CNPJEducationalInstitution      !== undefined ?
                                                (<InputMask mask="99.999.999/9999-99" type="text"  onChange={myChangeHandler} name="CNPJEducationalInstitution" defaultValue={registeredInternInformation.CNPJEducationalInstitution}/>) :
                                                (<InputMask mask="99.999.999/9999-99" type="text"  onChange={myChangeHandler} name="CNPJEducationalInstitution" placeholder="CNPJ da instituição de ensino" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>CEP</p>
                                            </div>
                                            <div className="intemB">
                                                {registeredInternInformation.zipcode      !== undefined ?
                                                (<InputMask type="text" name="zipcode" onChange={myChangeHandler}  defaultValue={registeredInternInformation.zipcode}/>) :
                                                (<InputMask type="text" name="zipcode" onChange={myChangeHandler}  placeholder="CEP" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Endereço</p>
                                            </div>
                                            <div className="intemB">
                                                {internInformationUpdate && internInformationUpdate.address ?
                                                (<InputMask type="text" name="address" onChange={myChangeHandler} value={internInformationUpdate.address}/>):
                                                registeredInternInformation.address    !== undefined ?
                                                (<InputMask type="text" name="address" onChange={myChangeHandler} value={registeredInternInformation.address}/>) :
                                                (<InputMask type="text" name="address" onChange={myChangeHandler} placeholder="Endereço" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Número</p>
                                            </div>
                                            <div className="intemB">
                                                {registeredInternInformation.addressNumber    !== undefined ?
                                                (<InputMask type="text" name="addressNumber" onChange={myChangeHandler}  defaultValue={registeredInternInformation.addressNumber}/>) :
                                                (<InputMask type="text" name="addressNumber"  onChange={myChangeHandler} placeholder="Número" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Complemento</p>
                                            </div>
                                            <div className="intemB">
                                                {internInformationUpdate && internInformationUpdate.address ?
                                                (<input type="text" name="addressComplement"  onChange={myChangeHandler} value={internInformationUpdate.addressComplement}/>):
                                                registeredInternInformation.addressComplement     !== undefined ?
                                                (<input type="text" name="addressComplement"  onChange={myChangeHandler} defaultValue={registeredInternInformation.addressComplement}/>) :
                                                (<input type="text" name="addressComplement"  onChange={myChangeHandler} placeholder="Complemento" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Bairro</p>
                                            </div>
                                            <div className="intemB">
                                                {internInformationUpdate && internInformationUpdate.address ?
                                                (<InputMask type="text" name="addressNeighborhood"  onChange={myChangeHandler} value={internInformationUpdate.addressNeighborhood} />):
                                                registeredInternInformation.addressNeighborhood     !== undefined ?
                                                (<InputMask type="text" name="addressNeighborhood"  onChange={myChangeHandler} value={registeredInternInformation.addressNeighborhood} />) :
                                                (<InputMask type="text" name="addressNeighborhood"  onChange={myChangeHandler} placeholder="Bairro" />) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>UF</p>
                                            </div>
                                            <div className="intemB">
                                                {internInformationUpdate && internInformationUpdate.address ?
                                                (<input type="text" name="addressState"  onChange={myChangeHandler} value={internInformationUpdate.addressState}/>):
                                                registeredInternInformation.addressState     !== undefined ?
                                                (<input type="text" name="addressState"  onChange={myChangeHandler} value={registeredInternInformation.addressState}/>) :
                                                (<input type="text" name="addressState"  onChange={myChangeHandler} placeholder="Estado"/>) }
                                        </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                                <p>Cidade</p>
                                            </div>
                                            <div className="intemB">
                                                {internInformationUpdate && internInformationUpdate.address ?
                                                (<input type="text" name="addressCity"  onChange={myChangeHandler} value={internInformationUpdate.addressCity}/>):
                                                registeredInternInformation.addressCity     !== undefined ?
                                                (<input type="text" name="addressCity"  onChange={myChangeHandler} value={registeredInternInformation.addressCity}/>) :
                                                (<input type="text" name="addressCity"  onChange={myChangeHandler} placeholder="Cidade"/>) }
                                            </div>
                                        </li>
                                        <li>
                                            <div className="intemA">
                                            </div>
                                            <div className="intemB">
                                                <form className="btn-salvar" onSubmit={handleSubmit}   >
                                                    <button type="submit" className={load  ? 'saved btnGreen load' : 'saved btnGreen'}>{registeredInternInformation && registeredInternInformation._id !== undefined ? 'Atualizar' : 'Adicionar'}</button>
                                                </form>
                                            </div>
                                        </li>
                                    </ul>
                            </div>

                        </div>
                    :
                        null
                        }


            </>
        );
}
