import React from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Close from '../../../../assets/images/close.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import { Modal} from "react-bootstrap";

const ColProfileContact = ({
    id,
    collaborator,
    loadPage,
    collaboratorUpdate,
    onCollaboratorInputChange,
    OnSubmit,
    loadContinue,
    PopupContactVisible,
    visibleContact,
    saveContact,
    Loadcellphone,
    Loademail
}) => {

        return (
            <>

            <div className="box contato">
                        <h3>Contatos <button className="edit-Benefit" onClick={() => PopupContactVisible()}><img src={EditBlack} alt="Edite Informações" /></button></h3>
                        {
                            loadPage ?
                                (
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregando} />
                                    </div>
                                    </>
                                ):(
                                    <>

                        <ul>
                            <li>
                                <div className="intemA">
                                    <p>Telefone</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.phone ? collaborator.phone : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Celular</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.cellphone ? collaborator.cellphone : '-'}</p>
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>E-mail</p>
                                </div>
                                <div className="intemB">
                                    <p>{collaborator.personalEmail ? collaborator.personalEmail : '-'}</p>
                                </div>
                            </li>
                        </ul>
                        </> )}
                    </div>

                <Modal show={visibleContact} className="popup-edit-perfil" onHide={PopupContactVisible} >
                    <div>
                        <div className="titulo">
                            <h4>Contatos <p className="btn-fechar" onClick={PopupContactVisible}><img src={Close} alt="Fechar" /></p></h4>
                        </div>
                        <ul>
                            <li>
                                <div className="intemA">
                                <p>Telefone</p>
                                </div>
                                <div className="intemB">

                                    {
                                    (collaboratorUpdate && collaboratorUpdate.phone !== undefined) || collaboratorUpdate.phone === '' ?
                                    (
                                        <input type="text" name="phone" autocomplete="autocompleteoff" value={(collaboratorUpdate.phone !== undefined) ? collaboratorUpdate.phone : ''}  onChange={onCollaboratorInputChange} placeholder="Telefone"  />
                                    ) :
                                    collaborator && collaborator.phone ?(
                                        <input type="text" name="phone" autocomplete="autocompleteoff" defaultValue={(collaborator.phone !== undefined) ? collaborator.phone : ''}  onChange={onCollaboratorInputChange} placeholder="Telefone" />
                                    ):
                                    (
                                        <input type="text" name="phone" autocomplete="autocompleteoff" value={(collaboratorUpdate.phone !== undefined) ? collaboratorUpdate.phone : ''}  onChange={onCollaboratorInputChange} placeholder="Telefone" />
                                    )}
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                    <p>Celular *</p>
                                </div>
                                <div className="intemB">

                                {
                                        (collaboratorUpdate && collaboratorUpdate.cellphone !== undefined) || collaboratorUpdate.cellphone === '' ?
                                        (
                                            <input type="text" name="cellphone" autocomplete="autocompleteoff" value={(collaboratorUpdate.cellphone !== undefined) ? collaboratorUpdate.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular"  className={Loadcellphone ? 'inputErro' : ''}/>
                                        ) :
                                        collaborator && collaborator.cellphone && collaborator.cellphone !== ''? (
                                            <input type="text" name="cellphone" autocomplete="autocompleteoff" defaultValue={(collaborator.cellphone !== undefined) ? collaborator.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputErro' : ''}/>
                                        ):
                                        (
                                            <input type="text" name="cellphone" autocomplete="autocompleteoff" value={(collaboratorUpdate.cellphone !== undefined) ? collaboratorUpdate.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular"  className={Loadcellphone ? 'inputErro' : ''}/>
                                        )}



                                    {Loadcellphone ? (
                                            <p className="textError inputTreis">O campo é obrigatório.</p>
                                            ) : ('')
                                        }
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                <p>E-mail *</p>
                                </div>
                                <div className="intemB">
                                    {
                                    (collaboratorUpdate && collaboratorUpdate.personalEmail !== undefined) || collaboratorUpdate.personalEmail === '' ?
                                    (
                                        <input type="email" name="personalEmail" autocomplete="0ff" onChange={onCollaboratorInputChange} value={(collaboratorUpdate.personalEmail !== undefined) ? collaboratorUpdate.personalEmail : ''} placeholder="E-mail" className={Loademail ? 'inputErro' : ''}/>
                                        // <input type="text" name="cellphone" value={(collaboratorUpdate.cellphone !== undefined) ? collaboratorUpdate.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular"  className={Loadcellphone ? 'inputErro' : ''}/>
                                    ) :
                                    collaborator && collaborator.cellphone && collaborator.cellphone !== ''? (
                                        <input type="email" name="personalEmail" autocomplete="off" onChange={onCollaboratorInputChange} defaultValue={(collaborator.personalEmail !== undefined) ? collaborator.personalEmail : ''} placeholder="E-mail" className={Loademail ? 'inputErro' : ''}/>
                                        // <input type="text" name="cellphone" defaultValue={(collaborator.cellphone !== undefined) ? collaborator.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular" className={Loadcellphone ? 'inputErro' : ''}/>
                                    ):
                                    (
                                        <input type="email" name="personalEmail" autocomplete="off"  onChange={onCollaboratorInputChange} placeholder="E-mail 3" value={(collaboratorUpdate.personalEmail !== undefined) ? collaboratorUpdate.personalEmail : ''} className={Loademail ? 'inputErro' : ''}/>
                                        // <input type="text" name="cellphone" value={(collaboratorUpdate.cellphone !== undefined) ? collaboratorUpdate.cellphone : ''}  onChange={onCollaboratorInputChange} placeholder="Celular"  className={Loadcellphone ? 'inputErro' : ''}/>
                                    )}
                                    {Loademail ? (
                                        <p className="textError inputTreis">O campo é obrigatório.</p>
                                        ) : ('')
                                    }
                                </div>
                            </li>
                            <li>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                    <form onSubmit={saveContact} className="btn-salvar">
                                        <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                    </form>
                                </div>
                            </li>
                        </ul>

                    </div>
                </Modal>
            </>
        );
}
export default ColProfileContact;
