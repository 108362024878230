import React, { useState } from 'react';
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import Dropzone from "react-dropzone";
import IconCamera from '../../../assets/images/icone-camera.svg';
// import CarregandoFoto from '../../../assets/images/carregando.gif';
import { NavLink } from 'react-router-dom'
import { mCNPJ, phones } from '../../../services/mask';
import swal from 'sweetalert';
import axios from 'axios';
import api from '../../../services/api';

import './styles.scss';

export default function pageSystemConfig(props, path) {

    // const { id } = props.match.params

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRazaoSocialEdit, setLoadRazaoSocialEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadTelefoneEdit, setLoadTelefoneEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCnpj, setLoadCnpj] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEmailEdit, setLoadEmailEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCEPEdit, setLoadCEPEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNumeroEdit, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRuaAvenidaEdit, setLoadRuaAvenidaEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBairroEdit, setLoadBairroEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEstadoEdit, setLoadEstadoEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCidadeEdit, setLoadCidadeEdit] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadComplementoEdit, ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonUpdate, setLoadButtonUpdate] = useState(false);

    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ loadPageCostCenterDataId, setLoadPageCostCenterDataId] = useState(false);




    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ costCenterDataAdd, setCostCenterDataAdd] = useState([]);


    async function myChangeHandlerCostCenter(event) {

        let nam = event.target.name;
        let val = event.target.value;

        //name
        if(nam === 'name') {

            if(val.length === 0) {
                setLoadRazaoSocialEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                setLoadRazaoSocialEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }


        }
        else
        //costCenterDataAdd
        if (nam === "phone") {

            if(val.length === 0) {
                setLoadTelefoneEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                let telefone = phones(val)
                setLoadTelefoneEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: telefone })
            }


        }
        else
        if (nam === "cnpj") {

            if(val.length === 0) {
                setLoadCnpj(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                var cnpj = mCNPJ(val)
                setLoadCnpj(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: cnpj })
            }

        }
        //email
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmailEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                setLoadEmailEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }

        }
        else
        if(nam === "zipcode") {


            if(val.length === 0) {
                setLoadCEPEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else
            if(val.length <=7 && val.length >= 1) {
                setLoadCEPEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {

                let zipcode = String(val).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
                //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
                // let cepppp = cep(zipcode)
                const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

                setCostCenterDataAdd({
                    ...costCenterDataAdd,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })

                setLoadRuaAvenidaEdit(false)
                setLoadBairroEdit(false)
                setLoadEstadoEdit(false)
                setLoadCidadeEdit(false)
            }


        }
        else
        //address
        if(nam === 'address') {

            if(val.length === 0) {
                setLoadRuaAvenidaEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                setLoadRuaAvenidaEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }

        }
        else
        //addressNeighborhood
        if(nam === 'addressNeighborhood') {

            if(val.length === 0) {
                setLoadBairroEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                setLoadBairroEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }

        }
        else
        //addressState
        if(nam === 'addressState') {

            if(val.length === 0) {
                setLoadEstadoEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                setLoadEstadoEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }

        }
        else
        //addressCity
        if(nam === 'addressCity') {

            if(val.length === 0) {
                setLoadCidadeEdit(true)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }
            else {
                setLoadCidadeEdit(false)
                setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })
            }

        }
        else {

            setCostCenterDataAdd({ ...costCenterDataAdd, [nam]: val })

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadPageCompanyData] = useState(false);



    async function addCostCenter() {


        if(costCenterDataAdd && costCenterDataAdd.length === 0) {

            swal({ icon: "error", title: "Erro!", text: "Necessário adicionar pelo menos um campo!" });

        }
        else {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.name                !== undefined &&
                costCenterDataAdd.name                !== ''
            ) {
                formData.append('name', costCenterDataAdd.name);
                setLoadRazaoSocialEdit(false)
            }
            else {
                setLoadRazaoSocialEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.phone                !== undefined &&
                costCenterDataAdd.phone                !== ''
            ) {
                formData.append('phone', costCenterDataAdd.phone);
                setLoadTelefoneEdit(false)
            }
            else {
                setLoadTelefoneEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.cnpj                !== undefined &&
                costCenterDataAdd.cnpj                !== ''
            ) {
                formData.append('cnpj', costCenterDataAdd.cnpj);
                setLoadCnpj(false)
            }
            else {
                setLoadCnpj(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.email                !== undefined &&
                costCenterDataAdd.email                !== ''
            ) {
                formData.append('email', costCenterDataAdd.email);
                setLoadEmailEdit(false)
            }
            else {
                setLoadEmailEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.zipcode                !== undefined &&
                costCenterDataAdd.zipcode                !== ''
            ) {
                formData.append('zipcode', costCenterDataAdd.zipcode);
                setLoadCEPEdit(false)
            }
            else {
                setLoadCEPEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.address                !== undefined &&
                costCenterDataAdd.address                !== ''
            ) {
                formData.append('address', costCenterDataAdd.address);
                setLoadRuaAvenidaEdit(false)
            }
            else {
                setLoadRuaAvenidaEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.addressNeighborhood                !== undefined &&
                costCenterDataAdd.addressNeighborhood                !== ''
            ) {
                formData.append('addressNeighborhood', costCenterDataAdd.addressNeighborhood);
                setLoadBairroEdit(false)
            }
            else {
                setLoadBairroEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.addressState                !== undefined &&
                costCenterDataAdd.addressState                !== ''
            ) {
                formData.append('addressState', costCenterDataAdd.addressState);
                setLoadEstadoEdit(false)
            }
            else {
                setLoadEstadoEdit(true)
            }

            if(
                costCenterDataAdd                                   &&
                costCenterDataAdd.addressCity                !== undefined &&
                costCenterDataAdd.addressCity                !== ''
            ) {
                formData.append('addressCity', costCenterDataAdd.addressCity);
                setLoadCidadeEdit(false)
            }
            else {
                setLoadCidadeEdit(true)
            }

            if(costCenterDataAdd.nameDescription !== undefined) {
                formData.append('nameDescription', costCenterDataAdd.nameDescription);
            }

            if(costCenterDataAdd.file !== undefined) {
                formData.append('file', costCenterDataAdd.file);
            }



            if(
                (costCenterDataAdd                                   &&
                costCenterDataAdd.name                !== undefined  &&
                costCenterDataAdd.name                !== ''         &&
                costCenterDataAdd.nameDescription    !== undefined  &&
                costCenterDataAdd.nameDescription     !== '')        ||
                (costCenterDataAdd.cnpj               !== undefined &&
                costCenterDataAdd.cnpj                !== '')        ||
                (costCenterDataAdd                                   &&
                costCenterDataAdd.phone               !== undefined  &&
                costCenterDataAdd.phone               !== ''         &&
                costCenterDataAdd                                    &&
                costCenterDataAdd.email               !== undefined  &&
                costCenterDataAdd.email               !== ''         &&
                costCenterDataAdd                                    &&
                costCenterDataAdd.zipcode             !== undefined  &&
                costCenterDataAdd.zipcode             !== ''         &&
                costCenterDataAdd                                    &&
                costCenterDataAdd.address             !== undefined  &&
                costCenterDataAdd.address             !== ''         &&
                costCenterDataAdd                                    &&
                costCenterDataAdd.addressNeighborhood !== undefined  &&
                costCenterDataAdd.addressNeighborhood !== ''         &&
                costCenterDataAdd                                    &&
                costCenterDataAdd.addressState        !== undefined  &&
                costCenterDataAdd.addressState        !== ''         &&
                costCenterDataAdd                                    &&
                costCenterDataAdd.addressCity         !== undefined  &&
                costCenterDataAdd.addressCity         !== '')
            ) {

                setLoadButtonUpdate(true)
                await api.post('/costcenter/', formData, config)
                .then(response => {
                    setLoadButtonUpdate(false)

                    setLoadPageCompanyData(false)
                    setCostCenterDataAdd([]);
                    swal({ icon: "success", title: "Sucesso!", text: "Empresa adicionada com sucesso." });
                    window.location.href = '/configuracao/empresas-cadastradas';

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao adicionar a empresa, tente novamente mais tarde." });
                });

            }

        }

    }


    // // eslint-disable-next-line react-hooks/rules-of-hooks
    // const [ loadLogoCompany, setLoadLogoCompany ] = useState(false);

    async function handleDropAvatar(acceptedFiles) {

        let file      = acceptedFiles[0];

        setCostCenterDataAdd({
            ...costCenterDataAdd,
            file,
            fileURL: URL.createObjectURL(file)
        })

    }




    // console.log('costCenterDataAdd')
    // console.log(costCenterDataAdd)


    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-add-empresa">
                    <h2 className="titulo">Adicionar empresa</h2>
                    <ul>
                        <li>

                            <div className='bloco'>
                                <div className="texto"><p>Logo</p></div>
                                <div className="foto">
                                {
                                    // loadLogoCompany ?
                                    // <img src={CarregandoFoto} alt="carregando" className="carregamento-foto" />
                                    // :
                                    // comapanyData &&
                                    // comapanyData.logo    !== undefined   &&
                                    // comapanyData.logo    !== 'undefined' &&
                                    costCenterDataAdd                         &&
                                    costCenterDataAdd.fileURL                  ?
                                    <>
                                    <Dropzone onDrop={handleDropAvatar} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "upload-foto" })}>
                                            {/* <img src={collaborator.avatar} alt={collaborator.name} className="img-adicionada"/> */}
                                            <label className="label-foto-adicionado" ><img alt="+" src={IconCamera} className="icon"/><img alt="logo da empresa"  src={costCenterDataAdd.fileURL} className="foto"/></label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                        </div>
                                        )}
                                    </Dropzone>
                                    </>
                                    :
                                    <Dropzone onDrop={handleDropAvatar}>
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "upload-foto" })}>
                                                        <label className="labelIconPhoto"><div  className="iconPhoto"> <span></span> Enviar foto</div> </label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                        </div>
                                        )}
                                    </Dropzone>
                                }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Razão social</p></div>
                                <div>
                                    <input type="text" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.name !== undefined ?
                                        costCenterDataAdd.name

                                        :
                                        ''
                                    } name="name" onChange={myChangeHandlerCostCenter} placeholder="Razão social"  className={loadRazaoSocialEdit ? 'inputErro' : ''}/>
                                    {loadRazaoSocialEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Descrição</p></div>
                                <div>
                                    <input type="text" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.nameDescription !== undefined ?
                                        costCenterDataAdd.nameDescription
                                        :
                                        ''
                                    } name="nameDescription" onChange={myChangeHandlerCostCenter} placeholder="Descrição" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>CNPJ</p></div>
                                <div>
                                    <input type="text" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.cnpj !== undefined ?
                                        costCenterDataAdd.cnpj
                                        :
                                        ''
                                    } name="cnpj" onChange={myChangeHandlerCostCenter} placeholder="CNPJ" className={loadCnpj ? 'inputErro' : ''}/>
                                    {loadCnpj? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Telefone</p></div>
                                <div>
                                <input type="text" name="phone" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.phone !== undefined ?
                                        costCenterDataAdd.phone
                                        :
                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Telefone"  className={loadTelefoneEdit ? 'inputErro' : ''}/>
                                    {loadTelefoneEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Email</p></div>
                                <div>
                                    <input type="text" name="email" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.email !== undefined ?
                                        costCenterDataAdd.email
                                        :

                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Email"  className={loadEmailEdit ? 'inputErro' : ''}/>
                                    {loadEmailEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>  
                            </div>
                        </li>
                    </ul>
                    <h3 className="sub-titulo">Endereço</h3>
                    <ul>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>CEP</p></div>
                                <div>
                                <input type="text" name="zipcode" value={
                                    costCenterDataAdd &&
                                    costCenterDataAdd.zipcode !== undefined ?
                                    costCenterDataAdd.zipcode
                                    :

                                    ''
                                } onChange={myChangeHandlerCostCenter} placeholder="CEP"  className={loadCEPEdit ? 'inputErro' : ''}/>
                                    {loadCEPEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>

                            <div className='bloco'>
                                <div className="texto"><p>Número</p></div>
                                <div>
                                    <input type="text" name="addressNumber" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.addressNumber !== undefined ?
                                        costCenterDataAdd.addressNumber
                                        :

                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Número"  className={loadNumeroEdit ? 'inputErro' : ''}/>
                                    {loadNumeroEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Rua ou avenida</p></div>
                                <div>
                                    <input type="text" name="address" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.address !== undefined ?
                                        costCenterDataAdd.address
                                        :
                                        ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Rua ou avenida"  className={loadRuaAvenidaEdit ? 'inputErro' : ''}/>
                                    {loadRuaAvenidaEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className="texto"><p>Bairro</p></div>
                                <div>
                                    <input type="text" name="addressNeighborhood" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.addressNeighborhood !== undefined ?
                                        costCenterDataAdd.addressNeighborhood
                                        : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Bairro"  className={loadBairroEdit ? 'inputErro' : ''}/>
                                    {loadBairroEdit ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Estado</p></div>
                                <div>
                                <input type="text" name="addressState" value={
                                    costCenterDataAdd &&
                                    costCenterDataAdd.addressState !== undefined ?
                                    costCenterDataAdd.addressState
                                    :
                                    ''
                                } onChange={myChangeHandlerCostCenter} placeholder="Estado"  className={loadEstadoEdit ? 'inputErro' : ''}/>
                                {loadEstadoEdit? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                                </div>
                            </div>
                            <div className='bloco'>
                                <div className="texto"><p>Cidade</p></div>
                                <div>
                                <input type="text" name="addressCity" value={
                                    costCenterDataAdd &&
                                    costCenterDataAdd.addressCity !== undefined ?
                                    costCenterDataAdd.addressCity
                                    :
                                    ''
                                } onChange={myChangeHandlerCostCenter} placeholder="Cidade"  className={loadCidadeEdit ? 'inputErro' : ''}/>
                                {loadCidadeEdit ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='bloco'>
                                <div className="texto"><p>Complemento</p></div>
                                <div>
                                    <input type="text" name="addressComplement" value={
                                        costCenterDataAdd &&
                                        costCenterDataAdd.addressComplement !== undefined ?
                                        costCenterDataAdd.addressComplement

                                        : ''
                                    } onChange={myChangeHandlerCostCenter} placeholder="Complemento"  className={loadComplementoEdit ? 'inputErro' : ''}/>
                                    {loadComplementoEdit? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                </div>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="configSystem-btn-add-empresa">


                    <NavLink to={`/configuracao/empresas-cadastradas`} className="btnGray">Voltar</NavLink>
                    <button onClick={addCostCenter} className={loadButtonUpdate ? "btnGreen cadastrar load" : "btnGreen cadastrar"}>Salvar Empresa</button>

                </div>
            </Layout>
        </>
    );
}
