import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/default';
import { NavLink } from 'react-router-dom'
import Header from '../../../components/Administrativo/SystemsConfig/header';
import SubMenu from '../../../components/Administrativo/SystemsConfig/subMenu';
import Carregamento from '../../../assets/images/carregando.gif';
import swal from 'sweetalert';
import api from '../../../services/api';
// import axios from 'axios';
// import { phoneNumbersOnly } from '../../../services/mask';
import './styles.scss';
export default function pageSystemConfigAccoutants(props, path) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ listAccountants, setListAccountants] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadListAccountants, setLoadListAccountants ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        // setLoadPagePaymentMethod(true)
        async function loadaccountants() {
            setLoadListAccountants(true)
            await api.get('/accounting/')
            .then(response => {
                setLoadListAccountants(false)
                setListAccountants(response.data);
            }).catch(error => {})
        }
        loadaccountants();

    }, []);
    // console.log(listAccountants._id)


    async function inactiveAccounting(event) {

        setLoadListAccountants(true)
        await api.put(`/accounting/${event}`, { status: "Inativo" })
        .then(response => {
            setLoadListAccountants(false)
            swal({ icon: "success", title: "Sucesso!", text: "Contador deletado com sucesso." });

            async function loadaccountants() {
                setLoadListAccountants(true)
                await api.get('/accounting/')
                .then(response => {
                    setLoadListAccountants(false)
                    setListAccountants(response.data);
                }).catch(error => {})
            }
            loadaccountants();

        }).catch(error => {
            setLoadListAccountants(false)
            swal({ icon: "error", title: "Erro!", text: error.response.data.message });

        });

    }



    return (
        <>
            <Layout
            path={props.match.path}>
                <Header/>
                <SubMenu path={props.match.path}/>
                <div className="configSystem-contador">
                    <h5 className="titulo">Contadores</h5>
                    <div className="infor-page">
                        <p className="sub-titulo">
                            Os contadores cadastrados aqui serão usados para enviar e validar informações de
                            férias, holerites e outros dados financeiros dos colaboradores.
                        </p>
                        <div className="infor">
                            <p>
                                Você poderá selecionar para qual contador deseja enviar os dados. O contador receberá os dados dos colaboradores no email cadastrado. Os contadores cadastrados aqui não possuem acesso ao sistema e responderão diretamente por email ou de outra maneira acordada com o gestor.
                            </p>
                        </div>
                    </div>
                    {
                                    loadListAccountants ?

                                        <>

                                            <div className="carregamento">
                                                <img alt="" src={Carregamento} />
                                            </div>
                                        </>
                                    :
                            listAccountants &&
                            listAccountants.length !== 0 ? (<>
                            <div className="lista-contador">
                            <ul>

                            {
                                listAccountants &&
                                listAccountants.length !== 0 ?
                                listAccountants.map((listaAccountant, index) => (

                                    <li key={index} >
                                        <p>
                                            {listaAccountant.name}
                                        </p>

                                        <div className="edita-remover">
                                            <NavLink to={`/configuracao/editar-contador/${listaAccountant._id}`}>
                                                Editar
                                            </NavLink>
                                        </div>
                                        <div className="edita-remover">
                                            <button onClick={() => inactiveAccounting(listaAccountant._id)}>Remover</button>
                                        </div>
                                    </li>


                                    ))
                                    :
                                    ''
                                }
                            </ul>
                        </div>


                            </>) : ""


                    }



                    <div className="btn-add-contador">
                        <NavLink to={`/configuracao/cadastrar-contador`}>
                        + CADASTRAR CONTADOR
                        </NavLink>
                    </div>

                </div>
            </Layout>
        </>
    );
}
