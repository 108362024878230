import React, { useState, useEffect } from 'react';
import { decodeToken } from '../../../../services/auth';
import { toast } from 'react-toastify';


import api from '../../../../services/api';
export default function ColProfileBondSalary ({ id, collaborator }) {
//////////////////////////////////////////////////////////////////////
/////////////////////       Loads         ///////////////////////////

const decoded = decodeToken();

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ , setOccupations ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ registeredBondAndSalary, setRegisteredBondAndSalary ] = useState([]);

// eslint-disable-next-line react-hooks/rules-of-hooks
useEffect(() => {
    loadBondAndSalary();
    loadRegisteredCollaborator(id);
    loadoccupations(id);
}, [id]);

async function loadBondAndSalary() {
    await api.get(`/collaborator-bond-and-salary-access/`)
    .then(response => {
        if(response.data[0] !== undefined) {
            setRegisteredBondAndSalary(response.data[0]);
        }
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

// eslint-disable-next-line react-hooks/rules-of-hooks
const [ registeredCollaborator, setRegisteredCollaborator ] = useState([]);

async function loadRegisteredCollaborator(id) {
    await api.get(`/collaborator-access/${id}`)
    .then(response => {
        if(response.data !== undefined) {
            setRegisteredCollaborator(response.data);
        }
    }).catch(error => {
        toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
    });
}

async function loadoccupations(id) {
    if(id && id !== undefined) {
        await api.get(`/collaborator-access/${id}`)
        .then(response => {
            setOccupations(response.data);
            if (response.data !== undefined) {
                localStorage.setItem('@peopleview/collaboratorSalary', response.data.salary);

            } else {
                return
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }
}

    return (
        <>

            <div className="box BondSalary">
                <h3>Vínculo e Salário</h3>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Vínculo</p>
                        </div>
                        <div className="intemB nome">
                            <p>{registeredBondAndSalary.bond !== undefined ?(registeredBondAndSalary.bond) : ('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Cargo</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator.occupation !== undefined ? (registeredCollaborator.occupation) : ('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Departamento</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredCollaborator.sector !== undefined ? (registeredCollaborator.sector) : ('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Centro de custo</p>
                        </div>
                        <div className="intemB">
                                <p>{decoded && decoded.user && decoded.user.costCenter ? decoded.user.costCenter : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Salário</p>
                        </div>
                        <div className="intemB nome">
                            <p>
                                {registeredBondAndSalary.salary !== undefined ? (registeredBondAndSalary.salary) : ('-')}
                            </p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Válido a partir de</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredBondAndSalary.validOnTheDateOf !== undefined ?(registeredBondAndSalary.validOnTheDateOf) : ('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Motivo</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredBondAndSalary.reason !== undefined ?(registeredBondAndSalary.reason) : ('-')}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Descrição</p>
                        </div>
                        <div className="intemB">
                            <p>{registeredBondAndSalary.description !== undefined ?(registeredBondAndSalary.description) : ('-')}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}

