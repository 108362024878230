import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import "./styles.scss";

import api from '../../../../services/api';


export default function Dashboard(props) {

   const { token }      = props.match.params;
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ , setCollaborator ] = useState([]);
   // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {
        async function loadcollaborator() {
            await api.get(`/collaborator-token/${token}`)
            .then(response => {

                async function loadresettoken() {

                    setCollaborator(response.data);
                    await api.put(`/collaborator-update-reset-token/${response.data._id}`, { tokenStatus: true })

                    window.location.href = `/colaboradores/nova-admissao/formulario-dados-pessoais/${response.data._id}`;

                }
                loadresettoken()


            }).catch(error => {
                toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            });
        }
        loadcollaborator();
    }, [token]);

    return (
        <>
        </>
    )

}

