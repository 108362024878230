import React from 'react';
import { NavLink } from 'react-router-dom'
import HeaderAdmin from '../../HeaderAdmin';


export default function headerScore({
    collaborator
}) {

    return (
        <>
            <HeaderAdmin>
                <div>
                    <h1>
                        <NavLink to="/ponto"  className="voltar" > Voltar</NavLink>
                        Folha da {collaborator?.name}
                        </h1>
                    {/* <p>Atualizado pela última vez em 28 de Fevereiro de 2022 às 11:57</p> */}
                </div>
            </HeaderAdmin>

        </>
    );
}

