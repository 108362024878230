import React, { useState, useEffect } from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import Close from '../../../../assets/images/close.svg';
import { Modal} from "react-bootstrap";
import { toast } from 'react-toastify';
import api from '../../../../services/api';


export default function ColProfileBankData({
    id,
    collaborator,
    loadPage,
    collaboratorUpdate,
    EditBankDataVisibleBank,
    visibleBank,
    onCollaboratorInputChange,
    OnSubmit,
    mySearchBank,
    abrirResultBank,
    myChangeHandlerToSaveBank,
    abrirResultado,
    openAccountType,
    openSelAccountType,
    selectAccountTypeOpen,
    myChangeHandlerAccountType,
    loadContinue
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setCollaboratorSaved ] = useState([]);
    // const [ collaboratorSaved, setCollaboratorSaved ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadcadastrocolaborador(id);
        loadapibank();
    }, [id]);

    async function loadcadastrocolaborador(id) {
        await api.get(`/collaborator-access/${id}`)
        .then(response => {
            setCollaboratorSaved(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ apiBank, setApiBank] = useState([]);

    async function loadapibank() {
        await api.get(`https://brasilapi.com.br/api/banks/v1`)
        .then(response => {
            setApiBank(response.data);
        }).catch(error => {});
    }
    
    return (
        <>
            <div className="box banco">
                <h3>Dados bancários <button className="edit-Benefit" onClick={() => EditBankDataVisibleBank(collaborator)}><img src={EditBlack} alt="Edite Informações" /></button></h3>
                {
                    loadPage ?
                    (
                        <>
                        <div className="carregamento">
                            <img alt="" src={Carregando} />
                        </div>
                        </>
                    ):(
                        <>
                            <ul>
                                <li>
                                    <div className="intemA">
                                        <p>Banco</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{collaborator.bankName !== undefined ? collaborator.bankName : '-'}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Agência</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{collaborator.bankAgency !== undefined ? collaborator.bankAgency : '-'}</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Conta</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{collaborator.bankAccount ? collaborator.bankAccount : ''}
                                        
                                        {collaborator.accountDigit ? " - " : ''}
                                        {collaborator.accountDigit ? collaborator.accountDigit : ''}</p>
                                    </div>
                                </li>

                                <li>
                                    <div className="intemA">
                                        <p>Tipo de conta</p>
                                    </div>
                                    <div className="intemB">
                                        <p>

                                        {collaborator.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                        collaborator.AccountType &&
                                        collaborator.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                        collaborator.AccountType &&
                                        collaborator.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                        }
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="intemA">
                                        <p>Pix</p>
                                    </div>
                                    <div className="intemB">
                                        <p>{collaborator.pix !== undefined ? collaborator.pix : '-'}</p>
                                    </div>
                                </li>
                            </ul>
                        </>
                )}
            </div>
            <Modal show={visibleBank} className="popup-edit-perfil popup-edit-cartTrabalho" onHide={EditBankDataVisibleBank}>
                <div  onClick={openAccountType}>
                    <div className="titulo">
                        <h4>Dados bancários <p className="btn-fechar" onClick={EditBankDataVisibleBank}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Banco</p>
                            </div>
                            <div className="intemB">

                                <div className='select-busca'>
                                    <input type="text" 
                                        name="bankName"
                                        value={
                                            collaboratorUpdate && collaboratorUpdate.bankName !== undefined ? 
                                            collaboratorUpdate.bankName 
                                            :
                                            collaborator.bankName !== undefined ? 
                                            collaborator.bankName 
                                            : 
                                            ''
                                        } 
                                        placeholder='Banco' 
                                        onChange={mySearchBank} 
                                        onClick={abrirResultado}  
                                        id="myBank" className='busca'
                                        />
                                    {abrirResultBank ? 
                                        <div className='resultado-busca busca-bank' id='resultadoBusca'>
                                            {
                                                apiBank.length !== 0 ? apiBank
                                                .map((bank, index) => (
                                                    <button onClick={() => myChangeHandlerToSaveBank(bank.name) } key={index} ><p  >{bank.code} {bank.code === null ? "" : "-"} {bank.name}</p></button>
                                                )): ('')
                                            }
                                            
                                        </div>
                                        :
                                        ""
                                    } 

                                </div>

                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Agência</p>
                            </div>
                            <div className="intemB">
                                {collaborator.bankAgency !== undefined ?
                                (
                                    collaboratorUpdate && collaboratorUpdate.bankAgency ?
                                    (<input type="text" name="bankAgency"  value={collaboratorUpdate.bankAgency ? collaboratorUpdate.bankAgency : ''} onChange={onCollaboratorInputChange} className="inputDois" placeholder="Agência" />) :
                                    (<input type="text" name="bankAgency"  defaultValue={collaborator.bankAgency ? collaborator.bankAgency : ''} onChange={onCollaboratorInputChange} className="inputDois" placeholder="Agência"/>)
                                ) :
                                (
                                    <input type="text" name="bankAgency" value={collaboratorUpdate.bankAgency ? collaboratorUpdate.bankAgency : ''} onChange={onCollaboratorInputChange} placeholder="Agência" className="inputDois"/>
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Conta</p>
                            </div>
                            <div className="intemB digito-conta">
                            {
                                (collaboratorUpdate && collaboratorUpdate.bankAccount !== undefined) || collaboratorUpdate.bankAccount === "" ?
                                    (
                                        <input type="text" name="bankAccount" maxlength="20"  value={(collaboratorUpdate.bankAccount !== undefined) ? collaboratorUpdate.bankAccount : ''}  onChange={onCollaboratorInputChange} placeholder="Conta"   className="conta"/>
                                    ) :
                                    collaborator && collaborator.bankAccount ? (
                                        <input type="text" name="bankAccount"  maxlength="20"  defaultValue={(collaborator.bankAccount !== undefined) ? collaborator.bankAccount : ''}  onChange={onCollaboratorInputChange} placeholder="Conta" className="conta"/>
                                    ):
                                    (
                                        <input type="text" name="bankAccount"  maxlength="20"  value={(collaboratorUpdate.bankAccount !== undefined) ? collaboratorUpdate.bankAccount : ''}  onChange={onCollaboratorInputChange} placeholder="Conta"    className="conta"/>
                                    )
                                }

                                {
                                (collaboratorUpdate && collaboratorUpdate.accountDigit !== undefined) || collaboratorUpdate.accountDigit === "" ?
                                    (
                                        <input type="text" name="accountDigit" value={(collaboratorUpdate.accountDigit !== undefined) ? collaboratorUpdate.accountDigit : ''}  onChange={onCollaboratorInputChange} placeholder="Dígito" className="digito"/>
                                    ) :
                                    collaborator && collaborator.accountDigit ? (
                                        
                                        <input type="text" name="accountDigit" defaultValue={(collaborator.accountDigit !== undefined) ? collaborator.accountDigit : ''}  onChange={onCollaboratorInputChange} placeholder="Dígito" className="digito"/>
                                    ):
                                    (
                                        <input type="text" name="accountDigit" value={(collaboratorUpdate.accountDigit !== undefined) ? collaboratorUpdate.accountDigit : ''}  onChange={onCollaboratorInputChange} placeholder="Dígito"    className="digito"/>
                                    )
                                }
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo de conta</p>
                            </div>
                            <div className="intemB">
                                <div className='select-x'>
                                    <button className='btn-select selectAccountType' id="selectAccountType" onClick={selectAccountTypeOpen} >
                                        {
                                        collaboratorUpdate && collaboratorUpdate.AccountType !==  undefined ?
                                        collaboratorUpdate.AccountType ? ( collaboratorUpdate.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                        collaboratorUpdate.AccountType &&
                                        collaboratorUpdate.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                        collaboratorUpdate.AccountType &&
                                        collaboratorUpdate.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                        ) : "selecione" 
                                        :
                                        collaborator.AccountType ? ( collaborator.AccountType === 'conta-Corrente' ? 'Conta Corrente' :
                                        collaborator.AccountType &&
                                        collaborator.AccountType === 'conta-poupanca' ? 'Conta Poupança' :
                                        collaborator.AccountType &&
                                        collaborator.AccountType === 'conta-salario' ? 'Conta Salário' : '-'
                                        ) : "selecione" 
                                    }
                                    </button>
                                    {openSelAccountType ? 
                                    <div className='resultado-busca'>
                                        <button onClick={() => myChangeHandlerAccountType('conta-Corrente')}><p>Conta Corrente</p></button>
                                        <button onClick={() => myChangeHandlerAccountType('conta-poupanca')} ><p>Conta Poupança</p></button>
                                        <button onClick={() => myChangeHandlerAccountType('conta-salario')}><p>Conta Salário</p></button>
                                    </div>
                                    :""}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Pix</p>
                            </div>
                            <div className="intemB">
                                {collaborator.pix !== undefined ?
                                (<input type="text" name="pix" defaultValue={collaborator.pix ? collaborator.pix : ''} onChange={onCollaboratorInputChange} placeholder="Pix"/>):
                                (<input  type="text" name="pix" onChange={onCollaboratorInputChange} placeholder="Pix"/>)}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                            </div>
                            <div className="intemB">
                                <form onSubmit={OnSubmit} className="btn-salvar">
                                    <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    );
}
