import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: unset;
    justify-content: space-between;
`;


