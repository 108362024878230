import React, {
    useState
} from 'react';
import { Dropdown } from "react-bootstrap";
import iconOrdenation from '../../../assets/images/ordenation.svg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import FotoExemploAlvaro from '../../../assets/images/alvarobraz.png';
import iconVerMais from '../../../assets/images/more.svg';
import Pagination from '../../Reused/Pagination'

import { NavLink } from 'react-router-dom'

export default function performanceEvaluationTable() {

    ///////// SETS paginacao

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages,  ] = useState(0);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ limit,  ] = useState(1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pagesTotalItem,  ] = useState(0);
    return (
        <>
            <div className='table-filter'>
                <div className="filter">
                    <p>Colaboradores</p>
                    <input type="text" name="filter" className='campo-busca' placeholder='Filtre por colaborador'/>
                </div>
                <div className="table general-table ">
                    <div className="header-table">
                        <div>Colaborador</div>
                        <div className='departamento'>Departamento</div>
                        <div className='nota'>Nota média</div>
                        <div className="potuacao">Melhor pontuação</div>
                        <div className='view-more'>
                            <Dropdown  className="dropdown-icon">
                                <Dropdown.Toggle>
                                <img alt="" src={iconOrdenation} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button type="button" >
                                    Ordem alfabética
                                    </button>
                                    <button type="button"
                                    >
                                    Mais recentes primeiro
                                    </button>
                                    <button type="button" >
                                    Mais antigos primeiro
                                    </button>
                                    <button  type="button" >
                                    Por número de habilidades
                                    </button>
                                    <button  type="button" >
                                    Por gestor
                                    </button>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </div>

                    <div className='body-table'> 
                        <div className="item">
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id" className="collaborador">
                                <div className="semImgCol">
                                    <img  src={IconUserNone} alt="Usuario sem imagem" />
                                </div>
                                <div className='infor'>
                                    <strong>Alan Fellipe Pfaffenzeller Emmerich</strong>
                                    <p>Analista de Conteúdo Jr 2</p>
                                </div>
                            </NavLink>  
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id" className="departamento">
                                Conteúdo
                            </NavLink>  
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id" className="nota">
                                8.2
                            </NavLink>   
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id"  className="potuacao">
                                Embaixadorismo
                            </NavLink>  
                            
                            <div className='view-more'>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" >
                                        Editar agendamento
                                        </button>
                                        <button type="button" >
                                        Histórico deste colaborador
                                        </button>
                                        <button type="button" className='red' >
                                        Excluir agendamento
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className="item">
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id" className="collaborador">
                                <div className="foto">
                                    <img alt="" src={FotoExemploAlvaro}/>
                                </div>
                                <div className='infor'>
                                    <strong>Alan Fellipe Pfaffenzeller Emmerich</strong>
                                    <p>Analista de Conteúdo Jr 2</p>
                                </div>
                            </NavLink>  
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id" className="departamento">
                                Conteúdo
                            </NavLink>  
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id" className="nota">
                                8.2
                            </NavLink>   
                            <NavLink to="/gestao/avaliacao-de-desempenho/:id"  className="potuacao">
                                Embaixadorismo
                            </NavLink>  
                            
                            <div className='view-more'>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconVerMais} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" >
                                        Editar agendamento
                                        </button>
                                        <button type="button" >
                                        Histórico deste colaborador
                                        </button>
                                        <button type="button" className='red' >
                                        Excluir agendamento
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                    </div>


                    <Pagination
                        pages={pages}
                        setPage={setPage}
                        page={page}
                        limit={limit}
                        pagesTotalItem={pagesTotalItem}
                    /> 
                </div>
            </div>
        </>
    );
}

