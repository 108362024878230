import React, { useEffect } from 'react';

import api from '../../services/api'

export default function SideBar() {

    useEffect(() => {

        loadcollaboratorsrequestcurrentvacation();
        loaadCountNewCollaborators();

    }, []);

    async function loadcollaboratorsrequestcurrentvacation() {
        // setLoadVacationSummary(true)
        await api.get('/collaborator-vacation-new/?OnVacation=true&type=individual')
        .then(response => {

            console.log('response.data-headersection => collaborator-vacation-new/?OnVacation=true&type=individual')
            console.log(response.data)

            localStorage.setItem('@peopleview/totalInVacations', response.data[1][0].countOnVacation);

        }).catch(error => {});
    }

    async function loaadCountNewCollaborators() {
        // setLoadVacationSummary(true)
        await api.get('/number-of-new-collaborators')
        .then(response => {

            localStorage.setItem('@peopleview/countNewCollaborators', response.data);

        }).catch(error => {});
    }

    const totalInVacation       = parseInt(localStorage.getItem('@peopleview/totalInVacations'));
    const countNewCollaborators = parseInt(localStorage.getItem('@peopleview/countNewCollaborators'));

    return (
        <>
            <div className="subHeader">
                <ul>

                    {/* <li>
                        <strong>Currículos</strong>
                        <button type="button">
                            <span></span>
                            23 novos
                        </button>
                    </li> */}

                    <li>
                        <strong>Colaboradores</strong>
                        <button type="button">
                            <span></span>
                            {  countNewCollaborators === 1 ? '1 novo' : countNewCollaborators > 1 ? countNewCollaborators+ ' novos' : '0 novos' }
                        </button>
                        <button type="button" className="ferias">
                            <span></span>
                            { totalInVacation === 1 ? ' 1 em férias' : totalInVacation > 1 ? totalInVacation+ ' em férias' : '0 em férias' }
                        </button>
                    </li>
                    {/* <li>
                        <strong>Feedback</strong>
                        <button type="button">
                            <span></span>
                            18 novos
                        </button>
                    </li> */}
                </ul>
            </div>
        </>
    );
}
