import React, {useState, useEffect} from 'react';

import Close from '../../../assets/images/close.svg';
import HeaderAdmin from '../../HeaderAdmin';
import { Modal } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';

import SelectPersonalizado from '../../../components/Reused/selectPersonalizado'

export default function HeaderFolhaDePagamento ({
    history,
    props,
    path,
    visibleCastrarMes,
    CastrarNovoMes,
    NaoVerCastrarNovoMes,
    loadCadYearPayroll,
    loadCadPayrollMonth,
    loadCadPayrollType,
    registerNewMonth,
    myChangeHandlerPayrollStore,
    cancelStatePayrollStore,
    savePayrollStore,
    loadButtonPayrollStore,
    id,
    user,
    loadPageNotifications,
    notifications,
    showMore,
    notificationsRead,
    readTrue,
    indexUpdate,
    setRegisterNewMonth,
    loadSendAllPaycheck,

    sendAllPaycheck,
    payroll,
    payrollHeader,
    setLoadCadYearPayroll,
    loadPayroll,
}) {

    const anoCorrent = new Date().getFullYear();
    const mesCorrent = new Date().getMonth();

    useEffect(()=>{
        anosCorrentes(anoCorrent);
        mesCorrentes(mesCorrent);
    },[anoCorrent, mesCorrent])

    const [ anos, setAnos ]           = useState([])

    function anosCorrentes(anoCorrent) {
        let anoInicial = 2000;
        const todosAnos = [];
        for(let a=anoInicial; a <= anoCorrent; a++){
             let ano = a;
             todosAnos.push(ano);
        }
        setAnos(todosAnos)
    }
    const anosFolhaPagamento = anos.sort((a, b) => b-a)

    const [ , setMesesAnteriores ]           = useState([])

    function mesCorrentes(mesCorrent) {
        const todosMes = [];
        for(let a=0; a <= mesCorrent; a++){
             let mes = a;
             todosMes.push(
                mes === 0 ? 'Janeiro' : "" ||
                mes === 1 ? 'Fevereiro'  : ""  ||
                mes === 2 ? 'Março'  : ""  ||
                mes === 3 ? 'Abril'  : ""  ||
                mes === 4 ? 'Maio'   : "" ||
                mes === 5 ? 'Junho' : ""   ||
                mes === 6 ? 'Julho'  : ""  ||
                mes === 7 ? 'Agosto'  : ""  ||
                mes === 8 ? 'Setembro'  : ""  ||
                mes === 9 ? 'Outubro'   : "" ||
                mes === 10 ? 'Novembro'   : "" ||
                mes === 11 ? 'Dezembro' : ""




                 );
        }
        setMesesAnteriores(todosMes)
    }
    const todosMesesAno = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ]
    const todosTipos =  [
        'Holerites',
        'Bônus',
        'Comissão',
        'Informe de Rendimentos',
        '13º Salário',
        'Outros',
        'Recibo de Férias',
        '13º Salário - Primeira parcela',
        '13º Salário - Segunda parcela',
        'Adiantamento',
        'PLR',
        'Folha Complementar',
        'Recibos',
        'Pró-labore',
        'Espelho de Ponto',
    ]

    return (
        <>
        <HeaderAdmin>
            {
               path && path === '/folha-de-pagamento' ?
                <>
                <div>
                    <h1>
                    Folha de pagamento
                    </h1>
                    <p>Acompanhe e gerencie a folha de pagamento</p>
                </div>
                <div className='folha-pagamento-cadastrar-novoMes'>
                    <button className="btnGreen more" onClick={() => CastrarNovoMes()}>Cadastrar novo mês</button>
                </div>
                </> :""
            }

            {
               path && path === '/folha-de-pagamento/ano/mes/tipo/:id' ?
                <>
                <div>
                    <h1>
                        <NavLink to="/folha-de-pagamento" className="voltar">Voltar</NavLink>{
                            loadPayroll ?
                                <Skeleton 
                                    count={1}  
                                    height={37}
                                    width={50}
                                    borderRadius={4}
                                    style={{marginBottom: "15px"}}
                                />
                            : <>
                            {payroll && payroll.month !== undefined ? payroll.month : ""} {payroll && payroll.year !== undefined ? payroll.year : ""} - {payroll && payroll.type !== undefined ? payroll.type : ""}
                            </>
                        }
                        
                    </h1>
                    <p>Gerencie e envie os horelites dos colaboradores.</p>
                </div>
                <div className='folha-pagamento'>
                    <Link to={{
                            pathname:`/folha-de-pagamento/ano/mes/enviar/${id}`, 
                            state:{payroll}
                        }} className="btnGreen more add">Adicionar holerites para todos</Link>
                    <button onClick={sendAllPaycheck} className={loadSendAllPaycheck ? "btnGreen enviar load" : "btnGreen enviar"}>Enviar email para todos</button>
                </div>
                </>:""
            }


        </HeaderAdmin>



        {
           path && path === '/folha-de-pagamento/ano/mes/tipo/:id' ?
            <>
                <div className='PgFolha-De-PagamentoHeader'>
                    <Link to={{
                            pathname:`/folha-de-pagamento/ano/mes/enviar/${id}`, 
                            state:{payroll}
                        }} className="btnGreen more add">Adicionar holerites para todos</Link>
                    <button onClick={sendAllPaycheck} className={loadSendAllPaycheck ? "btnGreen enviar load" : "btnGreen enviar"}>Enviar email para todos</button>
                </div>

            </>:""
        }

       
            <Modal show={visibleCastrarMes} className="popup-geral popup-payroll-cadastra" onHide={NaoVerCastrarNovoMes}>
                <div>
                    <div className="titulo">
                        <h4>Cadastrar novo mês <p className="btn-fechar" onClick={NaoVerCastrarNovoMes}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Ano</p>
                            </div>
                            <div className="intemB">

                            <SelectPersonalizado 
                                valorCampo={
                                    registerNewMonth && registerNewMonth.year ?
                                    registerNewMonth.year :
                                    ""
                                }
                                nameButton={"year"}
                                loadError={loadCadYearPayroll}
                                myChangeHandler={myChangeHandlerPayrollStore}
                                option={anosFolhaPagamento ? anosFolhaPagamento : ""}
                            />
                              


                                {loadCadYearPayroll ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Mês</p>
                            </div>
                            <div className="intemB">
                            {

                                registerNewMonth && registerNewMonth.year === anoCorrent ?
                                <SelectPersonalizado 
                                    valorCampo={
                                        registerNewMonth && registerNewMonth.month ?
                                        registerNewMonth.month :
                                        ''
                                    }
                                    loadError={loadCadPayrollMonth}
                                    nameButton={"month"}
                                    myChangeHandler={myChangeHandlerPayrollStore}
                                    optionFil={todosMesesAno ? todosMesesAno : ""}
                                    typeSelect="busca2"
                                />
                                :

                                <SelectPersonalizado 
                                    valorCampo={
                                        registerNewMonth && registerNewMonth.month ?
                                        registerNewMonth.month :
                                        ''
                                    }
                                    loadError={loadCadPayrollMonth}
                                    nameButton={"month"}
                                    myChangeHandler={myChangeHandlerPayrollStore}
                                    optionFil={todosMesesAno ? todosMesesAno : ""}
                                    typeSelect="busca2"
                                />

                            }

                               
                                {loadCadPayrollMonth ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo</p>
                            </div>
                            <div className="intemB">



                                <SelectPersonalizado 
                                    valorCampo={
                                        registerNewMonth && registerNewMonth.type ?
                                        registerNewMonth.type :
                                        ''
                                    }
                                    loadError={loadCadPayrollType}
                                    nameButton={"type"}
                                    myChangeHandler={myChangeHandlerPayrollStore}
                                    // option={todosTipos}

                                    optionFil={todosTipos ? todosTipos : ""}
                                    typeSelect="busca2"
                                />

                                {loadCadPayrollType ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                    </ul>
                    <div className="buttoes">
                        <button className="btnGray" onClick={cancelStatePayrollStore}>Cancelar</button>
                        <button onClick={savePayrollStore} className={loadButtonPayrollStore ? "btnGreen load" : "btnGreen"}>Salvar novo mês</button>
                    </div>
                </div>
            </Modal>



        </>
    );
}

