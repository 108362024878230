import React,{useState} from 'react';
import EditBlack from '../../../../assets/images/edit-black.svg';
import Carregando from '../../../../assets/images/carregando.gif';
import Close from '../../../../assets/images/close.svg';
import Dropzone from "react-dropzone";
import { Modal } from "react-bootstrap";
import {removerSpecials} from '../../../../services/utils'
import SelectPersonalizado from '../../../../components/Reused/selectPersonalizado'

export default function ColProfileWorkCard({
    id,
    collaborator,
    loadPage,
    onCollaboratorInputChange,
    collaboratorUpdate,
    ufEstados,
    ufDistrito ,
    ufCity,
    OnSubmit,
    loadContinue,
    EditWorkCardVisible,
    visibleWorkCard,
    filesSavedWorkCard,
    loadWorkCard,
    handleDropWorkCard,
    deleteFileWorkCard,
    setSelectWalletUF,
    setCollaboratorUpdate,
    selectWalletUF,
    estadosOrgam
}) {


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [walletUFStringBusca , setwalletUFStringBusca] = useState([]);

    const myChangeHandlerSlects = event => {
        let nam = event.target.name;
        let val = event.target.value;

        
        if(nam === "walletUFBusca") {
            let valOption = removerSpecials(val).toLowerCase()
            setwalletUFStringBusca({ ...walletUFStringBusca, [nam]: valOption });
        }else if(
            nam === "walletUF"
        ) {
            setCollaboratorUpdate({ ...collaboratorUpdate,  "walletUF": val })
            setwalletUFStringBusca({ ...walletUFStringBusca, "walletUFBusca": val });

        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const optionFiltreUCidade = estadosOrgam.filter((valOption) => {
    return removerSpecials(valOption).toLowerCase().includes(walletUFStringBusca?.walletUFBusca !== undefined ? walletUFStringBusca.walletUFBusca : "" )  ;
    });
 



    return (
            <>

                <div className="box carteira-trabalho">
                <h3>Carteira de Trabalho <button className="edit-Benefit" onClick={() => EditWorkCardVisible()}><img src={EditBlack} alt="Edite Informações" /></button></h3>
                {
                    loadPage ?
                        (
                            <>
                            <div className="carregamento">
                                <img alt="" src={Carregando} />
                            </div>
                            </>
                        ):(
                            <>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Número da carteira</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.walletNumber ? collaborator.walletNumber : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Nº de série</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.serialNumber ? collaborator.serialNumber : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Data de emissão</p>
                        </div>
                        <div className="intemB">
                        <p>{collaborator.issueDate ? collaborator.issueDate : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>UF da carteira</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.walletUF ? collaborator.walletUF : '-'}</p>
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Número PIS</p>
                        </div>
                        <div className="intemB">
                            <p>{collaborator.pisNumber ? collaborator.pisNumber : '-'}</p>
                        </div>
                    </li>

                    {filesSavedWorkCard[0] !== undefined ? (
                    <li className="li-anexo">
                        <div className="intemA">
                            <p>Anexo</p>
                        </div>



                    <div className="intemB arq-adicionado" >
                        {filesSavedWorkCard[0] !== undefined ?
                        filesSavedWorkCard.map((filesaved, index) => (
                                    <a href={filesaved.location} target="’_blank’" key={index}>
                                        {filesaved.name}
                                    </a>
                        )) : (
                            <>
                            <p>-</p>
                            </>
                        )}


                    </div>
                    </li>
                    ) : ('')}
                </ul>


                </>)}
            </div>

            <Modal show={visibleWorkCard} className="popup-edit-perfil popup-edit-cpf" onHide={EditWorkCardVisible}>
            <div>
                <div className="titulo">
                    <h4>Carteira de Trabalho  <p className="btn-fechar" onClick={EditWorkCardVisible}><img src={Close} alt="Fechar" /></p></h4>
                </div>
                <ul>
                    <li>
                        <div className="intemA">
                            <p>Número da carteira</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.walletNumber !== undefined) || collaboratorUpdate.walletNumber === "" ?
                            (
                                <input type="text"  maxlength="8" name="walletNumber" value={(collaboratorUpdate.walletNumber !== undefined) ? collaboratorUpdate.walletNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número da carteira"  />
                            ) :
                            collaborator && collaborator.walletNumber ? (
                                <input type="text"  name="walletNumber" maxlength="8" defaultValue={(collaborator.walletNumber !== undefined) ? collaborator.walletNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número da carteira"/>
                            ):
                            (
                                <input type="text"  maxlength="8" name="walletNumber" value={(collaboratorUpdate.walletNumber !== undefined) ? collaboratorUpdate.walletNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número da carteira"  />
                            )
                            }
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Nº de série</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.serialNumber !== undefined) || collaboratorUpdate.serialNumber === '' ?
                            (
                                <input type="text"  maxlength="4" name="serialNumber" value={(collaboratorUpdate.serialNumber !== undefined) ? collaboratorUpdate.serialNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Nº de série"  />
                            ) :
                            collaborator && collaborator.serialNumber ? (
                                <input type="text"  name="serialNumber" maxlength="4" defaultValue={(collaborator.serialNumber !== undefined) ? collaborator.serialNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Nº de série"/>
                            ):
                            (
                                <input type="text"  maxlength="4" name="serialNumber" value={(collaboratorUpdate.serialNumber !== undefined) ? collaboratorUpdate.serialNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Nº de série"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Data de emissão</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.issueDate !== undefined) || collaboratorUpdate.issueDate === '' ?
                            (
                                <input type="text"  maxlength="10" name="issueDate" value={(collaboratorUpdate.issueDate !== undefined) ? collaboratorUpdate.issueDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  />
                            ) :
                            collaborator && collaborator.issueDate ? (
                                <input type="text"  name="issueDate" maxlength="10" defaultValue={(collaborator.issueDate !== undefined) ? collaborator.issueDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"/>
                            ):
                            (
                                <input type="text"  maxlength="10" name="issueDate" value={(collaboratorUpdate.issueDate !== undefined) ? collaboratorUpdate.issueDate : ''}  onChange={onCollaboratorInputChange} placeholder="DD/MM/AAAA"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>UF da carteira</p>
                        </div>
                        <div className="intemB">
                             <SelectPersonalizado
                                valorCampo={
                                    walletUFStringBusca && walletUFStringBusca.walletUFBusca ?
                                    walletUFStringBusca.walletUFBusca :
                                    walletUFStringBusca && walletUFStringBusca.walletUFBusca === '' ?
                                    '' :
                                    collaborator && collaborator.walletUF ?
                                        collaborator.walletUF
                                        :
                                        ''
                                }
                                nameCampoBusca={"walletUFBusca"}
                                nameButton={"walletUF"}
                                myChangeHandler={myChangeHandlerSlects}
                                option={optionFiltreUCidade}
                                typeSelect={"busca"}
                            />
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Número PIS</p>
                        </div>
                        <div className="intemB">
                            {
                            (collaboratorUpdate && collaboratorUpdate.pisNumber !== undefined) || collaboratorUpdate.pisNumber === '' ?
                            (
                                <input type="text" name="pisNumber" value={(collaboratorUpdate.pisNumber !== undefined) ? collaboratorUpdate.pisNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número PIS"  />
                            ) :
                            collaborator && collaborator.pisNumber ? (
                                <input type="text" name="pisNumber" defaultValue={(collaborator.pisNumber !== undefined) ? collaborator.pisNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número PIS"/>
                            ):
                            (
                                <input type="text" name="pisNumber" value={(collaboratorUpdate.pisNumber !== undefined) ? collaboratorUpdate.pisNumber : ''}  onChange={onCollaboratorInputChange} placeholder="Número PIS"  />
                            )}
                        </div>
                    </li>
                    <li>
                        <div className="intemA">
                            <p>Anexar</p>
                        </div>
                        <div className="intemB">
                                <Dropzone onDrop={handleDropWorkCard} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                        </div>
                    </li>

                    <li>
                        <div className="intemA">
                        </div>
                        <div className="intemB">
                            <p className="infor-doc-dropzone">Nós aceitamos os arquivos que estão no formato   .jpg, .jpeg, .pjpeg, .png, .gif, .doc, e .pdf. Tamanho permitido 2mb.</p>
                        </div>
                    </li>
                    {filesSavedWorkCard[0]  !== undefined ? (
                    <li>
                        <div className="arquivoAnexado">
                            <h5>Enviados</h5>

                            {filesSavedWorkCard[0] !== undefined ?
                            filesSavedWorkCard.map((filesaved, index) => (
                                <div key={index} className="arquivo">
                                    <p className="nomeArquivo">
                                        <a href={filesaved.location} target="’_blank’">
                                            {filesaved.name}
                                        </a>
                                    </p>
                                    <button onClick={()=>deleteFileWorkCard(filesaved)} className="btnExclui">Remover anexo</button>
                                </div>
                            )) : ''}

                        </div>
                    </li>
                            ) : ''}
                    {loadWorkCard ?
                    ( <li>
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    </li>) : ('')}
                    <li>
                        <div className="intemA">
                        </div>
                        <div className="intemB">
                            <form onSubmit={OnSubmit} className="btn-salvar">
                                <button type="submit" className={loadContinue  ? 'saved btnGreen load' : 'saved btnGreen'}>Salvar</button>
                            </form>
                        </div>
                    </li>

                </ul>


            </div>
        </Modal>
    </>
    );
}

