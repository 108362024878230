import React, {useState, useEffect,useRef} from 'react';
import {removerSpecials} from '../../services/utils'
import { Scrollbars } from 'react-custom-scrollbars';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone';
import api from '../../services/api';
// import SelectPersonalizado from "../../components/Reused/selectPersonalizado"
import { FormataStringData } from '../../services/utils';
import { dateMask, phones, cep } from '../../services/mask';
import axios from 'axios';

export default function VacanciesPageForm({
    id_vacancie,
    companyId,
    focusForm,
    urlLp
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadsectors(companyId);
    }, [companyId]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadNameCandidate, setLoadNameCandidate] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadDateOfBirth, setLoadDateOfBirth] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSector,  setLoadSector] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadLaborBond,  setLoadLaborBond] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPhone, setLoadPhone ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadEmail, setLoadEmail ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadZipcode, setLoadZipcode ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadAddress, setLoadAddress ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadAddressNeighborhood, setLoadAddressNeighborhood ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadState, setLoadState ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadCity, setLoadCity ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ candidate, setCandidate ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ dateOfBirthUpdate, setDateOfBirthUpdate ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setDateOfbirthSaved ] = useState([]);

    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA sector /////////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sectors, setSectors ] = useState([]);
    async function loadsectors(companyId) {
        await api.get(`/sector-public/${companyId}`)
        .then(response => {
            setSectors(response.data);
        }).catch(error => {})
    }
    const myChangeHandlerSelects = event => {

        let nam = event.target.name;
        let val = event.target.value;
        if (nam === "nameSector"){
            let valSector = removerSpecials(val).toLowerCase()
            setBuscaSector({ ...buscaSector, [nam]: val });
            setBuscaSectorState({ ...buscaSectorState, [nam]: valSector });
            setCandidate({ ...candidate, [nam]: val })
        }
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSector, setBuscaSector ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [buscaSectorState, setBuscaSectorState ] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectBuscaSector, setIsActiveSelectBuscaSector] = useState(false);
    async function SelectBuscaSector() { setIsActiveSelectBuscaSector(!isActiveSelectBuscaSector) }
    // setLoadSector
    const sectorsFiltre = sectors.filter((sector) => {
        return removerSpecials(sector.name).toLowerCase().includes(buscaSectorState?.nameSector !== undefined ? buscaSectorState.nameSector : "" )  ;
    });


    //////////////////////////////////////////////////////
    ///////////////// BUSCA DA sector /////////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isActiveSelectLaborBond, setIsActiveSelectLaborBond] = useState(false);
    async function SelectLaborBond() { setIsActiveSelectLaborBond(!isActiveSelectLaborBond) }



    async function getAddress(zipcode) {
        zipcode = String(zipcode).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
        if (zipcode.length >= 8) {
            //const response = await api.get(`https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${zipcode}/json/`);
            // const response =
            await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
            .then(response => {
                setCandidate({
                    ...candidate,
                    zipcode: zipcode,
                    address: response.data.logradouro,
                    addressNeighborhood : response.data.bairro,
                    addressCity: response.data.localidade,
                    addressState: response.data.uf,
                })

                setLoadAddress(false)
                setLoadAddressNeighborhood(false)
                setLoadState(false)
                setLoadCity(false)
            })
            .catch(error=>{

                let val = ''
                setCandidate({ ...candidate, 'zipcode': val })

                setLoadZipcode(true)
                setLoadAddress(true)
                setLoadAddressNeighborhood(true)
                setLoadState(true)
                setLoadCity(true)

            })

        }
    }

    //////////////////////////////////////////////////////
    ///////////////// DROPZONE /////////////////
    ////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [laodDropZone, setLoadDropZone ] = useState(false);

    async function handleDrop(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png') ||
            (typeI === 'image/gif') ||
            (typeI === 'application/pdf') ||
            (typeI === 'application/msword') ||
            (typeI === 'application/vnd.ms-powerpoint') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
            (typeI === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (typeI === 'application/vnd.ms-excel') ||
            (typeI === 'text/csv') ||
            (typeI === 'application/csv' )
        ) {
            let file     = acceptedFiles[0];
            let nameFile = acceptedFiles[0].name;

            // setFileNames(acceptedFiles.map(file => file.name));
            setCandidate({ ...candidate, file, nameFile })
            setLoadDropZone(false)
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    function removeAttachment(){

        let file     = null;
        let nameFile = null;
        setCandidate({ ...candidate, file, nameFile })
        setLoadDropZone(true)

    }

    

    function sectorSelected(id_sector, name_sector) {
        setCandidate({ ...candidate, 'sector': id_sector, 'nameSector': name_sector })
        setLoadSector(false)
    }

    // sectorlaborBond
    function sectorlaborBond(name) {
        setCandidate({ ...candidate, 'laborBond': name})
        setLoadLaborBond(false)

    }

    async function mychangeHandlerCandidate(event) {

        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'name') {

            if(val.length === 0) {
                setLoadNameCandidate(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadNameCandidate(false)
                setCandidate({ ...candidate, [nam]: val })
            }



        }
        else
        if(nam === "dateOfBirth") {

            let date = dateMask(val)

            if(date.length === 0) {
                setLoadDateOfBirth(true)
                setCandidate({ ...candidate, [nam]: date })
            }
            else
            if(date.length >= 1 && date.length <= 10) {
                setCandidate({ ...candidate, [nam]: date })
                let valDate = FormataStringData(date);
                setLoadDateOfBirth(false)
                setDateOfBirthUpdate({ ...dateOfBirthUpdate, [nam]: valDate })
                setDateOfbirthSaved(date)
            }

        }
        // phone
        else
        if(nam === 'phone') {

            if(val.length === 0) {
                setLoadPhone(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                let phone = phones(val)
                setLoadPhone(false)
                setCandidate({ ...candidate, [nam]: phone })
            }

        }
        // email
        else
        if(nam === 'email') {

            if(val.length === 0) {
                setLoadEmail(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadEmail(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if (nam === "zipcode") {

            if(val.length === 0) {
                setLoadZipcode(true)
            }
            else {
                setLoadZipcode(false)
            }


            getAddress(val)
            let zipcode = cep(val)
            setCandidate({ ...candidate, [nam]: zipcode })

        }
        else
        if(nam === 'address') {

            if(val.length === 0) {
                setLoadAddress(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadAddress(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if(nam === 'addressNeighborhood') {

            if(val.length === 0) {
                setLoadAddressNeighborhood(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadAddressNeighborhood(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if(nam === 'addressState') {

            if(val.length === 0) {
                setLoadState(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadState(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else
        if(nam === 'addressCity') {

            if(val.length === 0) {
                setLoadCity(true)
                setCandidate({ ...candidate, [nam]: val })
            }
            else {
                setLoadCity(false)
                setCandidate({ ...candidate, [nam]: val })
            }

        }
        else {

            setCandidate({ ...candidate, [nam]: val })

        }





    }



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusData           = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusSetor          = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusRegime          = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusTelfone         = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusEmail         = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCep         = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusRua         = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusBairro         = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusEstado         = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusCidade         = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadBtnSave, setLoadBtnSave ] = useState(false);

    async function saveCandidate(){

        if(

            candidate?.name === '' ||
            candidate?.name === undefined
            ||
            candidate?.dateOfBirth === '' ||
            candidate?.dateOfBirth === undefined
            ||
            candidate?.sector === '' ||
            candidate?.sector === undefined
            ||
            candidate?.laborBond === '' ||
            candidate?.laborBond === undefined
            ||
            candidate?.phone === '' ||
            candidate?.phone === undefined
            ||
            candidate?.email === '' ||
            candidate?.email === undefined
            ||
            candidate?.zipcode === '' ||
            candidate?.zipcode === undefined
            ||
            candidate?.address === '' ||
            candidate?.address === undefined
            ||
            candidate?.addressNeighborhood === '' ||
            candidate?.addressNeighborhood === undefined
            ||
            candidate?.addressCity === '' ||
            candidate?.addressCity === undefined
            ||
            candidate?.addressState === '' ||
            candidate?.addressState === undefined
            ||
            candidate?.file === '' ||
            candidate?.file === undefined
        ) {


            if(candidate?.file === '' || candidate?.file === undefined){
                setLoadDropZone(true)
            }

            if(candidate?.addressState === '' || candidate?.addressState === undefined){
                setLoadState(true)
                focusEstado.current.focus();
            }

            if(candidate?.addressCity === '' || candidate?.addressCity === undefined){
                setLoadCity(true)
                focusCidade.current.focus();
            }

            if(candidate?.addressNeighborhood === '' || candidate?.addressNeighborhood === undefined){
                setLoadAddressNeighborhood(true)
                focusBairro.current.focus();
            }

            if(candidate?.address === '' || candidate?.address === undefined){
                setLoadAddress(true)
                focusRua.current.focus();
            }

            if(candidate?.zipcode === '' || candidate?.zipcode === undefined){
                setLoadZipcode(true)
                focusCep.current.focus();
            }

            if(candidate?.email === '' || candidate?.email === undefined){
                setLoadEmail(true)
                focusEmail.current.focus();
            }

            if(candidate?.phone === '' || candidate?.phone === undefined){
                setLoadPhone(true)
                focusTelfone.current.focus();
            }

            if(candidate?.laborBond === '' || candidate?.laborBond === undefined){
                setLoadLaborBond(true)
                focusRegime.current.focus();
            }

            if(candidate?.sector === '' || candidate?.sector === undefined){
                setLoadSector(true)
                focusSetor.current.focus();
            }

            if(candidate?.dateOfBirth === '' || candidate?.dateOfBirth === undefined){
                setLoadDateOfBirth(true)
                focusData.current.focus();
            }

            if(candidate?.name === '' || candidate?.name === undefined){
                setLoadNameCandidate(true)
                focusForm.current.focus();
            }
            
            return

        }
        else {
            // alert('salvar')
            setLoadNameCandidate(false)
            setLoadDateOfBirth(false)
            setLoadSector(false)
            setLoadLaborBond(false)
            setLoadPhone(false)
            setLoadEmail(false)
            setLoadZipcode(false)
            setLoadAddress(false)
            setLoadAddressNeighborhood(false)
            setLoadCity(false)
            setLoadState(false)
            setLoadDropZone(false)

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            formData.append('name', candidate.name);
            formData.append('dateOfBirth', dateOfBirthUpdate.dateOfBirth);
            formData.append('sector', candidate.sector);
            formData.append('laborBond', candidate.laborBond);
            formData.append('phone', candidate.phone);
            formData.append('email', candidate.email);
            formData.append('zipcode', candidate.zipcode);
            formData.append('address', candidate.address);
            formData.append('addressNeighborhood', candidate.addressNeighborhood);
            formData.append('addressCity', candidate.addressCity);
            formData.append('addressState', candidate.addressState);
            formData.append('file', candidate.file);
            if(candidate?.addressNumber) {
                formData.append('addressNumber', candidate.addressNumber);
            }
            if(candidate?.portfolio) {
                formData.append('portfolio', candidate.portfolio);
            }
            if(candidate?.facebook) {
                formData.append('facebook', candidate.facebook);
            }
            if(candidate?.linkedin) {
                formData.append('linkedin', candidate.linkedin);
            }
            // alert("salvo")
            setLoadBtnSave(true)
            await api.post(`/candidate-public/?id_vacancie=${id_vacancie}&companyId=${companyId}`, formData, config)
            .then(response => {
                setLoadBtnSave(false);

                swal({ icon: "success", title: "Sucesso!", text: "Você se candidatou com sucesso!" });

                setTimeout(function(){window.location.href =  `/lp/${urlLp}`;}, 1000);
    
            }).catch(error => {
                setLoadBtnSave(false);
                swal({
                    icon: "error",
                    title: "Erro!",
                    text: error?.response?.data?.message ?
                    error?.response?.data?.message
                    :
                    error?.response?.data?.error ?
                    error?.response?.data?.error
                    :
                    'Não foi possível se candidatar a essa vaga!'
    
                });
            });
        }

}

    return (
        <>
        
        <div className='formulario'>
            <h4 className='titulo'>Aplicar para vaga</h4>
                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Nome completo</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" ref={focusForm} placeholder='Nome completo' name='name' className={loadNameCandidate ? 'inputErro' : ''} onChange={mychangeHandlerCandidate} />
                                    { loadNameCandidate ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>

                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Data de nascimento</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='DD/MM/AAAA' 
                                        maxLength={10} 
                                        ref={focusData}
                                        name='dateOfBirth' 
                                        className={loadDateOfBirth ? 'inputErro' : ''}  
                                        onChange={mychangeHandlerCandidate} 
                                        value={
                                            candidate?.dateOfBirth !== undefined ?
                                            candidate.dateOfBirth : ""
                                        }
                                    />
                                    { loadDateOfBirth ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Setor</p>
                                </div>
                                <div className="intemB">
                                <div className="select-busca-btn">
                                    <input
                                        type="text"
                                        className={loadSector ? "menu-button inputErro" : "menu-button"}
                                        placeholder='Selecione'
                                        name="nameSector"
                                        value={
                                            candidate?.nameSector ? candidate?.nameSector : ''
                                        }
                                        ref={focusSetor}
                                        onClick={ SelectBuscaSector }
                                        onBlur={ () => (setIsActiveSelectBuscaSector(false)) }
                                        onChange={myChangeHandlerSelects}
                                    />
                                    <nav className={`menu ${isActiveSelectBuscaSector ? "ativo": ""}`} >
                                        <Scrollbars className='listDropDown'  style={{  height:
                                        sectorsFiltre.length === 0 ? 30 : "" ||
                                        sectorsFiltre.length === 1 ? 30 : ""  ||
                                        sectorsFiltre.length === 2 ? 70 : ""  ||
                                        sectorsFiltre.length === 3 ? 120 : 120
                                        }}>
                                            <ul>
                                                {
                                                    sectorsFiltre.length === 0 ?
                                                        <li>
                                                            <button>Nada encontrado</button>
                                                        </li>
                                                    :
                                                    sectorsFiltre
                                                    .map((sector, index) => (
                                                        <li key={index}>
                                                            <button
                                                            value={sector.name}
                                                            name="sector"
                                                            onClick={()=>sectorSelected(sector._id, sector.name)}
                                                            >{sector.name}</button>
                                                        </li>
                                                    ))}
                                            </ul>

                                        </Scrollbars>
                                    </nav>
                                </div>
                                {loadSector ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                                </div>

                            </div>
                        </div>
                        <div className="linha-typeOne">
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Regime</p>
                                </div>
                                <div className="intemB">


                                    <div className="select-busca-btn">
                                        <button
                                            className={loadLaborBond ? "menu-button inputErro" : "menu-button"}
                                            onBlur={ () => (setIsActiveSelectLaborBond(false)) }
                                            onClick={ SelectLaborBond }

                                            ref={focusRegime}
                                        >
                                            <span>
                                    
                                            {
                                            candidate?.laborBond ? 
                                                candidate?.laborBond === "PJ"  ? 
                                                "Pessoa Jurídica" : candidate?.laborBond 
                                             : 
                                                    'Selecione'
                                            }

                                            </span>

                                        </button>
                                        <nav className={`menu ${isActiveSelectLaborBond? "ativo": ""}`} >

                                            <Scrollbars className='listDropDown'  style={{  height: 100 }}>
                                                <ul>
                                                    {
                                                        [
                                                            "CLT", "Sócio", "Diretor Estatutário", "Estágio", "Aprendiz", "Trabalhador Autônomo", "Trabalhador Voluntário", "Trabalhador Temporário", "Trabalhador Rural", "Pessoa Jurídica", "Contrato Intermitente", "Contrato por Tempo Determinado", "Teletrabalho"
                                                        ].map((laborBondIntem, index) => (
                                                            <li>
                                                                <button
                                                                value={laborBondIntem}
                                                                name="laborBond"
                                                                onClick={()=>sectorlaborBond(laborBondIntem)}
                                                                >{laborBondIntem}</button>
                                                            </li>
                                                        ))}
                                                </ul>

                                            </Scrollbars>
                                        </nav>
                                    </div>
                                    {loadLaborBond ? (
                                        <p className="textError">O campo é obrigatório.</p>
                                    ) : ('')}
                                    </div>

                            </div>
                        </div>
                        <h5>Contato</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Telefone</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Telefone' 
                                        name='phone' 
                                        className={loadPhone ? 'inputErro' : ''} 
                                        onChange={mychangeHandlerCandidate}
                                        ref={focusTelfone}
                                    />
                                    { loadPhone ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Email</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Email' 
                                        name='email' 
                                        ref={focusEmail}
                                        className={loadEmail ? 'inputErro' : ''} 
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadEmail ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <h5>Endereço</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>CEP</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='CEP' 
                                        name='zipcode'
                                        ref={focusCep}
                                        className={loadZipcode ? 'inputErro' : ''} 
                                        onChange={mychangeHandlerCandidate}
                                    />
                                    { loadZipcode ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Número</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Número'
                                        name='addressNumber'
                                        value={
                                            candidate?.addressNumber !== undefined ?
                                            candidate?.addressNumber
                                            :
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />
                                   
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Rua ou avenida</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                    placeholder='Rua ou avenida' 
                                    name='address' 
                                    ref={focusRua}
                                    value={
                                        candidate?.address !== undefined ?
                                        candidate?.address
                                        :
                                        ''
                                    }
                                    onChange={mychangeHandlerCandidate}
                                    className={loadAddress ? 'inputErro' : ''}
                                    />
                                    { loadAddress ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Bairro</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Bairro' 
                                        name='addressNeighborhood' 
                                        ref={focusBairro}

                                        value={
                                            candidate?.addressNeighborhood !== undefined ?
                                            candidate?.addressNeighborhood
                                            :
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                        className={loadAddressNeighborhood ? 'inputErro' : ''} 
                                    />
                                    { loadAddressNeighborhood? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Estado</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Estado'
                                        name='addressState'
                                        onChange={mychangeHandlerCandidate}
                                        ref={focusEstado}
                                        className={loadState ? 'inputErro' : ''}
                                        value={
                                            candidate?.addressState !== undefined ?
                                                candidate?.addressState
                                            :
                                            ''
                                        }
                                    />



                                    { loadState ? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>Cidade</p>
                                </div>
                                <div className="intemB">
                                    <input
                                        type="text"
                                        placeholder='Cidade'
                                        name='addressCity'
                                        ref={focusCidade}
                                        className={loadCity ? 'inputErro' : ''}
                                        value={
                                            candidate?.addressCity !== undefined ?
                                                candidate?.addressCity
                                            :
                                            ''
                                        }
                                        onChange={mychangeHandlerCandidate}
                                    />

                                    { loadCity? <p className="textError">O campo é obrigatório.</p> : "" }
                                </div>
                            </div>
                        </div>
                        <h5>Links</h5>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Portfolio</p>
                                </div>
                                <div className="intemB">
                                    <input type="text"
                                        placeholder='Link do portfolio' 
                                        name='portfolio'
                                        onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                            <div className="bloco">
                                <div className="intemA">
                                    <p>LinkedIn</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do LinkedIn' 
                                        name='linkedin'  
                                        onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeTwo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Facebook</p>
                                </div>
                                <div className="intemB">
                                    <input type="text" 
                                        placeholder='Link do facebook' 
                                        name='facebook' 
                                        onChange={mychangeHandlerCandidate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="linha-typeOne dropzone-campo">
                            <div className='bloco'>
                                <div className="intemA">
                                    <p>Anexar currículo</p>
                                </div>
                                <div className="intemB">
                                    
                                    <Dropzone onDrop={handleDrop} >
                                        {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropzone" })}>
                                            <input type="file" name="file" {...getInputProps()}/>
                                            <label className={laodDropZone ? "imgDocLabel inputErro" : "imgDocLabel"}><span></span>Anexe um arquivo</label>
                                        </div>
                                        )}
                                    </Dropzone>
                                    {
                                            candidate?.nameFile ?
                                            <>
                                                <div className="ArquivosEnviados">
                                                    <div  className="arquivos">
                                                        <div className="nameArquivo">
                                                            <p>
                                                                {candidate?.nameFile}
                                                            </p>

                                                        </div>
                                                        <div className="btnExclui">
                                                            <button onClick={()=>removeAttachment()} >Excluir</button >
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :''
                                        }

                                </div>
                            </div>
                        </div>


                        {/* <div className="linha-typeOne adicionar-vagas">
                            <div className='bloco'>
                                <div className="intemA">

                                <h5>Aplicar para outras vagas</h5>
                                </div>
                                <div className="intemB">
                                    <SelectPersonalizado 
                                    option={ListVagas}
                                        typeSelect={"button"}
                                    
                                    />
                                </div>
                            </div>
                        </div>



                        <div className="linha-typeOne adicionar-vagas">
                            <div className='bloco'>
                                <div className="intemA">
                                </div>
                                <div className="intemB">
                                   <ul className='vagas-adicionadas'>
                                       <li>
                                           Vagas 1
                                           <button className="excluir"></button>
                                       </li>
                                       <li>
                                           Vagas 2
                                           <button className="excluir"></button>
                                       </li>
                                       <li>
                                           Vagas 3
                                           <button className="excluir"></button>
                                       </li>
                                       <li>
                                           Vagas 4
                                           <button className="excluir"></button>
                                       </li>
                                   </ul>
                                </div>
                            </div>
                        </div> */}


                    </div>
        
                    <div className="btn-form">
                        <button 
                            className={loadBtnSave ? "btn-aplicar-vaga load" : "btn-aplicar-vaga"}
                            onClick={saveCandidate}
                         >Aplicar para vaga</button>
                    </div>
        </>
    )
}