import React, { useState, useEffect } from 'react';

import Carregamento from '../../../../assets/images/carregando.gif';

import api from '../../../../services/api';
// import swal from 'sweetalert';

export default function ColProfileWorkDay ({ id  }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPage, setLoadPage ] = useState(false);



        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [ collaboratorJourneyRegistred, setCollaboratorJourneyRegistred ] = useState([]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            async function loadworkJourney() {
                setLoadPage(true)
                api.get(`/collaborator-work-journey-access`)
                .then(response => {
                    setLoadPage(false)
                    setCollaboratorJourneyRegistred(response.data);
                })
            }
            loadworkJourney();
        }, [id])

    //     // eslint-disable-next-line react-hooks/rules-of-hooks
    //    const [ load, setLoad ] = useState(false);



// console.log(collaboratorJourneyRegistred)

//console.log(collaboratorJourneyRegistred && collaboratorJourneyRegistred.workjourney ? collaboratorJourneyRegistred.workjourney._id : '')
        return (
            <>
            <div className="profile-work-day-user">
                <h1 className="titulo">Jornada de trabalho</h1>
                <div className="work-day">
                    {

                        loadPage ?
                        (
                            <>
                            <div className="carregamentoWork">
                                <img alt="" src={Carregamento} />
                            </div>
                            </>
                        ):
                        collaboratorJourneyRegistred && collaboratorJourneyRegistred.workjourney ?
                        // .map((work, index) =>
                            (
                                <>
                                    <h1>{collaboratorJourneyRegistred.workjourney.titleJourney}</h1>
                                    <p>
                                        {
                                        collaboratorJourneyRegistred.workjourney.journeyDescription.split('&')[0]
                                        }
                                        <br/>
                                        {
                                        collaboratorJourneyRegistred.workjourney.journeyDescription.split('&')[1]
                                        }
                                        <br/>
                                        {
                                        collaboratorJourneyRegistred.workjourney.journeyDescription.split('&')[2]
                                        }
                                        <br/>
                                        {
                                        collaboratorJourneyRegistred.workjourney.journeyDescription.split('&')[3]
                                        }
                                    </p>
                                </>
                            ) : ('')

                    }


                </div>
            </div>
            </>
        );
}
