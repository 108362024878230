import React, { useState } from 'react';
// import api from '../../../services/api';
// import swal from 'sweetalert';
import { NavLink } from 'react-router-dom'
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/header';
import SubMenu from '../../../components/Administrativo/ScoreAdmin/subMenu';
import { Dropdown } from "react-bootstrap";
import iconOrdenation from '../../../assets/images/ordenation.svg';
import iconVerMais from '../../../assets/images/more.svg';
import FotoCarol from '../../../assets/images/carolina_martini.jpg';
import IconUserNone from '../../../assets/images/iconUserNone.svg';
import ModalGeral from '../../../components/modal/modal-geral';
import Carregamento from '../../../assets/images/carregando.gif';
// import { mHora } from '../../services/mask';
import Dropzone from "react-dropzone";

import './styles.scss';

export default function ScoreAdmin(props) {
    ////////////////////////////////////////////////////////////////////////
    ///////////////////             MODAL EDITAR               ////////////
    //////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPontoEditHorarioAlert, setIsModalPontoEditHorarioAlert ] = useState(false);
    function abirModalPontoEditarHorarioAlert() {
        setIsModalPontoEditHorarioAlert(true);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pontoEditarHotaUpdate,  ] = useState([]);
    const myChangeHandler = event => {
        // let nam = event.target.name;
        // let val = event.target.value;

        // if (nam === "entrada") {
        //     let entrada = mHora(val)
        //     setPontoEditarHotaUpdate({ ...pontoEditarHotaUpdate, [nam]: entrada })
        // }

    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorEntrada,  ] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorEntradaInt,  ] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorSaida,  ] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorSaidaInter,  ] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnEditarH,  ] = useState(true);

    ////////////////////////////////////////////////////////////////////////
    ///////////////////          MODAL justificar              ////////////
    //////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ isModalPontoJustificaAlert, setIsModalPontoJustificaAlert ] = useState(false);
    function abirModalPontoJustificaAlert() {
        setIsModalPontoJustificaAlert(true);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorEntradaJustificativa,  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorEntradaIntJustificativa,  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorSaidaJustificativa,  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ errorSaidaInterJustificativa,  ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadBtnJustificativa,  ] = useState(true);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadEditarManterHorario, setLoadEditarManterHorario ] = useState(false);
    
    const myChangeHandlerEditManter = event => {
        let nam = event.target.name;
        let val = event.target.value;

        if (nam === "EditarManterHorario") {
            if (val === "ManterOriginal") {
                setLoadEditarManterHorario(false)
            }else {
                setLoadEditarManterHorario(true)
            } 
        } 

    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setFileNames] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pontoJustificar, setPontoJustificar ] = useState([]);
    async function handleDrop(acceptedFiles) {
        // console.log(acceptedFiles[0])
        //console.log(acceptedFiles[0].name)
        setFileNames(acceptedFiles.map(file => file.name));
        let file = acceptedFiles[0]
        setPontoJustificar({ ...pontoJustificar, file })
    }
    return (
        <>
                <ModalGeral onClose={()=> setIsModalPontoJustificaAlert(false)} isModalPontoJustificaAlert={isModalPontoJustificaAlert}>
                    <div className="modal-ponto-editarHorario modal-ponto-justicar">
                        <div className="header">
                            <h2>Justificar</h2>
                            <p>Terça, 03 Março 2022</p>
                            <div className="editar-horario">
                                <h3>Editar horário</h3>
                                <div className="form-edit-hr-justificativa">
                                    <p>Ao justificar, editar o horário é opcional</p>
                                    <div className="radios">
                                        {
                                            loadEditarManterHorario ? 
                                            <div className="radioNovo">
                                                <input type="radio" onChange={myChangeHandlerEditManter} name="EditarManterHorario" id="EditarHorario" value="EditarHorario"  checked={true}/>
                                                <label htmlFor="EditarHorario" className="foreignTexto">Editar</label>
                                            </div>
                                            :
                                            <div className="radioNovo">
                                                <input type="radio" onChange={myChangeHandlerEditManter} name="EditarManterHorario" id="EditarHorario" value="EditarHorario" />
                                                <label htmlFor="EditarHorario" className="foreignTexto">Editar</label>
                                            </div>
                                        }

                                        {
                                            loadEditarManterHorario ? 
                                            <div className="radioNovo">
                                                <input type="radio" onChange={myChangeHandlerEditManter} name="EditarManterHorario" id="ManterOriginal"   value="ManterOriginal"/>
                                                <label htmlFor="ManterOriginal" className="foreignTexto">Manter original</label>
                                            </div>
                                            :
                                            <div className="radioNovo">
                                                <input type="radio" onChange={myChangeHandlerEditManter} name="EditarManterHorario" id="ManterOriginal"  checked={true}  value="ManterOriginal"/>
                                                <label htmlFor="ManterOriginal" className="foreignTexto">Manter original</label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="horario-original">
                            <div className="titulo">
                                <h3>HORÁRIO ORIGINAL</h3>
                            </div>
                            <div className="infor">
                                <p>Entrada<span>8:02</span></p>
                                <p>Saída Int.<span>12:07</span></p>
                                <p>Entrada Int.<span>13:02</span></p>
                                <p>Saída<span>18:02</span></p>
                            </div>
                        </div>
                        {
                            loadEditarManterHorario ? 

                            <div className="infor-form">
                                <p>
                                    Você pode editar apenas o horário inconsistente ou todos os campos, se
                                    necessitar. Os campos editados ficarão marcados na folha.
                                </p>
                            </div>
                            :
                            ""
                        }

                        {
                        loadEditarManterHorario ? 
                        <div className="form">
                            <div className="titulo">
                                <h3>NOVO</h3>
                            </div>
                            <div className="bloco-form">
                                <div className="input-text">
                                    <p>Entrada</p>
                                    <div>
                                        <input type="text" name="entrada" 
                                        onChange={myChangeHandler} maxlength="5" 
                                        value={pontoEditarHotaUpdate.entrada ? pontoEditarHotaUpdate.entrada : ''}   
                                        placeholder="00:00" className={errorEntradaJustificativa ? 'inputErro' : ''} />
                                        {
                                            errorEntradaJustificativa ?
                                                <p className="textError">O campo é obrigatório.</p>
                                            : ('')
                                        }
                                    </div>
                                </div>
                                <div className="input-text">
                                    <p>Saída Int.</p>
                                    <div>
                                        <input type="text" name="saidaInt" 
                                        onChange={myChangeHandler} maxlength="5" 
                                        value={pontoEditarHotaUpdate.saidaInt ? pontoEditarHotaUpdate.saidaInt : ''}   
                                        placeholder="00:00" className={errorSaidaInterJustificativa ? 'inputErro' : ''}  />
                                         {
                                             errorSaidaInterJustificativa ?
                                                 <p className="textError">O campo é obrigatório.</p>
                                             : ('')
                                         }
                                    </div>
                                </div>
                                <div className="input-text">
                                    <p>Entrada Int.</p>
                                    <div>
                                        <input type="text" name="entradaInt"
                                        onChange={myChangeHandler} maxlength="5"
                                        value={pontoEditarHotaUpdate.entradaInt ? pontoEditarHotaUpdate.entradaInt : ''} 
                                        placeholder="00:00" className={errorEntradaIntJustificativa ? 'inputErro' : ''}   />
                                        {
                                            errorEntradaIntJustificativa ?
                                                <p className="textError">O campo é obrigatório.</p>
                                            : ('')
                                        }
                                    </div>
                                </div>
                                <div className="input-text">
                                    <p>Saída</p>
                                    <div>
                                        <input type="text" name="saida"
                                        onChange={myChangeHandler} maxlength="5"
                                        value={pontoEditarHotaUpdate.saida ? pontoEditarHotaUpdate.saida : ''} 
                                        placeholder="00:00" className={errorSaidaJustificativa ? 'inputErro' : ''} />
                                        {
                                            errorSaidaJustificativa ?
                                                <p className="textError">O campo é obrigatório.</p>
                                            : ('')
                                         }
                                    </div>
                                </div>
                            </div>
                        </div>

                        :
                        ""
                        }
                        <div className="justificativa-form">
                            <h4 className="titulo">Adicionar nova justificativa</h4>
                            <textarea name="justificativa" cols="150" rows="10" placeholder="Escreva um comentário…"></textarea>
                            <div className="anexar-arquivos">
                                <Dropzone  className="" onDrop={handleDrop} >
                                    {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input type="file" name="file" {...getInputProps()} />
                                        <label className="imgDocLabel"><span></span>Anexe um arquivo</label>
                                    </div>
                                    )}
                                </Dropzone>
                                <button className="btnGreen">Enviar</button>
                            </div>
                            <div className="list-arquivos-anexado">
                                <div className="arquivos-anexado">
                                    <div>
                                        <p className="nome">novo-atestado-medico.png</p>
                                        <span className="data">Enviado em 06/03/2022</span>
                                    </div>
                                    <button className="exclui">Excluir anexo</button>
                                </div>

                                <div className="arquivos-anexado">
                                    <div>
                                        <p className="nome">novo-atestado-medico.png</p>
                                        <span className="data">Enviado em 06/03/2022</span>
                                    </div>
                                    <button className="exclui">Excluir anexo</button>
                                </div>

                            </div>
                        </div>

                        <div className='justificativa-enviada'>
                                <h5 className='titulo'>Enviadas</h5>
                                <div className='justificativa'>
                                    <div>
                                        <h6 className="nome">Fui ao médico a partir das 15h</h6>
                                        <p className="aquivo">atestado.jpg</p>
                                        <span className="data">Enviado em 04/03/2022</span>
                                    </div>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button>Editar</button>
                                            <button className='red'>Excluir</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='justificativa'>
                                    <div>
                                        <h6 className="nome">Fui ao médico a partir das 15h</h6>
                                        <p className="aquivo">atestado.jpg</p>
                                        <span className="data">Enviado em 04/03/2022</span>
                                    </div>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <button>Editar</button>
                                            <button className='red'>Excluir</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        <div className="btn-salva">
                            <form  className="btn-salvar">
                                <button type="submit" className={loadBtnJustificativa  ? 'btnGreen load' : 'btnGreen'}>Salvar</button>
                            </form>
                            <button className="cancelar" onClick={()=> setIsModalPontoJustificaAlert(false)}>Cancelar</button>
                        </div>
                    </div>
                </ModalGeral>





            <Layout
            path={props.match.path}>
                <Header/>
                <div className="page-ponto">
                    <SubMenu props={props}/>

                    <div className="page-alertas">
                        <h2 className="titulo">Alertas</h2>
                        <div className="tabela-header">
                            <div className="inten-1 chekboxNovo">
                                <input type="checkbox" value="all" id="checkbox"/>
                                <label htmlFor="checkbox"></label>
                            </div>
                            <div className="btn-selecioanados">
                                <p>Resolver</p>
                                <button>Resolver selecionados</button>
                            </div>
                            <div>
                                <p>Tipo de alerta</p>
                            </div>
                            <div>
                                <p>Quando ocorreu</p>
                            </div>
                            <div>
                                <p>Colaborador</p>
                            </div>
                            <div>
                                <p>Descrição</p>
                            </div>
                            <div>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconOrdenation} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button type="button" name="name" value="" >
                                            Atenção primeiro
                                        </button>
                                        <button type="button" name="name" value="" >
                                            Grave primeiro
                                        </button>
                                        <button type="button" name="name" value="" >
                                            Ordem alfabética A
                                        </button>
                                        <button type="button" name="name" value="" >
                                            Ordem alfabética Z
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="tabela-corpo">

                            <div className="carregamento">
                                <img alt="" src={Carregamento} />
                            </div>
                            <div className="tr">
                                <div className="inten-1 chekboxNovo">
                                    <input type="checkbox" value="all" id="checkbox"/>
                                    <label htmlFor="checkbox"></label>
                                </div>
                                <div className="btn-selecioanados" >
                                    <button>Marcar como resolvido</button>
                                </div>
                                <div>
                                    <p className="atencao">Atenção</p>
                                    {/* <p className="grave">Grave</p> */}
                                </div>
                                <div>
                                    <p>8 Mar 2022 às 14:07</p>
                                </div>
                                <div>
                                    <div className="colaborador">
                                        <img alt="" src={FotoCarol} className="foto-colaborador"/>
                                        {/* <div className="semImgCol">
                                            <img src={IconUserNone} alt="Usuario sem imagem" />
                                        </div> */}
                                        <div className="nome-cargo">
                                            <strong className="nome">Carolina Martini Guerini</strong>
                                            <p className="cargo">Analista de RH - Pleno</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="descricao">
                                    <p>Saída após o horário esperado</p>
                                    {/* <p>Horas extras acima do limite mensal permitido</p> */}
                                </div>
                                <div>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <NavLink to={`/folha-de-ponto/:id`} >Visualizar folha</NavLink>
                                            <button onClick={abirModalPontoEditarHorarioAlert}>Editar horário</button>
                                            <button onClick={abirModalPontoJustificaAlert}>Justificar</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="tr">
                                <div className="inten-1 chekboxNovo">
                                    <input type="checkbox" value="all" id="checkbox"/>
                                    <label htmlFor="checkbox"></label>
                                </div>
                                <div className="btn-selecioanados" >
                                    <button>Marcar como resolvido</button>
                                </div>
                                <div>
                                    {/* <p className="atencao">Atenção</p> */}
                                    <p className="grave">Grave</p>
                                </div>
                                <div>
                                    <p>8 Mar 2022 às 14:07</p>
                                </div>
                                <div>
                                    <div className="colaborador">
                                        {/* <img alt="" src={FotoCarol} className="foto-colaborador"/> */}
                                        <div className="semImgCol">
                                            <img src={IconUserNone} alt="Usuario sem imagem" />
                                        </div>
                                        <div className="nome-cargo">
                                            <strong className="nome">Carolina Martini Guerini</strong>
                                            <p className="cargo">Analista de RH - Pleno</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="descricao">
                                    {/* <p>Saída após o horário esperado</p> */}
                                    <p>Horas extras acima do limite mensal permitido</p>
                                </div>
                                <div>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <NavLink to={`/folha-de-ponto/:id`} >Visualizar folha</NavLink>
                                            <button onClick={abirModalPontoEditarHorarioAlert}>Editar horário</button>
                                            <button onClick={abirModalPontoJustificaAlert}>Justificar</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                        </div>

                    </div>
                    
                </div>
            </Layout>



            <ModalGeral onClose={()=> setIsModalPontoEditHorarioAlert(false)} isModalPontoEditHorarioAlert={isModalPontoEditHorarioAlert}>
                    <div className="modal-ponto-editarHorario">
                        <div className="header">
                            <h2>Editar horário</h2>
                            <p>Terça, 03 Março 2022</p>
                        </div>
                        <div className="horario-original">
                            <div className="titulo">
                                <h3>HORÁRIO ORIGINAL</h3>
                            </div>
                            <div className="infor">
                                <p>Entrada<span>8:02</span></p>
                                <p>Saída Int.<span>12:07</span></p>
                                <p>Entrada Int.<span>13:02</span></p>
                                <p>Saída<span>18:02</span></p>
                            </div>
                        </div>
                        <div className="infor-form">
                            <p>
                                Você pode editar apenas o horário inconsistente ou todos os campos, se
                                necessitar. Os campos editados ficarão marcados na folha.
                            </p>
                        </div>
                        <div className="form">
                            <div className="titulo">
                                <h3>NOVO</h3>
                            </div>
                            <div className="bloco-form">
                                <div className="input-text">
                                    <p>Entrada</p>
                                    <div>
                                        <input type="text" name="entrada" 
                                        onChange={myChangeHandler} maxlength="5" 
                                        value={pontoEditarHotaUpdate.entrada ? pontoEditarHotaUpdate.entrada : ''}   
                                        placeholder="00:00" className={errorEntrada ? 'inputErro' : ''} />
                                        {
                                            errorEntrada ?
                                                <p className="textError">O campo é obrigatório.</p>
                                            : ('')
                                        }
                                    </div>
                                </div>
                                <div className="input-text">
                                    <p>Saída Int.</p>
                                    <div>
                                        <input type="text" name="saidaInt" 
                                        onChange={myChangeHandler} maxlength="5" 
                                        value={pontoEditarHotaUpdate.saidaInt ? pontoEditarHotaUpdate.saidaInt : ''}   
                                        placeholder="00:00" className={errorSaidaInter ? 'inputErro' : ''}  />
                                         {
                                             errorSaidaInter ?
                                                 <p className="textError">O campo é obrigatório.</p>
                                             : ('')
                                         }
                                    </div>
                                </div>
                                <div className="input-text">
                                    <p>Entrada Int.</p>
                                    <div>
                                        <input type="text" name="entradaInt"
                                        onChange={myChangeHandler} maxlength="5"
                                        value={pontoEditarHotaUpdate.entradaInt ? pontoEditarHotaUpdate.entradaInt : ''} 
                                        placeholder="00:00" className={errorEntradaInt ? 'inputErro' : ''}   />
                                        {
                                            errorEntradaInt ?
                                                <p className="textError">O campo é obrigatório.</p>
                                            : ('')
                                        }
                                    </div>
                                </div>
                                <div className="input-text">
                                    <p>Saída</p>
                                    <div>
                                        <input type="text" name="saida"
                                        onChange={myChangeHandler} maxlength="5"
                                        value={pontoEditarHotaUpdate.saida ? pontoEditarHotaUpdate.saida : ''} 
                                        placeholder="00:00" className={errorSaida ? 'inputErro' : ''} />
                                        {
                                            errorSaida ?
                                                <p className="textError">O campo é obrigatório.</p>
                                            : ('')
                                         }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="btn-salva">
                            <form  className="btn-salvar">
                                <button type="submit" className={loadBtnEditarH  ? 'btnGreen load' : 'btnGreen'}>Editar horário</button>
                            </form>
                            <button className="cancelar" onClick={()=> setIsModalPontoEditHorarioAlert(false)}>Cancelar</button>
                        </div>
                    </div>
                </ModalGeral>




        </>
    );
}

