import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';

const SubHeaderColProfile = ({ id, path, role }) => {
    // console.log(path)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusPessoal  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusProficional  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusHistorico  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusAnotacoes  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusNone  = useRef(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if(path === '/colaboradores/:id/anotacoes' ) {
            focusAnotacoes.current.focus();
        } else
        if(
            path === '/colaboradores/:id/pessoal' ||
            path === '/colaboradores/:id/pessoal/enderecos-contatos' ||
            path === '/colaboradores/:id/pessoal/documentos' ||
            path === '/colaboradores/:id/pessoal/faltas-e-afastamentos'
        ){
            focusPessoal.current.focus();
        } else
        if(
            path === '/colaboradores/:id/profissional' ||
            path === '/colaboradores/:id/profissional/beneficios-do-colaborador' ||
            path === '/colaboradores/:id/profissional/ferias' ||
            path === '/colaboradores/:id/profissional/documentos'  ||
            path === '/colaboradores/:id/profissional/folha-de-pagamento' ||
            path === '/colaboradores/:id/profissional/jornada-de-trabalho'
        ) {
            focusProficional.current.focus();
        }else
        if(
            path === '/colaboradores/:id/historico-ultimas-alteracoes' ||
            path === '/colaboradores/:id/historico-salario-e-promocoes'
        ) {
            focusHistorico.current.focus();

        }

    }, [path]);

        return (
            <>
                <ul className="menu-perfil">
                    <Scrollbars>
                    <div className='scroll-padding'>

                    <li>
                    <NavLink to={`/colaboradores/${id}/pessoal`} className={
                        path === '/colaboradores/:id/pessoal' ||
                        path === '/colaboradores/:id/pessoal/enderecos-contatos' ||
                        path === '/colaboradores/:id/pessoal/documentos' ||
                        path === '/colaboradores/:id/pessoal/faltas-e-afastamentos' ?
                        "active" :
                        ''
                        }
                        ref={
                                focusPessoal
                        }

                        ><span>Pessoal</span></NavLink>


                    </li>
                    <li>
                        <NavLink to={`/colaboradores/${id}/profissional`} exact className={
                        path === '/colaboradores/:id/profissional' ||
                        path === '/colaboradores/:id/profissional/beneficios-do-colaborador' ||
                        path === '/colaboradores/:id/profissional/ferias' ||
                        path === '/colaboradores/:id/profissional/documentos'  ||
                        path === '/colaboradores/:id/profissional/folha-de-pagamento' ||
                        path === '/colaboradores/:id/profissional/jornada-de-trabalho'  ?
                        "active" :
                        ''
                        }

                        ref={
                            focusProficional
                        }



                        ><span>Profissional</span></NavLink>
                    </li>
                    <li>
                        <NavLink to={`/colaboradores/${id}/dados-de-acesso`} exact activeClassName="active" ><span>Dados de Acesso</span></NavLink>
                    </li>
                    <li>
                        <NavLink to={
                            (role && role === 'admin') || (role && role === 'master') ?
                            `/colaboradores/${id}/historico-ultimas-alteracoes`
                            :
                            `/colaboradores/${id}/historico-salario-e-promocoes`
                        } exact className={
                        path === '/colaboradores/:id/historico-ultimas-alteracoes' ||
                        path === '/colaboradores/:id/historico-salario-e-promocoes'   ?
                        "active" :
                        ''}


                       ><span>Históricos</span></NavLink>
                    </li>
                    {
                        (role && role === 'admin') || (role && role === 'master') ?
                        <li>
                            <NavLink to={`/colaboradores/${id}/anotacoes`} exact activeClassName="active" ><span>Anotações</span></NavLink>
                        </li>
                        :
                        ''
                    }
                    <button ref={
                        path === '/colaboradores/:id/historico-ultimas-alteracoes' ||
                        path === '/colaboradores/:id/historico-salario-e-promocoes' ?
                        focusHistorico :
                        path === '/colaboradores/:id/anotacoes' ?
                            focusAnotacoes
                            :
                            focusNone
                    }></button>
                    

                    </div>

                    
                    </Scrollbars>

                </ul>
            </>
        );
}
export default SubHeaderColProfile;
