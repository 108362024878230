import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import JobInformation from './work/JobInformation';
import BondSalary from './work/BondSalary';
import InternInformation from './work/InternInformation';
import ExperiencePeriod from './work/ExperiencePeriod';
import api from '../../../services/api';
import { toast } from 'react-toastify';



export default function ColProfileProssional({ id, path, collaborator }) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ registeredBondAndSalary, setRegisteredBondAndSalary ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadBondAndSalary();
    }, []);

    async function loadBondAndSalary() {
        await api.get(`/collaborator-bond-and-salary-access/`)
        .then(response => {
            if(response.data[0] !== undefined) {
                setRegisteredBondAndSalary(response.data[0]);
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    return (
        <>
            <SubMenu id={id} collaborator={collaborator}/>
            <div className="box-infor-perfil profile-infor-prof">
                <JobInformation
                    id={id}
                    collaborator={collaborator}
                    collaboratorState={collaborator}
                    path={path}
                />
                <BondSalary
                    id={id}
                    collaborator={collaborator}
                    collaboratorState={collaborator}
                    path={path}
                />
                {registeredBondAndSalary.bond !== "Estágio" ? "" : <>
                    <InternInformation
                    id={id}
                    collaborator={collaborator}
                    path={path}
                    />
                </>}
                    <ExperiencePeriod collaborator={collaborator}
                    path={path}
                    />
                {/* <Workday id={id} collaborator={collaborator}/> */}
            </div>
        </>
    );
}

