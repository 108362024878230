import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
// import IconsInformation from '../../IconsInformation';
import HeaderAdmin from '../../HeaderAdmin';
import api from '../../../services/api';

export default function headerColaboradores({
    focusTop,
    user,
}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadcountskill();
        // loadcountcollaborators();

    }, []);

    // async function loadcountcollaborators() {

    //     await api.get('/number-of-collaborators')
    //     .then(response => {

    //         localStorage.setItem('@peopleview/countCollaborators', response.data);

    //     }).catch(error => {})

    // }

    async function loadcountskill() {
        await api.get('/number-of-skills')
        .then(response => {
            if(response.data.length !== 0 ) {
                localStorage.setItem('@peopleview/countSkills', response.data);
            } else {
                localStorage.setItem('@peopleview/countSkills', 0);
            }
        }).catch(error => {
        })
    }

    const countCollaborators = localStorage.getItem('@peopleview/countCollaborators');
    const countSkills        = localStorage.getItem('@peopleview/countSkills');



    return (
        <>
        <HeaderAdmin>
            <div className='ativos'>
                <h1>Colaboradores</h1>
                <p className="inforColaboraderes">No momento existem {countCollaborators !== null ? countCollaborators : '0'} colaboradores ativos com {countSkills !== null ? countSkills : '0'} habilidades
                {
                    (user && user.role === 'admin') || (user && user.role === 'master') ?
                    <NavLink  ref={focusTop}  to="/habilidades" >Ir para habilidades</NavLink>
                    :
                    <NavLink  ref={focusTop}  to="/colaboradores/ativos" ></NavLink>
                }
                </p>
            </div>
        </HeaderAdmin>
            {/* <header className="header-geral headerColaboradores" >
                <div className="cabeçalho">
                    <h1>Colaboradores</h1>
                    <p className="inforColaboraderes">No momento existem {countCollaborators !== null ? countCollaborators : '0'} colaboradores ativos com {countSkills !== null ? countSkills : '0'} habilidades
                    {
                        user && user.role === 'admin' ?
                        <NavLink  ref={focusTop}  to="/habilidades" >Ir para habilidades</NavLink>
                        :
                        <NavLink  ref={focusTop}  to="/colaboradores/ativos" ></NavLink>
                    }
                    </p>
                </div>
                <div className="infor">
                    <IconsInformation
                    history={history}
                    />
                </div>

            </header> */}
        </>
    );
}
