import React, { useState, useEffect } from 'react';
import './styles.scss';
import Layout from '../../../../components/Layouts/default';
import Header from '../../../../components/Administrativo/Colaboradores/profile/header';
import SubHeader from '../../../../components/Administrativo/Colaboradores/profile/SubHeader';
import Vacation from '../../../../components/Administrativo/Colaboradores/profile/Professional/vacation';
import InforProfile from '../../../../components/Administrativo/Colaboradores/profile/InforProfile';
import swal from 'sweetalert';
import api from '../../../../services/api';
import { decodeToken } from '../../../../services/auth';
import { toast } from 'react-toastify';


export default function ColProfileVacation(props) {

    const { id }   = props.match.params;
    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        if(id && id !== undefined) {

            loadcollaborator(id);

        }


    }, [id]);

    async function loadcollaborator(id) {

        await api.get(`/collaborator/${id}`)
        .then(response => {

            setCollaborator(response.data);

        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatarAdminProfile(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {

                return
            }
            formData.append('file', acceptedFiles[0]);
            //formData.append('collaborator', id);
            setLoadAvatar(true)
            await api.put(`/collaborator/${id}`, formData, config)
            .then(response => {

                setLoadAvatar(false)
                loadcollaborator(id);
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }

    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                id={id}
                collaborator={collaborator}
                />
                <InforProfile
                path={props.match.path}
                collaborator={collaborator}
                id={id}
                loadAvatar={loadAvatar}
                handleDropAvatarAdminProfile={handleDropAvatarAdminProfile}
                loadcollaborator={loadcollaborator}
                role={role}
                />
                <SubHeader
                id={id}
                path={props.match.path}
                collaborator={collaborator}
                role={role}
                />
                <Vacation
                id={id}
                path={props.match.path}
                collaborator={collaborator}
                role={role}
                />
            </Layout>
        </>
    );
}

