import React, { useState } from 'react';
//import { Row, Col } from 'react-bootstrap';
import Layout from '../../components/Layouts/defaultUser';
// import HeaderRedefinePassword from '../../components/HeaderUser/redefine-password';
import { NavLink } from 'react-router-dom'

import swal from 'sweetalert';

// import { decodeToken } from '../../services/auth';
import api from '../../services/api';
import './styles.scss';

const UserMyBenefits = (props) => {
    const { path } = props.match;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPasswordCurrent, setLoadPasswordCurrent] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNewPassword, setLoadNewPassword] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadConfirmNewPassword, setLoadConfirmNewPassword] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadButtonUpdatePassword, setLoadButtonUpdatePassword] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ changePassword, setChangePassword] = useState([]);
    async function myChangeHandlerChangePassword(event) {
        //event.preventDefault();
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'password') {

            if(val.length === 0) {

                setLoadPasswordCurrent(true);
                setChangePassword({ ...changePassword, [nam] : val });

            }
            else {

                setLoadPasswordCurrent(false);
                setChangePassword({ ...changePassword, [nam] : val });

            }

        }
        else
        if(nam === 'newPassword') {

            if(val.length === 0) {

                setLoadNewPassword(true);
                setChangePassword({ ...changePassword, [nam] : val });

            }
            else {

                setLoadNewPassword(false);
                setChangePassword({ ...changePassword, [nam] : val });

            }

        }
        else
        if(nam === 'confirnNewPassword') {

            if(val.length === 0) {

                setLoadConfirmNewPassword(true);
                setChangePassword({ ...changePassword, [nam] : val });

            }
            else {

                setLoadConfirmNewPassword(false);
                setChangePassword({ ...changePassword, [nam] : val });

            }

        }
        else {

        }

    }

    async function updateChangePassword() {

        if(changePassword && changePassword.length !== 0) {

            setLoadButtonUpdatePassword(true)
            await api.put('/collaborator-change-password/', changePassword)
            .then(response => {

                setLoadButtonUpdatePassword(false)
                swal({ icon: "success", title: "Sucesso!", text: "Senha alterada com sucesso." });
                setChangePassword([]);

            }).catch(error => {
                setLoadButtonUpdatePassword(false)
                swal({
                    icon: "error",
                    title: "Erro!",
                    text: error?.response?.data?.message  ?
                    error?.response?.data?.message
                    :
                    'Não foi possível realziar a alteração da senha!'
                });
            });

        }

    }
        return (
            <>
                <Layout  path={path}>
                    {/* <HeaderRedefinePassword/> */}
                        <div className="user-redefine-password">
                            <ul className="menu-perfil">
                                <li>
                                <NavLink to={`/configuracao/alterar-senha`} className="active"><span>Pessoal</span></NavLink>
                                </li>
                            </ul>
                            <div className="box-redefine-password">
                                    <h3 className="titulo">Alterar senha</h3>
                                    <h4 className="sub-titulo">Digite sua senha atual</h4>
                                    <ul>
                                        <li>
                                            <div className="texto"><p>Senha atual</p></div>
                                            <div>
                                                <input type="password" name="password"  placeholder="Senha atual" value={changePassword && changePassword.password ? changePassword.password : ""} onChange={myChangeHandlerChangePassword} className={loadPasswordCurrent ? 'inputErro' : ''}/>
                                                {loadPasswordCurrent ? (
                                                    <p className="textError">O campo é obrigatório.</p>
                                                ) : ('')}
                                            </div>
                                        </li>
                                    </ul>
                                    <h4 className="sub-titulo">Nova senha</h4>
                                    <ul>
                                        <li>
                                            <div className="texto"><p>Nova senha</p></div>
                                            <div>
                                                <input type="password" name="newPassword"  placeholder="Nova senha" value={changePassword && changePassword.newPassword ? changePassword.newPassword : ""} onChange={myChangeHandlerChangePassword}  className={loadNewPassword ? 'inputErro' : ''}/>
                                                {loadNewPassword ? (
                                                    <p className="textError">O campo é obrigatório.</p>
                                                ) : ('')}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="texto"><p>Confirme a nova senha</p></div>
                                            <div>
                                                <input type="password" name="confirnNewPassword"  placeholder="Confirme a nova senha" value={changePassword && changePassword.confirnNewPassword ? changePassword.confirnNewPassword : ""}  onChange={myChangeHandlerChangePassword} className={loadConfirmNewPassword ? 'inputErro' : ''}/>
                                                {loadConfirmNewPassword ? (
                                                    <p className="textError">O campo é obrigatório.</p>
                                                ) : ('')}
                                            </div>
                                        </li>
                                    </ul>
                                    <button  disabled={
                                            changePassword                               &&
                                            changePassword.newPassword                   &&
                                            changePassword.confirnNewPassword            &&
                                            changePassword.newPassword.length > 7        &&
                                            changePassword.confirnNewPassword.length > 7 &&
                                            changePassword.newPassword                  ===
                                            changePassword.confirnNewPassword             ?
                                            false
                                            :
                                            true
                                        }
                                        onClick={updateChangePassword}
                                        className={loadButtonUpdatePassword ? 'btnGreen load btn' : 'btnGreen btn'}>Salvar senha</button>
                            </div>
                        </div>
                </Layout>
            </>
        );
    }
export default UserMyBenefits;
