import React, { useState, useEffect } from 'react';
import SubMenu from './subMenu';
import PessonalData from './PessonalData/pessonalData';
import BankData from './PessonalData/bankData';
import AcademicEducation from './PessonalData/academicEducation';
import Cuorses from './PessonalData/Courses'
import Deficiencia from './PessonalData/deficiencia';
import Dependents from './PessonalData/Dependents';
import api from '../../../services/api';

export default function ColProfilePersonal ({
    id,
    path,
    loadPage,
    MakePersonalDataVisible,
    visiblePersonalData,
    setCollaborator,
    EditBankDataVisibleBank,
    visibleBank,
    collaborator,
    collaboratorUpdate,
    onCollaboratorInputChange,
    ufEstados,
    ufDistrito ,
    ufCity,
    OnSubmit,
    loadContinue,
    filesSavedPersonalData,
    loadPersonalData,
    deleteFilePersonaldata,
    mySearchBank,
    abrirResultBank,
    abrirResultado,
    myChangeHandlerToSaveBank,
    openAccountType,
    selectAccountTypeOpen,
    openSelAccountType,
    myChangeHandlerAccountType,
    handleDropPersonalData,
    selectEthnicity,
    selectNationality,
    selectGender,
    selectMaritalStatus,
    selectBornState,
    selectBornCity,
    setSelectEthnicity,
    setSelectNationality,
    setSelectGender,
    setSelectMaritalStatus,
    setSelectBornState,
    setSelectBornCity,
    setCollaboratorUpdate
}) {

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ ufEscolhido, setUEscolhido ] = useState('')

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loaduf() {
            await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufDistrito}`)
            .then(response => {
                setUEscolhido(response.data);
            }).catch(error => {});
        }
        loaduf();
    }, [ufDistrito]);

    return (
        <>
        <SubMenu
            id={id}
            />
            <div className="boxs-profile-user box-perssonalData">
                <PessonalData
                    id={id}
                    loadPage={loadPage}
                    path={path}
                    MakePersonalDataVisible={MakePersonalDataVisible}
                    visiblePersonalData={visiblePersonalData}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    ufEstados={ufEstados}
                    ufEscolhido={ufEscolhido}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    filesSavedPersonalData={filesSavedPersonalData}
                    loadPersonalData={loadPersonalData}
                    deleteFilePersonaldata={deleteFilePersonaldata}
                    handleDropPersonalData={handleDropPersonalData}
                    setCollaborator={setCollaborator}
                    setCollaboratorUpdate={setCollaboratorUpdate}
                    selectEthnicity={selectEthnicity}
                    selectNationality={selectNationality}
                    selectGender={selectGender}
                    selectMaritalStatus={selectMaritalStatus}
                    selectBornState={selectBornState}
                    selectBornCity={selectBornCity}
                    setSelectEthnicity={setSelectEthnicity}
                    setSelectNationality={setSelectNationality}
                    setSelectGender={setSelectGender}
                    setSelectMaritalStatus={setSelectMaritalStatus}
                    setSelectBornState={setSelectBornState}
                    setSelectBornCity={setSelectBornCity}
                />
                <BankData
                    id={id}
                    loadPage={loadPage}
                    collaborator={collaborator}
                    collaboratorUpdate={collaboratorUpdate}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    EditBankDataVisibleBank={EditBankDataVisibleBank}
                    visibleBank={visibleBank}
                    ufEstados={ufEstados}
                    ufEscolhido={ufEscolhido}
                    ufDistrito={ufDistrito}
                    ufEstadoEscolhido={collaborator.bornState}
                    ufCity={collaborator.bornCity}
                    OnSubmit={OnSubmit}
                    loadContinue={loadContinue}
                    mySearchBank={mySearchBank}
                    abrirResultBank={abrirResultBank}
                    abrirResultado={abrirResultado}
                    myChangeHandlerToSaveBank={myChangeHandlerToSaveBank}
                    openAccountType={openAccountType}
                    selectAccountTypeOpen={selectAccountTypeOpen}
                    openSelAccountType={openSelAccountType}
                    myChangeHandlerAccountType={myChangeHandlerAccountType}
                />
                <Deficiencia
                    id={id}
                    collaboratorState={collaborator}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    loadContinue={loadContinue}
                    path={path}
                />
                <AcademicEducation
                    id={id}
                    collaboratorState={collaborator}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    loadContinue={loadContinue}
                    path={path}
                />
                <Cuorses id={id} />
                <Dependents
                    id={id}
                    collaboratorState={collaborator}
                    onCollaboratorInputChange={onCollaboratorInputChange}
                    loadContinue={loadContinue}
                    path={path}
                />

            </div>

        </>
    );
}
