import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import Dropzone from "react-dropzone";
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../assets/images/more.svg';
import iconEyeSee from '../../assets/images/iconEyeSee.svg';
import IconCamera from '../../assets/images/icone-camera.svg';
import CarregandoFoto from '../../assets/images/carregando.gif';
import api from '../../services/api';

const inforProfile = ({ id, collaborator, setCollaborator, path  }) => {

    if(collaborator && collaborator.avatar !== undefined && collaborator.avatar !== 'undefined') {
        localStorage.setItem('@peopleview/avatar', collaborator.avatar);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
   useEffect(() => {
        loadcollaborator(id);
    }, [id]);

    async function loadcollaborator(id) {
        await api.get(`/collaborator-access/${id}`)
        .then(response => {

            if(response.data.name !== undefined) {
                localStorage.setItem('@peopleview/nameProfile', response.data.name);
            } else {
                localStorage.setItem('@peopleview/nameProfile', '');
            }

            if(response.data.occupation !== undefined) {
                localStorage.setItem('@peopleview/collaboratorOccupation', response.data.occupation);
            } else {
                localStorage.setItem('@peopleview/collaboratorOccupation', '');
            }

            if(response.data.salary !== undefined) {
                localStorage.setItem('@peopleview/collaboratorSalary', response.data.salary);
            } else {
                localStorage.setItem('@peopleview/collaboratorSalary', '');
            }

            if(response.data.sector !== undefined) {
                localStorage.setItem('@peopleview/collaboratorSector', response.data.sector);
            } else {
                localStorage.setItem('@peopleview/collaboratorSector', '');
            }

            if(response.data.admissionDate !== undefined) {
                localStorage.setItem('@peopleview/collaboratorAdmissionDate', response.data.admissionDate);
            } else {
                localStorage.setItem('@peopleview/collaboratorAdmissionDate','');
            }

            if(response.data.nameManager !== undefined) {
                localStorage.setItem('@peopleview/nameManager', response.data.nameManager);
            } else {
                localStorage.setItem('@peopleview/nameManager', '');
            }
        }).catch(error => {})
    }



    var   avatar                    = localStorage.getItem('@peopleview/avatar');
    const nameprofile               = localStorage.getItem('@peopleview/nameProfile');
    const collaboratoroccupation    = localStorage.getItem('@peopleview/collaboratorOccupation');
    const collaboratorsalary        = localStorage.getItem('@peopleview/collaboratorSalary');
    const collaboratorsector        = localStorage.getItem('@peopleview/collaboratorSector');
    const collaboratoradmissionDate = localStorage.getItem('@peopleview/collaboratorAdmissionDate');
    const namemanager               = localStorage.getItem('@peopleview/nameManager');


      ////////////////////////////////////////////////////////////////////////////////////////////////
     /////// AVATAR /////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadAvatar , setLoadAvatar ] = useState(false);

    async function handleDropAvatar(acceptedFiles) {

        let file = acceptedFiles[0]
        const typeI = file.type ;

        if ((typeI === 'image/jpg') ||
            (typeI === 'image/jpeg') ||
            (typeI === 'image/pjpeg') ||
            (typeI === 'image/png')  ||
            (typeI === "image/webp")
        ) {

            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }
            if (acceptedFiles === undefined) {
                return
            }
            formData.append('file', acceptedFiles[0]);
            formData.append('collaborator', id);
            formData.append('path', path);

            setLoadAvatar(true)
            await api.put(`/collaborator-access/${id}`, formData, config)
            .then(response => {
                setLoadAvatar(false)
                async function loadcadastrocolaborador() {
                    await api.get(`/collaborator-access/${id}`)
                    .then(response => {
                        setCollaborator(response.data);
                    })
                    .catch(error => {
                        swal({ icon: "error", title: "Erro!", text: error.response.data.message  });
                    });
                }
                loadcadastrocolaborador();
                swal({ icon: "success", title: "Sucesso!", text: "Foto do colaborador alterado com sucesso!" });
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: "Não foi possível fazer as alterações!" });
            });
        } else {
            swal({ icon: "error", title: "Erro!", text: "Arquivo não suportado!" });
        }


    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [verSalario, setVerSalario] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [esconderSalario, setEsconderVerSalario] = useState(true);

    function VerSalario() {
        setVerSalario(false);
        setEsconderVerSalario(true);
    }
    function EsconderSalario() {
        setVerSalario(true);
        setEsconderVerSalario(false);
    }

        return (
            <>
                <section className="infor-perfil user-infor-perfil">
                    {
                    loadAvatar ?
                    (
                        <img src={CarregandoFoto} alt="carregando" className="carregamento-foto" />
                    ) :
                    avatar && avatar ? (

                        <Dropzone onDrop={handleDropAvatar} >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "upload-foto2" })}>
                                            <label className="label-foto-adicionado" ><img alt="+" src={IconCamera} className="icon"/><img alt={ collaborator.name }  src={ avatar }   className="foto"/></label>
                                            <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                            </div>
                            )}
                        </Dropzone>

                    ) : (
                        <Dropzone onDrop={handleDropAvatar} >
                            {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "upload-foto2" })}>
                                            <label className="labelIconPhoto"><div  className="iconPhoto"> <span></span> Enviar foto</div> </label>
                                <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                            </div>
                            )}
                        </Dropzone>
                    )}
                    <div className="infor">
                        <h2 className="none">{nameprofile}</h2>
                        <p className="cargo">{collaboratoroccupation && collaboratoroccupation !== undefined ? collaboratoroccupation : '-'}</p>
                        <div className="bloco-section">
                            <div className="bloco">
                                <h4>Departamento</h4>
                                <p>{collaboratorsector && collaboratorsector !== undefined ? collaboratorsector : '-'}</p>
                            </div>
                            <div className="bloco salario">
                                <h4>Salário</h4>
                                {verSalario ? <>
                                    <button onClick={() => VerSalario()} >
                                        {/* {
                                            collaboratorsalary && collaboratorsalary !== undefined && collaboratorsalary !== '' ?
                                        (
                                            <>
                                            R$ {collaboratorsalary}
                                            </>
                                            ) : ('R$ 0,00')
                                        } */}
                                        {
                                            collaboratorsalary && collaboratorsalary !== undefined  ?
                                        (
                                            <>
                                            R$ {collaboratorsalary}
                                            </>
                                            ) : ('R$ 0,00')
                                        }

                                    </button>
                                </> : ""}
                                {esconderSalario ? <>
                                    <button onClick={() => EsconderSalario()} >
                                       <img src={iconEyeSee} alt="Olho" />

                                    </button>
                                </> : ""}
                                {/* VerSalario */}
                            </div>
                            <div className="bloco">
                                <h4>Admissão</h4>
                                <p>{collaboratoradmissionDate && collaboratoradmissionDate !== undefined ? collaboratoradmissionDate : '-'}</p>
                            </div>
                            <div className="bloco">
                                <h4>Gestor</h4>
                                <p>{namemanager && namemanager !== undefined ? namemanager : '-'}</p>
                            </div>
                        </div>

                    </div>
                    <div className="dropdow">
                            <Dropdown  className="dropdown-icon dropdown-perfil">
                                <Dropdown.Toggle>
                                <img alt="" src={iconVerMais} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>

                                        <Dropzone onDrop={handleDropAvatar} >
                                            {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "Dropdown-dropzone" })}>
                                                <label className="label-foto" >
                                                    {avatar && avatar ? ("Alterar foto"):("Adicionar foto")}
                                                    </label>
                                                <input type="file" name="file" className="inputFile" {...getInputProps()}/>
                                            </div>
                                            )}
                                        </Dropzone>

                                </Dropdown.Menu>
                            </Dropdown>
                    </div>
                </section>
            </>
        );
}
export default inforProfile;
