import React, { useState, useEffect } from "react";
import api from "../../services/api";
// import Logo from "../../assets/images/logo.svg";


import './../Login/styles.scss';
import logo from '../../assets/images/logo.svg';

import swal from "sweetalert";

export default function ResetPassword(props){
    const { token } = props.match.params;

    const [load, setLoad] = useState(false);

    //console.log(token)
    const [collaborator, setCollaborator] = useState([])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        loadcollaborator(token);

    }, [token]);

    async function loadcollaborator(token) {
        //setLoad(true)
        await api.get(`/recuperar-dados-colaborador/${token}`)
        .then(response => {

            if(response.data[0] !== undefined) {
                setCollaborator(response.data);
            }

        }).catch(error => {})
    }

    const [state, setState]           = useState([])
    //const [password, setPassword]     = useState([])
    //const [repassword, setRepassword] = useState([])


    async function myChangeHandler(event) {
        let nam = event.target.name;
        let val = event.target.value;
        setState({...state, [nam]: val})
        //this.setState({ [nam]: val });
    }


    async function renewPassword(event) {
        event.preventDefault();
        const { password } = state;
        //console.log(state)

        const id = collaborator[0]._id;
        //console.log(id)
        setLoad(true)
        await api.put(`/recover-password`, { password, id })
            .then(() => {
                setLoad(false)
                swal({ icon: "success", title: "Sucesso!", text: "Senha atualizada com sucesso." });
                setTimeout(() => {
                    window.location.href = "/";
                }, 3000);
            })
            .catch(() => {
                setLoad(false)
                swal({ icon: "error", title: "Erro!", text: "Erro ao atualizar a senha, tente novamente mais tarde." });
                setTimeout(() => {
                    window.location.href = "/";
                }, 3000);
            });

    }


    //const { user } = this.state.user;
    //const { password, repassword } = this.state;
    //console.log(state.repassword ? state.repassword.length : '')
    return (
        <>
            {collaborator[0] !== undefined ? collaborator
            .map((collab, index) => (
                <div key={index} className="pageLogin">
                <img src={logo} alt="People View" className="logo"/>

                <form className="formLogin" onSubmit={renewPassword}>
                    Olá, <h2 className="titulo-primeiro-acesso">{collab.name}</h2>
                    <p className="subtitulo-primeiro-acesso">Para continuar, crie uma nova senha com no mínimo 8 caracteres:</p>
                    <label className="autera-senha-subtitulo">Nova senha:</label>
                    <input type="password" name="password" onChange={myChangeHandler} className="nova-senha" placeholder="Nova senha" required/>
                    <label className="autera-senha-subtitulo">Digite novamente:</label>
                    <input type="password" name="repassword" onChange={myChangeHandler} className="nova-senha" placeholder="Digite novamente senha" required/>
                    {state.repassword && state.password !== state.repassword ? (<p className="senha-incopativel">Senhas diferentes</p>) : ('')}
                    {load ?
                    (<p className="carregando">Enviando...</p>) :
                    ('')
                    }
                    <button type="submit"  className="btnEntrar btnGreen" disabled={(state.password !== state.repassword || state.length === 0 || state.password === '' || state.repassword === '' || state.password.length <=7 ||state.repassword.length <=7 )}>Alterar senha</button>
                </form>
                <span className="copyright">© {new Date().getFullYear()} People View</span>
            </div>
                )) : (
                    <div className="pageLogin">
                <img src={logo} alt="People View" className="logo"/>

                <form className="formLogin" >
                    <h2 className="titulo-primeiro-acesso">Token inválido</h2>
                </form>
                <span className="copyright">© {new Date().getFullYear()} People View</span>
            </div>
                )}


            {/*
            <Container className="h-100 bg-white" fluid>
                <Row className="h-100 justify-content-center">
                    <Col xs={12} sm={8} lg={6} className="d-flex align-items-self justify-content-between flex-column">

                        <div className="px-lg-5">
                            <h1 className="d-inline-flex bg-primary text-uppercase px-2 pb-0 pt-2">Olá, {user.name}</h1>
                            <p className="text-muted mb-4 mt-2">Para continuar, crie uma nova senha:</p>
                            <Form onSubmit={this.renewPassword}>
                                <Row>
                                    <Col xs={12} className="mb-3">
                                        <Form.Label className="text-muted">Senha</Form.Label>
                                        <Form.Control className="rounded-0" type="password" name="password" onChange={this.myChangeHandler} required />
                                    </Col>
                                    <Col xs={12} className="mb-3">
                                        <Form.Label className="text-muted">Repetir senha</Form.Label>
                                        <Form.Control className="rounded-0" type="password" name="repassword" onChange={this.myChangeHandler} required />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs={12} className="d-flex align-items-center justify-content-end">
                                        <Button type="submit" className="rounded-0 text-white" disabled={(password !== repassword)}>Alterar senha</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="text-muted small py-3 text-center">
                            &copy; {new Date().getFullYear()} People View - All Rights Reserved
                        </div>
                    </Col>
                </Row>
            </Container>
            */}
        </>
    );
}
