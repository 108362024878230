import React, {useState} from "react";
import Close from '../../assets/images/close.svg';
import { Modal } from "react-bootstrap";
import Parser from 'html-react-parser';


export default function WebsiteVacanciesBaseBorard({aparencePage, filesPage, PrivacyPolicyPage}) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visiblePoliticaPrivacidade, setVisiblePoliticaPrivacidade] = useState(false);

    async function AbrirPoliticaPrivacidade() {
        setVisiblePoliticaPrivacidade(!visiblePoliticaPrivacidade);
    }
    return (
        <>
        
        
            <div className="Baseboard"
            style={{ background: aparencePage?.colorBackgroundFooter ? aparencePage.colorBackgroundFooter : "" }} 
             >
                <div className="center">

                    <div className="left">
                        {
                            aparencePage?.titleFooter ?
                            <p className="titulo"  
                             style={{ color: aparencePage?aparencePage.colorTextFooter : "#000" }}
                            >{aparencePage?.titleFooter}</p> :""
                        }
                        <ul>

                            {
                                aparencePage?.vacancyPageAppearanceConfig[0].site ?
                                <>
                                    <li>
                                        <button
                                            onClick={() => window.location.href =  aparencePage?.vacancyPageAppearanceConfig[0].site }
                                            style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}
                                        >Site</button>
                                    </li>
                                </>
                                :""
                            }

                            {
                                aparencePage?.vacancyPageAppearanceConfig[0].linkedin ?
                                <li>
                                    <button
                                        onClick={() => window.location.href =  aparencePage?.vacancyPageAppearanceConfig[0].linkedin }
                                        style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}
                                    >LinkedIn</button>
                                </li>

                                :""
                            }



                            {
                                aparencePage?.vacancyPageAppearanceConfig[0].facebook ?
                                <li>
                                    <button
                                        onClick={() => window.location.href =  aparencePage?.vacancyPageAppearanceConfig[0].facebook }
                                        style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}
                                    >Facebook</button>
                                </li>

                                :""
                            }

                            {
                                aparencePage?.vacancyPageAppearanceConfig[0].twitter ?
                                <li>
                                    <button
                                        onClick={() => window.location.href =  aparencePage?.vacancyPageAppearanceConfig[0].twitter }
                                        style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}
                                    >Twitter</button>
                                </li>
                                :""
                            }
                            {
                                aparencePage?.vacancyPageAppearanceConfig[0].instagram ?
                                <li>
                                    <button
                                        onClick={() => window.location.href =  aparencePage?.vacancyPageAppearanceConfig[0].instagram }
                                        style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}
                                    >Instagram</button>
                                </li>

                                :""
                            }

                            {
                                aparencePage?.vacancyPageAppearanceConfig[0].youtube ?
                                <li>
                                <button
                                    onClick={() => window.location.href =  aparencePage?.vacancyPageAppearanceConfig[0].youtube }
                                    style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}
                                >YouTube</button>
                                </li>

                                :""
                            }
                        </ul>
                        { PrivacyPolicyPage ? <button onClick={AbrirPoliticaPrivacidade}  style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }} >Política de Privacidade</button>:""}
                    </div>
                    <div className="right">
                        <div className="logo">

                           {filesPage !== "" ?
                                 filesPage
                                .filter(filter =>
                                    filter.category === "logoFooter"
                                ).map((filesPagef, index) => (
                                    <img src={filesPagef.location} alt="Nome da empresa" />
                                ))
                                : ""
                           }
                            {/* <img src={Logo} alt="Nome da empresa" /> */}
                        </div>
                        <p  style={{ color: aparencePage?.colorTextFooter ? aparencePage.colorTextFooter : "#000" }}>© 2022 People View</p>
    
                    </div>
                </div>
            </div>
        
        
        
                           {
                               PrivacyPolicyPage  ?
            <Modal show={visiblePoliticaPrivacidade} restoreFocus={false}  className="popup-geral popup-politica-privacidade" onHide={AbrirPoliticaPrivacidade}>
                <div>
                    <div className="titulo">
                        <h4>{PrivacyPolicyPage ? PrivacyPolicyPage?.title  : "" } <p className="btn-fechar" onClick={AbrirPoliticaPrivacidade}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <div className="infor-politica-privacidade">
                    {PrivacyPolicyPage ? Parser(PrivacyPolicyPage?.text) : "" } 
                    </div>
                    <div className="btn-politica-privacidade">
                        <button className="btnGray" onClick={AbrirPoliticaPrivacidade}>Fechar</button>
                    </div>
                </div>
            </Modal>

            :""
            }
        </>
    )
}