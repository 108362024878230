import React from 'react';

// import { NavLink } from 'react-router-dom'

// import ArrowLeft from '../../../../assets/images/arrow-left2.svg';
// import ArrowRight from '../../../../assets/images/arrow-right.svg';


// eslint-disable-next-line react-hooks/rules-of-hooks
//const [ setFilterPerCollaborator ] = useState([]);

export default function Paginacao({
    nextPage,
    prevPage,
    page,
    pages,
    paginationPage,
}) {

    // console.log('pages')
    // console.log(pages)

    let numberPaginate = []

    for(let i=0; i<=pages; i++){
        numberPaginate.push(
            <li className="number"  key={i}>
                <button className={i === page ? "secao" : ""} onClick={() => paginationPage(i)}>{i+1}</button>
            </li>
        )
    }
    return (
        <>
        <div className="paginacaoG">
            <ul >
                {
                    numberPaginate
                }
                {
                    pages && pages > 0 ?
                    <>
                    <li className="page-anterior">
                        <button style={(page === 0) ? { cursor: "not-allowed" } : {}}  disabled={page === 0} onClick={() => prevPage(page)} >anterior</button>
                    </li>
                    <li className="page-proxima">
                        <button style={(page === pages) ? { cursor: "not-allowed" } : {}}  disabled={page === pages} onClick={() => nextPage(page)} >próxima</button>
                    </li>
                    </>
                    :
                    ''
                }
            </ul>
        </div>
        </>
    );
}

