import React, { useState, useEffect, useRef } from 'react';
import Layout from '../../../components/Layouts/default';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { decodeToken } from '../../../services/auth';
import Header from '../../../components/Administrativo/Colaboradores/header';
import HeaderNavigation from '../../../components/Administrativo/Colaboradores/headerNavigation';
import AtivosColaboradores from '../../../components/Administrativo/Colaboradores/Ativos';
import './styles.scss';

export default function Colaboradores(props) {

    const { history } = props;
    const { user } = decodeToken();
    const role    = user.role;
    const idAcess = user._id;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [
        loadFocusTop,
    ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const focusTop  = useRef(null);

    if (loadFocusTop === true ) {
        focusTop.current.focus();
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterPerCollaborator, setFilterPerCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPerAlphabeticalOrder, setLoadPerAlphabeticalOrder ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPerMostRecent, setLoadPerMostRecent ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPerOlder, setLoadPerOlder ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadPerManager, setLoadPerManager ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ onlyDirectReports, setOnlyDirectReports ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ page, setPage ] = useState(0);

    const LIMIT = 10;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pages, setPages ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ total, setTotal ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ sortBy, setSortBy ] = useState('name');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameFilter, setNameFilter ] = useState('');

    useEffect(() => {
        loadcountadmission();
        loadshutdowcollaborator();
        loadcollaborator();
    }, []);

    async function loadcountadmission() {
        await api.get('/number-of-collaborators/?admissionProcess=true&status=Inativo')
        .then(response => {

            localStorage.setItem('@peopleview/coutAdmission', response.data);

        }).catch(error => {})
    }

    async function loadshutdowcollaborator() {
        await api.get('/number-of-collaborators/?shutdownProcess=true&status=Inativo')
        .then(response => {

            localStorage.setItem('@peopleview/shutdowProcess', response.data);

        }).catch(error => {})
    }


    async function loadcollaborator() {
        setLoad(true);
        await api.get(`/collaborator-new/?status=Ativo&role=admin&role=manager&role=user&sortBy=name&&page=0&limit=${LIMIT}`)
        .then(response => {
            setFilterPerCollaborator(response.data.collaboratorSearch);
            setPages(response.data.pages);
            setTotal(response.data.total);
            localStorage.setItem('@peopleview/countCollaborators', response.data.total);
            setLoad(false)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoad(false)
        });
    }

    async function loadcollaboratorPage(page, nameFilter, sortBy, onlyDirectReports) {
        setLoad(true);
        try {
            let response = await api.get(`/collaborator-new/?name=${nameFilter}&status=Ativo&role=admin&role=manager&role=user&sortBy=${sortBy}&page=${page}&limit=${LIMIT}&onlyDirect=${onlyDirectReports ?? false}`)
            setFilterPerCollaborator(response.data.collaboratorSearch);
            setPages(response.data.pages);
            setTotal(response.data.total);
        }catch(error){
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        } finally {
            setLoad(false)
        }
    }

    async function chooseOrdering(event) {

        if(event === 'ordem_alfabetica') {
            setSortBy('ordem_alfabetica')
            setLoadPerAlphabeticalOrder(true)
            setLoadPerMostRecent(false)
            setLoadPerOlder(false)
            setLoadPerManager(false)
            loadcollaboratorPage(page, nameFilter, 'ordem_alfabetica')
        }

        if(event === 'mais_recente') {
            setSortBy('mais_recente')
            setLoadPerAlphabeticalOrder(false)
            setLoadPerMostRecent(true)
            setLoadPerOlder(false)
            setLoadPerManager(false)
            loadcollaboratorPage(page, nameFilter, 'mais_recente')
        }

        if(event === 'mais_antigo') {
            setSortBy('mais_antigo')
            setLoadPerAlphabeticalOrder(false)
            setLoadPerMostRecent(false)
            setLoadPerOlder(true)
            setLoadPerManager(false)
            loadcollaboratorPage(page, nameFilter, 'mais_antigo')
        }

        if(event === 'por_gestor') {
            setSortBy('por_gestor')
            setLoadPerAlphabeticalOrder(false)
            setLoadPerMostRecent(false)
            setLoadPerOlder(false)
            setLoadPerManager(true)
            loadcollaboratorPage(page, nameFilter, 'por_gestor')
        }
    }

    async function loadfilterpercollaborator(event) {
        setLoad(true);
        const name = event;
        setNameFilter(name);
        const pageNumber = 0;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, name, sortBy, onlyDirectReports);
    }

    async function loadOnlyDirectReports(event) {
        setLoad(true);
        const name = nameFilter;
        const onlyDirectReports = event;
        const pageNumber = 0;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, name, sortBy, onlyDirectReports)
    }

    async function prevPage(page) {
        if (page === 0) return;
        const name = nameFilter;
        const pageNumber = page - 1;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, name, sortBy, onlyDirectReports);
    }

    async function nextPage(page) {
        if (page === pages) return;
        const pageNumber = page + 1;
        const name = nameFilter;
        setPage(pageNumber);
        loadcollaboratorPage(pageNumber, name, sortBy, onlyDirectReports);
    }

    async function paginationPage(page) {

        const pageNumber = page;
        setPage(pageNumber);

        const name = nameFilter;
        loadcollaboratorPage(pageNumber, name, sortBy, onlyDirectReports);

    }

    const myChangeHandlerStatusEdition = async (event) => {
        let nam              = event.target.name;
        let val              = event.target.value;
        let admissionProcess = "false";
        let shutdownProcess  = "true";
        let recentShutdown   = "false";
        let status           = "Inativo";

        setCollaborator({ status, [nam]: val, admissionProcess, recentShutdown, shutdownProcess })
    }

    async function desligarColaborador(event) {

        event.preventDefault();
        swal({
            title: "Atenção",
            text: "Deseja realmente desligar este colaborador?",
            icon: "warning",
            buttons: ["Cancelar", "OK"],
            dangerMode: false,
        })
        .then(async (res) => {
            if (res) {
                const formData = new FormData();
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                formData.append('_id', collaborator._id);
                formData.append('admissionProcess', collaborator.admissionProcess);
                formData.append('shutdownProcess', collaborator.shutdownProcess);
                formData.append('recentShutdown', collaborator.recentShutdown);
                formData.append('status', collaborator.status);
                setLoad(true)
                await api.put(`/collaborator/${collaborator._id}`, formData, config)
                .then(response => {


                    loadcollaborator()

                    swal({ icon: "success", title: "Sucesso!", text: "Colaborador desligado com sucesso." });
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: "Erro ao desligar o colaborador, tente novamente mais tarde." });
                });
            }
        }).catch(error => {});
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ checkhedAll, setCheckhedAll] = useState(false);
    const [ checkeds, setCheckeds] = useState([]);
    const [ loadCheckeds, setloadCheckeds] = useState(false);
    const [ checkedsaAll, setCheckedsaAll] = useState([]);
    const [ loadCheckedsaAll, setloadCheckedsaAll] = useState(false);
    const [ collaborators, setCollaborators] = useState([]);
    const [ allCollaborators, setAllCollaborators] = useState([]);
    const [ allCollaboratorsExclude, setAllCollaboratorsExclude] = useState([])
    const [ collaboratorUpdate, setCollaboratorUpdate] = useState([]);
    const [ skillUpdate, setSkillUpdate] = useState([]);
    const [ someOneChecked, setSomeOneChecked] = useState(false);
    const [ checkedSelected, setCheckedSelected] = useState(false);

    const [ loadButtomEditSelected, setloadButtomEditSelected] = useState(false);
    const [ loadButtomSkillAtributeSelected, setloadButtomSkillAtributeSelected] = useState(false);


    async function myChangeHandlerSetAll(event) {

        if(event.target.checked === true) {

            setCheckhedAll(true)
            // checkedsaAll, setCheckedsaAll
            let itensAllCopyCollaborators = allCollaborators;
            let itensCopy = checkedsaAll;
            setloadCheckedsaAll(true)
            for(let i=0; i < filterPerCollaborator.length; i++) {

                let idCollaborator = filterPerCollaborator[i]._id;

                await api.get(`/collaborator/${idCollaborator}`)
                .then(response => {

                    let idcollaborator         = i;
                    let avatar     = response.data.avatar;
                    let name       = response.data.name;
                    let occupation = response.data.occupation;
                    let _id        = response.data._id;

                    itensAllCopyCollaborators.push({ idcollaborator, avatar, name, occupation, _id });
                    itensCopy.push({ _id: _id });

                }).catch(error => {});

            }

            setloadCheckedsaAll(false);
            setCheckedsaAll(itensCopy);
            setAllCollaborators(itensAllCopyCollaborators);
            setCheckeds([]);
            setCollaborators([]);
            setCheckedSelected(false);


        } else {

            setloadCheckedsaAll(false);
            setCheckhedAll(false);
            setCollaborators([]);
            setAllCollaborators([]);
            setCheckeds([]);
            setCheckedsaAll([]);
            setCheckedSelected(false);
        }
    }

    var id_collaborator_excludeded = null;
    async function myChangeHandlerExcludeCollaborator(event) {
        event.preventDefault();

        id_collaborator_excludeded = event.target.value;
        setAllCollaboratorsExclude(id_collaborator_excludeded)

        if(allCollaborators && allCollaborators.length !== 0) {

            let arrayColaboradores = allCollaborators;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excludeded);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setAllCollaborators(arrayColaboradores);
            setCheckeds(arrayColaboradores);

        } else {

            let arrayColaboradores = collaborators;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excludeded);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setCollaborators(arrayColaboradores);
            setCheckeds(arrayColaboradores);

        }


    }

    async function myChangeHandlerToCheck(event) {

        if(event.target.checked === true) {
           let nam = event.target.name;
           let val = event.target.value;
           setloadCheckeds(true)
           await api.get(`/collaborator/${val}`)
            .then(response => {

                const itensCopyCollaborators = Array.from(collaborators);
                itensCopyCollaborators.push({idcollaborator: collaborators.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setCollaborators(itensCopyCollaborators);

            }).catch(error => {});

           const itensCopy = Array.from(checkeds);
           itensCopy.push({ [nam]: val });
           setCheckeds(itensCopy);
           setloadCheckeds(false);
           setSomeOneChecked(false);
           setAllCollaborators([]);
           setCheckhedAll(false);
           setCheckedSelected(true);


        } else {
            let nam = event.target.name;
            let val = event.target.value;

            let arr = checkeds;
            let indexC = arr.findIndex(objeto => objeto.nam === nam);
            arr.splice(indexC, 1);
            setCheckeds(arr);
            if(arr.length <= 1) {
                //alert('um')
                setSomeOneChecked(true);
                setCheckedsaAll([]);
            }
            else {
                setSomeOneChecked(false);
            }
            setCheckhedAll(false);

            let arrCol = collaborators;
            let indexCol = arrCol.findIndex(objeto => objeto._id === val);
            arrCol.splice(indexCol, 1);
            setCollaborators(arrCol);
            setAllCollaborators([]);
            setCheckedSelected(true)
        }


    }

    const [loaderErrorSkill, setloaderErrorSkill] = useState(false);

    async function myChangeHandlerskill(event) {
        let nam      = event.target.name;
        let val      = event.target.value;
        let atribute = 'true';

        setloaderErrorSkill(false)
        setSkillUpdate({ ...skillUpdate, atribute, [nam]: val })

    }

    async function saveSkill(event) {
        event.preventDefault();

        if(skillUpdate && skillUpdate.name !== undefined && skillUpdate.name !== "") {

            setloaderErrorSkill(false)

            await api.post("/skill", skillUpdate)
            .then(response => {

                let skill = response.data._id;

                //checkhedAll
                if(checkedsaAll && checkedsaAll.length !== 0) {

                    if(checkeds && checkeds.length !== 0) {

                        swal({
                            title: "Atenção",
                            text: "Deseja realmente atribuir está Skill para estes colaboradores?",
                            icon: "warning",
                            buttons: ["Cancelar", "OK"],
                            dangerMode: false,
                        })
                        .then(async (res) => {
                            if (res) {

                                for(let i=0; i < checkeds.length; i++){

                                    let collaborator =  checkeds[i]._id;

                                    async function atributeskilltocollaborator() {
                                        setloadButtomSkillAtributeSelected(true)
                                        // pre save
                                        await api.post('/skill-collaborator/', { collaborator, skill })
                                        .then(response => {

                                        }).catch(error => {
                                            toast.error(error.response.data.message);
                                            setloadButtomSkillAtributeSelected(false)
                                        });
                                    }
                                    atributeskilltocollaborator();

                                }
                                setSkillUpdate([])
                                setloadButtomSkillAtributeSelected(false)
                                if(checkeds.length === 1) {

                                    swal({ icon: "success", title: "Sucesso!", text: 'Skill criada com sucesso!' });
                                }
                                else {
                                    swal({ icon: "success", title: "Sucesso!", text: 'Skills criadas com sucesso!' });
                                }

                            }
                        })

                    }
                    else {

                        swal({
                            title: "Atenção",
                            text: "Deseja realmente atribuir está Skill para estes colaboradores?",
                            icon: "warning",
                            buttons: ["Cancelar", "OK"],
                            dangerMode: false,
                        })
                        .then(async (res) => {
                            if (res) {

                                for(let i=0; i < checkedsaAll.length; i++){

                                    let collaborator =  checkedsaAll[i]._id;

                                    async function atributeskilltocollaborator() {
                                        setloadButtomSkillAtributeSelected(true)
                                        // pre save
                                        await api.post('/skill-collaborator/', { collaborator, skill })
                                        .then(response => {
                                            //let name =response.data.name;
                                            //toast.info(`Skill criada com sucesso para o(a) collaborador(a) ${name}!`);
                                        }).catch(error => {
                                            toast.error(error.response.data.message);
                                            setloadButtomSkillAtributeSelected(false)

                                        });
                                    }
                                    atributeskilltocollaborator();

                                }

                                setSkillUpdate([])
                                setloadButtomSkillAtributeSelected(false)
                                if(checkedsaAll.length === 1) {
                                    swal({ icon: "success", title: "Sucesso!", text: 'Skill criada com sucesso!' });
                                }
                                else {
                                    swal({ icon: "success", title: "Sucesso!", text: 'Skills criadas com sucesso!' });
                                }


                            }
                        })

                    }

                }
                else {

                    swal({
                        title: "Atenção",
                        text: "Deseja realmente atribuir está Skill para estes colaboradores?",
                        icon: "warning",
                        buttons: ["Cancelar", "OK"],
                        dangerMode: false,
                    })
                    .then(async (res) => {
                        if (res) {

                            for(let i=0; i < checkeds.length; i++){

                                let collaborator =  checkeds[i]._id;

                                async function atributeskilltocollaborator() {
                                    setloadButtomSkillAtributeSelected(true)
                                    // pre save
                                    await api.post('/skill-collaborator/', { collaborator, skill })
                                    .then(response => {
                                    }).catch(error => {
                                        toast.error(error.response.data.message);
                                        setloadButtomSkillAtributeSelected(false)
                                    });
                                }
                                atributeskilltocollaborator();

                            }

                            setSkillUpdate([])
                            setloadButtomSkillAtributeSelected(false);
                            if(checkeds.length === 1) {

                                swal({ icon: "success", title: "Sucesso!", text: 'Skill criada com sucesso!' });
                            }
                            else {
                                swal({ icon: "success", title: "Sucesso!", text: 'Skills criadas com sucesso!' });
                            }

                        }
                    })

                }
            }).catch(error => {
                swal({ icon: "error", title: "Erro!", text: error.response.data.message });
            });

        }
        else {
            setloaderErrorSkill(true);
        }

    }

    async function myChangeHandlerUpdated(event) {
        let nam = event.target.name;
        let val = event.target.value;

        setCollaboratorUpdate({ ...collaboratorUpdate, [nam]: val });

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visible, setVisible] = useState(false);
    async function handleToggleVisible() {
        setVisible(!visible);
    }

    async function handleToggleNoVisible() {
        setVisible(!visible);
        setCollaboratorUpdate([]);
        setSkillUpdate([]);
        setloaderErrorSkill(false);
        setAllCollaborators([]);
        setCollaborators([]);
        setCheckhedAll(null);
        setCheckhedAll(false);
    }

    async function saveToCheck(event) {
        event.preventDefault();

        setloaderErrorSkill(false);

        if(checkedsaAll && checkedsaAll.length !== 0) {

            if(checkeds && checkeds.length !== 0) {

                if(collaboratorUpdate && collaboratorUpdate.length === 0) {

                    swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um dado!" });

                }
                else {

                    swal({
                        title: "Atenção",
                        text: "Deseja realmente alterar as informações?",
                        icon: "warning",
                        buttons: ["Cancelar", "OK"],
                        dangerMode: false,
                    })
                    .then(async (res) => {
                        if (res) {
                            // alert("entrou 1")

                            for(let i=0; i < checkeds.length; i++){

                                let id = checkeds[i]._id
                                setloadButtomEditSelected(true)
                                await api.put(`/collaborator/${id}`, collaboratorUpdate)
                                .then(response => {

                                    setCheckedSelected(false)
                                    setAllCollaborators([])
                                    setCollaborators([])
                                    setCheckedsaAll([])
                                    setCheckeds([])
                                    setCollaboratorUpdate([]);

                                    loadcollaborator()
                                    setLoad(false)

                                }).catch(error => {
                                    //swal({ icon: "error", title: "Erro!", text: "erro ao editar colaboradores!" });
                                    toast.error(error.response.data.message);
                                    setVisible(!visible);
                                    setCollaboratorUpdate([])
                                    setloadButtomEditSelected(false)
                                });
                            }

                            setloadButtomEditSelected(false)
                            setVisible(!visible);
                            if(checkeds.length === 1) {
                                swal({ icon: "success", title: "Sucesso!", text: `${checkeds.length} Colaborador alterado com sucesso!` });
                            }
                            else {
                                swal({ icon: "success", title: "Sucesso!", text: `${checkeds.length} Colaboradores alterados com sucesso!` });
                            }
                            // setCheckhedAll(false)
                            setCheckhedAll(null)

                        }
                    })

                }

            }
            else {


                if(collaboratorUpdate && collaboratorUpdate.length === 0) {

                    swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um dado!" });

                }
                else {
                    swal({
                        title: "Atenção",
                        text: "Deseja realmente alterar as informações?",
                        icon: "warning",
                        buttons: ["Cancelar", "OK"],
                        dangerMode: false,
                    })
                    .then(async (res) => {
                        if (res) {
                            // alert("entrou 2")


                            for(let i=0; i < checkedsaAll.length; i++){

                                let id   = checkedsaAll[i]._id
                                setloadButtomEditSelected(true)
                                await api.put(`/collaborator/${id}`, collaboratorUpdate)
                                .then(response => {

                                    setCheckedSelected(false)
                                    setAllCollaborators([])
                                    setCollaborators([])
                                    setCheckedsaAll([])
                                    setCollaboratorUpdate([]);
                                    loadcollaborator()
                                    setLoad(false)

                                }).catch(error => {
                                    toast.error(error.response.data.message);
                                    //swal({ icon: "error", title: "Erro!", text: "erro ao editar colaboradores!" });
                                    setVisible(!visible);
                                    setCollaboratorUpdate([])
                                    setloadButtomEditSelected(false)
                                });
                            }

                            setloadButtomEditSelected(false)
                            setVisible(!visible);
                            if(checkedsaAll.length === 1) {
                                swal({ icon: "success", title: "Sucesso!", text: `${checkedsaAll.length} Colaborador alterado com sucesso!` });
                            }
                            else {
                                swal({ icon: "success", title: "Sucesso!", text: `${checkedsaAll.length} Colaboradores alterados com sucesso!` });
                            }
                            // setCheckhedAll(false)
                            setCheckhedAll(null)
                        }
                    })

                }

            }

        }
        else {

            if(collaboratorUpdate && collaboratorUpdate.length === 0) {

                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um dado!" });

            }
            else {

                swal({
                    title: "Atenção",
                    text: "Deseja realmente alterar as informações?",
                    icon: "warning",
                    buttons: ["Cancelar", "OK"],
                    dangerMode: false,
                })
                .then(async (res) => {
                    if (res) {
                        for(let i=0; i < checkeds.length; i++){

                            let id = checkeds[i]._id
                            setloadButtomEditSelected(true)

                            await api.put(`/collaborator/${id}`, collaboratorUpdate)
                            .then(response => {

                                setCheckedSelected(false)
                                setAllCollaborators([])
                                setCollaborators([]);
                                setCheckedsaAll([])
                                setCheckeds([])
                                setCollaboratorUpdate([]);
                                setloadCheckeds(false);
                                setSomeOneChecked(false);
                                setCheckhedAll(null)
                                loadcollaborator()
                                setLoad(false)
                            }).catch(error => {
                                toast.error(error.response.data.message);
                                setVisible(!visible);
                                setCollaboratorUpdate([])
                                setloadButtomEditSelected(false)
                            });

                        }

                        setloadButtomEditSelected(false)
                        setVisible(!visible);
                        if(checkeds.length === 1) {

                            swal({ icon: "success", title: "Sucesso!", text: `${checkeds.length} Colaborador alterado com sucesso!` });
                        }
                        else {
                            swal({ icon: "success", title: "Sucesso!", text: `${checkeds.length} Colaboradores alterados com sucesso!` });
                        }
                        // setCheckhedAll(null)
                        // setCheckhedAll(false)
                        setCheckhedAll(null)

                    }
                })

            }

        }

    }

      /////////////////////////////////////////////////////////////////////////////////
     ////////////////////////// FINAL DE EDITAR VÁRIOS  //////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////


    localStorage.removeItem('@peopleview/nameProfile');
    localStorage.removeItem('@peopleview/collaboratorOccupation');
    localStorage.removeItem('@peopleview/collaboratorSalary');
    localStorage.removeItem('@peopleview/collaboratorSector');
    localStorage.removeItem('@peopleview/nameManager');
    localStorage.removeItem('@peopleview/avatar');
    localStorage.removeItem('@peopleview/collaboratorAdmissionDate');
    localStorage.removeItem('@peopleview/StepsNewAdmission');


    let numberPaginate = []

    for(let i=0; i<=pages; i++){
        numberPaginate.push(
            <li className="number"  key={i}>
                <button className={i === page ? "secao" : ""} onClick={() => paginationPage(i)}>{i+1}</button>
            </li>
        )
    }

    return (
        <>
            <Layout
            path={props.match.path}
            >
                <Header
                    history={history}
                    focusTop={focusTop}
                    user={user}
                />
                <HeaderNavigation
                    nameFilter={nameFilter}
                    idAcess={idAcess}
                    onUserInputChange={(event) => loadfilterpercollaborator(event)}
                    user={user}
                    role={role}
                    path={props.match.path}
                    onlyDirectReports={onlyDirectReports}
                    loadOnlyDirectReports={loadOnlyDirectReports}
                    setOnlyDirectReports={setOnlyDirectReports}
                />
                <AtivosColaboradores
                    filterPerCollaborator={filterPerCollaborator}
                    user={user}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    page={page}
                    pages={pages}
                    total={total}
                    numberPaginate={numberPaginate}
                    paginationPage={paginationPage}
                    setSortBy={setSortBy}
                    load={load}
                    role={role}
                    loadPerAlphabeticalOrder={loadPerAlphabeticalOrder}
                    loadPerMostRecent={loadPerMostRecent}
                    loadPerOlder={loadPerOlder}
                    loadPerManager={loadPerManager}
                    myChangeHandlerStatusEdition={myChangeHandlerStatusEdition}
                    desligarColaborador={desligarColaborador}
                    myChangeHandlerSetAll={myChangeHandlerSetAll}
                    myChangeHandlerToCheck={myChangeHandlerToCheck}
                    myChangeHandlerUpdated={myChangeHandlerUpdated}
                    myChangeHandlerskill={myChangeHandlerskill}
                    skillUpdate={skillUpdate}
                    allCollaborators={allCollaborators}
                    allCollaboratorsExclude={allCollaboratorsExclude}
                    collaborators={collaborators}
                    checkhedAll={checkhedAll}
                    checkedsaAll={checkedsaAll}
                    checkeds={checkeds}
                    someOneChecked={someOneChecked}
                    handleToggleVisible={handleToggleVisible}
                    handleToggleNoVisible={handleToggleNoVisible}
                    visible={visible}
                    saveToCheck={saveToCheck}
                    saveSkill={saveSkill}
                    loaderErrorSkill={loaderErrorSkill}
                    checkedSelected={checkedSelected}
                    loadButtomEditSelected={loadButtomEditSelected}
                    loadButtomSkillAtributeSelected={loadButtomSkillAtributeSelected}
                    myChangeHandlerExcludeCollaborator={myChangeHandlerExcludeCollaborator}
                    id_collaborator_excludeded={id_collaborator_excludeded}
                    loadCheckedsaAll={loadCheckedsaAll}
                    loadCheckeds={loadCheckeds}
                    chooseOrdering={chooseOrdering}
                />
            </Layout>
        </>
    );
}

