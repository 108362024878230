import React from 'react';
// import api from '../../../services/api';
// import swal from 'sweetalert';
// import { NavLink } from 'react-router-dom'
import Layout from '../../../components/Layouts/default';
import Header from '../../../components/Administrativo/ScoreAdmin/header';
import SubMenu from '../../../components/Administrativo/ScoreAdmin/subMenu';
import Carregamento from '../../../assets/images/carregando.gif';
// import IconAddGreen from '../../assets/images/add-green.svg';
// import iconVerMais from '../../assets/images/more.svg';
// import { Dropdown } from "react-bootstrap";
// import ModalGeral from '../../components/modal/modal-geral';
// import iconOrdenation from '../../assets/images/ordenation.svg';
// import { mHora } from '../../services/mask';
// import Dropzone from "react-dropzone";

import './styles.scss';

export default function ScoreAdmin(props) {
    ////////////////////////////////////////////////////////////////////////
    ///////////////////             MODAL Adicionar            ////////////
    //////////////////////////////////////////////////////////////////////

   
    return (
        <>
        


            <Layout path={props.match.path}>
                <Header/>
                <div className="page-ponto">
                    <SubMenu props={props}/>
                    <div className="page-configuracoes">
                        <h2 className="titulo">Configurar</h2>
                        <h3 className="subTitulo">A partir destas regras serão gerados alertas de inconsistências para correção antes do fechamento da folha.</h3>
                        <div className='selecionar-jornadar'>
                            <p>
                                Selecione uma jornada
                            </p>
                            <select name="jornada" id="">
                                <option>Selecione</option>
                                <option value="">40 horas semanais</option>
                            </select>
                        </div>


                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        <ul className="lista-configuracoes">
                            <li>
                                <div className="chekboxNovo">
                                    <input type="checkbox" name='maximumDayLimit' id="maximumDayLimit"/>
                                    <label htmlFor="maximumDayLimit"></label>
                                </div>

                                <label htmlFor="maximumDayLimit" className='name'>Limite máximo de jornada diária</label>
                                <input type="text" name="maximumDayLimitHr" maxlength="5" placeholder="00:00"  />
                            </li>
                            <li>
                                <div className="chekboxNovo">
                                    <input type="checkbox" name='Lack' id="Lack"/>
                                    <label htmlFor="Lack"></label>
                                </div>
                                <label htmlFor="Lack" className='name'>Falta</label>
                            </li>
                            <li>
                                <div className="chekboxNovo">
                                    <input type="checkbox" name='lackOfRegistration' id="lackOfRegistration"/>
                                    <label htmlFor="lackOfRegistration"></label>
                                </div>
                                <label htmlFor="lackOfRegistration" className='name'>Falta de registro</label>
                            </li>
                            <li>
                                <div className="chekboxNovo">
                                    <input type="checkbox" name='maximumMonthlyOvertimeLimit' id="maximumMonthlyOvertimeLimit"/>
                                    <label htmlFor="maximumMonthlyOvertimeLimit"></label>
                                </div>
                                <label htmlFor="maximumMonthlyOvertimeLimit" className='name'>Limite máximo de horas extras mensal</label>
                                <input type="text" name="maximumMonthlyOvertimeLimit" maxlength="5" placeholder="00:00"  />
                            </li>
                            <li>
                                <div className="chekboxNovo">
                                    <input type="checkbox" name='openPoint' id="openPoint"/>
                                    <label htmlFor="openPoint"></label>
                                </div>
                                <label htmlFor="openPoint" className='name'>Ponto em aberto</label>
                            </li>
                            <li>
                                <div className="chekboxNovo">
                                    <input type="checkbox" name='MaximumLimitOfMonthlyBankHours' id="MaximumLimitOfMonthlyBankHours"/>
                                    <label htmlFor="MaximumLimitOfMonthlyBankHours"></label>
                                </div>
                                <label htmlFor="MaximumLimitOfMonthlyBankHours" className='name'>Limite máximo de banco de horas mensal</label>
                                <input type="text" name="MaximumLimitOfMonthlyBankHours" maxlength="5" placeholder="00:00"  />
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </Layout>





        </>
    );
}

