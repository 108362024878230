import React from 'react';

import { NavLink } from 'react-router-dom'
import Carregamento from '../../../assets/images/carregando.gif';
import Close from '../../../assets/images/close.svg';
import { Modal } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../../assets/images/more.svg';

const PayrollList = ({
    allPayroll,
    loadPagePayroll,
    visibleEditarMes,
    EditarNovoMes,
    NaoVerEditarNovoMes,
    loadEditYearPayroll,
    loadEditPayrollMonth,
    loadEditPayrollType,
    registeredNewMonth,
    myChangeHandlerUpdateNewMonth,
    loadButtonPayrollUpdate,
    updatePayrollStore,
    deletePayroll,
}) => {
    return (
        <>

            <div className="lista-meses">
                <ul className="lista">
                    {
                    loadPagePayroll ?
                    (
                        <>

                        <div className="carregamento">
                            <img alt="" src={Carregamento} />
                        </div>
                        </>
                    ):
                        allPayroll &&
                        allPayroll !== undefined &&
                        allPayroll.length !== 0 ?
                        allPayroll.map((payroll, index) => (
                            <li key={index}>
                                <div className="mes">
                                    <p className="nome">
                                        {payroll.month}
                                    </p>
                                    <div className="infor">
                                        <span className="ano">{payroll.year}</span>
                                        {/* RSM - Revisar esse conteúdo, não faz sentido pois não tem como mudar status atualmente */}
                                        {/* <span className="processo">{payroll.status !== 'open' ? 'Fechado' : 'Em andamento'}</span>    */}
                                        <span className="arquivos-q">{payroll.totalPayrolldocuments} arquivos</span>
                                        <span className="tipo">{payroll.type}</span>
                                        <div className="ver-mais">
                                            <Dropdown  className="dropdown-icon">
                                                <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <button  type="button" name="alterar-tipo" onClick={() => EditarNovoMes(payroll)}>
                                                        Editar
                                                    </button>
                                                    <button type="button" onClick={() => deletePayroll(payroll._id)}  name="Exclui-lista" className="red">
                                                        Excluir
                                                    </button>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                    </div>
                                </div>
                                <div className="ver-tudo">

                                <div className="ver-mais">
                                            <Dropdown  className="dropdown-icon">
                                                <Dropdown.Toggle>
                                                <img alt="" src={iconVerMais} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <NavLink to={`/folha-de-pagamento/ano/mes/tipo/${payroll._id}`}>Ver tudo</NavLink>

                                                    <button  type="button" name="alterar-tipo" onClick={() => EditarNovoMes(payroll)}>
                                                        Editar
                                                    </button>
                                                    <button type="button" onClick={() => deletePayroll(payroll._id)}  name="Exclui-lista" className="red">
                                                        Excluir
                                                    </button>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                    <NavLink to={`/folha-de-pagamento/ano/mes/tipo/${payroll._id}`} className="ver-tudo-link">Ver tudo</NavLink>
                                </div>
                            </li>
                        )):' nao tem'
                    }


                </ul>
            </div>


            <Modal show={visibleEditarMes} className="popup-geral popup-payroll-cadastra" onHide={EditarNovoMes}>
                <div>
                    <div className="titulo">
                        <h4>Editar mês<p className="btn-fechar" onClick={NaoVerEditarNovoMes}><img src={Close} alt="Fechar" /></p></h4>
                    </div>
                    <ul>
                        <li>
                            <div className="intemA">
                                <p>Ano</p>
                            </div>
                            <div className="intemB">
                                <select onChange={myChangeHandlerUpdateNewMonth} defaultValue={registeredNewMonth && registeredNewMonth.year !== undefined ? registeredNewMonth.year : ''} name="year" className={loadEditYearPayroll ? 'inputErro year' : 'year'}>
                                    <option>Selecione o ano</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                    <option value="2020">2020</option>
                                    <option value="2019">2019</option>
                                </select>
                                {loadEditYearPayroll ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Mês</p>
                            </div>
                            <div className="intemB">
                                <select onChange={myChangeHandlerUpdateNewMonth} defaultValue={registeredNewMonth && registeredNewMonth.month !== undefined ? registeredNewMonth.month : ''} name="month" className={loadEditPayrollMonth ? 'inputErro month' : 'month'}>
                                    <option>Selecione o mês</option>
                                    <option value="Janeiro">Janeiro</option>
                                    <option value="Fevereiro">Fevereiro</option>
                                    <option value="Março">Março</option>
                                    <option value="Abril">Abril</option>
                                    <option value="Maio">Maio</option>
                                    <option value="Junho">Junho</option>
                                    <option value="Julho">Julho</option>
                                    <option value="Agosto">Agosto</option>
                                    <option value="Setembro">Setembro</option>
                                    <option value="Outubro">Outubro</option>
                                    <option value="Novembro">Novembro</option>
                                    <option value="Dezembro">Dezembro</option>
                                </select>
                                {loadEditPayrollMonth ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                        <li>
                            <div className="intemA">
                                <p>Tipo</p>
                            </div>
                            <div className="intemB">
                                <select onChange={myChangeHandlerUpdateNewMonth} defaultValue={registeredNewMonth && registeredNewMonth.type !== undefined ? registeredNewMonth.type : ''} name="type" className={loadEditPayrollType ? 'inputErro type' : 'type'}>
                                    <option>Selecione o tipo</option>
                                    <option value="Holerites">Holerites</option>
                                    <option value="Bônus">Bônus</option>
                                    <option value="Comissão">Comissão</option>
                                    <option value="Informe de Rendimentos">Informe de Rendimentos</option>
                                    <option value="13º Salário">13º Salário</option>
                                    <option value="Outros">Outros</option>
                                    <option value="Recibo de Férias">Recibo de Férias</option>
                                    <option value="13º Salário - Primeira parcela">13º Salário - Primeira parcela</option>
                                    <option value="13º Salário - Segunda parcela">13º Salário - Segunda parcela</option>
                                    <option value="Adiantamento">Adiantamento</option>
                                    <option value="PLR">PLR</option>
                                    <option value="Folha Complementar">Folha Complementar</option>
                                    <option value="Recibos">Recibos</option>
                                    <option value="Pró-labore">Pró-labore</option>
                                    <option value="Espelho de Ponto">Espelho de Ponto</option>
                                </select>
                                {loadEditPayrollType ? (
                                    <p className="textError">O campo é obrigatório.</p>
                                ) : ('')}
                            </div>
                        </li>
                    </ul>
                    <div className="buttoes">
                        <button className="btnGray" onClick={NaoVerEditarNovoMes}>Cancelar</button>
                        <button onClick={updatePayrollStore} className={loadButtonPayrollUpdate ? "btnGreen load" : "btnGreen"}>Editar mês</button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default PayrollList;
