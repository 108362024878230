import React, { useEffect, useState } from 'react';
// import ImgExemplo from '../../../../assets/images/alvarobraz.png';
import Carregando from '../../../../assets/images/carregando.gif';
import IconUserNone from '../../../../assets/images/iconUserNone.svg';
import { NavLink } from 'react-router-dom';
import { dateFormatedToShutDown } from '../../../../services/utils';

import api from '../../../../services/api';

export default function HistoricoFeriasBoxInforSolicitacao({
    id,
 }) {

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [loadBoxInfor, setLoadBoxInfor] = useState(false);

     //vacationAwaitingManagerApproval
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorVacationInfo, setCollaboratorVacationInfo ] = useState([]);

    useEffect(() => {

        loadcollaboratorvacationinfo(id);

    }, [id]);

    async function loadcollaboratorvacationinfo(id) {
        setLoadBoxInfor(true)
        await api.get(`collaborator-vacation-new/?id=${id}&type=individual`)
        .then(response => {
            setCollaboratorVacationInfo(response.data.collaboratorVacationNewSearch[0]);
            setLoadBoxInfor(false)
            //localStorage.setItem('@peopleview/totalwillGoOnVacation', response.data.length);
        }).catch(error => {});
    }


    return (
        <>
            <div className="box-infor">
                {
                    loadBoxInfor ? (
                    <div className="carregando"><img src={Carregando} alt="carregando" /></div>
                    ) :
                    (
                        <>
                            <div className="infor-cal">

                                <div className="img-col">
                                {
                                    collaboratorVacationInfo?.collaborator?.avatar ?
                                        <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                            <img src={collaboratorVacationInfo?.collaborator?.avatar} alt="nome" />
                                        </NavLink>
                                    :
                                        <NavLink to="/ferias" exact activeClassName="active">
                                            <div className="semFoto">
                                                <div className="semImgCol">
                                                    <img src={IconUserNone} alt="Usuario sem imagem" />
                                                </div>
                                            </div>
                                        </NavLink>

                                }


                                </div>

                                <div className="nome-setor">
                                    <NavLink to="/ferias" exact activeClassName="active" className="nome">
                                    {
                                        collaboratorVacationInfo?.collaborator?.name ?
                                        collaboratorVacationInfo?.collaborator?.name :
                                        '-'
                                    }
                                    </NavLink>
                                    <div className="setor">
                                        <p className="setor">
                                        {
                                            collaboratorVacationInfo?.collaborator?.occupation ?
                                            collaboratorVacationInfo?.collaborator?.occupation :
                                            '-'
                                        }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='infos'>
                                <div className="infor">
                                <h6>Período</h6>
                                <p><span>De</span> {collaboratorVacationInfo?.startDate ? dateFormatedToShutDown(collaboratorVacationInfo?.startDate) : '-'} <span>à</span> {collaboratorVacationInfo?.endDate ? dateFormatedToShutDown(collaboratorVacationInfo?.endDate) : '-'} ({collaboratorVacationInfo?.vacationDays ? collaboratorVacationInfo?.vacationDays : '-'} dias)</p>
                                </div>
                                <div className="infor">
                                <h6>Abono</h6>
                                <p>{collaboratorVacationInfo?.vacationDaysSold === 0 ? 'Nenhum dia' : collaboratorVacationInfo?.vacationDaysSold}</p>
                                </div>
                                <div className="infor">
                                <h6>Antecipar 13º</h6>
                                <p>{collaboratorVacationInfo?.ThirteenthSalary === true ? 'Sim' : 'Não'}</p>
                                </div>
                                <div className="infor">
                                <h6>Dias solicitados</h6>
                                <p>{collaboratorVacationInfo && collaboratorVacationInfo.balance ? collaboratorVacationInfo.balance : '-'} dias</p>

                            </div>
                        </div>
                        </>
                    )
                }


            </div>
        </>
    );
}
