import React, { useState, useEffect } from 'react';

import Layout from '../../../components/Layouts/default';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import Header from '../../../components/Administrativo/Habilidades/header';
import InforBox from '../../../components/Administrativo/Habilidades/InforBox';
import AllSkills from '../../../components/Administrativo/Habilidades/AllSkills';
import ByGroup from '../../../components/Administrativo/Habilidades/ByGroup';
import NenhumaHabilidade from '../../../assets/images/illustration-nenhum-habilidade.svg';
import { decodeToken } from '../../../services/auth';
import './styles.scss';

import api from '../../../services/api';

export default function pageHabilidade (props, { name }) {
    const { user } = decodeToken();
    const { path } = props.match;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        loadallskill();
        loadskill();
        loadskillNew();
        loadskillmorecommon();
        loadcountskillgroup();
        loadskilllost();
        loadTotalGroup();
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [todasHabilidades, setTodasHabilidades] = useState(true);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [porGrupos, setPorGrupos] = useState(false);
    function SelectTodas() {
        setTodasHabilidades(true);
        setPorGrupos(false);
    }
    function SelectPorGrupo() {
        setTodasHabilidades(false);
        setPorGrupos(true);
        loadperGroup()
    }
      ////////////////////////////////////////////////////////////////////////////
     //////////////////////////     ALL SKILL      //////////////////////////////
    ////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPageAllSkill, setLoadPageAllSkill ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ load, setLoad ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allSkill, setAllSkill] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pagesOfTheAllSkill, setPagesOfTheAllSkill] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ totalOfTheAllSkill, setTotalOfTheAllSkill] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadPageByGroup, setLoadPageByGroup ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ byGroup, setByGroup] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ totalGroups, setTotalGroups] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pageAllSkill, setPageAllSkill ] = useState(1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameSkillFilter, setNameSkillFilter ] = useState();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ nameGroupFilter, setNameGroupFilter ] = useState();
      ///////////////////////////////////////////////////////////////////////////////
     //////////////////////////////// BUSCAR POR GRUPO /////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pageByGroup, setPageByGroup ] = useState(1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ pagesOfTheByGroup, setPagesOfTheByGroup ] = useState(1);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    async function loadfilterperGroupName(event) {
        if(event.target.value.length === 0) {
            setNameGroupFilter();
            loadperGroup();
        }
        else {
            let name = event.target.value;
            setNameGroupFilter(event.target.value)
            setLoadPageByGroup(true);
            await api.get(`/count-collaborators-skill-group/?paginate=true&name=${name}`)
            .then(response => {
                if(response.data.length === 0) {
                    setByGroup([]);
                    setPagesOfTheByGroup(0);
                    setLoadPageByGroup(false);
                    setPageByGroup(0);
                }
                else {
                    setLoadPageByGroup(false)
                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];
                    let pagina = parseInt(arrInfo[0].pagina)
                    let itensCopyCollaborator = [];
                    if(pagina === arrInfo[0].pages) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setByGroup(itensCopyCollaborator);
                        setPagesOfTheByGroup(arrInfo[0].pages);
                        setLoad(false);
                        setPageByGroup(1);
                    }
                    else {
                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {
                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                            setPageByGroup(1);
                        }
                        else {
                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].pagina * arrInfo[0].limite
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                            setPageByGroup(1);
                        }
                        // => Final de => Se total menor que o limite
                    }
                }
            }).catch(error => {});
        }
    }

    async function loadTotalGroup() {
        await api.get('/count-collaborators-skill-group/')
        .then(response => {
            setTotalGroups(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    async function loadperGroup() {
        setLoadPageByGroup(true)
        await api.get(`/count-collaborators-skill-group/?paginate=true`)
        .then(response => {
            if(response.data.length !== 0) {
                setLoadPageByGroup(false)
                const docs_a  = response.data[0];
                const arrInfo = response.data[1];
                let pagina = parseInt(arrInfo[0].pagina)
                let itensCopyCollaborator = [];
                if(pagina === arrInfo[0].pages) {
                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;
                    for(let dcs=inicio; dcs < final; dcs++){
                        itensCopyCollaborator.push(docs_a[dcs]);
                    }
                    setByGroup(itensCopyCollaborator);
                    setPagesOfTheByGroup(arrInfo[0].pages);
                    setLoad(false);
                    setPageByGroup(1);
                }
                else {
                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setByGroup(itensCopyCollaborator);
                        setPagesOfTheByGroup(arrInfo[0].pages);
                        setLoad(false)
                        setPageByGroup(1);
                    }
                    else {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setByGroup(itensCopyCollaborator);
                        setPagesOfTheByGroup(arrInfo[0].pages);
                        setLoad(false);
                        setPageByGroup(1);
                    }
                    // => Final de => Se total menor que o limite
                }
            }
            else {
                setLoadPageByGroup(false)
                setByGroup([]);
                setPagesOfTheByGroup(0);
                setLoad(false);
                setPageByGroup(1)
            }
        }).catch(error => {});
    }
    // => Paginação BY GROUP
    async function paginationPageByGroup(page) {
        const pageNumber = page;
        setPageByGroup(pageNumber);
        async function loadbygrouppagination(page = pageNumber) {
            if(nameGroupFilter !== undefined) {
                setLoadPageByGroup(true);
                await api.get(`/count-collaborators-skill-group/?paginate=true&name=${nameGroupFilter}`)
                .then(response => {
                    if(response.data.length === 0) {
                        setByGroup([]);
                        setPagesOfTheByGroup(0);
                        setLoadPageByGroup(false);
                        setPageByGroup(0);
                    }
                    else {
                        setLoadPageByGroup(false)
                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];
                        let pagina = parseInt(page)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }
                                setByGroup(itensCopyCollaborator);
                                setPagesOfTheByGroup(arrInfo[0].pages);
                                setLoad(false);
                            }
                            else {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setByGroup(itensCopyCollaborator);
                                setPagesOfTheByGroup(arrInfo[0].pages)
                                setLoad(false)
                            }
                            // => Final de => Se total menor que o limite
                        }
                    }
                }).catch(error => {});
            }
            else {
                await api.get(`/count-collaborators-skill-group/?paginate=true`)
                .then(response => {
                    setLoadPageByGroup(false);
                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];
                    let pagina = parseInt(page);
                    let itensCopyCollaborator = [];
                    if(pagina === arrInfo[0].pages) {
                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setByGroup(itensCopyCollaborator);
                        setPagesOfTheByGroup(arrInfo[0].pages);
                        setLoad(false);
                    }
                    else {
                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        else {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);

                        }
                        // => Final de => Se total menor que o limite
                    }
                }).catch(error => {});
            }
        }
        loadbygrouppagination();
    }

    async function prevPageByGroup() {
        if (pageByGroup === 1) return;
        const pageNumber = pageByGroup - 1;
        setPageByGroup(pageNumber);
        async function loadbygroupprevpage(page = pageNumber) {
            if(nameGroupFilter !== undefined) {
                setLoadPageByGroup(true);
                await api.get(`/count-collaborators-skill-group/?paginate=true&name=${nameGroupFilter}`)
                .then(response => {
                    if(response.data.length === 0) {
                        setByGroup([]);
                        setPagesOfTheByGroup(0);
                        setLoadPageByGroup(false);
                        setPageByGroup(0);
                    }
                    else {
                        setLoadPageByGroup(false)
                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];
                        let pagina = parseInt(page)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setByGroup(itensCopyCollaborator);
                                setPagesOfTheByGroup(arrInfo[0].pages);
                                setLoad(false);
                            }
                            else {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setByGroup(itensCopyCollaborator);
                                setPagesOfTheByGroup(arrInfo[0].pages);
                                setLoad(false);
                            }
                            // => Final de => Se total menor que o limite
                        }
                    }
                }).catch(error => {});
            }
            else {

                await api.get(`/count-collaborators-skill-group/?paginate=true`)
                .then(response => {
                    setLoadPageByGroup(false);
                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];
                    let pagina = parseInt(page);
                    let itensCopyCollaborator = [];
                    if(pagina === arrInfo[0].pages) {
                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setByGroup(itensCopyCollaborator);
                        setPagesOfTheByGroup(arrInfo[0].pages);
                        setLoad(false);
                    }
                    else {
                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        else {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        // => Final de => Se total menor que o limite
                    }
                }).catch(error => {});
            }
        }
        loadbygroupprevpage();
    }

    async function nextPageByGroup(event) {
        if (pageByGroup === byGroup[0].pages) return;
        const pageNumber = pageByGroup + 1;
        setPageByGroup(pageNumber);
        async function loadgroupnextpage(page = pageNumber) {
            if(nameGroupFilter !== undefined) {
                setLoadPageByGroup(true);
                await api.get(`/count-collaborators-skill-group/?paginate=true&name=${nameGroupFilter}`)
                .then(response => {
                    if(response.data.length === 0) {
                        setByGroup([]);
                        setPagesOfTheByGroup(0);
                        setLoadPageByGroup(false);
                        setPageByGroup(0);
                    }
                    else {
                        setLoadPageByGroup(false)
                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];
                        let pagina = parseInt(page)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setByGroup(itensCopyCollaborator);
                                setPagesOfTheByGroup(arrInfo[0].pages);
                                setLoad(false);
                            }
                            else {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setByGroup(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheByGroup(arrInfo[0].pages)
                                // setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                                // setPageByGroup(1)
                            }
                            // => Final de => Se total menor que o limite
                        }
                    }
                }).catch(error => {});
            }
            else {

                await api.get(`/count-collaborators-skill-group/?paginate=true`)
                .then(response => {
                    setLoadPageByGroup(false);
                    const docs_a  = response.data[0];
                    const arrInfo = response.data[1];
                    let pagina = parseInt(page);
                    let itensCopyCollaborator = [];
                    if(pagina === arrInfo[0].pages) {
                        let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                        let final  = arrInfo[0].total;
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setByGroup(itensCopyCollaborator);
                        setPagesOfTheByGroup(arrInfo[0].pages);
                        setLoad(false)
                    }
                    else {

                        // => Se total menor que o limite
                        if(arrInfo[0].total < arrInfo[0].limite) {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);
                        }
                        else {
                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final =  pagina * arrInfo[0].limite
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setByGroup(itensCopyCollaborator);
                            setPagesOfTheByGroup(arrInfo[0].pages);
                            setLoad(false);

                        }
                        // => Final de => Se total menor que o limite
                    }
                }).catch(error => {});
            }
        }
        loadgroupnextpage();
    }

      /////////////////////////////////////////////////////////////////////////////////
     /////// Buscar colaboradores para cadastrar nova habilidade ////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorFilterForThisSkill, setLoadCollaboratorFilterForThisSkill ] = useState(false);
    //collaboratorFilterForThisSkill
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorFilterForThisSkill, setCollaboratorFilterForThisSkill ] = useState([]);
    
    async function loadFilterCollaboratorForThisSkill(event) {
        const name = event.target.value;

        if(name.length <= 1) {
            setCollaboratorFilterForThisSkill([]);
        }
        else {
            setLoadCollaboratorFilterForThisSkill(true)
            await api.get(`/collaborator-new?name=${name}&status=Ativo&role=user&role=manager&role=admin`)
            .then(response => {
                setLoadCollaboratorFilterForThisSkill(false);
                setCollaboratorFilterForThisSkill(response.data.collaboratorSearch);
            }).catch(error => {});
        }
    }
      ///////////////////////////////////////////////////////////////////////////////
     /////// Adicionar colaboradores para cadastrar nova habilidade ////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborators, setCollaborators] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadToAddCollaborator, setLoadToAddCollaborator ] = useState(false);
    async function myChangeHandlerToAddCollaborator(event) {
        let val = event.target.id;
        let arrCol = collaboratorFilterForThisSkill;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setCollaboratorFilterForThisSkill(arrCol);
        setLoadToAddCollaborator(true)
        await api.get(`/collaborator/${val}`)
        .then(response => {
            const itensCopyCollaborators = Array.from(collaborators);
            const searchCollaboratorExists = itensCopyCollaborators.findIndex(objeto => objeto._id === val);
            if(searchCollaboratorExists === -1) {
                itensCopyCollaborators.push({idcollaborator: collaborators.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setCollaborators(itensCopyCollaborators);
            }
            else {
                itensCopyCollaborators.splice(searchCollaboratorExists, 0);
            }
            setLoadToAddCollaborator(false)

        }).catch(error => {});
    }

      ///////////////////////////////////////////////////////////////////////////////
     /////// Exclui colaboradores para cadastrar nova habilidade ///////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadExcludeCollaboratorAdded, setLoadExcludeCollaboratorAdded ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allCollaboratorsExclude, setAllCollaboratorsExclude] = useState([])
    var id_collaborator_excluded = null;
    async function myChangeHandlerExcludeCollaboratorAdded(event) {
        event.preventDefault();
        id_collaborator_excluded = event.target.value;
        setAllCollaboratorsExclude(id_collaborator_excluded)
        if(collaborators && collaborators.length !== 0) {

            setLoadExcludeCollaboratorAdded(true)
            let arrayColaboradores = collaborators;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excluded);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setCollaborators(arrayColaboradores);
            setLoadExcludeCollaboratorAdded(false)
        }

    }

      ///////////////////////////////////////////////////////////////////////////////
     /////////////////////////// Cadastrar habilidade //////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillToSave, setSkillToSave] = useState([])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameCadHabilidade, setLoadNameCadHabilidade] = useState(false);

    async function myChangeHandlerToSaveSkill(event) {
        let nam = event.target.name;
        let val = event.target.value;
        if(val.length === 0) {
            setLoadNameCadHabilidade(true)
            setSkillToSave([])
        }
        else {
            setLoadNameCadHabilidade(false)
            setSkillToSave({ ...skillToSave, [nam]: val, path: path })
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleCastrarHabilidade, setVisibleCastrarHabilidade] = useState(false);
    function CastrarHabilidade() {
        setVisibleCastrarHabilidade(!visibleCastrarHabilidade);
        setLoadNameCadHabilidade(false)
        window.setTimeout(function () {
            document.getElementById('nameHabilidadeCadastrar').focus();
        }, 0);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRegisterNewSkill, setLoadRegisterNewSkill] = useState(false);

    async function saveSkill(event) {
        event.preventDefault();

        if(skillToSave && skillToSave.name !== undefined && skillToSave.name !== "") {
            setLoadPageAllSkill(true);
            if(collaborators && collaborators.length !== 0) {

                setLoadRegisterNewSkill(true)
                await api.post("/skill", skillToSave)
                .then(response => {
                    loadskillNew();
                    let skill = response.data._id;
                    for(let i=0; i < collaborators.length; i++){
                        let collaborator =  collaborators[i]._id;
                        async function atributeskilltocollaborator() {
                            // pre save
                            await api.post('/skill-collaborator/', { collaborator, skill, path: path })
                            .then(response => {
                                setLoadPageAllSkill(false)
                                setCollaboratorFilterForThisSkill([]);
                                // Aqui refresh em inforbox
                                loadskilllost();
                                loadskill();
                                loadskillNew();
                                loadperGroup();
                                loadskillmorecommon();
                                // loadskills();
                                setSkillToSave([]);
                                setCollaborators([]);
                                setLoadRegisterNewSkill(false);
                                setVisibleCastrarHabilidade(!visibleCastrarHabilidade);
                                if(filterMoreCommon === true) {
                                    loadallskillMoreCommon();
                                }
                                else
                                if(filterLessCommon === true) {
                                    loadallskillLessCommon();
                                }
                                else
                                if(filterSkillAlphabeticaOrder === true) {
                                    loadallskillAlphabeticaOrder();
                                }
                                else {
                                    loadallskill()
                                }
                            }).catch(error => {
                                toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                                setLoadRegisterNewSkill(false)
                            });
                        }
                        atributeskilltocollaborator();
                        setTodasHabilidades(true);
                        setPorGrupos(false);
                    }
                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadRegisterNewSkill(false)
                });
            }
            else {
                setLoadRegisterNewSkill(true);
                await api.post("/skill", skillToSave)
                .then(response => {
                    if(filterMoreCommon === true) {
                        loadallskillMoreCommon();
                    }
                    else
                    if(filterLessCommon === true) {
                        loadallskillLessCommon();
                    }
                    else
                    if(filterSkillAlphabeticaOrder === true) {
                        loadallskillAlphabeticaOrder();
                    }
                    else {
                        loadallskill();
                    }

                    setLoadPageAllSkill(false)
                    setCollaboratorFilterForThisSkill([]);
                    // Aqui refresh em inforbox
                    loadskilllost();
                    loadskill();
                    loadskillNew();
                    loadperGroup();
                    loadskillmorecommon();
                    setSkillToSave([]);
                    setCollaborators([]);
                    setLoadRegisterNewSkill(false);
                    setVisibleCastrarHabilidade(!visibleCastrarHabilidade);
                    setTodasHabilidades(true);
                    setPorGrupos(false);

                }).catch(error => {
                    setLoadPageAllSkill(false)
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadRegisterNewSkill(false)
                });
            }
        }
        else {
            setLoadNameCadHabilidade(true)
            setLoadRegisterNewSkill(false)
        }

    }
      /////////////////////////////////////////////////////////////////////////////////
     /////// Buscar colaboradores para cadastrar novo grupo ////////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadCollaboratorFilterForThiGroup, setLoadCollaboratorFilterForThiGroup ] = useState(false);
    //collaboratorFilterForThisSkill
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorFilterForThisGroup, setCollaboratorFilterForThisGroup ] = useState([]);
    async function loadFilterCollaboratorForThisGroup(event) {
        const name = event.target.value;
        if(name === '') {
            setCollaboratorFilterForThisGroup([]);
        }
        else {
            setLoadCollaboratorFilterForThiGroup(true)
            await api.get(`/collaborator-new?name=${name}&role=user&role=manager&role=admin`)
            .then(response => {
                setLoadCollaboratorFilterForThiGroup(false);
                setCollaboratorFilterForThisGroup(response.data[0]);
            }).catch(error => {});
        }
    }

      ///////////////////////////////////////////////////////////////////////////////
     ///////////// Adicionar colaboradores para cadastrar novo grupo ///////////////
    ///////////////////////////////////////////////////////////////////////////////

    //loadNameCadGrupoCollaborator
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameCadGrupoCollaborator, setLoadNameCadGrupoCollaborator] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaboratorsGroup, setCollaboratorsGroup] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadToAddCollaboratorGroup, setLoadToAddCollaboratorGroup ] = useState(false);

    async function myChangeHandlerToAddCollaboratorGroup(event) {
        let val = event.target.id;
        let arrCol = collaboratorFilterForThisGroup;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setCollaboratorFilterForThisGroup(arrCol);
        setLoadToAddCollaboratorGroup(true)
        await api.get(`/collaborator/${val}`)
        .then(response => {
            const itensCopyCollaborators = Array.from(collaboratorsGroup);
            const searchAddCollaboratorExists = itensCopyCollaborators.findIndex(objeto => objeto._id === val);
            if(searchAddCollaboratorExists === -1) {
                itensCopyCollaborators.push({idcollaborator: collaboratorsGroup.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setCollaboratorsGroup(itensCopyCollaborators);
                setLoadToAddCollaboratorGroup(false)
                setLoadNameCadGrupoCollaborator(false)
            }
            else {
                itensCopyCollaborators.splice(searchAddCollaboratorExists, 0);
                setCollaboratorsGroup(itensCopyCollaborators);
                setLoadToAddCollaboratorGroup(false)
                setLoadNameCadGrupoCollaborator(false)
            }
            setCollaboratorFilterForThisGroup([]);
        }).catch(error => {});
    }

      ///////////////////////////////////////////////////////////////////////////////
     /////// Exclui colaboradores para cadastrar novo grupo ///////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadExcludeCollaboratorAddedGroup, setLoadExcludeCollaboratorAddedGroup ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allCollaboratorsExcludeGroup, setAllCollaboratorsExcludeGroup] = useState([])

    var id_collaborator_excluded_group = null;
    async function myChangeHandlerExcludeCollaboratorAddedGroup(event) {
        event.preventDefault();
        id_collaborator_excluded_group = event.target.value;
        setAllCollaboratorsExcludeGroup(id_collaborator_excluded_group)
        if(collaboratorsGroup && collaboratorsGroup.length !== 0) {
            setLoadExcludeCollaboratorAddedGroup(true)
            let arrayColaboradores = collaboratorsGroup;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excluded_group);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setCollaboratorsGroup(arrayColaboradores);
            setLoadExcludeCollaboratorAddedGroup(false)
        }
    }

        /////// =======> HABILIDADES

      /////////////////////////////////////////////////////////////////////////
     /////// Buscar Habilidades para cadastrar novo grupo ////////////////////
    /////////////////////////////////////////////////////////////////////////


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadSkillFilterForThisGroup, setLoadSkillFilterForThisGroup ] = useState(false);

    //collaboratorFilterForThisSkill
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillFilterForThisGroup, setSkillFilterForThisGroup ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameCadGrupoHab, setLoadNameCadGrupoHab] = useState(false);

    async function filterSkillForThisGroup(event) {
        const name = event.target.value;
        if(name === '') {
            setSkillFilterForThisGroup([]);
        }
        else {
            setLoadSkillFilterForThisGroup(true)
            await api.get(`/skill?name=${name}`)
            .then(response => {

                setLoadSkillFilterForThisGroup(false);
                setSkillFilterForThisGroup(response.data);
            }).catch(error => {});
        }

    }

      ///////////////////////////////////////////////////////////////////////////////
     ///////////// Adicionar skills para cadastrar novo grupo ///////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillsGroup, setSkillsGroup] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadToAddSkillTorGroup, setLoadToAddSkillTorGroup ] = useState(false);

    async function myChangeHandlerSkillToAddGroup(event) {
        let val = event.target.id;
        let arrCol = skillFilterForThisGroup;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setSkillFilterForThisGroup(arrCol);
        setLoadToAddSkillTorGroup(true)
        await api.get(`/skill/${val}`)
        .then(response => {
            const itensCopyCollaborators = Array.from(skillsGroup);
            const searchAddCollaboratorExists = itensCopyCollaborators.findIndex(objeto => objeto._id === val);
            if(searchAddCollaboratorExists === -1) {
                itensCopyCollaborators.push({idcollaborator: skillsGroup.length, name: response.data.name, _id : response.data._id });
                setSkillsGroup(itensCopyCollaborators);
                setLoadToAddSkillTorGroup(false)
                setLoadNameCadGrupoHab(false)
                skillFilterForThisGroup(false)
            }
            else {
                itensCopyCollaborators.splice(searchAddCollaboratorExists, 0);
                setLoadToAddSkillTorGroup(false);
                setLoadNameCadGrupoHab(false);
                skillFilterForThisGroup(false);
            }
            setSkillFilterForThisGroup([]);


        }).catch(error => {});
    }

      ///////////////////////////////////////////////////////////////////////////////
     ////////////////// Excluir skills para cadastrar novo grupo ///////////////////
    ///////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadExcludeSkillAddedGroup, setLoadExcludeSkillAddedGroup ] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ allSkillExcludeGroup, setAllSkillExcludeGroup] = useState([])
    var id_skill_excluded_group = null;
    async function myChangeHandlerExcludeSkillAddedGroup(event) {
        event.preventDefault();
        id_skill_excluded_group = event.target.value;
        setAllSkillExcludeGroup(id_skill_excluded_group)
        if(skillsGroup && skillsGroup.length !== 0) {
            setLoadExcludeSkillAddedGroup(true)
            let arraySkills = skillsGroup;
            let indexColSkills = arraySkills.findIndex(objeto => objeto._id === id_skill_excluded_group);
            arraySkills.splice(indexColSkills, 1);
            setSkillsGroup(arraySkills);
            setLoadExcludeSkillAddedGroup(false)
        }

    }

      ///////////////////////////////////////////////////////////////////////////////
     /////////////////////////// Cadastrar Grupo ///////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleCastrarGrupo, setVisibleCastrarGrupo] = useState(false);
    function CastrarGrupo() {
        setVisibleCastrarGrupo(!visibleCastrarGrupo);
        setLoadNameCadGrupo(false)
        setLoadNameCadGrupoHab(false)
        setLoadNameCadGrupoCollaborator(false)
        window.setTimeout(function () {
            document.getElementById('nameGrupoCadastrar').focus();
        }, 0);

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameCadGrupo, setLoadNameCadGrupo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ groupToSave, setGroupToSave] = useState([]);

    async function myChangeHandlerToSaveGroup(event) {
        let nam = event.target.name;
        let val = event.target.value;

        if(val.length === 0) {

            setLoadNameCadGrupo(true)
            setGroupToSave([])
        }
        else {

            setLoadNameCadGrupo(false)
            setGroupToSave({ ...groupToSave, [nam]: val, path: path })
        }



    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadRegisterNewGroup, setLoadRegisterNewGroup] = useState(false);

    async function saveGroup(event) {
        event.preventDefault();

        if(groupToSave && groupToSave.nameGroup === undefined && groupToSave.nameGroup === "") {
            setLoadNameCadGrupo(true)
        }
        if(skillsGroup && skillsGroup.length === 0) {
            setLoadNameCadGrupoHab(true)
        }

        if(collaboratorsGroup && collaboratorsGroup.length === 0) {
            setLoadNameCadGrupoCollaborator(true)
        }

        if(groupToSave && groupToSave.nameGroup !== undefined && groupToSave.nameGroup !== "") {

            if(skillsGroup && skillsGroup.length !== 0 && collaboratorsGroup && collaboratorsGroup.length !== 0) {

                setLoadRegisterNewGroup(true)
                await api.post("/skill-group", groupToSave)
                .then(response => {
                    let skillGroup = response.data._id;
                    setTodasHabilidades(false);
                    setPorGrupos(true);
                    setLoadPageByGroup(true)
                    for(let i=0; i < skillsGroup.length; i++){
                        let skill =  skillsGroup[i]._id;
                        async function atributeskilltogroup() {
                            // pre save
                            await api.post('/skill-of-the-skill-group/', { skill, skillGroup, path: path })
                            .then(response => {
                            }).catch(error => {
                                toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                                setLoadRegisterNewGroup(false)
                            });
                        }
                        atributeskilltogroup();
                    }

                    setLoadNameCadGrupoHab(false)

                    for(let i=0; i < collaboratorsGroup.length; i++){

                        let collaborator =  collaboratorsGroup[i]._id;

                        async function atributecollaboratortogroup() {
                            await api.post('/collaborator-of-the-skill-group/', { collaborator, skillGroup, path: path })
                            .then(response => {
                            }).catch(error => {
                                toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                                setLoadRegisterNewGroup(false)
                            });
                        }
                        atributecollaboratortogroup();
                    }
                    setLoadNameCadGrupoCollaborator(false)
                    setCollaboratorFilterForThisGroup([]);
                    setCollaboratorsGroup([]);
                    setSkillFilterForThisGroup([]);
                    setSkillsGroup([])
                    setGroupToSave([])
                    setLoadRegisterNewGroup(false);
                    setVisibleCastrarGrupo(!visibleCastrarGrupo);
                    loadperGroup();

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadRegisterNewGroup(false)
                });

            }
            else {

                if(skillsGroup && skillsGroup.length === 0) {
                    setLoadNameCadGrupoHab(true)
                }
                else
                if(collaboratorsGroup && collaboratorsGroup.length === 0) {
                    setLoadNameCadGrupoCollaborator(true)
                }
                else {

                }
            }

        }
        else {
            setLoadNameCadGrupo(true)
            setLoadRegisterNewSkill(false)
            setLoadRegisterNewGroup(false)
        }

    }

      ///////////////////////////////////////////////////////////////////////////////////////////
     /////////////////////////     Editar Habilidades     //////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleEditarHabilidade, setVisibleEditarHabilidade] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showCollaboratorsavedInTheSkill, setShowCollaboratorsavedInTheSkill] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showCollaboratorDeleteToInTheSkill, setShowCollaboratorDeleteToInTheSkill] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadShowCollaboratorInTheSkill, setLoadShowCollaboratorInTheSkill] = useState(false);

    async function EditarHabilidade(e) {

        setLoadShowCollaboratorInTheSkill(true)
        setVisibleEditarHabilidade(!visibleEditarHabilidade);
        window.setTimeout(function () {
            document.getElementById('nameEditarHabilidade').focus();
        }, 0);

        if(e && e !== undefined) {
            setShowSkill(e)
            await api.get(`collaborators-in-the-skill?skill=${e.skill}`)
            .then(response => {

                let itensCopyCollaborators = Array.from(showCollaboratorsavedInTheSkill);

                for(let i=0; i < response.data.length; i++) {

                    let idCollaborator = i;
                    let avatar         = response.data[i].collaborator.avatar;
                    let collaborator   = response.data[i].collaborator._id;
                    let name           = response.data[i].collaborator.name;
                    let occupation     = response.data[i].collaborator.occupation;
                    let _id            = response.data[i]._id;

                    itensCopyCollaborators.push({
                        idcollaborator :idCollaborator,
                        avatar: avatar,
                        collaborator: collaborator,
                        name: name,
                        occupation: occupation,
                        _id : _id
                    });

                }
                setShowCollaboratorsavedInTheSkill(itensCopyCollaborators);
                setLoadShowCollaboratorInTheSkill(false)
            }).catch(error => {setLoadShowCollaboratorInTheSkill(false)});
        }
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameEditHabilidade, setLoadNameEditHabilidade] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleVerHabilidade, setVisibleVerHabilidade] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showSkill, setShowSkill] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showCollaboratorInTheSkill, setShowCollaboratorInTheSkill] = useState([]);

    async function VerHabilidade(e) {
        setShowCollaboratorInTheSkill("");
        setVisibleVerHabilidade(!visibleVerHabilidade);
        if(e && e !== undefined) {
            setShowSkill(e)
            setLoadShowCollaboratorInTheSkill(true)
            await api.get(`collaborators-in-the-skill?skill=${e.skill}`)
            .then(response => {
                setShowCollaboratorInTheSkill(response.data);
                setLoadShowCollaboratorInTheSkill(false)
            }).catch(error => {});
        }
    }

    //NaoVerHabilidade
    function NaoVerHabilidade() {
        setVisibleVerHabilidade(!visibleVerHabilidade);
        setShowSkill([]);
    }

    function NaoEditarHabilidade() {
        setVisibleEditarHabilidade(!visibleEditarHabilidade);
        setShowSkill([]);
        setCountCollaboratorSavedInTheSkill([]);
        setShowCollaboratorsavedInTheSkill([]);
        setCollaboratorFilterForThisSkill([]);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countCollaboratorSavedInTheSkill, setCountCollaboratorSavedInTheSkill] = useState([]);

    async function myChangeHandlerToAddCollaboratorEditSkill(event) {

       let val = event.target.id;

       let arrCol = collaboratorFilterForThisSkill;
       let indexCol = arrCol.findIndex(objeto => objeto._id === val);
       arrCol.splice(indexCol, 1);
       setCollaboratorFilterForThisSkill(arrCol);
       setLoadToAddCollaborator(true)

       await api.get(`/collaborator/${val}`)
       .then(response => {

        const itensCopyCollaborators = Array.from(showCollaboratorsavedInTheSkill);

        const searchEditCollaboratorExists = itensCopyCollaborators.findIndex(objeto => objeto.collaborator === val);

        const searchNameEditCollaboratorExists = itensCopyCollaborators.findIndex(objeto => objeto.name === response.data.name);


        if(searchEditCollaboratorExists === -1 && searchNameEditCollaboratorExists === -1) {
            itensCopyCollaborators.push({idcollaborator: showCollaboratorsavedInTheSkill.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
            setShowCollaboratorsavedInTheSkill(itensCopyCollaborators);
        }
        else {
            itensCopyCollaborators.splice(searchEditCollaboratorExists, 0);
        }

        const itensCountCollaborators = Array.from(countCollaboratorSavedInTheSkill);

        const searchCountEditCollaboratorExists = itensCopyCollaborators.findIndex(objeto => objeto.collaborator === val);

        if(searchCountEditCollaboratorExists === -1 && searchNameEditCollaboratorExists === -1) {
            itensCountCollaborators.push({idcollaborator: showCollaboratorsavedInTheSkill.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
            setCountCollaboratorSavedInTheSkill(itensCountCollaborators)
        }
        else {
            itensCountCollaborators.splice(searchCountEditCollaboratorExists, 0);
        }

        setLoadToAddCollaborator(false)
        setCollaboratorFilterForThisSkill([]);

       }).catch(error => {});

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collExcluido, setCollExcluido] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setAllCollaboratorsExcludedSkill] = useState([])

    // eslint-disable-next-line no-redeclare
    var id_collaborator_excluded_group = null;
    async function myChangeHandlerExcludeCollaboratorAddedEditInTheSkill(event) {
        event.preventDefault();
        ////impeditivo
        id_collaborator_excluded_group = event.target.value;
        setAllCollaboratorsExcludedSkill(id_collaborator_excluded_group)

        if(showCollaboratorsavedInTheSkill && showCollaboratorsavedInTheSkill.length !== 0) {
            let arrayColaboradores = showCollaboratorsavedInTheSkill;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excluded_group);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setShowCollaboratorsavedInTheSkill(arrayColaboradores);
            const itensCountCollaboratorsDelete = Array.from(showCollaboratorDeleteToInTheSkill);
            itensCountCollaboratorsDelete.push({idcollaborator: showCollaboratorDeleteToInTheSkill.length, _id : id_collaborator_excluded_group });
            setShowCollaboratorDeleteToInTheSkill(itensCountCollaboratorsDelete)
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillToEdit, setSkillToEdit] = useState([])

    async function myChangeHandlerToEditSkill(event) {
        let nam = event.target.name;
        let val = event.target.value;

        let id = showSkill.skill;

        if(val.length === 0) {

            setLoadNameEditHabilidade(true)
            setSkillToEdit([])
        }
        else {

            setLoadNameEditHabilidade(false)
            setSkillToEdit({ ...skillToEdit, [nam]: val, id, path: path })
        }



    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadUpdateSkill, setLoadUpdateSkill] = useState(false);

    async function updateSkill(event) {
        event.preventDefault();

        if(skillToEdit && skillToEdit.name !== undefined && skillToEdit.name !== "") {
                setLoadUpdateSkill(true)
                setLoadPageAllSkill(true)
                await api.put(`/skill/${skillToEdit.id}`, skillToEdit)
                .then(response => {

                    let skill = response.data._id;

                    if(countCollaboratorSavedInTheSkill && countCollaboratorSavedInTheSkill.length !== 0) {

                        for(let i=0; i < countCollaboratorSavedInTheSkill.length; i++){

                            let collaborator =  countCollaboratorSavedInTheSkill[i]._id;

                            async function atributeskilltocollaborator() {

                                await api.post('/skill-collaborator/', { collaborator, skill, path: path })
                                .then(response => {})
                                .catch(error => {
                                    setLoadUpdateSkill(false)
                                });
                            }
                            atributeskilltocollaborator();
                            setTodasHabilidades(true);
                            setPorGrupos(false);

                        }

                    }
                    if(showCollaboratorDeleteToInTheSkill && showCollaboratorDeleteToInTheSkill.length !== 0) {

                        for(let i=0; i < showCollaboratorDeleteToInTheSkill.length; i++){

                            let id_collaborator_excluded_group =  showCollaboratorDeleteToInTheSkill[i]._id;

                            async function deleteskilltocollaborator() {

                                await api.delete(`/skill-collaborator/${id_collaborator_excluded_group}?path=${path}`)
                                .then(response => {

                                    if(response.data !== undefined) {

                                        if(
                                            showCollaboratorsavedInTheSkill &&
                                            showCollaboratorsavedInTheSkill.length === 0
                                        ) {

                                            if(filterMoreCommon === true) {
                                                loadallskillMoreCommon();
                                                setLoadPageAllSkill(false)
                                            }else if(filterLessCommon === true){
                                                loadallskillLessCommon();
                                                setLoadPageAllSkill(false)
                                            }else if(filterSkillAlphabeticaOrder === true){
                                                loadallskillAlphabeticaOrder();
                                                setLoadPageAllSkill(false)
                                            } else {
                                                loadallskill()
                                                setLoadPageAllSkill(false)

                                                let name = response.data.skill.name;

                                                async function saveSkillLost() {

                                                    await api.post("/skill-lost", { name, path: path })
                                                    .then(response => {

                                                        loadallskill();
                                                        setLoadPageAllSkill(false);
                                                        loadskilllost();
                                                        loadskill();
                                                        loadskillNew();
                                                        loadperGroup();
                                                        loadskillmorecommon();

                                                    }).catch(error => {});

                                                }
                                                saveSkillLost()
                                            }
                                            setCollExcluido(true)

                                        }

                                    }


                                }).catch(error => {});
                            }
                            deleteskilltocollaborator();
                            setTodasHabilidades(true);
                            setPorGrupos(false);

                        }
                    }

                    if(filterMoreCommon === true) {

                        loadallskillMoreCommon();

                    }
                    else
                    if(filterLessCommon === true) {

                        loadallskillLessCommon();

                    }
                    else
                    if(filterSkillAlphabeticaOrder === true) {

                        loadallskillAlphabeticaOrder();

                    }
                    else {

                        loadallskill()

                    }

                    setCollaboratorFilterForThisSkill([]);
                    setShowCollaboratorDeleteToInTheSkill([])

                    // Aqui refresh em inforbox
                    loadskill();
                    loadskillmorecommon();
                    loadskillNew();

                    setSkillToSave([]);
                    setCollaborators([]);
                    setLoadRegisterNewSkill(false);
                    setTodasHabilidades(true);
                    setPorGrupos(false);
                    setSkillToEdit([]);
                    setShowCollaboratorsavedInTheSkill([]);
                    setLoadUpdateSkill(false);
                    setShowSkill([]);
                    setVisibleEditarHabilidade(!visibleEditarHabilidade);
                    setTodasHabilidades(true);
                    setPorGrupos(false);
                    setCountCollaboratorSavedInTheSkill([])

                }).catch(error => {

                    setLoadUpdateSkill(false);

                });

        }
        else
        if(
            countCollaboratorSavedInTheSkill && countCollaboratorSavedInTheSkill.length !== 0
            &&
            showCollaboratorDeleteToInTheSkill && showCollaboratorDeleteToInTheSkill.length !== 0
        ) {

            let skill = showSkill.skill;

            setLoadPageAllSkill(true)
            for(let i=0; i < countCollaboratorSavedInTheSkill.length; i++){

                let collaborator =  countCollaboratorSavedInTheSkill[i]._id;

                async function atributeskilltocollaborator() {

                    await api.post('/skill-collaborator/', { collaborator, skill, path: path })
                    .then(response => {

                        if(filterMoreCommon === true) {

                            loadallskillMoreCommon();
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();
                        }
                        else
                        if(filterLessCommon === true) {

                            loadallskillLessCommon();
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();

                        }
                        else
                        if(filterSkillAlphabeticaOrder === true) {

                            loadallskillAlphabeticaOrder();
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();

                        }
                        else {

                            loadallskill()
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();

                        }

                        setCollaboratorFilterForThisSkill([]);
                        // Aqui refresh em inforbox
                        loadskill();
                        loadskillmorecommon();
                        loadskillNew();

                        setSkillToSave([]);
                        setCollaborators([]);
                        setLoadRegisterNewSkill(false);
                        setTodasHabilidades(true);
                        setPorGrupos(false);
                        setSkillToEdit([]);
                        setShowCollaboratorsavedInTheSkill([]);
                        setLoadUpdateSkill(false);
                        setShowSkill([]);
                        setVisibleEditarHabilidade(!visibleEditarHabilidade);
                        setTodasHabilidades(true);
                        setPorGrupos(false);

                    }).catch(error => {

                        // toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                        setLoadUpdateSkill(false)
                    });
                }
                atributeskilltocollaborator();

            }
            setCountCollaboratorSavedInTheSkill([]);

            for(let i=0; i < showCollaboratorDeleteToInTheSkill.length; i++){

                let id_collaborator_excluded_group =  showCollaboratorDeleteToInTheSkill[i]._id;

                async function deleteskilltocollaborator() {

                    await api.delete(`/skill-collaborator/${id_collaborator_excluded_group}?path=${path}`)
                    .then(response => {

                        if(response.data !== undefined) {

                            if(
                                showCollaboratorsavedInTheSkill &&
                                showCollaboratorsavedInTheSkill.length === 0
                            ) {

                                if(filterMoreCommon === true) {
                                    loadallskillMoreCommon();
                                    setLoadPageAllSkill(false)
                                }else if(filterLessCommon === true){
                                    loadallskillLessCommon();
                                    setLoadPageAllSkill(false)
                                }else if(filterSkillAlphabeticaOrder === true){
                                    loadallskillAlphabeticaOrder();
                                    setLoadPageAllSkill(false)
                                } else {
                                    loadallskill()
                                    setLoadPageAllSkill(false)

                                    let name = response.data.skill.name;

                                    async function saveSkillLost() {

                                        await api.post("/skill-lost", { name, path: path })
                                        .then(response => {

                                            loadallskill();
                                            setLoadPageAllSkill(false);
                                            loadskilllost();
                                            loadskill();
                                            loadskillNew();
                                            loadperGroup();
                                            loadskillmorecommon();

                                        }).catch(error => {});

                                    }
                                    saveSkillLost()
                                }
                                setCollExcluido(true)

                            }
                            else {

                                if(filterSkilMoreCommon === true) {
                                    loadallskillMoreCommon();
                                    setLoadPageAllSkill(false)
                                }else if(filterSkilLessCommon === true){
                                    loadallskillLessCommon();
                                    setLoadPageAllSkill(false)
                                }else if(loadallskillAlphabeticaOrder === true){
                                    loadallskillAlphabeticaOrder();
                                    setLoadPageAllSkill(false)
                                } else {

                                    loadallskill()
                                    setLoadPageAllSkill(false)
                                    loadskilllost();
                                    loadskill();
                                    loadskillNew();
                                    loadperGroup();
                                    loadskillmorecommon();
                                }
                                setCollExcluido(true)

                            }

                        }


                    }).catch(error => {});
                }
                deleteskilltocollaborator();
                setTodasHabilidades(true);
                setPorGrupos(false);

            }

        }
        else
        if(countCollaboratorSavedInTheSkill && countCollaboratorSavedInTheSkill.length !== 0) {

            let skill = showSkill.skill;

            setLoadPageAllSkill(true)
            for(let i=0; i < countCollaboratorSavedInTheSkill.length; i++){

                let collaborator =  countCollaboratorSavedInTheSkill[i]._id;

                async function atributeskilltocollaborator() {

                    await api.post('/skill-collaborator/', { collaborator, skill, path: path })
                    .then(response => {

                        if(filterMoreCommon === true) {

                            loadallskillMoreCommon();
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();
                        }
                        else
                        if(filterLessCommon === true) {

                            loadallskillLessCommon();
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();

                        }
                        else
                        if(filterSkillAlphabeticaOrder === true) {

                            loadallskillAlphabeticaOrder();
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();

                        }
                        else {

                            loadallskill()
                            setLoadPageAllSkill(false)
                            loadskilllost();
                            loadskill();
                            loadskillNew();
                            loadperGroup();

                        }

                        setCollaboratorFilterForThisSkill([]);
                        // Aqui refresh em inforbox
                        loadskill();
                        loadskillmorecommon();
                        loadskillNew();

                        setSkillToSave([]);
                        setCollaborators([]);
                        setLoadRegisterNewSkill(false);
                        setTodasHabilidades(true);
                        setPorGrupos(false);
                        setSkillToEdit([]);
                        setShowCollaboratorsavedInTheSkill([]);
                        setLoadUpdateSkill(false);
                        setShowSkill([]);
                        setVisibleEditarHabilidade(!visibleEditarHabilidade);
                        setTodasHabilidades(true);
                        setPorGrupos(false);

                    }).catch(error => {

                        setLoadUpdateSkill(false)
                    });
                }
                atributeskilltocollaborator();

            }
            setCountCollaboratorSavedInTheSkill([]);

        }
        else
        if(showCollaboratorDeleteToInTheSkill && showCollaboratorDeleteToInTheSkill.length !== 0) {

            for(let i=0; i < showCollaboratorDeleteToInTheSkill.length; i++){

                let id_collaborator_excluded_group =  showCollaboratorDeleteToInTheSkill[i]._id;

                async function deleteskilltocollaborator() {

                    await api.delete(`/skill-collaborator/${id_collaborator_excluded_group}?path=${path}`)
                    .then(response => {

                        if(response.data !== undefined) {

                            if(
                                showCollaboratorsavedInTheSkill &&
                                showCollaboratorsavedInTheSkill.length === 0
                            ) {

                                if(filterMoreCommon === true) {
                                    loadallskillMoreCommon();
                                    setLoadPageAllSkill(false)
                                }else if(filterLessCommon === true){
                                    loadallskillLessCommon();
                                    setLoadPageAllSkill(false)
                                }else if(filterSkillAlphabeticaOrder === true){
                                    loadallskillAlphabeticaOrder();
                                    setLoadPageAllSkill(false)
                                } else {
                                    loadallskill()
                                    setLoadPageAllSkill(false)

                                    let name = response.data.skill.name;

                                    async function saveSkillLost() {

                                        await api.post("/skill-lost", { name, path: path })
                                        .then(response => {

                                            loadallskill();
                                            setLoadPageAllSkill(false);
                                            loadskilllost();
                                            loadskill();
                                            loadskillNew();
                                            loadperGroup();
                                            loadskillmorecommon();

                                        }).catch(error => {});

                                    }
                                    saveSkillLost()
                                }
                                setCollExcluido(true)

                            }
                            else {

                                if(filterSkilMoreCommon === true) {
                                    loadallskillMoreCommon();
                                    setLoadPageAllSkill(false)
                                }else if(filterSkilLessCommon === true){
                                    loadallskillLessCommon();
                                    setLoadPageAllSkill(false)
                                }else if(loadallskillAlphabeticaOrder === true){
                                    loadallskillAlphabeticaOrder();
                                    setLoadPageAllSkill(false)
                                } else {

                                    loadallskill()
                                    setLoadPageAllSkill(false)
                                    loadskilllost();
                                    loadskill();
                                    loadskillNew();
                                    loadperGroup();
                                    loadskillmorecommon();
                                }
                                setCollExcluido(true)

                            }

                        }


                    }).catch(error => {});
                }
                deleteskilltocollaborator();
                setTodasHabilidades(true);
                setPorGrupos(false);

            }

            setCollaboratorFilterForThisSkill([]);
            // Aqui refresh em inforbox
            loadskill();
            loadskillmorecommon();
            loadskillNew();

            setSkillToSave([]);
            setCollaborators([]);
            setLoadRegisterNewSkill(false);
            setTodasHabilidades(true);
            setPorGrupos(false);

            setSkillToEdit([]);
            setShowCollaboratorsavedInTheSkill([]);
            setLoadUpdateSkill(false);
            setVisibleEditarHabilidade(!visibleEditarHabilidade);
            setShowSkill([]);

            setShowCollaboratorDeleteToInTheSkill([])

        }
        else {


            if(collExcluido === true) {
                setVisibleEditarHabilidade(!visibleEditarHabilidade);
                setCollExcluido(false)
                setLoadUpdateSkill(false);

                if(filterMoreCommon === true) {

                    loadallskillMoreCommon();
                    setLoadPageAllSkill(false)
                    loadskilllost();
                    loadskill();
                    loadskillNew();
                    loadperGroup();
                }
                else
                if(filterLessCommon === true) {

                    loadallskillLessCommon();
                    setLoadPageAllSkill(false)
                    loadskilllost();
                    loadskill();
                    loadskillNew();
                    loadperGroup();

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    loadallskillAlphabeticaOrder();
                    setLoadPageAllSkill(false)
                    loadskilllost();
                    loadskill();
                    loadskillNew();
                    loadperGroup();

                }
                else {

                    loadallskill()
                    setLoadPageAllSkill(false)
                    loadskilllost();
                    loadskill();
                    loadskillNew();
                    loadperGroup();

                }

            } else {

                swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo." });
                setLoadUpdateSkill(false);

            }
        }

    }

      ///////////////////////////////////////////////////////////////////////////////////////////
     /////////////////////////////     Editar Grupos    ////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleVerGrupo, setVisibleVerGrupo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showGroup, setShowGroup] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showCollaboratorInGroup, setShowCollaboratorInGroup] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showSkillInGroup, setShowSkillInGroup] = useState([]);

    function VerGrupo(e) {

        if(e && e!== undefined) {

            setShowGroup(e)

            async function skilloftheskillgroup() {

                await api.get(`/skill-of-the-skill-group/?skillGroup=${e._id}`)
                .then(response => {

                    setShowSkillInGroup(response.data);

                }).catch(error => {});

            }
            skilloftheskillgroup()

            async function collaboratoroftheskillgroup() {

                await api.get(`collaborator-of-the-skill-group/?skillGroup=${e._id}`)
                .then(response => {

                    let itensCopyCollaborators = Array.from(showCollaboratorInGroup);

                    for(let i=0; i < response.data.length; i++) {

                        let idCollaborator = i;
                        let avatar         = response.data[i].collaborator.avatar;
                        let name           = response.data[i].collaborator.name;
                        let occupation     = response.data[i].collaborator.occupation;
                        let _id            = response.data[i]._id;

                        itensCopyCollaborators.push({
                            idcollaborator :idCollaborator,
                            avatar: avatar,
                            name: name,
                            occupation: occupation,
                            _id : _id
                        });


                    }

                    setShowCollaboratorInGroup(itensCopyCollaborators);

                }).catch(error => {});

            }
            collaboratoroftheskillgroup()


        }

        setVisibleVerGrupo(!visibleVerGrupo);
    }

    function NaoVerGrupo() {
        setVisibleVerGrupo(!visibleVerGrupo);
        setShowCollaboratorInGroup([])
    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleEditrupo, setVisibleEditGrupo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [showCollaboratorSavedInGroup, setShowCollaboratorSavedInGroup] = useState([]);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ showSkillEditInGroup, setShowSkillEditInGroup ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadShowCollaboratorSavedInGroup, setLoadShowCollaboratorSavedInGroup] = useState([]);

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ loadShowSkillEditInGroup, setloadShowSkillEditInGroup ] = useState(false);

    function EditarGrupo(e) {
        setloadShowSkillEditInGroup(true)
        setLoadShowCollaboratorSavedInGroup(true)
        setVisibleEditGrupo(!visibleEditrupo);
        window.setTimeout(function () {
            document.getElementById('nameEditarGrupo').focus();
        }, 0);
        if(e && e !== undefined) {
            setShowGroup(e)

            async function skilloftheskillgroup() {

                await api.get(`/skill-of-the-skill-group/?skillGroup=${e._id}`)
                .then(response => {


                    let itensCopyCollaborators = Array.from(showSkillEditInGroup);

                    for(let i=0; i < response.data.length; i++) {

                        let idCollaborator = i;
                        let name           = response.data[i].skill.name;
                        let _id            = response.data[i]._id;

                        itensCopyCollaborators.push({
                            idcollaborator: idCollaborator,
                            name: name,
                            _id : _id
                        });

                        setLoadToAddSkillToEditGroup(false)
                        setLoadNameCadGrupoHab(false)

                        setloadShowSkillEditInGroup(false)


                    }

                    setShowSkillEditInGroup(itensCopyCollaborators);

                }).catch(error => {});

            }
            skilloftheskillgroup()


            async function collaboratoroftheskillgroup() {

                await api.get(`collaborator-of-the-skill-group/?skillGroup=${e._id}`)
                .then(response => {

                    let itensCopyCollaborators = Array.from(showCollaboratorSavedInGroup);

                    for(let i=0; i < response.data.length; i++) {

                        let idCollaborator = i;
                        let avatar         = response.data[i].collaborator.avatar;
                        let collaborator   = response.data[i].collaborator._id;
                        let name           = response.data[i].collaborator.name;
                        let occupation     = response.data[i].collaborator.occupation;
                        let _id            = response.data[i]._id;

                        itensCopyCollaborators.push({
                            idcollaborator :idCollaborator,
                            avatar: avatar,
                            collaborator: collaborator,
                            name: name,
                            occupation: occupation,
                            _id : _id
                        });


                    }

                    setShowCollaboratorSavedInGroup(itensCopyCollaborators);

                    setLoadShowCollaboratorSavedInGroup(false)

                }).catch(error => {});

            }
            collaboratoroftheskillgroup()
            setloadShowSkillEditInGroup(false)


        }

    }

    function NaoEditarGrupo() {
        setVisibleEditGrupo(!visibleEditrupo);
        setShowSkillEditInGroup([]);
        setShowCollaboratorSavedInGroup([]);
        setLoadSkillFilterForThisGroup([])

    }


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameEditGrupo, setLoadNameEditGrupo] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadNameEditGrupoHab ] = useState(false);

    // eslint-disable-next-line no-redeclare
    async function loadFilterCollaboratorForThisGroup(event) {
        const name = event.target.value;

        if(name.length <= 1) {
            setCollaboratorFilterForThisGroup([]);
        }
        else {
            //setLoadCollaboratorFilterForThisGroup(true)
            await api.get(`/collaborator-new?name=${name}&role=user&role=manager&role=admin&status=Ativo`)
            .then(response => {
                setCollaboratorFilterForThisGroup(response.data.collaboratorSearch);

            }).catch(error => {});
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countCollaboratorSavedInTheGroup, setCountCollaboratorSavedInTheGroup] = useState([]);


    async function myChangeHandlerToAddCollaboratorEditGroup(event) {

       let val = event.target.id;

       let arrCol = collaboratorFilterForThisGroup;
       let indexCol = arrCol.findIndex(objeto => objeto._id === val);
       arrCol.splice(indexCol, 1);
       setCollaboratorFilterForThisGroup(arrCol);
       //setLoadToAddCollaborator(true)
       await api.get(`/collaborator/${val}`)
       .then(response => {

            const itensCopyCollaborators = Array.from(showCollaboratorSavedInGroup);

            const searchEditCollaboratorSavedInGroupExists =
            itensCopyCollaborators.findIndex(objeto => objeto.collaborator === val);

            const searchNameEditCollaboratorSavedInGroupExists =
            itensCopyCollaborators.findIndex(objeto => objeto.name === response.data.name);

            if(searchEditCollaboratorSavedInGroupExists === -1 && searchNameEditCollaboratorSavedInGroupExists === -1) {
                itensCopyCollaborators.push({idcollaborator: showCollaboratorSavedInGroup.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setShowCollaboratorSavedInGroup(itensCopyCollaborators);
            }
            else {
                itensCopyCollaborators.splice(searchEditCollaboratorSavedInGroupExists, 0);

            }

            const itensCountCollaborators = Array.from(countCollaboratorSavedInTheGroup);


            const searchCountEditCollaboratorSavedInGroupExists =
            itensCopyCollaborators.findIndex(objeto => objeto.collaborator === val);

            if(searchCountEditCollaboratorSavedInGroupExists === -1 && searchNameEditCollaboratorSavedInGroupExists === -1) {
                itensCountCollaborators.push({idcollaborator: showCollaboratorSavedInGroup.length, avatar: response.data.avatar, name: response.data.name, occupation: response.data.occupation, _id : response.data._id });
                setCountCollaboratorSavedInTheGroup(itensCountCollaborators)
            }
            else {
                itensCountCollaborators.splice(searchCountEditCollaboratorSavedInGroupExists, 0);
            }
            setCollaboratorFilterForThisGroup([]);


       }).catch(error => {});

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setAllCollaboratorsExcludedGroup] = useState([])

    // eslint-disable-next-line no-redeclare
    var id_collaborator_excluded_group = null;
    async function myChangeHandlerExcludeCollaboratorAddedEditInTheGroup(event) {
        event.preventDefault();

        id_collaborator_excluded_group = event.target.value;
        setAllCollaboratorsExcludedGroup(id_collaborator_excluded_group)

        if(showCollaboratorSavedInGroup && showCollaboratorSavedInGroup.length !== 0) {

            //setLoadExcludeCollaboratorAddedGroup(true)
            let arrayColaboradores = showCollaboratorSavedInGroup;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_collaborator_excluded_group);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setShowCollaboratorSavedInGroup(arrayColaboradores);
            //setLoadExcludeCollaboratorAddedGroup(false)

            await api.delete(`/collaborator-of-the-skill-group/${id_collaborator_excluded_group}?path=${path}`)
            .then(response => {

                loadperGroup();

            }).catch(error => {
                //swal({ icon: "error", title: "Erro!", text: "Impossível deletar colaborador." });
            });


        } else {

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setSkillExcludedGroup] = useState([])

    // eslint-disable-next-line no-redeclare
    var id_skill_excluded_group = null;
    async function myChangeHandlerExcludeSkillAddedEditInTheGroup(event) {
        event.preventDefault();

        id_skill_excluded_group = event.target.value;
        setSkillExcludedGroup(id_skill_excluded_group)

        if(showSkillEditInGroup && showSkillEditInGroup.length !== 0) {

            let arrayColaboradores = showSkillEditInGroup;
            let indexColColaboradores = arrayColaboradores.findIndex(objeto => objeto._id === id_skill_excluded_group);
            arrayColaboradores.splice(indexColColaboradores, 1);
            setShowSkillEditInGroup(arrayColaboradores);

            await api.delete(`/skill-of-the-skill-group/${id_skill_excluded_group}`)
            .then(response => {

                loadperGroup()

            }).catch(error => {
                //swal({ icon: "error", title: "Erro!", text: "Impossível deletar colaborador." });
            });


        } else {

        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ , setLoadToAddSkillToEditGroup ] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countSkillSavedInTheGroup, setCountSkillSavedInTheGroup] = useState([]);

    async function myChangeHandlerToAddSkillToEditGroup(event) {

        let val = event.target.id;

        let arrCol = skillFilterForThisGroup;
        let indexCol = arrCol.findIndex(objeto => objeto._id === val);
        arrCol.splice(indexCol, 1);
        setSkillFilterForThisGroup(arrCol);
        setLoadToAddSkillToEditGroup(true)
        await api.get(`/skill/${val}`)
        .then(response => {

            const itensCopyCollaborators = Array.from(showSkillEditInGroup);

            const searchshowSkillEditInGroup =
            itensCopyCollaborators.findIndex(objeto => objeto.name === response.data.name);

            if(searchshowSkillEditInGroup === -1) {
                itensCopyCollaborators.push({idcollaborator: showSkillEditInGroup.length, name: response.data.name, _id : response.data._id });
                setShowSkillEditInGroup(itensCopyCollaborators);
            }
            else {
                itensCopyCollaborators.splice(searchshowSkillEditInGroup, 0);
            }

            const itensCountSkills = Array.from(countSkillSavedInTheGroup);

            if(searchshowSkillEditInGroup === -1) {
                itensCountSkills.push({idcollaborator: showSkillEditInGroup.length, name: response.data.name, _id : response.data._id });
                setCountSkillSavedInTheGroup(itensCountSkills)
            }
            else {
                itensCopyCollaborators.splice(searchshowSkillEditInGroup, 0);
            }

            setLoadToAddSkillToEditGroup(false)
            setLoadNameCadGrupoHab(false)
            setSkillFilterForThisGroup([]);

        }).catch(error => {});
    }

     // eslint-disable-next-line react-hooks/rules-of-hooks
     const [ groupToEdit, setGroupToEdit] = useState([])

     async function myChangeHandlerToEditGroup(event) {
         let nam = event.target.name;
         let val = event.target.value;

         let id = showGroup._id;

         if(val.length === 0) {

             setLoadNameEditGrupo(true)
             setGroupToEdit([])
         }
         else {

             setLoadNameEditGrupo(false)
             setGroupToEdit({ ...groupToEdit, [nam]: val, id })
         }

     }

     // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadUpdateGroup, setLoadUpdateGroup] = useState(false);

    async function updateGroup(event) {
        event.preventDefault();

        if(groupToEdit && groupToEdit.nameGroup !== undefined && groupToEdit.nameGroup !== "") {

                setLoadUpdateGroup(true)
                setLoadPageByGroup(true)
                await api.put(`/skill-group/${groupToEdit.id}`, groupToEdit)
                .then(response => {

                    let skillGroup = response.data._id;

                    if(countCollaboratorSavedInTheGroup && countCollaboratorSavedInTheGroup.length !== 0) {

                        for(let i=0; i < countCollaboratorSavedInTheGroup.length; i++){

                            let collaborator =  countCollaboratorSavedInTheGroup[i]._id;

                            async function atributecollaboratortogroup() {

                                // pre save
                                await api.post('/collaborator-of-the-skill-group/', { collaborator, skillGroup, path: path })
                                .then(response => {

                                    loadperGroup();

                                }).catch(error => {
                                    //swal({ icon: "error", title: "Erro!", text: "Erro ao criar skill para este collaborador!" });
                                    toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                                    setLoadUpdateGroup(false)
                                });
                            }
                            atributecollaboratortogroup();
                            setTodasHabilidades(false);
                            setPorGrupos(true);

                        }

                    }

                    if(countSkillSavedInTheGroup && countSkillSavedInTheGroup.length !== 0) {

                        for(let i=0; i < countSkillSavedInTheGroup.length; i++){

                            let skill =  countSkillSavedInTheGroup[i]._id;

                            async function atributeskilltogroup() {

                                // pre save
                                await api.post('/skill-of-the-skill-group', { skill, skillGroup, path: path })
                                .then(response => {

                                    loadperGroup();

                                }).catch(error => {
                                    toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                                    setLoadUpdateGroup(false)
                                });
                            }
                            atributeskilltogroup();
                            setTodasHabilidades(false);
                            setPorGrupos(true);

                        }

                    }
                    loadperGroup();
                    setGroupToEdit([]);
                    setShowCollaboratorSavedInGroup([]);
                    setLoadUpdateGroup(false);
                    setVisibleEditGrupo(!visibleEditrupo);
                    setShowSkillEditInGroup([]);
                    setCountCollaboratorSavedInTheGroup([]);
                    setCountSkillSavedInTheGroup([]);
                    setSkillFilterForThisGroup([]);
                    setLoadPageByGroup(false)

                }).catch(error => {
                    swal({ icon: "error", title: "Erro!", text: error.response.data.message });
                    setLoadUpdateGroup(false);
                });

        }
        else
        if(
            countSkillSavedInTheGroup && countSkillSavedInTheGroup.length !== 0 &&
            countCollaboratorSavedInTheGroup && countCollaboratorSavedInTheGroup.length !== 0
        ) {


            let skillGroup = showGroup._id;

            for(let i=0; i < countSkillSavedInTheGroup.length; i++){

                let skill =  countSkillSavedInTheGroup[i]._id;

                async function atributeskilltogroup() {

                    // pre save
                    await api.post('/skill-of-the-skill-group/', { skill, skillGroup, path: path })
                    .then(response => {

                        loadperGroup()
                        setTodasHabilidades(false);
                        setPorGrupos(true);


                    }).catch(error => {
                        //swal({ icon: "error", title: "Erro!", text: "Erro ao criar skill para este collaborador!" });
                        toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                        setLoadUpdateGroup(false)
                    });
                }
                atributeskilltogroup();

            }

            for(let i=0; i < countCollaboratorSavedInTheGroup.length; i++){

                let collaborator =  countCollaboratorSavedInTheGroup[i]._id;

                async function atributecollaboratortogroup() {

                    // pre save
                    await api.post('/collaborator-of-the-skill-group/', { collaborator, skillGroup, path: path })
                    .then(response => {
                        loadperGroup()
                        setTodasHabilidades(false);
                        setPorGrupos(true);

                    }).catch(error => {
                        toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                        setLoadUpdateGroup(false)
                    });
                }
                atributecollaboratortogroup();

            }

            setGroupToEdit([]);
            setShowCollaboratorSavedInGroup([]);
            setLoadUpdateGroup(false);
            setVisibleEditGrupo(!visibleEditrupo);
            setShowSkillEditInGroup([]);
            setCountCollaboratorSavedInTheGroup([]);
            setCountSkillSavedInTheGroup([]);
            setSkillFilterForThisGroup([]);
            setLoadPageByGroup(false);

        }
        else
        if(countSkillSavedInTheGroup && countSkillSavedInTheGroup.length !== 0) {


            let skillGroup = showGroup._id;

            for(let i=0; i < countSkillSavedInTheGroup.length; i++){

                let skill =  countSkillSavedInTheGroup[i]._id;

                async function atributeskilltogroup() {

                    // pre save
                    await api.post('/skill-of-the-skill-group/', { skill, skillGroup, path: path })
                    .then(response => {

                        loadperGroup()
                        setTodasHabilidades(false);
                        setPorGrupos(true);

                    }).catch(error => {
                        toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                        setLoadUpdateGroup(false)
                    });
                }
                atributeskilltogroup();

            }



            setGroupToEdit([]);
            setShowCollaboratorSavedInGroup([]);
            setLoadUpdateGroup(false);
            setVisibleEditGrupo(!visibleEditrupo);
            setShowSkillEditInGroup([]);
            setCountCollaboratorSavedInTheGroup([]);
            setCountSkillSavedInTheGroup([]);
            setSkillFilterForThisGroup([]);
            setLoadPageByGroup(false);


        }
        else
        if(countCollaboratorSavedInTheGroup && countCollaboratorSavedInTheGroup.length !== 0) {


            let skillGroup = showGroup._id;

            for(let i=0; i < countCollaboratorSavedInTheGroup.length; i++) {

                let collaborator =  countCollaboratorSavedInTheGroup[i]._id;

                async function atributecollaboratortogroup() {

                    // pre save
                    await api.post('/collaborator-of-the-skill-group/', { collaborator, skillGroup, path: path })
                    .then(response => {
                        loadperGroup()
                        setTodasHabilidades(false);
                        setPorGrupos(true);

                    }).catch(error => {
                        toast.error(error.response.data.message, { position: toast.POSITION.BOTTOM_RIGHT  });
                        setLoadUpdateGroup(false)
                    });
                }
                atributecollaboratortogroup();

            }

            setGroupToEdit([]);
            setShowCollaboratorSavedInGroup([]);
            setLoadUpdateGroup(false);
            setVisibleEditGrupo(!visibleEditrupo);
            setShowSkillEditInGroup([]);
            setCountCollaboratorSavedInTheGroup([]);
            setCountSkillSavedInTheGroup([]);
            setSkillFilterForThisGroup([]);
            setLoadPageByGroup(false)

        }
        else {
            swal({ icon: "error", title: "Erro!", text: "Necessário atualizar pelo menos um campo." });
            setLoadUpdateSkill(false);
        }

    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skill, setSkill] = useState([]);

    async function loadskill() {
        await api.get('/skill/?paginate=true')
        .then(response => {
            setSkill(response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillNew, setSkillNew] = useState([]);

    async function loadskillNew() {
        await api.get('/skill-new/')
        .then(response => {
            setSkillNew(response.data[0]);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillMoreCommon, setSkillMoreCommon] = useState([]);

    async function loadskillmorecommon() {
        await api.get('/skill-more-common/')
        .then(response => {
            setSkillMoreCommon(response.data[0]);
        }).catch(error => {
            setSkillMoreCommon([]);
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })

    }

    async function loadcountskillgroup() {
        await api.get('/skill-group-count/')
        .then(response => {
            localStorage.setItem('@peopleview/countSkillGroup', response.data);
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ skillLost, setSkillLost] = useState([]);

    async function loadskilllost() {
        await api.get('/skill-lost/')
        .then(response => {
            setSkillLost(response.data)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        })
    }

    async function deleteSkill(event) {
        await api.delete(`/skill/${event}?path=${path}`)
        .then(response => {
            if(filterMoreCommon === true) {
                loadallskillMoreCommon();
            }
            else
            if(filterLessCommon === true) {
                loadallskillLessCommon();
            }
            else
            if(filterSkillAlphabeticaOrder === true) {
                loadallskillAlphabeticaOrder();
            }
            else {
                loadallskill();
            }
            setLoadPageAllSkill(false);
            loadskilllost();
            loadskill();
            loadskillNew();
            loadperGroup();
            loadskillmorecommon();
        }).catch(error => {})
    }

    async function deleteGroup(event) {
        await api.delete(`/skill-group/${event}?path=${path}`)
        .then(response => {
            loadperGroup();
        }).catch(error => {})
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filterSkilMoreCommon, setFilterSkilMoreCommon] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [filterSkilLessCommon, setFilterSkilLessCommon] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ /*filterSkilAlphabeticaOrder*/, setFilterSkilAlphabeticaOrder] = useState(false);
    // => Paginação ALL SKILLS
    async function loadallskill() {
        setLoadPageAllSkill(true)
        await api.get('/skill-collaborator-with-skill/?paginate=true')
        .then(response => {
            if(response.data.length !== 0) {

                setLoad(false);
                setLoadPageAllSkill(false)

                const docs_a  = response.data[0];
                const arrInfo = response.data[1];

                let pagina = parseInt(arrInfo[0].pagina)

                let itensCopyCollaborator = [];

                if(pagina === arrInfo[0].pages) {

                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;

                    for(let dcs=inicio; dcs < final; dcs++){

                        itensCopyCollaborator.push(docs_a[dcs]);

                    }

                    setAllSkill(itensCopyCollaborator);
                    setPagesOfTheAllSkill(arrInfo[0].pages)
                    setTotalOfTheAllSkill(arrInfo[0].total)

                    setLoad(false)
                    setPageAllSkill(1)

                }
                else {

                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {

                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setAllSkill(itensCopyCollaborator);
                        // setArrInfo(arrInfo[0]);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)


                        setLoad(false)
                        setPageAllSkill(1)

                    }
                    else {

                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite

                        for(let dcs=inicio; dcs < final; dcs++){

                            itensCopyCollaborator.push(docs_a[dcs]);

                        }

                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)


                    }
                    // => Final de => Se total menor que o limite

                }

            }
            else {
                setLoadPageAllSkill(false)
                setLoad(false)
            }
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
            setLoadPageAllSkill(false)
        })
    }
    ///// filtro skill
    async function filterSkills(event) {
        // moreCommon
        if(event === 'moreCommon') {
            loadallskillMoreCommon();
        }
        // lessCommon
        if(event === 'lessCommon') {
            loadallskillLessCommon();
        }
        if(event === 'alphabeticaOrder') {
            loadallskillAlphabeticaOrder();
        }
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterMoreCommon, setFilterMoreCommon] = useState(false);
    async function loadallskillMoreCommon(eventLength) {
        setFilterMoreCommon(true);
        setFilterLessCommon(false);
        setFilterSkillAlphabeticaOrder(false);
        if(nameSkillFilter !== undefined && eventLength === undefined) {
            setLoadPageAllSkill(true)
            await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
            .then(response => {
                setLoad(false);
                setLoadPageAllSkill(false);
                setFilterSkilMoreCommon(true);
                setFilterSkilLessCommon(false);
                setFilterSkilAlphabeticaOrder(false);
                const docs_a  = response.data[0];
                docs_a.sort(function (a, b) {
                    if (b.totalskill > a.totalskill) {
                    return 1;
                    }
                    if (b.totalskill < a.totalskill) {
                    return -1;
                    }
                    return 0;
                });
                const arrInfo = response.data[1];
                let pagina = parseInt(arrInfo[0].pagina)
                let itensCopyCollaborator = [];
                if(pagina === arrInfo[0].pages) {
                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;
                    for(let dcs=inicio; dcs < final; dcs++){
                        itensCopyCollaborator.push(docs_a[dcs]);
                    }
                    setAllSkill(itensCopyCollaborator);
                    setPagesOfTheAllSkill(arrInfo[0].pages)
                    setTotalOfTheAllSkill(arrInfo[0].total)
                    setLoad(false)
                    setPageAllSkill(1)
                }
                else {
                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                    }
                    else {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                    }
                    // => Final de => Se total menor que o limite
                }

            }).catch(error => {})
        }
        else {
            setLoadPageAllSkill(true)
            await api.get('/skill-collaborator-with-skill/?paginate=true')
            .then(response => {
                setLoad(false);
                setLoadPageAllSkill(false);
                setFilterSkilMoreCommon(true);
                setFilterSkilLessCommon(false);
                setFilterSkilAlphabeticaOrder(false);
                const docs_a  = response.data[0];
                docs_a.sort(function (a, b) {
                    if (b.totalskill > a.totalskill) {
                    return 1;
                    }
                    if (b.totalskill < a.totalskill) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                const arrInfo = response.data[1];
                let pagina = parseInt(arrInfo[0].pagina)
                let itensCopyCollaborator = [];
                if(pagina === arrInfo[0].pages) {
                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;
                    for(let dcs=inicio; dcs < final; dcs++){
                        itensCopyCollaborator.push(docs_a[dcs]);
                    }
                    setAllSkill(itensCopyCollaborator);
                    setPagesOfTheAllSkill(arrInfo[0].pages)
                    setTotalOfTheAllSkill(arrInfo[0].total)
                    setLoad(false)
                    setPageAllSkill(1)
                }
                else {
                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages);
                        setTotalOfTheAllSkill(arrInfo[0].total);
                        setLoad(false);
                        setPageAllSkill(1);
                    }
                    else {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                    }
                    // => Final de => Se total menor que o limite
                }
            }).catch(error => {})
        }
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterLessCommon, setFilterLessCommon] = useState(false);
    async function loadallskillLessCommon(eventLength) {
        setFilterLessCommon(true);
        setFilterMoreCommon(false);
        setFilterSkillAlphabeticaOrder(false);
        if(nameSkillFilter !== undefined && eventLength === undefined) {
            setLoadPageAllSkill(true)
            await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
            .then(response => {
                setLoad(false);
                setLoadPageAllSkill(false);
                setFilterSkilMoreCommon(false);
                setFilterSkilLessCommon(true);
                setFilterSkilAlphabeticaOrder(false);
                const docs_a  = response.data[0];
                docs_a.sort(function (a, b) {
                    if (a.totalskill > b.totalskill) {
                    return 1;
                    }
                    if (a.totalskill < b.totalskill) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                const arrInfo = response.data[1];
                let pagina = parseInt(arrInfo[0].pagina)
                let itensCopyCollaborator = [];
                if(pagina === arrInfo[0].pages) {
                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;
                    for(let dcs=inicio; dcs < final; dcs++){
                        itensCopyCollaborator.push(docs_a[dcs]);
                    }
                    setAllSkill(itensCopyCollaborator);
                    setPagesOfTheAllSkill(arrInfo[0].pages)
                    setTotalOfTheAllSkill(arrInfo[0].total)
                    setLoad(false)
                    setPageAllSkill(1)
                }
                else {
                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total;
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                    }
                    else {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                    }
                    // => Final de => Se total menor que o limite
                }
            }).catch(error => {})
        }
        else {
            setLoadPageAllSkill(true)
            await api.get('/skill-collaborator-with-skill/?paginate=true')
            .then(response => {
                setLoad(false);
                setLoadPageAllSkill(false);
                setFilterSkilMoreCommon(false);
                setFilterSkilLessCommon(true);
                setFilterSkilAlphabeticaOrder(false);
                const docs_a  = response.data[0];
                docs_a.sort(function (a, b) {
                    if (a.totalskill > b.totalskill) {
                    return 1;
                    }
                    if (a.totalskill < b.totalskill) {
                    return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                const arrInfo = response.data[1];
                let pagina = parseInt(arrInfo[0].pagina)
                let itensCopyCollaborator = [];
                if(pagina === arrInfo[0].pages) {
                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;
                    for(let dcs=inicio; dcs < final; dcs++){
                        itensCopyCollaborator.push(docs_a[dcs]);
                    }
                    setAllSkill(itensCopyCollaborator);
                    setPagesOfTheAllSkill(arrInfo[0].pages)
                    setTotalOfTheAllSkill(arrInfo[0].total)
                    setLoad(false)
                    setPageAllSkill(1)
                    setFilterSkilMoreCommon(true)
                }
                else {
                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total;
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                        setFilterSkilLessCommon(false)
                    }
                    else {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                        setPageAllSkill(1)
                        setFilterSkilAlphabeticaOrder(false)
                    }
                    // => Final de => Se total menor que o limite
                }
            }).catch(error => {})
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ filterSkillAlphabeticaOrder, setFilterSkillAlphabeticaOrder] = useState(false);
    async function loadallskillAlphabeticaOrder(eventLength) {
        setFilterMoreCommon(false);
        setFilterLessCommon(false);
        setFilterSkillAlphabeticaOrder(true);
        if(nameSkillFilter !== undefined && eventLength === undefined) {
            setLoadPageAllSkill(true)
            await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
            .then(response => {
                setLoad(false);
                setLoadPageAllSkill(false);
                setFilterSkilMoreCommon(false);
                setFilterSkilLessCommon(false);
                setFilterSkilAlphabeticaOrder(true);
                const docs_a  = response.data[0];
                const arrInfo = response.data[1];
                let pagina = parseInt(arrInfo[0].pagina)
                let itensCopyCollaborator = [];
                if(pagina === arrInfo[0].pages) {
                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                    let final  = arrInfo[0].total;
                    for(let dcs=inicio; dcs < final; dcs++){
                        itensCopyCollaborator.push(docs_a[dcs]);
                    }
                    setAllSkill(itensCopyCollaborator);
                    setPagesOfTheAllSkill(arrInfo[0].pages)
                    setPageAllSkill(arrInfo[0].pagina)
                    setTotalOfTheAllSkill(arrInfo[0].total)
                    setLoad(false)
                }
                else {
                    // => Se total menor que o limite
                    if(arrInfo[0].total < arrInfo[0].limite) {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].total
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setPageAllSkill(arrInfo[0].pagina)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                    }
                    else {
                        let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                        let final =  arrInfo[0].pagina * arrInfo[0].limite
                        for(let dcs=inicio; dcs < final; dcs++){
                            itensCopyCollaborator.push(docs_a[dcs]);
                        }
                        setAllSkill(itensCopyCollaborator);
                        setPagesOfTheAllSkill(arrInfo[0].pages)
                        setPageAllSkill(arrInfo[0].pagina)
                        setTotalOfTheAllSkill(arrInfo[0].total)
                        setLoad(false)
                    }
                    // => Final de => Se total menor que o limite
                }
            }).catch(error => {});
        }
        else {
            setFilterSkilMoreCommon(false);
            setFilterSkilLessCommon(false);
            setFilterSkilAlphabeticaOrder(true);
            loadallskill();
        }

    }

    async function loadfilterperskill(event) {
        event.preventDefault();
        setLoad(true)
        setLoadPageAllSkill(true)
        if(event.target.value.length === 0) {
            setNameSkillFilter();
            let eventLength = 0
            if(filterMoreCommon === true) {
                loadallskillMoreCommon(eventLength);
            }
            else
            if(filterLessCommon === true) {
                loadallskillLessCommon(eventLength);
            }
            else
            if(filterSkillAlphabeticaOrder === true) {
                loadallskillAlphabeticaOrder(eventLength)
            }
            else {
                loadallskill();
            }
        }
        else {
            let name = event.target.value;
            setNameSkillFilter(event.target.value);
            if(filterMoreCommon === true) {
                await api.get(`/skill-collaborator-with-skill/?name=${name}`)
                .then(response => {
                    if(response.data.length === 0) {
                        setAllSkill([]);
                        setPagesOfTheAllSkill(0);
                        setPageAllSkill(0);
                        setTotalOfTheAllSkill(0);
                        setLoadPageAllSkill(false);
                        setLoad(false);
                    }
                    else {
                        setLoad(false);
                        setLoadPageAllSkill(false)
                        const docs_a  = response.data[0];
                        docs_a.sort(function (a, b) {
                            if (b.totalskill > a.totalskill) {
                            return 1;
                            }
                            if (b.totalskill < a.totalskill) {
                            return -1;
                            }
                            // a must be equal to b
                            return 0;
                        });
                        const arrInfo = response.data[1];
                        let pagina = parseInt(arrInfo[0].pagina)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)
                            setLoad(false)
                            setPageAllSkill(1)
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                                setPageAllSkill(1)
                            }
                            else {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                                setPageAllSkill(1)
                            }
                            // => Final de => Se total menor que o limite
                        }

                    }

                }).catch(error => {
                    setLoad(false);
                })
            }
            else
            if(filterLessCommon === true) {
                await api.get(`/skill-collaborator-with-skill/?name=${name}`)
                .then(response => {
                    if(response.data.length === 0) {
                        setAllSkill([]);
                        setPagesOfTheAllSkill(0);
                        setPageAllSkill(0);
                        setTotalOfTheAllSkill(0);
                        setLoadPageAllSkill(false);
                        setLoad(false);
                    }
                    else {
                        setLoad(false);
                        setLoadPageAllSkill(false)
                        const docs_a  = response.data[0];
                        docs_a.sort(function (a, b) {
                            if (a.totalskill > b.totalskill) {
                            return 1;
                            }
                            if (a.totalskill < b.totalskill) {
                            return -1;
                            }
                            // a must be equal to b
                            return 0;
                        });
                        const arrInfo = response.data[1];
                        let pagina = parseInt(arrInfo[0].pagina)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)
                            setLoad(false)
                            setPageAllSkill(1)
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages);
                                setTotalOfTheAllSkill(arrInfo[0].total);
                                setLoad(false);
                                setPageAllSkill(1);
                            }
                            else {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages);
                                setTotalOfTheAllSkill(arrInfo[0].total);
                                setLoad(false);
                                setPageAllSkill(1);
                            }
                            // => Final de => Se total menor que o limite
                        }
                    }
                }).catch(error => {})
            }
            else
            if(filterSkillAlphabeticaOrder === true) {
                await api.get(`/skill-collaborator-with-skill/?name=${name}`)
                .then(response => {
                    if(response.data.length === 0) {
                        setAllSkill([]);
                        setPagesOfTheAllSkill(0);
                        setPageAllSkill(0);
                        setTotalOfTheAllSkill(0);
                        setLoadPageAllSkill(false);
                        setLoad(false);
                    }
                    else {
                        setLoad(false);
                        setLoadPageAllSkill(false)
                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];
                        let pagina = parseInt(arrInfo[0].pagina)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages);
                            setPageAllSkill(arrInfo[0].pagina);
                            setTotalOfTheAllSkill(arrInfo[0].total);
                            setLoad(false);
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages);
                                setPageAllSkill(arrInfo[0].pagina);
                                setTotalOfTheAllSkill(arrInfo[0].total);
                                setLoad(false);
                            }
                            else {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setPageAllSkill(arrInfo[0].pagina)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                            }
                            // => Final de => Se total menor que o limite
                        }
                        // setAllSkill(response.data);
                    }
                }).catch(error => {});
            }
            else {
                await api.get(`/skill-collaborator-with-skill/?name=${name}`)
                .then(response => {
                    setLoad(false);
                    setLoadPageAllSkill(false)
                    const docs_a  = response.data[0];
                    if(response.data.length === 0) {
                        setAllSkill([]);
                        setPagesOfTheAllSkill(0);
                        setPageAllSkill(0);
                        setTotalOfTheAllSkill(0);
                        setLoadPageAllSkill(false);
                        setLoad(false);
                    }
                    else {
                        const arrInfo = response.data[1];
                        let pagina = parseInt(arrInfo[0].pagina)
                        let itensCopyCollaborator = [];
                        if(pagina === arrInfo[0].pages) {
                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;
                            for(let dcs=inicio; dcs < final; dcs++){
                                itensCopyCollaborator.push(docs_a[dcs]);
                            }
                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setPageAllSkill(arrInfo[0].pagina)
                            setTotalOfTheAllSkill(arrInfo[0].total)
                            setLoad(false)
                        }
                        else {
                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setPageAllSkill(arrInfo[0].pagina)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                            }
                            else {
                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setPageAllSkill(arrInfo[0].pagina)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                            }
                            // => Final de => Se total menor que o limite
                        }
                    }
                }).catch(error => {});
            }
        }
    }
    async function prevPageAllSkill() {
        if (pageAllSkill === 1) return;
        const pageNumber = pageAllSkill - 1;
        setPageAllSkill(pageNumber);
        async function loadallskillprevpage(page = pageNumber) {
            if(nameSkillFilter !== undefined) {
                if(filterMoreCommon === true) {
                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {
                        if(response.data.length === 0) {
                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)
                            setLoad(false)
                        }
                        else {
                            setLoad(false);
                            setLoadPageAllSkill(false)
                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (b.totalskill > a.totalskill) {
                                return 1;
                                }
                                if (b.totalskill < a.totalskill) {
                                return -1;
                                }
                                return 0;
                            });
                            const arrInfo = response.data[1];
                            let pagina = parseInt(page)
                            let itensCopyCollaborator = [];
                            if(pagina === arrInfo[0].pages) {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                            }
                            else {
                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {
                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total
                                    for(let dcs=inicio; dcs < final; dcs++){
                                        itensCopyCollaborator.push(docs_a[dcs]);
                                    }
                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)
                                }
                                else {
                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite
                                    for(let dcs=inicio; dcs < final; dcs++){
                                        itensCopyCollaborator.push(docs_a[dcs]);
                                    }
                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)
                                }
                                // => Final de => Se total menor que o limite
                            }
                        }
                    }).catch(error => {})
                }
                else
                if(filterLessCommon === true) {
                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {
                        if(response.data.length === 0) {
                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)
                            setLoad(false)
                        }
                        else {
                            setLoad(false);
                            setLoadPageAllSkill(false)
                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (a.totalskill > b.totalskill) {
                                return 1;
                                }
                                if (a.totalskill < b.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });
                            const arrInfo = response.data[1];
                            let pagina = parseInt(page)
                            let itensCopyCollaborator = [];
                            if(pagina === arrInfo[0].pages) {
                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;
                                for(let dcs=inicio; dcs < final; dcs++){
                                    itensCopyCollaborator.push(docs_a[dcs]);
                                }
                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                            }
                            else {
                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {
                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total
                                    for(let dcs=inicio; dcs < final; dcs++){
                                        itensCopyCollaborator.push(docs_a[dcs]);
                                    }
                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)
                                }
                                else {
                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite
                                    for(let dcs=inicio; dcs < final; dcs++){
                                        itensCopyCollaborator.push(docs_a[dcs]);
                                    }
                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)

                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                // => Final de => Se total menor que o limite

                            }
                            // setAllSkill(response.data);

                        }

                    }).catch(error => {});

                }
                else {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        setLoad(false);
                        setLoadPageAllSkill(false)

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(page)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)

                            setLoad(false)



                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)


                                setLoad(false)



                            }
                            else {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)



                            }
                            // => Final de => Se total menor que o limite

                        }
                        // setAllSkill(response.data);

                    }).catch(error => {});

                }

            }
            else {

                if(filterMoreCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {


                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (b.totalskill > a.totalskill) {
                                return 1;
                                }
                                if (b.totalskill < a.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });


                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)



                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)
                                    // setPageAllSkill(1)



                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterLessCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (a.totalskill > b.totalskill) {
                                return 1;
                                }
                                if (a.totalskill < b.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(arrInfo[0].pagina)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)

                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)

                                }
                                else {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(arrInfo[0].pagina)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                // => Final de => Se total menor que o limite

                            }
                            // setAllSkill(response.data);

                        }

                    }).catch(error => {});

                }
                else {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        setLoad(false);
                        setLoadPageAllSkill(false)

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(arrInfo[0].pagina)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)

                            setLoad(false)
                            // setPageAllSkill(1)


                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)


                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            // => Final de => Se total menor que o limite

                        }

                    }).catch(error => {});

                }

            }

        }
        loadallskillprevpage();
    }

    async function nextPageAllSkill(event) {

        if (pageAllSkill === allSkill[0].pages) return;

        const pageNumber = pageAllSkill + 1;
        setPageAllSkill(pageNumber);
        async function loadallskillnextpage(page = pageNumber) {

            if(nameSkillFilter !== undefined) {

                if(filterMoreCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (b.totalskill > a.totalskill) {
                                return 1;
                                }
                                if (b.totalskill < a.totalskill) {
                                return -1;
                                }
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)

                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterLessCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (a.totalskill > b.totalskill) {
                                return 1;
                                }
                                if (a.totalskill < b.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)

                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)

                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                // => Final de => Se total menor que o limite

                            }
                            // setAllSkill(response.data);

                        }

                    }).catch(error => {});

                }
                else {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        setLoad(false);
                        setLoadPageAllSkill(false)

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(page)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)

                            setLoad(false)



                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)


                                setLoad(false)



                            }
                            else {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)



                            }
                            // => Final de => Se total menor que o limite

                        }
                        // setAllSkill(response.data);

                    }).catch(error => {});

                }

            }
            else {

                if(filterMoreCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {


                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (b.totalskill > a.totalskill) {
                                return 1;
                                }
                                if (b.totalskill < a.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });


                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)



                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)
                                    // setPageAllSkill(1)



                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterLessCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (a.totalskill > b.totalskill) {
                                return 1;
                                }
                                if (a.totalskill < b.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(arrInfo[0].pagina)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)

                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)

                                }
                                else {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(arrInfo[0].pagina)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                // => Final de => Se total menor que o limite

                            }
                            // setAllSkill(response.data);

                        }

                    }).catch(error => {});

                }
                else {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        setLoad(false);
                        setLoadPageAllSkill(false)

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(arrInfo[0].pagina)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)

                            setLoad(false)
                            // setPageAllSkill(1)


                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)


                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            // => Final de => Se total menor que o limite

                        }

                    }).catch(error => {});

                }

            }

        }
        loadallskillnextpage();

    }

    async function paginationPageAllSkill(page) {

        const pageNumber = page;
        setPageAllSkill(pageNumber);
        setLoadPageAllSkill(true)


        async function loadallskillpagination(page = pageNumber) {


            if(nameSkillFilter !== undefined) {

                if(filterMoreCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (b.totalskill > a.totalskill) {
                                return 1;
                                }
                                if (b.totalskill < a.totalskill) {
                                return -1;
                                }
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)

                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterLessCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (a.totalskill > b.totalskill) {
                                return 1;
                                }
                                if (a.totalskill < b.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)

                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)

                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                // => Final de => Se total menor que o limite

                            }
                            // setAllSkill(response.data);

                        }

                    }).catch(error => {});

                }
                else {

                    await api.get(`/skill-collaborator-with-skill/?name=${nameSkillFilter}`)
                    .then(response => {

                        setLoad(false);
                        setLoadPageAllSkill(false)

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(page)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)

                            setLoad(false)



                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)


                                setLoad(false)



                            }
                            else {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final =  pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)



                            }
                            // => Final de => Se total menor que o limite

                        }
                        // setAllSkill(response.data);

                    }).catch(error => {});

                }

            }
            else {

                if(filterMoreCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {


                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (b.totalskill > a.totalskill) {
                                return 1;
                                }
                                if (b.totalskill < a.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });


                            const arrInfo = response.data[1];

                            let pagina = parseInt(page)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)



                                }
                                else {

                                    let inicio = arrInfo[0].limite * pagina - arrInfo[0].limite;
                                    let final =  pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)
                                    // setPageAllSkill(1)



                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterLessCommon === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            docs_a.sort(function (a, b) {
                                if (a.totalskill > b.totalskill) {
                                return 1;
                                }
                                if (a.totalskill < b.totalskill) {
                                return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });

                            const arrInfo = response.data[1];

                            let pagina = parseInt(arrInfo[0].pagina)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)

                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)

                                }
                                else {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    // setArrInfo(arrInfo[0]);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)
                                    setLoad(false)


                                }
                                // => Final de => Se total menor que o limite

                            }

                        }

                    }).catch(error => {})

                }
                else
                if(filterSkillAlphabeticaOrder === true) {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        if(response.data.length === 0) {

                            setAllSkill([]);
                            setPagesOfTheAllSkill(0)
                            setPageAllSkill(0)
                            setTotalOfTheAllSkill(0)

                            setLoad(false)

                        }
                        else {

                            setLoad(false);
                            setLoadPageAllSkill(false)

                            const docs_a  = response.data[0];
                            const arrInfo = response.data[1];

                            let pagina = parseInt(arrInfo[0].pagina)

                            let itensCopyCollaborator = [];

                            if(pagina === arrInfo[0].pages) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final  = arrInfo[0].total;

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)

                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                // => Se total menor que o limite
                                if(arrInfo[0].total < arrInfo[0].limite) {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].total

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)


                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                else {

                                    let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                    let final =  arrInfo[0].pagina * arrInfo[0].limite

                                    for(let dcs=inicio; dcs < final; dcs++){

                                        itensCopyCollaborator.push(docs_a[dcs]);

                                    }

                                    setAllSkill(itensCopyCollaborator);
                                    setPagesOfTheAllSkill(arrInfo[0].pages)
                                    setTotalOfTheAllSkill(arrInfo[0].total)

                                    setLoad(false)
                                    // setPageAllSkill(1)


                                }
                                // => Final de => Se total menor que o limite

                            }
                            // setAllSkill(response.data);

                        }

                    }).catch(error => {});

                }
                else {

                    await api.get(`/skill-collaborator-with-skill/?paginate=true&page=${page}`)
                    .then(response => {

                        setLoad(false);
                        setLoadPageAllSkill(false)

                        const docs_a  = response.data[0];
                        const arrInfo = response.data[1];

                        let pagina = parseInt(arrInfo[0].pagina)

                        let itensCopyCollaborator = [];

                        if(pagina === arrInfo[0].pages) {

                            let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                            let final  = arrInfo[0].total;

                            for(let dcs=inicio; dcs < final; dcs++){

                                itensCopyCollaborator.push(docs_a[dcs]);

                            }

                            setAllSkill(itensCopyCollaborator);
                            setPagesOfTheAllSkill(arrInfo[0].pages)
                            setTotalOfTheAllSkill(arrInfo[0].total)

                            setLoad(false)
                            // setPageAllSkill(1)


                        }
                        else {

                            // => Se total menor que o limite
                            if(arrInfo[0].total < arrInfo[0].limite) {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].total

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)


                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            else {

                                let inicio = arrInfo[0].limite * arrInfo[0].pagina - arrInfo[0].limite;
                                let final =  arrInfo[0].pagina * arrInfo[0].limite

                                for(let dcs=inicio; dcs < final; dcs++){

                                    itensCopyCollaborator.push(docs_a[dcs]);

                                }

                                setAllSkill(itensCopyCollaborator);
                                // setArrInfo(arrInfo[0]);
                                setPagesOfTheAllSkill(arrInfo[0].pages)
                                setTotalOfTheAllSkill(arrInfo[0].total)
                                setLoad(false)
                                // setPageAllSkill(1)


                            }
                            // => Final de => Se total menor que o limite

                        }

                    }).catch(error => {});

                }

            }

        }
        loadallskillpagination();


    }

    async function closeSelect(e) {

        if(
            e.target.className !== "busca"

        ) {
            setSkillFilterForThisGroup(false)
            setCollaboratorFilterForThisGroup(false)
            setCollaboratorFilterForThisSkill(false)
        }
    }
    function handleKeyPressCriarHabilidade(event) {
        if (event.key === "Enter") {
            saveSkill(event);
        }
    }
    function handleKeyPressCriarGrupo(event) {
        if (event.key === "Enter") {
            saveGroup(event);
        }
    }
    function handleKeyPressEditarHabilidade(event) {
        if (event.key === "Enter") {
            updateSkill(event);
        }
    }
    function handleKeyPressEditarGrupo(event) {
        if (event.key === "Enter") {
            saveGroup(event);
        }
    }

    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                load={load}
                loadFilterCollaboratorForThisSkill={loadFilterCollaboratorForThisSkill}
                collaboratorFilterForThisSkill={collaboratorFilterForThisSkill}
                myChangeHandlerToAddCollaborator={myChangeHandlerToAddCollaborator}
                collaborators={collaborators}
                myChangeHandlerExcludeCollaboratorAdded={myChangeHandlerExcludeCollaboratorAdded}
                loadCollaboratorFilterForThisSkill={loadCollaboratorFilterForThisSkill}
                loadToAddCollaborator={loadToAddCollaborator}
                loadExcludeCollaboratorAdded={loadExcludeCollaboratorAdded}
                allCollaboratorsExclude={allCollaboratorsExclude}
                myChangeHandlerToSaveSkill={myChangeHandlerToSaveSkill}
                loadNameCadHabilidade={loadNameCadHabilidade}
                loadRegisterNewSkill={loadRegisterNewSkill}
                saveSkill={saveSkill}
                CastrarHabilidade={CastrarHabilidade}
                visibleCastrarHabilidade={visibleCastrarHabilidade}
                loadFilterCollaboratorForThisGroup={loadFilterCollaboratorForThisGroup}
                loadCollaboratorFilterForThiGroup={loadCollaboratorFilterForThiGroup}
                collaboratorFilterForThisGroup={collaboratorFilterForThisGroup}
                myChangeHandlerToAddCollaboratorGroup={myChangeHandlerToAddCollaboratorGroup}
                loadToAddCollaboratorGroup={loadToAddCollaboratorGroup}
                collaboratorsGroup={collaboratorsGroup}
                loadExcludeCollaboratorAddedGroup={loadExcludeCollaboratorAddedGroup}
                allCollaboratorsExcludeGroup={allCollaboratorsExcludeGroup}
                myChangeHandlerExcludeCollaboratorAddedGroup={myChangeHandlerExcludeCollaboratorAddedGroup}
                filterSkillForThisGroup={filterSkillForThisGroup}
                skillFilterForThisGroup={skillFilterForThisGroup}
                loadSkillFilterForThisGroup={loadSkillFilterForThisGroup}
                myChangeHandlerSkillToAddGroup={myChangeHandlerSkillToAddGroup}
                skillsGroup={skillsGroup}
                loadToAddSkillTorGroup={loadToAddSkillTorGroup}
                loadExcludeSkillAddedGroup={loadExcludeSkillAddedGroup}
                allSkillExcludeGroup={allSkillExcludeGroup}
                myChangeHandlerExcludeSkillAddedGroup={myChangeHandlerExcludeSkillAddedGroup}
                myChangeHandlerToSaveGroup={myChangeHandlerToSaveGroup}
                loadRegisterNewGroup={loadRegisterNewGroup}
                saveGroup={saveGroup}
                CastrarGrupo={CastrarGrupo}
                loadNameCadGrupo={loadNameCadGrupo}
                visibleCastrarGrupo={visibleCastrarGrupo}
                loadNameCadGrupoHab={loadNameCadGrupoHab}
                loadNameCadGrupoCollaborator={loadNameCadGrupoCollaborator}
                user={user}
                closeSelect={closeSelect}
                handleKeyPressCriarHabilidade={handleKeyPressCriarHabilidade}
                handleKeyPressCriarGrupo={handleKeyPressCriarGrupo}
                />
                <div className="page-habilidade-adm">
                    <InforBox
                    totalSkill={skill && skill.total ? skill.total : '0'}
                    totalGroup={totalGroups}
                    skillNew={skillNew && skillNew ? skillNew : ''}
                    skillMoreCommon={skillMoreCommon && skillMoreCommon ? skillMoreCommon : 'Nenhuma'}
                    skillLost={skillLost}
                    />
                    {
                    byGroup && byGroup.total === 0 && allSkill && allSkill.docs && allSkill.length === undefined ? (
                       <>
                        <div className="nenhuma-habilidade">
                            <p>Nenhuma habilidade ou grupo cadastrado.</p>
                            <div className="butoes">
                                <button className="btnGreen more"  onClick={() => CastrarHabilidade()} >Cadastrar nova habilidade</button>
                                <button className="btnGreen more" onClick={() => CastrarGrupo()}>Cadastrar novo grupo</button>
                            </div>
                            <img src={NenhumaHabilidade} alt="Nenhuma Habilidade" />
                        </div>
                       </>
                    ):(
                    <>

                    <div className="habilidades-cadastrada">
                        <div className="menu">
                            <p>Mostrando</p>
                            <button className={todasHabilidades ? "selecionado" : ""} onClick={() => SelectTodas()} >Todas</button>
                            <button className={porGrupos ? "selecionado" : ""} onClick={() => SelectPorGrupo()}>Por grupo</button>
                            {
                                todasHabilidades ?
                                <input name="name" value={nameSkillFilter !== undefined ? nameSkillFilter : ''} onChange={loadfilterperskill} autocomplete="off" placeholder="Filtre por habilidades" className="busca"/>
                                :
                                ''
                            }
                            {
                                porGrupos ?
                                <input name="name" value={name} onChange={loadfilterperGroupName} placeholder="Filtre por grupos" autocomplete="off" className="busca"/>
                                :
                                ''
                            }

                        </div>

                    {
                        todasHabilidades ? (
                            <AllSkills
                            handleKeyPressEditarHabilidade={handleKeyPressEditarHabilidade}
                            loadPageAllSkill={loadPageAllSkill}
                            allSkill={allSkill}
                            pages={pagesOfTheAllSkill}
                            pageAllSkill={pageAllSkill}
                            totalOfTheAllSkill={totalOfTheAllSkill}
                            prevPageAllSkill={prevPageAllSkill}
                            nextPageAllSkill={nextPageAllSkill}
                            CastrarHabilidade={CastrarHabilidade}
                            paginationPageAllSkill={paginationPageAllSkill}
                            showCollaboratorsavedInTheSkill={showCollaboratorsavedInTheSkill}
                            VerHabilidade={VerHabilidade}
                            EditarHabilidade={EditarHabilidade}
                            visibleEditarHabilidade={visibleEditarHabilidade}
                            NaoEditarHabilidade={NaoEditarHabilidade}
                            showSkill={showSkill}
                            loadNameEditHabilidade={loadNameEditHabilidade}
                            myChangeHandlerExcludeCollaboratorAddedEditInTheSkill={myChangeHandlerExcludeCollaboratorAddedEditInTheSkill}
                            visibleVerHabilidade={visibleVerHabilidade}
                            NaoVerHabilidade={NaoVerHabilidade}
                            showCollaboratorInTheSkill={showCollaboratorInTheSkill}
                            loadFilterCollaboratorForThisSkill={loadFilterCollaboratorForThisSkill}
                            collaboratorFilterForThisSkill={collaboratorFilterForThisSkill}
                            myChangeHandlerToAddCollaboratorEditSkill={myChangeHandlerToAddCollaboratorEditSkill}
                            myChangeHandlerToEditSkill={myChangeHandlerToEditSkill}
                            updateSkill={updateSkill}
                            loadUpdateSkill={loadUpdateSkill}
                            deleteSkill={deleteSkill}
                            filterSkills={filterSkills}
                            loadShowCollaboratorInTheSkill={loadShowCollaboratorInTheSkill}
                            />
                        ) : ('')
                    }

                    {
                        porGrupos ? (
                            <ByGroup
                            byGroup={byGroup}
                            loadPageByGroup={loadPageByGroup}
                            CastrarGrupo={CastrarGrupo}
                            pages={pagesOfTheByGroup}
                            pageByGroup={pageByGroup}
                            prevPageByGroup={prevPageByGroup}
                            nextPageByGroup={nextPageByGroup}
                            paginationPageByGroup={paginationPageByGroup}
                            handleKeyPressEditarGrupo={handleKeyPressEditarGrupo}
                            VerGrupo={VerGrupo}
                            EditarGrupo={EditarGrupo}
                            visibleVerGrupo={visibleVerGrupo}
                            NaoVerGrupo={NaoVerGrupo}
                            showGroup={showGroup}
                            showSkillInGroup={showSkillInGroup}
                            showCollaboratorInGroup={showCollaboratorInGroup}
                            visibleEditrupo={visibleEditrupo}
                            loadNameEditGrupo={loadNameEditGrupo}
                            loadNameEditGrupoHab={loadNameEditGrupoHab}
                            NaoEditarGrupo={NaoEditarGrupo}
                            loadFilterCollaboratorForThisGroup={loadFilterCollaboratorForThisGroup}
                            showCollaboratorSavedInGroup={showCollaboratorSavedInGroup}
                            collaboratorFilterForThisGroup={collaboratorFilterForThisGroup}
                            myChangeHandlerToAddCollaboratorEditGroup={myChangeHandlerToAddCollaboratorEditGroup}
                            myChangeHandlerExcludeCollaboratorAddedEditInTheGroup={myChangeHandlerExcludeCollaboratorAddedEditInTheGroup}
                            filterSkillForThisGroup={filterSkillForThisGroup}
                            skillFilterForThisGroup={skillFilterForThisGroup}
                            myChangeHandlerToAddSkillToEditGroup={myChangeHandlerToAddSkillToEditGroup}
                            showSkillEditInGroup={showSkillEditInGroup}
                            myChangeHandlerExcludeSkillAddedEditInTheGroup={myChangeHandlerExcludeSkillAddedEditInTheGroup}
                            myChangeHandlerToEditGroup={myChangeHandlerToEditGroup}
                            updateGroup={updateGroup}
                            loadUpdateGroup={loadUpdateGroup}
                            deleteGroup={deleteGroup}
                            loadShowCollaboratorSavedInGroup={loadShowCollaboratorSavedInGroup}
                            loadShowSkillEditInGroup={loadShowSkillEditInGroup}
                            />
                        ) : ('')
                    }

                    </div>

                    </>
                    )}
                </div>
            </Layout>
        </>
    );
}
