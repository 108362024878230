import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layouts/defaultUser';

import SubHeader from '../../../components/PerfilUser/subHeader';
import { decodeToken } from '../../../services/auth';

import InforProfile from '../../../components/PerfilUser/InforProfile';

import Workday from '../../../components/PerfilUser/Professional/Workday';
import SubMenu from '../../../components/PerfilUser/Professional/subMenu';

// import './styles.scss';
import api from '../../../services/api';
import '../styles.scss';

export default function ProfileWorkday(props) {
    const decoded = decodeToken();
    var id = decoded.user._id;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ collaborator, setCollaborator ] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function loadcollaborator() {
            api.get(`/collaborator-access/${id}`)
            .then(response => {
                setCollaborator(response.data);
            })
        }
        loadcollaborator();
    }, [id]);


        return (
            <>
               <Layout path={props.match.path}
               >

                    <div className="profile-user">
                        <InforProfile collaborator={collaborator} id={id}  setCollaborator={setCollaborator}/>
                        <SubHeader collaborator={collaborator} id={id} 
                    path={props.match.path}/>
                        <SubMenu collaborator={collaborator} id={id}/>
                        <Workday collaborator={collaborator} id={id}/>
                    </div>
                </Layout>
            </>
        );
}

