import React from 'react';
// import IconsInformation from '../../IconsInformation';
import HeaderAdmin from '../../HeaderAdmin';
import { NavLink } from 'react-router-dom'
import IconADD from '../../../assets/images/add-branco.svg'
// import api from '../../../services/api';



const headerBenefitListHeader = ({
    loadfilterbeneftis,
    name,
    history,
    user,
    loadPageNotifications,
    notifications,
    notificationsRead,
    readTrue,
    showMore,
    indexUpdate,
    detailBenefit,
    path,
}) => {

    return (
        <>
        <HeaderAdmin>
            {
                path === '/beneficios' ?
                <>
                    <div>
                        <h1>
                            Benefícios
                        </h1>
                        <p>Acompanhe e gerencie os benefícios dos colaboradores</p>
                    </div>
                    <div className='beneficios lista'>
                        <NavLink className="btnGreen more" to="/beneficios/criar" >Cadastrar um benefício</NavLink>  
               
                        <form action="">
                            <input name="name" value={name} onChange={loadfilterbeneftis} autoComplete="off" placeholder="Filtrar por benefício" className="campo-busca"/>
                        </form>
                    </div>
                </>
                :""
            }

            {
                path === '/beneficio/informacoes/:id' ?
                <>
                <div>
                    <h1>
                        <NavLink to="/beneficios" className="voltar">Voltar</NavLink>{detailBenefit?.name}
                    </h1>
                    <p>Veja detalhes e gerencie este benefício</p>
                </div>
                </>
                :""
            }

            {
                path === '/beneficios/:id/editar' ?
                <>
                <div>
                    <h1>
                        <NavLink to="/beneficios" className="voltar">Voltar</NavLink>
                        Editar benefício
                    </h1>
                </div>
                </>
                :""
            }

            {
                path === '/beneficios/criar' ?
                <>
                <div>
                    <h1>
                        <NavLink to="/beneficios" className="voltar">Voltar</NavLink>
                        Cadastrar benefício
                    </h1>
                </div>
                </>
                :""
            }


        </HeaderAdmin>

        {
                path === '/beneficios' ?
                <>
                <div className='PgBeneficios-busca-lista'>
                    <NavLink to="/beneficios/criar" className="btn-novo-beneficio"><img src={IconADD} alt="" /></NavLink>
                    <NavLink className="btnGreen more" to="/beneficios/criar" >Cadastrar um benefício</NavLink>
                    <form action="">
                        <input name="name" value={name} onChange={loadfilterbeneftis}autoComplete="off" placeholder="Filtrar por benefício" className="campo-busca"/>
                    </form>
                </div>
                </> : ""
        }
        </>
    );
}
export default headerBenefitListHeader;
