import React, {useEffect, useState} from 'react';
import Layout from '../../components/Layouts/default';
import Header from '../../components/RecruitmentAndSelection/header';
import { NavLink } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import iconVerMais from '../../assets/images/more.svg';
import iconOrdenation from '../../assets/images/ordenation.svg';
import Carregamento from '../../assets/images/carregando.gif';
import { formatDateString } from '../../services/utils';
import api from '../../services/api';
import { decodeToken } from '../../services/auth';
import { toast } from 'react-toastify';
import './styles.scss';


export default function FolderCandidates(props) {

    const { path } = props.match;
    const { pathname } = props.location;
    const { id }   = props.match.params
    const { user } = decodeToken();
    const role     = user.role;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [visibleModalAddFolder, setVisibleModalAddCandite] = useState(false);

    async function modalAddFolderOpen() {
        setVisibleModalAddCandite(!visibleModalAddFolder);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ folderCandidates, setFolderCandidates] = useState([]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [ loadFolderCandidates, setLoadFolderCandidates ] = useState(false);
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ nameFilter, setNameFilter ] = useState('');
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ nameFolder, setNameFolder ] = useState('');
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ sortBy, setSortBy ] = useState('name');
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ total, setTotal ] = useState(0);
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [ limit, setLimit ] = useState(5);
   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [
        laborBond,
        // setLaborBond
    ] = useState('');

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [pages, setPages] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [
        page,
        setPage
    ] = useState(0);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        folderAllCandidate(id, nameFilter, laborBond, sortBy, page, limit);
    }, [id, nameFilter, laborBond, sortBy, page, limit]);

    async function folderAllCandidate(id, nameFilter, laborBond, sortBy, page, limit) {

        setLoadFolderCandidates(true)
        await api.get(`/folder-all-candidate-count/${id}?name=${nameFilter}&laborBond=${laborBond}&sortBy=${sortBy}&page=${page}&limit=${limit}`)
        .then(response => {
            setLoadFolderCandidates(false)
            setFolderCandidates(response.data.folderSearchCandidate);
            setNameFolder(response.data.nameFolder)
            setPage(response.data.page)
            setPages(response.data.pages)
            setTotal(response.data.total)
            setLimit(response.data.limit)
        }).catch(error => {
            toast.error(error?.response?.data?.message ?? "Erro ao tentar carregar os dados, por favor tente novamente.", { position: toast.POSITION.BOTTOM_RIGHT  });
        });
    }

    // FUNÇÃO DO FILTRO BUSCAR
    async function loadCandidatesFilter(event) {
        if(role === 'admin' || role === 'master') {
            const name = event.target.value;
            setNameFilter(event.target.value);
            folderAllCandidate(id, name, laborBond, sortBy, page, limit)
        }
    }

    async function paginationPage(page) {
        if(role === 'admin' || role === 'master') {
            setPage(page);
            folderAllCandidate(id, nameFilter, laborBond, sortBy, page, limit)
        }
    }

    async function prevPage() {
        if(role === 'admin' || role === 'master') {
            if (page === 0) return;
            const pageNumber = Number(page) - 1;
            setPage(pageNumber);
            folderAllCandidate(id, nameFilter, laborBond, sortBy, pageNumber, limit)
        }
    }

    async function nextPage() {
        if(role === 'admin' || role === 'master') {
            if (page === pages) return;
            const pageNumber = Number(page) + 1;
            setPage(pageNumber);
            folderAllCandidate(id, nameFilter, laborBond, sortBy, pageNumber, limit)
        }

    }



    let numberPaginate = [];

    for(let i=0; i<=pages; i++){

        numberPaginate.push(
            <li className="number" key={i}>
                <button
                className={i === Number(page) ? "secao" : ""}
                onClick={
                    (event) => paginationPage(i)
                }>
                {i+1}
                </button>
            </li>
        )
    }


    return (
        <>
            <Layout
            path={props.match.path}>
                <Header
                    path={path}
                    pathname={pathname}
                    modalAddFolderOpen={modalAddFolderOpen}
                    setVisibleModalAddCandite={setVisibleModalAddCandite}
                    visibleModalAddFolder={visibleModalAddFolder}
                    nameFolder={nameFolder}
                    folderCandidates={total}
                />
                <div className='page-talents folder-cadidates'>
                    <div className='list-all-candidates'>
                    <div className='title-filter'>
                    <h2 className='title-box'>Nesta pasta</h2>
                    <div className="filter">
                        <input
                            type="text"
                            className='busca'
                            value={nameFilter && nameFilter !== undefined ? nameFilter : ''}
                            onChange={loadCandidatesFilter}
                            placeholder='Filtre por nome ou setor'
                        />
                    </div>

                    </div>
                    <div className='candidates-table'>
                        <div className='head-table grid-table'>
                            <div className='nome'>Nome</div>
                            <div className='data'>Recebido em</div>
                            <div className='setor'>Setor desejado</div>
                            <div className='regime'>Regime</div>
                            <div className='curriculo'>Currículo</div>
                            <div className='view-more'>
                                <Dropdown  className="dropdown-icon">
                                    <Dropdown.Toggle>
                                    <img alt="" src={iconOrdenation} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <button onClick={
                                            ()=> setSortBy('mais_recente')
                                        } type="button" >
                                        Mais recentes primeiro
                                        </button>
                                        <button onClick={
                                            ()=> setSortBy('mais_antigo')
                                        } type="button" >
                                        Mais antigos primeiro
                                        </button>
                                        <button onClick={
                                            ()=> setSortBy('por_nome')
                                        } type="button" >
                                        Nome em ordem alfabética
                                        </button>
                                        <button onClick={
                                            ()=> setSortBy('por_setor')
                                        } type="button" >
                                        Setor em ordem alfabética
                                        </button>
                                    </Dropdown.Menu>
                                </Dropdown>


                            </div>
                        </div>

                    <h4 className='infor-filtro'>
                    {
                    sortBy === 'mais_recente'  ?
                    <> Mais recentes  { laborBond && laborBond !== "" ? `de ${laborBond}` :""} </>
                    :
                    sortBy === 'mais_antigo' ?
                    <> Mais antigos  { laborBond && laborBond !== "" ? `de ${laborBond}` :""} </>
                    :
                    sortBy === 'por_nome' ?
                    <> Ordem alfabética  { laborBond && laborBond !== "" ? `de ${laborBond}` :""} </>
                    :
                    sortBy === 'por_setor' ?
                    <> Ordem alfabética por setor  { laborBond && laborBond !== "" ? `de ${laborBond}` :""} </>
                    :
                    sortBy === 'mais_recente' ?
                    'Mais recentes'
                    :
                    sortBy === 'mais_antigo' ?
                    'Mais antigos'
                    :
                    sortBy === 'por_nome' ?
                    'Por Ordem alfabética'
                    :
                    sortBy === 'por_setor' ?
                    'Por setor em ordem alfabética'
                    :
                    laborBond && laborBond !== "" ?
                    laborBond :""
                    }
                    </h4>
                        <div className='body-table'>
                            {
                                loadFolderCandidates ?
                                    <>
                                    <div className="carregamento">
                                        <img alt="" src={Carregamento} />
                                    </div>
                                    </>
                                    : folderCandidates.length === 0 ?

                                        <div className='nenhum-candidato'>
                                            <p>Nenhum candidato. <br/>Anuncie vagas ou cadastre manualmente.</p>
                                            <NavLink to="/"className='btnGreen more'> Cadastrar novo candidato </NavLink>
                                        </div>
                                    :
                            folderCandidates.length !== 0 ? folderCandidates
                            .map((candidate, index) => (
                            <>
                            <div className='grid-table candidates'key={index}>

                                <NavLink to={`/recrutamento/perfil-candidato/${candidate?._id}`} className="link-perfil">
                                    <div className='nome'>{candidate?.name}</div>
                                    <div className='data'> {formatDateString(candidate?.updatedAt)}  </div>
                                    <div className='setor'>{candidate?.sector?.name ?? "-"}</div>
                                    <div className='regime'>{candidate?.laborBond === "PJ" ? "Pessoa Jurídica" : candidate.laborBond }</div>
                                    <div className='curriculo'>
                                            {
                                                candidate && candidate?.curriculum !== undefined ?
                                                <><a  target="’_blank’" href={candidate && candidate?.curriculum}>Ver currículo</a></>:""
                                            }
                                    </div>
                                </NavLink>
                                <div className='view-more'>
                                    <Dropdown  className="dropdown-icon">
                                        <Dropdown.Toggle>
                                        <img alt="" src={iconVerMais} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>

                                            <div className='mudar-pasta'>
                                                <p>Mover para</p>
                                                <select name="" id="">
                                                    <option value="">Selecione a pasta</option>
                                                </select>
                                            </div>
                                            <button  type="button" className='red'>Excluir candidato</button>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </div>
                            </>
                            )) : ("")}
                        </div>
                    </div>
{

    loadFolderCandidates ?
    "":
        <div className='paginacaoG'>
            <ul>
            {
                Number(total) === Number(limit) ?
                ''
                :
                pages > 0 ?
                numberPaginate
                :
                ''
            }
            {
                Number(total) === Number(limit) ?
                ''
                :
                pages && pages > 0 ?
                <>
                <li className="page-anterior">
                    <button style={(Number(page) === 0) ? { cursor: "not-allowed" } : {}}  disabled={Number(page) === 0} onClick={
                        () => prevPage(page)
                        }>anterior</button>
                </li>
                <li className="page-proxima">
                    <button style={(Number(page) === Number(pages)) ? { cursor: "not-allowed" } : {}}  disabled={Number(page) === Number(pages)} onClick={
                        () => nextPage(page)
                        }>próxima</button>
                </li>
                </>
                :
                ''
            }
            </ul>
        </div>

}

                </div>
            </div>


            </Layout>
        </>
    );
}

